import { BillsbyAction } from "../models/BillsbyAction";
import { AppState } from "..";
import { RESET_TRANSACTION_LOGS_STATE, SET_TRANSACTION_LOGS_FIELD, FETCH_TRANSACTION_LOGS_SUCCESS, FETCH_TRANSACTION_LOGS_REQUEST, FETCH_TRANSACTION_LOGS_FAILURE } from "../actions/transactionLogsActions";
import { ITransactionLogsPaginated } from "../models/TransactionLogs";
import { GetPaymentLogsPaginatedRequest, GetPaymentLogsPaginatedResponse, PaymentLogStatus} from "../utils/grpc/generated/Billsby.Protos/billing/private/paymentlogs/payment_logs_pb";

import { PAGINATION_PAGE_SIZE } from "../utils/constants";

export interface ITransactionLogsReducerState {
    searchedTransactions: string,
    selectedLogStatus?: { label: string, value: PaymentLogStatus },
    selectedCustomerIdType?: { label: string, value: GetPaymentLogsPaginatedRequest.PaymentLogType },
    transactionLogsPaginated: ITransactionLogsPaginated
    isFirstSearch: boolean,
    isLoadingLogs: boolean,
    hasTransactionLogs: boolean,
    isCreatedOnDescendingOrder: boolean
}

export const initialState: ITransactionLogsReducerState = {
    searchedTransactions: "",
    transactionLogsPaginated: {
        currentPage: 1,
        pageCount: 1,
        pageSize: PAGINATION_PAGE_SIZE,
        rowCount: 0
    },
    isFirstSearch: true,
    hasTransactionLogs: false,
    isLoadingLogs: false, //TEMP: should be true
    isCreatedOnDescendingOrder: true
}

export default function transactionLogsReducer(state: ITransactionLogsReducerState = initialState, action: BillsbyAction, store: AppState) {
    switch (action.type) {
        case SET_TRANSACTION_LOGS_FIELD:
            return { ...state, [action.fieldName]: action.fieldValue }
        case FETCH_TRANSACTION_LOGS_REQUEST:
            return { ...state, isFirstSearch: !state.transactionLogsPaginated.results, isLoadingLogs: true }
        case FETCH_TRANSACTION_LOGS_SUCCESS:
            const response = action.response as GetPaymentLogsPaginatedResponse;
            const transactionLogsPaginated: ITransactionLogsPaginated = {
                currentPage: response.getCurrentPage(),
                pageCount: response.getPageCount(),
                pageSize: response.getPageSize(),
                rowCount: response.getRowCount(),
                results: response.getPaymentLogsList()
            }
            const hasTransactionLogs = (!!transactionLogsPaginated.results && transactionLogsPaginated.results.length > 0) || state.hasTransactionLogs;
            return { ...state, transactionLogsPaginated, hasTransactionLogs, isLoadingLogs: false }
        case FETCH_TRANSACTION_LOGS_FAILURE:
            return { ...state, isLoadingLogs: false }
        case RESET_TRANSACTION_LOGS_STATE:
            return initialState
        default:
            return state;
    }
}