import React from "react";
import FormGroup from "../../ui/form-group/FormGroup";
import { Row, Col } from "react-grid-system";
import FormLabel from "../../ui/form-label/FormLabel";
import { setIsPlanImageEnabled, setPlanError, uploadPlanImage, setPlanHelpTitleBasic, setPlanHelpContentBasic, } from "../../../actions/productPlanCycleActions";
import counterpart from "counterpart";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../../..";
import Switch from "../../ui/switch/Switch";
import FileUpload from "../../ui/file-upload/FileUpload";
import { validProductImageSize } from "../../../utils/commonUtils";

const PlanImageUploader: React.FC = () => {

  const currentCompanyDomain = useSelector((state: AppState) => state.auth.currentCompanyDomain);
  const plansReducerData = useSelector((state: AppState) => state.plans);

  const dispatch = useDispatch<Function>();

  const { planImageUrl, isPlanImageEnabled, isPlanImageUploading } = plansReducerData;

  const onDrop = (files: Array<File>) => {
    dispatch(setPlanHelpTitleBasic("CREATE_PLAN_HELP_PLAN_IMAGE_TITLE"));
    dispatch(setPlanHelpContentBasic(["CREATE_PLAN_HELP_PLAN_IMAGE_1", "CREATE_PLAN_HELP_PLAN_IMAGE_2"]));
    const image = new Image();
    image.onload = async () => {
      if (validProductImageSize(image)) {
        dispatch(uploadPlanImage(currentCompanyDomain, files));
      } else {
        dispatch(setPlanError(counterpart("PLAN_IMAGE_ERROR")));
      }
    }

    image.src = URL.createObjectURL(files[0])
  }

  return (
    <>
      <FormGroup>
        <Row align="center">
          <Col md={2}>
            <FormLabel target="plan-image-switch" content="CREATE_PLAN_IMAGE" noMargin />
          </Col>
          <Col md={10}>
            <Switch
              className="create-plan__basic-form__switch"
              id="plan-image-switch"
              checked={isPlanImageEnabled}
              onChange={() => {
                dispatch(setIsPlanImageEnabled(!isPlanImageEnabled));
                dispatch(setPlanHelpTitleBasic("CREATE_PLAN_HELP_PLAN_IMAGE_TITLE"));
                dispatch(setPlanHelpContentBasic(["CREATE_PLAN_HELP_PLAN_IMAGE_1", "CREATE_PLAN_HELP_PLAN_IMAGE_2"]));
              }} />
          </Col>
        </Row>
      </FormGroup>
      {isPlanImageEnabled &&
        <FormGroup>
          <Row align="center">
            <Col md={12}>
              <div className="create-plan__file-uploader">
                <FileUpload title={<>
                  {counterpart("CREATE_PRODUCT_IMAGE_UPLOADER_TEXT")} <a className="create-plan__file-uploader__browse">{counterpart("FILE_UPLOADER_BROWSE")}</a>
                </>}
                  subTitle={"CREATE_PRODUCT_IMAGE_UPLOADER_SUB_TEXT"}
                  shouldTranslateTitle={false}
                  image={planImageUrl}
                  onDrop={onDrop}
                  imageAsBackground={true}
                  isLoading={isPlanImageUploading}
                />
              </div>
            </Col>
          </Row>
        </FormGroup>
      }
    </>
  )
}

export default PlanImageUploader;