import React from "react";
import ReactPaginate, { ReactPaginateProps } from "react-paginate";
import "./Pagination.scss";

interface IPagination extends Partial<ReactPaginateProps> {
  className?: string,
  isVisible?: boolean,
  type?: "normal" | "unknownNrOfPages"
}

/* used for type = unknownNrOfPages  */
export enum SelectedPage {
  FIRST = 1,
  PREVIOUS = 2,
  NEXT = 3,
  LAST = 4
}

const Pagination: React.FC<IPagination> = React.memo(({ className = "", isVisible = true, type = "normal", forcePage, onPageChange = () => { }, ...rest }) => {

  if (!isVisible) {
    return <div />;
  }

  if (type === "unknownNrOfPages") {
    const selectedPage = forcePage || 1;
    return (
      <ul className={`pagination ${className}`}>
        <li
          className={`${selectedPage === SelectedPage.FIRST ? "active" : ""}`}
          onClick={() => onPageChange({ selected: SelectedPage.FIRST })}>
          <a><i className="fas fa-fast-backward"></i></a>
        </li>
        <li
          className={`previous ${selectedPage === SelectedPage.FIRST ? "disabled" : ""}`}
          onClick={() => onPageChange({ selected: SelectedPage.PREVIOUS })}>
          <a className="previous-btn" />
        </li>
        <li
          className={`next ${selectedPage === SelectedPage.LAST ? "disabled" : ""}`}
          onClick={() => onPageChange({ selected: SelectedPage.NEXT })}>
          <a className="next-btn" />
        </li>
        <li
          className={`${selectedPage === SelectedPage.LAST ? "active" : ""}`}
          onClick={() => onPageChange({ selected: SelectedPage.LAST })}>
          <a><i className="fas fa-fast-forward"></i></a>
        </li>
      </ul>
    )
  }

  return (
    <ReactPaginate
      containerClassName={`pagination ${className}`}
      forcePage={forcePage}
      onPageChange={onPageChange}
      activeClassName={"active"}
      breakLabel={<span>...</span>}
      previousLabel=""
      nextLabel=""
      previousLinkClassName="previous-btn"
      nextLinkClassName="next-btn"
      disabledClassName="disable-previous-next-btn"
      breakClassName={"break-me"}
      {...rest as ReactPaginateProps}
      marginPagesDisplayed={1}
    />
  )
})

export default Pagination;
