import API from "../utils/API";
import { makeActionCreator, makeGenericActionCreator } from "../utils/reduxUtils";
import { grpcUnaryCall } from "../utils/grpc/grpcUtils";
import { GetEmailNotificationStatusRequest, UpdateEmailNotificationStatusRequest } from "../utils/grpc/generated/Billsby.Protos/Messenger/private/companyconfig/companyconfig_pb";
import { CompanyConfigServiceClient } from "../utils/grpc/generated/Billsby.Protos/Messenger/private/companyconfig/CompanyconfigServiceClientPb";
import { ConfigConstants } from "../utils/config";

export const FETCH_REPLY_ADDRESS_REQUEST = "FETCH_REPLY_ADDRESS_REQUEST";
export const FETCH_REPLY_ADDRESS_SUCCESS = "FETCH_REPLY_ADDRESS_SUCCESS";
export const FETCH_REPLY_ADDRESS_FAILURE = "FETCH_REPLY_ADDRESS_FAILURE";

export const FETCH_EMAIL_NOTIFICATION_TOGGLES_REQUEST = "FETCH_EMAIL_NOTIFICATION_TOGGLES_REQUEST";
export const FETCH_EMAIL_NOTIFICATION_TOGGLES_SUCCESS = "FETCH_EMAIL_NOTIFICATION_TOGGLES_SUCCESS";
export const FETCH_EMAIL_NOTIFICATION_TOGGLES_FAILURE = "FETCH_EMAIL_NOTIFICATION_TOGGLES_FAILURE";

export const UPDATE_EMAIL_NOTIFICATION_TOGGLES_REQUEST = "UPDATE_EMAIL_NOTIFICATION_TOGGLES_REQUEST";
export const UPDATE_EMAIL_NOTIFICATION_TOGGLES_SUCCESS = "UPDATE_EMAIL_NOTIFICATION_TOGGLES_SUCCESS";
export const UPDATE_EMAIL_NOTIFICATION_TOGGLES_FAILURE = "UPDATE_EMAIL_NOTIFICATION_TOGGLES_FAILURE";

export const UPDATE_REPLY_ADDRESS_REQUEST = "UPDATE_REPLY_ADDRESS_REQUEST";
export const UPDATE_REPLY_ADDRESS_SUCCESS = "UPDATE_REPLY_ADDRESS_SUCCESS";
export const UPDATE_REPLY_ADDRESS_FAILURE = "UPDATE_REPLY_ADDRESS_FAILURE";

export const RESET_EMAIL_ROUTING = "RESET_EMAIL_ROUTING";
export const RESET_REPLY_ADDRESS = "RESET_REPLY_ADDRESS"; 
export const SET_EMAIL_ADDRESS_FIELD = "SET_EMAIL_ADDRESS_FIELD";

type AllowedEmailRoutingReducerFields = "forwardingEmailAddress";

export const resetEmailRouting = makeActionCreator(RESET_EMAIL_ROUTING, "payload");
export const resetReplyAddress = makeActionCreator(RESET_REPLY_ADDRESS, "payload");
export const setEmailNotificationsField = makeGenericActionCreator<AllowedEmailRoutingReducerFields>(SET_EMAIL_ADDRESS_FIELD);

export const fetchCompanyForwardingEmail = function(companyDomain: string) {
  return {
    types: [FETCH_REPLY_ADDRESS_REQUEST, FETCH_REPLY_ADDRESS_SUCCESS, FETCH_REPLY_ADDRESS_FAILURE],
    callAPI: () => API.fetchCompanyForwardingEmail(companyDomain)
  };
}

export const updateCompanyForwardingEmail = function(companyDomain: string, data: { forwardingEmailAddress: string }) {
  return {
    types: [UPDATE_REPLY_ADDRESS_REQUEST, UPDATE_REPLY_ADDRESS_SUCCESS, UPDATE_REPLY_ADDRESS_FAILURE],
    callAPI: () => API.updateCompanyForwardingEmail(companyDomain, data)
  };
}

export const fetchEmailNotificationToggles = function(companyId: number) {
  const getEmailNotificationStatus = new GetEmailNotificationStatusRequest();
  getEmailNotificationStatus.setCompanyId(companyId);
  const companyConfigServiceClient = new CompanyConfigServiceClient(ConfigConstants.grpcBaseUrl);
  return {
    types: [FETCH_EMAIL_NOTIFICATION_TOGGLES_REQUEST, FETCH_EMAIL_NOTIFICATION_TOGGLES_SUCCESS, FETCH_EMAIL_NOTIFICATION_TOGGLES_FAILURE],
    callAPI: () => grpcUnaryCall(getEmailNotificationStatus, companyConfigServiceClient, companyConfigServiceClient.getEmailNotificationStatus)
  }
}

export const updateEmailNotificationToggles = function(updateEmailNotificationStatus: UpdateEmailNotificationStatusRequest) {
  const companyConfigServiceClient = new CompanyConfigServiceClient(ConfigConstants.grpcBaseUrl);
  return {
    types: [UPDATE_EMAIL_NOTIFICATION_TOGGLES_REQUEST, UPDATE_EMAIL_NOTIFICATION_TOGGLES_SUCCESS, UPDATE_EMAIL_NOTIFICATION_TOGGLES_FAILURE],
    callAPI: () => grpcUnaryCall(updateEmailNotificationStatus, companyConfigServiceClient, companyConfigServiceClient.updateEmailNotificationStatus)
  }
}