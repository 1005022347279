import React from "react"
import { AppState } from "../../..";
import { useSelector } from "react-redux";
import "./SubscriptionStatusBar.scss";
import Panel from "../../../components/ui/panel/Panel";
import Text from "../../../components/ui/text/Text";
import moment from "moment";
import { Row, Col } from "react-grid-system";
import { SubscriptionStatusType } from "../../../models/Subscriptions";
import { COLOR } from "../../../utils/constants";
import StatusLabel from "../../ui/status-label/StatusLabel";
import Tooltip from "../../ui/tooltip/Tooltip";

const SubscriptionStatusBar: React.FC = () => {
  const subscriptionDetailsData = useSelector((state: AppState) => state.subscriptionDetailsReducer);
  const { status, createdOn, planName, productName, totalRevenueFormatted } = subscriptionDetailsData;
  const createdOnDate = moment(createdOn).format("DD MMM YYYY");

  const getStatusText = (status: SubscriptionStatusType) => {
    switch (status) {
      case SubscriptionStatusType.ACTIVE:
        return "SUBSCRIPTIONS_LIST_TABLE_ACTIVE";
      case SubscriptionStatusType.CANCELLED:
        return "SUBSCRIPTIONS_LIST_TABLE_CANCELLED";
      case SubscriptionStatusType.PAUSED:
        return "SUBSCRIPTIONS_LIST_TABLE_PAUSED";
      case SubscriptionStatusType.INTRIAL:
        return "SUBSCRIPTIONS_LIST_TABLE_INTRIAL";
      case SubscriptionStatusType.SUSPENDED:
        return "SUBSCRIPTIONS_LIST_TABLE_SUSPENDED"
      default:
        return "";
    }
  }

  const getStatusColor = (status: SubscriptionStatusType) => {
    switch (status) {
      case SubscriptionStatusType.ACTIVE:
        return COLOR.GREEN;
      case SubscriptionStatusType.CANCELLED:
        return COLOR.RED;
      case SubscriptionStatusType.PAUSED:
        return COLOR.LIGHT_GREEN;
      case SubscriptionStatusType.INTRIAL:
        return COLOR.ORANGE;
      case SubscriptionStatusType.SUSPENDED:
        return COLOR.LIGHT_RED
      default:
        return undefined;
    }
  }

  return (
    <div className="subscription_status_bar">
      <Row>
        <Col sm={3} className="subscription_status_bar__column subscription_status_bar__column-plan">
          <Panel className="subscription_status_bar flex-centered">
            <div>
              <Text className="subscription_status_bar__item__label-plan" shouldTranslate={false} content={planName} noMargin />
              <Text className="subscription_status_bar__item__value-product" shouldTranslate={false} content={productName} noMargin />
            </div>
          </Panel>
        </Col>
        <Col sm={3} className="subscription_status_bar__column">
          <Panel>
            <Text className="subscription_status_bar__item__label" content="SUBSCRIPTION_STATUS_BAR_SUBSCRIPTION_STATUS" noMargin />
            <StatusLabel className="subscription_status_bar__item__status" content={getStatusText(status)} color={getStatusColor(status)} />
          </Panel>
        </Col>
        <Col sm={3} className="subscription_status_bar__column">
          <Panel>
            <div className="subscription_status_bar-totalrevenue">
              <Text className="subscription_status_bar__item__label" content="SUBSCRIPTION_STATUS_BAR_SUBSCRIPTION_TOTAL_REVENUE" noMargin />
              <i className="far fa-info-circle" data-tip data-for='subscription-revenue-tooltip'/>
              <Tooltip id='subscription-revenue-tooltip' place='bottom' type='blue'>
                <Text content='SUBSCRIPTION_STATUS_BAR_SUBSCRIPTION_TOTAL_REVENUE_TOOLTIP' noMargin />
              </Tooltip>
            </div>
            <Text className="subscription_status_bar__item__value" shouldTranslate={false} content={totalRevenueFormatted} noMargin />
          </Panel>
        </Col>
        <Col sm={3} className="subscription_status_bar__column">
          <Panel>
            <Text className="subscription_status_bar__item__label" content="SUBSCRIPTION_STATUS_BAR_SUBSCRIPTION_SINCE" noMargin />
            <Text className="subscription_status_bar__item__value" shouldTranslate={false} content={createdOnDate} noMargin />
          </Panel>
        </Col>
      </Row>
    </div>
  )
}

export default SubscriptionStatusBar;