import React, { useState, useEffect, FormEvent } from "react";
import { useDispatch, useSelector } from "react-redux";
import FormGroup from "../ui/form-group/FormGroup";
import { Row, Col } from "react-grid-system";
import FormLabel from "../ui/form-label/FormLabel";
import Input from "../ui/input/Input";
import API from "../../utils/API";
import Panel from "../ui/panel/Panel";
import Button from "../ui/button/Button";
import { AppState } from "../..";
import { customFieldsInitialState } from "../../reducers/subscriptionCustomFieldsReducer";
import { CustomFieldType } from "../../models/CustomFields";
import CheckoutDatepicker from "../custom-fields/previews/ui-checkout/CheckoutDatepicker";
import moment, { Moment } from "moment";
import Textarea from "../ui/textarea/Textarea";
import Text from "../ui/text/Text";
import Checkbox from "../ui/checkbox/Checkbox";
import Dropdown from "../ui/dropdown/Dropdown";
import "./EditResponses.scss";
import { setSubscriptonCustomField, resetSubscriptionCustomFields } from "../../actions/subscriptionCustomFieldsActions";

interface IEditResponsesProps {
  handleUpdateCallback?: () => void
}

const EditResponses: React.FC<IEditResponsesProps> = ({ handleUpdateCallback }) => {
  const customFields = useSelector((state: AppState) => state.subscriptionCustomFieldsReducer.customFields);
  const currentCompanyDomain = useSelector((state: AppState) => state.auth.currentCompanyDomain);
  const dispatch = useDispatch<Function>();

  const [isLoading, setIsLoading] = useState(false);
  const [isSuccessful, setIsSuccessful] = useState(false);

  useEffect(() => {
    return () => !isSuccessful && dispatch(resetSubscriptionCustomFields());
  }, [isSuccessful]);

  const onSubmit = async (evt: FormEvent) => {
    evt.preventDefault();

    setIsLoading(true);
    try {
      let allAsync: Array<Promise<unknown>> = []
      customFields.forEach((field, idx) => {
        if (customFieldsInitialState[idx] && customFieldsInitialState[idx].value !== field.value) {
          allAsync.push(API.updateSubscriptionCustomFields(currentCompanyDomain, field.customFieldResponseId, { value: field.value }))
        }
      });
      
      await Promise.all(allAsync);
      setIsSuccessful(true);
      handleUpdateCallback && handleUpdateCallback();
      
    } catch (err) {
      setIsLoading(false);
      setIsSuccessful(false);
      console.log(err);
    }
  };

  const updateCustomFieldValue = (customFieldId: number, value: any) => {
    dispatch(
      setSubscriptonCustomField(
        "customFields",
        customFields.map(field => (field.customField.customFieldId === customFieldId ? { ...field, value } : field))
      )
    );
  };

  const renderCustomFields = () => {
    return (
      <Panel>
        {customFields.map(field => {
          const fieldId = field.customField.customFieldId;
          const labelProps = { content: field.customField.label, target: fieldId.toString(), shouldTranslate: false };
          const descProps = { className: "edit-responses__field-description", content: field.customField.description, shouldTranslate: false };

          switch (field.customField.type) {

            case CustomFieldType.SingleLineTextField:
              return (
                <FormGroup key={fieldId}>
                  <Row align="center">
                    <Col md={12}>
                      <FormLabel {...labelProps} />
                    </Col>
                    <Col md={12}>
                      <Input id={fieldId.toString()} value={field.value || ""} onChange={(evt: any) => updateCustomFieldValue(fieldId, evt.target.value)} />
                      <Text {...descProps}></Text>
                    </Col>
                  </Row>
                </FormGroup>
              );

            case CustomFieldType.MultiLineTextField:
              return (
                <FormGroup key={fieldId}>
                  <Row align="start">
                    <Col md={12}>
                      <FormLabel {...labelProps} />
                    </Col>
                    <Col md={12}>
                      <Textarea
                        className="edit-responses__multiline"
                        id={fieldId.toString()}
                        value={field.value || ""}
                        onChange={(evt: any) => updateCustomFieldValue(fieldId, evt.target.value)}
                      ></Textarea>
                      <Text {...descProps}></Text>
                    </Col>
                  </Row>
                </FormGroup>
              );

            case CustomFieldType.CheckboxField:
              return (
                <FormGroup key={fieldId}>
                  <Row align="start">
                    <Col md={12}>
                      <Checkbox
                        content={
                          <>
                            <Text className="edit-responses__checkbox-label cc-form-label" shouldTranslate={false} content={field.customField.label} />
                            <Text className="edit-responses__checkbox-description" shouldTranslate={false} content={field.customField.description} />
                          </>
                        }
                        value={"checked"}
                        checked={field.value === "true"}
                        onClick={() => updateCustomFieldValue(fieldId, field.value === "true" ? "false" : "true")}
                      ></Checkbox>
                    </Col>
                  </Row>
                </FormGroup>
              );

            case CustomFieldType.NumbersField:
              return (
                <FormGroup key={fieldId}>
                  <Row align="center">
                    <Col md={12}>
                      <FormLabel {...labelProps} />
                    </Col>
                    <Col md={12}>
                      <Input id={fieldId.toString()} type="number" value={field.value || ""} onChange={(evt: any) => updateCustomFieldValue(fieldId, evt.target.value)} />
                      <Text {...descProps}></Text>
                    </Col>
                  </Row>
                </FormGroup>
              );

            case CustomFieldType.DatePickerField:
              return (
                <FormGroup key={fieldId}>
                  <Row align="center">
                    <Col md={12}>
                      <FormLabel {...labelProps} />
                    </Col>
                    <Col md={12}>
                      <CheckoutDatepicker
                        onChange={(date: Moment | null) => {
                          date && updateCustomFieldValue(fieldId, date.format());
                        }}
                        date={field.value ? moment(new Date(field.value)) : moment()}
                      ></CheckoutDatepicker>
                      <Text {...descProps}></Text>
                    </Col>
                  </Row>
                </FormGroup>
              );

            case CustomFieldType.DropdownField:
              const dropdownOptions = field.customField.options.split(",").map((option: string) => ({ value: option, label: option }));
              const selectedValue = dropdownOptions.filter(option => option.value === field.value);

              return (
                <FormGroup key={fieldId}>
                  <Row align="center">
                    <Col md={12}>
                      <FormLabel {...labelProps} />
                    </Col>
                    <Col md={12}>
                      <Dropdown value={selectedValue} id={fieldId.toString()} options={dropdownOptions} onChange={(option: any) => updateCustomFieldValue(fieldId, option.value)}></Dropdown>
                      <Text {...descProps}></Text>
                    </Col>
                  </Row>
                </FormGroup>
              );

            default:
              return null;
          }
        })}
      </Panel>
    );
  };

  return (
    <form className="edit-responses" onSubmit={onSubmit}>
      {renderCustomFields()}
      <Button id="update-custom-fields-value-btn" disabled={false} type="submit" buttonType={"general"} isLoading={isLoading} title={"EDIT_RESPONSE_SAVE_CHANGES"} isFullWidth />
    </form>
  );
};

export default EditResponses;
