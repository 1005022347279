import React, { useState } from "react";
import counterpart from "counterpart";
import FormGroup from "../../../../ui/form-group/FormGroup";
import { Row, Col } from "react-grid-system";
import FormLabel from "../../../../ui/form-label/FormLabel";
import Input from "../../../../ui/input/Input";
import PaymentGatewayButtons from "../payment-gateways-shared-components/PaymentGatewayButtons";
import { isInvalidGatewayFormsDetails } from "../PaymentGateways";

interface Props {
  onSave: (displayName: string, apiLogin: string, password: string) => Promise<void>;
  onCancel: () => void;
  setHelpTitle: (text: string) => void;
  setHelpContent: (text: Array<string>) => void;
  initDisplayName: string;
  initApiLogin: string;
  isSaveEnabled: boolean;
  isEdit: boolean;
}

const AuthorizePaymentGatewayForm: React.FC<Props> = ({ onSave, onCancel, setHelpTitle, setHelpContent, initDisplayName, initApiLogin, isSaveEnabled, isEdit }) => {

  const [displayName, setDisplayName] = useState(initDisplayName);
  const [apiLogin, setApiLogin] = useState(initApiLogin);
  const [password, setPassword] = useState("");

  const clearFields = () => {
    setDisplayName(initDisplayName);
    setApiLogin(initApiLogin);
    setPassword("");
  }

  const savePaymentGateway = async () => {
    await onSave(displayName, apiLogin, password);
    clearFields();
  } 

  const onFocus = (inputField: string) => {
    switch (inputField) {
      case "DisplayName":
        setHelpTitle("PAYMENT_GATEWAYS_AUTHORIZE_DISPLAY_NAME_HELP_TITLE");
        setHelpContent(["PAYMENT_GATEWAYS_AUTHORIZE_DISPLAY_NAME_HELP_CONTENT_1"]);
        break;
      case "ApiLoginId":
        setHelpTitle("PAYMENT_GATEWAYS_AUTHORIZE_API_LOGIN_ID_HELP_TITLE");
        setHelpContent(["PAYMENT_GATEWAYS_AUTHORIZE_API_LOGIN_ID_HELP_CONTENT_1", "PAYMENT_GATEWAYS_AUTHORIZE_API_LOGIN_ID_HELP_CONTENT_2"]);
        break;
      case "Password":
        setHelpTitle("PAYMENT_GATEWAYS_AUTHORIZE_PASSWORD_HELP_TITLE");
        setHelpContent(["PAYMENT_GATEWAYS_AUTHORIZE_PASSWORD_HELP_CONTENT_1"]);
        break;
    }
  }

  return (
      <div className="payment-gateways__update">
        <FormGroup>
          <Row align="center" className="payment-gateways__update__form-group">
            <Col xs={2}>
              <FormLabel target="display-name" content="PAYMENT_GATEWAYS_AUTHORIZE_DISPLAY_NAME" noMargin />
            </Col>
            <Col xs={10}>
              <Input
                onFocus={() => onFocus("DisplayName")}
                id="display-name"
                placeholder={counterpart("PAYMENT_GATEWAYS_AUTHORIZE_DISPLAY_NAME_PLACEHOLDER")}
                value={displayName}
                onChange={(evt: any) => setDisplayName(evt.target.value)}
              />
            </Col>
          </Row>
          <Row align="center" className="payment-gateways__update__form-group">
            <Col xs={2}>
              <FormLabel target="api-login" content="PAYMENT_GATEWAYS_AUTHORIZE_API_LOGIN_ID" noMargin />
            </Col>
            <Col xs={10}>
              <Input
                onMouseOver={() => onFocus("ApiLoginId")}
                id="api-login"
                disabled={isEdit}
                placeholder={counterpart("PAYMENT_GATEWAYS_AUTHORIZE_API_LOGIN_ID_PLACEHOLDER")}
                value={apiLogin}
                onChange={(evt: any) => setApiLogin(evt.target.value)}
              />
            </Col>
          </Row>

          <Row align="center" className="payment-gateways__update__form-group">
            <Col xs={2}>
              <FormLabel target="password" content="PAYMENT_GATEWAYS_AUTHORIZE_PASSWORD" noMargin />
            </Col>
            <Col xs={10}>
              <Input
                onFocus={() => onFocus("Password")}
                type="password"
                id="password"
                placeholder={counterpart("PAYMENT_GATEWAYS_AUTHORIZE_PASSWORD_PLACEHOLDER")}
                value={password}
                onChange={(evt: any) => setPassword(evt.target.value)}
              />
            </Col>
          </Row>
        </FormGroup>

        <PaymentGatewayButtons
          onSave={() => savePaymentGateway()}
          onCancel={() => {
            onCancel();
            clearFields();
          }}
          isEdit={isEdit}
          isSaveEnabled={isSaveEnabled && !isInvalidGatewayFormsDetails([displayName, apiLogin, password])}
        />
      </div>
  )
}

export default AuthorizePaymentGatewayForm;