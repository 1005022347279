import React, { useLayoutEffect } from "react";
import Panel from "../../ui/panel/Panel";
import AmountFormatted from "../../ui/amount-formatted/AmountFormatted";
import {
  BarChart, Bar, Cell, XAxis, CartesianGrid, Tooltip, ResponsiveContainer, TooltipProps,
} from "recharts";
import Text from "../../ui/text/Text";
import ChartTooltip from "../../ui/chart-tooltip/ChartTooltip";
import { DashboardChartType, DashboardChartPeriod, IDashboardChartData } from "../../../models/Dashboard";
import "./DashboardChart.scss";
import moment from "moment";
import counterpart from "counterpart";

interface IDashboardChart {
  type: DashboardChartType,
  period: DashboardChartPeriod,
  chartData?: IDashboardChartData,
  fetchChartsData: (period: DashboardChartPeriod) => any,
  showChart?: boolean
}


const AxisTick: React.FC<any> = React.memo((props) => {
  const { x, y, payload, subText } = props;
  return (
    <g transform={`translate(${x},${y})`}>
      <text x={0} y={0} dy={"0.71em"} textAnchor="middle">{payload.value}</text>
      {subText && <text x={0} y={8} dy={16} fontSize="11px" textAnchor="middle">{subText}</text>}
    </g>
  );
})

const DashboardChart: React.FC<IDashboardChart> = React.memo(({ type, period, chartData, fetchChartsData, showChart = true }) => {

  const BLUE = "#1E2432";
  const ORANGE = "#F87847";

  useLayoutEffect(() => {
    fetchChartsData(period);
  }, [type, period]);

  if (!chartData) {
    return null;
  }

  const noDataAvailable = chartData.total === 0 && chartData.average === 0 && (period === DashboardChartPeriod.SIXMONTHS || period === DashboardChartPeriod.TWELVEMONTHS);

  const getGraph = () => {

    const dataToRender = [...chartData.records, chartData.current];
    if (!showChart || noDataAvailable || !dataToRender) {
      return null;
    }

    //sort from older to newwer
    dataToRender.sort((a, b) => moment(a.date).isAfter(b.date) ? 1 : -1);

    return (
      <ResponsiveContainer width="100%" height={400}>
        <BarChart data={dataToRender} margin={{ top: 5, right: 30, left: 20, bottom: 5 }}>
          <CartesianGrid />
          <XAxis
            width={0}
            tick={(props) => <AxisTick
              subText={props.index === dataToRender.length - 1 && `(${counterpart("DASHBOARD_STATS_TO_DATE")})`} {...props} />
            }
            interval={0}
            dataKey={(entry: { date: string, total: number, totalFormatted: string }) => {
              const date = moment(entry.date);
              const label = period === DashboardChartPeriod.DAILY ? date.format("D") : date.format("MMM");
              return label;
            }} />

          <Tooltip content={(tooltipData: TooltipProps) => (
            <ChartTooltip isActive={tooltipData.active}>
              <AmountFormatted amount={!!tooltipData.payload && !!tooltipData.payload.length && tooltipData.payload[0].payload.totalFormatted} />
            </ChartTooltip>
          )} />
          <Bar dataKey="total">
            {dataToRender.map((entry, index) => (
              <Cell key={index} fill={index !== dataToRender.length - 1 ? BLUE : ORANGE} />
            ))}
          </Bar>
        </BarChart>
      </ResponsiveContainer>
    )
  }

  const getAverageTotalLabel = () => {
    if (!showChart || noDataAvailable) {
      return <Text content="DASHBOARD_STATS_NO_DATA_AVAILABLE" noMargin />
    }
    return (
      <>
        <AmountFormatted amount={chartData.totalFormatted} className="dashboard-chart__amount" />
        <div className="dashboard-chart__average">
          <Text content="DASHBOARD_PAYMENTS_AVERAGE" noMargin />
          <AmountFormatted amount={chartData.averageFormatted} />
        </div>
      </>
    )
  }

  return (
    <Panel className="dashboard-chart">
      {getAverageTotalLabel()}
      {getGraph()}
    </Panel>
  )
})

export default DashboardChart;