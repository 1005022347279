import React, { useLayoutEffect, useState, useRef, useMemo } from "react";
import "./BillingRevenue.scss";

import Panel from "../../ui/panel/Panel";
import Text from "../../ui/text/Text";
import { Row, Col } from "react-grid-system";
import InteractiveList from "../../ui/interactive-list/InteractiveList";
import { GetMonthsRevenueResponse } from "../../../utils/grpc/generated/Billsby.Protos/revenues/private/company/company_pb";
import { BillsbyDataParams, BillsbyDataType, BillsbyDataAction, BILLSBY_BILLING_COMPANY } from "../../../utils/checkout/checkoutIntegrationModel";
import { appendBillsbyDataAttributeToCheckoutLib } from "../../../utils/checkout/checkoutIntegrationUtils";
import { findDOMNode } from "react-dom";
import API from "../../../utils/API";
import { divideNumberBy100 } from "../../../utils/commonUtils";
import moment from "moment";
import ErrorNotification from "../../ui/error-notification/ErrorNotification";
import { Timestamp } from "google-protobuf/google/protobuf/timestamp_pb";
import ProgressIndicator from "../../ui/progress-indicator/ProgressIndicator";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../../..";
import counterpart from "counterpart";
import { GetBillsbySubscriptionRequest, GetBillsbySubscriptionResponse } from "../../../utils/grpc/generated/Billsby.Protos/core/private/companies/company_pb";
import { CompanyServiceClient } from "../../../utils/grpc/generated/Billsby.Protos/core/private/companies/CompanyServiceClientPb";
import { ConfigConstants } from "../../../utils/config";
import { grpcUnaryCall } from "../../../utils/grpc/grpcUtils";
import { BillsbyPlan } from "../../../models/GoLive";
import { CROSS_DOMAIN_EVENTS, ICheckoutEvent } from "../../../utils/checkout/checkoutEvents";
import { fetchCompanyFeaturesState } from "../../../actions/goLiveActions";
import NoticePanel from "../../ui/notice-panel/NoticePanel";
import ProPlanFeatures from "../../../containers/pro-plan-features/ProPlanFeatures";
import { useIsFromSpecificPage } from "../../../utils/custom-hooks";
import { refreshToken } from "../../../utils/authUtils";
import InvalidRole from "../../invalid-role/InvalidRole";
import { Permission } from "../../../models/Auth";
import useCheckFeaturePermission from "../../../utils/hooks/useCheckFeaturePermission";

interface IBillingRevenueProps {
  monthsRevenue?: GetMonthsRevenueResponse
}

interface IUserSubscription {
  nextBillingDate?: Date
}

const BillingRevenue: React.FC<IBillingRevenueProps> = ({ monthsRevenue }) => {
  const auth = useSelector((state: AppState) => state.auth);
  const featuresList = useSelector((state: AppState) => state.goLiveReducer.featuresList);
  const isFromBillingModal = useIsFromSpecificPage("billing-modal");

  const { trialBalance, companyTrialLimit, currentCompanyId } = auth;

  const [nextBillingDate, setNextBillingDate] = useState<string>("");
  const [billsbySubscription, setBillsbySubscription] = useState<GetBillsbySubscriptionResponse | undefined>(undefined);
  const [showFeaturesList, setShowFeaturesList] = useState(isFromBillingModal);
  const [isLoading, setIsLoading] = useState(true);
  const [hasError, setHasError] = useState<boolean>(false);
  const updatePaymentDetailsRef = useRef(null);
  const viewBillingHistoryRef = useRef(null);
  const manageAddonRef = useRef(null);
  const viewAndChangeSubscriptionRef = useRef(null);
  const dispatch = useDispatch<Function>();
  const isBillingReadPermitted = useCheckFeaturePermission(Permission.BillingRead);

  const canChangeSubscription = useMemo(() => {
    return (billsbySubscription?.getPlanId() === BillsbyPlan.PRO && featuresList?.every(i => !i.isUsed)) 
      || billsbySubscription?.getPlanId() === BillsbyPlan.CORE;
  }, [featuresList, billsbySubscription]);


  const fetchSubscriptionDetails = async () => {
    setIsLoading(true);
    try {
      const request = new GetBillsbySubscriptionRequest();
      const serviceClient = new CompanyServiceClient(ConfigConstants.grpcBaseUrl);

      currentCompanyId && request.setCompanyId(currentCompanyId);

      const userSubResponse = await API.getUserSubscription("billsby", auth.currentCompanySubscriptionUniqueId) as IUserSubscription;
      const billsbySubResponse = await grpcUnaryCall(request, serviceClient, serviceClient.getBillsbySubscription) as GetBillsbySubscriptionResponse;
      currentCompanyId && await dispatch(fetchCompanyFeaturesState(currentCompanyId));

      setBillsbySubscription(billsbySubResponse)
      setNextBillingDate(moment(userSubResponse.nextBillingDate).format("DD MMM YYYY"));
    }
    catch {
      setHasError(true);
    }
    finally {
      setIsLoading(false);
    }
  }

  useLayoutEffect(() => {
    appendBillsbyDataAttributeToCheckoutLib({ label: BillsbyDataParams.company, value: BILLSBY_BILLING_COMPANY });
    fetchSubscriptionDetails().catch(console.error);
  }, []);

  const getCheckoutBtns = () => {
    const updatePaymentDetailsProps = {
      [BillsbyDataParams.type]: BillsbyDataType.ACCOUNT,
      [BillsbyDataParams.action]: BillsbyDataAction.UPDATE_PAYMENT_DETAILS,
      [BillsbyDataParams.customer]: auth.currentCompanyCustomerUniqueId,
      [BillsbyDataParams.subscription]: auth.currentCompanySubscriptionUniqueId
    }
    const viewBillingHistoryProps = {
      [BillsbyDataParams.type]: BillsbyDataType.ACCOUNT,
      [BillsbyDataParams.action]: BillsbyDataAction.VIEW_BILLING_HISTORY,
      [BillsbyDataParams.customer]: auth.currentCompanyCustomerUniqueId,
      [BillsbyDataParams.subscription]: auth.currentCompanySubscriptionUniqueId
    }
    const viewAndChangeSubscriptionProps = {
      [BillsbyDataParams.type]: BillsbyDataType.ACCOUNT,
      [BillsbyDataParams.action]: BillsbyDataAction.CHANGE_PLAN,
      [BillsbyDataParams.customer]: auth.currentCompanyCustomerUniqueId,
      [BillsbyDataParams.subscription]: auth.currentCompanySubscriptionUniqueId
    }

    const manageAddonProps = {
      [BillsbyDataParams.type]: BillsbyDataType.ACCOUNT,
      [BillsbyDataParams.action]: BillsbyDataAction.MANAGE_ADDONS,
      [BillsbyDataParams.customer]: auth.currentCompanyCustomerUniqueId,
      [BillsbyDataParams.subscription]: auth.currentCompanySubscriptionUniqueId
    }


    return (
      <div style={{ display: "none" }}>
        <a ref={updatePaymentDetailsRef} {...updatePaymentDetailsProps} />
        <a ref={viewBillingHistoryRef} {...viewBillingHistoryProps} />
        <a ref={manageAddonRef} {...manageAddonProps} />
        <a ref={viewAndChangeSubscriptionRef} {...viewAndChangeSubscriptionProps} />
      </div>
    )
  }

  const onFixFeatures = (evt: React.MouseEvent) => {
    evt.preventDefault();
    setShowFeaturesList(true)
  }

  useLayoutEffect(() => {
    window.scanDomBillsby();
  }, [monthsRevenue, isLoading]);

  const formatAmount = (value: number) => value.toLocaleString();
  const totalRevenue = !!monthsRevenue ? `$${formatAmount(divideNumberBy100(monthsRevenue.getTotalRevenue()) as number)}` : "";
  const billsbyFeePercentage = !!monthsRevenue ? `${divideNumberBy100(monthsRevenue.getBillsbyFeePercentage())}%` : "";
  const billsbyFee = !!monthsRevenue ? `$${divideNumberBy100(monthsRevenue.getBillsbyFeeAmount())}` : "";
  const currentTrialBalance = !!trialBalance ? `$${formatAmount(divideNumberBy100(trialBalance.getTrialBalance()) as number)}` : "";
  const trialExpiredOnDate = !!monthsRevenue ? (monthsRevenue.getTrialExpiredOnDate() as Timestamp).toDate() : undefined;
  const currentDate = new Date();
  const isExpiredInCurrentMonth = !!trialExpiredOnDate && currentDate.getMonth() === trialExpiredOnDate.getMonth()
    && currentDate.getFullYear() === trialExpiredOnDate.getFullYear();

  const renderOnTrialPanel = () => {
    return (
      <>
        {!!monthsRevenue && monthsRevenue.getIsInTrial() && !!trialBalance?.getTrialBalance() ?
          <Panel className="free-trial-ending__title-panel">
            <div className="free-trial-ending__title-panel__text">
              <Text
                content="FREE_TRIAL_ENDING_TITLE_REMAINING_TRIAL"
                translateWith={{ trialBalance: currentTrialBalance }}
                className="free-trial-ending__trial-balance"
              />
              <Text
                content="FREE_TRIAL_ENDING_TITLE_TRIAL_LIMIT"
                translateWith={{ trialLimit: companyTrialLimit }}
                className="free-trial-ending__trial-limit"
              />
              <Text
                content="FREE_TRIAL_ENDING_TITLE_TEXT"
                className="free-trial-ending__title-text"
                translateWith={{ trialLimit: companyTrialLimit }}
                noMargin
              />
            </div>
          </Panel>
          : <>
            {isExpiredInCurrentMonth && !!trialBalance?.getTrialBalance() &&
              <Panel className="free-trial-ending__title-panel">
                <div className="free-trial-ending__title-panel__text">
                  <Text content="FREE_TRIAL_ENDS_THIS_MONTH_TITLE" className="free-trial-ending__title" />

                  <Text
                    content="FREE_TRIAL_ENDS_THIS_MONTH_TEXT_1"
                    className="free-trial-ending__title-text free-trial-ending__title-text-inline"
                    noMargin
                  />
                  <Text
                    content={companyTrialLimit}
                    shouldTranslate={false}
                    className="free-trial-ending__title-text free-trial-ending__title-text-inline free-trial-ending__title-text-bold"
                    noMargin
                  />
                  <Text
                    content="FREE_TRIAL_ENDS_THIS_MONTH_TEXT_2"
                    className="free-trial-ending__title-text free-trial-ending__title-text-inline"
                    noMargin
                  />
                </div>
              </Panel>
            }
          </>
        }
      </>
    )
  }

  if (isLoading) {
    return <ProgressIndicator color="blue" coverage="normal" noPadding />
  }

  const getPlanName = (id?: number) => {
    switch (id) {
      case BillsbyPlan.CORE:
        return "COMPANY_BILLING_MENU_LIVE_PANEL_CONTENT_CORE_PLAN";
      case BillsbyPlan.PRO:
        return "COMPANY_BILLING_MENU_LIVE_PANEL_CONTENT_PRO_PLAN";
      default:
        return "COMPANY_BILLING_MENU_LIVE_PANEL_CONTENT_CORE_LEGACY_PLAN";
    }
  }

  window.onmessage = async (checkoutEvent: ICheckoutEvent) => {
    if([CROSS_DOMAIN_EVENTS.CHANGE_PLAN_SUCCESS, CROSS_DOMAIN_EVENTS.CHANGE_ADDONS_SUCCESS,
    CROSS_DOMAIN_EVENTS.CHANGE_ALLOWANCES_SUCCESS, CROSS_DOMAIN_EVENTS.CHANGE_PAYMENT_DETAILS_SUCCESS]
      .some(evt => evt === checkoutEvent.data.key)) {

      try {
        await fetchSubscriptionDetails()
        await refreshToken(currentCompanyId as number)
      } catch (err) {
        console.log(err)
      }
    }
  }


  if(!isBillingReadPermitted) {
    return <InvalidRole description="INVALID_ROLE_FOR_BILLING"/>
  }

  if (showFeaturesList && featuresList?.some(i => i.isUsed)) {
    return (<div className="fix-features__navbar">
      <div className="navigation-bar__previouspage" onClick={() => setShowFeaturesList(false)}>
        <i className="far fa-chevron-left" />
        <Text content={"GO_LIVE_PRO_PLAN_FEATURE_NAVBAR"} noMargin />
      </div>
      <ProPlanFeatures historyState={{ from: "billing-modal" }} />
    </div>)
  }

  return (
    <div>
      <ErrorNotification
        title="TRIAL_BALANCE_ERROR"
        showAlert={hasError}
        isModal={true}
        onClose={() => setHasError(false)}
      />
      {getCheckoutBtns()}
      {/* {renderOnTrialPanel()} */}
      <Panel className="free-trial-ending__title-panel">
        <div className="free-trial-ending__title-panel__text">
          <Text content="COMPANY_BILLING_MENU_LIVE_PANEL_CONTENT" translateWith={{
            plan: counterpart(getPlanName(billsbySubscription?.getPlanId())),
            allowance: billsbySubscription?.getAllowanceAmountFormatted(),
            fee: billsbySubscription?.getAllowanceOverage()
          }} />
          <Text className="free-trial-ending__title-panel__text--blue" content="COMPANY_BILLING_MENU_LIVE_PANEL_NEXT_BILLING_DATE" noMargin translateWith={{ nextBillingDate: <span className="bold">{nextBillingDate}</span> }}></Text>
        </div>
      </Panel>
      <div className="subscription_status_bar" style={{ display: "none" }}>
        <Row>
          <Col sm={3} className="subscription_status_bar__column subscription_status_bar__column-plan">
            <Panel className="subscription_status_bar flex-centered">
              <div>
                <Text className="subscription_status_bar__item__label" content="FREE_TRIAL_ENDING_NEXT_BILLING_DATE" noMargin />
                <Text className="subscription_status_bar__item__value" shouldTranslate={false} content={nextBillingDate} noMargin />
              </div>
            </Panel>
          </Col>
          <Col sm={3} className="subscription_status_bar__column">
            <Panel>
              <Text className="subscription_status_bar__item__label" content="FREE_TRIAL_ENDING_REVENUE_GENERATED" noMargin />
              <Text className="subscription_status_bar__item__value" shouldTranslate={false} content={totalRevenue} noMargin />
            </Panel>
          </Col>
          <Col sm={3} className="subscription_status_bar__column">
            <Panel>
              <Text className="subscription_status_bar__item__label" content="FREE_TRIAL_ENDING_REVENUE_SHARE" noMargin />
              <Text className="subscription_status_bar__item__value" shouldTranslate={false} content={billsbyFeePercentage} noMargin />
            </Panel>
          </Col>
          <Col sm={3} className="subscription_status_bar__column">
            <Panel>
              <Text className="subscription_status_bar__item__label" content="FREE_TRIAL_ENDING_BILLSBY_FEE" noMargin />
              <Text className="subscription_status_bar__item__value" shouldTranslate={false} content={billsbyFee} noMargin />
            </Panel>
          </Col>
        </Row>
      </div>

      {/* <Table className="free-trial-ending__table">
        <tbody>
          <tr>
            <td>
              <div>
                <Text className="free-trial-ending__item-label" content="FREE_TRIAL_ENDING_ADDRESS_VALIDATION" noMargin />
                <Text content="FREE_TRIAL_ENDING_ADDRESS_VALIDATION_DESCRIPTION" noMargin />
              </div>
            </td>
            <td>
              <Text content="126" className="free-trial-ending__item-value" shouldTranslate={false} noMargin />
            </td>
            <td>
              <Text content="$6.30" className="free-trial-ending__item-value" shouldTranslate={false} noMargin />
            </td>
          </tr>
          <tr>
            <td>
              <div>
                <Text className="free-trial-ending__item-label" content="FREE_TRIAL_ENDING_AUTOMATIC_TAX_CALCULATIONS" noMargin />
                <Text content="FREE_TRIAL_ENDING_AUTOMATIC_TAX_CALCULATIONS_DESCRIPTION" noMargin />
              </div>
            </td>
            <td>
              <Text content="563" className="free-trial-ending__item-value" shouldTranslate={false} noMargin />
            </td>
            <td>
              <Text content="$11.26" className="free-trial-ending__item-value" shouldTranslate={false} noMargin />
            </td>
          </tr>
        </tbody>
      </Table> */}

      {/*
      HIDE FOR NOW
      <Panel className="free-trial-ending__pro-feature-panel">
        <img src={billsbyPro} className="free-trial-ending__pro-feature-image" />
        <Button
          className="free-trial-ending__pro-feature-button"
          id="free-trial-ending-pro-feature-button"
          buttonType='general'
          title="FREE_TRIAL_ENDING_FIRST_PRO_FEATURE_BUTTON"
          icon="far fa-chevron-right"
          iconPosition="right"
          onClick={() => { }}
        ></Button>
    </Panel>*/}
      <NoticePanel 
        className="fix-features" 
        type="warning" 
        content="GO_LIVE_CORE_PLAN_WARNING" 
        translateWith={{
          link: <a className="fix-features__link" href="" onClick={onFixFeatures}>{counterpart("GO_LIVE_SELECT_PLAN_SUBTITLE_HERE")}</a>
        }}
        isVisible={billsbySubscription?.getPlanId() === BillsbyPlan.PRO && !canChangeSubscription}
      />
      <InteractiveList className="free-trial-ending__list"
        data={[
          {
            title: "BILLING_CHANGE_PLAN_PAYMENT_DETAILS",
            subTitle: "BILLING_CHANGE_PLAN_PAYMENT_DETAILS_DESCRIPTION",
            isLocked: !canChangeSubscription,
            translateSubtitleWith: {
              link: <a id="pro-features-link" href="https://www.billsby.com/pricing" onClick={(evt: any) => evt.stopPropagation()}>
                {counterpart("BILLING_CHANGE_PLAN_PAYMENT_DETAILS_LINK")}
              </a>
            },
            onClick: () => {
              const viewAndChangeSubscriptionDomEl = findDOMNode(viewAndChangeSubscriptionRef.current);
              viewAndChangeSubscriptionDomEl && (viewAndChangeSubscriptionDomEl as HTMLElement).click();
            }
          },
          {
            title: "FREE_TRIAL_ENDING_MANAGE_ADDONS",
            subTitle: "FREE_TRIAL_ENDING_MANAGE_ADDONS_DESCRIPTION",
            onClick: () => {
              const manageAddonDomEl = findDOMNode(manageAddonRef.current);
              (manageAddonDomEl as HTMLElement)?.click();
            }
          },
          {
            title: "FREE_TRIAL_ENDING_UPDATE_PAYMENT_DETAILS",
            subTitle: "FREE_TRIAL_ENDING_UPDATE_PAYMENT_DETAILS_DESCRIPTION",
            onClick: () => {
              const updatePaymentDetailsDomEl = findDOMNode(updatePaymentDetailsRef.current);
              updatePaymentDetailsDomEl && (updatePaymentDetailsDomEl as HTMLElement).click();
            }
          },
          {
            title: "FREE_TRIAL_ENDING_VIEW_BILLING_HISTORY",
            subTitle: "FREE_TRIAL_ENDING_VIEW_BILLING_HISTORY_DESCRIPTION",
            onClick: () => {
              const viewBillingHistoryDomEl = findDOMNode(viewBillingHistoryRef.current);
              viewBillingHistoryDomEl && (viewBillingHistoryDomEl as HTMLElement).click();
            }
          }
        ]}
      />

    </div>
  )
}

export default BillingRevenue;