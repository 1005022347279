import React, { useState, useEffect } from "react";
import Text from "../../../../ui/text/Text";
import Button from "../../../../ui/button/Button";
import { grpcUnaryCall } from "../../../../../utils/grpc/grpcUtils";
import { SendTestWebhookRequest, WebhookType } from "../../../../../utils/grpc/generated/Billsby.Protos/webhooks/private/webhooktests/webhook_tests_pb";
import { WebhookTestsServiceClient } from "../../../../../utils/grpc/generated/Billsby.Protos/webhooks/private/webhooktests/Webhook_testsServiceClientPb";
import { useSelector } from "react-redux";
import { AppState } from "../../../../..";
import ErrorNotification from "../../../../ui/error-notification/ErrorNotification";
import { ConfigConstants } from "../../../../../utils/config";

interface IWebhookTestPanelProps {
  data: { title: string; content: string; apiUrl: string, type: WebhookType },
}

const WebhookTestPanel: React.FC<IWebhookTestPanelProps> = (props: IWebhookTestPanelProps) => {
  const currentCompanyId = useSelector((state: AppState) => state.auth.currentCompanyId);

  const [isSent, setIsSent] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const { title, content, type } = props.data;
  const timeout = 3000;
  let testTimeout: ReturnType<typeof setTimeout>


  const handleTest = (webhookType: WebhookType) => {
    if (isSent) {
      return;
    }    
    const sendTestWebhookRequest = new SendTestWebhookRequest();
    sendTestWebhookRequest.setCompanyId(currentCompanyId as number);
    sendTestWebhookRequest.setWebhookType(webhookType);
    const webhookTestsServiceClient = new WebhookTestsServiceClient(ConfigConstants.grpcBaseUrl);
    grpcUnaryCall(sendTestWebhookRequest, webhookTestsServiceClient, webhookTestsServiceClient.sendTestWebhook).then(res => {

    }, err => {  
      setHasError(true); 
      setErrorMessage(err.message);
    });
    setIsSent(true);
    if(!testTimeout) {
      testTimeout = setTimeout(() => {
        setIsSent(false);
      }, timeout);
    }
  };

  useEffect(() => {
    return () => clearTimeout(testTimeout)
  }, [])

  return (
    <div className="webhooks__test-panel">
      <div>
        <Text className="webhooks__test-panel__title" content={title} noMargin />
        <Text className="webhooks__test-panel__content" content={content} noMargin />
      </div>
      <Button
        className={`webhooks__test-panel__button${isSent ? " webhooks__test-panel__button--sent" : ""}`}
        icon={isSent ? "far fa-check" : "fal fa-hard-hat"}
        buttonType="add"
        id="webhook-test"
        title={`CONFIG_WEBHOOKS_${isSent ? "SENT" : "TEST"}`}
        onClick={() => handleTest(type as 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9)}
      />      
      <ErrorNotification title={errorMessage} shouldTranslateTitle={false} showAlert={hasError} />
    </div>
  );
};

export default WebhookTestPanel;
