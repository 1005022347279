import React, { useState } from "react";
import counterpart from "counterpart";
import FormGroup from "../../../../ui/form-group/FormGroup";
import { Row, Col } from "react-grid-system";
import FormLabel from "../../../../ui/form-label/FormLabel";
import Input from "../../../../ui/input/Input";
import PaymentGatewayButtons from "../payment-gateways-shared-components/PaymentGatewayButtons";
import { isInvalidGatewayFormsDetails } from "../PaymentGateways";

interface Props {
  onSave: (displayName: string, secretKey: string) => Promise<void>;
  onCancel: () => void;
  setHelpTitle: (text: string) => void;
  setHelpContent: (text: Array<string>) => void;
  initDisplayName: string;
  isSaveEnabled: boolean;
  isEdit: boolean;
}

const CheckoutPaymentGatewayForm: React.FC<Props> = ({ onSave, onCancel, setHelpTitle, setHelpContent, initDisplayName, isSaveEnabled, isEdit }) => {

  const [displayName, setDisplayName] = useState(initDisplayName);
  const [secretKey, setSecretKey] = useState("");

  const clearFields = () => {
    setDisplayName(initDisplayName);
    setSecretKey("");
  }

  const savePaymentGateway = async () => {
    await onSave(displayName, secretKey);
    clearFields();
  } 

  const onFocus = (inputField: string) => {
    switch (inputField) {
      case "DisplayName":
        setHelpTitle("PAYMENT_GATEWAYS_ADDED_CHECKOUT_DISPLAY_NAME_HELP_TITLE");
        setHelpContent(["PAYMENT_GATEWAYS_ADDED_CHECKOUT_DISPLAY_NAME_HELP_CONTENT_1"]);
        break;
      case "SecretKey":
        setHelpTitle("PAYMENT_GATEWAYS_ADDED_BCHECKOUT_SECRET_KEY_HELP_TITLE");
        setHelpContent(["PAYMENT_GATEWAYS_ADDED_CHECKOUT_SECRET_KEY_HELP_CONTENT_1", "PAYMENT_GATEWAYS_ADDED_CHECKOUT_SECRET_KEY_HELP_CONTENT_2"]);
        break;
    }
  }

  return (
      <div className="payment-gateways__update">
        <FormGroup>
          <Row align="center" className="payment-gateways__update__form-group">
            <Col xs={2}>
              <FormLabel target="display-name" content="PAYMENT_GATEWAYS_CHECKOUT_DISPLAY_NAME" noMargin />
            </Col>
            <Col xs={10}>
              <Input
                id="display-name"
                onFocus={() => onFocus("DisplayName")}
                placeholder={counterpart("PAYMENT_GATEWAYS_CHECKOUT_DISPLAY_NAME_PLACEHOLDER")}
                value={displayName}
                onChange={(evt: any) => setDisplayName(evt.target.value)}
              />
            </Col>
          </Row>
          <Row align="center" className="payment-gateways__update__form-group">
            <Col xs={2}>
              <FormLabel target="secret-key" content="PAYMENT_GATEWAYS_CHECKOUT_SECRET_KEY" noMargin />
            </Col>
            <Col xs={10}>
              <Input
                id="secret-key"
                onFocus={() => onFocus("SecretKey")}
                type="password"
                placeholder={"********"}
                value={secretKey}
                onChange={(evt: any) => setSecretKey(evt.target.value)}
              />
            </Col>
          </Row>
        </FormGroup>

        <PaymentGatewayButtons
          onSave={() => savePaymentGateway()}
          onCancel={() => {
            onCancel();
            clearFields();
          }}
          isEdit={isEdit}
          isSaveEnabled={isSaveEnabled && !isInvalidGatewayFormsDetails([displayName, secretKey])}
        />
      </div>
  )
}

export default CheckoutPaymentGatewayForm;