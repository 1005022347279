import React from "react";
import PageWrapper from "../../components/ui/layout/PageWrapper";
import Panel from "../../components/ui/panel/Panel";
import Text from "../../components/ui/text/Text";
import FormGroup from "../../components/ui/form-group/FormGroup";
import InteractiveList from "../../components/ui/interactive-list/InteractiveList";
import ErrorPanel from "../../components/ui/error-panel/ErrorPanel";
import { COLOR } from "../../utils/constants";
import "./ReportsCheckoutSelfService.scss";
import { useHistory } from "react-router";

const ReportsCheckoutSelfService: React.FC = () => {
  const history = useHistory();

  return (
    <PageWrapper className="reports-checkout-self-service">
      <Panel title="REPORTS_CHECKOUT_SELF_SERVICE_HEADING_TITLE" className="reports-checkout-self-service__heading">
        <Text content="REPORTS_CHECKOUT_SELF_SERVICE_HEADING_BODY" noMargin />
      </Panel>

      <FormGroup>
        <InteractiveList
          data={[
            // {
            //   title: "REPORTS_CHECKOUT_SELF_SERVICE_CHECKOUT_PERFORMANCE_TITLE",
            //   subTitle: "REPORTS_CHECKOUT_SELF_SERVICE_CHECKOUT_PERFORMANCE_SUBTITLE",
            //   onClick: () => { history.push('/report-accounting/activity-summary') },
            //   statusLabel: "REPORTS_CUSTOMERS_MONTHLY_LABEL",
            //   statusLabelColor: COLOR.BLUE
            // },
            {
              title: "REPORTS_CHECKOUT_SELF_SERVICE_EXIT_REASONS_TITLE",
              subTitle: "REPORTS_CHECKOUT_SELF_SERVICE_EXIT_REASONS_SUBTITLE",
              onClick: () => { history.push("/report-checkout-self-service/exit-reasons") },
              statusLabel: "REPORTS_CUSTOMERS_MONTHLY_LABEL",
              statusLabelColor: COLOR.BLUE
            }
          ]}
        />
      </FormGroup>

      <ErrorPanel
        title="REPORTS_CUSTOMERS_FOOTER_TITLE"
        subtitle="REPORTS_CUSTOMERS_FOOTER_SUBTITLE"
        icon="fas fa-analytics"
      />
    </PageWrapper>
  )
}

export default ReportsCheckoutSelfService;