import React from "react";
import Panel from "../panel/Panel";
import Text from "../text/Text";
import "./BackNavigationPanel.scss";

interface IBackNavigationPanel {
  className?: string,
  effectOnHover?: boolean,
  title: string,
  isLocked?: boolean,
  shouldTranslateTitle?: boolean,
  isElementClickable?: boolean,
  onClick: () => void,
  children?: JSX.Element
}

const getListElementClass = (effectOnHover: boolean, isLocked: boolean, isElementClickable: boolean, children?: JSX.Element) => {
  let className = "back-navigation-panel__element";
  if (effectOnHover) {
    className += " back-navigation-panel__element--hover";
  }
  if (isLocked) {
    className += " back-navigation-panel__element--locked";
  }
  if (isElementClickable) {
    className += " back-navigation-panel__element--clickable";
  }

  if (children) {
    className += " back-navigation-panel__element--no-bottom-border";
  }

  return className;
}

const BackNavigationPanel: React.FC<IBackNavigationPanel> = React.memo(({ className = "", isLocked = false, shouldTranslateTitle = true, isElementClickable = true, effectOnHover = false, title = "", onClick = () => { }, children }) => {
  return (
    <Panel className={`back-navigation-panel ${className}`} noMargin>
      <Panel className={getListElementClass(effectOnHover, isLocked, isElementClickable, children)}
        onClick={(isElementClickable && onClick) ? () => onClick : () => { }} noMargin>
        <div onClick={onClick}>
          {onClick && <i className='far fa-chevron-left' />}
          <div>
            <Text content={title} shouldTranslate={shouldTranslateTitle} className="back-navigation-panel__element__title" noMargin />
          </div>
        </div>
      </Panel>
      {children && <Panel className="back-navigation-panel__element__children">
        {children}
      </Panel>}
    </Panel>
  )
})

export default BackNavigationPanel;