import React from "react";
import Panel from "../../../components/ui/panel/Panel";
import Text from "../../../components/ui/text/Text";
import Button from "../../ui/button/Button";
import { openIntercomIframe } from "../../../utils/commonUtils";
import "./PreLiveMenu.scss";
import { useSelector } from "react-redux";
import { AppState } from "../../..";

interface IPreLiveMenuProps {
  onRequestClose: Function,
  menuName: string
}

const PreLiveMenu: React.FC<IPreLiveMenuProps> = ({ onRequestClose, menuName }) => {
  const companyTrialLimit = useSelector((state: AppState) => state.auth.companyTrialLimit)
  
  return (
    <div>
      <Text content="PRE_LIVE__MENU_CONTENT_WHEN_YOU_GO_LIVE" translateWith={{ trialLimit: companyTrialLimit }}/>
      <div className="pre_live_menu_content_info_panel">
        <Panel className="pre_live_menu_content_info_panel_item">
          <Text content="PRE_LIVE_MENU_CONTENT_INFO_PANEL_BILLSBY" noMargin />
          <Text className="pre_live_menu_content_info_panel_item_value orange" content="PRE_LIVE_MENU_CONTENT_INFO_PANEL_BILLSBY_VALUE" noMargin />
        </Panel>

        <Panel className="pre_live_menu_content_info_panel_item">
          <Text content="PRE_LIVE_MENU_CONTENT_INFO_PANEL_CHARGEBEE_RISE" noMargin />
          <Text className="pre_live_menu_content_info_panel_item_value" content="PRE_LIVE_MENU_CONTENT_INFO_PANEL_CHARGEBEE_RISE_VALUE" noMargin />
        </Panel>

        <Panel className="pre_live_menu_content_info_panel_item">
          <Text content="PRE_LIVE_MENU_CONTENT_INFO_PANEL_RECURLY_CORE" noMargin />
          <Text className="pre_live_menu_content_info_panel_item_value" content="PRE_LIVE_MENU_CONTENT_INFO_PANEL_RECURLY_CORE_VALUE" noMargin />
        </Panel>
      </div>

      <div className="pre_live_menu_content_footer">
        <Text className="pre_live_menu_content_footer_text" content="PRE_LIVE_MENU_CONTENT_FOOTER_CONTENT" noMargin />
      </div>

      <Button id={`${menuName}-go-live-button`} className="pre_live_menu_content_footer_go_live_button" buttonType="add" title="PRE_LIVE_MENU_CONTENT_FOOTER_GO_LIVE_BUTTON" onClick={onRequestClose()} />
      <Button id={`${menuName}-talk-to-us-button`} className="pre_live_menu_content_footer_contact_button" icon="fal fa-headset" buttonType="add" title="PRE_LIVE_MENU_CONTENT_FOOTER_CONTACT_BUTTON" onClick={openIntercomIframe} />
    </div>
  )
}

export default PreLiveMenu;