import React, { FormEvent, useState } from "react";
import { Row, Col } from "react-grid-system";
import { useDispatch, useSelector } from "react-redux";
import Panel from "../../ui/panel/Panel";
import Text from "../../ui/text/Text";
import Button from "../../ui/button/Button";
import Input from "../../../components/ui/input/Input";
import FormLabel from "../../../components/ui/form-label/FormLabel";
import NoticePanel from "../../../components/ui/notice-panel/NoticePanel";
import { fetchTaxJarIntegrationProductCategories } from "../../../actions/companyTaxRatesActions";
import "./TaxJarTokenIntegration.scss";
import { AppState } from "../../..";
import TaxJarCompleteIntegration from "./taxjar-complete-integration/TaxJarCompleteIntegration";


const TaxJarTokenIntegration: React.FC = () => {
  const companyTaxRatesReducer = useSelector((state: AppState) => state.companyTaxRatesReducer);
  
  const dispatch = useDispatch<Function>();

  const [state, setState] = useState({
    apiToken: "",
    hasError: false,
    incorrectToken: false,
    success: false,
    isLoadingData: false,
    isLoading: false
  });
  const { apiToken, hasError, incorrectToken, success, isLoadingData, isLoading } = state;

  const onSubmit = async (evt: FormEvent) => {
    evt.preventDefault();
    evt.stopPropagation();

    setState({ ...state, isLoading: true, hasError: false, incorrectToken: false });

    try {
      const { error } = await dispatch(fetchTaxJarIntegrationProductCategories(apiToken));
      if (error) {
        setState({ ...state, incorrectToken: true });
      }
    } catch (err) {
      setState({ ...state, hasError: true });
    }
  };

  const renderErrorNoticePanel = () => {
    if (hasError || incorrectToken) {
      return <NoticePanel icon="fas fa-exclamation-circle" type="error" isModal content={`TAXJAR_INTEGRATION_${hasError ? "ERROR" : "INCORRECT_ERROR"}`} />;
    } else return null;
  };

  if (isLoadingData) {
    return null;
  }

  if (companyTaxRatesReducer.productCategories && !hasError) {
    return <TaxJarCompleteIntegration apiToken={apiToken} />;
  }

  return (
    <>
      {renderErrorNoticePanel()}
      <form className="taxjar-integration" onSubmit={onSubmit}>
        <Panel className="taxjar-integration__titlepanel" title="TAXJAR_INTEGRATION_PANEL_TITLE">
          <Text className="taxjar-integration__content" content="TAXJAR_INTEGRATION_PANEL_CONTENT" noMargin />
          <Button
            id="start-free-trial"
            className="taxjar-integration__start-free-trial"
            buttonType="add"
            title="TAXJAR_INTEGRATION_START_FREE_TRIAL"
            onClick={(evt: any) => {
              evt.stopPropagation();
              window.open("https://app.taxjar.com/sign_up", "_self");
            }}
          />
        </Panel>

        <Panel>
          <div>
            <Row align="center">
              <Col md={2}>
                <FormLabel target="api-token" content="TAXJAR_INTEGRATION_API_TOKEN" noMargin />
              </Col>
              <Col md={10}>
                <Input
                  disabled={isLoading}
                  required
                  id="api-token"
                  type="text"
                  placeholder="123-456-7890"
                  value={apiToken}
                  onChange={(evt: any) => setState({ ...state, apiToken: evt.target.value })}
                />
              </Col>
            </Row>
          </div>
        </Panel>

        <Button
          id="taxjar-integration"
          type="submit"
          disabled={!apiToken || isLoading}
          icon={success ? "far fa-check-circle" : ""}
          buttonType={success ? "success" : "general"}
          isLoading={isLoading && (!hasError || !incorrectToken)}
          title="TAXJAR_INTEGRATION_INTEGRATE"
          isFullWidth
        />
      </form>
    </>
  );
};

export default TaxJarTokenIntegration;
