import React, { useEffect, useMemo, useState } from "react";
import Panel from "../ui/panel/Panel";
import Text from "../ui/text/Text";
import FormGroup from "../ui/form-group/FormGroup";
import Button from "../ui/button/Button";
import { InvoiceStatus, StandAloneInvoiceType } from "../../models/Invoices";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../..";
import { reattemptInvoicePayment, fetchInvoiceStandalone, REPLACE_PAYMENT_CARD_FAILURE, replacePaymentCardSCA } from "../../actions/invoiceStandaloneActions";
import { delay, getCardText, isValidJSON } from "../../utils/commonUtils";
import moment from "moment";
import counterpart from "counterpart";
import InvoiceStandaloneSidebarOutputPanel from "./InvoiceStandaloneOutputPanel";
import { CardSCAStatus, PaymentMethodType } from "../../models/Customer";
import { appendBillsbyDataAttributeToCheckoutLib } from "../../utils/checkout/checkoutIntegrationUtils";
import { BillsbyDataAction, BillsbyDataParams, BillsbyDataType } from "../../utils/checkout/checkoutIntegrationModel";
import { CROSS_DOMAIN_EVENTS, ICheckoutEvent } from "../../utils/checkout/checkoutEvents";

const InvoiceStandaloneSidebarHome: React.FC = () => {
  const invoiceStandaloneReducer = useSelector((state: AppState) => state.invoiceStandaloneReducer);

  const dispatch = useDispatch<Function>();
  const { invoice, reattemptPaymentInvoiceFailure, replacePaymentCardFailure, customerLanguage } = invoiceStandaloneReducer;
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const reattemptPaymentAfterRedirection = async () => {
      const isValid = await isValidJSON(sessionStorage.invoiceStandaloneSCA);
      if (!invoice || !isValid) {
        return;
      }
      const invoiceStandaloneSCA = JSON.parse(sessionStorage.invoiceStandaloneSCA);
      const { replacePaymentCardSCAPayload, newCardData } = invoiceStandaloneSCA;

      if (!replacePaymentCardSCAPayload.transactionToken || invoice.status === InvoiceStatus.PAID || invoice.status === InvoiceStatus.PENDING) {
        return;
      }

      if (replacePaymentCardSCAPayload.status === CardSCAStatus.Unassigned) {
        return dispatch({ type: REPLACE_PAYMENT_CARD_FAILURE })
      }

      if (replacePaymentCardSCAPayload.status === CardSCAStatus.UpdateCompleted) {
        await dispatch(reattemptInvoicePayment(invoice.companyDomain, invoice.invoiceNumber));
        await dispatch(fetchInvoiceStandalone(invoice.invoiceNumber));
        return;
      }

      setIsLoading(true);

      try {
        await dispatch(replacePaymentCardSCA(invoice.companyDomain, invoice.customerUniqueId, newCardData)).then(async (res: any) => {
          if (!res.error) {
            await dispatch(reattemptInvoicePayment(invoice.companyDomain, invoice.invoiceNumber));
            await dispatch(fetchInvoiceStandalone(invoice.invoiceNumber));
          }
        })
      } catch (err) {
        return dispatch({ type: REPLACE_PAYMENT_CARD_FAILURE })
      } finally {
        setIsLoading(false);
        sessionStorage.removeItem("invoiceStandaloneSCA");
      }
    }
    reattemptPaymentAfterRedirection();
  }, [])

  const isCardExpiring = useMemo(() => {
    if (!invoice  || invoice.customerPaymentMethodTypeName === PaymentMethodType.ACH) { return false }
    const expirationCard = moment().utc();
    expirationCard.set("year", invoice.cardExpiryYear);
    expirationCard.set("month", (invoice.cardExpiryMonth - 1));
    // get last day of that specific month
    expirationCard.set("date", expirationCard.daysInMonth());
    const now = moment();
    const daysToExpiration = moment.duration(expirationCard.diff(now)).asDays();
    if (daysToExpiration <= 7) {
      return true;
    }
    return false;
  }, [invoice]);

  if (!invoice || isLoading) { return null }

  const isCreditNote = [StandAloneInvoiceType.ONE_TIME_CREDIT, StandAloneInvoiceType.REFUND].includes(invoice.invoiceType as StandAloneInvoiceType);

  const getSummaryPanel = () => {
    if (reattemptPaymentInvoiceFailure || replacePaymentCardFailure) {
      return (
        <Panel className="invoice-standalone-sidebar-error">
          <Text
            locale={customerLanguage}
            content={reattemptPaymentInvoiceFailure ? "INVOICE_STANDALONE_REATTEMPT_PAYMENT_CARD_ERROR_TITLE" : "INVOICE_STANDALONE_REPLACE_PAYMENT_CARD_ERROR_TITLE"}
            noMargin
          />
          <Text
            locale={customerLanguage}
            content={reattemptPaymentInvoiceFailure ? "INVOICE_STANDALONE_REATTEMPT_PAYMENT_CARD_ERROR_SUBTITLE" : "INVOICE_STANDALONE_REPLACE_PAYMENT_CARD_ERROR_SUBTITLE"}
            noMargin
          />
        </Panel>
      )
    }

    return (
      <InvoiceStandaloneSidebarOutputPanel locale={customerLanguage} invoice={invoice} />
    )
  }

  const getCheckoutBtn = () => {
    const updatePaymentMethodProps = {
      [BillsbyDataParams.type]: BillsbyDataType.ACCOUNT,
      [BillsbyDataParams.customer]: invoice.customerUniqueId,
      [BillsbyDataParams.language]: "en",
      [BillsbyDataParams.action]: BillsbyDataAction.UPDATE_PAYMENT_DETAILS
    }

    return (
      <div style={{ display: "none" }}>
        <a
          onClick={e => e.preventDefault()}
          id={`update-payment-${invoice.customerUniqueId}`}
          {...updatePaymentMethodProps}
        />
      </div>
    )
  }

  window.onmessage = async (checkoutEvent: ICheckoutEvent) => {
    if (invoice && checkoutEvent.data.key === CROSS_DOMAIN_EVENTS.CHANGE_PAYMENT_DETAILS_SUCCESS) {
      try {
        await dispatch(fetchInvoiceStandalone(invoice.invoiceNumber));
      } catch (err) {
        console.log(err)
      }
    }
  }


  return (
    <>
      {getSummaryPanel()}

      {
        [InvoiceStatus.UNPAID, InvoiceStatus.FAILED, InvoiceStatus.WRITTEN_OFF, InvoiceStatus.TOBEWRITTEN_OFF].includes(invoice.status)
        && !isCreditNote && !reattemptPaymentInvoiceFailure && (
          <FormGroup>
            <Panel className="invoice-standalone-sidebar-type2">
              <Text locale={customerLanguage} content="INVOICE_STANDALONE_CHARGE_CARD_TITLE" />
              <Text locale={customerLanguage} content="INVOICE_STANDALONE_CHARGE_CARD_SUBTITLE" translateWith={{ cardType: getCardText(invoice.cardType), lastDigits: invoice.cardLast4Digits }} noMargin />
            </Panel>

            <Button
              id="charge-visa"
              title="INVOICE_STANDALONE_CHARGE_CARD_BTN"
              locale={customerLanguage}
              isFullWidth
              buttonType="orange"
              icon="far fa-arrow-right"
              iconPosition="right"
              translateWith={{ cardType: invoice.cardType.toLocaleUpperCase(), amount: invoice.formattedAmount, lastDigits: invoice.cardLast4Digits }}
              onClick={() => delay(400).then(() => dispatch(reattemptInvoicePayment(invoice.companyDomain, invoice.invoiceNumber)))
                .then((resp) => {
                  if (!resp.error) {
                    dispatch(fetchInvoiceStandalone(invoice.invoiceNumber))
                  }
                })}
            />
          </FormGroup>
        )
      }

      {
        ([InvoiceStatus.UNPAID, InvoiceStatus.FAILED, InvoiceStatus.WRITTEN_OFF, InvoiceStatus.TOBEWRITTEN_OFF].includes(invoice.status)
          || (invoice.status === InvoiceStatus.PAID && isCardExpiring))
        && !isCreditNote && (
          <FormGroup>
            <Panel className="invoice-standalone-sidebar-type2">
              <Text locale={customerLanguage} className={isCardExpiring ? "warning-replace-card" : ""} content="INVOICE_STANDALONE_REPLACE_CARD_TITLE" />
              <Text
                locale={customerLanguage}
                content={isCardExpiring ? counterpart("INVOICE_STANDALONE_REPLACE_PAYMENT_WARNING_SUBTITLE", { locale: customerLanguage, cardType: getCardText(invoice.cardType), lastDigits: invoice.cardLast4Digits }) : counterpart("INVOICE_STANDALONE_REPLACE_CARD_SUBTITLE", { locale: customerLanguage })}
                shouldTranslate={false}
                noMargin
              />
            </Panel>

            <Button
              id="replace-payment-card"
              title="INVOICE_STANDALONE_REPLACE_CARD_BTN"
              locale={customerLanguage}
              isFullWidth
              buttonType="orange"
              icon="far fa-arrow-right"
              iconPosition="right"
              onClick={() => {
                appendBillsbyDataAttributeToCheckoutLib({ label: BillsbyDataParams.company, value: invoice.companyDomain });
                window.scanDomBillsby();
                const updatePaymentMethodBtn: HTMLElement | null = document.querySelector(`#update-payment-${invoice.customerUniqueId}`);
                updatePaymentMethodBtn && updatePaymentMethodBtn.click();
              }}
            />
          </FormGroup>
        )
      }

      {
        invoice.companyPhone && invoice.companyEmail && (
          <Panel className="invoice-standalone-sidebar-type2">
            <Text locale={customerLanguage} content="INVOICE_STANDALONE_TALK_TO_OUR_TEAM_TITLE" />
            <Text locale={customerLanguage} content="INVOICE_STANDALONE_TALK_TO_OUR_TEAM_CONTENT1" />
            <Text locale={customerLanguage} content="INVOICE_STANDALONE_TALK_TO_OUR_TEAM_CONTENT2" translateWith={{ companyPhone: invoice.companyPhone, companyEmail: invoice.companyEmail }} noMargin />
          </Panel>
        )
      }

      <Button
        locale={customerLanguage}
        id="visit-our-support"
        title="INVOICE_STANDALONE_TALK_TO_OUR_TEAM_BTN"
        isFullWidth
        buttonType="orange"
        icon="far fa-arrow-right"
        iconPosition="right"
        onClick={() => window.open(invoice.supportUrl, "_self")}
        isVisible={!!invoice.supportUrl}
      />
      {getCheckoutBtn()}
    </>

  )
}

export default InvoiceStandaloneSidebarHome;