import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../../../..";
import { IAuthReducerState } from "../../../../reducers/authReducer";
import Table from "../../../ui/table/Table";
import Text from "../../../ui/text/Text";
import Checkbox from "../../../ui/checkbox/Checkbox";
import MenuPanel from "../../../ui/menu-panel/MenuPanel";
import "./SelectAddOns.scss";
import { Row, Col } from "react-grid-system";
import Button from "../../../ui/button/Button";
import {} from "../../../../actions/addOnsActions";
import Search from "../../../search/Search";
import Pagination from "../../../ui/pagination/Pagination";
import { ILinkAddOnsAllowancesReducerState } from "../../../../reducers/linkAddOnsAllowancesReducer";
import { setSelectedAddOnsId, setSearchKeyAddOnsPage, setSelectedCompanyAddOnsPage } from "../../../../actions/linkAddOnsAllowancesActions";
import { setSelectedAddOns } from "../../../../actions/linkAddOnsAllowancesActions";
import { setShowSelectAddOnsModal } from "../../../../actions/linkAddOnsAllowancesActions";
import { cancelSelectedAddOnsPage } from "../../../../actions/linkAddOnsAllowancesActions";
import { selectAllAddOns } from "../../../../actions/linkAddOnsAllowancesActions";
import { deselectAllSelectedAddOns } from "../../../../actions/linkAddOnsAllowancesActions";
import { IPlanReducer } from "../../../../reducers/plansReducer";
import { AddonsAllowancesScreen } from "../../../../models/AddonsAllowances";


const SelectAddOns: React.FC = () => {
  const auth = useSelector<AppState, IAuthReducerState>(state => state.auth)
  const { companyAddOns, companyAddOnsDisplay, selectedAddOnsIds, selectedAllowancesIds, addOnsNumberOfPages, 
    activeTab } = useSelector<AppState, ILinkAddOnsAllowancesReducerState>(state => state.linkAddOnsAllowancesReducer);
  const planReducer = useSelector<AppState, IPlanReducer>(state => state.plans)
  const dispatch = useDispatch<Function>()

  const isAddonScreen = activeTab === AddonsAllowancesScreen.ADDONS;
  const arrayIds = isAddonScreen ? selectedAddOnsIds : selectedAllowancesIds;
  //const { addons } = planReducer;
  const [searchKey, setSearchKey] = useState("");

  const isSelected = (id: number) => {
    return arrayIds.indexOf(id) !== -1
  };

  return (
    <MenuPanel className="select-add-ons">
      <MenuPanel.Content>
        <Text 
          content={isAddonScreen ? "CREATE_PLAN_SELECT_ADD_ONS_MODAL_TITLE" : "CREATE_PLAN_SELECT_ALLOWANCES_MODAL_TITLE"} 
          className="select-add-ons__title" 
        />
        <div className="select-add-ons__search">
          <Search
            value={searchKey}
            placeholder={isAddonScreen ? "CREATE_PLAN_SEARCH_ADD_ONS_PLACEHOLDER" : "CREATE_PLAN_SEARCH_ALLOWANCES_PLACEHOLDER"}
            onChange={(evt: any) => setSearchKey(evt.target.value)}
            onSearch={() => dispatch(setSearchKeyAddOnsPage(searchKey))}
          />
          <Pagination
            className="select-add-ons__search__pagination"
            pageCount={addOnsNumberOfPages}
            isVisible={addOnsNumberOfPages > 1}
            onPageChange={({ selected }) => dispatch(setSelectedCompanyAddOnsPage(selected + 1))}
          />
        </div>
        <Table className="select-add-ons__table">
          <thead>
            <tr>
              <Text content={isAddonScreen ? "CREATE_PLAN_TABLE_ADD_ON" : "CREATE_PLAN_TABLE_ALLOWANCE"} component="th" noMargin />
              <Text content="CREATE_PLAN_TABLE_SINGLE_UNIT_NAME" component="th" noMargin />
              <th>
                {companyAddOns.length !== arrayIds.length && (
                  <Button
                    id="select-all"
                    icon="fas fa-list-alt"
                    title="CREATE_PLAN_BUTTON_SELECT_ALL"
                    buttonType="add"
                    onClick={() => dispatch(selectAllAddOns())}
                  />
                )}
                {companyAddOns.length === arrayIds.length && (
                  <Button
                    id="deselect-all"
                    icon="fas fa-times"
                    title="CREATE_PLAN_BUTTON_DESELECT_ALL"
                    buttonType="add"
                    onClick={() => dispatch(deselectAllSelectedAddOns())}
                  />
                )}
              </th>
            </tr>
          </thead>
          <tbody>
            {companyAddOnsDisplay.map(addOn => (
              <tr key={`select-add-ons-table-tr-${addOn.getId()}`}>
                <td>{addOn.getName()}</td>
                <td>{addOn.getSingleUnitName()}</td>
                <td>
                  <Checkbox checked={isSelected(addOn.getId())} onClick={() => dispatch(setSelectedAddOnsId(addOn.getId()))} value="" content="" />
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
        <Row>
          <Col md={9}>
            <Button
              id="select-add-ons-btn"
              title={isAddonScreen ? "CREATE_PLAN_BUTTON_SELECT_ADD_ONS" : "CREATE_PLAN_BUTTON_SELECT_ALLOWANCES"}
              translateWith={{ count: arrayIds.length > 0 ? arrayIds.length: "" }}
              disabled={arrayIds.length < 1}
              onClick={() => {
                dispatch(setSelectedAddOns(arrayIds));
                dispatch(setSearchKeyAddOnsPage(""));
                dispatch(setShowSelectAddOnsModal(false))
              }}  
              isFullWidth
            />
          </Col>
          <Col md={3}>
            <Button
              id="cancel-select-add-ons-btn"
              buttonType="error"
              title="CREATE_PLAN_BUTTON_SELECT_CANCEL"
              onClick={() => {
                dispatch(cancelSelectedAddOnsPage())
                dispatch(setShowSelectAddOnsModal(false))
                dispatch(setSearchKeyAddOnsPage("")) // reset filter
              }}
              isFullWidth
            />
          </Col>
        </Row>
      </MenuPanel.Content>
    </MenuPanel>
  );
};

export default SelectAddOns
