import React from "react";
import Panel from "../../../ui/panel/Panel";
import Text from "../../../ui/text/Text";
import ImageSlider from "../../../ui/image-slider/ImageSlider";
import image1 from "../../../../images/integrations/quickbooks/image1.png";
import image2 from "../../../../images/integrations/quickbooks/image2.png";
import image3 from "../../../../images/integrations/quickbooks/image3.png";
import image4 from "../../../../images/integrations/quickbooks/image4.png";
import image5 from "../../../../images/integrations/quickbooks/image5.png";
import "./QuickBooksOverview.scss";

interface IQuickBooksOverview {}

export const QuickBooksOverview: React.FC<IQuickBooksOverview> = () => {
  return (
    <div>
      <Panel className="quick-books-overview" title="INTEGRATIONS_QUICKBOOKS_OVERVIEW_TITLE">
        <div className="quick-books-overview__container">
          <div className="quick-books-overview__image-container">
            <img src={image1} className="quick-books-overview__image" alt="no img" />
          </div>
          <div className="quick-books-overview__text">
            <Text content="INTEGRATIONS_QUICKBOOKS_OVERVIEW_TEXT" />
            <Text content="INTEGRATIONS_QUICKBOOKS_OVERVIEW_TEXT2" noMargin />
          </div>
        </div>
      </Panel>
      <ImageSlider images={[image2, image3, image4, image5]} />
    </div>
  )
}

export default QuickBooksOverview;