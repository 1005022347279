import React, { ReactElement, useEffect, useMemo, useRef, useState } from "react";
import Children, { deepMap } from "react-children-utilities";
import "./Table.scss";

/*
  To see fully working examples check the Table.test.tsx file
*/
interface ITable {
  /**
   * Whether has expandable rows or not.
   * In case it has, the row that triggers the expansion will need a subsequent row that will keep the content to show on expansion
   * and that needs to be tagged with a class "expansion"
   */
  expandable?: boolean;
  scrollable?: boolean;
  scrollableClassName?: string;
  className?: string;
  expandableContent?: ReactElement;
  fixedColWidths?: Array<number>;
  children: ReactElement | Array<ReactElement>;
  enableHoverEffect?: boolean
}

interface ITableState {
  [key: string]: any;
}

const fixedColStyle = {
  position: "absolute",
  left: "0px",
  border: "inherit",
  backgroundColor: "#fff",
}

const Table: React.FC<ITable> = (props) => {
  const { children, expandable = false, scrollable = false, scrollableClassName, className, fixedColWidths = [200], enableHoverEffect = true } = props;

  const [scrolledEnd, setScrolledEnd] = useState(false);
  const [state, setState] = useState<ITableState>({});

  const _scrollable = useRef<HTMLDivElement>(null);
  const _table = useRef<HTMLTableElement>(null);

  let _children: any = children;

  const sumColWidths = useMemo(() => {
    return fixedColWidths.reduce((a, b) => a + b)
  }, [fixedColWidths]);

  const detectScrollEnd = () => {
    if (!_scrollable.current || !_table.current) {
      return;
    }

    if (Math.round(_scrollable.current.offsetWidth + _scrollable.current.scrollLeft) >= _table.current.offsetWidth) {
      setScrolledEnd(true)
    } else {
      setScrolledEnd(false)
    }
  };

  useEffect(() => {
    detectScrollEnd();
    window.addEventListener("resize", detectScrollEnd);
    return () => {
      window.removeEventListener("resize", detectScrollEnd);
    }
  }, [])


  if (expandable && _children) {
    /*
    add logic to trigger the expansion of the row to the children 
    */
    let idx = 0;
    _children = Children.deepMap(_children, (child: any) => {
      if (!child) {
        return child;
      }

      const childClasses = (child.props && child.props.className) || "";

      //Prevent rendering of content unless expanded to properly trigger data fetching
      if (child.type === "tr" && childClasses.includes("expanded")) {
        if (state["rowExpanded" + idx]) {
          return React.cloneElement(child, { className: `${childClasses} expanded--smooth` }, React.createElement("td", {}, [...child.props.children]));
        } else return null;
      }

      if (child.type === "tr" && !childClasses.includes("expanded")) {
        //need a block scope variable here to be bound to this block every iteration
        let index = ++idx;
        let clonedElement;

        if (childClasses.includes("not-expandable")) {
          clonedElement = React.cloneElement(child, { className: "not-expandable" });
        } else {
          const originalCallBack = child.props.onClick ? child.props.onClick : () => { };

          clonedElement = React.cloneElement(child, {
            className: `${state["rowExpanded" + index] ? "cc-table--expandable__rowExpanded" : ""}`,
            onClick: () => {
              setState({ ["rowExpanded" + index]: !state["rowExpanded" + index] });
              originalCallBack(child);
            }
          });
        }
        return clonedElement;
      }

      return child;
    });
  }

  if (scrollable && _children) {
    _children = Children.deepMap(_children, (child: any) => {
      if (!child) {
        return null;
      }

      if (child.type === "tr") {
        let idx = -1;

        if (fixedColWidths.length) {
          return deepMap(child, (_child: any): any => {
            if (_child.props.component === "th" || _child.props.component === "td" || _child.type === "th" || _child.type === "td") {
              ++idx;
              
              if (fixedColWidths.length >= idx + 1) {
                 return React.cloneElement(_child, {
                  style: {
                    ...fixedColStyle,
                    left: `${fixedColWidths.filter((fw, _idx) => _idx < idx).reduce((a, b) => a + b, 0)}px`,
                    width: `${fixedColWidths[idx]}px`,
                    borderRight: fixedColWidths.length === idx + 1 ? "1px solid #ccc5b9" : "unset"
                  }
                });
              }

            }

            return _child;
          });
        }
      }
      return child;
    });
  }

  if (scrollable) {
    return (
      <div className={`scrollable-table-container ${scrollableClassName} ${scrolledEnd ? "" : "with-fade-out"}`}>
        <div
          ref={_scrollable}
          className={"scrollable-table-container__inner"}
          onScroll={detectScrollEnd}
          style={fixedColWidths ? { marginLeft: `${sumColWidths}px`, width: `calc(100% - ${sumColWidths}px)` } : {}}
        >
          <table ref={_table} className={`cc-table ${className} ${expandable ? "cc-table--expandable" : ""}`}>
            {_children}
          </table>
        </div>
      </div>
    );
  }

  return (
    <table className={`cc-table ${className} ${enableHoverEffect ? "cc-table--hover-effect" : ""} ${expandable ? "cc-table--expandable" : ""}`}>{_children}</table>
  )
}
interface ITableArrowBtn {
  onClick?: Function,
  link?: boolean
  to?: string
}

export const TableArrowBtn: React.FC<ITableArrowBtn> = React.memo(({ onClick = () => { }, link = false, to = "" }) => {
  const _onClick = (e: any) => {
    e.preventDefault();
    onClick()
  }

  return (
    <td className="view-arrow">
      <a role="button" onClick={_onClick} href={link ? to : undefined}><i className="far fa-chevron-right"></i></a>
    </td>
  )
})

export default Table;
