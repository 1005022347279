import React, { FormEvent, useState, useEffect } from "react";
import { AppState } from "../../../../..";
import { useDispatch, useSelector } from "react-redux";
import Panel from "../../../../ui/panel/Panel";
import Text from "../../../../ui/text/Text";
import Input from "../../../../ui/input/Input";
import FormGroup from "../../../../ui/form-group/FormGroup";
import { Col, Row } from "react-grid-system";
import Button from "../../../../ui/button/Button";
import "./EmailRouting.scss";
import counterpart from "counterpart";
import Checkbox from "../../../../ui/checkbox/Checkbox";
import { setEmailNotificationsField, fetchCompanyForwardingEmail, updateCompanyForwardingEmail, resetReplyAddress, resetEmailRouting } from "../../../../../actions/emailRoutingActions";
import ProgressIndicator from "../../../../ui/progress-indicator/ProgressIndicator";

const EmailRouting: React.FC = () => {
  const forwardingEmailAddress = useSelector((state: AppState) => state.emailRoutingReducer.forwardingEmailAddress);
  const currentCompanyDomain = useSelector((state: AppState) => state.auth.currentCompanyDomain);

  const dispatch = useDispatch<Function>();

  const [state, setState] = useState({
    isLoadingData: false,
    replyAddressHasError: false,
    replyAddressErrorMsg: "",
    senderAddressUpdating: false,
    replyAddressUpdating: false
  });

  const { replyAddressHasError, replyAddressErrorMsg, replyAddressUpdating, senderAddressUpdating, isLoadingData } = state;

  const fetchData = async () => {
    setState({ ...state, isLoadingData: true });
    try {
      await dispatch(fetchCompanyForwardingEmail(currentCompanyDomain));
      setState({ ...state, isLoadingData: false });

    } catch (err) {
      console.log(err);
      setState({ ...state, isLoadingData: false });

    }
  };

  useEffect(() => {
    fetchData();

    return () => dispatch(resetEmailRouting())
  }, []);

  const handleUpdateSenderAddress = async (evt: FormEvent) => {
    evt.preventDefault();
    evt.stopPropagation();
  };

  const handleUpdateReplyAddress = async (evt: FormEvent) => {
    evt.preventDefault();
    evt.stopPropagation();

    setState({ ...state, replyAddressUpdating: true });

    try {
      const response = await dispatch(updateCompanyForwardingEmail(currentCompanyDomain, { forwardingEmailAddress }));

      if (!response.ok && response.error) {
        return setState({ ...state, replyAddressHasError: true, replyAddressErrorMsg: response.error.list[0].description });
      }

      setState({ ...state, replyAddressUpdating: false });
    } catch (err) {
      setState({ ...state, replyAddressUpdating: false, replyAddressHasError: true });
      console.log(err);
    }
  };

  const renderSenderAddressForm = () => {
    return (
      <form onSubmit={handleUpdateSenderAddress}>
        <Checkbox checked={false} onClick={() => null} value={""} content={""} noMargin />
        <Checkbox checked={false} onClick={() => null} value={""} content={""} noMargin />
        <Checkbox checked={false} onClick={() => null} value={""} content={""} noMargin />
        <Row align="center">
          <Col md={9}>
            <Button id="email-routing-update-sender-address" type="submit" isLoading={senderAddressUpdating} isFullWidth buttonType="general" title="EMAIL_ROUTING_UPDATE_SENDER_ADDRESS" />
          </Col>
          <Col md={3}>
            <Button id="email-routing-cancel-sender-address" isFullWidth buttonType="error" title="EMAIL_ROUTING_UPDATE_SENDER_CANCEL" />
          </Col>
        </Row>
      </form>
    );
  };

  const renderReplyAddressForm = () => {
    return (
      <form onSubmit={handleUpdateReplyAddress}>
        <FormGroup>
          <Row align="center">
            <Col md={12}>
              <Input
                type="email"
                placeholder={counterpart("EMAIL_ROUTING_EMAIL_PLACEHOLDER")}
                id="reply-address"
                isError={replyAddressHasError}
                errorMsg={replyAddressErrorMsg}
                value={forwardingEmailAddress}
                disabled={replyAddressUpdating}
                onChange={(evt: any) => {
                  setState({ ...state, replyAddressHasError: false });
                  dispatch(setEmailNotificationsField("forwardingEmailAddress", evt.target.value));
                }}
              />
            </Col>
          </Row>
        </FormGroup>
        <Row align="center">
          <Col md={9}>
            <Button id="email-routing-update-reply-address" type="submit" isLoading={replyAddressUpdating} isFullWidth buttonType="general" title="EMAIL_ROUTING_UPDATE_REPLY_ADDRESS" />
          </Col>
          <Col md={3}>
            <Button
              id="email-routing-cancel-reply-address"
              isFullWidth
              buttonType="error"
              title="EMAIL_ROUTING_UPDATE_REPLY_CANCEL"
              onClick={() => {
                setState({ ...state, replyAddressHasError: false });
                dispatch(resetReplyAddress());
              }}
            />
          </Col>
        </Row>
      </form>
    );
  };

  if(isLoadingData) {
    return <ProgressIndicator color="blue"></ProgressIndicator>
  }

  return (
    <div className="email-routing">
      {/* Hide for now */}
      {/* <Panel title="EMAIL_ROUTING_PANEL1_TITLE">
          <Text content="EMAIL_ROUTING_PANEL1_CONTENT" />
          {renderSenderAddressForm()}
        </Panel> */}
      <Panel title="EMAIL_ROUTING_PANEL2_TITLE">
        <Text content="EMAIL_ROUTING_PANEL2_CONTENT" />
        {renderReplyAddressForm()}
      </Panel>
    </div>
  );
};

export default EmailRouting;
