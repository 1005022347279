import React from "react";
import { NavLink, useRouteMatch } from "react-router-dom";
import TabbedContainer from "../../../components/ui/tabbed-container/TabbedContainer";
import { PrivateRoute } from "../../AuthRoutes";
import counterpart from "counterpart";
import "./SubscriptionDetailsTab.scss";
import SubscriptionEventLogs from "../../../components/subscriptions/subscription-event-logs/SubscriptionEventLogs";
import SubscriptionFeatureTags from "../../../components/subscriptions/subscription-feature-tags/SubscriptionFeatureTags";
import SubscriptionCustomFields from "../../../components/subscriptions/subscription-custom-fields/SubscriptionCustomFields";
import SubscriptionShippingAddress from "../../../components/subscriptions/subscription-shipping-address/SubscriptionShippingAddress";
import { useSelector } from "react-redux";
import { AppState } from "../../..";
import SubscriptionAddOns from "../../../components/subscriptions/subscription-add-ons/SubscriptionAddOns";
import SubscriptionAllowances from "../../../components/subscriptions/subscription-allowances/SubscriptionAllowances";
import SubscriptionDiscounts from "../../../components/subscriptions/subscription-discounts/SubscriptionDiscounts";

const SubscriptionDetailsTab: React.FC = () => {
  const subscriptionDetailsReducer = useSelector((state: AppState) => state.subscriptionDetailsReducer);
  
  const match = useRouteMatch();

  const tabArray = [
    {
      label: "SUBSCRIPTION_EVENT_LOG_TAB",
      navlink: ""
    },
    {
      label: "SUBSCRIPTION_ADD_ONS_TAB",
      navlink: "/add-ons"
    },
    {
      label: "SUBSCRIPTION_ALLOWANCES_TAB",
      navlink: "/allowances"
    },
    {
      label: "SUBSCRIPTION_DISCOUNTS_TAB",
      navlink: "/discounts"
    },
    {
      label: "FEATURE_TAG_TAB",
      navlink: "/feature-tags"
    },
    {
      label: "CUSTOM_FIELDS_TAB",
      navlink: "/custom-fields"
    }
  ];

  if(!subscriptionDetailsReducer.addOns) {
    tabArray.filter(i => i.label === "SUBSCRIPTION_ADD_ONS_TAB")
  }

  if (subscriptionDetailsReducer.isShippingAddressRequired) {
    tabArray.push({
      label: "SUBSCRIPTION_SHIPPING_ADDRESS_TAB",
      navlink: "/shipping-address"
    });
  }

  return (
    <div className="subscription-details-tab">
      <TabbedContainer type="submenu">
        {tabArray.map((tab, idx) => (
          <NavLink
            exact
            key={idx}
            activeClassName="active"
            to={`${match?.url}${tab.navlink}`}
          >
            <li>{counterpart(tab.label)}</li>
          </NavLink>
        ))}
      </TabbedContainer>

      <PrivateRoute
        render={() => (
          <div className="subscription-details-tab__content">
            <PrivateRoute
              exact
              path={`${match?.path}/`}
              component={SubscriptionEventLogs}
            />
            <PrivateRoute
              exact
              path={`${match?.path}/add-ons`}
              component={SubscriptionAddOns}
            />
            <PrivateRoute
              exact
              path={`${match?.path}/allowances`}
              component={SubscriptionAllowances}
            />
            <PrivateRoute
              exact
              path={`${match?.path}/discounts`}
              component={SubscriptionDiscounts}
            />
            <PrivateRoute
              exact
              path={`${match?.path}/feature-tags`}
              component={SubscriptionFeatureTags}
            />
            <PrivateRoute
              exact
              path={`${match?.path}/custom-fields`}
              component={SubscriptionCustomFields}
            />
            <PrivateRoute
              exact
              path={`${match?.path}/shipping-address`}
              component={SubscriptionShippingAddress}
            />
          </div>
        )}
      />
    </div>
  );
};

export default SubscriptionDetailsTab;