import React from "react";
import Panel from "../panel/Panel";
import "./ImageSlider.scss";

interface IImageSlider {
  images: string[],
  className?: string
}

export const ImageSlider: React.FC<IImageSlider> = React.memo(({ className = "", images }) => {

  return (
    <div className={`image-slider ${className}`}>
      {
        images.map((src, i) => <Panel key={i} className="image-slider__item">
          <img src={src} alt="no img" />
        </Panel>)
      }
    </div>
  )
})

export default ImageSlider;