import React from "react";
import { Row, Col } from "react-grid-system";
import Panel from "../../ui/panel/Panel";
import Text from "../../ui/text/Text";
import FormGroup from "../../ui/form-group/FormGroup";
import FormLabel from "../../ui/form-label/FormLabel";
import Input from "../../ui/input/Input";
import Button from "../../ui/button/Button";
import TaxRatePanel from "./TaxRatePanel";
import "./AddTaxRates.scss";
import { TaxRate } from "../../../utils/grpc/generated/Billsby.Protos/billing/private/company/taxation/taxation_pb";

interface IAddTaxRates {
  taxRates: Array<TaxRate>;
  onChangeTaxName: (value: string) => void;
  onChangeTaxRate: (value: number) => void;
  onAdd: () => void;
  onDelete: (value: number) => void;
  onDeleteConfig?: () => void;
  isConfigured?: boolean;
  taxName: string;
  taxRate: number;
}

const AddTaxRates: React.FC<IAddTaxRates> = React.memo(({ taxRates, onChangeTaxRate, onChangeTaxName, onAdd, onDelete, onDeleteConfig, isConfigured = false, taxName, taxRate }) => {
  return (
    <Panel className="add-tax-rates" title="ADD_TAX_TITLE">
      {isConfigured && <Button className="add-tax-rates__floating-button" id="delete-config" title="ADD_TAX_DELETE" icon="far fa-trash-alt" buttonType="error" onClick={onDeleteConfig} />}
      <Text content="ADD_TAX_CONTENT" />
      <FormGroup noMargin>
        <Row align="center">
          <Col md={1.5}>
            <FormLabel target="tax-name" content="ADD_TAX_NAME_LABEL" noMargin />
          </Col>
          <Col md={3}>
            <Input id="tax-name" type="text" placeholder="GST" value={taxName} onChange={(evt: any) => onChangeTaxName(evt.target.value)} />
          </Col>
          <Col md={1.5}>
            <FormLabel target="tax-rate" content="ADD_TAX_RATE" noMargin />
          </Col>
          <Col md={3}>
            <Input id="tax-rate" type="number" placeholder="10.00" value={taxRate === 0 ? "" : taxRate} onChange={(evt: any) => onChangeTaxRate(+evt.target.value)} />
          </Col>
          <Col md={0.5}>
            <FormLabel target="tax-name" content="%" shouldTranslate={false} noMargin />
          </Col>
          <Col md={2.5}>
            <Button isFullWidth id="add-tax-rates" disabled={!taxName || !taxRate} buttonType="add" title="ADD_TAX_BTN" icon="fal fa-plus-circle" onClick={onAdd} />
          </Col>
        </Row>
      </FormGroup>
      {taxRates.map((taxRate, i) => {
        return <TaxRatePanel key={`${i}_${taxRate.getName()}`} taxRate={taxRate} showAsText showEditButton={false} showDeleteButton onDelete={() => onDelete(i)} />;
      })}
    </Panel>
  );
});

export default AddTaxRates;
