import { ConfigConstants } from "../config";
import { BillsbyDataParams, BillsbyDataAction } from "./checkoutIntegrationModel";


const ID_CHECKOUT_LIB = "checkout-lib";
type CheckoutLibAttr = { label: BillsbyDataParams, value: string | BillsbyDataAction }

const getCheckoutLib = () => document.head.querySelector(`#${ID_CHECKOUT_LIB}`);

export const appendCheckoutLibScript = (companyDomain?: string) => {
  let checkoutLibScript = getCheckoutLib();
  if (checkoutLibScript) {
    return checkoutLibScript;
  }
  const script: HTMLElement = document.createElement("script");
  script.setAttribute("id", ID_CHECKOUT_LIB);
  script.setAttribute("src", ConfigConstants.checkoutLibUrl);
  if(companyDomain) {
    script.setAttribute(BillsbyDataParams.company, companyDomain);
  }
  document.head.appendChild(script);
  return script;
}

export const appendBillsbyDataAttributeToCheckoutLib = (attr: CheckoutLibAttr) => {
  let checkoutLibScript = getCheckoutLib();
  if (!checkoutLibScript) {
    return;
  }
  checkoutLibScript.setAttribute(attr.label, attr.value);
}