import React, { useState } from "react";
import counterpart from "counterpart";
import FormGroup from "../../../../ui/form-group/FormGroup";
import { Row, Col } from "react-grid-system";
import FormLabel from "../../../../ui/form-label/FormLabel";
import Input from "../../../../ui/input/Input";
import PaymentGatewayButtons from "../payment-gateways-shared-components/PaymentGatewayButtons";
import { isInvalidGatewayFormsDetails } from "../PaymentGateways";

interface Props {
  onSave: (displayName: string, userName: string, password: string, merchantAccount: string) => Promise<void>;
  onCancel: () => void;
  setHelpTitle: (text: string) => void;
  setHelpContent: (text: Array<string>) => void;
  initDisplayName: string;
  initUserName: string;
  initPassword: string;
  initMerchantAccount: string;
  isSaveEnabled: boolean;
  isEdit: boolean;
}

const CloverConnectGatewayForm: React.FC<Props> = ({ onSave, onCancel, setHelpTitle, setHelpContent, initDisplayName, initUserName, initPassword, initMerchantAccount, isSaveEnabled, isEdit }) => {
  const [displayName, setDisplayName] = useState(initDisplayName);
  const [username, setUsername] = useState(initUserName);
  const [password, setPassowrd] = useState(initPassword);
  const [merchantAccount, setMerchantAccount] = useState(initMerchantAccount);

  const clearFields = () => {
    setDisplayName(initDisplayName);
    setUsername(initUserName);
    setPassowrd(initPassword);
    setMerchantAccount(initMerchantAccount);
  }

  const savePaymentGateway = async () => {
    await onSave(displayName, username, password, merchantAccount);
    clearFields();
  }

  const onFocus = (inputField: string) => {
    switch (inputField) {
      case "DisplayName":
        setHelpTitle("PAYMENT_GATEWAYS_ADDED_CLOVER_CONNECT_DISPLAYNAME_HELP_TITLE");
        setHelpContent(["PAYMENT_GATEWAYS_ADDED_CLOVER_CONNECT_DISPLAYNAME_HELP_CONTENT_1"]);
        break;
      case "Username":
        setHelpTitle("PAYMENT_GATEWAYS_ADDED_CLOVER_CONNECT_USERNAME_HELP_TITLE");
        setHelpContent(["PAYMENT_GATEWAYS_ADDED_CLOVER_CONNECT_USERNAME_HELP_CONTENT_1"]);
        break;
      case "Password":
        setHelpTitle("PAYMENT_GATEWAYS_ADDED_CLOVER_CONNECT_PASSWORD_HELP_TITLE");
        setHelpContent(["PAYMENT_GATEWAYS_ADDED_CLOVER_CONNECT_PASSWORD_HELP_CONTENT_1"]);
        break;
      case "MerchantAccount":
        setHelpTitle("PAYMENT_GATEWAYS_ADDED_CLOVER_CONNECT_MERCHANT_ACCOUNT_HELP_TITLE");
        setHelpContent(["PAYMENT_GATEWAYS_ADDED_CLOVER_CONNECT_MERCHANT_ACCOUNT_HELP_CONTENT_1"]);
        break;
    }
  }

  return (
    <div className="payment-gateways__update">
      <FormGroup>
        <Row align="center" className="payment-gateways__update__form-group">
          <Col xs={2}>
            <FormLabel
              target="display-name"
              content="PAYMENT_GATEWAYS_CLOVER_CONNECT_DISPLAY_NAME"
              noMargin
            />
          </Col>
          <Col xs={10}>
            <Input
              value={displayName} onChange={(evt: any) => setDisplayName(evt.target.value)}
              onFocus={() => onFocus("DisplayName")} id="display-name"
              placeholder={counterpart("PAYMENT_GATEWAYS_CLOVER_CONNECT_DISPLAY_NAME_PLACEHOLDER")}
            />
          </Col>
        </Row>
        <Row align="center" className="payment-gateways__update__form-group">
          <Col xs={2}>
            <FormLabel
              target="user-name"
              content="PAYMENT_GATEWAYS_CLOVER_CONNECT_USERNAME"
              noMargin
            />
          </Col>
          <Col xs={10}>
            <Input
              value={username} onChange={(evt: any) => setUsername(evt.target.value)}
              id="user-name" onFocus={() => onFocus("Username")}
              placeholder={counterpart("PAYMENT_GATEWAYS_CLOVER_CONNECT_USERNAME_PLACEHOLDER")}
            />
          </Col>
        </Row>
        <Row align="center" className="payment-gateways__update__form-group">
          <Col xs={2}>
            <FormLabel target="password" content="PAYMENT_GATEWAYS_CLOVER_CONNECT_PASSWORD" noMargin />
          </Col>
          <Col xs={10}>
            <Input id="password" onFocus={() => onFocus("Password")}
              placeholder={counterpart("PAYMENT_GATEWAYS_CLOVER_CONNECT_PASSWORD_PLACEHOLDER")}
              type="password" value={password} onChange={(evt: any) => setPassowrd(evt.target.value)}
            />
          </Col>
        </Row>
        <Row align="center" className="payment-gateways__update__form-group">
          <Col xs={2}>
            <FormLabel target="merchant-account" content="PAYMENT_GATEWAYS_CLOVER_CONNECT_MERCHANT_ACCOUNT" noMargin />
          </Col>
          <Col xs={10}>
            <Input id="merchant-account" onFocus={() => onFocus("MerchantAccount")}
              placeholder={counterpart("PAYMENT_GATEWAYS_CLOVER_CONNECT_MERCHANT_ACCOUNT_PLACEHOLDER")}
              value={merchantAccount} onChange={(evt: any) => setMerchantAccount(evt.target.value)}
            />
          </Col>
        </Row>
      </FormGroup>

      <PaymentGatewayButtons
        onSave={() => savePaymentGateway()}
        onCancel={() => {
          onCancel();
          clearFields();
        }}
        isEdit={isEdit}
        isSaveEnabled={isSaveEnabled && !isInvalidGatewayFormsDetails([displayName, username, password, merchantAccount])}
      />
    </div>
  )
}

export default CloverConnectGatewayForm;