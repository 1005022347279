import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import FormGroup from "../ui/form-group/FormGroup";
import { Row, Col } from "react-grid-system";
import FormLabel from "../ui/form-label/FormLabel";
import Input from "../ui/input/Input";
import API from "../../utils/API";
import Panel from "../ui/panel/Panel";
import counterpart from "counterpart";
import PhoneInput from "../ui/phone-input/PhoneInput";
import { PREFERRED_PHONE_COUNTRIES } from "../../utils/constants";
import Button from "../ui/button/Button";
import { AppState } from "../..";
import AddressForm from "../address-form/AddressForm";
import { ConfigConstants } from "../../utils/config";
import { CompanyServiceClient } from "../../utils/grpc/generated/Billsby.Protos/core/private/companies/CompanyServiceClientPb";
import { CollectCompanyNameType, GetCollectCompanyNameRequest, GetCollectCompanyNameResponse } from "../../utils/grpc/generated/Billsby.Protos/core/private/companies/company_pb";
import { grpcUnaryCall } from "../../utils/grpc/grpcUtils";
import { trimWhiteSpaces } from "../../utils/commonUtils";

interface IEditCustomerProps {
  onUpdateCustomer: () => void
}

interface IEditCustomerState {
  firstName: string,
  lastName: string,
  email: string,
  isPhoneNumberValid: boolean,
  phoneNumber: string,
  phoneNumberDialCode: string,
  phoneNumberDialCountry: string,
  addressLine1: string,
  addressLine2: string,
  addressState: { value: string, label: string },
  addressCity: string,
  addressPostCode: string,
  addressCountry: { value: string, label: string },
  isCountryLocked: boolean,
  hasError: boolean,
  errorMsg: string,
  success: boolean,
  isLoading: boolean,
  countries: [],
  isLoadingData: boolean,
  customerCompanyName?: string,
  collectCompanyNameType: CollectCompanyNameType
}

const EditCustomer: React.FC<IEditCustomerProps> = ({ onUpdateCustomer }) => {
  const customerDetails = useSelector((state: AppState) => state.customerDetailsReducer);
  const { currentCompanyDomain, currentCompanyId } = useSelector((state: AppState) => ({ currentCompanyDomain: state.auth.currentCompanyDomain, currentCompanyId: state.auth.currentCompanyId }))

  const [state, setState] = useState<IEditCustomerState>({
    firstName: "",
    lastName: "",
    email: "",
    isPhoneNumberValid: true,
    phoneNumber: "",
    phoneNumberDialCode: "",
    phoneNumberDialCountry: "",
    addressLine1: "",
    addressLine2: "",
    addressState: { value: "", label: "" },
    addressCity: "",
    addressPostCode: "",
    addressCountry: { value: "", label: "" },
    isCountryLocked: false,
    hasError: false,
    errorMsg: "",
    success: false,
    isLoading: false,
    countries: [],
    isLoadingData: true,
    customerCompanyName: "",
    collectCompanyNameType: CollectCompanyNameType.DO_NOT_COLLECT
  });

  const {
    firstName,
    lastName,
    email,
    phoneNumber,
    phoneNumberDialCode,
    phoneNumberDialCountry,
    addressLine1,
    addressLine2,
    addressState,
    addressCity,
    addressPostCode,
    addressCountry,
    isLoadingData,
    isLoading,
    isPhoneNumberValid,customerCompanyName,
    collectCompanyNameType
  } = state;

  const { billingAddress, customerUniqueId } = customerDetails;
  const collectCompanyName = collectCompanyNameType === CollectCompanyNameType.OPTIONAL || collectCompanyNameType === CollectCompanyNameType.COMPULSORY

  useEffect(() => {
    setState((prevState) => ({
      ...prevState,
      firstName: customerDetails.firstName,
      lastName: customerDetails.lastName,
      email: customerDetails.email,
      customerCompanyName: customerDetails.companyName,
      phoneNumber: customerDetails.phoneNumber || "",
      phoneNumberDialCode: customerDetails.phoneNumberDialCode || "",
      phoneNumberDialCountry: customerDetails.phoneNumberDialCountry || "",
      addressLine1: billingAddress.addressLine1,
      addressLine2: billingAddress.addressLine2,
      addressCity: billingAddress.city,
      addressCountry: { value: billingAddress.country, label: "" },
      addressState: { value: billingAddress.state, label: "" },
      addressPostCode: billingAddress.postCode,
      isLoadingData: false
    }));
  }, [customerDetails]);

  useEffect(() => {
    const fetchData = async () => {
      const companyServiceClient = new CompanyServiceClient(ConfigConstants.grpcBaseUrl);

      try {
        const request = new GetCollectCompanyNameRequest();
        currentCompanyId && request.setCompanyId(currentCompanyId);
        const response = await grpcUnaryCall(request, companyServiceClient, companyServiceClient.getCollectCompanyName) as GetCollectCompanyNameResponse;
        setState((prevState) => ({ ...prevState, collectCompanyNameType: response.getCollectCompanyNameType() }))
      } catch (err) {
        console.log(err);
      }
    }
    fetchData()
  }, [])

  const onSubmit = async (evt: React.FormEvent) => {
    evt.preventDefault();

    if (!customerUniqueId || isFormNotValid()) {
      return;
    }

    setState((prevState) => ({ ...prevState, isLoading: true }));

    const baseDetails = { firstName, lastName, email, phoneNumber: isPhoneNumberValid ? phoneNumber : "", phoneNumberDialCode, phoneNumberDialCountry, companyName: customerCompanyName };
    const addressDetails = { addressLine1, addressLine2, state: addressState.value, city: addressCity, postCode: addressPostCode, country: addressCountry.value };

    try {
      await API.updateCustomer(currentCompanyDomain, customerUniqueId, baseDetails, addressDetails);

      await onUpdateCustomer();
    } catch (err) {
      setState((prevState) => ({ ...prevState, isLoading: false, hasError: true }));
      console.log(err);
    }
  };

  const isFormNotValid = () => {
    return (
      !firstName || !lastName || !email || !addressLine1 || !addressState || !addressState.value || !addressCountry || !addressCity || !addressPostCode
    );
  };

  const handleOnChange = (prop: keyof IEditCustomerState) => {
    return (evt: any) => {
      const value = evt.target.value;
      setState(prevState => ({ ...prevState, [prop]: value }))
    }
  }

  const renderGeneralInfoForm = () => {
    return (
      <Panel>
        <FormGroup>
          <Row align="center">
            <Col md={2}>
              <FormLabel target="first-name" content="CUSTOMER_EDIT_FULL_NAME" noMargin/>
            </Col>
            <Col md={5}>
              <Input id="first-name" required value={firstName} placeholder={counterpart("CUSTOMER_EDIT_FIRST_NAME")} onChange={handleOnChange("firstName")} />
            </Col>
            <Col md={5}>
              <Input id="last-name" required value={lastName} placeholder={counterpart("CUSTOMER_EDIT_LAST_NAME")} onChange={handleOnChange("lastName")} />
            </Col>
          </Row>
        </FormGroup>
        <FormGroup>
          <Row align="center">
            <Col lg={2}>
              <FormLabel target="email" content="CUSTOMER_EDIT_EMAIL_ADDRESS" noMargin/>
            </Col>
            <Col md={10}>
              <Input id="email" required value={email} type="email" placeholder={counterpart("CUSTOMER_EDIT_EMAIL_ADDRESS")} onChange={handleOnChange("email")} />
            </Col>
          </Row>
        </FormGroup>
        {
          (collectCompanyName || customerDetails.companyName) && (
            <FormGroup>
              <Row align="center">
                <Col lg={2}>
                  <FormLabel target="company-name" content="ADD_CUSTOMER_COMPANY_NAME" noMargin/>
                </Col>
                <Col md={10}>
                  <Input
                    id="company-name"
                    required={collectCompanyNameType === CollectCompanyNameType.COMPULSORY}
                    value={customerCompanyName}
                    type="text"
                    placeholder={counterpart("ADD_CUSTOMER_COMPANY_PLACEHOLDER")}
                    onChange={evt =>{
                      const customerCompanyName = trimWhiteSpaces(evt.target.value, "left");
                      setState(prevState => ({ ...prevState, customerCompanyName }))
                    }}
                  />
                </Col>
              </Row>
            </FormGroup>
          )
        }
        <Row align="center">
          <Col md={2}>
            <FormLabel target="" content="CUSTOMER_EDIT_PHONE_NUMBER" />
          </Col>
          <Col md={10}>
            <PhoneInput
              preferredCountries={PREFERRED_PHONE_COUNTRIES.countries}
              value={phoneNumber}
              defaultCountry={phoneNumberDialCountry}
              dialCode={phoneNumberDialCode}
              errorLabel="REGISTRATION_PHONE_VALIDATION_ERROR"
              onPhoneNumberChange={(isValid: boolean, phone: string, countryData: any) => {
                setState((prevState) => ({ ...prevState, phoneNumberDialCountry: countryData.iso2, phoneNumberDialCode: countryData.dialCode, phoneNumber: phone, isPhoneNumberValid: isValid }));
              }}
              onSelectFlag={(phone: string, countryData: any) => {
                setState((prevState) => ({ ...prevState, phoneNumber: phone, phoneNumberDialCode: countryData.dialCode, phoneNumberDialCountry: countryData.iso2 }));
              }}
              separateDialCode
            />
          </Col>
        </Row>
      </Panel>
    );
  };

  const renderBillingAddressForm = () => {
    return (
      <Panel>
        <AddressForm
          onChangeAddressLine1={(line1) => setState((prevState) => ({ ...prevState, addressLine1: line1 }))}
          onChangeAddressLine2={(line2) => setState((prevState) => ({ ...prevState, addressLine2: line2 }))}
          onChangeAddressState={(_state: any) => {
            setState((prevState) => ({ ...prevState, addressState: _state }))
          }}
          onChangeAddressCity={(city) => setState((prevState) => ({ ...prevState, addressCity: city }))}
          onChangeAddressPostCode={(code) => setState((prevState) => ({ ...prevState, addressPostCode: code }))}
          onChangeAddressCountry={(country: any) => setState((prevState) => ({ ...prevState, addressCountry: country }))}
          label={"ADD_CUSTOMER_BILLING_ADDRESS"}
          formErrors={false}
          formData={{ addressLine1, addressLine2, addressCity, addressPostCode, addressState, addressCountry }}
        />
      </Panel>
    );
  };

  if (isLoadingData) {
    return null;
  }

  return (
    <form className="change-password" onSubmit={onSubmit}>
      {renderGeneralInfoForm()}
      {renderBillingAddressForm()}
      <Button id="update-customer-btn" disabled={isFormNotValid()} type="submit" buttonType={"general"} isLoading={isLoading} title={"CUSTOMER_EDIT_SAVE_CHANGES"} isFullWidth />
    </form>
  );
};


export default EditCustomer;
