import React, { useState, useEffect, useMemo } from "react";
import TabbedContainer from "../../ui/tabbed-container/TabbedContainer";
import { NavLink } from "react-router-dom";
import Text from "../../ui/text/Text";
import { ICreateAddOnsReducerState } from "../../../reducers/add-ons/create-addons/createAddOnsReducer";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../../..";
import { IAddOnCostReducer } from "../../../reducers/add-ons/addOnCostReducer";
import StepCollapsablePanel from "../../ui/step-collapsable-panel/StepCollapsablePanel";
import {
  setAddOnsStepsCollapsed,
  fetchCurrenciesOfPlans,
  setAddOnsCostCurrentPage,
  setHasConfiguredAllPricingModels,
  fetchCurrencyPriceModels,
  setAddOnsFormHasError,
  FETCH_CURRENCIES_OF_PLANS_SUCCESS,
} from "../../../actions/addOnsActions";
import "./StepAddOnCost.scss";
import { IAuthReducerState } from "../../../reducers/authReducer";
import { ISelectPlanAddOnsReducerState } from "../../../reducers/add-ons/selectAddOnPlanReducer";
import StepAddOnCostList from "./StepAddOnCostList";
import {
  Currency, AddonPriceModel, GetPlanCurrenciesResponse,
} from "../../../utils/grpc/generated/Billsby.Protos/core/private/addons/addons_pb";
import AddOnConfigurePrice from "./AddOnConfigurePrice";
import { AddonsAllowancesScreen } from "../../../models/AddonsAllowances";
import { AllowancePriceModel } from "../../../utils/grpc/generated/Billsby.Protos/core/private/allowances/allowances_pb";

interface IAddOnCost {
  type: AddonsAllowancesScreen;
  addOnId: number | null;
}

const StepAddOnCost: React.FC<IAddOnCost> = ({ type, addOnId }) => {

  const { stageStatuses, stageErrors, hasSelectedPricingModel, stageCollapsed, selectedPricingModel } = useSelector<AppState, ICreateAddOnsReducerState>(state => state.createAddOnsReducer);
  const { currentCompanyDomain } = useSelector<AppState, IAuthReducerState>(state => state.auth)
  const { selectedPlans } = useSelector<AppState, ISelectPlanAddOnsReducerState>(state => state.selectAddOnPlanReducer);
  const { currenciesOfPlans, hasConfiguredAllPriceModels, currencyPriceModels, currentPage } = useSelector<AppState, IAddOnCostReducer>(state => state.addOnCostReducer);
  const dispatch = useDispatch<Function>()
  const [priceModel, setPriceModel] = useState<null | AddonPriceModel | AllowancePriceModel>(null);
  const [selectedCurrency, setSelectedCurrency] = useState<null | Currency>(null);
  const isAddonScreen = type === AddonsAllowancesScreen.ADDONS;

  const planIds = useMemo(() => {
    return selectedPlans.map((selectedPlan) => selectedPlan.planId);
  }, [selectedPlans]);

  useEffect(() => {
    if(!planIds.length) {
      const currencies = new GetPlanCurrenciesResponse();
      //set to empty array to avoid a useless api call
      currencies.setCurrenciesList([])
      dispatch({ type: FETCH_CURRENCIES_OF_PLANS_SUCCESS, response: currencies })
      return
    }
    dispatch(fetchCurrenciesOfPlans(currentCompanyDomain, planIds));
  }, [hasSelectedPricingModel, planIds]);

  useEffect(() => {
    if (currenciesOfPlans && currenciesOfPlans.getCurrenciesList().length) {
      setSelectedCurrency(currenciesOfPlans.getCurrenciesList()[0]);
    }
  }, [currenciesOfPlans]);

  useEffect(() => {
    if (!selectedCurrency) {
      return;
    }
    dispatch(fetchCurrencyPriceModels(currentCompanyDomain, selectedCurrency.getIso3Code(), planIds, currentPage, 10, type));
  }, [selectedCurrency]);

  useEffect(() => {
    const checkAllPriceModelsAreConfigured = () => {
      if (selectedCurrency && currenciesOfPlans && currencyPriceModels) {
        for (let currency of currenciesOfPlans.getCurrenciesList()) {
          if (!currencyPriceModels[currency.getIso3Code()]) {
            dispatch(setHasConfiguredAllPricingModels(false));
            return;
          }
        }
        for (let [currencyIso3, priceModels] of Object.entries(currencyPriceModels)) {
          if (priceModels.some(priceModel => !priceModel.getPerUnitPriceFormatted() && !priceModel.getTiersList().length
            && ((priceModel as AddonPriceModel).getFlatFeePriceFormatted && !(priceModel as AddonPriceModel).getFlatFeePriceFormatted()
              || (priceModel as AllowancePriceModel).getIncludedUnits && !(priceModel as AllowancePriceModel).getIncludedUnits()))) {
            dispatch(setAddOnsFormHasError(3, true));
            return dispatch(setHasConfiguredAllPricingModels(false));
          }
        }
        dispatch(setAddOnsFormHasError(3, false));
        return dispatch(setHasConfiguredAllPricingModels(true));
      }
    };
    checkAllPriceModelsAreConfigured();
  }, [currencyPriceModels, currentPage, priceModel]);

  const getTitle = () => {
    if (isAddonScreen && !addOnId) {
      return "CREATE_ADD_ONS_PRICE_MODELS_TITLE";
    }
    if (!isAddonScreen && !addOnId) {
      return "CREATE_ALLOWANCES_PRICE_MODELS_TITLE";
    }
    return "EDIT_ADDONS_ALLOWANCES_PRICE_MODELS_TITLE"
  }

  const getHelpContent = () => {
    if (isAddonScreen && planIds.length) {
      return "CREATE_ADD_ONS_PRICE_MODELS_HELP_TEXT_CONTENT";
    }
    if (!isAddonScreen && planIds.length) {
      return "CREATE_ALLOWANCES_PRICE_MODELS_HELP_TEXT_CONTENT";
    }
    if (isAddonScreen && !planIds.length) {
      return "CREATE_ADD_ONS_PRICE_MODELS_HELP_TEXT_ATTACH_LATER";
    }
    return "CREATE_ALLOWANCES_PRICE_MODELS_HELP_TEXT_ATTACH_LATER";
  }

  const renderPriceModelsList = () => {
    return (
      <StepCollapsablePanel
        title={getTitle()}
        number={4}
        isCompleted={hasConfiguredAllPriceModels && hasSelectedPricingModel}
        hasError={hasSelectedPricingModel ? stageErrors[3] : false}
        isLocked={!hasSelectedPricingModel}
        isShowTitleIcon={!addOnId || (!!addOnId && !hasConfiguredAllPriceModels)}
        hasHelpSection={true}
        isCollapsed={addOnId ? false : stageCollapsed[3]}
        className='step-addon-cost__list'
        helpMenuPosition='top'
        helpMenuContent={
          <Text
            content={getHelpContent()}
            translateWith={{
              icon: <i className='fas fa-map-marker-exclamation' />,
            }}
            noMargin
          />
        }
        onClick={() => dispatch(setAddOnsStepsCollapsed(3))}>
        <>
          <TabbedContainer type='submenu'>
            {currenciesOfPlans ? (
              currenciesOfPlans.getCurrenciesList().map((currency, id) => (
                <NavLink
                  key={id}
                  isActive={(match, location) =>
                    !!selectedCurrency &&
                    selectedCurrency.getIso3Code() === currency.getIso3Code()
                  }
                  to=''
                  onClick={(evt) => {
                    evt.preventDefault();
                    //reset current page
                    dispatch(setAddOnsCostCurrentPage(1));
                    setSelectedCurrency(currency);
                  }}>
                  {currency.getIso3Code()}
                </NavLink>
              ))
            ) : (
                <div />
              )}
          </TabbedContainer>

          <StepAddOnCostList
            pricingModelType={selectedPricingModel.value}
            selectedCurrency={selectedCurrency || new Currency()}
            configurePriceModelCb={(priceModel: AddonPriceModel | AllowancePriceModel) => {
              setPriceModel(priceModel);
            }}
            planIds={planIds}
          />
        </>
      </StepCollapsablePanel>
    );
  };


  const renderConfigurePrice = () => {
    return (
      <AddOnConfigurePrice
        priceModel={priceModel}
        setPriceModel={setPriceModel}
        selectedCurrency={selectedCurrency as Currency}
        selectedPricingModel={selectedPricingModel.value}
        type={type}
      />
    )
  };

  if (!priceModel) {
    return renderPriceModelsList();
  }
  return renderConfigurePrice();
};

export default StepAddOnCost