

import React, { FormEvent, useState } from "react";
import { AppState } from "../../../../..";
import { useDispatch, useSelector } from "react-redux";
import Panel from "../../../../ui/panel/Panel";
import Text from "../../../../ui/text/Text";
import Input from "../../../../ui/input/Input";
import FormLabel from "../../../../ui/form-label/FormLabel";
import FormGroup from "../../../../ui/form-group/FormGroup";
import { Col, Row } from "react-grid-system";
import Button from "../../../../ui/button/Button";
import counterpart from "counterpart";
import CheckoutPreviewContainer from "../../brand-customization/checkout-preview-container/CheckoutPreviewContainer";
import "./ConfirmationScreenPanel.scss";
import { grpcUnaryCall } from "../../../../../utils/grpc/grpcUtils";
import { CompanyCheckoutConfigServiceClient } from "../../../../../utils/grpc/generated/Billsby.Protos/core/private/companyconfig/companycheckoutconfig/CompanycheckoutconfigServiceClientPb";
import { UpsertConfirmationCheckoutConfigRequest } from "../../../../../utils/grpc/generated/Billsby.Protos/core/private/companyconfig/companycheckoutconfig/companycheckoutconfig_pb";
import { ConfigConstants } from "../../../../../utils/config";
import { AllowedCheckoutMgmtFields, resetCheckoutAcctMgmtConfirmationScreen, setCheckoutAccountMgmtField, updateCheckoutManagementData } from "../../../../../actions/checkoutAccountManagementActions";

const ConfirmationScreenPanel: React.FC = () => {
  const checkoutManagement = useSelector((state: AppState) => state.checkoutAccountManagementReducer.checkoutManagement);
  const currentCompanyDomain = useSelector((state: AppState) => state.auth.currentCompanyDomain);

  const dispatch = useDispatch<Function>();
  
  const [isUpdating, setIsUpdating] = useState(false);
  const { confirmationButtonText, confirmationCopy, confirmationHeading, confirmationSubheading, redirectUrl } = checkoutManagement;

  const handleUpdate = async (evt: FormEvent) => {
    evt.preventDefault();
    setIsUpdating(true);
    const upsertConfirmationCheckoutConfigRequest = new UpsertConfirmationCheckoutConfigRequest();
    upsertConfirmationCheckoutConfigRequest.setCompanyDomain(currentCompanyDomain);
    upsertConfirmationCheckoutConfigRequest.setConfirmationButtonText(confirmationButtonText);
    upsertConfirmationCheckoutConfigRequest.setConfirmationCopy(confirmationCopy);
    upsertConfirmationCheckoutConfigRequest.setConfirmationHeading(confirmationHeading);
    upsertConfirmationCheckoutConfigRequest.setConfirmationSubheading(confirmationSubheading);
    upsertConfirmationCheckoutConfigRequest.setRedirectUrl(redirectUrl);
    try {
      const companyCheckoutConfigServiceClient = new CompanyCheckoutConfigServiceClient(ConfigConstants.grpcBaseUrl);
      await grpcUnaryCall(upsertConfirmationCheckoutConfigRequest, companyCheckoutConfigServiceClient, companyCheckoutConfigServiceClient.upsertConfirmationCheckoutConfig)
      dispatch(updateCheckoutManagementData(checkoutManagement))
      setIsUpdating(false);
    } catch (err) {
      setIsUpdating(false);
      console.log(err);
    }
  };

  const updateCheckoutField = (arg: AllowedCheckoutMgmtFields, value: any) => {
    dispatch(setCheckoutAccountMgmtField("checkoutManagement", { ...checkoutManagement, [arg]: value }));
  };

  return (
    <Panel title="CONFIRMATION_SCREEN_PANEL_TITLE" className="confirmation-screen-panel">

      <CheckoutPreviewContainer screen="Confirmation" />

      <form onSubmit={handleUpdate} className="confirmation-screen-panel__form">
        <FormGroup>
          <Row align="center">
            <Col md={1.5}>
              <FormLabel target="tof" content="CONFIRMATION_SCREEN_PANEL_HEADING" noMargin />
            </Col>
            <Col md={10.5}>
              <Input
                type="text"
                placeholder={counterpart("CONFIRMATION_SCREEN_PANEL_HEADING_PLACEHOLDER")}
                id="tof"
                isError={false}
                value={confirmationHeading || ""}
                onChange={(evt: any) => updateCheckoutField("confirmationHeading", evt.target.value)}
              />
            </Col>
          </Row>
        </FormGroup>
        <FormGroup>
          <Row align="center">
            <Col md={1.5}>
              <FormLabel target="privacy-policy" content="CONFIRMATION_SCREEN_PANEL_SUB_HEADING" noMargin />
            </Col>
            <Col md={10.5}>
              <Input
                type="text"
                placeholder={counterpart("CONFIRMATION_SCREEN_PANEL_SUB_HEADING_PLACEHOLDER")}
                id="privacy-policy"
                isError={false}
                value={confirmationSubheading || ""}
                onChange={(evt: any) => updateCheckoutField("confirmationSubheading", evt.target.value)}
              />
            </Col>
          </Row>
        </FormGroup>
        <FormGroup>
          <Row align="center">
            <Col md={1.5}>
              <FormLabel target="privacy-policy" content="CONFIRMATION_SCREEN_PANEL_COPY" noMargin />
            </Col>
            <Col md={10.5}>
              <Input
                type="text"
                placeholder={counterpart("CONFIRMATION_SCREEN_PANEL_COPY_PLACEHOLDER")}
                id="privacy-policy"
                isError={false}
                value={confirmationCopy || ""}
                onChange={(evt: any) => updateCheckoutField("confirmationCopy", evt.target.value)}
              />
            </Col>
          </Row>
        </FormGroup>
        <FormGroup>
          <Row align="center">
            <Col md={1.5}>
              <FormLabel target="privacy-policy" content="CONFIRMATION_SCREEN_PANEL_BUTTON_TEXT" noMargin />
            </Col>
            <Col md={10.5}>
              <Input
                type="text"
                placeholder={counterpart("CONFIRMATION_SCREEN_PANEL_BUTTON_TEXT_PLACEHOLDER")}
                id="privacy-policy"
                isError={false}
                value={confirmationButtonText || ""}
                onChange={(evt: any) => updateCheckoutField("confirmationButtonText", evt.target.value)}
              />
            </Col>
          </Row>
        </FormGroup>

        <Text content="CONFIRMATION_SCREEN_PANEL_REDIRECT_URL_TEXT" />

        <FormGroup>
          <Row align="center">
            <Col md={1.5}>
              <FormLabel target="privacy-policy" content="CONFIRMATION_SCREEN_PANEL_REDIRECT_URL" noMargin />
            </Col>
            <Col md={10.5}>
              <div className="confirmation-screen-panel__redirect-url">
                <Input
                  type="text"
                  placeholder={counterpart("CONFIRMATION_SCREEN_PANEL_REDIRECT_URL_PLACEHOLDER")}
                  id="privacy-policy"
                  isError={false}
                  value={redirectUrl || ""}
                  onChange={(evt: any) => updateCheckoutField("redirectUrl", evt.target.value)}
                />
                <Text content="/?cid=" shouldTranslate={false} component="span" noMargin />
                <Text content="XXX" shouldTranslate={false} component="span" noMargin />
                <Text content="&sid=" shouldTranslate={false} component="span" noMargin />
                <Text content="XXX" shouldTranslate={false} component="span" noMargin />
              </div>
            </Col>
          </Row>
        </FormGroup>

        <Row align="center">
          <Col md={9}>
            <Button id="confirmation-screen-panel-save-btn" type="submit" isLoading={isUpdating} isFullWidth buttonType="general" title="CONFIRMATION_SCREEN_PANEL_REDIRECT_URL_SAVE_BUTTON" />
          </Col>
          <Col md={3}>
            <Button id="confirmation-screen-panel-cancel-btn" isFullWidth buttonType="error" title="CHECKOUT_ACCT_MANAGEMENT_BTN_CANCEL" onClick={() => dispatch(resetCheckoutAcctMgmtConfirmationScreen())} />
          </Col>
        </Row>
      </form>

    </Panel>
  );
};

export default ConfirmationScreenPanel;