import React, { Fragment } from "react";
import Panel from "../panel/Panel";
import Text from "../text/Text";

import StatusLabel from "../status-label/StatusLabel";
import { COLOR } from "../../../utils/constants";
import { IListElement } from "../../../models/Configuration";
import "./InteractiveList.scss";
import Tooltip from "../tooltip/Tooltip";

interface IInteractiveList<T extends IListElement> {
  //title?: string,
  className?: string,
  effectOnHover?: boolean,
  data: Array<T>
}

const getListElementClass = (index: number, data: Array<any>, effectOnHover: boolean, isLocked: boolean, isElementClickable: boolean) => {
  let className = "interactive-list__element";
  if (index === 0) {
    className += " interactive-list__element--first";
  }
  if (index === data.length - 1) {
    className += " interactive-list__element--last";
  }
  if (effectOnHover) {
    className += " interactive-list__element--hover";
  }
  if (isLocked) {
    className += " interactive-list__element--locked";
  }
  if (isElementClickable) {
    className += " interactive-list__element--clickable";
  }

  return className;
}

const InteractiveList = <T extends IListElement>({ className = "", effectOnHover = true, data }: IInteractiveList<T>) => {
  return (
    <Panel className={`interactive-list ${className}`}>
      {
        data.map((listElement: T, index) => {
          const { title, subTitle, onClick, shouldTranslateTitle = true, shouldTranslateSubtitle = true, translateSubtitleWith, logo, isLogoFontAwesome = true,
            statusLabel, statusLabelColor = COLOR.ORANGE, icon = "far fa-chevron-right", isElementClickable = true, isLocked = false, isVisible = true, customStatus, tooltipMessage } = listElement;
          const elementId = title ? `${title.replace(/\s/g, "")}-${index}` : index;
          if(!isVisible) { 
            return null 
          }
          return (
            <Fragment key={elementId}>
              <Panel data-tip data-for={elementId} key={index} className={getListElementClass(index, data, effectOnHover, isLocked, isElementClickable)}
                onClick={(isElementClickable && onClick && !isLocked) ? () => onClick(listElement) : () => { }}>

                <div>
                  {(logo && isLogoFontAwesome) && <i className={logo} />}
                  {(logo && !isLogoFontAwesome) && <img className="interactive-list__element__logo-img" src={logo} alt="no img" />}
                  <div>
                    {!!title && <Text content={title} shouldTranslate={shouldTranslateTitle} className="interactive-list__element__title" noMargin />}
                    {!!subTitle && <Text content={subTitle} shouldTranslate={shouldTranslateSubtitle} translateWith={translateSubtitleWith}  className="interactive-list__element__subTitle" noMargin />}
                  </div>
                </div>

                <div>
                  {statusLabel && <StatusLabel content={statusLabel} color={statusLabelColor} />}
                  {customStatus?.map((status, i) =>
                    <StatusLabel key={`status-${i}`} color={status.color} icon={status.icon} content={status.label}></StatusLabel>)}
                  {onClick && <i className={icon} onClick={() => onClick(listElement)} />}
                </div>
              </Panel>
              {tooltipMessage && <Tooltip id={elementId.toString()} place='bottom' type='blue'>
                <Text content={tooltipMessage} noMargin />
              </Tooltip>}
            </Fragment>
          )
        })
      }
    </Panel>
  )
}

export default InteractiveList;