import { makeActionCreator, makeGenericActionCreatorTypeSafe } from "../utils/reduxUtils";
import { GetTaxSummaryRequest, JurisdictionType } from "../utils/grpc/generated/Billsby.Protos/billing/private/report/report_pb";
import { ReportServiceClient } from "../utils/grpc/generated/Billsby.Protos/billing/private/report/ReportServiceClientPb";
import { grpcUnaryCall } from "../utils/grpc/grpcUtils";
import moment from "moment";
import { Timestamp } from "google-protobuf/google/protobuf/timestamp_pb";
import { ITaxSummaryReducerState } from "../reducers/taxSummaryReducer";
import { ConfigConstants } from "../utils/config";

export const FETCH_TAX_SUMMARY_SUCCESS = "FETCH_TAX_SUMMARY_SUCCESS";
export const FETCH_TAX_SUMMARY_FAILURE = "FETCH_TAX_SUMMARY_FAILURE";
export const FETCH_TAX_SUMMARY_REQUEST = "FETCH_TAX_SUMMARY_REQUEST";

export const FETCH_CURRENT_MONTH_TAX_SUMMARY_SUCCESS = "FETCH_CURRENT_MONTH_TAX_SUMMARY_SUCCESS";
export const FETCH_CURRENT_MONTH_TAX_SUMMARY_FAILURE = "FETCH_CURRENT_MONTH_TAX_SUMMARY_FAILURE";
export const FETCH_CURRENT_MONTH_TAX_SUMMARY_REQUEST = "FETCH_CURRENT_MONTH_TAX_SUMMARY_REQUEST";

export const SET_TAX_SUMMARY_FIELD = "SET_TAX_SUMMARY_FIELD";
export const RESET_TAX_SUMMARY = "RESET_TAX_SUMMARY";

export const resetTaxSummary = makeActionCreator(RESET_TAX_SUMMARY, "payload");
export const setTaxSummaryField = makeGenericActionCreatorTypeSafe<ITaxSummaryReducerState>(SET_TAX_SUMMARY_FIELD);

export const fetchTaxSummary = function (companyId: number, startDate: string, endDate: string, jurisdictionType: JurisdictionType) {
  const getTaxSummary = new GetTaxSummaryRequest();
  const startDateTimestamp = new Timestamp();
  const endDateTimeStamp = new Timestamp();

  startDateTimestamp.fromDate(moment.utc(startDate, "MMM YYYY").startOf("month").toDate());
  endDateTimeStamp.fromDate(moment.utc(endDate, "MMM YYYY").endOf("month").toDate());

  getTaxSummary.setCompanyId(companyId);
  getTaxSummary.setStartDate(startDateTimestamp);
  getTaxSummary.setEndDate(endDateTimeStamp);
  getTaxSummary.setJurisdictionType(jurisdictionType)
  const reportServiceClient = new ReportServiceClient(ConfigConstants.grpcBaseUrl);
  return ({
    types: [FETCH_TAX_SUMMARY_REQUEST, FETCH_TAX_SUMMARY_SUCCESS, FETCH_TAX_SUMMARY_FAILURE],
    callAPI: () => grpcUnaryCall(getTaxSummary, reportServiceClient, reportServiceClient.getTaxSummary)
  })
}
