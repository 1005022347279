import { BillsbyAction } from "../models/BillsbyAction";
import { AppState } from "..";
import { SET_EXIT_REASONS_REPORT_FIELD, FETCH_EXIT_REASONS_REPORT_SUCCESS, FETCH_EXIT_REASONS_REPORT_GRAPH_DATA_SUCCESS, FETCH_CURRENT_MONTH_EXIT_REASONS_REPORT_SUCCESS, RESET_EXIT_REASONS_REPORT } from "../actions/exitReasonsActions";
import { GetTaxSummaryResponse, ExitReasonsGraphDataResponse, GetExitReasonsResponse } from "../utils/grpc/generated/Billsby.Protos/billing/private/report/report_pb";
import moment from "moment";
import { Timestamp } from "google-protobuf/google/protobuf/timestamp_pb";
import { toFixedWithoutRounding } from "../utils/commonUtils";

export interface IExitReasonsState {
  startDate: string,
  endDate: string,
  includeCurrentMonthToDate: boolean,
  hasCurrentMonth: boolean,
  headerMessage: string,
  selectedProduct: { value?: number, label: string },
  barChartData: Array<{ [prop: string]: any; date: any }>,
  exitReasonsPagination: GetExitReasonsResponse.AsObject,
};

export const initState: IExitReasonsState = {
  startDate: "",
  endDate: "",
  hasCurrentMonth: true,
  headerMessage: "",
  selectedProduct: { value: undefined, label: "" },
  barChartData: [],
  exitReasonsPagination: {
    currentPage: 1,
    pageCount: 1,
    pageSize: 0,
    rowCount: 0,
    resultsList: []
  },
  includeCurrentMonthToDate: true,
}

export default function exitReasonsReducer(state: IExitReasonsState = initState, action: BillsbyAction, store: AppState) {
  switch (action.type) {
    case FETCH_EXIT_REASONS_REPORT_SUCCESS: {
      const details = action.response as GetExitReasonsResponse;
      return {
        ...state, exitReasonsPagination: {
          currentPage: details.getCurrentPage(),
          pageCount: details.getPageCount(),
          pageSize: details.getPageSize(),
          rowCount: details.getRowCount(),
          resultsList: details.getResultsList().map(d => (
            {
              planName: d.getPlanName(),
              productName: d.getProductName(),
              customerExitReason: d.getCustomerExitReason(),
              date: (d.getDate() as Timestamp).toObject(), 
              comments: d.getComments()
            })
          )
        }
      }
    }

    case FETCH_EXIT_REASONS_REPORT_GRAPH_DATA_SUCCESS: {
      const details = action.response as ExitReasonsGraphDataResponse;
      let barChartData: Array<{ [prop: string]: any; date: any }> = [];

      details.getDataList().forEach(d => {
        const obj: { [prop: string]: number } = {};
        d.getDataList().forEach(_d => (obj[_d.getExitReasonId()] = toFixedWithoutRounding(+(_d.getPercentage() * 100), 2)));
        barChartData.push({ ...obj,  date: d.getDate() && moment.unix((d.getDate() as Timestamp).getSeconds()).format("MMM YY") })
      });
    
      return { ...state, barChartData }
    }

    case FETCH_CURRENT_MONTH_EXIT_REASONS_REPORT_SUCCESS: {
      const currentMonthDetails = action.response as GetTaxSummaryResponse;
      return { ...state, currentMonthDetails }
    }

    case SET_EXIT_REASONS_REPORT_FIELD: {
      return { ...state, [action.fieldName]: action.fieldValue }
    }

    case RESET_EXIT_REASONS_REPORT: {
      return initState
    }

    default: return state
  }
}