import React, { FormEvent, useState } from "react";
import Panel from "../ui/panel/Panel";
import Text from "../ui/text/Text";
import Button from "../ui/button/Button";
import Input from "../ui/input/Input";
import FormGroup from "../ui/form-group/FormGroup";
import { Row, Col } from "react-grid-system";
import FormLabel from "../ui/form-label/FormLabel";
import counterpart from "counterpart";
import AddressForm from "../../components/address-form/AddressForm";
import CollectPaymentDetails from "./collect-payment-details/CollectPaymentDetails";
import "./AddCustomer.scss";
import { CollectCompanyNameType } from "../../utils/grpc/generated/Billsby.Protos/core/private/companies/company_pb";
import { trimWhiteSpaces } from "../../utils/commonUtils";

interface IAddCustomerModalProps {
  handleAddCustomerCallback: Function,
  collectCompanyNameType: CollectCompanyNameType
}

const AddCustomer: React.FC<IAddCustomerModalProps> = ({ handleAddCustomerCallback, collectCompanyNameType }) => {
  const [formErrors, setFormErrors] = useState(false);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [customerCompanyName, setCustomerCompanyName] = useState("");
  const [addressLine1, setAddressLine1] = useState("");
  const [addressLine2, setAddressLine2] = useState("");
  const [addressCity, setAddressCity] = useState("");
  const [addressPostCode, setAddressPostCode] = useState("");
  const [addressState, setAddressState] = useState({ label: "", value: "" });
  const [addressCountry, setAddressCountry] = useState({ label: "", value: "" });
  const [collectPaymentDetails, setCollectPaymentDetails] = useState(false);

  const validateFormData = () => {
    return !firstName || !lastName || !email || !addressLine1 || !addressState.value || !addressPostCode || !addressCountry.value || !addressCity;
  }

  const collectCompanyName = collectCompanyNameType === CollectCompanyNameType.OPTIONAL || collectCompanyNameType === CollectCompanyNameType.COMPULSORY

  const onSubmit = async (evt: FormEvent) => {
    evt.preventDefault();
    evt.stopPropagation();

    if (validateFormData()) {
      setFormErrors(true)
      return
    }

    setCollectPaymentDetails(true)
  };

  const renderCustomerDetailsForm = () => {
    return (
      <Panel>
        <FormGroup>
          <Row align="center">
            <Col md={2}>
              <FormLabel noMargin target="first-name" content="ADD_CUSTOMER_FULL_NAME" />
            </Col>
            <Col md={5}>
              <Input
                id="first-name"
                required
                value={firstName}
                placeholder={counterpart("ADD_CUSTOMER_FIRST_NAME_PLACEHOLDER")}
                onChange={(evt: any) => setFirstName(evt.target.value)}
              />
            </Col>
            <Col md={5}>
              <Input id="last-name" required value={lastName} placeholder={counterpart("ADD_CUSTOMER_LAST_NAME_PLACEHOLDER")}
                onChange={(evt: any) => setLastName(evt.target.value)}
              />
            </Col>
          </Row>
        </FormGroup>
        <FormGroup noMargin={!collectCompanyName}>
          <Row align="center">
            <Col lg={2}>
              <FormLabel noMargin target="email" content="ADD_CUSTOMER_EMAIL_ADDRESS" />
            </Col>
            <Col md={10}>
              <Input
                id="email"
                required
                value={email}
                type="email"
                placeholder={counterpart("ADD_CUSTOMER_EMAIL_ADDRESS_PLACEHOLDER")}
                onChange={(evt: any) => setEmail(evt.target.value)}
              />
            </Col>
          </Row>
        </FormGroup>
        {
          collectCompanyName && (
            <Row align="center">
              <Col lg={2}>
                <FormLabel noMargin target="company-name" content="ADD_CUSTOMER_COMPANY_NAME" />
              </Col>
              <Col md={10}>
                <Input
                  id="company-name"
                  required={collectCompanyNameType === CollectCompanyNameType.COMPULSORY}
                  value={customerCompanyName}
                  type="text"
                  placeholder={counterpart("ADD_CUSTOMER_COMPANY_PLACEHOLDER")}
                  onChange={(evt: any) => setCustomerCompanyName(trimWhiteSpaces(evt.target.value, "left"))}
                />
              </Col>
            </Row>
          )
        }
      </Panel>
    );
  };

  const renderCustomerBillingAddressForm = () => {
    return (
      <Panel>
        <AddressForm
          onChangeAddressLine1={(line1) => setAddressLine1(line1)}
          onChangeAddressLine2={(line2) => setAddressLine2(line2)}
          onChangeAddressState={(state: any) => setAddressState(state)}
          onChangeAddressCity={(city) => setAddressCity(city)}
          onChangeAddressPostCode={(code) => setAddressPostCode(code)}
          onChangeAddressCountry={(country: any) => setAddressCountry(country)}
          label={"ADD_CUSTOMER_BILLING_ADDRESS"}
          formErrors={formErrors}
          formData={{ addressLine1, addressLine2, addressCity, addressPostCode, addressState, addressCountry }}
        />
      </Panel>
    );
  };

  if (collectPaymentDetails) {
    return (
      <CollectPaymentDetails
        handleAddCustomerCallback={handleAddCustomerCallback}
        newCustomerDetails={{
          firstName, lastName, email, addressCity, addressCountry: addressCountry.value, addressLine1, addressLine2, addressPostCode, addressState: addressState.value, companyName: customerCompanyName
        }}
      />
    )
  }

  return (
    <form className="add-customer" onSubmit={onSubmit}>
      <Panel className="add-customer__title-panel" title="ADD_CUSTOMER_MODAL_TITLE">
        <Text className="add-customer__title-text" content="ADD_CUSTOMER_MODAL_CONTENT" noMargin />
      </Panel>
      {renderCustomerDetailsForm()}
      {renderCustomerBillingAddressForm()}
      <Button
        id="add-customer-next"
        type="submit"
        buttonType={"general"}
        title={"ADD_CUSTOMER_CONTINUE"}
        isFullWidth
      />
    </form>
  )
}

export default AddCustomer;
