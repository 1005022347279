import React, { useEffect, useState } from "react";
import logo from "../../../../images/integrations/quickbooks/logo.png";
import Text from "../../../ui/text/Text";
import Button from "../../../ui/button/Button";
import { IAuthReducerState } from "../../../../reducers/authReducer";
import { IAccountingIntegrationsReducerState } from "../../../../reducers/accountingIntegrationsReducer";
import { deleteAccountingIntegration, setAccountingIntegrationsField } from "../../../../actions/accountingIntegrationsActions";
import StepCollapsablePanel from "../../../ui/step-collapsable-panel/StepCollapsablePanel";
import { AccountingIntegrationType } from "../../../../models/AccountingIntegrations";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../../../..";
import { IntegrationType } from "../../../../utils/grpc/generated/Billsby.Protos/accountingIntegrations/private/accounts/accounts_pb";

interface IQuickBooksUninstall {
  onUninstall: () => void
}

export const QuickBooksUninstall: React.FC<IQuickBooksUninstall> = ({ onUninstall }) => {
  const authData = useSelector<AppState, IAuthReducerState>(state => state.auth)
  const accountingIntegrations = useSelector<AppState, IAccountingIntegrationsReducerState>(state => state.accountingIntegrationsReducer)
  const dispatch = useDispatch<Function>()
  
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    dispatch(setAccountingIntegrationsField("isCreatingIntegrationFailure", false));
    dispatch(setAccountingIntegrationsField("isCreatingIntegrationSuccess", false));
  }, [])

  return (
    <div>
      <StepCollapsablePanel title={
        <div className="free-agent-integrations__title">
          <div>
            <img src={logo} alt="no img" />
            <div>
              <Text content="INTEGRATION_UNINSTALL_QUICKBOOKS" noMargin />
            </div>
          </div>
        </div>
      } className="quickbooks-integrations__title-container"
        shouldTranslateTitle={false}
        hasHelpSection={false}
        isCollapsed={false}
      >
        <Text content="INTEGRATION_UNINSTALL_QUICKBOOKS_TEXT" noMargin />
      </StepCollapsablePanel>

      <Button
        id="quickbooks-uninstall-btn"
        type="button"
        buttonType={"general"}
        disabled={isLoading}
        onClick={async () => {
          setIsLoading(true);
          const isQuickbooksInstalled = accountingIntegrations.integrations.getIntegrationsList()
            .some(i => (i.getName() as AccountingIntegrationType) === AccountingIntegrationType.QUICKBOOKS && i.getIsInstalled());
          if (isQuickbooksInstalled) {
              await dispatch(deleteAccountingIntegration(Number(authData.currentCompanyId), IntegrationType.QUICKBOOKS));
              setIsLoading(false);
              onUninstall();
          }
        }}
        title="INTEGRATION_UNINSTALL_QUICKBOOKS_BUTTON"
        isFullWidth
      />
    </div>
  )
}

export default QuickBooksUninstall;