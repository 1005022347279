/**
 * @fileoverview gRPC-Web generated client stub for emailsender.private.emaillogs
 * @enhanceable
 * @public
 */

// GENERATED CODE -- DO NOT EDIT!


import * as grpcWeb from "grpc-web";


import {
  GetEmailLogsRequest,
  GetEmailLogsResponse,
  GetEmailRequest,
  GetEmailResponse} from "./emaillogs_pb";

export class EmailLogsServiceClient {
  client_: grpcWeb.AbstractClientBase;
  hostname_: string;
  credentials_: null | { [index: string]: string; };
  options_: null | { [index: string]: string; };

  constructor (hostname: string,
               credentials?: null | { [index: string]: string; },
               options?: null | { [index: string]: string; }) {
    if (!options) options = {};
    if (!credentials) credentials = {};
    options["format"] = "text";

    this.client_ = new grpcWeb.GrpcWebClientBase(options);
    this.hostname_ = hostname;
    this.credentials_ = credentials;
    this.options_ = options;
  }

  methodInfoGetEmailLogs = new grpcWeb.AbstractClientBase.MethodInfo(
    GetEmailLogsResponse,
    (request: GetEmailLogsRequest) => {
      return request.serializeBinary();
    },
    GetEmailLogsResponse.deserializeBinary
  );

  getEmailLogs(
    request: GetEmailLogsRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: GetEmailLogsResponse) => void) {
    return this.client_.rpcCall(
      this.hostname_ +
        "/emailsender.private.emaillogs.EmailLogsService/GetEmailLogs",
      request,
      metadata || {},
      this.methodInfoGetEmailLogs,
      callback);
  }

  methodInfoGetEmail = new grpcWeb.AbstractClientBase.MethodInfo(
    GetEmailResponse,
    (request: GetEmailRequest) => {
      return request.serializeBinary();
    },
    GetEmailResponse.deserializeBinary
  );

  getEmail(
    request: GetEmailRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: GetEmailResponse) => void) {
    return this.client_.rpcCall(
      this.hostname_ +
        "/emailsender.private.emaillogs.EmailLogsService/GetEmail",
      request,
      metadata || {},
      this.methodInfoGetEmail,
      callback);
  }

}

