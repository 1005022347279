import { makeActionCreator, makeGenericActionCreatorTypeSafe } from "../utils/reduxUtils";
import { IActivitySummaryReducerState } from "../reducers/activitySummaryReducer";
import { GetActivitySummaryRequest } from "../utils/grpc/generated/Billsby.Protos/billing/private/report/report_pb";
import { ReportServiceClient } from "../utils/grpc/generated/Billsby.Protos/billing/private/report/ReportServiceClientPb";
import { grpcUnaryCall } from "../utils/grpc/grpcUtils";
import moment from "moment";
import { Timestamp } from "google-protobuf/google/protobuf/timestamp_pb";
import { ConfigConstants } from "../utils/config";

export const FETCH_ACTIVITY_SUMMARY_SUCCESS = "FETCH_ACTIVITY_SUMMARY_SUCCESS";
export const FETCH_ACTIVITY_SUMMARY_FAILURE = "FETCH_ACTIVITY_SUMMARY_FAILURE";
export const FETCH_ACTIVITY_SUMMARY_REQUEST = "FETCH_ACTIVITY_SUMMARY_REQUEST";

export const FETCH_CURRENT_MONTH_ACTIVITY_SUMMARY_SUCCESS = "FETCH_CURRENT_MONTH_ACTIVITY_SUMMARY_SUCCESS";
export const FETCH_CURRENT_MONTH_ACTIVITY_SUMMARY_FAILURE = "FETCH_CURRENT_MONTH_ACTIVITY_SUMMARY_FAILURE";
export const FETCH_CURRENT_MONTH_ACTIVITY_SUMMARY_REQUEST = "FETCH_CURRENT_MONTH_ACTIVITY_SUMMARY_REQUEST";

export const SET_ACTIVITY_SUMMARY_FIELD = "SET_ACTIVITY_SUMMARY_FIELD";
export const RESET_ACTIVITY_SUMMARY = "RESET_ACTIVITY_SUMMARY";

export const resetActivitySummary = makeActionCreator(RESET_ACTIVITY_SUMMARY, "payload");
export const setActivitySummaryField = makeGenericActionCreatorTypeSafe<IActivitySummaryReducerState>(SET_ACTIVITY_SUMMARY_FIELD);

export const fetchActivitySummary = function (companyId: number,  showAfterTax: boolean = false, startDate: string, endDate: string) {
  const getActivitySummaryRequest = new GetActivitySummaryRequest();
  const startDateTimestamp = new Timestamp();
  const endDateTimeStamp = new Timestamp();

  startDateTimestamp.fromDate(moment.utc(startDate, "MMM YYYY").startOf("month").toDate());
  endDateTimeStamp.fromDate(moment.utc(endDate, "MMM YYYY").endOf("month").toDate());

  getActivitySummaryRequest.setCompanyId(companyId);
  getActivitySummaryRequest.setShowAfterTax(showAfterTax);
  getActivitySummaryRequest.setStartDate(startDateTimestamp);
  getActivitySummaryRequest.setEndDate(endDateTimeStamp);
  const reportServiceClient = new ReportServiceClient(ConfigConstants.grpcBaseUrl);

  return ({
    types: [FETCH_ACTIVITY_SUMMARY_REQUEST, FETCH_ACTIVITY_SUMMARY_SUCCESS, FETCH_ACTIVITY_SUMMARY_FAILURE],
    callAPI: () => grpcUnaryCall(getActivitySummaryRequest, reportServiceClient, reportServiceClient.getActivitySummary)
  })
}

