import React, { useRef, useState, useLayoutEffect, useMemo } from "react";
import StepCollapsablePanel from "../../ui/step-collapsable-panel/StepCollapsablePanel";
import "./CreateProduct.scss";
import Panel from "../../ui/panel/Panel";
import Text from "../../ui/text/Text";
import Button from "../../ui/button/Button";
import FormGroup from "../../ui/form-group/FormGroup";
import { Row, Col } from "react-grid-system";
import FormLabel from "../../ui/form-label/FormLabel";
import Input from "../../ui/input/Input";
import Dropdown from "../../ui/dropdown/Dropdown";
import { editCustomField, setCustomFieldsCurrentScreen, setIsQuickEdit, setStandardField } from "../../../actions/customFieldsActions";
import { setProductName, setProductDisplayName, setProductDescription, setProductCurrency, setProductFormCollapsed, setProductFormCompleted, setProductFormHasError, createProduct, fetchProducts, setProductVisibility, editProduct, resetProductForEditing, setProductHelpTitle, setProductHelpContent, setIsCustomFieldsOpened, setIsAdditionalEmailRequired, setIsPhoneNumberRequired, setIsMarketingConsentRequired, removeCustomField, setProductCustomFields, setIsReorderingCustomFields, setTempProductCustomFields, fetchProduct, setProductAlertEmail, setProductSettingsHelpTitle, setProductSettingsHelpContent, setProductAlertEmailIsValid } from "../../../actions/productPlanCycleActions";
import { findDOMNode } from "react-dom";
import { IProductPlanCycleReducerState } from "../../../reducers/productPlanCycleReducer";
import counterpart from "counterpart";
import Textarea from "../../ui/textarea/Textarea";
import { VisibilityType, IProductLight, IProductCountryLimitation, ICountryAddress } from "../../../models/Product";
import { IProductReducerState } from "../../../reducers/productsReducer";
import CustomFields from "../../custom-fields/CustomFields";
import { ICustomFieldsReducer } from "../../../reducers/customFieldsReducer";
import DragDropContainer from "../../ui/drag-drop-container/DragDropContainer";
import { fetchGettingStartedSettings } from "../../../actions/authActions";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../../..";
import PageWrapper from "../../ui/layout/PageWrapper";
import NavigationBar from "../../ui/navigation-bar/NavigationBar";
import NavigationAlertCurrencyMismatch from "../../ui/navigation-alert/NavigationAlertCurrencyMismatch";
import history from "../../../utils/history";
import Helmet from "react-helmet";
import { fetchCountriesProduct } from "../../../actions/productPlanCycleActions";
import CreateProductAddressValidator from "../create-product-address-validator/CreateProductAddressValidator";
import ErrorNotification from "../../ui/error-notification/ErrorNotification";
import ProgressIndicator from "../../ui/progress-indicator/ProgressIndicator";
import ProductImageUploader from "./ProductImageUploader";
import NoticePanel from "../../ui/notice-panel/NoticePanel";
import { CustomFieldScreen } from "../../../models/CustomFields";
import { isValidEmailFormat } from "../../../utils/commonUtils";
import { IAuthReducerState } from "../../../reducers/authReducer";
import { useRouteMatch } from "react-router-dom";


const CreateProduct: React.FC = () => {
  const { currentCompanyDomain } = useSelector<AppState, IAuthReducerState>(state => state.auth)
  const productPlanCycleReducerData = useSelector<AppState, IProductPlanCycleReducerState>(state => state.productPlanCycle)
  const productsReducerData = useSelector<AppState, IProductReducerState>(state => state.products)
  const customFieldsData = useSelector<AppState, ICustomFieldsReducer>(state => state.customFields)

  const [isLoadingData, setIsLoadingData] = useState(false);
  const dispatch = useDispatch<Function>()
  const match = useRouteMatch() as any

  function changeCollapsedValue(index: number, stageCollapsed: Array<boolean>, dispatch: Function) {
    if (stageCollapsed[index]) {
      dispatch(setProductFormCollapsed(index));
    }
  }

  const getAddressPermission = (el: ICountryAddress): "Both" | "BillingAddress" | "ShippingAddress" => {
    if (el.allowBillingAddress && el.allowShippingAddress) {
      return "Both";
    }
    if (el.allowBillingAddress) {
      return "BillingAddress";
    }
    return "ShippingAddress";
  }

  const { stageCollapsed: isProductFormCollapsed, stageErrors: hasProductFormError, productName, displayName, description,
    currency, visibility, alertEmail, typeOfProduct, isCreatingProductRequest, isCreatingProductFailure, stageStatuses, helpTextTitle,
    helpTextContent, isAddressCollapsed, isCustomFieldsOpened, isProductImageEnabled, productImageFileName,
    isAdditionalEmailRequired, isPhoneNumberRequired, isMarketingConsentRequired, productError,
    addressOptions, customFields, customFieldsTemp, isReorderingCustomFields,
    isFetchingProductFailure, isEditingProductRequest, productHelpTextTitle, productHelpTextContent, isAlertEmailValid } = productsReducerData;
  const { currenciesDropdown, productsPaginated } = productPlanCycleReducerData;

  const isProductImageValid = useMemo(() => isProductImageEnabled ? !!productImageFileName : true, [isProductImageEnabled, productImageFileName]);

  const formRef0: any = useRef(null);
  //const formRef1: any = useRef(null);
  const formRefBtn: any = useRef(null);

  const VISIBILITY_OPTIONS = [
    { value: VisibilityType.Public, label: counterpart("CREATE_PRODUCT_FORM_3_VISIBILITY_PUBLIC") },
    { value: VisibilityType.Hidden, label: counterpart("CREATE_PRODUCT_FORM_3_VISIBILITY_HIDDEN") },
    { value: VisibilityType.Internal, label: counterpart("CREATE_PRODUCT_FORM_3_VISIBILITY_INTERNAL") },
    { value: VisibilityType.OffSale, label: counterpart("CREATE_PRODUCT_FORM_3_VISIBILITY_OFF_SALE") }
  ];

  const selectedVisibility = VISIBILITY_OPTIONS.find((s: any) => s.value === visibility);
  const productId = +match?.params.productId;

  const fetchProductToEdit = async () => {
    setIsLoadingData(true);
    try {
      await dispatch(fetchCountriesProduct());
      if (productId) {
        await dispatch(fetchProduct(currentCompanyDomain, productId));
      }
      setIsLoadingData(false);

    }
    catch (err) {
      setIsLoadingData(false);
    }
  }

  useLayoutEffect(() => {
    dispatch(resetProductForEditing());
    fetchProductToEdit();
  }, []);

  const onCreateProduct = async (model: any) => {
    await dispatch(createProduct(currentCompanyDomain, model));
    await dispatch(fetchGettingStartedSettings(currentCompanyDomain));
    dispatch(fetchProducts(currentCompanyDomain, 1));
    history.push("/products/products-plans-cycles");
  }

  const onEditProduct = async (model: any) => {
    await dispatch(editProduct(currentCompanyDomain, model as IProductLight));
    dispatch(fetchProducts(currentCompanyDomain, 1));
    history.push("/products/products-plans-cycles");
  }

  if (!productsPaginated.results) {
    return null;
  }

  if (isLoadingData) {
    return <ProgressIndicator color="blue" coverage="full-content"></ProgressIndicator>
  }

  if (isFetchingProductFailure) {
    return (
      <ErrorNotification title="EDIT_PRODUCT_REQUESTED_ID_DOES_NOT_EXIST" />
    )
  }

  return (
    <div>
      <Helmet title={counterpart(!productId ? "NAVBAR_CREATE_PRODUCT" : "NAVBAR_EDIT_PRODUCT")} />
      <NavigationBar
        pageTitle=""
        previousPageTitle="CREATE_PRODUCT_PLANCYCLES_TITLE"
        previousPageCb={() => {
          //dispatch(resetProductForEditing());
          history.push("/products/products-plans-cycles");
        }}
      />
      <NavigationAlertCurrencyMismatch />

      <PageWrapper className="create-product">
        <Panel className="create-product__heading" shouldTranslate={false} title={!productId ? counterpart("CREATE_PRODUCT_HEADING_TITLE") : counterpart("EDIT_PRODUCT_HEADING_TITLE") + `${productName}`}>
          <Text content={!productId ? "CREATE_PRODUCT_HEADING_CONTENT" : "EDIT_PRODUCT_HEADING_CONTENT"} noMargin />
        </Panel>

        <StepCollapsablePanel
          number={1}
          isCompleted={stageStatuses[0] && isProductImageValid}
          isCollapsed={productId ? false : isProductFormCollapsed[0]}
          isShowTitleIcon={!productId}
          onClick={() => {
            dispatch(setProductFormCollapsed(0));
          }}
          onFocus={() => changeCollapsedValue(0, isProductFormCollapsed, dispatch)}
          hasError={hasProductFormError[0] || !isProductImageValid}
          title={!productId ? "CREATE_PRODUCT_FORM_TITLE" : "EDIT_PRODUCT_FORM_TITLE"}
          helpMenuPosition="right"
          helpMenuTitle={helpTextTitle}
          helpMenuContent={
            <div>
              {helpTextContent.map((text, id) => <Text key={id} content={text} />)}
            </div>
          }
        >
          <form ref={formRef0} onChange={() => {
            const form = findDOMNode(formRef0.current) as HTMLFormElement;
            if (form.checkValidity()) {
              dispatch(setProductFormCompleted(0, true));
              dispatch(setProductFormHasError(0, false));
            } else {
              dispatch(setProductFormCompleted(0, false));
              dispatch(setProductFormHasError(0, true));
            }
          }}>
          {!!productError && 
            <Row>
              <Col md={12}>
                <div className="create-product__error-contianer">
                  <NoticePanel type="error" isModal content={productError} shouldTranslate={false} />
                </div>
                </Col>
            </Row>}
            
            <FormGroup>
              <Row align="center">
                <Col md={2}>
                  <FormLabel target="name" content="CREATE_PRODUCT_FORM_NAME" />
                </Col>
                <Col md={10}>
                  <Input
                    id="name"
                    required
                    maxLength={90}
                    value={productName}
                    placeholder={counterpart("CREATE_PRODUCT_FORM_NAME_PLACEHOLDER")}
                    onChange={(evt: any) => dispatch(setProductName(evt.target.value))}
                    onFocus={() => {
                      dispatch(setProductHelpTitle("CREATE_PRODUCT_HELP_NAME_TITLE"));
                      dispatch(setProductHelpContent(["CREATE_PRODUCT_HELP_NAME_1", "CREATE_PRODUCT_HELP_NAME_2", "CREATE_PRODUCT_HELP_NAME_3", "CREATE_PRODUCT_HELP_NAME_4"]));
                    }}
                  />
                </Col>
              </Row>
            </FormGroup>

            <FormGroup>
              <Row align="center">
                <Col md={2}>
                  <FormLabel target="displayname" content="CREATE_PRODUCT_FORM_DISPLAYNAME" />
                </Col>
                <Col md={10}>
                  <Input
                    id="displayname"
                    maxLength={90}
                    placeholder={counterpart("CREATE_PRODUCT_FORM_NAME_PLACEHOLDER")}
                    value={displayName}
                    onChange={(evt: any) => dispatch(setProductDisplayName(evt.target.value))}
                    onFocus={() => {
                      dispatch(setProductHelpTitle("CREATE_PRODUCT_HELP_DISPLAY_NAME_TITLE"));
                      dispatch(setProductHelpContent(["CREATE_PRODUCT_HELP_DISPLAY_NAME_1"]));
                    }}
                  />
                </Col>
              </Row>
            </FormGroup>

            <FormGroup>
              <Row>
                <Col md={2}>
                  <FormLabel className="create-product__description" target="description" content="CREATE_PRODUCT_FORM_DESCRIPTION" />
                </Col>
                <Col md={10}>
                  <Textarea
                    id="description"
                    maxLength={165}
                    className="create-product__description-textarea"
                    value={description}
                    placeholder={counterpart("CREATE_PRODUCT_FORM_DESCRIPTION_DESCRIPTION")}
                    onChange={(evt: any) => dispatch(setProductDescription(evt.target.value))}
                    onFocus={() => {
                      dispatch(setProductHelpTitle("CREATE_PRODUCT_HELP_DESCRIPTION_TITLE"));
                      dispatch(setProductHelpContent(["CREATE_PRODUCT_HELP_DESCRIPTION_1"]));
                    }}
                  />
                </Col>
              </Row>
            </FormGroup>

            <FormGroup>
              <Row align="center">
                <Col md={2}>
                  <FormLabel target="currency" content="CREATE_PRODUCT_FORM_CURRENCY" />
                </Col>
                <Col md={10}>
                  {!productId ?
                    <Dropdown
                      id="currency"
                      dividerAfter={4}
                      onChange={(currency: any) => dispatch(setProductCurrency(currency))}
                      onFocus={() => {
                        dispatch(setProductHelpTitle("CREATE_PRODUCT_HELP_CURRENCY_TITLE"));
                        dispatch(setProductHelpContent(["CREATE_PRODUCT_HELP_CURRENCY_1"]));
                      }}
                      value={currency ? currency : productPlanCycleReducerData.defaultCurrency}
                      options={currenciesDropdown}
                    />
                    :
                    <div className="create-product__currency create-product__currency__locked">
                      <Text content={currency ? currency.label : ""} shouldTranslate={false} />

                    </div>
                  }
                </Col>
              </Row>
            </FormGroup>
            <ProductImageUploader />
          </form>
        </StepCollapsablePanel>
        {
          isCustomFieldsOpened
            ? (
              <CustomFields onClose={() => dispatch(setIsCustomFieldsOpened(false))} />
            )
            : (
              <StepCollapsablePanel
                title={!productId ? "CREATE_PRODUCT_INFO_TITLE" : "EDIT_PRODUCT_FORM_2_TITLE"}
                number={2}
                isCompleted={stageStatuses[1]}
                isCollapsed={productId ? false : isProductFormCollapsed[1]}
                hasError={hasProductFormError[1]}
                isShowTitleIcon={!productId}
                isLocked={!stageStatuses[0]}
                helpMenuPosition="top"
                className="create-product__info-body"
                helpMenuContent={
                  <div className={`create-product__info ${isAddressCollapsed ? "" : "create-product__info-body--opacity"}`}>
                    <Text content="CREATE_PRODUCT_INFO_HELP_EMPTY_1" noMargin component="span" />
                    <i className="fas fa-lock" />
                    <Text content="CREATE_PRODUCT_INFO_HELP_EMPTY_2" noMargin component="span" />
                  </div>
                }
                onClick={() => {
                  dispatch(setProductFormCollapsed(1));
                  const _formRefBtn = (findDOMNode(formRefBtn.current) as HTMLElement);
                  if (_formRefBtn) {
                    _formRefBtn.focus();
                  }
                }}
                onFocus={() => changeCollapsedValue(1, isProductFormCollapsed, dispatch)}
                onBlur={() => {
                  dispatch(setProductFormCompleted(1, true));
                  dispatch(setProductFormHasError(1, false));
                }}
              >
                <div className="create-product__info-body__fields-container">
                  <StepCollapsablePanel
                    title="CREATE_PRODUCT_INFO_NAME_TITLE"
                    className={`${(isAddressCollapsed && !isReorderingCustomFields) ? "create-product__info-body--without-opacity" : "create-product__info-body--hidden"}`}
                    isShowLockIcon
                  />
                  <StepCollapsablePanel
                    title="CREATE_PRODUCT_INFO_EMAIL_TITLE"
                    className={`${(isAddressCollapsed && !isReorderingCustomFields) ? "create-product__info-body--without-opacity" : "create-product__info-body--hidden"}`}
                    isShowLockIcon
                  />

                  <CreateProductAddressValidator />

                  {isAdditionalEmailRequired ?
                    <StepCollapsablePanel
                      title="CUSTOM_FIELDS_ADDITIONAL_EMAILS"
                      subTitle="CUSTOM_FIELDS_ADDITIONAL_EMAILS_SUBTITLE2"
                      className={`${(isAddressCollapsed && !isReorderingCustomFields) ? "create-product__info-body--without-opacity" : "create-product__info-body--hidden"}`}
                      onDeleteClick={() => isAddressCollapsed && dispatch(setIsAdditionalEmailRequired(false))}
                      hasHelpSection={false}
                    />
                    : null
                  }
                  {isPhoneNumberRequired ?
                    <StepCollapsablePanel
                      title="CUSTOM_FIELDS_PHONE_NUMBER"
                      subTitle="CUSTOM_FIELDS_PHONE_NUMBER_SUBTITLE2"
                      className={`${(isAddressCollapsed && !isReorderingCustomFields) ? "create-product__info-body--without-opacity" : "create-product__info-body--hidden"}`}
                      onDeleteClick={() => isAddressCollapsed && dispatch(setIsPhoneNumberRequired(false))}
                      hasHelpSection={false}
                    />
                    : null
                  }
                  {isMarketingConsentRequired ?
                    <StepCollapsablePanel
                      title="CUSTOM_FIELDS_MARKETING_CONSENT"
                      className={`${(isAddressCollapsed && !isReorderingCustomFields) ? "create-product__info-body--without-opacity" : "create-product__info-body--hidden"}`}
                      onDeleteClick={() => isAddressCollapsed && dispatch(setIsMarketingConsentRequired(false))}
                      hasHelpSection={false}
                    />
                    : null
                  }


                  {
                    isReorderingCustomFields
                      ? (
                        <DragDropContainer
                          list={customFieldsTemp.map((customField, idx) => ({
                            content: (
                              <StepCollapsablePanel
                                className="create-product__draggable-panel"
                                key={idx}
                                title={<div>
                                  <Text content={customField.label} shouldTranslate={false} noMargin />
                                  <i className="fas fa-grip-horizontal" />
                                </div>}
                                shouldTranslateTitle={false}
                                hasHelpSection={false}
                              />
                            ),
                            value: customField
                          }))}
                          onDragEnd={(newCustomFieldTemp) => dispatch(setTempProductCustomFields(newCustomFieldTemp))}
                        />
                      )
                      : (
                        customFields.map((customField, idx) => (
                          <StepCollapsablePanel
                            className={`create-product__draggable-panel ${isAddressCollapsed ? "create-product__info-body--without-opacity" : "create-product__info-body--opacity"}`}
                            key={idx}
                            title={customField.label}
                            shouldTranslateTitle={false}
                            onEditClick={() => {
                              dispatch(setCustomFieldsCurrentScreen(CustomFieldScreen.COMPANY_FIELDS));
                              dispatch(setIsCustomFieldsOpened(true));
                              dispatch(setIsQuickEdit(true));
                              dispatch(editCustomField(customField.customFieldId, customField.type, customField.label, customField.description, customField.options, customField.compulsory))}
                            }
                            onDeleteClick={() => dispatch(removeCustomField(customField))}
                            hasHelpSection={false}
                          />
                        ))
                      )
                  }
                </div>

                {
                  isReorderingCustomFields
                    ? (
                      <Row>
                        <Col md={8}>
                          <Button
                            id="create-product-save-order"
                            title="CUSTOM_FIELDS_SAVE_ORDER"
                            onClick={() => {
                              dispatch(setProductCustomFields(customFieldsTemp, true));
                              dispatch(setIsReorderingCustomFields(false));
                            }}
                            isFullWidth
                          />
                        </Col>
                        <Col md={4}>
                          <Button
                            id="create-product-cancel-reorder"
                            buttonType="error"
                            title="CUSTOM_FIELDS_CANCEL_REORDER"
                            onClick={() => {
                              dispatch(setTempProductCustomFields(customFields));
                              dispatch(setIsReorderingCustomFields(false));
                            }}
                            isFullWidth
                          />
                        </Col>
                      </Row>
                    )
                    : (
                      <Row>
                        <Col md={8}>
                          <Button
                            id="create-product-add-field"
                            title="CREATE_PRODUCT_INFO_BUTTON_ADD"
                            icon="fal fa-plus-circle"
                            onClick={() => {
                              dispatch(setStandardField("isAdditionalEmailRequired", isAdditionalEmailRequired));
                              dispatch(setStandardField("isPhoneNumberRequired", isPhoneNumberRequired));
                              dispatch(setStandardField("isMarketingConsentRequired", isMarketingConsentRequired));
                              dispatch(setIsCustomFieldsOpened(true));
                            }}
                            isFullWidth
                            ref={formRefBtn}
                          />
                        </Col>
                        <Col md={4}>
                          <Button
                            id="create-product-reorder"
                            buttonType="add"
                            title="CREATE_PRODUCT_INFO_BUTTON_REORDER"
                            disabled={customFields.length < 2}
                            onClick={() => {
                              dispatch(setIsReorderingCustomFields(true));
                              dispatch(setTempProductCustomFields(customFields));
                            }}
                            icon="fal fa-sort"
                            isFullWidth
                          />
                        </Col>
                      </Row>
                    )
                }
              </StepCollapsablePanel>
            )
        }

        <StepCollapsablePanel
          title={!productId ? "CREATE_PRODUCT_PRODUCT_SETTINGS_TITLE" : "EDIT_PRODUCT_FORM_3_TITLE"}
          number={3}
          isCompleted={stageStatuses[2]}
          isCollapsed={productId ? false : isProductFormCollapsed[2]}
          isShowTitleIcon={!productId}
          onClick={() => {
            dispatch(setProductFormCollapsed(2));            
          }}
          onFocus={() => {
            changeCollapsedValue(2, isProductFormCollapsed, dispatch);
            dispatch(setProductFormCompleted(1, true));
          }}
          hasError={hasProductFormError[2]}
          helpMenuPosition="right"
          helpMenuTitle={productHelpTextTitle}
          isLocked={!stageStatuses[0]}
          helpMenuContent={
            <div>
              {productHelpTextContent.map((text, id) => <Text key={id} content={text} />)}
            </div>
          }
        >
          <FormGroup>
            <Row align="center">
              <Col md={2}>
                <FormLabel target="visibility" content="CREATE_PRODUCT_FORM_3_VISIBILITY" noMargin />
              </Col>
              <Col md={10}>
                <Dropdown
                  id="visibility"
                  onChange={(visibility: any) => {
                    if (visibility) {
                      dispatch(setProductVisibility(visibility.value));
                      dispatch(setProductFormCompleted(2, true));
                      dispatch(setProductFormHasError(2, false));
                    }
                  }}
                  onFocus={() => {
                    dispatch(setProductSettingsHelpTitle("CREATE_PRODUCT_VISIBILITY_HELP_TITLE"));
                    dispatch(setProductSettingsHelpContent(["CREATE_PRODUCT_VISIBILITY_HELP_CONTENT_1", "CREATE_PRODUCT_VISIBILITY_HELP_CONTENT_2", "CREATE_PRODUCT_VISIBILITY_HELP_CONTENT_3", "CREATE_PRODUCT_VISIBILITY_HELP_CONTENT_4", "CREATE_PRODUCT_VISIBILITY_HELP_CONTENT_5"]));
                  }}
                  value={selectedVisibility}
                  options={VISIBILITY_OPTIONS}
                  placeholder={counterpart("CREATE_PRODUCT_FORM_3_VISIBILITY_PLACEHOLDER")}
                />
              </Col>
            </Row>
          </FormGroup>
          <Row align="center">
            <Col md={2}>
              <FormLabel target="alert-email" content="CREATE_PRODUCT_ALERT_EMAIL" noMargin />
            </Col>
            <Col md={10}>
              <Input
                id="alert-email"
                required
                value={alertEmail}
                isError={!isAlertEmailValid}
                placeholder={counterpart("CREATE_PRODUCT_ALERT_EMAIL_PLACEHOLDER")}
                onChange={(evt: any) => {  
                  const isValidEmail = isValidEmailFormat(evt.target.value) || !evt.target.value;
                  dispatch(setProductAlertEmailIsValid(isValidEmail));
                  dispatch(setProductAlertEmail(evt.target.value))}
                }
                onFocus={() => {
                  dispatch(setProductSettingsHelpTitle("CREATE_PRODUCT_ALERT_EMAIL_HELP_TITLE"));
                  dispatch(setProductSettingsHelpContent(["CREATE_PRODUCT_ALERT_EMAIL_HELP_CONTENT_1", "CREATE_PRODUCT_ALERT_EMAIL_HELP_CONTENT_2"]));
                }}
              />
            </Col>
          </Row>
        </StepCollapsablePanel>

        <Button
          id="create-product-btn"
          title={!productId ? "CREATE_PRODUCT_BUTTON" : "EDIT_PRODUCT_BUTTON"}
          // the second section is optional, that needs to be considered to disable the button
          disabled={stageStatuses.filter((val, idx) => idx !== 1).some(val => val === false) || !isProductImageValid || !isAlertEmailValid}
          onClick={() => {
            let currencyForSaving;
            if (currency) {
              currencyForSaving = currency;
            } else if (productPlanCycleReducerData.defaultCurrency) {
              currencyForSaving = productPlanCycleReducerData.defaultCurrency;
            }

            if (visibility && currencyForSaving) {
              const model = {
                productId: productId,
                hasPlans: false,
                name: productName,
                displayName,
                billingCurrency: currencyForSaving.value,
                billingCurrencyTemplate: "",
                description,
                typeOfProduct,
                visibility: VisibilityType[visibility as keyof typeof VisibilityType],
                isAdditionalEmailRequired,
                isPhoneNumberRequired,
                isMarketingConsentRequired,
                isShippingAddressRequired: addressOptions.isShippingAddressRequired,
                isShippingAddressValidationRequired: addressOptions.isShippingAddressValidationRequired,
                isBillingAddressValidationRequired: addressOptions.isBillingAddressValidationRequired,
                alertEmail,
                isProductImageEnabled,
                productImageFileName,
                //isWorldWideAddressAllowed: addressOptions.isWorldWideAddressAllowed,
                orderedCustomFields: customFields.length ? customFields.map(field => ({ customFieldId: field.customFieldId })) : undefined,
                productCountryLimitation: {
                  isAnyCountryAllowed: addressOptions.isWorldWideAddressAllowed,
                  countrySettings: addressOptions.isWorldWideAddressAllowed ? [] : addressOptions.permittedCountries.map(el => ({ countryIso3: el.value.iso3Code, addressPermission: getAddressPermission(el.value) }))
                } as IProductCountryLimitation
              };
              if (!productId) {
                onCreateProduct(model)
              } else {
                onEditProduct(model)
              }
            }
          }}
          isLoading={isCreatingProductRequest && !isCreatingProductFailure || isEditingProductRequest}
          isFullWidth
        />

      </PageWrapper>
    </div>
  )
}




export default CreateProduct