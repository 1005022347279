import React from "react";
import "./SubscriptionsWelcome.scss";

import Panel from "../ui/panel/Panel";
import Text from "../ui/text/Text";
import CirlcleIcon from "../ui/circle-icon/CirlcleIcon";

interface ISubscriptionsWelcome {
  readMeBillsbyApiLink: string
  readMeBillsbyCheckoutLink: string,
}

export const SubscriptionsWelcome = ({ readMeBillsbyApiLink, readMeBillsbyCheckoutLink }: ISubscriptionsWelcome) => {
  return (
    <div className="subscriptions-welcome">
     <Panel className="subscriptions-welcome__greeting" title="SUBSCRIPTIONS_WELCOME_TITLE">
      <Text content="SUBSCRIPTIONS_WELCOME_TEXT" />
      <Panel className="subscriptions-welcome__greeting-instructions" title="SUBSCRIPTIONS_WELCOME_INSTRUCTIONS_TITLE">
        <div className="subscriptions-welcome__greeting-instruction">
          <CirlcleIcon nr={1} icon={"far fa-code"} className={"subscriptions-welcome__greeting-icon"} />
          <Text content="SUBSCRIPTIONS_WELCOME_INSTRUCTIONS1_TEXT1" noMargin component="span"/>
          <a href={readMeBillsbyApiLink} target="_self">
            <Text content="SUBSCRIPTIONS_WELCOME_INSTRUCTIONS1_LINK" noMargin component="span"/>
          </a>          
        </div>
        <div className="subscriptions-welcome__greeting-instruction">          
          <CirlcleIcon nr={1} icon={"far fa-shopping-cart"} className={"subscriptions-welcome__greeting-icon"} />          
          <Text content="SUBSCRIPTIONS_WELCOME_INSTRUCTIONS2_TEXT1" noMargin component="span"/>
          <a href={readMeBillsbyCheckoutLink} target="_self">
            <Text content="SUBSCRIPTIONS_WELCOME_INSTRUCTIONS2_LINK" noMargin component="span"/>
          </a>
          <Text content="SUBSCRIPTIONS_WELCOME_INSTRUCTIONS2_TEXT2" noMargin component="span"/>
        </div>
        {/* <div className="subscriptions-welcome__greeting-instruction">
          <CirlcleIcon nr={1} icon={"far fa-pencil"} className={"subscriptions-welcome__greeting-icon"} />
          <Text content="SUBSCRIPTIONS_WELCOME_INSTRUCTIONS3_TEXT1" noMargin component="span"/>
          <Button id="subscriptions-welcome-btn" icon="far fa-plus-circle" buttonType="add" title="SUBSCRIPTIONS_WELCOME_INSTRUCTIONS3_BUTTON" className="subscriptions-welcome__greeting-button"/>
          <Text content="SUBSCRIPTIONS_WELCOME_INSTRUCTIONS3_TEXT2" noMargin component="span"/>
        </div> */}
      </Panel>
     </Panel>
    </div>
  )
}

export default SubscriptionsWelcome;