import React from "react";
import { ICustomFieldsReducer } from "../../reducers/customFieldsReducer";
import Text from "../ui/text/Text";
import integrationFieldPlaceholder from "../../images/work-in-progress.svg";
import { useSelector } from "react-redux";
import { AppState } from "../..";


const IntegrationFields: React.FC = () => {
  const customFieldsData = useSelector<AppState, ICustomFieldsReducer>(state => state.customFields)
  return (
    <div className="custom-fields__integrations">
      <Text content="COMPANY_FIELDS_INTEGRATIONS_TITLE" className="custom-fields__company__title" />
      <Text content="COMPANY_FIELDS_INTEGRATIONS_SUTITLE" noMargin />
      <img src={integrationFieldPlaceholder} alt="no img" />
    </div>
  )
}

export default IntegrationFields;