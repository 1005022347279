import React, { useEffect, useState } from "react";
import history from "../../../../../../utils/history";
import Panel from "../../../../../ui/panel/Panel";
import Text from "../../../../../ui/text/Text";
import "./SuccessfulReattemptEmailTemplate.scss";
import { AppState } from "../../../../../..";
import { useDispatch, useSelector } from "react-redux";
import { Row, Col } from "react-grid-system";
import EmailTextArea from "../../emailForms/emailTextArea/EmailTextArea";
import EmailSubject from "../../emailForms/emailSubject/EmailSubject";
import BbcSettings from "../../emailForms/bbcSettings/BbcSettings";
import EmailPreivewContainer from "../../../brand-customization/email-preview/EmailPreivewContainer";
import BackNavigationPanel from "../../../../../ui/back-navigation-panel/BackNavigationPanel";
import counterpart from "counterpart";
import { IEmailCustomizationReducerState } from "../../../../../../reducers/emailCustomizationReducer";
import { getEmailTemplateValues, setEmailTemplateField, resetEmailTemplateValues, updateEmailTemplateValues } from "../../../../../../actions/emailCustomizationActions";
import { EmailTypes } from "../../../../../../utils/grpc/generated/Billsby.Protos/Messenger/private/emailconfig/emailconfig_pb";

const SuccessfulReattemptEmailTemplate: React.FC = () => {
  const companyBrandingReducer = useSelector((state: AppState) => state.companyBrandingReducer);
  const emailCustomizationReducer = useSelector((state: AppState) => state.emailCustomizationReducer);
  const authReducer = useSelector((state: AppState) => state.auth);

  const dispatch = useDispatch<Function>();

  const { highlightColor } = companyBrandingReducer;
  const { subjectLine, customArea1, customArea2, sendToCompanyAlertEmail, sendToProductAlertEmail, sendToPlanAlertEmail} = emailCustomizationReducer;
  const { currentCompanyId, companyName } = authReducer;
  const defaultHighLightColor = "#F87847";
  type allowLoadingType = "subject" | "custom1" | "custom2" | "bbc";
  const [subjectIsLoading, setSubjectIsLoading] = useState(false);
  const [custom1IsLoading, setCustom1IsLoading] = useState(false);
  const [custom2IsIsLoading, setCustom2IsIsLoading] = useState(false);
  const [bbcIsLoading, setBbcIsLoading] = useState(false);

  useEffect(() => {
    dispatch(getEmailTemplateValues(currentCompanyId as number, companyName, EmailTypes.PAYMENT_SUCCEEDED_AFTER_FAILURE));
    return () => dispatch(resetEmailTemplateValues());
  }, []);

  const setLoading = (loadingType: allowLoadingType, value: boolean) => {
    switch (loadingType){
      case "subject": 
      setSubjectIsLoading(value);
      break;
      case "custom1": 
      setCustom1IsLoading(value);
      break;
      case "custom2": 
      setCustom2IsIsLoading(value);
      break;
      case "bbc": 
      setBbcIsLoading(value);
      break;
    }
  }

  const saveChanges = async (loadingType: allowLoadingType) => {
    setLoading(loadingType, true);
    try {
      const data = {...emailCustomizationReducer} as IEmailCustomizationReducerState;
      await dispatch(updateEmailTemplateValues(currentCompanyId as number, data));
    }
    catch { }
    finally{
      setLoading(loadingType, false);
    }
  }

  return (
    <div>
      <BackNavigationPanel
        title="DECLINED_PAYMENTS_EMAIL_TEMPLATE_BACK_PANEL_TITLE_SUCCESSFUL_REATTEMPT" shouldTranslateTitle={true}
        onClick={() => history.push("/configuration/dunning-process")} />

      <Row>
        <Col sm={8}>
          <Panel className="successful-reattempt-email-template__panel" noMargin>
            <EmailPreivewContainer>

              <Text
                content="DECLINED_PAYMENTS_EMAIL_TEMPLATE_GREETINGS"
                className="email-preview__details__text"
                component="span"
                noMargin
              />

              <Text
                content={<span dangerouslySetInnerHTML={{__html: customArea1}}></span>}
                shouldTranslate={false}
                className="email-preview__details__text bar bar-blue"
                component="span"
                noMargin
              />

              <Text  
                style={{ color: !!highlightColor ? highlightColor : defaultHighLightColor }}
                content="DECLINED_PAYMENTS_EMAIL_TEMPLATE_VIEW_INVOICE_SUCCESSFUL_REATTEMPT"
                className="email-preview__details__text email-preview__details__text-underlined"
                component="span"
                noMargin
              />

              <Text
                content={<span dangerouslySetInnerHTML={{__html: customArea2}}></span>}
                shouldTranslate={false}
                className="email-preview__details__text bar bar-orange"
                component="span"
                noMargin
              />

              <Text
                content="DECLINED_PAYMENTS_EMAIL_TEMPLATE_SUSPENDED_SUBSCRIPTION_SUCCESSFUL_REATTEMPT"
                className="email-preview__details__text email-preview__details__text-bold"
                component="span"
                noMargin
              />

              <Text
                content="DECLINED_PAYMENTS_EMAIL_TEMPLATE_REAGRDS"
                className="email-preview__details__text"
                component="span"
                noMargin
              />

              <Text
                content={companyName}
                className="email-preview__details__text"
                component="span"
                shouldTranslate={false}
                noMargin
              />
            </EmailPreivewContainer>
          </Panel>
        </Col>
        <Col sm={4}>

          <EmailSubject title={counterpart("DECLINED_PAYMENTS_EMAIL_TEMPLATE_SUBJECT_LINE")} id={"custom-copy"} value={subjectLine}
            buttonText={counterpart("DECLINED_PAYMENTS_EMAIL_TEMPLATE_SUBJECT_LINE_BUTTON_TITLE")}
            isLoading={subjectIsLoading}
            onSubmit={() => saveChanges("subject")} onChange={(evt: any) => {dispatch(setEmailTemplateField("subjectLine", evt.target.value))}} />

          <EmailTextArea title={counterpart("DECLINED_PAYMENTS_EMAIL_TEMPLATE_CUSTOM_COPY_AREA_ONE")}
            description={counterpart("DECLINED_PAYMENTS_EMAIL_TEMPLATE_CUSTOM_COPY_AREA_ONE_DESCRIPTION")}
            id={"custom-copy"} value={customArea1}
            isLoading={custom1IsLoading}
            buttonText={counterpart("DECLINED_PAYMENTS_EMAIL_TEMPLATE_CUSTOM_COPY_AREA_ONE_BUTTON_TITLE")}
            onSubmit={() => saveChanges("custom1")} onChange={(evt: any) => {dispatch(setEmailTemplateField("customArea1", evt.target.value))}} />

          <EmailTextArea title={counterpart("DECLINED_PAYMENTS_EMAIL_TEMPLATE_CUSTOM_COPY_AREA_TWO")}
            className="successful-reattempt-email-template__panel-header-orange"
            description={counterpart("DECLINED_PAYMENTS_EMAIL_TEMPLATE_CUSTOM_COPY_AREA_TWO_DESCRIPTION")}
            id={"custom-copy-two"} value={customArea2}
            isLoading={custom2IsIsLoading}
            buttonText={counterpart("DECLINED_PAYMENTS_EMAIL_TEMPLATE_CUSTOM_COPY_AREA_TWO_BUTTON_TITLE")}
            onSubmit={() => saveChanges("custom2")} onChange={(evt: any) => {dispatch(setEmailTemplateField("customArea2", evt.target.value))}} />

          <BbcSettings title={counterpart("DECLINED_PAYMENTS_EMAIL_TEMPLATE_CONFIGURE_BCC_SETTINGS")}
            noMargin={true} companyAlert={sendToCompanyAlertEmail} productAlert={sendToProductAlertEmail} planAlert={sendToPlanAlertEmail}
            id={"custom-copy-two"}
            buttonText={counterpart("DECLINED_PAYMENTS_EMAIL_TEMPLATE_CONFIGURE_BCC_SETTINGS_UPDATE_BUTTON")}
            onSubmit={() => saveChanges("bbc")}
            isLoading={bbcIsLoading}
            onCompanyAlertChange={() => {dispatch(setEmailTemplateField("sendToCompanyAlertEmail", !sendToCompanyAlertEmail))}}
            onProductAlertChange={() => {dispatch(setEmailTemplateField("sendToProductAlertEmail", !sendToProductAlertEmail))}}
            onPlanAlertChange={() => {dispatch(setEmailTemplateField("sendToPlanAlertEmail", !sendToPlanAlertEmail))}} />

        </Col>
      </Row>
    </div>
  )
}

export default SuccessfulReattemptEmailTemplate;