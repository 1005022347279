import API from "../utils/API";
import { PAGINATION_PAGE_SIZE } from "../utils/constants";
import { makeActionCreator } from "../utils/reduxUtils";

export const FETCH_EVENT_LOGS_REQUEST = "FETCH_EVENT_LOGS_REQUEST";
export const FETCH_EVENT_LOGS_SUCCESS = "FETCH_EVENT_LOGS_SUCCESS";
export const FETCH_EVENT_LOGS_FAILURE = "FETCH_EVENT_LOGS_FAILURE";

export const RESET_EVENT_LOGS = "RESET_EVENT_LOGS";
export const resetEventLogs = makeActionCreator(RESET_EVENT_LOGS);

export const fetchEventLogs = function (companyDomain: string, subscriptionId: string, pageNumber: number, pageSize: number = PAGINATION_PAGE_SIZE, 
  isLoadMore = false) {
  return {
    types: [FETCH_EVENT_LOGS_REQUEST, FETCH_EVENT_LOGS_SUCCESS, FETCH_EVENT_LOGS_FAILURE],
    callAPI: () => API.fetchEventLogs(companyDomain, subscriptionId, pageNumber, pageSize),
    payload: { isLoadMore }
  }
}