import React, { useEffect, useState } from "react";
import InvoicePrint from "../../components/invoice-print/InvoicePrint";
import "./InvoiceStandalonePreview.scss";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../..";
import { CustomerDataStatus, IInvoiceDetails, PaymentMethodType } from "../../models/Customer";
import { InvoiceConfigType, InvoiceRefundType, InvoiceStatus, StandAloneInvoiceType } from "../../models/Invoices";
import { ICompanyBrandingReducerState } from "../../reducers/companyBrandingReducer";
import { fetchCompanyBranding } from "../../actions/companyBrandingActions";
import { IAuthReducerState } from "../../reducers/authReducer";
import InvoiceStandaloneSidebarPreview from "../../components/invoice-standalone-sidebar-preview/InvoiceStandaloneSidebarPreview";
import { camelize } from "../../utils/commonUtils";
import { IInvoicesCreditNotesCustomizationState, ISupportContactDetails } from "../../reducers/invoicesCreditNotesCustomizationReducer";
import { fetchInvoiceCreditNotesConfig, fetchInvoiceCreditNotesFooter } from "../../actions/invoicesCreditNotesCustomizationActions";
import { useParams } from "react-router";


const InvoiceStandalonePreview: React.FC = () => {
  const { supportContactDetails, footerContents } = useSelector<AppState, IInvoicesCreditNotesCustomizationState>(state => state.invoicesCreditNotesCustomizationReducer);
  const companyBrandingData = useSelector<AppState, ICompanyBrandingReducerState>(state => state.companyBrandingReducer)
  const auth = useSelector<AppState, IAuthReducerState>(state => state.auth)
  const dispatch = useDispatch()
  const params = useParams<{ invoiceConfigType: InvoiceConfigType }>() ;
  const paramData = params.invoiceConfigType || "paid-invoices";
  const invoiceConfigType = (camelize(paramData)[0].toUpperCase() + camelize(paramData).slice(1, -1)) as InvoiceConfigType;

  const currentSupportContactDetails: ISupportContactDetails | undefined = supportContactDetails.find(details => details.invoiceType === invoiceConfigType);

  const [isLoadingData, setIsLoadingData] = useState(false);

  const fetchData = async () => {
    setIsLoadingData(true);
    try {
      await dispatch(fetchCompanyBranding(auth.currentCompanyDomain));
      await dispatch(fetchInvoiceCreditNotesConfig(auth.currentCompanyDomain));
      await dispatch(fetchInvoiceCreditNotesFooter(Number(auth.currentCompanyId)))
      setIsLoadingData(false);
    } catch (err) {
      setIsLoadingData(false);
      console.log(err);
    }
  };

  useEffect(() => {
    fetchData();
  }, [auth.currentCompanyDomain]);

  if (isLoadingData || !currentSupportContactDetails) {
    return null;
  }

  const { logoUrl, highlightColor, logoBackgroundColor } = companyBrandingData;

  const getPreviewStatus = () => {
    if (invoiceConfigType === InvoiceConfigType.PAID_INVOICE || invoiceConfigType === InvoiceConfigType.PAID_CREDIT_NOTE) {
      return InvoiceStatus.PAID;
    }

    if (invoiceConfigType === InvoiceConfigType.PENDING_INVOICE || invoiceConfigType === InvoiceConfigType.PENDING_CREDIT_NOTE) {
      return InvoiceStatus.PENDING;
    }

    if (invoiceConfigType === InvoiceConfigType.UNPAID_INVOICE || invoiceConfigType === InvoiceConfigType.FAILED_CREDIT_NOTE) {
      return InvoiceStatus.UNPAID;
    }

    return InvoiceStatus.PAID;
  };

  const getInvoiceType = () => {
    if (invoiceConfigType === InvoiceConfigType.PAID_INVOICE || invoiceConfigType === InvoiceConfigType.PENDING_INVOICE || invoiceConfigType === InvoiceConfigType.UNPAID_INVOICE) {
      return StandAloneInvoiceType.RENEWAL;
    }

    if (invoiceConfigType === InvoiceConfigType.PAID_CREDIT_NOTE || invoiceConfigType === InvoiceConfigType.PENDING_CREDIT_NOTE || invoiceConfigType === InvoiceConfigType.FAILED_CREDIT_NOTE) {
      return StandAloneInvoiceType.REFUND;
    }

    return StandAloneInvoiceType.RENEWAL;
  };

  const isInvoice = getInvoiceType() === StandAloneInvoiceType.RENEWAL;

  let previewData: IInvoiceDetails = {
    invoiceId: 100,
    customerEmail: "sample@customer.com",
    customerFirstName: "[Customer name]",
    customerLastName: "",
    customerStatus: CustomerDataStatus.ACTIVE,
    customerPaymentMethodTypeName: PaymentMethodType.CREDIT_CARD,
    prorationAdjustment: "",
    prorationFactor: undefined,
    customerUniqueId: "A153YZ6312A3",
    customerTaxRegNumber: "12345678912",
    addressLine1: "123 Anytown",
    addressLine2: "Anyville",
    addressCity: "Anycity",
    addressPostCode: "1234",
    addressState: "Anystate",
    addressCountryCode: "ANY",
    addressCountry: "Anycountry",
    invoiceNumber: "A153YZ6312A3-001",
    createdOn: "2019-11-19T11:47:24.007329",
    amount: 100,
    formattedAmount: isInvoice ? "$17.12" : "-$10.00",
    currency: "USD",
    status: getPreviewStatus(),
    subTotal: isInvoice ? "$16.00" : "-$10.70",
    taxTotal: isInvoice ? "$1.12" : "-$0.70",
    taxes: [],
    companyDomain: "dsfsdf",
    taxPercentage: 7,
    cardExpiryMonth: 100,
    cardExpiryYear: 100,
    cardLast4Digits: "1234",
    supportUrl: "",
    subscriptions: isInvoice
      ? [
          {
            planName: "Pro Plan",
            productName: "Pro Product",
            planDisplayName: "Pro Plan",
            productDisplayName: "Pro Product",
            subscriptionUniqueId: "B531DS6357",
            billingPeriodStart: "2019-12-19T11:47:23.912224",
            billingPeriodEnd: "2019-11-19T11:47:23.912224",
            subscriptionItems: [
              {
                description: "Pro Plan - Monthly",
                units: 1,
                unitPrice: "$12.00",
                discounts: "$2.00",
                amount: "$10.00"
              }
            ]
          },
          {
            planName: "Basic Plan",
            productName: "Basic Product",
            planDisplayName: "Basic Plan",
            productDisplayName: "Basic Product",
            subscriptionUniqueId: "E531DS65361",
            billingPeriodStart: "2019-12-19T11:47:23.912224",
            billingPeriodEnd: "2019-11-19T11:47:23.912224",
            subscriptionItems: [
              {
                description: "Basic Plan - Monthly",
                units: 1,
                unitPrice: "$6.00",
                discounts: "-",
                amount: "$6.00"
              }
            ]
          }
        ]
      : [
          {
            planName: "-",
            productName: "-",
            planDisplayName: "-",
            productDisplayName: "-",
            subscriptionUniqueId: "E531DS65361",
            billingPeriodStart: "2019-12-19T11:47:23.912224",
            billingPeriodEnd: "2019-11-19T11:47:23.912224",
            subscriptionItems: [
              {
                description: "Refund of customerID-xxxxx",
                units: 1,
                unitPrice: "-$10.00",
                discounts: "-",
                amount: "-$10.00"
              }
            ]
          }
        ],
    companyName: footerContents.companyName || auth.companyName,
    companyPhone: footerContents.phoneNumber || "",
    companyEmail: footerContents.emailAddress || "",
    companyTaxLabel: "",
    companyTaxNumber: "",
    refundableAmount: 100,
    brandingLogo: logoUrl,
    brandingLogoBackgroundColor: logoBackgroundColor.replace("#", ""),
    brandingHighlightColor: highlightColor.replace("#", ""),
    invoiceType: getInvoiceType(),
    cardType: "",
    companyCity: footerContents.addressCity,
    companyCountry: footerContents.addressCountry.value,
    companyAddressLine1: footerContents.addressLine1,
    companyAddressLine2: footerContents.addressLine2,
    companyPostCode: footerContents.addressPostCode,
    companyState: footerContents.addressState.value,
    companyPhoneNumber: footerContents.phoneNumber,
    companyPhoneNumberDialCode: footerContents.phoneNumberDialCode,
    companyPhoneNumberDialCountry: footerContents.phoneNumberDialCountry,
    supportContactContent: currentSupportContactDetails.content,
    supportContactHeading: currentSupportContactDetails.heading,
    supportContactSupportUrl: currentSupportContactDetails.supportPageUrl,
    supportContactSupportUrlButtonText: currentSupportContactDetails.supportPageUrlButtonText,
    advertImageUrl: currentSupportContactDetails.advertImageUrl,
    advertLinkToUrl: currentSupportContactDetails.advertLinkToUrl,
    isReattemptable: false,
    isRefundable: false,
    refundType: InvoiceRefundType.AVAILABLE
  };

  return (
    <div className="invoice-standalone">
      <InvoicePrint isStandAlone data={previewData} />
      <InvoiceStandaloneSidebarPreview invoiceConfigType={invoiceConfigType} data={previewData} />
    </div>
  );
};

export default InvoiceStandalonePreview;
