import React from "react";
import counterpart from "counterpart";
import "./Panel.scss";

interface IPanel {
  /**
   * optional id
   */
  id?: string,
  /**
    * Change the background colour
    */
  clear?: boolean,
  /**
   * The elements which will be wrapped by the panel
   */
  children: any,
  /**
   * Change the style to a success panel
   */
  success?: boolean,
  /**
   * Change the style to a warning panel
   */
  warning?: boolean,
  /**
   * The title of the panel
   */
  title?: string,
  /**
   * Allow custom styling
   */
  className?: string,
  /**
   * enable translation
   */
  shouldTranslate?: boolean,
  /**
   * add dots to border
   */
  dotted?: boolean,
  /**
   * remove border
   */
  noBorder?: boolean,
  /**
   * add inline style
   */
  style?: object,
  /**
   * callback on click on the panel container
   */
  onClick?: (evt: any) => void,
  /**
   * callback on focus on the panel container
   */
  onFocus?: (evt: any) => void,
  /**
   * callback on focus out on the panel container
   */
  onBlur?: (evt: any) => void,
  /**
   * callback to be triggered on hover in
   */
  onMouseEnter?: () => void,
  /**
   * callback to be triggered on hover out
   */
  onMouseLeave?: () => void,
  /*
   * Remove the bottom margin
   */
  noMargin?: boolean,
  /*
   * Anything dynamic data which needs translating
  */
  translateWith?: object,
  /*
   * Content of ribbon tag
  */
  ribbonContent?: string,
}

const Panel: React.FC<IPanel> = ({
  children = null,
  warning = false,
  success = false,
  title = "",
  className = "",
  style = {},
  shouldTranslate = true,
  dotted = false,
  translateWith = {},
  clear = false,
  onClick = () => { },
  onFocus = () => { },
  onBlur = () => { },
  onMouseEnter = () => { },
  onMouseLeave = () => { },
  noMargin = false,
  noBorder = false,
  id,
  ribbonContent,
  ...rest
}) => {

  const valueSetter = (check: boolean, value: string) => check ? value : "";

  const isWarning = valueSetter(warning, "content-panel--warning");
  const isSuccess = valueSetter(success, "content-panel--success");
  const isDotted = valueSetter(dotted, "content-panel--dotted");
  const isNoBorder = valueSetter(noBorder, "content-panel--noborder");
  const isClear = valueSetter(clear, "content-panel--clear");
  const onClickCb = typeof onClick === "function" ? { onClick } : {};
  const onFocusCb = typeof onFocus === "function" ? { onFocus } : {};
  const onBlurCb = typeof onBlur === "function" ? { onBlur } : {};
  const onMouseEnterCb = typeof onMouseEnter === "function" ? { onMouseEnter } : {};
  const onMouseLeaveCb = typeof onMouseLeave === "function" ? { onMouseLeave } : {};
  const margin = valueSetter(noMargin, "mb0");
  const idProp = id ? { id } : {};
  return (
    <section className={`content-panel ${isWarning} ${isSuccess} ${isDotted} ${isClear} ${margin} ${isNoBorder} ${className} ${ribbonContent ? "content-panel--with-ribbon" : ""}`} style={style} {...onClickCb} {...onFocusCb} {...onBlurCb} {...onMouseEnterCb} {...onMouseLeaveCb} {...idProp} {...rest}>
      {ribbonContent && <span className="content-panel__ribbon">{counterpart(ribbonContent)}</span>}
      {title && shouldTranslate && <h6 className="content-panel-title">{counterpart(title, translateWith)}</h6>}
      {title && !shouldTranslate && <h6 className="content-panel-title">{title}</h6>}
      {children}
    </section>
  );
}


export default Panel;
