import React from "react";
import { ICustomFieldsReducer } from "../../reducers/customFieldsReducer";
import { IAuthReducerState } from "../../reducers/authReducer";
import Text from "../ui/text/Text";
import companyFieldPlaceholder from "../../images/productPlanCycle/744773_billsbot_sewing_illustration.svg";
import Search from "../search/Search";
import counterpart from "counterpart";
import GenericFieldPanel from "./generic-field-panel/GenericFieldPanel";
import { setSearchedCustomField, selectCustomField, editCustomField } from "../../actions/customFieldsActions";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../..";



const getMaxLengthCompanyName = (companyName: string) => {
  const MAX_LENGTH = 12;
  if(companyName.length > MAX_LENGTH) {
    return companyName.substring(0, MAX_LENGTH) + "..";
  }
  return companyName;
}

const CompanyFields: React.FC = () => {
  const { newCustomFields, searchedCustomField, filteredCustomFields } = useSelector<AppState, ICustomFieldsReducer>(state => state.customFields)
  const authData = useSelector<AppState, IAuthReducerState>(state => state.auth)
  const dispatch = useDispatch<Function>()

  if (!newCustomFields.length) {
    return (
      <div className="custom-fields__company">
        <Text content="COMPANY_FIELDS_TITLE" className="custom-fields__company__title" translateWith={{ companyName: authData.companyName }} />
        <Text content="COMPANY_FIELDS_SUBTITLE" noMargin />
        <img src={companyFieldPlaceholder} alt="no img" />
        <div className="custom-fields__company__footer">
          <Text content="COMPANY_FIELDS_FOOTER_TITLE" />
          <Text content="COMPANY_FIELDS_FOOTER_SUBTITLE" noMargin />
        </div>
      </div>
    )
  }

  return (
    <div className="custom-fields__company">
      <div className="custom-fields__company__titlecontainer">
        <div>
          <Text content="COMPANY_FIELDS_TITLE" className="custom-fields__company__title" translateWith={{ companyName: authData.companyName }} />
          <Text content="COMPANY_FIELDS_SUBTITLE" noMargin />
        </div>
        <Search 
          value={searchedCustomField}
          placeholder={counterpart("COMPANY_FIELDS_SEARCH_PLACEHOLDER", { companyName: getMaxLengthCompanyName(authData.companyName) })} 
          onChange={(evt: any) => dispatch(setSearchedCustomField(evt.target.value))  } 
          shouldTranslatePlaceholder={false} 
        />
      </div>

      {
        filteredCustomFields.map((field, idx) => (
          <GenericFieldPanel
            key={idx}
            title={field.label}
            shouldTranslateTitle={false}
            editIcon={{ onClick: () => {dispatch(editCustomField(field.customFieldId, field.type, field.label, field.description, field.options, field.compulsory))} }}
            addIcon={{ isChecked: field.isSelected, onChange: () => dispatch(selectCustomField(field.customFieldId, !field.isSelected)) }}
          />
        ))
      }
    </div>
  )
}

export default CompanyFields;