import counterpart from "counterpart";
import React from "react"
import { Col, Row } from "react-grid-system";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../../../../..";
import { AllowedCheckoutMgmtFields, setCheckoutAccountMgmtField } from "../../../../../actions/checkoutAccountManagementActions";
import { ICheckoutAccountManagementReducerState } from "../../../../../reducers/checkoutAccountManagementReducer";
import FormGroup from "../../../../ui/form-group/FormGroup";
import FormLabel from "../../../../ui/form-label/FormLabel";
import Input from "../../../../ui/input/Input";

interface IProps {
  valueSingular: string,
  valuePlural: string
  isLock: boolean
  name: string,
  singularField: AllowedCheckoutMgmtFields,
  pluralField: AllowedCheckoutMgmtFields,
  singularPlaceHolder: string,
  pluralPlaceHolder: string,
  label: string
  maxLength:number
}

const TerminologyInput:React.FC<IProps> = ({ valueSingular, valuePlural, isLock, name,
  singularField, pluralField, singularPlaceHolder, pluralPlaceHolder, label, maxLength }) => {

  const { checkoutManagement } = useSelector<AppState, ICheckoutAccountManagementReducerState>(state => state.checkoutAccountManagementReducer)
  const dispatch = useDispatch<Function>()

  const updateCheckoutField = (arg: AllowedCheckoutMgmtFields, value: any) => {
    dispatch(setCheckoutAccountMgmtField("checkoutManagement", { ...checkoutManagement, [arg]: value }));
  }

  const isValidTerminology = (value: string) => value === value.toLowerCase()
  
  return (
    <FormGroup>
      <Row align="center">
        <Col md={1}>
          <FormLabel target={`${name}-single-product`} content={label} noMargin />
        </Col>
        <Col md={5.5}>
          <Input
            type="text"
            placeholder={counterpart(singularPlaceHolder)}
            showDisabledIcon={false}
            id={`${name}-single-product`}
            isError={false}
            maxLength={maxLength}
            value={valueSingular || ""}
            onChange={(evt: any) => isValidTerminology(evt.target.value) && updateCheckoutField(singularField, evt.target.value)}
            disabled={isLock}
          />
        </Col>
        <Col md={5.5}>
          <Input
            type="text"
            placeholder={counterpart(pluralPlaceHolder)}
            showDisabledIcon={false}
            id={`${name}-multiple-product`}
            isError={false}
            maxLength={maxLength}
            value={valuePlural || ""}
            onChange={(evt: any) => isValidTerminology(evt.target.value) && updateCheckoutField(pluralField, evt.target.value)}
            disabled={isLock}
          />
        </Col>
      </Row>
    </FormGroup>
  )
}

export default TerminologyInput;