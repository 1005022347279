import React, { useState } from "react";
import Text from "../text/Text";
import Tooltip from "../tooltip/Tooltip";
import "./SeeMoreText.scss";
import counterpart from "counterpart";

interface ISeeMoreText {
  maxChar?: number;
  label?: string;
  shouldTranslateLabel?: boolean;
  content: string;
  shouldTranslateContent?: boolean;
  id?: number;
  showTooltip?: boolean;
}

const SeeMoreText: React.FC<ISeeMoreText> = React.memo(
  ({ maxChar = 180, label = "SEE_MORE_LABEL", shouldTranslateLabel = true, content, shouldTranslateContent = true, showTooltip = false, id = 0 }) => {
    const [isChecked, setIsChecked] = useState(false);

    const getSeeMoreDOM = (contentTranslated: string) => {
      if (contentTranslated.length > maxChar && showTooltip) {
        return (
          <div className="see-more-text">
            <Tooltip id={id.toString()} place="bottom" type="blue">
              <Text content={contentTranslated} shouldTranslate={false} noMargin />
            </Tooltip>
            <span className="see-more-text__with-tooltip" data-tip data-for={id.toString()}>
              <Text content={`${contentTranslated.substring(0, maxChar)}...`} component="span" shouldTranslate={false} noMargin />
            </span>
          </div>
        );
      }

      if (contentTranslated.length > maxChar && !isChecked) {
        return (
          <div className="see-more-text">
            <Text content={contentTranslated.substring(0, maxChar)} shouldTranslate={false} noMargin />
            <Text content={label} shouldTranslate={shouldTranslateLabel} noMargin onClick={() => setIsChecked(!isChecked)} />
          </div>
        );
      }

      return <Text content={contentTranslated} shouldTranslate={false} noMargin />;
    };

    if (content && shouldTranslateContent) {
      const contentTranslated = counterpart(content);
      return getSeeMoreDOM(contentTranslated);
    }

    return getSeeMoreDOM(content);
  }
);

export default SeeMoreText;
