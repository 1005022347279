import React, { useState, useEffect } from "react";
import Panel from "../../../ui/panel/Panel";
import Text from "../../../ui/text/Text";
import history from "../../../../utils/history";
import NavigationBar from "../../../ui/navigation-bar/NavigationBar";
import PageWrapper from "../../../ui/layout/PageWrapper";
import { Col, Row } from "react-grid-system";
import Button from "../../../ui/button/Button";
import "./AllowanceConfig.scss";
import Checkbox from "../../../ui/checkbox/Checkbox";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../../../..";
import { updateTeammateAllowanceManualUpdate, fetchGettingStartedSettings } from "../../../../actions/authActions";

const AllowanceConfig: React.FC = () => {
  const authReducer = useSelector((state: AppState) => state.auth);

  const dispatch = useDispatch<Function>();

  const [allowMannualUpdate, setAllowManualUpdate] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const { dashboardSettings } = authReducer;

  useEffect(() => {
    if (dashboardSettings) {
      setAllowManualUpdate(dashboardSettings.isTeammateManualAllowanceUsageUpdateAllowed);
    }
  }, [dashboardSettings])

  const updateAllowTeamMateManualUpdate = () => {
    setIsLoading(true);
    dispatch(updateTeammateAllowanceManualUpdate(Number(authReducer.currentCompanyId), allowMannualUpdate))
      .then(() => dispatch(fetchGettingStartedSettings(authReducer.currentCompanyDomain)))
      .finally(() => setIsLoading(false));
  }

  if (!dashboardSettings)
    return null;

  return (
    <div className="allowance-config">
      <NavigationBar pageTitle="" previousPageTitle="CONFIGURATION_PAGE_TITLE" previousPageCb={() => history.push("/configuration")} />

      <PageWrapper>
        <Panel title="ALLOWANCE_CONFIG_TITLE" className="allowance-config__heading">
          <Text content="ALLOWANCE_CONFIG_TEXT_TEMPORARY" className="allowance-config__heading-text" noMargin />
          {/*
            TODO
            // this is the original copy but since we didn't implement fully the screen we use the temporary one above 
            <Text content="ALLOWANCE_CONFIG_TEXT" className="allowance-config__heading-text" noMargin />
          */}
        </Panel>

        {/* <Panel title="ALLOWANCE_CONFIG_FREE_TRIALS">
          <Text content="ALLOWANCE_CONFIG_FREE_TRIALS_TEXT" />
          <FormGroup>
            <Row align="center">
              <Col md={4}>
                <FormLabel noMargin target={`allowance-config-add-allowance-switch`} content="ALLOWANCE_CONFIG_FREE_TRIALS_ADD_ALLOWANCES" />
              </Col>
              <Col md={8}>
                <Switch
                  id={`allowance-config-add-allowance-switch`}
                  checked={true}
                  onChange={() => { }}
                  className="allowance-config__allow-customer-switch"
                />
              </Col>
            </Row>
          </FormGroup>

          <Checkbox
            key={'type.value'}
            checked={true}
            onClick={() => { }}
            value={1}
            content={<Text content={"ALLOWANCE_CONFIG_FREE_TRIALS_CHARGE_CUSTOMERS"} noMargin />}
          />

          <Checkbox
            key={'type.value'}
            checked={true}
            onClick={() => { }}
            value={1}
            content={<Text content={"ALLOWANCE_CONFIG_FREE_TRIALS_CHARGE_CUSTOMERS"} noMargin />}
          />
          <Row align="center">
            <Col md={9}>
              <Button disabled={false} id="allowance-config-update-free-trials" type="submit" isLoading={false} isFullWidth buttonType="general" title="ALLOWANCE_CONFIG_FREE_TRIALS_UPDATE_BUTTON" />
            </Col>
            <Col md={3}>
              <Button onClick={() => { }} disabled={false} id="allowance-config-cancel-free-trials" isFullWidth buttonType="error" title="ALLOWANCE_CONFIG_FREE_TRIALS_CANCEL_BUTTON" />
            </Col>
          </Row>
        </Panel> */}

        <Panel title="ALLOWANCE_CONFIG_UPDATE_USAGE">
          <Text content="ALLOWANCE_CONFIG_UPDATE_USAGE_TEXT" />

          <Checkbox
            checked={allowMannualUpdate}
            onClick={() => setAllowManualUpdate(!allowMannualUpdate)}
            value={1}
            content={<Text content={"ALLOWANCE_CONFIG_UPDATE_USAGE_ALLOW_MANUALLY_UPDATE"} noMargin />}
          />

          <Checkbox
            checked={!allowMannualUpdate}
            onClick={() => setAllowManualUpdate(!allowMannualUpdate)}
            value={0}
            content={<Text content={"ALLOWANCE_CONFIG_UPDATE_USAGE_DON_NOT_ALLOW_MANUALLY_UPDATE"} noMargin />}
          />

          <Row align="center">
            <Col md={9}>
              <Button onClick={() => updateAllowTeamMateManualUpdate()} disabled={isLoading} id="allowance-config-update-usage" type="button" isLoading={isLoading} isFullWidth buttonType="general" title="ALLOWANCE_CONFIG_UPDATE_USAGE_UPDATE_BUTTON" />
            </Col>
            <Col md={3}>
              <Button onClick={() => setAllowManualUpdate(dashboardSettings.isTeammateManualAllowanceUsageUpdateAllowed)} disabled={isLoading} id="allowance-config-cancel-usage" isFullWidth buttonType="error" title="ALLOWANCE_CONFIG_UPDATE_USAGE_CANCEL_BUTTON" />
            </Col>
          </Row>
        </Panel>

      </PageWrapper>
    </div>
  )
}

export default AllowanceConfig;
