import React, { useState, useEffect, useMemo } from "react";
import Table from "../../../components/ui/table/Table";
import PageWrapper from "../../../components/ui/layout/PageWrapper";
import NavigationAlert from "../../../components/ui/navigation-alert/NavigationAlert";
import counterpart from "counterpart";
import NavigationBar from "../../../components/ui/navigation-bar/NavigationBar";
import { useHistory } from "react-router-dom";
import Text from "../../../components/ui/text/Text";
import Dropdown from "../../../components/ui/dropdown/Dropdown";
import "./ExitReasons.scss";
import Switch from "../../../components/ui/switch/Switch";
import Button from "../../../components/ui/button/Button";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../../..";
import moment from "moment";
import { downloadCSV } from "../../../utils/commonUtils";
import LoadingModal from "../../../components/modal/loading-modal/LoadingModal";
import { fetchExitReasonsReportGraphData, fetchExitReasonsReportList, resetExitReasonsReport, setExitReasonsReportFields } from "../../../actions/exitReasonsActions";
import MonthPicker from "../../../components/ui/month-picker/MonthPicker";
import ProgressIndicator from "../../../components/ui/progress-indicator/ProgressIndicator";
import { BarChart, Bar, XAxis, CartesianGrid, Tooltip, ResponsiveContainer, TooltipProps } from "recharts";
import Panel from "../../../components/ui/panel/Panel";
import { fetchExitReasons } from "../../../actions/cancelSubscriptionConfigActions";
import ChartTooltip from "../../../components/ui/chart-tooltip/ChartTooltip";
import API from "../../../utils/API";
import { IProductLight } from "../../../models/Product";
import Pagination from "../../../components/ui/pagination/Pagination";
import SeeMoreText from "../../../components/ui/see-more-text/SeeMoreText";
import { GetExitReasonsConfigRequest } from "../../../utils/grpc/generated/Billsby.Protos/billing/private/company/exitreason/exit_reason_config_pb";

const barColors = ["#1E2432", "#F87847", "#3b9b1b", "#78756e", "#d22630", "#fdd835", "#a3643b", "#965fb1", "#e0e0e0", "#ff55b4", "#7c7552", "#61d495", "#9192f5", "#930c31"];

const ExitReasons: React.FC = () => {
  const auth = useSelector((state: AppState) => state.auth);
  const exitReasons = useSelector((state: AppState) => state.exitReasonsReducer);
  const cancelSubscriptionConfigReducer = useSelector((state: AppState) => state.cancelSubscriptionConfigReducer);

  const history = useHistory();
  const dispatch = useDispatch<Function>();

  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingData, setIsLoadingData] = useState(false);
  const [isCreatingCSV, setIsCreatingCSV] = useState(false);
  const [activeId, setActiveId] = useState<string | null>(null);
  const [productsList, setProductsList] = useState<Array<{ value?: number; label: string }>>([]);

  const { exitReasonsList } = cancelSubscriptionConfigReducer;
  const { startDate, endDate, includeCurrentMonthToDate, headerMessage, barChartData, selectedProduct, exitReasonsPagination } = exitReasons;
  const { currentPage, pageCount, resultsList } = exitReasonsPagination;

  const sortedExitReasonsList = useMemo(() => exitReasonsList.sort((a, b) => {
    return a.text.toLowerCase().localeCompare(b.text.toLowerCase())
  }), [exitReasonsList]);

  const dateFormat = "MMM YYYY";
  const defaultStartDate = moment().utc()
    .subtract(6, "month")
    .format(dateFormat);
  const defaultEndDate = moment().utc()
    .subtract(1, "month")
    .format(dateFormat);
    
  const fetchProductsList = async () => {
    try {
      const { results } = (await API.fetchAllProducts(auth.currentCompanyId, false, true, true)) as { results: Array<IProductLight> };
      const products = results.map((d) => ({ value: d.productId, label: d.displayName }));
      setProductsList([{ value: undefined, label: counterpart("PRODUCT_FILTER_STATUS_ALL") }, ...products]);
    } catch (err) {
      console.log(err);
    }
  };

  const fetchData = async (_startDate: string = defaultStartDate, _endDate: string = defaultEndDate) => {
    if (!auth.currentCompanyId) {
      return;
    }

    setIsLoading(true);

    const withCurrentMonth = includeCurrentMonthToDate && _endDate === defaultEndDate;
    const endDateToFetch = withCurrentMonth
      ? moment().utc()
        .subtract(0, "month")
        .format(dateFormat)
      : _endDate;

    try {
      await dispatch(fetchExitReasons(auth.currentCompanyId, GetExitReasonsConfigRequest.OrderByOption.TEXT));
      await dispatch(fetchExitReasonsReportList(auth.currentCompanyDomain, 1, _startDate, endDateToFetch, selectedProduct.value));
      await dispatch(fetchExitReasonsReportGraphData(auth.currentCompanyDomain, _startDate, endDateToFetch, selectedProduct.value));
      dispatch(setExitReasonsReportFields("hasCurrentMonth", withCurrentMonth));
      dispatch(
        setExitReasonsReportFields(
          "headerMessage",
          counterpart("EXIT_REASONS_ALERT_TITLE", {
            startDate: _startDate,
            endDate: endDateToFetch,
            toDate: withCurrentMonth ? ` (${counterpart("EXIT_REASONS_TO_DATE")}) ` : "",
          })
        )
      );

      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
      console.log(err);
    }
  };

  const handlePaginationOnChange = (selected: number, _startDate: string = defaultStartDate, _endDate: string = defaultEndDate) => {
    const withCurrentMonth = includeCurrentMonthToDate && _endDate === defaultEndDate;
    const endDateToFetch = withCurrentMonth
      ? moment().utc()
        .subtract(0, "month")
        .format(dateFormat)
      : _endDate;

    dispatch(fetchExitReasonsReportList(auth.currentCompanyDomain, selected + 1, _startDate, endDateToFetch, selectedProduct.value));
  };

  const getBarData = () => {

    let barData: Array<{ dataKey: string; color: string; value: string }> = [];
    let index = -1;

    sortedExitReasonsList.forEach((d, i) => {
      if (index < barColors.length - 1) {
        index = index + 1
      } else {
        index = 0;
      }

      barData.push({ dataKey: d.exitReasonConfigId.toString(), color: barColors[index], value: d.text });
    });

    return barData;
  };

  useEffect(() => {
    dispatch(setExitReasonsReportFields("selectedProduct", productsList[0]));
  }, [productsList]);

  useEffect(() => {
    const fetchAllData = async () => {
      setIsLoadingData(true);

      try {
        await fetchData();
        await fetchProductsList();
        setIsLoadingData(false);
      } catch (err) {
        console.log(err);
        setIsLoadingData(false);
      }
    };

    fetchAllData();
    dispatch(setExitReasonsReportFields("startDate", defaultStartDate));
    dispatch(setExitReasonsReportFields("endDate", defaultEndDate));

    return () => dispatch(resetExitReasonsReport());
  }, []);

  useEffect(() => {
    if (moment.utc(endDate, dateFormat).isBefore(startDate)) {
      dispatch(setExitReasonsReportFields("endDate", startDate));
    }
  }, [endDate, startDate]);

  const renderHeader = () => {
    const maxDate = moment.utc().subtract(1, "month").format(dateFormat);
    return (
      <>
        <NavigationBar previousPageTitle="NAVBAR_CHECKOUT_AND_SELF_SERVICE" shouldTranslatePageTitle={false} previousPageCb={() => history.push("/report-checkout-self-service")} pageTitle={""} />
        <div className="navigation-alert-wrapper">
          <NavigationAlert
            title={!isLoading ? headerMessage : ""}
            className=""
            shouldTranslateTitle={false}
            type="default"
            subTitle=""
            icon="fal fa-file-download"
            buttonLabel="SUMMARY_BUTTON_CSV"
            buttonDisabled={isLoading}
            onClick={handleDownloadCSV}
            hasButton={false}
            isVisible={true}
          />
        </div>
        <div className="exit-reasons__header">
          <div className="exit-reasons__filter">
            <MonthPicker className="exit-reasons__start-date" value={startDate} maxDate={maxDate} minDate={moment.utc(defaultStartDate, dateFormat).subtract(6, "M").format(dateFormat)} onChange={(value) => dispatch(setExitReasonsReportFields("startDate", value))} />
            <Text content="ACTIVITY_SUMMARY_TO" className="with-side-margin" component="span" noMargin />
            <MonthPicker className="exit-reasons__end-date" value={endDate} minDate={startDate} maxDate={maxDate} onChange={(value) => dispatch(setExitReasonsReportFields("endDate", value))} />
            {defaultEndDate === endDate && (
              <>
                <span className="divider divider--vertical with-side-margin" />
                <Switch id="include-current-month" checked={includeCurrentMonthToDate} onChange={() => dispatch(setExitReasonsReportFields("includeCurrentMonthToDate", !includeCurrentMonthToDate))} />
                <Text content="ACTIVITY_SUMMARY_SWITCH1_LABEL" component="span" noMargin />
              </>
            )}
            <span className="divider divider--vertical with-side-margin" />
            <Dropdown
              className="exit-reasons__dropdown"
              id="exit-reasons-product"
              value={selectedProduct}
              options={productsList}
              onChange={(product: any) => dispatch(setExitReasonsReportFields("selectedProduct", product))}
            />
            <Button isLoading={isLoading} id="run-report" icon="fas fa-sync-alt" title="SUMMARY_BUTTON_REPORT" onClick={() => fetchData(startDate, endDate)} />
          </div>
        </div>
      </>
    );
  };

  const renderGraph = () => {
    return (
      <>
        <Panel>
          <ResponsiveContainer width="100%" height={410}>
            <BarChart
              width={500}
              height={300}
              data={barChartData}
              margin={{
                top: 20,
                right: 30,
                left: 20,
                bottom: 5,
              }}
            >
              <CartesianGrid />
              <XAxis dataKey="date" />
              <Tooltip
                content={(tooltipData: TooltipProps, idx) => {
                  return (
                    <ChartTooltip key={idx} isActive={tooltipData.active}>
                      {!!tooltipData.payload &&
                        !!tooltipData.payload.length &&
                        tooltipData.payload.map((d, i) => <Text key={i} content={`${d.value}%`} style={{ color: d.color }} noMargin shouldTranslate={false} />).reverse()}
                    </ChartTooltip>
                  );
                }}
              />
              {getBarData().map((d) => {
                return (
                  <Bar
                    key={d.dataKey}
                    dataKey={d.dataKey}
                    barSize={56}
                    stackId={"exit-reason"}
                    fill={d.color}
                    style={{ opacity: !activeId || activeId === d.dataKey ? "1" : "0.4", transition: "opacity ease 0.3s" }}
                  />
                );
              })}
            </BarChart>
          </ResponsiveContainer>
        </Panel>
        <Panel className="exit-reasons__chart-legend">
          {getBarData().map((d) => (
            <Text
              key={d.dataKey}
              content={d.value}
              shouldTranslate={false}
              onClick={() => {
                if (activeId !== d.dataKey) {
                  setActiveId(d.dataKey);
                } else setActiveId(null);
              }}
              component="div"
              style={{ color: d.color, opacity: !activeId || activeId === d.dataKey ? "1" : "0.4" }}
            />
          ))}
        </Panel>
      </>
    );
  };

  const renderTable = () => {
    if (resultsList.length === 0) {
      return null;
    }

    return (
      <div className="exit-reasons__table-container">
        <Button id="exit-reasons-csv" icon="fas fa-file-download" title="EXIT_REASONS_BUTTON_CSV" buttonType="add" onClick={handleDownloadCSV} />
        <Table>
          <thead>
            <tr>
              <Text content="EXIT_REASONS_SUBSCRIPTION" component="th" noMargin />
              <Text content="EXIT_REASONS_REASON" component="th" noMargin />
              <Text content="EXIT_REASONS_DATE" component="th" noMargin />
              <Text content="EXIT_REASONS_COMMENTS" component="th" noMargin />
            </tr>
          </thead>
          <tbody>
            {resultsList.map((d, i) => {
              return (
                <tr key={i}>
                  <td>
                    <div>
                      <Text content={d.planName} shouldTranslate={false} noMargin />
                      <Text className="exit-reasons__product-name" content={d.productName} shouldTranslate={false} component="span" noMargin />
                    </div>
                  </td>
                  <Text content={d.customerExitReason} shouldTranslate={false} component="td" noMargin />
                  {d.date && <Text content={moment.utc(moment.unix(d.date.seconds)).format("DD MMM YYYY")} shouldTranslate={false} component="td" noMargin />}
                  {!d.comments ? <td>-</td> : <td>
                    {isCreatingCSV ? (
                      <Text content={d.comments} shouldTranslate={false} style={{ opacity: 0 }} />
                    ) : (
                        <SeeMoreText content={d.comments} id={i} showTooltip={true} maxChar={50} shouldTranslateContent={false} />
                      )}
                  </td>}
                </tr>
              );
            })}
          </tbody>
        </Table>
        <div className="exit-reasons__pagination-container">
          <Pagination className="exit-reasons__pagination" pageCount={pageCount} isVisible={pageCount > 1} onPageChange={({ selected }) => handlePaginationOnChange(selected, startDate, endDate)} />
        </div>
      </div>
    );
  };

  const handleDownloadCSV = () => {
    if (isLoading) {
      return null;
    }

    setIsCreatingCSV(true);
    setTimeout(() => {
      downloadCSV(".cc-table", `${headerMessage}_page_${currentPage}`);
      setIsCreatingCSV(false);
    }, 500);
  };

  if (isLoadingData) {
    return <ProgressIndicator coverage="full-content" color="blue" />;
  }

  return (
    <div className="exit-reasons">
      {renderHeader()}
      <PageWrapper>
        {renderGraph()}
        {isLoading ? <ProgressIndicator color="blue" /> : renderTable()}
      </PageWrapper>
      <LoadingModal isOpen={isCreatingCSV} title={counterpart("EXIT_REASONS_CSV_EXIT_REASONS")} subTitle={""} shouldTranslateTitle={false} shouldTranslateSubTitle={false} />
    </div>
  );
};

export default ExitReasons;
