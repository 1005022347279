import React, { useEffect, useState, FormEvent, useMemo } from "react";
import Button from "../../../../ui/button/Button";
import history from "../../../../../utils/history";
import Panel from "../../../../ui/panel/Panel";
import Text from "../../../../ui/text/Text";
import "./EditRetentionStep.scss";
import { AppState } from "../../../../..";
import { useDispatch, useSelector } from "react-redux";
import { IAuthReducerState } from "../../../../../reducers/authReducer";
import { ICompanyBrandingReducerState } from "../../../../../reducers/companyBrandingReducer";
import { Row, Col } from "react-grid-system";
import BackNavigationPanel from "../../../../ui/back-navigation-panel/BackNavigationPanel";
import counterpart from "counterpart";
import { camelize, getFrequencyText, allowNumberMoreThanZero, isValidUrl, isValidEmailFormat } from "../../../../../utils/commonUtils";
import Input from "../../../../ui/input/Input";
import { fetchCompanyBranding } from "../../../../../actions/companyBrandingActions";
import { RetentionStepType } from "../../../../../models/Subscription";
import FormLabel from "../../../../ui/form-label/FormLabel";
import Switch from "../../../../ui/switch/Switch";
import PhoneInput from "../../../../ui/phone-input/PhoneInput";
import Textarea from "../../../../ui/textarea/Textarea";
import FormGroup from "../../../../ui/form-group/FormGroup";
import Dropdown from "../../../../ui/dropdown/Dropdown";
import { FrequencyType as ProductFrequencyType } from "../../../../../models/Product";
import {
  fetchHelpInterrupt,
  setCancelSubConfigField,
  updateHelpInterrupt,
  fetchReasonsToStay,
  updateReasonsToStay,
  fetchTimeRemaining,
  updateTimeRemaining,
  fetchPlanChange,
  updatePlanChange,
  resetCancelSubConfig,
  updateOfferDiscount,
  fetchDiscountOffer, uploadExitIntentImage
} from "../../../../../actions/cancelSubscriptionConfigActions";
import { ICancelSubscriptionConfigState } from "../../../../../reducers/cancelSubscriptionConfigReducer";
import { PREFERRED_PHONE_COUNTRIES } from "../../../../../utils/constants";
import ProgressIndicator from "../../../../ui/progress-indicator/ProgressIndicator";
import Checkbox from "../../../../ui/checkbox/Checkbox";
import { FrequencyType } from "../../../../../utils/grpc/generated/Billsby.Protos/core/private/companyconfig/retentionstep/retention_step_pb";
import CheckoutPreview from "../../brand-customization/checkout-preview/CheckoutPreview";
import CouponImg from "../../../../../images/discounts/coupon.svg";
import FileUpload from "../../../../ui/file-upload/FileUpload";
import NoticePanel from "../../../../ui/notice-panel/NoticePanel";
import { useParams } from "react-router-dom";


const EditRetentionStep: React.FC = () => {
  const { confirmationColor, headerAndButtonColor } = useSelector<AppState, ICompanyBrandingReducerState>(state => state.companyBrandingReducer);
  const { currentCompanyDomain } = useSelector<AppState, IAuthReducerState>(state => state.auth);
  const { helpInterrupt, reasonsToStay, timeRemaining, planChange, offerDiscount,
    discountPercentage, discountAllCustomers, discountFrequency, discountFrequencyType,
    discountLimitToOne, discountMinimumTenure, discountMinimumTenureType, exitIntentImage } = useSelector<AppState, ICancelSubscriptionConfigState>(state => state.cancelSubscriptionConfigReducer);
  
  const dispatch = useDispatch<Function>()
  const { retentionStep } = useParams<{ retentionStep: string }>()

  const defaultTimeRemainingText = "You have paid for unused time. If you cancel any time remaining on this subscription will be lost.";

  const [isUpdatingData, setIsUpdatingData] = useState(false);
  const [isLoadingData, setIsLoadingData] = useState(true);
  const [isInputBlur, setIsInputBlur] = useState(false);
  const [isUpdatingDiscount, setIsUpdatingDiscount] = useState(false);
  const [discountImgTooLarge, setDiscountImgTooLarge] = useState(false);
  const [invalidPhoneNumber, setInvalidPhoneNumber] = useState(false);
  const [invalidUrl, setInvalidUrl] = useState(false);
  const [invalidEmail, setInvalidEmail] = useState(false);

  const formattedParam = retentionStep.replace(/-/g, "_").toUpperCase();
  const camelizedParam = camelize(retentionStep);
  const retentionStepType = (camelizedParam[0].toUpperCase() + camelizedParam.slice(1)) as RetentionStepType;

  const frequencyTypes = [
    { label: counterpart("CREATE_PLAN_PERIOD_DAILY"), value: FrequencyType.DAILY },
    { label: counterpart("CREATE_PLAN_PERIOD_WEEKLY"), value: FrequencyType.WEEKLY },
    { label: counterpart("CREATE_PLAN_PERIOD_MONTHLY"), value: FrequencyType.MONTHLY },
    { label: counterpart("CREATE_PLAN_PERIOD_YEARLY"), value: FrequencyType.YEARLY }
  ];
  const frequencyTypesPlural = [
    { label: counterpart("CREATE_PLAN_PERIOD_DAILY_PLURAL"), value: FrequencyType.DAILY },
    { label: counterpart("CREATE_PLAN_PERIOD_WEEKLY_PLURAL"), value: FrequencyType.WEEKLY },
    { label: counterpart("CREATE_PLAN_PERIOD_MONTHLY_PLURAL"), value: FrequencyType.MONTHLY },
    { label: counterpart("CREATE_PLAN_PERIOD_YEARLY_PLURAL"), value: FrequencyType.YEARLY }
  ];

  const checkoutPreviewConfig = {
    title: "CHECKOUT_PREVIEW_CANCEL_SUBSCRIPTION",
    subTitle: "",
    showLink: true,
    btnText: "CHECKOUT_PREVIEW_KEEP_PLAN",
    showCart: false,
    showNavigationBar: false
  }

  const fetchDataHandler = async () => {
    setIsLoadingData(true);
    try {
      switch (retentionStepType) {
        case RetentionStepType.HelpInterrupt:
          await dispatch(fetchHelpInterrupt(currentCompanyDomain));
          setIsLoadingData(false);
          break;

        case RetentionStepType.ReasonsToStay:
          await dispatch(fetchReasonsToStay(currentCompanyDomain));
          setIsLoadingData(false);
          break;

        case RetentionStepType.TimeRemaining:
          await dispatch(fetchTimeRemaining(currentCompanyDomain));
          setIsLoadingData(false);
          break;

        case RetentionStepType.PlanChange:
          await dispatch(fetchPlanChange(currentCompanyDomain));
          setIsLoadingData(false);
          break;

        case RetentionStepType.DiscountOffer:
          await dispatch(fetchDiscountOffer(currentCompanyDomain));
          setIsLoadingData(false);
          break;

        default:
          setIsLoadingData(false);
      }
    } catch (err) {
      console.log(err);
      setIsLoadingData(false);
    }
  };

  const canSaveDiscount = useMemo(() => {
    if (offerDiscount) {
      if (!!discountPercentage && !!discountFrequency && !!discountFrequencyType) {
        if (Number(discountPercentage) < 1 || Number(discountPercentage) > 100) {
          return false;
        }
        if (!discountAllCustomers) {
          return !!discountMinimumTenure && !!discountMinimumTenureType;
        } return true;
      }
      return false;
    } else return true;
  }, [offerDiscount, discountPercentage, discountAllCustomers, discountFrequency,
    discountFrequencyType, discountLimitToOne, discountMinimumTenure, discountMinimumTenureType]);

  const updateDiscount = () => {
    setIsUpdatingDiscount(true);
    dispatch(updateOfferDiscount(currentCompanyDomain, offerDiscount, discountAllCustomers, discountLimitToOne,
      discountFrequency, discountFrequencyType, discountPercentage, discountMinimumTenure, discountMinimumTenureType, exitIntentImage)).then(() => {
        setIsUpdatingDiscount(false);
      }).catch(() => {
        setIsUpdatingDiscount(false);
      })
  }

  const turnOffDiscount = () => {
    setIsUpdatingDiscount(true);
    dispatch(updateOfferDiscount(currentCompanyDomain, false, discountAllCustomers, discountLimitToOne,
      discountFrequency, discountFrequencyType, discountPercentage, discountMinimumTenure, discountMinimumTenureType, exitIntentImage)).then(() => {
        setIsUpdatingDiscount(false);
        dispatch(setCancelSubConfigField("discountAllCustomers", false))
      }).catch(() => {
        setIsUpdatingDiscount(false);
      })
  }

  const updateDataHandler = async (evt: FormEvent) => {
    evt.preventDefault();

    setIsUpdatingData(true);
    try {
      switch (retentionStepType) {
        case RetentionStepType.HelpInterrupt:
          await dispatch(updateHelpInterrupt(currentCompanyDomain, helpInterrupt));
          setIsUpdatingData(false);
          break;

        case RetentionStepType.ReasonsToStay:
          await dispatch(updateReasonsToStay(currentCompanyDomain, reasonsToStay));
          setIsUpdatingData(false);

          break;

        case RetentionStepType.TimeRemaining:
          await dispatch(updateTimeRemaining(currentCompanyDomain, timeRemaining));
          setIsUpdatingData(false);
          break;

        case RetentionStepType.PlanChange:
          await dispatch(updatePlanChange(currentCompanyDomain, planChange));
          setIsUpdatingData(false);
          break;

        default:
          setIsUpdatingData(false);
      }
    } catch (err) {
      console.log(err);
      setIsUpdatingData(false);
    }
  };

  useEffect(() => {
    dispatch(fetchCompanyBranding(currentCompanyDomain));
    fetchDataHandler();

    return () => dispatch(resetCancelSubConfig());
  }, []);

  useEffect(() => {
    if (!timeRemaining.text && isInputBlur) {
      dispatch(setCancelSubConfigField("timeRemaining", { ...timeRemaining, text: defaultTimeRemainingText }));
    }
  }, [timeRemaining.text, isInputBlur]);

  useEffect(() => {
    setInvalidEmail(helpInterrupt.provideEmailAddressEnabled && !isValidEmailFormat(helpInterrupt.email));
    setInvalidUrl(helpInterrupt.supportPageUrl.length > 0 && !isValidUrl(helpInterrupt.supportPageUrl))
  }, [helpInterrupt.supportPageUrl, helpInterrupt.email, helpInterrupt.provideEmailAddressEnabled])


  // Help interrupt screen
  const renderHelpInterrupt = () => {
    const { title, subtitle, email, phoneNumberDialCode, phoneNumberDialCountry, phoneNumber, supportPageUrl, provideEmailAddressEnabled, providePhoneNumberEnabled } = helpInterrupt;
    const disableUpdateHelpInterrupt = ((invalidPhoneNumber || !phoneNumber) && providePhoneNumberEnabled) || invalidEmail || invalidUrl;

    return (
      <Row>
        <Col sm={12}>
          <Panel className="edit-retention-step__container" noMargin>
            <CheckoutPreview {...checkoutPreviewConfig} btnText={supportPageUrl ? "HELP_INTERRUPT_SUPPORT_PAGE_BTN" : "CHECKOUT_PREVIEW_KEEP_PLAN"}>
              <div className="edit-retention-step__help-interrupt">
                <Panel className="checkout-preview-new__panel">
                  <Text className="checkout-preview-new__panel__title" weight="bold" content={title} noMargin shouldTranslate={false}></Text>
                  <Text className="checkout-preview-new__body-text" content={subtitle} noMargin shouldTranslate={false}></Text>
                </Panel>

                {(provideEmailAddressEnabled || providePhoneNumberEnabled) && (
                  <Panel className="checkout-preview-new__panel edit-retention-step__help-interrupt__contents">
                    {provideEmailAddressEnabled && <Text noMargin className="checkout-preview-new__body-text" content="HELP_INTERRUPT_EMAIL_CONTENT" translateWith={{ email: <Text component="span" className="checkout-preview-new__body-text" noMargin content={email} shouldTranslate={false} weight="bold"></Text> }}></Text>}
                    {providePhoneNumberEnabled && <Text noMargin className="checkout-preview-new__body-text" content="HELP_INTERRUPT_PHONE_CONTENT" translateWith={{ dialCode: <Text component="span" className="checkout-preview-new__body-text" noMargin content={`(+${phoneNumberDialCode})`} shouldTranslate={false} weight="bold"></Text>, phoneNumber: <Text className="checkout-preview-new__body-text" component="span" noMargin content={phoneNumber} shouldTranslate={false} weight="bold"></Text> }}></Text>}
                  </Panel>
                )}
              </div>
            </CheckoutPreview>
          </Panel>
        </Col>
        <Col sm={12}>
          <form id="form-help-interrupt" onSubmit={updateDataHandler}>
            <Panel title={`RETENTION_STEPS_${formattedParam}`}>
              <Text content="HELP_INTERRUPT_PANEL_CONTENT"></Text>
              <Input
                id="help-interrupt-1"
                value={title}
                placeholder={counterpart("HELP_INTERRUPT_PLACHOLDER1")}
                onChange={(evt: any) => dispatch(setCancelSubConfigField("helpInterrupt", { ...helpInterrupt, title: evt.target.value }))}
              ></Input>
              <Input
                id="help-interrupt-2"
                value={subtitle}
                placeholder={counterpart("HELP_INTERRUPT_PLACHOLDER2")}
                onChange={(evt: any) => dispatch(setCancelSubConfigField("helpInterrupt", { ...helpInterrupt, subtitle: evt.target.value }))}
              ></Input>
              <div className="edit-retention-step__switch">
                <Switch
                  id="provide-email"
                  checked={provideEmailAddressEnabled}
                  onChange={() => dispatch(setCancelSubConfigField("helpInterrupt", { ...helpInterrupt, provideEmailAddressEnabled: !provideEmailAddressEnabled }))}
                ></Switch>
                <Text content="HELP_INTERRUPT_OPT1" noMargin></Text>
              </div>
              {provideEmailAddressEnabled && (
                <Input
                  id="email"
                  type="email"
                  value={email}
                  isError={invalidEmail}
                  errorMsg={counterpart("HELP_INTERRUPT_INVALID")}
                  required
                  placeholder={counterpart("HELP_INTERRUPT_EMAIL_PLACEHOLDER")}
                  onChange={(evt: any) => dispatch(setCancelSubConfigField("helpInterrupt", { ...helpInterrupt, email: evt.target.value }))}
                ></Input>
              )}
              <div className="edit-retention-step__switch">
                <Switch
                  id="provide-phone-number"
                  checked={providePhoneNumberEnabled}
                  onChange={() => dispatch(setCancelSubConfigField("helpInterrupt", { ...helpInterrupt, providePhoneNumberEnabled: !providePhoneNumberEnabled }))}
                ></Switch>
                <Text content="HELP_INTERRUPT_OPT2" noMargin></Text>
              </div>
              {providePhoneNumberEnabled && (
                <div className="edit-retention-step__phone">
                  <PhoneInput
                    preferredCountries={PREFERRED_PHONE_COUNTRIES.countries}
                    value={phoneNumber}
                    defaultCountry={phoneNumberDialCountry}
                    dialCode={phoneNumberDialCode}
                    onPhoneNumberChange={(isValid: boolean, phone: string, countryData: any) => {
                      setInvalidPhoneNumber(!isValid)
                      dispatch(setCancelSubConfigField("helpInterrupt", { ...helpInterrupt, phoneNumberDialCountry: countryData.iso2, phoneNumberDialCode: countryData.dialCode, phoneNumber: phone }));
                    }}
                    onSelectFlag={(phone: string, countryData: any) => {
                      dispatch(setCancelSubConfigField("helpInterrupt", { ...helpInterrupt, phoneNumberDialCountry: countryData.iso2, phoneNumberDialCode: countryData.dialCode, phoneNumber: phone }));
                    }}
                    isRequired
                    isRequiredMsg="HELP_INTERRUPT_REQUIRED"
                    errorLabel="HELP_INTERRUPT_INVALID"
                    separateDialCode
                  />
                </div>
              )}
              <FormLabel target="support-url" content="HELP_INTERRUPT_URL_LABEL"></FormLabel>
              <Input
                id="support-url"
                value={supportPageUrl}
                placeholder={counterpart("HELP_INTERRUPT_URL_PLACEHOLDER")}
                isError={invalidUrl}
                errorMsg={counterpart("HELP_INTERRUPT_INVALID")}
                onChange={(evt: any) => dispatch(setCancelSubConfigField("helpInterrupt", { ...helpInterrupt, supportPageUrl: evt.target.value }))}
              ></Input>
              <Button id="update-help-interrupt" disabled={disableUpdateHelpInterrupt} buttonType="add" isLoading={isUpdatingData} type="submit" title={"HELP_INTERRUPT_UPDATE_BTN"} isFullWidth></Button>
            </Panel>
          </form>
        </Col>
      </Row>
    );
  };

  // Reasons to stay screen
  const renderReasonsToStay = () => {
    const { text1, text2, text3, text4, text5 } = reasonsToStay;
    const showReasonsToStayPanel = text1 || text2 || text3 || text4 || text5;

    const renderReasonToStayItem = (text: string) => {
      if (!text) {
        return null
      }

      return (
        <div className="edit-retention-step__reasons-to-stay__list__item">
          <i className="fas fa-check-circle" style={{ color: confirmationColor }}></i>
          <Text content={text} className="checkout-preview-new__body-text" shouldTranslate={false} noMargin></Text>
        </div>
      )
    }

    return (
      <Row>
        <Col sm={12}>
          <Panel className="edit-retention-step__container" noMargin>
            <CheckoutPreview {...checkoutPreviewConfig}>
              <div className="edit-retention-step__reasons-to-stay">
                <Panel className="checkout-preview-new__panel">
                  <Text className="checkout-preview-new__body-text" content="REASONS_TO_STAY_PREVIEW_PANEL_CONTENT" noMargin></Text>
                </Panel>

                {showReasonsToStayPanel && (
                  <Panel className="checkout-preview-new__panel edit-retention-step__reasons-to-stay__list">
                    {renderReasonToStayItem(text1)}
                    {renderReasonToStayItem(text2)}
                    {renderReasonToStayItem(text3)}
                    {renderReasonToStayItem(text4)}
                    {renderReasonToStayItem(text5)}
                  </Panel>
                )}
              </div>
            </CheckoutPreview>
          </Panel>
        </Col>
        <Col sm={12}>
          <form id="form-reasons-to-stay" onSubmit={updateDataHandler}>
            <Panel title={`RETENTION_STEPS_${formattedParam}`}>
              <Text content="REASONS_TO_STAY_PANEL_CONTENT"></Text>
              <Input
                id="reason1"
                value={text1}
                placeholder={counterpart("REASONS_TO_STAY_PLACEHOLDER")}
                onChange={(evt: any) => dispatch(setCancelSubConfigField("reasonsToStay", { ...reasonsToStay, text1: evt.target.value }))}
              ></Input>
              <Input
                id="reason2"
                value={text2}
                placeholder={counterpart("REASONS_TO_STAY_PLACEHOLDER")}
                onChange={(evt: any) => dispatch(setCancelSubConfigField("reasonsToStay", { ...reasonsToStay, text2: evt.target.value }))}
              ></Input>
              <Input
                id="reason3"
                value={text3}
                placeholder={counterpart("REASONS_TO_STAY_PLACEHOLDER")}
                onChange={(evt: any) => dispatch(setCancelSubConfigField("reasonsToStay", { ...reasonsToStay, text3: evt.target.value }))}
              ></Input>
              <Input
                id="reason4"
                value={text4}
                placeholder={counterpart("REASONS_TO_STAY_PLACEHOLDER")}
                onChange={(evt: any) => dispatch(setCancelSubConfigField("reasonsToStay", { ...reasonsToStay, text4: evt.target.value }))}
              ></Input>
              <Input
                id="reason5"
                value={text5}
                placeholder={counterpart("REASONS_TO_STAY_PLACEHOLDER")}
                onChange={(evt: any) => dispatch(setCancelSubConfigField("reasonsToStay", { ...reasonsToStay, text5: evt.target.value }))}
              ></Input>

              <Button id="update-reasons" buttonType="add" isLoading={isUpdatingData} type="submit" title="REASONS_TO_STAY_UPDATE_BTN" isFullWidth></Button>
            </Panel>
          </form>
        </Col>
      </Row>
    );
  };

  // Time remaining screen
  const renderTimeRemaining = () => {
    const { text } = timeRemaining;
    return (
      <Row>
        <Col sm={12}>
          <Panel className="edit-retention-step__container" noMargin>
            <CheckoutPreview {...checkoutPreviewConfig}>
              <div className="edit-retention-step__time-remaining">
                <Panel className="checkout-preview-new__panel">
                  <Text className="checkout-preview-new__body-text" content={text} shouldTranslate={false} noMargin></Text>
                </Panel>

                <Panel className="checkout-preview-new__panel edit-retention-step__time-remaining__preview">
                  <Panel style={{ borderColor: headerAndButtonColor }} noMargin>
                    <span>10</span>
                  </Panel>
                  <Text className="checkout-preview-new__panel__title" weight="bold" content="TIME_REMAINING_PREVIEW_PANEL_DAYS" noMargin></Text>
                </Panel>
              </div>
            </CheckoutPreview>
          </Panel>
        </Col>
        <Col sm={12}>
          <form id="form-time-remaining" onSubmit={updateDataHandler}>
            <Panel title={`RETENTION_STEPS_${formattedParam}`}>
              <Text content="TIME_REMAINING_PANEL_CONTENT"></Text>
              <Textarea
                id="time-remaining"
                value={text}
                onBlur={() => setIsInputBlur(true)}
                onFocus={() => setIsInputBlur(false)}
                onChange={(evt: any) => dispatch(setCancelSubConfigField("timeRemaining", { ...timeRemaining, text: evt.target.value }))}
              ></Textarea>
              <Button id="update-time-remaining" buttonType="add" isLoading={isUpdatingData} type="submit" title="TIME_REMAINING_UPDATE_BTN" isFullWidth></Button>
            </Panel>
          </form>
        </Col>
      </Row>
    );
  };

  // Plan change screen
  const renderPlanChange = () => {
    const { text, subText } = planChange;
    return (
      <Row>
        <Col sm={12}>
          <Panel className="edit-retention-step__container" noMargin>
            <CheckoutPreview {...checkoutPreviewConfig}>
              <div className="edit-retention-step__plan-change">
                <Panel className="checkout-preview-new__panel">
                  <Text className="checkout-preview-new__body-text" content={text} weight="bold" shouldTranslate={false} noMargin></Text>
                  {subText && <Text className="checkout-preview-new__body-text" content={subText} shouldTranslate={false} noMargin></Text>}
                </Panel>
                <div className="edit-retention-step__plan-change__list">
                  <Panel className="checkout-preview-new__panel edit-retention-step__plan-change__list__item">
                    <div>
                      <Text className="checkout-preview-new__panel__title" weight="bold" content="PLAN_CHANGE_PLAN_TITLE1" noMargin></Text>
                      <Text className="checkout-preview-new__body-text" content="PLAN_CHANGE_PLAN_SUBTITLE1" noMargin></Text>
                    </div>
                  </Panel>
                  <Panel className="checkout-preview-new__panel edit-retention-step__plan-change__list__item">
                    <div>
                      <Text className="checkout-preview-new__panel__title" weight="bold" content="PLAN_CHANGE_PLAN_TITLE2" noMargin></Text>
                      <Text className="checkout-preview-new__body-text" content="PLAN_CHANGE_PLAN_SUBTITLE2" noMargin></Text>
                    </div>
                  </Panel>
                </div>

              </div>
            </CheckoutPreview>
          </Panel>
        </Col>
        <Col sm={12}>
          <form id="form-plan-change" onSubmit={updateDataHandler}>
            <Panel title={`RETENTION_STEPS_${formattedParam}`}>
              <Text content="PLAN_CHANGE_PANEL_CONTENT"></Text>
              <Input id="plan-change1" value={text} onChange={(evt: any) => dispatch(setCancelSubConfigField("planChange", { ...planChange, text: evt.target.value }))}></Input>
              {/* TODO: New change plan field */}
              <Input id="plan-change2" value={subText} onChange={(evt: any) => dispatch(setCancelSubConfigField("planChange", { ...planChange, subText: evt.target.value }))}></Input>
              <Button id="update-plan-change" buttonType="add" isLoading={isUpdatingData} type="submit" title="PLAN_CHANGE_UPDATE_BTN" isFullWidth></Button>
            </Panel>
          </form>
        </Col>
      </Row>
    );
  };

  // Discount offer screen
  const renderDiscountOffer = () => {
    return (
      <>
        <Row>
          <Col sm={12}>
            <Panel className="edit-retention-step__container" noMargin>
              <CheckoutPreview {...checkoutPreviewConfig} btnText="CHECKOUT_PREVIEW_KEEP_PLAN_DISCOUNT">
                <div className="edit-retention-step__offer-discount">
                  <Panel className="checkout-preview-new__panel">
                    <Text
                      content="RETENTION_STEP_PANEL_TEXT_2"
                      translateWith={{
                        percentage: discountPercentage || 25,
                        duration: `${discountFrequency || 3} ${discountFrequencyType ? discountFrequencyType.label : frequencyTypesPlural[2].label}`
                      }}
                      weight="bold"
                      className="checkout-preview-new__header__title"></Text>
                    <img src={exitIntentImage || CouponImg} alt="exit intent" />
                  </Panel>
                </div>
              </CheckoutPreview>
            </Panel>
          </Col>
          <Col sm={12}>
            <form id="form-discount-offer" onSubmit={updateDataHandler}>
              <Panel className="edit-retention-step__offer-discount__config" title={`RETENTION_STEPS_${formattedParam}`}>
                {discountImgTooLarge && <NoticePanel type="error" content="ERROR_LARGE_IMG" />}
                <Text content="RETENTION_STEPS_DISCOUNT_OFFER_TEXT_1" />

                <FormGroup noMargin={!offerDiscount}>
                  <Switch
                    id="offer-discount"
                    text="RETENTION_STEP_OFFER_DISCOUNT_TEXT"
                    checked={offerDiscount}
                    onChange={() => {
                      if (offerDiscount) {
                        turnOffDiscount();
                      } else {
                        dispatch(setCancelSubConfigField("discountPercentage", 25));
                        dispatch(setCancelSubConfigField("discountAllCustomers", true));
                        dispatch(setCancelSubConfigField("discountLimitToOne", true));
                        dispatch(setCancelSubConfigField("discountFrequency", 3));
                        dispatch(setCancelSubConfigField("discountFrequencyType", { label: `${getFrequencyText(3, ProductFrequencyType.Monthly)}`, value: FrequencyType.MONTHLY }));
                        dispatch(setCancelSubConfigField("discountMinimumTenure", 1));
                        dispatch(setCancelSubConfigField("discountMinimumTenureType", { label: `${getFrequencyText(1, ProductFrequencyType.Monthly)}`, value: FrequencyType.MONTHLY }));
                      }
                      dispatch(setCancelSubConfigField("offerDiscount", !offerDiscount));
                    }}></Switch>
                </FormGroup>

                {offerDiscount && <><Text content="RETENTION_STEP_OFFER_DISCOUNT_PERCENTAGE_TEXT" className="edit-retention-step__offer-discount__input-label" noMargin></Text>
                  <Row>
                    <Col md={11}>
                      <Input
                        id="discount-percentage"
                        value={discountPercentage}
                        type="number"
                        placeholder={counterpart("RETENTION_STEP_OFFER_DISCOUNT_PERCENTAGE_PLACEHOLDER")}
                        onChange={(evt: any) => dispatch(setCancelSubConfigField("discountPercentage", allowNumberMoreThanZero(evt.target.value)))}
                      />
                    </Col>
                    <Col md={1}>
                      <Text content="%" shouldTranslate={false} noMargin className="edit-retention-step__offer-discount__percentage-symbol" />
                    </Col>
                  </Row>
                  <Text content="RETENTION_STEP_OFFER_DISCOUNT_DISCOUNT_LENGTH" className="edit-retention-step__offer-discount__input-label" noMargin></Text>
                  <Row align="center">
                    <Col md={6}>
                      <Input
                        className="edit-retention-step__offer-discount__input"
                        id="frequency"
                        required
                        value={discountFrequency}
                        type="number"
                        placeholder={counterpart("RETENTION_STEP_OFFER_DISCOUNT_DISCOUNT_LENGTH_PLACEHOLDER")}
                        onChange={(evt: any) => {
                          const inputValue = allowNumberMoreThanZero(evt.target.value);
                          dispatch(setCancelSubConfigField("discountFrequency", inputValue));
                          if (discountFrequencyType) {
                            const value = inputValue && inputValue > 1 ? frequencyTypesPlural.filter(i => i.value === discountFrequencyType.value)[0]
                              : frequencyTypes.filter(i => i.value === discountFrequencyType.value)[0];
                            dispatch(setCancelSubConfigField("discountFrequencyType", value));
                          }
                        }}
                      />
                    </Col>
                    <Col md={6}>
                      <Dropdown
                        className="edit-retention-step__offer-discount__dropdown"
                        id="frequencyType"
                        dividerAfter={4}
                        onChange={(selectedItem: any) => dispatch(setCancelSubConfigField("discountFrequencyType", selectedItem))}
                        value={discountFrequencyType}
                        options={discountFrequency && +discountFrequency > 1 ? frequencyTypesPlural : frequencyTypes}
                      />
                    </Col>
                  </Row>
                  <Checkbox checked={discountAllCustomers} onClick={() => {
                      if(discountAllCustomers && !discountMinimumTenure) {
                        dispatch(setCancelSubConfigField("discountMinimumTenure", 1));
                      }
                      dispatch(setCancelSubConfigField("discountAllCustomers", !discountAllCustomers));
                    }} value='' content={counterpart("RETENTION_STEP_OFFER_DISCOUNT_AVAILBLE_TO_ALL_CUSTOMERS")} />

                  {!discountAllCustomers &&
                    <>
                      <Text content="RETENTION_STEP_OFFER_DISCOUNT_MINIMUM_TENURE" className="edit-retention-step__offer-discount__input-label" noMargin></Text>
                      <Row align="center">
                        <Col md={6}>
                          <Input
                            className="edit-retention-step__offer-discount__input"
                            id="minimum-tenure-frequency"
                            required
                            value={discountMinimumTenure}
                            type="number"
                            placeholder={counterpart("RETENTION_STEP_OFFER_DISCOUNT_DISCOUNT_LENGTH_PLACEHOLDER")}
                            onChange={(evt: any) => {
                              const inputValue = allowNumberMoreThanZero(evt.target.value);
                              dispatch(setCancelSubConfigField("discountMinimumTenure", inputValue));
                              if (discountMinimumTenureType) {
                                const value = inputValue && inputValue > 1 ? frequencyTypesPlural.filter(i => i.value === discountMinimumTenureType.value)[0]
                                  : frequencyTypes.filter(i => i.value === discountMinimumTenureType.value)[0];
                                dispatch(setCancelSubConfigField("discountMinimumTenureType", value));
                              }
                            }}
                          />
                        </Col>
                        <Col md={6}>
                          <Dropdown
                            id="minimum-tenure-frequencyType"
                            className="edit-retention-step__offer-discount__dropdown"
                            dividerAfter={4}
                            onChange={(selectedItem: any) => dispatch(setCancelSubConfigField("discountMinimumTenureType", selectedItem))}
                            value={discountMinimumTenureType}
                            options={discountMinimumTenure && +discountMinimumTenure > 1 ? frequencyTypesPlural : frequencyTypes}
                          />
                        </Col>
                      </Row>
                    </>
                  }
                  <Checkbox checked={discountLimitToOne} onClick={() => dispatch(setCancelSubConfigField("discountLimitToOne", !discountLimitToOne))} value='' content={counterpart("RETENTION_STEP_OFFER_DISCOUNT_LIMIT_TO_ONE")} />
                  <FileUpload
                    title="RETENTION_STEP_OFFER_DISCOUNT_UPLOADER_TITLE"
                    subTitle="RETENTION_STEP_OFFER_DISCOUNT_UPLOADER_SUBTITLE"
                    image={exitIntentImage || ""}
                    onDrop={files => {
                      dispatch(uploadExitIntentImage(currentCompanyDomain, files[0]))
                      setDiscountImgTooLarge(files[0].size > 1000000);
                    }}
                  />
                  <Button id="update-discount-offer"
                    buttonType="add"
                    isLoading={isUpdatingDiscount}
                    onClick={() => updateDiscount()}
                    title="RETENTION_STEP_OFFER_DISCOUNT_BUTTON_TEXT"
                    disabled={!canSaveDiscount}
                    isFullWidth
                  />
                </>}
              </Panel>
            </form>
          </Col>
        </Row>
      </>
    );
  };

  const renderEditRetentionContent = () => {
    if (isLoadingData) {
      return <ProgressIndicator color={"blue"}></ProgressIndicator>;
    }

    switch (retentionStepType) {
      case RetentionStepType.HelpInterrupt:
        return renderHelpInterrupt();
      case RetentionStepType.ReasonsToStay:
        return renderReasonsToStay();
      case RetentionStepType.TimeRemaining:
        return renderTimeRemaining();
      case RetentionStepType.PlanChange:
        return renderPlanChange();
      case RetentionStepType.DiscountOffer:
        return renderDiscountOffer();
      default:
        return null;
    }
  };

  return (
    <div className="edit-retention-step">
      <BackNavigationPanel title={`RETENTION_STEPS_${formattedParam}`} shouldTranslateTitle={true} onClick={() => history.push("/configuration/pause-and-cancel-subscription/cancel")} />
      {renderEditRetentionContent()}
    </div>
  );
};

export default EditRetentionStep