export interface IGoLiveCheckList {
  isCurrencyReady: boolean,
  isPaymentGatewayReady: boolean,
  isProductReady: boolean,
}

export interface IGoLiveScaResponse extends IGoLiveCheckList {
  AuthorizationStatus: PaymentStatus
  transactionToken: string,
  isScaActivated: boolean,
  goLiveProcessCompleted: boolean
}

export interface ILiveCompany {
  planId: number,
  cardHolderName: string,
  billingAddressLine1: string,
  billingAddressLine2: string,
  billingAddressPostCode: string,
  billingAddressState: string,
  billingAddressCity: string
  billingAddressCountryISO3: string,
  creditCardToken: string,
  whiteLabelChecked: boolean,
  expiryMonth: number,
  expiryYear: number,
  cardType: string,
  last4Digits: string,
  ipAddress?: string,
  transactionToken?: string,
  redirectUrl?: string,
  callbackUrl?: string
}

export enum PaymentStatus {
  Success = "Success",
  Declined = "Declined", // When a charge failed because of an issue in customer's side (card expired, fraud, etc...).
  Failed = "Failed", // When a charge failed because of an issue in our side or Spreedly side. Service down, wrong API Key, etc..
  Pending = "Pending" // When a charge requires 3DSecure validation (SCA)
}

export enum BillsbyPlan {
  //core planId
  CORE = 999,
  //pro planId
  PRO = 998,
  CORE_LEGACY = 1000
}