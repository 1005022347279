import React, { useState } from "react";
import Panel from "../../ui/panel/Panel";
import Button from "../../ui/button/Button";
import { IProductLightWithPlans } from "../../../models/Product";
import Text from "../../ui/text/Text";
import counterpart from "counterpart";
import { ProductsServiceClient } from "../../../utils/grpc/generated/Billsby.Protos/core/private/products/ProductsServiceClientPb";
import { DeleteProductRequest } from "../../../utils/grpc/generated/Billsby.Protos/core/private/products/products_pb";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../../..";
import { IAuthReducerState } from "../../../reducers/authReducer";
import { grpcUnaryCall } from "../../../utils/grpc/grpcUtils";
import { ConfigConstants } from "../../../utils/config";
import { fetchGettingStartedSettings } from "../../../actions/authActions";


interface IDeleteProduct {
  product: IProductLightWithPlans,
  onClose: () => void
}

const DeleteProduct: React.FC<IDeleteProduct> = ({ product, onClose }) => {
  const { currentCompanyDomain } = useSelector<AppState, IAuthReducerState>(state => state.auth)
  const dispatch = useDispatch<Function>()

  const [isLoading, setIsLoading] = useState(false);

  const onSubmit = async () => {
    setIsLoading(true);
    try {
      const deleteProductRequest = new DeleteProductRequest();
      deleteProductRequest.setCompanyDomain(currentCompanyDomain);
      deleteProductRequest.setProductId(product.productId);
      const productServiceClient = new ProductsServiceClient(ConfigConstants.grpcBaseUrl);
      await grpcUnaryCall(deleteProductRequest, productServiceClient, productServiceClient.deleteProduct);
      await dispatch(fetchGettingStartedSettings(currentCompanyDomain));
      onClose();
    }
    catch(err) {}
    finally {
      setIsLoading(false);
    }
  }

  return (
    <>
      <Panel title="PRODUCT_DELETE_MODAL_TITLE" translateWith={{ productName: product.name }}>
        <Text 
          content="PRODUCT_DELETE_MODAL_HEADING"
          translateWith={{ retainProduct: <b>{counterpart("PRODUCT_DELETE_MODAL_RETAIN_PRODUCT")}</b> }}
          noMargin 
        />
      </Panel>
      <Button
        id="delete-product"
        title={counterpart("PRODUCT_DELETE_MODAL_TITLE", { productName: product.name }).toUpperCase()}
        isLoading={isLoading}
        disabled={isLoading}
        shouldTranslate={false}
        isFullWidth
        onClick={onSubmit}
      />
    </>
  )
}

export default DeleteProduct