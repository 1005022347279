import React, { useLayoutEffect } from "react";
import "./DashboardStats.scss";
import PageWrapper from "../../ui/layout/PageWrapper";
import StatsBar from "../../ui/stats-bar/StatsBar";
import StatsPanel from "../../ui/stats-panel/StatsPanel";
import TabbedContainer from "../../ui/tabbed-container/TabbedContainer";
import { NavLink, Redirect, Switch } from "react-router-dom";
import { DashboardChartType } from "../../../models/Dashboard";
import counterpart from "counterpart";
import { PrivateRoute } from "../../../containers/AuthRoutes";
import DashboardChartSection from "../dashboard-chart-section/DashboardChartSection";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../../..";
import { IDashboardReducer } from "../../../reducers/dashboardReducer";
import { IAuthReducerState } from "../../../reducers/authReducer";
import { fetchDashboardRecentData, fetchDashboardCurrentData } from "../../../actions/dashboardActions";
import history from "../../../utils/history";
import { setInvoicesField } from "../../../actions/invoicesActions";
import { InvoiceStatus } from "../../../models/Invoices";
import { setCustomersField } from "../../../actions/customersActions";
import { CardStatusType, CustomerStatusType } from "../../../models/Customers";


const DashboardStats: React.FC = () => {
  const { recentData, currentData } = useSelector<AppState, IDashboardReducer>(state => state.dashboardReducer)
  const authReducer = useSelector<AppState, IAuthReducerState>(state => state.auth)
  const dispatch = useDispatch<Function>()

  useLayoutEffect(() => {
    /*if (history.location.pathname === "/home" && dashboardSettings
      && (dashboardSettings.status === GettingStartedType.Live || dashboardSettings.status === GettingStartedType.OnTrial) && hasSelectedCompany) {
      history.push(`/home/${DashboardChartType.SALES}`);
    }*/

    dispatch(fetchDashboardRecentData(authReducer.currentCompanyDomain));
    dispatch(fetchDashboardCurrentData(authReducer.currentCompanyDomain));
  }, [authReducer.dashboardSettings]);

  if (!recentData || !currentData) {
    return null;
  }

  const { signUps, customers, revenueToday, revenueMonth } = recentData;

  const getStatPanelType = (percentageChanged: number, hasValueDropped: boolean): "positive" | "negative" | "equals" => {
    if (!percentageChanged) {
      return "equals";
    }
    if (hasValueDropped) {
      return "negative";
    }
    return "positive";
  }

  return (
    <PageWrapper className="dashboard-stats">
      <StatsBar
        data={[
          {
            label: "DASHBOARD_STATS_BAR_MRR",
            value: currentData.mrrValueFormatted,
            infoText: "DASHBOARD_STATS_BAR_MRR_INFO"
          },
          {
            label: "DASHBOARD_STATS_BAR_SUBSCRIPTIONS",
            value: currentData.subscriptionsCount,
            infoText: "DASHBOARD_STATS_BAR_SUBSCRIPTIONS_INFO"
          },
          {
            label: "DASHBOARD_STATS_BAR_NET_REVENUE_MTD",
            value: currentData.netRevValueFormatted,
            infoText: "DASHBOARD_STATS_BAR_NET_REVENUE_MTD_INFO"
          },
          {
            label: "DASHBOARD_STATS_BAR_NET_PAYMENTS_MTD",
            value: currentData.netPaymentsValueFormatted,
            infoText: "DASHBOARD_STATS_BAR_NET_PAYMENTS_MTD_INFO"
          },
          {
            label: "DASHBOARD_STATS_BAR_UNPAID_INVOICES",
            value: currentData.unpaidInvoicesCount,
            value2: currentData.unpaidInvoicesAmountFormatted,
            infoText: "DASHBOARD_STATS_BAR_UNPAID_INVOICES_INFO",
            clb: () => { 
              history.push({
                pathname: "/invoices",
                state: { 
                    from: "/home"
                }
              })
              dispatch(setInvoicesField("invoiceStatus", { label: "INVOICE_STATUS_FILTER_UNPAID", value: InvoiceStatus.UNPAID }));
            }
          },
          {
            label: "DASHBOARD_STATS_BAR_EXPIRED_CARDS",
            value: currentData.expiredCardsCount,
            infoText: "DASHBOARD_STATS_BAR_EXPIRED_CARDS_INFO",
            clb: () => {
              history.push({
                pathname: "/customers",
                state: { 
                    from: "/home"
                }
              })
              dispatch(setCustomersField("customerStatus", { label: "CUSTOMERS_LIST_TABLE_ACTIVE", value: CustomerStatusType.ACTIVE }));
              dispatch(setCustomersField("cardStatus", { label: "CUSTOMERS_LIST_TABLE_EXPIRED_CARD", value: CardStatusType.EXPIRED }));
            }
          }
        ]}
      />
      <StatsPanel title="DASHBOARD_STATS_PANEL_REVENUE_THIS_MONTH" percentage={revenueMonth.percentageChanged || 0} type={getStatPanelType(revenueMonth.percentageChanged || 0, revenueMonth.hasValueDropped)} infoText="DASHBOARD_STATS_PANEL_REVENUE_THIS_MONTH_INFO">
        <StatsPanel.VALUES
          value1={revenueMonth.expectedValueFormatted}
          label1="DASHBOARD_STATS_PANEL_EXPECTED"
          value2={revenueMonth.lastMonthsValueFormatted ? revenueMonth.lastMonthsValueFormatted : ""}
          label2="DASHBOARD_STATS_PANEL_LAST_MONTH"
        />
        <StatsPanel.VALUES
          value1={revenueMonth.todaysValueFormatted}
          label1="DASHBOARD_STATS_PANEL_RECEIVED"
        />
      </StatsPanel>
      <StatsPanel title="DASHBOARD_STATS_PANEL_REVENUE_TODAY" percentage={revenueToday.percentageChanged || 0} type={getStatPanelType(revenueToday.percentageChanged || 0, revenueToday.hasValueDropped)} infoText="DASHBOARD_STATS_PANEL_REVENUE_TODAY_INFO">
        <StatsPanel.VALUES
          value1={revenueToday.expectedValueFormatted}
          label1="DASHBOARD_STATS_PANEL_EXPECTED"
          value2={revenueToday.lastMonthsValueFormatted ? revenueToday.lastMonthsValueFormatted : ""}
          label2="DASHBOARD_STATS_PANEL_SAME_DAY_LAST_MONTH"
        />
        <StatsPanel.VALUES
          value1={revenueToday.todaysValueFormatted}
          label1="DASHBOARD_STATS_PANEL_RECEIVED"
        />
      </StatsPanel>
      <StatsPanel title="DASHBOARD_STATS_PANEL_CUSTOMER_BASE" percentage={customers.percentageChanged || 0} type={getStatPanelType(customers.percentageChanged || 0, customers.hasValueDropped)} infoText="DASHBOARD_STATS_PANEL_CUSTOMER_BASE_INFO" percentageText="DASHBOARD_STATS_PANEL_REVENUE_THIS_MONTH_PERCENTAGE">
        <StatsPanel.VALUES
          value1={customers.todaysValue + ""}
          label1="DASHBOARD_STATS_PANEL_TODAY"
          value2={customers.lastMonthsValue ? customers.lastMonthsValue + "" : ""}
          label2="DASHBOARD_STATS_PANEL_ONE_MONTH_AGO"
        />
      </StatsPanel>
      <StatsPanel title="DASHBOARD_STATS_PANEL_SIGNUPS" percentage={signUps.percentageChanged || 0} type={getStatPanelType(signUps.percentageChanged || 0, signUps.hasValueDropped)} infoText="DASHBOARD_STATS_PANEL_SIGNUPS_INFO" percentageText="DASHBOARD_STATS_PANEL_SIGNUPS_PERCENTAGE">
        <StatsPanel.VALUES
          value1={signUps.todaysValue + ""}
          label1="DASHBOARD_STATS_PANEL_SO_FAR_THIS_MONTH"
          value2={signUps.lastMonthsValue ? signUps.lastMonthsValue + "" : ""}
          label2="DASHBOARD_STATS_PANEL_AT_THIS_TIME_LAST_MONTH"
        />
      </StatsPanel>

      <TabbedContainer type="submenu">
        <NavLink activeClassName="active" to={`/home/${DashboardChartType.SALES}`}>{counterpart("DASHBOARD_STATS_MENU_SALES")}</NavLink>
        <NavLink activeClassName="active" to={`/home/${DashboardChartType.PAYMENTS}`}>{counterpart("DASHBOARD_STATS_MENU_PAYMENTS")}</NavLink>
        <NavLink activeClassName="active" to={`/home/${DashboardChartType.REFUNDS}`}>{counterpart("DASHBOARD_STATS_MENU_REFUNDS")}</NavLink>
        <NavLink activeClassName="active" to={`/home/${DashboardChartType.MRR}`}>{counterpart("DASHBOARD_STATS_MENU_MRR")}</NavLink>
        {/* NOT IMPLEMENTED FOR NOW
        <NavLink activeClassName="active" to={`/home/${DashboardChartType.CMRR}`}>{counterpart('DASHBOARD_STATS_MENU_CMRR')}</NavLink>*/}
        <NavLink activeClassName="active" to={`/home/${DashboardChartType.SIGNUPS}`}>{counterpart("DASHBOARD_STATS_MENU_SIGNUPS")}</NavLink>
        <NavLink activeClassName="active" to={`/home/${DashboardChartType.CHURN}`}>{counterpart("DASHBOARD_STATS_MENU_CHURN")}</NavLink>
        <NavLink activeClassName="active" to={`/home/${DashboardChartType.CREDITS}`}>{counterpart("DASHBOARD_STATS_MENU_CREDITS")}</NavLink>
      </TabbedContainer>

      <Switch>
        <PrivateRoute path={`/home/${DashboardChartType.SALES}`} render={() => <DashboardChartSection type={DashboardChartType.SALES} />} />
        <PrivateRoute path={`/home/${DashboardChartType.PAYMENTS}`} render={() => <DashboardChartSection type={DashboardChartType.PAYMENTS} />} />
        <PrivateRoute path={`/home/${DashboardChartType.REFUNDS}`} render={() => <DashboardChartSection type={DashboardChartType.REFUNDS} />} />
        <PrivateRoute path={`/home/${DashboardChartType.MRR}`} render={() => <DashboardChartSection type={DashboardChartType.MRR} />} />
        <PrivateRoute path={`/home/${DashboardChartType.CMRR}`} render={() => <DashboardChartSection type={DashboardChartType.CMRR} />} />
        <PrivateRoute path={`/home/${DashboardChartType.SIGNUPS}`} render={() => <DashboardChartSection type={DashboardChartType.SIGNUPS} />} />
        <PrivateRoute path={`/home/${DashboardChartType.CHURN}`} render={() => <DashboardChartSection type={DashboardChartType.CHURN} />} />
        <PrivateRoute path={`/home/${DashboardChartType.CREDITS}`} render={() => <DashboardChartSection type={DashboardChartType.CREDITS} />} />
        <Redirect exact from="/home" to={`/home/${DashboardChartType.SALES}`} />
      </Switch>
    </PageWrapper>
  )
}

export default DashboardStats