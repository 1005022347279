import React from "react";
import "./EmailLogsWelcome.scss";

import Panel from "../../../components/ui/panel/Panel";
import Text from "../../../components/ui/text/Text";
import PageWrapper from "../../ui/layout/PageWrapper";

interface IEmailLogsWelcome {
  customerName?: string
}

export const EmailLogsWelcome: React.FC<IEmailLogsWelcome> = ({ customerName }) => {
  return (
    <PageWrapper className="email-logs-welcome">
     <Panel className="email-logs-welcome__greeting" title={ customerName ? "LOGS_EMAIL_WELCOME_TITLE_TO_CUSTOMER" : "LOGS_EMAIL_WELCOME_TITLE" } 
      translateWith={{ customerName }}>
      <Text content="LOGS_EMAIL_WELCOME_TEXT" />
      <Panel className="email-logs-welcome__greeting-question" title="LOGS_EMAIL_FIRST_NOTE_TITLE">
        <Text noMargin content="LOGS_EMAIL_FIRST_NOTE_TEXT" />
      </Panel>
     </Panel>
    </PageWrapper>
  )
}

export default EmailLogsWelcome;