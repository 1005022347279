import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Panel from "../ui/panel/Panel";
import Text from "../ui/text/Text";
import Button from "../ui/button/Button";
import "./Teammates.scss";
import Table from "../ui/table/Table";
import { AppState } from "../..";
import { fetchCompanyTeammates } from "../../actions/companyTeammatesActions";
import { ITeammate } from "../../models/CompanyTeammates";
import RevokeConfirmationPanel from "../revoke-confirmation-panel/RevokeConfirmationPanel";
import InviteTeammate from "./InviteTeammate";
import PendingInvitationRow from "./PendingInvitationRow";
import { Permission, UserRole } from "../../models/Auth";
import { addSpaceBeforeCapitalLetter } from "../../utils/commonUtils";
import useCheckFeaturePermission from "../../utils/hooks/useCheckFeaturePermission";

interface IState {
  hasError: boolean,
  showInviteTeammate: boolean,
  selectedTeammate?: ITeammate,
  success: boolean,
  isLoadingData: boolean,
  isLoading: boolean,
  revokeConfirmationData: {
    firstName: string,
    userId: number
  }
}

const Teammates: React.FC = () => {
  const { user, existingTeammates, pendingInvitations } = useSelector((state: AppState) => state.companyTeammatesReducer);
  const { companyName, currentCompanyDomain } = useSelector((state: AppState) => state.auth);
  const dispatch = useDispatch<Function>();

  const [state, setState] = useState<IState>({
    hasError: false,
    showInviteTeammate: false,
    selectedTeammate: undefined,
    success: false,
    isLoadingData: true,
    isLoading: false,
    revokeConfirmationData: {
      firstName: "",
      userId: 0
    }
  });

  const { showInviteTeammate, success, isLoadingData, revokeConfirmationData, selectedTeammate } = state;
  const { firstName, userId } = revokeConfirmationData;
  const isTeammatesAddPermitted = useCheckFeaturePermission(Permission.TeammateAdd);
  const isTeammateAssignRolePermitted = useCheckFeaturePermission(Permission.TeammateAssignRole);

  useEffect(() => {
    fetchCompanyTeammatesData();
  }, []);


  const fetchCompanyTeammatesData = async () => {
    try {
      await dispatch(fetchCompanyTeammates(currentCompanyDomain));
      setState(state => ({
        ...state,
        isLoadingData: false,
        revokeConfirmationData: {
          firstName: "",
          userId: 0
        }
      }));
    } catch (err) {
      console.log(err);
    }
  };

  const handleRevokeAccessButton = (data: ITeammate) => () => {
    const { firstName, userId } = revokeConfirmationData;
    setState(state => ({
      ...state,
      revokeConfirmationData: {
        firstName: !firstName ? data.firstName : "",
        userId: !userId ? data.userId : 0
      }
    }));
  };

  const handleGoBackToTeammates = async () => {
    await fetchCompanyTeammatesData();
    setState(state => ({
      ...state,
      showInviteTeammate: false,
      selectedTeammate: undefined
    }));
  };

  const renderExistingTeammatesTable = () => {
    return (
      <Panel title="TEAMMATES_PANEL2_TITLE">
        <Table className="teammates__existing-teammates-table">
          <thead>
            <tr>
              <Text content="TEAMMATES_LIST_TABLE_EMAIL" component="th" noMargin />
              <Text content="TEAMMATES_LIST_TABLE_FULLNAME" component="th" noMargin />
              { isTeammateAssignRolePermitted &&
                <>
                  <Text content="TEAMMATES_LIST_TABLE_ROLE" component="th" noMargin />
                  <th/> 
                </>
               }
              <th/>
            </tr>
          </thead>
          <tbody>
            <tr>
              <Text shouldTranslate={false} content={user.email} component="td" noMargin />
              <Text shouldTranslate={false} content={`${user.firstName} ${user.lastName}`} component="td" noMargin />
              {
                isTeammateAssignRolePermitted && 
                  <>
                    <Text shouldTranslate={false} content={addSpaceBeforeCapitalLetter(UserRole[user.role])} component="td" noMargin />
                    <td><i className="fas fa-edit" onClick={() => { setState(state => ({ ...state, showInviteTeammate: true, selectedTeammate: user }))}} /></td>
                  </>
              }
              <td>
                <div className="teammates__action-buttons-wrapper">
                  <Button id="teammates-you" title="TEAMMATES_LIST_YOU" buttonType="success" isFullWidth />
                </div>
              </td>
            </tr>
            {existingTeammates.map(teammate => {
              return (
                <tr key={teammate.userId}>
                  <Text shouldTranslate={false} content={teammate.email} component="td" noMargin />
                  <Text shouldTranslate={false} content={`${teammate.firstName} ${teammate.lastName}`} component="td" noMargin />
                  { isTeammateAssignRolePermitted && 
                    <>
                      <Text shouldTranslate={false} content={addSpaceBeforeCapitalLetter(UserRole[teammate.role])} component="td" noMargin />
                      <td><i className="fas fa-edit" onClick={() => { setState(state => ({ ...state, showInviteTeammate: true, selectedTeammate: teammate }))}} /></td>
                    </>
                  }
                  <td>
                    <div className="teammates__action-buttons-wrapper">
                      <Button
                        id="teammates-revoke-access"
                        title="TEAMMATES_LIST_REVOKE_ACCESS"
                        buttonType="error"
                        className={teammate.userId === revokeConfirmationData.userId ? "active" : ""}
                        isFullWidth
                        isVisible={isTeammatesAddPermitted}
                        onClick={handleRevokeAccessButton(teammate)}
                      />
                    </div>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </Table>
      </Panel>
    );
  };

  const renderPendingInvitationsTable = () => {
    if (pendingInvitations.length === 0) {
      return null;
    }

    return (
      <Panel title="TEAMMATES_PANEL3_TITLE">
        <Table>
          <thead>
            <tr>
              <Text content="TEAMMATES_LIST_TABLE_EMAIL" component="th" noMargin />
              <Text content="TEAMMATES_LIST_TABLE_FULLNAME" component="th" noMargin />
              <th />
            </tr>
          </thead>
          <tbody>
            {pendingInvitations.map((teammate, key) => {
              return <PendingInvitationRow key={teammate.userId} teammate={teammate} />;
            })}
          </tbody>
        </Table>
      </Panel>
    );
  };

  if (isLoadingData) {
    return null;
  }

  return (
    <div className="teammates">
    
      {showInviteTeammate ? (
        <InviteTeammate
          selectedTeammate={selectedTeammate}
          handleGoToTeammatesPage={handleGoBackToTeammates} 
        />
      ) : (
        <>
          <Panel className="teammates__title-panel" title="TEAMMATES_PANEL1_TITLE">
            <Text className="teammates__title-text" content="TEAMMATES_PANEL1_CONTENT" noMargin />
          </Panel>
          {renderExistingTeammatesTable()}
          {<RevokeConfirmationPanel data={{ firstName, userId, currentCompanyDomain, companyName }} revokeSuccessCallback={fetchCompanyTeammatesData} />}
          {renderPendingInvitationsTable()}

          <Button
            id="invite-teammate"
            type="submit"
            icon={success ? "far fa-check-circle" : ""}
            onClick={() => setState(state => ({ ...state, showInviteTeammate: !showInviteTeammate, selectedTeammate: undefined }))}
            buttonType={"general"}
            title={"TEAMMATES_INVITE_NEW_TEAMMATE"}
            isVisible={isTeammatesAddPermitted}
            isFullWidth
          />
        </>
      )}
    </div>
  );
};

export default Teammates;
