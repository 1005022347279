import React, { useLayoutEffect, useState, useEffect, useMemo } from "react";
import Panel from "../../ui/panel/Panel";
import Text from "../../ui/text/Text";
import Button from "../../ui/button/Button";
import { AppState } from "../../..";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import "./UpdateSubscription.scss";
import FormLabel from "../../ui/form-label/FormLabel";
import FormGroup from "../../ui/form-group/FormGroup";
import Dropdown from "../../ui/dropdown/Dropdown";
import {
  fetchProductPlans,
  setCycle,
  setPlan,
  updateProductPlans,
  resetSelectPlanState,
  setShowUpdateSubscriptionModal,
  setPlanChangeType,
  fetchPaymentDescription,
  setNumberOfUnits,
  fetchPricingInfoDisclosure
} from "../../../actions/subscriptionPlanActions";
import { ICycle, IPlanWithCycles, VisibilityType, PricingModelType } from "../../../models/Product";
import Checkbox from "../../ui/checkbox/Checkbox";
import { getCycleFrequencyText, getSetupFeeText, getTieredUnitCycles,  getFreeQuantityText, capitalizeFirstLetter } from "../../../utils/commonUtils";
import { Row, Col } from "react-grid-system";
import NoticePanel from "../../ui/notice-panel/NoticePanel";
import { ISubscriptionUpdateModel, ISubscriptionAddonUpdateModel } from "../../../models/Subscription";
import { fetchSubscriptionDetails } from "../../../actions/subscriptionDetailsActions";
import { resetEventLogs, fetchEventLogs } from "../../../actions/eventLogsActions";
import counterpart from "counterpart";
import { PlanChangeType } from "../../../models/Subscriptions";
import ProgressIndicator from "../../ui/progress-indicator/ProgressIndicator";
import Input from "../../ui/input/Input";
import { AddonsAllowances } from "../../../utils/grpc/generated/Billsby.Protos/billing/public/invoice/invoice_pb";
import PricingInfoDisclosure from "../../pricing-info-disclosure/PricingInfoDisclosure";

export const UpdateSubscription: React.FC = () => {
  const auth = useSelector((state: AppState) => state.auth);
  const subscriptionState = useSelector((state: AppState) => state.subscriptionDetailsReducer);
  const subscriptionPlanState = useSelector((state: AppState) => state.subscriptionPlanReducer);
  const productPlanCycleReducerData = useSelector((state: AppState) => state.productPlanCycle);

  const dispatch = useDispatch<Function>();

  const [currentPlan, setCurrentPlan] = useState<{ label: string; value: IPlanWithCycles } | null>(null);
  const [currentUnits, setCurrentUnits] = useState(0);
  const [isLoadingData, setIsLoadingData] = useState(false);
  const [remainingDays, setRemainingDays] = useState(0);
  const [paymentToday, setPaymentToday] = useState("");
  const [isLoadingDescription, setIsLoadingDescription] = useState(false);
  const { currentCompanyDomain } = auth;
  const { productId, cycleId, planId, visibility, productVisibility, nextBillingDate, subscriptionUniqueId, customerUniqueId, subscriptionId, currency, units, allowances, addOns } = subscriptionState;
  const { plan, cycle, plansDropdown, isSavingSubscription, selectedCycleId, selectedPlanId, hasError, planChangeType, paymentDescription, pricingInfoDisclosure, numberOfUnits } = subscriptionPlanState;
  const { currencies, } = productPlanCycleReducerData;
  const currencyFormat = currencies.find(cur => cur.isoCode === currency);
  const isTiered = plan && plan.value.pricingModelType === PricingModelType.Tiered;
  const isVolume = plan && plan.value.pricingModelType === PricingModelType.Volume;
  const isRanged = plan && plan.value.pricingModelType === PricingModelType.Ranged;
  const isPerUnit = plan && plan.value.pricingModelType === PricingModelType.PerUnit;
  const cycleOptions = plan && (isTiered || isRanged || isVolume || isPerUnit ? getTieredUnitCycles(plan.value, numberOfUnits, currencyFormat && currencyFormat.symbol) : plan.value.cycles);
  const getCycles = () => {
    const selectedCycle = cycle;
    if (!plan || !cycleOptions) {
      return null;
    }

    return (
      <div className="update-subscription__cycle">
        {cycleOptions
          .sort((a, b) => {
            return a.pricingModel.frequencyType > b.pricingModel.frequencyType
              ? 1
              : a.pricingModel.frequencyType < b.pricingModel.frequencyType
              ? -1
              : 0 || a.pricingModel.price - b.pricingModel.price;
          })
          .map((cycle: ICycle) => {
            let isChecked = false;
            if (selectedCycle) {
              isChecked = selectedCycle.cycleId === cycle.cycleId;
            }
            const { priceFormatted, frequency, frequencyType, setupFeePriceFormatted, freeQuantity } = cycle.pricingModel;

            if(cycle.visibility === VisibilityType.OffSale) {
              return null;
            }

            return (
              <Checkbox
                key={cycle.cycleId}
                checked={isChecked}
                onClick={() => dispatch(setCycle(cycle))}
                value=""
                content={
                  <div>
                    {/* TO GET FREE TRIAL TEXT: ${getCycleFreeTrialText(freeTrial, freeTrialFrequencyType)} */}
                    <Text
                      content={`${priceFormatted} ${getCycleFrequencyText(frequency, frequencyType)}`}
                      shouldTranslate={false}
                      noMargin
                    />
                    {setupFeePriceFormatted ? (
                      <Text component="span" className="update-subscription__cycle__description" content={getSetupFeeText(setupFeePriceFormatted)} shouldTranslate={false} noMargin />
                    ) : null}
                    {freeQuantity ? (
                      <Text
                        component="span"
                        className={"update-subscription__cycle__description"}
                        content={!setupFeePriceFormatted ? capitalizeFirstLetter(getFreeQuantityText(freeQuantity)) : `, ${getFreeQuantityText(freeQuantity)}`}
                        shouldTranslate={false}
                      ></Text>
                    ) : null}
                  </div>
                }
              />
            );
          })}
      </div>
    );
  };

  const plansDropdownPublic = useMemo(() => {
    return plansDropdown.filter(plan => plan.value.visibility !== VisibilityType.OffSale);
  }, [plansDropdown]);

  const renderNoticePanel = () => {
    if (visibility !== VisibilityType.Public || productVisibility !== VisibilityType.Public || hasError) {
      return <NoticePanel type={hasError ? "error" : "warning"} isModal content={hasError ? "UPDATE_SUBSCRIPTION_PLAN_ERROR" : "UPDATE_SUBSCRIPTION_PLAN_IS_NO_LONGER_ON_SALE"} />;
    } else return null;
  };

  const updateSubscription = async () => {
    if(!isSaveAllowed() || isLoadingDescription) {
      return;
    }

    try {
      const result = await dispatch(
        updateProductPlans(currentCompanyDomain, subscriptionUniqueId, {
          customerUniqueId: customerUniqueId,
          planId: selectedPlanId,
          cycleId: selectedCycleId,
          planChangetype: isPlanChangeTypeAllowed() ? planChangeType : PlanChangeType.NEXT_BILLING,
          units: (isTiered || isVolume || isRanged || isPerUnit) ? Number(numberOfUnits) : 1,
          addOns: addOns.map(i => {
            return { addOnId: i.getId(), quantity: i.getQuantity()} as ISubscriptionAddonUpdateModel
          }),
          allowances: allowances.map(i => i.getId())
        } as ISubscriptionUpdateModel)
      );

      if (!!subscriptionId && !result.error) {
        await dispatch(resetEventLogs());
        await dispatch(fetchSubscriptionDetails(currentCompanyDomain, subscriptionUniqueId));
        await dispatch(fetchEventLogs(currentCompanyDomain, subscriptionUniqueId, 1, 6));
        await dispatch(setShowUpdateSubscriptionModal(false));
      }
    } catch (error) {
      console.log(error);
    }
  };

  const fetchData = async () => {
    setIsLoadingData(true);
    try {
      const { response } = await dispatch(fetchProductPlans(currentCompanyDomain, productId));
      const subscriptionPlans = response as Array<IPlanWithCycles>;
      const currentPlanId = !subscriptionState.planIdNext ? subscriptionState.planId : subscriptionState.planIdNext;
      const currentCycleId = !subscriptionState.cycleIdNext ? subscriptionState.cycleId : subscriptionState.cycleIdNext;
      const preselectedPlan = subscriptionPlans.find(item => item.planId === currentPlanId);

      const plansDropdown = response
        .map((plan: IPlanWithCycles) => ({ label: plan.displayName || plan.name, value: plan }))
        .filter((plan: IPlanWithCycles) => plan.visibility === VisibilityType.Public);

      // check if only one plan/cycle is available
      if (subscriptionPlans.length === 1) {
        const _plan = { label: subscriptionPlans[0].displayName || subscriptionPlans[0].name, value: subscriptionPlans[0] };
        dispatch(setPlan(_plan));
        setCurrentPlan(_plan);
        if (plansDropdown.length && plansDropdown[0].value.cycles.length === 1) {
          dispatch(setCycle(plansDropdown[0].value.cycles[0]));
          return;
        }
      }

      // check if plan/cycle is preselected
      if (preselectedPlan) {
        const _plan = { label: preselectedPlan.displayName || preselectedPlan.name, value: preselectedPlan };
        dispatch(setPlan(_plan));
        setCurrentPlan(_plan);

        const preselectedCycle = preselectedPlan.cycles.find(cycle => cycle.cycleId === currentCycleId);
        if (preselectedCycle) {
          dispatch(setCycle(preselectedCycle));
        }
      }

      setIsLoadingData(false);
    } catch (err) {
      console.log(err);
      setIsLoadingData(false);
    }
  };

  const isSaveAllowed = () => {
    const currentPlanId = !subscriptionState.planIdNext ? subscriptionState.planId : subscriptionState.planIdNext;
    const currentCycleId = !subscriptionState.cycleIdNext ? subscriptionState.cycleId : subscriptionState.cycleIdNext;
    const isAllowed = !isLoadingDescription && plan && cycle && (plan.value.planId !== currentPlanId || cycle.cycleId !== currentCycleId);

    if (isTiered || isVolume || isRanged || isPerUnit) {
      if (!numberOfUnits || !cycleOptions || cycleOptions.length === 0) {
        return false;
      }

      return isAllowed || (selectedCycleId && numberOfUnits && currentUnits !== numberOfUnits);
    }
    return isAllowed;
  };

  const isPlanChangeTypeAllowed = () => {
    if (currentPlan && plan) {
      return currentPlan.value.planId !== plan.value.planId;
    } else return false;
  };

  useLayoutEffect(() => {
    if (planChangeType === PlanChangeType.IMMEDIATE) {
      const date1 = moment(nextBillingDate);
      const date2 = moment(moment().format("DD MMM YYYY"));
      setRemainingDays(moment(date1).diff(date2, "days"));
    }
  }, [planChangeType]);

  useLayoutEffect(() => {
    fetchData();

    return () => {
      dispatch(resetSelectPlanState());
    };
  }, []);

  useLayoutEffect(() => {
    const fetchDescription = async () => {
      setIsLoadingDescription(true);

      try {
        const subscriptionAllowances = allowances.map(i => {
          const subscriptionAllowance = new AddonsAllowances();
          subscriptionAllowance.setId(i.getId());
          subscriptionAllowance.setUnits(i.getQuantity());
          return subscriptionAllowance;
        });
        await dispatch(fetchPaymentDescription(currentCompanyDomain, selectedCycleId, planId, numberOfUnits, cycleId));
        await dispatch(fetchPricingInfoDisclosure(currentCompanyDomain, selectedCycleId, planId, numberOfUnits, cycleId));
        setIsLoadingDescription(false);
      } catch (err) {
        setIsLoadingDescription(false);
        console.log(err);
      }
    };

    selectedCycleId && isPlanChangeTypeAllowed() && isSaveAllowed() && fetchDescription();
  }, [selectedCycleId, numberOfUnits]);

  useLayoutEffect(() => {
    if (units) {
      dispatch(setNumberOfUnits(Number(units)));
      setCurrentUnits(Number(units));
    }
  }, [units]);

  useEffect(() => {
    if (!paymentDescription) {
      return;
    }

    const { firstInvoiceTotalPrice } = paymentDescription.values;
    setPaymentToday(firstInvoiceTotalPrice);
  }, [paymentDescription]);

  const buttonContent = () => {
    if ((cycle && cycle.pricingModel.freeTrial) || !isSaveAllowed()) {
      return counterpart("UPDATE_SUBSCRIPTION_SAVE_CHANGES");
    }

    if (planChangeType === PlanChangeType.IMMEDIATE && isPlanChangeTypeAllowed()) {
      return counterpart("UPDATE_SUBSCRIPTION_PLAN_CHANGE_BUTTON", {
        totalAmount: paymentToday
      });
    }

    return counterpart("UPDATE_SUBSCRIPTION_SAVE_CHANGES");
  };

  return (
    <div className="update-subscription">
      {renderNoticePanel()}
      <Panel className="update-subscription__title-panel" title="UPDATE_SUBSCRIPTION_TITLE">
        <Text
          className="update-subscription__title-text"
          content="UPDATE_SUBSCRIPTION_DESCRIPTION"
          translateWith={{ nextBilling: <span className="text-blue">{moment(nextBillingDate).format("DD MMM YYYY")}</span> }}
          noMargin
        />
      </Panel>

      <Panel>
        {isLoadingData ? (
          <ProgressIndicator noPadding color="blue"></ProgressIndicator>
        ) : (
          <>
            <FormGroup>
              <Row>
                <Col xs={2}>
                  <FormLabel target="" content="UPDATE_SUBSCRIPTION_CHOOSE_PLAN" className="update-subscription__form-label" />
                </Col>
                <Col xs={10}>
                  <Dropdown
                    id="select-plan-dropdown"
                    value={plan}
                    onChange={(selectedItem: any) => {
                      dispatch(setCycle(null));
                      dispatch(setPlan(selectedItem));
                      dispatch(setNumberOfUnits(0));
                    }}
                    options={plansDropdownPublic}
                  />
                </Col>
              </Row>
            </FormGroup>

            {(isTiered || isVolume || isRanged || isPerUnit) && (
              <FormGroup>
                <Row>
                  <Col xs={2}>
                    <FormLabel target="number-of-units" content="UPDATE_SUBSCRIPTION_NUMBER_OF_UNITS" className="update-subscription__form-label"></FormLabel>
                  </Col>
                  <Col xs={10}>
                    <Input
                      id="number-of-units"
                      type="number"
                      placeholder="10"
                      value={numberOfUnits === 0 ? "" : numberOfUnits}
                      onChange={(evt: any) => dispatch(setNumberOfUnits(Number(evt.target.value)))}
                    ></Input>
                  </Col>
                </Row>
              </FormGroup>
            )}
            <Row>
              <Col xs={10} offset={{ xs: 2 }}>
                {getCycles()}
              </Col>
            </Row>
          </>
        )}
      </Panel>

      {isPlanChangeTypeAllowed() && cycleOptions && cycleOptions.length !== 0  && (
        <>
          <Panel className="update-subscription__plan-change-type">
            <Text
              content="UPDATE_SUBSCRIPTION_PLAN_CHANGE_MSG"
              translateWith={{
                remainingDays: <span className="text-blue">{remainingDays}</span>,
                dayFrequency: <span className="text-blue">{counterpart(remainingDays > 1 ? "FREQUENCY_DAYS" : "FREQUENCY_DAY")}</span>
              }}
            ></Text>
            <Checkbox
              checked={planChangeType === PlanChangeType.IMMEDIATE}
              value={PlanChangeType.IMMEDIATE}
              onClick={() => dispatch(setPlanChangeType(PlanChangeType.IMMEDIATE))}
              content={<Text content="UPDATE_SUBSCRIPTION_PLAN_CHANGE_TODAY" noMargin></Text>}
            ></Checkbox>
            <Checkbox
              checked={planChangeType === PlanChangeType.NEXT_BILLING}
              value={PlanChangeType.NEXT_BILLING}
              onClick={() => dispatch(setPlanChangeType(PlanChangeType.NEXT_BILLING))}
              content={<Text content="UPDATE_SUBSCRIPTION_PLAN_CHANGE_DATE" translateWith={{ date: moment(nextBillingDate).format("DD MMM YYYY") }} noMargin></Text>}
            ></Checkbox>
          </Panel>

          {isLoadingDescription ? (
            <Panel>
              <ProgressIndicator noPadding color="blue" />
            </Panel>
          ) : (
            <Panel>
              <PricingInfoDisclosure rawHTMLEncoded={pricingInfoDisclosure} />
            </Panel>
            /*<PaymentDescription
              description={paymentDescription}
              planType={plan ? plan.value.pricingModelType : undefined}
              planChangeType={planChangeType}
              cycle={cycle}
              nextBillDate={moment(nextBillingDate).format('DD MMM YYYY')}
            ></PaymentDescription>*/
          )}
        </>
      )}

      <Button
        disabled={!isSaveAllowed() || isLoadingDescription}
        id="update-subscription"
        type="submit"
        buttonType={"general"}
        isLoading={isSavingSubscription || isLoadingDescription}
        onClick={() => updateSubscription()}
        title={buttonContent()}
        isFullWidth
        shouldTranslate={false}
      />
    </div>
  );
};

export default UpdateSubscription;
