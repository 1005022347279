import React from "react"
import StepCollapsablePanel from "../../../../ui/step-collapsable-panel/StepCollapsablePanel";
import Button from "../../../../ui/button/Button";
import Text from "../../../../ui/text/Text";
import counterpart from "counterpart";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../../../../..";
import { IPaymentGatewaysReducer } from "../../../../../reducers/paymentGatewaysReducer";
import { SAVE_PAYMENT_GATEWAY_STATE, setPaymentGatewaysField } from "../../../../../actions/paymentGatewaysActions";
import { getStripeConnectURL } from "../../../../../utils/commonUtils";
import { ConfigConstants } from "../../../../../utils/config";


const StripePaymentGateway: React.FC = () => {
  const { selectedPaymentGateway } = useSelector<AppState, IPaymentGatewaysReducer>(state => state.paymentGatewaysReducer);
  const dispatch = useDispatch<Function>()

  if (!selectedPaymentGateway) {
    return null;
  }

  return (
    <StepCollapsablePanel
      isCollapsed={false}
      isShowTitleIcon={false}
      onFocus={() => { }}
      hasError={false}
      title={
        <div className="payment-gateways__selected-title">
          <div>
            {selectedPaymentGateway.isLogoFontAwesome && <i className={selectedPaymentGateway.logo} />}
            {!selectedPaymentGateway.isLogoFontAwesome && <img src={selectedPaymentGateway.logo} alt="no img" />}
            <div>
              <Text content="PAYMENT_GATEWAYS_DEFAULT_NAME" noMargin />
              <Text content={selectedPaymentGateway.title} noMargin />
            </div>
          </div>
          <div>
            <Button id="visit-website" title="PAYMENT_GATEWAYS_VISIT_WEBSITE_STRIPE" onClick={() => window.open("https://stripe.com/gb", "_self")} buttonType="add" />
          </div>
        </div>
      }
      shouldTranslateTitle={false}
      helpMenuPosition="right"
      helpMenuTitle={selectedPaymentGateway.helpTitle}
      helpMenuContent={
        <div>
          {selectedPaymentGateway.helpContent.map((helpLine, index) => (
            <Text key={index} content={helpLine} />
          ))}
        </div>
      }
    >
      <Text content="PAYMENT_GATEWAYS_STRIPE_DESCRIPTION" />
      <div className="payment-gateways__connect">
        <a href="#" className="stripe-connect" onClick={() => {
          dispatch({ type: SAVE_PAYMENT_GATEWAY_STATE });
          window.location.replace(getStripeConnectURL(ConfigConstants.stripeClientId))
        }}>
          <span>{counterpart("PAYMENT_GATEWAYS_CONNECT_WITH", { gatewayName: counterpart(selectedPaymentGateway.title) })}</span>
        </a>
        <Button id="selected-gateway-cancel" buttonType="error" title="PAYMENT_GATEWAYS_CANCEL" onClick={() => {
           dispatch(setPaymentGatewaysField("selectedPaymentGateway", undefined));
           dispatch(setPaymentGatewaysField("replacedPaymentGateway", undefined));
        }}/>
      </div>
    </StepCollapsablePanel>
  )
}

export default StripePaymentGateway
