/* eslint-disable */
// source: taxation.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = (function() {
  if (this) { return this; }
  if (typeof window !== 'undefined') { return window; }
  if (typeof global !== 'undefined') { return global; }
  if (typeof self !== 'undefined') { return self; }
  return Function('return this')();
}.call(null));

goog.exportSymbol('proto.billing.private.company.taxation.Address', null, global);
goog.exportSymbol('proto.billing.private.company.taxation.AutoBillsbyTaxConfig', null, global);
goog.exportSymbol('proto.billing.private.company.taxation.DeleteTaxConfigRequest', null, global);
goog.exportSymbol('proto.billing.private.company.taxation.DeleteTaxConfigResponse', null, global);
goog.exportSymbol('proto.billing.private.company.taxation.GetAutoBillsbyProductCategoriesRequest', null, global);
goog.exportSymbol('proto.billing.private.company.taxation.GetProductCategoriesResponse', null, global);
goog.exportSymbol('proto.billing.private.company.taxation.GetProductCategoriesResponse.Category', null, global);
goog.exportSymbol('proto.billing.private.company.taxation.GetTaxConfigRequest', null, global);
goog.exportSymbol('proto.billing.private.company.taxation.GetTaxConfigResponse', null, global);
goog.exportSymbol('proto.billing.private.company.taxation.GetTaxJarProductCategoriesRequest', null, global);
goog.exportSymbol('proto.billing.private.company.taxation.ManualTaxConfig', null, global);
goog.exportSymbol('proto.billing.private.company.taxation.TaxConfig', null, global);
goog.exportSymbol('proto.billing.private.company.taxation.TaxConfig.ConfigCase', null, global);
goog.exportSymbol('proto.billing.private.company.taxation.TaxJarConfig', null, global);
goog.exportSymbol('proto.billing.private.company.taxation.TaxRate', null, global);
goog.exportSymbol('proto.billing.private.company.taxation.UpsertAutoBillsbyTaxRequest', null, global);
goog.exportSymbol('proto.billing.private.company.taxation.UpsertAutoBillsbyTaxResponse', null, global);
goog.exportSymbol('proto.billing.private.company.taxation.UpsertManualTaxConfigRequest', null, global);
goog.exportSymbol('proto.billing.private.company.taxation.UpsertManualTaxConfigResponse', null, global);
goog.exportSymbol('proto.billing.private.company.taxation.UpsertTaxJarConfigRequest', null, global);
goog.exportSymbol('proto.billing.private.company.taxation.UpsertTaxJarConfigResponse', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.billing.private.company.taxation.DeleteTaxConfigRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.billing.private.company.taxation.DeleteTaxConfigRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.billing.private.company.taxation.DeleteTaxConfigRequest.displayName = 'proto.billing.private.company.taxation.DeleteTaxConfigRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.billing.private.company.taxation.DeleteTaxConfigResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.billing.private.company.taxation.DeleteTaxConfigResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.billing.private.company.taxation.DeleteTaxConfigResponse.displayName = 'proto.billing.private.company.taxation.DeleteTaxConfigResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.billing.private.company.taxation.GetTaxConfigRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.billing.private.company.taxation.GetTaxConfigRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.billing.private.company.taxation.GetTaxConfigRequest.displayName = 'proto.billing.private.company.taxation.GetTaxConfigRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.billing.private.company.taxation.GetTaxConfigResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.billing.private.company.taxation.GetTaxConfigResponse.repeatedFields_, null);
};
goog.inherits(proto.billing.private.company.taxation.GetTaxConfigResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.billing.private.company.taxation.GetTaxConfigResponse.displayName = 'proto.billing.private.company.taxation.GetTaxConfigResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.billing.private.company.taxation.UpsertTaxJarConfigRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.billing.private.company.taxation.UpsertTaxJarConfigRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.billing.private.company.taxation.UpsertTaxJarConfigRequest.displayName = 'proto.billing.private.company.taxation.UpsertTaxJarConfigRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.billing.private.company.taxation.UpsertTaxJarConfigResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.billing.private.company.taxation.UpsertTaxJarConfigResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.billing.private.company.taxation.UpsertTaxJarConfigResponse.displayName = 'proto.billing.private.company.taxation.UpsertTaxJarConfigResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.billing.private.company.taxation.UpsertAutoBillsbyTaxRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.billing.private.company.taxation.UpsertAutoBillsbyTaxRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.billing.private.company.taxation.UpsertAutoBillsbyTaxRequest.displayName = 'proto.billing.private.company.taxation.UpsertAutoBillsbyTaxRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.billing.private.company.taxation.UpsertAutoBillsbyTaxResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.billing.private.company.taxation.UpsertAutoBillsbyTaxResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.billing.private.company.taxation.UpsertAutoBillsbyTaxResponse.displayName = 'proto.billing.private.company.taxation.UpsertAutoBillsbyTaxResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.billing.private.company.taxation.UpsertManualTaxConfigRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.billing.private.company.taxation.UpsertManualTaxConfigRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.billing.private.company.taxation.UpsertManualTaxConfigRequest.displayName = 'proto.billing.private.company.taxation.UpsertManualTaxConfigRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.billing.private.company.taxation.UpsertManualTaxConfigResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.billing.private.company.taxation.UpsertManualTaxConfigResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.billing.private.company.taxation.UpsertManualTaxConfigResponse.displayName = 'proto.billing.private.company.taxation.UpsertManualTaxConfigResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.billing.private.company.taxation.GetAutoBillsbyProductCategoriesRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.billing.private.company.taxation.GetAutoBillsbyProductCategoriesRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.billing.private.company.taxation.GetAutoBillsbyProductCategoriesRequest.displayName = 'proto.billing.private.company.taxation.GetAutoBillsbyProductCategoriesRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.billing.private.company.taxation.GetTaxJarProductCategoriesRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.billing.private.company.taxation.GetTaxJarProductCategoriesRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.billing.private.company.taxation.GetTaxJarProductCategoriesRequest.displayName = 'proto.billing.private.company.taxation.GetTaxJarProductCategoriesRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.billing.private.company.taxation.GetProductCategoriesResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.billing.private.company.taxation.GetProductCategoriesResponse.repeatedFields_, null);
};
goog.inherits(proto.billing.private.company.taxation.GetProductCategoriesResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.billing.private.company.taxation.GetProductCategoriesResponse.displayName = 'proto.billing.private.company.taxation.GetProductCategoriesResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.billing.private.company.taxation.GetProductCategoriesResponse.Category = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.billing.private.company.taxation.GetProductCategoriesResponse.Category, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.billing.private.company.taxation.GetProductCategoriesResponse.Category.displayName = 'proto.billing.private.company.taxation.GetProductCategoriesResponse.Category';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.billing.private.company.taxation.TaxConfig = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.billing.private.company.taxation.TaxConfig.oneofGroups_);
};
goog.inherits(proto.billing.private.company.taxation.TaxConfig, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.billing.private.company.taxation.TaxConfig.displayName = 'proto.billing.private.company.taxation.TaxConfig';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.billing.private.company.taxation.TaxJarConfig = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.billing.private.company.taxation.TaxJarConfig, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.billing.private.company.taxation.TaxJarConfig.displayName = 'proto.billing.private.company.taxation.TaxJarConfig';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.billing.private.company.taxation.AutoBillsbyTaxConfig = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.billing.private.company.taxation.AutoBillsbyTaxConfig, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.billing.private.company.taxation.AutoBillsbyTaxConfig.displayName = 'proto.billing.private.company.taxation.AutoBillsbyTaxConfig';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.billing.private.company.taxation.Address = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.billing.private.company.taxation.Address, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.billing.private.company.taxation.Address.displayName = 'proto.billing.private.company.taxation.Address';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.billing.private.company.taxation.ManualTaxConfig = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.billing.private.company.taxation.ManualTaxConfig.repeatedFields_, null);
};
goog.inherits(proto.billing.private.company.taxation.ManualTaxConfig, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.billing.private.company.taxation.ManualTaxConfig.displayName = 'proto.billing.private.company.taxation.ManualTaxConfig';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.billing.private.company.taxation.TaxRate = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.billing.private.company.taxation.TaxRate, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.billing.private.company.taxation.TaxRate.displayName = 'proto.billing.private.company.taxation.TaxRate';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.billing.private.company.taxation.DeleteTaxConfigRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.billing.private.company.taxation.DeleteTaxConfigRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.billing.private.company.taxation.DeleteTaxConfigRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing.private.company.taxation.DeleteTaxConfigRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    companyDomain: jspb.Message.getFieldWithDefault(msg, 1, ""),
    countryIso3Code: jspb.Message.getFieldWithDefault(msg, 2, ""),
    taxServiceType: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.billing.private.company.taxation.DeleteTaxConfigRequest}
 */
proto.billing.private.company.taxation.DeleteTaxConfigRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.billing.private.company.taxation.DeleteTaxConfigRequest;
  return proto.billing.private.company.taxation.DeleteTaxConfigRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.billing.private.company.taxation.DeleteTaxConfigRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.billing.private.company.taxation.DeleteTaxConfigRequest}
 */
proto.billing.private.company.taxation.DeleteTaxConfigRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setCompanyDomain(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setCountryIso3Code(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setTaxServiceType(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.billing.private.company.taxation.DeleteTaxConfigRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.billing.private.company.taxation.DeleteTaxConfigRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.billing.private.company.taxation.DeleteTaxConfigRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing.private.company.taxation.DeleteTaxConfigRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCompanyDomain();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getCountryIso3Code();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getTaxServiceType();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional string company_domain = 1;
 * @return {string}
 */
proto.billing.private.company.taxation.DeleteTaxConfigRequest.prototype.getCompanyDomain = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.billing.private.company.taxation.DeleteTaxConfigRequest} returns this
 */
proto.billing.private.company.taxation.DeleteTaxConfigRequest.prototype.setCompanyDomain = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string country_iso3_code = 2;
 * @return {string}
 */
proto.billing.private.company.taxation.DeleteTaxConfigRequest.prototype.getCountryIso3Code = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.billing.private.company.taxation.DeleteTaxConfigRequest} returns this
 */
proto.billing.private.company.taxation.DeleteTaxConfigRequest.prototype.setCountryIso3Code = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string tax_service_type = 3;
 * @return {string}
 */
proto.billing.private.company.taxation.DeleteTaxConfigRequest.prototype.getTaxServiceType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.billing.private.company.taxation.DeleteTaxConfigRequest} returns this
 */
proto.billing.private.company.taxation.DeleteTaxConfigRequest.prototype.setTaxServiceType = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.billing.private.company.taxation.DeleteTaxConfigResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.billing.private.company.taxation.DeleteTaxConfigResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.billing.private.company.taxation.DeleteTaxConfigResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing.private.company.taxation.DeleteTaxConfigResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    isSuccess: jspb.Message.getBooleanFieldWithDefault(msg, 1, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.billing.private.company.taxation.DeleteTaxConfigResponse}
 */
proto.billing.private.company.taxation.DeleteTaxConfigResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.billing.private.company.taxation.DeleteTaxConfigResponse;
  return proto.billing.private.company.taxation.DeleteTaxConfigResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.billing.private.company.taxation.DeleteTaxConfigResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.billing.private.company.taxation.DeleteTaxConfigResponse}
 */
proto.billing.private.company.taxation.DeleteTaxConfigResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsSuccess(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.billing.private.company.taxation.DeleteTaxConfigResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.billing.private.company.taxation.DeleteTaxConfigResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.billing.private.company.taxation.DeleteTaxConfigResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing.private.company.taxation.DeleteTaxConfigResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getIsSuccess();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
};


/**
 * optional bool is_success = 1;
 * @return {boolean}
 */
proto.billing.private.company.taxation.DeleteTaxConfigResponse.prototype.getIsSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.billing.private.company.taxation.DeleteTaxConfigResponse} returns this
 */
proto.billing.private.company.taxation.DeleteTaxConfigResponse.prototype.setIsSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.billing.private.company.taxation.GetTaxConfigRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.billing.private.company.taxation.GetTaxConfigRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.billing.private.company.taxation.GetTaxConfigRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing.private.company.taxation.GetTaxConfigRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    companyDomain: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.billing.private.company.taxation.GetTaxConfigRequest}
 */
proto.billing.private.company.taxation.GetTaxConfigRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.billing.private.company.taxation.GetTaxConfigRequest;
  return proto.billing.private.company.taxation.GetTaxConfigRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.billing.private.company.taxation.GetTaxConfigRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.billing.private.company.taxation.GetTaxConfigRequest}
 */
proto.billing.private.company.taxation.GetTaxConfigRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setCompanyDomain(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.billing.private.company.taxation.GetTaxConfigRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.billing.private.company.taxation.GetTaxConfigRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.billing.private.company.taxation.GetTaxConfigRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing.private.company.taxation.GetTaxConfigRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCompanyDomain();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string company_domain = 1;
 * @return {string}
 */
proto.billing.private.company.taxation.GetTaxConfigRequest.prototype.getCompanyDomain = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.billing.private.company.taxation.GetTaxConfigRequest} returns this
 */
proto.billing.private.company.taxation.GetTaxConfigRequest.prototype.setCompanyDomain = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.billing.private.company.taxation.GetTaxConfigResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.billing.private.company.taxation.GetTaxConfigResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.billing.private.company.taxation.GetTaxConfigResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.billing.private.company.taxation.GetTaxConfigResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing.private.company.taxation.GetTaxConfigResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    resultsList: jspb.Message.toObjectList(msg.getResultsList(),
    proto.billing.private.company.taxation.TaxConfig.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.billing.private.company.taxation.GetTaxConfigResponse}
 */
proto.billing.private.company.taxation.GetTaxConfigResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.billing.private.company.taxation.GetTaxConfigResponse;
  return proto.billing.private.company.taxation.GetTaxConfigResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.billing.private.company.taxation.GetTaxConfigResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.billing.private.company.taxation.GetTaxConfigResponse}
 */
proto.billing.private.company.taxation.GetTaxConfigResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.billing.private.company.taxation.TaxConfig;
      reader.readMessage(value,proto.billing.private.company.taxation.TaxConfig.deserializeBinaryFromReader);
      msg.addResults(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.billing.private.company.taxation.GetTaxConfigResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.billing.private.company.taxation.GetTaxConfigResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.billing.private.company.taxation.GetTaxConfigResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing.private.company.taxation.GetTaxConfigResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getResultsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.billing.private.company.taxation.TaxConfig.serializeBinaryToWriter
    );
  }
};


/**
 * repeated TaxConfig results = 1;
 * @return {!Array<!proto.billing.private.company.taxation.TaxConfig>}
 */
proto.billing.private.company.taxation.GetTaxConfigResponse.prototype.getResultsList = function() {
  return /** @type{!Array<!proto.billing.private.company.taxation.TaxConfig>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.billing.private.company.taxation.TaxConfig, 1));
};


/**
 * @param {!Array<!proto.billing.private.company.taxation.TaxConfig>} value
 * @return {!proto.billing.private.company.taxation.GetTaxConfigResponse} returns this
*/
proto.billing.private.company.taxation.GetTaxConfigResponse.prototype.setResultsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.billing.private.company.taxation.TaxConfig=} opt_value
 * @param {number=} opt_index
 * @return {!proto.billing.private.company.taxation.TaxConfig}
 */
proto.billing.private.company.taxation.GetTaxConfigResponse.prototype.addResults = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.billing.private.company.taxation.TaxConfig, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.billing.private.company.taxation.GetTaxConfigResponse} returns this
 */
proto.billing.private.company.taxation.GetTaxConfigResponse.prototype.clearResultsList = function() {
  return this.setResultsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.billing.private.company.taxation.UpsertTaxJarConfigRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.billing.private.company.taxation.UpsertTaxJarConfigRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.billing.private.company.taxation.UpsertTaxJarConfigRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing.private.company.taxation.UpsertTaxJarConfigRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    companyDomain: jspb.Message.getFieldWithDefault(msg, 1, ""),
    countryIso3Code: jspb.Message.getFieldWithDefault(msg, 2, ""),
    taxJarConfig: (f = msg.getTaxJarConfig()) && proto.billing.private.company.taxation.TaxJarConfig.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.billing.private.company.taxation.UpsertTaxJarConfigRequest}
 */
proto.billing.private.company.taxation.UpsertTaxJarConfigRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.billing.private.company.taxation.UpsertTaxJarConfigRequest;
  return proto.billing.private.company.taxation.UpsertTaxJarConfigRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.billing.private.company.taxation.UpsertTaxJarConfigRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.billing.private.company.taxation.UpsertTaxJarConfigRequest}
 */
proto.billing.private.company.taxation.UpsertTaxJarConfigRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setCompanyDomain(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setCountryIso3Code(value);
      break;
    case 3:
      var value = new proto.billing.private.company.taxation.TaxJarConfig;
      reader.readMessage(value,proto.billing.private.company.taxation.TaxJarConfig.deserializeBinaryFromReader);
      msg.setTaxJarConfig(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.billing.private.company.taxation.UpsertTaxJarConfigRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.billing.private.company.taxation.UpsertTaxJarConfigRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.billing.private.company.taxation.UpsertTaxJarConfigRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing.private.company.taxation.UpsertTaxJarConfigRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCompanyDomain();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getCountryIso3Code();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getTaxJarConfig();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.billing.private.company.taxation.TaxJarConfig.serializeBinaryToWriter
    );
  }
};


/**
 * optional string company_domain = 1;
 * @return {string}
 */
proto.billing.private.company.taxation.UpsertTaxJarConfigRequest.prototype.getCompanyDomain = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.billing.private.company.taxation.UpsertTaxJarConfigRequest} returns this
 */
proto.billing.private.company.taxation.UpsertTaxJarConfigRequest.prototype.setCompanyDomain = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string country_iso3_code = 2;
 * @return {string}
 */
proto.billing.private.company.taxation.UpsertTaxJarConfigRequest.prototype.getCountryIso3Code = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.billing.private.company.taxation.UpsertTaxJarConfigRequest} returns this
 */
proto.billing.private.company.taxation.UpsertTaxJarConfigRequest.prototype.setCountryIso3Code = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional TaxJarConfig tax_jar_config = 3;
 * @return {?proto.billing.private.company.taxation.TaxJarConfig}
 */
proto.billing.private.company.taxation.UpsertTaxJarConfigRequest.prototype.getTaxJarConfig = function() {
  return /** @type{?proto.billing.private.company.taxation.TaxJarConfig} */ (
    jspb.Message.getWrapperField(this, proto.billing.private.company.taxation.TaxJarConfig, 3));
};


/**
 * @param {?proto.billing.private.company.taxation.TaxJarConfig|undefined} value
 * @return {!proto.billing.private.company.taxation.UpsertTaxJarConfigRequest} returns this
*/
proto.billing.private.company.taxation.UpsertTaxJarConfigRequest.prototype.setTaxJarConfig = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.billing.private.company.taxation.UpsertTaxJarConfigRequest} returns this
 */
proto.billing.private.company.taxation.UpsertTaxJarConfigRequest.prototype.clearTaxJarConfig = function() {
  return this.setTaxJarConfig(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.billing.private.company.taxation.UpsertTaxJarConfigRequest.prototype.hasTaxJarConfig = function() {
  return jspb.Message.getField(this, 3) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.billing.private.company.taxation.UpsertTaxJarConfigResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.billing.private.company.taxation.UpsertTaxJarConfigResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.billing.private.company.taxation.UpsertTaxJarConfigResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing.private.company.taxation.UpsertTaxJarConfigResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    companyDomain: jspb.Message.getFieldWithDefault(msg, 1, ""),
    countryIso3Code: jspb.Message.getFieldWithDefault(msg, 2, ""),
    taxJarConfig: (f = msg.getTaxJarConfig()) && proto.billing.private.company.taxation.TaxJarConfig.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.billing.private.company.taxation.UpsertTaxJarConfigResponse}
 */
proto.billing.private.company.taxation.UpsertTaxJarConfigResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.billing.private.company.taxation.UpsertTaxJarConfigResponse;
  return proto.billing.private.company.taxation.UpsertTaxJarConfigResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.billing.private.company.taxation.UpsertTaxJarConfigResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.billing.private.company.taxation.UpsertTaxJarConfigResponse}
 */
proto.billing.private.company.taxation.UpsertTaxJarConfigResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setCompanyDomain(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setCountryIso3Code(value);
      break;
    case 3:
      var value = new proto.billing.private.company.taxation.TaxJarConfig;
      reader.readMessage(value,proto.billing.private.company.taxation.TaxJarConfig.deserializeBinaryFromReader);
      msg.setTaxJarConfig(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.billing.private.company.taxation.UpsertTaxJarConfigResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.billing.private.company.taxation.UpsertTaxJarConfigResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.billing.private.company.taxation.UpsertTaxJarConfigResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing.private.company.taxation.UpsertTaxJarConfigResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCompanyDomain();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getCountryIso3Code();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getTaxJarConfig();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.billing.private.company.taxation.TaxJarConfig.serializeBinaryToWriter
    );
  }
};


/**
 * optional string company_domain = 1;
 * @return {string}
 */
proto.billing.private.company.taxation.UpsertTaxJarConfigResponse.prototype.getCompanyDomain = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.billing.private.company.taxation.UpsertTaxJarConfigResponse} returns this
 */
proto.billing.private.company.taxation.UpsertTaxJarConfigResponse.prototype.setCompanyDomain = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string country_iso3_code = 2;
 * @return {string}
 */
proto.billing.private.company.taxation.UpsertTaxJarConfigResponse.prototype.getCountryIso3Code = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.billing.private.company.taxation.UpsertTaxJarConfigResponse} returns this
 */
proto.billing.private.company.taxation.UpsertTaxJarConfigResponse.prototype.setCountryIso3Code = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional TaxJarConfig tax_jar_config = 3;
 * @return {?proto.billing.private.company.taxation.TaxJarConfig}
 */
proto.billing.private.company.taxation.UpsertTaxJarConfigResponse.prototype.getTaxJarConfig = function() {
  return /** @type{?proto.billing.private.company.taxation.TaxJarConfig} */ (
    jspb.Message.getWrapperField(this, proto.billing.private.company.taxation.TaxJarConfig, 3));
};


/**
 * @param {?proto.billing.private.company.taxation.TaxJarConfig|undefined} value
 * @return {!proto.billing.private.company.taxation.UpsertTaxJarConfigResponse} returns this
*/
proto.billing.private.company.taxation.UpsertTaxJarConfigResponse.prototype.setTaxJarConfig = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.billing.private.company.taxation.UpsertTaxJarConfigResponse} returns this
 */
proto.billing.private.company.taxation.UpsertTaxJarConfigResponse.prototype.clearTaxJarConfig = function() {
  return this.setTaxJarConfig(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.billing.private.company.taxation.UpsertTaxJarConfigResponse.prototype.hasTaxJarConfig = function() {
  return jspb.Message.getField(this, 3) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.billing.private.company.taxation.UpsertAutoBillsbyTaxRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.billing.private.company.taxation.UpsertAutoBillsbyTaxRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.billing.private.company.taxation.UpsertAutoBillsbyTaxRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing.private.company.taxation.UpsertAutoBillsbyTaxRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    companyDomain: jspb.Message.getFieldWithDefault(msg, 1, ""),
    countryIso3Code: jspb.Message.getFieldWithDefault(msg, 2, ""),
    billsbyTaxConfig: (f = msg.getBillsbyTaxConfig()) && proto.billing.private.company.taxation.AutoBillsbyTaxConfig.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.billing.private.company.taxation.UpsertAutoBillsbyTaxRequest}
 */
proto.billing.private.company.taxation.UpsertAutoBillsbyTaxRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.billing.private.company.taxation.UpsertAutoBillsbyTaxRequest;
  return proto.billing.private.company.taxation.UpsertAutoBillsbyTaxRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.billing.private.company.taxation.UpsertAutoBillsbyTaxRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.billing.private.company.taxation.UpsertAutoBillsbyTaxRequest}
 */
proto.billing.private.company.taxation.UpsertAutoBillsbyTaxRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setCompanyDomain(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setCountryIso3Code(value);
      break;
    case 3:
      var value = new proto.billing.private.company.taxation.AutoBillsbyTaxConfig;
      reader.readMessage(value,proto.billing.private.company.taxation.AutoBillsbyTaxConfig.deserializeBinaryFromReader);
      msg.setBillsbyTaxConfig(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.billing.private.company.taxation.UpsertAutoBillsbyTaxRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.billing.private.company.taxation.UpsertAutoBillsbyTaxRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.billing.private.company.taxation.UpsertAutoBillsbyTaxRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing.private.company.taxation.UpsertAutoBillsbyTaxRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCompanyDomain();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getCountryIso3Code();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getBillsbyTaxConfig();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.billing.private.company.taxation.AutoBillsbyTaxConfig.serializeBinaryToWriter
    );
  }
};


/**
 * optional string company_domain = 1;
 * @return {string}
 */
proto.billing.private.company.taxation.UpsertAutoBillsbyTaxRequest.prototype.getCompanyDomain = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.billing.private.company.taxation.UpsertAutoBillsbyTaxRequest} returns this
 */
proto.billing.private.company.taxation.UpsertAutoBillsbyTaxRequest.prototype.setCompanyDomain = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string country_iso3_code = 2;
 * @return {string}
 */
proto.billing.private.company.taxation.UpsertAutoBillsbyTaxRequest.prototype.getCountryIso3Code = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.billing.private.company.taxation.UpsertAutoBillsbyTaxRequest} returns this
 */
proto.billing.private.company.taxation.UpsertAutoBillsbyTaxRequest.prototype.setCountryIso3Code = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional AutoBillsbyTaxConfig billsby_tax_config = 3;
 * @return {?proto.billing.private.company.taxation.AutoBillsbyTaxConfig}
 */
proto.billing.private.company.taxation.UpsertAutoBillsbyTaxRequest.prototype.getBillsbyTaxConfig = function() {
  return /** @type{?proto.billing.private.company.taxation.AutoBillsbyTaxConfig} */ (
    jspb.Message.getWrapperField(this, proto.billing.private.company.taxation.AutoBillsbyTaxConfig, 3));
};


/**
 * @param {?proto.billing.private.company.taxation.AutoBillsbyTaxConfig|undefined} value
 * @return {!proto.billing.private.company.taxation.UpsertAutoBillsbyTaxRequest} returns this
*/
proto.billing.private.company.taxation.UpsertAutoBillsbyTaxRequest.prototype.setBillsbyTaxConfig = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.billing.private.company.taxation.UpsertAutoBillsbyTaxRequest} returns this
 */
proto.billing.private.company.taxation.UpsertAutoBillsbyTaxRequest.prototype.clearBillsbyTaxConfig = function() {
  return this.setBillsbyTaxConfig(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.billing.private.company.taxation.UpsertAutoBillsbyTaxRequest.prototype.hasBillsbyTaxConfig = function() {
  return jspb.Message.getField(this, 3) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.billing.private.company.taxation.UpsertAutoBillsbyTaxResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.billing.private.company.taxation.UpsertAutoBillsbyTaxResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.billing.private.company.taxation.UpsertAutoBillsbyTaxResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing.private.company.taxation.UpsertAutoBillsbyTaxResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    companyDomain: jspb.Message.getFieldWithDefault(msg, 1, ""),
    countryIso3Code: jspb.Message.getFieldWithDefault(msg, 2, ""),
    taxServiceType: jspb.Message.getFieldWithDefault(msg, 3, ""),
    billsbyTaxConfig: (f = msg.getBillsbyTaxConfig()) && proto.billing.private.company.taxation.AutoBillsbyTaxConfig.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.billing.private.company.taxation.UpsertAutoBillsbyTaxResponse}
 */
proto.billing.private.company.taxation.UpsertAutoBillsbyTaxResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.billing.private.company.taxation.UpsertAutoBillsbyTaxResponse;
  return proto.billing.private.company.taxation.UpsertAutoBillsbyTaxResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.billing.private.company.taxation.UpsertAutoBillsbyTaxResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.billing.private.company.taxation.UpsertAutoBillsbyTaxResponse}
 */
proto.billing.private.company.taxation.UpsertAutoBillsbyTaxResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setCompanyDomain(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setCountryIso3Code(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setTaxServiceType(value);
      break;
    case 4:
      var value = new proto.billing.private.company.taxation.AutoBillsbyTaxConfig;
      reader.readMessage(value,proto.billing.private.company.taxation.AutoBillsbyTaxConfig.deserializeBinaryFromReader);
      msg.setBillsbyTaxConfig(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.billing.private.company.taxation.UpsertAutoBillsbyTaxResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.billing.private.company.taxation.UpsertAutoBillsbyTaxResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.billing.private.company.taxation.UpsertAutoBillsbyTaxResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing.private.company.taxation.UpsertAutoBillsbyTaxResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCompanyDomain();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getCountryIso3Code();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getTaxServiceType();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getBillsbyTaxConfig();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.billing.private.company.taxation.AutoBillsbyTaxConfig.serializeBinaryToWriter
    );
  }
};


/**
 * optional string company_domain = 1;
 * @return {string}
 */
proto.billing.private.company.taxation.UpsertAutoBillsbyTaxResponse.prototype.getCompanyDomain = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.billing.private.company.taxation.UpsertAutoBillsbyTaxResponse} returns this
 */
proto.billing.private.company.taxation.UpsertAutoBillsbyTaxResponse.prototype.setCompanyDomain = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string country_iso3_code = 2;
 * @return {string}
 */
proto.billing.private.company.taxation.UpsertAutoBillsbyTaxResponse.prototype.getCountryIso3Code = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.billing.private.company.taxation.UpsertAutoBillsbyTaxResponse} returns this
 */
proto.billing.private.company.taxation.UpsertAutoBillsbyTaxResponse.prototype.setCountryIso3Code = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string tax_service_type = 3;
 * @return {string}
 */
proto.billing.private.company.taxation.UpsertAutoBillsbyTaxResponse.prototype.getTaxServiceType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.billing.private.company.taxation.UpsertAutoBillsbyTaxResponse} returns this
 */
proto.billing.private.company.taxation.UpsertAutoBillsbyTaxResponse.prototype.setTaxServiceType = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional AutoBillsbyTaxConfig billsby_tax_config = 4;
 * @return {?proto.billing.private.company.taxation.AutoBillsbyTaxConfig}
 */
proto.billing.private.company.taxation.UpsertAutoBillsbyTaxResponse.prototype.getBillsbyTaxConfig = function() {
  return /** @type{?proto.billing.private.company.taxation.AutoBillsbyTaxConfig} */ (
    jspb.Message.getWrapperField(this, proto.billing.private.company.taxation.AutoBillsbyTaxConfig, 4));
};


/**
 * @param {?proto.billing.private.company.taxation.AutoBillsbyTaxConfig|undefined} value
 * @return {!proto.billing.private.company.taxation.UpsertAutoBillsbyTaxResponse} returns this
*/
proto.billing.private.company.taxation.UpsertAutoBillsbyTaxResponse.prototype.setBillsbyTaxConfig = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.billing.private.company.taxation.UpsertAutoBillsbyTaxResponse} returns this
 */
proto.billing.private.company.taxation.UpsertAutoBillsbyTaxResponse.prototype.clearBillsbyTaxConfig = function() {
  return this.setBillsbyTaxConfig(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.billing.private.company.taxation.UpsertAutoBillsbyTaxResponse.prototype.hasBillsbyTaxConfig = function() {
  return jspb.Message.getField(this, 4) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.billing.private.company.taxation.UpsertManualTaxConfigRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.billing.private.company.taxation.UpsertManualTaxConfigRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.billing.private.company.taxation.UpsertManualTaxConfigRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing.private.company.taxation.UpsertManualTaxConfigRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    companyDomain: jspb.Message.getFieldWithDefault(msg, 1, ""),
    countryIso3Code: jspb.Message.getFieldWithDefault(msg, 2, ""),
    manualTaxConfig: (f = msg.getManualTaxConfig()) && proto.billing.private.company.taxation.ManualTaxConfig.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.billing.private.company.taxation.UpsertManualTaxConfigRequest}
 */
proto.billing.private.company.taxation.UpsertManualTaxConfigRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.billing.private.company.taxation.UpsertManualTaxConfigRequest;
  return proto.billing.private.company.taxation.UpsertManualTaxConfigRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.billing.private.company.taxation.UpsertManualTaxConfigRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.billing.private.company.taxation.UpsertManualTaxConfigRequest}
 */
proto.billing.private.company.taxation.UpsertManualTaxConfigRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setCompanyDomain(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setCountryIso3Code(value);
      break;
    case 3:
      var value = new proto.billing.private.company.taxation.ManualTaxConfig;
      reader.readMessage(value,proto.billing.private.company.taxation.ManualTaxConfig.deserializeBinaryFromReader);
      msg.setManualTaxConfig(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.billing.private.company.taxation.UpsertManualTaxConfigRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.billing.private.company.taxation.UpsertManualTaxConfigRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.billing.private.company.taxation.UpsertManualTaxConfigRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing.private.company.taxation.UpsertManualTaxConfigRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCompanyDomain();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getCountryIso3Code();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getManualTaxConfig();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.billing.private.company.taxation.ManualTaxConfig.serializeBinaryToWriter
    );
  }
};


/**
 * optional string company_domain = 1;
 * @return {string}
 */
proto.billing.private.company.taxation.UpsertManualTaxConfigRequest.prototype.getCompanyDomain = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.billing.private.company.taxation.UpsertManualTaxConfigRequest} returns this
 */
proto.billing.private.company.taxation.UpsertManualTaxConfigRequest.prototype.setCompanyDomain = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string country_iso3_code = 2;
 * @return {string}
 */
proto.billing.private.company.taxation.UpsertManualTaxConfigRequest.prototype.getCountryIso3Code = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.billing.private.company.taxation.UpsertManualTaxConfigRequest} returns this
 */
proto.billing.private.company.taxation.UpsertManualTaxConfigRequest.prototype.setCountryIso3Code = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional ManualTaxConfig manual_tax_config = 3;
 * @return {?proto.billing.private.company.taxation.ManualTaxConfig}
 */
proto.billing.private.company.taxation.UpsertManualTaxConfigRequest.prototype.getManualTaxConfig = function() {
  return /** @type{?proto.billing.private.company.taxation.ManualTaxConfig} */ (
    jspb.Message.getWrapperField(this, proto.billing.private.company.taxation.ManualTaxConfig, 3));
};


/**
 * @param {?proto.billing.private.company.taxation.ManualTaxConfig|undefined} value
 * @return {!proto.billing.private.company.taxation.UpsertManualTaxConfigRequest} returns this
*/
proto.billing.private.company.taxation.UpsertManualTaxConfigRequest.prototype.setManualTaxConfig = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.billing.private.company.taxation.UpsertManualTaxConfigRequest} returns this
 */
proto.billing.private.company.taxation.UpsertManualTaxConfigRequest.prototype.clearManualTaxConfig = function() {
  return this.setManualTaxConfig(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.billing.private.company.taxation.UpsertManualTaxConfigRequest.prototype.hasManualTaxConfig = function() {
  return jspb.Message.getField(this, 3) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.billing.private.company.taxation.UpsertManualTaxConfigResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.billing.private.company.taxation.UpsertManualTaxConfigResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.billing.private.company.taxation.UpsertManualTaxConfigResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing.private.company.taxation.UpsertManualTaxConfigResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    companyDomain: jspb.Message.getFieldWithDefault(msg, 1, ""),
    countryIso3Code: jspb.Message.getFieldWithDefault(msg, 2, ""),
    manualTaxConfig: (f = msg.getManualTaxConfig()) && proto.billing.private.company.taxation.ManualTaxConfig.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.billing.private.company.taxation.UpsertManualTaxConfigResponse}
 */
proto.billing.private.company.taxation.UpsertManualTaxConfigResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.billing.private.company.taxation.UpsertManualTaxConfigResponse;
  return proto.billing.private.company.taxation.UpsertManualTaxConfigResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.billing.private.company.taxation.UpsertManualTaxConfigResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.billing.private.company.taxation.UpsertManualTaxConfigResponse}
 */
proto.billing.private.company.taxation.UpsertManualTaxConfigResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setCompanyDomain(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setCountryIso3Code(value);
      break;
    case 3:
      var value = new proto.billing.private.company.taxation.ManualTaxConfig;
      reader.readMessage(value,proto.billing.private.company.taxation.ManualTaxConfig.deserializeBinaryFromReader);
      msg.setManualTaxConfig(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.billing.private.company.taxation.UpsertManualTaxConfigResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.billing.private.company.taxation.UpsertManualTaxConfigResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.billing.private.company.taxation.UpsertManualTaxConfigResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing.private.company.taxation.UpsertManualTaxConfigResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCompanyDomain();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getCountryIso3Code();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getManualTaxConfig();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.billing.private.company.taxation.ManualTaxConfig.serializeBinaryToWriter
    );
  }
};


/**
 * optional string company_domain = 1;
 * @return {string}
 */
proto.billing.private.company.taxation.UpsertManualTaxConfigResponse.prototype.getCompanyDomain = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.billing.private.company.taxation.UpsertManualTaxConfigResponse} returns this
 */
proto.billing.private.company.taxation.UpsertManualTaxConfigResponse.prototype.setCompanyDomain = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string country_iso3_code = 2;
 * @return {string}
 */
proto.billing.private.company.taxation.UpsertManualTaxConfigResponse.prototype.getCountryIso3Code = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.billing.private.company.taxation.UpsertManualTaxConfigResponse} returns this
 */
proto.billing.private.company.taxation.UpsertManualTaxConfigResponse.prototype.setCountryIso3Code = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional ManualTaxConfig manual_tax_config = 3;
 * @return {?proto.billing.private.company.taxation.ManualTaxConfig}
 */
proto.billing.private.company.taxation.UpsertManualTaxConfigResponse.prototype.getManualTaxConfig = function() {
  return /** @type{?proto.billing.private.company.taxation.ManualTaxConfig} */ (
    jspb.Message.getWrapperField(this, proto.billing.private.company.taxation.ManualTaxConfig, 3));
};


/**
 * @param {?proto.billing.private.company.taxation.ManualTaxConfig|undefined} value
 * @return {!proto.billing.private.company.taxation.UpsertManualTaxConfigResponse} returns this
*/
proto.billing.private.company.taxation.UpsertManualTaxConfigResponse.prototype.setManualTaxConfig = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.billing.private.company.taxation.UpsertManualTaxConfigResponse} returns this
 */
proto.billing.private.company.taxation.UpsertManualTaxConfigResponse.prototype.clearManualTaxConfig = function() {
  return this.setManualTaxConfig(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.billing.private.company.taxation.UpsertManualTaxConfigResponse.prototype.hasManualTaxConfig = function() {
  return jspb.Message.getField(this, 3) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.billing.private.company.taxation.GetAutoBillsbyProductCategoriesRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.billing.private.company.taxation.GetAutoBillsbyProductCategoriesRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.billing.private.company.taxation.GetAutoBillsbyProductCategoriesRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing.private.company.taxation.GetAutoBillsbyProductCategoriesRequest.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.billing.private.company.taxation.GetAutoBillsbyProductCategoriesRequest}
 */
proto.billing.private.company.taxation.GetAutoBillsbyProductCategoriesRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.billing.private.company.taxation.GetAutoBillsbyProductCategoriesRequest;
  return proto.billing.private.company.taxation.GetAutoBillsbyProductCategoriesRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.billing.private.company.taxation.GetAutoBillsbyProductCategoriesRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.billing.private.company.taxation.GetAutoBillsbyProductCategoriesRequest}
 */
proto.billing.private.company.taxation.GetAutoBillsbyProductCategoriesRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.billing.private.company.taxation.GetAutoBillsbyProductCategoriesRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.billing.private.company.taxation.GetAutoBillsbyProductCategoriesRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.billing.private.company.taxation.GetAutoBillsbyProductCategoriesRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing.private.company.taxation.GetAutoBillsbyProductCategoriesRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.billing.private.company.taxation.GetTaxJarProductCategoriesRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.billing.private.company.taxation.GetTaxJarProductCategoriesRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.billing.private.company.taxation.GetTaxJarProductCategoriesRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing.private.company.taxation.GetTaxJarProductCategoriesRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    apiToken: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.billing.private.company.taxation.GetTaxJarProductCategoriesRequest}
 */
proto.billing.private.company.taxation.GetTaxJarProductCategoriesRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.billing.private.company.taxation.GetTaxJarProductCategoriesRequest;
  return proto.billing.private.company.taxation.GetTaxJarProductCategoriesRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.billing.private.company.taxation.GetTaxJarProductCategoriesRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.billing.private.company.taxation.GetTaxJarProductCategoriesRequest}
 */
proto.billing.private.company.taxation.GetTaxJarProductCategoriesRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setApiToken(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.billing.private.company.taxation.GetTaxJarProductCategoriesRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.billing.private.company.taxation.GetTaxJarProductCategoriesRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.billing.private.company.taxation.GetTaxJarProductCategoriesRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing.private.company.taxation.GetTaxJarProductCategoriesRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getApiToken();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string api_token = 1;
 * @return {string}
 */
proto.billing.private.company.taxation.GetTaxJarProductCategoriesRequest.prototype.getApiToken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.billing.private.company.taxation.GetTaxJarProductCategoriesRequest} returns this
 */
proto.billing.private.company.taxation.GetTaxJarProductCategoriesRequest.prototype.setApiToken = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.billing.private.company.taxation.GetProductCategoriesResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.billing.private.company.taxation.GetProductCategoriesResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.billing.private.company.taxation.GetProductCategoriesResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.billing.private.company.taxation.GetProductCategoriesResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing.private.company.taxation.GetProductCategoriesResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    categoriesList: jspb.Message.toObjectList(msg.getCategoriesList(),
    proto.billing.private.company.taxation.GetProductCategoriesResponse.Category.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.billing.private.company.taxation.GetProductCategoriesResponse}
 */
proto.billing.private.company.taxation.GetProductCategoriesResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.billing.private.company.taxation.GetProductCategoriesResponse;
  return proto.billing.private.company.taxation.GetProductCategoriesResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.billing.private.company.taxation.GetProductCategoriesResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.billing.private.company.taxation.GetProductCategoriesResponse}
 */
proto.billing.private.company.taxation.GetProductCategoriesResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.billing.private.company.taxation.GetProductCategoriesResponse.Category;
      reader.readMessage(value,proto.billing.private.company.taxation.GetProductCategoriesResponse.Category.deserializeBinaryFromReader);
      msg.addCategories(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.billing.private.company.taxation.GetProductCategoriesResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.billing.private.company.taxation.GetProductCategoriesResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.billing.private.company.taxation.GetProductCategoriesResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing.private.company.taxation.GetProductCategoriesResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCategoriesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.billing.private.company.taxation.GetProductCategoriesResponse.Category.serializeBinaryToWriter
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.billing.private.company.taxation.GetProductCategoriesResponse.Category.prototype.toObject = function(opt_includeInstance) {
  return proto.billing.private.company.taxation.GetProductCategoriesResponse.Category.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.billing.private.company.taxation.GetProductCategoriesResponse.Category} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing.private.company.taxation.GetProductCategoriesResponse.Category.toObject = function(includeInstance, msg) {
  var f, obj = {
    name: jspb.Message.getFieldWithDefault(msg, 1, ""),
    productTaxCode: jspb.Message.getFieldWithDefault(msg, 2, ""),
    description: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.billing.private.company.taxation.GetProductCategoriesResponse.Category}
 */
proto.billing.private.company.taxation.GetProductCategoriesResponse.Category.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.billing.private.company.taxation.GetProductCategoriesResponse.Category;
  return proto.billing.private.company.taxation.GetProductCategoriesResponse.Category.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.billing.private.company.taxation.GetProductCategoriesResponse.Category} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.billing.private.company.taxation.GetProductCategoriesResponse.Category}
 */
proto.billing.private.company.taxation.GetProductCategoriesResponse.Category.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setProductTaxCode(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.billing.private.company.taxation.GetProductCategoriesResponse.Category.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.billing.private.company.taxation.GetProductCategoriesResponse.Category.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.billing.private.company.taxation.GetProductCategoriesResponse.Category} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing.private.company.taxation.GetProductCategoriesResponse.Category.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getProductTaxCode();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getDescription();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional string name = 1;
 * @return {string}
 */
proto.billing.private.company.taxation.GetProductCategoriesResponse.Category.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.billing.private.company.taxation.GetProductCategoriesResponse.Category} returns this
 */
proto.billing.private.company.taxation.GetProductCategoriesResponse.Category.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string product_tax_code = 2;
 * @return {string}
 */
proto.billing.private.company.taxation.GetProductCategoriesResponse.Category.prototype.getProductTaxCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.billing.private.company.taxation.GetProductCategoriesResponse.Category} returns this
 */
proto.billing.private.company.taxation.GetProductCategoriesResponse.Category.prototype.setProductTaxCode = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string description = 3;
 * @return {string}
 */
proto.billing.private.company.taxation.GetProductCategoriesResponse.Category.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.billing.private.company.taxation.GetProductCategoriesResponse.Category} returns this
 */
proto.billing.private.company.taxation.GetProductCategoriesResponse.Category.prototype.setDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * repeated Category categories = 1;
 * @return {!Array<!proto.billing.private.company.taxation.GetProductCategoriesResponse.Category>}
 */
proto.billing.private.company.taxation.GetProductCategoriesResponse.prototype.getCategoriesList = function() {
  return /** @type{!Array<!proto.billing.private.company.taxation.GetProductCategoriesResponse.Category>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.billing.private.company.taxation.GetProductCategoriesResponse.Category, 1));
};


/**
 * @param {!Array<!proto.billing.private.company.taxation.GetProductCategoriesResponse.Category>} value
 * @return {!proto.billing.private.company.taxation.GetProductCategoriesResponse} returns this
*/
proto.billing.private.company.taxation.GetProductCategoriesResponse.prototype.setCategoriesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.billing.private.company.taxation.GetProductCategoriesResponse.Category=} opt_value
 * @param {number=} opt_index
 * @return {!proto.billing.private.company.taxation.GetProductCategoriesResponse.Category}
 */
proto.billing.private.company.taxation.GetProductCategoriesResponse.prototype.addCategories = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.billing.private.company.taxation.GetProductCategoriesResponse.Category, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.billing.private.company.taxation.GetProductCategoriesResponse} returns this
 */
proto.billing.private.company.taxation.GetProductCategoriesResponse.prototype.clearCategoriesList = function() {
  return this.setCategoriesList([]);
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.billing.private.company.taxation.TaxConfig.oneofGroups_ = [[4,5,6]];

/**
 * @enum {number}
 */
proto.billing.private.company.taxation.TaxConfig.ConfigCase = {
  CONFIG_NOT_SET: 0,
  TAX_JAR_CONFIG: 4,
  BILLSBY_TAX_CONFIG: 5,
  MANUAL_TAX_CONFIG: 6
};

/**
 * @return {proto.billing.private.company.taxation.TaxConfig.ConfigCase}
 */
proto.billing.private.company.taxation.TaxConfig.prototype.getConfigCase = function() {
  return /** @type {proto.billing.private.company.taxation.TaxConfig.ConfigCase} */(jspb.Message.computeOneofCase(this, proto.billing.private.company.taxation.TaxConfig.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.billing.private.company.taxation.TaxConfig.prototype.toObject = function(opt_includeInstance) {
  return proto.billing.private.company.taxation.TaxConfig.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.billing.private.company.taxation.TaxConfig} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing.private.company.taxation.TaxConfig.toObject = function(includeInstance, msg) {
  var f, obj = {
    companyDomain: jspb.Message.getFieldWithDefault(msg, 1, ""),
    countryIso3Code: jspb.Message.getFieldWithDefault(msg, 2, ""),
    taxServiceType: jspb.Message.getFieldWithDefault(msg, 3, ""),
    taxJarConfig: (f = msg.getTaxJarConfig()) && proto.billing.private.company.taxation.TaxJarConfig.toObject(includeInstance, f),
    billsbyTaxConfig: (f = msg.getBillsbyTaxConfig()) && proto.billing.private.company.taxation.AutoBillsbyTaxConfig.toObject(includeInstance, f),
    manualTaxConfig: (f = msg.getManualTaxConfig()) && proto.billing.private.company.taxation.ManualTaxConfig.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.billing.private.company.taxation.TaxConfig}
 */
proto.billing.private.company.taxation.TaxConfig.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.billing.private.company.taxation.TaxConfig;
  return proto.billing.private.company.taxation.TaxConfig.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.billing.private.company.taxation.TaxConfig} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.billing.private.company.taxation.TaxConfig}
 */
proto.billing.private.company.taxation.TaxConfig.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setCompanyDomain(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setCountryIso3Code(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setTaxServiceType(value);
      break;
    case 4:
      var value = new proto.billing.private.company.taxation.TaxJarConfig;
      reader.readMessage(value,proto.billing.private.company.taxation.TaxJarConfig.deserializeBinaryFromReader);
      msg.setTaxJarConfig(value);
      break;
    case 5:
      var value = new proto.billing.private.company.taxation.AutoBillsbyTaxConfig;
      reader.readMessage(value,proto.billing.private.company.taxation.AutoBillsbyTaxConfig.deserializeBinaryFromReader);
      msg.setBillsbyTaxConfig(value);
      break;
    case 6:
      var value = new proto.billing.private.company.taxation.ManualTaxConfig;
      reader.readMessage(value,proto.billing.private.company.taxation.ManualTaxConfig.deserializeBinaryFromReader);
      msg.setManualTaxConfig(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.billing.private.company.taxation.TaxConfig.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.billing.private.company.taxation.TaxConfig.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.billing.private.company.taxation.TaxConfig} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing.private.company.taxation.TaxConfig.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCompanyDomain();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getCountryIso3Code();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getTaxServiceType();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getTaxJarConfig();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.billing.private.company.taxation.TaxJarConfig.serializeBinaryToWriter
    );
  }
  f = message.getBillsbyTaxConfig();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      proto.billing.private.company.taxation.AutoBillsbyTaxConfig.serializeBinaryToWriter
    );
  }
  f = message.getManualTaxConfig();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      proto.billing.private.company.taxation.ManualTaxConfig.serializeBinaryToWriter
    );
  }
};


/**
 * optional string company_domain = 1;
 * @return {string}
 */
proto.billing.private.company.taxation.TaxConfig.prototype.getCompanyDomain = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.billing.private.company.taxation.TaxConfig} returns this
 */
proto.billing.private.company.taxation.TaxConfig.prototype.setCompanyDomain = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string country_iso3_code = 2;
 * @return {string}
 */
proto.billing.private.company.taxation.TaxConfig.prototype.getCountryIso3Code = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.billing.private.company.taxation.TaxConfig} returns this
 */
proto.billing.private.company.taxation.TaxConfig.prototype.setCountryIso3Code = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string tax_service_type = 3;
 * @return {string}
 */
proto.billing.private.company.taxation.TaxConfig.prototype.getTaxServiceType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.billing.private.company.taxation.TaxConfig} returns this
 */
proto.billing.private.company.taxation.TaxConfig.prototype.setTaxServiceType = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional TaxJarConfig tax_jar_config = 4;
 * @return {?proto.billing.private.company.taxation.TaxJarConfig}
 */
proto.billing.private.company.taxation.TaxConfig.prototype.getTaxJarConfig = function() {
  return /** @type{?proto.billing.private.company.taxation.TaxJarConfig} */ (
    jspb.Message.getWrapperField(this, proto.billing.private.company.taxation.TaxJarConfig, 4));
};


/**
 * @param {?proto.billing.private.company.taxation.TaxJarConfig|undefined} value
 * @return {!proto.billing.private.company.taxation.TaxConfig} returns this
*/
proto.billing.private.company.taxation.TaxConfig.prototype.setTaxJarConfig = function(value) {
  return jspb.Message.setOneofWrapperField(this, 4, proto.billing.private.company.taxation.TaxConfig.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.billing.private.company.taxation.TaxConfig} returns this
 */
proto.billing.private.company.taxation.TaxConfig.prototype.clearTaxJarConfig = function() {
  return this.setTaxJarConfig(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.billing.private.company.taxation.TaxConfig.prototype.hasTaxJarConfig = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional AutoBillsbyTaxConfig billsby_tax_config = 5;
 * @return {?proto.billing.private.company.taxation.AutoBillsbyTaxConfig}
 */
proto.billing.private.company.taxation.TaxConfig.prototype.getBillsbyTaxConfig = function() {
  return /** @type{?proto.billing.private.company.taxation.AutoBillsbyTaxConfig} */ (
    jspb.Message.getWrapperField(this, proto.billing.private.company.taxation.AutoBillsbyTaxConfig, 5));
};


/**
 * @param {?proto.billing.private.company.taxation.AutoBillsbyTaxConfig|undefined} value
 * @return {!proto.billing.private.company.taxation.TaxConfig} returns this
*/
proto.billing.private.company.taxation.TaxConfig.prototype.setBillsbyTaxConfig = function(value) {
  return jspb.Message.setOneofWrapperField(this, 5, proto.billing.private.company.taxation.TaxConfig.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.billing.private.company.taxation.TaxConfig} returns this
 */
proto.billing.private.company.taxation.TaxConfig.prototype.clearBillsbyTaxConfig = function() {
  return this.setBillsbyTaxConfig(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.billing.private.company.taxation.TaxConfig.prototype.hasBillsbyTaxConfig = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional ManualTaxConfig manual_tax_config = 6;
 * @return {?proto.billing.private.company.taxation.ManualTaxConfig}
 */
proto.billing.private.company.taxation.TaxConfig.prototype.getManualTaxConfig = function() {
  return /** @type{?proto.billing.private.company.taxation.ManualTaxConfig} */ (
    jspb.Message.getWrapperField(this, proto.billing.private.company.taxation.ManualTaxConfig, 6));
};


/**
 * @param {?proto.billing.private.company.taxation.ManualTaxConfig|undefined} value
 * @return {!proto.billing.private.company.taxation.TaxConfig} returns this
*/
proto.billing.private.company.taxation.TaxConfig.prototype.setManualTaxConfig = function(value) {
  return jspb.Message.setOneofWrapperField(this, 6, proto.billing.private.company.taxation.TaxConfig.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.billing.private.company.taxation.TaxConfig} returns this
 */
proto.billing.private.company.taxation.TaxConfig.prototype.clearManualTaxConfig = function() {
  return this.setManualTaxConfig(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.billing.private.company.taxation.TaxConfig.prototype.hasManualTaxConfig = function() {
  return jspb.Message.getField(this, 6) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.billing.private.company.taxation.TaxJarConfig.prototype.toObject = function(opt_includeInstance) {
  return proto.billing.private.company.taxation.TaxJarConfig.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.billing.private.company.taxation.TaxJarConfig} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing.private.company.taxation.TaxJarConfig.toObject = function(includeInstance, msg) {
  var f, obj = {
    apiToken: jspb.Message.getFieldWithDefault(msg, 1, ""),
    taxProductCategoryCode: jspb.Message.getFieldWithDefault(msg, 2, ""),
    isAutoFileEnabled: jspb.Message.getBooleanFieldWithDefault(msg, 3, false),
    taxLabel: jspb.Message.getFieldWithDefault(msg, 4, ""),
    taxRegNumber: jspb.Message.getFieldWithDefault(msg, 5, ""),
    taxRegNumberRequirementType: jspb.Message.getFieldWithDefault(msg, 6, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.billing.private.company.taxation.TaxJarConfig}
 */
proto.billing.private.company.taxation.TaxJarConfig.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.billing.private.company.taxation.TaxJarConfig;
  return proto.billing.private.company.taxation.TaxJarConfig.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.billing.private.company.taxation.TaxJarConfig} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.billing.private.company.taxation.TaxJarConfig}
 */
proto.billing.private.company.taxation.TaxJarConfig.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setApiToken(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setTaxProductCategoryCode(value);
      break;
    case 3:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsAutoFileEnabled(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setTaxLabel(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setTaxRegNumber(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setTaxRegNumberRequirementType(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.billing.private.company.taxation.TaxJarConfig.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.billing.private.company.taxation.TaxJarConfig.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.billing.private.company.taxation.TaxJarConfig} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing.private.company.taxation.TaxJarConfig.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getApiToken();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getTaxProductCategoryCode();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getIsAutoFileEnabled();
  if (f) {
    writer.writeBool(
      3,
      f
    );
  }
  f = message.getTaxLabel();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getTaxRegNumber();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getTaxRegNumberRequirementType();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
};


/**
 * optional string api_token = 1;
 * @return {string}
 */
proto.billing.private.company.taxation.TaxJarConfig.prototype.getApiToken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.billing.private.company.taxation.TaxJarConfig} returns this
 */
proto.billing.private.company.taxation.TaxJarConfig.prototype.setApiToken = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string tax_product_category_code = 2;
 * @return {string}
 */
proto.billing.private.company.taxation.TaxJarConfig.prototype.getTaxProductCategoryCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.billing.private.company.taxation.TaxJarConfig} returns this
 */
proto.billing.private.company.taxation.TaxJarConfig.prototype.setTaxProductCategoryCode = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional bool is_auto_file_enabled = 3;
 * @return {boolean}
 */
proto.billing.private.company.taxation.TaxJarConfig.prototype.getIsAutoFileEnabled = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 3, false));
};


/**
 * @param {boolean} value
 * @return {!proto.billing.private.company.taxation.TaxJarConfig} returns this
 */
proto.billing.private.company.taxation.TaxJarConfig.prototype.setIsAutoFileEnabled = function(value) {
  return jspb.Message.setProto3BooleanField(this, 3, value);
};


/**
 * optional string tax_label = 4;
 * @return {string}
 */
proto.billing.private.company.taxation.TaxJarConfig.prototype.getTaxLabel = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.billing.private.company.taxation.TaxJarConfig} returns this
 */
proto.billing.private.company.taxation.TaxJarConfig.prototype.setTaxLabel = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string tax_reg_number = 5;
 * @return {string}
 */
proto.billing.private.company.taxation.TaxJarConfig.prototype.getTaxRegNumber = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.billing.private.company.taxation.TaxJarConfig} returns this
 */
proto.billing.private.company.taxation.TaxJarConfig.prototype.setTaxRegNumber = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string tax_reg_number_requirement_type = 6;
 * @return {string}
 */
proto.billing.private.company.taxation.TaxJarConfig.prototype.getTaxRegNumberRequirementType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.billing.private.company.taxation.TaxJarConfig} returns this
 */
proto.billing.private.company.taxation.TaxJarConfig.prototype.setTaxRegNumberRequirementType = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.billing.private.company.taxation.AutoBillsbyTaxConfig.prototype.toObject = function(opt_includeInstance) {
  return proto.billing.private.company.taxation.AutoBillsbyTaxConfig.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.billing.private.company.taxation.AutoBillsbyTaxConfig} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing.private.company.taxation.AutoBillsbyTaxConfig.toObject = function(includeInstance, msg) {
  var f, obj = {
    taxProductCategoryCode: jspb.Message.getFieldWithDefault(msg, 1, ""),
    taxLabel: jspb.Message.getFieldWithDefault(msg, 2, ""),
    taxRegNumber: jspb.Message.getFieldWithDefault(msg, 3, ""),
    taxRegNumberRequirementType: jspb.Message.getFieldWithDefault(msg, 4, ""),
    nexusAddress: (f = msg.getNexusAddress()) && proto.billing.private.company.taxation.Address.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.billing.private.company.taxation.AutoBillsbyTaxConfig}
 */
proto.billing.private.company.taxation.AutoBillsbyTaxConfig.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.billing.private.company.taxation.AutoBillsbyTaxConfig;
  return proto.billing.private.company.taxation.AutoBillsbyTaxConfig.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.billing.private.company.taxation.AutoBillsbyTaxConfig} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.billing.private.company.taxation.AutoBillsbyTaxConfig}
 */
proto.billing.private.company.taxation.AutoBillsbyTaxConfig.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setTaxProductCategoryCode(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setTaxLabel(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setTaxRegNumber(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setTaxRegNumberRequirementType(value);
      break;
    case 5:
      var value = new proto.billing.private.company.taxation.Address;
      reader.readMessage(value,proto.billing.private.company.taxation.Address.deserializeBinaryFromReader);
      msg.setNexusAddress(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.billing.private.company.taxation.AutoBillsbyTaxConfig.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.billing.private.company.taxation.AutoBillsbyTaxConfig.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.billing.private.company.taxation.AutoBillsbyTaxConfig} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing.private.company.taxation.AutoBillsbyTaxConfig.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTaxProductCategoryCode();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getTaxLabel();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getTaxRegNumber();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getTaxRegNumberRequirementType();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getNexusAddress();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      proto.billing.private.company.taxation.Address.serializeBinaryToWriter
    );
  }
};


/**
 * optional string tax_product_category_code = 1;
 * @return {string}
 */
proto.billing.private.company.taxation.AutoBillsbyTaxConfig.prototype.getTaxProductCategoryCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.billing.private.company.taxation.AutoBillsbyTaxConfig} returns this
 */
proto.billing.private.company.taxation.AutoBillsbyTaxConfig.prototype.setTaxProductCategoryCode = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string tax_label = 2;
 * @return {string}
 */
proto.billing.private.company.taxation.AutoBillsbyTaxConfig.prototype.getTaxLabel = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.billing.private.company.taxation.AutoBillsbyTaxConfig} returns this
 */
proto.billing.private.company.taxation.AutoBillsbyTaxConfig.prototype.setTaxLabel = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string tax_reg_number = 3;
 * @return {string}
 */
proto.billing.private.company.taxation.AutoBillsbyTaxConfig.prototype.getTaxRegNumber = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.billing.private.company.taxation.AutoBillsbyTaxConfig} returns this
 */
proto.billing.private.company.taxation.AutoBillsbyTaxConfig.prototype.setTaxRegNumber = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string tax_reg_number_requirement_type = 4;
 * @return {string}
 */
proto.billing.private.company.taxation.AutoBillsbyTaxConfig.prototype.getTaxRegNumberRequirementType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.billing.private.company.taxation.AutoBillsbyTaxConfig} returns this
 */
proto.billing.private.company.taxation.AutoBillsbyTaxConfig.prototype.setTaxRegNumberRequirementType = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional Address nexus_address = 5;
 * @return {?proto.billing.private.company.taxation.Address}
 */
proto.billing.private.company.taxation.AutoBillsbyTaxConfig.prototype.getNexusAddress = function() {
  return /** @type{?proto.billing.private.company.taxation.Address} */ (
    jspb.Message.getWrapperField(this, proto.billing.private.company.taxation.Address, 5));
};


/**
 * @param {?proto.billing.private.company.taxation.Address|undefined} value
 * @return {!proto.billing.private.company.taxation.AutoBillsbyTaxConfig} returns this
*/
proto.billing.private.company.taxation.AutoBillsbyTaxConfig.prototype.setNexusAddress = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.billing.private.company.taxation.AutoBillsbyTaxConfig} returns this
 */
proto.billing.private.company.taxation.AutoBillsbyTaxConfig.prototype.clearNexusAddress = function() {
  return this.setNexusAddress(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.billing.private.company.taxation.AutoBillsbyTaxConfig.prototype.hasNexusAddress = function() {
  return jspb.Message.getField(this, 5) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.billing.private.company.taxation.Address.prototype.toObject = function(opt_includeInstance) {
  return proto.billing.private.company.taxation.Address.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.billing.private.company.taxation.Address} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing.private.company.taxation.Address.toObject = function(includeInstance, msg) {
  var f, obj = {
    addressLine1: jspb.Message.getFieldWithDefault(msg, 1, ""),
    addressLine2: jspb.Message.getFieldWithDefault(msg, 2, ""),
    city: jspb.Message.getFieldWithDefault(msg, 3, ""),
    state: jspb.Message.getFieldWithDefault(msg, 4, ""),
    postCode: jspb.Message.getFieldWithDefault(msg, 5, ""),
    countryIso3Code: jspb.Message.getFieldWithDefault(msg, 6, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.billing.private.company.taxation.Address}
 */
proto.billing.private.company.taxation.Address.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.billing.private.company.taxation.Address;
  return proto.billing.private.company.taxation.Address.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.billing.private.company.taxation.Address} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.billing.private.company.taxation.Address}
 */
proto.billing.private.company.taxation.Address.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setAddressLine1(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setAddressLine2(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setCity(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setState(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setPostCode(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setCountryIso3Code(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.billing.private.company.taxation.Address.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.billing.private.company.taxation.Address.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.billing.private.company.taxation.Address} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing.private.company.taxation.Address.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAddressLine1();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getAddressLine2();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getCity();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getState();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getPostCode();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getCountryIso3Code();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
};


/**
 * optional string address_line_1 = 1;
 * @return {string}
 */
proto.billing.private.company.taxation.Address.prototype.getAddressLine1 = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.billing.private.company.taxation.Address} returns this
 */
proto.billing.private.company.taxation.Address.prototype.setAddressLine1 = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string address_line_2 = 2;
 * @return {string}
 */
proto.billing.private.company.taxation.Address.prototype.getAddressLine2 = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.billing.private.company.taxation.Address} returns this
 */
proto.billing.private.company.taxation.Address.prototype.setAddressLine2 = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string city = 3;
 * @return {string}
 */
proto.billing.private.company.taxation.Address.prototype.getCity = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.billing.private.company.taxation.Address} returns this
 */
proto.billing.private.company.taxation.Address.prototype.setCity = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string state = 4;
 * @return {string}
 */
proto.billing.private.company.taxation.Address.prototype.getState = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.billing.private.company.taxation.Address} returns this
 */
proto.billing.private.company.taxation.Address.prototype.setState = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string post_code = 5;
 * @return {string}
 */
proto.billing.private.company.taxation.Address.prototype.getPostCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.billing.private.company.taxation.Address} returns this
 */
proto.billing.private.company.taxation.Address.prototype.setPostCode = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string country_iso3_code = 6;
 * @return {string}
 */
proto.billing.private.company.taxation.Address.prototype.getCountryIso3Code = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.billing.private.company.taxation.Address} returns this
 */
proto.billing.private.company.taxation.Address.prototype.setCountryIso3Code = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.billing.private.company.taxation.ManualTaxConfig.repeatedFields_ = [12];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.billing.private.company.taxation.ManualTaxConfig.prototype.toObject = function(opt_includeInstance) {
  return proto.billing.private.company.taxation.ManualTaxConfig.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.billing.private.company.taxation.ManualTaxConfig} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing.private.company.taxation.ManualTaxConfig.toObject = function(includeInstance, msg) {
  var f, obj = {
    taxLabel: jspb.Message.getFieldWithDefault(msg, 1, ""),
    taxRegNumber: jspb.Message.getFieldWithDefault(msg, 2, ""),
    taxRegNumberRequirementType: jspb.Message.getFieldWithDefault(msg, 3, ""),
    locationValidation: jspb.Message.getBooleanFieldWithDefault(msg, 4, false),
    taxValidation: jspb.Message.getBooleanFieldWithDefault(msg, 5, false),
    customerTaxExemption: jspb.Message.getBooleanFieldWithDefault(msg, 6, false),
    isDigital: jspb.Message.getBooleanFieldWithDefault(msg, 7, false),
    withVatMoss: jspb.Message.getBooleanFieldWithDefault(msg, 8, false),
    viesValidation: jspb.Message.getBooleanFieldWithDefault(msg, 9, false),
    exemptionNoteReverseCharge: jspb.Message.getFieldWithDefault(msg, 10, ""),
    exemptionNoteExports: jspb.Message.getFieldWithDefault(msg, 11, ""),
    taxRatesList: jspb.Message.toObjectList(msg.getTaxRatesList(),
    proto.billing.private.company.taxation.TaxRate.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.billing.private.company.taxation.ManualTaxConfig}
 */
proto.billing.private.company.taxation.ManualTaxConfig.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.billing.private.company.taxation.ManualTaxConfig;
  return proto.billing.private.company.taxation.ManualTaxConfig.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.billing.private.company.taxation.ManualTaxConfig} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.billing.private.company.taxation.ManualTaxConfig}
 */
proto.billing.private.company.taxation.ManualTaxConfig.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setTaxLabel(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setTaxRegNumber(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setTaxRegNumberRequirementType(value);
      break;
    case 4:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setLocationValidation(value);
      break;
    case 5:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setTaxValidation(value);
      break;
    case 6:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setCustomerTaxExemption(value);
      break;
    case 7:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsDigital(value);
      break;
    case 8:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setWithVatMoss(value);
      break;
    case 9:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setViesValidation(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setExemptionNoteReverseCharge(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.setExemptionNoteExports(value);
      break;
    case 12:
      var value = new proto.billing.private.company.taxation.TaxRate;
      reader.readMessage(value,proto.billing.private.company.taxation.TaxRate.deserializeBinaryFromReader);
      msg.addTaxRates(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.billing.private.company.taxation.ManualTaxConfig.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.billing.private.company.taxation.ManualTaxConfig.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.billing.private.company.taxation.ManualTaxConfig} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing.private.company.taxation.ManualTaxConfig.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTaxLabel();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getTaxRegNumber();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getTaxRegNumberRequirementType();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getLocationValidation();
  if (f) {
    writer.writeBool(
      4,
      f
    );
  }
  f = message.getTaxValidation();
  if (f) {
    writer.writeBool(
      5,
      f
    );
  }
  f = message.getCustomerTaxExemption();
  if (f) {
    writer.writeBool(
      6,
      f
    );
  }
  f = message.getIsDigital();
  if (f) {
    writer.writeBool(
      7,
      f
    );
  }
  f = message.getWithVatMoss();
  if (f) {
    writer.writeBool(
      8,
      f
    );
  }
  f = message.getViesValidation();
  if (f) {
    writer.writeBool(
      9,
      f
    );
  }
  f = message.getExemptionNoteReverseCharge();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getExemptionNoteExports();
  if (f.length > 0) {
    writer.writeString(
      11,
      f
    );
  }
  f = message.getTaxRatesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      12,
      f,
      proto.billing.private.company.taxation.TaxRate.serializeBinaryToWriter
    );
  }
};


/**
 * optional string tax_label = 1;
 * @return {string}
 */
proto.billing.private.company.taxation.ManualTaxConfig.prototype.getTaxLabel = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.billing.private.company.taxation.ManualTaxConfig} returns this
 */
proto.billing.private.company.taxation.ManualTaxConfig.prototype.setTaxLabel = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string tax_reg_number = 2;
 * @return {string}
 */
proto.billing.private.company.taxation.ManualTaxConfig.prototype.getTaxRegNumber = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.billing.private.company.taxation.ManualTaxConfig} returns this
 */
proto.billing.private.company.taxation.ManualTaxConfig.prototype.setTaxRegNumber = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string tax_reg_number_requirement_type = 3;
 * @return {string}
 */
proto.billing.private.company.taxation.ManualTaxConfig.prototype.getTaxRegNumberRequirementType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.billing.private.company.taxation.ManualTaxConfig} returns this
 */
proto.billing.private.company.taxation.ManualTaxConfig.prototype.setTaxRegNumberRequirementType = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional bool location_validation = 4;
 * @return {boolean}
 */
proto.billing.private.company.taxation.ManualTaxConfig.prototype.getLocationValidation = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 4, false));
};


/**
 * @param {boolean} value
 * @return {!proto.billing.private.company.taxation.ManualTaxConfig} returns this
 */
proto.billing.private.company.taxation.ManualTaxConfig.prototype.setLocationValidation = function(value) {
  return jspb.Message.setProto3BooleanField(this, 4, value);
};


/**
 * optional bool tax_validation = 5;
 * @return {boolean}
 */
proto.billing.private.company.taxation.ManualTaxConfig.prototype.getTaxValidation = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 5, false));
};


/**
 * @param {boolean} value
 * @return {!proto.billing.private.company.taxation.ManualTaxConfig} returns this
 */
proto.billing.private.company.taxation.ManualTaxConfig.prototype.setTaxValidation = function(value) {
  return jspb.Message.setProto3BooleanField(this, 5, value);
};


/**
 * optional bool customer_tax_exemption = 6;
 * @return {boolean}
 */
proto.billing.private.company.taxation.ManualTaxConfig.prototype.getCustomerTaxExemption = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 6, false));
};


/**
 * @param {boolean} value
 * @return {!proto.billing.private.company.taxation.ManualTaxConfig} returns this
 */
proto.billing.private.company.taxation.ManualTaxConfig.prototype.setCustomerTaxExemption = function(value) {
  return jspb.Message.setProto3BooleanField(this, 6, value);
};


/**
 * optional bool is_digital = 7;
 * @return {boolean}
 */
proto.billing.private.company.taxation.ManualTaxConfig.prototype.getIsDigital = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 7, false));
};


/**
 * @param {boolean} value
 * @return {!proto.billing.private.company.taxation.ManualTaxConfig} returns this
 */
proto.billing.private.company.taxation.ManualTaxConfig.prototype.setIsDigital = function(value) {
  return jspb.Message.setProto3BooleanField(this, 7, value);
};


/**
 * optional bool with_vat_moss = 8;
 * @return {boolean}
 */
proto.billing.private.company.taxation.ManualTaxConfig.prototype.getWithVatMoss = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 8, false));
};


/**
 * @param {boolean} value
 * @return {!proto.billing.private.company.taxation.ManualTaxConfig} returns this
 */
proto.billing.private.company.taxation.ManualTaxConfig.prototype.setWithVatMoss = function(value) {
  return jspb.Message.setProto3BooleanField(this, 8, value);
};


/**
 * optional bool vies_validation = 9;
 * @return {boolean}
 */
proto.billing.private.company.taxation.ManualTaxConfig.prototype.getViesValidation = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 9, false));
};


/**
 * @param {boolean} value
 * @return {!proto.billing.private.company.taxation.ManualTaxConfig} returns this
 */
proto.billing.private.company.taxation.ManualTaxConfig.prototype.setViesValidation = function(value) {
  return jspb.Message.setProto3BooleanField(this, 9, value);
};


/**
 * optional string exemption_note_reverse_charge = 10;
 * @return {string}
 */
proto.billing.private.company.taxation.ManualTaxConfig.prototype.getExemptionNoteReverseCharge = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.billing.private.company.taxation.ManualTaxConfig} returns this
 */
proto.billing.private.company.taxation.ManualTaxConfig.prototype.setExemptionNoteReverseCharge = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * optional string exemption_note_exports = 11;
 * @return {string}
 */
proto.billing.private.company.taxation.ManualTaxConfig.prototype.getExemptionNoteExports = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};


/**
 * @param {string} value
 * @return {!proto.billing.private.company.taxation.ManualTaxConfig} returns this
 */
proto.billing.private.company.taxation.ManualTaxConfig.prototype.setExemptionNoteExports = function(value) {
  return jspb.Message.setProto3StringField(this, 11, value);
};


/**
 * repeated TaxRate tax_rates = 12;
 * @return {!Array<!proto.billing.private.company.taxation.TaxRate>}
 */
proto.billing.private.company.taxation.ManualTaxConfig.prototype.getTaxRatesList = function() {
  return /** @type{!Array<!proto.billing.private.company.taxation.TaxRate>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.billing.private.company.taxation.TaxRate, 12));
};


/**
 * @param {!Array<!proto.billing.private.company.taxation.TaxRate>} value
 * @return {!proto.billing.private.company.taxation.ManualTaxConfig} returns this
*/
proto.billing.private.company.taxation.ManualTaxConfig.prototype.setTaxRatesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 12, value);
};


/**
 * @param {!proto.billing.private.company.taxation.TaxRate=} opt_value
 * @param {number=} opt_index
 * @return {!proto.billing.private.company.taxation.TaxRate}
 */
proto.billing.private.company.taxation.ManualTaxConfig.prototype.addTaxRates = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 12, opt_value, proto.billing.private.company.taxation.TaxRate, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.billing.private.company.taxation.ManualTaxConfig} returns this
 */
proto.billing.private.company.taxation.ManualTaxConfig.prototype.clearTaxRatesList = function() {
  return this.setTaxRatesList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.billing.private.company.taxation.TaxRate.prototype.toObject = function(opt_includeInstance) {
  return proto.billing.private.company.taxation.TaxRate.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.billing.private.company.taxation.TaxRate} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing.private.company.taxation.TaxRate.toObject = function(includeInstance, msg) {
  var f, obj = {
    name: jspb.Message.getFieldWithDefault(msg, 1, ""),
    stateIso2: jspb.Message.getFieldWithDefault(msg, 2, ""),
    countryIso3: jspb.Message.getFieldWithDefault(msg, 3, ""),
    taxRegNumber: jspb.Message.getFieldWithDefault(msg, 4, ""),
    rate: jspb.Message.getFloatingPointFieldWithDefault(msg, 5, 0.0),
    isOrigin: jspb.Message.getBooleanFieldWithDefault(msg, 6, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.billing.private.company.taxation.TaxRate}
 */
proto.billing.private.company.taxation.TaxRate.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.billing.private.company.taxation.TaxRate;
  return proto.billing.private.company.taxation.TaxRate.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.billing.private.company.taxation.TaxRate} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.billing.private.company.taxation.TaxRate}
 */
proto.billing.private.company.taxation.TaxRate.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setStateIso2(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setCountryIso3(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setTaxRegNumber(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setRate(value);
      break;
    case 6:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsOrigin(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.billing.private.company.taxation.TaxRate.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.billing.private.company.taxation.TaxRate.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.billing.private.company.taxation.TaxRate} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing.private.company.taxation.TaxRate.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getStateIso2();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getCountryIso3();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getTaxRegNumber();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getRate();
  if (f !== 0.0) {
    writer.writeFloat(
      5,
      f
    );
  }
  f = message.getIsOrigin();
  if (f) {
    writer.writeBool(
      6,
      f
    );
  }
};


/**
 * optional string name = 1;
 * @return {string}
 */
proto.billing.private.company.taxation.TaxRate.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.billing.private.company.taxation.TaxRate} returns this
 */
proto.billing.private.company.taxation.TaxRate.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string state_iso_2 = 2;
 * @return {string}
 */
proto.billing.private.company.taxation.TaxRate.prototype.getStateIso2 = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.billing.private.company.taxation.TaxRate} returns this
 */
proto.billing.private.company.taxation.TaxRate.prototype.setStateIso2 = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string country_iso_3 = 3;
 * @return {string}
 */
proto.billing.private.company.taxation.TaxRate.prototype.getCountryIso3 = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.billing.private.company.taxation.TaxRate} returns this
 */
proto.billing.private.company.taxation.TaxRate.prototype.setCountryIso3 = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string tax_reg_number = 4;
 * @return {string}
 */
proto.billing.private.company.taxation.TaxRate.prototype.getTaxRegNumber = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.billing.private.company.taxation.TaxRate} returns this
 */
proto.billing.private.company.taxation.TaxRate.prototype.setTaxRegNumber = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional float rate = 5;
 * @return {number}
 */
proto.billing.private.company.taxation.TaxRate.prototype.getRate = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 5, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.billing.private.company.taxation.TaxRate} returns this
 */
proto.billing.private.company.taxation.TaxRate.prototype.setRate = function(value) {
  return jspb.Message.setProto3FloatField(this, 5, value);
};


/**
 * optional bool is_origin = 6;
 * @return {boolean}
 */
proto.billing.private.company.taxation.TaxRate.prototype.getIsOrigin = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 6, false));
};


/**
 * @param {boolean} value
 * @return {!proto.billing.private.company.taxation.TaxRate} returns this
 */
proto.billing.private.company.taxation.TaxRate.prototype.setIsOrigin = function(value) {
  return jspb.Message.setProto3BooleanField(this, 6, value);
};


goog.object.extend(exports, proto.billing.private.company.taxation);
