import counterpart from "counterpart";
import React, { useEffect, useMemo, useState } from "react";
import Helmet from "react-helmet";
import { useDispatch, useSelector } from "react-redux";
import { NavLink, useRouteMatch } from "react-router-dom";
import { AppState } from "../../..";
import PageWrapper from "../../../components/ui/layout/PageWrapper";
import NavigationBar from "../../../components/ui/navigation-bar/NavigationBar";
import TabbedContainer from "../../../components/ui/tabbed-container/TabbedContainer";
import Text from "../../../components/ui/text/Text";
import history from "../../../utils/history";
import "./QuickBooks.scss";
import QuickBooksOverview from "./quick-books-overview/QuickBooksOverview";
import Button from "../../../components/ui/button/Button";
import StepCollapsablePanel from "../../../components/ui/step-collapsable-panel/StepCollapsablePanel";
import { PrivateRoute } from "../../../containers/AuthRoutes";
import QuickBooksFaq from "./quick-books-faq/QuickBooksFaq";
import { ConfigConstants } from "../../../utils/config";
import logo from "../../../images/integrations/quickbooks/logo.png";
import quickBooksButton from "../../../images/integrations/quickbooks/button.png";
import Modal from "../../modal/Modal";
import QuickBooksOnlineIntegration from "./quick-books-online-integration/QuickBooksOnlineIntegration";
import { AccountingIntegrationType } from "../../../models/AccountingIntegrations";
import QuickBooksSettings from "./quick-books-settings/QuickBooksSettings";
import { createQuickbooksIntegration, deleteAccountingIntegration, fetchAccountIntegrations, fetchCompanyQuickBooksAccounts, fetchQuickBooksAccounts, setAccountingIntegrationsField } from "../../../actions/accountingIntegrationsActions";
import NavigationAlert from "../../ui/navigation-alert/NavigationAlert";
import { openIntercomIframe } from "../../../utils/commonUtils";
import NoticePanel from "../../ui/notice-panel/NoticePanel";
import { getScopeToken } from "../../../utils/authUtils";
import QuickBooksUninstall from "./quick-books-uninstall/QuickBooksUninstall";
import { GetCompanyQuickBooksAccountsResponse, IntegrationType } from "../../../utils/grpc/generated/Billsby.Protos/accountingIntegrations/private/accounts/accounts_pb";
import ProgressIndicator from "../../ui/progress-indicator/ProgressIndicator";


export const QuickBooks: React.FC = () => {
  const authData = useSelector((state: AppState) => state.auth);
  const accountingIntegrations = useSelector((state: AppState) => state.accountingIntegrationsReducer);

  const dispatch = useDispatch<Function>();
  const match = useRouteMatch();

  const [showUninstallModal, setShowUninstallModal] = useState(false);
  const [showNotification, setShowNotification] = useState(false);
  const [showErrorNotification, setShowErrorNotification] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const { currentCompanyId, currentCompanyDomain } = authData;
  const { integrations, isQuickbooksUnderSetup, invoiceIsDisconnected, creditNoteIsDisconnected, refundIsDisconnected, 
    isCreatingIntegrationSuccess, isCreatingIntegrationFailure, isDeleteIntegrationSuccess } = accountingIntegrations;

  useEffect(() => {
    return () => {
      dispatch(setAccountingIntegrationsField("isCreatingIntegrationFailure", false));
      dispatch(setAccountingIntegrationsField("isCreatingIntegrationSuccess", false));
      dispatch(setAccountingIntegrationsField("isDeleteIntegrationSuccess", false));
      dispatch(setAccountingIntegrationsField("isDeleteIntegrationFailure", false));
    }
  }, [])

  useEffect(() => {
    if(isCreatingIntegrationFailure) {
      setShowErrorNotification(true)
    }
  }, [isCreatingIntegrationFailure])

  useEffect(() => {
    setIsLoading(true);
    //ugly trick to update the integrations because the creation and deletion are asynchronous and when backend returns it might still be pending
    //https://dev.azure.com/airfidev/Billsby/_workitems/edit/25832?src=WorkItemMention&src-action=artifact_link
    setTimeout(async() => {
      await dispatch(fetchAccountIntegrations(Number(authData.currentCompanyId)));
      setIsLoading(false);
    }, 1500);
  }, [isCreatingIntegrationSuccess, isDeleteIntegrationSuccess])

  useEffect(() => {
    const url_string = window.location.href;
    const url = new URL(url_string);
    const authCode = url.searchParams.get("code");
    const realmId = url.searchParams.get("realmId");
    const error = url.searchParams.get("error");
    if(!!error) {
      dispatch(setAccountingIntegrationsField("isCreatingIntegrationFailure", true));
    }
    if(authCode && realmId && !isCreatingIntegrationFailure) {
      dispatch(createQuickbooksIntegration(currentCompanyId as number, authCode, realmId));
    }
  }, [])

  const isInstalled = useMemo(() => {
    // apparently creating an integration is an async operation and when backend return the isSuccess flag the getCompanyIntegrations endpoint is not immediately updated, for this reason we use the following OR ||
    return integrations.getIntegrationsList().some(i => i.getName() === AccountingIntegrationType.QUICKBOOKS && i.getIsInstalled()) || isCreatingIntegrationSuccess;
  }, [integrations]);

  useEffect(() => {
    const fetchData = async () => {

      if (isInstalled) {
        try {
          await dispatch(fetchQuickBooksAccounts(Number(authData.currentCompanyId)));
          const response = await dispatch(fetchCompanyQuickBooksAccounts(Number(authData.currentCompanyId)));
          if (!!response.error) {
            dispatch(setAccountingIntegrationsField("isQuickbooksUnderSetup", true));
          } 
          else {
            const data = response.response as GetCompanyQuickBooksAccountsResponse;
            if (!data.getInvoicesAccountId() && !data.getCreditNotesAccountId() && !data.getRefundsAccountId()) {
              dispatch(setAccountingIntegrationsField("isQuickbooksUnderSetup", true));
            }
          }
        } catch (err) {
          setShowErrorNotification(true)
        }
      }
    }

    fetchData();
  }, [isInstalled]);



  const hasError = useMemo(() => {
    return invoiceIsDisconnected || creditNoteIsDisconnected || refundIsDisconnected;
  }, [invoiceIsDisconnected, creditNoteIsDisconnected, refundIsDisconnected]);

  const pathIsIncluded = (path: string) => {
    return history.location.pathname.toLocaleLowerCase().includes(path);
  }

  const quickBooksAuth = async () => {
    if(!currentCompanyId) {
      setShowErrorNotification(true);
      return;
    }

    await getScopeToken(currentCompanyDomain, currentCompanyId);
    const redirectURI = encodeURI(`https://app${ConfigConstants.billsbyDomain}/auth/quickbooks&response_type=code&state=${currentCompanyId}|${window.location.href}`);
    window.location.href = `${ConfigConstants.quickBooksUrl}?client_id=${ConfigConstants.quickBooksClientId}&scope=com.intuit.quickbooks.accounting&redirect_uri=${redirectURI}`;
  }

  const getWarningNewFeature = () => {
    return (
      <NavigationAlert
        className="free-agent-integrations__integration-warning"
        title="INTEGRATION_QUICKBOOKS_WARNING_TITLE"
        subTitle="INTEGRATION_QUICKBOOKS_WARNING_CONTENT"
        buttonLabel="INTEGRATION_QUICKBOOKS_SUCCESS_BUTTON"
        icon="fas fa-headset"
        onClick={() => openIntercomIframe()}
      />
    )
  }


  if(isLoading) {
    return <ProgressIndicator color="blue" coverage="full-content" />
  }

  return (
    <div>
      <Helmet title={counterpart("INTEGRATIONS_TITLE")} />

      <div className="quickbooks-integrations">
        <NavigationBar pageTitle="" previousPageTitle="INTEGRATIONS_TITLE" previousPageCb={() => history.push("/integrations")} />
        {getWarningNewFeature()}
        {showNotification &&
          <NavigationAlert
            className="quickbooks-integrations__integration-success"
            title="INTEGRATION_QUICKBOOKS_SUCCESS_TITLE"
            subTitle="INTEGRATION_QUICKBOOKS_SUCCESS_TEXT"
            buttonLabel="INTEGRATION_QUICKBOOKS_SUCCESS_BUTTON_TEXT"
            icon="fas fa-headset"
            onClick={() => openIntercomIframe()}
          />}
        {showErrorNotification &&
          <NoticePanel
            className="quickbooks-integrations__error"
            type="error"
            icon="fas fa-exclamation-circle"
            content={"INTEGRATION_QUICKBOOKS_ERROR"}
          />}
        <PageWrapper>
          <StepCollapsablePanel title={
            <div className="quickbooks-integrations__title">
              <div>
                <img src={logo} alt="no img" />
                <div>
                  <Text content="INTEGRATIONS_QUICKBOOKS_PANEL1_TITLE" noMargin />
                </div>
              </div>
              {isInstalled ? <div>
                <div>
                  <Button
                    id="visit-website"
                    title="INTEGRATIONS_VIEW_WEBSITE"
                    onClick={(evt: any) => {
                      evt.stopPropagation();
                      window.open("https://quickbooks.intuit.com/choose-country/", "_self");
                    }}
                    buttonType="add"
                  />
                </div>
                <Button
                  id="uninstall-btn"
                  title="INTEGRATION_UNINSTALL"
                  onClick={() => setShowUninstallModal(true)}
                  buttonType="error"
                />
              </div> : <div>
                <div>
                  <img src={quickBooksButton}
                    id="integrate-btn"
                    onClick={() => quickBooksAuth()}
                    className="quickbooks-integrations__connect-btn" alt="no img" />
                </div>
                <Button
                  id="visit-website"
                  title="INTEGRATIONS_VIEW_WEBSITE"
                  onClick={(evt: any) => {
                    evt.stopPropagation();
                    window.open("https://quickbooks.intuit.com/choose-country", "_self");
                  }}
                  buttonType="add"
                />
              </div>}
            </div>
          } className="quickbooks-integrations__title-container"
            shouldTranslateTitle={false}
            hasHelpSection={false}
            isCollapsed={false}
          >
            <Text content={isInstalled ? "INTEGRATION_QUICKBOOKS_INSTALLED_TEXT" : "INTEGRATIONS_QUICKBOOKS_PANEL1_TEXT"} noMargin />
          </StepCollapsablePanel>

          <TabbedContainer type="submenu">
            <NavLink isActive={(match, location) => (!match || !location ? false : match?.isExact)} activeClassName="active" to={`${match?.url}`}>
              <>
                {isInstalled && hasError && <i className="fas fa-map-marker-exclamation quick-books-online-integration__error quick-books-online-integration__display-inline"></i>}
                {counterpart(isInstalled ? "INTEGRATION_SETTINGS" : "INTEGRATIONS_OVERVIEW")}
              </>
            </NavLink>
            <NavLink isActive={(match, location) => (!match || !location ? false : match?.isExact) || pathIsIncluded("/faq")} exact activeClassName="active" to={`${match?.url}/faq`}>
              {counterpart(isInstalled ? "INTEGRATION_HELP" : "INTEGRATIONS_FAQS")}
            </NavLink>
          </TabbedContainer>

          <PrivateRoute render={() => (
            <>
              <PrivateRoute exact path={match?.path} component={isInstalled ? QuickBooksSettings : QuickBooksOverview} />
              <PrivateRoute exact path={`${match?.path}/faq`} component={QuickBooksFaq} />
            </>
          )} />

        </PageWrapper>
      </div>
      <Modal 
        isOpen={isQuickbooksUnderSetup}
        shouldCloseOnOverlayClick={false}
        onRequestClose={async () => {
          if (isQuickbooksUnderSetup) {
            const hasQuickbooksIntegration = accountingIntegrations.integrations.getIntegrationsList().some(i => i.getName() === AccountingIntegrationType.QUICKBOOKS);
            if (hasQuickbooksIntegration) {
              try {
                await dispatch(deleteAccountingIntegration(Number(authData.currentCompanyId), IntegrationType.QUICKBOOKS))
                await dispatch(fetchAccountIntegrations(Number(authData.currentCompanyId)));
                setShowErrorNotification(true);
              } 
              catch (err) {
                console.log(err);
              }
            }
          }
          dispatch(setAccountingIntegrationsField("isQuickbooksUnderSetup", false));
          dispatch(setAccountingIntegrationsField("isCreatingIntegrationSuccess", false));
          dispatch(setAccountingIntegrationsField("isCreatingIntegrationFailure", true));
        }} 
        title="INTEGRATION_QUICKBOOKS_ONLINE_INTEGRATION_MODAL_TITLE">
        <QuickBooksOnlineIntegration setupDone={() => setShowNotification(true)} />
      </Modal>
      <Modal isOpen={showUninstallModal} onRequestClose={() => setShowUninstallModal(false)} title="INTEGRATION_UNINSTALL_QUICKBOOKS">
        <QuickBooksUninstall onUninstall={() => setShowUninstallModal(false)} />
      </Modal>
    </div>
  )
}

export default QuickBooks;
