import React from "react";
import Panel from "../ui/panel/Panel";
import Text from "../ui/text/Text";
import Button from "../ui/button/Button";
import moment from "moment";
import "./CancelSubscription.scss";

interface ICancelSubscriptionProps {
  contractMinimumTermEnd: Date,
  onClick: () => void
}

const CancelSubscription: React.FC<ICancelSubscriptionProps> = ({contractMinimumTermEnd, onClick}) => {

    return (
      <div className="cancel-subscription">
        <Panel
          className="cancel-subscription__titlepanel"
          title="CANCELING_SUBSCRIPTION_PANEL1_TITLE"
        >
          <Text
            content="CANCELING_SUBSCRIPTION_OVERRIDE_POLICY_CONTENT"
            translateWith={{contractMinimumTermEnd: moment(contractMinimumTermEnd).format("DD MMM YYYY")}}
            noMargin
          />
        </Panel>

        <Button
          id="override-policy"
          buttonType={"general"}
          title="CANCELING_SUBSCRIPTION_OVERRIDE_POLICY"
          isFullWidth
          onClick={onClick}
        />
      </div>
    );
}

export default CancelSubscription;
