import React, { useState } from "react";
import "./AddOnsWelcome.scss";
import history from "../../../utils/history";

import Panel from "../../ui/panel/Panel";
import Text from "../../ui/text/Text";
import Button from "../../ui/button/Button";
import OtherCompanies from "../../products/productPlanCycle/OtherCompanies";
import CollapsablePanelWithArrow from "../../ui/collapsable-panel-with-arrow/CollapsablePanelWithArrow";
import { AddonsAllowancesScreen } from "../../../models/AddonsAllowances";
import { Link } from "react-router-dom";
import counterpart from "counterpart";

interface IAddOnsWelcome {
  withAddOns?: boolean;
}
export const AddOnsWelcome: React.FC<IAddOnsWelcome> = ({ withAddOns = false }) => {
  const [isCollapsed, setIsCollapsed] = useState(true);

  const otherCompaniesContent = [
    {
      name: "ADD_ON_SIX_FLAGS",
      type: "ADD_ON_SIX_FLAGS_TYPE",
      otherDetails: [
        { title: "ADD_ON_SIX_FLAGS_CONTENT_TITLE_1", descriptions: ["ADD_ON_SIX_FLAGS_CONTENT_DESCRIPTION_1"] },
        { title: "ADD_ON_SIX_FLAGS_CONTENT_TITLE_2", descriptions: ["ADD_ON_SIX_FLAGS_CONTENT_DESCRIPTION_2"] },
        { title: "ADD_ON_SIX_FLAGS_CONTENT_TITLE_3", descriptions: ["ADD_ON_SIX_FLAGS_CONTENT_DESCRIPTION_3"] },
      ],
    },
    {
      name: "ADD_ON_THE_GYM",
      type: "ADD_ON_THE_GYM_TYPE",
      otherDetails: [
        { title: "ADD_ON_THE_GYM_CONTENT_TITLE_1", descriptions: ["ADD_ON_THE_GYM_CONTENT_DESCRIPTION_1"] },
        { title: "ADD_ON_THE_GYM_CONTENT_TITLE_2", descriptions: ["ADD_ON_THE_GYM_CONTENT_DESCRIPTION_2"] },
      ],
    },
    {
      name: "ADD_ON_WE_WORK",
      type: "ADD_ON_WE_WORK_TYPE",
      otherDetails: [
        { title: "ADD_ON_WE_WORK_CONTENT_TITLE_1", descriptions: ["ADD_ON_WE_WORK_CONTENT_DESCRIPTION_1"] },
        { title: "ADD_ON_WE_WORK_CONTENT_TITLE_2", descriptions: ["ADD_ON_WE_WORK_CONTENT_DESCRIPTION_2"] },
      ],
    },
    {
      name: "ADD_ON_INTERCOM",
      type: "ADD_ON_INTERCOM_TYPE",
      otherDetails: [
        { title: "ADD_ON_INTERCOM_CONTENT_TITLE_1", descriptions: ["ADD_ON_INTERCOM_CONTENT_DESCRIPTION_1"] },
        { title: "ADD_ON_INTERCOM_CONTENT_TITLE_2", descriptions: ["ADD_ON_INTERCOM_CONTENT_DESCRIPTION_2"] },
        { title: "ADD_ON_INTERCOM_CONTENT_TITLE_3", descriptions: ["ADD_ON_INTERCOM_CONTENT_DESCRIPTION_3"] },
      ],
    },
    {
      name: "ADD_ON_AMAZON",
      type: "ADD_ON_AMAZON_TYPE",
      otherDetails: [
        { title: "ADD_ON_AMAZON_CONTENT_TITLE_1", descriptions: ["ADD_ON_AMAZON_CONTENT_DESCRIPTION_1"] },
        { title: "ADD_ON_AMAZON_CONTENT_TITLE_2", descriptions: ["ADD_ON_AMAZON_CONTENT_DESCRIPTION_2"] },
        { title: "ADD_ON_AMAZON_CONTENT_TITLE_3", descriptions: ["ADD_ON_AMAZON_CONTENT_DESCRIPTION_3"] },
      ],
    },
    {
      name: "ADD_ON_QUIP",
      type: "ADD_ON_QUIP_TYPE",
      otherDetails: [
        { title: "ADD_ON_QUIP_CONTENT_TITLE_1", descriptions: ["ADD_ON_QUIP_CONTENT_DESCRIPTION_1"] },
        { title: "ADD_ON_QUIP_CONTENT_TITLE_2", descriptions: ["ADD_ON_QUIP_CONTENT_DESCRIPTION_2"] },
      ],
    },
  ];

  return (
    <div className="addons-welcome">
      <Panel className="addons-welcome__greeting" title="ADDONS_WELCOME_TITLE2">
        <div className="addons-welcome__greeting__text">
          {!withAddOns && <Text content="ADDONS_WELCOME_TEXT_1" />}
          <Text content="ADDONS_WELCOME_TEXT_2" />
          <Text
            content="ADDONS_WELCOME_TEXT_3"
            className="addons-welcome__greeting__text-inline"
            translateWith={{
              allowances: (
                <Link to={`/addons-allowances/${AddonsAllowancesScreen.ALLOWANCES}`}>{counterpart("ADDONS_WELCOME_TEXT_4")}</Link>
              )
            }}
          />

          {!withAddOns && (
            <Button
              className="addons-welcome__greeting-btn"
              id="add-ons-welcome"
              title="ADDONS_WELCOME_BUTTON"
              buttonType="general"
              icon="fal fa-plus-circle"
              onClick={() => history.push(`/addons-allowances/${AddonsAllowancesScreen.ADDONS}/create`)}
            />
          )}
        </div>
      </Panel>
      {withAddOns ? (
        <CollapsablePanelWithArrow isCollapsed={isCollapsed} onClick={() => setIsCollapsed(!isCollapsed)} className="ppc-list__other-companies" title="ADD_ON_OTHER_COMPANIES">
          <Text content="ADD_ON_OTHER_COMPANIES_TEXT" />
          {<OtherCompanies companies={otherCompaniesContent} />}
        </CollapsablePanelWithArrow>
      ) : (
          <Panel title="ADD_ON_OTHER_COMPANIES">
            <Text content="ADD_ON_OTHER_COMPANIES_TEXT" />
            {<OtherCompanies companies={otherCompaniesContent} />}
          </Panel>
        )}
    </div>
  );
};

export default AddOnsWelcome;
