import * as React from "react";
import "./BasicPreview.scss";
import Text from "../../ui/text/Text";
import CustomFieldPreview from "../../ui/custom-field-preview/CustomFieldPreview";
import CheckoutInput from "./ui-checkout/CheckoutInput";


interface IOneLinePreviewProps {
  label: string,
  supportText: string
}

const OneLinePreview: React.FC<IOneLinePreviewProps> = ({ label = "", supportText = "" }) => {
  return (
    <CustomFieldPreview className="preview">
      <Text content={label} shouldTranslate={false} />
      <CheckoutInput />
      <Text content={supportText} shouldTranslate={false} noMargin />
    </CustomFieldPreview>
  );
};

export default OneLinePreview;