import React, { FormEvent } from "react";
import Panel from "../../../components/ui/panel/Panel";
import counterpart from "counterpart";
import Text from "../../../components/ui/text/Text";
import CircleIcon from "../../../components/ui/circle-icon/CirlcleIcon";
import FormGroup from "../../../components/ui/form-group/FormGroup";
import Button from "../../../components/ui/button/Button";
import history from "../../../utils/history";
import TitleSection from "../../../components/ui/title-section/TitleSection";

interface IRegistrationSameEmailErrorProps {
  firstName: string,
  onContinue: () => void
}

const onSubmit = (evt: FormEvent, onContinue: () => void) => {
  evt.preventDefault();
  onContinue();
  history.push("/login");
}

const RegistrationSameEmailError = ({ firstName = "", onContinue }: IRegistrationSameEmailErrorProps) => {
  return (
    <form className="registration" onSubmit={(evt: FormEvent) => onSubmit(evt, onContinue)}>

      <TitleSection
        title={counterpart("REGISTRATION_STEP1B_ERROR_TITLE", { firstName })}
        shouldTranslateTitle={false}
        subTitle="REGISTRATION_STEP1B_ERROR_BODY_1"
      />

      <Panel>
        <Text content="REGISTRATION_STEP1B_ERROR_BODY_2" />

        <FormGroup>
          <CircleIcon nr={1} />
          <Text content="REGISTRATION_STEP1B_POINT_1" noMargin component="span" />
        </FormGroup>

        <FormGroup>
          <CircleIcon nr={2} />
          <Text content="REGISTRATION_STEP1B_POINT_2" noMargin component="span" />
        </FormGroup>

        <div>
          <CircleIcon nr={3} />
          <Text content="REGISTRATION_STEP1B_POINT_3" noMargin component="span" />
        </div>
      </Panel>

      <Button
        id="same_email_btn"
        title="REGISTRATION_STEP1B_LOGIN_BTN"
        type="submit"
        buttonType="orangeBillsbyMarketing"
        isFullWidth
      />
    </form>
  )
}



export default RegistrationSameEmailError;