import React, { useState } from "react"
import { setPaymentGatewaysField, fetchPaymentGateways, cancelLastPaymentGateway } from "../../../../../actions/paymentGatewaysActions";
import { ConfigConstants } from "../../../../../utils/config";
import { IPaymentGatewayElement } from "../../../../../models/Configuration";
import { UpdateIxopayGatewayRequest } from "../../../../../utils/grpc/generated/Billsby.Protos/billing/private/paymentgateway/payment_gateway_pb";
import { PaymentGatewayServiceClient } from "../../../../../utils/grpc/generated/Billsby.Protos/billing/private/paymentgateway/Payment_gatewayServiceClientPb";
import { grpcUnaryCall } from "../../../../../utils/grpc/grpcUtils";
import PaymentGatewayContainer from "../payment-gateways-shared-components/PaymentGatewayContainer";
import IxopayPaymentGatewayForm from "./IxopayPaymentGatewayForm";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../../../../..";
import { IAuthReducerState } from "../../../../../reducers/authReducer";

interface Props {
  paymentGateway: IPaymentGatewayElement,
  isOneGatewayPending: boolean,
  currentGatewayState: any,
  idx: number,
  isCreatingGateway: boolean,
  onError: () => void
}

const AdyenPaymentGatewayUpdate: React.FC<Props> = ({ onError, paymentGateway, isOneGatewayPending, currentGatewayState, idx, isCreatingGateway }) => {

  const { currentCompanyDomain } = useSelector<AppState, IAuthReducerState>(state => state.auth)
  const dispatch = useDispatch<Function>()
  const [helpTitle, setHelpTitle] = useState("PAYMENT_GATEWAYS_ADDED_IXOPAY_HELP");
  const [helpContent, setHelpContent] = useState(["PAYMENT_GATEWAYS_ADDED_IXOPAY_HELP_CONTENT_1", "PAYMENT_GATEWAYS_ADDED_IXOPAY_HELP_CONTENT_2"]);

  const cancelChanges = () => {
    paymentGateway.mustActivate ? dispatch(cancelLastPaymentGateway())
      : dispatch(setPaymentGatewaysField(`paymentGateway${idx}`, { ...currentGatewayState, displayName: paymentGateway.title, isCollapsed: true }))
  }

  const updatePaymentGateway = async (displayName: string, username: string, password: string, apiKey: string, apiSecret: string) => {
    const updateIxopayGatewayRequest = new UpdateIxopayGatewayRequest();
    updateIxopayGatewayRequest.setCompanyDomain(currentCompanyDomain);
    updateIxopayGatewayRequest.setDisplayName(displayName);
    updateIxopayGatewayRequest.setUsername(username);
    updateIxopayGatewayRequest.setPassword(password);
    updateIxopayGatewayRequest.setApiKey(apiKey);
    updateIxopayGatewayRequest.setSecret(apiSecret);
    updateIxopayGatewayRequest.setPaymentGatewayId(paymentGateway.paymentGatewayId as number)
    const paymentGatewayServiceClient = new PaymentGatewayServiceClient(ConfigConstants.grpcBaseUrl);
    try {
      await grpcUnaryCall(updateIxopayGatewayRequest, paymentGatewayServiceClient, paymentGatewayServiceClient.updateIxopayGateway);
      await dispatch(fetchPaymentGateways(currentCompanyDomain));
      dispatch(setPaymentGatewaysField(`paymentGateway${idx}`, { ...currentGatewayState, isCollapsed: true }));
    } catch {
      onError();
    }
  }

  return (
    <PaymentGatewayContainer
      helpTitle={helpTitle}
      helpContent={helpContent}
      description="PAYMENT_GATEWAYS_IXOPAY_DESCRIPTION"
      isOneGatewayPending={isOneGatewayPending}
      currentGatewayState={currentGatewayState}
      onClick={() => dispatch(setPaymentGatewaysField(`paymentGateway${idx}`, { ...currentGatewayState, isCollapsed: paymentGateway.mustActivate ? false : !currentGatewayState.isCollapsed }))}
      websiteLink="https://www.ixopay.com/"
      type="edit"
      paymentGateway={paymentGateway}
    >
      <IxopayPaymentGatewayForm
        onSave={(displayName: string, username: string, password: string, apiKey: string, apiSecret: string) => updatePaymentGateway(displayName, username, password, apiKey, apiSecret)}
        onCancel={() => cancelChanges()}
        setHelpTitle={(text: string) => setHelpTitle(text)}
        setHelpContent={(text: Array<string>) => setHelpContent(text)}
        initDisplayName={paymentGateway.title}
        isSaveEnabled={!isCreatingGateway}
        isEdit={true}
      />
    </PaymentGatewayContainer>
  )
}

export default AdyenPaymentGatewayUpdate
