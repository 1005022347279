import { BillsbyAction } from "../models/BillsbyAction";
import { SET_PRODUCT_NAME, SET_PRODUCT_DISPLAY_NAME, SET_PROUDUCT_DESCRIPTION, SET_PRODUCT_CURRENCY, SET_PRODUCT_TYPE, SET_PRODUCT_HELP_TITLE, SET_PRODUCT_HELP_CONTENT, SET_PRODUCT_FORM_COLLAPSED, SET_PRODUCT_FORM_HAS_ERROR, SET_PRODUCT_FORM_COMPLETED, SET_PRODUCT_VISIBILITY, SET_PRODUCT_FOR_EDITING, RESET_PRODUCT_FOR_EDITING, CREATE_PRODUCT_REQUEST, CREATE_PRODUCT_SUCCESS, CREATE_PRODUCT_FAILURE, EDIT_PRODUCT_REQUEST, EDIT_PRODUCT_SUCCESS, EDIT_PRODUCT_FAILURE, SET_PRODUCT_CYCLES_UNMOUNT, SET_IS_ADDITIONAL_EMAIL_REQUIRED, SET_IS_PHONE_NUMBER_REQUIRED, SET_IS_MARKETING_CONSENT_REQUIRED, SET_PRODUCT_ADDRESS_COLLAPSED, SET_IS_CUSTOMFIELDS_OPENED, SET_IS_WORLD_WIDE_ADDRESS_ALLOWED, SET_IS_BILLING_ADDRESS_VALIDATION_REQUIRED, SET_IS_SHIPPING_ADDRESS_VALIDATION_REQUIRED, SET_IS_SHIPPING_ADDRESS_REQUIRED, APPLY_ADDRESS_OPTIONS, DISCARD_ADDRESS_OPTIONS, SET_PRODUCT_CUSTOM_FIELDS, REMOVE_CUSTOM_FIELD, SET_IS_REORDERING_CUSTOM_FIELDS, SET_TEMP_PRODUCT_CUSTOM_FIELDS, FETCH_PRODUCT_SUCCESS, FETCH_PRODUCT_FAILURE, SET_SELECTED_COUNTRY_ADDRESS, SET_PERMITTED_COUNTRY_ADDRESS, ADD_SELECTED_COUNTRY_ADDRESS, REMOVE_SELECTED_COUNTRY_ADDRESS, SET_PRODUCT_ALERT_EMAIL, SET_PRODUCT_SETTINGS_HELP_TITLE, SET_PRODUCT_SETTINGS_HELP_CONTENT, SET_IS_PRODUCT_IMAGE_ENABLED, PRODUCT_IMAGE_UPLOAD_SUCCESS, SET_PRODUCT_ERROR, PRODUCT_IMAGE_UPLOAD_REQUEST, PRODUCT_IMAGE_UPLOAD_FAILURE, SET_SELECTED_COUNTRY_ALLOW_BILLING_SHIPPING_ADDRESS, SET_PRODUCT_ALERT_EMAIL_IS_VALID } from "../actions/productPlanCycleActions";
import { IProductFull, ICountryAddress } from "../models/Product";
import { AppState } from "..";
import { ICustomField, ISelectedCustomField } from "../models/CustomFields";
import { ICountry } from "../models/Country";
import { FETCH_COUNTRIES_PRODUCT_SUCCESS } from "../actions/productPlanCycleActions";
import { reorderCountries, getActionErrorMessage, getErrorMessage } from "../utils/commonUtils";

export interface ICustomFieldAddressOptions {
  isShippingAddressRequired: boolean,
  isShippingAddressValidationRequired: boolean,
  isBillingAddressValidationRequired: boolean,
  isWorldWideAddressAllowed: boolean,
  countries: Array<{ label: string, value: ICountry }>,
  permittedCountries: Array<{ label: string, value: ICountryAddress }>
  selectedCountry?: { label: string, value: ICountry },
  permittedCountry?: { label: string, value: ICountryAddress }
}

export interface IProductReducerState {
  productId: number | null,
  helpTextTitle: string,
  helpTextContent: Array<string>,
  productHelpTextTitle: string,
  productHelpTextContent: Array<string>,
  productName: string,
  displayName: string,
  description: string,
  isProductImageEnabled: boolean,
  isProductImageUploading: boolean,
  productImageUrl: string,
  productImageFileName: string,
  currency: { label: string, value: string } | null,
  visibility: string | null,
  alertEmail: string,
  typeOfProduct: number,
  stageCollapsed: Array<boolean>,
  stageErrors: Array<boolean>,
  stageStatuses: Array<boolean>,
  isAddressCollapsed: boolean,
  isCustomFieldsOpened: boolean,
  addressOptions: ICustomFieldAddressOptions,
  addressOptionsTemp: ICustomFieldAddressOptions,
  isAdditionalEmailRequired: boolean,
  isPhoneNumberRequired: boolean,
  isMarketingConsentRequired: boolean,
  isCreatingProductRequest: boolean,
  isCreatingProductSuccess: boolean,
  isCreatingProductFailure: boolean,
  isEditingProductRequest: boolean,
  isEditingProductSuccess: boolean,
  isEditingProductFailure: boolean,
  isFetchingProductFailure: boolean,
  errorMessage: string,
  customFields: Array<ICustomField>,
  customFieldsTemp: Array<ICustomField>,
  isReorderingCustomFields: boolean,
  productError: string;
  isAlertEmailValid: boolean
}

export const initOrResetProduct = (product: IProductReducerState | null) => {
  const baseProduct = {
    productId: null,
    helpTextTitle: "HELP_EMPTY_TITLE",
    helpTextContent: ["HELP_EMPTY_1", "HELP_EMPTY_2"],
    productHelpTextTitle: "HELP_EMPTY_TITLE",
    productHelpTextContent: ["HELP_EMPTY_1", "HELP_EMPTY_2"],
    productName: "",
    displayName: "",
    productImageFileName: "",
    description: "",
    isProductImageEnabled: false,
    isProductImageUploading: false,
    productImageUrl: "",
    visibility: null,
    alertEmail: "",
    typeOfProduct: 1,
    stageCollapsed: [false, true, true],
    stageErrors: [false, false, false],
    stageStatuses: [false, false, false],
    isAddressCollapsed: true,
    isCustomFieldsOpened: false,
    productError: "",
    addressOptions: {
      isShippingAddressRequired: false,
      isShippingAddressValidationRequired: false,
      isBillingAddressValidationRequired: false,
      isWorldWideAddressAllowed: true,
      permittedCountries: [],
      countries: []
    },
    addressOptionsTemp: {
      isShippingAddressRequired: false,
      isShippingAddressValidationRequired: false,
      isBillingAddressValidationRequired: false,
      isWorldWideAddressAllowed: true,
      permittedCountries: [],
      countries: []
    },
    isAdditionalEmailRequired: false,
    isPhoneNumberRequired: false,
    isMarketingConsentRequired: false,
    isCreatingProductRequest: false,
    isCreatingProductSuccess: false,
    isCreatingProductFailure: false,
    isEditingProductRequest: false,
    isEditingProductSuccess: false,
    isEditingProductFailure: false,
    isFetchingProductFailure: false,
    customFields: [],
    customFieldsTemp: [],
    isReorderingCustomFields: false,
    errorMessage: "",
    isAlertEmailValid: true
  }
  if (!product) {
    return {
      ...baseProduct,
      currency: null
    }
  }
  else {
    return {
      ...baseProduct,
      currency: product.currency
    }
  }
}


export default function productsReducer(state: IProductReducerState = initOrResetProduct(null), action: BillsbyAction, store: AppState) {
  switch (action.type) {
    case SET_PRODUCT_NAME:
      return { ...state, productName: action.payload }
    case SET_PRODUCT_DISPLAY_NAME:
      return { ...state, displayName: action.payload }
    case SET_PROUDUCT_DESCRIPTION:
      return { ...state, description: action.payload }
    case SET_IS_PRODUCT_IMAGE_ENABLED:
      return { ...state, isProductImageEnabled: action.payload }
    case SET_PRODUCT_CURRENCY:
      return { ...state, currency: action.payload }
    case SET_PRODUCT_TYPE:
      return { ...state, typeOfProduct: action.payload }
    case SET_PRODUCT_HELP_TITLE:
      return { ...state, helpTextTitle: action.payload }
    case SET_PRODUCT_HELP_CONTENT:
      return { ...state, helpTextContent: action.payload }
    case SET_PRODUCT_SETTINGS_HELP_TITLE:
      return { ...state, productHelpTextTitle: action.payload }
    case SET_PRODUCT_SETTINGS_HELP_CONTENT:
      return { ...state, productHelpTextContent: action.payload }
    case SET_PRODUCT_ERROR:
      return { ...state, productError: action.payload, productImageUrl: "", productImageFileName: "" }
    case SET_PRODUCT_FORM_COLLAPSED: {
      let panelToCollapse = action.payload;
      let newArray = [...state.stageCollapsed];

      newArray = newArray.map(el => (el !== panelToCollapse ? true : el));
      newArray[panelToCollapse] = false;

      return { ...state, stageCollapsed: newArray }
    }
    case SET_PRODUCT_FORM_HAS_ERROR: {
      let newArray = [...state.stageErrors];
      newArray[action.stageIndex] = action.value;
      return { ...state, stageErrors: newArray }
    }
    case SET_PRODUCT_FORM_COMPLETED: {
      let newArray = [...state.stageStatuses];
      newArray[action.stageIndex] = action.value;
      return { ...state, stageStatuses: newArray }
    }
    case SET_PRODUCT_VISIBILITY:
      return { ...state, visibility: action.payload }
    case SET_PRODUCT_ALERT_EMAIL:
      return { ...state, alertEmail: action.payload }
    case PRODUCT_IMAGE_UPLOAD_REQUEST:
      return { ...state, isProductImageUploading: true }
    case PRODUCT_IMAGE_UPLOAD_FAILURE: {
      const { error } = action;
      return { ...state, isProductImageUploading: false, productError: getErrorMessage(error)}
    }
    case PRODUCT_IMAGE_UPLOAD_SUCCESS:
      {
        const { response } = action;
        return { ...state, productImageFileName: response.fileName, productImageUrl: response.imageSrcUrl, productError: "", isProductImageUploading: false }
      }
    case SET_PRODUCT_FOR_EDITING:
    case FETCH_PRODUCT_SUCCESS: {
      const sourceProduct: IProductFull = action.payload || action.response;
      const currenciesDropdown = store.productPlanCycle.currenciesDropdown;
      const currency = currenciesDropdown.find((s) => s.value === sourceProduct.billingCurrency);
      const { displayName, name, description, productId, typeOfProduct, visibility, alertEmail, isShippingAddressRequired, isShippingAddressValidationRequired,
        isBillingAddressValidationRequired, isAdditionalEmailRequired, isMarketingConsentRequired, isPhoneNumberRequired, customFields, productCountryLimitation, productImageUrl, isProductImageEnabled, productImageFileName } = sourceProduct;
      const { countrySettings, isAnyCountryAllowed } = productCountryLimitation;

      const permittedCountries: Array<{ label: string, value: ICountryAddress }> = countrySettings.map(country => {
        const countryObj = state.addressOptions.countries.find(c => c.value.iso3Code === country.countryIso3) as { label: string, value: ICountry };
        return {
          ...countryObj,
          value: {
            ...(countryObj && countryObj.value),
            allowBillingAddress: country.addressPermission === "BillingAddress" || country.addressPermission === "Both",
            allowShippingAddress: country.addressPermission === "ShippingAddress" || country.addressPermission === "Both"
          }
        }
      })

      let newState = {
        ...state, currency, displayName, productName: name, description, productId, typeOfProduct, visibility, alertEmail, productImageUrl, isProductImageEnabled, productImageFileName,
        
        addressOptions: {
          ...state.addressOptions, isShippingAddressRequired, isShippingAddressValidationRequired, isBillingAddressValidationRequired,
          isWorldWideAddressAllowed: isAnyCountryAllowed, permittedCountries, permittedCountry: permittedCountries.length && permittedCountries[0]
        },

        addressOptionsTemp: {
          ...state.addressOptions, isShippingAddressRequired, isShippingAddressValidationRequired, isBillingAddressValidationRequired,
          isWorldWideAddressAllowed: isAnyCountryAllowed, permittedCountries, permittedCountry: permittedCountries.length && permittedCountries[0]
        },
        isAdditionalEmailRequired, isMarketingConsentRequired, isPhoneNumberRequired, customFields: customFields || [], customFieldsTemp: [...state.customFields]
      };
      newState.stageCollapsed = [false, false, false];
      newState.stageErrors = [false, false, false];
      newState.stageStatuses = [true, true, true];
      return newState;
    }
    case FETCH_PRODUCT_FAILURE:
      return { ...state, isFetchingProductFailure: true }
    case SET_PRODUCT_ADDRESS_COLLAPSED:
      return { ...state, isAddressCollapsed: action.payload }
    case SET_IS_CUSTOMFIELDS_OPENED:
      return { ...state, isCustomFieldsOpened: action.payload }
    case SET_IS_ADDITIONAL_EMAIL_REQUIRED:
      return { ...state, isAdditionalEmailRequired: action.payload }
    case SET_IS_PHONE_NUMBER_REQUIRED:
      return { ...state, isPhoneNumberRequired: action.payload }
    case SET_IS_MARKETING_CONSENT_REQUIRED:
      return { ...state, isMarketingConsentRequired: action.payload }

    case SET_IS_SHIPPING_ADDRESS_REQUIRED:
      return { ...state, addressOptionsTemp: { ...state.addressOptionsTemp, isShippingAddressRequired: action.payload } }
    case SET_IS_SHIPPING_ADDRESS_VALIDATION_REQUIRED:
      return { ...state, addressOptionsTemp: { ...state.addressOptionsTemp, isShippingAddressValidationRequired: action.payload } }
    case SET_IS_BILLING_ADDRESS_VALIDATION_REQUIRED:
      return { ...state, addressOptionsTemp: { ...state.addressOptionsTemp, isBillingAddressValidationRequired: action.payload } }
    case SET_IS_WORLD_WIDE_ADDRESS_ALLOWED:
      return { ...state, addressOptionsTemp: { ...state.addressOptionsTemp, isWorldWideAddressAllowed: action.payload } }
    case SET_SELECTED_COUNTRY_ADDRESS:
      return { ...state, addressOptionsTemp: { ...state.addressOptionsTemp, selectedCountry: action.payload } }
    case SET_PERMITTED_COUNTRY_ADDRESS:
      return { ...state, addressOptionsTemp: { ...state.addressOptionsTemp, permittedCountry: action.payload } }
    case ADD_SELECTED_COUNTRY_ADDRESS: {
      const elem = action.payload as { label: string, value: ICountryAddress };
      elem.value.allowBillingAddress = true;
      elem.value.allowShippingAddress = true;
      return { ...state, addressOptionsTemp: { ...state.addressOptionsTemp, permittedCountries: [...state.addressOptionsTemp.permittedCountries, elem], permittedCountry: elem } }
    }
    case REMOVE_SELECTED_COUNTRY_ADDRESS:
      const newPermittedCountries = state.addressOptionsTemp.permittedCountries.filter(el => el.value.iso3Code !== action.payload.value.iso3Code);
      return {
        ...state,
        addressOptionsTemp: {
          ...state.addressOptionsTemp,
          permittedCountries: newPermittedCountries,
          permittedCountry: newPermittedCountries[0]
        }
      }
    case APPLY_ADDRESS_OPTIONS:
      return { ...state, isAddressCollapsed: true, addressOptions: { ...state.addressOptionsTemp } }
    case DISCARD_ADDRESS_OPTIONS:
      return { ...state, isAddressCollapsed: true, addressOptionsTemp: { ...state.addressOptions } }

    case SET_SELECTED_COUNTRY_ALLOW_BILLING_SHIPPING_ADDRESS: 
      const payload = action.payload as { countryCode: string, prop: "allowBillingAddress" | "allowShippingAddress" }
      const selectedCountries = state.addressOptionsTemp.permittedCountries.slice();
      const index = state.addressOptionsTemp.permittedCountries.findIndex(c => c.value.iso3Code === payload.countryCode);
      selectedCountries[index] = { ...selectedCountries[index], value: { ...selectedCountries[index].value, [payload.prop]: !selectedCountries[index].value[payload.prop] } }
      return { ...state, addressOptionsTemp: {...state.addressOptionsTemp, permittedCountries: selectedCountries} }
    case RESET_PRODUCT_FOR_EDITING:
      return initOrResetProduct(null);
    case CREATE_PRODUCT_REQUEST:
      return { ...state, isCreatingProductRequest: true, isCreatingProductSuccess: false, isCreatingProductFailure: false }
    case CREATE_PRODUCT_SUCCESS:
      return { ...state, isCreatingProductRequest: false, isCreatingProductSuccess: true, isCreatingProductFailure: false }
    case CREATE_PRODUCT_FAILURE:
      return { ...state, isCreatingProductRequest: false, isCreatingProductSuccess: false, isCreatingProductFailure: true, errorMessage: getActionErrorMessage(action.error) }

    case EDIT_PRODUCT_REQUEST:
      return { ...state, isEditingProductRequest: true, isEditingProductSuccess: false, isEditingProductFailure: false }
    case EDIT_PRODUCT_SUCCESS:
      return { ...state, isEditingProductRequest: false, isEditingProductSuccess: true, isEditingProductFailure: false }
    case EDIT_PRODUCT_FAILURE:
      return { ...state, isEditingProductRequest: false, isEditingProductSuccess: false, isEditingProductFailure: true, errorMessage: getActionErrorMessage(action.error) }

    case SET_PRODUCT_CUSTOM_FIELDS: {

      if (action.isAfterReordering) {
        return { ...state, customFields: [...action.payload], customFieldsTemp: [...action.payload] }
      }

      const newSelectedCustomFields: Array<ISelectedCustomField> = action.payload;
      const sameCustomFields = state.customFields.filter(customField => newSelectedCustomFields.some(field => field.customFieldId === customField.customFieldId));
      const differentCustomFields = newSelectedCustomFields.filter(customField => state.customFields.every(field => field.customFieldId !== customField.customFieldId));
      const productCustomFields: Array<ICustomField> = [...sameCustomFields, ...differentCustomFields];
      return { ...state, customFields: productCustomFields, customFieldsTemp: [...productCustomFields] }
    }
    case SET_TEMP_PRODUCT_CUSTOM_FIELDS:
      return { ...state, customFieldsTemp: [...action.payload] }

    case REMOVE_CUSTOM_FIELD:
      return { ...state, customFields: state.customFields.filter(field => field !== action.payload) }
    case SET_IS_REORDERING_CUSTOM_FIELDS:
      return { ...state, isReorderingCustomFields: action.payload }
    case FETCH_COUNTRIES_PRODUCT_SUCCESS: {
      const countries = action.response as Array<ICountry>;
      reorderCountries(countries);
      const countriesDropdown = countries.map((country: ICountry) => ({ value: country, label: country.name }));
      const addressOptions = { ...state.addressOptionsTemp, countries: countriesDropdown };
      return { ...state, addressOptionsTemp: { ...addressOptions }, addressOptions: { ...addressOptions } }
    }
    case SET_PRODUCT_ALERT_EMAIL_IS_VALID: 
      return { ...state, isAlertEmailValid: action.payload }
    case SET_PRODUCT_CYCLES_UNMOUNT:
      return initOrResetProduct(null);
    default:
      return state;
  }
}