import { BillsbyAction } from "../models/BillsbyAction";
import { AppState } from "..";
import { IPaymentGatewayElement } from "../models/Configuration";
import { IProductLight } from "../models/Product";
import { FETCH_PRODUCTS_CURRENCIES_SUCCESS, FETCH_CURRENCIES_MAPPING_SUCCESS, UPDATE_BASE_CURRENCY_PAYMENT_GATEWAY_REQUEST, UPDATE_BASE_CURRENCY_PAYMENT_GATEWAY_SUCCESS, UPDATE_BASE_CURRENCY_PAYMENT_GATEWAY_FAILURE, UPDATE_CURRENCY_MAPPING_REQUEST, UPDATE_CURRENCY_MAPPING_SUCCESS, UPDATE_CURRENCY_MAPPING_FAILURE, SET_CURRENCIES_FIELD, RESET_CURRENCIES_STATE } from "../actions/currenciesActions";

export interface ICurrencyPaymentGateways {
  creditCardPaymentGateway: { label: string, value: IPaymentGatewayElement } , 
  achPaymentGateway?: { label: string, value: IPaymentGatewayElement },
  isCreditSupported: boolean,
  isAchSupported: boolean,
  isAchUsed: boolean
}

export interface IAdditionalCurrency extends ICurrencyPaymentGateways { 
  currencyCode: string,
}

export interface ICurrenciesReducer {
  selectedBaseCurrencyGateway?: ICurrencyPaymentGateways,
  additionalCurrencies?: Array<IAdditionalCurrency>,
  isUpdatingBaseCurrencyGateway: boolean,
  isUpdatingCurrencyGatewaysMapping: boolean,
  currenciesGatewaysMapping?: Array<{ currencyCode: string, paymentGatewayId: number, isCreditSupported: boolean, achPaymentGatewayId: number, isAchUsed: false }>,
  linkFromSca: boolean
}

export const initialState: ICurrenciesReducer = {
  selectedBaseCurrencyGateway: undefined,
  additionalCurrencies: undefined,
  isUpdatingBaseCurrencyGateway: false,
  isUpdatingCurrencyGatewaysMapping: false,
  currenciesGatewaysMapping: undefined,
  linkFromSca: false
}

export default function currenciesReducer(state: ICurrenciesReducer = initialState, action: BillsbyAction, store: AppState) {
  switch (action.type) {
    case SET_CURRENCIES_FIELD:
      return { ...state, [action.field]: action.payload }
    case FETCH_PRODUCTS_CURRENCIES_SUCCESS: {
      const products: Array<IProductLight> = action.response.results;
      const defaultSelectedGateway = { label: "-", value: { paymentGatewayId: null } };
  
      // case insensitive check for ach flag
      const isACHPreviewEnabled = !!window.location.search.toLocaleUpperCase().match(/ACHPREVIEWENABLED=TRUE/);
      const { isAchPaymentActive } = store.achSettingsReducer;

      const currenciesGatewaysMapping = [...state.currenciesGatewaysMapping as any];
      const paymentGatewaysDropdown = [...store.paymentGatewaysReducer.paymentGatewaysDropdown as any];
      // get currencies for each product, remove duplicates and remove base currency
      const additionalCurrencies = products
        .filter(product => product.isDeleted ? product.hasActiveSubscriptions : true)
        .map(product => product.billingCurrency)
        .filter((currency, idx, currArr) => currArr.indexOf(currency) === idx)
        .filter(c => c !== store.auth.currentCompanyCurrency)
        .map(currencyCode => {
          const mapping = currenciesGatewaysMapping.find(mapping => mapping.currencyCode === currencyCode) || { currencyCode, paymentGatewayId: null, achPaymentGatewayId: null, isCreditSupported: false };
          const creditCardPaymentGateway = paymentGatewaysDropdown.find(gateway => gateway.value.paymentGatewayId === mapping.paymentGatewayId) || defaultSelectedGateway;
          const achPaymentGateway = paymentGatewaysDropdown.find(gateway => gateway.value.paymentGatewayId === mapping.achPaymentGatewayId) || defaultSelectedGateway;
          return { currencyCode, isAchUsed: mapping.isAchUsed, creditCardPaymentGateway, achPaymentGateway, isCreditSupported: mapping.isCreditSupported, isAchSupported: isAchPaymentActive && isACHPreviewEnabled && currencyCode === "USD" }
        });
      const baseCurrencyMapping = currenciesGatewaysMapping.find(cur => cur.currencyCode === store.auth.currentCompanyCurrency) || {} as { currencyCode: string, paymentGatewayId: number, achPaymentGatewayId: number | null, isCreditSupported: boolean };
      const creditCardPaymentGateway = paymentGatewaysDropdown.find(gateway => gateway.value.paymentGatewayId === baseCurrencyMapping.paymentGatewayId) || defaultSelectedGateway;
      const achPaymentGateway = paymentGatewaysDropdown.find(gateway => gateway.value.paymentGatewayId === baseCurrencyMapping.achPaymentGatewayId) || defaultSelectedGateway;
      return { ...state, additionalCurrencies, selectedBaseCurrencyGateway: { ...state.selectedBaseCurrencyGateway, isAchUsed: baseCurrencyMapping.isAchUsed, creditCardPaymentGateway, achPaymentGateway, isCreditSupported: baseCurrencyMapping.isCreditSupported, isAchSupported: isAchPaymentActive && isACHPreviewEnabled && store.auth.currentCompanyCurrency === "USD" } }
    }
    case FETCH_CURRENCIES_MAPPING_SUCCESS:
      return { ...state, currenciesGatewaysMapping: action.response }
    case UPDATE_BASE_CURRENCY_PAYMENT_GATEWAY_REQUEST:
      return { ...state, isUpdatingBaseCurrencyGateway: true }
    case UPDATE_BASE_CURRENCY_PAYMENT_GATEWAY_SUCCESS:
      return { ...state, isUpdatingBaseCurrencyGateway: false }
    case UPDATE_BASE_CURRENCY_PAYMENT_GATEWAY_FAILURE:
      return { ...state, isUpdatingBaseCurrencyGateway: false }

    case UPDATE_CURRENCY_MAPPING_REQUEST:
      return { ...state, isUpdatingCurrencyGatewaysMapping: true }
    case UPDATE_CURRENCY_MAPPING_SUCCESS:
      return { ...state, isUpdatingCurrencyGatewaysMapping: false }
    case UPDATE_CURRENCY_MAPPING_FAILURE:
      return { ...state, isUpdatingCurrencyGatewaysMapping: false }
    case RESET_CURRENCIES_STATE:
      return { ...initialState }

    default:
      return state;
  }
}

