import API from "../utils/API";
import { makeActionCreator, makeGenericActionCreator } from "../utils/reduxUtils";
import { ISubscriptionCustomFieldsReducerState } from "../reducers/subscriptionCustomFieldsReducer";

export const FETCH_SUBSCRIPTION_CUSTOM_FIELDS_REQUEST = "FETCH_SUBSCRIPTION_CUSTOM_FIELDS_REQUEST";
export const FETCH_SUBSCRIPTION_CUSTOM_FIELDS_SUCCESS = "FETCH_SUBSCRIPTION_CUSTOM_FIELDS_SUCCESS";
export const FETCH_SUBSCRIPTION_CUSTOM_FIELDS_FAILURE = "FETCH_SUBSCRIPTION_CUSTOM_FIELDS_FAILURE";

export const UPDATE_SUBSCRIPTION_CUSTOM_FIELDS_REQUEST = "UPDATE_SUBSCRIPTION_CUSTOM_FIELDS_REQUEST";
export const UPDATE_SUBSCRIPTION_CUSTOM_FIELDS_SUCCESS = "UPDATE_SUBSCRIPTION_CUSTOM_FIELDS_SUCCESS";
export const UPDATE_SUBSCRIPTION_CUSTOM_FIELDS_FAILURE = "UPDATE_SUBSCRIPTION_CUSTOM_FIELDS_FAILURE";

export const SET_SUBSCRIPTION_CUSTOM_FIELDS = "SET_SUBSCRIPTION_CUSTOM_FIELDS";

export const RESET_SUBSCRIPTION_CUSTOM_FIELDS = "RESET_SUBSCRIPTION_CUSTOM_FIELDS";
type AllowedCustomField = keyof ISubscriptionCustomFieldsReducerState;

export const resetSubscriptionCustomFields = makeActionCreator(RESET_SUBSCRIPTION_CUSTOM_FIELDS);
export const setSubscriptonCustomField = makeGenericActionCreator<AllowedCustomField>(SET_SUBSCRIPTION_CUSTOM_FIELDS);

export const fetchSubscriptionCustomFields = function (companyDomain: string, productId: number, customerUniqueId: string) {
  return {
    types: [FETCH_SUBSCRIPTION_CUSTOM_FIELDS_REQUEST, FETCH_SUBSCRIPTION_CUSTOM_FIELDS_SUCCESS, FETCH_SUBSCRIPTION_CUSTOM_FIELDS_FAILURE],
    callAPI: () => API.fetchSubscriptionCustomFields(companyDomain, productId, customerUniqueId)
  }
}
