import React from "react";
import "./TransactionsWelcome.scss";
import Panel from "../ui/panel/Panel";
import Text from "../ui/text/Text";
import PageWrapper from "../ui/layout/PageWrapper";

export const CustomerTransactionsWelcome = () => {
  return (
    <PageWrapper className="transactions-welcome">
     <Panel className="transactions-welcome__greeting" title="TRANSACTIONS_WELCOME_TITLE">
      <Text content="TRANSACTIONS_WELCOME_TEXT" />
      <Panel className="transactions-welcome__greeting-question" title="TRANSACTIONS_FIRST_NOTE_TITLE">
        <Text noMargin content="TRANSACTIONS_FIRST_NOTE_TEXT" />
      </Panel>
     </Panel>
    </PageWrapper>
  )
}

export default CustomerTransactionsWelcome;