import React, { useEffect } from "react";
import "./CheckoutAccountManagementSettings.scss";
import { NavLink, Link, useRouteMatch } from "react-router-dom";
import Panel from "../../../ui/panel/Panel";
import Text from "../../../ui/text/Text";
import { useDispatch } from "react-redux";
import history from "../../../../utils/history";
import NavigationBar from "../../../ui/navigation-bar/NavigationBar";
import PageWrapper from "../../../ui/layout/PageWrapper";
import TabbedContainer from "../../../ui/tabbed-container/TabbedContainer";
import counterpart from "counterpart";
import { PrivateRoute } from "../../../../containers/AuthRoutes";
import SelfService from "./self-service/SelfService";
import Checkout from "./checkout/Checkout";
import { resetCheckoutAcctMgmt } from "../../../../actions/checkoutAccountManagementActions";
import TerminologyCustomization from "./terminology-customization/TerminologyCustomization";


const CheckoutAccountManagementSettings: React.FC = () => {
  const dispatch = useDispatch<Function>();
  const match = useRouteMatch();

  useEffect(() => {
    return () => dispatch(resetCheckoutAcctMgmt())
  }, [])

  return (
    <div className="checkout-account-management">
      <NavigationBar pageTitle="" previousPageTitle="CONFIGURATION_PAGE_TITLE" previousPageCb={() => history.push("/configuration")} />

      <PageWrapper>
        <Panel title="CHECKOUT_ACCT_MANAGEMENT_MENU_ITEM_TITLE" className="checkout-account-management__heading">
          <Text content="CHECKOUT_ACCT_MANAGEMENT_PANEL1_CONTENT" translateWith={{
          link: <Link to="/embed-codes/checkout">{counterpart("CHECKOUT_ACCT_MANAGEMENT_PANEL1_LINK")}</Link>
          }} noMargin />
        </Panel>
        
        <TerminologyCustomization />
        
        <TabbedContainer type="submenu">
          <NavLink isActive={(match, location) => (!match || !location ? false : match?.isExact)} activeClassName="active" to={`${match?.url}`}>
            {counterpart("CHECKOUT_ACCT_MANAGEMENT_TAB1")}
          </NavLink>
          <NavLink exact activeClassName="active" to={`${match?.url}/self-service`}>
            {counterpart("CHECKOUT_ACCT_MANAGEMENT_TAB2")}
          </NavLink>
        </TabbedContainer>

        <PrivateRoute exact path={`${match?.path}/`} component={Checkout} />
        <PrivateRoute exact path={`${match?.path}/self-service`} component={SelfService} />

      </PageWrapper>
    </div>
  );
};

export default CheckoutAccountManagementSettings;