import React, { useState } from "react";
import "./BasicPreview.scss";
import Text from "../../ui/text/Text";
import CustomFieldPreview from "../../ui/custom-field-preview/CustomFieldPreview";
import CheckoutCheckbox from "./ui-checkout/CheckoutCheckbox";


interface ICheckboxPreviewProps {
  label: string,
  supportText: string
}

const CheckboxPreview: React.FC<ICheckboxPreviewProps> = ({ label = "", supportText = "" }) => {
  const [isSelected, setIsSelected] = useState(false);

  return (
    <CustomFieldPreview className="preview">
      <CheckoutCheckbox
        id='checkout-checkbox-preview-id'
        checked={isSelected}
        onClick={() => setIsSelected(!isSelected)}
        value=''
        content={(
          <div className="">
            <Text content={label} noMargin shouldTranslate={false} />
            <Text content={supportText} noMargin shouldTranslate={false} />
          </div>
        )}
        noMargin
      />
    </CustomFieldPreview>
  );
};

export default CheckboxPreview;