import React, { useEffect } from "react";
import { ICustomFieldsReducer } from "../../../reducers/customFieldsReducer";
import StepCollapsablePanel from "../../ui/step-collapsable-panel/StepCollapsablePanel";
import "./CreateCustomField.scss";
import Text from "../../ui/text/Text";
import FormGroup from "../../ui/form-group/FormGroup";
import FormLabel from "../../ui/form-label/FormLabel";
import Dropdown from "../../ui/dropdown/Dropdown";
import { Row, Col } from "react-grid-system";
import Input from "../../ui/input/Input";
import Textarea from "../../ui/textarea/Textarea";
import Checkbox from "../../ui/checkbox/Checkbox";
import Button from "../../ui/button/Button";
import OneLinePreview from "../../../components/custom-fields/previews/OneLinePreview";
import counterpart from "counterpart";
import { CustomFieldType, IBaseCustomField, ISelectedCustomField, CustomFieldScreen } from "../../../models/CustomFields";
import { setCreateCustomField, resetCreateCustomFieldState, addCustomField, setCustomFieldsCurrentScreen, createCustomField, putCustomField, updateCustomField, resetCustomFieldsState } from "../../../actions/customFieldsActions";
import { IAuthReducerState } from "../../../reducers/authReducer";
import Alert from "../../ui/alert/Alert";
import DropdownOptions from "./dropdown-options/DropdownOptions";
import DropdownPreview from "../previews/DropdownPreview";
import MultiLinePreview from "../previews/MultiLinePreview";
import CheckboxPreview from "../previews/CheckboxPreview";
import NumbersPreview from "../previews/NumbersPreview";
import DatePickerPreview from "../previews/DatePickerPreview";
import ElementModifiers from "../../ui/element-modifiers/ElementModifiers";
import { setIsCustomFieldsOpened, setProductCustomFields } from "../../../actions/productPlanCycleActions";
import { IProductReducerState } from "../../../reducers/productsReducer";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../../..";

interface ICreateCustomField {
  onBack: () => void
}



const CreateCustomField: React.FC<ICreateCustomField> = ({ onBack }) => {


  const customFieldsData = useSelector<AppState, ICustomFieldsReducer>(state => state.customFields)
  const productsData = useSelector<AppState, IProductReducerState>(state => state.products)
  const authData = useSelector<AppState, IAuthReducerState>(state => state.auth)
  const { fieldType, fieldLabel, supportText, options, isCompulsory, helpTextTitle, helpTextContent, fieldLabelPlaceholder, supportTextPlaceholder, hasError } = customFieldsData.createCustomField;
  const { customFieldsOptions, customFieldId, isQuickEdit } = customFieldsData;
  const { customFields: productDisplayedCustomFields } = productsData;
  const dispatch = useDispatch<Function>()

  const getPreview = () => {
    const { fieldType, fieldLabel, supportText, optionValue, options, optionsSource } = customFieldsData.createCustomField;
    switch (fieldType.value) {
      case CustomFieldType.SingleLineTextField:
        return (
          <OneLinePreview
            label={fieldLabel || counterpart("CREATE_CUSTOM_FIELDS_LABEL_PLACEHOLDER")}
            supportText={supportText || counterpart("CREATE_CUSTOM_FIELDS_SUPPORT_TEXT_PLACEHOLDER")}
          />
        )
      case CustomFieldType.DropdownField:
        return (
          <div>
            <DropdownOptions
              optionValue={optionValue}
              optionValueOnChange={(evt: any) => dispatch(setCreateCustomField({ optionValue: evt.target.value }))}
              optionValueOnFocus={() => dispatch(setCreateCustomField({ helpTextTitle: "CREATE_CUSTOM_FIELDS_HELP_FIELDOPTIONS_TEXT_TITLE", helpTextContent: ["CREATE_CUSTOM_FIELDS_HELP_DROPDOWN_TEXT"] }))}
              optionValueOnDelete={(index: number) => dispatch(setCreateCustomField({ options: options.filter((opt, idx) => idx !== index) }))}
              optionListOnChange={(newList: Array<any>) => dispatch(setCreateCustomField({ options: newList }))}
              onAdd={() => { optionValue && dispatch(setCreateCustomField({ options: [...options, optionValue], optionValue: "" })) }}
              options={options}
              optionsSource={optionsSource}
            />
            <DropdownPreview
              label={fieldLabel || counterpart("CREATE_CUSTOM_FIELDS_LABEL_PLACEHOLDER_DROPDOWN")}
              supportText={supportText || counterpart("CREATE_CUSTOM_FIELDS_SUPPORT_TEXT_PLACEHOLDER_DROPDOWN")}
              options={options.map(opt => ({ label: opt, value: opt }))}
            />
          </div>
        )
      case CustomFieldType.MultiLineTextField:
        return (
          <MultiLinePreview
            label={fieldLabel || counterpart("CREATE_CUSTOM_FIELDS_LABEL_PLACEHOLDER_MULTILINE")}
            supportText={supportText || counterpart("CREATE_CUSTOM_FIELDS_SUPPORT_TEXT_PLACEHOLDER_MULTILINE")}
          />
        )
      case CustomFieldType.CheckboxField:
        return (
          <CheckboxPreview
            label={fieldLabel || counterpart("CREATE_CUSTOM_FIELDS_LABEL_PLACEHOLDER_CHECKBOX")}
            supportText={supportText || counterpart("CREATE_CUSTOM_FIELDS_SUPPORT_TEXT_PLACEHOLDER_CHECKBOX")}
          />
        )
      case CustomFieldType.NumbersField:
        return (
          <NumbersPreview
            label={fieldLabel || counterpart("CREATE_CUSTOM_FIELDS_LABEL_PLACEHOLDER_NUMBERS")}
            supportText={supportText || counterpart("CREATE_CUSTOM_FIELDS_SUPPORT_TEXT_PLACEHOLDER_NUMBERS")}
          />
        )
      case CustomFieldType.DatePickerField:
        return (
          <DatePickerPreview
            label={fieldLabel || counterpart("CREATE_CUSTOM_FIELDS_LABEL_PLACEHOLDER_DATEPICKER")}
            supportText={supportText || counterpart("CREATE_CUSTOM_FIELDS_SUPPORT_TEXT_PLACEHOLDER_DATEPICKER")}
          />
        )
      default:
        return <div />
    }
  }
  
  const getHelpmenuForType = (fieldType: { label: string, value: CustomFieldType }) => {
    switch (fieldType.value) {
      case CustomFieldType.SingleLineTextField:
        dispatch(setCreateCustomField({
          helpTextTitle: "CREATE_CUSTOM_FIELDS_HELP_SINGLE_LINE_TITLE",
          helpTextContent: ["CREATE_CUSTOM_FIELDS_HELP_LINE_1", "CREATE_CUSTOM_FIELDS_HELP_SINGLE_LINE_2"],
          fieldLabelPlaceholder: "CREATE_CUSTOM_FIELDS_LABEL_PLACEHOLDER",
          supportTextPlaceholder: "CREATE_CUSTOM_FIELDS_SUPPORT_TEXT_PLACEHOLDER",
        }))
        break;
      case CustomFieldType.MultiLineTextField:
        dispatch(setCreateCustomField({
          helpTextTitle: "CREATE_CUSTOM_FIELDS_HELP_MULTI_LINE_TITLE",
          helpTextContent: ["CREATE_CUSTOM_FIELDS_HELP_LINE_1", "CREATE_CUSTOM_FIELDS_HELP_MULTI_LINE_2"],
          fieldLabelPlaceholder: "CREATE_CUSTOM_FIELDS_LABEL_PLACEHOLDER_MULTILINE",
          supportTextPlaceholder: "CREATE_CUSTOM_FIELDS_SUPPORT_TEXT_PLACEHOLDER_MULTILINE",
        }))
        break;
      case CustomFieldType.CheckboxField:
        dispatch(setCreateCustomField({
          helpTextTitle: "CREATE_CUSTOM_FIELDS_HELP_CHECKBOX_TITLE",
          helpTextContent: ["CREATE_CUSTOM_FIELDS_HELP_LINE_1", "CREATE_CUSTOM_FIELDS_HELP_CHECKBOX_2"],
          fieldLabelPlaceholder: "CREATE_CUSTOM_FIELDS_LABEL_PLACEHOLDER_CHECKBOX",
          supportTextPlaceholder: "CREATE_CUSTOM_FIELDS_SUPPORT_TEXT_PLACEHOLDER_CHECKBOX",
        }))
        break;
      case CustomFieldType.NumbersField:
        dispatch(setCreateCustomField({
          helpTextTitle: "CREATE_CUSTOM_FIELDS_HELP_NUMBERS_TITLE",
          helpTextContent: ["CREATE_CUSTOM_FIELDS_HELP_LINE_1", "CREATE_CUSTOM_FIELDS_HELP_NUMBERS_2"],
          fieldLabelPlaceholder: "CREATE_CUSTOM_FIELDS_LABEL_PLACEHOLDER_NUMBERS",
          supportTextPlaceholder: "CREATE_CUSTOM_FIELDS_SUPPORT_TEXT_PLACEHOLDER_NUMBERS",
        }))
        break;
      case CustomFieldType.DatePickerField:
        dispatch(setCreateCustomField({
          helpTextTitle: "CREATE_CUSTOM_FIELDS_HELP_DATE_PICKER_TITLE",
          helpTextContent: ["CREATE_CUSTOM_FIELDS_HELP_LINE_1", "CREATE_CUSTOM_FIELDS_HELP_DATE_PICKER_2"],
          fieldLabelPlaceholder: "CREATE_CUSTOM_FIELDS_LABEL_PLACEHOLDER_DATEPICKER",
          supportTextPlaceholder: "CREATE_CUSTOM_FIELDS_SUPPORT_TEXT_PLACEHOLDER_DATEPICKER",
        }))
        break;
      case CustomFieldType.DropdownField:
        dispatch(setCreateCustomField({
          helpTextTitle: "CREATE_CUSTOM_FIELDS_HELP_DROPDOWN_TEXT_TITLE",
          helpTextContent: ["CREATE_CUSTOM_FIELDS_HELP_DROPDOWN_TEXT"],
          fieldLabelPlaceholder: "CREATE_CUSTOM_FIELDS_LABEL_PLACEHOLDER_DROPDOWN",
          supportTextPlaceholder: "CREATE_CUSTOM_FIELDS_SUPPORT_TEXT_PLACEHOLDER_DROPDOWN",
        }));
        break;
      default:
        dispatch(setCreateCustomField({
          helpTextTitle: "HELP_EMPTY_TITLE",
          helpTextContent: ["HELP_EMPTY_1", "HELP_EMPTY_2"],
          fieldLabelPlaceholder: "CREATE_CUSTOM_FIELDS_LABEL_PLACEHOLDER",
          supportTextPlaceholder: "CREATE_CUSTOM_FIELDS_SUPPORT_TEXT_PLACEHOLDER",
        }))
        break;
    }
  }

  useEffect(() => {
    getHelpmenuForType(fieldType);
    return () => {
      // reset state when component is flushed from memory
      dispatch(resetCreateCustomFieldState());
    }
  }, [])

  return (
    <StepCollapsablePanel
      className="create-custom-field"
      title={
        <div className="create-custom-field__title">
          <i className="far fa-chevron-left" onClick={onBack} />
          <Text content="CREATE_CUSTOM_FIELDS_TITLE" component="div" noMargin />
        </div>
      }
      shouldTranslateTitle={false}
      isCollapsed={false}
      helpMenuTitle={helpTextTitle}
      helpMenuPosition="right"
      helpMenuContent={
        <div>
          {helpTextContent.map((text, idx) => <Text key={idx} content={text} />)}
        </div>
      }
    >
      <Text content="CREATE_CUSTOM_FIELDS_SUBTITLE" />

      <FormGroup>
        <Row align="center">
          <Col xs={2}>
            <FormLabel target="field-type-dropdown" content="CREATE_CUSTOM_FIELDS_TYPE" />
          </Col>
          <Col xs={10}>
            {!customFieldId ?
              <Dropdown
                id="field-type-dropdown"
                value={fieldType}
                onChange={(fieldType: any) => {
                  dispatch(setCreateCustomField({ fieldType }));
                  getHelpmenuForType(fieldType);
                }}
                onFocus={() => getHelpmenuForType(fieldType)}
                options={customFieldsOptions}
              />
              :
              <div className="create-custom-field__field-type-value">
                <Text content={fieldType.label} shouldTranslate={false} noMargin />
                <ElementModifiers className="create-custom-field__field-type-value__lock-icon" isShowLockIcon />
              </div>

            }
          </Col>
        </Row>
      </FormGroup>

      <FormGroup>
        <Row align="center">
          <Col xs={2}>
            <FormLabel target="field-label-value" content="CREATE_CUSTOM_FIELDS_LABEL" />
          </Col>
          <Col xs={10}>
            <Input
              id="field-label-value"
              placeholder={counterpart(fieldLabelPlaceholder)}
              value={fieldLabel}
              maxLength={100}
              onChange={(evt: any) => dispatch(setCreateCustomField({ fieldLabel: evt.target.value }))}
              onFocus={() => dispatch(setCreateCustomField({ helpTextTitle: "CREATE_CUSTOM_FIELDS_HELP_FIELD_LABEL_TITLE", helpTextContent: ["CREATE_CUSTOM_FIELDS_HELP_FIELD_LABEL_1"] }))}
            />
          </Col>
        </Row>
      </FormGroup>

      <FormGroup>
        <Row>
          <Col xs={2}>
            <FormLabel target="support-text-value" content="CREATE_CUSTOM_FIELDS_SUPPORT_TEXT" />
          </Col>
          <Col xs={10}>
            <Textarea
              id="support-text-value"
              rows={4}
              placeholder={counterpart(supportTextPlaceholder)}
              value={supportText}
              onChange={(evt: any) => dispatch(setCreateCustomField({ supportText: evt.target.value }))}
              onFocus={() => dispatch(setCreateCustomField({ helpTextTitle: "CREATE_CUSTOM_FIELDS_HELP_SUPPORT_TEXT_TITLE", helpTextContent: ["CREATE_CUSTOM_FIELDS_HELP_SUPPORT_TEXT_1"] }))}
            />
          </Col>
        </Row>
      </FormGroup>

      {getPreview()}

      <FormGroup>
        <Checkbox
          checked={isCompulsory}
          value=""
          content={() => (
            <div className="create-custom-field__compulsoryfields">
              <Text content="CREATE_CUSTOM_FIELDS_COMPULSORY_TEXT" noMargin />
              <Text content="CREATE_CUSTOM_FIELDS_COMPULSORY_TEXT2" noMargin />
            </div>
          )}
          onClick={() => dispatch(setCreateCustomField({ isCompulsory: !isCompulsory }))}
          isHtml
        />
      </FormGroup>

      <Button
        id="create-custom-field-btn"
        title={!customFieldId ? "CREATE_CUSTOM_FIELDS_ADD_NEW" : "UPDATE_CUSTOM_FIELDS_ADD_NEW"}
        onClick={() => {
          const newCustomField: IBaseCustomField = {
            type: fieldType.value,
            label: fieldLabel,
            description: supportText,
            options: options.length ? options.toString() : null,
            compulsory: isCompulsory
          }

          if (!customFieldId) {
            dispatch(createCustomField(authData.currentCompanyDomain, newCustomField))
              .then(({ response }: any) => {
                if (response) {
                  const field: ISelectedCustomField = { ...response, isSelected: false }
                  dispatch(addCustomField(field));
                  dispatch(setCustomFieldsCurrentScreen(CustomFieldScreen.COMPANY_FIELDS));
                  onBack();
                }
              })
          } else {
            dispatch(putCustomField(authData.currentCompanyDomain, customFieldId, newCustomField))
              .then(({ response }: any) => {
                if (response) {
                  const field: ISelectedCustomField = { ...response, isSelected: false }
                  dispatch(updateCustomField(field));
                  dispatch(setProductCustomFields(productDisplayedCustomFields.map(pfield => pfield.customFieldId === field.customFieldId ? field : pfield), true));
                  if(isQuickEdit) {
                    dispatch(setIsCustomFieldsOpened(false));
                    dispatch(resetCustomFieldsState());
                  }
                  else {
                    dispatch(setCustomFieldsCurrentScreen(CustomFieldScreen.COMPANY_FIELDS));
                    onBack();
                  }
                }
              })
          }
        }}
        disabled={!fieldLabel || (fieldType.value === CustomFieldType.DropdownField && options.length === 0)}
        isFullWidth
      />

      <Alert
        text="CREATE_CUSTOM_FIELD_FAILED"
        type="warning"
        showAlert={hasError}
      />
    </StepCollapsablePanel>
  )
}

export default CreateCustomField;