import React, { useLayoutEffect, useMemo, useState } from "react";
import { AppState } from "../../../..";
import { useDispatch, useSelector } from "react-redux";
import { Link, NavLink, useRouteMatch } from "react-router-dom";
import history from "../../../../utils/history";
import NavigationBar from "../../../ui/navigation-bar/NavigationBar";
import PageWrapper from "../../../ui/layout/PageWrapper";
import CodePanel from "../../../ui/code-panel/CodePanel";
import CollapsablePanelWithArrow from "../../../ui/collapsable-panel-with-arrow/CollapsablePanelWithArrow";
import Panel from "../../../ui/panel/Panel";
import Text from "../../../ui/text/Text";
import moment from "moment";
import { fetchCompanyApiKey, setProductAddressCollapsed } from "../../../../actions/companyApiKeysActions";
import NavigationAlertCurrencyMismatch from "../../../ui/navigation-alert/NavigationAlertCurrencyMismatch";
import TabbedContainer from "../../../../components/ui/tabbed-container/TabbedContainer";
import counterpart from "counterpart";
import Webhooks from "./webhooks/Webhooks";
import { PrivateRoute } from "../../../../containers/AuthRoutes";

import "./ApiKeys.scss";
import ProgressIndicator from "../../../ui/progress-indicator/ProgressIndicator";
import Modal from "../../../modal/Modal";
import Teammates from "../../../teammates/Teammates";
import { Permission } from "../../../../models/Auth";
import NoticePanel from "../../../ui/notice-panel/NoticePanel";
import { setShowBillingModal } from "../../../../actions/authActions";

const ApiKeys: React.FC = () => {
  const authReducer = useSelector((state: AppState) => state.auth);
  const companyApiKeyReducer = useSelector((state: AppState) => state.companyApiKeyReducer);

  const dispatch = useDispatch<Function>();
  const match = useRouteMatch();

  const { currentCompanyDomain, parsedToken } = authReducer;
  const { apiKey, createdOn, isCollapsedKeepingSecure } = companyApiKeyReducer;

  const createdOnDate = moment(createdOn).format("DD MMM YYYY");
  const createdOnTime = moment(createdOn).format("HH:mm Z");

  const [state, setState] = useState({
    isLoadingData: false,
    isOpenTeamMatesModal: false
  })

  const { isLoadingData, isOpenTeamMatesModal } = state

  const hasPermission = useMemo(() => {
    if(parsedToken?.CurrentPermissions.some(p => p === Permission[Permission.BillsbyApiKey])) {
      return true
    }
    return false
  }, [parsedToken])

  const fetchData = async () => {
    setState((prevState) => { return { ...prevState, isLoadingData: true }});
    try {
      await dispatch(fetchCompanyApiKey(currentCompanyDomain));
    }
    catch(err) {}
    finally {
      setState((prevState) => { return { ...prevState, isLoadingData: false }});
    }
  }

  useLayoutEffect(() => {
    fetchData();
  }, [])

  const renderApiKeysScreen = () => {
    if(isLoadingData) {
      return (
      <ProgressIndicator color="blue" />
      )
    }
    if(!hasPermission) {
      return (
        <NoticePanel
          type="warning"
          icon="far fa-lightbulb-exclamation"
          content="API_KEYS_PERMISSION_WARNING"
          translateWith={{
            here: <Link to="" id="billing-modal-link" onClick={(evt) => { dispatch(setShowBillingModal(true)); evt.preventDefault();}}>{counterpart("REPORTS_ACCOUNTING_WARNING_PERMISSION_HERE")}</Link>
          }}
          isVisible={!hasPermission}
        />
      )
    }
    return (
      <>
        <Panel title="API_KEYS_BASE_TITLE" className="api-keys__base">
          <CodePanel id="basic-copy-2" codeStr={apiKey} noMargin={true}>
            <span className="eccheckout__basic__code-main">
              {apiKey}
              <Text className="api-keys_description" content={`Created on ${createdOnDate} at ${createdOnTime}`} noMargin shouldTranslate={false} />
            </span>
          </CodePanel>
        </Panel>

        <CollapsablePanelWithArrow
          title={"API_KEYS_KEEPING_API_KEYS_SECURE"}
          isCollapsed={isCollapsedKeepingSecure}
          onClick={() => dispatch(setProductAddressCollapsed(!isCollapsedKeepingSecure))}
          className="api-keys_collapsable_keep_api_secure"
        >
          <Text content="API_KEYS_KEEP_YOUR_API_KEYS_CONFIDENTIAL" className="api-keys_display_inline api-keys_add_top_margin" noMargin />
          <Text content="API_KEYS_NOT" className="api-keys_display_inline api-keys_display_bold" noMargin />
          <Text content="API_KEYS_SHARE_THEM" className="api-keys_display_inline" noMargin />
          <a href="javascript:void(0)" onClick={() => setState((prevState) => { return { ...prevState, isOpenTeamMatesModal: true }})}>
            <Text content="API_KEYS_TEAMMATES" className="api-keys_display_inline" noMargin />
          </a>
          <Text content="API_KEYS_HAVE_THEM_NAVIGATE" className="api-keys_display_inline" noMargin />

          {/* // HIDDEN FOR NOW
          <Text content="API_KEYS_USE_ONE_API_KEY_FOR_EACH_APPLICATION" className="api-keys_add_top_margin" noMargin />
          <Text content="API_KEYS_WHEN_YOU_DISABLE_OR_REMOVE_API_KEY" className="api-keys_add_top_margin" noMargin /> */}
        </CollapsablePanelWithArrow>
      </>
    );
  }

  return (
    <div className="api-keys">
      <NavigationBar pageTitle="" previousPageTitle="CONFIGURATION_PAGE_TITLE" previousPageCb={() => history.push("/configuration")} />
      <NavigationAlertCurrencyMismatch />

      <PageWrapper>
        <Panel title="API_KEYS_HEADING_TITLE" className="api-keys__panel" noMargin>
          <Text content="API_KEYS_HEADING_CONTENT" noMargin />
        </Panel>

        <TabbedContainer type="submenu">
          <NavLink exact activeClassName="active" to={`${match?.url}`}>
            {counterpart("CONFIG_TAB_API_KEYS")}
          </NavLink>
          <NavLink exact activeClassName="active" to={`${match?.url}/webhooks`}>
            {counterpart("CONFIG_TAB_WEBHOOKS")}
          </NavLink>
        </TabbedContainer>

        <PrivateRoute
          exact
          path={match?.path}
          render={renderApiKeysScreen}
        ></PrivateRoute>
        <PrivateRoute exact path={`${match?.path}/webhooks`} component={Webhooks}></PrivateRoute>

        <Modal isOpen={isOpenTeamMatesModal} onRequestClose={() => setState((prevState) => { return { ...prevState, isOpenTeamMatesModal: false }})} title="TEAMMATES_WELCOME">
          <Teammates />
        </Modal>

      </PageWrapper>
    </div>
  );
}

export default ApiKeys;
