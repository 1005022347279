import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import Table from "../../components/ui/table/Table";
import Text from "../../components/ui/text/Text";
import StatusLabel from "../../components/ui/status-label/StatusLabel";
import { ICustomerCreditNoteData } from "../../models/Customer";
import { fetchCustomerCreditNotes, resetCustomerDetailsCreditNotes } from "../../actions/customerDetailsActions";
import Button from "../../components/ui/button/Button";
import moment from "moment";
import { AppState } from "../../";
import "./CreditNotesTable.scss";
import { getCreditNoteStatusText, getCreditNoteStatusColor } from "../../utils/commonUtils";
import ProgressIndicator from "../../components/ui/progress-indicator/ProgressIndicator";
import InvoicesPaymentsTable from "../invoices-table/invoices-payment-table/InvoicesPaymentsTable";

const CreditNotesTable: React.FC = () => {
  const { creditNotes, currentCompanyDomain } = useSelector((state: AppState) => ({ creditNotes: state.customerDetailsReducer.creditNotes, currentCompanyDomain: state.auth.currentCompanyDomain }))
  const dispatch = useDispatch();

  const params = useParams<{ id: string }>();
  const [isLoadingData, setIsLoadingData] = useState(false);

  const creditNotesTableHeader = [
    "CUSTOMER_DETAILS_CREDIT_NOTES_DATE",
    "CUSTOMER_DETAILS_CREDIT_NOTES_CREDIT_NOTE_NUMBER",
    "CUSTOMER_DETAILS_CREDIT_NOTES_AMOUNT_CREDITED",
    "CUSTOMER_DETAILS_CREDIT_NOTES_PAYMENTS",
    "CUSTOMER_DETAILS_CREDIT_NOTES_STATUS"
  ];

  useEffect(() => {
    const fetchData = async () => {
      setIsLoadingData(true)
      try {
        await dispatch(fetchCustomerCreditNotes(currentCompanyDomain, params.id));
        setIsLoadingData(false)

      } catch (err) {
        console.log(err);
        setIsLoadingData(false)
      }
    };

    fetchData();
    return () => { dispatch(resetCustomerDetailsCreditNotes()) };
  }, []);

  const renderTableDataContents = () => {
    return creditNotes.map((data: ICustomerCreditNoteData, idx) => (
    <React.Fragment key={idx}>
      <tr>
        <Text shouldTranslate={false} content={moment(data.createdOn).format("DD MMM YYYY")} component="td" noMargin />
        <Text shouldTranslate={false} content={data.invoiceNumber} component="td" noMargin />
        <Text shouldTranslate={false} content={`-${data.amount}`} component="td" noMargin />
        <Text className="credit-notes-table__collapsible-transactions" content={"CUSTOMER_DETAILS_INVOICES_LINKED_TRANSACTIONS"}
          translateWith={{ transactionCount: data.linkedTransactionsCount }} component="td" noMargin
        />
        <td>
          <div className="credit-notes-table__status-btn-wrapper">
            <StatusLabel
              content={getCreditNoteStatusText(data.status)}
              color={getCreditNoteStatusColor(data.status)}
            />
            <Button
              id="view-credit-note"
              icon="fas fa-eye"
              className="credit-notes-table__view-btn"
              title="CUSTOMER_DETAILS_CREDIT_NOTES_VIEW"
              buttonType="add"
              link={`/credit-notes/${data.invoiceNumber}/customer/${params.id}`}
            />
          </div>
        </td>
      </tr>
      <tr className="expanded">
        <InvoicesPaymentsTable invoiceNumber={data.invoiceNumber} />
      </tr>
    </React.Fragment>

    ));
  };

  if(isLoadingData) {
    return <ProgressIndicator color="blue"></ProgressIndicator>
  }

  if (!creditNotes || creditNotes.length === 0) {
    return null;
  }

  return (
    <Table expandable className="credit-notes-table">
      <thead>
        <tr>
          {creditNotesTableHeader.map(content => (
            <Text key={content} content={content} component="th" noMargin />
          ))}
        </tr>
      </thead>
      <tbody>{renderTableDataContents()}</tbody>
    </Table>
  );
};


export default CreditNotesTable;
