import API from "../utils/API";
import { makeActionCreator, makeGenericActionCreatorTypeSafe } from "../utils/reduxUtils";
import { GetPlanCurrenciesRequest, GetCurrencyPriceModelsRequest, AddonPriceModel, AddonFilterType, CreateAddOnRequest, AddOnPlan, GetAddonRequest, Currency, GetAddonsRequest, UpsertAddonPriceModel, UpdateAddonRequest, DeleteAddonRequest, UpsertAddonUnitTier } from "../utils/grpc/generated/Billsby.Protos/core/private/addons/addons_pb";
import { AddOnsServiceClient } from "../utils/grpc/generated/Billsby.Protos/core/private/addons/AddonsServiceClientPb";
import { GetAllowancesRequest, AllowanceFilterType, AllowancePriceModel, GetCurrencyPriceModelsRequest as GetCurrencyPriceModelsRequestAllowances, UpdateAllowanceRequest, CreateAllowanceRequest, UpsertAllowancePlan, UpsertAllowancePriceModel, GetAllowanceRequest, DeleteAllowanceRequest } from "../utils/grpc/generated/Billsby.Protos/core/private/allowances/allowances_pb";
import { AllowancesServiceClient } from "../utils/grpc/generated/Billsby.Protos/core/private/allowances/AllowancesServiceClientPb";
import { Counter as CounterCreate } from "../utils/grpc/generated/Billsby.Protos/core/private/counters/counters_pb";
import { ISelectedCompanyPlans } from "../reducers/add-ons/selectAddOnPlanReducer";
import { grpcUnaryCall } from "../utils/grpc/grpcUtils";
import { PricingModelType } from "../models/Product";
import { ICurrencyPriceModels } from "../reducers/add-ons/addOnCostReducer";
import { getPricingModelTypeGrpc, multiplyNumberBy100 } from "../utils/commonUtils";
import { IAddOnsReducerState } from "../reducers/add-ons/addOnsReducer";
import { PAGINATION_PAGE_SIZE } from "../utils/constants";
import { Int32Value } from "google-protobuf/google/protobuf/wrappers_pb";
import { ConfigConstants } from "../utils/config";
import { AddonsAllowancesScreen } from "../models/AddonsAllowances";
import { CounterServiceClient } from "../utils/grpc/generated/Billsby.Protos/core/private/counters/CountersServiceClientPb";
import { GetCountersRequest, CreateCounterRequest } from "../utils/grpc/generated/Billsby.Protos/core/private/counters/counters_pb";
import { ICreateAddOnsReducerState } from "../reducers/add-ons/create-addons/createAddOnsReducer";

export const FETCH_COMPANY_PRODUCTS_WITH_PLANS_REQUEST = "FETCH_COMPANY_PRODUCTS_WITH_PLANS_REQUEST";
export const FETCH_COMPANY_PRODUCTS_WITH_PLANS_SUCCESS = "FETCH_COMPANY_PRODUCTS_WITH_PLANS_SUCCESS";
export const FETCH_COMPANY_PRODUCTS_WITH_PLANS_FAILURE = "FETCH_COMPANY_PRODUCTS_WITH_PLANS_FAILURE";

export const FETCH_CURRENCIES_OF_PLANS_REQUEST = "FETCH_CURRENCIES_OF_PLANS_REQUEST";
export const FETCH_CURRENCIES_OF_PLANS_SUCCESS = "FETCH_CURRENCIES_OF_PLANS_SUCCESS";
export const FETCH_CURRENCIES_OF_PLANS_FAILURE = "FETCH_CURRENCIES_OF_PLANS_FAILURE";

export const FETCH_CURRENCY_PRICE_MODELS_REQUEST = "FETCH_CURRENCY_PRICE_MODELS_REQUEST";
export const FETCH_CURRENCY_PRICE_MODELS_SUCCESS = "FETCH_CURRENCY_PRICE_MODELS_SUCCESS";
export const FETCH_CURRENCY_PRICE_MODELS_FAILURE = "FETCH_CURRENCY_PRICE_MODELS_FAILURE";

export const SET_CREATE_ADD_ONS_BASIC_INFO = "SET_CREATE_ADD_ONS_BASIC_INFO";

//type AllowedAddOnsReducerFields = "name" | "displayName" | "description" | "singleUnitName" | "pluralUnitName" | "helpTextTitleBasicForm" | "helpTextContentBasicForm"
 // | "selectedPricingModel" | "pricingModels" | "hasSelectedPricingModel" | "helpTextTitlePricingModel" | "helpTextContentPricingModel" | "selectedCounter";

export const setCreateAddOnsBasicInfo = makeGenericActionCreatorTypeSafe<ICreateAddOnsReducerState>(SET_CREATE_ADD_ONS_BASIC_INFO);


export const SET_SHOW_SELECT_PLAN_MODAL = "SET_SHOW_SELECT_PLAN_MODAL";
export const setShowSelectPlanModal = makeActionCreator(SET_SHOW_SELECT_PLAN_MODAL, "payload");

export const SET_ADD_ONS_FORM_COMPLETED = "SET_ADD_ONS_FORM_COMPLETED";
export const setAddOnsFormCompleted = makeActionCreator(SET_ADD_ONS_FORM_COMPLETED, "stageIndex", "value");

export const SET_ADD_ONS_FORM_HAS_ERROR = "SET_ADD_ONS_FORM_HAS_ERROR";
export const setAddOnsFormHasError = makeActionCreator(SET_ADD_ONS_FORM_HAS_ERROR, "stageIndex", "value");

export const SET_ADD_ONS_STEPS_COLLAPSED = "SET_ADD_ONS_STEPS_COLLAPSED";
export const setAddOnsStepsCollapsed = makeActionCreator(SET_ADD_ONS_STEPS_COLLAPSED, "payload");

export const SET_ADD_ONS_SELECTED_PLAN = "SET_ADD_ONS_SELECTED_PLAN";
export const setAddOnsSelectedPlan = makeActionCreator(SET_ADD_ONS_SELECTED_PLAN, "payload");

export const SET_ADD_ONS_SELECTED_PLANS_ID = "SET_ADD_ONS_SELECTED_PLANS_ID";
export const setAddOnsSelectedPlansId = makeActionCreator(SET_ADD_ONS_SELECTED_PLANS_ID, "payload");

export const SET_PLAN_FORCE_ADD_ON = "SET_PLAN_FORCE_ADD_ON";
export const setPlanForceAddOn = makeActionCreator(SET_PLAN_FORCE_ADD_ON, "payload");

export const REMOVE_ADD_ONS_SELECTED_PLAN = "REMOVE_ADD_ONS_SELECTED_PLAN";
export const removeAddOnsSelectedPlan = makeActionCreator(REMOVE_ADD_ONS_SELECTED_PLAN, "payload");

export const DESELECT_ALL_ADD_ONS_SELECTED_PLAN = "DESELECT_ALL_ADD_ONS_SELECTED_PLAN";
export const deselectAllAddOnsSelectedPlan = makeActionCreator(DESELECT_ALL_ADD_ONS_SELECTED_PLAN, "payload");

export const SELECT_ALL_ADD_ONS_SELECTED_PLAN = "SELECT_ALL_ADD_ONS_SELECTED_PLAN";
export const selectAllAddOnsSelectedPlan = makeActionCreator(SELECT_ALL_ADD_ONS_SELECTED_PLAN, "payload");

export const SET_SELECTED_ADD_ONS_PLANS_PAGE = "SET_SELECTED_ADD_ONS_PLANS_PAGE";
export const setSelectedAddOnsPlansPage = makeActionCreator(SET_SELECTED_ADD_ONS_PLANS_PAGE, "payload");

export const SET_SELECTED_COMPANY_PLANS_PAGE = "SET_SELECTED_COMPANY_PLANS_PAGE";
export const setSelectedCompanyPlansPage = makeActionCreator(SET_SELECTED_COMPANY_PLANS_PAGE, "payload");

export const SET_SEARCH_KEY_ADD_ONS_PLANS_PAGE = "SET_SEARCH_KEY_ADD_ONS_PLANS_PAGE";
export const setSearchKeyAddOnsPlansPage = makeActionCreator(SET_SEARCH_KEY_ADD_ONS_PLANS_PAGE, "payload");

export const CANCEL_SELECTED_ADD_ONS_PLANS_PAGE = "CANCEL_SELECTED_ADD_ONS_PLANS_PAGE";
export const cancelSelectedAddOnsPlans = makeActionCreator(CANCEL_SELECTED_ADD_ONS_PLANS_PAGE, "payload");

export const CREATE_ADD_ONS_ADD_FEATURE_TAGS = "CREATE_ADD_ONS_ADD_FEATURE_TAGS";
export const createAddOnsAddFeatureTags = makeActionCreator(CREATE_ADD_ONS_ADD_FEATURE_TAGS, "payload");

export const CREATE_ADD_ONS_REMOVE_FEATURE_TAGS = "CREATE_ADD_ONS_REMOVE_FEATURE_TAGS";
export const createAddOnsRemoveFeatureTags = makeActionCreator(CREATE_ADD_ONS_REMOVE_FEATURE_TAGS, "payload");

export const SET_ADD_ONS_COST_CURRENT_PAGE = "SET_ADD_ONS_COST_CURRENT_PAGE";
export const setAddOnsCostCurrentPage = makeActionCreator(SET_ADD_ONS_COST_CURRENT_PAGE, "payload");

export const SET_ADD_ONS_COST_CURRENCY_PRICEMODELS = "SET_ADD_ONS_COST_CURRENCY_PRICEMODELS";
export const setAddOnsCostCurrencyPriceModels = makeActionCreator(SET_ADD_ONS_COST_CURRENCY_PRICEMODELS, "payload");

export const SET_HAS_CONFIGURED_ALL_PRICING_MODELS = "SET_HAS_CONFIGURED_ALL_PRICING_MODELS";
export const setHasConfiguredAllPricingModels = makeActionCreator(SET_HAS_CONFIGURED_ALL_PRICING_MODELS, "payload");

// Actions for add-ons list
export const FETCH_ADD_ONS_LIST_REQUEST = "FETCH_ADD_ONS_LIST_REQUEST";
export const FETCH_ADD_ONS_LIST_SUCCESS = "FETCH_ADD_ONS_LIST_SUCCESS";
export const FETCH_ADD_ONS_LIST_FAILURE = "FETCH_ADD_ONS_LIST_FAILURE";

export const SET_ADD_ONS_FIELDS = "SET_ADD_ONS_FIELDS";
export const setAddOnsFields = makeGenericActionCreatorTypeSafe<IAddOnsReducerState>(SET_ADD_ONS_FIELDS)

export const CREATE_ADD_ON_REQUEST = "CREATE_ADD_ON_REQUEST";
export const CREATE_ADD_ON_SUCCESS = "CREATE_ADD_ON_SUCCESS";
export const CREATE_ADD_ON_FAILURE = "CREATE_ADD_ON_FAILURE";

export const UPDATE_ADD_ON_REQUEST = "UPDATE_ADD_ON_REQUEST";
export const UPDATE_ADD_ON_SUCCESS = "UPDATE_ADD_ON_SUCCESS";
export const UPDATE_ADD_ON_FAILURE = "UPDATE_ADD_ON_FAILURE";

export const FETCH_ADD_ON_REQUEST = "FETCH_ADD_ON_REQUEST";
export const FETCH_ADD_ON_SUCCESS = "FETCH_ADD_ON_SUCCESS";
export const FETCH_ADD_ON_FAILURE = "FETCH_ADD_ON_FAILURE";

export const DELETE_ADD_ON_REQUEST = "DELETE_ADD_ON_REQUEST";
export const DELETE_ADD_ON_SUCCESS = "DELETE_ADD_ON_SUCCESS";
export const DELETE_ADD_ON_FAILURE = "DELETE_ADD_ON_FAILURE";

export const FETCH_COUNTERS_REQUEST = "FETCH_COUNTERS_REQUEST";
export const FETCH_COUNTERS_SUCCESS = "FETCH_COUNTERS_SUCCESS";
export const FETCH_COUNTERS_FAILURE = "FETCH_COUNTERS_FAILURE";

export const CREATE_COUNTER_REQUEST = "CREATE_COUNTER_REQUEST";
export const CREATE_COUNTER_SUCCESS = "CREATE_COUNTER_SUCCESS";
export const CREATE_COUNTER_FAILURE = "CREATE_COUNTER_FAILURE";

export const RESET_ADD_ON_COST_STATE = "RESET_ADD_ON_COST_STATE";
export const resetAddOnCostState = makeActionCreator(RESET_ADD_ON_COST_STATE);

export const RESET_ADD_ONS_LIST = "RESET_ADD_ONS_LIST";
export const resetAddOnsList = makeActionCreator(RESET_ADD_ONS_LIST);

export const RESET_CREATE_ADD_ON_STATE = "RESET_CREATE_ADD_ON_STATE";
export const resetCreateAddOnState = makeActionCreator(RESET_CREATE_ADD_ON_STATE);

export const ADD_ON_IMAGE_UPLOAD_REQUEST = "ADD_ON_IMAGE_UPLOAD_REQUEST";
export const ADD_ON_IMAGE_UPLOAD_SUCCESS = "ADD_ON_IMAGE_UPLOAD_SUCCESS";
export const ADD_ON_IMAGE_UPLOAD_FAILURE = "ADD_ON_IMAGE_UPLOAD_FAILURE";

export const fetchCompanyProductsWithPlans = function (companyId: number) {
  return {
    types: [FETCH_COMPANY_PRODUCTS_WITH_PLANS_REQUEST, FETCH_COMPANY_PRODUCTS_WITH_PLANS_SUCCESS, FETCH_COMPANY_PRODUCTS_WITH_PLANS_FAILURE],
    callAPI: () => API.fetchAllProducts(companyId, true)
  }
}

export const fetchCurrenciesOfPlans = (companyDomain: string, planIds: Array<number>) => {
  const getPlanCurrenciesRequest = new GetPlanCurrenciesRequest();
  getPlanCurrenciesRequest.setCompanyDomain(companyDomain);
  getPlanCurrenciesRequest.setPlanIdsList(planIds);

  const addonServiceClient = new AddOnsServiceClient(ConfigConstants.grpcBaseUrl);
  return {
    types: [FETCH_CURRENCIES_OF_PLANS_REQUEST, FETCH_CURRENCIES_OF_PLANS_SUCCESS, FETCH_CURRENCIES_OF_PLANS_FAILURE],
    callAPI: () => grpcUnaryCall(getPlanCurrenciesRequest, addonServiceClient, addonServiceClient.getPlanCurrencies)
  }
}

export const fetchCurrencyPriceModels = (companyDomain: string, selectedCurrency: string, planIds: Array<number>, selectedPage = 1, pageSize = 10,
  type: AddonsAllowancesScreen) => {
  const isAddonScreen = type === AddonsAllowancesScreen.ADDONS;

  if (isAddonScreen) {
    const getCurrencyPriceModels = new GetCurrencyPriceModelsRequest();
    getCurrencyPriceModels.setCompanyDomain(companyDomain);
    getCurrencyPriceModels.setCurrencyIso3Code(selectedCurrency);
    getCurrencyPriceModels.setPlanIdsList(planIds);
    const addonServiceClient = new AddOnsServiceClient(ConfigConstants.grpcBaseUrl);
    return {
      types: [FETCH_CURRENCY_PRICE_MODELS_REQUEST, FETCH_CURRENCY_PRICE_MODELS_SUCCESS, FETCH_CURRENCY_PRICE_MODELS_FAILURE],
      callAPI: () => grpcUnaryCall(getCurrencyPriceModels, addonServiceClient, addonServiceClient.getCurrencyPriceModels),
      payload: { selectedCurrency, selectedPage }
    }
  }
  else {
    const getCurrencyPriceModels = new GetCurrencyPriceModelsRequestAllowances();
    getCurrencyPriceModels.setCompanyDomain(companyDomain);
    getCurrencyPriceModels.setCurrencyIso3Code(selectedCurrency);
    getCurrencyPriceModels.setPlanIdsList(planIds);
    const allowancesServiceClient = new AllowancesServiceClient(ConfigConstants.grpcBaseUrl);
    return {
      types: [FETCH_CURRENCY_PRICE_MODELS_REQUEST, FETCH_CURRENCY_PRICE_MODELS_SUCCESS, FETCH_CURRENCY_PRICE_MODELS_FAILURE],
      callAPI: () => grpcUnaryCall(getCurrencyPriceModels, allowancesServiceClient, allowancesServiceClient.getCurrencyPriceModels),
      payload: { selectedCurrency, selectedPage }
    }
  }

}

export const createUpdateAddOn = (addOnId: number | null, companyDomain: string, name: string, displayName: string, description: string, singleUnitName: string,
  pluralUnitName: string, plans: Array<ISelectedCompanyPlans>, pricingModel: PricingModelType, currencyPriceModels: ICurrencyPriceModels,
  selectedCounter: { label: string, value: CounterCreate }, tags: Array<string>, type: AddonsAllowancesScreen, isImageEnabled: boolean, imageFilename: string) => {

  const isAddonScreen = type === AddonsAllowancesScreen.ADDONS;

  let addOnRequest: CreateAddOnRequest | UpdateAddonRequest | CreateAllowanceRequest | UpdateAllowanceRequest;
  if (isAddonScreen) {
    addOnRequest = addOnId ? new UpdateAddonRequest() : new CreateAddOnRequest();
  } else {
    addOnRequest = addOnId ? new UpdateAllowanceRequest() : new CreateAllowanceRequest();
  }

  if (addOnId && isAddonScreen) {
    (addOnRequest as UpdateAddonRequest).setAddonId(addOnId);
  }
  if (addOnId && !isAddonScreen) {
    (addOnRequest as UpdateAllowanceRequest).setAllowanceId(addOnId);
  }

  addOnRequest.setCompanyDomain(companyDomain);
  addOnRequest.setName(name);
  addOnRequest.setDisplayName(displayName);
  addOnRequest.setDescription(description);
  addOnRequest.setSingleUnitName(singleUnitName);
  addOnRequest.setPluralUnitName(pluralUnitName);
  addOnRequest.setIsImageEnabled(isImageEnabled);
  addOnRequest.setImageFilename(imageFilename);

  if (isAddonScreen) {
    (addOnRequest as CreateAddOnRequest | UpdateAddonRequest).setPlansList(plans.map(plan => {
      const addonPlan = new AddOnPlan();
      addonPlan.setPlanId(plan.planId);
      addonPlan.setForceAddon(plan.isForced);
      addonPlan.setPlanName(plan.plan);
      addonPlan.setProductName(plan.product);
      return addonPlan;
    }));
    
    (addOnRequest as CreateAddOnRequest | UpdateAddonRequest).setIsImageEnabled(isImageEnabled);
    (addOnRequest as CreateAddOnRequest | UpdateAddonRequest).setImageFilename(imageFilename);

  } else {
    (addOnRequest as CreateAllowanceRequest | UpdateAllowanceRequest).setPlansList(plans.map(plan => {
      const allowancePlan = new UpsertAllowancePlan();
      allowancePlan.setPlanId(plan.planId);
      allowancePlan.setForceAllowance(plan.isForced);
      return allowancePlan;
    }))
  }

  addOnRequest.setPricingModelType(getPricingModelTypeGrpc(pricingModel));

  let allPriceModels: Array<UpsertAddonPriceModel | UpsertAllowancePriceModel> = [];
  for (let [currency, priceModels] of Object.entries(currencyPriceModels)) {
    for (let priceModel of priceModels) {
      const upsertPriceModel = isAddonScreen ? new UpsertAddonPriceModel() : new UpsertAllowancePriceModel();
      upsertPriceModel.setCurrencyIso3Code((priceModel.getCurrency() || new Currency()).getIso3Code());
      isAddonScreen && (upsertPriceModel as UpsertAddonPriceModel).setFlatFeePrice(multiplyNumberBy100((priceModel as AddonPriceModel).getFlatFeePrice()));
      if(isAddonScreen) {
        (upsertPriceModel as UpsertAddonPriceModel).setPerUnitPrice(multiplyNumberBy100((priceModel as AddonPriceModel).getPerUnitPrice()));
      }
      else {
        (upsertPriceModel as UpsertAllowancePriceModel).setPerUnitPrice(multiplyNumberBy100((priceModel as AllowancePriceModel).getPerUnitPrice()) || "");
      }
      
      upsertPriceModel.setFrequency(priceModel.getFrequency());
      upsertPriceModel.setFrequencyType(priceModel.getFrequencyType());
      upsertPriceModel.setId(priceModel.getId());
      !isAddonScreen && (upsertPriceModel as UpsertAllowancePriceModel).setIncludedUnits((priceModel as AllowancePriceModel).getIncludedUnits())
      upsertPriceModel.setTiersList(priceModel.getTiersList().map(tier => {
        const upsertAddonUnitTier  = new UpsertAddonUnitTier();
        upsertAddonUnitTier.setStart(tier.getStart());
        upsertAddonUnitTier.setFinish(tier.getFinish());
        upsertAddonUnitTier.setPrice(Math.round(tier.getPrice() * 100));
        return upsertAddonUnitTier;
      }));
      //console.log(upsertPriceModel.toObject());
      allPriceModels.push(upsertPriceModel);
    }
  }

  if (isAddonScreen) {
    (addOnRequest as CreateAddOnRequest | UpdateAddonRequest).setPriceModelsList(allPriceModels as Array<UpsertAddonPriceModel>);
  }
  else {
    (addOnRequest as CreateAllowanceRequest | UpdateAllowanceRequest).setPriceModelsList(allPriceModels as Array<UpsertAllowancePriceModel>);
  }

  if (!isAddonScreen) {
    (addOnRequest as CreateAllowanceRequest | UpdateAllowanceRequest).setCounterId(selectedCounter.value.getCounterId());
  }

  addOnRequest.setFeatureTagsList(tags);
  const addonServiceClient = new AddOnsServiceClient(ConfigConstants.grpcBaseUrl);
  const allowancesServiceClient = new AllowancesServiceClient(ConfigConstants.grpcBaseUrl);

  let action: any = {
    types: addOnId ? [UPDATE_ADD_ON_REQUEST, UPDATE_ADD_ON_SUCCESS, UPDATE_ADD_ON_FAILURE] : [CREATE_ADD_ON_REQUEST, CREATE_ADD_ON_SUCCESS, CREATE_ADD_ON_FAILURE]
  }

  if (isAddonScreen) {
    action.callAPI = () => grpcUnaryCall(addOnRequest, addonServiceClient, addOnId ? addonServiceClient.updateAddon : addonServiceClient.createAddOn)
  }
  else {
    action.callAPI = () => grpcUnaryCall(addOnRequest, allowancesServiceClient, addOnId ? allowancesServiceClient.updateAllowance : allowancesServiceClient.createAllowance)
  }
  //console.log(JSON.stringify(addOnRequest.toObject(), null, 2));
  return action;
}

export const fetchAddOn = (companyDomain: string, addOnId: number, type: AddonsAllowancesScreen) => {
  const isAddonScreen = type === AddonsAllowancesScreen.ADDONS;
  let action: any = {
    types: [FETCH_ADD_ON_REQUEST, FETCH_ADD_ON_SUCCESS, FETCH_ADD_ON_FAILURE]
  }

  if (isAddonScreen) {
    const getAddOnRequest = new GetAddonRequest();
    getAddOnRequest.setCompanyDomain(companyDomain);
    getAddOnRequest.setAddonId(addOnId);
    const addonServiceClient = new AddOnsServiceClient(ConfigConstants.grpcBaseUrl);
    action.callAPI = () => grpcUnaryCall(getAddOnRequest, addonServiceClient, addonServiceClient.getAddon)
  }
  else {
    const getAllowancesRequest = new GetAllowanceRequest();
    getAllowancesRequest.setCompanyDomain(companyDomain);
    getAllowancesRequest.setAllowanceId(addOnId);
    const allowancesServiceClient = new AllowancesServiceClient(ConfigConstants.grpcBaseUrl);
    action.callAPI = () => grpcUnaryCall(getAllowancesRequest, allowancesServiceClient, allowancesServiceClient.getAllowance)
  }
  action.payload = { fetchType: type }

  return action;
}

export const deleteAddOn = (companyDomain: string, addOnId: number, type: AddonsAllowancesScreen) => {
  const isAddonScreen = type === AddonsAllowancesScreen.ADDONS;
  let action: any = {
    types: [DELETE_ADD_ON_REQUEST, DELETE_ADD_ON_SUCCESS, DELETE_ADD_ON_FAILURE]
  }
  
  if(isAddonScreen) {
    const deleteAddOnRequest = new DeleteAddonRequest();
    deleteAddOnRequest.setCompanyDomain(companyDomain);
    deleteAddOnRequest.setAddonId(addOnId);
    const addOnServiceClient = new AddOnsServiceClient(ConfigConstants.grpcBaseUrl);
    action.callAPI = () => grpcUnaryCall(deleteAddOnRequest, addOnServiceClient, addOnServiceClient.deleteAddon)
  }
  else {
    const deleteAllowanceRequest = new DeleteAllowanceRequest();
    deleteAllowanceRequest.setCompanyDomain(companyDomain);
    deleteAllowanceRequest.setAllowanceId(addOnId);
    const allowanceServiceClient = new AllowancesServiceClient(ConfigConstants.grpcBaseUrl);
    action.callAPI = () => grpcUnaryCall(deleteAllowanceRequest, allowanceServiceClient, allowanceServiceClient.deleteAllowance)
  }

  return action;
}

export const fetchAddOnsList = (companyDomain: string, page: number, search: string, filter: AddonFilterType | AllowanceFilterType | null,
  type: AddonsAllowancesScreen) => {

  const pageVal = new Int32Value();
  pageVal.setValue(page)
  const paginationSize = new Int32Value();
  paginationSize.setValue(PAGINATION_PAGE_SIZE);

  let action: any = {
    types: [FETCH_ADD_ONS_LIST_REQUEST, FETCH_ADD_ONS_LIST_SUCCESS, FETCH_ADD_ONS_LIST_FAILURE]
  }

  if (type === AddonsAllowancesScreen.ADDONS) {
    const getAddOns = new GetAddonsRequest();
    getAddOns.setCompanyDomain(companyDomain);
    getAddOns.setPage(pageVal);
    getAddOns.setPageSize(paginationSize);
    getAddOns.setSearch(search);
    getAddOns.setAddonFilterType((filter as AddonFilterType) || AddonFilterType.UNSPECIFIED_AFT);
    const addonServiceClient = new AddOnsServiceClient(ConfigConstants.grpcBaseUrl);
    action.callAPI = () => grpcUnaryCall(getAddOns, addonServiceClient, addonServiceClient.getAddons);
    action.payload = { fetchType: AddonsAllowancesScreen.ADDONS }
  }
  else {
    const getAllowancesRequest = new GetAllowancesRequest();
    getAllowancesRequest.setCompanyDomain(companyDomain);
    getAllowancesRequest.setPage(pageVal);
    getAllowancesRequest.setPageSize(paginationSize);
    getAllowancesRequest.setSearch(search);
    getAllowancesRequest.setAllowanceFilterType((filter as AllowanceFilterType) || AllowanceFilterType.UNSPECIFIED_AFT);
    const allowancesServiceClient = new AllowancesServiceClient(ConfigConstants.grpcBaseUrl);
    action.callAPI = () => grpcUnaryCall(getAllowancesRequest, allowancesServiceClient, allowancesServiceClient.getAllowances);
    //action.callAPI = () => Promise.resolve(mockAllowances());
    action.payload = { fetchType: AddonsAllowancesScreen.ALLOWANCES }
  }


  return action;
}

export const fetchCounters = (companyDomain: string) => {
  const getCountersRequest = new GetCountersRequest();
  getCountersRequest.setCompanyDomain(companyDomain);
  const counterServiceClient = new CounterServiceClient(ConfigConstants.grpcBaseUrl);
  return {
    types: [FETCH_COUNTERS_REQUEST, FETCH_COUNTERS_SUCCESS, FETCH_COUNTERS_FAILURE],
    callAPI: () => grpcUnaryCall(getCountersRequest, counterServiceClient, counterServiceClient.getCounters)
  }
}

export const createCounter = (companyDomain: string, counterName: string) => {
  const createCounterRequest = new CreateCounterRequest();
  createCounterRequest.setCompanyDomain(companyDomain);
  createCounterRequest.setCounterName(counterName);
  const counterServiceClient = new CounterServiceClient(ConfigConstants.grpcBaseUrl);
  return {
    types: [CREATE_COUNTER_REQUEST, CREATE_COUNTER_SUCCESS, CREATE_COUNTER_FAILURE],
    callAPI: () => grpcUnaryCall(createCounterRequest, counterServiceClient, counterServiceClient.createCounter)
  }
}

export const uploadAddOnImage = (currentCompanyDomain: string, files: Array<File>, isAddon: boolean) => {
  const type = isAddon ? "Addon" : "Allowance";
  return {
    types: [ADD_ON_IMAGE_UPLOAD_REQUEST, ADD_ON_IMAGE_UPLOAD_SUCCESS, ADD_ON_IMAGE_UPLOAD_FAILURE],
    callAPI: () => API.uploadCompanyImage(currentCompanyDomain, files[0], type)
  }
}