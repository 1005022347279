import React, { useEffect, useState } from "react";
import Panel from "../../ui/panel/Panel";
import Text from "../../ui/text/Text";
import Button from "../../ui/button/Button";
import FormLabel from "../../ui/form-label/FormLabel";
import FormGroup from "../../ui/form-group/FormGroup";
import Dropdown from "../../ui/dropdown/Dropdown";
import { Row, Col } from "react-grid-system";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../../..";
import counterpart from "counterpart";
import { SubscriptionStatusType } from "../../../models/Subscriptions";
import { setSubscriptionDetailsField, updateSubscriptionStatus } from "../../../actions/subscriptionDetailsActions";
import NoticePanel from "../../ui/notice-panel/NoticePanel";
import "./SubscriptionUpdateStatus.scss";
import { SubscriptionStatus } from "../../../utils/grpc/generated/Billsby.Protos/billing/private/subscription/subscription_pb";
import API from "../../../utils/API";
import { IInvoice, InvoiceStatus } from "../../../models/Invoices";
import ProgressIndicator from "../../ui/progress-indicator/ProgressIndicator";

interface ISubscriptionUpdateStatus {
  handleCallback?: () => void
}

const SubscriptionUpdateStatus: React.FC<ISubscriptionUpdateStatus> = ({ handleCallback }) => {
  const authData = useSelector((state: AppState) => state.auth);
  const subscriptionData = useSelector((state: AppState) => state.subscriptionDetailsReducer);

  const dispatch = useDispatch<Function>();

  const { newSubscriptionStatus, updateStatusRequest, updateStatusSuccess, updateStatusError, updateStatusErrorMessage, subscriptionUniqueId, status } = subscriptionData;
  const { currentCompanyId, currentCompanyDomain, parsedToken } = authData;

  const [isLoading, setIsLoading] = useState(false);

  const options = status === SubscriptionStatusType.ACTIVE
    ? [{ value: SubscriptionStatus.SUSPENDED, label: counterpart("UPDATE_STATUS_SUSPENDED") }]
    : [{ value: SubscriptionStatus.ACTIVE, label: counterpart("UPDATE_STATUS_ACTIVE") }]

  const [hasRecentUnpaidInvoice, setHasRecentUnpaidInvoice] = useState(false);

  useEffect(() => {
    const fetchRenewalInvoices = async () => {
      setIsLoading(true);
      try {
        const { results } = await API.fetchInvoices(currentCompanyDomain, 1, 15, "", undefined, true, subscriptionUniqueId, false) as { results: IInvoice[] };
        if (results.length && results.some(i => i.status === InvoiceStatus.FAILED || i.status === InvoiceStatus.UNPAID)) {
          setHasRecentUnpaidInvoice(true)
        } else {
          setHasRecentUnpaidInvoice(false)
        }
      } finally {
        setIsLoading(false)
      }
    }

    fetchRenewalInvoices();

  }, [])

  useEffect(() => {
    if (updateStatusSuccess) {
      handleCallback && handleCallback();
    }

    return () => {
      dispatch(setSubscriptionDetailsField("updateStatusSuccess", false));
      dispatch(setSubscriptionDetailsField("updateStatusErrorMessage", undefined));
      dispatch(setSubscriptionDetailsField("newSubscriptionStatus", undefined));
    }
  }, [updateStatusSuccess])

  return (
    <div className="update-status">
      {updateStatusError && updateStatusErrorMessage && <NoticePanel
        type="error"
        isModal
        shouldTranslate={false}
        content={updateStatusErrorMessage}
      />}
      {hasRecentUnpaidInvoice && <NoticePanel
        type="error"
        isModal
        content={"UPDATE_STATUS_INVOICE_ERROR"}
      />}
      <Panel className="update-status__title-panel" title="UPDATE_STATUS">
        <Text
          className="update-status__title-text"
          content="UPDATE_STATUS_CONTENT"
          translateWith={{ status }}
          noMargin
        />
      </Panel>
      {
        isLoading ? <ProgressIndicator color='blue' coverage='normal'></ProgressIndicator> :
          <>
            <Panel>
              <FormGroup noMargin>
                <Row align='center'>
                  <Col md={2}>
                    <FormLabel target='new-status' content='UPDATE_STATUS_LABEL' noMargin />
                  </Col>
                  <Col md={10} style={{ pointerEvents: hasRecentUnpaidInvoice ? "none" : "unset" }}>
                    <Dropdown
                      id='new-status'
                      className="update-status__date-status"
                      value={newSubscriptionStatus}
                      placeholder={counterpart("CHANGE_BILL_DATE_SELECT_STATUS")}
                      onChange={(status: any) => {
                        dispatch(setSubscriptionDetailsField("newSubscriptionStatus", status));
                      }} options={options}></Dropdown>
                  </Col>
                </Row>
              </FormGroup>

            </Panel>
            <Button
              title="UPDATE_STATUS_BTN"
              id="update-status"
              isLoading={updateStatusRequest}
              disabled={!newSubscriptionStatus || status === SubscriptionStatusType.INTRIAL || status === SubscriptionStatusType.PAUSED || status === SubscriptionStatusType.CANCELLED || hasRecentUnpaidInvoice}
              isFullWidth
              onClick={() => {
                !hasRecentUnpaidInvoice && newSubscriptionStatus && currentCompanyId && parsedToken && dispatch(updateSubscriptionStatus(currentCompanyId, subscriptionUniqueId, newSubscriptionStatus.value, Number(parsedToken.nameid)));
              }} />
          </>
      }
    </div>
  )
}

export default SubscriptionUpdateStatus;
