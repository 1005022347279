import React, { useState, useEffect, useMemo } from "react";
import Table from "../../../components/ui/table/Table";
import PageWrapper from "../../../components/ui/layout/PageWrapper";
import NavigationAlert from "../../../components/ui/navigation-alert/NavigationAlert";
import counterpart from "counterpart";
import NavigationBar from "../../../components/ui/navigation-bar/NavigationBar";
import { useHistory } from "react-router-dom";
import Text from "../../../components/ui/text/Text";
import Dropdown from "../../../components/ui/dropdown/Dropdown";
import "./RevenueReport.scss";
import Button from "../../../components/ui/button/Button";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../../..";
import moment from "moment";
import { downloadCSV, getMonthYearDates } from "../../../utils/commonUtils";
import LoadingModal from "../../../components/modal/loading-modal/LoadingModal";
import MonthPicker from "../../../components/ui/month-picker/MonthPicker";
import ProgressIndicator from "../../../components/ui/progress-indicator/ProgressIndicator";
import { fetchRevenueReport, resetRevenueReport, setRevenueReportField } from "../../../actions/revenueReportActions";
import { RevenueReportFilterType } from "../../../models/Reports";
import { GetInvoiceRevenueResponse, GetPlanRevenueResponse, GetProductRevenueResponse, GetSubscriptionRevenueResponse, InvoiceMonthReport, PlanMonthReport, ProductMonthReport, SubscriptionMonthReport } from "../../../utils/grpc/generated/Billsby.Protos/revenuerecognition/private/reports/reports_pb";

interface IRevenueReport {
  revenueType: "revenue" | "deferred",
}

const RevenueReport: React.FC<IRevenueReport> = ({ revenueType }) => {
  const auth = useSelector((state: AppState) => state.auth);
  const revenueReport = useSelector((state: AppState) => state.revenueReportReducer);
  
  const dispatch = useDispatch<Function>();
  const history = useHistory();

  const [isLoading, setIsLoading] = useState(false);
  const [isCreatingCSV, setIsCreatingCSV] = useState(false);
  const { startDate, endDate, filterType, details, headerMessage, datesList } = revenueReport;
  const filteredByPlan = details instanceof GetPlanRevenueResponse;
  const filteredByProduct = details instanceof GetProductRevenueResponse;
  const filteredBySubscription = details instanceof GetSubscriptionRevenueResponse;
  const filteredByInvoice = details instanceof GetInvoiceRevenueResponse;

  const getHeaderType = useMemo(() => {
    if (filteredByInvoice) {
      return "REVENUE_DOCUMENT_NO"
    }
    if (filteredBySubscription) {
      return "REVENUE_SUBSCRIPTION"
    }
    return "REVENUE_PRODUCT_NAME"
  }, [details])

  const dateFormat = "MMM YYYY";

  const defaultDateRange: { startDate: string, endDate: string } = useMemo(() => {
    return {
      startDate:  moment.utc().subtract(2, "months").format(dateFormat),
      endDate: moment.utc().format(dateFormat)
    }
  }, []);

  useEffect(() => {
    if (moment.utc(endDate, dateFormat).isBefore(moment.utc(startDate, dateFormat))) {
      dispatch(setRevenueReportField("endDate", startDate));
    }
  }, [endDate, startDate]);

  const filterOptions = [
    { value: RevenueReportFilterType.PRODUCT, label: counterpart("REVENUE_FILTER_BY_PRODUCT") },
    { value: RevenueReportFilterType.PLAN, label: counterpart("REVENUE_FILTER_BY_PLAN") },
    { value: RevenueReportFilterType.SUBSCRIPTION, label: counterpart("REVENUE_FILTER_BY_SUBSCRIPTION") },
    { value: RevenueReportFilterType.INVOICE, label: counterpart("REVENUE_FILTER_BY_INVOICE") },
  ];

  const fetchData = async (startDate: string, endDate: string) => {
    if (!auth.currentCompanyId) {
      return;
    }

    setIsLoading(true);
    dispatch(setRevenueReportField("datesList", getMonthYearDates(startDate, endDate, false)))
    try {
      await dispatch(fetchRevenueReport(auth.currentCompanyId, startDate, endDate, filterType.value, revenueType));
      dispatch(
        setRevenueReportField(
          "headerMessage",
          counterpart(revenueType === "revenue" ? "REVENUE_RECOGNITION_TITLE" : "DEFERRED_REVENUE_TITLE", {
            startDate,
            endDate
          })
        )
      );

      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
      console.log(err);
    }
  };

  useEffect(() => {
    fetchData(defaultDateRange.startDate, defaultDateRange.endDate);

    dispatch(setRevenueReportField("startDate", defaultDateRange.startDate));
    dispatch(setRevenueReportField("endDate", defaultDateRange.endDate));
    dispatch(setRevenueReportField("filterType", filterOptions[0]));

    return () => dispatch(resetRevenueReport());
  }, [defaultDateRange]);

  const renderHeader = () => {
    return (
      <>
        <NavigationBar previousPageTitle="NAVBAR_ACCOUNTING" shouldTranslatePageTitle={false} previousPageCb={() => history.push("/report-accounting")} pageTitle={""} />
        <div className="navigation-alert-wrapper">
          <NavigationAlert
            title={!isLoading ? headerMessage : ""}
            className=""
            shouldTranslateTitle={false}
            type="default"
            subTitle=""
            icon="fal fa-file-download"
            buttonLabel="SUMMARY_BUTTON_CSV"
            buttonDisabled={!details || isLoading}
            onClick={handleDownloadCSV}
            isVisible={true}
          />
        </div>
        <div className="revenue-report__header">
          <div className="revenue-report__filter">
            <MonthPicker value={startDate} onChange={value => dispatch(setRevenueReportField("startDate", value))}></MonthPicker>
            <Text content="ACTIVITY_SUMMARY_TO" className="with-side-margin" component="span" noMargin></Text>
            <MonthPicker value={endDate} minDate={startDate} onChange={value => dispatch(setRevenueReportField("endDate", value))}></MonthPicker>
            <span className="divider divider--vertical with-side-margin"></span>
            <Dropdown
              id="revenue-report-dropdown-3"
              className="dropdown--lg"
              options={filterOptions}
              value={filterType}
              onChange={(selectedType: any) => dispatch(setRevenueReportField("filterType", selectedType))}
            ></Dropdown>
            <Button isLoading={isLoading} id="run-report" icon="fas fa-sync-alt" title="SUMMARY_BUTTON_REPORT" onClick={() => fetchData(startDate, endDate)}></Button>
          </div>
        </div>
      </>
    );
  };

  const getFormattedAmount = (result: PlanMonthReport | ProductMonthReport | SubscriptionMonthReport | InvoiceMonthReport) => {
    if (revenueType === "revenue") {
      return result.getRecognisedRevenueFormattedAmount() || "-"
    }
    return result.getDeferredRevenueFormattedAmount() || "-"
  }


  const renderRevenueReport = () => {
    if (isLoading || !details) {
      return null
    }

    if (filteredByPlan) {
      const results = details as GetPlanRevenueResponse;

      return results.getResultList().map((res, idx) => {
        return (
          <tr className="revenue-report__plan" key={idx}>
            <Text component="td" title={res.getProductName()} shouldTranslate={false} content={res.getProductName()}></Text>
            <Text component="td" title={res.getPlanName()} shouldTranslate={false} content={res.getPlanName()}></Text>
            {
              res.getMonthsList().map((res, idx) => <Text key={idx} component="td" shouldTranslate={false} content={getFormattedAmount(res)}></Text>)
            }
          </tr>
        )
      })
    }

    if (filteredByInvoice) {
      const results = details as GetInvoiceRevenueResponse

      return results.getResultList().map((res, idx) => {
        return (
          <tr className="revenue-report__invoice" key={idx}>
            <Text component="td" shouldTranslate={false} content={res.getInvoiceUniqueId()}></Text>
            {
              res.getMonthsList().map((res, idx) => <Text key={idx} component="td" shouldTranslate={false} content={getFormattedAmount(res)}></Text>)
            }
          </tr>
        )
      })
    }

    if (filteredBySubscription) {
      const results = details as GetSubscriptionRevenueResponse

      return results.getResultList().map((res, idx) => {
        return (
          <tr className="revenue-report__subscription" key={idx}>
            <Text component="td" shouldTranslate={false} content={res.getSubscriptionUniqueId()}></Text>
            {
              res.getMonthsList().map((res, idx) => <Text key={idx} component="td" shouldTranslate={false} content={getFormattedAmount(res)}></Text>)
            }
          </tr>
        )
      })
    }

    if (filteredByProduct) {
      const results = details as GetProductRevenueResponse

      return results.getResultList().map((res, idx) => {
        return (
          <tr className="revenue-report__product" key={idx}>
            <Text component="td" title={res.getProductName()} shouldTranslate={false} content={res.getProductName()}></Text>
            {
              res.getMonthsList().map((res, idx) => <Text key={idx} component="td" shouldTranslate={false} content={getFormattedAmount(res)}></Text>)
            }
          </tr>
        )
      })
    }

  }


  const renderTable = () => {
    if (!details || isLoading) {
      return <ProgressIndicator color="blue"></ProgressIndicator>;
    }

    return (
      <Table className="revenue-report__table" scrollable fixedColWidths={filteredByPlan ? [180, 200] : [200]}>
        <thead>
          <tr>
            <Text component="th" content={getHeaderType}></Text>
            {filteredByPlan && <Text component="th" content="REVENUE_PLAN_NAME"></Text>}
            {datesList.map((date) => <Text key={date} component="th" content={date} shouldTranslate={false}></Text>)}
          </tr>
        </thead>
        <tbody>
          {renderRevenueReport()}
        </tbody>
      </Table>
    );
  };

  const handleDownloadCSV = () => {
    if (!details || isLoading) {
      return null;
    }

    setIsCreatingCSV(true);
    setTimeout(() => {
      downloadCSV(".cc-table", headerMessage);
      setIsCreatingCSV(false);
    }, 500);
  };

  return (
    <div className="revenue-report">
      {renderHeader()}
      <PageWrapper>{renderTable()}</PageWrapper>
      <LoadingModal isOpen={isCreatingCSV} title={counterpart(revenueType === "revenue"
        ? "DOWNLOADING_CSV_REVENUE_RECOGNITION"
        : "DOWNLOADING_CSV_DEFERRED_RECOGNITION"
      )} subTitle={""} shouldTranslateTitle={false} shouldTranslateSubTitle={false} />
    </div>
  );
};

export default RevenueReport;
