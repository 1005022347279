import { makeActionCreator, makeGenericActionCreatorTypeSafe } from "../utils/reduxUtils";
import { RefundPolicyType } from "../models/RefundPolicy";
import { RefundPolicyServiceClient } from "../utils/grpc/generated/Billsby.Protos/billing/private/refundpolicy/RefundpolicyServiceClientPb";
import { multiplyNumberBy100 } from "../utils/commonUtils";
import { GetRefundPolicyRequest, UpdateRefundPolicyRequest } from "../utils/grpc/generated/Billsby.Protos/billing/private/refundpolicy/refundpolicy_pb";
import { grpcUnaryCall } from "../utils/grpc/grpcUtils";
import { ICancelSubscriptionConfigState, IHelpInterrupt, IRetentionStep } from "../reducers/cancelSubscriptionConfigReducer";
import { GetHelpInterruptRequest, UpsertHelpInterruptRequest, GetReasonsToStayRequest, GetReasonsToStayResponse, UpsertReasonsToStayRequest, GetTimeRemainingRequest, GetTimeRemainingResponse, UpsertTimeRemainingRequest, GetPlanChangeRequest, UpsertPlanChangeRequest, GetPlanChangeResponse, GetRetentionStepsRequest, UpdateRetentionStepsRequest, RetentionStepListItem, SetDiscountOfferRequest, GetDiscountOfferRequest } from "../utils/grpc/generated/Billsby.Protos/core/private/companyconfig/retentionstep/retention_step_pb";
import { GetExitReasonsConfigRequest, GetExitReasonsConfigResponse, UpsertExitReasonConfigRequest } from "../utils/grpc/generated/Billsby.Protos/billing/private/company/exitreason/exit_reason_config_pb";
import { ExitReasonConfigServiceClient } from "../utils/grpc/generated/Billsby.Protos/billing/private/company/exitreason/Exit_reason_configServiceClientPb";
import { CompanyRetentionStepServiceClient } from "../utils/grpc/generated/Billsby.Protos/core/private/companyconfig/retentionstep/Retention_stepServiceClientPb";
import { RetentionStepType } from "../models/Subscription";
import { ConfigConstants } from "../utils/config";
import { FrequencyType } from "../utils/grpc/generated/Billsby.Protos/core/private/companyconfig/retentionstep/retention_step_pb";
import API from "../utils/API";

export const FETCH_REFUND_POLICY_REQUEST = "FETCH_REFUND_POLICY_REQUEST";
export const FETCH_REFUND_POLICY_SUCCESS = "FETCH_REFUND_POLICY_SUCCESS";
export const FETCH_REFUND_POLICY_FAILURE = "FETCH_REFUND_POLICY_FAILURE";

export const UPDATE_REFUND_POLICY_REQUEST = "UPDATE_REFUND_POLICY_REQUEST";
export const UPDATE_REFUND_POLICY_SUCCESS = "UPDATE_REFUND_POLICY_SUCCESS";
export const UPDATE_REFUND_POLICY_FAILURE = "UPDATE_REFUND_POLICY_FAILURE";

export const FETCH_HELP_INTERRUPT_REQUEST = "FETCH_HELP_INTERRUPT_REQUEST";
export const FETCH_HELP_INTERRUPT_SUCCESS = "FETCH_HELP_INTERRUPT_SUCCESS";
export const FETCH_HELP_INTERRUPT_FAILURE = "FETCH_HELP_INTERRUPT_FAILURE";

export const UPDATE_HELP_INTERRUPT_REQUEST = "UPDATE_HELP_INTERRUPT_REQUEST";
export const UPDATE_HELP_INTERRUPT_SUCCESS = "UPDATE_HELP_INTERRUPT_SUCCESS";
export const UPDATE_HELP_INTERRUPT_FAILURE = "UPDATE_HELP_INTERRUPT_FAILURE";

export const FETCH_REASONS_TO_STAY_REQUEST = "FETCH_REASONS_TO_STAY_REQUEST";
export const FETCH_REASONS_TO_STAY_SUCCESS = "FETCH_REASONS_TO_STAY_SUCCESS";
export const FETCH_REASONS_TO_STAY_FAILURE = "FETCH_REASONS_TO_STAY_FAILURE";

export const UPDATE_REASONS_TO_STAY_REQUEST = "UPDATE_REASONS_TO_STAY_REQUEST";
export const UPDATE_REASONS_TO_STAY_SUCCESS = "UPDATE_REASONS_TO_STAY_SUCCESS";
export const UPDATE_REASONS_TO_STAY_FAILURE = "UPDATE_REASONS_TO_STAY_FAILURE";

export const FETCH_TIME_REMAINING_REQUEST = "FETCH_TIME_REMAINING_REQUEST";
export const FETCH_TIME_REMAINING_SUCCESS = "FETCH_TIME_REMAINING_SUCCESS";
export const FETCH_TIME_REMAINING_FAILURE = "FETCH_TIME_REMAINING_FAILURE";

export const UPDATE_TIME_REMAINING_REQUEST = "UPDATE_TIME_REMAINING_REQUEST";
export const UPDATE_TIME_REMAINING_SUCCESS = "UPDATE_TIME_REMAINING_SUCCESS";
export const UPDATE_TIME_REMAINING_FAILURE = "UPDATE_TIME_REMAINING_FAILURE";

export const FETCH_PLAN_CHANGE_REQUEST = "FETCH_PLAN_CHANGE_REQUEST";
export const FETCH_PLAN_CHANGE_SUCCESS = "FETCH_PLAN_CHANGE_SUCCESS";
export const FETCH_PLAN_CHANGE_FAILURE = "FETCH_PLAN_CHANGE_FAILURE";

export const UPDATE_PLAN_CHANGE_REQUEST = "UPDATE_PLAN_CHANGE_REQUEST";
export const UPDATE_PLAN_CHANGE_SUCCESS = "UPDATE_PLAN_CHANGE_SUCCESS";
export const UPDATE_PLAN_CHANGE_FAILURE = "UPDATE_PLAN_CHANGE_FAILURE";

export const FETCH_RETENTION_STEPS_REQUEST = "FETCH_RETENTION_STEPS_REQUEST";
export const FETCH_RETENTION_STEPS_SUCCESS = "FETCH_RETENTION_STEPS_SUCCESS";
export const FETCH_RETENTION_STEPS_FAILURE = "FETCH_RETENTION_STEPS_FAILURE";

export const UPDATE_RETENTION_STEPS_REQUEST = "UPDATE_RETENTION_STEPS_REQUEST";
export const UPDATE_RETENTION_STEPS_SUCCESS = "UPDATE_RETENTION_STEPS_SUCCESS";
export const UPDATE_RETENTION_STEPS_FAILURE = "UPDATE_RETENTION_STEPS_FAILURE";

export const FETCH_EXIT_REASONS_REQUEST = "FETCH_EXIT_REASONS_REQUEST";
export const FETCH_EXIT_REASONS_SUCCESS = "FETCH_EXIT_REASONS_SUCCESS";
export const FETCH_EXIT_REASONS_FAILURE = "FETCH_EXIT_REASONS_FAILURE";

export const UPDATE_EXIT_REASONS_REQUEST = "UPDATE_EXIT_REASONS_REQUEST";
export const UPDATE_EXIT_REASONS_SUCCESS = "UPDATE_EXIT_REASONS_SUCCESS";
export const UPDATE_EXIT_REASONS_FAILURE = "UPDATE_EXIT_REASONS_FAILURE";

export const FETCH_DISCOUNT_OFFER_REQUEST = "FETCH_DISCOUNT_OFFER_REQUEST";
export const FETCH_DISCOUNT_OFFER_SUCCESS = "FETCH_DISCOUNT_OFFER_SUCCESS";
export const FETCH_DISCOUNT_OFFER_FAILURE = "FETCH_DISCOUNT_OFFER_FAILURE";

export const UPDATE_OFFER_DISCOUNT_REQUEST = "UPDATE_OFFER_DISCOUNT_REQUEST";
export const UPDATE_OFFER_DISCOUNT_SUCCESS = "UPDATE_OFFER_DISCOUNT_SUCCESS";
export const UPDATE_OFFER_DISCOUNT_FAILURE = "UPDATE_OFFER_DISCOUNT_FAILURE";

export const UPLOAD_DISCOUNT_OFFER_IMG_REQUEST = "UPLOAD_DISCOUNT_OFFER_IMG_REQUEST";
export const UPLOAD_DISCOUNT_OFFER_IMG_SUCCESS = "UPLOAD_DISCOUNT_OFFER_IMG_SUCCESS";
export const UPLOAD_DISCOUNT_OFFER_IMG_FAILURE = "UPLOAD_DISCOUNT_OFFER_IMG_FAILURE";

export const SET_REFUND_POLICY = "SET_REFUND_POLICY";
export const setRefundPolicy = makeActionCreator(SET_REFUND_POLICY, "payload");

export const RESET_REFUND_POLICY = "RESET_REFUND_POLICY";
export const resetRefundPolicy = makeActionCreator(RESET_REFUND_POLICY);

export const RESET_CANCEL_SUB_CONFIG = "RESET_CANCEL_SUB_CONFIG";
export const resetCancelSubConfig = makeActionCreator(RESET_CANCEL_SUB_CONFIG);

export const SET_CANCEL_SUB_CONFIG_FIELD = "SET_CANCEL_SUB_CONFIG_FIELD";
export const setCancelSubConfigField = makeGenericActionCreatorTypeSafe<ICancelSubscriptionConfigState>(SET_CANCEL_SUB_CONFIG_FIELD)

export const fetchRefundPolicy = function (companyId: number) {
  const getRefundPolicyRequest = new GetRefundPolicyRequest();
  getRefundPolicyRequest.setCompanyId(companyId);
  const refundPolicyServiceClient = new RefundPolicyServiceClient(ConfigConstants.grpcBaseUrl);
  return {
    types: [FETCH_REFUND_POLICY_REQUEST, FETCH_REFUND_POLICY_SUCCESS, FETCH_REFUND_POLICY_FAILURE],
    callAPI: () => grpcUnaryCall(getRefundPolicyRequest, refundPolicyServiceClient, refundPolicyServiceClient.getRefundPolicy)
  }
}

export const updateRefundPolicy = function (companyId: number, refundPolicy: RefundPolicyType) {
  const updateRefundPolicyRequest = new UpdateRefundPolicyRequest();
  updateRefundPolicyRequest.setCompanyId(companyId);
  updateRefundPolicyRequest.setRefundPolicy(refundPolicy as any);
  const refundPolicyServiceClient = new RefundPolicyServiceClient(ConfigConstants.grpcBaseUrl);
  //new RefundPolicyServiceClient("").updateRefundPolicy(updateRefundPolicyRequest, { deadline: ""}, () => {})
  return {
    types: [UPDATE_REFUND_POLICY_REQUEST, UPDATE_REFUND_POLICY_SUCCESS, UPDATE_REFUND_POLICY_FAILURE],
    callAPI: () => grpcUnaryCall(updateRefundPolicyRequest, refundPolicyServiceClient, refundPolicyServiceClient.updateRefundPolicy)
  }
}

export const fetchHelpInterrupt = function (companyDomain: string) {
  const getHelpInterrupt = new GetHelpInterruptRequest();
  getHelpInterrupt.setCompanyDomain(companyDomain);
  const companyRetentionStepServiceClient = new CompanyRetentionStepServiceClient(ConfigConstants.grpcBaseUrl);
  return {
    types: [FETCH_HELP_INTERRUPT_REQUEST, FETCH_HELP_INTERRUPT_SUCCESS, FETCH_HELP_INTERRUPT_FAILURE],
    callAPI: () => grpcUnaryCall(getHelpInterrupt, companyRetentionStepServiceClient, companyRetentionStepServiceClient.getHelpInterrupt)
  }
}

export const updateHelpInterrupt = function (companyDomain: string, data: IHelpInterrupt) {
  const updateHelpInterrupt = new UpsertHelpInterruptRequest();
  updateHelpInterrupt.setCompanyDomain(companyDomain);
  updateHelpInterrupt.setTitle(data.title);
  updateHelpInterrupt.setSubtitle(data.subtitle);
  updateHelpInterrupt.setProvideEmailAddressEnabled(data.provideEmailAddressEnabled);
  updateHelpInterrupt.setProvidePhoneNumberEnabled(data.providePhoneNumberEnabled);
  data.provideEmailAddressEnabled && updateHelpInterrupt.setEmail(data.provideEmailAddressEnabled ? data.email : "");
  data.providePhoneNumberEnabled && updateHelpInterrupt.setPhoneNumberDialCode(data.providePhoneNumberEnabled ? data.phoneNumberDialCode : "");
  data.providePhoneNumberEnabled && updateHelpInterrupt.setPhoneNumberDialCountry(data.providePhoneNumberEnabled ? data.phoneNumberDialCountry : "");
  data.providePhoneNumberEnabled && updateHelpInterrupt.setPhoneNumber(data.providePhoneNumberEnabled ? data.phoneNumber : "");
  updateHelpInterrupt.setSupportPageUrl(data.supportPageUrl);
  const companyRetentionStepServiceClient = new CompanyRetentionStepServiceClient(ConfigConstants.grpcBaseUrl);

  return {
    types: [UPDATE_HELP_INTERRUPT_REQUEST, UPDATE_HELP_INTERRUPT_SUCCESS, UPDATE_HELP_INTERRUPT_FAILURE],
    callAPI: () => grpcUnaryCall(updateHelpInterrupt, companyRetentionStepServiceClient, companyRetentionStepServiceClient.upsertHelpInterrupt)
  }
}

export const fetchReasonsToStay = function (companyDomain: string) {
  const getReasonsToStay = new GetReasonsToStayRequest();
  getReasonsToStay.setCompanyDomain(companyDomain);
  const companyRetentionStepServiceClient = new CompanyRetentionStepServiceClient(ConfigConstants.grpcBaseUrl);
  return {
    types: [FETCH_REASONS_TO_STAY_REQUEST, FETCH_REASONS_TO_STAY_SUCCESS, FETCH_REASONS_TO_STAY_FAILURE],
    callAPI: () => grpcUnaryCall(getReasonsToStay, companyRetentionStepServiceClient, companyRetentionStepServiceClient.getReasonsToStay)
  }
}

export const updateReasonsToStay = function (companyDomain: string, data: GetReasonsToStayResponse.AsObject) {
  const updateReasonsToStay = new UpsertReasonsToStayRequest();
  updateReasonsToStay.setCompanyDomain(companyDomain);
  updateReasonsToStay.setText1(data.text1);
  updateReasonsToStay.setText2(data.text2);
  updateReasonsToStay.setText3(data.text3);
  updateReasonsToStay.setText4(data.text4);
  updateReasonsToStay.setText5(data.text5);
  const companyRetentionStepServiceClient = new CompanyRetentionStepServiceClient(ConfigConstants.grpcBaseUrl);
  return {
    types: [UPDATE_REASONS_TO_STAY_REQUEST, UPDATE_REASONS_TO_STAY_SUCCESS, UPDATE_REASONS_TO_STAY_FAILURE],
    callAPI: () => grpcUnaryCall(updateReasonsToStay, companyRetentionStepServiceClient, companyRetentionStepServiceClient.upsertReasonsToStay)
  }
}

export const fetchTimeRemaining = function (companyDomain: string) {
  const getTimeRemaining = new GetTimeRemainingRequest();
  getTimeRemaining.setCompanyDomain(companyDomain);
  const companyRetentionStepServiceClient = new CompanyRetentionStepServiceClient(ConfigConstants.grpcBaseUrl);
  return {
    types: [FETCH_TIME_REMAINING_REQUEST, FETCH_TIME_REMAINING_SUCCESS, FETCH_TIME_REMAINING_FAILURE],
    callAPI: () => grpcUnaryCall(getTimeRemaining, companyRetentionStepServiceClient, companyRetentionStepServiceClient.getTimeRemaining)
  }
}

export const updateTimeRemaining = function (companyDomain: string, data: GetTimeRemainingResponse.AsObject) {
  const updateTimeRemaining = new UpsertTimeRemainingRequest();
  updateTimeRemaining.setCompanyDomain(companyDomain);
  updateTimeRemaining.setText(data.text);
  const companyRetentionStepServiceClient = new CompanyRetentionStepServiceClient(ConfigConstants.grpcBaseUrl);
  return {
    types: [UPDATE_TIME_REMAINING_REQUEST, UPDATE_TIME_REMAINING_SUCCESS, UPDATE_TIME_REMAINING_FAILURE],
    callAPI: () => grpcUnaryCall(updateTimeRemaining, companyRetentionStepServiceClient, companyRetentionStepServiceClient.upsertTimeRemaining)
  }
}

export const fetchPlanChange = function (companyDomain: string) {
  const fetchPlanChange = new GetPlanChangeRequest();
  fetchPlanChange.setCompanyDomain(companyDomain);
  const companyRetentionStepServiceClient = new CompanyRetentionStepServiceClient(ConfigConstants.grpcBaseUrl);
  return {
    types: [FETCH_PLAN_CHANGE_REQUEST, FETCH_PLAN_CHANGE_SUCCESS, FETCH_PLAN_CHANGE_FAILURE],
    callAPI: () => grpcUnaryCall(fetchPlanChange, companyRetentionStepServiceClient, companyRetentionStepServiceClient.getPlanChange)
  }
}

export const updatePlanChange = function (companyDomain: string, data: GetPlanChangeResponse.AsObject) {
  const updatePlanChange = new UpsertPlanChangeRequest();
  updatePlanChange.setCompanyDomain(companyDomain);
  updatePlanChange.setText(data.text);
  updatePlanChange.setSubText(data.subText)
  const companyRetentionStepServiceClient = new CompanyRetentionStepServiceClient(ConfigConstants.grpcBaseUrl);
  return {
    types: [UPDATE_PLAN_CHANGE_REQUEST, UPDATE_PLAN_CHANGE_SUCCESS, UPDATE_PLAN_CHANGE_FAILURE],
    callAPI: () => grpcUnaryCall(updatePlanChange, companyRetentionStepServiceClient, companyRetentionStepServiceClient.upsertPlanChange)
  }
}

export const fetchRetentionSteps = function (companyDomain: string) {
  const getRetentionStepsRequest = new GetRetentionStepsRequest();
  getRetentionStepsRequest.setCompanyDomain(companyDomain);
  const companyRetentionStepServiceClient = new CompanyRetentionStepServiceClient(ConfigConstants.grpcBaseUrl);
  return {
    types: [FETCH_RETENTION_STEPS_REQUEST, FETCH_RETENTION_STEPS_SUCCESS, FETCH_RETENTION_STEPS_FAILURE],
    callAPI: () => grpcUnaryCall(getRetentionStepsRequest, companyRetentionStepServiceClient, companyRetentionStepServiceClient.getRetentionSteps)
  }
}

export const updateRetentionSteps = function (companyDomain: string, arr: Array<IRetentionStep>) {
  const updateRetentionSteps = new UpdateRetentionStepsRequest();

  const updatedStep = (type: RetentionStepType): RetentionStepListItem => {
    const retentionStepListItem = new RetentionStepListItem();
    const idx = arr.findIndex(x => x.type === type);
    retentionStepListItem.setIsEnabled(arr[idx].isEnabled);
    retentionStepListItem.setOrderSequenceNumber(idx + 1);

    return retentionStepListItem;
  }

  updateRetentionSteps.setCompanyDomain(companyDomain);
  updateRetentionSteps.setHelpInterrupt(updatedStep(RetentionStepType.HelpInterrupt));
  updateRetentionSteps.setReasonsToStay(updatedStep(RetentionStepType.ReasonsToStay));
  updateRetentionSteps.setTimeRemaining(updatedStep(RetentionStepType.TimeRemaining));
  updateRetentionSteps.setPlanChange(updatedStep(RetentionStepType.PlanChange));
  updateRetentionSteps.setDiscountOffer(updatedStep(RetentionStepType.DiscountOffer));
  const companyRetentionStepServiceClient = new CompanyRetentionStepServiceClient(ConfigConstants.grpcBaseUrl);

  return {
    types: [UPDATE_RETENTION_STEPS_REQUEST, UPDATE_RETENTION_STEPS_SUCCESS, UPDATE_RETENTION_STEPS_FAILURE],
    callAPI: () => grpcUnaryCall(updateRetentionSteps, companyRetentionStepServiceClient, companyRetentionStepServiceClient.updateRetentionSteps)
  }
}


export const fetchExitReasons = function (companyId: number, orderBy: GetExitReasonsConfigRequest.OrderByOption) {
  const getExitReasons = new GetExitReasonsConfigRequest();
  getExitReasons.setCompanyId(companyId);
  getExitReasons.setOrderBy(orderBy)
  const exitReasonConfigServiceClient = new ExitReasonConfigServiceClient(ConfigConstants.grpcBaseUrl);
  return {
    types: [FETCH_EXIT_REASONS_REQUEST, FETCH_EXIT_REASONS_SUCCESS, FETCH_EXIT_REASONS_FAILURE],
    callAPI: () => grpcUnaryCall(getExitReasons, exitReasonConfigServiceClient, exitReasonConfigServiceClient.getExitReasonsConfig)
  }
}

export const updateExitReasons = function (companyId: number, arr: Array<GetExitReasonsConfigResponse.ExitReasonConfig.AsObject>) {
  const updateExitReasons = new UpsertExitReasonConfigRequest();
  updateExitReasons.setCompanyId(companyId);

  let updateReasonsList: Array<GetExitReasonsConfigResponse.ExitReasonConfig> = [];

  const filteredReasons = arr.filter(x => {
    return x.exitReasonConfigId || (!x.exitReasonConfigId && !x.isArchived)
  })

  filteredReasons.forEach((x, i) => {
    const reason = new GetExitReasonsConfigResponse.ExitReasonConfig();
    reason.setText(x.text);
    reason.setIsArchived(x.isArchived);
    reason.setExitReasonConfigId(x.exitReasonConfigId);
    reason.setOrderSequenceNumber(i + 1);


    updateReasonsList.push(reason);
  })
  updateExitReasons.setExitReasonConfigsList(updateReasonsList);
  const exitReasonConfigServiceClient = new ExitReasonConfigServiceClient(ConfigConstants.grpcBaseUrl)
  return {
    types: [UPDATE_EXIT_REASONS_REQUEST, UPDATE_EXIT_REASONS_SUCCESS, UPDATE_EXIT_REASONS_FAILURE],
    callAPI: () => grpcUnaryCall(updateExitReasons, exitReasonConfigServiceClient, exitReasonConfigServiceClient.upsertExitReasonConfig)
  }
}

export const fetchDiscountOffer = function (companyDomain: string) {
  const getDiscountOfferRequest = new GetDiscountOfferRequest();
  getDiscountOfferRequest.setCompanyDomain(companyDomain);
  const companyRetentionStepServiceClient = new CompanyRetentionStepServiceClient(ConfigConstants.grpcBaseUrl);
  return {
    types: [FETCH_DISCOUNT_OFFER_REQUEST, FETCH_DISCOUNT_OFFER_SUCCESS, FETCH_DISCOUNT_OFFER_FAILURE],
    callAPI: () => grpcUnaryCall(getDiscountOfferRequest, companyRetentionStepServiceClient, companyRetentionStepServiceClient.getDiscountOffer)
  }
}

export const updateOfferDiscount = function (companyDomain: string,
  offerDiscount: boolean,
  discountAllCustomers: boolean,
  discountLimitToOne: boolean,
  discountFrequency?: number,
  discountFrequencyType?: { label: string, value: FrequencyType },
  discountPercentage?: number,
  discountMinimumTenure?: number,
  discountMinimumTenureType?: { label: string, value: FrequencyType },
  exitIntentImage?: string) {
  const setDiscountOfferRequest = new SetDiscountOfferRequest();
  setDiscountOfferRequest.setCompanyDomain(companyDomain);
  if (offerDiscount) {
    const discountOffer = new SetDiscountOfferRequest.DiscountOffer();
    discountOffer.setPercentage(Number(multiplyNumberBy100(discountPercentage)));
    discountOffer.setExpiryFrequency(Number(discountFrequency));
    discountOffer.setExpiryFrequencyType((discountFrequencyType as { label: string, value: FrequencyType }).value);
    discountOffer.setEnabledAllCustomers(discountAllCustomers);
    discountOffer.setLimitToOneRetentionDiscountPerSub(discountLimitToOne);
    exitIntentImage && discountOffer.setExitIntentImageUrl(exitIntentImage)

    if (!discountAllCustomers) {
      const minimumTenure = new SetDiscountOfferRequest.DiscountOffer.MinimumTenure();
      minimumTenure.setFrequency(Number(discountMinimumTenure));
      minimumTenure.setFrequencyType((discountMinimumTenureType as { label: string, value: FrequencyType }).value);
      discountOffer.setMinimumTenure(minimumTenure);
    }

    setDiscountOfferRequest.setDiscountOffer(discountOffer);
  } else {
    setDiscountOfferRequest.setIsDisabled(true);
  }

  const companyRetentionStepServiceClient = new CompanyRetentionStepServiceClient(ConfigConstants.grpcBaseUrl);
  return {
    types: [UPDATE_OFFER_DISCOUNT_REQUEST, UPDATE_OFFER_DISCOUNT_SUCCESS, UPDATE_OFFER_DISCOUNT_FAILURE],
    callAPI: () => grpcUnaryCall(setDiscountOfferRequest, companyRetentionStepServiceClient, companyRetentionStepServiceClient.setDiscountOffer)
  }
}

export const uploadExitIntentImage = function (companyDomain: string, file: File) {
  return {
    types: [UPLOAD_DISCOUNT_OFFER_IMG_REQUEST, UPLOAD_DISCOUNT_OFFER_IMG_SUCCESS, UPLOAD_DISCOUNT_OFFER_IMG_FAILURE],
    callAPI: () => API.uploadCompanyImage(companyDomain, file)
  }
}