import React, { useLayoutEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../../../index";
import { resetEmbedCodesCustomFields, fetchEmbedCodesCustomFields, setSelectedEmbedCodesCustomField } from "../../../actions/embedCodesCheckOutActions";
import Dropdown from "../../../components/ui/dropdown/Dropdown";
import { ICustomField, CustomFieldType } from "../../../models/CustomFields";
import CodePanel from "../../../components/ui/code-panel/CodePanel";
import counterpart from "counterpart";
import Text from "../../../components/ui/text/Text";

const EmbedCodesCustomFields: React.FC = () => {
  const embedCodesCheckOutData = useSelector((state: AppState) => state.embedCodesCheckOutReducer);
  const authData = useSelector((state: AppState) => state.auth);

  const dispatch = useDispatch<Function>();

  const { selectedCustomField, customFields } = embedCodesCheckOutData;
  const { currentCompanyDomain } = authData;

  const scriptPassCustomerData_1 = "<script>";
  const scriptPassCustomerData_1_1 = "window.billsbyData = {";
  const scriptPassCustomerData_2_1 = "customFields: [";
  const scriptPassCustomerData_2_2 = "]";
  const scriptPassCustomerData_3_1 = "{";
  const scriptPassCustomerData_3_2 = "}";
  const scriptPassCustomerData_4_1 = "customFieldId:";
  const scriptPassCustomerData_4_2 = ",";
  const scriptPassCustomerData_5_1 = "value:";
  const scriptPassCustomerData_9 = "};";
  const scriptPassCustomerData_10 = "</script>";


  const getCustomFieldCode = (field: ICustomField) => {
    const getTemplate = (value: string) => (
      `
        ${scriptPassCustomerData_4_1} ${field.customFieldId} ${scriptPassCustomerData_4_2}
        ${scriptPassCustomerData_5_1} ${value}
      `
    );

    switch (field.type) {
      case CustomFieldType.CheckboxField:
        return getTemplate("[true/false]");
      case CustomFieldType.DatePickerField:
        return getTemplate("\"[date]\"");
      case CustomFieldType.DropdownField: {
        var options = !!field.options ? field.options.split(",")
          .map((item, index) => { return index === 0 ? `"${item.trim()}"` : `or "${item.trim()}"` }).join(" ") : "";
        return getTemplate(options);
      }
      case CustomFieldType.MultiLineTextField:
        return getTemplate("\"[Free text]\"");
      case CustomFieldType.NumbersField:
        return getTemplate("[number]");
      case CustomFieldType.SingleLineTextField:
        return getTemplate("\"[Free text]\"");
      default:
        return "";
    }
  }

  const getCustomFieldCodeDom = (field: ICustomField) => {
    const getTemplate = (value: JSX.Element | JSX.Element[]) => (
      <div>
        <div>
          {scriptPassCustomerData_4_1} {field.customFieldId} {scriptPassCustomerData_4_2}
        </div>
        <div>
          {scriptPassCustomerData_5_1} {value}
        </div>
      </div>
    )

    switch (field.type) {
      case CustomFieldType.CheckboxField:
        return getTemplate(<span className="eccheckout__basic__variable">[true/false]</span>);
      case CustomFieldType.DatePickerField:
        return getTemplate(<span className="eccheckout__basic__variable">"[date]"</span>);
      case CustomFieldType.DropdownField: {
        const options = !!field.options ? field.options.split(",")
          .map((item, index) => { return index === 0 ? <div className="eccheckout__basic__dropdown-options">"<span className="eccheckout__basic__variable">{item.trim()}</span>"</div> : <div className="eccheckout__basic__dropdown-options"> or "<span className="eccheckout__basic__variable">{item.trim()}</span>"</div> }) : <div></div>;
        return getTemplate(options);
      }
      case CustomFieldType.MultiLineTextField:
        return getTemplate(<span className="eccheckout__basic__variable">"[Free text]"</span>);
      case CustomFieldType.NumbersField:
        return getTemplate(<span className="eccheckout__basic__variable">[number]</span>);
      case CustomFieldType.SingleLineTextField:
        return getTemplate(<span className="eccheckout__basic__variable">"[Free text]"</span>);
      default:
        return <div></div>;
    }
  }

  const scriptCustomFieldData = (customFieldCodeText: string) => `
    ${scriptPassCustomerData_1 + scriptPassCustomerData_1_1}
    ${scriptPassCustomerData_2_1}
    ${scriptPassCustomerData_3_1}
    ${customFieldCodeText}
    ${scriptPassCustomerData_3_2}
    ${scriptPassCustomerData_2_2}
    ${scriptPassCustomerData_9 + scriptPassCustomerData_10}
    `;

  useLayoutEffect(() => {
    dispatch(fetchEmbedCodesCustomFields(currentCompanyDomain));
    return () => {
      dispatch(resetEmbedCodesCustomFields());
    };
  }, [])

  const customFieldsMapped = useMemo(() => {
    return customFields.map(item => { return { value: item.customFieldId, type: item.type, label: `${item.label}` } });
  }, [customFields]);

  if (!customFieldsMapped.length) {
    return null;
  }

  return (
    <div>
      <Text className="eccheckout__basic__subtitle" content="EMBED_CODES_PASSING_CUSTOMER_DATA_SUBTITLE3" />
      <Dropdown
        id="product"
        onChange={(selectedItem: any) => { dispatch(setSelectedEmbedCodesCustomField(selectedItem)) }}
        value={selectedCustomField ? selectedCustomField : null}
        placeholder={counterpart("CHECKOUT_EMBED_SELECT_FIELD")}
        options={customFieldsMapped}
        className={selectedCustomField ? "eccheckout__basic__dropdown" : ""}
      />

      {selectedCustomField &&
        <CodePanel noMargin id="basic-copy-9" codeStr={scriptCustomFieldData(getCustomFieldCode(selectedCustomField))} makeBeauty>
          <span className="eccheckout__basic__code-main">
            <div>
              {scriptPassCustomerData_1}
            </div>
            <div>
              {scriptPassCustomerData_1_1}
            </div>
            <div>
              <div>
                {scriptPassCustomerData_2_1}
              </div>
              <div>
                {scriptPassCustomerData_3_1}
              </div>
              {getCustomFieldCodeDom(selectedCustomField)}
              <div>
                {scriptPassCustomerData_3_2}
              </div>
              <div>
                {scriptPassCustomerData_2_2}
              </div>
            </div>
            <div>
              {scriptPassCustomerData_9}
            </div>
            <div>
              {scriptPassCustomerData_10}
            </div>
          </span>
        </CodePanel>
      }
    </div>
  )
}

export default EmbedCodesCustomFields;