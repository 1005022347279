import React, { useState } from "react";
import counterpart from "counterpart";
import "./Nav.scss";
import { ICompany, Permission, UserRole } from "../../models/Auth";
import { logout, refreshToken } from "../../utils/authUtils";
import { ConfigConstants } from "../../utils/config";
import Tooltip from "../../components/ui/tooltip/Tooltip";
import logo from "../../images/billsby-logo.svg";
import logoMinimised from "../../images/billsby-logo-min-svg.svg";
import NavItem from "./NavItem";
import NavLink from "./NavLink";
import Modal from "../modal/Modal";
import BillingWelcome from "../billing/billing-welcome/BillingWelcome";
import SandboxWelcome from "../sandbox/sandbox-welcome/SandboxWelcome";
import SandboxPreLive from "../sandbox/sandbox-pre-live/SandboxPreLive";
import EditProfile from "../users/edit-profile/EditProfile";
import ReactTooltip from "react-tooltip";
import AddCompany from "../add-company/AddCompany";
import ChangePassword from "../change-password/ChangePassword";
import { openIntercomIframe } from "../../utils/commonUtils";
import { storeSet } from "../../utils/storeUtils";
import { BILLSBY_SELECTED_COMPANY } from "../../utils/constants";
import { IAuthReducerState } from "../../reducers/authReducer";
import { INavItemVisibility, INavItems, INavItem } from "../../models/Nav";
import Panel from "../ui/panel/Panel";
import Text from "../ui/text/Text";
import Button from "../ui/button/Button";
import { GettingStartedType } from "../../models/GettingStarted";
import BillingPreLive from "../billing/billing-pre-live/BillingPreLive";
import Sandboxtrial from "../sandbox/sandbox-trial/SandboxTrial";
import Teammates from "../../components/teammates/Teammates";
import TwoFactorAuth from "../two-factor-auth/TwoFactorAuth";
import AccountAccess from "../account-access/AccountAccess";
import BillingLive from "../billing/billing-live/BillingLive";
import API from "../../utils/API";
import { IAccountingIntegrationsReducerState } from "../../reducers/accountingIntegrationsReducer";
import { AccountingIntegrationType } from "../../models/AccountingIntegrations";
import { fetchAccountIntegrations } from "../../actions/accountingIntegrationsActions";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../..";
import { useHistory } from "react-router";
import { setShowBillingModal } from "../../actions/authActions";
import useCheckFeaturePermission from "../../utils/hooks/useCheckFeaturePermission";

const DEFAULT_NAV_ITEMS: INavItems = {
  dashboard: {
    targets: ["/home"]
  },
  newDashboard: {
    targets: ["/new-home"]
  },
  customers: {
    targets: ["/customers"]
  },
  subscriptions: {
    targets: ["/subscriptions"]
  },
  invoice: {
    isExpanded: false,
    targets: ["/invoices", "/credit-notes"]
  },
  products: {
    isExpanded: false,
    targets: ["/products/products-plans-cycles", "/addons-allowances", "/coupons"]
  },
  logs: {
    isExpanded: false,
    targets: ["/transactions", "/email-sms", "/events"]
  },
  reports: {
    isExpanded: false,
    targets: ["/report-subscriptions", "/accounting"]
  },
  zapier: {
    isExpanded: false,
    targets: ["/zapier"]
  },
  integrations: {
    isExpanded: false,
    targets: ["/integrations"]
  },
  settings: {
    isExpanded: false,
    targets: ["/configuration", "/embed-codes", "/integrations"]
  }
};

interface INavProps {
  navItemsVisibility: INavItemVisibility;
  navItems?: Partial<INavItems>;
  minimiseNav?: any;
  isMinimised?: boolean;
}

interface IFooterTooltipItem {
  icon: string;
  content: string;
  onClick?: any;
  isEnabled?: boolean;
}

interface IFooterTooltipItemSupportCode {
  icon: string;
  content: string;
  code: string;
}

const FooterTooltipItem = (props: IFooterTooltipItem) => {
  const { icon, content, onClick = () => { }, isEnabled = true } = props;
  if (!isEnabled) {
    return null;
  }
  return (
    <li
      onClick={() => {
        isEnabled && onClick() && ReactTooltip.hide();
      }}
    >
      <button>
        <i className={icon} />
        <span>{counterpart(content)}</span>
      </button>
    </li>
  );
};

const FooterTooltipItemSupportCode = (props: IFooterTooltipItemSupportCode) => {
  const { icon, content, code } = props;
  return (
    <div className="navbar__content-footer__tip-plaintext">
      <i className={icon} />
      <span>
        {counterpart(content)} <span className="navbar__content-footer__tip-plaintext-code">{code}</span>
      </span>
    </div>
  );
};


const Nav: React.FC<INavProps> = ({ isMinimised, navItemsVisibility, navItems }) => {

  const authData = useSelector<AppState, IAuthReducerState>(state => state.auth);
  const hasQuickbooksError = useSelector((state: AppState) => state.accountingIntegrationsReducer.hasQuickbooksError);
  const accountingIntegrations = useSelector<AppState, IAccountingIntegrationsReducerState>(state => state.accountingIntegrationsReducer)
  const dispatch = useDispatch<Function>()
  const { parsedToken, companyName, currentCompanyDomain, readmeioLinks, dashboardSettings, companies, showBillingModal, } = authData;
  const { integrations: integrationsData } = accountingIntegrations;
  const isSuspended = authData.dashboardSettings && authData.dashboardSettings.isSuspended;
  const history = useHistory();
  const [showTeammatesModal, setShowTeammatesModal] = useState(false);
  const [showSandboxModal, setShowSandboxModal] = useState(false);
  const [showEditProfileModal, setShowEditProfileModal] = useState(false);
  const [showAddCompanyModal, setShowAddCompanyModal] = useState(false);
  const [showChangePasswordModal, setShowChangePasswordModal] = useState(false);
  const [showTwoFactorAuthModal, setShowTwoFactorAuthModal] = useState(false);
  const [showAccountAccessModal, setShowAccountAccessModal] = useState(false);
  const [supportCode, setSupportCode] = useState("");
  const [menuItems, setMenuItems] = useState({ ...DEFAULT_NAV_ITEMS, ...navItems });
  const { dashboard, newDashboard, customers, subscriptions, invoice, products, logs, reports, settings, integrations } = menuItems;

  const isDashboardAnalyticsReadPermitted = useCheckFeaturePermission(Permission.DashboardAnalyticsRead);
  const isCustomersPermitted = useCheckFeaturePermission(Permission.CustomersRead);
  const isSubscriptionsPermitted = useCheckFeaturePermission(Permission.SubscriptionsRead);
  const isInvoicesPermitted = useCheckFeaturePermission(Permission.InvoicesRead);
  const isProductsPlansCyclesPermitted = useCheckFeaturePermission(Permission.ProductsPlansCyclesRead);
  const isTransactionLogsPermitted = useCheckFeaturePermission(Permission.TransactionLogsRead);
  const isEmailLogsPermitted = useCheckFeaturePermission(Permission.EmailLogsRead);
  const isAccountingReportsPermitted = useCheckFeaturePermission(Permission.AccountingReportsRead);
  const isSelfServiceReportsPermitted = useCheckFeaturePermission(Permission.SelfServiceReportsRead);
  const isIntegrationsPermitted = useCheckFeaturePermission(Permission.IntegrationsRead);
  const isEmbedCodesPermitted = useCheckFeaturePermission(Permission.EmbedCodesRead);
  const isGoLivePermitted = useCheckFeaturePermission(Permission.GoLive);
  const isTeammatesPermitted = useCheckFeaturePermission(Permission.TeammatesRead);
  const isSandboxPermitted = useCheckFeaturePermission(Permission.Sandbox);
  const isAddCompanyPermitted = useCheckFeaturePermission(Permission.CompanyAdd);

  const hasAccessToConfiguration = (role?: UserRole) => {
    return !role ? false : [UserRole.Admin, UserRole.Editor, UserRole.Developer, UserRole.BillsbyAdmin, UserRole.BillsbySeniorOperationsManager, UserRole.BillsbyDeveloper].includes(role)
  }

  const BillingModalLookUp = {
    GettingStarted1: <BillingWelcome />,
    GettingStarted2: <BillingWelcome />,
    PreLive: <BillingPreLive onRequestClose={() => dispatch(setShowBillingModal(!showBillingModal))} />,
    OnTrial: <BillingLive />,
    Live: <BillingLive />
  };

  const SandboxModalLookUp = {
    GettingStarted1: <SandboxWelcome />,
    GettingStarted2: <SandboxWelcome />,
    PreLive: <SandboxPreLive onRequestClose={() => setShowSandboxModal(!showSandboxModal)} />,
    OnTrial: <Sandboxtrial />,
    Live: <SandboxWelcome />
  };

  const setIsMenuItemxEpanded = (menuItemKey: string, isExpanded: boolean) => {
    const _menuItems = { ...DEFAULT_NAV_ITEMS, ...navItems };
    const newMenuItems: any = { ..._menuItems };
    newMenuItems[menuItemKey].isExpanded = isExpanded;

    if (menuItemKey === "integrations" && newMenuItems["integrations"].isExpanded) {
      dispatch(fetchAccountIntegrations(Number(authData.currentCompanyId)))
    }

    Object.keys(newMenuItems).forEach(key => {
      const menuItem = (newMenuItems as any)[key] as INavItem;
      if (key !== menuItemKey && menuItem.targets.every(target => history.location.pathname.indexOf(target) < 0)) {
        newMenuItems[key].isExpanded = false;
      }
    });

    setMenuItems(_menuItems)
  };

  const renderIntegrationsNavItem = (integrationType: AccountingIntegrationType, error: { hasQuickbooksError: boolean, hasWebexError: boolean, hasFreeAgentError: boolean }) => {
    switch (integrationType) {
      case AccountingIntegrationType.FREEAGENT:
        return <NavLink
          key="link-free-agent"
          icon={error.hasFreeAgentError ? "fas fa-map-marker-exclamation error" : ""}

          id="link-free-agent"
          isEnabled={true}
          target="/integrations/free-agent"
          content="NAVBAR_FREEAGENT"
        />
      case AccountingIntegrationType.QUICKBOOKS:
        return <NavLink
          key="link-quick-books"
          icon={error.hasQuickbooksError ? "fas fa-map-marker-exclamation error" : ""}
          id="link-quick-books"
          isEnabled={true}
          target="/integrations/quickbooks/faq"
          content="NAVBAR_QUICKBOOKS"
        />
      case AccountingIntegrationType.WEBEX:
        return <NavLink
          key="link-webex"
          icon={error.hasWebexError ? "fas fa-map-marker-exclamation error" : ""}
          id="link-webex"
          isEnabled={true}
          target="/integrations/webex"
          content="NAVBAR_WEBEX"
        />
      default:
        return null;
    }
  }

  const generateSupportCode = async () => {
    try {
      const response: any = await API.generateSupportCode();
      setSupportCode(response.supportCode)
    } catch (err) {
      console.log(err);
    }
  };

  const openInNewTab = (url: string) => {
    const win = window.open(url, "_self");
    if (win != null) win.focus();
  };

  if (!parsedToken) {
    return null;
  }

  return (
    <div className={isMinimised ? "navbar navbar--minimised" : "navbar"}>
      <div className="navbar__logo">
        <img src={isMinimised ? logoMinimised : logo} alt="Billsby Logo" />
        {/*<i onClick={() => minimiseNav(!isMinimised)} className={isMinimised ? "fas fa-chevron-right" : "fas fa-chevron-square-left"} />*/}
      </div>
      <div className={`navbar__content ${isSuspended ? "navbar__content--disabled" : ""}`}>
        <div className="navbar__content-body">
          <NavItem isVisible={isDashboardAnalyticsReadPermitted} icon="fas fa-tachometer-fast" content="NAVBAR_DASHBOARD" targets={dashboard.targets} isEnabled={!!navItemsVisibility.dashboard && !isSuspended} id="link-home" />
          
          <NavItem isVisible={isDashboardAnalyticsReadPermitted && ConfigConstants.reactAppEnvironment !== "Production"} icon="fas fa-tachometer-fast" content="NAVBAR_DASHBOARD_NEW" targets={newDashboard.targets} isEnabled={!!navItemsVisibility.newDashboard && !isSuspended} id="link-home" />

          <NavItem isVisible={isCustomersPermitted} icon="fas fa-user-friends" content="NAVBAR_CUSTOMERS" targets={customers.targets} isEnabled={!!navItemsVisibility.customers && !isSuspended} id="link-customers" />

          <NavItem isVisible={isSubscriptionsPermitted} icon="fas fa-calendar-alt" content="NAVBAR_SUBSCRIPTIONS" targets={subscriptions.targets} isEnabled={!!navItemsVisibility.subscriptions && !isSuspended} id="link-subscriptions" />

          <NavItem
            isVisible={isInvoicesPermitted}
            icon="fas fa-file-invoice-dollar"
            content="NAVBAR_INVOICE_CREDIT"
            id="link-invoices-group"
            targets={invoice.targets}
            isEnabled={!!navItemsVisibility.invoices && !isSuspended}
            dataFor={!!navItemsVisibility.invoices ? "show-invoices-group" : undefined}
            isExpanded={invoice.isExpanded}
            setIsExpanded={isInvoiceNavItemExpanded => setIsMenuItemxEpanded("invoice", isInvoiceNavItemExpanded)}
          >
            <NavLink
              id="link-invoices"
              isEnabled={navItemsVisibility.invoices === true || (Array.isArray(navItemsVisibility.invoices) && navItemsVisibility.invoices.includes(1))}
              target="/invoices"
              content="NAVBAR_INVOICES"
            />
            <NavLink
              id="link-credit-notes"
              isEnabled={navItemsVisibility.invoices === true || (Array.isArray(navItemsVisibility.invoices) && navItemsVisibility.invoices.includes(2))}
              target="/credit-notes"
              content="NAVBAR_CREDIT_NOTES"
            />
          </NavItem>

          {isMinimised ? (
            <Tooltip id="show-invoices-group" place="right">
              <ul>
                {(navItemsVisibility.invoices === true || (Array.isArray(navItemsVisibility.invoices) && navItemsVisibility.invoices.includes(1))) && (
                  <li>
                    <NavLink id="show-invoices-group-invoices" target="/invoices" content="NAVBAR_INVOICES" isEnabled={true} />
                  </li>
                )}
                {(navItemsVisibility.invoices === true || (Array.isArray(navItemsVisibility.invoices) && navItemsVisibility.invoices.includes(2))) && (
                  <li>
                    <NavLink id="show-invoices-group-credit-notes" target="/credit-notes" content="NAVBAR_CREDIT_NOTES" isEnabled={true} />
                  </li>
                )}
              </ul>
            </Tooltip>
          ) : null}

          <NavItem
            isVisible={isProductsPlansCyclesPermitted}
            icon="fas fa-box-full"
            content="NAVBAR_PRODUCTS"
            id="link-products-group"
            targets={products.targets}
            isEnabled={!!navItemsVisibility.products && !isSuspended}
            dataFor={!!navItemsVisibility.products ? "show-products-group" : undefined}
            isExpanded={products.isExpanded}
            setIsExpanded={isProductNavItemExpanded => setIsMenuItemxEpanded("products", isProductNavItemExpanded)}
          >
            <NavLink
              id="link-products-plans-cycles"
              isEnabled={navItemsVisibility.products === true || (Array.isArray(navItemsVisibility.products) && navItemsVisibility.products.includes(1))}
              target="/products/products-plans-cycles"
              content="NAVBAR_PRODUCTS_PLANS_CYCLES"
            />
            <NavLink
              id="link-addons"
              isEnabled={navItemsVisibility.products === true || (Array.isArray(navItemsVisibility.products) && navItemsVisibility.products.includes(2))}
              target="/addons-allowances/addons"
              content="NAVBAR_ADDONS"
            />

            <NavLink
              id="link-allowances"
              isEnabled={navItemsVisibility.products === true || (Array.isArray(navItemsVisibility.products) && navItemsVisibility.products.includes(2))}
              target="/addons-allowances/allowances"
              content="NAVBAR_ADDONS_ALLOWANCES"
            />
            <NavLink
              id="link-allowances"
              isEnabled={navItemsVisibility.products === true || (Array.isArray(navItemsVisibility.products) && navItemsVisibility.products.includes(2))}
              target="/discount-and-coupons"
              content="NAVBAR_DISCOUNTS_AND_COUPONS"
            />
            {/*  
              HIDDEN FOR NOW
              <NavLink
                id="link-coupons"
                isEnabled={navItemsVisibility.products === true || (Array.isArray(navItemsVisibility.products) && navItemsVisibility.products.includes(3))}
                target="/coupons"
                content="NAVBAR_COUPONS"
              />
            */}

          </NavItem>

          {isMinimised ? (
            <Tooltip id="show-products-group" place="right">
              <ul>
                {(navItemsVisibility.products === true || (Array.isArray(navItemsVisibility.products) && navItemsVisibility.products.includes(1))) && (
                  <li>
                    <NavLink id="show-products-group-products-plans-cycles" target="/products/products-plans-cycles" content="NAVBAR_PRODUCTS_PLANS_CYCLES" isEnabled={true} />
                  </li>
                )}
                {(navItemsVisibility.products === true || (Array.isArray(navItemsVisibility.products) && navItemsVisibility.products.includes(2))) && (
                  <li>
                    <NavLink id="show-products-group-add-ons" target="/addons-allowances/add-ons" content="NAVBAR_ADDONS_ALLOWANCES" isEnabled={true} />
                  </li>
                )}
                {(navItemsVisibility.products === true || (Array.isArray(navItemsVisibility.products) && navItemsVisibility.products.includes(3))) && (
                  <li>
                    <NavLink id="show-products-group-coupons" target="/coupons" content="NAVBAR_COUPONS" isEnabled={true} />
                  </li>
                )}
              </ul>
            </Tooltip>
          ) : null}

          {/*
              HIDDEN FOR NOW
              <NavItem
                icon="fas fa-clipboard-list"
                content="NAVBAR_LOGS"
                id="link-logs-group"
                targets={logs.targets}
                isEnabled={!!navItemsVisibility.logs}
                dataFor={!!navItemsVisibility.logs ? 'show-logs-group' : undefined}
                isExpanded={logs.isExpanded}
                setIsExpanded={isLogsNavItemExpanded => setIsMenuItemxEpanded('logs', isLogsNavItemExpanded)}
              >
                <NavLink
                  id="link-transactions"
                  isEnabled={navItemsVisibility.logs === true || (Array.isArray(navItemsVisibility.logs) && navItemsVisibility.logs.includes(1))}
                  target="/transactions"
                  content="NAVBAR_TRANSACTIONS"
                />
                <NavLink
                  id="link-email-sms"
                  isEnabled={navItemsVisibility.logs === true || (Array.isArray(navItemsVisibility.logs) && navItemsVisibility.logs.includes(2))}
                  target="/email-sms"
                  content="NAVBAR_EMAILS_SMS"
                />
                <NavLink
                  id="link-events"
                  isEnabled={navItemsVisibility.logs === true || (Array.isArray(navItemsVisibility.logs) && navItemsVisibility.logs.includes(3))}
                  target="/events"
                  content="NAVBAR_EVENTS"
                />
              </NavItem>
            */}

          <NavItem
            isVisible={isTransactionLogsPermitted || isEmailLogsPermitted}
            icon="fas fa-clipboard-list"
            content="NAVBAR_LOGS"
            id="link-logs-group"
            targets={logs.targets}
            isEnabled={!!navItemsVisibility.logs && !isSuspended}
            dataFor={!!navItemsVisibility.logs ? "show-logs-group" : undefined}
            isExpanded={logs.isExpanded}
            setIsExpanded={isLogsNavItemExpanded => setIsMenuItemxEpanded("logs", isLogsNavItemExpanded)}
          >

            <NavLink
              id="link-email"
              isVisible={isEmailLogsPermitted}
              isEnabled={navItemsVisibility.logs === true || (Array.isArray(navItemsVisibility.logs) && navItemsVisibility.logs.includes(2))}
              target="/email-logs"
              content="NAVBAR_EMAILS"
            />

            <NavLink
              id="link-transactions"
              isVisible={isTransactionLogsPermitted}
              isEnabled={navItemsVisibility.logs === true || (Array.isArray(navItemsVisibility.logs) && navItemsVisibility.logs.includes(1))}
              target="/transactions"
              content="NAVBAR_TRANSACTIONS"
            />

            {/*
              HIDDEN FOR NOW
              <NavLink
                id="link-events"
                isEnabled={navItemsVisibility.logs === true || (Array.isArray(navItemsVisibility.logs) && navItemsVisibility.logs.includes(3))}
                target="/events"
                content="NAVBAR_EVENTS"
              />
              */
            }
          </NavItem>

          {isMinimised ? (
            <Tooltip id="show-logs-group" place="right">
              <ul>
                {(navItemsVisibility.logs === true || (Array.isArray(navItemsVisibility.logs) && navItemsVisibility.logs.includes(1))) && (
                  <li>
                    <NavLink id="show-logs-group-transactions" target="/transactions" content="NAVBAR_TRANSACTIONS" isEnabled={true} />
                  </li>
                )}
                {(navItemsVisibility.logs === true || (Array.isArray(navItemsVisibility.logs) && navItemsVisibility.logs.includes(2))) && (
                  <li>
                    <NavLink id="show-logs-group-email-sms" target="/email-sms" content="NAVBAR_EMAILS_SMS" isEnabled={true} />
                  </li>
                )}
                {(navItemsVisibility.logs === true || (Array.isArray(navItemsVisibility.logs) && navItemsVisibility.logs.includes(3))) && (
                  <li>
                    <NavLink id="show-logs-group-events" target="/events" content="NAVBAR_EVENTS" isEnabled={true} />
                  </li>
                )}
              </ul>
            </Tooltip>
          ) : null}

          <NavItem
            isVisible={isAccountingReportsPermitted || isSelfServiceReportsPermitted}
            icon="fas fa-analytics"
            content="NAVBAR_REPORTS"
            id="link-reports-group"
            targets={reports.targets}
            isEnabled={!!navItemsVisibility.reports && !isSuspended}
            dataFor={!!navItemsVisibility.reports ? "show-reports-group" : undefined}
            isExpanded={reports.isExpanded}
            setIsExpanded={isReportsNavItemExpanded => setIsMenuItemxEpanded("reports", isReportsNavItemExpanded)}
          >
            {/*
            <NavLink
              id="link-report-subscriptions"
              isEnabled={navItemsVisibility.reports === true || (Array.isArray(navItemsVisibility.reports) && navItemsVisibility.reports.includes(1))}
              target="/report-customers"
              content="NAVBAR_REPORTS_SUBSCRIPTIONS"
            />
             */}
            <NavLink
              id="link-accounting"
              isEnabled={navItemsVisibility.reports === true || (Array.isArray(navItemsVisibility.reports) && navItemsVisibility.reports.includes(2))}
              target="/report-accounting"
              content="NAVBAR_ACCOUNTING"
            />
            <NavLink
              id="link-self-service"
              isEnabled={navItemsVisibility.reports === true || (Array.isArray(navItemsVisibility.reports) && navItemsVisibility.reports.includes(3))}
              target="/report-checkout-self-service"
              content="NAVBAR_CHECKOUT_AND_SELF_SERVICE"
            />
            {/* 
           
            <NavLink
              id="link-briefing"
              isEnabled={navItemsVisibility.reports === true || (Array.isArray(navItemsVisibility.reports) && navItemsVisibility.reports.includes(4))}
              target="/report-briefing"
              content="NAVBAR_BILLSBY_BRIEFING"
            />
            */}
          </NavItem>

          {isMinimised ? (
            <Tooltip id="show-reports-group" place="right">
              <ul>
                {(navItemsVisibility.reports === true || (Array.isArray(navItemsVisibility.reports) && navItemsVisibility.reports.includes(1))) && (
                  <li>
                    <NavLink id="show-reports-group-report-subscriptions" target="/report-subscriptions" content="NAVBAR_REPORTS_SUBSCRIPTIONS" isEnabled={true} />
                  </li>
                )}
                {(navItemsVisibility.reports === true || (Array.isArray(navItemsVisibility.reports) && navItemsVisibility.reports.includes(2))) && (
                  <li>
                    <NavLink id="show-reports-group-accounting" target="/accounting" content="NAVBAR_ACCOUNTING" isEnabled={true} />
                  </li>
                )}
              </ul>
            </Tooltip>
          ) : null}

          {/* <NavItem
            icon="fas fa-plug"
            content="NAVBAR_ZAPIER"
            id="link-reports-group"
            isEnabled={!!navItemsVisibility.zapier && !isSuspended}
            targets={zapier.targets}
          /> */}

          {
            integrationsData.getIntegrationsList().filter(i => i.getIsInstalled()).length === 0
              ? <NavItem
                isVisible={isIntegrationsPermitted}
                icon="fas fa-boxes"
                content="NAVBAR_INTEGRATIONS"
                id="link-integrations-group"
                targets={integrations.targets}
                isEnabled={!!navItemsVisibility.integrations && !isSuspended}
              />
              : <NavItem
                isVisible={isIntegrationsPermitted}
                icon="fas fa-boxes"
                content="NAVBAR_INTEGRATIONS"
                id="link-integrations-group"
                targets={integrations.targets}
                hasError={hasQuickbooksError}
                isEnabled={!!navItemsVisibility.integrations && !isSuspended}
                dataFor={!!navItemsVisibility.integrations ? "show-integrations-group" : undefined}
                isExpanded={integrations.isExpanded}
                setIsExpanded={isIntegrationsNavItemExpanded => setIsMenuItemxEpanded("integrations", isIntegrationsNavItemExpanded)}
              >
                {integrationsData.getIntegrationsList()
                  .filter(i => i.getIsInstalled())
                  .map(i => renderIntegrationsNavItem(i.getName() as AccountingIntegrationType, { hasQuickbooksError, hasFreeAgentError: false, hasWebexError: false }))}
                <NavLink
                  id="link-add-integrations"
                  isEnabled={true}
                  icon="far fa-plus-circle"
                  target="/integrations/add"
                  content="NAVBAR_ADD_INTEGRATIONS"
                />
              </NavItem>}

          <NavItem
            isVisible={isEmbedCodesPermitted || hasAccessToConfiguration(parsedToken.UserRole)}
            icon="fas fa-cogs"
            content="NAVBAR_SETTINGS"
            id="link-settings-group"
            targets={settings.targets}
            isEnabled={!!navItemsVisibility.settings && !isSuspended}
            dataFor={!!navItemsVisibility.settings ? "show-settings-group" : undefined}
            isExpanded={settings.isExpanded}
            setIsExpanded={isSettingsNavItemExpanded => setIsMenuItemxEpanded("settings", isSettingsNavItemExpanded)}
          >
            <NavLink
              isVisible={hasAccessToConfiguration(parsedToken.UserRole)}
              id="link-configuration"
              isEnabled={navItemsVisibility.settings === true || (Array.isArray(navItemsVisibility.settings) && navItemsVisibility.settings.includes(1))}
              target="/configuration"
              content="NAVBAR_CONFIGURATION"
            />
            <NavLink
              isVisible={isEmbedCodesPermitted}
              id="link-embed-codes"
              isEnabled={navItemsVisibility.settings === true || (Array.isArray(navItemsVisibility.settings) && navItemsVisibility.settings.includes(2))}
              target="/embed-codes"
              content="NAVBAR_EMBED_CODES"
            />
            {/* <NavLink
              id="link-integrations"
              isEnabled={navItemsVisibility.settings === true || (Array.isArray(navItemsVisibility.settings) && navItemsVisibility.settings.includes(3))}
              target="/integrations"
              content="NAVBAR_INTEGRATIONS"
            /> */}
          </NavItem>

          {isMinimised ? (
            <Tooltip id="show-settings-group" place="right">
              <ul>
                {(navItemsVisibility.settings === true || (Array.isArray(navItemsVisibility.settings) && navItemsVisibility.settings.includes(1))) && (
                  <li>
                    <NavLink id="show-settings-group-configuration" target="/configuration" content="NAVBAR_CONFIGURATION" isEnabled={true} />
                  </li>
                )}
                {(navItemsVisibility.settings === true || (Array.isArray(navItemsVisibility.settings) && navItemsVisibility.settings.includes(2))) && (
                  <li>
                    <NavLink id="show-settings-group-embed-codes" target="/embed-codes" content="NAVBAR_EMBED_CODES" isEnabled={true} />
                  </li>
                )}
                {(navItemsVisibility.settings === true || (Array.isArray(navItemsVisibility.settings) && navItemsVisibility.settings.includes(3))) && (
                  <li>
                    <NavLink id="show-settings-group-integrations" target="/integrations" content="NAVBAR_INTEGRATIONS" isEnabled={true} />
                  </li>
                )}
              </ul>
            </Tooltip>
          ) : null}

          {dashboardSettings && !dashboardSettings.isCurrencyMappingMatched && (
            <Panel className="navbar__currency-mismtached-banner">
              <Text content="ALERT_CURRENCY_MAPPING_MISMATCH_TITLE"></Text>
              <Button onClick={() => history.push("/configuration/currencies")} isFullWidth icon="fas fa-wrench" id="fix-mismatched" title="ALERT_CURRENCY_MAPPING_MISMATCH_FIXTHIS" />
            </Panel>
          )}


          {isGoLivePermitted && dashboardSettings && dashboardSettings.status === GettingStartedType.PreLive && !isMinimised ? (
            <Panel className="navbar__golive-banner">
              <Text className="navbar__golive-banner__title" content="NAVBAR_GOLIVE_TEXT" />
              <Text content="NAVBAR_GOLIVE_CONTENT" />
              <Button
                id="golive-btn"
                className={history.location.pathname.includes("/launch-checklist") ? "golive-btn--active" : ""}
                title="NAVBAR_GOLIVE_BTN"
                onClick={() => history.push("/launch-checklist")}
                isFullWidth
              />
            </Panel>
          ) : null}
        </div>

        <div className="navbar__content-footer">
          <div onClick={generateSupportCode}>
            <NavItem
              icon="fas fa-question-circle"
              className={isMinimised ? "navbar__content-body__item--minimised" : ""}
              content={counterpart("NAVBAR_HELP")}
              isEnabled={!!navItemsVisibility.help && !isSuspended}
              shouldTranslate={false}
              dataFor={!!navItemsVisibility.help ? "show-docs" : undefined}
            />
          </div>
          {!isSuspended && <Tooltip id="show-docs" place="top">
            <ul className="navbar__content-footer__tip">
              <FooterTooltipItem
                icon="fas fa-book"
                content="HEADER_TOOLTIP_DOCUMENTATION"
                isEnabled={navItemsVisibility.help === true || (Array.isArray(navItemsVisibility.help) && navItemsVisibility.help.includes(1))}
                onClick={() => openInNewTab(readmeioLinks.docs)}
              />
              <FooterTooltipItem
                icon="fas fa-code"
                content="HEADER_TOOLTIP_APISPEC"
                onClick={() => openInNewTab(readmeioLinks.api)}
                isEnabled={navItemsVisibility.help === true || (Array.isArray(navItemsVisibility.help) && navItemsVisibility.help.includes(2))}
              />
              <FooterTooltipItem
                icon="fas fa-comments"
                content="HEADER_TOOLTIP_COMMUNITY"
                onClick={() => openInNewTab(readmeioLinks.discuss)}
                isEnabled={navItemsVisibility.help === true || (Array.isArray(navItemsVisibility.help) && navItemsVisibility.help.includes(3))}
              />
              <FooterTooltipItem
                icon="fas fa-video"
                content="HEADER_TOOLTIP_VIDEOS"
                onClick={() => openInNewTab("https://www.youtube.com/channel/UCjqmQ0xkmDLcTHCH9Q1PN2g")}
                isEnabled={navItemsVisibility.help === true || (Array.isArray(navItemsVisibility.help) && navItemsVisibility.help.includes(4))}
              />
              <FooterTooltipItem
                icon="fas fa-lightbulb"
                content="HEADER_TOOLTIP_WHATSNEW"
                onClick={() => openInNewTab("https://feedback.billsby.com")}
                isEnabled={navItemsVisibility.help === true || (Array.isArray(navItemsVisibility.help) && navItemsVisibility.help.includes(5))}
              />
              <FooterTooltipItem
                icon="fas fa-cloud"
                content="HEADER_TOOLTIP_SERVICE_STATUS"
                onClick={() => openInNewTab("https://status.billsby.com")}
                isEnabled={navItemsVisibility.help === true || (Array.isArray(navItemsVisibility.help) && navItemsVisibility.help.includes(6))}
              />
              <FooterTooltipItem
                icon="fas fa-headset"
                content="HEADER_TOOLTIP_TALKTOUS"
                onClick={openIntercomIframe}
                isEnabled={navItemsVisibility.help === true || (Array.isArray(navItemsVisibility.help) && navItemsVisibility.help.includes(7))}
              />
              <hr className="navbar__content-hr" />
              <FooterTooltipItem
                icon="fas fa-book"
                content="HEADER_TOOLTIP_PERMITACCOUNT"
                onClick={() => setShowAccountAccessModal(true)}
                isEnabled={navItemsVisibility.help === true || (Array.isArray(navItemsVisibility.help) && navItemsVisibility.help.includes(8))}
              />
              <FooterTooltipItemSupportCode icon="fas fa-phone" content="HEADER_TOOLTIP_SUPPORTCODE" code={supportCode} />
            </ul>
          </Tooltip>}
          <NavItem
            icon="fas fa-building"
            className={isMinimised ? "navbar__content-body__item--minimised" : ""}
            content={companyName.trunc(20)}
            isEnabled={!!navItemsVisibility.company && !isSuspended}
            shouldTranslate={false}
            dataFor={!!navItemsVisibility.company ? "show-company" : undefined}
          />

          {!isSuspended && <Tooltip id="show-company" place="top">
            <ul className="navbar__content-footer__tip">
              <FooterTooltipItem
                onClick={() => {
                  dispatch(setShowBillingModal(!showBillingModal))
                }}
                icon="fas fa-file-invoice-dollar"
                content="HEADER_TOOLTIP_BILLING"
                isEnabled={navItemsVisibility.company === true || (Array.isArray(navItemsVisibility.company) && navItemsVisibility.company.includes(1))}
              />
              <FooterTooltipItem
                onClick={() => {
                  setShowTeammatesModal(!showTeammatesModal);
                }}
                icon="fas fa-users-cog"
                content="HEADER_TOOLTIP_TEAMMATES"
                isEnabled={isTeammatesPermitted && (navItemsVisibility.company === true || (Array.isArray(navItemsVisibility.company) && navItemsVisibility.company.includes(2)))}
              />
              <FooterTooltipItem
                onClick={() => {
                  setShowSandboxModal(!showSandboxModal);
                }}
                icon="fas fa-umbrella-beach"
                content="HEADER_TOOLTIP_SANDBOX"
                isEnabled={isSandboxPermitted && (navItemsVisibility.company === true || (Array.isArray(navItemsVisibility.company) && navItemsVisibility.company.includes(3)))}
              />
            </ul>
          </Tooltip>}
          <NavItem
            icon="fas fa-user"
            className={`${isMinimised ? "navbar__content-body__item--minimised" : ""} navbar__content-body__item--margin`}
            content={parsedToken.given_name}
            isEnabled={!!navItemsVisibility.user}
            shouldTranslate={false}
            dataFor={!!navItemsVisibility.user ? "show-user" : undefined}
          />
          <Tooltip id="show-user" place="top">
            <ul className="navbar__content-footer__tip">
              <FooterTooltipItem
                onClick={() => setShowEditProfileModal(!showEditProfileModal)}
                icon="fas fa-user"
                content="HEADER_TOOLTIP_EDITPROFILE"
                isEnabled={navItemsVisibility.user === true || (Array.isArray(navItemsVisibility.user) && navItemsVisibility.user.includes(1))}
              />
              <FooterTooltipItem
                onClick={() => setShowChangePasswordModal(!showChangePasswordModal)}
                icon="fas fa-key"
                content="HEADER_TOOLTIP_CHANGEPASSWORD"
                isEnabled={navItemsVisibility.user === true || (Array.isArray(navItemsVisibility.user) && navItemsVisibility.user.includes(2))}
              />
              <FooterTooltipItem
                icon="fas fa-shield-check"
                onClick={() => setShowTwoFactorAuthModal(!showTwoFactorAuthModal)}
                content="HEADER_TOOLTIP_TWOFACTORAUTH"
                isEnabled={navItemsVisibility.user === true || (Array.isArray(navItemsVisibility.user) && navItemsVisibility.user.includes(3))}
              />
              <FooterTooltipItem
                onClick={logout}
                icon="fas fa-sign-out-alt"
                content="HEADER_TOOLTIP_LOGOUT"
                isEnabled={navItemsVisibility.user === true || (Array.isArray(navItemsVisibility.user) && navItemsVisibility.user.includes(4))}
              />

              {navItemsVisibility.user === true || (Array.isArray(navItemsVisibility.user) && navItemsVisibility.user.includes(5)) ? (
                <div>
                  <div className="navbar__content-footer__tip__sub">{counterpart("HEADER_TOOLTIP_SWITCHCOMPANIES")}</div>
                  {companies
                    .filter(company => company.companyDomain !== currentCompanyDomain)
                    .sort((a, b) => {
                      return a.name > b.name ? 1 : -1;
                    })
                    .map((company: ICompany) => (
                      <li
                        key={company.companyId}
                        onClick={() => {
                          (async () => {
                            storeSet(BILLSBY_SELECTED_COMPANY, company.companyId);
                            await refreshToken(company.companyId);
                            window.location.replace(`//${company.companyDomain}${ConfigConstants.billsbyDomain}${ConfigConstants.reactAppEnvironment === "Localhost" ? `:${process.env.REACT_APP_PORT}` : ""}`);
                          })()
                        }}
                      >
                        {company.name}
                      </li>
                    ))}
                  <FooterTooltipItem isEnabled={isAddCompanyPermitted} onClick={() => setShowAddCompanyModal(!showAddCompanyModal)} icon="fas fa-plus-circle" content="HEADER_TOOLTIP_ADDCOMPANY" />
                </div>
              ) : null}
            </ul>
          </Tooltip>

          <Modal isOpen={showTeammatesModal} onRequestClose={() => setShowTeammatesModal(!showTeammatesModal)} title="TEAMMATES_WELCOME">
            <Teammates />
          </Modal>
          <Modal isOpen={showBillingModal} onRequestClose={() => {
            (async () => {
              dispatch(setShowBillingModal(!showBillingModal));
            })()
          }} title="BILLING_WELCOME">
            {BillingModalLookUp[dashboardSettings === null ? GettingStartedType.GettingStarted1 : dashboardSettings.status]}
          </Modal>
          <Modal isOpen={showSandboxModal} onRequestClose={() => setShowSandboxModal(!showSandboxModal)} title="SANDBOX_WELCOME">
            {SandboxModalLookUp[dashboardSettings === null ? GettingStartedType.GettingStarted1 : dashboardSettings.status]}
          </Modal>
          <Modal isOpen={showEditProfileModal} onRequestClose={() => setShowEditProfileModal(!showEditProfileModal)} title="EDIT_PROFILE_MODAL_TITLE">
            <EditProfile />
          </Modal>
          <Modal isOpen={showAddCompanyModal} onRequestClose={() => setShowAddCompanyModal(!showAddCompanyModal)} title="ADD_COMPANY_MODAL_TITLE">
            <AddCompany />
          </Modal>
          <Modal isOpen={showChangePasswordModal} onRequestClose={() => setShowChangePasswordModal(!showChangePasswordModal)} title="CHANGE_PASSWORD_MODAL_TITLE">
            <ChangePassword />
          </Modal>
          <Modal isOpen={showTwoFactorAuthModal} onRequestClose={() => setShowTwoFactorAuthModal(!showTwoFactorAuthModal)} title="TWO_FACTOR_AUTH_MODAL_TITLE">
            <TwoFactorAuth />
          </Modal>
          <Modal isOpen={showAccountAccessModal} onRequestClose={() => setShowAccountAccessModal(!showAccountAccessModal)} title="ACCOUNT_ACCESS_MODAL_TITLE">
            <AccountAccess />
          </Modal>
        </div>
      </div>
    </div>
  );
}

export default Nav;