import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchCurrencies, setProductCyclesUnmount, fetchProducts, setProductsCustomFields } from "../../actions/productPlanCycleActions";
import ProductPlanCycleList from "../../components/products/productPlanCycle/ProductPlanCycleList";
import CreateProduct from "../../components/products/create-product/CreateProduct";
import CreatePlan from "../../components/products/create-plan/CreatePlan";
import { AppState } from "../..";
import { IPlanReducer } from "../../reducers/plansReducer";
import { ICycleReducer } from "../../reducers/cyclesReducer";
import { IProductReducerState } from "../../reducers/productsReducer";
import { IAuthReducerState } from "../../reducers/authReducer";
import { PrivateRoute } from "../AuthRoutes";
import ErrorNotification from "../../components/ui/error-notification/ErrorNotification";
import ProgressIndicator from "../../components/ui/progress-indicator/ProgressIndicator";


const ProductPlanCycle: React.FC = () => {
  const plansReducerData = useSelector<AppState, IPlanReducer>(state => state.plans)
  const cyclesReducerData = useSelector<AppState, ICycleReducer>(state => state.cycles)
  const productsReducerData = useSelector<AppState, IProductReducerState>(state => state.products)
  const authReducerData = useSelector<AppState, IAuthReducerState>(state => state.auth)
  const dispatch = useDispatch<Function>()

  const { isCreatingPlanFailure, isEditingPlanFailure } = plansReducerData;
  const { isCreatingCyclesFailure } = cyclesReducerData;
  const { isCreatingProductFailure, isEditingProductFailure } = productsReducerData;

  const productError = productsReducerData.errorMessage; 
  const planError = plansReducerData.errorMessage;
  const cycleError = plansReducerData.errorMessage;

  const { currentCompanyDomain } = authReducerData;
  const [isFetching, setIsFetching] = useState(false);

  const fetchData = async () => {
    setIsFetching(true);
    try {
      await dispatch(fetchCurrencies());
      const { response } = await dispatch(fetchProducts(currentCompanyDomain, 1));
      if (response.results && response.results.length === 1) {
        dispatch(setProductsCustomFields("firstCreatedProduct", response.results[0]))
      } 
      setIsFetching(false);
      setIsFetching(false);
    } catch (err) {
      setIsFetching(false);
      console.log(err);
    }
  };

  useEffect(() => {
    fetchData();

    return () => dispatch(setProductCyclesUnmount())
  }, []);


  if (isFetching) {
    return <ProgressIndicator color="blue" coverage='full-content'/>
  }

  return (
    <div>
      <PrivateRoute exact path="/products/products-plans-cycles" render={() => <ProductPlanCycleList reloadData={fetchData} />} />
      <PrivateRoute exact path="/products/products-plans-cycles/create-product" component={CreateProduct} />
      <PrivateRoute exact path="/products/products-plans-cycles/edit-product/:productId" component={CreateProduct} />
      <PrivateRoute exact path="/products/products-plans-cycles/:planProductId/create-plan" component={CreatePlan} />
      <PrivateRoute exact path="/products/products-plans-cycles/:planProductId/edit-plan/:planId" component={CreatePlan} />

      {/* Error alerts */}
      <ErrorNotification closingTime={8000} title={productError ? productError : "CREATE_PRODUCT_FAILURE"} shouldTranslateTitle={!productError}  showAlert={isCreatingProductFailure || isEditingProductFailure} />
      <ErrorNotification closingTime={8000} title={planError ? planError : "CREATE_PLAN_FAILED"} shouldTranslateTitle={!planError}  showAlert={isCreatingPlanFailure || isEditingPlanFailure} />
      <ErrorNotification closingTime={8000} title={cycleError ? cycleError : "CREATE_CYCLES_FAILED"} shouldTranslateTitle={!cycleError} showAlert={isCreatingCyclesFailure} />

    </div>
  );
};



export default ProductPlanCycle
