import React, { useState } from "react";
import "./DashboardPreLive.scss";

import Panel from "../../../components/ui/panel/Panel";
import Text from "../../../components/ui/text/Text";
import history from "../../../utils/history";
import PageWrapper from "../../ui/layout/PageWrapper";
import Button from "../../ui/button/Button";
import "./DashboardPreLive.scss";
import { Row, Col } from "react-grid-system";
import ErrorPanel from "../../ui/error-panel/ErrorPanel";
import Modal from "../../modal/Modal";
import Teammates from "../../teammates/Teammates";
import { Link } from "react-router-dom";
import counterpart from "counterpart";
import { Permission } from "../../../models/Auth";
import useCheckFeaturePermission from "../../../utils/hooks/useCheckFeaturePermission";

const DashboardPreLive = () => {
  const [isOpenTeammates, setIsOpenTeammates] = useState(false);
  const isEmbedCodesReadPermitted = useCheckFeaturePermission(Permission.EmbedCodesRead);
  const isPaymentGatewaysReadPermitted = useCheckFeaturePermission(Permission.PaymentGatewaysRead);
  const isManualTaxConfigurationReadPermitted = useCheckFeaturePermission(Permission.ManualTaxConfigurationRead);
  const isGoLivePermitted = useCheckFeaturePermission(Permission.GoLive);
  const isTeammateAddPermitted = useCheckFeaturePermission(Permission.TeammateAdd);

  return (
    <PageWrapper className="dashboard__prelive">

      <div className="dashboard__prelive-heading1">
        <div className="text">
          <Text content="DASHBOARD_PRELIVE_HEADING1" translateWith={{
            link: <Link title={counterpart("DASHBOARD_GOLIVE_LINK")}  to="/launch-checklist" onClick={(evt: any) => {
              evt.preventDefault();
              history.push("/launch-checklist")
            }}>{counterpart("DASHBOARD_GOLIVE_LINK")}</Link>
          }} className="title" />
          <div className="product-manager">
            <Text content="DASHBOARD_PRELIVE_HEADING1_JESS" />
            <Text content="DASHBOARD_PRELIVE_HEADING1_PRODUC_MANAGER" />
          </div>
        </div>
      </div>

      <Panel title="DASHBOARD_PRELIVE_HEADING2_TITLE" className="dashboard__prelive-heading2">
        <Text content="DASHBOARD_PRELIVE_HEADING2_CONTENT" />
        <Button
          id="embed-code"
          buttonType="add"
          icon="fal fa-code"
          disabled={!isEmbedCodesReadPermitted}
          tooltipMessage={!isEmbedCodesReadPermitted ? "NO_ACCESS_MSG" : undefined}
          title="DASHBOARD_PRELIVE_HEADING2_BUTTON"
          onClick={() => history.push("/embed-codes/checkout")}
        />
      </Panel>

      <Row align="center">
        <Col xs={6}>
          <Panel title="DASHBOARD_PRELIVE_HEADING3_TITLE" className="dashboard__prelive-heading3">
            <Text content="DASHBOARD_PRELIVE_HEADING3_CONTENT" />
            <Button
              id="payment-gateways"
              buttonType="add"
              disabled={!isPaymentGatewaysReadPermitted}
              tooltipMessage={!isPaymentGatewaysReadPermitted ? "NO_ACCESS_MSG" : ""}
              icon="fal fa-credit-card"
              title="DASHBOARD_PRELIVE_HEADING3_BUTTON"
              onClick={() => history.push("/configuration/payment-gateways")}
            />
          </Panel>
        </Col>
        <Col xs={6}>
          <Panel title="DASHBOARD_PRELIVE_HEADING4_TITLE" className="dashboard__prelive-heading4">
            <Text content="DASHBOARD_PRELIVE_HEADING4_CONTENT" />
            <Button
              id="tax-configuration"
              buttonType="add"
              disabled={!isManualTaxConfigurationReadPermitted}
              tooltipMessage={!isManualTaxConfigurationReadPermitted ? "NO_ACCESS_MSG" : ""}
              icon="fal fa-gavel"
              title="DASHBOARD_PRELIVE_HEADING4_BUTTON"
              onClick={() => history.push("/configuration/taxes")}
            />
          </Panel>
        </Col>
      </Row>

      <Panel title="DASHBOARD_PRELIVE_HEADING5_TITLE" className="dashboard__prelive-heading5">
        <Text content="DASHBOARD_PRELIVE_HEADING5_CONTENT" />
        <Button
          id="webinar"
          buttonType="add"
          icon="far fa-webcam"
          disabled={!isGoLivePermitted}
          tooltipMessage={!isGoLivePermitted ? "NO_ACCESS_MSG" : undefined}
          title="DASHBOARD_PRELIVE_HEADING5_BUTTON"
          onClick={() => window.open("https://calendly.com/billsby/billsby-setup-assistance", "_self")}
        />
      </Panel>

      <Panel title="DASHBOARD_PRELIVE_HEADING6_TITLE" className="dashboard__prelive-heading6">
        <Text content="DASHBOARD_PRELIVE_HEADING6_CONTENT" />
        <Button
          id="teammates"
          buttonType="add"
          icon="fal fa-users"
          disabled={!isTeammateAddPermitted}
          tooltipMessage={!isTeammateAddPermitted ? "NO_ACCESS_MSG" : undefined}
          title="DASHBOARD_PRELIVE_HEADING6_BUTTON"
          onClick={() => setIsOpenTeammates(true)}
        />
      </Panel>

      <ErrorPanel
        title="DASHBOARD_PRELIVE_HELP_TITLE"
        subtitle="DASHBOARD_PRELIVE_HELP_CONTENT"
      />

      <Modal isOpen={isOpenTeammates} onRequestClose={() => setIsOpenTeammates(!isOpenTeammates)} title="TEAMMATES_WELCOME">
        <Teammates />
      </Modal>

    </PageWrapper>
  )
}

export default DashboardPreLive;