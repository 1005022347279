import React, { useState, useRef } from "react";
import Panel from "../ui/panel/Panel";
import Text from "../ui/text/Text";
import Button from "../ui/button/Button";
import RangeSlider from "../ui/range-slider/RangeSlider";
import { QUIZ_FIELD_SUBSCRIBER_BASE_TYPE } from "../../utils/constants";

interface IQuizStep5 {
  onContinue: (nextStep: number, fieldType: string, fieldValue: string) => any
}

const adjustScale = (slider: any) => {
  /*const sliderDOM: any = findDOMNode(slider.current);
  const handle = sliderDOM.querySelector(".rc-slider-handle");
  //p.style = p.style || {};
  handle.style.setProperty("left", `${Number(handle.style.left.replace("%", "")) + 20}%`, "important");*/
}

const QuizStep5: React.FC<IQuizStep5> = ({ onContinue }) => {
  const [value, setValue] = useState(5000);
  const slider = useRef(null);
  return (
    <div className="quiz quiz-step5">
      <Panel title="QUIZ_STEP5_TITLE">
        <Text content="QUIZ_STEP5_SUBTITLE" />
        <RangeSlider
          ref={slider}
          className="quiz-step5__slider"
          min={0}
          max={220000}
          defaultValue={value}
          value={value}
          marks={{ 5000: 5000, 20000: 20000, 50000: 50000, 200000: 200000 }}
          step={1}
          onChange={(value: any) => { setValue(value); adjustScale(slider)}}
          tipFormatter={(value: number) => value !== 220000 ? `${value}` : `${value}+`}
        />
        <Button id="quiz-step5-btn" title="QUIZ_STEP5_BTN" isFullWidth onClick={() => onContinue(7, QUIZ_FIELD_SUBSCRIBER_BASE_TYPE, value + "")} />
        <Text content="QUIZ_STEP5_LINK" className="quiz__link" noMargin onClick={() => onContinue(7, QUIZ_FIELD_SUBSCRIBER_BASE_TYPE, "Ignore")} />
      </Panel>
    </div>
  )
}

export default QuizStep5;