import React, { useState } from "react"
import MenuPanel from "../../ui/menu-panel/MenuPanel";
import StepCollapsablePanel from "../../ui/step-collapsable-panel/StepCollapsablePanel";
import Text from "../../ui/text/Text";
import FormGroup from "../../ui/form-group/FormGroup";
import { Row, Col } from "react-grid-system";
import FormLabel from "../../ui/form-label/FormLabel";
import Input from "../../ui/input/Input";
import { allowZeroAndPositiveNumbers, isPriceWith2Decimals } from "../../../utils/commonUtils";
import Button from "../../ui/button/Button";
import counterpart from "counterpart";
import Switch from "../../ui/switch/Switch";
import { AddonsAllowancesScreen } from "../../../models/AddonsAllowances";

interface IProps {
  currency: string,
  frequency: string,
  price?: number,
  nrUnits?: number,
  configure: (price?: number, nrUnits?: number) => void,
  cancel: () => void,
  isPerUnit?: boolean
  type: AddonsAllowancesScreen;
}

const FlatFeePriceModel: React.FC<IProps> = React.memo(({ currency, frequency, price, nrUnits = "", configure, cancel, isPerUnit = false, type }) => {
  const [tempPrice, setTempPrice] = useState(price);
  const [hasIncludedUnits, setHasIncludedUnits] = useState(!!nrUnits);
  const [includedUnits, setIncludedUnits] = useState(`${nrUnits}`);
  const isAddonScreen = type === AddonsAllowancesScreen.ADDONS;
  const extractSymbol = currency.replace(/ |_/g, "");
  const getPriceModelHelpTitle = () => {
    return isAddonScreen
      ? "CREATE_ADD_ONS_PRICE_MODELS_CONFIGURE_PRICE_LABEL"
      : "CREATE_ALLOWANCES_PRICE_MODELS_CONFIGURE_PRICE_LABEL";
  }
  const getPriceModelHelpContent = () => {
    return isAddonScreen
      ? [
        "CREATE_ADD_ONS_PRICE_MODELS_CONFIGURE_PRICE_HELP_CONTENT1",
        "CREATE_ADD_ONS_PRICE_MODELS_CONFIGURE_PRICE_HELP_CONTENT2"
      ]
      : [
        "CREATE_ALLOWANCES_PRICE_MODELS_CONFIGURE_PRICE_HELP_CONTENT1",
        "CREATE_ALLOWANCES_PRICE_MODELS_CONFIGURE_PRICE_HELP_CONTENT2"
      ]
  }
  const [helpTitle, setHelpTitle] = useState(getPriceModelHelpTitle());
  const [helpContent, setHelpContent] = useState(getPriceModelHelpContent());


  const getIncludedUnitsGroup = () => {
    return (
      <Row align="center" className="step-addon-cost__configurePanel-includedunits">
        <Col xs={2}>
          <FormLabel target="units-switch" content="CREATE_ALLOWANCES_PRICE_MODELS_CONFIGURE_INCLUDEDUNITS_LABEL" />
        </Col>
        <Col xs={2}>
          <Switch id="units-switch" checked={hasIncludedUnits} onChange={isChecked => {
            setHasIncludedUnits(isChecked);
            setHelpTitle("CREATE_ALLOWANCES_PRICE_MODELS_INCLUDEDUNITS_HELP_TITLE");
            setHelpContent(["CREATE_ALLOWANCES_PRICE_MODELS_INCLUDEDUNITS_HELP_CONTENT"]);
            if (!isChecked) {
              setIncludedUnits("0");
              setHelpTitle(getPriceModelHelpTitle());
              setHelpContent(getPriceModelHelpContent());
            }
          }} />
        </Col>
        <Col xs={8}>
          {hasIncludedUnits && (
            <Input
              id="unit-switch-input"
              type="number"
              allowDecimal={false}
              value={includedUnits}
              min={0}
              onFocus={() => {
                setHelpTitle("CREATE_ALLOWANCES_PRICE_MODELS_INCLUDEDUNITS_HELP_TITLE");
                setHelpContent(["CREATE_ALLOWANCES_PRICE_MODELS_INCLUDEDUNITS_HELP_CONTENT"]);
              }}
              onChange={evt => Number.isInteger(+evt.target.value) && setIncludedUnits(evt.target.value)}
            />
          )}
        </Col>
      </Row>
    )
  }

  return (
    <MenuPanel className="step-addon-cost__configurePanel">
      <MenuPanel.Content>
        <StepCollapsablePanel
          title="CREATE_ADD_ONS_PRICE_MODELS_CONFIGURE_TITLE"
          isCompleted={false}
          hasError={false}
          isLocked={false}
          hasHelpSection={true}
          isCollapsed={false}
          helpMenuPosition="right"
          helpMenuTitle={helpTitle}
          helpMenuContent={
            <>
              {helpContent.map((el, id) => <Text key={id} content={el} />)}
            </>
          }
          onClick={() => { }}
        >
          <FormGroup>
            {!isAddonScreen && isPerUnit && getIncludedUnitsGroup()}
            <Row align="center">
              <Col xs={1.5}>
                <div className="price-label">
                  <FormLabel target="" content="CREATE_ADD_ONS_PRICE_MODELS_CONFIGURE_PRICE_LABEL" noMargin />
                  <FormLabel target="" content={extractSymbol} shouldTranslate={false} noMargin />
                </div>
              </Col>
              <Col xs={isPerUnit ? 7.5 : 8.5}>
                <Input
                  id="price"
                  type="number"
                  value={tempPrice}
                  placeholder="10.00"
                  onChange={(evt) => {
                    const value = parseFloat(evt.target.value).toFixed(2);
                    setTempPrice(allowZeroAndPositiveNumbers(value))
                  }}
                  onFocus={() => {
                    setHelpTitle(getPriceModelHelpTitle());
                    setHelpContent(getPriceModelHelpContent());
                  }}
                />
              </Col>
              <Col xs={isPerUnit ? 3 : 2}>
                <FormLabel
                  target=""
                  content={isPerUnit ? `${counterpart("CREATE_ADD_ONS_PRICE_MODELS_CONFIGURE_PERUNIT")} ${frequency}` : frequency}
                  shouldTranslate={false}
                  noMargin
                />
              </Col>
            </Row>
          </FormGroup>
          <Row align="center">
            <Col xs={8}>
              <Button
                id="configure-price"
                title="CREATE_ADD_ONS_PRICE_MODELS_CONFIGURE_PRICE_BTN"
                disabled={!isPriceWith2Decimals(tempPrice + "") || (hasIncludedUnits && !includedUnits) || (hasIncludedUnits && !(+includedUnits))}
                onClick={() => configure(tempPrice, hasIncludedUnits ? +includedUnits : 0)}
                isFullWidth
              />
            </Col>
            <Col xs={4}>
              <Button
                id="cancel"
                title="CREATE_ADD_ONS_PRICE_MODELS_CONFIGURE_PRICE_CANCEL"
                onClick={cancel}
                buttonType="error"
                isFullWidth
              />
            </Col>
          </Row>
        </StepCollapsablePanel>
      </MenuPanel.Content>
    </MenuPanel>
  )
})

export default FlatFeePriceModel
