import React from "react";
import Panel from "../panel/Panel";
import Text from "../text/Text";
import Button from "../button/Button";
import "./CheckList.scss";

interface ICheckListElement {
  title: string,
  shouldTranslateTitle?: boolean,
  isDone: boolean,
  btnTitle?: string,
  btnIcon?: string,
  shouldTranslateBtnTitle?: boolean,
  btnClb?: () => void,
  isBtnVisible: boolean
}

interface ICheckList {
  data: Array<ICheckListElement>
}

const CheckList: React.FC<ICheckList> = ({ data }) => {
  return (
    <div className="checklist">
      {
        data.map((el: ICheckListElement, idx) => {
          const { title, shouldTranslateTitle = true, isDone, btnTitle = "", btnIcon = "fas fa-tools", shouldTranslateBtnTitle, btnClb, isBtnVisible } = el;
          return (
            <Panel key={idx} className="checklist__element">
              <div>
                <i className={isDone ? "fas fa-check-circle" : "fas fa-times-circle"} />
                <Text content={title} shouldTranslate={shouldTranslateTitle} noMargin />
              </div>
              <Button
                id={`checklist-element-${idx}`}
                className="checklist__element__btn"
                title={btnTitle}
                shouldTranslate={shouldTranslateBtnTitle}
                onClick={btnClb}
                icon={btnIcon}
                isVisible={isBtnVisible}
              />
            </Panel>
          )
        })
      }
    </div>
  )
}

export default CheckList;