import React, { useState, useMemo } from "react";
import Text from "../../../components/ui/text/Text";
import Button from "../../../components/ui/button/Button";
import Table from "../../../components/ui/table/Table";
import StatusLabel from "../../../components/ui/status-label/StatusLabel";
import { getProductStatusText, getProductStatusColor, getCycleFrequencyText, getPricingModelTypeEnum, getFrequencyTypeEnum, getUnitsOverageText, getNumberOfDays } from "../../../utils/commonUtils";
import { PricingModelType } from "../../../models/Product";
import { Addon, AddonPriceModel } from "../../../utils/grpc/generated/Billsby.Protos/core/private/addons/addons_pb";
import counterpart from "counterpart";
import history from "../../../utils/history";
import { Int32Value } from "google-protobuf/google/protobuf/wrappers_pb";
import { AddonsAllowancesScreen } from "../../../models/AddonsAllowances";
import { Allowance, AllowancePriceModel, PricingModelType as PricingModelTypeGrpc } from "../../../utils/grpc/generated/Billsby.Protos/core/private/allowances/allowances_pb";

interface IAddOnsListRow {
  data: Addon | Allowance;
  type: AddonsAllowancesScreen;
  onDelete: (data: Addon | Allowance) => void,
  disableEdit?: boolean,
  disableDelete?: boolean
}

const AddOnsListRow: React.FC<IAddOnsListRow> = React.memo(({ data, type, onDelete, disableDelete = false, disableEdit = false }) => {
  const [expandedTable, setExpandedTable] = useState<"plans" | "cycles" | null>(null);

  const sortedPlansList = useMemo(() => data.getPlansList().sort((a, b) => {
    return a.getProductName().toLowerCase().localeCompare(b.getProductName().toLowerCase())
  }), [data.getPlansList()]);

  const getCycleDescriptionFormat = (priceModel: AddonPriceModel | AllowancePriceModel) => {
    if (getPricingModelTypeEnum(data.getPricingModelType()) === PricingModelType.Capped) {
      return "-";
    }
    if (priceModel.getTiersList().length === 0) {
      return ((priceModel as AddonPriceModel).getFlatFeePriceFormatted && (priceModel as AddonPriceModel).getFlatFeePriceFormatted())
        || priceModel.getPerUnitPriceFormatted();
    }

    return getUnitsOverageText(priceModel, getPricingModelTypeEnum(data.getPricingModelType()));

  };

  const getPricingModels = (): Array<AddonPriceModel | AllowancePriceModel> => {
    return type === AddonsAllowancesScreen.ADDONS
      ? (data as Addon).getPriceModelsList()
      : (data as Allowance).getPriceModelsList()
  }

  const sortedPriceModels = useMemo(() => getPricingModels().sort((a, b) => {
    return getNumberOfDays(a) - getNumberOfDays(b)
  }), [data])

  const renderPlansListTable = () => {
    return (
      <Table className="add-ons-list__inner-table">
        <thead>
          <tr>
            <Text component="th" content="ADD_ONS_LIST_TABLE_PRODUCT_NAME" noMargin />
            <Text component="th" content="ADD_ONS_LIST_TABLE_PLAN_NAME" noMargin />
            <Text component="th" content="ADD_ONS_LIST_TABLE_PLAN_STATUS" noMargin />
            <Text component="th" content={type === AddonsAllowancesScreen.ADDONS ? "ADD_ONS_LIST_TABLE_ADD_ON_TYPE" : "ALLOWANCES_TYPE_TABLE_HEAD"} noMargin />
          </tr>
        </thead>

        <tbody>
          {sortedPlansList.map((plan) => {
            return (
              <tr key={plan.getId()}>
                <Text component="td" shouldTranslate={false} content={plan.getProductName()} noMargin />
                <Text component="td" shouldTranslate={false} content={plan.getName()} noMargin />
                <td>
                  <StatusLabel color={getProductStatusColor(plan.getStatus())} content={getProductStatusText(plan.getStatus())} />
                </td>
                <Text component="td" content={plan.getForced() ? "ADD_ONS_LIST_TABLE_FORCED" : "ADD_ONS_LIST_TABLE_OPTIONAL"} noMargin />
              </tr>
            );
          })}
        </tbody>
      </Table>
    );
  };


  const renderCyclesListTable = () => {
    return (
      <Table className="add-ons-list__inner-table">
        <thead>
          <tr>
            <Text component="th" content="ADD_ONS_LIST_TABLE_CYCLE" noMargin />
            {type === AddonsAllowancesScreen.ALLOWANCES && <Text component="th" content="ALLOWANCES_INCLUDED_UNITS_TABLE_HEAD" noMargin />}
            <Text component="th" content={type === AddonsAllowancesScreen.ADDONS ? "ADD_ONS_LIST_TABLE_ADD_ON_PRICE" : "ALLOWANCES_OVERAGE_TABLE_HEAD"} noMargin />
          </tr>
        </thead>
        <tbody>
          {sortedPriceModels.map((addOnPriceModel, idx) => {
            return (
              <tr key={idx}>
                <Text
                  component="td"
                  shouldTranslate={false}
                  content={getCycleFrequencyText(addOnPriceModel.getFrequency(), getFrequencyTypeEnum(addOnPriceModel.getFrequencyType()), getPricingModelTypeEnum(data.getPricingModelType()))}
                  noMargin
                />
                {type === AddonsAllowancesScreen.ALLOWANCES && <Text component="td" content={((addOnPriceModel as AllowancePriceModel).getIncludedUnits() || new Int32Value()).getValue() as any} shouldTranslate={false} noMargin />}
                <Text component="td" shouldTranslate={false} content={getCycleDescriptionFormat(addOnPriceModel)} noMargin />
              </tr>
            );
          })}
        </tbody>
      </Table>
    );
  };

  const getLabelType = (pricingModelType: PricingModelTypeGrpc) => {
    const type = getPricingModelTypeEnum(pricingModelType);
    return type === PricingModelType.PerUnit ? counterpart("CREATE_PLAN_PRICING_MODEL_PER_UNIT") : type;
  }

  return (
    <>
      <tr className={expandedTable ? "cc-table--expandable__rowExpanded" : ""}>
        <Text content={data.getName()} shouldTranslate={false} component="td" noMargin />
        <td
          className={`add-ons-list__table__collapsible${data.getPlansList().length < 1 ? " add-ons-list__table__collapsible--no-data" : ""}`}
          onClick={() => {
            setExpandedTable(expandedTable === "plans" ? null : "plans");
          }}
        >
          <span className={`add-ons-list__table__collapsible-arrow${expandedTable === "plans" ? " expanded" : ""}`} />
          <Text className="add-ons-list__table__collapsible-label" content="ADD_ONS_LIST_PLANS_NUM" translateWith={{ count: data.getPlansList().length }} component="span" noMargin />
        </td>
        <Text content={getLabelType(data.getPricingModelType())} shouldTranslate={false} component="td" noMargin />
        <td
          className={`add-ons-list__table__collapsible${getPricingModels().length < 1 ? " add-ons-list__table__collapsible--no-data" : ""}`}
          onClick={() => {
            setExpandedTable(expandedTable === "cycles" ? null : "cycles");
          }}
        >
          <span className={`add-ons-list__table__collapsible-arrow${expandedTable === "cycles" ? " expanded" : ""}`} />
          <Text className="add-ons-list__table__collapsible-label" content="ADD_ONS_LIST_CYCLES_NUM" translateWith={{ count: getPricingModels().length }} component="span" noMargin />
        </td>
        <td>
          <Button
            id="edit-add-on"
            icon="fas fa-edit left"
            disabled={disableEdit}
            tooltipMessage={disableEdit ? "NO_ACCESS_MSG" : undefined}
            onClick={() => history.push(`/addons-allowances/${type}/edit/${data.getId()}`)}
            buttonType="add"
            title="ADD_ONS_LIST_TABLE_BTN_EDIT"
          />
          <Button
            id="delete-add-on"
            buttonType="error"
            title="ADD_ONS_LIST_TABLE_BTN_DELETE"
            disabled={data.getHasActiveLinkedPlans() || disableDelete}
            tooltipMessage={disableDelete ? "NO_ACCESS_MSG" : undefined}
            icon={data.getHasActiveLinkedPlans() ? "fas fa-lock" : "far fa-trash-alt"}
            onClick={() => onDelete(data)}
          />
        </td>
      </tr>
      {expandedTable && (
        <tr className="expanded">
          <td>
            <div className="add-ons-list__expanded-wrapper">{expandedTable === "plans" ? renderPlansListTable() : renderCyclesListTable()}</div>
          </td>
        </tr>
      )}
    </>
  );
});

export default AddOnsListRow;
