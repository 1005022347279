import React, { useState, useEffect, useMemo } from "react";
import { IAuthReducerState } from "../../../../../reducers/authReducer";
import Panel from "../../../../ui/panel/Panel";
import Text from "../../../../ui/text/Text";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../../../../..";
import { setCurrentCountry, deleteTaxconfig, fetchTaxConfig } from "../../../../../actions/companyTaxRatesActions";
import { ICompanyTaxRatesReducerState } from "../../../../../reducers/companyTaxRatesReducer";
import InteractiveList from "../../../../ui/interactive-list/InteractiveList";
import { COLOR } from "../../../../../utils/constants";
import { IListElement } from "../../../../../models/Configuration";
import Modal from "../../../../modal/Modal";
import TaxJarEditIntegration from "../../../../tax-integration/taxjar-token-integration/taxjar-edit-integration/TaxJarEditIntegration";
import BillsbyTaxEditIntegration from "../../../../tax-integration/billsby-tax-integration/billsby-tax-edit/BillsbyTaxEditIntegration";
import ManualTaxIntegration from "../../../../tax-integration/manual-tax-integration/ManualTaxIntegration";
import { ICountryTax } from "../../../../../models/Taxes";
import Dropdown from "../../../../ui/dropdown/Dropdown";
import counterpart from "counterpart";
import { TaxConfig, ManualTaxConfig } from "../../../../../utils/grpc/generated/Billsby.Protos/billing/private/company/taxation/taxation_pb";
import "./TaxRates.scss";
import { Col, Row } from "react-grid-system";
import FormLabel from "../../../../ui/form-label/FormLabel";
import { europeanCountriesCode } from "../../../../../utils/states/states";
import ErrorNotification from "../../../../ui/error-notification/ErrorNotification";
import { fetchCompanyConfigurationDetails } from "../../../../../actions/companyDetailsActions";
import { ICompanyDetailsReducerState } from "../../../../../reducers/companyDetailsReducer";
import { useHistory } from "react-router";
import { Link, useRouteMatch } from "react-router-dom";
import { Permission } from "../../../../../models/Auth";
import NoticePanel from "../../../../ui/notice-panel/NoticePanel";
import { setShowBillingModal } from "../../../../../actions/authActions";


const TaxRates: React.FC = () => {
  const [showEditTaxJarModal, setShowTaxJarEditModal] = useState(false);
  const [showEditBillsbyTaxModal, setShowEditBillsbyTaxModal] = useState(false);
  const [showManualTaxModal, setShowManualTaxModal] = useState(false);
  const [genericCountries, setGenericCountries] = useState<Array<{ value: ICountryTax; label: string }>>([]);
  const [configuredCountries, setConfiguredCountries] = useState<Array<TaxConfig>>([]);
  const [isLoadingData, setIsLoadingData] = useState(false);

  const { currentCountry, configs, taxableCountries, otherCountries, errorMessage } = useSelector<AppState, ICompanyTaxRatesReducerState>(state => state.companyTaxRatesReducer);
  const { currentCompanyDomain, parsedToken } = useSelector<AppState, IAuthReducerState>(state => state.auth);
  const { companyDetails: { billingAddressLine1 } } = useSelector<AppState, ICompanyDetailsReducerState>(state => state.companyDetailsReducer);
  const dispatch = useDispatch<Function>();

  const hasPermissionTaxRegion = useMemo(() => {
    const hasConfiguredATaxRegion = !!configs?.getResultsList().some((config) => (config.hasManualTaxConfig() || config.hasTaxJarConfig() || config.hasBillsbyTaxConfig()));
    if ((parsedToken?.Limitations.TaxRegion === 1 && hasConfiguredATaxRegion) || parsedToken?.Limitations.TaxRegion === 0) {
      return false;
    }
    return !!parsedToken?.CurrentPermissions.some(p => p === Permission[Permission.MultiTaxRegionRead]);
  }, [parsedToken, configs]);

  const history = useHistory()
  const match = useRouteMatch()

  const fetchCompanyDetails = async () => {
    setIsLoadingData(true);
    try {
      await dispatch(fetchCompanyConfigurationDetails(currentCompanyDomain));
      setIsLoadingData(false);

    } catch (err) {
      setIsLoadingData(true);
      console.log(err)
    }
  }

  useEffect(() => {
    fetchCompanyDetails();
  }, [])



  useEffect(() => {
    if (otherCountries.length !== 0) {
      setGenericCountries(
        otherCountries
          .filter((country) => {
            return (
              europeanCountriesCode.indexOf(country.iso3Code) === -1 &&
              !taxableCountries.find((tc) => tc.iso3Code === country.iso3Code) &&
              !configuredCountries.find((cc) => cc.getCountryIso3Code() === country.iso3Code)
            );
          })
          .map((country) => ({ value: { countryCode: country.iso3Code, countryName: country.name }, label: country.name }))
      );
    }
  }, [otherCountries, configuredCountries]);

  useEffect(() => {
    if (configs) {
      setConfiguredCountries(
        configs
          .getResultsList()
          .filter((cfg) => {
            return europeanCountriesCode.indexOf(cfg.getCountryIso3Code()) === -1 && !taxableCountries.find((tc) => tc.iso3Code === cfg.getCountryIso3Code());
          })
          .sort((a: any, b: any) => {
            if (a.getCountryIso3Code() < b.getCountryIso3Code()) {
              return -1;
            } return 1
          })
      );
    }
  }, [configs]);

  const onDeleteTaxConfig = async (countryCode: string, isOtherCountry: boolean = false) => {
    try {
      //Other countries tax config has the "ManualTax" as the default tax service type
      await dispatch(deleteTaxconfig(currentCompanyDomain, countryCode, isOtherCountry ? "ManualTax" : currentCountry?.taxServiceType));
      await dispatch(fetchTaxConfig(currentCompanyDomain));
      setShowManualTaxModal(false);
    } catch (err) {
      console.log(err);
    }
  };

  const joinRates = (config: ManualTaxConfig | undefined) => {
    if (!config) {
      return "";
    }

    return config
      .getTaxRatesList()
      .map((rate) => counterpart("CONFIG_TAXES_RATE", { name: rate.getName(), rate: `${+rate.getRate().toFixed(2)}%` }))
      .join(", ");
  };

  const renderRegionsTable = () => {
    if (isLoadingData || !configs) {
      return null;
    }

    const listDataArray: Array<IListElement> = [];

    taxableCountries.forEach((data) => {
      const countryConfig = configs
        .getResultsList()
        .filter((config) => (config.hasManualTaxConfig() || config.hasTaxJarConfig() || config.hasBillsbyTaxConfig()) && config.getCountryIso3Code() === data.iso3Code);
      const hasConfig = countryConfig.length !== 0;

      listDataArray.push({
        title: data.name,
        subTitle: "",
        shouldTranslateTitle: false,
        statusLabel: hasConfig ? "CONFIG_TAXES_CONFIGURED" : "CONFIG_TAXES_NOT_CONFIGURED",
        statusLabelColor: COLOR[hasConfig ? "GREEN" : "RED"],
        isLocked: hasConfig ? false : !hasPermissionTaxRegion,
        onClick: () => {
          dispatch(setCurrentCountry({ countryName: data.name, countryCode: data.iso3Code }));

          if (hasConfig) {
            if (countryConfig[0].hasManualTaxConfig()) {
              setShowManualTaxModal(!showManualTaxModal);
              return;
            }

            if (countryConfig[0].hasTaxJarConfig()) {
              setShowTaxJarEditModal(!showEditTaxJarModal);
              return;
            }

            if (countryConfig[0].hasBillsbyTaxConfig()) {
              setShowEditBillsbyTaxModal(!showEditBillsbyTaxModal);
              return;
            }
          }
          history.push(`${match?.path}/configure/${data.iso3Code}`);
        },
      });
    });

    return <InteractiveList className="tax-rates__regions-list" data={listDataArray} />;
  };

  const renderConfiguredCountries = () => {
    if (!configs) {
      return null;
    }

    return configuredCountries.map((config, i) => {
      const country = otherCountries.find((country) => country.iso3Code === config.getCountryIso3Code());
      if (!config.hasManualTaxConfig() || !country) {
        return null;
      }

      return (
        <Panel key={`${i}_${country.iso3Code}`} className="tax-rates__item" noMargin>
          <Text content={country ? country.name : ""} shouldTranslate={false} noMargin />
          <span className="divider divider--vertical" />
          <Text className="tax-rates__item__rates" content={joinRates(config.getManualTaxConfig())} shouldTranslate={false} noMargin />
          <div className="tax-rates__item__actions">
            <i
              className="fas fa-edit left"
              onClick={() => {
                dispatch(setCurrentCountry({ countryName: country.name, countryCode: country.iso3Code }));
                setShowManualTaxModal(true);
              }}
            />
            <i className="far fa-trash-alt" onClick={() => onDeleteTaxConfig(country.iso3Code, true)} />
          </div>
        </Panel>
      );
    });
  };

  if (isLoadingData) {
    return null
  }

  return (
    <div className="tax-rates">
      <NoticePanel isVisible={!hasPermissionTaxRegion} type="warning" icon="far fa-lightbulb-exclamation" content="CONFIG_TAXES_RATE_WARNING_PERMISSION" translateWith={{
          here: <Link to="" id="billing-modal-link" onClick={(evt) => { dispatch(setShowBillingModal(true)); evt.preventDefault(); }}>{counterpart("REPORTS_ACCOUNTING_WARNING_PERMISSION_HERE")}</Link>
        }}
      />
      {!billingAddressLine1 && <Panel title="CONFIG_TAXES_SETTING_UP_ADDRESS_TITLE">
        <Text content="CONFIG_TAXES_SETTING_UP_ADDRESS_CONTENT" />
        <InteractiveList data={[{
          title: counterpart("CONFIG_COMPANY_DETAILS_MENU_ITEM_TITLE"),
          subTitle: "",
          shouldTranslateTitle: false,
          onClick: () => {
            history.push("/configuration/company-details");
          }
        }]} />
      </Panel>}

      <Panel className="tax-rates__regions" title="CONFIG_TAXES_PANEL1_TITLE">
        <Text content="CONFIG_TAXES_PANEL1_CONTENT" />
        {renderRegionsTable()}
      </Panel>

      <Panel className={`tax-rates__other-countries ${!hasPermissionTaxRegion ? "disabled": ""}`} title="CONFIG_TAXES_PANEL2_TITLE">
        <Text content="CONFIG_TAXES_PANEL2_CONTENT" />
        <Row align="center">
          <Col md={1}>
            <FormLabel target="country" content="CONFIG_TAXES_COUNTRIES" noMargin />
          </Col>
          <Col md={11}>
            <Dropdown
              id="other-countries"
              value={null}
              onChange={(data: any) => {
                dispatch(setCurrentCountry({ countryName: data.value.countryName, countryCode: data.value.countryCode }));
                setShowManualTaxModal(true);
              }}
              options={genericCountries}
              placeholder={counterpart("CONFIG_TAXES_OTHER_COUNTRIES_PLACEHOLDER")}
            />
          </Col>
        </Row>
      </Panel>

      {configuredCountries.length > 0 && (
        <Panel className="tax-rates__configured-countries" title="CONFIG_TAXES_PANEL3_TITLE">
          <Text content="CONFIG_TAXES_PANEL3_CONTENT" />
          {renderConfiguredCountries()}
        </Panel>
      )}

      <Modal
        className="tax-rates__modal-taxjar"
        shouldTranslateTitle={false}
        title={currentCountry ? currentCountry.countryName : ""}
        isOpen={showEditTaxJarModal}
        onRequestClose={() => setShowTaxJarEditModal(false)}
        children={<TaxJarEditIntegration handleUpdateCallback={() => setShowTaxJarEditModal(false)} showSuccessPanel={false} />}
      />

      <Modal
        className="tax-rates__modal-billsby-tax"
        shouldTranslateTitle={false}
        title={currentCountry ? currentCountry.countryName : ""}
        isOpen={showEditBillsbyTaxModal}
        onRequestClose={() => setShowEditBillsbyTaxModal(false)}
        children={<BillsbyTaxEditIntegration handleUpdateCallback={() => setShowEditBillsbyTaxModal(false)} showSuccessPanel={false} />}
      />

      <Modal
        className="tax-rates__modal-manual-tax"
        shouldTranslateTitle={false}
        title={currentCountry ? currentCountry.countryName : ""}
        isOpen={showManualTaxModal}
        onRequestClose={() => setShowManualTaxModal(false)}
        children={<ManualTaxIntegration handleUpdateCallback={() => setShowManualTaxModal(false)} />}
      />

      <ErrorNotification title="MANUAL_TAX_CONFIG_ERROR_TITLE" subtitle={errorMessage} showAlert={Boolean(errorMessage)} shouldTranslateSubtitle={false}  ></ErrorNotification>

    </div>
  );
};

export default TaxRates
