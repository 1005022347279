/**
 * @fileoverview gRPC-Web generated client stub for core.private.companyconfig.companycheckoutconfig
 * @enhanceable
 * @public
 */

// GENERATED CODE -- DO NOT EDIT!


import * as grpcWeb from "grpc-web";

import {
  UpdateAccountManagementLanguageRequest,
  UpdateAccountManagementLanguageResponse,
  UpdateCheckoutDefaultLanguageRequest,
  UpdateCheckoutDefaultLanguageResponse,
  UpsertConfirmationCheckoutConfigRequest,
  UpsertConfirmationCheckoutConfigResponse,
  UpsertShowHideFeaturesRequest,
  UpsertShowHideFeaturesResponse,
  UpsertTerminologiesCheckoutConfigRequest,
  UpsertTerminologiesCheckoutConfigResponse} from "./companycheckoutconfig_pb";

export class CompanyCheckoutConfigServiceClient {
  client_: grpcWeb.AbstractClientBase;
  hostname_: string;
  credentials_: null | { [index: string]: string; };
  options_: null | { [index: string]: string; };

  constructor (hostname: string,
               credentials?: null | { [index: string]: string; },
               options?: null | { [index: string]: string; }) {
    if (!options) options = {};
    if (!credentials) credentials = {};
    options["format"] = "text";

    this.client_ = new grpcWeb.GrpcWebClientBase(options);
    this.hostname_ = hostname;
    this.credentials_ = credentials;
    this.options_ = options;
  }

  methodInfoUpsertShowHideFeatures = new grpcWeb.AbstractClientBase.MethodInfo(
    UpsertShowHideFeaturesResponse,
    (request: UpsertShowHideFeaturesRequest) => {
      return request.serializeBinary();
    },
    UpsertShowHideFeaturesResponse.deserializeBinary
  );

  upsertShowHideFeatures(
    request: UpsertShowHideFeaturesRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: UpsertShowHideFeaturesResponse) => void) {
    return this.client_.rpcCall(
      this.hostname_ +
        "/core.private.companyconfig.companycheckoutconfig.CompanyCheckoutConfigService/UpsertShowHideFeatures",
      request,
      metadata || {},
      this.methodInfoUpsertShowHideFeatures,
      callback);
  }

  methodInfoUpsertConfirmationCheckoutConfig = new grpcWeb.AbstractClientBase.MethodInfo(
    UpsertConfirmationCheckoutConfigResponse,
    (request: UpsertConfirmationCheckoutConfigRequest) => {
      return request.serializeBinary();
    },
    UpsertConfirmationCheckoutConfigResponse.deserializeBinary
  );

  upsertConfirmationCheckoutConfig(
    request: UpsertConfirmationCheckoutConfigRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: UpsertConfirmationCheckoutConfigResponse) => void) {
    return this.client_.rpcCall(
      this.hostname_ +
        "/core.private.companyconfig.companycheckoutconfig.CompanyCheckoutConfigService/UpsertConfirmationCheckoutConfig",
      request,
      metadata || {},
      this.methodInfoUpsertConfirmationCheckoutConfig,
      callback);
  }

  methodInfoUpsertTerminologiesCheckoutConfig = new grpcWeb.AbstractClientBase.MethodInfo(
    UpsertTerminologiesCheckoutConfigResponse,
    (request: UpsertTerminologiesCheckoutConfigRequest) => {
      return request.serializeBinary();
    },
    UpsertTerminologiesCheckoutConfigResponse.deserializeBinary
  );

  upsertTerminologiesCheckoutConfig(
    request: UpsertTerminologiesCheckoutConfigRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: UpsertTerminologiesCheckoutConfigResponse) => void) {
    return this.client_.rpcCall(
      this.hostname_ +
        "/core.private.companyconfig.companycheckoutconfig.CompanyCheckoutConfigService/UpsertTerminologiesCheckoutConfig",
      request,
      metadata || {},
      this.methodInfoUpsertTerminologiesCheckoutConfig,
      callback);
  }

  methodInfoUpdateCheckoutDefaultLanguage = new grpcWeb.AbstractClientBase.MethodInfo(
    UpdateCheckoutDefaultLanguageResponse,
    (request: UpdateCheckoutDefaultLanguageRequest) => {
      return request.serializeBinary();
    },
    UpdateCheckoutDefaultLanguageResponse.deserializeBinary
  );

  updateCheckoutDefaultLanguage(
    request: UpdateCheckoutDefaultLanguageRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: UpdateCheckoutDefaultLanguageResponse) => void) {
    return this.client_.rpcCall(
      this.hostname_ +
        "/core.private.companyconfig.companycheckoutconfig.CompanyCheckoutConfigService/UpdateCheckoutDefaultLanguage",
      request,
      metadata || {},
      this.methodInfoUpdateCheckoutDefaultLanguage,
      callback);
  }

  methodInfoUpdateAccountManagementDefaultLanguage = new grpcWeb.AbstractClientBase.MethodInfo(
    UpdateAccountManagementLanguageResponse,
    (request: UpdateAccountManagementLanguageRequest) => {
      return request.serializeBinary();
    },
    UpdateAccountManagementLanguageResponse.deserializeBinary
  );

  updateAccountManagementDefaultLanguage(
    request: UpdateAccountManagementLanguageRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: UpdateAccountManagementLanguageResponse) => void) {
    return this.client_.rpcCall(
      this.hostname_ +
        "/core.private.companyconfig.companycheckoutconfig.CompanyCheckoutConfigService/UpdateAccountManagementDefaultLanguage",
      request,
      metadata || {},
      this.methodInfoUpdateAccountManagementDefaultLanguage,
      callback);
  }

}

