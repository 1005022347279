import React, { useLayoutEffect, useState } from "react";
import { Row, Col } from "react-grid-system";
import { useDispatch, useSelector } from "react-redux";
import Panel from "../../../../ui/panel/Panel";
import Text from "../../../../ui/text/Text";
import Button from "../../../../ui/button/Button";
import FormGroup from "../../../../ui/form-group/FormGroup";
import Input from "../../../../ui/input/Input";
import { AppState } from "../../../../..";
import "./CancelSubscriptionConfig.scss";
import Checkbox from "../../../../ui/checkbox/Checkbox";
import { IAuthReducerState } from "../../../../../reducers/authReducer";
import { ICancelSubscriptionConfigState, initialRetentionStepsList, initialExitReasonsList } from "../../../../../reducers/cancelSubscriptionConfigReducer";
import {
  fetchRefundPolicy,
  fetchRetentionSteps,
  setRefundPolicy,
  updateRefundPolicy,
  setCancelSubConfigField,
  resetCancelSubConfig,
  updateRetentionSteps,
  fetchExitReasons,
  updateExitReasons
} from "../../../../../actions/cancelSubscriptionConfigActions";
import { RefundPolicyType } from "../../../../../models/RefundPolicy";
import ProgressIndicator from "../../../../ui/progress-indicator/ProgressIndicator";
import counterpart from "counterpart";
import DragDropContainer from "../../../../ui/drag-drop-container/DragDropContainer";
import StepCollapsablePanel from "../../../../ui/step-collapsable-panel/StepCollapsablePanel";
import Switch from "../../../../ui/switch/Switch";
import { useHistory } from "react-router";
import { GetExitReasonsConfigRequest } from "../../../../../utils/grpc/generated/Billsby.Protos/billing/private/company/exitreason/exit_reason_config_pb";
import { useRouteMatch } from "react-router-dom";


const CancelSubscriptionConfig: React.FC = () => {
  const { currentCompanyId, currentCompanyDomain } = useSelector<AppState, IAuthReducerState>(state => state.auth);
  const { refundPolicy, selectedRefundPolicy, exitReason, exitReasonsList, retentionStepsList } = useSelector<AppState, ICancelSubscriptionConfigState>(state => state.cancelSubscriptionConfigReducer);
  const dispatch = useDispatch<Function>()
  const history = useHistory()
  const match = useRouteMatch()
  
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingData, setIsLoadingData] = useState(false);
  const [isUpdatingRetentionList, setIsUpdatingRetentionList] = useState(false);
  const [isUpdatingExitReasonsList, setIsUpdatingExitReasonsList] = useState(false);

  useLayoutEffect(() => {
    const fetchData = async () => {
      setIsLoadingData(true);
      try {
        await dispatch(fetchRefundPolicy(currentCompanyId as number));
        await dispatch(fetchExitReasons(currentCompanyId as number, GetExitReasonsConfigRequest.OrderByOption.ORDER_SEQUENCE_NUMBER));
        await dispatch(fetchRetentionSteps(currentCompanyDomain));
        setIsLoadingData(false);
      } catch (err) {
        setIsLoadingData(false);
      }
    };

    fetchData();

    return () => {
      dispatch(resetCancelSubConfig());
    };
  }, []);

  if (isLoadingData) {
    return <ProgressIndicator color="blue"></ProgressIndicator>;
  }

  const saveChanges = async () => {
    setIsLoading(true);
    await dispatch(updateRefundPolicy(currentCompanyId as number, selectedRefundPolicy));
    setIsLoading(false);
  };

  const addExitReason = () => {
    if (!exitReason) {
      return;
    }

    dispatch(setCancelSubConfigField("exitReason", exitReason));
    dispatch(
      setCancelSubConfigField("exitReasonsList", [
        ...exitReasonsList,
        { text: exitReason, exitReasonConfigId: 0, isArchived: false, orderSequenceNumber: exitReasonsList.length + 1, temporaryId: exitReasonsList.length + 1 }
      ])
    );
    dispatch(setCancelSubConfigField("exitReason", ""));
  };

  const deleteExitReason = (id: number) => {
    dispatch(
      setCancelSubConfigField(
        "exitReasonsList",
        exitReasonsList.map(reason => {
          if (reason.temporaryId && reason.temporaryId === id || reason.exitReasonConfigId === id) {
            return { ...reason, isArchived: true };
          }
          return reason;
        })
      )
    );
  };

  const updateRetentionList = async () => {
    setIsUpdatingRetentionList(true);
    try {
      await dispatch(updateRetentionSteps(currentCompanyDomain, retentionStepsList));
      await dispatch(fetchRetentionSteps(currentCompanyDomain));
      setIsUpdatingRetentionList(false);
    } catch (err) {
      console.log(err);
      setIsUpdatingRetentionList(false);
    }
  };

  const updateExitReasonsList = async () => {
    setIsUpdatingExitReasonsList(true);

    try {
      await dispatch(updateExitReasons(currentCompanyId as number, exitReasonsList));
      await dispatch(fetchExitReasons(currentCompanyId as number, GetExitReasonsConfigRequest.OrderByOption.ORDER_SEQUENCE_NUMBER));
      setIsUpdatingExitReasonsList(false);
    } catch (err) {
      console.log(err);
      setIsUpdatingExitReasonsList(false);
    }
  };

  const renderRefundPolicy = () => {
    return (
      <Panel title="PAUSE_AND_CANCEL_SUBSCRIPTION_REFUND_POLICY">
        <Text content="PAUSE_AND_CANCEL_SUBSCRIPTION_REFUND_POLICY_DESCRIPTION"></Text>
        <FormGroup>
          <Checkbox
            checked={selectedRefundPolicy === RefundPolicyType.PRORATE_UNUSED}
            onClick={() => dispatch(setRefundPolicy(RefundPolicyType.PRORATE_UNUSED))}
            value="somevalue"
            content={<Text content="PAUSE_AND_CANCEL_SUBSCRIPTION_REFUND_POLICY_PRO_RATED" noMargin />}
          />
          <Checkbox
            checked={selectedRefundPolicy === RefundPolicyType.NO_REFUND}
            onClick={() => dispatch(setRefundPolicy(RefundPolicyType.NO_REFUND))}
            value="somevalue"
            content={<Text content="PAUSE_AND_CANCEL_SUBSCRIPTION_REFUND_POLICY_NO_REFUND" noMargin />}
          />
        </FormGroup>

        <Row align="center">
          <Col md={9}>
            <Button
              onClick={() => saveChanges()}
              isLoading={isLoading}
              disabled={false}
              id="update-refund-policy-button"
              type="submit"
              isFullWidth
              buttonType="general"
              title="PAUSE_AND_CANCEL_SUBSCRIPTION_REFUND_POLICY_UPDATE_BUTTON"
            />
          </Col>
          <Col md={3}>
            <Button
              onClick={() => dispatch(setRefundPolicy(refundPolicy))}
              disabled={false}
              id="cancel-refund-policy-button"
              isFullWidth
              buttonType="error"
              title="PAUSE_AND_CANCEL_SUBSCRIPTION_REFUND_POLICY_CANCEL_BUTTON"
            />
          </Col>
        </Row>
      </Panel>
    );
  };

  const renderExitReasons = () => {
    return (
      <Panel title="EXIT_REASONS_PANEL_TITLE">
        <Text content="EXIT_REASONS_PANEL_CONTENT"></Text>
        <form>
          <FormGroup>
            <Row align="center">
              <Col md={11}>
                <Input
                  id="exit-reason"
                  value={exitReason}
                  onChange={(evt: any) => dispatch(setCancelSubConfigField("exitReason", evt.target.value))}
                  placeholder={counterpart("EXIT_REASONS_PLACEHOLDER")}
                ></Input>
              </Col>
              <Col md={1}>
                <Button title="EXIT_REASONS_ADD_BTN" disabled={!exitReason} buttonType="add" isFullWidth id="add-exit-reason" onClick={addExitReason}></Button>
              </Col>
            </Row>
          </FormGroup>

          <div className="cancel-subscription-config__draggable-container">
            <DragDropContainer
              list={exitReasonsList.map((reason, idx) => ({
                content: !reason.isArchived ? (
                  <StepCollapsablePanel
                    className="cancel-subscription-config__draggable-panel"
                    key={reason.temporaryId || reason.exitReasonConfigId}
                    title={
                      <div className="cancel-subscription-config__draggable-panel__content">
                        <i className="fas fa-grip-horizontal drag" />
                        <Text className="title" content={reason.text} shouldTranslate={false} noMargin />
                        <i className="far fa-trash-alt delete" onClick={() => deleteExitReason(reason.temporaryId || reason.exitReasonConfigId)} />
                      </div>
                    }
                    shouldTranslateTitle={false}
                    hasHelpSection={false}
                  />
                ) : (
                  <div key={reason.temporaryId || reason.exitReasonConfigId}></div>
                ),
                value: reason
              }))}
              onDragEnd={(newExitReasonsList: any) => dispatch(setCancelSubConfigField("exitReasonsList", newExitReasonsList))}
            />
          </div>

          <Row align="center">
            <Col md={9}>
              <Button
                onClick={updateExitReasonsList}
                isLoading={isUpdatingExitReasonsList}
                disabled={false}
                id="update-exit-reasons"
                type="button"
                isFullWidth
                buttonType="general"
                title="EXIT_REASONS_UPDATE_BTN"
              />
            </Col>
            <Col md={3}>
              <Button
                onClick={() => dispatch(setCancelSubConfigField("exitReasonsList", initialExitReasonsList))}
                disabled={false}
                id="cancel-exit-reasons"
                isFullWidth
                buttonType="error"
                title="EXIT_REASONS_CANCEL_BTN"
              />
            </Col>
          </Row>
        </form>
      </Panel>
    );
  };

  const renderRetentionSteps = () => {
    return (
      <Panel title="RETENTION_STEPS_PANEL_TITLE">
        <Text content="RETENTION_STEPS_PANEL_CONTENT"></Text>
        <div className="cancel-subscription-config__draggable-container">
          <DragDropContainer
            list={retentionStepsList.map(step => ({
              content: (
                <StepCollapsablePanel
                  className="cancel-subscription-config__draggable-panel"
                  key={step.type}
                  title={
                    <div className="cancel-subscription-config__draggable-panel__content">
                      <i className="fas fa-grip-horizontal drag" />
                      <div>
                        <Text className="title" content={step.title} noMargin />
                        <Text className="sub-title" content={step.subTitle} noMargin />
                      </div>
                      <div className="cancel-subscription-config__draggable-panel__action">
                        <i className="fas fa-fw fa-edit" onClick={() => history.push(`${match?.path}/edit${step.url}`)} />
                        <br />
                        <Switch
                          id="step-active"
                          onChange={() =>
                            dispatch(
                              setCancelSubConfigField(
                                "retentionStepsList",
                                retentionStepsList.map(_step => (_step.type === step.type ? { ..._step, ["isEnabled"]: !_step.isEnabled } : _step))
                              )
                            )
                          }
                          checked={step.isEnabled}
                        ></Switch>
                      </div>
                    </div>
                  }
                  shouldTranslateTitle={false}
                  hasHelpSection={false}
                />
              ),
              value: step
            }))}
            onDragEnd={(newRetentionStepsList: any) => dispatch(setCancelSubConfigField("retentionStepsList", newRetentionStepsList))}
          />
        </div>

        <Row align="center">
          <Col md={9}>
            <Button
              onClick={updateRetentionList}
              isLoading={isUpdatingRetentionList}
              disabled={false}
              id="update-retention-steps"
              type="submit"
              isFullWidth
              buttonType="general"
              title="RETENTION_STEPS_UPDATE_BTN"
            />
          </Col>
          <Col md={3}>
            <Button
              onClick={() => dispatch(setCancelSubConfigField("retentionStepsList", initialRetentionStepsList))}
              disabled={false}
              id="cancel-retention-steps"
              isFullWidth
              buttonType="error"
              title="RETENTION_STEPS_CANCEL_BTN"
            />
          </Col>
        </Row>
      </Panel>
    );
  };

  return (
    <div className="cancel-subscription-config">
      {renderExitReasons()}
      {renderRefundPolicy()}
      {renderRetentionSteps()}
    </div>
  );
};

export default CancelSubscriptionConfig
