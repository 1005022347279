import React from "react";
import InternalColorPicker from "rc-color-picker";
import Text from "../text/Text";
import "./rc-color.scss";
import "./ColorPicker.scss";

interface IColorPicker {
  color: string,
  colorText?: string,
  label: string,
  shouldTranslateText?: boolean,
  disabled?: boolean,
  onChange?: (evt: { color: string }) => any,
  animation?: "slide-up",
  placement?: "bottomLeft",
  defaultColor?: string,
  enableAlpha?: boolean
  [key: string]: any,
  colorLeft?: string,
  colorRight?: string,
  colorTextLeft?: string,
  colorTextRight?: string,
  showSampleText?: boolean,
  fontWeight?: "bold" | "normal"
}

const ColorPicker: React.FC<IColorPicker> = ({ color, disabled = false, animation = "slide-up", placement = "bottomLeft", enableAlpha = false,
  label, shouldTranslateText = true, colorLeft, colorRight, colorTextLeft, colorText = "#ffffff", colorTextRight, fontWeight = "bold", showSampleText = true, ...rest }) => {

  const renderCurrentColorPicker = () => {
    return (
      <div
        className={`custom-colorpicker ${disabled ? "custom-colorpicker--disabled" : ""}`}
        onClick={(evt: any) => {
          const colorPicker = evt.target.querySelector(".rc-color-picker-trigger") as HTMLElement;
          colorPicker && colorPicker.click();
        }}
      >
        <InternalColorPicker
          color={color}
          animation={animation}
          placement={placement}
          enableAlpha={enableAlpha}
          {...rest}
        />
        <Text
          className="custom-colorpicker__color-edit"
          content={color.toUpperCase()}
          shouldTranslate={false}
          onClick={(evt: any) => {
            const colorPicker = evt.target.closest(".custom-colorpicker") as HTMLElement;
            colorPicker && colorPicker.click();
          }}
          noMargin
        />

        <Text
          className="custom-colorpicker__text"
          content={label}
          shouldTranslate={shouldTranslateText}
          noMargin
        />
      </div >
    )
  }

  const renderNewColorPicker = () => {
    return (
      <div className="custom-colorpicker__container">
        <div
          className={`custom-colorpicker ${disabled ? "custom-colorpicker--disabled" : ""}`}
          onClick={(evt: any) => {
            const colorPicker = evt.target.querySelector(".rc-color-picker-trigger") as HTMLElement;
            colorPicker && colorPicker.click();
          }}
        >
          <InternalColorPicker
            color={color}
            animation={animation}
            placement={placement}
            enableAlpha={enableAlpha}
            {...rest}
          />
          <Text
            className="custom-colorpicker__color-edit"
            content={color.toUpperCase()}
            shouldTranslate={false}
            onClick={(evt: any) => {
              const colorPicker = evt.target.closest(".custom-colorpicker") as HTMLElement;
              colorPicker && colorPicker.click();
            }}
            noMargin
          />
          <div className="custom-colorpicker__content">
            <div className="custom-colorpicker__content__left-side" style={{ backgroundColor: colorLeft || color }}>
              {showSampleText && <Text component="span" className="custom-colorpicker___content__text" content="Abc" style={{ color: colorTextLeft || colorText }} noMargin shouldTranslate={false} weight={fontWeight}></Text>}
            </div>
            <div className="custom-colorpicker__content__right-side" style={{ backgroundColor: colorRight || color }}>
              {showSampleText && <Text component="span" className="custom-colorpicker___content__text" content="Abc" style={{ color: colorTextRight || colorText }} noMargin shouldTranslate={false} weight={fontWeight}></Text>}
            </div>
          </div>
        </div>
        <Text
          className="custom-colorpicker__text"
          content={label}
          shouldTranslate={shouldTranslateText}
          noMargin
        />
      </div>
    )
  }

  return renderNewColorPicker()
}

export default ColorPicker;