import React, { useState, useLayoutEffect } from "react";
import { Row, Col, Container } from "react-grid-system";
import Panel from "../../ui/panel/Panel";
import Text from "../../ui/text/Text";
import FormLabel from "../../ui/form-label/FormLabel";
import Input from "../../ui/input/Input";
import Checkbox from "../../ui/checkbox/Checkbox";
import "./OverrideTaxRatePanel.scss";
import { IndiaGeneralRate, IOverrideStateRate } from "../../../models/Taxes";
import { TaxRate } from "../../../utils/grpc/generated/Billsby.Protos/billing/private/company/taxation/taxation_pb";
import TaxRatePanel from "./TaxRatePanel";

interface IOverrideTaxRatePanel {
  stateToOverride: IOverrideStateRate;
  showAsText?: boolean;
  onDelete?: () => void;

  onChangeOverrideTaxRate: (value: number, taxRate: TaxRate) => void;
  onChangeOverrideRegNumber: (value: string, taxRate: TaxRate) => void;
  onChangeOverride: (value: boolean, stateToOverride: IOverrideStateRate) => void;
}

const OverrideTaxRatePanel: React.FC<IOverrideTaxRatePanel> = React.memo(
  ({ stateToOverride, onDelete, showAsText = false, onChangeOverrideTaxRate, onChangeOverride, onChangeOverrideRegNumber }) => {
    const [isEditing, setIsEditing] = useState(false);
    const [isConfigured, setIsCongifured] = useState(false);
    const { stateName, isOverride, taxRates, taxRegNumber, stateCode } = stateToOverride;

    useLayoutEffect(() => {
      setIsEditing(showAsText);
    }, [showAsText]);

    useLayoutEffect(() => {
      setIsCongifured(taxRegNumber !== "");
    }, []);

    useLayoutEffect(() => {
      if (taxRegNumber === "") {
        onChangeOverride(false, stateToOverride);
      }
    }, [taxRegNumber]);

    return (
      <Panel className="override-tax-rate-panel" noMargin>
        <div className={"override-tax-rate-panel__name"}>
          <Text content={stateName} shouldTranslate={false} noMargin />
        </div>
        <span className={`divider divider--vertical ${isOverride ? "" : ""}`} />
        <Container className="override-tax-rate-panel__content">
          <Row align="center">
            <Col md={1}>
              <FormLabel target={`GSTIN_${stateCode}`} content="GSTIN" shouldTranslate={false} noMargin />
            </Col>
            <Col md={5}>
              <Input
                id={`GSTIN_${stateCode}`}
                value={isConfigured && isEditing ? `#${taxRegNumber}` : taxRegNumber}
                showAsText={isConfigured && isEditing}
                placeholder="29-ABCDE1234F-2-Z-5"
                onChange={(evt: any) => onChangeOverrideRegNumber(evt.target.value, taxRates[0])}
              />
            </Col>
            <Col md={4.5}>
              <Checkbox
                disabled={taxRegNumber === ""}
                checked={isOverride}
                onClick={() => onChangeOverride(!isOverride, stateToOverride)}
                value={""}
                content={<Text content="CONFIG_TAX_RATES_OVERRIDE_RATES" noMargin />}
              />
            </Col>
            <Col className="override-tax-rate-panel__actions" md={1.5}>
              {isEditing && isConfigured && <i className="fas fa-edit edit" onClick={() => setIsEditing(!isEditing)} />}
              <i className="far fa-trash-alt delete" onClick={onDelete} />
            </Col>
          </Row>
          {isOverride && (
            <Row className="override-tax-rate-panel__rates" align="center">
              {taxRates.map((taxRate) => {
                return (
                  <React.Fragment key={taxRate.getName()}>
                    <Col md={taxRate.getName() === IndiaGeneralRate.SGST_UTGST ? 2 : 1}>
                      <FormLabel target={`${taxRate.getName()}_${taxRate.getStateIso2()}`} content={taxRate.getName()} shouldTranslate={false} noMargin />
                    </Col>
                    <Col md={2.5}>
                      <TaxRatePanel
                        inputOnly
                        taxRateTextFormat={`@ ${+taxRate.getRate().toFixed(2)} %`}
                        inputSuffix="%"
                        showAsText={isEditing}
                        taxRate={taxRate}
                        onChangeTaxRate={(value) => onChangeOverrideTaxRate(value, taxRate)}
                      />
                    </Col>
                  </React.Fragment>
                );
              })}
            </Row>
          )}
        </Container>
      </Panel>
    );
  }
);

export default OverrideTaxRatePanel;
