import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../../../../..";
import { IPaymentGatewaysReducer } from "../../../../../reducers/paymentGatewaysReducer";
import { IAuthReducerState } from "../../../../../reducers/authReducer";
import { setPaymentGatewaysField, fetchPaymentGateways } from "../../../../../actions/paymentGatewaysActions";
import { CreatePaymentCloudRequest, CreatePaymentCloudResponse, ReplaceGatewayToPaymentCloudRequest } from "../../../../../utils/grpc/generated/Billsby.Protos/billing/private/paymentgateway/payment_gateway_pb";
import { PaymentGatewayServiceClient } from "../../../../../utils/grpc/generated/Billsby.Protos/billing/private/paymentgateway/Payment_gatewayServiceClientPb";
import { grpcUnaryCall } from "../../../../../utils/grpc/grpcUtils";
import { ConfigConstants } from "../../../../../utils/config";
import PaymentCloudGatewayForm from "./PaymentCloudGatewayForm";
import PaymentGatewayContainer from "../payment-gateways-shared-components/PaymentGatewayContainer";

interface Props {
  onError: () => void;
}

const PaymentCloudGateway: React.FC<Props> = ({ onError }) => {
  const { selectedPaymentGateway, isCreatingGateway, replacedPaymentGateway } = useSelector<AppState, IPaymentGatewaysReducer>(state => state.paymentGatewaysReducer);
  const { currentCompanyDomain } = useSelector<AppState, IAuthReducerState>(state => state.auth);
  const dispatch = useDispatch<Function>()

  const [helpTitle, setHelpTitle] = useState("PAYMENT_GATEWAYS_PAYMENTCLOUD_HELP_TITLE");
  const [helpContent, setHelpContent] = useState(["PAYMENT_GATEWAYS_PAYMENTCLOUD_HELP_CONTENT_1", "PAYMENT_GATEWAYS_PAYMENTCLOUD_HELP_CONTENT_2"]);

  if (!selectedPaymentGateway) {
    return null;
  }

  const addNewPaymentGateway = async (displayName: string, apiLogin: string, password: string) => {
    const createPaymentCloudRequest = new CreatePaymentCloudRequest();
    createPaymentCloudRequest.setCompanyDomain(currentCompanyDomain);
    createPaymentCloudRequest.setDisplayName(displayName);
    createPaymentCloudRequest.setApiLoginId(apiLogin);
    createPaymentCloudRequest.setTransactionKey(password);
    const paymentGatewayServiceClient = new PaymentGatewayServiceClient(ConfigConstants.grpcBaseUrl);
    
    let request: Promise<any>
    if(replacedPaymentGateway) {
      const replaceGatewayToPaymentCloudRequest = new ReplaceGatewayToPaymentCloudRequest()
      replaceGatewayToPaymentCloudRequest.setCreatePaymentCloudRequest(createPaymentCloudRequest);
      replaceGatewayToPaymentCloudRequest.setPaymentGatewayToReplaceId(replacedPaymentGateway.paymentGatewayId as number);
      request = grpcUnaryCall(replaceGatewayToPaymentCloudRequest, paymentGatewayServiceClient, paymentGatewayServiceClient.replaceGatewayToPaymentCloud)
    }
    else {
      request = grpcUnaryCall(createPaymentCloudRequest, paymentGatewayServiceClient, paymentGatewayServiceClient.createPaymentCloudGateway)
    }

    try {
      const data = await request as CreatePaymentCloudResponse;
      dispatch(setPaymentGatewaysField("createdPaymentGatewayId", data.getPaymentGatewayId()));
      dispatch(setPaymentGatewaysField("selectedPaymentGateway", undefined));
      dispatch(setPaymentGatewaysField("replacedPaymentGateway", undefined));
      await dispatch(fetchPaymentGateways(currentCompanyDomain));
    } catch {
      onError();
    }
  }

  return (
    <PaymentGatewayContainer
      helpTitle={helpTitle}
      helpContent={helpContent}
      description="PAYMENT_GATEWAYS_PAYMENTCLOUD_DESCRIPTION"
      isOneGatewayPending={false}
      currentGatewayState={{ isCollapsed: false }}
      websiteLink="https://paymentcloudinc.com/billsby/"
      type="create"
      paymentGateway={selectedPaymentGateway}
    >
      <PaymentCloudGatewayForm
        onSave={(displayName: string, apiLogin: string, password: string) => addNewPaymentGateway(displayName, apiLogin, password)}
        onCancel={() => {
          dispatch(setPaymentGatewaysField("selectedPaymentGateway", undefined));
          dispatch(setPaymentGatewaysField("replacedPaymentGateway", undefined));
        }}
        setHelpTitle={(text: string) => setHelpTitle(text)}
        setHelpContent={(text: Array<string>) => setHelpContent(text)}
        initDisplayName=""
        initApiLogin=""
        isSaveEnabled={!isCreatingGateway}
        isEdit={false}
      />
    </PaymentGatewayContainer>
  )
}

export default PaymentCloudGateway