import React, { MouseEventHandler } from "react"
import Button from "../ui/button/Button"
import Text from "../ui/text/Text"
import "./PlanPriceCard.scss"

interface IPlanPriceCard {
  type: string,
  label?: string,
  cost: string,
  billingType: string,
  description: string,
  amountUsers: string,
  features: Array<string>,
  isSelected: boolean,
  disabled?: boolean,
  onClick: MouseEventHandler
}

const PlanPriceCard: React.FC<IPlanPriceCard> = React.memo(({ type, label, cost, billingType, description, amountUsers,
  features, isSelected, disabled = false, onClick }) => {

  return (
    <div className={`plan-price-card ${disabled ? "plan-price-card--disabled" : ""}`}>
      <Text content={type} noMargin />
      {label ? <Text content={label} noMargin /> : <span style={{ padding: 0 }} />}
      <Text content={cost} noMargin />
      <Text content="PRICE_CARD_CYCLE_TYPE" noMargin />
      <Text content={billingType} noMargin />
      <Text content={description} />
      <hr />
      <Text content={amountUsers} noMargin />
      <hr />

      <div className="plan-price-card__features">
        {
          features.map(feature => (
            <div key={feature}>  
              <i className="far fa-check-circle" />
              <Text content={feature} noMargin />
            </div>
          ))
        }
      </div>
      <Button
        id={`button${type}`}
        title={isSelected ? "PRICE_CARD_SELECTED" : "PRICE_CARD_SELECT_PLAN"}
        onClick={onClick}
        isFullWidth
        disabled={disabled}
        icon={isSelected ? "fal fa-check-circle" : undefined}
        buttonType={isSelected ? "success" : "general"}
      />
     
    </div>
  )
})

export default PlanPriceCard