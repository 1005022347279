import { makeActionCreator, makeGenericActionCreatorTypeSafe } from "../../utils/reduxUtils";
import { grpcUnaryCall } from "../../utils/grpc/grpcUtils";
import { CouponsServiceClient } from "../../utils/grpc/generated/Billsby.Protos/core/private/coupons/CouponsServiceClientPb";
import { ConfigConstants } from "../../utils/config";
import moment from "moment";
import { ICreateCouponState } from "../../reducers/discount-coupons/createCouponReducer";
import { CreateCouponRequest, EligibleCustomers, FrequencyType, GetCouponRequest, UpdateCouponRequest } from "../../utils/grpc/generated/Billsby.Protos/core/private/coupons/coupons_pb";
import { Timestamp } from "google-protobuf/google/protobuf/timestamp_pb";
import { CouponsServiceClient as BilingCouponServiceClient } from "../../utils/grpc/generated/Billsby.Protos/billing/private/coupons/CouponsServiceClientPb";
import { GetCouponUsageRequest } from "../../utils/grpc/generated/Billsby.Protos/billing/private/coupons/coupons_pb";


export const SET_CREATE_COUPON_BASIC_INFO = "SET_CREATE_COUPON_BASIC_INFO";

export const setCreateCouponReducerFields = makeGenericActionCreatorTypeSafe<ICreateCouponState>(SET_CREATE_COUPON_BASIC_INFO);

export const SET_COUPON_FORM_COMPLETED = "SET_COUPON_FORM_COMPLETED";
export const setCouponFormCompleted = makeActionCreator(SET_COUPON_FORM_COMPLETED, "stageIndex", "value");

export const SET_COUPON_FORM_HAS_ERROR = "SET_COUPON_FORM_HAS_ERROR";
export const setCouponFormHasError = makeActionCreator(SET_COUPON_FORM_HAS_ERROR, "stageIndex", "value");

export const SET_COUPON_STEPS_COLLAPSED = "SET_COUPON_STEPS_COLLAPSED";
export const setCouponStepsCollapsed = makeActionCreator(SET_COUPON_STEPS_COLLAPSED, "payload");

export const RESET_CREATE_COUPON_STATE = "RESET_CREATE_COUPON_STATE";
export const resetCreateCoupon = makeActionCreator(RESET_CREATE_COUPON_STATE);

export const CREATE_COUPON_REQUEST = "CREATE_COUPON_REQUEST";
export const CREATE_COUPON_SUCCESS = "CREATE_COUPON_SUCCESS";
export const CREATE_COUPON_FAILURE = "CREATE_COUPON_FAILURE";

export const EDIT_COUPON_REQUEST = "EDIT_COUPON_REQUEST";
export const EDIT_COUPON_SUCCESS = "EDIT_COUPON_SUCCESS";
export const EDIT_COUPON_FAILURE = "EDIT_COUPON_FAILURE";

export const FETCH_COUPON_DETAILS_REQUEST = "FETCH_COUPON_DETAILS_REQUEST";
export const FETCH_COUPON_DETAILS_SUCCESS = "FETCH_COUPON_DETAILS_SUCCESS";
export const FETCH_COUPON_DETAILS_FAILURE = "FETCH_COUPON_DETAILS_FAILURE";

export const FETCH_COUPON_USAGE_REQUEST = "FETCH_COUPON_USAGE_REQUEST";
export const FETCH_COUPON_USAGE_SUCCESS = "FETCH_COUPON_USAGE_SUCCESS";
export const FETCH_COUPON_USAGE_FAILURE = "FETCH_COUPON_USAGE_FAILURE";

export const createCoupon = (companyId: number, discountId: number, createCoupon: ICreateCouponState) => {
  const createCouponRequest = new CreateCouponRequest();
  createCouponRequest.setCompanyId(companyId);
  createCouponRequest.setDiscountId(discountId);
  createCouponRequest.setName(createCoupon.name);
  createCouponRequest.setEligibleCustomers((createCoupon.eligibleCustomers as {value:EligibleCustomers, label:string}).value);
  if(createCoupon.couponType === CreateCouponRequest.CouponTypeCase.SINGLE_USE) {
    const singelUseCoupon = new CreateCouponRequest.SingleUse();
    singelUseCoupon.setGenerateAmount(Number(createCoupon.numberGenerated));
    createCouponRequest.setSingleUse(singelUseCoupon);
  }
  if(createCoupon.couponType === CreateCouponRequest.CouponTypeCase.MULTI_USE) {
    const multilUseCoupon = new CreateCouponRequest.MultiUse();
    multilUseCoupon.setCouponCode(createCoupon.couponCode);
    multilUseCoupon.setUsesLimit(Number(createCoupon.limits));
    createCouponRequest.setMultiUse(multilUseCoupon);
  }
  if(createCoupon.hasExpiry) {
    const expiryDateTimestamp = new Timestamp();
    expiryDateTimestamp.fromDate(moment(createCoupon.expirationDate, "MMM YYYY").toDate());
    createCouponRequest.setExpiresOn(expiryDateTimestamp)
  } else {
    createCouponRequest.setIsInfinite(true);
  }

  const discountLength = (createCoupon.discountLength as {value:CreateCouponRequest.DiscountLengthCase, label:string}).value;
  if(discountLength === CreateCouponRequest.DiscountLengthCase.IS_LIFETIME) {
    createCouponRequest.setIsLifetime(true);
  }

  if(discountLength === CreateCouponRequest.DiscountLengthCase.FIXED_TERM) {
    const fixedTerm = new CreateCouponRequest.FixedTermDiscountLength();
    createCouponRequest.setIsLifetime(false);
    fixedTerm.setFrequency(Number(createCoupon.frequency));
    fixedTerm.setFrequencyType((createCoupon.frequencyType as { value: FrequencyType; label: string; }).value);
    createCouponRequest.setFixedTerm(fixedTerm);
  }

  const couponsServiceClient = new CouponsServiceClient(ConfigConstants.grpcBaseUrl);
  return {
    types: [CREATE_COUPON_REQUEST, CREATE_COUPON_SUCCESS, CREATE_COUPON_FAILURE],
    callAPI: () =>  grpcUnaryCall(createCouponRequest, couponsServiceClient, couponsServiceClient.createCoupon)
  }
}

export const updateCoupon = (companyId: number, couponId: number, createCoupon: ICreateCouponState) => {
  const updateCouponRequest = new UpdateCouponRequest();
  updateCouponRequest.setCompanyId(companyId);
  updateCouponRequest.setCouponId(couponId);
  updateCouponRequest.setName(createCoupon.name);
  updateCouponRequest.setEligibleCustomers((createCoupon.eligibleCustomers as {value:EligibleCustomers, label:string}).value);
  if(createCoupon.couponType === CreateCouponRequest.CouponTypeCase.SINGLE_USE) {
    const singelUseCoupon = new UpdateCouponRequest.UpdateSingleUse();    
    singelUseCoupon.setAdditionalAmountToGenerate(!!createCoupon.extraCoupons ? Number(createCoupon.extraCoupons) : 0);    
    updateCouponRequest.setSingleUse(singelUseCoupon);
  }
  if(createCoupon.couponType === CreateCouponRequest.CouponTypeCase.MULTI_USE) {
    const multilUseCoupon = new CreateCouponRequest.MultiUse();
    multilUseCoupon.setCouponCode(createCoupon.couponCode);
    multilUseCoupon.setUsesLimit(Number(createCoupon.limits));
    updateCouponRequest.setMultiUse(multilUseCoupon);
  }
  if(createCoupon.hasExpiry) {
    const expiryDateTimestamp = new Timestamp();
    expiryDateTimestamp.fromDate(moment(createCoupon.expirationDate, "MMM YYYY").toDate());
    updateCouponRequest.setExpiresOn(expiryDateTimestamp)
  } else {
    updateCouponRequest.setIsInfinite(true);
  }

  const discountLength = (createCoupon.discountLength as {value:CreateCouponRequest.DiscountLengthCase, label:string}).value;
  if(discountLength === CreateCouponRequest.DiscountLengthCase.IS_LIFETIME) {
    updateCouponRequest.setIsLifeTime(true);
  }
  if(discountLength === CreateCouponRequest.DiscountLengthCase.FIXED_TERM) {
    const fixedTerm = new CreateCouponRequest.FixedTermDiscountLength();
    updateCouponRequest.setIsLifeTime(false);
    fixedTerm.setFrequency(Number(createCoupon.frequency));
    fixedTerm.setFrequencyType((createCoupon.frequencyType as { value: FrequencyType; label: string; }).value);
    updateCouponRequest.setFixedTerm(fixedTerm);
  }

  const couponsServiceClient = new CouponsServiceClient(ConfigConstants.grpcBaseUrl);
  return {
    types: [EDIT_COUPON_REQUEST, EDIT_COUPON_SUCCESS, EDIT_COUPON_FAILURE],
    callAPI: () =>  grpcUnaryCall(updateCouponRequest, couponsServiceClient, couponsServiceClient.updateCoupon)
  }
}

export const fetchCouponDetails = (companyId: number, couponId: number) => {

  const getCouponRequest = new GetCouponRequest();
  getCouponRequest.setCompanyId(companyId);
  getCouponRequest.setCouponId(couponId);
  
  const couponsServiceClient = new CouponsServiceClient(ConfigConstants.grpcBaseUrl);

  return {
    types: [FETCH_COUPON_DETAILS_REQUEST, FETCH_COUPON_DETAILS_SUCCESS, FETCH_COUPON_DETAILS_FAILURE],
    callAPI: () =>  grpcUnaryCall(getCouponRequest, couponsServiceClient, couponsServiceClient.getCoupon)
  }
}

export const fetchCouponUsage = (companyId: number, couponId: number) => {

  const getCouponUsageRequest = new GetCouponUsageRequest();
  getCouponUsageRequest.setCompanyId(companyId);
  getCouponUsageRequest.setCouponId(couponId);
  
  const couponsServiceClient = new BilingCouponServiceClient(ConfigConstants.grpcBaseUrl);

  return {
    types: [FETCH_COUPON_USAGE_REQUEST, FETCH_COUPON_USAGE_SUCCESS, FETCH_COUPON_USAGE_FAILURE],
    callAPI: () =>  grpcUnaryCall(getCouponUsageRequest, couponsServiceClient, couponsServiceClient.getCouponUsage)
  }
}