import counterpart from "counterpart";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../../../../..";
import { setEmailTemplateField, updateAdsBanner, updateEmailTemplateValues } from "../../../../../actions/emailCustomizationActions";
import { IAuthReducerState } from "../../../../../reducers/authReducer";
import { IEmailCustomizationReducerState } from "../../../../../reducers/emailCustomizationReducer";
import BbcSettings from "../emailForms/bbcSettings/BbcSettings";
import EmailAdvertisement from "../emailForms/emailAdvertisement/EmailAdvertisement";
import EmailSubject from "../emailForms/emailSubject/EmailSubject";
import EmailTextArea from "../emailForms/emailTextArea/EmailTextArea";

export type allowLoadingType = "subject" | "custom1" | "custom2" | "custom3" | "ads" | "bbc";

interface IEmailEditableArea {
  id: string,
  emailCustomCopyOneIsVisible?: boolean,
  emailCustomCopyTwoIsVisible?: boolean,
  emailCustomCopyThreeIsVisible?: boolean,
  emailAdsIsVisible?: boolean,
  emailCustomCopyOneValue: string,
  emailCustomCopyTwoValue: string,
  emailCustomCopyThreeValue: string,
  showCompanyAlertToggle: boolean,
  showProductAlertToggle: boolean,
  showPlanAlertToggle: boolean
}

const EmailEditableArea: React.FC<IEmailEditableArea> = ({ emailCustomCopyOneIsVisible = true, emailCustomCopyTwoIsVisible = true, 
  emailCustomCopyThreeIsVisible = true, emailAdsIsVisible = true, emailCustomCopyOneValue, emailCustomCopyTwoValue, emailCustomCopyThreeValue, 
  showCompanyAlertToggle, showProductAlertToggle, showPlanAlertToggle, id }) => {
  const dispatch = useDispatch();
  const { currentCompanyId, currentCompanyDomain } = useSelector<AppState, IAuthReducerState>(state => state.auth);
  const emailCustomizationReducer = useSelector<AppState, IEmailCustomizationReducerState>(state => state.emailCustomizationReducer);
  const { subjectLine, sendToCompanyAlertEmail, sendToPlanAlertEmail, sendToProductAlertEmail, advertisementLink, hideAdvertisement, overrideAdvert } = emailCustomizationReducer;

  const [subjectIsLoading, setSubjectIsLoading] = useState(false);
  const [custom1IsLoading, setCustom1IsLoading] = useState(false);
  const [custom2IsLoading, setCustom2IsLoading] = useState(false);
  const [custom3IsLoading, setCustom3IsLoading] = useState(false);
  const [adsIsLoading, setAdsIsLoading] = useState(false);
  const [bbcIsLoading, setBbcIsLoading] = useState(false);

  const setLoading = (loadingType: allowLoadingType, value: boolean) => {
    switch (loadingType) {
      case "subject":
        setSubjectIsLoading(value);
        break;
      case "custom1":
        setCustom1IsLoading(value);
        break;
      case "custom2":
        setCustom2IsLoading(value);
        break;
      case "custom3":
        setCustom3IsLoading(value);
        break;
      case "ads":
        setAdsIsLoading(value);
        break;
      case "bbc":
        setBbcIsLoading(value);
        break;
    }
  }

  const saveChanges = async (loadingType: allowLoadingType) => {
    setLoading(loadingType, true);
    try {
      const data = { ...emailCustomizationReducer } as IEmailCustomizationReducerState;
      await dispatch(updateEmailTemplateValues(currentCompanyId as number, data));
    }
    catch { }
    finally {
      setLoading(loadingType, false);
    }
  }

  const updateAds = async (file: File) => {
    setLoading("ads", true);
    try {
      return await dispatch(updateAdsBanner(currentCompanyDomain, file));
    }
    catch { }
    finally {
      setLoading("ads", false);
    }
  }

  const handleOverrideBaseAdvert = () => {
    dispatch(setEmailTemplateField("overrideAdvert", !overrideAdvert));
    const data = { ...emailCustomizationReducer, overrideAdvert: !overrideAdvert} as IEmailCustomizationReducerState;
    dispatch(updateEmailTemplateValues(currentCompanyId as number, data))
  }

  return (
    <div id={id}>
      <EmailSubject
        title={counterpart("EMAIL_TEMPLATE_SUBJECT_LINE")}
        id={"custom-copy"}
        value={subjectLine}
        buttonText={counterpart("EMAIL_TEMPLATE_SUBJECT_LINE_BUTTON_TITLE")}
        onSubmit={() => saveChanges("subject")}
        onChange={(evt: any) => { dispatch(setEmailTemplateField("subjectLine", evt.target.value)) }}
        isLoading={subjectIsLoading}
      />

      {emailCustomCopyOneIsVisible && 
        <EmailTextArea 
          title={counterpart("EMAIL_TEMPLATE_CUSTOM_COPY_AREA_ONE")}
          description={counterpart("EMAIL_TEMPLATE_CUSTOM_COPY_AREA_ONE_DESCRIPTION")}
          id={"custom-copy"}
          value={emailCustomCopyOneValue}
          buttonText={counterpart("EMAIL_TEMPLATE_CUSTOM_COPY_AREA_ONE_BUTTON_TITLE")}
          onSubmit={() => saveChanges("custom1")}
          onChange={(evt: any) => { dispatch(setEmailTemplateField("customArea1", evt.target.value)) }}
          isLoading={custom1IsLoading}
          />
      }

      {emailCustomCopyTwoIsVisible && 
        <EmailTextArea 
          title={counterpart("EMAIL_TEMPLATE_CUSTOM_COPY_AREA_TWO")}
          description={counterpart("EMAIL_TEMPLATE_CUSTOM_COPY_AREA_TWO_DESCRIPTION")}
          className="subscription-renewal-captured-successfully-email-template__panel-header-orange"
          id={"custom-copy-two"}
          value={emailCustomCopyTwoValue}
          buttonText={counterpart("EMAIL_TEMPLATE_CUSTOM_COPY_AREA_TWO_BUTTON_TITLE")}
          onSubmit={() => saveChanges("custom2")}
          onChange={(evt: any) => { dispatch(setEmailTemplateField("customArea2", evt.target.value)) }}
          isLoading={custom2IsLoading}
        />
      }

      {emailCustomCopyThreeIsVisible && 
        <EmailTextArea 
          title={counterpart("EMAIL_TEMPLATE_CUSTOM_COPY_AREA_THREE")}
          description={counterpart("EMAIL_TEMPLATE_CUSTOM_COPY_AREA_THREE_DESCRIPTION")}
          className="subscription-renewal-captured-successfully-email-template__panel-header-green"
          id={"custom-copy-three"}
          value={emailCustomCopyThreeValue}
          buttonText={counterpart("EMAIL_TEMPLATE_CUSTOM_COPY_AREA_THREE_BUTTON_TITLE")}
          onSubmit={() => saveChanges("custom3")}
          onChange={(evt: any) => { dispatch(setEmailTemplateField("customArea3", evt.target.value)) }}
          isLoading={custom3IsLoading}
        />
      }

      {emailAdsIsVisible &&
        <EmailAdvertisement 
          title={counterpart("EMAIL_TEMPLATE_OVERRIDE_ADVERTISING_SPACE")} 
          id={"advertisement"} 
          linkUrl={advertisementLink}
          logoUrl='' 
          onDrop={(files) => updateAds(files[0])}
          hideAdvertisement={hideAdvertisement} 
          onHideAdvertisementChange={() => { dispatch(setEmailTemplateField("hideAdvertisement", !hideAdvertisement)) }}
          buttonText={counterpart("EMAIL_TEMPLATE_OVERRIDE_ADVERTISING_SPACE_BUTTON_TEXT")}
          onSubmit={() => saveChanges("ads")}
          onLinkUrlChange={(evt: any) => { dispatch(setEmailTemplateField("advertisementLink", evt.target.value)) }}
          isLoading={adsIsLoading} 
          canOverrideBaseAdvert={true} 
          overrideBaseAdvert={overrideAdvert} 
          onOverrideBaseAdvertChange={() => handleOverrideBaseAdvert()}
        />
      }

      {!showProductAlertToggle && !showPlanAlertToggle && !showCompanyAlertToggle 
        ? null
        : (
            <BbcSettings title={counterpart("EMAIL_TEMPLATE_CONFIGURE_BCC_SETTINGS")}
              noMargin={true}
              companyAlert={sendToCompanyAlertEmail}
              productAlert={sendToProductAlertEmail}
              planAlert={sendToPlanAlertEmail}
              id={"custom-copy-two"}
              buttonText={counterpart("EMAIL_TEMPLATE_CONFIGURE_BCC_SETTINGS_UPDATE_BUTTON")}
              onSubmit={() => saveChanges("bbc")}
              onProductAlertChange={showProductAlertToggle ? () => { dispatch(setEmailTemplateField("sendToProductAlertEmail", !sendToProductAlertEmail)) } : undefined}
              onPlanAlertChange={showPlanAlertToggle ? () => { dispatch(setEmailTemplateField("sendToPlanAlertEmail", !sendToPlanAlertEmail)) } : undefined}
              onCompanyAlertChange={showCompanyAlertToggle ? () => { dispatch(setEmailTemplateField("sendToCompanyAlertEmail", !sendToCompanyAlertEmail)) } : undefined}
              isLoading={bbcIsLoading}
          />
        )
      }
      
    </div>
  )
}

export default EmailEditableArea;