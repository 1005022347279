import React from "react";
import { Router } from "react-router-dom";
import { Redirect, Route, Switch } from "react-router";
import Login from "./login/Login";
import CancelledCompany from "./cancelled-company/CancelledCompany"
import RegistrationStep1 from "./registration/step1/RegistrationStep1";
import NotFound from "./error/NotFound";
import "../style/global.scss";
import ForgotPassword from "./login/forgot-password/ForgotPassword";
import ProductPlanCycle from "./products/ProductPlanCycle";
import CreditNotes from "./credit-notes/CreditNotes";
import Invoices from "./invoices/Invoices";
import Coupons from "./coupons/Coupons";
import AddOns from "./add-ons/AddOns";
import Customers from "./customers/Customers";
import CustomerDetails from "./customers/customer-details/CustomerDetails";
import Transactions from "./transactions/Transactions";
import Subscriptions from "./subscriptions/Subscriptions";
import SubscriptionDetails from "./subscriptions/subscription-details/SubscriptionDetails";
import InvoiceDetails from "./invoice-details/InvoiceDetails";
import CreditNoteDetails from "./credit-note-details/CreditNoteDetails";
import EmailLogs from "./email-logs/EmailLogs";
import Events from "./events/Events";
import EmbedCodes from "./settings/embed-codes/EmbedCodes";
import EmbedCodesCheckout from "./settings/embed-codes/EmbedCodesCheckout";
import history from "../utils/history";
import Dashboard from "./dashboard/Dashboard";
import { setConfiguration } from "react-grid-system";
import Configuration from "./settings/configuration/Configuration";
import GoLive from "./golive/GoLive";
import PaymentGateways from "../components/settings/configuration/payment-gateways/PaymentGateways";
import Currencies from "../components/settings/configuration/currencies/Currencies";
import ConfigurationHome from "../components/settings/configuration/ConfigurationHome";
import ApiKeys from "../components/settings/configuration/api-keys/ApiKeys";
import Taxes from "../components/settings/configuration/taxes/Taxes";
import CompanyDetails from "../components/settings/configuration/company-details/CompanyDetails";
import Container from "./container/Container";
import { PublicRoute, PrivateRoute } from "./AuthRoutes";
import BrandCustomization from "../components/settings/configuration/brand-customization/BrandCustomization";
import TwoFactorAuthLogin from "./login/two-factor-auth-login/TwoFactorAuthLogin";
import EmbedCodesSelfService from "./settings/embed-codes/EmbedCodesSelfService";
import EmbedCodesChangePlan from "./settings/embed-codes/EmbedCodesChangePlan";
import InvoiceStandalone from "./invoice-standalone/InvoiceStandalone";
import EmailNotifications from "../components//settings/configuration/email-notifications/EmailNotifications";
import RegistrationFromInvitation from "./registration/registration-from-invitation/RegistrationFromInvitation";
import DunningFailedProccess from "../components/settings/configuration/dunning-failed-process/DunningFailedProcess";
import InvoicesCreditNotesCustomization from "../components/settings/configuration/invoices-credit-notes-customization/InvoicesCreditNotesCustomization";
import CheckoutAccountManagementSetting from "../components/settings/configuration/checkout-account-management/CheckoutAccountManagementSettings";
import InvoiceStandalonePreview from "./invoice-standalone-preview/InvoiceStandalonePreview";
import PauseCancelSubscription from "../components/settings/configuration/pause-cancel-subscription/PauseCancelSubscription";
import ReportsCustomers from "./reports-customers/ReportsCustomers";
import ReportsAccounting from "./reports-accounting/ReportsAccounting";
import ActivitySummary from "./reports-accounting/activity-summary/ActivitySummary";
import TaxSummary from "./reports-accounting/tax-summary/TaxSummary";
import ReportsCheckoutSelfService from "./reports-checkout-self-service/ReportsCheckoutSelfService";
import ExitReasons from "./reports-checkout-self-service/exit-reasons/ExitReasons";
import CreateAddOns from "./add-ons/create-add-ons/CreateAddOns";
import EmbedCodesAddOn from "./settings/embed-codes/EmbedCodesAddOn";
import SuspendedAccount from "../containers/suspended-account/SuspendedAccount";
import DiscountAndCoupons from "./discount-and-coupons/DiscountAndCoupons";
import CreateDiscount from "./discount-and-coupons/create-discount/CreateDiscount";
import CreateCoupon from "./discount-and-coupons/create-coupon/CreateCoupon";
import ScrollTop from "../components/ui/scroll-top/ScrollTop";
import EmbedCodesAllowance from "./settings/embed-codes/EmbedCodesAllowance";
import { useSelector } from "react-redux";
import { AppState } from "..";
import EmbedCodesCardTokenizer from "./settings/embed-codes/EmbedCodesCardTokenizer";
import { GettingStartedType } from "../models/GettingStarted";
import GoLiveSuccess from "./golive/GoLiveSuccess";
import SetupAccount from "./registration/setup-account/SetupAccount";
import Zapier from "./zapier/Zapier";
import AllowanceConfig from "../components/settings/configuration/allowance-config/AllowanceConfig";
import Integrations from "../containers/integrations/Integrations";
import FreeAgent from "../components/integrations/free-agent/FreeAgent";
import QuickBooks from "../components/integrations/quick-books/QuickBooks";
import RevenueRecognition from "./reports-accounting/revenue-report/RevenueRecognition";
import DeferredRevenue from "./reports-accounting/revenue-report/DeferredRevenue";
import Sca from "../components/settings/configuration/sca/Sca";
import Sca3ds1Success from "../containers/sca-3ds1/Sca3ds1Success";
import LanguageConfig from "../components/settings/configuration/language-config/LanguageConfig";
import BillsbyPages from "../components/integrations/website-builders/billsby-pages/BillsbyPages";
import GoLive3ds1Success from "./golive/GoLive3ds1Success";
import InformationFields from "../components/settings/configuration/information-fields/InformationFields";
import AchSettings from "../components/settings/configuration/ach-settings/AchSettings";
import Webex from "../components/integrations/webex/Webex";
import useAnalytics from "../utils/hooks/useAnalytics";
import useRegistrationFromInvitation from "../utils/hooks/useRegistrationFromInvitation";
import DashboardNew from "./dashboard-new/DashboardNew";
import useG2Prospect from "../utils/hooks/useG2ProspectScript";

setConfiguration({ gutterWidth: 10 });


const App: React.FC = () => {
  const autoScroll = useSelector((state: AppState) => state.userInterfaceReducer.autoScroll);
  const authData = useSelector((state: AppState) => state.auth);

  const { dashboardSettings} = authData;
  const isLive = dashboardSettings && (dashboardSettings.status === GettingStartedType.OnTrial || dashboardSettings.status === GettingStartedType.Live);

  useAnalytics();
  useG2Prospect();
  useRegistrationFromInvitation();
  
  return (
    <Router history={history}>
      <ScrollTop autoScroll={autoScroll}>
        <Switch>
          <Route exact path="/invoice/:invoiceCode" component={InvoiceStandalone} />
          <Route exact path="/creditnote/:invoiceCode" component={InvoiceStandalone} />
          <Route exact path="/invoice/:invoiceConfigType/preview" component={InvoiceStandalonePreview} />
          <Route exact path="/loggin-out" component={() => <div />} />
          {dashboardSettings && dashboardSettings.deletedCompany && <Route exact path="/cancelled" component={CancelledCompany} />}

          <Container>
            <PublicRoute exact path="/suspended-account" component={SuspendedAccount} />
            <PublicRoute exact path="/registration" component={RegistrationStep1} />
            <PublicRoute exact path="/registration/quiz" component={SetupAccount} />
            <PublicRoute exact path="/invitation/registration" component={RegistrationFromInvitation} />
            <PublicRoute exact path="/login" component={Login} />
            <PublicRoute exact path="/two-factor-authentication" component={TwoFactorAuthLogin} />
            <PublicRoute exact path="/forgot-password" component={ForgotPassword} />
            <PublicRoute exact path="/new-password" component={ForgotPassword} />

            <PrivateRoute path="/home" component={Dashboard} />
            <PrivateRoute path="/new-home" component={DashboardNew} />
            <PrivateRoute exact path="/customers" component={Customers} />
            <PrivateRoute path="/customers/:id" component={CustomerDetails} />
            <PrivateRoute exact path="/credit-notes" component={CreditNotes} />
            <PrivateRoute exact path="/invoices" component={Invoices} />
            <PrivateRoute exact path="/subscriptions" component={Subscriptions} />
            <PrivateRoute exact path="/invoices/:invoiceNumber/customer/:customerUniqueId" component={InvoiceDetails} />
            <PrivateRoute exact path="/credit-notes/:creditNoteNumber/customer/:customerUniqueId" component={CreditNoteDetails} />
            <PrivateRoute path="/subscriptions/:id" component={SubscriptionDetails} />
            <PrivateRoute exact path="/transactions" component={Transactions} />
            <PrivateRoute path="/email-logs" component={EmailLogs} />
            <PrivateRoute exact path="/events" component={Events} />
            <PrivateRoute exact path="/zapier" component={Zapier} />
            <PrivateRoute exact path="/integrations" component={Integrations} />
            <PrivateRoute path="/integrations/add" component={Integrations} />
            <PrivateRoute path="/integrations/free-agent" component={FreeAgent} />
            <PrivateRoute path="/integrations/webex" component={Webex} />
            <PrivateRoute path="/integrations/quickbooks" component={QuickBooks} />
            <PrivateRoute path="/integrations/zapier" component={Zapier} />
            <PrivateRoute path="/integrations/billsby-pages" component={BillsbyPages} />
            <PrivateRoute
              path="/configuration"
              render={() => (
                <Configuration>
                  <PrivateRoute exact path="/configuration" component={ConfigurationHome} />
                  <PrivateRoute exact path="/configuration/payment-gateways" component={PaymentGateways} />
                  <PrivateRoute exact path="/configuration/ach-settings" component={AchSettings} />
                  <PrivateRoute exact path="/configuration/sca" component={Sca} />
                  <PrivateRoute exact path="/configuration/3ds1-status" component={Sca3ds1Success} />
                  <PrivateRoute exact path="/configuration/currencies" component={Currencies} />
                  <PrivateRoute path="/configuration/api-keys" component={ApiKeys} />
                  <PrivateRoute path="/configuration/brand-customization" component={BrandCustomization} />
                  <PrivateRoute path="/configuration/taxes" component={Taxes} />
                  <PrivateRoute path="/configuration/company-details" component={CompanyDetails} />
                  <PrivateRoute path="/configuration/email-notifications" component={EmailNotifications} />
                  <PrivateRoute path="/configuration/dunning-process" component={DunningFailedProccess} />
                  <PrivateRoute path="/configuration/invoices-credit-notes-customization" component={InvoicesCreditNotesCustomization} />
                  <PrivateRoute path="/configuration/checkout-account-management" component={CheckoutAccountManagementSetting} />
                  <PrivateRoute path="/configuration/pause-and-cancel-subscription" component={PauseCancelSubscription} />
                  <PrivateRoute path="/configuration/allowance" component={AllowanceConfig} />
                  <PrivateRoute path="/configuration/languages" component={LanguageConfig} />
                  <PrivateRoute path="/configuration/information-fields" component={InformationFields} />
                </Configuration>
              )}
            />

            <PrivateRoute
              path="/embed-codes"
              render={() => (
                <EmbedCodes>
                  <PrivateRoute exact path="/embed-codes" component={EmbedCodesCheckout} />
                  <PrivateRoute exact path="/embed-codes/checkout" component={EmbedCodesCheckout} />
                  <PrivateRoute exact path="/embed-codes/self-service" component={EmbedCodesSelfService} />
                  <PrivateRoute exact path="/embed-codes/change-plan" component={EmbedCodesChangePlan} />
                  <PrivateRoute exact path="/embed-codes/add-on" component={EmbedCodesAddOn} />
                  <PrivateRoute exact path="/embed-codes/allowances" component={EmbedCodesAllowance} />
                  <PrivateRoute exact path="/embed-codes/card-tokenizer" component={EmbedCodesCardTokenizer} />
                </EmbedCodes>
              )}
            />

            <PrivateRoute path="/report-customers" component={ReportsCustomers} />
            <PrivateRoute exact path="/report-accounting" component={ReportsAccounting} />
            <PrivateRoute path="/report-accounting/activity-summary" component={ActivitySummary} />
            <PrivateRoute path="/report-accounting/tax-summary" component={TaxSummary} />
            <PrivateRoute path="/report-accounting/revenue-recognition" component={RevenueRecognition} />
            <PrivateRoute path="/report-accounting/deferred-revenue" component={DeferredRevenue} />
            <PrivateRoute exact path="/report-checkout-self-service" component={ReportsCheckoutSelfService} />
            <PrivateRoute path="/report-checkout-self-service/exit-reasons" component={ExitReasons} />

            <PrivateRoute exact path="/coupons" component={Coupons} />
            <PrivateRoute path="/products/products-plans-cycles" component={ProductPlanCycle} />
            <PrivateRoute exact path="/addons-allowances/:type" component={AddOns} />
            <PrivateRoute exact path="/addons-allowances/:type/create" component={CreateAddOns} />
            <PrivateRoute exact path="/addons-allowances/:type/edit/:addOnId" component={CreateAddOns} />
            <PrivateRoute exact path="/addons" component={AddOns} />
            <PrivateRoute exact path="/discount-and-coupons" component={DiscountAndCoupons} />
            <PrivateRoute path="/discount-and-coupons/create" component={CreateDiscount} />
            <PrivateRoute path="/discount-and-coupons/edit/:discountId" component={CreateDiscount} />
            <PrivateRoute path="/discount-and-coupons/:discountId/coupon/create" component={CreateCoupon} />
            <PrivateRoute path="/discount-and-coupons/:discountId/coupon/edit/:couponId" component={CreateCoupon} />
            <PrivateRoute exact path="/addons/create" component={CreateAddOns} />
            <PrivateRoute exact path="/addons/edit/:addOnId" component={CreateAddOns} />
            <PrivateRoute exact path="/auth/stripe" />
            <PrivateRoute exact path="/auth/webex" />
            <PrivateRoute exact path="/auth/quickbooks" />
            <PrivateRoute exact path="/auth/freeagent" />
            <PrivateRoute exact path="/launch-checklist" component={isLive ? () => <GoLiveSuccess authData={authData}></GoLiveSuccess> : GoLive} />
            <PrivateRoute exact path="/launch-checklist/3ds1-status" component={GoLive3ds1Success} />

            <Redirect exact from="/" to="/home" />
            <Route path="*" component={NotFound} />
          </Container>
        </Switch>
      </ScrollTop>
    </Router>
  );
};

export default App;
