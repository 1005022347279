import React, { useMemo } from "react";
import FormGroup from "../../../components/ui/form-group/FormGroup";
import { Row, Col } from "react-grid-system";
import FormLabel from "../../../components/ui/form-label/FormLabel";
import counterpart from "counterpart";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../../..";
import Switch from "../../../components/ui/switch/Switch";
import FileUpload from "../../../components/ui/file-upload/FileUpload";
import { validProductImageSize } from "../../../utils/commonUtils";
import { setCreateAddOnsBasicInfo, uploadAddOnImage } from "../../../actions/addOnsActions";
import { ICreateAddOnsReducerState } from "../../../reducers/add-ons/create-addons/createAddOnsReducer";
import { IAuthReducerState } from "../../../reducers/authReducer";

interface IAddOnImageUploader {
  isAddonScreen: boolean
}

const AddOnImageUploader: React.FC<IAddOnImageUploader> = ({ isAddonScreen }) => {

  const { currentCompanyDomain } = useSelector<AppState, IAuthReducerState>(state => state.auth)
  const createdAddOns = useSelector<AppState, ICreateAddOnsReducerState>(state => state.createAddOnsReducer)
  const dispatch = useDispatch<Function>()
  const { isAddOnImageEnabled, addOnImageUrl, isAddOnImageUploading } = createdAddOns;
  const addOnAllowanceText = useMemo(() => isAddonScreen ? "ADD_ON" : "ALLOWANCE", [isAddonScreen]);

  const updateHelpContent = () => {
    dispatch(setCreateAddOnsBasicInfo("helpTextTitleBasicForm", `CREATE_${addOnAllowanceText}_FORM_HELP_IMAGE_INPUT_TITLE`));
    dispatch(setCreateAddOnsBasicInfo("helpTextContentBasicForm", [`CREATE_${addOnAllowanceText}_FORM_HELP_IMAGE_INPUT_TEXT_1`, `CREATE_${addOnAllowanceText}_FORM_HELP_IMAGE_INPUT_TEXT_2`]));
  }

  const onDrop = (files: Array<File>) => {
    updateHelpContent();
    const image = new Image();
    image.onload = async () => {
      if (validProductImageSize(image)) {
        dispatch(uploadAddOnImage(currentCompanyDomain, files, isAddonScreen));
      } else {
        dispatch(setCreateAddOnsBasicInfo("addOnError", counterpart(`CREATE_${addOnAllowanceText}_IMAGE_ERROR`)));
      }
    }

    image.src = URL.createObjectURL(files[0])
  }

  return (
    <>
      <FormGroup>
        <Row align="center">
          <Col md={2}>
            <FormLabel target="addon-image-switch" content={`CREATE_${addOnAllowanceText}_IMAGE_UPLOADER_LABEL`} noMargin />
          </Col>
          <Col md={10}>
            <Switch
              className="create-add-ons__basic-form__switch"
              id="addon-image-switch"
              checked={isAddOnImageEnabled}
              onChange={() => {
                dispatch(setCreateAddOnsBasicInfo("isAddOnImageEnabled", !isAddOnImageEnabled));
                updateHelpContent();
              }} />
          </Col>
        </Row>
      </FormGroup>
      {isAddOnImageEnabled &&
        <FormGroup>
          <Row align="center">
            <Col md={12}>
              <div className="create-add-ons__file-uploader">
                <FileUpload title={<>
                  {counterpart("CREATE_ADD_ON_IMAGE_UPLOADER_TEXT")} <a className="create-add-ons__file-uploader__browse">{counterpart("FILE_UPLOADER_BROWSE")}</a>
                </>}
                  subTitle={"CREATE_ADD_ON_IMAGE_UPLOADER_SUB_TEXT"}
                  shouldTranslateTitle={false}
                  image={addOnImageUrl}
                  onDrop={onDrop}
                  imageAsBackground={true}
                  isLoading={isAddOnImageUploading}
                />
              </div>
            </Col>
          </Row>
        </FormGroup>
      }
    </>
  )
}

export default AddOnImageUploader