/* eslint-disable */
import React, { FormEvent, useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Row, Col } from 'react-grid-system';
import Panel from '../../../ui/panel/Panel';
import Text from '../../../ui/text/Text';
import Button from '../../../ui/button/Button';
import FormLabel from '../../../ui/form-label/FormLabel';
import FormGroup from '../../../ui/form-group/FormGroup';
import Checkbox from '../../../ui/checkbox/Checkbox';
import Input from '../../../ui/input/Input';
import Dropdown from '../../../ui/dropdown/Dropdown';
import Switch from '../../../ui/switch/Switch';
import counterpart from 'counterpart';
import { AppState } from '../../../..';
import CollapsablePanel from '../../../ui/collapsable-panel/CollapsablePanel';
import './BillsbyTaxEditIntegration.scss';
import NoticePanel from '../../../ui/notice-panel/NoticePanel';
import { TaxCollection } from '../../../../models/Taxes';
import { goToTaxConfig } from "../../tax-utils/TaxUtils";

import {
  resetCompanyTaxJarCurrentCountry,
  setTaxRatesField,
  resetCompanyTaxJarProductCategories,
  fetchAutoBillsbyProductCategories,
  updateBillsbyTaxConfig,
  fetchTaxConfig,
  deleteTaxconfig
} from '../../../../actions/companyTaxRatesActions';
import AddressForm from '../../../address-form/AddressForm';
import { AutoBillsbyTaxConfig, UpsertAutoBillsbyTaxRequest, Address } from '../../../../utils/grpc/generated/Billsby.Protos/billing/private/company/taxation/taxation_pb';
import ProgressIndicator from '../../../ui/progress-indicator/ProgressIndicator';

interface ICategory {
  label: string;
  value: string;
}

interface IMatchProps {
  countryCode: string;
}

interface IBillsbyTaxEditIntegrationProps {
  showSuccessPanel?: boolean;
  handleUpdateCallback?: Function;
}

const BillsbyTaxEditIntegration: React.FC<IBillsbyTaxEditIntegrationProps> = ({ showSuccessPanel, handleUpdateCallback }) => {
  const authData = useSelector((state: AppState) => state.auth);
  const companyTaxRatesReducer = useSelector((state: AppState) => state.companyTaxRatesReducer);

  const dispatch = useDispatch<Function>();

  const [state, setState] = useState({ isLoading: false, isLoadingData: false, hasError: false, collectTaxRegNumber: false, customerTaxExemption: false });
  const [addressLine1, setAddressLine1] = useState('');
  const [addressLine2, setAddressLine2] = useState('');
  const [addressState, setAddressState] = useState({ value: '', label: '' });
  const [addressCountry, setAddressCountry] = useState({ value: '', label: '' });
  const [addressCity, setAddressCity] = useState('');
  const [addressPostCode, setAddressPostCode] = useState('');

  const [isLoadingCategories, setIsLoadingCategories] = useState(false);
  const [panelCollapsed, setPanelCollapsed] = useState(true);

  const { isLoading, collectTaxRegNumber, hasError, customerTaxExemption } = state;

  const { companyName, currentCompanyDomain, currentCompanyId } = authData;
  const { productCategories, currentCountry } = companyTaxRatesReducer;

  if (!currentCountry || !currentCompanyId) {
    return null;
  }

  const { taxConfig } = currentCountry;

  if (!taxConfig) {
    return null;
  }

  const { taxRegNumber, taxLabel, taxProductCategoryCode, taxRegNumberRequirementType, nexusAddress } = taxConfig as AutoBillsbyTaxConfig.AsObject;

  const fetchProductCategories = async () => {
    setIsLoadingCategories(true);
    try {
      await dispatch(fetchAutoBillsbyProductCategories());
      setIsLoadingCategories(false);
    } catch (err) {
      setIsLoadingCategories(false);
      console.log(err);
    }
  };

  useEffect(() => {
    fetchProductCategories();

    setState({ ...state, collectTaxRegNumber: taxRegNumberRequirementType !== TaxCollection.DO_NOT_COLLECT });

    return () => {
      dispatch(resetCompanyTaxJarProductCategories());
      dispatch(resetCompanyTaxJarCurrentCountry());
    };
  }, []);

  useEffect(() => {
    if (nexusAddress) {
      setAddressLine1(nexusAddress.addressLine1);
      setAddressLine2(nexusAddress.addressLine2);
      setAddressCountry({ value: nexusAddress.countryIso3Code, label: nexusAddress.countryIso3Code });
      setAddressState({ value: nexusAddress.state, label: nexusAddress.state });
      setAddressPostCode(nexusAddress.postCode);
      setAddressCity(nexusAddress.city);
    }
  }, [nexusAddress]);

  const disableConfigUpdate = () => {
    if (isLoadingCategories) {
      return true;
    }

    if (!nexusAddress) {
      return false;
    }

    return addressLine1 === '' || addressState.value === '' || addressCountry.value === '' || addressCity === '' || addressPostCode === '';
  };

  const onSubmit = async (evt: FormEvent) => {
    evt.preventDefault();
    evt.stopPropagation();

    if (disableConfigUpdate()) {
      return;
    }

    const newNexusAddress: Address.AsObject | undefined = nexusAddress
      ? {
        addressLine1,
        addressLine2,
        city: addressCity,
        state: addressState.value,
        postCode: addressPostCode,
        countryIso3Code: addressCountry.value
      }
      : undefined;

    const data: UpsertAutoBillsbyTaxRequest.AsObject = {
      companyDomain: currentCompanyDomain,
      countryIso3Code: currentCountry.countryCode,
      billsbyTaxConfig: { ...taxConfig, nexusAddress: newNexusAddress, taxRegNumberRequirementType: collectTaxRegNumber ? taxRegNumberRequirementType : TaxCollection.DO_NOT_COLLECT } as AutoBillsbyTaxConfig.AsObject
    };

    setState({ ...state, isLoading: true, hasError: false });

    try {
      await dispatch(updateBillsbyTaxConfig(data));
      await dispatch(fetchTaxConfig(currentCompanyDomain));
      handleUpdateCallback && handleUpdateCallback();
      goToTaxConfig();
    } catch (err) {
      setState({ ...state, isLoading: false, hasError: true });
      console.log(err);
    }
  };

  const updateTaxConfigDetails = (detailName: string, detailValue: any) => {
    dispatch(setTaxRatesField('currentCountry', { ...currentCountry, taxConfig: { ...taxConfig, [detailName]: detailValue } }));
  };

  const handleDeleteTaxConfig = async (evt: any) => {
    evt.stopPropagation();

    if (!currentCountry) {
      return null;
    }

    try {
      await dispatch(deleteTaxconfig(currentCompanyDomain, currentCountry.countryCode, currentCountry.taxServiceType))
      await dispatch(fetchTaxConfig(currentCompanyDomain));
      handleUpdateCallback && handleUpdateCallback();
      goToTaxConfig();
    } catch (err) {
      console.log(err);
    }
  };

  const collapsibleTitle = () => {
    return (
      <div className="content-panel-title">
        <Text content="AUTO_TAX_CALC_COLLAPSIBLE_PANEL" translateWith={{ companyName }}></Text>
        <div className="content-panel-title__action-button-wrapper">
          {!panelCollapsed && (
            <>
              <Button id='delete-integration' icon='far fa-trash-alt' buttonType='error' title='TAXJAR_EDIT_TAX_DELETE' onClick={handleDeleteTaxConfig} />
            </>
          )}
          <i className={`fas fa-chevron-${panelCollapsed ? 'down' : 'up'}`}></i>
        </div>
      </div>
    );
  };

  const renderNexusForm = () => {
    return (
      <FormGroup>
        <AddressForm
          formData={{ addressLine1, addressLine2, addressState, addressCity, addressPostCode, addressCountry }}
          onChangeAddressLine1={line1 => setAddressLine1(line1)}
          onChangeAddressLine2={line2 => setAddressLine2(line2)}
          onChangeAddressCity={city => setAddressCity(city)}
          onChangeAddressCountry={country => setAddressCountry(country)}
          onChangeAddressPostCode={code => setAddressPostCode(code)}
          onChangeAddressState={state => setAddressState(state)}
          label="AUTO_TAX_CALC_NEXUS_ADDRESS"
        ></AddressForm>
      </FormGroup>
    );
  };

  const renderCategoryDropdown = () => {
    if (isLoadingCategories) {
      return <ProgressIndicator color="blue"></ProgressIndicator>;
    }

    if (!productCategories) {
      return null;
    }

    return (
      <div>
        <Row align="center">
          <Col md={2}>
            <FormLabel target="product-category" content="TAXJAR_COMPLETE_INTEGRATION_PRODUCT_CATEGORY" />
          </Col>
          <Col md={10}>
            <Dropdown
              id="product-category"
              onChange={(category: ICategory) => updateTaxConfigDetails('taxProductCategoryCode', category.value)}
              placeholder={counterpart('TAXJAR_COMPLETE_INTEGRATION_DIGITAL_SERVICES')}
              value={productCategories ? productCategories.filter(category => category.value === taxProductCategoryCode) : null}
              options={productCategories || []}
            />
          </Col>
        </Row>
      </div>
    );
  };

  const renderBillsbyTaxIntegrationPanel = () => {
    return (
      <CollapsablePanel title={collapsibleTitle()} isCollapsed={panelCollapsed} onClick={() => setPanelCollapsed(!panelCollapsed)}>
        <div onClick={(evt: any) => evt.stopPropagation()}>
          {nexusAddress && renderNexusForm()}
          {renderCategoryDropdown()}
        </div>
      </CollapsablePanel>
    );
  };

  const renderTaxRegistrationPanel = () => {
    return (
      <Panel title="TAXJAR_EDIT_PANEL2_TITLE">
        <Text content="TAXJAR_EDIT_PANEL2_CONTENT" />
        <FormGroup>
          <Row align="center">
            <Col md={2}>
              <FormLabel target="tax-label" content="TAXJAR_EDIT_TAX_LABEL" noMargin />
            </Col>
            <Col md={10}>
              <Input id="tax-label" type="text" placeholder={counterpart("TAXJAR_EDIT_TAX_LABEL_PLACEHOLDER")} value={taxLabel} onChange={(evt: any) => updateTaxConfigDetails('taxLabel', evt.target.value)} />
            </Col>
          </Row>
        </FormGroup>
        <Row align="center">
          <Col md={2}>
            <FormLabel target="tax-reg-number" content="TAXJAR_EDIT_TAX_REG_NUM" noMargin />
          </Col>
          <Col md={10}>
            <Input id="tax-reg-number" type="text" placeholder="123-456-7890" value={taxRegNumber} onChange={(evt: any) => updateTaxConfigDetails('taxRegNumber', evt.target.value)} />
          </Col>
        </Row>
      </Panel>
    );
  };

  const renderCollectTaxRegistrationPanel = () => {
    return (
      <>
        <Panel title="TAXJAR_EDIT_PANEL3_TITLE">
          <Text content="TAXJAR_EDIT_PANEL3_CONTENT" />
          <FormGroup>
            <Row align="center">
              <Col md={1}>
                <Switch
                  id="collect-tax"
                  checked={collectTaxRegNumber}
                  onChange={() => {
                    setState({ ...state, collectTaxRegNumber: !state.collectTaxRegNumber });
                    if (taxRegNumberRequirementType === TaxCollection.DO_NOT_COLLECT) {
                      updateTaxConfigDetails('taxRegNumberRequirementType', TaxCollection.COMPULSORY);
                    }
                  }}
                />
              </Col>
              <Col md={11}>
                <Text component="span" content="TAXJAR_EDIT_SWITCH" />
              </Col>
            </Row>
          </FormGroup>
          {collectTaxRegNumber && (
            <Row align="center">
              <Col md={12}>
                <Checkbox
                  checked={taxRegNumberRequirementType === TaxCollection.COMPULSORY}
                  onClick={() => updateTaxConfigDetails('taxRegNumberRequirementType', TaxCollection.COMPULSORY)}
                  value={TaxCollection.COMPULSORY}
                  content={<Text content="TAXJAR_EDIT_COLLECT_OPT1" noMargin />}
                />
                <Checkbox
                  checked={taxRegNumberRequirementType === TaxCollection.OPTIONAL}
                  onClick={() => updateTaxConfigDetails('taxRegNumberRequirementType', TaxCollection.OPTIONAL)}
                  value={TaxCollection.OPTIONAL}
                  content={<Text content="TAXJAR_EDIT_COLLECT_OPT2" noMargin />}
                />
              </Col>
            </Row>
          )}
        </Panel>
      </>
    );
  };

  const renderErrorNoticePanel = () => {
    // if (hasError || incorrectToken) {
    //   return <NoticePanel icon="fas fa-exclamation-circle" isModal type="error" content={`TAXJAR_INTEGRATION_${false ? 'ERROR' : 'INCORRECT_ERROR'}`} />;
    // } else return null;
  };

  return (
    <div className="billsby-tax-edit-integration">
      {renderErrorNoticePanel()}
      {showSuccessPanel && <NoticePanel icon="fas fa-check-circle" className="billsby-tax-edit-integration__notice-panel" type="success" content="AUTO_TAX_COMPLETE_INTEGRATION_SUCCESSFUL" />}
      <form onSubmit={onSubmit}>
        {renderBillsbyTaxIntegrationPanel()}
        {renderTaxRegistrationPanel()}
        {renderCollectTaxRegistrationPanel()}
        <Button
          id="billsby-tax-integration"
          disabled={disableConfigUpdate()}
          type="submit"
          icon={'far fa-check-circle'}
          buttonType={'general'}
          isLoading={isLoading}
          title="TAXJAR_EDIT_SAVE_CHANGES"
          isFullWidth
        />
      </form>
    </div>
  );
};

export default BillsbyTaxEditIntegration;
