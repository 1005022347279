/* eslint-disable */
import React, { useState, useRef, useEffect } from "react";
import Select from "react-select";
import "./Dropdown.scss";
import { findDOMNode } from "react-dom";


interface IDropdown {
  [key: string]: any,
  id: string,
  options: Array<any>
  multioptions?: Array<{ key: string, value: Array<string> }>
  placeholder?: string,
  isSearchable?: boolean,
  className?: string,
  resetDependencies?: any[]
}


const Dropdown: React.FC<IDropdown> = React.memo(({ dividerAfter, id, className = "", resetDependencies, isMultiLayer, onChange, options = [], multioptions = [], showTooltipAfterChar = -1,
  placeholder = "", isSearchable = true, ...props }) => {

  const [menuIsOpen, setMenuIsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState(null);
  const [inputLength, setInputLength] = useState(0);
  const firstUpdate = useRef(0);
  const selectRef = useRef(null);

  const _onChange = (p: any) => {
    onChange(p);
    setSelectedOption(props.value)
  }

  //Reset the dropdown depending on the added dependencies
  useEffect(() => {
    resetDependencies && setSelectedOption(null)
  }, [resetDependencies])

  let hideTooltipStyle = "";

  if (showTooltipAfterChar > 0 && inputLength < showTooltipAfterChar) {
    hideTooltipStyle = `
      .custom-select__menu, .custom-select__control--menu-is-open::before {
        display: none;
      }
    `;
  }

  const dividedStyle = dividerAfter ?
    `
      #${id} .custom-select__menu-list *:nth-child(${dividerAfter}) {
        border-bottom: 1px solid #F87847;
      }`
    : "";

  if (isMultiLayer) {

    const onUpdate = () => {
      firstUpdate.current++;
      // if (firstUpdate.current !== 2) {
      //   return;
      // }


      const p = findDOMNode(selectRef.current as any);
      if (menuIsOpen && selectRef && findDOMNode(selectRef.current as any)) {
        let mainOptions = (findDOMNode(selectRef.current as any) as any).querySelectorAll(".custom-select__option") || [];
        mainOptions = Array.from(mainOptions);

        let indexFocused = -1;
        //const onHoverListener = () => setTimeout(onUpdate, 500);
        for (let i = 0; i < mainOptions.length; i++) {
          mainOptions[i].addEventListener("mouseover", (evt: any) => setTimeout(() => {
            if (mainOptions[i].className.match(/.*custom-select__option--is-focused.*/)) {
              const indexFocused = i;
              const _selectedOption = indexFocused >= 0 ? options[indexFocused] : null;
              setSelectedOption(_selectedOption);
            }
          }, 200), false);
          if (mainOptions[i].className.match(/.*custom-select__option--is-focused.*/)) {
            indexFocused = i;
          }
        }

        const _selectedOption = indexFocused >= 0 ? options[indexFocused] : null;
        setSelectedOption(_selectedOption);

      }
    }

    useEffect(onUpdate);


    return (
      <div className="dropdown__container dropdown__container--fixedheight">
        <style>
          {`
            ${dividedStyle}
            ${hideTooltipStyle}
          `}
        </style>
        <Select
          ref={selectRef}
          menuIsOpen={menuIsOpen}
          {...props}
          id={id}
          closeMenuOnSelect={false}
          options={options}
          className={`dropdown ${className}`}
          isSearchable={isSearchable}
          classNamePrefix="custom-select"
          placeholder={placeholder}
          onInputChange={(text: string) => setInputLength(text.length)}
          onMenuOpen={() => setMenuIsOpen(true)}
          onMenuClose={() => { setTimeout(() => setMenuIsOpen(false), 200); firstUpdate.current = 0 }}
        />
        {
          menuIsOpen && selectedOption && multioptions.some(multi => multi.key === (selectedOption as any).label)
            ? (
              <div className="dropdown__multilayer">
                {
                  (multioptions.find(opt => opt.key === (selectedOption as any).label) as any).value.map((multi: string, index: number) => (
                    <div key={index} onClick={() => { onChange({ category: (selectedOption as any).value, subCategory: multi }) }}>{multi}</div>
                  ))
                }
              </div>
            )
            : null
        }

      </div>
    )

  }

  return (
    <div className="dropdown__container">
      <style>
        {`          
          ${dividedStyle}
          ${hideTooltipStyle}
        `}
      </style>
      <Select
        menuIsOpen={menuIsOpen}
        {...props}
        id={id}
        value={props.value || selectedOption}
        onChange={_onChange}
        options={options}
        className={`dropdown ${className}`}
        isSearchable={isSearchable}
        classNamePrefix="custom-select"
        placeholder={placeholder}
        onMenuOpen={() => setMenuIsOpen(true)}
        onMenuClose={() => setMenuIsOpen(false)}
        onInputChange={(text: string) => setInputLength(text.length)}
      />
    </div>
  )

})



export default Dropdown;
