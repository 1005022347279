import React from "react";
import PageWrapper from "../../components/ui/layout/PageWrapper";
import "./ReportsCustomers.scss";
import Panel from "../../components/ui/panel/Panel";
import Text from "../../components/ui/text/Text";
import InteractiveList from "../../components/ui/interactive-list/InteractiveList";
import { COLOR } from "../../utils/constants";
import ErrorPanel from "../../components/ui/error-panel/ErrorPanel";
import FormGroup from "../../components/ui/form-group/FormGroup";

interface IReportsCustomers {

}

const ReportsCustomers: React.FC<IReportsCustomers> = () => {
  return (
    <PageWrapper className="reports-customers">
      <Panel title="REPORTS_CUSTOMERS_SUBSCRIPTIONS_HEADING_TITLE" className="reports-customers__heading">
        <Text content="REPORTS_CUSTOMERS_SUBSCRIPTIONS_HEADING_SUBTITLE" noMargin />
      </Panel>

      <FormGroup>
        <InteractiveList
          data={[
            {
              title: "REPORTS_CUSTOMERS_SUBSCRIPTION_SUMMARY_TITLE",
              subTitle: "REPORTS_CUSTOMERS_SUBSCRIPTION_SUMMARY_SUBTITLE",
              onClick: () => { },
              statusLabel: "REPORTS_CUSTOMERS_DAILY_LABEL",
              statusLabelColor: COLOR.BLUE
            },
            {
              title: "REPORTS_CUSTOMERS_SUBSCRIPTION_PERFORMANCE_TITLE",
              subTitle: "REPORTS_CUSTOMERS_SUBSCRIPTION_PERFORMANCE_SUBTITLE",
              onClick: () => { },
              statusLabel: "REPORTS_CUSTOMERS_MONTHLY_LABEL",
              statusLabelColor: COLOR.BLUE
            }
          ]}
        />
      </FormGroup>

      <ErrorPanel
        title="REPORTS_CUSTOMERS_FOOTER_TITLE"
        subtitle="REPORTS_CUSTOMERS_FOOTER_SUBTITLE"
        icon="fas fa-analytics"
      />
    </PageWrapper>
  )
}

export default ReportsCustomers;