import React, { useLayoutEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../../../..";
import NavigationBar from "../../../ui/navigation-bar/NavigationBar";
import history from "../../../../utils/history";
import PageWrapper from "../../../ui/layout/PageWrapper";
import Panel from "../../../ui/panel/Panel";
import Text from "../../../ui/text/Text";
import NoticePanel from "../../../ui/notice-panel/NoticePanel";
import "./Sca.scss";
import StripeScaPaymentGateway from "./sca-payment-gateways/StripeScaPaymentGateway";
import NavigationAlert from "../../../ui/navigation-alert/NavigationAlert";
import Modal from "../../../modal/Modal";
import InitiateTestSCA from "../../../initiate-test-sca/InitiateTestSCA";
import { GetGatewayScaDetailsRequest } from "../../../../utils/grpc/generated/Billsby.Protos/billing/private/paymentgateway/payment_gateway_pb";
import { resetScaSettingsSetup, setScaField } from "../../../../actions/scaActions";
import InteractiveList from "../../../ui/interactive-list/InteractiveList";
import { PaymentGatewaysType } from "../../../../models/Configuration";
import { fetchPaymentGateways, setPaymentGatewaysField } from "../../../../actions/paymentGatewaysActions";
import ProgressIndicator from "../../../ui/progress-indicator/ProgressIndicator";
import { setCurrenciesField } from "../../../../actions/currenciesActions";

const Sca: React.FC = () => {
  const scaReducer = useSelector((state: AppState) => state.scaReducer);
  const paymentGateways = useSelector((state: AppState) => state.paymentGatewaysReducer.paymentGateways);
  const { companyName, currentCompanyDomain } = useSelector((state: AppState) => state.auth);

  const dispatch = useDispatch<Function>();

  const { hasSuccessfulScaSetup, hasFailedScaSetup, showTestModal, selectedPaymentgatewayId } = scaReducer;

  const [isLoadingData, setIsLoadingData] = useState(false);

  const gatewaysWithScaSupport = useMemo(() => paymentGateways?.filter(i => i.type === PaymentGatewaysType.STRIPE || i.type === PaymentGatewaysType.STRIPEINTENT), [paymentGateways])
  const paymentGatewayName = useMemo(() => paymentGateways?.find(p => p.paymentGatewayId === selectedPaymentgatewayId)?.title, [hasFailedScaSetup, selectedPaymentgatewayId, paymentGateways])

  useLayoutEffect(() => {
    const fetchData = async () => {
      setIsLoadingData(true);
      try {
        await dispatch(fetchPaymentGateways(currentCompanyDomain));
        setIsLoadingData(false);
      } catch (err) {
        setIsLoadingData(false);
      }
    };

    fetchData();
    sessionStorage.removeItem("billsbyTestSCA");

    return () => {
      dispatch(resetScaSettingsSetup());
    };
  }, []);

  if (isLoadingData) {
    return <ProgressIndicator color="blue" coverage="full-content"></ProgressIndicator>
  }

  return (
    <div>
      <NavigationBar pageTitle="" previousPageTitle="CONFIGURATION_PAGE_TITLE" previousPageCb={() => history.push("/configuration")} />

      {
        hasSuccessfulScaSetup &&
        <NavigationAlert
          className="sca__setup-success"
          title="SCA_CONGRULATIONS_TITLE"
          subTitle="SCA_CONGRULATIONS_CONTENT"
          hasButton={false}
        />
      }

      {
        hasFailedScaSetup &&
        <NoticePanel
          className="sca__error"
          type="error"
          icon="fas fa-exclamation-circle"
          content={"SCA_ERROR_CONTENT"}
          translateWith={{ paymentGatewayName }}
        />
      }

      <PageWrapper>
        <Panel title="CONFIGURATION_SCA_PAGE_TITLE" className="sca__heading">
          <Text content="CONFIGURATION_SCA_PAGE_CONTENT" noMargin />
        </Panel>

        <Panel title="SCA_SUPPORTED_PAYMENT_GATEWAYS_TITLE" translateWith={{ companyName }}>
          <Text content="SCA_SUPPORTED_PAYMENT_GATEWAYS_DESCRIPTION" />
          {!gatewaysWithScaSupport?.length ? <>
            <Panel className={"sca__payment-gateway"} >
              <NoticePanel type="warning" isModal content={"SCA_SUPPORTED_PAYMENT_GATEWAYS_WARNING"} />
              <div className="sca__payment-gateway__container" onClick={() => {
                dispatch(setPaymentGatewaysField("linkFromSca", true));
                history.push("/configuration/payment-gateways");
              }}>
                <div>
                  <div>
                    <Text content={"CONFIGURATION_BASIC_PAYMENT_GATEWAYS_TITLE"} className="sca__payment-gateway__title" noMargin />
                    <Text content={"CONFIGURATION_BASIC_PAYMENT_GATEWAYS_CONTENT"} className="sca__payment-gateway__subTitle" noMargin />
                  </div>
                </div>

                <div>
                  <i className={"far fa-chevron-right"} />
                </div>
              </div>
            </Panel>
          </> : <>
            {!!gatewaysWithScaSupport && gatewaysWithScaSupport.map(gateway => <StripeScaPaymentGateway
              key={gateway.paymentGatewayId}
              gateway={gateway}
            />)}
          </>}
        </Panel>

        {!!paymentGateways?.find(i => i.isScaActivated) &&
          <>
            <Panel title="SCA_SCA_FAILURES_HANDLED_TITLE" className="sca__heading">
              <Text content="SCA_SCA_FAILURES_HANDLED_TEXT_1" className="sca__text text-inline" noMargin />
              <Text content="SCA_SCA_FAILURES_HANDLED_TEXT_2" className="sca__text text-inline text-link" noMargin onClick={() => window.open("https://support.billsby.com/docs/strong-customer-authentication-sca", "_self")} />
              <Text content="SCA_SCA_FAILURES_HANDLED_TEXT_3" className="sca__text text-inline" noMargin />
            </Panel>

            <InteractiveList
              data={[
                {
                  title: "SCA_CURRENCIES",
                  subTitle: "SCA_CURRENCIES_DESCRIPTION",
                  onClick: () => {
                    dispatch(setCurrenciesField("linkFromSca", true));
                    history.push("/configuration/currencies");
                  }
                }
              ]}
            />
          </>
        }

        <Modal isOpen={showTestModal} title="SCA_TEST_TRANSACTION_TITLE" onRequestClose={() => dispatch(setScaField("showTestModal", false))}>
          <InitiateTestSCA paymentGatewayType={GetGatewayScaDetailsRequest.PaymentGateTypes.STRIPE} callback={() => { }} />
        </Modal>


      </PageWrapper>
    </div>
  );
};

export default Sca;