import { apiGet, apiPost, apiPut, apiPatch, apiDelete, apiPostFiles } from "./apiUtils";
import { login } from "./authUtils";
import { IUser } from "../models/User";
import { INewUserDetails } from "../models/NewUserDetails";
import { INewCompany, UserRole } from "../models/Auth";
import { IBaseProduct, IBasePlan, IProductLight, IPricingModel, VisibilityType, ILinkedAddOn, ILinkedAllowance } from "../models/Product";
import { IBaseCustomField } from "../models/CustomFields";
import { IGettingStartedSettings } from "../models/GettingStarted";
import { ILiveCompany } from "../models/GoLive";
import { BaseCustomer, INewCustomerDetailsWithCardDetails, ICustomerCardDetails, ICustomerCardDetailsSCA } from "../models/Customer";
import { BaseAddress } from "../models/Address";
import { ITaxConfigurationData } from "../models/Taxes";
import { ICompanyBranding } from "../models/CompanyBranding";
import { IQRCode } from "../models/TwoFactorAuth";
import { ISubscriptionFeaturedTags } from "../models/SubscriptionFeaturedTags";
import { ICompanyDetailsConfigrationData } from "../models/CompanyDetails";
import { AcceptDeclinePendingInvitation, RegisterInvitedUser } from "../models/Invitations";
import { DashboardChartType, DashboardChartPeriod } from "../models/Dashboard";
import { IDunningConfig } from "../models/DunningFailedProcess";
import { ICheckoutManagement, ISelfServiceManagement } from "../models/CheckoutAccountManagement";
import { IInvoicesSupportContactConfigData, InvoiceConfigType, IInvoicesFooterConfigData, InvoiceStatus } from "../models/Invoices";
import { ISubscriptionUpdateModel } from "../models/Subscription";
import { PAGINATION_PAGE_SIZE } from "./constants";
import { fetchGet } from "./fetchUtils";
import { getHeaders } from "./apiUtils";
import { ConfigConstants } from "./config";
import { SubscriptionStatusType } from "../models/Subscriptions";
import { CardStatusType, CustomerStatusType, PaymentSourceType } from "../models/Customers";
import { CreditNoteStatus } from "../models/CreditNotes";
import { IEmailCustomizationReducerState } from "../reducers/emailCustomizationReducer";
import { INewTeammate } from "../models/CompanyTeammates";

class API {
  static validateEmail(email: string) {
    return apiGet(`api/v1/users/validators/user/validator?email=${email}`);
  }

  static fetchCompanies() {
    return apiGet("api/v1/companies");
  }

  static registerUser(user: IUser) {
    return apiPost("api/v1/users", user);
  }

  static getCountries() {
    return apiGet("api/v1/countries");
  }

  static getCurrencies() {
    return apiGet("api/v1/currencies");
  }

  static loginUser(email: string, password: string, code2fa?: string, redirectAfterLogin: boolean = true) {
    return login(email, password, code2fa, redirectAfterLogin);
  }

  static sendResponseToQuiz(companyDomain: string, fieldType: string, fieldValue: string | number) {
    return apiPost(`api/v1/${companyDomain}/companies/details?fieldType=${fieldType}&fieldValue=${fieldValue}`, {});
  }

  static getUserDetails() {
    return apiGet("api/v1/users");
  }
  static joinGoCardlessWaitingList(isJoiningWishlist: boolean){ 
    return apiPost("api/v1/users/gocardless/waitinglist", { isJoiningWishlist }) 
  }
  static updateUserProfile(newUserDetails: INewUserDetails) {
    return apiPut("api/v1/users", newUserDetails);
  }

  static createCompany(companyDomain: string, company: INewCompany) {
    return apiPost(`api/v1/${companyDomain}/companies`, company);
  }

  static changePassword(currentPassword: string, newPassword: string) {
    return apiPut("api/v1/users/password", { currentPassword, newPassword });
  }

  static requestPasswordLink(email: string) {
    return apiPost(`api/v1/users/emails/${email}/codes`, {});
  }

  static submitNewPassword(newPassword: string, validationCode: string) {
    return apiPatch("api/v1/users/password", { validationCode, newPassword });
  }

  static createProduct(companyDomain: string, product: IBaseProduct) {
    return apiPost(`api/v1/${companyDomain}/products`, product);
  }

  static editProduct(companyDomain: string, product: IProductLight) {
    return apiPut(`api/v1/${companyDomain}/products/${product.productId}`, product as IBaseProduct);
  }

  static fetchAllProducts(companyId: number | null, active = false, excludePlans = false, excludeCycles = false, ) {
    return apiGet(`api/v1/company/${companyId}/products?active=${active || ""}&excludePlans=${excludePlans || ""}&excludeCycles=${excludeCycles || ""}`);
  }

  static fetchProducts(companyDomain: string = "", pageNumber: number = 1, pageSize: number = PAGINATION_PAGE_SIZE, search: string = "", visibilityType: VisibilityType | string = "") {
    return apiGet(`api/v1/${companyDomain}/products?page=${pageNumber}&pageSize=${pageSize}&search=${search}&visibilityType=${visibilityType}`);
  }

  static fetchProduct(companyDomain: string, productId: number) {
    return apiGet(`api/v1/${companyDomain}/products/${productId}`);
  }

  static fetchPlansByProduct(companyDomain: string, productId: number) {
    return apiGet(`api/v1/${companyDomain}/products/${productId}/plans`);
  }

  static createPlan(companyDomain: string, productId: number, plan: IBasePlan) {
    return apiPost(`api/v1/${companyDomain}/products/${productId}/plans`, plan);
  }

  static editPlan(companyDomain: string, productId: number, planId: number, plan: any) {
    return apiPut(`api/v1/${companyDomain}/products/${productId}/plans/${planId}`, plan);
  }

  static updatePlansOrder(companyDomain: string, productId: number, data: { orderedPlanIds: Array<number> }) {
    return apiPut(`api/v1/${companyDomain}/products/${productId}/plans/orders`, data);
  }

  static createCycles(companyDomain: string, productId: number, planId: number, 
    data: { 
      cyclesInputModel: Array<IPricingModel> | IPricingModel, 
      addonPlanInputModels: Array<ILinkedAddOn>, 
      allowancePlanInputModels: Array<ILinkedAllowance> 
    }) {
    return apiPost(`api/v1/${companyDomain}/products/${productId}/plans/${planId}/cycles`, data);
  }

  static createCustomField(companyDomain: string, customField: IBaseCustomField) {
    return apiPost(`api/v1/${companyDomain}/customfields`, customField);
  }

  static getCustomFields(companyDomain: string) {
    return apiGet(`api/v1/${companyDomain}/customfields`);
  }

  static putCustomField(companyDomain: string, customFieldId: number, customField: IBaseCustomField) {
    return apiPut(`api/v1/${companyDomain}/customfields/${customFieldId}`, customField);
  }

  static fetchGettingStartedSettings(companyDomain: string) {
    return apiGet(`api/v1/${companyDomain}/companies/dashboard/settings`);
  }

  static updateGettingStartedSettings(companyDomain: string, dashboardSettings: IGettingStartedSettings) {
    return apiPut(`api/v1/${companyDomain}/companies/dashboard/settings`, dashboardSettings);
  }

  static getPaymentGateways(companyDomain: string) {
    return apiGet(`api/v1/${companyDomain}/paymentgateways`);
  }

  static createPaymentGateway(companyDomain: string, data: { authorizationCode: string; displayName: string }) {
    return apiPost(`api/v1/${companyDomain}/paymentgateways/stripe`, data);
  }

  static updatePaymentGateway(companyDomain: string, paymentGatewayId: number, displayName: string) {
    return apiPut(`api/v1/${companyDomain}/paymentgateways/${paymentGatewayId}`, { displayName });
  }

  static fetchCurrenciesMapping(companyDomain: string) {
    return apiGet(`api/v1/${companyDomain}/paymentgateways/currencymapping`);
  }

  static updateBaseCurrencyPaymentGateway(companyDomain: string, currencyCode: string, paymentGatewayId: number | null, achPaymentGatewayId: number | null) {
    return apiPut(`api/v1/${companyDomain}/paymentgateways/currencymapping/base`, { paymentGatewayId, currencyCode, achPaymentGatewayId });
  }

  static updateCurrencyGatewayMapping(
    companyDomain: string,
    mappings: Array<{
      paymentGatewayId: number | null | undefined;
      currencyCode: string;
    }>
  ) {
    return apiPut(`api/v1/${companyDomain}/paymentgateways/currencymapping`, mappings);
  }

  static getGoLiveChecklist(companyDomain: string) {
    return apiGet(`api/v1/${companyDomain}/companies/live`);
  }

  static moveCompanyToLive(companyDomain: string, data: ILiveCompany) {
    return apiPost(`api/v1/${companyDomain}/companies/live`, data);
  }

  static updateCustomer(companyDomain: string, customerUniqueId: string, customer: BaseCustomer, billingAddress: BaseAddress) {
    const { firstName, lastName, email, phoneNumber, phoneNumberDialCountry, phoneNumberDialCode, companyName } = customer;

    return apiPut(`api/private/v1/${companyDomain}/customers/${customerUniqueId}`, {
      firstName,
      lastName,
      email,
      phoneNumber,
      phoneNumberDialCountry,
      phoneNumberDialCode,
      billingAddress,
      companyName
    });
  }

  static getCompanyApiKey(companyDomain: string) {
    return apiGet(`api/v1/${companyDomain}/companies/configuration/apiKey`);
  }

  static fetchCustomers(companyDomain: string, pageNr: number, pageSize: number, search: string = "", customerStatus?: CustomerStatusType, 
  isCreatedOnDescendingOrder: boolean = true, cardStatus?: CardStatusType) {
    const customerStatusQuery =`&active=${customerStatus === CustomerStatusType.ACTIVE}&inactive=${customerStatus === CustomerStatusType.INACTIVE}`;
    const createdOnSortQuery = `&${isCreatedOnDescendingOrder ? "orderByDescending" : "orderBy"}=createdOn`;
    const cardStatusQuery = `&cardActive=${cardStatus === CardStatusType.ACTIVE}&cardExpired=${cardStatus === CardStatusType.EXPIRED}&noCard=${cardStatus === CardStatusType.NO_CARD}`
    let paymentMethod: PaymentSourceType | string = PaymentSourceType.CreditCards
    if(cardStatus === CardStatusType.NO_CARD) {
      paymentMethod = PaymentSourceType.None
    }
    if(cardStatus === CardStatusType.ACH) {
      paymentMethod = PaymentSourceType.Ach
    }
    if(!cardStatus) {
      paymentMethod = ""
    }
    
    return apiGet(`api/v1/${companyDomain}/customers?page=${pageNr}&pageSize=${pageSize}${customerStatusQuery}${createdOnSortQuery}${cardStatusQuery}&search=${search}&paymentMethod=${paymentMethod}`);
  }
  static deletePaymentGateway(companyDomain: string, paymentGatewayId: number) {
    return apiDelete(`api/v1/${companyDomain}/paymentgateways/${paymentGatewayId}`);
  }

  static getCustomerDetails(companyDomain: string, customerUniqueId: string) {
    return apiGet(`api/v1/${companyDomain}/customers/${customerUniqueId}`);
  }

  static getCustomerAvatar(companyDomain: string, customerUniqueId: string) {
    return apiGet(`api/v1/${companyDomain}/customers/${customerUniqueId}/avatar`);
  }
  
  static getCompanyBranding(companyDomain: string) {
    return apiGet(`api/v1/${companyDomain}/companies/configuration/branding`);
  }

  static updateCompanyBranding(companyDomain: string, data: ICompanyBranding) {
    return apiPost(`api/v1/${companyDomain}/companies/configuration/branding`, data);
  }

  static uploadCompanyImage(companyDomain: string, file: File, type?:string) {
    const data = new FormData();
    data.append("file", file);
    type && data.append("imageType", type);
    return apiPostFiles(`api/v1/${companyDomain}/companies/configuration/branding/imageupload`, data);
  }

  static fetchSubscriptions(companyDomain: string, pageNumber: number, pageSize: number, search: string, subscriptionStatus?: SubscriptionStatusType, searchedProductName: string = "", searchedPlanName: string = "", isCreatedOnSortDescendingOrder: boolean = true) {
    const subscriptionStatusQuery = `&isActive=${subscriptionStatus ===SubscriptionStatusType.ACTIVE}&isCancelled=${subscriptionStatus === SubscriptionStatusType.CANCELLED}&isPaused=${subscriptionStatus === SubscriptionStatusType.PAUSED}&isInFreeTrial=${subscriptionStatus === SubscriptionStatusType.INTRIAL}&isSuspended=${subscriptionStatus === SubscriptionStatusType.SUSPENDED}&productName=${searchedProductName}&planName=${searchedPlanName}`;
    const createdOnSortQuery = `&${isCreatedOnSortDescendingOrder ? "orderByDescending" : "orderBy"}=createdOn`;
    return apiGet(`api/v1/${companyDomain}/subscriptions?page=${pageNumber}&pageSize=${pageSize}${subscriptionStatusQuery}${createdOnSortQuery}&search=${search}`);
  }

  static fetchCustomerCreditNotes(companyDomain: string, customerUniqueId: string) {
    return apiGet(`api/v1/${companyDomain}/customers/${customerUniqueId}/creditNotes`);
  }

  static fetchCustomerInvoices(companyDomain: string, customerUniqueId: string) {
    return apiGet(`api/v1/${companyDomain}/customers/${customerUniqueId}/invoices`);
  }

  static fetchCustomerInvoicesPayments(companyDomain: string, customerUniqueId: string, invoiceNumber: string) {
    return apiGet(`api/v1/${companyDomain}/customers/${customerUniqueId}/invoices/${invoiceNumber}/paymentlogs`);
  }
  static getSubscriptionDetails(companyDomain: string, subscriptionUniqueId: string) {
    return apiGet(`api/v1/${companyDomain}/subscriptions/${subscriptionUniqueId}`);
  }

  static getCustomerSubscriptions(companyDomain: string, customerUniqueId: string) {
    return apiGet(`api/v1/${companyDomain}/customers/${customerUniqueId}/subscriptions`);
  }

  static updateCustomerSubscription(companyDomain: string, subscriptionUniqueId: string | undefined, data: { pauseSubscription: boolean, pauseSubscriptionCycleCount: number | string }) {
    return apiPut(`api/v1/${companyDomain}/subscriptions/${subscriptionUniqueId}`, data)
  }

  static pauseSubscriptionSimulation(companyDomain: string, subscriptionUniqueId: string | undefined, data: { skipCyclesCount: number }) {
    return apiPost(`api/v1/${companyDomain}/subscriptions/${subscriptionUniqueId}/pauseSimulation`, data);
  }

  static resumeSubscriptionSimulation(companyDomain: string, subscriptionUniqueId: string) {
    return apiGet(`api/v1/${companyDomain}/subscriptions/${subscriptionUniqueId}/resumeSimulation`)
  }

  static fetchCreditNotes(companyDomain: string, pageNumber: number, pageSize: number, search: string, creditNoteStatus?: CreditNoteStatus, isDateSortDescendingOrder: boolean = true) {
    const creditNoteStatusQuery = `&isPending=${creditNoteStatus === CreditNoteStatus.PENDING}&isPaid=${creditNoteStatus === CreditNoteStatus.PAID}&isUnpaid=${creditNoteStatus === CreditNoteStatus.UNPAID}&isFailed=${creditNoteStatus === CreditNoteStatus.FAILED}`
    const dateSortQuery = `&${isDateSortDescendingOrder ? "orderByDescending" : "orderBy"}=createdOn`;
    return apiGet(
      `api/v1/${companyDomain}/companies/creditnotes?page=${pageNumber}&pageSize=${pageSize}${creditNoteStatusQuery}${dateSortQuery}&query=${search}`
    );
  }
  
  static fetchCompanyTeammates(companyDomain: string) {
    return apiGet(`api/v1/${companyDomain}/companies/teammates`);
  }

  static revokeTeammateUserAccess(companyDomain: string, userId: number) {
    return apiDelete(`api/v1/${companyDomain}/companies/teammates/${userId}`);
  }

  static sendInvitationToUser(companyDomain: string, data: INewTeammate) {
    return apiPost(`api/v1/${companyDomain}/companies/teammates`, data);
  }

  static editTeammate(companyDomain: string, userId: number, role: UserRole) {
    return apiPut(`api/v1/${companyDomain}/companies/teammates/${userId}`, { role })
  }

  static fetchPendingInvitations() {
    return apiGet("api/v1/users/invitations");
  }

  static fetchDetailsInvitedUser(data: { companyDomain: string, email: string, invitationCode: string }) {
    return apiGet(`api/v1/users/new/invitation?companyDomain=${data.companyDomain}&email=${data.email}&invitationCode=${data.invitationCode}`);
  }

  static registerUserFromInvitation(user: RegisterInvitedUser) {
    return apiPost("api/v1/users/new/invitation", user);
  }

  static acceptDeclinePendingInvitation(data: AcceptDeclinePendingInvitation) {
    return apiPost("api/v1/users/invitations", data);
  }

  static oneTimeChargeUser(companyDomain: string, customerUniqueId: string, data: { currencyCode: string, amount: number, description: string }) {
    return apiPost(`api/v1/${companyDomain}/customers/${customerUniqueId}/invoices`, data)
  }
  static getQrCode() {
    return apiGet("api/v1/users/2fa");
  }

  static activateDeactivateQRCode(qrCode: IQRCode) {
    return apiPut("api/v1/users/2fa", qrCode);
  }

  static sendLoginCodeViaSMS(data: { email: string, password: string }) {
    return apiPost("token/sms", data);
  }

  static fetchEventLogs(companyDomain: string, subscriptionId: string, pageNumber: number, pageSize: number) {
    return apiGet(
      `api/v1/${companyDomain}/subscriptions/${subscriptionId}/logs?page=${pageNumber}&pageSize=${pageSize}`
    );
  }

  static fetchInvoices(companyDomain: string, pageNumber: number, pageSize: number, search: string, invoiceStatus?: InvoiceStatus, isDateSortDescendingOrder: boolean = true, subscriptionUniqueId?: string, isRenewal?: boolean) {
    const invoiceStatusQuery = `&isPending=${invoiceStatus === InvoiceStatus.PENDING}&isPaid=${invoiceStatus === InvoiceStatus.PAID}&isUnpaid=${invoiceStatus === InvoiceStatus.UNPAID}&isPaidOffline=${invoiceStatus === InvoiceStatus.PAID_OFFLINE}&isWrittenOff=${invoiceStatus === InvoiceStatus.WRITTEN_OFF}&isToBeWrittenOff=${invoiceStatus === InvoiceStatus.TOBEWRITTEN_OFF}&isClearing=${invoiceStatus === InvoiceStatus.CLEARING}`;
    const dateSortQuery = `&${isDateSortDescendingOrder ? "orderByDescending" : "orderBy"}=createdOn`;
    const isRenewalQuery = isRenewal ? "&isRenewal=true": "";
    const subscriptionUniqueIdQuery = subscriptionUniqueId ? `&subscriptionUniqueId=${subscriptionUniqueId}` : "";
    return apiGet(`api/v1/${companyDomain}/companies/invoices?page=${pageNumber}&pageSize=${pageSize}${invoiceStatusQuery}${isRenewalQuery}${subscriptionUniqueIdQuery}${dateSortQuery}&query=${search}`)
  }


  static fetchSubscriptionFeatureTags(companyDomain: string, subscriptionUniqueId: string) {
    return apiGet(`api/v1/${companyDomain}/subscriptions/${subscriptionUniqueId}/tags/split`);
  }

  static addSubscriptionFeatureTags(companyDomain: string, data: ISubscriptionFeaturedTags) {
    return apiPost(`api/v1/${companyDomain}/subscriptions/tags`, data);
  }

  static deleteSubscriptionFeatureTags(companyDomain: string, subscriptionUniqueId: string, tag: string) {
    return apiDelete(`api/v1/${companyDomain}/subscriptions/tags/${tag}?subsId=${[subscriptionUniqueId]}`);
  }

  static refundCustomerInvoice(companyDomain: string, customerUniqueId: string, data: { invoiceNumber: string, amount: number }) {
    return apiPost(`api/v1/${companyDomain}/customers/${customerUniqueId}/refunds`, data);
  }

  static putCreditNotePayment(companyDomain: string, creditNoteNumber: string) {
    return apiPut(`api/v1/${companyDomain}/creditNotes/${creditNoteNumber}/Payment?companyDomain=${companyDomain}`, {});
  }

  static putInvoicePayment(companyDomain: string, invoiceNumber: string) {
    return apiPut(`api/v1/${companyDomain}/invoices/${invoiceNumber}/Payment?companyDomain=${companyDomain}`, {});
  }

  static fetchCompanyWebhookSetup(companyDomain: string) {
    return apiGet(`api/v1/${companyDomain}/webhooks`)
  }

  static updateCompanyWebhookSetup(companyDomain: string, data: { targetUrl: string, secret: string }) {
    return apiPost(`api/v1/${companyDomain}/webhooks`, data)
  }

  static removeCompanyWebhookSetup(companyDomain: string) {
    return apiDelete(`api/v1/${companyDomain}/webhooks`);
  }

  static fetchInvoiceStandalone(invoiceCode: string) {
    return apiGet(`api/v1/invoices/${invoiceCode}`);
  }

  static fetchCountriesForTaxation(companyDomain: string) {
    return apiGet(`api/v1/${companyDomain}/companies/configuration/taxation`);
  }

  static fetchTaxJarIntegrationProductCategories(companyDomain: string, apiToken: string) {
    return apiGet(`api/v1/${companyDomain}/taxes/taxjar/${apiToken}/productcategories`)
  }

  static updateTaxJarConfiguration(companyDomain: string, data: ITaxConfigurationData) {
    return apiPost(`api/v1/${companyDomain}/companies/configuration/taxation/taxjar`, data)
  }

  static fetchCompanyConfigurationDetails(companyDomain: string) {
    return apiGet(`api/v1/${companyDomain}/companies/configuration`)
  }

  static updateCompanyConfigurationDetails(companyDomain: string, data: ICompanyDetailsConfigrationData) {
    return apiPut(`api/v1/${companyDomain}/companies/configuration`, data);
  }

  static deleteCustomer(companyDomain: string, customerUniqueId: string) {
    return apiDelete(`api/v1/${companyDomain}/customers/${customerUniqueId}`)
  }

  static updateInvoiceToWrittenOff(companyDomain: string, invoiceNumber: string) {
    return apiPut(`api/v1/${companyDomain}/invoices/${invoiceNumber}/WrittenOff`, {});
  }

  static updateInvoiceToPaidOffLine(companyDomain: string, invoiceNumber: string) {
    return apiPut(`api/v1/${companyDomain}/invoices/${invoiceNumber}/PaidOffLine`, {});
  }

  static clearCustomerDetails(companyDomain: string, customerUniqueId: string) {
    return apiPut(`api/v1/${companyDomain}/customers/${customerUniqueId}/gdprcleanup`, {})
  }

  static createCustomer(companyDomain: string, data: INewCustomerDetailsWithCardDetails) {
    return apiPost(`api/v1/${companyDomain}/customers/app`, {
      addressLine1: data.addressLine1,
      addressLine2: data.addressLine2,
      city: data.addressCity,
      state: data.addressState,
      country: data.addressCountry,
      postCode: data.addressPostCode,
      firstName: data.firstName,
      lastName: data.lastName,
      email: data.email,
      cardDetails: data.cardDetails,
      companyName: data.companyName
    })
  }

  static fetchDashboardChartStats(companyDomain: string, statType: DashboardChartType, period: DashboardChartPeriod) {
    const periodToNumber = {
      [DashboardChartPeriod.DAILY]: 1,
      [DashboardChartPeriod.THREEMONTHS]: 3,
      [DashboardChartPeriod.SIXMONTHS]: 6,
      [DashboardChartPeriod.TWELVEMONTHS]: 12
    }
    return apiGet(`api/v1/${companyDomain}/companies/stats/month?statType=${statType.capitalize()}&amountOfMonths=${periodToNumber[period]}`);
  }

  static fetchDashboardRecentStasts(companyDomain: string) {
    return apiGet(`api/v1/${companyDomain}/companies/stats/recent`);
  }

  static fetchDashboardCurrentStats(companyDomain: string) {
    return apiGet(`api/v1/${companyDomain}/companies/stats/current`);
  }

  static sendCustomerRequestPaymentDetails(companyDomain: string, customerUniqueId: string, type?: "ach" | "creditcard") {
    const query = type ? `?paymentMethodType=${type}` : ""
    return apiGet(`api/v1/${companyDomain}/customers/${customerUniqueId}/paymentdetailsrequest${query}`)
  }

  static deleteTaxJar(companyDomain: string, countryCode: string) {
    return apiDelete(`api/v1/${companyDomain}/companies/configuration/taxation/taxjar/${countryCode}`)
  }

  static getEmailTemplateValues(companyDomain: string, emailType: string) {
    return apiGet(`api/v1/${companyDomain}/companies/configuration/emailconfig/${emailType}`)
  }

  static updateEmailTemplateValues(companyDomain: string, data: IEmailCustomizationReducerState) {
    return apiPost(`api/v1/${companyDomain}/companies/configuration/emailconfig`, data)
  }

  static fetchDunningProccessData(companyDomain: string) {
    return apiGet(`api/v1/${companyDomain}/companies/dunningConfig`)
  }

  static updateDunningProcessData(companyDomain: string, data: IDunningConfig) {
    return apiPut(`api/v1/${companyDomain}/companies/dunningConfig`, data)
  }

  static reattemptInvoicePayment(companyDomain: string, invoiceUniqueId: string) {
    return apiPut(`api/v1/${companyDomain}/invoices/${invoiceUniqueId}/paymentreattempt`, {});
  }

  static replacePaymentCard(companyDomain: string, customerUniqueId: string, data: ICustomerCardDetails) {
    return apiPut(`api/v1/checkout/${companyDomain}/customers/${customerUniqueId}`, data);
  }

  static replacePaymentCardSCA(companyDomain: string, customerUniqueId: string, data: ICustomerCardDetailsSCA) {
    return apiPut(`api/v1/checkout/${companyDomain}/customers/${customerUniqueId}/sca`, data);
  }

  static fetchCompanyForwardingEmail(companyDomain: string) {
    return apiGet(`api/v1/${companyDomain}/companies/configuration/forwardingemail`)
  }

  static updateCompanyForwardingEmail(companyDomain: string, data: { forwardingEmailAddress: string }) {
    return apiPut(`api/v1/${companyDomain}/companies/configuration/forwardingemail`, data)
  }

  static sendInvoiceLink(companyDomain: string, customerUniqueId: string, data: { invoiceNumber: string }) {
    return apiPost(`api/v1/${companyDomain}/customers/${customerUniqueId}/send/invoices`, data)
  }

  static sendCreditNoteLink(companyDomain: string, customerUniqueId: string, data: { invoiceNumber: string }) {
    return apiPost(`api/v1/${companyDomain}/customers/${customerUniqueId}/send/creditnotes`, data)
  }

  static fetchSubscriptionCustomFields(companyDomain: string, productId: number, customerUniqueId: string) {
    return apiGet(`api/v1/${companyDomain}/customfieldResponses?productId=${productId}&customerUniqueId=${customerUniqueId}`);
  }

  static updateSubscriptionCustomFields(companyDomain: string, customfieldResponseId: number, data: { value: any }) {
    return apiPut(`api/v1/${companyDomain}/customfieldResponses/${customfieldResponseId}`, data);
  }

  static fetchCheckoutMgmtData(companyDomain: string) {
    return apiGet(`api/v1/${companyDomain}/companies/configuration/checkout`)
  }

  static updateCheckoutMgmtData(companyDomain: string, data: ICheckoutManagement) {
    return apiPost(`api/v1/${companyDomain}/companies/configuration/checkout`, data)
  }

  static fetchSelfServiceMgmtData(companyDomain: string) {
    return apiGet(`api/v1/${companyDomain}/companies/configuration/accountmanagement`)
  }

  static updateSelfServiceMgmtData(companyDomain: string, data: ISelfServiceManagement) {
    return apiPost(`api/v1/${companyDomain}/companies/configuration/accountmanagement`, data)
  }

  static fetchInvoiceCreditNotesConfig(companyDomain: string) {
    return apiGet(`api/v1/${companyDomain}/companies/configuration/invoices`)
  }

  static updateInvoiceCreditNotesConfig(companyDomain: string, data: IInvoicesFooterConfigData) {
    return apiPut(`api/v1/${companyDomain}/companies/configuration/invoices/footerContent`, data)
  }

  static updateSupportContactDetails(companyDomain: string, invoiceType: InvoiceConfigType, data: IInvoicesSupportContactConfigData) {
    return apiPut(`api/v1/${companyDomain}/companies/configuration/invoices/${invoiceType}`, data)
  }

  static uploadInvoiceAdBanner(companyDomain: string, file: File) {
    const data = new FormData();
    data.append("file", file);
    return apiPostFiles(`api/v1/${companyDomain}/companies/configuration/invoices/adBannerImageUpload`, data);
  }

  static updateAdsBanner(companyDomain: string, file: File) {
    const data = new FormData();
    data.append("file", file);
    return apiPostFiles(`api/v1/${companyDomain}/companies/configuration/adBannerImage`, data);
  }

  static updateSubscription(companyDomain: string, subscriptionUniqueId: string, data: ISubscriptionUpdateModel) {
    return apiPut(`api/v1/${companyDomain}/subscriptions/${subscriptionUniqueId}/plan`, data)
  }

  static allowAccountAccess() {
    return apiPost("api/v1/users/access", {})
  }

  static revokeAccountAccess() {
    return apiDelete("api/v1/users/access")
  }

  static generateSupportCode() {
    return apiGet("api/v1/users/supportcode");
  }

  static fetchShippingAddress(companyDomain: string, subscriptionUniqueId: string) {
    return apiGet(`api/v1/checkout/${companyDomain}/subscriptions/${subscriptionUniqueId}/shippingAddress`)
  }

  static updateShippingAddress(companyDomain: string, subscriptionUniqueId: string, data: BaseAddress) {
    return apiPut(`api/v1/${companyDomain}/subscriptions/${subscriptionUniqueId}/shippingAddress`, data);
  }

  static fetchMappedCurrency(companyDomain: string) {
    return apiGet(`api/v1/${companyDomain}/paymentgateways/currencymapping`);
  }

  static fetchTaxableCountries() {
    return apiGet("api/v1/countries/taxable");
  }

  static getUserSubscription(companyDomain: string, subscriptionUniqueId: string) {
    return apiGet(`api/v1/checkout/${companyDomain}/subscriptions/${subscriptionUniqueId}/customer`);
  }

  static fetchGoogleFonts(){
    return fetchGet(`https://www.googleapis.com/webfonts/v1/webfonts?key=${ConfigConstants.googleFontApiKey}`, getHeaders())
  }

  static requestPaymentCloudCallback = (companyDomain: string) => {
    return apiPost(`api/v1/${companyDomain}/paymentgateways/paymentCloud/callback`, {})
  }

  static updateBillingDate = (companyDomain: string, subscriptionUniqueId: string, data: { newRenewalDate?: string, pauseSubscription?: boolean }) => {
    return apiPut(`api/v1/${companyDomain}/subscriptions/${subscriptionUniqueId}/renewaldate`, data)
  }

  static fetchG2ReviewURL = (companyDomain: string) => {
    return apiGet(`api/v1/${companyDomain}/integrations/g2`);
  }
}

export default API;
