import React, { FormEvent, useState } from "react";
import Panel from "../ui/panel/Panel";
import Text from "../ui/text/Text";
import FormGroup from "../ui/form-group/FormGroup";
import { Row, Col } from "react-grid-system";
import FormLabel from "../ui/form-label/FormLabel";
import Input from "../ui/input/Input";
import Password from "../ui/password/Password";
import Button from "../ui/button/Button";
import "./ChangePassword.scss";
import API from "../../utils/API";
import counterpart from "counterpart";


const ChangePassword: React.FC = () => {

  const [state, setState] = useState({
    currentPassword: "",
    password: "",
    passwordConfirmation: "",
    passwordIsValid: false,
    hasError: false,
    errorMsg: "",
    success: false,
    isLoading: false
  });

  const onSubmit = async (evt: FormEvent) => {
    evt.preventDefault();
    const { currentPassword, password } = state;
    setState((prevState) => { return {
      ...prevState,
      hasError: false,
      success: false,
      isLoading: true
    }})

    try {
      await API.changePassword(currentPassword, password)
      setState((prevState) => { return { ...prevState, success: true }});
    }
    catch (err) {
      const errMsg = err && (err as any).message && (err as any).message.toUpperCase();
      if (errMsg === "USER_PASSWORD_CHANGE_INVALID_PASSWORD") {
        setState((prevState) => { return { ...prevState, hasError: true, success: false, errorMsg: counterpart("CHANGE_PASSWORD_ERROR") }});
      }
      else {
        setState((prevState) => { return { ...prevState, hasError: true, success: false }});
      }
    }
    finally {
      setState((prevState) => { return { ...prevState, isLoading: false }});
    }
  }

  return (
    <form className="change-password" onSubmit={onSubmit}>
      <Panel title="CHANGE_PASSWORD_PANEL1_TITLE" className="change-password__titlepanel">
        <Text content="CHANGE_PASSWORD_PANEL1_CONTENT" noMargin />
      </Panel>
      <Panel>
        <FormGroup>
          <Row align="center">
            <Col md={2}>
              <FormLabel target="psw" content="CHANGE_PASSWORD_CURRENT_PASSWORD" />
            </Col>
            <Col md={10}>
              <Input
                id="psw"
                required
                type="password"
                value={state.currentPassword}
                placeholder="*********"
                isError={state.hasError}
                errorMsg={state.errorMsg}
                onChange={(evt: any) => {
                  let currentPassword = evt.target.value;
                  setState((prevState) => { return { ...prevState, currentPassword }})}
                }
              />
            </Col>
          </Row>
        </FormGroup>

        <Password
          password={state.password}
          passwordConfirmation={state.passwordConfirmation}
          onChangeConfirmPassword={(passwordConfirmation: string) => setState((prevState) => { return { ...prevState, passwordConfirmation }})}
          onChangePassword={(password: string) => setState((prevState) => { return { ...prevState, password }})}
          passwordIsValidCallback={(passwordIsValid: boolean) => setState((prevState) => { return { ...prevState, passwordIsValid }})}
          passwordLabel="CHANGE_PASSWORD_NEW_PASSWORD"
        />

      </Panel>

      <Button
        id="change-psw"
        type="submit"
        disabled={!state.passwordIsValid}
        icon={state.success ? "far fa-check-circle" : ""}
        buttonType={state.success ? "success" : "general"}
        isLoading={state.isLoading}
        title={state.success ? "CHANGE_PASSWORD_SAVE_CHANGES_SUCCESS" : "CHANGE_PASSWORD_SAVE_CHANGES"}
        isFullWidth
      />
    </form>
  )
}

export default ChangePassword;