import React from "react";
import Panel from "../ui/panel/Panel";
import Text from "../ui/text/Text";
import Dropdown from "../ui/dropdown/Dropdown";
import { EXISTING_BILLING_PROVIDERS, QUIZ_FIELD_EXISTING_PROVIDER_TYPE } from "../../utils/constants";
import counterpart from "counterpart";

interface IQuizStep2 {
  onContinue: (nextStep: number, fieldType: string, fieldValue: string) => any
}

const QuizStep2: React.FC<IQuizStep2> = ({ onContinue }) => {
  return (
    <div className="quiz quiz-step2">
      <Panel title="QUIZ_STEP2_TITLE">
        <Text content="QUIZ_STEP2_SUBTITLE" />
        <Dropdown
          menuIsOpen={true}
          id="existingprovider-select"
          placeholder={counterpart("QUIZ_STEP2_EXISTING_PROVIDER_PLACEHOLDER")}
          showTooltipAfterChar={3}
          onChange={(provider: { value: string, label: string }) => EXISTING_BILLING_PROVIDERS.some(el => el === provider.value) && onContinue(4, QUIZ_FIELD_EXISTING_PROVIDER_TYPE, provider.value)}
          options={EXISTING_BILLING_PROVIDERS.map(el => ({ value: el, label: el }))}
        />
        {/* HIDE FOR NOW */}
        {/* <div className="quiz-step2__other">
          <Text content="QUIZ_STEP2_OTHER" className="quiz__link" noMargin onClick={() => onContinue(4, QUIZ_FIELD_EXISTING_PROVIDER_TYPE, "Ignore")} />
          <Text content="QUIZ_STEP2_OWN_SOLUTIONS" className="quiz__link" noMargin onClick={() => onContinue(4, QUIZ_FIELD_EXISTING_PROVIDER_TYPE, "Ignore")} />
        </div> */}
      </Panel>
    </div>
  )
}

export default QuizStep2;