import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Table from "../../../components/ui/table/Table";
import Text from "../../../components/ui/text/Text";
import StatusLabel from "../../../components/ui/status-label/StatusLabel";
import { AppState } from "../../..";
import API from "../../../utils/API";
import { IAuthReducerState } from "../../../reducers/authReducer";
import { IPlanWithCycles, VisibilityType } from "../../../models/Product";
import "./ProductPlansList.scss";
import { getProductStatusColor, getProductStatusText, getCycleDescription } from "../../../utils/commonUtils";
import Button from "../../ui/button/Button";
import ButtonLoader from "../../ui/button/ButtonLoader";
import DragDropContainer from "../../ui/drag-drop-container/DragDropContainer";
import StepCollapsablePanel from "../../ui/step-collapsable-panel/StepCollapsablePanel";
import { Row, Col } from "react-grid-system";
import history from "../../../utils/history";
import Modal from "../../modal/Modal";
import counterpart from "counterpart";
import DeletePlan from "./DeletePlan";
import { Permission } from "../../../models/Auth";
import useCheckFeaturePermission from "../../../utils/hooks/useCheckFeaturePermission";
import AddTooltip from "../../add-tooltip/AddTooltip";

interface IProductPlansTable {
  productId: number;
  reloadData: () => void
}

const ProductPlansTable: React.FC<IProductPlansTable> = ({ productId, reloadData }) => {
  const { currentCompanyDomain } = useSelector<AppState, IAuthReducerState>(state => state.auth);
  
  const isProductPlansReorderPermitted = useCheckFeaturePermission(Permission.ProductPlansReorder);
  const isProductPlanEditPermitted = useCheckFeaturePermission(Permission.ProductPlanEdit);
  const isProductPlanDeletePermitted = useCheckFeaturePermission(Permission.ProductPlanDelete);

  const [plansList, setPlansList] = useState<Array<IPlanWithCycles>>([]);
  const [defaultPlanList, setDefaultPlanList] = useState<Array<IPlanWithCycles>>([]);
  const [reorderPlans, setReorderPlans] = useState(false);
  const [isLoadingData, setIsLoadingData] = useState(true);
  const [isUpdatingOrder, setIsUpdatingOrder] = useState(false);
  const [planToDelete, setPlanToDelete] = useState<IPlanWithCycles | undefined>(undefined);

  useEffect(() => {
    let isSubscribed = true;

    const fetchData = async () => {
      setIsLoadingData(true);

      try {
        const list = (await API.fetchPlansByProduct(currentCompanyDomain, productId)) as Array<IPlanWithCycles>;

        if (isSubscribed) {
          setPlansList(list);
          setDefaultPlanList(list);
          setIsLoadingData(false);
        }
      } catch (err) {
        if (isSubscribed) {
          setIsLoadingData(false);
          console.log(err);
        }
      }
    };

    fetchData();

    return () => {
      isSubscribed = false;
    };
  }, []);

  const renderPlansTable = () => {
    return (
      <Table className="plans-list__table">
        <thead>
          <tr>
            <Text content="PLANS_TABLE_NAME" component="th" noMargin></Text>
            <Text content="PLANS_TABLE_STATUS" component="th" noMargin></Text>
            <Text content="PLANS_TABLE_CYCLES" component="th" noMargin></Text>
            <th>
              <Button
                className="plans-list__reorder-button"
                disabled={!isProductPlansReorderPermitted}
                tooltipMessage={!isProductPlansReorderPermitted ? "NO_ACCESS_MSG" : undefined}
                id="add-new-plan"
                buttonType="add"
                icon="fas fa-sort"
                title="PLANS_BUTTON_REORDER"
                onClick={() => setReorderPlans(true)}
              />
            </th>
          </tr>
        </thead>
        <tbody>
          {plansList.map((plan: IPlanWithCycles) => {
            const cycleDesc = plan.cycles.filter(c => c.visibility !== VisibilityType.OffSale).map(x => getCycleDescription(x, true, plan.pricingModelType));
            return (
              <tr key={plan.planId}>
                <Text content={plan.name} shouldTranslate={false} component="td" noMargin />
                <td>
                  <StatusLabel color={getProductStatusColor(plan.visibility)} content={getProductStatusText(plan.visibility)}></StatusLabel>
                </td>
                <td className="plans-list__table__cycles">
                  {cycleDesc.map((cycle, idx) => (
                    <Text key={idx} content={cycle} shouldTranslate={false} noMargin component="div" />
                  ))}
                </td>
                <td className="plans-list__table__actions">
                  <AddTooltip id="plan-edit" showTooltip={!isProductPlanEditPermitted}>
                    <span className="plans-list__edit" onClick={editPlan(plan.planId)}>
                      <i className="fas fa-fw fa-edit"></i>
                    </span>
                  </AddTooltip>
                  <AddTooltip id="plan-delete" showTooltip={!isProductPlanDeletePermitted}>
                    <span className="plans-list__delete" onClick={() => setPlanToDelete(plan)}>
                      <i className="far fa-fw fa-trash-alt"></i>
                    </span>
                  </AddTooltip>
                  {/* TODO: Determine if plan has subscribers */}
                  {/* <span className="plans-list__lock">
                    <i className="fas fa-fw fa-lock-alt"></i>
                  </span> */}
                </td>
              </tr>
            );
          })}
          <Modal
            isOpen={!!planToDelete}
            onRequestClose={() => setPlanToDelete(undefined)}
            title={counterpart("PLAN_DELETE_MODAL_TITLE", { planName: planToDelete ? planToDelete.name : "" })}
            shouldTranslateTitle={false}
          >
            {planToDelete && (
              <DeletePlan
                plan={planToDelete}
                onClose={() => {
                  reloadData();
                  setPlanToDelete(undefined);
                }}
              />
            )}
          </Modal>
        </tbody>
      </Table>
    );
  };

  const renderReorderPlansList = () => {
    return (
      <div>
        <DragDropContainer
          list={plansList.map(plan => ({
            content: (
              <StepCollapsablePanel
                className="plans-list__draggable-panel"
                key={plan.planId}
                title={
                  <div className="plans-list__draggable-panel__content">
                    <Text content={plan.name} shouldTranslate={false} noMargin />
                    <i className="fas fa-grip-horizontal" />
                  </div>
                }
                shouldTranslateTitle={false}
                hasHelpSection={false}
              />
            ),
            value: plan
          }))}
          onDragEnd={(newPlan: any) => setPlansList(newPlan)}
        />
        <Row align="center">
          <Col md={9}>
            <Button
              disabled={isUpdatingOrder}
              id="update-plans-order"
              type="submit"
              isLoading={isUpdatingOrder}
              isFullWidth
              buttonType="general"
              title="PLANS_BUTTON_UPDATE_ORDER"
              onClick={updateOrder}
            />
          </Col>
          <Col md={3}>
            <Button disabled={isUpdatingOrder} id="cancel-plans-order" isFullWidth buttonType="error" title="PLANS_BUTTON_CANCEL_ORDER" onClick={cancelOrder} />
          </Col>
        </Row>
      </div>
    );
  };

  const editPlan = (planId: number) => (evt: any) => {
    history.push(`/products/products-plans-cycles/${productId}/edit-plan/${planId}`);
  };

  const cancelOrder = () => {
    setPlansList(defaultPlanList);
    setReorderPlans(false);
  };

  const updateOrder = async () => {
    setIsUpdatingOrder(true);

    try {
      await API.updatePlansOrder(currentCompanyDomain, productId, { orderedPlanIds: plansList.map(plan => plan.planId) });
      const list = (await API.fetchPlansByProduct(currentCompanyDomain, productId)) as Array<IPlanWithCycles>;

      setIsUpdatingOrder(false);
      setReorderPlans(false);
      setPlansList(list);
      setDefaultPlanList(list);
    } catch (err) {
      setIsUpdatingOrder(false);
      console.log(err);
    }
  };

  return <div className="plans-list__wrapper">{isLoadingData ? <ButtonLoader /> : reorderPlans ? renderReorderPlansList() : renderPlansTable()}</div>;
};


export default ProductPlansTable
