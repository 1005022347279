import React, { useState } from "react";
import Text from "../text/Text";
import "./FilterSort.scss";

interface IFilterSort {
  component?: string,
  label: string,
  className?: string,
  isDescendingOrder?: boolean,
  onClick: (bool: boolean) => void
}

const FilterSort: React.FC<IFilterSort> = ({ label, component = "div", isDescendingOrder = true, className, onClick }) => {
  const Component: any = component;

  const [isDescending, setIsDescending] = useState(isDescendingOrder);

  const _onClick = () => {
    setIsDescending(!isDescending);
    onClick(!isDescending)
  }

  return (
    <Component className={`filter-sort ${className}`} onClick={_onClick}>
      <Text content={label} noMargin component="span"></Text>
      <div className='filter-sort__arrows'>
        <div className='filter-sort__arrows__up'><i className={`${isDescending ? "fal" : "fas"} fa-sort-up`}></i></div>
        <div className='filter-sort__arrows__down'><i className={`${isDescending ? "fas" : "fal"} fa-sort-down`}></i></div>
      </div>
    </Component>
  )
}

export default FilterSort;