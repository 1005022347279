import API from "../utils/API";
import { makeActionCreator, makeGenericActionCreator } from "../utils/reduxUtils";
import { ICheckoutAccountManagementReducerState } from "../reducers/checkoutAccountManagementReducer";
import { ICheckoutManagement, ISelfServiceManagement } from "../models/CheckoutAccountManagement";

export const FETCH_CHECKOUT_MGMT_REQUEST = "FETCH_CHECKOUT_MGMT_REQUEST";
export const FETCH_CHECKOUT_MGMT_SUCCESS = "FETCH_CHECKOUT_MGMT_SUCCESS";
export const FETCH_CHECKOUT_MGMT_FAILURE = "FETCH_CHECKOUT_MGMT_FAILURE";

export const FETCH_SELF_SERVICE_MGMT_REQUEST = "FETCH_SELF_SERVICE_MGMT_REQUEST";
export const FETCH_SELF_SERVICE_MGMT_SUCCESS = "FETCH_SELF_SERVICE_MGMT_SUCCESS";
export const FETCH_SELF_SERVICE_MGMT_FAILURE = "FETCH_SELF_SERVICE_MGMT_FAILURE";

export const UPDATE_CHECKOUT_MGMT_REQUEST = "UPDATE_CHECKOUT_MGMT_REQUEST";
export const UPDATE_CHECKOUT_MGMT_SUCCESS = "UPDATE_CHECKOUT_MGMT_SUCCESS";
export const UPDATE_CHECKOUT_MGMT_FAILURE = "UPDATE_CHECKOUT_MGMT_FAILURE";

export const UPDATE_SELF_SERVICE_MGMT_REQUEST = "UPDATE_SELF_SERVICE_MGMT_REQUEST";
export const UPDATE_SELF_SERVICE_MGMT_SUCCESS = "UPDATE_SELF_SERVICE_MGMT_SUCCESS";
export const UPDATE_SELF_SERVICE_MGMT_FAILURE = "UPDATE_SELF_SERVICE_MGMT_FAILURE";

export const RESET_CHECKOUT_MGMT_TERMINOLOGY = "RESET_CHECKOUT_MGMT_TERMINOLOGY";
export const RESET_CHECKOUT_MGMT_INCLUDED_FEATURES = "RESET_CHECKOUT_MGMT_INCLUDED_FEATURES";
export const RESET_CHECKOUT_MGMT_TOF_PRIVACY_POLICY = "RESET_CHECKOUT_MGMT_TOF_PRIVACY_POLICY";
export const RESET_CHECKOUT_MGMT_REDIRECT_URL = "RESET_CHECKOUT_MGMT_REDIRECT_URL";
export const RESET_CHECKOUT_MGMT_JS_INTEGRATION = "RESET_CHECKOUT_MGMT_JS_INTEGRATION";
export const RESET_CHECKOUT_MGMT_GA_CODE = "RESET_CHECKOUT_MGMT_GA_CODE";

export const RESET_SELF_SERVICE_MGMT_TERMINOLOGY = "RESET_SELF_SERVICE_MGMT_TERMINOLOGY";
export const RESET_SELF_SERVICE_MGMT_MENU = "RESET_SELF_SERVICE_MGMT_MENU";

export const RESET_CHECKOUT_ACCT_MGMT = "RESET_CHECKOUT_ACCT_MGMT";
export const RESET_CHECKOUT_ACCT_MGMT_SHOW_HIDE_FEATURES = "RESET_CHECKOUT_ACCT_MGMT_SHOW_HIDE_FEATURES";
export const RESET_CHECKOUT_ACCT_MGMT_CONFIRMATION_SCREEN = "RESET_CHECKOUT_ACCT_MGMT_CONFIRMATION_SCREEN";
export const SET_CHECKOUT_ACCT_MGMT = "SET_CHECKOUT_ACCT_MGMT";
export const UPDATE_CHECK_OUT_MANAGEMENT_DATA = "UPDATE_CHECK_OUT_MANAGEMENT_DATA";

export type AllowedCheckoutAcctMgmtReducerFields = keyof ICheckoutAccountManagementReducerState;
export type AllowedCheckoutMgmtFields = keyof ICheckoutManagement;
export type AllowedSelfServiceMgmtFields = keyof ISelfServiceManagement;

export const resetSelfServiceMgmtTerminology = makeActionCreator(RESET_SELF_SERVICE_MGMT_TERMINOLOGY, "payload");
export const resetSelfServiceMgmtMenu = makeActionCreator(RESET_SELF_SERVICE_MGMT_MENU, "payload");

export const resetCheckoutMgmtTerminology = makeActionCreator(RESET_CHECKOUT_MGMT_TERMINOLOGY, "payload");
export const resetCheckoutMgmtIncludedFeatures = makeActionCreator(RESET_CHECKOUT_MGMT_INCLUDED_FEATURES, "payload");
export const resetCheckoutMgmtTOFPrivacyPolcy = makeActionCreator(RESET_CHECKOUT_MGMT_TOF_PRIVACY_POLICY, "payload");
export const resetCheckoutMgmtRedirectUrl = makeActionCreator(RESET_CHECKOUT_MGMT_REDIRECT_URL, "payload");
export const resetCheckoutMgmtJsIntegration = makeActionCreator(RESET_CHECKOUT_MGMT_JS_INTEGRATION, "payload");
export const resetCheckoutMgmtGACode = makeActionCreator(RESET_CHECKOUT_MGMT_GA_CODE, "payload");
export const resetCheckoutAcctMgmt = makeActionCreator(RESET_CHECKOUT_ACCT_MGMT, "payload");
export const resetCheckoutAcctMgmtShowHideFeatures = makeActionCreator(RESET_CHECKOUT_ACCT_MGMT_SHOW_HIDE_FEATURES, "payload");
export const resetCheckoutAcctMgmtConfirmationScreen = makeActionCreator(RESET_CHECKOUT_ACCT_MGMT_CONFIRMATION_SCREEN, "payload");

export const setCheckoutAccountMgmtField = makeGenericActionCreator<AllowedCheckoutAcctMgmtReducerFields>(SET_CHECKOUT_ACCT_MGMT);
export const updateCheckoutManagementData = makeActionCreator(UPDATE_CHECK_OUT_MANAGEMENT_DATA, "payload");


export const fetchCheckoutMgmtData = function(companyDomain: string) {
  return {
    types: [FETCH_CHECKOUT_MGMT_REQUEST, FETCH_CHECKOUT_MGMT_SUCCESS, FETCH_CHECKOUT_MGMT_FAILURE],
    callAPI: () => API.fetchCheckoutMgmtData(companyDomain)
  };
}

export const updateCheckoutMgmtData = function(companyDomain: string, data: ICheckoutManagement) {
  return {
    types: [UPDATE_CHECKOUT_MGMT_REQUEST, UPDATE_CHECKOUT_MGMT_SUCCESS, UPDATE_CHECKOUT_MGMT_FAILURE],
    callAPI: () => API.updateCheckoutMgmtData(companyDomain, data)
  };
}

export const fetchSelfServiceMgmtData = function(companyDomain: string) {
  return {
    types: [FETCH_SELF_SERVICE_MGMT_REQUEST, FETCH_SELF_SERVICE_MGMT_SUCCESS, FETCH_SELF_SERVICE_MGMT_FAILURE],
    callAPI: () => API.fetchSelfServiceMgmtData(companyDomain)
  };
}

export const updateSelfServiceMgmtData = function(companyDomain: string, data: ISelfServiceManagement) {
  return {
    types: [UPDATE_SELF_SERVICE_MGMT_REQUEST, UPDATE_SELF_SERVICE_MGMT_SUCCESS, UPDATE_SELF_SERVICE_MGMT_FAILURE],
    callAPI: () => API.updateSelfServiceMgmtData(companyDomain, data)
  };
}

