import React, { useEffect, useState } from "react";
import PageWrapper from "../../components/ui/layout/PageWrapper";
import counterpart from "counterpart";
import Helmet from "react-helmet";
import NavigationAlertCurrencyMismatch from "../../components/ui/navigation-alert/NavigationAlertCurrencyMismatch";
import TransactionsWelcome from "../../components/transactions/TransactionsWelcome";
import "./Transactions.scss";
import { useDispatch, useSelector } from "react-redux";
import Table, { TableArrowBtn } from "../../components/ui/table/Table";
import Text from "../../components/ui/text/Text";
import StatusLabel from "../../components/ui/status-label/StatusLabel";
import FilterDropdown from "../../components/ui/filter-dropdown/FilterDropdown";
import FilterSort from "../../components/ui/filter-sort/FilterSort";
import Modal from "../../components/modal/Modal";
import { COLOR } from "../../utils/constants";
import { AppState } from "../..";
import Search from "../../components/search/Search";
import { fetchTransactionLogs, setTransactionLogsField } from "../../actions/transactionLogsActions";
import Pagination from "../../components/ui/pagination/Pagination";
import { GetPaymentLogsPaginatedRequest, LogExtended, PaymentLogStatus } from "../../utils/grpc/generated/Billsby.Protos/billing/private/paymentlogs/payment_logs_pb";
import { Timestamp } from "google-protobuf/google/protobuf/timestamp_pb";
import moment from "moment";
import ProgressIndicator from "../../components/ui/progress-indicator/ProgressIndicator";
import ViewTransactionLog from "../../components/view-transaction-log/ViewTransactionLog";
import { PaymentSourceType } from "../../utils/grpc/generated/Billsby.Protos/billing/private/paymentgateway/payment_gateway_pb";

export const Transactions: React.FC = () => {
  const dispatch = useDispatch();
  const { searchedTransactions, transactionLogsPaginated, isFirstSearch, hasTransactionLogs, isLoadingLogs, isCreatedOnDescendingOrder, selectedLogStatus, selectedCustomerIdType } = useSelector((state: AppState) => state.transactionLogsReducer);
  const currentCompanyId = useSelector((state: AppState) => state.auth.currentCompanyId);
  const { currentPage, pageCount, rowCount, results } = transactionLogsPaginated;
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [selectedLog, setSelectedLog] = useState<Partial<LogExtended.AsObject>>({ reference: "", paymentSourceType: PaymentSourceType.NONE });

  

  const filterStatusOptions = [
    { label: counterpart("TRANSACTIONS_STATUS_SUCCESS"), value: PaymentLogStatus.SUCCESS },
    { label: counterpart("TRANSACTIONS_STATUS_PENDINGACH"), value: PaymentLogStatus.PENDING_ACH },
    { label: counterpart("TRANSACTIONS_STATUS_PENDING"), value: PaymentLogStatus.PENDING },
    { label: counterpart("TRANSACTIONS_STATUS_FAILED"), value: PaymentLogStatus.FAILED },
    { label: counterpart("TRANSACTIONS_STATUS_DECLINED"), value: PaymentLogStatus.DECLINED }
  ];

  const filterCustomerId = [
    { label: counterpart("TRANSACTIONS_CUSTOMER_ID"), value: GetPaymentLogsPaginatedRequest.PaymentLogType.WITH_CUSTOMER_ID },
    { label: counterpart("TRANSACTIONS_NO_CUSTOMER_ID"), value: GetPaymentLogsPaginatedRequest.PaymentLogType.WITHOUT_CUSTOMER_ID },
  ]

  useEffect(() => {
    if (results || !currentCompanyId) {
      return;
    }

    dispatch(fetchTransactionLogs(
      currentCompanyId,
      1,
      searchedTransactions,
      PaymentLogStatus.UNSPECIFIED,
      GetPaymentLogsPaginatedRequest.PaymentLogType.UNSPECIFIED
    ))
  }, [])

  if (!results) {
    return <ProgressIndicator color="blue" coverage='full-content' />
  }

  if (!hasTransactionLogs && isFirstSearch || !currentCompanyId) {
    return (
      <>
        <Helmet title={counterpart("NAVBAR_TRANSACTIONS")} />
        <NavigationAlertCurrencyMismatch />
        <TransactionsWelcome />
      </>
    )
  }

  const getStatusText = (status: PaymentLogStatus) => {
    switch (status) {
      case PaymentLogStatus.SUCCESS:
        return "TRANSACTIONS_STATUS_SUCCESS"
      case PaymentLogStatus.FAILED:
        return "TRANSACTIONS_STATUS_FAILED"
      case PaymentLogStatus.DECLINED:
        return "TRANSACTIONS_STATUS_DECLINED"
      case PaymentLogStatus.PENDING:
        return "TRANSACTIONS_STATUS_PENDING"
      case PaymentLogStatus.PENDING_ACH:
        return "TRANSACTIONS_STATUS_PENDINGACH"
      default:
        return ""
    }
  }

  const getStatusColor = (status: PaymentLogStatus) => {
    switch (status) {
      case PaymentLogStatus.SUCCESS:
        return COLOR.GREEN
      case PaymentLogStatus.FAILED:
      case PaymentLogStatus.DECLINED:
        return COLOR.RED
      case PaymentLogStatus.PENDING:
        return COLOR.ORANGE
      case PaymentLogStatus.PENDING_ACH:
        return COLOR.LIGHT_ORANGE
      default:
        return undefined
    }
  }

  const renderSearchPanel = () => {
    return (
      <div className='transaction-logs__header'>
        <div className="transaction-logs__search">
          <Text
            content="TRANSACTIONS_LIST_TABLE_NUMBER"
            translateWith={{
              num: rowCount
            }}
            noMargin
          />
          <Search
            value={searchedTransactions}
            placeholder="TRANSACTIONS_SEARCH_PLACEHOLDER"
            onChange={(evt: any) => dispatch(setTransactionLogsField("searchedTransactions", evt.target.value))}
            onSearch={() => dispatch(fetchTransactionLogs(currentCompanyId, 1, searchedTransactions, selectedLogStatus?.value, selectedCustomerIdType?.value, isCreatedOnDescendingOrder))}
          />
          <Pagination
            className="transaction-logs__search__pagination"
            pageCount={pageCount}
            isVisible={pageCount > 1}
            forcePage={currentPage - 1}
            onPageChange={({ selected }) => {
              dispatch(setTransactionLogsField("transactionLogsPaginated", { ...transactionLogsPaginated, currentPage: selected + 1 }));
              dispatch(fetchTransactionLogs(currentCompanyId, selected + 1, searchedTransactions, selectedLogStatus?.value, selectedCustomerIdType?.value, isCreatedOnDescendingOrder));
            }}
          />
        </div>
      </div>
    )
  }

  const renderTransactionsTable = () => {
    if (isLoadingLogs) {
      return <ProgressIndicator color="blue" coverage='full-content' />
    }

    return (
      <Table enableHoverEffect className="transaction-logs__table">
        <thead>
          <tr>
            <Text content="TRANSACTIONS_REFERENCE" component="th" noMargin />
            <Text content="TRANSACTIONS_FULL_NAME" component="th" noMargin />
            <FilterSort className="transaction-logs__date-sort" label="TRANSACTIONS_CREATED_ON" component="th"
              isDescendingOrder={isCreatedOnDescendingOrder}
              onClick={(bool) => {
                dispatch(setTransactionLogsField("isCreatedOnDescendingOrder", bool));
                dispatch(fetchTransactionLogs(currentCompanyId, currentPage, searchedTransactions, selectedLogStatus?.value, selectedCustomerIdType?.value, bool));
              }}
            />
            <FilterDropdown label="TRANSACTIONS_STATUS" component="th"
              shouldTranslateOptionLabel={false}
              selected={selectedLogStatus}
              className="transaction-logs__product-filter"
              options={filterStatusOptions}
              onClear={() => {
                dispatch(setTransactionLogsField("selectedLogStatus", undefined));
                dispatch(fetchTransactionLogs(currentCompanyId, 1, searchedTransactions, undefined, selectedCustomerIdType?.value, isCreatedOnDescendingOrder));
              }}
              onSelect={(filter) => {
                dispatch(setTransactionLogsField("selectedLogStatus", filter));
                dispatch(fetchTransactionLogs(currentCompanyId, 1, searchedTransactions, filter?.value, selectedCustomerIdType?.value, isCreatedOnDescendingOrder));
              }}
            />
            <FilterDropdown label="TRANSACTIONS_CUSTOMER_ID" component="th"
              shouldTranslateOptionLabel={false}
              selected={selectedCustomerIdType}
              className="transaction-logs__product-filter"
              options={filterCustomerId}
              onClear={() => {
                dispatch(setTransactionLogsField("selectedCustomerIdType", undefined))
                dispatch(fetchTransactionLogs(currentCompanyId, 1, searchedTransactions, selectedLogStatus?.value, undefined, isCreatedOnDescendingOrder));
              }}
              onSelect={(filter) => {
                dispatch(setTransactionLogsField("selectedCustomerIdType", filter));
                dispatch(fetchTransactionLogs(currentCompanyId, 1, searchedTransactions, selectedLogStatus?.value, filter?.value, isCreatedOnDescendingOrder));
              }}
            />
            <Text className="view-arrow" content="" component="th" shouldTranslate={false} noMargin />
          </tr>
        </thead>
        <tbody>
          {
            results?.map((log, _) => {
              return (
                <tr className={`transaction-logs__table__item ${log.getStatus() === PaymentLogStatus.PENDING_ACH ? "disabled" : ""}`} key={log.getReference()} onClick={() => {
                  if (log.getStatus() === PaymentLogStatus.PENDING_ACH) {
                    return;
                  }

                  setIsOpenModal(true);
                  setSelectedLog(log.toObject())
                }}>
                  <td>{log.getReference()}</td>
                  <td>{log.getCustomerFullName()}</td>
                  <td>{moment((log.getCreatedOn() as Timestamp).toDate()).format("DD MMM YYYY")}</td>
                  <td>
                    <StatusLabel content={getStatusText(log.getStatus())} color={getStatusColor(log.getStatus())} />
                  </td>
                  <td>{log.getCustomerUniqueId() || "N/A"}</td>
                  <TableArrowBtn>
                    <i className="far fa-chevron-right"></i>
                  </TableArrowBtn>
                </tr>
              )
            })
          }

        </tbody>
        <Modal isOpen={isOpenModal} onRequestClose={() => setIsOpenModal(false)} title="VIEW_TRANSACTION_LOG_TITLE">
           <ViewTransactionLog data={selectedLog}></ViewTransactionLog>
        </Modal>
      </Table>
    )
  }

  return (
    <div className='transaction-logs'>
      <Helmet title={counterpart("NAVBAR_TRANSACTIONS")} />
      <NavigationAlertCurrencyMismatch />
      {renderSearchPanel()}
      <PageWrapper>
        {renderTransactionsTable()}
      </PageWrapper>
    </div>
  )
}

export default Transactions;