import * as React from "react";
import "./BasicPreview.scss";
import Text from "../../ui/text/Text";
import CustomFieldPreview from "../../ui/custom-field-preview/CustomFieldPreview";
import CheckoutInput from "./ui-checkout/CheckoutInput";


interface INumbersPreviewProps {
  label: string,
  supportText: string
}

const NumbersPreview: React.FC<INumbersPreviewProps> = ({ label = "", supportText = "" }) => {
  return (
    <CustomFieldPreview className="preview">
      <Text content={label} shouldTranslate={false} />
      <CheckoutInput type="number" />
      <Text content={supportText} shouldTranslate={false} noMargin />
    </CustomFieldPreview>
  );
};

export default NumbersPreview;