import React from "react";
import "./CheckoutCheckbox.scss";

interface ICheckoutCheckbox {
  /**
  * The ID of the checkbox
  */
  id?: string,
  /**
   * The checked state of the checkbox
   */
  checked: any,
  /**
   * The callback when a checkbox is selected
   */
  onClick: () => void,
  /**
   * Whether or not the user has to tick these checkboxes
   */
  required?: boolean,
  /**
   * The text of the checkbox option could be either a string or a node (plain html)
   */
  content: string | JSX.Element,
  /**
   * The design style of the checkbox
   */
  checkType?: "general",
  /**
   * The value of the checkbox
   */
  value: string | number
  /*
   * Remove margin if it's true
   */
  noMargin?: boolean
}

const CheckoutCheckbox: React.FC<ICheckoutCheckbox> = (props) => {
  const {
    id,
    checked,
    onClick,
    required = false,
    content = "",
    checkType = "general",
    value,    
    noMargin = false
  } = props;

  return (
    <div className={`checkout-checkbox ${!noMargin ? "cc-checkbox--margin-bottom" : ""}`} onClick={onClick}>
      <input
        id={id}
        className={`checkout-checkbox-${checkType}__input`}
        type="checkbox"
        checked={checked}
        onClick={evt => { evt.preventDefault(); evt.stopPropagation(); }}
        onChange={evt => { evt.preventDefault(); evt.stopPropagation(); }}
        required={required}
        value={value}
      />
      {checked ? <i className={`fas fa-check-circle fa-2x checkout-checkbox-${checkType}__icon`} /> : <i className={`fal fa-circle fa-2x checkout-checkbox-${checkType}__icon`} />}
      <label className={`checkout-checkbox-${checkType}__label`} htmlFor={id}>{ content }</label>
    </div>

  );
}

export default CheckoutCheckbox;
