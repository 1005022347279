import React, { FormEvent, useEffect, useLayoutEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Panel from "../ui/panel/Panel";
import Text from "../ui/text/Text";
import Button from "../ui/button/Button";
import API from "../../utils/API";
import { AppState } from "../..";
import "./ReplacePaymentMethod.scss";
import CollapsablePanelWithArrow from "../ui/collapsable-panel-with-arrow/CollapsablePanelWithArrow";
import { CardStatus, ICustomerPaymentDetailsError, PaymentMethodType } from "../../models/Customer";
import NoticePanel from "../ui/notice-panel/NoticePanel";
import PaymentDetailsForm, { validatePaymentDetailsForm } from "../payment-details-form/PaymentDetailsForm";
import { ISpreedlyCardData } from "../../models/Spreedly";
import { setCustomerDetailsField, updateCustomerCardDetails, fetchCustomerCardDetails, fetchCustomerDetails } from "../../actions/customerDetailsActions";
import ErrorNotification from "../ui/error-notification/ErrorNotification";
import { SPREEDLY_TEST_CARD_NAME, SPREEDLY_TEST_CREDIT_CARD, SPREEDLY_TEST_CVV, SPREEDLY_TEST_EXP_MONTH, SPREEDLY_TEST_EXP_YEAR } from "../../utils/constants";
import { GettingStartedType } from "../../models/GettingStarted";
import { fetchPaymentGateways } from "../../actions/paymentGatewaysActions";
import { fetchCurrenciesMapping } from "../../actions/currenciesActions";

interface IState {
  success: boolean;
  isLoading: boolean;
  sendingRequest: boolean;
  sendingACHRequest: boolean;
  requestACHSent: boolean;
  requestSent: boolean;
  openManualInputPanel: boolean;
  openSendPaymentDetailsPanel: boolean;
  openACHMandatePanel: boolean;
  cardholderName: string;
  expiryMonth: number;
  expiryYear: number;
  cardDetailsErrors: ICustomerPaymentDetailsError | null;
}

const ReplacePaymentMethod: React.FC = () => {
  const dispatch = useDispatch<Function>();
  const { auth, paymentGatewaysData, customerData } = useSelector((state: AppState) => ({ auth: state.auth, paymentGatewaysData: state.paymentGatewaysReducer, customerData: state.customerDetailsReducer }))
  const { currentCompanyDomain, currentCompanyId, dashboardSettings } = auth;
  const { isScaActivated, isFetchingPaymentGateways } = paymentGatewaysData;
  const isACHActivated = useSelector<AppState, boolean>(state => !!state.currenciesReducer.currenciesGatewaysMapping?.some(c => c.achPaymentGatewayId))
  const { customerUniqueId, billingAddress, email, isUpdatingCustomerCardDetails, hasErrorUpdatingCustomerCardDetails, cardStatus, cardDetails: { paymentMethodType } } = customerData;
  const isACHMandated = paymentMethodType === PaymentMethodType.ACH;

  const [state, setState] = useState<IState>({
    success: false,
    isLoading: false,
    sendingRequest: false,
    sendingACHRequest: false,
    requestACHSent: false,
    requestSent: false,
    openManualInputPanel: false,
    openSendPaymentDetailsPanel: false,
    openACHMandatePanel: isACHMandated,
    cardholderName: "",
    expiryMonth: 0,
    expiryYear: 0,
    cardDetailsErrors: null
  });
  
  useEffect(() => {
    // isScaActivated is set asynchronously
    if(isScaActivated && (!isACHActivated || cardStatus !== CardStatus.MISSING) ) {
      setState(prevState => ({ ...prevState, openSendPaymentDetailsPanel: true }))
    }
    else {
      setState(prevState => ({ ...prevState, openSendPaymentDetailsPanel: false }))
    }
  }, [isScaActivated, isACHActivated])

  useLayoutEffect(() => {
    dispatch(fetchPaymentGateways(currentCompanyDomain))
    dispatch(fetchCurrenciesMapping(currentCompanyDomain))

    return () => {
      dispatch(setCustomerDetailsField("hasErrorUpdatingCustomerCardDetails", false))
    }
  }, [])

  const handleUpdatePaymentMethod = (evt: FormEvent) => {
    evt.preventDefault();
    const { cardholderName, expiryMonth, expiryYear } = state;

    validatePaymentDetailsForm({ cardholderName, expiryMonth, expiryYear },
      {
        addressLine1: billingAddress.addressLine1,
        addressLine2: billingAddress.addressLine2,
        state: billingAddress.state,
        city: billingAddress.city,
        country: billingAddress.country,
        postCode: billingAddress.postCode
      },
      async (cardData: ISpreedlyCardData, error: ICustomerPaymentDetailsError | null) => {
        if (error || !cardData) {
          setState((prevState) => { return { ...prevState, cardDetailsErrors: error }});
          return;
        }

        setState((prevState) => { return { ...prevState, cardDetailsErrors: null }});

        if (!currentCompanyId || !customerUniqueId) {
          return null;
        }

        try {
          const response = await dispatch(updateCustomerCardDetails(customerUniqueId, currentCompanyId, cardData.card_type, cardData.full_name, cardData.last_four_digits, expiryMonth, expiryYear, cardData.token))
          if(response.error) {
            throw new Error(response.error.message);
          }
          await dispatch(fetchCustomerDetails(currentCompanyDomain, customerUniqueId))
          await dispatch(fetchCustomerCardDetails(currentCompanyId, customerUniqueId));
          dispatch(setCustomerDetailsField("showReplacePaymentModal", false))
        } catch (err) {
          console.log(err)
        }
      });
  };

  const handleSendDetailsRequest = async (evt: any) => {
    evt.preventDefault();

    setState((prevState) => { return { ...prevState,  sendingRequest: true, requestSent: false }});

    if (!customerUniqueId) {
      return null;
    }

    try {
      await API.sendCustomerRequestPaymentDetails(currentCompanyDomain, customerUniqueId);
      setState((prevState) => { return { ...prevState, sendingRequest: false, requestSent: true }});
    } catch (err) {
      setState((prevState) => { return { ...prevState,  sendingRequest: false }});
    }
  };

  const handleSendACHMandateRequest = async (evt: any) => {
    evt.preventDefault();
    setState((prevState) => ({ ...prevState, sendingACHRequest: true, requestACHSent: false }));
    
    try {
      await API.sendCustomerRequestPaymentDetails(currentCompanyDomain, customerUniqueId, "ach");
      setState((prevState) => ({ ...prevState, sendingACHRequest: false, requestACHSent: true }));
    } catch (err) {
      setState((prevState) => ({ ...prevState, sendingACHRequest: false}));
    }
  }

  const validateData = () => {
    const { cardholderName, expiryMonth, expiryYear } = state;
    return !cardholderName || !expiryMonth || !expiryYear;
  };

  const renderManualInputDetailsForm = () => {
    const { openManualInputPanel, cardholderName, expiryMonth, expiryYear, cardDetailsErrors } = state;
    const paymentDetailsFormData = { cardholderName, expiryMonth, expiryYear };
    const isLive = dashboardSettings && (dashboardSettings.status === GettingStartedType.OnTrial || dashboardSettings.status === GettingStartedType.Live);
    if (isScaActivated || isACHMandated) { 
      return null 
    }
    return (
      <CollapsablePanelWithArrow
        onClick={() => setState((prevState) => { return { ...prevState,  openManualInputPanel: !state.openManualInputPanel }})}
        isCollapsed={!openManualInputPanel}
        title={"REPLACE_CARD_PANEL2_TITLE"}
        subtitle={"REPLACE_CARD_PANEL2_CONTENT"}
      >
        <form onSubmit={handleUpdatePaymentMethod}>
          <PaymentDetailsForm
            onChangeCardholderName={(name) => setState((prevState) => { return { ...prevState, cardholderName: name }})}
            onChangeExpiryMonth={(month) => setState((prevState) => { return { ...prevState, expiryMonth: month }})}
            onChangeExpiryYear={(year) => setState((prevState) => { return { ...prevState, expiryYear: year }})}
            cardNumberId="spreedly-number-2"
            cvvId="spreedly-cvv-2"
            initialCardholderName={!isLive ? SPREEDLY_TEST_CARD_NAME : undefined}
            initialCardNumber={!isLive ? SPREEDLY_TEST_CREDIT_CARD : undefined}
            initialCvv={!isLive ? SPREEDLY_TEST_CVV : undefined}
            initialExpiryMonth={!isLive ? SPREEDLY_TEST_EXP_MONTH : undefined}
            initialExpiryYear={!isLive ? SPREEDLY_TEST_EXP_YEAR : undefined}
            formData={paymentDetailsFormData}
            formErrors={cardDetailsErrors}
          />
          <Button 
            id="payment-details-btn" 
            type="submit" 
            buttonType={"general"} 
            disabled={validateData()} 
            isLoading={isUpdatingCustomerCardDetails} 
            title={"REPLACE_CARD_BUTTON_UPDATE_DETAILS"} 
            isFullWidth 
          />
        </form>
      </CollapsablePanelWithArrow>
    );
  };

  const renderSendPaymentDetailsForm = () => {
    if(isACHMandated) {
      return null;
    }

    return (
      <CollapsablePanelWithArrow
        onClick={() => setState((prevState) => { return { ...prevState, openSendPaymentDetailsPanel: !state.openSendPaymentDetailsPanel }})}
        isCollapsed={!state.openSendPaymentDetailsPanel} 
        title={"REPLACE_CARD_PANEL3_TITLE"}
        subtitle={"REPLACE_CARD_PANEL3_CONTENT1"}
      >
        <Text content="REPLACE_CARD_PANEL3_CONTENT2" translateWith={{ customerEmail: <span className="replace-payment-method__email">{email}</span> }}></Text>
        <Button 
          id="send-request-btn" 
          type="submit" 
          isLoading={state.sendingRequest} 
          icon={state.requestSent ? "far fa-check-circle" : ""} 
          buttonType={state.requestSent ? "success" : "general"} 
          title={state.requestSent ? "REPLACE_CARD_BUTTON_SEND_REQUEST_SENT" : "REPLACE_CARD_BUTTON_SEND_REQUEST"} 
          onClick={handleSendDetailsRequest} 
          isFullWidth 
        />
      </CollapsablePanelWithArrow>
    );
  };

  const renderACHMandate = () => {
    if((!isACHMandated && cardStatus !== CardStatus.MISSING) || !isACHActivated) {
      return null
    }
    return (
      <CollapsablePanelWithArrow
        onClick={() => setState((prevState) => { return { ...prevState, openACHMandatePanel: !state.openACHMandatePanel }})}
        isCollapsed={!state.openACHMandatePanel} 
        title={"REPLACE_CARD_PANEL4_TITLE"}
        subtitle={"REPLACE_CARD_PANEL4_CONTENT1"}
      >
        <Text content="REPLACE_CARD_PANEL4_CONTENT2" translateWith={{ customerEmail: <span className="replace-payment-method__email">{email}</span> }}></Text>
        <Button 
          id="send-ach-request-btn" 
          type="submit" 
          isLoading={state.sendingACHRequest} 
          icon={state.requestACHSent ? "far fa-check-circle" : ""} 
          buttonType={state.requestACHSent ? "success" :"general"} 
          title={state.requestACHSent ? "REPLACE_CARD_BUTTON_SEND_REQUEST_SENT" : "REPLACE_CARD_PANEL4_BTN"} 
          onClick={handleSendACHMandateRequest} 
          isFullWidth 
        />
      </CollapsablePanelWithArrow>
    );
  }

  if (isFetchingPaymentGateways) {
    return null;
  }

  return (
    <>
      {hasErrorUpdatingCustomerCardDetails && <ErrorNotification autoClose={false} isModal title="REPLACE_CARD_ERROR"></ErrorNotification>}
      {state.cardDetailsErrors && <NoticePanel isModal type="warning" content="PAYMENT_DETAILS_INCORRECT_DETAILS" />}
      <div className="replace-payment-method">
        <Panel className="replace-payment-method__title-panel" title="REPLACE_CARD_PANEL1_TITLE">
          <Text className="replace-payment-method__title-text" content="REPLACE_CARD_PANEL1_CONTENT" noMargin />
        </Panel>
        {renderManualInputDetailsForm()}
        {renderSendPaymentDetailsForm()}
        {renderACHMandate()}
      </div>
    </>
  );
}

export default ReplacePaymentMethod;
