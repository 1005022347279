import counterpart from "counterpart";
import { ICountry } from "../models/Country";
import API from "./API";

declare global {
  interface Window {
    ccObjectRef: any;
    clickToAddress: (config: CraftyClicksConfig) => any
  }
}


export enum CraftyClicksField {
  SEARCH = "CraftClicksSearch", // 'search_field' is the name of the search box element
  LINE_1 = "CraftClicksLine1",
  LINE_2 = "CraftClicksLine2",
  CITY = "CraftClicksCity",
  POSTCODE = "CraftClicksPostcode",
  STATE = "CraftClicksState",
  COUNTRY = "CraftClicksCountry"
}

export interface CraftyClicksSelectedAddress {
  line1: string,
  line2: string,
  city: string,
  zipCode: string,
  state: string,
  countryIso2: string,
  countryIso3: string,
  countryName: string
}


export interface CraftyClicksConfig {
  accessToken: string,
  dom: {
    search: string,
    line_1: string,
    line_2: string,
    town: string,
    postcode: string,
    county: string,
    country: string
  } | null,
  domMode?: string,
  gfxMode?: number,
  style?: object,
  // Use a custom CSS file
  cssPath?: string,
  placeholders?: boolean, // Show placeholders
  texts?: {
    default_placeholder?: string,
    country_placeholder?: string,
    country_button?: string,
    generic_error?: string,
    no_results?: string
    more?: string
  },
  showLogo?: boolean, // Show ClickToAddress logo
  historyTools?: boolean, // Show arrows
  defaultCountry?: string, // Sets the default country to the UK
  getIpLocation?: boolean, // Sets default country based on IP
  countrySelector?: boolean, // Country list is enabled
  enabledCountries?: Array<string>, // Enabled countries are the UK, USA and Canada
  countryMatchWith?: string, // enabledCountries uses ISO_3 codes
  countryLanguage?: string, // Country list displayed in English
  disableAutoSearch?: boolean // Auto-search is enabled,
  onResultSelected?: (c2a: any, elements: any, address: any) => void,
  onSetCounty?: (c2a: any, elements: any, county: any) => any,
  onError?: (code: any, message: any) => void,
  onSearchFocus?: (c2a: string, elements: string) => void,

}

export default class CraftyClicks {
  static DEFAULT_DOM = {
    search: CraftyClicksField.SEARCH,
    town: CraftyClicksField.CITY,
    postcode: CraftyClicksField.POSTCODE,
    county: CraftyClicksField.STATE,
    line_1: CraftyClicksField.LINE_1,
    line_2: CraftyClicksField.LINE_2,
    country: CraftyClicksField.COUNTRY,
  }

  static DEFAULT_CONFIG: CraftyClicksConfig = {
    accessToken: "9faba-3c5fb-bb266-0200c",
    domMode: "id",
    dom: CraftyClicks.DEFAULT_DOM,
    showLogo: false,
    placeholders: true, // Show placeholders
    defaultCountry: "usa"
  };

  static supportedCountries: Array<ICountry>;

  static async loadConfig(onSelectAddress: (selectedAddress: CraftyClicksSelectedAddress) => void, newConfig?: Partial<CraftyClicksConfig>) {
    if(!this.supportedCountries) {
      try {
        this.supportedCountries = await API.getCountries() as Array<ICountry>;
      }
      catch(err) {}
    }
    
    const config = {
      ...this.DEFAULT_CONFIG,
      ...newConfig,
      enabledCountries: this.supportedCountries ? this.supportedCountries.map(country => country.iso3Code.toLocaleLowerCase()) : ["usa", "can"],
      //enabledCountries: ['usa', 'can'],
      //if we lock a country to a specific value e.g. GBR the user CANNOT select a different one from the crafty click panel
      countrySelector: true,
      onResultSelected(c2a: any, elements: any, addressCC: any) {
        const address: CraftyClicksSelectedAddress = {
          line1: addressCC.line_1,
          line2: addressCC.line_2,
          city: addressCC.locality,
          zipCode: addressCC.postal_code,
          state: addressCC.province,
          countryIso2: addressCC.country.iso_3166_1_alpha_2,
          countryIso3: addressCC.country.iso_3166_1_alpha_3,
          countryName: addressCC.country.country_name
        }
        onSelectAddress(address);
      },
      onSearchFocus(c2a: any, dom: any) {
        //global function provided by CraftyClicks library to transform the country to an internal format
        let countryCode = (window as any).getCountryCode(c2a, false, "iso_3");
        countryCode && c2a.selectCountry(countryCode);
      },
      texts: {
        default_placeholder: counterpart("CRAFTY_CLICKS_ADDRESS_PHONE_SEARCH_PLACEHOLDER_DEFAULT"),
        country_placeholder: counterpart("CRAFTY_CLICKS_ADDRESS_PHONE_SEARCH_PLACEHOLDER_COUNTRY"),
        country_button: counterpart("CRAFTY_CLICKS_ADDRESS_PHONE_SEARCH_COUNTRY_BUTTON"),
        generic_error: counterpart("CRAFTY_CLICKS_ADDRESS_PHONE_SEARCH_ERROR_GENERIC"),
        no_results: counterpart("CRAFTY_CLICKS_ADDRESS_PHONE_SEARCH_NO_RESULTS")
      }
    };


    if (window.ccObjectRef) {
      // if multiple crafty clicks address lookup in the same page
      window.ccObjectRef.attach(config.dom, config);
    } else {
      window.ccObjectRef = new (window as any).clickToAddress(config) as any;
    }
  }
}