import { BillsbyAction } from "../models/BillsbyAction";
import { ICompanyDetails } from "../models/CompanyDetails";
import { AppState } from "..";
import { UPDATE_COMPANY_DETAILS_ALERT_EMAIL_FAILURE, UPDATE_COMPANY_DETAILS_ALERT_EMAIL_REQUEST, UPDATE_COMPANY_DETAILS_ALERT_EMAIL_SUCCESS, FETCH_COMPANY_DETAILS_ALERT_EMAIL_SUCCESS, SET_COMPANY_DETAILS_FIELD, UPDATE_COMPANY_DETAILS_FAILURE, UPDATE_COMPANY_DETAILS_SUCCESS, UPDATE_COMPANY_DETAILS_REQUEST, RESET_COMPANY_DETAILS, FETCH_COMPANY_DETAILS_SUCCESS, SET_COMPANY_DETAILS_ADDRESS, SET_COMPANY_DETAILS_ALERT_NOTIF_FIELD } from "../actions/companyDetailsActions";
import { getActionErrorMessage } from "../utils/commonUtils";
import { GetCompanyAlertsAndNotificationResponse } from "../utils/grpc/generated/Billsby.Protos/Messenger/private/companyconfig/companyconfig_pb";

export interface ICompanyDetailsReducerState extends ICompanyDetails { }

export const initialState: ICompanyDetailsReducerState = {
  companyDetails: {
    name: "",
    billingAddressLine1: "",
    billingAddressLine2: "",
    billingAddressCity: "",
    billingAddressPostCode: "",
    billingAddressState: "",
    billingAddressCountryISO3: "",
    companyDomain: "",
    companyId: 0,
    countryCode: "",
    companyName: "",
    industry: "",
    timeZone: "",
    isUpdatingDetailsRequest: false,
    isUpdatingDetailsSuccess: false,
    isUpdatingDetailsFailure: false,
    errorMessage: ""
  },
  companyAlertsAndNotifications: {
    billingEmail: "",
    alertEmail: "",
    isUpdatingAlertEmailRequest: false,
    isUpdatingAlertEmailSuccess: false,
    isUpdatingAlertEmailFailure: false,
    errorMessage: ""
  }
}

export default function companyDetailsReducer(state: ICompanyDetailsReducerState = initialState, action: BillsbyAction, store: AppState) {
  switch (action.type) {

    case SET_COMPANY_DETAILS_FIELD: {
      return { ...state, companyDetails: { ...state.companyDetails, [action.fieldName]: action.fieldValue } }
    }

    case FETCH_COMPANY_DETAILS_SUCCESS: {
      return { ...state, companyDetails: { ...action.response, isUpdatingDetailsRequest: false, isUpdatingDetailsSuccess: false, isUpdatingDetailsFailure: false } }
    }

    case SET_COMPANY_DETAILS_ADDRESS: {
      const { payload } = action;
      return { ...state, companyDetails: { ...state.companyDetails, billingAddressLine1: payload.line1, billingAddressLine2: payload.line2, billingAddressCity: payload.city, billingAddressState: payload.state, billingAddressCountryISO3: payload.countryIso3, billingAddressPostCode: payload.zipCode, countryName: payload.countryName, timeZone: payload.timeZone, industry: payload.industry } }
    }

    case UPDATE_COMPANY_DETAILS_REQUEST: {
      return { ...state, companyDetails: { ...state.companyDetails, isUpdatingDetailsRequest: true, isUpdatingDetailsSuccess: false, isUpdatingDetailsFailure: false } }
    }

    case UPDATE_COMPANY_DETAILS_SUCCESS: {
      return { ...state, companyDetails: { ...state.companyDetails, isUpdatingDetailsRequest: false, isUpdatingDetailsSuccess: true, isUpdatingDetailsFailure: false } }
    }

    case UPDATE_COMPANY_DETAILS_FAILURE: {
      return { ...state, companyDetails: { ...state.companyDetails, isUpdatingDetailsRequest: false, isUpdatingDetailsSuccess: false, isUpdatingDetailsFailure: true, errorMessage: getActionErrorMessage(action.error) } }
    }

    case UPDATE_COMPANY_DETAILS_ALERT_EMAIL_REQUEST: {
      return { ...state, companyAlertsAndNotifications: { ...state.companyAlertsAndNotifications, isUpdatingAlertEmailRequest: true, isUpdatingAlertEmailSuccess: false, isUpdatingAlertEmailFailure: false }}
    }

    case UPDATE_COMPANY_DETAILS_ALERT_EMAIL_SUCCESS: {
      return { ...state, companyAlertsAndNotifications: { ...state.companyAlertsAndNotifications, isUpdatingAlertEmailRequest: false, isUpdatingAlertEmailSuccess: true, isUpdatingAlertEmailFailure: false }}
    }

    case UPDATE_COMPANY_DETAILS_ALERT_EMAIL_FAILURE: {
      return { ...state, companyAlertsAndNotifications: { ...state.companyAlertsAndNotifications, isUpdatingAlertEmailRequest: false, isUpdatingAlertEmailSuccess: false, isUpdatingAlertEmailFailure: true, errorMessage: action.error.message }}
    }

    case FETCH_COMPANY_DETAILS_ALERT_EMAIL_SUCCESS: {
      const response = action.response as GetCompanyAlertsAndNotificationResponse
      return { ...state, companyAlertsAndNotifications: { ...state.companyAlertsAndNotifications, alertEmail: response.getAlertEmail() } }
    }

    case SET_COMPANY_DETAILS_ALERT_NOTIF_FIELD: {
      return { ...state, companyAlertsAndNotifications: { ...state.companyAlertsAndNotifications, [action.fieldName] : action.fieldValue } }
    }

    case RESET_COMPANY_DETAILS: {
      return initialState;
    }

    default:
      return state
  }
}