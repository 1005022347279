import React, { useState, useEffect } from "react";
import BackNavigationPanel from "../../../../../ui/back-navigation-panel/BackNavigationPanel";
import MenuTable from "../../../../../ui/menu-table/MenuTable";
import { UpdateEmailNotificationStatusRequest, UpdateEmailNotificationStatusResponse } from "../../../../../../utils/grpc/generated/Billsby.Protos/Messenger/private/companyconfig/companyconfig_pb";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../../../../../..";
import { updateEmailNotificationToggles, fetchEmailNotificationToggles } from "../../../../../../actions/emailRoutingActions";
import { EmailTypes } from "../../../../../../utils/grpc/generated/Billsby.Protos/Messenger/private/companyconfig/companyconfig_pb";
import { useHistory } from "react-router";


const DiscountCouponsMenu: React.FC = () => {
  const emailNotificationToggles = useSelector((state: AppState) => state.emailRoutingReducer.emailNotificationToggles);
  const currentCompanyId = useSelector((state: AppState) => state.auth.currentCompanyId);

  const dispatch = useDispatch<Function>();
  const history = useHistory();

  const [switchState, setSwitchState] = useState({
    tokenApplied: false,
    expiresIn20Days: false
  });

  const updateData = async (updateEmailNotification: UpdateEmailNotificationStatusRequest) => {
    try {
      await (dispatch(updateEmailNotificationToggles(updateEmailNotification)) as { response: UpdateEmailNotificationStatusResponse });
      dispatch(fetchEmailNotificationToggles(Number(currentCompanyId)));
    }
    catch (err) { }
  }

  useEffect(() => {
    if (emailNotificationToggles.getEmailNotificationConfigsList().length) {
      let newState: any = {};
      emailNotificationToggles.getEmailNotificationConfigsList().forEach((elem) => {
        switch (elem.getEmailType()) {
          case EmailTypes.TOKEN_APPLIED:
            newState.tokenApplied = elem.getIsEnabled();
            break;
          case EmailTypes.REMINDER_COUPON_EXPIRES_IN_20_DAYS:
            newState.expiresIn20Days = elem.getIsEnabled();
            break;
        }
      })
      setSwitchState(newState);
    }
  }, [emailNotificationToggles]);

  useEffect(() => {
    dispatch(fetchEmailNotificationToggles(Number(currentCompanyId)));
  }, []);


  return (
    <div>
      <BackNavigationPanel
        title={"DISCOUNTS_AND_COUPONS_TEMPLATE_MENU"} shouldTranslateTitle={true}
        onClick={() => history.push("/configuration/email-notifications/emails")}>
        <MenuTable
          data={[
            {
              title: "DISCOUNTS_AND_COUPONS_TEMPLATE_MENU_COUPON_IS_APPLIED",
              onClick: () => history.push("/configuration/email-notifications/emails/discount-coupons/coupon-applied-email-template"),
              canConfigureNotification: true,
              enableNotification: switchState.tokenApplied,
              enableNotificationChange: (isChecked) => {
                setSwitchState({ ...switchState, tokenApplied: isChecked });
                const updateEmailNotification = new UpdateEmailNotificationStatusRequest();
                updateEmailNotification.setCompanyId(Number(currentCompanyId));
                updateEmailNotification.setEmailType(EmailTypes.TOKEN_APPLIED);
                updateEmailNotification.setIsEnabled(isChecked);
                updateData(updateEmailNotification);
              }
            },
            {
              title: "DISCOUNTS_AND_COUPONS_TEMPLATE_MENU_BEFORE_COUPON_EXPIRED",
              onClick: () => history.push("/configuration/email-notifications/emails/discount-coupons/before-discount-expires-email-template"),
              canConfigureNotification: true,
              enableNotification: switchState.expiresIn20Days,
              enableNotificationChange: (isChecked) => {
                setSwitchState({ ...switchState, expiresIn20Days: isChecked });
                const updateEmailNotification = new UpdateEmailNotificationStatusRequest();
                updateEmailNotification.setCompanyId(Number(currentCompanyId));
                updateEmailNotification.setEmailType(EmailTypes.REMINDER_COUPON_EXPIRES_IN_20_DAYS);
                updateEmailNotification.setIsEnabled(isChecked);
                updateData(updateEmailNotification);
              }
            }
          ]}
        />
      </BackNavigationPanel>
    </div>
  )
}

export default DiscountCouponsMenu;