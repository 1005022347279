import React from "react";
import Panel from "../ui/panel/Panel";
import Text from "../ui/text/Text";
import Button from "../ui/button/Button";
import { QUIZ_FIELD_TIMESCALE_CAPTURED_TYPE } from "../../utils/constants";

interface IQuizStep3 {
  onContinue: (nextStep: number, fieldType: string, fieldValue: string) => any
}

const QuizStep3: React.FC<IQuizStep3> = ({ onContinue }) => {
  return (
    <div className="quiz quiz-step3">
      <Panel title="QUIZ_STEP3_TITLE">
        <Text content="QUIZ_STEP3_SUBTITLE" />
        <Button id="quiz-step3-opt1" title="QUIZ_STEP3_OPT1" isFullWidth onClick={() => onContinue(5, QUIZ_FIELD_TIMESCALE_CAPTURED_TYPE, "1 month")} />
        <Button id="quiz-step3-opt2" title="QUIZ_STEP3_OPT2" isFullWidth onClick={() => onContinue(5, QUIZ_FIELD_TIMESCALE_CAPTURED_TYPE, "1-3 months")} />
        <Button id="quiz-step3-opt3" title="QUIZ_STEP3_OPT3" isFullWidth onClick={() => onContinue(5, QUIZ_FIELD_TIMESCALE_CAPTURED_TYPE, "3-12 months")} />
        <Button id="quiz-step3-opt4" title="QUIZ_STEP3_OPT4" isFullWidth onClick={() => onContinue(5, QUIZ_FIELD_TIMESCALE_CAPTURED_TYPE, "> 12 months")} />
        <Text content="QUIZ_STEP3_LINK" className="quiz__link" noMargin onClick={() => onContinue(5, QUIZ_FIELD_TIMESCALE_CAPTURED_TYPE, "Ignore")} />
      </Panel>
    </div>
  )
}

export default QuizStep3;