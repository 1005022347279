import counterpart from "counterpart";
import React, { useEffect, useMemo, useState } from "react";
import Helmet from "react-helmet";
import { useDispatch, useSelector } from "react-redux";
import { Link, NavLink, useRouteMatch } from "react-router-dom";
import { AppState } from "../../..";
import PageWrapper from "../../../components/ui/layout/PageWrapper";
import NavigationBar from "../../../components/ui/navigation-bar/NavigationBar";
import TabbedContainer from "../../../components/ui/tabbed-container/TabbedContainer";
import Text from "../../../components/ui/text/Text";
import history from "../../../utils/history";
import "./FreeAgent.scss";
import FreeAgentOverview from "./free-agent-overview/FreeAgentOverview";
import Button from "../../../components/ui/button/Button";
import StepCollapsablePanel from "../../../components/ui/step-collapsable-panel/StepCollapsablePanel";
import logo from "../../../images/integrations/free-agent-logo.png";
import { PrivateRoute } from "../../../containers/AuthRoutes";
import FreeAgentFaq from "./free-agent-faq/FreeAgentFaq";
import { getToken } from "../../../utils/apiUtils";
import { AccountingIntegrationType } from "../../../models/AccountingIntegrations";
import Modal from "../../modal/Modal";
import CollapsablePanelWithArrow from "../../ui/collapsable-panel-with-arrow/CollapsablePanelWithArrow";
import Panel from "../../ui/panel/Panel";
import NavigationAlert from "../../ui/navigation-alert/NavigationAlert";
import { openIntercomIframe } from "../../../utils/commonUtils";
import NoticePanel from "../../ui/notice-panel/NoticePanel";
import UninstallFreeAgent from "./uninstall-free-agent/UninstallFreeAgent";
import FreeAgentInstalled from "./free-agent-installed/FreeAgentInstalled";
import FreeAgentCheckList from "./free-agent-check-list/FreeAgentCheckList";
import { useIsFromSpecificPage } from "../../../utils/custom-hooks";
import { setShowBillingModal } from "../../../actions/authActions";
import { Permission } from "../../../models/Auth";
import { createFreeAgentIntegration, fetchAccountIntegrations, setAccountingIntegrationsField } from "../../../actions/accountingIntegrationsActions";
import ProgressIndicator from "../../ui/progress-indicator/ProgressIndicator";

export const FreeAgent: React.FC = () => {
  const authData = useSelector((state: AppState) => state.auth);
  const accountingIntegrations = useSelector((state: AppState) => state.accountingIntegrationsReducer);
	const isFromChecklist = useIsFromSpecificPage("/launch-checklist");
  const isFromBillingModal = useIsFromSpecificPage("billing-modal");

  const dispatch = useDispatch<Function>()
  const match = useRouteMatch();

  const { currentCompanyId, parsedToken } = authData;
  const { integrations, isCreatingIntegrationSuccess, isCreatingIntegrationFailure, isDeleteIntegrationSuccess } = accountingIntegrations;
  const [showModal, setShowModal] = useState(false);
  const [showSuccessIntegrationModal, setShowSuccessIntegrationModal] = useState(false);
  const [faqCollpsed, setFaqCollapsed] = useState(false);
  const [showNotification, setShowNotification] = useState(false);
  const [showErrorNotification, setShowErrorNotification] = useState(false);
  const [showUninstallNotification, setShowUninstallNotification] = useState(false);
  const [showFreeAgentCheckList, setShowFreeAgentCheckList] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    return () => {
      dispatch(setAccountingIntegrationsField("isCreatingIntegrationFailure", false));
      dispatch(setAccountingIntegrationsField("isCreatingIntegrationSuccess", false));
      dispatch(setAccountingIntegrationsField("isDeleteIntegrationSuccess", false));
      dispatch(setAccountingIntegrationsField("isDeleteIntegrationFailure", false));
    }
  }, [])

  useEffect(() => {
    setIsLoading(true);
    //ugly trick to update the integrations because the creation and deletion are asynchronous and when backend returns it might still be pending
    //https://dev.azure.com/airfidev/Billsby/_workitems/edit/25832?src=WorkItemMention&src-action=artifact_link
    setTimeout(async() => {
      await dispatch(fetchAccountIntegrations(Number(authData.currentCompanyId)));
      setIsLoading(false);
    }, 1500);
  }, [isCreatingIntegrationSuccess, isDeleteIntegrationSuccess])

  useEffect(() => {
    if(isCreatingIntegrationSuccess) {
      setShowNotification(true);
      setShowSuccessIntegrationModal(true);
    }
    if(isCreatingIntegrationFailure) {
      setShowErrorNotification(true)
    }
  }, [isCreatingIntegrationSuccess, isCreatingIntegrationFailure])

  useEffect(() => {
    const url_string = window.location.href;
    const url = new URL(url_string);
    const authCode = url.searchParams.get("code");
    const error = url.searchParams.get("error");
    if(!!error) {
      dispatch(setAccountingIntegrationsField("isCreatingIntegrationFailure", true));
    }
    if(authCode) {
      dispatch(createFreeAgentIntegration(currentCompanyId as number, authCode));
    }

  }, [])

  const hasPermissionToInstall = useMemo(() => {
    if(parsedToken?.CurrentPermissions.some(p => p === Permission[Permission.FreeAgentRead])) {
      return true
    }
    return false
  }, [parsedToken])

  const isInstalled = useMemo(() => {
    // apparently creating an integration is an async operation and when backend return the isSuccess flag the getCompanyIntegrations endpoint is not immediately updated, for this reason we use the following OR ||
    return integrations.getIntegrationsList().some(i => i.getName() === AccountingIntegrationType.FREEAGENT && i.getIsInstalled()) || isCreatingIntegrationSuccess;
  }, [integrations]);


  const pathIsIncluded = (path: string) => {
    return history.location.pathname.toLocaleLowerCase().includes(path);
  }

  const renderNavBar = () => {
    return (
      <NavigationBar pageTitle="" previousPageTitle={
        isFromChecklist || isFromBillingModal
        ? "GO_LIVE_PRO_PLAN_FEATURE_NAVBAR" 
        : "INTEGRATIONS_TITLE"} 
        previousPageCb={() => 
        isFromChecklist 
        ? history.push({ pathname: "/launch-checklist", state: { hasprofeature: true } }) 
        : isFromBillingModal ? dispatch(setShowBillingModal(true))
        : history.push("/integrations")} />
    )
  }

  const getWarningNewFeature = () => {
    return (
      <NavigationAlert
        className="free-agent-integrations__integration-warning"
        title="INTEGRATION_FREE_AGENT_WARNING_TITLE"
        subTitle="INTEGRATION_FREE_AGENT_WARNING_CONTENT"
        buttonLabel="INTEGRATION_FREE_AGENT_SUCCESS_BUTTON"
        icon="fas fa-headset"
        type="info"
        onClick={() => openIntercomIframe()}
      />
    )
  }

  const getWarningPermission = () => {
    return (
      <NoticePanel
        type="warning"
        icon="far fa-lightbulb-exclamation"
        content="INTEGRATIONS_INSTALL_WARNING_PERMISSION"
        translateWith={{
          here: <Link to="" id="billing-modal-link" onClick={(evt) => { dispatch(setShowBillingModal(true)); evt.preventDefault();}}>{counterpart("REPORTS_ACCOUNTING_WARNING_PERMISSION_HERE")}</Link>
        }}
        isVisible={!hasPermissionToInstall}
      />
    )
  }

  if(isLoading) {
    return <ProgressIndicator color="blue" coverage="full-content" />
  }

  if (isInstalled) {
    return (
      <div>
        <Helmet title={counterpart("INTEGRATIONS_TITLE")} />
        <div className="free-agent-integrations">
          {renderNavBar()}
          {getWarningNewFeature()}
          {isInstalled && showNotification &&
            <NavigationAlert
              className="free-agent-integrations__integration-success"
              title="INTEGRATION_FREE_AGENT_SUCCESS_TITLE"
              subTitle="INTEGRATION_FREE_AGENT_SUCCESS_TEXT"
              buttonLabel="INTEGRATION_FREE_AGENT_SUCCESS_BUTTON"
              icon="fas fa-headset"
              onClick={() => openIntercomIframe()}
            />}
          <PageWrapper>
            <StepCollapsablePanel title={
              <div className="free-agent-integrations__title">
                <div>
                  <img src={logo} alt="no img" />
                  <div>
                    <Text content="INTEGRATIONS_FREE_AGENT" noMargin />
                  </div>
                </div>
                <div>
                  <div>
                    <Button
                      id="visit-website"
                      title="INTEGRATIONS_VIEW_WEBSITE"
                      onClick={(evt: any) => {
                        evt.stopPropagation();
                        window.open("https://www.freeagent.com/");
                      }}
                      buttonType="add"
                    />
                  </div>
                  <Button
                    id="uninstall-btn"
                    title="INTEGRATION_UNINSTALL"
                    onClick={() => setShowModal(true)}
                    buttonType="error"
                  />
                </div>
              </div>
            } className="free-agent-integrations__free-agent"
              shouldTranslateTitle={false}
              hasHelpSection={false}
              isCollapsed={false}
            >
              <Text content="INTEGRATIONS_FREE_AGENT_INTEGRATED_CONTENT" noMargin />
            </StepCollapsablePanel>


            <CollapsablePanelWithArrow
              className="free-agent-integrations__faq__panel"
              title="INTEGRATIONS_FAQS"
              isCollapsed={faqCollpsed}
              onClick={() => setFaqCollapsed(!faqCollpsed)}
            >

              <Text content="INTEGRATION_FAQ_PANEL1_TITLE" className="free-agent-integrations__faq__text text-title" />
              <Text content="INTEGRATION_FAQ_PANEL1_TEXT" />

              <Text content="INTEGRATION_FAQ_PANEL2_TITLE" className="free-agent-integrations__faq__text text-title" />
              <Text content="INTEGRATION_FAQ_PANEL2_TEXT" />

              <Text content="INTEGRATION_FAQ_PANEL3_TITLE" className="free-agent-integrations__faq__text text-title" />
              <Text content="INTEGRATION_FAQ_PANEL3_TEXT1" />
              <div className="free-agent-integrations__faq__text text-group">
                <Text content="INTEGRATION_FAQ_PANEL3_TEXT2" className="free-agent-integrations__faq__text text-inline" />
                <Text
                  content="INTEGRATION_FAQ_PANEL3_TEXT3"
                  className="free-agent-integrations__faq__text text-inline text-link"
                  onClick={() => window.open("https://www.thebunker.net/", "_self")}
                />
                <Text content="INTEGRATION_FAQ_PANEL3_TEXT4" className="free-agent-integrations__faq__text text-inline" />
                <Text
                  content="INTEGRATION_FAQ_PANEL3_TEXT5"
                  className="free-agent-integrations__faq__text text-inline text-link"
                  onClick={() => window.open("https://www.freeagent.com/features/security/", "_self")}
                />
                <Text content="INTEGRATION_FAQ_PANEL3_TEXT6" className="free-agent-integrations__faq__text text-inline" />
              </div>

              <Text content="INTEGRATION_FAQ_PANEL4_TITLE" className="free-agent-integrations__faq__text text-title" />
              <Text content="INTEGRATION_FAQ_PANEL4_TEXT" />
            </CollapsablePanelWithArrow>

            <Panel title="INTEGRATION_CONTACT_FREE_AGENT">
              <Text content="INTEGRATION_CONTACT_FREE_AGENT_TEXT" className="qfree-agent-faq__text" />
              <i className="button-icon fa fa-globe-americas left free-agent-faq__text text-title text-inline"></i>
              <Text
                content="INTEGRATION_CONTACT_FREE_AGENT_SUPPORT_PAGE"
                className="free-agent-faq__text text-title text-link text-inline"
                onClick={() => window.open("https://www.freeagent.com/company/contact-us/", "_self")}
              />
            </Panel>
          </PageWrapper>
        </div>
        <Modal isOpen={showModal} onRequestClose={() => setShowModal(false)} title="INTEGRATION_UNINSTALL_FREE_AGENT">
          <UninstallFreeAgent onUninstall={() => setShowModal(false)} />
        </Modal>

        <Modal isOpen={showSuccessIntegrationModal} onRequestClose={() => setShowSuccessIntegrationModal(false)} title="INTEGRATION_FREE_AGENT_INSTALLED_MODAL_TITLE">
          <FreeAgentInstalled />
        </Modal>

      </div>
    )
  }

  return (
    <div>
      <Helmet title={counterpart("INTEGRATIONS_TITLE")} />

      <div className="integrations">
        {renderNavBar()}
        {getWarningNewFeature()}
        {getWarningPermission()}
        {
          !isInstalled && showErrorNotification &&
          <NoticePanel
            className="free-agent-integrations__error"
            type="error"
            icon="fas fa-exclamation-circle"
            content={"INTEGRATION_FREE_AGENT_ERROR"}
          />
        }
        {
          showUninstallNotification &&
          <NoticePanel
            className="free-agent-integrations__error"
            type="error"
            icon="fas fa-exclamation-circle"
            content={"INTEGRATION_FREE_AGENT_UNINSTALL_TEXT"}
          />
        }
        <PageWrapper>
          <StepCollapsablePanel title={
            <div className="integrations__title">
              <div>
                <img src={logo} alt="no img" />
                <div>
                  <Text content="INTEGRATIONS_FREE_AGENT" noMargin />
                </div>
              </div>
              <div>
                <div>
                  <Button
                    id="integrate-btn"
                    title="INTEGRATIONS_INTEGRATE"
                    disabled={!hasPermissionToInstall}
                    icon={"far fa-plus-circle"}
                    buttonType="general"
                    onClick={() => setShowFreeAgentCheckList(true)}
                  />
                </div>
                <Button
                  id="visit-website"
                  title="INTEGRATIONS_VIEW_WEBSITE"
                  onClick={(evt: any) => {
                    evt.stopPropagation();
                    window.open("https://www.freeagent.com/", "_self");
                  }}
                  buttonType="add"
                />
              </div>
            </div>
          } className="integrations__free-agent"
            shouldTranslateTitle={false}
            hasHelpSection={false}
            isCollapsed={false}
          >
            <Text content="INTEGRATIONS_FREE_AGENT_CONTENT" noMargin />
          </StepCollapsablePanel>

          <TabbedContainer type="submenu">
            <NavLink isActive={(match, location) => (!match || !location ? false : match?.isExact)} activeClassName="active" to={`${match?.url}`}>
              {counterpart("INTEGRATIONS_OVERVIEW")}
            </NavLink>
            <NavLink isActive={(match, location) => (!match || !location ? false : match?.isExact) || pathIsIncluded("/faq")} exact activeClassName="active" to={`${match?.url}/faq`}>
              {counterpart("INTEGRATIONS_FAQS")}
            </NavLink>
          </TabbedContainer>

          <PrivateRoute render={() => (
            <>
              <PrivateRoute exact path={match?.path} component={FreeAgentOverview} />
              <PrivateRoute exact path={`${match?.path}/faq`} component={FreeAgentFaq} />
            </>
          )} />

        </PageWrapper>
      </div>

      <Modal
        isOpen={showFreeAgentCheckList}
        shouldCloseOnOverlayClick={false}
        onRequestClose={() => {
          setShowFreeAgentCheckList(false);
          setShowErrorNotification(true);
        }}
        title="INTEGRATION_FREE_AGENT_INSTALLED_MODAL_TITLE">
        <FreeAgentCheckList token={getToken().access_token} currentCompanyId={currentCompanyId} />
      </Modal>
    </div>
  )
}

export default FreeAgent;
