import React from "react";
import "./InvoicesWelcome.scss";
import Panel from "../../components/ui/panel/Panel";
import Text from "../../components/ui/text/Text";

export const InvoicesWelcome: React.FC = () => {
  return (
    <div className="invoices">
     <Panel className="invoices__greeting" title="INVOICES_WELCOME_TITLE">
      <Text content="INVOICES_WELCOME_TEXT" />
      <Panel className="invoices__greeting-question" title="INVOICES_FIRST_NOTE_TITLE">
        <Text noMargin content="INVOICES_FIRST_NOTE_TEXT" />
      </Panel>
     </Panel>
    </div>
  )
}

export default InvoicesWelcome;