export type INavItemVisibility = {
  [key in INavItemVisibilityKey]: INavItemVisibilityValue
}

type INavItemVisibilityKey = "dashboard" | "newDashboard" | "customers" | "subscriptions" | "invoices" | "products" | "logs" | "reports" | "zapier" | "integrations" | "settings" | "help" | "company" | "user";

type INavItemVisibilityValue = boolean | Array<number>

export interface INavItem {
  isExpanded?: boolean,
  targets: Array<string>
}

export interface INavItems {
  dashboard: INavItem,
  newDashboard: INavItem,
  customers: INavItem,
  subscriptions: INavItem,
  invoice: INavItem,
  products: INavItem,
  logs: INavItem,
  reports: INavItem,
  settings: INavItem,
  zapier: INavItem,
  integrations: INavItem
}

export enum NavigationBarSteps {
  SELECT_PLAN = "SELECT_PLAN",
  PERSONAL_DETAILS = "PERSONAL_DETAILS",
  ADDRESS_DETAILS = "ADDRESS_DETAILS",
  PAYMENT_METHOD = "PAYMENT_METHOD"
}