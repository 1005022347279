import { TaxJarConfig, AutoBillsbyTaxConfig, ManualTaxConfig, TaxRate } from "../utils/grpc/generated/Billsby.Protos/billing/private/company/taxation/taxation_pb"

export interface ITaxes {
  countries: Array<ICountryTax>
}

export interface ITaxJarProductCategory {
  name: string,
  productTaxCode: string,
  description: string
}

export interface ITaxConfigurationData {
  countryCode: string,
  taxProductCategoryCode: string,
  apiToken: string,
  isAutoFileEnabled: boolean,
  taxLabel: string,
  taxRegNumber: string,
  isCollectTaxRegNumberEnabled: boolean,
  taxRegNumberRequirementType: string
}

export interface ICountryTax {
  countryName: string,
  countryCode: string,
  taxConfig?: TaxJarConfig.AsObject | AutoBillsbyTaxConfig.AsObject | ManualTaxConfig.AsObject
  taxServiceType?: string
}


export interface ITaxCountryConfig {
  countryCode: string,
  companyId: number,
  taxServiceType: string,
  taxationConfigId: number,
  taxConfigDetails?: TaxJarConfig.AsObject
}


export interface IOtherCountry {
  name: string,
  iso3Code: string,
  iso2Code: string,
  defaultCurrencyCode: string
}

export interface IOverrideStateRate {
  stateName: string,
  stateCode: string,
  taxRegNumber: string,
  taxRates: Array<TaxRate>,
  isOverride: boolean
}

export enum TaxCollection {
  DO_NOT_COLLECT = "DoNotCollect",
  COMPULSORY = "Compulsory",
  OPTIONAL = "Optional"
}

export enum ComplexTaxCountry {
  Australia = "AUS",
  Canada = "CAN",
  EuropeanUnion = "EUE",
  India = "IND",
  NewZealand = "NZL",
  UnitedStates = "USA"
}

export enum IndiaGeneralRate {
  CGST = "CGST",
  SGST_UTGST = "SGST/UTGST",
  Cess = "Cess"
}