import React, { useState, useLayoutEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import NavigationBar from "../../components/ui/navigation-bar/NavigationBar";
import PageWrapper from "../../components/ui/layout/PageWrapper";
import Text from "../../components/ui/text/Text";
import Button from "../../components/ui/button/Button";
import { Link } from "react-router-dom";
import Modal from "../../components/modal/Modal";
import "./InvoiceDetails.scss";
import { AppState } from "../..";
import counterpart from "counterpart";
import Refund from "../../components/refund/Refund";
import UpdateInvoiceStatus from "../../components/update-invoice-status/UpdateInvoiceStatus";
import LoadingModal from "../../components/modal/loading-modal/LoadingModal";
import { setInvoicePaymentLoading, putInvoicePayment, fetchInvoiceDetails, resetInvoiceDetails } from "../../actions/invoiceDetailsActions";
import { InvoiceRefundType, InvoiceStatus } from "../../models/Invoices";
import InvoicePrint from "../../components/invoice-print/InvoicePrint";
import SendInvoice from "../../components/send-invoice/SendInvoice";
import NoticePanel from "../../components/ui/notice-panel/NoticePanel";
import { downloadPdf, getInvoiceRefundError } from "../../utils/commonUtils";
import ProgressIndicator from "../../components/ui/progress-indicator/ProgressIndicator";
import { CustomerDataStatus } from "../../models/Customer";
import history from "../../utils/history";
import { Permission } from "../../models/Auth";
import useCheckFeaturePermission from "../../utils/hooks/useCheckFeaturePermission";
import ErrorNotification from "../../components/ui/error-notification/ErrorNotification";

interface IMatchParams {
  customerUniqueId: string,
  invoiceNumber: string
}

const InvoiceDetails: React.FC = () => {
  const dispatch = useDispatch();
  const { auth, companyBrandingReducer, invoiceDetailsReducer } = useSelector((state: AppState) => ({
    auth: state.auth, companyBrandingReducer: state.companyBrandingReducer, invoiceDetailsReducer: state.invoiceDetailsReducer
  }))

  const [isLoadingData, setIsLoadingData] = useState(false);
  const [showSuccessNotice, setShowSuccessPanel] = useState(false);
  const [showRefundModal, setShowRefundModal] = useState(false);
  const [showUpdateStatusModal, setShowUpdateStatusModal] = useState(false);
  const [showSendInvoiceModal, setShowSendInvoiceModal] = useState(false);
  const [isCreatingPdf, setIsCreatingPdf] = useState(false);
  const isInvoiceReattemptPermitted = useCheckFeaturePermission(Permission.InvoiceReattempt);
  const isInvoiceRefundPermitted = useCheckFeaturePermission(Permission.InvoiceRefund);
  const isInvoiceUpdateStatusPermitted = useCheckFeaturePermission(Permission.InvoiceUpdateStatus);
  const isInvoiceSendPermitted = useCheckFeaturePermission(Permission.InvoiceSend);
  const isInvoiceDownloadPermitted = useCheckFeaturePermission(Permission.InvoiceDownloadPdf);

  const { currentCompanyDomain } = auth;
  const { paymentLoading, reattemptErrorMessage, invoiceDetails } = invoiceDetailsReducer;
  const { customerFirstName, refundType, customerLastName, amount, status, invoiceNumber, isReattemptable, customerStatus } = invoiceDetails;
  const { logoUrl, logoBackgroundColor } = companyBrandingReducer;
  const customerName = `${customerFirstName} ${customerLastName}`;

  const params = useParams<IMatchParams>();

  const isDeletedCustomer = useMemo(() => customerStatus === CustomerDataStatus.GDPR_DELETED || customerStatus === CustomerDataStatus.DELETED, [customerStatus]);
  const isUpdatable = useMemo(() => status === InvoiceStatus.UNPAID || status === InvoiceStatus.FAILED, [status]);

  const fetchData = async () => {
    setIsLoadingData(true);
    try {
      await dispatch(fetchInvoiceDetails(params.invoiceNumber));
    } catch (err) {
      console.log(err);
    } finally {
      setIsLoadingData(false);
    }
  };

  const reattemptPayment = async () => {
    await dispatch(setInvoicePaymentLoading(true));
    try {
      await dispatch(putInvoicePayment(currentCompanyDomain, params.invoiceNumber));
      fetchData();
    } catch {
    } finally {
      await dispatch(setInvoicePaymentLoading(false));
    }
  };

  const handleUpdateInvoiceDetails = async () => {
    setShowRefundModal(false);
    setShowUpdateStatusModal(false);
    setShowRefundModal(false);
    await dispatch(fetchInvoiceDetails(params.invoiceNumber));
  };

  const download = () => {
    var element = document.querySelector(".receipt-details") as HTMLElement;
    setIsCreatingPdf(true);
    downloadPdf(element, invoiceNumber)
      .then(() => setIsCreatingPdf(false));
  };

  useLayoutEffect(() => {
    fetchData();
    return function cleanup() {
      dispatch(resetInvoiceDetails());
    };
  }, []);

  if (isLoadingData) {
    return <ProgressIndicator color="blue" coverage="full-content"></ProgressIndicator>
  }


  return (
    <div className="invoice-details">
      <NavigationBar
        previousPageTitle="INVOICE_DETAILS_BACK"
        shouldTranslatePageTitle={false}
        previousPageCb={() => history.goBack()}
        pageTitle={
          <span>
            {counterpart("INVOICE_DETAILS_FOR")}
            {
              isDeletedCustomer ? <Text content={customerName} className="invoice-details__customer-link deleted" component="span" shouldTranslate={false} noMargin /> :
                <Link to={`/customers/${params.customerUniqueId}`}>
                  <Text content={customerName} className="invoice-details__customer-link" component="span" shouldTranslate={false} noMargin />
                </Link>
            }
          </span>
        }
      />
      <div className="invoice-details__action-bar">
        <div className="invoice-details__action-bar__wrapper">
          <div>
            {status !== InvoiceStatus.PENDING && (
              <>
                {isReattemptable && status !== InvoiceStatus.CLEARING ? (
                  <Button id="reattempt-invoice"
                    disabled={!isInvoiceReattemptPermitted}
                    tooltipMessage={!isInvoiceReattemptPermitted ? "NO_ACCESS_MSG" : undefined}
                    icon="fas fa-sync" title="INVOICE_DETAILS_REATTEMPT" buttonType="error" onClick={() => reattemptPayment()} />
                ) : (
                  <Button
                    tooltipMessage={!isInvoiceRefundPermitted ? "NO_ACCESS_MSG" : getInvoiceRefundError(refundType)}
                    id="refund-invoice"
                    disabled={refundType !== InvoiceRefundType.AVAILABLE || !isInvoiceRefundPermitted}
                    icon="fas fa-cash-register"
                    title="INVOICE_DETAILS_REFUND"
                    buttonType="add"
                    onClick={() => setShowRefundModal(true)}
                  />
                )}
                {isUpdatable && <Button
                  disabled={!isInvoiceUpdateStatusPermitted}
                  tooltipMessage={!isInvoiceUpdateStatusPermitted ? "NO_ACCESS_MSG" : undefined}
                  id="update-invoice-status" icon="fas fa-edit" title="INVOICE_UPDATE_STATUS" buttonType="add" onClick={() => setShowUpdateStatusModal(true)} />}
              </>
            )}
          </div>
          <div>
            <Button
              id="send-invoice"
              icon="fab fa-telegram-plane"
              onClick={() => setShowSendInvoiceModal(!showSendInvoiceModal)}
              className="invoice-details__send-button"
              title="INVOICE_DETAILS_SEND_INVOICE"
              buttonType="add"
              disabled={isDeletedCustomer || !isInvoiceSendPermitted}
              tooltipMessage={!isInvoiceSendPermitted ? "NO_ACCESS_MSG" : undefined}
            />
            <Button 
              id="download-pdf" 
              icon="fas fa-file-pdf" 
              title="INVOICE_DETAILS_DOWNLOAD_PDF" 
              buttonType="add" 
              onClick={download}
              disabled={!isInvoiceDownloadPermitted}
              tooltipMessage={!isInvoiceDownloadPermitted ? "NO_ACCESS_MSG" : undefined}
            />
          </div>
        </div>
      </div>
      {showSuccessNotice && <NoticePanel type="success" icon="fas fa-check-circle" className="invoice-details__notice-panel" content="SEND_INVOICE_SUCCESS" />}

      <PageWrapper>
        <InvoicePrint data={invoiceDetails} />
      </PageWrapper>
      <Modal
        id="invoice-refund-modal"
        title="REFUND_INVOICE_WELCOME"
        isOpen={showRefundModal}
        onRequestClose={() => setShowRefundModal(false)}
        children={<Refund handleRefundCallback={handleUpdateInvoiceDetails} refundData={{ customerUniqueId: params.customerUniqueId, invoiceNumber: params.invoiceNumber }} />}
      />
      <Modal
        id="update-status-modal"
        title="INVOICE_UPDATE_STATUS"
        isOpen={showUpdateStatusModal}
        onRequestClose={() => setShowUpdateStatusModal(false)}
        children={<UpdateInvoiceStatus handleUpdateStatusCallback={handleUpdateInvoiceDetails} />}
      />
      <Modal
        id="send-invoice-modal"
        title="SEND_INVOICE_TITLE"
        isOpen={showSendInvoiceModal}
        onRequestClose={() => setShowSendInvoiceModal(false)}
        children={
          <SendInvoice
            customerUniqueId={params.customerUniqueId}
            handleSendInvoiceCallback={() => {
              setShowSuccessPanel(true);
              setShowSendInvoiceModal(false);
            }}
          />
        }
      />
      <LoadingModal
        title="INVOICE_DETAILS_REATTEMPT_TITLE"
        logo={logoUrl}
        logoBackgroundColor={logoBackgroundColor}
        titleTranslateWith={{ amount }}
        subTitle="INVOICE_DETAILS_REATTEMPT_SUB_TITLE"
        isOpen={paymentLoading}
      />
      <ErrorNotification title="GENERIC_ERROR" subtitle={reattemptErrorMessage} shouldTranslateSubtitle={false} showAlert={!!reattemptErrorMessage}  />
      <LoadingModal isOpen={isCreatingPdf} title={counterpart("DOWNLOADING_INVOICE")} subTitle={""} shouldTranslateTitle={false} shouldTranslateSubTitle={false} />

    </div>
  );
};

export default InvoiceDetails;
