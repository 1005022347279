import { BillsbyAction } from "../models/BillsbyAction";
import { AppState } from "..";
import { CustomFieldScreen, CustomFieldType, ISelectedCustomField, ICustomField } from "../models/CustomFields";
import { SET_CUSTOMFIELDS_CURRENTSCREEN, SET_SEARCHED_STANDARD_FIELD, SET_STANDARD_FIELD, SET_IS_CREATING_CUSTOM_FIELD, SET_CREATE_CUSTOM_FIELD, RESET_CREATE_CUSTOM_FIELD_STATE, ADD_CUSTOM_FIELD, SET_SEARCHED_CUSTOM_FIELD, SELECT_CUSTOM_FIELD, RESET_CUSTOM_FIELDS_STATE, FETCH_CUSTOM_FIELDS_SUCCESS, CREATE_CUSTOM_FIELD_FAILURE, CREATE_CUSTOM_FIELD_REQUEST, EDIT_CUSTOM_FIELD, UPDATE_CUSTOM_FIELD, SET_IS_QUICK_EDIT } from "../actions/customFieldsActions";
import counterpart from "counterpart";

export interface ICustomFieldsReducer {
  currentScreen: CustomFieldScreen,
  readonly customFieldsOptions: Array<{ label: string, value: string }>,
  searchedStandardField: string,
  searchedCustomField: string,
  newCustomFields: Array<ISelectedCustomField>,
  newCustomFieldsTemp: Array<ISelectedCustomField>
  filteredCustomFields: Array<ISelectedCustomField>,
  isCreatingCustomField: boolean,
  customFieldId: number | null,
  standardFields: {
    isAdditionalEmailRequired: boolean,
    isPhoneNumberRequired: boolean,
    isMarketingConsentRequired: boolean,
  },
  createCustomField: {
    fieldType: { label: string, value: CustomFieldType },
    fieldLabel: string,
    supportText: string,
    options: Array<any>,
    optionsSource: Array<any>,
    optionValue: string,
    isCompulsory: boolean,
    helpTextTitle: string,
    helpTextContent: Array<string>,
    fieldLabelPlaceholder: string,
    supportTextPlaceholder: string,
    hasError: boolean
  },
  isQuickEdit: boolean
}

export const initState = (): ICustomFieldsReducer => {
  const FIELD_TYPE_OPTIONS = [
    { label: counterpart("CREATE_CUSTOM_FIELDS_SINGLE_LINE_TEXT"), value: CustomFieldType.SingleLineTextField },
    { label: counterpart("CREATE_CUSTOM_FIELDS_MULTI_LINE_TEXT"), value: CustomFieldType.MultiLineTextField },
    { label: counterpart("CREATE_CUSTOM_FIELDS_CHECKBOX"), value: CustomFieldType.CheckboxField },
    { label: counterpart("CREATE_CUSTOM_FIELDS_NUMBERS"), value: CustomFieldType.NumbersField },
    { label: counterpart("CREATE_CUSTOM_FIELDS_DATEPICKER"), value: CustomFieldType.DatePickerField },
    { label: counterpart("CREATE_CUSTOM_FIELDS_DROPDOWN"), value: CustomFieldType.DropdownField }
  ];

  return {
    currentScreen: CustomFieldScreen.STANDARD_FIELDS,
    customFieldsOptions: FIELD_TYPE_OPTIONS,
    searchedStandardField: "",
    searchedCustomField: "",
    newCustomFields: [],
    newCustomFieldsTemp: [],
    filteredCustomFields: [],
    isCreatingCustomField: false,
    customFieldId: null,
    standardFields: {
      isAdditionalEmailRequired: false,
      isPhoneNumberRequired: false,
      isMarketingConsentRequired: false,
    },
    createCustomField: {
      fieldType: { label: FIELD_TYPE_OPTIONS[0].label, value: FIELD_TYPE_OPTIONS[0].value },
      fieldLabel: "",
      supportText: "",
      options: [],
      optionsSource: [],
      optionValue: "",
      isCompulsory: false,
      helpTextTitle: "HELP_EMPTY_TITLE",
      helpTextContent: ["HELP_EMPTY_1", "HELP_EMPTY_2"],
      fieldLabelPlaceholder: "CREATE_CUSTOM_FIELDS_LABEL_PLACEHOLDER",
      supportTextPlaceholder: "CREATE_CUSTOM_FIELDS_SUPPORT_TEXT_PLACEHOLDER",
      hasError: false
    },
    isQuickEdit: false
  }
}

export default function customFieldsReducer(state: ICustomFieldsReducer = initState(), action: BillsbyAction, store: AppState) {
  switch (action.type) {
    case SET_CUSTOMFIELDS_CURRENTSCREEN:
      return { ...state, currentScreen: action.payload }
    case SET_SEARCHED_STANDARD_FIELD:
      return { ...state, searchedStandardField: action.payload }
    case SET_SEARCHED_CUSTOM_FIELD:
      return { ...state, searchedCustomField: action.payload, filteredCustomFields: state.newCustomFields.filter(customField => customField.label.toUpperCase().indexOf(action.payload.toUpperCase()) >= 0) }
    case SET_STANDARD_FIELD:
      return { ...state, standardFields: { ...state.standardFields, [action.fieldName]: action.payload } }
    case SET_IS_CREATING_CUSTOM_FIELD:
      return { ...state, isCreatingCustomField: action.payload, customFieldId: null }
    case SET_CREATE_CUSTOM_FIELD:
      return { ...state, createCustomField: { ...state.createCustomField, ...action.payload } }
    case ADD_CUSTOM_FIELD:
      return { ...state, newCustomFields: [...state.newCustomFields, { ...action.payload }], filteredCustomFields: [...state.newCustomFields, { ...action.payload }], newCustomFieldsTemp: [...state.newCustomFieldsTemp, { ...action.payload }] }
    case UPDATE_CUSTOM_FIELD: {
      const payload = action.payload;

      let arr = [...state.newCustomFields];
      let arrFiltered = [...state.filteredCustomFields];
      let arrTemp = [...state.newCustomFieldsTemp];

      const updateArray = (array: ISelectedCustomField[], payload: any) => {
        let field = array.find(customField => customField.customFieldId === payload.customFieldId);
        if (field) {
          field.description = payload.description;
          field.label = payload.label;
          field.options = payload.options;
          field.compulsory = payload.compulsory;
          let idx = array.indexOf(field);
          array.splice(idx, 1, field);
        }
        return array;
      }

      arr = updateArray(arr, payload);
      arrFiltered = updateArray(arrFiltered, payload);
      arrTemp = updateArray(arrTemp, payload);

      return { ...state, newCustomFields: arr, filteredCustomFields: arrFiltered, newCustomFieldsTemp: arrTemp }
    }

    case SELECT_CUSTOM_FIELD: {
      const field = state.newCustomFields.find(customField => customField.customFieldId === action.customFieldId);
      if (field) {
        field.isSelected = action.isSelected;
        const indexNewCustomFields = state.newCustomFields.findIndex(customField => customField.customFieldId === field.customFieldId);
        const indexFilteredCustomFields = state.filteredCustomFields.findIndex(customField => customField.customFieldId === field.customFieldId);
        const _newCustomFields = [...state.newCustomFields];
        const _filteredCustomFields = [...state.filteredCustomFields];
        _newCustomFields.splice(indexNewCustomFields, 1, field);
        _filteredCustomFields.splice(indexFilteredCustomFields, 1, field);

        const _newCustomFieldsTemp = state.newCustomFieldsTemp.map(customField => {
          if (customField.customFieldId === field.customFieldId) {
            return { ...field };
          }
          return customField;
        })

        if (_newCustomFieldsTemp.every(customField => customField.customFieldId !== field.customFieldId)) {
          _newCustomFieldsTemp.push({ ...field });
        }

        return { ...state, newCustomFields: _newCustomFields, filteredCustomFields: _filteredCustomFields }
      }
      return state;
    }
    case FETCH_CUSTOM_FIELDS_SUCCESS: {
      const existingProductCustomFields = [...store.products.customFields];
      const customFields = action.response as Array<ICustomField>;
      const mergeCustomFields: Array<ISelectedCustomField> = customFields.map(field => {
        const existingField = existingProductCustomFields.find(customField => customField.customFieldId === field.customFieldId);
        if (existingField) {
          return { ...existingField, isSelected: true };
        }
        return { ...field, isSelected: false };
      });

      return { ...state, newCustomFields: mergeCustomFields, filteredCustomFields: mergeCustomFields, newCustomFieldsTemp: JSON.parse(JSON.stringify(mergeCustomFields)) }
    }

    case CREATE_CUSTOM_FIELD_REQUEST:
      return { ...state, createCustomField: { ...state.createCustomField, hasError: false } }
    case CREATE_CUSTOM_FIELD_FAILURE:
      return { ...state, createCustomField: { ...state.createCustomField, hasError: true } }

    case RESET_CREATE_CUSTOM_FIELD_STATE:
      return { ...state, createCustomField: { ...initState().createCustomField } }
    case RESET_CUSTOM_FIELDS_STATE:
      return initState();

    case EDIT_CUSTOM_FIELD: {
      let optionsT: Array<any> = [];
      if (action.fieldOptions as string) {
        const optionsStr: string = action.fieldOptions;
        optionsT = optionsStr.split(",");
      }

      const typeOfField = CustomFieldType[action.fieldType as keyof typeof CustomFieldType];

      return {
        ...state, isCreatingCustomField: true, customFieldId: action.customFieldId,
        createCustomField: {
          ...state.createCustomField,
          fieldType: state.customFieldsOptions.find((val) => val.value === typeOfField),
          fieldLabel: action.label,
          supportText: action.supportText,
          options: optionsT,
          optionsSource: [...optionsT],
          isCompulsory: action.compulsory,
        }
      }
    }
    case SET_IS_QUICK_EDIT:
      return { ...state, isQuickEdit: action.payload }

    default:
      return state;
  }
}