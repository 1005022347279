import { LogExtended } from "../utils/grpc/generated/Billsby.Protos/billing/private/paymentlogs/payment_logs_pb";

export enum TransactionLogStatus {
    SUCCESS = "Success",
    FAILED = "Failed",
    DECLINED = "Declined"
}

export enum CustomerIdStatus {
    CUSTOMER_ID = "Customer ID",
    NO_CUSTOMER_ID = "No customer ID"
}

export interface ITransactionLog {
    reference: string;
    fullName: string;
    createdOn: Date;
    status: TransactionLogStatus;
    customerId: string
}

export interface ITransactionLogsPaginated {
    currentPage: number,
    pageCount: number,
    pageSize: number,
    rowCount: number,
    results?: Array<LogExtended>
}

export enum TransactionPaymentStatus {
    Success = "Success",
    Declined = "Declined", // When a charge failed because of an issue in customer's side (card expired, fraud, etc...).
    Failed = "Failed", // When a charge failed because of an issue in our side or Spreedly side. Service down, wrong API Key, etc..
    Pending = "Pending", // When a charge requires 3DSecure validation (SCA)
    PendingAch = "PendingAch"  // When ACH payment intent was created but we need to wait until the charge is validated
}

