import API from "../utils/API";
import { makeActionCreator } from "../utils/reduxUtils";

export const FETCH_INVOICE_DETAILS_REQUEST = "FETCH_INVOICE_DETAILS_REQUEST";
export const FETCH_INVOICE_DETAILS_SUCCESS = "FETCH_INVOICE_DETAILS_SUCCESS";
export const FETCH_INVOICE_DETAILS_FAILURE = "FETCH_INVOICE_DETAILS_FAILURE";

export const PUT_INVOICE_PAYMENT_REQUEST = "PUT_INVOICE_PAYMENT_REQUEST";
export const PUT_INVOICE_PAYMENT_SUCCESS = "PUT_INVOICE_PAYMENT_SUCCESS";
export const PUT_INVOICE_PAYMENT_FAILURE = "PUT_INVOICE_PAYMENT_FAILURE";

export const RESET_INVOICE_DETAILS = "RESET_INVOICE_DETAILS";
export const resetInvoiceDetails = makeActionCreator(RESET_INVOICE_DETAILS, "payload");

export const SET_INVOICE_PAYMENT_LOADING = "SET_INVOICE_PAYMENT_LOADING";
export const setInvoicePaymentLoading = makeActionCreator(SET_INVOICE_PAYMENT_LOADING, "payload");

export const putInvoicePayment = function (companyDomain: string, invoiceNumber: string) {
  return {
    types: [PUT_INVOICE_PAYMENT_REQUEST, PUT_INVOICE_PAYMENT_SUCCESS, PUT_INVOICE_PAYMENT_FAILURE],
    callAPI: () => API.putInvoicePayment(companyDomain, invoiceNumber)
  }
}

export const fetchInvoiceDetails = function (invoiceNumber: string) {
  return {
    types: [FETCH_INVOICE_DETAILS_REQUEST, FETCH_INVOICE_DETAILS_SUCCESS, FETCH_INVOICE_DETAILS_FAILURE],
    callAPI: () => API.fetchInvoiceStandalone(invoiceNumber)
  }
}