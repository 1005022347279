import React, { FormEvent, useEffect, useState } from "react";
import Panel from "../ui/panel/Panel";
import Text from "../ui/text/Text";
import { Row, Col } from "react-grid-system";
import FormLabel from "../ui/form-label/FormLabel";
import Input from "../ui/input/Input";
import Button from "../ui/button/Button";
import FormGroup from "../ui/form-group/FormGroup";
import counterpart from "counterpart";
import API from "../../utils/API";
import "./InviteTeammate.scss";
import Table from "../ui/table/Table";
import Dropdown from "../ui/dropdown/Dropdown";
import { ITeammate } from "../../models/CompanyTeammates";
import { Permission, UserRole } from "../../models/Auth";
import { useSelector } from "react-redux";
import { AppState } from "../..";
import NoticePanel from "../ui/notice-panel/NoticePanel";
import { BILLSBY_COMPANY_ID } from "../../utils/constants";
import { addSpaceBeforeCapitalLetter } from "../../utils/commonUtils";
import useCheckFeaturePermission from "../../utils/hooks/useCheckFeaturePermission";
import { GettingStartedType } from "../../models/GettingStarted";

interface IInviteTeammate {
  handleGoToTeammatesPage: Function,
  selectedTeammate?: ITeammate
}

const InviteTeammate: React.FC<IInviteTeammate> = ({ handleGoToTeammatesPage, selectedTeammate }) => {

  const { currentCompanyDomain, currentCompanyId, dashboardSettings } = useSelector((state: AppState) => state.auth);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [roles, setRoles] = useState<Array<({ label: string, value: UserRole })>>([]);
  const [currentRole, setCurrentRole] = useState({ label: UserRole[UserRole.Admin], value: UserRole.Admin });
  const [hasGenericError, setHasGenericError] = useState(false);
  const [hasSingleAdminError, setHasSingleAdminError] = useState(false);
  const [isSuccessful, setIsSuccessful] = useState(false);
  const [isLoadingData, setIsLoadingData] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [rolesDescription, setRolesDescription] = useState<Array<{ label: string, content: string }>>([])
  const isTeammateAssignRolePermitted = useCheckFeaturePermission(Permission.TeammateAssignRole);
  const isLive = dashboardSettings && (dashboardSettings.status === GettingStartedType.OnTrial || dashboardSettings.status === GettingStartedType.Live);

  const isNewTeamMate = !selectedTeammate;

  useEffect(() => {
    const billsbyRoles = [
      { label: addSpaceBeforeCapitalLetter(UserRole[UserRole.BillsbyAdmin]), value: UserRole.BillsbyAdmin },
      { label: addSpaceBeforeCapitalLetter(UserRole[UserRole.BillsbyAnalyst]), value: UserRole.BillsbyAnalyst },
      { label: addSpaceBeforeCapitalLetter(UserRole[UserRole.BillsbyDeveloper]), value: UserRole.BillsbyDeveloper },
      { label: addSpaceBeforeCapitalLetter(UserRole[UserRole.BillsbyOperationsManager]), value: UserRole.BillsbyOperationsManager },
      { label: addSpaceBeforeCapitalLetter(UserRole[UserRole.BillsbySeniorOperationsManager]), value: UserRole.BillsbySeniorOperationsManager },
    ]
    const normalCompanyRoles = [
      { label: UserRole[UserRole.Admin], value: UserRole.Admin },
      { label: UserRole[UserRole.Editor], value: UserRole.Editor },
      { label: UserRole[UserRole.Viewer], value: UserRole.Viewer },
      { label: UserRole[UserRole.Developer], value: UserRole.Developer },
      { label: UserRole[UserRole.Agent], value: UserRole.Agent },
    ]

    if (currentCompanyId === BILLSBY_COMPANY_ID) {
      setRoles(billsbyRoles);
      setCurrentRole(selectedTeammate?.role ? { label: addSpaceBeforeCapitalLetter(UserRole[selectedTeammate.role]), value: selectedTeammate.role } : billsbyRoles[0])
      setRolesDescription([
        { label: addSpaceBeforeCapitalLetter(UserRole[UserRole.BillsbyOperationsManager]), content: "TEAMMATE_INVITE_BILLSBY_OP_MANAGER_PERMISSIONS" },
        { label: addSpaceBeforeCapitalLetter(UserRole[UserRole.BillsbySeniorOperationsManager]), content: "TEAMMATE_INVITE_BILLSBY_SENIOR_OP_MANAGER_PERMISSIONS" },
        { label: addSpaceBeforeCapitalLetter(UserRole[UserRole.BillsbyAnalyst]), content: "TEAMMATE_INVITE_BILLSBY_ANALYST_PERMISSIONS" },
        { label: addSpaceBeforeCapitalLetter(UserRole[UserRole.BillsbyDeveloper]), content: "TEAMMATE_INVITE_BILLSBY_DEVELOPER_PERMISSIONS" },
        { label: addSpaceBeforeCapitalLetter(UserRole[UserRole.BillsbyAdmin]), content: "TEAMMATE_INVITE_BILLSBY_ADMIN_PERMISSIONS" }
      ])
      return;
    }

    setRoles(normalCompanyRoles);
    setCurrentRole(selectedTeammate?.role ? { label: UserRole[selectedTeammate.role], value: selectedTeammate.role } : normalCompanyRoles[0])
    setRolesDescription([
      { label: UserRole[UserRole.Admin], content: "TEAMMATE_INVITE_ADMIN_PERMISSIONS" },
      { label: UserRole[UserRole.Developer], content: "TEAMMATE_INVITE_DEVELOPER_PERMISSIONS" },
      { label: UserRole[UserRole.Editor], content: "TEAMMATE_INVITE_EDITOR_PERMISSIONS" },
      { label: UserRole[UserRole.Viewer], content: "TEAMMATE_INVITE_VIEWER_PERMISSIONS" },
      { label: UserRole[UserRole.Agent], content: "TEAMMATE_INVITE_AGENT_PERMISSIONS" }
    ])
  }, [])

  const resetStates = () => {
    setFirstName("");
    setLastName("");
    setEmail("");
    setHasGenericError(false);
    setHasSingleAdminError(false);
    setIsSuccessful(false);
    setIsLoadingData(false);
    setIsLoading(false);
  }

  const onSubmit = async (evt: FormEvent) => {
    evt.preventDefault();
    evt.stopPropagation();
    setIsLoading(true);

    try {
      if (selectedTeammate) {
        await API.editTeammate(currentCompanyDomain, selectedTeammate.userId, currentRole.value);
      }
      else {
        await API.sendInvitationToUser(currentCompanyDomain, { email, firstName, lastName, role: currentRole.value });
      }

      setIsSuccessful(true);
    }
    catch (err) {
      if ((err as any)?.list?.[0]?.code === "Company_Edit_Teammate_Role_Cannot_Be_Removed") {
        return setHasSingleAdminError(true)
      }
      setHasGenericError(true)
    }
    finally {
      setIsLoading(false)
    }
  }

  const onAddAnotherTeammate = (evt: any) => {
    evt.preventDefault();
    resetStates()
  };

  const goToTeammatesPage = (evt: any) => {
    evt.preventDefault();
    handleGoToTeammatesPage()
  }

  const renderNameEmailForm = () => {
    return (
      <>
        <FormGroup>
          <Row align="center">
            <Col md={2}>
              <FormLabel target="first-name" content="TEAMMATES_FULLNAME" noMargin />
            </Col>
            <Col md={5}>
              <Input
                required
                id="first-name"
                placeholder={counterpart("TEAMMATES_FIRST_NAME")}
                value={firstName}
                onChange={e => setFirstName(e.target.value)}
              />
            </Col>
            <Col md={5}>
              <Input
                required
                id="last-name"
                placeholder={counterpart("TEAMMATES_LAST_NAME")}
                value={lastName}
                onChange={e => setLastName(e.target.value)}
              />
            </Col>
          </Row>
        </FormGroup>

        <FormGroup>
          <Row align="center">
            <Col md={2}>
              <FormLabel target="email" content="TEAMMATES_EMAIL" noMargin />
            </Col>
            <Col md={10}>
              <Input
                required
                id="email"
                isError={hasGenericError}
                errorMsg={counterpart("TEAMMATES_EMAIL_ERROR")}
                placeholder={counterpart("TEAMMATES_EMAIL_PLACEHOLDER")}
                value={email}
                onChange={e => {
                  setEmail(e.target.value);
                  setHasGenericError(false)
                }} />
            </Col>
          </Row>
        </FormGroup>
      </>
    )
  }

  const renderSuccessOption = () => {
    if (!isSuccessful) {
      return null;
    }

    return (
      <div className="invite-teammate__success-option">
        <Text component="span" content={"TEAMMATES_OPTION_TEXT1"} noMargin />{" "}
        <a href="" id="add-teammate" onClick={onAddAnotherTeammate}>
          <Text component="span" content={"TEAMMATES_OPTION_LINK1"} noMargin />
        </a>{" "}
        <Text component="span" content={"TEAMMATES_OPTION_TEXT2"} noMargin />{" "}
        <a href="" id="go-back" onClick={goToTeammatesPage}>
          <Text component="span" content={"TEAMMATES_OPTION_LINK2"} noMargin />
        </a>
      </div>
    )
  }

  const getButtonTitle = () => {
    if (isSuccessful && isNewTeamMate) {
      return "TEAMMATE_SEND_INVITE_EMAIL_SUCCESS"
    }
    if (isSuccessful && !isNewTeamMate) {
      return "TEAMMATE_UPDATE_SUCCESS"
    }
    if (isNewTeamMate) {
      return "TEAMMATE_SEND_INVITE_EMAIL"
    }
    return "TEAMMATE_UPDATE_TEAMMATE_EMAIL"
  }

  if (isLoadingData) {
    return null;
  }

  return (
    <form className="invite-teammate" onSubmit={onSubmit}>
      <NoticePanel
        type="error"
        content="TEAMMATE_SINGLE_ADMIN_ERROR"
        translateWith={{ adminType: (currentCompanyId === BILLSBY_COMPANY_ID) ? UserRole[UserRole.BillsbyAdmin] : UserRole[UserRole.Admin] }}
        isModal
        isVisible={hasSingleAdminError}
      />
      <NoticePanel
        type="warning"
        content="TEAMMATE_USER_PERMISSIONS"
        isModal
        isVisible={!isLive}
      />
      
      <Panel className="teammates__title-panel" title="TEAMMATES_PANEL1_TITLE">
        <Text className="teammates__title-text" content="TEAMMATES_PANEL1_CONTENT" noMargin />
      </Panel>

      <FormGroup className="navigation-bar__previouspage" onClick={() => handleGoToTeammatesPage()}>
        <i className="far fa-chevron-left" />
        <Text content="TEAMMATE_INVITE_NAVIGATION_BACK" noMargin />
      </FormGroup>

      {isTeammateAssignRolePermitted && <Panel>
        <Table className="invite-teammate__table">
          <thead>
            <tr>
              <Text content="TEAMMATE_INVITE_ROLE" component="th" noMargin />
              <Text content="TEAMMATE_INVITE_PERMISSIONS" component="th" noMargin />
            </tr>
          </thead>
          <tbody>
            {
              rolesDescription.map(({ label, content }) => (
                <tr key={label}>
                  <Text content={label} component="td" noMargin shouldTranslate={false} />
                  <Text content={content} component="td" noMargin />
                </tr>
              ))
            }
          </tbody>
        </Table>
      </Panel>}
      <Panel>

        {isNewTeamMate && renderNameEmailForm()}

        <Row align="center">
          <Col md={2}>
            <FormLabel target="role" content="TEAMMATES_LIST_TABLE_ROLE" noMargin />
          </Col>
          <Col md={10}>
            <Dropdown
              id="role"
              onChange={(role: any) => setCurrentRole(role)}
              isDisabled={!isTeammateAssignRolePermitted}
              value={currentRole}
              options={roles}
            />
          </Col>
        </Row>

      </Panel>

      <Button
        id="send-invite-teammates"
        type="submit"
        icon={isSuccessful ? "far fa-check-circle" : ""}
        translateWith={{ firstName }}
        buttonType={isSuccessful ? "success" : "general"}
        isLoading={isLoading && !hasGenericError}
        title={getButtonTitle()}
        isFullWidth
      />

      {renderSuccessOption()}
    </form>
  )
}

export default InviteTeammate;
