import { ConfigConstants } from "./config";
import { IParsedToken } from "../models/Auth"
import { storeRemove } from "./storeUtils";
import { isCustomerFacingPage } from "./commonUtils";

function createIntercomScript(isAuth?: boolean, id?: string, hash?: string) {
  const head: HTMLElement = document.createElement("script");
  const body: HTMLElement = document.createElement("script");

  head.setAttribute("id", "intercomHeadScript");
  head.textContent = `var APP_ID = '${ConfigConstants.intercomKey}';
    (function(){var w=window;var ic=w.Intercom;if(typeof ic==="function"){ic('reattach_activator');ic('update',w.intercomSettings);}
    else{var d=document;var i=function(){i.c(arguments);};i.q=[];i.c=function(args){i.q.push(args);};w.Intercom=i;var l=function(){var s=d.createElement('script');
    s.type='text/javascript';s.async=true;s.src='https://widget.intercom.io/widget/' + APP_ID;var x=d.getElementsByTagName('script')[0];x.parentNode.insertBefore(s,x);};
    if(w.attachEvent){w.attachEvent('onload',l);}else{w.addEventListener('load',l,false);}}})();`;

  body.setAttribute("id", "intercomBodyScript");

  if (isAuth) {
    body.textContent = `window.Intercom('boot', {
      app_id: '${ConfigConstants.intercomKey}',
      user_id: '${id}',
      user_hash: '${hash}'});`
  } else {
    body.textContent = `window.Intercom('boot', {
      app_id: '${ConfigConstants.intercomKey}'});`
  }

  return {
    head,
    body
  }
}

function createHotjarScript(id: string) {
  const comment: Comment = document.createComment(" Hotjar Tracking Code for https://*.billsby.com ");
  const script: HTMLElement = document.createElement("script");

  script.setAttribute("id", "hotJarScript");
  script.textContent = `(function(h,o,t,j,a,r){ h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)}; h._hjSettings={hjid:${id},hjsv:6}; a=o.getElementsByTagName('head')[0]; 
  r=o.createElement('script');r.async=1; r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv; a.appendChild(r);})(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=')`;

  return {
    comment,
    script
  }
}

const removeIntercomCookies = function () {
  // Get an array of cookies
  var arrSplit = document.cookie.split(";");

  for (var i = 0; i < arrSplit.length; i++) {
    var cookie = arrSplit[i].trim();
    var cookieName = cookie.split("=")[0];
    if (cookieName.indexOf("intercom") > -1) {
      storeRemove(cookieName);
    }
  }
}

export function injectTrackingScripts(token: IParsedToken | null) {
  let hotjar;
  let intercom;
  removeIntercomCookies();
  
  //don't load intercom in a customer facing page
  if(isCustomerFacingPage()) {
    return null;
  }

  if (token !== null) {
    //hotjar = createHotjarScript(ConfigConstants.hotjarLogged);
    intercom = createIntercomScript(true, token.nameid, token.Intercom);

    //document.head.appendChild(hotjar.comment);
    //document.head.appendChild(hotjar.script);

    document.head.appendChild(intercom.head);
    document.body.appendChild(intercom.body);

  } else {
    //hotjar = createHotjarScript(ConfigConstants.hotjarNotLogged);
    intercom = createIntercomScript();

    //document.head.appendChild(hotjar.comment);
    //document.head.appendChild(hotjar.script);

    document.head.appendChild(intercom.head);
    document.body.appendChild(intercom.body);
  }
}



export const appendFontPolyfillOldBrowser = () => {
  const link: HTMLElement = document.createElement("link");
  link.setAttribute("rel", "stylesheet");
  link.setAttribute("href", "/static/css/all.css");
  document.head.appendChild(link)
}