/**
 * @fileoverview gRPC-Web generated client stub for webhooks.private.webhooktests
 * @enhanceable
 * @public
 */

// GENERATED CODE -- DO NOT EDIT!


/* eslint-disable */
// @ts-nocheck


import * as grpcWeb from 'grpc-web';

import * as webhook_tests_pb from './webhook_tests_pb';


export class WebhookTestsServiceClient {
  client_: grpcWeb.AbstractClientBase;
  hostname_: string;
  credentials_: null | { [index: string]: string; };
  options_: null | { [index: string]: any; };

  constructor (hostname: string,
               credentials?: null | { [index: string]: string; },
               options?: null | { [index: string]: any; }) {
    if (!options) options = {};
    if (!credentials) credentials = {};
    options['format'] = 'text';

    this.client_ = new grpcWeb.GrpcWebClientBase(options);
    this.hostname_ = hostname;
    this.credentials_ = credentials;
    this.options_ = options;
  }

  methodDescriptorSendTestWebhook = new grpcWeb.MethodDescriptor(
    '/webhooks.private.webhooktests.WebhookTestsService/SendTestWebhook',
    grpcWeb.MethodType.UNARY,
    webhook_tests_pb.SendTestWebhookRequest,
    webhook_tests_pb.SendTestWebhookResponse,
    (request: webhook_tests_pb.SendTestWebhookRequest) => {
      return request.serializeBinary();
    },
    webhook_tests_pb.SendTestWebhookResponse.deserializeBinary
  );

  sendTestWebhook(
    request: webhook_tests_pb.SendTestWebhookRequest,
    metadata: grpcWeb.Metadata | null): Promise<webhook_tests_pb.SendTestWebhookResponse>;

  sendTestWebhook(
    request: webhook_tests_pb.SendTestWebhookRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: webhook_tests_pb.SendTestWebhookResponse) => void): grpcWeb.ClientReadableStream<webhook_tests_pb.SendTestWebhookResponse>;

  sendTestWebhook(
    request: webhook_tests_pb.SendTestWebhookRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: webhook_tests_pb.SendTestWebhookResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/webhooks.private.webhooktests.WebhookTestsService/SendTestWebhook',
        request,
        metadata || {},
        this.methodDescriptorSendTestWebhook,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/webhooks.private.webhooktests.WebhookTestsService/SendTestWebhook',
    request,
    metadata || {},
    this.methodDescriptorSendTestWebhook);
  }

}

