import React, { useState, useLayoutEffect } from "react";
import Button from "../../../ui/button/Button";
import AddTaxRates from "../../tax-components/AddTaxRates";
import CollectTaxRegistrationNumber from "../../tax-components/CollectTaxRegistrationNumber";
import ValidationOptions from "../../tax-components/ValidationOptions";
import TaxRegistrationNumber from "../../tax-components/TaxRegistrationNumber";
import { TaxCollection } from "../../../../models/Taxes";
import { TaxRate, ManualTaxConfig } from "../../../../utils/grpc/generated/Billsby.Protos/billing/private/company/taxation/taxation_pb";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../../../..";
import "./TaxConfigFlow.scss";
import { deleteTaxconfig, fetchTaxConfig, updateManualTaxConfig } from "../../../../actions/companyTaxRatesActions";

interface IAustraliaTaxConfigFlow {
  handleUpdateCallback?: Function;
}

const AustraliaTaxConfigFlow: React.FC<IAustraliaTaxConfigFlow> = ({ handleUpdateCallback }) => {
  const auth = useSelector((state: AppState) => state.auth);
  const companyTaxRates = useSelector((state: AppState) => state.companyTaxRatesReducer);

  const dispatch = useDispatch<Function>();

  const [isLoading, setIsLoading] = useState(false);
  const [isConfigured, setIsConfigured] = useState(false);

  //Add tax states
  const [taxRates, setTaxRates] = useState<Array<TaxRate>>([]);
  const [taxName, setTaxName] = useState("");
  const [taxRate, setTaxRate] = useState(0);

  //Tax registration number
  const [taxLabel, setTaxLabel] = useState("");
  const [taxRegNumber, setTaxRegNumber] = useState("");

  //Collect tax registration number states
  const [taxRegNumberRequirementType, setTaxRegNumberRequirementType] = useState<TaxCollection>(TaxCollection.DO_NOT_COLLECT);

  //Validation options states
  const [validateLocation, setValidateLocation] = useState(false);
  const [validateTax, setValidateTax] = useState(false);

  const { currentCompanyDomain } = auth;
  const { currentCountry } = companyTaxRates;


  useLayoutEffect(() => {
    if (taxRegNumberRequirementType === TaxCollection.DO_NOT_COLLECT) {
      setValidateLocation(false);
      setValidateTax(false);
    } 
  }, [currentCountry, taxRegNumberRequirementType]);


  useLayoutEffect(() => {
    //Preload data if configured

    if (currentCountry && currentCountry.taxConfig) {
      const taxConfig = currentCountry.taxConfig as ManualTaxConfig.AsObject;
      setIsConfigured(true);

      setTaxRates(
        taxConfig.taxRatesList.map((taxRate) => {
          const tr = new TaxRate();
          tr.setName(taxRate.name);
          tr.setRate(taxRate.rate);
          return tr;
        })
      );
      setTaxLabel(taxConfig.taxLabel);
      setTaxRegNumber(taxConfig.taxRegNumber);
      setTaxRegNumberRequirementType(taxConfig.taxRegNumberRequirementType as TaxCollection);
      setValidateLocation(taxConfig.locationValidation);
      setValidateTax(taxConfig.taxValidation);
    }
  }, [currentCountry]);

  if (!currentCountry) {
    return null;
  }


  const onAdd = () => {
    const newTaxRate = new TaxRate();
    newTaxRate.setName(taxName);
    newTaxRate.setRate(taxRate);
    newTaxRate.setCountryIso3(currentCountry.countryCode);

    setTaxRates([...taxRates, newTaxRate]);
    setTaxName("");
    setTaxRate(0);
  };

  const onDelete = (i: number) => {
    setTaxRates(taxRates.filter((tax, _i) => i !== _i));
  };

  const onDeleteConfig = async () => {
    if (!currentCountry) {
      return;
    }
    try {
      await dispatch(deleteTaxconfig(currentCompanyDomain, currentCountry.countryCode, currentCountry.taxServiceType));
      await dispatch(fetchTaxConfig(currentCompanyDomain));
      handleUpdateCallback && handleUpdateCallback();
    } catch (err) {
      console.log(err);
    }
  };

  const onUpdateTaxConfig = async () => {
    if (taxRates.length === 0) {
      return;
    }

    setIsLoading(true);
    const manualTaxConfig = new ManualTaxConfig();

    manualTaxConfig.setTaxRatesList(taxRates);
    manualTaxConfig.setTaxLabel(taxLabel);
    manualTaxConfig.setTaxRegNumber(taxRegNumber);
    manualTaxConfig.setTaxRegNumberRequirementType(taxRegNumberRequirementType);
    manualTaxConfig.setLocationValidation(validateLocation);
    manualTaxConfig.setTaxValidation(validateTax);

    try {
      await dispatch(updateManualTaxConfig(currentCompanyDomain, currentCountry.countryCode, manualTaxConfig));
      await dispatch(fetchTaxConfig(currentCompanyDomain));

      handleUpdateCallback && handleUpdateCallback();

    } catch (err) {
      setIsLoading(false);
    }
  };

  return (
    <div className="tax-config-flow">
      <AddTaxRates
        taxName={taxName}
        taxRate={taxRate}
        taxRates={taxRates}
        onChangeTaxName={(value) => setTaxName(value)}
        onChangeTaxRate={(value) => setTaxRate(value)}
        onAdd={onAdd}
        onDelete={onDelete}
        onDeleteConfig={onDeleteConfig}
        isConfigured={isConfigured}
      />

      <TaxRegistrationNumber taxLabel={taxLabel} taxRegNumber={taxRegNumber} onChangeTaxLabel={(value) => setTaxLabel(value)} onChangeTaxRegNumber={(value) => setTaxRegNumber(value)} />

      <CollectTaxRegistrationNumber
        taxRegNumberRequirementType={taxRegNumberRequirementType}
        onChangeType={(value) => setTaxRegNumberRequirementType(value)}
        onChangeCollectTax={(value) => setTaxRegNumberRequirementType(value)}
      />

      {taxRegNumberRequirementType !== TaxCollection.DO_NOT_COLLECT && (
        <ValidationOptions
          taxIdentifier="ABN"
          onValidateLocation={() => setValidateLocation(!validateLocation)}
          onValidateTax={() => setValidateTax(!validateTax)}
          validateLocation={validateLocation}
          validateTax={validateTax}
        />
      )}

      <Button disabled={taxRates.length === 0} isLoading={isLoading} isFullWidth id="save-manual-tax" title="MANUAL_TAX_CONFIG_SAVE" onClick={onUpdateTaxConfig} />
    </div>
  );
};

export default AustraliaTaxConfigFlow;
