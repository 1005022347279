
import * as grpcWeb from "grpc-web";
import * as jspb from "google-protobuf";
import { reduxStore } from "../..";
import { BILLSBY_AUTH_DATA_CONTROL } from "../constants";
import { storeGet } from "../storeUtils";
import { IToken, IParsedToken } from "../../models/Auth";
import { retryConnection } from "../apiUtils";
import moment from "moment";



const getMetadataGrpcOfficial = (): grpcWeb.Metadata => {
  //var deadline = new Date();
  //deadline.setSeconds(deadline.getSeconds() + 3);
  //let meta: grpcWeb.Metadata = { deadline: deadline.getTime() as any };
  let meta: grpcWeb.Metadata = {}
  if (storeGet(BILLSBY_AUTH_DATA_CONTROL)) {
    const token = <IToken>storeGet(BILLSBY_AUTH_DATA_CONTROL);
    meta["Authorization"] = "Bearer " + token.access_token;
  }

  return meta;
}

const refreshTokenIfExpired = async () => {
  const parsedToken = reduxStore.getState().auth.parsedToken as IParsedToken;
  if (!parsedToken) { return }
  const dateExpUnixTimestamp = parsedToken.exp;
  const expDate = moment.unix(dateExpUnixTimestamp);
  const now = moment();
  const diff = moment.duration(expDate.diff(now)).asSeconds();
  const safeDelaySeconds = 5;
  if (diff <= safeDelaySeconds || now.isAfter(expDate)) {
    return retryConnection(0);
  }
  return Promise.resolve();
}


export async function grpcUnaryCall(request: any, client: Object, method: (request: any, metadata: grpcWeb.Metadata | null, callback: (err: grpcWeb.Error,
  response: jspb.Message) => void) => grpcWeb.ClientReadableStream<any>) {

  // enable grpc dev tools in network tab -> the chrome plugin is needed anyways
  const enableDevTools = (window as any).__GRPCWEB_DEVTOOLS__ || (() => { });
  enableDevTools([client]);

  const MAX_RETRIES = 1;
  let attempt = 0;

  await refreshTokenIfExpired();

  return (function grpcInternal() {
    return new Promise<jspb.Message>((resolve, reject) => {
      try {
        method.bind(client)(request, getMetadataGrpcOfficial(), (err, response) => {
          if (err && attempt < MAX_RETRIES) {
            attempt++;
            retryConnection(0)
              .then(() => grpcInternal())
              .then(message => resolve(message))
              .catch(err => reject(err));
          }
          else if (err) {
            reject(err);
          }
          else {
            resolve(response);
          }
        });
      }
      catch (err) {
        return reject(err);
      }
    })

  })()

}