import API from "../utils/API";
import { makeActionCreator } from "../utils/reduxUtils";

export const SET_CURRENCIES_FIELD = "SET_CURRENCIES_FIELD";
export const setCurrenciesField = makeActionCreator(SET_CURRENCIES_FIELD, "field", "payload");

export const RESET_CURRENCIES_STATE = "RESET_CONFIGURATION_STATE";
export const resetCurrenciesState = makeActionCreator(RESET_CURRENCIES_STATE);

export const FETCH_PRODUCTS_CURRENCIES_REQUEST = "FETCH_PRODUCTS_CURRENCIES_REQUEST";
export const FETCH_PRODUCTS_CURRENCIES_SUCCESS = "FETCH_PRODUCTS_CURRENCIES_SUCCESS";
export const FETCH_PRODUCTS_CURRENCIES_FAILURE = "FETCH_PRODUCTS_CURRENCIES_FAILURE";

export const UPDATE_BASE_CURRENCY_PAYMENT_GATEWAY_REQUEST = "UPDATE_BASE_CURRENCY_PAYMENT_GATEWAY_REQUEST";
export const UPDATE_BASE_CURRENCY_PAYMENT_GATEWAY_SUCCESS = "UPDATE_BASE_CURRENCY_PAYMENT_GATEWAY_SUCCESS";
export const UPDATE_BASE_CURRENCY_PAYMENT_GATEWAY_FAILURE = "UPDATE_BASE_CURRENCY_PAYMENT_GATEWAY_FAILURE";

export const FETCH_CURRENCIES_MAPPING_REQUEST = "FETCH_CURRENCIES_MAPPING_REQUEST";
export const FETCH_CURRENCIES_MAPPING_SUCCESS = "FETCH_CURRENCIES_MAPPING_SUCCESS";
export const FETCH_CURRENCIES_MAPPING_FAILURE = "FETCH_CURRENCIES_MAPPING_FAILURE";

export const UPDATE_CURRENCY_MAPPING_REQUEST = "UPDATE_CURRENCY_MAPPING_REQUEST";
export const UPDATE_CURRENCY_MAPPING_SUCCESS = "UPDATE_CURRENCY_MAPPING_SUCCESS";
export const UPDATE_CURRENCY_MAPPING_FAILURE = "UPDATE_CURRENCY_MAPPING_FAILURE";


export const fetchProductsCurrencies = (companyId: number | null) => {
  return {
    types: [FETCH_PRODUCTS_CURRENCIES_REQUEST, FETCH_PRODUCTS_CURRENCIES_SUCCESS, FETCH_PRODUCTS_CURRENCIES_FAILURE],
    callAPI: () => API.fetchAllProducts(companyId, true, true, true)
  }
}

export const fetchCurrenciesMapping = (companyDomain: string) => {
  return {
    types: [FETCH_CURRENCIES_MAPPING_REQUEST, FETCH_CURRENCIES_MAPPING_SUCCESS, FETCH_CURRENCIES_MAPPING_FAILURE],
    callAPI: () => API.fetchCurrenciesMapping(companyDomain)
  }
}

export const updateBaseCurrencyPaymentGateway = (companyDomain: string, currencyCode: string, paymentGatewayId: number | null, achPaymentGatewayId: number | null) => {
  return {
    types: [UPDATE_BASE_CURRENCY_PAYMENT_GATEWAY_REQUEST, UPDATE_BASE_CURRENCY_PAYMENT_GATEWAY_SUCCESS, UPDATE_BASE_CURRENCY_PAYMENT_GATEWAY_FAILURE],
    callAPI: () => API.updateBaseCurrencyPaymentGateway(companyDomain, currencyCode, paymentGatewayId, achPaymentGatewayId)
  }
}

export const updateCurrencyGatewayMapping = (companyDomain: string, mappings: Array<{ paymentGatewayId: number | null | undefined, achPaymentGatewayId: number | null | undefined, currencyCode: string }>) => {
  return {
    types: [UPDATE_CURRENCY_MAPPING_REQUEST, UPDATE_CURRENCY_MAPPING_SUCCESS, UPDATE_CURRENCY_MAPPING_FAILURE],
    callAPI: () => API.updateCurrencyGatewayMapping(companyDomain, mappings)
  }
}
