import React from "react";
import Panel from "../../components/ui/panel/Panel";
import { ICompany } from "../../models/Auth";
import Text from "../../components/ui/text/Text";
import "./CompanySelector.scss";
import { useSelector } from "react-redux";
import { storeSet } from "../../utils/storeUtils";
import { BILLSBY_SELECTED_COMPANY } from "../../utils/constants";
import { ConfigConstants } from "../../utils/config";
import { AppState } from "../..";
import NavigationAlert from "../../components/ui/navigation-alert/NavigationAlert";
import FormGroup from "../../components/ui/form-group/FormGroup";
import { refreshToken } from "../../utils/authUtils";

const isLocalHost = ConfigConstants.reactAppEnvironment === "Localhost";

const CompanySelector: React.FC = () => {
  const { auth: { parsedToken, isFetchedCompaniesSuccess, companies } } = useSelector((state: AppState) => ({ auth: state.auth }))
  if (!parsedToken || !isFetchedCompaniesSuccess) {
    return null;
  }

  return (
    <FormGroup className="company-selector">
      {
        companies.map((company: ICompany) => {
          const companyUrl = `${company.companyDomain}${ConfigConstants.billsbyDomain}${isLocalHost ? `:${process.env.REACT_APP_PORT}` : ""}`;
          return (
            <Panel key={company.companyId}>
              <div onClick={() => {
                (async () => {
                  storeSet(BILLSBY_SELECTED_COMPANY, company.companyId);
                  await refreshToken(company.companyId);
                  window.location.replace(`//${companyUrl}`);
                })()
              }} className="company-selector__container">
                <div className="company-selector__data">
                  <Text content={company.name} shouldTranslate={false} />
                  <Text content={companyUrl} shouldTranslate={false} noMargin />
                </div>
                <i className="far fa-arrow-right"></i>
              </div>
              <NavigationAlert
                title="ALERT_CURRENCY_MAPPING_MISMATCH_TITLE"
                subTitle="ALERT_CURRENCY_MAPPING_MISMATCH_SUBTITLE"
                className="company-selector__currency-mismatch"
                type="error"
                buttonLabel="ALERT_CURRENCY_MAPPING_MISMATCH_FIXTHIS"
                icon="fas fa-headset"
                onClick={() => {
                  (async () => {
                    storeSet(BILLSBY_SELECTED_COMPANY, company.companyId);
                    await refreshToken(company.companyId);
                    window.location.replace(`//${companyUrl}/configuration/currencies`);
                  })()
                }}
                isVisible={!company.isCurrencyMappingMatched}
              />
            </Panel>
          )
        })
      }
    </FormGroup>
  )
}

export default CompanySelector;