import React, { useState } from "react";
import Panel from "../../components/ui/panel/Panel";
import Text from "../../components/ui/text/Text";
import Button from "../../components/ui/button/Button";
import API from "../../utils/API";
import "./RevokeConfirmationPanel.scss";

interface IRevokeConfirmationPanel {
  data: {
    firstName: string;
    currentCompanyDomain: string;
    companyName: string;
    userId: number;
  };
  revokeSuccessCallback?: Function;
}

const RevokeConfirmationPanel: React.FC<IRevokeConfirmationPanel> = ({ data, revokeSuccessCallback }: IRevokeConfirmationPanel) => {
  const [state, setState] = useState({ isLoading: false })
  const { firstName, currentCompanyDomain, companyName, userId } = data;
  const { isLoading } = state;
  const handleRevokeUser = async () => {
    try {
      setState(state => ({ ...state, isLoading: true }))
      await API.revokeTeammateUserAccess(currentCompanyDomain, userId);
      if (revokeSuccessCallback) {
        await revokeSuccessCallback();
      }
    } catch (err) {
      console.log(err);
    }
    finally {
      setState(state => ({ ...state, isLoading: false }))
    }
  };

  if(userId === 0) {
    return null;
  }

  return (
    <Panel
      className="revoke-access-panel"
      title="TEAMMATES_LIST_REVOKE_ACCESS_PANEL_TITLE"
      translateWith={{ firstName }}
    >
      <Text content="TEAMMATES_LIST_REVOKE_ACCESS_PANEL_CONTENT" translateWith={{ firstName, companyName }} />
      <Button
        id="revoke-access"
        className="revoke-access-panel__button"
        title="TEAMMATES_LIST_REVOKE_ACCESS_COMPANY"
        onClick={handleRevokeUser}
        isLoading={isLoading}
        disabled={isLoading}
        translateWith={{ companyName: companyName.toUpperCase() }}
      />
    </Panel>
  );
};

export default RevokeConfirmationPanel;
