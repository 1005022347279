import React, { useEffect, ReactElement } from "react";
import Helmet from "react-helmet";
import { useDispatch } from "react-redux";
import counterpart from "counterpart";
import { resetPaymentGatewaysState } from "../../../actions/paymentGatewaysActions";
import queryString from "query-string";
import history from "../../../utils/history";
import { PaymentGatewaysType } from "../../../models/Configuration";
import { resetCurrenciesState } from "../../../actions/currenciesActions";

interface IConfiguration {
  children: ReactElement | Array<ReactElement>
}

const Configuration: React.FC<IConfiguration> = ({ children }) => {
  const dispatch = useDispatch<Function>();

  useEffect(() => {
    const params = queryString.parse(window.location.search);
    if (params.code && params.scope) {
      // redirect from stripe
      history.push(`/configuration/payment-gateways${window.location.search}&type=${PaymentGatewaysType.STRIPE}`);
    }

    return () => {
      dispatch(resetPaymentGatewaysState());
      dispatch(resetCurrenciesState());
    }
  }, [])

  return (
    <div>
      <Helmet title={counterpart("CONFIGURATION_PAGE_TITLE")} />

      {children}

    </div>
  )
}

export default Configuration;