import React from "react";
import "./AmountFormatted.scss";
import { extractDecimal } from "../../../utils/commonUtils";

interface IAmountFormatted {
  amount: string,
  className?: string
}

const AmountFormatted: React.FC<IAmountFormatted> = React.memo(({ amount, className = "" }) => {
  const { base, decimal } = extractDecimal(amount);
  return (
    <div className={`amount-formatted ${className}`}>
      <div className="amount-formatted__base">{base}</div>
      {decimal && <div>.</div>}
      {decimal && <div className="amount-formatted__decimal">{decimal}</div>}
    </div>
  )
})

export default AmountFormatted;