import { BillsbyAction } from "../models/BillsbyAction";
import { AppState } from "..";
import { RESET_EMAIL_LOGS_STATE, SET_EMAIL_LOGS_FIELD, FETCH_EMAIL_LOGS_SUCCESS, FETCH_EMAIL_LOGS_REQUEST, FETCH_EMAIL_LOG_BODY_SUCCESS, FETCH_EMAIL_LOGS_FAILURE, FETCH_EMAIL_LOG_BODY_REQUEST, FETCH_EMAIL_LOG_BODY_FAILURE } from "../actions/emailLogsActions";
import counterpart from "counterpart";
import { GetEmailLogsResponse, GetEmailResponse } from "../utils/grpc/generated/Billsby.Protos/EmailSender/private/emaillogs/emaillogs_pb";
import { EmailLogStatus } from "../models/EmailLog";
import { SelectedPage } from "../components/ui/pagination/Pagination";


export interface IEmailLogsReducer {
  emailSearchedFilter: string,
  filters: Array<{ label: string, value: EmailLogStatus }>
  emailTypeFilter: { label: string, value: EmailLogStatus },
  emailLogs?: GetEmailLogsResponse,
  emailBody?: GetEmailResponse,
  selectedPage: SelectedPage,
  isFirstSearch: boolean,
  isLoadingLogs: boolean,
  isLoadingEmailBody: boolean,
  hasMoreThanOnePage: boolean
}

export const getInitialState = (): IEmailLogsReducer => {
  const filters = [
    { label: counterpart("EMAIL_LOGS_FILTER_ALL"), value: EmailLogStatus.UNASSIGNED },
    { label: counterpart("EMAIL_LOGS_FILTER_DELIVERED"), value: EmailLogStatus.DELIVERED },
    { label: counterpart("EMAIL_LOGS_FILTER_FAILED"), value: EmailLogStatus.FAILED },
  ]
  return {
    emailSearchedFilter: "",
    filters,
    emailTypeFilter: filters[0],
    selectedPage: SelectedPage.FIRST,
    isFirstSearch: true,
    isLoadingLogs: true,
    isLoadingEmailBody: false,
    hasMoreThanOnePage: false
  }
}


export default function emailLogsReducer(state: IEmailLogsReducer = getInitialState(), action: BillsbyAction, store: AppState) {
  switch (action.type) {
    case SET_EMAIL_LOGS_FIELD:
      return { ...state, [action.fieldName]: action.fieldValue }
    case FETCH_EMAIL_LOGS_REQUEST:
      return { ...state, isFirstSearch: state.emailLogs ? false : true, isLoadingLogs: true }
    case FETCH_EMAIL_LOGS_SUCCESS:
      return { ...state, emailLogs: action.response, isLoadingLogs: false }
    case FETCH_EMAIL_LOGS_FAILURE:
      return { ...state, isLoadingLogs: false}
    case FETCH_EMAIL_LOG_BODY_REQUEST:
      return { ...state, isLoadingEmailBody: true }
    case FETCH_EMAIL_LOG_BODY_SUCCESS:
      return { ...state, emailBody: action.response, isLoadingEmailBody: false }
    case FETCH_EMAIL_LOG_BODY_FAILURE:
      return { ...state, emailBody: undefined, isLoadingEmailBody: false }
    case RESET_EMAIL_LOGS_STATE:
      return { ...getInitialState() }
    default:
      return state;
  }
}