import React, { useEffect } from "react";
import history from "../../../../../utils/history";
import Panel from "../../../../ui/panel/Panel";
import Text from "../../../../ui/text/Text";
import "./ResendInvoiceLinkEmailTemplate.scss";
import { AppState } from "../../../../..";
import { useDispatch, useSelector } from "react-redux";
import { IAuthReducerState } from "../../../../../reducers/authReducer";
import { ICompanyBrandingReducerState } from "../../../../../reducers/companyBrandingReducer";
import { Row, Col } from "react-grid-system";
import EmailPreivewContainer from "../../brand-customization/email-preview/EmailPreivewContainer";
import BackNavigationPanel from "../../../../ui/back-navigation-panel/BackNavigationPanel";
import { IEmailCustomizationReducerState } from "../../../../../reducers/emailCustomizationReducer";
import { resetEmailTemplateValues, getEmailTemplateValues } from "../../../../../actions/emailCustomizationActions";
import { fetchCompanyBranding } from "../../../../../actions/companyBrandingActions";
import EmailFooter from "../email-footer/EmailFooter";
import EmailEditableArea from "../email-editable-area/EmailEditableArea";
import { EmailTypes } from "../../../../../utils/grpc/generated/Billsby.Protos/Messenger/private/emailconfig/emailconfig_pb";


const ResendInvoiceLinkEmailTemplate: React.FC = () => {
  const ril_companyBrandingReducer = useSelector<AppState, ICompanyBrandingReducerState>(state => state.companyBrandingReducer);
  const { highlightColor } = ril_companyBrandingReducer;
  const { customArea1, customArea2 } = useSelector<AppState, IEmailCustomizationReducerState>(state => state.emailCustomizationReducer);
  const authReducer = useSelector<AppState, IAuthReducerState>(state => state.auth);
  const { currentCompanyId, companyName, currentCompanyDomain } = authReducer;
  const defaultHighLightColor = "#F87847";

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchCompanyBranding(currentCompanyDomain));
    dispatch(getEmailTemplateValues(currentCompanyId as number, companyName, EmailTypes.INVOICE_LINK));
    return () => { dispatch(resetEmailTemplateValues()) };
  }, []);


  return (
    <div>
      <BackNavigationPanel
        title={"EMAIL_TEMPLATE_RESEND_INVOICE_LINK_BACK_PANEL_TITLE"} shouldTranslateTitle={true}
        onClick={() => history.push("/configuration/email-notifications/emails/payment-credits-refund")} />

      <Row>
        <Col sm={8}>
          <Panel className="resend-invoice-link-email-template__panel" noMargin>
            <EmailPreivewContainer>

              <Text
                content={<span dangerouslySetInnerHTML={{__html: customArea1}}></span>}
                shouldTranslate={false}
                className="email-preview__details__text email-preview__details__text-title bar bar-blue"
                component="span"
                noMargin
              />

              <Text
                content="EMAIL_TEMPLATE_RESEND_INVOICE_LINK_GREETINGS"
                className="email-preview__details__text"
                component="span"
                noMargin
              />
              <Text
                content={<span dangerouslySetInnerHTML={{__html: customArea2}}></span>}
                shouldTranslate={false}
                className="email-preview__details__text bar bar-orange"
                component="span"
                noMargin
              />

              <Text
                content="EMAIL_TEMPLATE_RESEND_INVOICE_LINK_BODY_1"
                className="email-preview__details__text"
                component="span"
                noMargin
                style={{ textDecoration: "underline", cursor: "pointer", color: !!highlightColor ? highlightColor : defaultHighLightColor }}
              />

              <Text
                content="EMAIL_TEMPLATE_RESEND_INVOICE_LINK_REAGRDS"
                className="email-preview__details__text"
                component="span"
                noMargin
              />

              <Text
                content={companyName}
                className="email-preview__details__text"
                component="span"
                shouldTranslate={false}
                noMargin
              />
              <EmailFooter />
            </EmailPreivewContainer>
          </Panel>
        </Col>
        <Col sm={4}>
          <EmailEditableArea  
            id="ResendInvoiceLinkEmailTemplate"
            emailCustomCopyOneValue={customArea1}
            emailCustomCopyTwoValue={customArea2}
            emailCustomCopyThreeValue=""
            emailCustomCopyThreeIsVisible={false}
            showCompanyAlertToggle={true}
            showProductAlertToggle={false}
            showPlanAlertToggle={false}
          />
        </Col>
      </Row>
    </div>
  )
}

export default ResendInvoiceLinkEmailTemplate;