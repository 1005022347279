import API from "../utils/API";
import { makeActionCreator, makeGenericActionCreator } from "../utils/reduxUtils";
import { GetTaxConfigRequest, UpsertTaxJarConfigRequest, TaxJarConfig, UpsertAutoBillsbyTaxRequest, AutoBillsbyTaxConfig, Address, GetTaxJarProductCategoriesRequest, GetAutoBillsbyProductCategoriesRequest, DeleteTaxConfigRequest, UpsertManualTaxConfigRequest, ManualTaxConfig } from "../utils/grpc/generated/Billsby.Protos/billing/private/company/taxation/taxation_pb"
import { TaxationServiceClient } from "../utils/grpc/generated/Billsby.Protos/billing/private/company/taxation/TaxationServiceClientPb";
import { grpcUnaryCall } from "../utils/grpc/grpcUtils";
import { ICompanyTaxRatesReducerState } from "../reducers/companyTaxRatesReducer";
import { ConfigConstants } from "../utils/config";

export const FETCH_COUNTRIES_FOR_TAXATION_REQUEST = "FETCH_COUNTRIES_FOR_TAXATION_REQUEST";
export const FETCH_COUNTRIES_FOR_TAXATION_SUCCESS = "FETCH_COUNTRIES_FOR_TAXATION_SUCCESS";
export const FETCH_COUNTRIES_FOR_TAXATION_FAILURE = "FETCH_COUNTRIES_FOR_TAXATION_FAILURE";

export const FETCH_OTHER_COUNTRIES_REQUEST = "FETCH_OTHER_COUNTRIES_REQUEST";
export const FETCH_OTHER_COUNTRIES_SUCCESS = "FETCH_OTHER_COUNTRIES_SUCCESS";
export const FETCH_OTHER_COUNTRIES_FAILURE = "FETCH_OTHER_COUNTRIES_REQUEST";

export const FETCH_TAX_CONFIGS_REQUEST = "FETCH_TAX_CONFIGS_REQUEST"; 
export const FETCH_TAX_CONFIGS_SUCCESS = "FETCH_TAX_CONFIGS_SUCCESS"; 
export const FETCH_TAX_CONFIGS_FAILURE = "FETCH_TAX_CONFIGS_FAILURE"; 

export const DELETE_TAX_CONFIGS_REQUEST = "DELETE_TAX_CONFIGS_REQUEST"; 
export const DELETE_TAX_CONFIGS_SUCCESS = "DELETE_TAX_CONFIGS_SUCCESS"; 
export const DELETE_TAX_CONFIGS_FAILURE = "DELETE_TAX_CONFIGS_FAILURE"; 

export const FETCH_TAXABLE_COUNTRIES_REQUEST = "FETCH_TAXABLE_COUNTRIES_REQUEST"; 
export const FETCH_TAXABLE_COUNTRIES_SUCCESS = "FETCH_TAXABLE_COUNTRIES_SUCCESS"; 
export const FETCH_TAXABLE_COUNTRIES_FAILURE = "FETCH_TAXABLE_COUNTRIES_FAILURE"; 

export const FETCH_PRODUCT_CATEGORIES_REQUEST = "FETCH_PRODUCT_CATEGORIES_REQUEST";
export const FETCH_PRODUCT_CATEGORIES_SUCCESS = "FETCH_PRODUCT_CATEGORIES_SUCCESS";
export const FETCH_PRODUCT_CATEGORIES_FAILURE = "FETCH_PRODUCT_CATEGORIES_FAILURE";

export const SET_CURRENT_COUNTRY = "SET_CURRENT_COUNTRY";
export const SET_TAX_RATES_FIELD = "SET_TAX_RATES_FIELD";
export const SET_TAX_COUNTRY_CONFIG = "SET_TAX_COUNTRY_CONFIG";
export const RESET_COMPANY_TAX_RATES = "RESET_COMPANY_TAX_RATES";
export const RESET_TAXJAR_PRODUCT_CATEGORIES = "RESET_PRODUCT_CATEGORIES";
export const RESET_TAXJAR_CURRENT_COUNTRY = "RESET_TAXJAR_CURRENT_COUNTRY";

export const UPDATE_TAX_CONFIG_REQUEST = "UPDATE_TAX_CONFIG_REQUEST";
export const UPDATE_TAX_CONFIG_SUCCESS = "UPDATE_TAX_CONFIG_SUCCESS";
export const UPDATE_TAX_CONFIG_FAILURE = "UPDATE_TAX_CONFIG_FAILURE";

type AllowedTaxRatesFields =  keyof ICompanyTaxRatesReducerState;

export const setCurrentCountry = makeActionCreator(SET_CURRENT_COUNTRY, "payload");
export const resetCompanyTaxRates = makeActionCreator(RESET_COMPANY_TAX_RATES, "payload");
export const resetCompanyTaxJarProductCategories = makeActionCreator(RESET_TAXJAR_PRODUCT_CATEGORIES, "payload");
export const resetCompanyTaxJarCurrentCountry = makeActionCreator(RESET_TAXJAR_CURRENT_COUNTRY, "payload");
export const setTaxRatesField = makeGenericActionCreator<AllowedTaxRatesFields>(SET_TAX_RATES_FIELD);

export const fetchTaxConfig = function (companyDomain: string) {
  const getTaxConfig = new GetTaxConfigRequest();
  getTaxConfig.setCompanyDomain(companyDomain);
  const taxationServiceClient = new TaxationServiceClient(ConfigConstants.grpcBaseUrl);
  return {
    types: [FETCH_TAX_CONFIGS_REQUEST, FETCH_TAX_CONFIGS_SUCCESS, FETCH_TAX_CONFIGS_FAILURE],
    callAPI: () => grpcUnaryCall(getTaxConfig, taxationServiceClient, taxationServiceClient.getTaxConfig)
  };
};

export const deleteTaxconfig = function(companyDomain: string, countryIso3Code: string, taxServiceType?: string) {
  const deleteTaxConfig = new DeleteTaxConfigRequest();
  deleteTaxConfig.setCompanyDomain(companyDomain);
  deleteTaxConfig.setCountryIso3Code(countryIso3Code);
  taxServiceType && deleteTaxConfig.setTaxServiceType(taxServiceType);
  const taxationServiceClient = new TaxationServiceClient(ConfigConstants.grpcBaseUrl);
  return {
    types: [DELETE_TAX_CONFIGS_REQUEST, DELETE_TAX_CONFIGS_SUCCESS, DELETE_TAX_CONFIGS_FAILURE],
    callAPI: () => grpcUnaryCall(deleteTaxConfig, taxationServiceClient, taxationServiceClient.deleteTaxConfig)
  }
}

export const updateTaxJarConfig = function(data: UpsertTaxJarConfigRequest.AsObject) {
  const taxConfig = new UpsertTaxJarConfigRequest();
  const taxConfigData = new TaxJarConfig();

  if(!data.taxJarConfig) {
    return;
  }

  taxConfigData.setApiToken(data.taxJarConfig.apiToken);
  taxConfigData.setTaxProductCategoryCode(data.taxJarConfig.taxProductCategoryCode)
  taxConfigData.setIsAutoFileEnabled(data.taxJarConfig.isAutoFileEnabled);
  taxConfigData.setTaxLabel(data.taxJarConfig.taxLabel);
  taxConfigData.setTaxRegNumber(data.taxJarConfig.taxRegNumber);
  taxConfigData.setTaxRegNumberRequirementType(data.taxJarConfig.taxRegNumberRequirementType);

  taxConfig.setCompanyDomain(data.companyDomain);
  taxConfig.setCountryIso3Code(data.countryIso3Code);
  taxConfig.setTaxJarConfig(taxConfigData);
  const taxationServiceClient = new TaxationServiceClient(ConfigConstants.grpcBaseUrl);
  return {
    types: [UPDATE_TAX_CONFIG_REQUEST, UPDATE_TAX_CONFIG_SUCCESS, UPDATE_TAX_CONFIG_FAILURE],
    callAPI: () => grpcUnaryCall(taxConfig, taxationServiceClient, taxationServiceClient.upsertTaxJarConfig)
  };
}

export const updateBillsbyTaxConfig = function(data: UpsertAutoBillsbyTaxRequest.AsObject) {
  const taxConfig = new UpsertAutoBillsbyTaxRequest();
  const taxConfigData = new AutoBillsbyTaxConfig();
  const nexusAddress = new Address();

  if(!data.billsbyTaxConfig) {
    return;
  }

  if(data.billsbyTaxConfig.nexusAddress) {
    nexusAddress.setAddressLine1(data.billsbyTaxConfig.nexusAddress.addressLine1);
    nexusAddress.setAddressLine2(data.billsbyTaxConfig.nexusAddress.addressLine2);
    nexusAddress.setCity(data.billsbyTaxConfig.nexusAddress.city);
    nexusAddress.setCountryIso3Code(data.billsbyTaxConfig.nexusAddress.countryIso3Code);
    nexusAddress.setPostCode(data.billsbyTaxConfig.nexusAddress.postCode);
    nexusAddress.setState(data.billsbyTaxConfig.nexusAddress.state);
  }
  
  taxConfigData.setTaxLabel(data.billsbyTaxConfig.taxLabel);
  taxConfigData.setTaxProductCategoryCode(data.billsbyTaxConfig.taxProductCategoryCode);
  taxConfigData.setTaxRegNumber(data.billsbyTaxConfig.taxRegNumber);
  taxConfigData.setTaxRegNumberRequirementType(data.billsbyTaxConfig.taxRegNumberRequirementType);
  taxConfigData.setNexusAddress(data.billsbyTaxConfig.nexusAddress ? nexusAddress : undefined);

  taxConfig.setCompanyDomain(data.companyDomain);
  taxConfig.setCountryIso3Code(data.countryIso3Code);
  taxConfig.setBillsbyTaxConfig(taxConfigData);
  const taxationServiceClient = new TaxationServiceClient(ConfigConstants.grpcBaseUrl);
  
  return {
    types: [UPDATE_TAX_CONFIG_REQUEST, UPDATE_TAX_CONFIG_SUCCESS, UPDATE_TAX_CONFIG_FAILURE],
    callAPI: () => grpcUnaryCall(taxConfig, taxationServiceClient, taxationServiceClient.upsertAutoBillsbyTax)
  };
}

export const updateManualTaxConfig = function(companyDomain: string, countryIso3Code: string, taxConfig: ManualTaxConfig){
  const taxationServiceClient = new TaxationServiceClient(ConfigConstants.grpcBaseUrl);
  const manualTaxConfigRequest = new UpsertManualTaxConfigRequest();

  manualTaxConfigRequest.setCompanyDomain(companyDomain);
  manualTaxConfigRequest.setCountryIso3Code(countryIso3Code);
  manualTaxConfigRequest.setManualTaxConfig(taxConfig);

  return {
    types: [UPDATE_TAX_CONFIG_REQUEST, UPDATE_TAX_CONFIG_SUCCESS, UPDATE_TAX_CONFIG_FAILURE],
    callAPI: () => grpcUnaryCall(manualTaxConfigRequest, taxationServiceClient, taxationServiceClient.upsertManualTaxConfig)
  };
}

export const fetchTaxableCountries = function(){
  return {
    types: [FETCH_TAXABLE_COUNTRIES_REQUEST, FETCH_TAXABLE_COUNTRIES_SUCCESS, FETCH_TAXABLE_COUNTRIES_FAILURE],
    callAPI: () => API.fetchTaxableCountries()
  }
}

export const fetchOtherCountries = function(){
  return {
    types: [FETCH_OTHER_COUNTRIES_REQUEST, FETCH_OTHER_COUNTRIES_SUCCESS, FETCH_OTHER_COUNTRIES_FAILURE],
    callAPI: () => API.getCountries()
  }
}

export const fetchTaxJarIntegrationProductCategories = function (apiToken: string) {
  const getProductCategories = new GetTaxJarProductCategoriesRequest();
  getProductCategories.setApiToken(apiToken);
  const taxationServiceClient = new TaxationServiceClient(ConfigConstants.grpcBaseUrl);
  return {
    types: [FETCH_PRODUCT_CATEGORIES_REQUEST, FETCH_PRODUCT_CATEGORIES_SUCCESS, FETCH_PRODUCT_CATEGORIES_FAILURE],
    callAPI: () => grpcUnaryCall(getProductCategories, taxationServiceClient, taxationServiceClient.getTaxJarProductCategories)
  }
} 

export const fetchAutoBillsbyProductCategories = function () {
  const getProductCategories = new GetAutoBillsbyProductCategoriesRequest();
  const taxationServiceClient = new TaxationServiceClient(ConfigConstants.grpcBaseUrl);
  return {
    types: [FETCH_PRODUCT_CATEGORIES_REQUEST, FETCH_PRODUCT_CATEGORIES_SUCCESS, FETCH_PRODUCT_CATEGORIES_FAILURE],
    callAPI: () => grpcUnaryCall(getProductCategories, taxationServiceClient, taxationServiceClient.getAutoBillsbyProductCategories)
  }
} 