import { BillsbyAction } from "../../models/BillsbyAction";
import { AppState } from "../..";
import { RESET_CREATE_DISCOUNT_STATE, SET_MONETARY_DISCOUNT, SET_DISCOUNT_STEPS_COLLAPSED, SET_DISCOUNT_FORM_COMPLETED, SET_DISCOUNT_FORM_HAS_ERROR, SET_CREATE_DISCOUNT_BASIC_INFO, CREATE_DISCOUNT_REQUEST, CREATE_DISCOUNT_SUCCESS, CREATE_DISCOUNT_FAILURE, FETCH_DISCOUNT_DETAILS_SUCCESS, FETCH_CURRENCIES_OF_PLANS_DISCOUNTS_SUCCESS } from "../../actions/discount-coupon/createDiscountActions";
import { DeductionType, DiscountType, GetDiscountResponse, Currency, GetPlanCurrenciesResponse } from "../../utils/grpc/generated/Billsby.Protos/core/private/discounts/discounts_pb";
import {MonetaryCurrencyValue} from "../../models/Discount";
import { divideNumberBy100, removeDuplicatedItems } from "../../utils/commonUtils";

export interface ICreateDiscountState {
  helpTextTitleBasicForm: string,
  helpTextContentBasicForm: Array<string>,
  helpTextTitleDiscountForm: string,
  helpTextContentDiscountForm: Array<string>,
  name: string,
  isAllPlans: boolean,
  canBeUseInConjunction: boolean,
  stageCollapsed: Array<boolean>,
  stageStatuses: Array<boolean>,
  stageErrors: Array<boolean>,
  isCreatingDiscountRequest: boolean,
  isCreatingDiscountSuccess: boolean,
  isCreatingDiscountFailure: boolean,
  discountType: DiscountType,
  discountTypeButtonText: string,
  hasSelectedDiscountType: boolean,
  deductionType: DeductionType,
  percentage?: number,
  monetaries: Array<MonetaryCurrencyValue>,
  selectedCurrency?: Currency,
  isUpdate: boolean,
  currenciesOfPlans?: GetPlanCurrenciesResponse
}

export const initialState = {
  helpTextTitleBasicForm: "CREATE_DISCOUNT_FORM_HELP_TITLE",
  helpTextContentBasicForm: ["CREATE_DISCOUNT_FORM_HELP_TEXT_1", "CREATE_DISCOUNT_FORM_HELP_TEXT_2"],
  helpTextTitleDiscountForm: "CREATE_DISCOUNT_FORM_HELP_TITLE",
  helpTextContentDiscountForm: ["CREATE_DISCOUNT_FORM_HELP_TEXT_1", "CREATE_DISCOUNT_FORM_HELP_TEXT_2"],
  name: "",
  isAllPlans: true,
  canBeUseInConjunction: false,
  stageCollapsed: [false, true, true, true, true],
  stageStatuses: [false, false, false, false],
  stageErrors: [false, false, false, false],
  isCreatingDiscountRequest: false,
  isCreatingDiscountSuccess: false,
  isCreatingDiscountFailure: false,
  discountType: DiscountType.PERCENTAGE,
  discountTypeButtonText: "CREATE_DISCOUNT_FORM_DISCOUNT_TYPE_PERCENTAGE_BUTTON",
  hasSelectedDiscountType: false,
  deductionType: DeductionType.PLAN_ONLY,
  percentage: undefined,
  monetaries: [],
  selectedCurrency: undefined,
  isUpdate: false,
}

export default function createDiscountReducer(state: ICreateDiscountState = initialState, action: BillsbyAction, store: AppState) {
  switch (action.type) {
    case FETCH_CURRENCIES_OF_PLANS_DISCOUNTS_SUCCESS:
      return { ...state, currenciesOfPlans: action.response }
    case SET_CREATE_DISCOUNT_BASIC_INFO:
      return { ...state, [action.fieldName]: action.fieldValue }
    case SET_DISCOUNT_STEPS_COLLAPSED: {
      let panelToCollapse = action.payload;
      let newArray = [...state.stageCollapsed];

      newArray = newArray.map(el => (el !== panelToCollapse ? true : el));
      newArray[panelToCollapse] = false;

      return { ...state, stageCollapsed: newArray }
    }
    case SET_DISCOUNT_FORM_COMPLETED: {
      let newArray = [...state.stageStatuses];
      newArray[action.stageIndex] = action.value;
      return { ...state, stageStatuses: newArray }
    }
    case FETCH_DISCOUNT_DETAILS_SUCCESS: {
      const data = action.response as GetDiscountResponse;
      let percentage: number|undefined;
      let priceList: Array<MonetaryCurrencyValue> = [];
      const hasMonetary = !!data.getMonetary();
      const hasPercentage = !!data.getPercentage();
      if(hasMonetary) {
        const monetary = data.getMonetary() as GetDiscountResponse.MonetaryModel;
        priceList = monetary.getPricesList().map(i => {
          const value = i.getValue() ? Number(i.getValue()) / 100 : undefined;
          return {currencyCode: i.getCurrencyCode(), value: value};
        });
      }

      if(hasPercentage) {
        const percentageData = data.getPercentage() as GetDiscountResponse.PercentageModel;
        percentage = percentageData.getPercentage(); 
      }

      

      return {
        ...state,
        name: data.getName(),
        isAllPlans: data.getAllowAllPlans(),
        canBeUseInConjunction: data.getIsAllowedWithOtherDiscounts(),
        discountType: hasMonetary ? DiscountType.MONETARY : DiscountType.PERCENTAGE,
        discountTypeButtonText: hasMonetary ? "CREATE_DISCOUNT_FORM_DISCOUNT_TYPE_MONETARY_BUTTON" : "CREATE_DISCOUNT_FORM_DISCOUNT_TYPE_PERCENTAGE_BUTTON",
        hasSelectedDiscountType: true,
        deductionType: data.getDeductionType(),
        percentage: divideNumberBy100(percentage),
        monetaries: removeDuplicatedItems(priceList, "currencyCode"),
        isUpdate: true,
        stageStatuses: [true, true, true, true], 
      }
    }
    case SET_MONETARY_DISCOUNT: {
      let monetaries = [...state.monetaries];
      const existingMonetary = monetaries.filter(i => i.currencyCode === action.currency);
      if (existingMonetary.length > 0) {
        monetaries = monetaries.map(i => {
          if (i.currencyCode === action.currency) {
            i.value = Number(action.value) ? action.value : undefined;
          }
          return i;
        })
      } else {
        const newMonetary: MonetaryCurrencyValue = {currencyCode: action.currency, value: +action.value};
        monetaries.push(newMonetary);
      }

      return { ...state, monetaries: monetaries }
    }
    case SET_DISCOUNT_FORM_HAS_ERROR: {
      let newArray = [...state.stageErrors];
      newArray[action.stageIndex] = action.value;
      return { ...state, stageErrors: newArray }
    }
    case CREATE_DISCOUNT_REQUEST:
      return { ...state, isCreatingDiscountRequest: true, isCreatingDiscountSuccess: false, isCreatingDiscountFailure: false }
    case CREATE_DISCOUNT_SUCCESS:
      return { ...state, isCreatingDiscountRequest: false, isCreatingDiscountSuccess: true, isCreatingDiscountFailure: false }
    case CREATE_DISCOUNT_FAILURE:
      return { ...state, isCreatingDiscountRequest: false, isCreatingDiscountSuccess: false, isCreatingDiscountFailure: true }
    case RESET_CREATE_DISCOUNT_STATE:
      return { ...initialState }
    default:
      return state;
  }
}