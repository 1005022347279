import React from "react";
import Text from "../text/Text";
import BillsbyLogoBlack from "../../../images/billsby-logo-black.svg";
import BillsbyLogo from "../../../images/billsby-logo.svg";
import "./FixedSidebar.scss";
import { lightOrDark } from "../../../utils/commonUtils";

interface IFixedSidebar {
  backgroundHeader: string,
  logoHeader: string,
  rightIcon?: JSX.Element,
  leftIcon?: JSX.Element,
  companyDomain: string,
  companyName: string,
  className?: string
}

const FixedSidebar: React.FC<IFixedSidebar> = React.memo(({ backgroundHeader, logoHeader, rightIcon, leftIcon, companyDomain, companyName, className = "", children }) => {
  
  return (
    <div className={`fixed-sidebar ${className}`}>
      <div className="fixed-sidebar__header" style={{ backgroundColor: backgroundHeader }}>
        <div>
          {leftIcon}
          <img src={logoHeader ? logoHeader : lightOrDark(backgroundHeader) === "dark" ? BillsbyLogo : BillsbyLogoBlack} alt="Billsby logo" />
        </div>
        {rightIcon}
      </div>
      <div className="fixed-sidebar__body">
        {children}
      </div>
      <div className="fixed-sidebar__footer" onClick={() => window.open(`https://www.billsby.com/powered?companyname=${companyName}&utm_campaign=brandedlinks&utm_medium=invoice&utm_source=${companyDomain}`, "_self")}>
        <i className="fas fa-lock" />
        <Text content="SUBSCRIPTION_BILLING_BY" noMargin />
        <a><img src={BillsbyLogoBlack} alt="Billsby" className="footer__logo" /></a>
      </div>
    </div>
  )
})

export default FixedSidebar;