import React, { useEffect } from "react";
import FormGroup from "../ui/form-group/FormGroup";
import FormLabel from "../ui/form-label/FormLabel";
import Input from "../ui/input/Input";
import { Row, Col } from "react-grid-system";
import counterpart from "counterpart";
import { ConfigConstants } from "../../utils/config";
import "./PaymentDetailsForm.scss";
import { SPREEDLY_ERRORS } from "../../utils/constants";
import { ICustomerPaymentDetailsError } from "../../models/Customer";
import { BaseAddress } from "../../models/Address";

interface IPaymentDetailsFormProps {
  onChangeCardholderName: (name: string) => void,
  onChangeExpiryMonth: (month: number) => void,
  onChangeExpiryYear: (year: number) => void,
  cardNumberId: string,
  cvvId: string,
  formErrors: { cardNumber: string, expiryMonth: string, expiryYear: string } | null,
  formData: {
    cardholderName: string,
    expiryMonth: number,
    expiryYear: number,
  },
  initialCardholderName?: string,
  initialCardNumber?: string,
  initialCvv?: string,
  initialExpiryMonth?: number,
  initialExpiryYear?: number,
  disabled?: boolean,
  className?: string
}

export interface ICardDetailInputDetail {        
  paymentCardToken: string,
  expiryMonth: string,
  expiryYear: string,
  cardType: string,
  last4Digits: string,
  fullName: string,
  ipAddress?: string
}

export const validatePaymentDetailsForm = (data: { cardholderName: string; expiryMonth: number; expiryYear: number }, billingAddress: BaseAddress,
  callback: (cardData: any, error: ICustomerPaymentDetailsError | null ) => void) => {
  const Spreedly = (window as any).Spreedly;
  // for some reasons the Spreedly callback 'paymentMethod' triggers multiple times, so to avoid to trigger the callback more than once we use this flag
  let hasPaymentMethodCbTriggered = false;
  const [CARD_EXPIRY_MONTH, CARD_EXPIRY_YEAR, CREDIT_CARD_NUMBER] = ["expiryMonth", "expiryYear", "cardNumber"];

  // reset handlers
  Spreedly.removeHandlers();


  Spreedly.on("errors", (errors: Array<{ key: string; message: string; attribute: string }>) => {
    let spreedlyErrors: any = {};

    const attrMap: any = {
      month: CARD_EXPIRY_MONTH,
      year: CARD_EXPIRY_YEAR,
      number: CREDIT_CARD_NUMBER
    };

    errors.forEach(err => {
      spreedlyErrors[attrMap[err.attribute] || err.attribute] = counterpart(SPREEDLY_ERRORS[err.key] || "SPREEDLY_ERROR_GENERIC", { attribute: err.attribute });
    });

    callback(null, spreedlyErrors);
  });

  Spreedly.on("paymentMethod", (token: string, cardData: any) => {
    if(hasPaymentMethodCbTriggered) { return }
    hasPaymentMethodCbTriggered = true;
    callback(cardData, null)
  });

  Spreedly.tokenizeCreditCard({
    full_name: data.cardholderName,
    month: data.expiryMonth,
    year: data.expiryYear,
    address1: billingAddress.addressLine1,
    address2: billingAddress.addressLine2,
    city: billingAddress.city,
    state: billingAddress.state,
    zip: billingAddress.postCode,
    country: billingAddress.country
  });
};

const PaymentDetailsForm: React.FC<IPaymentDetailsFormProps> = (props) => {
  const {
    formData: { cardholderName, expiryMonth, expiryYear },
    disabled = false,
    formErrors,
    onChangeCardholderName,
    onChangeExpiryMonth,
    onChangeExpiryYear,
    cardNumberId,
    cvvId,
    initialCardNumber,
    initialCvv,
    initialExpiryMonth, 
    initialExpiryYear,
    initialCardholderName
  } = props;

  useEffect(() => {
    const Spreedly = (window as any).Spreedly;

    Spreedly.init(ConfigConstants.spreedlyKey, {
      numberEl: cardNumberId,
      cvvEl: cvvId
    });

    Spreedly.on("ready", function() {
      Spreedly.setPlaceholder("number","xxxx xxxx xxxx xxxx");
      Spreedly.setPlaceholder("cvv", "xxx");
      Spreedly.setFieldType("number", "text");
      Spreedly.setNumberFormat("prettyFormat");

      if(initialCardNumber) {
        Spreedly.setValue("number", initialCardNumber);
      }

      if(initialCvv) {
        Spreedly.setValue("cvv", initialCvv)
      }
    });
  }, []);

  useEffect(() => {
    initialExpiryYear && onChangeExpiryYear(initialExpiryYear);
    initialExpiryMonth && onChangeExpiryMonth(initialExpiryMonth);
    initialCardholderName && onChangeCardholderName(initialCardholderName)
  }, [initialExpiryMonth, initialExpiryYear, initialCardholderName])

  const cardNumberHasError = formErrors && formErrors.cardNumber ? "payment-details-form__spreedly-input--error" : "";
  const expiryMonthHasError = formErrors && formErrors.expiryMonth;
  const expiryYearHasError = formErrors && formErrors.expiryYear;


  return (
    <div className="payment-details-form">
      <FormGroup>
        <Row align="center">
          <Col md={2}>
            <FormLabel target="cardholder-name" noMargin content="PAYMENT_DETAILS_FORM_CARDHOLDER_NAME" />
          </Col>
          <Col md={10}>
            <Input
              id="cardholder-name"
              required
              disabled={!!initialCardholderName}
              value={cardholderName}
              showDisabledIcon={true}
              placeholder={`${counterpart("PAYMENT_DETAILS_FORM_FIRST_NAME_PLACEHOLDER")} ${counterpart("PAYMENT_DETAILS_FORM_LAST_NAME_PLACEHOLDER")}`}
              onChange={(evt: any) => onChangeCardholderName(evt.target.value)}
            />
          </Col>
        </Row>
      </FormGroup>
      <FormGroup>
        <Row align="center">
          <Col md={2}>
            <FormLabel noMargin target="card-number" content="PAYMENT_DETAILS_FORM_CARD_NUMBER" />
          </Col>
          <Col md={3}>
            <div className={`cc-input__field payment-details-form__spreedly-input ${initialCardNumber ? "payment-details-form__spreedly-input--disabled" : ""} ${cardNumberHasError} `} id={cardNumberId}></div>
          </Col>
          <Col md={0.8}>
            <FormLabel noMargin target="card-cvv" content="PAYMENT_DETAILS_FORM_CARD_CVV" />
          </Col>
          <Col md={1.5}>
            <div className={`cc-input__field payment-details-form__spreedly-input ${initialCvv ? "payment-details-form__spreedly-input--disabled" : ""}`} id={cvvId}></div>
          </Col>
          <Col md={1.8}>
            <FormLabel noMargin target="expiration-date-mm" content="PAYMENT_DETAILS_FORM_EXPIRATION_DATE" />
          </Col>
          <Col md={1.25}>
            <Input
              id="expiration-date-mm"
              isError={!!expiryMonthHasError}
              warninglayout={!!expiryMonthHasError}
              required
              disabled={!!initialExpiryMonth}
              maxLength={2}
              showDisabledIcon={true}
              value={initialExpiryMonth ? initialExpiryMonth : expiryMonth === 0 ? "" : expiryMonth}
              placeholder={counterpart("ADD_CUSTOMER_EXPIRATION_DATE_MM")}
              onChange={(evt: any) =>  +evt.target.value <= 12 && onChangeExpiryMonth(evt.target.value)}
            />
          </Col>
          <Col md={1.6}>
            <Input
              id="expiration-date-yy"
              isError={!!expiryYearHasError}
              warninglayout={!expiryYearHasError}
              required
              disabled={!!initialExpiryYear}
              maxLength={4}
              showDisabledIcon={true}
              value={initialExpiryYear ? initialExpiryYear : expiryYear === 0 ? "": expiryYear}
              placeholder={counterpart("ADD_CUSTOMER_EXPIRATION_DATE_YY")}
              onChange={(evt: any) => +evt.target.value < 3000 && onChangeExpiryYear(evt.target.value)}
            />
          </Col>
        </Row>
      </FormGroup>
    </div>
  );
}

export default PaymentDetailsForm;
