import { BillsbyAction } from "../models/BillsbyAction";
import { AppState } from "..";
import { FETCH_INVOICES_CREDIT_NOTES_CONFIG_SUCCESS, UPDATE_SUPPORT_CONTACT_DETAILS_SUCCESS, SET_INVOICES_CREDIT_NOTES_CUSTOMIZATION_FIELD, RESET_CONTACT_DETAILS_INVOICE, RESET_INVOICES_CREDIT_NOTES_FOOTER, RESET_INVOICES_CREDIT_NOTES_SUPPORT, UPDATE_INVOICES_CREDIT_NOTES_CONFIG_SUCCESS, FETCH_INVOICES_CREDIT_NOTES_FOOTER_SUCCESS, FETCH_INVOICE_CONFIG_SUCCESS, UPDATE_INVOICE_CONFIG_SUCCESS } from "../actions/invoicesCreditNotesCustomizationActions";
import { InvoiceConfigType } from "../models/Invoices";
import { GetInvoiceAddressConfigResponse, GetInvoiceConfigResponse, UpsertInvoiceConfigResponse } from "../utils/grpc/generated/Billsby.Protos/billing/private/companyconfig/company_config_pb";
import { IDropdownOptions } from "../models/Configuration";

export interface IFooterContent {
  companyName: string,
  addressLine1: string,
  addressLine2: string,
  addressCity: string,
  addressPostCode: string,
  addressState: IDropdownOptions,
  addressCountry: IDropdownOptions,
  emailAddress: string,
  phoneNumberDialCountry: string,
  phoneNumberDialCode: string,
  phoneNumber: string,
}

export interface ISupportContactDetails {
  invoiceType: InvoiceConfigType,
  heading: string,
  content: string,
  supportPageUrl: string,
  supportPageUrlButtonText: string,
  isAdvertEnabled: boolean,
  advertImageUrl: string,
  advertLinkToUrl: string
}

export interface IInvoicesCreditNotesCustomizationState {
  footerContents: IFooterContent,
  supportContactDetails: Array<ISupportContactDetails>
  invoiceTitle: string
}

export let footerContentsInitialState = {
  companyName: "",
  addressLine1: "",
  addressLine2: "",
  addressCity: "",
  addressPostCode: "",
  addressState: { label: "", value: "" },
  addressCountry: { label: "", value: "" },
  emailAddress: "",
  phoneNumberDialCountry: "",
  phoneNumberDialCode: "",
  phoneNumber: "",
}

export let supportContactDetailsInitialState = [{
  invoiceType: InvoiceConfigType.PAID_INVOICE,
  heading: "",
  content: "",
  supportPageUrl: "",
  supportPageUrlButtonText: "Visit our portal",
  isAdvertEnabled: false,
  advertImageUrl: "",
  advertLinkToUrl: ""
}]

export let invoiceCreditNoteCustomizationInitState: IInvoicesCreditNotesCustomizationState = {
  footerContents: footerContentsInitialState,
  supportContactDetails: supportContactDetailsInitialState,
  invoiceTitle: ""
}

export default function invoicesCreditNotesCustomizationReducer(state = invoiceCreditNoteCustomizationInitState, action: BillsbyAction, store: AppState) {
  switch (action.type) {

    case FETCH_INVOICES_CREDIT_NOTES_CONFIG_SUCCESS: {
      const { footerContent, invoiceConfigItems } = action.response;

      if (footerContent) {
        const {
          companyName,
          addressLine1,
          addressLine2,
          city,
          state,
          countryIso3,
          postCode,
          emailAddress,
          phoneNumberDialCountry,
          phoneNumberDialCode,
          phoneNumber
        } = footerContent;

        footerContentsInitialState = {
          companyName,
          addressLine1,
          addressLine2,
          addressCity: city,
          addressPostCode: postCode,
          addressState: { label: "", value: state },
          addressCountry: { label: "", value: countryIso3 },
          emailAddress,
          phoneNumberDialCountry,
          phoneNumberDialCode,
          phoneNumber
        }
      }

      if (invoiceConfigItems) {
        supportContactDetailsInitialState = invoiceConfigItems;
        supportContactDetailsInitialState.forEach(((item: ISupportContactDetails) => { item.supportPageUrlButtonText = item.supportPageUrlButtonText ? item.supportPageUrlButtonText : "Visit our support portal " }))
      }

      return { ...state, footerContents: footerContentsInitialState, supportContactDetails: supportContactDetailsInitialState }
    }

    case FETCH_INVOICES_CREDIT_NOTES_FOOTER_SUCCESS: {
      const footerContent = action.response as GetInvoiceAddressConfigResponse;
      footerContentsInitialState = {
        companyName: footerContent.getCompanyName(),
        addressLine1: footerContent.getAddressLine1(),
        addressLine2: footerContent.getAddressLine2(),
        addressCity: footerContent.getCity(),
        addressPostCode: footerContent.getPostCode(),
        addressState: { label: "", value: footerContent.getState() },
        addressCountry: { label: "", value: footerContent.getCountryIso3() },
        emailAddress: footerContent.getEmailAddress(),
        phoneNumberDialCountry: footerContent.getPhoneNumberDialCountry(),
        phoneNumberDialCode: footerContent.getPhoneNumberDialCode(),
        phoneNumber: footerContent.getPhoneNumber()
      }
      return { ...state, footerContents: footerContentsInitialState }
    }


    case UPDATE_INVOICES_CREDIT_NOTES_CONFIG_SUCCESS: {
      const { footerContent } = action.response;

      if (footerContent) {
        const {
          companyName,
          addressLine1,
          addressLine2,
          city,
          state,
          countryIso3,
          postCode,
          emailAddress,
          phoneNumberDialCountry,
          phoneNumberDialCode,
          phoneNumber
        } = footerContent;

        footerContentsInitialState = {
          companyName,
          addressLine1,
          addressLine2,
          addressCity: city,
          addressPostCode: postCode,
          addressState: { label: "", value: state },
          addressCountry: { label: "", value: countryIso3 },
          emailAddress,
          phoneNumberDialCountry,
          phoneNumberDialCode,
          phoneNumber
        }
      }

      return { ...state, footerContent: footerContentsInitialState }
    }

    case UPDATE_SUPPORT_CONTACT_DETAILS_SUCCESS: {
      const { invoiceConfigItems } = action.response;

      if (invoiceConfigItems) {
        supportContactDetailsInitialState = invoiceConfigItems;
        supportContactDetailsInitialState.forEach(((item: ISupportContactDetails) => { item.supportPageUrlButtonText = item.supportPageUrlButtonText ? item.supportPageUrlButtonText : "Visit our support portal " }))
      }

      return { ...state, footerContents: footerContentsInitialState, supportContactDetails: supportContactDetailsInitialState }
    }

    case FETCH_INVOICE_CONFIG_SUCCESS: {
      const invoiceConfig = action.response as GetInvoiceConfigResponse;
      invoiceCreditNoteCustomizationInitState = { ...invoiceCreditNoteCustomizationInitState, invoiceTitle: invoiceConfig.getTitle() }

      return { ...state, invoiceTitle: invoiceConfig.getTitle() }
    }

    case UPDATE_INVOICE_CONFIG_SUCCESS: {
      const invoiceConfig = action.response as UpsertInvoiceConfigResponse;
      invoiceCreditNoteCustomizationInitState = { ...invoiceCreditNoteCustomizationInitState, invoiceTitle: invoiceConfig.getTitle() }

      return { ...state, invoiceTitle: invoiceConfig.getTitle() }
    }

    case SET_INVOICES_CREDIT_NOTES_CUSTOMIZATION_FIELD: {
      return { ...state, [action.fieldName]: action.fieldValue }
    }


    case RESET_CONTACT_DETAILS_INVOICE: {
      return { ...state, supportContactDetails: state.supportContactDetails.map((details, i) => (details.invoiceType === action.payload ? supportContactDetailsInitialState[i] : details)) }
    }

    case RESET_INVOICES_CREDIT_NOTES_FOOTER: {
      return { ...state, footerContents: footerContentsInitialState }
    }

    case RESET_INVOICES_CREDIT_NOTES_SUPPORT: {
      return { ...state, supportContactDetails: supportContactDetailsInitialState }
    }

    default:
      return state
  }
}