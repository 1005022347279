import React, { useState } from "react";
import Text from "../../../components/ui/text/Text";
import Table from "../../../components/ui/table/Table";
import { Discount, DeductionType } from "../../../utils/grpc/generated/Billsby.Protos/core/private/discounts/discounts_pb";

interface IDiscountMonetariesTable {
  data: Discount
}

const DiscountMonetariesTable: React.FC<IDiscountMonetariesTable> = ({ data }) => {

  const getMonetaries = () => {
    if (!data.getMonetary())
      return null;
    return data.getMonetary() as Discount.MonetaryModel;
  }

  const [showAll, setShowAll] = useState(false);

  const monetaries = getMonetaries();
  if (!monetaries)
    return null;

  return (
    <>
      <Table className="discount-list__inner-table">
        <thead>
          <tr>
            <Text component="th" content="DISCOUNTS_LIST_DISCOUNT_TYPE_MONETARY_CURRENCY" noMargin />
            <Text component="th" content="DISCOUNTS_LIST_DISCOUNT_TYPE_MONETARY_AMOUNT" noMargin />
            <Text component="th" content="DISCOUNTS_LIST_DISCOUNT_TYPE_MONETARY_TYPE" noMargin />
          </tr>
        </thead>
        <tbody>
          {monetaries.getPricesList().map((monetary, idx) => {
            if ((!showAll && idx > 4)) {
              return null;
            }
            return (<tr key={idx} className={monetary.hasValue() ? "" : "discount-list__table__row-error"}>
              <td className="cc-text mb0 ">
                {!monetary.hasValue() && <i className="fas fa-map-marker-exclamation discount-list__table__currency-error"></i>}
                <p>{monetary.getCurrencyCode()}</p>
              </td>
              <Text component="td" shouldTranslate={false} content={monetary.getValueFormatted()} noMargin />
              <Text component="td" content={data.getDeductionType() === DeductionType.ENTIRE_SUBSCRIPTION ?
                "DISCOUNTS_LIST_DISCOUNT_TYPE_MONETARY_TYPE_ENTIRE_SUBSCRIPTION" : "DISCOUNTS_LIST_DISCOUNT_TYPE_MONETARY_TYPE_PLAN_ONLY"} noMargin />
            </tr>
            );
          })}
        </tbody>
      </Table>
      {monetaries.getPricesList().length > 5 && !showAll && <div className="discount-list__show-all__container">
        <Text className="discount-list__show-all"
          onClick={() => setShowAll(true)}
          content="DISCOUNTS_LIST_LOAD_ALL_CURRENCIES"
          noMargin />
      </div>}
    </>
  );
}

export default DiscountMonetariesTable;
