import React from "react";
import { Col, Row } from "react-grid-system";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../../../../..";
import { setCompanyBrandingFields } from "../../../../../actions/companyBrandingActions";
import Dropdown from "../../../../ui/dropdown/Dropdown";
import FormGroup from "../../../../ui/form-group/FormGroup";
import FormLabel from "../../../../ui/form-label/FormLabel";
import Panel from "../../../../ui/panel/Panel";
import Switch from "../../../../ui/switch/Switch";
import Text from "../../../../ui/text/Text";
import "./TypographyCustomization.scss";

const TypographyCustomization: React.FC = () => {
  const companyBrandingReducer = useSelector((state: AppState) => state.companyBrandingReducer);

  const dispatch = useDispatch<Function>();

  const { fontOptions, typographyHeaderIsAllCaps, typographyHeader, typographyBody } = companyBrandingReducer;

  return (
    <Panel title="BRAND_CUSTOMIZATION_CHECKOUT_TYPOGRAPHY" className="typography-customization">
      <FormGroup>
        <Row align="center">
          <Col md={1}><FormLabel noMargin target="font-header" content="BRAND_CUSTOMISATION_TYPOGRAPHY_HEADER"></FormLabel></Col>
          <Col md={9}><Dropdown value={typographyHeader} id="font-header" options={fontOptions} onChange={(selectedFont: any) => {
            dispatch(setCompanyBrandingFields("typographyHeader", selectedFont))
          }}></Dropdown></Col>
          <Col md={2} className="typography-customization__all-caps">
            <Switch id="font-header" checked={typographyHeaderIsAllCaps} onChange={() => {
              dispatch(setCompanyBrandingFields("typographyHeaderIsAllCaps", !typographyHeaderIsAllCaps))
            }}></Switch>
            <Text content="BRAND_CUSTOMISATION_TYPOGRAPHY_ALL_CAPS" noMargin></Text>
          </Col>
        </Row>
      </FormGroup>

      <Row align="center">
        <Col md={1}><FormLabel noMargin target="font-body" content="BRAND_CUSTOMISATION_TYPOGRAPHY_BODY"></FormLabel></Col>
        <Col md={11}><Dropdown value={typographyBody} id="font-body" options={fontOptions} onChange={(selectedFont: any) => {
          dispatch(setCompanyBrandingFields("typographyBody", selectedFont))
        }}></Dropdown></Col>
      </Row>
    </Panel>
  )
}

export default TypographyCustomization;