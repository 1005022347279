import React, { useState } from "react"
import { setPaymentGatewaysField, fetchPaymentGateways, cancelLastPaymentGateway } from "../../../../../actions/paymentGatewaysActions";
import { ConfigConstants } from "../../../../../utils/config";
import { IPaymentGatewayElement } from "../../../../../models/Configuration";
import { grpcUnaryCall } from "../../../../../utils/grpc/grpcUtils";
import { UpdatePaymentCloudRequest } from "../../../../../utils/grpc/generated/Billsby.Protos/billing/private/paymentgateway/payment_gateway_pb";
import { PaymentGatewayServiceClient } from "../../../../../utils/grpc/generated/Billsby.Protos/billing/private/paymentgateway/Payment_gatewayServiceClientPb";
import PaymentCloudGatewayForm from "./PaymentCloudGatewayForm";
import PaymentGatewayContainer from "../payment-gateways-shared-components/PaymentGatewayContainer";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../../../../..";
import { IAuthReducerState } from "../../../../../reducers/authReducer";

interface Props {
  paymentGateway: IPaymentGatewayElement,
  isOneGatewayPending: boolean,
  currentGatewayState: any,
  idx: number,
  isCreatingGateway: boolean,
  onError: () => void
}

interface IData {
  apiLoginId: string
}

const PaymentCloudGatewayUpdate: React.FC<Props> = ({ onError, paymentGateway, isOneGatewayPending, currentGatewayState, idx, isCreatingGateway }) => {

  const { currentCompanyDomain } = useSelector<AppState, IAuthReducerState>(state => state.auth)
  const dispatch = useDispatch<Function>()
  const [helpTitle, setHelpTitle] = useState("PAYMENT_GATEWAYS_PAYMENTCLOUD_HELP_TITLE");
  const [helpContent, setHelpContent] = useState(["PAYMENT_GATEWAYS_PAYMENTCLOUD_HELP_CONTENT_1", "PAYMENT_GATEWAYS_PAYMENTCLOUD_HELP_CONTENT_2"]);

  const cancelChanges = () => {
    paymentGateway.mustActivate ? dispatch(cancelLastPaymentGateway())
      : dispatch(setPaymentGatewaysField(`paymentGateway${idx}`, { ...currentGatewayState, displayName: paymentGateway.title, isCollapsed: true }))
  }

  const updatePaymentGateway = async (displayName: string, apiLogin: string, password: string) => {
    const updatePaymentCloudRequest = new UpdatePaymentCloudRequest();
    updatePaymentCloudRequest.setCompanyDomain(currentCompanyDomain);
    updatePaymentCloudRequest.setDisplayName(displayName);
    updatePaymentCloudRequest.setApiLoginId(apiLogin);
    updatePaymentCloudRequest.setTransactionKey(password);
    updatePaymentCloudRequest.setPaymentGatewayId(paymentGateway.paymentGatewayId as number);
    const paymentGatewayServiceClient = new PaymentGatewayServiceClient(ConfigConstants.grpcBaseUrl);
    try {
      await grpcUnaryCall(updatePaymentCloudRequest, paymentGatewayServiceClient, paymentGatewayServiceClient.updatePaymentCloudGateway)
      await dispatch(fetchPaymentGateways(currentCompanyDomain));
      dispatch(setPaymentGatewaysField(`paymentGateway${idx}`, { ...currentGatewayState, isCollapsed: true }));
    } catch {
      onError();
    }
  }

  return (
    <PaymentGatewayContainer
      helpTitle={helpTitle}
      helpContent={helpContent}
      description="PAYMENT_GATEWAYS_PAYMENTCLOUD_DESCRIPTION"
      isOneGatewayPending={isOneGatewayPending}
      currentGatewayState={currentGatewayState}
      onClick={() => dispatch(setPaymentGatewaysField(`paymentGateway${idx}`, { ...currentGatewayState, isCollapsed: paymentGateway.mustActivate ? false : !currentGatewayState.isCollapsed }))}
      websiteLink="https://paymentcloudinc.com/billsby/"
      type="edit"
      paymentGateway={paymentGateway}
    >
      <PaymentCloudGatewayForm
        onSave={(displayName: string, apiLogin: string, password: string) => updatePaymentGateway(displayName, apiLogin, password)}
        onCancel={() => cancelChanges()}
        setHelpTitle={(text: string) => setHelpTitle(text)}
        setHelpContent={(text: Array<string>) => setHelpContent(text)}
        initDisplayName={paymentGateway.title}
        initApiLogin=""
        isSaveEnabled={!isCreatingGateway}
        isEdit={true}
      />
    </PaymentGatewayContainer>
  )
}

export default PaymentCloudGatewayUpdate
