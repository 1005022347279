/**
 * @fileoverview gRPC-Web generated client stub for core.private.addons
 * @enhanceable
 * @public
 */

// GENERATED CODE -- DO NOT EDIT!


/* eslint-disable */
// @ts-nocheck


import * as grpcWeb from 'grpc-web';

import * as addons_pb from './addons_pb';


export class AddOnsServiceClient {
  client_: grpcWeb.AbstractClientBase;
  hostname_: string;
  credentials_: null | { [index: string]: string; };
  options_: null | { [index: string]: any; };

  constructor (hostname: string,
               credentials?: null | { [index: string]: string; },
               options?: null | { [index: string]: any; }) {
    if (!options) options = {};
    if (!credentials) credentials = {};
    options['format'] = 'text';

    this.client_ = new grpcWeb.GrpcWebClientBase(options);
    this.hostname_ = hostname;
    this.credentials_ = credentials;
    this.options_ = options;
  }

  methodInfoGetPlanCurrencies = new grpcWeb.AbstractClientBase.MethodInfo(
    addons_pb.GetPlanCurrenciesResponse,
    (request: addons_pb.GetPlanCurrenciesRequest) => {
      return request.serializeBinary();
    },
    addons_pb.GetPlanCurrenciesResponse.deserializeBinary
  );

  getPlanCurrencies(
    request: addons_pb.GetPlanCurrenciesRequest,
    metadata: grpcWeb.Metadata | null): Promise<addons_pb.GetPlanCurrenciesResponse>;

  getPlanCurrencies(
    request: addons_pb.GetPlanCurrenciesRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: addons_pb.GetPlanCurrenciesResponse) => void): grpcWeb.ClientReadableStream<addons_pb.GetPlanCurrenciesResponse>;

  getPlanCurrencies(
    request: addons_pb.GetPlanCurrenciesRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.Error,
               response: addons_pb.GetPlanCurrenciesResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/core.private.addons.AddOnsService/GetPlanCurrencies',
        request,
        metadata || {},
        this.methodInfoGetPlanCurrencies,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/core.private.addons.AddOnsService/GetPlanCurrencies',
    request,
    metadata || {},
    this.methodInfoGetPlanCurrencies);
  }

  methodInfoGetCurrencyPriceModels = new grpcWeb.AbstractClientBase.MethodInfo(
    addons_pb.GetCurrencyPriceModelsResponse,
    (request: addons_pb.GetCurrencyPriceModelsRequest) => {
      return request.serializeBinary();
    },
    addons_pb.GetCurrencyPriceModelsResponse.deserializeBinary
  );

  getCurrencyPriceModels(
    request: addons_pb.GetCurrencyPriceModelsRequest,
    metadata: grpcWeb.Metadata | null): Promise<addons_pb.GetCurrencyPriceModelsResponse>;

  getCurrencyPriceModels(
    request: addons_pb.GetCurrencyPriceModelsRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: addons_pb.GetCurrencyPriceModelsResponse) => void): grpcWeb.ClientReadableStream<addons_pb.GetCurrencyPriceModelsResponse>;

  getCurrencyPriceModels(
    request: addons_pb.GetCurrencyPriceModelsRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.Error,
               response: addons_pb.GetCurrencyPriceModelsResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/core.private.addons.AddOnsService/GetCurrencyPriceModels',
        request,
        metadata || {},
        this.methodInfoGetCurrencyPriceModels,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/core.private.addons.AddOnsService/GetCurrencyPriceModels',
    request,
    metadata || {},
    this.methodInfoGetCurrencyPriceModels);
  }

  methodInfoGetAddons = new grpcWeb.AbstractClientBase.MethodInfo(
    addons_pb.GetAddonsResponse,
    (request: addons_pb.GetAddonsRequest) => {
      return request.serializeBinary();
    },
    addons_pb.GetAddonsResponse.deserializeBinary
  );

  getAddons(
    request: addons_pb.GetAddonsRequest,
    metadata: grpcWeb.Metadata | null): Promise<addons_pb.GetAddonsResponse>;

  getAddons(
    request: addons_pb.GetAddonsRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: addons_pb.GetAddonsResponse) => void): grpcWeb.ClientReadableStream<addons_pb.GetAddonsResponse>;

  getAddons(
    request: addons_pb.GetAddonsRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.Error,
               response: addons_pb.GetAddonsResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/core.private.addons.AddOnsService/GetAddons',
        request,
        metadata || {},
        this.methodInfoGetAddons,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/core.private.addons.AddOnsService/GetAddons',
    request,
    metadata || {},
    this.methodInfoGetAddons);
  }

  methodInfoCreateAddOn = new grpcWeb.AbstractClientBase.MethodInfo(
    addons_pb.CreateAddOnResponse,
    (request: addons_pb.CreateAddOnRequest) => {
      return request.serializeBinary();
    },
    addons_pb.CreateAddOnResponse.deserializeBinary
  );

  createAddOn(
    request: addons_pb.CreateAddOnRequest,
    metadata: grpcWeb.Metadata | null): Promise<addons_pb.CreateAddOnResponse>;

  createAddOn(
    request: addons_pb.CreateAddOnRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: addons_pb.CreateAddOnResponse) => void): grpcWeb.ClientReadableStream<addons_pb.CreateAddOnResponse>;

  createAddOn(
    request: addons_pb.CreateAddOnRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.Error,
               response: addons_pb.CreateAddOnResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/core.private.addons.AddOnsService/CreateAddOn',
        request,
        metadata || {},
        this.methodInfoCreateAddOn,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/core.private.addons.AddOnsService/CreateAddOn',
    request,
    metadata || {},
    this.methodInfoCreateAddOn);
  }

  methodInfoGetAddon = new grpcWeb.AbstractClientBase.MethodInfo(
    addons_pb.GetAddonResponse,
    (request: addons_pb.GetAddonRequest) => {
      return request.serializeBinary();
    },
    addons_pb.GetAddonResponse.deserializeBinary
  );

  getAddon(
    request: addons_pb.GetAddonRequest,
    metadata: grpcWeb.Metadata | null): Promise<addons_pb.GetAddonResponse>;

  getAddon(
    request: addons_pb.GetAddonRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: addons_pb.GetAddonResponse) => void): grpcWeb.ClientReadableStream<addons_pb.GetAddonResponse>;

  getAddon(
    request: addons_pb.GetAddonRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.Error,
               response: addons_pb.GetAddonResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/core.private.addons.AddOnsService/GetAddon',
        request,
        metadata || {},
        this.methodInfoGetAddon,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/core.private.addons.AddOnsService/GetAddon',
    request,
    metadata || {},
    this.methodInfoGetAddon);
  }

  methodInfoUpdateAddon = new grpcWeb.AbstractClientBase.MethodInfo(
    addons_pb.UpdateAddonResponse,
    (request: addons_pb.UpdateAddonRequest) => {
      return request.serializeBinary();
    },
    addons_pb.UpdateAddonResponse.deserializeBinary
  );

  updateAddon(
    request: addons_pb.UpdateAddonRequest,
    metadata: grpcWeb.Metadata | null): Promise<addons_pb.UpdateAddonResponse>;

  updateAddon(
    request: addons_pb.UpdateAddonRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: addons_pb.UpdateAddonResponse) => void): grpcWeb.ClientReadableStream<addons_pb.UpdateAddonResponse>;

  updateAddon(
    request: addons_pb.UpdateAddonRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.Error,
               response: addons_pb.UpdateAddonResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/core.private.addons.AddOnsService/UpdateAddon',
        request,
        metadata || {},
        this.methodInfoUpdateAddon,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/core.private.addons.AddOnsService/UpdateAddon',
    request,
    metadata || {},
    this.methodInfoUpdateAddon);
  }

  methodInfoDeleteAddon = new grpcWeb.AbstractClientBase.MethodInfo(
    addons_pb.DeleteAddonResponse,
    (request: addons_pb.DeleteAddonRequest) => {
      return request.serializeBinary();
    },
    addons_pb.DeleteAddonResponse.deserializeBinary
  );

  deleteAddon(
    request: addons_pb.DeleteAddonRequest,
    metadata: grpcWeb.Metadata | null): Promise<addons_pb.DeleteAddonResponse>;

  deleteAddon(
    request: addons_pb.DeleteAddonRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: addons_pb.DeleteAddonResponse) => void): grpcWeb.ClientReadableStream<addons_pb.DeleteAddonResponse>;

  deleteAddon(
    request: addons_pb.DeleteAddonRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.Error,
               response: addons_pb.DeleteAddonResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/core.private.addons.AddOnsService/DeleteAddon',
        request,
        metadata || {},
        this.methodInfoDeleteAddon,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/core.private.addons.AddOnsService/DeleteAddon',
    request,
    metadata || {},
    this.methodInfoDeleteAddon);
  }

}

