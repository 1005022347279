import React, { FormEvent, useState, useEffect } from "react";
import { Row, Col } from "react-grid-system";
import { useDispatch, useSelector } from "react-redux";
import Panel from "../ui/panel/Panel";
import Text from "../ui/text/Text";
import Button from "../ui/button/Button";
import FormGroup from "../../components/ui/form-group/FormGroup";
import Input from "../../components/ui/input/Input";
import FormLabel from "../../components/ui/form-label/FormLabel";
import NoticePanel from "../../components/ui/notice-panel/NoticePanel";
import API from "../../utils/API";
import { AppState } from "../..";
import { fetchInvoiceDetails } from "../../actions/invoiceDetailsActions";
import "./Refund.scss";
import { InvoiceRefundType } from "../../models/Invoices";

interface IRefundProps {
  refundData: { customerUniqueId: string, invoiceNumber: string },
  getInvoiceDetails?: boolean,
  handleRefundCallback?: Function
}

const Refund: React.FC<IRefundProps> = ({ refundData, handleRefundCallback, getInvoiceDetails = false }) => {
  const dispatch = useDispatch();
  const { auth: { currentCompanyDomain }, invoiceData } = useSelector((state: AppState) => ({ auth: state.auth, invoiceData: state.invoiceDetailsReducer }))
  
  const [status, setStatus] = useState({ isLoading: false, isLoadingData: false });
  const [notice, setNotice] = useState({ refundHasWarning: false, refundHasError: false });
  const [currentAmount, setCurrentAmount] = useState(0);

  const { customerUniqueId, invoiceNumber } = refundData;
  const { invoiceDetails: { refundableAmount, amount, refundType } } = invoiceData;
  const amountExceeded = Number(currentAmount) > Number(refundableAmount);
  const isRefundable = refundType === InvoiceRefundType.AVAILABLE;

  const fetchData = async () => {
    setStatus({ ...status, isLoadingData: true });
    try {
      await dispatch(fetchInvoiceDetails(invoiceNumber));
      setStatus({ ...status, isLoadingData: false });
    } catch (err) {
      setStatus({ ...status, isLoadingData: false });

      console.log(err);
    }
  };

  useEffect(() => {
    if (currentAmount === 0 && getInvoiceDetails) {
      fetchData();
    }

    setNotice({ ...notice, refundHasWarning: isRefundable && Number(amount) !== Number(refundableAmount) });
    setCurrentAmount(refundableAmount);
  }, [refundableAmount, isRefundable]);

  const onSubmit = async (evt: FormEvent) => {
    evt.preventDefault();
    evt.stopPropagation();

    if(!isRefundable) {
      return;
    }

    setStatus({ ...status, isLoading: true });

    try {
      await (API.refundCustomerInvoice(currentCompanyDomain, customerUniqueId, { invoiceNumber, amount: Number((currentAmount * 100).toFixed(2)) }));
      await dispatch(fetchInvoiceDetails(invoiceNumber));
      setStatus({ ...status, isLoading: false });

      handleRefundCallback && handleRefundCallback();
    } catch (err) {
      setStatus({ ...status, isLoading: false });
      setNotice({ ...notice, refundHasError: true })
    }
  };

  const renderNoticePanel = () => {
    if (notice.refundHasError || notice.refundHasWarning || !isRefundable) {
      return (
        <NoticePanel
          type={(notice.refundHasError || !isRefundable) ? "error" : "warning"}
          translateWith={{ refundableValue: refundableAmount }}
          isModal
          content={`REFUND_INVOICE_${notice.refundHasWarning ? "WARNING" : !isRefundable ? "GATEWAY" : "ERROR"}_NOTICE`}
        />
      );
    } else return null;
  };

  if (status.isLoadingData) {
    return null;
  }

  return (
    <>
      {renderNoticePanel()}
      <form className="refund" onSubmit={onSubmit}>
        <Panel className="refund__title__panel" title="REFUND_INVOICE_PANEL1_TITLE">
          <Text className="refund__title__panel__text" content="REFUND_INVOICE_PANEL1_CONTENT" noMargin />
        </Panel>

        <Panel>
          <FormGroup style={{ marginBottom: "0px" }}>
            <Row align="center">
              <Col md={2}>
                <FormLabel target="refund-amount" content="REFUND_INVOICE_AMOUNT" noMargin />
              </Col>
              <Col md={10}>
                <Input
                  required
                  id="refund-amount"
                  placeholder="10.00"
                  step="0.01"
                  type="number"
                  value={currentAmount === 0 ? "" : currentAmount}
                  onChange={(evt: any) => {
                    setCurrentAmount(evt.target.value);
                  }}
                  errorIcon=""
                  isError={amountExceeded}
                  warninglayout={amountExceeded}
                />
              </Col>
            </Row>
          </FormGroup>
        </Panel>

        <Button
          id="refund-invoice"
          type="submit"
          disabled={amountExceeded || !currentAmount || !isRefundable}
          buttonType={"general"}
          isLoading={status.isLoading}
          title={"REFUND_INVOICE"}
          isFullWidth
        />
      </form>
    </>
  );
};

export default Refund;
