import React, { useState, ReactElement, useMemo, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../..";
import "./Container.scss";
import Helmet from "react-helmet";
import Header from "../header/Header";
import { Switch } from "react-router-dom";
import history from "../../utils/history";
import NavigationAlert from "../../components/ui/navigation-alert/NavigationAlert";
import { RemoveTaxCouldNotBeAppliedWarningRequest, RemoveTaxCouldNotBeAppliedWarningResponse } from "../../utils/grpc/generated/Billsby.Protos/core/private/dashboards/dashboards_pb";
import { grpcUnaryCall } from "../../utils/grpc/grpcUtils";
import { DashboardsServiceClient } from "../../utils/grpc/generated/Billsby.Protos/core/private/dashboards/DashboardsServiceClientPb";
import { ConfigConstants } from "../../utils/config";
import { fetchGettingStartedSettings } from "../../actions/authActions";

interface IContainer {
  children: ReactElement | Array<ReactElement>
}

const Container: React.FC<IContainer> = ({ children }) => {
  const { auth: { selectedCompanyId, parsedToken, isFetchedCompaniesSuccess, dashboardSettings, currentCompanyDomain }, registrationData: { isDoingQuiz } } = useSelector((state: AppState) => ({ auth: state.auth, registrationData: state.registration }))
  const hasSelectedCompany = !!selectedCompanyId;
  const [isMinimised, minimiseNav] = useState(false);
  const [showTaxWarning, setShowTaxWarning] = useState(false);

  const dispatch = useDispatch<Function>();

  const removeTaxWarning = async () => {
    const request = new RemoveTaxCouldNotBeAppliedWarningRequest();
    const service = new DashboardsServiceClient(ConfigConstants.grpcBaseUrl);

    selectedCompanyId && request.setCompanyId(selectedCompanyId);

    try {
      const response = await grpcUnaryCall(request, service, service.removeTaxCouldNotBeAppliedWarning) as RemoveTaxCouldNotBeAppliedWarningResponse;
      await dispatch(fetchGettingStartedSettings(currentCompanyDomain));

      if(!response.getIsSuccess()) {
        throw new Error()
      }
    } catch (err) {
      console.log(err)
    }
  }

  useEffect(() => {
    if (dashboardSettings?.companyTaxCouldNotBeAppliedWarnings && dashboardSettings?.companyTaxCouldNotBeAppliedWarnings.length > 0) {
      setShowTaxWarning(true);
    } else setShowTaxWarning(false);
  }, [dashboardSettings])

  const discountWithCurrenyWarning = useMemo(() => {
    if (dashboardSettings) {
      if (dashboardSettings.companyDiscountCurrencyWarnings && dashboardSettings.companyDiscountCurrencyWarnings.length > 0) {
        return dashboardSettings.companyDiscountCurrencyWarnings;
      }
    }
    return null;
  }, [dashboardSettings])

  // when 'logged in' we need to wait the async call to fetch the companies to complete before rendering the dom when
  if (parsedToken && !isFetchedCompaniesSuccess) {
    return null;
  }

  if (hasSelectedCompany && !isDoingQuiz) {
    return (
      <div>
        <Helmet defaultTitle="Billsby" titleTemplate="Billsby - %s" />
        <div className={"container container__logged"}>
          <div className={isMinimised ? "container__sidebar container__sidebar--minimised" : "container__sidebar"}>
            <Header isMinimised={isMinimised} minimiseNav={minimiseNav} />
          </div>
          <div className={isMinimised ? "container__content-wrapper--minimised" : "container__content-wrapper"}>
            <div className="container__content">
              {
                showTaxWarning && (
                  <NavigationAlert
                    className="container__warning"
                    title="TAX_WARNING_NOTIF_TITLE"
                    subTitle="TAX_WARNING_NOTIF_CONTENT"
                    buttonLabel="TAX_WARNING_NOTIF_BTN"
                    icon="fal fa-times"
                    onClick={removeTaxWarning}
                  />
                )
              }
              {discountWithCurrenyWarning && discountWithCurrenyWarning.length > 0 && (
                <NavigationAlert
                  className="container__warning"
                  title="DISCOUNT_LIST_WARNING_NOTIFICATION_TITLE"
                  subTitle="DISCOUNT_LIST_WARNING_NOTIFICATION_SUB_TITLE"
                  translateSubTitleWith={{
                    currency: discountWithCurrenyWarning[0].currencyCode
                  }}
                  buttonLabel="DISCOUNT_LIST_WARNING_NOTIFICATION_BUTTON_TEXT"
                  icon="fas fa-wrench"
                  buttonDisabled={window.location.pathname === "/discount-and-coupons"}
                  onClick={() => discountWithCurrenyWarning.length > 1 ?
                    history.push("/discount-and-coupons") :
                    history.push(`/discount-and-coupons/edit/${discountWithCurrenyWarning[0].discountId}`)}
                />
              )}
              <Switch>
                {children}
              </Switch>
            </div>
          </div>
        </div>

      </div>
    )
  }

  return (
    <div>
      {Header && <Header />}
      <div className={"container container__not-logged"}>
        <div className="container__content-wrapper">
          <div className="container__content">
            <Switch>
              {children}
            </Switch>
          </div>
        </div>

      </div>
    </div>
  )
}

export default Container;