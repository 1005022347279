import * as React from "react";
import { useSelector } from "react-redux";
import Helmet from "react-helmet";
import counterpart from "counterpart";
import { Link } from "react-router-dom";
import Panel from "../../../components/ui/panel/Panel";
import Text from "../../../components/ui/text/Text";
import { Row, Col } from "react-grid-system";
import CodePanel from "../../../components/ui/code-panel/CodePanel";
import { ConfigConstants } from "../../../utils/config";
import "./EmbedCodes.scss";
import { AppState } from "../../../index";


const EmbedCodesAllowance: React.FC = () => {
  const companyDomain = useSelector((state: AppState) => state.auth.currentCompanyDomain);

  const renderBasicEmbedCodePanel = () => {
    const basicEmbedCode1 = `<script src="${ConfigConstants.checkoutLibUrl}" data-billsby-company="${companyDomain}"></script>`;
    const basicEmbedCode2 = "<a href=\"javascript:void(0)\" data-billsby-type=\"account\">Manage account</a>";

    return (
      <Panel className="ecself-service__basic" title="SETTINGS_EMBED_CODES_ADD_ON_PANEL2_TITLE">
        <Text content="SETTINGS_EMBED_CODES_ADD_ON_PANEL2_CONTENT" />

        <Text className="ecself-service__basic__subtitle" content="SETTINGS_EMBED_CODES_ADD_ON_PANEL2_HEADER1" />
        <CodePanel id="basic-copy-1" codeStr={basicEmbedCode1}>
          <span className="ecself-service__basic__code-main">{basicEmbedCode1}</span>
        </CodePanel>

        <Text className="ecself-service__basic__subtitle" content="SETTINGS_EMBED_CODES_ADD_ON_PANEL2_HEADER2" />
        <CodePanel noMargin id="basic-copy-2" codeStr={basicEmbedCode2}>
          <span className="ecself-service__basic__code-main">{basicEmbedCode2}</span>
        </CodePanel>
      </Panel>
    );
  };

  const renderIDsManageAccountPanel = () => {
    const specificCustomerCode1 = '<a href="javascript:void(0)" data-billsby-type="account" data-billsby-customer="';
    const specificCustomerId = "[Customer ID]";
    const specificCustomerCode2 = '">Manage account</a>';
    const specificSubscriptionCode1 = '" data-billsby-subscription="';
    const specificSubscriptionId = "[Subscription ID]";

    return (
      <Panel className="embed-codes__basic" title="SETTINGS_EMBED_CODES_ADD_ON_PANEL3_TITLE">
        <Text content="SETTINGS_EMBED_CODES_ADD_ON_PANEL3_CONTENT" />

        <Text className="embed-codes__basic__subtitle" content="SETTINGS_EMBED_CODES_ADD_ON_PANEL3_HEADER1" />
        <CodePanel id="basic-copy-3" codeStr={specificCustomerCode1 + specificCustomerId + specificCustomerCode2}>
          <span className="eccheckout__basic__code-main">
            {specificCustomerCode1}
            <span className="eccheckout__basic__variable">{specificCustomerId}</span>
            {specificCustomerCode2}
          </span>
        </CodePanel>
        <Text className="embed-codes__basic__subtitle" content="SETTINGS_EMBED_CODES_ADD_ON_PANEL3_HEADER2" />
        <CodePanel noMargin id="basic-copy-3x" codeStr={specificCustomerCode1 + specificCustomerId + specificSubscriptionCode1 + specificSubscriptionId + specificCustomerCode2}>
          <span className="embed-codes__basic__code-main">
            {specificCustomerCode1}
            <span className="embed-codes__basic__variable">{specificCustomerId}</span>
            {specificSubscriptionCode1}
            <span className="embed-codes__basic__variable">{specificSubscriptionId}</span>
            {specificCustomerCode2}
          </span>
        </CodePanel>
      </Panel>
    );
  };


  const renderDirectCustomersPanel = () => {
    const actionName = "\"manageallowances\"";

    const begin = '<a href="javascript:void(0)" data-billsby-type="account" data-billsby-action=';
    const end = ">Manage allowances</a>";

    return (
      <Panel className="embed-codes__basic" title="SETTINGS_EMBED_CODES_ALLOWANCE_PANEL4_TITLE">
        <Text content="SETTINGS_EMBED_CODES_ALLOWANCE_PANEL4_CONTENT" />

        <Text className="embed-codes__basic__subtitle" content="SETTINGS_EMBED_CODES_ALLOWANCE_PANEL4_HEADER1" />
        <CodePanel noMargin id="basic-copy-4" codeStr={begin + actionName + end}>
          <span className="embed-codes__basic__code-main">
            {begin}
            <span className="embed-codes__basic__variable">{actionName}</span>
            {end}
          </span>
        </CodePanel>
      </Panel>
    );
  };

    return (
      <div className="embed-codes">
        <Helmet title={`${counterpart("NAVBAR_EMBED_CODES")}-${counterpart("SETTINGS_EMBED_CODES_ALLOWANCES")}`} />
        <Panel className="embed-codes__welcome-allowance" title="SETTINGS_EMBED_CODES_ALLOWANCE_PANEL1_TITLE">
          <Row>
            <Col md={10}>
              <Text content="SETTINGS_EMBED_CODES_ALLOWANCE_PANEL1_CONTENT" component="span" />
              <Link to="/configuration/brand-customization">
                <Text content="SETTINGS_EMBED_CODES_ADD_ON_PANEL1_LINK" noMargin component="span" />
              </Link>
                <Text content="SETTINGS_EMBED_CODES_ADD_ON_PANEL1_CONTENT_1" noMargin component="span" />
            </Col>
          </Row>
        </Panel>

        {renderBasicEmbedCodePanel()}
        {renderIDsManageAccountPanel()}
        {renderDirectCustomersPanel()}
      </div>
    );
}

export default EmbedCodesAllowance;
