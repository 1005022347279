import { makeGenericActionCreator } from "../utils/reduxUtils";
import API from "../utils/API";
import { ICustomerCardDetails, ICustomerCardDetailsSCA } from "../models/Customer";
import { grpcUnaryCall } from "../utils/grpc/grpcUtils";
import { GetCustomerLanguageRequest } from "../utils/grpc/generated/Billsby.Protos/billing/public/customer/customer_pb"
import { CustomerServiceClient } from "../utils/grpc/generated/Billsby.Protos/billing/public/customer/CustomerServiceClientPb"
import { ConfigConstants } from "../utils/config";

type allowedInvoiceStandaloneTypes = "sidebarCurrentScreen" | "cardHolderName" | "expDateMM" | "expDateYYYY" | "newSpreedlyCardData";
export const SET_INVOICE_STANDALONE_FIELD = "SET_INVOICE_STANDALONE_FIELD";
export const setInvoiceStandaloneField = makeGenericActionCreator<allowedInvoiceStandaloneTypes>(SET_INVOICE_STANDALONE_FIELD);

export const FETCH_INVOICE_STANDALONE_REQUEST = "FETCH_INVOICE_STANDALONE_REQUEST";
export const FETCH_INVOICE_STANDALONE_SUCCESS = "FETCH_INVOICE_STANDALONE_SUCCESS";
export const FETCH_INVOICE_STANDALONE_FAILURE = "FETCH_INVOICE_STANDALONE_FAILURE";

export const REATTEMPT_INVOICE_PAYMENT_REQUEST = "REATTEMPT_INVOICE_PAYMENT_REQUEST";
export const REATTEMPT_INVOICE_PAYMENT_SUCCESS = "REATTEMPT_INVOICE_PAYMENT_SUCCESS";
export const REATTEMPT_INVOICE_PAYMENT_FAILURE = "REATTEMPT_INVOICE_PAYMENT_FAILURE";

export const FETCH_CUSTOMER_LANGUAGE_REQUEST = "FETCH_CUSTOMER_LANGUAGE_REQUEST";
export const FETCH_CUSTOMER_LANGUAGE_SUCCESS = "FETCH_CUSTOMER_LANGUAGE_SUCCESS";
export const FETCH_CUSTOMER_LANGUAGE_FAILURE = "FETCH_CUSTOMER_LANGUAGE_FAILURE";

export const REPLACE_PAYMENT_CARD_REQUEST = "REPLACE_PAYMENT_CARD_REQUEST";
export const REPLACE_PAYMENT_CARD_SUCCESS = "REPLACE_PAYMENT_CARD_SUCCESS";
export const REPLACE_PAYMENT_CARD_FAILURE = "REPLACE_PAYMENT_CARD_FAILURE";

export const fetchInvoiceStandalone = (invoiceCode: string) => {
  return {
    types: [FETCH_INVOICE_STANDALONE_REQUEST, FETCH_INVOICE_STANDALONE_SUCCESS, FETCH_INVOICE_STANDALONE_FAILURE],
    callAPI: () => API.fetchInvoiceStandalone(invoiceCode)
  }
}

export const reattemptInvoicePayment = (companyDomain: string, invoiceUniqueId: string) => {
  return {
    types: [REATTEMPT_INVOICE_PAYMENT_REQUEST, REATTEMPT_INVOICE_PAYMENT_SUCCESS, REATTEMPT_INVOICE_PAYMENT_FAILURE],
    callAPI: () => API.reattemptInvoicePayment(companyDomain, invoiceUniqueId)
  }
}

export const replacePaymentCard = (companyDomain: string, customerUniqueId: string, data: ICustomerCardDetails) => {
  return {
    types: [REPLACE_PAYMENT_CARD_REQUEST, REPLACE_PAYMENT_CARD_SUCCESS, REPLACE_PAYMENT_CARD_FAILURE],
    callAPI: () => API.replacePaymentCard(companyDomain, customerUniqueId, data)
  }
}

export const replacePaymentCardSCA = (companyDomain: string, customerUniqueId: string, data: ICustomerCardDetailsSCA) => {
  return {
    types: [REPLACE_PAYMENT_CARD_REQUEST, REPLACE_PAYMENT_CARD_SUCCESS, REPLACE_PAYMENT_CARD_FAILURE],
    callAPI: () => API.replacePaymentCardSCA(companyDomain, customerUniqueId, data)
  }
}

export const fetchCustomerLanguage = (companyDomain: string, customerUniqueId: string) => {
  const getCustomerLanguageRequest = new GetCustomerLanguageRequest();
  getCustomerLanguageRequest.setCompanyDomain(companyDomain);
  getCustomerLanguageRequest.setCustomerUniqueId(customerUniqueId);
  const customerServiceClient = new CustomerServiceClient(ConfigConstants.grpcBaseUrl);

  return {
    types: [FETCH_CUSTOMER_LANGUAGE_REQUEST, FETCH_CUSTOMER_LANGUAGE_SUCCESS, FETCH_CUSTOMER_LANGUAGE_FAILURE],
    callAPI: () => grpcUnaryCall(getCustomerLanguageRequest, customerServiceClient, customerServiceClient.getCustomerLanguage)
  }
}