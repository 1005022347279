import React, { ReactElement } from "react";
import Panel from "../panel/Panel";
import "./CollapsablePanel.scss";

interface ICollapsablePanel {
  /**
   * title is a JSX element, not a string, thus can include a generic dom structure, such as buttons icons etc.
   */
  title?: JSX.Element,
  isCollapsed?: boolean,
  className?: string
  children?: ReactElement,
  onClick?: (evt: any) => void
  onFocus?: (evt: any) => void,
  onBlur?: (evt: any) => void,
}

/**
 * Generic collapsable panel 
 */
const CollapsablePanel: React.FC<ICollapsablePanel> = React.memo(({ title, isCollapsed = true, className = "", children, onClick = () => { },
  onFocus = () => { }, onBlur = () => { } }) => {
  return (
    <Panel className={`cpanel ${isCollapsed ? "cpanel--collapsed" : ""}  ${className} `} onClick={onClick} onFocus={onFocus} onBlur={onBlur}>
      {
        title
          ? (
            <div className="cpanel__title">
              {title}
            </div>
          )
          : null
      }
      {children}
    </Panel>
  )
})

export default CollapsablePanel;