import React from "react";
import { Row, Col } from "react-grid-system";
import Text from "../ui/text/Text";
import Search from "../search/Search";
import { ICustomFieldsReducer } from "../../reducers/customFieldsReducer";
import { setSearchedStandardField, setStandardField } from "../../actions/customFieldsActions";
import counterpart from "counterpart";
import GenericFieldPanel from "./generic-field-panel/GenericFieldPanel";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../..";


const StandardFields: React.FC = () => {  
  const { searchedStandardField, standardFields }  = useSelector<AppState, ICustomFieldsReducer>(state => state.customFields)
  const { isAdditionalEmailRequired, isPhoneNumberRequired, isMarketingConsentRequired } = standardFields;
  const dispatch = useDispatch<Function>()
  
  return (
    <div>
      <Row>
        <Col xs={6}>
          <Text content="CUSTOM_FIELDS_MODAL_STANDARD_FIELDS" className="custom-fields__menu__title" noMargin />
          <Text content="CUSTOM_FIELDS_OPTIONAL_FIELDS" />
        </Col>
        <Col xs={6}>
          <Search placeholder="CUSTOM_FIELDS_SEARCH_PLACEHOLDER" className="custom-fields__search" value={searchedStandardField} onChange={(evt: any) => dispatch(setSearchedStandardField(evt.target.value))} />
        </Col>
      </Row>

      {
        (!searchedStandardField || counterpart("CUSTOM_FIELDS_ADDITIONAL_EMAILS").toUpperCase().indexOf(searchedStandardField.toUpperCase()) >= 0)
          ? (
            <GenericFieldPanel
              title="CUSTOM_FIELDS_ADDITIONAL_EMAILS"
              subTitle="CUSTOM_FIELDS_ADDITIONAL_EMAILS_SUBTITLE"
              addIcon={{ isChecked: isAdditionalEmailRequired, onChange: () => dispatch(setStandardField("isAdditionalEmailRequired", !isAdditionalEmailRequired)) }}
            />
          )
          : null
      }

      {
        (!searchedStandardField || counterpart("CUSTOM_FIELDS_PHONE_NUMBER").toUpperCase().indexOf(searchedStandardField.toUpperCase()) >= 0)
          ? (
            <GenericFieldPanel
              title="CUSTOM_FIELDS_PHONE_NUMBER"
              subTitle="CUSTOM_FIELDS_PHONE_NUMBER_SUBTITLE"
              addIcon={{ isChecked: isPhoneNumberRequired, onChange: () => dispatch(setStandardField("isPhoneNumberRequired", !isPhoneNumberRequired)) }}
            />
          )
          : null
      }


      {
        (!searchedStandardField || counterpart("CUSTOM_FIELDS_MARKETING_CONSENT").toUpperCase().indexOf(searchedStandardField.toUpperCase()) >= 0)
          ? (
            <GenericFieldPanel
              title="CUSTOM_FIELDS_MARKETING_CONSENT"
              subTitle="CUSTOM_FIELDS_MARKETING_CONSENT_SUBTITLE"
              addIcon={{ isChecked: isMarketingConsentRequired, onChange: () => dispatch(setStandardField("isMarketingConsentRequired", !isMarketingConsentRequired)) }}
            />
          )
          : null
      }

    </div>
  )
}

export default StandardFields;