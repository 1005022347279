/* eslint-disable */
// source: addons.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = Function('return this')();

var google_protobuf_wrappers_pb = require('google-protobuf/google/protobuf/wrappers_pb.js');
goog.object.extend(proto, google_protobuf_wrappers_pb);
goog.exportSymbol('proto.core.private.addons.AddOnPlan', null, global);
goog.exportSymbol('proto.core.private.addons.Addon', null, global);
goog.exportSymbol('proto.core.private.addons.AddonFilterType', null, global);
goog.exportSymbol('proto.core.private.addons.AddonPriceModel', null, global);
goog.exportSymbol('proto.core.private.addons.CreateAddOnRequest', null, global);
goog.exportSymbol('proto.core.private.addons.CreateAddOnResponse', null, global);
goog.exportSymbol('proto.core.private.addons.Currency', null, global);
goog.exportSymbol('proto.core.private.addons.DeleteAddonRequest', null, global);
goog.exportSymbol('proto.core.private.addons.DeleteAddonResponse', null, global);
goog.exportSymbol('proto.core.private.addons.FrequencyType', null, global);
goog.exportSymbol('proto.core.private.addons.GetAddonRequest', null, global);
goog.exportSymbol('proto.core.private.addons.GetAddonResponse', null, global);
goog.exportSymbol('proto.core.private.addons.GetAddonsRequest', null, global);
goog.exportSymbol('proto.core.private.addons.GetAddonsResponse', null, global);
goog.exportSymbol('proto.core.private.addons.GetCurrencyPriceModelsRequest', null, global);
goog.exportSymbol('proto.core.private.addons.GetCurrencyPriceModelsResponse', null, global);
goog.exportSymbol('proto.core.private.addons.GetPlanCurrenciesRequest', null, global);
goog.exportSymbol('proto.core.private.addons.GetPlanCurrenciesResponse', null, global);
goog.exportSymbol('proto.core.private.addons.Plan', null, global);
goog.exportSymbol('proto.core.private.addons.PricingModelType', null, global);
goog.exportSymbol('proto.core.private.addons.UnitTier', null, global);
goog.exportSymbol('proto.core.private.addons.UpdateAddonRequest', null, global);
goog.exportSymbol('proto.core.private.addons.UpdateAddonResponse', null, global);
goog.exportSymbol('proto.core.private.addons.UpsertAddonPlan', null, global);
goog.exportSymbol('proto.core.private.addons.UpsertAddonPriceModel', null, global);
goog.exportSymbol('proto.core.private.addons.UpsertAddonUnitTier', null, global);
goog.exportSymbol('proto.core.private.addons.VisibilityType', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.core.private.addons.GetCurrencyPriceModelsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.core.private.addons.GetCurrencyPriceModelsRequest.repeatedFields_, null);
};
goog.inherits(proto.core.private.addons.GetCurrencyPriceModelsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.core.private.addons.GetCurrencyPriceModelsRequest.displayName = 'proto.core.private.addons.GetCurrencyPriceModelsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.core.private.addons.GetCurrencyPriceModelsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.core.private.addons.GetCurrencyPriceModelsResponse.repeatedFields_, null);
};
goog.inherits(proto.core.private.addons.GetCurrencyPriceModelsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.core.private.addons.GetCurrencyPriceModelsResponse.displayName = 'proto.core.private.addons.GetCurrencyPriceModelsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.core.private.addons.GetAddonsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.core.private.addons.GetAddonsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.core.private.addons.GetAddonsRequest.displayName = 'proto.core.private.addons.GetAddonsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.core.private.addons.GetAddonsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.core.private.addons.GetAddonsResponse.repeatedFields_, null);
};
goog.inherits(proto.core.private.addons.GetAddonsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.core.private.addons.GetAddonsResponse.displayName = 'proto.core.private.addons.GetAddonsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.core.private.addons.GetPlanCurrenciesRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.core.private.addons.GetPlanCurrenciesRequest.repeatedFields_, null);
};
goog.inherits(proto.core.private.addons.GetPlanCurrenciesRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.core.private.addons.GetPlanCurrenciesRequest.displayName = 'proto.core.private.addons.GetPlanCurrenciesRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.core.private.addons.GetPlanCurrenciesResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.core.private.addons.GetPlanCurrenciesResponse.repeatedFields_, null);
};
goog.inherits(proto.core.private.addons.GetPlanCurrenciesResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.core.private.addons.GetPlanCurrenciesResponse.displayName = 'proto.core.private.addons.GetPlanCurrenciesResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.core.private.addons.CreateAddOnRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.core.private.addons.CreateAddOnRequest.repeatedFields_, null);
};
goog.inherits(proto.core.private.addons.CreateAddOnRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.core.private.addons.CreateAddOnRequest.displayName = 'proto.core.private.addons.CreateAddOnRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.core.private.addons.CreateAddOnResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.core.private.addons.CreateAddOnResponse.repeatedFields_, null);
};
goog.inherits(proto.core.private.addons.CreateAddOnResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.core.private.addons.CreateAddOnResponse.displayName = 'proto.core.private.addons.CreateAddOnResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.core.private.addons.GetAddonRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.core.private.addons.GetAddonRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.core.private.addons.GetAddonRequest.displayName = 'proto.core.private.addons.GetAddonRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.core.private.addons.GetAddonResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.core.private.addons.GetAddonResponse.repeatedFields_, null);
};
goog.inherits(proto.core.private.addons.GetAddonResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.core.private.addons.GetAddonResponse.displayName = 'proto.core.private.addons.GetAddonResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.core.private.addons.UpdateAddonRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.core.private.addons.UpdateAddonRequest.repeatedFields_, null);
};
goog.inherits(proto.core.private.addons.UpdateAddonRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.core.private.addons.UpdateAddonRequest.displayName = 'proto.core.private.addons.UpdateAddonRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.core.private.addons.UpdateAddonResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.core.private.addons.UpdateAddonResponse.repeatedFields_, null);
};
goog.inherits(proto.core.private.addons.UpdateAddonResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.core.private.addons.UpdateAddonResponse.displayName = 'proto.core.private.addons.UpdateAddonResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.core.private.addons.DeleteAddonRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.core.private.addons.DeleteAddonRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.core.private.addons.DeleteAddonRequest.displayName = 'proto.core.private.addons.DeleteAddonRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.core.private.addons.DeleteAddonResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.core.private.addons.DeleteAddonResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.core.private.addons.DeleteAddonResponse.displayName = 'proto.core.private.addons.DeleteAddonResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.core.private.addons.Addon = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.core.private.addons.Addon.repeatedFields_, null);
};
goog.inherits(proto.core.private.addons.Addon, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.core.private.addons.Addon.displayName = 'proto.core.private.addons.Addon';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.core.private.addons.AddonPriceModel = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.core.private.addons.AddonPriceModel.repeatedFields_, null);
};
goog.inherits(proto.core.private.addons.AddonPriceModel, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.core.private.addons.AddonPriceModel.displayName = 'proto.core.private.addons.AddonPriceModel';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.core.private.addons.UpsertAddonPriceModel = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.core.private.addons.UpsertAddonPriceModel.repeatedFields_, null);
};
goog.inherits(proto.core.private.addons.UpsertAddonPriceModel, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.core.private.addons.UpsertAddonPriceModel.displayName = 'proto.core.private.addons.UpsertAddonPriceModel';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.core.private.addons.Currency = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.core.private.addons.Currency, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.core.private.addons.Currency.displayName = 'proto.core.private.addons.Currency';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.core.private.addons.Plan = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.core.private.addons.Plan, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.core.private.addons.Plan.displayName = 'proto.core.private.addons.Plan';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.core.private.addons.AddOnPlan = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.core.private.addons.AddOnPlan, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.core.private.addons.AddOnPlan.displayName = 'proto.core.private.addons.AddOnPlan';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.core.private.addons.UpsertAddonPlan = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.core.private.addons.UpsertAddonPlan, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.core.private.addons.UpsertAddonPlan.displayName = 'proto.core.private.addons.UpsertAddonPlan';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.core.private.addons.UnitTier = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.core.private.addons.UnitTier, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.core.private.addons.UnitTier.displayName = 'proto.core.private.addons.UnitTier';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.core.private.addons.UpsertAddonUnitTier = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.core.private.addons.UpsertAddonUnitTier, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.core.private.addons.UpsertAddonUnitTier.displayName = 'proto.core.private.addons.UpsertAddonUnitTier';
}

/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.core.private.addons.GetCurrencyPriceModelsRequest.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.core.private.addons.GetCurrencyPriceModelsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.core.private.addons.GetCurrencyPriceModelsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.core.private.addons.GetCurrencyPriceModelsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.core.private.addons.GetCurrencyPriceModelsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    companyDomain: jspb.Message.getFieldWithDefault(msg, 1, ""),
    currencyIso3Code: jspb.Message.getFieldWithDefault(msg, 2, ""),
    planIdsList: (f = jspb.Message.getRepeatedField(msg, 3)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.core.private.addons.GetCurrencyPriceModelsRequest}
 */
proto.core.private.addons.GetCurrencyPriceModelsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.core.private.addons.GetCurrencyPriceModelsRequest;
  return proto.core.private.addons.GetCurrencyPriceModelsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.core.private.addons.GetCurrencyPriceModelsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.core.private.addons.GetCurrencyPriceModelsRequest}
 */
proto.core.private.addons.GetCurrencyPriceModelsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setCompanyDomain(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setCurrencyIso3Code(value);
      break;
    case 3:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedInt32() : [reader.readInt32()]);
      for (var i = 0; i < values.length; i++) {
        msg.addPlanIds(values[i]);
      }
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.core.private.addons.GetCurrencyPriceModelsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.core.private.addons.GetCurrencyPriceModelsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.core.private.addons.GetCurrencyPriceModelsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.core.private.addons.GetCurrencyPriceModelsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCompanyDomain();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getCurrencyIso3Code();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getPlanIdsList();
  if (f.length > 0) {
    writer.writePackedInt32(
      3,
      f
    );
  }
};


/**
 * optional string company_domain = 1;
 * @return {string}
 */
proto.core.private.addons.GetCurrencyPriceModelsRequest.prototype.getCompanyDomain = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.core.private.addons.GetCurrencyPriceModelsRequest} returns this
 */
proto.core.private.addons.GetCurrencyPriceModelsRequest.prototype.setCompanyDomain = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string currency_iso_3_code = 2;
 * @return {string}
 */
proto.core.private.addons.GetCurrencyPriceModelsRequest.prototype.getCurrencyIso3Code = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.core.private.addons.GetCurrencyPriceModelsRequest} returns this
 */
proto.core.private.addons.GetCurrencyPriceModelsRequest.prototype.setCurrencyIso3Code = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * repeated int32 plan_ids = 3;
 * @return {!Array<number>}
 */
proto.core.private.addons.GetCurrencyPriceModelsRequest.prototype.getPlanIdsList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 3));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.core.private.addons.GetCurrencyPriceModelsRequest} returns this
 */
proto.core.private.addons.GetCurrencyPriceModelsRequest.prototype.setPlanIdsList = function(value) {
  return jspb.Message.setField(this, 3, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.core.private.addons.GetCurrencyPriceModelsRequest} returns this
 */
proto.core.private.addons.GetCurrencyPriceModelsRequest.prototype.addPlanIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 3, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.core.private.addons.GetCurrencyPriceModelsRequest} returns this
 */
proto.core.private.addons.GetCurrencyPriceModelsRequest.prototype.clearPlanIdsList = function() {
  return this.setPlanIdsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.core.private.addons.GetCurrencyPriceModelsResponse.repeatedFields_ = [6];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.core.private.addons.GetCurrencyPriceModelsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.core.private.addons.GetCurrencyPriceModelsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.core.private.addons.GetCurrencyPriceModelsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.core.private.addons.GetCurrencyPriceModelsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    currencyIso3Code: jspb.Message.getFieldWithDefault(msg, 1, ""),
    resultsList: jspb.Message.toObjectList(msg.getResultsList(),
    proto.core.private.addons.AddonPriceModel.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.core.private.addons.GetCurrencyPriceModelsResponse}
 */
proto.core.private.addons.GetCurrencyPriceModelsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.core.private.addons.GetCurrencyPriceModelsResponse;
  return proto.core.private.addons.GetCurrencyPriceModelsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.core.private.addons.GetCurrencyPriceModelsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.core.private.addons.GetCurrencyPriceModelsResponse}
 */
proto.core.private.addons.GetCurrencyPriceModelsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setCurrencyIso3Code(value);
      break;
    case 6:
      var value = new proto.core.private.addons.AddonPriceModel;
      reader.readMessage(value,proto.core.private.addons.AddonPriceModel.deserializeBinaryFromReader);
      msg.addResults(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.core.private.addons.GetCurrencyPriceModelsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.core.private.addons.GetCurrencyPriceModelsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.core.private.addons.GetCurrencyPriceModelsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.core.private.addons.GetCurrencyPriceModelsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCurrencyIso3Code();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getResultsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      6,
      f,
      proto.core.private.addons.AddonPriceModel.serializeBinaryToWriter
    );
  }
};


/**
 * optional string currency_iso_3_code = 1;
 * @return {string}
 */
proto.core.private.addons.GetCurrencyPriceModelsResponse.prototype.getCurrencyIso3Code = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.core.private.addons.GetCurrencyPriceModelsResponse} returns this
 */
proto.core.private.addons.GetCurrencyPriceModelsResponse.prototype.setCurrencyIso3Code = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * repeated AddonPriceModel results = 6;
 * @return {!Array<!proto.core.private.addons.AddonPriceModel>}
 */
proto.core.private.addons.GetCurrencyPriceModelsResponse.prototype.getResultsList = function() {
  return /** @type{!Array<!proto.core.private.addons.AddonPriceModel>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.core.private.addons.AddonPriceModel, 6));
};


/**
 * @param {!Array<!proto.core.private.addons.AddonPriceModel>} value
 * @return {!proto.core.private.addons.GetCurrencyPriceModelsResponse} returns this
*/
proto.core.private.addons.GetCurrencyPriceModelsResponse.prototype.setResultsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 6, value);
};


/**
 * @param {!proto.core.private.addons.AddonPriceModel=} opt_value
 * @param {number=} opt_index
 * @return {!proto.core.private.addons.AddonPriceModel}
 */
proto.core.private.addons.GetCurrencyPriceModelsResponse.prototype.addResults = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 6, opt_value, proto.core.private.addons.AddonPriceModel, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.core.private.addons.GetCurrencyPriceModelsResponse} returns this
 */
proto.core.private.addons.GetCurrencyPriceModelsResponse.prototype.clearResultsList = function() {
  return this.setResultsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.core.private.addons.GetAddonsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.core.private.addons.GetAddonsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.core.private.addons.GetAddonsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.core.private.addons.GetAddonsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    companyDomain: jspb.Message.getFieldWithDefault(msg, 1, ""),
    page: (f = msg.getPage()) && google_protobuf_wrappers_pb.Int32Value.toObject(includeInstance, f),
    pageSize: (f = msg.getPageSize()) && google_protobuf_wrappers_pb.Int32Value.toObject(includeInstance, f),
    search: jspb.Message.getFieldWithDefault(msg, 4, ""),
    addonFilterType: jspb.Message.getFieldWithDefault(msg, 5, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.core.private.addons.GetAddonsRequest}
 */
proto.core.private.addons.GetAddonsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.core.private.addons.GetAddonsRequest;
  return proto.core.private.addons.GetAddonsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.core.private.addons.GetAddonsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.core.private.addons.GetAddonsRequest}
 */
proto.core.private.addons.GetAddonsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setCompanyDomain(value);
      break;
    case 2:
      var value = new google_protobuf_wrappers_pb.Int32Value;
      reader.readMessage(value,google_protobuf_wrappers_pb.Int32Value.deserializeBinaryFromReader);
      msg.setPage(value);
      break;
    case 3:
      var value = new google_protobuf_wrappers_pb.Int32Value;
      reader.readMessage(value,google_protobuf_wrappers_pb.Int32Value.deserializeBinaryFromReader);
      msg.setPageSize(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setSearch(value);
      break;
    case 5:
      var value = /** @type {!proto.core.private.addons.AddonFilterType} */ (reader.readEnum());
      msg.setAddonFilterType(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.core.private.addons.GetAddonsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.core.private.addons.GetAddonsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.core.private.addons.GetAddonsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.core.private.addons.GetAddonsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCompanyDomain();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getPage();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      google_protobuf_wrappers_pb.Int32Value.serializeBinaryToWriter
    );
  }
  f = message.getPageSize();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      google_protobuf_wrappers_pb.Int32Value.serializeBinaryToWriter
    );
  }
  f = message.getSearch();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getAddonFilterType();
  if (f !== 0.0) {
    writer.writeEnum(
      5,
      f
    );
  }
};


/**
 * optional string company_domain = 1;
 * @return {string}
 */
proto.core.private.addons.GetAddonsRequest.prototype.getCompanyDomain = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.core.private.addons.GetAddonsRequest} returns this
 */
proto.core.private.addons.GetAddonsRequest.prototype.setCompanyDomain = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional google.protobuf.Int32Value page = 2;
 * @return {?proto.google.protobuf.Int32Value}
 */
proto.core.private.addons.GetAddonsRequest.prototype.getPage = function() {
  return /** @type{?proto.google.protobuf.Int32Value} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.Int32Value, 2));
};


/**
 * @param {?proto.google.protobuf.Int32Value|undefined} value
 * @return {!proto.core.private.addons.GetAddonsRequest} returns this
*/
proto.core.private.addons.GetAddonsRequest.prototype.setPage = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.core.private.addons.GetAddonsRequest} returns this
 */
proto.core.private.addons.GetAddonsRequest.prototype.clearPage = function() {
  return this.setPage(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.core.private.addons.GetAddonsRequest.prototype.hasPage = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional google.protobuf.Int32Value page_size = 3;
 * @return {?proto.google.protobuf.Int32Value}
 */
proto.core.private.addons.GetAddonsRequest.prototype.getPageSize = function() {
  return /** @type{?proto.google.protobuf.Int32Value} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.Int32Value, 3));
};


/**
 * @param {?proto.google.protobuf.Int32Value|undefined} value
 * @return {!proto.core.private.addons.GetAddonsRequest} returns this
*/
proto.core.private.addons.GetAddonsRequest.prototype.setPageSize = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.core.private.addons.GetAddonsRequest} returns this
 */
proto.core.private.addons.GetAddonsRequest.prototype.clearPageSize = function() {
  return this.setPageSize(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.core.private.addons.GetAddonsRequest.prototype.hasPageSize = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional string search = 4;
 * @return {string}
 */
proto.core.private.addons.GetAddonsRequest.prototype.getSearch = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.core.private.addons.GetAddonsRequest} returns this
 */
proto.core.private.addons.GetAddonsRequest.prototype.setSearch = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional AddonFilterType addon_filter_type = 5;
 * @return {!proto.core.private.addons.AddonFilterType}
 */
proto.core.private.addons.GetAddonsRequest.prototype.getAddonFilterType = function() {
  return /** @type {!proto.core.private.addons.AddonFilterType} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {!proto.core.private.addons.AddonFilterType} value
 * @return {!proto.core.private.addons.GetAddonsRequest} returns this
 */
proto.core.private.addons.GetAddonsRequest.prototype.setAddonFilterType = function(value) {
  return jspb.Message.setProto3EnumField(this, 5, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.core.private.addons.GetAddonsResponse.repeatedFields_ = [5];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.core.private.addons.GetAddonsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.core.private.addons.GetAddonsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.core.private.addons.GetAddonsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.core.private.addons.GetAddonsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    currentPage: jspb.Message.getFieldWithDefault(msg, 1, 0),
    pageCount: jspb.Message.getFieldWithDefault(msg, 2, 0),
    pageSize: jspb.Message.getFieldWithDefault(msg, 3, 0),
    rowCount: jspb.Message.getFieldWithDefault(msg, 4, 0),
    addonsList: jspb.Message.toObjectList(msg.getAddonsList(),
    proto.core.private.addons.Addon.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.core.private.addons.GetAddonsResponse}
 */
proto.core.private.addons.GetAddonsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.core.private.addons.GetAddonsResponse;
  return proto.core.private.addons.GetAddonsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.core.private.addons.GetAddonsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.core.private.addons.GetAddonsResponse}
 */
proto.core.private.addons.GetAddonsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setCurrentPage(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPageCount(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPageSize(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setRowCount(value);
      break;
    case 5:
      var value = new proto.core.private.addons.Addon;
      reader.readMessage(value,proto.core.private.addons.Addon.deserializeBinaryFromReader);
      msg.addAddons(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.core.private.addons.GetAddonsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.core.private.addons.GetAddonsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.core.private.addons.GetAddonsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.core.private.addons.GetAddonsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCurrentPage();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getPageCount();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getPageSize();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
  f = message.getRowCount();
  if (f !== 0) {
    writer.writeInt64(
      4,
      f
    );
  }
  f = message.getAddonsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      5,
      f,
      proto.core.private.addons.Addon.serializeBinaryToWriter
    );
  }
};


/**
 * optional int32 current_page = 1;
 * @return {number}
 */
proto.core.private.addons.GetAddonsResponse.prototype.getCurrentPage = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.core.private.addons.GetAddonsResponse} returns this
 */
proto.core.private.addons.GetAddonsResponse.prototype.setCurrentPage = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int32 page_count = 2;
 * @return {number}
 */
proto.core.private.addons.GetAddonsResponse.prototype.getPageCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.core.private.addons.GetAddonsResponse} returns this
 */
proto.core.private.addons.GetAddonsResponse.prototype.setPageCount = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int32 page_size = 3;
 * @return {number}
 */
proto.core.private.addons.GetAddonsResponse.prototype.getPageSize = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.core.private.addons.GetAddonsResponse} returns this
 */
proto.core.private.addons.GetAddonsResponse.prototype.setPageSize = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional int64 row_count = 4;
 * @return {number}
 */
proto.core.private.addons.GetAddonsResponse.prototype.getRowCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.core.private.addons.GetAddonsResponse} returns this
 */
proto.core.private.addons.GetAddonsResponse.prototype.setRowCount = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * repeated Addon addons = 5;
 * @return {!Array<!proto.core.private.addons.Addon>}
 */
proto.core.private.addons.GetAddonsResponse.prototype.getAddonsList = function() {
  return /** @type{!Array<!proto.core.private.addons.Addon>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.core.private.addons.Addon, 5));
};


/**
 * @param {!Array<!proto.core.private.addons.Addon>} value
 * @return {!proto.core.private.addons.GetAddonsResponse} returns this
*/
proto.core.private.addons.GetAddonsResponse.prototype.setAddonsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 5, value);
};


/**
 * @param {!proto.core.private.addons.Addon=} opt_value
 * @param {number=} opt_index
 * @return {!proto.core.private.addons.Addon}
 */
proto.core.private.addons.GetAddonsResponse.prototype.addAddons = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 5, opt_value, proto.core.private.addons.Addon, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.core.private.addons.GetAddonsResponse} returns this
 */
proto.core.private.addons.GetAddonsResponse.prototype.clearAddonsList = function() {
  return this.setAddonsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.core.private.addons.GetPlanCurrenciesRequest.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.core.private.addons.GetPlanCurrenciesRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.core.private.addons.GetPlanCurrenciesRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.core.private.addons.GetPlanCurrenciesRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.core.private.addons.GetPlanCurrenciesRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    companyDomain: jspb.Message.getFieldWithDefault(msg, 1, ""),
    planIdsList: (f = jspb.Message.getRepeatedField(msg, 2)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.core.private.addons.GetPlanCurrenciesRequest}
 */
proto.core.private.addons.GetPlanCurrenciesRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.core.private.addons.GetPlanCurrenciesRequest;
  return proto.core.private.addons.GetPlanCurrenciesRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.core.private.addons.GetPlanCurrenciesRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.core.private.addons.GetPlanCurrenciesRequest}
 */
proto.core.private.addons.GetPlanCurrenciesRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setCompanyDomain(value);
      break;
    case 2:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedInt32() : [reader.readInt32()]);
      for (var i = 0; i < values.length; i++) {
        msg.addPlanIds(values[i]);
      }
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.core.private.addons.GetPlanCurrenciesRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.core.private.addons.GetPlanCurrenciesRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.core.private.addons.GetPlanCurrenciesRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.core.private.addons.GetPlanCurrenciesRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCompanyDomain();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getPlanIdsList();
  if (f.length > 0) {
    writer.writePackedInt32(
      2,
      f
    );
  }
};


/**
 * optional string company_domain = 1;
 * @return {string}
 */
proto.core.private.addons.GetPlanCurrenciesRequest.prototype.getCompanyDomain = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.core.private.addons.GetPlanCurrenciesRequest} returns this
 */
proto.core.private.addons.GetPlanCurrenciesRequest.prototype.setCompanyDomain = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * repeated int32 plan_ids = 2;
 * @return {!Array<number>}
 */
proto.core.private.addons.GetPlanCurrenciesRequest.prototype.getPlanIdsList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 2));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.core.private.addons.GetPlanCurrenciesRequest} returns this
 */
proto.core.private.addons.GetPlanCurrenciesRequest.prototype.setPlanIdsList = function(value) {
  return jspb.Message.setField(this, 2, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.core.private.addons.GetPlanCurrenciesRequest} returns this
 */
proto.core.private.addons.GetPlanCurrenciesRequest.prototype.addPlanIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 2, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.core.private.addons.GetPlanCurrenciesRequest} returns this
 */
proto.core.private.addons.GetPlanCurrenciesRequest.prototype.clearPlanIdsList = function() {
  return this.setPlanIdsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.core.private.addons.GetPlanCurrenciesResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.core.private.addons.GetPlanCurrenciesResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.core.private.addons.GetPlanCurrenciesResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.core.private.addons.GetPlanCurrenciesResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.core.private.addons.GetPlanCurrenciesResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    currenciesList: jspb.Message.toObjectList(msg.getCurrenciesList(),
    proto.core.private.addons.Currency.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.core.private.addons.GetPlanCurrenciesResponse}
 */
proto.core.private.addons.GetPlanCurrenciesResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.core.private.addons.GetPlanCurrenciesResponse;
  return proto.core.private.addons.GetPlanCurrenciesResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.core.private.addons.GetPlanCurrenciesResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.core.private.addons.GetPlanCurrenciesResponse}
 */
proto.core.private.addons.GetPlanCurrenciesResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.core.private.addons.Currency;
      reader.readMessage(value,proto.core.private.addons.Currency.deserializeBinaryFromReader);
      msg.addCurrencies(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.core.private.addons.GetPlanCurrenciesResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.core.private.addons.GetPlanCurrenciesResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.core.private.addons.GetPlanCurrenciesResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.core.private.addons.GetPlanCurrenciesResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCurrenciesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.core.private.addons.Currency.serializeBinaryToWriter
    );
  }
};


/**
 * repeated Currency currencies = 1;
 * @return {!Array<!proto.core.private.addons.Currency>}
 */
proto.core.private.addons.GetPlanCurrenciesResponse.prototype.getCurrenciesList = function() {
  return /** @type{!Array<!proto.core.private.addons.Currency>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.core.private.addons.Currency, 1));
};


/**
 * @param {!Array<!proto.core.private.addons.Currency>} value
 * @return {!proto.core.private.addons.GetPlanCurrenciesResponse} returns this
*/
proto.core.private.addons.GetPlanCurrenciesResponse.prototype.setCurrenciesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.core.private.addons.Currency=} opt_value
 * @param {number=} opt_index
 * @return {!proto.core.private.addons.Currency}
 */
proto.core.private.addons.GetPlanCurrenciesResponse.prototype.addCurrencies = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.core.private.addons.Currency, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.core.private.addons.GetPlanCurrenciesResponse} returns this
 */
proto.core.private.addons.GetPlanCurrenciesResponse.prototype.clearCurrenciesList = function() {
  return this.setCurrenciesList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.core.private.addons.CreateAddOnRequest.repeatedFields_ = [7,9,10];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.core.private.addons.CreateAddOnRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.core.private.addons.CreateAddOnRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.core.private.addons.CreateAddOnRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.core.private.addons.CreateAddOnRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    companyDomain: jspb.Message.getFieldWithDefault(msg, 1, ""),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    displayName: jspb.Message.getFieldWithDefault(msg, 3, ""),
    description: jspb.Message.getFieldWithDefault(msg, 4, ""),
    singleUnitName: jspb.Message.getFieldWithDefault(msg, 5, ""),
    pluralUnitName: jspb.Message.getFieldWithDefault(msg, 6, ""),
    plansList: jspb.Message.toObjectList(msg.getPlansList(),
    proto.core.private.addons.UpsertAddonPlan.toObject, includeInstance),
    pricingModelType: jspb.Message.getFieldWithDefault(msg, 8, 0),
    priceModelsList: jspb.Message.toObjectList(msg.getPriceModelsList(),
    proto.core.private.addons.UpsertAddonPriceModel.toObject, includeInstance),
    featureTagsList: (f = jspb.Message.getRepeatedField(msg, 10)) == null ? undefined : f,
    isImageEnabled: jspb.Message.getBooleanFieldWithDefault(msg, 11, false),
    imageFilename: jspb.Message.getFieldWithDefault(msg, 12, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.core.private.addons.CreateAddOnRequest}
 */
proto.core.private.addons.CreateAddOnRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.core.private.addons.CreateAddOnRequest;
  return proto.core.private.addons.CreateAddOnRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.core.private.addons.CreateAddOnRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.core.private.addons.CreateAddOnRequest}
 */
proto.core.private.addons.CreateAddOnRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setCompanyDomain(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setDisplayName(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setSingleUnitName(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setPluralUnitName(value);
      break;
    case 7:
      var value = new proto.core.private.addons.UpsertAddonPlan;
      reader.readMessage(value,proto.core.private.addons.UpsertAddonPlan.deserializeBinaryFromReader);
      msg.addPlans(value);
      break;
    case 8:
      var value = /** @type {!proto.core.private.addons.PricingModelType} */ (reader.readEnum());
      msg.setPricingModelType(value);
      break;
    case 9:
      var value = new proto.core.private.addons.UpsertAddonPriceModel;
      reader.readMessage(value,proto.core.private.addons.UpsertAddonPriceModel.deserializeBinaryFromReader);
      msg.addPriceModels(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.addFeatureTags(value);
      break;
    case 11:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsImageEnabled(value);
      break;
    case 12:
      var value = /** @type {string} */ (reader.readString());
      msg.setImageFilename(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.core.private.addons.CreateAddOnRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.core.private.addons.CreateAddOnRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.core.private.addons.CreateAddOnRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.core.private.addons.CreateAddOnRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCompanyDomain();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getDisplayName();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getDescription();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getSingleUnitName();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getPluralUnitName();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getPlansList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      7,
      f,
      proto.core.private.addons.UpsertAddonPlan.serializeBinaryToWriter
    );
  }
  f = message.getPricingModelType();
  if (f !== 0.0) {
    writer.writeEnum(
      8,
      f
    );
  }
  f = message.getPriceModelsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      9,
      f,
      proto.core.private.addons.UpsertAddonPriceModel.serializeBinaryToWriter
    );
  }
  f = message.getFeatureTagsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      10,
      f
    );
  }
  f = message.getIsImageEnabled();
  if (f) {
    writer.writeBool(
      11,
      f
    );
  }
  f = message.getImageFilename();
  if (f.length > 0) {
    writer.writeString(
      12,
      f
    );
  }
};


/**
 * optional string company_domain = 1;
 * @return {string}
 */
proto.core.private.addons.CreateAddOnRequest.prototype.getCompanyDomain = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.core.private.addons.CreateAddOnRequest} returns this
 */
proto.core.private.addons.CreateAddOnRequest.prototype.setCompanyDomain = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.core.private.addons.CreateAddOnRequest.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.core.private.addons.CreateAddOnRequest} returns this
 */
proto.core.private.addons.CreateAddOnRequest.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string display_name = 3;
 * @return {string}
 */
proto.core.private.addons.CreateAddOnRequest.prototype.getDisplayName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.core.private.addons.CreateAddOnRequest} returns this
 */
proto.core.private.addons.CreateAddOnRequest.prototype.setDisplayName = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string description = 4;
 * @return {string}
 */
proto.core.private.addons.CreateAddOnRequest.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.core.private.addons.CreateAddOnRequest} returns this
 */
proto.core.private.addons.CreateAddOnRequest.prototype.setDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string single_unit_name = 5;
 * @return {string}
 */
proto.core.private.addons.CreateAddOnRequest.prototype.getSingleUnitName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.core.private.addons.CreateAddOnRequest} returns this
 */
proto.core.private.addons.CreateAddOnRequest.prototype.setSingleUnitName = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string plural_unit_name = 6;
 * @return {string}
 */
proto.core.private.addons.CreateAddOnRequest.prototype.getPluralUnitName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.core.private.addons.CreateAddOnRequest} returns this
 */
proto.core.private.addons.CreateAddOnRequest.prototype.setPluralUnitName = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * repeated UpsertAddonPlan plans = 7;
 * @return {!Array<!proto.core.private.addons.UpsertAddonPlan>}
 */
proto.core.private.addons.CreateAddOnRequest.prototype.getPlansList = function() {
  return /** @type{!Array<!proto.core.private.addons.UpsertAddonPlan>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.core.private.addons.UpsertAddonPlan, 7));
};


/**
 * @param {!Array<!proto.core.private.addons.UpsertAddonPlan>} value
 * @return {!proto.core.private.addons.CreateAddOnRequest} returns this
*/
proto.core.private.addons.CreateAddOnRequest.prototype.setPlansList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 7, value);
};


/**
 * @param {!proto.core.private.addons.UpsertAddonPlan=} opt_value
 * @param {number=} opt_index
 * @return {!proto.core.private.addons.UpsertAddonPlan}
 */
proto.core.private.addons.CreateAddOnRequest.prototype.addPlans = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 7, opt_value, proto.core.private.addons.UpsertAddonPlan, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.core.private.addons.CreateAddOnRequest} returns this
 */
proto.core.private.addons.CreateAddOnRequest.prototype.clearPlansList = function() {
  return this.setPlansList([]);
};


/**
 * optional PricingModelType pricing_model_type = 8;
 * @return {!proto.core.private.addons.PricingModelType}
 */
proto.core.private.addons.CreateAddOnRequest.prototype.getPricingModelType = function() {
  return /** @type {!proto.core.private.addons.PricingModelType} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {!proto.core.private.addons.PricingModelType} value
 * @return {!proto.core.private.addons.CreateAddOnRequest} returns this
 */
proto.core.private.addons.CreateAddOnRequest.prototype.setPricingModelType = function(value) {
  return jspb.Message.setProto3EnumField(this, 8, value);
};


/**
 * repeated UpsertAddonPriceModel price_models = 9;
 * @return {!Array<!proto.core.private.addons.UpsertAddonPriceModel>}
 */
proto.core.private.addons.CreateAddOnRequest.prototype.getPriceModelsList = function() {
  return /** @type{!Array<!proto.core.private.addons.UpsertAddonPriceModel>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.core.private.addons.UpsertAddonPriceModel, 9));
};


/**
 * @param {!Array<!proto.core.private.addons.UpsertAddonPriceModel>} value
 * @return {!proto.core.private.addons.CreateAddOnRequest} returns this
*/
proto.core.private.addons.CreateAddOnRequest.prototype.setPriceModelsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 9, value);
};


/**
 * @param {!proto.core.private.addons.UpsertAddonPriceModel=} opt_value
 * @param {number=} opt_index
 * @return {!proto.core.private.addons.UpsertAddonPriceModel}
 */
proto.core.private.addons.CreateAddOnRequest.prototype.addPriceModels = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 9, opt_value, proto.core.private.addons.UpsertAddonPriceModel, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.core.private.addons.CreateAddOnRequest} returns this
 */
proto.core.private.addons.CreateAddOnRequest.prototype.clearPriceModelsList = function() {
  return this.setPriceModelsList([]);
};


/**
 * repeated string feature_tags = 10;
 * @return {!Array<string>}
 */
proto.core.private.addons.CreateAddOnRequest.prototype.getFeatureTagsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 10));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.core.private.addons.CreateAddOnRequest} returns this
 */
proto.core.private.addons.CreateAddOnRequest.prototype.setFeatureTagsList = function(value) {
  return jspb.Message.setField(this, 10, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.core.private.addons.CreateAddOnRequest} returns this
 */
proto.core.private.addons.CreateAddOnRequest.prototype.addFeatureTags = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 10, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.core.private.addons.CreateAddOnRequest} returns this
 */
proto.core.private.addons.CreateAddOnRequest.prototype.clearFeatureTagsList = function() {
  return this.setFeatureTagsList([]);
};


/**
 * optional bool is_image_enabled = 11;
 * @return {boolean}
 */
proto.core.private.addons.CreateAddOnRequest.prototype.getIsImageEnabled = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 11, false));
};


/**
 * @param {boolean} value
 * @return {!proto.core.private.addons.CreateAddOnRequest} returns this
 */
proto.core.private.addons.CreateAddOnRequest.prototype.setIsImageEnabled = function(value) {
  return jspb.Message.setProto3BooleanField(this, 11, value);
};


/**
 * optional string image_filename = 12;
 * @return {string}
 */
proto.core.private.addons.CreateAddOnRequest.prototype.getImageFilename = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 12, ""));
};


/**
 * @param {string} value
 * @return {!proto.core.private.addons.CreateAddOnRequest} returns this
 */
proto.core.private.addons.CreateAddOnRequest.prototype.setImageFilename = function(value) {
  return jspb.Message.setProto3StringField(this, 12, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.core.private.addons.CreateAddOnResponse.repeatedFields_ = [7,9,10];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.core.private.addons.CreateAddOnResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.core.private.addons.CreateAddOnResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.core.private.addons.CreateAddOnResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.core.private.addons.CreateAddOnResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    addonId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    displayName: jspb.Message.getFieldWithDefault(msg, 3, ""),
    description: jspb.Message.getFieldWithDefault(msg, 4, ""),
    singleUnitName: jspb.Message.getFieldWithDefault(msg, 5, ""),
    pluralUnitName: jspb.Message.getFieldWithDefault(msg, 6, ""),
    plansList: jspb.Message.toObjectList(msg.getPlansList(),
    proto.core.private.addons.AddOnPlan.toObject, includeInstance),
    pricingModelType: jspb.Message.getFieldWithDefault(msg, 8, 0),
    priceModelsList: jspb.Message.toObjectList(msg.getPriceModelsList(),
    proto.core.private.addons.AddonPriceModel.toObject, includeInstance),
    featureTagsList: (f = jspb.Message.getRepeatedField(msg, 10)) == null ? undefined : f,
    hasActiveLinkedPlans: jspb.Message.getBooleanFieldWithDefault(msg, 11, false),
    isActive: jspb.Message.getBooleanFieldWithDefault(msg, 12, false),
    isImageEnabled: jspb.Message.getBooleanFieldWithDefault(msg, 13, false),
    imageFilename: jspb.Message.getFieldWithDefault(msg, 14, ""),
    imageUrl: jspb.Message.getFieldWithDefault(msg, 15, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.core.private.addons.CreateAddOnResponse}
 */
proto.core.private.addons.CreateAddOnResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.core.private.addons.CreateAddOnResponse;
  return proto.core.private.addons.CreateAddOnResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.core.private.addons.CreateAddOnResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.core.private.addons.CreateAddOnResponse}
 */
proto.core.private.addons.CreateAddOnResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setAddonId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setDisplayName(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setSingleUnitName(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setPluralUnitName(value);
      break;
    case 7:
      var value = new proto.core.private.addons.AddOnPlan;
      reader.readMessage(value,proto.core.private.addons.AddOnPlan.deserializeBinaryFromReader);
      msg.addPlans(value);
      break;
    case 8:
      var value = /** @type {!proto.core.private.addons.PricingModelType} */ (reader.readEnum());
      msg.setPricingModelType(value);
      break;
    case 9:
      var value = new proto.core.private.addons.AddonPriceModel;
      reader.readMessage(value,proto.core.private.addons.AddonPriceModel.deserializeBinaryFromReader);
      msg.addPriceModels(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.addFeatureTags(value);
      break;
    case 11:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setHasActiveLinkedPlans(value);
      break;
    case 12:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsActive(value);
      break;
    case 13:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsImageEnabled(value);
      break;
    case 14:
      var value = /** @type {string} */ (reader.readString());
      msg.setImageFilename(value);
      break;
    case 15:
      var value = /** @type {string} */ (reader.readString());
      msg.setImageUrl(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.core.private.addons.CreateAddOnResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.core.private.addons.CreateAddOnResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.core.private.addons.CreateAddOnResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.core.private.addons.CreateAddOnResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAddonId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getDisplayName();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getDescription();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getSingleUnitName();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getPluralUnitName();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getPlansList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      7,
      f,
      proto.core.private.addons.AddOnPlan.serializeBinaryToWriter
    );
  }
  f = message.getPricingModelType();
  if (f !== 0.0) {
    writer.writeEnum(
      8,
      f
    );
  }
  f = message.getPriceModelsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      9,
      f,
      proto.core.private.addons.AddonPriceModel.serializeBinaryToWriter
    );
  }
  f = message.getFeatureTagsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      10,
      f
    );
  }
  f = message.getHasActiveLinkedPlans();
  if (f) {
    writer.writeBool(
      11,
      f
    );
  }
  f = message.getIsActive();
  if (f) {
    writer.writeBool(
      12,
      f
    );
  }
  f = message.getIsImageEnabled();
  if (f) {
    writer.writeBool(
      13,
      f
    );
  }
  f = message.getImageFilename();
  if (f.length > 0) {
    writer.writeString(
      14,
      f
    );
  }
  f = message.getImageUrl();
  if (f.length > 0) {
    writer.writeString(
      15,
      f
    );
  }
};


/**
 * optional int32 addon_id = 1;
 * @return {number}
 */
proto.core.private.addons.CreateAddOnResponse.prototype.getAddonId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.core.private.addons.CreateAddOnResponse} returns this
 */
proto.core.private.addons.CreateAddOnResponse.prototype.setAddonId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.core.private.addons.CreateAddOnResponse.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.core.private.addons.CreateAddOnResponse} returns this
 */
proto.core.private.addons.CreateAddOnResponse.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string display_name = 3;
 * @return {string}
 */
proto.core.private.addons.CreateAddOnResponse.prototype.getDisplayName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.core.private.addons.CreateAddOnResponse} returns this
 */
proto.core.private.addons.CreateAddOnResponse.prototype.setDisplayName = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string description = 4;
 * @return {string}
 */
proto.core.private.addons.CreateAddOnResponse.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.core.private.addons.CreateAddOnResponse} returns this
 */
proto.core.private.addons.CreateAddOnResponse.prototype.setDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string single_unit_name = 5;
 * @return {string}
 */
proto.core.private.addons.CreateAddOnResponse.prototype.getSingleUnitName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.core.private.addons.CreateAddOnResponse} returns this
 */
proto.core.private.addons.CreateAddOnResponse.prototype.setSingleUnitName = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string plural_unit_name = 6;
 * @return {string}
 */
proto.core.private.addons.CreateAddOnResponse.prototype.getPluralUnitName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.core.private.addons.CreateAddOnResponse} returns this
 */
proto.core.private.addons.CreateAddOnResponse.prototype.setPluralUnitName = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * repeated AddOnPlan plans = 7;
 * @return {!Array<!proto.core.private.addons.AddOnPlan>}
 */
proto.core.private.addons.CreateAddOnResponse.prototype.getPlansList = function() {
  return /** @type{!Array<!proto.core.private.addons.AddOnPlan>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.core.private.addons.AddOnPlan, 7));
};


/**
 * @param {!Array<!proto.core.private.addons.AddOnPlan>} value
 * @return {!proto.core.private.addons.CreateAddOnResponse} returns this
*/
proto.core.private.addons.CreateAddOnResponse.prototype.setPlansList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 7, value);
};


/**
 * @param {!proto.core.private.addons.AddOnPlan=} opt_value
 * @param {number=} opt_index
 * @return {!proto.core.private.addons.AddOnPlan}
 */
proto.core.private.addons.CreateAddOnResponse.prototype.addPlans = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 7, opt_value, proto.core.private.addons.AddOnPlan, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.core.private.addons.CreateAddOnResponse} returns this
 */
proto.core.private.addons.CreateAddOnResponse.prototype.clearPlansList = function() {
  return this.setPlansList([]);
};


/**
 * optional PricingModelType pricing_model_type = 8;
 * @return {!proto.core.private.addons.PricingModelType}
 */
proto.core.private.addons.CreateAddOnResponse.prototype.getPricingModelType = function() {
  return /** @type {!proto.core.private.addons.PricingModelType} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {!proto.core.private.addons.PricingModelType} value
 * @return {!proto.core.private.addons.CreateAddOnResponse} returns this
 */
proto.core.private.addons.CreateAddOnResponse.prototype.setPricingModelType = function(value) {
  return jspb.Message.setProto3EnumField(this, 8, value);
};


/**
 * repeated AddonPriceModel price_models = 9;
 * @return {!Array<!proto.core.private.addons.AddonPriceModel>}
 */
proto.core.private.addons.CreateAddOnResponse.prototype.getPriceModelsList = function() {
  return /** @type{!Array<!proto.core.private.addons.AddonPriceModel>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.core.private.addons.AddonPriceModel, 9));
};


/**
 * @param {!Array<!proto.core.private.addons.AddonPriceModel>} value
 * @return {!proto.core.private.addons.CreateAddOnResponse} returns this
*/
proto.core.private.addons.CreateAddOnResponse.prototype.setPriceModelsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 9, value);
};


/**
 * @param {!proto.core.private.addons.AddonPriceModel=} opt_value
 * @param {number=} opt_index
 * @return {!proto.core.private.addons.AddonPriceModel}
 */
proto.core.private.addons.CreateAddOnResponse.prototype.addPriceModels = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 9, opt_value, proto.core.private.addons.AddonPriceModel, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.core.private.addons.CreateAddOnResponse} returns this
 */
proto.core.private.addons.CreateAddOnResponse.prototype.clearPriceModelsList = function() {
  return this.setPriceModelsList([]);
};


/**
 * repeated string feature_tags = 10;
 * @return {!Array<string>}
 */
proto.core.private.addons.CreateAddOnResponse.prototype.getFeatureTagsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 10));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.core.private.addons.CreateAddOnResponse} returns this
 */
proto.core.private.addons.CreateAddOnResponse.prototype.setFeatureTagsList = function(value) {
  return jspb.Message.setField(this, 10, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.core.private.addons.CreateAddOnResponse} returns this
 */
proto.core.private.addons.CreateAddOnResponse.prototype.addFeatureTags = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 10, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.core.private.addons.CreateAddOnResponse} returns this
 */
proto.core.private.addons.CreateAddOnResponse.prototype.clearFeatureTagsList = function() {
  return this.setFeatureTagsList([]);
};


/**
 * optional bool has_active_linked_plans = 11;
 * @return {boolean}
 */
proto.core.private.addons.CreateAddOnResponse.prototype.getHasActiveLinkedPlans = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 11, false));
};


/**
 * @param {boolean} value
 * @return {!proto.core.private.addons.CreateAddOnResponse} returns this
 */
proto.core.private.addons.CreateAddOnResponse.prototype.setHasActiveLinkedPlans = function(value) {
  return jspb.Message.setProto3BooleanField(this, 11, value);
};


/**
 * optional bool is_active = 12;
 * @return {boolean}
 */
proto.core.private.addons.CreateAddOnResponse.prototype.getIsActive = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 12, false));
};


/**
 * @param {boolean} value
 * @return {!proto.core.private.addons.CreateAddOnResponse} returns this
 */
proto.core.private.addons.CreateAddOnResponse.prototype.setIsActive = function(value) {
  return jspb.Message.setProto3BooleanField(this, 12, value);
};


/**
 * optional bool is_image_enabled = 13;
 * @return {boolean}
 */
proto.core.private.addons.CreateAddOnResponse.prototype.getIsImageEnabled = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 13, false));
};


/**
 * @param {boolean} value
 * @return {!proto.core.private.addons.CreateAddOnResponse} returns this
 */
proto.core.private.addons.CreateAddOnResponse.prototype.setIsImageEnabled = function(value) {
  return jspb.Message.setProto3BooleanField(this, 13, value);
};


/**
 * optional string image_filename = 14;
 * @return {string}
 */
proto.core.private.addons.CreateAddOnResponse.prototype.getImageFilename = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 14, ""));
};


/**
 * @param {string} value
 * @return {!proto.core.private.addons.CreateAddOnResponse} returns this
 */
proto.core.private.addons.CreateAddOnResponse.prototype.setImageFilename = function(value) {
  return jspb.Message.setProto3StringField(this, 14, value);
};


/**
 * optional string image_url = 15;
 * @return {string}
 */
proto.core.private.addons.CreateAddOnResponse.prototype.getImageUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 15, ""));
};


/**
 * @param {string} value
 * @return {!proto.core.private.addons.CreateAddOnResponse} returns this
 */
proto.core.private.addons.CreateAddOnResponse.prototype.setImageUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 15, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.core.private.addons.GetAddonRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.core.private.addons.GetAddonRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.core.private.addons.GetAddonRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.core.private.addons.GetAddonRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    companyDomain: jspb.Message.getFieldWithDefault(msg, 1, ""),
    addonId: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.core.private.addons.GetAddonRequest}
 */
proto.core.private.addons.GetAddonRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.core.private.addons.GetAddonRequest;
  return proto.core.private.addons.GetAddonRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.core.private.addons.GetAddonRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.core.private.addons.GetAddonRequest}
 */
proto.core.private.addons.GetAddonRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setCompanyDomain(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setAddonId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.core.private.addons.GetAddonRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.core.private.addons.GetAddonRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.core.private.addons.GetAddonRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.core.private.addons.GetAddonRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCompanyDomain();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getAddonId();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
};


/**
 * optional string company_domain = 1;
 * @return {string}
 */
proto.core.private.addons.GetAddonRequest.prototype.getCompanyDomain = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.core.private.addons.GetAddonRequest} returns this
 */
proto.core.private.addons.GetAddonRequest.prototype.setCompanyDomain = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int32 addon_id = 2;
 * @return {number}
 */
proto.core.private.addons.GetAddonRequest.prototype.getAddonId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.core.private.addons.GetAddonRequest} returns this
 */
proto.core.private.addons.GetAddonRequest.prototype.setAddonId = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.core.private.addons.GetAddonResponse.repeatedFields_ = [7,9,10];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.core.private.addons.GetAddonResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.core.private.addons.GetAddonResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.core.private.addons.GetAddonResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.core.private.addons.GetAddonResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    addonId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    displayName: jspb.Message.getFieldWithDefault(msg, 3, ""),
    description: jspb.Message.getFieldWithDefault(msg, 4, ""),
    singleUnitName: jspb.Message.getFieldWithDefault(msg, 5, ""),
    pluralUnitName: jspb.Message.getFieldWithDefault(msg, 6, ""),
    plansList: jspb.Message.toObjectList(msg.getPlansList(),
    proto.core.private.addons.AddOnPlan.toObject, includeInstance),
    pricingModelType: jspb.Message.getFieldWithDefault(msg, 8, 0),
    priceModelsList: jspb.Message.toObjectList(msg.getPriceModelsList(),
    proto.core.private.addons.AddonPriceModel.toObject, includeInstance),
    featureTagsList: (f = jspb.Message.getRepeatedField(msg, 10)) == null ? undefined : f,
    hasActiveLinkedPlans: jspb.Message.getBooleanFieldWithDefault(msg, 11, false),
    isActive: jspb.Message.getBooleanFieldWithDefault(msg, 12, false),
    isImageEnabled: jspb.Message.getBooleanFieldWithDefault(msg, 13, false),
    imageFilename: jspb.Message.getFieldWithDefault(msg, 14, ""),
    imageUrl: jspb.Message.getFieldWithDefault(msg, 15, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.core.private.addons.GetAddonResponse}
 */
proto.core.private.addons.GetAddonResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.core.private.addons.GetAddonResponse;
  return proto.core.private.addons.GetAddonResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.core.private.addons.GetAddonResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.core.private.addons.GetAddonResponse}
 */
proto.core.private.addons.GetAddonResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setAddonId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setDisplayName(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setSingleUnitName(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setPluralUnitName(value);
      break;
    case 7:
      var value = new proto.core.private.addons.AddOnPlan;
      reader.readMessage(value,proto.core.private.addons.AddOnPlan.deserializeBinaryFromReader);
      msg.addPlans(value);
      break;
    case 8:
      var value = /** @type {!proto.core.private.addons.PricingModelType} */ (reader.readEnum());
      msg.setPricingModelType(value);
      break;
    case 9:
      var value = new proto.core.private.addons.AddonPriceModel;
      reader.readMessage(value,proto.core.private.addons.AddonPriceModel.deserializeBinaryFromReader);
      msg.addPriceModels(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.addFeatureTags(value);
      break;
    case 11:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setHasActiveLinkedPlans(value);
      break;
    case 12:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsActive(value);
      break;
    case 13:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsImageEnabled(value);
      break;
    case 14:
      var value = /** @type {string} */ (reader.readString());
      msg.setImageFilename(value);
      break;
    case 15:
      var value = /** @type {string} */ (reader.readString());
      msg.setImageUrl(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.core.private.addons.GetAddonResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.core.private.addons.GetAddonResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.core.private.addons.GetAddonResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.core.private.addons.GetAddonResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAddonId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getDisplayName();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getDescription();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getSingleUnitName();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getPluralUnitName();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getPlansList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      7,
      f,
      proto.core.private.addons.AddOnPlan.serializeBinaryToWriter
    );
  }
  f = message.getPricingModelType();
  if (f !== 0.0) {
    writer.writeEnum(
      8,
      f
    );
  }
  f = message.getPriceModelsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      9,
      f,
      proto.core.private.addons.AddonPriceModel.serializeBinaryToWriter
    );
  }
  f = message.getFeatureTagsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      10,
      f
    );
  }
  f = message.getHasActiveLinkedPlans();
  if (f) {
    writer.writeBool(
      11,
      f
    );
  }
  f = message.getIsActive();
  if (f) {
    writer.writeBool(
      12,
      f
    );
  }
  f = message.getIsImageEnabled();
  if (f) {
    writer.writeBool(
      13,
      f
    );
  }
  f = message.getImageFilename();
  if (f.length > 0) {
    writer.writeString(
      14,
      f
    );
  }
  f = message.getImageUrl();
  if (f.length > 0) {
    writer.writeString(
      15,
      f
    );
  }
};


/**
 * optional int32 addon_id = 1;
 * @return {number}
 */
proto.core.private.addons.GetAddonResponse.prototype.getAddonId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.core.private.addons.GetAddonResponse} returns this
 */
proto.core.private.addons.GetAddonResponse.prototype.setAddonId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.core.private.addons.GetAddonResponse.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.core.private.addons.GetAddonResponse} returns this
 */
proto.core.private.addons.GetAddonResponse.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string display_name = 3;
 * @return {string}
 */
proto.core.private.addons.GetAddonResponse.prototype.getDisplayName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.core.private.addons.GetAddonResponse} returns this
 */
proto.core.private.addons.GetAddonResponse.prototype.setDisplayName = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string description = 4;
 * @return {string}
 */
proto.core.private.addons.GetAddonResponse.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.core.private.addons.GetAddonResponse} returns this
 */
proto.core.private.addons.GetAddonResponse.prototype.setDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string single_unit_name = 5;
 * @return {string}
 */
proto.core.private.addons.GetAddonResponse.prototype.getSingleUnitName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.core.private.addons.GetAddonResponse} returns this
 */
proto.core.private.addons.GetAddonResponse.prototype.setSingleUnitName = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string plural_unit_name = 6;
 * @return {string}
 */
proto.core.private.addons.GetAddonResponse.prototype.getPluralUnitName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.core.private.addons.GetAddonResponse} returns this
 */
proto.core.private.addons.GetAddonResponse.prototype.setPluralUnitName = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * repeated AddOnPlan plans = 7;
 * @return {!Array<!proto.core.private.addons.AddOnPlan>}
 */
proto.core.private.addons.GetAddonResponse.prototype.getPlansList = function() {
  return /** @type{!Array<!proto.core.private.addons.AddOnPlan>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.core.private.addons.AddOnPlan, 7));
};


/**
 * @param {!Array<!proto.core.private.addons.AddOnPlan>} value
 * @return {!proto.core.private.addons.GetAddonResponse} returns this
*/
proto.core.private.addons.GetAddonResponse.prototype.setPlansList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 7, value);
};


/**
 * @param {!proto.core.private.addons.AddOnPlan=} opt_value
 * @param {number=} opt_index
 * @return {!proto.core.private.addons.AddOnPlan}
 */
proto.core.private.addons.GetAddonResponse.prototype.addPlans = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 7, opt_value, proto.core.private.addons.AddOnPlan, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.core.private.addons.GetAddonResponse} returns this
 */
proto.core.private.addons.GetAddonResponse.prototype.clearPlansList = function() {
  return this.setPlansList([]);
};


/**
 * optional PricingModelType pricing_model_type = 8;
 * @return {!proto.core.private.addons.PricingModelType}
 */
proto.core.private.addons.GetAddonResponse.prototype.getPricingModelType = function() {
  return /** @type {!proto.core.private.addons.PricingModelType} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {!proto.core.private.addons.PricingModelType} value
 * @return {!proto.core.private.addons.GetAddonResponse} returns this
 */
proto.core.private.addons.GetAddonResponse.prototype.setPricingModelType = function(value) {
  return jspb.Message.setProto3EnumField(this, 8, value);
};


/**
 * repeated AddonPriceModel price_models = 9;
 * @return {!Array<!proto.core.private.addons.AddonPriceModel>}
 */
proto.core.private.addons.GetAddonResponse.prototype.getPriceModelsList = function() {
  return /** @type{!Array<!proto.core.private.addons.AddonPriceModel>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.core.private.addons.AddonPriceModel, 9));
};


/**
 * @param {!Array<!proto.core.private.addons.AddonPriceModel>} value
 * @return {!proto.core.private.addons.GetAddonResponse} returns this
*/
proto.core.private.addons.GetAddonResponse.prototype.setPriceModelsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 9, value);
};


/**
 * @param {!proto.core.private.addons.AddonPriceModel=} opt_value
 * @param {number=} opt_index
 * @return {!proto.core.private.addons.AddonPriceModel}
 */
proto.core.private.addons.GetAddonResponse.prototype.addPriceModels = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 9, opt_value, proto.core.private.addons.AddonPriceModel, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.core.private.addons.GetAddonResponse} returns this
 */
proto.core.private.addons.GetAddonResponse.prototype.clearPriceModelsList = function() {
  return this.setPriceModelsList([]);
};


/**
 * repeated string feature_tags = 10;
 * @return {!Array<string>}
 */
proto.core.private.addons.GetAddonResponse.prototype.getFeatureTagsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 10));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.core.private.addons.GetAddonResponse} returns this
 */
proto.core.private.addons.GetAddonResponse.prototype.setFeatureTagsList = function(value) {
  return jspb.Message.setField(this, 10, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.core.private.addons.GetAddonResponse} returns this
 */
proto.core.private.addons.GetAddonResponse.prototype.addFeatureTags = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 10, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.core.private.addons.GetAddonResponse} returns this
 */
proto.core.private.addons.GetAddonResponse.prototype.clearFeatureTagsList = function() {
  return this.setFeatureTagsList([]);
};


/**
 * optional bool has_active_linked_plans = 11;
 * @return {boolean}
 */
proto.core.private.addons.GetAddonResponse.prototype.getHasActiveLinkedPlans = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 11, false));
};


/**
 * @param {boolean} value
 * @return {!proto.core.private.addons.GetAddonResponse} returns this
 */
proto.core.private.addons.GetAddonResponse.prototype.setHasActiveLinkedPlans = function(value) {
  return jspb.Message.setProto3BooleanField(this, 11, value);
};


/**
 * optional bool is_active = 12;
 * @return {boolean}
 */
proto.core.private.addons.GetAddonResponse.prototype.getIsActive = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 12, false));
};


/**
 * @param {boolean} value
 * @return {!proto.core.private.addons.GetAddonResponse} returns this
 */
proto.core.private.addons.GetAddonResponse.prototype.setIsActive = function(value) {
  return jspb.Message.setProto3BooleanField(this, 12, value);
};


/**
 * optional bool is_image_enabled = 13;
 * @return {boolean}
 */
proto.core.private.addons.GetAddonResponse.prototype.getIsImageEnabled = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 13, false));
};


/**
 * @param {boolean} value
 * @return {!proto.core.private.addons.GetAddonResponse} returns this
 */
proto.core.private.addons.GetAddonResponse.prototype.setIsImageEnabled = function(value) {
  return jspb.Message.setProto3BooleanField(this, 13, value);
};


/**
 * optional string image_filename = 14;
 * @return {string}
 */
proto.core.private.addons.GetAddonResponse.prototype.getImageFilename = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 14, ""));
};


/**
 * @param {string} value
 * @return {!proto.core.private.addons.GetAddonResponse} returns this
 */
proto.core.private.addons.GetAddonResponse.prototype.setImageFilename = function(value) {
  return jspb.Message.setProto3StringField(this, 14, value);
};


/**
 * optional string image_url = 15;
 * @return {string}
 */
proto.core.private.addons.GetAddonResponse.prototype.getImageUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 15, ""));
};


/**
 * @param {string} value
 * @return {!proto.core.private.addons.GetAddonResponse} returns this
 */
proto.core.private.addons.GetAddonResponse.prototype.setImageUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 15, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.core.private.addons.UpdateAddonRequest.repeatedFields_ = [8,10,11];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.core.private.addons.UpdateAddonRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.core.private.addons.UpdateAddonRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.core.private.addons.UpdateAddonRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.core.private.addons.UpdateAddonRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    companyDomain: jspb.Message.getFieldWithDefault(msg, 1, ""),
    addonId: jspb.Message.getFieldWithDefault(msg, 2, 0),
    name: jspb.Message.getFieldWithDefault(msg, 3, ""),
    displayName: jspb.Message.getFieldWithDefault(msg, 4, ""),
    description: jspb.Message.getFieldWithDefault(msg, 5, ""),
    singleUnitName: jspb.Message.getFieldWithDefault(msg, 6, ""),
    pluralUnitName: jspb.Message.getFieldWithDefault(msg, 7, ""),
    plansList: jspb.Message.toObjectList(msg.getPlansList(),
    proto.core.private.addons.UpsertAddonPlan.toObject, includeInstance),
    pricingModelType: jspb.Message.getFieldWithDefault(msg, 9, 0),
    priceModelsList: jspb.Message.toObjectList(msg.getPriceModelsList(),
    proto.core.private.addons.UpsertAddonPriceModel.toObject, includeInstance),
    featureTagsList: (f = jspb.Message.getRepeatedField(msg, 11)) == null ? undefined : f,
    isImageEnabled: jspb.Message.getBooleanFieldWithDefault(msg, 12, false),
    imageFilename: jspb.Message.getFieldWithDefault(msg, 13, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.core.private.addons.UpdateAddonRequest}
 */
proto.core.private.addons.UpdateAddonRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.core.private.addons.UpdateAddonRequest;
  return proto.core.private.addons.UpdateAddonRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.core.private.addons.UpdateAddonRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.core.private.addons.UpdateAddonRequest}
 */
proto.core.private.addons.UpdateAddonRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setCompanyDomain(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setAddonId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setDisplayName(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setSingleUnitName(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setPluralUnitName(value);
      break;
    case 8:
      var value = new proto.core.private.addons.UpsertAddonPlan;
      reader.readMessage(value,proto.core.private.addons.UpsertAddonPlan.deserializeBinaryFromReader);
      msg.addPlans(value);
      break;
    case 9:
      var value = /** @type {!proto.core.private.addons.PricingModelType} */ (reader.readEnum());
      msg.setPricingModelType(value);
      break;
    case 10:
      var value = new proto.core.private.addons.UpsertAddonPriceModel;
      reader.readMessage(value,proto.core.private.addons.UpsertAddonPriceModel.deserializeBinaryFromReader);
      msg.addPriceModels(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.addFeatureTags(value);
      break;
    case 12:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsImageEnabled(value);
      break;
    case 13:
      var value = /** @type {string} */ (reader.readString());
      msg.setImageFilename(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.core.private.addons.UpdateAddonRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.core.private.addons.UpdateAddonRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.core.private.addons.UpdateAddonRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.core.private.addons.UpdateAddonRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCompanyDomain();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getAddonId();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getDisplayName();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getDescription();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getSingleUnitName();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getPluralUnitName();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getPlansList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      8,
      f,
      proto.core.private.addons.UpsertAddonPlan.serializeBinaryToWriter
    );
  }
  f = message.getPricingModelType();
  if (f !== 0.0) {
    writer.writeEnum(
      9,
      f
    );
  }
  f = message.getPriceModelsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      10,
      f,
      proto.core.private.addons.UpsertAddonPriceModel.serializeBinaryToWriter
    );
  }
  f = message.getFeatureTagsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      11,
      f
    );
  }
  f = message.getIsImageEnabled();
  if (f) {
    writer.writeBool(
      12,
      f
    );
  }
  f = message.getImageFilename();
  if (f.length > 0) {
    writer.writeString(
      13,
      f
    );
  }
};


/**
 * optional string company_domain = 1;
 * @return {string}
 */
proto.core.private.addons.UpdateAddonRequest.prototype.getCompanyDomain = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.core.private.addons.UpdateAddonRequest} returns this
 */
proto.core.private.addons.UpdateAddonRequest.prototype.setCompanyDomain = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int32 addon_id = 2;
 * @return {number}
 */
proto.core.private.addons.UpdateAddonRequest.prototype.getAddonId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.core.private.addons.UpdateAddonRequest} returns this
 */
proto.core.private.addons.UpdateAddonRequest.prototype.setAddonId = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional string name = 3;
 * @return {string}
 */
proto.core.private.addons.UpdateAddonRequest.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.core.private.addons.UpdateAddonRequest} returns this
 */
proto.core.private.addons.UpdateAddonRequest.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string display_name = 4;
 * @return {string}
 */
proto.core.private.addons.UpdateAddonRequest.prototype.getDisplayName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.core.private.addons.UpdateAddonRequest} returns this
 */
proto.core.private.addons.UpdateAddonRequest.prototype.setDisplayName = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string description = 5;
 * @return {string}
 */
proto.core.private.addons.UpdateAddonRequest.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.core.private.addons.UpdateAddonRequest} returns this
 */
proto.core.private.addons.UpdateAddonRequest.prototype.setDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string single_unit_name = 6;
 * @return {string}
 */
proto.core.private.addons.UpdateAddonRequest.prototype.getSingleUnitName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.core.private.addons.UpdateAddonRequest} returns this
 */
proto.core.private.addons.UpdateAddonRequest.prototype.setSingleUnitName = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string plural_unit_name = 7;
 * @return {string}
 */
proto.core.private.addons.UpdateAddonRequest.prototype.getPluralUnitName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.core.private.addons.UpdateAddonRequest} returns this
 */
proto.core.private.addons.UpdateAddonRequest.prototype.setPluralUnitName = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * repeated UpsertAddonPlan plans = 8;
 * @return {!Array<!proto.core.private.addons.UpsertAddonPlan>}
 */
proto.core.private.addons.UpdateAddonRequest.prototype.getPlansList = function() {
  return /** @type{!Array<!proto.core.private.addons.UpsertAddonPlan>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.core.private.addons.UpsertAddonPlan, 8));
};


/**
 * @param {!Array<!proto.core.private.addons.UpsertAddonPlan>} value
 * @return {!proto.core.private.addons.UpdateAddonRequest} returns this
*/
proto.core.private.addons.UpdateAddonRequest.prototype.setPlansList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 8, value);
};


/**
 * @param {!proto.core.private.addons.UpsertAddonPlan=} opt_value
 * @param {number=} opt_index
 * @return {!proto.core.private.addons.UpsertAddonPlan}
 */
proto.core.private.addons.UpdateAddonRequest.prototype.addPlans = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 8, opt_value, proto.core.private.addons.UpsertAddonPlan, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.core.private.addons.UpdateAddonRequest} returns this
 */
proto.core.private.addons.UpdateAddonRequest.prototype.clearPlansList = function() {
  return this.setPlansList([]);
};


/**
 * optional PricingModelType pricing_model_type = 9;
 * @return {!proto.core.private.addons.PricingModelType}
 */
proto.core.private.addons.UpdateAddonRequest.prototype.getPricingModelType = function() {
  return /** @type {!proto.core.private.addons.PricingModelType} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/**
 * @param {!proto.core.private.addons.PricingModelType} value
 * @return {!proto.core.private.addons.UpdateAddonRequest} returns this
 */
proto.core.private.addons.UpdateAddonRequest.prototype.setPricingModelType = function(value) {
  return jspb.Message.setProto3EnumField(this, 9, value);
};


/**
 * repeated UpsertAddonPriceModel price_models = 10;
 * @return {!Array<!proto.core.private.addons.UpsertAddonPriceModel>}
 */
proto.core.private.addons.UpdateAddonRequest.prototype.getPriceModelsList = function() {
  return /** @type{!Array<!proto.core.private.addons.UpsertAddonPriceModel>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.core.private.addons.UpsertAddonPriceModel, 10));
};


/**
 * @param {!Array<!proto.core.private.addons.UpsertAddonPriceModel>} value
 * @return {!proto.core.private.addons.UpdateAddonRequest} returns this
*/
proto.core.private.addons.UpdateAddonRequest.prototype.setPriceModelsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 10, value);
};


/**
 * @param {!proto.core.private.addons.UpsertAddonPriceModel=} opt_value
 * @param {number=} opt_index
 * @return {!proto.core.private.addons.UpsertAddonPriceModel}
 */
proto.core.private.addons.UpdateAddonRequest.prototype.addPriceModels = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 10, opt_value, proto.core.private.addons.UpsertAddonPriceModel, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.core.private.addons.UpdateAddonRequest} returns this
 */
proto.core.private.addons.UpdateAddonRequest.prototype.clearPriceModelsList = function() {
  return this.setPriceModelsList([]);
};


/**
 * repeated string feature_tags = 11;
 * @return {!Array<string>}
 */
proto.core.private.addons.UpdateAddonRequest.prototype.getFeatureTagsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 11));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.core.private.addons.UpdateAddonRequest} returns this
 */
proto.core.private.addons.UpdateAddonRequest.prototype.setFeatureTagsList = function(value) {
  return jspb.Message.setField(this, 11, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.core.private.addons.UpdateAddonRequest} returns this
 */
proto.core.private.addons.UpdateAddonRequest.prototype.addFeatureTags = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 11, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.core.private.addons.UpdateAddonRequest} returns this
 */
proto.core.private.addons.UpdateAddonRequest.prototype.clearFeatureTagsList = function() {
  return this.setFeatureTagsList([]);
};


/**
 * optional bool is_image_enabled = 12;
 * @return {boolean}
 */
proto.core.private.addons.UpdateAddonRequest.prototype.getIsImageEnabled = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 12, false));
};


/**
 * @param {boolean} value
 * @return {!proto.core.private.addons.UpdateAddonRequest} returns this
 */
proto.core.private.addons.UpdateAddonRequest.prototype.setIsImageEnabled = function(value) {
  return jspb.Message.setProto3BooleanField(this, 12, value);
};


/**
 * optional string image_filename = 13;
 * @return {string}
 */
proto.core.private.addons.UpdateAddonRequest.prototype.getImageFilename = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 13, ""));
};


/**
 * @param {string} value
 * @return {!proto.core.private.addons.UpdateAddonRequest} returns this
 */
proto.core.private.addons.UpdateAddonRequest.prototype.setImageFilename = function(value) {
  return jspb.Message.setProto3StringField(this, 13, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.core.private.addons.UpdateAddonResponse.repeatedFields_ = [7,9,10];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.core.private.addons.UpdateAddonResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.core.private.addons.UpdateAddonResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.core.private.addons.UpdateAddonResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.core.private.addons.UpdateAddonResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    addonId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    displayName: jspb.Message.getFieldWithDefault(msg, 3, ""),
    description: jspb.Message.getFieldWithDefault(msg, 4, ""),
    singleUnitName: jspb.Message.getFieldWithDefault(msg, 5, ""),
    pluralUnitName: jspb.Message.getFieldWithDefault(msg, 6, ""),
    plansList: jspb.Message.toObjectList(msg.getPlansList(),
    proto.core.private.addons.AddOnPlan.toObject, includeInstance),
    pricingModelType: jspb.Message.getFieldWithDefault(msg, 8, 0),
    priceModelsList: jspb.Message.toObjectList(msg.getPriceModelsList(),
    proto.core.private.addons.AddonPriceModel.toObject, includeInstance),
    featureTagsList: (f = jspb.Message.getRepeatedField(msg, 10)) == null ? undefined : f,
    hasActiveLinkedPlans: jspb.Message.getBooleanFieldWithDefault(msg, 11, false),
    isActive: jspb.Message.getBooleanFieldWithDefault(msg, 12, false),
    isImageEnabled: jspb.Message.getBooleanFieldWithDefault(msg, 13, false),
    imageFilename: jspb.Message.getFieldWithDefault(msg, 14, ""),
    imageUrl: jspb.Message.getFieldWithDefault(msg, 15, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.core.private.addons.UpdateAddonResponse}
 */
proto.core.private.addons.UpdateAddonResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.core.private.addons.UpdateAddonResponse;
  return proto.core.private.addons.UpdateAddonResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.core.private.addons.UpdateAddonResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.core.private.addons.UpdateAddonResponse}
 */
proto.core.private.addons.UpdateAddonResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setAddonId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setDisplayName(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setSingleUnitName(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setPluralUnitName(value);
      break;
    case 7:
      var value = new proto.core.private.addons.AddOnPlan;
      reader.readMessage(value,proto.core.private.addons.AddOnPlan.deserializeBinaryFromReader);
      msg.addPlans(value);
      break;
    case 8:
      var value = /** @type {!proto.core.private.addons.PricingModelType} */ (reader.readEnum());
      msg.setPricingModelType(value);
      break;
    case 9:
      var value = new proto.core.private.addons.AddonPriceModel;
      reader.readMessage(value,proto.core.private.addons.AddonPriceModel.deserializeBinaryFromReader);
      msg.addPriceModels(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.addFeatureTags(value);
      break;
    case 11:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setHasActiveLinkedPlans(value);
      break;
    case 12:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsActive(value);
      break;
    case 13:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsImageEnabled(value);
      break;
    case 14:
      var value = /** @type {string} */ (reader.readString());
      msg.setImageFilename(value);
      break;
    case 15:
      var value = /** @type {string} */ (reader.readString());
      msg.setImageUrl(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.core.private.addons.UpdateAddonResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.core.private.addons.UpdateAddonResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.core.private.addons.UpdateAddonResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.core.private.addons.UpdateAddonResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAddonId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getDisplayName();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getDescription();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getSingleUnitName();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getPluralUnitName();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getPlansList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      7,
      f,
      proto.core.private.addons.AddOnPlan.serializeBinaryToWriter
    );
  }
  f = message.getPricingModelType();
  if (f !== 0.0) {
    writer.writeEnum(
      8,
      f
    );
  }
  f = message.getPriceModelsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      9,
      f,
      proto.core.private.addons.AddonPriceModel.serializeBinaryToWriter
    );
  }
  f = message.getFeatureTagsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      10,
      f
    );
  }
  f = message.getHasActiveLinkedPlans();
  if (f) {
    writer.writeBool(
      11,
      f
    );
  }
  f = message.getIsActive();
  if (f) {
    writer.writeBool(
      12,
      f
    );
  }
  f = message.getIsImageEnabled();
  if (f) {
    writer.writeBool(
      13,
      f
    );
  }
  f = message.getImageFilename();
  if (f.length > 0) {
    writer.writeString(
      14,
      f
    );
  }
  f = message.getImageUrl();
  if (f.length > 0) {
    writer.writeString(
      15,
      f
    );
  }
};


/**
 * optional int32 addon_id = 1;
 * @return {number}
 */
proto.core.private.addons.UpdateAddonResponse.prototype.getAddonId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.core.private.addons.UpdateAddonResponse} returns this
 */
proto.core.private.addons.UpdateAddonResponse.prototype.setAddonId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.core.private.addons.UpdateAddonResponse.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.core.private.addons.UpdateAddonResponse} returns this
 */
proto.core.private.addons.UpdateAddonResponse.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string display_name = 3;
 * @return {string}
 */
proto.core.private.addons.UpdateAddonResponse.prototype.getDisplayName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.core.private.addons.UpdateAddonResponse} returns this
 */
proto.core.private.addons.UpdateAddonResponse.prototype.setDisplayName = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string description = 4;
 * @return {string}
 */
proto.core.private.addons.UpdateAddonResponse.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.core.private.addons.UpdateAddonResponse} returns this
 */
proto.core.private.addons.UpdateAddonResponse.prototype.setDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string single_unit_name = 5;
 * @return {string}
 */
proto.core.private.addons.UpdateAddonResponse.prototype.getSingleUnitName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.core.private.addons.UpdateAddonResponse} returns this
 */
proto.core.private.addons.UpdateAddonResponse.prototype.setSingleUnitName = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string plural_unit_name = 6;
 * @return {string}
 */
proto.core.private.addons.UpdateAddonResponse.prototype.getPluralUnitName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.core.private.addons.UpdateAddonResponse} returns this
 */
proto.core.private.addons.UpdateAddonResponse.prototype.setPluralUnitName = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * repeated AddOnPlan plans = 7;
 * @return {!Array<!proto.core.private.addons.AddOnPlan>}
 */
proto.core.private.addons.UpdateAddonResponse.prototype.getPlansList = function() {
  return /** @type{!Array<!proto.core.private.addons.AddOnPlan>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.core.private.addons.AddOnPlan, 7));
};


/**
 * @param {!Array<!proto.core.private.addons.AddOnPlan>} value
 * @return {!proto.core.private.addons.UpdateAddonResponse} returns this
*/
proto.core.private.addons.UpdateAddonResponse.prototype.setPlansList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 7, value);
};


/**
 * @param {!proto.core.private.addons.AddOnPlan=} opt_value
 * @param {number=} opt_index
 * @return {!proto.core.private.addons.AddOnPlan}
 */
proto.core.private.addons.UpdateAddonResponse.prototype.addPlans = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 7, opt_value, proto.core.private.addons.AddOnPlan, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.core.private.addons.UpdateAddonResponse} returns this
 */
proto.core.private.addons.UpdateAddonResponse.prototype.clearPlansList = function() {
  return this.setPlansList([]);
};


/**
 * optional PricingModelType pricing_model_type = 8;
 * @return {!proto.core.private.addons.PricingModelType}
 */
proto.core.private.addons.UpdateAddonResponse.prototype.getPricingModelType = function() {
  return /** @type {!proto.core.private.addons.PricingModelType} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {!proto.core.private.addons.PricingModelType} value
 * @return {!proto.core.private.addons.UpdateAddonResponse} returns this
 */
proto.core.private.addons.UpdateAddonResponse.prototype.setPricingModelType = function(value) {
  return jspb.Message.setProto3EnumField(this, 8, value);
};


/**
 * repeated AddonPriceModel price_models = 9;
 * @return {!Array<!proto.core.private.addons.AddonPriceModel>}
 */
proto.core.private.addons.UpdateAddonResponse.prototype.getPriceModelsList = function() {
  return /** @type{!Array<!proto.core.private.addons.AddonPriceModel>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.core.private.addons.AddonPriceModel, 9));
};


/**
 * @param {!Array<!proto.core.private.addons.AddonPriceModel>} value
 * @return {!proto.core.private.addons.UpdateAddonResponse} returns this
*/
proto.core.private.addons.UpdateAddonResponse.prototype.setPriceModelsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 9, value);
};


/**
 * @param {!proto.core.private.addons.AddonPriceModel=} opt_value
 * @param {number=} opt_index
 * @return {!proto.core.private.addons.AddonPriceModel}
 */
proto.core.private.addons.UpdateAddonResponse.prototype.addPriceModels = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 9, opt_value, proto.core.private.addons.AddonPriceModel, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.core.private.addons.UpdateAddonResponse} returns this
 */
proto.core.private.addons.UpdateAddonResponse.prototype.clearPriceModelsList = function() {
  return this.setPriceModelsList([]);
};


/**
 * repeated string feature_tags = 10;
 * @return {!Array<string>}
 */
proto.core.private.addons.UpdateAddonResponse.prototype.getFeatureTagsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 10));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.core.private.addons.UpdateAddonResponse} returns this
 */
proto.core.private.addons.UpdateAddonResponse.prototype.setFeatureTagsList = function(value) {
  return jspb.Message.setField(this, 10, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.core.private.addons.UpdateAddonResponse} returns this
 */
proto.core.private.addons.UpdateAddonResponse.prototype.addFeatureTags = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 10, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.core.private.addons.UpdateAddonResponse} returns this
 */
proto.core.private.addons.UpdateAddonResponse.prototype.clearFeatureTagsList = function() {
  return this.setFeatureTagsList([]);
};


/**
 * optional bool has_active_linked_plans = 11;
 * @return {boolean}
 */
proto.core.private.addons.UpdateAddonResponse.prototype.getHasActiveLinkedPlans = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 11, false));
};


/**
 * @param {boolean} value
 * @return {!proto.core.private.addons.UpdateAddonResponse} returns this
 */
proto.core.private.addons.UpdateAddonResponse.prototype.setHasActiveLinkedPlans = function(value) {
  return jspb.Message.setProto3BooleanField(this, 11, value);
};


/**
 * optional bool is_active = 12;
 * @return {boolean}
 */
proto.core.private.addons.UpdateAddonResponse.prototype.getIsActive = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 12, false));
};


/**
 * @param {boolean} value
 * @return {!proto.core.private.addons.UpdateAddonResponse} returns this
 */
proto.core.private.addons.UpdateAddonResponse.prototype.setIsActive = function(value) {
  return jspb.Message.setProto3BooleanField(this, 12, value);
};


/**
 * optional bool is_image_enabled = 13;
 * @return {boolean}
 */
proto.core.private.addons.UpdateAddonResponse.prototype.getIsImageEnabled = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 13, false));
};


/**
 * @param {boolean} value
 * @return {!proto.core.private.addons.UpdateAddonResponse} returns this
 */
proto.core.private.addons.UpdateAddonResponse.prototype.setIsImageEnabled = function(value) {
  return jspb.Message.setProto3BooleanField(this, 13, value);
};


/**
 * optional string image_filename = 14;
 * @return {string}
 */
proto.core.private.addons.UpdateAddonResponse.prototype.getImageFilename = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 14, ""));
};


/**
 * @param {string} value
 * @return {!proto.core.private.addons.UpdateAddonResponse} returns this
 */
proto.core.private.addons.UpdateAddonResponse.prototype.setImageFilename = function(value) {
  return jspb.Message.setProto3StringField(this, 14, value);
};


/**
 * optional string image_url = 15;
 * @return {string}
 */
proto.core.private.addons.UpdateAddonResponse.prototype.getImageUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 15, ""));
};


/**
 * @param {string} value
 * @return {!proto.core.private.addons.UpdateAddonResponse} returns this
 */
proto.core.private.addons.UpdateAddonResponse.prototype.setImageUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 15, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.core.private.addons.DeleteAddonRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.core.private.addons.DeleteAddonRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.core.private.addons.DeleteAddonRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.core.private.addons.DeleteAddonRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    companyDomain: jspb.Message.getFieldWithDefault(msg, 1, ""),
    addonId: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.core.private.addons.DeleteAddonRequest}
 */
proto.core.private.addons.DeleteAddonRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.core.private.addons.DeleteAddonRequest;
  return proto.core.private.addons.DeleteAddonRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.core.private.addons.DeleteAddonRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.core.private.addons.DeleteAddonRequest}
 */
proto.core.private.addons.DeleteAddonRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setCompanyDomain(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setAddonId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.core.private.addons.DeleteAddonRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.core.private.addons.DeleteAddonRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.core.private.addons.DeleteAddonRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.core.private.addons.DeleteAddonRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCompanyDomain();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getAddonId();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
};


/**
 * optional string company_domain = 1;
 * @return {string}
 */
proto.core.private.addons.DeleteAddonRequest.prototype.getCompanyDomain = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.core.private.addons.DeleteAddonRequest} returns this
 */
proto.core.private.addons.DeleteAddonRequest.prototype.setCompanyDomain = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int32 addon_id = 2;
 * @return {number}
 */
proto.core.private.addons.DeleteAddonRequest.prototype.getAddonId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.core.private.addons.DeleteAddonRequest} returns this
 */
proto.core.private.addons.DeleteAddonRequest.prototype.setAddonId = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.core.private.addons.DeleteAddonResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.core.private.addons.DeleteAddonResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.core.private.addons.DeleteAddonResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.core.private.addons.DeleteAddonResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    isSuccess: jspb.Message.getBooleanFieldWithDefault(msg, 1, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.core.private.addons.DeleteAddonResponse}
 */
proto.core.private.addons.DeleteAddonResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.core.private.addons.DeleteAddonResponse;
  return proto.core.private.addons.DeleteAddonResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.core.private.addons.DeleteAddonResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.core.private.addons.DeleteAddonResponse}
 */
proto.core.private.addons.DeleteAddonResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsSuccess(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.core.private.addons.DeleteAddonResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.core.private.addons.DeleteAddonResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.core.private.addons.DeleteAddonResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.core.private.addons.DeleteAddonResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getIsSuccess();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
};


/**
 * optional bool is_success = 1;
 * @return {boolean}
 */
proto.core.private.addons.DeleteAddonResponse.prototype.getIsSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.core.private.addons.DeleteAddonResponse} returns this
 */
proto.core.private.addons.DeleteAddonResponse.prototype.setIsSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.core.private.addons.Addon.repeatedFields_ = [7,8];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.core.private.addons.Addon.prototype.toObject = function(opt_includeInstance) {
  return proto.core.private.addons.Addon.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.core.private.addons.Addon} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.core.private.addons.Addon.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    displayName: jspb.Message.getFieldWithDefault(msg, 3, ""),
    singleUnitName: jspb.Message.getFieldWithDefault(msg, 4, ""),
    pluralUnitName: jspb.Message.getFieldWithDefault(msg, 5, ""),
    pricingModelType: jspb.Message.getFieldWithDefault(msg, 6, 0),
    plansList: jspb.Message.toObjectList(msg.getPlansList(),
    proto.core.private.addons.Plan.toObject, includeInstance),
    priceModelsList: jspb.Message.toObjectList(msg.getPriceModelsList(),
    proto.core.private.addons.AddonPriceModel.toObject, includeInstance),
    hasActiveLinkedPlans: jspb.Message.getBooleanFieldWithDefault(msg, 9, false),
    isActive: jspb.Message.getBooleanFieldWithDefault(msg, 10, false),
    isImageEnabled: jspb.Message.getBooleanFieldWithDefault(msg, 11, false),
    imageFilename: jspb.Message.getFieldWithDefault(msg, 12, ""),
    imageUrl: jspb.Message.getFieldWithDefault(msg, 13, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.core.private.addons.Addon}
 */
proto.core.private.addons.Addon.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.core.private.addons.Addon;
  return proto.core.private.addons.Addon.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.core.private.addons.Addon} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.core.private.addons.Addon}
 */
proto.core.private.addons.Addon.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setDisplayName(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setSingleUnitName(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setPluralUnitName(value);
      break;
    case 6:
      var value = /** @type {!proto.core.private.addons.PricingModelType} */ (reader.readEnum());
      msg.setPricingModelType(value);
      break;
    case 7:
      var value = new proto.core.private.addons.Plan;
      reader.readMessage(value,proto.core.private.addons.Plan.deserializeBinaryFromReader);
      msg.addPlans(value);
      break;
    case 8:
      var value = new proto.core.private.addons.AddonPriceModel;
      reader.readMessage(value,proto.core.private.addons.AddonPriceModel.deserializeBinaryFromReader);
      msg.addPriceModels(value);
      break;
    case 9:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setHasActiveLinkedPlans(value);
      break;
    case 10:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsActive(value);
      break;
    case 11:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsImageEnabled(value);
      break;
    case 12:
      var value = /** @type {string} */ (reader.readString());
      msg.setImageFilename(value);
      break;
    case 13:
      var value = /** @type {string} */ (reader.readString());
      msg.setImageUrl(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.core.private.addons.Addon.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.core.private.addons.Addon.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.core.private.addons.Addon} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.core.private.addons.Addon.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getDisplayName();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getSingleUnitName();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getPluralUnitName();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getPricingModelType();
  if (f !== 0.0) {
    writer.writeEnum(
      6,
      f
    );
  }
  f = message.getPlansList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      7,
      f,
      proto.core.private.addons.Plan.serializeBinaryToWriter
    );
  }
  f = message.getPriceModelsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      8,
      f,
      proto.core.private.addons.AddonPriceModel.serializeBinaryToWriter
    );
  }
  f = message.getHasActiveLinkedPlans();
  if (f) {
    writer.writeBool(
      9,
      f
    );
  }
  f = message.getIsActive();
  if (f) {
    writer.writeBool(
      10,
      f
    );
  }
  f = message.getIsImageEnabled();
  if (f) {
    writer.writeBool(
      11,
      f
    );
  }
  f = message.getImageFilename();
  if (f.length > 0) {
    writer.writeString(
      12,
      f
    );
  }
  f = message.getImageUrl();
  if (f.length > 0) {
    writer.writeString(
      13,
      f
    );
  }
};


/**
 * optional int32 id = 1;
 * @return {number}
 */
proto.core.private.addons.Addon.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.core.private.addons.Addon} returns this
 */
proto.core.private.addons.Addon.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.core.private.addons.Addon.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.core.private.addons.Addon} returns this
 */
proto.core.private.addons.Addon.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string display_name = 3;
 * @return {string}
 */
proto.core.private.addons.Addon.prototype.getDisplayName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.core.private.addons.Addon} returns this
 */
proto.core.private.addons.Addon.prototype.setDisplayName = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string single_unit_name = 4;
 * @return {string}
 */
proto.core.private.addons.Addon.prototype.getSingleUnitName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.core.private.addons.Addon} returns this
 */
proto.core.private.addons.Addon.prototype.setSingleUnitName = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string plural_unit_name = 5;
 * @return {string}
 */
proto.core.private.addons.Addon.prototype.getPluralUnitName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.core.private.addons.Addon} returns this
 */
proto.core.private.addons.Addon.prototype.setPluralUnitName = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional PricingModelType pricing_model_type = 6;
 * @return {!proto.core.private.addons.PricingModelType}
 */
proto.core.private.addons.Addon.prototype.getPricingModelType = function() {
  return /** @type {!proto.core.private.addons.PricingModelType} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {!proto.core.private.addons.PricingModelType} value
 * @return {!proto.core.private.addons.Addon} returns this
 */
proto.core.private.addons.Addon.prototype.setPricingModelType = function(value) {
  return jspb.Message.setProto3EnumField(this, 6, value);
};


/**
 * repeated Plan plans = 7;
 * @return {!Array<!proto.core.private.addons.Plan>}
 */
proto.core.private.addons.Addon.prototype.getPlansList = function() {
  return /** @type{!Array<!proto.core.private.addons.Plan>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.core.private.addons.Plan, 7));
};


/**
 * @param {!Array<!proto.core.private.addons.Plan>} value
 * @return {!proto.core.private.addons.Addon} returns this
*/
proto.core.private.addons.Addon.prototype.setPlansList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 7, value);
};


/**
 * @param {!proto.core.private.addons.Plan=} opt_value
 * @param {number=} opt_index
 * @return {!proto.core.private.addons.Plan}
 */
proto.core.private.addons.Addon.prototype.addPlans = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 7, opt_value, proto.core.private.addons.Plan, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.core.private.addons.Addon} returns this
 */
proto.core.private.addons.Addon.prototype.clearPlansList = function() {
  return this.setPlansList([]);
};


/**
 * repeated AddonPriceModel price_models = 8;
 * @return {!Array<!proto.core.private.addons.AddonPriceModel>}
 */
proto.core.private.addons.Addon.prototype.getPriceModelsList = function() {
  return /** @type{!Array<!proto.core.private.addons.AddonPriceModel>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.core.private.addons.AddonPriceModel, 8));
};


/**
 * @param {!Array<!proto.core.private.addons.AddonPriceModel>} value
 * @return {!proto.core.private.addons.Addon} returns this
*/
proto.core.private.addons.Addon.prototype.setPriceModelsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 8, value);
};


/**
 * @param {!proto.core.private.addons.AddonPriceModel=} opt_value
 * @param {number=} opt_index
 * @return {!proto.core.private.addons.AddonPriceModel}
 */
proto.core.private.addons.Addon.prototype.addPriceModels = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 8, opt_value, proto.core.private.addons.AddonPriceModel, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.core.private.addons.Addon} returns this
 */
proto.core.private.addons.Addon.prototype.clearPriceModelsList = function() {
  return this.setPriceModelsList([]);
};


/**
 * optional bool has_active_linked_plans = 9;
 * @return {boolean}
 */
proto.core.private.addons.Addon.prototype.getHasActiveLinkedPlans = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 9, false));
};


/**
 * @param {boolean} value
 * @return {!proto.core.private.addons.Addon} returns this
 */
proto.core.private.addons.Addon.prototype.setHasActiveLinkedPlans = function(value) {
  return jspb.Message.setProto3BooleanField(this, 9, value);
};


/**
 * optional bool is_active = 10;
 * @return {boolean}
 */
proto.core.private.addons.Addon.prototype.getIsActive = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 10, false));
};


/**
 * @param {boolean} value
 * @return {!proto.core.private.addons.Addon} returns this
 */
proto.core.private.addons.Addon.prototype.setIsActive = function(value) {
  return jspb.Message.setProto3BooleanField(this, 10, value);
};


/**
 * optional bool is_image_enabled = 11;
 * @return {boolean}
 */
proto.core.private.addons.Addon.prototype.getIsImageEnabled = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 11, false));
};


/**
 * @param {boolean} value
 * @return {!proto.core.private.addons.Addon} returns this
 */
proto.core.private.addons.Addon.prototype.setIsImageEnabled = function(value) {
  return jspb.Message.setProto3BooleanField(this, 11, value);
};


/**
 * optional string image_filename = 12;
 * @return {string}
 */
proto.core.private.addons.Addon.prototype.getImageFilename = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 12, ""));
};


/**
 * @param {string} value
 * @return {!proto.core.private.addons.Addon} returns this
 */
proto.core.private.addons.Addon.prototype.setImageFilename = function(value) {
  return jspb.Message.setProto3StringField(this, 12, value);
};


/**
 * optional string image_url = 13;
 * @return {string}
 */
proto.core.private.addons.Addon.prototype.getImageUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 13, ""));
};


/**
 * @param {string} value
 * @return {!proto.core.private.addons.Addon} returns this
 */
proto.core.private.addons.Addon.prototype.setImageUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 13, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.core.private.addons.AddonPriceModel.repeatedFields_ = [10];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.core.private.addons.AddonPriceModel.prototype.toObject = function(opt_includeInstance) {
  return proto.core.private.addons.AddonPriceModel.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.core.private.addons.AddonPriceModel} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.core.private.addons.AddonPriceModel.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    frequency: jspb.Message.getFieldWithDefault(msg, 3, 0),
    frequencyType: jspb.Message.getFieldWithDefault(msg, 4, 0),
    flatFeePrice: (f = msg.getFlatFeePrice()) && google_protobuf_wrappers_pb.Int32Value.toObject(includeInstance, f),
    flatFeePriceFormatted: jspb.Message.getFieldWithDefault(msg, 6, ""),
    perUnitPrice: (f = msg.getPerUnitPrice()) && google_protobuf_wrappers_pb.Int32Value.toObject(includeInstance, f),
    perUnitPriceFormatted: jspb.Message.getFieldWithDefault(msg, 8, ""),
    currency: (f = msg.getCurrency()) && proto.core.private.addons.Currency.toObject(includeInstance, f),
    tiersList: jspb.Message.toObjectList(msg.getTiersList(),
    proto.core.private.addons.UnitTier.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.core.private.addons.AddonPriceModel}
 */
proto.core.private.addons.AddonPriceModel.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.core.private.addons.AddonPriceModel;
  return proto.core.private.addons.AddonPriceModel.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.core.private.addons.AddonPriceModel} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.core.private.addons.AddonPriceModel}
 */
proto.core.private.addons.AddonPriceModel.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setFrequency(value);
      break;
    case 4:
      var value = /** @type {!proto.core.private.addons.FrequencyType} */ (reader.readEnum());
      msg.setFrequencyType(value);
      break;
    case 5:
      var value = new google_protobuf_wrappers_pb.Int32Value;
      reader.readMessage(value,google_protobuf_wrappers_pb.Int32Value.deserializeBinaryFromReader);
      msg.setFlatFeePrice(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setFlatFeePriceFormatted(value);
      break;
    case 7:
      var value = new google_protobuf_wrappers_pb.Int32Value;
      reader.readMessage(value,google_protobuf_wrappers_pb.Int32Value.deserializeBinaryFromReader);
      msg.setPerUnitPrice(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setPerUnitPriceFormatted(value);
      break;
    case 9:
      var value = new proto.core.private.addons.Currency;
      reader.readMessage(value,proto.core.private.addons.Currency.deserializeBinaryFromReader);
      msg.setCurrency(value);
      break;
    case 10:
      var value = new proto.core.private.addons.UnitTier;
      reader.readMessage(value,proto.core.private.addons.UnitTier.deserializeBinaryFromReader);
      msg.addTiers(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.core.private.addons.AddonPriceModel.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.core.private.addons.AddonPriceModel.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.core.private.addons.AddonPriceModel} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.core.private.addons.AddonPriceModel.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getFrequency();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
  f = message.getFrequencyType();
  if (f !== 0.0) {
    writer.writeEnum(
      4,
      f
    );
  }
  f = message.getFlatFeePrice();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      google_protobuf_wrappers_pb.Int32Value.serializeBinaryToWriter
    );
  }
  f = message.getFlatFeePriceFormatted();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getPerUnitPrice();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      google_protobuf_wrappers_pb.Int32Value.serializeBinaryToWriter
    );
  }
  f = message.getPerUnitPriceFormatted();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getCurrency();
  if (f != null) {
    writer.writeMessage(
      9,
      f,
      proto.core.private.addons.Currency.serializeBinaryToWriter
    );
  }
  f = message.getTiersList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      10,
      f,
      proto.core.private.addons.UnitTier.serializeBinaryToWriter
    );
  }
};


/**
 * optional int32 id = 1;
 * @return {number}
 */
proto.core.private.addons.AddonPriceModel.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.core.private.addons.AddonPriceModel} returns this
 */
proto.core.private.addons.AddonPriceModel.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.core.private.addons.AddonPriceModel.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.core.private.addons.AddonPriceModel} returns this
 */
proto.core.private.addons.AddonPriceModel.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional int32 frequency = 3;
 * @return {number}
 */
proto.core.private.addons.AddonPriceModel.prototype.getFrequency = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.core.private.addons.AddonPriceModel} returns this
 */
proto.core.private.addons.AddonPriceModel.prototype.setFrequency = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional FrequencyType frequency_type = 4;
 * @return {!proto.core.private.addons.FrequencyType}
 */
proto.core.private.addons.AddonPriceModel.prototype.getFrequencyType = function() {
  return /** @type {!proto.core.private.addons.FrequencyType} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {!proto.core.private.addons.FrequencyType} value
 * @return {!proto.core.private.addons.AddonPriceModel} returns this
 */
proto.core.private.addons.AddonPriceModel.prototype.setFrequencyType = function(value) {
  return jspb.Message.setProto3EnumField(this, 4, value);
};


/**
 * optional google.protobuf.Int32Value flat_fee_price = 5;
 * @return {?proto.google.protobuf.Int32Value}
 */
proto.core.private.addons.AddonPriceModel.prototype.getFlatFeePrice = function() {
  return /** @type{?proto.google.protobuf.Int32Value} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.Int32Value, 5));
};


/**
 * @param {?proto.google.protobuf.Int32Value|undefined} value
 * @return {!proto.core.private.addons.AddonPriceModel} returns this
*/
proto.core.private.addons.AddonPriceModel.prototype.setFlatFeePrice = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.core.private.addons.AddonPriceModel} returns this
 */
proto.core.private.addons.AddonPriceModel.prototype.clearFlatFeePrice = function() {
  return this.setFlatFeePrice(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.core.private.addons.AddonPriceModel.prototype.hasFlatFeePrice = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional string flat_fee_price_formatted = 6;
 * @return {string}
 */
proto.core.private.addons.AddonPriceModel.prototype.getFlatFeePriceFormatted = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.core.private.addons.AddonPriceModel} returns this
 */
proto.core.private.addons.AddonPriceModel.prototype.setFlatFeePriceFormatted = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional google.protobuf.Int32Value per_unit_price = 7;
 * @return {?proto.google.protobuf.Int32Value}
 */
proto.core.private.addons.AddonPriceModel.prototype.getPerUnitPrice = function() {
  return /** @type{?proto.google.protobuf.Int32Value} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.Int32Value, 7));
};


/**
 * @param {?proto.google.protobuf.Int32Value|undefined} value
 * @return {!proto.core.private.addons.AddonPriceModel} returns this
*/
proto.core.private.addons.AddonPriceModel.prototype.setPerUnitPrice = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.core.private.addons.AddonPriceModel} returns this
 */
proto.core.private.addons.AddonPriceModel.prototype.clearPerUnitPrice = function() {
  return this.setPerUnitPrice(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.core.private.addons.AddonPriceModel.prototype.hasPerUnitPrice = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional string per_unit_price_formatted = 8;
 * @return {string}
 */
proto.core.private.addons.AddonPriceModel.prototype.getPerUnitPriceFormatted = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.core.private.addons.AddonPriceModel} returns this
 */
proto.core.private.addons.AddonPriceModel.prototype.setPerUnitPriceFormatted = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional Currency currency = 9;
 * @return {?proto.core.private.addons.Currency}
 */
proto.core.private.addons.AddonPriceModel.prototype.getCurrency = function() {
  return /** @type{?proto.core.private.addons.Currency} */ (
    jspb.Message.getWrapperField(this, proto.core.private.addons.Currency, 9));
};


/**
 * @param {?proto.core.private.addons.Currency|undefined} value
 * @return {!proto.core.private.addons.AddonPriceModel} returns this
*/
proto.core.private.addons.AddonPriceModel.prototype.setCurrency = function(value) {
  return jspb.Message.setWrapperField(this, 9, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.core.private.addons.AddonPriceModel} returns this
 */
proto.core.private.addons.AddonPriceModel.prototype.clearCurrency = function() {
  return this.setCurrency(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.core.private.addons.AddonPriceModel.prototype.hasCurrency = function() {
  return jspb.Message.getField(this, 9) != null;
};


/**
 * repeated UnitTier tiers = 10;
 * @return {!Array<!proto.core.private.addons.UnitTier>}
 */
proto.core.private.addons.AddonPriceModel.prototype.getTiersList = function() {
  return /** @type{!Array<!proto.core.private.addons.UnitTier>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.core.private.addons.UnitTier, 10));
};


/**
 * @param {!Array<!proto.core.private.addons.UnitTier>} value
 * @return {!proto.core.private.addons.AddonPriceModel} returns this
*/
proto.core.private.addons.AddonPriceModel.prototype.setTiersList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 10, value);
};


/**
 * @param {!proto.core.private.addons.UnitTier=} opt_value
 * @param {number=} opt_index
 * @return {!proto.core.private.addons.UnitTier}
 */
proto.core.private.addons.AddonPriceModel.prototype.addTiers = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 10, opt_value, proto.core.private.addons.UnitTier, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.core.private.addons.AddonPriceModel} returns this
 */
proto.core.private.addons.AddonPriceModel.prototype.clearTiersList = function() {
  return this.setTiersList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.core.private.addons.UpsertAddonPriceModel.repeatedFields_ = [7];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.core.private.addons.UpsertAddonPriceModel.prototype.toObject = function(opt_includeInstance) {
  return proto.core.private.addons.UpsertAddonPriceModel.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.core.private.addons.UpsertAddonPriceModel} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.core.private.addons.UpsertAddonPriceModel.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    frequency: jspb.Message.getFieldWithDefault(msg, 2, 0),
    frequencyType: jspb.Message.getFieldWithDefault(msg, 3, 0),
    flatFeePrice: (f = msg.getFlatFeePrice()) && google_protobuf_wrappers_pb.Int32Value.toObject(includeInstance, f),
    perUnitPrice: (f = msg.getPerUnitPrice()) && google_protobuf_wrappers_pb.Int32Value.toObject(includeInstance, f),
    currencyIso3Code: jspb.Message.getFieldWithDefault(msg, 6, ""),
    tiersList: jspb.Message.toObjectList(msg.getTiersList(),
    proto.core.private.addons.UpsertAddonUnitTier.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.core.private.addons.UpsertAddonPriceModel}
 */
proto.core.private.addons.UpsertAddonPriceModel.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.core.private.addons.UpsertAddonPriceModel;
  return proto.core.private.addons.UpsertAddonPriceModel.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.core.private.addons.UpsertAddonPriceModel} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.core.private.addons.UpsertAddonPriceModel}
 */
proto.core.private.addons.UpsertAddonPriceModel.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setFrequency(value);
      break;
    case 3:
      var value = /** @type {!proto.core.private.addons.FrequencyType} */ (reader.readEnum());
      msg.setFrequencyType(value);
      break;
    case 4:
      var value = new google_protobuf_wrappers_pb.Int32Value;
      reader.readMessage(value,google_protobuf_wrappers_pb.Int32Value.deserializeBinaryFromReader);
      msg.setFlatFeePrice(value);
      break;
    case 5:
      var value = new google_protobuf_wrappers_pb.Int32Value;
      reader.readMessage(value,google_protobuf_wrappers_pb.Int32Value.deserializeBinaryFromReader);
      msg.setPerUnitPrice(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setCurrencyIso3Code(value);
      break;
    case 7:
      var value = new proto.core.private.addons.UpsertAddonUnitTier;
      reader.readMessage(value,proto.core.private.addons.UpsertAddonUnitTier.deserializeBinaryFromReader);
      msg.addTiers(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.core.private.addons.UpsertAddonPriceModel.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.core.private.addons.UpsertAddonPriceModel.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.core.private.addons.UpsertAddonPriceModel} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.core.private.addons.UpsertAddonPriceModel.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getFrequency();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getFrequencyType();
  if (f !== 0.0) {
    writer.writeEnum(
      3,
      f
    );
  }
  f = message.getFlatFeePrice();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      google_protobuf_wrappers_pb.Int32Value.serializeBinaryToWriter
    );
  }
  f = message.getPerUnitPrice();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      google_protobuf_wrappers_pb.Int32Value.serializeBinaryToWriter
    );
  }
  f = message.getCurrencyIso3Code();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getTiersList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      7,
      f,
      proto.core.private.addons.UpsertAddonUnitTier.serializeBinaryToWriter
    );
  }
};


/**
 * optional int32 id = 1;
 * @return {number}
 */
proto.core.private.addons.UpsertAddonPriceModel.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.core.private.addons.UpsertAddonPriceModel} returns this
 */
proto.core.private.addons.UpsertAddonPriceModel.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int32 frequency = 2;
 * @return {number}
 */
proto.core.private.addons.UpsertAddonPriceModel.prototype.getFrequency = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.core.private.addons.UpsertAddonPriceModel} returns this
 */
proto.core.private.addons.UpsertAddonPriceModel.prototype.setFrequency = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional FrequencyType frequency_type = 3;
 * @return {!proto.core.private.addons.FrequencyType}
 */
proto.core.private.addons.UpsertAddonPriceModel.prototype.getFrequencyType = function() {
  return /** @type {!proto.core.private.addons.FrequencyType} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {!proto.core.private.addons.FrequencyType} value
 * @return {!proto.core.private.addons.UpsertAddonPriceModel} returns this
 */
proto.core.private.addons.UpsertAddonPriceModel.prototype.setFrequencyType = function(value) {
  return jspb.Message.setProto3EnumField(this, 3, value);
};


/**
 * optional google.protobuf.Int32Value flat_fee_price = 4;
 * @return {?proto.google.protobuf.Int32Value}
 */
proto.core.private.addons.UpsertAddonPriceModel.prototype.getFlatFeePrice = function() {
  return /** @type{?proto.google.protobuf.Int32Value} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.Int32Value, 4));
};


/**
 * @param {?proto.google.protobuf.Int32Value|undefined} value
 * @return {!proto.core.private.addons.UpsertAddonPriceModel} returns this
*/
proto.core.private.addons.UpsertAddonPriceModel.prototype.setFlatFeePrice = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.core.private.addons.UpsertAddonPriceModel} returns this
 */
proto.core.private.addons.UpsertAddonPriceModel.prototype.clearFlatFeePrice = function() {
  return this.setFlatFeePrice(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.core.private.addons.UpsertAddonPriceModel.prototype.hasFlatFeePrice = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional google.protobuf.Int32Value per_unit_price = 5;
 * @return {?proto.google.protobuf.Int32Value}
 */
proto.core.private.addons.UpsertAddonPriceModel.prototype.getPerUnitPrice = function() {
  return /** @type{?proto.google.protobuf.Int32Value} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.Int32Value, 5));
};


/**
 * @param {?proto.google.protobuf.Int32Value|undefined} value
 * @return {!proto.core.private.addons.UpsertAddonPriceModel} returns this
*/
proto.core.private.addons.UpsertAddonPriceModel.prototype.setPerUnitPrice = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.core.private.addons.UpsertAddonPriceModel} returns this
 */
proto.core.private.addons.UpsertAddonPriceModel.prototype.clearPerUnitPrice = function() {
  return this.setPerUnitPrice(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.core.private.addons.UpsertAddonPriceModel.prototype.hasPerUnitPrice = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional string currency_iso_3_code = 6;
 * @return {string}
 */
proto.core.private.addons.UpsertAddonPriceModel.prototype.getCurrencyIso3Code = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.core.private.addons.UpsertAddonPriceModel} returns this
 */
proto.core.private.addons.UpsertAddonPriceModel.prototype.setCurrencyIso3Code = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * repeated UpsertAddonUnitTier tiers = 7;
 * @return {!Array<!proto.core.private.addons.UpsertAddonUnitTier>}
 */
proto.core.private.addons.UpsertAddonPriceModel.prototype.getTiersList = function() {
  return /** @type{!Array<!proto.core.private.addons.UpsertAddonUnitTier>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.core.private.addons.UpsertAddonUnitTier, 7));
};


/**
 * @param {!Array<!proto.core.private.addons.UpsertAddonUnitTier>} value
 * @return {!proto.core.private.addons.UpsertAddonPriceModel} returns this
*/
proto.core.private.addons.UpsertAddonPriceModel.prototype.setTiersList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 7, value);
};


/**
 * @param {!proto.core.private.addons.UpsertAddonUnitTier=} opt_value
 * @param {number=} opt_index
 * @return {!proto.core.private.addons.UpsertAddonUnitTier}
 */
proto.core.private.addons.UpsertAddonPriceModel.prototype.addTiers = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 7, opt_value, proto.core.private.addons.UpsertAddonUnitTier, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.core.private.addons.UpsertAddonPriceModel} returns this
 */
proto.core.private.addons.UpsertAddonPriceModel.prototype.clearTiersList = function() {
  return this.setTiersList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.core.private.addons.Currency.prototype.toObject = function(opt_includeInstance) {
  return proto.core.private.addons.Currency.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.core.private.addons.Currency} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.core.private.addons.Currency.toObject = function(includeInstance, msg) {
  var f, obj = {
    englishName: jspb.Message.getFieldWithDefault(msg, 1, ""),
    iso3Code: jspb.Message.getFieldWithDefault(msg, 2, ""),
    symbol: jspb.Message.getFieldWithDefault(msg, 3, ""),
    hasDecimal: jspb.Message.getBooleanFieldWithDefault(msg, 4, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.core.private.addons.Currency}
 */
proto.core.private.addons.Currency.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.core.private.addons.Currency;
  return proto.core.private.addons.Currency.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.core.private.addons.Currency} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.core.private.addons.Currency}
 */
proto.core.private.addons.Currency.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setEnglishName(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setIso3Code(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setSymbol(value);
      break;
    case 4:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setHasDecimal(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.core.private.addons.Currency.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.core.private.addons.Currency.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.core.private.addons.Currency} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.core.private.addons.Currency.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getEnglishName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getIso3Code();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getSymbol();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getHasDecimal();
  if (f) {
    writer.writeBool(
      4,
      f
    );
  }
};


/**
 * optional string english_name = 1;
 * @return {string}
 */
proto.core.private.addons.Currency.prototype.getEnglishName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.core.private.addons.Currency} returns this
 */
proto.core.private.addons.Currency.prototype.setEnglishName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string iso_3_code = 2;
 * @return {string}
 */
proto.core.private.addons.Currency.prototype.getIso3Code = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.core.private.addons.Currency} returns this
 */
proto.core.private.addons.Currency.prototype.setIso3Code = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string symbol = 3;
 * @return {string}
 */
proto.core.private.addons.Currency.prototype.getSymbol = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.core.private.addons.Currency} returns this
 */
proto.core.private.addons.Currency.prototype.setSymbol = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional bool has_decimal = 4;
 * @return {boolean}
 */
proto.core.private.addons.Currency.prototype.getHasDecimal = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 4, false));
};


/**
 * @param {boolean} value
 * @return {!proto.core.private.addons.Currency} returns this
 */
proto.core.private.addons.Currency.prototype.setHasDecimal = function(value) {
  return jspb.Message.setProto3BooleanField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.core.private.addons.Plan.prototype.toObject = function(opt_includeInstance) {
  return proto.core.private.addons.Plan.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.core.private.addons.Plan} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.core.private.addons.Plan.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    productName: jspb.Message.getFieldWithDefault(msg, 3, ""),
    productId: jspb.Message.getFieldWithDefault(msg, 4, 0),
    forced: jspb.Message.getBooleanFieldWithDefault(msg, 5, false),
    status: jspb.Message.getFieldWithDefault(msg, 6, 0),
    isActive: jspb.Message.getBooleanFieldWithDefault(msg, 7, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.core.private.addons.Plan}
 */
proto.core.private.addons.Plan.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.core.private.addons.Plan;
  return proto.core.private.addons.Plan.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.core.private.addons.Plan} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.core.private.addons.Plan}
 */
proto.core.private.addons.Plan.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setProductName(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setProductId(value);
      break;
    case 5:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setForced(value);
      break;
    case 6:
      var value = /** @type {!proto.core.private.addons.VisibilityType} */ (reader.readEnum());
      msg.setStatus(value);
      break;
    case 7:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsActive(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.core.private.addons.Plan.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.core.private.addons.Plan.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.core.private.addons.Plan} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.core.private.addons.Plan.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getProductName();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getProductId();
  if (f !== 0) {
    writer.writeInt32(
      4,
      f
    );
  }
  f = message.getForced();
  if (f) {
    writer.writeBool(
      5,
      f
    );
  }
  f = message.getStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      6,
      f
    );
  }
  f = message.getIsActive();
  if (f) {
    writer.writeBool(
      7,
      f
    );
  }
};


/**
 * optional int32 id = 1;
 * @return {number}
 */
proto.core.private.addons.Plan.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.core.private.addons.Plan} returns this
 */
proto.core.private.addons.Plan.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.core.private.addons.Plan.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.core.private.addons.Plan} returns this
 */
proto.core.private.addons.Plan.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string product_name = 3;
 * @return {string}
 */
proto.core.private.addons.Plan.prototype.getProductName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.core.private.addons.Plan} returns this
 */
proto.core.private.addons.Plan.prototype.setProductName = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional int32 product_id = 4;
 * @return {number}
 */
proto.core.private.addons.Plan.prototype.getProductId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.core.private.addons.Plan} returns this
 */
proto.core.private.addons.Plan.prototype.setProductId = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional bool forced = 5;
 * @return {boolean}
 */
proto.core.private.addons.Plan.prototype.getForced = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 5, false));
};


/**
 * @param {boolean} value
 * @return {!proto.core.private.addons.Plan} returns this
 */
proto.core.private.addons.Plan.prototype.setForced = function(value) {
  return jspb.Message.setProto3BooleanField(this, 5, value);
};


/**
 * optional VisibilityType status = 6;
 * @return {!proto.core.private.addons.VisibilityType}
 */
proto.core.private.addons.Plan.prototype.getStatus = function() {
  return /** @type {!proto.core.private.addons.VisibilityType} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {!proto.core.private.addons.VisibilityType} value
 * @return {!proto.core.private.addons.Plan} returns this
 */
proto.core.private.addons.Plan.prototype.setStatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 6, value);
};


/**
 * optional bool is_active = 7;
 * @return {boolean}
 */
proto.core.private.addons.Plan.prototype.getIsActive = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 7, false));
};


/**
 * @param {boolean} value
 * @return {!proto.core.private.addons.Plan} returns this
 */
proto.core.private.addons.Plan.prototype.setIsActive = function(value) {
  return jspb.Message.setProto3BooleanField(this, 7, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.core.private.addons.AddOnPlan.prototype.toObject = function(opt_includeInstance) {
  return proto.core.private.addons.AddOnPlan.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.core.private.addons.AddOnPlan} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.core.private.addons.AddOnPlan.toObject = function(includeInstance, msg) {
  var f, obj = {
    planId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    planName: jspb.Message.getFieldWithDefault(msg, 2, ""),
    productName: jspb.Message.getFieldWithDefault(msg, 3, ""),
    forceAddon: jspb.Message.getBooleanFieldWithDefault(msg, 4, false),
    isActive: jspb.Message.getBooleanFieldWithDefault(msg, 5, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.core.private.addons.AddOnPlan}
 */
proto.core.private.addons.AddOnPlan.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.core.private.addons.AddOnPlan;
  return proto.core.private.addons.AddOnPlan.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.core.private.addons.AddOnPlan} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.core.private.addons.AddOnPlan}
 */
proto.core.private.addons.AddOnPlan.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPlanId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setPlanName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setProductName(value);
      break;
    case 4:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setForceAddon(value);
      break;
    case 5:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsActive(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.core.private.addons.AddOnPlan.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.core.private.addons.AddOnPlan.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.core.private.addons.AddOnPlan} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.core.private.addons.AddOnPlan.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPlanId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getPlanName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getProductName();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getForceAddon();
  if (f) {
    writer.writeBool(
      4,
      f
    );
  }
  f = message.getIsActive();
  if (f) {
    writer.writeBool(
      5,
      f
    );
  }
};


/**
 * optional int32 plan_id = 1;
 * @return {number}
 */
proto.core.private.addons.AddOnPlan.prototype.getPlanId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.core.private.addons.AddOnPlan} returns this
 */
proto.core.private.addons.AddOnPlan.prototype.setPlanId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string plan_name = 2;
 * @return {string}
 */
proto.core.private.addons.AddOnPlan.prototype.getPlanName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.core.private.addons.AddOnPlan} returns this
 */
proto.core.private.addons.AddOnPlan.prototype.setPlanName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string product_name = 3;
 * @return {string}
 */
proto.core.private.addons.AddOnPlan.prototype.getProductName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.core.private.addons.AddOnPlan} returns this
 */
proto.core.private.addons.AddOnPlan.prototype.setProductName = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional bool force_addon = 4;
 * @return {boolean}
 */
proto.core.private.addons.AddOnPlan.prototype.getForceAddon = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 4, false));
};


/**
 * @param {boolean} value
 * @return {!proto.core.private.addons.AddOnPlan} returns this
 */
proto.core.private.addons.AddOnPlan.prototype.setForceAddon = function(value) {
  return jspb.Message.setProto3BooleanField(this, 4, value);
};


/**
 * optional bool is_active = 5;
 * @return {boolean}
 */
proto.core.private.addons.AddOnPlan.prototype.getIsActive = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 5, false));
};


/**
 * @param {boolean} value
 * @return {!proto.core.private.addons.AddOnPlan} returns this
 */
proto.core.private.addons.AddOnPlan.prototype.setIsActive = function(value) {
  return jspb.Message.setProto3BooleanField(this, 5, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.core.private.addons.UpsertAddonPlan.prototype.toObject = function(opt_includeInstance) {
  return proto.core.private.addons.UpsertAddonPlan.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.core.private.addons.UpsertAddonPlan} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.core.private.addons.UpsertAddonPlan.toObject = function(includeInstance, msg) {
  var f, obj = {
    planId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    forceAddon: jspb.Message.getBooleanFieldWithDefault(msg, 2, false),
    isActive: jspb.Message.getBooleanFieldWithDefault(msg, 3, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.core.private.addons.UpsertAddonPlan}
 */
proto.core.private.addons.UpsertAddonPlan.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.core.private.addons.UpsertAddonPlan;
  return proto.core.private.addons.UpsertAddonPlan.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.core.private.addons.UpsertAddonPlan} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.core.private.addons.UpsertAddonPlan}
 */
proto.core.private.addons.UpsertAddonPlan.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPlanId(value);
      break;
    case 2:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setForceAddon(value);
      break;
    case 3:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsActive(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.core.private.addons.UpsertAddonPlan.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.core.private.addons.UpsertAddonPlan.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.core.private.addons.UpsertAddonPlan} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.core.private.addons.UpsertAddonPlan.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPlanId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getForceAddon();
  if (f) {
    writer.writeBool(
      2,
      f
    );
  }
  f = message.getIsActive();
  if (f) {
    writer.writeBool(
      3,
      f
    );
  }
};


/**
 * optional int32 plan_id = 1;
 * @return {number}
 */
proto.core.private.addons.UpsertAddonPlan.prototype.getPlanId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.core.private.addons.UpsertAddonPlan} returns this
 */
proto.core.private.addons.UpsertAddonPlan.prototype.setPlanId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional bool force_addon = 2;
 * @return {boolean}
 */
proto.core.private.addons.UpsertAddonPlan.prototype.getForceAddon = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
};


/**
 * @param {boolean} value
 * @return {!proto.core.private.addons.UpsertAddonPlan} returns this
 */
proto.core.private.addons.UpsertAddonPlan.prototype.setForceAddon = function(value) {
  return jspb.Message.setProto3BooleanField(this, 2, value);
};


/**
 * optional bool is_active = 3;
 * @return {boolean}
 */
proto.core.private.addons.UpsertAddonPlan.prototype.getIsActive = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 3, false));
};


/**
 * @param {boolean} value
 * @return {!proto.core.private.addons.UpsertAddonPlan} returns this
 */
proto.core.private.addons.UpsertAddonPlan.prototype.setIsActive = function(value) {
  return jspb.Message.setProto3BooleanField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.core.private.addons.UnitTier.prototype.toObject = function(opt_includeInstance) {
  return proto.core.private.addons.UnitTier.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.core.private.addons.UnitTier} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.core.private.addons.UnitTier.toObject = function(includeInstance, msg) {
  var f, obj = {
    start: jspb.Message.getFieldWithDefault(msg, 1, 0),
    finish: jspb.Message.getFieldWithDefault(msg, 2, 0),
    price: jspb.Message.getFieldWithDefault(msg, 3, 0),
    priceFormatted: jspb.Message.getFieldWithDefault(msg, 4, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.core.private.addons.UnitTier}
 */
proto.core.private.addons.UnitTier.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.core.private.addons.UnitTier;
  return proto.core.private.addons.UnitTier.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.core.private.addons.UnitTier} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.core.private.addons.UnitTier}
 */
proto.core.private.addons.UnitTier.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setStart(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setFinish(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPrice(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setPriceFormatted(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.core.private.addons.UnitTier.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.core.private.addons.UnitTier.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.core.private.addons.UnitTier} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.core.private.addons.UnitTier.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStart();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getFinish();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getPrice();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
  f = message.getPriceFormatted();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
};


/**
 * optional int32 start = 1;
 * @return {number}
 */
proto.core.private.addons.UnitTier.prototype.getStart = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.core.private.addons.UnitTier} returns this
 */
proto.core.private.addons.UnitTier.prototype.setStart = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int32 finish = 2;
 * @return {number}
 */
proto.core.private.addons.UnitTier.prototype.getFinish = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.core.private.addons.UnitTier} returns this
 */
proto.core.private.addons.UnitTier.prototype.setFinish = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int32 price = 3;
 * @return {number}
 */
proto.core.private.addons.UnitTier.prototype.getPrice = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.core.private.addons.UnitTier} returns this
 */
proto.core.private.addons.UnitTier.prototype.setPrice = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional string price_formatted = 4;
 * @return {string}
 */
proto.core.private.addons.UnitTier.prototype.getPriceFormatted = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.core.private.addons.UnitTier} returns this
 */
proto.core.private.addons.UnitTier.prototype.setPriceFormatted = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.core.private.addons.UpsertAddonUnitTier.prototype.toObject = function(opt_includeInstance) {
  return proto.core.private.addons.UpsertAddonUnitTier.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.core.private.addons.UpsertAddonUnitTier} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.core.private.addons.UpsertAddonUnitTier.toObject = function(includeInstance, msg) {
  var f, obj = {
    start: jspb.Message.getFieldWithDefault(msg, 1, 0),
    finish: jspb.Message.getFieldWithDefault(msg, 2, 0),
    price: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.core.private.addons.UpsertAddonUnitTier}
 */
proto.core.private.addons.UpsertAddonUnitTier.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.core.private.addons.UpsertAddonUnitTier;
  return proto.core.private.addons.UpsertAddonUnitTier.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.core.private.addons.UpsertAddonUnitTier} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.core.private.addons.UpsertAddonUnitTier}
 */
proto.core.private.addons.UpsertAddonUnitTier.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setStart(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setFinish(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPrice(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.core.private.addons.UpsertAddonUnitTier.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.core.private.addons.UpsertAddonUnitTier.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.core.private.addons.UpsertAddonUnitTier} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.core.private.addons.UpsertAddonUnitTier.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStart();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getFinish();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getPrice();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
};


/**
 * optional int32 start = 1;
 * @return {number}
 */
proto.core.private.addons.UpsertAddonUnitTier.prototype.getStart = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.core.private.addons.UpsertAddonUnitTier} returns this
 */
proto.core.private.addons.UpsertAddonUnitTier.prototype.setStart = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int32 finish = 2;
 * @return {number}
 */
proto.core.private.addons.UpsertAddonUnitTier.prototype.getFinish = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.core.private.addons.UpsertAddonUnitTier} returns this
 */
proto.core.private.addons.UpsertAddonUnitTier.prototype.setFinish = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int32 price = 3;
 * @return {number}
 */
proto.core.private.addons.UpsertAddonUnitTier.prototype.getPrice = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.core.private.addons.UpsertAddonUnitTier} returns this
 */
proto.core.private.addons.UpsertAddonUnitTier.prototype.setPrice = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * @enum {number}
 */
proto.core.private.addons.AddonFilterType = {
  UNSPECIFIED_AFT: 0,
  WITH_LINKED_PLANS: 1,
  WITHOUT_LINKED_PLANS: 2,
  WITH_FLAT_FEE: 3,
  WITH_PER_UNIT: 4,
  WITH_TIERED: 5,
  WITH_RANGED: 6,
  WITH_VOLUME: 7
};

/**
 * @enum {number}
 */
proto.core.private.addons.FrequencyType = {
  UNSPECIFIED_FT: 0,
  DAILY: 1,
  WEEKLY: 2,
  MONTHLY: 3,
  YEARLY: 4
};

/**
 * @enum {number}
 */
proto.core.private.addons.PricingModelType = {
  UNSPECIFIED_PMT: 0,
  FLAT_FEE: 1,
  PER_UNIT: 2,
  TIERED: 3,
  RANGED: 4,
  VOLUME: 5,
  CAPPED: 6
};

/**
 * @enum {number}
 */
proto.core.private.addons.VisibilityType = {
  UNSPECIFIED_VT: 0,
  PUBLIC: 1,
  HIDDEN: 2,
  INTERNAL: 3,
  OFFSALE: 4
};

goog.object.extend(exports, proto.core.private.addons);
