import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useRouteMatch } from "react-router-dom";
import Table from "../../components/ui/table/Table";
import Text from "../../components/ui/text/Text";
import { AppState } from "../..";
import "./TaxValidationTable.scss";
import ProgressIndicator from "../../components/ui/progress-indicator/ProgressIndicator";
import TaxValidationRow from "./TaxValidationRow";
import { fetchCustomerTaxValidations } from "../../actions/customerDetailsActions";

interface IMatchParams {
  id: string;
}

const TaxValidationTable: React.FC = () => {
  const auth = useSelector((state: AppState) => state.auth);
  const customerDetailsReducer = useSelector((state: AppState) => state.customerDetailsReducer);

  const dispatch = useDispatch<Function>();
  const match = useRouteMatch<IMatchParams>();

  const [isLoadingData, setIsLoadingData] = useState(false);
  const { currentCompanyDomain } = auth;
  const { taxValidations } = customerDetailsReducer;

  const params = match?.params

  const fetchData = async () => {
    setIsLoadingData(true);
    try {
      await dispatch(fetchCustomerTaxValidations(currentCompanyDomain, params.id));
      setIsLoadingData(false);
    } catch (err) {
      setIsLoadingData(false);

      console.log(err);
    }
  };

  useEffect(() => {
    fetchData();
    return () => {};
  }, [params.id]);


  if(isLoadingData) {
    return <ProgressIndicator color="blue"></ProgressIndicator>
  }

  if (taxValidations.length === 0) {
    return null;
  }

  return (
    <Table expandable className="tax-validation-table">
      <thead>
        <tr>
          <Text content="TAX_VALIDATION_TH1" component="th" noMargin />
          <Text content="TAX_VALIDATION_TH2" component="th" noMargin />
          <th />
        </tr>
      </thead>
      <tbody>
        {taxValidations.map((data, i) => (
          <TaxValidationRow key={i} data={data} />
        ))}
      </tbody>
    </Table>
  );
};

export default TaxValidationTable;
