import React from "react";
import Panel from "../ui/panel/Panel";
import Text from "../ui/text/Text";

const QuizStep9: React.FC = () => (
  <div className="quiz quiz-step9">
    <Panel title="QUIZ_STEP9_TITLE">
      <Text content="QUIZ_STEP9_SUBTITLE" />

    </Panel>
  </div>
)

export default QuizStep9;