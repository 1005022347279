import React, { useEffect, useState, FormEvent } from "react";
import "./InvoicesCreditNotesCustomization.scss";
import { NavLink, Link, useRouteMatch } from "react-router-dom";
import Panel from "../../../ui/panel/Panel";
import Text from "../../../ui/text/Text";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../../../..";
import NavigationBar from "../../../ui/navigation-bar/NavigationBar";
import PageWrapper from "../../../ui/layout/PageWrapper";
import TabbedContainer from "../../../ui/tabbed-container/TabbedContainer";
import counterpart from "counterpart";
import { PrivateRoute } from "../../../../containers/AuthRoutes";
import { IAuthReducerState } from "../../../../reducers/authReducer";
import SupportContactDetails from "./support-contact-details/SupportContactDetails";
import { Row, Col } from "react-grid-system";
import Input from "../../../ui/input/Input";
import Button from "../../../ui/button/Button";
import FormLabel from "../../../ui/form-label/FormLabel";
import FormGroup from "../../../ui/form-group/FormGroup";
import AddressForm from "../../../address-form/AddressForm";
import { PREFERRED_PHONE_COUNTRIES } from "../../../../utils/constants";
import PhoneInput from "../../../ui/phone-input/PhoneInput";
import { IInvoicesCreditNotesCustomizationState, IFooterContent, invoiceCreditNoteCustomizationInitState } from "../../../../reducers/invoicesCreditNotesCustomizationReducer";
import {
  setInvoicesCreditNotesField,
  fetchInvoiceCreditNotesConfig,
  resetFooterContent,
  resetSupportContactDetails,
  fetchInvoiceCreditNotesFooter,
  updateInvoiceCreditNotesFooter,
  fetchInvoiceConfig,
  updateInvoiceConfig,
} from "../../../../actions/invoicesCreditNotesCustomizationActions";
import { IInvoicesFooterConfigData } from "../../../../models/Invoices";
import ProgressIndicator from "../../../ui/progress-indicator/ProgressIndicator";
import ErrorNotification from "../../../ui/error-notification/ErrorNotification";
import { setUserInterfaceField } from "../../../../actions/userInteraceActions";
import { extractGrpcErrorMessage } from "../../../../utils/commonUtils";
import { useHistory } from "react-router";


const InvoicesCreditNotesCustomization: React.FC = () => {
  const authReducer = useSelector<AppState, IAuthReducerState>(state => state.auth)
  const { footerContents, invoiceTitle } = useSelector<AppState, IInvoicesCreditNotesCustomizationState>(state => state.invoicesCreditNotesCustomizationReducer);
  const dispatch = useDispatch<Function>()
  const history = useHistory()
  const match = useRouteMatch()
  const [footerAddress, setFooterAddress] = useState({
    addressLine1: "",
    addressLine2: "",
    addressCity: "",
    addressCountry: { label: "", value: "" },
    addressPostCode: "",
    addressState: { label: "", value: "" },
  });
  const [loadingState, setLoadingState] = useState({ isLoading: false, isUpdatingPreferences: false, isLoadingData: false });
  const [errorMessage, setErrorMessage] = useState<string | undefined>("");
  const [showErrorNotification, setShowErrorNotification] = useState(false);
  const { companyName, emailAddress, phoneNumber, phoneNumberDialCode, phoneNumberDialCountry } = footerContents;

  const { addressLine1, addressLine2, addressCity, addressCountry, addressPostCode, addressState } = footerAddress;
  const { isLoadingData, isLoading, isUpdatingPreferences } = loadingState;
  const formData = { addressLine1, addressLine2, addressCity, addressCountry, addressPostCode, addressState };
  const { currentCompanyId, currentCompanyDomain } = authReducer;

  const updateFooterContent = (property: keyof IFooterContent, value: any) => {
    dispatch(setInvoicesCreditNotesField("footerContents", { ...footerContents, [property]: value }));
  };

  const updateFooterContentGroup = (value: Object) => {
    dispatch(setInvoicesCreditNotesField("footerContents", { ...footerContents, ...value }));
  };

  const updateFooterAddress = (source: any) => {
    setFooterAddress({
      addressLine1: source.addressLine1,
      addressLine2: source.addressLine2,
      addressCity: source.city || source.addressCity,
      addressCountry: { label: "", value: source.countryIso3 || source.addressCountry.value },
      addressState: { label: "", value: source.state || source.addressState.value },
      addressPostCode: source.postCode ? source.postCode : source.addressPostCode,
    });
  };

  const fetchData = async () => {
    setLoadingState({ ...loadingState, isLoadingData: true });

    try {
      await dispatch(fetchInvoiceCreditNotesFooter(currentCompanyId as number));
      await dispatch(fetchInvoiceConfig(currentCompanyId as number));
      await dispatch(fetchInvoiceCreditNotesConfig(currentCompanyDomain));
      setLoadingState({ ...loadingState, isLoadingData: false });
    } catch (err) {
      setLoadingState({ ...loadingState, isLoadingData: false });
    }
  };

  useEffect(() => {
    window.scroll(0, 0);
    dispatch(setUserInterfaceField("autoScroll", false));
    fetchData();

    return () => {
      dispatch(setUserInterfaceField("autoScroll", true));
      dispatch(resetSupportContactDetails());
    };
  }, []);

  useEffect(() => {
    if (footerContents) {
      setFooterAddress({
        addressLine1: footerContents.addressLine1,
        addressLine2: footerContents.addressLine2,
        addressCity: footerContents.addressCity,
        addressCountry: { label: "", value: footerContents.addressCountry.value },
        addressState: { label: "", value: footerContents.addressState.value },
        addressPostCode: footerContents.addressPostCode,
      });
    }
  }, [footerContents.addressLine1]);

  const onSubmit = async (evt: FormEvent) => {
    evt.preventDefault();

    const footerConfigData: IInvoicesFooterConfigData = {
      companyName: companyName || companyName,
      address: {
        addressLine1,
        addressLine2,
        state: addressState.value,
        city: addressCity,
        country: addressCountry.value,
        postCode: addressPostCode,
      },
      emailAddress,
      phoneNumber,
      phoneNumberDialCode,
      phoneNumberDialCountry,
    };

    setLoadingState({ ...loadingState, isLoading: true });

    dispatch(updateInvoiceCreditNotesFooter(currentCompanyId as number, footerConfigData))
      .then((res:any) => {
        if(!!res.error) {
          const errorMessage = extractGrpcErrorMessage(res.error.message);
          setErrorMessage(errorMessage);
          setShowErrorNotification(true);
        }
      }).finally(() => setLoadingState({ ...loadingState, isLoading: false }));
  };

  const onUpdatePreferences = async (evt: FormEvent) => {
    evt.preventDefault();
    setLoadingState({ ...loadingState, isUpdatingPreferences: true });
    try {
      await dispatch(updateInvoiceConfig(currentCompanyId as number, invoiceTitle));
      setLoadingState({ ...loadingState, isUpdatingPreferences: false });
    } catch (err) {
      console.log(err);
      setLoadingState({ ...loadingState, isUpdatingPreferences: false });
    }
  };

  const handleResetFooterContent = () => {
    dispatch(resetFooterContent());
    updateFooterAddress(footerContents);
  };

  const renderTerminologyForm = () => {
    return (
      <>
        <form className="invoices-credit-notes-customization__form" onSubmit={onUpdatePreferences}>
          <Panel title="INVOICE_CREDIT_NOTE_CUSTOMIZATION_TERMINOLOGY_TITLE">
            <Text content="INVOICE_CREDIT_NOTE_CUSTOMIZATION_TERMINOLOGY_CONTENT" />
            <FormGroup>
              <Row align="center">
                <Col md={1.5}>
                  <FormLabel target="invoice-title" content="INVOICE_CREDIT_NOTE_CUSTOMIZATION_TERMINOLOGY_LABEL" />
                </Col>
                <Col md={10.5}>
                  <Input
                    id="invoice-title"
                    value={invoiceTitle}
                    placeholder={counterpart("INVOICE_CREDIT_NOTE_CUSTOMIZATION_TERMINOLOGY_LABEL")}
                    onChange={(evt: any) => dispatch(setInvoicesCreditNotesField("invoiceTitle", evt.target.value))}
                  />
                </Col>
              </Row>
            </FormGroup>
            <Row align="center">
              <Col md={8}>
                <Button 
                  id="invoice-title-update"
                  type="submit" 
                  isLoading={isUpdatingPreferences} 
                  isFullWidth 
                  buttonType="general" 
                  title="INVOICE_CREDIT_NOTE_CUSTOMIZATION_TERMINOLOGY_BTN" 
                />
              </Col>
              <Col md={4}>
                <Button
                  id="invoice-title-cancel"
                  isFullWidth
                  buttonType="error"
                  title="INVOICE_CREDIT_NOTE_CUSTOMIZATION_BUTTON_CANCEL"
                  onClick={() => dispatch(setInvoicesCreditNotesField("invoiceTitle", invoiceCreditNoteCustomizationInitState.invoiceTitle))}
                />
              </Col>
            </Row>
          </Panel>
        </form>
      </>
    );
  };

  const renderFooterContents = () => {
    return (
      <>
        <form className="invoices-credit-notes-customization__form" onSubmit={onSubmit}>
          <Panel title="INVOICE_CREDIT_NOTE_CUSTOMIZATION_PANEL1_TITLE">
            <Text
              content="INVOICE_CREDIT_NOTE_CUSTOMIZATION_PANEL1_CONTENT"
              translateWith={{ link: <Link to="/configuration/taxes">{counterpart("INVOICE_CREDIT_NOTE_CUSTOMIZATION_PANEL1_CONTENT_1")}</Link> }}
            />
            <FormGroup>
              <Row align="center">
                <Col md={1.5}>
                  <FormLabel target="company-name" content="INVOICE_CREDIT_NOTE_CUSTOMIZATION_LABEL_NAME" />
                </Col>
                <Col md={10.5}>
                  <Input id="company-name" required value={companyName || companyName} onChange={(evt: any) => updateFooterContent("companyName", evt.target.value)} />
                </Col>
              </Row>
            </FormGroup>
            <FormGroup>
              <Row align="start">
                <Col md={1.5}>
                  <FormLabel target="company-address" content="INVOICE_CREDIT_NOTE_CUSTOMIZATION_LABEL_ADDRESS" />
                </Col>
                <Col md={10.5}>
                  <AddressForm
                    onChangeAddressCity={(city) => setFooterAddress({ ...footerAddress, addressCity: city })}
                    onChangeAddressLine1={(line1) => setFooterAddress({ ...footerAddress, addressLine1: line1 })}
                    onChangeAddressLine2={(line2) => setFooterAddress({ ...footerAddress, addressLine2: line2 })}
                    onChangeAddressPostCode={(code) => setFooterAddress({ ...footerAddress, addressPostCode: code })}
                    onChangeAddressState={(state: any) => setFooterAddress((prev) => ({ ...prev, addressState: state }))}
                    onChangeAddressCountry={(country: any) => setFooterAddress((prev) => ({ ...prev, addressCountry: country }))}
                    formData={formData}
                    formErrors={false}
                    isRequired={false}
                  />
                </Col>
              </Row>
            </FormGroup>
            <FormGroup>
              <Row align="center">
                <Col md={1.5}>
                  <FormLabel target="company-email" content="INVOICE_CREDIT_NOTE_CUSTOMIZATION_LABEL_EMAIL_ADDRESS" noMargin />
                </Col>
                <Col md={10.5}>
                  <Input
                    id="company-email"
                    placeholder={counterpart("INVOICE_CREDIT_NOTE_CUSTOMIZATION_LABEL_EMAIL_ADDRESS_PLACEHOLDER")}
                    value={emailAddress}
                    onChange={(evt: any) => updateFooterContent("emailAddress", evt.target.value)}
                  />
                </Col>
              </Row>
            </FormGroup>
            <FormGroup>
              <Row align="center">
                <Col md={1.5}>
                  <FormLabel target="company-phone" content="INVOICE_CREDIT_NOTE_CUSTOMIZATION_LABEL_PHONE_NUMBER" noMargin />
                </Col>
                <Col md={10.5}>
                  <PhoneInput
                    preferredCountries={PREFERRED_PHONE_COUNTRIES.countries}
                    value={phoneNumber}
                    defaultCountry={phoneNumberDialCountry}
                    dialCode={phoneNumberDialCode}
                    onPhoneNumberChange={(isValid: boolean, phone: string, countryData: any) => {
                      updateFooterContentGroup({ phoneNumber: phone, phoneNumberDialCode: countryData.dialCode, phoneNumberDialCountry: countryData.iso2 });
                    }}
                    onSelectFlag={(phone: string, countryData: any) => {
                      updateFooterContentGroup({ phoneNumber: phone, phoneNumberDialCode: countryData.dialCode, phoneNumberDialCountry: countryData.iso2 });
                    }}
                    errorLabel="REGISTRATION_PHONE_VALIDATION_ERROR"
                    separateDialCode
                  />
                </Col>
              </Row>
            </FormGroup>
            <Row align="center">
              <Col md={8}>
                <Button id="support-contact-details-update" type="submit" isLoading={isLoading} isFullWidth buttonType="general" title="INVOICE_CREDIT_NOTE_CUSTOMIZATION_BUTTON_UPDATE_FOOTER" />
              </Col>
              <Col md={4}>
                <Button id="support-contact-details-cancel" isFullWidth buttonType="error" title="INVOICE_CREDIT_NOTE_CUSTOMIZATION_BUTTON_CANCEL" onClick={() => handleResetFooterContent()} />
              </Col>
            </Row>
          </Panel>
        </form>
      </>
    );
  };

  const renderTabContents = () => {
    const tabArray = [
      { label: "PAID_INVOICES", navLink: "" },
      { label: "PENDING_INVOICES", navLink: "/pending-invoices" },
      { label: "UNPAID_INVOICES", navLink: "/unpaid-invoices" },
      { label: "PAID_CREDIT_NOTES", navLink: "/paid-credit-notes" },
      { label: "PENDING_CREDIT_NOTES", navLink: "/pending-credit-notes" },
      { label: "FAILED_CREDIT_NOTES", navLink: "/failed-credit-notes" },
    ];

    return (
      <>
        <TabbedContainer type="submenu">
          {tabArray.map((tab, idx) => (
            <NavLink to={`${match?.url}${tab.navLink}`} exact activeClassName="active" key={idx}>
              {counterpart(`INVOICE_CREDIT_NOTE_CUSTOMIZATION_${tab.label}`)}
            </NavLink>
          ))}
        </TabbedContainer>
            

        <PrivateRoute exact path={match?.path} component={SupportContactDetails} />
        <PrivateRoute path={`${match?.path}/:propertyData`} component={SupportContactDetails} />
      </>
    );
  };

  if (isLoadingData) {
    return <ProgressIndicator color="blue" coverage="full-content" />;
  }

  return (
    <div className="invoices-credit-notes-customization">
      <NavigationBar pageTitle="" previousPageTitle="CONFIGURATION_PAGE_TITLE" previousPageCb={() => history.push("/configuration")} />

      <PageWrapper>
        <ErrorNotification
          title={errorMessage}
          shouldTranslateTitle={false}
          showSubtitle={false}
          showAlert={showErrorNotification}
          onClose={() => setShowErrorNotification(false)}
        />
        <Panel title="INVOICE_CREDIT_NOTE_CUSTOMIZATION_MENU_ITEM_TITLE" className="invoices-credit-notes-customization__heading">
          <Text
            content="INVOICE_CREDIT_NOTE_CUSTOMIZATION_HEADER_CONTENT"
            translateWith={{ link: <Link to="/configuration/brand-customization">{counterpart("INVOICE_CREDIT_NOTE_CUSTOMIZATION_BRAND_SETTINGS")}</Link> }}
            noMargin
          />
        </Panel>
        {renderTerminologyForm()}
        {renderFooterContents()}
        {renderTabContents()}
      </PageWrapper>
    </div>
  );
};

export default InvoicesCreditNotesCustomization