import React from "react";
import "./TitleSection.scss";
import Text from "../text/Text";

interface ITitleSection {
  title: string,
  subTitle: string,
  shouldTranslateTitle?: boolean,
  shouldTranslateSubtitle?: boolean,
  translateTitleWith?: object,
  translateSubTitleWith?: object
}

const TitleSection: React.FC<ITitleSection> = React.memo(({ title, subTitle, shouldTranslateTitle = true, shouldTranslateSubtitle = true, translateTitleWith = {}, translateSubTitleWith={}  }) => {
  return (
    <div className={"title-section"}>
      <Text content={title} shouldTranslate={shouldTranslateTitle} translateWith={translateTitleWith}  />
      <Text content={subTitle} shouldTranslate={shouldTranslateSubtitle} translateWith={translateSubTitleWith} noMargin />
    </div>
  )
})

export default TitleSection;