import React from "react";
import "./InvoicePreview.scss";
import Text from "../../../../ui/text/Text";
import { AppState } from "../../../../..";
import { useSelector } from "react-redux";
import Receipt from "../../../../receipt/Receipt";
import { COLOR } from "../../../../../utils/constants";
import Table from "../../../../ui/table/Table";
import counterpart from "counterpart";

const InvoicePreview: React.FC = () => {
  const companyBrandingReducer = useSelector((state: AppState) => state.companyBrandingReducer);

  const { logoUrl, highlightColor, logoBackgroundColor } = companyBrandingReducer;
  const defaultLogoBackgroundColor = "#1E2432";
  const defaultHighLightColor = "#F87847";

  const _logoBackgroundColor = !!logoBackgroundColor ? logoBackgroundColor : defaultLogoBackgroundColor;
  const _highlightColor = !!highlightColor ? highlightColor : defaultHighLightColor;

  const placeholderBillingAddress =  { addressLine1: counterpart("BRAND_CUSTOMISATION_ADDRESS_LINE1"), addressCity: counterpart("BRAND_CUSTOMISATION_ADDRESS_LINE2"), addressCountry: counterpart("BRAND_CUSTOMISATION_ADDRESS_COUNTRY"), addressPostCode: "1234", addressState: counterpart("BRAND_CUSTOMISATION_ADDRESS_STATE") };
  
  const renderPlanTables = () => {
    const subscriptions = [{ planName: counterpart("BRAND_CUSTOMISATION_PLAN_NAME"), subscriptionUniqueId: "B531DS6357Z1", billingPeriodStart: counterpart("BRAND_CUSTOMISATION_DATE_START"), billingPeriodEnd: counterpart("BRAND_CUSTOMISATION_DATE_END"), subscriptionItems: [ { description: counterpart("BRAND_CUSTOMISATION_PLAN_DESCRIPTION"), units: "1", unitPrice: "$10.00", amount: "$10.00" }] }]
  
    const tdProps = { shouldTranslate: false, component: "td", noMargin: true };
    const thProps = { style: { color: _highlightColor }, component: "th", noMargin: true }
    return (
      <>
        {subscriptions !== null &&
          subscriptions.map((subscription: any, idx: number) => (
            <React.Fragment key={idx}>
              <Receipt.PlanHeader
                highlightColor={_highlightColor}
                content={{
                  planName: subscription.planDisplayName || subscription.planName,
                  subscriptionUniqueId: subscription.subscriptionUniqueId,
                  billingPeriod: `${subscription.billingPeriodStart} - ${subscription.billingPeriodEnd}`
                }}
              />
  
              <Table className="invoice-details__table">
                <thead>
                  <tr>
                    <Text {...thProps} content="INVOICE_DETAILS_TABLE_DESCRIPTION" />
                    <Text {...thProps} content="INVOICE_DETAILS_TABLE_UNITS" />
                    <Text {...thProps} content="INVOICE_DETAILS_TABLE_UNIT_PRICE" />
                    <Text {...thProps} content="INVOICE_DETAILS_TABLE_DISCOUNTS" />
                    <Text
                      {...thProps}
                      content="INVOICE_DETAILS_TABLE_AMOUNT"
                      translateWith={{
                        currency: "USD"
                      }}
                    />
                  </tr>
                </thead>
                <tbody>
                  {subscription.subscriptionItems.map((item: any, idx: number) => {
                    return (
                      <tr key={idx}>
                        <Text {...tdProps} content={item.description} />
                        <Text {...tdProps} content={item.units} />
                        <Text {...tdProps} content={item.unitPrice} />
                        <Text {...tdProps} content={item.discounts} />
                        <Text {...tdProps} content={item.amount} />
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
            </React.Fragment>
          ))}
      </>
    );
  };
 
  return (
    <div className="invoice-preview-container">
      <div className="invoice-preview-container__content">
        <div className="invoice-preview__container">
          <Receipt.Container className="invoice-details">
            <Receipt.Header title="INVOICE_DETAILS_TITLE" backgroundColor={_logoBackgroundColor} logoUrl={logoUrl} />
            <Receipt.Body>
              <Receipt.GeneralInfo
                content={{
                  fullName: counterpart("BRAND_CUSTOMISATION_CUSTOMER_NAME"),
                  billingAddress: placeholderBillingAddress,
                  referenceNumber: {
                    label: "INVOICE_DETAILS_REF",
                    value: "A153YZ6312A3-001"
                  },
                  createdOn: {
                    label: "INVOICE_DETAILS_DATE",
                    value: counterpart("BRAND_CUSTOMISATION_DATE_START")
                  },
                  amount: {
                    label: "INVOICE_DETAILS_AMOUNT",
                    value: "$10.00 (USD)"
                  },
                  customerId: {
                    label: "INVOICE_DETAILS_CUSTOMER_ID",
                    value: "A153YZ6312A3"
                  },
                  status: {
                    color: COLOR.GREEN,
                    content: "INVOICE_STATUS_PAID"
                  }
                }}
              />
              {renderPlanTables()}
              <Receipt.Summary
                highlightColor={_highlightColor}
                content={{
                  subtotalAmount: "$10.00",
                  prorationAdjustment: "$0",
                  prorationFactor: undefined,
                  taxAmount: "$0.00",
                  totalAmount: "$10.00",
                  taxPercentage: 0,
                  taxes: []
                }}
              />
              <Receipt.Footer
                backgroundColor={_logoBackgroundColor}
                content={{
                  companyName: counterpart("BRAND_CUSTOMISATION_COMPANY_NAME"),
                  companyPhone: counterpart("BRAND_CUSTOMISATION_COMPANY_PHONE"),
                  companyEmail: counterpart("BRAND_CUSTOMISATION_COMPANY_EMAIL"),
                  companyTaxLabel: "",
                  companyTaxNumber: counterpart("BRAND_CUSTOMISATION_COMPANY_TAX"),
                  companyAddress: placeholderBillingAddress
                }}
              />
            </Receipt.Body>
          </Receipt.Container>
        </div>
      </div>
    </div>
  );
};

export default InvoicePreview;
