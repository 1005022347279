import React, { useEffect, useState } from "react";
import "./TwoFactorAuth.scss";
import Panel from "../ui/panel/Panel";
import Text from "../ui/text/Text";
import Button from "../ui/button/Button";
import Input from "../ui/input/Input";
import FormGroup from "../ui/form-group/FormGroup";
import FormLabel from "../ui/form-label/FormLabel";
import API from "../../utils/API";
import { IQRCode } from "../../models/TwoFactorAuth";
import counterpart from "counterpart";
import InfoLabel from "../ui/info-label/InfoLabel";

const TwoFactorAuthDisable: React.FC = () => {
  const [sixDigitsPart1, setSixDigitsPart1] = useState("");
  const [sixDigitsPart2, setSixDigitsPart2] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState<any>(null);
  const [isSuccessful, setIsSuccessful] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if(sixDigitsPart1.length >= 3) {
      const inputDigit = document.querySelector("#six-digit-second-part") as HTMLInputElement;
      inputDigit && inputDigit.focus()
    }

    if(sixDigitsPart2.length >= 3) {
      const inputPassword = document.querySelector("#password") as HTMLInputElement;
      inputPassword && inputPassword.focus()
    }
  }, [sixDigitsPart1, sixDigitsPart2])

  const onSubmit = async () => {
    setIsLoading(true);
    setIsSuccessful(false);
    setError(null);

    try {
      const qrCode: IQRCode = {
        active: false,
        code: `${sixDigitsPart1}${sixDigitsPart2}`,
        password
      }
      await API.activateDeactivateQRCode(qrCode);
      setIsSuccessful(true)
    } catch (err) {
      setError(err && err.list && err.list.length && err.list[0].description)
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <div className="two-factor-auth-disable">
      <Panel title="TWO_FACTOR_AUTH_MODAL_DISABLE_HEAD_TITLE">
        <Text content="TWO_FACTOR_AUTH_MODAL_DISABLE_HEAD_CONTENT" noMargin />
      </Panel>

      <Panel title="TWO_FACTOR_AUTH_MODAL_DISABLE_TURNOFF_TITLE" className="two-factor-auth-disable__turnoff">
        <Text content="TWO_FACTOR_AUTH_MODAL_DISABLE_TURNOFF_SUBTITLE" />

        <FormGroup className="two-factor-auth-disable__sixdigits">
          <FormLabel content="TWO_FACTOR_AUTH_MODAL_DISABLE_TURNOFF_SIX_DIGITS" target="six-digit-first-part" noMargin />
          <div>
            <Input
              id="six-digit-first-part"
              value={sixDigitsPart1}
              onChange={(evt: any) => setSixDigitsPart1(evt.target.value)}
              maxLength="3"
              placeholder="123"
            />
            <Input
              id="six-digit-second-part"
              value={sixDigitsPart2}
              onChange={(evt: any) => setSixDigitsPart2(evt.target.value)}
              maxLength="3"
              placeholder="456"
            />
            <InfoLabel content="TWO_FACTOR_AUTH_ERROR_LOGIN_EMPTY_WRONG_CODE" type="error" noMargin isVisible={!!error && error === "Invalid 2FA six-digit code"} />
          </div>
        </FormGroup>

        <div className="two-factor-auth-disable__password">
          <FormLabel content="TWO_FACTOR_AUTH_MODAL_DISABLE_TURNOFF_PSW" target="password" noMargin />
          <Input
            id="password"
            type="password"
            value={password}
            isError={!!error && error === "Invalid password" ? true : false}
            errorMsg={counterpart("TWO_FACTOR_AUTH_ERROR_PASSWORD")}
            onChange={(evt: any) => setPassword(evt.target.value)}
            placeholder="**********"
          />
        </div>

      </Panel>

      <Button
        id="turnoff-2fa"
        title="TWO_FACTOR_AUTH_MODAL_DISABLE_BTN"
        isFullWidth
        buttonType={error ? "error" : isSuccessful ? "success" : "general"}
        disabled={isLoading || !sixDigitsPart1 || !sixDigitsPart2 || !password || isSuccessful}
        isLoading={isLoading}
        onClick={onSubmit}
      />
    </div>
  )
}

export default TwoFactorAuthDisable;