import React, { useState, useEffect } from "react";
import Panel from "../../../ui/panel/Panel";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../../../..";
import Text from "../../../ui/text/Text";
import Table from "../../../ui/table/Table";
import { Row, Col } from "react-grid-system";
import "./BillsbyCancellation.scss";
import moment from "moment";
import Button from "../../../ui/button/Button";
import Checkbox from "../../../ui/checkbox/Checkbox";
import { requestCompanyDeletion, cancelRequestCompanyDeletion, fetchGettingStartedSettings, deleteCompany } from "../../../../actions/authActions";
import history from "../../../../utils/history";

const BillsbyCancellation: React.FC = () => {
  const authData = useSelector((state: AppState) => state.auth);

  const dispatch = useDispatch<Function>();

  const { dashboardSettings, currentCompanyId, companyName, currentCompanyDomain } = authData;
  const [isLoading, setIsLoading] = useState(false);
  const [options, setOptions] = useState([false, false, false]);

  useEffect(() => {
    //Redirect to cancelled page 
    if(dashboardSettings && dashboardSettings.deletedCompany) {
      history.push("/cancelled");
    }
  }, [dashboardSettings])


  if (!dashboardSettings) {
    return null;
  }

  const { hasActiveDeletionRequest, isReadyForDeletion, deletionActiveDate } = dashboardSettings;

  const optionsList = [
    "CONFIGURATION_CANCEL_ACCOUNT_OPT_1",
    "CONFIGURATION_CANCEL_ACCOUNT_OPT_2",
    "CONFIGURATION_CANCEL_ACCOUNT_OPT_3"
  ]

  const getButtonText = () => {
    if (isReadyForDeletion) {
      return "CONFIGURATION_CANCEL_ACCOUNT_CANCEL_BILLSBY_BUTTON"
    }

    if (hasActiveDeletionRequest) {
      return "CONFIGURATION_CANCEL_ACCOUNT_KEEP_BILLSBY_BUTTON"
    }
    
    return "CONFIGURATION_CANCEL_ACCOUNT_BUTTON";
  }

  const onButtonClick = async () => {
    setIsLoading(true);

    try {
      if (isReadyForDeletion) {
        await dispatch(deleteCompany(currentCompanyId as number));
        return;
      }

      if (hasActiveDeletionRequest) {
        await dispatch(cancelRequestCompanyDeletion(currentCompanyId as number));
        await dispatch(fetchGettingStartedSettings(currentCompanyDomain));
        setIsLoading(false);
        return;
      }

      await dispatch(requestCompanyDeletion(currentCompanyId as number));
      await dispatch(fetchGettingStartedSettings(currentCompanyDomain));
      setIsLoading(false);

    } catch (err) {
      console.log(err);
      setIsLoading(false);
    }
  }

  const getDate = () => {
    var date = new Date();
    date.setDate(date.getDate() + 14);

    return ({
      date: moment.utc(deletionActiveDate || date).format("DD MMM YYYY"),
      time: moment.utc(deletionActiveDate || date).format("HH:mm")
    })
  }

  const renderContent = () => {
    if (hasActiveDeletionRequest && !isReadyForDeletion) {
      return (
        <Panel>
          <Text content="CONFIGURATION_CANCEL_ACCOUNT_PANEL_4_CONTENT" translateWith={{
            date: getDate().date,
            time: getDate().time
          }} noMargin />
        </Panel>
      )
    }

    return (
      <>
        <Panel>
          <Text content="CONFIGURATION_CANCEL_ACCOUNT_PANEL_2_CONTENT" />
          <Table className="billsby-cancellation__info-table">
            <tbody>
              <tr className="no-max-height">
                <td>
                  <Row justify="start" className="billsby-cancellation__info-table__row">
                    <Col md={3}>
                      <Text className="billsby-cancellation__info-table__label" content="CONFIGURATION_CANCEL_ACCOUNT_PANEL_2_CONTENT_SUBSCRIPTIONS" noMargin />
                    </Col>
                    <Col md={9}>
                      <Text content="CONFIGURATION_CANCEL_ACCOUNT_PANEL_2_CONTENT_SUBSCRIPTIONS_DESCRIPTION" noMargin />
                    </Col>
                  </Row>
                </td>
              </tr>
              <tr className="no-max-height">
                <td>
                  <Row justify="start" className="billsby-cancellation__info-table__row">
                    <Col md={3}>
                      <Text className="billsby-cancellation__info-table__label" content="CONFIGURATION_CANCEL_ACCOUNT_PANEL_2_CONTENT_DATA" noMargin />
                    </Col>
                    <Col md={9}>
                      <Text content="CONFIGURATION_CANCEL_ACCOUNT_PANEL_2_CONTENT_DATA_DESCRIPTION" noMargin />
                    </Col>
                  </Row>
                </td>
              </tr>
              <tr className="no-max-height">
                <td>
                  <Row justify="start" className="billsby-cancellation__info-table__row">
                    <Col md={3}>
                      <Text className="billsby-cancellation__info-table__label" content="CONFIGURATION_CANCEL_ACCOUNT_PANEL_2_CONTENT_TEAMMATES" noMargin />
                    </Col>
                    <Col md={9}>
                      <Text content="CONFIGURATION_CANCEL_ACCOUNT_PANEL_2_CONTENT_TEAMMATES_DESCRIPTION" shouldTranslate={true} translateWith={{ companyName: companyName }} noMargin />
                    </Col>
                  </Row>
                </td>
              </tr>
            </tbody>
          </Table>
        </Panel>

        <Panel>
          <Text content={isReadyForDeletion ? "CONFIGURATION_CANCEL_ACCOUNT_PANEL_5_CONTENT" : "CONFIGURATION_CANCEL_ACCOUNT_PANEL_3_CONTENT"} translateWith={{ date: getDate().date }} noMargin />
        </Panel>

        {
          isReadyForDeletion && (
            <Panel>
              <Text content="CONFIGURATION_CANCEL_ACCOUNT_PANEL_6_CONTENT"></Text>
              {
                optionsList.map((opt, idx) => (
                  <Checkbox
                    key={idx}
                    checked={options[idx]}
                    onClick={() => setOptions(options.map((o, i) => i === idx ? !o : o))}
                    value={""}
                    content={<Text content={opt} translateWith={{ companyName: companyName }} noMargin />}
                  />)
                )
              }
            </Panel>
          )
        }
      </>
    )
  }

  return (
    <div>
      <Panel title="CONFIGURATION_CANCEL_ACCOUNT_TITLE" className="billsby-cancellation__title-panel" translateWith={{ companyName: companyName }}>
        <Text content='CONFIGURATION_CANCEL_ACCOUNT_CONTENT' className="billsby-cancellation__title-panel-text" noMargin />
      </Panel>
      {renderContent()}
      <Button
        id="billsby-cancellation-button"
        type="submit"
        disabled={isReadyForDeletion ? !options.every(opt => opt) : false}
        buttonType={"general"}
        isLoading={isLoading}
        title={getButtonText()}
        translateWith={{ companyName: companyName.toUpperCase() }}
        isFullWidth
        onClick={onButtonClick}
      />
    </div>
  )
}


export default BillsbyCancellation;
