import React from "react";
import { useSelector } from "react-redux";
import { AppState } from "../../..";
import AustraliaTaxConfigFlow from "./tax-config-flow/AustraliaTaxConfigFlow";
import NewZealandTaxConfigFlow from "./tax-config-flow/NewZealandTaxConfigFlow";
import EuropeanUnionTaxConfigFlow from "./tax-config-flow/EuropeanUnionTaxConfigFlow";
import IndiaTaxConfigFlow from "./tax-config-flow/IndiaTaxConfigFlow";
import OtherCountriesTaxConfigFlow from "./tax-config-flow/OtherCountriesTaxConfigFlow";
import { ComplexTaxCountry } from "../../../models/Taxes";
import { goToTaxConfig } from "../tax-utils/TaxUtils";

interface IManualTaxIntegration {
  handleUpdateCallback?: Function;
}

const ManualTaxIntegration: React.FC<IManualTaxIntegration> = ({ handleUpdateCallback }) => {
  const currentCountry = useSelector((state: AppState) => state.companyTaxRatesReducer.currentCountry);

  const renderConfigFlow = () => {
    if (!currentCountry) {
      return null;
    }

    const updateCallback = () => {
      handleUpdateCallback?.();
      goToTaxConfig();
    }

    switch (currentCountry.countryCode) {
      case ComplexTaxCountry.Australia:
        return <AustraliaTaxConfigFlow handleUpdateCallback={updateCallback} />;
      case ComplexTaxCountry.NewZealand:
        return <NewZealandTaxConfigFlow handleUpdateCallback={updateCallback} />;
      case ComplexTaxCountry.EuropeanUnion:
        return <EuropeanUnionTaxConfigFlow handleUpdateCallback={updateCallback} />;
      case ComplexTaxCountry.India:
        return <IndiaTaxConfigFlow handleUpdateCallback={updateCallback} />;
      default:
        return <OtherCountriesTaxConfigFlow handleUpdateCallback={updateCallback} />;
    }
  };

  return <div className="manual-tax-integration">{renderConfigFlow()}</div>;
};

export default ManualTaxIntegration;
