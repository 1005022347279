import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../../..";
import Text from "../../ui/text/Text";
import Button from "../../ui/button/Button";
import { fetchCustomerLanguages, setCustomerDetailsField } from "../../../actions/customerDetailsActions";
import Table from "../../ui/table/Table";
import moment from "moment";
import ProgressIndicator from "../../ui/progress-indicator/ProgressIndicator";

const CustomerLanguages: React.FC = () => {
  const authData = useSelector((state: AppState) => state.auth);
  const customerDetailsData = useSelector((state: AppState) => state.customerDetailsReducer);

  const dispatch = useDispatch<Function>();

  const { currentCompanyDomain } = authData;
  const { customerUniqueId, customerLanguages, loadingCustomerLanguage } = customerDetailsData;

  const fetchLanguages = async () => {
    dispatch(fetchCustomerLanguages(currentCompanyDomain, customerUniqueId));
  }

  useEffect(() => {
    fetchLanguages();
  }, [])
  
  if (loadingCustomerLanguage) {
    return <ProgressIndicator color="blue" />
  }

  return (
    <>
      <div className="customer_languages">
        <Table className="customer_languages__table">
          <thead>
            <tr>
              <Text content="CUSTOMER_LANGUAGES_LANGUAGE" component="th" noMargin />
              <Text content="CUSTOMER_LANGUAGES_DATE_ASSIGNED" component="th" noMargin />
              <Text content="" component="th" shouldTranslate={false} noMargin />
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>{customerLanguages.getLanguageEnglishName()}</td>
              <td>{`${moment(customerLanguages.getDateUpdated()?.toDate()).format("DD MMM YYYY")} at ${moment(customerLanguages.getDateUpdated()?.toDate()).format("HH:mm:ss Z")}`}</td>
              <td>
                <Button
                  id="change-customer-language-btn"
                  title="CUSTOMER_LANGUAGES_CHANGE_LANGUAGE_BUTTON"
                  buttonType="add"
                  onClick={() => dispatch(setCustomerDetailsField("showChangeCustomerLanguage", true))}
                />
              </td>
            </tr>     
          </tbody>
        </Table>
      </div>
    </>
  )
}

export default CustomerLanguages;