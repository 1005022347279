import React from "react";
import "./CreditNotesWelcome.scss";

import Panel from "../../components/ui/panel/Panel";
import Text from "../../components/ui/text/Text";

export const CreditNotesWelcome = () => {
  return (
    <div className="credit-notes">
     <Panel className="credit-notes__greeting" title="CREDIT_NOTES_WELCOME_TITLE">
      <Text content="CREDIT_NOTES_WELCOME_TEXT" />
      <Panel className="credit-notes__greeting-question" title="CREDIT_NOTES_FIRST_NOTE_TITLE">
        <Text noMargin content="CREDIT_NOTES_FIRST_NOTE_TEXT" />
      </Panel>
     </Panel>
    </div>
  )
}

export default CreditNotesWelcome;