import React, { FormEvent, useState, useLayoutEffect } from "react";
import { AppState } from "../../../../..";
import { useDispatch, useSelector } from "react-redux";
import Panel from "../../../../ui/panel/Panel";
import Text from "../../../../ui/text/Text";
import Input from "../../../../ui/input/Input";
import FormLabel from "../../../../ui/form-label/FormLabel";
import FormGroup from "../../../../ui/form-group/FormGroup";
import { Col, Row } from "react-grid-system";
import Button from "../../../../ui/button/Button";
import "./Checkout.scss";
import counterpart from "counterpart";
import Textarea from "../../../../ui/textarea/Textarea";
import {
  updateCheckoutMgmtData,
  AllowedCheckoutMgmtFields,
  setCheckoutAccountMgmtField,
  fetchCheckoutMgmtData,
  resetCheckoutMgmtTOFPrivacyPolcy,
  resetCheckoutMgmtJsIntegration,
  resetCheckoutMgmtGACode
} from "../../../../../actions/checkoutAccountManagementActions";
import ProgressIndicator from "../../../../ui/progress-indicator/ProgressIndicator";
import ConfirmationScreenPanel from "../confirmation-screen-panel/ConfirmationScreenPanel";
import ShowHideCheckoutFeaturePanel from "../show-hide-checkout-feature-panel/ShowHideCheckoutFeaturePanel";
import NoticePanel from "../../../../ui/notice-panel/NoticePanel";
import * as checkoutReducer from "../../../../../reducers/checkoutAccountManagementReducer";

const Checkout: React.FC = () => {
  const checkoutManagement = useSelector((state: AppState) => state.checkoutAccountManagementReducer.checkoutManagement);
  const currentCompanyDomain = useSelector((state: AppState) => state.auth.currentCompanyDomain);

  const dispatch = useDispatch<Function>();

  const [state, setState] = useState({
    isLoadingData: false,
    isUpdatingTerminology: false,
    isUpdatingIncludedFeatures: false,
    isUpdatingTOFandPrivacyPolicy: false,
    isUpdatingRedirectUrl: false,
    isUpdatingJsIntegration: false,
    isUpdatingGACode: false
  });

  const { isLoadingData, isUpdatingJsIntegration, isUpdatingTOFandPrivacyPolicy, isUpdatingGACode } = state;
  const {
    termsOfUseUrl,
    privacyPolicyUrl,
    trackingCodeEnd,
    trackingCodeStart,
    googleAnalyticsCode
  } = checkoutManagement;

  const fetchData = async () => {
    setState({ ...state, isLoadingData: true });
    try {
      await dispatch(fetchCheckoutMgmtData(currentCompanyDomain));
      setState({ ...state, isLoadingData: false });
    } catch (err) {
      setState({ ...state, isLoadingData: false });
      console.log(err);
    }
  };

  useLayoutEffect(() => {
    fetchData();
  }, []);

  const updateCheckoutField = (arg: AllowedCheckoutMgmtFields, value: any) => {
    dispatch(setCheckoutAccountMgmtField("checkoutManagement", { ...checkoutManagement, [arg]: value }));
  };

  const handleUpdateTOFPrivacyPolicy = async (evt: FormEvent) => {
    evt.preventDefault();
    setState({ ...state, isUpdatingTOFandPrivacyPolicy: true });

    try {
      await dispatch(
        updateCheckoutMgmtData(currentCompanyDomain, {
          ...checkoutReducer.checkoutMgmtInitialState,
          termsOfUseUrl,
          privacyPolicyUrl
        })
      );
      setState({ ...state, isUpdatingTOFandPrivacyPolicy: false });
    } catch (err) {
      setState({ ...state, isUpdatingTOFandPrivacyPolicy: false });
      console.log(err);
    }
  };

  const handleUpdateJsIntegration = async (evt: FormEvent) => {
    evt.preventDefault();
    setState((prevState) => ({ ...prevState, isUpdatingJsIntegration: true }));

    try {
      await dispatch(
        updateCheckoutMgmtData(currentCompanyDomain, {
          ...checkoutReducer.checkoutMgmtInitialState,
          trackingCodeStart,
          trackingCodeEnd
        })
      );
    } catch (err) {
      console.log(err);
    } finally {
      setState((prevState) => ({ ...prevState, isUpdatingJsIntegration: false }));
    }
  };

  const handleUpdateGACode = async (evt: FormEvent) => {
    evt.preventDefault();
    setState((prevState) => ({ ...prevState, isUpdatingGACode: true }));

    try {
      await dispatch(
        updateCheckoutMgmtData(currentCompanyDomain, {
          ...checkoutReducer.checkoutMgmtInitialState,
          googleAnalyticsCode
        })
      );
    } catch (err) {
      console.log(err);
    } finally {
      setState((prevState) => ({ ...prevState, isUpdatingGACode: false }));
    }
  };

  const renderTOFandPrivacyPolicyForm = () => {
    return (
      <form id="tof-form" onSubmit={handleUpdateTOFPrivacyPolicy}>
        <FormGroup>
          <Row align="center">
            <Col md={1.5}>
              <FormLabel target="tof" content="CHECKOUT_MANAGEMENT_LABEL_TOF" noMargin />
            </Col>
            <Col md={10.5}>
              <Input
                type="text"
                showDisabledIcon={false}
                placeholder={counterpart("CHECKOUT_MANAGEMENT_PLACEHOLDER_TOF")}
                id="tof"
                isError={false}
                value={termsOfUseUrl || ""}
                onChange={(evt: any) => updateCheckoutField("termsOfUseUrl", evt.target.value)}
                disabled={isUpdatingTOFandPrivacyPolicy}
              />
            </Col>
          </Row>
        </FormGroup>
        <FormGroup>
          <Row align="center">
            <Col md={1.5}>
              <FormLabel target="privacy-policy" content="CHECKOUT_MANAGEMENT_LABEL_PRIVACY_POLICY" noMargin />
            </Col>
            <Col md={10.5}>
              <Input
                type="text"
                showDisabledIcon={false}
                placeholder={counterpart("CHECKOUT_MANAGEMENT_PLACEHOLDER_PRIVACY_POLICY")}
                id="privacy-policy"
                isError={false}
                value={privacyPolicyUrl || ""}
                onChange={(evt: any) => updateCheckoutField("privacyPolicyUrl", evt.target.value)}
                disabled={isUpdatingTOFandPrivacyPolicy}
              />
            </Col>
          </Row>
        </FormGroup>
        <Row align="center">
          <Col md={9}>
            <Button id="update-tof-privacy-policy" type="submit" isLoading={isUpdatingTOFandPrivacyPolicy} isFullWidth buttonType="general" title="CHECKOUT_MANAGEMENT_BTN_UPDATE_LINKS" />
          </Col>
          <Col md={3}>
            <Button id="cancel-update-tof-privacy-policy" isFullWidth buttonType="error" title="CHECKOUT_ACCT_MANAGEMENT_BTN_CANCEL" onClick={() => dispatch(resetCheckoutMgmtTOFPrivacyPolcy())} />
          </Col>
        </Row>
      </form>
    );
  };

  const renderJsTrackingIntegrationForm = () => {
    return (
      <form id="js-tracking-code-form" onSubmit={handleUpdateJsIntegration}>
        <FormGroup>
          <Row align="start">
            <Col md={1.5}>
              <FormLabel className="checkout-account-management-checkout__textarea-label" target="start-code" content="CHECKOUT_MANAGEMENT_LABEL_START_CODE" noMargin />
            </Col>
            <Col md={10.5}>
              <Textarea
                id="start-code"
                value={trackingCodeStart || ""}
                onChange={(evt: any) => updateCheckoutField("trackingCodeStart", evt.target.value)}
                disabled={isUpdatingJsIntegration}
                placeholder={counterpart("CHECKOUT_MANAGEMENT_PLACEHOLDER_START_CODE")}
              ></Textarea>
              <Text className="checkout-account-management-checkout__sub-info" content="CHECKOUT_MANAGEMENT_INFO_START_CODE" />
            </Col>
          </Row>
        </FormGroup>
        <FormGroup>
          <Row align="start">
            <Col md={1.5}>
              <FormLabel className="checkout-account-management-checkout__textarea-label" target="end-code" content="CHECKOUT_MANAGEMENT_LABEL_END_CODE" noMargin />
            </Col>
            <Col md={10.5}>
              <Textarea
                id="end-code"
                value={trackingCodeEnd || ""}
                onChange={(evt: any) => updateCheckoutField("trackingCodeEnd", evt.target.value)}
                disabled={isUpdatingJsIntegration}
                placeholder={counterpart("CHECKOUT_MANAGEMENT_PLACEHOLDER_END_CODE")}
              ></Textarea>
              <Text className="checkout-account-management-checkout__sub-info" content="CHECKOUT_MANAGEMENT_INFO_END_CODE" />
            </Col>
          </Row>
        </FormGroup>
        <Row align="center">
          <Col md={9}>
            <Button id="update-code" type="submit" isLoading={isUpdatingJsIntegration} isFullWidth buttonType="general" title="CHECKOUT_MANAGEMENT_BTN_UPDATE_CODE" />
          </Col>
          <Col md={3}>
            <Button id="cancel-update-code" isFullWidth buttonType="error" title="CHECKOUT_ACCT_MANAGEMENT_BTN_CANCEL" onClick={() => dispatch(resetCheckoutMgmtJsIntegration())} />
          </Col>
        </Row>
      </form>
    );
  };

  const renderGoogleAnalyticsForm = () => {
    return (
      <form id="ga-form" onSubmit={handleUpdateGACode}>
        <FormGroup>
          <Row align="center">
            <Col md={1.5}>
              <FormLabel target="ga-code" content="CHECKOUT_MANAGEMENT_GA_LABEL" noMargin />
            </Col>
            <Col md={10.5}>
              <Input
                type="text"
                showDisabledIcon={false}
                placeholder={counterpart("CHECKOUT_MANAGEMENT_GA_PLACEHOLDER")}
                id="ga-code"
                isError={false}
                value={googleAnalyticsCode || ""}
                onChange={(evt: any) => updateCheckoutField("googleAnalyticsCode", evt.target.value)}
                disabled={isUpdatingGACode}
              />
            </Col>
          </Row>
        </FormGroup>
        <Row align="center">
          <Col md={9}>
            <Button id="update-ga-code" type="submit" isLoading={isUpdatingGACode} isFullWidth buttonType="general" title="CHECKOUT_MANAGEMENT_GA_BTN" />
          </Col>
          <Col md={3}>
            <Button id="cancel-ga-code" isFullWidth buttonType="error" title="CHECKOUT_MANAGEMENT_GA_CANCEL" onClick={() => dispatch(resetCheckoutMgmtGACode())} />
          </Col>
        </Row>
      </form>
    )
  }

  if (isLoadingData) {
    return <ProgressIndicator color="blue"></ProgressIndicator>
  }

  return (
    <div className="checkout-account-management-checkout">
      <ShowHideCheckoutFeaturePanel />

      {/*
      // HIDDEN FOR NOW
      <Panel title="BRAND_CUSTOMIZATION_WHITE_LABEL" className="brand_customization__panel__white-label">
        <Text content="BRAND_CUSTOMIZATION_WHITE_LABEL_CONTENT" />
        <Button className="brand_customization__panel__white-label__button" id="brand-customization--buy-btn" title="BRAND_CUSTOMIZATION_BUY_WHITE_LABEL" buttonType="add" onClick={() => {}} />
      </Panel>
      */}
      <Panel title="CHECKOUT_MANAGEMENT_PANEL3_TITLE">
        <Text content="CHECKOUT_MANAGEMENT_PANEL3_CONTENT" />
        {renderTOFandPrivacyPolicyForm()}
      </Panel>
      <ConfirmationScreenPanel />

      <Panel className='checkout-account-management-checkout__js-tracking' title="CHECKOUT_MANAGEMENT_PANEL5_TITLE">
      <Text content="CHECKOUT_MANAGEMENT_JS_CODE_CONTENT" />
        <NoticePanel type="warning" content='CHECKOUT_MANAGEMENT_JS_CODE_WARNING' translateWith={{
          link: <a id="checkout-read-more" href="https://support.billsby.com/docs/checkout" target="_self" onClick={(evt: any) => evt.stopPropagation()}>
            {counterpart("CHECKOUT_MANAGEMENT_JS_CODE_LINK")}
          </a>
        }}></NoticePanel>
        {renderJsTrackingIntegrationForm()}
      </Panel>
      <Panel title="CHECKOUT_MANAGEMENT_PANEL6_TITLE">
        <Text content="CHECKOUT_MANAGEMENT_PANEL6_CONTENT" />
        {renderGoogleAnalyticsForm()}
      </Panel>
    </div>
  );
};

export default Checkout;
