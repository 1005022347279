import * as React from "react";
import "./CustomFieldPreview.scss";
import Panel from "../panel/Panel";

interface ICustomFieldPreviewProps {
  /**
   * The elements which will be wrapped by the panel
   */
  children: any,
  /**
  * Allow custom styling
  */
  className?: string,
}

const CustomFieldPreview: React.FunctionComponent<ICustomFieldPreviewProps> = React.memo(({ children = null, className = "" }) => {
  return (
    <Panel className={`custom-field-preview ${className}`}>
      {children}
    </Panel>
  );
});

export default CustomFieldPreview;