import API from "../utils/API";
import { makeActionCreator } from "../utils/reduxUtils";
import { BaseAddress } from "../models/Address";

export const FETCH_SUBSCRIPTION_SHIPPING_ADDRESS_REQUEST = "FETCH_SUBSCRIPTION_SHIPPING_ADDRESS_REQUEST";
export const FETCH_SUBSCRIPTION_SHIPPING_ADDRESS_SUCCESS = "FETCH_SUBSCRIPTION_SHIPPING_ADDRESS_SUCCESS";
export const FETCH_SUBSCRIPTION_SHIPPING_ADDRESS_FAILURE = "FETCH_SUBSCRIPTION_SHIPPING_ADDRESS_FAILURE";

export const UPDATE_SUBSCRIPTION_SHIPPING_ADDRESS_REQUEST = "UPDATE_SUBSCRIPTION_SHIPPING_ADDRESS_REQUEST";
export const UPDATE_SUBSCRIPTION_SHIPPING_ADDRESS_SUCCESS = "UPDATE_SUBSCRIPTION_SHIPPING_ADDRESS_SUCCESS";
export const UPDATE_SUBSCRIPTION_SHIPPING_ADDRESS_FAILURE = "UPDATE_SUBSCRIPTION_SHIPPING_ADDRESS_FAILURE";

export const SET_SUBSCRIPTIONS_SHIPPING_ADDRESS_SHOW_UPDATE_MODAL = "SET_SUBSCRIPTIONS_SHIPPING_ADDRESS_SHOW_UPDATE_MODAL";
export const setShowShippingAddressUpdateModal = makeActionCreator(SET_SUBSCRIPTIONS_SHIPPING_ADDRESS_SHOW_UPDATE_MODAL, "payload");

export const SET_SUBSCRIPTION_SHIPPING_ADDRESS = "SET_SUBSCRIPTION_SHIPPING_ADDRESS";
export const setSubscriptionShippingAddress = makeActionCreator(SET_SUBSCRIPTION_SHIPPING_ADDRESS, "payload");

export const RESET_SUBSCRIPTION_SHIPPING_ADDRESS = "RESET_SUBSCRIPTION_SHIPPING_ADDRESS";
export const resetSubscriptionShippingAddress = makeActionCreator(RESET_SUBSCRIPTION_SHIPPING_ADDRESS, "payload");

export const fetchSubscriptionShippingAddress = function (companyDomain: string, subscriptionUniqueId: string) {
  return {
    types: [FETCH_SUBSCRIPTION_SHIPPING_ADDRESS_REQUEST, FETCH_SUBSCRIPTION_SHIPPING_ADDRESS_SUCCESS, FETCH_SUBSCRIPTION_SHIPPING_ADDRESS_FAILURE],
    callAPI: () => API.fetchShippingAddress(companyDomain, subscriptionUniqueId)
  }
}

export const updateSubscriptionShippingAddress = function (companyDomain: string, subscriptionUniqueId: string, data: BaseAddress) {
  return {
    types: [UPDATE_SUBSCRIPTION_SHIPPING_ADDRESS_REQUEST, UPDATE_SUBSCRIPTION_SHIPPING_ADDRESS_SUCCESS, UPDATE_SUBSCRIPTION_SHIPPING_ADDRESS_FAILURE],
    callAPI: () => API.updateShippingAddress(companyDomain, subscriptionUniqueId, data)
  }
}