import registration from "./registrationReducer";
import login from "./loginReducer";
import auth from "./authReducer";
import productPlanCycle from "./productPlanCycleReducer";
import products from "./productsReducer";
import plans from "./plansReducer";
import cycles from "./cyclesReducer";
import customFields from "./customFieldsReducer";
import paymentGatewaysReducer from "./paymentGatewaysReducer";
import goLiveReducer from "./goLiveReducer";
import companyApiKeyReducer from "./companyApiKeyReducer";
import currenciesReducer from "./currenciesReducer";
import customersReducer from "./customersReducer";
import customerDetailsReducer from "./customerDetailsReducer";
import companyBrandingReducer from "./companyBrandingReducer";
import companyTeammatesReducer from "./companyTeammatesReducer";
import subscriptionsReducer from "./subscriptionsReducer";
import subscriptionDetailsReducer from "./subscriptionDetailsReducer";
import customerSubscriptionsReducer from "./customerSubscriptionsReducer";
import invoicesReducer from "./invoicesReducer";
import eventLogsReducer from "./eventLogsReducer";
import subscriptionFeatureTagsReducer from "./subscriptionFeatureTagsReducer";
import creditNotesReducer from "./creditNotesReducer";
import embedCodesCheckOutReducer from "./embedCodesCheckOutReducer";
import companyWebhooksReducer from "./companyWebhooksReducer";
import creditNoteDetailsReducer from "./creditNoteDetailsReducer";
import invoiceDetailsReducer from "./invoiceDetailsReducer";
import companyTaxRatesReducer from "./companyTaxRatesReducer";
import companyDetailsReducer from "./companyDetailsReducer";
import emailRoutingReducer from "./emailRoutingReducer";
import emailCustomizationReducer from "./emailCustomizationReducer";
import dunningFailedProcessReducer from "./dunningFailedProcessReducer";
import dashboardReducer from "./dashboardReducer";
import invoicesCreditNotesCustomizationReducer from "./invoicesCreditNotesCustomizationReducer";
import invoiceStandaloneReducer from "./invoiceStandaloneReducer";
import checkoutAccountManagementReducer from "./checkoutAccountManagementReducer";
import subscriptionCustomFieldsReducer from "./subscriptionCustomFieldsReducer";
import cancelSubscriptionConfig from "./cancelSubscriptionConfigReducer";
import subscriptionPlanReducer from "./subscriptionPlanReducer";
import shippingAddressReducer from "./shippingAddressReducer";
import activitySummaryReducer from "./activitySummaryReducer";
import taxSummaryReducer from "./taxSummaryReducer";
import revenueReportReducer from "./revenueReportReducer";
import emailBaseTemplateReducer from "./emailBaseTemplateReducer";
import exitReasonsReducer from "./exitReasonsReducer";
import emailLogsReducer from "./emailLogsReducer";
import createAddOnsReducer from "./add-ons/create-addons/createAddOnsReducer";
import selectAddOnPlanReducer from "./add-ons/selectAddOnPlanReducer";
import addOnCostReducer from "./add-ons/addOnCostReducer";
import linkAddOnsAllowancesReducer from "./linkAddOnsAllowancesReducer";
import addOnsReducer from "./add-ons/addOnsReducer";
import createDiscountReducer from "./discount-coupons/createDiscountReducer";
import createCouponReducer from "./discount-coupons/createCouponReducer";
import discountsReducer from "./discount-coupons/discountsReducer";
import userInterfaceReducer from "./userInterfaceReducer";
import accountingIntegrationsReducer from "./accountingIntegrationsReducer";
import scaReducer from "./scaReducer";
import languageSupportReducer from "./languageSupportReducer";
import transactionLogsReducer from "./transactionLogsReducer";
import achSettingsReducer from "./achSettingsReducer";

const reducers = function(store: any = {}, action: any) {
  return {
    registration: registration(store.registration, action, store),
    login: login(store.login, action, store),
    auth: auth(store.auth, action, store),
    productPlanCycle: productPlanCycle(store.productPlanCycle, action, store),
    products: products(store.products, action, store),
    plans: plans(store.plans, action, store),
    cycles: cycles(store.cycles, action, store),
    customFields: customFields(store.customFields, action, store),
    currenciesReducer: currenciesReducer(
      store.currenciesReducer,
      action,
      store
    ),
    paymentGatewaysReducer: paymentGatewaysReducer(
      store.paymentGatewaysReducer,
      action,
      store
    ),
    goLiveReducer: goLiveReducer(store.goLiveReducer, action, store),
    companyApiKeyReducer: companyApiKeyReducer(
      store.companyApiKeyReducer,
      action,
      store
    ),
    customersReducer: customersReducer(store.customersReducer, action, store),
    customerDetailsReducer: customerDetailsReducer(
      store.customerDetailsReducer,
      action,
      store
    ),
    companyBrandingReducer: companyBrandingReducer(
      store.companyBrandingReducer,
      action,
      store
    ),
    subscriptionsReducer: subscriptionsReducer(
      store.subscriptionsReducer,
      action,
      store
    ),
    subscriptionDetailsReducer: subscriptionDetailsReducer(
      store.subscriptionDetailsReducer,
      action,
      store
    ),
    customerSubscriptionsReducer: customerSubscriptionsReducer(
      store.customerSubscriptionsReducer,
      action,
      store
    ),
    invoicesReducer: invoicesReducer(store.invoicesReducer, action, store),
    companyTeammatesReducer: companyTeammatesReducer(store.companyTeammatesReducer, action, store),
    eventLogsReducer: eventLogsReducer(store.eventLogsReducer, action, store),
    subscriptionFeatureTagsReducer: subscriptionFeatureTagsReducer(store.subscriptionFeatureTagsReducer, action, store),
    creditNotesReducer: creditNotesReducer(store.creditNotesReducer, action, store),
    embedCodesCheckOutReducer: embedCodesCheckOutReducer(store.embedCodesCheckOutReducer, action, store),
    companyWebhooksReducer: companyWebhooksReducer(store.companyWebhooksReducer, action, store),
    creditNoteDetailsReducer: creditNoteDetailsReducer(store.creditNoteDetailsReducer, action, store),
    invoiceDetailsReducer : invoiceDetailsReducer(store.invoiceDetailsReducer, action, store),
    companyTaxRatesReducer: companyTaxRatesReducer(store.companyTaxRatesReducer, action, store),
    companyDetailsReducer: companyDetailsReducer(store.companyDetailsReducer, action, store),
    emailRoutingReducer: emailRoutingReducer(store.emailRoutingReducer, action, store),  
    dunningFailedProcessReducer: dunningFailedProcessReducer(store.dunningFailedProcessReducer, action, store),
    dashboardReducer: dashboardReducer(store.dashboardReducer, action, store),
    invoicesCreditNotesCustomizationReducer: invoicesCreditNotesCustomizationReducer(store.invoicesCreditNotesCustomizationReducer, action, store),
    emailCustomizationReducer: emailCustomizationReducer(store.emailCustomizationReducer, action, store),
    invoiceStandaloneReducer: invoiceStandaloneReducer(store.invoiceStandaloneReducer, action, store),
    checkoutAccountManagementReducer: checkoutAccountManagementReducer(store.checkoutAccountManagementReducer, action, store),
    subscriptionCustomFieldsReducer: subscriptionCustomFieldsReducer(store.subscriptionCustomFieldsReducer, action, store),
    cancelSubscriptionConfigReducer: cancelSubscriptionConfig(store.cancelSubscriptionConfigReducer, action, store),
    subscriptionPlanReducer: subscriptionPlanReducer(store.subscriptionPlanReducer, action, store),
    shippingAddressReducer: shippingAddressReducer(store.shippingAddressReducer, action, store),
    activitySummaryReducer: activitySummaryReducer(store.activitySummaryReducer, action, store),
    taxSummaryReducer: taxSummaryReducer(store.taxSummaryReducer, action, store),
    emailBaseTemplateReducer: emailBaseTemplateReducer(store.emailBaseTemplateReducer, action, store),
    exitReasonsReducer: exitReasonsReducer(store.exitReasonsReducer, action, store),
    emailLogsReducer: emailLogsReducer(store.emailLogsReducer, action, store),
    createAddOnsReducer: createAddOnsReducer(store.createAddOnsReducer, action, store),
    selectAddOnPlanReducer: selectAddOnPlanReducer(store.selectAddOnPlanReducer, action, store),
    addOnCostReducer: addOnCostReducer(store.addOnCostReducer, action, store),
    linkAddOnsAllowancesReducer: linkAddOnsAllowancesReducer(store.linkAddOnsAllowancesReducer, action, store),
    addOnsReducer: addOnsReducer(store.addOnsReducer, action, store),
    createDiscountReducer: createDiscountReducer(store.createDiscountReducer, action, store),
    createCouponReducer: createCouponReducer(store.createCouponReducer, action, store),
    discountsReducer: discountsReducer(store.discountsReducer, action, store),
    userInterfaceReducer: userInterfaceReducer(store.userInterfaceReducer, action, store),
    accountingIntegrationsReducer: accountingIntegrationsReducer(store.accountingIntegrationsReducer, action, store),
    revenueReportReducer: revenueReportReducer(store.revenueReportReducer, action, store),
    scaReducer: scaReducer(store.scaReducer, action, store),
    languageSupportReducer: languageSupportReducer(store.languageSupportReducer, action, store),
    transactionLogsReducer: transactionLogsReducer(store.transactionLogsReducer, action, store),
    achSettingsReducer: achSettingsReducer(store.achSettingsReducer, action, store)
  };
}

export default reducers