import React from "react";
import NavigationAlert from "./NavigationAlert";
import history from "../../../utils/history";


const NavigationAlertCurrencyMismatch: React.FC = React.memo(() => {
  return (
    <NavigationAlert
      title="ALERT_CURRENCY_MAPPING_MISMATCH_TITLE"
      subTitle="ALERT_CURRENCY_MAPPING_MISMATCH_SUBTITLE"
      type="error"
      buttonLabel="ALERT_CURRENCY_MAPPING_MISMATCH_FIXTHIS"
      icon="fas fa-headset"
      onClick={() => history.push("/configuration/currencies")}
      isVisible={false}
    />
  )
})

export default NavigationAlertCurrencyMismatch;