import React, { useEffect, useState } from "react";
import "./TwoFactorAuth.scss";
import Panel from "../ui/panel/Panel";
import Text from "../ui/text/Text";
import FormLabel from "../ui/form-label/FormLabel";
import counterpart from "counterpart";
import FormGroup from "../ui/form-group/FormGroup";
import Input from "../ui/input/Input";
import Button from "../ui/button/Button";
import API from "../../utils/API";
import { IUserWith2FA } from "../../models/User";
import InfoLabel from "../ui/info-label/InfoLabel";
interface ITwoFactorAuthEnableProps {
  userProfile: IUserWith2FA
}

const TwoFactorAuthEnable: React.FC<ITwoFactorAuthEnableProps> = ({ userProfile }) => {
  const [isValidPhoneNumber, setIsValidPhoneNumber] = useState(true);
  const [sixDigitsPart1, setSixDigitsPart1] = useState("");
  const [sixDigitsPart2, setSixDigitsPart2] = useState("");
  const [password, setPassword] = useState("");
  const [qrCode, setQrCode] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isSuccessful, setIsSuccessful] = useState(false);
  const [error, setError] = useState<any>(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const qrCode = await API.getQrCode() as string;
        setQrCode(qrCode)
        const qrCodeDom = document.getElementById("qr-code");
        qrCodeDom && (qrCodeDom.innerHTML = "");
        new (window as any).QRCode(document.getElementById("qr-code"), {
          text: qrCode,
          width: 160,
          height: 160,
          colorDark: "#000000",
          colorLight: "#ffffff",
          correctLevel: (window as any).QRCode.CorrectLevel.H
        });
      } catch (err) {
        console.log(err);
      }
    }

    fetchData()
  }, []);

  useEffect(() => {
    if ((error || (sixDigitsPart1.length === 3 && sixDigitsPart2.length === 3 && password.length))) {
      return;
    }
    if (sixDigitsPart1.length >= 3) {
      const inputDigit = document.querySelector("#six-digit-second-part") as HTMLInputElement;
      inputDigit && inputDigit.focus();

      if (sixDigitsPart2.length >= 3) {
        const inputPassword = document.querySelector("#password") as HTMLInputElement;
        inputPassword && inputPassword.focus()
      }
    }

  }, [sixDigitsPart1, sixDigitsPart2, error])

  const onSubmit = async () => {
    const qrCode = {
      active: true,
      code: `${sixDigitsPart1}${sixDigitsPart2}`,
      password
    }
    setError(null);
    setIsSuccessful(false);
    setIsLoading(true);
    try {
      await API.activateDeactivateQRCode(qrCode);
      setIsSuccessful(true)
    }
    catch (err) {
      setError(err && err.list && err.list.length && err.list[0].description);
    }
    finally {
      setIsLoading(false);
    }
  }

  return (
    <div className="two-factor-auth-enable">
      <FormGroup noMargin>
        <Panel title="TWO_FACTOR_AUTH_MODAL_ENABLE_HEAD_TITLE" className="two-factor-auth-enable__heading">
          <Text content="TWO_FACTOR_AUTH_MODAL_ENABLE_HEAD_CONTENT" noMargin />
        </Panel>

        <Panel>
          <Text content="TWO_FACTOR_AUTH_MODAL_ENABLE_PHONE_CONTENT" />
          <div className="two-factor-auth-enable__phone">
            <FormLabel content="TWO_FACTOR_AUTH_MODAL_ENABLE_PHONE_LABEL" target="" noMargin />
            <Text content={`+${userProfile.phoneNumberDialCode} ${userProfile.phoneNumber}`} shouldTranslate={false} noMargin />
          </div>
        </Panel>

        <Panel>
          <FormGroup className="two-factor-auth-enable__qrcode">
            <Text content="TWO_FACTOR_AUTH_MODAL_ENABLE_GOOGLE_APP_CONTENT_1" component="span" />
            <span>
              <a onClick={() => window.open("https://support.google.com/accounts/answer/1066447", "_self")}>
                {counterpart("TWO_FACTOR_AUTH_MODAL_ENABLE_GOOGLE_APP_CONTENT_2")}
              </a>
            </span>
            <Text content="TWO_FACTOR_AUTH_MODAL_ENABLE_GOOGLE_APP_CONTENT_3" component="span" />
          </FormGroup>

          <div id="qr-code" />
        </Panel>

        <Panel>
          <Text content="TWO_FACTOR_AUTH_MODAL_ENABLE_CODE_GENERATED" />
          <FormGroup className="two-factor-auth-enable__sixdigits">
            <FormLabel content="TWO_FACTOR_AUTH_MODAL_ENABLE_SIX_DIGITS" target="six-digit-first-part" noMargin />
            <div>
              <Input
                id="six-digit-first-part"
                onChange={(evt: any) => setSixDigitsPart1(evt.target.value)}
                value={sixDigitsPart1}
                maxLength="3"
                placeholder="123"
              />
              <Input
                id="six-digit-second-part"
                value={sixDigitsPart2}
                onChange={(evt: any) => setSixDigitsPart2(evt.target.value)}
                maxLength="3"
                placeholder="456"
              />
              <InfoLabel content="TWO_FACTOR_AUTH_ERROR_LOGIN_EMPTY_WRONG_CODE" type="error" noMargin isVisible={!!error && error === "Invalid 2FA six-digit code"} />
            </div>
          </FormGroup>

          <div className="two-factor-auth-enable__password">
            <FormLabel content="TWO_FACTOR_AUTH_MODAL_DISABLE_TURNOFF_PSW" target="password" noMargin />
            <Input
              id="password"
              type="password"
              value={password}
              isError={!!error && error === "Invalid password" ? true : false}
              errorMsg={counterpart("TWO_FACTOR_AUTH_ERROR_PASSWORD")}
              onChange={(evt: any) => setPassword(evt.target.value)}
              placeholder="**********"
            />
          </div>
        </Panel>

        <Button
          id="turn-off-2fa-btn"
          title="TWO_FACTOR_AUTH_MODAL_ENABLE_BTN"
          onClick={onSubmit}
          isFullWidth
          isLoading={isLoading}
          buttonType={!!error ? "error" : isSuccessful ? "success" : "general"}
          disabled={!isValidPhoneNumber || !sixDigitsPart1 || !sixDigitsPart2 || !password || isLoading || isSuccessful}
        />
      </FormGroup>
      <FormGroup className="two-factor-auth-enable__footer" noMargin>
        <Text content="TWO_FACTOR_AUTH_MODAL_ENABLE_BTN_FOOTER" noMargin />
      </FormGroup>
    </div>
  )
}

export default TwoFactorAuthEnable;