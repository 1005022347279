import React, { FormEvent, useEffect } from "react";
import counterpart from "counterpart";
import { Row, Col } from "react-grid-system";
import { Link, Redirect } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import history from "../../utils/history";
import { setRememberUser, loginUser, setPassword, setEmail, clearErrors, setIncorrectPasswordCount } from "../../actions/loginActions";
import "./Login.scss";
import Panel from "../../components/ui/panel/Panel";
import Text from "../../components/ui/text/Text";
import FormGroup from "../../components/ui/form-group/FormGroup";
import FormLabel from "../../components/ui/form-label/FormLabel";
import Input from "../../components/ui/input/Input";
import Checkbox from "../../components/ui/checkbox/Checkbox";
import Button from "../../components/ui/button/Button";
import { AppState } from "../..";
import { ILoginReducerState } from "../../reducers/loginReducer";


const Login: React.FC = () => {
  const {  rememberUser, email, password, incorrectPasswordCount, isValidating, passwordErrorMsg, emailErrorMsg } = useSelector<AppState, ILoginReducerState>(state => state.login);
  const dispatch = useDispatch<Function>()

  
  useEffect(() => {
    return () => dispatch(clearErrors())
  }, [])
  
  useEffect(() => {
    if (incorrectPasswordCount === 5) {
      dispatch(setIncorrectPasswordCount(0));
    }
  }, [incorrectPasswordCount])

  const onSubmit = async (evt: FormEvent) => {
    evt.preventDefault();
    try {
      const resp = await dispatch(loginUser(email, password))
      if(resp && resp.error && resp.error.message === "User_Login_2FA_Code_Invalid") {
        history.push("/two-factor-authentication");
      }
    }
    catch(err) {}
  }
  
  if (incorrectPasswordCount === 5) {
    return <Redirect to='/forgot-password' />
  }

  return (
    <div className="login">
      <Panel title="LOGIN_PANEL_TITLE" className="login-panel">
        <Text content="LOGIN_PANEL_TEXT" />
        <a href="https://feedback.billsby.com/changelog" target="_self">{counterpart("LOGIN_PANEL_LINK")}</a>
      </Panel>

      <form id="login-form" onSubmit={onSubmit.bind(null)}>
        <Panel className="login-info">
          <FormGroup>
            <Row>
              <Col md={2} className="login-info_input-id">
                <FormLabel target="login-email" content="LOGIN_EMAIL" noMargin />
              </Col>
              <Col md={10}>
                <Input
                  type="email"
                  required
                  id="login-email"
                  placeholder={counterpart("LOGIN_EMAIL_PLACEHOLDER")}
                  value={email}
                  onChange={e => dispatch(setEmail(e.target.value))}
                  errorMsg={emailErrorMsg}
                  isError={emailErrorMsg !== ""}
                />
              </Col>
            </Row>
          </FormGroup>
          <FormGroup>
            <Row>
              <Col md={2} className="login-info_input-id">
                <FormLabel target="login-password" content="LOGIN_PASSWORD" noMargin />
              </Col>
              <Col md={10}>
                <Input
                  type="password"
                  id="login-password"
                  required
                  placeholder={counterpart("LOGIN_PASSWORD_PLACEHOLDER")}
                  value={password}
                  onChange={e => dispatch(setPassword(e.target.value))}
                  errorMsg={passwordErrorMsg}
                  isError={passwordErrorMsg !== ""}
                />
              </Col>
            </Row>
          </FormGroup>
          <Row>
            <Col md={2} />
            <Col md={10}>
              <Checkbox
                checked={rememberUser}
                onClick={() => dispatch(setRememberUser())}
                value="sss"
                content={
                  <div className="login-info_remember">
                    <Text content="LOGIN_REMEMBER_TITLE" component="p" />
                    <Text content="LOGIN_REMEMBER_TEXT" component="p" />
                  </div>
                }
              />
            </Col>
          </Row>
        </Panel>
        <Row>
          <Col className="login-footer">
            <Button
              id="login-btn"
              isFullWidth
              title="LOGIN_BUTTON_TEXT"
              type="submit"
              isLoading={isValidating}
              className="btn-uppercase"
            />
            <Text
              className="login-footer_text"
              shouldTranslate={false}
              content={
                <Link id="login-forgot-password" to="/forgot-password">{counterpart("LOGIN_FORGOT_TEXT_LINK")}</Link>
              }
            />
          </Col>
        </Row>
      </form>
    </div>
  )
}

export default Login