/**
 * @fileoverview gRPC-Web generated client stub for billing.private.customer
 * @enhanceable
 * @public
 */

// GENERATED CODE -- DO NOT EDIT!


/* eslint-disable */
// @ts-nocheck


import * as grpcWeb from 'grpc-web';

import * as customer_pb from './customer_pb';


export class CustomerServiceClient {
  client_: grpcWeb.AbstractClientBase;
  hostname_: string;
  credentials_: null | { [index: string]: string; };
  options_: null | { [index: string]: any; };

  constructor (hostname: string,
               credentials?: null | { [index: string]: string; },
               options?: null | { [index: string]: any; }) {
    if (!options) options = {};
    if (!credentials) credentials = {};
    options['format'] = 'text';

    this.client_ = new grpcWeb.GrpcWebClientBase(options);
    this.hostname_ = hostname;
    this.credentials_ = credentials;
    this.options_ = options;
  }

  methodDescriptorGetCustomerPaymentDetails = new grpcWeb.MethodDescriptor(
    '/billing.private.customer.CustomerService/GetCustomerPaymentDetails',
    grpcWeb.MethodType.UNARY,
    customer_pb.GetCustomerPaymentDetailsRequest,
    customer_pb.GetCustomerPaymentDetailsResponse,
    (request: customer_pb.GetCustomerPaymentDetailsRequest) => {
      return request.serializeBinary();
    },
    customer_pb.GetCustomerPaymentDetailsResponse.deserializeBinary
  );

  getCustomerPaymentDetails(
    request: customer_pb.GetCustomerPaymentDetailsRequest,
    metadata: grpcWeb.Metadata | null): Promise<customer_pb.GetCustomerPaymentDetailsResponse>;

  getCustomerPaymentDetails(
    request: customer_pb.GetCustomerPaymentDetailsRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: customer_pb.GetCustomerPaymentDetailsResponse) => void): grpcWeb.ClientReadableStream<customer_pb.GetCustomerPaymentDetailsResponse>;

  getCustomerPaymentDetails(
    request: customer_pb.GetCustomerPaymentDetailsRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: customer_pb.GetCustomerPaymentDetailsResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/billing.private.customer.CustomerService/GetCustomerPaymentDetails',
        request,
        metadata || {},
        this.methodDescriptorGetCustomerPaymentDetails,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/billing.private.customer.CustomerService/GetCustomerPaymentDetails',
    request,
    metadata || {},
    this.methodDescriptorGetCustomerPaymentDetails);
  }

  methodDescriptorGetViesVatValidations = new grpcWeb.MethodDescriptor(
    '/billing.private.customer.CustomerService/GetViesVatValidations',
    grpcWeb.MethodType.UNARY,
    customer_pb.GetViesVatValidationsRequest,
    customer_pb.GetViesVatValidationsResponse,
    (request: customer_pb.GetViesVatValidationsRequest) => {
      return request.serializeBinary();
    },
    customer_pb.GetViesVatValidationsResponse.deserializeBinary
  );

  getViesVatValidations(
    request: customer_pb.GetViesVatValidationsRequest,
    metadata: grpcWeb.Metadata | null): Promise<customer_pb.GetViesVatValidationsResponse>;

  getViesVatValidations(
    request: customer_pb.GetViesVatValidationsRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: customer_pb.GetViesVatValidationsResponse) => void): grpcWeb.ClientReadableStream<customer_pb.GetViesVatValidationsResponse>;

  getViesVatValidations(
    request: customer_pb.GetViesVatValidationsRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: customer_pb.GetViesVatValidationsResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/billing.private.customer.CustomerService/GetViesVatValidations',
        request,
        metadata || {},
        this.methodDescriptorGetViesVatValidations,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/billing.private.customer.CustomerService/GetViesVatValidations',
    request,
    metadata || {},
    this.methodDescriptorGetViesVatValidations);
  }

  methodDescriptorUpdateCustomerPaymentDetailsSca = new grpcWeb.MethodDescriptor(
    '/billing.private.customer.CustomerService/UpdateCustomerPaymentDetailsSca',
    grpcWeb.MethodType.UNARY,
    customer_pb.UpdateCustomerPaymentDetailsScaRequest,
    customer_pb.UpdateCustomerPaymentDetailsScaResponse,
    (request: customer_pb.UpdateCustomerPaymentDetailsScaRequest) => {
      return request.serializeBinary();
    },
    customer_pb.UpdateCustomerPaymentDetailsScaResponse.deserializeBinary
  );

  updateCustomerPaymentDetailsSca(
    request: customer_pb.UpdateCustomerPaymentDetailsScaRequest,
    metadata: grpcWeb.Metadata | null): Promise<customer_pb.UpdateCustomerPaymentDetailsScaResponse>;

  updateCustomerPaymentDetailsSca(
    request: customer_pb.UpdateCustomerPaymentDetailsScaRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: customer_pb.UpdateCustomerPaymentDetailsScaResponse) => void): grpcWeb.ClientReadableStream<customer_pb.UpdateCustomerPaymentDetailsScaResponse>;

  updateCustomerPaymentDetailsSca(
    request: customer_pb.UpdateCustomerPaymentDetailsScaRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: customer_pb.UpdateCustomerPaymentDetailsScaResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/billing.private.customer.CustomerService/UpdateCustomerPaymentDetailsSca',
        request,
        metadata || {},
        this.methodDescriptorUpdateCustomerPaymentDetailsSca,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/billing.private.customer.CustomerService/UpdateCustomerPaymentDetailsSca',
    request,
    metadata || {},
    this.methodDescriptorUpdateCustomerPaymentDetailsSca);
  }

  methodDescriptorAdminUpdateCustomerCardDetailsSca = new grpcWeb.MethodDescriptor(
    '/billing.private.customer.CustomerService/AdminUpdateCustomerCardDetailsSca',
    grpcWeb.MethodType.UNARY,
    customer_pb.AdminUpdateCustomerCardDetailsScaRequest,
    customer_pb.AdminUpdateCustomerCardDetailsScaResponse,
    (request: customer_pb.AdminUpdateCustomerCardDetailsScaRequest) => {
      return request.serializeBinary();
    },
    customer_pb.AdminUpdateCustomerCardDetailsScaResponse.deserializeBinary
  );

  adminUpdateCustomerCardDetailsSca(
    request: customer_pb.AdminUpdateCustomerCardDetailsScaRequest,
    metadata: grpcWeb.Metadata | null): Promise<customer_pb.AdminUpdateCustomerCardDetailsScaResponse>;

  adminUpdateCustomerCardDetailsSca(
    request: customer_pb.AdminUpdateCustomerCardDetailsScaRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: customer_pb.AdminUpdateCustomerCardDetailsScaResponse) => void): grpcWeb.ClientReadableStream<customer_pb.AdminUpdateCustomerCardDetailsScaResponse>;

  adminUpdateCustomerCardDetailsSca(
    request: customer_pb.AdminUpdateCustomerCardDetailsScaRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: customer_pb.AdminUpdateCustomerCardDetailsScaResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/billing.private.customer.CustomerService/AdminUpdateCustomerCardDetailsSca',
        request,
        metadata || {},
        this.methodDescriptorAdminUpdateCustomerCardDetailsSca,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/billing.private.customer.CustomerService/AdminUpdateCustomerCardDetailsSca',
    request,
    metadata || {},
    this.methodDescriptorAdminUpdateCustomerCardDetailsSca);
  }

  methodDescriptorAddCustomerNote = new grpcWeb.MethodDescriptor(
    '/billing.private.customer.CustomerService/AddCustomerNote',
    grpcWeb.MethodType.UNARY,
    customer_pb.AddCustomerNoteRequest,
    customer_pb.AddCustomerNoteResponse,
    (request: customer_pb.AddCustomerNoteRequest) => {
      return request.serializeBinary();
    },
    customer_pb.AddCustomerNoteResponse.deserializeBinary
  );

  addCustomerNote(
    request: customer_pb.AddCustomerNoteRequest,
    metadata: grpcWeb.Metadata | null): Promise<customer_pb.AddCustomerNoteResponse>;

  addCustomerNote(
    request: customer_pb.AddCustomerNoteRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: customer_pb.AddCustomerNoteResponse) => void): grpcWeb.ClientReadableStream<customer_pb.AddCustomerNoteResponse>;

  addCustomerNote(
    request: customer_pb.AddCustomerNoteRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: customer_pb.AddCustomerNoteResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/billing.private.customer.CustomerService/AddCustomerNote',
        request,
        metadata || {},
        this.methodDescriptorAddCustomerNote,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/billing.private.customer.CustomerService/AddCustomerNote',
    request,
    metadata || {},
    this.methodDescriptorAddCustomerNote);
  }

  methodDescriptorUpdateCustomerNote = new grpcWeb.MethodDescriptor(
    '/billing.private.customer.CustomerService/UpdateCustomerNote',
    grpcWeb.MethodType.UNARY,
    customer_pb.UpdateCustomerNoteRequest,
    customer_pb.UpdateCustomerNoteResponse,
    (request: customer_pb.UpdateCustomerNoteRequest) => {
      return request.serializeBinary();
    },
    customer_pb.UpdateCustomerNoteResponse.deserializeBinary
  );

  updateCustomerNote(
    request: customer_pb.UpdateCustomerNoteRequest,
    metadata: grpcWeb.Metadata | null): Promise<customer_pb.UpdateCustomerNoteResponse>;

  updateCustomerNote(
    request: customer_pb.UpdateCustomerNoteRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: customer_pb.UpdateCustomerNoteResponse) => void): grpcWeb.ClientReadableStream<customer_pb.UpdateCustomerNoteResponse>;

  updateCustomerNote(
    request: customer_pb.UpdateCustomerNoteRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: customer_pb.UpdateCustomerNoteResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/billing.private.customer.CustomerService/UpdateCustomerNote',
        request,
        metadata || {},
        this.methodDescriptorUpdateCustomerNote,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/billing.private.customer.CustomerService/UpdateCustomerNote',
    request,
    metadata || {},
    this.methodDescriptorUpdateCustomerNote);
  }

  methodDescriptorDeleteCustomerNote = new grpcWeb.MethodDescriptor(
    '/billing.private.customer.CustomerService/DeleteCustomerNote',
    grpcWeb.MethodType.UNARY,
    customer_pb.DeleteCustomerNoteRequest,
    customer_pb.DeleteCustomerNoteResponse,
    (request: customer_pb.DeleteCustomerNoteRequest) => {
      return request.serializeBinary();
    },
    customer_pb.DeleteCustomerNoteResponse.deserializeBinary
  );

  deleteCustomerNote(
    request: customer_pb.DeleteCustomerNoteRequest,
    metadata: grpcWeb.Metadata | null): Promise<customer_pb.DeleteCustomerNoteResponse>;

  deleteCustomerNote(
    request: customer_pb.DeleteCustomerNoteRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: customer_pb.DeleteCustomerNoteResponse) => void): grpcWeb.ClientReadableStream<customer_pb.DeleteCustomerNoteResponse>;

  deleteCustomerNote(
    request: customer_pb.DeleteCustomerNoteRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: customer_pb.DeleteCustomerNoteResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/billing.private.customer.CustomerService/DeleteCustomerNote',
        request,
        metadata || {},
        this.methodDescriptorDeleteCustomerNote,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/billing.private.customer.CustomerService/DeleteCustomerNote',
    request,
    metadata || {},
    this.methodDescriptorDeleteCustomerNote);
  }

  methodDescriptorGetCustomerNotes = new grpcWeb.MethodDescriptor(
    '/billing.private.customer.CustomerService/GetCustomerNotes',
    grpcWeb.MethodType.UNARY,
    customer_pb.GetCustomerNotesRequest,
    customer_pb.GetCustomerNotesResponse,
    (request: customer_pb.GetCustomerNotesRequest) => {
      return request.serializeBinary();
    },
    customer_pb.GetCustomerNotesResponse.deserializeBinary
  );

  getCustomerNotes(
    request: customer_pb.GetCustomerNotesRequest,
    metadata: grpcWeb.Metadata | null): Promise<customer_pb.GetCustomerNotesResponse>;

  getCustomerNotes(
    request: customer_pb.GetCustomerNotesRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: customer_pb.GetCustomerNotesResponse) => void): grpcWeb.ClientReadableStream<customer_pb.GetCustomerNotesResponse>;

  getCustomerNotes(
    request: customer_pb.GetCustomerNotesRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: customer_pb.GetCustomerNotesResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/billing.private.customer.CustomerService/GetCustomerNotes',
        request,
        metadata || {},
        this.methodDescriptorGetCustomerNotes,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/billing.private.customer.CustomerService/GetCustomerNotes',
    request,
    metadata || {},
    this.methodDescriptorGetCustomerNotes);
  }

  methodDescriptorGetCustomerLanguage = new grpcWeb.MethodDescriptor(
    '/billing.private.customer.CustomerService/GetCustomerLanguage',
    grpcWeb.MethodType.UNARY,
    customer_pb.GetCustomerLanguageRequest,
    customer_pb.GetCustomerLanguageResponse,
    (request: customer_pb.GetCustomerLanguageRequest) => {
      return request.serializeBinary();
    },
    customer_pb.GetCustomerLanguageResponse.deserializeBinary
  );

  getCustomerLanguage(
    request: customer_pb.GetCustomerLanguageRequest,
    metadata: grpcWeb.Metadata | null): Promise<customer_pb.GetCustomerLanguageResponse>;

  getCustomerLanguage(
    request: customer_pb.GetCustomerLanguageRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: customer_pb.GetCustomerLanguageResponse) => void): grpcWeb.ClientReadableStream<customer_pb.GetCustomerLanguageResponse>;

  getCustomerLanguage(
    request: customer_pb.GetCustomerLanguageRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: customer_pb.GetCustomerLanguageResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/billing.private.customer.CustomerService/GetCustomerLanguage',
        request,
        metadata || {},
        this.methodDescriptorGetCustomerLanguage,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/billing.private.customer.CustomerService/GetCustomerLanguage',
    request,
    metadata || {},
    this.methodDescriptorGetCustomerLanguage);
  }

  methodDescriptorUpdateCustomerLanguage = new grpcWeb.MethodDescriptor(
    '/billing.private.customer.CustomerService/UpdateCustomerLanguage',
    grpcWeb.MethodType.UNARY,
    customer_pb.UpdateCustomerLanguageRequest,
    customer_pb.UpdateCustomerLanguageResponse,
    (request: customer_pb.UpdateCustomerLanguageRequest) => {
      return request.serializeBinary();
    },
    customer_pb.UpdateCustomerLanguageResponse.deserializeBinary
  );

  updateCustomerLanguage(
    request: customer_pb.UpdateCustomerLanguageRequest,
    metadata: grpcWeb.Metadata | null): Promise<customer_pb.UpdateCustomerLanguageResponse>;

  updateCustomerLanguage(
    request: customer_pb.UpdateCustomerLanguageRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: customer_pb.UpdateCustomerLanguageResponse) => void): grpcWeb.ClientReadableStream<customer_pb.UpdateCustomerLanguageResponse>;

  updateCustomerLanguage(
    request: customer_pb.UpdateCustomerLanguageRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: customer_pb.UpdateCustomerLanguageResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/billing.private.customer.CustomerService/UpdateCustomerLanguage',
        request,
        metadata || {},
        this.methodDescriptorUpdateCustomerLanguage,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/billing.private.customer.CustomerService/UpdateCustomerLanguage',
    request,
    metadata || {},
    this.methodDescriptorUpdateCustomerLanguage);
  }

}

