import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import "./EmbedCodesSelfService.scss";
import Helmet from "react-helmet";
import counterpart from "counterpart";
import { Link } from "react-router-dom";
import Panel from "../../../components/ui/panel/Panel";
import Text from "../../../components/ui/text/Text";
import { Row, Col } from "react-grid-system";
import CodePanel from "../../../components/ui/code-panel/CodePanel";
import { AppState } from "../../../index";
import { ConfigConstants } from "../../../utils/config";
import Dropdown from "../../../components/ui/dropdown/Dropdown";
import FormGroup from "../../../components/ui/form-group/FormGroup";
import CollapsablePanelWithArrow from "../../../components/ui/collapsable-panel-with-arrow/CollapsablePanelWithArrow";
import { fetchEmbedCodesLanguages } from "../../../actions/embedCodesCheckOutActions";

const EmbedCodesSelfService: React.FC = () => {
  const embedCodesCheckOutData = useSelector((state: AppState) => state.embedCodesCheckOutReducer);
  const companyId = useSelector((state: AppState) => state.auth.currentCompanyId);
  const companydomain = useSelector((state: AppState) => state.auth.currentCompanyDomain);

  const dispatch  = useDispatch<Function>();

  const [activePanel, setActivePanel] = useState<number | undefined>(undefined);
  const [selectedLanguage, setSelectedLanguage] = useState<{ value: "", label: "" } | undefined>(undefined);
  const { companyLanguages } = embedCodesCheckOutData;

  useEffect(() => {
    const fetchLanguages = async () => {
      try {
        companyId && await dispatch(fetchEmbedCodesLanguages(companyId));
      } catch (err) {
        console.log(err)
      }
    }
    fetchLanguages()
  }, [])

  const onPanelClick = (number: number) => {
    if (activePanel === number) {
      setActivePanel(undefined)
    } else {
      setActivePanel(number)
    }
  }

  const renderBasicEmbedCodePanel = () => {
    const basicEmbedCode1 = `<script src="${ConfigConstants.checkoutLibUrl}" data-billsby-company="${companydomain}"></script>`;
    const basicEmbedCode2 = "<a href=\"javascript:void(0)\" data-billsby-type=\"account\">Manage account</a>";

    return (
      <Panel className="ecself-service__basic" title="SETTINGS_SELF_SERVICE_PANEL2_TITLE">
        <Text content="SETTINGS_SELF_SERVICE_PANEL2_CONTENT" />

        <Text className="ecself-service__basic__subtitle" content="SETTINGS_SELF_SERVICE_PANEL2_HEADER1" />
        <CodePanel id="basic-copy-1" codeStr={basicEmbedCode1}>
          <span className="ecself-service__basic__code-main">{basicEmbedCode1}</span>
        </CodePanel>

        <Text className="ecself-service__basic__subtitle" content="SETTINGS_SELF_SERVICE_PANEL2_HEADER2" />
        <CodePanel id="basic-copy-2" codeStr={basicEmbedCode2} noMargin>
          <span className="ecself-service__basic__code-main">{basicEmbedCode2}</span>
        </CodePanel>
      </Panel>
    );
  };

  const renderIDsManageAccountPanel = () => {
    const specificCustomerCode1 = '<a href="javascript:void(0)" data-billsby-type="account" data-billsby-customer="';
    const specificCustomerId = "[Customer ID]";
    const specificCustomerCode2 = '">Manage account</a>';
    const specificSubscriptionCode1 = '" data-billsby-subscription="';
    const specificSubscriptionId = "[Subscription ID]";

    return (
      <CollapsablePanelWithArrow unmount isCollapsed={activePanel !== 1} onClick={() => onPanelClick(1)} className="ecself-service__basic panel--1" title="SETTINGS_SELF_SERVICE_PANEL3_TITLE">
        <Text content="SETTINGS_SELF_SERVICE_PANEL3_CONTENT" />

        <Text className="ecself-service__basic__subtitle" content="SETTINGS_SELF_SERVICE_PANEL3_HEADER1" />
        <CodePanel id="basic-copy-3" codeStr={specificCustomerCode1 + specificCustomerId + specificCustomerCode2}>
          <span className="eccheckout__basic__code-main">
            {specificCustomerCode1}
            <span className="eccheckout__basic__variable">{specificCustomerId}</span>
            {specificCustomerCode2}
          </span>
        </CodePanel>
        <Text className="ecself-service__basic__subtitle" content="SETTINGS_SELF_SERVICE_PANEL3_HEADER2" />
        <CodePanel id="basic-copy-3x" codeStr={specificCustomerCode1 + specificCustomerId + specificSubscriptionCode1 + specificSubscriptionId + specificCustomerCode2} noMargin>
          <span className="ecself-service__basic__code-main">
            {specificCustomerCode1}
            <span className="ecself-service__basic__variable">{specificCustomerId}</span>
            {specificSubscriptionCode1}
            <span className="ecself-service__basic__variable">{specificSubscriptionId}</span>
            {specificCustomerCode2}
          </span>
        </CodePanel>
      </CollapsablePanelWithArrow>
    );
  };


  const renderOverrideDefaultLanguagePanel = () => {
    const overrideSelfServiceLanguageCode1 = '<a href="javascript:void(0)" data-billsby-type="account" data-billsby-language="';
    const overrideSelfServiceLanguageCode2 = '">Manage account</a>';

    return (
      <CollapsablePanelWithArrow isCollapsed={activePanel !== 2} onClick={() => onPanelClick(2)} className="ecself-service__basic panel--2" title="SETTINGS_SELF_SERVICE_OVERRIDE_DEFAULT_LANG_TITLE">
        <Text content="SETTINGS_SELF_SERVICE_OVERRIDE_DEFAULT_LANG_CONTENT" translateWith={{ link: <Link to={"/configuration/languages"}>{counterpart("SETTINGS_SELF_SERVICE_OVERRIDE_DEFAULT_LANG_CONTENT_LINK")}</Link> }} />
        <FormGroup noMargin={!selectedLanguage}>
          <Text className="ecself-service__basic__subtitle" content="SETTINGS_SELF_SERVICE_OVERRIDE_DEFAULT_LANG_LABEL" />
          <Dropdown value={selectedLanguage} onChange={(value: any) => setSelectedLanguage(value)} id="languages-self-service-dropdown" placeholder={counterpart("SETTINGS_CHECKOUT_OVERRIDE_DEFAULT_LANG_PLACEHOLDER")} options={companyLanguages}  />
        </FormGroup>

        {selectedLanguage && <CodePanel noMargin id="basic-copy-3" codeStr={overrideSelfServiceLanguageCode1 + selectedLanguage.value + overrideSelfServiceLanguageCode2}>
          <span className="eccheckout__basic__code-main">
            {overrideSelfServiceLanguageCode1}
            <span className="eccheckout__basic__variable">{selectedLanguage.value}</span>
            {overrideSelfServiceLanguageCode2}
          </span>
        </CodePanel>}
      </CollapsablePanelWithArrow>
    );
  }

  const renderDirectCustomersPanel = () => {
    const actionCancelPlan = "\"cancelplan\"";
    const actionPaymentDetails = "\"paymentdetails\"";
    const actionPersonalDetails = "\"personaldetails\"";
    const addressAction = "\"address\"";
    const billingHistoryAction = "\"billinghistory\"";

    const cancelPlan1 = '<a href="javascript:void(0)" data-billsby-type="account" data-billsby-action=';
    const cancelPlan2 = ">Cancel subscription</a>";

    const updatePaymentDetails1 = '<a href="javascript:void(0)" data-billsby-type="account" data-billsby-action=';
    const updatePaymentDetails2 = ">Update payment details</a>";

    const personalDetails1 = '<a href="javascript:void(0)" data-billsby-type="account" data-billsby-action=';
    const personalDetails2 = ">Change contact details</a>";

    const address1 = '<a href="javascript:void(0)" data-billsby-type="account" data-billsby-action=';
    const address2 = ">Change address details</a>";

    const billingHistory1 = '<a href="javascript:void(0)" data-billsby-type="account" data-billsby-action=';
    const billingHistory2 = ">View billing history</a>";
    return (
      <CollapsablePanelWithArrow unmount isCollapsed={activePanel !== 3} onClick={() => onPanelClick(3)} className="ecself-service__basic panel--3" title="SETTINGS_SELF_SERVICE_PANEL4_TITLE">
        <Text content="SETTINGS_SELF_SERVICE_PANEL4_CONTENT" />

        <Text className="ecself-service__basic__subtitle" content="SETTINGS_SELF_SERVICE_PANEL5_HEADER" />
        <CodePanel id="basic-copy-4" codeStr={personalDetails1 + actionPersonalDetails + personalDetails2}>
          <span className="ecself-service__basic__code-main">
            {personalDetails1}
            <span className="ecself-service__basic__variable">{actionPersonalDetails}</span>
            {personalDetails2}
          </span>
        </CodePanel>

        <Text className="ecself-service__basic__subtitle" content="SETTINGS_SELF_SERVICE_PANEL6_HEADER" />
        <CodePanel id="basic-copy-5" codeStr={address1 + addressAction + address2}>
          <span className="ecself-service__basic__code-main">
            {address1}
            <span className="ecself-service__basic__variable">{addressAction}</span>
            {address2}
          </span>
        </CodePanel>

        <Text className="ecself-service__basic__subtitle" content="SETTINGS_SELF_SERVICE_PANEL4_HEADER3" />
        <CodePanel id="basic-copy-6" codeStr={updatePaymentDetails1 + actionPaymentDetails + updatePaymentDetails2}>
          <span className="ecself-service__basic__code-main">
            {updatePaymentDetails1}
            <span className="ecself-service__basic__variable">{actionPaymentDetails}</span>
            {updatePaymentDetails2}
          </span>
        </CodePanel>


        <Text className="ecself-service__basic__subtitle" content="SETTINGS_SELF_SERVICE_PANEL4_HEADER4" />
        <CodePanel id="basic-copy-7" codeStr={billingHistory1 + billingHistoryAction + billingHistory2}>
          <span className="ecself-service__basic__code-main">
            {billingHistory1}
            <span className="ecself-service__basic__variable">{billingHistoryAction}</span>
            {billingHistory2}
          </span>
        </CodePanel>

        <Text className="ecself-service__basic__subtitle" content="SETTINGS_SELF_SERVICE_PANEL4_HEADER5" />
        <CodePanel noMargin id="basic-copy-8" codeStr={cancelPlan1 + actionCancelPlan + cancelPlan2}>
          <span className="ecself-service__basic__code-main">
            {cancelPlan1}
            <span className="ecself-service__basic__variable">{actionCancelPlan}</span>
            {cancelPlan2}
          </span>
        </CodePanel>
      </CollapsablePanelWithArrow>
    );
  };

    return (
      <div className="ecself-service">
        <Helmet title={`${counterpart("NAVBAR_EMBED_CODES")}-${counterpart("SETTINGS_EMBED_CODES_SELF_SERVICE")}`} />
        <Panel className="ecself-service__welcome" title="SETTINGS_SELF_SERVICE_PANEL1_TITLE">
          <Row>
            <Col md={10}>
              <Text content="SETTINGS_SELF_SERVICE_PANEL1_CONTENT" component="span" />
              <Link to="/configuration/brand-customization">
                <Text content="SETTINGS_EMBED_CODES_SELF_SERVICE_WELCOME_LINK" noMargin component="span" />
              </Link>
            </Col>
          </Row>
        </Panel>

        {renderBasicEmbedCodePanel()}
        {renderIDsManageAccountPanel()}
        {/* {renderOverrideDefaultLanguagePanel()} */}
        {renderDirectCustomersPanel()}
      </div>
    );
}

export default EmbedCodesSelfService;
