import React, { useState, useLayoutEffect } from "react";
import { Row, Col } from "react-grid-system";
import Panel from "../../ui/panel/Panel";
import Text from "../../ui/text/Text";
import FormGroup from "../../ui/form-group/FormGroup";
import FormLabel from "../../ui/form-label/FormLabel";
import Button from "../../ui/button/Button";
import TaxRatePanel from "./TaxRatePanel";
import "./ConfigureMemberStates.scss";
import { TaxRate } from "../../../utils/grpc/generated/Billsby.Protos/billing/private/company/taxation/taxation_pb";
import Dropdown from "../../ui/dropdown/Dropdown";
import counterpart from "counterpart";
import Input from "../../ui/input/Input";

interface IConfigureMemberStates {
  title: string;
  content: string;
  taxLabel?: string;
  taxRegNumber?: string;
  states: Array<IDropdownSelect>;
  selectedStates: Array<TaxRate>;
  isConfigured: boolean;
  isDigital?: boolean;
  originCountry?: TaxRate;
  onAdd: (value: IDropdownSelect) => void;
  onDelete: (value: TaxRate) => void;
  onAddOrigin: (value: IDropdownSelect) => void;
  onDeleteOrigin: () => void;
  onChangeTaxRate: (value: number, taxRate: TaxRate) => void;
  onChangeTaxRegNum?: (value: string, taxRate: TaxRate) => void;
  onDeleteConfig: () => void;
}

interface IDropdownSelect {
  value: string;
  label: string;
}

const ConfigureMemberStates: React.FC<IConfigureMemberStates> = React.memo(
  ({
    states,
    selectedStates,
    originCountry,
    title,
    isConfigured,
    isDigital,
    content,
    taxLabel,
    taxRegNumber,
    onAdd,
    onDelete,
    onDeleteConfig,
    onChangeTaxRate,
    onChangeTaxRegNum,
    onAddOrigin,
    onDeleteOrigin,
  }) => {
    const [memberStates, setMemberStates] = useState<Array<IDropdownSelect>>([]);

    useLayoutEffect(() => {
      setMemberStates(
        states.filter((state) => {
          //remove selected rates from dropdown
          return !(originCountry ? [...selectedStates, originCountry] : selectedStates).find((ss) => ss.getCountryIso3() === state.value);
        })
      );
    }, [selectedStates, originCountry]);

    const renderOriginForm = () => {
      return (
        <div className="configure-member-states__origin">
          <FormGroup noMargin={!originCountry}>
            <Row align="center">
              <Col md={2}>
                <FormLabel target="origin-country" content="CONFIG_MEMBER_STATE_ORIGIN_LABEL" noMargin />
              </Col>
              <Col md={10}>
                {originCountry && isConfigured ? (
                  <Input id="origin-country" value={originCountry.getName()} disabled />
                ) : (
                  <Dropdown id="selected-states" options={memberStates} onChange={(value: any) => onAddOrigin(value)} placeholder={counterpart("EUROPEAN_UNION_DROPDOWN_PLACEHOLDER")} />
                )}
              </Col>
            </Row>
          </FormGroup>
          {originCountry && (
            <TaxRatePanel
              key={originCountry.getCountryIso3()}
              required
              fixedNameWidth
              taxRate={originCountry}
              showDeleteButton
              showAsText={isConfigured}
              onChangeTaxRegNum={(value) => onChangeTaxRegNum && onChangeTaxRegNum(value, originCountry)}
              showTaxRegInput={!!onChangeTaxRegNum}
              taxRegNumTextFormat={`VAT reg #${originCountry.getTaxRegNumber()}`}
              onDelete={() => onDeleteOrigin()}
              onChangeTaxRate={(value) => onChangeTaxRate(value, originCountry)}
              isLocked={selectedStates.length > 0}
            />
          )}
        </div>
      );
    };

    const renderSelectedStatesForm = () => {
      return (
        <>
          <FormGroup noMargin={selectedStates.length === 0}>
            <Row align="center">
              <Col md={2}>
                <FormLabel target="selected-states" content={isDigital ? "CONFIG_MEMBER_STATE_LABEL" : "CONFIG_TAX_RATES_OVERRIDE_COUNTRIES"} noMargin />
              </Col>
              <Col md={10}>
                <Dropdown id="selected-states" options={memberStates} onChange={(value: any) => onAdd(value)} placeholder={counterpart("EUROPEAN_UNION_DROPDOWN_PLACEHOLDER")} />
              </Col>
            </Row>
          </FormGroup>

          {selectedStates.map((state) => (
            <TaxRatePanel
              key={state.getCountryIso3()}
              required
              fixedNameWidth
              taxRate={state}
              showDeleteButton
              showAsText={isConfigured}
              onChangeTaxRegNum={(value) => onChangeTaxRegNum && onChangeTaxRegNum(value, state)}
              showTaxRegInput={!!onChangeTaxRegNum}
              taxRegNumTextFormat={`VAT reg #${state.getTaxRegNumber()}`}
              onDelete={() => onDelete(state)}
              onChangeTaxRate={(value) => onChangeTaxRate(value, state)}
            />
          ))}
        </>
      );
    };

    return (
      <Panel className="configure-member-states" title={title}>
        {isConfigured && <Button className="add-tax-rates__floating-button" id="delete-config" title="ADD_TAX_DELETE" icon="far fa-trash-alt" buttonType="error" onClick={onDeleteConfig} />}
        <Text className={isConfigured ? "configure-member-states__content" : ""} content={content} />
        {taxLabel && taxRegNumber && (
          <FormGroup>
            <Text content={taxLabel} shouldTranslate={false} component="span" />
            <Text content={` #${taxRegNumber}`} shouldTranslate={false} className="bold" component="span" />
          </FormGroup>
        )}

        {isDigital ? null : renderOriginForm()}
        {isDigital ? renderSelectedStatesForm() : originCountry ? renderSelectedStatesForm() : null}
      </Panel>
    );
  }
);

export default ConfigureMemberStates;
