import { makeActionCreator, makeGenericActionCreatorTypeSafe } from "../../utils/reduxUtils";
import { PAGINATION_PAGE_SIZE } from "../../utils/constants";
import { Int32Value } from "google-protobuf/google/protobuf/wrappers_pb";
import { GetDiscountsRequest, GetCouponsRequest, DeleteDiscountRequest } from "../../utils/grpc/generated/Billsby.Protos/core/private/discounts/discounts_pb";
import { DiscountsServiceClient } from "../../utils/grpc/generated/Billsby.Protos/core/private/discounts/DiscountsServiceClientPb";
import { ConfigConstants } from "../../utils/config";
import { grpcUnaryCall } from "../../utils/grpc/grpcUtils";
import { IDiscountsState } from "../../reducers/discount-coupons/discountsReducer";
import { ExpireCouponRequest } from "../../utils/grpc/generated/Billsby.Protos/core/private/coupons/coupons_pb";
import { CouponsServiceClient } from "../../utils/grpc/generated/Billsby.Protos/core/private/coupons/CouponsServiceClientPb";
import { GetCouponCodesRequest  } from "../../utils/grpc/generated/Billsby.Protos/billing/private/coupons/coupons_pb";
import { CouponsServiceClient as BilingCouponServiceClient } from "../../utils/grpc/generated/Billsby.Protos/billing/private/coupons/CouponsServiceClientPb";

export const FETCH_DISCOUNTS_LIST_REQUEST = "FETCH_DISCOUNTS_LIST_REQUEST";
export const FETCH_DISCOUNTS_LIST_SUCCESS = "FETCH_DISCOUNTS_LIST_SUCCESS";
export const FETCH_DISCOUNTS_LIST_FAILURE = "FETCH_DISCOUNTS_LIST_FAILURE";

export const FETCH_COUPONS_LIST_REQUEST = "FETCH_COUPONS_LIST_REQUEST";
export const FETCH_COUPONS_LIST_SUCCESS = "FETCH_COUPONS_LIST_SUCCESS";
export const FETCH_COUPONS_LIST_FAILURE = "FETCH_COUPONS_LIST_FAILURE";

export const EXPIRE_COUPON_REQUEST = "EXPIRE_COUPON_REQUEST";
export const EXPIRE_COUPON_SUCCESS = "EXPIRE_COUPON_SUCCESS";
export const EXPIRE_COUPON_FAILURE = "EXPIRE_COUPON_FAILURE";

export const FETCH_COUPON_CODES_REQUEST = "FETCH_COUPON_CODES_REQUEST";
export const FETCH_COUPON_CODES_SUCCESS = "FETCH_COUPON_CODES_SUCCESS";
export const FETCH_COUPON_CODES_FAILURE = "FETCH_COUPON_CODES_FAILURE";

export const DELETE_DISCOUNT_REQUEST = "DELETE_DISCOUNT_REQUEST";
export const DELETE_DISCOUNT_SUCCESS = "DELETE_DISCOUNT_SUCCESS";
export const DELETE_DISCOUNT_FAILURE = "DELETE_DISCOUNT_FAILURE";

export const SET_DISCOUNTS_FIELDS = "SET_DISCOUNTS_FIELDS";
export const setDiscountsFields = makeGenericActionCreatorTypeSafe<IDiscountsState>(SET_DISCOUNTS_FIELDS)

export const RESET_DISCOUNT_LIST_STATE = "RESET_DISCOUNT_LIST_STATE";
export const resetDiscountList = makeActionCreator(RESET_DISCOUNT_LIST_STATE);

export const fetchDiscountsList = (companyId: number, page?: number, search?: string, pageSize: number = PAGINATION_PAGE_SIZE) => {

  const pageVal = new Int32Value();
  if(!!page) {
    pageVal.setValue(page as number)
  }
  const paginationSize = new Int32Value();
  paginationSize.setValue(pageSize);

  const getDiscountsRequest = new GetDiscountsRequest();
  getDiscountsRequest.setCompanyId(companyId);
  getDiscountsRequest.setPage(pageVal);
  getDiscountsRequest.setPageSize(paginationSize);
  getDiscountsRequest.setSearch(!!search ? search : "");
  
  const discountsServiceClient = new DiscountsServiceClient(ConfigConstants.grpcBaseUrl);

  return {
    types: [FETCH_DISCOUNTS_LIST_REQUEST, FETCH_DISCOUNTS_LIST_SUCCESS, FETCH_DISCOUNTS_LIST_FAILURE],
    callAPI: () =>  grpcUnaryCall(getDiscountsRequest, discountsServiceClient, discountsServiceClient.getDiscounts)
  }
}

export const fetchCouponsList = (companyId: number, discountId: number) => {

  const getCouponsRequest = new GetCouponsRequest();
  getCouponsRequest.setCompanyId(companyId);
  getCouponsRequest.setDiscountId(discountId);
  
  const discountsServiceClient = new DiscountsServiceClient(ConfigConstants.grpcBaseUrl);

  return {
    types: [FETCH_COUPONS_LIST_REQUEST, FETCH_COUPONS_LIST_SUCCESS, FETCH_COUPONS_LIST_FAILURE],
    payload: { discountId: discountId },
    callAPI: () =>  grpcUnaryCall(getCouponsRequest, discountsServiceClient, discountsServiceClient.getCoupons)
  }
}

export const expireCoupon = (companyId: number, couponId: number) => {

  const expireCouponRequest = new ExpireCouponRequest();
  expireCouponRequest.setCompanyId(companyId);
  expireCouponRequest.setCouponId(couponId);
  
  const couponsServiceClient = new CouponsServiceClient(ConfigConstants.grpcBaseUrl);

  return {
    types: [EXPIRE_COUPON_REQUEST, EXPIRE_COUPON_SUCCESS, EXPIRE_COUPON_FAILURE],
    callAPI: () =>  grpcUnaryCall(expireCouponRequest, couponsServiceClient, couponsServiceClient.expireCoupon)
  }
}

export const fetchCouponCodes = (companyId: number, couponId: number) => {

  const getCouponCodesRequest = new GetCouponCodesRequest();
  getCouponCodesRequest.setCompanyId(companyId);
  getCouponCodesRequest.setCouponId(couponId);
  
  const couponsServiceClient = new BilingCouponServiceClient(ConfigConstants.grpcBaseUrl);

  return {
    types: [FETCH_COUPON_CODES_REQUEST, FETCH_COUPON_CODES_SUCCESS, FETCH_COUPON_CODES_FAILURE],
    callAPI: () => grpcUnaryCall(getCouponCodesRequest, couponsServiceClient, couponsServiceClient.getCouponCodes)
  }
}

export const deleteDiscount = (companyId: number, discountId: number) => {

  const deleteDiscountRequest = new DeleteDiscountRequest();
  deleteDiscountRequest.setCompanyId(companyId);
  deleteDiscountRequest.setDiscountId(discountId);
  
  const discountsServiceClient = new DiscountsServiceClient(ConfigConstants.grpcBaseUrl);

  return {
    types: [DELETE_DISCOUNT_REQUEST, DELETE_DISCOUNT_SUCCESS, DELETE_DISCOUNT_FAILURE],
    callAPI: () =>  grpcUnaryCall(deleteDiscountRequest, discountsServiceClient, discountsServiceClient.deleteDiscount)
  }
}