import React, { useState, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import "./EmbedCodesCheckout.scss";
import Helmet from "react-helmet";
import counterpart from "counterpart";
import { Link } from "react-router-dom";
import Panel from "../../../components/ui/panel/Panel";
import Text from "../../../components/ui/text/Text";
import { Row, Col } from "react-grid-system";
import CodePanel from "../../../components/ui/code-panel/CodePanel";
import { AppState } from "../../../index";
import { ConfigConstants } from "../../../utils/config";
import CollapsablePanelWithArrow from "../../../components/ui/collapsable-panel-with-arrow/CollapsablePanelWithArrow";
import API from "../../../utils/API";
import { IProductLight, IPlanWithCycles, PricingModelType, ICycle, IPlanAddOnPricingModel, IPlanAllowancePricingModel } from "../../../models/Product";
import Dropdown from "../../../components/ui/dropdown/Dropdown";
import { getCycleDescription, getTieredUnitCycles, getMaximumUnit, getFrequencyTypeEnum, isComplexPricingModel, getMaximumUnitAddOn } from "../../../utils/commonUtils";
import EmbedCodesCustomFields from "./EmbedCodesCustomFields";
import Input from "../../../components/ui/input/Input";
import FormGroup from "../../../components/ui/form-group/FormGroup";
import Button from "../../../components/ui/button/Button";
import { fetchCurrencies } from "../../../actions/productPlanCycleActions";
import Tooltip from "../../../components/ui/tooltip/Tooltip";
import { PricingModelType as PricingModelTypeGrpc, } from "../../../utils/grpc/generated/Billsby.Protos/core/private/allowances/allowances_pb";
import { fetchEmbedCodesLanguages } from "../../../actions/embedCodesCheckOutActions";

interface IDropboxModel {
  value: string,
  label: string
}

interface IAddOnAllowanceModel extends IDropboxModel {
  name: string,
  pricingModelType: PricingModelTypeGrpc
  units?: number,
  maxUnits?: number
}

const EmbedCodesCheckout: React.FC = () => {
  const embedCodesCheckOutData = useSelector((state: AppState) => state.embedCodesCheckOutReducer);
  const companyDomain = useSelector((state: AppState) => state.auth.currentCompanyDomain);
  const companyId = useSelector((state: AppState) => state.auth.currentCompanyId);
  const productPlanCycle = useSelector((state: AppState) => state.productPlanCycle);

  const dispatch = useDispatch();

  const dropdownModel = { value: "", label: "" };
  const { currencies } = productPlanCycle;
  const { customFields, companyLanguages } = embedCodesCheckOutData;

  useEffect(() => {
    const fetchLanguages = async () => {
      try {
        companyId && await dispatch(fetchEmbedCodesLanguages(companyId));
      } catch (err) {
        console.log(err)
      }
    }
    fetchLanguages()
  }, [])

  const [activePanel, setActivePanel] = useState<number | undefined>(undefined);

  const [products, setProducts] = useState<Array<IDropboxModel>>([]);
  const [productsDetails, setProductsDetails] = useState<Array<IProductLight>>([]);
  const [selectedProduct, setSelectedProduct] = useState<IDropboxModel>(dropdownModel);
  const [plansSource, setPlansSource] = useState<Array<IPlanWithCycles>>([]);
  const [plans, setPlans] = useState<Array<IDropboxModel>>([]);
  const [selectedPlan, setSelectedPlan] = useState<IDropboxModel>(dropdownModel);
  const [cycles, setCycles] = useState<Array<IDropboxModel>>([]);
  const [selectedCycle, setSelectedCycle] = useState<IDropboxModel>(dropdownModel);
  const [planDetails, setPlanDetails] = useState<IPlanWithCycles | undefined>(undefined);
  const [productDetails, setProductDetail] = useState<IProductLight | undefined>(undefined);
  const [unitValue, setUnitValue] = useState<number | undefined>(undefined);
  const [isUnitAdded, setIsUnitAdded] = useState(false);
  const [maxUnit, setMaxUnit] = useState(0);
  const [currencyFormat, setCurrencyFormat] = useState<string | undefined>("$_ ");
  const [addOns, setAddOns] = useState<Array<IDropboxModel>>([]);
  const [selectedAddOns, setSelectedAddOns] = useState<Array<IAddOnAllowanceModel>>([]);
  const [selectedAddOn, setSelectedAddOn] = useState<IAddOnAllowanceModel | null>(null);
  const [allowances, setAllowances] = useState<Array<IDropboxModel>>([]);
  const [selectedAllowances, setSelectedAllowances] = useState<Array<IAddOnAllowanceModel>>([]);
  const [selectedAllowance, setSelectedAllowance] = useState<IAddOnAllowanceModel | null>(null);
  const [selectedLanguage, setSelectedLanguage] = useState<{ value: "", label: "" } | undefined>(undefined);


  const isUnitBased = planDetails ? planDetails.pricingModelType !== PricingModelType.FlatFee : false;
  const isComplexPricing = planDetails ? planDetails.pricingModelType !== PricingModelType.FlatFee && planDetails.pricingModelType !== PricingModelType.PerUnit : false;
  const isOutOfRange = unitValue ? unitValue > maxUnit : false;

  const basicCode1 = `<script src="${ConfigConstants.checkoutLibUrl}" data-billsby-company="${companyDomain}"></script>`;
  const basicCode2 = "<a href=\"javascript:void(0)\" data-billsby-type=\"checkout\">Subscribe</a>";
  const basicCode3_1 = "<a href=\"javascript:void(0)\" data-billsby-type=\"checkout\" data-billsby-product=\"";
  const basicCode3_2 = "\">Subscribe</a>";
  const basicCode4 = " data-billsby-plan=\"";
  const basicCode5 = " data-billsby-cycle=\"";
  const basicCode6 = " data-billsby-units=\"";
  const basicCode7 = " data-billsby-addons=\"";
  const basicCode8 = " data-billsby-allowances=\"";
  const productId = "[Product ID]";
  const planId = "[Plan ID]";
  const cycleId = "[Cycle ID]";
  const units = "[Units]";
  const basicCode_override_1_1 = "<a href=\"javascript:void(0)\" data-billsby-type=\"checkout\" data-billsby-redirect=\"";
  const basicCode_override_1_2 = "\">Subscribe</a>\"";
  const redirectURL = "[Redirect URL]";
  const basicCode_override_2_1 = "<a href=\"javascript:void(0)\" data-billsby-type=\"checkout\" data-billsby-wmessage=\"";
  const basicCode_override_2_2 = "\">Subscribe</a>";
  const wMessage = "[Welcome Message]";
  const scriptPassCustomerData_1 = "<script>";
  const scriptPassCustomerData_1_1 = "window.billsbyData = {";
  const scriptPassCustomerData_1_2 = "cid:";
  const scriptPassCustomerData_2 = "shippingAddressLine1:";
  const scriptPassCustomerData_3 = "shippingAddressLine2:";
  const scriptPassCustomerData_4 = "shippingAddressCity:";
  const scriptPassCustomerData_5 = "shippingAddressState:";
  const scriptPassCustomerData_6 = "shippingAddressZip:";
  const scriptPassCustomerData_7 = "shippingAddressCountry:";
  const scriptPassCustomerData_7_1 = "companyName:";
  const scriptPassCustomerData_8 = "additionalEmails:";
  const scriptPassCustomerData_9 = "};";
  const scriptPassCustomerData_10 = "</script>";

  const customerID = "[Customer ID]";
  const freeText = "[Free text]";
  //const freeTextAddressState = "[Free text, unless US/Canada in which case two letter state code]";
  const freeTextAddressState = "[ISO2 Code]";
  const freeTextAddressZip = "[Free text, unless US in which case five digit ZIP code]";
  const iso3Code = "[ISO3 Code]";
  const additionalEmails = "[Email1],[Email2],[Email3]";
  const customField_1 = "customFields: [";
  const customField_2 = "{";
  const customField_3 = "customFieldId:";
  const customField_4 = "[Custom Field Id]";
  const customField_5 = "value:";
  const customField_6 = "[Custom Field Value]";
  const customField_7 = "}";
  const customField_8 = "]";

  const scriptPassCustomerData = `
  ${scriptPassCustomerData_1 + scriptPassCustomerData_1_1 + scriptPassCustomerData_1_2}"${customerID}", 
  ${scriptPassCustomerData_2}"${freeText}",
  ${scriptPassCustomerData_3}"${freeText}",
  ${scriptPassCustomerData_4}"${freeText}",
  ${scriptPassCustomerData_5}"${freeTextAddressState}",
  ${scriptPassCustomerData_6}"${freeTextAddressZip}",
  ${scriptPassCustomerData_7}"${iso3Code}",
  ${scriptPassCustomerData_7_1}"${freeText}",
  ${scriptPassCustomerData_8}"${additionalEmails}",
  ${customField_1}
  ${customField_2}
  ${customField_3}${customField_4},
  ${customField_5}"${customField_6}"
  ${customField_7}
  ${customField_8}
  ${scriptPassCustomerData_9 + scriptPassCustomerData_10}
  `;

  const scriptNewCustomerData_1 = "firstName:";
  const scriptNewCustomerData_2 = "lastName:";
  const scriptNewCustomerData_3 = "email:";
  const scriptNewCustomerData_4 = "billingAddressLine1:";
  const scriptNewCustomerData_5 = "billingAddressLine2:";
  const scriptNewCustomerData_6 = "billingAddressCity:";
  const scriptNewCustomerData_7 = "billingAddressState:";
  const scriptNewCustomerData_8 = "billingAddressZip:";
  const scriptNewCustomerData_9 = "billingAddressCountry:";
  const scriptNewCustomerData_10 = "phoneNumberDialCode:";
  const scriptNewCustomerData_11_b = "phoneNumberDialCountry:";
  const scriptNewCustomerData_11 = "phoneNumber:";
  const scriptNewCustomerData_12 = "marketingConsent:";

  const email = "[Valid email address]";
  const phoneNumberDialCode = "[International code]";
  const phoneNumber = "[Phone number]";
  const phoneNumberDialCountry = "[Phone number dial country ISO2]";
  const marketingConsent = "[true/false]";

  const refreshBillsbyCommand = "window.scanDomBillsby();";

  const scriptNewCustomerData = `
  ${scriptPassCustomerData_1 + scriptPassCustomerData_1_1}
  ${scriptNewCustomerData_1}"${freeText}",
  ${scriptNewCustomerData_2}"${freeText}",
  ${scriptNewCustomerData_3}"${email}",
  ${scriptNewCustomerData_4}"${freeText}",
  ${scriptNewCustomerData_5}"${freeText}",
  ${scriptNewCustomerData_6}"${freeText}",
  ${scriptNewCustomerData_7}"${freeTextAddressState}",
  ${scriptNewCustomerData_8}"${freeTextAddressZip}",
  ${scriptNewCustomerData_9}"${iso3Code}",
  ${scriptPassCustomerData_2}"${freeText}",
  ${scriptPassCustomerData_3}"${freeText}",
  ${scriptPassCustomerData_4}"${freeText}",
  ${scriptPassCustomerData_5}"${freeTextAddressState}",
  ${scriptPassCustomerData_6}"${freeTextAddressZip}",
  ${scriptPassCustomerData_7}"${iso3Code}",
  ${scriptPassCustomerData_7_1}"${freeText}",
  ${scriptNewCustomerData_10}"${phoneNumberDialCode}",
  ${scriptNewCustomerData_11}"${phoneNumber}",
  ${scriptNewCustomerData_11_b}"${phoneNumberDialCountry}",
  ${scriptNewCustomerData_12}${marketingConsent},
  ${customField_1}
  ${customField_2}
  ${customField_3}${customField_4},
  ${customField_5}"${customField_6}"
  ${customField_7}
  ${customField_8}
  ${scriptPassCustomerData_9 + scriptPassCustomerData_10}
  `;

  useEffect(() => {
    const fetchData = async () => {
      try {
        const productRequest = await API.fetchAllProducts(companyId, true, true, true) as { results: Array<IProductLight>};
        await dispatch(fetchCurrencies())
        const products = productRequest.results.filter(p => !p.isDeleted);
        
        const dropdownProducts = products.map((product) => {
          const name = product.displayName ? product.displayName : product.name;
          return { value: product.productId.toString(), label: `${name}-${product.visibility}` }
        })

        setProducts(dropdownProducts);
        setProductsDetails(products);
        setPlans([]);
        setCycles([]);

      }
      catch (err) {
        setProducts([]);
        setProductsDetails([]);
        setPlans([]);
        setCycles([])
      }
    }

    fetchData()
  }, []);

  useEffect(() => {
    if (planDetails && isComplexPricing) {
      setMaxUnit(getMaximumUnit(planDetails))
    } else {
      setMaxUnit(1000000)
    }

  }, [selectedPlan, isComplexPricing]);

  useEffect(() => {
    if (productDetails) {
      const currency = currencies.find(c => c.isoCode === productDetails.billingCurrency);
      if (currency) {
        setCurrencyFormat(currency.symbol)
      } else {
        setCurrencyFormat("$_")
      }

    }
  }, [productDetails]);

  useEffect(() => {
    if (planDetails) {
      let newCycles: Array<ICycle> = planDetails.cycles

      const planWithUnits = getTieredUnitCycles(planDetails, unitValue, currencyFormat);

      if (isComplexPricing) {
        if (unitValue && planWithUnits) {
          newCycles = planWithUnits
        } else {
          newCycles = planDetails.cycles
        }
      } else {
        if (isUnitAdded && planWithUnits) {
          newCycles = planWithUnits
        } else {
          newCycles = planDetails.cycles
        }
      }

      const tCycle = newCycles/*.filter(c => c.visibility !== VisibilityType.OffSale)*/.map((cycle) => {
        return { value: cycle.cycleId.toString(), label: `${getCycleDescription(cycle)}-${cycle.visibility}` };
      });

      setCycles(tCycle);
      if (selectedCycle.value && !isComplexPricing) {
        let cycle = tCycle.find(c => c.value === selectedCycle.value);

        setSelectedCycle(cycle ? cycle : dropdownModel);
      }

    }
  }, [planDetails, unitValue, isComplexPricing, isUnitAdded]);

  useEffect(() => {
    if (planDetails) {
      const cycleDetails = planDetails.cycles.find(c => c.cycleId === +selectedCycle.value);
      if (cycleDetails) {
        const addOns: Array<IAddOnAllowanceModel> = planDetails.addons.map(addOn => {
          const addOnPriceModel = addOn.pricingModels.find(p => p.frequency === cycleDetails.pricingModel.frequency
            && getFrequencyTypeEnum(p.frequencyType) === cycleDetails.pricingModel.frequencyType) as IPlanAddOnPricingModel;
          return {
            value: addOn.id.toString(),
            //label: `${getAddOnAllowancePriceModelDescription(addOnPriceModel)}-${addOn.id}`,
            label: `${addOn.name}`,
            name: addOn.name,
            pricingModelType: addOn.pricingModelType,
            maxUnits: getMaximumUnitAddOn(addOnPriceModel)
          }
        });
        const allowances: Array<IAddOnAllowanceModel> = planDetails.allowances.map(allowance => {
          const allowancePriceModel = allowance.pricingModels.find(p => p.frequency === cycleDetails.pricingModel.frequency
            && getFrequencyTypeEnum(p.frequencyType) === cycleDetails.pricingModel.frequencyType) as IPlanAllowancePricingModel;
          return {
            value: allowance.id.toString(),
            //label: `${getAddOnAllowancePriceModelDescription(allowancePriceModel)}-${allowance.id}`,
            label: `${allowance.name}`,
            name: allowance.name,
            pricingModelType: allowance.pricingModelType
          }
        });
        setAddOns(addOns);
        setAllowances(allowances)
      }
    }
  }, [selectedCycle]);

  const addableAddOns = useMemo(() => {
    return addOns
      .filter(a => selectedAddOns.every(b => a.value !== b.value))
      .sort((a, b) => a.label.toLowerCase() > b.label.toLowerCase() ? 1 : -1);
  }, [addOns, selectedAddOns]);

  const addableAllowances = useMemo(() => {
    return allowances
      .filter(a => selectedAllowances.every(b => a.value !== b.value))
      .sort((a, b) => a.label.toLowerCase() > b.label.toLowerCase() ? 1 : -1);;
  }, [allowances, selectedAllowances]);


  const onProductChange = async (selectedProduct: IDropboxModel) => {
    setUnitValue(undefined);
    setIsUnitAdded(false);
    setProductDetail(productsDetails.find(p => p.productId === +selectedProduct.value))

    try {
      const plans = await API.fetchPlansByProduct(companyDomain, Number(selectedProduct.value)) as Array<IPlanWithCycles>;
      if (plans) {
        const tPlans = plans.map((plan) => {
          const name = plan.displayName ? plan.displayName : plan.name;
          return { value: plan.planId.toString(), label: `${name}-${plan.visibility}` }
        });

        setPlans(tPlans);
        setPlansSource(plans)
      }
    }
    finally {
      setSelectedProduct(selectedProduct);
      setSelectedPlan(dropdownModel);
      setSelectedCycle(dropdownModel);
      setSelectedAddOns([]);
      setSelectedAllowances([]);
    }
  }

  const onPlanChange = (selectedPlan: IDropboxModel) => {
    setUnitValue(undefined);
    setIsUnitAdded(false);

    try {
      const plan = plansSource.find(s => s.planId.toString() === selectedPlan.value);

      if (plan) {
        const name = plan.displayName ? plan.displayName : plan.name;
        setPlanDetails(plan)
        setSelectedCycle(dropdownModel);
        setSelectedPlan({ value: plan.planId.toString(), label: `${name}-${plan.visibility}` });
      };
    }
    catch (err) {
      console.log(err)
    }
    finally {
      setSelectedAddOns([]);
      setSelectedAllowances([]);
    }
  }

  const onPanelClick = (number: number) => {
    if (activePanel === number) {
      setActivePanel(undefined)
    } else {
      setActivePanel(number)
    }
  }

  const renderBasicEmbedCode = () => {
    return (
      <Panel className="eccheckout__basic" title="SETTINGS_EMBED_CODES_CHECKOUT_BASIC_TITLE">
        <Text content="SETTINGS_EMBED_CODES_CHECKOUT_BASIC_TEXT_1" />
        <Text className="eccheckout__basic__subtitle" content="SETTINGS_EMBED_CODES_CHECKOUT_BASIC_TEXT_2" />
        <CodePanel id="basic-copy-1" codeStr={basicCode1}>
          <span className="eccheckout__basic__code-main">
            {basicCode1}
          </span>
        </CodePanel>
        <Text className="eccheckout__basic__subtitle" content="SETTINGS_EMBED_CODES_CHECKOUT_BASIC_TEXT_3" />
        <CodePanel id="basic-copy-2" codeStr={basicCode2} noMargin={true}>
          <span className="eccheckout__basic__code-main">
            {basicCode2}
          </span>
        </CodePanel>
      </Panel>
    )
  }

  const renderPassingIDsInSubscribeButton = () => {
    const isLastInArray = (indexEl: number, list: Array<IAddOnAllowanceModel>) => {
      return indexEl === list.length - 1;
    }

    const getAddOnsCodeString = () => {
      return selectedAddOns.length
        ? `${basicCode7}[${selectedAddOns.map((a, idx) => `[${a.value}${a.units ? `,${a.units}` : ""}]`)}]`
        : ""
    }
    const getAllowancesCodeString = () => {
      return selectedAllowances.length
        ? `${basicCode8}[${selectedAllowances.map((a, idx) => `${a.value}`)}]`
        : ""
    }

    const addDoubleQuoteAfterCycleId = () => {
      if(!!getAddOnsCodeString()) {
        return "\""
      } 
      return ""
    }

    const codeStringPlan = isUnitAdded
      ? basicCode3_1 + selectedProduct.value + "\"" + basicCode4 + selectedPlan.value + "\"" + basicCode6 + unitValue + basicCode3_2
      : basicCode3_1 + selectedProduct.value + "\"" + basicCode4 + selectedPlan.value + basicCode3_2
    const codeStringCycle = isUnitAdded
      ? basicCode3_1 + selectedProduct.value + "\"" + basicCode4 + selectedPlan.value + "\"" + basicCode5 + selectedCycle.value + "\"" + basicCode6 + unitValue + basicCode3_2
      : basicCode3_1 + selectedProduct.value + "\"" + basicCode4 + selectedPlan.value + "\"" + basicCode5 + selectedCycle.value + basicCode3_2
    const codeAddOns = isUnitAdded
      ? basicCode3_1 + selectedProduct.value + "\"" + basicCode4 + selectedPlan.value + "\"" + basicCode5 + selectedCycle.value + "\"" + basicCode6 + unitValue + "\"" + getAddOnsCodeString() + basicCode3_2
      : basicCode3_1 + selectedProduct.value + "\"" + basicCode4 + selectedPlan.value + "\"" + basicCode5 + selectedCycle.value + "\"" + getAddOnsCodeString() + basicCode3_2
    const codeAllowances = isUnitAdded
      ? basicCode3_1 + selectedProduct.value + "\"" + basicCode4 + selectedPlan.value + "\"" + basicCode5 + selectedCycle.value + "\"" + basicCode6 + unitValue + "\"" + getAddOnsCodeString() + "\"" + getAllowancesCodeString() + basicCode3_2
      : basicCode3_1 + selectedProduct.value + "\"" + basicCode4 + selectedPlan.value + "\"" + basicCode5 + selectedCycle.value + addDoubleQuoteAfterCycleId() + getAddOnsCodeString() + "\"" + getAllowancesCodeString() + basicCode3_2

    return (
      <CollapsablePanelWithArrow unmount isCollapsed={activePanel !== 1} onClick={() => onPanelClick(1)} className="eccheckout__basic panel--1" title="EMBED_CODES_PASSING_PRODUCT_PLAN_CYCLE_TITLE">
        <Text content="EMBED_CODES_PASSING_PRODUCT_PLAN_CYCLE_CONTENT" />
        <FormGroup noMargin={!selectedProduct.value}>
          <Text className="eccheckout__basic__subtitle" content="EMBED_CODES_PASSING_PRODUCT_PLAN_CYCLE_SUBTITLE1" />
          <Dropdown
            id="product"
            onChange={(selectedProduct: any) => onProductChange(selectedProduct)}
            value={selectedProduct.value ? selectedProduct : null}
            placeholder={counterpart("CHECKOUT_EMBED_SELECT_PRODUCT")}
            options={products}
          />
        </FormGroup>

        {selectedProduct.value &&
          <CodePanel id="basic-copy-3-sample" codeStr={basicCode3_1 + selectedProduct.value + basicCode3_2}>
            <span className="eccheckout__basic__code-main">
              {basicCode3_1}<span className="eccheckout__basic__variable">{selectedProduct.value}</span>{basicCode3_2}
            </span>
          </CodePanel>
        }
        {selectedProduct.value && plans &&
          <FormGroup noMargin={!selectedPlan.value}>
            <Text className="eccheckout__basic__subtitle" content="EMBED_CODES_PASSING_PRODUCT_PLAN_CYCLE_SUBTITLE2"></Text>
            <Dropdown
              id="plan"
              onChange={(selectedPlan: any) => onPlanChange(selectedPlan)}
              value={selectedPlan.value ? selectedPlan : null}
              placeholder={counterpart("CHECKOUT_EMBED_SELECT_PLAN")}
              options={plans}
            />
          </FormGroup>
        }
        {isUnitBased && selectedPlan.value && plans &&
          <FormGroup>
            <Text
              className="eccheckout__basic__subtitle"
              content="EMBED_CODES_PASSING_PRODUCT_PLAN_CYCLE_SUBTITLE5"
              translateWith={{ planName: planDetails ? planDetails.displayName : "" }}
            />
            <Row>
              <Col md={10}>
                <Input
                  id="units"
                  type="number"
                  placeholder="10"
                  disabled={isUnitAdded}
                  value={unitValue}
                  onChange={(evt: any) => {
                    const value = evt.target.value;
                    setSelectedCycle(dropdownModel);
                    setUnitValue(value && +value >= 0 ? +evt.target.value : undefined);
                  }}
                />
              </Col>
              <Col md={2}>
                {isOutOfRange && <Tooltip id='add-unit-tooltip' place='top' type='blue'>
                  <Text content='EMBED_CODES_PASSING_PRODUCT_PLAN_CYCLE_WARNING' translateWith={{ units: maxUnit }} component={"span"} />
                </Tooltip>}
                <div data-tip data-for='add-unit-tooltip'>
                  <Button disabled={!Boolean(unitValue) || isOutOfRange}
                    id="units" title={isUnitAdded ? "EMBED_CODES_PASSING_PRODUCT_PLAN_CYCLE_DELETE" : "EMBED_CODES_PASSING_PRODUCT_PLAN_CYCLE_ADD_UNIT"}
                    isFullWidth
                    icon={isUnitAdded ? "far fa-trash-alt" : "far fa-plus-circle"}
                    onClick={() => {
                      setIsUnitAdded(isUnitAdded ? false : true);

                      if (isUnitAdded) {
                        setUnitValue(undefined);
                        setSelectedCycle(dropdownModel)
                      }
                    }}
                    buttonType={isUnitAdded ? "error" : "add"} />
                </div>
              </Col>
            </Row>
          </FormGroup>

        }
        {selectedPlan.value &&
          <CodePanel id="basic-copy-4" codeStr={codeStringPlan}>
            <span className="eccheckout__basic__code-main">
              {basicCode3_1}<span className="eccheckout__basic__variable">{selectedProduct.value}</span>" {basicCode4}<span className="eccheckout__basic__variable">{selectedPlan.value}</span>{isUnitAdded && <>" {basicCode6}<span className="eccheckout__basic__variable">{unitValue}</span></>}{basicCode3_2}
            </span>
          </CodePanel>
        }
        {selectedPlan.value && cycles &&
          <FormGroup noMargin={!selectedCycle.value}>
            <Text className="eccheckout__basic__subtitle" content="EMBED_CODES_PASSING_PRODUCT_PLAN_CYCLE_SUBTITLE3" translateWith={{ planName: planDetails ? planDetails.displayName : "" }}></Text>
            <Dropdown
              id="cycle"
              onChange={(selectedCycle: any) => setSelectedCycle(selectedCycle)}
              placeholder={counterpart("CHECKOUT_EMBED_SELECT_CYCLE")}
              value={selectedCycle.value ? selectedCycle : null}
              options={cycles}
            />
          </FormGroup>
        }
        {selectedCycle.value &&
          <CodePanel noMargin={!addOns.length && !allowances.length} id="basic-copy-5" codeStr={codeStringCycle}>
            <span className="eccheckout__basic__code-main">
              {basicCode3_1}<span className="eccheckout__basic__variable">{selectedProduct.value}</span>" {basicCode4}<span className="eccheckout__basic__variable">{selectedPlan.value}</span>" {basicCode5}<span className="eccheckout__basic__variable">{selectedCycle.value}</span>{isUnitAdded && <>" {basicCode6}<span className="eccheckout__basic__variable">{unitValue}</span></>}{basicCode3_2}
            </span>
          </CodePanel>
        }

        {selectedCycle.value && selectedAddOns.map(addOn =>
          // view selected add-ons
          <FormGroup>
            <Text className="eccheckout__basic__subtitle" content="EMBED_CODES_PASSING_PRODUCT_PLAN_CYCLE_SUBTITLE6" />
            <Row>
              <Col md={10}>
                <Input
                  id="addon"
                  disabled
                  value={addOn.label}
                />
              </Col>
              <Col md={2}>
                <Button
                  id="remove-addon"
                  title="EMBED_CODES_PASSING_PRODUCT_PLAN_CYCLE_REMOVE"
                  isFullWidth
                  icon="far fa-trash-alt"
                  onClick={() => {
                    setSelectedAddOns(selectedAddOns.filter(a => a.value !== addOn.value));
                  }}
                  buttonType="error"
                />
              </Col>
            </Row>
          </FormGroup>
        )}

        {selectedCycle.value && !!addOns.length && addableAddOns.length !== 0 &&
          // add an add-on
          <FormGroup>
            <Text className="eccheckout__basic__subtitle" content="EMBED_CODES_PASSING_PRODUCT_PLAN_CYCLE_SUBTITLE6" />
            <Row>
              <Col md={10}>
                <Dropdown
                  id="addons"
                  onChange={(selectedAddOn: any) => setSelectedAddOn(selectedAddOn)}
                  placeholder={counterpart("CHECKOUT_EMBED_SELECT_ADDON")}
                  value={selectedAddOn}
                  options={addableAddOns}
                />
              </Col>
              <Col md={2}>
                <Button
                  disabled={!selectedAddOn}
                  id="selected-addon"
                  title="EMBED_CODES_PASSING_PRODUCT_PLAN_CYCLE_ADD_ADDON"
                  isFullWidth
                  icon="far fa-plus-circle"
                  onClick={() => {
                    if (selectedAddOn) {
                      setSelectedAddOns([...selectedAddOns, selectedAddOn]);
                      setSelectedAddOn(null);
                    }
                  }}
                  buttonType="add"
                  isVisible={!!selectedAddOn && !isComplexPricingModel(selectedAddOn.pricingModelType)}
                />
              </Col>
            </Row>
          </FormGroup>
        }

        {selectedCycle.value && !!addOns.length && selectedAddOn && isComplexPricingModel(selectedAddOn.pricingModelType) &&
          // add add-on units (only for unit based types)
          <FormGroup>
            <Text
              className="eccheckout__basic__subtitle"
              content="EMBED_CODES_PASSING_PRODUCT_PLAN_CYCLE_SUBTITLE8"
              translateWith={{ addOnName: selectedAddOn.name }}
            />
            <Row>
              <Col md={10}>
                <Input
                  id="addon-units"
                  type="number"
                  placeholder=""
                  value={selectedAddOn.units}
                  onChange={evt => setSelectedAddOn({ ...selectedAddOn, units: evt.target.value })}
                />
              </Col>
              <Col md={2}>
                {Number(selectedAddOn.units) > Number(selectedAddOn.maxUnits) &&
                  <Tooltip id='addon-unit-tooltip' place='top' type='blue'>
                    <Text content='EMBED_CODES_PASSING_PRODUCT_PLAN_CYCLE_WARNING' translateWith={{ units: selectedAddOn.maxUnits }} component={"span"} />
                  </Tooltip>}
                <div data-tip data-for='addon-unit-tooltip'>
                  <Button
                    disabled={!!selectedAddOn.units && Number(selectedAddOn.units) > Number(selectedAddOn.maxUnits)}
                    id="selected-addon"
                    title="EMBED_CODES_PASSING_PRODUCT_PLAN_CYCLE_ADD_ADDON"
                    isFullWidth
                    icon="far fa-plus-circle"
                    onClick={() => {
                      if (selectedAddOn) {
                        setSelectedAddOns([...selectedAddOns, selectedAddOn]);
                        setSelectedAddOn(null);
                      }
                    }}
                    buttonType="add"
                  />
                </div>
              </Col>
            </Row>
          </FormGroup>
        }

        {!!selectedAddOns.length &&
          <CodePanel noMargin={!allowances.length} id="basic-copy-6" codeStr={codeAddOns}>
            <span className="eccheckout__basic__code-main">
              {basicCode3_1}<span className="eccheckout__basic__variable">{selectedProduct.value}</span>" {basicCode4}<span className="eccheckout__basic__variable">{selectedPlan.value}</span>" {basicCode5}<span className="eccheckout__basic__variable">{selectedCycle.value}</span>{isUnitAdded && <>" {basicCode6}<span className="eccheckout__basic__variable">{unitValue}</span></>}
              {selectedAddOns.length
                ? <>"{basicCode7}<span className="eccheckout__basic__variable">[{selectedAddOns.map((a, idx) => `[${a.value}${a.units ? `,${a.units}` : ""}]${!isLastInArray(idx, selectedAddOns) ? "," : ""}`)}]</span></>
                : ""}
              {basicCode3_2}
            </span>
          </CodePanel>
        }

        {selectedCycle.value && selectedAllowances.map(allowance =>
          // view selected allowances
          <FormGroup>
            <Text className="eccheckout__basic__subtitle" content="EMBED_CODES_PASSING_PRODUCT_PLAN_CYCLE_SUBTITLE7" />
            <Row>
              <Col md={10}>
                <Input
                  id="allowance"
                  disabled
                  value={allowance.label}
                />
              </Col>
              <Col md={2}>
                <Button
                  id="remove-allowance"
                  title="EMBED_CODES_PASSING_PRODUCT_PLAN_CYCLE_REMOVE"
                  isFullWidth
                  icon="far fa-trash-alt"
                  onClick={() => {
                    setSelectedAllowances(selectedAllowances.filter(a => a.value !== allowance.value));
                  }}
                  buttonType="error"
                />
              </Col>
            </Row>
          </FormGroup>
        )}
        {selectedCycle.value && !!allowances.length && addableAllowances.length !== 0 &&
          // add an allowance
          <FormGroup>
            <Text className="eccheckout__basic__subtitle" content="EMBED_CODES_PASSING_PRODUCT_PLAN_CYCLE_SUBTITLE7" />
            <Row>
              <Col md={10}>
                <Dropdown
                  id="allowances"
                  onChange={(selectedAllowance: any) => setSelectedAllowance(selectedAllowance)}
                  placeholder={counterpart("CHECKOUT_EMBED_SELECT_ALLOWANCE")}
                  value={selectedAllowance}
                  options={addableAllowances}
                />
              </Col>
              <Col md={2}>
                <Button
                  disabled={!selectedAllowance}
                  id="selected-allowance"
                  title="EMBED_CODES_PASSING_PRODUCT_PLAN_CYCLE_ADD_ALLOWANCE"
                  isFullWidth
                  icon="far fa-plus-circle"
                  onClick={() => {
                    if (selectedAllowance) {
                      setSelectedAllowances([...selectedAllowances, selectedAllowance]);
                      setSelectedAllowance(null);
                    }
                  }}
                  buttonType="add"
                />
              </Col>
            </Row>
          </FormGroup>
        }
        {!!selectedAllowances.length &&
          <CodePanel noMargin id="basic-copy-6" codeStr={codeAllowances}>
            <span className="eccheckout__basic__code-main">
              {basicCode3_1}<span className="eccheckout__basic__variable">{selectedProduct.value}</span>" {basicCode4}<span className="eccheckout__basic__variable">{selectedPlan.value}</span>" {basicCode5}<span className="eccheckout__basic__variable">{selectedCycle.value}</span>{isUnitAdded && <>" {basicCode6}<span className="eccheckout__basic__variable">{unitValue}</span></>}
              {selectedAddOns.length
                ? <>"{basicCode7}<span className="eccheckout__basic__variable">[{selectedAddOns.map((a, idx) => `[${a.value}${a.units ? `,${a.units}` : ""}]${!isLastInArray(idx, selectedAddOns) ? "," : ""}`)}]</span></>
                : ""}
              {selectedAllowances.length
                ? <>"{basicCode8}<span className="eccheckout__basic__variable">[{selectedAllowances.map((a, idx) => `${a.value}${!isLastInArray(idx, selectedAllowances) ? "," : ""}`)}]</span></>
                : ""}
              {basicCode3_2}
            </span>
          </CodePanel>
        }

      </CollapsablePanelWithArrow>
    )
  }

  const renderOverridingRedirectSettingsInSubscribeButton = () => {
    return (
      <CollapsablePanelWithArrow unmount isCollapsed={activePanel !== 3} onClick={() => onPanelClick(3)} className="eccheckout__basic panel--3" title="EMBED_OVERRIDING_REDIRECT_SETTINGS_TITLE">
        <Text content="EMBED_OVERRIDING_REDIRECT_SETTINGS_CONTENT" />

        <Text className="eccheckout__basic__subtitle" content="EMBED_OVERRIDING_REDIRECT_SETTINGS_SUBTITLE1" />
        <CodePanel id="basic-copy-6" codeStr={basicCode_override_1_1 + redirectURL + basicCode_override_1_2}>
          <span className="eccheckout__basic__code-main">
            {basicCode_override_1_1}<span className="eccheckout__basic__variable">{redirectURL}</span>{basicCode_override_1_2}
          </span>
        </CodePanel>

        <Text className="eccheckout__basic__subtitle" content="EMBED_OVERRIDING_REDIRECT_SETTINGS_SUBTITLE2" />
        <CodePanel noMargin id="basic-copy-7" codeStr={basicCode_override_2_1 + wMessage + basicCode_override_2_2}>
          <span className="eccheckout__basic__code-main">
            {basicCode_override_2_1}<span className="eccheckout__basic__variable">{wMessage}</span>{basicCode_override_2_2}
          </span>
        </CodePanel>
      </CollapsablePanelWithArrow>
    )
  }

  const renderPassingCustomerDataInArray = () => {
    return (
      <CollapsablePanelWithArrow unmount isCollapsed={activePanel !== 4} onClick={() => onPanelClick(4)} className="panel--4" title="EMBED_CODES_PASSING_CUSTOMER_DATA_TITLE">
        <Text content="EMBED_CODES_PASSING_CUSTOMER_DATA_CONTENT" />
        <Text className="eccheckout__basic__subtitle" content="EMBED_CODES_PASSING_CUSTOMER_DATA_SUBTITLE1" />

        <CodePanel id="basic-copy-8" codeStr={scriptPassCustomerData} makeBeauty>
          <span className="eccheckout__basic__code-main">
            <div>
              {scriptPassCustomerData_1}
            </div>
            <div>
              {scriptPassCustomerData_1_1}
            </div>
            <div>
              {scriptPassCustomerData_1_2} "<span className="eccheckout__basic__variable">{customerID}</span>",
        </div>
            <div>
              {scriptPassCustomerData_2} "<span className="eccheckout__basic__variable">{freeText}</span>",
        </div>
            <div>
              {scriptPassCustomerData_3} "<span className="eccheckout__basic__variable">{freeText}</span>",
        </div>
            <div>
              {scriptPassCustomerData_4} "<span className="eccheckout__basic__variable">{freeText}</span>",
        </div>
            <div>
              {scriptPassCustomerData_5} "<span className="eccheckout__basic__variable">{freeTextAddressState}</span>",
        </div>
            <div>
              {scriptPassCustomerData_6} "<span className="eccheckout__basic__variable">{freeTextAddressZip}</span>",
        </div>
            <div>
              {scriptPassCustomerData_7} "<span className="eccheckout__basic__variable">{iso3Code}</span>",
        </div>
        <div>
              {scriptPassCustomerData_7_1} "<span className="eccheckout__basic__variable">{freeText}</span>",
        </div>
            <div>
              {scriptPassCustomerData_8} "<span className="eccheckout__basic__variable">{additionalEmails}</span>",
        </div>
            <div>
              <div>{customField_1}</div>
              <div>{customField_2}</div>
              <div>{customField_3} <span className="eccheckout__basic__variable">{customField_4}</span>,</div>
              <div>{customField_5} "<span className="eccheckout__basic__variable">{customField_6}</span>"</div>
              <div>{customField_7}</div>
              <div>{customField_8}</div>
            </div>
            <div>
              {scriptPassCustomerData_9}
            </div>
            <div>
              {scriptPassCustomerData_10}
            </div>
          </span>
        </CodePanel>

        <Text content="EMBED_CODES_PASSING_CUSTOMER_DATA_CONTENT2" />
        <Text className="eccheckout__basic__subtitle" content="EMBED_CODES_PASSING_CUSTOMER_DATA_SUBTITLE2" />
        <CodePanel id="basic-copy-8" codeStr={scriptNewCustomerData} makeBeauty>
          <span className="eccheckout__basic__code-main">
            <div>
              {scriptPassCustomerData_1}
            </div>
            <div>
              {scriptPassCustomerData_1_1}
            </div>
            <div>
              {scriptNewCustomerData_1} "<span className="eccheckout__basic__variable">{freeText}</span>",
        </div>
            <div>
              {scriptNewCustomerData_2} "<span className="eccheckout__basic__variable">{freeText}</span>",
        </div>
            <div>
              {scriptNewCustomerData_3} "<span className="eccheckout__basic__variable">{email}</span>",
        </div>
            <div>
              {scriptNewCustomerData_4} "<span className="eccheckout__basic__variable">{freeText}</span>",
        </div>
            <div>
              {scriptNewCustomerData_5} "<span className="eccheckout__basic__variable">{freeText}</span>",
        </div>
            <div>
              {scriptNewCustomerData_6} "<span className="eccheckout__basic__variable">{freeText}</span>",
        </div>
            <div>
              {scriptNewCustomerData_7} "<span className="eccheckout__basic__variable">{freeTextAddressState}</span>",
        </div>
            <div>
              {scriptNewCustomerData_8} "<span className="eccheckout__basic__variable">{freeTextAddressZip}</span>",
        </div>
            <div>
              {scriptNewCustomerData_9} "<span className="eccheckout__basic__variable">{iso3Code}</span>",
        </div>
            <div>
              {scriptPassCustomerData_2} "<span className="eccheckout__basic__variable">{freeText}</span>",
        </div>
            <div>
              {scriptPassCustomerData_3} "<span className="eccheckout__basic__variable">{freeText}</span>",
        </div>
            <div>
              {scriptPassCustomerData_4} "<span className="eccheckout__basic__variable">{freeText}</span>",
        </div>
            <div>
              {scriptPassCustomerData_5} "<span className="eccheckout__basic__variable">{freeTextAddressState}</span>",
        </div>
            <div>
              {scriptPassCustomerData_6} "<span className="eccheckout__basic__variable">{freeTextAddressZip}</span>",
        </div>
            <div>
              {scriptPassCustomerData_7} "<span className="eccheckout__basic__variable">{iso3Code}</span>",
        </div>
        <div>
              {scriptPassCustomerData_7_1} "<span className="eccheckout__basic__variable">{freeText}</span>",
        </div>
            <div>
              {scriptPassCustomerData_8} "<span className="eccheckout__basic__variable">{additionalEmails}</span>",
        </div>
            <div>
              {scriptNewCustomerData_10} "<span className="eccheckout__basic__variable">{phoneNumberDialCode}</span>",
        </div>
            <div>
              {scriptNewCustomerData_11_b} "<span className="eccheckout__basic__variable">{phoneNumberDialCountry}</span>",
        </div>
            <div>
              {scriptNewCustomerData_11} "<span className="eccheckout__basic__variable">{phoneNumber}</span>",
        </div>
            <div>
              {scriptNewCustomerData_12} <span className="eccheckout__basic__variable">{marketingConsent}</span>,
        </div>
            <div>
              <div>{customField_1}</div>
              <div>{customField_2}</div>
              <div>{customField_3} <span className="eccheckout__basic__variable">{customField_4}</span>,</div>
              <div>{customField_5} "<span className="eccheckout__basic__variable">{customField_6}</span>"</div>
              <div>{customField_7}</div>
              <div>{customField_8}</div>
            </div>
            <div>
              {scriptPassCustomerData_9}
            </div>
            <div>
              {scriptPassCustomerData_10}
            </div>
          </span>
        </CodePanel>
        <Text content="EMBED_CODES_PASSING_CUSTOMER_DATA_CONTENT3" noMargin={!customFields.length}/>
        <EmbedCodesCustomFields />
      </CollapsablePanelWithArrow>
    )
  }

  const renderRefreshingBillsbyCheckout = () => {
    return (
      <CollapsablePanelWithArrow unmount isCollapsed={activePanel !== 5} onClick={() => onPanelClick(5)} className="panel--5" title="EMBED_REFRESHING_BILLSBY_TITLE">
        <Text content="EMBED_REFRESHING_BILLSBY_TEXT_1" />

        <Text className="eccheckout__basic__subtitle" content="EMBED_REFRESHING_BILLSBY_SUBTITLE1" />
        <CodePanel noMargin id="basic-copy-6" codeStr={refreshBillsbyCommand}>
          <span className="eccheckout__basic__code-main">
            {refreshBillsbyCommand}
          </span>
        </CodePanel>
      </CollapsablePanelWithArrow>
    )
  }

  const renderOverrideDefaultLanguagePanel = () => {
    const overrideCheckoutLanguageCode1 = '<a href="javascript:void(0)" data-billsby-type="checkout" data-billsby-language="';
    const overrideCheckoutLanguageCode2 = '">Subscribe</a>';

    return (
      <CollapsablePanelWithArrow isCollapsed={activePanel !== 2} onClick={() => onPanelClick(2)} className="ecself-service__basic panel--2" title="SETTINGS_CHECKOUT_OVERRIDE_DEFAULT_LANG_TITLE">
        <Text content="SETTINGS_CHECKOUT_OVERRIDE_DEFAULT_LANG_CONTENT" translateWith={{ link: <Link to={"/configuration/languages"}>{counterpart("SETTINGS_CHECKOUT_OVERRIDE_DEFAULT_LANG_CONTENT_LINK")}</Link> }} />
        
        <FormGroup noMargin={!selectedLanguage}>
          <Text className="ecself-service__basic__subtitle" content="SETTINGS_CHECKOUT_OVERRIDE_DEFAULT_LANG_LABEL" />
          <Dropdown value={selectedLanguage} onChange={(value: any) => setSelectedLanguage(value)} id="languages-checkout-dropdown" placeholder={counterpart("SETTINGS_CHECKOUT_OVERRIDE_DEFAULT_LANG_PLACEHOLDER")} options={companyLanguages}  />
        </FormGroup>

        {selectedLanguage && <CodePanel noMargin id="basic-copy-3" codeStr={overrideCheckoutLanguageCode1 + selectedLanguage.value + overrideCheckoutLanguageCode2}>
          <span className="eccheckout__basic__code-main">
            {overrideCheckoutLanguageCode1}
            <span className="eccheckout__basic__variable">{selectedLanguage.value}</span>
            {overrideCheckoutLanguageCode2}
          </span>
        </CodePanel>}
      </CollapsablePanelWithArrow>
    );
  }


  return <div className="eccheckout">
    <Helmet title={`${counterpart("NAVBAR_EMBED_CODES")}-${counterpart("SETTINGS_EMBED_CODES_CHECKOUT")}`} />
    <Panel className="eccheckout__welcome" title="SETTINGS_EMBED_CODES_CHECKOUT_WELCOME_TITLE">
      <Row>
        <Col md={10}>
          <Text content="SETTINGS_EMBED_CODES_CHECKOUT_WELCOME_TEXT" component="span" />
          <Link to="/configuration/brand-customization">
            <Text content="SETTINGS_EMBED_CODES_CHECKOUT_WELCOME_LINK" noMargin component="span" />
          </Link>
        </Col>
      </Row>
    </Panel>
    {renderBasicEmbedCode()}
    {renderPassingIDsInSubscribeButton()}
    {/* {renderOverrideDefaultLanguagePanel()} */}
    {renderOverridingRedirectSettingsInSubscribeButton()}
    {renderPassingCustomerDataInArray()}
    {renderRefreshingBillsbyCheckout()}
  </div>
}

export default EmbedCodesCheckout;