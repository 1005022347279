/* eslint-disable */
// source: allowances.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = Function('return this')();

var google_protobuf_wrappers_pb = require('google-protobuf/google/protobuf/wrappers_pb.js');
goog.object.extend(proto, google_protobuf_wrappers_pb);
goog.exportSymbol('proto.core.private.allowances.Allowance', null, global);
goog.exportSymbol('proto.core.private.allowances.AllowanceFilterType', null, global);
goog.exportSymbol('proto.core.private.allowances.AllowancePlan', null, global);
goog.exportSymbol('proto.core.private.allowances.AllowancePriceModel', null, global);
goog.exportSymbol('proto.core.private.allowances.Counter', null, global);
goog.exportSymbol('proto.core.private.allowances.CreateAllowanceRequest', null, global);
goog.exportSymbol('proto.core.private.allowances.CreateAllowanceResponse', null, global);
goog.exportSymbol('proto.core.private.allowances.Currency', null, global);
goog.exportSymbol('proto.core.private.allowances.DeleteAllowanceRequest', null, global);
goog.exportSymbol('proto.core.private.allowances.DeleteAllowanceResponse', null, global);
goog.exportSymbol('proto.core.private.allowances.FrequencyType', null, global);
goog.exportSymbol('proto.core.private.allowances.GetAllowanceRequest', null, global);
goog.exportSymbol('proto.core.private.allowances.GetAllowanceResponse', null, global);
goog.exportSymbol('proto.core.private.allowances.GetAllowancesRequest', null, global);
goog.exportSymbol('proto.core.private.allowances.GetAllowancesResponse', null, global);
goog.exportSymbol('proto.core.private.allowances.GetCurrencyPriceModelsRequest', null, global);
goog.exportSymbol('proto.core.private.allowances.GetCurrencyPriceModelsResponse', null, global);
goog.exportSymbol('proto.core.private.allowances.Plan', null, global);
goog.exportSymbol('proto.core.private.allowances.PricingModelType', null, global);
goog.exportSymbol('proto.core.private.allowances.UnitTier', null, global);
goog.exportSymbol('proto.core.private.allowances.UpdateAllowanceRequest', null, global);
goog.exportSymbol('proto.core.private.allowances.UpdateAllowanceResponse', null, global);
goog.exportSymbol('proto.core.private.allowances.UpdateUsageRequest', null, global);
goog.exportSymbol('proto.core.private.allowances.UpdateUsageResponse', null, global);
goog.exportSymbol('proto.core.private.allowances.UpsertAllowancePlan', null, global);
goog.exportSymbol('proto.core.private.allowances.UpsertAllowancePriceModel', null, global);
goog.exportSymbol('proto.core.private.allowances.UpsertUnitTier', null, global);
goog.exportSymbol('proto.core.private.allowances.VisibilityType', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.core.private.allowances.GetCurrencyPriceModelsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.core.private.allowances.GetCurrencyPriceModelsRequest.repeatedFields_, null);
};
goog.inherits(proto.core.private.allowances.GetCurrencyPriceModelsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.core.private.allowances.GetCurrencyPriceModelsRequest.displayName = 'proto.core.private.allowances.GetCurrencyPriceModelsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.core.private.allowances.GetCurrencyPriceModelsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.core.private.allowances.GetCurrencyPriceModelsResponse.repeatedFields_, null);
};
goog.inherits(proto.core.private.allowances.GetCurrencyPriceModelsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.core.private.allowances.GetCurrencyPriceModelsResponse.displayName = 'proto.core.private.allowances.GetCurrencyPriceModelsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.core.private.allowances.GetAllowancesRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.core.private.allowances.GetAllowancesRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.core.private.allowances.GetAllowancesRequest.displayName = 'proto.core.private.allowances.GetAllowancesRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.core.private.allowances.GetAllowancesResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.core.private.allowances.GetAllowancesResponse.repeatedFields_, null);
};
goog.inherits(proto.core.private.allowances.GetAllowancesResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.core.private.allowances.GetAllowancesResponse.displayName = 'proto.core.private.allowances.GetAllowancesResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.core.private.allowances.CreateAllowanceRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.core.private.allowances.CreateAllowanceRequest.repeatedFields_, null);
};
goog.inherits(proto.core.private.allowances.CreateAllowanceRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.core.private.allowances.CreateAllowanceRequest.displayName = 'proto.core.private.allowances.CreateAllowanceRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.core.private.allowances.CreateAllowanceResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.core.private.allowances.CreateAllowanceResponse.repeatedFields_, null);
};
goog.inherits(proto.core.private.allowances.CreateAllowanceResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.core.private.allowances.CreateAllowanceResponse.displayName = 'proto.core.private.allowances.CreateAllowanceResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.core.private.allowances.GetAllowanceRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.core.private.allowances.GetAllowanceRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.core.private.allowances.GetAllowanceRequest.displayName = 'proto.core.private.allowances.GetAllowanceRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.core.private.allowances.GetAllowanceResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.core.private.allowances.GetAllowanceResponse.repeatedFields_, null);
};
goog.inherits(proto.core.private.allowances.GetAllowanceResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.core.private.allowances.GetAllowanceResponse.displayName = 'proto.core.private.allowances.GetAllowanceResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.core.private.allowances.UpdateAllowanceRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.core.private.allowances.UpdateAllowanceRequest.repeatedFields_, null);
};
goog.inherits(proto.core.private.allowances.UpdateAllowanceRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.core.private.allowances.UpdateAllowanceRequest.displayName = 'proto.core.private.allowances.UpdateAllowanceRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.core.private.allowances.UpdateAllowanceResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.core.private.allowances.UpdateAllowanceResponse.repeatedFields_, null);
};
goog.inherits(proto.core.private.allowances.UpdateAllowanceResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.core.private.allowances.UpdateAllowanceResponse.displayName = 'proto.core.private.allowances.UpdateAllowanceResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.core.private.allowances.UpsertAllowancePriceModel = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.core.private.allowances.UpsertAllowancePriceModel.repeatedFields_, null);
};
goog.inherits(proto.core.private.allowances.UpsertAllowancePriceModel, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.core.private.allowances.UpsertAllowancePriceModel.displayName = 'proto.core.private.allowances.UpsertAllowancePriceModel';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.core.private.allowances.UpdateUsageRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.core.private.allowances.UpdateUsageRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.core.private.allowances.UpdateUsageRequest.displayName = 'proto.core.private.allowances.UpdateUsageRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.core.private.allowances.UpdateUsageResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.core.private.allowances.UpdateUsageResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.core.private.allowances.UpdateUsageResponse.displayName = 'proto.core.private.allowances.UpdateUsageResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.core.private.allowances.DeleteAllowanceRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.core.private.allowances.DeleteAllowanceRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.core.private.allowances.DeleteAllowanceRequest.displayName = 'proto.core.private.allowances.DeleteAllowanceRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.core.private.allowances.DeleteAllowanceResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.core.private.allowances.DeleteAllowanceResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.core.private.allowances.DeleteAllowanceResponse.displayName = 'proto.core.private.allowances.DeleteAllowanceResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.core.private.allowances.Allowance = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.core.private.allowances.Allowance.repeatedFields_, null);
};
goog.inherits(proto.core.private.allowances.Allowance, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.core.private.allowances.Allowance.displayName = 'proto.core.private.allowances.Allowance';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.core.private.allowances.AllowancePriceModel = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.core.private.allowances.AllowancePriceModel.repeatedFields_, null);
};
goog.inherits(proto.core.private.allowances.AllowancePriceModel, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.core.private.allowances.AllowancePriceModel.displayName = 'proto.core.private.allowances.AllowancePriceModel';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.core.private.allowances.Currency = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.core.private.allowances.Currency, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.core.private.allowances.Currency.displayName = 'proto.core.private.allowances.Currency';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.core.private.allowances.Plan = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.core.private.allowances.Plan, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.core.private.allowances.Plan.displayName = 'proto.core.private.allowances.Plan';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.core.private.allowances.AllowancePlan = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.core.private.allowances.AllowancePlan, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.core.private.allowances.AllowancePlan.displayName = 'proto.core.private.allowances.AllowancePlan';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.core.private.allowances.UpsertAllowancePlan = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.core.private.allowances.UpsertAllowancePlan, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.core.private.allowances.UpsertAllowancePlan.displayName = 'proto.core.private.allowances.UpsertAllowancePlan';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.core.private.allowances.UnitTier = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.core.private.allowances.UnitTier, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.core.private.allowances.UnitTier.displayName = 'proto.core.private.allowances.UnitTier';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.core.private.allowances.UpsertUnitTier = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.core.private.allowances.UpsertUnitTier, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.core.private.allowances.UpsertUnitTier.displayName = 'proto.core.private.allowances.UpsertUnitTier';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.core.private.allowances.Counter = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.core.private.allowances.Counter, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.core.private.allowances.Counter.displayName = 'proto.core.private.allowances.Counter';
}

/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.core.private.allowances.GetCurrencyPriceModelsRequest.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.core.private.allowances.GetCurrencyPriceModelsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.core.private.allowances.GetCurrencyPriceModelsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.core.private.allowances.GetCurrencyPriceModelsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.core.private.allowances.GetCurrencyPriceModelsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    companyDomain: jspb.Message.getFieldWithDefault(msg, 1, ""),
    currencyIso3Code: jspb.Message.getFieldWithDefault(msg, 2, ""),
    planIdsList: (f = jspb.Message.getRepeatedField(msg, 3)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.core.private.allowances.GetCurrencyPriceModelsRequest}
 */
proto.core.private.allowances.GetCurrencyPriceModelsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.core.private.allowances.GetCurrencyPriceModelsRequest;
  return proto.core.private.allowances.GetCurrencyPriceModelsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.core.private.allowances.GetCurrencyPriceModelsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.core.private.allowances.GetCurrencyPriceModelsRequest}
 */
proto.core.private.allowances.GetCurrencyPriceModelsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setCompanyDomain(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setCurrencyIso3Code(value);
      break;
    case 3:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedInt32() : [reader.readInt32()]);
      for (var i = 0; i < values.length; i++) {
        msg.addPlanIds(values[i]);
      }
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.core.private.allowances.GetCurrencyPriceModelsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.core.private.allowances.GetCurrencyPriceModelsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.core.private.allowances.GetCurrencyPriceModelsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.core.private.allowances.GetCurrencyPriceModelsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCompanyDomain();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getCurrencyIso3Code();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getPlanIdsList();
  if (f.length > 0) {
    writer.writePackedInt32(
      3,
      f
    );
  }
};


/**
 * optional string company_domain = 1;
 * @return {string}
 */
proto.core.private.allowances.GetCurrencyPriceModelsRequest.prototype.getCompanyDomain = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.core.private.allowances.GetCurrencyPriceModelsRequest} returns this
 */
proto.core.private.allowances.GetCurrencyPriceModelsRequest.prototype.setCompanyDomain = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string currency_iso_3_code = 2;
 * @return {string}
 */
proto.core.private.allowances.GetCurrencyPriceModelsRequest.prototype.getCurrencyIso3Code = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.core.private.allowances.GetCurrencyPriceModelsRequest} returns this
 */
proto.core.private.allowances.GetCurrencyPriceModelsRequest.prototype.setCurrencyIso3Code = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * repeated int32 plan_ids = 3;
 * @return {!Array<number>}
 */
proto.core.private.allowances.GetCurrencyPriceModelsRequest.prototype.getPlanIdsList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 3));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.core.private.allowances.GetCurrencyPriceModelsRequest} returns this
 */
proto.core.private.allowances.GetCurrencyPriceModelsRequest.prototype.setPlanIdsList = function(value) {
  return jspb.Message.setField(this, 3, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.core.private.allowances.GetCurrencyPriceModelsRequest} returns this
 */
proto.core.private.allowances.GetCurrencyPriceModelsRequest.prototype.addPlanIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 3, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.core.private.allowances.GetCurrencyPriceModelsRequest} returns this
 */
proto.core.private.allowances.GetCurrencyPriceModelsRequest.prototype.clearPlanIdsList = function() {
  return this.setPlanIdsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.core.private.allowances.GetCurrencyPriceModelsResponse.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.core.private.allowances.GetCurrencyPriceModelsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.core.private.allowances.GetCurrencyPriceModelsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.core.private.allowances.GetCurrencyPriceModelsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.core.private.allowances.GetCurrencyPriceModelsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    currencyIso3Code: jspb.Message.getFieldWithDefault(msg, 1, ""),
    resultsList: jspb.Message.toObjectList(msg.getResultsList(),
    proto.core.private.allowances.AllowancePriceModel.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.core.private.allowances.GetCurrencyPriceModelsResponse}
 */
proto.core.private.allowances.GetCurrencyPriceModelsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.core.private.allowances.GetCurrencyPriceModelsResponse;
  return proto.core.private.allowances.GetCurrencyPriceModelsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.core.private.allowances.GetCurrencyPriceModelsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.core.private.allowances.GetCurrencyPriceModelsResponse}
 */
proto.core.private.allowances.GetCurrencyPriceModelsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setCurrencyIso3Code(value);
      break;
    case 2:
      var value = new proto.core.private.allowances.AllowancePriceModel;
      reader.readMessage(value,proto.core.private.allowances.AllowancePriceModel.deserializeBinaryFromReader);
      msg.addResults(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.core.private.allowances.GetCurrencyPriceModelsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.core.private.allowances.GetCurrencyPriceModelsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.core.private.allowances.GetCurrencyPriceModelsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.core.private.allowances.GetCurrencyPriceModelsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCurrencyIso3Code();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getResultsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.core.private.allowances.AllowancePriceModel.serializeBinaryToWriter
    );
  }
};


/**
 * optional string currency_iso_3_code = 1;
 * @return {string}
 */
proto.core.private.allowances.GetCurrencyPriceModelsResponse.prototype.getCurrencyIso3Code = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.core.private.allowances.GetCurrencyPriceModelsResponse} returns this
 */
proto.core.private.allowances.GetCurrencyPriceModelsResponse.prototype.setCurrencyIso3Code = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * repeated AllowancePriceModel results = 2;
 * @return {!Array<!proto.core.private.allowances.AllowancePriceModel>}
 */
proto.core.private.allowances.GetCurrencyPriceModelsResponse.prototype.getResultsList = function() {
  return /** @type{!Array<!proto.core.private.allowances.AllowancePriceModel>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.core.private.allowances.AllowancePriceModel, 2));
};


/**
 * @param {!Array<!proto.core.private.allowances.AllowancePriceModel>} value
 * @return {!proto.core.private.allowances.GetCurrencyPriceModelsResponse} returns this
*/
proto.core.private.allowances.GetCurrencyPriceModelsResponse.prototype.setResultsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.core.private.allowances.AllowancePriceModel=} opt_value
 * @param {number=} opt_index
 * @return {!proto.core.private.allowances.AllowancePriceModel}
 */
proto.core.private.allowances.GetCurrencyPriceModelsResponse.prototype.addResults = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.core.private.allowances.AllowancePriceModel, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.core.private.allowances.GetCurrencyPriceModelsResponse} returns this
 */
proto.core.private.allowances.GetCurrencyPriceModelsResponse.prototype.clearResultsList = function() {
  return this.setResultsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.core.private.allowances.GetAllowancesRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.core.private.allowances.GetAllowancesRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.core.private.allowances.GetAllowancesRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.core.private.allowances.GetAllowancesRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    companyDomain: jspb.Message.getFieldWithDefault(msg, 1, ""),
    page: (f = msg.getPage()) && google_protobuf_wrappers_pb.Int32Value.toObject(includeInstance, f),
    pageSize: (f = msg.getPageSize()) && google_protobuf_wrappers_pb.Int32Value.toObject(includeInstance, f),
    search: jspb.Message.getFieldWithDefault(msg, 4, ""),
    allowanceFilterType: jspb.Message.getFieldWithDefault(msg, 5, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.core.private.allowances.GetAllowancesRequest}
 */
proto.core.private.allowances.GetAllowancesRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.core.private.allowances.GetAllowancesRequest;
  return proto.core.private.allowances.GetAllowancesRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.core.private.allowances.GetAllowancesRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.core.private.allowances.GetAllowancesRequest}
 */
proto.core.private.allowances.GetAllowancesRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setCompanyDomain(value);
      break;
    case 2:
      var value = new google_protobuf_wrappers_pb.Int32Value;
      reader.readMessage(value,google_protobuf_wrappers_pb.Int32Value.deserializeBinaryFromReader);
      msg.setPage(value);
      break;
    case 3:
      var value = new google_protobuf_wrappers_pb.Int32Value;
      reader.readMessage(value,google_protobuf_wrappers_pb.Int32Value.deserializeBinaryFromReader);
      msg.setPageSize(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setSearch(value);
      break;
    case 5:
      var value = /** @type {!proto.core.private.allowances.AllowanceFilterType} */ (reader.readEnum());
      msg.setAllowanceFilterType(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.core.private.allowances.GetAllowancesRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.core.private.allowances.GetAllowancesRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.core.private.allowances.GetAllowancesRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.core.private.allowances.GetAllowancesRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCompanyDomain();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getPage();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      google_protobuf_wrappers_pb.Int32Value.serializeBinaryToWriter
    );
  }
  f = message.getPageSize();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      google_protobuf_wrappers_pb.Int32Value.serializeBinaryToWriter
    );
  }
  f = message.getSearch();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getAllowanceFilterType();
  if (f !== 0.0) {
    writer.writeEnum(
      5,
      f
    );
  }
};


/**
 * optional string company_domain = 1;
 * @return {string}
 */
proto.core.private.allowances.GetAllowancesRequest.prototype.getCompanyDomain = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.core.private.allowances.GetAllowancesRequest} returns this
 */
proto.core.private.allowances.GetAllowancesRequest.prototype.setCompanyDomain = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional google.protobuf.Int32Value page = 2;
 * @return {?proto.google.protobuf.Int32Value}
 */
proto.core.private.allowances.GetAllowancesRequest.prototype.getPage = function() {
  return /** @type{?proto.google.protobuf.Int32Value} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.Int32Value, 2));
};


/**
 * @param {?proto.google.protobuf.Int32Value|undefined} value
 * @return {!proto.core.private.allowances.GetAllowancesRequest} returns this
*/
proto.core.private.allowances.GetAllowancesRequest.prototype.setPage = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.core.private.allowances.GetAllowancesRequest} returns this
 */
proto.core.private.allowances.GetAllowancesRequest.prototype.clearPage = function() {
  return this.setPage(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.core.private.allowances.GetAllowancesRequest.prototype.hasPage = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional google.protobuf.Int32Value page_size = 3;
 * @return {?proto.google.protobuf.Int32Value}
 */
proto.core.private.allowances.GetAllowancesRequest.prototype.getPageSize = function() {
  return /** @type{?proto.google.protobuf.Int32Value} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.Int32Value, 3));
};


/**
 * @param {?proto.google.protobuf.Int32Value|undefined} value
 * @return {!proto.core.private.allowances.GetAllowancesRequest} returns this
*/
proto.core.private.allowances.GetAllowancesRequest.prototype.setPageSize = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.core.private.allowances.GetAllowancesRequest} returns this
 */
proto.core.private.allowances.GetAllowancesRequest.prototype.clearPageSize = function() {
  return this.setPageSize(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.core.private.allowances.GetAllowancesRequest.prototype.hasPageSize = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional string search = 4;
 * @return {string}
 */
proto.core.private.allowances.GetAllowancesRequest.prototype.getSearch = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.core.private.allowances.GetAllowancesRequest} returns this
 */
proto.core.private.allowances.GetAllowancesRequest.prototype.setSearch = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional AllowanceFilterType allowance_filter_type = 5;
 * @return {!proto.core.private.allowances.AllowanceFilterType}
 */
proto.core.private.allowances.GetAllowancesRequest.prototype.getAllowanceFilterType = function() {
  return /** @type {!proto.core.private.allowances.AllowanceFilterType} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {!proto.core.private.allowances.AllowanceFilterType} value
 * @return {!proto.core.private.allowances.GetAllowancesRequest} returns this
 */
proto.core.private.allowances.GetAllowancesRequest.prototype.setAllowanceFilterType = function(value) {
  return jspb.Message.setProto3EnumField(this, 5, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.core.private.allowances.GetAllowancesResponse.repeatedFields_ = [5];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.core.private.allowances.GetAllowancesResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.core.private.allowances.GetAllowancesResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.core.private.allowances.GetAllowancesResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.core.private.allowances.GetAllowancesResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    currentPage: jspb.Message.getFieldWithDefault(msg, 1, 0),
    pageCount: jspb.Message.getFieldWithDefault(msg, 2, 0),
    pageSize: jspb.Message.getFieldWithDefault(msg, 3, 0),
    rowCount: jspb.Message.getFieldWithDefault(msg, 4, 0),
    allowancesList: jspb.Message.toObjectList(msg.getAllowancesList(),
    proto.core.private.allowances.Allowance.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.core.private.allowances.GetAllowancesResponse}
 */
proto.core.private.allowances.GetAllowancesResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.core.private.allowances.GetAllowancesResponse;
  return proto.core.private.allowances.GetAllowancesResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.core.private.allowances.GetAllowancesResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.core.private.allowances.GetAllowancesResponse}
 */
proto.core.private.allowances.GetAllowancesResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setCurrentPage(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPageCount(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPageSize(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setRowCount(value);
      break;
    case 5:
      var value = new proto.core.private.allowances.Allowance;
      reader.readMessage(value,proto.core.private.allowances.Allowance.deserializeBinaryFromReader);
      msg.addAllowances(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.core.private.allowances.GetAllowancesResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.core.private.allowances.GetAllowancesResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.core.private.allowances.GetAllowancesResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.core.private.allowances.GetAllowancesResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCurrentPage();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getPageCount();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getPageSize();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
  f = message.getRowCount();
  if (f !== 0) {
    writer.writeInt64(
      4,
      f
    );
  }
  f = message.getAllowancesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      5,
      f,
      proto.core.private.allowances.Allowance.serializeBinaryToWriter
    );
  }
};


/**
 * optional int32 current_page = 1;
 * @return {number}
 */
proto.core.private.allowances.GetAllowancesResponse.prototype.getCurrentPage = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.core.private.allowances.GetAllowancesResponse} returns this
 */
proto.core.private.allowances.GetAllowancesResponse.prototype.setCurrentPage = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int32 page_count = 2;
 * @return {number}
 */
proto.core.private.allowances.GetAllowancesResponse.prototype.getPageCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.core.private.allowances.GetAllowancesResponse} returns this
 */
proto.core.private.allowances.GetAllowancesResponse.prototype.setPageCount = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int32 page_size = 3;
 * @return {number}
 */
proto.core.private.allowances.GetAllowancesResponse.prototype.getPageSize = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.core.private.allowances.GetAllowancesResponse} returns this
 */
proto.core.private.allowances.GetAllowancesResponse.prototype.setPageSize = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional int64 row_count = 4;
 * @return {number}
 */
proto.core.private.allowances.GetAllowancesResponse.prototype.getRowCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.core.private.allowances.GetAllowancesResponse} returns this
 */
proto.core.private.allowances.GetAllowancesResponse.prototype.setRowCount = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * repeated Allowance allowances = 5;
 * @return {!Array<!proto.core.private.allowances.Allowance>}
 */
proto.core.private.allowances.GetAllowancesResponse.prototype.getAllowancesList = function() {
  return /** @type{!Array<!proto.core.private.allowances.Allowance>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.core.private.allowances.Allowance, 5));
};


/**
 * @param {!Array<!proto.core.private.allowances.Allowance>} value
 * @return {!proto.core.private.allowances.GetAllowancesResponse} returns this
*/
proto.core.private.allowances.GetAllowancesResponse.prototype.setAllowancesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 5, value);
};


/**
 * @param {!proto.core.private.allowances.Allowance=} opt_value
 * @param {number=} opt_index
 * @return {!proto.core.private.allowances.Allowance}
 */
proto.core.private.allowances.GetAllowancesResponse.prototype.addAllowances = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 5, opt_value, proto.core.private.allowances.Allowance, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.core.private.allowances.GetAllowancesResponse} returns this
 */
proto.core.private.allowances.GetAllowancesResponse.prototype.clearAllowancesList = function() {
  return this.setAllowancesList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.core.private.allowances.CreateAllowanceRequest.repeatedFields_ = [7,9,10];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.core.private.allowances.CreateAllowanceRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.core.private.allowances.CreateAllowanceRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.core.private.allowances.CreateAllowanceRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.core.private.allowances.CreateAllowanceRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    companyDomain: jspb.Message.getFieldWithDefault(msg, 1, ""),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    displayName: jspb.Message.getFieldWithDefault(msg, 3, ""),
    description: jspb.Message.getFieldWithDefault(msg, 4, ""),
    singleUnitName: jspb.Message.getFieldWithDefault(msg, 5, ""),
    pluralUnitName: jspb.Message.getFieldWithDefault(msg, 6, ""),
    plansList: jspb.Message.toObjectList(msg.getPlansList(),
    proto.core.private.allowances.UpsertAllowancePlan.toObject, includeInstance),
    pricingModelType: jspb.Message.getFieldWithDefault(msg, 8, 0),
    priceModelsList: jspb.Message.toObjectList(msg.getPriceModelsList(),
    proto.core.private.allowances.UpsertAllowancePriceModel.toObject, includeInstance),
    featureTagsList: (f = jspb.Message.getRepeatedField(msg, 10)) == null ? undefined : f,
    counterId: jspb.Message.getFieldWithDefault(msg, 11, ""),
    isImageEnabled: jspb.Message.getBooleanFieldWithDefault(msg, 12, false),
    imageFilename: jspb.Message.getFieldWithDefault(msg, 13, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.core.private.allowances.CreateAllowanceRequest}
 */
proto.core.private.allowances.CreateAllowanceRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.core.private.allowances.CreateAllowanceRequest;
  return proto.core.private.allowances.CreateAllowanceRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.core.private.allowances.CreateAllowanceRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.core.private.allowances.CreateAllowanceRequest}
 */
proto.core.private.allowances.CreateAllowanceRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setCompanyDomain(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setDisplayName(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setSingleUnitName(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setPluralUnitName(value);
      break;
    case 7:
      var value = new proto.core.private.allowances.UpsertAllowancePlan;
      reader.readMessage(value,proto.core.private.allowances.UpsertAllowancePlan.deserializeBinaryFromReader);
      msg.addPlans(value);
      break;
    case 8:
      var value = /** @type {!proto.core.private.allowances.PricingModelType} */ (reader.readEnum());
      msg.setPricingModelType(value);
      break;
    case 9:
      var value = new proto.core.private.allowances.UpsertAllowancePriceModel;
      reader.readMessage(value,proto.core.private.allowances.UpsertAllowancePriceModel.deserializeBinaryFromReader);
      msg.addPriceModels(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.addFeatureTags(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.setCounterId(value);
      break;
    case 12:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsImageEnabled(value);
      break;
    case 13:
      var value = /** @type {string} */ (reader.readString());
      msg.setImageFilename(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.core.private.allowances.CreateAllowanceRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.core.private.allowances.CreateAllowanceRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.core.private.allowances.CreateAllowanceRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.core.private.allowances.CreateAllowanceRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCompanyDomain();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getDisplayName();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getDescription();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getSingleUnitName();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getPluralUnitName();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getPlansList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      7,
      f,
      proto.core.private.allowances.UpsertAllowancePlan.serializeBinaryToWriter
    );
  }
  f = message.getPricingModelType();
  if (f !== 0.0) {
    writer.writeEnum(
      8,
      f
    );
  }
  f = message.getPriceModelsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      9,
      f,
      proto.core.private.allowances.UpsertAllowancePriceModel.serializeBinaryToWriter
    );
  }
  f = message.getFeatureTagsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      10,
      f
    );
  }
  f = message.getCounterId();
  if (f.length > 0) {
    writer.writeString(
      11,
      f
    );
  }
  f = message.getIsImageEnabled();
  if (f) {
    writer.writeBool(
      12,
      f
    );
  }
  f = message.getImageFilename();
  if (f.length > 0) {
    writer.writeString(
      13,
      f
    );
  }
};


/**
 * optional string company_domain = 1;
 * @return {string}
 */
proto.core.private.allowances.CreateAllowanceRequest.prototype.getCompanyDomain = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.core.private.allowances.CreateAllowanceRequest} returns this
 */
proto.core.private.allowances.CreateAllowanceRequest.prototype.setCompanyDomain = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.core.private.allowances.CreateAllowanceRequest.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.core.private.allowances.CreateAllowanceRequest} returns this
 */
proto.core.private.allowances.CreateAllowanceRequest.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string display_name = 3;
 * @return {string}
 */
proto.core.private.allowances.CreateAllowanceRequest.prototype.getDisplayName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.core.private.allowances.CreateAllowanceRequest} returns this
 */
proto.core.private.allowances.CreateAllowanceRequest.prototype.setDisplayName = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string description = 4;
 * @return {string}
 */
proto.core.private.allowances.CreateAllowanceRequest.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.core.private.allowances.CreateAllowanceRequest} returns this
 */
proto.core.private.allowances.CreateAllowanceRequest.prototype.setDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string single_unit_name = 5;
 * @return {string}
 */
proto.core.private.allowances.CreateAllowanceRequest.prototype.getSingleUnitName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.core.private.allowances.CreateAllowanceRequest} returns this
 */
proto.core.private.allowances.CreateAllowanceRequest.prototype.setSingleUnitName = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string plural_unit_name = 6;
 * @return {string}
 */
proto.core.private.allowances.CreateAllowanceRequest.prototype.getPluralUnitName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.core.private.allowances.CreateAllowanceRequest} returns this
 */
proto.core.private.allowances.CreateAllowanceRequest.prototype.setPluralUnitName = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * repeated UpsertAllowancePlan plans = 7;
 * @return {!Array<!proto.core.private.allowances.UpsertAllowancePlan>}
 */
proto.core.private.allowances.CreateAllowanceRequest.prototype.getPlansList = function() {
  return /** @type{!Array<!proto.core.private.allowances.UpsertAllowancePlan>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.core.private.allowances.UpsertAllowancePlan, 7));
};


/**
 * @param {!Array<!proto.core.private.allowances.UpsertAllowancePlan>} value
 * @return {!proto.core.private.allowances.CreateAllowanceRequest} returns this
*/
proto.core.private.allowances.CreateAllowanceRequest.prototype.setPlansList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 7, value);
};


/**
 * @param {!proto.core.private.allowances.UpsertAllowancePlan=} opt_value
 * @param {number=} opt_index
 * @return {!proto.core.private.allowances.UpsertAllowancePlan}
 */
proto.core.private.allowances.CreateAllowanceRequest.prototype.addPlans = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 7, opt_value, proto.core.private.allowances.UpsertAllowancePlan, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.core.private.allowances.CreateAllowanceRequest} returns this
 */
proto.core.private.allowances.CreateAllowanceRequest.prototype.clearPlansList = function() {
  return this.setPlansList([]);
};


/**
 * optional PricingModelType pricing_model_type = 8;
 * @return {!proto.core.private.allowances.PricingModelType}
 */
proto.core.private.allowances.CreateAllowanceRequest.prototype.getPricingModelType = function() {
  return /** @type {!proto.core.private.allowances.PricingModelType} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {!proto.core.private.allowances.PricingModelType} value
 * @return {!proto.core.private.allowances.CreateAllowanceRequest} returns this
 */
proto.core.private.allowances.CreateAllowanceRequest.prototype.setPricingModelType = function(value) {
  return jspb.Message.setProto3EnumField(this, 8, value);
};


/**
 * repeated UpsertAllowancePriceModel price_models = 9;
 * @return {!Array<!proto.core.private.allowances.UpsertAllowancePriceModel>}
 */
proto.core.private.allowances.CreateAllowanceRequest.prototype.getPriceModelsList = function() {
  return /** @type{!Array<!proto.core.private.allowances.UpsertAllowancePriceModel>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.core.private.allowances.UpsertAllowancePriceModel, 9));
};


/**
 * @param {!Array<!proto.core.private.allowances.UpsertAllowancePriceModel>} value
 * @return {!proto.core.private.allowances.CreateAllowanceRequest} returns this
*/
proto.core.private.allowances.CreateAllowanceRequest.prototype.setPriceModelsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 9, value);
};


/**
 * @param {!proto.core.private.allowances.UpsertAllowancePriceModel=} opt_value
 * @param {number=} opt_index
 * @return {!proto.core.private.allowances.UpsertAllowancePriceModel}
 */
proto.core.private.allowances.CreateAllowanceRequest.prototype.addPriceModels = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 9, opt_value, proto.core.private.allowances.UpsertAllowancePriceModel, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.core.private.allowances.CreateAllowanceRequest} returns this
 */
proto.core.private.allowances.CreateAllowanceRequest.prototype.clearPriceModelsList = function() {
  return this.setPriceModelsList([]);
};


/**
 * repeated string feature_tags = 10;
 * @return {!Array<string>}
 */
proto.core.private.allowances.CreateAllowanceRequest.prototype.getFeatureTagsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 10));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.core.private.allowances.CreateAllowanceRequest} returns this
 */
proto.core.private.allowances.CreateAllowanceRequest.prototype.setFeatureTagsList = function(value) {
  return jspb.Message.setField(this, 10, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.core.private.allowances.CreateAllowanceRequest} returns this
 */
proto.core.private.allowances.CreateAllowanceRequest.prototype.addFeatureTags = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 10, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.core.private.allowances.CreateAllowanceRequest} returns this
 */
proto.core.private.allowances.CreateAllowanceRequest.prototype.clearFeatureTagsList = function() {
  return this.setFeatureTagsList([]);
};


/**
 * optional string counter_id = 11;
 * @return {string}
 */
proto.core.private.allowances.CreateAllowanceRequest.prototype.getCounterId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};


/**
 * @param {string} value
 * @return {!proto.core.private.allowances.CreateAllowanceRequest} returns this
 */
proto.core.private.allowances.CreateAllowanceRequest.prototype.setCounterId = function(value) {
  return jspb.Message.setProto3StringField(this, 11, value);
};


/**
 * optional bool is_image_enabled = 12;
 * @return {boolean}
 */
proto.core.private.allowances.CreateAllowanceRequest.prototype.getIsImageEnabled = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 12, false));
};


/**
 * @param {boolean} value
 * @return {!proto.core.private.allowances.CreateAllowanceRequest} returns this
 */
proto.core.private.allowances.CreateAllowanceRequest.prototype.setIsImageEnabled = function(value) {
  return jspb.Message.setProto3BooleanField(this, 12, value);
};


/**
 * optional string image_filename = 13;
 * @return {string}
 */
proto.core.private.allowances.CreateAllowanceRequest.prototype.getImageFilename = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 13, ""));
};


/**
 * @param {string} value
 * @return {!proto.core.private.allowances.CreateAllowanceRequest} returns this
 */
proto.core.private.allowances.CreateAllowanceRequest.prototype.setImageFilename = function(value) {
  return jspb.Message.setProto3StringField(this, 13, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.core.private.allowances.CreateAllowanceResponse.repeatedFields_ = [7,9,10];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.core.private.allowances.CreateAllowanceResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.core.private.allowances.CreateAllowanceResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.core.private.allowances.CreateAllowanceResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.core.private.allowances.CreateAllowanceResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    companyDomain: jspb.Message.getFieldWithDefault(msg, 1, ""),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    displayName: jspb.Message.getFieldWithDefault(msg, 3, ""),
    description: jspb.Message.getFieldWithDefault(msg, 4, ""),
    singleUnitName: jspb.Message.getFieldWithDefault(msg, 5, ""),
    pluralUnitName: jspb.Message.getFieldWithDefault(msg, 6, ""),
    plansList: jspb.Message.toObjectList(msg.getPlansList(),
    proto.core.private.allowances.AllowancePlan.toObject, includeInstance),
    pricingModelType: jspb.Message.getFieldWithDefault(msg, 8, 0),
    priceModelsList: jspb.Message.toObjectList(msg.getPriceModelsList(),
    proto.core.private.allowances.AllowancePriceModel.toObject, includeInstance),
    featureTagsList: (f = jspb.Message.getRepeatedField(msg, 10)) == null ? undefined : f,
    counter: (f = msg.getCounter()) && proto.core.private.allowances.Counter.toObject(includeInstance, f),
    hasActiveLinkedPlans: jspb.Message.getBooleanFieldWithDefault(msg, 12, false),
    isActive: jspb.Message.getBooleanFieldWithDefault(msg, 13, false),
    isImageEnabled: jspb.Message.getBooleanFieldWithDefault(msg, 14, false),
    imageFilename: jspb.Message.getFieldWithDefault(msg, 15, ""),
    imageUrl: jspb.Message.getFieldWithDefault(msg, 16, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.core.private.allowances.CreateAllowanceResponse}
 */
proto.core.private.allowances.CreateAllowanceResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.core.private.allowances.CreateAllowanceResponse;
  return proto.core.private.allowances.CreateAllowanceResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.core.private.allowances.CreateAllowanceResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.core.private.allowances.CreateAllowanceResponse}
 */
proto.core.private.allowances.CreateAllowanceResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setCompanyDomain(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setDisplayName(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setSingleUnitName(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setPluralUnitName(value);
      break;
    case 7:
      var value = new proto.core.private.allowances.AllowancePlan;
      reader.readMessage(value,proto.core.private.allowances.AllowancePlan.deserializeBinaryFromReader);
      msg.addPlans(value);
      break;
    case 8:
      var value = /** @type {!proto.core.private.allowances.PricingModelType} */ (reader.readEnum());
      msg.setPricingModelType(value);
      break;
    case 9:
      var value = new proto.core.private.allowances.AllowancePriceModel;
      reader.readMessage(value,proto.core.private.allowances.AllowancePriceModel.deserializeBinaryFromReader);
      msg.addPriceModels(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.addFeatureTags(value);
      break;
    case 11:
      var value = new proto.core.private.allowances.Counter;
      reader.readMessage(value,proto.core.private.allowances.Counter.deserializeBinaryFromReader);
      msg.setCounter(value);
      break;
    case 12:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setHasActiveLinkedPlans(value);
      break;
    case 13:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsActive(value);
      break;
    case 14:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsImageEnabled(value);
      break;
    case 15:
      var value = /** @type {string} */ (reader.readString());
      msg.setImageFilename(value);
      break;
    case 16:
      var value = /** @type {string} */ (reader.readString());
      msg.setImageUrl(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.core.private.allowances.CreateAllowanceResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.core.private.allowances.CreateAllowanceResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.core.private.allowances.CreateAllowanceResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.core.private.allowances.CreateAllowanceResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCompanyDomain();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getDisplayName();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getDescription();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getSingleUnitName();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getPluralUnitName();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getPlansList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      7,
      f,
      proto.core.private.allowances.AllowancePlan.serializeBinaryToWriter
    );
  }
  f = message.getPricingModelType();
  if (f !== 0.0) {
    writer.writeEnum(
      8,
      f
    );
  }
  f = message.getPriceModelsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      9,
      f,
      proto.core.private.allowances.AllowancePriceModel.serializeBinaryToWriter
    );
  }
  f = message.getFeatureTagsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      10,
      f
    );
  }
  f = message.getCounter();
  if (f != null) {
    writer.writeMessage(
      11,
      f,
      proto.core.private.allowances.Counter.serializeBinaryToWriter
    );
  }
  f = message.getHasActiveLinkedPlans();
  if (f) {
    writer.writeBool(
      12,
      f
    );
  }
  f = message.getIsActive();
  if (f) {
    writer.writeBool(
      13,
      f
    );
  }
  f = message.getIsImageEnabled();
  if (f) {
    writer.writeBool(
      14,
      f
    );
  }
  f = message.getImageFilename();
  if (f.length > 0) {
    writer.writeString(
      15,
      f
    );
  }
  f = message.getImageUrl();
  if (f.length > 0) {
    writer.writeString(
      16,
      f
    );
  }
};


/**
 * optional string company_domain = 1;
 * @return {string}
 */
proto.core.private.allowances.CreateAllowanceResponse.prototype.getCompanyDomain = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.core.private.allowances.CreateAllowanceResponse} returns this
 */
proto.core.private.allowances.CreateAllowanceResponse.prototype.setCompanyDomain = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.core.private.allowances.CreateAllowanceResponse.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.core.private.allowances.CreateAllowanceResponse} returns this
 */
proto.core.private.allowances.CreateAllowanceResponse.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string display_name = 3;
 * @return {string}
 */
proto.core.private.allowances.CreateAllowanceResponse.prototype.getDisplayName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.core.private.allowances.CreateAllowanceResponse} returns this
 */
proto.core.private.allowances.CreateAllowanceResponse.prototype.setDisplayName = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string description = 4;
 * @return {string}
 */
proto.core.private.allowances.CreateAllowanceResponse.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.core.private.allowances.CreateAllowanceResponse} returns this
 */
proto.core.private.allowances.CreateAllowanceResponse.prototype.setDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string single_unit_name = 5;
 * @return {string}
 */
proto.core.private.allowances.CreateAllowanceResponse.prototype.getSingleUnitName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.core.private.allowances.CreateAllowanceResponse} returns this
 */
proto.core.private.allowances.CreateAllowanceResponse.prototype.setSingleUnitName = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string plural_unit_name = 6;
 * @return {string}
 */
proto.core.private.allowances.CreateAllowanceResponse.prototype.getPluralUnitName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.core.private.allowances.CreateAllowanceResponse} returns this
 */
proto.core.private.allowances.CreateAllowanceResponse.prototype.setPluralUnitName = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * repeated AllowancePlan plans = 7;
 * @return {!Array<!proto.core.private.allowances.AllowancePlan>}
 */
proto.core.private.allowances.CreateAllowanceResponse.prototype.getPlansList = function() {
  return /** @type{!Array<!proto.core.private.allowances.AllowancePlan>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.core.private.allowances.AllowancePlan, 7));
};


/**
 * @param {!Array<!proto.core.private.allowances.AllowancePlan>} value
 * @return {!proto.core.private.allowances.CreateAllowanceResponse} returns this
*/
proto.core.private.allowances.CreateAllowanceResponse.prototype.setPlansList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 7, value);
};


/**
 * @param {!proto.core.private.allowances.AllowancePlan=} opt_value
 * @param {number=} opt_index
 * @return {!proto.core.private.allowances.AllowancePlan}
 */
proto.core.private.allowances.CreateAllowanceResponse.prototype.addPlans = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 7, opt_value, proto.core.private.allowances.AllowancePlan, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.core.private.allowances.CreateAllowanceResponse} returns this
 */
proto.core.private.allowances.CreateAllowanceResponse.prototype.clearPlansList = function() {
  return this.setPlansList([]);
};


/**
 * optional PricingModelType pricing_model_type = 8;
 * @return {!proto.core.private.allowances.PricingModelType}
 */
proto.core.private.allowances.CreateAllowanceResponse.prototype.getPricingModelType = function() {
  return /** @type {!proto.core.private.allowances.PricingModelType} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {!proto.core.private.allowances.PricingModelType} value
 * @return {!proto.core.private.allowances.CreateAllowanceResponse} returns this
 */
proto.core.private.allowances.CreateAllowanceResponse.prototype.setPricingModelType = function(value) {
  return jspb.Message.setProto3EnumField(this, 8, value);
};


/**
 * repeated AllowancePriceModel price_models = 9;
 * @return {!Array<!proto.core.private.allowances.AllowancePriceModel>}
 */
proto.core.private.allowances.CreateAllowanceResponse.prototype.getPriceModelsList = function() {
  return /** @type{!Array<!proto.core.private.allowances.AllowancePriceModel>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.core.private.allowances.AllowancePriceModel, 9));
};


/**
 * @param {!Array<!proto.core.private.allowances.AllowancePriceModel>} value
 * @return {!proto.core.private.allowances.CreateAllowanceResponse} returns this
*/
proto.core.private.allowances.CreateAllowanceResponse.prototype.setPriceModelsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 9, value);
};


/**
 * @param {!proto.core.private.allowances.AllowancePriceModel=} opt_value
 * @param {number=} opt_index
 * @return {!proto.core.private.allowances.AllowancePriceModel}
 */
proto.core.private.allowances.CreateAllowanceResponse.prototype.addPriceModels = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 9, opt_value, proto.core.private.allowances.AllowancePriceModel, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.core.private.allowances.CreateAllowanceResponse} returns this
 */
proto.core.private.allowances.CreateAllowanceResponse.prototype.clearPriceModelsList = function() {
  return this.setPriceModelsList([]);
};


/**
 * repeated string feature_tags = 10;
 * @return {!Array<string>}
 */
proto.core.private.allowances.CreateAllowanceResponse.prototype.getFeatureTagsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 10));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.core.private.allowances.CreateAllowanceResponse} returns this
 */
proto.core.private.allowances.CreateAllowanceResponse.prototype.setFeatureTagsList = function(value) {
  return jspb.Message.setField(this, 10, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.core.private.allowances.CreateAllowanceResponse} returns this
 */
proto.core.private.allowances.CreateAllowanceResponse.prototype.addFeatureTags = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 10, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.core.private.allowances.CreateAllowanceResponse} returns this
 */
proto.core.private.allowances.CreateAllowanceResponse.prototype.clearFeatureTagsList = function() {
  return this.setFeatureTagsList([]);
};


/**
 * optional Counter counter = 11;
 * @return {?proto.core.private.allowances.Counter}
 */
proto.core.private.allowances.CreateAllowanceResponse.prototype.getCounter = function() {
  return /** @type{?proto.core.private.allowances.Counter} */ (
    jspb.Message.getWrapperField(this, proto.core.private.allowances.Counter, 11));
};


/**
 * @param {?proto.core.private.allowances.Counter|undefined} value
 * @return {!proto.core.private.allowances.CreateAllowanceResponse} returns this
*/
proto.core.private.allowances.CreateAllowanceResponse.prototype.setCounter = function(value) {
  return jspb.Message.setWrapperField(this, 11, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.core.private.allowances.CreateAllowanceResponse} returns this
 */
proto.core.private.allowances.CreateAllowanceResponse.prototype.clearCounter = function() {
  return this.setCounter(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.core.private.allowances.CreateAllowanceResponse.prototype.hasCounter = function() {
  return jspb.Message.getField(this, 11) != null;
};


/**
 * optional bool has_active_linked_plans = 12;
 * @return {boolean}
 */
proto.core.private.allowances.CreateAllowanceResponse.prototype.getHasActiveLinkedPlans = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 12, false));
};


/**
 * @param {boolean} value
 * @return {!proto.core.private.allowances.CreateAllowanceResponse} returns this
 */
proto.core.private.allowances.CreateAllowanceResponse.prototype.setHasActiveLinkedPlans = function(value) {
  return jspb.Message.setProto3BooleanField(this, 12, value);
};


/**
 * optional bool is_active = 13;
 * @return {boolean}
 */
proto.core.private.allowances.CreateAllowanceResponse.prototype.getIsActive = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 13, false));
};


/**
 * @param {boolean} value
 * @return {!proto.core.private.allowances.CreateAllowanceResponse} returns this
 */
proto.core.private.allowances.CreateAllowanceResponse.prototype.setIsActive = function(value) {
  return jspb.Message.setProto3BooleanField(this, 13, value);
};


/**
 * optional bool is_image_enabled = 14;
 * @return {boolean}
 */
proto.core.private.allowances.CreateAllowanceResponse.prototype.getIsImageEnabled = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 14, false));
};


/**
 * @param {boolean} value
 * @return {!proto.core.private.allowances.CreateAllowanceResponse} returns this
 */
proto.core.private.allowances.CreateAllowanceResponse.prototype.setIsImageEnabled = function(value) {
  return jspb.Message.setProto3BooleanField(this, 14, value);
};


/**
 * optional string image_filename = 15;
 * @return {string}
 */
proto.core.private.allowances.CreateAllowanceResponse.prototype.getImageFilename = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 15, ""));
};


/**
 * @param {string} value
 * @return {!proto.core.private.allowances.CreateAllowanceResponse} returns this
 */
proto.core.private.allowances.CreateAllowanceResponse.prototype.setImageFilename = function(value) {
  return jspb.Message.setProto3StringField(this, 15, value);
};


/**
 * optional string image_url = 16;
 * @return {string}
 */
proto.core.private.allowances.CreateAllowanceResponse.prototype.getImageUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 16, ""));
};


/**
 * @param {string} value
 * @return {!proto.core.private.allowances.CreateAllowanceResponse} returns this
 */
proto.core.private.allowances.CreateAllowanceResponse.prototype.setImageUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 16, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.core.private.allowances.GetAllowanceRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.core.private.allowances.GetAllowanceRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.core.private.allowances.GetAllowanceRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.core.private.allowances.GetAllowanceRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    companyDomain: jspb.Message.getFieldWithDefault(msg, 1, ""),
    allowanceId: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.core.private.allowances.GetAllowanceRequest}
 */
proto.core.private.allowances.GetAllowanceRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.core.private.allowances.GetAllowanceRequest;
  return proto.core.private.allowances.GetAllowanceRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.core.private.allowances.GetAllowanceRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.core.private.allowances.GetAllowanceRequest}
 */
proto.core.private.allowances.GetAllowanceRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setCompanyDomain(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setAllowanceId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.core.private.allowances.GetAllowanceRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.core.private.allowances.GetAllowanceRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.core.private.allowances.GetAllowanceRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.core.private.allowances.GetAllowanceRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCompanyDomain();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getAllowanceId();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
};


/**
 * optional string company_domain = 1;
 * @return {string}
 */
proto.core.private.allowances.GetAllowanceRequest.prototype.getCompanyDomain = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.core.private.allowances.GetAllowanceRequest} returns this
 */
proto.core.private.allowances.GetAllowanceRequest.prototype.setCompanyDomain = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int32 allowance_id = 2;
 * @return {number}
 */
proto.core.private.allowances.GetAllowanceRequest.prototype.getAllowanceId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.core.private.allowances.GetAllowanceRequest} returns this
 */
proto.core.private.allowances.GetAllowanceRequest.prototype.setAllowanceId = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.core.private.allowances.GetAllowanceResponse.repeatedFields_ = [7,9,10];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.core.private.allowances.GetAllowanceResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.core.private.allowances.GetAllowanceResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.core.private.allowances.GetAllowanceResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.core.private.allowances.GetAllowanceResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    allowanceId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    displayName: jspb.Message.getFieldWithDefault(msg, 3, ""),
    description: jspb.Message.getFieldWithDefault(msg, 4, ""),
    singleUnitName: jspb.Message.getFieldWithDefault(msg, 5, ""),
    pluralUnitName: jspb.Message.getFieldWithDefault(msg, 6, ""),
    plansList: jspb.Message.toObjectList(msg.getPlansList(),
    proto.core.private.allowances.AllowancePlan.toObject, includeInstance),
    pricingModelType: jspb.Message.getFieldWithDefault(msg, 8, 0),
    priceModelsList: jspb.Message.toObjectList(msg.getPriceModelsList(),
    proto.core.private.allowances.AllowancePriceModel.toObject, includeInstance),
    featureTagsList: (f = jspb.Message.getRepeatedField(msg, 10)) == null ? undefined : f,
    counter: (f = msg.getCounter()) && proto.core.private.allowances.Counter.toObject(includeInstance, f),
    hasActiveLinkedPlans: jspb.Message.getBooleanFieldWithDefault(msg, 12, false),
    isActive: jspb.Message.getBooleanFieldWithDefault(msg, 13, false),
    isImageEnabled: jspb.Message.getBooleanFieldWithDefault(msg, 14, false),
    imageFilename: jspb.Message.getFieldWithDefault(msg, 15, ""),
    imageUrl: jspb.Message.getFieldWithDefault(msg, 16, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.core.private.allowances.GetAllowanceResponse}
 */
proto.core.private.allowances.GetAllowanceResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.core.private.allowances.GetAllowanceResponse;
  return proto.core.private.allowances.GetAllowanceResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.core.private.allowances.GetAllowanceResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.core.private.allowances.GetAllowanceResponse}
 */
proto.core.private.allowances.GetAllowanceResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setAllowanceId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setDisplayName(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setSingleUnitName(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setPluralUnitName(value);
      break;
    case 7:
      var value = new proto.core.private.allowances.AllowancePlan;
      reader.readMessage(value,proto.core.private.allowances.AllowancePlan.deserializeBinaryFromReader);
      msg.addPlans(value);
      break;
    case 8:
      var value = /** @type {!proto.core.private.allowances.PricingModelType} */ (reader.readEnum());
      msg.setPricingModelType(value);
      break;
    case 9:
      var value = new proto.core.private.allowances.AllowancePriceModel;
      reader.readMessage(value,proto.core.private.allowances.AllowancePriceModel.deserializeBinaryFromReader);
      msg.addPriceModels(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.addFeatureTags(value);
      break;
    case 11:
      var value = new proto.core.private.allowances.Counter;
      reader.readMessage(value,proto.core.private.allowances.Counter.deserializeBinaryFromReader);
      msg.setCounter(value);
      break;
    case 12:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setHasActiveLinkedPlans(value);
      break;
    case 13:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsActive(value);
      break;
    case 14:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsImageEnabled(value);
      break;
    case 15:
      var value = /** @type {string} */ (reader.readString());
      msg.setImageFilename(value);
      break;
    case 16:
      var value = /** @type {string} */ (reader.readString());
      msg.setImageUrl(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.core.private.allowances.GetAllowanceResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.core.private.allowances.GetAllowanceResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.core.private.allowances.GetAllowanceResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.core.private.allowances.GetAllowanceResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAllowanceId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getDisplayName();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getDescription();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getSingleUnitName();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getPluralUnitName();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getPlansList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      7,
      f,
      proto.core.private.allowances.AllowancePlan.serializeBinaryToWriter
    );
  }
  f = message.getPricingModelType();
  if (f !== 0.0) {
    writer.writeEnum(
      8,
      f
    );
  }
  f = message.getPriceModelsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      9,
      f,
      proto.core.private.allowances.AllowancePriceModel.serializeBinaryToWriter
    );
  }
  f = message.getFeatureTagsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      10,
      f
    );
  }
  f = message.getCounter();
  if (f != null) {
    writer.writeMessage(
      11,
      f,
      proto.core.private.allowances.Counter.serializeBinaryToWriter
    );
  }
  f = message.getHasActiveLinkedPlans();
  if (f) {
    writer.writeBool(
      12,
      f
    );
  }
  f = message.getIsActive();
  if (f) {
    writer.writeBool(
      13,
      f
    );
  }
  f = message.getIsImageEnabled();
  if (f) {
    writer.writeBool(
      14,
      f
    );
  }
  f = message.getImageFilename();
  if (f.length > 0) {
    writer.writeString(
      15,
      f
    );
  }
  f = message.getImageUrl();
  if (f.length > 0) {
    writer.writeString(
      16,
      f
    );
  }
};


/**
 * optional int32 allowance_id = 1;
 * @return {number}
 */
proto.core.private.allowances.GetAllowanceResponse.prototype.getAllowanceId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.core.private.allowances.GetAllowanceResponse} returns this
 */
proto.core.private.allowances.GetAllowanceResponse.prototype.setAllowanceId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.core.private.allowances.GetAllowanceResponse.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.core.private.allowances.GetAllowanceResponse} returns this
 */
proto.core.private.allowances.GetAllowanceResponse.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string display_name = 3;
 * @return {string}
 */
proto.core.private.allowances.GetAllowanceResponse.prototype.getDisplayName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.core.private.allowances.GetAllowanceResponse} returns this
 */
proto.core.private.allowances.GetAllowanceResponse.prototype.setDisplayName = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string description = 4;
 * @return {string}
 */
proto.core.private.allowances.GetAllowanceResponse.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.core.private.allowances.GetAllowanceResponse} returns this
 */
proto.core.private.allowances.GetAllowanceResponse.prototype.setDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string single_unit_name = 5;
 * @return {string}
 */
proto.core.private.allowances.GetAllowanceResponse.prototype.getSingleUnitName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.core.private.allowances.GetAllowanceResponse} returns this
 */
proto.core.private.allowances.GetAllowanceResponse.prototype.setSingleUnitName = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string plural_unit_name = 6;
 * @return {string}
 */
proto.core.private.allowances.GetAllowanceResponse.prototype.getPluralUnitName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.core.private.allowances.GetAllowanceResponse} returns this
 */
proto.core.private.allowances.GetAllowanceResponse.prototype.setPluralUnitName = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * repeated AllowancePlan plans = 7;
 * @return {!Array<!proto.core.private.allowances.AllowancePlan>}
 */
proto.core.private.allowances.GetAllowanceResponse.prototype.getPlansList = function() {
  return /** @type{!Array<!proto.core.private.allowances.AllowancePlan>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.core.private.allowances.AllowancePlan, 7));
};


/**
 * @param {!Array<!proto.core.private.allowances.AllowancePlan>} value
 * @return {!proto.core.private.allowances.GetAllowanceResponse} returns this
*/
proto.core.private.allowances.GetAllowanceResponse.prototype.setPlansList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 7, value);
};


/**
 * @param {!proto.core.private.allowances.AllowancePlan=} opt_value
 * @param {number=} opt_index
 * @return {!proto.core.private.allowances.AllowancePlan}
 */
proto.core.private.allowances.GetAllowanceResponse.prototype.addPlans = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 7, opt_value, proto.core.private.allowances.AllowancePlan, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.core.private.allowances.GetAllowanceResponse} returns this
 */
proto.core.private.allowances.GetAllowanceResponse.prototype.clearPlansList = function() {
  return this.setPlansList([]);
};


/**
 * optional PricingModelType pricing_model_type = 8;
 * @return {!proto.core.private.allowances.PricingModelType}
 */
proto.core.private.allowances.GetAllowanceResponse.prototype.getPricingModelType = function() {
  return /** @type {!proto.core.private.allowances.PricingModelType} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {!proto.core.private.allowances.PricingModelType} value
 * @return {!proto.core.private.allowances.GetAllowanceResponse} returns this
 */
proto.core.private.allowances.GetAllowanceResponse.prototype.setPricingModelType = function(value) {
  return jspb.Message.setProto3EnumField(this, 8, value);
};


/**
 * repeated AllowancePriceModel price_models = 9;
 * @return {!Array<!proto.core.private.allowances.AllowancePriceModel>}
 */
proto.core.private.allowances.GetAllowanceResponse.prototype.getPriceModelsList = function() {
  return /** @type{!Array<!proto.core.private.allowances.AllowancePriceModel>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.core.private.allowances.AllowancePriceModel, 9));
};


/**
 * @param {!Array<!proto.core.private.allowances.AllowancePriceModel>} value
 * @return {!proto.core.private.allowances.GetAllowanceResponse} returns this
*/
proto.core.private.allowances.GetAllowanceResponse.prototype.setPriceModelsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 9, value);
};


/**
 * @param {!proto.core.private.allowances.AllowancePriceModel=} opt_value
 * @param {number=} opt_index
 * @return {!proto.core.private.allowances.AllowancePriceModel}
 */
proto.core.private.allowances.GetAllowanceResponse.prototype.addPriceModels = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 9, opt_value, proto.core.private.allowances.AllowancePriceModel, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.core.private.allowances.GetAllowanceResponse} returns this
 */
proto.core.private.allowances.GetAllowanceResponse.prototype.clearPriceModelsList = function() {
  return this.setPriceModelsList([]);
};


/**
 * repeated string feature_tags = 10;
 * @return {!Array<string>}
 */
proto.core.private.allowances.GetAllowanceResponse.prototype.getFeatureTagsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 10));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.core.private.allowances.GetAllowanceResponse} returns this
 */
proto.core.private.allowances.GetAllowanceResponse.prototype.setFeatureTagsList = function(value) {
  return jspb.Message.setField(this, 10, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.core.private.allowances.GetAllowanceResponse} returns this
 */
proto.core.private.allowances.GetAllowanceResponse.prototype.addFeatureTags = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 10, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.core.private.allowances.GetAllowanceResponse} returns this
 */
proto.core.private.allowances.GetAllowanceResponse.prototype.clearFeatureTagsList = function() {
  return this.setFeatureTagsList([]);
};


/**
 * optional Counter counter = 11;
 * @return {?proto.core.private.allowances.Counter}
 */
proto.core.private.allowances.GetAllowanceResponse.prototype.getCounter = function() {
  return /** @type{?proto.core.private.allowances.Counter} */ (
    jspb.Message.getWrapperField(this, proto.core.private.allowances.Counter, 11));
};


/**
 * @param {?proto.core.private.allowances.Counter|undefined} value
 * @return {!proto.core.private.allowances.GetAllowanceResponse} returns this
*/
proto.core.private.allowances.GetAllowanceResponse.prototype.setCounter = function(value) {
  return jspb.Message.setWrapperField(this, 11, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.core.private.allowances.GetAllowanceResponse} returns this
 */
proto.core.private.allowances.GetAllowanceResponse.prototype.clearCounter = function() {
  return this.setCounter(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.core.private.allowances.GetAllowanceResponse.prototype.hasCounter = function() {
  return jspb.Message.getField(this, 11) != null;
};


/**
 * optional bool has_active_linked_plans = 12;
 * @return {boolean}
 */
proto.core.private.allowances.GetAllowanceResponse.prototype.getHasActiveLinkedPlans = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 12, false));
};


/**
 * @param {boolean} value
 * @return {!proto.core.private.allowances.GetAllowanceResponse} returns this
 */
proto.core.private.allowances.GetAllowanceResponse.prototype.setHasActiveLinkedPlans = function(value) {
  return jspb.Message.setProto3BooleanField(this, 12, value);
};


/**
 * optional bool is_active = 13;
 * @return {boolean}
 */
proto.core.private.allowances.GetAllowanceResponse.prototype.getIsActive = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 13, false));
};


/**
 * @param {boolean} value
 * @return {!proto.core.private.allowances.GetAllowanceResponse} returns this
 */
proto.core.private.allowances.GetAllowanceResponse.prototype.setIsActive = function(value) {
  return jspb.Message.setProto3BooleanField(this, 13, value);
};


/**
 * optional bool is_image_enabled = 14;
 * @return {boolean}
 */
proto.core.private.allowances.GetAllowanceResponse.prototype.getIsImageEnabled = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 14, false));
};


/**
 * @param {boolean} value
 * @return {!proto.core.private.allowances.GetAllowanceResponse} returns this
 */
proto.core.private.allowances.GetAllowanceResponse.prototype.setIsImageEnabled = function(value) {
  return jspb.Message.setProto3BooleanField(this, 14, value);
};


/**
 * optional string image_filename = 15;
 * @return {string}
 */
proto.core.private.allowances.GetAllowanceResponse.prototype.getImageFilename = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 15, ""));
};


/**
 * @param {string} value
 * @return {!proto.core.private.allowances.GetAllowanceResponse} returns this
 */
proto.core.private.allowances.GetAllowanceResponse.prototype.setImageFilename = function(value) {
  return jspb.Message.setProto3StringField(this, 15, value);
};


/**
 * optional string image_url = 16;
 * @return {string}
 */
proto.core.private.allowances.GetAllowanceResponse.prototype.getImageUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 16, ""));
};


/**
 * @param {string} value
 * @return {!proto.core.private.allowances.GetAllowanceResponse} returns this
 */
proto.core.private.allowances.GetAllowanceResponse.prototype.setImageUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 16, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.core.private.allowances.UpdateAllowanceRequest.repeatedFields_ = [8,10,11];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.core.private.allowances.UpdateAllowanceRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.core.private.allowances.UpdateAllowanceRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.core.private.allowances.UpdateAllowanceRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.core.private.allowances.UpdateAllowanceRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    companyDomain: jspb.Message.getFieldWithDefault(msg, 1, ""),
    allowanceId: jspb.Message.getFieldWithDefault(msg, 2, 0),
    name: jspb.Message.getFieldWithDefault(msg, 3, ""),
    displayName: jspb.Message.getFieldWithDefault(msg, 4, ""),
    description: jspb.Message.getFieldWithDefault(msg, 5, ""),
    singleUnitName: jspb.Message.getFieldWithDefault(msg, 6, ""),
    pluralUnitName: jspb.Message.getFieldWithDefault(msg, 7, ""),
    plansList: jspb.Message.toObjectList(msg.getPlansList(),
    proto.core.private.allowances.UpsertAllowancePlan.toObject, includeInstance),
    pricingModelType: jspb.Message.getFieldWithDefault(msg, 9, 0),
    priceModelsList: jspb.Message.toObjectList(msg.getPriceModelsList(),
    proto.core.private.allowances.UpsertAllowancePriceModel.toObject, includeInstance),
    featureTagsList: (f = jspb.Message.getRepeatedField(msg, 11)) == null ? undefined : f,
    counterId: jspb.Message.getFieldWithDefault(msg, 12, ""),
    isImageEnabled: jspb.Message.getBooleanFieldWithDefault(msg, 13, false),
    imageFilename: jspb.Message.getFieldWithDefault(msg, 14, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.core.private.allowances.UpdateAllowanceRequest}
 */
proto.core.private.allowances.UpdateAllowanceRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.core.private.allowances.UpdateAllowanceRequest;
  return proto.core.private.allowances.UpdateAllowanceRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.core.private.allowances.UpdateAllowanceRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.core.private.allowances.UpdateAllowanceRequest}
 */
proto.core.private.allowances.UpdateAllowanceRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setCompanyDomain(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setAllowanceId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setDisplayName(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setSingleUnitName(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setPluralUnitName(value);
      break;
    case 8:
      var value = new proto.core.private.allowances.UpsertAllowancePlan;
      reader.readMessage(value,proto.core.private.allowances.UpsertAllowancePlan.deserializeBinaryFromReader);
      msg.addPlans(value);
      break;
    case 9:
      var value = /** @type {!proto.core.private.allowances.PricingModelType} */ (reader.readEnum());
      msg.setPricingModelType(value);
      break;
    case 10:
      var value = new proto.core.private.allowances.UpsertAllowancePriceModel;
      reader.readMessage(value,proto.core.private.allowances.UpsertAllowancePriceModel.deserializeBinaryFromReader);
      msg.addPriceModels(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.addFeatureTags(value);
      break;
    case 12:
      var value = /** @type {string} */ (reader.readString());
      msg.setCounterId(value);
      break;
    case 13:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsImageEnabled(value);
      break;
    case 14:
      var value = /** @type {string} */ (reader.readString());
      msg.setImageFilename(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.core.private.allowances.UpdateAllowanceRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.core.private.allowances.UpdateAllowanceRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.core.private.allowances.UpdateAllowanceRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.core.private.allowances.UpdateAllowanceRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCompanyDomain();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getAllowanceId();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getDisplayName();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getDescription();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getSingleUnitName();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getPluralUnitName();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getPlansList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      8,
      f,
      proto.core.private.allowances.UpsertAllowancePlan.serializeBinaryToWriter
    );
  }
  f = message.getPricingModelType();
  if (f !== 0.0) {
    writer.writeEnum(
      9,
      f
    );
  }
  f = message.getPriceModelsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      10,
      f,
      proto.core.private.allowances.UpsertAllowancePriceModel.serializeBinaryToWriter
    );
  }
  f = message.getFeatureTagsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      11,
      f
    );
  }
  f = message.getCounterId();
  if (f.length > 0) {
    writer.writeString(
      12,
      f
    );
  }
  f = message.getIsImageEnabled();
  if (f) {
    writer.writeBool(
      13,
      f
    );
  }
  f = message.getImageFilename();
  if (f.length > 0) {
    writer.writeString(
      14,
      f
    );
  }
};


/**
 * optional string company_domain = 1;
 * @return {string}
 */
proto.core.private.allowances.UpdateAllowanceRequest.prototype.getCompanyDomain = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.core.private.allowances.UpdateAllowanceRequest} returns this
 */
proto.core.private.allowances.UpdateAllowanceRequest.prototype.setCompanyDomain = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int32 allowance_id = 2;
 * @return {number}
 */
proto.core.private.allowances.UpdateAllowanceRequest.prototype.getAllowanceId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.core.private.allowances.UpdateAllowanceRequest} returns this
 */
proto.core.private.allowances.UpdateAllowanceRequest.prototype.setAllowanceId = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional string name = 3;
 * @return {string}
 */
proto.core.private.allowances.UpdateAllowanceRequest.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.core.private.allowances.UpdateAllowanceRequest} returns this
 */
proto.core.private.allowances.UpdateAllowanceRequest.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string display_name = 4;
 * @return {string}
 */
proto.core.private.allowances.UpdateAllowanceRequest.prototype.getDisplayName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.core.private.allowances.UpdateAllowanceRequest} returns this
 */
proto.core.private.allowances.UpdateAllowanceRequest.prototype.setDisplayName = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string description = 5;
 * @return {string}
 */
proto.core.private.allowances.UpdateAllowanceRequest.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.core.private.allowances.UpdateAllowanceRequest} returns this
 */
proto.core.private.allowances.UpdateAllowanceRequest.prototype.setDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string single_unit_name = 6;
 * @return {string}
 */
proto.core.private.allowances.UpdateAllowanceRequest.prototype.getSingleUnitName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.core.private.allowances.UpdateAllowanceRequest} returns this
 */
proto.core.private.allowances.UpdateAllowanceRequest.prototype.setSingleUnitName = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string plural_unit_name = 7;
 * @return {string}
 */
proto.core.private.allowances.UpdateAllowanceRequest.prototype.getPluralUnitName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.core.private.allowances.UpdateAllowanceRequest} returns this
 */
proto.core.private.allowances.UpdateAllowanceRequest.prototype.setPluralUnitName = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * repeated UpsertAllowancePlan plans = 8;
 * @return {!Array<!proto.core.private.allowances.UpsertAllowancePlan>}
 */
proto.core.private.allowances.UpdateAllowanceRequest.prototype.getPlansList = function() {
  return /** @type{!Array<!proto.core.private.allowances.UpsertAllowancePlan>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.core.private.allowances.UpsertAllowancePlan, 8));
};


/**
 * @param {!Array<!proto.core.private.allowances.UpsertAllowancePlan>} value
 * @return {!proto.core.private.allowances.UpdateAllowanceRequest} returns this
*/
proto.core.private.allowances.UpdateAllowanceRequest.prototype.setPlansList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 8, value);
};


/**
 * @param {!proto.core.private.allowances.UpsertAllowancePlan=} opt_value
 * @param {number=} opt_index
 * @return {!proto.core.private.allowances.UpsertAllowancePlan}
 */
proto.core.private.allowances.UpdateAllowanceRequest.prototype.addPlans = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 8, opt_value, proto.core.private.allowances.UpsertAllowancePlan, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.core.private.allowances.UpdateAllowanceRequest} returns this
 */
proto.core.private.allowances.UpdateAllowanceRequest.prototype.clearPlansList = function() {
  return this.setPlansList([]);
};


/**
 * optional PricingModelType pricing_model_type = 9;
 * @return {!proto.core.private.allowances.PricingModelType}
 */
proto.core.private.allowances.UpdateAllowanceRequest.prototype.getPricingModelType = function() {
  return /** @type {!proto.core.private.allowances.PricingModelType} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/**
 * @param {!proto.core.private.allowances.PricingModelType} value
 * @return {!proto.core.private.allowances.UpdateAllowanceRequest} returns this
 */
proto.core.private.allowances.UpdateAllowanceRequest.prototype.setPricingModelType = function(value) {
  return jspb.Message.setProto3EnumField(this, 9, value);
};


/**
 * repeated UpsertAllowancePriceModel price_models = 10;
 * @return {!Array<!proto.core.private.allowances.UpsertAllowancePriceModel>}
 */
proto.core.private.allowances.UpdateAllowanceRequest.prototype.getPriceModelsList = function() {
  return /** @type{!Array<!proto.core.private.allowances.UpsertAllowancePriceModel>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.core.private.allowances.UpsertAllowancePriceModel, 10));
};


/**
 * @param {!Array<!proto.core.private.allowances.UpsertAllowancePriceModel>} value
 * @return {!proto.core.private.allowances.UpdateAllowanceRequest} returns this
*/
proto.core.private.allowances.UpdateAllowanceRequest.prototype.setPriceModelsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 10, value);
};


/**
 * @param {!proto.core.private.allowances.UpsertAllowancePriceModel=} opt_value
 * @param {number=} opt_index
 * @return {!proto.core.private.allowances.UpsertAllowancePriceModel}
 */
proto.core.private.allowances.UpdateAllowanceRequest.prototype.addPriceModels = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 10, opt_value, proto.core.private.allowances.UpsertAllowancePriceModel, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.core.private.allowances.UpdateAllowanceRequest} returns this
 */
proto.core.private.allowances.UpdateAllowanceRequest.prototype.clearPriceModelsList = function() {
  return this.setPriceModelsList([]);
};


/**
 * repeated string feature_tags = 11;
 * @return {!Array<string>}
 */
proto.core.private.allowances.UpdateAllowanceRequest.prototype.getFeatureTagsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 11));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.core.private.allowances.UpdateAllowanceRequest} returns this
 */
proto.core.private.allowances.UpdateAllowanceRequest.prototype.setFeatureTagsList = function(value) {
  return jspb.Message.setField(this, 11, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.core.private.allowances.UpdateAllowanceRequest} returns this
 */
proto.core.private.allowances.UpdateAllowanceRequest.prototype.addFeatureTags = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 11, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.core.private.allowances.UpdateAllowanceRequest} returns this
 */
proto.core.private.allowances.UpdateAllowanceRequest.prototype.clearFeatureTagsList = function() {
  return this.setFeatureTagsList([]);
};


/**
 * optional string counter_id = 12;
 * @return {string}
 */
proto.core.private.allowances.UpdateAllowanceRequest.prototype.getCounterId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 12, ""));
};


/**
 * @param {string} value
 * @return {!proto.core.private.allowances.UpdateAllowanceRequest} returns this
 */
proto.core.private.allowances.UpdateAllowanceRequest.prototype.setCounterId = function(value) {
  return jspb.Message.setProto3StringField(this, 12, value);
};


/**
 * optional bool is_image_enabled = 13;
 * @return {boolean}
 */
proto.core.private.allowances.UpdateAllowanceRequest.prototype.getIsImageEnabled = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 13, false));
};


/**
 * @param {boolean} value
 * @return {!proto.core.private.allowances.UpdateAllowanceRequest} returns this
 */
proto.core.private.allowances.UpdateAllowanceRequest.prototype.setIsImageEnabled = function(value) {
  return jspb.Message.setProto3BooleanField(this, 13, value);
};


/**
 * optional string image_filename = 14;
 * @return {string}
 */
proto.core.private.allowances.UpdateAllowanceRequest.prototype.getImageFilename = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 14, ""));
};


/**
 * @param {string} value
 * @return {!proto.core.private.allowances.UpdateAllowanceRequest} returns this
 */
proto.core.private.allowances.UpdateAllowanceRequest.prototype.setImageFilename = function(value) {
  return jspb.Message.setProto3StringField(this, 14, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.core.private.allowances.UpdateAllowanceResponse.repeatedFields_ = [7,9,10];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.core.private.allowances.UpdateAllowanceResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.core.private.allowances.UpdateAllowanceResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.core.private.allowances.UpdateAllowanceResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.core.private.allowances.UpdateAllowanceResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    allowanceId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    displayName: jspb.Message.getFieldWithDefault(msg, 3, ""),
    description: jspb.Message.getFieldWithDefault(msg, 4, ""),
    singleUnitName: jspb.Message.getFieldWithDefault(msg, 5, ""),
    pluralUnitName: jspb.Message.getFieldWithDefault(msg, 6, ""),
    plansList: jspb.Message.toObjectList(msg.getPlansList(),
    proto.core.private.allowances.AllowancePlan.toObject, includeInstance),
    pricingModelType: jspb.Message.getFieldWithDefault(msg, 8, 0),
    priceModelsList: jspb.Message.toObjectList(msg.getPriceModelsList(),
    proto.core.private.allowances.AllowancePriceModel.toObject, includeInstance),
    featureTagsList: (f = jspb.Message.getRepeatedField(msg, 10)) == null ? undefined : f,
    counter: (f = msg.getCounter()) && proto.core.private.allowances.Counter.toObject(includeInstance, f),
    hasActiveLinkedPlans: jspb.Message.getBooleanFieldWithDefault(msg, 12, false),
    isActive: jspb.Message.getBooleanFieldWithDefault(msg, 13, false),
    isImageEnabled: jspb.Message.getBooleanFieldWithDefault(msg, 14, false),
    imageFilename: jspb.Message.getFieldWithDefault(msg, 15, ""),
    imageUrl: jspb.Message.getFieldWithDefault(msg, 16, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.core.private.allowances.UpdateAllowanceResponse}
 */
proto.core.private.allowances.UpdateAllowanceResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.core.private.allowances.UpdateAllowanceResponse;
  return proto.core.private.allowances.UpdateAllowanceResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.core.private.allowances.UpdateAllowanceResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.core.private.allowances.UpdateAllowanceResponse}
 */
proto.core.private.allowances.UpdateAllowanceResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setAllowanceId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setDisplayName(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setSingleUnitName(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setPluralUnitName(value);
      break;
    case 7:
      var value = new proto.core.private.allowances.AllowancePlan;
      reader.readMessage(value,proto.core.private.allowances.AllowancePlan.deserializeBinaryFromReader);
      msg.addPlans(value);
      break;
    case 8:
      var value = /** @type {!proto.core.private.allowances.PricingModelType} */ (reader.readEnum());
      msg.setPricingModelType(value);
      break;
    case 9:
      var value = new proto.core.private.allowances.AllowancePriceModel;
      reader.readMessage(value,proto.core.private.allowances.AllowancePriceModel.deserializeBinaryFromReader);
      msg.addPriceModels(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.addFeatureTags(value);
      break;
    case 11:
      var value = new proto.core.private.allowances.Counter;
      reader.readMessage(value,proto.core.private.allowances.Counter.deserializeBinaryFromReader);
      msg.setCounter(value);
      break;
    case 12:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setHasActiveLinkedPlans(value);
      break;
    case 13:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsActive(value);
      break;
    case 14:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsImageEnabled(value);
      break;
    case 15:
      var value = /** @type {string} */ (reader.readString());
      msg.setImageFilename(value);
      break;
    case 16:
      var value = /** @type {string} */ (reader.readString());
      msg.setImageUrl(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.core.private.allowances.UpdateAllowanceResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.core.private.allowances.UpdateAllowanceResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.core.private.allowances.UpdateAllowanceResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.core.private.allowances.UpdateAllowanceResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAllowanceId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getDisplayName();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getDescription();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getSingleUnitName();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getPluralUnitName();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getPlansList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      7,
      f,
      proto.core.private.allowances.AllowancePlan.serializeBinaryToWriter
    );
  }
  f = message.getPricingModelType();
  if (f !== 0.0) {
    writer.writeEnum(
      8,
      f
    );
  }
  f = message.getPriceModelsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      9,
      f,
      proto.core.private.allowances.AllowancePriceModel.serializeBinaryToWriter
    );
  }
  f = message.getFeatureTagsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      10,
      f
    );
  }
  f = message.getCounter();
  if (f != null) {
    writer.writeMessage(
      11,
      f,
      proto.core.private.allowances.Counter.serializeBinaryToWriter
    );
  }
  f = message.getHasActiveLinkedPlans();
  if (f) {
    writer.writeBool(
      12,
      f
    );
  }
  f = message.getIsActive();
  if (f) {
    writer.writeBool(
      13,
      f
    );
  }
  f = message.getIsImageEnabled();
  if (f) {
    writer.writeBool(
      14,
      f
    );
  }
  f = message.getImageFilename();
  if (f.length > 0) {
    writer.writeString(
      15,
      f
    );
  }
  f = message.getImageUrl();
  if (f.length > 0) {
    writer.writeString(
      16,
      f
    );
  }
};


/**
 * optional int32 allowance_id = 1;
 * @return {number}
 */
proto.core.private.allowances.UpdateAllowanceResponse.prototype.getAllowanceId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.core.private.allowances.UpdateAllowanceResponse} returns this
 */
proto.core.private.allowances.UpdateAllowanceResponse.prototype.setAllowanceId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.core.private.allowances.UpdateAllowanceResponse.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.core.private.allowances.UpdateAllowanceResponse} returns this
 */
proto.core.private.allowances.UpdateAllowanceResponse.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string display_name = 3;
 * @return {string}
 */
proto.core.private.allowances.UpdateAllowanceResponse.prototype.getDisplayName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.core.private.allowances.UpdateAllowanceResponse} returns this
 */
proto.core.private.allowances.UpdateAllowanceResponse.prototype.setDisplayName = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string description = 4;
 * @return {string}
 */
proto.core.private.allowances.UpdateAllowanceResponse.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.core.private.allowances.UpdateAllowanceResponse} returns this
 */
proto.core.private.allowances.UpdateAllowanceResponse.prototype.setDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string single_unit_name = 5;
 * @return {string}
 */
proto.core.private.allowances.UpdateAllowanceResponse.prototype.getSingleUnitName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.core.private.allowances.UpdateAllowanceResponse} returns this
 */
proto.core.private.allowances.UpdateAllowanceResponse.prototype.setSingleUnitName = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string plural_unit_name = 6;
 * @return {string}
 */
proto.core.private.allowances.UpdateAllowanceResponse.prototype.getPluralUnitName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.core.private.allowances.UpdateAllowanceResponse} returns this
 */
proto.core.private.allowances.UpdateAllowanceResponse.prototype.setPluralUnitName = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * repeated AllowancePlan plans = 7;
 * @return {!Array<!proto.core.private.allowances.AllowancePlan>}
 */
proto.core.private.allowances.UpdateAllowanceResponse.prototype.getPlansList = function() {
  return /** @type{!Array<!proto.core.private.allowances.AllowancePlan>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.core.private.allowances.AllowancePlan, 7));
};


/**
 * @param {!Array<!proto.core.private.allowances.AllowancePlan>} value
 * @return {!proto.core.private.allowances.UpdateAllowanceResponse} returns this
*/
proto.core.private.allowances.UpdateAllowanceResponse.prototype.setPlansList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 7, value);
};


/**
 * @param {!proto.core.private.allowances.AllowancePlan=} opt_value
 * @param {number=} opt_index
 * @return {!proto.core.private.allowances.AllowancePlan}
 */
proto.core.private.allowances.UpdateAllowanceResponse.prototype.addPlans = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 7, opt_value, proto.core.private.allowances.AllowancePlan, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.core.private.allowances.UpdateAllowanceResponse} returns this
 */
proto.core.private.allowances.UpdateAllowanceResponse.prototype.clearPlansList = function() {
  return this.setPlansList([]);
};


/**
 * optional PricingModelType pricing_model_type = 8;
 * @return {!proto.core.private.allowances.PricingModelType}
 */
proto.core.private.allowances.UpdateAllowanceResponse.prototype.getPricingModelType = function() {
  return /** @type {!proto.core.private.allowances.PricingModelType} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {!proto.core.private.allowances.PricingModelType} value
 * @return {!proto.core.private.allowances.UpdateAllowanceResponse} returns this
 */
proto.core.private.allowances.UpdateAllowanceResponse.prototype.setPricingModelType = function(value) {
  return jspb.Message.setProto3EnumField(this, 8, value);
};


/**
 * repeated AllowancePriceModel price_models = 9;
 * @return {!Array<!proto.core.private.allowances.AllowancePriceModel>}
 */
proto.core.private.allowances.UpdateAllowanceResponse.prototype.getPriceModelsList = function() {
  return /** @type{!Array<!proto.core.private.allowances.AllowancePriceModel>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.core.private.allowances.AllowancePriceModel, 9));
};


/**
 * @param {!Array<!proto.core.private.allowances.AllowancePriceModel>} value
 * @return {!proto.core.private.allowances.UpdateAllowanceResponse} returns this
*/
proto.core.private.allowances.UpdateAllowanceResponse.prototype.setPriceModelsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 9, value);
};


/**
 * @param {!proto.core.private.allowances.AllowancePriceModel=} opt_value
 * @param {number=} opt_index
 * @return {!proto.core.private.allowances.AllowancePriceModel}
 */
proto.core.private.allowances.UpdateAllowanceResponse.prototype.addPriceModels = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 9, opt_value, proto.core.private.allowances.AllowancePriceModel, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.core.private.allowances.UpdateAllowanceResponse} returns this
 */
proto.core.private.allowances.UpdateAllowanceResponse.prototype.clearPriceModelsList = function() {
  return this.setPriceModelsList([]);
};


/**
 * repeated string feature_tags = 10;
 * @return {!Array<string>}
 */
proto.core.private.allowances.UpdateAllowanceResponse.prototype.getFeatureTagsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 10));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.core.private.allowances.UpdateAllowanceResponse} returns this
 */
proto.core.private.allowances.UpdateAllowanceResponse.prototype.setFeatureTagsList = function(value) {
  return jspb.Message.setField(this, 10, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.core.private.allowances.UpdateAllowanceResponse} returns this
 */
proto.core.private.allowances.UpdateAllowanceResponse.prototype.addFeatureTags = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 10, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.core.private.allowances.UpdateAllowanceResponse} returns this
 */
proto.core.private.allowances.UpdateAllowanceResponse.prototype.clearFeatureTagsList = function() {
  return this.setFeatureTagsList([]);
};


/**
 * optional Counter counter = 11;
 * @return {?proto.core.private.allowances.Counter}
 */
proto.core.private.allowances.UpdateAllowanceResponse.prototype.getCounter = function() {
  return /** @type{?proto.core.private.allowances.Counter} */ (
    jspb.Message.getWrapperField(this, proto.core.private.allowances.Counter, 11));
};


/**
 * @param {?proto.core.private.allowances.Counter|undefined} value
 * @return {!proto.core.private.allowances.UpdateAllowanceResponse} returns this
*/
proto.core.private.allowances.UpdateAllowanceResponse.prototype.setCounter = function(value) {
  return jspb.Message.setWrapperField(this, 11, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.core.private.allowances.UpdateAllowanceResponse} returns this
 */
proto.core.private.allowances.UpdateAllowanceResponse.prototype.clearCounter = function() {
  return this.setCounter(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.core.private.allowances.UpdateAllowanceResponse.prototype.hasCounter = function() {
  return jspb.Message.getField(this, 11) != null;
};


/**
 * optional bool has_active_linked_plans = 12;
 * @return {boolean}
 */
proto.core.private.allowances.UpdateAllowanceResponse.prototype.getHasActiveLinkedPlans = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 12, false));
};


/**
 * @param {boolean} value
 * @return {!proto.core.private.allowances.UpdateAllowanceResponse} returns this
 */
proto.core.private.allowances.UpdateAllowanceResponse.prototype.setHasActiveLinkedPlans = function(value) {
  return jspb.Message.setProto3BooleanField(this, 12, value);
};


/**
 * optional bool is_active = 13;
 * @return {boolean}
 */
proto.core.private.allowances.UpdateAllowanceResponse.prototype.getIsActive = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 13, false));
};


/**
 * @param {boolean} value
 * @return {!proto.core.private.allowances.UpdateAllowanceResponse} returns this
 */
proto.core.private.allowances.UpdateAllowanceResponse.prototype.setIsActive = function(value) {
  return jspb.Message.setProto3BooleanField(this, 13, value);
};


/**
 * optional bool is_image_enabled = 14;
 * @return {boolean}
 */
proto.core.private.allowances.UpdateAllowanceResponse.prototype.getIsImageEnabled = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 14, false));
};


/**
 * @param {boolean} value
 * @return {!proto.core.private.allowances.UpdateAllowanceResponse} returns this
 */
proto.core.private.allowances.UpdateAllowanceResponse.prototype.setIsImageEnabled = function(value) {
  return jspb.Message.setProto3BooleanField(this, 14, value);
};


/**
 * optional string image_filename = 15;
 * @return {string}
 */
proto.core.private.allowances.UpdateAllowanceResponse.prototype.getImageFilename = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 15, ""));
};


/**
 * @param {string} value
 * @return {!proto.core.private.allowances.UpdateAllowanceResponse} returns this
 */
proto.core.private.allowances.UpdateAllowanceResponse.prototype.setImageFilename = function(value) {
  return jspb.Message.setProto3StringField(this, 15, value);
};


/**
 * optional string image_url = 16;
 * @return {string}
 */
proto.core.private.allowances.UpdateAllowanceResponse.prototype.getImageUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 16, ""));
};


/**
 * @param {string} value
 * @return {!proto.core.private.allowances.UpdateAllowanceResponse} returns this
 */
proto.core.private.allowances.UpdateAllowanceResponse.prototype.setImageUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 16, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.core.private.allowances.UpsertAllowancePriceModel.repeatedFields_ = [7];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.core.private.allowances.UpsertAllowancePriceModel.prototype.toObject = function(opt_includeInstance) {
  return proto.core.private.allowances.UpsertAllowancePriceModel.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.core.private.allowances.UpsertAllowancePriceModel} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.core.private.allowances.UpsertAllowancePriceModel.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    frequency: jspb.Message.getFieldWithDefault(msg, 2, 0),
    frequencyType: jspb.Message.getFieldWithDefault(msg, 3, 0),
    includedUnits: (f = msg.getIncludedUnits()) && google_protobuf_wrappers_pb.Int32Value.toObject(includeInstance, f),
    perUnitPrice: jspb.Message.getFieldWithDefault(msg, 5, ""),
    currencyIso3Code: jspb.Message.getFieldWithDefault(msg, 6, ""),
    tiersList: jspb.Message.toObjectList(msg.getTiersList(),
    proto.core.private.allowances.UpsertUnitTier.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.core.private.allowances.UpsertAllowancePriceModel}
 */
proto.core.private.allowances.UpsertAllowancePriceModel.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.core.private.allowances.UpsertAllowancePriceModel;
  return proto.core.private.allowances.UpsertAllowancePriceModel.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.core.private.allowances.UpsertAllowancePriceModel} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.core.private.allowances.UpsertAllowancePriceModel}
 */
proto.core.private.allowances.UpsertAllowancePriceModel.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setFrequency(value);
      break;
    case 3:
      var value = /** @type {!proto.core.private.allowances.FrequencyType} */ (reader.readEnum());
      msg.setFrequencyType(value);
      break;
    case 4:
      var value = new google_protobuf_wrappers_pb.Int32Value;
      reader.readMessage(value,google_protobuf_wrappers_pb.Int32Value.deserializeBinaryFromReader);
      msg.setIncludedUnits(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setPerUnitPrice(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setCurrencyIso3Code(value);
      break;
    case 7:
      var value = new proto.core.private.allowances.UpsertUnitTier;
      reader.readMessage(value,proto.core.private.allowances.UpsertUnitTier.deserializeBinaryFromReader);
      msg.addTiers(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.core.private.allowances.UpsertAllowancePriceModel.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.core.private.allowances.UpsertAllowancePriceModel.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.core.private.allowances.UpsertAllowancePriceModel} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.core.private.allowances.UpsertAllowancePriceModel.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getFrequency();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getFrequencyType();
  if (f !== 0.0) {
    writer.writeEnum(
      3,
      f
    );
  }
  f = message.getIncludedUnits();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      google_protobuf_wrappers_pb.Int32Value.serializeBinaryToWriter
    );
  }
  f = message.getPerUnitPrice();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getCurrencyIso3Code();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getTiersList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      7,
      f,
      proto.core.private.allowances.UpsertUnitTier.serializeBinaryToWriter
    );
  }
};


/**
 * optional int32 id = 1;
 * @return {number}
 */
proto.core.private.allowances.UpsertAllowancePriceModel.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.core.private.allowances.UpsertAllowancePriceModel} returns this
 */
proto.core.private.allowances.UpsertAllowancePriceModel.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int32 frequency = 2;
 * @return {number}
 */
proto.core.private.allowances.UpsertAllowancePriceModel.prototype.getFrequency = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.core.private.allowances.UpsertAllowancePriceModel} returns this
 */
proto.core.private.allowances.UpsertAllowancePriceModel.prototype.setFrequency = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional FrequencyType frequency_type = 3;
 * @return {!proto.core.private.allowances.FrequencyType}
 */
proto.core.private.allowances.UpsertAllowancePriceModel.prototype.getFrequencyType = function() {
  return /** @type {!proto.core.private.allowances.FrequencyType} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {!proto.core.private.allowances.FrequencyType} value
 * @return {!proto.core.private.allowances.UpsertAllowancePriceModel} returns this
 */
proto.core.private.allowances.UpsertAllowancePriceModel.prototype.setFrequencyType = function(value) {
  return jspb.Message.setProto3EnumField(this, 3, value);
};


/**
 * optional google.protobuf.Int32Value included_units = 4;
 * @return {?proto.google.protobuf.Int32Value}
 */
proto.core.private.allowances.UpsertAllowancePriceModel.prototype.getIncludedUnits = function() {
  return /** @type{?proto.google.protobuf.Int32Value} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.Int32Value, 4));
};


/**
 * @param {?proto.google.protobuf.Int32Value|undefined} value
 * @return {!proto.core.private.allowances.UpsertAllowancePriceModel} returns this
*/
proto.core.private.allowances.UpsertAllowancePriceModel.prototype.setIncludedUnits = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.core.private.allowances.UpsertAllowancePriceModel} returns this
 */
proto.core.private.allowances.UpsertAllowancePriceModel.prototype.clearIncludedUnits = function() {
  return this.setIncludedUnits(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.core.private.allowances.UpsertAllowancePriceModel.prototype.hasIncludedUnits = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional string per_unit_price = 5;
 * @return {string}
 */
proto.core.private.allowances.UpsertAllowancePriceModel.prototype.getPerUnitPrice = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.core.private.allowances.UpsertAllowancePriceModel} returns this
 */
proto.core.private.allowances.UpsertAllowancePriceModel.prototype.setPerUnitPrice = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string currency_iso_3_code = 6;
 * @return {string}
 */
proto.core.private.allowances.UpsertAllowancePriceModel.prototype.getCurrencyIso3Code = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.core.private.allowances.UpsertAllowancePriceModel} returns this
 */
proto.core.private.allowances.UpsertAllowancePriceModel.prototype.setCurrencyIso3Code = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * repeated UpsertUnitTier tiers = 7;
 * @return {!Array<!proto.core.private.allowances.UpsertUnitTier>}
 */
proto.core.private.allowances.UpsertAllowancePriceModel.prototype.getTiersList = function() {
  return /** @type{!Array<!proto.core.private.allowances.UpsertUnitTier>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.core.private.allowances.UpsertUnitTier, 7));
};


/**
 * @param {!Array<!proto.core.private.allowances.UpsertUnitTier>} value
 * @return {!proto.core.private.allowances.UpsertAllowancePriceModel} returns this
*/
proto.core.private.allowances.UpsertAllowancePriceModel.prototype.setTiersList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 7, value);
};


/**
 * @param {!proto.core.private.allowances.UpsertUnitTier=} opt_value
 * @param {number=} opt_index
 * @return {!proto.core.private.allowances.UpsertUnitTier}
 */
proto.core.private.allowances.UpsertAllowancePriceModel.prototype.addTiers = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 7, opt_value, proto.core.private.allowances.UpsertUnitTier, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.core.private.allowances.UpsertAllowancePriceModel} returns this
 */
proto.core.private.allowances.UpsertAllowancePriceModel.prototype.clearTiersList = function() {
  return this.setTiersList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.core.private.allowances.UpdateUsageRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.core.private.allowances.UpdateUsageRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.core.private.allowances.UpdateUsageRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.core.private.allowances.UpdateUsageRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    companyDomain: jspb.Message.getFieldWithDefault(msg, 1, ""),
    subscriptionUniqueId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    allowanceId: jspb.Message.getFieldWithDefault(msg, 3, 0),
    usageQuantity: jspb.Message.getFieldWithDefault(msg, 4, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.core.private.allowances.UpdateUsageRequest}
 */
proto.core.private.allowances.UpdateUsageRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.core.private.allowances.UpdateUsageRequest;
  return proto.core.private.allowances.UpdateUsageRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.core.private.allowances.UpdateUsageRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.core.private.allowances.UpdateUsageRequest}
 */
proto.core.private.allowances.UpdateUsageRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setCompanyDomain(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setSubscriptionUniqueId(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setAllowanceId(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setUsageQuantity(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.core.private.allowances.UpdateUsageRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.core.private.allowances.UpdateUsageRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.core.private.allowances.UpdateUsageRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.core.private.allowances.UpdateUsageRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCompanyDomain();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getSubscriptionUniqueId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getAllowanceId();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
  f = message.getUsageQuantity();
  if (f !== 0) {
    writer.writeInt32(
      4,
      f
    );
  }
};


/**
 * optional string company_domain = 1;
 * @return {string}
 */
proto.core.private.allowances.UpdateUsageRequest.prototype.getCompanyDomain = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.core.private.allowances.UpdateUsageRequest} returns this
 */
proto.core.private.allowances.UpdateUsageRequest.prototype.setCompanyDomain = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string subscription_unique_id = 2;
 * @return {string}
 */
proto.core.private.allowances.UpdateUsageRequest.prototype.getSubscriptionUniqueId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.core.private.allowances.UpdateUsageRequest} returns this
 */
proto.core.private.allowances.UpdateUsageRequest.prototype.setSubscriptionUniqueId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional int32 allowance_id = 3;
 * @return {number}
 */
proto.core.private.allowances.UpdateUsageRequest.prototype.getAllowanceId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.core.private.allowances.UpdateUsageRequest} returns this
 */
proto.core.private.allowances.UpdateUsageRequest.prototype.setAllowanceId = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional int32 usage_quantity = 4;
 * @return {number}
 */
proto.core.private.allowances.UpdateUsageRequest.prototype.getUsageQuantity = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.core.private.allowances.UpdateUsageRequest} returns this
 */
proto.core.private.allowances.UpdateUsageRequest.prototype.setUsageQuantity = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.core.private.allowances.UpdateUsageResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.core.private.allowances.UpdateUsageResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.core.private.allowances.UpdateUsageResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.core.private.allowances.UpdateUsageResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    isSuccess: jspb.Message.getBooleanFieldWithDefault(msg, 1, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.core.private.allowances.UpdateUsageResponse}
 */
proto.core.private.allowances.UpdateUsageResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.core.private.allowances.UpdateUsageResponse;
  return proto.core.private.allowances.UpdateUsageResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.core.private.allowances.UpdateUsageResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.core.private.allowances.UpdateUsageResponse}
 */
proto.core.private.allowances.UpdateUsageResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsSuccess(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.core.private.allowances.UpdateUsageResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.core.private.allowances.UpdateUsageResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.core.private.allowances.UpdateUsageResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.core.private.allowances.UpdateUsageResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getIsSuccess();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
};


/**
 * optional bool is_success = 1;
 * @return {boolean}
 */
proto.core.private.allowances.UpdateUsageResponse.prototype.getIsSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.core.private.allowances.UpdateUsageResponse} returns this
 */
proto.core.private.allowances.UpdateUsageResponse.prototype.setIsSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.core.private.allowances.DeleteAllowanceRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.core.private.allowances.DeleteAllowanceRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.core.private.allowances.DeleteAllowanceRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.core.private.allowances.DeleteAllowanceRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    companyDomain: jspb.Message.getFieldWithDefault(msg, 1, ""),
    allowanceId: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.core.private.allowances.DeleteAllowanceRequest}
 */
proto.core.private.allowances.DeleteAllowanceRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.core.private.allowances.DeleteAllowanceRequest;
  return proto.core.private.allowances.DeleteAllowanceRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.core.private.allowances.DeleteAllowanceRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.core.private.allowances.DeleteAllowanceRequest}
 */
proto.core.private.allowances.DeleteAllowanceRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setCompanyDomain(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setAllowanceId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.core.private.allowances.DeleteAllowanceRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.core.private.allowances.DeleteAllowanceRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.core.private.allowances.DeleteAllowanceRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.core.private.allowances.DeleteAllowanceRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCompanyDomain();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getAllowanceId();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
};


/**
 * optional string company_domain = 1;
 * @return {string}
 */
proto.core.private.allowances.DeleteAllowanceRequest.prototype.getCompanyDomain = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.core.private.allowances.DeleteAllowanceRequest} returns this
 */
proto.core.private.allowances.DeleteAllowanceRequest.prototype.setCompanyDomain = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int32 allowance_id = 2;
 * @return {number}
 */
proto.core.private.allowances.DeleteAllowanceRequest.prototype.getAllowanceId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.core.private.allowances.DeleteAllowanceRequest} returns this
 */
proto.core.private.allowances.DeleteAllowanceRequest.prototype.setAllowanceId = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.core.private.allowances.DeleteAllowanceResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.core.private.allowances.DeleteAllowanceResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.core.private.allowances.DeleteAllowanceResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.core.private.allowances.DeleteAllowanceResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    isSuccess: jspb.Message.getBooleanFieldWithDefault(msg, 1, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.core.private.allowances.DeleteAllowanceResponse}
 */
proto.core.private.allowances.DeleteAllowanceResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.core.private.allowances.DeleteAllowanceResponse;
  return proto.core.private.allowances.DeleteAllowanceResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.core.private.allowances.DeleteAllowanceResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.core.private.allowances.DeleteAllowanceResponse}
 */
proto.core.private.allowances.DeleteAllowanceResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsSuccess(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.core.private.allowances.DeleteAllowanceResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.core.private.allowances.DeleteAllowanceResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.core.private.allowances.DeleteAllowanceResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.core.private.allowances.DeleteAllowanceResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getIsSuccess();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
};


/**
 * optional bool is_success = 1;
 * @return {boolean}
 */
proto.core.private.allowances.DeleteAllowanceResponse.prototype.getIsSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.core.private.allowances.DeleteAllowanceResponse} returns this
 */
proto.core.private.allowances.DeleteAllowanceResponse.prototype.setIsSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.core.private.allowances.Allowance.repeatedFields_ = [7,8];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.core.private.allowances.Allowance.prototype.toObject = function(opt_includeInstance) {
  return proto.core.private.allowances.Allowance.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.core.private.allowances.Allowance} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.core.private.allowances.Allowance.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    displayName: jspb.Message.getFieldWithDefault(msg, 3, ""),
    singleUnitName: jspb.Message.getFieldWithDefault(msg, 4, ""),
    pluralUnitName: jspb.Message.getFieldWithDefault(msg, 5, ""),
    pricingModelType: jspb.Message.getFieldWithDefault(msg, 6, 0),
    plansList: jspb.Message.toObjectList(msg.getPlansList(),
    proto.core.private.allowances.Plan.toObject, includeInstance),
    priceModelsList: jspb.Message.toObjectList(msg.getPriceModelsList(),
    proto.core.private.allowances.AllowancePriceModel.toObject, includeInstance),
    counter: (f = msg.getCounter()) && proto.core.private.allowances.Counter.toObject(includeInstance, f),
    hasActiveLinkedPlans: jspb.Message.getBooleanFieldWithDefault(msg, 10, false),
    isActive: jspb.Message.getBooleanFieldWithDefault(msg, 11, false),
    isImageEnabled: jspb.Message.getBooleanFieldWithDefault(msg, 12, false),
    imageFilename: jspb.Message.getFieldWithDefault(msg, 13, ""),
    imageUrl: jspb.Message.getFieldWithDefault(msg, 14, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.core.private.allowances.Allowance}
 */
proto.core.private.allowances.Allowance.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.core.private.allowances.Allowance;
  return proto.core.private.allowances.Allowance.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.core.private.allowances.Allowance} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.core.private.allowances.Allowance}
 */
proto.core.private.allowances.Allowance.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setDisplayName(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setSingleUnitName(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setPluralUnitName(value);
      break;
    case 6:
      var value = /** @type {!proto.core.private.allowances.PricingModelType} */ (reader.readEnum());
      msg.setPricingModelType(value);
      break;
    case 7:
      var value = new proto.core.private.allowances.Plan;
      reader.readMessage(value,proto.core.private.allowances.Plan.deserializeBinaryFromReader);
      msg.addPlans(value);
      break;
    case 8:
      var value = new proto.core.private.allowances.AllowancePriceModel;
      reader.readMessage(value,proto.core.private.allowances.AllowancePriceModel.deserializeBinaryFromReader);
      msg.addPriceModels(value);
      break;
    case 9:
      var value = new proto.core.private.allowances.Counter;
      reader.readMessage(value,proto.core.private.allowances.Counter.deserializeBinaryFromReader);
      msg.setCounter(value);
      break;
    case 10:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setHasActiveLinkedPlans(value);
      break;
    case 11:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsActive(value);
      break;
    case 12:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsImageEnabled(value);
      break;
    case 13:
      var value = /** @type {string} */ (reader.readString());
      msg.setImageFilename(value);
      break;
    case 14:
      var value = /** @type {string} */ (reader.readString());
      msg.setImageUrl(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.core.private.allowances.Allowance.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.core.private.allowances.Allowance.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.core.private.allowances.Allowance} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.core.private.allowances.Allowance.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getDisplayName();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getSingleUnitName();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getPluralUnitName();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getPricingModelType();
  if (f !== 0.0) {
    writer.writeEnum(
      6,
      f
    );
  }
  f = message.getPlansList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      7,
      f,
      proto.core.private.allowances.Plan.serializeBinaryToWriter
    );
  }
  f = message.getPriceModelsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      8,
      f,
      proto.core.private.allowances.AllowancePriceModel.serializeBinaryToWriter
    );
  }
  f = message.getCounter();
  if (f != null) {
    writer.writeMessage(
      9,
      f,
      proto.core.private.allowances.Counter.serializeBinaryToWriter
    );
  }
  f = message.getHasActiveLinkedPlans();
  if (f) {
    writer.writeBool(
      10,
      f
    );
  }
  f = message.getIsActive();
  if (f) {
    writer.writeBool(
      11,
      f
    );
  }
  f = message.getIsImageEnabled();
  if (f) {
    writer.writeBool(
      12,
      f
    );
  }
  f = message.getImageFilename();
  if (f.length > 0) {
    writer.writeString(
      13,
      f
    );
  }
  f = message.getImageUrl();
  if (f.length > 0) {
    writer.writeString(
      14,
      f
    );
  }
};


/**
 * optional int32 id = 1;
 * @return {number}
 */
proto.core.private.allowances.Allowance.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.core.private.allowances.Allowance} returns this
 */
proto.core.private.allowances.Allowance.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.core.private.allowances.Allowance.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.core.private.allowances.Allowance} returns this
 */
proto.core.private.allowances.Allowance.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string display_name = 3;
 * @return {string}
 */
proto.core.private.allowances.Allowance.prototype.getDisplayName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.core.private.allowances.Allowance} returns this
 */
proto.core.private.allowances.Allowance.prototype.setDisplayName = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string single_unit_name = 4;
 * @return {string}
 */
proto.core.private.allowances.Allowance.prototype.getSingleUnitName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.core.private.allowances.Allowance} returns this
 */
proto.core.private.allowances.Allowance.prototype.setSingleUnitName = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string plural_unit_name = 5;
 * @return {string}
 */
proto.core.private.allowances.Allowance.prototype.getPluralUnitName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.core.private.allowances.Allowance} returns this
 */
proto.core.private.allowances.Allowance.prototype.setPluralUnitName = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional PricingModelType pricing_model_type = 6;
 * @return {!proto.core.private.allowances.PricingModelType}
 */
proto.core.private.allowances.Allowance.prototype.getPricingModelType = function() {
  return /** @type {!proto.core.private.allowances.PricingModelType} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {!proto.core.private.allowances.PricingModelType} value
 * @return {!proto.core.private.allowances.Allowance} returns this
 */
proto.core.private.allowances.Allowance.prototype.setPricingModelType = function(value) {
  return jspb.Message.setProto3EnumField(this, 6, value);
};


/**
 * repeated Plan plans = 7;
 * @return {!Array<!proto.core.private.allowances.Plan>}
 */
proto.core.private.allowances.Allowance.prototype.getPlansList = function() {
  return /** @type{!Array<!proto.core.private.allowances.Plan>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.core.private.allowances.Plan, 7));
};


/**
 * @param {!Array<!proto.core.private.allowances.Plan>} value
 * @return {!proto.core.private.allowances.Allowance} returns this
*/
proto.core.private.allowances.Allowance.prototype.setPlansList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 7, value);
};


/**
 * @param {!proto.core.private.allowances.Plan=} opt_value
 * @param {number=} opt_index
 * @return {!proto.core.private.allowances.Plan}
 */
proto.core.private.allowances.Allowance.prototype.addPlans = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 7, opt_value, proto.core.private.allowances.Plan, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.core.private.allowances.Allowance} returns this
 */
proto.core.private.allowances.Allowance.prototype.clearPlansList = function() {
  return this.setPlansList([]);
};


/**
 * repeated AllowancePriceModel price_models = 8;
 * @return {!Array<!proto.core.private.allowances.AllowancePriceModel>}
 */
proto.core.private.allowances.Allowance.prototype.getPriceModelsList = function() {
  return /** @type{!Array<!proto.core.private.allowances.AllowancePriceModel>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.core.private.allowances.AllowancePriceModel, 8));
};


/**
 * @param {!Array<!proto.core.private.allowances.AllowancePriceModel>} value
 * @return {!proto.core.private.allowances.Allowance} returns this
*/
proto.core.private.allowances.Allowance.prototype.setPriceModelsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 8, value);
};


/**
 * @param {!proto.core.private.allowances.AllowancePriceModel=} opt_value
 * @param {number=} opt_index
 * @return {!proto.core.private.allowances.AllowancePriceModel}
 */
proto.core.private.allowances.Allowance.prototype.addPriceModels = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 8, opt_value, proto.core.private.allowances.AllowancePriceModel, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.core.private.allowances.Allowance} returns this
 */
proto.core.private.allowances.Allowance.prototype.clearPriceModelsList = function() {
  return this.setPriceModelsList([]);
};


/**
 * optional Counter counter = 9;
 * @return {?proto.core.private.allowances.Counter}
 */
proto.core.private.allowances.Allowance.prototype.getCounter = function() {
  return /** @type{?proto.core.private.allowances.Counter} */ (
    jspb.Message.getWrapperField(this, proto.core.private.allowances.Counter, 9));
};


/**
 * @param {?proto.core.private.allowances.Counter|undefined} value
 * @return {!proto.core.private.allowances.Allowance} returns this
*/
proto.core.private.allowances.Allowance.prototype.setCounter = function(value) {
  return jspb.Message.setWrapperField(this, 9, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.core.private.allowances.Allowance} returns this
 */
proto.core.private.allowances.Allowance.prototype.clearCounter = function() {
  return this.setCounter(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.core.private.allowances.Allowance.prototype.hasCounter = function() {
  return jspb.Message.getField(this, 9) != null;
};


/**
 * optional bool has_active_linked_plans = 10;
 * @return {boolean}
 */
proto.core.private.allowances.Allowance.prototype.getHasActiveLinkedPlans = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 10, false));
};


/**
 * @param {boolean} value
 * @return {!proto.core.private.allowances.Allowance} returns this
 */
proto.core.private.allowances.Allowance.prototype.setHasActiveLinkedPlans = function(value) {
  return jspb.Message.setProto3BooleanField(this, 10, value);
};


/**
 * optional bool is_active = 11;
 * @return {boolean}
 */
proto.core.private.allowances.Allowance.prototype.getIsActive = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 11, false));
};


/**
 * @param {boolean} value
 * @return {!proto.core.private.allowances.Allowance} returns this
 */
proto.core.private.allowances.Allowance.prototype.setIsActive = function(value) {
  return jspb.Message.setProto3BooleanField(this, 11, value);
};


/**
 * optional bool is_image_enabled = 12;
 * @return {boolean}
 */
proto.core.private.allowances.Allowance.prototype.getIsImageEnabled = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 12, false));
};


/**
 * @param {boolean} value
 * @return {!proto.core.private.allowances.Allowance} returns this
 */
proto.core.private.allowances.Allowance.prototype.setIsImageEnabled = function(value) {
  return jspb.Message.setProto3BooleanField(this, 12, value);
};


/**
 * optional string image_filename = 13;
 * @return {string}
 */
proto.core.private.allowances.Allowance.prototype.getImageFilename = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 13, ""));
};


/**
 * @param {string} value
 * @return {!proto.core.private.allowances.Allowance} returns this
 */
proto.core.private.allowances.Allowance.prototype.setImageFilename = function(value) {
  return jspb.Message.setProto3StringField(this, 13, value);
};


/**
 * optional string image_url = 14;
 * @return {string}
 */
proto.core.private.allowances.Allowance.prototype.getImageUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 14, ""));
};


/**
 * @param {string} value
 * @return {!proto.core.private.allowances.Allowance} returns this
 */
proto.core.private.allowances.Allowance.prototype.setImageUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 14, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.core.private.allowances.AllowancePriceModel.repeatedFields_ = [9];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.core.private.allowances.AllowancePriceModel.prototype.toObject = function(opt_includeInstance) {
  return proto.core.private.allowances.AllowancePriceModel.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.core.private.allowances.AllowancePriceModel} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.core.private.allowances.AllowancePriceModel.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    frequency: jspb.Message.getFieldWithDefault(msg, 3, 0),
    frequencyType: jspb.Message.getFieldWithDefault(msg, 4, 0),
    includedUnits: (f = msg.getIncludedUnits()) && google_protobuf_wrappers_pb.Int32Value.toObject(includeInstance, f),
    perUnitPrice: jspb.Message.getFieldWithDefault(msg, 6, ""),
    perUnitPriceFormatted: jspb.Message.getFieldWithDefault(msg, 7, ""),
    currency: (f = msg.getCurrency()) && proto.core.private.allowances.Currency.toObject(includeInstance, f),
    tiersList: jspb.Message.toObjectList(msg.getTiersList(),
    proto.core.private.allowances.UnitTier.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.core.private.allowances.AllowancePriceModel}
 */
proto.core.private.allowances.AllowancePriceModel.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.core.private.allowances.AllowancePriceModel;
  return proto.core.private.allowances.AllowancePriceModel.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.core.private.allowances.AllowancePriceModel} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.core.private.allowances.AllowancePriceModel}
 */
proto.core.private.allowances.AllowancePriceModel.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setFrequency(value);
      break;
    case 4:
      var value = /** @type {!proto.core.private.allowances.FrequencyType} */ (reader.readEnum());
      msg.setFrequencyType(value);
      break;
    case 5:
      var value = new google_protobuf_wrappers_pb.Int32Value;
      reader.readMessage(value,google_protobuf_wrappers_pb.Int32Value.deserializeBinaryFromReader);
      msg.setIncludedUnits(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setPerUnitPrice(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setPerUnitPriceFormatted(value);
      break;
    case 8:
      var value = new proto.core.private.allowances.Currency;
      reader.readMessage(value,proto.core.private.allowances.Currency.deserializeBinaryFromReader);
      msg.setCurrency(value);
      break;
    case 9:
      var value = new proto.core.private.allowances.UnitTier;
      reader.readMessage(value,proto.core.private.allowances.UnitTier.deserializeBinaryFromReader);
      msg.addTiers(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.core.private.allowances.AllowancePriceModel.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.core.private.allowances.AllowancePriceModel.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.core.private.allowances.AllowancePriceModel} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.core.private.allowances.AllowancePriceModel.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getFrequency();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
  f = message.getFrequencyType();
  if (f !== 0.0) {
    writer.writeEnum(
      4,
      f
    );
  }
  f = message.getIncludedUnits();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      google_protobuf_wrappers_pb.Int32Value.serializeBinaryToWriter
    );
  }
  f = message.getPerUnitPrice();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getPerUnitPriceFormatted();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getCurrency();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      proto.core.private.allowances.Currency.serializeBinaryToWriter
    );
  }
  f = message.getTiersList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      9,
      f,
      proto.core.private.allowances.UnitTier.serializeBinaryToWriter
    );
  }
};


/**
 * optional int32 id = 1;
 * @return {number}
 */
proto.core.private.allowances.AllowancePriceModel.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.core.private.allowances.AllowancePriceModel} returns this
 */
proto.core.private.allowances.AllowancePriceModel.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.core.private.allowances.AllowancePriceModel.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.core.private.allowances.AllowancePriceModel} returns this
 */
proto.core.private.allowances.AllowancePriceModel.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional int32 frequency = 3;
 * @return {number}
 */
proto.core.private.allowances.AllowancePriceModel.prototype.getFrequency = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.core.private.allowances.AllowancePriceModel} returns this
 */
proto.core.private.allowances.AllowancePriceModel.prototype.setFrequency = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional FrequencyType frequency_type = 4;
 * @return {!proto.core.private.allowances.FrequencyType}
 */
proto.core.private.allowances.AllowancePriceModel.prototype.getFrequencyType = function() {
  return /** @type {!proto.core.private.allowances.FrequencyType} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {!proto.core.private.allowances.FrequencyType} value
 * @return {!proto.core.private.allowances.AllowancePriceModel} returns this
 */
proto.core.private.allowances.AllowancePriceModel.prototype.setFrequencyType = function(value) {
  return jspb.Message.setProto3EnumField(this, 4, value);
};


/**
 * optional google.protobuf.Int32Value included_units = 5;
 * @return {?proto.google.protobuf.Int32Value}
 */
proto.core.private.allowances.AllowancePriceModel.prototype.getIncludedUnits = function() {
  return /** @type{?proto.google.protobuf.Int32Value} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.Int32Value, 5));
};


/**
 * @param {?proto.google.protobuf.Int32Value|undefined} value
 * @return {!proto.core.private.allowances.AllowancePriceModel} returns this
*/
proto.core.private.allowances.AllowancePriceModel.prototype.setIncludedUnits = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.core.private.allowances.AllowancePriceModel} returns this
 */
proto.core.private.allowances.AllowancePriceModel.prototype.clearIncludedUnits = function() {
  return this.setIncludedUnits(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.core.private.allowances.AllowancePriceModel.prototype.hasIncludedUnits = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional string per_unit_price = 6;
 * @return {string}
 */
proto.core.private.allowances.AllowancePriceModel.prototype.getPerUnitPrice = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.core.private.allowances.AllowancePriceModel} returns this
 */
proto.core.private.allowances.AllowancePriceModel.prototype.setPerUnitPrice = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string per_unit_price_formatted = 7;
 * @return {string}
 */
proto.core.private.allowances.AllowancePriceModel.prototype.getPerUnitPriceFormatted = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.core.private.allowances.AllowancePriceModel} returns this
 */
proto.core.private.allowances.AllowancePriceModel.prototype.setPerUnitPriceFormatted = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional Currency currency = 8;
 * @return {?proto.core.private.allowances.Currency}
 */
proto.core.private.allowances.AllowancePriceModel.prototype.getCurrency = function() {
  return /** @type{?proto.core.private.allowances.Currency} */ (
    jspb.Message.getWrapperField(this, proto.core.private.allowances.Currency, 8));
};


/**
 * @param {?proto.core.private.allowances.Currency|undefined} value
 * @return {!proto.core.private.allowances.AllowancePriceModel} returns this
*/
proto.core.private.allowances.AllowancePriceModel.prototype.setCurrency = function(value) {
  return jspb.Message.setWrapperField(this, 8, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.core.private.allowances.AllowancePriceModel} returns this
 */
proto.core.private.allowances.AllowancePriceModel.prototype.clearCurrency = function() {
  return this.setCurrency(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.core.private.allowances.AllowancePriceModel.prototype.hasCurrency = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * repeated UnitTier tiers = 9;
 * @return {!Array<!proto.core.private.allowances.UnitTier>}
 */
proto.core.private.allowances.AllowancePriceModel.prototype.getTiersList = function() {
  return /** @type{!Array<!proto.core.private.allowances.UnitTier>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.core.private.allowances.UnitTier, 9));
};


/**
 * @param {!Array<!proto.core.private.allowances.UnitTier>} value
 * @return {!proto.core.private.allowances.AllowancePriceModel} returns this
*/
proto.core.private.allowances.AllowancePriceModel.prototype.setTiersList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 9, value);
};


/**
 * @param {!proto.core.private.allowances.UnitTier=} opt_value
 * @param {number=} opt_index
 * @return {!proto.core.private.allowances.UnitTier}
 */
proto.core.private.allowances.AllowancePriceModel.prototype.addTiers = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 9, opt_value, proto.core.private.allowances.UnitTier, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.core.private.allowances.AllowancePriceModel} returns this
 */
proto.core.private.allowances.AllowancePriceModel.prototype.clearTiersList = function() {
  return this.setTiersList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.core.private.allowances.Currency.prototype.toObject = function(opt_includeInstance) {
  return proto.core.private.allowances.Currency.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.core.private.allowances.Currency} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.core.private.allowances.Currency.toObject = function(includeInstance, msg) {
  var f, obj = {
    englishName: jspb.Message.getFieldWithDefault(msg, 1, ""),
    iso3Code: jspb.Message.getFieldWithDefault(msg, 2, ""),
    symbol: jspb.Message.getFieldWithDefault(msg, 3, ""),
    hasDecimal: jspb.Message.getBooleanFieldWithDefault(msg, 4, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.core.private.allowances.Currency}
 */
proto.core.private.allowances.Currency.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.core.private.allowances.Currency;
  return proto.core.private.allowances.Currency.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.core.private.allowances.Currency} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.core.private.allowances.Currency}
 */
proto.core.private.allowances.Currency.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setEnglishName(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setIso3Code(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setSymbol(value);
      break;
    case 4:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setHasDecimal(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.core.private.allowances.Currency.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.core.private.allowances.Currency.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.core.private.allowances.Currency} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.core.private.allowances.Currency.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getEnglishName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getIso3Code();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getSymbol();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getHasDecimal();
  if (f) {
    writer.writeBool(
      4,
      f
    );
  }
};


/**
 * optional string english_name = 1;
 * @return {string}
 */
proto.core.private.allowances.Currency.prototype.getEnglishName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.core.private.allowances.Currency} returns this
 */
proto.core.private.allowances.Currency.prototype.setEnglishName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string iso_3_code = 2;
 * @return {string}
 */
proto.core.private.allowances.Currency.prototype.getIso3Code = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.core.private.allowances.Currency} returns this
 */
proto.core.private.allowances.Currency.prototype.setIso3Code = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string symbol = 3;
 * @return {string}
 */
proto.core.private.allowances.Currency.prototype.getSymbol = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.core.private.allowances.Currency} returns this
 */
proto.core.private.allowances.Currency.prototype.setSymbol = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional bool has_decimal = 4;
 * @return {boolean}
 */
proto.core.private.allowances.Currency.prototype.getHasDecimal = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 4, false));
};


/**
 * @param {boolean} value
 * @return {!proto.core.private.allowances.Currency} returns this
 */
proto.core.private.allowances.Currency.prototype.setHasDecimal = function(value) {
  return jspb.Message.setProto3BooleanField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.core.private.allowances.Plan.prototype.toObject = function(opt_includeInstance) {
  return proto.core.private.allowances.Plan.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.core.private.allowances.Plan} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.core.private.allowances.Plan.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    productName: jspb.Message.getFieldWithDefault(msg, 3, ""),
    productId: jspb.Message.getFieldWithDefault(msg, 4, 0),
    forced: jspb.Message.getBooleanFieldWithDefault(msg, 5, false),
    status: jspb.Message.getFieldWithDefault(msg, 6, 0),
    isActive: jspb.Message.getBooleanFieldWithDefault(msg, 7, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.core.private.allowances.Plan}
 */
proto.core.private.allowances.Plan.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.core.private.allowances.Plan;
  return proto.core.private.allowances.Plan.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.core.private.allowances.Plan} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.core.private.allowances.Plan}
 */
proto.core.private.allowances.Plan.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setProductName(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setProductId(value);
      break;
    case 5:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setForced(value);
      break;
    case 6:
      var value = /** @type {!proto.core.private.allowances.VisibilityType} */ (reader.readEnum());
      msg.setStatus(value);
      break;
    case 7:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsActive(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.core.private.allowances.Plan.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.core.private.allowances.Plan.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.core.private.allowances.Plan} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.core.private.allowances.Plan.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getProductName();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getProductId();
  if (f !== 0) {
    writer.writeInt32(
      4,
      f
    );
  }
  f = message.getForced();
  if (f) {
    writer.writeBool(
      5,
      f
    );
  }
  f = message.getStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      6,
      f
    );
  }
  f = message.getIsActive();
  if (f) {
    writer.writeBool(
      7,
      f
    );
  }
};


/**
 * optional int32 id = 1;
 * @return {number}
 */
proto.core.private.allowances.Plan.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.core.private.allowances.Plan} returns this
 */
proto.core.private.allowances.Plan.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.core.private.allowances.Plan.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.core.private.allowances.Plan} returns this
 */
proto.core.private.allowances.Plan.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string product_name = 3;
 * @return {string}
 */
proto.core.private.allowances.Plan.prototype.getProductName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.core.private.allowances.Plan} returns this
 */
proto.core.private.allowances.Plan.prototype.setProductName = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional int32 product_id = 4;
 * @return {number}
 */
proto.core.private.allowances.Plan.prototype.getProductId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.core.private.allowances.Plan} returns this
 */
proto.core.private.allowances.Plan.prototype.setProductId = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional bool forced = 5;
 * @return {boolean}
 */
proto.core.private.allowances.Plan.prototype.getForced = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 5, false));
};


/**
 * @param {boolean} value
 * @return {!proto.core.private.allowances.Plan} returns this
 */
proto.core.private.allowances.Plan.prototype.setForced = function(value) {
  return jspb.Message.setProto3BooleanField(this, 5, value);
};


/**
 * optional VisibilityType status = 6;
 * @return {!proto.core.private.allowances.VisibilityType}
 */
proto.core.private.allowances.Plan.prototype.getStatus = function() {
  return /** @type {!proto.core.private.allowances.VisibilityType} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {!proto.core.private.allowances.VisibilityType} value
 * @return {!proto.core.private.allowances.Plan} returns this
 */
proto.core.private.allowances.Plan.prototype.setStatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 6, value);
};


/**
 * optional bool is_active = 7;
 * @return {boolean}
 */
proto.core.private.allowances.Plan.prototype.getIsActive = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 7, false));
};


/**
 * @param {boolean} value
 * @return {!proto.core.private.allowances.Plan} returns this
 */
proto.core.private.allowances.Plan.prototype.setIsActive = function(value) {
  return jspb.Message.setProto3BooleanField(this, 7, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.core.private.allowances.AllowancePlan.prototype.toObject = function(opt_includeInstance) {
  return proto.core.private.allowances.AllowancePlan.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.core.private.allowances.AllowancePlan} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.core.private.allowances.AllowancePlan.toObject = function(includeInstance, msg) {
  var f, obj = {
    planId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    planName: jspb.Message.getFieldWithDefault(msg, 2, ""),
    productName: jspb.Message.getFieldWithDefault(msg, 3, ""),
    forceAllowance: jspb.Message.getBooleanFieldWithDefault(msg, 4, false),
    isActive: jspb.Message.getBooleanFieldWithDefault(msg, 5, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.core.private.allowances.AllowancePlan}
 */
proto.core.private.allowances.AllowancePlan.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.core.private.allowances.AllowancePlan;
  return proto.core.private.allowances.AllowancePlan.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.core.private.allowances.AllowancePlan} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.core.private.allowances.AllowancePlan}
 */
proto.core.private.allowances.AllowancePlan.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPlanId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setPlanName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setProductName(value);
      break;
    case 4:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setForceAllowance(value);
      break;
    case 5:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsActive(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.core.private.allowances.AllowancePlan.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.core.private.allowances.AllowancePlan.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.core.private.allowances.AllowancePlan} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.core.private.allowances.AllowancePlan.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPlanId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getPlanName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getProductName();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getForceAllowance();
  if (f) {
    writer.writeBool(
      4,
      f
    );
  }
  f = message.getIsActive();
  if (f) {
    writer.writeBool(
      5,
      f
    );
  }
};


/**
 * optional int32 plan_id = 1;
 * @return {number}
 */
proto.core.private.allowances.AllowancePlan.prototype.getPlanId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.core.private.allowances.AllowancePlan} returns this
 */
proto.core.private.allowances.AllowancePlan.prototype.setPlanId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string plan_name = 2;
 * @return {string}
 */
proto.core.private.allowances.AllowancePlan.prototype.getPlanName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.core.private.allowances.AllowancePlan} returns this
 */
proto.core.private.allowances.AllowancePlan.prototype.setPlanName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string product_name = 3;
 * @return {string}
 */
proto.core.private.allowances.AllowancePlan.prototype.getProductName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.core.private.allowances.AllowancePlan} returns this
 */
proto.core.private.allowances.AllowancePlan.prototype.setProductName = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional bool force_allowance = 4;
 * @return {boolean}
 */
proto.core.private.allowances.AllowancePlan.prototype.getForceAllowance = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 4, false));
};


/**
 * @param {boolean} value
 * @return {!proto.core.private.allowances.AllowancePlan} returns this
 */
proto.core.private.allowances.AllowancePlan.prototype.setForceAllowance = function(value) {
  return jspb.Message.setProto3BooleanField(this, 4, value);
};


/**
 * optional bool is_active = 5;
 * @return {boolean}
 */
proto.core.private.allowances.AllowancePlan.prototype.getIsActive = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 5, false));
};


/**
 * @param {boolean} value
 * @return {!proto.core.private.allowances.AllowancePlan} returns this
 */
proto.core.private.allowances.AllowancePlan.prototype.setIsActive = function(value) {
  return jspb.Message.setProto3BooleanField(this, 5, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.core.private.allowances.UpsertAllowancePlan.prototype.toObject = function(opt_includeInstance) {
  return proto.core.private.allowances.UpsertAllowancePlan.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.core.private.allowances.UpsertAllowancePlan} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.core.private.allowances.UpsertAllowancePlan.toObject = function(includeInstance, msg) {
  var f, obj = {
    planId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    forceAllowance: jspb.Message.getBooleanFieldWithDefault(msg, 2, false),
    isActive: jspb.Message.getBooleanFieldWithDefault(msg, 3, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.core.private.allowances.UpsertAllowancePlan}
 */
proto.core.private.allowances.UpsertAllowancePlan.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.core.private.allowances.UpsertAllowancePlan;
  return proto.core.private.allowances.UpsertAllowancePlan.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.core.private.allowances.UpsertAllowancePlan} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.core.private.allowances.UpsertAllowancePlan}
 */
proto.core.private.allowances.UpsertAllowancePlan.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPlanId(value);
      break;
    case 2:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setForceAllowance(value);
      break;
    case 3:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsActive(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.core.private.allowances.UpsertAllowancePlan.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.core.private.allowances.UpsertAllowancePlan.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.core.private.allowances.UpsertAllowancePlan} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.core.private.allowances.UpsertAllowancePlan.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPlanId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getForceAllowance();
  if (f) {
    writer.writeBool(
      2,
      f
    );
  }
  f = message.getIsActive();
  if (f) {
    writer.writeBool(
      3,
      f
    );
  }
};


/**
 * optional int32 plan_id = 1;
 * @return {number}
 */
proto.core.private.allowances.UpsertAllowancePlan.prototype.getPlanId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.core.private.allowances.UpsertAllowancePlan} returns this
 */
proto.core.private.allowances.UpsertAllowancePlan.prototype.setPlanId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional bool force_allowance = 2;
 * @return {boolean}
 */
proto.core.private.allowances.UpsertAllowancePlan.prototype.getForceAllowance = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
};


/**
 * @param {boolean} value
 * @return {!proto.core.private.allowances.UpsertAllowancePlan} returns this
 */
proto.core.private.allowances.UpsertAllowancePlan.prototype.setForceAllowance = function(value) {
  return jspb.Message.setProto3BooleanField(this, 2, value);
};


/**
 * optional bool is_active = 3;
 * @return {boolean}
 */
proto.core.private.allowances.UpsertAllowancePlan.prototype.getIsActive = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 3, false));
};


/**
 * @param {boolean} value
 * @return {!proto.core.private.allowances.UpsertAllowancePlan} returns this
 */
proto.core.private.allowances.UpsertAllowancePlan.prototype.setIsActive = function(value) {
  return jspb.Message.setProto3BooleanField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.core.private.allowances.UnitTier.prototype.toObject = function(opt_includeInstance) {
  return proto.core.private.allowances.UnitTier.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.core.private.allowances.UnitTier} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.core.private.allowances.UnitTier.toObject = function(includeInstance, msg) {
  var f, obj = {
    start: jspb.Message.getFieldWithDefault(msg, 1, 0),
    finish: jspb.Message.getFieldWithDefault(msg, 2, 0),
    price: jspb.Message.getFieldWithDefault(msg, 3, 0),
    priceFormatted: jspb.Message.getFieldWithDefault(msg, 4, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.core.private.allowances.UnitTier}
 */
proto.core.private.allowances.UnitTier.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.core.private.allowances.UnitTier;
  return proto.core.private.allowances.UnitTier.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.core.private.allowances.UnitTier} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.core.private.allowances.UnitTier}
 */
proto.core.private.allowances.UnitTier.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setStart(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setFinish(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPrice(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setPriceFormatted(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.core.private.allowances.UnitTier.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.core.private.allowances.UnitTier.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.core.private.allowances.UnitTier} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.core.private.allowances.UnitTier.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStart();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getFinish();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getPrice();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
  f = message.getPriceFormatted();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
};


/**
 * optional int32 start = 1;
 * @return {number}
 */
proto.core.private.allowances.UnitTier.prototype.getStart = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.core.private.allowances.UnitTier} returns this
 */
proto.core.private.allowances.UnitTier.prototype.setStart = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int32 finish = 2;
 * @return {number}
 */
proto.core.private.allowances.UnitTier.prototype.getFinish = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.core.private.allowances.UnitTier} returns this
 */
proto.core.private.allowances.UnitTier.prototype.setFinish = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int32 price = 3;
 * @return {number}
 */
proto.core.private.allowances.UnitTier.prototype.getPrice = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.core.private.allowances.UnitTier} returns this
 */
proto.core.private.allowances.UnitTier.prototype.setPrice = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional string price_formatted = 4;
 * @return {string}
 */
proto.core.private.allowances.UnitTier.prototype.getPriceFormatted = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.core.private.allowances.UnitTier} returns this
 */
proto.core.private.allowances.UnitTier.prototype.setPriceFormatted = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.core.private.allowances.UpsertUnitTier.prototype.toObject = function(opt_includeInstance) {
  return proto.core.private.allowances.UpsertUnitTier.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.core.private.allowances.UpsertUnitTier} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.core.private.allowances.UpsertUnitTier.toObject = function(includeInstance, msg) {
  var f, obj = {
    start: jspb.Message.getFieldWithDefault(msg, 1, 0),
    finish: jspb.Message.getFieldWithDefault(msg, 2, 0),
    price: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.core.private.allowances.UpsertUnitTier}
 */
proto.core.private.allowances.UpsertUnitTier.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.core.private.allowances.UpsertUnitTier;
  return proto.core.private.allowances.UpsertUnitTier.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.core.private.allowances.UpsertUnitTier} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.core.private.allowances.UpsertUnitTier}
 */
proto.core.private.allowances.UpsertUnitTier.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setStart(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setFinish(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPrice(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.core.private.allowances.UpsertUnitTier.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.core.private.allowances.UpsertUnitTier.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.core.private.allowances.UpsertUnitTier} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.core.private.allowances.UpsertUnitTier.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStart();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getFinish();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getPrice();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
};


/**
 * optional int32 start = 1;
 * @return {number}
 */
proto.core.private.allowances.UpsertUnitTier.prototype.getStart = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.core.private.allowances.UpsertUnitTier} returns this
 */
proto.core.private.allowances.UpsertUnitTier.prototype.setStart = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int32 finish = 2;
 * @return {number}
 */
proto.core.private.allowances.UpsertUnitTier.prototype.getFinish = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.core.private.allowances.UpsertUnitTier} returns this
 */
proto.core.private.allowances.UpsertUnitTier.prototype.setFinish = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int32 price = 3;
 * @return {number}
 */
proto.core.private.allowances.UpsertUnitTier.prototype.getPrice = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.core.private.allowances.UpsertUnitTier} returns this
 */
proto.core.private.allowances.UpsertUnitTier.prototype.setPrice = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.core.private.allowances.Counter.prototype.toObject = function(opt_includeInstance) {
  return proto.core.private.allowances.Counter.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.core.private.allowances.Counter} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.core.private.allowances.Counter.toObject = function(includeInstance, msg) {
  var f, obj = {
    counterId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    counterName: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.core.private.allowances.Counter}
 */
proto.core.private.allowances.Counter.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.core.private.allowances.Counter;
  return proto.core.private.allowances.Counter.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.core.private.allowances.Counter} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.core.private.allowances.Counter}
 */
proto.core.private.allowances.Counter.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setCounterId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setCounterName(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.core.private.allowances.Counter.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.core.private.allowances.Counter.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.core.private.allowances.Counter} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.core.private.allowances.Counter.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCounterId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getCounterName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string counter_id = 1;
 * @return {string}
 */
proto.core.private.allowances.Counter.prototype.getCounterId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.core.private.allowances.Counter} returns this
 */
proto.core.private.allowances.Counter.prototype.setCounterId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string counter_name = 2;
 * @return {string}
 */
proto.core.private.allowances.Counter.prototype.getCounterName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.core.private.allowances.Counter} returns this
 */
proto.core.private.allowances.Counter.prototype.setCounterName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * @enum {number}
 */
proto.core.private.allowances.AllowanceFilterType = {
  UNSPECIFIED_AFT: 0,
  WITH_LINKED_PLANS: 1,
  WITHOUT_LINKED_PLANS: 2,
  WITH_CAPPED: 3,
  WITH_PER_UNIT: 4,
  WITH_TIERED: 5,
  WITH_RANGED: 6,
  WITH_VOLUME: 7
};

/**
 * @enum {number}
 */
proto.core.private.allowances.PricingModelType = {
  UNSPECIFIED_PMT: 0,
  FLAT_FEE: 1,
  PER_UNIT: 2,
  TIERED: 3,
  RANGED: 4,
  VOLUME: 5,
  CAPPED: 6
};

/**
 * @enum {number}
 */
proto.core.private.allowances.VisibilityType = {
  UNSPECIFIED_VT: 0,
  PUBLIC: 1,
  HIDDEN: 2,
  INTERNAL: 3,
  OFFSALE: 4
};

/**
 * @enum {number}
 */
proto.core.private.allowances.FrequencyType = {
  UNSPECIFIED_FT: 0,
  DAILY: 1,
  WEEKLY: 2,
  MONTHLY: 3,
  YEARLY: 4
};

goog.object.extend(exports, proto.core.private.allowances);
