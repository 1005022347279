import React, { useEffect, useMemo, useState } from "react"
import Text from "../../../ui/text/Text";
import Button from "../../../ui/button/Button";
import FormGroup from "../../../ui/form-group/FormGroup";
import { Col, Row } from "react-grid-system";
import FormLabel from "../../../ui/form-label/FormLabel";
import Dropdown from "../../../ui/dropdown/Dropdown";
import "./QuickBooksSettings.scss";
import { UpdateCompanyQuickBooksAccountsRequest } from "../../../../utils/grpc/generated/Billsby.Protos/accountingIntegrations/private/accounts/accounts_pb";
import { IAuthReducerState } from "../../../../reducers/authReducer";
import { AccountingIntegrationServiceClient } from "../../../../utils/grpc/generated/Billsby.Protos/accountingIntegrations/private/accounts/AccountsServiceClientPb";
import { ConfigConstants } from "../../../../utils/config";
import { grpcUnaryCall } from "../../../../utils/grpc/grpcUtils";
import { StringValue } from "google-protobuf/google/protobuf/wrappers_pb";
import { IAccountingIntegrationsReducerState } from "../../../../reducers/accountingIntegrationsReducer";
import { cancelAccountIntegrationChanges, fetchCompanyQuickBooksAccounts, fetchQuickBooksAccounts, setAccountingIntegrationsField } from "../../../../actions/accountingIntegrationsActions";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../../../..";
import StepCollapsablePanel from "../../../ui/step-collapsable-panel/StepCollapsablePanel";
import NoticePanel from "../../../ui/notice-panel/NoticePanel";
import ProgressIndicator from "../../../ui/progress-indicator/ProgressIndicator";


export const QuickBooksSettings: React.FC = () => {
  const authData = useSelector<AppState, IAuthReducerState>(state => state.auth)
  const { invoicesOptions, creditNotesOptions, refundsOptions,selectedInvoice, selectedCreditNote, selectedRefund,invoiceIsDisconnected, 
    creditNoteIsDisconnected, refundIsDisconnected } = useSelector<AppState, IAccountingIntegrationsReducerState>(state => state.accountingIntegrationsReducer);
  const dispatch = useDispatch<Function>()

  const [isLoading, setIsLoading] = useState(false);
  
  const [warningMessage, setWarningMessage] = useState("");
  
  const hasError = useMemo(() => {
    return invoiceIsDisconnected || creditNoteIsDisconnected || refundIsDisconnected;
  }, [invoiceIsDisconnected, creditNoteIsDisconnected, refundIsDisconnected]);

  const fetchData = async () => {
    try {
      setIsLoading(true);
      await dispatch(fetchQuickBooksAccounts(Number(authData.currentCompanyId)));
      dispatch(fetchCompanyQuickBooksAccounts(Number(authData.currentCompanyId)));
    }
    catch (err) { }
    finally {
      setIsLoading(false)
    }    
  }

  const updateQuickBooksAccounts = async () => {
    setIsLoading(true);
    const updateCompanyQuickbooksAccountsRequest = new UpdateCompanyQuickBooksAccountsRequest();
    updateCompanyQuickbooksAccountsRequest.setCompanyId(Number(authData.currentCompanyId));
    const invoice = new StringValue();
    invoice.setValue(!!selectedInvoice ? selectedInvoice.value : "");
    updateCompanyQuickbooksAccountsRequest.setInvoicesAccountId(invoice);

    const creditNote = new StringValue();
    creditNote.setValue(!!selectedCreditNote ? selectedCreditNote.value : "");
    updateCompanyQuickbooksAccountsRequest.setCreditNotesAccountId(creditNote);

    const refund = new StringValue();
    refund.setValue(!!selectedRefund ? selectedRefund.value : "");
    updateCompanyQuickbooksAccountsRequest.setRefundsAccountId(refund);

    const accountingIntegrationServiceClient = new AccountingIntegrationServiceClient(ConfigConstants.grpcBaseUrl);

    try {
      await grpcUnaryCall(updateCompanyQuickbooksAccountsRequest, accountingIntegrationServiceClient, accountingIntegrationServiceClient.updateCompanyQuickBooksAccounts);
      await fetchData();
    } finally {
      setIsLoading(false);
    }
  }

  useEffect(() => {
    fetchData();
  }, []);

  if(isLoading)
    return <ProgressIndicator color="blue" coverage="normal" />

  return (
    <div>      
      <StepCollapsablePanel
        className={hasError ? "quick-books-online-integration__settings-panel-error": ""}
        isShowTitleIcon={true}
        hasError={hasError}
        title={"INTEGRATION_SETTINGS_TITLE"}
        hasHelpSection={false}
        isCollapsed={false}
      >
        <>
          {!!warningMessage && <NoticePanel
          type="warning"
          isModal
          content={warningMessage}
          />}
          <Text content="INTEGRATION_SETTINGS_TEXT" />
          <FormGroup>
            <Row>
              <Col xs={2}>
                <div className="quick-books-online-integration__form-item">
                  {invoiceIsDisconnected &&<i className="fas fa-map-marker-exclamation quick-books-online-integration__error quick-books-online-integration__display-inline"></i>}
                  <FormLabel
                    target=""
                    content="INTEGRATION_QUICKBOOKS_ONLINE_INVOICES"
                    className={`quick-books-online-integration__form-label quick-books-online-integration__display-inline ${invoiceIsDisconnected ? "error" : ""}`}
                    />
                </div>
              </Col>
              <Col xs={10}>
                <Dropdown
                  id="quick-books-online-integration-dropdown-invoices"
                  value={selectedInvoice}
                  onChange={(selectedItem: any) => 
                    {
                      dispatch(setAccountingIntegrationsField("selectedInvoice", selectedItem));
                      setWarningMessage(!selectedItem.value ? "INTEGRATION_QUICKBOOKS_INVOICE_CREATE_NEW" : "");
                    }}
                  options={invoicesOptions}
                />
              </Col>
            </Row>
          </FormGroup>
          <FormGroup>
            <Row>
              <Col xs={2}>
                <div className="quick-books-online-integration__form-item">
                  {creditNoteIsDisconnected && <i className="fas fa-map-marker-exclamation quick-books-online-integration__error quick-books-online-integration__display-inline"></i>}
                  <FormLabel
                    target=""
                    content="INTEGRATION_QUICKBOOKS_ONLINE_CREDIT_NOTES"
                    className={`quick-books-online-integration__form-label quick-books-online-integration__display-inline ${creditNoteIsDisconnected ? "error" : ""}`}
                    />
                </div>
              </Col>
              <Col xs={10}>
                <Dropdown
                  id="quick-books-online-integration-dropdown-credit-notes"
                  value={selectedCreditNote}
                  onChange={(selectedItem: any) => {
                    dispatch(setAccountingIntegrationsField("selectedCreditNote", selectedItem));
                    setWarningMessage(!selectedItem.value ? "INTEGRATION_QUICKBOOKS_CREDIT_NOTE_CREATE_NEW" : "");
                  }}
                  options={creditNotesOptions}
                />
              </Col>
            </Row>
          </FormGroup>
          <FormGroup>
            <Row>
              <Col xs={2}>
                <div className="quick-books-online-integration__form-item">
                  {refundIsDisconnected && <i className="fas fa-map-marker-exclamation quick-books-online-integration__error quick-books-online-integration__display-inline"></i>}
                  <FormLabel
                    target=""
                    content="INTEGRATION_QUICKBOOKS_ONLINE_REFUNDS"
                    className={`quick-books-online-integration__form-label quick-books-online-integration__display-inline ${refundIsDisconnected ? "error" : ""}`}
                    />
                </div>
              </Col>
              <Col xs={10}>
                <Dropdown
                  id="quick-books-online-integration-dropdown-refunds"
                  value={selectedRefund}
                  onChange={(selectedItem: any) => {
                    dispatch(setAccountingIntegrationsField("selectedRefund", selectedItem));
                    setWarningMessage(!selectedItem.value ? "INTEGRATION_QUICKBOOKS_REFUND_CREATE_NEW" : "");
                  }}
                  options={refundsOptions}
                />
              </Col>
            </Row>
          </FormGroup>
        </>
        </StepCollapsablePanel>
      <Row align="center">
        <Col xs={9}>
          <Button
            id="quick-books-online-integration-submit"
            type="submit"
            isLoading={isLoading}
            disabled={isLoading}
            buttonType={"general"}
            onClick={() => updateQuickBooksAccounts()}
            title="INTEGRATION_SETTINGS_QUICKBOOKS_UPDATE"
            isFullWidth
          />
        </Col>
        <Col xs={3}>
          <Button
            disabled={isLoading}
            id="quick-books-online-integration-cancel"
            isFullWidth buttonType="error"
            title="INTEGRATION_SETTINGS_QUICKBOOKS_CANCEL"
            onClick={() => dispatch(cancelAccountIntegrationChanges())}
            />
        </Col>
      </Row>
    </div>
  )
}

export default QuickBooksSettings