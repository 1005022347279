import React from "react";
import "./CardsPanel.scss";

const CardsPanel = React.memo(() => {
  return (
    <div className="cards-panel">
      <i className="fab fa-cc-visa" />
      <i className="fab fa-cc-mastercard" />
      <i className="fab fa-cc-amex" />
      <i className="fab fa-cc-jcb" />
      <i className="fab fa-cc-diners-club" />
    </div>
  )
})

export default CardsPanel;