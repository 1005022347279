import React, { useEffect, useLayoutEffect } from "react";
import CompanySelector from "../company-selector/CompanySelector";
import Helmet from "react-helmet";
import counterpart from "counterpart";
import NavigationAlert from "../../components/ui/navigation-alert/NavigationAlert";
import { openIntercomIframe } from "../../utils/commonUtils";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../..";
import NavigationAlertCurrencyMismatch from "../../components/ui/navigation-alert/NavigationAlertCurrencyMismatch";
import DashboardPreLive from "../../components/dashboard/dashboard-pre-live/DashboardPreLive";
import { GettingStartedType } from "../../models/GettingStarted";
import { setIsBillsbyLiveFirstTime } from "../../actions/authActions";
import "./Dashboard.scss";
import { resetDashboardState } from "../../actions/dashboardActions";
import PendingInvitations from "../../components/pending-invitations/PendingInvitations";
import DashboardStats from "../../components/dashboard/dashboard-stats/DashboardStats";
import { setUserInterfaceField } from "../../actions/userInteraceActions";
import DashboardUnpaidInvoiveWarning from "../../components/dashboard/dashboard-unpaid-invoice-warning/DashboardUnpaidInvoiveWarning";


const Dashboard: React.FC = () => {
  const authData = useSelector((state: AppState) => state.auth);
  const dispatch = useDispatch();

  const hasSelectedCompany = !!authData.selectedCompanyId;
  const { isBillsbyPreliveFirstTime, dashboardSettings } = authData;

  useEffect(() => {
    return () => {
      if (isBillsbyPreliveFirstTime && dashboardSettings?.status === GettingStartedType.PreLive) {
        dispatch(setIsBillsbyLiveFirstTime(false));
      }
    }
  }, []);


  useLayoutEffect(() => {
    dispatch(setUserInterfaceField("autoScroll", false));
    return function cleanup() {      
      dispatch(setUserInterfaceField("autoScroll", true));
      dispatch(resetDashboardState());
    };
  }, [dashboardSettings]);


  if (!hasSelectedCompany) {
    return (
      <>
        <CompanySelector />
        <PendingInvitations />
      </>
    )
  }



  if (!dashboardSettings) {
    return null;
  }

  return (
    <div>
      <Helmet title={counterpart("NAVBAR_DASHBOARD")} />
      {!!authData.dashboardSettings &&
        authData.dashboardSettings.hasUnpaidInvoices &&
        !authData.dashboardSettings.isSuspended &&
        <DashboardUnpaidInvoiveWarning
          companyName={authData.companyName}
          firstName={!!authData.parsedToken ? authData.parsedToken.given_name : ""}
          amountFormatted={!!authData.dashboardSettings ? authData.dashboardSettings.unpaidAmountFormatted : ""}
          invoiceNumber={!!authData.dashboardSettings ? authData.dashboardSettings.unpaidInvoiceNumber : ""}
          />
      }
      <NavigationAlert
        title={counterpart("DASHBOARD_GOLIVE_ALERT_TEXT1", { firstName: (authData && authData.parsedToken) ? authData.parsedToken.given_name : "" })}
        shouldTranslateTitle={false}
        subTitle="DASHBOARD_GOLIVE_ALERT_TEXT2"
        type="success"
        buttonLabel="DASHBOARD_GOLIVE_ALERT_BTN"
        icon="fas fa-headset"
        onClick={openIntercomIframe}
        isVisible={isBillsbyPreliveFirstTime}
      />

      <NavigationAlertCurrencyMismatch />
      {
        dashboardSettings && dashboardSettings.status === GettingStartedType.PreLive ?
          <DashboardPreLive /> :
          <DashboardStats />
      }
    </div>
  )
}

export default Dashboard;