/* eslint-disable */
// source: exit_reason_config.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!

var jspb = require('google-protobuf');
var goog = jspb;
var global = Function('return this')();

goog.exportSymbol('proto.billing.private.company.exitreasonconfig.GetExitReasonsConfigRequest', null, global);
goog.exportSymbol('proto.billing.private.company.exitreasonconfig.GetExitReasonsConfigRequest.OrderByOption', null, global);
goog.exportSymbol('proto.billing.private.company.exitreasonconfig.GetExitReasonsConfigResponse', null, global);
goog.exportSymbol('proto.billing.private.company.exitreasonconfig.GetExitReasonsConfigResponse.ExitReasonConfig', null, global);
goog.exportSymbol('proto.billing.private.company.exitreasonconfig.UpsertExitReasonConfigRequest', null, global);
goog.exportSymbol('proto.billing.private.company.exitreasonconfig.UpsertExitReasonConfigRequest.ExitReasonConfig', null, global);
goog.exportSymbol('proto.billing.private.company.exitreasonconfig.UpsertExitReasonConfigResponse', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.billing.private.company.exitreasonconfig.GetExitReasonsConfigRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.billing.private.company.exitreasonconfig.GetExitReasonsConfigRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.billing.private.company.exitreasonconfig.GetExitReasonsConfigRequest.displayName = 'proto.billing.private.company.exitreasonconfig.GetExitReasonsConfigRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.billing.private.company.exitreasonconfig.GetExitReasonsConfigResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.billing.private.company.exitreasonconfig.GetExitReasonsConfigResponse.repeatedFields_, null);
};
goog.inherits(proto.billing.private.company.exitreasonconfig.GetExitReasonsConfigResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.billing.private.company.exitreasonconfig.GetExitReasonsConfigResponse.displayName = 'proto.billing.private.company.exitreasonconfig.GetExitReasonsConfigResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.billing.private.company.exitreasonconfig.GetExitReasonsConfigResponse.ExitReasonConfig = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.billing.private.company.exitreasonconfig.GetExitReasonsConfigResponse.ExitReasonConfig, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.billing.private.company.exitreasonconfig.GetExitReasonsConfigResponse.ExitReasonConfig.displayName = 'proto.billing.private.company.exitreasonconfig.GetExitReasonsConfigResponse.ExitReasonConfig';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.billing.private.company.exitreasonconfig.UpsertExitReasonConfigRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.billing.private.company.exitreasonconfig.UpsertExitReasonConfigRequest.repeatedFields_, null);
};
goog.inherits(proto.billing.private.company.exitreasonconfig.UpsertExitReasonConfigRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.billing.private.company.exitreasonconfig.UpsertExitReasonConfigRequest.displayName = 'proto.billing.private.company.exitreasonconfig.UpsertExitReasonConfigRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.billing.private.company.exitreasonconfig.UpsertExitReasonConfigRequest.ExitReasonConfig = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.billing.private.company.exitreasonconfig.UpsertExitReasonConfigRequest.ExitReasonConfig, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.billing.private.company.exitreasonconfig.UpsertExitReasonConfigRequest.ExitReasonConfig.displayName = 'proto.billing.private.company.exitreasonconfig.UpsertExitReasonConfigRequest.ExitReasonConfig';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.billing.private.company.exitreasonconfig.UpsertExitReasonConfigResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.billing.private.company.exitreasonconfig.UpsertExitReasonConfigResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.billing.private.company.exitreasonconfig.UpsertExitReasonConfigResponse.displayName = 'proto.billing.private.company.exitreasonconfig.UpsertExitReasonConfigResponse';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.billing.private.company.exitreasonconfig.GetExitReasonsConfigRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.billing.private.company.exitreasonconfig.GetExitReasonsConfigRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.billing.private.company.exitreasonconfig.GetExitReasonsConfigRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing.private.company.exitreasonconfig.GetExitReasonsConfigRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    companyId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    orderBy: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.billing.private.company.exitreasonconfig.GetExitReasonsConfigRequest}
 */
proto.billing.private.company.exitreasonconfig.GetExitReasonsConfigRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.billing.private.company.exitreasonconfig.GetExitReasonsConfigRequest;
  return proto.billing.private.company.exitreasonconfig.GetExitReasonsConfigRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.billing.private.company.exitreasonconfig.GetExitReasonsConfigRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.billing.private.company.exitreasonconfig.GetExitReasonsConfigRequest}
 */
proto.billing.private.company.exitreasonconfig.GetExitReasonsConfigRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setCompanyId(value);
      break;
    case 2:
      var value = /** @type {!proto.billing.private.company.exitreasonconfig.GetExitReasonsConfigRequest.OrderByOption} */ (reader.readEnum());
      msg.setOrderBy(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.billing.private.company.exitreasonconfig.GetExitReasonsConfigRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.billing.private.company.exitreasonconfig.GetExitReasonsConfigRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.billing.private.company.exitreasonconfig.GetExitReasonsConfigRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing.private.company.exitreasonconfig.GetExitReasonsConfigRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCompanyId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getOrderBy();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
};


/**
 * @enum {number}
 */
proto.billing.private.company.exitreasonconfig.GetExitReasonsConfigRequest.OrderByOption = {
  UNSPECIFIED: 0,
  ORDER_SEQUENCE_NUMBER: 1,
  TEXT: 2
};

/**
 * optional int32 company_id = 1;
 * @return {number}
 */
proto.billing.private.company.exitreasonconfig.GetExitReasonsConfigRequest.prototype.getCompanyId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.billing.private.company.exitreasonconfig.GetExitReasonsConfigRequest} returns this
 */
proto.billing.private.company.exitreasonconfig.GetExitReasonsConfigRequest.prototype.setCompanyId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional OrderByOption order_by = 2;
 * @return {!proto.billing.private.company.exitreasonconfig.GetExitReasonsConfigRequest.OrderByOption}
 */
proto.billing.private.company.exitreasonconfig.GetExitReasonsConfigRequest.prototype.getOrderBy = function() {
  return /** @type {!proto.billing.private.company.exitreasonconfig.GetExitReasonsConfigRequest.OrderByOption} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.billing.private.company.exitreasonconfig.GetExitReasonsConfigRequest.OrderByOption} value
 * @return {!proto.billing.private.company.exitreasonconfig.GetExitReasonsConfigRequest} returns this
 */
proto.billing.private.company.exitreasonconfig.GetExitReasonsConfigRequest.prototype.setOrderBy = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.billing.private.company.exitreasonconfig.GetExitReasonsConfigResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.billing.private.company.exitreasonconfig.GetExitReasonsConfigResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.billing.private.company.exitreasonconfig.GetExitReasonsConfigResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.billing.private.company.exitreasonconfig.GetExitReasonsConfigResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing.private.company.exitreasonconfig.GetExitReasonsConfigResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    resultsList: jspb.Message.toObjectList(msg.getResultsList(),
    proto.billing.private.company.exitreasonconfig.GetExitReasonsConfigResponse.ExitReasonConfig.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.billing.private.company.exitreasonconfig.GetExitReasonsConfigResponse}
 */
proto.billing.private.company.exitreasonconfig.GetExitReasonsConfigResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.billing.private.company.exitreasonconfig.GetExitReasonsConfigResponse;
  return proto.billing.private.company.exitreasonconfig.GetExitReasonsConfigResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.billing.private.company.exitreasonconfig.GetExitReasonsConfigResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.billing.private.company.exitreasonconfig.GetExitReasonsConfigResponse}
 */
proto.billing.private.company.exitreasonconfig.GetExitReasonsConfigResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.billing.private.company.exitreasonconfig.GetExitReasonsConfigResponse.ExitReasonConfig;
      reader.readMessage(value,proto.billing.private.company.exitreasonconfig.GetExitReasonsConfigResponse.ExitReasonConfig.deserializeBinaryFromReader);
      msg.addResults(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.billing.private.company.exitreasonconfig.GetExitReasonsConfigResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.billing.private.company.exitreasonconfig.GetExitReasonsConfigResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.billing.private.company.exitreasonconfig.GetExitReasonsConfigResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing.private.company.exitreasonconfig.GetExitReasonsConfigResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getResultsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.billing.private.company.exitreasonconfig.GetExitReasonsConfigResponse.ExitReasonConfig.serializeBinaryToWriter
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.billing.private.company.exitreasonconfig.GetExitReasonsConfigResponse.ExitReasonConfig.prototype.toObject = function(opt_includeInstance) {
  return proto.billing.private.company.exitreasonconfig.GetExitReasonsConfigResponse.ExitReasonConfig.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.billing.private.company.exitreasonconfig.GetExitReasonsConfigResponse.ExitReasonConfig} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing.private.company.exitreasonconfig.GetExitReasonsConfigResponse.ExitReasonConfig.toObject = function(includeInstance, msg) {
  var f, obj = {
    exitReasonConfigId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    text: jspb.Message.getFieldWithDefault(msg, 2, ""),
    orderSequenceNumber: jspb.Message.getFieldWithDefault(msg, 3, 0),
    isArchived: jspb.Message.getBooleanFieldWithDefault(msg, 4, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.billing.private.company.exitreasonconfig.GetExitReasonsConfigResponse.ExitReasonConfig}
 */
proto.billing.private.company.exitreasonconfig.GetExitReasonsConfigResponse.ExitReasonConfig.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.billing.private.company.exitreasonconfig.GetExitReasonsConfigResponse.ExitReasonConfig;
  return proto.billing.private.company.exitreasonconfig.GetExitReasonsConfigResponse.ExitReasonConfig.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.billing.private.company.exitreasonconfig.GetExitReasonsConfigResponse.ExitReasonConfig} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.billing.private.company.exitreasonconfig.GetExitReasonsConfigResponse.ExitReasonConfig}
 */
proto.billing.private.company.exitreasonconfig.GetExitReasonsConfigResponse.ExitReasonConfig.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setExitReasonConfigId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setText(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setOrderSequenceNumber(value);
      break;
    case 4:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsArchived(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.billing.private.company.exitreasonconfig.GetExitReasonsConfigResponse.ExitReasonConfig.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.billing.private.company.exitreasonconfig.GetExitReasonsConfigResponse.ExitReasonConfig.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.billing.private.company.exitreasonconfig.GetExitReasonsConfigResponse.ExitReasonConfig} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing.private.company.exitreasonconfig.GetExitReasonsConfigResponse.ExitReasonConfig.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getExitReasonConfigId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getText();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getOrderSequenceNumber();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
  f = message.getIsArchived();
  if (f) {
    writer.writeBool(
      4,
      f
    );
  }
};


/**
 * optional int32 exit_reason_config_id = 1;
 * @return {number}
 */
proto.billing.private.company.exitreasonconfig.GetExitReasonsConfigResponse.ExitReasonConfig.prototype.getExitReasonConfigId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.billing.private.company.exitreasonconfig.GetExitReasonsConfigResponse.ExitReasonConfig} returns this
 */
proto.billing.private.company.exitreasonconfig.GetExitReasonsConfigResponse.ExitReasonConfig.prototype.setExitReasonConfigId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string text = 2;
 * @return {string}
 */
proto.billing.private.company.exitreasonconfig.GetExitReasonsConfigResponse.ExitReasonConfig.prototype.getText = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.billing.private.company.exitreasonconfig.GetExitReasonsConfigResponse.ExitReasonConfig} returns this
 */
proto.billing.private.company.exitreasonconfig.GetExitReasonsConfigResponse.ExitReasonConfig.prototype.setText = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional int32 order_sequence_number = 3;
 * @return {number}
 */
proto.billing.private.company.exitreasonconfig.GetExitReasonsConfigResponse.ExitReasonConfig.prototype.getOrderSequenceNumber = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.billing.private.company.exitreasonconfig.GetExitReasonsConfigResponse.ExitReasonConfig} returns this
 */
proto.billing.private.company.exitreasonconfig.GetExitReasonsConfigResponse.ExitReasonConfig.prototype.setOrderSequenceNumber = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional bool is_archived = 4;
 * @return {boolean}
 */
proto.billing.private.company.exitreasonconfig.GetExitReasonsConfigResponse.ExitReasonConfig.prototype.getIsArchived = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 4, false));
};


/**
 * @param {boolean} value
 * @return {!proto.billing.private.company.exitreasonconfig.GetExitReasonsConfigResponse.ExitReasonConfig} returns this
 */
proto.billing.private.company.exitreasonconfig.GetExitReasonsConfigResponse.ExitReasonConfig.prototype.setIsArchived = function(value) {
  return jspb.Message.setProto3BooleanField(this, 4, value);
};


/**
 * repeated ExitReasonConfig results = 1;
 * @return {!Array<!proto.billing.private.company.exitreasonconfig.GetExitReasonsConfigResponse.ExitReasonConfig>}
 */
proto.billing.private.company.exitreasonconfig.GetExitReasonsConfigResponse.prototype.getResultsList = function() {
  return /** @type{!Array<!proto.billing.private.company.exitreasonconfig.GetExitReasonsConfigResponse.ExitReasonConfig>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.billing.private.company.exitreasonconfig.GetExitReasonsConfigResponse.ExitReasonConfig, 1));
};


/**
 * @param {!Array<!proto.billing.private.company.exitreasonconfig.GetExitReasonsConfigResponse.ExitReasonConfig>} value
 * @return {!proto.billing.private.company.exitreasonconfig.GetExitReasonsConfigResponse} returns this
*/
proto.billing.private.company.exitreasonconfig.GetExitReasonsConfigResponse.prototype.setResultsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.billing.private.company.exitreasonconfig.GetExitReasonsConfigResponse.ExitReasonConfig=} opt_value
 * @param {number=} opt_index
 * @return {!proto.billing.private.company.exitreasonconfig.GetExitReasonsConfigResponse.ExitReasonConfig}
 */
proto.billing.private.company.exitreasonconfig.GetExitReasonsConfigResponse.prototype.addResults = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.billing.private.company.exitreasonconfig.GetExitReasonsConfigResponse.ExitReasonConfig, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.billing.private.company.exitreasonconfig.GetExitReasonsConfigResponse} returns this
 */
proto.billing.private.company.exitreasonconfig.GetExitReasonsConfigResponse.prototype.clearResultsList = function() {
  return this.setResultsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.billing.private.company.exitreasonconfig.UpsertExitReasonConfigRequest.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.billing.private.company.exitreasonconfig.UpsertExitReasonConfigRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.billing.private.company.exitreasonconfig.UpsertExitReasonConfigRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.billing.private.company.exitreasonconfig.UpsertExitReasonConfigRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing.private.company.exitreasonconfig.UpsertExitReasonConfigRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    companyId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    exitReasonConfigsList: jspb.Message.toObjectList(msg.getExitReasonConfigsList(),
    proto.billing.private.company.exitreasonconfig.UpsertExitReasonConfigRequest.ExitReasonConfig.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.billing.private.company.exitreasonconfig.UpsertExitReasonConfigRequest}
 */
proto.billing.private.company.exitreasonconfig.UpsertExitReasonConfigRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.billing.private.company.exitreasonconfig.UpsertExitReasonConfigRequest;
  return proto.billing.private.company.exitreasonconfig.UpsertExitReasonConfigRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.billing.private.company.exitreasonconfig.UpsertExitReasonConfigRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.billing.private.company.exitreasonconfig.UpsertExitReasonConfigRequest}
 */
proto.billing.private.company.exitreasonconfig.UpsertExitReasonConfigRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setCompanyId(value);
      break;
    case 2:
      var value = new proto.billing.private.company.exitreasonconfig.UpsertExitReasonConfigRequest.ExitReasonConfig;
      reader.readMessage(value,proto.billing.private.company.exitreasonconfig.UpsertExitReasonConfigRequest.ExitReasonConfig.deserializeBinaryFromReader);
      msg.addExitReasonConfigs(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.billing.private.company.exitreasonconfig.UpsertExitReasonConfigRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.billing.private.company.exitreasonconfig.UpsertExitReasonConfigRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.billing.private.company.exitreasonconfig.UpsertExitReasonConfigRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing.private.company.exitreasonconfig.UpsertExitReasonConfigRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCompanyId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getExitReasonConfigsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.billing.private.company.exitreasonconfig.UpsertExitReasonConfigRequest.ExitReasonConfig.serializeBinaryToWriter
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.billing.private.company.exitreasonconfig.UpsertExitReasonConfigRequest.ExitReasonConfig.prototype.toObject = function(opt_includeInstance) {
  return proto.billing.private.company.exitreasonconfig.UpsertExitReasonConfigRequest.ExitReasonConfig.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.billing.private.company.exitreasonconfig.UpsertExitReasonConfigRequest.ExitReasonConfig} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing.private.company.exitreasonconfig.UpsertExitReasonConfigRequest.ExitReasonConfig.toObject = function(includeInstance, msg) {
  var f, obj = {
    exitReasonConfigId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    text: jspb.Message.getFieldWithDefault(msg, 2, ""),
    orderSequenceNumber: jspb.Message.getFieldWithDefault(msg, 3, 0),
    isArchived: jspb.Message.getBooleanFieldWithDefault(msg, 4, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.billing.private.company.exitreasonconfig.UpsertExitReasonConfigRequest.ExitReasonConfig}
 */
proto.billing.private.company.exitreasonconfig.UpsertExitReasonConfigRequest.ExitReasonConfig.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.billing.private.company.exitreasonconfig.UpsertExitReasonConfigRequest.ExitReasonConfig;
  return proto.billing.private.company.exitreasonconfig.UpsertExitReasonConfigRequest.ExitReasonConfig.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.billing.private.company.exitreasonconfig.UpsertExitReasonConfigRequest.ExitReasonConfig} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.billing.private.company.exitreasonconfig.UpsertExitReasonConfigRequest.ExitReasonConfig}
 */
proto.billing.private.company.exitreasonconfig.UpsertExitReasonConfigRequest.ExitReasonConfig.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setExitReasonConfigId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setText(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setOrderSequenceNumber(value);
      break;
    case 4:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsArchived(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.billing.private.company.exitreasonconfig.UpsertExitReasonConfigRequest.ExitReasonConfig.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.billing.private.company.exitreasonconfig.UpsertExitReasonConfigRequest.ExitReasonConfig.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.billing.private.company.exitreasonconfig.UpsertExitReasonConfigRequest.ExitReasonConfig} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing.private.company.exitreasonconfig.UpsertExitReasonConfigRequest.ExitReasonConfig.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getExitReasonConfigId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getText();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getOrderSequenceNumber();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
  f = message.getIsArchived();
  if (f) {
    writer.writeBool(
      4,
      f
    );
  }
};


/**
 * optional int32 exit_reason_config_id = 1;
 * @return {number}
 */
proto.billing.private.company.exitreasonconfig.UpsertExitReasonConfigRequest.ExitReasonConfig.prototype.getExitReasonConfigId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.billing.private.company.exitreasonconfig.UpsertExitReasonConfigRequest.ExitReasonConfig} returns this
 */
proto.billing.private.company.exitreasonconfig.UpsertExitReasonConfigRequest.ExitReasonConfig.prototype.setExitReasonConfigId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string text = 2;
 * @return {string}
 */
proto.billing.private.company.exitreasonconfig.UpsertExitReasonConfigRequest.ExitReasonConfig.prototype.getText = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.billing.private.company.exitreasonconfig.UpsertExitReasonConfigRequest.ExitReasonConfig} returns this
 */
proto.billing.private.company.exitreasonconfig.UpsertExitReasonConfigRequest.ExitReasonConfig.prototype.setText = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional int32 order_sequence_number = 3;
 * @return {number}
 */
proto.billing.private.company.exitreasonconfig.UpsertExitReasonConfigRequest.ExitReasonConfig.prototype.getOrderSequenceNumber = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.billing.private.company.exitreasonconfig.UpsertExitReasonConfigRequest.ExitReasonConfig} returns this
 */
proto.billing.private.company.exitreasonconfig.UpsertExitReasonConfigRequest.ExitReasonConfig.prototype.setOrderSequenceNumber = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional bool is_archived = 4;
 * @return {boolean}
 */
proto.billing.private.company.exitreasonconfig.UpsertExitReasonConfigRequest.ExitReasonConfig.prototype.getIsArchived = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 4, false));
};


/**
 * @param {boolean} value
 * @return {!proto.billing.private.company.exitreasonconfig.UpsertExitReasonConfigRequest.ExitReasonConfig} returns this
 */
proto.billing.private.company.exitreasonconfig.UpsertExitReasonConfigRequest.ExitReasonConfig.prototype.setIsArchived = function(value) {
  return jspb.Message.setProto3BooleanField(this, 4, value);
};


/**
 * optional int32 company_id = 1;
 * @return {number}
 */
proto.billing.private.company.exitreasonconfig.UpsertExitReasonConfigRequest.prototype.getCompanyId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.billing.private.company.exitreasonconfig.UpsertExitReasonConfigRequest} returns this
 */
proto.billing.private.company.exitreasonconfig.UpsertExitReasonConfigRequest.prototype.setCompanyId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * repeated ExitReasonConfig exit_reason_configs = 2;
 * @return {!Array<!proto.billing.private.company.exitreasonconfig.UpsertExitReasonConfigRequest.ExitReasonConfig>}
 */
proto.billing.private.company.exitreasonconfig.UpsertExitReasonConfigRequest.prototype.getExitReasonConfigsList = function() {
  return /** @type{!Array<!proto.billing.private.company.exitreasonconfig.UpsertExitReasonConfigRequest.ExitReasonConfig>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.billing.private.company.exitreasonconfig.UpsertExitReasonConfigRequest.ExitReasonConfig, 2));
};


/**
 * @param {!Array<!proto.billing.private.company.exitreasonconfig.UpsertExitReasonConfigRequest.ExitReasonConfig>} value
 * @return {!proto.billing.private.company.exitreasonconfig.UpsertExitReasonConfigRequest} returns this
*/
proto.billing.private.company.exitreasonconfig.UpsertExitReasonConfigRequest.prototype.setExitReasonConfigsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.billing.private.company.exitreasonconfig.UpsertExitReasonConfigRequest.ExitReasonConfig=} opt_value
 * @param {number=} opt_index
 * @return {!proto.billing.private.company.exitreasonconfig.UpsertExitReasonConfigRequest.ExitReasonConfig}
 */
proto.billing.private.company.exitreasonconfig.UpsertExitReasonConfigRequest.prototype.addExitReasonConfigs = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.billing.private.company.exitreasonconfig.UpsertExitReasonConfigRequest.ExitReasonConfig, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.billing.private.company.exitreasonconfig.UpsertExitReasonConfigRequest} returns this
 */
proto.billing.private.company.exitreasonconfig.UpsertExitReasonConfigRequest.prototype.clearExitReasonConfigsList = function() {
  return this.setExitReasonConfigsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.billing.private.company.exitreasonconfig.UpsertExitReasonConfigResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.billing.private.company.exitreasonconfig.UpsertExitReasonConfigResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.billing.private.company.exitreasonconfig.UpsertExitReasonConfigResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing.private.company.exitreasonconfig.UpsertExitReasonConfigResponse.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.billing.private.company.exitreasonconfig.UpsertExitReasonConfigResponse}
 */
proto.billing.private.company.exitreasonconfig.UpsertExitReasonConfigResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.billing.private.company.exitreasonconfig.UpsertExitReasonConfigResponse;
  return proto.billing.private.company.exitreasonconfig.UpsertExitReasonConfigResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.billing.private.company.exitreasonconfig.UpsertExitReasonConfigResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.billing.private.company.exitreasonconfig.UpsertExitReasonConfigResponse}
 */
proto.billing.private.company.exitreasonconfig.UpsertExitReasonConfigResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.billing.private.company.exitreasonconfig.UpsertExitReasonConfigResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.billing.private.company.exitreasonconfig.UpsertExitReasonConfigResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.billing.private.company.exitreasonconfig.UpsertExitReasonConfigResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing.private.company.exitreasonconfig.UpsertExitReasonConfigResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};


goog.object.extend(exports, proto.billing.private.company.exitreasonconfig);
