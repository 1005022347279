/* eslint-disable */
// source: companycheckoutconfig.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = Function('return this')();

goog.exportSymbol('proto.core.private.companyconfig.companycheckoutconfig.UpdateAccountManagementLanguageRequest', null, global);
goog.exportSymbol('proto.core.private.companyconfig.companycheckoutconfig.UpdateAccountManagementLanguageResponse', null, global);
goog.exportSymbol('proto.core.private.companyconfig.companycheckoutconfig.UpdateCheckoutDefaultLanguageRequest', null, global);
goog.exportSymbol('proto.core.private.companyconfig.companycheckoutconfig.UpdateCheckoutDefaultLanguageResponse', null, global);
goog.exportSymbol('proto.core.private.companyconfig.companycheckoutconfig.UpsertConfirmationCheckoutConfigRequest', null, global);
goog.exportSymbol('proto.core.private.companyconfig.companycheckoutconfig.UpsertConfirmationCheckoutConfigResponse', null, global);
goog.exportSymbol('proto.core.private.companyconfig.companycheckoutconfig.UpsertShowHideFeaturesRequest', null, global);
goog.exportSymbol('proto.core.private.companyconfig.companycheckoutconfig.UpsertShowHideFeaturesResponse', null, global);
goog.exportSymbol('proto.core.private.companyconfig.companycheckoutconfig.UpsertTerminologiesCheckoutConfigRequest', null, global);
goog.exportSymbol('proto.core.private.companyconfig.companycheckoutconfig.UpsertTerminologiesCheckoutConfigResponse', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.core.private.companyconfig.companycheckoutconfig.UpsertShowHideFeaturesRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.core.private.companyconfig.companycheckoutconfig.UpsertShowHideFeaturesRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.core.private.companyconfig.companycheckoutconfig.UpsertShowHideFeaturesRequest.displayName = 'proto.core.private.companyconfig.companycheckoutconfig.UpsertShowHideFeaturesRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.core.private.companyconfig.companycheckoutconfig.UpsertShowHideFeaturesResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.core.private.companyconfig.companycheckoutconfig.UpsertShowHideFeaturesResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.core.private.companyconfig.companycheckoutconfig.UpsertShowHideFeaturesResponse.displayName = 'proto.core.private.companyconfig.companycheckoutconfig.UpsertShowHideFeaturesResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.core.private.companyconfig.companycheckoutconfig.UpsertConfirmationCheckoutConfigRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.core.private.companyconfig.companycheckoutconfig.UpsertConfirmationCheckoutConfigRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.core.private.companyconfig.companycheckoutconfig.UpsertConfirmationCheckoutConfigRequest.displayName = 'proto.core.private.companyconfig.companycheckoutconfig.UpsertConfirmationCheckoutConfigRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.core.private.companyconfig.companycheckoutconfig.UpsertConfirmationCheckoutConfigResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.core.private.companyconfig.companycheckoutconfig.UpsertConfirmationCheckoutConfigResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.core.private.companyconfig.companycheckoutconfig.UpsertConfirmationCheckoutConfigResponse.displayName = 'proto.core.private.companyconfig.companycheckoutconfig.UpsertConfirmationCheckoutConfigResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.core.private.companyconfig.companycheckoutconfig.UpsertTerminologiesCheckoutConfigRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.core.private.companyconfig.companycheckoutconfig.UpsertTerminologiesCheckoutConfigRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.core.private.companyconfig.companycheckoutconfig.UpsertTerminologiesCheckoutConfigRequest.displayName = 'proto.core.private.companyconfig.companycheckoutconfig.UpsertTerminologiesCheckoutConfigRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.core.private.companyconfig.companycheckoutconfig.UpsertTerminologiesCheckoutConfigResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.core.private.companyconfig.companycheckoutconfig.UpsertTerminologiesCheckoutConfigResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.core.private.companyconfig.companycheckoutconfig.UpsertTerminologiesCheckoutConfigResponse.displayName = 'proto.core.private.companyconfig.companycheckoutconfig.UpsertTerminologiesCheckoutConfigResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.core.private.companyconfig.companycheckoutconfig.UpdateCheckoutDefaultLanguageRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.core.private.companyconfig.companycheckoutconfig.UpdateCheckoutDefaultLanguageRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.core.private.companyconfig.companycheckoutconfig.UpdateCheckoutDefaultLanguageRequest.displayName = 'proto.core.private.companyconfig.companycheckoutconfig.UpdateCheckoutDefaultLanguageRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.core.private.companyconfig.companycheckoutconfig.UpdateCheckoutDefaultLanguageResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.core.private.companyconfig.companycheckoutconfig.UpdateCheckoutDefaultLanguageResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.core.private.companyconfig.companycheckoutconfig.UpdateCheckoutDefaultLanguageResponse.displayName = 'proto.core.private.companyconfig.companycheckoutconfig.UpdateCheckoutDefaultLanguageResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.core.private.companyconfig.companycheckoutconfig.UpdateAccountManagementLanguageRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.core.private.companyconfig.companycheckoutconfig.UpdateAccountManagementLanguageRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.core.private.companyconfig.companycheckoutconfig.UpdateAccountManagementLanguageRequest.displayName = 'proto.core.private.companyconfig.companycheckoutconfig.UpdateAccountManagementLanguageRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.core.private.companyconfig.companycheckoutconfig.UpdateAccountManagementLanguageResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.core.private.companyconfig.companycheckoutconfig.UpdateAccountManagementLanguageResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.core.private.companyconfig.companycheckoutconfig.UpdateAccountManagementLanguageResponse.displayName = 'proto.core.private.companyconfig.companycheckoutconfig.UpdateAccountManagementLanguageResponse';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.core.private.companyconfig.companycheckoutconfig.UpsertShowHideFeaturesRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.core.private.companyconfig.companycheckoutconfig.UpsertShowHideFeaturesRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.core.private.companyconfig.companycheckoutconfig.UpsertShowHideFeaturesRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.core.private.companyconfig.companycheckoutconfig.UpsertShowHideFeaturesRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    companyDomain: jspb.Message.getFieldWithDefault(msg, 1, ""),
    isProgressIndicatorVisible: jspb.Message.getBooleanFieldWithDefault(msg, 2, false),
    isSummaryCartHidden: jspb.Message.getBooleanFieldWithDefault(msg, 3, false),
    isVisaCardLogoVisible: jspb.Message.getBooleanFieldWithDefault(msg, 4, false),
    isMasterCardLogoVisible: jspb.Message.getBooleanFieldWithDefault(msg, 5, false),
    isAmexCardLogoVisible: jspb.Message.getBooleanFieldWithDefault(msg, 6, false),
    isJcbCardLogoVisible: jspb.Message.getBooleanFieldWithDefault(msg, 7, false),
    isDinersCardLogoVisible: jspb.Message.getBooleanFieldWithDefault(msg, 8, false),
    isHowWasThisCalculatedVisible: jspb.Message.getBooleanFieldWithDefault(msg, 9, false),
    isApplyCouponsVisible: jspb.Message.getBooleanFieldWithDefault(msg, 10, false),
    isWixCompatibilityVisible: jspb.Message.getBooleanFieldWithDefault(msg, 11, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.core.private.companyconfig.companycheckoutconfig.UpsertShowHideFeaturesRequest}
 */
proto.core.private.companyconfig.companycheckoutconfig.UpsertShowHideFeaturesRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.core.private.companyconfig.companycheckoutconfig.UpsertShowHideFeaturesRequest;
  return proto.core.private.companyconfig.companycheckoutconfig.UpsertShowHideFeaturesRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.core.private.companyconfig.companycheckoutconfig.UpsertShowHideFeaturesRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.core.private.companyconfig.companycheckoutconfig.UpsertShowHideFeaturesRequest}
 */
proto.core.private.companyconfig.companycheckoutconfig.UpsertShowHideFeaturesRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setCompanyDomain(value);
      break;
    case 2:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsProgressIndicatorVisible(value);
      break;
    case 3:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsSummaryCartHidden(value);
      break;
    case 4:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsVisaCardLogoVisible(value);
      break;
    case 5:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsMasterCardLogoVisible(value);
      break;
    case 6:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsAmexCardLogoVisible(value);
      break;
    case 7:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsJcbCardLogoVisible(value);
      break;
    case 8:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsDinersCardLogoVisible(value);
      break;
    case 9:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsHowWasThisCalculatedVisible(value);
      break;
    case 10:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsApplyCouponsVisible(value);
      break;
    case 11:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsWixCompatibilityVisible(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.core.private.companyconfig.companycheckoutconfig.UpsertShowHideFeaturesRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.core.private.companyconfig.companycheckoutconfig.UpsertShowHideFeaturesRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.core.private.companyconfig.companycheckoutconfig.UpsertShowHideFeaturesRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.core.private.companyconfig.companycheckoutconfig.UpsertShowHideFeaturesRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCompanyDomain();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getIsProgressIndicatorVisible();
  if (f) {
    writer.writeBool(
      2,
      f
    );
  }
  f = message.getIsSummaryCartHidden();
  if (f) {
    writer.writeBool(
      3,
      f
    );
  }
  f = message.getIsVisaCardLogoVisible();
  if (f) {
    writer.writeBool(
      4,
      f
    );
  }
  f = message.getIsMasterCardLogoVisible();
  if (f) {
    writer.writeBool(
      5,
      f
    );
  }
  f = message.getIsAmexCardLogoVisible();
  if (f) {
    writer.writeBool(
      6,
      f
    );
  }
  f = message.getIsJcbCardLogoVisible();
  if (f) {
    writer.writeBool(
      7,
      f
    );
  }
  f = message.getIsDinersCardLogoVisible();
  if (f) {
    writer.writeBool(
      8,
      f
    );
  }
  f = message.getIsHowWasThisCalculatedVisible();
  if (f) {
    writer.writeBool(
      9,
      f
    );
  }
  f = message.getIsApplyCouponsVisible();
  if (f) {
    writer.writeBool(
      10,
      f
    );
  }
  f = message.getIsWixCompatibilityVisible();
  if (f) {
    writer.writeBool(
      11,
      f
    );
  }
};


/**
 * optional string company_domain = 1;
 * @return {string}
 */
proto.core.private.companyconfig.companycheckoutconfig.UpsertShowHideFeaturesRequest.prototype.getCompanyDomain = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.core.private.companyconfig.companycheckoutconfig.UpsertShowHideFeaturesRequest} returns this
 */
proto.core.private.companyconfig.companycheckoutconfig.UpsertShowHideFeaturesRequest.prototype.setCompanyDomain = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional bool is_progress_indicator_visible = 2;
 * @return {boolean}
 */
proto.core.private.companyconfig.companycheckoutconfig.UpsertShowHideFeaturesRequest.prototype.getIsProgressIndicatorVisible = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
};


/**
 * @param {boolean} value
 * @return {!proto.core.private.companyconfig.companycheckoutconfig.UpsertShowHideFeaturesRequest} returns this
 */
proto.core.private.companyconfig.companycheckoutconfig.UpsertShowHideFeaturesRequest.prototype.setIsProgressIndicatorVisible = function(value) {
  return jspb.Message.setProto3BooleanField(this, 2, value);
};


/**
 * optional bool is_summary_cart_hidden = 3;
 * @return {boolean}
 */
proto.core.private.companyconfig.companycheckoutconfig.UpsertShowHideFeaturesRequest.prototype.getIsSummaryCartHidden = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 3, false));
};


/**
 * @param {boolean} value
 * @return {!proto.core.private.companyconfig.companycheckoutconfig.UpsertShowHideFeaturesRequest} returns this
 */
proto.core.private.companyconfig.companycheckoutconfig.UpsertShowHideFeaturesRequest.prototype.setIsSummaryCartHidden = function(value) {
  return jspb.Message.setProto3BooleanField(this, 3, value);
};


/**
 * optional bool is_visa_card_logo_visible = 4;
 * @return {boolean}
 */
proto.core.private.companyconfig.companycheckoutconfig.UpsertShowHideFeaturesRequest.prototype.getIsVisaCardLogoVisible = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 4, false));
};


/**
 * @param {boolean} value
 * @return {!proto.core.private.companyconfig.companycheckoutconfig.UpsertShowHideFeaturesRequest} returns this
 */
proto.core.private.companyconfig.companycheckoutconfig.UpsertShowHideFeaturesRequest.prototype.setIsVisaCardLogoVisible = function(value) {
  return jspb.Message.setProto3BooleanField(this, 4, value);
};


/**
 * optional bool is_master_card_logo_visible = 5;
 * @return {boolean}
 */
proto.core.private.companyconfig.companycheckoutconfig.UpsertShowHideFeaturesRequest.prototype.getIsMasterCardLogoVisible = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 5, false));
};


/**
 * @param {boolean} value
 * @return {!proto.core.private.companyconfig.companycheckoutconfig.UpsertShowHideFeaturesRequest} returns this
 */
proto.core.private.companyconfig.companycheckoutconfig.UpsertShowHideFeaturesRequest.prototype.setIsMasterCardLogoVisible = function(value) {
  return jspb.Message.setProto3BooleanField(this, 5, value);
};


/**
 * optional bool is_amex_card_logo_visible = 6;
 * @return {boolean}
 */
proto.core.private.companyconfig.companycheckoutconfig.UpsertShowHideFeaturesRequest.prototype.getIsAmexCardLogoVisible = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 6, false));
};


/**
 * @param {boolean} value
 * @return {!proto.core.private.companyconfig.companycheckoutconfig.UpsertShowHideFeaturesRequest} returns this
 */
proto.core.private.companyconfig.companycheckoutconfig.UpsertShowHideFeaturesRequest.prototype.setIsAmexCardLogoVisible = function(value) {
  return jspb.Message.setProto3BooleanField(this, 6, value);
};


/**
 * optional bool is_jcb_card_logo_visible = 7;
 * @return {boolean}
 */
proto.core.private.companyconfig.companycheckoutconfig.UpsertShowHideFeaturesRequest.prototype.getIsJcbCardLogoVisible = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 7, false));
};


/**
 * @param {boolean} value
 * @return {!proto.core.private.companyconfig.companycheckoutconfig.UpsertShowHideFeaturesRequest} returns this
 */
proto.core.private.companyconfig.companycheckoutconfig.UpsertShowHideFeaturesRequest.prototype.setIsJcbCardLogoVisible = function(value) {
  return jspb.Message.setProto3BooleanField(this, 7, value);
};


/**
 * optional bool is_diners_card_logo_visible = 8;
 * @return {boolean}
 */
proto.core.private.companyconfig.companycheckoutconfig.UpsertShowHideFeaturesRequest.prototype.getIsDinersCardLogoVisible = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 8, false));
};


/**
 * @param {boolean} value
 * @return {!proto.core.private.companyconfig.companycheckoutconfig.UpsertShowHideFeaturesRequest} returns this
 */
proto.core.private.companyconfig.companycheckoutconfig.UpsertShowHideFeaturesRequest.prototype.setIsDinersCardLogoVisible = function(value) {
  return jspb.Message.setProto3BooleanField(this, 8, value);
};


/**
 * optional bool is_how_was_this_calculated_visible = 9;
 * @return {boolean}
 */
proto.core.private.companyconfig.companycheckoutconfig.UpsertShowHideFeaturesRequest.prototype.getIsHowWasThisCalculatedVisible = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 9, false));
};


/**
 * @param {boolean} value
 * @return {!proto.core.private.companyconfig.companycheckoutconfig.UpsertShowHideFeaturesRequest} returns this
 */
proto.core.private.companyconfig.companycheckoutconfig.UpsertShowHideFeaturesRequest.prototype.setIsHowWasThisCalculatedVisible = function(value) {
  return jspb.Message.setProto3BooleanField(this, 9, value);
};


/**
 * optional bool is_apply_coupons_visible = 10;
 * @return {boolean}
 */
proto.core.private.companyconfig.companycheckoutconfig.UpsertShowHideFeaturesRequest.prototype.getIsApplyCouponsVisible = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 10, false));
};


/**
 * @param {boolean} value
 * @return {!proto.core.private.companyconfig.companycheckoutconfig.UpsertShowHideFeaturesRequest} returns this
 */
proto.core.private.companyconfig.companycheckoutconfig.UpsertShowHideFeaturesRequest.prototype.setIsApplyCouponsVisible = function(value) {
  return jspb.Message.setProto3BooleanField(this, 10, value);
};


/**
 * optional bool is_wix_compatibility_visible = 11;
 * @return {boolean}
 */
proto.core.private.companyconfig.companycheckoutconfig.UpsertShowHideFeaturesRequest.prototype.getIsWixCompatibilityVisible = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 11, false));
};


/**
 * @param {boolean} value
 * @return {!proto.core.private.companyconfig.companycheckoutconfig.UpsertShowHideFeaturesRequest} returns this
 */
proto.core.private.companyconfig.companycheckoutconfig.UpsertShowHideFeaturesRequest.prototype.setIsWixCompatibilityVisible = function(value) {
  return jspb.Message.setProto3BooleanField(this, 11, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.core.private.companyconfig.companycheckoutconfig.UpsertShowHideFeaturesResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.core.private.companyconfig.companycheckoutconfig.UpsertShowHideFeaturesResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.core.private.companyconfig.companycheckoutconfig.UpsertShowHideFeaturesResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.core.private.companyconfig.companycheckoutconfig.UpsertShowHideFeaturesResponse.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.core.private.companyconfig.companycheckoutconfig.UpsertShowHideFeaturesResponse}
 */
proto.core.private.companyconfig.companycheckoutconfig.UpsertShowHideFeaturesResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.core.private.companyconfig.companycheckoutconfig.UpsertShowHideFeaturesResponse;
  return proto.core.private.companyconfig.companycheckoutconfig.UpsertShowHideFeaturesResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.core.private.companyconfig.companycheckoutconfig.UpsertShowHideFeaturesResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.core.private.companyconfig.companycheckoutconfig.UpsertShowHideFeaturesResponse}
 */
proto.core.private.companyconfig.companycheckoutconfig.UpsertShowHideFeaturesResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.core.private.companyconfig.companycheckoutconfig.UpsertShowHideFeaturesResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.core.private.companyconfig.companycheckoutconfig.UpsertShowHideFeaturesResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.core.private.companyconfig.companycheckoutconfig.UpsertShowHideFeaturesResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.core.private.companyconfig.companycheckoutconfig.UpsertShowHideFeaturesResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.core.private.companyconfig.companycheckoutconfig.UpsertConfirmationCheckoutConfigRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.core.private.companyconfig.companycheckoutconfig.UpsertConfirmationCheckoutConfigRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.core.private.companyconfig.companycheckoutconfig.UpsertConfirmationCheckoutConfigRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.core.private.companyconfig.companycheckoutconfig.UpsertConfirmationCheckoutConfigRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    companyDomain: jspb.Message.getFieldWithDefault(msg, 1, ""),
    confirmationHeading: jspb.Message.getFieldWithDefault(msg, 2, ""),
    confirmationSubheading: jspb.Message.getFieldWithDefault(msg, 3, ""),
    confirmationCopy: jspb.Message.getFieldWithDefault(msg, 4, ""),
    confirmationButtonText: jspb.Message.getFieldWithDefault(msg, 5, ""),
    redirectUrl: jspb.Message.getFieldWithDefault(msg, 6, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.core.private.companyconfig.companycheckoutconfig.UpsertConfirmationCheckoutConfigRequest}
 */
proto.core.private.companyconfig.companycheckoutconfig.UpsertConfirmationCheckoutConfigRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.core.private.companyconfig.companycheckoutconfig.UpsertConfirmationCheckoutConfigRequest;
  return proto.core.private.companyconfig.companycheckoutconfig.UpsertConfirmationCheckoutConfigRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.core.private.companyconfig.companycheckoutconfig.UpsertConfirmationCheckoutConfigRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.core.private.companyconfig.companycheckoutconfig.UpsertConfirmationCheckoutConfigRequest}
 */
proto.core.private.companyconfig.companycheckoutconfig.UpsertConfirmationCheckoutConfigRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setCompanyDomain(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setConfirmationHeading(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setConfirmationSubheading(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setConfirmationCopy(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setConfirmationButtonText(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setRedirectUrl(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.core.private.companyconfig.companycheckoutconfig.UpsertConfirmationCheckoutConfigRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.core.private.companyconfig.companycheckoutconfig.UpsertConfirmationCheckoutConfigRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.core.private.companyconfig.companycheckoutconfig.UpsertConfirmationCheckoutConfigRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.core.private.companyconfig.companycheckoutconfig.UpsertConfirmationCheckoutConfigRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCompanyDomain();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getConfirmationHeading();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getConfirmationSubheading();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getConfirmationCopy();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getConfirmationButtonText();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getRedirectUrl();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
};


/**
 * optional string company_domain = 1;
 * @return {string}
 */
proto.core.private.companyconfig.companycheckoutconfig.UpsertConfirmationCheckoutConfigRequest.prototype.getCompanyDomain = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.core.private.companyconfig.companycheckoutconfig.UpsertConfirmationCheckoutConfigRequest} returns this
 */
proto.core.private.companyconfig.companycheckoutconfig.UpsertConfirmationCheckoutConfigRequest.prototype.setCompanyDomain = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string confirmation_heading = 2;
 * @return {string}
 */
proto.core.private.companyconfig.companycheckoutconfig.UpsertConfirmationCheckoutConfigRequest.prototype.getConfirmationHeading = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.core.private.companyconfig.companycheckoutconfig.UpsertConfirmationCheckoutConfigRequest} returns this
 */
proto.core.private.companyconfig.companycheckoutconfig.UpsertConfirmationCheckoutConfigRequest.prototype.setConfirmationHeading = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string confirmation_subheading = 3;
 * @return {string}
 */
proto.core.private.companyconfig.companycheckoutconfig.UpsertConfirmationCheckoutConfigRequest.prototype.getConfirmationSubheading = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.core.private.companyconfig.companycheckoutconfig.UpsertConfirmationCheckoutConfigRequest} returns this
 */
proto.core.private.companyconfig.companycheckoutconfig.UpsertConfirmationCheckoutConfigRequest.prototype.setConfirmationSubheading = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string confirmation_copy = 4;
 * @return {string}
 */
proto.core.private.companyconfig.companycheckoutconfig.UpsertConfirmationCheckoutConfigRequest.prototype.getConfirmationCopy = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.core.private.companyconfig.companycheckoutconfig.UpsertConfirmationCheckoutConfigRequest} returns this
 */
proto.core.private.companyconfig.companycheckoutconfig.UpsertConfirmationCheckoutConfigRequest.prototype.setConfirmationCopy = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string confirmation_button_text = 5;
 * @return {string}
 */
proto.core.private.companyconfig.companycheckoutconfig.UpsertConfirmationCheckoutConfigRequest.prototype.getConfirmationButtonText = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.core.private.companyconfig.companycheckoutconfig.UpsertConfirmationCheckoutConfigRequest} returns this
 */
proto.core.private.companyconfig.companycheckoutconfig.UpsertConfirmationCheckoutConfigRequest.prototype.setConfirmationButtonText = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string redirect_url = 6;
 * @return {string}
 */
proto.core.private.companyconfig.companycheckoutconfig.UpsertConfirmationCheckoutConfigRequest.prototype.getRedirectUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.core.private.companyconfig.companycheckoutconfig.UpsertConfirmationCheckoutConfigRequest} returns this
 */
proto.core.private.companyconfig.companycheckoutconfig.UpsertConfirmationCheckoutConfigRequest.prototype.setRedirectUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.core.private.companyconfig.companycheckoutconfig.UpsertConfirmationCheckoutConfigResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.core.private.companyconfig.companycheckoutconfig.UpsertConfirmationCheckoutConfigResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.core.private.companyconfig.companycheckoutconfig.UpsertConfirmationCheckoutConfigResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.core.private.companyconfig.companycheckoutconfig.UpsertConfirmationCheckoutConfigResponse.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.core.private.companyconfig.companycheckoutconfig.UpsertConfirmationCheckoutConfigResponse}
 */
proto.core.private.companyconfig.companycheckoutconfig.UpsertConfirmationCheckoutConfigResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.core.private.companyconfig.companycheckoutconfig.UpsertConfirmationCheckoutConfigResponse;
  return proto.core.private.companyconfig.companycheckoutconfig.UpsertConfirmationCheckoutConfigResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.core.private.companyconfig.companycheckoutconfig.UpsertConfirmationCheckoutConfigResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.core.private.companyconfig.companycheckoutconfig.UpsertConfirmationCheckoutConfigResponse}
 */
proto.core.private.companyconfig.companycheckoutconfig.UpsertConfirmationCheckoutConfigResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.core.private.companyconfig.companycheckoutconfig.UpsertConfirmationCheckoutConfigResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.core.private.companyconfig.companycheckoutconfig.UpsertConfirmationCheckoutConfigResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.core.private.companyconfig.companycheckoutconfig.UpsertConfirmationCheckoutConfigResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.core.private.companyconfig.companycheckoutconfig.UpsertConfirmationCheckoutConfigResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.core.private.companyconfig.companycheckoutconfig.UpsertTerminologiesCheckoutConfigRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.core.private.companyconfig.companycheckoutconfig.UpsertTerminologiesCheckoutConfigRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.core.private.companyconfig.companycheckoutconfig.UpsertTerminologiesCheckoutConfigRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.core.private.companyconfig.companycheckoutconfig.UpsertTerminologiesCheckoutConfigRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    companyDomain: jspb.Message.getFieldWithDefault(msg, 1, ""),
    terminologyAddonPlural: jspb.Message.getFieldWithDefault(msg, 2, ""),
    terminologyAddonSingular: jspb.Message.getFieldWithDefault(msg, 3, ""),
    terminologyAllowancePlural: jspb.Message.getFieldWithDefault(msg, 4, ""),
    terminologyAllowanceSingular: jspb.Message.getFieldWithDefault(msg, 5, ""),
    terminologyPlanPlural: jspb.Message.getFieldWithDefault(msg, 6, ""),
    terminologyPlanSingular: jspb.Message.getFieldWithDefault(msg, 7, ""),
    terminologyProductPlural: jspb.Message.getFieldWithDefault(msg, 8, ""),
    terminologyProductSingular: jspb.Message.getFieldWithDefault(msg, 9, ""),
    terminologySetupFeePlural: jspb.Message.getFieldWithDefault(msg, 10, ""),
    terminologySetupFeeSingular: jspb.Message.getFieldWithDefault(msg, 11, ""),
    terminologySubscriptionPlural: jspb.Message.getFieldWithDefault(msg, 12, ""),
    terminologySubscriptionSingular: jspb.Message.getFieldWithDefault(msg, 13, ""),
    terminologyUnitPlural: jspb.Message.getFieldWithDefault(msg, 14, ""),
    terminologyUnitSingular: jspb.Message.getFieldWithDefault(msg, 15, ""),
    terminologyDiscountPlural: jspb.Message.getFieldWithDefault(msg, 16, ""),
    terminologyDiscountSingular: jspb.Message.getFieldWithDefault(msg, 17, ""),
    terminologyCouponPlural: jspb.Message.getFieldWithDefault(msg, 18, ""),
    terminologyCouponSingular: jspb.Message.getFieldWithDefault(msg, 19, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.core.private.companyconfig.companycheckoutconfig.UpsertTerminologiesCheckoutConfigRequest}
 */
proto.core.private.companyconfig.companycheckoutconfig.UpsertTerminologiesCheckoutConfigRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.core.private.companyconfig.companycheckoutconfig.UpsertTerminologiesCheckoutConfigRequest;
  return proto.core.private.companyconfig.companycheckoutconfig.UpsertTerminologiesCheckoutConfigRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.core.private.companyconfig.companycheckoutconfig.UpsertTerminologiesCheckoutConfigRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.core.private.companyconfig.companycheckoutconfig.UpsertTerminologiesCheckoutConfigRequest}
 */
proto.core.private.companyconfig.companycheckoutconfig.UpsertTerminologiesCheckoutConfigRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setCompanyDomain(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setTerminologyAddonPlural(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setTerminologyAddonSingular(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setTerminologyAllowancePlural(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setTerminologyAllowanceSingular(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setTerminologyPlanPlural(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setTerminologyPlanSingular(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setTerminologyProductPlural(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setTerminologyProductSingular(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setTerminologySetupFeePlural(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.setTerminologySetupFeeSingular(value);
      break;
    case 12:
      var value = /** @type {string} */ (reader.readString());
      msg.setTerminologySubscriptionPlural(value);
      break;
    case 13:
      var value = /** @type {string} */ (reader.readString());
      msg.setTerminologySubscriptionSingular(value);
      break;
    case 14:
      var value = /** @type {string} */ (reader.readString());
      msg.setTerminologyUnitPlural(value);
      break;
    case 15:
      var value = /** @type {string} */ (reader.readString());
      msg.setTerminologyUnitSingular(value);
      break;
    case 16:
      var value = /** @type {string} */ (reader.readString());
      msg.setTerminologyDiscountPlural(value);
      break;
    case 17:
      var value = /** @type {string} */ (reader.readString());
      msg.setTerminologyDiscountSingular(value);
      break;
    case 18:
      var value = /** @type {string} */ (reader.readString());
      msg.setTerminologyCouponPlural(value);
      break;
    case 19:
      var value = /** @type {string} */ (reader.readString());
      msg.setTerminologyCouponSingular(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.core.private.companyconfig.companycheckoutconfig.UpsertTerminologiesCheckoutConfigRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.core.private.companyconfig.companycheckoutconfig.UpsertTerminologiesCheckoutConfigRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.core.private.companyconfig.companycheckoutconfig.UpsertTerminologiesCheckoutConfigRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.core.private.companyconfig.companycheckoutconfig.UpsertTerminologiesCheckoutConfigRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCompanyDomain();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getTerminologyAddonPlural();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getTerminologyAddonSingular();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getTerminologyAllowancePlural();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getTerminologyAllowanceSingular();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getTerminologyPlanPlural();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getTerminologyPlanSingular();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getTerminologyProductPlural();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getTerminologyProductSingular();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getTerminologySetupFeePlural();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getTerminologySetupFeeSingular();
  if (f.length > 0) {
    writer.writeString(
      11,
      f
    );
  }
  f = message.getTerminologySubscriptionPlural();
  if (f.length > 0) {
    writer.writeString(
      12,
      f
    );
  }
  f = message.getTerminologySubscriptionSingular();
  if (f.length > 0) {
    writer.writeString(
      13,
      f
    );
  }
  f = message.getTerminologyUnitPlural();
  if (f.length > 0) {
    writer.writeString(
      14,
      f
    );
  }
  f = message.getTerminologyUnitSingular();
  if (f.length > 0) {
    writer.writeString(
      15,
      f
    );
  }
  f = message.getTerminologyDiscountPlural();
  if (f.length > 0) {
    writer.writeString(
      16,
      f
    );
  }
  f = message.getTerminologyDiscountSingular();
  if (f.length > 0) {
    writer.writeString(
      17,
      f
    );
  }
  f = message.getTerminologyCouponPlural();
  if (f.length > 0) {
    writer.writeString(
      18,
      f
    );
  }
  f = message.getTerminologyCouponSingular();
  if (f.length > 0) {
    writer.writeString(
      19,
      f
    );
  }
};


/**
 * optional string company_domain = 1;
 * @return {string}
 */
proto.core.private.companyconfig.companycheckoutconfig.UpsertTerminologiesCheckoutConfigRequest.prototype.getCompanyDomain = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.core.private.companyconfig.companycheckoutconfig.UpsertTerminologiesCheckoutConfigRequest} returns this
 */
proto.core.private.companyconfig.companycheckoutconfig.UpsertTerminologiesCheckoutConfigRequest.prototype.setCompanyDomain = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string terminology_addon_plural = 2;
 * @return {string}
 */
proto.core.private.companyconfig.companycheckoutconfig.UpsertTerminologiesCheckoutConfigRequest.prototype.getTerminologyAddonPlural = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.core.private.companyconfig.companycheckoutconfig.UpsertTerminologiesCheckoutConfigRequest} returns this
 */
proto.core.private.companyconfig.companycheckoutconfig.UpsertTerminologiesCheckoutConfigRequest.prototype.setTerminologyAddonPlural = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string terminology_addon_singular = 3;
 * @return {string}
 */
proto.core.private.companyconfig.companycheckoutconfig.UpsertTerminologiesCheckoutConfigRequest.prototype.getTerminologyAddonSingular = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.core.private.companyconfig.companycheckoutconfig.UpsertTerminologiesCheckoutConfigRequest} returns this
 */
proto.core.private.companyconfig.companycheckoutconfig.UpsertTerminologiesCheckoutConfigRequest.prototype.setTerminologyAddonSingular = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string terminology_allowance_plural = 4;
 * @return {string}
 */
proto.core.private.companyconfig.companycheckoutconfig.UpsertTerminologiesCheckoutConfigRequest.prototype.getTerminologyAllowancePlural = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.core.private.companyconfig.companycheckoutconfig.UpsertTerminologiesCheckoutConfigRequest} returns this
 */
proto.core.private.companyconfig.companycheckoutconfig.UpsertTerminologiesCheckoutConfigRequest.prototype.setTerminologyAllowancePlural = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string terminology_allowance_singular = 5;
 * @return {string}
 */
proto.core.private.companyconfig.companycheckoutconfig.UpsertTerminologiesCheckoutConfigRequest.prototype.getTerminologyAllowanceSingular = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.core.private.companyconfig.companycheckoutconfig.UpsertTerminologiesCheckoutConfigRequest} returns this
 */
proto.core.private.companyconfig.companycheckoutconfig.UpsertTerminologiesCheckoutConfigRequest.prototype.setTerminologyAllowanceSingular = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string terminology_plan_plural = 6;
 * @return {string}
 */
proto.core.private.companyconfig.companycheckoutconfig.UpsertTerminologiesCheckoutConfigRequest.prototype.getTerminologyPlanPlural = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.core.private.companyconfig.companycheckoutconfig.UpsertTerminologiesCheckoutConfigRequest} returns this
 */
proto.core.private.companyconfig.companycheckoutconfig.UpsertTerminologiesCheckoutConfigRequest.prototype.setTerminologyPlanPlural = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string terminology_plan_singular = 7;
 * @return {string}
 */
proto.core.private.companyconfig.companycheckoutconfig.UpsertTerminologiesCheckoutConfigRequest.prototype.getTerminologyPlanSingular = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.core.private.companyconfig.companycheckoutconfig.UpsertTerminologiesCheckoutConfigRequest} returns this
 */
proto.core.private.companyconfig.companycheckoutconfig.UpsertTerminologiesCheckoutConfigRequest.prototype.setTerminologyPlanSingular = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string terminology_product_plural = 8;
 * @return {string}
 */
proto.core.private.companyconfig.companycheckoutconfig.UpsertTerminologiesCheckoutConfigRequest.prototype.getTerminologyProductPlural = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.core.private.companyconfig.companycheckoutconfig.UpsertTerminologiesCheckoutConfigRequest} returns this
 */
proto.core.private.companyconfig.companycheckoutconfig.UpsertTerminologiesCheckoutConfigRequest.prototype.setTerminologyProductPlural = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional string terminology_product_singular = 9;
 * @return {string}
 */
proto.core.private.companyconfig.companycheckoutconfig.UpsertTerminologiesCheckoutConfigRequest.prototype.getTerminologyProductSingular = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.core.private.companyconfig.companycheckoutconfig.UpsertTerminologiesCheckoutConfigRequest} returns this
 */
proto.core.private.companyconfig.companycheckoutconfig.UpsertTerminologiesCheckoutConfigRequest.prototype.setTerminologyProductSingular = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional string terminology_setup_fee_plural = 10;
 * @return {string}
 */
proto.core.private.companyconfig.companycheckoutconfig.UpsertTerminologiesCheckoutConfigRequest.prototype.getTerminologySetupFeePlural = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.core.private.companyconfig.companycheckoutconfig.UpsertTerminologiesCheckoutConfigRequest} returns this
 */
proto.core.private.companyconfig.companycheckoutconfig.UpsertTerminologiesCheckoutConfigRequest.prototype.setTerminologySetupFeePlural = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * optional string terminology_setup_fee_singular = 11;
 * @return {string}
 */
proto.core.private.companyconfig.companycheckoutconfig.UpsertTerminologiesCheckoutConfigRequest.prototype.getTerminologySetupFeeSingular = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};


/**
 * @param {string} value
 * @return {!proto.core.private.companyconfig.companycheckoutconfig.UpsertTerminologiesCheckoutConfigRequest} returns this
 */
proto.core.private.companyconfig.companycheckoutconfig.UpsertTerminologiesCheckoutConfigRequest.prototype.setTerminologySetupFeeSingular = function(value) {
  return jspb.Message.setProto3StringField(this, 11, value);
};


/**
 * optional string terminology_subscription_plural = 12;
 * @return {string}
 */
proto.core.private.companyconfig.companycheckoutconfig.UpsertTerminologiesCheckoutConfigRequest.prototype.getTerminologySubscriptionPlural = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 12, ""));
};


/**
 * @param {string} value
 * @return {!proto.core.private.companyconfig.companycheckoutconfig.UpsertTerminologiesCheckoutConfigRequest} returns this
 */
proto.core.private.companyconfig.companycheckoutconfig.UpsertTerminologiesCheckoutConfigRequest.prototype.setTerminologySubscriptionPlural = function(value) {
  return jspb.Message.setProto3StringField(this, 12, value);
};


/**
 * optional string terminology_subscription_singular = 13;
 * @return {string}
 */
proto.core.private.companyconfig.companycheckoutconfig.UpsertTerminologiesCheckoutConfigRequest.prototype.getTerminologySubscriptionSingular = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 13, ""));
};


/**
 * @param {string} value
 * @return {!proto.core.private.companyconfig.companycheckoutconfig.UpsertTerminologiesCheckoutConfigRequest} returns this
 */
proto.core.private.companyconfig.companycheckoutconfig.UpsertTerminologiesCheckoutConfigRequest.prototype.setTerminologySubscriptionSingular = function(value) {
  return jspb.Message.setProto3StringField(this, 13, value);
};


/**
 * optional string terminology_unit_plural = 14;
 * @return {string}
 */
proto.core.private.companyconfig.companycheckoutconfig.UpsertTerminologiesCheckoutConfigRequest.prototype.getTerminologyUnitPlural = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 14, ""));
};


/**
 * @param {string} value
 * @return {!proto.core.private.companyconfig.companycheckoutconfig.UpsertTerminologiesCheckoutConfigRequest} returns this
 */
proto.core.private.companyconfig.companycheckoutconfig.UpsertTerminologiesCheckoutConfigRequest.prototype.setTerminologyUnitPlural = function(value) {
  return jspb.Message.setProto3StringField(this, 14, value);
};


/**
 * optional string terminology_unit_singular = 15;
 * @return {string}
 */
proto.core.private.companyconfig.companycheckoutconfig.UpsertTerminologiesCheckoutConfigRequest.prototype.getTerminologyUnitSingular = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 15, ""));
};


/**
 * @param {string} value
 * @return {!proto.core.private.companyconfig.companycheckoutconfig.UpsertTerminologiesCheckoutConfigRequest} returns this
 */
proto.core.private.companyconfig.companycheckoutconfig.UpsertTerminologiesCheckoutConfigRequest.prototype.setTerminologyUnitSingular = function(value) {
  return jspb.Message.setProto3StringField(this, 15, value);
};


/**
 * optional string terminology_discount_plural = 16;
 * @return {string}
 */
proto.core.private.companyconfig.companycheckoutconfig.UpsertTerminologiesCheckoutConfigRequest.prototype.getTerminologyDiscountPlural = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 16, ""));
};


/**
 * @param {string} value
 * @return {!proto.core.private.companyconfig.companycheckoutconfig.UpsertTerminologiesCheckoutConfigRequest} returns this
 */
proto.core.private.companyconfig.companycheckoutconfig.UpsertTerminologiesCheckoutConfigRequest.prototype.setTerminologyDiscountPlural = function(value) {
  return jspb.Message.setProto3StringField(this, 16, value);
};


/**
 * optional string terminology_discount_singular = 17;
 * @return {string}
 */
proto.core.private.companyconfig.companycheckoutconfig.UpsertTerminologiesCheckoutConfigRequest.prototype.getTerminologyDiscountSingular = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 17, ""));
};


/**
 * @param {string} value
 * @return {!proto.core.private.companyconfig.companycheckoutconfig.UpsertTerminologiesCheckoutConfigRequest} returns this
 */
proto.core.private.companyconfig.companycheckoutconfig.UpsertTerminologiesCheckoutConfigRequest.prototype.setTerminologyDiscountSingular = function(value) {
  return jspb.Message.setProto3StringField(this, 17, value);
};


/**
 * optional string terminology_coupon_plural = 18;
 * @return {string}
 */
proto.core.private.companyconfig.companycheckoutconfig.UpsertTerminologiesCheckoutConfigRequest.prototype.getTerminologyCouponPlural = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 18, ""));
};


/**
 * @param {string} value
 * @return {!proto.core.private.companyconfig.companycheckoutconfig.UpsertTerminologiesCheckoutConfigRequest} returns this
 */
proto.core.private.companyconfig.companycheckoutconfig.UpsertTerminologiesCheckoutConfigRequest.prototype.setTerminologyCouponPlural = function(value) {
  return jspb.Message.setProto3StringField(this, 18, value);
};


/**
 * optional string terminology_coupon_singular = 19;
 * @return {string}
 */
proto.core.private.companyconfig.companycheckoutconfig.UpsertTerminologiesCheckoutConfigRequest.prototype.getTerminologyCouponSingular = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 19, ""));
};


/**
 * @param {string} value
 * @return {!proto.core.private.companyconfig.companycheckoutconfig.UpsertTerminologiesCheckoutConfigRequest} returns this
 */
proto.core.private.companyconfig.companycheckoutconfig.UpsertTerminologiesCheckoutConfigRequest.prototype.setTerminologyCouponSingular = function(value) {
  return jspb.Message.setProto3StringField(this, 19, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.core.private.companyconfig.companycheckoutconfig.UpsertTerminologiesCheckoutConfigResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.core.private.companyconfig.companycheckoutconfig.UpsertTerminologiesCheckoutConfigResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.core.private.companyconfig.companycheckoutconfig.UpsertTerminologiesCheckoutConfigResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.core.private.companyconfig.companycheckoutconfig.UpsertTerminologiesCheckoutConfigResponse.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.core.private.companyconfig.companycheckoutconfig.UpsertTerminologiesCheckoutConfigResponse}
 */
proto.core.private.companyconfig.companycheckoutconfig.UpsertTerminologiesCheckoutConfigResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.core.private.companyconfig.companycheckoutconfig.UpsertTerminologiesCheckoutConfigResponse;
  return proto.core.private.companyconfig.companycheckoutconfig.UpsertTerminologiesCheckoutConfigResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.core.private.companyconfig.companycheckoutconfig.UpsertTerminologiesCheckoutConfigResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.core.private.companyconfig.companycheckoutconfig.UpsertTerminologiesCheckoutConfigResponse}
 */
proto.core.private.companyconfig.companycheckoutconfig.UpsertTerminologiesCheckoutConfigResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.core.private.companyconfig.companycheckoutconfig.UpsertTerminologiesCheckoutConfigResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.core.private.companyconfig.companycheckoutconfig.UpsertTerminologiesCheckoutConfigResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.core.private.companyconfig.companycheckoutconfig.UpsertTerminologiesCheckoutConfigResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.core.private.companyconfig.companycheckoutconfig.UpsertTerminologiesCheckoutConfigResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.core.private.companyconfig.companycheckoutconfig.UpdateCheckoutDefaultLanguageRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.core.private.companyconfig.companycheckoutconfig.UpdateCheckoutDefaultLanguageRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.core.private.companyconfig.companycheckoutconfig.UpdateCheckoutDefaultLanguageRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.core.private.companyconfig.companycheckoutconfig.UpdateCheckoutDefaultLanguageRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    companyId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    languageIsoCode: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.core.private.companyconfig.companycheckoutconfig.UpdateCheckoutDefaultLanguageRequest}
 */
proto.core.private.companyconfig.companycheckoutconfig.UpdateCheckoutDefaultLanguageRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.core.private.companyconfig.companycheckoutconfig.UpdateCheckoutDefaultLanguageRequest;
  return proto.core.private.companyconfig.companycheckoutconfig.UpdateCheckoutDefaultLanguageRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.core.private.companyconfig.companycheckoutconfig.UpdateCheckoutDefaultLanguageRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.core.private.companyconfig.companycheckoutconfig.UpdateCheckoutDefaultLanguageRequest}
 */
proto.core.private.companyconfig.companycheckoutconfig.UpdateCheckoutDefaultLanguageRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setCompanyId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setLanguageIsoCode(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.core.private.companyconfig.companycheckoutconfig.UpdateCheckoutDefaultLanguageRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.core.private.companyconfig.companycheckoutconfig.UpdateCheckoutDefaultLanguageRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.core.private.companyconfig.companycheckoutconfig.UpdateCheckoutDefaultLanguageRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.core.private.companyconfig.companycheckoutconfig.UpdateCheckoutDefaultLanguageRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCompanyId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getLanguageIsoCode();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional int32 company_id = 1;
 * @return {number}
 */
proto.core.private.companyconfig.companycheckoutconfig.UpdateCheckoutDefaultLanguageRequest.prototype.getCompanyId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.core.private.companyconfig.companycheckoutconfig.UpdateCheckoutDefaultLanguageRequest} returns this
 */
proto.core.private.companyconfig.companycheckoutconfig.UpdateCheckoutDefaultLanguageRequest.prototype.setCompanyId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string language_iso_code = 2;
 * @return {string}
 */
proto.core.private.companyconfig.companycheckoutconfig.UpdateCheckoutDefaultLanguageRequest.prototype.getLanguageIsoCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.core.private.companyconfig.companycheckoutconfig.UpdateCheckoutDefaultLanguageRequest} returns this
 */
proto.core.private.companyconfig.companycheckoutconfig.UpdateCheckoutDefaultLanguageRequest.prototype.setLanguageIsoCode = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.core.private.companyconfig.companycheckoutconfig.UpdateCheckoutDefaultLanguageResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.core.private.companyconfig.companycheckoutconfig.UpdateCheckoutDefaultLanguageResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.core.private.companyconfig.companycheckoutconfig.UpdateCheckoutDefaultLanguageResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.core.private.companyconfig.companycheckoutconfig.UpdateCheckoutDefaultLanguageResponse.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.core.private.companyconfig.companycheckoutconfig.UpdateCheckoutDefaultLanguageResponse}
 */
proto.core.private.companyconfig.companycheckoutconfig.UpdateCheckoutDefaultLanguageResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.core.private.companyconfig.companycheckoutconfig.UpdateCheckoutDefaultLanguageResponse;
  return proto.core.private.companyconfig.companycheckoutconfig.UpdateCheckoutDefaultLanguageResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.core.private.companyconfig.companycheckoutconfig.UpdateCheckoutDefaultLanguageResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.core.private.companyconfig.companycheckoutconfig.UpdateCheckoutDefaultLanguageResponse}
 */
proto.core.private.companyconfig.companycheckoutconfig.UpdateCheckoutDefaultLanguageResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.core.private.companyconfig.companycheckoutconfig.UpdateCheckoutDefaultLanguageResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.core.private.companyconfig.companycheckoutconfig.UpdateCheckoutDefaultLanguageResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.core.private.companyconfig.companycheckoutconfig.UpdateCheckoutDefaultLanguageResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.core.private.companyconfig.companycheckoutconfig.UpdateCheckoutDefaultLanguageResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.core.private.companyconfig.companycheckoutconfig.UpdateAccountManagementLanguageRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.core.private.companyconfig.companycheckoutconfig.UpdateAccountManagementLanguageRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.core.private.companyconfig.companycheckoutconfig.UpdateAccountManagementLanguageRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.core.private.companyconfig.companycheckoutconfig.UpdateAccountManagementLanguageRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    companyId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    languageIsoCode: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.core.private.companyconfig.companycheckoutconfig.UpdateAccountManagementLanguageRequest}
 */
proto.core.private.companyconfig.companycheckoutconfig.UpdateAccountManagementLanguageRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.core.private.companyconfig.companycheckoutconfig.UpdateAccountManagementLanguageRequest;
  return proto.core.private.companyconfig.companycheckoutconfig.UpdateAccountManagementLanguageRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.core.private.companyconfig.companycheckoutconfig.UpdateAccountManagementLanguageRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.core.private.companyconfig.companycheckoutconfig.UpdateAccountManagementLanguageRequest}
 */
proto.core.private.companyconfig.companycheckoutconfig.UpdateAccountManagementLanguageRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setCompanyId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setLanguageIsoCode(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.core.private.companyconfig.companycheckoutconfig.UpdateAccountManagementLanguageRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.core.private.companyconfig.companycheckoutconfig.UpdateAccountManagementLanguageRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.core.private.companyconfig.companycheckoutconfig.UpdateAccountManagementLanguageRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.core.private.companyconfig.companycheckoutconfig.UpdateAccountManagementLanguageRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCompanyId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getLanguageIsoCode();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional int32 company_id = 1;
 * @return {number}
 */
proto.core.private.companyconfig.companycheckoutconfig.UpdateAccountManagementLanguageRequest.prototype.getCompanyId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.core.private.companyconfig.companycheckoutconfig.UpdateAccountManagementLanguageRequest} returns this
 */
proto.core.private.companyconfig.companycheckoutconfig.UpdateAccountManagementLanguageRequest.prototype.setCompanyId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string language_iso_code = 2;
 * @return {string}
 */
proto.core.private.companyconfig.companycheckoutconfig.UpdateAccountManagementLanguageRequest.prototype.getLanguageIsoCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.core.private.companyconfig.companycheckoutconfig.UpdateAccountManagementLanguageRequest} returns this
 */
proto.core.private.companyconfig.companycheckoutconfig.UpdateAccountManagementLanguageRequest.prototype.setLanguageIsoCode = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.core.private.companyconfig.companycheckoutconfig.UpdateAccountManagementLanguageResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.core.private.companyconfig.companycheckoutconfig.UpdateAccountManagementLanguageResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.core.private.companyconfig.companycheckoutconfig.UpdateAccountManagementLanguageResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.core.private.companyconfig.companycheckoutconfig.UpdateAccountManagementLanguageResponse.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.core.private.companyconfig.companycheckoutconfig.UpdateAccountManagementLanguageResponse}
 */
proto.core.private.companyconfig.companycheckoutconfig.UpdateAccountManagementLanguageResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.core.private.companyconfig.companycheckoutconfig.UpdateAccountManagementLanguageResponse;
  return proto.core.private.companyconfig.companycheckoutconfig.UpdateAccountManagementLanguageResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.core.private.companyconfig.companycheckoutconfig.UpdateAccountManagementLanguageResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.core.private.companyconfig.companycheckoutconfig.UpdateAccountManagementLanguageResponse}
 */
proto.core.private.companyconfig.companycheckoutconfig.UpdateAccountManagementLanguageResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.core.private.companyconfig.companycheckoutconfig.UpdateAccountManagementLanguageResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.core.private.companyconfig.companycheckoutconfig.UpdateAccountManagementLanguageResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.core.private.companyconfig.companycheckoutconfig.UpdateAccountManagementLanguageResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.core.private.companyconfig.companycheckoutconfig.UpdateAccountManagementLanguageResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};


goog.object.extend(exports, proto.core.private.companyconfig.companycheckoutconfig);
