import React, { useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { AppState } from "../../../..";
import { AccountingIntegrationType } from "../../../../models/AccountingIntegrations";
import { IAccountingIntegrationsReducerState } from "../../../../reducers/accountingIntegrationsReducer";
import CollapsablePanelWithArrow from "../../../ui/collapsable-panel-with-arrow/CollapsablePanelWithArrow";
import ErrorPanel from "../../../ui/error-panel/ErrorPanel";
import Panel from "../../../ui/panel/Panel";
import Text from "../../../ui/text/Text";
import "./QuickBooksFaq.scss";



export const QuickBooksFaq: React.FC = () => {

  const { integrations } = useSelector<AppState, IAccountingIntegrationsReducerState>(state => state.accountingIntegrationsReducer);
  const [collapsedPanel, setCollapsedPanel] = useState([false, true, true, true, true]);
  const [collapsedHelpPanel, setCollapsedHelpPanel] = useState(false);

  const isInstalled = useMemo(() => {
    const quickbooksIntegrations = integrations.getIntegrationsList()
      .filter(i => i.getName() === AccountingIntegrationType.QUICKBOOKS);
    if (quickbooksIntegrations.length > 0) {
      return quickbooksIntegrations[0].getIsInstalled()
    }

    return false;
  }, [integrations]);

  if (isInstalled)
    return (
      <div className="quick-books-faq">
        <CollapsablePanelWithArrow
          isCollapsed={collapsedHelpPanel}
          onClick={() => setCollapsedHelpPanel(!collapsedHelpPanel)}
          className="quick-books-faq__panel"
          title="INTEGRATIONS_FAQS">
          <Text content="INTEGRATION_QUICKBOOKS_FAQ_PANEL1_TITLE" className="quick-books-faq__text text-title" />
          <Text content="INTEGRATION_QUICKBOOKS_FAQ_PANEL1_TEXT" className="quick-books-faq__text" noMargin />
          <Text content="INTEGRATION_QUICKBOOKS_FAQ_PANEL1_TEXT_1" className="quick-books-faq__text" />

          <Text content="INTEGRATION_QUICKBOOKS_FAQ_PANEL2_TITLE" className="quick-books-faq__text text-title" />
          <Text content="INTEGRATION_QUICKBOOKS_FAQ_PANEL2_TEXT" className="quick-books-faq__text" />

          <Text content="INTEGRATION_QUICKBOOKS_FAQ_PANEL3_TITLE" className="quick-books-faq__text text-title" />
          <Text content="INTEGRATION_QUICKBOOKS_FAQ_PANEL3_TEXT" className="quick-books-faq__text" />

          <Text content="INTEGRATION_QUICKBOOKS_FAQ_PANEL4_TITLE" className="quick-books-faq__text text-title" />
          <Text content="INTEGRATION_QUICKBOOKS_FAQ_PANEL4_TEXT" className="quick-books-faq__text" />

          <Text content="INTEGRATION_QUICKBOOKS_FAQ_PANEL5_TITLE" className="quick-books-faq__text text-title" />
          <Text content="INTEGRATION_QUICKBOOKS_FAQ_PANEL5_TEXT" className="quick-books-faq__text text-inline" />
          <Text
            content="INTEGRATION_QUICKBOOKS_FAQ_PANEL5_LINK_TEXT"
            className="quick-books-faq__text text-link text-inline"
            onClick={() => window.open("https://security.intuit.com/", "_self")}
          />
          <Text
            content="INTEGRATION_QUICKBOOKS_FAQ_PANEL5_DOT_TEXT"
            className="quick-books-faq__text text-inline"
          />
        </CollapsablePanelWithArrow>

        <Panel title="INTEGRATION_CONTACT_QUICKBOOKS">
          <Text content="INTEGRATION_CONTACT_QUICKBOOKS_TEXT" className="quick-books-faq__text" />
          <i className="button-icon fa fa-globe-americas left quick-books-faq__text text-title text-inline"></i>
          <Text
            content="INTEGRATION_CONTACT_QUICKBOOKS_SUPPORT_PAGE"
            className="quick-books-faq__text text-title text-link text-inline"
            onClick={() => window.open("https://help.quickbooks.intuit.com/en_US/contact", "_self")}
          />
        </Panel>
      </div>
    )

  return (
    <div className="quick-books-faq">
      <CollapsablePanelWithArrow
        isCollapsed={collapsedPanel[0]}
        onClick={() => setCollapsedPanel([false, true, true, true, true])}
        className="quick-books-faq__panel"
        title="INTEGRATION_QUICKBOOKS_FAQ_PANEL1_TITLE">
        <Text content="INTEGRATION_QUICKBOOKS_FAQ_PANEL1_TEXT" noMargin />
        <Text content="INTEGRATION_QUICKBOOKS_FAQ_PANEL1_TEXT_1" noMargin />
      </CollapsablePanelWithArrow>

      <CollapsablePanelWithArrow
        isCollapsed={collapsedPanel[1]}
        onClick={() => setCollapsedPanel([true, false, true, true, true])}
        className="quick-books-faq__panel"
        title="INTEGRATION_QUICKBOOKS_FAQ_PANEL2_TITLE">
        <Text content="INTEGRATION_QUICKBOOKS_FAQ_PANEL2_TEXT" noMargin />
      </CollapsablePanelWithArrow>

      <CollapsablePanelWithArrow
        isCollapsed={collapsedPanel[2]}
        onClick={() => setCollapsedPanel([true, true, false, true, true])}
        className="quick-books-faq__panel"
        title="INTEGRATION_QUICKBOOKS_FAQ_PANEL3_TITLE">
        <Text content="INTEGRATION_QUICKBOOKS_FAQ_PANEL3_TEXT" noMargin />
      </CollapsablePanelWithArrow>

      <CollapsablePanelWithArrow
        isCollapsed={collapsedPanel[3]}
        onClick={() => setCollapsedPanel([true, true, true, false, true])}
        className="quick-books-faq__panel"
        title="INTEGRATION_QUICKBOOKS_FAQ_PANEL4_TITLE">
        <Text content="INTEGRATION_QUICKBOOKS_FAQ_PANEL4_TEXT" noMargin />
      </CollapsablePanelWithArrow>

      <CollapsablePanelWithArrow
        isCollapsed={collapsedPanel[4]}
        onClick={() => setCollapsedPanel([true, true, true, true, false])}
        className="quick-books-faq__panel"
        title="INTEGRATION_QUICKBOOKS_FAQ_PANEL5_TITLE">
        <Text
            content="INTEGRATION_QUICKBOOKS_FAQ_PANEL5_TEXT"
            noMargin            
            className="quick-books-faq__text text-inline"
          />
          <Text
            content="INTEGRATION_QUICKBOOKS_FAQ_PANEL5_LINK_TEXT"
            className="quick-books-faq__text text-link text-inline"
            onClick={() => window.open("https://security.intuit.com/", "_self")}
          />
          <Text
            content="INTEGRATION_QUICKBOOKS_FAQ_PANEL5_DOT_TEXT"
            className="quick-books-faq__text text-inline"
          />
      </CollapsablePanelWithArrow>

      <ErrorPanel
        title="INTEGRATION_FAQ_TALK_US_TEXT1"
        subtitle="INTEGRATION_FAQ_TALK_US_TEXT2"
        icon="fas fa-question-circle"
      />
    </div>
  )
}

export default QuickBooksFaq