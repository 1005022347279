import { BillsbyAction } from "../models/BillsbyAction";
import {
  FETCH_CUSTOMER_DETAILS_SUCCESS,
  FETCH_CUSTOMER_CREDIT_NOTES_SUCCESS,
  FETCH_CUSTOMER_INVOICES_SUCCESS,
  RESET_CUSTOMER_DETAILS,
  FETCH_CUSTOMER_CARD_DETAILS_SUCCESS,
  SET_CUSTOMER_DETAILS_FIELD,
  RESET_CUSTOMER_INVOICES,
  RESET_CUSTOMER_CREDIT_NOTES,
  FETCH_CUSTOMER_TAX_VALIDATIONS_SUCCESS,
  FETCH_SPREEDLY_PAYMENT_LOG_SUCCESS,
  RESET_SPREEDLY_PAYMENT_LOG,
  FETCH_CUSTOMER_NOTE_SUCCESS,
  FETCH_CUSTOMER_LANGUAGES_SUCCESS,
  FETCH_CUSTOMER_LANGUAGES_REQUEST,
  FETCH_CUSTOMER_LANGUAGES_FAILURE,
  UPDATE_CUSTOMER_CARD_DETAILS_SUCCESS,
  UPDATE_CUSTOMER_CARD_DETAILS_REQUEST,
  UPDATE_CUSTOMER_CARD_DETAILS_FAILURE
} from "../actions/customerDetailsActions";
import { ICustomerCreditNoteData, CardStatus, CustomerDataStatus, PaymentMethodType } from "../models/Customer";
import { AppState } from "..";

import {
  ICustomerInvoiceData,
  ICustomerInvoicePaymentLogData
} from "../models/Customer";
import { GetCustomerPaymentDetailsResponse, ViesVatValidation, GetViesVatValidationsResponse, GetCustomerNotesResponse, GetCustomerLanguageResponse } from "../utils/grpc/generated/Billsby.Protos/billing/private/customer/customer_pb";
import { GetSpreedlyTransactionResponse } from "../utils/grpc/generated/Billsby.Protos/billing/private/paymentgateway/payment_gateway_pb";
import moment from "moment";

export interface ICustomerDetailsReducerState {
  companyCurrency: string,
  totalAmountPaid: number,
  averageAmountPerMonth: number,
  customerTaxRegNumber: string,
  customerUniqueId: string,
  firstName: string,
  lastName: string,
  billingAddress: {
    addressLine1: string,
    addressLine2: string,
    state: string,
    city: string,
    country: string,
    postCode: string,
  },
  email: string,
  additionalEmail: string,
  phoneNumber: string,
  phoneNumberDialCode: string,
  phoneNumberDialCountry: string,
  isMarketingConsentGiven: boolean,
  createdOn: Date,
  avatar: string,
  creditNotes: Array<ICustomerCreditNoteData>,
  invoiceData: Array<ICustomerInvoiceData>,
  invoicePaymentData: Array<ICustomerInvoicePaymentLogData>,
  taxValidations: Array<ViesVatValidation>
  cardStatus: CardStatus,
  status: CustomerDataStatus,
  cardDetails: {
    lastFourDigits: string,
    cardType: string,
    expiryMonth: number,
    expiryYear: number,
    paymentMethodType: PaymentMethodType,
    bankName: string,
    fullName: string,
    createdOn?: string
  },
  showReplacePaymentModal: boolean
  paymentLog: string,
  customerNotes: GetCustomerNotesResponse,
  customerLanguages: GetCustomerLanguageResponse,
  loadingCustomerLanguage: boolean,
  showChangeCustomerLanguage: boolean,
  isUpdatingCustomerCardDetails: boolean,
  hasErrorUpdatingCustomerCardDetails: boolean,
  companyName?: string
}

export const initialState = {
  companyCurrency: "",
  totalAmountPaid: 0,
  averageAmountPerMonth: 0,
  customerTaxRegNumber: "",
  customerUniqueId: "",
  firstName: "",
  lastName: "",
  billingAddress: {
    addressLine1: "",
    addressLine2: "",
    state: "",
    city: "",
    country: "",
    postCode: ""
  },
  email: "",
  additionalEmail: "",
  phoneNumber: "",
  phoneNumberDialCode: "",
  phoneNumberDialCountry: "",
  isMarketingConsentGiven: false,
  createdOn: new Date(),
  uniqueId: "",
  avatar: "",
  creditNotes: [],
  invoiceData: [],
  invoicePaymentData: [],
  cardStatus: CardStatus.VALID,
  status: CustomerDataStatus.ACTIVE,
  taxValidations: [],
  cardDetails: {
    lastFourDigits: "",
    cardType: "",
    expiryMonth: 0,
    expiryYear: 0,
    fullName: "",
    bankName: "",
    paymentMethodType: PaymentMethodType.CREDIT_CARD,
  },
  showReplacePaymentModal: false,
  paymentLog: "",
  customerNotes: new GetCustomerNotesResponse(),
  customerLanguages: new GetCustomerLanguageResponse(),
  loadingCustomerLanguage: false,
  showChangeCustomerLanguage: false,
  isUpdatingCustomerCardDetails: false,
  hasErrorUpdatingCustomerCardDetails: false
};

export default function customerDetailsReducer(
  state: ICustomerDetailsReducerState = initialState,
  action: BillsbyAction,
  store: AppState
) {
  switch (action.type) {
    case FETCH_CUSTOMER_DETAILS_SUCCESS:
      const customerDetails = action.response;
      return { ...state, ...customerDetails };
    case FETCH_CUSTOMER_CREDIT_NOTES_SUCCESS: {
      return { ...state, creditNotes: action.response };
    }
    case FETCH_CUSTOMER_INVOICES_SUCCESS: {
      return { ...state, invoiceData: action.response };
    }
    case RESET_CUSTOMER_INVOICES: {
      return { ...state, invoiceData: [] }
    }
    case RESET_CUSTOMER_CREDIT_NOTES: {
      return { ...state, creditNotes: [] }
    }
    case FETCH_CUSTOMER_CARD_DETAILS_SUCCESS: {
      const cardDetails = action.response as GetCustomerPaymentDetailsResponse;

      return {
        ...state, cardDetails: {
          lastFourDigits: cardDetails.getLastFourDigits(),
          cardType: cardDetails.getCardType(),
          expiryMonth: cardDetails.getExpiryMonth(),
          expiryYear: cardDetails.getExpiryYear(),
          fullName: cardDetails.getFullName(),
          bankName: cardDetails.getBankName(),
          paymentMethodType: cardDetails.getPaymentMethodType(),
          createdOn: moment(cardDetails.getCreatedOn()?.toDate()).format("DD MMM YYYY")
        }
      };
    }
    case UPDATE_CUSTOMER_CARD_DETAILS_REQUEST: {
      return { ...state, isUpdatingCustomerCardDetails: true }
    }
    case UPDATE_CUSTOMER_CARD_DETAILS_FAILURE: {
      return { ...state, isUpdatingCustomerCardDetails: false, hasErrorUpdatingCustomerCardDetails: true }
    }
    case UPDATE_CUSTOMER_CARD_DETAILS_SUCCESS: {
      return { ...state, isUpdatingCustomerCardDetails: false, hasErrorUpdatingCustomerCardDetails: false }
    }
    case FETCH_CUSTOMER_TAX_VALIDATIONS_SUCCESS: {
      const taxValidations = action.response as GetViesVatValidationsResponse;
      return { ...state, taxValidations: taxValidations.getResultsList() }
    }
    case FETCH_SPREEDLY_PAYMENT_LOG_SUCCESS: {
      const paymentLog = action.response as GetSpreedlyTransactionResponse;
      return { ...state, paymentLog: JSON.stringify(paymentLog.toObject(), null, 2) }
    }
    case RESET_SPREEDLY_PAYMENT_LOG: {
      return { ...state, paymentLog: "" }
    }
    case SET_CUSTOMER_DETAILS_FIELD: {
      return { ...state, [action.fieldName]: action.fieldValue }
    }
    case FETCH_CUSTOMER_NOTE_SUCCESS: {
      const customerNotes = action.response as GetCustomerNotesResponse;
      return { ...state, customerNotes }
    }
    case FETCH_CUSTOMER_LANGUAGES_REQUEST: {
      return { ...state, loadingCustomerLanguage: true }
    }
    case FETCH_CUSTOMER_LANGUAGES_FAILURE: {
      return { ...state, loadingCustomerLanguage: false }
    }
    case FETCH_CUSTOMER_LANGUAGES_SUCCESS: {
      const customerLanguages = action.response as GetCustomerLanguageResponse;
      return { ...state, customerLanguages, loadingCustomerLanguage: false }
    }
    case RESET_CUSTOMER_DETAILS:
      return { ...initialState };
    default:
      return state;
  }
}
