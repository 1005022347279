import { BillsbyAction } from "../models/BillsbyAction";
import { AppState } from "..";
import { FETCH_ACH_MICRODEPOSIT_SETTINGS_FAILURE, FETCH_ACH_MICRODEPOSIT_SETTINGS_REQUEST, FETCH_ACH_MICRODEPOSIT_SETTINGS_SUCCESS, FETCH_ACH_PAYMENT_CONFIG_FAILURE, FETCH_ACH_PAYMENT_CONFIG_REQUEST, FETCH_ACH_PAYMENT_CONFIG_SUCCESS, SET_ACH_SETTINGS_FIELD, UPDATE_ACH_MICRODEPOSIT_SETTINGS_FAILURE, UPDATE_ACH_MICRODEPOSIT_SETTINGS_REQUEST, UPDATE_ACH_MICRODEPOSIT_SETTINGS_SUCCESS, UPDATE_ACH_PAYMENT_CONFIG_FAILURE, UPDATE_ACH_PAYMENT_CONFIG_REQUEST, UPDATE_ACH_PAYMENT_CONFIG_SUCCESS } from "../actions/achSettingsActions";
import { GetAchMicroDepositEmailConfigResponse } from "../utils/grpc/generated/Billsby.Protos/Messenger/private/companyconfig/companyconfig_pb";
import { GetAchPaymentConfigResponse } from "../utils/grpc/generated/Billsby.Protos/billing/private/companyconfig/company_config_pb";

export interface IAchSettingsReducer {
  bccEmail: string,
  isValidEmail: boolean,
  isEnabledAchNotifications: boolean,
  isAchPaymentActive: boolean,
  isAchPaymentAvailable: boolean,
  isLoading: boolean,
  isSubmittingMicroDepositConfig: boolean,
  isSubmittingPaymentConfig: boolean
}

export const initState: IAchSettingsReducer = {
  bccEmail: "",
  isValidEmail: true,
  isEnabledAchNotifications: false,
  isAchPaymentActive: false,
  isAchPaymentAvailable: false,
  isLoading: false,
  isSubmittingMicroDepositConfig: false,
  isSubmittingPaymentConfig: false
}

export default function achSettingsReducer(state: IAchSettingsReducer = initState, action: BillsbyAction, store: AppState): IAchSettingsReducer {
  switch (action.type) {
    case SET_ACH_SETTINGS_FIELD:
      return { ...state, [action.fieldName]: action.fieldValue}

    case FETCH_ACH_MICRODEPOSIT_SETTINGS_REQUEST:
      return { ...state, isLoading: true }
    case FETCH_ACH_MICRODEPOSIT_SETTINGS_SUCCESS: {
      const response = action.response as GetAchMicroDepositEmailConfigResponse
      return { ...state, isLoading: false, bccEmail: response.getBccEmail(), isEnabledAchNotifications: response.getIsEnabled()}
    }
    case FETCH_ACH_MICRODEPOSIT_SETTINGS_FAILURE:
      return { ...state, isLoading: false }

    case UPDATE_ACH_MICRODEPOSIT_SETTINGS_REQUEST:
      return { ...state, isSubmittingMicroDepositConfig: true }
    case UPDATE_ACH_MICRODEPOSIT_SETTINGS_SUCCESS:
      return { ...state, isSubmittingMicroDepositConfig: false }
    case UPDATE_ACH_MICRODEPOSIT_SETTINGS_FAILURE:
      return { ...state, isSubmittingMicroDepositConfig: false }


    case FETCH_ACH_PAYMENT_CONFIG_REQUEST:
      return { ...state, isLoading: true }
    case FETCH_ACH_PAYMENT_CONFIG_SUCCESS: {
      const response = action.response as GetAchPaymentConfigResponse
      return { ...state, isLoading: false, isAchPaymentActive: response.getIsAchPaymentsActive(), isAchPaymentAvailable: response.getIsAchPaymentsAvailable() }
    }
    case FETCH_ACH_PAYMENT_CONFIG_FAILURE:
      return { ...state, isLoading: false }

    case UPDATE_ACH_PAYMENT_CONFIG_REQUEST:
      return { ...state, isSubmittingPaymentConfig: true }
    case UPDATE_ACH_PAYMENT_CONFIG_SUCCESS:
      return { ...state, isSubmittingPaymentConfig: false }
    case UPDATE_ACH_PAYMENT_CONFIG_FAILURE:
      return { ...state, isSubmittingPaymentConfig: false }

    default: 
      return state
  }
}