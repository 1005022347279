import React, { useEffect } from "react";
import Text from "../../../../ui/text/Text";
import "./EmailFooter.scss";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../../../../..";
import { IAuthReducerState } from "../../../../../reducers/authReducer";
import { IEmailCustomizationReducerState } from "../../../../../reducers/emailCustomizationReducer";
import { IEmailBaseTemplateState } from "../../../../../reducers/emailBaseTemplateReducer";
import { fetchEmailBaseTemplate } from "../../../../../actions/emailBaseTemplateActions";



const EmailFooter: React.FC = () => {
  const { overrideAdvert, subjectLine, customArea1, customArea2, customArea3, emailType, hideAdvertisement, advertisementLink, 
    advertisementImageUrl, sendToCompanyAlertEmail, sendToPlanAlertEmail, sendToProductAlertEmail 
  } = useSelector<AppState, IEmailCustomizationReducerState>(state => state.emailCustomizationReducer);
  const { currentCompanyId, companyName, currentCompanyDomain } = useSelector<AppState, IAuthReducerState>(state => state.auth);
  const { includeAdvert, avertisementImageUrl, advertisementUrl, footerText } = useSelector<AppState, IEmailBaseTemplateState>(state => state.emailBaseTemplateReducer);
  const dispatch = useDispatch<Function>()

  const shouldDisplayAdvert = () => {
    return includeAdvert || (overrideAdvert && !hideAdvertisement);
  }

  const getAds = () => {

    if(overrideAdvert && !hideAdvertisement) {
      return { imageUrl: advertisementImageUrl, link: advertisementLink };
    }
    
    if(includeAdvert) {
      return { imageUrl: avertisementImageUrl, link: advertisementUrl };
    }
      
    return null;
  }

  useEffect(() => {
    dispatch(fetchEmailBaseTemplate(currentCompanyId as number));
    return () => { };
  }, []);

  return (
    <>
      <div className="email-footer__ads-container">
        {shouldDisplayAdvert() && !!getAds() && <a href={overrideAdvert ? advertisementLink : advertisementUrl} target="_self">
          {
            !overrideAdvert && avertisementImageUrl && <img src={avertisementImageUrl} alt={avertisementImageUrl} className="email-footer__ads" />
          }
          {
            overrideAdvert && advertisementImageUrl && <img src={advertisementImageUrl} alt={advertisementImageUrl} className="email-footer__ads" />
          }
          </a>}
      </div>

      {footerText && <div className="email-footer__text-container">
        <Text
          content={<span dangerouslySetInnerHTML={{__html: footerText}}></span>}
          shouldTranslate={false}
          className="email-preview__details__text email-footer__text"
          component="span"
          noMargin
        />
      </div>}
    </>
  )
}

export default EmailFooter