import React from "react";
import Text from "../../components/ui/text/Text";
import StatusLabel from "../../components/ui/status-label/StatusLabel";
import BillsbyLogo from "../../images/billsby-logo.svg";
import BillsbyLogoBlack from "../../images/billsby-logo-black.svg";
import { ReceiptAddress } from "../../models/Address";
import { COLOR } from "../../utils/constants";
import { lightOrDark, multiplyNumberBy100 } from "../../utils/commonUtils";

import "./Receipt.scss";

interface IContainer {
  className?: string;
  children: JSX.Element[] | JSX.Element;
}

interface IBody {
  children: JSX.Element[] | JSX.Element;
}

interface IHeader {
  title: string;
  backgroundColor?: string;
  logoUrl?: string;
  customTitle?: string;
  locale?: string
}

interface IGeneralInfo {
  content: {
    fullName: string;
    customerCompanyName?: string,
    billingAddress: ReceiptAddress;
    referenceNumber: { label: string; value: string };
    createdOn: { label: string; value: string };
    amount: { label: string; value: string };
    customerId: { label: string; value: string };
    customerTaxRegNumber?: { label: string; value: string };
    status: {
      color?: COLOR;
      content: string;
    };
  };
  locale?: string
}

interface ISummary {
  content: {
    subtotalAmount: string;
    entireDiscount?: string;
    taxAmount: string;
    totalAmount: string;
    prorationAdjustment: string,
    prorationFactor?: number,
    taxPercentage: number;
    cardDetails?: string;
    taxes: Array<{ name: string; percentage: number }>;
  };
  highlightColor?: string;
  locale?: string
}

interface IFooter {
  content: {
    companyName?: string;
    companyPhone?: string;
    companyEmail?: string;
    companyTaxLabel?: string;
    companyTaxNumber?: string;
    companyAddress: ReceiptAddress;
  };
  backgroundColor?: string;
  locale?: string
}

interface IPlanHeader {
  content: {
    planName: string;
    subscriptionUniqueId: string;
    billingPeriod: string;
  };
  highlightColor?: string;
  locale?: string
}

const Container: React.FC<IContainer> = (props: IContainer) => {
  const { children, className } = props;
  return <div className={`receipt-details ${className ? className : ""}`}>{children}</div>;
};

const Body: React.FC<IBody> = (props: IBody) => {
  return <div className="receipt-details__body">{props.children}</div>;
};

const Header: React.FC<IHeader> = (props: IHeader) => {
  const { title, backgroundColor, logoUrl, customTitle, locale } = props;

  return (
    <div className="receipt-details__header" style={backgroundColor ? { backgroundColor } : {}}>
      <img className="receipt-details__header__logo" src={logoUrl && logoUrl !== "" ? logoUrl : lightOrDark(backgroundColor) === "dark" ? BillsbyLogo : BillsbyLogoBlack} alt="Billsby" />
      <Text className={`receipt-details__header__text bold ${lightOrDark(backgroundColor) === "dark" ? "light-text" : ""}`} component="span" content={customTitle || title} shouldTranslate={!customTitle} locale={!customTitle ? locale : undefined} noMargin />
    </div>
  );
};

const GeneralInfo: React.FC<IGeneralInfo> = (props: IGeneralInfo) => {
  const { fullName, billingAddress, referenceNumber, createdOn, amount, customerId, status, customerTaxRegNumber, customerCompanyName } = props.content;
  const { locale } = props;
  return (
    <div className="receipt-details__general-info">
      <div className="general-info__section">
        {customerCompanyName && <>
          <Text shouldTranslate={false} component="span" noMargin content={`${customerCompanyName}`} />
          <br />
        </>}

        <Text shouldTranslate={false} component="span" noMargin content={`${fullName}`} />
        <br />
        <Text shouldTranslate={false} component="span" noMargin content={billingAddress.addressLine1} />
        <br />
        {billingAddress.addressLine2 && (
          <>
            <Text shouldTranslate={false} component="span" noMargin content={billingAddress.addressLine2} />
            <br />
          </>
        )}
        {billingAddress.addressCity && (
          <>
            <Text shouldTranslate={false} component="span" noMargin content={billingAddress.addressCity} />
            <br />
          </>
        )}
        <Text shouldTranslate={false} component="span" noMargin content={billingAddress.addressState} />
        <br />
        <Text shouldTranslate={false} component="span" noMargin content={billingAddress.addressCountry} />
        <br />
        <Text shouldTranslate={false} component="span" noMargin content={billingAddress.addressPostCode} />
      </div>
      <div className="general-info__section">
        <Text component="span" locale={locale} content={referenceNumber.label} />
        <Text shouldTranslate={false} className="bold" component="span" content={referenceNumber.value} />
        <br />
        <Text component="span" locale={locale} content={createdOn.label} />
        <Text shouldTranslate={false} className="bold" component="span" content={createdOn.value} />
        <br />
        <Text component="span" locale={locale} content={amount.label} />
        <Text shouldTranslate={false} className="bold" component="span" content={amount.value} />
        <br />
        <Text component="span" locale={locale} content={customerId.label} />
        <Text shouldTranslate={false} className="bold" component="span" content={customerId.value ? customerId.value.toString() : ""} />
        <br />
        {customerTaxRegNumber && (
          <>
            <Text component="span" content={customerTaxRegNumber.label} />
            <Text shouldTranslate={false} className="bold" component="span" content={customerTaxRegNumber.value} />
            <br />
          </>
        )}
        <div data-html2canvas-ignore>
          <StatusLabel data-html2canvas-ignore color={status.color} content={status.content} />
        </div>
      </div>
    </div>
  );
};

const PlanHeader: React.FC<IPlanHeader> = (props: IPlanHeader) => {
  const { subscriptionUniqueId, billingPeriod, planName } = props.content;
  const { highlightColor, locale } = props;

  return (
    <div className="receipt-details__plan-header">
      <Text component="h1" noMargin shouldTranslate={false} className="bold dusty-orange" style={highlightColor ? { color: highlightColor } : {}} content={planName} />

      <Text component="span" noMargin content="RECEIPT_DETAILS_SUBSCRIPTION_ID" locale={locale} />
      <Text component="span" className="bold" shouldTranslate={false} noMargin content={subscriptionUniqueId} />
      <br />
      <Text component="span" noMargin content="RECEIPT_DETAILS_BILLING_PERIOD" locale={locale} />
      <Text component="span" className="bold" shouldTranslate={false} noMargin content={billingPeriod} />
    </div>
  );
};

const Summary: React.FC<ISummary> = (props: ISummary) => {
  const { subtotalAmount, entireDiscount, taxAmount, totalAmount, prorationAdjustment, prorationFactor, cardDetails, taxPercentage, taxes } = props.content;
  const { highlightColor, locale } = props;
  // prorationFactor can be even zero 
  const isValidProrationFactor = typeof prorationFactor === "number";

  const renderTaxRates = () => {
    if(taxes.length === 0) {
      return <Text component="span" className="receipt-details__tax-rates gray" noMargin content={"(0%)"} shouldTranslate={false} />;
    }
    return taxes.map((tx, i) => <Text key={i} component="span" className="receipt-details__tax-rates gray" noMargin content={`(${tx.name} @ ${tx.percentage}%)`} shouldTranslate={false} />);
  };

  const textColorStyle = { style: highlightColor ? { color: highlightColor } : {} };
  return (
    <div className="receipt-details__summary">
      <div className="summary-wrapper">
        <Text component="h1" className="summary-header bold dusty-orange" {...textColorStyle} content="RECEIPT_DETAILS_TOTALS" locale={locale} />
        <div className="summary-table">
          <div className="summary-table__row">
            <div className="summary-table__col">
              <Text component="span" className="dusty-orange" {...textColorStyle} content="RECEIPT_DETAILS_SUBTOTAL" locale={locale} />
            </div>
            <div className="summary-table__col">
              <Text component="span" shouldTranslate={false} content={subtotalAmount} />
            </div>
          </div>
          {(prorationAdjustment && isValidProrationFactor) ? <div className="summary-table__row">
            <div className="summary-table__col">
              <Text component="span" className="dusty-orange" {...textColorStyle} content="RECEIPT_DETAILS_PRO_RATION_ADJUSTMENT" locale={locale} />
            </div>
            <div className="summary-table__col">
              <Text component="span" shouldTranslate={false} content={prorationAdjustment} />
            </div>
          </div> : null}
          {entireDiscount ? (
            <div className="summary-table__row">
              <div className="summary-table__col">
                <Text component="span" className="dusty-orange" {...textColorStyle} content="RECEIPT_DETAILS_DISCOUNT" locale={locale} />
              </div>
              <div className="summary-table__col">
                <Text component="span" shouldTranslate={false} content={entireDiscount} />
              </div>
            </div>
          ) : null}
            <div className="summary-table__row">
              <div className="summary-table__col" style={taxes.length > 1 ? { lineHeight: 1 } : {}}>
                <Text component="span" className="dusty-orange" {...textColorStyle} content="RECEIPT_DETAILS_TAX" locale={locale} />
                {renderTaxRates()}
              </div>
              <div className="summary-table__col">
                <Text component="span" shouldTranslate={false} content={taxAmount} />
              </div>
            </div>
          <div className="summary-table__row">
            <div className="summary-table__col">
              <Text component="span" className="dusty-orange" {...textColorStyle} shouldTranslate={false} content="Total" />
            </div>
            <div className="summary-table__col">
              <Text component="span" shouldTranslate={false} content={totalAmount} />
            </div>
          </div>
        </div>
        {
          prorationFactor ? (
            <Text
              className="summary-table__sub-info gray bold display-block"
              component="span"
              content="RECEIPT_DETAILS_PRO_RATION_ADJUSTMENT"
              noMargin
              locale={locale}
            />
          ) : null
        }
        {
          prorationFactor ? (
            <Text
              className="summary-table__sub-info gray display-block margin-small"
              component="span" content="RECEIPT_DETAILS_PRO_RATION_ADJUSTMENT_TEXT"
              translateWith={{ prorationFactor: multiplyNumberBy100(prorationFactor) }}
              locale={locale}
            />
          ) : null
        }
        {
          cardDetails ? (
            <Text
              className="summary-table__sub-info gray bold"
              component="span"
              shouldTranslate={false}
              content={cardDetails}
            />
          ) : null
        }
      </div>
    </div>
  );
};

const Footer: React.FC<IFooter> = (props: IFooter) => {
  const { companyName, companyAddress, companyPhone, companyEmail, companyTaxLabel, companyTaxNumber } = props.content;
  const { addressCity, addressCountry, addressLine1, addressLine2, addressPostCode, addressState } = companyAddress;
  const { backgroundColor, locale } = props;

  const footerTopContent = `${companyName}${addressLine1 ? `, ${addressLine1}` : ""}${addressLine2 ? `, ${addressLine2}` : ""}${addressCity ? `, ${addressCity}` : ""}${addressState ? `, ${addressState}` : ""
    }${addressCountry ? `, ${addressCountry}` : ""}${addressPostCode ? `, ${addressPostCode}` : ""}`;

  const footerBottomContent = `${companyPhone ? `${companyPhone}` : ""}${companyEmail ? ` | ${companyEmail}` : ""}${companyTaxLabel || companyTaxNumber ? ` | ${companyTaxLabel ? `${companyTaxLabel} ${companyTaxNumber}` : `${companyTaxNumber}`}` : ""}`;

  return (
    <div className={`receipt-details__footer ${lightOrDark(backgroundColor) === "dark" ? "light-text" : ""}`} style={backgroundColor ? { backgroundColor } : {}}>
      <div className="receipt-details__footer__company-details">
        <Text component="span" noMargin shouldTranslate={false} content={footerTopContent} />
        <br />
        <Text component="span" noMargin shouldTranslate={false} content={footerBottomContent} />
      </div>
      <div className="receipt-details__footer__company-sub">
        <Text component="span" locale={locale} noMargin content="RECEIPT_DETAILS_SUB_BILLING" />
        <a href={`https://www.billsby.com/powered/?companyname=${companyName}`} target="_self">
          <img className="receipt-details__footer__company-sub__logo" src={lightOrDark(backgroundColor) === "dark" ? BillsbyLogo : BillsbyLogoBlack} alt="Billsby" />
        </a>
      </div>
    </div>
  );
};

const Receipt = {
  Container,
  GeneralInfo,
  Header,
  Summary,
  Footer,
  Body,
  PlanHeader,
};

export default Receipt;
