import { makeActionCreator, makeGenericActionCreatorTypeSafe } from "../utils/reduxUtils";
import { ReportsServiceClient } from "../utils/grpc/generated/Billsby.Protos/revenuerecognition/private/reports/ReportsServiceClientPb";
import { grpcUnaryCall } from "../utils/grpc/grpcUtils";
import moment from "moment";
import { IRevenueReportState } from "../reducers/revenueReportReducer";
import { ConfigConstants } from "../utils/config";
import { GetInvoiceRevenueRequest, GetPlanRevenueRequest, GetProductRevenueRequest, GetSubscriptionRevenueRequest } from "../utils/grpc/generated/Billsby.Protos/revenuerecognition/private/reports/reports_pb"
import { RevenueReportFilterType } from "../models/Reports";

export const FETCH_REVENUE_REPORT_SUCCESS = "FETCH_REVENUE_REPORT_SUCCESS";
export const FETCH_REVENUE_REPORT_FAILURE = "FETCH_REVENUE_REPORT_FAILURE";
export const FETCH_REVENUE_REPORT_REQUEST = "FETCH_REVENUE_REPORT_REQUEST";

export const SET_REVENUE_REPORT_FIELD = "SET_REVENUE_REPORT_FIELD";
export const RESET_REVENUE_REPORT = "RESET_REVENUE_REPORT";

export const resetRevenueReport = makeActionCreator(RESET_REVENUE_REPORT, "payload");
export const setRevenueReportField = makeGenericActionCreatorTypeSafe<IRevenueReportState>(SET_REVENUE_REPORT_FIELD);


export const fetchRevenueReport = function (companyId: number, startDate: string, endDate: string, filterType: RevenueReportFilterType, revenueType: "revenue" | "deferred") {

  const reportServiceClient = new ReportsServiceClient(ConfigConstants.grpcBaseUrl);
  let reportRequest: GetInvoiceRevenueRequest | GetPlanRevenueRequest | GetProductRevenueRequest | GetSubscriptionRevenueRequest | undefined;
  let reportClient: any;

  const isRevenueRecogntion = revenueType === "revenue";

  if (filterType === RevenueReportFilterType.PLAN) {
    reportRequest = new GetPlanRevenueRequest();
    reportClient = isRevenueRecogntion ? reportServiceClient.getPlanRevenueRecognition : reportServiceClient.getPlanDeferredRevenue;
  }

  if (filterType === RevenueReportFilterType.SUBSCRIPTION) {
    reportRequest = new GetSubscriptionRevenueRequest();
    reportClient = isRevenueRecogntion ?  reportServiceClient.getSubscriptionRevenueRecognition : reportServiceClient.getSubscriptionDeferredRevenue;
  }

  if (filterType === RevenueReportFilterType.INVOICE) {
    reportRequest = new GetInvoiceRevenueRequest();
    reportClient = isRevenueRecogntion ? reportServiceClient.getInvoiceRevenueRecognition : reportServiceClient.getInvoiceDeferredRevenue;
  }

  if (filterType === RevenueReportFilterType.PRODUCT) {
    reportRequest = new GetProductRevenueRequest();
    reportClient = isRevenueRecogntion ? reportServiceClient.getProductRevenueRecognition : reportServiceClient.getProductDeferredRevenue;
  }

  if (reportRequest) {
    reportRequest.setCompanyId(companyId)
    reportRequest.setFilterEndMonth(moment.utc(endDate, "MMM YYYY").month() + 1);
    reportRequest.setFilterEndYear(moment.utc(endDate, "MMM YYYY").year());
    reportRequest.setFilterStartMonth(moment.utc(startDate, "MMM YYYY").month() + 1);
    reportRequest.setFilterStartYear(moment.utc(startDate, "MMM YYYY").year());
  } 

  return ({
    types: [FETCH_REVENUE_REPORT_REQUEST, FETCH_REVENUE_REPORT_SUCCESS, FETCH_REVENUE_REPORT_FAILURE],
    callAPI: () => grpcUnaryCall(reportRequest, reportServiceClient, reportClient)
  })
}