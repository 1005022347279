import { UserRole } from "./Auth"


const adminForbiddenRoutes: Readonly<Array<string>> = []

const developerForbiddenRoutes: Readonly<Array<string>> = [
  "/launch-checklist"
]

const editorForbiddenRoutes: Readonly<Array<string>> = [
  "/embed-codes/checkout",
  "/embed-codes/self-service",
  "/embed-codes/change-plan",
  "/embed-codes/add-on",
  "/embed-codes/allowances",
  "/embed-codes/card-tokenizer",

  "/integrations",
  "/integrations/free-agent",
  "/integrations/free-agent/faq",
  "/integrations/quickbooks",
  "/integrations/quickbooks/faq",
  "/integrations/webex",
  "/integrations/billsby-pages",
  "/integrations/zapier",

  "/configuration/api-keys",
  "/configuration/api-keys/webhooks",

  "/launch-checklist"
]

const viewerForbiddenRoutes: Readonly<Array<string>> = [
  "/products/products-plans-cycles/create-product",
  "/products/products-plans-cycles/.*/create-plan",
  "/products/products-plans-cycles/edit-product/.*",
  "/products/products-plans-cycles/.*/edit-plan/.*",

  "/addons-allowances/addons/create",
  "/addons-allowances/addons/edit/.*",
  "/addons-allowances/allowances/create",
  "/addons-allowances/allowances/edit/.*",

  "/discount-and-coupons/create",
  "/discount-and-coupons/.*/coupon/create",
  "/discount-and-coupons/edit/.*",
  "/discount-and-coupons/.*/coupon/edit/.*",

  "/integrations",
  "/integrations/free-agent",
  "/integrations/free-agent/faq",
  "/integrations/quickbooks",
  "/integrations/quickbooks/faq",
  "/integrations/webex",
  "/integrations/billsby-pages",
  "/integrations/zapier",

  "/configuration",
  "/configuration/company-details",
  "/configuration/pause-and-cancel-subscription/cancel",
  "/configuration/pause-and-cancel-subscription",
  "/configuration/currencies",
  "/configuration/taxes",
  "/configuration/taxes/configure/AUS",
  "/configuration/taxes/configure/CAN",
  "/configuration/taxes/configure/EUE",
  "/configuration/taxes/configure/IND",
  "/configuration/taxes/configure/NZL",
  "/configuration/taxes/configure/USA",
  "/configuration/invoices-credit-notes-customization",
  "/configuration/invoices-credit-notes-customization/pending-invoices",
  "/configuration/invoices-credit-notes-customization/unpaid-invoices",
  "/configuration/invoices-credit-notes-customization/paid-credit-notes",
  "/configuration/invoices-credit-notes-customization/pending-credit-notes",
  "/configuration/invoices-credit-notes-customization/failed-credit-notes",
  "/configuration/dunning-process",
  "/configuration/dunning-process/customize-email/declined/PaymentDeclinedInitial",
  "/configuration/dunning-process/customize-email/declined/PaymentDeclinedAttemptOne",
  "/configuration/dunning-process/customize-email/declined/PaymentDeclinedAttemptTwo",
  "/configuration/dunning-process/customize-email/declined/PaymentDeclinedAttemptThree",
  "/configuration/dunning-process/customize-email/declined/HandlingMinimumTermsEmail",
  "/configuration/dunning-process/customize-email/successful-reattempt",
  "/configuration/dunning-process/failed",
  "/configuration/dunning-process/customize-email/failed/initial-payment-failure",
  "/configuration/allowance",
  "/configuration/payment-gateways",
  "/configuration/sca",
  "/configuration/brand-customization/checkout-account-management",
  "/configuration/brand-customization/email-invoices",
  "/configuration/email-notifications/emails",
  "/configuration/email-notifications/emails/welcome-emails/new-subscription-with-trial-template",
  "/configuration/email-notifications/emails/welcome-emails/new-subscription-without-trial-template",
  "/configuration/email-notifications/emails/welcome-emails/new-customer-without-subscription-template",
  "/configuration/email-notifications/emails/welcome-emails/new-add-on-template",
  "/configuration/email-notifications/emails/welcome-emails/new-allowance-template",
  "/configuration/email-notifications/emails/trial-management/trial-will-expire-in-three-days-template",
  "/configuration/email-notifications/emails/renewal-reminders/subscription-will-auto-renew-in-three-days-template",
  "/configuration/email-notifications/emails/contract-term/before-end-email-template",
  "/configuration/email-notifications/emails/discount-coupons/coupon-applied-email-template",
  "/configuration/email-notifications/emails/discount-coupons/before-discount-expires-email-template",
  "/configuration/email-notifications/emails/payment-credits-refund/renewal-payment-email-template",
  "/configuration/email-notifications/emails/payment-credits-refund/positive-one-time-charge-successfully-captured-email-template",
  "/configuration/email-notifications/emails/payment-credits-refund/positive-one-time-charge-payment-failed",
  "/configuration/email-notifications/emails/payment-credits-refund/refund-issued-email-template",
  "/configuration/email-notifications/emails/payment-credits-refund/resend-invoice-link-email-template",
  "/configuration/email-notifications/emails/payment-credits-refund/resend-credit-note-link-email-template",
  "/configuration/email-notifications/template",
  "/configuration/email-notifications",
  "/configuration/checkout-account-management",
  "/configuration/checkout-account-management/self-service",
  "/configuration/api-keys",
  "/configuration/api-keys/webhooks",
  "/configuration/information-fields",

  "/embed-codes/checkout",
  "/embed-codes/self-service",
  "/embed-codes/change-plan",
  "/embed-codes/add-on",
  "/embed-codes/allowances",
  "/embed-codes/card-tokenizer",

  "/launch-checklist"
]

const agentForbiddenRoutes: Readonly<Array<string>> = [
  "/home",

  "/products/products-plans-cycles/create-product",
  "/products/products-plans-cycles/.*/create-plan",
  "/products/products-plans-cycles/edit-product/.*",
  "/products/products-plans-cycles/.*/edit-plan/.*",

  "/addons-allowances/addons/create",
  "/addons-allowances/addons/edit/.*",
  "/addons-allowances/allowances/create",
  "/addons-allowances/allowances/edit/.*",

  "/report-accounting",
  "/report-accounting/activity-summary",
  "/report-accounting/tax-summary",
  "/report-accounting/revenue-recognition",
  "/report-accounting/deferred-revenue",
  "/report-checkout-self-service",
  "/report-checkout-self-service/exit-reasons",

  "/integrations",
  "/integrations/free-agent",
  "/integrations/quickbooks",
  "/integrations/webex",
  "/integrations/billsby-pages",
  "/integrations/zapier",

  "/configuration",
  "/configuration/company-details",
  "/configuration/pause-and-cancel-subscription/cancel",
  "/configuration/pause-and-cancel-subscription",
  "/configuration/currencies",
  "/configuration/taxes",
  "/configuration/taxes/configure/AUS",
  "/configuration/taxes/configure/CAN",
  "/configuration/taxes/configure/EUE",
  "/configuration/taxes/configure/IND",
  "/configuration/taxes/configure/NZL",
  "/configuration/taxes/configure/USA",
  "/configuration/invoices-credit-notes-customization",
  "/configuration/invoices-credit-notes-customization/pending-invoices",
  "/configuration/invoices-credit-notes-customization/unpaid-invoices",
  "/configuration/invoices-credit-notes-customization/paid-credit-notes",
  "/configuration/invoices-credit-notes-customization/pending-credit-notes",
  "/configuration/invoices-credit-notes-customization/failed-credit-notes",
  "/configuration/dunning-process",
  "/configuration/dunning-process/customize-email/declined/PaymentDeclinedInitial",
  "/configuration/dunning-process/customize-email/declined/PaymentDeclinedAttemptOne",
  "/configuration/dunning-process/customize-email/declined/PaymentDeclinedAttemptTwo",
  "/configuration/dunning-process/customize-email/declined/PaymentDeclinedAttemptThree",
  "/configuration/dunning-process/customize-email/declined/HandlingMinimumTermsEmail",
  "/configuration/dunning-process/customize-email/successful-reattempt",
  "/configuration/dunning-process/failed",
  "/configuration/dunning-process/customize-email/failed/initial-payment-failure",
  "/configuration/allowance",
  "/configuration/payment-gateways",
  "/configuration/brand-customization/checkout-account-management",
  "/configuration/brand-customization/email-invoices",
  "/configuration/email-notifications/emails",
  "/configuration/email-notifications/emails/welcome-emails/new-subscription-with-trial-template",
  "/configuration/email-notifications/emails/welcome-emails/new-subscription-without-trial-template",
  "/configuration/email-notifications/emails/welcome-emails/new-customer-without-subscription-template",
  "/configuration/email-notifications/emails/welcome-emails/new-add-on-template",
  "/configuration/email-notifications/emails/welcome-emails/new-allowance-template",
  "/configuration/email-notifications/emails/trial-management/trial-will-expire-in-three-days-template",
  "/configuration/email-notifications/emails/renewal-reminders/subscription-will-auto-renew-in-three-days-template",
  "/configuration/email-notifications/emails/contract-term/before-end-email-template",
  "/configuration/email-notifications/emails/discount-coupons/coupon-applied-email-template",
  "/configuration/email-notifications/emails/discount-coupons/before-discount-expires-email-template",
  "/configuration/email-notifications/emails/payment-credits-refund/renewal-payment-email-template",
  "/configuration/email-notifications/emails/payment-credits-refund/positive-one-time-charge-successfully-captured-email-template",
  "/configuration/email-notifications/emails/payment-credits-refund/positive-one-time-charge-payment-failed",
  "/configuration/email-notifications/emails/payment-credits-refund/refund-issued-email-template",
  "/configuration/email-notifications/emails/payment-credits-refund/resend-invoice-link-email-template",
  "/configuration/email-notifications/emails/payment-credits-refund/resend-credit-note-link-email-template",
  "/configuration/email-notifications/template",
  "/configuration/email-notifications",
  "/configuration/checkout-account-management",
  "/configuration/checkout-account-management/self-service",
  "/configuration/api-keys",
  "/configuration/api-keys/webhooks",
  "/configuration/information-fields",

  "/embed-codes/checkout",
  "/embed-codes/self-service",
  "/embed-codes/change-plan",
  "/embed-codes/add-on",
  "/embed-codes/allowances",
  "/embed-codes/card-tokenizer",
  
  "/launch-checklist"
];

const billsbyAdminForbiddenRoutes: Readonly<Array<string>> = []


const billsbyOperationsManagerForbiddenRoutes: Readonly<Array<string>> = [
  "/home/sales",
  "/subscriptions/.*/allowances",


  "/products/products-plans-cycles/create-product",
  "/products/products-plans-cycles/.*/create-plan",
  "/products/products-plans-cycles/edit-product/.*",
  "/products/products-plans-cycles/.*/edit-plan/.*",

  "/addons-allowances/addons/create",
  "/addons-allowances/addons/edit/.*",
  "/addons-allowances/allowances/create",
  "/addons-allowances/allowances/edit/.*",

  "/discount-and-coupons/create",
  "/discount-and-coupons/.*/coupon/create",
  "/discount-and-coupons/edit/.*",
  "/discount-and-coupons/.*/coupon/edit/.*",

  "/report-accounting",
  "/report-accounting/activity-summary",
  "/report-accounting/tax-summary",
  "/report-accounting/revenue-recognition",
  "/report-accounting/deferred-revenue",

  "/integrations",
  "/integrations/free-agent",
  "/integrations/free-agent/faq",
  "/integrations/quickbooks",
  "/integrations/quickbooks/faq",
  "/integrations/webex",
  "/integrations/billsby-pages",
  "/integrations/zapier",

  "/configuration",
  "/configuration/company-details",
  "/configuration/pause-and-cancel-subscription/cancel",
  "/configuration/pause-and-cancel-subscription",
  "/configuration/currencies",
  "/configuration/taxes",
  "/configuration/taxes/configure/AUS",
  "/configuration/taxes/configure/CAN",
  "/configuration/taxes/configure/EUE",
  "/configuration/taxes/configure/IND",
  "/configuration/taxes/configure/NZL",
  "/configuration/taxes/configure/USA",
  "/configuration/invoices-credit-notes-customization",
  "/configuration/invoices-credit-notes-customization/pending-invoices",
  "/configuration/invoices-credit-notes-customization/unpaid-invoices",
  "/configuration/invoices-credit-notes-customization/paid-credit-notes",
  "/configuration/invoices-credit-notes-customization/pending-credit-notes",
  "/configuration/invoices-credit-notes-customization/failed-credit-notes",
  "/configuration/dunning-process",
  "/configuration/dunning-process/customize-email/declined/PaymentDeclinedInitial",
  "/configuration/dunning-process/customize-email/declined/PaymentDeclinedAttemptOne",
  "/configuration/dunning-process/customize-email/declined/PaymentDeclinedAttemptTwo",
  "/configuration/dunning-process/customize-email/declined/PaymentDeclinedAttemptThree",
  "/configuration/dunning-process/customize-email/declined/HandlingMinimumTermsEmail",
  "/configuration/dunning-process/customize-email/successful-reattempt",
  "/configuration/dunning-process/failed",
  "/configuration/dunning-process/customize-email/failed/initial-payment-failure",
  "/configuration/allowance",
  "/configuration/payment-gateways",
  "/configuration/sca",
  "/configuration/brand-customization/checkout-account-management",
  "/configuration/brand-customization/email-invoices",
  "/configuration/email-notifications/emails",
  "/configuration/email-notifications/emails/welcome-emails/new-subscription-with-trial-template",
  "/configuration/email-notifications/emails/welcome-emails/new-subscription-without-trial-template",
  "/configuration/email-notifications/emails/welcome-emails/new-customer-without-subscription-template",
  "/configuration/email-notifications/emails/welcome-emails/new-add-on-template",
  "/configuration/email-notifications/emails/welcome-emails/new-allowance-template",
  "/configuration/email-notifications/emails/trial-management/trial-will-expire-in-three-days-template",
  "/configuration/email-notifications/emails/renewal-reminders/subscription-will-auto-renew-in-three-days-template",
  "/configuration/email-notifications/emails/contract-term/before-end-email-template",
  "/configuration/email-notifications/emails/discount-coupons/coupon-applied-email-template",
  "/configuration/email-notifications/emails/discount-coupons/before-discount-expires-email-template",
  "/configuration/email-notifications/emails/payment-credits-refund/renewal-payment-email-template",
  "/configuration/email-notifications/emails/payment-credits-refund/positive-one-time-charge-successfully-captured-email-template",
  "/configuration/email-notifications/emails/payment-credits-refund/positive-one-time-charge-payment-failed",
  "/configuration/email-notifications/emails/payment-credits-refund/refund-issued-email-template",
  "/configuration/email-notifications/emails/payment-credits-refund/resend-invoice-link-email-template",
  "/configuration/email-notifications/emails/payment-credits-refund/resend-credit-note-link-email-template",
  "/configuration/email-notifications/template",
  "/configuration/email-notifications",
  "/configuration/checkout-account-management",
  "/configuration/checkout-account-management/self-service",
  "/configuration/api-keys",
  "/configuration/api-keys/webhooks",
  "/configuration/information-fields",
  
  "/embed-codes/card-tokenizer",
  "/embed-codes/checkout",
  "/embed-codes/self-service",
  "/embed-codes/change-plan",
  "/embed-codes/add-on",
  "/embed-codes/allowances",

  "/launch-checklist"
]


const billsbySeniorOperationsManagerForbiddenRoutes: Readonly<Array<string>> = [
  "/products/products-plans-cycles/create-product",
  "/products/products-plans-cycles/.*/create-plan",
  "/products/products-plans-cycles/edit-product/.*",
  "/products/products-plans-cycles/.*/edit-plan/.*",

  
  "/addons-allowances/addons/create",
  "/addons-allowances/addons/edit/.*",
  "/addons-allowances/allowances/create",
  "/addons-allowances/allowances/edit/.*",
  "/integrations",
  "/integrations/free-agent",
  "/integrations/free-agent/faq",
  "/integrations/quickbooks",
  "/integrations/quickbooks/faq",
  "/integrations/webex",
  "/integrations/billsby-pages",
  "/integrations/zapier",

  "/configuration/pause-and-cancel-subscription/cancel",
  "/configuration/pause-and-cancel-subscription",
  "/configuration/currencies",
  "/configuration/taxes",
  "/configuration/taxes/configure/AUS",
  "/configuration/taxes/configure/CAN",
  "/configuration/taxes/configure/EUE",
  "/configuration/taxes/configure/IND",
  "/configuration/taxes/configure/NZL",
  "/configuration/taxes/configure/USA",

  "/configuration/dunning-process",
  "/configuration/dunning-process/customize-email/declined/PaymentDeclinedInitial",
  "/configuration/dunning-process/customize-email/declined/PaymentDeclinedAttemptOne",
  "/configuration/dunning-process/customize-email/declined/PaymentDeclinedAttemptTwo",
  "/configuration/dunning-process/customize-email/declined/PaymentDeclinedAttemptThree",
  "/configuration/dunning-process/customize-email/declined/HandlingMinimumTermsEmail",
  "/configuration/dunning-process/customize-email/successful-reattempt",
  "/configuration/dunning-process/failed",
  "/configuration/dunning-process/customize-email/failed/initial-payment-failure",
  "/configuration/allowance",
  "/configuration/payment-gateways",
  "/configuration/sca",

  "/configuration/api-keys",
  "/configuration/api-keys/webhooks",
  "/configuration/information-fields",

  "/embed-codes/card-tokenizer",
  "/embed-codes/checkout",
  "/embed-codes/self-service",
  "/embed-codes/change-plan",
  "/embed-codes/add-on",
  "/embed-codes/allowances",

  "/launch-checklist"
]

const billsbyAnalystForbiddenRoutes: Readonly<Array<string>> = [

  "/customers/.*/emails",
  "/customers/.*/tax-validation",
  "/customers/.*/notes",
  "/customers/.*/ach-details",
  "/subscriptions/.*/add-ons",
  "/subscriptions/.*/allowances",
  "/subscriptions/.*/discounts",
  "/subscriptions/.*/feature-tags",

  "/products/products-plans-cycles",
  "/products/products-plans-cycles/create-product",
  "/products/products-plans-cycles/.*/create-plan",
  "/products/products-plans-cycles/edit-product/.*",
  "/products/products-plans-cycles/.*/edit-plan/.*",

  "/addons-allowances/addons",
  "/addons-allowances/allowances",
  "/addons-allowances/addons/create",
  "/addons-allowances/addons/edit/.*",
  "/addons-allowances/allowances/create",
  "/addons-allowances/allowances/edit/.*",

  "/discount-and-coupons",
  "/discount-and-coupons/create",
  "/discount-and-coupons/.*/coupon/create",
  "/discount-and-coupons/edit/.*",
  "/discount-and-coupons/.*/coupon/edit/.*",

  "/email-logs",
  
  "/integrations",
  "/integrations/free-agent",
  "/integrations/free-agent/faq",
  "/integrations/quickbooks",
  "/integrations/quickbooks/faq",
  "/integrations/webex",
  "/integrations/billsby-pages",
  "/integrations/zapier",

  "/configuration",
  "/configuration/company-details",
  "/configuration/pause-and-cancel-subscription/cancel",
  "/configuration/pause-and-cancel-subscription",
  "/configuration/currencies",
  "/configuration/taxes",
  "/configuration/taxes/configure/AUS",
  "/configuration/taxes/configure/CAN",
  "/configuration/taxes/configure/EUE",
  "/configuration/taxes/configure/IND",
  "/configuration/taxes/configure/NZL",
  "/configuration/taxes/configure/USA",
  "/configuration/invoices-credit-notes-customization",
  "/configuration/invoices-credit-notes-customization/pending-invoices",
  "/configuration/invoices-credit-notes-customization/unpaid-invoices",
  "/configuration/invoices-credit-notes-customization/paid-credit-notes",
  "/configuration/invoices-credit-notes-customization/pending-credit-notes",
  "/configuration/invoices-credit-notes-customization/failed-credit-notes",
  "/configuration/dunning-process",
  "/configuration/dunning-process/customize-email/declined/PaymentDeclinedInitial",
  "/configuration/dunning-process/customize-email/declined/PaymentDeclinedAttemptOne",
  "/configuration/dunning-process/customize-email/declined/PaymentDeclinedAttemptTwo",
  "/configuration/dunning-process/customize-email/declined/PaymentDeclinedAttemptThree",
  "/configuration/dunning-process/customize-email/declined/HandlingMinimumTermsEmail",
  "/configuration/dunning-process/customize-email/successful-reattempt",
  "/configuration/dunning-process/failed",
  "/configuration/dunning-process/customize-email/failed/initial-payment-failure",
  "/configuration/allowance",
  "/configuration/payment-gateways",
  "/configuration/sca",

  "/configuration/brand-customization/checkout-account-management",
  "/configuration/brand-customization/email-invoices",
  "/configuration/email-notifications/emails",
  "/configuration/email-notifications/emails/welcome-emails/new-subscription-with-trial-template",
  "/configuration/email-notifications/emails/welcome-emails/new-subscription-without-trial-template",
  "/configuration/email-notifications/emails/welcome-emails/new-customer-without-subscription-template",
  "/configuration/email-notifications/emails/welcome-emails/new-add-on-template",
  "/configuration/email-notifications/emails/welcome-emails/new-allowance-template",
  "/configuration/email-notifications/emails/trial-management/trial-will-expire-in-three-days-template",
  "/configuration/email-notifications/emails/renewal-reminders/subscription-will-auto-renew-in-three-days-template",
  "/configuration/email-notifications/emails/contract-term/before-end-email-template",
  "/configuration/email-notifications/emails/discount-coupons/coupon-applied-email-template",
  "/configuration/email-notifications/emails/discount-coupons/before-discount-expires-email-template",
  "/configuration/email-notifications/emails/payment-credits-refund/renewal-payment-email-template",
  "/configuration/email-notifications/emails/payment-credits-refund/positive-one-time-charge-successfully-captured-email-template",
  "/configuration/email-notifications/emails/payment-credits-refund/positive-one-time-charge-payment-failed",
  "/configuration/email-notifications/emails/payment-credits-refund/refund-issued-email-template",
  "/configuration/email-notifications/emails/payment-credits-refund/resend-invoice-link-email-template",
  "/configuration/email-notifications/emails/payment-credits-refund/resend-credit-note-link-email-template",
  "/configuration/email-notifications/template",
  "/configuration/email-notifications",
  "/configuration/checkout-account-management",
  "/configuration/checkout-account-management/self-service",
  "/configuration/api-keys",
  "/configuration/api-keys/webhooks",
  "/configuration/information-fields",

  "/embed-codes/card-tokenizer",
  "/embed-codes/checkout",
  "/embed-codes/self-service",
  "/embed-codes/change-plan",
  "/embed-codes/add-on",
  "/embed-codes/allowances",

  "/launch-checklist"
]

const billsbyDeveloperForbiddenRoutes: Readonly<Array<string>> = [
  "/launch-checklist"
]


/* the key of this object will be the user role */
export const forbiddenRoutes: { [key in UserRole]: Readonly<Array<string>> } = {
  [UserRole.Admin]: adminForbiddenRoutes,
  [UserRole.Developer]: developerForbiddenRoutes,
  [UserRole.Editor]: editorForbiddenRoutes,
  [UserRole.Viewer]: viewerForbiddenRoutes,
  [UserRole.Agent]: agentForbiddenRoutes,
  [UserRole.BillsbyAdmin]: billsbyAdminForbiddenRoutes,
  [UserRole.BillsbyOperationsManager]: billsbyOperationsManagerForbiddenRoutes,
  [UserRole.BillsbySeniorOperationsManager]: billsbySeniorOperationsManagerForbiddenRoutes,
  [UserRole.BillsbyAnalyst]: billsbyAnalystForbiddenRoutes,

  [UserRole.Unassigned]: viewerForbiddenRoutes,
  [UserRole.AirfiAgent]: viewerForbiddenRoutes,
  [UserRole.BillsbyDeveloper]: billsbyDeveloperForbiddenRoutes
}