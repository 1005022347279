/* eslint-disable */
// source: company.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = (function() {
  if (this) { return this; }
  if (typeof window !== 'undefined') { return window; }
  if (typeof global !== 'undefined') { return global; }
  if (typeof self !== 'undefined') { return self; }
  return Function('return this')();
}.call(null));

goog.exportSymbol('proto.core.private.companies.Address', null, global);
goog.exportSymbol('proto.core.private.companies.CancelDeleteCompanyRequestRequest', null, global);
goog.exportSymbol('proto.core.private.companies.CancelDeleteCompanyRequestResponse', null, global);
goog.exportSymbol('proto.core.private.companies.CardDetails', null, global);
goog.exportSymbol('proto.core.private.companies.CollectCompanyNameType', null, global);
goog.exportSymbol('proto.core.private.companies.CompanyLanguage', null, global);
goog.exportSymbol('proto.core.private.companies.CompanyLanguageUpdate', null, global);
goog.exportSymbol('proto.core.private.companies.DeleteCompanyRequest', null, global);
goog.exportSymbol('proto.core.private.companies.DeleteCompanyResponse', null, global);
goog.exportSymbol('proto.core.private.companies.DeleteCustomFieldRequest', null, global);
goog.exportSymbol('proto.core.private.companies.DeleteCustomFieldResponse', null, global);
goog.exportSymbol('proto.core.private.companies.GetBillsbySubscriptionRequest', null, global);
goog.exportSymbol('proto.core.private.companies.GetBillsbySubscriptionResponse', null, global);
goog.exportSymbol('proto.core.private.companies.GetCollectCompanyNameRequest', null, global);
goog.exportSymbol('proto.core.private.companies.GetCollectCompanyNameResponse', null, global);
goog.exportSymbol('proto.core.private.companies.GetCompanyFeaturesStateRequest', null, global);
goog.exportSymbol('proto.core.private.companies.GetCompanyFeaturesStateResponse', null, global);
goog.exportSymbol('proto.core.private.companies.GetCompanyLanguagesRequest', null, global);
goog.exportSymbol('proto.core.private.companies.GetCompanyLanguagesResponse', null, global);
goog.exportSymbol('proto.core.private.companies.GetTrialLimitRequest', null, global);
goog.exportSymbol('proto.core.private.companies.GetTrialLimitResponse', null, global);
goog.exportSymbol('proto.core.private.companies.GoLiveRequest', null, global);
goog.exportSymbol('proto.core.private.companies.GoLiveResponse', null, global);
goog.exportSymbol('proto.core.private.companies.PaymentStatus', null, global);
goog.exportSymbol('proto.core.private.companies.RequestDeleteCompanyRequest', null, global);
goog.exportSymbol('proto.core.private.companies.RequestDeleteCompanyResponse', null, global);
goog.exportSymbol('proto.core.private.companies.Transaction', null, global);
goog.exportSymbol('proto.core.private.companies.UpdateCollectCompanyNameRequest', null, global);
goog.exportSymbol('proto.core.private.companies.UpdateCollectCompanyNameResponse', null, global);
goog.exportSymbol('proto.core.private.companies.UpdateCompanyDomainRequest', null, global);
goog.exportSymbol('proto.core.private.companies.UpdateCompanyDomainResponse', null, global);
goog.exportSymbol('proto.core.private.companies.UpdateCompanyLanguagesRequest', null, global);
goog.exportSymbol('proto.core.private.companies.UpdateCompanyLanguagesResponse', null, global);
goog.exportSymbol('proto.core.private.companies.UpdateTrialLimitRequest', null, global);
goog.exportSymbol('proto.core.private.companies.UpdateTrialLimitResponse', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.core.private.companies.DeleteCompanyRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.core.private.companies.DeleteCompanyRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.core.private.companies.DeleteCompanyRequest.displayName = 'proto.core.private.companies.DeleteCompanyRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.core.private.companies.DeleteCompanyResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.core.private.companies.DeleteCompanyResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.core.private.companies.DeleteCompanyResponse.displayName = 'proto.core.private.companies.DeleteCompanyResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.core.private.companies.RequestDeleteCompanyRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.core.private.companies.RequestDeleteCompanyRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.core.private.companies.RequestDeleteCompanyRequest.displayName = 'proto.core.private.companies.RequestDeleteCompanyRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.core.private.companies.RequestDeleteCompanyResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.core.private.companies.RequestDeleteCompanyResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.core.private.companies.RequestDeleteCompanyResponse.displayName = 'proto.core.private.companies.RequestDeleteCompanyResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.core.private.companies.CancelDeleteCompanyRequestRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.core.private.companies.CancelDeleteCompanyRequestRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.core.private.companies.CancelDeleteCompanyRequestRequest.displayName = 'proto.core.private.companies.CancelDeleteCompanyRequestRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.core.private.companies.CancelDeleteCompanyRequestResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.core.private.companies.CancelDeleteCompanyRequestResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.core.private.companies.CancelDeleteCompanyRequestResponse.displayName = 'proto.core.private.companies.CancelDeleteCompanyRequestResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.core.private.companies.UpdateCompanyDomainRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.core.private.companies.UpdateCompanyDomainRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.core.private.companies.UpdateCompanyDomainRequest.displayName = 'proto.core.private.companies.UpdateCompanyDomainRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.core.private.companies.UpdateCompanyDomainResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.core.private.companies.UpdateCompanyDomainResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.core.private.companies.UpdateCompanyDomainResponse.displayName = 'proto.core.private.companies.UpdateCompanyDomainResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.core.private.companies.UpdateCollectCompanyNameRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.core.private.companies.UpdateCollectCompanyNameRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.core.private.companies.UpdateCollectCompanyNameRequest.displayName = 'proto.core.private.companies.UpdateCollectCompanyNameRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.core.private.companies.UpdateCollectCompanyNameResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.core.private.companies.UpdateCollectCompanyNameResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.core.private.companies.UpdateCollectCompanyNameResponse.displayName = 'proto.core.private.companies.UpdateCollectCompanyNameResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.core.private.companies.GetCollectCompanyNameRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.core.private.companies.GetCollectCompanyNameRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.core.private.companies.GetCollectCompanyNameRequest.displayName = 'proto.core.private.companies.GetCollectCompanyNameRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.core.private.companies.GetCollectCompanyNameResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.core.private.companies.GetCollectCompanyNameResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.core.private.companies.GetCollectCompanyNameResponse.displayName = 'proto.core.private.companies.GetCollectCompanyNameResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.core.private.companies.UpdateTrialLimitRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.core.private.companies.UpdateTrialLimitRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.core.private.companies.UpdateTrialLimitRequest.displayName = 'proto.core.private.companies.UpdateTrialLimitRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.core.private.companies.UpdateTrialLimitResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.core.private.companies.UpdateTrialLimitResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.core.private.companies.UpdateTrialLimitResponse.displayName = 'proto.core.private.companies.UpdateTrialLimitResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.core.private.companies.GetTrialLimitRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.core.private.companies.GetTrialLimitRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.core.private.companies.GetTrialLimitRequest.displayName = 'proto.core.private.companies.GetTrialLimitRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.core.private.companies.GetTrialLimitResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.core.private.companies.GetTrialLimitResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.core.private.companies.GetTrialLimitResponse.displayName = 'proto.core.private.companies.GetTrialLimitResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.core.private.companies.GetCompanyLanguagesRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.core.private.companies.GetCompanyLanguagesRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.core.private.companies.GetCompanyLanguagesRequest.displayName = 'proto.core.private.companies.GetCompanyLanguagesRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.core.private.companies.GetCompanyLanguagesResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.core.private.companies.GetCompanyLanguagesResponse.repeatedFields_, null);
};
goog.inherits(proto.core.private.companies.GetCompanyLanguagesResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.core.private.companies.GetCompanyLanguagesResponse.displayName = 'proto.core.private.companies.GetCompanyLanguagesResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.core.private.companies.UpdateCompanyLanguagesRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.core.private.companies.UpdateCompanyLanguagesRequest.repeatedFields_, null);
};
goog.inherits(proto.core.private.companies.UpdateCompanyLanguagesRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.core.private.companies.UpdateCompanyLanguagesRequest.displayName = 'proto.core.private.companies.UpdateCompanyLanguagesRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.core.private.companies.UpdateCompanyLanguagesResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.core.private.companies.UpdateCompanyLanguagesResponse.repeatedFields_, null);
};
goog.inherits(proto.core.private.companies.UpdateCompanyLanguagesResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.core.private.companies.UpdateCompanyLanguagesResponse.displayName = 'proto.core.private.companies.UpdateCompanyLanguagesResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.core.private.companies.CompanyLanguage = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.core.private.companies.CompanyLanguage, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.core.private.companies.CompanyLanguage.displayName = 'proto.core.private.companies.CompanyLanguage';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.core.private.companies.CompanyLanguageUpdate = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.core.private.companies.CompanyLanguageUpdate, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.core.private.companies.CompanyLanguageUpdate.displayName = 'proto.core.private.companies.CompanyLanguageUpdate';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.core.private.companies.DeleteCustomFieldRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.core.private.companies.DeleteCustomFieldRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.core.private.companies.DeleteCustomFieldRequest.displayName = 'proto.core.private.companies.DeleteCustomFieldRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.core.private.companies.DeleteCustomFieldResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.core.private.companies.DeleteCustomFieldResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.core.private.companies.DeleteCustomFieldResponse.displayName = 'proto.core.private.companies.DeleteCustomFieldResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.core.private.companies.GoLiveRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.core.private.companies.GoLiveRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.core.private.companies.GoLiveRequest.displayName = 'proto.core.private.companies.GoLiveRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.core.private.companies.GoLiveResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.core.private.companies.GoLiveResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.core.private.companies.GoLiveResponse.displayName = 'proto.core.private.companies.GoLiveResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.core.private.companies.Address = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.core.private.companies.Address, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.core.private.companies.Address.displayName = 'proto.core.private.companies.Address';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.core.private.companies.Transaction = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.core.private.companies.Transaction, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.core.private.companies.Transaction.displayName = 'proto.core.private.companies.Transaction';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.core.private.companies.CardDetails = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.core.private.companies.CardDetails, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.core.private.companies.CardDetails.displayName = 'proto.core.private.companies.CardDetails';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.core.private.companies.GetCompanyFeaturesStateRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.core.private.companies.GetCompanyFeaturesStateRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.core.private.companies.GetCompanyFeaturesStateRequest.displayName = 'proto.core.private.companies.GetCompanyFeaturesStateRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.core.private.companies.GetCompanyFeaturesStateResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.core.private.companies.GetCompanyFeaturesStateResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.core.private.companies.GetCompanyFeaturesStateResponse.displayName = 'proto.core.private.companies.GetCompanyFeaturesStateResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.core.private.companies.GetBillsbySubscriptionRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.core.private.companies.GetBillsbySubscriptionRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.core.private.companies.GetBillsbySubscriptionRequest.displayName = 'proto.core.private.companies.GetBillsbySubscriptionRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.core.private.companies.GetBillsbySubscriptionResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.core.private.companies.GetBillsbySubscriptionResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.core.private.companies.GetBillsbySubscriptionResponse.displayName = 'proto.core.private.companies.GetBillsbySubscriptionResponse';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.core.private.companies.DeleteCompanyRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.core.private.companies.DeleteCompanyRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.core.private.companies.DeleteCompanyRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.core.private.companies.DeleteCompanyRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    companyId: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.core.private.companies.DeleteCompanyRequest}
 */
proto.core.private.companies.DeleteCompanyRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.core.private.companies.DeleteCompanyRequest;
  return proto.core.private.companies.DeleteCompanyRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.core.private.companies.DeleteCompanyRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.core.private.companies.DeleteCompanyRequest}
 */
proto.core.private.companies.DeleteCompanyRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setCompanyId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.core.private.companies.DeleteCompanyRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.core.private.companies.DeleteCompanyRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.core.private.companies.DeleteCompanyRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.core.private.companies.DeleteCompanyRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCompanyId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
};


/**
 * optional int32 company_id = 1;
 * @return {number}
 */
proto.core.private.companies.DeleteCompanyRequest.prototype.getCompanyId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.core.private.companies.DeleteCompanyRequest} returns this
 */
proto.core.private.companies.DeleteCompanyRequest.prototype.setCompanyId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.core.private.companies.DeleteCompanyResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.core.private.companies.DeleteCompanyResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.core.private.companies.DeleteCompanyResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.core.private.companies.DeleteCompanyResponse.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.core.private.companies.DeleteCompanyResponse}
 */
proto.core.private.companies.DeleteCompanyResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.core.private.companies.DeleteCompanyResponse;
  return proto.core.private.companies.DeleteCompanyResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.core.private.companies.DeleteCompanyResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.core.private.companies.DeleteCompanyResponse}
 */
proto.core.private.companies.DeleteCompanyResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.core.private.companies.DeleteCompanyResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.core.private.companies.DeleteCompanyResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.core.private.companies.DeleteCompanyResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.core.private.companies.DeleteCompanyResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.core.private.companies.RequestDeleteCompanyRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.core.private.companies.RequestDeleteCompanyRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.core.private.companies.RequestDeleteCompanyRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.core.private.companies.RequestDeleteCompanyRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    companyId: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.core.private.companies.RequestDeleteCompanyRequest}
 */
proto.core.private.companies.RequestDeleteCompanyRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.core.private.companies.RequestDeleteCompanyRequest;
  return proto.core.private.companies.RequestDeleteCompanyRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.core.private.companies.RequestDeleteCompanyRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.core.private.companies.RequestDeleteCompanyRequest}
 */
proto.core.private.companies.RequestDeleteCompanyRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setCompanyId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.core.private.companies.RequestDeleteCompanyRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.core.private.companies.RequestDeleteCompanyRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.core.private.companies.RequestDeleteCompanyRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.core.private.companies.RequestDeleteCompanyRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCompanyId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
};


/**
 * optional int32 company_id = 1;
 * @return {number}
 */
proto.core.private.companies.RequestDeleteCompanyRequest.prototype.getCompanyId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.core.private.companies.RequestDeleteCompanyRequest} returns this
 */
proto.core.private.companies.RequestDeleteCompanyRequest.prototype.setCompanyId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.core.private.companies.RequestDeleteCompanyResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.core.private.companies.RequestDeleteCompanyResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.core.private.companies.RequestDeleteCompanyResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.core.private.companies.RequestDeleteCompanyResponse.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.core.private.companies.RequestDeleteCompanyResponse}
 */
proto.core.private.companies.RequestDeleteCompanyResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.core.private.companies.RequestDeleteCompanyResponse;
  return proto.core.private.companies.RequestDeleteCompanyResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.core.private.companies.RequestDeleteCompanyResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.core.private.companies.RequestDeleteCompanyResponse}
 */
proto.core.private.companies.RequestDeleteCompanyResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.core.private.companies.RequestDeleteCompanyResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.core.private.companies.RequestDeleteCompanyResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.core.private.companies.RequestDeleteCompanyResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.core.private.companies.RequestDeleteCompanyResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.core.private.companies.CancelDeleteCompanyRequestRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.core.private.companies.CancelDeleteCompanyRequestRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.core.private.companies.CancelDeleteCompanyRequestRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.core.private.companies.CancelDeleteCompanyRequestRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    companyId: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.core.private.companies.CancelDeleteCompanyRequestRequest}
 */
proto.core.private.companies.CancelDeleteCompanyRequestRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.core.private.companies.CancelDeleteCompanyRequestRequest;
  return proto.core.private.companies.CancelDeleteCompanyRequestRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.core.private.companies.CancelDeleteCompanyRequestRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.core.private.companies.CancelDeleteCompanyRequestRequest}
 */
proto.core.private.companies.CancelDeleteCompanyRequestRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setCompanyId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.core.private.companies.CancelDeleteCompanyRequestRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.core.private.companies.CancelDeleteCompanyRequestRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.core.private.companies.CancelDeleteCompanyRequestRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.core.private.companies.CancelDeleteCompanyRequestRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCompanyId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
};


/**
 * optional int32 company_id = 1;
 * @return {number}
 */
proto.core.private.companies.CancelDeleteCompanyRequestRequest.prototype.getCompanyId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.core.private.companies.CancelDeleteCompanyRequestRequest} returns this
 */
proto.core.private.companies.CancelDeleteCompanyRequestRequest.prototype.setCompanyId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.core.private.companies.CancelDeleteCompanyRequestResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.core.private.companies.CancelDeleteCompanyRequestResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.core.private.companies.CancelDeleteCompanyRequestResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.core.private.companies.CancelDeleteCompanyRequestResponse.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.core.private.companies.CancelDeleteCompanyRequestResponse}
 */
proto.core.private.companies.CancelDeleteCompanyRequestResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.core.private.companies.CancelDeleteCompanyRequestResponse;
  return proto.core.private.companies.CancelDeleteCompanyRequestResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.core.private.companies.CancelDeleteCompanyRequestResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.core.private.companies.CancelDeleteCompanyRequestResponse}
 */
proto.core.private.companies.CancelDeleteCompanyRequestResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.core.private.companies.CancelDeleteCompanyRequestResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.core.private.companies.CancelDeleteCompanyRequestResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.core.private.companies.CancelDeleteCompanyRequestResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.core.private.companies.CancelDeleteCompanyRequestResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.core.private.companies.UpdateCompanyDomainRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.core.private.companies.UpdateCompanyDomainRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.core.private.companies.UpdateCompanyDomainRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.core.private.companies.UpdateCompanyDomainRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    companyId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    companyDomain: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.core.private.companies.UpdateCompanyDomainRequest}
 */
proto.core.private.companies.UpdateCompanyDomainRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.core.private.companies.UpdateCompanyDomainRequest;
  return proto.core.private.companies.UpdateCompanyDomainRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.core.private.companies.UpdateCompanyDomainRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.core.private.companies.UpdateCompanyDomainRequest}
 */
proto.core.private.companies.UpdateCompanyDomainRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setCompanyId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setCompanyDomain(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.core.private.companies.UpdateCompanyDomainRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.core.private.companies.UpdateCompanyDomainRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.core.private.companies.UpdateCompanyDomainRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.core.private.companies.UpdateCompanyDomainRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCompanyId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getCompanyDomain();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional int32 company_id = 1;
 * @return {number}
 */
proto.core.private.companies.UpdateCompanyDomainRequest.prototype.getCompanyId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.core.private.companies.UpdateCompanyDomainRequest} returns this
 */
proto.core.private.companies.UpdateCompanyDomainRequest.prototype.setCompanyId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string company_domain = 2;
 * @return {string}
 */
proto.core.private.companies.UpdateCompanyDomainRequest.prototype.getCompanyDomain = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.core.private.companies.UpdateCompanyDomainRequest} returns this
 */
proto.core.private.companies.UpdateCompanyDomainRequest.prototype.setCompanyDomain = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.core.private.companies.UpdateCompanyDomainResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.core.private.companies.UpdateCompanyDomainResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.core.private.companies.UpdateCompanyDomainResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.core.private.companies.UpdateCompanyDomainResponse.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.core.private.companies.UpdateCompanyDomainResponse}
 */
proto.core.private.companies.UpdateCompanyDomainResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.core.private.companies.UpdateCompanyDomainResponse;
  return proto.core.private.companies.UpdateCompanyDomainResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.core.private.companies.UpdateCompanyDomainResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.core.private.companies.UpdateCompanyDomainResponse}
 */
proto.core.private.companies.UpdateCompanyDomainResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.core.private.companies.UpdateCompanyDomainResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.core.private.companies.UpdateCompanyDomainResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.core.private.companies.UpdateCompanyDomainResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.core.private.companies.UpdateCompanyDomainResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.core.private.companies.UpdateCollectCompanyNameRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.core.private.companies.UpdateCollectCompanyNameRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.core.private.companies.UpdateCollectCompanyNameRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.core.private.companies.UpdateCollectCompanyNameRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    companyId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    collectCompanyNameType: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.core.private.companies.UpdateCollectCompanyNameRequest}
 */
proto.core.private.companies.UpdateCollectCompanyNameRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.core.private.companies.UpdateCollectCompanyNameRequest;
  return proto.core.private.companies.UpdateCollectCompanyNameRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.core.private.companies.UpdateCollectCompanyNameRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.core.private.companies.UpdateCollectCompanyNameRequest}
 */
proto.core.private.companies.UpdateCollectCompanyNameRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setCompanyId(value);
      break;
    case 2:
      var value = /** @type {!proto.core.private.companies.CollectCompanyNameType} */ (reader.readEnum());
      msg.setCollectCompanyNameType(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.core.private.companies.UpdateCollectCompanyNameRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.core.private.companies.UpdateCollectCompanyNameRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.core.private.companies.UpdateCollectCompanyNameRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.core.private.companies.UpdateCollectCompanyNameRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCompanyId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getCollectCompanyNameType();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
};


/**
 * optional int32 company_id = 1;
 * @return {number}
 */
proto.core.private.companies.UpdateCollectCompanyNameRequest.prototype.getCompanyId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.core.private.companies.UpdateCollectCompanyNameRequest} returns this
 */
proto.core.private.companies.UpdateCollectCompanyNameRequest.prototype.setCompanyId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional CollectCompanyNameType collect_company_name_type = 2;
 * @return {!proto.core.private.companies.CollectCompanyNameType}
 */
proto.core.private.companies.UpdateCollectCompanyNameRequest.prototype.getCollectCompanyNameType = function() {
  return /** @type {!proto.core.private.companies.CollectCompanyNameType} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.core.private.companies.CollectCompanyNameType} value
 * @return {!proto.core.private.companies.UpdateCollectCompanyNameRequest} returns this
 */
proto.core.private.companies.UpdateCollectCompanyNameRequest.prototype.setCollectCompanyNameType = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.core.private.companies.UpdateCollectCompanyNameResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.core.private.companies.UpdateCollectCompanyNameResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.core.private.companies.UpdateCollectCompanyNameResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.core.private.companies.UpdateCollectCompanyNameResponse.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.core.private.companies.UpdateCollectCompanyNameResponse}
 */
proto.core.private.companies.UpdateCollectCompanyNameResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.core.private.companies.UpdateCollectCompanyNameResponse;
  return proto.core.private.companies.UpdateCollectCompanyNameResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.core.private.companies.UpdateCollectCompanyNameResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.core.private.companies.UpdateCollectCompanyNameResponse}
 */
proto.core.private.companies.UpdateCollectCompanyNameResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.core.private.companies.UpdateCollectCompanyNameResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.core.private.companies.UpdateCollectCompanyNameResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.core.private.companies.UpdateCollectCompanyNameResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.core.private.companies.UpdateCollectCompanyNameResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.core.private.companies.GetCollectCompanyNameRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.core.private.companies.GetCollectCompanyNameRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.core.private.companies.GetCollectCompanyNameRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.core.private.companies.GetCollectCompanyNameRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    companyId: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.core.private.companies.GetCollectCompanyNameRequest}
 */
proto.core.private.companies.GetCollectCompanyNameRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.core.private.companies.GetCollectCompanyNameRequest;
  return proto.core.private.companies.GetCollectCompanyNameRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.core.private.companies.GetCollectCompanyNameRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.core.private.companies.GetCollectCompanyNameRequest}
 */
proto.core.private.companies.GetCollectCompanyNameRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setCompanyId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.core.private.companies.GetCollectCompanyNameRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.core.private.companies.GetCollectCompanyNameRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.core.private.companies.GetCollectCompanyNameRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.core.private.companies.GetCollectCompanyNameRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCompanyId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
};


/**
 * optional int32 company_id = 1;
 * @return {number}
 */
proto.core.private.companies.GetCollectCompanyNameRequest.prototype.getCompanyId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.core.private.companies.GetCollectCompanyNameRequest} returns this
 */
proto.core.private.companies.GetCollectCompanyNameRequest.prototype.setCompanyId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.core.private.companies.GetCollectCompanyNameResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.core.private.companies.GetCollectCompanyNameResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.core.private.companies.GetCollectCompanyNameResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.core.private.companies.GetCollectCompanyNameResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    collectCompanyNameType: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.core.private.companies.GetCollectCompanyNameResponse}
 */
proto.core.private.companies.GetCollectCompanyNameResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.core.private.companies.GetCollectCompanyNameResponse;
  return proto.core.private.companies.GetCollectCompanyNameResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.core.private.companies.GetCollectCompanyNameResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.core.private.companies.GetCollectCompanyNameResponse}
 */
proto.core.private.companies.GetCollectCompanyNameResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.core.private.companies.CollectCompanyNameType} */ (reader.readEnum());
      msg.setCollectCompanyNameType(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.core.private.companies.GetCollectCompanyNameResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.core.private.companies.GetCollectCompanyNameResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.core.private.companies.GetCollectCompanyNameResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.core.private.companies.GetCollectCompanyNameResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCollectCompanyNameType();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
};


/**
 * optional CollectCompanyNameType collect_company_name_type = 1;
 * @return {!proto.core.private.companies.CollectCompanyNameType}
 */
proto.core.private.companies.GetCollectCompanyNameResponse.prototype.getCollectCompanyNameType = function() {
  return /** @type {!proto.core.private.companies.CollectCompanyNameType} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.core.private.companies.CollectCompanyNameType} value
 * @return {!proto.core.private.companies.GetCollectCompanyNameResponse} returns this
 */
proto.core.private.companies.GetCollectCompanyNameResponse.prototype.setCollectCompanyNameType = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.core.private.companies.UpdateTrialLimitRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.core.private.companies.UpdateTrialLimitRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.core.private.companies.UpdateTrialLimitRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.core.private.companies.UpdateTrialLimitRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    companyId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    trialLimit: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.core.private.companies.UpdateTrialLimitRequest}
 */
proto.core.private.companies.UpdateTrialLimitRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.core.private.companies.UpdateTrialLimitRequest;
  return proto.core.private.companies.UpdateTrialLimitRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.core.private.companies.UpdateTrialLimitRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.core.private.companies.UpdateTrialLimitRequest}
 */
proto.core.private.companies.UpdateTrialLimitRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setCompanyId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setTrialLimit(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.core.private.companies.UpdateTrialLimitRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.core.private.companies.UpdateTrialLimitRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.core.private.companies.UpdateTrialLimitRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.core.private.companies.UpdateTrialLimitRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCompanyId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getTrialLimit();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
};


/**
 * optional int32 company_id = 1;
 * @return {number}
 */
proto.core.private.companies.UpdateTrialLimitRequest.prototype.getCompanyId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.core.private.companies.UpdateTrialLimitRequest} returns this
 */
proto.core.private.companies.UpdateTrialLimitRequest.prototype.setCompanyId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int32 trial_limit = 2;
 * @return {number}
 */
proto.core.private.companies.UpdateTrialLimitRequest.prototype.getTrialLimit = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.core.private.companies.UpdateTrialLimitRequest} returns this
 */
proto.core.private.companies.UpdateTrialLimitRequest.prototype.setTrialLimit = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.core.private.companies.UpdateTrialLimitResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.core.private.companies.UpdateTrialLimitResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.core.private.companies.UpdateTrialLimitResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.core.private.companies.UpdateTrialLimitResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    trialLimit: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.core.private.companies.UpdateTrialLimitResponse}
 */
proto.core.private.companies.UpdateTrialLimitResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.core.private.companies.UpdateTrialLimitResponse;
  return proto.core.private.companies.UpdateTrialLimitResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.core.private.companies.UpdateTrialLimitResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.core.private.companies.UpdateTrialLimitResponse}
 */
proto.core.private.companies.UpdateTrialLimitResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setTrialLimit(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.core.private.companies.UpdateTrialLimitResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.core.private.companies.UpdateTrialLimitResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.core.private.companies.UpdateTrialLimitResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.core.private.companies.UpdateTrialLimitResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTrialLimit();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
};


/**
 * optional int32 trial_limit = 1;
 * @return {number}
 */
proto.core.private.companies.UpdateTrialLimitResponse.prototype.getTrialLimit = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.core.private.companies.UpdateTrialLimitResponse} returns this
 */
proto.core.private.companies.UpdateTrialLimitResponse.prototype.setTrialLimit = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.core.private.companies.GetTrialLimitRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.core.private.companies.GetTrialLimitRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.core.private.companies.GetTrialLimitRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.core.private.companies.GetTrialLimitRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    companyId: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.core.private.companies.GetTrialLimitRequest}
 */
proto.core.private.companies.GetTrialLimitRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.core.private.companies.GetTrialLimitRequest;
  return proto.core.private.companies.GetTrialLimitRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.core.private.companies.GetTrialLimitRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.core.private.companies.GetTrialLimitRequest}
 */
proto.core.private.companies.GetTrialLimitRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setCompanyId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.core.private.companies.GetTrialLimitRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.core.private.companies.GetTrialLimitRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.core.private.companies.GetTrialLimitRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.core.private.companies.GetTrialLimitRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCompanyId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
};


/**
 * optional int32 company_id = 1;
 * @return {number}
 */
proto.core.private.companies.GetTrialLimitRequest.prototype.getCompanyId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.core.private.companies.GetTrialLimitRequest} returns this
 */
proto.core.private.companies.GetTrialLimitRequest.prototype.setCompanyId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.core.private.companies.GetTrialLimitResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.core.private.companies.GetTrialLimitResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.core.private.companies.GetTrialLimitResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.core.private.companies.GetTrialLimitResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    trialLimit: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.core.private.companies.GetTrialLimitResponse}
 */
proto.core.private.companies.GetTrialLimitResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.core.private.companies.GetTrialLimitResponse;
  return proto.core.private.companies.GetTrialLimitResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.core.private.companies.GetTrialLimitResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.core.private.companies.GetTrialLimitResponse}
 */
proto.core.private.companies.GetTrialLimitResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setTrialLimit(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.core.private.companies.GetTrialLimitResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.core.private.companies.GetTrialLimitResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.core.private.companies.GetTrialLimitResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.core.private.companies.GetTrialLimitResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTrialLimit();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
};


/**
 * optional int32 trial_limit = 1;
 * @return {number}
 */
proto.core.private.companies.GetTrialLimitResponse.prototype.getTrialLimit = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.core.private.companies.GetTrialLimitResponse} returns this
 */
proto.core.private.companies.GetTrialLimitResponse.prototype.setTrialLimit = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.core.private.companies.GetCompanyLanguagesRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.core.private.companies.GetCompanyLanguagesRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.core.private.companies.GetCompanyLanguagesRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.core.private.companies.GetCompanyLanguagesRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    companyId: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.core.private.companies.GetCompanyLanguagesRequest}
 */
proto.core.private.companies.GetCompanyLanguagesRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.core.private.companies.GetCompanyLanguagesRequest;
  return proto.core.private.companies.GetCompanyLanguagesRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.core.private.companies.GetCompanyLanguagesRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.core.private.companies.GetCompanyLanguagesRequest}
 */
proto.core.private.companies.GetCompanyLanguagesRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setCompanyId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.core.private.companies.GetCompanyLanguagesRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.core.private.companies.GetCompanyLanguagesRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.core.private.companies.GetCompanyLanguagesRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.core.private.companies.GetCompanyLanguagesRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCompanyId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
};


/**
 * optional int32 company_id = 1;
 * @return {number}
 */
proto.core.private.companies.GetCompanyLanguagesRequest.prototype.getCompanyId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.core.private.companies.GetCompanyLanguagesRequest} returns this
 */
proto.core.private.companies.GetCompanyLanguagesRequest.prototype.setCompanyId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.core.private.companies.GetCompanyLanguagesResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.core.private.companies.GetCompanyLanguagesResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.core.private.companies.GetCompanyLanguagesResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.core.private.companies.GetCompanyLanguagesResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.core.private.companies.GetCompanyLanguagesResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    companyLanguagesList: jspb.Message.toObjectList(msg.getCompanyLanguagesList(),
    proto.core.private.companies.CompanyLanguage.toObject, includeInstance),
    defaultCheckoutLanguage: jspb.Message.getFieldWithDefault(msg, 2, ""),
    defaultAccountManagementLanguage: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.core.private.companies.GetCompanyLanguagesResponse}
 */
proto.core.private.companies.GetCompanyLanguagesResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.core.private.companies.GetCompanyLanguagesResponse;
  return proto.core.private.companies.GetCompanyLanguagesResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.core.private.companies.GetCompanyLanguagesResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.core.private.companies.GetCompanyLanguagesResponse}
 */
proto.core.private.companies.GetCompanyLanguagesResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.core.private.companies.CompanyLanguage;
      reader.readMessage(value,proto.core.private.companies.CompanyLanguage.deserializeBinaryFromReader);
      msg.addCompanyLanguages(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setDefaultCheckoutLanguage(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setDefaultAccountManagementLanguage(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.core.private.companies.GetCompanyLanguagesResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.core.private.companies.GetCompanyLanguagesResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.core.private.companies.GetCompanyLanguagesResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.core.private.companies.GetCompanyLanguagesResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCompanyLanguagesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.core.private.companies.CompanyLanguage.serializeBinaryToWriter
    );
  }
  f = message.getDefaultCheckoutLanguage();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getDefaultAccountManagementLanguage();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * repeated CompanyLanguage company_languages = 1;
 * @return {!Array<!proto.core.private.companies.CompanyLanguage>}
 */
proto.core.private.companies.GetCompanyLanguagesResponse.prototype.getCompanyLanguagesList = function() {
  return /** @type{!Array<!proto.core.private.companies.CompanyLanguage>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.core.private.companies.CompanyLanguage, 1));
};


/**
 * @param {!Array<!proto.core.private.companies.CompanyLanguage>} value
 * @return {!proto.core.private.companies.GetCompanyLanguagesResponse} returns this
*/
proto.core.private.companies.GetCompanyLanguagesResponse.prototype.setCompanyLanguagesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.core.private.companies.CompanyLanguage=} opt_value
 * @param {number=} opt_index
 * @return {!proto.core.private.companies.CompanyLanguage}
 */
proto.core.private.companies.GetCompanyLanguagesResponse.prototype.addCompanyLanguages = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.core.private.companies.CompanyLanguage, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.core.private.companies.GetCompanyLanguagesResponse} returns this
 */
proto.core.private.companies.GetCompanyLanguagesResponse.prototype.clearCompanyLanguagesList = function() {
  return this.setCompanyLanguagesList([]);
};


/**
 * optional string default_checkout_language = 2;
 * @return {string}
 */
proto.core.private.companies.GetCompanyLanguagesResponse.prototype.getDefaultCheckoutLanguage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.core.private.companies.GetCompanyLanguagesResponse} returns this
 */
proto.core.private.companies.GetCompanyLanguagesResponse.prototype.setDefaultCheckoutLanguage = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string default_account_management_language = 3;
 * @return {string}
 */
proto.core.private.companies.GetCompanyLanguagesResponse.prototype.getDefaultAccountManagementLanguage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.core.private.companies.GetCompanyLanguagesResponse} returns this
 */
proto.core.private.companies.GetCompanyLanguagesResponse.prototype.setDefaultAccountManagementLanguage = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.core.private.companies.UpdateCompanyLanguagesRequest.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.core.private.companies.UpdateCompanyLanguagesRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.core.private.companies.UpdateCompanyLanguagesRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.core.private.companies.UpdateCompanyLanguagesRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.core.private.companies.UpdateCompanyLanguagesRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    companyId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    companyLanguageUpdateInputsList: jspb.Message.toObjectList(msg.getCompanyLanguageUpdateInputsList(),
    proto.core.private.companies.CompanyLanguageUpdate.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.core.private.companies.UpdateCompanyLanguagesRequest}
 */
proto.core.private.companies.UpdateCompanyLanguagesRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.core.private.companies.UpdateCompanyLanguagesRequest;
  return proto.core.private.companies.UpdateCompanyLanguagesRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.core.private.companies.UpdateCompanyLanguagesRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.core.private.companies.UpdateCompanyLanguagesRequest}
 */
proto.core.private.companies.UpdateCompanyLanguagesRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setCompanyId(value);
      break;
    case 2:
      var value = new proto.core.private.companies.CompanyLanguageUpdate;
      reader.readMessage(value,proto.core.private.companies.CompanyLanguageUpdate.deserializeBinaryFromReader);
      msg.addCompanyLanguageUpdateInputs(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.core.private.companies.UpdateCompanyLanguagesRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.core.private.companies.UpdateCompanyLanguagesRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.core.private.companies.UpdateCompanyLanguagesRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.core.private.companies.UpdateCompanyLanguagesRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCompanyId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getCompanyLanguageUpdateInputsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.core.private.companies.CompanyLanguageUpdate.serializeBinaryToWriter
    );
  }
};


/**
 * optional int32 company_id = 1;
 * @return {number}
 */
proto.core.private.companies.UpdateCompanyLanguagesRequest.prototype.getCompanyId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.core.private.companies.UpdateCompanyLanguagesRequest} returns this
 */
proto.core.private.companies.UpdateCompanyLanguagesRequest.prototype.setCompanyId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * repeated CompanyLanguageUpdate company_language_update_inputs = 2;
 * @return {!Array<!proto.core.private.companies.CompanyLanguageUpdate>}
 */
proto.core.private.companies.UpdateCompanyLanguagesRequest.prototype.getCompanyLanguageUpdateInputsList = function() {
  return /** @type{!Array<!proto.core.private.companies.CompanyLanguageUpdate>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.core.private.companies.CompanyLanguageUpdate, 2));
};


/**
 * @param {!Array<!proto.core.private.companies.CompanyLanguageUpdate>} value
 * @return {!proto.core.private.companies.UpdateCompanyLanguagesRequest} returns this
*/
proto.core.private.companies.UpdateCompanyLanguagesRequest.prototype.setCompanyLanguageUpdateInputsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.core.private.companies.CompanyLanguageUpdate=} opt_value
 * @param {number=} opt_index
 * @return {!proto.core.private.companies.CompanyLanguageUpdate}
 */
proto.core.private.companies.UpdateCompanyLanguagesRequest.prototype.addCompanyLanguageUpdateInputs = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.core.private.companies.CompanyLanguageUpdate, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.core.private.companies.UpdateCompanyLanguagesRequest} returns this
 */
proto.core.private.companies.UpdateCompanyLanguagesRequest.prototype.clearCompanyLanguageUpdateInputsList = function() {
  return this.setCompanyLanguageUpdateInputsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.core.private.companies.UpdateCompanyLanguagesResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.core.private.companies.UpdateCompanyLanguagesResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.core.private.companies.UpdateCompanyLanguagesResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.core.private.companies.UpdateCompanyLanguagesResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.core.private.companies.UpdateCompanyLanguagesResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    companyLanguagesList: jspb.Message.toObjectList(msg.getCompanyLanguagesList(),
    proto.core.private.companies.CompanyLanguage.toObject, includeInstance),
    defaultCheckoutLanguage: jspb.Message.getFieldWithDefault(msg, 2, ""),
    defaultAccountManagementLanguage: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.core.private.companies.UpdateCompanyLanguagesResponse}
 */
proto.core.private.companies.UpdateCompanyLanguagesResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.core.private.companies.UpdateCompanyLanguagesResponse;
  return proto.core.private.companies.UpdateCompanyLanguagesResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.core.private.companies.UpdateCompanyLanguagesResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.core.private.companies.UpdateCompanyLanguagesResponse}
 */
proto.core.private.companies.UpdateCompanyLanguagesResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.core.private.companies.CompanyLanguage;
      reader.readMessage(value,proto.core.private.companies.CompanyLanguage.deserializeBinaryFromReader);
      msg.addCompanyLanguages(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setDefaultCheckoutLanguage(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setDefaultAccountManagementLanguage(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.core.private.companies.UpdateCompanyLanguagesResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.core.private.companies.UpdateCompanyLanguagesResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.core.private.companies.UpdateCompanyLanguagesResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.core.private.companies.UpdateCompanyLanguagesResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCompanyLanguagesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.core.private.companies.CompanyLanguage.serializeBinaryToWriter
    );
  }
  f = message.getDefaultCheckoutLanguage();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getDefaultAccountManagementLanguage();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * repeated CompanyLanguage company_languages = 1;
 * @return {!Array<!proto.core.private.companies.CompanyLanguage>}
 */
proto.core.private.companies.UpdateCompanyLanguagesResponse.prototype.getCompanyLanguagesList = function() {
  return /** @type{!Array<!proto.core.private.companies.CompanyLanguage>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.core.private.companies.CompanyLanguage, 1));
};


/**
 * @param {!Array<!proto.core.private.companies.CompanyLanguage>} value
 * @return {!proto.core.private.companies.UpdateCompanyLanguagesResponse} returns this
*/
proto.core.private.companies.UpdateCompanyLanguagesResponse.prototype.setCompanyLanguagesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.core.private.companies.CompanyLanguage=} opt_value
 * @param {number=} opt_index
 * @return {!proto.core.private.companies.CompanyLanguage}
 */
proto.core.private.companies.UpdateCompanyLanguagesResponse.prototype.addCompanyLanguages = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.core.private.companies.CompanyLanguage, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.core.private.companies.UpdateCompanyLanguagesResponse} returns this
 */
proto.core.private.companies.UpdateCompanyLanguagesResponse.prototype.clearCompanyLanguagesList = function() {
  return this.setCompanyLanguagesList([]);
};


/**
 * optional string default_checkout_language = 2;
 * @return {string}
 */
proto.core.private.companies.UpdateCompanyLanguagesResponse.prototype.getDefaultCheckoutLanguage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.core.private.companies.UpdateCompanyLanguagesResponse} returns this
 */
proto.core.private.companies.UpdateCompanyLanguagesResponse.prototype.setDefaultCheckoutLanguage = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string default_account_management_language = 3;
 * @return {string}
 */
proto.core.private.companies.UpdateCompanyLanguagesResponse.prototype.getDefaultAccountManagementLanguage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.core.private.companies.UpdateCompanyLanguagesResponse} returns this
 */
proto.core.private.companies.UpdateCompanyLanguagesResponse.prototype.setDefaultAccountManagementLanguage = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.core.private.companies.CompanyLanguage.prototype.toObject = function(opt_includeInstance) {
  return proto.core.private.companies.CompanyLanguage.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.core.private.companies.CompanyLanguage} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.core.private.companies.CompanyLanguage.toObject = function(includeInstance, msg) {
  var f, obj = {
    englishName: jspb.Message.getFieldWithDefault(msg, 1, ""),
    nativeName: jspb.Message.getFieldWithDefault(msg, 2, ""),
    isoCode: jspb.Message.getFieldWithDefault(msg, 3, ""),
    isEnabled: jspb.Message.getBooleanFieldWithDefault(msg, 4, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.core.private.companies.CompanyLanguage}
 */
proto.core.private.companies.CompanyLanguage.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.core.private.companies.CompanyLanguage;
  return proto.core.private.companies.CompanyLanguage.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.core.private.companies.CompanyLanguage} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.core.private.companies.CompanyLanguage}
 */
proto.core.private.companies.CompanyLanguage.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setEnglishName(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setNativeName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setIsoCode(value);
      break;
    case 4:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsEnabled(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.core.private.companies.CompanyLanguage.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.core.private.companies.CompanyLanguage.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.core.private.companies.CompanyLanguage} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.core.private.companies.CompanyLanguage.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getEnglishName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getNativeName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getIsoCode();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getIsEnabled();
  if (f) {
    writer.writeBool(
      4,
      f
    );
  }
};


/**
 * optional string english_name = 1;
 * @return {string}
 */
proto.core.private.companies.CompanyLanguage.prototype.getEnglishName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.core.private.companies.CompanyLanguage} returns this
 */
proto.core.private.companies.CompanyLanguage.prototype.setEnglishName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string native_name = 2;
 * @return {string}
 */
proto.core.private.companies.CompanyLanguage.prototype.getNativeName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.core.private.companies.CompanyLanguage} returns this
 */
proto.core.private.companies.CompanyLanguage.prototype.setNativeName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string iso_code = 3;
 * @return {string}
 */
proto.core.private.companies.CompanyLanguage.prototype.getIsoCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.core.private.companies.CompanyLanguage} returns this
 */
proto.core.private.companies.CompanyLanguage.prototype.setIsoCode = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional bool is_enabled = 4;
 * @return {boolean}
 */
proto.core.private.companies.CompanyLanguage.prototype.getIsEnabled = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 4, false));
};


/**
 * @param {boolean} value
 * @return {!proto.core.private.companies.CompanyLanguage} returns this
 */
proto.core.private.companies.CompanyLanguage.prototype.setIsEnabled = function(value) {
  return jspb.Message.setProto3BooleanField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.core.private.companies.CompanyLanguageUpdate.prototype.toObject = function(opt_includeInstance) {
  return proto.core.private.companies.CompanyLanguageUpdate.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.core.private.companies.CompanyLanguageUpdate} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.core.private.companies.CompanyLanguageUpdate.toObject = function(includeInstance, msg) {
  var f, obj = {
    isoCode: jspb.Message.getFieldWithDefault(msg, 1, ""),
    isEnabled: jspb.Message.getBooleanFieldWithDefault(msg, 2, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.core.private.companies.CompanyLanguageUpdate}
 */
proto.core.private.companies.CompanyLanguageUpdate.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.core.private.companies.CompanyLanguageUpdate;
  return proto.core.private.companies.CompanyLanguageUpdate.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.core.private.companies.CompanyLanguageUpdate} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.core.private.companies.CompanyLanguageUpdate}
 */
proto.core.private.companies.CompanyLanguageUpdate.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setIsoCode(value);
      break;
    case 2:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsEnabled(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.core.private.companies.CompanyLanguageUpdate.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.core.private.companies.CompanyLanguageUpdate.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.core.private.companies.CompanyLanguageUpdate} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.core.private.companies.CompanyLanguageUpdate.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getIsoCode();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getIsEnabled();
  if (f) {
    writer.writeBool(
      2,
      f
    );
  }
};


/**
 * optional string iso_code = 1;
 * @return {string}
 */
proto.core.private.companies.CompanyLanguageUpdate.prototype.getIsoCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.core.private.companies.CompanyLanguageUpdate} returns this
 */
proto.core.private.companies.CompanyLanguageUpdate.prototype.setIsoCode = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional bool is_enabled = 2;
 * @return {boolean}
 */
proto.core.private.companies.CompanyLanguageUpdate.prototype.getIsEnabled = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
};


/**
 * @param {boolean} value
 * @return {!proto.core.private.companies.CompanyLanguageUpdate} returns this
 */
proto.core.private.companies.CompanyLanguageUpdate.prototype.setIsEnabled = function(value) {
  return jspb.Message.setProto3BooleanField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.core.private.companies.DeleteCustomFieldRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.core.private.companies.DeleteCustomFieldRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.core.private.companies.DeleteCustomFieldRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.core.private.companies.DeleteCustomFieldRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    companyId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    customFieldId: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.core.private.companies.DeleteCustomFieldRequest}
 */
proto.core.private.companies.DeleteCustomFieldRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.core.private.companies.DeleteCustomFieldRequest;
  return proto.core.private.companies.DeleteCustomFieldRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.core.private.companies.DeleteCustomFieldRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.core.private.companies.DeleteCustomFieldRequest}
 */
proto.core.private.companies.DeleteCustomFieldRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setCompanyId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setCustomFieldId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.core.private.companies.DeleteCustomFieldRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.core.private.companies.DeleteCustomFieldRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.core.private.companies.DeleteCustomFieldRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.core.private.companies.DeleteCustomFieldRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCompanyId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getCustomFieldId();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
};


/**
 * optional int32 company_id = 1;
 * @return {number}
 */
proto.core.private.companies.DeleteCustomFieldRequest.prototype.getCompanyId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.core.private.companies.DeleteCustomFieldRequest} returns this
 */
proto.core.private.companies.DeleteCustomFieldRequest.prototype.setCompanyId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int64 custom_field_id = 2;
 * @return {number}
 */
proto.core.private.companies.DeleteCustomFieldRequest.prototype.getCustomFieldId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.core.private.companies.DeleteCustomFieldRequest} returns this
 */
proto.core.private.companies.DeleteCustomFieldRequest.prototype.setCustomFieldId = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.core.private.companies.DeleteCustomFieldResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.core.private.companies.DeleteCustomFieldResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.core.private.companies.DeleteCustomFieldResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.core.private.companies.DeleteCustomFieldResponse.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.core.private.companies.DeleteCustomFieldResponse}
 */
proto.core.private.companies.DeleteCustomFieldResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.core.private.companies.DeleteCustomFieldResponse;
  return proto.core.private.companies.DeleteCustomFieldResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.core.private.companies.DeleteCustomFieldResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.core.private.companies.DeleteCustomFieldResponse}
 */
proto.core.private.companies.DeleteCustomFieldResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.core.private.companies.DeleteCustomFieldResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.core.private.companies.DeleteCustomFieldResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.core.private.companies.DeleteCustomFieldResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.core.private.companies.DeleteCustomFieldResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.core.private.companies.GoLiveRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.core.private.companies.GoLiveRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.core.private.companies.GoLiveRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.core.private.companies.GoLiveRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    companyId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    cardDetails: (f = msg.getCardDetails()) && proto.core.private.companies.CardDetails.toObject(includeInstance, f),
    transaction: (f = msg.getTransaction()) && proto.core.private.companies.Transaction.toObject(includeInstance, f),
    billingAddress: (f = msg.getBillingAddress()) && proto.core.private.companies.Address.toObject(includeInstance, f),
    whiteLabelChecked: jspb.Message.getBooleanFieldWithDefault(msg, 6, false),
    billsbyPlanId: jspb.Message.getFieldWithDefault(msg, 7, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.core.private.companies.GoLiveRequest}
 */
proto.core.private.companies.GoLiveRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.core.private.companies.GoLiveRequest;
  return proto.core.private.companies.GoLiveRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.core.private.companies.GoLiveRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.core.private.companies.GoLiveRequest}
 */
proto.core.private.companies.GoLiveRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setCompanyId(value);
      break;
    case 2:
      var value = new proto.core.private.companies.CardDetails;
      reader.readMessage(value,proto.core.private.companies.CardDetails.deserializeBinaryFromReader);
      msg.setCardDetails(value);
      break;
    case 3:
      var value = new proto.core.private.companies.Transaction;
      reader.readMessage(value,proto.core.private.companies.Transaction.deserializeBinaryFromReader);
      msg.setTransaction(value);
      break;
    case 4:
      var value = new proto.core.private.companies.Address;
      reader.readMessage(value,proto.core.private.companies.Address.deserializeBinaryFromReader);
      msg.setBillingAddress(value);
      break;
    case 6:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setWhiteLabelChecked(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setBillsbyPlanId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.core.private.companies.GoLiveRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.core.private.companies.GoLiveRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.core.private.companies.GoLiveRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.core.private.companies.GoLiveRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCompanyId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getCardDetails();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.core.private.companies.CardDetails.serializeBinaryToWriter
    );
  }
  f = message.getTransaction();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.core.private.companies.Transaction.serializeBinaryToWriter
    );
  }
  f = message.getBillingAddress();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.core.private.companies.Address.serializeBinaryToWriter
    );
  }
  f = message.getWhiteLabelChecked();
  if (f) {
    writer.writeBool(
      6,
      f
    );
  }
  f = message.getBillsbyPlanId();
  if (f !== 0) {
    writer.writeInt32(
      7,
      f
    );
  }
};


/**
 * optional int32 company_id = 1;
 * @return {number}
 */
proto.core.private.companies.GoLiveRequest.prototype.getCompanyId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.core.private.companies.GoLiveRequest} returns this
 */
proto.core.private.companies.GoLiveRequest.prototype.setCompanyId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional CardDetails card_details = 2;
 * @return {?proto.core.private.companies.CardDetails}
 */
proto.core.private.companies.GoLiveRequest.prototype.getCardDetails = function() {
  return /** @type{?proto.core.private.companies.CardDetails} */ (
    jspb.Message.getWrapperField(this, proto.core.private.companies.CardDetails, 2));
};


/**
 * @param {?proto.core.private.companies.CardDetails|undefined} value
 * @return {!proto.core.private.companies.GoLiveRequest} returns this
*/
proto.core.private.companies.GoLiveRequest.prototype.setCardDetails = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.core.private.companies.GoLiveRequest} returns this
 */
proto.core.private.companies.GoLiveRequest.prototype.clearCardDetails = function() {
  return this.setCardDetails(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.core.private.companies.GoLiveRequest.prototype.hasCardDetails = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional Transaction transaction = 3;
 * @return {?proto.core.private.companies.Transaction}
 */
proto.core.private.companies.GoLiveRequest.prototype.getTransaction = function() {
  return /** @type{?proto.core.private.companies.Transaction} */ (
    jspb.Message.getWrapperField(this, proto.core.private.companies.Transaction, 3));
};


/**
 * @param {?proto.core.private.companies.Transaction|undefined} value
 * @return {!proto.core.private.companies.GoLiveRequest} returns this
*/
proto.core.private.companies.GoLiveRequest.prototype.setTransaction = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.core.private.companies.GoLiveRequest} returns this
 */
proto.core.private.companies.GoLiveRequest.prototype.clearTransaction = function() {
  return this.setTransaction(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.core.private.companies.GoLiveRequest.prototype.hasTransaction = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional Address billing_address = 4;
 * @return {?proto.core.private.companies.Address}
 */
proto.core.private.companies.GoLiveRequest.prototype.getBillingAddress = function() {
  return /** @type{?proto.core.private.companies.Address} */ (
    jspb.Message.getWrapperField(this, proto.core.private.companies.Address, 4));
};


/**
 * @param {?proto.core.private.companies.Address|undefined} value
 * @return {!proto.core.private.companies.GoLiveRequest} returns this
*/
proto.core.private.companies.GoLiveRequest.prototype.setBillingAddress = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.core.private.companies.GoLiveRequest} returns this
 */
proto.core.private.companies.GoLiveRequest.prototype.clearBillingAddress = function() {
  return this.setBillingAddress(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.core.private.companies.GoLiveRequest.prototype.hasBillingAddress = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional bool white_label_checked = 6;
 * @return {boolean}
 */
proto.core.private.companies.GoLiveRequest.prototype.getWhiteLabelChecked = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 6, false));
};


/**
 * @param {boolean} value
 * @return {!proto.core.private.companies.GoLiveRequest} returns this
 */
proto.core.private.companies.GoLiveRequest.prototype.setWhiteLabelChecked = function(value) {
  return jspb.Message.setProto3BooleanField(this, 6, value);
};


/**
 * optional int32 billsby_plan_id = 7;
 * @return {number}
 */
proto.core.private.companies.GoLiveRequest.prototype.getBillsbyPlanId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.core.private.companies.GoLiveRequest} returns this
 */
proto.core.private.companies.GoLiveRequest.prototype.setBillsbyPlanId = function(value) {
  return jspb.Message.setProto3IntField(this, 7, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.core.private.companies.GoLiveResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.core.private.companies.GoLiveResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.core.private.companies.GoLiveResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.core.private.companies.GoLiveResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    processCompletedSuccessfully: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    paymentStatus: jspb.Message.getFieldWithDefault(msg, 2, 0),
    transactionToken: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.core.private.companies.GoLiveResponse}
 */
proto.core.private.companies.GoLiveResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.core.private.companies.GoLiveResponse;
  return proto.core.private.companies.GoLiveResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.core.private.companies.GoLiveResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.core.private.companies.GoLiveResponse}
 */
proto.core.private.companies.GoLiveResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setProcessCompletedSuccessfully(value);
      break;
    case 2:
      var value = /** @type {!proto.core.private.companies.PaymentStatus} */ (reader.readEnum());
      msg.setPaymentStatus(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setTransactionToken(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.core.private.companies.GoLiveResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.core.private.companies.GoLiveResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.core.private.companies.GoLiveResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.core.private.companies.GoLiveResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getProcessCompletedSuccessfully();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getPaymentStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
  f = message.getTransactionToken();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional bool process_completed_successfully = 1;
 * @return {boolean}
 */
proto.core.private.companies.GoLiveResponse.prototype.getProcessCompletedSuccessfully = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.core.private.companies.GoLiveResponse} returns this
 */
proto.core.private.companies.GoLiveResponse.prototype.setProcessCompletedSuccessfully = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional PaymentStatus payment_status = 2;
 * @return {!proto.core.private.companies.PaymentStatus}
 */
proto.core.private.companies.GoLiveResponse.prototype.getPaymentStatus = function() {
  return /** @type {!proto.core.private.companies.PaymentStatus} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.core.private.companies.PaymentStatus} value
 * @return {!proto.core.private.companies.GoLiveResponse} returns this
 */
proto.core.private.companies.GoLiveResponse.prototype.setPaymentStatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};


/**
 * optional string transaction_token = 3;
 * @return {string}
 */
proto.core.private.companies.GoLiveResponse.prototype.getTransactionToken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.core.private.companies.GoLiveResponse} returns this
 */
proto.core.private.companies.GoLiveResponse.prototype.setTransactionToken = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.core.private.companies.Address.prototype.toObject = function(opt_includeInstance) {
  return proto.core.private.companies.Address.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.core.private.companies.Address} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.core.private.companies.Address.toObject = function(includeInstance, msg) {
  var f, obj = {
    lineOne: jspb.Message.getFieldWithDefault(msg, 1, ""),
    lineTwo: jspb.Message.getFieldWithDefault(msg, 2, ""),
    postCode: jspb.Message.getFieldWithDefault(msg, 3, ""),
    state: jspb.Message.getFieldWithDefault(msg, 4, ""),
    city: jspb.Message.getFieldWithDefault(msg, 5, ""),
    countryIso3: jspb.Message.getFieldWithDefault(msg, 6, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.core.private.companies.Address}
 */
proto.core.private.companies.Address.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.core.private.companies.Address;
  return proto.core.private.companies.Address.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.core.private.companies.Address} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.core.private.companies.Address}
 */
proto.core.private.companies.Address.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setLineOne(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setLineTwo(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setPostCode(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setState(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setCity(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setCountryIso3(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.core.private.companies.Address.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.core.private.companies.Address.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.core.private.companies.Address} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.core.private.companies.Address.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getLineOne();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getLineTwo();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getPostCode();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getState();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getCity();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getCountryIso3();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
};


/**
 * optional string line_one = 1;
 * @return {string}
 */
proto.core.private.companies.Address.prototype.getLineOne = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.core.private.companies.Address} returns this
 */
proto.core.private.companies.Address.prototype.setLineOne = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string line_two = 2;
 * @return {string}
 */
proto.core.private.companies.Address.prototype.getLineTwo = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.core.private.companies.Address} returns this
 */
proto.core.private.companies.Address.prototype.setLineTwo = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string post_code = 3;
 * @return {string}
 */
proto.core.private.companies.Address.prototype.getPostCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.core.private.companies.Address} returns this
 */
proto.core.private.companies.Address.prototype.setPostCode = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string state = 4;
 * @return {string}
 */
proto.core.private.companies.Address.prototype.getState = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.core.private.companies.Address} returns this
 */
proto.core.private.companies.Address.prototype.setState = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string city = 5;
 * @return {string}
 */
proto.core.private.companies.Address.prototype.getCity = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.core.private.companies.Address} returns this
 */
proto.core.private.companies.Address.prototype.setCity = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string country_iso3 = 6;
 * @return {string}
 */
proto.core.private.companies.Address.prototype.getCountryIso3 = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.core.private.companies.Address} returns this
 */
proto.core.private.companies.Address.prototype.setCountryIso3 = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.core.private.companies.Transaction.prototype.toObject = function(opt_includeInstance) {
  return proto.core.private.companies.Transaction.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.core.private.companies.Transaction} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.core.private.companies.Transaction.toObject = function(includeInstance, msg) {
  var f, obj = {
    transactionToken: jspb.Message.getFieldWithDefault(msg, 1, ""),
    browserInfo: jspb.Message.getFieldWithDefault(msg, 2, ""),
    ipAddress: jspb.Message.getFieldWithDefault(msg, 3, ""),
    redirectUrl: jspb.Message.getFieldWithDefault(msg, 4, ""),
    callbackUrl: jspb.Message.getFieldWithDefault(msg, 5, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.core.private.companies.Transaction}
 */
proto.core.private.companies.Transaction.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.core.private.companies.Transaction;
  return proto.core.private.companies.Transaction.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.core.private.companies.Transaction} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.core.private.companies.Transaction}
 */
proto.core.private.companies.Transaction.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setTransactionToken(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setBrowserInfo(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setIpAddress(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setRedirectUrl(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setCallbackUrl(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.core.private.companies.Transaction.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.core.private.companies.Transaction.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.core.private.companies.Transaction} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.core.private.companies.Transaction.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTransactionToken();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getBrowserInfo();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getIpAddress();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getRedirectUrl();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getCallbackUrl();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
};


/**
 * optional string transaction_token = 1;
 * @return {string}
 */
proto.core.private.companies.Transaction.prototype.getTransactionToken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.core.private.companies.Transaction} returns this
 */
proto.core.private.companies.Transaction.prototype.setTransactionToken = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string browser_info = 2;
 * @return {string}
 */
proto.core.private.companies.Transaction.prototype.getBrowserInfo = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.core.private.companies.Transaction} returns this
 */
proto.core.private.companies.Transaction.prototype.setBrowserInfo = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string ip_address = 3;
 * @return {string}
 */
proto.core.private.companies.Transaction.prototype.getIpAddress = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.core.private.companies.Transaction} returns this
 */
proto.core.private.companies.Transaction.prototype.setIpAddress = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string redirect_url = 4;
 * @return {string}
 */
proto.core.private.companies.Transaction.prototype.getRedirectUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.core.private.companies.Transaction} returns this
 */
proto.core.private.companies.Transaction.prototype.setRedirectUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string callback_url = 5;
 * @return {string}
 */
proto.core.private.companies.Transaction.prototype.getCallbackUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.core.private.companies.Transaction} returns this
 */
proto.core.private.companies.Transaction.prototype.setCallbackUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.core.private.companies.CardDetails.prototype.toObject = function(opt_includeInstance) {
  return proto.core.private.companies.CardDetails.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.core.private.companies.CardDetails} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.core.private.companies.CardDetails.toObject = function(includeInstance, msg) {
  var f, obj = {
    cardholderName: jspb.Message.getFieldWithDefault(msg, 1, ""),
    paymentMethodToken: jspb.Message.getFieldWithDefault(msg, 2, ""),
    expiryMonth: jspb.Message.getFieldWithDefault(msg, 3, 0),
    expiryYear: jspb.Message.getFieldWithDefault(msg, 4, 0),
    cardType: jspb.Message.getFieldWithDefault(msg, 5, ""),
    last4digits: jspb.Message.getFieldWithDefault(msg, 6, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.core.private.companies.CardDetails}
 */
proto.core.private.companies.CardDetails.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.core.private.companies.CardDetails;
  return proto.core.private.companies.CardDetails.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.core.private.companies.CardDetails} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.core.private.companies.CardDetails}
 */
proto.core.private.companies.CardDetails.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setCardholderName(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setPaymentMethodToken(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setExpiryMonth(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setExpiryYear(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setCardType(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setLast4digits(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.core.private.companies.CardDetails.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.core.private.companies.CardDetails.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.core.private.companies.CardDetails} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.core.private.companies.CardDetails.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCardholderName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getPaymentMethodToken();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getExpiryMonth();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
  f = message.getExpiryYear();
  if (f !== 0) {
    writer.writeInt32(
      4,
      f
    );
  }
  f = message.getCardType();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getLast4digits();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
};


/**
 * optional string cardholder_name = 1;
 * @return {string}
 */
proto.core.private.companies.CardDetails.prototype.getCardholderName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.core.private.companies.CardDetails} returns this
 */
proto.core.private.companies.CardDetails.prototype.setCardholderName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string payment_method_token = 2;
 * @return {string}
 */
proto.core.private.companies.CardDetails.prototype.getPaymentMethodToken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.core.private.companies.CardDetails} returns this
 */
proto.core.private.companies.CardDetails.prototype.setPaymentMethodToken = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional int32 expiry_month = 3;
 * @return {number}
 */
proto.core.private.companies.CardDetails.prototype.getExpiryMonth = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.core.private.companies.CardDetails} returns this
 */
proto.core.private.companies.CardDetails.prototype.setExpiryMonth = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional int32 expiry_year = 4;
 * @return {number}
 */
proto.core.private.companies.CardDetails.prototype.getExpiryYear = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.core.private.companies.CardDetails} returns this
 */
proto.core.private.companies.CardDetails.prototype.setExpiryYear = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional string card_type = 5;
 * @return {string}
 */
proto.core.private.companies.CardDetails.prototype.getCardType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.core.private.companies.CardDetails} returns this
 */
proto.core.private.companies.CardDetails.prototype.setCardType = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string last_4digits = 6;
 * @return {string}
 */
proto.core.private.companies.CardDetails.prototype.getLast4digits = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.core.private.companies.CardDetails} returns this
 */
proto.core.private.companies.CardDetails.prototype.setLast4digits = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.core.private.companies.GetCompanyFeaturesStateRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.core.private.companies.GetCompanyFeaturesStateRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.core.private.companies.GetCompanyFeaturesStateRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.core.private.companies.GetCompanyFeaturesStateRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    companyId: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.core.private.companies.GetCompanyFeaturesStateRequest}
 */
proto.core.private.companies.GetCompanyFeaturesStateRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.core.private.companies.GetCompanyFeaturesStateRequest;
  return proto.core.private.companies.GetCompanyFeaturesStateRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.core.private.companies.GetCompanyFeaturesStateRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.core.private.companies.GetCompanyFeaturesStateRequest}
 */
proto.core.private.companies.GetCompanyFeaturesStateRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setCompanyId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.core.private.companies.GetCompanyFeaturesStateRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.core.private.companies.GetCompanyFeaturesStateRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.core.private.companies.GetCompanyFeaturesStateRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.core.private.companies.GetCompanyFeaturesStateRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCompanyId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
};


/**
 * optional int32 company_id = 1;
 * @return {number}
 */
proto.core.private.companies.GetCompanyFeaturesStateRequest.prototype.getCompanyId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.core.private.companies.GetCompanyFeaturesStateRequest} returns this
 */
proto.core.private.companies.GetCompanyFeaturesStateRequest.prototype.setCompanyId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.core.private.companies.GetCompanyFeaturesStateResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.core.private.companies.GetCompanyFeaturesStateResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.core.private.companies.GetCompanyFeaturesStateResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.core.private.companies.GetCompanyFeaturesStateResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    isAchUsed: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    isFreeAgentIntegrationUsed: jspb.Message.getBooleanFieldWithDefault(msg, 2, false),
    isWebexIntegrationUsed: jspb.Message.getBooleanFieldWithDefault(msg, 3, false),
    isMultiplePaymentGatewaysUsed: jspb.Message.getBooleanFieldWithDefault(msg, 4, false),
    isMultipleTaxRegionsUsed: jspb.Message.getBooleanFieldWithDefault(msg, 5, false),
    isTaxJarUsed: jspb.Message.getBooleanFieldWithDefault(msg, 6, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.core.private.companies.GetCompanyFeaturesStateResponse}
 */
proto.core.private.companies.GetCompanyFeaturesStateResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.core.private.companies.GetCompanyFeaturesStateResponse;
  return proto.core.private.companies.GetCompanyFeaturesStateResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.core.private.companies.GetCompanyFeaturesStateResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.core.private.companies.GetCompanyFeaturesStateResponse}
 */
proto.core.private.companies.GetCompanyFeaturesStateResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsAchUsed(value);
      break;
    case 2:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsFreeAgentIntegrationUsed(value);
      break;
    case 3:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsWebexIntegrationUsed(value);
      break;
    case 4:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsMultiplePaymentGatewaysUsed(value);
      break;
    case 5:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsMultipleTaxRegionsUsed(value);
      break;
    case 6:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsTaxJarUsed(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.core.private.companies.GetCompanyFeaturesStateResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.core.private.companies.GetCompanyFeaturesStateResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.core.private.companies.GetCompanyFeaturesStateResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.core.private.companies.GetCompanyFeaturesStateResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getIsAchUsed();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getIsFreeAgentIntegrationUsed();
  if (f) {
    writer.writeBool(
      2,
      f
    );
  }
  f = message.getIsWebexIntegrationUsed();
  if (f) {
    writer.writeBool(
      3,
      f
    );
  }
  f = message.getIsMultiplePaymentGatewaysUsed();
  if (f) {
    writer.writeBool(
      4,
      f
    );
  }
  f = message.getIsMultipleTaxRegionsUsed();
  if (f) {
    writer.writeBool(
      5,
      f
    );
  }
  f = message.getIsTaxJarUsed();
  if (f) {
    writer.writeBool(
      6,
      f
    );
  }
};


/**
 * optional bool is_ach_used = 1;
 * @return {boolean}
 */
proto.core.private.companies.GetCompanyFeaturesStateResponse.prototype.getIsAchUsed = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.core.private.companies.GetCompanyFeaturesStateResponse} returns this
 */
proto.core.private.companies.GetCompanyFeaturesStateResponse.prototype.setIsAchUsed = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional bool is_free_agent_integration_used = 2;
 * @return {boolean}
 */
proto.core.private.companies.GetCompanyFeaturesStateResponse.prototype.getIsFreeAgentIntegrationUsed = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
};


/**
 * @param {boolean} value
 * @return {!proto.core.private.companies.GetCompanyFeaturesStateResponse} returns this
 */
proto.core.private.companies.GetCompanyFeaturesStateResponse.prototype.setIsFreeAgentIntegrationUsed = function(value) {
  return jspb.Message.setProto3BooleanField(this, 2, value);
};


/**
 * optional bool is_webex_integration_used = 3;
 * @return {boolean}
 */
proto.core.private.companies.GetCompanyFeaturesStateResponse.prototype.getIsWebexIntegrationUsed = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 3, false));
};


/**
 * @param {boolean} value
 * @return {!proto.core.private.companies.GetCompanyFeaturesStateResponse} returns this
 */
proto.core.private.companies.GetCompanyFeaturesStateResponse.prototype.setIsWebexIntegrationUsed = function(value) {
  return jspb.Message.setProto3BooleanField(this, 3, value);
};


/**
 * optional bool is_multiple_payment_gateways_used = 4;
 * @return {boolean}
 */
proto.core.private.companies.GetCompanyFeaturesStateResponse.prototype.getIsMultiplePaymentGatewaysUsed = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 4, false));
};


/**
 * @param {boolean} value
 * @return {!proto.core.private.companies.GetCompanyFeaturesStateResponse} returns this
 */
proto.core.private.companies.GetCompanyFeaturesStateResponse.prototype.setIsMultiplePaymentGatewaysUsed = function(value) {
  return jspb.Message.setProto3BooleanField(this, 4, value);
};


/**
 * optional bool is_multiple_tax_regions_used = 5;
 * @return {boolean}
 */
proto.core.private.companies.GetCompanyFeaturesStateResponse.prototype.getIsMultipleTaxRegionsUsed = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 5, false));
};


/**
 * @param {boolean} value
 * @return {!proto.core.private.companies.GetCompanyFeaturesStateResponse} returns this
 */
proto.core.private.companies.GetCompanyFeaturesStateResponse.prototype.setIsMultipleTaxRegionsUsed = function(value) {
  return jspb.Message.setProto3BooleanField(this, 5, value);
};


/**
 * optional bool is_tax_jar_used = 6;
 * @return {boolean}
 */
proto.core.private.companies.GetCompanyFeaturesStateResponse.prototype.getIsTaxJarUsed = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 6, false));
};


/**
 * @param {boolean} value
 * @return {!proto.core.private.companies.GetCompanyFeaturesStateResponse} returns this
 */
proto.core.private.companies.GetCompanyFeaturesStateResponse.prototype.setIsTaxJarUsed = function(value) {
  return jspb.Message.setProto3BooleanField(this, 6, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.core.private.companies.GetBillsbySubscriptionRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.core.private.companies.GetBillsbySubscriptionRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.core.private.companies.GetBillsbySubscriptionRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.core.private.companies.GetBillsbySubscriptionRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    companyId: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.core.private.companies.GetBillsbySubscriptionRequest}
 */
proto.core.private.companies.GetBillsbySubscriptionRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.core.private.companies.GetBillsbySubscriptionRequest;
  return proto.core.private.companies.GetBillsbySubscriptionRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.core.private.companies.GetBillsbySubscriptionRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.core.private.companies.GetBillsbySubscriptionRequest}
 */
proto.core.private.companies.GetBillsbySubscriptionRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setCompanyId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.core.private.companies.GetBillsbySubscriptionRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.core.private.companies.GetBillsbySubscriptionRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.core.private.companies.GetBillsbySubscriptionRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.core.private.companies.GetBillsbySubscriptionRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCompanyId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
};


/**
 * optional int32 company_id = 1;
 * @return {number}
 */
proto.core.private.companies.GetBillsbySubscriptionRequest.prototype.getCompanyId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.core.private.companies.GetBillsbySubscriptionRequest} returns this
 */
proto.core.private.companies.GetBillsbySubscriptionRequest.prototype.setCompanyId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.core.private.companies.GetBillsbySubscriptionResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.core.private.companies.GetBillsbySubscriptionResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.core.private.companies.GetBillsbySubscriptionResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.core.private.companies.GetBillsbySubscriptionResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    planId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    allowanceAmount: jspb.Message.getFieldWithDefault(msg, 2, 0),
    allowanceAmountFormatted: jspb.Message.getFieldWithDefault(msg, 3, ""),
    allowanceOverage: jspb.Message.getFloatingPointFieldWithDefault(msg, 4, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.core.private.companies.GetBillsbySubscriptionResponse}
 */
proto.core.private.companies.GetBillsbySubscriptionResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.core.private.companies.GetBillsbySubscriptionResponse;
  return proto.core.private.companies.GetBillsbySubscriptionResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.core.private.companies.GetBillsbySubscriptionResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.core.private.companies.GetBillsbySubscriptionResponse}
 */
proto.core.private.companies.GetBillsbySubscriptionResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPlanId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setAllowanceAmount(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setAllowanceAmountFormatted(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setAllowanceOverage(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.core.private.companies.GetBillsbySubscriptionResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.core.private.companies.GetBillsbySubscriptionResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.core.private.companies.GetBillsbySubscriptionResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.core.private.companies.GetBillsbySubscriptionResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPlanId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getAllowanceAmount();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getAllowanceAmountFormatted();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getAllowanceOverage();
  if (f !== 0.0) {
    writer.writeDouble(
      4,
      f
    );
  }
};


/**
 * optional int32 plan_id = 1;
 * @return {number}
 */
proto.core.private.companies.GetBillsbySubscriptionResponse.prototype.getPlanId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.core.private.companies.GetBillsbySubscriptionResponse} returns this
 */
proto.core.private.companies.GetBillsbySubscriptionResponse.prototype.setPlanId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int32 allowance_amount = 2;
 * @return {number}
 */
proto.core.private.companies.GetBillsbySubscriptionResponse.prototype.getAllowanceAmount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.core.private.companies.GetBillsbySubscriptionResponse} returns this
 */
proto.core.private.companies.GetBillsbySubscriptionResponse.prototype.setAllowanceAmount = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional string allowance_amount_formatted = 3;
 * @return {string}
 */
proto.core.private.companies.GetBillsbySubscriptionResponse.prototype.getAllowanceAmountFormatted = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.core.private.companies.GetBillsbySubscriptionResponse} returns this
 */
proto.core.private.companies.GetBillsbySubscriptionResponse.prototype.setAllowanceAmountFormatted = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional double allowance_overage = 4;
 * @return {number}
 */
proto.core.private.companies.GetBillsbySubscriptionResponse.prototype.getAllowanceOverage = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 4, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.core.private.companies.GetBillsbySubscriptionResponse} returns this
 */
proto.core.private.companies.GetBillsbySubscriptionResponse.prototype.setAllowanceOverage = function(value) {
  return jspb.Message.setProto3FloatField(this, 4, value);
};


/**
 * @enum {number}
 */
proto.core.private.companies.CollectCompanyNameType = {
  DO_NOT_COLLECT: 0,
  OPTIONAL: 1,
  COMPULSORY: 2
};

/**
 * @enum {number}
 */
proto.core.private.companies.PaymentStatus = {
  SUCCESS: 0,
  DECLINED: 1,
  FAILED: 2,
  PENDING: 3,
  PENDING_ACH: 4
};

goog.object.extend(exports, proto.core.private.companies);
