import React, { useRef } from "react";
import ReactModal from "react-modal";
import "./Modal.scss";
import Text from "../ui/text/Text";
import { findDOMNode } from "react-dom";

interface IModal {
  isOpen: boolean,
  title: string | JSX.Element,
  translateWith?: object,
  shouldTranslateTitle?: boolean,
  shouldCloseOnOverlayClick?:boolean,
  onAfterOpen?: () => any,
  onRequestClose: () => void,
  className?: string,
  children?: JSX.Element,
  [key: string]: any
}

/**
 * this wrapper function is used to removed the --after-open class before the modal closes in order to apply the transition!!
 * without it the transition is not applyed onClose because the modal dom element is immediatly removed
 */
let _onRequestClose = (modalRef: any, onRequestClose: Function) => {
  const modalBody = (findDOMNode(modalRef.current) as Element) && (findDOMNode(modalRef.current) as Element).querySelector(".modal__body") as Element;
  if(modalBody) {
    modalBody.className = modalBody.className.replace(/modal__body--visible/, "");
    const modalDom = (findDOMNode(modalRef.current) as Element).querySelector(".ReactModal__Content--after-open") as Element;
    modalDom && (modalDom.className = modalDom.className.replace(/ReactModal__Content--after-open/, ""));
    setTimeout(onRequestClose, 200);
  }
}

/**
 * wrapper functiont that shows the modal body only after the transition has completed, to avoid showing the content of the modal changing during the transition
 */
let _onAfterOpen = (modalRef: any, onAfterOpen: Function) => {
  setTimeout(() => {
    const modal = (findDOMNode(modalRef.current) as Element);
    if(modal) {
      const modalBody = modal.querySelector(".modal__body") as Element;
      modalBody.className += " modal__body--visible";
    }
  }, 500)
  onAfterOpen();
}


const Modal: React.FC<IModal> = ({ children, className, onRequestClose, title, shouldTranslateTitle = true, shouldCloseOnOverlayClick = true, translateWith = {}, onAfterOpen = () => { }, ...props }) => {
  const modalRef: any = useRef(null);
  ReactModal.setAppElement("body");

  return (
    <ReactModal
      ref={modalRef}
      onRequestClose={() => _onRequestClose(modalRef, onRequestClose)}
      onAfterOpen={() => _onAfterOpen(modalRef, onAfterOpen)}
      shouldCloseOnOverlayClick={shouldCloseOnOverlayClick}
      {...props}
      className={`modal ${className}`}
    >
      <div className="modal__header">
        {
          shouldTranslateTitle
            ? <Text content={title} translateWith={translateWith} noMargin component="div" />
            : <div>{title}</div>
        }

        <span className="fal fa-times" aria-hidden="true" onClick={() => _onRequestClose(modalRef, onRequestClose)} />
      </div>
      <div className="modal__body">
        {children}
      </div>
    </ReactModal>
  )
}

export default Modal;