export enum DashboardChartType {
  SALES = "sales",
  PAYMENTS = "payments",
  REFUNDS = "refund",
  MRR = "mrr",
  CMRR = "cmrr",
  SIGNUPS = "signups",
  CHURN = "churn",
  CREDITS = "credits"
}

export enum DashboardChartPeriod {
  DAILY = "daily",
  THREEMONTHS = "three-months",
  SIXMONTHS = "six-months",
  TWELVEMONTHS = "twelve-months"
}


export interface IDashboardChartData {
  current: { date: string, total: number, totalFormatted: string },
  records: Array<{ date: string, total: number, totalFormatted: string }>,
  average: number,
  averageFormatted: string,
  total: number,
  totalFormatted: string

}

export interface IDashboardRecentData {
  signUps: {
    todaysValue: number,
    lastMonthsValue: number | null,
    percentageChanged: number | null,
    percentageChangedFormatted: string | null,
    hasValueDropped: boolean
  },
  customers: {
    todaysValue: number,
    lastMonthsValue: number | null,
    percentageChanged: number | null,
    percentageChangedFormatted: string | null,
    hasValueDropped: boolean
  },
  revenueMonth: {
    expectedValue: number,
    todaysValueFormatted: string,
    lastMonthsValueFormatted: string | null,
    expectedValueFormatted: string,
    todaysValue: number,
    lastMonthsValue: number | null,
    percentageChanged: number | null,
    percentageChangedFormatted: string | null,
    hasValueDropped: boolean
  },
  revenueToday: {
    expectedValue: number,
    todaysValueFormatted: string,
    lastMonthsValueFormatted: string | null,
    expectedValueFormatted: string,
    todaysValue: number,
    lastMonthsValue: number | null,
    percentageChanged: number | null,
    percentageChangedFormatted: string | null,
    hasValueDropped: boolean
  }
}

export interface IDashboardCurrentData {
  mrrValue: number,
  mrrValueFormatted: string,
  subscriptionsCount: number,
  netRevValue: number,
  netRevValueFormatted: string,
  netPaymentsValue: number,
  netPaymentsValueFormatted: string,
  unpaidInvoicesCount: number,
  unpaidInvoicesAmount: number,
  unpaidInvoicesAmountFormatted: string,
  expiredCardsCount: number
}