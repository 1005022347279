import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../../../../..";
import { updateGettingStartedSettings } from "../../../../../actions/authActions";
import { updateCompanyBranding } from "../../../../../actions/companyBrandingActions";
import { GettingStartedType, IGettingStartedSettings } from "../../../../../models/GettingStarted";
import history from "../../../../../utils/history";
import Button from "../../../../ui/button/Button";
import FormGroup from "../../../../ui/form-group/FormGroup";
import InteractiveList from "../../../../ui/interactive-list/InteractiveList";
import CheckoutPreviewContainer from "../checkout-preview-container/CheckoutPreviewContainer";
import ColorCustomization from "../color-customization/ColorCustomization";
import RoundingCustomization from "../rounding-customization/RoundingCustomization";
import TypographyCustomization from "../typography-customization/TypographyCustomization";

const CheckoutAccountManagement: React.FC = () => {
  const authReducer = useSelector((state: AppState) => state.auth);
  const companyBrandingReducer = useSelector((state: AppState) => state.companyBrandingReducer);

  const dispatch = useDispatch<Function>();

  const { currentCompanyDomain, dashboardSettings } = authReducer;
  const { logoBackgroundColor, highlightColor, isLoading, logoUrl, faviconUrl, logoFileName,
    faviconFileName, rounding, typographyBody, typographyHeader, typographyHeaderIsAllCaps,
    headerAndButtonColor, backgroundColor, bodyTextColor, confirmationColor, errorColor } = companyBrandingReducer;
  const defaultColor1 = "#1E2432";
  const defaultColor2 = "#F87847";
  return (
    <>
      <FormGroup>
        {/* <CheckoutAccountManagementTabs /> */}
        <CheckoutPreviewContainer />
      </FormGroup>

      <ColorCustomization />
      <TypographyCustomization />
      <RoundingCustomization />


      {/* HIDDEN FOR NOW
        <Panel title="BRAND_CUSTOMIZATION_WHITE_LABEL" className="brand_customization__panel__white-label">
          <Text content="BRAND_CUSTOMIZATION_WHITE_LABEL_CONTENT" />
          <Button className="brand_customization__panel__white-label__button" id="brand-customization--buy-btn" title="BRAND_CUSTOMIZATION_BUY_WHITE_LABEL" buttonType="add" onClick={() => {}} />
        </Panel>
      */}

      <FormGroup>
        <Button
          id="brand-customization--update-btn"
          title="BRAND_CUSTOMIZATION_UPDATE_BRAND_SETTINGS"
          onClick={() => {
            dispatch(updateCompanyBranding(currentCompanyDomain, {
              logoUrl,
              logoBackgroundColor,
              faviconUrl,
              highlightColor,
              logoFileName,
              faviconFileName,
              rounding,
              typographyBody: typographyBody.value,
              typographyHeader: typographyHeader.value,
              typographyHeaderIsAllCaps,
              headerAndButtonColor,
              backgroundColor,
              bodyTextColor,
              confirmationColor,
              errorColor
            })).then(() => {
              if (!!dashboardSettings && dashboardSettings.status === GettingStartedType.GettingStarted2) {
                dispatch(
                  updateGettingStartedSettings(currentCompanyDomain, {
                    ...dashboardSettings as IGettingStartedSettings,
                    status: GettingStartedType.PreLive
                  })
                );
                setTimeout(() => history.push("/home"), 500);
              }
            });
          }}
          isLoading={isLoading}
          isFullWidth
        />
      </FormGroup>

      <InteractiveList
        data={[
          {
            title: "CHECKOUT_ACCT_MANAGEMENT_MENU_ITEM_TITLE",
            subTitle: "CHECKOUT_ACCT_MANAGEMENT_MENU_ITEM_CONTENT",
            onClick: () => history.push("/configuration/checkout-account-management")
          }
        ]}
      />
    </>
  )
}

export default CheckoutAccountManagement;