import React, { FormEvent, useState, useLayoutEffect } from "react";
import { AppState } from "../../../../..";
import { useDispatch, useSelector } from "react-redux";
import Panel from "../../../../ui/panel/Panel";
import Text from "../../../../ui/text/Text";
import FormGroup from "../../../../ui/form-group/FormGroup";
import { Col, Row } from "react-grid-system";
import Button from "../../../../ui/button/Button";
import "./SelfService.scss";
import Table from "../../../../ui/table/Table";
import Switch from "../../../../ui/switch/Switch";
import { selfServiceMgmtInitialState } from "../../../../../reducers/checkoutAccountManagementReducer";
import {
  setCheckoutAccountMgmtField,
  AllowedSelfServiceMgmtFields,
  resetSelfServiceMgmtMenu,
  fetchSelfServiceMgmtData,
  updateSelfServiceMgmtData
} from "../../../../../actions/checkoutAccountManagementActions";
import ProgressIndicator from "../../../../ui/progress-indicator/ProgressIndicator";

const SelfService: React.FC = () => {
  const selfServiceManagement = useSelector((state: AppState) => state.checkoutAccountManagementReducer.selfServiceManagement);
  const currentCompanyDomain = useSelector((state: AppState) => state.auth.currentCompanyDomain);

  const dispatch = useDispatch<Function>();

  const [state, setState] = useState({
    isLoadingData: true,
    isUpdatingTerminology: false,
    isUpdatingAcctMngtMenu: false,
    terminologyHasError: false,
    singleSubErrorMsg: "",
    multipleSubErrorMsg: ""
  });

  const { isLoadingData, isUpdatingAcctMngtMenu } = state;
  const {
    isCancelPlanVisible,
    isChangeAddressVisible,
    isChangePersonalDetailsVisible,
    isManageAddonsVisible,
    isManageAllowancesVisible,
    isUpdatePaymentVisible,
    isViewBillingHistoryVisible,
    isViewChangePlanVisible
  } = selfServiceManagement;

  const fetchData = async () => {
    setState({ ...state, isLoadingData: true });
    try {
      await dispatch(fetchSelfServiceMgmtData(currentCompanyDomain));
      setState({ ...state, isLoadingData: false });
    } catch (err) {
      setState({ ...state, isLoadingData: false });
      console.log(err);
    }
  };

  useLayoutEffect(() => {
    fetchData();
  }, []);

  const handleUpdateAcctMgmtMenu = async (evt: FormEvent) => {
    evt.preventDefault();

    setState({ ...state, isUpdatingAcctMngtMenu: true });

    try {
      await dispatch(
        updateSelfServiceMgmtData(currentCompanyDomain, {
          ...selfServiceMgmtInitialState,
          isCancelPlanVisible,
          isChangeAddressVisible,
          isChangePersonalDetailsVisible,
          isManageAddonsVisible,
          isManageAllowancesVisible,
          isUpdatePaymentVisible,
          isViewBillingHistoryVisible,
          isViewChangePlanVisible
        })
      );
      setState({ ...state, isUpdatingAcctMngtMenu: false });
    } catch (err) {
      setState({ ...state, isUpdatingAcctMngtMenu: true });
      console.log(err);
    }
  };

  const updateSelfServiceField = (arg: AllowedSelfServiceMgmtFields, value: any) => {
    dispatch(setCheckoutAccountMgmtField("selfServiceManagement", { ...selfServiceManagement, [arg]: value }));
  };

  const renderMenuItems = () => {
    const menuItems: Array<{ icon: string; property: AllowedSelfServiceMgmtFields }> = [
      { icon: "fas fa-fw fa-file-edit", property: "isViewChangePlanVisible" },
      { icon: "fas fa-fw fa-user-plus", property: "isChangePersonalDetailsVisible" },
      { icon: "fas fa-fw fa-map-marked-alt", property: "isChangeAddressVisible" },
      { icon: "fas fa-fw fa-money-check", property: "isUpdatePaymentVisible" },
      { icon: "fas fa-fw fa-tachometer-alt", property: "isManageAllowancesVisible" },
      { icon: "fas fa-fw fa-layer-plus", property: "isManageAddonsVisible" },
      { icon: "fas fa-fw fa-file-invoice-dollar", property: "isViewBillingHistoryVisible" },
      { icon: "fas fa-fw fa-trash-alt cancel", property: "isCancelPlanVisible" }
    ];

    return (
      <Table className="checkout-account-management-self-service__table">
        <tbody>
          {menuItems.map((item, idx) => {
            const isChecked = Boolean(selfServiceManagement[item.property]);
            return (
              <tr key={idx}>
                <td>
                  <i className={item.icon}></i> <Text noMargin component="span" content={`SELF_SERVICE_MANAGEMENT_MENU${idx + 1}`}></Text>
                </td>
                <td>
                  <Switch id={`menu${idx + 1}`} onChange={() => updateSelfServiceField(item.property, !isChecked)} checked={isChecked} />
                </td>
              </tr>
            );
          })}
        </tbody>
      </Table>
    );
  };

  const renderSelfServiceAcctMngtMenu = () => {
    return (
      <form onSubmit={handleUpdateAcctMgmtMenu}>
        <FormGroup>{renderMenuItems()}</FormGroup>
        <Row align="center">
          <Col md={9}>
            <Button id="update-acct-mngt-menu" type="submit" isLoading={isUpdatingAcctMngtMenu} isFullWidth buttonType="general" title="CHECKOUT_ACCT_MANAGEMENT_BTN_UPDATE_PREF" />
          </Col>
          <Col md={3}>
            <Button id="cancel-update-acct-mngt-menu" isFullWidth buttonType="error" title="CHECKOUT_ACCT_MANAGEMENT_BTN_CANCEL" onClick={() => dispatch(resetSelfServiceMgmtMenu())} />
          </Col>
        </Row>
      </form>
    );
  };

  if (isLoadingData) {
    return <ProgressIndicator color="blue"></ProgressIndicator>
  }

  return (
    <div className="checkout-account-management-self-service">
      <Panel title="SELF_SERVICE_MANAGEMENT_PANEL2_TITLE">
        <Text content="SELF_SERVICE_MANAGEMENT_PANEL2_CONTENT" />
        {renderSelfServiceAcctMngtMenu()}
      </Panel>
      {/* 
      // HIDDEN FOR NOW
      <Panel title="BRAND_CUSTOMIZATION_WHITE_LABEL" className="brand_customization__panel__white-label">
        <Text content="BRAND_CUSTOMIZATION_WHITE_LABEL_CONTENT" />
        <Button className="brand_customization__panel__white-label__button" id="brand-customization--buy-btn" title="BRAND_CUSTOMIZATION_BUY_WHITE_LABEL" buttonType="add" onClick={() => {}} />
      </Panel>
      */}
    </div>
  );
};

export default SelfService;