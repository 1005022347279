export enum PaymentSourceType {
  CreditCards = "CreditCard",
  Ach = "Ach",
  None = "None"
}

export interface ICustomer {
  customerUniqueId: string;
  firstName: string;
  lastName: string;
  email: string;
  createdOn: string;
  status: CustomerStatusType;
  isCardExpired: boolean;
  isNoCard: boolean,
  paymentSourceType: PaymentSourceType
}

export interface ICustomersPaginated {
  currentPage: number;
  pageCount: number;
  pageSize: number;
  rowCount: number;
  results?: Array<ICustomer>;
}

export enum CustomerStatusType {
  ACTIVE = "Active",
  INACTIVE = "InActive"
}

export enum CardStatusType {
  EXPIRED = "Expired",
  ACTIVE = "Active",
  ACH = "Ach",
  NO_CARD = "NoCard"
}
