import React, { useMemo, useState } from "react";
import Panel from "../../ui/panel/Panel";
import Text from "../../ui/text/Text";
import InteractiveList from "../../ui/interactive-list/InteractiveList";
import "./ConfigurationHome.scss";
import PageWrapper from "../../ui/layout/PageWrapper";
import history from "../../../utils/history";
import NavigationAlertCurrencyMismatch from "../../ui/navigation-alert/NavigationAlertCurrencyMismatch";
import { useSelector } from "react-redux";
import { AppState } from "../../..";
import Modal from "../../modal/Modal";
import BillsbyCancellation from "./billsby-cancellation/BillsbyCancellation";
import { Permission } from "../../../models/Auth";
import useCheckFeaturePermission from "../../../utils/hooks/useCheckFeaturePermission";
import AddTooltip from "../../add-tooltip/AddTooltip";

const ConfigurationHome: React.FC = () => {
  const companyName = useSelector((state: AppState) => state.auth.companyName);
  const isSettingsApiAndWebhooksReadPermitted = useCheckFeaturePermission(Permission.SettingsApiAndWebhooksRead);
  const isCompanyDeatailsReadPermitted = useCheckFeaturePermission(Permission.SettingsCompanyDetailsRead);
  const isPauseAndCancelSubscriptionReadPermitted = useCheckFeaturePermission(Permission.SettingsPauseAndCancelSubscriptionRead);
  const isCurrenciesReadPermitted = useCheckFeaturePermission(Permission.SettingsCurrenciesRead);
  const isTaxesReadPermitted = useCheckFeaturePermission(Permission.SettingsTaxesRead);
  const isInvoiceSettingsReadPermitted = useCheckFeaturePermission(Permission.SettingsInvoicesRead);
  const isDunningAndFailedPaymentsReadPermitted = useCheckFeaturePermission(Permission.SettingsDunningAndFailedPaymentsRead);
  const isAllowancesReadPermitted = useCheckFeaturePermission(Permission.SettingsAllowancesRead);
  const isPaymentGatewaysReadPermitted = useCheckFeaturePermission(Permission.SettingsPaymentGatewayScaRead);
  const isBrandAndCustomizationReadPermitted = useCheckFeaturePermission(Permission.SettingsBrandCustomizationRead);
  const isEmailNotificationsReadPermitted = useCheckFeaturePermission(Permission.SettingsEmailNotificationsRead);
  const isCheckoutSettingsReadPermitted = useCheckFeaturePermission(Permission.SettingsCheckoutRead);
  const isInformationFieldsReadPermitted = useCheckFeaturePermission(Permission.SettingsInformationFieldsRead);
  const isCancelCompanyPermitted = useCheckFeaturePermission(Permission.CancelCompany);

  // case insensitive check for ach flag
  const isACHPreviewEnabled = useMemo(() => {
    return !!window.location.search?.toLocaleUpperCase()?.match(/ACHPREVIEWENABLED=TRUE/)
  }, [window.location.search])

  const [showCancelModal, setShowCancelModal] = useState(false);
  return (
    <div>
      <NavigationAlertCurrencyMismatch />
      <PageWrapper className="configuration-main">
        <Panel title="CONFIGURATION_MAIN_HEAD_TITLE" className="configuration-main__panel">
          <Text content="CONFIGURATION_MAIN_HEAD_CONTENT" noMargin />
        </Panel>

        <Panel className="configuration-main__section-panel" title="CONFIGURATION_BASIC_TITLE">
          <InteractiveList
            data={[
              {
                title: "CONFIG_COMPANY_DETAILS_MENU_ITEM_TITLE",
                subTitle: "CONFIG_COMPANY_DETAILS_MENU_ITEM_CONTENT",
                onClick: () => history.push("/configuration/company-details"),
                isLocked: !isCompanyDeatailsReadPermitted,
                tooltipMessage: !isCompanyDeatailsReadPermitted ? "NO_ACCESS_MSG" : undefined
              },
              {
                title: "CONFIG_PAUSE_AND_CANCEL_SUBSCRIPTION_TITLE",
                subTitle: "CONFIG_PAUSE_AND_CANCEL_SUBSCRIPTION_CONTENT",
                onClick: () => history.push("/configuration/pause-and-cancel-subscription/cancel"),
                isLocked: !isPauseAndCancelSubscriptionReadPermitted,
                tooltipMessage: !isPauseAndCancelSubscriptionReadPermitted ? "NO_ACCESS_MSG" : undefined
              },
              {
                title: "CURRENCIES_MENU_ITEM_TITLE",
                subTitle: "CURRENCIES_MENU_ITEM_CONTENT",
                onClick: () => history.push("/configuration/currencies"),
                isLocked: !isCurrenciesReadPermitted,
                tooltipMessage: !isCurrenciesReadPermitted ? "NO_ACCESS_MSG" : undefined
              },
              {
                title: "CONFIG_TAXES_MENU_ITEM_TITLE",
                subTitle: "CONFIG_TAXES_MENU_ITEM_DESCRIPTION",
                onClick: () => history.push("/configuration/taxes"),
                isLocked: !isTaxesReadPermitted,
                tooltipMessage: !isTaxesReadPermitted ? "NO_ACCESS_MSG" : undefined
              },
              {
                title: "INVOICE_CREDIT_NOTE_CUSTOMIZATION_MENU_ITEM_TITLE",
                subTitle: "INVOICE_CREDIT_NOTE_CUSTOMIZATION_MENU_ITEM_CONTENT",
                onClick: () => history.push("/configuration/invoices-credit-notes-customization"),
                isLocked: !isInvoiceSettingsReadPermitted,
                tooltipMessage: !isInvoiceSettingsReadPermitted ? "NO_ACCESS_MSG" : undefined
              },
              {
                title: "DUNNING_FAILED_PAYMENTS_MENU_TITLE",
                subTitle: "DUNNING_FAILED_PAYMENTS_MENU_CONTENT",
                onClick: () => history.push("/configuration/dunning-process"),
                isLocked: !isDunningAndFailedPaymentsReadPermitted,
                tooltipMessage: !isDunningAndFailedPaymentsReadPermitted ? "NO_ACCESS_MSG" : undefined
              },
              {
                title: "ALLOWANCE_MENU_TITLE",
                subTitle: "ALLOWANCE_MENU_CONTENT",
                onClick: () => history.push("/configuration/allowance"),
                isLocked: !isAllowancesReadPermitted,
                tooltipMessage: !isAllowancesReadPermitted ? "NO_ACCESS_MSG" : undefined
              }
            ]}
          />
        </Panel>

        <Panel className="configuration-main__section-panel" title="CONFIGURATION_PAYMENTS_TITLE">
          <InteractiveList
            data={[
              {
                title: "CONFIGURATION_BASIC_PAYMENT_GATEWAYS_TITLE",
                subTitle: "CONFIGURATION_BASIC_PAYMENT_GATEWAYS_CONTENT",
                onClick: () => history.push("/configuration/payment-gateways"),
                isLocked: !isPaymentGatewaysReadPermitted,
                tooltipMessage: !isPaymentGatewaysReadPermitted ? "NO_ACCESS_MSG" : undefined
              },
              {
                title: "CONFIGURATION_BASIC_ACH_CONFIG_TITLE",
                subTitle: "CONFIGURATION_BASIC_ACH_CONFIG_CONTENT",
                onClick: () => history.push("/configuration/ach-settings"),
                isVisible: isACHPreviewEnabled,
                isLocked: !isPaymentGatewaysReadPermitted,
                tooltipMessage: !isPaymentGatewaysReadPermitted ? "NO_ACCESS_MSG" : undefined
              }
              // {
              //   title: 'CONFIGURATION_SCA_TITLE',
              //   subTitle: 'CONFIGURATION_SCA_DESCRIPTION',
              //   onClick: () => history.push('/configuration/sca')
              // }
            ]}
          />
        </Panel>

        <Panel className="configuration-main__section-panel" title="CONFIG_CUSTOMER_EXPERIENCE_TITLE">
          <InteractiveList
            data={[
              {
                title: "BRAND_CUSTOMIZATION_MENU_ITEM_TITLE",
                subTitle: "BRAND_CUSTOMIZATION_MENU_ITEM_DESCRIPTION",
                onClick: () => history.push("/configuration/brand-customization"),
                isLocked: !isBrandAndCustomizationReadPermitted,
                tooltipMessage: !isBrandAndCustomizationReadPermitted ? "NO_ACCESS_MSG" : undefined
              },
              // {
              //   title: 'CONFIGURATION_LANGUAGE_TITLE',
              //   subTitle: 'CONFIGURATION_LANGUAGE_DESCRIPTION',
              //   onClick: () => history.push('/configuration/languages')
              // },
              {
                title: "EMAIL_NOTIFICATIONS_MENU_ITEM_TITLE",
                subTitle: "EMAIL_NOTIFICATIONS_MENU_ITEM_CONTENT",
                onClick: () => history.push("/configuration/email-notifications/emails"),
                isLocked: !isEmailNotificationsReadPermitted,
                tooltipMessage: !isEmailNotificationsReadPermitted ? "NO_ACCESS_MSG" : undefined
              },
              {
                title: "CHECKOUT_ACCT_MANAGEMENT_MENU_ITEM_TITLE",
                subTitle: "CHECKOUT_ACCT_MANAGEMENT_MENU_ITEM_CONTENT",
                onClick: () => history.push("/configuration/checkout-account-management"),
                isLocked: !isCheckoutSettingsReadPermitted,
                tooltipMessage: !isCheckoutSettingsReadPermitted ? "NO_ACCESS_MSG" : undefined
              }
            ]}
          />
        </Panel>

        <Panel className="configuration-main__section-panel" title="CONFIG_API_TITLE">
          <InteractiveList
            data={[
              {
                title: "API_KEYS_MENU_ITEM_TITLE",
                subTitle: "API_KEYS_MENU_ITEM_CONTENT",
                isLocked: !isSettingsApiAndWebhooksReadPermitted,
                onClick: () => history.push("/configuration/api-keys"),
                tooltipMessage: !isSettingsApiAndWebhooksReadPermitted ? "NO_ACCESS_MSG" : undefined
              }
            ]}
          />
        </Panel>

        <Panel className="configuration-main__section-panel" title="CONFIG_ADVANCED_TITLE">
          <InteractiveList
            data={[
              {
                title: "INFO_FIELDS_MENU_ITEM_TITLE",
                subTitle: "INFO_FIELDS_MENU_ITEM_CONTENT",
                onClick: () => history.push("/configuration/information-fields"),
                isLocked: !isInformationFieldsReadPermitted,
                tooltipMessage: !isInformationFieldsReadPermitted ? "NO_ACCESS_MSG" : undefined
              }
            ]}
          />
        </Panel>
        <div className="configuration-main__cancel_account">
          <AddTooltip id="cancel-company" showTooltip={!isCancelCompanyPermitted}>
           <Text content="CONFIGURATION_CANCEL_ACCOUNT" translateWith={{ companyName }} onClick={() => setShowCancelModal(true)} noMargin />
          </AddTooltip>
        </div>
      </PageWrapper>

      <Modal
        isOpen={showCancelModal}
        onRequestClose={() => setShowCancelModal(!showCancelModal)}
        title="CONFIGURATION_CANCEL_ACCOUNT_MODAL_TITLE"
        shouldTranslateTitle={true}
        translateWith={{ companyName }}>
        <BillsbyCancellation />
      </Modal>
    </div>
  );
};

export default ConfigurationHome;
