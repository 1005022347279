import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import Table from "../../../components/ui/table/Table";
import Text from "../../../components/ui/text/Text";
import { AppState } from "../../..";
import "./SubscriptionCustomFields.scss";
import Button from "../../ui/button/Button";
import SeeMoreText from "../../ui/see-more-text/SeeMoreText";
import { fetchSubscriptionCustomFields, resetSubscriptionCustomFields } from "../../../actions/subscriptionCustomFieldsActions";
import NoticePanel from "../../ui/notice-panel/NoticePanel";
import counterpart from "counterpart";
import Modal from "../../modal/Modal";
import EditResponses from "../../edit-responses/EditResponses";
import { CustomFieldType } from "../../../models/CustomFields";
import moment from "moment";
import ProgressIndicator from "../../ui/progress-indicator/ProgressIndicator";
import { fetchCustomerDetails } from "../../../actions/customerDetailsActions";

const SubscriptionCustomFields: React.FC = () => {
  const { customerUniqueId, additionalEmail } = useSelector((state: AppState) => ({  customerUniqueId: state.customerDetailsReducer.customerUniqueId, additionalEmail: state.customerDetailsReducer.additionalEmail }))
  const currentCompanyDomain = useSelector((state: AppState) => state.auth.currentCompanyDomain);
  const productId = useSelector((state: AppState) => state.subscriptionDetailsReducer.productId);
  const customFields = useSelector((state: AppState) => state.subscriptionCustomFieldsReducer.customFields);

  const dispatch = useDispatch<Function>();

  const [isLoadingData, setIsLoadingData] = useState(false);
  const [showEditResponseModal, setShowEditResponseModal] = useState(false);
  const [showAllEmails, setShowAllEmails] = useState(false);
  const [emailsHasTwoLines, setEmailHasTwoLines] = useState(false);
  const emailTd = useRef<HTMLTableCellElement>(null);

  const fetchData = async () => {
    setIsLoadingData(true);

    if (!customerUniqueId) {
      return;
    }

    try {
      await dispatch(fetchSubscriptionCustomFields(currentCompanyDomain, productId, customerUniqueId));
      await dispatch(fetchCustomerDetails(currentCompanyDomain, customerUniqueId));
      setIsLoadingData(false);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    fetchData();

    return () => dispatch(resetSubscriptionCustomFields());
  }, []);

  useEffect(() => {
    if(!isLoadingData && emailTd.current) {
      if(emailTd.current.getBoundingClientRect().height > 48) {
        setEmailHasTwoLines(true);
      }
    }
  }, [isLoadingData])

  const handleUpdateCallback = async () => {
    if (!customerUniqueId) {
      return;
    }

    try {
      //await dispatch(fetchSubscriptionCustomFields(currentCompanyDomain, productId, customerUniqueId));
      await fetchData();
      setShowEditResponseModal(false)

    } catch (err) {
      console.log(err)
    }

  }

  const renderTableData = () => {
    return customFields.map((data, idx) => {
      return (
        <tr key={idx}>
          <Text className="subscription-custom-fields__label" shouldTranslate={false} content={data.customField.label} component="td" noMargin></Text>
          <td>
            <SeeMoreText
              content={data.customField.type === CustomFieldType.DatePickerField ? moment(data.value).format("DD MMM YYYY") : data.value.toString()}
              maxChar={90}
              shouldTranslateContent={false}
            ></SeeMoreText>
          </td>
        </tr>
      );
    });
  };

  const renderAdditionalEmailsData = () => {
    if(!additionalEmail) {
      return null
    }
    const splittedEmails = additionalEmail.replace(/\s/g, "").split(",");
    const showLessEmail =  emailsHasTwoLines && !showAllEmails;
    const visibleEmails = showLessEmail ? splittedEmails.slice(0, 4) : splittedEmails

    return (
      <tr>
        <Text className="subscription-custom-fields__label" content="CUSTOM_FIELDS_ADDITIONAL_EMAILS" component="td" noMargin></Text>
        <td ref={emailTd} className='subscription-custom-fields__emails'>
          {visibleEmails.map((email, i) => {
            return <a key={i} href={`mailto:${email}`} className='subscription-custom-fields__emails__item'>{`${email}${visibleEmails.length !== i + 1 ? "," : ""}`}</a>
          })}
          {showLessEmail && <Text component='span' className='subscription-custom-fields__emails__see-more' content='SEE_MORE_LABEL' onClick={() => setShowAllEmails(!showAllEmails)}></Text>}
        </td>
      </tr>
    )
  }

  if (isLoadingData) {
    return <ProgressIndicator color="blue"></ProgressIndicator>
  }

  return (
    <div className="subscription-custom-fields">
      {customFields.length === 0 && !additionalEmail ? (
        <NoticePanel type="warning" content="CUSTOM_FIELDS_WARNING" translateWith={{ link: <Link to="/products/products-plans-cycles">{counterpart("CUSTOM_FIELDS_LINK")}</Link> }} />
      ) : (
        <Table className="subscription-custom-fields__table">
          <thead>
            <tr>
              <Text content="CUSTOM_FIELDS_TH1" component="th" noMargin></Text>
              <th>
                <Text content="CUSTOM_FIELDS_TH2" component="span" noMargin></Text>
                <Button
                  id="edit-responses"
                  className="subscription-custom-fields__button"
                  icon="fas fa-edit"
                  buttonType="add"
                  title="CUSTOM_FIELDS_EDIT_RESPONSES"
                  onClick={() => setShowEditResponseModal(true)}
                ></Button>
              </th>
            </tr>
          </thead>
          <tbody>{renderTableData()}{renderAdditionalEmailsData()}</tbody>
        </Table>
      )}
      <Modal isOpen={showEditResponseModal} title="CUSTOM_FIELDS_MODAL_TITLE" onRequestClose={() => setShowEditResponseModal(false)}>
        <EditResponses handleUpdateCallback={handleUpdateCallback} />
      </Modal>
    </div>
  );
};

export default SubscriptionCustomFields;
