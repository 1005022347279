import React, { useState } from "react";
import Button from "../../../../ui/button/Button";

interface Props {
  onSave: () => Promise<void>;
  onCancel: () => void;
  isEdit: boolean;
  isSaveEnabled: boolean;
}

const PaymentGatewayButtons: React.FC<Props> = ({ onSave, onCancel, isEdit, isSaveEnabled}) => {

  const [isLoading, setIsLoading] = useState(false);

  const savePaymentGateway = async () => {
    setIsLoading(true);
    try {
      await onSave();
      setIsLoading(false);
    }
    catch {
      setIsLoading(false);
    }
  }

  return (
    <div className="payment-gateways__update-btns">
      <Button
        id="payment-gateway-save-changes"
        title={!isEdit ? "PAYMENT_GATEWAYS_ADD_NEW_GATEWAY_BUTTON" : "PAYMENT_GATEWAYS_SAVE_CHANGES"}
        disabled={!isSaveEnabled || isLoading}
        isLoading={isLoading}
        onClick={() => savePaymentGateway()}
      />
      <Button
        id="payment-gateway-cancel"
        buttonType="error"
        title="PAYMENT_GATEWAYS_CANCEL"
        onClick={() => onCancel()}
      />          
    </div>
  )
}

export default PaymentGatewayButtons;