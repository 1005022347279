import { makeGenericActionCreator, makeActionCreator } from "../utils/reduxUtils";
import { grpcUnaryCall } from "../utils/grpc/grpcUtils";
import { GetEmailLogsRequest, GetEmailLogsResponse, GetEmailRequest } from "../utils/grpc/generated/Billsby.Protos/EmailSender/private/emaillogs/emaillogs_pb";
import { EmailLogsServiceClient } from "../utils/grpc/generated/Billsby.Protos/EmailSender/private/emaillogs/EmaillogsServiceClientPb";
import { EMAIL_LOG_PAGINATION_SIZE } from "../utils/constants";
import { EmailLogStatus } from "../models/EmailLog";
import { AppState } from "..";
import { SelectedPage } from "../components/ui/pagination/Pagination";
import { ConfigConstants } from "../utils/config";
export const SET_EMAIL_LOGS_FIELD = "SET_EMAIL_LOGS_FIELD";
export type allowedEmailLogsTypes = "emailSearchedFilter" | "emailTypeFilter" | "selectedPage" | "hasMoreThanOnePage";
export const setEmailLogsField = makeGenericActionCreator<allowedEmailLogsTypes>(SET_EMAIL_LOGS_FIELD);

export const FETCH_EMAIL_LOGS_REQUEST = "FETCH_EMAIL_LOGS_REQUEST";
export const FETCH_EMAIL_LOGS_SUCCESS = "FETCH_EMAIL_LOGS_SUCCESS";
export const FETCH_EMAIL_LOGS_FAILURE = "FETCH_EMAIL_LOGS_FAILURE";

export const FETCH_EMAIL_LOG_BODY_REQUEST = "FETCH_EMAIL_LOG_BODY_REQUEST";
export const FETCH_EMAIL_LOG_BODY_SUCCESS = "FETCH_EMAIL_LOG_BODY_SUCCESS";
export const FETCH_EMAIL_LOG_BODY_FAILURE = "FETCH_EMAIL_LOG_BODY_FAILURE";


export const RESET_EMAIL_LOGS_STATE = "RESET_EMAIL_LOGS_STATE";
export const resetEmailLogsState = makeActionCreator(RESET_EMAIL_LOGS_STATE);


/**
 * This method does a prefetch of one page more to understand if we are in the first or last page
 * @param companyDomain 
 * @param newPage 
 * @param emailFilter 
 * @param searchFilter 
 * @param pageUrl 
 * @param pageSize 
 */
export const fetchEmailLogs = (currentCompanyId: number | null, newPage: SelectedPage, emailFilter = EmailLogStatus.UNASSIGNED, searchFilter = "", pageUrl = "",
  pageSize = EMAIL_LOG_PAGINATION_SIZE) => {
  return async (dispatch: Function, getState: () => AppState) => {

    let currentPage = {} as GetEmailLogsResponse;
    try {
      const getEmailLogsRequest = new GetEmailLogsRequest();
      getEmailLogsRequest.setCompanyId(currentCompanyId as number);
      getEmailLogsRequest.setEmailStatusFilter(emailFilter as any);
      getEmailLogsRequest.setSearchFilter(searchFilter);
      getEmailLogsRequest.setResultsPerPage(pageSize);
      getEmailLogsRequest.setPageUrl(pageUrl);
      const emailLogsServiceClient = new EmailLogsServiceClient(ConfigConstants.grpcBaseUrl);

      dispatch({ type: FETCH_EMAIL_LOGS_REQUEST });
      currentPage = await grpcUnaryCall(getEmailLogsRequest, emailLogsServiceClient, emailLogsServiceClient.getEmailLogs) as GetEmailLogsResponse;
      dispatch({ type: FETCH_EMAIL_LOGS_SUCCESS, response: currentPage });

    }
    catch (err) {
      dispatch({ type: FETCH_EMAIL_LOGS_FAILURE });
    }

    try {
      const getEmailLogsPrefetch = new GetEmailLogsRequest();
      getEmailLogsPrefetch.setCompanyId(currentCompanyId as number);
      getEmailLogsPrefetch.setEmailStatusFilter(emailFilter as any);
      getEmailLogsPrefetch.setSearchFilter(searchFilter);
      getEmailLogsPrefetch.setResultsPerPage(pageSize);
      getEmailLogsPrefetch.setPageUrl(pageUrl);
      getEmailLogsPrefetch.setPageUrl(newPage === SelectedPage.PREVIOUS ? currentPage.getPrevious() : currentPage.getNext());
      const emailLogsServiceClientPrefetch = new EmailLogsServiceClient(ConfigConstants.grpcBaseUrl);
      const prefetchedPage = await grpcUnaryCall(getEmailLogsPrefetch, emailLogsServiceClientPrefetch, emailLogsServiceClientPrefetch.getEmailLogs) as GetEmailLogsResponse;
      if (!prefetchedPage.getEmailsList().length && newPage === SelectedPage.PREVIOUS) {
        dispatch(setEmailLogsField("selectedPage", SelectedPage.FIRST));
      }
      if (!prefetchedPage.getEmailsList().length && newPage === SelectedPage.NEXT) {
        dispatch(setEmailLogsField("selectedPage", SelectedPage.LAST));
      }
      if(newPage === SelectedPage.FIRST) {
        dispatch(setEmailLogsField("hasMoreThanOnePage", prefetchedPage.getEmailsList().length > 0));
      }
    }
    catch(err) {
      dispatch({ type: FETCH_EMAIL_LOGS_FAILURE });
    }
  }
}

export const fetchEmailBody = (currentCompanyId: number, emailUrl: string) => {
  const getEmailRequest = new GetEmailRequest();
  getEmailRequest.setCompanyId(currentCompanyId);
  getEmailRequest.setEmailUrl(emailUrl);
  const emailLogsServiceClient = new EmailLogsServiceClient(ConfigConstants.grpcBaseUrl);
  return {
    types: [FETCH_EMAIL_LOG_BODY_REQUEST, FETCH_EMAIL_LOG_BODY_SUCCESS, FETCH_EMAIL_LOG_BODY_FAILURE],
    callAPI: () => grpcUnaryCall(getEmailRequest, emailLogsServiceClient, emailLogsServiceClient.getEmail)
  }
}