
import { BillsbyAction } from "../../models/BillsbyAction";
import { AppState } from "../..";
import { FETCH_DISCOUNTS_LIST_REQUEST, FETCH_DISCOUNTS_LIST_SUCCESS, FETCH_DISCOUNTS_LIST_FAILURE,RESET_DISCOUNT_LIST_STATE, SET_DISCOUNTS_FIELDS, FETCH_COUPONS_LIST_SUCCESS, EXPIRE_COUPON_REQUEST, EXPIRE_COUPON_SUCCESS, EXPIRE_COUPON_FAILURE, DELETE_DISCOUNT_REQUEST, DELETE_DISCOUNT_SUCCESS, DELETE_DISCOUNT_FAILURE} from "../../actions/discount-coupon/discountsActions";
import { GetDiscountsResponse, Discount, Coupon, GetCouponsResponse } from "../../utils/grpc/generated/Billsby.Protos/core/private/discounts/discounts_pb";
import { CREATE_DISCOUNT_FAILURE } from "../../actions/discount-coupon/createDiscountActions";

export interface IDiscountsState {
  searchedDiscountKey: string,
  discountList: GetDiscountsResponse,
  hasDiscounts: boolean,
  couponList: Array<{discountId: number, coupons: GetCouponsResponse}>,
  showExpireModal: boolean,
  couponToExpire?: Coupon,
  discountIdOfcouponToExpire?: number,
  isCouponExpiring: boolean,
  showDiscountDeleteModal: boolean,
  discountToDelete?: Discount,
  isDeleting: boolean,
  newCouponId?: number,
  newCouponName: string,
  errorMessage: string,
  isFetchingDiscounts: boolean
}

export const initState = () => ({
  discountList: new GetDiscountsResponse(),
  searchedDiscountKey: "",
  hasDiscounts: false,
  couponList: [],
  showExpireModal: false,
  couponToExpire: undefined,
  discountIdOfcouponToExpire: undefined,
  isCouponExpiring: false,
  showDiscountDeleteModal: false,
  discountToDelete: undefined,
  isDeleting: false,
  newCouponId: undefined,
  newCouponName: "",
  errorMessage: "",
  isFetchingDiscounts: false
})

export default function discountsReducer(state: IDiscountsState = initState(), action: BillsbyAction, store: AppState) {
  switch (action.type) {
    case FETCH_DISCOUNTS_LIST_REQUEST: {
      return { ...state, isFetchingDiscounts: true }
    }
    case FETCH_DISCOUNTS_LIST_FAILURE: {
      return { ...state, isFetchingDiscounts: false }
    }  
    case FETCH_DISCOUNTS_LIST_SUCCESS: {
      const discountList = action.response as GetDiscountsResponse;
      const hasDiscounts = (!!discountList.getDiscountsList() && discountList.getDiscountsList().length > 0) || state.hasDiscounts;
      return { ...state, discountList: discountList, hasDiscounts: hasDiscounts, isFetchingDiscounts: false }
    }    
    case FETCH_COUPONS_LIST_SUCCESS: {
      const couponList = action.response as GetCouponsResponse;
      let items = [...state.couponList];
      if(items.filter(i => i.discountId === action.discountId).length < 1) {
        items.push({discountId: action.discountId, coupons: couponList})
      } else {
        items = items.map(i => {
          if(i.discountId === action.discountId) {
            return {discountId: action.discountId, coupons: couponList}
          }

          return i;
        })
      }
      return {...state, couponList: items }
    }
    
    case EXPIRE_COUPON_REQUEST:
      return { ...state, isCouponExpiring: true }
    case EXPIRE_COUPON_SUCCESS:
      return { ...state, isCouponExpiring: false }
    case EXPIRE_COUPON_FAILURE:
      return { ...state, isCouponExpiring: false }
      
    case DELETE_DISCOUNT_REQUEST:
      return { ...state, isDeleting: true }
    case DELETE_DISCOUNT_SUCCESS:
      return { ...state, isDeleting: false }
    case DELETE_DISCOUNT_FAILURE:
      return { ...state, isDeleting: false }
      
    case SET_DISCOUNTS_FIELDS: {
      return { ...state, [action.fieldName]: action.fieldValue }
    }
    case CREATE_DISCOUNT_FAILURE: {
      return { ...state, errorMessage: action.error.message }
    }
    case RESET_DISCOUNT_LIST_STATE:
      return initState()
    default: 
      return state
  }
}