import React, { useEffect, useMemo, useState } from "react";
import counterpart from "counterpart";
import Helmet from "react-helmet";
import Button from "../../ui/button/Button";
import PageWrapper from "../../ui/layout/PageWrapper";
import NavigationBar from "../../ui/navigation-bar/NavigationBar";
import StepCollapsablePanel from "../../ui/step-collapsable-panel/StepCollapsablePanel";
import Text from "../../ui/text/Text";
import logo from "../../../images/integrations/webex/webex-logo.png";
import { useHistory, useRouteMatch } from "react-router";
import TabbedContainer from "../../ui/tabbed-container/TabbedContainer";
import { Link, NavLink } from "react-router-dom";
import { PrivateRoute } from "../../../containers/AuthRoutes";
import WebexOverview from "./webex-overview/WebexOverview";
import WebexFaq from "./webex-faq/WebexFaq";
import WebexEventTriggers from "./webex-event-triggers/WebexEventTriggers";
import "./Webex.scss"
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../../..";
import { AccountingIntegrationType } from "../../../models/AccountingIntegrations";
import { ConfigConstants } from "../../../utils/config";
import Modal from "../../modal/Modal";
import UninstallWebex from "./uninstall-webex/UninstallWebex";
import NavigationAlert from "../../ui/navigation-alert/NavigationAlert";
import { openIntercomIframe } from "../../../utils/commonUtils";
import NoticePanel from "../../ui/notice-panel/NoticePanel";
import { createWebexIntegration, fetchAccountIntegrations, setAccountingIntegrationsField } from "../../../actions/accountingIntegrationsActions";
import ProgressIndicator from "../../ui/progress-indicator/ProgressIndicator";
import { useIsFromSpecificPage } from "../../../utils/custom-hooks";
import { setShowBillingModal } from "../../../actions/authActions";
import { Permission } from "../../../models/Auth";

const Webex: React.FC = () => {
  const { currentCompanyId, parsedToken } = useSelector((state: AppState) => state.auth);
  const { integrations, isCreatingIntegrationSuccess, isDeleteIntegrationSuccess, isCreatingIntegrationFailure } = useSelector((state: AppState) => state.accountingIntegrationsReducer);
  const history = useHistory();
  const match = useRouteMatch();
  const dispatch = useDispatch<Function>();
  const params = new URLSearchParams(history.location.search);
  const webexCode = params.get("code");


  const [showModal, setShowModal] = useState(false);
  const [showNotification, setShowNotification] = useState(false);
  const [showErrorNotification, setShowErrorNotification] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
	const isFromChecklist = useIsFromSpecificPage("/launch-checklist");
  const isFromBillingModal = useIsFromSpecificPage("billing-modal");

  useEffect(() => {
    return () => {
      dispatch(setAccountingIntegrationsField("isCreatingIntegrationFailure", false));
      dispatch(setAccountingIntegrationsField("isCreatingIntegrationSuccess", false));
      dispatch(setAccountingIntegrationsField("isDeleteIntegrationSuccess", false));
      dispatch(setAccountingIntegrationsField("isDeleteIntegrationFailure", false));
    }
  }, [])

  useEffect(() => {
    setIsLoading(true);
    //ugly trick to update the integrations because the creation and deletion are asynchronous and when backend returns it might still be pending
    //https://dev.azure.com/airfidev/Billsby/_workitems/edit/25832?src=WorkItemMention&src-action=artifact_link
    setTimeout(async() => {
      await dispatch(fetchAccountIntegrations(Number(currentCompanyId)));
      setIsLoading(false);
    }, 1500);
  }, [isCreatingIntegrationSuccess, isDeleteIntegrationSuccess])


  useEffect(() => {
    if(isCreatingIntegrationSuccess) {
      setShowNotification(true);
    }
    if(isCreatingIntegrationFailure) {
      setShowErrorNotification(true);
    }
  }, [isCreatingIntegrationSuccess, isCreatingIntegrationFailure])

  const isInstalled = useMemo(() => {
    return integrations.getIntegrationsList().some(i => i.getName() === AccountingIntegrationType.WEBEX && i.getIsInstalled());
  }, [integrations]);

  const hasPermissionToInstall = useMemo(() => {
    if(parsedToken?.CurrentPermissions.some(p => p === Permission[Permission.WebexRead])) {
      return true
    }
    return false
  }, [parsedToken])

  useEffect(() => {
    if(!isInstalled && webexCode) {
      dispatch(createWebexIntegration(Number(currentCompanyId), webexCode as string));
    }
  }, [])

  const webexAuth = () => {
    const scopes = "spark%3Akms%20spark%3Arooms_read%20spark%3Amessages_write%20spark%3Arooms_write%20spark%3Amessages_read";
    window.location.href = `${ConfigConstants.webexUrl}?client_id=${ConfigConstants.webexClientId}&response_type=code&scope=${scopes}&redirect_uri=${ConfigConstants.webexUrlRedirect}`;
  }

  const pathIsIncluded = (path: string) => {
    return history.location.pathname.toLocaleLowerCase().includes(path);
  }

  const renderPageContents = () => {
    return (
      <>
        <TabbedContainer type="submenu">
          <NavLink isActive={(match, location) => (!match || !location ? false : match?.isExact)} activeClassName="active" to={`${match?.url}`}>
            {counterpart("INTEGRATIONS_OVERVIEW")}
          </NavLink>
          <NavLink isActive={(match, location) => (!match || !location ? false : match?.isExact) || pathIsIncluded("/faq")} exact activeClassName="active" to={`${match?.url}/faq`}>
            {counterpart("INTEGRATIONS_FAQS")}
          </NavLink>
        </TabbedContainer>

        <PrivateRoute render={() => (
          <>
            <PrivateRoute exact path={match?.path} component={WebexOverview} />
            <PrivateRoute exact path={`${match?.path}/faq`} component={WebexFaq} />
          </>
        )} />
      </>
    )
  }

  const renderInstalledPageContents = () => {
    return (
      <div className="webex-integrations">
        <WebexEventTriggers></WebexEventTriggers>
        <WebexFaq isInstalled></WebexFaq>
      </div>
    )
  }

  if (isLoading) {
    return <ProgressIndicator color="blue" coverage="full-content"></ProgressIndicator>
  }

  return (
    <div>
      <Helmet title={counterpart("INTEGRATIONS_TITLE")} />

      <div className="integrations webex-integrations">
        <NavigationBar pageTitle="" previousPageTitle={
          isFromChecklist || isFromBillingModal 
          ? "GO_LIVE_PRO_PLAN_FEATURE_NAVBAR" 
          : "INTEGRATIONS_TITLE"} previousPageCb={() => 
          isFromChecklist 
          ? history.push({ pathname: "/launch-checklist", state: { hasprofeature: true } }) 
          : isFromBillingModal ? dispatch(setShowBillingModal(true))
          : history.push("/integrations")} />
        {
          isInstalled && showNotification &&
          <NavigationAlert
            className="webex-integrations__integration-success"
            title="INTEGRATION_WEBEX_SUCCESS_TITLE"
            subTitle="INTEGRATION_WEBEX_SUCCESS_TEXT"
            buttonLabel="INTEGRATION_WEBEX_SUCCESS_BUTTON"
            icon="fas fa-headset"
            onClick={() => openIntercomIframe()}
          />
        }
        {
          !isInstalled && showErrorNotification &&
          <NoticePanel
            className="webex-integrations__error"
            type="error"
            icon="fas fa-exclamation-circle"
            content={"INTEGRATION_WEBEX_ERROR"}
            
          />
        }
        {
          <NoticePanel
            type="warning"
            icon="far fa-lightbulb-exclamation"
            content="INTEGRATION_WEBEX_PERMISSIONS_WARNING"
            translateWith={{
              here: <Link to="" id="billing-modal-link" onClick={(evt) => { dispatch(setShowBillingModal(true)); evt.preventDefault();}}>{counterpart("REPORTS_ACCOUNTING_WARNING_PERMISSION_HERE")}</Link>
            }}
            isVisible={!hasPermissionToInstall}
          />
        }
        <PageWrapper>
          <StepCollapsablePanel title={
            <div className="integrations__title">
              <div>
                <img src={logo} alt="no img" />
                <div>
                  <Text content="INTEGRATIONS_WEBEX" noMargin />
                </div>
              </div>
              {
                isInstalled
                  ? (
                    <div>
                      <Button
                        id="visit-website"
                        title="INTEGRATIONS_VIEW_WEBSITE"
                        onClick={(evt: any) => {
                          evt.stopPropagation();
                          window.open("https://www.webex.com/", "WindowName", "noopener");
                        }}
                        buttonType="add"
                      />
                      <Button
                        id="uninstall-btn"
                        title="INTEGRATION_UNINSTALL"
                        onClick={() => setShowModal(true)}
                        buttonType="error"
                      />
                    </div>
                  )
                  : (
                    <div>
                      <Button
                        id="integrate-btn"
                        title="INTEGRATIONS_INTEGRATE"
                        disabled={!hasPermissionToInstall}
                        icon={"far fa-plus-circle"}
                        buttonType="general"
                        onClick={webexAuth}
                      />
                      <Button
                        id="visit-website"
                        title="INTEGRATIONS_VIEW_WEBSITE"
                        onClick={(evt: any) => {
                          evt.stopPropagation();
                          window.open("https://www.webex.com/", "WindowName", "noopener");
                        }}
                        buttonType="add"
                      />
                    </div>
                  )
              }

            </div>
          } className="integrations__free-agent"
            shouldTranslateTitle={false}
            hasHelpSection={false}
            isCollapsed={false}
          >
            <Text content="INTEGRATIONS_WEBEX_TEXT" noMargin />
          </StepCollapsablePanel>

          {
            isInstalled ? renderInstalledPageContents() : renderPageContents()
          }
        </PageWrapper>

        <Modal isOpen={showModal} onRequestClose={() => setShowModal(false)} title="INTEGRATION_UNINSTALL_WEBEX">
          <UninstallWebex onUninstall={() => setShowModal(false)} />
        </Modal>
      </div>
    </div>
  )
}

export default Webex;