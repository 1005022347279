import React, { FormHTMLAttributes, useLayoutEffect, useState } from "react";

interface IForm extends FormHTMLAttributes<HTMLFormElement> { }

interface ExtendedStyle extends CSSStyleDeclaration {
  // the original border of the element
  savedBorder: string
}

const Form = React.forwardRef<HTMLFormElement, IForm>(({ children, onChange = () => { }, onBlur = () => { }, ...props }, forwardedRef) => {
  
  const [form, setForm] = useState<HTMLFormElement | null>(null);
  const uniqueId = Math.random() * 100;
  const touchedClass = "touched";
  const childrenSelector = "input,textarea";

  const showErrorOnInvalidAndTouchedEls = () => {
    if (!form) { return }
    const borderColor = "#F87847";
    const invalidAndTouchedEls: Array<HTMLElement> = Array.from(form.querySelectorAll(`.${touchedClass}[required]:invalid`));
    const allEls: Array<HTMLElement> = Array.from(form.querySelectorAll(childrenSelector))
    for (let item of allEls) {
      // will inherit the original border
      item.style.border = (item.style as ExtendedStyle).savedBorder;
      //item.style.border = "solid 1px #e1e0e0";
    }
    for (let item of invalidAndTouchedEls) {
      item.style.border = `1px solid ${borderColor}`;
    }
  }


  const registerIsTouchedListener = () => {
    const form = document.getElementById(`form-${uniqueId}`) as HTMLFormElement;
    //oninput - onfocus - onblur
    const eventTrigger = "onfocus";
    setForm(form);
    if(!form) { return }
    const inputChildren = Array.from(form.querySelectorAll(childrenSelector));
    for (let i = 0; i < inputChildren.length; i++) {
      const child: any = inputChildren[i];
      // save a reference to the original border
      (child.style as ExtendedStyle).savedBorder = child.style.border;
      if (child && (eventTrigger in child)) {
        child[eventTrigger] = (evt: any) => {
          if (!child.classList.contains(touchedClass)) {
            child.classList.add(touchedClass);
          }
        }
      }
    }
  }

  useLayoutEffect(() => {
    registerIsTouchedListener();
  }, []);

  return (
    <form id={`form-${uniqueId}`} {...props} onChange={(evt) => {
      showErrorOnInvalidAndTouchedEls();
      onChange(evt);
    }} onBlur={(evt) => {
      showErrorOnInvalidAndTouchedEls();
      onBlur(evt);
    }} ref={forwardedRef}>
      {children}
    </form>
  )
})

export default Form;