import React, { useEffect, useMemo, useState } from "react";
import "./CheckoutPreview.scss";
import "./CheckoutPreviewNew.scss";
import Text from "../../../../ui/text/Text";
import Button from "../../../../ui/button/Button";
import { useSelector } from "react-redux";
import { AppState } from "../../../../..";
import { hexToRgb } from "../../../../../utils/commonUtils";
import WebFont from "webfontloader"
import counterpart from "counterpart";

const CheckoutConfirmationPreview: React.FC = () => {
  const companyBrandingReducer = useSelector((state: AppState) => state.companyBrandingReducer);
  const checkoutManagement = useSelector((state: AppState) => state.checkoutAccountManagementReducer.checkoutManagement);
  
  const { typographyBody, typographyHeader, typographyHeaderIsAllCaps, backgroundColor, headerAndButtonColor, bodyTextColor, confirmationColor, errorColor, rounding } = companyBrandingReducer;
  const { confirmationButtonText, confirmationCopy, confirmationHeading, confirmationSubheading } = checkoutManagement;
  const [bottomScrolled, setBottomScrolled] = useState(false);

  const backgroundColorStyle = useMemo(() => `  
  .checkout-preview-new__body__gradient {
    background:  linear-gradient(0deg, rgba(${hexToRgb(backgroundColor)},1) 9%, rgba(${hexToRgb(backgroundColor)},0) 55%)
  }
  .checkout-preview-new {
    background-color: ${backgroundColor}
  }
`, [backgroundColor]);

  const headerAndButtonColorStyle = useMemo(() => `
  .cart__button,
  .checkout-preview-new .number-input__btn,
  .checkout-preview-new__btn,
  .navigation-bar__filler,
  .navigation-bar__text {
    background-color: ${headerAndButtonColor}
  }
  .checkout-preview-new__panel__title,
  .checkout-preview-new__header__title,
  .checkout-preview-new__header__icon {
    color: ${headerAndButtonColor}
  }
  .navigation-bar__container {
    background-color: rgba(${hexToRgb(headerAndButtonColor)}, 0.5)
  }
`, [headerAndButtonColor]);

  const bodyTextColorStyle = useMemo(() => `
  .checkout-preview-new__body-text {
    color: ${bodyTextColor}
  }
`, [bodyTextColor])

  const confirmationColorStyle = useMemo(() => `
  .checkout-preview-new__btn.checkout-preview-new__btn--selected {
    background-color: ${confirmationColor}
  }
`, [confirmationColor]);

  const errorColorStyle = useMemo(() => `
  .checkout-preview-new .number-input__btn.lock {
    background-color: ${errorColor}
  }
`, [errorColor]);

  const roundingStyle = useMemo(() => `
  .checkout-preview-new,
  .checkout-preview-new__btn,
  .checkout-preview-new__panel {
    border-radius: ${rounding}px
  }
  .checkout-preview-new .number-input__btn.lock,
  .cart__button {
    border-radius: ${rounding}px 0px 0px ${rounding}px
  }
  .checkout-preview-new .number-input__btn {
    border-radius: 0px ${rounding}px ${rounding}px 0px
  }
  .navigation-bar__text {
    border-top-right-radius: ${rounding}px
  }
`, [rounding]);
  
  const bodyFontStyle = useMemo(() => `
  .checkout-preview-new__body-text,
  .checkout-preview-new__btn span {
    font-family: ${typographyBody.value};
  }
  `, [typographyBody])

  const headerFontStyle = useMemo(() => `
  .checkout-preview-new__header__title,
  .checkout-preview-new__panel__title,
  .checkout-preview-new__header__icon {
    font-family: ${typographyHeader.value};
    text-transform: ${typographyHeaderIsAllCaps ? "uppercase" : "unset"}
  }
  `, [typographyHeaderIsAllCaps, typographyHeader]);

  useEffect(() => {
    WebFont.load({
      google: {
        families: [typographyHeader.value, typographyBody.value],
      },
    })
  }, [typographyHeader, typographyBody])

  const onScrollContainer = (evt: any) => {
    if ((evt.target.scrollHeight - evt.target.offsetHeight) <= evt.target.scrollTop) {
      setBottomScrolled(true);
    } else {
      setBottomScrolled(false);
    }
  }

  const renderHeader = () => {
    return (
      <div className="checkout-preview-new__header">
        <div className="checkout-preview-new__header__content align-center">
          <Text className="checkout-preview-new__header__title" content={!!confirmationHeading ? confirmationHeading : counterpart("CONFIRMATION_SCREEN_PANEL_HEADING_PLACEHOLDER")} shouldTranslate={false} weight="bold" noMargin></Text>
        </div>
      </div>
    )
  }

  const renderContents = () => {
    return (
      <div className="checkout-preview-new__body no-subtitle" onScroll={onScrollContainer}>
        <div className="checkout-preview-new__body__gradient" style={{ bottom: "82px", opacity: bottomScrolled ? 0 : 1 }}></div>
        <div className="checkout-preview-new__panel" style={{ minHeight: "104px" }}>
          <div className="checkout-preview-new__panel__content">
            <Text className="checkout-preview-new__panel__title" content={!!confirmationSubheading ? confirmationSubheading : counterpart("CONFIRMATION_SCREEN_PANEL_SUB_HEADING_PLACEHOLDER")} shouldTranslate={false} weight="bold" noMargin></Text>
            <Text className="checkout-preview-new__body-text checkout-preview-new__panel__subtitle" content={!!confirmationCopy ? confirmationCopy : counterpart("CONFIRMATION_SCREEN_PANEL_COPY_PLACEHOLDER")} shouldTranslate={false} noMargin></Text>
          </div>
        </div>

      </div>
    )
  }

  const renderNewCheckoutPreview = () => {
    return (
      <>
        <style id="123">
          {`
            ${backgroundColorStyle}
            ${headerAndButtonColorStyle}
            ${bodyTextColorStyle}
            ${confirmationColorStyle}
            ${errorColorStyle}
            ${roundingStyle}
            ${headerFontStyle}
            ${bodyFontStyle}
          `}
        </style>
        <div className="checkout-preview-new">
          {renderHeader()}
          {renderContents()}
          <div className="checkout-preview-new__footer">
            <Button className="checkout-preview-new__btn" id="continue" isFullWidth title={!!confirmationButtonText ? confirmationButtonText : counterpart("CONFIRMATION_SCREEN_PANEL_BUTTON_TEXT_PLACEHOLDER")} shouldTranslate={false}></Button>
          </div>
        </div>
      </>
    )
  }

  return renderNewCheckoutPreview()
}

export default CheckoutConfirmationPreview;