export enum SimulationKeyType {
  FirstInvoicePriceFormatted = "FirstInvoicePriceFormatted",
  FirstInvoiceDate = "FirstInvoiceDate",
  SecondInvoicePriceFormatted = "SecondInvoicePriceFormatted",
  SecondInvoiceDate = "SecondInvoiceDate",
  CyclePriceFormatted = "CyclePriceFormatted",
  SetupFeeFormatted = "SetupFeeFormatted",
  ProRatedPriceFormatted = "ProRatedPriceFormatted",
  ProRateType = "ProRateType",
  BillingCurrency = "BillingCurrency",
  Frequency = "Frequency",
  FrequencyType = "FrequencyType",
  EndTrialDate = "EndTrialDate"
}

export enum ProRateOptions {
  DoNotProRate = "DoNotProRate",
  DoNotChargeUntilNextCycle = "DoNotChargeUntilNextCycle",
  ProRateBasedOnDaysRemaining = "ProRateBasedOnDaysRemaining"
}

export enum BusinessType {
  PRE_ESTABLISHED = "PRE_ESTABLISHED",
  HIGH_RISK = "HIGH_RISK",
  LOW_RISK = "LOW_RISK"
}


export interface IDictionary<TVal> {
  [index: string]: TVal;
}

export interface PaymentDescriptionInputModel {
  values: IDictionary<string>
}