import React, { useState } from "react";
import FixedSidebar from "../ui/fixed-sidebar/FixedSidebar";
import LoadingModal from "../modal/loading-modal/LoadingModal";
import InvoiceStandaloneSidebarHome from "./InvoiceStandaloneSidebarHome";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../..";
import { InvoiceStandaloneSidebarCurrentScreen } from "../../models/InvoiceStandalone";
import { setInvoiceStandaloneField } from "../../actions/invoiceStandaloneActions";
import InvoiceStandaloneSidebarReplaceCard from "./InvoiceStandaloneSidebarReplaceCard";
import counterpart from "counterpart";
import Panel from "../ui/panel/Panel";
import Text from "../ui/text/Text";
import Button from "../ui/button/Button";
import { downloadPdf, lightOrDark } from "../../utils/commonUtils";

const InvoiceStandaloneSidebar: React.FC = () => {
  const invoiceStandaloneReducer = useSelector((state: AppState) => state.invoiceStandaloneReducer);

  const dispatch = useDispatch<Function>();

  const { invoice, sidebarCurrentScreen, reattemptPaymentInvoiceRequest } = invoiceStandaloneReducer;
  const [isCreatingPdf, setIsCreatingPdf] = useState(false);

  if (!invoice) {
    return null;
  }
  const { companyDomain, companyName } = invoice;

  const getContent = () => {
    switch (sidebarCurrentScreen) {
      case InvoiceStandaloneSidebarCurrentScreen.HOME:
        return <InvoiceStandaloneSidebarHome />;
      case InvoiceStandaloneSidebarCurrentScreen.REPLACE_PAYMENT:
        return <InvoiceStandaloneSidebarReplaceCard />;
    }
  };

  const download = () => {
    var element = document.querySelector(".receipt-details") as HTMLElement;
    setIsCreatingPdf(true);
    downloadPdf(element, invoice.invoiceNumber)
      .then(() => setIsCreatingPdf(false));
  };

  const renderSupportContactDetails = () => {
    return (
      <>
        {(invoice.supportContactHeading || invoice.supportContactContent) && (
          <Panel className="invoice-sidebar-preview__panel">
            {invoice.supportContactHeading && (
              <Text component="h6" content={invoice.supportContactHeading} shouldTranslate={false} style={{ color: `#${invoice.brandingHighlightColor}` }} noMargin></Text>
            )}
            {invoice.supportContactContent && invoice.supportContactContent.split("\n").map((text: string, idx) => <Text key={idx} content={text} shouldTranslate={false}></Text>)}
          </Panel>
        )}
        {invoice.supportContactSupportUrl && (
          <Button
            id="support-button"
            type="button"
            className="invoice-sidebar-preview__arrow-button"
            shouldTranslate={false}
            title={invoice.supportContactSupportUrlButtonText}
            style={{ backgroundColor: `#${invoice.brandingHighlightColor}` }}
            isFullWidth
            onClick={(evt: any) => {
              evt.preventDefault();
              window.open(invoice.supportContactSupportUrl, "_self");
            }}
          />
        )}
        {invoice.advertImageUrl && invoice.advertLinkToUrl && (
          <a className="invoice-sidebar-preview__link-advert" href={invoice.advertLinkToUrl}>
            <img className="invoice-sidebar-preview__advert" src={invoice.advertImageUrl} alt="no img" />
          </a>
        )}
      </>
    );
  };

  return (
    <>
      <FixedSidebar
        backgroundHeader={`#${invoice.brandingLogoBackgroundColor}`}
        logoHeader={invoice.brandingLogo}
        leftIcon={
          sidebarCurrentScreen !== InvoiceStandaloneSidebarCurrentScreen.HOME ? (
            <i className="far fa-arrow-left" onClick={() => dispatch(setInvoiceStandaloneField("sidebarCurrentScreen", InvoiceStandaloneSidebarCurrentScreen.HOME))} />
          ) : (
            undefined
          )
        }
        rightIcon={<i className={`fal fa-file-pdf ${lightOrDark(`#${invoice.brandingLogoBackgroundColor}`) === "dark" ? "light-icon" : ""}`} onClick={download} />}
        companyDomain={companyDomain}
        companyName={companyName}
        className="invoice-standalone-sidebar"
      >
        {getContent()}
        {renderSupportContactDetails()}
      </FixedSidebar>

      <LoadingModal
        isOpen={isCreatingPdf || reattemptPaymentInvoiceRequest}
        title={isCreatingPdf ? counterpart("DOWNLOADING_INVOICE") : reattemptPaymentInvoiceRequest ? counterpart("INVOICE_STANDALONE_RETTEMPT_PAYMENT_TITLE", { amount: invoice.formattedAmount }) : ""}
        subTitle={isCreatingPdf ? "" : counterpart("INVOICE_STANDALONE_RETTEMPT_PAYMENT_SUBTITLE")}
        shouldTranslateTitle={false}
        shouldTranslateSubTitle={false}
      />
    </>
  );
};

export default InvoiceStandaloneSidebar;
