import React, { useLayoutEffect, useMemo, useState } from "react";
import "../Taxes.scss";
import { Link, useRouteMatch } from "react-router-dom";
import Panel from "../../../../ui/panel/Panel";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../../../../..";
import { setCurrentCountry, resetCompanyTaxJarCurrentCountry } from "../../../../../actions/companyTaxRatesActions";
import { ICompanyTaxRatesReducerState } from "../../../../../reducers/companyTaxRatesReducer";
import InteractiveList from "../../../../ui/interactive-list/InteractiveList";
import Modal from "../../../../modal/Modal";
import TaxJarTokenIntegration from "../../../../tax-integration/taxjar-token-integration/TaxJarTokenIntegration";
import TaxJarEditIntegration from "../../../../tax-integration/taxjar-token-integration/taxjar-edit-integration/TaxJarEditIntegration";
import BillsbyTaxIntegration from "../../../../tax-integration/billsby-tax-integration/BillsbyTaxIntegration";
import BillsbyTaxEditIntegration from "../../../../tax-integration/billsby-tax-integration/billsby-tax-edit/BillsbyTaxEditIntegration";
import ManualTaxIntegration from "../../../../tax-integration/manual-tax-integration/ManualTaxIntegration";
import counterpart from "counterpart";
import Text from "../../../../ui/text/Text";
import { ComplexTaxCountry } from "../../../../../models/Taxes";
import { useHistory } from "react-router";
import { Permission } from "../../../../../models/Auth";
import NoticePanel from "../../../../ui/notice-panel/NoticePanel";
import { setShowBillingModal } from "../../../../../actions/authActions";


interface IMatchProps {
  countryCode: ComplexTaxCountry;
}

const TaxConfiguration: React.FC = () => {

  const { currentCountry, taxableCountries } = useSelector<AppState, ICompanyTaxRatesReducerState>(state => state.companyTaxRatesReducer);
  const parsedToken = useSelector((state: AppState) => state.auth.parsedToken);
  const dispatch = useDispatch<Function>()

  const history = useHistory()
  const [showTaxJarModal, setShowTaxJarModal] = useState(false);
  const [showTaxCalculationModal, setShowTaxCalculationModal] = useState(false);
  const [showManualTaxModal, setShowManualTaxModal] = useState(false);

  const hasTaxJarPermission = useMemo(() => {
    return !!parsedToken?.CurrentPermissions.some(p => p === Permission[Permission.TaxJarRead]);
  }, [parsedToken]);

  const match = useRouteMatch()
  const params = match?.params as IMatchProps;
  const regionsWithoutTaxJar = useMemo(() => params.countryCode !== ComplexTaxCountry.UnitedStates && params.countryCode !== ComplexTaxCountry.Canada, [params])

  const getListData = () => {
    if (regionsWithoutTaxJar) {
      return [
        {
          title: "CONFIG_MANUALLY_ADD_TAX_RATES_TITLE",
          subTitle: "CONFIG_MANUALLY_ADD_TAX_RATES_CONTENT",
          onClick: () => setShowManualTaxModal(!showManualTaxModal),
        },
      ];
    }

    return [
      {
        title: "CONFIG_INTEGRATE_TITLE",
        subTitle: (
          <Text
            component="span"
            className="interactive-list__element__subTitle"
            content="CONFIG_INTEGRATE_CONTENT"
            translateWith={{
              link: (
                <a href="https://app.taxjar.com/sign_up" target="_self" onClick={(evt: any) => evt.stopPropagation()}>
                  {counterpart("CONFIG_INTEGRATE_TRY_TAXJAR")}
                </a>
              ),
            }}
          />
        ),
        isLocked: !hasTaxJarPermission,
        shouldTranslateSubtitle: false,
        statusLabel: "CONFIG_INTEGRATE_RECOMMENDED",
        onClick: () => setShowTaxJarModal(!showTaxJarModal),
      },
      {
        title: "CONFIG_CALCULATE_TITLE",
        subTitle: "CONFIG_CALCULATE_CONTENT",
        onClick: () => setShowTaxCalculationModal(!showTaxCalculationModal),
      },
    ];
  };

  useLayoutEffect(() => {
    const country = taxableCountries.filter((country) => country.iso3Code === params.countryCode);

    if (country.length !== 0) {
      dispatch(setCurrentCountry({ countryName: country[0].name, countryCode: country[0].iso3Code }));
    }

    return () => dispatch(resetCompanyTaxJarCurrentCountry());
  }, [taxableCountries]);

  if (!currentCountry) {
    return null;
  }

  return (
    <>
      <Panel className="taxes__country-back" shouldTranslate={false} title={""} onClick={() => history.push("/configuration/taxes")}>
        <i className="far fa-chevron-left" /> <h6 className="content-panel-title"> {currentCountry.countryName} </h6>
      </Panel>
      <NoticePanel isVisible={!hasTaxJarPermission && !regionsWithoutTaxJar} type="warning" icon="far fa-lightbulb-exclamation" content="CONFIG_TAXJAR_WARNING_PERMISSION" translateWith={{
          here: <Link to="" id="billing-modal-link" onClick={(evt) => { dispatch(setShowBillingModal(true)); evt.preventDefault(); }}>{counterpart("REPORTS_ACCOUNTING_WARNING_PERMISSION_HERE")}</Link>
        }}
      />

      {!currentCountry.taxConfig && <InteractiveList data={getListData()} />}

      <Modal
        shouldTranslateTitle={false}
        title={currentCountry.countryName}
        isOpen={showTaxJarModal}
        onRequestClose={() => {
          setShowTaxJarModal(false);
          currentCountry.taxConfig && history.push("/configuration/taxes");
        }}
        children={currentCountry.taxConfig ? <TaxJarEditIntegration showSuccessPanel={true} /> : <TaxJarTokenIntegration />}
      />
      <Modal
        shouldTranslateTitle={false}
        title={currentCountry.countryName}
        isOpen={showTaxCalculationModal}
        onRequestClose={() => {
          setShowTaxCalculationModal(false);
          currentCountry.taxConfig && history.push("/configuration/taxes");
        }}
        children={currentCountry.taxConfig ? <BillsbyTaxEditIntegration showSuccessPanel={true} /> : <BillsbyTaxIntegration />}
      />
      <Modal
        shouldTranslateTitle={false}
        title={currentCountry.countryName}
        isOpen={showManualTaxModal}
        onRequestClose={() => {
          setShowManualTaxModal(false);
          currentCountry.taxConfig && history.push("/configuration/taxes");
        }}
        children={<ManualTaxIntegration  />}
      />
    </>
  );
};

export default TaxConfiguration