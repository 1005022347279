import React from "react";
import Panel from "../../../../ui/panel/Panel";
import InteractiveList from "../../../../ui/interactive-list/InteractiveList";
import "./EmailNotificationsMenu.scss";
import { useHistory } from "react-router";

const EmailNotificationsMenu: React.FC = () => {
  const history = useHistory();
  
  return (
    <div>
      <Panel className="configuration-main__section-panel" title="EMAILS_MENU_SUBSCRIPTION_LIFECYCLE">
        <InteractiveList
          data={[
            {
              title: "EMAILS_MENU_SUBSCRIPTION_LIFECYCLE_WELCOME_EMAILS",
              subTitle: "EMAILS_MENU_SUBSCRIPTION_LIFECYCLE_WELCOME_EMAILS_DESCRIPTION",
              onClick: () => history.push("/configuration/email-notifications/emails/welcome-emails")
            },
            {
              title: "EMAILS_MENU_SUBSCRIPTION_LIFECYCLE_TRIAL_MANAGEMENT",
              subTitle: "EMAILS_MENU_SUBSCRIPTION_LIFECYCLE_TRIAL_MANAGEMENT_DESCRIPTION",
              onClick: () => history.push("/configuration/email-notifications/emails/trial-management")
            },
            {
              title: "EMAILS_MENU_SUBSCRIPTION_LIFECYCLE_RENEWAL_REMINDERS",
              subTitle: "EMAILS_MENU_SUBSCRIPTION_LIFECYCLE_RENEWAL_REMINDERS_DESCRIPTION",
              onClick: () => history.push("/configuration/email-notifications/emails/renewal-reminders")
            },
            {
              title: "EMAILS_MENU_SUBSCRIPTION_LIFECYCLE_CONTRACT_TERM",
              subTitle: "EMAILS_MENU_SUBSCRIPTION_LIFECYCLE_CONTRACT_TERM_DESCRIPTION",
              onClick: () => history.push("/configuration/email-notifications/emails/contract-term")
            },
            {
              title: "EMAILS_MENU_SUBSCRIPTION_LIFECYCLE_DISCOUNT_AND_COUPONS",
              subTitle: "EMAILS_MENU_SUBSCRIPTION_LIFECYCLE_DISCOUNT_AND_COUPONS_DESCRIPTION",
              onClick: () => history.push("/configuration/email-notifications/emails/discount-coupons")
            }
          ]}
        />
      </Panel>

      <Panel className="configuration-main__section-panel" title="EMAILS_MENU_INVOICES_AND_PAYMENTS">
        <InteractiveList
          data={[
            {
              title: "EMAILS_MENU_INVOICES_AND_PAYMENTS_PAYMENTS_CREDIT_REFUND",
              subTitle: "EMAILS_MENU_INVOICES_AND_PAYMENTS_PAYMENTS_CREDIT_REFUND_DESCRIPTION",
              onClick: () => history.push("/configuration/email-notifications/emails/payment-credits-refund")
            }
          ]}
        />
      </Panel>

      <Panel className="configuration-main__section-panel" title="EMAILS_MENU_RETENTION">
        <InteractiveList
          data={[
            {
              title: "EMAILS_MENU_RETENTION_EXPIRATIONS",
              subTitle: "EMAILS_MENU_RETENTION_EXPIRATIONS_DESCRIPTION",
              onClick: () => history.push("/configuration/email-notifications/emails/expiring-payment-methods")
            },
            {
              title: "EMAILS_MENU_RETENTION_NEWBILLDATE",
              subTitle: "EMAILS_MENU_RETENTION_NEWBILLDATE_DESCRIPTION",
              onClick: () => history.push("/configuration/email-notifications/emails/new-bill-date")
            },
            /*{
              title: 'EMAILS_MENU_RETENTION_PAUSES',
              subTitle: 'EMAILS_MENU_RETENTION_PAUSES_DESCRIPTION',
              onClick: () => history.push('/configuration/email-notifications/emails/pauses')
            },*/
            {
              title: "EMAILS_MENU_RETENTION_CANCELLATIONS",
              subTitle: "EMAILS_MENU_RETENTION_CANCELLATIONS_DESCRIPTION",
              onClick: () => history.push("/configuration/email-notifications/emails/cancellations")
            }
          ]}
        />
      </Panel>

      <Panel className="configuration-main__section-panel" title="EMAILS_MENU_SELF_SERVICE">
        <InteractiveList
          data={[
            {
              title: "EMAILS_MENU_SELF_SERVICE_ONE_TIME_PASSCODE",
              subTitle: "EMAILS_MENU_SELF_SERVICE_ONE_TIME_PASSCODE_DESCRIPTION",
              onClick: () => history.push("/configuration/email-notifications/emails/one-time-passcode")
            },
            {
              title: "EMAILS_MENU_SELF_SERVICE_PAYMENT_DETAILS_REQUEST",
              subTitle: "EMAILS_MENU_SELF_SERVICE_PAYMENT_DETAILS_REQUEST_DESCRIPTION",
              onClick: () => history.push("/configuration/email-notifications/emails/payment-details-request")
            }
          ]}
        />
      </Panel>
    </div>
  )
}

export default EmailNotificationsMenu;
