export enum Language {
  ENGLISH = "en-us",
  FRENCH = "fr-fr",
  GERMAN = "de-de",
  ITALIAN = "it-it",
  SPANISH = "es-es"
}

export interface ISupportedLanguage {
  englishName: string;
  nativeName: string;
  isoCode: string;
  isEnabled: boolean;
}

export interface ICompanyLaguageConfig {
  companyLanguagesList: Array<ISupportedLanguage>;
  defaultCheckoutLanguage: string;
  defaultAccountManagementLanguage: string;
}