import { BillsbyAction } from "../models/BillsbyAction";
import { SET_PLAN_PRODUCT_ID, SET_PLAN_PRODUCT_NAME, SET_PLAN_NAME, SET_PLAN_DISPLAY_NAME, SET_PLAN_DESCRIPTION, SET_PLAN_FEATURE_TAG, REMOVE_PLAN_FEATURE_TAG, SET_PLAN_FORM_COLLAPSED, SET_PLAN_FORM_COMPLETED, SET_PLAN_FORM_HAS_ERROR, SET_PLAN_PRICING_MODEL, SET_PLAN_PRICING_MODEL_FORM_COLLAPSED, SET_PLAN_HELP_TITLE_BASIC, SET_PLAN_HELP_CONTENT_BASIC, SET_HAS_SELECTED_FEE, CREATE_PLAN_REQUEST, CREATE_PLAN_SUCCESS, CREATE_PLAN_FAILURE, RESET_PLAN_FOR_EDITING, SET_PLAN_FIELD, SET_PLAN_VISIBILITY, SET_PLAN_REDIRECT_URL, SET_PLAN_ALERT_EMAIL, EDIT_PLAN_REQUEST, EDIT_PLAN_FAILURE, EDIT_PLAN_SUCCESS, SET_PLAN_ADD_ONS, SET_PLAN_ALLOWANCES, SET_PLAN_HELP_TITLE_ADVANCED, SET_PLAN_HELP_CONTENT_ADVANCED, SET_IS_PLAN_IMAGE_ENABLED, PLAN_IMAGE_UPLOAD_SUCCESS, SET_PLAN_ERROR, SET_PLAN_IMAGE_URL, SET_PLAN_IMAGE_FILE_NAME, PLAN_IMAGE_UPLOAD_REQUEST, PLAN_IMAGE_UPLOAD_FAILURE, SET_PLAN_ALERT_EMAIL_IS_VALID } from "../actions/productPlanCycleActions";
import { AppState } from "..";
import { PricingModelType, VisibilityType, IPlanAddOn, IPlanAllowance } from "../models/Product";
import { getActionErrorMessage, getErrorMessage } from "../utils/commonUtils";

export interface IPlanReducer {
  planProductId: number,
  planProductName: string
  name: string,
  displayName: string,
  description: string,
  isPlanImageEnabled: boolean,
  isPlanImageUploading: boolean,
  planImageUrl: string,
  planImageFileName: string,
  planError: string,
  featureTags: Array<any>,
  stageCollapsed: Array<boolean>,
  stageErrors: Array<boolean>,
  stageStatuses: Array<boolean>,
  helpTextTitleBasic: string,
  helpTextContentBasic: Array<string>,
  helpTextTitleAdvanced: string,
  helpTextContentAdvanced: Array<string>
  pricingModel: PricingModelType | undefined,
  hasSelectedFee: boolean,
  isCreatingPlanRequest: boolean,
  isCreatingPlanSuccess: boolean,
  isCreatingPlanFailure: boolean,
  isEditingPlanRequest: boolean,
  isEditingPlanSuccess: boolean,
  isEditingPlanFailure: boolean,
  errorMessage: string,
  visibility: VisibilityType,
  redirectUrl: string,
  alertEmail: string,
  isAlertEmailValid: boolean
  addons: Array<IPlanAddOn>,
  allowances: Array<IPlanAllowance>
}

export const initialState = {
  planProductId: -1,
  planProductName: "",
  name: "",
  displayName: "",
  description: "",
  isPlanImageEnabled: false,
  isPlanImageUploading: false,
  planImageUrl: "",
  planImageFileName: "",
  planError: "",
  featureTags: [],
  stageCollapsed: [false, true, true, true, true],
  stageErrors: [false, false, false, false, false],
  stageStatuses: [false, false, false, false, false],
  helpTextTitleBasic: "HELP_EMPTY_TITLE",
  helpTextContentBasic: ["HELP_EMPTY_1", "HELP_EMPTY_2"],
  helpTextTitleAdvanced: "HELP_EMPTY_TITLE",
  helpTextContentAdvanced: ["HELP_EMPTY_1", "HELP_EMPTY_2"],
  pricingModel: undefined,
  hasSelectedFee: false,
  isCreatingPlanRequest: false,
  isCreatingPlanSuccess: false,
  isCreatingPlanFailure: false,
  isEditingPlanRequest: false,
  isEditingPlanSuccess: false,
  isEditingPlanFailure: false,
  errorMessage: "",
  visibility: VisibilityType.Public,
  redirectUrl: "",
  alertEmail: "",
  isAlertEmailValid: true,
  addons: [],
  allowances: []
}

export default function plansReducer(state: IPlanReducer = initialState, action: BillsbyAction, store: AppState) {
  switch (action.type) {
    case SET_PLAN_PRODUCT_ID:
      return { ...state, planProductId: action.payload }
    case SET_PLAN_PRODUCT_NAME:
      return { ...state, planProductName: action.payload }
    case SET_PLAN_NAME:
      return { ...state, name: action.payload }
    case SET_PLAN_ADD_ONS:
      return { ...state, addons: action.payload }
    case SET_PLAN_ALLOWANCES:
      return { ...state, allowances: action.payload }
    case SET_PLAN_DISPLAY_NAME:
      return { ...state, displayName: action.payload }
    case SET_PLAN_DESCRIPTION:
      return { ...state, description: action.payload }
    case SET_IS_PLAN_IMAGE_ENABLED:
      return { ...state, isPlanImageEnabled: action.payload }
    case SET_PLAN_IMAGE_URL:
      return { ...state, planImageUrl: action.payload }
    case SET_PLAN_IMAGE_FILE_NAME:
      return { ...state, planImageFileName: action.payload }
    case SET_PLAN_ERROR:
      return { ...state, planError: action.payload, planImageUrl: "", planImageFileName: "" }
    case SET_PLAN_FEATURE_TAG:
      {
        action.payload.name = action.payload.name.replace(/[^a-z0-9]/g, "");
        let tags = state.featureTags.slice(0);
        if (action.payload.name && action.payload.name.trim() !== "")
          tags.push(action.payload);
        return { ...state, featureTags: tags }
      }
    case REMOVE_PLAN_FEATURE_TAG:
      {
        let tags = state.featureTags.slice(0)
        tags.splice(action.payload, 1);
        return { ...state, featureTags: tags }
      }
    case SET_PLAN_FORM_COLLAPSED: {
      let panelToCollapse = action.payload;
      let newArray = [...state.stageCollapsed];
      newArray = newArray.map(el => (el !== panelToCollapse ? true : el));
      newArray[panelToCollapse] = !newArray[panelToCollapse];

      return { ...state, stageCollapsed: newArray }
    }
    case SET_PLAN_FORM_COMPLETED: {
      let newArray = [...state.stageStatuses];
      const cycles = store.cycles.cycles;
      //Check all fields are filled
      if (action.stageIndex === 0 && state.name !== "") {
        newArray[action.stageIndex] = action.value;
      } else if (action.stageIndex === 1 && state.hasSelectedFee) {
        newArray[action.stageIndex] = action.value;
      } else if (action.stageIndex === 2 && cycles.length) {
        newArray[action.stageIndex] = action.value;
      } else {
        newArray[action.stageIndex] = action.value;
      }

      return { ...state, stageStatuses: newArray }
    }
    case SET_PLAN_FORM_HAS_ERROR:
      let newArray = [...state.stageErrors];
      newArray[action.stageIndex] = action.value;
      return { ...state, stageErrors: newArray }
    case SET_PLAN_PRICING_MODEL:
      return { ...state, pricingModel: action.payload }
    case SET_PLAN_PRICING_MODEL_FORM_COLLAPSED:
      return { ...state, isPricingModelFormCollapsed: action.payload }
    case SET_PLAN_HELP_TITLE_BASIC:
      return { ...state, helpTextTitleBasic: action.payload }
    case SET_PLAN_HELP_CONTENT_BASIC:
      return { ...state, helpTextContentBasic: action.payload }
    case SET_PLAN_HELP_TITLE_ADVANCED:
      return { ...state, helpTextTitleAdvanced: action.payload }
    case SET_PLAN_HELP_CONTENT_ADVANCED:
      return { ...state, helpTextContentAdvanced: action.payload }
    case PLAN_IMAGE_UPLOAD_REQUEST:
      return { ...state, isPlanImageUploading: true }
    case PLAN_IMAGE_UPLOAD_FAILURE: {
      const { error } = action;
      return { ...state, isPlanImageUploading: false, planError: getErrorMessage(error) }
    }
    case PLAN_IMAGE_UPLOAD_SUCCESS:
      {
        const { response } = action;
        return { ...state, planImageFileName: response.fileName, planImageUrl: response.imageSrcUrl, planError: "", isPlanImageUploading: false }
      }
    case SET_HAS_SELECTED_FEE:
      return { ...state, hasSelectedFee: action.payload }

    case RESET_PLAN_FOR_EDITING:
      return { ...initialState };
    case CREATE_PLAN_REQUEST:
      return { ...state, isCreatingPlanRequest: true, isCreatingPlanSuccess: false, isCreatingPlanFailure: false }
    case CREATE_PLAN_SUCCESS:
      return { ...state, isCreatingPlanRequest: false, isCreatingPlanSuccess: true, isCreatingPlanFailure: false }
    case CREATE_PLAN_FAILURE:
      return { ...state, isCreatingPlanRequest: false, isCreatingPlanSuccess: false, isCreatingPlanFailure: true, errorMessage: getActionErrorMessage(action.error) }
    case EDIT_PLAN_REQUEST:
      return { ...state, isEditingPlanRequest: true, isEditingPlanSuccess: false, isEditingPlanFailure: false }
    case EDIT_PLAN_SUCCESS:
      return { ...state, isEditingPlanRequest: false, isEditingPlanSuccess: true, isEditingPlanFailure: false }
    case EDIT_PLAN_FAILURE:
      return { ...state, isEditingPlanRequest: false, isEditingPlanSuccess: false, isEditingPlanFailure: true, errorMessage: getActionErrorMessage(action.error) }

    case SET_PLAN_FIELD: {
      return { ...state, [action.fieldName]: action.fieldValue }
    }
    case SET_PLAN_VISIBILITY:
      return { ...state, visibility: action.payload }

    case SET_PLAN_ALERT_EMAIL:
      return { ...state, alertEmail: action.payload }
    
    case SET_PLAN_ALERT_EMAIL_IS_VALID:
      return { ...state, isAlertEmailValid: action.payload }

    case SET_PLAN_REDIRECT_URL:
      return { ...state, redirectUrl: action.payload }

    default:
      return state;
  }
}