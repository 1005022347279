import { BillsbyAction } from "../models/BillsbyAction";
import { AppState } from "..";
import { FETCH_EMAIL_BASE_TEMPLATE_SUCCESS, SET_BASE_EMAIL_TEMPLATE_FIELD, UPDATE_EMAIL_BASE_TEMPLATE_ADS_BANNER_SUCCESS, RESET_EMAIL_BASE_TEMPLATE_VALUES, FETCH_EMAIL_BASE_TEMPLATE_FAILURE, UPSERT_EMAIL_BASE_TEMPLATE_SUCCESS, UPSERT_EMAIL_BASE_TEMPLATE_FAILURE } from "../actions/emailBaseTemplateActions";
import { GetEmailTemplateConfigResponse } from "../utils/grpc/generated/Billsby.Protos/Messenger/private/companyconfig/companyconfig_pb";


export interface IEmailBaseTemplateState {
  companyId: number,
  includeAdvert: boolean,
  avertisementImageUrl: string,
  advertisementUrl: string,
  footerText: string,
  errorMessage: string
}


export const initialState: IEmailBaseTemplateState = {
  companyId: 0,
  includeAdvert: false,
  avertisementImageUrl: "",
  advertisementUrl: "",
  footerText: "",
  errorMessage: ""
}

export default function emailBaseTemplateReducer(state: IEmailBaseTemplateState = initialState, action: BillsbyAction, store: AppState) {
  switch (action.type) {    
    case UPDATE_EMAIL_BASE_TEMPLATE_ADS_BANNER_SUCCESS:
      const adsImage = action.response;
      return { ...state, avertisementImageUrl: adsImage.imageSrcUrl }
    case UPSERT_EMAIL_BASE_TEMPLATE_SUCCESS:
        return { ...state, errorMessage: "" };
    case UPSERT_EMAIL_BASE_TEMPLATE_FAILURE:
        const failureResponse = action as any;
        return { ...state, errorMessage: failureResponse.error.message };
    case SET_BASE_EMAIL_TEMPLATE_FIELD:
      return { ...state, [action.fieldName]: action.fieldValue }
    case FETCH_EMAIL_BASE_TEMPLATE_SUCCESS: {
      const response = action.response as GetEmailTemplateConfigResponse;
      return { ...state,
        companyId: response.getCompanyId(),
        includeAdvert: response.getIncludeAdvert(),
        avertisementImageUrl: response.getAdvertisementImageUrl(),
        advertisementUrl: response.getAdvertisementUrl(),
        footerText: response.getFooterText(),
      };
    }
    case FETCH_EMAIL_BASE_TEMPLATE_FAILURE: {
      return state;
    }
    case RESET_EMAIL_BASE_TEMPLATE_VALUES:
      return { ...initialState }
    default:
      return state
  }
}