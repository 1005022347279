import React, { useState } from "react";
import "./PauseCancelSubscription.scss";
import { NavLink, useRouteMatch } from "react-router-dom";
import Panel from "../../../ui/panel/Panel";
import Text from "../../../ui/text/Text";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../../../..";
import history from "../../../../utils/history";
import NavigationBar from "../../../ui/navigation-bar/NavigationBar";
import PageWrapper from "../../../ui/layout/PageWrapper";
import TabbedContainer from "../../../ui/tabbed-container/TabbedContainer";
import counterpart from "counterpart";
import { PrivateRoute } from "../../../../containers/AuthRoutes";
import { IAuthReducerState } from "../../../../reducers/authReducer";
import CancelSubscriptionConfig from "./cancel-subscription-config/CancelSubscriptionConfig";
import PauseSubscriptionWelcome from "./pause-subscription-welcome/PauseSubscriptionWelcome";
import EditRetentionStep from "./edit-retention-step/EditRetentionStep";


const PauseCancelSubscription: React.FC = () => {
  const { currentCompanyDomain } = useSelector<AppState, IAuthReducerState>(state => state.auth)
  const dispatch = useDispatch<Function>()
  const match = useRouteMatch()

  const [state, setState] = useState({ isLoadingData: false });


  const pathIsIncluded = (path: string) => {
    return history.location.pathname.toLocaleLowerCase().includes(path);
  }
  
  if(state.isLoadingData) {
    return null;
  }

  return (
    <div className="pause-cancel-subscription">
      <NavigationBar pageTitle="" previousPageTitle="CONFIGURATION_PAGE_TITLE" previousPageCb={() => history.push("/configuration")} />

      <PageWrapper>
        <Panel title="PAUSE_AND_CANCEL_SUBSCRIPTION_TITLE" className="pause-cancel-subscription__welcome" noMargin>
          <Text content="PAUSE_AND_CANCEL_SUBSCRIPTION_DESCRIPTION" noMargin />
        </Panel>

        <TabbedContainer type="submenu">
          <NavLink isActive={(match, location) => (!match || !location ? false : match?.isExact) || pathIsIncluded("/cancel")} exact activeClassName="active" to={`${match?.url}/cancel`}>
            {counterpart("PAUSE_AND_CANCEL_SUBSCRIPTION_CANCEL_TAB_TITLE")}
          </NavLink>
          <NavLink isActive={(match, location) => (!match || !location ? false : match?.isExact)} activeClassName="active" to={`${match?.url}`}>
            {counterpart("PAUSE_AND_CANCEL_SUBSCRIPTION_PAUSE_TAB_TITLE")}
          </NavLink>   
        </TabbedContainer>

        <PrivateRoute render={() => (
          <>
            <PrivateRoute exact path={match?.path} component={PauseSubscriptionWelcome} />
            <PrivateRoute exact path={`${match?.path}/cancel`} component={CancelSubscriptionConfig} />
            <PrivateRoute exact path={`${match?.path}/cancel/edit/:retentionStep`} component={EditRetentionStep} />
          </>
        )} />

      </PageWrapper>
    </div>
  );
};

export default PauseCancelSubscription
