import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../..";
import Panel from "../ui/panel/Panel";
import FormLabel from "../ui/form-label/FormLabel";
import Text from "../ui/text/Text";
import PaymentDetailsFormCheckout from "../payment-details-form-checkout/PaymentDetailsFormCheckout";
import "./InvoiceStandaloneSidebar.scss";
import Button from "../ui/button/Button";
import FormGroup from "../ui/form-group/FormGroup";
import { setInvoiceStandaloneField, reattemptInvoicePayment, fetchInvoiceStandalone, REPLACE_PAYMENT_CARD_FAILURE, replacePaymentCardSCA } from "../../actions/invoiceStandaloneActions";
import { validatePaymentDetailsForm } from "../payment-details-form/PaymentDetailsForm";
import { ICustomerPaymentDetailsError, ICustomerCardDetails, CardSCAStatus } from "../../models/Customer";
import { ISpreedlyCardData } from "../../models/Spreedly";
import { InvoiceStandaloneSidebarCurrentScreen } from "../../models/InvoiceStandalone";
import { setup3dSecure } from "../sca-setup/ScaSetup";
import NoticePanel from "../ui/notice-panel/NoticePanel";
import { ConfigConstants } from "../../utils/config";

const InvoiceStandaloneSidebarReplaceCard: React.FC = () => {
  const invoiceStandaloneReducer = useSelector((state: AppState) => state.invoiceStandaloneReducer);

  const dispatch = useDispatch<Function>();
  
  const { invoice, cardHolderName, expDateMM, expDateYYYY, replacePaymentCardRequest, reattemptPaymentInvoiceRequest, replacePaymentCardFailure, customerLanguage } = invoiceStandaloneReducer;
  const [formErrors, setFormErrors] = useState<ICustomerPaymentDetailsError>({ cardNumber: "", expiryMonth: "", expiryYear: "" });

  if (!invoice) { return null }

  const submitPaymentDetails = () => {
    const url = `https://app${ConfigConstants.billsbyDomain}/invoice/${invoice.invoiceNumber}`
    
    validatePaymentDetailsForm({ cardholderName: cardHolderName, expiryMonth: expDateMM as any, expiryYear: expDateYYYY as any },
      {
        addressLine1: invoice.addressLine1,
        addressLine2: invoice.addressLine2,
        state: invoice.addressState,
        city: invoice.addressCity,
        country: invoice.addressCountryCode,
        postCode: invoice.addressPostCode
      },
      async (cardData: ISpreedlyCardData, error: ICustomerPaymentDetailsError | null) => {
        if (error || !cardData) {
          setFormErrors(error as ICustomerPaymentDetailsError);
          return;
        }
        const newCardData: ICustomerCardDetails = {
          cardType: cardData.card_type,
          expiryMonth: +expDateMM,
          expiryYear: +expDateYYYY,
          last4Digits: cardData.last_four_digits,
          paymentCardToken: cardData.token,
          fullName: cardData.full_name,
        }

        try {
          const { response } = await dispatch(replacePaymentCardSCA(invoice.companyDomain, invoice.customerUniqueId, { ...newCardData, redirectUrl: url, callbackUrl: url }))
          if (!response) {
            return
          }
  
          if (response.status === CardSCAStatus.UpdateCompleted) {
            await dispatch(reattemptInvoicePayment(invoice.companyDomain, invoice.invoiceNumber));
            await dispatch(fetchInvoiceStandalone(invoice.invoiceNumber));
            await dispatch(setInvoiceStandaloneField("sidebarCurrentScreen", InvoiceStandaloneSidebarCurrentScreen.HOME))
            return;
          }
  
          if (response.status === CardSCAStatus.Unassigned) {
            return dispatch({ type: REPLACE_PAYMENT_CARD_FAILURE })
          }
  
          const statusUpdates = async (event: { action: string }) => {
            sessionStorage.setItem("invoiceStandaloneSCA", JSON.stringify({
              replacePaymentCardSCAPayload: response,
              newCardData: { ...newCardData, redirectUrl: url, callbackUrl: url, transactionToken: response.transactionToken }
            }));
  
            if (event.action === "succeeded" || event.action === "trigger-completion") {
              await dispatch(replacePaymentCardSCA(invoice.companyDomain, invoice.customerUniqueId, { ...newCardData, transactionToken: response.transactionToken }))
              await dispatch(reattemptInvoicePayment(invoice.companyDomain, invoice.invoiceNumber));
              await dispatch(fetchInvoiceStandalone(invoice.invoiceNumber));
              await dispatch(setInvoiceStandaloneField("sidebarCurrentScreen", InvoiceStandaloneSidebarCurrentScreen.HOME))
            }
            else if (event.action === "error") {
              return dispatch({ type: REPLACE_PAYMENT_CARD_FAILURE });
            }
          }
  
          return setup3dSecure(response.transactionToken, statusUpdates);
        } catch (err) {
          console.log(err)
          return dispatch({ type: REPLACE_PAYMENT_CARD_FAILURE });
        }
      });
  }

  return (
    <div className="invoice-standalone-sidebar-replace-card">
      {replacePaymentCardFailure && <NoticePanel content="INVOICE_STANDALONE_CARD_DETAILS_FAILED_UPDATE" type="error"></NoticePanel>}
      <FormGroup>
        <Panel>
          <FormLabel locale={customerLanguage} target="" content="INVOICE_STANDALONE_REPLACE_PAYMENT_CARD_TITLE" className="heading" />
          <Text locale={customerLanguage} content="INVOICE_STANDALONE_REPLACE_PAYMENT_CARD_CONTENT" noMargin />
        </Panel>
        <PaymentDetailsFormCheckout
          locale={customerLanguage}
          className="invoice-standalone-sidebar-replace-card__form"
          formData={{ cardholderName: cardHolderName, expiryMonth: expDateMM, expiryYear: expDateYYYY }}
          onChangeCardholderName={(evt: any) => dispatch(setInvoiceStandaloneField("cardHolderName", evt.target.value))}
          onChangeExpiryMonth={(evt: any) => dispatch(setInvoiceStandaloneField("expDateMM", evt.target.value))}
          onChangeExpiryYear={(evt: any) => dispatch(setInvoiceStandaloneField("expDateYYYY", evt.target.value))}
          cardNumberId="idcard"
          cvvId="idcvv"
          formErrors={formErrors}
        />
      </FormGroup>

      <Button
        locale={customerLanguage}
        className="invoice-standalone-sidebar-replace-card__btn"
        id="replace-card"
        title="INVOICE_STANDALONE_REPLACE_PAYMENT_CARD_BTN"
        translateWith={{ amount: invoice.formattedAmount }}
        isLoading={reattemptPaymentInvoiceRequest || replacePaymentCardRequest}
        buttonType="orange"
        isFullWidth
        onClick={submitPaymentDetails}
      />
    </div>
  )
}

export default InvoiceStandaloneSidebarReplaceCard;