import API from "../utils/API";
import { makeActionCreator, makeGenericActionCreator } from "../utils/reduxUtils";
import { ICompanyDetailsConfigrationData } from "../models/CompanyDetails";
import { CraftyClicksSelectedAddress } from "../utils/craftyClicksUtils";
import { GetCompanyAlertsAndNotificationRequest, UpdateCompanyAlertsAndNotificationRequest } from "../utils/grpc/generated/Billsby.Protos/Messenger/private/companyconfig/companyconfig_pb";
import { CompanyConfigServiceClient } from "../utils/grpc/generated/Billsby.Protos/Messenger/private/companyconfig/CompanyconfigServiceClientPb";
import { ConfigConstants } from "../utils/config";
import { grpcUnaryCall } from "../utils/grpc/grpcUtils";

export const FETCH_COMPANY_DETAILS_REQUEST = "FETCH_COMPANY_DETAILS_REQUEST";
export const FETCH_COMPANY_DETAILS_SUCCESS = "FETCH_COMPANY_DETAILS_SUCCESS";
export const FETCH_COMPANY_DETAILS_FAILURE = "FETCH_COMPANY_DETAILS_FAILURE";

export const UPDATE_COMPANY_DETAILS_REQUEST = "UPDATE_COMPANY_DETAILS_REQUEST";
export const UPDATE_COMPANY_DETAILS_SUCCESS = "UPDATE_COMPANY_DETAILS_SUCCESS";
export const UPDATE_COMPANY_DETAILS_FAILURE = "UPDATE_COMPANY_DETAILS_FAILURE";
export const SET_COMPANY_DETAILS_ADDRESS = "SET_COMPANY_DETAILS_ADDRESS";

export const UPDATE_COMPANY_DETAILS_ALERT_EMAIL_REQUEST = "UPDATE_COMPANY_DETAILS_ALERT_EMAIL_REQUEST";
export const UPDATE_COMPANY_DETAILS_ALERT_EMAIL_SUCCESS = "UPDATE_COMPANY_DETAILS_ALERT_EMAIL_SUCCESS";
export const UPDATE_COMPANY_DETAILS_ALERT_EMAIL_FAILURE = "UPDATE_COMPANY_DETAILS_ALERT_EMAIL_FAILURE";

export const FETCH_COMPANY_DETAILS_ALERT_EMAIL_REQUEST = "FETCH_COMPANY_DETAILS_ALERT_EMAIL_REQUEST";
export const FETCH_COMPANY_DETAILS_ALERT_EMAIL_SUCCESS = "FETCH_COMPANY_DETAILS_ALERT_EMAIL_SUCCESS";
export const FETCH_COMPANY_DETAILS_ALERT_EMAIL_FAILURE = "FETCH_COMPANY_DETAILS_ALERT_EMAIL_FAILURE";

export const SET_COMPANY_DETAILS_FIELD = "SET_COMPANY_DETAILS_FIELD";
export const RESET_COMPANY_DETAILS = "RESET_COMPANY_DETAILS";

export const SET_COMPANY_DETAILS_ALERT_NOTIF_FIELD = "SET_COMPANY_DETAILS_ALERT_NOTIF_FIELD";
export const RESET_COMPANY_DETAILS_ALERT_NOTIF = "RESET_COMPANY_DETAILS_ALERT_NOTIF";


type AllowedCompanyDetailsReducerFields = "name" | "billingAddressLine1" | "billingAddressLine2" | "billingAddressCity" | "billingAddressPostCode" | "billingAddressState" | "billingAddressCountryISO3" | "industry" | "timeZone" | "companyDomain"  ;
type AllowedAlertNotificationReducerFields = "billingEmail" | "alertEmail";

export const resetCompanyDetails = makeActionCreator(RESET_COMPANY_DETAILS, "payload");
export const setCompanyDetailsField = makeGenericActionCreator<AllowedCompanyDetailsReducerFields>(SET_COMPANY_DETAILS_FIELD);

export const resetCompanyDetailsAlertNotif = makeActionCreator(RESET_COMPANY_DETAILS_ALERT_NOTIF, "payload");
export const setCompanyDetailsAlertNotifField = makeGenericActionCreator<AllowedAlertNotificationReducerFields>(SET_COMPANY_DETAILS_ALERT_NOTIF_FIELD);

export const fetchCompanyConfigurationDetails = function(companyDomain: string) {
  return {
    types: [FETCH_COMPANY_DETAILS_REQUEST, FETCH_COMPANY_DETAILS_SUCCESS, FETCH_COMPANY_DETAILS_SUCCESS],
    callAPI: () => API.fetchCompanyConfigurationDetails(companyDomain)
  }
}

export const updateCompanyConfigurationDetails = function(companyDomain: string, data: ICompanyDetailsConfigrationData) {
  return {
    types: [UPDATE_COMPANY_DETAILS_REQUEST, UPDATE_COMPANY_DETAILS_SUCCESS, UPDATE_COMPANY_DETAILS_FAILURE],
    callAPI: () => API.updateCompanyConfigurationDetails(companyDomain, data)
  }
}

export const setCompanyConfigurationAddressForm = function(selectedAddress: CraftyClicksSelectedAddress ){
  return {
    type: SET_COMPANY_DETAILS_ADDRESS,
    payload: selectedAddress
  }
}

export const fetchCompanyAlertEmail = function(companyId: number) {
  const getCompanyAlertsAndNotificationRequest = new GetCompanyAlertsAndNotificationRequest();
  const companyConfigServiceClient = new CompanyConfigServiceClient(ConfigConstants.grpcBaseUrl);

  getCompanyAlertsAndNotificationRequest.setCompanyId(companyId);

  return {
    types: [FETCH_COMPANY_DETAILS_ALERT_EMAIL_REQUEST, FETCH_COMPANY_DETAILS_ALERT_EMAIL_SUCCESS, FETCH_COMPANY_DETAILS_ALERT_EMAIL_FAILURE],
    callAPI: () => grpcUnaryCall(getCompanyAlertsAndNotificationRequest, companyConfigServiceClient, companyConfigServiceClient.getCompanyAlertsAndNotification)
  }
}

export const updateCompanyAlertEmail = function(companyId: number, alertEmail: string) {
  const updateCompanyAlertsAndNotificationRequest = new UpdateCompanyAlertsAndNotificationRequest();
  const companyConfigServiceClient = new CompanyConfigServiceClient(ConfigConstants.grpcBaseUrl);

  updateCompanyAlertsAndNotificationRequest.setCompanyId(companyId);
  updateCompanyAlertsAndNotificationRequest.setAlertEmail(alertEmail);

  return {
    types: [UPDATE_COMPANY_DETAILS_ALERT_EMAIL_REQUEST, UPDATE_COMPANY_DETAILS_ALERT_EMAIL_SUCCESS, UPDATE_COMPANY_DETAILS_ALERT_EMAIL_FAILURE],
    callAPI: () => grpcUnaryCall(updateCompanyAlertsAndNotificationRequest, companyConfigServiceClient, companyConfigServiceClient.updateCompanyAlertsAndNotification)
  }
}