import { BillsbyAction } from "../models/BillsbyAction";
import { AppState } from "..";
import { SET_REVENUE_REPORT_FIELD, FETCH_REVENUE_REPORT_SUCCESS, RESET_REVENUE_REPORT, FETCH_REVENUE_REPORT_FAILURE, FETCH_REVENUE_REPORT_REQUEST } from "../actions/revenueReportActions";
import { RevenueReportFilterType } from "../models/Reports";
import { GetInvoiceRevenueResponse, GetPlanRevenueResponse, GetProductRevenueResponse, GetSubscriptionRevenueResponse } from "../utils/grpc/generated/Billsby.Protos/revenuerecognition/private/reports/reports_pb";

export interface IRevenueReportState {
  startDate: string,
  endDate: string,
  tableHeaderType: string,
  headerMessage: string,
  datesList: Array<string>,
  filterType: { value: RevenueReportFilterType, label: string },
  details?: GetPlanRevenueResponse | GetSubscriptionRevenueResponse | GetInvoiceRevenueResponse | GetProductRevenueResponse
};

export const initState: IRevenueReportState = {
  startDate: "",
  endDate: "",
  datesList: [],
  headerMessage: "",
  tableHeaderType: "",
  filterType: { value: RevenueReportFilterType.PRODUCT, label: "" }
}

export default function revenueReportReducer(state: IRevenueReportState = initState, action: BillsbyAction, store: AppState) {
  switch (action.type) {
    case FETCH_REVENUE_REPORT_REQUEST:
    case FETCH_REVENUE_REPORT_FAILURE: {
      return { ...state, details: undefined }
    }

    case FETCH_REVENUE_REPORT_SUCCESS: {
      const details = action.response as (GetPlanRevenueResponse | GetSubscriptionRevenueResponse | GetInvoiceRevenueResponse | GetProductRevenueResponse);
      if (details instanceof GetPlanRevenueResponse) {
        details.getResultList().sort((a, b) => {
          if (a.getProductName().toLowerCase() > b.getProductName().toLowerCase()) {
            return 1;
          }
          else if (a.getProductName().toLowerCase() === b.getProductName().toLowerCase()) {
            if (a.getPlanName().toLowerCase() > b.getPlanName().toLowerCase()) {
              return 1;
            }
            return -1;
          }
          return -1;
        });
      }
      if (details instanceof GetProductRevenueResponse) {
        details.getResultList().sort((a, b) => a.getProductName().toLowerCase() > b.getProductName().toLowerCase() ? 1 : -1);
      }
      if (details instanceof GetSubscriptionRevenueResponse) {
        details.getResultList().sort((a, b) => a.getSubscriptionUniqueId().toLowerCase() > b.getSubscriptionUniqueId().toLowerCase() ? 1 : -1);
      }
      if (details instanceof GetInvoiceRevenueResponse) {
        details.getResultList().sort((a, b) => a.getInvoiceUniqueId().toLowerCase() > b.getInvoiceUniqueId().toLowerCase() ? 1 : -1);
      }
      return { ...state, details }
    }

    case SET_REVENUE_REPORT_FIELD: {
      return { ...state, [action.fieldName]: action.fieldValue }
    }

    case RESET_REVENUE_REPORT: {
      return initState
    }

    default: return state
  }
}