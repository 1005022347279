import { BillsbyAction } from "../models/BillsbyAction";
import { AppState } from "..";
import { CustomerDataStatus, IInvoiceDetails, PaymentMethodType } from "../models/Customer";
import { FETCH_INVOICE_DETAILS_SUCCESS, SET_INVOICE_PAYMENT_LOADING, RESET_INVOICE_DETAILS, PUT_INVOICE_PAYMENT_FAILURE, PUT_INVOICE_PAYMENT_REQUEST, PUT_INVOICE_PAYMENT_SUCCESS } from "../actions/invoiceDetailsActions";
import { InvoiceRefundType, InvoiceStatus, InvoiceType } from "../models/Invoices";
import { BillsbyException } from "../utils/fetchUtils";
import { extractInfoErrorMessage } from "../utils/commonUtils";

export interface IInvoiceDetailsReducerState {
  invoiceDetails: IInvoiceDetails,
  paymentLoading: boolean,
  reattemptErrorMessage?: string
}

const formatAmountValue = (value: number) => {
  return ((value) / 100).toFixed(2);
}

export const initialState: IInvoiceDetailsReducerState = {
  invoiceDetails: {
    invoiceId: 0,
    customerEmail: "",
    customerFirstName: "",
    customerLastName: "",
    customerUniqueId: "",
    customerStatus: CustomerDataStatus.ACTIVE,
    customerTaxRegNumber: "",
    customerPaymentMethodTypeName: PaymentMethodType.CREDIT_CARD,
    addressLine1: "",
    addressLine2: "",
    addressCity: "",
    addressPostCode: "",
    addressState: "",
    addressCountryCode: "",
    addressCountry: "",
    invoiceNumber: "",
    createdOn: "",
    formattedAmount: "",
    amount: 0,
    currency: "",
    status: InvoiceStatus.PAID,
    subTotal: "",
    taxTotal: "",
    taxes: [],
    cardExpiryMonth: 0,
    cardExpiryYear: 0,
    cardType: "",
    cardLast4Digits: "",
    companyDomain: "",
    taxPercentage: 0,
    subscriptions: null,
    companyName: "",
    companyPhone: "",
    companyEmail: "",
    companyTaxLabel: "",
    companyTaxNumber: "",
    refundableAmount: 0,
    brandingLogo: "",
    brandingLogoBackgroundColor: "",
    brandingHighlightColor: "",
    invoiceType: InvoiceType.RENEWAL,
    companyCity: "",
    companyCountry: "",
    companyAddressLine1: "",
    companyAddressLine2: "",
    companyPostCode: "",
    companyState: "",
    companyPhoneNumber: "",
    companyPhoneNumberDialCode: "",
    companyPhoneNumberDialCountry: "",
    supportContactContent: "",
    supportContactHeading: "",
    supportContactSupportUrl: "",
    supportContactSupportUrlButtonText: "",
    advertImageUrl: "",
    advertLinkToUrl: "",
    prorationAdjustment: "",
    prorationFactor: undefined,
    isReattemptable: false,
    isRefundable: true,
    refundType: InvoiceRefundType.AVAILABLE
  },
  paymentLoading: false
}

const invoiceDetailsReducer = (state: IInvoiceDetailsReducerState = initialState, action: BillsbyAction, store: AppState) => {
  switch (action.type) {
    case FETCH_INVOICE_DETAILS_SUCCESS:
      return { ...state, invoiceDetails: { ...state.invoiceDetails, ...action.response, amount: formatAmountValue(action.response.amount), refundableAmount: formatAmountValue(action.response.refundableAmount) } }
    case SET_INVOICE_PAYMENT_LOADING:
      return { ...state, paymentLoading: action.payload };
    case RESET_INVOICE_DETAILS:
      return { ...initialState }
    case PUT_INVOICE_PAYMENT_REQUEST:
    case PUT_INVOICE_PAYMENT_SUCCESS:
      return {  ...state, reattemptErrorMessage: undefined }
    case PUT_INVOICE_PAYMENT_FAILURE:
      return { ...state, reattemptErrorMessage: extractInfoErrorMessage((action.error as BillsbyException).info) };
    default:
      return state;
  }
};


export default invoiceDetailsReducer;
