import React, { useState } from "react"
import { setPaymentGatewaysField, fetchPaymentGateways, cancelLastPaymentGateway } from "../../../../../actions/paymentGatewaysActions";
import { ConfigConstants } from "../../../../../utils/config";
import { IPaymentGatewayElement } from "../../../../../models/Configuration";
import { UpdateCloverConnectRequest } from "../../../../../utils/grpc/generated/Billsby.Protos/billing/private/paymentgateway/payment_gateway_pb";
import { PaymentGatewayServiceClient } from "../../../../../utils/grpc/generated/Billsby.Protos/billing/private/paymentgateway/Payment_gatewayServiceClientPb";
import { grpcUnaryCall } from "../../../../../utils/grpc/grpcUtils";
import CloverConnectGatewayForm from "./CloverConnectGatewayForm";
import PaymentGatewayContainer from "../payment-gateways-shared-components/PaymentGatewayContainer";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../../../../..";
import { IAuthReducerState } from "../../../../../reducers/authReducer";

interface Props {
  paymentGateway: IPaymentGatewayElement,
  isOneGatewayPending: boolean,
  currentGatewayState: any,
  idx: number,
  isCreatingGateway: boolean,
  onError: () => void,
}

interface IData {
  username: string,
  merchantId: string
}

const CloverConnectPaymentGatewayUpdate: React.FC<Props> = ({ onError, paymentGateway, isOneGatewayPending, currentGatewayState, idx, isCreatingGateway }) => {

  const { currentCompanyDomain } = useSelector<AppState, IAuthReducerState>(state => state.auth)
  const dispatch = useDispatch<Function>()
  const [helpTitle, setHelpTitle] = useState("PAYMENT_GATEWAYS_ADDED_ADYEN_HELP");
  const [helpContent, setHelpContent] = useState(["PAYMENT_GATEWAYS_ADDED_ADYEN_HELP_CONTENT_1", "PAYMENT_GATEWAYS_ADDED_ADYEN_HELP_CONTENT_2"]);

  const cancelChanges = () => {
    paymentGateway.mustActivate ? dispatch(cancelLastPaymentGateway())
      : dispatch(setPaymentGatewaysField(`paymentGateway${idx}`, { ...currentGatewayState, displayName: paymentGateway.title, isCollapsed: true }))
  }

  const updatePaymentGateway = async (displayName: string, userName: string, password: string, merchantAccount: string) => {
    const updateCloverConnectRequest = new UpdateCloverConnectRequest();
    updateCloverConnectRequest.setCompanyDomain(currentCompanyDomain);
    updateCloverConnectRequest.setDisplayName(displayName);
    updateCloverConnectRequest.setLogin(userName);
    updateCloverConnectRequest.setPassword(password);
    updateCloverConnectRequest.setMerchantId(merchantAccount);
    updateCloverConnectRequest.setPaymentGatewayId(paymentGateway.paymentGatewayId as number)
    const paymentGatewayServiceClient = new PaymentGatewayServiceClient(ConfigConstants.grpcBaseUrl);
    try {
      await grpcUnaryCall(updateCloverConnectRequest, paymentGatewayServiceClient, paymentGatewayServiceClient.updateCloverConnectGateway);
      await dispatch(fetchPaymentGateways(currentCompanyDomain));
      dispatch(setPaymentGatewaysField(`paymentGateway${idx}`, { ...currentGatewayState, isCollapsed: true }));
    } catch {
      onError();
    }
  }
  
  return (
    <PaymentGatewayContainer
      helpTitle={helpTitle}
      helpContent={helpContent}
      description="PAYMENT_GATEWAYS_CLOVER_CONNECT_DESCRIPTION"
      isOneGatewayPending={isOneGatewayPending}
      currentGatewayState={currentGatewayState}
      onClick={() => dispatch(setPaymentGatewaysField(`paymentGateway${idx}`, { ...currentGatewayState, isCollapsed: paymentGateway.mustActivate ? false : !currentGatewayState.isCollapsed }))}
      websiteLink="https://integrate.clover.com/partner/billsby"
      type="edit"
      paymentGateway={paymentGateway}
    >
      <CloverConnectGatewayForm
        onSave={(displayName: string, userName: string, password: string, merchantAccount: string) => updatePaymentGateway(displayName, userName, password, merchantAccount)}
        onCancel={() => cancelChanges()}
        setHelpTitle={(text: string) => setHelpTitle(text)}
        setHelpContent={(text: Array<string>) => setHelpContent(text)}        
        isSaveEnabled={!isCreatingGateway}
        isEdit={true}
        initDisplayName={paymentGateway.title}
        initUserName=""
        initMerchantAccount=""
        initPassword=""
      />
    </PaymentGatewayContainer>
  )
}

export default CloverConnectPaymentGatewayUpdate
