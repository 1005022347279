import { makeActionCreator } from "../utils/reduxUtils";
import API from "../utils/API";
import { DashboardChartType, DashboardChartPeriod } from "../models/Dashboard";
import { DashboardServiceClient } from "../utils/grpc/generated/Billsby.Protos/dashboard/private/dashboard/DashboardServiceClientPb";
import { grpcUnaryCall } from "../utils/grpc/grpcUtils";


import { ConfigConstants } from "../utils/config";
import { GetDashboardRequest } from "../utils/grpc/generated/Billsby.Protos/dashboard/private/dashboard/dashboard_pb";



export const RESET_DASHBOARD_STATE = "RESET_DASHBOARD_STATE";
export const resetDashboardState = makeActionCreator(RESET_DASHBOARD_STATE);

export const FETCH_DASHBOARD_CHARTS_DATA_REQUEST = "FETCH_DASHBOARD_CHARTS_DATA_REQUEST";
export const FETCH_DASHBOARD_CHARTS_DATA_SUCCESS = "FETCH_DASHBOARD_CHARTS_DATA_SUCCESS";
export const FETCH_DASHBOARD_CHARTS_DATA_FAILURE = "FETCH_DASHBOARD_CHARTS_DATA_FAILURE";

export const FETCH_DASHBOARD_RECENT_DATA_REQUEST = "FETCH_DASHBOARD_RECENT_DATA_REQUEST";
export const FETCH_DASHBOARD_RECENT_DATA_SUCCESS = "FETCH_DASHBOARD_RECENT_DATA_SUCCESS";
export const FETCH_DASHBOARD_RECENT_DATA_FAILURE = "FETCH_DASHBOARD_RECENT_DATA_FAILURE";

export const FETCH_DASHBOARD_CURRENT_DATA_REQUEST = "FETCH_DASHBOARD_CURRENT_DATA_REQUEST";
export const FETCH_DASHBOARD_CURRENT_DATA_SUCCESS = "FETCH_DASHBOARD_CURRENT_DATA_SUCCESS";
export const FETCH_DASHBOARD_CURRENT_DATA_FAILURE = "FETCH_DASHBOARD_CURRENT_DATA_FAILURE";


export const FETCH_DASHBOARD_NEW_CURRENT_DATA_REQUEST = "FETCH_DASHBOARD_NEW_CURRENT_DATA_REQUEST";
export const FETCH_DASHBOARD_NEW_CURRENT_DATA_SUCCESS = "FETCH_DASHBOARD_NEW_CURRENT_DATA_SUCCESS";
export const FETCH_DASHBOARD_NEW_CURRENT_DATA_FAILURE = "FETCH_DASHBOARD_NEW_CURRENT_DATA_FAILURE";


export const fetchDashboardChartsData = (companyDomain: string, statType: DashboardChartType, period: DashboardChartPeriod) => {
  return {
    types: [FETCH_DASHBOARD_CHARTS_DATA_REQUEST, FETCH_DASHBOARD_CHARTS_DATA_SUCCESS, FETCH_DASHBOARD_CHARTS_DATA_FAILURE],
    callAPI: () => API.fetchDashboardChartStats(companyDomain, statType, period)
  }
}

export const fetchDashboardRecentData = (companyDomain: string) => {
  return {
    types: [FETCH_DASHBOARD_RECENT_DATA_REQUEST, FETCH_DASHBOARD_RECENT_DATA_SUCCESS, FETCH_DASHBOARD_RECENT_DATA_FAILURE],
    callAPI: () => API.fetchDashboardRecentStasts(companyDomain)
  }
}

export const fetchDashboardCurrentData = (companyDomain: string) => {
  return {
    types: [FETCH_DASHBOARD_CURRENT_DATA_REQUEST, FETCH_DASHBOARD_CURRENT_DATA_SUCCESS, FETCH_DASHBOARD_CURRENT_DATA_FAILURE],
    callAPI: () => API.fetchDashboardCurrentStats(companyDomain)
  }
}


export const fetchDashboardNewCurrentData = (companyId: number) => {
  const dashboardServiceClient = new DashboardServiceClient(ConfigConstants.grpcBaseUrl);
  const getDashboardHeader = new GetDashboardRequest();
  getDashboardHeader.setCompanyId(companyId);

  return {
    types: [FETCH_DASHBOARD_NEW_CURRENT_DATA_REQUEST, FETCH_DASHBOARD_NEW_CURRENT_DATA_SUCCESS, FETCH_DASHBOARD_NEW_CURRENT_DATA_FAILURE],
    callAPI: () => grpcUnaryCall(getDashboardHeader, dashboardServiceClient, dashboardServiceClient.getDashboard)
  }
}