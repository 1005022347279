import React, { FormEvent, useEffect, useState } from "react";
import Panel from "../../../ui/panel/Panel";
import Text from "../../../ui/text/Text";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../../../..";
import history from "../../../../utils/history";
import NavigationBar from "../../../ui/navigation-bar/NavigationBar";
import PageWrapper from "../../../ui/layout/PageWrapper";
import counterpart from "counterpart";
import { IAuthReducerState } from "../../../../reducers/authReducer";
import StepCollapsablePanel from "../../../ui/step-collapsable-panel/StepCollapsablePanel";
import Input from "../../../ui/input/Input";
import FormLabel from "../../../ui/form-label/FormLabel";
import FormGroup from "../../../ui/form-group/FormGroup";
import { Col, Row } from "react-grid-system";
import Button from "../../../ui/button/Button";
import { ICompanyDetailsReducerState } from "../../../../reducers/companyDetailsReducer";
import { setCompanyDetailsField, fetchCompanyConfigurationDetails, updateCompanyConfigurationDetails, setCompanyConfigurationAddressForm, fetchCompanyAlertEmail, setCompanyDetailsAlertNotifField, updateCompanyAlertEmail } from "../../../../actions/companyDetailsActions";
import "./CompanyDetails.scss";
import AddressLookup from "../../../address-lookup/AddressLookup";
import { CraftyClicksSelectedAddress } from "../../../../utils/craftyClicksUtils";
import momentTZ from "moment-timezone";
import Dropdown from "../../../ui/dropdown/Dropdown";
import { INDUSTRY_OPTIONS, INDUSTRY_MULTIOPTIONS } from "../../../../utils/industries/industries";
import Modal from "../../../modal/Modal";
import ChangeCompanyDomain from "./ChangeCompanyDomain";
import ErrorNotification from "../../../ui/error-notification/ErrorNotification";
import { isValidEmailFormat } from "../../../../utils/commonUtils";
interface IDropdownItem {
  value: string;
  label: string;
}

const CompanyDetails: React.FC = () => {
  const auth = useSelector<AppState, IAuthReducerState>(state => state.auth);
  const companyDetailsReducer = useSelector<AppState, ICompanyDetailsReducerState>(state => state.companyDetailsReducer);
  
  const dispatch = useDispatch();

  const { currentCompanyId, currentCompanyDomain } = auth;
  const { companyAlertsAndNotifications, companyDetails:
    {
      errorMessage,
      isUpdatingDetailsFailure,
      name, companyDomain, billingAddressCity,
      billingAddressLine1, billingAddressLine2,
      billingAddressCountryISO3, billingAddressState,
      billingAddressPostCode, timeZone,
      industry,
      isUpdatingDetailsRequest,
    }
  } = companyDetailsReducer;

  const [isLoadingData, setIsLoadingData] = useState(true);
  const [errors, setErrors] = useState([]);
  const [timeZones, setTimeZones] = useState<IDropdownItem[]>([]);
  const [showChangeDomainModal, setShowChangeDomainModal] = useState(false);
  const [helpMenuTitle, setHelpMenuTitle] = useState("CONFIG_COMPANY_DETAILS_HELP1_TITLE");
  const [helpMenuContent, setHelpMenuContent] = useState(["CONFIG_COMPANY_DETAILS_HELP1_CONTENT1", "CONFIG_COMPANY_DETAILS_HELP1_CONTENT2"]);

  useEffect(() => {
    const fetchCompanyConfigData = async () => {
      setTimeZones(momentTZ.tz.names().map(tz => ({ value: tz, label: `${tz} - UTC ${momentTZ.tz(tz).format("Z")}` })));
      setIsLoadingData(true);
  
      try {
        await dispatch(fetchCompanyConfigurationDetails(currentCompanyDomain));
        currentCompanyId && await dispatch(fetchCompanyAlertEmail(currentCompanyId))
      } catch (err) {
        console.log(err)
      } finally {
        setIsLoadingData(false)
      }
    }
    fetchCompanyConfigData()
  }, [])

  const onUpdateCompanyDetails = async (evt: FormEvent) => {
    evt.preventDefault();
    evt.stopPropagation();

    try {
      const response: any = await dispatch(updateCompanyConfigurationDetails(currentCompanyDomain, {
        name,
        billingAddressLine1,
        billingAddressLine2,
        billingAddressPostCode,
        billingAddressCity,
        billingAddressState,
        billingAddressCountryISO3,
        timeZone,
        industry
      }));

      if(response && response.error && response.error.list && response.error.list.length) {
        setErrors(response.error.list.map((el: any) => el.code));
      }
    } catch (err) {
      console.log(err)
    } finally {
      setIsLoadingData(false)
    }
  }

  const onUpdateCompanyAlertsNotifications = async (evt: FormEvent) => {
    evt.preventDefault();
    evt.stopPropagation();

    if(!currentCompanyId) {
      return;
    }

    try {
      await dispatch(updateCompanyAlertEmail(currentCompanyId, companyAlertsAndNotifications.alertEmail));
      await dispatch(fetchCompanyAlertEmail(currentCompanyId));
    } catch (err) {
      console.log(err)
    }
  }

  const getTimeZoneDropdownValue = (selectedTimeZone: string) => {
    return timeZones.filter(item => item.value === selectedTimeZone);
  }

  const setSelectedIndustry = (category: string, subCategory: string) => {
    dispatch(setCompanyDetailsField("industry", `${category} > ${subCategory}`));
  }

  const resetHelpMenu = () => {
    setHelpMenuTitle("CONFIG_COMPANY_DETAILS_HELP1_TITLE");
    setHelpMenuContent(["CONFIG_COMPANY_DETAILS_HELP1_CONTENT1", "CONFIG_COMPANY_DETAILS_HELP1_CONTENT2"])
  }

  const renderCompanyDetailsForm = () => {
    const selectedIndustry = INDUSTRY_OPTIONS.find(item => !!(industry && industry.match(new RegExp(`.*${item.value}.*>.*`))));
    return (
      <form id="company-details-form" onSubmit={onUpdateCompanyDetails}>
        <FormGroup>
          <Row align="center">
            <Col xs={1.5}>
              <FormLabel target="company-name" content="CONFIG_COMPANY_DETAILS_NAME" noMargin />
            </Col>
            <Col xs={10.5}>
              <Input
                isError={errors.some((err: string) => err === "Company_Configuration_Invalid_Company_Name")}
                id="company-name"
                placeholder={counterpart("CONFIG_COMPANY_DETAILS_COMPANY_NAME")}
                disabled={isUpdatingDetailsRequest}
                value={name}
                onChange={(evt: any) => dispatch(setCompanyDetailsField("name", evt.target.value))}
              />
            </Col>
          </Row>
        </FormGroup>

        <Row className="company-details__address-lookup">
          <Col xs={1.5}>
            <FormLabel target="company-address" className="company-details__address-lookup__label" content="CONFIG_COMPANY_DETAILS_ADDRESS" noMargin />
          </Col>
          <Col xs={10.5}>
            <AddressLookup
              title=""
              shouldTranslateTitle={false}
              showSummaryPanel
              selectedAddress={{
                line1: billingAddressLine1,
                line2: billingAddressLine2,
                city: billingAddressCity,
                zipCode: billingAddressPostCode,
                countryIso2: "",
                countryIso3: billingAddressCountryISO3,
                countryName: billingAddressCountryISO3,
                state: billingAddressState
              }}
              onSelectedAddress={(selectedAddress: CraftyClicksSelectedAddress) => {
                dispatch(setCompanyConfigurationAddressForm(selectedAddress));
              }}
              hasError={errors.some((err: string) => err === "Company_Configuration_Invalid_Address")}
            />
          </Col>
        </Row>

        <FormGroup className="company-subdomain__container">
          <Row align="center">
            <Col xs={1.5}>
              <FormLabel target="company-subdomain" content="CONFIG_COMPANY_DETAILS_SUBDOMAIN" noMargin />
            </Col>
            <Col xs={9.5}>
              <Input
                value={companyDomain}
                placeholder={counterpart("CONFIG_COMPANY_DETAILS_SUBDOMAIN")}
                required
                id="company-subdomain"
                disabled
                showDisabledIcon={false}
              />
              <Text
                className="company-details__subdomain-change"
                content="CONFIG_COMPANY_CHANGE_SUBDOMAIN"
                component="a"
                onClick={(evt) => { 
                  evt.preventDefault(); 
                  setShowChangeDomainModal(true);} }
                noMargin
              />
            </Col>
            <Col xs={1}>
              <Text className="company-details__subdomain-prefix" shouldTranslate={false} content=".billsby.com" noMargin />
            </Col>
          </Row>
        </FormGroup>
        <FormGroup>
          <Row align="center">
            <Col xs={1.5}>
              <FormLabel target="company-industry" content="CONFIG_COMPANY_DETAILS_INDUSTRY" noMargin />
            </Col>
            <Col xs={10.5}>
              <Dropdown
                //menuIsOpen={true}
                value={selectedIndustry}
                id="company-industry"
                placeholder={counterpart("CONFIG_COMPANY_DETAILS_INDUSTRY")}
                onChange={(provider: { category: string, subCategory: string }) => setSelectedIndustry(provider.category, provider.subCategory)}
                options={INDUSTRY_OPTIONS}
                multioptions={INDUSTRY_MULTIOPTIONS}
                isMultiLayer
              />
            </Col>
          </Row>
        </FormGroup>
        <FormGroup>
          <Row align="center">
            <Col xs={1.5}>
              <FormLabel target="company-time-zone" content="CONFIG_COMPANY_DETAILS_TIME_ZONE" noMargin />
            </Col>
            <Col xs={10.5}>
              <Dropdown
                id="company-time-zone"
                placeholder={counterpart("CONFIG_COMPANY_DETAILS_TIME_ZONE")}
                value={getTimeZoneDropdownValue(timeZone)}
                onChange={(selectedItem: any) => dispatch(setCompanyDetailsField("timeZone", selectedItem.value))}
                options={timeZones}
              ></Dropdown>
            </Col>
          </Row>
        </FormGroup>
        <Row align="center">
          <Col xs={9}>
            <Button
              disabled={isLoadingData}
              id="company-update-details"
              type="submit"
              isLoading={isUpdatingDetailsRequest}
              isFullWidth
              buttonType="general"
              title="CONFIG_COMPANY_DETAILS_UPDATE_DETAILS"
            />
          </Col>
          <Col xs={3}>
            <Button
              disabled={isLoadingData}
              id="company-cancel-details-update"
              isFullWidth
              buttonType="error"
              title="CONFIG_COMPANY_DETAILS_CANCEL"
              onClick={() => dispatch(fetchCompanyConfigurationDetails(currentCompanyDomain))}
            />
          </Col>
        </Row>
        <Modal isOpen={showChangeDomainModal} onRequestClose={() => setShowChangeDomainModal(false)} title="CHANGE_COMPANY_SUBDOMAIN_PANEL1_TITLE">
          <ChangeCompanyDomain />
        </Modal>
      </form>
    );
  };


  const renderAlertsNotificationsForm = () => {
    const { alertEmail, isUpdatingAlertEmailRequest } = companyAlertsAndNotifications;
    return (
      <form id="company-alert-form" onSubmit={onUpdateCompanyAlertsNotifications}>
      <FormGroup>
        <Row align="center">
          <Col xs={1.5}>
            <FormLabel target="company-alert-email" content="CONFIG_COMPANY_DETAILS_ALERT_EMAIL" noMargin />
          </Col>
          <Col xs={10.5}>
            <Input
              onFocus={() => {
              setHelpMenuTitle("CONFIG_COMPANY_DETAILS_ALERT_EMAIL");
              setHelpMenuContent(["CONFIG_COMPANY_DETAILS_ALERT_EMAIL_HELP_CONTENT1"])
            }}
              onBlur={resetHelpMenu}
              onChange={(evt: any) => {
                dispatch(setCompanyDetailsAlertNotifField("alertEmail", evt.target.value))
              }}
              value={alertEmail}
              isError={alertEmail ? !isValidEmailFormat(alertEmail, false) : false}
              required
              id="company-alert-email"
              placeholder=""
              disabled={false} />
          </Col>
        </Row>
      </FormGroup>
      {/* TODO: Add backend support for billing email */}
      {/* <FormGroup>
        <Row align="center">
          <Col xs={1.5}>
            <FormLabel target="company-alert-billing" content="CONFIG_COMPANY_DETAILS_BILLING_EMAIL" />
          </Col>
          <Col xs={10.5}>
            <Input required id="company-alert-billing" disabled={isUpdatingAlerts} />
          </Col>
        </Row>
      </FormGroup> */}
      <Row align="center">
        <Col xs={9}>
          <Button
            disabled={isLoadingData || !isValidEmailFormat(alertEmail, false)}
            id="company-update-alerts"
            type="submit"
            isLoading={isUpdatingAlertEmailRequest}
            isFullWidth
            buttonType="general"
            title="CONFIG_COMPANY_DETAILS_UPDATE_ALERTS_NOTIF"
          />
        </Col>
        <Col xs={3}>
          <Button disabled={isLoadingData} id="company-cancel-alerts-update" isFullWidth buttonType="error" title="CONFIG_COMPANY_DETAILS_CANCEL" onClick={() => {
            currentCompanyId && dispatch(fetchCompanyAlertEmail(currentCompanyId));
          }} />
        </Col>
      </Row>
    </form>
    )
  }

  return (
    <div className="company-details">
      <NavigationBar pageTitle="" previousPageTitle="CONFIGURATION_PAGE_TITLE" previousPageCb={() => history.push("/configuration")} />
      <PageWrapper>
        <Panel title="CONFIG_COMPANY_DETAILS_MENU_ITEM_TITLE" className="company-details__heading">
          <Text content="CONFIG_COMPANY_DETAILS_MENU_ITEM_CONTENT" noMargin />
        </Panel>

        <Panel title="CONFIG_COMPANY_DETAILS_PANEL1_TITLE">
          <Text content="CONFIG_COMPANY_DETAILS_PANEL1_CONTENT" />
          {renderCompanyDetailsForm()}
        </Panel>

        <StepCollapsablePanel
          isCollapsed={false}
          title="CONFIG_COMPANY_DETAILS_PANEL2_TITLE"
          helpMenuPosition="right"
          helpMenuTitle={helpMenuTitle}
          helpMenuContent={
            <>
              {helpMenuContent.map((helpContent, id) => (
                <Text key={id} content={helpContent} />
              ))}
            </>
          }
        >
          <Text content="CONFIG_COMPANY_DETAILS_PANEL2_CONTENT" />
          {renderAlertsNotificationsForm()}
        </StepCollapsablePanel>
      </PageWrapper>
      <ErrorNotification
        closingTime={8000}
        title={errorMessage ? errorMessage : "CONFIG_COMPANY_DETAILS_PANEL1_UPDATE_ERROR"}
        shouldTranslateTitle={!errorMessage}
        showAlert={isUpdatingDetailsFailure}
      />
      <ErrorNotification
        closingTime={8000}
        title={companyAlertsAndNotifications.errorMessage ? companyAlertsAndNotifications.errorMessage : "CONFIG_COMPANY_DETAILS_PANEL1_UPDATE_ERROR"}
        shouldTranslateTitle={!companyAlertsAndNotifications.errorMessage}
        showAlert={companyAlertsAndNotifications.isUpdatingAlertEmailFailure}
      />
    </div>
  )
}

export default CompanyDetails;
