import API from "../utils/API";
import { makeActionCreator, makeGenericActionCreatorTypeSafe } from "../utils/reduxUtils";
import { PAGINATION_PAGE_SIZE } from "../utils/constants";
import { ICustomersReducer } from "../reducers/customersReducer";
import { CardStatusType, CustomerStatusType } from "../models/Customers";

export const FETCH_CUSTOMERS_REQUEST = "FETCH_CUSTOMERS_REQUEST";
export const FETCH_CUSTOMERS_SUCCESS= "FETCH_CUSTOMERS_SUCCESS";
export const FETCH_CUSTOMERS_FAILURE = "FETCH_CUSTOMERS_FAILURE";

export const SET_CUSTOMERS_FIELD = "SET_CUSTOMERS_FIELD";
export const setCustomersField = makeGenericActionCreatorTypeSafe<ICustomersReducer>(SET_CUSTOMERS_FIELD);

export const RESET_CUSTOMERS = "RESET_CUSTOMERS";
export const resetCustomers = makeActionCreator(RESET_CUSTOMERS, "payload");

export const fetchCustomers = function (companyDomain: string, pageNr: number, pageSize: number = PAGINATION_PAGE_SIZE, search: string = "", 
  customerStatus?: CustomerStatusType, isCreatedOnSortDescendingOrder: boolean = true, cardStatus?: CardStatusType) {
  return {
    types: [FETCH_CUSTOMERS_REQUEST, FETCH_CUSTOMERS_SUCCESS, FETCH_CUSTOMERS_FAILURE],
    callAPI: () => API.fetchCustomers(companyDomain, pageNr, pageSize, search, customerStatus, isCreatedOnSortDescendingOrder, cardStatus)
  }
}
