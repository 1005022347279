import React, { useRef } from "react";
import ReactModal from "react-modal";
import "./LoadingModal.scss";
import Text from "../../ui/text/Text";
import { findDOMNode } from "react-dom";
import defaultLogo from "../../../images/billsby-logo.svg";
import Spinner from "../../ui/spinner/Spinner";

interface ILoadingModal {
  isOpen: boolean,
  title: string,
  shouldTranslateTitle?: boolean,
  titleTranslateWith?: object,
  subTitle: string,
  shouldTranslateSubTitle?: boolean,
  subTitleTranslateWith?: object,
  logo?: string,
  logoBackgroundColor?: string,
  onAfterOpen?: () => any,
  [key: string]: any
}

/**
 * wrapper functiont that shows the modal body only after the transition has completed, to avoid showing the content of the modal changing during the transition
 */
let _onAfterOpen = (modalRef: any, onAfterOpen: Function) => {
  setTimeout(() => {
    const modal = (findDOMNode(modalRef.current) as Element);
    if (modal) {
      const modalBody = modal.querySelector(".loading_modal__body") as Element;
      modalBody.className += " loading_modal__body--visible";
    }
  }, 100)
  onAfterOpen();
}

const LoadingModal: React.FC<ILoadingModal> = ({ title, shouldTranslateTitle = true, titleTranslateWith = {}, subTitle, shouldTranslateSubTitle = true, subTitleTranslateWith = {}, logo = "", logoBackgroundColor = "", onAfterOpen = () => { }, ...props }) => {
  const modalRef: any = useRef(null);
  ReactModal.setAppElement("body");

  const defaultLogoBackground = "#1E2432";
  return (
    <ReactModal
      ref={modalRef}
      onAfterOpen={() => _onAfterOpen(modalRef, onAfterOpen)}
      {...props}
      className="loading_modal"
    >
      <div className="loading_modal__header" style={{backgroundColor: !!logoBackgroundColor ? logoBackgroundColor : defaultLogoBackground}}>
        <img src={!!logo ? logo : defaultLogo} alt="Billsby Logo" className="loading_modal__header__logo" />
      </div>
      <div className="loading_modal__body">
        {
          shouldTranslateTitle
            ? <Text translateWith={titleTranslateWith} content={title} noMargin component="div" className="loading_modal__body__title" />
            : <div className="loading_modal__body__title">{title}</div>
        }
        {
          shouldTranslateSubTitle
            ? <Text translateWith={subTitleTranslateWith} content={subTitle} noMargin component="div" className="loading_modal__body__sub-title" />
            : <div className="loading_modal__body__sub-title">{subTitle}</div>
        }
        <div className="loading_modal__body__spinner">
          <Spinner />
        </div>
      </div>
    </ReactModal>
  )
}

export default LoadingModal;