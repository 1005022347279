import React from "react";
import "./FeatureTags.scss";
import counterpart from "counterpart";

interface IFeatureTags {
  tags: Array<string>,
  hasDelete?: boolean,
  handleDelete?: (tag: string) => void
}

const FeatureTags: React.FC<IFeatureTags> = React.memo(({ tags = [], hasDelete = false, handleDelete = (tag: string) => { } }) => {
  return (
    <div className="feature-tags">
      <div className="feature-tags__items">
        {tags.map((tag, index) => (
          <div key={`${index}-${tag}`} className={"feature-tags__items-tag"}>
            <span className="feature-tags__items-tag-name">{tag}</span>
            {hasDelete && <span className="feature-tags__items-tag-delete" title={counterpart("FEATURE_TAG_TOOLTIP_TEXT")} onClick={hasDelete ? () => handleDelete(tag) : () => { }}></span>}
          </div>
        ))}
      </div>
    </div>
  )
})

export default FeatureTags;
