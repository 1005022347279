/* eslint-disable */
// source: report.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = Function('return this')();

var google_protobuf_timestamp_pb = require('google-protobuf/google/protobuf/timestamp_pb.js');
goog.object.extend(proto, google_protobuf_timestamp_pb);
var google_protobuf_wrappers_pb = require('google-protobuf/google/protobuf/wrappers_pb.js');
goog.object.extend(proto, google_protobuf_wrappers_pb);
goog.exportSymbol('proto.billing.private.report.ActivitySummaryMonth', null, global);
goog.exportSymbol('proto.billing.private.report.ExitReasonGraphMonthData', null, global);
goog.exportSymbol('proto.billing.private.report.ExitReasonGraphPerTypeData', null, global);
goog.exportSymbol('proto.billing.private.report.ExitReasonListItem', null, global);
goog.exportSymbol('proto.billing.private.report.ExitReasonsGraphDataRequest', null, global);
goog.exportSymbol('proto.billing.private.report.ExitReasonsGraphDataResponse', null, global);
goog.exportSymbol('proto.billing.private.report.GetActivitySummaryRequest', null, global);
goog.exportSymbol('proto.billing.private.report.GetActivitySummaryResponse', null, global);
goog.exportSymbol('proto.billing.private.report.GetExitReasonsRequest', null, global);
goog.exportSymbol('proto.billing.private.report.GetExitReasonsResponse', null, global);
goog.exportSymbol('proto.billing.private.report.GetTaxSummaryRequest', null, global);
goog.exportSymbol('proto.billing.private.report.GetTaxSummaryResponse', null, global);
goog.exportSymbol('proto.billing.private.report.JurisdictionType', null, global);
goog.exportSymbol('proto.billing.private.report.TaxSummary', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.billing.private.report.GetActivitySummaryRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.billing.private.report.GetActivitySummaryRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.billing.private.report.GetActivitySummaryRequest.displayName = 'proto.billing.private.report.GetActivitySummaryRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.billing.private.report.GetActivitySummaryResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.billing.private.report.GetActivitySummaryResponse.repeatedFields_, null);
};
goog.inherits(proto.billing.private.report.GetActivitySummaryResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.billing.private.report.GetActivitySummaryResponse.displayName = 'proto.billing.private.report.GetActivitySummaryResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.billing.private.report.ActivitySummaryMonth = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.billing.private.report.ActivitySummaryMonth, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.billing.private.report.ActivitySummaryMonth.displayName = 'proto.billing.private.report.ActivitySummaryMonth';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.billing.private.report.GetTaxSummaryRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.billing.private.report.GetTaxSummaryRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.billing.private.report.GetTaxSummaryRequest.displayName = 'proto.billing.private.report.GetTaxSummaryRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.billing.private.report.GetTaxSummaryResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.billing.private.report.GetTaxSummaryResponse.repeatedFields_, null);
};
goog.inherits(proto.billing.private.report.GetTaxSummaryResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.billing.private.report.GetTaxSummaryResponse.displayName = 'proto.billing.private.report.GetTaxSummaryResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.billing.private.report.TaxSummary = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.billing.private.report.TaxSummary, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.billing.private.report.TaxSummary.displayName = 'proto.billing.private.report.TaxSummary';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.billing.private.report.GetExitReasonsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.billing.private.report.GetExitReasonsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.billing.private.report.GetExitReasonsRequest.displayName = 'proto.billing.private.report.GetExitReasonsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.billing.private.report.GetExitReasonsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.billing.private.report.GetExitReasonsResponse.repeatedFields_, null);
};
goog.inherits(proto.billing.private.report.GetExitReasonsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.billing.private.report.GetExitReasonsResponse.displayName = 'proto.billing.private.report.GetExitReasonsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.billing.private.report.ExitReasonListItem = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.billing.private.report.ExitReasonListItem, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.billing.private.report.ExitReasonListItem.displayName = 'proto.billing.private.report.ExitReasonListItem';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.billing.private.report.ExitReasonsGraphDataRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.billing.private.report.ExitReasonsGraphDataRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.billing.private.report.ExitReasonsGraphDataRequest.displayName = 'proto.billing.private.report.ExitReasonsGraphDataRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.billing.private.report.ExitReasonsGraphDataResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.billing.private.report.ExitReasonsGraphDataResponse.repeatedFields_, null);
};
goog.inherits(proto.billing.private.report.ExitReasonsGraphDataResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.billing.private.report.ExitReasonsGraphDataResponse.displayName = 'proto.billing.private.report.ExitReasonsGraphDataResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.billing.private.report.ExitReasonGraphMonthData = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.billing.private.report.ExitReasonGraphMonthData.repeatedFields_, null);
};
goog.inherits(proto.billing.private.report.ExitReasonGraphMonthData, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.billing.private.report.ExitReasonGraphMonthData.displayName = 'proto.billing.private.report.ExitReasonGraphMonthData';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.billing.private.report.ExitReasonGraphPerTypeData = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.billing.private.report.ExitReasonGraphPerTypeData, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.billing.private.report.ExitReasonGraphPerTypeData.displayName = 'proto.billing.private.report.ExitReasonGraphPerTypeData';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.billing.private.report.GetActivitySummaryRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.billing.private.report.GetActivitySummaryRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.billing.private.report.GetActivitySummaryRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing.private.report.GetActivitySummaryRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    companyId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    startDate: (f = msg.getStartDate()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    endDate: (f = msg.getEndDate()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    showAfterTax: jspb.Message.getBooleanFieldWithDefault(msg, 4, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.billing.private.report.GetActivitySummaryRequest}
 */
proto.billing.private.report.GetActivitySummaryRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.billing.private.report.GetActivitySummaryRequest;
  return proto.billing.private.report.GetActivitySummaryRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.billing.private.report.GetActivitySummaryRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.billing.private.report.GetActivitySummaryRequest}
 */
proto.billing.private.report.GetActivitySummaryRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setCompanyId(value);
      break;
    case 2:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setStartDate(value);
      break;
    case 3:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setEndDate(value);
      break;
    case 4:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setShowAfterTax(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.billing.private.report.GetActivitySummaryRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.billing.private.report.GetActivitySummaryRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.billing.private.report.GetActivitySummaryRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing.private.report.GetActivitySummaryRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCompanyId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getStartDate();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getEndDate();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getShowAfterTax();
  if (f) {
    writer.writeBool(
      4,
      f
    );
  }
};


/**
 * optional int32 company_id = 1;
 * @return {number}
 */
proto.billing.private.report.GetActivitySummaryRequest.prototype.getCompanyId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.billing.private.report.GetActivitySummaryRequest} returns this
 */
proto.billing.private.report.GetActivitySummaryRequest.prototype.setCompanyId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional google.protobuf.Timestamp start_date = 2;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.billing.private.report.GetActivitySummaryRequest.prototype.getStartDate = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 2));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.billing.private.report.GetActivitySummaryRequest} returns this
*/
proto.billing.private.report.GetActivitySummaryRequest.prototype.setStartDate = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.billing.private.report.GetActivitySummaryRequest} returns this
 */
proto.billing.private.report.GetActivitySummaryRequest.prototype.clearStartDate = function() {
  return this.setStartDate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.billing.private.report.GetActivitySummaryRequest.prototype.hasStartDate = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional google.protobuf.Timestamp end_date = 3;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.billing.private.report.GetActivitySummaryRequest.prototype.getEndDate = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 3));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.billing.private.report.GetActivitySummaryRequest} returns this
*/
proto.billing.private.report.GetActivitySummaryRequest.prototype.setEndDate = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.billing.private.report.GetActivitySummaryRequest} returns this
 */
proto.billing.private.report.GetActivitySummaryRequest.prototype.clearEndDate = function() {
  return this.setEndDate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.billing.private.report.GetActivitySummaryRequest.prototype.hasEndDate = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional bool show_after_tax = 4;
 * @return {boolean}
 */
proto.billing.private.report.GetActivitySummaryRequest.prototype.getShowAfterTax = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 4, false));
};


/**
 * @param {boolean} value
 * @return {!proto.billing.private.report.GetActivitySummaryRequest} returns this
 */
proto.billing.private.report.GetActivitySummaryRequest.prototype.setShowAfterTax = function(value) {
  return jspb.Message.setProto3BooleanField(this, 4, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.billing.private.report.GetActivitySummaryResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.billing.private.report.GetActivitySummaryResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.billing.private.report.GetActivitySummaryResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.billing.private.report.GetActivitySummaryResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing.private.report.GetActivitySummaryResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    resultsList: jspb.Message.toObjectList(msg.getResultsList(),
    proto.billing.private.report.ActivitySummaryMonth.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.billing.private.report.GetActivitySummaryResponse}
 */
proto.billing.private.report.GetActivitySummaryResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.billing.private.report.GetActivitySummaryResponse;
  return proto.billing.private.report.GetActivitySummaryResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.billing.private.report.GetActivitySummaryResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.billing.private.report.GetActivitySummaryResponse}
 */
proto.billing.private.report.GetActivitySummaryResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.billing.private.report.ActivitySummaryMonth;
      reader.readMessage(value,proto.billing.private.report.ActivitySummaryMonth.deserializeBinaryFromReader);
      msg.addResults(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.billing.private.report.GetActivitySummaryResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.billing.private.report.GetActivitySummaryResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.billing.private.report.GetActivitySummaryResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing.private.report.GetActivitySummaryResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getResultsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.billing.private.report.ActivitySummaryMonth.serializeBinaryToWriter
    );
  }
};


/**
 * repeated ActivitySummaryMonth results = 1;
 * @return {!Array<!proto.billing.private.report.ActivitySummaryMonth>}
 */
proto.billing.private.report.GetActivitySummaryResponse.prototype.getResultsList = function() {
  return /** @type{!Array<!proto.billing.private.report.ActivitySummaryMonth>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.billing.private.report.ActivitySummaryMonth, 1));
};


/**
 * @param {!Array<!proto.billing.private.report.ActivitySummaryMonth>} value
 * @return {!proto.billing.private.report.GetActivitySummaryResponse} returns this
*/
proto.billing.private.report.GetActivitySummaryResponse.prototype.setResultsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.billing.private.report.ActivitySummaryMonth=} opt_value
 * @param {number=} opt_index
 * @return {!proto.billing.private.report.ActivitySummaryMonth}
 */
proto.billing.private.report.GetActivitySummaryResponse.prototype.addResults = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.billing.private.report.ActivitySummaryMonth, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.billing.private.report.GetActivitySummaryResponse} returns this
 */
proto.billing.private.report.GetActivitySummaryResponse.prototype.clearResultsList = function() {
  return this.setResultsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.billing.private.report.ActivitySummaryMonth.prototype.toObject = function(opt_includeInstance) {
  return proto.billing.private.report.ActivitySummaryMonth.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.billing.private.report.ActivitySummaryMonth} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing.private.report.ActivitySummaryMonth.toObject = function(includeInstance, msg) {
  var f, obj = {
    date: (f = msg.getDate()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    sales: jspb.Message.getFieldWithDefault(msg, 2, 0),
    salesFormatted: jspb.Message.getFieldWithDefault(msg, 3, ""),
    salesRev: jspb.Message.getFieldWithDefault(msg, 4, 0),
    salesRevFormatted: jspb.Message.getFieldWithDefault(msg, 5, ""),
    discount: jspb.Message.getFieldWithDefault(msg, 6, 0),
    discountFormatted: jspb.Message.getFieldWithDefault(msg, 7, ""),
    discountRev: jspb.Message.getFieldWithDefault(msg, 8, 0),
    discountRevFormatted: jspb.Message.getFieldWithDefault(msg, 9, ""),
    tax: jspb.Message.getFieldWithDefault(msg, 10, 0),
    taxFormatted: jspb.Message.getFieldWithDefault(msg, 11, ""),
    taxRev: jspb.Message.getFieldWithDefault(msg, 12, 0),
    taxRevFormatted: jspb.Message.getFieldWithDefault(msg, 13, ""),
    writtenOff: jspb.Message.getFieldWithDefault(msg, 14, 0),
    writtenOffFormatted: jspb.Message.getFieldWithDefault(msg, 15, ""),
    writtenOffRev: jspb.Message.getFieldWithDefault(msg, 16, 0),
    writtenOffRevFormatted: jspb.Message.getFieldWithDefault(msg, 17, ""),
    payment: jspb.Message.getFieldWithDefault(msg, 18, 0),
    paymentFormatted: jspb.Message.getFieldWithDefault(msg, 19, ""),
    refunds: jspb.Message.getFieldWithDefault(msg, 20, 0),
    refundsFormatted: jspb.Message.getFieldWithDefault(msg, 21, ""),
    revenue: jspb.Message.getFieldWithDefault(msg, 22, 0),
    revenueFormatted: jspb.Message.getFieldWithDefault(msg, 23, ""),
    recognisedRevenue: jspb.Message.getFieldWithDefault(msg, 24, 0),
    recognisedRevenueFormatted: jspb.Message.getFieldWithDefault(msg, 25, ""),
    deferredRevenue: jspb.Message.getFieldWithDefault(msg, 26, 0),
    deferredRevenueFormatted: jspb.Message.getFieldWithDefault(msg, 27, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.billing.private.report.ActivitySummaryMonth}
 */
proto.billing.private.report.ActivitySummaryMonth.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.billing.private.report.ActivitySummaryMonth;
  return proto.billing.private.report.ActivitySummaryMonth.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.billing.private.report.ActivitySummaryMonth} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.billing.private.report.ActivitySummaryMonth}
 */
proto.billing.private.report.ActivitySummaryMonth.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setDate(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setSales(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setSalesFormatted(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setSalesRev(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setSalesRevFormatted(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setDiscount(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setDiscountFormatted(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setDiscountRev(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setDiscountRevFormatted(value);
      break;
    case 10:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setTax(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.setTaxFormatted(value);
      break;
    case 12:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setTaxRev(value);
      break;
    case 13:
      var value = /** @type {string} */ (reader.readString());
      msg.setTaxRevFormatted(value);
      break;
    case 14:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setWrittenOff(value);
      break;
    case 15:
      var value = /** @type {string} */ (reader.readString());
      msg.setWrittenOffFormatted(value);
      break;
    case 16:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setWrittenOffRev(value);
      break;
    case 17:
      var value = /** @type {string} */ (reader.readString());
      msg.setWrittenOffRevFormatted(value);
      break;
    case 18:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPayment(value);
      break;
    case 19:
      var value = /** @type {string} */ (reader.readString());
      msg.setPaymentFormatted(value);
      break;
    case 20:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setRefunds(value);
      break;
    case 21:
      var value = /** @type {string} */ (reader.readString());
      msg.setRefundsFormatted(value);
      break;
    case 22:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setRevenue(value);
      break;
    case 23:
      var value = /** @type {string} */ (reader.readString());
      msg.setRevenueFormatted(value);
      break;
    case 24:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setRecognisedRevenue(value);
      break;
    case 25:
      var value = /** @type {string} */ (reader.readString());
      msg.setRecognisedRevenueFormatted(value);
      break;
    case 26:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setDeferredRevenue(value);
      break;
    case 27:
      var value = /** @type {string} */ (reader.readString());
      msg.setDeferredRevenueFormatted(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.billing.private.report.ActivitySummaryMonth.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.billing.private.report.ActivitySummaryMonth.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.billing.private.report.ActivitySummaryMonth} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing.private.report.ActivitySummaryMonth.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDate();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getSales();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getSalesFormatted();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getSalesRev();
  if (f !== 0) {
    writer.writeInt32(
      4,
      f
    );
  }
  f = message.getSalesRevFormatted();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getDiscount();
  if (f !== 0) {
    writer.writeInt32(
      6,
      f
    );
  }
  f = message.getDiscountFormatted();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getDiscountRev();
  if (f !== 0) {
    writer.writeInt32(
      8,
      f
    );
  }
  f = message.getDiscountRevFormatted();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getTax();
  if (f !== 0) {
    writer.writeInt32(
      10,
      f
    );
  }
  f = message.getTaxFormatted();
  if (f.length > 0) {
    writer.writeString(
      11,
      f
    );
  }
  f = message.getTaxRev();
  if (f !== 0) {
    writer.writeInt32(
      12,
      f
    );
  }
  f = message.getTaxRevFormatted();
  if (f.length > 0) {
    writer.writeString(
      13,
      f
    );
  }
  f = message.getWrittenOff();
  if (f !== 0) {
    writer.writeInt32(
      14,
      f
    );
  }
  f = message.getWrittenOffFormatted();
  if (f.length > 0) {
    writer.writeString(
      15,
      f
    );
  }
  f = message.getWrittenOffRev();
  if (f !== 0) {
    writer.writeInt32(
      16,
      f
    );
  }
  f = message.getWrittenOffRevFormatted();
  if (f.length > 0) {
    writer.writeString(
      17,
      f
    );
  }
  f = message.getPayment();
  if (f !== 0) {
    writer.writeInt32(
      18,
      f
    );
  }
  f = message.getPaymentFormatted();
  if (f.length > 0) {
    writer.writeString(
      19,
      f
    );
  }
  f = message.getRefunds();
  if (f !== 0) {
    writer.writeInt32(
      20,
      f
    );
  }
  f = message.getRefundsFormatted();
  if (f.length > 0) {
    writer.writeString(
      21,
      f
    );
  }
  f = message.getRevenue();
  if (f !== 0) {
    writer.writeInt32(
      22,
      f
    );
  }
  f = message.getRevenueFormatted();
  if (f.length > 0) {
    writer.writeString(
      23,
      f
    );
  }
  f = message.getRecognisedRevenue();
  if (f !== 0) {
    writer.writeInt32(
      24,
      f
    );
  }
  f = message.getRecognisedRevenueFormatted();
  if (f.length > 0) {
    writer.writeString(
      25,
      f
    );
  }
  f = message.getDeferredRevenue();
  if (f !== 0) {
    writer.writeInt32(
      26,
      f
    );
  }
  f = message.getDeferredRevenueFormatted();
  if (f.length > 0) {
    writer.writeString(
      27,
      f
    );
  }
};


/**
 * optional google.protobuf.Timestamp date = 1;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.billing.private.report.ActivitySummaryMonth.prototype.getDate = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 1));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.billing.private.report.ActivitySummaryMonth} returns this
*/
proto.billing.private.report.ActivitySummaryMonth.prototype.setDate = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.billing.private.report.ActivitySummaryMonth} returns this
 */
proto.billing.private.report.ActivitySummaryMonth.prototype.clearDate = function() {
  return this.setDate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.billing.private.report.ActivitySummaryMonth.prototype.hasDate = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional int32 sales = 2;
 * @return {number}
 */
proto.billing.private.report.ActivitySummaryMonth.prototype.getSales = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.billing.private.report.ActivitySummaryMonth} returns this
 */
proto.billing.private.report.ActivitySummaryMonth.prototype.setSales = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional string sales_formatted = 3;
 * @return {string}
 */
proto.billing.private.report.ActivitySummaryMonth.prototype.getSalesFormatted = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.billing.private.report.ActivitySummaryMonth} returns this
 */
proto.billing.private.report.ActivitySummaryMonth.prototype.setSalesFormatted = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional int32 sales_rev = 4;
 * @return {number}
 */
proto.billing.private.report.ActivitySummaryMonth.prototype.getSalesRev = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.billing.private.report.ActivitySummaryMonth} returns this
 */
proto.billing.private.report.ActivitySummaryMonth.prototype.setSalesRev = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional string sales_rev_formatted = 5;
 * @return {string}
 */
proto.billing.private.report.ActivitySummaryMonth.prototype.getSalesRevFormatted = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.billing.private.report.ActivitySummaryMonth} returns this
 */
proto.billing.private.report.ActivitySummaryMonth.prototype.setSalesRevFormatted = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional int32 discount = 6;
 * @return {number}
 */
proto.billing.private.report.ActivitySummaryMonth.prototype.getDiscount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.billing.private.report.ActivitySummaryMonth} returns this
 */
proto.billing.private.report.ActivitySummaryMonth.prototype.setDiscount = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * optional string discount_formatted = 7;
 * @return {string}
 */
proto.billing.private.report.ActivitySummaryMonth.prototype.getDiscountFormatted = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.billing.private.report.ActivitySummaryMonth} returns this
 */
proto.billing.private.report.ActivitySummaryMonth.prototype.setDiscountFormatted = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional int32 discount_rev = 8;
 * @return {number}
 */
proto.billing.private.report.ActivitySummaryMonth.prototype.getDiscountRev = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {number} value
 * @return {!proto.billing.private.report.ActivitySummaryMonth} returns this
 */
proto.billing.private.report.ActivitySummaryMonth.prototype.setDiscountRev = function(value) {
  return jspb.Message.setProto3IntField(this, 8, value);
};


/**
 * optional string discount_rev_formatted = 9;
 * @return {string}
 */
proto.billing.private.report.ActivitySummaryMonth.prototype.getDiscountRevFormatted = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.billing.private.report.ActivitySummaryMonth} returns this
 */
proto.billing.private.report.ActivitySummaryMonth.prototype.setDiscountRevFormatted = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional int32 tax = 10;
 * @return {number}
 */
proto.billing.private.report.ActivitySummaryMonth.prototype.getTax = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 10, 0));
};


/**
 * @param {number} value
 * @return {!proto.billing.private.report.ActivitySummaryMonth} returns this
 */
proto.billing.private.report.ActivitySummaryMonth.prototype.setTax = function(value) {
  return jspb.Message.setProto3IntField(this, 10, value);
};


/**
 * optional string tax_formatted = 11;
 * @return {string}
 */
proto.billing.private.report.ActivitySummaryMonth.prototype.getTaxFormatted = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};


/**
 * @param {string} value
 * @return {!proto.billing.private.report.ActivitySummaryMonth} returns this
 */
proto.billing.private.report.ActivitySummaryMonth.prototype.setTaxFormatted = function(value) {
  return jspb.Message.setProto3StringField(this, 11, value);
};


/**
 * optional int32 tax_rev = 12;
 * @return {number}
 */
proto.billing.private.report.ActivitySummaryMonth.prototype.getTaxRev = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 12, 0));
};


/**
 * @param {number} value
 * @return {!proto.billing.private.report.ActivitySummaryMonth} returns this
 */
proto.billing.private.report.ActivitySummaryMonth.prototype.setTaxRev = function(value) {
  return jspb.Message.setProto3IntField(this, 12, value);
};


/**
 * optional string tax_rev_formatted = 13;
 * @return {string}
 */
proto.billing.private.report.ActivitySummaryMonth.prototype.getTaxRevFormatted = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 13, ""));
};


/**
 * @param {string} value
 * @return {!proto.billing.private.report.ActivitySummaryMonth} returns this
 */
proto.billing.private.report.ActivitySummaryMonth.prototype.setTaxRevFormatted = function(value) {
  return jspb.Message.setProto3StringField(this, 13, value);
};


/**
 * optional int32 written_off = 14;
 * @return {number}
 */
proto.billing.private.report.ActivitySummaryMonth.prototype.getWrittenOff = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 14, 0));
};


/**
 * @param {number} value
 * @return {!proto.billing.private.report.ActivitySummaryMonth} returns this
 */
proto.billing.private.report.ActivitySummaryMonth.prototype.setWrittenOff = function(value) {
  return jspb.Message.setProto3IntField(this, 14, value);
};


/**
 * optional string written_off_formatted = 15;
 * @return {string}
 */
proto.billing.private.report.ActivitySummaryMonth.prototype.getWrittenOffFormatted = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 15, ""));
};


/**
 * @param {string} value
 * @return {!proto.billing.private.report.ActivitySummaryMonth} returns this
 */
proto.billing.private.report.ActivitySummaryMonth.prototype.setWrittenOffFormatted = function(value) {
  return jspb.Message.setProto3StringField(this, 15, value);
};


/**
 * optional int32 written_off_rev = 16;
 * @return {number}
 */
proto.billing.private.report.ActivitySummaryMonth.prototype.getWrittenOffRev = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 16, 0));
};


/**
 * @param {number} value
 * @return {!proto.billing.private.report.ActivitySummaryMonth} returns this
 */
proto.billing.private.report.ActivitySummaryMonth.prototype.setWrittenOffRev = function(value) {
  return jspb.Message.setProto3IntField(this, 16, value);
};


/**
 * optional string written_off_rev_formatted = 17;
 * @return {string}
 */
proto.billing.private.report.ActivitySummaryMonth.prototype.getWrittenOffRevFormatted = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 17, ""));
};


/**
 * @param {string} value
 * @return {!proto.billing.private.report.ActivitySummaryMonth} returns this
 */
proto.billing.private.report.ActivitySummaryMonth.prototype.setWrittenOffRevFormatted = function(value) {
  return jspb.Message.setProto3StringField(this, 17, value);
};


/**
 * optional int32 payment = 18;
 * @return {number}
 */
proto.billing.private.report.ActivitySummaryMonth.prototype.getPayment = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 18, 0));
};


/**
 * @param {number} value
 * @return {!proto.billing.private.report.ActivitySummaryMonth} returns this
 */
proto.billing.private.report.ActivitySummaryMonth.prototype.setPayment = function(value) {
  return jspb.Message.setProto3IntField(this, 18, value);
};


/**
 * optional string payment_formatted = 19;
 * @return {string}
 */
proto.billing.private.report.ActivitySummaryMonth.prototype.getPaymentFormatted = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 19, ""));
};


/**
 * @param {string} value
 * @return {!proto.billing.private.report.ActivitySummaryMonth} returns this
 */
proto.billing.private.report.ActivitySummaryMonth.prototype.setPaymentFormatted = function(value) {
  return jspb.Message.setProto3StringField(this, 19, value);
};


/**
 * optional int32 refunds = 20;
 * @return {number}
 */
proto.billing.private.report.ActivitySummaryMonth.prototype.getRefunds = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 20, 0));
};


/**
 * @param {number} value
 * @return {!proto.billing.private.report.ActivitySummaryMonth} returns this
 */
proto.billing.private.report.ActivitySummaryMonth.prototype.setRefunds = function(value) {
  return jspb.Message.setProto3IntField(this, 20, value);
};


/**
 * optional string refunds_formatted = 21;
 * @return {string}
 */
proto.billing.private.report.ActivitySummaryMonth.prototype.getRefundsFormatted = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 21, ""));
};


/**
 * @param {string} value
 * @return {!proto.billing.private.report.ActivitySummaryMonth} returns this
 */
proto.billing.private.report.ActivitySummaryMonth.prototype.setRefundsFormatted = function(value) {
  return jspb.Message.setProto3StringField(this, 21, value);
};


/**
 * optional int32 revenue = 22;
 * @return {number}
 */
proto.billing.private.report.ActivitySummaryMonth.prototype.getRevenue = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 22, 0));
};


/**
 * @param {number} value
 * @return {!proto.billing.private.report.ActivitySummaryMonth} returns this
 */
proto.billing.private.report.ActivitySummaryMonth.prototype.setRevenue = function(value) {
  return jspb.Message.setProto3IntField(this, 22, value);
};


/**
 * optional string revenue_formatted = 23;
 * @return {string}
 */
proto.billing.private.report.ActivitySummaryMonth.prototype.getRevenueFormatted = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 23, ""));
};


/**
 * @param {string} value
 * @return {!proto.billing.private.report.ActivitySummaryMonth} returns this
 */
proto.billing.private.report.ActivitySummaryMonth.prototype.setRevenueFormatted = function(value) {
  return jspb.Message.setProto3StringField(this, 23, value);
};


/**
 * optional int32 recognised_revenue = 24;
 * @return {number}
 */
proto.billing.private.report.ActivitySummaryMonth.prototype.getRecognisedRevenue = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 24, 0));
};


/**
 * @param {number} value
 * @return {!proto.billing.private.report.ActivitySummaryMonth} returns this
 */
proto.billing.private.report.ActivitySummaryMonth.prototype.setRecognisedRevenue = function(value) {
  return jspb.Message.setProto3IntField(this, 24, value);
};


/**
 * optional string recognised_revenue_formatted = 25;
 * @return {string}
 */
proto.billing.private.report.ActivitySummaryMonth.prototype.getRecognisedRevenueFormatted = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 25, ""));
};


/**
 * @param {string} value
 * @return {!proto.billing.private.report.ActivitySummaryMonth} returns this
 */
proto.billing.private.report.ActivitySummaryMonth.prototype.setRecognisedRevenueFormatted = function(value) {
  return jspb.Message.setProto3StringField(this, 25, value);
};


/**
 * optional int32 deferred_revenue = 26;
 * @return {number}
 */
proto.billing.private.report.ActivitySummaryMonth.prototype.getDeferredRevenue = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 26, 0));
};


/**
 * @param {number} value
 * @return {!proto.billing.private.report.ActivitySummaryMonth} returns this
 */
proto.billing.private.report.ActivitySummaryMonth.prototype.setDeferredRevenue = function(value) {
  return jspb.Message.setProto3IntField(this, 26, value);
};


/**
 * optional string deferred_revenue_formatted = 27;
 * @return {string}
 */
proto.billing.private.report.ActivitySummaryMonth.prototype.getDeferredRevenueFormatted = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 27, ""));
};


/**
 * @param {string} value
 * @return {!proto.billing.private.report.ActivitySummaryMonth} returns this
 */
proto.billing.private.report.ActivitySummaryMonth.prototype.setDeferredRevenueFormatted = function(value) {
  return jspb.Message.setProto3StringField(this, 27, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.billing.private.report.GetTaxSummaryRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.billing.private.report.GetTaxSummaryRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.billing.private.report.GetTaxSummaryRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing.private.report.GetTaxSummaryRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    companyId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    startDate: (f = msg.getStartDate()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    endDate: (f = msg.getEndDate()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    jurisdictionType: jspb.Message.getFieldWithDefault(msg, 4, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.billing.private.report.GetTaxSummaryRequest}
 */
proto.billing.private.report.GetTaxSummaryRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.billing.private.report.GetTaxSummaryRequest;
  return proto.billing.private.report.GetTaxSummaryRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.billing.private.report.GetTaxSummaryRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.billing.private.report.GetTaxSummaryRequest}
 */
proto.billing.private.report.GetTaxSummaryRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setCompanyId(value);
      break;
    case 2:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setStartDate(value);
      break;
    case 3:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setEndDate(value);
      break;
    case 4:
      var value = /** @type {!proto.billing.private.report.JurisdictionType} */ (reader.readEnum());
      msg.setJurisdictionType(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.billing.private.report.GetTaxSummaryRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.billing.private.report.GetTaxSummaryRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.billing.private.report.GetTaxSummaryRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing.private.report.GetTaxSummaryRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCompanyId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getStartDate();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getEndDate();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getJurisdictionType();
  if (f !== 0.0) {
    writer.writeEnum(
      4,
      f
    );
  }
};


/**
 * optional int32 company_id = 1;
 * @return {number}
 */
proto.billing.private.report.GetTaxSummaryRequest.prototype.getCompanyId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.billing.private.report.GetTaxSummaryRequest} returns this
 */
proto.billing.private.report.GetTaxSummaryRequest.prototype.setCompanyId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional google.protobuf.Timestamp start_date = 2;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.billing.private.report.GetTaxSummaryRequest.prototype.getStartDate = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 2));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.billing.private.report.GetTaxSummaryRequest} returns this
*/
proto.billing.private.report.GetTaxSummaryRequest.prototype.setStartDate = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.billing.private.report.GetTaxSummaryRequest} returns this
 */
proto.billing.private.report.GetTaxSummaryRequest.prototype.clearStartDate = function() {
  return this.setStartDate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.billing.private.report.GetTaxSummaryRequest.prototype.hasStartDate = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional google.protobuf.Timestamp end_date = 3;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.billing.private.report.GetTaxSummaryRequest.prototype.getEndDate = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 3));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.billing.private.report.GetTaxSummaryRequest} returns this
*/
proto.billing.private.report.GetTaxSummaryRequest.prototype.setEndDate = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.billing.private.report.GetTaxSummaryRequest} returns this
 */
proto.billing.private.report.GetTaxSummaryRequest.prototype.clearEndDate = function() {
  return this.setEndDate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.billing.private.report.GetTaxSummaryRequest.prototype.hasEndDate = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional JurisdictionType jurisdiction_type = 4;
 * @return {!proto.billing.private.report.JurisdictionType}
 */
proto.billing.private.report.GetTaxSummaryRequest.prototype.getJurisdictionType = function() {
  return /** @type {!proto.billing.private.report.JurisdictionType} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {!proto.billing.private.report.JurisdictionType} value
 * @return {!proto.billing.private.report.GetTaxSummaryRequest} returns this
 */
proto.billing.private.report.GetTaxSummaryRequest.prototype.setJurisdictionType = function(value) {
  return jspb.Message.setProto3EnumField(this, 4, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.billing.private.report.GetTaxSummaryResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.billing.private.report.GetTaxSummaryResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.billing.private.report.GetTaxSummaryResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.billing.private.report.GetTaxSummaryResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing.private.report.GetTaxSummaryResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    resultsList: jspb.Message.toObjectList(msg.getResultsList(),
    proto.billing.private.report.TaxSummary.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.billing.private.report.GetTaxSummaryResponse}
 */
proto.billing.private.report.GetTaxSummaryResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.billing.private.report.GetTaxSummaryResponse;
  return proto.billing.private.report.GetTaxSummaryResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.billing.private.report.GetTaxSummaryResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.billing.private.report.GetTaxSummaryResponse}
 */
proto.billing.private.report.GetTaxSummaryResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.billing.private.report.TaxSummary;
      reader.readMessage(value,proto.billing.private.report.TaxSummary.deserializeBinaryFromReader);
      msg.addResults(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.billing.private.report.GetTaxSummaryResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.billing.private.report.GetTaxSummaryResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.billing.private.report.GetTaxSummaryResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing.private.report.GetTaxSummaryResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getResultsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.billing.private.report.TaxSummary.serializeBinaryToWriter
    );
  }
};


/**
 * repeated TaxSummary results = 1;
 * @return {!Array<!proto.billing.private.report.TaxSummary>}
 */
proto.billing.private.report.GetTaxSummaryResponse.prototype.getResultsList = function() {
  return /** @type{!Array<!proto.billing.private.report.TaxSummary>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.billing.private.report.TaxSummary, 1));
};


/**
 * @param {!Array<!proto.billing.private.report.TaxSummary>} value
 * @return {!proto.billing.private.report.GetTaxSummaryResponse} returns this
*/
proto.billing.private.report.GetTaxSummaryResponse.prototype.setResultsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.billing.private.report.TaxSummary=} opt_value
 * @param {number=} opt_index
 * @return {!proto.billing.private.report.TaxSummary}
 */
proto.billing.private.report.GetTaxSummaryResponse.prototype.addResults = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.billing.private.report.TaxSummary, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.billing.private.report.GetTaxSummaryResponse} returns this
 */
proto.billing.private.report.GetTaxSummaryResponse.prototype.clearResultsList = function() {
  return this.setResultsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.billing.private.report.TaxSummary.prototype.toObject = function(opt_includeInstance) {
  return proto.billing.private.report.TaxSummary.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.billing.private.report.TaxSummary} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing.private.report.TaxSummary.toObject = function(includeInstance, msg) {
  var f, obj = {
    jurisdictionName: jspb.Message.getFieldWithDefault(msg, 1, ""),
    taxableAmount: jspb.Message.getFieldWithDefault(msg, 2, 0),
    taxableAmountFormatted: jspb.Message.getFieldWithDefault(msg, 3, ""),
    tax: jspb.Message.getFieldWithDefault(msg, 4, 0),
    taxFormatted: jspb.Message.getFieldWithDefault(msg, 5, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.billing.private.report.TaxSummary}
 */
proto.billing.private.report.TaxSummary.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.billing.private.report.TaxSummary;
  return proto.billing.private.report.TaxSummary.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.billing.private.report.TaxSummary} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.billing.private.report.TaxSummary}
 */
proto.billing.private.report.TaxSummary.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setJurisdictionName(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setTaxableAmount(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setTaxableAmountFormatted(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setTax(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setTaxFormatted(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.billing.private.report.TaxSummary.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.billing.private.report.TaxSummary.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.billing.private.report.TaxSummary} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing.private.report.TaxSummary.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getJurisdictionName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getTaxableAmount();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getTaxableAmountFormatted();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getTax();
  if (f !== 0) {
    writer.writeInt32(
      4,
      f
    );
  }
  f = message.getTaxFormatted();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
};


/**
 * optional string jurisdiction_name = 1;
 * @return {string}
 */
proto.billing.private.report.TaxSummary.prototype.getJurisdictionName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.billing.private.report.TaxSummary} returns this
 */
proto.billing.private.report.TaxSummary.prototype.setJurisdictionName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int32 taxable_amount = 2;
 * @return {number}
 */
proto.billing.private.report.TaxSummary.prototype.getTaxableAmount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.billing.private.report.TaxSummary} returns this
 */
proto.billing.private.report.TaxSummary.prototype.setTaxableAmount = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional string taxable_amount_formatted = 3;
 * @return {string}
 */
proto.billing.private.report.TaxSummary.prototype.getTaxableAmountFormatted = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.billing.private.report.TaxSummary} returns this
 */
proto.billing.private.report.TaxSummary.prototype.setTaxableAmountFormatted = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional int32 tax = 4;
 * @return {number}
 */
proto.billing.private.report.TaxSummary.prototype.getTax = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.billing.private.report.TaxSummary} returns this
 */
proto.billing.private.report.TaxSummary.prototype.setTax = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional string tax_formatted = 5;
 * @return {string}
 */
proto.billing.private.report.TaxSummary.prototype.getTaxFormatted = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.billing.private.report.TaxSummary} returns this
 */
proto.billing.private.report.TaxSummary.prototype.setTaxFormatted = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.billing.private.report.GetExitReasonsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.billing.private.report.GetExitReasonsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.billing.private.report.GetExitReasonsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing.private.report.GetExitReasonsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    companyDomain: jspb.Message.getFieldWithDefault(msg, 1, ""),
    page: jspb.Message.getFieldWithDefault(msg, 2, 0),
    pageSize: jspb.Message.getFieldWithDefault(msg, 3, 0),
    dateFrom: (f = msg.getDateFrom()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    dateTo: (f = msg.getDateTo()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    productId: (f = msg.getProductId()) && google_protobuf_wrappers_pb.Int32Value.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.billing.private.report.GetExitReasonsRequest}
 */
proto.billing.private.report.GetExitReasonsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.billing.private.report.GetExitReasonsRequest;
  return proto.billing.private.report.GetExitReasonsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.billing.private.report.GetExitReasonsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.billing.private.report.GetExitReasonsRequest}
 */
proto.billing.private.report.GetExitReasonsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setCompanyDomain(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPage(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPageSize(value);
      break;
    case 4:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setDateFrom(value);
      break;
    case 5:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setDateTo(value);
      break;
    case 6:
      var value = new google_protobuf_wrappers_pb.Int32Value;
      reader.readMessage(value,google_protobuf_wrappers_pb.Int32Value.deserializeBinaryFromReader);
      msg.setProductId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.billing.private.report.GetExitReasonsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.billing.private.report.GetExitReasonsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.billing.private.report.GetExitReasonsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing.private.report.GetExitReasonsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCompanyDomain();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getPage();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getPageSize();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
  f = message.getDateFrom();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getDateTo();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getProductId();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      google_protobuf_wrappers_pb.Int32Value.serializeBinaryToWriter
    );
  }
};


/**
 * optional string company_domain = 1;
 * @return {string}
 */
proto.billing.private.report.GetExitReasonsRequest.prototype.getCompanyDomain = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.billing.private.report.GetExitReasonsRequest} returns this
 */
proto.billing.private.report.GetExitReasonsRequest.prototype.setCompanyDomain = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int32 page = 2;
 * @return {number}
 */
proto.billing.private.report.GetExitReasonsRequest.prototype.getPage = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.billing.private.report.GetExitReasonsRequest} returns this
 */
proto.billing.private.report.GetExitReasonsRequest.prototype.setPage = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int32 page_size = 3;
 * @return {number}
 */
proto.billing.private.report.GetExitReasonsRequest.prototype.getPageSize = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.billing.private.report.GetExitReasonsRequest} returns this
 */
proto.billing.private.report.GetExitReasonsRequest.prototype.setPageSize = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional google.protobuf.Timestamp date_from = 4;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.billing.private.report.GetExitReasonsRequest.prototype.getDateFrom = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 4));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.billing.private.report.GetExitReasonsRequest} returns this
*/
proto.billing.private.report.GetExitReasonsRequest.prototype.setDateFrom = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.billing.private.report.GetExitReasonsRequest} returns this
 */
proto.billing.private.report.GetExitReasonsRequest.prototype.clearDateFrom = function() {
  return this.setDateFrom(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.billing.private.report.GetExitReasonsRequest.prototype.hasDateFrom = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional google.protobuf.Timestamp date_to = 5;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.billing.private.report.GetExitReasonsRequest.prototype.getDateTo = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 5));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.billing.private.report.GetExitReasonsRequest} returns this
*/
proto.billing.private.report.GetExitReasonsRequest.prototype.setDateTo = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.billing.private.report.GetExitReasonsRequest} returns this
 */
proto.billing.private.report.GetExitReasonsRequest.prototype.clearDateTo = function() {
  return this.setDateTo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.billing.private.report.GetExitReasonsRequest.prototype.hasDateTo = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional google.protobuf.Int32Value product_id = 6;
 * @return {?proto.google.protobuf.Int32Value}
 */
proto.billing.private.report.GetExitReasonsRequest.prototype.getProductId = function() {
  return /** @type{?proto.google.protobuf.Int32Value} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.Int32Value, 6));
};


/**
 * @param {?proto.google.protobuf.Int32Value|undefined} value
 * @return {!proto.billing.private.report.GetExitReasonsRequest} returns this
*/
proto.billing.private.report.GetExitReasonsRequest.prototype.setProductId = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.billing.private.report.GetExitReasonsRequest} returns this
 */
proto.billing.private.report.GetExitReasonsRequest.prototype.clearProductId = function() {
  return this.setProductId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.billing.private.report.GetExitReasonsRequest.prototype.hasProductId = function() {
  return jspb.Message.getField(this, 6) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.billing.private.report.GetExitReasonsResponse.repeatedFields_ = [5];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.billing.private.report.GetExitReasonsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.billing.private.report.GetExitReasonsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.billing.private.report.GetExitReasonsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing.private.report.GetExitReasonsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    currentPage: jspb.Message.getFieldWithDefault(msg, 1, 0),
    pageCount: jspb.Message.getFieldWithDefault(msg, 2, 0),
    pageSize: jspb.Message.getFieldWithDefault(msg, 3, 0),
    rowCount: jspb.Message.getFieldWithDefault(msg, 4, 0),
    resultsList: jspb.Message.toObjectList(msg.getResultsList(),
    proto.billing.private.report.ExitReasonListItem.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.billing.private.report.GetExitReasonsResponse}
 */
proto.billing.private.report.GetExitReasonsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.billing.private.report.GetExitReasonsResponse;
  return proto.billing.private.report.GetExitReasonsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.billing.private.report.GetExitReasonsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.billing.private.report.GetExitReasonsResponse}
 */
proto.billing.private.report.GetExitReasonsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setCurrentPage(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPageCount(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPageSize(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setRowCount(value);
      break;
    case 5:
      var value = new proto.billing.private.report.ExitReasonListItem;
      reader.readMessage(value,proto.billing.private.report.ExitReasonListItem.deserializeBinaryFromReader);
      msg.addResults(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.billing.private.report.GetExitReasonsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.billing.private.report.GetExitReasonsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.billing.private.report.GetExitReasonsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing.private.report.GetExitReasonsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCurrentPage();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getPageCount();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getPageSize();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
  f = message.getRowCount();
  if (f !== 0) {
    writer.writeInt64(
      4,
      f
    );
  }
  f = message.getResultsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      5,
      f,
      proto.billing.private.report.ExitReasonListItem.serializeBinaryToWriter
    );
  }
};


/**
 * optional int32 current_page = 1;
 * @return {number}
 */
proto.billing.private.report.GetExitReasonsResponse.prototype.getCurrentPage = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.billing.private.report.GetExitReasonsResponse} returns this
 */
proto.billing.private.report.GetExitReasonsResponse.prototype.setCurrentPage = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int32 page_count = 2;
 * @return {number}
 */
proto.billing.private.report.GetExitReasonsResponse.prototype.getPageCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.billing.private.report.GetExitReasonsResponse} returns this
 */
proto.billing.private.report.GetExitReasonsResponse.prototype.setPageCount = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int32 page_size = 3;
 * @return {number}
 */
proto.billing.private.report.GetExitReasonsResponse.prototype.getPageSize = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.billing.private.report.GetExitReasonsResponse} returns this
 */
proto.billing.private.report.GetExitReasonsResponse.prototype.setPageSize = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional int64 row_count = 4;
 * @return {number}
 */
proto.billing.private.report.GetExitReasonsResponse.prototype.getRowCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.billing.private.report.GetExitReasonsResponse} returns this
 */
proto.billing.private.report.GetExitReasonsResponse.prototype.setRowCount = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * repeated ExitReasonListItem results = 5;
 * @return {!Array<!proto.billing.private.report.ExitReasonListItem>}
 */
proto.billing.private.report.GetExitReasonsResponse.prototype.getResultsList = function() {
  return /** @type{!Array<!proto.billing.private.report.ExitReasonListItem>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.billing.private.report.ExitReasonListItem, 5));
};


/**
 * @param {!Array<!proto.billing.private.report.ExitReasonListItem>} value
 * @return {!proto.billing.private.report.GetExitReasonsResponse} returns this
*/
proto.billing.private.report.GetExitReasonsResponse.prototype.setResultsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 5, value);
};


/**
 * @param {!proto.billing.private.report.ExitReasonListItem=} opt_value
 * @param {number=} opt_index
 * @return {!proto.billing.private.report.ExitReasonListItem}
 */
proto.billing.private.report.GetExitReasonsResponse.prototype.addResults = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 5, opt_value, proto.billing.private.report.ExitReasonListItem, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.billing.private.report.GetExitReasonsResponse} returns this
 */
proto.billing.private.report.GetExitReasonsResponse.prototype.clearResultsList = function() {
  return this.setResultsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.billing.private.report.ExitReasonListItem.prototype.toObject = function(opt_includeInstance) {
  return proto.billing.private.report.ExitReasonListItem.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.billing.private.report.ExitReasonListItem} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing.private.report.ExitReasonListItem.toObject = function(includeInstance, msg) {
  var f, obj = {
    planName: jspb.Message.getFieldWithDefault(msg, 1, ""),
    productName: jspb.Message.getFieldWithDefault(msg, 2, ""),
    customerExitReason: jspb.Message.getFieldWithDefault(msg, 3, ""),
    date: (f = msg.getDate()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    comments: jspb.Message.getFieldWithDefault(msg, 5, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.billing.private.report.ExitReasonListItem}
 */
proto.billing.private.report.ExitReasonListItem.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.billing.private.report.ExitReasonListItem;
  return proto.billing.private.report.ExitReasonListItem.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.billing.private.report.ExitReasonListItem} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.billing.private.report.ExitReasonListItem}
 */
proto.billing.private.report.ExitReasonListItem.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setPlanName(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setProductName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setCustomerExitReason(value);
      break;
    case 4:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setDate(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setComments(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.billing.private.report.ExitReasonListItem.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.billing.private.report.ExitReasonListItem.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.billing.private.report.ExitReasonListItem} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing.private.report.ExitReasonListItem.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPlanName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getProductName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getCustomerExitReason();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getDate();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getComments();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
};


/**
 * optional string plan_name = 1;
 * @return {string}
 */
proto.billing.private.report.ExitReasonListItem.prototype.getPlanName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.billing.private.report.ExitReasonListItem} returns this
 */
proto.billing.private.report.ExitReasonListItem.prototype.setPlanName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string product_name = 2;
 * @return {string}
 */
proto.billing.private.report.ExitReasonListItem.prototype.getProductName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.billing.private.report.ExitReasonListItem} returns this
 */
proto.billing.private.report.ExitReasonListItem.prototype.setProductName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string customer_exit_reason = 3;
 * @return {string}
 */
proto.billing.private.report.ExitReasonListItem.prototype.getCustomerExitReason = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.billing.private.report.ExitReasonListItem} returns this
 */
proto.billing.private.report.ExitReasonListItem.prototype.setCustomerExitReason = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional google.protobuf.Timestamp date = 4;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.billing.private.report.ExitReasonListItem.prototype.getDate = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 4));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.billing.private.report.ExitReasonListItem} returns this
*/
proto.billing.private.report.ExitReasonListItem.prototype.setDate = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.billing.private.report.ExitReasonListItem} returns this
 */
proto.billing.private.report.ExitReasonListItem.prototype.clearDate = function() {
  return this.setDate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.billing.private.report.ExitReasonListItem.prototype.hasDate = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional string comments = 5;
 * @return {string}
 */
proto.billing.private.report.ExitReasonListItem.prototype.getComments = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.billing.private.report.ExitReasonListItem} returns this
 */
proto.billing.private.report.ExitReasonListItem.prototype.setComments = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.billing.private.report.ExitReasonsGraphDataRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.billing.private.report.ExitReasonsGraphDataRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.billing.private.report.ExitReasonsGraphDataRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing.private.report.ExitReasonsGraphDataRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    companyDomain: jspb.Message.getFieldWithDefault(msg, 1, ""),
    dateFrom: (f = msg.getDateFrom()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    dateTo: (f = msg.getDateTo()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    productId: (f = msg.getProductId()) && google_protobuf_wrappers_pb.Int32Value.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.billing.private.report.ExitReasonsGraphDataRequest}
 */
proto.billing.private.report.ExitReasonsGraphDataRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.billing.private.report.ExitReasonsGraphDataRequest;
  return proto.billing.private.report.ExitReasonsGraphDataRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.billing.private.report.ExitReasonsGraphDataRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.billing.private.report.ExitReasonsGraphDataRequest}
 */
proto.billing.private.report.ExitReasonsGraphDataRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setCompanyDomain(value);
      break;
    case 2:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setDateFrom(value);
      break;
    case 3:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setDateTo(value);
      break;
    case 4:
      var value = new google_protobuf_wrappers_pb.Int32Value;
      reader.readMessage(value,google_protobuf_wrappers_pb.Int32Value.deserializeBinaryFromReader);
      msg.setProductId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.billing.private.report.ExitReasonsGraphDataRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.billing.private.report.ExitReasonsGraphDataRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.billing.private.report.ExitReasonsGraphDataRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing.private.report.ExitReasonsGraphDataRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCompanyDomain();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getDateFrom();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getDateTo();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getProductId();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      google_protobuf_wrappers_pb.Int32Value.serializeBinaryToWriter
    );
  }
};


/**
 * optional string company_domain = 1;
 * @return {string}
 */
proto.billing.private.report.ExitReasonsGraphDataRequest.prototype.getCompanyDomain = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.billing.private.report.ExitReasonsGraphDataRequest} returns this
 */
proto.billing.private.report.ExitReasonsGraphDataRequest.prototype.setCompanyDomain = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional google.protobuf.Timestamp date_from = 2;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.billing.private.report.ExitReasonsGraphDataRequest.prototype.getDateFrom = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 2));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.billing.private.report.ExitReasonsGraphDataRequest} returns this
*/
proto.billing.private.report.ExitReasonsGraphDataRequest.prototype.setDateFrom = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.billing.private.report.ExitReasonsGraphDataRequest} returns this
 */
proto.billing.private.report.ExitReasonsGraphDataRequest.prototype.clearDateFrom = function() {
  return this.setDateFrom(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.billing.private.report.ExitReasonsGraphDataRequest.prototype.hasDateFrom = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional google.protobuf.Timestamp date_to = 3;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.billing.private.report.ExitReasonsGraphDataRequest.prototype.getDateTo = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 3));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.billing.private.report.ExitReasonsGraphDataRequest} returns this
*/
proto.billing.private.report.ExitReasonsGraphDataRequest.prototype.setDateTo = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.billing.private.report.ExitReasonsGraphDataRequest} returns this
 */
proto.billing.private.report.ExitReasonsGraphDataRequest.prototype.clearDateTo = function() {
  return this.setDateTo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.billing.private.report.ExitReasonsGraphDataRequest.prototype.hasDateTo = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional google.protobuf.Int32Value product_id = 4;
 * @return {?proto.google.protobuf.Int32Value}
 */
proto.billing.private.report.ExitReasonsGraphDataRequest.prototype.getProductId = function() {
  return /** @type{?proto.google.protobuf.Int32Value} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.Int32Value, 4));
};


/**
 * @param {?proto.google.protobuf.Int32Value|undefined} value
 * @return {!proto.billing.private.report.ExitReasonsGraphDataRequest} returns this
*/
proto.billing.private.report.ExitReasonsGraphDataRequest.prototype.setProductId = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.billing.private.report.ExitReasonsGraphDataRequest} returns this
 */
proto.billing.private.report.ExitReasonsGraphDataRequest.prototype.clearProductId = function() {
  return this.setProductId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.billing.private.report.ExitReasonsGraphDataRequest.prototype.hasProductId = function() {
  return jspb.Message.getField(this, 4) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.billing.private.report.ExitReasonsGraphDataResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.billing.private.report.ExitReasonsGraphDataResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.billing.private.report.ExitReasonsGraphDataResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.billing.private.report.ExitReasonsGraphDataResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing.private.report.ExitReasonsGraphDataResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    dataList: jspb.Message.toObjectList(msg.getDataList(),
    proto.billing.private.report.ExitReasonGraphMonthData.toObject, includeInstance),
    total: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.billing.private.report.ExitReasonsGraphDataResponse}
 */
proto.billing.private.report.ExitReasonsGraphDataResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.billing.private.report.ExitReasonsGraphDataResponse;
  return proto.billing.private.report.ExitReasonsGraphDataResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.billing.private.report.ExitReasonsGraphDataResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.billing.private.report.ExitReasonsGraphDataResponse}
 */
proto.billing.private.report.ExitReasonsGraphDataResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.billing.private.report.ExitReasonGraphMonthData;
      reader.readMessage(value,proto.billing.private.report.ExitReasonGraphMonthData.deserializeBinaryFromReader);
      msg.addData(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setTotal(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.billing.private.report.ExitReasonsGraphDataResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.billing.private.report.ExitReasonsGraphDataResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.billing.private.report.ExitReasonsGraphDataResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing.private.report.ExitReasonsGraphDataResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDataList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.billing.private.report.ExitReasonGraphMonthData.serializeBinaryToWriter
    );
  }
  f = message.getTotal();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
};


/**
 * repeated ExitReasonGraphMonthData data = 1;
 * @return {!Array<!proto.billing.private.report.ExitReasonGraphMonthData>}
 */
proto.billing.private.report.ExitReasonsGraphDataResponse.prototype.getDataList = function() {
  return /** @type{!Array<!proto.billing.private.report.ExitReasonGraphMonthData>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.billing.private.report.ExitReasonGraphMonthData, 1));
};


/**
 * @param {!Array<!proto.billing.private.report.ExitReasonGraphMonthData>} value
 * @return {!proto.billing.private.report.ExitReasonsGraphDataResponse} returns this
*/
proto.billing.private.report.ExitReasonsGraphDataResponse.prototype.setDataList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.billing.private.report.ExitReasonGraphMonthData=} opt_value
 * @param {number=} opt_index
 * @return {!proto.billing.private.report.ExitReasonGraphMonthData}
 */
proto.billing.private.report.ExitReasonsGraphDataResponse.prototype.addData = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.billing.private.report.ExitReasonGraphMonthData, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.billing.private.report.ExitReasonsGraphDataResponse} returns this
 */
proto.billing.private.report.ExitReasonsGraphDataResponse.prototype.clearDataList = function() {
  return this.setDataList([]);
};


/**
 * optional int32 total = 2;
 * @return {number}
 */
proto.billing.private.report.ExitReasonsGraphDataResponse.prototype.getTotal = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.billing.private.report.ExitReasonsGraphDataResponse} returns this
 */
proto.billing.private.report.ExitReasonsGraphDataResponse.prototype.setTotal = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.billing.private.report.ExitReasonGraphMonthData.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.billing.private.report.ExitReasonGraphMonthData.prototype.toObject = function(opt_includeInstance) {
  return proto.billing.private.report.ExitReasonGraphMonthData.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.billing.private.report.ExitReasonGraphMonthData} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing.private.report.ExitReasonGraphMonthData.toObject = function(includeInstance, msg) {
  var f, obj = {
    date: (f = msg.getDate()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    dataList: jspb.Message.toObjectList(msg.getDataList(),
    proto.billing.private.report.ExitReasonGraphPerTypeData.toObject, includeInstance),
    monthTotal: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.billing.private.report.ExitReasonGraphMonthData}
 */
proto.billing.private.report.ExitReasonGraphMonthData.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.billing.private.report.ExitReasonGraphMonthData;
  return proto.billing.private.report.ExitReasonGraphMonthData.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.billing.private.report.ExitReasonGraphMonthData} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.billing.private.report.ExitReasonGraphMonthData}
 */
proto.billing.private.report.ExitReasonGraphMonthData.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setDate(value);
      break;
    case 2:
      var value = new proto.billing.private.report.ExitReasonGraphPerTypeData;
      reader.readMessage(value,proto.billing.private.report.ExitReasonGraphPerTypeData.deserializeBinaryFromReader);
      msg.addData(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setMonthTotal(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.billing.private.report.ExitReasonGraphMonthData.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.billing.private.report.ExitReasonGraphMonthData.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.billing.private.report.ExitReasonGraphMonthData} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing.private.report.ExitReasonGraphMonthData.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDate();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getDataList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.billing.private.report.ExitReasonGraphPerTypeData.serializeBinaryToWriter
    );
  }
  f = message.getMonthTotal();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
};


/**
 * optional google.protobuf.Timestamp date = 1;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.billing.private.report.ExitReasonGraphMonthData.prototype.getDate = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 1));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.billing.private.report.ExitReasonGraphMonthData} returns this
*/
proto.billing.private.report.ExitReasonGraphMonthData.prototype.setDate = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.billing.private.report.ExitReasonGraphMonthData} returns this
 */
proto.billing.private.report.ExitReasonGraphMonthData.prototype.clearDate = function() {
  return this.setDate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.billing.private.report.ExitReasonGraphMonthData.prototype.hasDate = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * repeated ExitReasonGraphPerTypeData data = 2;
 * @return {!Array<!proto.billing.private.report.ExitReasonGraphPerTypeData>}
 */
proto.billing.private.report.ExitReasonGraphMonthData.prototype.getDataList = function() {
  return /** @type{!Array<!proto.billing.private.report.ExitReasonGraphPerTypeData>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.billing.private.report.ExitReasonGraphPerTypeData, 2));
};


/**
 * @param {!Array<!proto.billing.private.report.ExitReasonGraphPerTypeData>} value
 * @return {!proto.billing.private.report.ExitReasonGraphMonthData} returns this
*/
proto.billing.private.report.ExitReasonGraphMonthData.prototype.setDataList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.billing.private.report.ExitReasonGraphPerTypeData=} opt_value
 * @param {number=} opt_index
 * @return {!proto.billing.private.report.ExitReasonGraphPerTypeData}
 */
proto.billing.private.report.ExitReasonGraphMonthData.prototype.addData = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.billing.private.report.ExitReasonGraphPerTypeData, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.billing.private.report.ExitReasonGraphMonthData} returns this
 */
proto.billing.private.report.ExitReasonGraphMonthData.prototype.clearDataList = function() {
  return this.setDataList([]);
};


/**
 * optional int32 month_total = 3;
 * @return {number}
 */
proto.billing.private.report.ExitReasonGraphMonthData.prototype.getMonthTotal = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.billing.private.report.ExitReasonGraphMonthData} returns this
 */
proto.billing.private.report.ExitReasonGraphMonthData.prototype.setMonthTotal = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.billing.private.report.ExitReasonGraphPerTypeData.prototype.toObject = function(opt_includeInstance) {
  return proto.billing.private.report.ExitReasonGraphPerTypeData.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.billing.private.report.ExitReasonGraphPerTypeData} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing.private.report.ExitReasonGraphPerTypeData.toObject = function(includeInstance, msg) {
  var f, obj = {
    exitReasonId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    count: jspb.Message.getFieldWithDefault(msg, 2, 0),
    percentage: jspb.Message.getFloatingPointFieldWithDefault(msg, 3, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.billing.private.report.ExitReasonGraphPerTypeData}
 */
proto.billing.private.report.ExitReasonGraphPerTypeData.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.billing.private.report.ExitReasonGraphPerTypeData;
  return proto.billing.private.report.ExitReasonGraphPerTypeData.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.billing.private.report.ExitReasonGraphPerTypeData} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.billing.private.report.ExitReasonGraphPerTypeData}
 */
proto.billing.private.report.ExitReasonGraphPerTypeData.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setExitReasonId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setCount(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setPercentage(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.billing.private.report.ExitReasonGraphPerTypeData.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.billing.private.report.ExitReasonGraphPerTypeData.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.billing.private.report.ExitReasonGraphPerTypeData} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing.private.report.ExitReasonGraphPerTypeData.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getExitReasonId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getCount();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getPercentage();
  if (f !== 0.0) {
    writer.writeDouble(
      3,
      f
    );
  }
};


/**
 * optional int32 exit_reason_id = 1;
 * @return {number}
 */
proto.billing.private.report.ExitReasonGraphPerTypeData.prototype.getExitReasonId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.billing.private.report.ExitReasonGraphPerTypeData} returns this
 */
proto.billing.private.report.ExitReasonGraphPerTypeData.prototype.setExitReasonId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int32 count = 2;
 * @return {number}
 */
proto.billing.private.report.ExitReasonGraphPerTypeData.prototype.getCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.billing.private.report.ExitReasonGraphPerTypeData} returns this
 */
proto.billing.private.report.ExitReasonGraphPerTypeData.prototype.setCount = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional double percentage = 3;
 * @return {number}
 */
proto.billing.private.report.ExitReasonGraphPerTypeData.prototype.getPercentage = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 3, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.billing.private.report.ExitReasonGraphPerTypeData} returns this
 */
proto.billing.private.report.ExitReasonGraphPerTypeData.prototype.setPercentage = function(value) {
  return jspb.Message.setProto3FloatField(this, 3, value);
};


/**
 * @enum {number}
 */
proto.billing.private.report.JurisdictionType = {
  JURISDICTION: 0,
  COUNTRY: 1
};

goog.object.extend(exports, proto.billing.private.report);
