import React from "react";
import { AppState } from "../../..";
import { useSelector } from "react-redux";
import "./CustomerStatusBar.scss";
import Panel from "../../../components/ui/panel/Panel";
import Text from "../../../components/ui/text/Text";
import { ICustomerDetailsReducerState } from "../../../reducers/customerDetailsReducer";
import moment from "moment";
import { Row, Col } from "react-grid-system";

const CustomerStatusBar: React.FC = () => {
  const { createdOn, totalAmountPaid, averageAmountPerMonth } = useSelector<AppState, ICustomerDetailsReducerState>(state => state.customerDetailsReducer)
  const createdOnDate = moment(createdOn).format("DD MMM YYYY");
  
  return (
    <Panel className="customer_status_bar">
      <Row>
        <Col sm={4}>
          <Text
            className="customer_status_bar__item__label"
            content="CUSTOMER_STATUS_BAR_CUSTOMER_SINCE"
            noMargin
          />
          <Text
            className="customer_status_bar__item__value"
            shouldTranslate={false}
            content={createdOnDate}
            noMargin
          />
        </Col>
        <Col sm={4}>
          <Text
            className="customer_status_bar__item__label"
            content="CUSTOMER_STATUS_BAR_LIFETIME_VALUE"
            noMargin
          />
          <Text
            className="customer_status_bar__item__value"
            shouldTranslate={false}
            content={`${totalAmountPaid}`}
            noMargin
          />
        </Col>
        <Col sm={4}>
          <Text
            className="customer_status_bar__item__label"
            content="CUSTOMER_STATUS_BAR_AVERAGE_VALUE"
            noMargin
          />
          <Text
            className="customer_status_bar__item__value"
            translateWith={{ averageAmountPerMonth }}
            content="AVERAGE_VALUE_PER_MONTH"
            noMargin
          />
        </Col>
      </Row>
    </Panel>
  )
}

export default CustomerStatusBar;
