import React from "react";

import CouponsWelcome from "../../components/coupons/CouponsWelcome";
import PageWrapper from "../../components/ui/layout/PageWrapper";
import counterpart from "counterpart";
import Helmet from "react-helmet";
import NavigationAlertCurrencyMismatch from "../../components/ui/navigation-alert/NavigationAlertCurrencyMismatch";

interface ICouponsProps {
}

export const Coupons = (props: ICouponsProps) => {
  return (
    <div>
      <Helmet title={counterpart("NAVBAR_COUPONS")}/>
      <NavigationAlertCurrencyMismatch />
      <PageWrapper>
        <CouponsWelcome />
      </PageWrapper>
    </div>
  )
}

export default Coupons;