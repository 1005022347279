import React, { useEffect, useState, useMemo } from "react";
import moment from "moment";
import SubscriptionStatusBar from "../../../components/subscriptions/subscription-status-bar/SubscriptionStatusBar";
import UpdateSubscription from "../../../components/subscriptions/update-subscription/UpdateSubscription";
import PageWrapper from "../../../components/ui/layout/PageWrapper";
import SideNavBar from "../../../components/ui/side-nav-bar/SideNavBar";
import "./SubscriptionDetails.scss";
import { ISideNavItems } from "../../../models/SideNavItem";
import NavigationBar from "../../../components/ui/navigation-bar/NavigationBar";
import Text from "../../../components/ui/text/Text";
import Table from "../../../components/ui/table/Table";
import { useDispatch, useSelector } from "react-redux";
import { IAuthReducerState } from "../../../reducers/authReducer";
import { ISubscriptionDetailsReducerState } from "../../../reducers/subscriptionDetailsReducer";
import { AppState } from "../../..";
import { fetchSubscriptionDetails, resetSubscriptionDetails, fetchSubscriptionAddOns, setSubscriptionDetailsField, fetchSubscriptionAllowances, fetchSubscriptionDiscounts, setShowDeleteDiscountModal, setDeleteDiscount, removeSubscriptionDiscount } from "../../../actions/subscriptionDetailsActions";
import { fetchCustomerDetails, fetchCustomerCardDetails, resetCustomerDetails } from "../../../actions/customerDetailsActions";
import counterpart from "counterpart";
import { Link, useHistory, useRouteMatch } from "react-router-dom";
import NotFound from "../../error/NotFound";
import Modal from "../../../components/modal/Modal";
import { SubscriptionStatusType } from "../../../models/Subscriptions";
import CancelSubscription from "../../../components/cancel-subscription/CancelSubscription";
import SubscriptionDetailsTab from "../subscription-details-tab/SubscriptionDetailsTab";
import UpdateAddOn from "../../../components/subscriptions/update-add-on/UpdateAddOn";
import { setShowUpdateSubscriptionModal } from "../../../actions/subscriptionPlanActions";
import { IPlanWithCycles, FrequencyType, IProductWithPlans } from "../../../models/Product";
import NoticePanel from "../../../components/ui/notice-panel/NoticePanel";
import { ISubscriptionPlanReducerState } from "../../../reducers/subscriptionPlanReducer";
import { IShippingAddressReducerState } from "../../../reducers/shippingAddressReducer";
import { fetchSubscriptionShippingAddress, setShowShippingAddressUpdateModal } from "../../../actions/shippingAddressActions";
import UpdateShippingAddress from "../../../components/subscriptions/subscription-shipping-address/update-shipping-address/UpdateShippingAddress";
import { fetchInvoiceCreditNotesFooter } from "../../../actions/invoicesCreditNotesCustomizationActions";
import ProgressIndicator from "../../../components/ui/progress-indicator/ProgressIndicator";
import { IEventLogsReducerState } from "../../../reducers/eventLogsReducer";
import { IPlanChangedEventLog, IUpdatedItem, RelatedItemType, EventLogType } from "../../../models/EventLogs";
import { fetchCurrencies } from "../../../actions/productPlanCycleActions";
import API from "../../../utils/API";
import { getCycleDescription } from "../../../utils/commonUtils";
import UpdateAllowance from "../../../components/subscriptions/update-allowance/UpdateAllowance";
import SubscriptionAddDiscount from "../../../components/subscriptions/subscription-add-discount/SubscriptionAddDiscount";
import ChangeBillDate from "../../../components/subscriptions/change-bill-date/ChangeBillDate";
import { fetchDiscountsList } from "../../../actions/discount-coupon/discountsActions";
import Panel from "../../../components/ui/panel/Panel";
import Button from "../../../components/ui/button/Button";
import { Discount } from "../../../utils/grpc/generated/Billsby.Protos/billing/private/subscription/discount/discount_pb";
import { IDiscountsState } from "../../../reducers/discount-coupons/discountsReducer";
import { fetchEventLogs } from "../../../actions/eventLogsActions";
import { PricingModelType } from "../../../utils/grpc/generated/Billsby.Protos/billing/private/subscription/subscription_pb";
import { Int32Value } from "google-protobuf/google/protobuf/wrappers_pb";
import ErrorNotification from "../../../components/ui/error-notification/ErrorNotification";
import SubscriptionUpdateStatus from "../../../components/subscriptions/subscription-update-status/SubscriptionUpdateStatus";
import { Permission } from "../../../models/Auth";
import useCheckFeaturePermission from "../../../utils/hooks/useCheckFeaturePermission";

interface ISubscriptionDetailsProps {
  authData: IAuthReducerState;
  subscriptionDetailsData: ISubscriptionDetailsReducerState;
  subscriptionPlanData: ISubscriptionPlanReducerState;
  shippingAddressData: IShippingAddressReducerState;
  eventLogs: IEventLogsReducerState;
  discountListData: IDiscountsState
}

interface ISubscriberId {
  id: string;
}

export const SubscriptionDetails: React.FC<ISubscriptionDetailsProps> = () => {
  const discountListData = useSelector((state: AppState) => state.discountsReducer);
  const authData = useSelector((state: AppState) => state.auth);
  const subscriptionDetailsData = useSelector((state: AppState) => state.subscriptionDetailsReducer);
  const subscriptionPlanData = useSelector((state: AppState) => state.subscriptionPlanReducer);
  const shippingAddressData = useSelector((state: AppState) => state.shippingAddressReducer);
  const eventLogs = useSelector((state: AppState) => state.eventLogsReducer);

  const dispatch = useDispatch<Function>();

  const history = useHistory();
  const match = useRouteMatch();

  const { currentCompanyDomain, currentCompanyId } = authData;
  const [plan, setPlan] = useState<IPlanWithCycles | undefined>(undefined);
  const {
    nextBillingAmountFormatted,
    nextCycleRecurringBillingAmount,
    nextBillingDate,
    subscriptionId,
    cycleIdNext,
    planIdNext,
    subscriptionUniqueId,
    nextPlanPricingModel,
    customerFullname,
    customerUniqueId,
    planId,
    cycleId,
    status,
    addOnToEdit,
    addOns,
    showDeleteDiscountModal,
    discountToDelete,
    discounts,
    cycleSkipCounter, contractMinimumTermEnd, pauseSubscriptionError, resumeSubscriptionError
  } = subscriptionDetailsData;
  const { discountList } = discountListData;
  const { showSubscriptionUpdateModal } = subscriptionPlanData;
  const { showShippingAddressUpdateModal } = shippingAddressData;
  const formattedNextBillingDate = moment(nextBillingDate).format("DD MMM YYYY");
  const formattedNextBillingTime = moment(nextBillingDate).format("HH:mm:ss Z");

  const [showPauseSubscriptionModal, setShowPauseSubscriptionModal] = useState(false);
  const [showCancelSubscriptionModal, setShowCancelSubscriptionModal] = useState(false);
  const [showResumeSubscriptionModal, setShowResumeSubscriptionModal] = useState(false);
  const [showUpdateStatusModal, setShowUpdateStatusModal] = useState(false);
  const [showAddAddonModal, setShowAddAddonModal] = useState(false);
  const [showAllowanceModal, setShowAllowanceModal] = useState(false);
  const [showDiscountModal, setShowDiscountModal] = useState(false);
  const [showChangeBillDateModal, setShowChangeBillDateModal] = useState(false);
  const [dataIsLoaded, setDataIsLoaded] = useState(false);
  const [isLoadingData, setIsLoadingData] = useState(false);
  const [plansList, setPlansList] = useState<Array<IPlanWithCycles>>([]);
  const [priceSummary, setPriceSummary] = useState("");
  const [isRemovingDiscount, setIsRemovingDiscount] = useState(false);

  const isSubscriptionEditPermitted = useCheckFeaturePermission(Permission.SubscriptionEdit);
  const isSubscriptionAddAddonPermitted = useCheckFeaturePermission(Permission.SubscriptionAddAddon);
  const isSubscriptionAddAllowancePermitted = useCheckFeaturePermission(Permission.SubscriptionAddAllowance);
  const isSubscriptionAddDiscountPermitted = useCheckFeaturePermission(Permission.SubscriptionAddDiscount);
  const isSubscriptionChangeBillDatePermitted = useCheckFeaturePermission(Permission.SubscriptionChangeBillDate);
  const isSubscriptionUpdateStatusPermitted = useCheckFeaturePermission(Permission.SubscriptionUpdateStatus);
  const isSubscriptionDeletionPermitted = useCheckFeaturePermission(Permission.SubscriptionCancel);

  const getFrequencyTypeText = (frequency: number, frequencyType?: FrequencyType) => {
    switch (frequencyType) {
      case FrequencyType.Daily:
        return frequency > 1 ? counterpart("SUBSCRIPTION_DETAILS_FREQUENCY_TYPE_DAILY_PLURAL") : counterpart("SUBSCRIPTION_DETAILS_FREQUENCY_TYPE_DAILY");
      case FrequencyType.Weekly:
        return frequency > 1 ? counterpart("SUBSCRIPTION_DETAILS_FREQUENCY_TYPE_WEEKLY_PLURAL") : counterpart("SUBSCRIPTION_DETAILS_FREQUENCY_TYPE_WEEKLY");
      case FrequencyType.Monthly:
        return frequency > 1 ? counterpart("SUBSCRIPTION_DETAILS_FREQUENCY_TYPE_MONTHLY_PLURAL") : counterpart("SUBSCRIPTION_DETAILS_FREQUENCY_TYPE_MONTHLY");
      case FrequencyType.Yearly:
        return frequency > 1 ? counterpart("SUBSCRIPTION_DETAILS_FREQUENCY_TYPE_YEARLY_PLURAL") : counterpart("SUBSCRIPTION_DETAILS_FREQUENCY_TYPE_YEARLY");
      default:
        return "";
    }
  };

  const canBePaused = useMemo(() => {
    if (status === SubscriptionStatusType.ACTIVE && cycleSkipCounter === 0
      || status === SubscriptionStatusType.PAUSED && cycleSkipCounter === 0) {
      return true
    }

    else if (status === SubscriptionStatusType.ACTIVE && cycleSkipCounter > 0
      || status === SubscriptionStatusType.PAUSED && cycleSkipCounter > 0) {
      return false
    }

    else {
      return true
    }
  }, [status, cycleSkipCounter])

  const canAddDiscount = useMemo(() => {
    if (discountList.getDiscountsList().length > 0) {
      const items = discountList.getDiscountsList();
      if (discounts.getDiscountsList().length > 0) {
        const appliedDiscounts = discounts.getDiscountsList();
        if (appliedDiscounts.filter(i => !(i.getDiscount() as Discount).getIsAllowedWithOtherDiscounts()).length > 0) {
          return false;
        }
        return items.filter(i => i.getIsAllowedWithOtherDiscounts()).length > 0;
      }
      return true;
    }

    return false;
  }, [discounts, discountList]);

  const handleUpdateSubscriptionModalCallback = async () => {
    setShowPauseSubscriptionModal(false);
    setShowResumeSubscriptionModal(false);
    await fetchData();
  };

  const handleCancelSubscriptionCallback = async () => {
    setShowCancelSubscriptionModal(false);
    await fetchData();
  };

  const handleUpdateAddOnCallback = async () => {
    setShowAddAddonModal(false);
    dispatch(setSubscriptionDetailsField("addOnToEdit", null));

    await fetchData();
    await dispatch(fetchSubscriptionAddOns(currentCompanyId as number, subscriptionId as number));
    history.push(`${match?.url}`)
  };

  const handleUpdateAllowanceCallback = async () => {
    setShowAllowanceModal(false);
    await fetchData();
    await dispatch(fetchSubscriptionAllowances(currentCompanyId as number, subscriptionId as number));
    history.push(`${match?.url}/allowances`)
  }

  const handleUpdateStatusCallback = async () => {
    setShowUpdateStatusModal(false);
    await fetchData();
    history.push(`${match?.url}`)
  }

  const handleUpdateBillingDateCallback = async () => {
    setShowChangeBillDateModal(false)
    await fetchData();
  };

  const handleAddDiscountCallback = async () => {
    await fetchData();
    await dispatch(fetchSubscriptionDiscounts(Number(authData.currentCompanyId), Number(subscriptionId)));
    setShowDiscountModal(false);
    history.push(`${match?.url}/discounts`);
  }

  const sideNav: ISideNavItems = {
    navItems: [
      {
        name: "edit-subscription",
        text: "SUBSCRIPTION_DETAILS_NAV_EDIT",
        icon: "fas fa-pencil-alt",
        callback: () => dispatch(setShowUpdateSubscriptionModal(true)),
        hasPermission: isSubscriptionEditPermitted
      },
      {
        name: "add-addon",
        text: "SUBSCRIPTION_DETAILS_NAV_ADD_ADD_ON",
        icon: "fas fa-layer-group",
        callback: () => setShowAddAddonModal(true),
        hasPermission: isSubscriptionAddAddonPermitted
      },
      {
        name: "add-allowance",
        text: "SUBSCRIPTION_DETAILS_NAV_ADD_ALLOWANCE",
        icon: "fas fa-tachometer-alt",
        callback: () => setShowAllowanceModal(true),
        hasPermission: isSubscriptionAddAllowancePermitted
      },
      {
        name: "add-discount",
        text: "SUBSCRIPTION_DETAILS_NAV_ADD_A_DISCOUNT",
        icon: "fas fa-ticket-alt",
        isEnabled: canAddDiscount,
        callback: () => setShowDiscountModal(true),
        hasPermission: isSubscriptionAddDiscountPermitted
      },
      // {
      //   name: 'replace-payment-method',
      //   text: 'SUBSCRIPTION_DETAILS_NAV_ADD_COUPON',
      //   icon: 'fas fa-money-check',
      // },
      // {
      //   name: 'capture-one-time-charge',
      //   text: 'SUBSCRIPTION_DETAILS_NAV_EXTEND_FREE_TRIAL',
      //   icon: 'fas fa-cash-register',
      // },
      // {
      //   name: 'clear-personal-data-gdpr',
      //   text: 'SUBSCRIPTION_DETAILS_NAV_ADJUST_NEXT_BILL',
      //   icon: 'fas fa-ghost',
      // },
      // {
      {
        name: "change-bill-date",
        text: "CHANGE_BILL_DATE",
        icon: "fas fa-calendar-day",
        className: "nav-item-change-bill-date",
        isEnabled: (status === SubscriptionStatusType.ACTIVE || status === SubscriptionStatusType.INTRIAL || status === SubscriptionStatusType.PAUSED),
        callback: () => setShowChangeBillDateModal(true),
        hasPermission: isSubscriptionChangeBillDatePermitted
      },
      {
        name: "update-status",
        text: "UPDATE_STATUS",
        icon: "fas fa-edit",
        className: "nav-item-update-status",
        isEnabled: (status === SubscriptionStatusType.ACTIVE || status === SubscriptionStatusType.SUSPENDED),
        callback: () => setShowUpdateStatusModal(true),
        hasPermission: isSubscriptionUpdateStatusPermitted
      },
      {
        name: "delete-customer",
        text: "SUBSCRIPTION_DETAILS_NAV_CANCEL_SUBSCRIPTION",
        icon: "fas fa-trash-alt",
        className: "nav-item-icon-red",
        callback: () => setShowCancelSubscriptionModal(true),
        hasPermission: isSubscriptionDeletionPermitted
      }
    ],
  };


  const fetchData = async () => {
    const params = match?.params as ISubscriberId;

    try {
      const { response } = await dispatch(fetchSubscriptionDetails(currentCompanyDomain, params.id));
      const allProducts = await API.fetchAllProducts(currentCompanyId) as { results: Array<IProductWithPlans> };
      // list of plans must contain all the plans even the cancelled ones
      let list = allProducts?.results.find(p => p.productId === response.productId)?.plans || [];
      //const list = (await API.fetchPlansByProduct(authData.currentCompanyDomain, response.productId)) as Array<IPlanWithCycles>;
      await dispatch(fetchCustomerDetails(currentCompanyDomain, response.customerUniqueId));
      await dispatch(fetchCurrencies());
      dispatch(fetchInvoiceCreditNotesFooter(currentCompanyId as number));
      dispatch(fetchCustomerCardDetails(currentCompanyId as number, response.customerUniqueId));
      await dispatch(fetchSubscriptionShippingAddress(currentCompanyDomain, response.subscriptionUniqueId));
      await dispatch(fetchDiscountsList(currentCompanyId as number, 1, undefined, 1000)); // Temporary solution
      await dispatch(fetchSubscriptionAllowances(currentCompanyId as number, response.subscriptionId));
      await dispatch(fetchSubscriptionAddOns(currentCompanyId as number, response.subscriptionId as number));
      await dispatch(fetchEventLogs(currentCompanyDomain, response.subscriptionUniqueId, 1, 6));
      setIsLoadingData(false);
      setPlansList(list);
    } catch (err) {
      setIsLoadingData(false);
      console.log(err);
    } finally {
      setDataIsLoaded(true);
    }
  };

  useEffect(() => {
    setIsLoadingData(true);
    fetchData();

    return function cleanup() {
      dispatch(resetSubscriptionDetails());
      dispatch(resetCustomerDetails());
    };
  }, []);

  useEffect(() => {
    const currentPlan = plansList.find((plan) => plan.planId === planId);
    setPlan(currentPlan);
    const cycle = currentPlan && currentPlan.cycles.find((cycle) => cycle.cycleId === cycleId);
    if (cycle) {
      // this ugly line of code below is needed because the cycle object returned by the get /products has a .priceModel field instead of a .pricingModel field
      (cycle as any).pricingModel = (cycle as any)?.priceModel || (cycle as any)?.pricingModel;
      setPriceSummary(getCycleDescription(cycle, false, currentPlan && currentPlan.pricingModelType));
    }
  }, [plansList, cycleId, planId]);

  if (isLoadingData) {
    return <ProgressIndicator color="blue" coverage="full-content" />;
  }

  if (!dataIsLoaded) {
    return null;
  }

  if (!subscriptionId) {
    return <NotFound />;
  }

  const renderNoticePanel = () => {
    if (status === SubscriptionStatusType.CANCELLED || status === SubscriptionStatusType.SUSPENDED) {
      return null
    }


    if (!!cycleIdNext || !!planIdNext || !!nextPlanPricingModel) {
      return (
        <NoticePanel
          icon="fas fa-check-circle"
          type="success"
          className="subscriptions_details__notice-panel"
          content={"UPDATE_SUBSCRIPTION_PLAN_SUCCESSFUL_NOTIFICATION"}
          translateWith={{
            nextBilling: `${formattedNextBillingDate} at ${formattedNextBillingTime}`,
            price: nextCycleRecurringBillingAmount,
            frequency: nextPlanPricingModel.frequency,
            type: getFrequencyTypeText(nextPlanPricingModel.frequency, nextPlanPricingModel.frequencyType),
          }}
        />
      );
    }

    if (eventLogs.results) {
      const log = eventLogs.results[0];

      if (log.type === EventLogType.UpdatedItemV1) {
        const eventData = log.eventData as IUpdatedItem;

        if (eventData.type === RelatedItemType.Addon) {
          const updatedAddOn = addOns.find(ao => ao.getId() === eventData.itemId);

          if (!updatedAddOn) {
            return
          }

          let addOnUnitValue = 0;

          if (updatedAddOn.getPricingModelType() !== PricingModelType.FLATFEE) {
            if (updatedAddOn.getQuantityNext()) {
              addOnUnitValue = (updatedAddOn.getQuantityNext() as Int32Value).getValue()
            } else {
              addOnUnitValue = updatedAddOn.getQuantity();
            }
          }

          return (
            <NoticePanel
              icon="fas fa-check-circle"
              type="success"
              className="subscriptions_details__notice-panel"
              content={"UPDATE_SUBSCRIPTION_ADD_ON_SUCCESSFUL"}
              translateWith={{
                addOnName: updatedAddOn.getName(),
                units: addOnUnitValue,
                nextBilling: `${formattedNextBillingDate} at ${formattedNextBillingTime}`,
              }}
            />
          )
        }
      }

      const changePlanLog = eventLogs.results.find(log => {
        const eventData = log.eventData as IPlanChangedEventLog;
        return eventData.fromPlanId !== eventData.toPlanId
      })

      if (changePlanLog && changePlanLog.type === EventLogType.PlanChangedV1) {
        const eventData = changePlanLog.eventData as IPlanChangedEventLog;
        const isChangedToday = eventData.toPlanId === planId;

        return (
          <NoticePanel
            icon="fas fa-check-circle"
            type="success"
            className="subscriptions_details__notice-panel"
            content={"UPDATE_SUBSCRIPTION_PLAN_SUCCESSFUL_NOTIFICATION2"}
            translateWith={{
              newPlan: eventData.toPlanName,
              nextBilling: isChangedToday ?
                `${moment(changePlanLog.createdOn).format("DD MMM YYYY")} at ${moment(changePlanLog.createdOn).format("HH:mm:ss Z")}` :
                `${formattedNextBillingDate} at ${formattedNextBillingTime}`,
            }}
          />
        );
      }

    }

    if (eventLogs.results) {
      const currentLog = eventLogs.results[0] && (eventLogs.results[0].eventData as IPlanChangedEventLog);

      if (currentLog.fromPlanName && currentLog.fromPlanName !== currentLog.toPlanName) {
        return (
          <NoticePanel
            icon="fas fa-check-circle"
            type="success"
            className="subscriptions_details__notice-panel"
            content={"UPDATE_SUBSCRIPTION_PLAN_SUCCESSFUL_NOTIFICATION2"}
            translateWith={{
              newPlan: currentLog.toPlanName,
              nextBilling: `${formattedNextBillingDate} at ${formattedNextBillingTime}`,
            }}
          />
        );
      }
    }

    return null;
  };

  return (
    <div>
      <NavigationBar
        pageTitle={
          <span>
            {counterpart("SUBSCRIPTION_DETAILS_NAV_SUBSCRIPTION_BELONGS_TO")}
            <Link to={`/customers/${customerUniqueId}`}>
              <Text content={customerFullname} className="subscriptions_details__customer" component="span" shouldTranslate={false} noMargin />
            </Link>
          </span>
        }
        shouldTranslatePageTitle={false}
        previousPageTitle="SUBSCRIPTIONS_DETAILS_SUBSCRIPTIONS"
        previousPageCb={() => history.push("/subscriptions")}
      />
      {renderNoticePanel()}
      <PageWrapper>
        <div className="subscriptions_details">
          <div className="subscriptions_details__content">
            <div>
              <SubscriptionStatusBar />
              <Table>
                <tbody>
                  <tr>
                    <Text content="SUBSCRIPTIONS_DETAILS_PLAN_PRICE" noMargin component="td" className="subscription_detail__label-column" />
                    <Text
                      translateWith={{
                        priceSummary,
                      }}
                      content="SUBSCRIPTION_DETAILS_PLAN_PRICE"
                      component="td"
                      className="subscription_detail__value-column mb0"
                    />
                  </tr>
                  <tr>
                    <Text content="SUBSCRIPTIONS_DETAILS_NEXT_BILL" noMargin component="td" className="subscription_detail__label-column" />
                    <Text
                      content={status === SubscriptionStatusType.CANCELLED ? "-" : `${nextBillingAmountFormatted} on ${formattedNextBillingDate} at ${formattedNextBillingTime}`}
                      shouldTranslate={false}
                      component="td"
                      className="subscription_detail__value-column mb0"
                    />
                  </tr>
                  <tr>
                    <Text content="SUBSCRIPTIONS_DETAILS_SUBSCRIPTION_ID" noMargin component="td" className="subscription_detail__label-column" />
                    <Text content={`${subscriptionUniqueId}`} shouldTranslate={false} noMargin component="td" className="subscription_detail__value-column" />
                  </tr>
                </tbody>
              </Table>
            </div>
          </div>
          <div className={`subscriptions_details__nav ${status === SubscriptionStatusType.CANCELLED ? "subscriptions_details__nav--cancelled" : ""}`}>
            <SideNavBar navItems={sideNav.navItems} />
          </div>
        </div>
        <SubscriptionDetailsTab />
      </PageWrapper>
      <Modal isOpen={showCancelSubscriptionModal} onRequestClose={() => setShowCancelSubscriptionModal(false)} title="CANCELING_SUBSCRIPTION_TITLE">
        <CancelSubscription handleCancelSubscriptionCallback={handleCancelSubscriptionCallback} />
      </Modal>

      <Modal
        title="UPDATE_SUBSCRIPTION_MODAL_TITLE"
        isOpen={showSubscriptionUpdateModal}
        onRequestClose={() => dispatch(setShowUpdateSubscriptionModal(false))}
        children={<UpdateSubscription />}
      />
      <Modal
        title="UPDATE_SUBSCRIPTION_SHIPPING_ADDRESS_MODAL_TITLE"
        isOpen={showShippingAddressUpdateModal}
        onRequestClose={() => dispatch(setShowShippingAddressUpdateModal(false))}
        children={<UpdateShippingAddress />}
      />
      <Modal
        title="UPDATE_SUBSCRIPTION_ADD_ON_MODAL_TITLE"
        isOpen={showAddAddonModal}
        onRequestClose={() => setShowAddAddonModal(false)}
        children={<UpdateAddOn handleCallback={handleUpdateAddOnCallback} />}
      />
      <Modal
        title="UPDATE_SUBSCRIPTION_EDIT_ADD_ON_MODAL_TITLE"
        isOpen={!!addOnToEdit}
        onRequestClose={() => dispatch(setSubscriptionDetailsField("addOnToEdit", null))}
        children={<UpdateAddOn addOnId={addOnToEdit} handleCallback={handleUpdateAddOnCallback} />}
      />
      <Modal
        title="UPDATE_SUBSCRIPTION_ALLOWANCE_MODAL_TITLE"
        isOpen={showAllowanceModal}
        onRequestClose={() => setShowAllowanceModal(false)}
        children={<UpdateAllowance handleCallback={handleUpdateAllowanceCallback} />}
      />
      <Modal
        title="ADD_SUBSCRIPTION_DISCOUNT_MODAL_TITLE"
        isOpen={showDiscountModal}
        onRequestClose={() => setShowDiscountModal(false)}
        children={<SubscriptionAddDiscount
          plan={plan as IPlanWithCycles}
          handleCallback={handleAddDiscountCallback} />}
      />

      <Modal
        title="CHANGE_BILL_DATE"
        className="subscriptions_details__change-bill-date"
        isOpen={showChangeBillDateModal}
        onRequestClose={() => setShowChangeBillDateModal(false)}
        children={<ChangeBillDate handleCallback={handleUpdateBillingDateCallback} />}
      />

      <Modal
        title="UPDATE_STATUS"
        className="subscriptions_details__update-status"
        isOpen={showUpdateStatusModal}
        onRequestClose={() => setShowUpdateStatusModal(false)}
        children={<SubscriptionUpdateStatus handleCallback={handleUpdateStatusCallback} />}
      />

      <Modal isOpen={showDeleteDiscountModal} onRequestClose={() => {
        dispatch(setShowDeleteDiscountModal(false));
        dispatch(setDeleteDiscount(undefined));
        setIsRemovingDiscount(false);
      }} title="SUBSCRIPTION_DISCOUNTS_LIST_REMOVE_DISCOUNT_MODAL_TITLE">
        <>
          <Panel title="SUBSCRIPTION_DISCOUNTS_LIST_REMOVE_DISCOUNT_MODAL_PANEL_TITLE">
            <Text content='SUBSCRIPTION_DISCOUNTS_LIST_REMOVE_DISCOUNT_MODAL_PANEL_TEXT_1'
              translateWith={{ discount: discountToDelete ? (discountToDelete.getDiscount() as Discount).getName() : "" }} noMargin />
          </Panel>

          <Button
            id="remove-discount-btn"
            title="SUBSCRIPTION_DISCOUNTS_LIST_REMOVE_DISCOUNT_MODAL_BUTTON_TEXT"
            buttonType="general"
            isFullWidth={true}
            isLoading={isRemovingDiscount}
            onClick={() => {
              const discountToDeleteId = discountToDelete ? discountToDelete.getSubscriptionDiscountId() : undefined;
              dispatch(removeSubscriptionDiscount(Number(authData.currentCompanyId), Number(subscriptionId), Number(discountToDeleteId), !!authData.parsedToken ? Number(authData.parsedToken.nameid) : 0))
                .then((res: any) => {
                  dispatch(setShowDeleteDiscountModal(false));
                  dispatch(setDeleteDiscount(undefined));
                  setIsRemovingDiscount(false);
                  dispatch(fetchSubscriptionDiscounts(Number(authData.currentCompanyId), Number(subscriptionId)));
                })
                .catch((err: any) => console.log(err))
            }}
          />
        </>
      </Modal>
      <ErrorNotification showAlert={pauseSubscriptionError || resumeSubscriptionError} title={pauseSubscriptionError ? "PAUSE_SUBSCRIPTION_ERROR" : "RESUME_SUBSCRIPTION_ERROR"}></ErrorNotification>
    </div>
  );
};

export default SubscriptionDetails;
