import React, { FormEvent, useLayoutEffect, useMemo, useState } from "react";
import { AppState } from "../../../../..";
import { useDispatch, useSelector } from "react-redux";
import CollapsablePanelWithArrow from "../../../../ui/collapsable-panel-with-arrow/CollapsablePanelWithArrow";
import Panel from "../../../../ui/panel/Panel";
import Text from "../../../../ui/text/Text";
import Input from "../../../../ui/input/Input";
import FormLabel from "../../../../ui/form-label/FormLabel";
import FormGroup from "../../../../ui/form-group/FormGroup";
import { Col, Row } from "react-grid-system";
import Button from "../../../../ui/button/Button";
import Table from "../../../../ui/table/Table";
import WebhookTestPanel from "./WebhookTestPanel";
import {
  setRespondingToWebhooksCollapsed,
  setWebhooksDetailsField,
  resetWebhooks,
  fetchCompanyWebhookSetup,
  updateCompanyWebhookSetup,
  removeCompanyWebhookSetup
} from "../../../../../actions/companyWebhooksActions";
import "./Webhooks.scss";
import ErrorPanel from "../../../../ui/error-panel/ErrorPanel";
import ProgressIndicator from "../../../../ui/progress-indicator/ProgressIndicator";
import { WebhookType } from "../../../../../utils/grpc/generated/Billsby.Protos/webhooks/private/webhooktests/webhook_tests_pb";
import { Permission } from "../../../../../models/Auth";
import NoticePanel from "../../../../ui/notice-panel/NoticePanel";
import { Link } from "react-router-dom";
import { setShowBillingModal } from "../../../../../actions/authActions";
import counterpart from "counterpart";


const Webhooks: React.FC = () => {
  const dispatch = useDispatch<Function>();
  const companyWebhooksReducer = useSelector((state: AppState) => state.companyWebhooksReducer);
  const { currentCompanyDomain, parsedToken } = useSelector((state: AppState) => state.auth);
  
  const { url, secret, isCollapsedRespondingToWebhooks } = companyWebhooksReducer;
  const [state, setState] = useState({
    isLoadingData: false,
    hasError: false,
    errorMessage: "",
    success: false,
    isLoading: false
  });

  const hasPermission = useMemo(() => {
    if(parsedToken?.CurrentPermissions.some(p => p === Permission[Permission.WebhookConfigRead])) {
      return true
    }
    return false
  }, [parsedToken])

  const fetchData = async () => {
    setState((prevState) => { return { ...prevState, isLoadingData: true }});
    try {
      await dispatch(fetchCompanyWebhookSetup(currentCompanyDomain));
    } finally {
      setState((prevState) => { return { ...prevState, isLoadingData: false }});
    }
  }

  useLayoutEffect(() => {
    fetchData();
    return () => {
      dispatch(resetWebhooks());
    };
  }, [])

  const renderWebhookTable = () => {
    const webhooksData = [
      { title: "CONFIG_WEBHOOKS_CUSTOMER_CREATED", content: "CONFIG_WEBHOOKS_CUSTOMER_CREATED_CONTENT", apiUrl: "", type: WebhookType.CUSTOMER_CREATED },
      { title: "CONFIG_WEBHOOKS_CUSTOMER_CHANGED", content: "CONFIG_WEBHOOKS_CUSTOMER_CHANGED_CONTENT", apiUrl: "", type: WebhookType.CUSTOMER_CHANGED },
      { title: "CONFIG_WEBHOOKS_CUSTOMER_DELETED", content: "CONFIG_WEBHOOKS_CUSTOMER_DELETED_CONTENT", apiUrl: "", type: WebhookType.CUSTOMER_DELETED },
      { title: "CONFIG_WEBHOOKS_SUBSCRIPTION_CREATED", content: "CONFIG_WEBHOOKS_SUBSCRIPTION_CREATED_CONTENT", apiUrl: "", type: WebhookType.SUBSCRIPTION_CREATED },
      { title: "CONFIG_WEBHOOKS_SUBSCRIPTION_UPDATED", content: "CONFIG_WEBHOOKS_SUBSCRIPTION_UPDATED_CONTENT", apiUrl: "", type: WebhookType.SUBSCRIPTION_UPDATED },
      { title: "CONFIG_WEBHOOKS_INVOICE_CREATED", content: "CONFIG_WEBHOOKS_INVOICE_CREATED_CONTENT", apiUrl: "", type: WebhookType.INVOICE_CREATED },
      { title: "CONFIG_WEBHOOKS_INVOICE_UPDATED", content: "CONFIG_WEBHOOKS_INVOICE_UPDATED_CONTENT", apiUrl: "", type: WebhookType.INVOICE_UPDATED },
      { title: "CONFIG_WEBHOOKS_CREDIT_NOTE_CREATED", content: "CONFIG_WEBHOOKS_CREDIT_NOTE_CREATED_CONTENT", apiUrl: "", type: WebhookType.CREDIT_NOTE_CREATED },
      { title: "CONFIG_WEBHOOKS_CREDIT_NOTE_UPDATED", content: "CONFIG_WEBHOOKS_CREDIT_NOTE_UPDATED_CONTENT", apiUrl: "", type: WebhookType.CREDIT_NOTE_UPDATED }
    ];

    return (
      <Table className="webhooks__table">
        <tbody>
          {webhooksData.map((data, idx) => {
            return (
              <tr key={idx}>
                <td>
                  <WebhookTestPanel data={data} />
                </td>
              </tr>
            );
          })}
        </tbody>
      </Table>
    );
  };

  const handleCancelWebhooks = async () => {
    setState((prevState) => { return { ...prevState, hasError: false }});
    try {
      dispatch(resetWebhooks());
      await dispatch(fetchCompanyWebhookSetup(currentCompanyDomain));
    } catch (err) {
      console.log(err);
      setState((prevState) => { return { ...prevState, hasError: true }});
    }
  };

  const onSubmit = async (evt: FormEvent) => {
    evt.preventDefault();
    evt.stopPropagation();

    setState((prevState) => { return { ...prevState, isLoading: true }});

    try {
      const response = await (url ? dispatch(updateCompanyWebhookSetup(currentCompanyDomain, { targetUrl: url, secret })) : dispatch(removeCompanyWebhookSetup(currentCompanyDomain)));
      setState((prevState) => { return { ...prevState, isLoading: false }});

      if (!response.ok && response.error) {
        setState((prevState) => { return { ...prevState, isLoading: false, hasError: true, errorMessage: response.error.list[0].description }});
      }
    } catch (err) {
      setState((prevState) => { return { ...prevState, isLoading: false, hasError: true }});
    }
  };


  if (state.isLoadingData) {
    return <ProgressIndicator color="blue"></ProgressIndicator>
  }

  if(!hasPermission) {
    return (
      <NoticePanel
        type="warning"
        icon="far fa-lightbulb-exclamation"
        content="CONFIG_WEBHOOKS_WARNING_PERMISSIONS"
        translateWith={{
          here: <Link to="" id="billing-modal-link" onClick={(evt) => { dispatch(setShowBillingModal(true)); evt.preventDefault();}}>{counterpart("REPORTS_ACCOUNTING_WARNING_PERMISSION_HERE")}</Link>
        }}
      />
    )
  }

  return (
    <div className="webhooks">
      <Panel title="CONFIG_WEBHOOKS_PANEL1_TITLE">
        <form onSubmit={onSubmit}>
          <FormGroup>
            <Row align="center">
              <Col md={1.5}>
                <FormLabel target="webhook-url" content="CONFIG_WEBHOOKS_URL" />
              </Col>
              <Col md={10.5}>
                <Input
                  isError={state.hasError}
                  errorMsg={state.errorMessage}
                  id="webhook-url"
                  disabled={state.isLoading}
                  value={url}
                  onChange={(evt: any) => {
                    setState((prevState) => { return { ...prevState, hasError: false }});
                    dispatch(setWebhooksDetailsField("url", evt.target.value));
                  }}
                />
              </Col>
            </Row>
          </FormGroup>
          <Text content="CONFIF_WEBHOOKS_URL_CONTENT"></Text>
          <FormGroup>
            <Row align="center">
              <Col md={1.5}>
                <FormLabel target="webhook-secret-key" content="CONFIG_WEBHOOKS_SECRET" />
              </Col>
              <Col md={10.5}>
                <Input id="webhook-secret-key" disabled={state.isLoading} value={secret} onChange={(evt: any) => dispatch(setWebhooksDetailsField("secret", evt.target.value))} />
              </Col>
            </Row>
          </FormGroup>
          <Row align="center">
            <Col md={9}>
              <Button disabled={state.isLoadingData} id="webhook-update-details" type="submit" isLoading={state.isLoading} isFullWidth buttonType="general" title="CONFIG_WEBHOOKS_UPDATE_WEBHOOK_DETAILS" />
            </Col>
            <Col md={3}>
              <Button disabled={state.isLoadingData} id="webhook-cancel" isFullWidth buttonType="error" title="CONFIG_WEBHOOKS_CANCEL" onClick={handleCancelWebhooks} />
            </Col>
          </Row>
        </form>
      </Panel>

      <Panel title="CONFIG_WEBHOOKS_PANEL2_TITLE">
        <Text content="CONFIG_WEBHOOKS_PANEL2_CONTENT"></Text>
        {renderWebhookTable()}
      </Panel>

      <ErrorPanel title="CONFIG_WEBHOOKS_PANEL3_TITLE" subtitle="CONFIG_WEBHOOKS_PANEL3_CONTENT" icon="fas fa-wrench" />

      <CollapsablePanelWithArrow title="CONFIG_WEBHOOKS_PANEL4_TITLE" isCollapsed={isCollapsedRespondingToWebhooks} onClick={() => dispatch(setRespondingToWebhooksCollapsed())}>
        <Text content="CONFIG_WEBHOOKS_PANEL4_CONTENT1" />
        <Text content="CONFIG_WEBHOOKS_PANEL4_CONTENT2" noMargin />
      </CollapsablePanelWithArrow>
    </div>
  );
}

export default Webhooks;
