import { BillsbyAction } from "../models/BillsbyAction";
import { AppState } from "..";
import { FETCH_REPLY_ADDRESS_SUCCESS, SET_EMAIL_ADDRESS_FIELD, RESET_EMAIL_ROUTING, RESET_REPLY_ADDRESS, FETCH_EMAIL_NOTIFICATION_TOGGLES_SUCCESS } from "../actions/emailRoutingActions";
import { GetEmailNotificationStatusResponse } from "../utils/grpc/generated/Billsby.Protos/Messenger/private/companyconfig/companyconfig_pb";

export interface IEmailRoutingReducerState {
  forwardingEmailAddress: string,
  emailNotificationToggles: GetEmailNotificationStatusResponse
}

let initialForwardingEmailAddress: string = "";
export const initialState: IEmailRoutingReducerState = {
  forwardingEmailAddress: "",
  emailNotificationToggles: new GetEmailNotificationStatusResponse()
}

export default function emailRoutingReducer(state: IEmailRoutingReducerState = initialState, action: BillsbyAction, store: AppState) {
  switch(action.type){
    case FETCH_REPLY_ADDRESS_SUCCESS:
        initialForwardingEmailAddress = action.response;
      return { ...state, forwardingEmailAddress: initialForwardingEmailAddress }
    case SET_EMAIL_ADDRESS_FIELD: 
      return { ...state, [action.fieldName]: action.fieldValue }
    case RESET_REPLY_ADDRESS:
      return { ...state, forwardingEmailAddress: initialForwardingEmailAddress }
    case FETCH_EMAIL_NOTIFICATION_TOGGLES_SUCCESS:
      return { ...state, emailNotificationToggles: action.response }
    case RESET_EMAIL_ROUTING:
      return {...initialState}
    default: 
      return state
  }
}