import React from "react";
import "./EmailPreivewContainer.scss";
import Text from "../../../../ui/text/Text";
import { ICompanyBrandingReducerState } from "../../../../../reducers/companyBrandingReducer";
import CheckoutRectanglePanel from "../checkout-preview/checkout-rectangle-panel/CheckoutRectanglePanel";
import billsbyLogo from "../../../../../images/billsby-logo-black.svg";
import defaultLogo from "../../../../../images/billsby-logo.svg";
import { useSelector } from "react-redux";
import { AppState } from "../../../../..";

interface IEmailPreviewProps {
  children: any;
  className?: string;
}

const EmailPreivewContainer: React.FC<IEmailPreviewProps> = ({ children = null, className = "" }) => {
  const { logoUrl, logoBackgroundColor } = useSelector<AppState, ICompanyBrandingReducerState>(state => state.companyBrandingReducer);
  const defaultLogoBackgroundColor = "#1E2432";
  return (
    <>
      <CheckoutRectanglePanel className={`email-preview__container ${className}`}>
        <div className="email-preview__header" style={{ backgroundColor: !!logoBackgroundColor ? logoBackgroundColor : defaultLogoBackgroundColor }}>
          <img src={!!logoUrl ? logoUrl : defaultLogo} alt="no logo" className="email-preview__header__logo" />
        </div>
        <div className="email-preview__content">
          <div className="email-preview__details">{children}</div>
        </div>
        <div className="email-preview__footer">
          {/* <i className="fas fa-lock" /> */}
          <Text className="email-preview__footer__text" content="BRAND_CUSTOMISATION_PAGES_PREVIEW_FOOTER_TEXT" noMargin />
          <img src={billsbyLogo} alt="Billsby" className="email-preview__footer__logo" />
        </div>
      </CheckoutRectanglePanel>
    </>
  );
};

export default EmailPreivewContainer;
