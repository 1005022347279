import React from "react";
import history from "../../../../../../utils/history";
import BackNavigationPanel from "../../../../../ui/back-navigation-panel/BackNavigationPanel";
import MenuTable from "../../../../../ui/menu-table/MenuTable";

interface Props {

}

const OneTimePasscodeMenu: React.FC<Props> = () => {
  return (
    <div>
      <BackNavigationPanel
        title={"EMAIL_TEMPLATE_ONE_TIME_PASSCODE_BACK_PANEL_TITLE"} shouldTranslateTitle={true}
        onClick={() => history.push("/configuration/email-notifications/emails")}>
        <MenuTable
          data={[
            {
              title: "EMAIL_TEMPLATE_ONE_TIME_PASSCODE_TEMPLATE_BACK_PANEL_TITLE",
              onClick: () => history.push("/configuration/email-notifications/emails/one-time-passcode/template")
            }
          ]}
        />
      </BackNavigationPanel>
    </div>
  )
}

export default OneTimePasscodeMenu
