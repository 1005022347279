import { BillsbyAction } from "../models/BillsbyAction";
import { AppState } from "..";
import { RESET_DASHBOARD_STATE, FETCH_DASHBOARD_CHARTS_DATA_SUCCESS, FETCH_DASHBOARD_RECENT_DATA_SUCCESS, FETCH_DASHBOARD_CURRENT_DATA_SUCCESS, FETCH_DASHBOARD_NEW_CURRENT_DATA_SUCCESS } from "../actions/dashboardActions";
import { IDashboardChartData, IDashboardRecentData, IDashboardCurrentData } from "../models/Dashboard";
import { DashboardHeader, GetDashboardResponse } from "../utils/grpc/generated/Billsby.Protos/dashboard/private/dashboard/dashboard_pb";

export interface IDashboardReducer {
  chartData?: IDashboardChartData,
  recentData?: IDashboardRecentData,
  currentData?: IDashboardCurrentData
  newCurrentData?: DashboardHeader
}

const initialState = {

}

const formatValue = (val: number | null) => {
  return val ? val / 100 : val;
}

export default function dashboardReducer(state: IDashboardReducer = initialState, action: BillsbyAction, store: AppState) {
  switch (action.type) {
    case FETCH_DASHBOARD_CHARTS_DATA_SUCCESS:
      return { ...state, chartData: action.response }
    case FETCH_DASHBOARD_RECENT_DATA_SUCCESS: {
      const recentData: IDashboardRecentData = action.response;
      recentData.customers.percentageChanged = formatValue(recentData.customers.percentageChanged);
      recentData.revenueMonth.expectedValue = formatValue(recentData.revenueMonth.expectedValue) || 0;
      recentData.revenueMonth.lastMonthsValue = formatValue(recentData.revenueMonth.lastMonthsValue);
      recentData.revenueMonth.percentageChanged = formatValue(recentData.revenueMonth.percentageChanged);
      recentData.revenueMonth.todaysValue = formatValue(recentData.revenueMonth.todaysValue) || 0;

      recentData.revenueToday.expectedValue = formatValue(recentData.revenueToday.expectedValue) || 0;
      recentData.revenueToday.lastMonthsValue = formatValue(recentData.revenueToday.lastMonthsValue);
      recentData.revenueToday.percentageChanged = formatValue(recentData.revenueToday.percentageChanged);
      recentData.revenueToday.todaysValue = formatValue(recentData.revenueToday.todaysValue) || 0;

      recentData.signUps.percentageChanged = formatValue(recentData.signUps.percentageChanged) || 0;
      return { ...state, recentData }
    }
    case FETCH_DASHBOARD_CURRENT_DATA_SUCCESS: {
      const currentData: IDashboardCurrentData = action.response;
      currentData.mrrValue = formatValue(currentData.mrrValue) || 0;
      currentData.netPaymentsValue = formatValue(currentData.netPaymentsValue) || 0;
      currentData.netRevValue = formatValue(currentData.netRevValue) || 0;
      currentData.unpaidInvoicesAmount = formatValue(currentData.unpaidInvoicesAmount) || 0;
      return { ...state, currentData }
    }
    case FETCH_DASHBOARD_NEW_CURRENT_DATA_SUCCESS: {
      const dashboardResponse = action.response as GetDashboardResponse;
      return {
        ...state,
        newCurrentData: dashboardResponse.getHeader()
      }
    }
    case RESET_DASHBOARD_STATE:
      return { ...initialState }
    default:
      return state;
  }
}