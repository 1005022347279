import React from "react";
import { Link } from "react-router-dom"
import counterpart from "counterpart";

import "./NotFound.scss";
import Panel from "../../components/ui/panel/Panel";
import Text from "../../components/ui/text/Text";
import { useSelector } from "react-redux";
import { AppState } from "../..";

interface INotFound {
  message?: string
}

const NotFound: React.FC<INotFound> = ({ message }) => {
  const isAuth = useSelector((state: AppState) => !!state.auth.parsedToken);
  return (
    <div className={`error-404 ${isAuth ? "error-404--auth" : ""}`}>
      <Panel className="error-404__panel" >
        <Text content="NOT_FOUND_CODE" />
        <Text content={message || "NOT_FOUND_TEXT"} />
        {!isAuth &&
          <p className="cc-text">
            <Link id="not-found-login" to="/login">{counterpart("NOT_FOUND_LOGIN")}</Link>
          </p>}
        {!isAuth &&
          <p className="cc-text">
            <Link id="not-found-registration" to="/registration">{counterpart("NOT_FOUND_REGISTER")}</Link>
          </p>}
      </Panel>
    </div>
  )
}

export default NotFound;