/* eslint-disable */
// source: retention_step.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!

var jspb = require('google-protobuf');
var goog = jspb;
var global = Function('return this')();

goog.exportSymbol('proto.core.private.companyretentionstep.FrequencyType', null, global);
goog.exportSymbol('proto.core.private.companyretentionstep.GetDiscountOfferRequest', null, global);
goog.exportSymbol('proto.core.private.companyretentionstep.GetDiscountOfferResponse', null, global);
goog.exportSymbol('proto.core.private.companyretentionstep.GetDiscountOfferResponse.ConfigurationCase', null, global);
goog.exportSymbol('proto.core.private.companyretentionstep.GetDiscountOfferResponse.DiscountOffer', null, global);
goog.exportSymbol('proto.core.private.companyretentionstep.GetDiscountOfferResponse.DiscountOffer.EligibilityCase', null, global);
goog.exportSymbol('proto.core.private.companyretentionstep.GetDiscountOfferResponse.DiscountOffer.MinimumTenure', null, global);
goog.exportSymbol('proto.core.private.companyretentionstep.GetHelpInterruptRequest', null, global);
goog.exportSymbol('proto.core.private.companyretentionstep.GetHelpInterruptResponse', null, global);
goog.exportSymbol('proto.core.private.companyretentionstep.GetPlanChangeRequest', null, global);
goog.exportSymbol('proto.core.private.companyretentionstep.GetPlanChangeResponse', null, global);
goog.exportSymbol('proto.core.private.companyretentionstep.GetReasonsToStayRequest', null, global);
goog.exportSymbol('proto.core.private.companyretentionstep.GetReasonsToStayResponse', null, global);
goog.exportSymbol('proto.core.private.companyretentionstep.GetRetentionStepsRequest', null, global);
goog.exportSymbol('proto.core.private.companyretentionstep.GetRetentionStepsResponse', null, global);
goog.exportSymbol('proto.core.private.companyretentionstep.GetTimeRemainingRequest', null, global);
goog.exportSymbol('proto.core.private.companyretentionstep.GetTimeRemainingResponse', null, global);
goog.exportSymbol('proto.core.private.companyretentionstep.RetentionStepListItem', null, global);
goog.exportSymbol('proto.core.private.companyretentionstep.SetDiscountOfferRequest', null, global);
goog.exportSymbol('proto.core.private.companyretentionstep.SetDiscountOfferRequest.ConfigurationCase', null, global);
goog.exportSymbol('proto.core.private.companyretentionstep.SetDiscountOfferRequest.DiscountOffer', null, global);
goog.exportSymbol('proto.core.private.companyretentionstep.SetDiscountOfferRequest.DiscountOffer.EligibilityCase', null, global);
goog.exportSymbol('proto.core.private.companyretentionstep.SetDiscountOfferRequest.DiscountOffer.MinimumTenure', null, global);
goog.exportSymbol('proto.core.private.companyretentionstep.SetDiscountOfferResponse', null, global);
goog.exportSymbol('proto.core.private.companyretentionstep.SetDiscountOfferResponse.ConfigurationCase', null, global);
goog.exportSymbol('proto.core.private.companyretentionstep.SetDiscountOfferResponse.DiscountOffer', null, global);
goog.exportSymbol('proto.core.private.companyretentionstep.SetDiscountOfferResponse.DiscountOffer.EligibilityCase', null, global);
goog.exportSymbol('proto.core.private.companyretentionstep.SetDiscountOfferResponse.DiscountOffer.MinimumTenure', null, global);
goog.exportSymbol('proto.core.private.companyretentionstep.UpdateRetentionStepsRequest', null, global);
goog.exportSymbol('proto.core.private.companyretentionstep.UpdateRetentionStepsResponse', null, global);
goog.exportSymbol('proto.core.private.companyretentionstep.UpsertHelpInterruptRequest', null, global);
goog.exportSymbol('proto.core.private.companyretentionstep.UpsertHelpInterruptResponse', null, global);
goog.exportSymbol('proto.core.private.companyretentionstep.UpsertPlanChangeRequest', null, global);
goog.exportSymbol('proto.core.private.companyretentionstep.UpsertPlanChangeResponse', null, global);
goog.exportSymbol('proto.core.private.companyretentionstep.UpsertReasonsToStayRequest', null, global);
goog.exportSymbol('proto.core.private.companyretentionstep.UpsertReasonsToStayResponse', null, global);
goog.exportSymbol('proto.core.private.companyretentionstep.UpsertTimeRemainingRequest', null, global);
goog.exportSymbol('proto.core.private.companyretentionstep.UpsertTimeRemainingResponse', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.core.private.companyretentionstep.SetDiscountOfferRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.core.private.companyretentionstep.SetDiscountOfferRequest.oneofGroups_);
};
goog.inherits(proto.core.private.companyretentionstep.SetDiscountOfferRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.core.private.companyretentionstep.SetDiscountOfferRequest.displayName = 'proto.core.private.companyretentionstep.SetDiscountOfferRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.core.private.companyretentionstep.SetDiscountOfferRequest.DiscountOffer = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.core.private.companyretentionstep.SetDiscountOfferRequest.DiscountOffer.oneofGroups_);
};
goog.inherits(proto.core.private.companyretentionstep.SetDiscountOfferRequest.DiscountOffer, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.core.private.companyretentionstep.SetDiscountOfferRequest.DiscountOffer.displayName = 'proto.core.private.companyretentionstep.SetDiscountOfferRequest.DiscountOffer';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.core.private.companyretentionstep.SetDiscountOfferRequest.DiscountOffer.MinimumTenure = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.core.private.companyretentionstep.SetDiscountOfferRequest.DiscountOffer.MinimumTenure, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.core.private.companyretentionstep.SetDiscountOfferRequest.DiscountOffer.MinimumTenure.displayName = 'proto.core.private.companyretentionstep.SetDiscountOfferRequest.DiscountOffer.MinimumTenure';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.core.private.companyretentionstep.SetDiscountOfferResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.core.private.companyretentionstep.SetDiscountOfferResponse.oneofGroups_);
};
goog.inherits(proto.core.private.companyretentionstep.SetDiscountOfferResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.core.private.companyretentionstep.SetDiscountOfferResponse.displayName = 'proto.core.private.companyretentionstep.SetDiscountOfferResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.core.private.companyretentionstep.SetDiscountOfferResponse.DiscountOffer = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.core.private.companyretentionstep.SetDiscountOfferResponse.DiscountOffer.oneofGroups_);
};
goog.inherits(proto.core.private.companyretentionstep.SetDiscountOfferResponse.DiscountOffer, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.core.private.companyretentionstep.SetDiscountOfferResponse.DiscountOffer.displayName = 'proto.core.private.companyretentionstep.SetDiscountOfferResponse.DiscountOffer';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.core.private.companyretentionstep.SetDiscountOfferResponse.DiscountOffer.MinimumTenure = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.core.private.companyretentionstep.SetDiscountOfferResponse.DiscountOffer.MinimumTenure, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.core.private.companyretentionstep.SetDiscountOfferResponse.DiscountOffer.MinimumTenure.displayName = 'proto.core.private.companyretentionstep.SetDiscountOfferResponse.DiscountOffer.MinimumTenure';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.core.private.companyretentionstep.GetDiscountOfferRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.core.private.companyretentionstep.GetDiscountOfferRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.core.private.companyretentionstep.GetDiscountOfferRequest.displayName = 'proto.core.private.companyretentionstep.GetDiscountOfferRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.core.private.companyretentionstep.GetDiscountOfferResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.core.private.companyretentionstep.GetDiscountOfferResponse.oneofGroups_);
};
goog.inherits(proto.core.private.companyretentionstep.GetDiscountOfferResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.core.private.companyretentionstep.GetDiscountOfferResponse.displayName = 'proto.core.private.companyretentionstep.GetDiscountOfferResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.core.private.companyretentionstep.GetDiscountOfferResponse.DiscountOffer = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.core.private.companyretentionstep.GetDiscountOfferResponse.DiscountOffer.oneofGroups_);
};
goog.inherits(proto.core.private.companyretentionstep.GetDiscountOfferResponse.DiscountOffer, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.core.private.companyretentionstep.GetDiscountOfferResponse.DiscountOffer.displayName = 'proto.core.private.companyretentionstep.GetDiscountOfferResponse.DiscountOffer';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.core.private.companyretentionstep.GetDiscountOfferResponse.DiscountOffer.MinimumTenure = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.core.private.companyretentionstep.GetDiscountOfferResponse.DiscountOffer.MinimumTenure, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.core.private.companyretentionstep.GetDiscountOfferResponse.DiscountOffer.MinimumTenure.displayName = 'proto.core.private.companyretentionstep.GetDiscountOfferResponse.DiscountOffer.MinimumTenure';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.core.private.companyretentionstep.UpsertHelpInterruptRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.core.private.companyretentionstep.UpsertHelpInterruptRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.core.private.companyretentionstep.UpsertHelpInterruptRequest.displayName = 'proto.core.private.companyretentionstep.UpsertHelpInterruptRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.core.private.companyretentionstep.UpsertHelpInterruptResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.core.private.companyretentionstep.UpsertHelpInterruptResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.core.private.companyretentionstep.UpsertHelpInterruptResponse.displayName = 'proto.core.private.companyretentionstep.UpsertHelpInterruptResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.core.private.companyretentionstep.GetHelpInterruptRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.core.private.companyretentionstep.GetHelpInterruptRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.core.private.companyretentionstep.GetHelpInterruptRequest.displayName = 'proto.core.private.companyretentionstep.GetHelpInterruptRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.core.private.companyretentionstep.GetHelpInterruptResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.core.private.companyretentionstep.GetHelpInterruptResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.core.private.companyretentionstep.GetHelpInterruptResponse.displayName = 'proto.core.private.companyretentionstep.GetHelpInterruptResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.core.private.companyretentionstep.UpsertPlanChangeRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.core.private.companyretentionstep.UpsertPlanChangeRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.core.private.companyretentionstep.UpsertPlanChangeRequest.displayName = 'proto.core.private.companyretentionstep.UpsertPlanChangeRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.core.private.companyretentionstep.UpsertPlanChangeResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.core.private.companyretentionstep.UpsertPlanChangeResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.core.private.companyretentionstep.UpsertPlanChangeResponse.displayName = 'proto.core.private.companyretentionstep.UpsertPlanChangeResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.core.private.companyretentionstep.GetPlanChangeRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.core.private.companyretentionstep.GetPlanChangeRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.core.private.companyretentionstep.GetPlanChangeRequest.displayName = 'proto.core.private.companyretentionstep.GetPlanChangeRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.core.private.companyretentionstep.GetPlanChangeResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.core.private.companyretentionstep.GetPlanChangeResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.core.private.companyretentionstep.GetPlanChangeResponse.displayName = 'proto.core.private.companyretentionstep.GetPlanChangeResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.core.private.companyretentionstep.UpsertReasonsToStayRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.core.private.companyretentionstep.UpsertReasonsToStayRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.core.private.companyretentionstep.UpsertReasonsToStayRequest.displayName = 'proto.core.private.companyretentionstep.UpsertReasonsToStayRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.core.private.companyretentionstep.UpsertReasonsToStayResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.core.private.companyretentionstep.UpsertReasonsToStayResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.core.private.companyretentionstep.UpsertReasonsToStayResponse.displayName = 'proto.core.private.companyretentionstep.UpsertReasonsToStayResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.core.private.companyretentionstep.GetReasonsToStayRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.core.private.companyretentionstep.GetReasonsToStayRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.core.private.companyretentionstep.GetReasonsToStayRequest.displayName = 'proto.core.private.companyretentionstep.GetReasonsToStayRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.core.private.companyretentionstep.GetReasonsToStayResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.core.private.companyretentionstep.GetReasonsToStayResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.core.private.companyretentionstep.GetReasonsToStayResponse.displayName = 'proto.core.private.companyretentionstep.GetReasonsToStayResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.core.private.companyretentionstep.UpsertTimeRemainingRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.core.private.companyretentionstep.UpsertTimeRemainingRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.core.private.companyretentionstep.UpsertTimeRemainingRequest.displayName = 'proto.core.private.companyretentionstep.UpsertTimeRemainingRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.core.private.companyretentionstep.UpsertTimeRemainingResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.core.private.companyretentionstep.UpsertTimeRemainingResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.core.private.companyretentionstep.UpsertTimeRemainingResponse.displayName = 'proto.core.private.companyretentionstep.UpsertTimeRemainingResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.core.private.companyretentionstep.GetTimeRemainingRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.core.private.companyretentionstep.GetTimeRemainingRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.core.private.companyretentionstep.GetTimeRemainingRequest.displayName = 'proto.core.private.companyretentionstep.GetTimeRemainingRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.core.private.companyretentionstep.GetTimeRemainingResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.core.private.companyretentionstep.GetTimeRemainingResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.core.private.companyretentionstep.GetTimeRemainingResponse.displayName = 'proto.core.private.companyretentionstep.GetTimeRemainingResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.core.private.companyretentionstep.GetRetentionStepsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.core.private.companyretentionstep.GetRetentionStepsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.core.private.companyretentionstep.GetRetentionStepsRequest.displayName = 'proto.core.private.companyretentionstep.GetRetentionStepsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.core.private.companyretentionstep.GetRetentionStepsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.core.private.companyretentionstep.GetRetentionStepsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.core.private.companyretentionstep.GetRetentionStepsResponse.displayName = 'proto.core.private.companyretentionstep.GetRetentionStepsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.core.private.companyretentionstep.UpdateRetentionStepsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.core.private.companyretentionstep.UpdateRetentionStepsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.core.private.companyretentionstep.UpdateRetentionStepsRequest.displayName = 'proto.core.private.companyretentionstep.UpdateRetentionStepsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.core.private.companyretentionstep.UpdateRetentionStepsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.core.private.companyretentionstep.UpdateRetentionStepsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.core.private.companyretentionstep.UpdateRetentionStepsResponse.displayName = 'proto.core.private.companyretentionstep.UpdateRetentionStepsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.core.private.companyretentionstep.RetentionStepListItem = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.core.private.companyretentionstep.RetentionStepListItem, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.core.private.companyretentionstep.RetentionStepListItem.displayName = 'proto.core.private.companyretentionstep.RetentionStepListItem';
}

/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.core.private.companyretentionstep.SetDiscountOfferRequest.oneofGroups_ = [[2,3]];

/**
 * @enum {number}
 */
proto.core.private.companyretentionstep.SetDiscountOfferRequest.ConfigurationCase = {
  CONFIGURATION_NOT_SET: 0,
  IS_DISABLED: 2,
  DISCOUNT_OFFER: 3
};

/**
 * @return {proto.core.private.companyretentionstep.SetDiscountOfferRequest.ConfigurationCase}
 */
proto.core.private.companyretentionstep.SetDiscountOfferRequest.prototype.getConfigurationCase = function() {
  return /** @type {proto.core.private.companyretentionstep.SetDiscountOfferRequest.ConfigurationCase} */(jspb.Message.computeOneofCase(this, proto.core.private.companyretentionstep.SetDiscountOfferRequest.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.core.private.companyretentionstep.SetDiscountOfferRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.core.private.companyretentionstep.SetDiscountOfferRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.core.private.companyretentionstep.SetDiscountOfferRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.core.private.companyretentionstep.SetDiscountOfferRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    companyDomain: jspb.Message.getFieldWithDefault(msg, 1, ""),
    isDisabled: jspb.Message.getBooleanFieldWithDefault(msg, 2, false),
    discountOffer: (f = msg.getDiscountOffer()) && proto.core.private.companyretentionstep.SetDiscountOfferRequest.DiscountOffer.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.core.private.companyretentionstep.SetDiscountOfferRequest}
 */
proto.core.private.companyretentionstep.SetDiscountOfferRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.core.private.companyretentionstep.SetDiscountOfferRequest;
  return proto.core.private.companyretentionstep.SetDiscountOfferRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.core.private.companyretentionstep.SetDiscountOfferRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.core.private.companyretentionstep.SetDiscountOfferRequest}
 */
proto.core.private.companyretentionstep.SetDiscountOfferRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setCompanyDomain(value);
      break;
    case 2:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsDisabled(value);
      break;
    case 3:
      var value = new proto.core.private.companyretentionstep.SetDiscountOfferRequest.DiscountOffer;
      reader.readMessage(value,proto.core.private.companyretentionstep.SetDiscountOfferRequest.DiscountOffer.deserializeBinaryFromReader);
      msg.setDiscountOffer(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.core.private.companyretentionstep.SetDiscountOfferRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.core.private.companyretentionstep.SetDiscountOfferRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.core.private.companyretentionstep.SetDiscountOfferRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.core.private.companyretentionstep.SetDiscountOfferRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCompanyDomain();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = /** @type {boolean} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeBool(
      2,
      f
    );
  }
  f = message.getDiscountOffer();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.core.private.companyretentionstep.SetDiscountOfferRequest.DiscountOffer.serializeBinaryToWriter
    );
  }
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.core.private.companyretentionstep.SetDiscountOfferRequest.DiscountOffer.oneofGroups_ = [[4,5]];

/**
 * @enum {number}
 */
proto.core.private.companyretentionstep.SetDiscountOfferRequest.DiscountOffer.EligibilityCase = {
  ELIGIBILITY_NOT_SET: 0,
  ENABLED_ALL_CUSTOMERS: 4,
  MINIMUM_TENURE: 5
};

/**
 * @return {proto.core.private.companyretentionstep.SetDiscountOfferRequest.DiscountOffer.EligibilityCase}
 */
proto.core.private.companyretentionstep.SetDiscountOfferRequest.DiscountOffer.prototype.getEligibilityCase = function() {
  return /** @type {proto.core.private.companyretentionstep.SetDiscountOfferRequest.DiscountOffer.EligibilityCase} */(jspb.Message.computeOneofCase(this, proto.core.private.companyretentionstep.SetDiscountOfferRequest.DiscountOffer.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.core.private.companyretentionstep.SetDiscountOfferRequest.DiscountOffer.prototype.toObject = function(opt_includeInstance) {
  return proto.core.private.companyretentionstep.SetDiscountOfferRequest.DiscountOffer.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.core.private.companyretentionstep.SetDiscountOfferRequest.DiscountOffer} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.core.private.companyretentionstep.SetDiscountOfferRequest.DiscountOffer.toObject = function(includeInstance, msg) {
  var f, obj = {
    percentage: jspb.Message.getFieldWithDefault(msg, 1, 0),
    expiryFrequency: jspb.Message.getFieldWithDefault(msg, 2, 0),
    expiryFrequencyType: jspb.Message.getFieldWithDefault(msg, 3, 0),
    enabledAllCustomers: jspb.Message.getBooleanFieldWithDefault(msg, 4, false),
    minimumTenure: (f = msg.getMinimumTenure()) && proto.core.private.companyretentionstep.SetDiscountOfferRequest.DiscountOffer.MinimumTenure.toObject(includeInstance, f),
    limitToOneRetentionDiscountPerSub: jspb.Message.getBooleanFieldWithDefault(msg, 6, false),
    exitIntentImageUrl: jspb.Message.getFieldWithDefault(msg, 7, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.core.private.companyretentionstep.SetDiscountOfferRequest.DiscountOffer}
 */
proto.core.private.companyretentionstep.SetDiscountOfferRequest.DiscountOffer.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.core.private.companyretentionstep.SetDiscountOfferRequest.DiscountOffer;
  return proto.core.private.companyretentionstep.SetDiscountOfferRequest.DiscountOffer.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.core.private.companyretentionstep.SetDiscountOfferRequest.DiscountOffer} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.core.private.companyretentionstep.SetDiscountOfferRequest.DiscountOffer}
 */
proto.core.private.companyretentionstep.SetDiscountOfferRequest.DiscountOffer.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPercentage(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setExpiryFrequency(value);
      break;
    case 3:
      var value = /** @type {!proto.core.private.companyretentionstep.FrequencyType} */ (reader.readEnum());
      msg.setExpiryFrequencyType(value);
      break;
    case 4:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setEnabledAllCustomers(value);
      break;
    case 5:
      var value = new proto.core.private.companyretentionstep.SetDiscountOfferRequest.DiscountOffer.MinimumTenure;
      reader.readMessage(value,proto.core.private.companyretentionstep.SetDiscountOfferRequest.DiscountOffer.MinimumTenure.deserializeBinaryFromReader);
      msg.setMinimumTenure(value);
      break;
    case 6:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setLimitToOneRetentionDiscountPerSub(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setExitIntentImageUrl(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.core.private.companyretentionstep.SetDiscountOfferRequest.DiscountOffer.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.core.private.companyretentionstep.SetDiscountOfferRequest.DiscountOffer.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.core.private.companyretentionstep.SetDiscountOfferRequest.DiscountOffer} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.core.private.companyretentionstep.SetDiscountOfferRequest.DiscountOffer.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPercentage();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getExpiryFrequency();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getExpiryFrequencyType();
  if (f !== 0.0) {
    writer.writeEnum(
      3,
      f
    );
  }
  f = /** @type {boolean} */ (jspb.Message.getField(message, 4));
  if (f != null) {
    writer.writeBool(
      4,
      f
    );
  }
  f = message.getMinimumTenure();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      proto.core.private.companyretentionstep.SetDiscountOfferRequest.DiscountOffer.MinimumTenure.serializeBinaryToWriter
    );
  }
  f = message.getLimitToOneRetentionDiscountPerSub();
  if (f) {
    writer.writeBool(
      6,
      f
    );
  }
  f = message.getExitIntentImageUrl();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.core.private.companyretentionstep.SetDiscountOfferRequest.DiscountOffer.MinimumTenure.prototype.toObject = function(opt_includeInstance) {
  return proto.core.private.companyretentionstep.SetDiscountOfferRequest.DiscountOffer.MinimumTenure.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.core.private.companyretentionstep.SetDiscountOfferRequest.DiscountOffer.MinimumTenure} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.core.private.companyretentionstep.SetDiscountOfferRequest.DiscountOffer.MinimumTenure.toObject = function(includeInstance, msg) {
  var f, obj = {
    frequency: jspb.Message.getFieldWithDefault(msg, 1, 0),
    frequencyType: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.core.private.companyretentionstep.SetDiscountOfferRequest.DiscountOffer.MinimumTenure}
 */
proto.core.private.companyretentionstep.SetDiscountOfferRequest.DiscountOffer.MinimumTenure.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.core.private.companyretentionstep.SetDiscountOfferRequest.DiscountOffer.MinimumTenure;
  return proto.core.private.companyretentionstep.SetDiscountOfferRequest.DiscountOffer.MinimumTenure.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.core.private.companyretentionstep.SetDiscountOfferRequest.DiscountOffer.MinimumTenure} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.core.private.companyretentionstep.SetDiscountOfferRequest.DiscountOffer.MinimumTenure}
 */
proto.core.private.companyretentionstep.SetDiscountOfferRequest.DiscountOffer.MinimumTenure.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setFrequency(value);
      break;
    case 2:
      var value = /** @type {!proto.core.private.companyretentionstep.FrequencyType} */ (reader.readEnum());
      msg.setFrequencyType(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.core.private.companyretentionstep.SetDiscountOfferRequest.DiscountOffer.MinimumTenure.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.core.private.companyretentionstep.SetDiscountOfferRequest.DiscountOffer.MinimumTenure.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.core.private.companyretentionstep.SetDiscountOfferRequest.DiscountOffer.MinimumTenure} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.core.private.companyretentionstep.SetDiscountOfferRequest.DiscountOffer.MinimumTenure.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFrequency();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getFrequencyType();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
};


/**
 * optional int32 frequency = 1;
 * @return {number}
 */
proto.core.private.companyretentionstep.SetDiscountOfferRequest.DiscountOffer.MinimumTenure.prototype.getFrequency = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.core.private.companyretentionstep.SetDiscountOfferRequest.DiscountOffer.MinimumTenure} returns this
 */
proto.core.private.companyretentionstep.SetDiscountOfferRequest.DiscountOffer.MinimumTenure.prototype.setFrequency = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional FrequencyType frequency_type = 2;
 * @return {!proto.core.private.companyretentionstep.FrequencyType}
 */
proto.core.private.companyretentionstep.SetDiscountOfferRequest.DiscountOffer.MinimumTenure.prototype.getFrequencyType = function() {
  return /** @type {!proto.core.private.companyretentionstep.FrequencyType} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.core.private.companyretentionstep.FrequencyType} value
 * @return {!proto.core.private.companyretentionstep.SetDiscountOfferRequest.DiscountOffer.MinimumTenure} returns this
 */
proto.core.private.companyretentionstep.SetDiscountOfferRequest.DiscountOffer.MinimumTenure.prototype.setFrequencyType = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};


/**
 * optional int32 percentage = 1;
 * @return {number}
 */
proto.core.private.companyretentionstep.SetDiscountOfferRequest.DiscountOffer.prototype.getPercentage = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.core.private.companyretentionstep.SetDiscountOfferRequest.DiscountOffer} returns this
 */
proto.core.private.companyretentionstep.SetDiscountOfferRequest.DiscountOffer.prototype.setPercentage = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int32 expiry_frequency = 2;
 * @return {number}
 */
proto.core.private.companyretentionstep.SetDiscountOfferRequest.DiscountOffer.prototype.getExpiryFrequency = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.core.private.companyretentionstep.SetDiscountOfferRequest.DiscountOffer} returns this
 */
proto.core.private.companyretentionstep.SetDiscountOfferRequest.DiscountOffer.prototype.setExpiryFrequency = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional FrequencyType expiry_frequency_type = 3;
 * @return {!proto.core.private.companyretentionstep.FrequencyType}
 */
proto.core.private.companyretentionstep.SetDiscountOfferRequest.DiscountOffer.prototype.getExpiryFrequencyType = function() {
  return /** @type {!proto.core.private.companyretentionstep.FrequencyType} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {!proto.core.private.companyretentionstep.FrequencyType} value
 * @return {!proto.core.private.companyretentionstep.SetDiscountOfferRequest.DiscountOffer} returns this
 */
proto.core.private.companyretentionstep.SetDiscountOfferRequest.DiscountOffer.prototype.setExpiryFrequencyType = function(value) {
  return jspb.Message.setProto3EnumField(this, 3, value);
};


/**
 * optional bool enabled_all_customers = 4;
 * @return {boolean}
 */
proto.core.private.companyretentionstep.SetDiscountOfferRequest.DiscountOffer.prototype.getEnabledAllCustomers = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 4, false));
};


/**
 * @param {boolean} value
 * @return {!proto.core.private.companyretentionstep.SetDiscountOfferRequest.DiscountOffer} returns this
 */
proto.core.private.companyretentionstep.SetDiscountOfferRequest.DiscountOffer.prototype.setEnabledAllCustomers = function(value) {
  return jspb.Message.setOneofField(this, 4, proto.core.private.companyretentionstep.SetDiscountOfferRequest.DiscountOffer.oneofGroups_[0], value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.core.private.companyretentionstep.SetDiscountOfferRequest.DiscountOffer} returns this
 */
proto.core.private.companyretentionstep.SetDiscountOfferRequest.DiscountOffer.prototype.clearEnabledAllCustomers = function() {
  return jspb.Message.setOneofField(this, 4, proto.core.private.companyretentionstep.SetDiscountOfferRequest.DiscountOffer.oneofGroups_[0], undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.core.private.companyretentionstep.SetDiscountOfferRequest.DiscountOffer.prototype.hasEnabledAllCustomers = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional MinimumTenure minimum_tenure = 5;
 * @return {?proto.core.private.companyretentionstep.SetDiscountOfferRequest.DiscountOffer.MinimumTenure}
 */
proto.core.private.companyretentionstep.SetDiscountOfferRequest.DiscountOffer.prototype.getMinimumTenure = function() {
  return /** @type{?proto.core.private.companyretentionstep.SetDiscountOfferRequest.DiscountOffer.MinimumTenure} */ (
    jspb.Message.getWrapperField(this, proto.core.private.companyretentionstep.SetDiscountOfferRequest.DiscountOffer.MinimumTenure, 5));
};


/**
 * @param {?proto.core.private.companyretentionstep.SetDiscountOfferRequest.DiscountOffer.MinimumTenure|undefined} value
 * @return {!proto.core.private.companyretentionstep.SetDiscountOfferRequest.DiscountOffer} returns this
*/
proto.core.private.companyretentionstep.SetDiscountOfferRequest.DiscountOffer.prototype.setMinimumTenure = function(value) {
  return jspb.Message.setOneofWrapperField(this, 5, proto.core.private.companyretentionstep.SetDiscountOfferRequest.DiscountOffer.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.core.private.companyretentionstep.SetDiscountOfferRequest.DiscountOffer} returns this
 */
proto.core.private.companyretentionstep.SetDiscountOfferRequest.DiscountOffer.prototype.clearMinimumTenure = function() {
  return this.setMinimumTenure(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.core.private.companyretentionstep.SetDiscountOfferRequest.DiscountOffer.prototype.hasMinimumTenure = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional bool limit_to_one_retention_discount_per_sub = 6;
 * @return {boolean}
 */
proto.core.private.companyretentionstep.SetDiscountOfferRequest.DiscountOffer.prototype.getLimitToOneRetentionDiscountPerSub = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 6, false));
};


/**
 * @param {boolean} value
 * @return {!proto.core.private.companyretentionstep.SetDiscountOfferRequest.DiscountOffer} returns this
 */
proto.core.private.companyretentionstep.SetDiscountOfferRequest.DiscountOffer.prototype.setLimitToOneRetentionDiscountPerSub = function(value) {
  return jspb.Message.setProto3BooleanField(this, 6, value);
};


/**
 * optional string exit_intent_image_url = 7;
 * @return {string}
 */
proto.core.private.companyretentionstep.SetDiscountOfferRequest.DiscountOffer.prototype.getExitIntentImageUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.core.private.companyretentionstep.SetDiscountOfferRequest.DiscountOffer} returns this
 */
proto.core.private.companyretentionstep.SetDiscountOfferRequest.DiscountOffer.prototype.setExitIntentImageUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string company_domain = 1;
 * @return {string}
 */
proto.core.private.companyretentionstep.SetDiscountOfferRequest.prototype.getCompanyDomain = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.core.private.companyretentionstep.SetDiscountOfferRequest} returns this
 */
proto.core.private.companyretentionstep.SetDiscountOfferRequest.prototype.setCompanyDomain = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional bool is_disabled = 2;
 * @return {boolean}
 */
proto.core.private.companyretentionstep.SetDiscountOfferRequest.prototype.getIsDisabled = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
};


/**
 * @param {boolean} value
 * @return {!proto.core.private.companyretentionstep.SetDiscountOfferRequest} returns this
 */
proto.core.private.companyretentionstep.SetDiscountOfferRequest.prototype.setIsDisabled = function(value) {
  return jspb.Message.setOneofField(this, 2, proto.core.private.companyretentionstep.SetDiscountOfferRequest.oneofGroups_[0], value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.core.private.companyretentionstep.SetDiscountOfferRequest} returns this
 */
proto.core.private.companyretentionstep.SetDiscountOfferRequest.prototype.clearIsDisabled = function() {
  return jspb.Message.setOneofField(this, 2, proto.core.private.companyretentionstep.SetDiscountOfferRequest.oneofGroups_[0], undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.core.private.companyretentionstep.SetDiscountOfferRequest.prototype.hasIsDisabled = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional DiscountOffer discount_offer = 3;
 * @return {?proto.core.private.companyretentionstep.SetDiscountOfferRequest.DiscountOffer}
 */
proto.core.private.companyretentionstep.SetDiscountOfferRequest.prototype.getDiscountOffer = function() {
  return /** @type{?proto.core.private.companyretentionstep.SetDiscountOfferRequest.DiscountOffer} */ (
    jspb.Message.getWrapperField(this, proto.core.private.companyretentionstep.SetDiscountOfferRequest.DiscountOffer, 3));
};


/**
 * @param {?proto.core.private.companyretentionstep.SetDiscountOfferRequest.DiscountOffer|undefined} value
 * @return {!proto.core.private.companyretentionstep.SetDiscountOfferRequest} returns this
*/
proto.core.private.companyretentionstep.SetDiscountOfferRequest.prototype.setDiscountOffer = function(value) {
  return jspb.Message.setOneofWrapperField(this, 3, proto.core.private.companyretentionstep.SetDiscountOfferRequest.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.core.private.companyretentionstep.SetDiscountOfferRequest} returns this
 */
proto.core.private.companyretentionstep.SetDiscountOfferRequest.prototype.clearDiscountOffer = function() {
  return this.setDiscountOffer(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.core.private.companyretentionstep.SetDiscountOfferRequest.prototype.hasDiscountOffer = function() {
  return jspb.Message.getField(this, 3) != null;
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.core.private.companyretentionstep.SetDiscountOfferResponse.oneofGroups_ = [[1,2]];

/**
 * @enum {number}
 */
proto.core.private.companyretentionstep.SetDiscountOfferResponse.ConfigurationCase = {
  CONFIGURATION_NOT_SET: 0,
  IS_DISABLED: 1,
  DISCOUNT_OFFER: 2
};

/**
 * @return {proto.core.private.companyretentionstep.SetDiscountOfferResponse.ConfigurationCase}
 */
proto.core.private.companyretentionstep.SetDiscountOfferResponse.prototype.getConfigurationCase = function() {
  return /** @type {proto.core.private.companyretentionstep.SetDiscountOfferResponse.ConfigurationCase} */(jspb.Message.computeOneofCase(this, proto.core.private.companyretentionstep.SetDiscountOfferResponse.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.core.private.companyretentionstep.SetDiscountOfferResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.core.private.companyretentionstep.SetDiscountOfferResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.core.private.companyretentionstep.SetDiscountOfferResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.core.private.companyretentionstep.SetDiscountOfferResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    isDisabled: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    discountOffer: (f = msg.getDiscountOffer()) && proto.core.private.companyretentionstep.SetDiscountOfferResponse.DiscountOffer.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.core.private.companyretentionstep.SetDiscountOfferResponse}
 */
proto.core.private.companyretentionstep.SetDiscountOfferResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.core.private.companyretentionstep.SetDiscountOfferResponse;
  return proto.core.private.companyretentionstep.SetDiscountOfferResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.core.private.companyretentionstep.SetDiscountOfferResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.core.private.companyretentionstep.SetDiscountOfferResponse}
 */
proto.core.private.companyretentionstep.SetDiscountOfferResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsDisabled(value);
      break;
    case 2:
      var value = new proto.core.private.companyretentionstep.SetDiscountOfferResponse.DiscountOffer;
      reader.readMessage(value,proto.core.private.companyretentionstep.SetDiscountOfferResponse.DiscountOffer.deserializeBinaryFromReader);
      msg.setDiscountOffer(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.core.private.companyretentionstep.SetDiscountOfferResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.core.private.companyretentionstep.SetDiscountOfferResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.core.private.companyretentionstep.SetDiscountOfferResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.core.private.companyretentionstep.SetDiscountOfferResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {boolean} */ (jspb.Message.getField(message, 1));
  if (f != null) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getDiscountOffer();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.core.private.companyretentionstep.SetDiscountOfferResponse.DiscountOffer.serializeBinaryToWriter
    );
  }
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.core.private.companyretentionstep.SetDiscountOfferResponse.DiscountOffer.oneofGroups_ = [[4,5]];

/**
 * @enum {number}
 */
proto.core.private.companyretentionstep.SetDiscountOfferResponse.DiscountOffer.EligibilityCase = {
  ELIGIBILITY_NOT_SET: 0,
  ENABLED_ALL_CUSTOMERS: 4,
  MINIMUM_TENURE: 5
};

/**
 * @return {proto.core.private.companyretentionstep.SetDiscountOfferResponse.DiscountOffer.EligibilityCase}
 */
proto.core.private.companyretentionstep.SetDiscountOfferResponse.DiscountOffer.prototype.getEligibilityCase = function() {
  return /** @type {proto.core.private.companyretentionstep.SetDiscountOfferResponse.DiscountOffer.EligibilityCase} */(jspb.Message.computeOneofCase(this, proto.core.private.companyretentionstep.SetDiscountOfferResponse.DiscountOffer.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.core.private.companyretentionstep.SetDiscountOfferResponse.DiscountOffer.prototype.toObject = function(opt_includeInstance) {
  return proto.core.private.companyretentionstep.SetDiscountOfferResponse.DiscountOffer.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.core.private.companyretentionstep.SetDiscountOfferResponse.DiscountOffer} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.core.private.companyretentionstep.SetDiscountOfferResponse.DiscountOffer.toObject = function(includeInstance, msg) {
  var f, obj = {
    percentage: jspb.Message.getFieldWithDefault(msg, 1, 0),
    expiryFrequency: jspb.Message.getFieldWithDefault(msg, 2, 0),
    expiryFrequencyType: jspb.Message.getFieldWithDefault(msg, 3, 0),
    enabledAllCustomers: jspb.Message.getBooleanFieldWithDefault(msg, 4, false),
    minimumTenure: (f = msg.getMinimumTenure()) && proto.core.private.companyretentionstep.SetDiscountOfferResponse.DiscountOffer.MinimumTenure.toObject(includeInstance, f),
    limitToOneRetentionDiscountPerSub: jspb.Message.getBooleanFieldWithDefault(msg, 6, false),
    exitIntentImageUrl: jspb.Message.getFieldWithDefault(msg, 7, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.core.private.companyretentionstep.SetDiscountOfferResponse.DiscountOffer}
 */
proto.core.private.companyretentionstep.SetDiscountOfferResponse.DiscountOffer.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.core.private.companyretentionstep.SetDiscountOfferResponse.DiscountOffer;
  return proto.core.private.companyretentionstep.SetDiscountOfferResponse.DiscountOffer.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.core.private.companyretentionstep.SetDiscountOfferResponse.DiscountOffer} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.core.private.companyretentionstep.SetDiscountOfferResponse.DiscountOffer}
 */
proto.core.private.companyretentionstep.SetDiscountOfferResponse.DiscountOffer.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPercentage(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setExpiryFrequency(value);
      break;
    case 3:
      var value = /** @type {!proto.core.private.companyretentionstep.FrequencyType} */ (reader.readEnum());
      msg.setExpiryFrequencyType(value);
      break;
    case 4:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setEnabledAllCustomers(value);
      break;
    case 5:
      var value = new proto.core.private.companyretentionstep.SetDiscountOfferResponse.DiscountOffer.MinimumTenure;
      reader.readMessage(value,proto.core.private.companyretentionstep.SetDiscountOfferResponse.DiscountOffer.MinimumTenure.deserializeBinaryFromReader);
      msg.setMinimumTenure(value);
      break;
    case 6:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setLimitToOneRetentionDiscountPerSub(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setExitIntentImageUrl(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.core.private.companyretentionstep.SetDiscountOfferResponse.DiscountOffer.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.core.private.companyretentionstep.SetDiscountOfferResponse.DiscountOffer.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.core.private.companyretentionstep.SetDiscountOfferResponse.DiscountOffer} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.core.private.companyretentionstep.SetDiscountOfferResponse.DiscountOffer.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPercentage();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getExpiryFrequency();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getExpiryFrequencyType();
  if (f !== 0.0) {
    writer.writeEnum(
      3,
      f
    );
  }
  f = /** @type {boolean} */ (jspb.Message.getField(message, 4));
  if (f != null) {
    writer.writeBool(
      4,
      f
    );
  }
  f = message.getMinimumTenure();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      proto.core.private.companyretentionstep.SetDiscountOfferResponse.DiscountOffer.MinimumTenure.serializeBinaryToWriter
    );
  }
  f = message.getLimitToOneRetentionDiscountPerSub();
  if (f) {
    writer.writeBool(
      6,
      f
    );
  }
  f = message.getExitIntentImageUrl();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.core.private.companyretentionstep.SetDiscountOfferResponse.DiscountOffer.MinimumTenure.prototype.toObject = function(opt_includeInstance) {
  return proto.core.private.companyretentionstep.SetDiscountOfferResponse.DiscountOffer.MinimumTenure.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.core.private.companyretentionstep.SetDiscountOfferResponse.DiscountOffer.MinimumTenure} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.core.private.companyretentionstep.SetDiscountOfferResponse.DiscountOffer.MinimumTenure.toObject = function(includeInstance, msg) {
  var f, obj = {
    frequency: jspb.Message.getFieldWithDefault(msg, 1, 0),
    frequencyType: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.core.private.companyretentionstep.SetDiscountOfferResponse.DiscountOffer.MinimumTenure}
 */
proto.core.private.companyretentionstep.SetDiscountOfferResponse.DiscountOffer.MinimumTenure.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.core.private.companyretentionstep.SetDiscountOfferResponse.DiscountOffer.MinimumTenure;
  return proto.core.private.companyretentionstep.SetDiscountOfferResponse.DiscountOffer.MinimumTenure.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.core.private.companyretentionstep.SetDiscountOfferResponse.DiscountOffer.MinimumTenure} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.core.private.companyretentionstep.SetDiscountOfferResponse.DiscountOffer.MinimumTenure}
 */
proto.core.private.companyretentionstep.SetDiscountOfferResponse.DiscountOffer.MinimumTenure.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setFrequency(value);
      break;
    case 2:
      var value = /** @type {!proto.core.private.companyretentionstep.FrequencyType} */ (reader.readEnum());
      msg.setFrequencyType(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.core.private.companyretentionstep.SetDiscountOfferResponse.DiscountOffer.MinimumTenure.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.core.private.companyretentionstep.SetDiscountOfferResponse.DiscountOffer.MinimumTenure.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.core.private.companyretentionstep.SetDiscountOfferResponse.DiscountOffer.MinimumTenure} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.core.private.companyretentionstep.SetDiscountOfferResponse.DiscountOffer.MinimumTenure.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFrequency();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getFrequencyType();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
};


/**
 * optional int32 frequency = 1;
 * @return {number}
 */
proto.core.private.companyretentionstep.SetDiscountOfferResponse.DiscountOffer.MinimumTenure.prototype.getFrequency = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.core.private.companyretentionstep.SetDiscountOfferResponse.DiscountOffer.MinimumTenure} returns this
 */
proto.core.private.companyretentionstep.SetDiscountOfferResponse.DiscountOffer.MinimumTenure.prototype.setFrequency = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional FrequencyType frequency_type = 2;
 * @return {!proto.core.private.companyretentionstep.FrequencyType}
 */
proto.core.private.companyretentionstep.SetDiscountOfferResponse.DiscountOffer.MinimumTenure.prototype.getFrequencyType = function() {
  return /** @type {!proto.core.private.companyretentionstep.FrequencyType} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.core.private.companyretentionstep.FrequencyType} value
 * @return {!proto.core.private.companyretentionstep.SetDiscountOfferResponse.DiscountOffer.MinimumTenure} returns this
 */
proto.core.private.companyretentionstep.SetDiscountOfferResponse.DiscountOffer.MinimumTenure.prototype.setFrequencyType = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};


/**
 * optional int32 percentage = 1;
 * @return {number}
 */
proto.core.private.companyretentionstep.SetDiscountOfferResponse.DiscountOffer.prototype.getPercentage = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.core.private.companyretentionstep.SetDiscountOfferResponse.DiscountOffer} returns this
 */
proto.core.private.companyretentionstep.SetDiscountOfferResponse.DiscountOffer.prototype.setPercentage = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int32 expiry_frequency = 2;
 * @return {number}
 */
proto.core.private.companyretentionstep.SetDiscountOfferResponse.DiscountOffer.prototype.getExpiryFrequency = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.core.private.companyretentionstep.SetDiscountOfferResponse.DiscountOffer} returns this
 */
proto.core.private.companyretentionstep.SetDiscountOfferResponse.DiscountOffer.prototype.setExpiryFrequency = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional FrequencyType expiry_frequency_type = 3;
 * @return {!proto.core.private.companyretentionstep.FrequencyType}
 */
proto.core.private.companyretentionstep.SetDiscountOfferResponse.DiscountOffer.prototype.getExpiryFrequencyType = function() {
  return /** @type {!proto.core.private.companyretentionstep.FrequencyType} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {!proto.core.private.companyretentionstep.FrequencyType} value
 * @return {!proto.core.private.companyretentionstep.SetDiscountOfferResponse.DiscountOffer} returns this
 */
proto.core.private.companyretentionstep.SetDiscountOfferResponse.DiscountOffer.prototype.setExpiryFrequencyType = function(value) {
  return jspb.Message.setProto3EnumField(this, 3, value);
};


/**
 * optional bool enabled_all_customers = 4;
 * @return {boolean}
 */
proto.core.private.companyretentionstep.SetDiscountOfferResponse.DiscountOffer.prototype.getEnabledAllCustomers = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 4, false));
};


/**
 * @param {boolean} value
 * @return {!proto.core.private.companyretentionstep.SetDiscountOfferResponse.DiscountOffer} returns this
 */
proto.core.private.companyretentionstep.SetDiscountOfferResponse.DiscountOffer.prototype.setEnabledAllCustomers = function(value) {
  return jspb.Message.setOneofField(this, 4, proto.core.private.companyretentionstep.SetDiscountOfferResponse.DiscountOffer.oneofGroups_[0], value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.core.private.companyretentionstep.SetDiscountOfferResponse.DiscountOffer} returns this
 */
proto.core.private.companyretentionstep.SetDiscountOfferResponse.DiscountOffer.prototype.clearEnabledAllCustomers = function() {
  return jspb.Message.setOneofField(this, 4, proto.core.private.companyretentionstep.SetDiscountOfferResponse.DiscountOffer.oneofGroups_[0], undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.core.private.companyretentionstep.SetDiscountOfferResponse.DiscountOffer.prototype.hasEnabledAllCustomers = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional MinimumTenure minimum_tenure = 5;
 * @return {?proto.core.private.companyretentionstep.SetDiscountOfferResponse.DiscountOffer.MinimumTenure}
 */
proto.core.private.companyretentionstep.SetDiscountOfferResponse.DiscountOffer.prototype.getMinimumTenure = function() {
  return /** @type{?proto.core.private.companyretentionstep.SetDiscountOfferResponse.DiscountOffer.MinimumTenure} */ (
    jspb.Message.getWrapperField(this, proto.core.private.companyretentionstep.SetDiscountOfferResponse.DiscountOffer.MinimumTenure, 5));
};


/**
 * @param {?proto.core.private.companyretentionstep.SetDiscountOfferResponse.DiscountOffer.MinimumTenure|undefined} value
 * @return {!proto.core.private.companyretentionstep.SetDiscountOfferResponse.DiscountOffer} returns this
*/
proto.core.private.companyretentionstep.SetDiscountOfferResponse.DiscountOffer.prototype.setMinimumTenure = function(value) {
  return jspb.Message.setOneofWrapperField(this, 5, proto.core.private.companyretentionstep.SetDiscountOfferResponse.DiscountOffer.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.core.private.companyretentionstep.SetDiscountOfferResponse.DiscountOffer} returns this
 */
proto.core.private.companyretentionstep.SetDiscountOfferResponse.DiscountOffer.prototype.clearMinimumTenure = function() {
  return this.setMinimumTenure(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.core.private.companyretentionstep.SetDiscountOfferResponse.DiscountOffer.prototype.hasMinimumTenure = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional bool limit_to_one_retention_discount_per_sub = 6;
 * @return {boolean}
 */
proto.core.private.companyretentionstep.SetDiscountOfferResponse.DiscountOffer.prototype.getLimitToOneRetentionDiscountPerSub = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 6, false));
};


/**
 * @param {boolean} value
 * @return {!proto.core.private.companyretentionstep.SetDiscountOfferResponse.DiscountOffer} returns this
 */
proto.core.private.companyretentionstep.SetDiscountOfferResponse.DiscountOffer.prototype.setLimitToOneRetentionDiscountPerSub = function(value) {
  return jspb.Message.setProto3BooleanField(this, 6, value);
};


/**
 * optional string exit_intent_image_url = 7;
 * @return {string}
 */
proto.core.private.companyretentionstep.SetDiscountOfferResponse.DiscountOffer.prototype.getExitIntentImageUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.core.private.companyretentionstep.SetDiscountOfferResponse.DiscountOffer} returns this
 */
proto.core.private.companyretentionstep.SetDiscountOfferResponse.DiscountOffer.prototype.setExitIntentImageUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional bool is_disabled = 1;
 * @return {boolean}
 */
proto.core.private.companyretentionstep.SetDiscountOfferResponse.prototype.getIsDisabled = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.core.private.companyretentionstep.SetDiscountOfferResponse} returns this
 */
proto.core.private.companyretentionstep.SetDiscountOfferResponse.prototype.setIsDisabled = function(value) {
  return jspb.Message.setOneofField(this, 1, proto.core.private.companyretentionstep.SetDiscountOfferResponse.oneofGroups_[0], value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.core.private.companyretentionstep.SetDiscountOfferResponse} returns this
 */
proto.core.private.companyretentionstep.SetDiscountOfferResponse.prototype.clearIsDisabled = function() {
  return jspb.Message.setOneofField(this, 1, proto.core.private.companyretentionstep.SetDiscountOfferResponse.oneofGroups_[0], undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.core.private.companyretentionstep.SetDiscountOfferResponse.prototype.hasIsDisabled = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional DiscountOffer discount_offer = 2;
 * @return {?proto.core.private.companyretentionstep.SetDiscountOfferResponse.DiscountOffer}
 */
proto.core.private.companyretentionstep.SetDiscountOfferResponse.prototype.getDiscountOffer = function() {
  return /** @type{?proto.core.private.companyretentionstep.SetDiscountOfferResponse.DiscountOffer} */ (
    jspb.Message.getWrapperField(this, proto.core.private.companyretentionstep.SetDiscountOfferResponse.DiscountOffer, 2));
};


/**
 * @param {?proto.core.private.companyretentionstep.SetDiscountOfferResponse.DiscountOffer|undefined} value
 * @return {!proto.core.private.companyretentionstep.SetDiscountOfferResponse} returns this
*/
proto.core.private.companyretentionstep.SetDiscountOfferResponse.prototype.setDiscountOffer = function(value) {
  return jspb.Message.setOneofWrapperField(this, 2, proto.core.private.companyretentionstep.SetDiscountOfferResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.core.private.companyretentionstep.SetDiscountOfferResponse} returns this
 */
proto.core.private.companyretentionstep.SetDiscountOfferResponse.prototype.clearDiscountOffer = function() {
  return this.setDiscountOffer(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.core.private.companyretentionstep.SetDiscountOfferResponse.prototype.hasDiscountOffer = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.core.private.companyretentionstep.GetDiscountOfferRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.core.private.companyretentionstep.GetDiscountOfferRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.core.private.companyretentionstep.GetDiscountOfferRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.core.private.companyretentionstep.GetDiscountOfferRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    companyDomain: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.core.private.companyretentionstep.GetDiscountOfferRequest}
 */
proto.core.private.companyretentionstep.GetDiscountOfferRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.core.private.companyretentionstep.GetDiscountOfferRequest;
  return proto.core.private.companyretentionstep.GetDiscountOfferRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.core.private.companyretentionstep.GetDiscountOfferRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.core.private.companyretentionstep.GetDiscountOfferRequest}
 */
proto.core.private.companyretentionstep.GetDiscountOfferRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setCompanyDomain(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.core.private.companyretentionstep.GetDiscountOfferRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.core.private.companyretentionstep.GetDiscountOfferRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.core.private.companyretentionstep.GetDiscountOfferRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.core.private.companyretentionstep.GetDiscountOfferRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCompanyDomain();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string company_domain = 1;
 * @return {string}
 */
proto.core.private.companyretentionstep.GetDiscountOfferRequest.prototype.getCompanyDomain = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.core.private.companyretentionstep.GetDiscountOfferRequest} returns this
 */
proto.core.private.companyretentionstep.GetDiscountOfferRequest.prototype.setCompanyDomain = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.core.private.companyretentionstep.GetDiscountOfferResponse.oneofGroups_ = [[1,2]];

/**
 * @enum {number}
 */
proto.core.private.companyretentionstep.GetDiscountOfferResponse.ConfigurationCase = {
  CONFIGURATION_NOT_SET: 0,
  IS_DISABLED: 1,
  DISCOUNT_OFFER: 2
};

/**
 * @return {proto.core.private.companyretentionstep.GetDiscountOfferResponse.ConfigurationCase}
 */
proto.core.private.companyretentionstep.GetDiscountOfferResponse.prototype.getConfigurationCase = function() {
  return /** @type {proto.core.private.companyretentionstep.GetDiscountOfferResponse.ConfigurationCase} */(jspb.Message.computeOneofCase(this, proto.core.private.companyretentionstep.GetDiscountOfferResponse.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.core.private.companyretentionstep.GetDiscountOfferResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.core.private.companyretentionstep.GetDiscountOfferResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.core.private.companyretentionstep.GetDiscountOfferResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.core.private.companyretentionstep.GetDiscountOfferResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    isDisabled: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    discountOffer: (f = msg.getDiscountOffer()) && proto.core.private.companyretentionstep.GetDiscountOfferResponse.DiscountOffer.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.core.private.companyretentionstep.GetDiscountOfferResponse}
 */
proto.core.private.companyretentionstep.GetDiscountOfferResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.core.private.companyretentionstep.GetDiscountOfferResponse;
  return proto.core.private.companyretentionstep.GetDiscountOfferResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.core.private.companyretentionstep.GetDiscountOfferResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.core.private.companyretentionstep.GetDiscountOfferResponse}
 */
proto.core.private.companyretentionstep.GetDiscountOfferResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsDisabled(value);
      break;
    case 2:
      var value = new proto.core.private.companyretentionstep.GetDiscountOfferResponse.DiscountOffer;
      reader.readMessage(value,proto.core.private.companyretentionstep.GetDiscountOfferResponse.DiscountOffer.deserializeBinaryFromReader);
      msg.setDiscountOffer(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.core.private.companyretentionstep.GetDiscountOfferResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.core.private.companyretentionstep.GetDiscountOfferResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.core.private.companyretentionstep.GetDiscountOfferResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.core.private.companyretentionstep.GetDiscountOfferResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {boolean} */ (jspb.Message.getField(message, 1));
  if (f != null) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getDiscountOffer();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.core.private.companyretentionstep.GetDiscountOfferResponse.DiscountOffer.serializeBinaryToWriter
    );
  }
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.core.private.companyretentionstep.GetDiscountOfferResponse.DiscountOffer.oneofGroups_ = [[4,5]];

/**
 * @enum {number}
 */
proto.core.private.companyretentionstep.GetDiscountOfferResponse.DiscountOffer.EligibilityCase = {
  ELIGIBILITY_NOT_SET: 0,
  ENABLED_ALL_CUSTOMERS: 4,
  MINIMUM_TENURE: 5
};

/**
 * @return {proto.core.private.companyretentionstep.GetDiscountOfferResponse.DiscountOffer.EligibilityCase}
 */
proto.core.private.companyretentionstep.GetDiscountOfferResponse.DiscountOffer.prototype.getEligibilityCase = function() {
  return /** @type {proto.core.private.companyretentionstep.GetDiscountOfferResponse.DiscountOffer.EligibilityCase} */(jspb.Message.computeOneofCase(this, proto.core.private.companyretentionstep.GetDiscountOfferResponse.DiscountOffer.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.core.private.companyretentionstep.GetDiscountOfferResponse.DiscountOffer.prototype.toObject = function(opt_includeInstance) {
  return proto.core.private.companyretentionstep.GetDiscountOfferResponse.DiscountOffer.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.core.private.companyretentionstep.GetDiscountOfferResponse.DiscountOffer} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.core.private.companyretentionstep.GetDiscountOfferResponse.DiscountOffer.toObject = function(includeInstance, msg) {
  var f, obj = {
    percentage: jspb.Message.getFieldWithDefault(msg, 1, 0),
    expiryFrequency: jspb.Message.getFieldWithDefault(msg, 2, 0),
    expiryFrequencyType: jspb.Message.getFieldWithDefault(msg, 3, 0),
    enabledAllCustomers: jspb.Message.getBooleanFieldWithDefault(msg, 4, false),
    minimumTenure: (f = msg.getMinimumTenure()) && proto.core.private.companyretentionstep.GetDiscountOfferResponse.DiscountOffer.MinimumTenure.toObject(includeInstance, f),
    limitToOneRetentionDiscountPerSub: jspb.Message.getBooleanFieldWithDefault(msg, 6, false),
    exitIntentImageUrl: jspb.Message.getFieldWithDefault(msg, 7, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.core.private.companyretentionstep.GetDiscountOfferResponse.DiscountOffer}
 */
proto.core.private.companyretentionstep.GetDiscountOfferResponse.DiscountOffer.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.core.private.companyretentionstep.GetDiscountOfferResponse.DiscountOffer;
  return proto.core.private.companyretentionstep.GetDiscountOfferResponse.DiscountOffer.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.core.private.companyretentionstep.GetDiscountOfferResponse.DiscountOffer} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.core.private.companyretentionstep.GetDiscountOfferResponse.DiscountOffer}
 */
proto.core.private.companyretentionstep.GetDiscountOfferResponse.DiscountOffer.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPercentage(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setExpiryFrequency(value);
      break;
    case 3:
      var value = /** @type {!proto.core.private.companyretentionstep.FrequencyType} */ (reader.readEnum());
      msg.setExpiryFrequencyType(value);
      break;
    case 4:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setEnabledAllCustomers(value);
      break;
    case 5:
      var value = new proto.core.private.companyretentionstep.GetDiscountOfferResponse.DiscountOffer.MinimumTenure;
      reader.readMessage(value,proto.core.private.companyretentionstep.GetDiscountOfferResponse.DiscountOffer.MinimumTenure.deserializeBinaryFromReader);
      msg.setMinimumTenure(value);
      break;
    case 6:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setLimitToOneRetentionDiscountPerSub(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setExitIntentImageUrl(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.core.private.companyretentionstep.GetDiscountOfferResponse.DiscountOffer.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.core.private.companyretentionstep.GetDiscountOfferResponse.DiscountOffer.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.core.private.companyretentionstep.GetDiscountOfferResponse.DiscountOffer} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.core.private.companyretentionstep.GetDiscountOfferResponse.DiscountOffer.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPercentage();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getExpiryFrequency();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getExpiryFrequencyType();
  if (f !== 0.0) {
    writer.writeEnum(
      3,
      f
    );
  }
  f = /** @type {boolean} */ (jspb.Message.getField(message, 4));
  if (f != null) {
    writer.writeBool(
      4,
      f
    );
  }
  f = message.getMinimumTenure();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      proto.core.private.companyretentionstep.GetDiscountOfferResponse.DiscountOffer.MinimumTenure.serializeBinaryToWriter
    );
  }
  f = message.getLimitToOneRetentionDiscountPerSub();
  if (f) {
    writer.writeBool(
      6,
      f
    );
  }
  f = message.getExitIntentImageUrl();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.core.private.companyretentionstep.GetDiscountOfferResponse.DiscountOffer.MinimumTenure.prototype.toObject = function(opt_includeInstance) {
  return proto.core.private.companyretentionstep.GetDiscountOfferResponse.DiscountOffer.MinimumTenure.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.core.private.companyretentionstep.GetDiscountOfferResponse.DiscountOffer.MinimumTenure} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.core.private.companyretentionstep.GetDiscountOfferResponse.DiscountOffer.MinimumTenure.toObject = function(includeInstance, msg) {
  var f, obj = {
    frequency: jspb.Message.getFieldWithDefault(msg, 1, 0),
    frequencyType: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.core.private.companyretentionstep.GetDiscountOfferResponse.DiscountOffer.MinimumTenure}
 */
proto.core.private.companyretentionstep.GetDiscountOfferResponse.DiscountOffer.MinimumTenure.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.core.private.companyretentionstep.GetDiscountOfferResponse.DiscountOffer.MinimumTenure;
  return proto.core.private.companyretentionstep.GetDiscountOfferResponse.DiscountOffer.MinimumTenure.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.core.private.companyretentionstep.GetDiscountOfferResponse.DiscountOffer.MinimumTenure} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.core.private.companyretentionstep.GetDiscountOfferResponse.DiscountOffer.MinimumTenure}
 */
proto.core.private.companyretentionstep.GetDiscountOfferResponse.DiscountOffer.MinimumTenure.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setFrequency(value);
      break;
    case 2:
      var value = /** @type {!proto.core.private.companyretentionstep.FrequencyType} */ (reader.readEnum());
      msg.setFrequencyType(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.core.private.companyretentionstep.GetDiscountOfferResponse.DiscountOffer.MinimumTenure.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.core.private.companyretentionstep.GetDiscountOfferResponse.DiscountOffer.MinimumTenure.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.core.private.companyretentionstep.GetDiscountOfferResponse.DiscountOffer.MinimumTenure} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.core.private.companyretentionstep.GetDiscountOfferResponse.DiscountOffer.MinimumTenure.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFrequency();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getFrequencyType();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
};


/**
 * optional int32 frequency = 1;
 * @return {number}
 */
proto.core.private.companyretentionstep.GetDiscountOfferResponse.DiscountOffer.MinimumTenure.prototype.getFrequency = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.core.private.companyretentionstep.GetDiscountOfferResponse.DiscountOffer.MinimumTenure} returns this
 */
proto.core.private.companyretentionstep.GetDiscountOfferResponse.DiscountOffer.MinimumTenure.prototype.setFrequency = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional FrequencyType frequency_type = 2;
 * @return {!proto.core.private.companyretentionstep.FrequencyType}
 */
proto.core.private.companyretentionstep.GetDiscountOfferResponse.DiscountOffer.MinimumTenure.prototype.getFrequencyType = function() {
  return /** @type {!proto.core.private.companyretentionstep.FrequencyType} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.core.private.companyretentionstep.FrequencyType} value
 * @return {!proto.core.private.companyretentionstep.GetDiscountOfferResponse.DiscountOffer.MinimumTenure} returns this
 */
proto.core.private.companyretentionstep.GetDiscountOfferResponse.DiscountOffer.MinimumTenure.prototype.setFrequencyType = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};


/**
 * optional int32 percentage = 1;
 * @return {number}
 */
proto.core.private.companyretentionstep.GetDiscountOfferResponse.DiscountOffer.prototype.getPercentage = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.core.private.companyretentionstep.GetDiscountOfferResponse.DiscountOffer} returns this
 */
proto.core.private.companyretentionstep.GetDiscountOfferResponse.DiscountOffer.prototype.setPercentage = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int32 expiry_frequency = 2;
 * @return {number}
 */
proto.core.private.companyretentionstep.GetDiscountOfferResponse.DiscountOffer.prototype.getExpiryFrequency = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.core.private.companyretentionstep.GetDiscountOfferResponse.DiscountOffer} returns this
 */
proto.core.private.companyretentionstep.GetDiscountOfferResponse.DiscountOffer.prototype.setExpiryFrequency = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional FrequencyType expiry_frequency_type = 3;
 * @return {!proto.core.private.companyretentionstep.FrequencyType}
 */
proto.core.private.companyretentionstep.GetDiscountOfferResponse.DiscountOffer.prototype.getExpiryFrequencyType = function() {
  return /** @type {!proto.core.private.companyretentionstep.FrequencyType} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {!proto.core.private.companyretentionstep.FrequencyType} value
 * @return {!proto.core.private.companyretentionstep.GetDiscountOfferResponse.DiscountOffer} returns this
 */
proto.core.private.companyretentionstep.GetDiscountOfferResponse.DiscountOffer.prototype.setExpiryFrequencyType = function(value) {
  return jspb.Message.setProto3EnumField(this, 3, value);
};


/**
 * optional bool enabled_all_customers = 4;
 * @return {boolean}
 */
proto.core.private.companyretentionstep.GetDiscountOfferResponse.DiscountOffer.prototype.getEnabledAllCustomers = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 4, false));
};


/**
 * @param {boolean} value
 * @return {!proto.core.private.companyretentionstep.GetDiscountOfferResponse.DiscountOffer} returns this
 */
proto.core.private.companyretentionstep.GetDiscountOfferResponse.DiscountOffer.prototype.setEnabledAllCustomers = function(value) {
  return jspb.Message.setOneofField(this, 4, proto.core.private.companyretentionstep.GetDiscountOfferResponse.DiscountOffer.oneofGroups_[0], value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.core.private.companyretentionstep.GetDiscountOfferResponse.DiscountOffer} returns this
 */
proto.core.private.companyretentionstep.GetDiscountOfferResponse.DiscountOffer.prototype.clearEnabledAllCustomers = function() {
  return jspb.Message.setOneofField(this, 4, proto.core.private.companyretentionstep.GetDiscountOfferResponse.DiscountOffer.oneofGroups_[0], undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.core.private.companyretentionstep.GetDiscountOfferResponse.DiscountOffer.prototype.hasEnabledAllCustomers = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional MinimumTenure minimum_tenure = 5;
 * @return {?proto.core.private.companyretentionstep.GetDiscountOfferResponse.DiscountOffer.MinimumTenure}
 */
proto.core.private.companyretentionstep.GetDiscountOfferResponse.DiscountOffer.prototype.getMinimumTenure = function() {
  return /** @type{?proto.core.private.companyretentionstep.GetDiscountOfferResponse.DiscountOffer.MinimumTenure} */ (
    jspb.Message.getWrapperField(this, proto.core.private.companyretentionstep.GetDiscountOfferResponse.DiscountOffer.MinimumTenure, 5));
};


/**
 * @param {?proto.core.private.companyretentionstep.GetDiscountOfferResponse.DiscountOffer.MinimumTenure|undefined} value
 * @return {!proto.core.private.companyretentionstep.GetDiscountOfferResponse.DiscountOffer} returns this
*/
proto.core.private.companyretentionstep.GetDiscountOfferResponse.DiscountOffer.prototype.setMinimumTenure = function(value) {
  return jspb.Message.setOneofWrapperField(this, 5, proto.core.private.companyretentionstep.GetDiscountOfferResponse.DiscountOffer.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.core.private.companyretentionstep.GetDiscountOfferResponse.DiscountOffer} returns this
 */
proto.core.private.companyretentionstep.GetDiscountOfferResponse.DiscountOffer.prototype.clearMinimumTenure = function() {
  return this.setMinimumTenure(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.core.private.companyretentionstep.GetDiscountOfferResponse.DiscountOffer.prototype.hasMinimumTenure = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional bool limit_to_one_retention_discount_per_sub = 6;
 * @return {boolean}
 */
proto.core.private.companyretentionstep.GetDiscountOfferResponse.DiscountOffer.prototype.getLimitToOneRetentionDiscountPerSub = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 6, false));
};


/**
 * @param {boolean} value
 * @return {!proto.core.private.companyretentionstep.GetDiscountOfferResponse.DiscountOffer} returns this
 */
proto.core.private.companyretentionstep.GetDiscountOfferResponse.DiscountOffer.prototype.setLimitToOneRetentionDiscountPerSub = function(value) {
  return jspb.Message.setProto3BooleanField(this, 6, value);
};


/**
 * optional string exit_intent_image_url = 7;
 * @return {string}
 */
proto.core.private.companyretentionstep.GetDiscountOfferResponse.DiscountOffer.prototype.getExitIntentImageUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.core.private.companyretentionstep.GetDiscountOfferResponse.DiscountOffer} returns this
 */
proto.core.private.companyretentionstep.GetDiscountOfferResponse.DiscountOffer.prototype.setExitIntentImageUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional bool is_disabled = 1;
 * @return {boolean}
 */
proto.core.private.companyretentionstep.GetDiscountOfferResponse.prototype.getIsDisabled = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.core.private.companyretentionstep.GetDiscountOfferResponse} returns this
 */
proto.core.private.companyretentionstep.GetDiscountOfferResponse.prototype.setIsDisabled = function(value) {
  return jspb.Message.setOneofField(this, 1, proto.core.private.companyretentionstep.GetDiscountOfferResponse.oneofGroups_[0], value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.core.private.companyretentionstep.GetDiscountOfferResponse} returns this
 */
proto.core.private.companyretentionstep.GetDiscountOfferResponse.prototype.clearIsDisabled = function() {
  return jspb.Message.setOneofField(this, 1, proto.core.private.companyretentionstep.GetDiscountOfferResponse.oneofGroups_[0], undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.core.private.companyretentionstep.GetDiscountOfferResponse.prototype.hasIsDisabled = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional DiscountOffer discount_offer = 2;
 * @return {?proto.core.private.companyretentionstep.GetDiscountOfferResponse.DiscountOffer}
 */
proto.core.private.companyretentionstep.GetDiscountOfferResponse.prototype.getDiscountOffer = function() {
  return /** @type{?proto.core.private.companyretentionstep.GetDiscountOfferResponse.DiscountOffer} */ (
    jspb.Message.getWrapperField(this, proto.core.private.companyretentionstep.GetDiscountOfferResponse.DiscountOffer, 2));
};


/**
 * @param {?proto.core.private.companyretentionstep.GetDiscountOfferResponse.DiscountOffer|undefined} value
 * @return {!proto.core.private.companyretentionstep.GetDiscountOfferResponse} returns this
*/
proto.core.private.companyretentionstep.GetDiscountOfferResponse.prototype.setDiscountOffer = function(value) {
  return jspb.Message.setOneofWrapperField(this, 2, proto.core.private.companyretentionstep.GetDiscountOfferResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.core.private.companyretentionstep.GetDiscountOfferResponse} returns this
 */
proto.core.private.companyretentionstep.GetDiscountOfferResponse.prototype.clearDiscountOffer = function() {
  return this.setDiscountOffer(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.core.private.companyretentionstep.GetDiscountOfferResponse.prototype.hasDiscountOffer = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.core.private.companyretentionstep.UpsertHelpInterruptRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.core.private.companyretentionstep.UpsertHelpInterruptRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.core.private.companyretentionstep.UpsertHelpInterruptRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.core.private.companyretentionstep.UpsertHelpInterruptRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    companyDomain: jspb.Message.getFieldWithDefault(msg, 1, ""),
    title: jspb.Message.getFieldWithDefault(msg, 3, ""),
    subtitle: jspb.Message.getFieldWithDefault(msg, 4, ""),
    provideEmailAddressEnabled: jspb.Message.getBooleanFieldWithDefault(msg, 5, false),
    email: jspb.Message.getFieldWithDefault(msg, 6, ""),
    providePhoneNumberEnabled: jspb.Message.getBooleanFieldWithDefault(msg, 7, false),
    phoneNumberDialCode: jspb.Message.getFieldWithDefault(msg, 8, ""),
    phoneNumberDialCountry: jspb.Message.getFieldWithDefault(msg, 9, ""),
    phoneNumber: jspb.Message.getFieldWithDefault(msg, 10, ""),
    supportPageUrl: jspb.Message.getFieldWithDefault(msg, 11, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.core.private.companyretentionstep.UpsertHelpInterruptRequest}
 */
proto.core.private.companyretentionstep.UpsertHelpInterruptRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.core.private.companyretentionstep.UpsertHelpInterruptRequest;
  return proto.core.private.companyretentionstep.UpsertHelpInterruptRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.core.private.companyretentionstep.UpsertHelpInterruptRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.core.private.companyretentionstep.UpsertHelpInterruptRequest}
 */
proto.core.private.companyretentionstep.UpsertHelpInterruptRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setCompanyDomain(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setTitle(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setSubtitle(value);
      break;
    case 5:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setProvideEmailAddressEnabled(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setEmail(value);
      break;
    case 7:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setProvidePhoneNumberEnabled(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setPhoneNumberDialCode(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setPhoneNumberDialCountry(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setPhoneNumber(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.setSupportPageUrl(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.core.private.companyretentionstep.UpsertHelpInterruptRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.core.private.companyretentionstep.UpsertHelpInterruptRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.core.private.companyretentionstep.UpsertHelpInterruptRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.core.private.companyretentionstep.UpsertHelpInterruptRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCompanyDomain();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getTitle();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getSubtitle();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getProvideEmailAddressEnabled();
  if (f) {
    writer.writeBool(
      5,
      f
    );
  }
  f = message.getEmail();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getProvidePhoneNumberEnabled();
  if (f) {
    writer.writeBool(
      7,
      f
    );
  }
  f = message.getPhoneNumberDialCode();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getPhoneNumberDialCountry();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getPhoneNumber();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getSupportPageUrl();
  if (f.length > 0) {
    writer.writeString(
      11,
      f
    );
  }
};


/**
 * optional string company_domain = 1;
 * @return {string}
 */
proto.core.private.companyretentionstep.UpsertHelpInterruptRequest.prototype.getCompanyDomain = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.core.private.companyretentionstep.UpsertHelpInterruptRequest} returns this
 */
proto.core.private.companyretentionstep.UpsertHelpInterruptRequest.prototype.setCompanyDomain = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string title = 3;
 * @return {string}
 */
proto.core.private.companyretentionstep.UpsertHelpInterruptRequest.prototype.getTitle = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.core.private.companyretentionstep.UpsertHelpInterruptRequest} returns this
 */
proto.core.private.companyretentionstep.UpsertHelpInterruptRequest.prototype.setTitle = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string subtitle = 4;
 * @return {string}
 */
proto.core.private.companyretentionstep.UpsertHelpInterruptRequest.prototype.getSubtitle = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.core.private.companyretentionstep.UpsertHelpInterruptRequest} returns this
 */
proto.core.private.companyretentionstep.UpsertHelpInterruptRequest.prototype.setSubtitle = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional bool provide_email_address_enabled = 5;
 * @return {boolean}
 */
proto.core.private.companyretentionstep.UpsertHelpInterruptRequest.prototype.getProvideEmailAddressEnabled = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 5, false));
};


/**
 * @param {boolean} value
 * @return {!proto.core.private.companyretentionstep.UpsertHelpInterruptRequest} returns this
 */
proto.core.private.companyretentionstep.UpsertHelpInterruptRequest.prototype.setProvideEmailAddressEnabled = function(value) {
  return jspb.Message.setProto3BooleanField(this, 5, value);
};


/**
 * optional string email = 6;
 * @return {string}
 */
proto.core.private.companyretentionstep.UpsertHelpInterruptRequest.prototype.getEmail = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.core.private.companyretentionstep.UpsertHelpInterruptRequest} returns this
 */
proto.core.private.companyretentionstep.UpsertHelpInterruptRequest.prototype.setEmail = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional bool provide_phone_number_enabled = 7;
 * @return {boolean}
 */
proto.core.private.companyretentionstep.UpsertHelpInterruptRequest.prototype.getProvidePhoneNumberEnabled = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 7, false));
};


/**
 * @param {boolean} value
 * @return {!proto.core.private.companyretentionstep.UpsertHelpInterruptRequest} returns this
 */
proto.core.private.companyretentionstep.UpsertHelpInterruptRequest.prototype.setProvidePhoneNumberEnabled = function(value) {
  return jspb.Message.setProto3BooleanField(this, 7, value);
};


/**
 * optional string phone_number_dial_code = 8;
 * @return {string}
 */
proto.core.private.companyretentionstep.UpsertHelpInterruptRequest.prototype.getPhoneNumberDialCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.core.private.companyretentionstep.UpsertHelpInterruptRequest} returns this
 */
proto.core.private.companyretentionstep.UpsertHelpInterruptRequest.prototype.setPhoneNumberDialCode = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional string phone_number_dial_country = 9;
 * @return {string}
 */
proto.core.private.companyretentionstep.UpsertHelpInterruptRequest.prototype.getPhoneNumberDialCountry = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.core.private.companyretentionstep.UpsertHelpInterruptRequest} returns this
 */
proto.core.private.companyretentionstep.UpsertHelpInterruptRequest.prototype.setPhoneNumberDialCountry = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional string phone_number = 10;
 * @return {string}
 */
proto.core.private.companyretentionstep.UpsertHelpInterruptRequest.prototype.getPhoneNumber = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.core.private.companyretentionstep.UpsertHelpInterruptRequest} returns this
 */
proto.core.private.companyretentionstep.UpsertHelpInterruptRequest.prototype.setPhoneNumber = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * optional string support_page_url = 11;
 * @return {string}
 */
proto.core.private.companyretentionstep.UpsertHelpInterruptRequest.prototype.getSupportPageUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};


/**
 * @param {string} value
 * @return {!proto.core.private.companyretentionstep.UpsertHelpInterruptRequest} returns this
 */
proto.core.private.companyretentionstep.UpsertHelpInterruptRequest.prototype.setSupportPageUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 11, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.core.private.companyretentionstep.UpsertHelpInterruptResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.core.private.companyretentionstep.UpsertHelpInterruptResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.core.private.companyretentionstep.UpsertHelpInterruptResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.core.private.companyretentionstep.UpsertHelpInterruptResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    title: jspb.Message.getFieldWithDefault(msg, 1, ""),
    subtitle: jspb.Message.getFieldWithDefault(msg, 2, ""),
    email: jspb.Message.getFieldWithDefault(msg, 3, ""),
    phoneNumberDialCode: jspb.Message.getFieldWithDefault(msg, 4, ""),
    phoneNumberDialCountry: jspb.Message.getFieldWithDefault(msg, 5, ""),
    phoneNumber: jspb.Message.getFieldWithDefault(msg, 6, ""),
    supportPageUrl: jspb.Message.getFieldWithDefault(msg, 7, ""),
    isEnabled: jspb.Message.getBooleanFieldWithDefault(msg, 8, false),
    orderSequenceNumber: jspb.Message.getFieldWithDefault(msg, 9, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.core.private.companyretentionstep.UpsertHelpInterruptResponse}
 */
proto.core.private.companyretentionstep.UpsertHelpInterruptResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.core.private.companyretentionstep.UpsertHelpInterruptResponse;
  return proto.core.private.companyretentionstep.UpsertHelpInterruptResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.core.private.companyretentionstep.UpsertHelpInterruptResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.core.private.companyretentionstep.UpsertHelpInterruptResponse}
 */
proto.core.private.companyretentionstep.UpsertHelpInterruptResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setTitle(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setSubtitle(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setEmail(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setPhoneNumberDialCode(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setPhoneNumberDialCountry(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setPhoneNumber(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setSupportPageUrl(value);
      break;
    case 8:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsEnabled(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setOrderSequenceNumber(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.core.private.companyretentionstep.UpsertHelpInterruptResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.core.private.companyretentionstep.UpsertHelpInterruptResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.core.private.companyretentionstep.UpsertHelpInterruptResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.core.private.companyretentionstep.UpsertHelpInterruptResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTitle();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getSubtitle();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getEmail();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getPhoneNumberDialCode();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getPhoneNumberDialCountry();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getPhoneNumber();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getSupportPageUrl();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getIsEnabled();
  if (f) {
    writer.writeBool(
      8,
      f
    );
  }
  f = message.getOrderSequenceNumber();
  if (f !== 0) {
    writer.writeInt32(
      9,
      f
    );
  }
};


/**
 * optional string title = 1;
 * @return {string}
 */
proto.core.private.companyretentionstep.UpsertHelpInterruptResponse.prototype.getTitle = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.core.private.companyretentionstep.UpsertHelpInterruptResponse} returns this
 */
proto.core.private.companyretentionstep.UpsertHelpInterruptResponse.prototype.setTitle = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string subtitle = 2;
 * @return {string}
 */
proto.core.private.companyretentionstep.UpsertHelpInterruptResponse.prototype.getSubtitle = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.core.private.companyretentionstep.UpsertHelpInterruptResponse} returns this
 */
proto.core.private.companyretentionstep.UpsertHelpInterruptResponse.prototype.setSubtitle = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string email = 3;
 * @return {string}
 */
proto.core.private.companyretentionstep.UpsertHelpInterruptResponse.prototype.getEmail = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.core.private.companyretentionstep.UpsertHelpInterruptResponse} returns this
 */
proto.core.private.companyretentionstep.UpsertHelpInterruptResponse.prototype.setEmail = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string phone_number_dial_code = 4;
 * @return {string}
 */
proto.core.private.companyretentionstep.UpsertHelpInterruptResponse.prototype.getPhoneNumberDialCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.core.private.companyretentionstep.UpsertHelpInterruptResponse} returns this
 */
proto.core.private.companyretentionstep.UpsertHelpInterruptResponse.prototype.setPhoneNumberDialCode = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string phone_number_dial_country = 5;
 * @return {string}
 */
proto.core.private.companyretentionstep.UpsertHelpInterruptResponse.prototype.getPhoneNumberDialCountry = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.core.private.companyretentionstep.UpsertHelpInterruptResponse} returns this
 */
proto.core.private.companyretentionstep.UpsertHelpInterruptResponse.prototype.setPhoneNumberDialCountry = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string phone_number = 6;
 * @return {string}
 */
proto.core.private.companyretentionstep.UpsertHelpInterruptResponse.prototype.getPhoneNumber = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.core.private.companyretentionstep.UpsertHelpInterruptResponse} returns this
 */
proto.core.private.companyretentionstep.UpsertHelpInterruptResponse.prototype.setPhoneNumber = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string support_page_url = 7;
 * @return {string}
 */
proto.core.private.companyretentionstep.UpsertHelpInterruptResponse.prototype.getSupportPageUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.core.private.companyretentionstep.UpsertHelpInterruptResponse} returns this
 */
proto.core.private.companyretentionstep.UpsertHelpInterruptResponse.prototype.setSupportPageUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional bool is_enabled = 8;
 * @return {boolean}
 */
proto.core.private.companyretentionstep.UpsertHelpInterruptResponse.prototype.getIsEnabled = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 8, false));
};


/**
 * @param {boolean} value
 * @return {!proto.core.private.companyretentionstep.UpsertHelpInterruptResponse} returns this
 */
proto.core.private.companyretentionstep.UpsertHelpInterruptResponse.prototype.setIsEnabled = function(value) {
  return jspb.Message.setProto3BooleanField(this, 8, value);
};


/**
 * optional int32 order_sequence_number = 9;
 * @return {number}
 */
proto.core.private.companyretentionstep.UpsertHelpInterruptResponse.prototype.getOrderSequenceNumber = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/**
 * @param {number} value
 * @return {!proto.core.private.companyretentionstep.UpsertHelpInterruptResponse} returns this
 */
proto.core.private.companyretentionstep.UpsertHelpInterruptResponse.prototype.setOrderSequenceNumber = function(value) {
  return jspb.Message.setProto3IntField(this, 9, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.core.private.companyretentionstep.GetHelpInterruptRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.core.private.companyretentionstep.GetHelpInterruptRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.core.private.companyretentionstep.GetHelpInterruptRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.core.private.companyretentionstep.GetHelpInterruptRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    companyDomain: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.core.private.companyretentionstep.GetHelpInterruptRequest}
 */
proto.core.private.companyretentionstep.GetHelpInterruptRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.core.private.companyretentionstep.GetHelpInterruptRequest;
  return proto.core.private.companyretentionstep.GetHelpInterruptRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.core.private.companyretentionstep.GetHelpInterruptRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.core.private.companyretentionstep.GetHelpInterruptRequest}
 */
proto.core.private.companyretentionstep.GetHelpInterruptRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setCompanyDomain(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.core.private.companyretentionstep.GetHelpInterruptRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.core.private.companyretentionstep.GetHelpInterruptRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.core.private.companyretentionstep.GetHelpInterruptRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.core.private.companyretentionstep.GetHelpInterruptRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCompanyDomain();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string company_domain = 1;
 * @return {string}
 */
proto.core.private.companyretentionstep.GetHelpInterruptRequest.prototype.getCompanyDomain = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.core.private.companyretentionstep.GetHelpInterruptRequest} returns this
 */
proto.core.private.companyretentionstep.GetHelpInterruptRequest.prototype.setCompanyDomain = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.core.private.companyretentionstep.GetHelpInterruptResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.core.private.companyretentionstep.GetHelpInterruptResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.core.private.companyretentionstep.GetHelpInterruptResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.core.private.companyretentionstep.GetHelpInterruptResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    title: jspb.Message.getFieldWithDefault(msg, 1, ""),
    subtitle: jspb.Message.getFieldWithDefault(msg, 2, ""),
    email: jspb.Message.getFieldWithDefault(msg, 3, ""),
    phoneNumberDialCode: jspb.Message.getFieldWithDefault(msg, 4, ""),
    phoneNumberDialCountry: jspb.Message.getFieldWithDefault(msg, 5, ""),
    phoneNumber: jspb.Message.getFieldWithDefault(msg, 6, ""),
    supportPageUrl: jspb.Message.getFieldWithDefault(msg, 7, ""),
    isEnabled: jspb.Message.getBooleanFieldWithDefault(msg, 8, false),
    orderSequenceNumber: jspb.Message.getFieldWithDefault(msg, 9, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.core.private.companyretentionstep.GetHelpInterruptResponse}
 */
proto.core.private.companyretentionstep.GetHelpInterruptResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.core.private.companyretentionstep.GetHelpInterruptResponse;
  return proto.core.private.companyretentionstep.GetHelpInterruptResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.core.private.companyretentionstep.GetHelpInterruptResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.core.private.companyretentionstep.GetHelpInterruptResponse}
 */
proto.core.private.companyretentionstep.GetHelpInterruptResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setTitle(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setSubtitle(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setEmail(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setPhoneNumberDialCode(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setPhoneNumberDialCountry(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setPhoneNumber(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setSupportPageUrl(value);
      break;
    case 8:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsEnabled(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setOrderSequenceNumber(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.core.private.companyretentionstep.GetHelpInterruptResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.core.private.companyretentionstep.GetHelpInterruptResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.core.private.companyretentionstep.GetHelpInterruptResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.core.private.companyretentionstep.GetHelpInterruptResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTitle();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getSubtitle();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getEmail();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getPhoneNumberDialCode();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getPhoneNumberDialCountry();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getPhoneNumber();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getSupportPageUrl();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getIsEnabled();
  if (f) {
    writer.writeBool(
      8,
      f
    );
  }
  f = message.getOrderSequenceNumber();
  if (f !== 0) {
    writer.writeInt32(
      9,
      f
    );
  }
};


/**
 * optional string title = 1;
 * @return {string}
 */
proto.core.private.companyretentionstep.GetHelpInterruptResponse.prototype.getTitle = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.core.private.companyretentionstep.GetHelpInterruptResponse} returns this
 */
proto.core.private.companyretentionstep.GetHelpInterruptResponse.prototype.setTitle = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string subtitle = 2;
 * @return {string}
 */
proto.core.private.companyretentionstep.GetHelpInterruptResponse.prototype.getSubtitle = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.core.private.companyretentionstep.GetHelpInterruptResponse} returns this
 */
proto.core.private.companyretentionstep.GetHelpInterruptResponse.prototype.setSubtitle = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string email = 3;
 * @return {string}
 */
proto.core.private.companyretentionstep.GetHelpInterruptResponse.prototype.getEmail = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.core.private.companyretentionstep.GetHelpInterruptResponse} returns this
 */
proto.core.private.companyretentionstep.GetHelpInterruptResponse.prototype.setEmail = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string phone_number_dial_code = 4;
 * @return {string}
 */
proto.core.private.companyretentionstep.GetHelpInterruptResponse.prototype.getPhoneNumberDialCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.core.private.companyretentionstep.GetHelpInterruptResponse} returns this
 */
proto.core.private.companyretentionstep.GetHelpInterruptResponse.prototype.setPhoneNumberDialCode = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string phone_number_dial_country = 5;
 * @return {string}
 */
proto.core.private.companyretentionstep.GetHelpInterruptResponse.prototype.getPhoneNumberDialCountry = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.core.private.companyretentionstep.GetHelpInterruptResponse} returns this
 */
proto.core.private.companyretentionstep.GetHelpInterruptResponse.prototype.setPhoneNumberDialCountry = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string phone_number = 6;
 * @return {string}
 */
proto.core.private.companyretentionstep.GetHelpInterruptResponse.prototype.getPhoneNumber = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.core.private.companyretentionstep.GetHelpInterruptResponse} returns this
 */
proto.core.private.companyretentionstep.GetHelpInterruptResponse.prototype.setPhoneNumber = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string support_page_url = 7;
 * @return {string}
 */
proto.core.private.companyretentionstep.GetHelpInterruptResponse.prototype.getSupportPageUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.core.private.companyretentionstep.GetHelpInterruptResponse} returns this
 */
proto.core.private.companyretentionstep.GetHelpInterruptResponse.prototype.setSupportPageUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional bool is_enabled = 8;
 * @return {boolean}
 */
proto.core.private.companyretentionstep.GetHelpInterruptResponse.prototype.getIsEnabled = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 8, false));
};


/**
 * @param {boolean} value
 * @return {!proto.core.private.companyretentionstep.GetHelpInterruptResponse} returns this
 */
proto.core.private.companyretentionstep.GetHelpInterruptResponse.prototype.setIsEnabled = function(value) {
  return jspb.Message.setProto3BooleanField(this, 8, value);
};


/**
 * optional int32 order_sequence_number = 9;
 * @return {number}
 */
proto.core.private.companyretentionstep.GetHelpInterruptResponse.prototype.getOrderSequenceNumber = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/**
 * @param {number} value
 * @return {!proto.core.private.companyretentionstep.GetHelpInterruptResponse} returns this
 */
proto.core.private.companyretentionstep.GetHelpInterruptResponse.prototype.setOrderSequenceNumber = function(value) {
  return jspb.Message.setProto3IntField(this, 9, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.core.private.companyretentionstep.UpsertPlanChangeRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.core.private.companyretentionstep.UpsertPlanChangeRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.core.private.companyretentionstep.UpsertPlanChangeRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.core.private.companyretentionstep.UpsertPlanChangeRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    companyDomain: jspb.Message.getFieldWithDefault(msg, 1, ""),
    text: jspb.Message.getFieldWithDefault(msg, 2, ""),
    subText: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.core.private.companyretentionstep.UpsertPlanChangeRequest}
 */
proto.core.private.companyretentionstep.UpsertPlanChangeRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.core.private.companyretentionstep.UpsertPlanChangeRequest;
  return proto.core.private.companyretentionstep.UpsertPlanChangeRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.core.private.companyretentionstep.UpsertPlanChangeRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.core.private.companyretentionstep.UpsertPlanChangeRequest}
 */
proto.core.private.companyretentionstep.UpsertPlanChangeRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setCompanyDomain(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setText(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setSubText(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.core.private.companyretentionstep.UpsertPlanChangeRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.core.private.companyretentionstep.UpsertPlanChangeRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.core.private.companyretentionstep.UpsertPlanChangeRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.core.private.companyretentionstep.UpsertPlanChangeRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCompanyDomain();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getText();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getSubText();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional string company_domain = 1;
 * @return {string}
 */
proto.core.private.companyretentionstep.UpsertPlanChangeRequest.prototype.getCompanyDomain = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.core.private.companyretentionstep.UpsertPlanChangeRequest} returns this
 */
proto.core.private.companyretentionstep.UpsertPlanChangeRequest.prototype.setCompanyDomain = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string text = 2;
 * @return {string}
 */
proto.core.private.companyretentionstep.UpsertPlanChangeRequest.prototype.getText = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.core.private.companyretentionstep.UpsertPlanChangeRequest} returns this
 */
proto.core.private.companyretentionstep.UpsertPlanChangeRequest.prototype.setText = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string sub_text = 3;
 * @return {string}
 */
proto.core.private.companyretentionstep.UpsertPlanChangeRequest.prototype.getSubText = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.core.private.companyretentionstep.UpsertPlanChangeRequest} returns this
 */
proto.core.private.companyretentionstep.UpsertPlanChangeRequest.prototype.setSubText = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.core.private.companyretentionstep.UpsertPlanChangeResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.core.private.companyretentionstep.UpsertPlanChangeResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.core.private.companyretentionstep.UpsertPlanChangeResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.core.private.companyretentionstep.UpsertPlanChangeResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    text: jspb.Message.getFieldWithDefault(msg, 1, ""),
    isEnabled: jspb.Message.getBooleanFieldWithDefault(msg, 2, false),
    orderSequenceNumber: jspb.Message.getFieldWithDefault(msg, 3, 0),
    subText: jspb.Message.getFieldWithDefault(msg, 4, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.core.private.companyretentionstep.UpsertPlanChangeResponse}
 */
proto.core.private.companyretentionstep.UpsertPlanChangeResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.core.private.companyretentionstep.UpsertPlanChangeResponse;
  return proto.core.private.companyretentionstep.UpsertPlanChangeResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.core.private.companyretentionstep.UpsertPlanChangeResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.core.private.companyretentionstep.UpsertPlanChangeResponse}
 */
proto.core.private.companyretentionstep.UpsertPlanChangeResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setText(value);
      break;
    case 2:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsEnabled(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setOrderSequenceNumber(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setSubText(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.core.private.companyretentionstep.UpsertPlanChangeResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.core.private.companyretentionstep.UpsertPlanChangeResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.core.private.companyretentionstep.UpsertPlanChangeResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.core.private.companyretentionstep.UpsertPlanChangeResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getText();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getIsEnabled();
  if (f) {
    writer.writeBool(
      2,
      f
    );
  }
  f = message.getOrderSequenceNumber();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
  f = message.getSubText();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
};


/**
 * optional string text = 1;
 * @return {string}
 */
proto.core.private.companyretentionstep.UpsertPlanChangeResponse.prototype.getText = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.core.private.companyretentionstep.UpsertPlanChangeResponse} returns this
 */
proto.core.private.companyretentionstep.UpsertPlanChangeResponse.prototype.setText = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional bool is_enabled = 2;
 * @return {boolean}
 */
proto.core.private.companyretentionstep.UpsertPlanChangeResponse.prototype.getIsEnabled = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
};


/**
 * @param {boolean} value
 * @return {!proto.core.private.companyretentionstep.UpsertPlanChangeResponse} returns this
 */
proto.core.private.companyretentionstep.UpsertPlanChangeResponse.prototype.setIsEnabled = function(value) {
  return jspb.Message.setProto3BooleanField(this, 2, value);
};


/**
 * optional int32 order_sequence_number = 3;
 * @return {number}
 */
proto.core.private.companyretentionstep.UpsertPlanChangeResponse.prototype.getOrderSequenceNumber = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.core.private.companyretentionstep.UpsertPlanChangeResponse} returns this
 */
proto.core.private.companyretentionstep.UpsertPlanChangeResponse.prototype.setOrderSequenceNumber = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional string sub_text = 4;
 * @return {string}
 */
proto.core.private.companyretentionstep.UpsertPlanChangeResponse.prototype.getSubText = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.core.private.companyretentionstep.UpsertPlanChangeResponse} returns this
 */
proto.core.private.companyretentionstep.UpsertPlanChangeResponse.prototype.setSubText = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.core.private.companyretentionstep.GetPlanChangeRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.core.private.companyretentionstep.GetPlanChangeRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.core.private.companyretentionstep.GetPlanChangeRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.core.private.companyretentionstep.GetPlanChangeRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    companyDomain: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.core.private.companyretentionstep.GetPlanChangeRequest}
 */
proto.core.private.companyretentionstep.GetPlanChangeRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.core.private.companyretentionstep.GetPlanChangeRequest;
  return proto.core.private.companyretentionstep.GetPlanChangeRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.core.private.companyretentionstep.GetPlanChangeRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.core.private.companyretentionstep.GetPlanChangeRequest}
 */
proto.core.private.companyretentionstep.GetPlanChangeRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setCompanyDomain(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.core.private.companyretentionstep.GetPlanChangeRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.core.private.companyretentionstep.GetPlanChangeRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.core.private.companyretentionstep.GetPlanChangeRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.core.private.companyretentionstep.GetPlanChangeRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCompanyDomain();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string company_domain = 1;
 * @return {string}
 */
proto.core.private.companyretentionstep.GetPlanChangeRequest.prototype.getCompanyDomain = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.core.private.companyretentionstep.GetPlanChangeRequest} returns this
 */
proto.core.private.companyretentionstep.GetPlanChangeRequest.prototype.setCompanyDomain = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.core.private.companyretentionstep.GetPlanChangeResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.core.private.companyretentionstep.GetPlanChangeResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.core.private.companyretentionstep.GetPlanChangeResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.core.private.companyretentionstep.GetPlanChangeResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    text: jspb.Message.getFieldWithDefault(msg, 1, ""),
    isEnabled: jspb.Message.getBooleanFieldWithDefault(msg, 2, false),
    orderSequenceNumber: jspb.Message.getFieldWithDefault(msg, 3, 0),
    subText: jspb.Message.getFieldWithDefault(msg, 4, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.core.private.companyretentionstep.GetPlanChangeResponse}
 */
proto.core.private.companyretentionstep.GetPlanChangeResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.core.private.companyretentionstep.GetPlanChangeResponse;
  return proto.core.private.companyretentionstep.GetPlanChangeResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.core.private.companyretentionstep.GetPlanChangeResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.core.private.companyretentionstep.GetPlanChangeResponse}
 */
proto.core.private.companyretentionstep.GetPlanChangeResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setText(value);
      break;
    case 2:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsEnabled(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setOrderSequenceNumber(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setSubText(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.core.private.companyretentionstep.GetPlanChangeResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.core.private.companyretentionstep.GetPlanChangeResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.core.private.companyretentionstep.GetPlanChangeResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.core.private.companyretentionstep.GetPlanChangeResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getText();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getIsEnabled();
  if (f) {
    writer.writeBool(
      2,
      f
    );
  }
  f = message.getOrderSequenceNumber();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
  f = message.getSubText();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
};


/**
 * optional string text = 1;
 * @return {string}
 */
proto.core.private.companyretentionstep.GetPlanChangeResponse.prototype.getText = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.core.private.companyretentionstep.GetPlanChangeResponse} returns this
 */
proto.core.private.companyretentionstep.GetPlanChangeResponse.prototype.setText = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional bool is_enabled = 2;
 * @return {boolean}
 */
proto.core.private.companyretentionstep.GetPlanChangeResponse.prototype.getIsEnabled = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
};


/**
 * @param {boolean} value
 * @return {!proto.core.private.companyretentionstep.GetPlanChangeResponse} returns this
 */
proto.core.private.companyretentionstep.GetPlanChangeResponse.prototype.setIsEnabled = function(value) {
  return jspb.Message.setProto3BooleanField(this, 2, value);
};


/**
 * optional int32 order_sequence_number = 3;
 * @return {number}
 */
proto.core.private.companyretentionstep.GetPlanChangeResponse.prototype.getOrderSequenceNumber = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.core.private.companyretentionstep.GetPlanChangeResponse} returns this
 */
proto.core.private.companyretentionstep.GetPlanChangeResponse.prototype.setOrderSequenceNumber = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional string sub_text = 4;
 * @return {string}
 */
proto.core.private.companyretentionstep.GetPlanChangeResponse.prototype.getSubText = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.core.private.companyretentionstep.GetPlanChangeResponse} returns this
 */
proto.core.private.companyretentionstep.GetPlanChangeResponse.prototype.setSubText = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.core.private.companyretentionstep.UpsertReasonsToStayRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.core.private.companyretentionstep.UpsertReasonsToStayRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.core.private.companyretentionstep.UpsertReasonsToStayRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.core.private.companyretentionstep.UpsertReasonsToStayRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    companyDomain: jspb.Message.getFieldWithDefault(msg, 1, ""),
    text1: jspb.Message.getFieldWithDefault(msg, 2, ""),
    text2: jspb.Message.getFieldWithDefault(msg, 3, ""),
    text3: jspb.Message.getFieldWithDefault(msg, 4, ""),
    text4: jspb.Message.getFieldWithDefault(msg, 5, ""),
    text5: jspb.Message.getFieldWithDefault(msg, 6, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.core.private.companyretentionstep.UpsertReasonsToStayRequest}
 */
proto.core.private.companyretentionstep.UpsertReasonsToStayRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.core.private.companyretentionstep.UpsertReasonsToStayRequest;
  return proto.core.private.companyretentionstep.UpsertReasonsToStayRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.core.private.companyretentionstep.UpsertReasonsToStayRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.core.private.companyretentionstep.UpsertReasonsToStayRequest}
 */
proto.core.private.companyretentionstep.UpsertReasonsToStayRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setCompanyDomain(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setText1(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setText2(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setText3(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setText4(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setText5(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.core.private.companyretentionstep.UpsertReasonsToStayRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.core.private.companyretentionstep.UpsertReasonsToStayRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.core.private.companyretentionstep.UpsertReasonsToStayRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.core.private.companyretentionstep.UpsertReasonsToStayRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCompanyDomain();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getText1();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getText2();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getText3();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getText4();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getText5();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
};


/**
 * optional string company_domain = 1;
 * @return {string}
 */
proto.core.private.companyretentionstep.UpsertReasonsToStayRequest.prototype.getCompanyDomain = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.core.private.companyretentionstep.UpsertReasonsToStayRequest} returns this
 */
proto.core.private.companyretentionstep.UpsertReasonsToStayRequest.prototype.setCompanyDomain = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string text1 = 2;
 * @return {string}
 */
proto.core.private.companyretentionstep.UpsertReasonsToStayRequest.prototype.getText1 = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.core.private.companyretentionstep.UpsertReasonsToStayRequest} returns this
 */
proto.core.private.companyretentionstep.UpsertReasonsToStayRequest.prototype.setText1 = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string text2 = 3;
 * @return {string}
 */
proto.core.private.companyretentionstep.UpsertReasonsToStayRequest.prototype.getText2 = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.core.private.companyretentionstep.UpsertReasonsToStayRequest} returns this
 */
proto.core.private.companyretentionstep.UpsertReasonsToStayRequest.prototype.setText2 = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string text3 = 4;
 * @return {string}
 */
proto.core.private.companyretentionstep.UpsertReasonsToStayRequest.prototype.getText3 = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.core.private.companyretentionstep.UpsertReasonsToStayRequest} returns this
 */
proto.core.private.companyretentionstep.UpsertReasonsToStayRequest.prototype.setText3 = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string text4 = 5;
 * @return {string}
 */
proto.core.private.companyretentionstep.UpsertReasonsToStayRequest.prototype.getText4 = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.core.private.companyretentionstep.UpsertReasonsToStayRequest} returns this
 */
proto.core.private.companyretentionstep.UpsertReasonsToStayRequest.prototype.setText4 = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string text5 = 6;
 * @return {string}
 */
proto.core.private.companyretentionstep.UpsertReasonsToStayRequest.prototype.getText5 = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.core.private.companyretentionstep.UpsertReasonsToStayRequest} returns this
 */
proto.core.private.companyretentionstep.UpsertReasonsToStayRequest.prototype.setText5 = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.core.private.companyretentionstep.UpsertReasonsToStayResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.core.private.companyretentionstep.UpsertReasonsToStayResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.core.private.companyretentionstep.UpsertReasonsToStayResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.core.private.companyretentionstep.UpsertReasonsToStayResponse.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.core.private.companyretentionstep.UpsertReasonsToStayResponse}
 */
proto.core.private.companyretentionstep.UpsertReasonsToStayResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.core.private.companyretentionstep.UpsertReasonsToStayResponse;
  return proto.core.private.companyretentionstep.UpsertReasonsToStayResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.core.private.companyretentionstep.UpsertReasonsToStayResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.core.private.companyretentionstep.UpsertReasonsToStayResponse}
 */
proto.core.private.companyretentionstep.UpsertReasonsToStayResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.core.private.companyretentionstep.UpsertReasonsToStayResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.core.private.companyretentionstep.UpsertReasonsToStayResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.core.private.companyretentionstep.UpsertReasonsToStayResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.core.private.companyretentionstep.UpsertReasonsToStayResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.core.private.companyretentionstep.GetReasonsToStayRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.core.private.companyretentionstep.GetReasonsToStayRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.core.private.companyretentionstep.GetReasonsToStayRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.core.private.companyretentionstep.GetReasonsToStayRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    companyDomain: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.core.private.companyretentionstep.GetReasonsToStayRequest}
 */
proto.core.private.companyretentionstep.GetReasonsToStayRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.core.private.companyretentionstep.GetReasonsToStayRequest;
  return proto.core.private.companyretentionstep.GetReasonsToStayRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.core.private.companyretentionstep.GetReasonsToStayRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.core.private.companyretentionstep.GetReasonsToStayRequest}
 */
proto.core.private.companyretentionstep.GetReasonsToStayRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setCompanyDomain(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.core.private.companyretentionstep.GetReasonsToStayRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.core.private.companyretentionstep.GetReasonsToStayRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.core.private.companyretentionstep.GetReasonsToStayRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.core.private.companyretentionstep.GetReasonsToStayRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCompanyDomain();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string company_domain = 1;
 * @return {string}
 */
proto.core.private.companyretentionstep.GetReasonsToStayRequest.prototype.getCompanyDomain = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.core.private.companyretentionstep.GetReasonsToStayRequest} returns this
 */
proto.core.private.companyretentionstep.GetReasonsToStayRequest.prototype.setCompanyDomain = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.core.private.companyretentionstep.GetReasonsToStayResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.core.private.companyretentionstep.GetReasonsToStayResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.core.private.companyretentionstep.GetReasonsToStayResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.core.private.companyretentionstep.GetReasonsToStayResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    text1: jspb.Message.getFieldWithDefault(msg, 1, ""),
    text2: jspb.Message.getFieldWithDefault(msg, 2, ""),
    text3: jspb.Message.getFieldWithDefault(msg, 3, ""),
    text4: jspb.Message.getFieldWithDefault(msg, 4, ""),
    text5: jspb.Message.getFieldWithDefault(msg, 5, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.core.private.companyretentionstep.GetReasonsToStayResponse}
 */
proto.core.private.companyretentionstep.GetReasonsToStayResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.core.private.companyretentionstep.GetReasonsToStayResponse;
  return proto.core.private.companyretentionstep.GetReasonsToStayResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.core.private.companyretentionstep.GetReasonsToStayResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.core.private.companyretentionstep.GetReasonsToStayResponse}
 */
proto.core.private.companyretentionstep.GetReasonsToStayResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setText1(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setText2(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setText3(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setText4(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setText5(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.core.private.companyretentionstep.GetReasonsToStayResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.core.private.companyretentionstep.GetReasonsToStayResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.core.private.companyretentionstep.GetReasonsToStayResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.core.private.companyretentionstep.GetReasonsToStayResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getText1();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getText2();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getText3();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getText4();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getText5();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
};


/**
 * optional string text1 = 1;
 * @return {string}
 */
proto.core.private.companyretentionstep.GetReasonsToStayResponse.prototype.getText1 = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.core.private.companyretentionstep.GetReasonsToStayResponse} returns this
 */
proto.core.private.companyretentionstep.GetReasonsToStayResponse.prototype.setText1 = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string text2 = 2;
 * @return {string}
 */
proto.core.private.companyretentionstep.GetReasonsToStayResponse.prototype.getText2 = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.core.private.companyretentionstep.GetReasonsToStayResponse} returns this
 */
proto.core.private.companyretentionstep.GetReasonsToStayResponse.prototype.setText2 = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string text3 = 3;
 * @return {string}
 */
proto.core.private.companyretentionstep.GetReasonsToStayResponse.prototype.getText3 = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.core.private.companyretentionstep.GetReasonsToStayResponse} returns this
 */
proto.core.private.companyretentionstep.GetReasonsToStayResponse.prototype.setText3 = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string text4 = 4;
 * @return {string}
 */
proto.core.private.companyretentionstep.GetReasonsToStayResponse.prototype.getText4 = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.core.private.companyretentionstep.GetReasonsToStayResponse} returns this
 */
proto.core.private.companyretentionstep.GetReasonsToStayResponse.prototype.setText4 = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string text5 = 5;
 * @return {string}
 */
proto.core.private.companyretentionstep.GetReasonsToStayResponse.prototype.getText5 = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.core.private.companyretentionstep.GetReasonsToStayResponse} returns this
 */
proto.core.private.companyretentionstep.GetReasonsToStayResponse.prototype.setText5 = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.core.private.companyretentionstep.UpsertTimeRemainingRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.core.private.companyretentionstep.UpsertTimeRemainingRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.core.private.companyretentionstep.UpsertTimeRemainingRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.core.private.companyretentionstep.UpsertTimeRemainingRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    companyDomain: jspb.Message.getFieldWithDefault(msg, 1, ""),
    text: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.core.private.companyretentionstep.UpsertTimeRemainingRequest}
 */
proto.core.private.companyretentionstep.UpsertTimeRemainingRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.core.private.companyretentionstep.UpsertTimeRemainingRequest;
  return proto.core.private.companyretentionstep.UpsertTimeRemainingRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.core.private.companyretentionstep.UpsertTimeRemainingRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.core.private.companyretentionstep.UpsertTimeRemainingRequest}
 */
proto.core.private.companyretentionstep.UpsertTimeRemainingRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setCompanyDomain(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setText(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.core.private.companyretentionstep.UpsertTimeRemainingRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.core.private.companyretentionstep.UpsertTimeRemainingRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.core.private.companyretentionstep.UpsertTimeRemainingRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.core.private.companyretentionstep.UpsertTimeRemainingRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCompanyDomain();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getText();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string company_domain = 1;
 * @return {string}
 */
proto.core.private.companyretentionstep.UpsertTimeRemainingRequest.prototype.getCompanyDomain = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.core.private.companyretentionstep.UpsertTimeRemainingRequest} returns this
 */
proto.core.private.companyretentionstep.UpsertTimeRemainingRequest.prototype.setCompanyDomain = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string text = 2;
 * @return {string}
 */
proto.core.private.companyretentionstep.UpsertTimeRemainingRequest.prototype.getText = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.core.private.companyretentionstep.UpsertTimeRemainingRequest} returns this
 */
proto.core.private.companyretentionstep.UpsertTimeRemainingRequest.prototype.setText = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.core.private.companyretentionstep.UpsertTimeRemainingResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.core.private.companyretentionstep.UpsertTimeRemainingResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.core.private.companyretentionstep.UpsertTimeRemainingResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.core.private.companyretentionstep.UpsertTimeRemainingResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    text: jspb.Message.getFieldWithDefault(msg, 1, ""),
    isEnabled: jspb.Message.getBooleanFieldWithDefault(msg, 2, false),
    orderSequenceNumber: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.core.private.companyretentionstep.UpsertTimeRemainingResponse}
 */
proto.core.private.companyretentionstep.UpsertTimeRemainingResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.core.private.companyretentionstep.UpsertTimeRemainingResponse;
  return proto.core.private.companyretentionstep.UpsertTimeRemainingResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.core.private.companyretentionstep.UpsertTimeRemainingResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.core.private.companyretentionstep.UpsertTimeRemainingResponse}
 */
proto.core.private.companyretentionstep.UpsertTimeRemainingResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setText(value);
      break;
    case 2:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsEnabled(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setOrderSequenceNumber(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.core.private.companyretentionstep.UpsertTimeRemainingResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.core.private.companyretentionstep.UpsertTimeRemainingResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.core.private.companyretentionstep.UpsertTimeRemainingResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.core.private.companyretentionstep.UpsertTimeRemainingResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getText();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getIsEnabled();
  if (f) {
    writer.writeBool(
      2,
      f
    );
  }
  f = message.getOrderSequenceNumber();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
};


/**
 * optional string text = 1;
 * @return {string}
 */
proto.core.private.companyretentionstep.UpsertTimeRemainingResponse.prototype.getText = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.core.private.companyretentionstep.UpsertTimeRemainingResponse} returns this
 */
proto.core.private.companyretentionstep.UpsertTimeRemainingResponse.prototype.setText = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional bool is_enabled = 2;
 * @return {boolean}
 */
proto.core.private.companyretentionstep.UpsertTimeRemainingResponse.prototype.getIsEnabled = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
};


/**
 * @param {boolean} value
 * @return {!proto.core.private.companyretentionstep.UpsertTimeRemainingResponse} returns this
 */
proto.core.private.companyretentionstep.UpsertTimeRemainingResponse.prototype.setIsEnabled = function(value) {
  return jspb.Message.setProto3BooleanField(this, 2, value);
};


/**
 * optional int32 order_sequence_number = 3;
 * @return {number}
 */
proto.core.private.companyretentionstep.UpsertTimeRemainingResponse.prototype.getOrderSequenceNumber = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.core.private.companyretentionstep.UpsertTimeRemainingResponse} returns this
 */
proto.core.private.companyretentionstep.UpsertTimeRemainingResponse.prototype.setOrderSequenceNumber = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.core.private.companyretentionstep.GetTimeRemainingRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.core.private.companyretentionstep.GetTimeRemainingRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.core.private.companyretentionstep.GetTimeRemainingRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.core.private.companyretentionstep.GetTimeRemainingRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    companyDomain: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.core.private.companyretentionstep.GetTimeRemainingRequest}
 */
proto.core.private.companyretentionstep.GetTimeRemainingRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.core.private.companyretentionstep.GetTimeRemainingRequest;
  return proto.core.private.companyretentionstep.GetTimeRemainingRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.core.private.companyretentionstep.GetTimeRemainingRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.core.private.companyretentionstep.GetTimeRemainingRequest}
 */
proto.core.private.companyretentionstep.GetTimeRemainingRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setCompanyDomain(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.core.private.companyretentionstep.GetTimeRemainingRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.core.private.companyretentionstep.GetTimeRemainingRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.core.private.companyretentionstep.GetTimeRemainingRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.core.private.companyretentionstep.GetTimeRemainingRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCompanyDomain();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string company_domain = 1;
 * @return {string}
 */
proto.core.private.companyretentionstep.GetTimeRemainingRequest.prototype.getCompanyDomain = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.core.private.companyretentionstep.GetTimeRemainingRequest} returns this
 */
proto.core.private.companyretentionstep.GetTimeRemainingRequest.prototype.setCompanyDomain = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.core.private.companyretentionstep.GetTimeRemainingResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.core.private.companyretentionstep.GetTimeRemainingResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.core.private.companyretentionstep.GetTimeRemainingResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.core.private.companyretentionstep.GetTimeRemainingResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    text: jspb.Message.getFieldWithDefault(msg, 1, ""),
    isEnabled: jspb.Message.getBooleanFieldWithDefault(msg, 2, false),
    orderSequenceNumber: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.core.private.companyretentionstep.GetTimeRemainingResponse}
 */
proto.core.private.companyretentionstep.GetTimeRemainingResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.core.private.companyretentionstep.GetTimeRemainingResponse;
  return proto.core.private.companyretentionstep.GetTimeRemainingResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.core.private.companyretentionstep.GetTimeRemainingResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.core.private.companyretentionstep.GetTimeRemainingResponse}
 */
proto.core.private.companyretentionstep.GetTimeRemainingResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setText(value);
      break;
    case 2:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsEnabled(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setOrderSequenceNumber(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.core.private.companyretentionstep.GetTimeRemainingResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.core.private.companyretentionstep.GetTimeRemainingResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.core.private.companyretentionstep.GetTimeRemainingResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.core.private.companyretentionstep.GetTimeRemainingResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getText();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getIsEnabled();
  if (f) {
    writer.writeBool(
      2,
      f
    );
  }
  f = message.getOrderSequenceNumber();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
};


/**
 * optional string text = 1;
 * @return {string}
 */
proto.core.private.companyretentionstep.GetTimeRemainingResponse.prototype.getText = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.core.private.companyretentionstep.GetTimeRemainingResponse} returns this
 */
proto.core.private.companyretentionstep.GetTimeRemainingResponse.prototype.setText = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional bool is_enabled = 2;
 * @return {boolean}
 */
proto.core.private.companyretentionstep.GetTimeRemainingResponse.prototype.getIsEnabled = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
};


/**
 * @param {boolean} value
 * @return {!proto.core.private.companyretentionstep.GetTimeRemainingResponse} returns this
 */
proto.core.private.companyretentionstep.GetTimeRemainingResponse.prototype.setIsEnabled = function(value) {
  return jspb.Message.setProto3BooleanField(this, 2, value);
};


/**
 * optional int32 order_sequence_number = 3;
 * @return {number}
 */
proto.core.private.companyretentionstep.GetTimeRemainingResponse.prototype.getOrderSequenceNumber = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.core.private.companyretentionstep.GetTimeRemainingResponse} returns this
 */
proto.core.private.companyretentionstep.GetTimeRemainingResponse.prototype.setOrderSequenceNumber = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.core.private.companyretentionstep.GetRetentionStepsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.core.private.companyretentionstep.GetRetentionStepsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.core.private.companyretentionstep.GetRetentionStepsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.core.private.companyretentionstep.GetRetentionStepsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    companyDomain: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.core.private.companyretentionstep.GetRetentionStepsRequest}
 */
proto.core.private.companyretentionstep.GetRetentionStepsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.core.private.companyretentionstep.GetRetentionStepsRequest;
  return proto.core.private.companyretentionstep.GetRetentionStepsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.core.private.companyretentionstep.GetRetentionStepsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.core.private.companyretentionstep.GetRetentionStepsRequest}
 */
proto.core.private.companyretentionstep.GetRetentionStepsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setCompanyDomain(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.core.private.companyretentionstep.GetRetentionStepsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.core.private.companyretentionstep.GetRetentionStepsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.core.private.companyretentionstep.GetRetentionStepsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.core.private.companyretentionstep.GetRetentionStepsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCompanyDomain();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string company_domain = 1;
 * @return {string}
 */
proto.core.private.companyretentionstep.GetRetentionStepsRequest.prototype.getCompanyDomain = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.core.private.companyretentionstep.GetRetentionStepsRequest} returns this
 */
proto.core.private.companyretentionstep.GetRetentionStepsRequest.prototype.setCompanyDomain = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.core.private.companyretentionstep.GetRetentionStepsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.core.private.companyretentionstep.GetRetentionStepsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.core.private.companyretentionstep.GetRetentionStepsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.core.private.companyretentionstep.GetRetentionStepsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    helpInterrupt: (f = msg.getHelpInterrupt()) && proto.core.private.companyretentionstep.RetentionStepListItem.toObject(includeInstance, f),
    reasonsToStay: (f = msg.getReasonsToStay()) && proto.core.private.companyretentionstep.RetentionStepListItem.toObject(includeInstance, f),
    planChange: (f = msg.getPlanChange()) && proto.core.private.companyretentionstep.RetentionStepListItem.toObject(includeInstance, f),
    timeRemaining: (f = msg.getTimeRemaining()) && proto.core.private.companyretentionstep.RetentionStepListItem.toObject(includeInstance, f),
    discountOffer: (f = msg.getDiscountOffer()) && proto.core.private.companyretentionstep.RetentionStepListItem.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.core.private.companyretentionstep.GetRetentionStepsResponse}
 */
proto.core.private.companyretentionstep.GetRetentionStepsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.core.private.companyretentionstep.GetRetentionStepsResponse;
  return proto.core.private.companyretentionstep.GetRetentionStepsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.core.private.companyretentionstep.GetRetentionStepsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.core.private.companyretentionstep.GetRetentionStepsResponse}
 */
proto.core.private.companyretentionstep.GetRetentionStepsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.core.private.companyretentionstep.RetentionStepListItem;
      reader.readMessage(value,proto.core.private.companyretentionstep.RetentionStepListItem.deserializeBinaryFromReader);
      msg.setHelpInterrupt(value);
      break;
    case 2:
      var value = new proto.core.private.companyretentionstep.RetentionStepListItem;
      reader.readMessage(value,proto.core.private.companyretentionstep.RetentionStepListItem.deserializeBinaryFromReader);
      msg.setReasonsToStay(value);
      break;
    case 3:
      var value = new proto.core.private.companyretentionstep.RetentionStepListItem;
      reader.readMessage(value,proto.core.private.companyretentionstep.RetentionStepListItem.deserializeBinaryFromReader);
      msg.setPlanChange(value);
      break;
    case 4:
      var value = new proto.core.private.companyretentionstep.RetentionStepListItem;
      reader.readMessage(value,proto.core.private.companyretentionstep.RetentionStepListItem.deserializeBinaryFromReader);
      msg.setTimeRemaining(value);
      break;
    case 5:
      var value = new proto.core.private.companyretentionstep.RetentionStepListItem;
      reader.readMessage(value,proto.core.private.companyretentionstep.RetentionStepListItem.deserializeBinaryFromReader);
      msg.setDiscountOffer(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.core.private.companyretentionstep.GetRetentionStepsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.core.private.companyretentionstep.GetRetentionStepsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.core.private.companyretentionstep.GetRetentionStepsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.core.private.companyretentionstep.GetRetentionStepsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getHelpInterrupt();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.core.private.companyretentionstep.RetentionStepListItem.serializeBinaryToWriter
    );
  }
  f = message.getReasonsToStay();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.core.private.companyretentionstep.RetentionStepListItem.serializeBinaryToWriter
    );
  }
  f = message.getPlanChange();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.core.private.companyretentionstep.RetentionStepListItem.serializeBinaryToWriter
    );
  }
  f = message.getTimeRemaining();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.core.private.companyretentionstep.RetentionStepListItem.serializeBinaryToWriter
    );
  }
  f = message.getDiscountOffer();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      proto.core.private.companyretentionstep.RetentionStepListItem.serializeBinaryToWriter
    );
  }
};


/**
 * optional RetentionStepListItem help_interrupt = 1;
 * @return {?proto.core.private.companyretentionstep.RetentionStepListItem}
 */
proto.core.private.companyretentionstep.GetRetentionStepsResponse.prototype.getHelpInterrupt = function() {
  return /** @type{?proto.core.private.companyretentionstep.RetentionStepListItem} */ (
    jspb.Message.getWrapperField(this, proto.core.private.companyretentionstep.RetentionStepListItem, 1));
};


/**
 * @param {?proto.core.private.companyretentionstep.RetentionStepListItem|undefined} value
 * @return {!proto.core.private.companyretentionstep.GetRetentionStepsResponse} returns this
*/
proto.core.private.companyretentionstep.GetRetentionStepsResponse.prototype.setHelpInterrupt = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.core.private.companyretentionstep.GetRetentionStepsResponse} returns this
 */
proto.core.private.companyretentionstep.GetRetentionStepsResponse.prototype.clearHelpInterrupt = function() {
  return this.setHelpInterrupt(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.core.private.companyretentionstep.GetRetentionStepsResponse.prototype.hasHelpInterrupt = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional RetentionStepListItem reasons_to_stay = 2;
 * @return {?proto.core.private.companyretentionstep.RetentionStepListItem}
 */
proto.core.private.companyretentionstep.GetRetentionStepsResponse.prototype.getReasonsToStay = function() {
  return /** @type{?proto.core.private.companyretentionstep.RetentionStepListItem} */ (
    jspb.Message.getWrapperField(this, proto.core.private.companyretentionstep.RetentionStepListItem, 2));
};


/**
 * @param {?proto.core.private.companyretentionstep.RetentionStepListItem|undefined} value
 * @return {!proto.core.private.companyretentionstep.GetRetentionStepsResponse} returns this
*/
proto.core.private.companyretentionstep.GetRetentionStepsResponse.prototype.setReasonsToStay = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.core.private.companyretentionstep.GetRetentionStepsResponse} returns this
 */
proto.core.private.companyretentionstep.GetRetentionStepsResponse.prototype.clearReasonsToStay = function() {
  return this.setReasonsToStay(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.core.private.companyretentionstep.GetRetentionStepsResponse.prototype.hasReasonsToStay = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional RetentionStepListItem plan_change = 3;
 * @return {?proto.core.private.companyretentionstep.RetentionStepListItem}
 */
proto.core.private.companyretentionstep.GetRetentionStepsResponse.prototype.getPlanChange = function() {
  return /** @type{?proto.core.private.companyretentionstep.RetentionStepListItem} */ (
    jspb.Message.getWrapperField(this, proto.core.private.companyretentionstep.RetentionStepListItem, 3));
};


/**
 * @param {?proto.core.private.companyretentionstep.RetentionStepListItem|undefined} value
 * @return {!proto.core.private.companyretentionstep.GetRetentionStepsResponse} returns this
*/
proto.core.private.companyretentionstep.GetRetentionStepsResponse.prototype.setPlanChange = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.core.private.companyretentionstep.GetRetentionStepsResponse} returns this
 */
proto.core.private.companyretentionstep.GetRetentionStepsResponse.prototype.clearPlanChange = function() {
  return this.setPlanChange(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.core.private.companyretentionstep.GetRetentionStepsResponse.prototype.hasPlanChange = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional RetentionStepListItem time_remaining = 4;
 * @return {?proto.core.private.companyretentionstep.RetentionStepListItem}
 */
proto.core.private.companyretentionstep.GetRetentionStepsResponse.prototype.getTimeRemaining = function() {
  return /** @type{?proto.core.private.companyretentionstep.RetentionStepListItem} */ (
    jspb.Message.getWrapperField(this, proto.core.private.companyretentionstep.RetentionStepListItem, 4));
};


/**
 * @param {?proto.core.private.companyretentionstep.RetentionStepListItem|undefined} value
 * @return {!proto.core.private.companyretentionstep.GetRetentionStepsResponse} returns this
*/
proto.core.private.companyretentionstep.GetRetentionStepsResponse.prototype.setTimeRemaining = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.core.private.companyretentionstep.GetRetentionStepsResponse} returns this
 */
proto.core.private.companyretentionstep.GetRetentionStepsResponse.prototype.clearTimeRemaining = function() {
  return this.setTimeRemaining(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.core.private.companyretentionstep.GetRetentionStepsResponse.prototype.hasTimeRemaining = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional RetentionStepListItem discount_offer = 5;
 * @return {?proto.core.private.companyretentionstep.RetentionStepListItem}
 */
proto.core.private.companyretentionstep.GetRetentionStepsResponse.prototype.getDiscountOffer = function() {
  return /** @type{?proto.core.private.companyretentionstep.RetentionStepListItem} */ (
    jspb.Message.getWrapperField(this, proto.core.private.companyretentionstep.RetentionStepListItem, 5));
};


/**
 * @param {?proto.core.private.companyretentionstep.RetentionStepListItem|undefined} value
 * @return {!proto.core.private.companyretentionstep.GetRetentionStepsResponse} returns this
*/
proto.core.private.companyretentionstep.GetRetentionStepsResponse.prototype.setDiscountOffer = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.core.private.companyretentionstep.GetRetentionStepsResponse} returns this
 */
proto.core.private.companyretentionstep.GetRetentionStepsResponse.prototype.clearDiscountOffer = function() {
  return this.setDiscountOffer(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.core.private.companyretentionstep.GetRetentionStepsResponse.prototype.hasDiscountOffer = function() {
  return jspb.Message.getField(this, 5) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.core.private.companyretentionstep.UpdateRetentionStepsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.core.private.companyretentionstep.UpdateRetentionStepsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.core.private.companyretentionstep.UpdateRetentionStepsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.core.private.companyretentionstep.UpdateRetentionStepsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    companyDomain: jspb.Message.getFieldWithDefault(msg, 1, ""),
    helpInterrupt: (f = msg.getHelpInterrupt()) && proto.core.private.companyretentionstep.RetentionStepListItem.toObject(includeInstance, f),
    reasonsToStay: (f = msg.getReasonsToStay()) && proto.core.private.companyretentionstep.RetentionStepListItem.toObject(includeInstance, f),
    planChange: (f = msg.getPlanChange()) && proto.core.private.companyretentionstep.RetentionStepListItem.toObject(includeInstance, f),
    timeRemaining: (f = msg.getTimeRemaining()) && proto.core.private.companyretentionstep.RetentionStepListItem.toObject(includeInstance, f),
    discountOffer: (f = msg.getDiscountOffer()) && proto.core.private.companyretentionstep.RetentionStepListItem.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.core.private.companyretentionstep.UpdateRetentionStepsRequest}
 */
proto.core.private.companyretentionstep.UpdateRetentionStepsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.core.private.companyretentionstep.UpdateRetentionStepsRequest;
  return proto.core.private.companyretentionstep.UpdateRetentionStepsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.core.private.companyretentionstep.UpdateRetentionStepsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.core.private.companyretentionstep.UpdateRetentionStepsRequest}
 */
proto.core.private.companyretentionstep.UpdateRetentionStepsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setCompanyDomain(value);
      break;
    case 2:
      var value = new proto.core.private.companyretentionstep.RetentionStepListItem;
      reader.readMessage(value,proto.core.private.companyretentionstep.RetentionStepListItem.deserializeBinaryFromReader);
      msg.setHelpInterrupt(value);
      break;
    case 3:
      var value = new proto.core.private.companyretentionstep.RetentionStepListItem;
      reader.readMessage(value,proto.core.private.companyretentionstep.RetentionStepListItem.deserializeBinaryFromReader);
      msg.setReasonsToStay(value);
      break;
    case 4:
      var value = new proto.core.private.companyretentionstep.RetentionStepListItem;
      reader.readMessage(value,proto.core.private.companyretentionstep.RetentionStepListItem.deserializeBinaryFromReader);
      msg.setPlanChange(value);
      break;
    case 5:
      var value = new proto.core.private.companyretentionstep.RetentionStepListItem;
      reader.readMessage(value,proto.core.private.companyretentionstep.RetentionStepListItem.deserializeBinaryFromReader);
      msg.setTimeRemaining(value);
      break;
    case 6:
      var value = new proto.core.private.companyretentionstep.RetentionStepListItem;
      reader.readMessage(value,proto.core.private.companyretentionstep.RetentionStepListItem.deserializeBinaryFromReader);
      msg.setDiscountOffer(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.core.private.companyretentionstep.UpdateRetentionStepsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.core.private.companyretentionstep.UpdateRetentionStepsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.core.private.companyretentionstep.UpdateRetentionStepsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.core.private.companyretentionstep.UpdateRetentionStepsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCompanyDomain();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getHelpInterrupt();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.core.private.companyretentionstep.RetentionStepListItem.serializeBinaryToWriter
    );
  }
  f = message.getReasonsToStay();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.core.private.companyretentionstep.RetentionStepListItem.serializeBinaryToWriter
    );
  }
  f = message.getPlanChange();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.core.private.companyretentionstep.RetentionStepListItem.serializeBinaryToWriter
    );
  }
  f = message.getTimeRemaining();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      proto.core.private.companyretentionstep.RetentionStepListItem.serializeBinaryToWriter
    );
  }
  f = message.getDiscountOffer();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      proto.core.private.companyretentionstep.RetentionStepListItem.serializeBinaryToWriter
    );
  }
};


/**
 * optional string company_domain = 1;
 * @return {string}
 */
proto.core.private.companyretentionstep.UpdateRetentionStepsRequest.prototype.getCompanyDomain = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.core.private.companyretentionstep.UpdateRetentionStepsRequest} returns this
 */
proto.core.private.companyretentionstep.UpdateRetentionStepsRequest.prototype.setCompanyDomain = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional RetentionStepListItem help_interrupt = 2;
 * @return {?proto.core.private.companyretentionstep.RetentionStepListItem}
 */
proto.core.private.companyretentionstep.UpdateRetentionStepsRequest.prototype.getHelpInterrupt = function() {
  return /** @type{?proto.core.private.companyretentionstep.RetentionStepListItem} */ (
    jspb.Message.getWrapperField(this, proto.core.private.companyretentionstep.RetentionStepListItem, 2));
};


/**
 * @param {?proto.core.private.companyretentionstep.RetentionStepListItem|undefined} value
 * @return {!proto.core.private.companyretentionstep.UpdateRetentionStepsRequest} returns this
*/
proto.core.private.companyretentionstep.UpdateRetentionStepsRequest.prototype.setHelpInterrupt = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.core.private.companyretentionstep.UpdateRetentionStepsRequest} returns this
 */
proto.core.private.companyretentionstep.UpdateRetentionStepsRequest.prototype.clearHelpInterrupt = function() {
  return this.setHelpInterrupt(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.core.private.companyretentionstep.UpdateRetentionStepsRequest.prototype.hasHelpInterrupt = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional RetentionStepListItem reasons_to_stay = 3;
 * @return {?proto.core.private.companyretentionstep.RetentionStepListItem}
 */
proto.core.private.companyretentionstep.UpdateRetentionStepsRequest.prototype.getReasonsToStay = function() {
  return /** @type{?proto.core.private.companyretentionstep.RetentionStepListItem} */ (
    jspb.Message.getWrapperField(this, proto.core.private.companyretentionstep.RetentionStepListItem, 3));
};


/**
 * @param {?proto.core.private.companyretentionstep.RetentionStepListItem|undefined} value
 * @return {!proto.core.private.companyretentionstep.UpdateRetentionStepsRequest} returns this
*/
proto.core.private.companyretentionstep.UpdateRetentionStepsRequest.prototype.setReasonsToStay = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.core.private.companyretentionstep.UpdateRetentionStepsRequest} returns this
 */
proto.core.private.companyretentionstep.UpdateRetentionStepsRequest.prototype.clearReasonsToStay = function() {
  return this.setReasonsToStay(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.core.private.companyretentionstep.UpdateRetentionStepsRequest.prototype.hasReasonsToStay = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional RetentionStepListItem plan_change = 4;
 * @return {?proto.core.private.companyretentionstep.RetentionStepListItem}
 */
proto.core.private.companyretentionstep.UpdateRetentionStepsRequest.prototype.getPlanChange = function() {
  return /** @type{?proto.core.private.companyretentionstep.RetentionStepListItem} */ (
    jspb.Message.getWrapperField(this, proto.core.private.companyretentionstep.RetentionStepListItem, 4));
};


/**
 * @param {?proto.core.private.companyretentionstep.RetentionStepListItem|undefined} value
 * @return {!proto.core.private.companyretentionstep.UpdateRetentionStepsRequest} returns this
*/
proto.core.private.companyretentionstep.UpdateRetentionStepsRequest.prototype.setPlanChange = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.core.private.companyretentionstep.UpdateRetentionStepsRequest} returns this
 */
proto.core.private.companyretentionstep.UpdateRetentionStepsRequest.prototype.clearPlanChange = function() {
  return this.setPlanChange(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.core.private.companyretentionstep.UpdateRetentionStepsRequest.prototype.hasPlanChange = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional RetentionStepListItem time_remaining = 5;
 * @return {?proto.core.private.companyretentionstep.RetentionStepListItem}
 */
proto.core.private.companyretentionstep.UpdateRetentionStepsRequest.prototype.getTimeRemaining = function() {
  return /** @type{?proto.core.private.companyretentionstep.RetentionStepListItem} */ (
    jspb.Message.getWrapperField(this, proto.core.private.companyretentionstep.RetentionStepListItem, 5));
};


/**
 * @param {?proto.core.private.companyretentionstep.RetentionStepListItem|undefined} value
 * @return {!proto.core.private.companyretentionstep.UpdateRetentionStepsRequest} returns this
*/
proto.core.private.companyretentionstep.UpdateRetentionStepsRequest.prototype.setTimeRemaining = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.core.private.companyretentionstep.UpdateRetentionStepsRequest} returns this
 */
proto.core.private.companyretentionstep.UpdateRetentionStepsRequest.prototype.clearTimeRemaining = function() {
  return this.setTimeRemaining(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.core.private.companyretentionstep.UpdateRetentionStepsRequest.prototype.hasTimeRemaining = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional RetentionStepListItem discount_offer = 6;
 * @return {?proto.core.private.companyretentionstep.RetentionStepListItem}
 */
proto.core.private.companyretentionstep.UpdateRetentionStepsRequest.prototype.getDiscountOffer = function() {
  return /** @type{?proto.core.private.companyretentionstep.RetentionStepListItem} */ (
    jspb.Message.getWrapperField(this, proto.core.private.companyretentionstep.RetentionStepListItem, 6));
};


/**
 * @param {?proto.core.private.companyretentionstep.RetentionStepListItem|undefined} value
 * @return {!proto.core.private.companyretentionstep.UpdateRetentionStepsRequest} returns this
*/
proto.core.private.companyretentionstep.UpdateRetentionStepsRequest.prototype.setDiscountOffer = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.core.private.companyretentionstep.UpdateRetentionStepsRequest} returns this
 */
proto.core.private.companyretentionstep.UpdateRetentionStepsRequest.prototype.clearDiscountOffer = function() {
  return this.setDiscountOffer(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.core.private.companyretentionstep.UpdateRetentionStepsRequest.prototype.hasDiscountOffer = function() {
  return jspb.Message.getField(this, 6) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.core.private.companyretentionstep.UpdateRetentionStepsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.core.private.companyretentionstep.UpdateRetentionStepsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.core.private.companyretentionstep.UpdateRetentionStepsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.core.private.companyretentionstep.UpdateRetentionStepsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    helpInterrupt: (f = msg.getHelpInterrupt()) && proto.core.private.companyretentionstep.RetentionStepListItem.toObject(includeInstance, f),
    reasonsToStay: (f = msg.getReasonsToStay()) && proto.core.private.companyretentionstep.RetentionStepListItem.toObject(includeInstance, f),
    planChange: (f = msg.getPlanChange()) && proto.core.private.companyretentionstep.RetentionStepListItem.toObject(includeInstance, f),
    timeRemaining: (f = msg.getTimeRemaining()) && proto.core.private.companyretentionstep.RetentionStepListItem.toObject(includeInstance, f),
    discountOffer: (f = msg.getDiscountOffer()) && proto.core.private.companyretentionstep.RetentionStepListItem.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.core.private.companyretentionstep.UpdateRetentionStepsResponse}
 */
proto.core.private.companyretentionstep.UpdateRetentionStepsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.core.private.companyretentionstep.UpdateRetentionStepsResponse;
  return proto.core.private.companyretentionstep.UpdateRetentionStepsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.core.private.companyretentionstep.UpdateRetentionStepsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.core.private.companyretentionstep.UpdateRetentionStepsResponse}
 */
proto.core.private.companyretentionstep.UpdateRetentionStepsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.core.private.companyretentionstep.RetentionStepListItem;
      reader.readMessage(value,proto.core.private.companyretentionstep.RetentionStepListItem.deserializeBinaryFromReader);
      msg.setHelpInterrupt(value);
      break;
    case 2:
      var value = new proto.core.private.companyretentionstep.RetentionStepListItem;
      reader.readMessage(value,proto.core.private.companyretentionstep.RetentionStepListItem.deserializeBinaryFromReader);
      msg.setReasonsToStay(value);
      break;
    case 3:
      var value = new proto.core.private.companyretentionstep.RetentionStepListItem;
      reader.readMessage(value,proto.core.private.companyretentionstep.RetentionStepListItem.deserializeBinaryFromReader);
      msg.setPlanChange(value);
      break;
    case 4:
      var value = new proto.core.private.companyretentionstep.RetentionStepListItem;
      reader.readMessage(value,proto.core.private.companyretentionstep.RetentionStepListItem.deserializeBinaryFromReader);
      msg.setTimeRemaining(value);
      break;
    case 5:
      var value = new proto.core.private.companyretentionstep.RetentionStepListItem;
      reader.readMessage(value,proto.core.private.companyretentionstep.RetentionStepListItem.deserializeBinaryFromReader);
      msg.setDiscountOffer(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.core.private.companyretentionstep.UpdateRetentionStepsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.core.private.companyretentionstep.UpdateRetentionStepsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.core.private.companyretentionstep.UpdateRetentionStepsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.core.private.companyretentionstep.UpdateRetentionStepsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getHelpInterrupt();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.core.private.companyretentionstep.RetentionStepListItem.serializeBinaryToWriter
    );
  }
  f = message.getReasonsToStay();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.core.private.companyretentionstep.RetentionStepListItem.serializeBinaryToWriter
    );
  }
  f = message.getPlanChange();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.core.private.companyretentionstep.RetentionStepListItem.serializeBinaryToWriter
    );
  }
  f = message.getTimeRemaining();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.core.private.companyretentionstep.RetentionStepListItem.serializeBinaryToWriter
    );
  }
  f = message.getDiscountOffer();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      proto.core.private.companyretentionstep.RetentionStepListItem.serializeBinaryToWriter
    );
  }
};


/**
 * optional RetentionStepListItem help_interrupt = 1;
 * @return {?proto.core.private.companyretentionstep.RetentionStepListItem}
 */
proto.core.private.companyretentionstep.UpdateRetentionStepsResponse.prototype.getHelpInterrupt = function() {
  return /** @type{?proto.core.private.companyretentionstep.RetentionStepListItem} */ (
    jspb.Message.getWrapperField(this, proto.core.private.companyretentionstep.RetentionStepListItem, 1));
};


/**
 * @param {?proto.core.private.companyretentionstep.RetentionStepListItem|undefined} value
 * @return {!proto.core.private.companyretentionstep.UpdateRetentionStepsResponse} returns this
*/
proto.core.private.companyretentionstep.UpdateRetentionStepsResponse.prototype.setHelpInterrupt = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.core.private.companyretentionstep.UpdateRetentionStepsResponse} returns this
 */
proto.core.private.companyretentionstep.UpdateRetentionStepsResponse.prototype.clearHelpInterrupt = function() {
  return this.setHelpInterrupt(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.core.private.companyretentionstep.UpdateRetentionStepsResponse.prototype.hasHelpInterrupt = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional RetentionStepListItem reasons_to_stay = 2;
 * @return {?proto.core.private.companyretentionstep.RetentionStepListItem}
 */
proto.core.private.companyretentionstep.UpdateRetentionStepsResponse.prototype.getReasonsToStay = function() {
  return /** @type{?proto.core.private.companyretentionstep.RetentionStepListItem} */ (
    jspb.Message.getWrapperField(this, proto.core.private.companyretentionstep.RetentionStepListItem, 2));
};


/**
 * @param {?proto.core.private.companyretentionstep.RetentionStepListItem|undefined} value
 * @return {!proto.core.private.companyretentionstep.UpdateRetentionStepsResponse} returns this
*/
proto.core.private.companyretentionstep.UpdateRetentionStepsResponse.prototype.setReasonsToStay = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.core.private.companyretentionstep.UpdateRetentionStepsResponse} returns this
 */
proto.core.private.companyretentionstep.UpdateRetentionStepsResponse.prototype.clearReasonsToStay = function() {
  return this.setReasonsToStay(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.core.private.companyretentionstep.UpdateRetentionStepsResponse.prototype.hasReasonsToStay = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional RetentionStepListItem plan_change = 3;
 * @return {?proto.core.private.companyretentionstep.RetentionStepListItem}
 */
proto.core.private.companyretentionstep.UpdateRetentionStepsResponse.prototype.getPlanChange = function() {
  return /** @type{?proto.core.private.companyretentionstep.RetentionStepListItem} */ (
    jspb.Message.getWrapperField(this, proto.core.private.companyretentionstep.RetentionStepListItem, 3));
};


/**
 * @param {?proto.core.private.companyretentionstep.RetentionStepListItem|undefined} value
 * @return {!proto.core.private.companyretentionstep.UpdateRetentionStepsResponse} returns this
*/
proto.core.private.companyretentionstep.UpdateRetentionStepsResponse.prototype.setPlanChange = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.core.private.companyretentionstep.UpdateRetentionStepsResponse} returns this
 */
proto.core.private.companyretentionstep.UpdateRetentionStepsResponse.prototype.clearPlanChange = function() {
  return this.setPlanChange(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.core.private.companyretentionstep.UpdateRetentionStepsResponse.prototype.hasPlanChange = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional RetentionStepListItem time_remaining = 4;
 * @return {?proto.core.private.companyretentionstep.RetentionStepListItem}
 */
proto.core.private.companyretentionstep.UpdateRetentionStepsResponse.prototype.getTimeRemaining = function() {
  return /** @type{?proto.core.private.companyretentionstep.RetentionStepListItem} */ (
    jspb.Message.getWrapperField(this, proto.core.private.companyretentionstep.RetentionStepListItem, 4));
};


/**
 * @param {?proto.core.private.companyretentionstep.RetentionStepListItem|undefined} value
 * @return {!proto.core.private.companyretentionstep.UpdateRetentionStepsResponse} returns this
*/
proto.core.private.companyretentionstep.UpdateRetentionStepsResponse.prototype.setTimeRemaining = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.core.private.companyretentionstep.UpdateRetentionStepsResponse} returns this
 */
proto.core.private.companyretentionstep.UpdateRetentionStepsResponse.prototype.clearTimeRemaining = function() {
  return this.setTimeRemaining(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.core.private.companyretentionstep.UpdateRetentionStepsResponse.prototype.hasTimeRemaining = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional RetentionStepListItem discount_offer = 5;
 * @return {?proto.core.private.companyretentionstep.RetentionStepListItem}
 */
proto.core.private.companyretentionstep.UpdateRetentionStepsResponse.prototype.getDiscountOffer = function() {
  return /** @type{?proto.core.private.companyretentionstep.RetentionStepListItem} */ (
    jspb.Message.getWrapperField(this, proto.core.private.companyretentionstep.RetentionStepListItem, 5));
};


/**
 * @param {?proto.core.private.companyretentionstep.RetentionStepListItem|undefined} value
 * @return {!proto.core.private.companyretentionstep.UpdateRetentionStepsResponse} returns this
*/
proto.core.private.companyretentionstep.UpdateRetentionStepsResponse.prototype.setDiscountOffer = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.core.private.companyretentionstep.UpdateRetentionStepsResponse} returns this
 */
proto.core.private.companyretentionstep.UpdateRetentionStepsResponse.prototype.clearDiscountOffer = function() {
  return this.setDiscountOffer(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.core.private.companyretentionstep.UpdateRetentionStepsResponse.prototype.hasDiscountOffer = function() {
  return jspb.Message.getField(this, 5) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.core.private.companyretentionstep.RetentionStepListItem.prototype.toObject = function(opt_includeInstance) {
  return proto.core.private.companyretentionstep.RetentionStepListItem.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.core.private.companyretentionstep.RetentionStepListItem} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.core.private.companyretentionstep.RetentionStepListItem.toObject = function(includeInstance, msg) {
  var f, obj = {
    isEnabled: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    orderSequenceNumber: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.core.private.companyretentionstep.RetentionStepListItem}
 */
proto.core.private.companyretentionstep.RetentionStepListItem.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.core.private.companyretentionstep.RetentionStepListItem;
  return proto.core.private.companyretentionstep.RetentionStepListItem.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.core.private.companyretentionstep.RetentionStepListItem} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.core.private.companyretentionstep.RetentionStepListItem}
 */
proto.core.private.companyretentionstep.RetentionStepListItem.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsEnabled(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setOrderSequenceNumber(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.core.private.companyretentionstep.RetentionStepListItem.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.core.private.companyretentionstep.RetentionStepListItem.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.core.private.companyretentionstep.RetentionStepListItem} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.core.private.companyretentionstep.RetentionStepListItem.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getIsEnabled();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getOrderSequenceNumber();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
};


/**
 * optional bool is_enabled = 1;
 * @return {boolean}
 */
proto.core.private.companyretentionstep.RetentionStepListItem.prototype.getIsEnabled = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.core.private.companyretentionstep.RetentionStepListItem} returns this
 */
proto.core.private.companyretentionstep.RetentionStepListItem.prototype.setIsEnabled = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional int32 order_sequence_number = 2;
 * @return {number}
 */
proto.core.private.companyretentionstep.RetentionStepListItem.prototype.getOrderSequenceNumber = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.core.private.companyretentionstep.RetentionStepListItem} returns this
 */
proto.core.private.companyretentionstep.RetentionStepListItem.prototype.setOrderSequenceNumber = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * @enum {number}
 */
proto.core.private.companyretentionstep.FrequencyType = {
  UNSPECIFIED_FT: 0,
  DAILY: 1,
  WEEKLY: 2,
  MONTHLY: 3,
  YEARLY: 4
};

goog.object.extend(exports, proto.core.private.companyretentionstep);
