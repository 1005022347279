import React, { useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import Search from "../../components/search/Search";
import Pagination from "../../components/ui/pagination/Pagination";
import Table, { TableArrowBtn } from "../../components/ui/table/Table";
import StatusLabel from "../../components/ui/status-label/StatusLabel";
import FilterDropdown from "../../components/ui/filter-dropdown/FilterDropdown";
import Text from "../../components/ui/text/Text";
import PageWrapper from "../../components/ui/layout/PageWrapper";
import { IInvoicesReducer } from "../../reducers/invoicesReducer";
import { fetchInvoices, setInvoicesField } from "../../actions/invoicesActions";
import { AppState } from "../..";
import { InvoiceStatus } from "../../models/Invoices";
import "./InvoicesList.scss";
import { getInvoiceStatusColor, getInvoiceStatusText } from "../../utils/commonUtils";
import ProgressIndicator from "../../components/ui/progress-indicator/ProgressIndicator";
import NavigationBar from "../../components/ui/navigation-bar/NavigationBar";
import history from "../../utils/history";
import FilterSort from "../../components/ui/filter-sort/FilterSort";
import Button from "../../components/ui/button/Button";
import Modal from "../../components/modal/Modal";
import ExportData from "../../components/export-data/ExportData";
import { IAuthReducerState } from "../../reducers/authReducer";
import NoticePanel from "../../components/ui/notice-panel/NoticePanel";
import { Permission } from "../../models/Auth";
import Tooltip from "../../components/ui/tooltip/Tooltip";

interface IInvoicesListProps {
  isComingFromDashboard?: boolean
}

const InvoicesList: React.FC<IInvoicesListProps> = ({ isComingFromDashboard }) => {
  const { searchedInvoices, invoiceStatus, invoicesPaginated, isDateDescendingOrder, isFetching, pageNotification } = useSelector<AppState, IInvoicesReducer>(state => state.invoicesReducer)
  const { currentCompanyDomain, currentCompanyId, parsedToken } = useSelector<AppState, IAuthReducerState>(state => state.auth)
  const { pageCount, rowCount, results, currentPage } = invoicesPaginated
  const dispatch = useDispatch<Function>()
  const [showExportInvoicesModal, setShowExportInvoicesModal] = useState(false);

  const hasPermissionDataExport = useMemo(() => {
    if(parsedToken?.CurrentPermissions.some(p => p === Permission[Permission.DataExportsCsv])) {
      return true
    }
    return false
  }, [parsedToken])

  const filterOptions = [
    { label: "INVOICE_STATUS_FILTER_PAID", value: InvoiceStatus.PAID },
    { label: "INVOICE_STATUS_FILTER_PAID_OFFLINE", value: InvoiceStatus.PAID_OFFLINE },
    { label: "INVOICE_STATUS_FILTER_PENDING", value: InvoiceStatus.PENDING },
    { label: "INVOICE_STATUS_FILTER_UNPAID", value: InvoiceStatus.UNPAID },
    { label: "INVOICE_STATUS_FILTER_TOBE_WRITTENOFF", value: InvoiceStatus.TOBEWRITTEN_OFF },
    { label: "INVOICE_STATUS_FILTER_WRITTENOFF", value: InvoiceStatus.WRITTEN_OFF },
    { label: "INVOICE_STATUS_FILTER_CLEARING", value: InvoiceStatus.CLEARING }
  ];

  const renderTableData = () => {
    if (!results || results.length === 0) {
      return null;
    }

    return results.map(data => {
      return (
        <tr className="invoices-list__table__item" key={data.invoiceId} onClick={() => history.push(`/invoices/${data.invoiceNumber}/customer/${data.customerUniqueId}`)}>
          <Text shouldTranslate={false} component="td" content={moment.utc(data.createdOn).format("DD MMM YYYY")} noMargin />
          <Text shouldTranslate={false} component="td" content={data.customerFullName} noMargin />
          <Text shouldTranslate={false} component="td" content={data.formattedAmount} noMargin />
          <Text shouldTranslate={false} component="td" content={data.invoiceNumber} noMargin />
          <td>
            <StatusLabel color={getInvoiceStatusColor(data.status)} content={getInvoiceStatusText(data.status)}></StatusLabel>
          </td>
          <Text shouldTranslate={false} component="td" content={data.customerUniqueId} noMargin />
          <TableArrowBtn link={true} to={`/invoices/${data.invoiceNumber}/customer/${data.customerUniqueId}`} />
        </tr>
      );
    });
  };

  if (!results) {
    return <ProgressIndicator color="blue" coverage='full-content' />
  }

  return (
    <div className="invoices-list">
      {isComingFromDashboard
        && <NavigationBar
          className="invoices-list__navigation"
          pageTitle=""
          previousPageTitle="DASHBOARD_STATS_SCREEN_TITLE"
          previousPageCb={() => history.push("/home")}
        />}
      <div className="invoices-list__header">
        <div className="invoices-list__search">
          <Text content="INVOICES_LIST_COUNT" translateWith={{ numberOfInvoices: rowCount }} noMargin />
          <Search
            className="invoices-list__search-bar"
            value={searchedInvoices}
            placeholder="INVOICES_LIST_SEARCH_PLACEHOLDER"
            onChange={(evt: any) => dispatch(setInvoicesField("searchedInvoices", evt.target.value))}
            onSearch={() => {
              dispatch(fetchInvoices(currentCompanyDomain, 1, undefined, searchedInvoices, invoiceStatus?.value, isDateDescendingOrder))
            }}
          />
          <div className="invoices-list__export-btn__container" data-tip data-for="permission-warning-tooltip">
            <Button
              id="invoices-list-export"
              buttonType="add"
              disabled={!hasPermissionDataExport}
              className="invoices-list__export-btn" 
              title="EXPORT_INVOICES_DATA" 
              onClick={() => setShowExportInvoicesModal(true)} 
              icon="far fa-file-download"
            />
            {
              !hasPermissionDataExport &&
              <Tooltip id="permission-warning-tooltip" place="bottom" type="blue">
                <Text content="EXPORT_CUSTOMER_DATA_WARNING_PERMISSION" noMargin />
              </Tooltip>
            }
          </div>
          <Pagination
            className="invoices-list__search__pagination"
            pageCount={pageCount}
            isVisible={pageCount > 1}
            forcePage={currentPage - 1}
            onPageChange={({ selected }) => {
              dispatch(setInvoicesField("invoicesPaginated", { ...invoicesPaginated, currentPage: selected + 1 }))
              dispatch(fetchInvoices(currentCompanyDomain, selected + 1, undefined, searchedInvoices, invoiceStatus?.value, isDateDescendingOrder))
            }}
          />
        </div>
      </div>

      {isFetching && <ProgressIndicator color="blue" />}
      {!isFetching &&
      <PageWrapper>
        {pageNotification && <NoticePanel isModal className="customers__notice-panel" type={pageNotification.type} icon={pageNotification.icon} content={pageNotification.content} translateWith={pageNotification.translateWith}></NoticePanel>}
        <Table enableHoverEffect className="invoices-list__table">
          <thead>
            <tr>
              <FilterSort className="invoices-list__date-sort" label="INVOICES_LIST_DATE"
                component="th"
                isDescendingOrder={isDateDescendingOrder} 
                onClick={(bool) => {
                  dispatch(setInvoicesField("isDateDescendingOrder", bool))
                  dispatch(fetchInvoices(currentCompanyDomain, currentPage, undefined, searchedInvoices, invoiceStatus?.value, bool))
                }}  
              />
              <Text content={"INVOICES_LIST_CUSTOMER_NAME"} component="th" noMargin />
              <Text content={"INVOICES_LIST_AMOUNT"} component="th" noMargin />
              <Text content={"INVOICES_LIST_INVOICE_NUMBER"} component="th" noMargin />
              <FilterDropdown label="INVOICES_LIST_STATUS" component="th"
                className="invoices-list__status-filter"
                options={filterOptions}
                selected={invoiceStatus}
                onSelect={(filter) => {
                  dispatch(setInvoicesField("invoiceStatus", filter))
                  dispatch(fetchInvoices(currentCompanyDomain, 1, undefined, searchedInvoices, filter.value, isDateDescendingOrder))
                }}
                onClear={() => {
                  dispatch(setInvoicesField("invoiceStatus", undefined));
                  dispatch(fetchInvoices(currentCompanyDomain, 1, undefined, searchedInvoices, undefined, isDateDescendingOrder))
                }}
              />
              <Text content={"INVOICES_LIST_CUSTOMERID"} component="th" noMargin />
              <th className="view-arrow" />
            </tr>
          </thead>
          <tbody>{renderTableData()}</tbody>
        </Table>
      </PageWrapper>
      }

    <Modal
        isOpen={showExportInvoicesModal}
        title="EXPORT_INVOICES_DATA"
        onRequestClose={() => setShowExportInvoicesModal(false)}
        children={
        <ExportData
          title="EXPORT_INVOICES_DATA_PANEL_TITLE"
          content="EXPORT_INVOICES_DATA_PANEL_CONTENT"
          type='invoices'
          companyId={currentCompanyId}
          callback={() => {
            setShowExportInvoicesModal(false);
            dispatch(setInvoicesField("pageNotification", { type: "success", icon: "fas fa-check-circle", content: "EXPORT_DATE_SUCCESS_NOTIFICATION", translateWith: { email: parsedToken?.email } }));
          }}
        />}
      />
    </div>
  );
};


export default InvoicesList;
