/**
 * @fileoverview gRPC-Web generated client stub for billing.public.invoice
 * @enhanceable
 * @public
 */

// GENERATED CODE -- DO NOT EDIT!


import * as grpcWeb from "grpc-web";


import {
  GetInvoicePricingDisclosureResponse,
  GetInvoiceSimulationRequest,
  GetInvoiceSimulationResponse} from "./invoice_pb";

export class InvoiceServiceClient {
  client_: grpcWeb.AbstractClientBase;
  hostname_: string;
  credentials_: null | { [index: string]: string; };
  options_: null | { [index: string]: string; };

  constructor (hostname: string,
               credentials?: null | { [index: string]: string; },
               options?: null | { [index: string]: string; }) {
    if (!options) options = {};
    if (!credentials) credentials = {};
    options["format"] = "text";

    this.client_ = new grpcWeb.GrpcWebClientBase(options);
    this.hostname_ = hostname;
    this.credentials_ = credentials;
    this.options_ = options;
  }

  methodInfoGetInvoiceSimulation = new grpcWeb.AbstractClientBase.MethodInfo(
    GetInvoiceSimulationResponse,
    (request: GetInvoiceSimulationRequest) => {
      return request.serializeBinary();
    },
    GetInvoiceSimulationResponse.deserializeBinary
  );

  getInvoiceSimulation(
    request: GetInvoiceSimulationRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: GetInvoiceSimulationResponse) => void) {
    return this.client_.rpcCall(
      this.hostname_ +
        "/billing.public.invoice.InvoiceService/GetInvoiceSimulation",
      request,
      metadata || {},
      this.methodInfoGetInvoiceSimulation,
      callback);
  }

  methodInfoGetInvoicePricingDisclosure = new grpcWeb.AbstractClientBase.MethodInfo(
    GetInvoicePricingDisclosureResponse,
    (request: GetInvoiceSimulationRequest) => {
      return request.serializeBinary();
    },
    GetInvoicePricingDisclosureResponse.deserializeBinary
  );

  getInvoicePricingDisclosure(
    request: GetInvoiceSimulationRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: GetInvoicePricingDisclosureResponse) => void) {
    return this.client_.rpcCall(
      this.hostname_ +
        "/billing.public.invoice.InvoiceService/GetInvoicePricingDisclosure",
      request,
      metadata || {},
      this.methodInfoGetInvoicePricingDisclosure,
      callback);
  }

}

