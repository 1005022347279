import React, { useState, useRef, useEffect, useMemo } from "react";
import NavigationBar from "../../../components/ui/navigation-bar/NavigationBar";
import NavigationAlertCurrencyMismatch from "../../../components/ui/navigation-alert/NavigationAlertCurrencyMismatch";
import PageWrapper from "../../../components/ui/layout/PageWrapper";
import Panel from "../../../components/ui/panel/Panel";
import Text from "../../../components/ui/text/Text";
import counterpart from "counterpart";
import StepCollapsablePanel from "../../../components/ui/step-collapsable-panel/StepCollapsablePanel";
import history from "../../../utils/history";
import FormGroup from "../../../components/ui/form-group/FormGroup";
import { Row, Col } from "react-grid-system";
import FormLabel from "../../../components/ui/form-label/FormLabel";
import Input from "../../../components/ui/input/Input";
import { AppState } from "../../..";
import { findDOMNode } from "react-dom";
import { downloadCsvFile, trimWhiteSpaces, allowNumberMoreThanZero, removeSpecialChars, allowZeroAndPositiveNumbers, extractGrpcErrorMessage } from "../../../utils/commonUtils";
import Switch from "../../../components/ui/switch/Switch";
import { setCreateCouponReducerFields, resetCreateCoupon, createCoupon, setCouponStepsCollapsed, setCouponFormCompleted, setCouponFormHasError, fetchCouponDetails, updateCoupon, fetchCouponUsage } from "../../../actions/discount-coupon/createCouponActions";
import Dropdown from "../../../components/ui/dropdown/Dropdown";
import { EligibleCustomers, CreateCouponRequest, FrequencyType, GetCouponResponse, CreateCouponResponse } from "../../../utils/grpc/generated/Billsby.Protos/core/private/coupons/coupons_pb";
import Button from "../../../components/ui/button/Button";
import "./CreateCoupon.scss";
import moment, { Moment } from "moment";
import CheckoutDatepicker from "../../../components/custom-fields/previews/ui-checkout/CheckoutDatepicker";
import { fetchDiscountDetails, resetCreateDiscount } from "../../../actions/discount-coupon/createDiscountActions";
import { CouponType } from "../../../utils/grpc/generated/Billsby.Protos/core/private/discounts/discounts_pb";
import { setDiscountsFields, fetchCouponCodes } from "../../../actions/discount-coupon/discountsActions";
import { GetCouponCodesResponse } from "../../../utils/grpc/generated/Billsby.Protos/billing/private/coupons/coupons_pb";
import { Timestamp } from "google-protobuf/google/protobuf/timestamp_pb";
import ErrorNotification from "../../../components/ui/error-notification/ErrorNotification";
import Form from "../../../components/ui/form/Form";
import { useDispatch, useSelector } from "react-redux";
interface ICreateCoupon {
  match: { params: { discountId: number, couponId: number } }
}

const CreateCoupon: React.FC<ICreateCoupon> = ({ match }) => {
  const auth = useSelector((state: AppState) => state.auth);
  const createDiscountState = useSelector((state: AppState) => state.createDiscountReducer);
  const createCouponData = useSelector((state: AppState) => state.createCouponReducer);

  const dispatch = useDispatch<Function>();

  const { helpTextContentBasicForm, helpTextTitleBasicForm, name, stageStatuses, stageCollapsed,
    stageErrors, eligibleCustomers, couponType, helpTextTitleCouponTypeForm, helpTextContentCouponTypeForm,
    couponCode, limits, hasExpiry, discountLength, expirationDate, helpTextTitleHowCouponWorksForm,
    helpTextContentHowCouponWorksForm, frequency, frequencyType, numberGenerated, isCreatingCouponRequest,
    addExtraCoupons, extraCoupons, couponUsage } = createCouponData;

  const [hasError, setHasError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const formRef0: any = useRef(null);


  const discountId = match?.params.discountId || null;
  const couponId = match?.params.couponId || null;
  const [completeForm, setCompleteForm] = useState(false);
  const [isCreate, setIsCreate] = useState(true);
  const [couponName, setCouponName] = useState("");

  const isNumberGeneratedValid = useMemo(() => numberGenerated ? numberGenerated <= 5000 : true, [numberGenerated]);
  const isExtraCouponsValid = useMemo(() => extraCoupons ? extraCoupons <= 5000 : true, [extraCoupons]);

  const eligibleCustomersTypes = [
    { value: EligibleCustomers.NEW_CUSTOMERS, label: counterpart("CREATE_COUPON_FORM_ELIGIBLE_CUSTOMERS_NEW_CUSTOMERS_ONLY") },
    { value: EligibleCustomers.EXISTING_CUSTOMERS, label: counterpart("CREATE_COUPON_FORM_ELIGIBLE_CUSTOMERS_EXISTING_CUSTOMERS_ONLY") },
    { value: EligibleCustomers.ALL_CUSTOMERS, label: counterpart("CREATE_COUPON_FORM_ELIGIBLE_CUSTOMERS_ALL_CUSTOMERS") }
  ];

  const frequencyTypes = [
    { label: counterpart("CREATE_PLAN_PERIOD_DAILY"), value: FrequencyType.DAILY },
    { label: counterpart("CREATE_PLAN_PERIOD_WEEKLY"), value: FrequencyType.WEEKLY },
    { label: counterpart("CREATE_PLAN_PERIOD_MONTHLY"), value: FrequencyType.MONTHLY },
    { label: counterpart("CREATE_PLAN_PERIOD_YEARLY"), value: FrequencyType.YEARLY }
  ];
  const frequencyTypesPlural = [
    { label: counterpart("CREATE_PLAN_PERIOD_DAILY_PLURAL"), value: FrequencyType.DAILY },
    { label: counterpart("CREATE_PLAN_PERIOD_WEEKLY_PLURAL"), value: FrequencyType.WEEKLY },
    { label: counterpart("CREATE_PLAN_PERIOD_MONTHLY_PLURAL"), value: FrequencyType.MONTHLY },
    { label: counterpart("CREATE_PLAN_PERIOD_YEARLY_PLURAL"), value: FrequencyType.YEARLY }
  ];

  const canSubmit = () => {
    if (!stageStatuses[0]) {
      setCompleteForm(false);
      return;
    }

    if (couponType === CreateCouponRequest.CouponTypeCase.SINGLE_USE) {
      if (!numberGenerated || !discountLength) {
        setCompleteForm(false);
        return;
      }
    }

    if (couponType === CreateCouponRequest.CouponTypeCase.MULTI_USE) {
      if (!couponCode || !limits || !discountLength) {
        setCompleteForm(false);
        return;
      }
    }

    if (hasExpiry) {
      if (!expirationDate) {
        setCompleteForm(false);
        return;
      }
    }

    if (discountLength && discountLength.value === CreateCouponRequest.DiscountLengthCase.FIXED_TERM) {
      setCompleteForm(!!frequency && !!frequencyType);
      return;
    }

    setCompleteForm(true);
  }

  const allowNumberLessThanOrEqual100000 = (input?: number) => {
    if (!input) return undefined;
    if (Number(input) <= 100000)
      return +input;

    return numberGenerated;
  }

  useEffect(() => {
    canSubmit();
    return () => {
    }
  }, [stageStatuses, couponType, numberGenerated, hasExpiry, expirationDate, discountLength,
    frequency, frequencyType, couponCode, limits]);

  useEffect(() => {
    if (discountId) {
      dispatch(fetchDiscountDetails(auth.currentCompanyId as number, discountId as number));
    }

    if (couponId) {
      setIsCreate(false);
      dispatch(fetchCouponDetails(Number(auth.currentCompanyId), Number(couponId)))
        .then((res: any) => {
          const response = res.response as GetCouponResponse;
          if (response.getCouponType() === CouponType.MULTI_USE) {
            dispatch(fetchCouponUsage(Number(auth.currentCompanyId), Number(couponId)));
          }
          setCouponName(response.getName());
        }).catch((err: any) => history.push("/discount-and-coupons"));
    }

    return () => {
      dispatch(resetCreateCoupon());
      dispatch(resetCreateDiscount());
    }
  }, [])

  const isGreaterThanOrEqualToUsedCoupon = useMemo(() => {
    if (!isCreate) {
      if (couponType === CreateCouponRequest.CouponTypeCase.MULTI_USE) {
        return Number(limits) >= Number(couponUsage);
      }
    }
    return true;
  }, [couponUsage, limits, couponType, isCreate])

  const downloadCsv = async (filename: string, couponId: number) => {
    try {
      const { response } = await dispatch(fetchCouponCodes(Number(auth.currentCompanyId), couponId)) as { response: GetCouponCodesResponse };
      const rows = response.getCouponCodesList().map(i => [name, i.getCode(),
        `${moment((i.getCreatedOn() as Timestamp).toDate()).format("DD MMM YYYY")} at ${moment((i.getCreatedOn() as Timestamp).toDate()).format("HH:mm:ss Z")}`,
        i.getUsageLeft() > 0 ? "Unused" : "Used"]);
      rows.unshift([counterpart("CSV_COLUMN_COUPON_NAME"),
      counterpart("CSV_COLUMN_COUPON_CODE"),
      counterpart("CSV_COLUMN_COUPON_TIME_STAMP"),
      counterpart("CSV_COLUMN_COUPON_USAGE_STATE")
      ]);
      downloadCsvFile(rows, filename);
    } catch (err) {
      console.log(err)
    }
  }

  const displayError = (errorMessage: string) => {
    setErrorMessage(extractGrpcErrorMessage(errorMessage));
    setHasError(true);
  }

  const submit = async () => {
    const fileName = `${name}-${moment(new Date()).format("DD MMM YYYY")} at ${moment(new Date()).format("HH:mm:ss Z")}`;
    if (couponId) {
      try {
        const res = await dispatch(updateCoupon(Number(auth.currentCompanyId), Number(couponId), createCouponData)) as any;
        if (!!res.error) {
          displayError(res.error.message);
        } else {
          if (couponType === CreateCouponRequest.CouponTypeCase.SINGLE_USE) {
            dispatch(setDiscountsFields("newCouponId", couponId));
            dispatch(setDiscountsFields("newCouponName", couponName));
            downloadCsv(fileName, couponId);
          }
          history.push("/discount-and-coupons");
        }
      } catch (err) {
        console.log(err);
      }
    } else {
      try {
        const res = await dispatch(createCoupon(Number(auth.currentCompanyId), Number(discountId), createCouponData)) as any;
        if (!!res.error) {
          displayError(res.error.message);
        } else {
          const response = res.response as CreateCouponResponse;
          if (couponType === CreateCouponRequest.CouponTypeCase.SINGLE_USE) {
            dispatch(setDiscountsFields("newCouponId", response.getCouponId()));
            dispatch(setDiscountsFields("newCouponName", name));
            downloadCsv(fileName, response.getCouponId());
          }
          history.push("/discount-and-coupons");
        }
      } catch (err) {
        console.log(err);
      }
    }
  }

  const hasSelectedCouponType = () => {
    dispatch(setCouponFormCompleted(1, true));
    dispatch(setCouponFormHasError(1, false));
    dispatch(setCouponStepsCollapsed(2));
  }
  return (
    <div>
      <NavigationBar
        pageTitle={<>
          <Text content="CREATE_COUPON_NAV_TITLE" noMargin className="create-coupon__nav-title" component="span" />
          <Text content={createDiscountState.name} shouldTranslate={false} noMargin className="create-coupon__nav-title-bold" component="span" />
        </>}
        shouldTranslatePageTitle={false}
        previousPageTitle="DISCOUNT_AND_COUPONS_TITLE"
        previousPageCb={() => history.push("/discount-and-coupons")}
      />
      <NavigationAlertCurrencyMismatch />

      <PageWrapper className="create-coupon">
        <Panel className="create-coupon__heading"
          title={isCreate ? "CREATE_COUPON_TITLE" : "EDIT_COUPON_TITLE"}
          // shouldTranslate={false}
          translateWith={{ name: couponName }}
        >
          <Text
            content={isCreate ? "CREATE_COUPON_TEXT_1" : "EDIT_COUPON_TEXT_1"}
            className="create-coupon__heading-text"
            noMargin
          />
        </Panel>

        <StepCollapsablePanel
          number={isCreate ? 1 : undefined}
          isCompleted={stageStatuses[0]}
          isCollapsed={stageCollapsed[0]}
          isShowTitleIcon={isCreate}
          onClick={() => {
            if (isCreate) dispatch(setCouponStepsCollapsed(0));
          }}
          //onFocus={() => setIsBasicFormCollapsed(!isBasicFormCollapsed)}
          hasError={stageErrors[0]}
          title={isCreate ? "CREATE_COUPON_FORM_HEADER_BASICS" : "EDIT_COUPON_FORM_HEADER_BASICS"}
          helpMenuPosition="right"
          helpMenuTitle={helpTextTitleBasicForm}
          helpMenuContent={
            <div>
              {helpTextContentBasicForm.map((text, id) => <Text key={id} content={text} />)}
            </div>
          }
        >

          <Form ref={formRef0} onChange={() => {
            const form = findDOMNode(formRef0.current) as HTMLFormElement;
            if (form.checkValidity()) {
              dispatch(setCouponFormCompleted(0, true));
              dispatch(setCouponFormHasError(0, false));
            } else {
              dispatch(setCouponFormCompleted(0, false));
              dispatch(setCouponFormHasError(0, true));
            }
          }}>
            <FormGroup className={isCreate ? "create-coupon__basic-form" : ""}>
              <Row align="center">
                <Col md={isCreate ? 3 : 2}>
                  <FormLabel target="name" content="CREATE_COUPON_FORM_NAME_INPUT" noMargin />
                </Col>
                <Col md={isCreate ? 9 : 10}>
                  <Input
                    id="name"
                    required
                    value={name}
                    placeholder={counterpart("CREATE_COUPON_FORM_NAME_INPUT_PLACEHOLDER")}
                    onChange={(evt: any) => dispatch(setCreateCouponReducerFields("name", evt.target.value))}
                    onFocus={() => {
                      dispatch(setCreateCouponReducerFields("helpTextTitleBasicForm", "CREATE_COUPON_FORM_NAME_HELP_TITLE"));
                      dispatch(setCreateCouponReducerFields("helpTextContentBasicForm", ["CREATE_COUPON_FORM_NAME_HELP_TEXT_1"]));
                    }}
                  />
                </Col>
              </Row>
            </FormGroup>

            <FormGroup className={isCreate ? "create-coupon__basic-form" : ""}>
              <Row align="center">
                <Col md={isCreate ? 3 : 2}>
                  <FormLabel target="eligibleCustomers" content="CREATE_COUPON_FORM_ELIGIBLE_CUSTOMERS" noMargin />
                </Col>
                <Col md={isCreate ? 9 : 10}>
                  <Dropdown
                    required
                    placeholder={counterpart("CREATE_COUPON_FORM_ELIGIBLE_CUSTOMERS_PLACEHOLDER")}
                    id="eligibleCustomers"
                    dividerAfter={4}
                    onFocus={() => {
                      dispatch(setCreateCouponReducerFields("helpTextTitleBasicForm", "CREATE_COUPON_FORM_ELIGIBLE_CUSTOMERS_HELP_TITLE"));
                      dispatch(setCreateCouponReducerFields("helpTextContentBasicForm", ["CREATE_COUPON_FORM_ELIGIBLE_CUSTOMERS_HELP_TEXT_1"]));
                    }}
                    onChange={(selectedItem: any) => dispatch(setCreateCouponReducerFields("eligibleCustomers", selectedItem))}
                    value={eligibleCustomers}
                    options={eligibleCustomersTypes}
                  />
                </Col>
              </Row>
            </FormGroup>
          </Form>
        </StepCollapsablePanel>

        <StepCollapsablePanel
          number={isCreate ? 2 : undefined}
          isCompleted={stageStatuses[1]}
          isCollapsed={stageCollapsed[1]}
          isShowTitleIcon={isCreate}
          isLocked={!stageStatuses[0] || !eligibleCustomers || stageStatuses[1]}
          onClick={() => {
            dispatch(setCouponStepsCollapsed(1))
            dispatch(setCreateCouponReducerFields("helpTextTitleCouponTypeForm", "CREATE_COUPON_FORM_COUPON_TYPE_SINGLE_USE_HELP_TITLE"));
            dispatch(setCreateCouponReducerFields("helpTextContentCouponTypeForm", ["CREATE_COUPON_FORM_COUPON_TYPE_SINGLE_USE_HELP_TEXT_1"]));
            dispatch(setCreateCouponReducerFields("couponType", CreateCouponRequest.CouponTypeCase.SINGLE_USE));
          }}
          //onFocus={() => setIsBasicFormCollapsed(!isBasicFormCollapsed)}
          hasError={stageErrors[1]}
          title={
            <div className="create-coupon__coupon-type-title">
              <Text content={isCreate ? "CREATE_COUPON_FORM_COUPON_TYPE" : "EDIT_COUPON_FORM_COUPON_TYPE"} noMargin />
              {
                stageStatuses[1]
                  ? (
                    <div>
                      <div className="border" />
                      <Text
                        content={couponType === CreateCouponRequest.CouponTypeCase.MULTI_USE ? "CREATE_COUPON_FORM_COUPON_TYPE_MULTI_USE_TEXT" : "CREATE_COUPON_FORM_COUPON_TYPE_SINGLE_USE_TEXT"} noMargin />
                    </div>
                  )
                  : null
              }
            </div>
          }
          shouldTranslateTitle={false}
          isShowLockIcon={!stageStatuses[0] || stageStatuses[1]}
          helpMenuPosition="right"
          hasHelpSection={false}
          helpMenuTitle={helpTextTitleCouponTypeForm}
          helpMenuContent={
            <div>
              {helpTextContentCouponTypeForm.map((text, id) => <Text key={id} content={text} />)}
            </div>
          }
        >
          <div className="create-coupon__select-coupon-type__panel">
            <Text content="CREATE_COUPON_FORM_COUPON_TYPE_TEXT" />
            <div className="create-coupon__select-panel">
              <Panel noMargin className="create-coupon__select-panel--single-use" title="CREATE_COUPON_FORM_COUPON_TYPE_SINGLE_USE_TEXT">
                <Text content="CREATE_COUPON_FORM_COUPON_TYPE_SINGLE_USE_HELP_TEXT_1" />
                <Button id="single-use" buttonType="add" title="CREATE_COUPON_FORM_COUPON_TYPE_SELECT" onClick={() => {
                  dispatch(setCreateCouponReducerFields("couponType", CreateCouponRequest.CouponTypeCase.SINGLE_USE));
                  hasSelectedCouponType();
                }}></Button>
              </Panel>
              <Panel noMargin className="create-coupon__select-panel--multi-use" title="CREATE_COUPON_FORM_COUPON_TYPE_MULTI_USE_HELP_TITLE">
                <Text content="CREATE_COUPON_FORM_COUPON_TYPE_MULTI_USE_HELP_TEXT_1" />
                <Button id="multi-use" buttonType="add" title="CREATE_COUPON_FORM_COUPON_TYPE_SELECT" onClick={() => {
                  dispatch(setCreateCouponReducerFields("couponType", CreateCouponRequest.CouponTypeCase.MULTI_USE));
                  hasSelectedCouponType();
                }}></Button>
              </Panel>
            </div>
          </div>
        </StepCollapsablePanel>

        <StepCollapsablePanel
          number={isCreate ? 3 : undefined}
          isCompleted={stageStatuses[2]}
          isCollapsed={isCreate ? stageCollapsed[2] : false}
          isShowTitleIcon={isCreate}
          isLocked={isCreate ? !stageStatuses[1] : false}
          onClick={() => {
            if (isCreate) dispatch(setCouponStepsCollapsed(2));
          }}
          //onFocus={() => setIsBasicFormCollapsed(!isBasicFormCollapsed)}
          hasError={stageErrors[1]}
          title={isCreate ? "CREATE_COUPON_FORM_COUPON_WORKS" : "EDIT_COUPON_FORM_COUPON_WORKS"}
          isShowLockIcon={!stageStatuses[1]}
          helpMenuPosition="right"
          helpMenuTitle={helpTextTitleHowCouponWorksForm}
          helpMenuContent={
            <div>
              {helpTextContentHowCouponWorksForm.map((text, id) => <Text key={id} content={text} />)}
            </div>
          }
        >
          <Text content="EDIT_COUPON_FORM_COUPON_WORKS_TEXT" />
          {couponType === CreateCouponRequest.CouponTypeCase.SINGLE_USE ? <FormGroup>
            <Row align="center">
              <Col md={2}>
                <FormLabel className={!isCreate ? "create-coupon__text-disabled" : ""}
                  target="numberGenerated" content="CREATE_COUPON_FORM_COUPON_WORKS_NUMBER_GENERATED" noMargin />
              </Col>
              <Col md={isCreate ? 10 : 7}>
                <Input
                  id="numberGenerated"
                  className="create-coupon__generate-input"
                  disabled={!isCreate}
                  required
                  value={numberGenerated}
                  type="text"
                  isError={!isNumberGeneratedValid}
                  errorMsg={counterpart("CREATE_COUPON_FORM_COUPON_WORKS_USES_LIMIT_WARNING")}
                  placeholder={counterpart("CREATE_COUPON_FORM_COUPON_WORKS_NUMBER_GENERATED_PLACEHOLDER")}
                  onChange={(evt: any) => dispatch(setCreateCouponReducerFields("numberGenerated", allowNumberLessThanOrEqual100000(removeSpecialChars(evt.target.value))))}
                  onFocus={() => {
                    dispatch(setCreateCouponReducerFields("helpTextTitleHowCouponWorksForm", "CREATE_COUPON_FORM_COUPON_WORKS_NUMBER_GENERATED_HELP_TITLE"));
                    dispatch(setCreateCouponReducerFields("helpTextContentHowCouponWorksForm", ["CREATE_COUPON_FORM_COUPON_WORKS_NUMBER_GENERATED_HELP_TEXT_1", "CREATE_COUPON_FORM_COUPON_WORKS_NUMBER_GENERATED_HELP_TEXT_2", "CREATE_COUPON_FORM_COUPON_WORKS_NUMBER_GENERATED_HELP_TEXT_3"]));
                  }}
                  onMouseOver={() => {
                    if (!isCreate) {
                      dispatch(setCreateCouponReducerFields("helpTextTitleHowCouponWorksForm", "CREATE_COUPON_FORM_COUPON_WORKS_NUMBER_GENERATED_HELP_TITLE"));
                      dispatch(setCreateCouponReducerFields("helpTextContentHowCouponWorksForm", ["CREATE_COUPON_FORM_COUPON_WORKS_NUMBER_GENERATED_HELP_TEXT_1", "CREATE_COUPON_FORM_COUPON_WORKS_NUMBER_GENERATED_HELP_TEXT_2", "CREATE_COUPON_FORM_COUPON_WORKS_NUMBER_GENERATED_HELP_TEXT_3"]));
                    }
                  }}
                />
              </Col>
              {!isCreate && <Col md={3}>
                <Button
                  className="create-coupon__generate-more"
                  id='coupon-generate-more'
                  buttonType='add'
                  icon='fas fa-plus-circle'
                  title="EDIT_GENERATE_MORE"
                  disabled={addExtraCoupons}
                  onClick={() => dispatch(setCreateCouponReducerFields("addExtraCoupons", true))}
                ></Button>
              </Col>}
            </Row>
          </FormGroup> : <></>}
          {addExtraCoupons ? <FormGroup>
            <Row align="center">
              <Col md={2}>
                <FormLabel target="extraCoupons" content="EDIT_COUPON_FORM_COUPON_WORKS_EXTRA_COUPONS" noMargin />
              </Col>
              <Col md={10}>
                <Input
                  id="extraCoupons"
                  className="create-coupon__generate-input"
                  required
                  value={extraCoupons}
                  isError={!isExtraCouponsValid}
                  errorMsg={counterpart("CREATE_COUPON_FORM_COUPON_WORKS_USES_LIMIT_WARNING")}
                  type="text"
                  placeholder={counterpart("EDIT_COUPON_FORM_COUPON_WORKS_EXTRA_COUPONS_PLACEHOLDER")}
                  onChange={(evt: any) => dispatch(setCreateCouponReducerFields("extraCoupons", allowZeroAndPositiveNumbers(removeSpecialChars(evt.target.value))))}
                  onFocus={() => {
                    dispatch(setCreateCouponReducerFields("helpTextTitleHowCouponWorksForm", "CREATE_COUPON_FORM_COUPON_WORKS_EXTRA_COUPONS_HELP_TITLE"));
                    dispatch(setCreateCouponReducerFields("helpTextContentHowCouponWorksForm", ["CREATE_COUPON_FORM_COUPON_WORKS_EXTRA_COUPONS_HELP_TEXT_1"]));
                  }}
                />
              </Col>
            </Row>
          </FormGroup> : <></>}
          {couponType === CreateCouponRequest.CouponTypeCase.MULTI_USE ? <><FormGroup>
            <Row align="center">
              <Col md={2}>
                <FormLabel target="couponCode" content="CREATE_COUPON_FORM_COUPON_WORKS_COUPON_CODE" noMargin />
              </Col>
              <Col md={10}>
                <Input
                  id="couponCode"
                  required
                  value={couponCode}
                  placeholder={counterpart("CREATE_COUPON_FORM_COUPON_WORKS_COUPON_CODE_PLACEHOLDER")}
                  onChange={(evt: any) => dispatch(setCreateCouponReducerFields("couponCode", trimWhiteSpaces(evt.target.value, "all")))}
                  onFocus={() => {
                    dispatch(setCreateCouponReducerFields("helpTextTitleHowCouponWorksForm", "CREATE_COUPON_FORM_COUPON_WORKS_COUPON_CODE_HELP_TITLE"));
                    dispatch(setCreateCouponReducerFields("helpTextContentHowCouponWorksForm", ["CREATE_COUPON_FORM_COUPON_WORKS_COUPON_CODE_HELP_TEXT_1"]));
                  }}
                />
              </Col>
            </Row>
          </FormGroup>
            <FormGroup>
              <Row align="center">
                <Col md={2}>
                  <FormLabel target="limits" content="CREATE_COUPON_FORM_COUPON_WORKS_USES_LIMIT" noMargin />
                </Col>
                <Col md={10}>
                  <Input
                    id="limits"
                    required
                    value={limits}
                    type="text"
                    placeholder={counterpart("CREATE_COUPON_FORM_COUPON_WORKS_USES_LIMIT_PLACEHOLDER")}
                    onChange={(evt: any) => dispatch(setCreateCouponReducerFields("limits", allowNumberMoreThanZero(removeSpecialChars(evt.target.value))))}
                    onFocus={() => {
                      dispatch(setCreateCouponReducerFields("helpTextTitleHowCouponWorksForm", "CREATE_COUPON_FORM_COUPON_WORKS_USES_LIMIT_HELP_TITLE"));
                      dispatch(setCreateCouponReducerFields("helpTextContentHowCouponWorksForm", ["CREATE_COUPON_FORM_COUPON_WORKS_USES_LIMIT_HELP_TEXT_1"]));
                    }}
                  />
                </Col>
              </Row>
            </FormGroup>
            {!isCreate && <FormGroup>
              <Row align="center">
                <Col md={2}>
                </Col>
                <Col md={10}>
                  <Text content="EDIT_COUPON_FORM_COUPON_WORKS_USED_COUPONS_TEXT" translateWith={{ usedCoupons: couponUsage }} />
                </Col>
              </Row>
            </FormGroup>}</> : <></>}
          <FormGroup>
            <Row align="center">
              <Col md={2}>
                <FormLabel target="hasExpiry" content="CREATE_COUPON_FORM_COUPON_WORKS_EXIPIRATION" noMargin />
              </Col>
              <Col md={2}>
                <Switch
                  className="create-coupon__basic-form__switch"
                  id={"create-coupon-conjuction-switch"}
                  checked={hasExpiry}
                  onChange={() => {
                    dispatch(setCreateCouponReducerFields("hasExpiry", !hasExpiry));
                    dispatch(setCreateCouponReducerFields("helpTextTitleHowCouponWorksForm", "CREATE_COUPON_FORM_COUPON_WORKS_EXPIRATION_DATE_HELP_TITLE"));
                    dispatch(setCreateCouponReducerFields("helpTextContentHowCouponWorksForm", ["CREATE_COUPON_FORM_COUPON_WORKS_EXPIRATION_DATE_HELP_TEXT_1", "CREATE_COUPON_FORM_COUPON_WORKS_EXPIRATION_DATE_HELP_TEXT_2"]));
                  }}
                />
              </Col>
              <Col md={8}>
                {hasExpiry &&
                  <CheckoutDatepicker
                    enableOutsideDays={false}
                    placeholder={counterpart("CREATE_COUPON_FORM_COUPON_WORKS_EXIPIRATION_PLACEHOLDER")}
                    onChange={(date: Moment | null) => {
                      dispatch(setCreateCouponReducerFields("expirationDate", !!date ? date.toDate() : undefined));
                      dispatch(setCreateCouponReducerFields("helpTextTitleHowCouponWorksForm", "CREATE_COUPON_FORM_COUPON_WORKS_EXPIRATION_DATE_HELP_TITLE"));
                      dispatch(setCreateCouponReducerFields("helpTextContentHowCouponWorksForm", ["CREATE_COUPON_FORM_COUPON_WORKS_EXPIRATION_DATE_HELP_TEXT_1", "CREATE_COUPON_FORM_COUPON_WORKS_EXPIRATION_DATE_HELP_TEXT_2"]));
                    }}
                    date={expirationDate ? moment(new Date(expirationDate)).utc() : null}
                  ></CheckoutDatepicker>}
              </Col>
            </Row>
          </FormGroup>
          <FormGroup>
            <Row align="center">
              <Col md={2}>
                <FormLabel target="discountLength" content="CREATE_COUPON_FORM_COUPON_WORKS_DISCOUNT_LENGTH" noMargin />
              </Col>
              <Col md={10}>
                <Dropdown
                  id="discountLength"
                  dividerAfter={4}
                  placeholder={counterpart("CREATE_COUPON_FORM_COUPON_WORKS_DISCOUNT_LENGTH_PLACEHOLDER")}
                  onFocus={() => {
                    dispatch(setCreateCouponReducerFields("helpTextTitleHowCouponWorksForm", "CREATE_COUPON_FORM_COUPON_WORKS_DISCOUNT_LENGTH_HELP_TITLE"));
                    dispatch(setCreateCouponReducerFields("helpTextContentHowCouponWorksForm", ["CREATE_COUPON_FORM_COUPON_WORKS_DISCOUNT_LENGTH_HELP_TEXT_1", "CREATE_COUPON_FORM_COUPON_WORKS_DISCOUNT_LENGTH_HELP_TEXT_2", "CREATE_COUPON_FORM_COUPON_WORKS_DISCOUNT_LENGTH_HELP_TEXT_3"]));
                  }}
                  onChange={(selectedItem: any) => dispatch(setCreateCouponReducerFields("discountLength", selectedItem))}
                  value={discountLength}
                  options={[
                    { value: CreateCouponRequest.DiscountLengthCase.IS_LIFETIME, label: counterpart("CREATE_COUPON_FORM_COUPON_WORKS_DISCOUNT_LENGTH_LIFETIME") },
                    { value: CreateCouponRequest.DiscountLengthCase.FIXED_TERM, label: counterpart("CREATE_COUPON_FORM_COUPON_WORKS_DISCOUNT_LENGTH_FIXED_TERM") }
                  ]}
                />
              </Col>
            </Row>
          </FormGroup>
          <>{discountLength && discountLength.value === CreateCouponRequest.DiscountLengthCase.FIXED_TERM &&
            <FormGroup>
              <Row align="center">
                <Col md={2}>
                </Col>
                <Col md={4}>
                  <Input
                    id="frequency"
                    required
                    value={frequency}
                    type="number"
                    min={0}
                    placeholder={counterpart("CREATE_COUPON_FORM_COUPON_WORKS_DISCOUNT_LENGTH_FIXED_TERM_FREQUENCY_PLACEHOLDER")}
                    onChange={(evt: any) => {
                      dispatch(setCreateCouponReducerFields("frequency", allowZeroAndPositiveNumbers(evt.target.value)));
                      if (frequencyType) {
                        const value = evt.target.value > 1 ? frequencyTypesPlural.filter(i => i.value === frequencyType.value)[0]
                          : frequencyTypes.filter(i => i.value === frequencyType.value)[0];
                        dispatch(setCreateCouponReducerFields("frequencyType", value));
                      }
                    }}
                    onFocus={() => {
                      dispatch(setCreateCouponReducerFields("helpTextTitleHowCouponWorksForm", "CREATE_COUPON_FORM_COUPON_WORKS_DISCOUNT_LENGTH_HELP_TITLE"));
                      dispatch(setCreateCouponReducerFields("helpTextContentHowCouponWorksForm", ["CREATE_COUPON_FORM_COUPON_WORKS_DISCOUNT_LENGTH_HELP_TEXT_1", "CREATE_COUPON_FORM_COUPON_WORKS_DISCOUNT_LENGTH_HELP_TEXT_2", "CREATE_COUPON_FORM_COUPON_WORKS_DISCOUNT_LENGTH_HELP_TEXT_3"]));
                    }}
                  />
                </Col>
                <Col md={6}>
                  <Dropdown
                    id="frequencyType"
                    dividerAfter={4}
                    onFocus={() => {
                      dispatch(setCreateCouponReducerFields("helpTextTitleHowCouponWorksForm", "CREATE_COUPON_FORM_COUPON_WORKS_DISCOUNT_LENGTH_HELP_TITLE"));
                      dispatch(setCreateCouponReducerFields("helpTextContentHowCouponWorksForm", ["CREATE_COUPON_FORM_COUPON_WORKS_DISCOUNT_LENGTH_HELP_TEXT_1", "CREATE_COUPON_FORM_COUPON_WORKS_DISCOUNT_LENGTH_HELP_TEXT_2", "CREATE_COUPON_FORM_COUPON_WORKS_DISCOUNT_LENGTH_HELP_TEXT_3"]));
                    }}
                    onChange={(selectedItem: any) => dispatch(setCreateCouponReducerFields("frequencyType", selectedItem))}
                    value={frequencyType}
                    options={frequency && +frequency > 1 ? frequencyTypesPlural : frequencyTypes}
                  />
                </Col>
              </Row>
            </FormGroup>}</>
        </StepCollapsablePanel>

        <Button
          className="create-coupon__btn"
          id="create-coupon-btn"
          title={isCreate ? "CREATE_COUPON_BUTTON" : "EDIT_COUPON_BUTTON"}
          buttonType="general"
          disabled={!completeForm || !isGreaterThanOrEqualToUsedCoupon || !isNumberGeneratedValid || !isExtraCouponsValid}
          isLoading={isCreatingCouponRequest}
          isFullWidth={true}
          onClick={() => submit()}
        />

        {!isCreate && <Text className="create-coupon__warning" content="EDIT_COUPON_WARNING_MESSAGE" />}
        <ErrorNotification title={!!couponId ? "UPDATE_COUPON_FAILURE" : "CREATE_COUPON_FAILURE"}
          subtitle={errorMessage}
          shouldTranslateSubtitle={false}
          showAlert={hasError} onClose={() => {
            setHasError(false);
            setErrorMessage("");
          }} />
      </PageWrapper>
    </div>
  )
}

export default CreateCoupon;
