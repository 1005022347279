import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import "./CancelledCompany.scss";
import Text from "../../components/ui/text/Text";
import cancelledImg from "../../images/cancelled-company.svg";
import { AppState } from "../..";
import Header from "../header/Header";
import { logout } from "../../utils/authUtils";

const CancelledCompany: React.FC = () => {
  const { dashboardSettings } = useSelector((state: AppState) => ({ dashboardSettings: state.auth.dashboardSettings }))

  useEffect(() => {
   //Logout user if entering new url 
   window.onbeforeunload = logout

   //Logout user when navigating away from cancelled page
   return () => logout()
 }, [])

  if(!dashboardSettings || !dashboardSettings.deletedCompany) {
    return <div/>
  }

  return <>
    <div className="cancelled-company">
      <Header isSimplified ></Header>
      <div className="cancelled-company__content" >
        <Text className="cancelled-company__content__header" content="CANCELLED_COMPANY_HEADER"></Text>
        <Text className="cancelled-company__content__title" content="CANCELLED_COMPANY_TITLE" translateWith={{ companyName: dashboardSettings.deletedCompany }}></Text>
        <img src={cancelledImg} alt="Cancelled company" />
        <Text className="cancelled-company__content__subtitle" content="CANCELLED_COMPANY_SUBTITLE"></Text>
      </div>
    </div>
  </>
}

export default CancelledCompany;
