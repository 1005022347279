import React from "react";
import Translate from "react-translate-component";
import ButtonLoader from "./ButtonLoader";
import "./Button.scss";
import { Link } from "react-router-dom";
import Tooltip from "../tooltip/Tooltip";
import Text from "../text/Text";

interface IButton {
  title: string,
  disabled?: boolean,
  isVisible?: boolean,
  onClick?: (event: any) => void,
  isLoading?: boolean,
  isFullWidth?: boolean,
  translateWith?: object,
  buttonType?: "general" | "custom" | "add" | "light" | "error" | "success" | "with-shadow" | "orange" | "orangeBillsbyMarketing",
  type?: "button" | "submit" | "reset",
  id: string,
  icon?: string,
  iconPosition?: "left" | "right",
  style?: object,
  className?: string,
  shouldTranslate?: boolean,
  tabIndex?: number,
  link?: string,
  locale?: string,
  ref?: any,
  tooltipMessage?: string | null
}

const Button: React.FC<IButton> = ({
  title = "Button title",
  disabled = false,
  isVisible = true,
  onClick = () => { },
  isLoading = false,
  isFullWidth = false,
  translateWith = {},
  buttonType = "general",
  type = "button",
  id = "",
  icon = "",
  iconPosition = "left",
  style = {},
  className = "",
  shouldTranslate = true,
  tabIndex = 0,
  link,
  locale,
  tooltipMessage,
  ...rest
}) => {

  const fullWidthClassName: string = isFullWidth ? "btn-full" : "";
  const Component = link ? Link : "button";

  const defaultProps: any = {
    id,
    type: type,
    className: `button ${fullWidthClassName} btn-basic btn-${buttonType} ${className} ${disabled ? "btn-disabled" : ""}`,
    disabled: disabled || isLoading,
    onClick: disabled || isLoading ? undefined : onClick,
    style: style,
    tabIndex: tabIndex,
    ...rest
  }

  const componentProps = link ? { ...defaultProps, onClick: undefined, to: link } : defaultProps

  if (!isVisible) {
    return null;
  }

  const renderContent = () => {
    if (isLoading) {
      return <ButtonLoader />
    }

    return <>
      {icon && iconPosition === "left" ? <i className={`button-icon ${icon} ${iconPosition}`} /> : null}

      {
        shouldTranslate
          ? <Translate locale={locale} content={title} component="span" with={translateWith} />
          : <span>{title}</span>
      }

      {icon && iconPosition === "right" ? <i className={`button-icon ${icon} ${iconPosition}`} /> : null}
    </>
  }

  if (tooltipMessage) {
    return (
      /*
        Important: Disabled attribute is set to false to enable the ReactTooltip to
        work on the element without the need to wrap it in another tag. This won't affect the current
        behavior since we use btn-disabled css class name.
      */
      <>
        <Component data-tip data-for={id} {...componentProps} disabled={false}  >
          {renderContent()}
        </Component>
        <Tooltip id={id} className="button-tooltip" place='bottom' type='blue'>
          <Text content={tooltipMessage} noMargin />
        </Tooltip>
      </>
    )
  }

  return (
    <Component {...componentProps}  >
      {renderContent()}
    </Component>
  );
}

export default Button;
