import React from "react";
import { Row, Col } from "react-grid-system";
import Panel from "../../ui/panel/Panel";
import Text from "../../ui/text/Text";
import FormGroup from "../../ui/form-group/FormGroup";
import Switch from "../../ui/switch/Switch";
import "./ValidationOptions.scss";

interface IValidationOptions {
  onValidateLocation?: () => void;
  onValidateTax?: () => void;
  validateLocation?: boolean;
  validateTax?: boolean;
  taxIdentifier?: "ABN" | "VAT";
}

const ValidationOptions: React.FC<IValidationOptions> = React.memo(({ onValidateLocation, onValidateTax, validateLocation = false, validateTax = false, taxIdentifier = "ABN" }) => {
  return (
    <Panel className="validation-options" title="VALIDATION_OPTIONS_TITLE">
      <Text content="VALIDATION_OPTIONS_CONTENT" />
      {onValidateLocation && (
        <FormGroup noMargin={!onValidateTax}>
          <Row align="center">
            <Col md={1}>
              <Switch id="validate-location" checked={validateLocation} onChange={onValidateLocation} />
            </Col>
            <Col md={11}>
              <Text component="span" content="VALIDATION_OPTIONS_LOCATION" />
            </Col>
          </Row>
        </FormGroup>
      )}
      {onValidateTax && (
        <FormGroup noMargin>
          <Row align="center">
            <Col md={1}>
              <Switch id="validate-tax" checked={validateTax} onChange={onValidateTax} />
            </Col>
            <Col md={11}>
              <Text component="span" content="VALIDATION_OPTIONS_TAX" translateWith={{ taxIdentifier }} />
            </Col>
          </Row>
        </FormGroup>
      )}
    </Panel>
  );
});

export default ValidationOptions;
