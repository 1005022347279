import React, { useState } from "react";
import StepCollapsablePanel from "../../../../ui/step-collapsable-panel/StepCollapsablePanel";
import Button from "../../../../ui/button/Button";
import Text from "../../../../ui/text/Text";
import counterpart from "counterpart";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../../../../..";
import { IPaymentGatewaysReducer } from "../../../../../reducers/paymentGatewaysReducer";
import { IAuthReducerState } from "../../../../../reducers/authReducer";
import { setPaymentGatewaysField, fetchPaymentGateways } from "../../../../../actions/paymentGatewaysActions";
import FormGroup from "../../../../ui/form-group/FormGroup";
import { Row, Col } from "react-grid-system";
import FormLabel from "../../../../ui/form-label/FormLabel";
import Input from "../../../../ui/input/Input";
import { CreateBraintreeGatewayRequest, CreateBraintreeGatewayResponse, ReplaceGatewayToBraintreeRequest } from "../../../../../utils/grpc/generated/Billsby.Protos/billing/private/paymentgateway/payment_gateway_pb";
import { PaymentGatewayServiceClient } from "../../../../../utils/grpc/generated/Billsby.Protos/billing/private/paymentgateway/Payment_gatewayServiceClientPb";
import { grpcUnaryCall } from "../../../../../utils/grpc/grpcUtils";
import Dropdown from "../../../../ui/dropdown/Dropdown";
import NoticePanel from "../../../../ui/notice-panel/NoticePanel";
import { ConfigConstants } from "../../../../../utils/config";
import { isInvalidGatewayFormsDetails } from "../PaymentGateways";
import Switch from "../../../../ui/switch/Switch";

const BrainTreePaymentGateway: React.FC = () => {
  const { selectedPaymentGateway, isCreatingGateway, replacedPaymentGateway } = useSelector<AppState, IPaymentGatewaysReducer>(state => state.paymentGatewaysReducer);
  const { currentCompanyDomain } = useSelector<AppState, IAuthReducerState>(state => state.auth);
  const dispatch = useDispatch<Function>();

  const [displayName, setDisplayName] = useState("");
  const [merchantId, setMerchantId] = useState("");
  const [publickKey, setPublicKey] = useState("");
  const [privateKey, setPrivateKey] = useState("");
  const [isMultipleMerchant, setIsMultipleMerchant] = useState(false);
  const [selectedMerchant, setSelectedMerchant] = useState({ value: "", label: "" });
  const [merchantList, setMerchantList] = useState([{ value: "", label: "" }]);
  const [hasError, setHasError] = useState(false);
  const [helpTitle, setHelpTitle] = useState("PAYMENT_GATEWAYS_ADDED_BRAINTREE_HELP_TITLE");
  const [helpContent, setHelpContent] = useState(["PAYMENT_GATEWAYS_ADDED_BRAINTREE_HELP_CONTENT_1", "PAYMENT_GATEWAYS_ADDED_BRAINTREE_HELP_CONTENT_2"]);
  const [isLoading, setIsLoading] = useState(false);
  const [isSandbox, setIsSandbox] = useState(false);

  if (!selectedPaymentGateway) {
    return null;
  }

  const onFocus = (inputField: string) => {
    switch (inputField) {
      case "DisplayName":
        setHelpTitle("PAYMENT_GATEWAYS_ADDED_BRAINTREE_DISPLAY_NAME_HELP_TITLE");
        setHelpContent(["PAYMENT_GATEWAYS_ADDED_BRAINTREE_DISPLAY_NAME_HELP_CONTENT_1"]);
        break;
      case "MerchantId":
        setHelpTitle("PAYMENT_GATEWAYS_ADDED_BRAINTREE_MERCHANT_ID_HELP_TITLE");
        setHelpContent(["PAYMENT_GATEWAYS_ADDED_BRAINTREE_MERCHANT_ID_HELP_CONTENT_1", "PAYMENT_GATEWAYS_ADDED_BRAINTREE_MERCHANT_ID_HELP_CONTENT_2"]);
        break;
      case "PublicKey":
        setHelpTitle("PAYMENT_GATEWAYS_ADDED_BRAINTREE_PUBLIC_KEY_HELP_TITLE");
        setHelpContent(["PAYMENT_GATEWAYS_ADDED_BRAINTREE_PUBLIC_KEY_HELP_CONTENT_1", "PAYMENT_GATEWAYS_ADDED_BRAINTREE_PUBLIC_KEY_HELP_CONTENT_2"]);
        break;
      case "PrivateKey":
        setHelpTitle("PAYMENT_GATEWAYS_ADDED_BRAINTREE_PRIVATE_KEY_HELP_TITLE");
        setHelpContent(["PAYMENT_GATEWAYS_ADDED_BRAINTREE_PRIVATE_KEY_HELP_CONTENT_1", "PAYMENT_GATEWAYS_ADDED_BRAINTREE_PRIVATE_KEY_HELP_CONTENT_2"]);
        break;
      case "MerchantAccount":
        setHelpTitle("PAYMENT_GATEWAYS_ADDED_BRAINTREE_MERCHANT_ACCOUNT_HELP_TITLE");
        setHelpContent(["PAYMENT_GATEWAYS_ADDED_BRAINTREE_MERCHANT_ACCOUNT_HELP_CONTENT_1", "PAYMENT_GATEWAYS_ADDED_BRAINTREE_MERCHANT_ACCOUNT_HELP_CONTENT_2"]);
        break;
    }
  }

  const addNewPaymentGateway = async () => {
    setIsLoading(true);
    const createBraintreeGatewayRequest = new CreateBraintreeGatewayRequest();
    createBraintreeGatewayRequest.setCompanyDomain(currentCompanyDomain);
    createBraintreeGatewayRequest.setDisplayName(displayName);
    createBraintreeGatewayRequest.setMerchantId(merchantId);
    createBraintreeGatewayRequest.setPublicKey(publickKey);
    createBraintreeGatewayRequest.setPrivateKey(privateKey);
    createBraintreeGatewayRequest.setIsSandbox(isSandbox);

    if (selectedMerchant.value) {
      createBraintreeGatewayRequest.setMerchantAccountId(selectedMerchant.value);
    }
    const paymentGatewayServiceClient = new PaymentGatewayServiceClient(ConfigConstants.grpcBaseUrl);
    
    let request: Promise<any>
    if(replacedPaymentGateway) {
      const replaceGatewayToBraintreeRequest = new ReplaceGatewayToBraintreeRequest()
      replaceGatewayToBraintreeRequest.setCreateBraintreeRequest(createBraintreeGatewayRequest);
      replaceGatewayToBraintreeRequest.setPaymentGatewayToReplaceId(replacedPaymentGateway.paymentGatewayId as number);
      request = grpcUnaryCall(replaceGatewayToBraintreeRequest, paymentGatewayServiceClient, paymentGatewayServiceClient.replaceGatewayToBraintree)
    }
    else {
      request = grpcUnaryCall(createBraintreeGatewayRequest, paymentGatewayServiceClient, paymentGatewayServiceClient.createBraintreeGateway)
    }

    try {
      const data = await request as CreateBraintreeGatewayResponse;
      if (data.getMerchantAccountsList().length > 1) {
        setIsMultipleMerchant(true);
        const merchants = data.getMerchantAccountsList().map((item: any) => ({ value: item.getMerchantAccountId(), label: `[${item.getCurrencyIsoCode()} - ${item.getMerchantAccountId()}]` }))
        setMerchantList([...merchants]);
        setSelectedMerchant(merchants[0]);
        setHasError(false);
      } 
      else {
        setHasError(false);
        dispatch(setPaymentGatewaysField("createdPaymentGatewayId", data.getPaymentGatewayId()));
        dispatch(setPaymentGatewaysField("selectedPaymentGateway", undefined));
        dispatch(setPaymentGatewaysField("replacedPaymentGateway", undefined));
        await dispatch(fetchPaymentGateways(currentCompanyDomain));
      }
      setIsLoading(false);
    } catch {
      setHasError(true);
      setIsLoading(false);
    }
  }

  return (
    <StepCollapsablePanel
      isCollapsed={false}
      isShowTitleIcon={false}
      onFocus={() => { }}
      hasError={false}
      title={
        <div className="payment-gateways__selected-title">
          <div>
            {selectedPaymentGateway.isLogoFontAwesome && <i className={selectedPaymentGateway.logo} />}
            {!selectedPaymentGateway.isLogoFontAwesome && <img src={selectedPaymentGateway.logo} alt="no img" />}
            <div>
              <Text content="PAYMENT_GATEWAYS_DEFAULT_NAME" noMargin />
              <Text content={selectedPaymentGateway.title} noMargin />
            </div>
          </div>
          <div>
            <Button id="visit-website" title="PAYMENT_GATEWAYS_VISIT_WEBSITE" onClick={() => window.open("https://www.braintreepayments.com/", "_self")} buttonType="add" />
          </div>
        </div>
      }
      shouldTranslateTitle={false}
      helpMenuPosition="right"
      helpMenuTitle={helpTitle}
      helpMenuContent={
        <div>
          {helpContent.map((helpLine, index) => (
            <Text key={index} content={helpLine} />
          ))}
        </div>
      }
    >
      <>{hasError && <NoticePanel
        className="payment-gateways__error-panel"
        content="PAYMENT_GATEWAYS_BRAINTREE_ERROR"
        type="error"
        icon="fas fa-exclamation-circle"
        isModal={true}
      />}
        <Text content={isMultipleMerchant ? "PAYMENT_GATEWAYS_BRAINTREE_MULTI_MERCHANT_DESCRIPTION" : "PAYMENT_GATEWAYS_BRAINTREE_DESCRIPTION"} />
        <div className="payment-gateways__update">
          {isMultipleMerchant ? (<FormGroup>
            <Row align="center" className="payment-gateways__update__form-group">
              <Col xs={2}>
                <FormLabel target="merchant-account" content="PAYMENT_GATEWAYS_BRAINTREE_MERCHANT_ACCOUNT" noMargin />
              </Col>
              <Col xs={10}>
                <Dropdown
                  id="merchant-account"
                  onFocus={() => onFocus("MerchantAccount")}
                  dividerAfter={4}
                  onChange={(merchant: any) => setSelectedMerchant(merchant)}
                  value={selectedMerchant}
                  options={merchantList}
                />
              </Col>
            </Row>
          </FormGroup>) :
            (<FormGroup>
              <Row align="center" className="payment-gateways__update__form-group">
                <Col xs={2}>
                  <FormLabel target="display-name" content="PAYMENT_GATEWAYS_BRAINTREE_DISPLAY_NAME" noMargin />
                </Col>
                <Col xs={10}>
                  <Input
                    id="display-name"
                    onFocus={() => onFocus("DisplayName")}
                    placeholder={counterpart("PAYMENT_GATEWAYS_BRAINTREE_DISPLAY_NAME_PLACEHOLDER")}
                    value={displayName}
                    onChange={(evt: any) => setDisplayName(evt.target.value)}
                  />
                </Col>
              </Row>
              <Row align="center" className="payment-gateways__update__form-group">
                <Col xs={2}>
                  <FormLabel target="merchant-id" content="PAYMENT_GATEWAYS_BRAINTREE_MERCHANT_ID" noMargin />
                </Col>
                <Col xs={10}>
                  <Input
                    id="merchant-id"
                    onFocus={() => onFocus("MerchantId")}
                    placeholder={counterpart("PAYMENT_GATEWAYS_BRAINTREE_MERCHANT_ID_PLACEHOLDER")}
                    value={merchantId}
                    trim='all'
                    onChange={(evt: any) => setMerchantId(evt.target.value)}
                  />
                </Col>
              </Row>
              <Row align="center" className="payment-gateways__update__form-group">
                <Col xs={2}>
                  <FormLabel target="public-key" content="PAYMENT_GATEWAYS_BRAINTREE_PUBLIC_KEY" noMargin />
                </Col>
                <Col xs={10}>
                  <Input
                    id="public-key"
                    onFocus={() => onFocus("PublicKey")}
                    placeholder={counterpart("PAYMENT_GATEWAYS_BRAINTREE_PUBLIC_KEY_PLACEHOLDER")}
                    value={publickKey}
                    trim='all'
                    onChange={(evt: any) => setPublicKey(evt.target.value)}
                  />
                </Col>
              </Row>
              <Row align="center" className="payment-gateways__update__form-group">
                <Col xs={2}>
                  <FormLabel target="private-key" content="PAYMENT_GATEWAYS_BRAINTREE_PRIVATE_KEY" noMargin />
                </Col>
                <Col xs={10}>
                  <Input
                    id="private-key"
                    onFocus={() => onFocus("PrivateKey")}
                    placeholder={counterpart("PAYMENT_GATEWAYS_BRAINTREE_PRIVATE_KEY_PLACEHOLDER")}
                    value={privateKey}
                    trim='all'
                    onChange={(evt: any) => setPrivateKey(evt.target.value)}
                  />
                </Col>
              </Row>

              <Row align="center" className="payment-gateways__update__form-group">
                <Col xs={2}>
                  <FormLabel target="is-sandbox" content="PAYMENT_GATEWAYS_BRAINTREE_SANDBOX" noMargin />
                </Col>
                <Col xs={10}>
                  <Switch id="is-sandbox" checked={isSandbox} onChange={() => setIsSandbox(!isSandbox)}></Switch>
                </Col>
              </Row>
            </FormGroup>)}

          <div className="payment-gateways__update-btns">
            <Button
              id="payment-gateway-save-changes"
              title={isMultipleMerchant ? "PAYMENT_GATEWAYS_BRAINTREE_SELECT_MERCHANT" : "PAYMENT_GATEWAYS_ADD_NEW_GATEWAY_BUTTON"}
              disabled={isCreatingGateway || isLoading || isInvalidGatewayFormsDetails([displayName, merchantId, publickKey, privateKey])}
              isLoading={isCreatingGateway || isLoading}
              onClick={async (evt: any) => addNewPaymentGateway()}
            />
            <Button
              id="payment-gateway-cancel"
              buttonType="error"
              title="PAYMENT_GATEWAYS_CANCEL"
              onClick={() => {
                dispatch(setPaymentGatewaysField("selectedPaymentGateway", undefined));
                dispatch(setPaymentGatewaysField("replacedPaymentGateway", undefined));
              }}
            />
          </div>
        </div>
      </>
    </StepCollapsablePanel>
  )
}

export default BrainTreePaymentGateway
