import React from "react";
import { Row, Col } from "react-grid-system";
import Panel from "../../ui/panel/Panel";
import Text from "../../ui/text/Text";
import Input from "../../ui/input/Input";
import FormLabel from "../../ui/form-label/FormLabel";
import counterpart from "counterpart";

interface IExemptionNote {
  onChangeNote: (value: string) => void;
  placeholder: string;
  note: string;
  title: string;
  content: string;
}

const ExemptionNote: React.FC<IExemptionNote> = React.memo(({ onChangeNote, note, title, content, placeholder }) => {
  return (
    <Panel title={title}>
      <Text content={content} />
        <Row align="center">
          <Col md={2}>
            <FormLabel target={title} content="EUROPEAN_UNION_EXEMPTION_LABEL" noMargin />
          </Col>
          <Col md={10}>
            <Input id={title} type="text" placeholder={counterpart(placeholder)} value={note} onChange={(evt: any) => onChangeNote(evt.target.value)} />
          </Col>
        </Row>
    </Panel>
  );
});

export default ExemptionNote;
