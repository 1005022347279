import React from "react";
import "./Spinner.scss";

interface ISpinnerProps {

}

const Spinner: React.FC<ISpinnerProps> = React.memo(() => {
  return (
    <div className="lds-roller">
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>
  )
})

export default Spinner;
