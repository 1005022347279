import React, { FormEvent, useEffect, useState } from "react";
import "./RegistrationStep1.scss";
import { useDispatch, useSelector } from "react-redux";
import {
  addFirstName,
  addLastName,
  addWorkEmail,
  addPhoneNumberPrefix,
  addPhoneNumber,
  addPassword,
  addPasswordConfirm,
  validateEmail,
  addPhoneNumberCountryIso2,
  checkPasswordValidity,
  resetRegistrationState,
  addFreeTrialToken
} from "../../../actions/registrationActions";
import Panel from "../../../components/ui/panel/Panel";
import Text from "../../../components/ui/text/Text";
import FormLabel from "../../../components/ui/form-label/FormLabel";
import FormGroup from "../../../components/ui/form-group/FormGroup";
import Input from "../../../components/ui/input/Input";
import { Row, Col } from "react-grid-system";
import Button from "../../../components/ui/button/Button";
import counterpart from "counterpart";
import PhoneInput from "../../../components/ui/phone-input/PhoneInput";
import Password from "../../../components/ui/password/Password";
import RegistrationSameEmailError from "../step1b/RegistrationSameEmailError";
import RegistrationStep2 from "../step2/RegistrationStep2";
import RegistrationSameDomainError from "../step1c/RegistrationSameDomainError";
import { AppState } from "../../..";
import { PREFERRED_PHONE_COUNTRIES, CustomeAnalyticsEvents } from "../../../utils/constants";
import queryString from "query-string";
import history from "../../../utils/history";
import TitleSection from "../../../components/ui/title-section/TitleSection";
import { isRegistrationFromInvitationQueryString, divideNumberBy100, gaEventTracker } from "../../../utils/commonUtils";
import { GetTrialLimitRequest, GetTrialLimitResponse } from "../../../utils/grpc/generated/Billsby.Protos/core/public/triallimits/triallimits_pb";
import { TrialLimitsServiceClient } from "../../../utils/grpc/generated/Billsby.Protos/core/public/triallimits/TriallimitsServiceClientPb";
import { grpcUnaryCall } from "../../../utils/grpc/grpcUtils";
import { ConfigConstants } from "../../../utils/config";
import ProgressIndicator from "../../../components/ui/progress-indicator/ProgressIndicator";
import { IRegistrationReducerState } from "../../../reducers/registrationReducer";
import RegistrationCheckboxes from "../RegistrationCheckboxes";
const { isValidNumber } = (global as any).intlTelInputUtils;

const RegistrationStep1: React.FC = () => {
  const registration = useSelector<AppState, IRegistrationReducerState>(state => state.registration);
  const currentCompanyGoogleAnalyticsUID = useSelector((state: AppState) => state.auth.currentCompanyGoogleAnalyticsUID);
  const dispatch = useDispatch<Function>();

  const [trialLimit, setTrialLimit] = useState("$50,000");
  const [isLoading, setIsLoading] = useState(false);
  const [showAgreementError, setShowAgreementError] = useState(false);

  const {
    workEmail,
    phoneNumberPrefix,
    phoneNumber,
    hasWorkEmailInvalidDomain,
    passwordIsValid,
    firstName,
    lastName,
    password,
    passwordConfirmation,
    acceptAgreement,
    isValidatingEmail,
    isValidatingFailure,
    isValidatingSuccess,
  } = registration;

  const onSubmit = (evt: FormEvent) => {
    evt.preventDefault();

    if (!acceptAgreement) {
      setShowAgreementError(true);
      return;
    }

    if (!isValidNumber(`+${phoneNumberPrefix}${phoneNumber}`) || !passwordIsValid) {
      return;
    }

    dispatch(validateEmail(workEmail));
  }

  useEffect(() => {
    const getTrialLimit = async () => {
      const trialLimitKey = queryString.parse(window.location.search).key as string;
      dispatch(addFreeTrialToken(trialLimitKey));
      setIsLoading(true);

      try {
        const getTrialLimitRequest = new GetTrialLimitRequest();
        const trialLimitServiceClient = new TrialLimitsServiceClient(ConfigConstants.grpcBaseUrl);
        getTrialLimitRequest.setKey(trialLimitKey || "");

        const trialLimitResponse = await grpcUnaryCall(getTrialLimitRequest, trialLimitServiceClient, trialLimitServiceClient.getTrialLimit) as GetTrialLimitResponse;

        setTrialLimit(`$${(divideNumberBy100(trialLimitResponse.getLimit()) as number).toLocaleString()}`);
        setIsLoading(false)


      } catch (err) {
        console.log(err)
      } finally {
        setIsLoading(false)
      }
    }

    if (isRegistrationFromInvitationQueryString()) {
      history.push({ pathname: "/invitation/registration", search: window.location.search });
    }
    getTrialLimit();
  }, []);

  useEffect(() => {
    currentCompanyGoogleAnalyticsUID && gaEventTracker(CustomeAnalyticsEvents.REGISTRATION_JOURNEY_STARTED);
  }, [currentCompanyGoogleAnalyticsUID])

  if (isValidatingSuccess === "") {
    return <RegistrationStep2 />;
  }

  if (isValidatingFailure && isValidatingFailure.status === 409) {
    //existing email
    return <RegistrationSameEmailError firstName={firstName} onContinue={() => dispatch(resetRegistrationState())} />;
  }

  if (isValidatingSuccess && isValidatingSuccess.errorCode) {
    return <RegistrationSameDomainError firstName={firstName} workEmail={workEmail} />;
  }

  if (isLoading) {
    return <ProgressIndicator color="blue" coverage="full-screen"></ProgressIndicator>
  }



  return (
    <form className="registration registration__step1" onSubmit={onSubmit}>
      {/*<Panel title="REGISTRATION_FEATURES_PANEL_TITLE">
      <Text content="REGISTRATION_FEATURES_PANEL_CONTENT" noMargin />
    </Panel>*/}

      <TitleSection
        title="REGISTRATION_START_FREE_TRIAL"
        translateTitleWith={{ trialLimit }}
        subTitle="REGISTRATION_START_NO_CREDIT_CARD_REQUIRED"
      />

      <Panel className="registration__panel">
        <FormGroup>
          <Row align="center">
            <Col md={2}>
              <FormLabel target="firstname" content="REGISTRATION_FORM_FULLNAME" noMargin />
            </Col>
            <Col md={5}>
              <Input
                required
                id="firstname"
                placeholder="James"
                value={firstName}
                minLength="2"
                onChange={evt => dispatch(addFirstName(evt.target.value))}
              />
            </Col>
            <Col md={5}>
              <Input
                required
                id="lastname"
                placeholder="Stevenson"
                value={lastName}
                minLength="2"
                onChange={evt => dispatch(addLastName(evt.target.value))}
              />
            </Col>
          </Row>
        </FormGroup>

        <FormGroup>
          <Row align="center">
            <Col md={2}>
              <FormLabel target="workemail" content="REGISTRATION_FORM_WORKEMAIL" noMargin />
            </Col>
            <Col md={10}>
              <Input
                id="workemail"
                required
                type="email"
                placeholder={counterpart("LOGIN_EMAIL_PLACEHOLDER")}
                value={workEmail}
                onChange={evt => dispatch(addWorkEmail(evt.target.value))}
                isError={hasWorkEmailInvalidDomain || isValidatingFailure}
                errorMsg={isValidatingFailure ? counterpart("REGISTRATION_FORM_WORKEMAIL_ERROR_BACKEND") : counterpart("REGISTRATION_FORM_WORKEMAIL_ERROR")}
                warninglayout={isValidatingFailure ? false : hasWorkEmailInvalidDomain}
              />
            </Col>
          </Row>
        </FormGroup>

        <FormGroup>
          <Row align="center">
            <Col md={2}>
              <FormLabel target="phonenumber" content="REGISTRATION_FORM_PHONENUMBER" noMargin />
            </Col>
            <Col md={10}>
              <PhoneInput
                preferredCountries={PREFERRED_PHONE_COUNTRIES.countries}
                defaultCountry={PREFERRED_PHONE_COUNTRIES.defaultCountry}
                value={phoneNumber}
                isRequired
                dialCode={phoneNumberPrefix}
                onPhoneNumberChange={(isValid: boolean, phone: string, countryData: any) => {
                  dispatch(addPhoneNumber(phone));
                  dispatch(addPhoneNumberPrefix(countryData.dialCode));
                  dispatch(addPhoneNumberCountryIso2(countryData.iso2));
                }}
                onSelectFlag={(phone: string, countryData: any) => {
                  dispatch(addPhoneNumberPrefix(countryData.dialCode));
                  dispatch(addPhoneNumberCountryIso2(countryData.iso2));
                }}
                errorLabel="REGISTRATION_PHONE_VALIDATION_ERROR"
                separateDialCode
              />
            </Col>
          </Row>
        </FormGroup>

        <Password
          password={password}
          passwordConfirmation={passwordConfirmation}
          onChangeConfirmPassword={(pswConfirmation: string) => dispatch(addPasswordConfirm(pswConfirmation))}
          onChangePassword={(psw: string) => dispatch(addPassword(psw))}
          passwordIsValidCallback={(passwordIsValid: boolean) => dispatch(checkPasswordValidity(passwordIsValid))}
        />

        <RegistrationCheckboxes showAgreementError={showAgreementError} acceptAgreementOnClick={() => setShowAgreementError(false)}/>

        {/*<Row>
        <Col md={12}>
          <Dropdown
            id="billingcurrency"
            menuIsOpen={true}
            dividerAfter={1}
            options={[{ value: "1", label: "pippo"}, { value: "2", label: "pluto"}, { value: "3", label: "paperino"}]}
          />
        </Col>
      </Row>*/}
      </Panel>

      {/*<Panel title="REGISTRATION_BETA_TITLE" className="registration__beta">
      <Text content="REGISTRATION_BETA_SUBTITLE1" className="registration__beta-subtitle" />
      <Text content="REGISTRATION_BETA_CONTENT1" />
      <Text content="REGISTRATION_BETA_CONTENT1_1" />
      <Text content="REGISTRATION_BETA_CONTENT1_2" />
      <Text content="REGISTRATION_BETA_SUBTITLE2" className="registration__beta-subtitle" />
      <Text content="REGISTRATION_BETA_CONTENT2" />
      <NoticePanel type="warning" content="REGISTRATION_BETA_CONTENT3" className="registration__beta-notice" icon="fal fa-tag"  />
    </Panel>*/}

      {/*<Panel title="REGISTRATION_INVITE_CODE_PANEL_TITLE" className="registration__invite-panel">
      <Row>
        <Col md={10}>
          <Text content="REGISTRATION_INVITE_CODE_PANEL_CONTENT1" component="span"></Text>
          <a href="https://www.billsby.com" target="_self">
            {counterpart('REGISTRATION_INVITE_CODE_PANEL_CONTENT_LINK')}
          </a>
          <Text content="REGISTRATION_INVITE_CODE_PANEL_CONTENT2" component="span"></Text>
        </Col>
      </Row>
      <div className="registration__invite-code">
        <Row align="center">
          <Col md={2}>
            <FormLabel target="invite-code" content="REGISTRATION_INVITE_CODE_LABEL"></FormLabel>
          </Col>
          <Col md={8}>
            <Input
              id="invite-code"
              required
              value={invitationCode}
              placeholder={counterpart('REGISTRATION_INVITE_CODE_PLACEHOLDER')}
              onChange={evt => dispatch(addInviteCode(evt.target.value))}
              isError={isValidatingFailure}
              errorMsg={counterpart('REGISTRATION_FORM_INVITE_CODE_ERROR_BACKEND')}
              warninglayout={isValidatingFailure ? false : hasWorkEmailInvalidDomain}
            ></Input>
          </Col>
        </Row>
      </div>
    </Panel>
    */}

      <Button
        id="register_btn"
        title="REGISTRATION_FORM_BUTTON"
        type="submit"
        buttonType="orangeBillsbyMarketing"
        isLoading={isValidatingEmail}
        disabled={!password ? false : !passwordIsValid}
        isFullWidth
      />

      <FormGroup style={{ textAlign: "center" }}>
        <a href="https://www.billsby.com/terms" target="_self">
          {counterpart("REGISTRATION_FORM_TERMS_LINK1")}
        </a>
        <Text content="REGISTRATION_FORM_TERMS_AND" component="span" />
        <a href="https://www.billsby.com/privacy" target="_self">
          {counterpart("REGISTRATION_FORM_TERMS_LINK2")}
        </a>
      </FormGroup>

      <Panel className="registration__sample-review">
        <img src="https://assets.capterra.com/badge/69416cf7dc3929c9af9378c72177cf01.png?v=2134781&p=192675" alt="no img" />
        <Text content="REGISTRATION_SAMPLE_REVIEW" noMargin />
      </Panel>

      {/*<FormGroup className="registration__faq">
      <Text content="REGISTRATION_COMMON_QUERSTIONS" className="registration__questions__heading" />
      <Text content="REGISTRATION_QUESTION1_TITLE" className="registration__questions__title" />
      <Text content="REGISTRATION_QUESTION1_CONTENT1" />
      <Text content="REGISTRATION_QUESTION1_CONTENT2" />

      <Text content="REGISTRATION_QUESTION2_TITLE" className="registration__questions__title" />
      <Text content="REGISTRATION_QUESTION2_CONTENT" />

      <Text content="REGISTRATION_QUESTION3_TITLE" className="registration__questions__title" />
      <Text content="REGISTRATION_QUESTION3_CONTENT" />
      </FormGroup>*/}
    </form>
  )
}

export default RegistrationStep1;
