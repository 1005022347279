import React from "react";
import { IInvoiceDetails } from "../../models/Customer";
import { StandAloneInvoiceType, InvoiceStatus } from "../../models/Invoices";
import Panel from "../ui/panel/Panel";
import Text from "../ui/text/Text";
import { CreditNoteStatus } from "../../models/CreditNotes";

interface IInvoiceStandaloneSidebarOutputPanel {
  invoice?: IInvoiceDetails,
  locale?: string
}

const InvoiceStandaloneSidebarOutputPanel: React.FC<IInvoiceStandaloneSidebarOutputPanel> = ({ invoice, locale }) => {
  if (!invoice) {
    return null;
  }

  const getInvoiceOutputPanel = () => {
    if ([InvoiceStatus.UNPAID, InvoiceStatus.FAILED, InvoiceStatus.WRITTEN_OFF, InvoiceStatus.TOBEWRITTEN_OFF].includes(invoice.status)) {
      return (
        <Panel className="invoice-standalone-sidebar-unpaid">
          <Text locale={locale} content="INVOICE_STANDALONE_UNPAID_TITLE" translateWith={{ amount: invoice.formattedAmount }} />
          <Text locale={locale} content="INVOICE_STANDALONE_UNPAID_SUBTITLE" noMargin />
        </Panel>
      )
    }

    if ([InvoiceStatus.PAID, InvoiceStatus.PAID_MANUALLY, InvoiceStatus.PAID_OFFLINE].includes(invoice.status)) {
      return (
        <Panel className="invoice-standalone-sidebar-paid">
          <Text locale={locale} content="INVOICE_STANDALONE_PAID_TITLE" translateWith={{ amount: invoice.formattedAmount }} />
          <Text locale={locale} content="INVOICE_STANDALONE_PAID_SUBTITLE" noMargin />
        </Panel>
      )
    }

    if (invoice.status === InvoiceStatus.PENDING) {
      return (
        <Panel className="invoice-standalone-sidebar-pending">
          <Text locale={locale} content="INVOICE_STANDALONE_PENDING_TITLE" />
          <Text locale={locale} content="INVOICE_STANDALONE_PENDING_SUBTITLE" noMargin />
        </Panel>
      )
    }
    return null;
  }

  const getCreditNoteOutputPanel = () => {
    const creditNotestStatus: CreditNoteStatus = invoice.status as any;
    if ([CreditNoteStatus.UNPAID, CreditNoteStatus.FAILED, CreditNoteStatus.WRITTEN_OFF].includes(creditNotestStatus)) {
      return (
        <Panel className="invoice-standalone-sidebar-unpaid">
          <Text locale={locale} content="INVOICE_STANDALONE_CREDITNOTE_UNPAID_TITLE" />
          <Text locale={locale} content="INVOICE_STANDALONE_CREDITNOTE_UNPAID_SUBTITLE" translateWith={{ cardType: invoice.cardType, lastDigits: invoice.cardLast4Digits || invoice.cardType }} noMargin />
        </Panel>
      )
    }
    if (creditNotestStatus === CreditNoteStatus.PAID) {
      return (
        <Panel className="invoice-standalone-sidebar-paid">
          <Text locale={locale} content="INVOICE_STANDALONE_CREDITNOTE_PAID_TITLE" translateWith={{ amount: invoice.formattedAmount }} />
          <Text locale={locale} content="INVOICE_STANDALONE_CREDITNOTE_PAID_SUBTITLE" translateWith={{ cardType: invoice.cardType, lastDigits: invoice.cardLast4Digits || invoice.cardType }} noMargin />
        </Panel>
      )
    }
    if (creditNotestStatus === CreditNoteStatus.PENDING) {
      return (
        <Panel className="invoice-standalone-sidebar-pending">
          <Text locale={locale} content="INVOICE_STANDALONE_CREDITNOTE_PENDING_TITLE" />
          <Text locale={locale} content="INVOICE_STANDALONE_CREDITNOTE_PENDING_SUBTITLE" noMargin />
        </Panel>
      )
    }
    return null;
  }




  if ([StandAloneInvoiceType.ONE_TIME_CREDIT, StandAloneInvoiceType.REFUND].includes(invoice.invoiceType as StandAloneInvoiceType)) {
    // it's a credit note
    return getCreditNoteOutputPanel();
  }

  // it's an invoice
  return getInvoiceOutputPanel();
}

export default InvoiceStandaloneSidebarOutputPanel;