import React from "react";
import Text from "../text/Text";
import Button from "../button/Button";
import "./NavigationAlert.scss";

interface INavigationAlert {
  title: string;
  subTitle: string;
  className?: string;
  type?: "success" | "warning" | "error" | "default" | "info";
  shouldTranslateTitle?: boolean;
  translateTitleWith?: object;
  translateSubTitleWith?: object;
  shouldTranslateSubTitle?: boolean;
  buttonLabel?: string;
  shouldTranslateButtonLabel?: boolean;
  buttonDisabled?: boolean;
  icon?: string;
  onClick?: () => void;
  isVisible?: boolean;
  hasButton?: boolean;
}

const NavigationAlert: React.FC<INavigationAlert> = React.memo(
  ({
    title,
    subTitle,
    className = "",
    type = "warning",
    shouldTranslateTitle = true,
    shouldTranslateSubTitle = true,
    buttonLabel = "",
    shouldTranslateButtonLabel = true,
    buttonDisabled = false,
    icon = "fas fa-rocket",
    onClick,
    translateTitleWith = {},
    translateSubTitleWith = {},
    isVisible = true,
    hasButton = true
  }) => {
    if (!isVisible) {
      return null;
    }

    return (
      <div className={`navigation-alert navigation-alert--${type} ${className}`}>
        <div className="navigation-alert__wrapper">
          <div>
            <Text content={title} translateWith={translateTitleWith} shouldTranslate={shouldTranslateTitle} className="navigation-alert__title" noMargin />
            <Text content={subTitle} translateWith={translateSubTitleWith} shouldTranslate={shouldTranslateSubTitle} className="navigation-alert__subTitle" noMargin />
          </div>
          {hasButton && (
            <Button
              id="navigation-alert-btn"
              className={`navigation-alert__btn navigation-alert__btn--${type}`}
              title={buttonLabel}
              icon={icon}
              onClick={onClick}
              disabled={buttonDisabled}
              buttonType="add"
              shouldTranslate={shouldTranslateButtonLabel}
            />
          )}
        </div>
      </div>
    );
  }
);

export default NavigationAlert;
