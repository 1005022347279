import React from "react";
import FormGroup from "../../ui/form-group/FormGroup";
import { Row, Col } from "react-grid-system";
import FormLabel from "../../ui/form-label/FormLabel";
import { setIsProductImageEnabled, setProductError, setProductHelpContent, setProductHelpTitle, uploadProductImage } from "../../../actions/productPlanCycleActions";
import counterpart from "counterpart";
import { IProductReducerState } from "../../../reducers/productsReducer";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../../..";
import Switch from "../../ui/switch/Switch";
import FileUpload from "../../ui/file-upload/FileUpload";
import { validProductImageSize } from "../../../utils/commonUtils";
import { IAuthReducerState } from "../../../reducers/authReducer";


const ProductImageUploader: React.FC = () => {

  const { currentCompanyDomain } = useSelector<AppState, IAuthReducerState>(state => state.auth)
  const { productImageUrl, isProductImageEnabled, isProductImageUploading } = useSelector<AppState, IProductReducerState>(state => state.products);
  const dispatch = useDispatch<Function>()

  const onDrop = (files: Array<File>) => {
    dispatch(setProductHelpTitle("CREATE_PRODUCT_HELP_PRODUCT_IMAGE_TITLE"));
    dispatch(setProductHelpContent(["CREATE_PRODUCT_HELP_PRODUCT_IMAGE_1", "CREATE_PRODUCT_HELP_PRODUCT_IMAGE_2"]));
    const image = new Image();
    image.onload = async () => {
      if (validProductImageSize(image)) {
        dispatch(uploadProductImage(currentCompanyDomain, files));
      } else {
        dispatch(setProductError(counterpart("PRODUCT_IMAGE_ERROR")));
      }
    }

    image.src = URL.createObjectURL(files[0])
  }

  return (
    <>
      <FormGroup>
        <Row align="center">
          <Col md={2}>
            <FormLabel target="product-image-switch" content="CREATE_PRODUCT_PRODUCT_IMAGE" noMargin />
          </Col>
          <Col md={10}>
            <Switch
              className="create-product__basic-form__switch"
              id="product-image-switch"
              checked={isProductImageEnabled}
              onChange={() => {
                dispatch(setIsProductImageEnabled(!isProductImageEnabled));
                dispatch(setProductHelpTitle("CREATE_PRODUCT_HELP_PRODUCT_IMAGE_TITLE"));
                dispatch(setProductHelpContent(["CREATE_PRODUCT_HELP_PRODUCT_IMAGE_1", "CREATE_PRODUCT_HELP_PRODUCT_IMAGE_2"]));
              }} />
          </Col>
        </Row>
      </FormGroup>
      {isProductImageEnabled &&
        <FormGroup>
          <Row align="center">
            <Col md={12}>
              <div className="create-product__file-uploader">
                <FileUpload title={<>
                  {counterpart("CREATE_PRODUCT_IMAGE_UPLOADER_TEXT")} <a className="create-product__file-uploader__browse">{counterpart("FILE_UPLOADER_BROWSE")}</a>
                </>}
                  subTitle={"CREATE_PRODUCT_IMAGE_UPLOADER_SUB_TEXT"}
                  shouldTranslateTitle={false}
                  image={productImageUrl}
                  onDrop={onDrop}
                  imageAsBackground={true}
                  isLoading={isProductImageUploading}
                />
              </div>
            </Col>
          </Row>
        </FormGroup>
      }
    </>
  )
}

export default ProductImageUploader