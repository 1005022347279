import API from "../utils/API";
import { makeActionCreator, makeGenericActionCreator } from "../utils/reduxUtils";
import { CustomerServiceClient } from "../utils/grpc/generated/Billsby.Protos/billing/private/customer/CustomerServiceClientPb";
// import { GetCustomerCardDetailsRequest } from '../utils/grpc/generated/customer_private.proto/customer_private_pb';
import { UpdateCustomerLanguageRequest, GetCustomerPaymentDetailsRequest, UpdateCustomerPaymentDetailsScaRequest, GetViesVatValidationsRequest, AddCustomerNoteRequest, UpdateCustomerNoteRequest, DeleteCustomerNoteRequest, GetCustomerNotesRequest, GetCustomerLanguageRequest } from "../utils/grpc/generated/Billsby.Protos/billing/private/customer/customer_pb"
import { grpcUnaryCall } from "../utils/grpc/grpcUtils";
import { ICustomerDetailsReducerState } from "../reducers/customerDetailsReducer";
import { ConfigConstants } from "../utils/config";
import { GetSpreedlyTransactionRequest } from "../utils/grpc/generated/Billsby.Protos/billing/private/paymentgateway/payment_gateway_pb";
import { PaymentGatewayServiceClient } from "../utils/grpc/generated/Billsby.Protos/billing/private/paymentgateway/Payment_gatewayServiceClientPb";

export const FETCH_CUSTOMER_DETAILS_REQUEST = "FETCH_CUSTOMER_DETAILS_REQUEST";
export const FETCH_CUSTOMER_DETAILS_SUCCESS = "FETCH_CUSTOMER_DETAILS_SUCCESS";
export const FETCH_CUSTOMER_DETAILS_FAILURE = "FETCH_CUSTOMER_DETAILS_FAILURE";

export const FETCH_CUSTOMER_CARD_DETAILS_REQUEST = "FETCH_CUSTOMER_CARD_DETAILS_REQUEST";
export const FETCH_CUSTOMER_CARD_DETAILS_SUCCESS = "FETCH_CUSTOMER_CARD_DETAILS_SUCCESS";
export const FETCH_CUSTOMER_CARD_DETAILS_FAILURE = "FETCH_CUSTOMER_CARD_DETAILS_FAILURE";

export const FETCH_CUSTOMER_TAX_VALIDATIONS_REQUEST = "FETCH_CUSTOMER_TAX_VALIDATIONS_REQUEST";
export const FETCH_CUSTOMER_TAX_VALIDATIONS_SUCCESS = "FETCH_CUSTOMER_TAX_VALIDATIONS_SUCCESS";
export const FETCH_CUSTOMER_TAX_VALIDATIONS_FAILURE = "FETCH_CUSTOMER_TAX_VALIDATIONS_FAILURE";

export const FETCH_SPREEDLY_PAYMENT_LOG_REQUEST = "FETCH_SPREEDLY_PAYMENT_LOG_REQUEST";
export const FETCH_SPREEDLY_PAYMENT_LOG_SUCCESS = "FETCH_SPREEDLY_PAYMENT_LOG_SUCCESS";
export const FETCH_SPREEDLY_PAYMENT_LOG_FAILURE = "FETCH_SPREEDLY_PAYMENT_LOG_FAILURE";

export const FETCH_CUSTOMER_CREDIT_NOTES_REQUEST =
  "FETCH_CUSTOMER_CREDIT_NOTES_REQUEST";
export const FETCH_CUSTOMER_CREDIT_NOTES_SUCCESS =
  "FETCH_CUSTOMER_CREDIT_NOTES_SUCCESS";
export const FETCH_CUSTOMER_CREDIT_NOTES_FAILURE =
  "FETCH_CUSTOMER_CREDIT_NOTES_FAILURE";
export const RESET_CUSTOMER_CREDIT_NOTES =
  "RESET_CUSTOMER_CREDIT_NOTES";

export const FETCH_CUSTOMER_INVOICES_REQUEST =
  "FETCH_CUSTOMER_INVOICES_REQUEST";
export const FETCH_CUSTOMER_INVOICES_SUCCESS =
  "FETCH_CUSTOMER_INVOICES_SUCCESS";
export const FETCH_CUSTOMER_INVOICES_FAILURE =
  "FETCH_CUSTOMER_INVOICES_FAILURE";
export const RESET_CUSTOMER_INVOICES =
  "RESET_CUSTOMER_INVOICES";

export const FETCH_CUSTOMER_INVOICES_PAYMENTS_REQUEST =
  "FETCH_CUSTOMER_INVOICES_PAYMENTS_REQUEST";
export const FETCH_CUSTOMER_INVOICES_PAYMENTS_SUCCESS =
  "FETCH_CUSTOMER_INVOICES_PAYMENTS_SUCCESS";
export const FETCH_CUSTOMER_INVOICES_PAYMENTS_FAILURE =
  "FETCH_CUSTOMER_INVOICES_PAYMENTS_FAILURE";

export const UPDATE_CUSTOMER_CARD_DETAILS_REQUEST = "UPDATE_CUSTOMER_CARD_DETAILS_REQUEST";
export const UPDATE_CUSTOMER_CARD_DETAILS_SUCCESS = "UPDATE_CUSTOMER_CARD_DETAILS_SUCCESS";
export const UPDATE_CUSTOMER_CARD_DETAILS_FAILURE = "UPDATE_CUSTOMER_CARD_DETAILS_FAILURE";

export const CREATE_CUSTOMER_NOTE_REQUEST = "CREATE_CUSTOMER_NOTE_REQUEST";
export const CREATE_CUSTOMER_NOTE_SUCCESS = "CREATE_CUSTOMER_NOTE_SUCCESS";
export const CREATE_CUSTOMER_NOTE_FAILURE = "CREATE_CUSTOMER_NOTE_FAILURE";

export const UPDATE_CUSTOMER_NOTE_REQUEST = "UPDATE_CUSTOMER_NOTE_REQUEST";
export const UPDATE_CUSTOMER_NOTE_SUCCESS = "UPDATE_CUSTOMER_NOTE_SUCCESS";
export const UPDATE_CUSTOMER_NOTE_FAILURE = "UPDATE_CUSTOMER_NOTE_FAILURE";

export const DELETE_CUSTOMER_NOTE_REQUEST = "DELETE_CUSTOMER_NOTE_REQUEST";
export const DELETE_CUSTOMER_NOTE_SUCCESS = "DELETE_CUSTOMER_NOTE_SUCCESS";
export const DELETE_CUSTOMER_NOTE_FAILURE = "DELETE_CUSTOMER_NOTE_FAILURE";

export const FETCH_CUSTOMER_NOTE_REQUEST = "FETCH_CUSTOMER_NOTE_REQUEST";
export const FETCH_CUSTOMER_NOTE_SUCCESS = "FETCH_CUSTOMER_NOTE_SUCCESS";
export const FETCH_CUSTOMER_NOTE_FAILURE = "FETCH_CUSTOMER_NOTE_FAILURE";

export const CHANGE_CUSTOMER_LANGUAGE_REQUEST = "CHANGE_CUSTOMER_LANGUAGE_REQUEST";
export const CHANGE_CUSTOMER_LANGUAGE_SUCCESS = "CHANGE_CUSTOMER_LANGUAGE_SUCCESS";
export const CHANGE_CUSTOMER_LANGUAGE_FAILURE = "CHANGE_CUSTOMER_LANGUAGE_FAILURE";

export const FETCH_CUSTOMER_LANGUAGES_REQUEST = "FETCH_CUSTOMER_LANGUAGES_REQUEST";
export const FETCH_CUSTOMER_LANGUAGES_SUCCESS = "FETCH_CUSTOMER_LANGUAGES_SUCCESS";
export const FETCH_CUSTOMER_LANGUAGES_FAILURE = "FETCH_CUSTOMER_LANGUAGES_FAILURE";

export const RESET_CUSTOMER_DETAILS = "RESET_CUSTOMER_DETAILS";
export const SET_CUSTOMER_DETAILS_FIELD = "SET_CUSTOMER_DETAILS_FIELD";
export const resetCustomerDetails = makeActionCreator(RESET_CUSTOMER_DETAILS);
export const resetCustomerDetailsInvoices = makeActionCreator(RESET_CUSTOMER_INVOICES);
export const resetCustomerDetailsCreditNotes = makeActionCreator(RESET_CUSTOMER_CREDIT_NOTES);
export const setCustomerDetailsField = makeGenericActionCreator<keyof ICustomerDetailsReducerState>("SET_CUSTOMER_DETAILS_FIELD")

export const RESET_SPREEDLY_PAYMENT_LOG = "RESET_SPREEDLY_PAYMENT_LOG";
export const resetSpreedlyPaymentLog = makeActionCreator(RESET_SPREEDLY_PAYMENT_LOG);

export const fetchCustomerDetails = function (
  companyDomain: string,
  customerUniqueId: string
) {
  return {
    types: [
      FETCH_CUSTOMER_DETAILS_REQUEST,
      FETCH_CUSTOMER_DETAILS_SUCCESS,
      FETCH_CUSTOMER_DETAILS_FAILURE
    ],
    callAPI: () => API.getCustomerDetails(companyDomain, customerUniqueId)
  };
};


export const fetchCustomerCreditNotes = function (
  companyDomain: string,
  customerUniqueId: string
) {
  return {
    types: [
      FETCH_CUSTOMER_CREDIT_NOTES_REQUEST,
      FETCH_CUSTOMER_CREDIT_NOTES_SUCCESS,
      FETCH_CUSTOMER_CREDIT_NOTES_FAILURE
    ],
    callAPI: () => API.fetchCustomerCreditNotes(companyDomain, customerUniqueId)
  };
};

export const fetchCustomerInvoices = function (
  companyDomain: string,
  customerUniqueId: string
) {
  return {
    types: [
      FETCH_CUSTOMER_INVOICES_REQUEST,
      FETCH_CUSTOMER_INVOICES_SUCCESS,
      FETCH_CUSTOMER_INVOICES_FAILURE
    ],
    callAPI: () => API.fetchCustomerInvoices(companyDomain, customerUniqueId)
  };
};

export const fetchCustomerInvoicesPayments = function (
  companyDomain: string,
  customerUniqueId: string,
  invoiceNumber: string
) {
  return {
    type: [
      FETCH_CUSTOMER_INVOICES_PAYMENTS_REQUEST,
      FETCH_CUSTOMER_INVOICES_PAYMENTS_SUCCESS,
      FETCH_CUSTOMER_INVOICES_PAYMENTS_FAILURE
    ],
    callAPI: () =>
      API.fetchCustomerInvoicesPayments(companyDomain, customerUniqueId, invoiceNumber)
  };
};

export const fetchCustomerCardDetails = function (
  companyId: number,
  customerUniqueId: string
) {
  const getCustomerCardDetailsRequest = new GetCustomerPaymentDetailsRequest();
  getCustomerCardDetailsRequest.setCompanyId(companyId);
  getCustomerCardDetailsRequest.setCustomerUniqueId(customerUniqueId);
  const customerServiceClient = new CustomerServiceClient(ConfigConstants.grpcBaseUrl);
  return {
    types: [
      FETCH_CUSTOMER_CARD_DETAILS_REQUEST,
      FETCH_CUSTOMER_CARD_DETAILS_SUCCESS,
      FETCH_CUSTOMER_CARD_DETAILS_FAILURE
    ],
    callAPI: () => grpcUnaryCall(getCustomerCardDetailsRequest, customerServiceClient, customerServiceClient.getCustomerPaymentDetails)
  };
};


export const updateCustomerCardDetails = function (
  customerUniqueId: string,
  companyId: number,
  cardType: string,
  cardHoldersName: string,
  cardLastFourDigits: string,
  expiryMonth: number,
  expiryYear: number,
  paymentCardToken: string) {

  const updateCustomerCardDetailsScaRequest = new UpdateCustomerPaymentDetailsScaRequest();
  updateCustomerCardDetailsScaRequest.setCustomerUniqueId(customerUniqueId);
  updateCustomerCardDetailsScaRequest.setCompanyId(+companyId);
  updateCustomerCardDetailsScaRequest.setCardType(cardType);
  updateCustomerCardDetailsScaRequest.setCardLastFourDigits(cardLastFourDigits);
  updateCustomerCardDetailsScaRequest.setExpiryMonth(+expiryMonth);
  updateCustomerCardDetailsScaRequest.setExpiryYear(+expiryYear);
  updateCustomerCardDetailsScaRequest.setPaymentCardToken(paymentCardToken);
  updateCustomerCardDetailsScaRequest.setFullName(cardHoldersName);
  const customerServiceClient = new CustomerServiceClient(ConfigConstants.grpcBaseUrl);
  return {
    types: [UPDATE_CUSTOMER_CARD_DETAILS_REQUEST, UPDATE_CUSTOMER_CARD_DETAILS_SUCCESS, UPDATE_CUSTOMER_CARD_DETAILS_FAILURE],
    callAPI: () => grpcUnaryCall(updateCustomerCardDetailsScaRequest, customerServiceClient, customerServiceClient.updateCustomerPaymentDetailsSca)
  }
}

export const fetchCustomerTaxValidations = function (companyDomain: string, customerUniqueId: string) {
  const getTaxValidations = new GetViesVatValidationsRequest();
  getTaxValidations.setCompanyDomain(companyDomain);
  getTaxValidations.setCustomerUniqueId(customerUniqueId);

  const customerServiceClient = new CustomerServiceClient(ConfigConstants.grpcBaseUrl);
  return {
    types: [FETCH_CUSTOMER_TAX_VALIDATIONS_REQUEST, FETCH_CUSTOMER_TAX_VALIDATIONS_SUCCESS, FETCH_CUSTOMER_TAX_VALIDATIONS_FAILURE],
    callAPI: () => grpcUnaryCall(getTaxValidations, customerServiceClient, customerServiceClient.getViesVatValidations)
  }
}

export const getSpreedlyTransaction = function (companyDomain: string, refrenceNumber: string) {
  const getSpreedlyTransactionRequest = new GetSpreedlyTransactionRequest();
  getSpreedlyTransactionRequest.setCompanyDomain(companyDomain);
  getSpreedlyTransactionRequest.setTransactionToken(refrenceNumber)
  const paymentGatewayServiceClient = new PaymentGatewayServiceClient(ConfigConstants.grpcBaseUrl);
  return {
    types: [FETCH_SPREEDLY_PAYMENT_LOG_REQUEST, FETCH_SPREEDLY_PAYMENT_LOG_SUCCESS, FETCH_SPREEDLY_PAYMENT_LOG_FAILURE],
    callAPI: () => grpcUnaryCall(getSpreedlyTransactionRequest, paymentGatewayServiceClient, paymentGatewayServiceClient.getSpreedlyTransaction)
  }
}

export const createCustomerNote = function (companyId: number, customerUniqueId: string, subject: string, content: string) {
  const addCustomerNoteRequest = new AddCustomerNoteRequest();
  addCustomerNoteRequest.setCompanyId(companyId);
  addCustomerNoteRequest.setCustomerUniqueId(customerUniqueId);
  addCustomerNoteRequest.setSubjectLine(subject);
  addCustomerNoteRequest.setContent(content);
  const customerServiceClient = new CustomerServiceClient(ConfigConstants.grpcBaseUrl);
  return {
    types: [CREATE_CUSTOMER_NOTE_REQUEST, CREATE_CUSTOMER_NOTE_SUCCESS, CREATE_CUSTOMER_NOTE_FAILURE],
    callAPI: () => grpcUnaryCall(addCustomerNoteRequest, customerServiceClient, customerServiceClient.addCustomerNote)
  }
}

export const updateCustomerNote = function (companyId: number, customerUniqueId: string, customerNoteId: number, subject: string, content: string) {
  const updateCustomerNoteRequest = new UpdateCustomerNoteRequest();
  updateCustomerNoteRequest.setCompanyId(companyId);
  updateCustomerNoteRequest.setCustomerUniqueId(customerUniqueId);
  updateCustomerNoteRequest.setCustomerNoteId(customerNoteId);
  updateCustomerNoteRequest.setSubjectLine(subject);
  updateCustomerNoteRequest.setContent(content);
  const customerServiceClient = new CustomerServiceClient(ConfigConstants.grpcBaseUrl);
  return {
    types: [UPDATE_CUSTOMER_NOTE_REQUEST, UPDATE_CUSTOMER_NOTE_SUCCESS, UPDATE_CUSTOMER_NOTE_FAILURE],
    callAPI: () => grpcUnaryCall(updateCustomerNoteRequest, customerServiceClient, customerServiceClient.updateCustomerNote)
  }
}

export const deleteCustomerNote = function (companyId: number, customerUniqueId: string, customerNoteId: number) {
  const deleteCustomerNoteRequest = new DeleteCustomerNoteRequest();
  deleteCustomerNoteRequest.setCompanyId(companyId);
  deleteCustomerNoteRequest.setCustomerUniqueId(customerUniqueId);
  deleteCustomerNoteRequest.setCustomerNoteId(customerNoteId);
  const customerServiceClient = new CustomerServiceClient(ConfigConstants.grpcBaseUrl);
  return {
    types: [DELETE_CUSTOMER_NOTE_REQUEST, DELETE_CUSTOMER_NOTE_SUCCESS, DELETE_CUSTOMER_NOTE_FAILURE],
    callAPI: () => grpcUnaryCall(deleteCustomerNoteRequest, customerServiceClient, customerServiceClient.deleteCustomerNote)
  }
}

export const fetchCustomerNotes = function (companyId: number, customerUniqueId: string, page: number, pageSize: number, searchKey: string) {
  const getCustomerNotesRequest = new GetCustomerNotesRequest();
  getCustomerNotesRequest.setCompanyId(companyId);
  getCustomerNotesRequest.setCustomerUniqueId(customerUniqueId);
  getCustomerNotesRequest.setSearch(searchKey);
  getCustomerNotesRequest.setPage(page);
  getCustomerNotesRequest.setPageSize(pageSize);
  const customerServiceClient = new CustomerServiceClient(ConfigConstants.grpcBaseUrl);
  return {
    types: [FETCH_CUSTOMER_NOTE_REQUEST, FETCH_CUSTOMER_NOTE_SUCCESS, FETCH_CUSTOMER_NOTE_FAILURE],
    callAPI: () => grpcUnaryCall(getCustomerNotesRequest, customerServiceClient, customerServiceClient.getCustomerNotes)
  }
}

export const updateCustomerLanguage = (companyId: number, customerUniqueId: string, isoCode: string) => {
  const updateCustomerLanguageRequest = new UpdateCustomerLanguageRequest();
  updateCustomerLanguageRequest.setCompanyId(companyId);
  updateCustomerLanguageRequest.setCustomerUniqueId(customerUniqueId);
  updateCustomerLanguageRequest.setLanguageIsoCode(isoCode);
  const customerServiceClient = new CustomerServiceClient(ConfigConstants.grpcBaseUrl);
  return {
    types: [CHANGE_CUSTOMER_LANGUAGE_REQUEST, CHANGE_CUSTOMER_LANGUAGE_SUCCESS, CHANGE_CUSTOMER_LANGUAGE_FAILURE],
    callAPI: () => grpcUnaryCall(updateCustomerLanguageRequest, customerServiceClient, customerServiceClient.updateCustomerLanguage)
  }
}

export const fetchCustomerLanguages = function (companyDomain: string, customerUniqueId: string) {
  const getCustomerLanguageRequest = new GetCustomerLanguageRequest();
  getCustomerLanguageRequest.setCompanyDomain(companyDomain);
  getCustomerLanguageRequest.setCustomerUniqueId(customerUniqueId);
  const customerServiceClient = new CustomerServiceClient(ConfigConstants.grpcBaseUrl);
  return {
    types: [FETCH_CUSTOMER_LANGUAGES_REQUEST, FETCH_CUSTOMER_LANGUAGES_SUCCESS, FETCH_CUSTOMER_LANGUAGES_FAILURE],
    callAPI: () => grpcUnaryCall(getCustomerLanguageRequest, customerServiceClient, customerServiceClient.getCustomerLanguage)
  }
}