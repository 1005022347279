import React, { useEffect } from "react";
import history from "../../../../../utils/history";
import Panel from "../../../../ui/panel/Panel";
import Text from "../../../../ui/text/Text";
import "./CouponAppliedEmailTemplate.scss";
import { AppState } from "../../../../..";
import { useDispatch, useSelector } from "react-redux";
import { IAuthReducerState } from "../../../../../reducers/authReducer";
import { ICompanyBrandingReducerState } from "../../../../../reducers/companyBrandingReducer";
import { Row, Col } from "react-grid-system";
import EmailPreivewContainer from "../../brand-customization/email-preview/EmailPreivewContainer";
import BackNavigationPanel from "../../../../ui/back-navigation-panel/BackNavigationPanel";
import { IEmailCustomizationReducerState } from "../../../../../reducers/emailCustomizationReducer";
import { resetEmailTemplateValues, getEmailTemplateValues } from "../../../../../actions/emailCustomizationActions";
import { fetchCompanyBranding } from "../../../../../actions/companyBrandingActions";
import EmailFooter from "../email-footer/EmailFooter";
import EmailEditableArea from "../email-editable-area/EmailEditableArea";
import { EmailTypes } from "../../../../../utils/grpc/generated/Billsby.Protos/Messenger/private/emailconfig/emailconfig_pb";

const CouponAppliedEmailTemplate: React.FC = () => {
  const dispatch = useDispatch();
  const companyBrandingReducer = useSelector<AppState, ICompanyBrandingReducerState>(state => state.companyBrandingReducer);
  const emailCustomizationReducer = useSelector<AppState, IEmailCustomizationReducerState>(state => state.emailCustomizationReducer);
  const authReducer = useSelector<AppState, IAuthReducerState>(state => state.auth);

  const { customArea1, customArea2, customArea3 } = emailCustomizationReducer;
  const { currentCompanyId, companyName, currentCompanyDomain } = authReducer;

  useEffect(() => {
    dispatch(fetchCompanyBranding(currentCompanyDomain));
    dispatch(getEmailTemplateValues(currentCompanyId as number, companyName, EmailTypes.TOKEN_APPLIED));
    return () => { dispatch(resetEmailTemplateValues()) };
  }, []);

  return (
    <div>
      <BackNavigationPanel
        title={"DISCOUNTS_AND_COUPONS_TEMPLATE_MENU_COUPON_IS_APPLIED"} shouldTranslateTitle={true}
        onClick={() => history.push("/configuration/email-notifications/emails/discount-coupons")} />

      <Row>
        <Col sm={8}>
          <Panel className="coupon-applied-email-template__panel" noMargin>
            <EmailPreivewContainer>

              <Text
                content={<span dangerouslySetInnerHTML={{__html: customArea1}}></span>}
                shouldTranslate={false}
                className="email-preview__details__text email-preview__details__text-title bar bar-blue"
                component="span"
                noMargin
              />

              <Text
                content="EMAIL_TEMPLATE_PAYMENT_DETAILS_REQUEST_GREETINGS"
                className="email-preview__details__text"
                component="span"
                noMargin
              />

              <Text
                content={<span dangerouslySetInnerHTML={{__html: customArea2}}></span>}
                shouldTranslate={false}
                className="email-preview__details__text bar bar-orange"
                component="span"
                noMargin
              />

              <Text
                content="DISCOUNTS_AND_COUPONS_COUPON_IS_APPLIED_TEMPLATE_BODY_1"
                shouldTranslate={true}
                className="email-preview__details__text email-preview__details__text-bold"
                component="span"
                noMargin
              />

              <Text
                content="DISCOUNTS_AND_COUPONS_COUPON_IS_APPLIED_TEMPLATE_BODY_2"
                shouldTranslate={true}
                className="email-preview__details__text"
                component="span"
                noMargin
              />

              <Text
                content={<span dangerouslySetInnerHTML={{__html: customArea3}}></span>}
                shouldTranslate={false}
                className="email-preview__details__text bar bar-green"
                component="span"
                noMargin
              />

              <Text
                content="EMAIL_TEMPLATE_PAYMENT_DETAILS_REQUEST_REAGRDS"
                className="email-preview__details__text"
                component="span"
                noMargin
              />

              <Text
                content={companyName}
                className="email-preview__details__text"
                component="span"
                shouldTranslate={false}
                noMargin
              />
              <EmailFooter />
            </EmailPreivewContainer>
          </Panel>
        </Col>
        <Col sm={4}>          
        <EmailEditableArea
          id="CouponAppliedEmailTemplate"
          emailCustomCopyOneValue={customArea1}
          emailCustomCopyTwoValue={customArea2}
          emailCustomCopyThreeValue={customArea3}
          showCompanyAlertToggle={true}
          showProductAlertToggle={true}
          showPlanAlertToggle={true}
          />
        </Col>
      </Row>
    </div>
  )
}

export default CouponAppliedEmailTemplate;