import React, { FormEvent, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Row, Col } from "react-grid-system";
import Panel from "../../../ui/panel/Panel";
import Text from "../../../ui/text/Text";
import Button from "../../../ui/button/Button";
import FormLabel from "../../../../components/ui/form-label/FormLabel";
import FormGroup from "../../../../components/ui/form-group/FormGroup";
import Checkbox from "../../../../components/ui/checkbox/Checkbox";
import Input from "../../../../components/ui/input/Input";
import Dropdown from "../../../../components/ui/dropdown/Dropdown";
import Switch from "../../../../components/ui/switch/Switch";
import counterpart from "counterpart";
import { AppState } from "../../../..";
import CollapsablePanel from "../../../ui/collapsable-panel/CollapsablePanel";
import "./TaxJarEditIntegration.scss";
import NoticePanel from "../../../ui/notice-panel/NoticePanel";
import { TaxCollection } from "../../../../models/Taxes";
import {
  fetchTaxConfig,
  resetCompanyTaxJarCurrentCountry,
  setTaxRatesField,
  fetchTaxJarIntegrationProductCategories,
  resetCompanyTaxJarProductCategories,
  updateTaxJarConfig,
  deleteTaxconfig
} from "../../../../actions/companyTaxRatesActions";
import { UpsertTaxJarConfigRequest, TaxJarConfig } from "../../../../utils/grpc/generated/Billsby.Protos/billing/private/company/taxation/taxation_pb";
import ProgressIndicator from "../../../ui/progress-indicator/ProgressIndicator";
import { useHistory } from "react-router";
import { goToTaxConfig } from "../../tax-utils/TaxUtils";

interface ICategory {
  label: string;
  value: string;
}

interface ITaxJarEditIntegrationProps {
  showSuccessPanel?: boolean;
  handleUpdateCallback?: Function;
}

const TaxJarEditIntegration: React.FC<ITaxJarEditIntegrationProps> = ({ showSuccessPanel = false, handleUpdateCallback }) => {
  const authData = useSelector((state: AppState) => state.auth);
  const companyTaxRatesReducer = useSelector((state: AppState) => state.companyTaxRatesReducer);

  const dispatch = useDispatch<Function>();
  const history = useHistory();
  
  const [state, setState] = useState({
    hasError: false,
    newApiToken: "",
    showToken: false,
    incorrectToken: false,
    calculateAndFile: true,
    isLoadingData: false,
    isLoading: false,
    collectTaxRegNumber: false,
    taxJarCollapsed: true
  });

  const [tokenError, setTokenError] = useState(false);
  const [isLoadingCategories, setIsLoadingCategories] = useState(false);

  const { incorrectToken, isLoading, collectTaxRegNumber, taxJarCollapsed, hasError, newApiToken, showToken } = state;
  const { productCategories, currentCountry } = companyTaxRatesReducer;
  const { companyName, currentCompanyDomain, currentCompanyId } = authData;

  useEffect(() => {
    if (!currentCountry || !currentCountry.taxConfig) { return }
    setState({ ...state, newApiToken: (currentCountry.taxConfig as TaxJarConfig.AsObject).apiToken, collectTaxRegNumber: currentCountry.taxConfig.taxRegNumberRequirementType !== TaxCollection.DO_NOT_COLLECT });
  }, [currentCountry]);

  useEffect(() => {
    const timeout = setTimeout(() => {
      newApiToken && fetchProductCategories()
    }, 500);

    return () => {
      clearTimeout(timeout);
    };

  }, [newApiToken]);

  useEffect(() => {
    return () => {
      dispatch(resetCompanyTaxJarProductCategories());
      dispatch(resetCompanyTaxJarCurrentCountry());
    }
  }, [])

  if (!currentCountry || !currentCompanyId) {
    return null;
  }

  const { taxConfig } = currentCountry;

  if (!taxConfig) {
    return null;
  }

  const { isAutoFileEnabled, taxRegNumber, taxLabel, taxProductCategoryCode, taxRegNumberRequirementType } = taxConfig as TaxJarConfig.AsObject;

  const fetchProductCategories = async () => {
    setIsLoadingCategories(true);
    setTokenError(false);
    try {
      const result = await dispatch(fetchTaxJarIntegrationProductCategories(newApiToken));

      setIsLoadingCategories(false);
      setTokenError(result.error);
    } catch (err) {

      setIsLoadingCategories(false);
      setTokenError(true);
      console.log(err);
    }
  };

  const onSubmit = async (evt: FormEvent) => {
    evt.preventDefault();
    evt.stopPropagation();

    const data: UpsertTaxJarConfigRequest.AsObject = {
      companyDomain: currentCompanyDomain,
      countryIso3Code: currentCountry.countryCode,
      taxJarConfig: { ...taxConfig, apiToken: newApiToken, taxRegNumberRequirementType: collectTaxRegNumber ? taxRegNumberRequirementType : TaxCollection.DO_NOT_COLLECT } as TaxJarConfig.AsObject
    };

    setState({ ...state, isLoading: true, hasError: false, incorrectToken: false });

    try {
      await dispatch(updateTaxJarConfig(data));
      await dispatch(fetchTaxConfig(currentCompanyDomain));
      handleUpdateCallback && handleUpdateCallback();
      history.push("/configuration/taxes");
    } catch (err) {
      setState({ ...state, isLoading: false, hasError: true });
      console.log(err);
    }
  };

  const handleDeleteTaxConfig = async (evt: any) => {
    evt.stopPropagation();

    if (!currentCountry) {
      return null;
    }

    try {
      await dispatch(deleteTaxconfig(currentCompanyDomain, currentCountry.countryCode, currentCountry.taxServiceType))
      await dispatch(fetchTaxConfig(currentCompanyDomain));
      handleUpdateCallback && handleUpdateCallback();
      goToTaxConfig();
    } catch (err) {
      console.log(err);
    }
  };

  const collapsibleTitle = () => {
    return (
      <div className="content-panel-title">
        <Text content={`${companyName}'s TaxJar`} shouldTranslate={false}></Text>
        <div className="content-panel-title__action-button-wrapper">
          {!taxJarCollapsed && (
            <>
              <Button
                id="visit-website"
                buttonType="add"
                title="TAXJAR_EDIT_TAX_VISIT_WEBSITE"
                onClick={(evt: any) => {
                  evt.stopPropagation();
                  window.open("https://www.taxjar.com/", "_self");
                }}
              />
              <Button id="delete-integration" icon="far fa-trash-alt" buttonType="error" title="TAXJAR_EDIT_TAX_DELETE" onClick={handleDeleteTaxConfig} />
            </>
          )}
          <i className={`fas fa-chevron-${taxJarCollapsed ? "down" : "up"}`}></i>
        </div>
      </div>
    );
  };


  const renderCategoryDropdown = () => {
    if (isLoadingCategories) {
      return <ProgressIndicator color="blue"></ProgressIndicator>;
    }

    if (!productCategories || tokenError) {
      return null;
    }

    return (
      <FormGroup>
        <Row align="center">
          <Col md={2}>
            <FormLabel target="product-category" content="TAXJAR_COMPLETE_INTEGRATION_PRODUCT_CATEGORY" noMargin/>
          </Col>
          <Col md={10}>
            <Dropdown
              id="product-category"
              onChange={(category: ICategory) => updateTaxConfigDetails("taxProductCategoryCode", category.value)}
              placeholder={counterpart("TAXJAR_COMPLETE_INTEGRATION_DIGITAL_SERVICES")}
              value={productCategories ? productCategories.filter(category => category.value === taxProductCategoryCode) : null}
              options={productCategories || []}
            />
          </Col>
        </Row>
      </FormGroup>
    );
  };

  const updateTaxConfigDetails = (detailName: string, detailValue: any) => {
    dispatch(setTaxRatesField("currentCountry", { ...currentCountry, taxConfig: { ...taxConfig, [detailName]: detailValue } }));
  };

  const renderTaxJarApiTokenIntegrationPanel = () => {
    return (
      <CollapsablePanel title={collapsibleTitle()} isCollapsed={taxJarCollapsed} onClick={() => setState({ ...state, taxJarCollapsed: !state.taxJarCollapsed })}>
        <div onClick={(evt: any) => evt.stopPropagation()}>
          <FormGroup>
            <Row align="center">
              <Col md={2}>
                <FormLabel target="api-token" content="TAXJAR_INTEGRATION_API_TOKEN" noMargin />
              </Col>
              <Col md={10}>
                <Input isError={tokenError} errorMsg={counterpart("TAXJAR_EDIT_TAX_INCORRECT_TOKEN")} id="api-token" type="text" placeholder="********" value={showToken ? newApiToken : ""} onChange={(evt: any) => setState({ ...state, newApiToken: evt.target.value, showToken: true })} />
              </Col>
            </Row>
          </FormGroup>
          {renderCategoryDropdown()}
          <Row align="center">
            <Col offset={{ md: 2 }} md={10}>
              <Checkbox
                checked={isAutoFileEnabled}
                onClick={() => updateTaxConfigDetails("isAutoFileEnabled", true)}
                value="File and calculate tax"
                content={<Text content="TAXJAR_COMPLETE_INTEGRATION_CALCULATE_OPT1" noMargin />}
              />
              <Checkbox
                checked={!isAutoFileEnabled}
                onClick={() => updateTaxConfigDetails("isAutoFileEnabled", false)}
                value="Calculate only"
                content={<Text content="TAXJAR_COMPLETE_INTEGRATION_CALCULATE_OPT2" noMargin />}
              />
            </Col>
          </Row>
        </div>
      </CollapsablePanel>
    );
  };

  const renderTaxRegistrationPanel = () => {
    return (
      <Panel title="TAXJAR_EDIT_PANEL2_TITLE">
        <Text content="TAXJAR_EDIT_PANEL2_CONTENT" />
        <FormGroup>
          <Row align="center">
            <Col md={2}>
              <FormLabel target="tax-label" content="TAXJAR_EDIT_TAX_LABEL" noMargin />
            </Col>
            <Col md={10}>
              <Input id="tax-label" type="text" placeholder={counterpart("TAXJAR_EDIT_TAX_LABEL_PLACEHOLDER")} value={taxLabel} onChange={(evt: any) => updateTaxConfigDetails("taxLabel", evt.target.value)} />
            </Col>
          </Row>
        </FormGroup>
        <Row align="center">
          <Col md={2}>
            <FormLabel target="tax-reg-number" content="TAXJAR_EDIT_TAX_REG_NUM" noMargin />
          </Col>
          <Col md={10}>
            <Input id="tax-reg-number" type="text" placeholder="123-456-7890" value={taxRegNumber} onChange={(evt: any) => updateTaxConfigDetails("taxRegNumber", evt.target.value)} />
          </Col>
        </Row>
      </Panel>
    );
  };

  const renderCollectTaxRegistrationPanel = () => {
    return (
      <Panel title="TAXJAR_EDIT_PANEL3_TITLE">
        <Text content="TAXJAR_EDIT_PANEL3_CONTENT" />
        <FormGroup>
          <Row align="center">
            <Col md={1}>
              <Switch
                id="collect-tax"
                checked={collectTaxRegNumber}
                onChange={() => {
                  setState({ ...state, collectTaxRegNumber: !state.collectTaxRegNumber });
                  if (taxRegNumberRequirementType === TaxCollection.DO_NOT_COLLECT) {
                    updateTaxConfigDetails("taxRegNumberRequirementType", TaxCollection.COMPULSORY);
                  }
                }}
              />
            </Col>
            <Col md={11}>
              <Text component="span" content="TAXJAR_EDIT_SWITCH" />
            </Col>
          </Row>
        </FormGroup>
        {collectTaxRegNumber && (
          <Row align="center">
            <Col md={12}>
              <Checkbox
                checked={taxRegNumberRequirementType === TaxCollection.COMPULSORY}
                onClick={() => updateTaxConfigDetails("taxRegNumberRequirementType", TaxCollection.COMPULSORY)}
                value={TaxCollection.COMPULSORY}
                content={<Text content="TAXJAR_EDIT_COLLECT_OPT1" noMargin />}
              />
              <Checkbox
                checked={taxRegNumberRequirementType === TaxCollection.OPTIONAL}
                onClick={() => updateTaxConfigDetails("taxRegNumberRequirementType", TaxCollection.OPTIONAL)}
                value={TaxCollection.OPTIONAL}
                content={<Text content="TAXJAR_EDIT_COLLECT_OPT2" noMargin />}
              />
            </Col>
          </Row>
        )}
      </Panel>
    );
  };

  const renderErrorNoticePanel = () => {
    if (hasError || incorrectToken) {
      return <NoticePanel icon="fas fa-exclamation-circle" isModal type="error" content={`TAXJAR_INTEGRATION_${hasError ? "ERROR" : "INCORRECT_ERROR"}`} />;
    } else return null;
  };

  return (
    <div className="taxjar-edit-integration">
      {renderErrorNoticePanel()}
      {showSuccessPanel && <NoticePanel icon="fas fa-check-circle" className="taxjar-edit-integration__notice-panel" type="success" content="TAXJAR_COMPLETE_INTEGRATION_SUCCESSFUL" />}
      <form onSubmit={onSubmit}>
        {renderTaxJarApiTokenIntegrationPanel()}
        {renderTaxRegistrationPanel()}
        {renderCollectTaxRegistrationPanel()}
        <Button id="taxjar-integration" type="submit" icon={"far fa-check-circle"} buttonType={"general"} isLoading={isLoading && !hasError} title="TAXJAR_EDIT_SAVE_CHANGES" isFullWidth />
      </form>
    </div>
  );
};

export default TaxJarEditIntegration;
