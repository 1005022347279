/* eslint-disable */
// source: reports.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = (function() {
  if (this) { return this; }
  if (typeof window !== 'undefined') { return window; }
  if (typeof global !== 'undefined') { return global; }
  if (typeof self !== 'undefined') { return self; }
  return Function('return this')();
}.call(null));

goog.exportSymbol('proto.revenuerecognition.private.reports.GetInvoiceRevenueRequest', null, global);
goog.exportSymbol('proto.revenuerecognition.private.reports.GetInvoiceRevenueResponse', null, global);
goog.exportSymbol('proto.revenuerecognition.private.reports.GetPlanRevenueRequest', null, global);
goog.exportSymbol('proto.revenuerecognition.private.reports.GetPlanRevenueResponse', null, global);
goog.exportSymbol('proto.revenuerecognition.private.reports.GetProductRevenueRequest', null, global);
goog.exportSymbol('proto.revenuerecognition.private.reports.GetProductRevenueResponse', null, global);
goog.exportSymbol('proto.revenuerecognition.private.reports.GetSubscriptionRevenueRequest', null, global);
goog.exportSymbol('proto.revenuerecognition.private.reports.GetSubscriptionRevenueResponse', null, global);
goog.exportSymbol('proto.revenuerecognition.private.reports.InvoiceMonthReport', null, global);
goog.exportSymbol('proto.revenuerecognition.private.reports.InvoiceReport', null, global);
goog.exportSymbol('proto.revenuerecognition.private.reports.PlanMonthReport', null, global);
goog.exportSymbol('proto.revenuerecognition.private.reports.PlanReport', null, global);
goog.exportSymbol('proto.revenuerecognition.private.reports.ProductMonthReport', null, global);
goog.exportSymbol('proto.revenuerecognition.private.reports.ProductReport', null, global);
goog.exportSymbol('proto.revenuerecognition.private.reports.SubscriptionMonthReport', null, global);
goog.exportSymbol('proto.revenuerecognition.private.reports.SubscriptionReport', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.revenuerecognition.private.reports.GetInvoiceRevenueRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.revenuerecognition.private.reports.GetInvoiceRevenueRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.revenuerecognition.private.reports.GetInvoiceRevenueRequest.displayName = 'proto.revenuerecognition.private.reports.GetInvoiceRevenueRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.revenuerecognition.private.reports.GetInvoiceRevenueResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.revenuerecognition.private.reports.GetInvoiceRevenueResponse.repeatedFields_, null);
};
goog.inherits(proto.revenuerecognition.private.reports.GetInvoiceRevenueResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.revenuerecognition.private.reports.GetInvoiceRevenueResponse.displayName = 'proto.revenuerecognition.private.reports.GetInvoiceRevenueResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.revenuerecognition.private.reports.InvoiceReport = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.revenuerecognition.private.reports.InvoiceReport.repeatedFields_, null);
};
goog.inherits(proto.revenuerecognition.private.reports.InvoiceReport, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.revenuerecognition.private.reports.InvoiceReport.displayName = 'proto.revenuerecognition.private.reports.InvoiceReport';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.revenuerecognition.private.reports.InvoiceMonthReport = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.revenuerecognition.private.reports.InvoiceMonthReport, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.revenuerecognition.private.reports.InvoiceMonthReport.displayName = 'proto.revenuerecognition.private.reports.InvoiceMonthReport';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.revenuerecognition.private.reports.GetSubscriptionRevenueRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.revenuerecognition.private.reports.GetSubscriptionRevenueRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.revenuerecognition.private.reports.GetSubscriptionRevenueRequest.displayName = 'proto.revenuerecognition.private.reports.GetSubscriptionRevenueRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.revenuerecognition.private.reports.GetSubscriptionRevenueResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.revenuerecognition.private.reports.GetSubscriptionRevenueResponse.repeatedFields_, null);
};
goog.inherits(proto.revenuerecognition.private.reports.GetSubscriptionRevenueResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.revenuerecognition.private.reports.GetSubscriptionRevenueResponse.displayName = 'proto.revenuerecognition.private.reports.GetSubscriptionRevenueResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.revenuerecognition.private.reports.SubscriptionReport = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.revenuerecognition.private.reports.SubscriptionReport.repeatedFields_, null);
};
goog.inherits(proto.revenuerecognition.private.reports.SubscriptionReport, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.revenuerecognition.private.reports.SubscriptionReport.displayName = 'proto.revenuerecognition.private.reports.SubscriptionReport';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.revenuerecognition.private.reports.SubscriptionMonthReport = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.revenuerecognition.private.reports.SubscriptionMonthReport, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.revenuerecognition.private.reports.SubscriptionMonthReport.displayName = 'proto.revenuerecognition.private.reports.SubscriptionMonthReport';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.revenuerecognition.private.reports.GetPlanRevenueRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.revenuerecognition.private.reports.GetPlanRevenueRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.revenuerecognition.private.reports.GetPlanRevenueRequest.displayName = 'proto.revenuerecognition.private.reports.GetPlanRevenueRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.revenuerecognition.private.reports.GetPlanRevenueResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.revenuerecognition.private.reports.GetPlanRevenueResponse.repeatedFields_, null);
};
goog.inherits(proto.revenuerecognition.private.reports.GetPlanRevenueResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.revenuerecognition.private.reports.GetPlanRevenueResponse.displayName = 'proto.revenuerecognition.private.reports.GetPlanRevenueResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.revenuerecognition.private.reports.PlanReport = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.revenuerecognition.private.reports.PlanReport.repeatedFields_, null);
};
goog.inherits(proto.revenuerecognition.private.reports.PlanReport, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.revenuerecognition.private.reports.PlanReport.displayName = 'proto.revenuerecognition.private.reports.PlanReport';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.revenuerecognition.private.reports.PlanMonthReport = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.revenuerecognition.private.reports.PlanMonthReport, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.revenuerecognition.private.reports.PlanMonthReport.displayName = 'proto.revenuerecognition.private.reports.PlanMonthReport';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.revenuerecognition.private.reports.GetProductRevenueRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.revenuerecognition.private.reports.GetProductRevenueRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.revenuerecognition.private.reports.GetProductRevenueRequest.displayName = 'proto.revenuerecognition.private.reports.GetProductRevenueRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.revenuerecognition.private.reports.GetProductRevenueResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.revenuerecognition.private.reports.GetProductRevenueResponse.repeatedFields_, null);
};
goog.inherits(proto.revenuerecognition.private.reports.GetProductRevenueResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.revenuerecognition.private.reports.GetProductRevenueResponse.displayName = 'proto.revenuerecognition.private.reports.GetProductRevenueResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.revenuerecognition.private.reports.ProductReport = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.revenuerecognition.private.reports.ProductReport.repeatedFields_, null);
};
goog.inherits(proto.revenuerecognition.private.reports.ProductReport, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.revenuerecognition.private.reports.ProductReport.displayName = 'proto.revenuerecognition.private.reports.ProductReport';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.revenuerecognition.private.reports.ProductMonthReport = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.revenuerecognition.private.reports.ProductMonthReport, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.revenuerecognition.private.reports.ProductMonthReport.displayName = 'proto.revenuerecognition.private.reports.ProductMonthReport';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.revenuerecognition.private.reports.GetInvoiceRevenueRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.revenuerecognition.private.reports.GetInvoiceRevenueRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.revenuerecognition.private.reports.GetInvoiceRevenueRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.revenuerecognition.private.reports.GetInvoiceRevenueRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    companyId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    filterStartMonth: jspb.Message.getFieldWithDefault(msg, 2, 0),
    filterStartYear: jspb.Message.getFieldWithDefault(msg, 3, 0),
    filterEndMonth: jspb.Message.getFieldWithDefault(msg, 4, 0),
    filterEndYear: jspb.Message.getFieldWithDefault(msg, 5, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.revenuerecognition.private.reports.GetInvoiceRevenueRequest}
 */
proto.revenuerecognition.private.reports.GetInvoiceRevenueRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.revenuerecognition.private.reports.GetInvoiceRevenueRequest;
  return proto.revenuerecognition.private.reports.GetInvoiceRevenueRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.revenuerecognition.private.reports.GetInvoiceRevenueRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.revenuerecognition.private.reports.GetInvoiceRevenueRequest}
 */
proto.revenuerecognition.private.reports.GetInvoiceRevenueRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setCompanyId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setFilterStartMonth(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setFilterStartYear(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setFilterEndMonth(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setFilterEndYear(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.revenuerecognition.private.reports.GetInvoiceRevenueRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.revenuerecognition.private.reports.GetInvoiceRevenueRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.revenuerecognition.private.reports.GetInvoiceRevenueRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.revenuerecognition.private.reports.GetInvoiceRevenueRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCompanyId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getFilterStartMonth();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getFilterStartYear();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
  f = message.getFilterEndMonth();
  if (f !== 0) {
    writer.writeInt32(
      4,
      f
    );
  }
  f = message.getFilterEndYear();
  if (f !== 0) {
    writer.writeInt32(
      5,
      f
    );
  }
};


/**
 * optional int32 company_id = 1;
 * @return {number}
 */
proto.revenuerecognition.private.reports.GetInvoiceRevenueRequest.prototype.getCompanyId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.revenuerecognition.private.reports.GetInvoiceRevenueRequest} returns this
 */
proto.revenuerecognition.private.reports.GetInvoiceRevenueRequest.prototype.setCompanyId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int32 filter_start_month = 2;
 * @return {number}
 */
proto.revenuerecognition.private.reports.GetInvoiceRevenueRequest.prototype.getFilterStartMonth = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.revenuerecognition.private.reports.GetInvoiceRevenueRequest} returns this
 */
proto.revenuerecognition.private.reports.GetInvoiceRevenueRequest.prototype.setFilterStartMonth = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int32 filter_start_year = 3;
 * @return {number}
 */
proto.revenuerecognition.private.reports.GetInvoiceRevenueRequest.prototype.getFilterStartYear = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.revenuerecognition.private.reports.GetInvoiceRevenueRequest} returns this
 */
proto.revenuerecognition.private.reports.GetInvoiceRevenueRequest.prototype.setFilterStartYear = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional int32 filter_end_month = 4;
 * @return {number}
 */
proto.revenuerecognition.private.reports.GetInvoiceRevenueRequest.prototype.getFilterEndMonth = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.revenuerecognition.private.reports.GetInvoiceRevenueRequest} returns this
 */
proto.revenuerecognition.private.reports.GetInvoiceRevenueRequest.prototype.setFilterEndMonth = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional int32 filter_end_year = 5;
 * @return {number}
 */
proto.revenuerecognition.private.reports.GetInvoiceRevenueRequest.prototype.getFilterEndYear = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.revenuerecognition.private.reports.GetInvoiceRevenueRequest} returns this
 */
proto.revenuerecognition.private.reports.GetInvoiceRevenueRequest.prototype.setFilterEndYear = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.revenuerecognition.private.reports.GetInvoiceRevenueResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.revenuerecognition.private.reports.GetInvoiceRevenueResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.revenuerecognition.private.reports.GetInvoiceRevenueResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.revenuerecognition.private.reports.GetInvoiceRevenueResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.revenuerecognition.private.reports.GetInvoiceRevenueResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    resultList: jspb.Message.toObjectList(msg.getResultList(),
    proto.revenuerecognition.private.reports.InvoiceReport.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.revenuerecognition.private.reports.GetInvoiceRevenueResponse}
 */
proto.revenuerecognition.private.reports.GetInvoiceRevenueResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.revenuerecognition.private.reports.GetInvoiceRevenueResponse;
  return proto.revenuerecognition.private.reports.GetInvoiceRevenueResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.revenuerecognition.private.reports.GetInvoiceRevenueResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.revenuerecognition.private.reports.GetInvoiceRevenueResponse}
 */
proto.revenuerecognition.private.reports.GetInvoiceRevenueResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.revenuerecognition.private.reports.InvoiceReport;
      reader.readMessage(value,proto.revenuerecognition.private.reports.InvoiceReport.deserializeBinaryFromReader);
      msg.addResult(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.revenuerecognition.private.reports.GetInvoiceRevenueResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.revenuerecognition.private.reports.GetInvoiceRevenueResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.revenuerecognition.private.reports.GetInvoiceRevenueResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.revenuerecognition.private.reports.GetInvoiceRevenueResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getResultList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.revenuerecognition.private.reports.InvoiceReport.serializeBinaryToWriter
    );
  }
};


/**
 * repeated InvoiceReport result = 1;
 * @return {!Array<!proto.revenuerecognition.private.reports.InvoiceReport>}
 */
proto.revenuerecognition.private.reports.GetInvoiceRevenueResponse.prototype.getResultList = function() {
  return /** @type{!Array<!proto.revenuerecognition.private.reports.InvoiceReport>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.revenuerecognition.private.reports.InvoiceReport, 1));
};


/**
 * @param {!Array<!proto.revenuerecognition.private.reports.InvoiceReport>} value
 * @return {!proto.revenuerecognition.private.reports.GetInvoiceRevenueResponse} returns this
*/
proto.revenuerecognition.private.reports.GetInvoiceRevenueResponse.prototype.setResultList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.revenuerecognition.private.reports.InvoiceReport=} opt_value
 * @param {number=} opt_index
 * @return {!proto.revenuerecognition.private.reports.InvoiceReport}
 */
proto.revenuerecognition.private.reports.GetInvoiceRevenueResponse.prototype.addResult = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.revenuerecognition.private.reports.InvoiceReport, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.revenuerecognition.private.reports.GetInvoiceRevenueResponse} returns this
 */
proto.revenuerecognition.private.reports.GetInvoiceRevenueResponse.prototype.clearResultList = function() {
  return this.setResultList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.revenuerecognition.private.reports.InvoiceReport.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.revenuerecognition.private.reports.InvoiceReport.prototype.toObject = function(opt_includeInstance) {
  return proto.revenuerecognition.private.reports.InvoiceReport.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.revenuerecognition.private.reports.InvoiceReport} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.revenuerecognition.private.reports.InvoiceReport.toObject = function(includeInstance, msg) {
  var f, obj = {
    invoiceUniqueId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    monthsList: jspb.Message.toObjectList(msg.getMonthsList(),
    proto.revenuerecognition.private.reports.InvoiceMonthReport.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.revenuerecognition.private.reports.InvoiceReport}
 */
proto.revenuerecognition.private.reports.InvoiceReport.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.revenuerecognition.private.reports.InvoiceReport;
  return proto.revenuerecognition.private.reports.InvoiceReport.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.revenuerecognition.private.reports.InvoiceReport} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.revenuerecognition.private.reports.InvoiceReport}
 */
proto.revenuerecognition.private.reports.InvoiceReport.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setInvoiceUniqueId(value);
      break;
    case 2:
      var value = new proto.revenuerecognition.private.reports.InvoiceMonthReport;
      reader.readMessage(value,proto.revenuerecognition.private.reports.InvoiceMonthReport.deserializeBinaryFromReader);
      msg.addMonths(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.revenuerecognition.private.reports.InvoiceReport.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.revenuerecognition.private.reports.InvoiceReport.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.revenuerecognition.private.reports.InvoiceReport} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.revenuerecognition.private.reports.InvoiceReport.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getInvoiceUniqueId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getMonthsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.revenuerecognition.private.reports.InvoiceMonthReport.serializeBinaryToWriter
    );
  }
};


/**
 * optional string invoice_unique_id = 1;
 * @return {string}
 */
proto.revenuerecognition.private.reports.InvoiceReport.prototype.getInvoiceUniqueId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.revenuerecognition.private.reports.InvoiceReport} returns this
 */
proto.revenuerecognition.private.reports.InvoiceReport.prototype.setInvoiceUniqueId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * repeated InvoiceMonthReport months = 2;
 * @return {!Array<!proto.revenuerecognition.private.reports.InvoiceMonthReport>}
 */
proto.revenuerecognition.private.reports.InvoiceReport.prototype.getMonthsList = function() {
  return /** @type{!Array<!proto.revenuerecognition.private.reports.InvoiceMonthReport>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.revenuerecognition.private.reports.InvoiceMonthReport, 2));
};


/**
 * @param {!Array<!proto.revenuerecognition.private.reports.InvoiceMonthReport>} value
 * @return {!proto.revenuerecognition.private.reports.InvoiceReport} returns this
*/
proto.revenuerecognition.private.reports.InvoiceReport.prototype.setMonthsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.revenuerecognition.private.reports.InvoiceMonthReport=} opt_value
 * @param {number=} opt_index
 * @return {!proto.revenuerecognition.private.reports.InvoiceMonthReport}
 */
proto.revenuerecognition.private.reports.InvoiceReport.prototype.addMonths = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.revenuerecognition.private.reports.InvoiceMonthReport, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.revenuerecognition.private.reports.InvoiceReport} returns this
 */
proto.revenuerecognition.private.reports.InvoiceReport.prototype.clearMonthsList = function() {
  return this.setMonthsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.revenuerecognition.private.reports.InvoiceMonthReport.prototype.toObject = function(opt_includeInstance) {
  return proto.revenuerecognition.private.reports.InvoiceMonthReport.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.revenuerecognition.private.reports.InvoiceMonthReport} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.revenuerecognition.private.reports.InvoiceMonthReport.toObject = function(includeInstance, msg) {
  var f, obj = {
    month: jspb.Message.getFieldWithDefault(msg, 1, 0),
    year: jspb.Message.getFieldWithDefault(msg, 2, 0),
    recognisedRevenueFormattedAmount: jspb.Message.getFieldWithDefault(msg, 3, ""),
    deferredRevenueFormattedAmount: jspb.Message.getFieldWithDefault(msg, 4, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.revenuerecognition.private.reports.InvoiceMonthReport}
 */
proto.revenuerecognition.private.reports.InvoiceMonthReport.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.revenuerecognition.private.reports.InvoiceMonthReport;
  return proto.revenuerecognition.private.reports.InvoiceMonthReport.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.revenuerecognition.private.reports.InvoiceMonthReport} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.revenuerecognition.private.reports.InvoiceMonthReport}
 */
proto.revenuerecognition.private.reports.InvoiceMonthReport.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setMonth(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setYear(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setRecognisedRevenueFormattedAmount(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setDeferredRevenueFormattedAmount(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.revenuerecognition.private.reports.InvoiceMonthReport.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.revenuerecognition.private.reports.InvoiceMonthReport.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.revenuerecognition.private.reports.InvoiceMonthReport} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.revenuerecognition.private.reports.InvoiceMonthReport.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMonth();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getYear();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getRecognisedRevenueFormattedAmount();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getDeferredRevenueFormattedAmount();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
};


/**
 * optional int32 month = 1;
 * @return {number}
 */
proto.revenuerecognition.private.reports.InvoiceMonthReport.prototype.getMonth = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.revenuerecognition.private.reports.InvoiceMonthReport} returns this
 */
proto.revenuerecognition.private.reports.InvoiceMonthReport.prototype.setMonth = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int32 year = 2;
 * @return {number}
 */
proto.revenuerecognition.private.reports.InvoiceMonthReport.prototype.getYear = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.revenuerecognition.private.reports.InvoiceMonthReport} returns this
 */
proto.revenuerecognition.private.reports.InvoiceMonthReport.prototype.setYear = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional string recognised_revenue_formatted_amount = 3;
 * @return {string}
 */
proto.revenuerecognition.private.reports.InvoiceMonthReport.prototype.getRecognisedRevenueFormattedAmount = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.revenuerecognition.private.reports.InvoiceMonthReport} returns this
 */
proto.revenuerecognition.private.reports.InvoiceMonthReport.prototype.setRecognisedRevenueFormattedAmount = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string deferred_revenue_formatted_amount = 4;
 * @return {string}
 */
proto.revenuerecognition.private.reports.InvoiceMonthReport.prototype.getDeferredRevenueFormattedAmount = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.revenuerecognition.private.reports.InvoiceMonthReport} returns this
 */
proto.revenuerecognition.private.reports.InvoiceMonthReport.prototype.setDeferredRevenueFormattedAmount = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.revenuerecognition.private.reports.GetSubscriptionRevenueRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.revenuerecognition.private.reports.GetSubscriptionRevenueRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.revenuerecognition.private.reports.GetSubscriptionRevenueRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.revenuerecognition.private.reports.GetSubscriptionRevenueRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    companyId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    filterStartMonth: jspb.Message.getFieldWithDefault(msg, 2, 0),
    filterStartYear: jspb.Message.getFieldWithDefault(msg, 3, 0),
    filterEndMonth: jspb.Message.getFieldWithDefault(msg, 4, 0),
    filterEndYear: jspb.Message.getFieldWithDefault(msg, 5, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.revenuerecognition.private.reports.GetSubscriptionRevenueRequest}
 */
proto.revenuerecognition.private.reports.GetSubscriptionRevenueRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.revenuerecognition.private.reports.GetSubscriptionRevenueRequest;
  return proto.revenuerecognition.private.reports.GetSubscriptionRevenueRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.revenuerecognition.private.reports.GetSubscriptionRevenueRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.revenuerecognition.private.reports.GetSubscriptionRevenueRequest}
 */
proto.revenuerecognition.private.reports.GetSubscriptionRevenueRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setCompanyId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setFilterStartMonth(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setFilterStartYear(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setFilterEndMonth(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setFilterEndYear(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.revenuerecognition.private.reports.GetSubscriptionRevenueRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.revenuerecognition.private.reports.GetSubscriptionRevenueRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.revenuerecognition.private.reports.GetSubscriptionRevenueRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.revenuerecognition.private.reports.GetSubscriptionRevenueRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCompanyId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getFilterStartMonth();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getFilterStartYear();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
  f = message.getFilterEndMonth();
  if (f !== 0) {
    writer.writeInt32(
      4,
      f
    );
  }
  f = message.getFilterEndYear();
  if (f !== 0) {
    writer.writeInt32(
      5,
      f
    );
  }
};


/**
 * optional int32 company_id = 1;
 * @return {number}
 */
proto.revenuerecognition.private.reports.GetSubscriptionRevenueRequest.prototype.getCompanyId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.revenuerecognition.private.reports.GetSubscriptionRevenueRequest} returns this
 */
proto.revenuerecognition.private.reports.GetSubscriptionRevenueRequest.prototype.setCompanyId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int32 filter_start_month = 2;
 * @return {number}
 */
proto.revenuerecognition.private.reports.GetSubscriptionRevenueRequest.prototype.getFilterStartMonth = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.revenuerecognition.private.reports.GetSubscriptionRevenueRequest} returns this
 */
proto.revenuerecognition.private.reports.GetSubscriptionRevenueRequest.prototype.setFilterStartMonth = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int32 filter_start_year = 3;
 * @return {number}
 */
proto.revenuerecognition.private.reports.GetSubscriptionRevenueRequest.prototype.getFilterStartYear = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.revenuerecognition.private.reports.GetSubscriptionRevenueRequest} returns this
 */
proto.revenuerecognition.private.reports.GetSubscriptionRevenueRequest.prototype.setFilterStartYear = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional int32 filter_end_month = 4;
 * @return {number}
 */
proto.revenuerecognition.private.reports.GetSubscriptionRevenueRequest.prototype.getFilterEndMonth = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.revenuerecognition.private.reports.GetSubscriptionRevenueRequest} returns this
 */
proto.revenuerecognition.private.reports.GetSubscriptionRevenueRequest.prototype.setFilterEndMonth = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional int32 filter_end_year = 5;
 * @return {number}
 */
proto.revenuerecognition.private.reports.GetSubscriptionRevenueRequest.prototype.getFilterEndYear = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.revenuerecognition.private.reports.GetSubscriptionRevenueRequest} returns this
 */
proto.revenuerecognition.private.reports.GetSubscriptionRevenueRequest.prototype.setFilterEndYear = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.revenuerecognition.private.reports.GetSubscriptionRevenueResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.revenuerecognition.private.reports.GetSubscriptionRevenueResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.revenuerecognition.private.reports.GetSubscriptionRevenueResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.revenuerecognition.private.reports.GetSubscriptionRevenueResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.revenuerecognition.private.reports.GetSubscriptionRevenueResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    resultList: jspb.Message.toObjectList(msg.getResultList(),
    proto.revenuerecognition.private.reports.SubscriptionReport.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.revenuerecognition.private.reports.GetSubscriptionRevenueResponse}
 */
proto.revenuerecognition.private.reports.GetSubscriptionRevenueResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.revenuerecognition.private.reports.GetSubscriptionRevenueResponse;
  return proto.revenuerecognition.private.reports.GetSubscriptionRevenueResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.revenuerecognition.private.reports.GetSubscriptionRevenueResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.revenuerecognition.private.reports.GetSubscriptionRevenueResponse}
 */
proto.revenuerecognition.private.reports.GetSubscriptionRevenueResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.revenuerecognition.private.reports.SubscriptionReport;
      reader.readMessage(value,proto.revenuerecognition.private.reports.SubscriptionReport.deserializeBinaryFromReader);
      msg.addResult(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.revenuerecognition.private.reports.GetSubscriptionRevenueResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.revenuerecognition.private.reports.GetSubscriptionRevenueResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.revenuerecognition.private.reports.GetSubscriptionRevenueResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.revenuerecognition.private.reports.GetSubscriptionRevenueResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getResultList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.revenuerecognition.private.reports.SubscriptionReport.serializeBinaryToWriter
    );
  }
};


/**
 * repeated SubscriptionReport result = 1;
 * @return {!Array<!proto.revenuerecognition.private.reports.SubscriptionReport>}
 */
proto.revenuerecognition.private.reports.GetSubscriptionRevenueResponse.prototype.getResultList = function() {
  return /** @type{!Array<!proto.revenuerecognition.private.reports.SubscriptionReport>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.revenuerecognition.private.reports.SubscriptionReport, 1));
};


/**
 * @param {!Array<!proto.revenuerecognition.private.reports.SubscriptionReport>} value
 * @return {!proto.revenuerecognition.private.reports.GetSubscriptionRevenueResponse} returns this
*/
proto.revenuerecognition.private.reports.GetSubscriptionRevenueResponse.prototype.setResultList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.revenuerecognition.private.reports.SubscriptionReport=} opt_value
 * @param {number=} opt_index
 * @return {!proto.revenuerecognition.private.reports.SubscriptionReport}
 */
proto.revenuerecognition.private.reports.GetSubscriptionRevenueResponse.prototype.addResult = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.revenuerecognition.private.reports.SubscriptionReport, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.revenuerecognition.private.reports.GetSubscriptionRevenueResponse} returns this
 */
proto.revenuerecognition.private.reports.GetSubscriptionRevenueResponse.prototype.clearResultList = function() {
  return this.setResultList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.revenuerecognition.private.reports.SubscriptionReport.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.revenuerecognition.private.reports.SubscriptionReport.prototype.toObject = function(opt_includeInstance) {
  return proto.revenuerecognition.private.reports.SubscriptionReport.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.revenuerecognition.private.reports.SubscriptionReport} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.revenuerecognition.private.reports.SubscriptionReport.toObject = function(includeInstance, msg) {
  var f, obj = {
    subscriptionUniqueId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    monthsList: jspb.Message.toObjectList(msg.getMonthsList(),
    proto.revenuerecognition.private.reports.SubscriptionMonthReport.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.revenuerecognition.private.reports.SubscriptionReport}
 */
proto.revenuerecognition.private.reports.SubscriptionReport.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.revenuerecognition.private.reports.SubscriptionReport;
  return proto.revenuerecognition.private.reports.SubscriptionReport.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.revenuerecognition.private.reports.SubscriptionReport} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.revenuerecognition.private.reports.SubscriptionReport}
 */
proto.revenuerecognition.private.reports.SubscriptionReport.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setSubscriptionUniqueId(value);
      break;
    case 2:
      var value = new proto.revenuerecognition.private.reports.SubscriptionMonthReport;
      reader.readMessage(value,proto.revenuerecognition.private.reports.SubscriptionMonthReport.deserializeBinaryFromReader);
      msg.addMonths(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.revenuerecognition.private.reports.SubscriptionReport.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.revenuerecognition.private.reports.SubscriptionReport.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.revenuerecognition.private.reports.SubscriptionReport} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.revenuerecognition.private.reports.SubscriptionReport.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSubscriptionUniqueId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getMonthsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.revenuerecognition.private.reports.SubscriptionMonthReport.serializeBinaryToWriter
    );
  }
};


/**
 * optional string subscription_unique_id = 1;
 * @return {string}
 */
proto.revenuerecognition.private.reports.SubscriptionReport.prototype.getSubscriptionUniqueId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.revenuerecognition.private.reports.SubscriptionReport} returns this
 */
proto.revenuerecognition.private.reports.SubscriptionReport.prototype.setSubscriptionUniqueId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * repeated SubscriptionMonthReport months = 2;
 * @return {!Array<!proto.revenuerecognition.private.reports.SubscriptionMonthReport>}
 */
proto.revenuerecognition.private.reports.SubscriptionReport.prototype.getMonthsList = function() {
  return /** @type{!Array<!proto.revenuerecognition.private.reports.SubscriptionMonthReport>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.revenuerecognition.private.reports.SubscriptionMonthReport, 2));
};


/**
 * @param {!Array<!proto.revenuerecognition.private.reports.SubscriptionMonthReport>} value
 * @return {!proto.revenuerecognition.private.reports.SubscriptionReport} returns this
*/
proto.revenuerecognition.private.reports.SubscriptionReport.prototype.setMonthsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.revenuerecognition.private.reports.SubscriptionMonthReport=} opt_value
 * @param {number=} opt_index
 * @return {!proto.revenuerecognition.private.reports.SubscriptionMonthReport}
 */
proto.revenuerecognition.private.reports.SubscriptionReport.prototype.addMonths = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.revenuerecognition.private.reports.SubscriptionMonthReport, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.revenuerecognition.private.reports.SubscriptionReport} returns this
 */
proto.revenuerecognition.private.reports.SubscriptionReport.prototype.clearMonthsList = function() {
  return this.setMonthsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.revenuerecognition.private.reports.SubscriptionMonthReport.prototype.toObject = function(opt_includeInstance) {
  return proto.revenuerecognition.private.reports.SubscriptionMonthReport.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.revenuerecognition.private.reports.SubscriptionMonthReport} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.revenuerecognition.private.reports.SubscriptionMonthReport.toObject = function(includeInstance, msg) {
  var f, obj = {
    month: jspb.Message.getFieldWithDefault(msg, 1, 0),
    year: jspb.Message.getFieldWithDefault(msg, 2, 0),
    recognisedRevenueFormattedAmount: jspb.Message.getFieldWithDefault(msg, 3, ""),
    deferredRevenueFormattedAmount: jspb.Message.getFieldWithDefault(msg, 4, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.revenuerecognition.private.reports.SubscriptionMonthReport}
 */
proto.revenuerecognition.private.reports.SubscriptionMonthReport.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.revenuerecognition.private.reports.SubscriptionMonthReport;
  return proto.revenuerecognition.private.reports.SubscriptionMonthReport.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.revenuerecognition.private.reports.SubscriptionMonthReport} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.revenuerecognition.private.reports.SubscriptionMonthReport}
 */
proto.revenuerecognition.private.reports.SubscriptionMonthReport.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setMonth(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setYear(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setRecognisedRevenueFormattedAmount(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setDeferredRevenueFormattedAmount(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.revenuerecognition.private.reports.SubscriptionMonthReport.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.revenuerecognition.private.reports.SubscriptionMonthReport.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.revenuerecognition.private.reports.SubscriptionMonthReport} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.revenuerecognition.private.reports.SubscriptionMonthReport.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMonth();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getYear();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getRecognisedRevenueFormattedAmount();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getDeferredRevenueFormattedAmount();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
};


/**
 * optional int32 month = 1;
 * @return {number}
 */
proto.revenuerecognition.private.reports.SubscriptionMonthReport.prototype.getMonth = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.revenuerecognition.private.reports.SubscriptionMonthReport} returns this
 */
proto.revenuerecognition.private.reports.SubscriptionMonthReport.prototype.setMonth = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int32 year = 2;
 * @return {number}
 */
proto.revenuerecognition.private.reports.SubscriptionMonthReport.prototype.getYear = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.revenuerecognition.private.reports.SubscriptionMonthReport} returns this
 */
proto.revenuerecognition.private.reports.SubscriptionMonthReport.prototype.setYear = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional string recognised_revenue_formatted_amount = 3;
 * @return {string}
 */
proto.revenuerecognition.private.reports.SubscriptionMonthReport.prototype.getRecognisedRevenueFormattedAmount = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.revenuerecognition.private.reports.SubscriptionMonthReport} returns this
 */
proto.revenuerecognition.private.reports.SubscriptionMonthReport.prototype.setRecognisedRevenueFormattedAmount = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string deferred_revenue_formatted_amount = 4;
 * @return {string}
 */
proto.revenuerecognition.private.reports.SubscriptionMonthReport.prototype.getDeferredRevenueFormattedAmount = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.revenuerecognition.private.reports.SubscriptionMonthReport} returns this
 */
proto.revenuerecognition.private.reports.SubscriptionMonthReport.prototype.setDeferredRevenueFormattedAmount = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.revenuerecognition.private.reports.GetPlanRevenueRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.revenuerecognition.private.reports.GetPlanRevenueRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.revenuerecognition.private.reports.GetPlanRevenueRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.revenuerecognition.private.reports.GetPlanRevenueRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    companyId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    filterStartMonth: jspb.Message.getFieldWithDefault(msg, 2, 0),
    filterStartYear: jspb.Message.getFieldWithDefault(msg, 3, 0),
    filterEndMonth: jspb.Message.getFieldWithDefault(msg, 4, 0),
    filterEndYear: jspb.Message.getFieldWithDefault(msg, 5, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.revenuerecognition.private.reports.GetPlanRevenueRequest}
 */
proto.revenuerecognition.private.reports.GetPlanRevenueRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.revenuerecognition.private.reports.GetPlanRevenueRequest;
  return proto.revenuerecognition.private.reports.GetPlanRevenueRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.revenuerecognition.private.reports.GetPlanRevenueRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.revenuerecognition.private.reports.GetPlanRevenueRequest}
 */
proto.revenuerecognition.private.reports.GetPlanRevenueRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setCompanyId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setFilterStartMonth(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setFilterStartYear(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setFilterEndMonth(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setFilterEndYear(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.revenuerecognition.private.reports.GetPlanRevenueRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.revenuerecognition.private.reports.GetPlanRevenueRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.revenuerecognition.private.reports.GetPlanRevenueRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.revenuerecognition.private.reports.GetPlanRevenueRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCompanyId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getFilterStartMonth();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getFilterStartYear();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
  f = message.getFilterEndMonth();
  if (f !== 0) {
    writer.writeInt32(
      4,
      f
    );
  }
  f = message.getFilterEndYear();
  if (f !== 0) {
    writer.writeInt32(
      5,
      f
    );
  }
};


/**
 * optional int32 company_id = 1;
 * @return {number}
 */
proto.revenuerecognition.private.reports.GetPlanRevenueRequest.prototype.getCompanyId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.revenuerecognition.private.reports.GetPlanRevenueRequest} returns this
 */
proto.revenuerecognition.private.reports.GetPlanRevenueRequest.prototype.setCompanyId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int32 filter_start_month = 2;
 * @return {number}
 */
proto.revenuerecognition.private.reports.GetPlanRevenueRequest.prototype.getFilterStartMonth = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.revenuerecognition.private.reports.GetPlanRevenueRequest} returns this
 */
proto.revenuerecognition.private.reports.GetPlanRevenueRequest.prototype.setFilterStartMonth = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int32 filter_start_year = 3;
 * @return {number}
 */
proto.revenuerecognition.private.reports.GetPlanRevenueRequest.prototype.getFilterStartYear = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.revenuerecognition.private.reports.GetPlanRevenueRequest} returns this
 */
proto.revenuerecognition.private.reports.GetPlanRevenueRequest.prototype.setFilterStartYear = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional int32 filter_end_month = 4;
 * @return {number}
 */
proto.revenuerecognition.private.reports.GetPlanRevenueRequest.prototype.getFilterEndMonth = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.revenuerecognition.private.reports.GetPlanRevenueRequest} returns this
 */
proto.revenuerecognition.private.reports.GetPlanRevenueRequest.prototype.setFilterEndMonth = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional int32 filter_end_year = 5;
 * @return {number}
 */
proto.revenuerecognition.private.reports.GetPlanRevenueRequest.prototype.getFilterEndYear = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.revenuerecognition.private.reports.GetPlanRevenueRequest} returns this
 */
proto.revenuerecognition.private.reports.GetPlanRevenueRequest.prototype.setFilterEndYear = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.revenuerecognition.private.reports.GetPlanRevenueResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.revenuerecognition.private.reports.GetPlanRevenueResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.revenuerecognition.private.reports.GetPlanRevenueResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.revenuerecognition.private.reports.GetPlanRevenueResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.revenuerecognition.private.reports.GetPlanRevenueResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    resultList: jspb.Message.toObjectList(msg.getResultList(),
    proto.revenuerecognition.private.reports.PlanReport.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.revenuerecognition.private.reports.GetPlanRevenueResponse}
 */
proto.revenuerecognition.private.reports.GetPlanRevenueResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.revenuerecognition.private.reports.GetPlanRevenueResponse;
  return proto.revenuerecognition.private.reports.GetPlanRevenueResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.revenuerecognition.private.reports.GetPlanRevenueResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.revenuerecognition.private.reports.GetPlanRevenueResponse}
 */
proto.revenuerecognition.private.reports.GetPlanRevenueResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.revenuerecognition.private.reports.PlanReport;
      reader.readMessage(value,proto.revenuerecognition.private.reports.PlanReport.deserializeBinaryFromReader);
      msg.addResult(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.revenuerecognition.private.reports.GetPlanRevenueResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.revenuerecognition.private.reports.GetPlanRevenueResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.revenuerecognition.private.reports.GetPlanRevenueResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.revenuerecognition.private.reports.GetPlanRevenueResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getResultList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.revenuerecognition.private.reports.PlanReport.serializeBinaryToWriter
    );
  }
};


/**
 * repeated PlanReport result = 1;
 * @return {!Array<!proto.revenuerecognition.private.reports.PlanReport>}
 */
proto.revenuerecognition.private.reports.GetPlanRevenueResponse.prototype.getResultList = function() {
  return /** @type{!Array<!proto.revenuerecognition.private.reports.PlanReport>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.revenuerecognition.private.reports.PlanReport, 1));
};


/**
 * @param {!Array<!proto.revenuerecognition.private.reports.PlanReport>} value
 * @return {!proto.revenuerecognition.private.reports.GetPlanRevenueResponse} returns this
*/
proto.revenuerecognition.private.reports.GetPlanRevenueResponse.prototype.setResultList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.revenuerecognition.private.reports.PlanReport=} opt_value
 * @param {number=} opt_index
 * @return {!proto.revenuerecognition.private.reports.PlanReport}
 */
proto.revenuerecognition.private.reports.GetPlanRevenueResponse.prototype.addResult = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.revenuerecognition.private.reports.PlanReport, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.revenuerecognition.private.reports.GetPlanRevenueResponse} returns this
 */
proto.revenuerecognition.private.reports.GetPlanRevenueResponse.prototype.clearResultList = function() {
  return this.setResultList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.revenuerecognition.private.reports.PlanReport.repeatedFields_ = [4];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.revenuerecognition.private.reports.PlanReport.prototype.toObject = function(opt_includeInstance) {
  return proto.revenuerecognition.private.reports.PlanReport.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.revenuerecognition.private.reports.PlanReport} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.revenuerecognition.private.reports.PlanReport.toObject = function(includeInstance, msg) {
  var f, obj = {
    planId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    planName: jspb.Message.getFieldWithDefault(msg, 2, ""),
    productName: jspb.Message.getFieldWithDefault(msg, 3, ""),
    monthsList: jspb.Message.toObjectList(msg.getMonthsList(),
    proto.revenuerecognition.private.reports.PlanMonthReport.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.revenuerecognition.private.reports.PlanReport}
 */
proto.revenuerecognition.private.reports.PlanReport.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.revenuerecognition.private.reports.PlanReport;
  return proto.revenuerecognition.private.reports.PlanReport.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.revenuerecognition.private.reports.PlanReport} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.revenuerecognition.private.reports.PlanReport}
 */
proto.revenuerecognition.private.reports.PlanReport.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPlanId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setPlanName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setProductName(value);
      break;
    case 4:
      var value = new proto.revenuerecognition.private.reports.PlanMonthReport;
      reader.readMessage(value,proto.revenuerecognition.private.reports.PlanMonthReport.deserializeBinaryFromReader);
      msg.addMonths(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.revenuerecognition.private.reports.PlanReport.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.revenuerecognition.private.reports.PlanReport.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.revenuerecognition.private.reports.PlanReport} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.revenuerecognition.private.reports.PlanReport.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPlanId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getPlanName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getProductName();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getMonthsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      proto.revenuerecognition.private.reports.PlanMonthReport.serializeBinaryToWriter
    );
  }
};


/**
 * optional int32 plan_id = 1;
 * @return {number}
 */
proto.revenuerecognition.private.reports.PlanReport.prototype.getPlanId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.revenuerecognition.private.reports.PlanReport} returns this
 */
proto.revenuerecognition.private.reports.PlanReport.prototype.setPlanId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string plan_name = 2;
 * @return {string}
 */
proto.revenuerecognition.private.reports.PlanReport.prototype.getPlanName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.revenuerecognition.private.reports.PlanReport} returns this
 */
proto.revenuerecognition.private.reports.PlanReport.prototype.setPlanName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string product_name = 3;
 * @return {string}
 */
proto.revenuerecognition.private.reports.PlanReport.prototype.getProductName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.revenuerecognition.private.reports.PlanReport} returns this
 */
proto.revenuerecognition.private.reports.PlanReport.prototype.setProductName = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * repeated PlanMonthReport months = 4;
 * @return {!Array<!proto.revenuerecognition.private.reports.PlanMonthReport>}
 */
proto.revenuerecognition.private.reports.PlanReport.prototype.getMonthsList = function() {
  return /** @type{!Array<!proto.revenuerecognition.private.reports.PlanMonthReport>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.revenuerecognition.private.reports.PlanMonthReport, 4));
};


/**
 * @param {!Array<!proto.revenuerecognition.private.reports.PlanMonthReport>} value
 * @return {!proto.revenuerecognition.private.reports.PlanReport} returns this
*/
proto.revenuerecognition.private.reports.PlanReport.prototype.setMonthsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.revenuerecognition.private.reports.PlanMonthReport=} opt_value
 * @param {number=} opt_index
 * @return {!proto.revenuerecognition.private.reports.PlanMonthReport}
 */
proto.revenuerecognition.private.reports.PlanReport.prototype.addMonths = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.revenuerecognition.private.reports.PlanMonthReport, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.revenuerecognition.private.reports.PlanReport} returns this
 */
proto.revenuerecognition.private.reports.PlanReport.prototype.clearMonthsList = function() {
  return this.setMonthsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.revenuerecognition.private.reports.PlanMonthReport.prototype.toObject = function(opt_includeInstance) {
  return proto.revenuerecognition.private.reports.PlanMonthReport.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.revenuerecognition.private.reports.PlanMonthReport} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.revenuerecognition.private.reports.PlanMonthReport.toObject = function(includeInstance, msg) {
  var f, obj = {
    month: jspb.Message.getFieldWithDefault(msg, 1, 0),
    year: jspb.Message.getFieldWithDefault(msg, 2, 0),
    recognisedRevenueFormattedAmount: jspb.Message.getFieldWithDefault(msg, 3, ""),
    deferredRevenueFormattedAmount: jspb.Message.getFieldWithDefault(msg, 4, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.revenuerecognition.private.reports.PlanMonthReport}
 */
proto.revenuerecognition.private.reports.PlanMonthReport.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.revenuerecognition.private.reports.PlanMonthReport;
  return proto.revenuerecognition.private.reports.PlanMonthReport.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.revenuerecognition.private.reports.PlanMonthReport} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.revenuerecognition.private.reports.PlanMonthReport}
 */
proto.revenuerecognition.private.reports.PlanMonthReport.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setMonth(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setYear(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setRecognisedRevenueFormattedAmount(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setDeferredRevenueFormattedAmount(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.revenuerecognition.private.reports.PlanMonthReport.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.revenuerecognition.private.reports.PlanMonthReport.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.revenuerecognition.private.reports.PlanMonthReport} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.revenuerecognition.private.reports.PlanMonthReport.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMonth();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getYear();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getRecognisedRevenueFormattedAmount();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getDeferredRevenueFormattedAmount();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
};


/**
 * optional int32 month = 1;
 * @return {number}
 */
proto.revenuerecognition.private.reports.PlanMonthReport.prototype.getMonth = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.revenuerecognition.private.reports.PlanMonthReport} returns this
 */
proto.revenuerecognition.private.reports.PlanMonthReport.prototype.setMonth = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int32 year = 2;
 * @return {number}
 */
proto.revenuerecognition.private.reports.PlanMonthReport.prototype.getYear = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.revenuerecognition.private.reports.PlanMonthReport} returns this
 */
proto.revenuerecognition.private.reports.PlanMonthReport.prototype.setYear = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional string recognised_revenue_formatted_amount = 3;
 * @return {string}
 */
proto.revenuerecognition.private.reports.PlanMonthReport.prototype.getRecognisedRevenueFormattedAmount = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.revenuerecognition.private.reports.PlanMonthReport} returns this
 */
proto.revenuerecognition.private.reports.PlanMonthReport.prototype.setRecognisedRevenueFormattedAmount = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string deferred_revenue_formatted_amount = 4;
 * @return {string}
 */
proto.revenuerecognition.private.reports.PlanMonthReport.prototype.getDeferredRevenueFormattedAmount = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.revenuerecognition.private.reports.PlanMonthReport} returns this
 */
proto.revenuerecognition.private.reports.PlanMonthReport.prototype.setDeferredRevenueFormattedAmount = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.revenuerecognition.private.reports.GetProductRevenueRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.revenuerecognition.private.reports.GetProductRevenueRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.revenuerecognition.private.reports.GetProductRevenueRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.revenuerecognition.private.reports.GetProductRevenueRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    companyId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    filterStartMonth: jspb.Message.getFieldWithDefault(msg, 2, 0),
    filterStartYear: jspb.Message.getFieldWithDefault(msg, 3, 0),
    filterEndMonth: jspb.Message.getFieldWithDefault(msg, 4, 0),
    filterEndYear: jspb.Message.getFieldWithDefault(msg, 5, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.revenuerecognition.private.reports.GetProductRevenueRequest}
 */
proto.revenuerecognition.private.reports.GetProductRevenueRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.revenuerecognition.private.reports.GetProductRevenueRequest;
  return proto.revenuerecognition.private.reports.GetProductRevenueRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.revenuerecognition.private.reports.GetProductRevenueRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.revenuerecognition.private.reports.GetProductRevenueRequest}
 */
proto.revenuerecognition.private.reports.GetProductRevenueRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setCompanyId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setFilterStartMonth(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setFilterStartYear(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setFilterEndMonth(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setFilterEndYear(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.revenuerecognition.private.reports.GetProductRevenueRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.revenuerecognition.private.reports.GetProductRevenueRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.revenuerecognition.private.reports.GetProductRevenueRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.revenuerecognition.private.reports.GetProductRevenueRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCompanyId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getFilterStartMonth();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getFilterStartYear();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
  f = message.getFilterEndMonth();
  if (f !== 0) {
    writer.writeInt32(
      4,
      f
    );
  }
  f = message.getFilterEndYear();
  if (f !== 0) {
    writer.writeInt32(
      5,
      f
    );
  }
};


/**
 * optional int32 company_id = 1;
 * @return {number}
 */
proto.revenuerecognition.private.reports.GetProductRevenueRequest.prototype.getCompanyId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.revenuerecognition.private.reports.GetProductRevenueRequest} returns this
 */
proto.revenuerecognition.private.reports.GetProductRevenueRequest.prototype.setCompanyId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int32 filter_start_month = 2;
 * @return {number}
 */
proto.revenuerecognition.private.reports.GetProductRevenueRequest.prototype.getFilterStartMonth = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.revenuerecognition.private.reports.GetProductRevenueRequest} returns this
 */
proto.revenuerecognition.private.reports.GetProductRevenueRequest.prototype.setFilterStartMonth = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int32 filter_start_year = 3;
 * @return {number}
 */
proto.revenuerecognition.private.reports.GetProductRevenueRequest.prototype.getFilterStartYear = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.revenuerecognition.private.reports.GetProductRevenueRequest} returns this
 */
proto.revenuerecognition.private.reports.GetProductRevenueRequest.prototype.setFilterStartYear = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional int32 filter_end_month = 4;
 * @return {number}
 */
proto.revenuerecognition.private.reports.GetProductRevenueRequest.prototype.getFilterEndMonth = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.revenuerecognition.private.reports.GetProductRevenueRequest} returns this
 */
proto.revenuerecognition.private.reports.GetProductRevenueRequest.prototype.setFilterEndMonth = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional int32 filter_end_year = 5;
 * @return {number}
 */
proto.revenuerecognition.private.reports.GetProductRevenueRequest.prototype.getFilterEndYear = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.revenuerecognition.private.reports.GetProductRevenueRequest} returns this
 */
proto.revenuerecognition.private.reports.GetProductRevenueRequest.prototype.setFilterEndYear = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.revenuerecognition.private.reports.GetProductRevenueResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.revenuerecognition.private.reports.GetProductRevenueResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.revenuerecognition.private.reports.GetProductRevenueResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.revenuerecognition.private.reports.GetProductRevenueResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.revenuerecognition.private.reports.GetProductRevenueResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    resultList: jspb.Message.toObjectList(msg.getResultList(),
    proto.revenuerecognition.private.reports.ProductReport.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.revenuerecognition.private.reports.GetProductRevenueResponse}
 */
proto.revenuerecognition.private.reports.GetProductRevenueResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.revenuerecognition.private.reports.GetProductRevenueResponse;
  return proto.revenuerecognition.private.reports.GetProductRevenueResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.revenuerecognition.private.reports.GetProductRevenueResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.revenuerecognition.private.reports.GetProductRevenueResponse}
 */
proto.revenuerecognition.private.reports.GetProductRevenueResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.revenuerecognition.private.reports.ProductReport;
      reader.readMessage(value,proto.revenuerecognition.private.reports.ProductReport.deserializeBinaryFromReader);
      msg.addResult(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.revenuerecognition.private.reports.GetProductRevenueResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.revenuerecognition.private.reports.GetProductRevenueResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.revenuerecognition.private.reports.GetProductRevenueResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.revenuerecognition.private.reports.GetProductRevenueResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getResultList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.revenuerecognition.private.reports.ProductReport.serializeBinaryToWriter
    );
  }
};


/**
 * repeated ProductReport result = 1;
 * @return {!Array<!proto.revenuerecognition.private.reports.ProductReport>}
 */
proto.revenuerecognition.private.reports.GetProductRevenueResponse.prototype.getResultList = function() {
  return /** @type{!Array<!proto.revenuerecognition.private.reports.ProductReport>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.revenuerecognition.private.reports.ProductReport, 1));
};


/**
 * @param {!Array<!proto.revenuerecognition.private.reports.ProductReport>} value
 * @return {!proto.revenuerecognition.private.reports.GetProductRevenueResponse} returns this
*/
proto.revenuerecognition.private.reports.GetProductRevenueResponse.prototype.setResultList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.revenuerecognition.private.reports.ProductReport=} opt_value
 * @param {number=} opt_index
 * @return {!proto.revenuerecognition.private.reports.ProductReport}
 */
proto.revenuerecognition.private.reports.GetProductRevenueResponse.prototype.addResult = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.revenuerecognition.private.reports.ProductReport, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.revenuerecognition.private.reports.GetProductRevenueResponse} returns this
 */
proto.revenuerecognition.private.reports.GetProductRevenueResponse.prototype.clearResultList = function() {
  return this.setResultList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.revenuerecognition.private.reports.ProductReport.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.revenuerecognition.private.reports.ProductReport.prototype.toObject = function(opt_includeInstance) {
  return proto.revenuerecognition.private.reports.ProductReport.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.revenuerecognition.private.reports.ProductReport} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.revenuerecognition.private.reports.ProductReport.toObject = function(includeInstance, msg) {
  var f, obj = {
    productId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    productName: jspb.Message.getFieldWithDefault(msg, 2, ""),
    monthsList: jspb.Message.toObjectList(msg.getMonthsList(),
    proto.revenuerecognition.private.reports.ProductMonthReport.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.revenuerecognition.private.reports.ProductReport}
 */
proto.revenuerecognition.private.reports.ProductReport.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.revenuerecognition.private.reports.ProductReport;
  return proto.revenuerecognition.private.reports.ProductReport.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.revenuerecognition.private.reports.ProductReport} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.revenuerecognition.private.reports.ProductReport}
 */
proto.revenuerecognition.private.reports.ProductReport.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setProductId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setProductName(value);
      break;
    case 3:
      var value = new proto.revenuerecognition.private.reports.ProductMonthReport;
      reader.readMessage(value,proto.revenuerecognition.private.reports.ProductMonthReport.deserializeBinaryFromReader);
      msg.addMonths(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.revenuerecognition.private.reports.ProductReport.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.revenuerecognition.private.reports.ProductReport.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.revenuerecognition.private.reports.ProductReport} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.revenuerecognition.private.reports.ProductReport.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getProductId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getProductName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getMonthsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.revenuerecognition.private.reports.ProductMonthReport.serializeBinaryToWriter
    );
  }
};


/**
 * optional int32 product_id = 1;
 * @return {number}
 */
proto.revenuerecognition.private.reports.ProductReport.prototype.getProductId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.revenuerecognition.private.reports.ProductReport} returns this
 */
proto.revenuerecognition.private.reports.ProductReport.prototype.setProductId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string product_name = 2;
 * @return {string}
 */
proto.revenuerecognition.private.reports.ProductReport.prototype.getProductName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.revenuerecognition.private.reports.ProductReport} returns this
 */
proto.revenuerecognition.private.reports.ProductReport.prototype.setProductName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * repeated ProductMonthReport months = 3;
 * @return {!Array<!proto.revenuerecognition.private.reports.ProductMonthReport>}
 */
proto.revenuerecognition.private.reports.ProductReport.prototype.getMonthsList = function() {
  return /** @type{!Array<!proto.revenuerecognition.private.reports.ProductMonthReport>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.revenuerecognition.private.reports.ProductMonthReport, 3));
};


/**
 * @param {!Array<!proto.revenuerecognition.private.reports.ProductMonthReport>} value
 * @return {!proto.revenuerecognition.private.reports.ProductReport} returns this
*/
proto.revenuerecognition.private.reports.ProductReport.prototype.setMonthsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.revenuerecognition.private.reports.ProductMonthReport=} opt_value
 * @param {number=} opt_index
 * @return {!proto.revenuerecognition.private.reports.ProductMonthReport}
 */
proto.revenuerecognition.private.reports.ProductReport.prototype.addMonths = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.revenuerecognition.private.reports.ProductMonthReport, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.revenuerecognition.private.reports.ProductReport} returns this
 */
proto.revenuerecognition.private.reports.ProductReport.prototype.clearMonthsList = function() {
  return this.setMonthsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.revenuerecognition.private.reports.ProductMonthReport.prototype.toObject = function(opt_includeInstance) {
  return proto.revenuerecognition.private.reports.ProductMonthReport.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.revenuerecognition.private.reports.ProductMonthReport} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.revenuerecognition.private.reports.ProductMonthReport.toObject = function(includeInstance, msg) {
  var f, obj = {
    month: jspb.Message.getFieldWithDefault(msg, 1, 0),
    year: jspb.Message.getFieldWithDefault(msg, 2, 0),
    recognisedRevenueFormattedAmount: jspb.Message.getFieldWithDefault(msg, 3, ""),
    deferredRevenueFormattedAmount: jspb.Message.getFieldWithDefault(msg, 4, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.revenuerecognition.private.reports.ProductMonthReport}
 */
proto.revenuerecognition.private.reports.ProductMonthReport.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.revenuerecognition.private.reports.ProductMonthReport;
  return proto.revenuerecognition.private.reports.ProductMonthReport.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.revenuerecognition.private.reports.ProductMonthReport} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.revenuerecognition.private.reports.ProductMonthReport}
 */
proto.revenuerecognition.private.reports.ProductMonthReport.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setMonth(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setYear(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setRecognisedRevenueFormattedAmount(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setDeferredRevenueFormattedAmount(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.revenuerecognition.private.reports.ProductMonthReport.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.revenuerecognition.private.reports.ProductMonthReport.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.revenuerecognition.private.reports.ProductMonthReport} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.revenuerecognition.private.reports.ProductMonthReport.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMonth();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getYear();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getRecognisedRevenueFormattedAmount();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getDeferredRevenueFormattedAmount();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
};


/**
 * optional int32 month = 1;
 * @return {number}
 */
proto.revenuerecognition.private.reports.ProductMonthReport.prototype.getMonth = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.revenuerecognition.private.reports.ProductMonthReport} returns this
 */
proto.revenuerecognition.private.reports.ProductMonthReport.prototype.setMonth = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int32 year = 2;
 * @return {number}
 */
proto.revenuerecognition.private.reports.ProductMonthReport.prototype.getYear = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.revenuerecognition.private.reports.ProductMonthReport} returns this
 */
proto.revenuerecognition.private.reports.ProductMonthReport.prototype.setYear = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional string recognised_revenue_formatted_amount = 3;
 * @return {string}
 */
proto.revenuerecognition.private.reports.ProductMonthReport.prototype.getRecognisedRevenueFormattedAmount = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.revenuerecognition.private.reports.ProductMonthReport} returns this
 */
proto.revenuerecognition.private.reports.ProductMonthReport.prototype.setRecognisedRevenueFormattedAmount = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string deferred_revenue_formatted_amount = 4;
 * @return {string}
 */
proto.revenuerecognition.private.reports.ProductMonthReport.prototype.getDeferredRevenueFormattedAmount = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.revenuerecognition.private.reports.ProductMonthReport} returns this
 */
proto.revenuerecognition.private.reports.ProductMonthReport.prototype.setDeferredRevenueFormattedAmount = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


goog.object.extend(exports, proto.revenuerecognition.private.reports);
