import { makeActionCreator } from "../utils/reduxUtils";
import API from "../utils/API";
import { CreateStripeIntentGatewayRequest } from "../utils/grpc/generated/Billsby.Protos/billing/private/paymentgateway/payment_gateway_pb";
import { PaymentGatewayServiceClient } from "../utils/grpc/generated/Billsby.Protos/billing/private/paymentgateway/Payment_gatewayServiceClientPb";
import { ConfigConstants } from "../utils/config";
import { grpcUnaryCall } from "../utils/grpc/grpcUtils";

export const SET_PAYMENT_GATEWAYS_FIELD = "SET_PAYMENT_GATEWAYS_FIELD";
export const setPaymentGatewaysField = makeActionCreator(SET_PAYMENT_GATEWAYS_FIELD, "field", "payload");

export const RESET_PAYMENT_GATEWAYS_STATE = "RESET_CONFIGURATION_STATE";
export const resetPaymentGatewaysState = makeActionCreator(RESET_PAYMENT_GATEWAYS_STATE);

export const CANCEL_LAST_PAYMENT_GATEWAY = "CANCEL_LAST_PAYMENT_GATEWAY";
export const cancelLastPaymentGateway = makeActionCreator(CANCEL_LAST_PAYMENT_GATEWAY);

export const FETCH_PAYMENT_GATEWAYS_REQUEST = "FETCH_PAYMENT_GATEWAYS_REQUEST";
export const FETCH_PAYMENT_GATEWAYS_SUCCESS = "FETCH_PAYMENT_GATEWAYS_SUCCESS";
export const FETCH_PAYMENT_GATEWAYS_FAILURE = "FETCH_PAYMENT_GATEWAYS_FAILURE";

export const CREATE_PAYMENT_GATEWAY_REQUEST = "CREATE_PAYMENT_GATEWAY_REQUEST";
export const CREATE_PAYMENT_GATEWAY_SUCCESS = "CREATE_PAYMENT_GATEWAY_SUCCESS";
export const CREATE_PAYMENT_GATEWAY_FAILURE = "CREATE_PAYMENT_GATEWAY_FAILURE";

export const CREATE_STRIPE_PAYMENT_GATEWAY_REQUEST = "CREATE_STRIPE_PAYMENT_GATEWAY_REQUEST";
export const CREATE_STRIPE_PAYMENT_GATEWAY_SUCCESS = "CREATE_STRIPE_PAYMENT_GATEWAY_SUCCESS";
export const CREATE_STRIPE_PAYMENT_GATEWAY_FAILURE = "CREATE_STRIPE_PAYMENT_GATEWAY_FAILURE";

export const UPDATE_PAYMENT_GATEWAY_REQUEST = "UPDATE_PAYMENT_GATEWAY_REQUEST";
export const UPDATE_PAYMENT_GATEWAY_SUCCESS = "UPDATE_PAYMENT_GATEWAY_SUCCESS";
export const UPDATE_PAYMENT_GATEWAY_FAILURE = "UPDATE_PAYMENT_GATEWAY_FAILURE";

export const DELETE_PAYMENT_GATEWAY_REQUEST = "DELETE_PAYMENT_GATEWAY_REQUEST";
export const DELETE_PAYMENT_GATEWAY_SUCCESS = "DELETE_PAYMENT_GATEWAY_SUCCESS";
export const DELETE_PAYMENT_GATEWAY_FAILURE = "DELETE_PAYMENT_GATEWAY_FAILURE";

export const SAVE_PAYMENT_GATEWAY_STATE = "SAVE_PAYMENT_GATEWAY_STATE";
export const RESTORE_PAYMENT_GATEWAY_STATE = "RESTORE_PAYMENT_GATEWAY_STATE";


export const fetchPaymentGateways = (companyDomain: string) => {
  return {
    types: [FETCH_PAYMENT_GATEWAYS_REQUEST, FETCH_PAYMENT_GATEWAYS_SUCCESS, FETCH_PAYMENT_GATEWAYS_FAILURE],
    callAPI: () => API.getPaymentGateways(companyDomain)
  }
}

export const createPaymentGateway = (companyDomain: string, data: { authorizationCode: string, displayName: string }) => {
  return {
    types: [CREATE_PAYMENT_GATEWAY_REQUEST, CREATE_PAYMENT_GATEWAY_SUCCESS, CREATE_PAYMENT_GATEWAY_FAILURE],
    callAPI: () => API.createPaymentGateway(companyDomain, data)
  }
}

export const createStripeIntentGateway = (companyDomain: string, authCode: string, displayName: string) => {
  const request = new CreateStripeIntentGatewayRequest();
  const clientService = new PaymentGatewayServiceClient(ConfigConstants.grpcBaseUrl); 
  
  request.setCompanyDomain(companyDomain);
  request.setAuthorizationCode(authCode);
  request.setDisplayName(displayName);

  return {
    types: [CREATE_STRIPE_PAYMENT_GATEWAY_REQUEST, CREATE_STRIPE_PAYMENT_GATEWAY_SUCCESS, CREATE_STRIPE_PAYMENT_GATEWAY_FAILURE],
    callAPI: () => grpcUnaryCall(request, clientService, clientService.createStripeIntentGateway)
  }
}

export const updatePaymentGatewayName = (companyDomain: string, paymentGatewayId: number, displayName: string) => {
  return {
    types: [UPDATE_PAYMENT_GATEWAY_REQUEST, UPDATE_PAYMENT_GATEWAY_SUCCESS, UPDATE_PAYMENT_GATEWAY_FAILURE],
    callAPI: () => API.updatePaymentGateway(companyDomain, paymentGatewayId, displayName)
  }
}

export const deletePaymentGateway = (companyDomain: string, paymentGatewayId: number) => {
  return {
    types: [DELETE_PAYMENT_GATEWAY_REQUEST, DELETE_PAYMENT_GATEWAY_SUCCESS, DELETE_PAYMENT_GATEWAY_FAILURE],
    callAPI: () => API.deletePaymentGateway(companyDomain, paymentGatewayId)
  }
}