/**
 * @fileoverview gRPC-Web generated client stub for billing.private.company.exitreasonconfig
 * @enhanceable
 * @public
 */

// GENERATED CODE -- DO NOT EDIT!


import * as grpcWeb from "grpc-web";

import {
  GetExitReasonsConfigRequest,
  GetExitReasonsConfigResponse,
  UpsertExitReasonConfigRequest,
  UpsertExitReasonConfigResponse} from "./exit_reason_config_pb";

export class ExitReasonConfigServiceClient {
  client_: grpcWeb.AbstractClientBase;
  hostname_: string;
  credentials_: null | { [index: string]: string; };
  options_: null | { [index: string]: string; };

  constructor (hostname: string,
               credentials?: null | { [index: string]: string; },
               options?: null | { [index: string]: string; }) {
    if (!options) options = {};
    if (!credentials) credentials = {};
    options["format"] = "text";

    this.client_ = new grpcWeb.GrpcWebClientBase(options);
    this.hostname_ = hostname;
    this.credentials_ = credentials;
    this.options_ = options;
  }

  methodInfoGetExitReasonsConfig = new grpcWeb.AbstractClientBase.MethodInfo(
    GetExitReasonsConfigResponse,
    (request: GetExitReasonsConfigRequest) => {
      return request.serializeBinary();
    },
    GetExitReasonsConfigResponse.deserializeBinary
  );

  getExitReasonsConfig(
    request: GetExitReasonsConfigRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: GetExitReasonsConfigResponse) => void) {
    return this.client_.rpcCall(
      this.hostname_ +
        "/billing.private.company.exitreasonconfig.ExitReasonConfigService/GetExitReasonsConfig",
      request,
      metadata || {},
      this.methodInfoGetExitReasonsConfig,
      callback);
  }

  methodInfoUpsertExitReasonConfig = new grpcWeb.AbstractClientBase.MethodInfo(
    UpsertExitReasonConfigResponse,
    (request: UpsertExitReasonConfigRequest) => {
      return request.serializeBinary();
    },
    UpsertExitReasonConfigResponse.deserializeBinary
  );

  upsertExitReasonConfig(
    request: UpsertExitReasonConfigRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: UpsertExitReasonConfigResponse) => void) {
    return this.client_.rpcCall(
      this.hostname_ +
        "/billing.private.company.exitreasonconfig.ExitReasonConfigService/UpsertExitReasonConfig",
      request,
      metadata || {},
      this.methodInfoUpsertExitReasonConfig,
      callback);
  }

}

