import { Address } from "./Address";
import { InvoiceStatus, InvoicePaymentLogStatus, InvoiceType, StandAloneInvoiceType, CreditNoteType, InvoiceRefundType } from "./Invoices";
import { CreditNoteStatus } from "./CreditNotes";

type STATUSES = "SUCCESS" | "DECLINED" | "FAILED";

export enum CardStatus {
  MISSING = "Missing",
  VALID = "Valid",
  INVALID = "Invalid"
}

export interface BaseCustomer {
  firstName: string,
  lastName: string,
  email: string,
  phoneNumber: string,
  phoneNumberDialCode: string,
  phoneNumberDialCountry: string,
  companyName?: string
}

export interface INewCustomerDetails {
  firstName: string,
  lastName: string,
  email: string,
  addressLine1: string,
  addressLine2?: string,
  addressCity: string,
  addressPostCode: string,
  addressState: string,
  addressCountry: string,
  companyName?: string
}

export interface Customer extends BaseCustomer {
  customerUniqueId: string,
  billingAddress: Address,
}

export interface ICustomerCreditNoteData {
  invoiceId: number,
  subscriptionId: number,
  createdOn: string,
  invoiceNumber: string,
  amount: string,
  currency: string,
  linkedTransactionsCount: number,
  status: CreditNoteStatus,
  paymentGatewayDisplayName: string,
}

export interface ICustomerCreditNoteDetails {
  firstName: string;
  lastName: string;
  billingAddress: Address;
  customerUniqueId: string;
  creditNoteRef: string;
  creditNoteDate: string;
  creditAmount: string;
  companyName: string;
  amount: string;
  subtotalAmount: string;
  taxAmount: string;
  totalAmount: string;
  refundMethod?: string;
  description: string;
  status: CreditNoteStatus;
  companyPhone: string;
  companyEmail: string;
  companyTaxLabel: string;
  companyTaxNumber: string;
}

export interface IInvoiceDetails {
  invoiceId: number,
  invoiceTitle?: string,
  customerEmail: string,
  customerFirstName: string,
  customerLastName: string,
  customerUniqueId: string,
  customerTaxRegNumber: string,
  customerStatus: CustomerDataStatus,
  customerPaymentMethodTypeName: PaymentMethodType,
  addressLine1: string,
  addressLine2: string,
  addressCity: string,
  addressPostCode: string,
  addressState: string,
  addressCountryCode: string,
  addressCountry: string,
  invoiceNumber: string,
  createdOn: string,
  amount: number,
  formattedAmount: string,
  currency: string,
  status: InvoiceStatus,
  subTotal: string,
  taxTotal: string,
  taxes: Array<{ name: string, percentage: number }>
  companyDomain: string,
  taxPercentage: number,
  cardExpiryMonth: number,
  cardExpiryYear: number,
  cardLast4Digits: string,
  prorationAdjustment: string,
  prorationFactor?: number,
  supportUrl?: string,
  subscriptions: Array<{
    planName: string,
    planDisplayName: string | null,
    productName: string,
    productDisplayName: string | null,
    subscriptionUniqueId: string,
    billingPeriodStart: string,
    billingPeriodEnd: string,
    subscriptionItems: [
      {
        description: string,
        units: number,
        unitPrice: string,
        discounts: string,
        amount: string
      }
    ]
  }> | null,
  companyName: string,
  companyPhone: string,
  companyEmail: string,
  companyTaxLabel: string,
  companyTaxNumber: string,
  refundableAmount: number,
  brandingLogo: string,
  brandingLogoBackgroundColor: string,
  brandingHighlightColor: string,
  invoiceType: InvoiceType | StandAloneInvoiceType | CreditNoteType,
  cardType: string,
  companyCity: string
  companyCountry: string,
  companyAddressLine1: string,
  companyAddressLine2: string,
  companyPostCode: string,
  companyState: string,
  companyPhoneNumber: string,
  companyPhoneNumberDialCode: string,
  companyPhoneNumberDialCountry: string,
  supportContactContent: string,
  supportContactHeading: string,
  supportContactSupportUrl: string,
  supportContactSupportUrlButtonText: string,
  advertImageUrl: string,
  advertLinkToUrl: string,
  isReattemptable: boolean,
  customerCompanyName?: string,
  isRefundable: boolean,
  refundType: InvoiceRefundType,
  brandingFaviconUrl?: string
}

export interface ICreditNoteDetails {
  invoiceId: number,
  customerEmail: string,
  customerFirstName: string,
  customerLastName: string,
  customerUniqueId: string,
  addressLine1: string,
  addressLine2: string,
  addressCity: string,
  addressPostCode: string,
  addressState: string,
  addressCountry: string,
  invoiceNumber: string,
  createdOn: string,
  amount: string,
  currency: string,
  status: CreditNoteStatus,
  subTotal: string,
  taxTotal: string,
  formattedAmount: string,
  taxPercentage: number,
  description: string,
  companyName: string,
  companyPhone: string,
  companyEmail: string,
  companyTaxLabel: string,
  companyTaxNumber: string,
  brandingLogo: string,
  brandingLogoBackgroundColor: string,
  brandingHighlightColor: string,
  invoiceType: CreditNoteType,
  cardType: string,
  cardLast4Digits: string,
  companyCity: string,
  companyCountry: string,
  companyAddressLine1: string,
  companyAddressLine2: string,
  companyPostCode: string,
  companyState: string,
  companyPhoneNumber: string,
  companyPhoneNumberDialCode: string,
  companyPhoneNumberDialCountry: string,
  supportContactContent: string,
  supportContactHeading: string,
  supportContactSupportUrl: string,
  supportContactSupportUrlButtonText: string,
  advertImageUrl: string,
  advertLinkToUrl: string
}

export interface ICustomerInvoiceData {
  invoiceId: number,
  subscriptionId: number,
  createdOn: string,
  invoiceNumber: string,
  amount: number,
  formattedAmount: string,
  formattedTotalGross: string,
  currency: string,
  linkedTransactionsCount: number,
  status: InvoiceStatus,
  refundableAmount: number,
  isReattemptable: boolean,
  invoiceType: InvoiceType,
  isRefundable: boolean,
  refundType: InvoiceRefundType
}

export interface ICustomerInvoicePaymentData {
  paymentLogId: number,
  createdOn: string,
  gatewayId: number,
  gatewayName: string,
  reference: string,
  paymentMethod: string,
  status: string,
}

export interface ICustomerCardDetails {
  paymentCardToken: string | null,
  expiryMonth: number,
  expiryYear: number,
  cardType: string,
  last4Digits: string,
  fullName: string
}

export interface ICustomerCardDetailsSCA extends ICustomerCardDetails {
  browserInfo?: string,
  transactionToken?: string,
  redirectUrl?: string,
  callbackUrl?: string
}

export enum CardSCAStatus {
  Unassigned = "Unassigned",
  Pending  = "Pending",
  UpdateCompleted = "UpdateCompleted"
}

export interface ICustomerCardDetailsSCAResponse {
  status: CardSCAStatus,
  transactionToken: string
}

export interface INewCustomerDetailsWithCardDetails extends INewCustomerDetails {
  cardDetails: ICustomerCardDetails | null
}

export interface ICustomerPaymentDetailsForm {
  cardholderName: string,
  expiryMonth: string,
  expiryYear: string,
  errors: { cardNumber: string, expiryMonth: string, expiryYear: string } | null
}

export interface ICustomerInvoicePaymentLogData {
  paymentLogId: number;
  createdOn: string;
  gatewayId: number;
  gatewayName: string;
  reference: string;
  paymentMethod: string;
  status: InvoicePaymentLogStatus;
  paymentSourceType: PaymentMethodType;
}

export interface ICustomerPaymentDetailsError {
  cardNumber: string,
  expiryMonth: string,
  expiryYear: string
}

export enum CustomerDataStatus {
  INACTIVE = "InActive",
  GDPR_DELETED = "GdprDeleted",
  ACTIVE = "Active",
  DELETED = "Deleted"
}

export interface ICustomerNote {
  id?: number;
  subject: string;
  content: string;
}

export enum PaymentMethodType {
  CREDIT_CARD = "CreditCard",
  ACH = "Ach"
}