/**
 * @fileoverview gRPC-Web generated client stub for messenger.private.companyconfig
 * @enhanceable
 * @public
 */

// GENERATED CODE -- DO NOT EDIT!


import * as grpcWeb from "grpc-web";

import {
  GetAchMicroDepositEmailConfigRequest,
  GetAchMicroDepositEmailConfigResponse,
  GetCompanyAlertsAndNotificationRequest,
  GetCompanyAlertsAndNotificationResponse,
  GetEmailNotificationStatusRequest,
  GetEmailNotificationStatusResponse,
  GetEmailTemplateConfigRequest,
  GetEmailTemplateConfigResponse,
  UpdateAchMicroDepositEmailConfigRequest,
  UpdateAchMicroDepositEmailConfigResponse,
  UpdateCompanyAlertsAndNotificationRequest,
  UpdateCompanyAlertsAndNotificationResponse,
  UpdateEmailNotificationStatusRequest,
  UpdateEmailNotificationStatusResponse,
  UpsertEmailTemplateConfigRequest,
  UpsertEmailTemplateConfigResponse} from "./companyconfig_pb";

export class CompanyConfigServiceClient {
  client_: grpcWeb.AbstractClientBase;
  hostname_: string;
  credentials_: null | { [index: string]: string; };
  options_: null | { [index: string]: string; };

  constructor (hostname: string,
               credentials?: null | { [index: string]: string; },
               options?: null | { [index: string]: string; }) {
    if (!options) options = {};
    if (!credentials) credentials = {};
    options["format"] = "text";

    this.client_ = new grpcWeb.GrpcWebClientBase(options);
    this.hostname_ = hostname;
    this.credentials_ = credentials;
    this.options_ = options;
  }

  methodInfoUpsertEmailTemplateConfig = new grpcWeb.AbstractClientBase.MethodInfo(
    UpsertEmailTemplateConfigResponse,
    (request: UpsertEmailTemplateConfigRequest) => {
      return request.serializeBinary();
    },
    UpsertEmailTemplateConfigResponse.deserializeBinary
  );

  upsertEmailTemplateConfig(
    request: UpsertEmailTemplateConfigRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: UpsertEmailTemplateConfigResponse) => void) {
    return this.client_.rpcCall(
      this.hostname_ +
        "/messenger.private.companyconfig.CompanyConfigService/UpsertEmailTemplateConfig",
      request,
      metadata || {},
      this.methodInfoUpsertEmailTemplateConfig,
      callback);
  }

  methodInfoGetEmailTemplateConfig = new grpcWeb.AbstractClientBase.MethodInfo(
    GetEmailTemplateConfigResponse,
    (request: GetEmailTemplateConfigRequest) => {
      return request.serializeBinary();
    },
    GetEmailTemplateConfigResponse.deserializeBinary
  );

  getEmailTemplateConfig(
    request: GetEmailTemplateConfigRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: GetEmailTemplateConfigResponse) => void) {
    return this.client_.rpcCall(
      this.hostname_ +
        "/messenger.private.companyconfig.CompanyConfigService/GetEmailTemplateConfig",
      request,
      metadata || {},
      this.methodInfoGetEmailTemplateConfig,
      callback);
  }

  methodInfoGetEmailNotificationStatus = new grpcWeb.AbstractClientBase.MethodInfo(
    GetEmailNotificationStatusResponse,
    (request: GetEmailNotificationStatusRequest) => {
      return request.serializeBinary();
    },
    GetEmailNotificationStatusResponse.deserializeBinary
  );

  getEmailNotificationStatus(
    request: GetEmailNotificationStatusRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: GetEmailNotificationStatusResponse) => void) {
    return this.client_.rpcCall(
      this.hostname_ +
        "/messenger.private.companyconfig.CompanyConfigService/GetEmailNotificationStatus",
      request,
      metadata || {},
      this.methodInfoGetEmailNotificationStatus,
      callback);
  }

  methodInfoUpdateEmailNotificationStatus = new grpcWeb.AbstractClientBase.MethodInfo(
    UpdateEmailNotificationStatusResponse,
    (request: UpdateEmailNotificationStatusRequest) => {
      return request.serializeBinary();
    },
    UpdateEmailNotificationStatusResponse.deserializeBinary
  );

  updateEmailNotificationStatus(
    request: UpdateEmailNotificationStatusRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: UpdateEmailNotificationStatusResponse) => void) {
    return this.client_.rpcCall(
      this.hostname_ +
        "/messenger.private.companyconfig.CompanyConfigService/UpdateEmailNotificationStatus",
      request,
      metadata || {},
      this.methodInfoUpdateEmailNotificationStatus,
      callback);
  }

  methodInfoUpdateCompanyAlertsAndNotification = new grpcWeb.AbstractClientBase.MethodInfo(
    UpdateCompanyAlertsAndNotificationResponse,
    (request: UpdateCompanyAlertsAndNotificationRequest) => {
      return request.serializeBinary();
    },
    UpdateCompanyAlertsAndNotificationResponse.deserializeBinary
  );

  updateCompanyAlertsAndNotification(
    request: UpdateCompanyAlertsAndNotificationRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: UpdateCompanyAlertsAndNotificationResponse) => void) {
    return this.client_.rpcCall(
      this.hostname_ +
        "/messenger.private.companyconfig.CompanyConfigService/UpdateCompanyAlertsAndNotification",
      request,
      metadata || {},
      this.methodInfoUpdateCompanyAlertsAndNotification,
      callback);
  }

  methodInfoGetCompanyAlertsAndNotification = new grpcWeb.AbstractClientBase.MethodInfo(
    GetCompanyAlertsAndNotificationResponse,
    (request: GetCompanyAlertsAndNotificationRequest) => {
      return request.serializeBinary();
    },
    GetCompanyAlertsAndNotificationResponse.deserializeBinary
  );

  getCompanyAlertsAndNotification(
    request: GetCompanyAlertsAndNotificationRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: GetCompanyAlertsAndNotificationResponse) => void) {
    return this.client_.rpcCall(
      this.hostname_ +
        "/messenger.private.companyconfig.CompanyConfigService/GetCompanyAlertsAndNotification",
      request,
      metadata || {},
      this.methodInfoGetCompanyAlertsAndNotification,
      callback);
  }

  methodInfoGetAchMicroDepositEmailConfig = new grpcWeb.AbstractClientBase.MethodInfo(
    GetAchMicroDepositEmailConfigResponse,
    (request: GetAchMicroDepositEmailConfigRequest) => {
      return request.serializeBinary();
    },
    GetAchMicroDepositEmailConfigResponse.deserializeBinary
  );

  getAchMicroDepositEmailConfig(
    request: GetAchMicroDepositEmailConfigRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: GetAchMicroDepositEmailConfigResponse) => void) {
    return this.client_.rpcCall(
      this.hostname_ +
        "/messenger.private.companyconfig.CompanyConfigService/GetAchMicroDepositEmailConfig",
      request,
      metadata || {},
      this.methodInfoGetAchMicroDepositEmailConfig,
      callback);
  }

  methodInfoUpdateAchMicroDepositEmailConfig = new grpcWeb.AbstractClientBase.MethodInfo(
    UpdateAchMicroDepositEmailConfigResponse,
    (request: UpdateAchMicroDepositEmailConfigRequest) => {
      return request.serializeBinary();
    },
    UpdateAchMicroDepositEmailConfigResponse.deserializeBinary
  );

  updateAchMicroDepositEmailConfig(
    request: UpdateAchMicroDepositEmailConfigRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: UpdateAchMicroDepositEmailConfigResponse) => void) {
    return this.client_.rpcCall(
      this.hostname_ +
        "/messenger.private.companyconfig.CompanyConfigService/UpdateAchMicroDepositEmailConfig",
      request,
      metadata || {},
      this.methodInfoUpdateAchMicroDepositEmailConfig,
      callback);
  }

}

