import React, { useState } from "react";
import { setPaymentGatewaysField, fetchPaymentGateways } from "../../../../../actions/paymentGatewaysActions";
import { CreateCloverConnectRequest, CreateCloverConnectResponse, ReplaceGatewayToCloverConnectRequest } from "../../../../../utils/grpc/generated/Billsby.Protos/billing/private/paymentgateway/payment_gateway_pb";
import { PaymentGatewayServiceClient } from "../../../../../utils/grpc/generated/Billsby.Protos/billing/private/paymentgateway/Payment_gatewayServiceClientPb";
import { grpcUnaryCall } from "../../../../../utils/grpc/grpcUtils";
import { ConfigConstants } from "../../../../../utils/config";
import CloverConnectGatewayForm from "./CloverConnectGatewayForm";
import PaymentGatewayContainer from "../payment-gateways-shared-components/PaymentGatewayContainer";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../../../../..";
import { IPaymentGatewaysReducer } from "../../../../../reducers/paymentGatewaysReducer";
import { IAuthReducerState } from "../../../../../reducers/authReducer";

interface Props {
  onError: () => void;
}

const CloverConnectPaymentGateway: React.FC<Props> = ({ onError }) => {
  const { selectedPaymentGateway, isCreatingGateway, replacedPaymentGateway } = useSelector<AppState, IPaymentGatewaysReducer>(state => state.paymentGatewaysReducer);
  const { currentCompanyDomain } = useSelector<AppState, IAuthReducerState>(state => state.auth);
  const dispatch = useDispatch<Function>()

  const [helpTitle, setHelpTitle] = useState("PAYMENT_GATEWAYS_ADDED_CLOVER_CONNECT_HELP");
  const [helpContent, setHelpContent] = useState(["PAYMENT_GATEWAYS_ADDED_CLOVER_CONNECT_HELP_CONTENT_1", "PAYMENT_GATEWAYS_ADDED_CLOVER_CONNECT_HELP_CONTENT_2"]);

  if (!selectedPaymentGateway) {
    return null;
  }

  const addNewPaymentGateway = async (displayName: string, userName: string, password: string, merchantAccount: string) => {
    const createCloverConnectRequest = new CreateCloverConnectRequest();
    createCloverConnectRequest.setCompanyDomain(currentCompanyDomain);
    createCloverConnectRequest.setDisplayName(displayName);
    createCloverConnectRequest.setLogin(userName);
    createCloverConnectRequest.setPassword(password);
    createCloverConnectRequest.setMerchantId(merchantAccount);
    const paymentGatewayServiceClient = new PaymentGatewayServiceClient(ConfigConstants.grpcBaseUrl);
    
    let request: Promise<any>
    if(replacedPaymentGateway) {
      const replaceGatewayToCloverConnectRequest = new ReplaceGatewayToCloverConnectRequest()
      replaceGatewayToCloverConnectRequest.setCreateCloverConnectRequest(createCloverConnectRequest);
      replaceGatewayToCloverConnectRequest.setPaymentGatewayToReplaceId(replacedPaymentGateway.paymentGatewayId as number);
      request = grpcUnaryCall(replaceGatewayToCloverConnectRequest, paymentGatewayServiceClient, paymentGatewayServiceClient.replaceGatewayToCloverConnect)
    }
    else {
      request = grpcUnaryCall(createCloverConnectRequest, paymentGatewayServiceClient, paymentGatewayServiceClient.createCloverConnectGateway)
    }

    try {
      const data = await request as CreateCloverConnectResponse;
      dispatch(setPaymentGatewaysField("createdPaymentGatewayId", data.getPaymentGatewayId()));
      dispatch(setPaymentGatewaysField("selectedPaymentGateway", undefined));
      dispatch(setPaymentGatewaysField("replacedPaymentGateway", undefined));
      await dispatch(fetchPaymentGateways(currentCompanyDomain));
    } catch {
      onError();
    }
  }

  return (    
    <PaymentGatewayContainer
      helpTitle={helpTitle}
      helpContent={helpContent}
      description="PAYMENT_GATEWAYS_CLOVER_CONNECT_DESCRIPTION"
      isOneGatewayPending={false}
      currentGatewayState={{ isCollapsed: false }}
      websiteLink="https://integrate.clover.com/partner/billsby"
      type="create"
      paymentGateway={selectedPaymentGateway}
    >
      <CloverConnectGatewayForm
        onSave={(displayName: string, userName: string, password: string, merchantAccount: string) => addNewPaymentGateway(displayName, userName, password, merchantAccount)}
        onCancel={() => {
          dispatch(setPaymentGatewaysField("selectedPaymentGateway", undefined));
          dispatch(setPaymentGatewaysField("replacedPaymentGateway", undefined));
        }}
        setHelpTitle={(text: string) => setHelpTitle(text)}
        setHelpContent={(text: Array<string>) => setHelpContent(text)}
        isSaveEnabled={!isCreatingGateway}
        isEdit={false}
        initDisplayName=""
        initUserName=""
        initMerchantAccount=""
        initPassword=""
      />
    </PaymentGatewayContainer>
  )
}

export default CloverConnectPaymentGateway