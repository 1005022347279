import React from "react";
import { useDispatch, useSelector } from "react-redux";
import Text from "../../components/ui/text/Text";
import Button from "../../components/ui/button/Button";
import "./ProPlanFeatures.scss";
import Panel from "../../components/ui/panel/Panel";
import { AppState } from "../..";
import { useHistory } from "react-router";
import { setShowBillingModal } from "../../actions/authActions";

interface IProPlanFeatures {
  historyState?: object
}

const ProPlanFeatures: React.FC<IProPlanFeatures> = ({ historyState }) => {
  const featuresList = useSelector((state: AppState) => state.goLiveReducer.featuresList);
  const dispatch = useDispatch<Function>()
  const history = useHistory();

  if(!featuresList.length) {
    return null
  }

  const renderFeatureLists = () => {
    return featuresList?.filter(i => i.isUsed).map((i, idx) => {
      return (
        <Panel className="pro-plan-feature__panel" key={i.title}>
          <div>
            <Text className="pro-plan-feature__panel__title" content={i.title} noMargin />
            <Text content={i.subTitle} noMargin />
          </div>
          <div>
            <Button buttonType="add" isFullWidth id={`feature-${idx}`} icon="fas fa-tools" title="GO_LIVE_CHECKLIST_FIXTHIS"
              onClick={() => {
                history.push({ pathname: i.pathname, state: historyState })
                dispatch(setShowBillingModal(false))
              }}
            />
          </div>
        </Panel>
      )
    })
  }

  return (
    <div className="pro-plan-feature">
      <Panel className="pro-plan-feature__titlepanel" title="GO_LIVE_PRO_PLAN_FEATURE_TITLE">
        <Text content="GO_LIVE_PRO_PLAN_FEATURE_CONTENT" noMargin />
      </Panel>

      {renderFeatureLists()}
    </div>
  );
};

export default ProPlanFeatures;
