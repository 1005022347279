import API from "../utils/API";
import { makeActionCreator, makeGenericActionCreatorTypeSafe } from "../utils/reduxUtils";
import { ICompanyBranding } from "../models/CompanyBranding";
import { ICompanyBrandingReducerState } from "../reducers/companyBrandingReducer";

export const FETCH_COMPANY_BRANDING_REQUEST = "FETCH_COMPANY_BRANDING_REQUEST";
export const FETCH_COMPANY_BRANDING_SUCCESS = "FETCH_COMPANY_BRANDING_SUCCESS";
export const FETCH_COMPANY_BRANDING_FAILURE = "FETCH_COMPANY_BRANDING_FAILURE";

export const UPDATE_COMPANY_BRANDING_REQUEST = "UPDATE_COMPANY_BRANDING_REQUEST";
export const UPDATE_COMPANY_BRANDING_SUCCESS = "UPDATE_COMPANY_BRANDING_SUCCESS";
export const UPDATE_COMPANY_BRANDING_FAILURE = "UPDATE_COMPANY_BRANDING_FAILURE";

export const UPDATE_COMPANY_BRANDING_LOGO_REQUEST = "UPDATE_COMPANY_BRANDING_LOGO_REQUEST";
export const UPDATE_COMPANY_BRANDING_LOGO_SUCCESS = "UPDATE_COMPANY_BRANDING_LOGO_SUCCESS";
export const UPDATE_COMPANY_BRANDING_LOGO_FAILURE = "UPDATE_COMPANY_BRANDING_LOGO_FAILURE";

export const UPDATE_COMPANY_BRANDING_FAVICON_REQUEST = "UPDATE_COMPANY_BRANDING_FAVICON_REQUEST";
export const UPDATE_COMPANY_BRANDING_FAVICON_SUCCESS = "UPDATE_COMPANY_BRANDING_FAVICON_SUCCESS";
export const UPDATE_COMPANY_BRANDING_FAVICON_FAILURE = "UPDATE_COMPANY_BRANDING_FAVICON_FAILURE";

export const FETCH_GOOGLE_FONTS_REQUEST = "FETCH_GOOGLE_FONTS_REQUEST"; 
export const FETCH_GOOGLE_FONTS_FAILURE = "FETCH_GOOGLE_FONTS_FAILURE"; 
export const FETCH_GOOGLE_FONTS_SUCCESS = "FETCH_GOOGLE_FONTS_SUCCESS"; 

export const SET_COMPANY_BRANDING_LOGO_BACKGROUND_COLOR = "SET_COMPANY_BRANDING_LOGO_BACKGROUND_COLOR";
export const setCompanyBrandingLogoBackgroundColor = makeActionCreator(SET_COMPANY_BRANDING_LOGO_BACKGROUND_COLOR, "payload");

export const SET_COMPANY_BRANDING_HIGHLIGHT_COLOR = "SET_COMPANY_BRANDING_HIGHLIGHT_COLOR";
export const setCompanyBrandingHighLightColor = makeActionCreator(SET_COMPANY_BRANDING_HIGHLIGHT_COLOR, "payload");

export const RESET_COMPANY_BRANDING = "RESET_COMPANY_BRANDING";
export const resetCompanyBranding = makeActionCreator(RESET_COMPANY_BRANDING);

export const SET_COMPANY_BRANDING_FIELDS = "SET_COMPANY_BRANDING_FIELDS";
export const setCompanyBrandingFields = makeGenericActionCreatorTypeSafe<ICompanyBrandingReducerState>(SET_COMPANY_BRANDING_FIELDS);

export const fetchCompanyBranding = function (companyDomain: string) {
  return {
    types: [FETCH_COMPANY_BRANDING_REQUEST, FETCH_COMPANY_BRANDING_SUCCESS, FETCH_COMPANY_BRANDING_FAILURE],
    callAPI: () => API.getCompanyBranding(companyDomain)
  }
}

export const updateCompanyBranding = function (companyDomain: string, companyBranding: ICompanyBranding) {
  return {
    types: [UPDATE_COMPANY_BRANDING_REQUEST, UPDATE_COMPANY_BRANDING_SUCCESS, UPDATE_COMPANY_BRANDING_FAILURE],
    callAPI: () => API.updateCompanyBranding(companyDomain, companyBranding)
  }
}

export const updateCompanyBrandingLogo = function (companyDomain: string, file: File) {
  return {
    types: [UPDATE_COMPANY_BRANDING_LOGO_REQUEST, UPDATE_COMPANY_BRANDING_LOGO_SUCCESS, UPDATE_COMPANY_BRANDING_LOGO_FAILURE],
    callAPI: () => API.uploadCompanyImage(companyDomain, file)
  }
}

export const updateCompanyBrandingFavicon = function (companyDomain: string, file: File) {
  return {
    types: [UPDATE_COMPANY_BRANDING_FAVICON_REQUEST, UPDATE_COMPANY_BRANDING_FAVICON_SUCCESS, UPDATE_COMPANY_BRANDING_FAVICON_FAILURE],
    callAPI: () => API.uploadCompanyImage(companyDomain, file)
  }
}

export const fetchGoogleFonts = function () {
  return {
    types: [FETCH_GOOGLE_FONTS_REQUEST, FETCH_GOOGLE_FONTS_SUCCESS, FETCH_GOOGLE_FONTS_FAILURE],
    callAPI: () => API.fetchGoogleFonts()
  }
}