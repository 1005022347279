import React, { useState } from "react"
import MenuPanel from "../../ui/menu-panel/MenuPanel";
import StepCollapsablePanel from "../../ui/step-collapsable-panel/StepCollapsablePanel";
import Text from "../../ui/text/Text";
import FormGroup from "../../ui/form-group/FormGroup";
import { Row, Col } from "react-grid-system";
import FormLabel from "../../ui/form-label/FormLabel";
import Input from "../../ui/input/Input";
import Button from "../../ui/button/Button";

interface ICappedPriceModel {
  nrUnits?: number,
  configure: (nrUnits: number) => void,
  cancel: () => void,
}

const CappedPriceModel: React.FC<ICappedPriceModel> = React.memo(({ nrUnits = "", configure, cancel }) => {
  const [includedUnits, setIncludedUnits] = useState(`${nrUnits}`);
  const [helpTitle, setHelpTitle] = useState("CREATE_ALLOWANCES_PRICE_MODELS_INCLUDEDUNITS_HELP_TITLE");
  const [helpContent, setHelpContent] = useState(["CREATE_ALLOWANCES_PRICE_MODELS_INCLUDEDUNITS_CAPPED_HELP_CONTENT"]);

  return (
    <MenuPanel className="step-addon-cost__configurePanel">
      <MenuPanel.Content>
        <StepCollapsablePanel
          title="CREATE_ALLOWANCES_PRICE_MODELS_CONFIGURE_UNITS_LABEL"
          isCompleted={false}
          hasError={false}
          isLocked={false}
          hasHelpSection={true}
          isCollapsed={false}
          helpMenuPosition="right"
          helpMenuTitle={helpTitle}
          helpMenuContent={
            <>
              {helpContent.map((el, id) => <Text key={id} content={el} />)}
            </>
          }
          onClick={() => { }}
        >
          <FormGroup>
            <Row align="center" className="step-addon-cost__configurePanel-includedunits">
              <Col xs={2}>
                <FormLabel target="units-input" content="CREATE_ALLOWANCES_PRICE_MODELS_CONFIGURE_INCLUDEDUNITS_LABEL" />
              </Col>
              <Col xs={10}>
                <Input
                  id="unit-input"
                  value={includedUnits}
                  type="number"
                  min={0}
                  onChange={evt => Number.isInteger(+evt.target.value) && setIncludedUnits(evt.target.value)}
                />
              </Col>
            </Row>
          </FormGroup>
          <Row align="center">
            <Col xs={8}>
              <Button
                id="configure-price"
                title="CREATE_ALLOWANCES_PRICE_MODELS_CONFIGURE_BTN_LABEL"
                disabled={!(+includedUnits)}
                onClick={() => configure(+includedUnits)}
                isFullWidth
              />
            </Col>
            <Col xs={4}>
              <Button
                id="cancel"
                title="CREATE_ALLOWANCES_PRICE_MODELS_CONFIGURE_CANCEL_BTN_LABEL"
                onClick={cancel}
                buttonType="error"
                isFullWidth
              />
            </Col>
          </Row>
        </StepCollapsablePanel>
      </MenuPanel.Content>
    </MenuPanel>
  )
})

export default CappedPriceModel;
