import React from "react"
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../../..";
import { IAddOnCostReducer } from "../../../reducers/add-ons/addOnCostReducer";
import { Row, Col } from "react-grid-system";
import FormLabel from "../../ui/form-label/FormLabel";
import Input from "../../ui/input/Input";
import counterpart from "counterpart";
import { setCreateDiscountReducerFields, setMonetaryDiscount } from "../../../actions/discount-coupon/createDiscountActions";
import { ICreateDiscountState } from "../../../reducers/discount-coupons/createDiscountReducer";
import { allowZeroAndPositiveNumbers } from "../../../utils/commonUtils";
import { Currency } from "../../../utils/grpc/generated/Billsby.Protos/core/private/discounts/discounts_pb";


const CurrencyInput: React.FC = () => {

  const { monetaries, selectedCurrency, currenciesOfPlans } = useSelector<AppState, ICreateDiscountState>(state => state.createDiscountReducer)
  const addOnCostReducer = useSelector<AppState, IAddOnCostReducer>(state => state.addOnCostReducer)
  const dispatch = useDispatch<Function>()

  const getCurrencySymbol = (currency?: Currency) => {
    if (!currency)
      return "";

    if (!currency.getSymbol())
      return "";

    return currency.getSymbol().replace("_", "").trim();
  }

  const getCurrency = () => {
    if (selectedCurrency)
      return selectedCurrency.getIso3Code();

    return currenciesOfPlans && currenciesOfPlans.getCurrenciesList().length > 0 ?
      currenciesOfPlans.getCurrenciesList()
        .sort((a, b) => {
          const x = a.getIso3Code();
          const y = b.getIso3Code();
          return x < y ? -1 : x > y ? 1 : 0;
        })[0].getIso3Code() : "";
  }

  return (
    <div>
      <Row align="center">
        <Col md={1}>
          <FormLabel target="monetary" content="CREATE_DISCOUNT_FORM_DISCOUNT_DEDUCTION_FROM_MONETARY" noMargin />
        </Col>
        <Col md={1}>
          <FormLabel className="currency-input-tab__symbol" target="monetary" content={getCurrencySymbol(selectedCurrency)} shouldTranslate={false} noMargin />
        </Col>
        <Col md={10}>
          <Input
            id="monetary"
            required
            type="number"
            value={
              monetaries.filter(i => i.currencyCode === getCurrency()).length > 0 ?
              monetaries.filter(i => i.currencyCode === getCurrency())[0].value : ""}
            placeholder={counterpart("CREATE_DISCOUNT_FORM_DISCOUNT_DEDUCTION_FROM_MONETARY_PLACEHOLDER")}
            onChange={(evt: any) => dispatch(setMonetaryDiscount(getCurrency(), allowZeroAndPositiveNumbers(evt.target.value)))}
            onFocus={() => {
              dispatch(setCreateDiscountReducerFields("helpTextTitleBasicForm", "CREATE_DISCOUNT_FORM_NAME_HELP_TITLE"));
              dispatch(setCreateDiscountReducerFields("helpTextContentBasicForm", ["CREATE_DISCOUNT_FORM_NAME_HELP_TEXT_1"]));
            }}
          />
        </Col>
      </Row>
    </div>
  )
}

export default CurrencyInput
