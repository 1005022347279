import React, { useLayoutEffect, useMemo, useState } from "react";
import "./Currencies.scss";
import Panel from "../../../ui/panel/Panel";
import Text from "../../../ui/text/Text";
import FormGroup from "../../../ui/form-group/FormGroup";
import Button from "../../../ui/button/Button";
import Dropdown from "../../../ui/dropdown/Dropdown";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../../../..";
import history from "../../../../utils/history";
import NavigationBar from "../../../ui/navigation-bar/NavigationBar";
import PageWrapper from "../../../ui/layout/PageWrapper";
import { IAdditionalCurrency } from "../../../../reducers/currenciesReducer";
import { updateBaseCurrencyPaymentGateway, fetchCurrenciesMapping, fetchProductsCurrencies, updateCurrencyGatewayMapping, setCurrenciesField, resetCurrenciesState } from "../../../../actions/currenciesActions";
import { fetchPaymentGateways } from "../../../../actions/paymentGatewaysActions";
import { fetchGettingStartedSettings } from "../../../../actions/authActions";
import ProgressIndicator from "../../../ui/progress-indicator/ProgressIndicator";
import NoticePanel from "../../../ui/notice-panel/NoticePanel";
import { fetchAchPaymentConfig } from "../../../../actions/achSettingsActions";
import { Permission } from "../../../../models/Auth";

const Currencies: React.FC = () => {
  const currenciesReducer = useSelector((state: AppState) => state.currenciesReducer);
  const paymentGatewayReducer = useSelector((state: AppState) => state.paymentGatewaysReducer);
  const authReducer = useSelector((state: AppState) => state.auth);

  const dispatch = useDispatch<Function>();

  const { selectedBaseCurrencyGateway, additionalCurrencies, isUpdatingBaseCurrencyGateway, isUpdatingCurrencyGatewaysMapping, linkFromSca } = currenciesReducer;
  const { paymentGatewaysDropdown } = paymentGatewayReducer;
  const { currentCompanyCurrency, currentCompanyDomain, currentCompanyId, parsedToken } = authReducer;
  const [isLoadingData, setIsLoadingData] = useState(false);
  const defaultSelectedGateway = { label: "-", value: { paymentGatewayId: null } };


  const creditCardDropdown = useMemo(() => {
    return paymentGatewaysDropdown ? [defaultSelectedGateway, ...paymentGatewaysDropdown] : []
  }, [paymentGatewaysDropdown]);

  const achDropdown = useMemo(() => {
    return paymentGatewaysDropdown ? [defaultSelectedGateway, ...paymentGatewaysDropdown.filter(gateway => gateway.value.isAchSupported)] : []
  }, [paymentGatewaysDropdown])

  const hasPermissionACH = useMemo(() => {
    if(parsedToken?.CurrentPermissions.some(p => p === Permission[Permission.AchPaymentProcessorRead])) {
      return true
    }
    return false
  }, [parsedToken])

  const fetchData = async (addLoading: boolean = false) => {
    addLoading && setIsLoadingData(true);
    try {
      await dispatch(fetchAchPaymentConfig(currentCompanyId as number));
      await dispatch(fetchCurrenciesMapping(currentCompanyDomain));
      await dispatch(fetchPaymentGateways(currentCompanyDomain));
      await dispatch(fetchProductsCurrencies(currentCompanyId));
      addLoading && setIsLoadingData(false);

    } catch (err) {
      addLoading && setIsLoadingData(false);
    }
  }

  useLayoutEffect(() => {

    fetchData(true)

    // dispatch(fetchCurrenciesMapping(currentCompanyDomain))
    //   .then(() => dispatch(fetchPaymentGateways(currentCompanyDomain)))
    //   .then(() => dispatch(fetchProductsCurrencies(currentCompanyId)));

    return () => {
      dispatch(resetCurrenciesState());
    };
  }, []);

  if (isLoadingData) {
    return <ProgressIndicator color="blue" coverage="full-content"></ProgressIndicator>
  }

  if (!paymentGatewaysDropdown || !additionalCurrencies) {
    return null;
  }

  const renderCurrencyDropdown = (currency: IAdditionalCurrency, key: number) => {
    return (
      <FormGroup key={key} className="currencies__base__content">
        <Text content={currency.currencyCode} shouldTranslate={false} noMargin />
        {currency.isAchSupported && <Text className='label__cc' content="CURRENCIES_CREDIT_CARD" noMargin />}
        <Dropdown
          id="cc-additional-payment-gateways-list"
          value={currency.creditCardPaymentGateway}
          options={creditCardDropdown}
          onChange={(selectedGateway: any) => {
            let newMapping = [...additionalCurrencies]
            newMapping.splice(key, 1, { ...currency, currencyCode: currency.currencyCode, creditCardPaymentGateway: selectedGateway });
            dispatch(setCurrenciesField("additionalCurrencies", newMapping));
          }}
        />
        {currency.isAchSupported && hasPermissionACH && <>
          <Text className='label__ach' content="CURRENCIES_ACH" noMargin />
          <Dropdown
            id="ach-additional-payment-gateways-list"
            value={currency.achPaymentGateway}
            isDisabled={currency.isAchUsed}
            options={achDropdown}
            onChange={(selectedGateway: any) => {
              let newMapping = [...additionalCurrencies]
              newMapping.splice(key, 1, { ...currency, currencyCode: currency.currencyCode, achPaymentGateway: selectedGateway });
              dispatch(setCurrenciesField("additionalCurrencies", newMapping));
            }} />
        </>}

      </FormGroup>
    )
  }

  return (
    <div className="currencies">
      <NavigationBar
        pageTitle=""
        previousPageTitle={linkFromSca ? "CONFIGURATION_SCA_TITLE" : "CONFIGURATION_PAGE_TITLE"}
        previousPageCb={() => linkFromSca ? history.push("/configuration/sca") : history.push("/configuration")}
      />

      <PageWrapper>
        <Panel title="CURRENCIES_HEADING_TITLE" className="currencies__heading">
          <Text content="CURRENCIES_HEADING_CONTENT" noMargin />
        </Panel>
        <Panel title="CURRENCIES_BASE_TITLE" className="currencies__base">
          { selectedBaseCurrencyGateway?.isAchSupported && currentCompanyCurrency === "USD" && <NoticePanel type="warning" className="currencies__banner-ach" content="CURRENCIES_ACH_WARNING" /> }
          <Text content="CURRENCIES_BASE_CONTENT" />
          <FormGroup>
            <div className="currencies__base__content">
              <Text content={currentCompanyCurrency} shouldTranslate={false} noMargin />
              {selectedBaseCurrencyGateway?.isAchSupported && <Text className='label__cc' content="CURRENCIES_CREDIT_CARD" noMargin />}
              <Dropdown
                id="cc-payment-gateways-list"
                value={selectedBaseCurrencyGateway?.creditCardPaymentGateway}
                options={creditCardDropdown}
                onChange={(selectedGateway: any) => dispatch(setCurrenciesField("selectedBaseCurrencyGateway", { ...selectedBaseCurrencyGateway, creditCardPaymentGateway: selectedGateway }))}
              />
              {selectedBaseCurrencyGateway?.isAchSupported && hasPermissionACH && <>
                <Text className='label__ach' content="CURRENCIES_ACH" noMargin />
                <Dropdown
                  id="ach-payment-gateways-list"
                  isDisabled={selectedBaseCurrencyGateway.isAchUsed}
                  value={selectedBaseCurrencyGateway?.achPaymentGateway}
                  options={achDropdown}
                  onChange={(selectedGateway: any) => {
                    dispatch(setCurrenciesField("selectedBaseCurrencyGateway", { ...selectedBaseCurrencyGateway, achPaymentGateway: selectedGateway }))
                  }}
                />
              </>}
            </div>
          </FormGroup>

          <Button
            id="save-gateway-link"
            title="CURRENCIES_BASE_SAVE_GATEWAY_LINK"
            isLoading={isUpdatingBaseCurrencyGateway}
            disabled={isUpdatingBaseCurrencyGateway}
            onClick={() => {
              if (selectedBaseCurrencyGateway) {
                dispatch(updateBaseCurrencyPaymentGateway(
                  currentCompanyDomain,
                  currentCompanyCurrency,
                  selectedBaseCurrencyGateway.creditCardPaymentGateway?.value?.paymentGatewayId || null,
                  selectedBaseCurrencyGateway.achPaymentGateway?.value?.paymentGatewayId || null
                ))
                  .then(() => dispatch(fetchCurrenciesMapping(currentCompanyDomain)))
                  .then(() => dispatch(fetchProductsCurrencies(currentCompanyId)))
                  .then(() => dispatch(fetchGettingStartedSettings(currentCompanyDomain)));
              }
            }}
          />

          <Button
            id="cancel-gateway-link"
            title="CURRENCIES_BASE_CANCEL"
            buttonType="error"
            onClick={() => fetchData()}
          />

        </Panel>

        <Panel title="CURRENCIES_ADDITIONAL_TITLE" className={additionalCurrencies.length ? "currencies__additional" : "currencies__additional currencies__additional--background"}>
          { additionalCurrencies.some(c => c.currencyCode === "USD" && c.isAchSupported) && <NoticePanel className="currencies__banner-ach" type="warning" content="CURRENCIES_ACH_WARNING" /> }
          <Text content="CURRENCIES_ADDITIONAL_CONTENT" noMargin={!additionalCurrencies.length} />
          {
            additionalCurrencies.length
              ? (
                additionalCurrencies.map((currencyMapping, idx) => {
                  return (
                    renderCurrencyDropdown(currencyMapping, idx)
                  )
                })
              )
              : (
                <div className="currencies__additional-text">
                  <Text content="CURRENCIES_FOOTER_TITLE" />
                  <Text content="CURRENCIES_FOOTER_CONTENT" noMargin />
                </div>
              )
          }

          {
            additionalCurrencies.length
              ? (
                <div>
                  <Button
                    id="save-gateway-links"
                    title="CURRENCIES_BASE_SAVE_GATEWAY_LINKS"
                    isLoading={isUpdatingCurrencyGatewaysMapping}
                    disabled={isUpdatingCurrencyGatewaysMapping}
                    onClick={() => {
                      dispatch(updateCurrencyGatewayMapping(currentCompanyDomain,
                        additionalCurrencies
                          .map(mapping => ({
                            paymentGatewayId: (mapping.creditCardPaymentGateway ? mapping.creditCardPaymentGateway.value : {} as any).paymentGatewayId,
                            achPaymentGatewayId: mapping.achPaymentGateway?.value ? mapping.achPaymentGateway?.value.paymentGatewayId : null,
                            currencyCode: mapping.currencyCode
                          }))))
                        .then(() => dispatch(fetchCurrenciesMapping(currentCompanyDomain)))
                        .then(() => dispatch(fetchProductsCurrencies(currentCompanyId)))
                        .then(() => dispatch(fetchGettingStartedSettings(currentCompanyDomain)));
                    }}
                  />

                  <Button
                    id="cancel-gateway-links"
                    title="CURRENCIES_BASE_CANCEL"
                    buttonType="error"
                    onClick={() => fetchData()}
                  />
                </div>
              )
              : null
          }
        </Panel>

      
      </PageWrapper>

    </div>
  )
}

export default Currencies;