import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import "./style/fonts/chantal_medium.ttf";
import App from "./containers/App";
import * as serviceWorker from "./serviceWorker";
import rootReducer from "./reducers";
import { createStore, applyMiddleware, compose } from "redux";
import { Provider } from "react-redux";
import thunk from "redux-thunk";
import callAPIMiddleware from "./middlewares/callApiMiddleware";
import { registerTranslations } from "./utils/languages/registerTranslations";
import { injectTrackingScripts, appendFontPolyfillOldBrowser } from "./utils/injectScripts";
import { initAuthData, initReadmeioLinks } from "./actions/authActions";
import { customPolyfill } from "./utils/customPolifylls";
import { IRegistrationReducerState } from "./reducers/registrationReducer";
import { ILoginReducerState } from "./reducers/loginReducer";
import { IAuthReducerState } from "./reducers/authReducer";
import { IProductPlanCycleReducerState } from "./reducers/productPlanCycleReducer";
import { IProductReducerState } from "./reducers/productsReducer";
import { IPlanReducer } from "./reducers/plansReducer";
import { ICycleReducer } from "./reducers/cyclesReducer";
import { ICustomFieldsReducer } from "./reducers/customFieldsReducer";
import { IPaymentGatewaysReducer } from "./reducers/paymentGatewaysReducer";
import { addNoScriptElementDynamically, addScriptDynamically, checkBillsbyOneSessionLogin } from "./utils/commonUtils";
import { IGoLiveReducer } from "./reducers/goLiveReducer";
import { ICompanyApiKeyReducerState } from "./reducers/companyApiKeyReducer";
import { ICurrenciesReducer } from "./reducers/currenciesReducer";
import { ICustomersReducer } from "./reducers/customersReducer";
import { ICustomerDetailsReducerState } from "./reducers/customerDetailsReducer";
import { ICompanyBrandingReducerState } from "./reducers/companyBrandingReducer";
import { ICompanyTeammatesReducerState } from "./reducers/companyTeammatesReducer";
import { ISubscriptionsReducer } from "./reducers/subscriptionsReducer";
import { ISubscriptionDetailsReducerState } from "./reducers/subscriptionDetailsReducer";
import { ICustomerSubscriptionsReducerState } from "./reducers/customerSubscriptionsReducer";
import { IInvoicesReducer } from "./reducers/invoicesReducer";
import { IEventLogsReducerState } from "./reducers/eventLogsReducer";
import { ISubscriptionFeatureTagsState } from "./reducers/subscriptionFeatureTagsReducer";
import { ICreditNotesReducerState } from "./reducers/creditNotesReducer";
import { IEmbedCodesCheckOutReducerState } from "./reducers/embedCodesCheckOutReducer";
import { ICompanyWebhooksReducerState } from "./reducers/companyWebhooksReducer";
import { ICreditNoteDetailsReducerState } from "./reducers/creditNoteDetailsReducer";
import { IInvoiceDetailsReducerState } from "./reducers/invoiceDetailsReducer";
import { ICompanyTaxRatesReducerState } from "./reducers/companyTaxRatesReducer";
import { ICompanyDetailsReducerState } from "./reducers/companyDetailsReducer";
import { IEmailRoutingReducerState } from "./reducers/emailRoutingReducer";
import { IDunningFailedProcessState } from "./reducers/dunningFailedProcessReducer";
import { IDashboardReducer } from "./reducers/dashboardReducer";
import { IInvoicesCreditNotesCustomizationState } from "./reducers/invoicesCreditNotesCustomizationReducer";
import { IEmailCustomizationReducerState } from "./reducers/emailCustomizationReducer";
import { IInvoiceStandaloneReducerState } from "./reducers/invoiceStandaloneReducer";
import { ICheckoutAccountManagementReducerState } from "./reducers/checkoutAccountManagementReducer";
import { ICancelSubscriptionConfigState } from "./reducers/cancelSubscriptionConfigReducer";
import { ISubscriptionCustomFieldsReducerState } from "./reducers/subscriptionCustomFieldsReducer";
import { ISubscriptionPlanReducerState } from "./reducers/subscriptionPlanReducer";
import { IShippingAddressReducerState } from "./reducers/shippingAddressReducer";
import { IActivitySummaryReducerState } from "./reducers/activitySummaryReducer";
import { ITaxSummaryReducerState } from "./reducers/taxSummaryReducer";
import { IEmailBaseTemplateState } from "./reducers/emailBaseTemplateReducer";
import { IExitReasonsState } from "./reducers/exitReasonsReducer";
import { ICreateAddOnsReducerState } from "./reducers/add-ons/create-addons/createAddOnsReducer";
import { loadAppInsights } from "./utils/app-insights";
import { IEmailLogsReducer } from "./reducers/emailLogsReducer";
import { ISelectPlanAddOnsReducerState } from "./reducers/add-ons/selectAddOnPlanReducer";
import { IAddOnCostReducer } from "./reducers/add-ons/addOnCostReducer";
import { ILinkAddOnsAllowancesReducerState } from "./reducers/linkAddOnsAllowancesReducer";
import { IAddOnsReducerState } from "./reducers/add-ons/addOnsReducer";
import { IDiscountsState } from "./reducers/discount-coupons/discountsReducer";
import { ICreateDiscountState } from "./reducers/discount-coupons/createDiscountReducer";
import { ICreateCouponState } from "./reducers/discount-coupons/createCouponReducer";
import { IUserInterfaceReducerState } from "./reducers/userInterfaceReducer";
import { appendCheckoutLibScript } from "./utils/checkout/checkoutIntegrationUtils";
import { IAccountingIntegrationsReducerState } from "./reducers/accountingIntegrationsReducer";
import { IRevenueReportState } from "./reducers/revenueReportReducer";
import { IScaState } from "./reducers/scaReducer";
import { ILanguageSupportReducerState } from "./reducers/languageSupportReducer";
import { ITransactionLogsReducerState } from "./reducers/transactionLogsReducer";
import { IAchSettingsReducer } from "./reducers/achSettingsReducer";
import { ConfigConstants } from "./utils/config";

customPolyfill();
registerTranslations();
loadAppInsights();

const composeEnhancers =
  typeof window === "object" &&
    (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    ? (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
      // Specify extension’s options like name, actionsBlacklist, actionsCreators, serialize...
    })
    : compose;

const enhancer = composeEnhancers(applyMiddleware(thunk, callAPIMiddleware));

// export store to make dispatch() fn globally available
export const reduxStore = createStore(rootReducer, enhancer);


export interface AppState {
  registration: IRegistrationReducerState,
  login: ILoginReducerState,
  auth: IAuthReducerState,
  productPlanCycle: IProductPlanCycleReducerState,
  products: IProductReducerState,
  plans: IPlanReducer,
  cycles: ICycleReducer,
  customFields: ICustomFieldsReducer,
  currenciesReducer: ICurrenciesReducer,
  paymentGatewaysReducer: IPaymentGatewaysReducer,
  goLiveReducer: IGoLiveReducer,
  companyApiKeyReducer: ICompanyApiKeyReducerState,
  customersReducer: ICustomersReducer,
  customerDetailsReducer: ICustomerDetailsReducerState,
  companyBrandingReducer: ICompanyBrandingReducerState,
  companyTeammatesReducer: ICompanyTeammatesReducerState,
  subscriptionsReducer: ISubscriptionsReducer,
  subscriptionDetailsReducer: ISubscriptionDetailsReducerState,
  customerSubscriptionsReducer: ICustomerSubscriptionsReducerState,
  eventLogsReducer: IEventLogsReducerState,
  subscriptionFeatureTagsReducer: ISubscriptionFeatureTagsState,
  invoicesReducer: IInvoicesReducer,
  creditNotesReducer: ICreditNotesReducerState,
  embedCodesCheckOutReducer: IEmbedCodesCheckOutReducerState,
  companyWebhooksReducer: ICompanyWebhooksReducerState
  creditNoteDetailsReducer: ICreditNoteDetailsReducerState,
  invoiceDetailsReducer: IInvoiceDetailsReducerState,
  companyTaxRatesReducer: ICompanyTaxRatesReducerState,
  companyDetailsReducer: ICompanyDetailsReducerState,
  emailRoutingReducer: IEmailRoutingReducerState,
  dunningFailedProcessReducer: IDunningFailedProcessState,
  dashboardReducer: IDashboardReducer,
  invoicesCreditNotesCustomizationReducer: IInvoicesCreditNotesCustomizationState,
  emailCustomizationReducer: IEmailCustomizationReducerState,
  invoiceStandaloneReducer: IInvoiceStandaloneReducerState,
  checkoutAccountManagementReducer: ICheckoutAccountManagementReducerState
  subscriptionCustomFieldsReducer: ISubscriptionCustomFieldsReducerState
  cancelSubscriptionConfigReducer: ICancelSubscriptionConfigState,
  subscriptionPlanReducer: ISubscriptionPlanReducerState,
  shippingAddressReducer: IShippingAddressReducerState,
  activitySummaryReducer: IActivitySummaryReducerState,
  taxSummaryReducer: ITaxSummaryReducerState,
  emailBaseTemplateReducer: IEmailBaseTemplateState,
  exitReasonsReducer: IExitReasonsState,
  emailLogsReducer: IEmailLogsReducer,
  createAddOnsReducer: ICreateAddOnsReducerState,
  selectAddOnPlanReducer: ISelectPlanAddOnsReducerState,
  addOnCostReducer: IAddOnCostReducer,
  linkAddOnsAllowancesReducer: ILinkAddOnsAllowancesReducerState,
  addOnsReducer: IAddOnsReducerState,
  createDiscountReducer: ICreateDiscountState,
  createCouponReducer: ICreateCouponState,
  discountsReducer: IDiscountsState,
  userInterfaceReducer: IUserInterfaceReducerState,
  accountingIntegrationsReducer: IAccountingIntegrationsReducerState,
  revenueReportReducer: IRevenueReportState,
  scaReducer: IScaState,
  languageSupportReducer: ILanguageSupportReducerState,
  transactionLogsReducer: ITransactionLogsReducerState,
  achSettingsReducer: IAchSettingsReducer
}

checkBillsbyOneSessionLogin();

const isRunningTests = process.env.JEST_WORKER_ID !== undefined;
if (!isRunningTests) {
  // INITIALIZE AUTHENTICATION DATA FOR THE USER 
  reduxStore.dispatch(initAuthData());
  reduxStore.dispatch(initReadmeioLinks());
}

const isChrome = /Chrome/.test(navigator.userAgent) && /Google Inc/.test(navigator.vendor);

injectTrackingScripts(reduxStore.getState().auth.parsedToken);
appendCheckoutLibScript();

// Google Tag Manager script
addScriptDynamically({ 
  scriptAsText: `
    (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
    new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
    j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
    'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
    })(window,document,'script','dataLayer','${ConfigConstants.billsbyGtagContainerId}');
  `
})

// Google Tag Manager noscript
addNoScriptElementDynamically(
  `
    <iframe src="https://www.googletagmanager.com/ns.html?id=${ConfigConstants.billsbyGtagContainerId}" height="0" width="0"
    style="display:none;visibility:hidden"></iframe>
  `
)

if (!isChrome) {
  // we're loading the fonts with the rel="preload" attribute in the index.html for performance reason but it's not supported aside from chrome
  // so we add this polyfill for old browsers
  appendFontPolyfillOldBrowser();
}

ReactDOM.render(
  <Provider store={reduxStore}>
    <App />
  </Provider>,
  document.getElementById("root") || document.createElement("div") // for testing purposes
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
