import React, { useState } from "react";
import "./StatsBar.scss";
import Panel from "../panel/Panel";
import Text from "../text/Text";
import Tooltip from "../tooltip/Tooltip";
import "./StatsBar.scss";
import AmountFormatted from "../amount-formatted/AmountFormatted";

interface IStatsBarEl {
  label: string,
  shouldTranslate?: boolean,
  value: string | number,
  value2?: string | number,
  infoText?: string | JSX.Element,
  shouldTranslateInfoText?: boolean,
  clb?: Function
}

interface IStatsBar {
  data: Array<IStatsBarEl>
}

const StatsBar: React.FC<IStatsBar> = React.memo(({ data }) => {
  const [hoverData, setHoverData] = useState<{ [key: string]: boolean }>(data.reduce((acc, el, index) => ({ ...acc, [index + ""]: false }), {}));
  const [tooltipIds] = useState<{ [key: string]: string }>(data.reduce((acc, el, index) => ({ ...acc, [index + ""]: `tooltip-stats-bar-${Math.floor(Math.random() * 100)}` }), {}));


  return (
    <Panel className="stats-bar">
      <>
        {
          data.map((el, index) => {
            const idInfoTooltip = tooltipIds[index];
            return (
              <div key={index} className={`stats-bar__el ${el.clb ? "stats-bar__el--link" : ""}`} onClick={() => el.clb && el.clb()}>
                <div className="stats-bar__el-top">
                  <Text content={el.label} shouldTranslate={el.shouldTranslate} noMargin />
                  <i 
                    // need to bind onMouseEnter in the <i/>  because it's absolute positioned so it's like it's not part of the hoverable section
                    onMouseEnter={(evt: any) => { setHoverData({ ...hoverData, [index]: true }); evt.stopPropagation() }} 
                    className="far fa-info-circle" 
                    data-tip /*data-event='click focus'*/ 
                    data-for={idInfoTooltip} 
                    style={{ visibility: el.infoText && hoverData[index] ? "visible" : "hidden" }} 
                  />
                </div>
                <div className="stats-bar__el-bottom">
                  <AmountFormatted amount={el.value + ""} />
                  {el.value2 && <Text content={el.value2 + ""} shouldTranslate={false} noMargin />}
                </div>
                {
                  el.infoText && (
                    <Tooltip id={idInfoTooltip} place="bottom" type="blue">
                      <Text content={el.infoText} shouldTranslate={el.shouldTranslateInfoText === undefined ? true : false} noMargin />
                    </Tooltip>
                  )
                }
                <div
                  className=" stats-bar__el-hoverable"
                  onMouseEnter={(evt: any) => { setHoverData({ ...hoverData, [index]: true }); evt.stopPropagation() }}
                  onMouseLeave={(evt: any) => { setHoverData({ ...hoverData, [index]: false }); evt.stopPropagation() }}
                />
              </div>
            )
          })
        }
      </>
    </Panel>
  )
})

export default StatsBar;