import counterpart from "counterpart";
import React, { useState } from "react";
import CollapsablePanelWithArrow from "../../../ui/collapsable-panel-with-arrow/CollapsablePanelWithArrow";
import ErrorPanel from "../../../ui/error-panel/ErrorPanel";
import Panel from "../../../ui/panel/Panel";
import Text from "../../../ui/text/Text";
import "./WebexFaq.scss";

interface IWebexFaq {
  isInstalled?: boolean
}

export const WebexFaq: React.FC<IWebexFaq> = ({ isInstalled = false }) => {
  const [collapsedPanels, setCollapsedPanels] = useState([false, true, true, true]);
  const [collapsedPanel, setCollapsedPanel] = useState(true);

  const renderPanel3Contents = () => {
    return (
      <>
        <Text content="INTEGRATION_WEBEX_FAQ_PANEL3_TEXT1" />
        <Text content="INTEGRATION_WEBEX_FAQ_PANEL3_TEXT2" translateWith={{ text: <strong>{`${counterpart("INTEGRATION_WEBEX_FAQ_BOLD_TEXT1")}`}</strong> }} />
        <Text content="INTEGRATION_WEBEX_FAQ_PANEL3_TEXT3" translateWith={{ text: <strong>{`${counterpart("INTEGRATION_WEBEX_FAQ_BOLD_TEXT2")}`}</strong> }} />
        <Text content="INTEGRATION_WEBEX_FAQ_PANEL3_TEXT4" translateWith={{ text: <strong>{`${counterpart("INTEGRATION_WEBEX_FAQ_BOLD_TEXT3")}`}</strong> }} />
        <Text content="INTEGRATION_WEBEX_FAQ_PANEL3_TEXT5" translateWith={{ text: <strong>{`${counterpart("INTEGRATION_WEBEX_FAQ_BOLD_TEXT4")}`}</strong> }} />
        <Text content="INTEGRATION_WEBEX_FAQ_PANEL3_TEXT6" translateWith={{ text: <strong>{`${counterpart("INTEGRATION_WEBEX_FAQ_BOLD_TEXT5")}`}</strong> }} />
      </>
    )
  }

  const renderPanel4Contents = () => {
    return (
      <>
        <Text content="INTEGRATION_WEBEX_FAQ_PANEL4_TEXT1" />
        <Text content="INTEGRATION_WEBEX_FAQ_PANEL4_TEXT2" translateWith={{ text: <strong>{`${counterpart("INTEGRATION_WEBEX_FAQ_BOLD_TEXT1")}`}</strong> }} />
        <Text content="INTEGRATION_WEBEX_FAQ_PANEL4_TEXT3" translateWith={{ text: <strong>{`${counterpart("INTEGRATION_WEBEX_FAQ_BOLD_TEXT2")}`}</strong> }} />
        <Text content="INTEGRATION_WEBEX_FAQ_PANEL4_TEXT4" translateWith={{ text: <strong>{`${counterpart("INTEGRATION_WEBEX_FAQ_BOLD_TEXT3")}`}</strong> }} />
        <Text content="INTEGRATION_WEBEX_FAQ_PANEL4_TEXT5" translateWith={{ text: <strong>{`${counterpart("INTEGRATION_WEBEX_FAQ_BOLD_TEXT4")}`}</strong> }} />
        <Text content="INTEGRATION_WEBEX_FAQ_PANEL4_TEXT6" translateWith={{ text: <strong>{`${counterpart("INTEGRATION_WEBEX_FAQ_BOLD_TEXT5")}`}</strong> }} />
      </>
    )
  }

  const renderFaq = () => {
    if (isInstalled) {
      return (
        <CollapsablePanelWithArrow
          isCollapsed={collapsedPanel}
          className="webex-faq__panel"
          onClick={() => setCollapsedPanel(!collapsedPanel)}
          title="INTEGRATION_WEBEX_FAQS"
        >
          <Text className="webex-faq__panel__title" content="INTEGRATION_WEBEX_FAQ_PANEL1_TITLE"></Text>
          <Text content="INTEGRATION_WEBEX_FAQ_PANEL1_TEXT" />

          <Text className="webex-faq__panel__title" content="INTEGRATION_WEBEX_FAQ_PANEL2_TITLE"></Text>
          <Text content="INTEGRATION_WEBEX_FAQ_PANEL2_TEXT" />

          <Text className="webex-faq__panel__title" content="INTEGRATION_WEBEX_FAQ_PANEL3_TITLE"></Text>
          {renderPanel3Contents()}

          <Text className="webex-faq__panel__title" content="INTEGRATION_WEBEX_FAQ_PANEL4_TITLE"></Text>
          {renderPanel4Contents()}
        </CollapsablePanelWithArrow>
      )
    }

    return (
      <>
        <CollapsablePanelWithArrow
          isCollapsed={collapsedPanels[0]}
          onClick={() => setCollapsedPanels([false, true, true, true])}
          className="webex-faq__panel"
          title="INTEGRATION_WEBEX_FAQ_PANEL1_TITLE">
          <Text content="INTEGRATION_WEBEX_FAQ_PANEL1_TEXT" noMargin />
        </CollapsablePanelWithArrow>

        <CollapsablePanelWithArrow
          isCollapsed={collapsedPanels[1]}
          onClick={() => setCollapsedPanels([true, false, true, true])}
          className="webex-faq__panel"
          title="INTEGRATION_WEBEX_FAQ_PANEL2_TITLE">
          <Text content="INTEGRATION_WEBEX_FAQ_PANEL2_TEXT" noMargin />
        </CollapsablePanelWithArrow>

        <CollapsablePanelWithArrow
          isCollapsed={collapsedPanels[2]}
          onClick={() => setCollapsedPanels([true, true, false, true])}
          className="webex-faq__panel"
          title="INTEGRATION_WEBEX_FAQ_PANEL3_TITLE">
          <Text content="INTEGRATION_WEBEX_FAQ_PANEL3_TITLE"></Text>
          {renderPanel3Contents()}
        </CollapsablePanelWithArrow>

        <CollapsablePanelWithArrow
          isCollapsed={collapsedPanels[3]}
          onClick={() => setCollapsedPanels([true, true, true, false])}
          className="webex-faq__panel"
          title="INTEGRATION_WEBEX_FAQ_PANEL4_TITLE">
          <Text content="INTEGRATION_WEBEX_FAQ_PANEL4_TITLE"></Text>
          {renderPanel4Contents()}
        </CollapsablePanelWithArrow>
      </>
    )
  }

  const renderHelpBanner = () => {
    if (isInstalled) {
      return (
        <Panel title="INTEGRATION_WEBEX_FAQ_CONTACT_US">
          <Text content="INTEGRATION_WEBEX_FAQ_TALK_US_TEXT1" noMargin></Text>
          <a className="webex-faq__link" href="https://help.webex.com/contact" target="_self"><Text component="span" content="INTEGRATION_WEBEX_FAQ_TALK_US_TEXT2" noMargin></Text></a>
        </Panel>
      )
    }

    return (
      <ErrorPanel
        title="INTEGRATION_FAQ_TALK_US_TEXT1"
        subtitle="INTEGRATION_FAQ_TALK_US_TEXT2"
        icon="fas fa-question-circle"
      />
    )
  }

  return (
    <div className="webex-faq">
      {renderFaq()}
      {renderHelpBanner()}
    </div>
  )
}

export default WebexFaq;