import React, { useState } from "react";
import counterpart from "counterpart";
import FormGroup from "../../../../ui/form-group/FormGroup";
import { Row, Col } from "react-grid-system";
import FormLabel from "../../../../ui/form-label/FormLabel";
import Input from "../../../../ui/input/Input";
import PaymentGatewayButtons from "../payment-gateways-shared-components/PaymentGatewayButtons";
import { isInvalidGatewayFormsDetails } from "../PaymentGateways";

interface Props {
  onSave: (displayName: string, username: string, password: string) => Promise<void>;
  onCancel: () => void;
  setHelpTitle: (text: string) => void;
  setHelpContent: (text: Array<string>) => void;
  isEdit: boolean;
  isSaveEnabled: boolean;
  initDisplayName: string;
  initUsername: string,
}

const NMIPaymentGatewayForm: React.FC<Props> = ({ onSave, onCancel, setHelpTitle, setHelpContent, isSaveEnabled, isEdit, initDisplayName, initUsername }) => {

  const [displayName, setDisplayName] = useState(initDisplayName);
  const [username, setUsername] = useState(initUsername);
  const [password, setPassword] = useState("");

  const savePaymentGateway = async () => {
    await onSave(displayName, username, password);
    setPassword("");
  } 

  const onFocus = (inputField: string) => {
    switch (inputField) {
      case "DisplayName":
        setHelpTitle("PAYMENT_GATEWAYS_ADDED_NMI_DISPLAYNAME_HELP_TITLE");
        setHelpContent(["PAYMENT_GATEWAYS_ADDED_NMI_DISPLAYNAME_HELP_CONTENT_1"]);
        break;
      case "Username":
        setHelpTitle("PAYMENT_GATEWAYS_ADDED_NMI_USERNAME_HELP_TITLE");
        setHelpContent(["PAYMENT_GATEWAYS_ADDED_NMI_USERNAME_HELP_CONTENT_1"]);
        break;
      case "Password":
        setHelpTitle("PAYMENT_GATEWAYS_ADDED_NMI_PASSWORD_HELP_TITLE");
        setHelpContent(["PAYMENT_GATEWAYS_ADDED_NMI_PASSWORD_HELP_CONTENT_1"]);
        break;
    }
  }

  return (
      <div className="payment-gateways__update">
        <FormGroup>
          <Row align="center" className="payment-gateways__update__form-group">
            <Col xs={2}>
              <FormLabel target="display-name" content="PAYMENT_GATEWAYS_NMI_DISPLAY_NAME" noMargin />
            </Col>
            <Col xs={10}>
              <Input
                id="display-name"
                onFocus={() => onFocus("DisplayName")}
                placeholder={counterpart("PAYMENT_GATEWAYS_NMI_DISPLAY_NAME_PLACEHOLDER")}
                value={displayName}
                onChange={(evt: any) => setDisplayName(evt.target.value)}
              />
            </Col>
          </Row>
          <Row align="center" className="payment-gateways__update__form-group">
            <Col xs={2}>
              <FormLabel target="user-name" content="PAYMENT_GATEWAYS_NMI_USERNAME" noMargin />
            </Col>
            <Col xs={10}>
              <Input
                id="user-name"
                disabled={isEdit}
                onFocus={() => onFocus("Username")}
                placeholder={counterpart("PAYMENT_GATEWAYS_NMI_USERNAME_PLACEHOLDER")}
                value={username}
                onChange={(evt: any) => setUsername(evt.target.value)}
              />
            </Col>
          </Row>
          <Row align="center" className="payment-gateways__update__form-group">
            <Col xs={2}>
              <FormLabel target="password" content="PAYMENT_GATEWAYS_NMI_PASSWORD" noMargin />
            </Col>
            <Col xs={10}>
              <Input
                id="password"
                onFocus={() => onFocus("Password")}
                placeholder={counterpart("PAYMENT_GATEWAYS_NMI_PASSWORD_PLACEHOLDER")}
                type="password"
                value={password}
                onChange={(evt: any) => setPassword(evt.target.value)}
              />
            </Col>
          </Row>
        </FormGroup>

        <PaymentGatewayButtons
          onSave={() => savePaymentGateway()}
          onCancel={() => {
            onCancel();
            setDisplayName(initDisplayName);
            setUsername(initUsername);
            setPassword(""); 
          }}
          isEdit={isEdit}
          isSaveEnabled={isSaveEnabled && !isInvalidGatewayFormsDetails([displayName, username, password])}
        />
      </div>
  )
}

export default NMIPaymentGatewayForm;