/**
 * @fileoverview gRPC-Web generated client stub for revenuerecognition.private.reports
 * @enhanceable
 * @public
 */

// GENERATED CODE -- DO NOT EDIT!


/* eslint-disable */
// @ts-nocheck


import * as grpcWeb from 'grpc-web';

import * as reports_pb from './reports_pb';


export class ReportsServiceClient {
  client_: grpcWeb.AbstractClientBase;
  hostname_: string;
  credentials_: null | { [index: string]: string; };
  options_: null | { [index: string]: any; };

  constructor (hostname: string,
               credentials?: null | { [index: string]: string; },
               options?: null | { [index: string]: any; }) {
    if (!options) options = {};
    if (!credentials) credentials = {};
    options['format'] = 'text';

    this.client_ = new grpcWeb.GrpcWebClientBase(options);
    this.hostname_ = hostname;
    this.credentials_ = credentials;
    this.options_ = options;
  }

  methodDescriptorGetPlanRevenueRecognition = new grpcWeb.MethodDescriptor(
    '/revenuerecognition.private.reports.ReportsService/GetPlanRevenueRecognition',
    grpcWeb.MethodType.UNARY,
    reports_pb.GetPlanRevenueRequest,
    reports_pb.GetPlanRevenueResponse,
    (request: reports_pb.GetPlanRevenueRequest) => {
      return request.serializeBinary();
    },
    reports_pb.GetPlanRevenueResponse.deserializeBinary
  );

  getPlanRevenueRecognition(
    request: reports_pb.GetPlanRevenueRequest,
    metadata: grpcWeb.Metadata | null): Promise<reports_pb.GetPlanRevenueResponse>;

  getPlanRevenueRecognition(
    request: reports_pb.GetPlanRevenueRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: reports_pb.GetPlanRevenueResponse) => void): grpcWeb.ClientReadableStream<reports_pb.GetPlanRevenueResponse>;

  getPlanRevenueRecognition(
    request: reports_pb.GetPlanRevenueRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: reports_pb.GetPlanRevenueResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/revenuerecognition.private.reports.ReportsService/GetPlanRevenueRecognition',
        request,
        metadata || {},
        this.methodDescriptorGetPlanRevenueRecognition,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/revenuerecognition.private.reports.ReportsService/GetPlanRevenueRecognition',
    request,
    metadata || {},
    this.methodDescriptorGetPlanRevenueRecognition);
  }

  methodDescriptorGetProductRevenueRecognition = new grpcWeb.MethodDescriptor(
    '/revenuerecognition.private.reports.ReportsService/GetProductRevenueRecognition',
    grpcWeb.MethodType.UNARY,
    reports_pb.GetProductRevenueRequest,
    reports_pb.GetProductRevenueResponse,
    (request: reports_pb.GetProductRevenueRequest) => {
      return request.serializeBinary();
    },
    reports_pb.GetProductRevenueResponse.deserializeBinary
  );

  getProductRevenueRecognition(
    request: reports_pb.GetProductRevenueRequest,
    metadata: grpcWeb.Metadata | null): Promise<reports_pb.GetProductRevenueResponse>;

  getProductRevenueRecognition(
    request: reports_pb.GetProductRevenueRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: reports_pb.GetProductRevenueResponse) => void): grpcWeb.ClientReadableStream<reports_pb.GetProductRevenueResponse>;

  getProductRevenueRecognition(
    request: reports_pb.GetProductRevenueRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: reports_pb.GetProductRevenueResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/revenuerecognition.private.reports.ReportsService/GetProductRevenueRecognition',
        request,
        metadata || {},
        this.methodDescriptorGetProductRevenueRecognition,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/revenuerecognition.private.reports.ReportsService/GetProductRevenueRecognition',
    request,
    metadata || {},
    this.methodDescriptorGetProductRevenueRecognition);
  }

  methodDescriptorGetSubscriptionRevenueRecognition = new grpcWeb.MethodDescriptor(
    '/revenuerecognition.private.reports.ReportsService/GetSubscriptionRevenueRecognition',
    grpcWeb.MethodType.UNARY,
    reports_pb.GetSubscriptionRevenueRequest,
    reports_pb.GetSubscriptionRevenueResponse,
    (request: reports_pb.GetSubscriptionRevenueRequest) => {
      return request.serializeBinary();
    },
    reports_pb.GetSubscriptionRevenueResponse.deserializeBinary
  );

  getSubscriptionRevenueRecognition(
    request: reports_pb.GetSubscriptionRevenueRequest,
    metadata: grpcWeb.Metadata | null): Promise<reports_pb.GetSubscriptionRevenueResponse>;

  getSubscriptionRevenueRecognition(
    request: reports_pb.GetSubscriptionRevenueRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: reports_pb.GetSubscriptionRevenueResponse) => void): grpcWeb.ClientReadableStream<reports_pb.GetSubscriptionRevenueResponse>;

  getSubscriptionRevenueRecognition(
    request: reports_pb.GetSubscriptionRevenueRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: reports_pb.GetSubscriptionRevenueResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/revenuerecognition.private.reports.ReportsService/GetSubscriptionRevenueRecognition',
        request,
        metadata || {},
        this.methodDescriptorGetSubscriptionRevenueRecognition,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/revenuerecognition.private.reports.ReportsService/GetSubscriptionRevenueRecognition',
    request,
    metadata || {},
    this.methodDescriptorGetSubscriptionRevenueRecognition);
  }

  methodDescriptorGetInvoiceRevenueRecognition = new grpcWeb.MethodDescriptor(
    '/revenuerecognition.private.reports.ReportsService/GetInvoiceRevenueRecognition',
    grpcWeb.MethodType.UNARY,
    reports_pb.GetInvoiceRevenueRequest,
    reports_pb.GetInvoiceRevenueResponse,
    (request: reports_pb.GetInvoiceRevenueRequest) => {
      return request.serializeBinary();
    },
    reports_pb.GetInvoiceRevenueResponse.deserializeBinary
  );

  getInvoiceRevenueRecognition(
    request: reports_pb.GetInvoiceRevenueRequest,
    metadata: grpcWeb.Metadata | null): Promise<reports_pb.GetInvoiceRevenueResponse>;

  getInvoiceRevenueRecognition(
    request: reports_pb.GetInvoiceRevenueRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: reports_pb.GetInvoiceRevenueResponse) => void): grpcWeb.ClientReadableStream<reports_pb.GetInvoiceRevenueResponse>;

  getInvoiceRevenueRecognition(
    request: reports_pb.GetInvoiceRevenueRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: reports_pb.GetInvoiceRevenueResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/revenuerecognition.private.reports.ReportsService/GetInvoiceRevenueRecognition',
        request,
        metadata || {},
        this.methodDescriptorGetInvoiceRevenueRecognition,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/revenuerecognition.private.reports.ReportsService/GetInvoiceRevenueRecognition',
    request,
    metadata || {},
    this.methodDescriptorGetInvoiceRevenueRecognition);
  }

  methodDescriptorGetPlanDeferredRevenue = new grpcWeb.MethodDescriptor(
    '/revenuerecognition.private.reports.ReportsService/GetPlanDeferredRevenue',
    grpcWeb.MethodType.UNARY,
    reports_pb.GetPlanRevenueRequest,
    reports_pb.GetPlanRevenueResponse,
    (request: reports_pb.GetPlanRevenueRequest) => {
      return request.serializeBinary();
    },
    reports_pb.GetPlanRevenueResponse.deserializeBinary
  );

  getPlanDeferredRevenue(
    request: reports_pb.GetPlanRevenueRequest,
    metadata: grpcWeb.Metadata | null): Promise<reports_pb.GetPlanRevenueResponse>;

  getPlanDeferredRevenue(
    request: reports_pb.GetPlanRevenueRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: reports_pb.GetPlanRevenueResponse) => void): grpcWeb.ClientReadableStream<reports_pb.GetPlanRevenueResponse>;

  getPlanDeferredRevenue(
    request: reports_pb.GetPlanRevenueRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: reports_pb.GetPlanRevenueResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/revenuerecognition.private.reports.ReportsService/GetPlanDeferredRevenue',
        request,
        metadata || {},
        this.methodDescriptorGetPlanDeferredRevenue,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/revenuerecognition.private.reports.ReportsService/GetPlanDeferredRevenue',
    request,
    metadata || {},
    this.methodDescriptorGetPlanDeferredRevenue);
  }

  methodDescriptorGetProductDeferredRevenue = new grpcWeb.MethodDescriptor(
    '/revenuerecognition.private.reports.ReportsService/GetProductDeferredRevenue',
    grpcWeb.MethodType.UNARY,
    reports_pb.GetProductRevenueRequest,
    reports_pb.GetProductRevenueResponse,
    (request: reports_pb.GetProductRevenueRequest) => {
      return request.serializeBinary();
    },
    reports_pb.GetProductRevenueResponse.deserializeBinary
  );

  getProductDeferredRevenue(
    request: reports_pb.GetProductRevenueRequest,
    metadata: grpcWeb.Metadata | null): Promise<reports_pb.GetProductRevenueResponse>;

  getProductDeferredRevenue(
    request: reports_pb.GetProductRevenueRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: reports_pb.GetProductRevenueResponse) => void): grpcWeb.ClientReadableStream<reports_pb.GetProductRevenueResponse>;

  getProductDeferredRevenue(
    request: reports_pb.GetProductRevenueRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: reports_pb.GetProductRevenueResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/revenuerecognition.private.reports.ReportsService/GetProductDeferredRevenue',
        request,
        metadata || {},
        this.methodDescriptorGetProductDeferredRevenue,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/revenuerecognition.private.reports.ReportsService/GetProductDeferredRevenue',
    request,
    metadata || {},
    this.methodDescriptorGetProductDeferredRevenue);
  }

  methodDescriptorGetSubscriptionDeferredRevenue = new grpcWeb.MethodDescriptor(
    '/revenuerecognition.private.reports.ReportsService/GetSubscriptionDeferredRevenue',
    grpcWeb.MethodType.UNARY,
    reports_pb.GetSubscriptionRevenueRequest,
    reports_pb.GetSubscriptionRevenueResponse,
    (request: reports_pb.GetSubscriptionRevenueRequest) => {
      return request.serializeBinary();
    },
    reports_pb.GetSubscriptionRevenueResponse.deserializeBinary
  );

  getSubscriptionDeferredRevenue(
    request: reports_pb.GetSubscriptionRevenueRequest,
    metadata: grpcWeb.Metadata | null): Promise<reports_pb.GetSubscriptionRevenueResponse>;

  getSubscriptionDeferredRevenue(
    request: reports_pb.GetSubscriptionRevenueRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: reports_pb.GetSubscriptionRevenueResponse) => void): grpcWeb.ClientReadableStream<reports_pb.GetSubscriptionRevenueResponse>;

  getSubscriptionDeferredRevenue(
    request: reports_pb.GetSubscriptionRevenueRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: reports_pb.GetSubscriptionRevenueResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/revenuerecognition.private.reports.ReportsService/GetSubscriptionDeferredRevenue',
        request,
        metadata || {},
        this.methodDescriptorGetSubscriptionDeferredRevenue,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/revenuerecognition.private.reports.ReportsService/GetSubscriptionDeferredRevenue',
    request,
    metadata || {},
    this.methodDescriptorGetSubscriptionDeferredRevenue);
  }

  methodDescriptorGetInvoiceDeferredRevenue = new grpcWeb.MethodDescriptor(
    '/revenuerecognition.private.reports.ReportsService/GetInvoiceDeferredRevenue',
    grpcWeb.MethodType.UNARY,
    reports_pb.GetInvoiceRevenueRequest,
    reports_pb.GetInvoiceRevenueResponse,
    (request: reports_pb.GetInvoiceRevenueRequest) => {
      return request.serializeBinary();
    },
    reports_pb.GetInvoiceRevenueResponse.deserializeBinary
  );

  getInvoiceDeferredRevenue(
    request: reports_pb.GetInvoiceRevenueRequest,
    metadata: grpcWeb.Metadata | null): Promise<reports_pb.GetInvoiceRevenueResponse>;

  getInvoiceDeferredRevenue(
    request: reports_pb.GetInvoiceRevenueRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: reports_pb.GetInvoiceRevenueResponse) => void): grpcWeb.ClientReadableStream<reports_pb.GetInvoiceRevenueResponse>;

  getInvoiceDeferredRevenue(
    request: reports_pb.GetInvoiceRevenueRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: reports_pb.GetInvoiceRevenueResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/revenuerecognition.private.reports.ReportsService/GetInvoiceDeferredRevenue',
        request,
        metadata || {},
        this.methodDescriptorGetInvoiceDeferredRevenue,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/revenuerecognition.private.reports.ReportsService/GetInvoiceDeferredRevenue',
    request,
    metadata || {},
    this.methodDescriptorGetInvoiceDeferredRevenue);
  }

}

