/* eslint-disable */
// source: payment_gateway.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = Function('return this')();

var google_protobuf_timestamp_pb = require('google-protobuf/google/protobuf/timestamp_pb.js');
goog.object.extend(proto, google_protobuf_timestamp_pb);
var google_protobuf_wrappers_pb = require('google-protobuf/google/protobuf/wrappers_pb.js');
goog.object.extend(proto, google_protobuf_wrappers_pb);
goog.exportSymbol('proto.billing.private.paymentgateway.BraintreeMerchantAccount', null, global);
goog.exportSymbol('proto.billing.private.paymentgateway.CompleteEnableSCARequest', null, global);
goog.exportSymbol('proto.billing.private.paymentgateway.CompleteEnableSCAResponse', null, global);
goog.exportSymbol('proto.billing.private.paymentgateway.CreateAdyenGatewayRequest', null, global);
goog.exportSymbol('proto.billing.private.paymentgateway.CreateAdyenGatewayResponse', null, global);
goog.exportSymbol('proto.billing.private.paymentgateway.CreateAuthorizeDotNetGatewayRequest', null, global);
goog.exportSymbol('proto.billing.private.paymentgateway.CreateAuthorizeDotNetGatewayResponse', null, global);
goog.exportSymbol('proto.billing.private.paymentgateway.CreateBraintreeGatewayRequest', null, global);
goog.exportSymbol('proto.billing.private.paymentgateway.CreateBraintreeGatewayResponse', null, global);
goog.exportSymbol('proto.billing.private.paymentgateway.CreateCheckoutDotComGatewayRequest', null, global);
goog.exportSymbol('proto.billing.private.paymentgateway.CreateCheckoutDotComGatewayResponse', null, global);
goog.exportSymbol('proto.billing.private.paymentgateway.CreateCloverConnectRequest', null, global);
goog.exportSymbol('proto.billing.private.paymentgateway.CreateCloverConnectResponse', null, global);
goog.exportSymbol('proto.billing.private.paymentgateway.CreateIxopayGatewayRequest', null, global);
goog.exportSymbol('proto.billing.private.paymentgateway.CreateIxopayGatewayResponse', null, global);
goog.exportSymbol('proto.billing.private.paymentgateway.CreateNMIGatewayRequest', null, global);
goog.exportSymbol('proto.billing.private.paymentgateway.CreateNMIGatewayResponse', null, global);
goog.exportSymbol('proto.billing.private.paymentgateway.CreatePaymentCloudRequest', null, global);
goog.exportSymbol('proto.billing.private.paymentgateway.CreatePaymentCloudResponse', null, global);
goog.exportSymbol('proto.billing.private.paymentgateway.CreateStripeIntentGatewayRequest', null, global);
goog.exportSymbol('proto.billing.private.paymentgateway.CreateStripeIntentGatewayResponse', null, global);
goog.exportSymbol('proto.billing.private.paymentgateway.DeletePaymentGatewayRequest', null, global);
goog.exportSymbol('proto.billing.private.paymentgateway.DeletePaymentGatewayResponse', null, global);
goog.exportSymbol('proto.billing.private.paymentgateway.ErrorCodeAndDescription', null, global);
goog.exportSymbol('proto.billing.private.paymentgateway.GetGatewayScaDetailsRequest', null, global);
goog.exportSymbol('proto.billing.private.paymentgateway.GetGatewayScaDetailsRequest.PaymentGateTypes', null, global);
goog.exportSymbol('proto.billing.private.paymentgateway.GetGatewayScaDetailsResponse', null, global);
goog.exportSymbol('proto.billing.private.paymentgateway.GetSpreedlyTransactionRequest', null, global);
goog.exportSymbol('proto.billing.private.paymentgateway.GetSpreedlyTransactionResponse', null, global);
goog.exportSymbol('proto.billing.private.paymentgateway.GetTransactionLogRequest', null, global);
goog.exportSymbol('proto.billing.private.paymentgateway.GetTransactionLogResponse', null, global);
goog.exportSymbol('proto.billing.private.paymentgateway.PaymentLogErrorViewModel', null, global);
goog.exportSymbol('proto.billing.private.paymentgateway.PaymentSourceType', null, global);
goog.exportSymbol('proto.billing.private.paymentgateway.ReplaceGatewayToAdyenRequest', null, global);
goog.exportSymbol('proto.billing.private.paymentgateway.ReplaceGatewayToAuthorizeDotNetRequest', null, global);
goog.exportSymbol('proto.billing.private.paymentgateway.ReplaceGatewayToBraintreeRequest', null, global);
goog.exportSymbol('proto.billing.private.paymentgateway.ReplaceGatewayToCheckoutDotComRequest', null, global);
goog.exportSymbol('proto.billing.private.paymentgateway.ReplaceGatewayToCloverConnectRequest', null, global);
goog.exportSymbol('proto.billing.private.paymentgateway.ReplaceGatewayToIxopayRequest', null, global);
goog.exportSymbol('proto.billing.private.paymentgateway.ReplaceGatewayToNMIRequest', null, global);
goog.exportSymbol('proto.billing.private.paymentgateway.ReplaceGatewayToPaymentCloudRequest', null, global);
goog.exportSymbol('proto.billing.private.paymentgateway.ReplaceGatewayToStripeIntentGatewayRequest', null, global);
goog.exportSymbol('proto.billing.private.paymentgateway.ResponseCodesAndDescription', null, global);
goog.exportSymbol('proto.billing.private.paymentgateway.StartEnableSCARequest', null, global);
goog.exportSymbol('proto.billing.private.paymentgateway.StartEnableSCAResponse', null, global);
goog.exportSymbol('proto.billing.private.paymentgateway.UpdateAdyenGatewayRequest', null, global);
goog.exportSymbol('proto.billing.private.paymentgateway.UpdateAdyenGatewayResponse', null, global);
goog.exportSymbol('proto.billing.private.paymentgateway.UpdateAuthorizeDotNetGatewayRequest', null, global);
goog.exportSymbol('proto.billing.private.paymentgateway.UpdateAuthorizeDotNetGatewayResponse', null, global);
goog.exportSymbol('proto.billing.private.paymentgateway.UpdateBraintreeGatewayRequest', null, global);
goog.exportSymbol('proto.billing.private.paymentgateway.UpdateBraintreeGatewayResponse', null, global);
goog.exportSymbol('proto.billing.private.paymentgateway.UpdateCheckoutDotComGatewayRequest', null, global);
goog.exportSymbol('proto.billing.private.paymentgateway.UpdateCheckoutDotComGatewayResponse', null, global);
goog.exportSymbol('proto.billing.private.paymentgateway.UpdateCloverConnectRequest', null, global);
goog.exportSymbol('proto.billing.private.paymentgateway.UpdateCloverConnectResponse', null, global);
goog.exportSymbol('proto.billing.private.paymentgateway.UpdateIxopayGatewayRequest', null, global);
goog.exportSymbol('proto.billing.private.paymentgateway.UpdateIxopayGatewayResponse', null, global);
goog.exportSymbol('proto.billing.private.paymentgateway.UpdateNMIGatewayRequest', null, global);
goog.exportSymbol('proto.billing.private.paymentgateway.UpdateNMIGatewayResponse', null, global);
goog.exportSymbol('proto.billing.private.paymentgateway.UpdatePaymentCloudRequest', null, global);
goog.exportSymbol('proto.billing.private.paymentgateway.UpdatePaymentCloudResponse', null, global);
goog.exportSymbol('proto.billing.private.paymentgateway.UpdateStripeGatewayRequest', null, global);
goog.exportSymbol('proto.billing.private.paymentgateway.UpdateStripeGatewayResponse', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.billing.private.paymentgateway.GetGatewayScaDetailsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.billing.private.paymentgateway.GetGatewayScaDetailsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.billing.private.paymentgateway.GetGatewayScaDetailsRequest.displayName = 'proto.billing.private.paymentgateway.GetGatewayScaDetailsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.billing.private.paymentgateway.GetGatewayScaDetailsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.billing.private.paymentgateway.GetGatewayScaDetailsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.billing.private.paymentgateway.GetGatewayScaDetailsResponse.displayName = 'proto.billing.private.paymentgateway.GetGatewayScaDetailsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.billing.private.paymentgateway.StartEnableSCARequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.billing.private.paymentgateway.StartEnableSCARequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.billing.private.paymentgateway.StartEnableSCARequest.displayName = 'proto.billing.private.paymentgateway.StartEnableSCARequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.billing.private.paymentgateway.StartEnableSCAResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.billing.private.paymentgateway.StartEnableSCAResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.billing.private.paymentgateway.StartEnableSCAResponse.displayName = 'proto.billing.private.paymentgateway.StartEnableSCAResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.billing.private.paymentgateway.CompleteEnableSCARequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.billing.private.paymentgateway.CompleteEnableSCARequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.billing.private.paymentgateway.CompleteEnableSCARequest.displayName = 'proto.billing.private.paymentgateway.CompleteEnableSCARequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.billing.private.paymentgateway.CompleteEnableSCAResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.billing.private.paymentgateway.CompleteEnableSCAResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.billing.private.paymentgateway.CompleteEnableSCAResponse.displayName = 'proto.billing.private.paymentgateway.CompleteEnableSCAResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.billing.private.paymentgateway.CreateStripeIntentGatewayRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.billing.private.paymentgateway.CreateStripeIntentGatewayRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.billing.private.paymentgateway.CreateStripeIntentGatewayRequest.displayName = 'proto.billing.private.paymentgateway.CreateStripeIntentGatewayRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.billing.private.paymentgateway.ReplaceGatewayToStripeIntentGatewayRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.billing.private.paymentgateway.ReplaceGatewayToStripeIntentGatewayRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.billing.private.paymentgateway.ReplaceGatewayToStripeIntentGatewayRequest.displayName = 'proto.billing.private.paymentgateway.ReplaceGatewayToStripeIntentGatewayRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.billing.private.paymentgateway.CreateStripeIntentGatewayResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.billing.private.paymentgateway.CreateStripeIntentGatewayResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.billing.private.paymentgateway.CreateStripeIntentGatewayResponse.displayName = 'proto.billing.private.paymentgateway.CreateStripeIntentGatewayResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.billing.private.paymentgateway.UpdateStripeGatewayRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.billing.private.paymentgateway.UpdateStripeGatewayRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.billing.private.paymentgateway.UpdateStripeGatewayRequest.displayName = 'proto.billing.private.paymentgateway.UpdateStripeGatewayRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.billing.private.paymentgateway.UpdateStripeGatewayResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.billing.private.paymentgateway.UpdateStripeGatewayResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.billing.private.paymentgateway.UpdateStripeGatewayResponse.displayName = 'proto.billing.private.paymentgateway.UpdateStripeGatewayResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.billing.private.paymentgateway.CreateCheckoutDotComGatewayRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.billing.private.paymentgateway.CreateCheckoutDotComGatewayRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.billing.private.paymentgateway.CreateCheckoutDotComGatewayRequest.displayName = 'proto.billing.private.paymentgateway.CreateCheckoutDotComGatewayRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.billing.private.paymentgateway.ReplaceGatewayToCheckoutDotComRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.billing.private.paymentgateway.ReplaceGatewayToCheckoutDotComRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.billing.private.paymentgateway.ReplaceGatewayToCheckoutDotComRequest.displayName = 'proto.billing.private.paymentgateway.ReplaceGatewayToCheckoutDotComRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.billing.private.paymentgateway.CreateCheckoutDotComGatewayResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.billing.private.paymentgateway.CreateCheckoutDotComGatewayResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.billing.private.paymentgateway.CreateCheckoutDotComGatewayResponse.displayName = 'proto.billing.private.paymentgateway.CreateCheckoutDotComGatewayResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.billing.private.paymentgateway.UpdateCheckoutDotComGatewayRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.billing.private.paymentgateway.UpdateCheckoutDotComGatewayRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.billing.private.paymentgateway.UpdateCheckoutDotComGatewayRequest.displayName = 'proto.billing.private.paymentgateway.UpdateCheckoutDotComGatewayRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.billing.private.paymentgateway.UpdateCheckoutDotComGatewayResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.billing.private.paymentgateway.UpdateCheckoutDotComGatewayResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.billing.private.paymentgateway.UpdateCheckoutDotComGatewayResponse.displayName = 'proto.billing.private.paymentgateway.UpdateCheckoutDotComGatewayResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.billing.private.paymentgateway.CreateBraintreeGatewayRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.billing.private.paymentgateway.CreateBraintreeGatewayRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.billing.private.paymentgateway.CreateBraintreeGatewayRequest.displayName = 'proto.billing.private.paymentgateway.CreateBraintreeGatewayRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.billing.private.paymentgateway.ReplaceGatewayToBraintreeRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.billing.private.paymentgateway.ReplaceGatewayToBraintreeRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.billing.private.paymentgateway.ReplaceGatewayToBraintreeRequest.displayName = 'proto.billing.private.paymentgateway.ReplaceGatewayToBraintreeRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.billing.private.paymentgateway.CreateBraintreeGatewayResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.billing.private.paymentgateway.CreateBraintreeGatewayResponse.repeatedFields_, null);
};
goog.inherits(proto.billing.private.paymentgateway.CreateBraintreeGatewayResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.billing.private.paymentgateway.CreateBraintreeGatewayResponse.displayName = 'proto.billing.private.paymentgateway.CreateBraintreeGatewayResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.billing.private.paymentgateway.UpdateBraintreeGatewayRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.billing.private.paymentgateway.UpdateBraintreeGatewayRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.billing.private.paymentgateway.UpdateBraintreeGatewayRequest.displayName = 'proto.billing.private.paymentgateway.UpdateBraintreeGatewayRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.billing.private.paymentgateway.UpdateBraintreeGatewayResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.billing.private.paymentgateway.UpdateBraintreeGatewayResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.billing.private.paymentgateway.UpdateBraintreeGatewayResponse.displayName = 'proto.billing.private.paymentgateway.UpdateBraintreeGatewayResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.billing.private.paymentgateway.CreateAdyenGatewayRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.billing.private.paymentgateway.CreateAdyenGatewayRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.billing.private.paymentgateway.CreateAdyenGatewayRequest.displayName = 'proto.billing.private.paymentgateway.CreateAdyenGatewayRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.billing.private.paymentgateway.ReplaceGatewayToAdyenRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.billing.private.paymentgateway.ReplaceGatewayToAdyenRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.billing.private.paymentgateway.ReplaceGatewayToAdyenRequest.displayName = 'proto.billing.private.paymentgateway.ReplaceGatewayToAdyenRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.billing.private.paymentgateway.CreateAdyenGatewayResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.billing.private.paymentgateway.CreateAdyenGatewayResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.billing.private.paymentgateway.CreateAdyenGatewayResponse.displayName = 'proto.billing.private.paymentgateway.CreateAdyenGatewayResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.billing.private.paymentgateway.UpdateAdyenGatewayRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.billing.private.paymentgateway.UpdateAdyenGatewayRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.billing.private.paymentgateway.UpdateAdyenGatewayRequest.displayName = 'proto.billing.private.paymentgateway.UpdateAdyenGatewayRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.billing.private.paymentgateway.UpdateAdyenGatewayResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.billing.private.paymentgateway.UpdateAdyenGatewayResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.billing.private.paymentgateway.UpdateAdyenGatewayResponse.displayName = 'proto.billing.private.paymentgateway.UpdateAdyenGatewayResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.billing.private.paymentgateway.CreateAuthorizeDotNetGatewayRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.billing.private.paymentgateway.CreateAuthorizeDotNetGatewayRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.billing.private.paymentgateway.CreateAuthorizeDotNetGatewayRequest.displayName = 'proto.billing.private.paymentgateway.CreateAuthorizeDotNetGatewayRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.billing.private.paymentgateway.ReplaceGatewayToAuthorizeDotNetRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.billing.private.paymentgateway.ReplaceGatewayToAuthorizeDotNetRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.billing.private.paymentgateway.ReplaceGatewayToAuthorizeDotNetRequest.displayName = 'proto.billing.private.paymentgateway.ReplaceGatewayToAuthorizeDotNetRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.billing.private.paymentgateway.CreateAuthorizeDotNetGatewayResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.billing.private.paymentgateway.CreateAuthorizeDotNetGatewayResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.billing.private.paymentgateway.CreateAuthorizeDotNetGatewayResponse.displayName = 'proto.billing.private.paymentgateway.CreateAuthorizeDotNetGatewayResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.billing.private.paymentgateway.UpdateAuthorizeDotNetGatewayRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.billing.private.paymentgateway.UpdateAuthorizeDotNetGatewayRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.billing.private.paymentgateway.UpdateAuthorizeDotNetGatewayRequest.displayName = 'proto.billing.private.paymentgateway.UpdateAuthorizeDotNetGatewayRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.billing.private.paymentgateway.UpdateAuthorizeDotNetGatewayResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.billing.private.paymentgateway.UpdateAuthorizeDotNetGatewayResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.billing.private.paymentgateway.UpdateAuthorizeDotNetGatewayResponse.displayName = 'proto.billing.private.paymentgateway.UpdateAuthorizeDotNetGatewayResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.billing.private.paymentgateway.CreateIxopayGatewayRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.billing.private.paymentgateway.CreateIxopayGatewayRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.billing.private.paymentgateway.CreateIxopayGatewayRequest.displayName = 'proto.billing.private.paymentgateway.CreateIxopayGatewayRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.billing.private.paymentgateway.ReplaceGatewayToIxopayRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.billing.private.paymentgateway.ReplaceGatewayToIxopayRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.billing.private.paymentgateway.ReplaceGatewayToIxopayRequest.displayName = 'proto.billing.private.paymentgateway.ReplaceGatewayToIxopayRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.billing.private.paymentgateway.CreateIxopayGatewayResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.billing.private.paymentgateway.CreateIxopayGatewayResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.billing.private.paymentgateway.CreateIxopayGatewayResponse.displayName = 'proto.billing.private.paymentgateway.CreateIxopayGatewayResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.billing.private.paymentgateway.UpdateIxopayGatewayRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.billing.private.paymentgateway.UpdateIxopayGatewayRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.billing.private.paymentgateway.UpdateIxopayGatewayRequest.displayName = 'proto.billing.private.paymentgateway.UpdateIxopayGatewayRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.billing.private.paymentgateway.UpdateIxopayGatewayResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.billing.private.paymentgateway.UpdateIxopayGatewayResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.billing.private.paymentgateway.UpdateIxopayGatewayResponse.displayName = 'proto.billing.private.paymentgateway.UpdateIxopayGatewayResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.billing.private.paymentgateway.CreateNMIGatewayRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.billing.private.paymentgateway.CreateNMIGatewayRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.billing.private.paymentgateway.CreateNMIGatewayRequest.displayName = 'proto.billing.private.paymentgateway.CreateNMIGatewayRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.billing.private.paymentgateway.ReplaceGatewayToNMIRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.billing.private.paymentgateway.ReplaceGatewayToNMIRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.billing.private.paymentgateway.ReplaceGatewayToNMIRequest.displayName = 'proto.billing.private.paymentgateway.ReplaceGatewayToNMIRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.billing.private.paymentgateway.CreateNMIGatewayResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.billing.private.paymentgateway.CreateNMIGatewayResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.billing.private.paymentgateway.CreateNMIGatewayResponse.displayName = 'proto.billing.private.paymentgateway.CreateNMIGatewayResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.billing.private.paymentgateway.UpdateNMIGatewayRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.billing.private.paymentgateway.UpdateNMIGatewayRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.billing.private.paymentgateway.UpdateNMIGatewayRequest.displayName = 'proto.billing.private.paymentgateway.UpdateNMIGatewayRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.billing.private.paymentgateway.UpdateNMIGatewayResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.billing.private.paymentgateway.UpdateNMIGatewayResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.billing.private.paymentgateway.UpdateNMIGatewayResponse.displayName = 'proto.billing.private.paymentgateway.UpdateNMIGatewayResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.billing.private.paymentgateway.CreateCloverConnectRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.billing.private.paymentgateway.CreateCloverConnectRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.billing.private.paymentgateway.CreateCloverConnectRequest.displayName = 'proto.billing.private.paymentgateway.CreateCloverConnectRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.billing.private.paymentgateway.ReplaceGatewayToCloverConnectRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.billing.private.paymentgateway.ReplaceGatewayToCloverConnectRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.billing.private.paymentgateway.ReplaceGatewayToCloverConnectRequest.displayName = 'proto.billing.private.paymentgateway.ReplaceGatewayToCloverConnectRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.billing.private.paymentgateway.CreateCloverConnectResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.billing.private.paymentgateway.CreateCloverConnectResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.billing.private.paymentgateway.CreateCloverConnectResponse.displayName = 'proto.billing.private.paymentgateway.CreateCloverConnectResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.billing.private.paymentgateway.UpdateCloverConnectRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.billing.private.paymentgateway.UpdateCloverConnectRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.billing.private.paymentgateway.UpdateCloverConnectRequest.displayName = 'proto.billing.private.paymentgateway.UpdateCloverConnectRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.billing.private.paymentgateway.UpdateCloverConnectResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.billing.private.paymentgateway.UpdateCloverConnectResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.billing.private.paymentgateway.UpdateCloverConnectResponse.displayName = 'proto.billing.private.paymentgateway.UpdateCloverConnectResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.billing.private.paymentgateway.CreatePaymentCloudRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.billing.private.paymentgateway.CreatePaymentCloudRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.billing.private.paymentgateway.CreatePaymentCloudRequest.displayName = 'proto.billing.private.paymentgateway.CreatePaymentCloudRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.billing.private.paymentgateway.ReplaceGatewayToPaymentCloudRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.billing.private.paymentgateway.ReplaceGatewayToPaymentCloudRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.billing.private.paymentgateway.ReplaceGatewayToPaymentCloudRequest.displayName = 'proto.billing.private.paymentgateway.ReplaceGatewayToPaymentCloudRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.billing.private.paymentgateway.CreatePaymentCloudResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.billing.private.paymentgateway.CreatePaymentCloudResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.billing.private.paymentgateway.CreatePaymentCloudResponse.displayName = 'proto.billing.private.paymentgateway.CreatePaymentCloudResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.billing.private.paymentgateway.UpdatePaymentCloudRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.billing.private.paymentgateway.UpdatePaymentCloudRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.billing.private.paymentgateway.UpdatePaymentCloudRequest.displayName = 'proto.billing.private.paymentgateway.UpdatePaymentCloudRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.billing.private.paymentgateway.UpdatePaymentCloudResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.billing.private.paymentgateway.UpdatePaymentCloudResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.billing.private.paymentgateway.UpdatePaymentCloudResponse.displayName = 'proto.billing.private.paymentgateway.UpdatePaymentCloudResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.billing.private.paymentgateway.DeletePaymentGatewayRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.billing.private.paymentgateway.DeletePaymentGatewayRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.billing.private.paymentgateway.DeletePaymentGatewayRequest.displayName = 'proto.billing.private.paymentgateway.DeletePaymentGatewayRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.billing.private.paymentgateway.DeletePaymentGatewayResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.billing.private.paymentgateway.DeletePaymentGatewayResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.billing.private.paymentgateway.DeletePaymentGatewayResponse.displayName = 'proto.billing.private.paymentgateway.DeletePaymentGatewayResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.billing.private.paymentgateway.BraintreeMerchantAccount = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.billing.private.paymentgateway.BraintreeMerchantAccount, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.billing.private.paymentgateway.BraintreeMerchantAccount.displayName = 'proto.billing.private.paymentgateway.BraintreeMerchantAccount';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.billing.private.paymentgateway.GetSpreedlyTransactionRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.billing.private.paymentgateway.GetSpreedlyTransactionRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.billing.private.paymentgateway.GetSpreedlyTransactionRequest.displayName = 'proto.billing.private.paymentgateway.GetSpreedlyTransactionRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.billing.private.paymentgateway.GetSpreedlyTransactionResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.billing.private.paymentgateway.GetSpreedlyTransactionResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.billing.private.paymentgateway.GetSpreedlyTransactionResponse.displayName = 'proto.billing.private.paymentgateway.GetSpreedlyTransactionResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.billing.private.paymentgateway.ResponseCodesAndDescription = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.billing.private.paymentgateway.ResponseCodesAndDescription.repeatedFields_, null);
};
goog.inherits(proto.billing.private.paymentgateway.ResponseCodesAndDescription, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.billing.private.paymentgateway.ResponseCodesAndDescription.displayName = 'proto.billing.private.paymentgateway.ResponseCodesAndDescription';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.billing.private.paymentgateway.ErrorCodeAndDescription = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.billing.private.paymentgateway.ErrorCodeAndDescription, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.billing.private.paymentgateway.ErrorCodeAndDescription.displayName = 'proto.billing.private.paymentgateway.ErrorCodeAndDescription';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.billing.private.paymentgateway.GetTransactionLogRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.billing.private.paymentgateway.GetTransactionLogRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.billing.private.paymentgateway.GetTransactionLogRequest.displayName = 'proto.billing.private.paymentgateway.GetTransactionLogRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.billing.private.paymentgateway.GetTransactionLogResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.billing.private.paymentgateway.GetTransactionLogResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.billing.private.paymentgateway.GetTransactionLogResponse.displayName = 'proto.billing.private.paymentgateway.GetTransactionLogResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.billing.private.paymentgateway.PaymentLogErrorViewModel = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.billing.private.paymentgateway.PaymentLogErrorViewModel, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.billing.private.paymentgateway.PaymentLogErrorViewModel.displayName = 'proto.billing.private.paymentgateway.PaymentLogErrorViewModel';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.billing.private.paymentgateway.GetGatewayScaDetailsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.billing.private.paymentgateway.GetGatewayScaDetailsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.billing.private.paymentgateway.GetGatewayScaDetailsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing.private.paymentgateway.GetGatewayScaDetailsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    companyDomain: jspb.Message.getFieldWithDefault(msg, 1, ""),
    paymentGatewayType: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.billing.private.paymentgateway.GetGatewayScaDetailsRequest}
 */
proto.billing.private.paymentgateway.GetGatewayScaDetailsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.billing.private.paymentgateway.GetGatewayScaDetailsRequest;
  return proto.billing.private.paymentgateway.GetGatewayScaDetailsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.billing.private.paymentgateway.GetGatewayScaDetailsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.billing.private.paymentgateway.GetGatewayScaDetailsRequest}
 */
proto.billing.private.paymentgateway.GetGatewayScaDetailsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setCompanyDomain(value);
      break;
    case 2:
      var value = /** @type {!proto.billing.private.paymentgateway.GetGatewayScaDetailsRequest.PaymentGateTypes} */ (reader.readEnum());
      msg.setPaymentGatewayType(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.billing.private.paymentgateway.GetGatewayScaDetailsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.billing.private.paymentgateway.GetGatewayScaDetailsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.billing.private.paymentgateway.GetGatewayScaDetailsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing.private.paymentgateway.GetGatewayScaDetailsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCompanyDomain();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getPaymentGatewayType();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
};


/**
 * @enum {number}
 */
proto.billing.private.paymentgateway.GetGatewayScaDetailsRequest.PaymentGateTypes = {
  SPREEDLY_TEST: 0,
  STRIPE: 1,
  CHECKOUT_DOT_COM: 2,
  BRAINTREE: 3,
  ADYEN: 4,
  AUTHORIZE_DOT_NET: 5,
  IXOPAY: 6,
  NMI: 7,
  CLOVER_CONNECT: 8,
  PAYMENT_CLOUD: 9,
  STRIPE_INTENT: 10
};

/**
 * optional string company_domain = 1;
 * @return {string}
 */
proto.billing.private.paymentgateway.GetGatewayScaDetailsRequest.prototype.getCompanyDomain = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.billing.private.paymentgateway.GetGatewayScaDetailsRequest} returns this
 */
proto.billing.private.paymentgateway.GetGatewayScaDetailsRequest.prototype.setCompanyDomain = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional PaymentGateTypes payment_gateway_type = 2;
 * @return {!proto.billing.private.paymentgateway.GetGatewayScaDetailsRequest.PaymentGateTypes}
 */
proto.billing.private.paymentgateway.GetGatewayScaDetailsRequest.prototype.getPaymentGatewayType = function() {
  return /** @type {!proto.billing.private.paymentgateway.GetGatewayScaDetailsRequest.PaymentGateTypes} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.billing.private.paymentgateway.GetGatewayScaDetailsRequest.PaymentGateTypes} value
 * @return {!proto.billing.private.paymentgateway.GetGatewayScaDetailsRequest} returns this
 */
proto.billing.private.paymentgateway.GetGatewayScaDetailsRequest.prototype.setPaymentGatewayType = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.billing.private.paymentgateway.GetGatewayScaDetailsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.billing.private.paymentgateway.GetGatewayScaDetailsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.billing.private.paymentgateway.GetGatewayScaDetailsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing.private.paymentgateway.GetGatewayScaDetailsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    minimumChargeAmount: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.billing.private.paymentgateway.GetGatewayScaDetailsResponse}
 */
proto.billing.private.paymentgateway.GetGatewayScaDetailsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.billing.private.paymentgateway.GetGatewayScaDetailsResponse;
  return proto.billing.private.paymentgateway.GetGatewayScaDetailsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.billing.private.paymentgateway.GetGatewayScaDetailsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.billing.private.paymentgateway.GetGatewayScaDetailsResponse}
 */
proto.billing.private.paymentgateway.GetGatewayScaDetailsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setMinimumChargeAmount(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.billing.private.paymentgateway.GetGatewayScaDetailsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.billing.private.paymentgateway.GetGatewayScaDetailsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.billing.private.paymentgateway.GetGatewayScaDetailsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing.private.paymentgateway.GetGatewayScaDetailsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMinimumChargeAmount();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string minimum_charge_amount = 1;
 * @return {string}
 */
proto.billing.private.paymentgateway.GetGatewayScaDetailsResponse.prototype.getMinimumChargeAmount = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.billing.private.paymentgateway.GetGatewayScaDetailsResponse} returns this
 */
proto.billing.private.paymentgateway.GetGatewayScaDetailsResponse.prototype.setMinimumChargeAmount = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.billing.private.paymentgateway.StartEnableSCARequest.prototype.toObject = function(opt_includeInstance) {
  return proto.billing.private.paymentgateway.StartEnableSCARequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.billing.private.paymentgateway.StartEnableSCARequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing.private.paymentgateway.StartEnableSCARequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    companyDomain: jspb.Message.getFieldWithDefault(msg, 1, ""),
    paymentGatewayId: jspb.Message.getFieldWithDefault(msg, 2, 0),
    testCardFullName: jspb.Message.getFieldWithDefault(msg, 3, ""),
    testCardPaymentMethodToken: jspb.Message.getFieldWithDefault(msg, 4, ""),
    webhookId: jspb.Message.getFieldWithDefault(msg, 5, ""),
    webhookSigningSecret: jspb.Message.getFieldWithDefault(msg, 6, ""),
    browserInfo: jspb.Message.getFieldWithDefault(msg, 7, ""),
    gatewaySecretKey: jspb.Message.getFieldWithDefault(msg, 8, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.billing.private.paymentgateway.StartEnableSCARequest}
 */
proto.billing.private.paymentgateway.StartEnableSCARequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.billing.private.paymentgateway.StartEnableSCARequest;
  return proto.billing.private.paymentgateway.StartEnableSCARequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.billing.private.paymentgateway.StartEnableSCARequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.billing.private.paymentgateway.StartEnableSCARequest}
 */
proto.billing.private.paymentgateway.StartEnableSCARequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setCompanyDomain(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPaymentGatewayId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setTestCardFullName(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setTestCardPaymentMethodToken(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setWebhookId(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setWebhookSigningSecret(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setBrowserInfo(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setGatewaySecretKey(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.billing.private.paymentgateway.StartEnableSCARequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.billing.private.paymentgateway.StartEnableSCARequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.billing.private.paymentgateway.StartEnableSCARequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing.private.paymentgateway.StartEnableSCARequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCompanyDomain();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getPaymentGatewayId();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getTestCardFullName();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getTestCardPaymentMethodToken();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getWebhookId();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getWebhookSigningSecret();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getBrowserInfo();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getGatewaySecretKey();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
};


/**
 * optional string company_domain = 1;
 * @return {string}
 */
proto.billing.private.paymentgateway.StartEnableSCARequest.prototype.getCompanyDomain = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.billing.private.paymentgateway.StartEnableSCARequest} returns this
 */
proto.billing.private.paymentgateway.StartEnableSCARequest.prototype.setCompanyDomain = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int32 payment_gateway_id = 2;
 * @return {number}
 */
proto.billing.private.paymentgateway.StartEnableSCARequest.prototype.getPaymentGatewayId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.billing.private.paymentgateway.StartEnableSCARequest} returns this
 */
proto.billing.private.paymentgateway.StartEnableSCARequest.prototype.setPaymentGatewayId = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional string test_card_full_name = 3;
 * @return {string}
 */
proto.billing.private.paymentgateway.StartEnableSCARequest.prototype.getTestCardFullName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.billing.private.paymentgateway.StartEnableSCARequest} returns this
 */
proto.billing.private.paymentgateway.StartEnableSCARequest.prototype.setTestCardFullName = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string test_card_payment_method_token = 4;
 * @return {string}
 */
proto.billing.private.paymentgateway.StartEnableSCARequest.prototype.getTestCardPaymentMethodToken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.billing.private.paymentgateway.StartEnableSCARequest} returns this
 */
proto.billing.private.paymentgateway.StartEnableSCARequest.prototype.setTestCardPaymentMethodToken = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string webhook_id = 5;
 * @return {string}
 */
proto.billing.private.paymentgateway.StartEnableSCARequest.prototype.getWebhookId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.billing.private.paymentgateway.StartEnableSCARequest} returns this
 */
proto.billing.private.paymentgateway.StartEnableSCARequest.prototype.setWebhookId = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string webhook_signing_secret = 6;
 * @return {string}
 */
proto.billing.private.paymentgateway.StartEnableSCARequest.prototype.getWebhookSigningSecret = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.billing.private.paymentgateway.StartEnableSCARequest} returns this
 */
proto.billing.private.paymentgateway.StartEnableSCARequest.prototype.setWebhookSigningSecret = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string browser_info = 7;
 * @return {string}
 */
proto.billing.private.paymentgateway.StartEnableSCARequest.prototype.getBrowserInfo = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.billing.private.paymentgateway.StartEnableSCARequest} returns this
 */
proto.billing.private.paymentgateway.StartEnableSCARequest.prototype.setBrowserInfo = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string gateway_secret_key = 8;
 * @return {string}
 */
proto.billing.private.paymentgateway.StartEnableSCARequest.prototype.getGatewaySecretKey = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.billing.private.paymentgateway.StartEnableSCARequest} returns this
 */
proto.billing.private.paymentgateway.StartEnableSCARequest.prototype.setGatewaySecretKey = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.billing.private.paymentgateway.StartEnableSCAResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.billing.private.paymentgateway.StartEnableSCAResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.billing.private.paymentgateway.StartEnableSCAResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing.private.paymentgateway.StartEnableSCAResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    isThreeDsRequired: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    scaTransactionId: (f = msg.getScaTransactionId()) && google_protobuf_wrappers_pb.Int32Value.toObject(includeInstance, f),
    transactionToken: jspb.Message.getFieldWithDefault(msg, 3, ""),
    transactionCheckoutUrl: jspb.Message.getFieldWithDefault(msg, 4, ""),
    transactionCheckoutForm: jspb.Message.getFieldWithDefault(msg, 5, ""),
    paymentGatewayId: jspb.Message.getFieldWithDefault(msg, 6, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.billing.private.paymentgateway.StartEnableSCAResponse}
 */
proto.billing.private.paymentgateway.StartEnableSCAResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.billing.private.paymentgateway.StartEnableSCAResponse;
  return proto.billing.private.paymentgateway.StartEnableSCAResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.billing.private.paymentgateway.StartEnableSCAResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.billing.private.paymentgateway.StartEnableSCAResponse}
 */
proto.billing.private.paymentgateway.StartEnableSCAResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsThreeDsRequired(value);
      break;
    case 2:
      var value = new google_protobuf_wrappers_pb.Int32Value;
      reader.readMessage(value,google_protobuf_wrappers_pb.Int32Value.deserializeBinaryFromReader);
      msg.setScaTransactionId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setTransactionToken(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setTransactionCheckoutUrl(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setTransactionCheckoutForm(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPaymentGatewayId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.billing.private.paymentgateway.StartEnableSCAResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.billing.private.paymentgateway.StartEnableSCAResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.billing.private.paymentgateway.StartEnableSCAResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing.private.paymentgateway.StartEnableSCAResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getIsThreeDsRequired();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getScaTransactionId();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      google_protobuf_wrappers_pb.Int32Value.serializeBinaryToWriter
    );
  }
  f = message.getTransactionToken();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getTransactionCheckoutUrl();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getTransactionCheckoutForm();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getPaymentGatewayId();
  if (f !== 0) {
    writer.writeInt32(
      6,
      f
    );
  }
};


/**
 * optional bool is_three_ds_required = 1;
 * @return {boolean}
 */
proto.billing.private.paymentgateway.StartEnableSCAResponse.prototype.getIsThreeDsRequired = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.billing.private.paymentgateway.StartEnableSCAResponse} returns this
 */
proto.billing.private.paymentgateway.StartEnableSCAResponse.prototype.setIsThreeDsRequired = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional google.protobuf.Int32Value sca_transaction_id = 2;
 * @return {?proto.google.protobuf.Int32Value}
 */
proto.billing.private.paymentgateway.StartEnableSCAResponse.prototype.getScaTransactionId = function() {
  return /** @type{?proto.google.protobuf.Int32Value} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.Int32Value, 2));
};


/**
 * @param {?proto.google.protobuf.Int32Value|undefined} value
 * @return {!proto.billing.private.paymentgateway.StartEnableSCAResponse} returns this
*/
proto.billing.private.paymentgateway.StartEnableSCAResponse.prototype.setScaTransactionId = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.billing.private.paymentgateway.StartEnableSCAResponse} returns this
 */
proto.billing.private.paymentgateway.StartEnableSCAResponse.prototype.clearScaTransactionId = function() {
  return this.setScaTransactionId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.billing.private.paymentgateway.StartEnableSCAResponse.prototype.hasScaTransactionId = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional string transaction_token = 3;
 * @return {string}
 */
proto.billing.private.paymentgateway.StartEnableSCAResponse.prototype.getTransactionToken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.billing.private.paymentgateway.StartEnableSCAResponse} returns this
 */
proto.billing.private.paymentgateway.StartEnableSCAResponse.prototype.setTransactionToken = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string transaction_checkout_url = 4;
 * @return {string}
 */
proto.billing.private.paymentgateway.StartEnableSCAResponse.prototype.getTransactionCheckoutUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.billing.private.paymentgateway.StartEnableSCAResponse} returns this
 */
proto.billing.private.paymentgateway.StartEnableSCAResponse.prototype.setTransactionCheckoutUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string transaction_checkout_form = 5;
 * @return {string}
 */
proto.billing.private.paymentgateway.StartEnableSCAResponse.prototype.getTransactionCheckoutForm = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.billing.private.paymentgateway.StartEnableSCAResponse} returns this
 */
proto.billing.private.paymentgateway.StartEnableSCAResponse.prototype.setTransactionCheckoutForm = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional int32 payment_gateway_id = 6;
 * @return {number}
 */
proto.billing.private.paymentgateway.StartEnableSCAResponse.prototype.getPaymentGatewayId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.billing.private.paymentgateway.StartEnableSCAResponse} returns this
 */
proto.billing.private.paymentgateway.StartEnableSCAResponse.prototype.setPaymentGatewayId = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.billing.private.paymentgateway.CompleteEnableSCARequest.prototype.toObject = function(opt_includeInstance) {
  return proto.billing.private.paymentgateway.CompleteEnableSCARequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.billing.private.paymentgateway.CompleteEnableSCARequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing.private.paymentgateway.CompleteEnableSCARequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    companyDomain: jspb.Message.getFieldWithDefault(msg, 1, ""),
    scaTransactionId: jspb.Message.getFieldWithDefault(msg, 2, 0),
    paymentGatewayId: jspb.Message.getFieldWithDefault(msg, 3, 0),
    isSuccessful: jspb.Message.getBooleanFieldWithDefault(msg, 4, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.billing.private.paymentgateway.CompleteEnableSCARequest}
 */
proto.billing.private.paymentgateway.CompleteEnableSCARequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.billing.private.paymentgateway.CompleteEnableSCARequest;
  return proto.billing.private.paymentgateway.CompleteEnableSCARequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.billing.private.paymentgateway.CompleteEnableSCARequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.billing.private.paymentgateway.CompleteEnableSCARequest}
 */
proto.billing.private.paymentgateway.CompleteEnableSCARequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setCompanyDomain(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setScaTransactionId(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPaymentGatewayId(value);
      break;
    case 4:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsSuccessful(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.billing.private.paymentgateway.CompleteEnableSCARequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.billing.private.paymentgateway.CompleteEnableSCARequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.billing.private.paymentgateway.CompleteEnableSCARequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing.private.paymentgateway.CompleteEnableSCARequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCompanyDomain();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getScaTransactionId();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getPaymentGatewayId();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
  f = message.getIsSuccessful();
  if (f) {
    writer.writeBool(
      4,
      f
    );
  }
};


/**
 * optional string company_domain = 1;
 * @return {string}
 */
proto.billing.private.paymentgateway.CompleteEnableSCARequest.prototype.getCompanyDomain = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.billing.private.paymentgateway.CompleteEnableSCARequest} returns this
 */
proto.billing.private.paymentgateway.CompleteEnableSCARequest.prototype.setCompanyDomain = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int32 sca_transaction_id = 2;
 * @return {number}
 */
proto.billing.private.paymentgateway.CompleteEnableSCARequest.prototype.getScaTransactionId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.billing.private.paymentgateway.CompleteEnableSCARequest} returns this
 */
proto.billing.private.paymentgateway.CompleteEnableSCARequest.prototype.setScaTransactionId = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int32 payment_gateway_id = 3;
 * @return {number}
 */
proto.billing.private.paymentgateway.CompleteEnableSCARequest.prototype.getPaymentGatewayId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.billing.private.paymentgateway.CompleteEnableSCARequest} returns this
 */
proto.billing.private.paymentgateway.CompleteEnableSCARequest.prototype.setPaymentGatewayId = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional bool is_successful = 4;
 * @return {boolean}
 */
proto.billing.private.paymentgateway.CompleteEnableSCARequest.prototype.getIsSuccessful = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 4, false));
};


/**
 * @param {boolean} value
 * @return {!proto.billing.private.paymentgateway.CompleteEnableSCARequest} returns this
 */
proto.billing.private.paymentgateway.CompleteEnableSCARequest.prototype.setIsSuccessful = function(value) {
  return jspb.Message.setProto3BooleanField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.billing.private.paymentgateway.CompleteEnableSCAResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.billing.private.paymentgateway.CompleteEnableSCAResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.billing.private.paymentgateway.CompleteEnableSCAResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing.private.paymentgateway.CompleteEnableSCAResponse.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.billing.private.paymentgateway.CompleteEnableSCAResponse}
 */
proto.billing.private.paymentgateway.CompleteEnableSCAResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.billing.private.paymentgateway.CompleteEnableSCAResponse;
  return proto.billing.private.paymentgateway.CompleteEnableSCAResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.billing.private.paymentgateway.CompleteEnableSCAResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.billing.private.paymentgateway.CompleteEnableSCAResponse}
 */
proto.billing.private.paymentgateway.CompleteEnableSCAResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.billing.private.paymentgateway.CompleteEnableSCAResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.billing.private.paymentgateway.CompleteEnableSCAResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.billing.private.paymentgateway.CompleteEnableSCAResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing.private.paymentgateway.CompleteEnableSCAResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.billing.private.paymentgateway.CreateStripeIntentGatewayRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.billing.private.paymentgateway.CreateStripeIntentGatewayRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.billing.private.paymentgateway.CreateStripeIntentGatewayRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing.private.paymentgateway.CreateStripeIntentGatewayRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    companyDomain: jspb.Message.getFieldWithDefault(msg, 1, ""),
    authorizationCode: jspb.Message.getFieldWithDefault(msg, 2, ""),
    displayName: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.billing.private.paymentgateway.CreateStripeIntentGatewayRequest}
 */
proto.billing.private.paymentgateway.CreateStripeIntentGatewayRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.billing.private.paymentgateway.CreateStripeIntentGatewayRequest;
  return proto.billing.private.paymentgateway.CreateStripeIntentGatewayRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.billing.private.paymentgateway.CreateStripeIntentGatewayRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.billing.private.paymentgateway.CreateStripeIntentGatewayRequest}
 */
proto.billing.private.paymentgateway.CreateStripeIntentGatewayRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setCompanyDomain(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setAuthorizationCode(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setDisplayName(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.billing.private.paymentgateway.CreateStripeIntentGatewayRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.billing.private.paymentgateway.CreateStripeIntentGatewayRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.billing.private.paymentgateway.CreateStripeIntentGatewayRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing.private.paymentgateway.CreateStripeIntentGatewayRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCompanyDomain();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getAuthorizationCode();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getDisplayName();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional string company_domain = 1;
 * @return {string}
 */
proto.billing.private.paymentgateway.CreateStripeIntentGatewayRequest.prototype.getCompanyDomain = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.billing.private.paymentgateway.CreateStripeIntentGatewayRequest} returns this
 */
proto.billing.private.paymentgateway.CreateStripeIntentGatewayRequest.prototype.setCompanyDomain = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string authorization_code = 2;
 * @return {string}
 */
proto.billing.private.paymentgateway.CreateStripeIntentGatewayRequest.prototype.getAuthorizationCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.billing.private.paymentgateway.CreateStripeIntentGatewayRequest} returns this
 */
proto.billing.private.paymentgateway.CreateStripeIntentGatewayRequest.prototype.setAuthorizationCode = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string display_name = 3;
 * @return {string}
 */
proto.billing.private.paymentgateway.CreateStripeIntentGatewayRequest.prototype.getDisplayName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.billing.private.paymentgateway.CreateStripeIntentGatewayRequest} returns this
 */
proto.billing.private.paymentgateway.CreateStripeIntentGatewayRequest.prototype.setDisplayName = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.billing.private.paymentgateway.ReplaceGatewayToStripeIntentGatewayRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.billing.private.paymentgateway.ReplaceGatewayToStripeIntentGatewayRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.billing.private.paymentgateway.ReplaceGatewayToStripeIntentGatewayRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing.private.paymentgateway.ReplaceGatewayToStripeIntentGatewayRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    createStripeRequest: (f = msg.getCreateStripeRequest()) && proto.billing.private.paymentgateway.CreateStripeIntentGatewayRequest.toObject(includeInstance, f),
    paymentGatewayToReplaceId: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.billing.private.paymentgateway.ReplaceGatewayToStripeIntentGatewayRequest}
 */
proto.billing.private.paymentgateway.ReplaceGatewayToStripeIntentGatewayRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.billing.private.paymentgateway.ReplaceGatewayToStripeIntentGatewayRequest;
  return proto.billing.private.paymentgateway.ReplaceGatewayToStripeIntentGatewayRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.billing.private.paymentgateway.ReplaceGatewayToStripeIntentGatewayRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.billing.private.paymentgateway.ReplaceGatewayToStripeIntentGatewayRequest}
 */
proto.billing.private.paymentgateway.ReplaceGatewayToStripeIntentGatewayRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.billing.private.paymentgateway.CreateStripeIntentGatewayRequest;
      reader.readMessage(value,proto.billing.private.paymentgateway.CreateStripeIntentGatewayRequest.deserializeBinaryFromReader);
      msg.setCreateStripeRequest(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPaymentGatewayToReplaceId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.billing.private.paymentgateway.ReplaceGatewayToStripeIntentGatewayRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.billing.private.paymentgateway.ReplaceGatewayToStripeIntentGatewayRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.billing.private.paymentgateway.ReplaceGatewayToStripeIntentGatewayRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing.private.paymentgateway.ReplaceGatewayToStripeIntentGatewayRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCreateStripeRequest();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.billing.private.paymentgateway.CreateStripeIntentGatewayRequest.serializeBinaryToWriter
    );
  }
  f = message.getPaymentGatewayToReplaceId();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
};


/**
 * optional CreateStripeIntentGatewayRequest create_stripe_request = 1;
 * @return {?proto.billing.private.paymentgateway.CreateStripeIntentGatewayRequest}
 */
proto.billing.private.paymentgateway.ReplaceGatewayToStripeIntentGatewayRequest.prototype.getCreateStripeRequest = function() {
  return /** @type{?proto.billing.private.paymentgateway.CreateStripeIntentGatewayRequest} */ (
    jspb.Message.getWrapperField(this, proto.billing.private.paymentgateway.CreateStripeIntentGatewayRequest, 1));
};


/**
 * @param {?proto.billing.private.paymentgateway.CreateStripeIntentGatewayRequest|undefined} value
 * @return {!proto.billing.private.paymentgateway.ReplaceGatewayToStripeIntentGatewayRequest} returns this
*/
proto.billing.private.paymentgateway.ReplaceGatewayToStripeIntentGatewayRequest.prototype.setCreateStripeRequest = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.billing.private.paymentgateway.ReplaceGatewayToStripeIntentGatewayRequest} returns this
 */
proto.billing.private.paymentgateway.ReplaceGatewayToStripeIntentGatewayRequest.prototype.clearCreateStripeRequest = function() {
  return this.setCreateStripeRequest(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.billing.private.paymentgateway.ReplaceGatewayToStripeIntentGatewayRequest.prototype.hasCreateStripeRequest = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional int32 payment_gateway_to_replace_id = 2;
 * @return {number}
 */
proto.billing.private.paymentgateway.ReplaceGatewayToStripeIntentGatewayRequest.prototype.getPaymentGatewayToReplaceId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.billing.private.paymentgateway.ReplaceGatewayToStripeIntentGatewayRequest} returns this
 */
proto.billing.private.paymentgateway.ReplaceGatewayToStripeIntentGatewayRequest.prototype.setPaymentGatewayToReplaceId = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.billing.private.paymentgateway.CreateStripeIntentGatewayResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.billing.private.paymentgateway.CreateStripeIntentGatewayResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.billing.private.paymentgateway.CreateStripeIntentGatewayResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing.private.paymentgateway.CreateStripeIntentGatewayResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    paymentGatewayId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    displayName: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.billing.private.paymentgateway.CreateStripeIntentGatewayResponse}
 */
proto.billing.private.paymentgateway.CreateStripeIntentGatewayResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.billing.private.paymentgateway.CreateStripeIntentGatewayResponse;
  return proto.billing.private.paymentgateway.CreateStripeIntentGatewayResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.billing.private.paymentgateway.CreateStripeIntentGatewayResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.billing.private.paymentgateway.CreateStripeIntentGatewayResponse}
 */
proto.billing.private.paymentgateway.CreateStripeIntentGatewayResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPaymentGatewayId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setDisplayName(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.billing.private.paymentgateway.CreateStripeIntentGatewayResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.billing.private.paymentgateway.CreateStripeIntentGatewayResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.billing.private.paymentgateway.CreateStripeIntentGatewayResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing.private.paymentgateway.CreateStripeIntentGatewayResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPaymentGatewayId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getDisplayName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional int32 payment_gateway_id = 1;
 * @return {number}
 */
proto.billing.private.paymentgateway.CreateStripeIntentGatewayResponse.prototype.getPaymentGatewayId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.billing.private.paymentgateway.CreateStripeIntentGatewayResponse} returns this
 */
proto.billing.private.paymentgateway.CreateStripeIntentGatewayResponse.prototype.setPaymentGatewayId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string display_name = 2;
 * @return {string}
 */
proto.billing.private.paymentgateway.CreateStripeIntentGatewayResponse.prototype.getDisplayName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.billing.private.paymentgateway.CreateStripeIntentGatewayResponse} returns this
 */
proto.billing.private.paymentgateway.CreateStripeIntentGatewayResponse.prototype.setDisplayName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.billing.private.paymentgateway.UpdateStripeGatewayRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.billing.private.paymentgateway.UpdateStripeGatewayRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.billing.private.paymentgateway.UpdateStripeGatewayRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing.private.paymentgateway.UpdateStripeGatewayRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    companyDomain: jspb.Message.getFieldWithDefault(msg, 1, ""),
    paymentGatewayId: jspb.Message.getFieldWithDefault(msg, 2, 0),
    displayName: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.billing.private.paymentgateway.UpdateStripeGatewayRequest}
 */
proto.billing.private.paymentgateway.UpdateStripeGatewayRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.billing.private.paymentgateway.UpdateStripeGatewayRequest;
  return proto.billing.private.paymentgateway.UpdateStripeGatewayRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.billing.private.paymentgateway.UpdateStripeGatewayRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.billing.private.paymentgateway.UpdateStripeGatewayRequest}
 */
proto.billing.private.paymentgateway.UpdateStripeGatewayRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setCompanyDomain(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPaymentGatewayId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setDisplayName(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.billing.private.paymentgateway.UpdateStripeGatewayRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.billing.private.paymentgateway.UpdateStripeGatewayRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.billing.private.paymentgateway.UpdateStripeGatewayRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing.private.paymentgateway.UpdateStripeGatewayRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCompanyDomain();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getPaymentGatewayId();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getDisplayName();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional string company_domain = 1;
 * @return {string}
 */
proto.billing.private.paymentgateway.UpdateStripeGatewayRequest.prototype.getCompanyDomain = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.billing.private.paymentgateway.UpdateStripeGatewayRequest} returns this
 */
proto.billing.private.paymentgateway.UpdateStripeGatewayRequest.prototype.setCompanyDomain = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int32 payment_gateway_id = 2;
 * @return {number}
 */
proto.billing.private.paymentgateway.UpdateStripeGatewayRequest.prototype.getPaymentGatewayId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.billing.private.paymentgateway.UpdateStripeGatewayRequest} returns this
 */
proto.billing.private.paymentgateway.UpdateStripeGatewayRequest.prototype.setPaymentGatewayId = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional string display_name = 3;
 * @return {string}
 */
proto.billing.private.paymentgateway.UpdateStripeGatewayRequest.prototype.getDisplayName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.billing.private.paymentgateway.UpdateStripeGatewayRequest} returns this
 */
proto.billing.private.paymentgateway.UpdateStripeGatewayRequest.prototype.setDisplayName = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.billing.private.paymentgateway.UpdateStripeGatewayResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.billing.private.paymentgateway.UpdateStripeGatewayResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.billing.private.paymentgateway.UpdateStripeGatewayResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing.private.paymentgateway.UpdateStripeGatewayResponse.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.billing.private.paymentgateway.UpdateStripeGatewayResponse}
 */
proto.billing.private.paymentgateway.UpdateStripeGatewayResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.billing.private.paymentgateway.UpdateStripeGatewayResponse;
  return proto.billing.private.paymentgateway.UpdateStripeGatewayResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.billing.private.paymentgateway.UpdateStripeGatewayResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.billing.private.paymentgateway.UpdateStripeGatewayResponse}
 */
proto.billing.private.paymentgateway.UpdateStripeGatewayResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.billing.private.paymentgateway.UpdateStripeGatewayResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.billing.private.paymentgateway.UpdateStripeGatewayResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.billing.private.paymentgateway.UpdateStripeGatewayResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing.private.paymentgateway.UpdateStripeGatewayResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.billing.private.paymentgateway.CreateCheckoutDotComGatewayRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.billing.private.paymentgateway.CreateCheckoutDotComGatewayRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.billing.private.paymentgateway.CreateCheckoutDotComGatewayRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing.private.paymentgateway.CreateCheckoutDotComGatewayRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    companyDomain: jspb.Message.getFieldWithDefault(msg, 1, ""),
    secretKey: jspb.Message.getFieldWithDefault(msg, 2, ""),
    displayName: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.billing.private.paymentgateway.CreateCheckoutDotComGatewayRequest}
 */
proto.billing.private.paymentgateway.CreateCheckoutDotComGatewayRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.billing.private.paymentgateway.CreateCheckoutDotComGatewayRequest;
  return proto.billing.private.paymentgateway.CreateCheckoutDotComGatewayRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.billing.private.paymentgateway.CreateCheckoutDotComGatewayRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.billing.private.paymentgateway.CreateCheckoutDotComGatewayRequest}
 */
proto.billing.private.paymentgateway.CreateCheckoutDotComGatewayRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setCompanyDomain(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setSecretKey(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setDisplayName(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.billing.private.paymentgateway.CreateCheckoutDotComGatewayRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.billing.private.paymentgateway.CreateCheckoutDotComGatewayRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.billing.private.paymentgateway.CreateCheckoutDotComGatewayRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing.private.paymentgateway.CreateCheckoutDotComGatewayRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCompanyDomain();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getSecretKey();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getDisplayName();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional string company_domain = 1;
 * @return {string}
 */
proto.billing.private.paymentgateway.CreateCheckoutDotComGatewayRequest.prototype.getCompanyDomain = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.billing.private.paymentgateway.CreateCheckoutDotComGatewayRequest} returns this
 */
proto.billing.private.paymentgateway.CreateCheckoutDotComGatewayRequest.prototype.setCompanyDomain = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string secret_key = 2;
 * @return {string}
 */
proto.billing.private.paymentgateway.CreateCheckoutDotComGatewayRequest.prototype.getSecretKey = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.billing.private.paymentgateway.CreateCheckoutDotComGatewayRequest} returns this
 */
proto.billing.private.paymentgateway.CreateCheckoutDotComGatewayRequest.prototype.setSecretKey = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string display_name = 3;
 * @return {string}
 */
proto.billing.private.paymentgateway.CreateCheckoutDotComGatewayRequest.prototype.getDisplayName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.billing.private.paymentgateway.CreateCheckoutDotComGatewayRequest} returns this
 */
proto.billing.private.paymentgateway.CreateCheckoutDotComGatewayRequest.prototype.setDisplayName = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.billing.private.paymentgateway.ReplaceGatewayToCheckoutDotComRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.billing.private.paymentgateway.ReplaceGatewayToCheckoutDotComRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.billing.private.paymentgateway.ReplaceGatewayToCheckoutDotComRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing.private.paymentgateway.ReplaceGatewayToCheckoutDotComRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    createCheckoutGateway: (f = msg.getCreateCheckoutGateway()) && proto.billing.private.paymentgateway.CreateCheckoutDotComGatewayRequest.toObject(includeInstance, f),
    paymentGatewayToReplaceId: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.billing.private.paymentgateway.ReplaceGatewayToCheckoutDotComRequest}
 */
proto.billing.private.paymentgateway.ReplaceGatewayToCheckoutDotComRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.billing.private.paymentgateway.ReplaceGatewayToCheckoutDotComRequest;
  return proto.billing.private.paymentgateway.ReplaceGatewayToCheckoutDotComRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.billing.private.paymentgateway.ReplaceGatewayToCheckoutDotComRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.billing.private.paymentgateway.ReplaceGatewayToCheckoutDotComRequest}
 */
proto.billing.private.paymentgateway.ReplaceGatewayToCheckoutDotComRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.billing.private.paymentgateway.CreateCheckoutDotComGatewayRequest;
      reader.readMessage(value,proto.billing.private.paymentgateway.CreateCheckoutDotComGatewayRequest.deserializeBinaryFromReader);
      msg.setCreateCheckoutGateway(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPaymentGatewayToReplaceId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.billing.private.paymentgateway.ReplaceGatewayToCheckoutDotComRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.billing.private.paymentgateway.ReplaceGatewayToCheckoutDotComRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.billing.private.paymentgateway.ReplaceGatewayToCheckoutDotComRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing.private.paymentgateway.ReplaceGatewayToCheckoutDotComRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCreateCheckoutGateway();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.billing.private.paymentgateway.CreateCheckoutDotComGatewayRequest.serializeBinaryToWriter
    );
  }
  f = message.getPaymentGatewayToReplaceId();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
};


/**
 * optional CreateCheckoutDotComGatewayRequest create_checkout_gateway = 1;
 * @return {?proto.billing.private.paymentgateway.CreateCheckoutDotComGatewayRequest}
 */
proto.billing.private.paymentgateway.ReplaceGatewayToCheckoutDotComRequest.prototype.getCreateCheckoutGateway = function() {
  return /** @type{?proto.billing.private.paymentgateway.CreateCheckoutDotComGatewayRequest} */ (
    jspb.Message.getWrapperField(this, proto.billing.private.paymentgateway.CreateCheckoutDotComGatewayRequest, 1));
};


/**
 * @param {?proto.billing.private.paymentgateway.CreateCheckoutDotComGatewayRequest|undefined} value
 * @return {!proto.billing.private.paymentgateway.ReplaceGatewayToCheckoutDotComRequest} returns this
*/
proto.billing.private.paymentgateway.ReplaceGatewayToCheckoutDotComRequest.prototype.setCreateCheckoutGateway = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.billing.private.paymentgateway.ReplaceGatewayToCheckoutDotComRequest} returns this
 */
proto.billing.private.paymentgateway.ReplaceGatewayToCheckoutDotComRequest.prototype.clearCreateCheckoutGateway = function() {
  return this.setCreateCheckoutGateway(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.billing.private.paymentgateway.ReplaceGatewayToCheckoutDotComRequest.prototype.hasCreateCheckoutGateway = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional int32 payment_gateway_to_replace_id = 2;
 * @return {number}
 */
proto.billing.private.paymentgateway.ReplaceGatewayToCheckoutDotComRequest.prototype.getPaymentGatewayToReplaceId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.billing.private.paymentgateway.ReplaceGatewayToCheckoutDotComRequest} returns this
 */
proto.billing.private.paymentgateway.ReplaceGatewayToCheckoutDotComRequest.prototype.setPaymentGatewayToReplaceId = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.billing.private.paymentgateway.CreateCheckoutDotComGatewayResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.billing.private.paymentgateway.CreateCheckoutDotComGatewayResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.billing.private.paymentgateway.CreateCheckoutDotComGatewayResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing.private.paymentgateway.CreateCheckoutDotComGatewayResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    paymentGatewayId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    displayName: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.billing.private.paymentgateway.CreateCheckoutDotComGatewayResponse}
 */
proto.billing.private.paymentgateway.CreateCheckoutDotComGatewayResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.billing.private.paymentgateway.CreateCheckoutDotComGatewayResponse;
  return proto.billing.private.paymentgateway.CreateCheckoutDotComGatewayResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.billing.private.paymentgateway.CreateCheckoutDotComGatewayResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.billing.private.paymentgateway.CreateCheckoutDotComGatewayResponse}
 */
proto.billing.private.paymentgateway.CreateCheckoutDotComGatewayResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPaymentGatewayId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setDisplayName(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.billing.private.paymentgateway.CreateCheckoutDotComGatewayResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.billing.private.paymentgateway.CreateCheckoutDotComGatewayResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.billing.private.paymentgateway.CreateCheckoutDotComGatewayResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing.private.paymentgateway.CreateCheckoutDotComGatewayResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPaymentGatewayId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getDisplayName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional int32 payment_gateway_id = 1;
 * @return {number}
 */
proto.billing.private.paymentgateway.CreateCheckoutDotComGatewayResponse.prototype.getPaymentGatewayId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.billing.private.paymentgateway.CreateCheckoutDotComGatewayResponse} returns this
 */
proto.billing.private.paymentgateway.CreateCheckoutDotComGatewayResponse.prototype.setPaymentGatewayId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string display_name = 2;
 * @return {string}
 */
proto.billing.private.paymentgateway.CreateCheckoutDotComGatewayResponse.prototype.getDisplayName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.billing.private.paymentgateway.CreateCheckoutDotComGatewayResponse} returns this
 */
proto.billing.private.paymentgateway.CreateCheckoutDotComGatewayResponse.prototype.setDisplayName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.billing.private.paymentgateway.UpdateCheckoutDotComGatewayRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.billing.private.paymentgateway.UpdateCheckoutDotComGatewayRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.billing.private.paymentgateway.UpdateCheckoutDotComGatewayRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing.private.paymentgateway.UpdateCheckoutDotComGatewayRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    companyDomain: jspb.Message.getFieldWithDefault(msg, 1, ""),
    paymentGatewayId: jspb.Message.getFieldWithDefault(msg, 2, 0),
    secretKey: jspb.Message.getFieldWithDefault(msg, 3, ""),
    displayName: jspb.Message.getFieldWithDefault(msg, 4, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.billing.private.paymentgateway.UpdateCheckoutDotComGatewayRequest}
 */
proto.billing.private.paymentgateway.UpdateCheckoutDotComGatewayRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.billing.private.paymentgateway.UpdateCheckoutDotComGatewayRequest;
  return proto.billing.private.paymentgateway.UpdateCheckoutDotComGatewayRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.billing.private.paymentgateway.UpdateCheckoutDotComGatewayRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.billing.private.paymentgateway.UpdateCheckoutDotComGatewayRequest}
 */
proto.billing.private.paymentgateway.UpdateCheckoutDotComGatewayRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setCompanyDomain(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPaymentGatewayId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setSecretKey(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setDisplayName(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.billing.private.paymentgateway.UpdateCheckoutDotComGatewayRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.billing.private.paymentgateway.UpdateCheckoutDotComGatewayRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.billing.private.paymentgateway.UpdateCheckoutDotComGatewayRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing.private.paymentgateway.UpdateCheckoutDotComGatewayRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCompanyDomain();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getPaymentGatewayId();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getSecretKey();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getDisplayName();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
};


/**
 * optional string company_domain = 1;
 * @return {string}
 */
proto.billing.private.paymentgateway.UpdateCheckoutDotComGatewayRequest.prototype.getCompanyDomain = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.billing.private.paymentgateway.UpdateCheckoutDotComGatewayRequest} returns this
 */
proto.billing.private.paymentgateway.UpdateCheckoutDotComGatewayRequest.prototype.setCompanyDomain = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int32 payment_gateway_id = 2;
 * @return {number}
 */
proto.billing.private.paymentgateway.UpdateCheckoutDotComGatewayRequest.prototype.getPaymentGatewayId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.billing.private.paymentgateway.UpdateCheckoutDotComGatewayRequest} returns this
 */
proto.billing.private.paymentgateway.UpdateCheckoutDotComGatewayRequest.prototype.setPaymentGatewayId = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional string secret_key = 3;
 * @return {string}
 */
proto.billing.private.paymentgateway.UpdateCheckoutDotComGatewayRequest.prototype.getSecretKey = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.billing.private.paymentgateway.UpdateCheckoutDotComGatewayRequest} returns this
 */
proto.billing.private.paymentgateway.UpdateCheckoutDotComGatewayRequest.prototype.setSecretKey = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string display_name = 4;
 * @return {string}
 */
proto.billing.private.paymentgateway.UpdateCheckoutDotComGatewayRequest.prototype.getDisplayName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.billing.private.paymentgateway.UpdateCheckoutDotComGatewayRequest} returns this
 */
proto.billing.private.paymentgateway.UpdateCheckoutDotComGatewayRequest.prototype.setDisplayName = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.billing.private.paymentgateway.UpdateCheckoutDotComGatewayResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.billing.private.paymentgateway.UpdateCheckoutDotComGatewayResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.billing.private.paymentgateway.UpdateCheckoutDotComGatewayResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing.private.paymentgateway.UpdateCheckoutDotComGatewayResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    paymentGatewayId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    displayName: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.billing.private.paymentgateway.UpdateCheckoutDotComGatewayResponse}
 */
proto.billing.private.paymentgateway.UpdateCheckoutDotComGatewayResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.billing.private.paymentgateway.UpdateCheckoutDotComGatewayResponse;
  return proto.billing.private.paymentgateway.UpdateCheckoutDotComGatewayResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.billing.private.paymentgateway.UpdateCheckoutDotComGatewayResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.billing.private.paymentgateway.UpdateCheckoutDotComGatewayResponse}
 */
proto.billing.private.paymentgateway.UpdateCheckoutDotComGatewayResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPaymentGatewayId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setDisplayName(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.billing.private.paymentgateway.UpdateCheckoutDotComGatewayResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.billing.private.paymentgateway.UpdateCheckoutDotComGatewayResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.billing.private.paymentgateway.UpdateCheckoutDotComGatewayResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing.private.paymentgateway.UpdateCheckoutDotComGatewayResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPaymentGatewayId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getDisplayName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional int32 payment_gateway_id = 1;
 * @return {number}
 */
proto.billing.private.paymentgateway.UpdateCheckoutDotComGatewayResponse.prototype.getPaymentGatewayId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.billing.private.paymentgateway.UpdateCheckoutDotComGatewayResponse} returns this
 */
proto.billing.private.paymentgateway.UpdateCheckoutDotComGatewayResponse.prototype.setPaymentGatewayId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string display_name = 2;
 * @return {string}
 */
proto.billing.private.paymentgateway.UpdateCheckoutDotComGatewayResponse.prototype.getDisplayName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.billing.private.paymentgateway.UpdateCheckoutDotComGatewayResponse} returns this
 */
proto.billing.private.paymentgateway.UpdateCheckoutDotComGatewayResponse.prototype.setDisplayName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.billing.private.paymentgateway.CreateBraintreeGatewayRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.billing.private.paymentgateway.CreateBraintreeGatewayRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.billing.private.paymentgateway.CreateBraintreeGatewayRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing.private.paymentgateway.CreateBraintreeGatewayRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    companyDomain: jspb.Message.getFieldWithDefault(msg, 1, ""),
    displayName: jspb.Message.getFieldWithDefault(msg, 2, ""),
    merchantId: jspb.Message.getFieldWithDefault(msg, 3, ""),
    publicKey: jspb.Message.getFieldWithDefault(msg, 4, ""),
    privateKey: jspb.Message.getFieldWithDefault(msg, 5, ""),
    merchantAccountId: jspb.Message.getFieldWithDefault(msg, 6, ""),
    isSandbox: jspb.Message.getBooleanFieldWithDefault(msg, 7, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.billing.private.paymentgateway.CreateBraintreeGatewayRequest}
 */
proto.billing.private.paymentgateway.CreateBraintreeGatewayRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.billing.private.paymentgateway.CreateBraintreeGatewayRequest;
  return proto.billing.private.paymentgateway.CreateBraintreeGatewayRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.billing.private.paymentgateway.CreateBraintreeGatewayRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.billing.private.paymentgateway.CreateBraintreeGatewayRequest}
 */
proto.billing.private.paymentgateway.CreateBraintreeGatewayRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setCompanyDomain(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setDisplayName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setMerchantId(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setPublicKey(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setPrivateKey(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setMerchantAccountId(value);
      break;
    case 7:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsSandbox(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.billing.private.paymentgateway.CreateBraintreeGatewayRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.billing.private.paymentgateway.CreateBraintreeGatewayRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.billing.private.paymentgateway.CreateBraintreeGatewayRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing.private.paymentgateway.CreateBraintreeGatewayRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCompanyDomain();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getDisplayName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getMerchantId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getPublicKey();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getPrivateKey();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getMerchantAccountId();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getIsSandbox();
  if (f) {
    writer.writeBool(
      7,
      f
    );
  }
};


/**
 * optional string company_domain = 1;
 * @return {string}
 */
proto.billing.private.paymentgateway.CreateBraintreeGatewayRequest.prototype.getCompanyDomain = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.billing.private.paymentgateway.CreateBraintreeGatewayRequest} returns this
 */
proto.billing.private.paymentgateway.CreateBraintreeGatewayRequest.prototype.setCompanyDomain = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string display_name = 2;
 * @return {string}
 */
proto.billing.private.paymentgateway.CreateBraintreeGatewayRequest.prototype.getDisplayName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.billing.private.paymentgateway.CreateBraintreeGatewayRequest} returns this
 */
proto.billing.private.paymentgateway.CreateBraintreeGatewayRequest.prototype.setDisplayName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string merchant_id = 3;
 * @return {string}
 */
proto.billing.private.paymentgateway.CreateBraintreeGatewayRequest.prototype.getMerchantId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.billing.private.paymentgateway.CreateBraintreeGatewayRequest} returns this
 */
proto.billing.private.paymentgateway.CreateBraintreeGatewayRequest.prototype.setMerchantId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string public_key = 4;
 * @return {string}
 */
proto.billing.private.paymentgateway.CreateBraintreeGatewayRequest.prototype.getPublicKey = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.billing.private.paymentgateway.CreateBraintreeGatewayRequest} returns this
 */
proto.billing.private.paymentgateway.CreateBraintreeGatewayRequest.prototype.setPublicKey = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string private_key = 5;
 * @return {string}
 */
proto.billing.private.paymentgateway.CreateBraintreeGatewayRequest.prototype.getPrivateKey = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.billing.private.paymentgateway.CreateBraintreeGatewayRequest} returns this
 */
proto.billing.private.paymentgateway.CreateBraintreeGatewayRequest.prototype.setPrivateKey = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string merchant_account_id = 6;
 * @return {string}
 */
proto.billing.private.paymentgateway.CreateBraintreeGatewayRequest.prototype.getMerchantAccountId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.billing.private.paymentgateway.CreateBraintreeGatewayRequest} returns this
 */
proto.billing.private.paymentgateway.CreateBraintreeGatewayRequest.prototype.setMerchantAccountId = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional bool is_sandbox = 7;
 * @return {boolean}
 */
proto.billing.private.paymentgateway.CreateBraintreeGatewayRequest.prototype.getIsSandbox = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 7, false));
};


/**
 * @param {boolean} value
 * @return {!proto.billing.private.paymentgateway.CreateBraintreeGatewayRequest} returns this
 */
proto.billing.private.paymentgateway.CreateBraintreeGatewayRequest.prototype.setIsSandbox = function(value) {
  return jspb.Message.setProto3BooleanField(this, 7, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.billing.private.paymentgateway.ReplaceGatewayToBraintreeRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.billing.private.paymentgateway.ReplaceGatewayToBraintreeRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.billing.private.paymentgateway.ReplaceGatewayToBraintreeRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing.private.paymentgateway.ReplaceGatewayToBraintreeRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    createBraintreeRequest: (f = msg.getCreateBraintreeRequest()) && proto.billing.private.paymentgateway.CreateBraintreeGatewayRequest.toObject(includeInstance, f),
    paymentGatewayToReplaceId: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.billing.private.paymentgateway.ReplaceGatewayToBraintreeRequest}
 */
proto.billing.private.paymentgateway.ReplaceGatewayToBraintreeRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.billing.private.paymentgateway.ReplaceGatewayToBraintreeRequest;
  return proto.billing.private.paymentgateway.ReplaceGatewayToBraintreeRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.billing.private.paymentgateway.ReplaceGatewayToBraintreeRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.billing.private.paymentgateway.ReplaceGatewayToBraintreeRequest}
 */
proto.billing.private.paymentgateway.ReplaceGatewayToBraintreeRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.billing.private.paymentgateway.CreateBraintreeGatewayRequest;
      reader.readMessage(value,proto.billing.private.paymentgateway.CreateBraintreeGatewayRequest.deserializeBinaryFromReader);
      msg.setCreateBraintreeRequest(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPaymentGatewayToReplaceId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.billing.private.paymentgateway.ReplaceGatewayToBraintreeRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.billing.private.paymentgateway.ReplaceGatewayToBraintreeRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.billing.private.paymentgateway.ReplaceGatewayToBraintreeRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing.private.paymentgateway.ReplaceGatewayToBraintreeRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCreateBraintreeRequest();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.billing.private.paymentgateway.CreateBraintreeGatewayRequest.serializeBinaryToWriter
    );
  }
  f = message.getPaymentGatewayToReplaceId();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
};


/**
 * optional CreateBraintreeGatewayRequest create_braintree_request = 1;
 * @return {?proto.billing.private.paymentgateway.CreateBraintreeGatewayRequest}
 */
proto.billing.private.paymentgateway.ReplaceGatewayToBraintreeRequest.prototype.getCreateBraintreeRequest = function() {
  return /** @type{?proto.billing.private.paymentgateway.CreateBraintreeGatewayRequest} */ (
    jspb.Message.getWrapperField(this, proto.billing.private.paymentgateway.CreateBraintreeGatewayRequest, 1));
};


/**
 * @param {?proto.billing.private.paymentgateway.CreateBraintreeGatewayRequest|undefined} value
 * @return {!proto.billing.private.paymentgateway.ReplaceGatewayToBraintreeRequest} returns this
*/
proto.billing.private.paymentgateway.ReplaceGatewayToBraintreeRequest.prototype.setCreateBraintreeRequest = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.billing.private.paymentgateway.ReplaceGatewayToBraintreeRequest} returns this
 */
proto.billing.private.paymentgateway.ReplaceGatewayToBraintreeRequest.prototype.clearCreateBraintreeRequest = function() {
  return this.setCreateBraintreeRequest(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.billing.private.paymentgateway.ReplaceGatewayToBraintreeRequest.prototype.hasCreateBraintreeRequest = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional int32 payment_gateway_to_replace_id = 2;
 * @return {number}
 */
proto.billing.private.paymentgateway.ReplaceGatewayToBraintreeRequest.prototype.getPaymentGatewayToReplaceId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.billing.private.paymentgateway.ReplaceGatewayToBraintreeRequest} returns this
 */
proto.billing.private.paymentgateway.ReplaceGatewayToBraintreeRequest.prototype.setPaymentGatewayToReplaceId = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.billing.private.paymentgateway.CreateBraintreeGatewayResponse.repeatedFields_ = [4];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.billing.private.paymentgateway.CreateBraintreeGatewayResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.billing.private.paymentgateway.CreateBraintreeGatewayResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.billing.private.paymentgateway.CreateBraintreeGatewayResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing.private.paymentgateway.CreateBraintreeGatewayResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    paymentGatewayId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    displayName: jspb.Message.getFieldWithDefault(msg, 2, ""),
    isSaved: jspb.Message.getBooleanFieldWithDefault(msg, 3, false),
    merchantAccountsList: jspb.Message.toObjectList(msg.getMerchantAccountsList(),
    proto.billing.private.paymentgateway.BraintreeMerchantAccount.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.billing.private.paymentgateway.CreateBraintreeGatewayResponse}
 */
proto.billing.private.paymentgateway.CreateBraintreeGatewayResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.billing.private.paymentgateway.CreateBraintreeGatewayResponse;
  return proto.billing.private.paymentgateway.CreateBraintreeGatewayResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.billing.private.paymentgateway.CreateBraintreeGatewayResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.billing.private.paymentgateway.CreateBraintreeGatewayResponse}
 */
proto.billing.private.paymentgateway.CreateBraintreeGatewayResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPaymentGatewayId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setDisplayName(value);
      break;
    case 3:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsSaved(value);
      break;
    case 4:
      var value = new proto.billing.private.paymentgateway.BraintreeMerchantAccount;
      reader.readMessage(value,proto.billing.private.paymentgateway.BraintreeMerchantAccount.deserializeBinaryFromReader);
      msg.addMerchantAccounts(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.billing.private.paymentgateway.CreateBraintreeGatewayResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.billing.private.paymentgateway.CreateBraintreeGatewayResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.billing.private.paymentgateway.CreateBraintreeGatewayResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing.private.paymentgateway.CreateBraintreeGatewayResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPaymentGatewayId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getDisplayName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getIsSaved();
  if (f) {
    writer.writeBool(
      3,
      f
    );
  }
  f = message.getMerchantAccountsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      proto.billing.private.paymentgateway.BraintreeMerchantAccount.serializeBinaryToWriter
    );
  }
};


/**
 * optional int32 payment_gateway_id = 1;
 * @return {number}
 */
proto.billing.private.paymentgateway.CreateBraintreeGatewayResponse.prototype.getPaymentGatewayId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.billing.private.paymentgateway.CreateBraintreeGatewayResponse} returns this
 */
proto.billing.private.paymentgateway.CreateBraintreeGatewayResponse.prototype.setPaymentGatewayId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string display_name = 2;
 * @return {string}
 */
proto.billing.private.paymentgateway.CreateBraintreeGatewayResponse.prototype.getDisplayName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.billing.private.paymentgateway.CreateBraintreeGatewayResponse} returns this
 */
proto.billing.private.paymentgateway.CreateBraintreeGatewayResponse.prototype.setDisplayName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional bool is_saved = 3;
 * @return {boolean}
 */
proto.billing.private.paymentgateway.CreateBraintreeGatewayResponse.prototype.getIsSaved = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 3, false));
};


/**
 * @param {boolean} value
 * @return {!proto.billing.private.paymentgateway.CreateBraintreeGatewayResponse} returns this
 */
proto.billing.private.paymentgateway.CreateBraintreeGatewayResponse.prototype.setIsSaved = function(value) {
  return jspb.Message.setProto3BooleanField(this, 3, value);
};


/**
 * repeated BraintreeMerchantAccount merchant_accounts = 4;
 * @return {!Array<!proto.billing.private.paymentgateway.BraintreeMerchantAccount>}
 */
proto.billing.private.paymentgateway.CreateBraintreeGatewayResponse.prototype.getMerchantAccountsList = function() {
  return /** @type{!Array<!proto.billing.private.paymentgateway.BraintreeMerchantAccount>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.billing.private.paymentgateway.BraintreeMerchantAccount, 4));
};


/**
 * @param {!Array<!proto.billing.private.paymentgateway.BraintreeMerchantAccount>} value
 * @return {!proto.billing.private.paymentgateway.CreateBraintreeGatewayResponse} returns this
*/
proto.billing.private.paymentgateway.CreateBraintreeGatewayResponse.prototype.setMerchantAccountsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.billing.private.paymentgateway.BraintreeMerchantAccount=} opt_value
 * @param {number=} opt_index
 * @return {!proto.billing.private.paymentgateway.BraintreeMerchantAccount}
 */
proto.billing.private.paymentgateway.CreateBraintreeGatewayResponse.prototype.addMerchantAccounts = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.billing.private.paymentgateway.BraintreeMerchantAccount, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.billing.private.paymentgateway.CreateBraintreeGatewayResponse} returns this
 */
proto.billing.private.paymentgateway.CreateBraintreeGatewayResponse.prototype.clearMerchantAccountsList = function() {
  return this.setMerchantAccountsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.billing.private.paymentgateway.UpdateBraintreeGatewayRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.billing.private.paymentgateway.UpdateBraintreeGatewayRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.billing.private.paymentgateway.UpdateBraintreeGatewayRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing.private.paymentgateway.UpdateBraintreeGatewayRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    companyDomain: jspb.Message.getFieldWithDefault(msg, 1, ""),
    paymentGatewayId: jspb.Message.getFieldWithDefault(msg, 2, 0),
    displayName: jspb.Message.getFieldWithDefault(msg, 3, ""),
    merchantId: jspb.Message.getFieldWithDefault(msg, 4, ""),
    publicKey: jspb.Message.getFieldWithDefault(msg, 5, ""),
    privateKey: jspb.Message.getFieldWithDefault(msg, 6, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.billing.private.paymentgateway.UpdateBraintreeGatewayRequest}
 */
proto.billing.private.paymentgateway.UpdateBraintreeGatewayRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.billing.private.paymentgateway.UpdateBraintreeGatewayRequest;
  return proto.billing.private.paymentgateway.UpdateBraintreeGatewayRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.billing.private.paymentgateway.UpdateBraintreeGatewayRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.billing.private.paymentgateway.UpdateBraintreeGatewayRequest}
 */
proto.billing.private.paymentgateway.UpdateBraintreeGatewayRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setCompanyDomain(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPaymentGatewayId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setDisplayName(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setMerchantId(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setPublicKey(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setPrivateKey(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.billing.private.paymentgateway.UpdateBraintreeGatewayRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.billing.private.paymentgateway.UpdateBraintreeGatewayRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.billing.private.paymentgateway.UpdateBraintreeGatewayRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing.private.paymentgateway.UpdateBraintreeGatewayRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCompanyDomain();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getPaymentGatewayId();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getDisplayName();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getMerchantId();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getPublicKey();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getPrivateKey();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
};


/**
 * optional string company_domain = 1;
 * @return {string}
 */
proto.billing.private.paymentgateway.UpdateBraintreeGatewayRequest.prototype.getCompanyDomain = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.billing.private.paymentgateway.UpdateBraintreeGatewayRequest} returns this
 */
proto.billing.private.paymentgateway.UpdateBraintreeGatewayRequest.prototype.setCompanyDomain = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int32 payment_gateway_id = 2;
 * @return {number}
 */
proto.billing.private.paymentgateway.UpdateBraintreeGatewayRequest.prototype.getPaymentGatewayId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.billing.private.paymentgateway.UpdateBraintreeGatewayRequest} returns this
 */
proto.billing.private.paymentgateway.UpdateBraintreeGatewayRequest.prototype.setPaymentGatewayId = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional string display_name = 3;
 * @return {string}
 */
proto.billing.private.paymentgateway.UpdateBraintreeGatewayRequest.prototype.getDisplayName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.billing.private.paymentgateway.UpdateBraintreeGatewayRequest} returns this
 */
proto.billing.private.paymentgateway.UpdateBraintreeGatewayRequest.prototype.setDisplayName = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string merchant_id = 4;
 * @return {string}
 */
proto.billing.private.paymentgateway.UpdateBraintreeGatewayRequest.prototype.getMerchantId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.billing.private.paymentgateway.UpdateBraintreeGatewayRequest} returns this
 */
proto.billing.private.paymentgateway.UpdateBraintreeGatewayRequest.prototype.setMerchantId = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string public_key = 5;
 * @return {string}
 */
proto.billing.private.paymentgateway.UpdateBraintreeGatewayRequest.prototype.getPublicKey = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.billing.private.paymentgateway.UpdateBraintreeGatewayRequest} returns this
 */
proto.billing.private.paymentgateway.UpdateBraintreeGatewayRequest.prototype.setPublicKey = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string private_key = 6;
 * @return {string}
 */
proto.billing.private.paymentgateway.UpdateBraintreeGatewayRequest.prototype.getPrivateKey = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.billing.private.paymentgateway.UpdateBraintreeGatewayRequest} returns this
 */
proto.billing.private.paymentgateway.UpdateBraintreeGatewayRequest.prototype.setPrivateKey = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.billing.private.paymentgateway.UpdateBraintreeGatewayResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.billing.private.paymentgateway.UpdateBraintreeGatewayResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.billing.private.paymentgateway.UpdateBraintreeGatewayResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing.private.paymentgateway.UpdateBraintreeGatewayResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    paymentGatewayId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    displayName: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.billing.private.paymentgateway.UpdateBraintreeGatewayResponse}
 */
proto.billing.private.paymentgateway.UpdateBraintreeGatewayResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.billing.private.paymentgateway.UpdateBraintreeGatewayResponse;
  return proto.billing.private.paymentgateway.UpdateBraintreeGatewayResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.billing.private.paymentgateway.UpdateBraintreeGatewayResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.billing.private.paymentgateway.UpdateBraintreeGatewayResponse}
 */
proto.billing.private.paymentgateway.UpdateBraintreeGatewayResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPaymentGatewayId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setDisplayName(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.billing.private.paymentgateway.UpdateBraintreeGatewayResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.billing.private.paymentgateway.UpdateBraintreeGatewayResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.billing.private.paymentgateway.UpdateBraintreeGatewayResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing.private.paymentgateway.UpdateBraintreeGatewayResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPaymentGatewayId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getDisplayName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional int32 payment_gateway_id = 1;
 * @return {number}
 */
proto.billing.private.paymentgateway.UpdateBraintreeGatewayResponse.prototype.getPaymentGatewayId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.billing.private.paymentgateway.UpdateBraintreeGatewayResponse} returns this
 */
proto.billing.private.paymentgateway.UpdateBraintreeGatewayResponse.prototype.setPaymentGatewayId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string display_name = 2;
 * @return {string}
 */
proto.billing.private.paymentgateway.UpdateBraintreeGatewayResponse.prototype.getDisplayName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.billing.private.paymentgateway.UpdateBraintreeGatewayResponse} returns this
 */
proto.billing.private.paymentgateway.UpdateBraintreeGatewayResponse.prototype.setDisplayName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.billing.private.paymentgateway.CreateAdyenGatewayRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.billing.private.paymentgateway.CreateAdyenGatewayRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.billing.private.paymentgateway.CreateAdyenGatewayRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing.private.paymentgateway.CreateAdyenGatewayRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    companyDomain: jspb.Message.getFieldWithDefault(msg, 1, ""),
    displayName: jspb.Message.getFieldWithDefault(msg, 2, ""),
    username: jspb.Message.getFieldWithDefault(msg, 3, ""),
    password: jspb.Message.getFieldWithDefault(msg, 4, ""),
    merchantAccount: jspb.Message.getFieldWithDefault(msg, 5, ""),
    subdomain: jspb.Message.getFieldWithDefault(msg, 6, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.billing.private.paymentgateway.CreateAdyenGatewayRequest}
 */
proto.billing.private.paymentgateway.CreateAdyenGatewayRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.billing.private.paymentgateway.CreateAdyenGatewayRequest;
  return proto.billing.private.paymentgateway.CreateAdyenGatewayRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.billing.private.paymentgateway.CreateAdyenGatewayRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.billing.private.paymentgateway.CreateAdyenGatewayRequest}
 */
proto.billing.private.paymentgateway.CreateAdyenGatewayRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setCompanyDomain(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setDisplayName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setUsername(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setPassword(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setMerchantAccount(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setSubdomain(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.billing.private.paymentgateway.CreateAdyenGatewayRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.billing.private.paymentgateway.CreateAdyenGatewayRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.billing.private.paymentgateway.CreateAdyenGatewayRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing.private.paymentgateway.CreateAdyenGatewayRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCompanyDomain();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getDisplayName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getUsername();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getPassword();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getMerchantAccount();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getSubdomain();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
};


/**
 * optional string company_domain = 1;
 * @return {string}
 */
proto.billing.private.paymentgateway.CreateAdyenGatewayRequest.prototype.getCompanyDomain = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.billing.private.paymentgateway.CreateAdyenGatewayRequest} returns this
 */
proto.billing.private.paymentgateway.CreateAdyenGatewayRequest.prototype.setCompanyDomain = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string display_name = 2;
 * @return {string}
 */
proto.billing.private.paymentgateway.CreateAdyenGatewayRequest.prototype.getDisplayName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.billing.private.paymentgateway.CreateAdyenGatewayRequest} returns this
 */
proto.billing.private.paymentgateway.CreateAdyenGatewayRequest.prototype.setDisplayName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string username = 3;
 * @return {string}
 */
proto.billing.private.paymentgateway.CreateAdyenGatewayRequest.prototype.getUsername = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.billing.private.paymentgateway.CreateAdyenGatewayRequest} returns this
 */
proto.billing.private.paymentgateway.CreateAdyenGatewayRequest.prototype.setUsername = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string password = 4;
 * @return {string}
 */
proto.billing.private.paymentgateway.CreateAdyenGatewayRequest.prototype.getPassword = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.billing.private.paymentgateway.CreateAdyenGatewayRequest} returns this
 */
proto.billing.private.paymentgateway.CreateAdyenGatewayRequest.prototype.setPassword = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string merchant_account = 5;
 * @return {string}
 */
proto.billing.private.paymentgateway.CreateAdyenGatewayRequest.prototype.getMerchantAccount = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.billing.private.paymentgateway.CreateAdyenGatewayRequest} returns this
 */
proto.billing.private.paymentgateway.CreateAdyenGatewayRequest.prototype.setMerchantAccount = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string subdomain = 6;
 * @return {string}
 */
proto.billing.private.paymentgateway.CreateAdyenGatewayRequest.prototype.getSubdomain = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.billing.private.paymentgateway.CreateAdyenGatewayRequest} returns this
 */
proto.billing.private.paymentgateway.CreateAdyenGatewayRequest.prototype.setSubdomain = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.billing.private.paymentgateway.ReplaceGatewayToAdyenRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.billing.private.paymentgateway.ReplaceGatewayToAdyenRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.billing.private.paymentgateway.ReplaceGatewayToAdyenRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing.private.paymentgateway.ReplaceGatewayToAdyenRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    createAdyenRequest: (f = msg.getCreateAdyenRequest()) && proto.billing.private.paymentgateway.CreateAdyenGatewayRequest.toObject(includeInstance, f),
    paymentGatewayToReplaceId: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.billing.private.paymentgateway.ReplaceGatewayToAdyenRequest}
 */
proto.billing.private.paymentgateway.ReplaceGatewayToAdyenRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.billing.private.paymentgateway.ReplaceGatewayToAdyenRequest;
  return proto.billing.private.paymentgateway.ReplaceGatewayToAdyenRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.billing.private.paymentgateway.ReplaceGatewayToAdyenRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.billing.private.paymentgateway.ReplaceGatewayToAdyenRequest}
 */
proto.billing.private.paymentgateway.ReplaceGatewayToAdyenRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.billing.private.paymentgateway.CreateAdyenGatewayRequest;
      reader.readMessage(value,proto.billing.private.paymentgateway.CreateAdyenGatewayRequest.deserializeBinaryFromReader);
      msg.setCreateAdyenRequest(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPaymentGatewayToReplaceId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.billing.private.paymentgateway.ReplaceGatewayToAdyenRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.billing.private.paymentgateway.ReplaceGatewayToAdyenRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.billing.private.paymentgateway.ReplaceGatewayToAdyenRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing.private.paymentgateway.ReplaceGatewayToAdyenRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCreateAdyenRequest();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.billing.private.paymentgateway.CreateAdyenGatewayRequest.serializeBinaryToWriter
    );
  }
  f = message.getPaymentGatewayToReplaceId();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
};


/**
 * optional CreateAdyenGatewayRequest create_adyen_request = 1;
 * @return {?proto.billing.private.paymentgateway.CreateAdyenGatewayRequest}
 */
proto.billing.private.paymentgateway.ReplaceGatewayToAdyenRequest.prototype.getCreateAdyenRequest = function() {
  return /** @type{?proto.billing.private.paymentgateway.CreateAdyenGatewayRequest} */ (
    jspb.Message.getWrapperField(this, proto.billing.private.paymentgateway.CreateAdyenGatewayRequest, 1));
};


/**
 * @param {?proto.billing.private.paymentgateway.CreateAdyenGatewayRequest|undefined} value
 * @return {!proto.billing.private.paymentgateway.ReplaceGatewayToAdyenRequest} returns this
*/
proto.billing.private.paymentgateway.ReplaceGatewayToAdyenRequest.prototype.setCreateAdyenRequest = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.billing.private.paymentgateway.ReplaceGatewayToAdyenRequest} returns this
 */
proto.billing.private.paymentgateway.ReplaceGatewayToAdyenRequest.prototype.clearCreateAdyenRequest = function() {
  return this.setCreateAdyenRequest(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.billing.private.paymentgateway.ReplaceGatewayToAdyenRequest.prototype.hasCreateAdyenRequest = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional int32 payment_gateway_to_replace_id = 2;
 * @return {number}
 */
proto.billing.private.paymentgateway.ReplaceGatewayToAdyenRequest.prototype.getPaymentGatewayToReplaceId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.billing.private.paymentgateway.ReplaceGatewayToAdyenRequest} returns this
 */
proto.billing.private.paymentgateway.ReplaceGatewayToAdyenRequest.prototype.setPaymentGatewayToReplaceId = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.billing.private.paymentgateway.CreateAdyenGatewayResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.billing.private.paymentgateway.CreateAdyenGatewayResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.billing.private.paymentgateway.CreateAdyenGatewayResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing.private.paymentgateway.CreateAdyenGatewayResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    paymentGatewayId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    displayName: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.billing.private.paymentgateway.CreateAdyenGatewayResponse}
 */
proto.billing.private.paymentgateway.CreateAdyenGatewayResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.billing.private.paymentgateway.CreateAdyenGatewayResponse;
  return proto.billing.private.paymentgateway.CreateAdyenGatewayResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.billing.private.paymentgateway.CreateAdyenGatewayResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.billing.private.paymentgateway.CreateAdyenGatewayResponse}
 */
proto.billing.private.paymentgateway.CreateAdyenGatewayResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPaymentGatewayId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setDisplayName(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.billing.private.paymentgateway.CreateAdyenGatewayResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.billing.private.paymentgateway.CreateAdyenGatewayResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.billing.private.paymentgateway.CreateAdyenGatewayResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing.private.paymentgateway.CreateAdyenGatewayResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPaymentGatewayId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getDisplayName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional int32 payment_gateway_id = 1;
 * @return {number}
 */
proto.billing.private.paymentgateway.CreateAdyenGatewayResponse.prototype.getPaymentGatewayId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.billing.private.paymentgateway.CreateAdyenGatewayResponse} returns this
 */
proto.billing.private.paymentgateway.CreateAdyenGatewayResponse.prototype.setPaymentGatewayId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string display_name = 2;
 * @return {string}
 */
proto.billing.private.paymentgateway.CreateAdyenGatewayResponse.prototype.getDisplayName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.billing.private.paymentgateway.CreateAdyenGatewayResponse} returns this
 */
proto.billing.private.paymentgateway.CreateAdyenGatewayResponse.prototype.setDisplayName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.billing.private.paymentgateway.UpdateAdyenGatewayRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.billing.private.paymentgateway.UpdateAdyenGatewayRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.billing.private.paymentgateway.UpdateAdyenGatewayRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing.private.paymentgateway.UpdateAdyenGatewayRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    companyDomain: jspb.Message.getFieldWithDefault(msg, 1, ""),
    paymentGatewayId: jspb.Message.getFieldWithDefault(msg, 2, 0),
    displayName: jspb.Message.getFieldWithDefault(msg, 3, ""),
    username: jspb.Message.getFieldWithDefault(msg, 4, ""),
    password: jspb.Message.getFieldWithDefault(msg, 5, ""),
    merchantAccount: jspb.Message.getFieldWithDefault(msg, 6, ""),
    subdomain: jspb.Message.getFieldWithDefault(msg, 7, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.billing.private.paymentgateway.UpdateAdyenGatewayRequest}
 */
proto.billing.private.paymentgateway.UpdateAdyenGatewayRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.billing.private.paymentgateway.UpdateAdyenGatewayRequest;
  return proto.billing.private.paymentgateway.UpdateAdyenGatewayRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.billing.private.paymentgateway.UpdateAdyenGatewayRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.billing.private.paymentgateway.UpdateAdyenGatewayRequest}
 */
proto.billing.private.paymentgateway.UpdateAdyenGatewayRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setCompanyDomain(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPaymentGatewayId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setDisplayName(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setUsername(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setPassword(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setMerchantAccount(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setSubdomain(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.billing.private.paymentgateway.UpdateAdyenGatewayRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.billing.private.paymentgateway.UpdateAdyenGatewayRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.billing.private.paymentgateway.UpdateAdyenGatewayRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing.private.paymentgateway.UpdateAdyenGatewayRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCompanyDomain();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getPaymentGatewayId();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getDisplayName();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getUsername();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getPassword();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getMerchantAccount();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getSubdomain();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
};


/**
 * optional string company_domain = 1;
 * @return {string}
 */
proto.billing.private.paymentgateway.UpdateAdyenGatewayRequest.prototype.getCompanyDomain = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.billing.private.paymentgateway.UpdateAdyenGatewayRequest} returns this
 */
proto.billing.private.paymentgateway.UpdateAdyenGatewayRequest.prototype.setCompanyDomain = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int32 payment_gateway_id = 2;
 * @return {number}
 */
proto.billing.private.paymentgateway.UpdateAdyenGatewayRequest.prototype.getPaymentGatewayId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.billing.private.paymentgateway.UpdateAdyenGatewayRequest} returns this
 */
proto.billing.private.paymentgateway.UpdateAdyenGatewayRequest.prototype.setPaymentGatewayId = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional string display_name = 3;
 * @return {string}
 */
proto.billing.private.paymentgateway.UpdateAdyenGatewayRequest.prototype.getDisplayName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.billing.private.paymentgateway.UpdateAdyenGatewayRequest} returns this
 */
proto.billing.private.paymentgateway.UpdateAdyenGatewayRequest.prototype.setDisplayName = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string username = 4;
 * @return {string}
 */
proto.billing.private.paymentgateway.UpdateAdyenGatewayRequest.prototype.getUsername = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.billing.private.paymentgateway.UpdateAdyenGatewayRequest} returns this
 */
proto.billing.private.paymentgateway.UpdateAdyenGatewayRequest.prototype.setUsername = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string password = 5;
 * @return {string}
 */
proto.billing.private.paymentgateway.UpdateAdyenGatewayRequest.prototype.getPassword = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.billing.private.paymentgateway.UpdateAdyenGatewayRequest} returns this
 */
proto.billing.private.paymentgateway.UpdateAdyenGatewayRequest.prototype.setPassword = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string merchant_account = 6;
 * @return {string}
 */
proto.billing.private.paymentgateway.UpdateAdyenGatewayRequest.prototype.getMerchantAccount = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.billing.private.paymentgateway.UpdateAdyenGatewayRequest} returns this
 */
proto.billing.private.paymentgateway.UpdateAdyenGatewayRequest.prototype.setMerchantAccount = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string subdomain = 7;
 * @return {string}
 */
proto.billing.private.paymentgateway.UpdateAdyenGatewayRequest.prototype.getSubdomain = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.billing.private.paymentgateway.UpdateAdyenGatewayRequest} returns this
 */
proto.billing.private.paymentgateway.UpdateAdyenGatewayRequest.prototype.setSubdomain = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.billing.private.paymentgateway.UpdateAdyenGatewayResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.billing.private.paymentgateway.UpdateAdyenGatewayResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.billing.private.paymentgateway.UpdateAdyenGatewayResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing.private.paymentgateway.UpdateAdyenGatewayResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    paymentGatewayId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    displayName: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.billing.private.paymentgateway.UpdateAdyenGatewayResponse}
 */
proto.billing.private.paymentgateway.UpdateAdyenGatewayResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.billing.private.paymentgateway.UpdateAdyenGatewayResponse;
  return proto.billing.private.paymentgateway.UpdateAdyenGatewayResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.billing.private.paymentgateway.UpdateAdyenGatewayResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.billing.private.paymentgateway.UpdateAdyenGatewayResponse}
 */
proto.billing.private.paymentgateway.UpdateAdyenGatewayResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPaymentGatewayId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setDisplayName(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.billing.private.paymentgateway.UpdateAdyenGatewayResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.billing.private.paymentgateway.UpdateAdyenGatewayResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.billing.private.paymentgateway.UpdateAdyenGatewayResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing.private.paymentgateway.UpdateAdyenGatewayResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPaymentGatewayId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getDisplayName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional int32 payment_gateway_id = 1;
 * @return {number}
 */
proto.billing.private.paymentgateway.UpdateAdyenGatewayResponse.prototype.getPaymentGatewayId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.billing.private.paymentgateway.UpdateAdyenGatewayResponse} returns this
 */
proto.billing.private.paymentgateway.UpdateAdyenGatewayResponse.prototype.setPaymentGatewayId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string display_name = 2;
 * @return {string}
 */
proto.billing.private.paymentgateway.UpdateAdyenGatewayResponse.prototype.getDisplayName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.billing.private.paymentgateway.UpdateAdyenGatewayResponse} returns this
 */
proto.billing.private.paymentgateway.UpdateAdyenGatewayResponse.prototype.setDisplayName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.billing.private.paymentgateway.CreateAuthorizeDotNetGatewayRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.billing.private.paymentgateway.CreateAuthorizeDotNetGatewayRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.billing.private.paymentgateway.CreateAuthorizeDotNetGatewayRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing.private.paymentgateway.CreateAuthorizeDotNetGatewayRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    companyDomain: jspb.Message.getFieldWithDefault(msg, 1, ""),
    displayName: jspb.Message.getFieldWithDefault(msg, 2, ""),
    apiLoginId: jspb.Message.getFieldWithDefault(msg, 3, ""),
    password: jspb.Message.getFieldWithDefault(msg, 4, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.billing.private.paymentgateway.CreateAuthorizeDotNetGatewayRequest}
 */
proto.billing.private.paymentgateway.CreateAuthorizeDotNetGatewayRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.billing.private.paymentgateway.CreateAuthorizeDotNetGatewayRequest;
  return proto.billing.private.paymentgateway.CreateAuthorizeDotNetGatewayRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.billing.private.paymentgateway.CreateAuthorizeDotNetGatewayRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.billing.private.paymentgateway.CreateAuthorizeDotNetGatewayRequest}
 */
proto.billing.private.paymentgateway.CreateAuthorizeDotNetGatewayRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setCompanyDomain(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setDisplayName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setApiLoginId(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setPassword(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.billing.private.paymentgateway.CreateAuthorizeDotNetGatewayRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.billing.private.paymentgateway.CreateAuthorizeDotNetGatewayRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.billing.private.paymentgateway.CreateAuthorizeDotNetGatewayRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing.private.paymentgateway.CreateAuthorizeDotNetGatewayRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCompanyDomain();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getDisplayName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getApiLoginId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getPassword();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
};


/**
 * optional string company_domain = 1;
 * @return {string}
 */
proto.billing.private.paymentgateway.CreateAuthorizeDotNetGatewayRequest.prototype.getCompanyDomain = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.billing.private.paymentgateway.CreateAuthorizeDotNetGatewayRequest} returns this
 */
proto.billing.private.paymentgateway.CreateAuthorizeDotNetGatewayRequest.prototype.setCompanyDomain = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string display_name = 2;
 * @return {string}
 */
proto.billing.private.paymentgateway.CreateAuthorizeDotNetGatewayRequest.prototype.getDisplayName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.billing.private.paymentgateway.CreateAuthorizeDotNetGatewayRequest} returns this
 */
proto.billing.private.paymentgateway.CreateAuthorizeDotNetGatewayRequest.prototype.setDisplayName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string api_login_id = 3;
 * @return {string}
 */
proto.billing.private.paymentgateway.CreateAuthorizeDotNetGatewayRequest.prototype.getApiLoginId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.billing.private.paymentgateway.CreateAuthorizeDotNetGatewayRequest} returns this
 */
proto.billing.private.paymentgateway.CreateAuthorizeDotNetGatewayRequest.prototype.setApiLoginId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string password = 4;
 * @return {string}
 */
proto.billing.private.paymentgateway.CreateAuthorizeDotNetGatewayRequest.prototype.getPassword = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.billing.private.paymentgateway.CreateAuthorizeDotNetGatewayRequest} returns this
 */
proto.billing.private.paymentgateway.CreateAuthorizeDotNetGatewayRequest.prototype.setPassword = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.billing.private.paymentgateway.ReplaceGatewayToAuthorizeDotNetRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.billing.private.paymentgateway.ReplaceGatewayToAuthorizeDotNetRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.billing.private.paymentgateway.ReplaceGatewayToAuthorizeDotNetRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing.private.paymentgateway.ReplaceGatewayToAuthorizeDotNetRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    createAuthorizeRequest: (f = msg.getCreateAuthorizeRequest()) && proto.billing.private.paymentgateway.CreateAuthorizeDotNetGatewayRequest.toObject(includeInstance, f),
    paymentGatewayToReplaceId: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.billing.private.paymentgateway.ReplaceGatewayToAuthorizeDotNetRequest}
 */
proto.billing.private.paymentgateway.ReplaceGatewayToAuthorizeDotNetRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.billing.private.paymentgateway.ReplaceGatewayToAuthorizeDotNetRequest;
  return proto.billing.private.paymentgateway.ReplaceGatewayToAuthorizeDotNetRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.billing.private.paymentgateway.ReplaceGatewayToAuthorizeDotNetRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.billing.private.paymentgateway.ReplaceGatewayToAuthorizeDotNetRequest}
 */
proto.billing.private.paymentgateway.ReplaceGatewayToAuthorizeDotNetRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.billing.private.paymentgateway.CreateAuthorizeDotNetGatewayRequest;
      reader.readMessage(value,proto.billing.private.paymentgateway.CreateAuthorizeDotNetGatewayRequest.deserializeBinaryFromReader);
      msg.setCreateAuthorizeRequest(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPaymentGatewayToReplaceId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.billing.private.paymentgateway.ReplaceGatewayToAuthorizeDotNetRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.billing.private.paymentgateway.ReplaceGatewayToAuthorizeDotNetRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.billing.private.paymentgateway.ReplaceGatewayToAuthorizeDotNetRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing.private.paymentgateway.ReplaceGatewayToAuthorizeDotNetRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCreateAuthorizeRequest();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.billing.private.paymentgateway.CreateAuthorizeDotNetGatewayRequest.serializeBinaryToWriter
    );
  }
  f = message.getPaymentGatewayToReplaceId();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
};


/**
 * optional CreateAuthorizeDotNetGatewayRequest create_authorize_request = 1;
 * @return {?proto.billing.private.paymentgateway.CreateAuthorizeDotNetGatewayRequest}
 */
proto.billing.private.paymentgateway.ReplaceGatewayToAuthorizeDotNetRequest.prototype.getCreateAuthorizeRequest = function() {
  return /** @type{?proto.billing.private.paymentgateway.CreateAuthorizeDotNetGatewayRequest} */ (
    jspb.Message.getWrapperField(this, proto.billing.private.paymentgateway.CreateAuthorizeDotNetGatewayRequest, 1));
};


/**
 * @param {?proto.billing.private.paymentgateway.CreateAuthorizeDotNetGatewayRequest|undefined} value
 * @return {!proto.billing.private.paymentgateway.ReplaceGatewayToAuthorizeDotNetRequest} returns this
*/
proto.billing.private.paymentgateway.ReplaceGatewayToAuthorizeDotNetRequest.prototype.setCreateAuthorizeRequest = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.billing.private.paymentgateway.ReplaceGatewayToAuthorizeDotNetRequest} returns this
 */
proto.billing.private.paymentgateway.ReplaceGatewayToAuthorizeDotNetRequest.prototype.clearCreateAuthorizeRequest = function() {
  return this.setCreateAuthorizeRequest(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.billing.private.paymentgateway.ReplaceGatewayToAuthorizeDotNetRequest.prototype.hasCreateAuthorizeRequest = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional int32 payment_gateway_to_replace_id = 2;
 * @return {number}
 */
proto.billing.private.paymentgateway.ReplaceGatewayToAuthorizeDotNetRequest.prototype.getPaymentGatewayToReplaceId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.billing.private.paymentgateway.ReplaceGatewayToAuthorizeDotNetRequest} returns this
 */
proto.billing.private.paymentgateway.ReplaceGatewayToAuthorizeDotNetRequest.prototype.setPaymentGatewayToReplaceId = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.billing.private.paymentgateway.CreateAuthorizeDotNetGatewayResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.billing.private.paymentgateway.CreateAuthorizeDotNetGatewayResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.billing.private.paymentgateway.CreateAuthorizeDotNetGatewayResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing.private.paymentgateway.CreateAuthorizeDotNetGatewayResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    paymentGatewayId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    displayName: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.billing.private.paymentgateway.CreateAuthorizeDotNetGatewayResponse}
 */
proto.billing.private.paymentgateway.CreateAuthorizeDotNetGatewayResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.billing.private.paymentgateway.CreateAuthorizeDotNetGatewayResponse;
  return proto.billing.private.paymentgateway.CreateAuthorizeDotNetGatewayResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.billing.private.paymentgateway.CreateAuthorizeDotNetGatewayResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.billing.private.paymentgateway.CreateAuthorizeDotNetGatewayResponse}
 */
proto.billing.private.paymentgateway.CreateAuthorizeDotNetGatewayResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPaymentGatewayId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setDisplayName(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.billing.private.paymentgateway.CreateAuthorizeDotNetGatewayResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.billing.private.paymentgateway.CreateAuthorizeDotNetGatewayResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.billing.private.paymentgateway.CreateAuthorizeDotNetGatewayResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing.private.paymentgateway.CreateAuthorizeDotNetGatewayResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPaymentGatewayId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getDisplayName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional int32 payment_gateway_id = 1;
 * @return {number}
 */
proto.billing.private.paymentgateway.CreateAuthorizeDotNetGatewayResponse.prototype.getPaymentGatewayId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.billing.private.paymentgateway.CreateAuthorizeDotNetGatewayResponse} returns this
 */
proto.billing.private.paymentgateway.CreateAuthorizeDotNetGatewayResponse.prototype.setPaymentGatewayId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string display_name = 2;
 * @return {string}
 */
proto.billing.private.paymentgateway.CreateAuthorizeDotNetGatewayResponse.prototype.getDisplayName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.billing.private.paymentgateway.CreateAuthorizeDotNetGatewayResponse} returns this
 */
proto.billing.private.paymentgateway.CreateAuthorizeDotNetGatewayResponse.prototype.setDisplayName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.billing.private.paymentgateway.UpdateAuthorizeDotNetGatewayRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.billing.private.paymentgateway.UpdateAuthorizeDotNetGatewayRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.billing.private.paymentgateway.UpdateAuthorizeDotNetGatewayRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing.private.paymentgateway.UpdateAuthorizeDotNetGatewayRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    companyDomain: jspb.Message.getFieldWithDefault(msg, 1, ""),
    paymentGatewayId: jspb.Message.getFieldWithDefault(msg, 2, 0),
    displayName: jspb.Message.getFieldWithDefault(msg, 3, ""),
    apiLoginId: jspb.Message.getFieldWithDefault(msg, 4, ""),
    password: jspb.Message.getFieldWithDefault(msg, 5, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.billing.private.paymentgateway.UpdateAuthorizeDotNetGatewayRequest}
 */
proto.billing.private.paymentgateway.UpdateAuthorizeDotNetGatewayRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.billing.private.paymentgateway.UpdateAuthorizeDotNetGatewayRequest;
  return proto.billing.private.paymentgateway.UpdateAuthorizeDotNetGatewayRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.billing.private.paymentgateway.UpdateAuthorizeDotNetGatewayRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.billing.private.paymentgateway.UpdateAuthorizeDotNetGatewayRequest}
 */
proto.billing.private.paymentgateway.UpdateAuthorizeDotNetGatewayRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setCompanyDomain(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPaymentGatewayId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setDisplayName(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setApiLoginId(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setPassword(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.billing.private.paymentgateway.UpdateAuthorizeDotNetGatewayRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.billing.private.paymentgateway.UpdateAuthorizeDotNetGatewayRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.billing.private.paymentgateway.UpdateAuthorizeDotNetGatewayRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing.private.paymentgateway.UpdateAuthorizeDotNetGatewayRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCompanyDomain();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getPaymentGatewayId();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getDisplayName();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getApiLoginId();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getPassword();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
};


/**
 * optional string company_domain = 1;
 * @return {string}
 */
proto.billing.private.paymentgateway.UpdateAuthorizeDotNetGatewayRequest.prototype.getCompanyDomain = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.billing.private.paymentgateway.UpdateAuthorizeDotNetGatewayRequest} returns this
 */
proto.billing.private.paymentgateway.UpdateAuthorizeDotNetGatewayRequest.prototype.setCompanyDomain = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int32 payment_gateway_id = 2;
 * @return {number}
 */
proto.billing.private.paymentgateway.UpdateAuthorizeDotNetGatewayRequest.prototype.getPaymentGatewayId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.billing.private.paymentgateway.UpdateAuthorizeDotNetGatewayRequest} returns this
 */
proto.billing.private.paymentgateway.UpdateAuthorizeDotNetGatewayRequest.prototype.setPaymentGatewayId = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional string display_name = 3;
 * @return {string}
 */
proto.billing.private.paymentgateway.UpdateAuthorizeDotNetGatewayRequest.prototype.getDisplayName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.billing.private.paymentgateway.UpdateAuthorizeDotNetGatewayRequest} returns this
 */
proto.billing.private.paymentgateway.UpdateAuthorizeDotNetGatewayRequest.prototype.setDisplayName = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string api_login_id = 4;
 * @return {string}
 */
proto.billing.private.paymentgateway.UpdateAuthorizeDotNetGatewayRequest.prototype.getApiLoginId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.billing.private.paymentgateway.UpdateAuthorizeDotNetGatewayRequest} returns this
 */
proto.billing.private.paymentgateway.UpdateAuthorizeDotNetGatewayRequest.prototype.setApiLoginId = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string password = 5;
 * @return {string}
 */
proto.billing.private.paymentgateway.UpdateAuthorizeDotNetGatewayRequest.prototype.getPassword = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.billing.private.paymentgateway.UpdateAuthorizeDotNetGatewayRequest} returns this
 */
proto.billing.private.paymentgateway.UpdateAuthorizeDotNetGatewayRequest.prototype.setPassword = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.billing.private.paymentgateway.UpdateAuthorizeDotNetGatewayResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.billing.private.paymentgateway.UpdateAuthorizeDotNetGatewayResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.billing.private.paymentgateway.UpdateAuthorizeDotNetGatewayResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing.private.paymentgateway.UpdateAuthorizeDotNetGatewayResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    paymentGatewayId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    displayName: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.billing.private.paymentgateway.UpdateAuthorizeDotNetGatewayResponse}
 */
proto.billing.private.paymentgateway.UpdateAuthorizeDotNetGatewayResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.billing.private.paymentgateway.UpdateAuthorizeDotNetGatewayResponse;
  return proto.billing.private.paymentgateway.UpdateAuthorizeDotNetGatewayResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.billing.private.paymentgateway.UpdateAuthorizeDotNetGatewayResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.billing.private.paymentgateway.UpdateAuthorizeDotNetGatewayResponse}
 */
proto.billing.private.paymentgateway.UpdateAuthorizeDotNetGatewayResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPaymentGatewayId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setDisplayName(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.billing.private.paymentgateway.UpdateAuthorizeDotNetGatewayResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.billing.private.paymentgateway.UpdateAuthorizeDotNetGatewayResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.billing.private.paymentgateway.UpdateAuthorizeDotNetGatewayResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing.private.paymentgateway.UpdateAuthorizeDotNetGatewayResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPaymentGatewayId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getDisplayName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional int32 payment_gateway_id = 1;
 * @return {number}
 */
proto.billing.private.paymentgateway.UpdateAuthorizeDotNetGatewayResponse.prototype.getPaymentGatewayId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.billing.private.paymentgateway.UpdateAuthorizeDotNetGatewayResponse} returns this
 */
proto.billing.private.paymentgateway.UpdateAuthorizeDotNetGatewayResponse.prototype.setPaymentGatewayId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string display_name = 2;
 * @return {string}
 */
proto.billing.private.paymentgateway.UpdateAuthorizeDotNetGatewayResponse.prototype.getDisplayName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.billing.private.paymentgateway.UpdateAuthorizeDotNetGatewayResponse} returns this
 */
proto.billing.private.paymentgateway.UpdateAuthorizeDotNetGatewayResponse.prototype.setDisplayName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.billing.private.paymentgateway.CreateIxopayGatewayRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.billing.private.paymentgateway.CreateIxopayGatewayRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.billing.private.paymentgateway.CreateIxopayGatewayRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing.private.paymentgateway.CreateIxopayGatewayRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    companyDomain: jspb.Message.getFieldWithDefault(msg, 1, ""),
    displayName: jspb.Message.getFieldWithDefault(msg, 2, ""),
    username: jspb.Message.getFieldWithDefault(msg, 3, ""),
    apiKey: jspb.Message.getFieldWithDefault(msg, 4, ""),
    password: jspb.Message.getFieldWithDefault(msg, 5, ""),
    secret: jspb.Message.getFieldWithDefault(msg, 6, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.billing.private.paymentgateway.CreateIxopayGatewayRequest}
 */
proto.billing.private.paymentgateway.CreateIxopayGatewayRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.billing.private.paymentgateway.CreateIxopayGatewayRequest;
  return proto.billing.private.paymentgateway.CreateIxopayGatewayRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.billing.private.paymentgateway.CreateIxopayGatewayRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.billing.private.paymentgateway.CreateIxopayGatewayRequest}
 */
proto.billing.private.paymentgateway.CreateIxopayGatewayRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setCompanyDomain(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setDisplayName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setUsername(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setApiKey(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setPassword(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setSecret(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.billing.private.paymentgateway.CreateIxopayGatewayRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.billing.private.paymentgateway.CreateIxopayGatewayRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.billing.private.paymentgateway.CreateIxopayGatewayRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing.private.paymentgateway.CreateIxopayGatewayRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCompanyDomain();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getDisplayName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getUsername();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getApiKey();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getPassword();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getSecret();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
};


/**
 * optional string company_domain = 1;
 * @return {string}
 */
proto.billing.private.paymentgateway.CreateIxopayGatewayRequest.prototype.getCompanyDomain = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.billing.private.paymentgateway.CreateIxopayGatewayRequest} returns this
 */
proto.billing.private.paymentgateway.CreateIxopayGatewayRequest.prototype.setCompanyDomain = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string display_name = 2;
 * @return {string}
 */
proto.billing.private.paymentgateway.CreateIxopayGatewayRequest.prototype.getDisplayName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.billing.private.paymentgateway.CreateIxopayGatewayRequest} returns this
 */
proto.billing.private.paymentgateway.CreateIxopayGatewayRequest.prototype.setDisplayName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string username = 3;
 * @return {string}
 */
proto.billing.private.paymentgateway.CreateIxopayGatewayRequest.prototype.getUsername = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.billing.private.paymentgateway.CreateIxopayGatewayRequest} returns this
 */
proto.billing.private.paymentgateway.CreateIxopayGatewayRequest.prototype.setUsername = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string api_key = 4;
 * @return {string}
 */
proto.billing.private.paymentgateway.CreateIxopayGatewayRequest.prototype.getApiKey = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.billing.private.paymentgateway.CreateIxopayGatewayRequest} returns this
 */
proto.billing.private.paymentgateway.CreateIxopayGatewayRequest.prototype.setApiKey = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string password = 5;
 * @return {string}
 */
proto.billing.private.paymentgateway.CreateIxopayGatewayRequest.prototype.getPassword = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.billing.private.paymentgateway.CreateIxopayGatewayRequest} returns this
 */
proto.billing.private.paymentgateway.CreateIxopayGatewayRequest.prototype.setPassword = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string secret = 6;
 * @return {string}
 */
proto.billing.private.paymentgateway.CreateIxopayGatewayRequest.prototype.getSecret = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.billing.private.paymentgateway.CreateIxopayGatewayRequest} returns this
 */
proto.billing.private.paymentgateway.CreateIxopayGatewayRequest.prototype.setSecret = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.billing.private.paymentgateway.ReplaceGatewayToIxopayRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.billing.private.paymentgateway.ReplaceGatewayToIxopayRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.billing.private.paymentgateway.ReplaceGatewayToIxopayRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing.private.paymentgateway.ReplaceGatewayToIxopayRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    createIxopayRequest: (f = msg.getCreateIxopayRequest()) && proto.billing.private.paymentgateway.CreateIxopayGatewayRequest.toObject(includeInstance, f),
    paymentGatewayToReplaceId: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.billing.private.paymentgateway.ReplaceGatewayToIxopayRequest}
 */
proto.billing.private.paymentgateway.ReplaceGatewayToIxopayRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.billing.private.paymentgateway.ReplaceGatewayToIxopayRequest;
  return proto.billing.private.paymentgateway.ReplaceGatewayToIxopayRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.billing.private.paymentgateway.ReplaceGatewayToIxopayRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.billing.private.paymentgateway.ReplaceGatewayToIxopayRequest}
 */
proto.billing.private.paymentgateway.ReplaceGatewayToIxopayRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.billing.private.paymentgateway.CreateIxopayGatewayRequest;
      reader.readMessage(value,proto.billing.private.paymentgateway.CreateIxopayGatewayRequest.deserializeBinaryFromReader);
      msg.setCreateIxopayRequest(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPaymentGatewayToReplaceId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.billing.private.paymentgateway.ReplaceGatewayToIxopayRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.billing.private.paymentgateway.ReplaceGatewayToIxopayRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.billing.private.paymentgateway.ReplaceGatewayToIxopayRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing.private.paymentgateway.ReplaceGatewayToIxopayRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCreateIxopayRequest();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.billing.private.paymentgateway.CreateIxopayGatewayRequest.serializeBinaryToWriter
    );
  }
  f = message.getPaymentGatewayToReplaceId();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
};


/**
 * optional CreateIxopayGatewayRequest create_ixopay_request = 1;
 * @return {?proto.billing.private.paymentgateway.CreateIxopayGatewayRequest}
 */
proto.billing.private.paymentgateway.ReplaceGatewayToIxopayRequest.prototype.getCreateIxopayRequest = function() {
  return /** @type{?proto.billing.private.paymentgateway.CreateIxopayGatewayRequest} */ (
    jspb.Message.getWrapperField(this, proto.billing.private.paymentgateway.CreateIxopayGatewayRequest, 1));
};


/**
 * @param {?proto.billing.private.paymentgateway.CreateIxopayGatewayRequest|undefined} value
 * @return {!proto.billing.private.paymentgateway.ReplaceGatewayToIxopayRequest} returns this
*/
proto.billing.private.paymentgateway.ReplaceGatewayToIxopayRequest.prototype.setCreateIxopayRequest = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.billing.private.paymentgateway.ReplaceGatewayToIxopayRequest} returns this
 */
proto.billing.private.paymentgateway.ReplaceGatewayToIxopayRequest.prototype.clearCreateIxopayRequest = function() {
  return this.setCreateIxopayRequest(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.billing.private.paymentgateway.ReplaceGatewayToIxopayRequest.prototype.hasCreateIxopayRequest = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional int32 payment_gateway_to_replace_id = 2;
 * @return {number}
 */
proto.billing.private.paymentgateway.ReplaceGatewayToIxopayRequest.prototype.getPaymentGatewayToReplaceId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.billing.private.paymentgateway.ReplaceGatewayToIxopayRequest} returns this
 */
proto.billing.private.paymentgateway.ReplaceGatewayToIxopayRequest.prototype.setPaymentGatewayToReplaceId = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.billing.private.paymentgateway.CreateIxopayGatewayResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.billing.private.paymentgateway.CreateIxopayGatewayResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.billing.private.paymentgateway.CreateIxopayGatewayResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing.private.paymentgateway.CreateIxopayGatewayResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    paymentGatewayId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    displayName: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.billing.private.paymentgateway.CreateIxopayGatewayResponse}
 */
proto.billing.private.paymentgateway.CreateIxopayGatewayResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.billing.private.paymentgateway.CreateIxopayGatewayResponse;
  return proto.billing.private.paymentgateway.CreateIxopayGatewayResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.billing.private.paymentgateway.CreateIxopayGatewayResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.billing.private.paymentgateway.CreateIxopayGatewayResponse}
 */
proto.billing.private.paymentgateway.CreateIxopayGatewayResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPaymentGatewayId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setDisplayName(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.billing.private.paymentgateway.CreateIxopayGatewayResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.billing.private.paymentgateway.CreateIxopayGatewayResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.billing.private.paymentgateway.CreateIxopayGatewayResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing.private.paymentgateway.CreateIxopayGatewayResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPaymentGatewayId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getDisplayName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional int32 payment_gateway_id = 1;
 * @return {number}
 */
proto.billing.private.paymentgateway.CreateIxopayGatewayResponse.prototype.getPaymentGatewayId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.billing.private.paymentgateway.CreateIxopayGatewayResponse} returns this
 */
proto.billing.private.paymentgateway.CreateIxopayGatewayResponse.prototype.setPaymentGatewayId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string display_name = 2;
 * @return {string}
 */
proto.billing.private.paymentgateway.CreateIxopayGatewayResponse.prototype.getDisplayName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.billing.private.paymentgateway.CreateIxopayGatewayResponse} returns this
 */
proto.billing.private.paymentgateway.CreateIxopayGatewayResponse.prototype.setDisplayName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.billing.private.paymentgateway.UpdateIxopayGatewayRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.billing.private.paymentgateway.UpdateIxopayGatewayRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.billing.private.paymentgateway.UpdateIxopayGatewayRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing.private.paymentgateway.UpdateIxopayGatewayRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    companyDomain: jspb.Message.getFieldWithDefault(msg, 1, ""),
    paymentGatewayId: jspb.Message.getFieldWithDefault(msg, 2, 0),
    displayName: jspb.Message.getFieldWithDefault(msg, 3, ""),
    username: jspb.Message.getFieldWithDefault(msg, 4, ""),
    apiKey: jspb.Message.getFieldWithDefault(msg, 5, ""),
    password: jspb.Message.getFieldWithDefault(msg, 6, ""),
    secret: jspb.Message.getFieldWithDefault(msg, 7, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.billing.private.paymentgateway.UpdateIxopayGatewayRequest}
 */
proto.billing.private.paymentgateway.UpdateIxopayGatewayRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.billing.private.paymentgateway.UpdateIxopayGatewayRequest;
  return proto.billing.private.paymentgateway.UpdateIxopayGatewayRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.billing.private.paymentgateway.UpdateIxopayGatewayRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.billing.private.paymentgateway.UpdateIxopayGatewayRequest}
 */
proto.billing.private.paymentgateway.UpdateIxopayGatewayRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setCompanyDomain(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPaymentGatewayId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setDisplayName(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setUsername(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setApiKey(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setPassword(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setSecret(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.billing.private.paymentgateway.UpdateIxopayGatewayRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.billing.private.paymentgateway.UpdateIxopayGatewayRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.billing.private.paymentgateway.UpdateIxopayGatewayRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing.private.paymentgateway.UpdateIxopayGatewayRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCompanyDomain();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getPaymentGatewayId();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getDisplayName();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getUsername();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getApiKey();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getPassword();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getSecret();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
};


/**
 * optional string company_domain = 1;
 * @return {string}
 */
proto.billing.private.paymentgateway.UpdateIxopayGatewayRequest.prototype.getCompanyDomain = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.billing.private.paymentgateway.UpdateIxopayGatewayRequest} returns this
 */
proto.billing.private.paymentgateway.UpdateIxopayGatewayRequest.prototype.setCompanyDomain = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int32 payment_gateway_id = 2;
 * @return {number}
 */
proto.billing.private.paymentgateway.UpdateIxopayGatewayRequest.prototype.getPaymentGatewayId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.billing.private.paymentgateway.UpdateIxopayGatewayRequest} returns this
 */
proto.billing.private.paymentgateway.UpdateIxopayGatewayRequest.prototype.setPaymentGatewayId = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional string display_name = 3;
 * @return {string}
 */
proto.billing.private.paymentgateway.UpdateIxopayGatewayRequest.prototype.getDisplayName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.billing.private.paymentgateway.UpdateIxopayGatewayRequest} returns this
 */
proto.billing.private.paymentgateway.UpdateIxopayGatewayRequest.prototype.setDisplayName = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string username = 4;
 * @return {string}
 */
proto.billing.private.paymentgateway.UpdateIxopayGatewayRequest.prototype.getUsername = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.billing.private.paymentgateway.UpdateIxopayGatewayRequest} returns this
 */
proto.billing.private.paymentgateway.UpdateIxopayGatewayRequest.prototype.setUsername = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string api_key = 5;
 * @return {string}
 */
proto.billing.private.paymentgateway.UpdateIxopayGatewayRequest.prototype.getApiKey = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.billing.private.paymentgateway.UpdateIxopayGatewayRequest} returns this
 */
proto.billing.private.paymentgateway.UpdateIxopayGatewayRequest.prototype.setApiKey = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string password = 6;
 * @return {string}
 */
proto.billing.private.paymentgateway.UpdateIxopayGatewayRequest.prototype.getPassword = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.billing.private.paymentgateway.UpdateIxopayGatewayRequest} returns this
 */
proto.billing.private.paymentgateway.UpdateIxopayGatewayRequest.prototype.setPassword = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string secret = 7;
 * @return {string}
 */
proto.billing.private.paymentgateway.UpdateIxopayGatewayRequest.prototype.getSecret = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.billing.private.paymentgateway.UpdateIxopayGatewayRequest} returns this
 */
proto.billing.private.paymentgateway.UpdateIxopayGatewayRequest.prototype.setSecret = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.billing.private.paymentgateway.UpdateIxopayGatewayResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.billing.private.paymentgateway.UpdateIxopayGatewayResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.billing.private.paymentgateway.UpdateIxopayGatewayResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing.private.paymentgateway.UpdateIxopayGatewayResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    paymentGatewayId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    displayName: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.billing.private.paymentgateway.UpdateIxopayGatewayResponse}
 */
proto.billing.private.paymentgateway.UpdateIxopayGatewayResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.billing.private.paymentgateway.UpdateIxopayGatewayResponse;
  return proto.billing.private.paymentgateway.UpdateIxopayGatewayResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.billing.private.paymentgateway.UpdateIxopayGatewayResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.billing.private.paymentgateway.UpdateIxopayGatewayResponse}
 */
proto.billing.private.paymentgateway.UpdateIxopayGatewayResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPaymentGatewayId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setDisplayName(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.billing.private.paymentgateway.UpdateIxopayGatewayResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.billing.private.paymentgateway.UpdateIxopayGatewayResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.billing.private.paymentgateway.UpdateIxopayGatewayResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing.private.paymentgateway.UpdateIxopayGatewayResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPaymentGatewayId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getDisplayName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional int32 payment_gateway_id = 1;
 * @return {number}
 */
proto.billing.private.paymentgateway.UpdateIxopayGatewayResponse.prototype.getPaymentGatewayId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.billing.private.paymentgateway.UpdateIxopayGatewayResponse} returns this
 */
proto.billing.private.paymentgateway.UpdateIxopayGatewayResponse.prototype.setPaymentGatewayId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string display_name = 2;
 * @return {string}
 */
proto.billing.private.paymentgateway.UpdateIxopayGatewayResponse.prototype.getDisplayName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.billing.private.paymentgateway.UpdateIxopayGatewayResponse} returns this
 */
proto.billing.private.paymentgateway.UpdateIxopayGatewayResponse.prototype.setDisplayName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.billing.private.paymentgateway.CreateNMIGatewayRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.billing.private.paymentgateway.CreateNMIGatewayRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.billing.private.paymentgateway.CreateNMIGatewayRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing.private.paymentgateway.CreateNMIGatewayRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    companyDomain: jspb.Message.getFieldWithDefault(msg, 1, ""),
    displayName: jspb.Message.getFieldWithDefault(msg, 2, ""),
    login: jspb.Message.getFieldWithDefault(msg, 3, ""),
    password: jspb.Message.getFieldWithDefault(msg, 4, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.billing.private.paymentgateway.CreateNMIGatewayRequest}
 */
proto.billing.private.paymentgateway.CreateNMIGatewayRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.billing.private.paymentgateway.CreateNMIGatewayRequest;
  return proto.billing.private.paymentgateway.CreateNMIGatewayRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.billing.private.paymentgateway.CreateNMIGatewayRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.billing.private.paymentgateway.CreateNMIGatewayRequest}
 */
proto.billing.private.paymentgateway.CreateNMIGatewayRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setCompanyDomain(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setDisplayName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setLogin(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setPassword(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.billing.private.paymentgateway.CreateNMIGatewayRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.billing.private.paymentgateway.CreateNMIGatewayRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.billing.private.paymentgateway.CreateNMIGatewayRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing.private.paymentgateway.CreateNMIGatewayRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCompanyDomain();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getDisplayName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getLogin();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getPassword();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
};


/**
 * optional string company_domain = 1;
 * @return {string}
 */
proto.billing.private.paymentgateway.CreateNMIGatewayRequest.prototype.getCompanyDomain = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.billing.private.paymentgateway.CreateNMIGatewayRequest} returns this
 */
proto.billing.private.paymentgateway.CreateNMIGatewayRequest.prototype.setCompanyDomain = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string display_name = 2;
 * @return {string}
 */
proto.billing.private.paymentgateway.CreateNMIGatewayRequest.prototype.getDisplayName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.billing.private.paymentgateway.CreateNMIGatewayRequest} returns this
 */
proto.billing.private.paymentgateway.CreateNMIGatewayRequest.prototype.setDisplayName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string login = 3;
 * @return {string}
 */
proto.billing.private.paymentgateway.CreateNMIGatewayRequest.prototype.getLogin = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.billing.private.paymentgateway.CreateNMIGatewayRequest} returns this
 */
proto.billing.private.paymentgateway.CreateNMIGatewayRequest.prototype.setLogin = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string password = 4;
 * @return {string}
 */
proto.billing.private.paymentgateway.CreateNMIGatewayRequest.prototype.getPassword = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.billing.private.paymentgateway.CreateNMIGatewayRequest} returns this
 */
proto.billing.private.paymentgateway.CreateNMIGatewayRequest.prototype.setPassword = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.billing.private.paymentgateway.ReplaceGatewayToNMIRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.billing.private.paymentgateway.ReplaceGatewayToNMIRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.billing.private.paymentgateway.ReplaceGatewayToNMIRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing.private.paymentgateway.ReplaceGatewayToNMIRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    createNmiRequest: (f = msg.getCreateNmiRequest()) && proto.billing.private.paymentgateway.CreateNMIGatewayRequest.toObject(includeInstance, f),
    paymentGatewayToReplaceId: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.billing.private.paymentgateway.ReplaceGatewayToNMIRequest}
 */
proto.billing.private.paymentgateway.ReplaceGatewayToNMIRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.billing.private.paymentgateway.ReplaceGatewayToNMIRequest;
  return proto.billing.private.paymentgateway.ReplaceGatewayToNMIRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.billing.private.paymentgateway.ReplaceGatewayToNMIRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.billing.private.paymentgateway.ReplaceGatewayToNMIRequest}
 */
proto.billing.private.paymentgateway.ReplaceGatewayToNMIRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.billing.private.paymentgateway.CreateNMIGatewayRequest;
      reader.readMessage(value,proto.billing.private.paymentgateway.CreateNMIGatewayRequest.deserializeBinaryFromReader);
      msg.setCreateNmiRequest(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPaymentGatewayToReplaceId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.billing.private.paymentgateway.ReplaceGatewayToNMIRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.billing.private.paymentgateway.ReplaceGatewayToNMIRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.billing.private.paymentgateway.ReplaceGatewayToNMIRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing.private.paymentgateway.ReplaceGatewayToNMIRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCreateNmiRequest();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.billing.private.paymentgateway.CreateNMIGatewayRequest.serializeBinaryToWriter
    );
  }
  f = message.getPaymentGatewayToReplaceId();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
};


/**
 * optional CreateNMIGatewayRequest create_nmi_request = 1;
 * @return {?proto.billing.private.paymentgateway.CreateNMIGatewayRequest}
 */
proto.billing.private.paymentgateway.ReplaceGatewayToNMIRequest.prototype.getCreateNmiRequest = function() {
  return /** @type{?proto.billing.private.paymentgateway.CreateNMIGatewayRequest} */ (
    jspb.Message.getWrapperField(this, proto.billing.private.paymentgateway.CreateNMIGatewayRequest, 1));
};


/**
 * @param {?proto.billing.private.paymentgateway.CreateNMIGatewayRequest|undefined} value
 * @return {!proto.billing.private.paymentgateway.ReplaceGatewayToNMIRequest} returns this
*/
proto.billing.private.paymentgateway.ReplaceGatewayToNMIRequest.prototype.setCreateNmiRequest = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.billing.private.paymentgateway.ReplaceGatewayToNMIRequest} returns this
 */
proto.billing.private.paymentgateway.ReplaceGatewayToNMIRequest.prototype.clearCreateNmiRequest = function() {
  return this.setCreateNmiRequest(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.billing.private.paymentgateway.ReplaceGatewayToNMIRequest.prototype.hasCreateNmiRequest = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional int32 payment_gateway_to_replace_id = 2;
 * @return {number}
 */
proto.billing.private.paymentgateway.ReplaceGatewayToNMIRequest.prototype.getPaymentGatewayToReplaceId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.billing.private.paymentgateway.ReplaceGatewayToNMIRequest} returns this
 */
proto.billing.private.paymentgateway.ReplaceGatewayToNMIRequest.prototype.setPaymentGatewayToReplaceId = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.billing.private.paymentgateway.CreateNMIGatewayResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.billing.private.paymentgateway.CreateNMIGatewayResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.billing.private.paymentgateway.CreateNMIGatewayResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing.private.paymentgateway.CreateNMIGatewayResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    paymentGatewayId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    displayName: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.billing.private.paymentgateway.CreateNMIGatewayResponse}
 */
proto.billing.private.paymentgateway.CreateNMIGatewayResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.billing.private.paymentgateway.CreateNMIGatewayResponse;
  return proto.billing.private.paymentgateway.CreateNMIGatewayResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.billing.private.paymentgateway.CreateNMIGatewayResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.billing.private.paymentgateway.CreateNMIGatewayResponse}
 */
proto.billing.private.paymentgateway.CreateNMIGatewayResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPaymentGatewayId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setDisplayName(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.billing.private.paymentgateway.CreateNMIGatewayResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.billing.private.paymentgateway.CreateNMIGatewayResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.billing.private.paymentgateway.CreateNMIGatewayResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing.private.paymentgateway.CreateNMIGatewayResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPaymentGatewayId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getDisplayName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional int32 payment_gateway_id = 1;
 * @return {number}
 */
proto.billing.private.paymentgateway.CreateNMIGatewayResponse.prototype.getPaymentGatewayId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.billing.private.paymentgateway.CreateNMIGatewayResponse} returns this
 */
proto.billing.private.paymentgateway.CreateNMIGatewayResponse.prototype.setPaymentGatewayId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string display_name = 2;
 * @return {string}
 */
proto.billing.private.paymentgateway.CreateNMIGatewayResponse.prototype.getDisplayName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.billing.private.paymentgateway.CreateNMIGatewayResponse} returns this
 */
proto.billing.private.paymentgateway.CreateNMIGatewayResponse.prototype.setDisplayName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.billing.private.paymentgateway.UpdateNMIGatewayRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.billing.private.paymentgateway.UpdateNMIGatewayRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.billing.private.paymentgateway.UpdateNMIGatewayRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing.private.paymentgateway.UpdateNMIGatewayRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    companyDomain: jspb.Message.getFieldWithDefault(msg, 1, ""),
    paymentGatewayId: jspb.Message.getFieldWithDefault(msg, 2, 0),
    displayName: jspb.Message.getFieldWithDefault(msg, 3, ""),
    login: jspb.Message.getFieldWithDefault(msg, 4, ""),
    password: jspb.Message.getFieldWithDefault(msg, 5, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.billing.private.paymentgateway.UpdateNMIGatewayRequest}
 */
proto.billing.private.paymentgateway.UpdateNMIGatewayRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.billing.private.paymentgateway.UpdateNMIGatewayRequest;
  return proto.billing.private.paymentgateway.UpdateNMIGatewayRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.billing.private.paymentgateway.UpdateNMIGatewayRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.billing.private.paymentgateway.UpdateNMIGatewayRequest}
 */
proto.billing.private.paymentgateway.UpdateNMIGatewayRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setCompanyDomain(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPaymentGatewayId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setDisplayName(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setLogin(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setPassword(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.billing.private.paymentgateway.UpdateNMIGatewayRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.billing.private.paymentgateway.UpdateNMIGatewayRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.billing.private.paymentgateway.UpdateNMIGatewayRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing.private.paymentgateway.UpdateNMIGatewayRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCompanyDomain();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getPaymentGatewayId();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getDisplayName();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getLogin();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getPassword();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
};


/**
 * optional string company_domain = 1;
 * @return {string}
 */
proto.billing.private.paymentgateway.UpdateNMIGatewayRequest.prototype.getCompanyDomain = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.billing.private.paymentgateway.UpdateNMIGatewayRequest} returns this
 */
proto.billing.private.paymentgateway.UpdateNMIGatewayRequest.prototype.setCompanyDomain = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int32 payment_gateway_id = 2;
 * @return {number}
 */
proto.billing.private.paymentgateway.UpdateNMIGatewayRequest.prototype.getPaymentGatewayId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.billing.private.paymentgateway.UpdateNMIGatewayRequest} returns this
 */
proto.billing.private.paymentgateway.UpdateNMIGatewayRequest.prototype.setPaymentGatewayId = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional string display_name = 3;
 * @return {string}
 */
proto.billing.private.paymentgateway.UpdateNMIGatewayRequest.prototype.getDisplayName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.billing.private.paymentgateway.UpdateNMIGatewayRequest} returns this
 */
proto.billing.private.paymentgateway.UpdateNMIGatewayRequest.prototype.setDisplayName = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string login = 4;
 * @return {string}
 */
proto.billing.private.paymentgateway.UpdateNMIGatewayRequest.prototype.getLogin = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.billing.private.paymentgateway.UpdateNMIGatewayRequest} returns this
 */
proto.billing.private.paymentgateway.UpdateNMIGatewayRequest.prototype.setLogin = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string password = 5;
 * @return {string}
 */
proto.billing.private.paymentgateway.UpdateNMIGatewayRequest.prototype.getPassword = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.billing.private.paymentgateway.UpdateNMIGatewayRequest} returns this
 */
proto.billing.private.paymentgateway.UpdateNMIGatewayRequest.prototype.setPassword = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.billing.private.paymentgateway.UpdateNMIGatewayResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.billing.private.paymentgateway.UpdateNMIGatewayResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.billing.private.paymentgateway.UpdateNMIGatewayResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing.private.paymentgateway.UpdateNMIGatewayResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    paymentGatewayId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    displayName: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.billing.private.paymentgateway.UpdateNMIGatewayResponse}
 */
proto.billing.private.paymentgateway.UpdateNMIGatewayResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.billing.private.paymentgateway.UpdateNMIGatewayResponse;
  return proto.billing.private.paymentgateway.UpdateNMIGatewayResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.billing.private.paymentgateway.UpdateNMIGatewayResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.billing.private.paymentgateway.UpdateNMIGatewayResponse}
 */
proto.billing.private.paymentgateway.UpdateNMIGatewayResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPaymentGatewayId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setDisplayName(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.billing.private.paymentgateway.UpdateNMIGatewayResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.billing.private.paymentgateway.UpdateNMIGatewayResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.billing.private.paymentgateway.UpdateNMIGatewayResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing.private.paymentgateway.UpdateNMIGatewayResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPaymentGatewayId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getDisplayName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional int32 payment_gateway_id = 1;
 * @return {number}
 */
proto.billing.private.paymentgateway.UpdateNMIGatewayResponse.prototype.getPaymentGatewayId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.billing.private.paymentgateway.UpdateNMIGatewayResponse} returns this
 */
proto.billing.private.paymentgateway.UpdateNMIGatewayResponse.prototype.setPaymentGatewayId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string display_name = 2;
 * @return {string}
 */
proto.billing.private.paymentgateway.UpdateNMIGatewayResponse.prototype.getDisplayName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.billing.private.paymentgateway.UpdateNMIGatewayResponse} returns this
 */
proto.billing.private.paymentgateway.UpdateNMIGatewayResponse.prototype.setDisplayName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.billing.private.paymentgateway.CreateCloverConnectRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.billing.private.paymentgateway.CreateCloverConnectRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.billing.private.paymentgateway.CreateCloverConnectRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing.private.paymentgateway.CreateCloverConnectRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    companyDomain: jspb.Message.getFieldWithDefault(msg, 1, ""),
    displayName: jspb.Message.getFieldWithDefault(msg, 2, ""),
    login: jspb.Message.getFieldWithDefault(msg, 3, ""),
    password: jspb.Message.getFieldWithDefault(msg, 4, ""),
    merchantId: jspb.Message.getFieldWithDefault(msg, 5, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.billing.private.paymentgateway.CreateCloverConnectRequest}
 */
proto.billing.private.paymentgateway.CreateCloverConnectRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.billing.private.paymentgateway.CreateCloverConnectRequest;
  return proto.billing.private.paymentgateway.CreateCloverConnectRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.billing.private.paymentgateway.CreateCloverConnectRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.billing.private.paymentgateway.CreateCloverConnectRequest}
 */
proto.billing.private.paymentgateway.CreateCloverConnectRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setCompanyDomain(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setDisplayName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setLogin(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setPassword(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setMerchantId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.billing.private.paymentgateway.CreateCloverConnectRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.billing.private.paymentgateway.CreateCloverConnectRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.billing.private.paymentgateway.CreateCloverConnectRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing.private.paymentgateway.CreateCloverConnectRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCompanyDomain();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getDisplayName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getLogin();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getPassword();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getMerchantId();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
};


/**
 * optional string company_domain = 1;
 * @return {string}
 */
proto.billing.private.paymentgateway.CreateCloverConnectRequest.prototype.getCompanyDomain = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.billing.private.paymentgateway.CreateCloverConnectRequest} returns this
 */
proto.billing.private.paymentgateway.CreateCloverConnectRequest.prototype.setCompanyDomain = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string display_name = 2;
 * @return {string}
 */
proto.billing.private.paymentgateway.CreateCloverConnectRequest.prototype.getDisplayName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.billing.private.paymentgateway.CreateCloverConnectRequest} returns this
 */
proto.billing.private.paymentgateway.CreateCloverConnectRequest.prototype.setDisplayName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string login = 3;
 * @return {string}
 */
proto.billing.private.paymentgateway.CreateCloverConnectRequest.prototype.getLogin = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.billing.private.paymentgateway.CreateCloverConnectRequest} returns this
 */
proto.billing.private.paymentgateway.CreateCloverConnectRequest.prototype.setLogin = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string password = 4;
 * @return {string}
 */
proto.billing.private.paymentgateway.CreateCloverConnectRequest.prototype.getPassword = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.billing.private.paymentgateway.CreateCloverConnectRequest} returns this
 */
proto.billing.private.paymentgateway.CreateCloverConnectRequest.prototype.setPassword = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string merchant_id = 5;
 * @return {string}
 */
proto.billing.private.paymentgateway.CreateCloverConnectRequest.prototype.getMerchantId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.billing.private.paymentgateway.CreateCloverConnectRequest} returns this
 */
proto.billing.private.paymentgateway.CreateCloverConnectRequest.prototype.setMerchantId = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.billing.private.paymentgateway.ReplaceGatewayToCloverConnectRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.billing.private.paymentgateway.ReplaceGatewayToCloverConnectRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.billing.private.paymentgateway.ReplaceGatewayToCloverConnectRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing.private.paymentgateway.ReplaceGatewayToCloverConnectRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    createCloverConnectRequest: (f = msg.getCreateCloverConnectRequest()) && proto.billing.private.paymentgateway.CreateCloverConnectRequest.toObject(includeInstance, f),
    paymentGatewayToReplaceId: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.billing.private.paymentgateway.ReplaceGatewayToCloverConnectRequest}
 */
proto.billing.private.paymentgateway.ReplaceGatewayToCloverConnectRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.billing.private.paymentgateway.ReplaceGatewayToCloverConnectRequest;
  return proto.billing.private.paymentgateway.ReplaceGatewayToCloverConnectRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.billing.private.paymentgateway.ReplaceGatewayToCloverConnectRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.billing.private.paymentgateway.ReplaceGatewayToCloverConnectRequest}
 */
proto.billing.private.paymentgateway.ReplaceGatewayToCloverConnectRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.billing.private.paymentgateway.CreateCloverConnectRequest;
      reader.readMessage(value,proto.billing.private.paymentgateway.CreateCloverConnectRequest.deserializeBinaryFromReader);
      msg.setCreateCloverConnectRequest(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPaymentGatewayToReplaceId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.billing.private.paymentgateway.ReplaceGatewayToCloverConnectRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.billing.private.paymentgateway.ReplaceGatewayToCloverConnectRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.billing.private.paymentgateway.ReplaceGatewayToCloverConnectRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing.private.paymentgateway.ReplaceGatewayToCloverConnectRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCreateCloverConnectRequest();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.billing.private.paymentgateway.CreateCloverConnectRequest.serializeBinaryToWriter
    );
  }
  f = message.getPaymentGatewayToReplaceId();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
};


/**
 * optional CreateCloverConnectRequest create_clover_connect_request = 1;
 * @return {?proto.billing.private.paymentgateway.CreateCloverConnectRequest}
 */
proto.billing.private.paymentgateway.ReplaceGatewayToCloverConnectRequest.prototype.getCreateCloverConnectRequest = function() {
  return /** @type{?proto.billing.private.paymentgateway.CreateCloverConnectRequest} */ (
    jspb.Message.getWrapperField(this, proto.billing.private.paymentgateway.CreateCloverConnectRequest, 1));
};


/**
 * @param {?proto.billing.private.paymentgateway.CreateCloverConnectRequest|undefined} value
 * @return {!proto.billing.private.paymentgateway.ReplaceGatewayToCloverConnectRequest} returns this
*/
proto.billing.private.paymentgateway.ReplaceGatewayToCloverConnectRequest.prototype.setCreateCloverConnectRequest = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.billing.private.paymentgateway.ReplaceGatewayToCloverConnectRequest} returns this
 */
proto.billing.private.paymentgateway.ReplaceGatewayToCloverConnectRequest.prototype.clearCreateCloverConnectRequest = function() {
  return this.setCreateCloverConnectRequest(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.billing.private.paymentgateway.ReplaceGatewayToCloverConnectRequest.prototype.hasCreateCloverConnectRequest = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional int32 payment_gateway_to_replace_id = 2;
 * @return {number}
 */
proto.billing.private.paymentgateway.ReplaceGatewayToCloverConnectRequest.prototype.getPaymentGatewayToReplaceId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.billing.private.paymentgateway.ReplaceGatewayToCloverConnectRequest} returns this
 */
proto.billing.private.paymentgateway.ReplaceGatewayToCloverConnectRequest.prototype.setPaymentGatewayToReplaceId = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.billing.private.paymentgateway.CreateCloverConnectResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.billing.private.paymentgateway.CreateCloverConnectResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.billing.private.paymentgateway.CreateCloverConnectResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing.private.paymentgateway.CreateCloverConnectResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    paymentGatewayId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    displayName: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.billing.private.paymentgateway.CreateCloverConnectResponse}
 */
proto.billing.private.paymentgateway.CreateCloverConnectResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.billing.private.paymentgateway.CreateCloverConnectResponse;
  return proto.billing.private.paymentgateway.CreateCloverConnectResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.billing.private.paymentgateway.CreateCloverConnectResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.billing.private.paymentgateway.CreateCloverConnectResponse}
 */
proto.billing.private.paymentgateway.CreateCloverConnectResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPaymentGatewayId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setDisplayName(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.billing.private.paymentgateway.CreateCloverConnectResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.billing.private.paymentgateway.CreateCloverConnectResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.billing.private.paymentgateway.CreateCloverConnectResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing.private.paymentgateway.CreateCloverConnectResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPaymentGatewayId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getDisplayName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional int32 payment_gateway_id = 1;
 * @return {number}
 */
proto.billing.private.paymentgateway.CreateCloverConnectResponse.prototype.getPaymentGatewayId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.billing.private.paymentgateway.CreateCloverConnectResponse} returns this
 */
proto.billing.private.paymentgateway.CreateCloverConnectResponse.prototype.setPaymentGatewayId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string display_name = 2;
 * @return {string}
 */
proto.billing.private.paymentgateway.CreateCloverConnectResponse.prototype.getDisplayName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.billing.private.paymentgateway.CreateCloverConnectResponse} returns this
 */
proto.billing.private.paymentgateway.CreateCloverConnectResponse.prototype.setDisplayName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.billing.private.paymentgateway.UpdateCloverConnectRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.billing.private.paymentgateway.UpdateCloverConnectRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.billing.private.paymentgateway.UpdateCloverConnectRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing.private.paymentgateway.UpdateCloverConnectRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    companyDomain: jspb.Message.getFieldWithDefault(msg, 1, ""),
    paymentGatewayId: jspb.Message.getFieldWithDefault(msg, 2, 0),
    displayName: jspb.Message.getFieldWithDefault(msg, 3, ""),
    login: jspb.Message.getFieldWithDefault(msg, 4, ""),
    password: jspb.Message.getFieldWithDefault(msg, 5, ""),
    merchantId: jspb.Message.getFieldWithDefault(msg, 6, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.billing.private.paymentgateway.UpdateCloverConnectRequest}
 */
proto.billing.private.paymentgateway.UpdateCloverConnectRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.billing.private.paymentgateway.UpdateCloverConnectRequest;
  return proto.billing.private.paymentgateway.UpdateCloverConnectRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.billing.private.paymentgateway.UpdateCloverConnectRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.billing.private.paymentgateway.UpdateCloverConnectRequest}
 */
proto.billing.private.paymentgateway.UpdateCloverConnectRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setCompanyDomain(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPaymentGatewayId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setDisplayName(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setLogin(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setPassword(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setMerchantId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.billing.private.paymentgateway.UpdateCloverConnectRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.billing.private.paymentgateway.UpdateCloverConnectRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.billing.private.paymentgateway.UpdateCloverConnectRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing.private.paymentgateway.UpdateCloverConnectRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCompanyDomain();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getPaymentGatewayId();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getDisplayName();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getLogin();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getPassword();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getMerchantId();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
};


/**
 * optional string company_domain = 1;
 * @return {string}
 */
proto.billing.private.paymentgateway.UpdateCloverConnectRequest.prototype.getCompanyDomain = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.billing.private.paymentgateway.UpdateCloverConnectRequest} returns this
 */
proto.billing.private.paymentgateway.UpdateCloverConnectRequest.prototype.setCompanyDomain = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int32 payment_gateway_id = 2;
 * @return {number}
 */
proto.billing.private.paymentgateway.UpdateCloverConnectRequest.prototype.getPaymentGatewayId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.billing.private.paymentgateway.UpdateCloverConnectRequest} returns this
 */
proto.billing.private.paymentgateway.UpdateCloverConnectRequest.prototype.setPaymentGatewayId = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional string display_name = 3;
 * @return {string}
 */
proto.billing.private.paymentgateway.UpdateCloverConnectRequest.prototype.getDisplayName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.billing.private.paymentgateway.UpdateCloverConnectRequest} returns this
 */
proto.billing.private.paymentgateway.UpdateCloverConnectRequest.prototype.setDisplayName = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string login = 4;
 * @return {string}
 */
proto.billing.private.paymentgateway.UpdateCloverConnectRequest.prototype.getLogin = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.billing.private.paymentgateway.UpdateCloverConnectRequest} returns this
 */
proto.billing.private.paymentgateway.UpdateCloverConnectRequest.prototype.setLogin = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string password = 5;
 * @return {string}
 */
proto.billing.private.paymentgateway.UpdateCloverConnectRequest.prototype.getPassword = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.billing.private.paymentgateway.UpdateCloverConnectRequest} returns this
 */
proto.billing.private.paymentgateway.UpdateCloverConnectRequest.prototype.setPassword = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string merchant_id = 6;
 * @return {string}
 */
proto.billing.private.paymentgateway.UpdateCloverConnectRequest.prototype.getMerchantId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.billing.private.paymentgateway.UpdateCloverConnectRequest} returns this
 */
proto.billing.private.paymentgateway.UpdateCloverConnectRequest.prototype.setMerchantId = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.billing.private.paymentgateway.UpdateCloverConnectResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.billing.private.paymentgateway.UpdateCloverConnectResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.billing.private.paymentgateway.UpdateCloverConnectResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing.private.paymentgateway.UpdateCloverConnectResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    paymentGatewayId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    displayName: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.billing.private.paymentgateway.UpdateCloverConnectResponse}
 */
proto.billing.private.paymentgateway.UpdateCloverConnectResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.billing.private.paymentgateway.UpdateCloverConnectResponse;
  return proto.billing.private.paymentgateway.UpdateCloverConnectResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.billing.private.paymentgateway.UpdateCloverConnectResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.billing.private.paymentgateway.UpdateCloverConnectResponse}
 */
proto.billing.private.paymentgateway.UpdateCloverConnectResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPaymentGatewayId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setDisplayName(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.billing.private.paymentgateway.UpdateCloverConnectResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.billing.private.paymentgateway.UpdateCloverConnectResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.billing.private.paymentgateway.UpdateCloverConnectResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing.private.paymentgateway.UpdateCloverConnectResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPaymentGatewayId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getDisplayName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional int32 payment_gateway_id = 1;
 * @return {number}
 */
proto.billing.private.paymentgateway.UpdateCloverConnectResponse.prototype.getPaymentGatewayId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.billing.private.paymentgateway.UpdateCloverConnectResponse} returns this
 */
proto.billing.private.paymentgateway.UpdateCloverConnectResponse.prototype.setPaymentGatewayId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string display_name = 2;
 * @return {string}
 */
proto.billing.private.paymentgateway.UpdateCloverConnectResponse.prototype.getDisplayName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.billing.private.paymentgateway.UpdateCloverConnectResponse} returns this
 */
proto.billing.private.paymentgateway.UpdateCloverConnectResponse.prototype.setDisplayName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.billing.private.paymentgateway.CreatePaymentCloudRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.billing.private.paymentgateway.CreatePaymentCloudRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.billing.private.paymentgateway.CreatePaymentCloudRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing.private.paymentgateway.CreatePaymentCloudRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    companyDomain: jspb.Message.getFieldWithDefault(msg, 1, ""),
    displayName: jspb.Message.getFieldWithDefault(msg, 2, ""),
    apiLoginId: jspb.Message.getFieldWithDefault(msg, 3, ""),
    transactionKey: jspb.Message.getFieldWithDefault(msg, 4, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.billing.private.paymentgateway.CreatePaymentCloudRequest}
 */
proto.billing.private.paymentgateway.CreatePaymentCloudRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.billing.private.paymentgateway.CreatePaymentCloudRequest;
  return proto.billing.private.paymentgateway.CreatePaymentCloudRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.billing.private.paymentgateway.CreatePaymentCloudRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.billing.private.paymentgateway.CreatePaymentCloudRequest}
 */
proto.billing.private.paymentgateway.CreatePaymentCloudRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setCompanyDomain(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setDisplayName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setApiLoginId(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setTransactionKey(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.billing.private.paymentgateway.CreatePaymentCloudRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.billing.private.paymentgateway.CreatePaymentCloudRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.billing.private.paymentgateway.CreatePaymentCloudRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing.private.paymentgateway.CreatePaymentCloudRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCompanyDomain();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getDisplayName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getApiLoginId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getTransactionKey();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
};


/**
 * optional string company_domain = 1;
 * @return {string}
 */
proto.billing.private.paymentgateway.CreatePaymentCloudRequest.prototype.getCompanyDomain = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.billing.private.paymentgateway.CreatePaymentCloudRequest} returns this
 */
proto.billing.private.paymentgateway.CreatePaymentCloudRequest.prototype.setCompanyDomain = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string display_name = 2;
 * @return {string}
 */
proto.billing.private.paymentgateway.CreatePaymentCloudRequest.prototype.getDisplayName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.billing.private.paymentgateway.CreatePaymentCloudRequest} returns this
 */
proto.billing.private.paymentgateway.CreatePaymentCloudRequest.prototype.setDisplayName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string api_login_id = 3;
 * @return {string}
 */
proto.billing.private.paymentgateway.CreatePaymentCloudRequest.prototype.getApiLoginId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.billing.private.paymentgateway.CreatePaymentCloudRequest} returns this
 */
proto.billing.private.paymentgateway.CreatePaymentCloudRequest.prototype.setApiLoginId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string transaction_key = 4;
 * @return {string}
 */
proto.billing.private.paymentgateway.CreatePaymentCloudRequest.prototype.getTransactionKey = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.billing.private.paymentgateway.CreatePaymentCloudRequest} returns this
 */
proto.billing.private.paymentgateway.CreatePaymentCloudRequest.prototype.setTransactionKey = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.billing.private.paymentgateway.ReplaceGatewayToPaymentCloudRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.billing.private.paymentgateway.ReplaceGatewayToPaymentCloudRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.billing.private.paymentgateway.ReplaceGatewayToPaymentCloudRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing.private.paymentgateway.ReplaceGatewayToPaymentCloudRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    createPaymentCloudRequest: (f = msg.getCreatePaymentCloudRequest()) && proto.billing.private.paymentgateway.CreatePaymentCloudRequest.toObject(includeInstance, f),
    paymentGatewayToReplaceId: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.billing.private.paymentgateway.ReplaceGatewayToPaymentCloudRequest}
 */
proto.billing.private.paymentgateway.ReplaceGatewayToPaymentCloudRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.billing.private.paymentgateway.ReplaceGatewayToPaymentCloudRequest;
  return proto.billing.private.paymentgateway.ReplaceGatewayToPaymentCloudRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.billing.private.paymentgateway.ReplaceGatewayToPaymentCloudRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.billing.private.paymentgateway.ReplaceGatewayToPaymentCloudRequest}
 */
proto.billing.private.paymentgateway.ReplaceGatewayToPaymentCloudRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.billing.private.paymentgateway.CreatePaymentCloudRequest;
      reader.readMessage(value,proto.billing.private.paymentgateway.CreatePaymentCloudRequest.deserializeBinaryFromReader);
      msg.setCreatePaymentCloudRequest(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPaymentGatewayToReplaceId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.billing.private.paymentgateway.ReplaceGatewayToPaymentCloudRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.billing.private.paymentgateway.ReplaceGatewayToPaymentCloudRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.billing.private.paymentgateway.ReplaceGatewayToPaymentCloudRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing.private.paymentgateway.ReplaceGatewayToPaymentCloudRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCreatePaymentCloudRequest();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.billing.private.paymentgateway.CreatePaymentCloudRequest.serializeBinaryToWriter
    );
  }
  f = message.getPaymentGatewayToReplaceId();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
};


/**
 * optional CreatePaymentCloudRequest create_payment_cloud_request = 1;
 * @return {?proto.billing.private.paymentgateway.CreatePaymentCloudRequest}
 */
proto.billing.private.paymentgateway.ReplaceGatewayToPaymentCloudRequest.prototype.getCreatePaymentCloudRequest = function() {
  return /** @type{?proto.billing.private.paymentgateway.CreatePaymentCloudRequest} */ (
    jspb.Message.getWrapperField(this, proto.billing.private.paymentgateway.CreatePaymentCloudRequest, 1));
};


/**
 * @param {?proto.billing.private.paymentgateway.CreatePaymentCloudRequest|undefined} value
 * @return {!proto.billing.private.paymentgateway.ReplaceGatewayToPaymentCloudRequest} returns this
*/
proto.billing.private.paymentgateway.ReplaceGatewayToPaymentCloudRequest.prototype.setCreatePaymentCloudRequest = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.billing.private.paymentgateway.ReplaceGatewayToPaymentCloudRequest} returns this
 */
proto.billing.private.paymentgateway.ReplaceGatewayToPaymentCloudRequest.prototype.clearCreatePaymentCloudRequest = function() {
  return this.setCreatePaymentCloudRequest(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.billing.private.paymentgateway.ReplaceGatewayToPaymentCloudRequest.prototype.hasCreatePaymentCloudRequest = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional int32 payment_gateway_to_replace_id = 2;
 * @return {number}
 */
proto.billing.private.paymentgateway.ReplaceGatewayToPaymentCloudRequest.prototype.getPaymentGatewayToReplaceId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.billing.private.paymentgateway.ReplaceGatewayToPaymentCloudRequest} returns this
 */
proto.billing.private.paymentgateway.ReplaceGatewayToPaymentCloudRequest.prototype.setPaymentGatewayToReplaceId = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.billing.private.paymentgateway.CreatePaymentCloudResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.billing.private.paymentgateway.CreatePaymentCloudResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.billing.private.paymentgateway.CreatePaymentCloudResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing.private.paymentgateway.CreatePaymentCloudResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    paymentGatewayId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    displayName: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.billing.private.paymentgateway.CreatePaymentCloudResponse}
 */
proto.billing.private.paymentgateway.CreatePaymentCloudResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.billing.private.paymentgateway.CreatePaymentCloudResponse;
  return proto.billing.private.paymentgateway.CreatePaymentCloudResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.billing.private.paymentgateway.CreatePaymentCloudResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.billing.private.paymentgateway.CreatePaymentCloudResponse}
 */
proto.billing.private.paymentgateway.CreatePaymentCloudResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPaymentGatewayId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setDisplayName(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.billing.private.paymentgateway.CreatePaymentCloudResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.billing.private.paymentgateway.CreatePaymentCloudResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.billing.private.paymentgateway.CreatePaymentCloudResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing.private.paymentgateway.CreatePaymentCloudResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPaymentGatewayId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getDisplayName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional int32 payment_gateway_id = 1;
 * @return {number}
 */
proto.billing.private.paymentgateway.CreatePaymentCloudResponse.prototype.getPaymentGatewayId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.billing.private.paymentgateway.CreatePaymentCloudResponse} returns this
 */
proto.billing.private.paymentgateway.CreatePaymentCloudResponse.prototype.setPaymentGatewayId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string display_name = 2;
 * @return {string}
 */
proto.billing.private.paymentgateway.CreatePaymentCloudResponse.prototype.getDisplayName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.billing.private.paymentgateway.CreatePaymentCloudResponse} returns this
 */
proto.billing.private.paymentgateway.CreatePaymentCloudResponse.prototype.setDisplayName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.billing.private.paymentgateway.UpdatePaymentCloudRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.billing.private.paymentgateway.UpdatePaymentCloudRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.billing.private.paymentgateway.UpdatePaymentCloudRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing.private.paymentgateway.UpdatePaymentCloudRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    companyDomain: jspb.Message.getFieldWithDefault(msg, 1, ""),
    paymentGatewayId: jspb.Message.getFieldWithDefault(msg, 2, 0),
    displayName: jspb.Message.getFieldWithDefault(msg, 3, ""),
    apiLoginId: jspb.Message.getFieldWithDefault(msg, 4, ""),
    transactionKey: jspb.Message.getFieldWithDefault(msg, 5, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.billing.private.paymentgateway.UpdatePaymentCloudRequest}
 */
proto.billing.private.paymentgateway.UpdatePaymentCloudRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.billing.private.paymentgateway.UpdatePaymentCloudRequest;
  return proto.billing.private.paymentgateway.UpdatePaymentCloudRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.billing.private.paymentgateway.UpdatePaymentCloudRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.billing.private.paymentgateway.UpdatePaymentCloudRequest}
 */
proto.billing.private.paymentgateway.UpdatePaymentCloudRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setCompanyDomain(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPaymentGatewayId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setDisplayName(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setApiLoginId(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setTransactionKey(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.billing.private.paymentgateway.UpdatePaymentCloudRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.billing.private.paymentgateway.UpdatePaymentCloudRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.billing.private.paymentgateway.UpdatePaymentCloudRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing.private.paymentgateway.UpdatePaymentCloudRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCompanyDomain();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getPaymentGatewayId();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getDisplayName();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getApiLoginId();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getTransactionKey();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
};


/**
 * optional string company_domain = 1;
 * @return {string}
 */
proto.billing.private.paymentgateway.UpdatePaymentCloudRequest.prototype.getCompanyDomain = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.billing.private.paymentgateway.UpdatePaymentCloudRequest} returns this
 */
proto.billing.private.paymentgateway.UpdatePaymentCloudRequest.prototype.setCompanyDomain = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int32 payment_gateway_id = 2;
 * @return {number}
 */
proto.billing.private.paymentgateway.UpdatePaymentCloudRequest.prototype.getPaymentGatewayId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.billing.private.paymentgateway.UpdatePaymentCloudRequest} returns this
 */
proto.billing.private.paymentgateway.UpdatePaymentCloudRequest.prototype.setPaymentGatewayId = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional string display_name = 3;
 * @return {string}
 */
proto.billing.private.paymentgateway.UpdatePaymentCloudRequest.prototype.getDisplayName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.billing.private.paymentgateway.UpdatePaymentCloudRequest} returns this
 */
proto.billing.private.paymentgateway.UpdatePaymentCloudRequest.prototype.setDisplayName = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string api_login_id = 4;
 * @return {string}
 */
proto.billing.private.paymentgateway.UpdatePaymentCloudRequest.prototype.getApiLoginId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.billing.private.paymentgateway.UpdatePaymentCloudRequest} returns this
 */
proto.billing.private.paymentgateway.UpdatePaymentCloudRequest.prototype.setApiLoginId = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string transaction_key = 5;
 * @return {string}
 */
proto.billing.private.paymentgateway.UpdatePaymentCloudRequest.prototype.getTransactionKey = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.billing.private.paymentgateway.UpdatePaymentCloudRequest} returns this
 */
proto.billing.private.paymentgateway.UpdatePaymentCloudRequest.prototype.setTransactionKey = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.billing.private.paymentgateway.UpdatePaymentCloudResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.billing.private.paymentgateway.UpdatePaymentCloudResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.billing.private.paymentgateway.UpdatePaymentCloudResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing.private.paymentgateway.UpdatePaymentCloudResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    paymentGatewayId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    displayName: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.billing.private.paymentgateway.UpdatePaymentCloudResponse}
 */
proto.billing.private.paymentgateway.UpdatePaymentCloudResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.billing.private.paymentgateway.UpdatePaymentCloudResponse;
  return proto.billing.private.paymentgateway.UpdatePaymentCloudResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.billing.private.paymentgateway.UpdatePaymentCloudResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.billing.private.paymentgateway.UpdatePaymentCloudResponse}
 */
proto.billing.private.paymentgateway.UpdatePaymentCloudResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPaymentGatewayId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setDisplayName(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.billing.private.paymentgateway.UpdatePaymentCloudResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.billing.private.paymentgateway.UpdatePaymentCloudResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.billing.private.paymentgateway.UpdatePaymentCloudResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing.private.paymentgateway.UpdatePaymentCloudResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPaymentGatewayId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getDisplayName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional int32 payment_gateway_id = 1;
 * @return {number}
 */
proto.billing.private.paymentgateway.UpdatePaymentCloudResponse.prototype.getPaymentGatewayId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.billing.private.paymentgateway.UpdatePaymentCloudResponse} returns this
 */
proto.billing.private.paymentgateway.UpdatePaymentCloudResponse.prototype.setPaymentGatewayId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string display_name = 2;
 * @return {string}
 */
proto.billing.private.paymentgateway.UpdatePaymentCloudResponse.prototype.getDisplayName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.billing.private.paymentgateway.UpdatePaymentCloudResponse} returns this
 */
proto.billing.private.paymentgateway.UpdatePaymentCloudResponse.prototype.setDisplayName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.billing.private.paymentgateway.DeletePaymentGatewayRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.billing.private.paymentgateway.DeletePaymentGatewayRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.billing.private.paymentgateway.DeletePaymentGatewayRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing.private.paymentgateway.DeletePaymentGatewayRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    paymentGatewayId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    companyDomain: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.billing.private.paymentgateway.DeletePaymentGatewayRequest}
 */
proto.billing.private.paymentgateway.DeletePaymentGatewayRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.billing.private.paymentgateway.DeletePaymentGatewayRequest;
  return proto.billing.private.paymentgateway.DeletePaymentGatewayRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.billing.private.paymentgateway.DeletePaymentGatewayRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.billing.private.paymentgateway.DeletePaymentGatewayRequest}
 */
proto.billing.private.paymentgateway.DeletePaymentGatewayRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPaymentGatewayId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setCompanyDomain(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.billing.private.paymentgateway.DeletePaymentGatewayRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.billing.private.paymentgateway.DeletePaymentGatewayRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.billing.private.paymentgateway.DeletePaymentGatewayRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing.private.paymentgateway.DeletePaymentGatewayRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPaymentGatewayId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getCompanyDomain();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional int32 payment_gateway_id = 1;
 * @return {number}
 */
proto.billing.private.paymentgateway.DeletePaymentGatewayRequest.prototype.getPaymentGatewayId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.billing.private.paymentgateway.DeletePaymentGatewayRequest} returns this
 */
proto.billing.private.paymentgateway.DeletePaymentGatewayRequest.prototype.setPaymentGatewayId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string company_domain = 2;
 * @return {string}
 */
proto.billing.private.paymentgateway.DeletePaymentGatewayRequest.prototype.getCompanyDomain = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.billing.private.paymentgateway.DeletePaymentGatewayRequest} returns this
 */
proto.billing.private.paymentgateway.DeletePaymentGatewayRequest.prototype.setCompanyDomain = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.billing.private.paymentgateway.DeletePaymentGatewayResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.billing.private.paymentgateway.DeletePaymentGatewayResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.billing.private.paymentgateway.DeletePaymentGatewayResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing.private.paymentgateway.DeletePaymentGatewayResponse.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.billing.private.paymentgateway.DeletePaymentGatewayResponse}
 */
proto.billing.private.paymentgateway.DeletePaymentGatewayResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.billing.private.paymentgateway.DeletePaymentGatewayResponse;
  return proto.billing.private.paymentgateway.DeletePaymentGatewayResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.billing.private.paymentgateway.DeletePaymentGatewayResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.billing.private.paymentgateway.DeletePaymentGatewayResponse}
 */
proto.billing.private.paymentgateway.DeletePaymentGatewayResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.billing.private.paymentgateway.DeletePaymentGatewayResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.billing.private.paymentgateway.DeletePaymentGatewayResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.billing.private.paymentgateway.DeletePaymentGatewayResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing.private.paymentgateway.DeletePaymentGatewayResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.billing.private.paymentgateway.BraintreeMerchantAccount.prototype.toObject = function(opt_includeInstance) {
  return proto.billing.private.paymentgateway.BraintreeMerchantAccount.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.billing.private.paymentgateway.BraintreeMerchantAccount} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing.private.paymentgateway.BraintreeMerchantAccount.toObject = function(includeInstance, msg) {
  var f, obj = {
    merchantAccountId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    currencyIsoCode: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.billing.private.paymentgateway.BraintreeMerchantAccount}
 */
proto.billing.private.paymentgateway.BraintreeMerchantAccount.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.billing.private.paymentgateway.BraintreeMerchantAccount;
  return proto.billing.private.paymentgateway.BraintreeMerchantAccount.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.billing.private.paymentgateway.BraintreeMerchantAccount} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.billing.private.paymentgateway.BraintreeMerchantAccount}
 */
proto.billing.private.paymentgateway.BraintreeMerchantAccount.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setMerchantAccountId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setCurrencyIsoCode(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.billing.private.paymentgateway.BraintreeMerchantAccount.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.billing.private.paymentgateway.BraintreeMerchantAccount.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.billing.private.paymentgateway.BraintreeMerchantAccount} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing.private.paymentgateway.BraintreeMerchantAccount.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMerchantAccountId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getCurrencyIsoCode();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string merchant_account_id = 1;
 * @return {string}
 */
proto.billing.private.paymentgateway.BraintreeMerchantAccount.prototype.getMerchantAccountId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.billing.private.paymentgateway.BraintreeMerchantAccount} returns this
 */
proto.billing.private.paymentgateway.BraintreeMerchantAccount.prototype.setMerchantAccountId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string currency_iso_code = 2;
 * @return {string}
 */
proto.billing.private.paymentgateway.BraintreeMerchantAccount.prototype.getCurrencyIsoCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.billing.private.paymentgateway.BraintreeMerchantAccount} returns this
 */
proto.billing.private.paymentgateway.BraintreeMerchantAccount.prototype.setCurrencyIsoCode = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.billing.private.paymentgateway.GetSpreedlyTransactionRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.billing.private.paymentgateway.GetSpreedlyTransactionRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.billing.private.paymentgateway.GetSpreedlyTransactionRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing.private.paymentgateway.GetSpreedlyTransactionRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    companyDomain: jspb.Message.getFieldWithDefault(msg, 1, ""),
    transactionToken: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.billing.private.paymentgateway.GetSpreedlyTransactionRequest}
 */
proto.billing.private.paymentgateway.GetSpreedlyTransactionRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.billing.private.paymentgateway.GetSpreedlyTransactionRequest;
  return proto.billing.private.paymentgateway.GetSpreedlyTransactionRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.billing.private.paymentgateway.GetSpreedlyTransactionRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.billing.private.paymentgateway.GetSpreedlyTransactionRequest}
 */
proto.billing.private.paymentgateway.GetSpreedlyTransactionRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setCompanyDomain(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setTransactionToken(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.billing.private.paymentgateway.GetSpreedlyTransactionRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.billing.private.paymentgateway.GetSpreedlyTransactionRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.billing.private.paymentgateway.GetSpreedlyTransactionRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing.private.paymentgateway.GetSpreedlyTransactionRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCompanyDomain();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getTransactionToken();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string company_domain = 1;
 * @return {string}
 */
proto.billing.private.paymentgateway.GetSpreedlyTransactionRequest.prototype.getCompanyDomain = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.billing.private.paymentgateway.GetSpreedlyTransactionRequest} returns this
 */
proto.billing.private.paymentgateway.GetSpreedlyTransactionRequest.prototype.setCompanyDomain = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string transaction_token = 2;
 * @return {string}
 */
proto.billing.private.paymentgateway.GetSpreedlyTransactionRequest.prototype.getTransactionToken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.billing.private.paymentgateway.GetSpreedlyTransactionRequest} returns this
 */
proto.billing.private.paymentgateway.GetSpreedlyTransactionRequest.prototype.setTransactionToken = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.billing.private.paymentgateway.GetSpreedlyTransactionResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.billing.private.paymentgateway.GetSpreedlyTransactionResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.billing.private.paymentgateway.GetSpreedlyTransactionResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing.private.paymentgateway.GetSpreedlyTransactionResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    status: jspb.Message.getFieldWithDefault(msg, 1, ""),
    transactionToken: jspb.Message.getFieldWithDefault(msg, 2, ""),
    rawSpreedlyTransaction: jspb.Message.getFieldWithDefault(msg, 3, ""),
    paymentSourceType: jspb.Message.getFieldWithDefault(msg, 4, 0),
    responseCodesAndDescription: (f = msg.getResponseCodesAndDescription()) && proto.billing.private.paymentgateway.ResponseCodesAndDescription.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.billing.private.paymentgateway.GetSpreedlyTransactionResponse}
 */
proto.billing.private.paymentgateway.GetSpreedlyTransactionResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.billing.private.paymentgateway.GetSpreedlyTransactionResponse;
  return proto.billing.private.paymentgateway.GetSpreedlyTransactionResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.billing.private.paymentgateway.GetSpreedlyTransactionResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.billing.private.paymentgateway.GetSpreedlyTransactionResponse}
 */
proto.billing.private.paymentgateway.GetSpreedlyTransactionResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setStatus(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setTransactionToken(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setRawSpreedlyTransaction(value);
      break;
    case 4:
      var value = /** @type {!proto.billing.private.paymentgateway.PaymentSourceType} */ (reader.readEnum());
      msg.setPaymentSourceType(value);
      break;
    case 5:
      var value = new proto.billing.private.paymentgateway.ResponseCodesAndDescription;
      reader.readMessage(value,proto.billing.private.paymentgateway.ResponseCodesAndDescription.deserializeBinaryFromReader);
      msg.setResponseCodesAndDescription(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.billing.private.paymentgateway.GetSpreedlyTransactionResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.billing.private.paymentgateway.GetSpreedlyTransactionResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.billing.private.paymentgateway.GetSpreedlyTransactionResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing.private.paymentgateway.GetSpreedlyTransactionResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStatus();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getTransactionToken();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getRawSpreedlyTransaction();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getPaymentSourceType();
  if (f !== 0.0) {
    writer.writeEnum(
      4,
      f
    );
  }
  f = message.getResponseCodesAndDescription();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      proto.billing.private.paymentgateway.ResponseCodesAndDescription.serializeBinaryToWriter
    );
  }
};


/**
 * optional string status = 1;
 * @return {string}
 */
proto.billing.private.paymentgateway.GetSpreedlyTransactionResponse.prototype.getStatus = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.billing.private.paymentgateway.GetSpreedlyTransactionResponse} returns this
 */
proto.billing.private.paymentgateway.GetSpreedlyTransactionResponse.prototype.setStatus = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string transaction_token = 2;
 * @return {string}
 */
proto.billing.private.paymentgateway.GetSpreedlyTransactionResponse.prototype.getTransactionToken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.billing.private.paymentgateway.GetSpreedlyTransactionResponse} returns this
 */
proto.billing.private.paymentgateway.GetSpreedlyTransactionResponse.prototype.setTransactionToken = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string raw_spreedly_transaction = 3;
 * @return {string}
 */
proto.billing.private.paymentgateway.GetSpreedlyTransactionResponse.prototype.getRawSpreedlyTransaction = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.billing.private.paymentgateway.GetSpreedlyTransactionResponse} returns this
 */
proto.billing.private.paymentgateway.GetSpreedlyTransactionResponse.prototype.setRawSpreedlyTransaction = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional PaymentSourceType payment_source_type = 4;
 * @return {!proto.billing.private.paymentgateway.PaymentSourceType}
 */
proto.billing.private.paymentgateway.GetSpreedlyTransactionResponse.prototype.getPaymentSourceType = function() {
  return /** @type {!proto.billing.private.paymentgateway.PaymentSourceType} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {!proto.billing.private.paymentgateway.PaymentSourceType} value
 * @return {!proto.billing.private.paymentgateway.GetSpreedlyTransactionResponse} returns this
 */
proto.billing.private.paymentgateway.GetSpreedlyTransactionResponse.prototype.setPaymentSourceType = function(value) {
  return jspb.Message.setProto3EnumField(this, 4, value);
};


/**
 * optional ResponseCodesAndDescription response_codes_and_description = 5;
 * @return {?proto.billing.private.paymentgateway.ResponseCodesAndDescription}
 */
proto.billing.private.paymentgateway.GetSpreedlyTransactionResponse.prototype.getResponseCodesAndDescription = function() {
  return /** @type{?proto.billing.private.paymentgateway.ResponseCodesAndDescription} */ (
    jspb.Message.getWrapperField(this, proto.billing.private.paymentgateway.ResponseCodesAndDescription, 5));
};


/**
 * @param {?proto.billing.private.paymentgateway.ResponseCodesAndDescription|undefined} value
 * @return {!proto.billing.private.paymentgateway.GetSpreedlyTransactionResponse} returns this
*/
proto.billing.private.paymentgateway.GetSpreedlyTransactionResponse.prototype.setResponseCodesAndDescription = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.billing.private.paymentgateway.GetSpreedlyTransactionResponse} returns this
 */
proto.billing.private.paymentgateway.GetSpreedlyTransactionResponse.prototype.clearResponseCodesAndDescription = function() {
  return this.setResponseCodesAndDescription(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.billing.private.paymentgateway.GetSpreedlyTransactionResponse.prototype.hasResponseCodesAndDescription = function() {
  return jspb.Message.getField(this, 5) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.billing.private.paymentgateway.ResponseCodesAndDescription.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.billing.private.paymentgateway.ResponseCodesAndDescription.prototype.toObject = function(opt_includeInstance) {
  return proto.billing.private.paymentgateway.ResponseCodesAndDescription.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.billing.private.paymentgateway.ResponseCodesAndDescription} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing.private.paymentgateway.ResponseCodesAndDescription.toObject = function(includeInstance, msg) {
  var f, obj = {
    gatewayType: jspb.Message.getFieldWithDefault(msg, 1, ""),
    errorCodesAndDescriptionList: jspb.Message.toObjectList(msg.getErrorCodesAndDescriptionList(),
    proto.billing.private.paymentgateway.ErrorCodeAndDescription.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.billing.private.paymentgateway.ResponseCodesAndDescription}
 */
proto.billing.private.paymentgateway.ResponseCodesAndDescription.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.billing.private.paymentgateway.ResponseCodesAndDescription;
  return proto.billing.private.paymentgateway.ResponseCodesAndDescription.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.billing.private.paymentgateway.ResponseCodesAndDescription} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.billing.private.paymentgateway.ResponseCodesAndDescription}
 */
proto.billing.private.paymentgateway.ResponseCodesAndDescription.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setGatewayType(value);
      break;
    case 2:
      var value = new proto.billing.private.paymentgateway.ErrorCodeAndDescription;
      reader.readMessage(value,proto.billing.private.paymentgateway.ErrorCodeAndDescription.deserializeBinaryFromReader);
      msg.addErrorCodesAndDescription(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.billing.private.paymentgateway.ResponseCodesAndDescription.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.billing.private.paymentgateway.ResponseCodesAndDescription.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.billing.private.paymentgateway.ResponseCodesAndDescription} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing.private.paymentgateway.ResponseCodesAndDescription.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getGatewayType();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getErrorCodesAndDescriptionList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.billing.private.paymentgateway.ErrorCodeAndDescription.serializeBinaryToWriter
    );
  }
};


/**
 * optional string gateway_type = 1;
 * @return {string}
 */
proto.billing.private.paymentgateway.ResponseCodesAndDescription.prototype.getGatewayType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.billing.private.paymentgateway.ResponseCodesAndDescription} returns this
 */
proto.billing.private.paymentgateway.ResponseCodesAndDescription.prototype.setGatewayType = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * repeated ErrorCodeAndDescription error_codes_and_description = 2;
 * @return {!Array<!proto.billing.private.paymentgateway.ErrorCodeAndDescription>}
 */
proto.billing.private.paymentgateway.ResponseCodesAndDescription.prototype.getErrorCodesAndDescriptionList = function() {
  return /** @type{!Array<!proto.billing.private.paymentgateway.ErrorCodeAndDescription>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.billing.private.paymentgateway.ErrorCodeAndDescription, 2));
};


/**
 * @param {!Array<!proto.billing.private.paymentgateway.ErrorCodeAndDescription>} value
 * @return {!proto.billing.private.paymentgateway.ResponseCodesAndDescription} returns this
*/
proto.billing.private.paymentgateway.ResponseCodesAndDescription.prototype.setErrorCodesAndDescriptionList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.billing.private.paymentgateway.ErrorCodeAndDescription=} opt_value
 * @param {number=} opt_index
 * @return {!proto.billing.private.paymentgateway.ErrorCodeAndDescription}
 */
proto.billing.private.paymentgateway.ResponseCodesAndDescription.prototype.addErrorCodesAndDescription = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.billing.private.paymentgateway.ErrorCodeAndDescription, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.billing.private.paymentgateway.ResponseCodesAndDescription} returns this
 */
proto.billing.private.paymentgateway.ResponseCodesAndDescription.prototype.clearErrorCodesAndDescriptionList = function() {
  return this.setErrorCodesAndDescriptionList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.billing.private.paymentgateway.ErrorCodeAndDescription.prototype.toObject = function(opt_includeInstance) {
  return proto.billing.private.paymentgateway.ErrorCodeAndDescription.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.billing.private.paymentgateway.ErrorCodeAndDescription} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing.private.paymentgateway.ErrorCodeAndDescription.toObject = function(includeInstance, msg) {
  var f, obj = {
    index: jspb.Message.getFieldWithDefault(msg, 1, 0),
    code: jspb.Message.getFieldWithDefault(msg, 2, ""),
    description: jspb.Message.getFieldWithDefault(msg, 3, ""),
    text: jspb.Message.getFieldWithDefault(msg, 4, ""),
    integrationSuggestions: jspb.Message.getFieldWithDefault(msg, 5, ""),
    otherSuggestions: jspb.Message.getFieldWithDefault(msg, 6, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.billing.private.paymentgateway.ErrorCodeAndDescription}
 */
proto.billing.private.paymentgateway.ErrorCodeAndDescription.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.billing.private.paymentgateway.ErrorCodeAndDescription;
  return proto.billing.private.paymentgateway.ErrorCodeAndDescription.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.billing.private.paymentgateway.ErrorCodeAndDescription} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.billing.private.paymentgateway.ErrorCodeAndDescription}
 */
proto.billing.private.paymentgateway.ErrorCodeAndDescription.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setIndex(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setCode(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setText(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setIntegrationSuggestions(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setOtherSuggestions(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.billing.private.paymentgateway.ErrorCodeAndDescription.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.billing.private.paymentgateway.ErrorCodeAndDescription.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.billing.private.paymentgateway.ErrorCodeAndDescription} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing.private.paymentgateway.ErrorCodeAndDescription.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getIndex();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getCode();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getDescription();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getText();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getIntegrationSuggestions();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getOtherSuggestions();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
};


/**
 * optional int32 index = 1;
 * @return {number}
 */
proto.billing.private.paymentgateway.ErrorCodeAndDescription.prototype.getIndex = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.billing.private.paymentgateway.ErrorCodeAndDescription} returns this
 */
proto.billing.private.paymentgateway.ErrorCodeAndDescription.prototype.setIndex = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string code = 2;
 * @return {string}
 */
proto.billing.private.paymentgateway.ErrorCodeAndDescription.prototype.getCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.billing.private.paymentgateway.ErrorCodeAndDescription} returns this
 */
proto.billing.private.paymentgateway.ErrorCodeAndDescription.prototype.setCode = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string description = 3;
 * @return {string}
 */
proto.billing.private.paymentgateway.ErrorCodeAndDescription.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.billing.private.paymentgateway.ErrorCodeAndDescription} returns this
 */
proto.billing.private.paymentgateway.ErrorCodeAndDescription.prototype.setDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string text = 4;
 * @return {string}
 */
proto.billing.private.paymentgateway.ErrorCodeAndDescription.prototype.getText = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.billing.private.paymentgateway.ErrorCodeAndDescription} returns this
 */
proto.billing.private.paymentgateway.ErrorCodeAndDescription.prototype.setText = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string integration_suggestions = 5;
 * @return {string}
 */
proto.billing.private.paymentgateway.ErrorCodeAndDescription.prototype.getIntegrationSuggestions = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.billing.private.paymentgateway.ErrorCodeAndDescription} returns this
 */
proto.billing.private.paymentgateway.ErrorCodeAndDescription.prototype.setIntegrationSuggestions = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string other_suggestions = 6;
 * @return {string}
 */
proto.billing.private.paymentgateway.ErrorCodeAndDescription.prototype.getOtherSuggestions = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.billing.private.paymentgateway.ErrorCodeAndDescription} returns this
 */
proto.billing.private.paymentgateway.ErrorCodeAndDescription.prototype.setOtherSuggestions = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.billing.private.paymentgateway.GetTransactionLogRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.billing.private.paymentgateway.GetTransactionLogRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.billing.private.paymentgateway.GetTransactionLogRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing.private.paymentgateway.GetTransactionLogRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    companyDomain: jspb.Message.getFieldWithDefault(msg, 1, ""),
    transactionToken: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.billing.private.paymentgateway.GetTransactionLogRequest}
 */
proto.billing.private.paymentgateway.GetTransactionLogRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.billing.private.paymentgateway.GetTransactionLogRequest;
  return proto.billing.private.paymentgateway.GetTransactionLogRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.billing.private.paymentgateway.GetTransactionLogRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.billing.private.paymentgateway.GetTransactionLogRequest}
 */
proto.billing.private.paymentgateway.GetTransactionLogRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setCompanyDomain(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setTransactionToken(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.billing.private.paymentgateway.GetTransactionLogRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.billing.private.paymentgateway.GetTransactionLogRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.billing.private.paymentgateway.GetTransactionLogRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing.private.paymentgateway.GetTransactionLogRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCompanyDomain();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getTransactionToken();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string company_domain = 1;
 * @return {string}
 */
proto.billing.private.paymentgateway.GetTransactionLogRequest.prototype.getCompanyDomain = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.billing.private.paymentgateway.GetTransactionLogRequest} returns this
 */
proto.billing.private.paymentgateway.GetTransactionLogRequest.prototype.setCompanyDomain = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string transaction_token = 2;
 * @return {string}
 */
proto.billing.private.paymentgateway.GetTransactionLogRequest.prototype.getTransactionToken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.billing.private.paymentgateway.GetTransactionLogRequest} returns this
 */
proto.billing.private.paymentgateway.GetTransactionLogRequest.prototype.setTransactionToken = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.billing.private.paymentgateway.GetTransactionLogResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.billing.private.paymentgateway.GetTransactionLogResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.billing.private.paymentgateway.GetTransactionLogResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing.private.paymentgateway.GetTransactionLogResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    status: jspb.Message.getFieldWithDefault(msg, 1, ""),
    reference: jspb.Message.getFieldWithDefault(msg, 2, ""),
    rawJson: jspb.Message.getFieldWithDefault(msg, 3, ""),
    paymentSourceType: jspb.Message.getFieldWithDefault(msg, 4, 0),
    errorModel: (f = msg.getErrorModel()) && proto.billing.private.paymentgateway.PaymentLogErrorViewModel.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.billing.private.paymentgateway.GetTransactionLogResponse}
 */
proto.billing.private.paymentgateway.GetTransactionLogResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.billing.private.paymentgateway.GetTransactionLogResponse;
  return proto.billing.private.paymentgateway.GetTransactionLogResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.billing.private.paymentgateway.GetTransactionLogResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.billing.private.paymentgateway.GetTransactionLogResponse}
 */
proto.billing.private.paymentgateway.GetTransactionLogResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setStatus(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setReference(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setRawJson(value);
      break;
    case 4:
      var value = /** @type {!proto.billing.private.paymentgateway.PaymentSourceType} */ (reader.readEnum());
      msg.setPaymentSourceType(value);
      break;
    case 5:
      var value = new proto.billing.private.paymentgateway.PaymentLogErrorViewModel;
      reader.readMessage(value,proto.billing.private.paymentgateway.PaymentLogErrorViewModel.deserializeBinaryFromReader);
      msg.setErrorModel(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.billing.private.paymentgateway.GetTransactionLogResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.billing.private.paymentgateway.GetTransactionLogResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.billing.private.paymentgateway.GetTransactionLogResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing.private.paymentgateway.GetTransactionLogResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStatus();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getReference();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getRawJson();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getPaymentSourceType();
  if (f !== 0.0) {
    writer.writeEnum(
      4,
      f
    );
  }
  f = message.getErrorModel();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      proto.billing.private.paymentgateway.PaymentLogErrorViewModel.serializeBinaryToWriter
    );
  }
};


/**
 * optional string status = 1;
 * @return {string}
 */
proto.billing.private.paymentgateway.GetTransactionLogResponse.prototype.getStatus = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.billing.private.paymentgateway.GetTransactionLogResponse} returns this
 */
proto.billing.private.paymentgateway.GetTransactionLogResponse.prototype.setStatus = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string reference = 2;
 * @return {string}
 */
proto.billing.private.paymentgateway.GetTransactionLogResponse.prototype.getReference = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.billing.private.paymentgateway.GetTransactionLogResponse} returns this
 */
proto.billing.private.paymentgateway.GetTransactionLogResponse.prototype.setReference = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string raw_json = 3;
 * @return {string}
 */
proto.billing.private.paymentgateway.GetTransactionLogResponse.prototype.getRawJson = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.billing.private.paymentgateway.GetTransactionLogResponse} returns this
 */
proto.billing.private.paymentgateway.GetTransactionLogResponse.prototype.setRawJson = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional PaymentSourceType payment_source_type = 4;
 * @return {!proto.billing.private.paymentgateway.PaymentSourceType}
 */
proto.billing.private.paymentgateway.GetTransactionLogResponse.prototype.getPaymentSourceType = function() {
  return /** @type {!proto.billing.private.paymentgateway.PaymentSourceType} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {!proto.billing.private.paymentgateway.PaymentSourceType} value
 * @return {!proto.billing.private.paymentgateway.GetTransactionLogResponse} returns this
 */
proto.billing.private.paymentgateway.GetTransactionLogResponse.prototype.setPaymentSourceType = function(value) {
  return jspb.Message.setProto3EnumField(this, 4, value);
};


/**
 * optional PaymentLogErrorViewModel error_model = 5;
 * @return {?proto.billing.private.paymentgateway.PaymentLogErrorViewModel}
 */
proto.billing.private.paymentgateway.GetTransactionLogResponse.prototype.getErrorModel = function() {
  return /** @type{?proto.billing.private.paymentgateway.PaymentLogErrorViewModel} */ (
    jspb.Message.getWrapperField(this, proto.billing.private.paymentgateway.PaymentLogErrorViewModel, 5));
};


/**
 * @param {?proto.billing.private.paymentgateway.PaymentLogErrorViewModel|undefined} value
 * @return {!proto.billing.private.paymentgateway.GetTransactionLogResponse} returns this
*/
proto.billing.private.paymentgateway.GetTransactionLogResponse.prototype.setErrorModel = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.billing.private.paymentgateway.GetTransactionLogResponse} returns this
 */
proto.billing.private.paymentgateway.GetTransactionLogResponse.prototype.clearErrorModel = function() {
  return this.setErrorModel(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.billing.private.paymentgateway.GetTransactionLogResponse.prototype.hasErrorModel = function() {
  return jspb.Message.getField(this, 5) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.billing.private.paymentgateway.PaymentLogErrorViewModel.prototype.toObject = function(opt_includeInstance) {
  return proto.billing.private.paymentgateway.PaymentLogErrorViewModel.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.billing.private.paymentgateway.PaymentLogErrorViewModel} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing.private.paymentgateway.PaymentLogErrorViewModel.toObject = function(includeInstance, msg) {
  var f, obj = {
    errorCode: jspb.Message.getFieldWithDefault(msg, 1, ""),
    message: jspb.Message.getFieldWithDefault(msg, 2, ""),
    declineCode: jspb.Message.getFieldWithDefault(msg, 3, ""),
    errorDescription: jspb.Message.getFieldWithDefault(msg, 4, ""),
    type: jspb.Message.getFieldWithDefault(msg, 5, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.billing.private.paymentgateway.PaymentLogErrorViewModel}
 */
proto.billing.private.paymentgateway.PaymentLogErrorViewModel.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.billing.private.paymentgateway.PaymentLogErrorViewModel;
  return proto.billing.private.paymentgateway.PaymentLogErrorViewModel.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.billing.private.paymentgateway.PaymentLogErrorViewModel} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.billing.private.paymentgateway.PaymentLogErrorViewModel}
 */
proto.billing.private.paymentgateway.PaymentLogErrorViewModel.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setErrorCode(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setMessage(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setDeclineCode(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setErrorDescription(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setType(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.billing.private.paymentgateway.PaymentLogErrorViewModel.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.billing.private.paymentgateway.PaymentLogErrorViewModel.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.billing.private.paymentgateway.PaymentLogErrorViewModel} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing.private.paymentgateway.PaymentLogErrorViewModel.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getErrorCode();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getMessage();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getDeclineCode();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getErrorDescription();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getType();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
};


/**
 * optional string error_code = 1;
 * @return {string}
 */
proto.billing.private.paymentgateway.PaymentLogErrorViewModel.prototype.getErrorCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.billing.private.paymentgateway.PaymentLogErrorViewModel} returns this
 */
proto.billing.private.paymentgateway.PaymentLogErrorViewModel.prototype.setErrorCode = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string message = 2;
 * @return {string}
 */
proto.billing.private.paymentgateway.PaymentLogErrorViewModel.prototype.getMessage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.billing.private.paymentgateway.PaymentLogErrorViewModel} returns this
 */
proto.billing.private.paymentgateway.PaymentLogErrorViewModel.prototype.setMessage = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string decline_code = 3;
 * @return {string}
 */
proto.billing.private.paymentgateway.PaymentLogErrorViewModel.prototype.getDeclineCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.billing.private.paymentgateway.PaymentLogErrorViewModel} returns this
 */
proto.billing.private.paymentgateway.PaymentLogErrorViewModel.prototype.setDeclineCode = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string error_description = 4;
 * @return {string}
 */
proto.billing.private.paymentgateway.PaymentLogErrorViewModel.prototype.getErrorDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.billing.private.paymentgateway.PaymentLogErrorViewModel} returns this
 */
proto.billing.private.paymentgateway.PaymentLogErrorViewModel.prototype.setErrorDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string type = 5;
 * @return {string}
 */
proto.billing.private.paymentgateway.PaymentLogErrorViewModel.prototype.getType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.billing.private.paymentgateway.PaymentLogErrorViewModel} returns this
 */
proto.billing.private.paymentgateway.PaymentLogErrorViewModel.prototype.setType = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * @enum {number}
 */
proto.billing.private.paymentgateway.PaymentSourceType = {
  NONE: 0,
  CREDIT_CARD: 1,
  ACH: 2
};

goog.object.extend(exports, proto.billing.private.paymentgateway);
