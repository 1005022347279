import React from "react";
import "./ButtonLoader.scss";

interface IButtonLoader {
  color?: "white" | "blue"
}

const ButtonLoader: React.FC<IButtonLoader> = React.memo(({ color = "white"  }) => {
  return (
    <div className={`spinner spinner--${color}`}>
      <div className="bounce1"></div>
      <div className="bounce2"></div>
      <div className="bounce3"></div>
    </div>
  );
})

export default ButtonLoader;
