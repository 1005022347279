import React from "react";
import "./Textarea.scss";

interface ITextArea {
  [key: string]: any,
  placeholder?: string,
  rows?: number,
  cols?: number,
  maxlength?: number,
  required?: boolean,
  value: string,
  onChange?: (evt: any) => any,
  onBlur?:(evt: any) => any,
  name?: string,
  id: string,
  className?: string
}

const Textarea: React.FC<ITextArea> = React.memo(({ placeholder = "", rows = 10, cols = 20, maxlength = 1000, required = false, value, onChange = () => { },
onBlur = () => { }, name = "", id, className = "", ...props }) => {

  return (
    <textarea
      placeholder={placeholder}
      rows={rows}
      cols={cols}
      maxLength={maxlength}
      required={required}
      className={`textarea ${className}`}
      value={value}
      onBlur={onBlur}
      onChange={onChange}
      name={name}
      id={id}
      {...props}
    />
  );
})


export default Textarea;
