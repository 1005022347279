import { makeActionCreator } from "../utils/reduxUtils";
import API from "../utils/API";
import { IUser } from "../models/User";
import { RegisterInvitedUser } from "../models/Invitations";
import { grpcUnaryCall } from "../utils/grpc/grpcUtils";
import { CompanyServiceClient } from "../utils/grpc/generated/Billsby.Protos/core/public/company/CompanyServiceClientPb";

import { IsCompanyDomainUsedRequest } from "../utils/grpc/generated/Billsby.Protos/core/public/company/company_pb";
import { ConfigConstants } from "../utils/config";
/**********************  SYNCHRONOUS ACTIONS ********************/
export const ADD_FIRSTNAME = "ADD_FIRSTNAME";
export const addFirstName = makeActionCreator(ADD_FIRSTNAME, "payload");
export const ADD_LASTNAME = "ADD_LASTNAME";
export const addLastName = makeActionCreator(ADD_LASTNAME, "payload");
export const ADD_WORKEMAIL = "ADD_WORKEMAIL";
export const addWorkEmail = makeActionCreator(ADD_WORKEMAIL, "payload");
export const ADD_PHONE_NUMBER_PREFIX = "ADD_PHONE_NUMBER_PREFIX";
export const addPhoneNumberPrefix = makeActionCreator(ADD_PHONE_NUMBER_PREFIX, "payload");
export const ADD_PHONE_NUMBER = "ADD_PHONE_NUMBER";
export const addPhoneNumber = makeActionCreator(ADD_PHONE_NUMBER, "payload");
export const ADD_PASSWORD = "ADD_PASSWORD";
export const addPassword = makeActionCreator(ADD_PASSWORD, "payload");
export const ADD_PASSWORD_CONFIRM = "ADD_PASSWORD_CONFIRM";
export const addPasswordConfirm = makeActionCreator(ADD_PASSWORD_CONFIRM, "payload");
export const SET_NOTIFICATION_ALERT = "SET_NOTIFICATION_ALERT";
export const setNotificationAlert = makeActionCreator(SET_NOTIFICATION_ALERT, "payload");
export const SET_ACCEPT_AGREEMENT = "SET_ACCEPT_AGREEMENT";
export const setAcceptAgreement = makeActionCreator(SET_ACCEPT_AGREEMENT, "payload");
export const ADD_COMPANY_NAME = "ADD_COMPANY_NAME";
export const addCompanyName = makeActionCreator(ADD_COMPANY_NAME, "payload");
export const ADD_COUNTRY = "ADD_COUNTRY";
export const addCountry = makeActionCreator(ADD_COUNTRY, "payload");
export const ADD_BILLING_CURRENCY = "ADD_BILLING_CURRENCY";
export const addBillingCurrency = makeActionCreator(ADD_BILLING_CURRENCY, "payload");
export const ADD_COMPANY_DOMAIN = "ADD_COMPANY_DOMAIN";
export const addCompanyDomain = makeActionCreator(ADD_COMPANY_DOMAIN, "payload");
export const ADD_PHONE_NUMBER_COUNTRY_ISO2 = "ADD_PHONE_NUMBER_COUNTRY_ISO2";
export const addPhoneNumberCountryIso2 = makeActionCreator(ADD_PHONE_NUMBER_COUNTRY_ISO2, "payload");
export const CHECK_PASSWORD_VALIDITY = "CHECK_PASSWORD_VALIDITY";
export const checkPasswordValidity = makeActionCreator(CHECK_PASSWORD_VALIDITY, "payload");
export const ADD_INVITE_CODE = "ADD_INVITE_CODE";
export const addInviteCode = makeActionCreator(ADD_INVITE_CODE, "payload");
export const ADD_FREE_TRIAL_TOKEN = "ADD_FREE_TRIAL_TOKEN";
export const addFreeTrialToken = makeActionCreator(ADD_FREE_TRIAL_TOKEN, "payload");
export const RESET_REGISTRATION_STATE = "RESET_REGISTRATION_STATE";
export const resetRegistrationState = makeActionCreator(RESET_REGISTRATION_STATE);

export const VALIDATE_EMAIL_REQUEST = "VALIDATE_EMAIL_REQUEST";
export const VALIDATE_EMAIL_SUCCESS = "VALIDATE_EMAIL_SUCCESS";
export const VALIDATE_EMAIL_FAILURE = "VALIDATE_EMAIL_FAILURE";

export const REGISTER_USER_REQUEST = "REGISTER_USER_REQUEST";
export const REGISTER_USER_SUCCESS = "REGISTER_USER_SUCCESS";
export const REGISTER_USER_FAILURE = "REGISTER_USER_FAILURE";

export const REGISTER_USER_FROM_INVITATION_REQUEST = "REGISTER_USER_FROM_INVITATION_REQUEST";
export const REGISTER_USER_FROM_INVITATION_SUCCESS = "REGISTER_USER_FROM_INVITATION_SUCCESS";
export const REGISTER_USER_FROM_INVITATION_FAILURE = "REGISTER_USER_FROM_INVITATION_FAILURE";

export const FETCH_DETAILS_INVITED_USER_REQUEST = "FETCH_DETAILS_INVITED_USER_REQUEST";
export const FETCH_DETAILS_INVITED_USER_SUCCESS = "FETCH_DETAILS_INVITED_USER_SUCCESS";
export const FETCH_DETAILS_INVITED_USER_FAILURE = "FETCH_DETAILS_INVITED_USER_FAILURE";

export const FETCH_CURRENCIES_REQUEST = "FETCH_CURRENCIES_REQUEST";
export const FETCH_CURRENCIES_SUCCESS = "FETCH_CURRENCIES_SUCCESS";
export const FETCH_CURRENCIES_FAILURE = "FETCH_CURRENCIES_FAILURE";

export const FETCH_COUNTRIES_REQUEST = "FETCH_COUNTRIES_REQUEST";
export const FETCH_COUNTRIES_SUCCESS = "FETCH_COUNTRIES_SUCCESS";
export const FETCH_COUNTRIES_FAILURE = "FETCH_COUNTRIES_FAILURE";

export const IS_COMPANYDOMAIN_USED_REQUEST = "IS_COMPANYDOMAIN_USED_REQUEST";
export const IS_COMPANYDOMAIN_USED_SUCCESS = "IS_COMPANYDOMAIN_USED_SUCCESS";
export const IS_COMPANYDOMAIN_USED_FAILURE = "IS_COMPANYDOMAIN_USED_FAILURE";

export const SET_IS_DOING_QUIZ = "SET_IS_DOING_QUIZ";
export const setIsDoingQuiz = makeActionCreator(SET_IS_DOING_QUIZ, "payload");

export const SET_QUIZ_FINISHED = "SET_QUIZ_FINISHED";
export const setQuizFinished = makeActionCreator(SET_QUIZ_FINISHED);

/**********************  ASYNCHRONOUS ACTIONS (api requests) ********************/
export const validateEmail = function (email: string) {
  return {
    types: [VALIDATE_EMAIL_REQUEST, VALIDATE_EMAIL_SUCCESS, VALIDATE_EMAIL_FAILURE],
    callAPI: () => API.validateEmail(email)
  }
}

export const registerUser = function (user: IUser) {
  return {
    types: [REGISTER_USER_REQUEST, REGISTER_USER_SUCCESS, REGISTER_USER_FAILURE],
    callAPI: () => API.registerUser(user)
  }
}

export const registerUserFromInvitation = function (user: RegisterInvitedUser) {
  return {
    types: [REGISTER_USER_FROM_INVITATION_REQUEST, REGISTER_USER_FROM_INVITATION_SUCCESS, REGISTER_USER_FROM_INVITATION_FAILURE],
    callAPI: () => API.registerUserFromInvitation(user)
  }
}

export const fetchDetailsInvitedUser = function (data: { companyDomain: string, email: string, invitationCode: string }) {
  return {
    types: [FETCH_DETAILS_INVITED_USER_REQUEST, FETCH_DETAILS_INVITED_USER_SUCCESS, FETCH_DETAILS_INVITED_USER_FAILURE],
    callAPI: () => API.fetchDetailsInvitedUser(data)
  }
}

export const getCurrencies = function () {
  return {
    types: [FETCH_CURRENCIES_REQUEST, FETCH_CURRENCIES_SUCCESS, FETCH_CURRENCIES_FAILURE],
    callAPI: () => API.getCurrencies()
  }
}

export const getCountries = function () {
  return {
    types: [FETCH_COUNTRIES_REQUEST, FETCH_COUNTRIES_SUCCESS, FETCH_COUNTRIES_FAILURE],
    callAPI: () => API.getCountries()
  }
}

export const getIsCompanyDomainUsed = function (requestedCompanyDomain: string) {
  const isCompanyDomainUsedRequest = new IsCompanyDomainUsedRequest();
  isCompanyDomainUsedRequest.setCompanyDomain(requestedCompanyDomain);
  const companyServiceClient = new CompanyServiceClient(ConfigConstants.grpcBaseUrl);

  return {
    types: [IS_COMPANYDOMAIN_USED_REQUEST, IS_COMPANYDOMAIN_USED_SUCCESS, IS_COMPANYDOMAIN_USED_FAILURE],
    callAPI: () => grpcUnaryCall(isCompanyDomainUsedRequest, companyServiceClient, companyServiceClient.isCompanyDomainUsed)
  }
}