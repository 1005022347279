/**
 * @fileoverview gRPC-Web generated client stub for core.private.companies
 * @enhanceable
 * @public
 */

// GENERATED CODE -- DO NOT EDIT!


/* eslint-disable */
// @ts-nocheck


import * as grpcWeb from 'grpc-web';

import * as company_pb from './company_pb';


export class CompanyServiceClient {
  client_: grpcWeb.AbstractClientBase;
  hostname_: string;
  credentials_: null | { [index: string]: string; };
  options_: null | { [index: string]: any; };

  constructor (hostname: string,
               credentials?: null | { [index: string]: string; },
               options?: null | { [index: string]: any; }) {
    if (!options) options = {};
    if (!credentials) credentials = {};
    options['format'] = 'text';

    this.client_ = new grpcWeb.GrpcWebClientBase(options);
    this.hostname_ = hostname;
    this.credentials_ = credentials;
    this.options_ = options;
  }

  methodDescriptorDeleteCompany = new grpcWeb.MethodDescriptor(
    '/core.private.companies.CompanyService/DeleteCompany',
    grpcWeb.MethodType.UNARY,
    company_pb.DeleteCompanyRequest,
    company_pb.DeleteCompanyResponse,
    (request: company_pb.DeleteCompanyRequest) => {
      return request.serializeBinary();
    },
    company_pb.DeleteCompanyResponse.deserializeBinary
  );

  deleteCompany(
    request: company_pb.DeleteCompanyRequest,
    metadata: grpcWeb.Metadata | null): Promise<company_pb.DeleteCompanyResponse>;

  deleteCompany(
    request: company_pb.DeleteCompanyRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: company_pb.DeleteCompanyResponse) => void): grpcWeb.ClientReadableStream<company_pb.DeleteCompanyResponse>;

  deleteCompany(
    request: company_pb.DeleteCompanyRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: company_pb.DeleteCompanyResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/core.private.companies.CompanyService/DeleteCompany',
        request,
        metadata || {},
        this.methodDescriptorDeleteCompany,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/core.private.companies.CompanyService/DeleteCompany',
    request,
    metadata || {},
    this.methodDescriptorDeleteCompany);
  }

  methodDescriptorRequestDeleteCompany = new grpcWeb.MethodDescriptor(
    '/core.private.companies.CompanyService/RequestDeleteCompany',
    grpcWeb.MethodType.UNARY,
    company_pb.RequestDeleteCompanyRequest,
    company_pb.RequestDeleteCompanyResponse,
    (request: company_pb.RequestDeleteCompanyRequest) => {
      return request.serializeBinary();
    },
    company_pb.RequestDeleteCompanyResponse.deserializeBinary
  );

  requestDeleteCompany(
    request: company_pb.RequestDeleteCompanyRequest,
    metadata: grpcWeb.Metadata | null): Promise<company_pb.RequestDeleteCompanyResponse>;

  requestDeleteCompany(
    request: company_pb.RequestDeleteCompanyRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: company_pb.RequestDeleteCompanyResponse) => void): grpcWeb.ClientReadableStream<company_pb.RequestDeleteCompanyResponse>;

  requestDeleteCompany(
    request: company_pb.RequestDeleteCompanyRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: company_pb.RequestDeleteCompanyResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/core.private.companies.CompanyService/RequestDeleteCompany',
        request,
        metadata || {},
        this.methodDescriptorRequestDeleteCompany,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/core.private.companies.CompanyService/RequestDeleteCompany',
    request,
    metadata || {},
    this.methodDescriptorRequestDeleteCompany);
  }

  methodDescriptorCancelDeleteCompanyRequest = new grpcWeb.MethodDescriptor(
    '/core.private.companies.CompanyService/CancelDeleteCompanyRequest',
    grpcWeb.MethodType.UNARY,
    company_pb.CancelDeleteCompanyRequestRequest,
    company_pb.CancelDeleteCompanyRequestResponse,
    (request: company_pb.CancelDeleteCompanyRequestRequest) => {
      return request.serializeBinary();
    },
    company_pb.CancelDeleteCompanyRequestResponse.deserializeBinary
  );

  cancelDeleteCompanyRequest(
    request: company_pb.CancelDeleteCompanyRequestRequest,
    metadata: grpcWeb.Metadata | null): Promise<company_pb.CancelDeleteCompanyRequestResponse>;

  cancelDeleteCompanyRequest(
    request: company_pb.CancelDeleteCompanyRequestRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: company_pb.CancelDeleteCompanyRequestResponse) => void): grpcWeb.ClientReadableStream<company_pb.CancelDeleteCompanyRequestResponse>;

  cancelDeleteCompanyRequest(
    request: company_pb.CancelDeleteCompanyRequestRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: company_pb.CancelDeleteCompanyRequestResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/core.private.companies.CompanyService/CancelDeleteCompanyRequest',
        request,
        metadata || {},
        this.methodDescriptorCancelDeleteCompanyRequest,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/core.private.companies.CompanyService/CancelDeleteCompanyRequest',
    request,
    metadata || {},
    this.methodDescriptorCancelDeleteCompanyRequest);
  }

  methodDescriptorUpdateCompanyDomain = new grpcWeb.MethodDescriptor(
    '/core.private.companies.CompanyService/UpdateCompanyDomain',
    grpcWeb.MethodType.UNARY,
    company_pb.UpdateCompanyDomainRequest,
    company_pb.UpdateCompanyDomainResponse,
    (request: company_pb.UpdateCompanyDomainRequest) => {
      return request.serializeBinary();
    },
    company_pb.UpdateCompanyDomainResponse.deserializeBinary
  );

  updateCompanyDomain(
    request: company_pb.UpdateCompanyDomainRequest,
    metadata: grpcWeb.Metadata | null): Promise<company_pb.UpdateCompanyDomainResponse>;

  updateCompanyDomain(
    request: company_pb.UpdateCompanyDomainRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: company_pb.UpdateCompanyDomainResponse) => void): grpcWeb.ClientReadableStream<company_pb.UpdateCompanyDomainResponse>;

  updateCompanyDomain(
    request: company_pb.UpdateCompanyDomainRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: company_pb.UpdateCompanyDomainResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/core.private.companies.CompanyService/UpdateCompanyDomain',
        request,
        metadata || {},
        this.methodDescriptorUpdateCompanyDomain,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/core.private.companies.CompanyService/UpdateCompanyDomain',
    request,
    metadata || {},
    this.methodDescriptorUpdateCompanyDomain);
  }

  methodDescriptorUpdateTrialLimit = new grpcWeb.MethodDescriptor(
    '/core.private.companies.CompanyService/UpdateTrialLimit',
    grpcWeb.MethodType.UNARY,
    company_pb.UpdateTrialLimitRequest,
    company_pb.UpdateTrialLimitResponse,
    (request: company_pb.UpdateTrialLimitRequest) => {
      return request.serializeBinary();
    },
    company_pb.UpdateTrialLimitResponse.deserializeBinary
  );

  updateTrialLimit(
    request: company_pb.UpdateTrialLimitRequest,
    metadata: grpcWeb.Metadata | null): Promise<company_pb.UpdateTrialLimitResponse>;

  updateTrialLimit(
    request: company_pb.UpdateTrialLimitRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: company_pb.UpdateTrialLimitResponse) => void): grpcWeb.ClientReadableStream<company_pb.UpdateTrialLimitResponse>;

  updateTrialLimit(
    request: company_pb.UpdateTrialLimitRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: company_pb.UpdateTrialLimitResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/core.private.companies.CompanyService/UpdateTrialLimit',
        request,
        metadata || {},
        this.methodDescriptorUpdateTrialLimit,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/core.private.companies.CompanyService/UpdateTrialLimit',
    request,
    metadata || {},
    this.methodDescriptorUpdateTrialLimit);
  }

  methodDescriptorGetTrialLimit = new grpcWeb.MethodDescriptor(
    '/core.private.companies.CompanyService/GetTrialLimit',
    grpcWeb.MethodType.UNARY,
    company_pb.GetTrialLimitRequest,
    company_pb.GetTrialLimitResponse,
    (request: company_pb.GetTrialLimitRequest) => {
      return request.serializeBinary();
    },
    company_pb.GetTrialLimitResponse.deserializeBinary
  );

  getTrialLimit(
    request: company_pb.GetTrialLimitRequest,
    metadata: grpcWeb.Metadata | null): Promise<company_pb.GetTrialLimitResponse>;

  getTrialLimit(
    request: company_pb.GetTrialLimitRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: company_pb.GetTrialLimitResponse) => void): grpcWeb.ClientReadableStream<company_pb.GetTrialLimitResponse>;

  getTrialLimit(
    request: company_pb.GetTrialLimitRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: company_pb.GetTrialLimitResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/core.private.companies.CompanyService/GetTrialLimit',
        request,
        metadata || {},
        this.methodDescriptorGetTrialLimit,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/core.private.companies.CompanyService/GetTrialLimit',
    request,
    metadata || {},
    this.methodDescriptorGetTrialLimit);
  }

  methodDescriptorGetCompanyLanguages = new grpcWeb.MethodDescriptor(
    '/core.private.companies.CompanyService/GetCompanyLanguages',
    grpcWeb.MethodType.UNARY,
    company_pb.GetCompanyLanguagesRequest,
    company_pb.GetCompanyLanguagesResponse,
    (request: company_pb.GetCompanyLanguagesRequest) => {
      return request.serializeBinary();
    },
    company_pb.GetCompanyLanguagesResponse.deserializeBinary
  );

  getCompanyLanguages(
    request: company_pb.GetCompanyLanguagesRequest,
    metadata: grpcWeb.Metadata | null): Promise<company_pb.GetCompanyLanguagesResponse>;

  getCompanyLanguages(
    request: company_pb.GetCompanyLanguagesRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: company_pb.GetCompanyLanguagesResponse) => void): grpcWeb.ClientReadableStream<company_pb.GetCompanyLanguagesResponse>;

  getCompanyLanguages(
    request: company_pb.GetCompanyLanguagesRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: company_pb.GetCompanyLanguagesResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/core.private.companies.CompanyService/GetCompanyLanguages',
        request,
        metadata || {},
        this.methodDescriptorGetCompanyLanguages,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/core.private.companies.CompanyService/GetCompanyLanguages',
    request,
    metadata || {},
    this.methodDescriptorGetCompanyLanguages);
  }

  methodDescriptorUpdateCompanyLanguages = new grpcWeb.MethodDescriptor(
    '/core.private.companies.CompanyService/UpdateCompanyLanguages',
    grpcWeb.MethodType.UNARY,
    company_pb.UpdateCompanyLanguagesRequest,
    company_pb.UpdateCompanyLanguagesResponse,
    (request: company_pb.UpdateCompanyLanguagesRequest) => {
      return request.serializeBinary();
    },
    company_pb.UpdateCompanyLanguagesResponse.deserializeBinary
  );

  updateCompanyLanguages(
    request: company_pb.UpdateCompanyLanguagesRequest,
    metadata: grpcWeb.Metadata | null): Promise<company_pb.UpdateCompanyLanguagesResponse>;

  updateCompanyLanguages(
    request: company_pb.UpdateCompanyLanguagesRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: company_pb.UpdateCompanyLanguagesResponse) => void): grpcWeb.ClientReadableStream<company_pb.UpdateCompanyLanguagesResponse>;

  updateCompanyLanguages(
    request: company_pb.UpdateCompanyLanguagesRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: company_pb.UpdateCompanyLanguagesResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/core.private.companies.CompanyService/UpdateCompanyLanguages',
        request,
        metadata || {},
        this.methodDescriptorUpdateCompanyLanguages,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/core.private.companies.CompanyService/UpdateCompanyLanguages',
    request,
    metadata || {},
    this.methodDescriptorUpdateCompanyLanguages);
  }

  methodDescriptorUpdateCollectCompanyName = new grpcWeb.MethodDescriptor(
    '/core.private.companies.CompanyService/UpdateCollectCompanyName',
    grpcWeb.MethodType.UNARY,
    company_pb.UpdateCollectCompanyNameRequest,
    company_pb.UpdateCollectCompanyNameResponse,
    (request: company_pb.UpdateCollectCompanyNameRequest) => {
      return request.serializeBinary();
    },
    company_pb.UpdateCollectCompanyNameResponse.deserializeBinary
  );

  updateCollectCompanyName(
    request: company_pb.UpdateCollectCompanyNameRequest,
    metadata: grpcWeb.Metadata | null): Promise<company_pb.UpdateCollectCompanyNameResponse>;

  updateCollectCompanyName(
    request: company_pb.UpdateCollectCompanyNameRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: company_pb.UpdateCollectCompanyNameResponse) => void): grpcWeb.ClientReadableStream<company_pb.UpdateCollectCompanyNameResponse>;

  updateCollectCompanyName(
    request: company_pb.UpdateCollectCompanyNameRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: company_pb.UpdateCollectCompanyNameResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/core.private.companies.CompanyService/UpdateCollectCompanyName',
        request,
        metadata || {},
        this.methodDescriptorUpdateCollectCompanyName,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/core.private.companies.CompanyService/UpdateCollectCompanyName',
    request,
    metadata || {},
    this.methodDescriptorUpdateCollectCompanyName);
  }

  methodDescriptorGetCollectCompanyName = new grpcWeb.MethodDescriptor(
    '/core.private.companies.CompanyService/GetCollectCompanyName',
    grpcWeb.MethodType.UNARY,
    company_pb.GetCollectCompanyNameRequest,
    company_pb.GetCollectCompanyNameResponse,
    (request: company_pb.GetCollectCompanyNameRequest) => {
      return request.serializeBinary();
    },
    company_pb.GetCollectCompanyNameResponse.deserializeBinary
  );

  getCollectCompanyName(
    request: company_pb.GetCollectCompanyNameRequest,
    metadata: grpcWeb.Metadata | null): Promise<company_pb.GetCollectCompanyNameResponse>;

  getCollectCompanyName(
    request: company_pb.GetCollectCompanyNameRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: company_pb.GetCollectCompanyNameResponse) => void): grpcWeb.ClientReadableStream<company_pb.GetCollectCompanyNameResponse>;

  getCollectCompanyName(
    request: company_pb.GetCollectCompanyNameRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: company_pb.GetCollectCompanyNameResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/core.private.companies.CompanyService/GetCollectCompanyName',
        request,
        metadata || {},
        this.methodDescriptorGetCollectCompanyName,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/core.private.companies.CompanyService/GetCollectCompanyName',
    request,
    metadata || {},
    this.methodDescriptorGetCollectCompanyName);
  }

  methodDescriptorDeleteCustomField = new grpcWeb.MethodDescriptor(
    '/core.private.companies.CompanyService/DeleteCustomField',
    grpcWeb.MethodType.UNARY,
    company_pb.DeleteCustomFieldRequest,
    company_pb.DeleteCustomFieldResponse,
    (request: company_pb.DeleteCustomFieldRequest) => {
      return request.serializeBinary();
    },
    company_pb.DeleteCustomFieldResponse.deserializeBinary
  );

  deleteCustomField(
    request: company_pb.DeleteCustomFieldRequest,
    metadata: grpcWeb.Metadata | null): Promise<company_pb.DeleteCustomFieldResponse>;

  deleteCustomField(
    request: company_pb.DeleteCustomFieldRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: company_pb.DeleteCustomFieldResponse) => void): grpcWeb.ClientReadableStream<company_pb.DeleteCustomFieldResponse>;

  deleteCustomField(
    request: company_pb.DeleteCustomFieldRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: company_pb.DeleteCustomFieldResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/core.private.companies.CompanyService/DeleteCustomField',
        request,
        metadata || {},
        this.methodDescriptorDeleteCustomField,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/core.private.companies.CompanyService/DeleteCustomField',
    request,
    metadata || {},
    this.methodDescriptorDeleteCustomField);
  }

  methodDescriptorGoLive = new grpcWeb.MethodDescriptor(
    '/core.private.companies.CompanyService/GoLive',
    grpcWeb.MethodType.UNARY,
    company_pb.GoLiveRequest,
    company_pb.GoLiveResponse,
    (request: company_pb.GoLiveRequest) => {
      return request.serializeBinary();
    },
    company_pb.GoLiveResponse.deserializeBinary
  );

  goLive(
    request: company_pb.GoLiveRequest,
    metadata: grpcWeb.Metadata | null): Promise<company_pb.GoLiveResponse>;

  goLive(
    request: company_pb.GoLiveRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: company_pb.GoLiveResponse) => void): grpcWeb.ClientReadableStream<company_pb.GoLiveResponse>;

  goLive(
    request: company_pb.GoLiveRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: company_pb.GoLiveResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/core.private.companies.CompanyService/GoLive',
        request,
        metadata || {},
        this.methodDescriptorGoLive,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/core.private.companies.CompanyService/GoLive',
    request,
    metadata || {},
    this.methodDescriptorGoLive);
  }

  methodDescriptorGetCompanyFeaturesState = new grpcWeb.MethodDescriptor(
    '/core.private.companies.CompanyService/GetCompanyFeaturesState',
    grpcWeb.MethodType.UNARY,
    company_pb.GetCompanyFeaturesStateRequest,
    company_pb.GetCompanyFeaturesStateResponse,
    (request: company_pb.GetCompanyFeaturesStateRequest) => {
      return request.serializeBinary();
    },
    company_pb.GetCompanyFeaturesStateResponse.deserializeBinary
  );

  getCompanyFeaturesState(
    request: company_pb.GetCompanyFeaturesStateRequest,
    metadata: grpcWeb.Metadata | null): Promise<company_pb.GetCompanyFeaturesStateResponse>;

  getCompanyFeaturesState(
    request: company_pb.GetCompanyFeaturesStateRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: company_pb.GetCompanyFeaturesStateResponse) => void): grpcWeb.ClientReadableStream<company_pb.GetCompanyFeaturesStateResponse>;

  getCompanyFeaturesState(
    request: company_pb.GetCompanyFeaturesStateRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: company_pb.GetCompanyFeaturesStateResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/core.private.companies.CompanyService/GetCompanyFeaturesState',
        request,
        metadata || {},
        this.methodDescriptorGetCompanyFeaturesState,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/core.private.companies.CompanyService/GetCompanyFeaturesState',
    request,
    metadata || {},
    this.methodDescriptorGetCompanyFeaturesState);
  }

  methodDescriptorGetBillsbySubscription = new grpcWeb.MethodDescriptor(
    '/core.private.companies.CompanyService/GetBillsbySubscription',
    grpcWeb.MethodType.UNARY,
    company_pb.GetBillsbySubscriptionRequest,
    company_pb.GetBillsbySubscriptionResponse,
    (request: company_pb.GetBillsbySubscriptionRequest) => {
      return request.serializeBinary();
    },
    company_pb.GetBillsbySubscriptionResponse.deserializeBinary
  );

  getBillsbySubscription(
    request: company_pb.GetBillsbySubscriptionRequest,
    metadata: grpcWeb.Metadata | null): Promise<company_pb.GetBillsbySubscriptionResponse>;

  getBillsbySubscription(
    request: company_pb.GetBillsbySubscriptionRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: company_pb.GetBillsbySubscriptionResponse) => void): grpcWeb.ClientReadableStream<company_pb.GetBillsbySubscriptionResponse>;

  getBillsbySubscription(
    request: company_pb.GetBillsbySubscriptionRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: company_pb.GetBillsbySubscriptionResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/core.private.companies.CompanyService/GetBillsbySubscription',
        request,
        metadata || {},
        this.methodDescriptorGetBillsbySubscription,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/core.private.companies.CompanyService/GetBillsbySubscription',
    request,
    metadata || {},
    this.methodDescriptorGetBillsbySubscription);
  }

}

