/**
 * @fileoverview gRPC-Web generated client stub for core.private.discounts
 * @enhanceable
 * @public
 */

// GENERATED CODE -- DO NOT EDIT!


import * as grpcWeb from "grpc-web";


import {
  CreateDiscountRequest,
  CreateDiscountResponse,
  DeleteDiscountRequest,
  DeleteDiscountResponse,
  GetCouponsRequest,
  GetCouponsResponse,
  GetDiscountRequest,
  GetDiscountResponse,
  GetDiscountsRequest,
  GetDiscountsResponse,
  GetPlanCurrenciesRequest,
  GetPlanCurrenciesResponse,
  UpdateDiscountRequest,
  UpdateDiscountResponse} from "./discounts_pb";

export class DiscountsServiceClient {
  client_: grpcWeb.AbstractClientBase;
  hostname_: string;
  credentials_: null | { [index: string]: string; };
  options_: null | { [index: string]: string; };

  constructor (hostname: string,
               credentials?: null | { [index: string]: string; },
               options?: null | { [index: string]: string; }) {
    if (!options) options = {};
    if (!credentials) credentials = {};
    options["format"] = "text";

    this.client_ = new grpcWeb.GrpcWebClientBase(options);
    this.hostname_ = hostname;
    this.credentials_ = credentials;
    this.options_ = options;
  }

  methodInfoCreateDiscount = new grpcWeb.AbstractClientBase.MethodInfo(
    CreateDiscountResponse,
    (request: CreateDiscountRequest) => {
      return request.serializeBinary();
    },
    CreateDiscountResponse.deserializeBinary
  );

  createDiscount(
    request: CreateDiscountRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: CreateDiscountResponse) => void) {
    return this.client_.rpcCall(
      this.hostname_ +
        "/core.private.discounts.DiscountsService/CreateDiscount",
      request,
      metadata || {},
      this.methodInfoCreateDiscount,
      callback);
  }

  methodInfoUpdateDiscount = new grpcWeb.AbstractClientBase.MethodInfo(
    UpdateDiscountResponse,
    (request: UpdateDiscountRequest) => {
      return request.serializeBinary();
    },
    UpdateDiscountResponse.deserializeBinary
  );

  updateDiscount(
    request: UpdateDiscountRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: UpdateDiscountResponse) => void) {
    return this.client_.rpcCall(
      this.hostname_ +
        "/core.private.discounts.DiscountsService/UpdateDiscount",
      request,
      metadata || {},
      this.methodInfoUpdateDiscount,
      callback);
  }

  methodInfoGetDiscounts = new grpcWeb.AbstractClientBase.MethodInfo(
    GetDiscountsResponse,
    (request: GetDiscountsRequest) => {
      return request.serializeBinary();
    },
    GetDiscountsResponse.deserializeBinary
  );

  getDiscounts(
    request: GetDiscountsRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: GetDiscountsResponse) => void) {
    return this.client_.rpcCall(
      this.hostname_ +
        "/core.private.discounts.DiscountsService/GetDiscounts",
      request,
      metadata || {},
      this.methodInfoGetDiscounts,
      callback);
  }

  methodInfoGetDiscount = new grpcWeb.AbstractClientBase.MethodInfo(
    GetDiscountResponse,
    (request: GetDiscountRequest) => {
      return request.serializeBinary();
    },
    GetDiscountResponse.deserializeBinary
  );

  getDiscount(
    request: GetDiscountRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: GetDiscountResponse) => void) {
    return this.client_.rpcCall(
      this.hostname_ +
        "/core.private.discounts.DiscountsService/GetDiscount",
      request,
      metadata || {},
      this.methodInfoGetDiscount,
      callback);
  }

  methodInfoGetCoupons = new grpcWeb.AbstractClientBase.MethodInfo(
    GetCouponsResponse,
    (request: GetCouponsRequest) => {
      return request.serializeBinary();
    },
    GetCouponsResponse.deserializeBinary
  );

  getCoupons(
    request: GetCouponsRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: GetCouponsResponse) => void) {
    return this.client_.rpcCall(
      this.hostname_ +
        "/core.private.discounts.DiscountsService/GetCoupons",
      request,
      metadata || {},
      this.methodInfoGetCoupons,
      callback);
  }

  methodInfoDeleteDiscount = new grpcWeb.AbstractClientBase.MethodInfo(
    DeleteDiscountResponse,
    (request: DeleteDiscountRequest) => {
      return request.serializeBinary();
    },
    DeleteDiscountResponse.deserializeBinary
  );

  deleteDiscount(
    request: DeleteDiscountRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: DeleteDiscountResponse) => void) {
    return this.client_.rpcCall(
      this.hostname_ +
        "/core.private.discounts.DiscountsService/DeleteDiscount",
      request,
      metadata || {},
      this.methodInfoDeleteDiscount,
      callback);
  }

  methodInfoGetPlanCurrencies = new grpcWeb.AbstractClientBase.MethodInfo(
    GetPlanCurrenciesResponse,
    (request: GetPlanCurrenciesRequest) => {
      return request.serializeBinary();
    },
    GetPlanCurrenciesResponse.deserializeBinary
  );

  getPlanCurrencies(
    request: GetPlanCurrenciesRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: GetPlanCurrenciesResponse) => void) {
    return this.client_.rpcCall(
      this.hostname_ +
        "/core.private.discounts.DiscountsService/GetPlanCurrencies",
      request,
      metadata || {},
      this.methodInfoGetPlanCurrencies,
      callback);
  }

}

