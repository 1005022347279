import React, { useState } from "react"
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../../..";
import { IAuthReducerState } from "../../../reducers/authReducer";
import Table from "../../ui/table/Table";
import Text from "../../ui/text/Text";
import Checkbox from "../../ui/checkbox/Checkbox";
import MenuPanel from "../../ui/menu-panel/MenuPanel";
import "./SelectAddOnPlan.scss";
import { Row, Col } from "react-grid-system";
import Button from "../../ui/button/Button";
import { ISelectPlanAddOnsReducerState } from "../../../reducers/add-ons/selectAddOnPlanReducer";
import { ICreateAddOnsReducerState } from "../../../reducers/add-ons/create-addons/createAddOnsReducer";
import { setShowSelectPlanModal, setAddOnsSelectedPlansId, setAddOnsSelectedPlan, deselectAllAddOnsSelectedPlan, setSelectedCompanyPlansPage, selectAllAddOnsSelectedPlan, setSearchKeyAddOnsPlansPage, cancelSelectedAddOnsPlans, setAddOnsFormCompleted } from "../../../actions/addOnsActions";
import Search from "../../search/Search";
import Pagination from "../../ui/pagination/Pagination";
import { AddonsAllowancesScreen } from "../../../models/AddonsAllowances";

interface Props {
  type: AddonsAllowancesScreen;
}

const SelectAddOnPlan: React.FC<Props> = ({ type }) => {
  const auth = useSelector<AppState, IAuthReducerState>(state => state.auth)
  const addOns = useSelector<AppState, ICreateAddOnsReducerState>(state => state.createAddOnsReducer)
  const selectAddOnPlan = useSelector<AppState, ISelectPlanAddOnsReducerState>(state => state.selectAddOnPlanReducer)
  const { selectedPlansIds, companyPlans, companyPlansFiltered, plansNumberOfPages, plansCurrentPage, companyPlansDisplay } = selectAddOnPlan;
  const dispatch = useDispatch<Function>()

  const [searchKey, setSearchKey] = useState("");
  const isAddonScreen = type === AddonsAllowancesScreen.ADDONS;
  const isSelected = (planId: number) => {
    const index = selectedPlansIds.findIndex(item => item === planId);
    return index >= 0;
  }  
  
  return (
    <MenuPanel className="select-add-on-plan">
      <MenuPanel.Content>
        <Text content="CREATE_ADD_ONS_FORM_HEADER_SELECT_PLANS_MODAL_TITLE" className="select-add-on-plan__title" />
        <div className="select-add-on-plan__search">
          <Search
            value={searchKey}
            placeholder="CREATE_ADD_ONS_FORM_HEADER_SELECT_PLANS_TABLE_SEARCH_PLACEHOLDER"
            onChange={(evt: any) => setSearchKey(evt.target.value)}
            onSearch={() => dispatch(setSearchKeyAddOnsPlansPage(searchKey))}
          />
          <Pagination
            className="select-add-on-plan__search__pagination"
            pageCount={plansNumberOfPages}
            isVisible={plansNumberOfPages > 1}
            onPageChange={({ selected }) => dispatch(setSelectedCompanyPlansPage(selected + 1))}
          />
        </div>
        <Table className="select-add-on-plan__table">
          <thead>
            <tr>
              <Text content="CREATE_ADD_ONS_FORM_HEADER_SELECT_PLANS_TABLE_PRODUCT_COLUMN_TITLE" component="th" noMargin />
              <Text content="CREATE_ADD_ONS_FORM_HEADER_SELECT_PLANS_TABLE_PLAN_COLUMN_TITLE" component="th" noMargin />
              <th>
                {companyPlans.length !== selectedPlansIds.length && <Button
                  id="select-all"
                  icon="fas fa-list-alt"
                  title="CREATE_ADD_ONS_FORM_HEADER_SELECT_PLANS_TABLE_SELECT_ALL_COLUMN_TITLE"
                  buttonType="add"
                  onClick={() => dispatch(selectAllAddOnsSelectedPlan())}
                />}
                {companyPlans.length === selectedPlansIds.length && <Button
                  id="deselect-all"
                  icon="fas fa-times"
                  title="CREATE_ADD_ONS_FORM_HEADER_SELECT_PLANS_TABLE_DESELECT_ALL_COLUMN_TITLE"
                  buttonType="add"
                  onClick={() => dispatch(deselectAllAddOnsSelectedPlan())}
                />}
              </th>
            </tr>
          </thead>
          <tbody>
            {companyPlansDisplay.map(plan => (
              <tr key={`select-add-on-plan-table-tr-${plan.planId}`}>
                <td>{plan.product}</td>
                <td>
                  {plan.plan}
                </td>
                <td>
                  <Checkbox checked={isSelected(plan.planId)} onClick={() => dispatch(setAddOnsSelectedPlansId(plan.planId))} value='' content="" />
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
        <Row>
          <Col md={9}>
            <Button
              id="select-add-on-plans-btn"
              title="CREATE_ADD_ONS_FORM_HEADER_SELECT_PLANS_MODAL_SELECT_BUTTON"
              disabled={selectedPlansIds.length < 1}
              onClick={() => {
                dispatch(setAddOnsSelectedPlan(selectedPlansIds));
                dispatch(setAddOnsFormCompleted(1, true));
                dispatch(setSearchKeyAddOnsPlansPage(""))
                dispatch(setShowSelectPlanModal(false))
              }}
              isFullWidth
            />
          </Col>
          <Col md={3}>
            <Button
              id="cancel-select-add-on-plans-btn"
              buttonType="error"
              title="CREATE_ADD_ONS_FORM_HEADER_SELECT_PLANS_MODAL_CANCEL_BUTTON"
              onClick={() => {
                dispatch(cancelSelectedAddOnsPlans());
                dispatch(setShowSelectPlanModal(false))
              }
              }
              isFullWidth
            />
          </Col>
        </Row>
      </MenuPanel.Content>
    </MenuPanel>
  )
}

export default SelectAddOnPlan