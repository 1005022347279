import { ICountry } from "./Country";
import { ICustomField } from "./CustomFields";
import { ICurrency } from "./Currency";

export interface IProductFull extends IProductLight {
  isShippingAddressRequired: boolean,
  isShippingAddressValidationRequired: boolean,
  isBillingAddressValidationRequired: boolean,
  isWorldWideAddressAllowed: boolean,
  isAdditionalEmailRequired: boolean,
  isPhoneNumberRequired: boolean,
  isMarketingConsentRequired: boolean,
  orderedCustomFields?: Array<{ customFieldId: number }>,
  customFields?: Array<ICustomField>,
  productCountryLimitation: IProductCountryLimitation
}

export interface IProductLight extends IBaseProduct {
  productId: number,
  hasPlans: boolean,
  isDeleted: boolean,
  hasActiveSubscriptions: boolean
}

export interface IBaseProduct {
  name: string,
  visibility: VisibilityType,
  billingCurrency: string,
  billingCurrencyTemplate: string,
  displayName: string,
  description: string,
  typeOfProduct: ProductType,
  alertEmail: string,
  productImageUrl: string,
  isProductImageEnabled: boolean,
  productImageFileName: string
}

export interface IProductCountryLimitation {
  isAnyCountryAllowed: boolean,
  countrySettings: Array<{ countryIso3: string, addressPermission: "Both" | "BillingAddress" | "ShippingAddress" }>
}

export enum VisibilityType {
  Public = "Public",
  Hidden = "Hidden",
  Internal = "Internal",
  OffSale = "OffSale"
}

export enum ProductType {
  TieredPlan = 1
}

export enum PricingModelType {
  FlatFee = "FlatFee",
  PerUnit = "PerUnit",
  Tiered = "Tiered",
  Ranged = "Ranged",
  Volume = "Volume",
  Capped = "Capped"
}

export enum FrequencyType {
  Daily = "Daily",
  Weekly = "Weekly",
  Monthly = "Monthly",
  Yearly = "Yearly",
}


export interface IProductLightWithPlans extends IProductLight {
  plans: Array<IPlanWithCycles>,
  numberOfPlans: number
}

export interface IProductWithPlans  {
  alertEmail: string,
  billingCurrency: string,
  description: string,
  displayName: string,
  isDeleted: boolean
  name: string,
  productId: number,
  typeOfProduct: number,
  visibility: VisibilityType,
  plans: IPlanWithCycles[]
}

export interface IBasePlan {
  name: string,
  displayName: string,
  description: string,
  featureTags: Array<string>,
  visibility: VisibilityType,
  pricingModel: PricingModelType,
  redirectUrl: string,
  alertEmail: string,
  addons: Array<IPlanAddOn>,
  allowances: Array<IPlanAllowance>,
  isPlanImageEnabled: boolean,
  planImageFileName: string,
  planImageUrl: string
}

export interface IPlan extends IBasePlan {
  planId: number,
  productId: number,

}

export interface IPlanWithCycles extends IPlan {
  cycles: ICycle[],
  pricingModelType: PricingModelType
}

export interface IPricingModel {
  pricingModelId?: number,
  price: number,
  priceFormatted: string,
  frequencyType: FrequencyType,
  frequency: number,
  freeTrial?: number,
  freeTrialFrequencyType?: FrequencyType,
  contractTerm?: number,
  contractTermFrequencyType?: FrequencyType,
  freeQuantity?: number,
  setupFeePrice?: number,
  setupFeePriceFormatted?: string,
  billingDateType: BillingDateType,
  fixedBillingDateDay?: number,
  fixedBillingDateMonth?: number,
  proRateOption?: ProRateType,
  visibility: VisibilityType,
  tiers?: Array<ICreatedTier>
}

export interface IProductsPaginated {
  currentPage: number,
  pageCount: number,
  pageSize: number,
  rowCount: number,
  results?: Array<IProductLightWithPlans>
}

export interface IBaseCycle {
  pricingModel: IPricingModel
}

export interface ICycle extends IBaseCycle {
  billingDateType: BillingDateType,
  cycleId: number,
  planId: number
  visibility: VisibilityType
}

export interface VisibilityModelWithTips {
  visibilityType: VisibilityType,
  visibilityStr: string,
  tip: string
}

export enum BillingDateType {
  FIXED_DAY_MONTH = "FixedDayEachMonth",
  FIXED_DAY_WEEK = "FixedDayOfTheWeek",
  ANNINVERSARY_SIGNUP = "Anniversary",
  FIXED_DAY_EACH_YEAR = "FixedDayEachYear"
}

export enum ProRateType {
  NO_PRORATE = "DoNotProRate",
  NO_CHARGE_UNTIL_NEXT_CYCLE = "DoNotChargeUntilNextCycle",
  BASED_ON_DAYS_REMAINING = "ProRateBasedOnDaysRemaining"
}

export interface ICountryAddress extends ICountry {
  allowBillingAddress: boolean,
  allowShippingAddress: boolean
}

export interface ICreatedTier {
  start: number,
  finish: number,
  price: number,
  priceFormatted?: string
}

export interface IAddOnPriceModel {
  frequency: number,
  frequencyType: number,
  flatFeePrice: number,
  pricePerUnit: number,
  currencyIso3Code: string,
  unitTiers: Array<
    {
      start: number,
      finish: number,
      price: number
    }
  >
}

export interface ILinkedAddOn {
  addonId: number,
  isForced: boolean,
  addonPriceModels: Array<IAddOnPriceModel>
}


export interface IAllowancePriceModel {
  frequency: number,
  frequencyType: number,
  includedUnits: number,
  pricePerUnit: number,
  unitTiers: Array<
    {
      start: number,
      finish: number,
      price: number
    }
  >
}

export interface ILinkedAllowance {
  allowanceId: number,
  isForced: boolean,
  allowancePriceModels: Array<IAllowancePriceModel>
}



export interface IPlanAddOn {
  id: number,
  name: string,
  displayName: string,
  description: string,
  singleUnitName: string,
  pluralUnitName: string,
  isForced: true,
  pricingModelType: number,
  pricingModels: Array<IPlanAddOnPricingModel>
}

export interface IPlanAllowance {
  id: 0,
  name: string,
  displayName: string,
  description: string,
  singleUnitName: string,
  pluralUnitName: string,
  isForced: boolean,
  pricingModelType: number,
  pricingModels: Array<IPlanAllowancePricingModel>
}

export interface IPlanAddOnPricingModel {
  frequency: number,
  frequencyType: number,
  flatFeePrice: number,
  perUnitPrice: number,
  pricingModelType: number,
  currency: ICurrency,
  tiers: Array<
    {
      unitTierId: number,
      priceModelId: number,
      start: number,
      finish: number,
      price: number
    }
  >
}

export interface IPlanAllowancePricingModel {
  frequency: number,
  frequencyType: number,
  includedUnits: number,
  perUnitPrice: number,
  pricingModelType: number,
  currency: ICurrency,
  tiers: Array<
    {
      unitTierId: number,
      priceModelId: number,
      start: number,
      finish: number,
      price: number
    }
  >
}