import React, { useEffect } from "react";
import "./PaymentDetailsFormCheckout.scss";
import FormGroup from "../ui/form-group/FormGroup";
import FormLabel from "../ui/form-label/FormLabel";
import CheckoutInput from "../custom-fields/previews/ui-checkout/CheckoutInput";
import { Row, Col } from "react-grid-system";
import CardsPanel from "../ui/cards-panel/CardsPanel";
import { ConfigConstants } from "../../utils/config";
import counterpart from "counterpart";

interface IPaymentDetailsFormCheckout {
  onChangeCardholderName: (evt: any) => void,
  onChangeExpiryMonth: (evt: any) => void,
  onChangeExpiryYear: (evt: any) => void,
  cardNumberId: string,
  cvvId: string,
  formErrors: { cardNumber: string, expiryMonth: string, expiryYear: string } | null,
  formData: {
    cardholderName: string,
    expiryMonth: string,
    expiryYear: string,
  },
  className?: string,
  locale?: string
}

const PaymentDetailsFormCheckout: React.FC<IPaymentDetailsFormCheckout> = (props) => {
  const {
    formData: { cardholderName, expiryMonth, expiryYear },
    formErrors,
    onChangeCardholderName,
    onChangeExpiryMonth,
    onChangeExpiryYear,
    cardNumberId,
    cvvId, 
    locale
  } = props;

  useEffect(() => {
    const Spreedly = (window as any).Spreedly;

    Spreedly.init(ConfigConstants.spreedlyKey, {
      numberEl: cardNumberId,
      cvvEl: cvvId
    });

    Spreedly.on("ready", function () {
      Spreedly.setPlaceholder("number", "xxxx xxxx xxxx xxxx");
      Spreedly.setPlaceholder("cvv", "xxx");
      Spreedly.setFieldType("number", "text");
      Spreedly.setNumberFormat("prettyFormat");
    });
  }, []);

  const cardNumberHasError = formErrors && formErrors.cardNumber ? "payment-details-form-checkout__spreedly-input--error" : "";
  const expiryMonthHasError = formErrors && formErrors.expiryMonth;
  const expiryYearHasError = formErrors && formErrors.expiryYear;

  return (
    <div className="payment-details-form-checkout">
      <FormGroup>
        <FormLabel locale={locale} target="" content="PAYMENT_DETAILS_FORM_CHECKOUT_CARDHOLDER_NAME" />
        <CheckoutInput
          id="cardholder"
          placeholder={`${counterpart("PAYMENT_DETAILS_FORM_FIRST_NAME_PLACEHOLDER", { locale })} ${counterpart("PAYMENT_DETAILS_FORM_LAST_NAME_PLACEHOLDER", { locale })}`}
          value={cardholderName}
          onChange={onChangeCardholderName}
        />
      </FormGroup>

      <FormGroup>
        <Row align="center">
          <Col xs={10}>
            <FormLabel locale={locale} target="cardnumber" content="PAYMENT_DETAILS_FORM_CHECKOUT_CREDIT_CARD_NUMBER" />
            <div className={`payment-details-form-checkout__spreedly-input ${cardNumberHasError}`} id={cardNumberId} />
          </Col>
          <Col xs={2}>
            <FormLabel target="cvv" content="PAYMENT_DETAILS_FORM_CHECKOUT_CVV" />
            <div className="payment-details-form-checkout__spreedly-input" id={cvvId}></div>
          </Col>
        </Row>
      </FormGroup>

      <FormGroup>
        <FormLabel locale={locale} target="expdate" content="PAYMENT_DETAILS_FORM_CHECKOUT_EXP_DATE" />
        <Row align="center">
          <Col xs={2}>
            <CheckoutInput
              isError={!!expiryMonthHasError}
              id="expdateMM"
              placeholder="MM"
              value={expiryMonth}
              onChange={onChangeExpiryMonth}
            />
          </Col>
          <Col xs={2}>
            <CheckoutInput
              isError={!!expiryYearHasError}
              id="expdateYYYY"
              placeholder="YYYY"
              value={expiryYear}
              onChange={onChangeExpiryYear}
            />
          </Col>
        </Row>
      </FormGroup>

      <CardsPanel />
    </div>
  )
}

export default PaymentDetailsFormCheckout;