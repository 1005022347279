import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { fetchGettingStartedSettings } from "../../actions/authActions";
import { moveCompanyToLiveRequest, MOVE_COMPANY_TO_LIVE_SCA_FAILURE, MOVE_COMPANY_TO_LIVE_SCA_SUCCESS } from "../../actions/goLiveActions";
import ProgressIndicator from "../../components/ui/progress-indicator/ProgressIndicator";
import { isValidJSON } from "../../utils/commonUtils";
import history from "../../utils/history";


const GoLive3ds1Success: React.FC = () => {
  const dispatch = useDispatch<Function>();

  useEffect(() => {
    const handleGoLiveSca = async () => {
      const isValid = await isValidJSON(sessionStorage.goLiveSCA);

      if(!isValid) {
        return history.push("/launch-checklist")
      } 
      const goLiveSCA = JSON.parse(sessionStorage.goLiveSCA);
      const { companyLiveData, companyDomain, companyId } = goLiveSCA;

      try {
        await moveCompanyToLiveRequest(companyId, companyLiveData);
        dispatch({ type: MOVE_COMPANY_TO_LIVE_SCA_SUCCESS });
        dispatch(fetchGettingStartedSettings(companyDomain));
      } catch (err) { 
        dispatch({ type: MOVE_COMPANY_TO_LIVE_SCA_FAILURE })
      } finally {
        history.push("/launch-checklist")
      }
    }

    handleGoLiveSca();
  }, [])

  return <ProgressIndicator color="blue" coverage="full-content"></ProgressIndicator>
}

export default GoLive3ds1Success;