
import { makeActionCreator } from "../utils/reduxUtils";
import API from "../utils/API";

export const FETCH_SUBSCRIPTION_FEATURE_TAGS_REQUEST = "FETCH_SUBSCRIPTION_FEATURE_TAGS_REQUEST";
export const FETCH_SUBSCRIPTION_FEATURE_TAGS_SUCCESS = "FETCH_SUBSCRIPTION_FEATURE_TAGS_SUCCESS";
export const FETCH_SUBSCRIPTION_FEATURE_TAGS_FAILURE = "FETCH_SUBSCRIPTION_FEATURE_TAGS_FAILURE";

export const SET_SUBSCRIPTION_FEATURE_TAGS = "SET_SUBSCRIPTION_FEATURE_TAGS";
export const setSubscriptionFeatureTags = makeActionCreator(SET_SUBSCRIPTION_FEATURE_TAGS, "payload");

export const REMOVE_SUBSCRIPTION_FEATURE_TAG = "REMOVE_SUBSCRIPTION_FEATURE_TAG";
export const removeSubscriptionFeatureTags = makeActionCreator(REMOVE_SUBSCRIPTION_FEATURE_TAG, "payload");

export const ADD_SUBSCRIPTION_FEATURE_TAGS_REQUEST = "ADD_SUBSCRIPTION_FEATURE_TAGS_REQUEST";
export const ADD_SUBSCRIPTION_FEATURE_TAGS_SUCCESS = "ADD_SUBSCRIPTION_FEATURE_TAGS_SUCCESS";
export const ADD_SUBSCRIPTION_FEATURE_TAGS_FAILURE = "ADD_SUBSCRIPTION_FEATURE_TAGS_FAILURE";

export const DELETE_SUBSCRIPTION_FEATURE_TAGS_REQUEST = "DELETE_SUBSCRIPTION_FEATURE_TAGS_REQUEST";
export const DELETE_SUBSCRIPTION_FEATURE_TAGS_SUCCESS = "DELETE_SUBSCRIPTION_FEATURE_TAGS_SUCCESS";
export const DELETE_SUBSCRIPTION_FEATURE_TAGS_FAILURE = "DELETE_SUBSCRIPTION_FEATURE_TAGS_FAILURE";

export const RESET_SUBSCRIPTION_FEATURE_TAGS = "RESET_SUBSCRIPTION_FEATURE_TAGS";
export const resetSubscriptionFeatureTags = makeActionCreator(RESET_SUBSCRIPTION_FEATURE_TAGS);

export const fetchSubscriptionFeatureTags = function (companyDomain: string, subscriptionUniqueId: string) {
  return {
    types: [FETCH_SUBSCRIPTION_FEATURE_TAGS_REQUEST, FETCH_SUBSCRIPTION_FEATURE_TAGS_SUCCESS, FETCH_SUBSCRIPTION_FEATURE_TAGS_FAILURE],
    callAPI: () => API.fetchSubscriptionFeatureTags(companyDomain, subscriptionUniqueId)
  }
}

export const addSubscriptionFeatureTags = function (companyDomain: string, subscriptionUniqueId: string, tags: Array<string>) {
  const data = {
    subscriptionUniqueIds: [subscriptionUniqueId],
    tagNames: tags
  }
  return {
    types: [ADD_SUBSCRIPTION_FEATURE_TAGS_REQUEST, ADD_SUBSCRIPTION_FEATURE_TAGS_SUCCESS, ADD_SUBSCRIPTION_FEATURE_TAGS_FAILURE],
    callAPI: () => API.addSubscriptionFeatureTags(companyDomain, data)
  }
}

export const deleteSubscriptionFeatureTags = function (companyDomain: string, subscriptionUniqueId: string, tag: string) {
  return {
    types: [DELETE_SUBSCRIPTION_FEATURE_TAGS_REQUEST, DELETE_SUBSCRIPTION_FEATURE_TAGS_SUCCESS, DELETE_SUBSCRIPTION_FEATURE_TAGS_FAILURE],
    callAPI: () => API.deleteSubscriptionFeatureTags(companyDomain, subscriptionUniqueId, tag)
  }
}