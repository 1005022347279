import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../../../../..";
import { updateGettingStartedSettings } from "../../../../../actions/authActions";
import { setCompanyBrandingHighLightColor, setCompanyBrandingLogoBackgroundColor, updateCompanyBranding, updateCompanyBrandingFavicon, updateCompanyBrandingLogo } from "../../../../../actions/companyBrandingActions";
import { GettingStartedType, IGettingStartedSettings } from "../../../../../models/GettingStarted";
import history from "../../../../../utils/history";
import Button from "../../../../ui/button/Button";
import ColorPicker from "../../../../ui/color-picker/ColorPicker";
import FileUpload from "../../../../ui/file-upload/FileUpload";
import FormGroup from "../../../../ui/form-group/FormGroup";
import InteractiveList from "../../../../ui/interactive-list/InteractiveList";
import NoticePanel from "../../../../ui/notice-panel/NoticePanel";
import Panel from "../../../../ui/panel/Panel";
import EmailInvoicesTabs from "../email-invoices-tabs/EmailInvoicesTabs";

const EmailInvoices: React.FC = () => {
  const authReducer = useSelector((state: AppState) => state.auth);
  const companyBrandingReducer = useSelector((state: AppState) => state.companyBrandingReducer);

  const dispatch = useDispatch<Function>();

  const [logoTooLarge, setLogoTooLarge] = useState(false);
  const [faviconTooLarge, setFaviconTooLarge] = useState(false);
  const { currentCompanyDomain, dashboardSettings } = authReducer;
  const { logoBackgroundColor, highlightColor, isLoading, logoUrl, faviconUrl,logoFileName, faviconFileName, rounding, typographyHeaderIsAllCaps,
  typographyBody, typographyHeader, headerAndButtonColor, backgroundColor, bodyTextColor, confirmationColor,errorColor } = companyBrandingReducer;
  const defaultColor1 = "#1E2432";
  const defaultColor2 = "#F87847";

  const renderColorsPanel = () => {
    return (
      <Panel title="BRAND_CUSTOMIZATION_COLORS" className="brand_customization__panel__logo">
        <div className="brand_customization__panel__logo__container">
          <div className="brand_customization__panel__logo__background-color-picker">
            <ColorPicker
              label="BRAND_CUSTOMIZATION_LOGO_BACKGROUND_COLOR_TITLE"
              color={!!logoBackgroundColor ? logoBackgroundColor : defaultColor1}
              text="BRAND_CUSTOMIZATION_LOGO_BACKGROUND_COLOR_TITLE"
              key="background-color-picker"
              onChange={(evt: { color: string }) => dispatch(setCompanyBrandingLogoBackgroundColor(evt.color))}
            />
          </div>
          <div className="brand_customization__panel__logo__background-color-picker">
            <ColorPicker
              label="BRAND_CUSTOMIZATION_HIGHLIGHT_COLOR_TITLE"
              color={!!highlightColor ? highlightColor : defaultColor2}
              text="BRAND_CUSTOMIZATION_HIGHLIGHT_COLOR_TITLE"
              key="highlight-color-picker"
              onChange={(evt: { color: string }) => dispatch(setCompanyBrandingHighLightColor(evt.color))}
            />
          </div>
        </div>
      </Panel >
    )
  }

  const renderLogoAndFavIconPanel = () => {
    return (
      <Panel title="BRAND_CUSTOMIZATION_YOUR_LOGO_AND_FAVICON">
        {logoTooLarge && <NoticePanel type="error" content="ERROR_LARGE_IMG" />}
        {faviconTooLarge && <NoticePanel type="error" content="ERROR_LARGE_IMG" />}

        <div className="brand_customization__panel__logo__container">
          <div className="brand_customization__panel__logo__file-uploader">
            <FileUpload
              title="BRAND_CUSTOMIZATION_LOGO_UPLOADER_TITLE"
              subTitle="BRAND_CUSTOMIZATION_LOGO_UPLOADER_SUB_TITLE"
              image={logoUrl}
              onDrop={files => {
                dispatch(updateCompanyBrandingLogo(currentCompanyDomain, files[0]));
                setLogoTooLarge(files[0].size > 1000000);
              }}
            />
          </div>
          <div className="brand_customization__panel__logo__file-uploader">
            <FileUpload
              title="BRAND_CUSTOMIZATION_FAVICON_UPLOADER_TITLE"
              subTitle="BRAND_CUSTOMIZATION_LOGO_UPLOADER_SUB_TITLE"
              image={faviconUrl}
              onDrop={files => {
                dispatch(updateCompanyBrandingFavicon(currentCompanyDomain, files[0]));
                setFaviconTooLarge(files[0].size > 1000000);
              }}
            />
          </div>
        </div>

      </Panel>
    )
  }

  return (
    <>
      <FormGroup>
        <EmailInvoicesTabs />
      </FormGroup>

      {renderColorsPanel()}
      {renderLogoAndFavIconPanel()}
      <FormGroup>
        <Button
          id="brand-customization--update-btn"
          title="BRAND_CUSTOMIZATION_UPDATE_BRAND_SETTINGS"
          onClick={() => {
            dispatch(updateCompanyBranding(currentCompanyDomain, {
              logoUrl,
              logoBackgroundColor,
              faviconUrl,
              highlightColor,
              logoFileName,
              faviconFileName,
              rounding,
              typographyBody: typographyBody.value,
              typographyHeader: typographyHeader.value,
              typographyHeaderIsAllCaps,
              headerAndButtonColor,
              backgroundColor,
              bodyTextColor,
              confirmationColor,
              errorColor
            })).then(() => {
              if (!!dashboardSettings && dashboardSettings.status === GettingStartedType.GettingStarted2) {
                dispatch(
                  updateGettingStartedSettings(currentCompanyDomain, {
                    ...dashboardSettings as IGettingStartedSettings,
                    status: GettingStartedType.PreLive
                  })
                );
                setTimeout(() => history.push("/home"), 500);
              }
            });
          }}
          isLoading={isLoading}
          isFullWidth
        />
      </FormGroup>
      <InteractiveList
        data={[
          {
            title: "EMAIL_NOTIFICATIONS_MENU_ITEM_TITLE",
            subTitle: "EMAIL_NOTIFICATIONS_MENU_ITEM_CONTENT",
            onClick: () => history.push("/configuration/email-notifications")
          },
          {
            title: "INVOICE_CREDIT_NOTE_CUSTOMIZATION_MENU_ITEM_TITLE",
            subTitle: "INVOICE_CREDIT_NOTE_CUSTOMIZATION_MENU_ITEM_CONTENT",
            onClick: () => history.push("/configuration/invoices-credit-notes-customization")
          }
        ]}
      />
    </>
  )
}

export default EmailInvoices;