import React, { useLayoutEffect, useRef } from "react";
import "./Zapier.scss";
import Panel from "../../components/ui/panel/Panel";
import Text from "../../components/ui/text/Text";
import PageWrapper from "../../components/ui/layout/PageWrapper";
import Button from "../../components/ui/button/Button";
import counterpart from "counterpart";
import { findDOMNode } from "react-dom";
import ProgressIndicator from "../../components/ui/progress-indicator/ProgressIndicator";
import NavigationBar from "../../components/ui/navigation-bar/NavigationBar";
import { ConfigConstants } from "../../utils/config";
import { useHistory } from "react-router";

const Zapier: React.FC = () => {
  const history = useHistory();

  const templateRef = useRef(null);
  const TEMPLATE_ID = "zapier-integration";

  useLayoutEffect(() => {
    const templateDom = findDOMNode(templateRef.current);
    const zapierScript = document.createElement("script")
    zapierScript.type = "text/javascript"
    zapierScript.src = `https://zapier.com/apps/embed/widget.js?services=billsby&limit=10&html_id=${TEMPLATE_ID}`;
    zapierScript.onload = () => {
      // remove Loader
      const templateNode = templateDom?.getRootNode() as HTMLElement;
      const loader = templateNode.querySelector(".progress-indicator");
      loader?.remove();
    }
    
    if (templateDom) {
      document.head.appendChild(zapierScript);
    }

    return () => {
      // remove script on un-mount to avoid re-appending a new script everytime we land on zapier url 
      document.head.removeChild(zapierScript);
    }
  }, []);

  const goToZapierIntegration = () => {
    const isDev = ConfigConstants.reactAppEnvironment === "Localhost" || ConfigConstants.reactAppEnvironment === "Development"
    const isTest = ConfigConstants.reactAppEnvironment === "Test"
    const isStaging = ConfigConstants.reactAppEnvironment === "Staging"
    let url = "";
    if (isDev) {
      url = "https://zapier.com/developer/public-invite/115853/ff3312735f55ebf4013d4ed7eaac7944/";
    }
    else if (isTest) {
      url = "https://zapier.com/developer/public-invite/116656/2880f7fb3e2f8fa32966ece7e9a2ad65/";
    }
    else if (isStaging) {
      url = "https://zapier.com/developer/public-invite/176947/acf98038ca791746116792b682dc9b2c/";
    }
    else {
      url = "https://zapier.com/apps/billsby/integrations"
    }
    window.open(url, "_self");
  }

  return (
    <>
      <NavigationBar pageTitle="" previousPageTitle="INTEGRATIONS_TITLE" previousPageCb={() => history.push("/integrations")} />
      <PageWrapper className="zapier">
        <Panel className="zapier__heading">
          <div className="zapier__heading__title">
            <div className="zapier__heading__logo" />
            <Text content="ZAPIER_PANEL1_TITLE" noMargin />
          </div>
          <Text content="ZAPIER_PANEL1_CONTENT" noMargin />
          <Button
            id="integrate"
            className="zapier__heading__integrateBtn"
            title="ZAPIER_PANEL1_BTN"
            icon="fal fa-plus-circle"
            onClick={goToZapierIntegration}
          />
        </Panel>

        <Panel title="ZAPIER_PANEL2_TITLE">
          <Text content="ZAPIER_PANEL2_CONTENT1" translateWith={{ triggersLabel: <b>{counterpart("ZAPIER_PANEL2_CONTENT1_TRIGGERS")}</b> }} />
          <Text content="ZAPIER_PANEL2_CONTENT2" translateWith={{ actionsLabel: <b>{counterpart("ZAPIER_PANEL2_CONTENT2_ACTIONS")}</b> }} />
          <Text content="ZAPIER_PANEL2_CONTENT3" noMargin />
        </Panel>

        <Panel className="zapier__template">
          <div ref={templateRef} id={TEMPLATE_ID}>
            <ProgressIndicator color="blue" />
          </div>
        </Panel>

        <Button
          id="integrate-zapier-with-billby"
          title="ZAPIER_INTEGRATE_WITH_BILLSBY"
          icon="fal fa-plus-circle"
          isFullWidth
          onClick={goToZapierIntegration}
        />

      </PageWrapper>
    </>
  )
}

export default Zapier;