/* eslint-disable */
// source: discounts.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = Function('return this')();

var google_protobuf_timestamp_pb = require('google-protobuf/google/protobuf/timestamp_pb.js');
goog.object.extend(proto, google_protobuf_timestamp_pb);
var google_protobuf_wrappers_pb = require('google-protobuf/google/protobuf/wrappers_pb.js');
goog.object.extend(proto, google_protobuf_wrappers_pb);
goog.exportSymbol('proto.core.private.discounts.Coupon', null, global);
goog.exportSymbol('proto.core.private.discounts.Coupon.DiscountLengthCase', null, global);
goog.exportSymbol('proto.core.private.discounts.Coupon.ExpirationCase', null, global);
goog.exportSymbol('proto.core.private.discounts.CouponType', null, global);
goog.exportSymbol('proto.core.private.discounts.CreateDiscountRequest', null, global);
goog.exportSymbol('proto.core.private.discounts.CreateDiscountRequest.DiscountModelCase', null, global);
goog.exportSymbol('proto.core.private.discounts.CreateDiscountRequest.MonetaryModel', null, global);
goog.exportSymbol('proto.core.private.discounts.CreateDiscountRequest.PercentageModel', null, global);
goog.exportSymbol('proto.core.private.discounts.CreateDiscountResponse', null, global);
goog.exportSymbol('proto.core.private.discounts.Currency', null, global);
goog.exportSymbol('proto.core.private.discounts.DeductionType', null, global);
goog.exportSymbol('proto.core.private.discounts.DeleteDiscountRequest', null, global);
goog.exportSymbol('proto.core.private.discounts.DeleteDiscountResponse', null, global);
goog.exportSymbol('proto.core.private.discounts.Discount', null, global);
goog.exportSymbol('proto.core.private.discounts.Discount.DiscountModelCase', null, global);
goog.exportSymbol('proto.core.private.discounts.Discount.MonetaryModel', null, global);
goog.exportSymbol('proto.core.private.discounts.Discount.PercentageModel', null, global);
goog.exportSymbol('proto.core.private.discounts.DiscountType', null, global);
goog.exportSymbol('proto.core.private.discounts.EligibleCustomers', null, global);
goog.exportSymbol('proto.core.private.discounts.FixedTermDiscountLength', null, global);
goog.exportSymbol('proto.core.private.discounts.FrequencyType', null, global);
goog.exportSymbol('proto.core.private.discounts.GetCouponsRequest', null, global);
goog.exportSymbol('proto.core.private.discounts.GetCouponsResponse', null, global);
goog.exportSymbol('proto.core.private.discounts.GetDiscountRequest', null, global);
goog.exportSymbol('proto.core.private.discounts.GetDiscountResponse', null, global);
goog.exportSymbol('proto.core.private.discounts.GetDiscountResponse.DiscountModelCase', null, global);
goog.exportSymbol('proto.core.private.discounts.GetDiscountResponse.MonetaryModel', null, global);
goog.exportSymbol('proto.core.private.discounts.GetDiscountResponse.PercentageModel', null, global);
goog.exportSymbol('proto.core.private.discounts.GetDiscountsRequest', null, global);
goog.exportSymbol('proto.core.private.discounts.GetDiscountsResponse', null, global);
goog.exportSymbol('proto.core.private.discounts.GetMonetaryCurrencyPrices', null, global);
goog.exportSymbol('proto.core.private.discounts.GetPlanCurrenciesRequest', null, global);
goog.exportSymbol('proto.core.private.discounts.GetPlanCurrenciesResponse', null, global);
goog.exportSymbol('proto.core.private.discounts.MonetaryCurrencyPrices', null, global);
goog.exportSymbol('proto.core.private.discounts.UpdateDiscountRequest', null, global);
goog.exportSymbol('proto.core.private.discounts.UpdateDiscountRequest.DiscountModelCase', null, global);
goog.exportSymbol('proto.core.private.discounts.UpdateDiscountRequest.MonetaryModel', null, global);
goog.exportSymbol('proto.core.private.discounts.UpdateDiscountRequest.PercentageModel', null, global);
goog.exportSymbol('proto.core.private.discounts.UpdateDiscountResponse', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.core.private.discounts.DeleteDiscountRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.core.private.discounts.DeleteDiscountRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.core.private.discounts.DeleteDiscountRequest.displayName = 'proto.core.private.discounts.DeleteDiscountRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.core.private.discounts.DeleteDiscountResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.core.private.discounts.DeleteDiscountResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.core.private.discounts.DeleteDiscountResponse.displayName = 'proto.core.private.discounts.DeleteDiscountResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.core.private.discounts.GetPlanCurrenciesRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.core.private.discounts.GetPlanCurrenciesRequest.repeatedFields_, null);
};
goog.inherits(proto.core.private.discounts.GetPlanCurrenciesRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.core.private.discounts.GetPlanCurrenciesRequest.displayName = 'proto.core.private.discounts.GetPlanCurrenciesRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.core.private.discounts.GetPlanCurrenciesResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.core.private.discounts.GetPlanCurrenciesResponse.repeatedFields_, null);
};
goog.inherits(proto.core.private.discounts.GetPlanCurrenciesResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.core.private.discounts.GetPlanCurrenciesResponse.displayName = 'proto.core.private.discounts.GetPlanCurrenciesResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.core.private.discounts.Currency = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.core.private.discounts.Currency, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.core.private.discounts.Currency.displayName = 'proto.core.private.discounts.Currency';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.core.private.discounts.UpdateDiscountRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.core.private.discounts.UpdateDiscountRequest.repeatedFields_, proto.core.private.discounts.UpdateDiscountRequest.oneofGroups_);
};
goog.inherits(proto.core.private.discounts.UpdateDiscountRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.core.private.discounts.UpdateDiscountRequest.displayName = 'proto.core.private.discounts.UpdateDiscountRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.core.private.discounts.UpdateDiscountRequest.PercentageModel = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.core.private.discounts.UpdateDiscountRequest.PercentageModel, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.core.private.discounts.UpdateDiscountRequest.PercentageModel.displayName = 'proto.core.private.discounts.UpdateDiscountRequest.PercentageModel';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.core.private.discounts.UpdateDiscountRequest.MonetaryModel = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.core.private.discounts.UpdateDiscountRequest.MonetaryModel.repeatedFields_, null);
};
goog.inherits(proto.core.private.discounts.UpdateDiscountRequest.MonetaryModel, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.core.private.discounts.UpdateDiscountRequest.MonetaryModel.displayName = 'proto.core.private.discounts.UpdateDiscountRequest.MonetaryModel';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.core.private.discounts.UpdateDiscountResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.core.private.discounts.UpdateDiscountResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.core.private.discounts.UpdateDiscountResponse.displayName = 'proto.core.private.discounts.UpdateDiscountResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.core.private.discounts.GetCouponsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.core.private.discounts.GetCouponsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.core.private.discounts.GetCouponsRequest.displayName = 'proto.core.private.discounts.GetCouponsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.core.private.discounts.GetCouponsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.core.private.discounts.GetCouponsResponse.repeatedFields_, null);
};
goog.inherits(proto.core.private.discounts.GetCouponsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.core.private.discounts.GetCouponsResponse.displayName = 'proto.core.private.discounts.GetCouponsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.core.private.discounts.Coupon = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.core.private.discounts.Coupon.oneofGroups_);
};
goog.inherits(proto.core.private.discounts.Coupon, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.core.private.discounts.Coupon.displayName = 'proto.core.private.discounts.Coupon';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.core.private.discounts.FixedTermDiscountLength = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.core.private.discounts.FixedTermDiscountLength, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.core.private.discounts.FixedTermDiscountLength.displayName = 'proto.core.private.discounts.FixedTermDiscountLength';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.core.private.discounts.GetDiscountsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.core.private.discounts.GetDiscountsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.core.private.discounts.GetDiscountsRequest.displayName = 'proto.core.private.discounts.GetDiscountsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.core.private.discounts.GetDiscountsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.core.private.discounts.GetDiscountsResponse.repeatedFields_, null);
};
goog.inherits(proto.core.private.discounts.GetDiscountsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.core.private.discounts.GetDiscountsResponse.displayName = 'proto.core.private.discounts.GetDiscountsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.core.private.discounts.GetDiscountRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.core.private.discounts.GetDiscountRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.core.private.discounts.GetDiscountRequest.displayName = 'proto.core.private.discounts.GetDiscountRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.core.private.discounts.GetDiscountResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.core.private.discounts.GetDiscountResponse.repeatedFields_, proto.core.private.discounts.GetDiscountResponse.oneofGroups_);
};
goog.inherits(proto.core.private.discounts.GetDiscountResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.core.private.discounts.GetDiscountResponse.displayName = 'proto.core.private.discounts.GetDiscountResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.core.private.discounts.GetDiscountResponse.PercentageModel = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.core.private.discounts.GetDiscountResponse.PercentageModel, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.core.private.discounts.GetDiscountResponse.PercentageModel.displayName = 'proto.core.private.discounts.GetDiscountResponse.PercentageModel';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.core.private.discounts.GetDiscountResponse.MonetaryModel = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.core.private.discounts.GetDiscountResponse.MonetaryModel.repeatedFields_, null);
};
goog.inherits(proto.core.private.discounts.GetDiscountResponse.MonetaryModel, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.core.private.discounts.GetDiscountResponse.MonetaryModel.displayName = 'proto.core.private.discounts.GetDiscountResponse.MonetaryModel';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.core.private.discounts.Discount = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.core.private.discounts.Discount.oneofGroups_);
};
goog.inherits(proto.core.private.discounts.Discount, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.core.private.discounts.Discount.displayName = 'proto.core.private.discounts.Discount';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.core.private.discounts.Discount.MonetaryModel = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.core.private.discounts.Discount.MonetaryModel.repeatedFields_, null);
};
goog.inherits(proto.core.private.discounts.Discount.MonetaryModel, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.core.private.discounts.Discount.MonetaryModel.displayName = 'proto.core.private.discounts.Discount.MonetaryModel';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.core.private.discounts.Discount.PercentageModel = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.core.private.discounts.Discount.PercentageModel, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.core.private.discounts.Discount.PercentageModel.displayName = 'proto.core.private.discounts.Discount.PercentageModel';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.core.private.discounts.GetMonetaryCurrencyPrices = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.core.private.discounts.GetMonetaryCurrencyPrices, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.core.private.discounts.GetMonetaryCurrencyPrices.displayName = 'proto.core.private.discounts.GetMonetaryCurrencyPrices';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.core.private.discounts.CreateDiscountRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.core.private.discounts.CreateDiscountRequest.repeatedFields_, proto.core.private.discounts.CreateDiscountRequest.oneofGroups_);
};
goog.inherits(proto.core.private.discounts.CreateDiscountRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.core.private.discounts.CreateDiscountRequest.displayName = 'proto.core.private.discounts.CreateDiscountRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.core.private.discounts.CreateDiscountRequest.PercentageModel = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.core.private.discounts.CreateDiscountRequest.PercentageModel, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.core.private.discounts.CreateDiscountRequest.PercentageModel.displayName = 'proto.core.private.discounts.CreateDiscountRequest.PercentageModel';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.core.private.discounts.CreateDiscountRequest.MonetaryModel = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.core.private.discounts.CreateDiscountRequest.MonetaryModel.repeatedFields_, null);
};
goog.inherits(proto.core.private.discounts.CreateDiscountRequest.MonetaryModel, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.core.private.discounts.CreateDiscountRequest.MonetaryModel.displayName = 'proto.core.private.discounts.CreateDiscountRequest.MonetaryModel';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.core.private.discounts.MonetaryCurrencyPrices = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.core.private.discounts.MonetaryCurrencyPrices, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.core.private.discounts.MonetaryCurrencyPrices.displayName = 'proto.core.private.discounts.MonetaryCurrencyPrices';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.core.private.discounts.CreateDiscountResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.core.private.discounts.CreateDiscountResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.core.private.discounts.CreateDiscountResponse.displayName = 'proto.core.private.discounts.CreateDiscountResponse';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.core.private.discounts.DeleteDiscountRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.core.private.discounts.DeleteDiscountRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.core.private.discounts.DeleteDiscountRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.core.private.discounts.DeleteDiscountRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    companyId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    discountId: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.core.private.discounts.DeleteDiscountRequest}
 */
proto.core.private.discounts.DeleteDiscountRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.core.private.discounts.DeleteDiscountRequest;
  return proto.core.private.discounts.DeleteDiscountRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.core.private.discounts.DeleteDiscountRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.core.private.discounts.DeleteDiscountRequest}
 */
proto.core.private.discounts.DeleteDiscountRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setCompanyId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setDiscountId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.core.private.discounts.DeleteDiscountRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.core.private.discounts.DeleteDiscountRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.core.private.discounts.DeleteDiscountRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.core.private.discounts.DeleteDiscountRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCompanyId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getDiscountId();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
};


/**
 * optional int32 company_id = 1;
 * @return {number}
 */
proto.core.private.discounts.DeleteDiscountRequest.prototype.getCompanyId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.core.private.discounts.DeleteDiscountRequest} returns this
 */
proto.core.private.discounts.DeleteDiscountRequest.prototype.setCompanyId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int32 discount_id = 2;
 * @return {number}
 */
proto.core.private.discounts.DeleteDiscountRequest.prototype.getDiscountId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.core.private.discounts.DeleteDiscountRequest} returns this
 */
proto.core.private.discounts.DeleteDiscountRequest.prototype.setDiscountId = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.core.private.discounts.DeleteDiscountResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.core.private.discounts.DeleteDiscountResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.core.private.discounts.DeleteDiscountResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.core.private.discounts.DeleteDiscountResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    affectedCustomersCount: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.core.private.discounts.DeleteDiscountResponse}
 */
proto.core.private.discounts.DeleteDiscountResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.core.private.discounts.DeleteDiscountResponse;
  return proto.core.private.discounts.DeleteDiscountResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.core.private.discounts.DeleteDiscountResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.core.private.discounts.DeleteDiscountResponse}
 */
proto.core.private.discounts.DeleteDiscountResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setAffectedCustomersCount(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.core.private.discounts.DeleteDiscountResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.core.private.discounts.DeleteDiscountResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.core.private.discounts.DeleteDiscountResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.core.private.discounts.DeleteDiscountResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAffectedCustomersCount();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
};


/**
 * optional int32 affected_customers_count = 1;
 * @return {number}
 */
proto.core.private.discounts.DeleteDiscountResponse.prototype.getAffectedCustomersCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.core.private.discounts.DeleteDiscountResponse} returns this
 */
proto.core.private.discounts.DeleteDiscountResponse.prototype.setAffectedCustomersCount = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.core.private.discounts.GetPlanCurrenciesRequest.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.core.private.discounts.GetPlanCurrenciesRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.core.private.discounts.GetPlanCurrenciesRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.core.private.discounts.GetPlanCurrenciesRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.core.private.discounts.GetPlanCurrenciesRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    companyDomain: jspb.Message.getFieldWithDefault(msg, 1, ""),
    planIdsList: (f = jspb.Message.getRepeatedField(msg, 2)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.core.private.discounts.GetPlanCurrenciesRequest}
 */
proto.core.private.discounts.GetPlanCurrenciesRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.core.private.discounts.GetPlanCurrenciesRequest;
  return proto.core.private.discounts.GetPlanCurrenciesRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.core.private.discounts.GetPlanCurrenciesRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.core.private.discounts.GetPlanCurrenciesRequest}
 */
proto.core.private.discounts.GetPlanCurrenciesRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setCompanyDomain(value);
      break;
    case 2:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedInt32() : [reader.readInt32()]);
      for (var i = 0; i < values.length; i++) {
        msg.addPlanIds(values[i]);
      }
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.core.private.discounts.GetPlanCurrenciesRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.core.private.discounts.GetPlanCurrenciesRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.core.private.discounts.GetPlanCurrenciesRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.core.private.discounts.GetPlanCurrenciesRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCompanyDomain();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getPlanIdsList();
  if (f.length > 0) {
    writer.writePackedInt32(
      2,
      f
    );
  }
};


/**
 * optional string company_domain = 1;
 * @return {string}
 */
proto.core.private.discounts.GetPlanCurrenciesRequest.prototype.getCompanyDomain = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.core.private.discounts.GetPlanCurrenciesRequest} returns this
 */
proto.core.private.discounts.GetPlanCurrenciesRequest.prototype.setCompanyDomain = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * repeated int32 plan_ids = 2;
 * @return {!Array<number>}
 */
proto.core.private.discounts.GetPlanCurrenciesRequest.prototype.getPlanIdsList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 2));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.core.private.discounts.GetPlanCurrenciesRequest} returns this
 */
proto.core.private.discounts.GetPlanCurrenciesRequest.prototype.setPlanIdsList = function(value) {
  return jspb.Message.setField(this, 2, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.core.private.discounts.GetPlanCurrenciesRequest} returns this
 */
proto.core.private.discounts.GetPlanCurrenciesRequest.prototype.addPlanIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 2, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.core.private.discounts.GetPlanCurrenciesRequest} returns this
 */
proto.core.private.discounts.GetPlanCurrenciesRequest.prototype.clearPlanIdsList = function() {
  return this.setPlanIdsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.core.private.discounts.GetPlanCurrenciesResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.core.private.discounts.GetPlanCurrenciesResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.core.private.discounts.GetPlanCurrenciesResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.core.private.discounts.GetPlanCurrenciesResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.core.private.discounts.GetPlanCurrenciesResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    currenciesList: jspb.Message.toObjectList(msg.getCurrenciesList(),
    proto.core.private.discounts.Currency.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.core.private.discounts.GetPlanCurrenciesResponse}
 */
proto.core.private.discounts.GetPlanCurrenciesResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.core.private.discounts.GetPlanCurrenciesResponse;
  return proto.core.private.discounts.GetPlanCurrenciesResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.core.private.discounts.GetPlanCurrenciesResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.core.private.discounts.GetPlanCurrenciesResponse}
 */
proto.core.private.discounts.GetPlanCurrenciesResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.core.private.discounts.Currency;
      reader.readMessage(value,proto.core.private.discounts.Currency.deserializeBinaryFromReader);
      msg.addCurrencies(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.core.private.discounts.GetPlanCurrenciesResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.core.private.discounts.GetPlanCurrenciesResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.core.private.discounts.GetPlanCurrenciesResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.core.private.discounts.GetPlanCurrenciesResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCurrenciesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.core.private.discounts.Currency.serializeBinaryToWriter
    );
  }
};


/**
 * repeated Currency currencies = 1;
 * @return {!Array<!proto.core.private.discounts.Currency>}
 */
proto.core.private.discounts.GetPlanCurrenciesResponse.prototype.getCurrenciesList = function() {
  return /** @type{!Array<!proto.core.private.discounts.Currency>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.core.private.discounts.Currency, 1));
};


/**
 * @param {!Array<!proto.core.private.discounts.Currency>} value
 * @return {!proto.core.private.discounts.GetPlanCurrenciesResponse} returns this
*/
proto.core.private.discounts.GetPlanCurrenciesResponse.prototype.setCurrenciesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.core.private.discounts.Currency=} opt_value
 * @param {number=} opt_index
 * @return {!proto.core.private.discounts.Currency}
 */
proto.core.private.discounts.GetPlanCurrenciesResponse.prototype.addCurrencies = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.core.private.discounts.Currency, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.core.private.discounts.GetPlanCurrenciesResponse} returns this
 */
proto.core.private.discounts.GetPlanCurrenciesResponse.prototype.clearCurrenciesList = function() {
  return this.setCurrenciesList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.core.private.discounts.Currency.prototype.toObject = function(opt_includeInstance) {
  return proto.core.private.discounts.Currency.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.core.private.discounts.Currency} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.core.private.discounts.Currency.toObject = function(includeInstance, msg) {
  var f, obj = {
    englishName: jspb.Message.getFieldWithDefault(msg, 1, ""),
    iso3Code: jspb.Message.getFieldWithDefault(msg, 2, ""),
    symbol: jspb.Message.getFieldWithDefault(msg, 3, ""),
    hasDecimal: jspb.Message.getBooleanFieldWithDefault(msg, 4, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.core.private.discounts.Currency}
 */
proto.core.private.discounts.Currency.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.core.private.discounts.Currency;
  return proto.core.private.discounts.Currency.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.core.private.discounts.Currency} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.core.private.discounts.Currency}
 */
proto.core.private.discounts.Currency.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setEnglishName(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setIso3Code(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setSymbol(value);
      break;
    case 4:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setHasDecimal(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.core.private.discounts.Currency.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.core.private.discounts.Currency.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.core.private.discounts.Currency} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.core.private.discounts.Currency.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getEnglishName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getIso3Code();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getSymbol();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getHasDecimal();
  if (f) {
    writer.writeBool(
      4,
      f
    );
  }
};


/**
 * optional string english_name = 1;
 * @return {string}
 */
proto.core.private.discounts.Currency.prototype.getEnglishName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.core.private.discounts.Currency} returns this
 */
proto.core.private.discounts.Currency.prototype.setEnglishName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string iso_3_code = 2;
 * @return {string}
 */
proto.core.private.discounts.Currency.prototype.getIso3Code = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.core.private.discounts.Currency} returns this
 */
proto.core.private.discounts.Currency.prototype.setIso3Code = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string symbol = 3;
 * @return {string}
 */
proto.core.private.discounts.Currency.prototype.getSymbol = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.core.private.discounts.Currency} returns this
 */
proto.core.private.discounts.Currency.prototype.setSymbol = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional bool has_decimal = 4;
 * @return {boolean}
 */
proto.core.private.discounts.Currency.prototype.getHasDecimal = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 4, false));
};


/**
 * @param {boolean} value
 * @return {!proto.core.private.discounts.Currency} returns this
 */
proto.core.private.discounts.Currency.prototype.setHasDecimal = function(value) {
  return jspb.Message.setProto3BooleanField(this, 4, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.core.private.discounts.UpdateDiscountRequest.repeatedFields_ = [6];

/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.core.private.discounts.UpdateDiscountRequest.oneofGroups_ = [[8,9]];

/**
 * @enum {number}
 */
proto.core.private.discounts.UpdateDiscountRequest.DiscountModelCase = {
  DISCOUNT_MODEL_NOT_SET: 0,
  PERCENTAGE: 8,
  MONETARY: 9
};

/**
 * @return {proto.core.private.discounts.UpdateDiscountRequest.DiscountModelCase}
 */
proto.core.private.discounts.UpdateDiscountRequest.prototype.getDiscountModelCase = function() {
  return /** @type {proto.core.private.discounts.UpdateDiscountRequest.DiscountModelCase} */(jspb.Message.computeOneofCase(this, proto.core.private.discounts.UpdateDiscountRequest.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.core.private.discounts.UpdateDiscountRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.core.private.discounts.UpdateDiscountRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.core.private.discounts.UpdateDiscountRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.core.private.discounts.UpdateDiscountRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    companyId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    discountId: jspb.Message.getFieldWithDefault(msg, 2, 0),
    name: jspb.Message.getFieldWithDefault(msg, 3, ""),
    isAllowedWithOtherDiscounts: jspb.Message.getBooleanFieldWithDefault(msg, 4, false),
    allowAllPlans: jspb.Message.getBooleanFieldWithDefault(msg, 5, false),
    planIdsList: (f = jspb.Message.getRepeatedField(msg, 6)) == null ? undefined : f,
    deductionType: jspb.Message.getFieldWithDefault(msg, 7, 0),
    percentage: (f = msg.getPercentage()) && proto.core.private.discounts.UpdateDiscountRequest.PercentageModel.toObject(includeInstance, f),
    monetary: (f = msg.getMonetary()) && proto.core.private.discounts.UpdateDiscountRequest.MonetaryModel.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.core.private.discounts.UpdateDiscountRequest}
 */
proto.core.private.discounts.UpdateDiscountRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.core.private.discounts.UpdateDiscountRequest;
  return proto.core.private.discounts.UpdateDiscountRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.core.private.discounts.UpdateDiscountRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.core.private.discounts.UpdateDiscountRequest}
 */
proto.core.private.discounts.UpdateDiscountRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setCompanyId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setDiscountId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 4:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsAllowedWithOtherDiscounts(value);
      break;
    case 5:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setAllowAllPlans(value);
      break;
    case 6:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedInt32() : [reader.readInt32()]);
      for (var i = 0; i < values.length; i++) {
        msg.addPlanIds(values[i]);
      }
      break;
    case 7:
      var value = /** @type {!proto.core.private.discounts.DeductionType} */ (reader.readEnum());
      msg.setDeductionType(value);
      break;
    case 8:
      var value = new proto.core.private.discounts.UpdateDiscountRequest.PercentageModel;
      reader.readMessage(value,proto.core.private.discounts.UpdateDiscountRequest.PercentageModel.deserializeBinaryFromReader);
      msg.setPercentage(value);
      break;
    case 9:
      var value = new proto.core.private.discounts.UpdateDiscountRequest.MonetaryModel;
      reader.readMessage(value,proto.core.private.discounts.UpdateDiscountRequest.MonetaryModel.deserializeBinaryFromReader);
      msg.setMonetary(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.core.private.discounts.UpdateDiscountRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.core.private.discounts.UpdateDiscountRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.core.private.discounts.UpdateDiscountRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.core.private.discounts.UpdateDiscountRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCompanyId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getDiscountId();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getIsAllowedWithOtherDiscounts();
  if (f) {
    writer.writeBool(
      4,
      f
    );
  }
  f = message.getAllowAllPlans();
  if (f) {
    writer.writeBool(
      5,
      f
    );
  }
  f = message.getPlanIdsList();
  if (f.length > 0) {
    writer.writePackedInt32(
      6,
      f
    );
  }
  f = message.getDeductionType();
  if (f !== 0.0) {
    writer.writeEnum(
      7,
      f
    );
  }
  f = message.getPercentage();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      proto.core.private.discounts.UpdateDiscountRequest.PercentageModel.serializeBinaryToWriter
    );
  }
  f = message.getMonetary();
  if (f != null) {
    writer.writeMessage(
      9,
      f,
      proto.core.private.discounts.UpdateDiscountRequest.MonetaryModel.serializeBinaryToWriter
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.core.private.discounts.UpdateDiscountRequest.PercentageModel.prototype.toObject = function(opt_includeInstance) {
  return proto.core.private.discounts.UpdateDiscountRequest.PercentageModel.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.core.private.discounts.UpdateDiscountRequest.PercentageModel} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.core.private.discounts.UpdateDiscountRequest.PercentageModel.toObject = function(includeInstance, msg) {
  var f, obj = {
    percentage: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.core.private.discounts.UpdateDiscountRequest.PercentageModel}
 */
proto.core.private.discounts.UpdateDiscountRequest.PercentageModel.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.core.private.discounts.UpdateDiscountRequest.PercentageModel;
  return proto.core.private.discounts.UpdateDiscountRequest.PercentageModel.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.core.private.discounts.UpdateDiscountRequest.PercentageModel} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.core.private.discounts.UpdateDiscountRequest.PercentageModel}
 */
proto.core.private.discounts.UpdateDiscountRequest.PercentageModel.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPercentage(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.core.private.discounts.UpdateDiscountRequest.PercentageModel.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.core.private.discounts.UpdateDiscountRequest.PercentageModel.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.core.private.discounts.UpdateDiscountRequest.PercentageModel} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.core.private.discounts.UpdateDiscountRequest.PercentageModel.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPercentage();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
};


/**
 * optional int32 percentage = 1;
 * @return {number}
 */
proto.core.private.discounts.UpdateDiscountRequest.PercentageModel.prototype.getPercentage = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.core.private.discounts.UpdateDiscountRequest.PercentageModel} returns this
 */
proto.core.private.discounts.UpdateDiscountRequest.PercentageModel.prototype.setPercentage = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.core.private.discounts.UpdateDiscountRequest.MonetaryModel.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.core.private.discounts.UpdateDiscountRequest.MonetaryModel.prototype.toObject = function(opt_includeInstance) {
  return proto.core.private.discounts.UpdateDiscountRequest.MonetaryModel.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.core.private.discounts.UpdateDiscountRequest.MonetaryModel} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.core.private.discounts.UpdateDiscountRequest.MonetaryModel.toObject = function(includeInstance, msg) {
  var f, obj = {
    pricesList: jspb.Message.toObjectList(msg.getPricesList(),
    proto.core.private.discounts.MonetaryCurrencyPrices.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.core.private.discounts.UpdateDiscountRequest.MonetaryModel}
 */
proto.core.private.discounts.UpdateDiscountRequest.MonetaryModel.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.core.private.discounts.UpdateDiscountRequest.MonetaryModel;
  return proto.core.private.discounts.UpdateDiscountRequest.MonetaryModel.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.core.private.discounts.UpdateDiscountRequest.MonetaryModel} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.core.private.discounts.UpdateDiscountRequest.MonetaryModel}
 */
proto.core.private.discounts.UpdateDiscountRequest.MonetaryModel.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.core.private.discounts.MonetaryCurrencyPrices;
      reader.readMessage(value,proto.core.private.discounts.MonetaryCurrencyPrices.deserializeBinaryFromReader);
      msg.addPrices(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.core.private.discounts.UpdateDiscountRequest.MonetaryModel.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.core.private.discounts.UpdateDiscountRequest.MonetaryModel.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.core.private.discounts.UpdateDiscountRequest.MonetaryModel} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.core.private.discounts.UpdateDiscountRequest.MonetaryModel.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPricesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.core.private.discounts.MonetaryCurrencyPrices.serializeBinaryToWriter
    );
  }
};


/**
 * repeated MonetaryCurrencyPrices prices = 1;
 * @return {!Array<!proto.core.private.discounts.MonetaryCurrencyPrices>}
 */
proto.core.private.discounts.UpdateDiscountRequest.MonetaryModel.prototype.getPricesList = function() {
  return /** @type{!Array<!proto.core.private.discounts.MonetaryCurrencyPrices>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.core.private.discounts.MonetaryCurrencyPrices, 1));
};


/**
 * @param {!Array<!proto.core.private.discounts.MonetaryCurrencyPrices>} value
 * @return {!proto.core.private.discounts.UpdateDiscountRequest.MonetaryModel} returns this
*/
proto.core.private.discounts.UpdateDiscountRequest.MonetaryModel.prototype.setPricesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.core.private.discounts.MonetaryCurrencyPrices=} opt_value
 * @param {number=} opt_index
 * @return {!proto.core.private.discounts.MonetaryCurrencyPrices}
 */
proto.core.private.discounts.UpdateDiscountRequest.MonetaryModel.prototype.addPrices = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.core.private.discounts.MonetaryCurrencyPrices, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.core.private.discounts.UpdateDiscountRequest.MonetaryModel} returns this
 */
proto.core.private.discounts.UpdateDiscountRequest.MonetaryModel.prototype.clearPricesList = function() {
  return this.setPricesList([]);
};


/**
 * optional int32 company_id = 1;
 * @return {number}
 */
proto.core.private.discounts.UpdateDiscountRequest.prototype.getCompanyId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.core.private.discounts.UpdateDiscountRequest} returns this
 */
proto.core.private.discounts.UpdateDiscountRequest.prototype.setCompanyId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int32 discount_id = 2;
 * @return {number}
 */
proto.core.private.discounts.UpdateDiscountRequest.prototype.getDiscountId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.core.private.discounts.UpdateDiscountRequest} returns this
 */
proto.core.private.discounts.UpdateDiscountRequest.prototype.setDiscountId = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional string name = 3;
 * @return {string}
 */
proto.core.private.discounts.UpdateDiscountRequest.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.core.private.discounts.UpdateDiscountRequest} returns this
 */
proto.core.private.discounts.UpdateDiscountRequest.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional bool is_allowed_with_other_discounts = 4;
 * @return {boolean}
 */
proto.core.private.discounts.UpdateDiscountRequest.prototype.getIsAllowedWithOtherDiscounts = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 4, false));
};


/**
 * @param {boolean} value
 * @return {!proto.core.private.discounts.UpdateDiscountRequest} returns this
 */
proto.core.private.discounts.UpdateDiscountRequest.prototype.setIsAllowedWithOtherDiscounts = function(value) {
  return jspb.Message.setProto3BooleanField(this, 4, value);
};


/**
 * optional bool allow_all_plans = 5;
 * @return {boolean}
 */
proto.core.private.discounts.UpdateDiscountRequest.prototype.getAllowAllPlans = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 5, false));
};


/**
 * @param {boolean} value
 * @return {!proto.core.private.discounts.UpdateDiscountRequest} returns this
 */
proto.core.private.discounts.UpdateDiscountRequest.prototype.setAllowAllPlans = function(value) {
  return jspb.Message.setProto3BooleanField(this, 5, value);
};


/**
 * repeated int32 plan_ids = 6;
 * @return {!Array<number>}
 */
proto.core.private.discounts.UpdateDiscountRequest.prototype.getPlanIdsList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 6));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.core.private.discounts.UpdateDiscountRequest} returns this
 */
proto.core.private.discounts.UpdateDiscountRequest.prototype.setPlanIdsList = function(value) {
  return jspb.Message.setField(this, 6, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.core.private.discounts.UpdateDiscountRequest} returns this
 */
proto.core.private.discounts.UpdateDiscountRequest.prototype.addPlanIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 6, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.core.private.discounts.UpdateDiscountRequest} returns this
 */
proto.core.private.discounts.UpdateDiscountRequest.prototype.clearPlanIdsList = function() {
  return this.setPlanIdsList([]);
};


/**
 * optional DeductionType deduction_type = 7;
 * @return {!proto.core.private.discounts.DeductionType}
 */
proto.core.private.discounts.UpdateDiscountRequest.prototype.getDeductionType = function() {
  return /** @type {!proto.core.private.discounts.DeductionType} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {!proto.core.private.discounts.DeductionType} value
 * @return {!proto.core.private.discounts.UpdateDiscountRequest} returns this
 */
proto.core.private.discounts.UpdateDiscountRequest.prototype.setDeductionType = function(value) {
  return jspb.Message.setProto3EnumField(this, 7, value);
};


/**
 * optional PercentageModel percentage = 8;
 * @return {?proto.core.private.discounts.UpdateDiscountRequest.PercentageModel}
 */
proto.core.private.discounts.UpdateDiscountRequest.prototype.getPercentage = function() {
  return /** @type{?proto.core.private.discounts.UpdateDiscountRequest.PercentageModel} */ (
    jspb.Message.getWrapperField(this, proto.core.private.discounts.UpdateDiscountRequest.PercentageModel, 8));
};


/**
 * @param {?proto.core.private.discounts.UpdateDiscountRequest.PercentageModel|undefined} value
 * @return {!proto.core.private.discounts.UpdateDiscountRequest} returns this
*/
proto.core.private.discounts.UpdateDiscountRequest.prototype.setPercentage = function(value) {
  return jspb.Message.setOneofWrapperField(this, 8, proto.core.private.discounts.UpdateDiscountRequest.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.core.private.discounts.UpdateDiscountRequest} returns this
 */
proto.core.private.discounts.UpdateDiscountRequest.prototype.clearPercentage = function() {
  return this.setPercentage(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.core.private.discounts.UpdateDiscountRequest.prototype.hasPercentage = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * optional MonetaryModel monetary = 9;
 * @return {?proto.core.private.discounts.UpdateDiscountRequest.MonetaryModel}
 */
proto.core.private.discounts.UpdateDiscountRequest.prototype.getMonetary = function() {
  return /** @type{?proto.core.private.discounts.UpdateDiscountRequest.MonetaryModel} */ (
    jspb.Message.getWrapperField(this, proto.core.private.discounts.UpdateDiscountRequest.MonetaryModel, 9));
};


/**
 * @param {?proto.core.private.discounts.UpdateDiscountRequest.MonetaryModel|undefined} value
 * @return {!proto.core.private.discounts.UpdateDiscountRequest} returns this
*/
proto.core.private.discounts.UpdateDiscountRequest.prototype.setMonetary = function(value) {
  return jspb.Message.setOneofWrapperField(this, 9, proto.core.private.discounts.UpdateDiscountRequest.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.core.private.discounts.UpdateDiscountRequest} returns this
 */
proto.core.private.discounts.UpdateDiscountRequest.prototype.clearMonetary = function() {
  return this.setMonetary(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.core.private.discounts.UpdateDiscountRequest.prototype.hasMonetary = function() {
  return jspb.Message.getField(this, 9) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.core.private.discounts.UpdateDiscountResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.core.private.discounts.UpdateDiscountResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.core.private.discounts.UpdateDiscountResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.core.private.discounts.UpdateDiscountResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    isSuccess: jspb.Message.getBooleanFieldWithDefault(msg, 1, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.core.private.discounts.UpdateDiscountResponse}
 */
proto.core.private.discounts.UpdateDiscountResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.core.private.discounts.UpdateDiscountResponse;
  return proto.core.private.discounts.UpdateDiscountResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.core.private.discounts.UpdateDiscountResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.core.private.discounts.UpdateDiscountResponse}
 */
proto.core.private.discounts.UpdateDiscountResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsSuccess(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.core.private.discounts.UpdateDiscountResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.core.private.discounts.UpdateDiscountResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.core.private.discounts.UpdateDiscountResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.core.private.discounts.UpdateDiscountResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getIsSuccess();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
};


/**
 * optional bool is_success = 1;
 * @return {boolean}
 */
proto.core.private.discounts.UpdateDiscountResponse.prototype.getIsSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.core.private.discounts.UpdateDiscountResponse} returns this
 */
proto.core.private.discounts.UpdateDiscountResponse.prototype.setIsSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.core.private.discounts.GetCouponsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.core.private.discounts.GetCouponsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.core.private.discounts.GetCouponsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.core.private.discounts.GetCouponsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    companyId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    discountId: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.core.private.discounts.GetCouponsRequest}
 */
proto.core.private.discounts.GetCouponsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.core.private.discounts.GetCouponsRequest;
  return proto.core.private.discounts.GetCouponsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.core.private.discounts.GetCouponsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.core.private.discounts.GetCouponsRequest}
 */
proto.core.private.discounts.GetCouponsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setCompanyId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setDiscountId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.core.private.discounts.GetCouponsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.core.private.discounts.GetCouponsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.core.private.discounts.GetCouponsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.core.private.discounts.GetCouponsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCompanyId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getDiscountId();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
};


/**
 * optional int32 company_id = 1;
 * @return {number}
 */
proto.core.private.discounts.GetCouponsRequest.prototype.getCompanyId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.core.private.discounts.GetCouponsRequest} returns this
 */
proto.core.private.discounts.GetCouponsRequest.prototype.setCompanyId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int32 discount_id = 2;
 * @return {number}
 */
proto.core.private.discounts.GetCouponsRequest.prototype.getDiscountId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.core.private.discounts.GetCouponsRequest} returns this
 */
proto.core.private.discounts.GetCouponsRequest.prototype.setDiscountId = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.core.private.discounts.GetCouponsResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.core.private.discounts.GetCouponsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.core.private.discounts.GetCouponsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.core.private.discounts.GetCouponsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.core.private.discounts.GetCouponsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    couponsList: jspb.Message.toObjectList(msg.getCouponsList(),
    proto.core.private.discounts.Coupon.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.core.private.discounts.GetCouponsResponse}
 */
proto.core.private.discounts.GetCouponsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.core.private.discounts.GetCouponsResponse;
  return proto.core.private.discounts.GetCouponsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.core.private.discounts.GetCouponsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.core.private.discounts.GetCouponsResponse}
 */
proto.core.private.discounts.GetCouponsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.core.private.discounts.Coupon;
      reader.readMessage(value,proto.core.private.discounts.Coupon.deserializeBinaryFromReader);
      msg.addCoupons(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.core.private.discounts.GetCouponsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.core.private.discounts.GetCouponsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.core.private.discounts.GetCouponsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.core.private.discounts.GetCouponsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCouponsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.core.private.discounts.Coupon.serializeBinaryToWriter
    );
  }
};


/**
 * repeated Coupon coupons = 1;
 * @return {!Array<!proto.core.private.discounts.Coupon>}
 */
proto.core.private.discounts.GetCouponsResponse.prototype.getCouponsList = function() {
  return /** @type{!Array<!proto.core.private.discounts.Coupon>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.core.private.discounts.Coupon, 1));
};


/**
 * @param {!Array<!proto.core.private.discounts.Coupon>} value
 * @return {!proto.core.private.discounts.GetCouponsResponse} returns this
*/
proto.core.private.discounts.GetCouponsResponse.prototype.setCouponsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.core.private.discounts.Coupon=} opt_value
 * @param {number=} opt_index
 * @return {!proto.core.private.discounts.Coupon}
 */
proto.core.private.discounts.GetCouponsResponse.prototype.addCoupons = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.core.private.discounts.Coupon, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.core.private.discounts.GetCouponsResponse} returns this
 */
proto.core.private.discounts.GetCouponsResponse.prototype.clearCouponsList = function() {
  return this.setCouponsList([]);
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.core.private.discounts.Coupon.oneofGroups_ = [[6,7],[8,9]];

/**
 * @enum {number}
 */
proto.core.private.discounts.Coupon.ExpirationCase = {
  EXPIRATION_NOT_SET: 0,
  IS_INFINITE: 6,
  EXPIRES_ON: 7
};

/**
 * @return {proto.core.private.discounts.Coupon.ExpirationCase}
 */
proto.core.private.discounts.Coupon.prototype.getExpirationCase = function() {
  return /** @type {proto.core.private.discounts.Coupon.ExpirationCase} */(jspb.Message.computeOneofCase(this, proto.core.private.discounts.Coupon.oneofGroups_[0]));
};

/**
 * @enum {number}
 */
proto.core.private.discounts.Coupon.DiscountLengthCase = {
  DISCOUNT_LENGTH_NOT_SET: 0,
  IS_LIFE_TIME: 8,
  FIXED_TERM: 9
};

/**
 * @return {proto.core.private.discounts.Coupon.DiscountLengthCase}
 */
proto.core.private.discounts.Coupon.prototype.getDiscountLengthCase = function() {
  return /** @type {proto.core.private.discounts.Coupon.DiscountLengthCase} */(jspb.Message.computeOneofCase(this, proto.core.private.discounts.Coupon.oneofGroups_[1]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.core.private.discounts.Coupon.prototype.toObject = function(opt_includeInstance) {
  return proto.core.private.discounts.Coupon.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.core.private.discounts.Coupon} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.core.private.discounts.Coupon.toObject = function(includeInstance, msg) {
  var f, obj = {
    couponId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    couponType: jspb.Message.getFieldWithDefault(msg, 3, 0),
    eligibleCustomers: jspb.Message.getFieldWithDefault(msg, 4, 0),
    isActive: jspb.Message.getBooleanFieldWithDefault(msg, 5, false),
    isInfinite: jspb.Message.getBooleanFieldWithDefault(msg, 6, false),
    expiresOn: (f = msg.getExpiresOn()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    isLifeTime: jspb.Message.getBooleanFieldWithDefault(msg, 8, false),
    fixedTerm: (f = msg.getFixedTerm()) && proto.core.private.discounts.FixedTermDiscountLength.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.core.private.discounts.Coupon}
 */
proto.core.private.discounts.Coupon.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.core.private.discounts.Coupon;
  return proto.core.private.discounts.Coupon.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.core.private.discounts.Coupon} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.core.private.discounts.Coupon}
 */
proto.core.private.discounts.Coupon.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setCouponId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = /** @type {!proto.core.private.discounts.CouponType} */ (reader.readEnum());
      msg.setCouponType(value);
      break;
    case 4:
      var value = /** @type {!proto.core.private.discounts.EligibleCustomers} */ (reader.readEnum());
      msg.setEligibleCustomers(value);
      break;
    case 5:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsActive(value);
      break;
    case 6:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsInfinite(value);
      break;
    case 7:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setExpiresOn(value);
      break;
    case 8:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsLifeTime(value);
      break;
    case 9:
      var value = new proto.core.private.discounts.FixedTermDiscountLength;
      reader.readMessage(value,proto.core.private.discounts.FixedTermDiscountLength.deserializeBinaryFromReader);
      msg.setFixedTerm(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.core.private.discounts.Coupon.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.core.private.discounts.Coupon.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.core.private.discounts.Coupon} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.core.private.discounts.Coupon.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCouponId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getCouponType();
  if (f !== 0.0) {
    writer.writeEnum(
      3,
      f
    );
  }
  f = message.getEligibleCustomers();
  if (f !== 0.0) {
    writer.writeEnum(
      4,
      f
    );
  }
  f = message.getIsActive();
  if (f) {
    writer.writeBool(
      5,
      f
    );
  }
  f = /** @type {boolean} */ (jspb.Message.getField(message, 6));
  if (f != null) {
    writer.writeBool(
      6,
      f
    );
  }
  f = message.getExpiresOn();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = /** @type {boolean} */ (jspb.Message.getField(message, 8));
  if (f != null) {
    writer.writeBool(
      8,
      f
    );
  }
  f = message.getFixedTerm();
  if (f != null) {
    writer.writeMessage(
      9,
      f,
      proto.core.private.discounts.FixedTermDiscountLength.serializeBinaryToWriter
    );
  }
};


/**
 * optional int32 coupon_id = 1;
 * @return {number}
 */
proto.core.private.discounts.Coupon.prototype.getCouponId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.core.private.discounts.Coupon} returns this
 */
proto.core.private.discounts.Coupon.prototype.setCouponId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.core.private.discounts.Coupon.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.core.private.discounts.Coupon} returns this
 */
proto.core.private.discounts.Coupon.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional CouponType coupon_type = 3;
 * @return {!proto.core.private.discounts.CouponType}
 */
proto.core.private.discounts.Coupon.prototype.getCouponType = function() {
  return /** @type {!proto.core.private.discounts.CouponType} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {!proto.core.private.discounts.CouponType} value
 * @return {!proto.core.private.discounts.Coupon} returns this
 */
proto.core.private.discounts.Coupon.prototype.setCouponType = function(value) {
  return jspb.Message.setProto3EnumField(this, 3, value);
};


/**
 * optional EligibleCustomers eligible_customers = 4;
 * @return {!proto.core.private.discounts.EligibleCustomers}
 */
proto.core.private.discounts.Coupon.prototype.getEligibleCustomers = function() {
  return /** @type {!proto.core.private.discounts.EligibleCustomers} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {!proto.core.private.discounts.EligibleCustomers} value
 * @return {!proto.core.private.discounts.Coupon} returns this
 */
proto.core.private.discounts.Coupon.prototype.setEligibleCustomers = function(value) {
  return jspb.Message.setProto3EnumField(this, 4, value);
};


/**
 * optional bool is_active = 5;
 * @return {boolean}
 */
proto.core.private.discounts.Coupon.prototype.getIsActive = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 5, false));
};


/**
 * @param {boolean} value
 * @return {!proto.core.private.discounts.Coupon} returns this
 */
proto.core.private.discounts.Coupon.prototype.setIsActive = function(value) {
  return jspb.Message.setProto3BooleanField(this, 5, value);
};


/**
 * optional bool is_infinite = 6;
 * @return {boolean}
 */
proto.core.private.discounts.Coupon.prototype.getIsInfinite = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 6, false));
};


/**
 * @param {boolean} value
 * @return {!proto.core.private.discounts.Coupon} returns this
 */
proto.core.private.discounts.Coupon.prototype.setIsInfinite = function(value) {
  return jspb.Message.setOneofField(this, 6, proto.core.private.discounts.Coupon.oneofGroups_[0], value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.core.private.discounts.Coupon} returns this
 */
proto.core.private.discounts.Coupon.prototype.clearIsInfinite = function() {
  return jspb.Message.setOneofField(this, 6, proto.core.private.discounts.Coupon.oneofGroups_[0], undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.core.private.discounts.Coupon.prototype.hasIsInfinite = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional google.protobuf.Timestamp expires_on = 7;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.core.private.discounts.Coupon.prototype.getExpiresOn = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 7));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.core.private.discounts.Coupon} returns this
*/
proto.core.private.discounts.Coupon.prototype.setExpiresOn = function(value) {
  return jspb.Message.setOneofWrapperField(this, 7, proto.core.private.discounts.Coupon.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.core.private.discounts.Coupon} returns this
 */
proto.core.private.discounts.Coupon.prototype.clearExpiresOn = function() {
  return this.setExpiresOn(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.core.private.discounts.Coupon.prototype.hasExpiresOn = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional bool is_life_time = 8;
 * @return {boolean}
 */
proto.core.private.discounts.Coupon.prototype.getIsLifeTime = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 8, false));
};


/**
 * @param {boolean} value
 * @return {!proto.core.private.discounts.Coupon} returns this
 */
proto.core.private.discounts.Coupon.prototype.setIsLifeTime = function(value) {
  return jspb.Message.setOneofField(this, 8, proto.core.private.discounts.Coupon.oneofGroups_[1], value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.core.private.discounts.Coupon} returns this
 */
proto.core.private.discounts.Coupon.prototype.clearIsLifeTime = function() {
  return jspb.Message.setOneofField(this, 8, proto.core.private.discounts.Coupon.oneofGroups_[1], undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.core.private.discounts.Coupon.prototype.hasIsLifeTime = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * optional FixedTermDiscountLength fixed_term = 9;
 * @return {?proto.core.private.discounts.FixedTermDiscountLength}
 */
proto.core.private.discounts.Coupon.prototype.getFixedTerm = function() {
  return /** @type{?proto.core.private.discounts.FixedTermDiscountLength} */ (
    jspb.Message.getWrapperField(this, proto.core.private.discounts.FixedTermDiscountLength, 9));
};


/**
 * @param {?proto.core.private.discounts.FixedTermDiscountLength|undefined} value
 * @return {!proto.core.private.discounts.Coupon} returns this
*/
proto.core.private.discounts.Coupon.prototype.setFixedTerm = function(value) {
  return jspb.Message.setOneofWrapperField(this, 9, proto.core.private.discounts.Coupon.oneofGroups_[1], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.core.private.discounts.Coupon} returns this
 */
proto.core.private.discounts.Coupon.prototype.clearFixedTerm = function() {
  return this.setFixedTerm(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.core.private.discounts.Coupon.prototype.hasFixedTerm = function() {
  return jspb.Message.getField(this, 9) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.core.private.discounts.FixedTermDiscountLength.prototype.toObject = function(opt_includeInstance) {
  return proto.core.private.discounts.FixedTermDiscountLength.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.core.private.discounts.FixedTermDiscountLength} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.core.private.discounts.FixedTermDiscountLength.toObject = function(includeInstance, msg) {
  var f, obj = {
    frequency: jspb.Message.getFieldWithDefault(msg, 1, 0),
    frequencyType: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.core.private.discounts.FixedTermDiscountLength}
 */
proto.core.private.discounts.FixedTermDiscountLength.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.core.private.discounts.FixedTermDiscountLength;
  return proto.core.private.discounts.FixedTermDiscountLength.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.core.private.discounts.FixedTermDiscountLength} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.core.private.discounts.FixedTermDiscountLength}
 */
proto.core.private.discounts.FixedTermDiscountLength.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setFrequency(value);
      break;
    case 2:
      var value = /** @type {!proto.core.private.discounts.FrequencyType} */ (reader.readEnum());
      msg.setFrequencyType(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.core.private.discounts.FixedTermDiscountLength.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.core.private.discounts.FixedTermDiscountLength.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.core.private.discounts.FixedTermDiscountLength} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.core.private.discounts.FixedTermDiscountLength.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFrequency();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getFrequencyType();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
};


/**
 * optional int32 frequency = 1;
 * @return {number}
 */
proto.core.private.discounts.FixedTermDiscountLength.prototype.getFrequency = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.core.private.discounts.FixedTermDiscountLength} returns this
 */
proto.core.private.discounts.FixedTermDiscountLength.prototype.setFrequency = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional FrequencyType frequency_type = 2;
 * @return {!proto.core.private.discounts.FrequencyType}
 */
proto.core.private.discounts.FixedTermDiscountLength.prototype.getFrequencyType = function() {
  return /** @type {!proto.core.private.discounts.FrequencyType} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.core.private.discounts.FrequencyType} value
 * @return {!proto.core.private.discounts.FixedTermDiscountLength} returns this
 */
proto.core.private.discounts.FixedTermDiscountLength.prototype.setFrequencyType = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.core.private.discounts.GetDiscountsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.core.private.discounts.GetDiscountsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.core.private.discounts.GetDiscountsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.core.private.discounts.GetDiscountsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    companyId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    page: (f = msg.getPage()) && google_protobuf_wrappers_pb.Int32Value.toObject(includeInstance, f),
    pageSize: (f = msg.getPageSize()) && google_protobuf_wrappers_pb.Int32Value.toObject(includeInstance, f),
    search: jspb.Message.getFieldWithDefault(msg, 4, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.core.private.discounts.GetDiscountsRequest}
 */
proto.core.private.discounts.GetDiscountsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.core.private.discounts.GetDiscountsRequest;
  return proto.core.private.discounts.GetDiscountsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.core.private.discounts.GetDiscountsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.core.private.discounts.GetDiscountsRequest}
 */
proto.core.private.discounts.GetDiscountsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setCompanyId(value);
      break;
    case 2:
      var value = new google_protobuf_wrappers_pb.Int32Value;
      reader.readMessage(value,google_protobuf_wrappers_pb.Int32Value.deserializeBinaryFromReader);
      msg.setPage(value);
      break;
    case 3:
      var value = new google_protobuf_wrappers_pb.Int32Value;
      reader.readMessage(value,google_protobuf_wrappers_pb.Int32Value.deserializeBinaryFromReader);
      msg.setPageSize(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setSearch(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.core.private.discounts.GetDiscountsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.core.private.discounts.GetDiscountsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.core.private.discounts.GetDiscountsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.core.private.discounts.GetDiscountsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCompanyId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getPage();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      google_protobuf_wrappers_pb.Int32Value.serializeBinaryToWriter
    );
  }
  f = message.getPageSize();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      google_protobuf_wrappers_pb.Int32Value.serializeBinaryToWriter
    );
  }
  f = message.getSearch();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
};


/**
 * optional int32 company_id = 1;
 * @return {number}
 */
proto.core.private.discounts.GetDiscountsRequest.prototype.getCompanyId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.core.private.discounts.GetDiscountsRequest} returns this
 */
proto.core.private.discounts.GetDiscountsRequest.prototype.setCompanyId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional google.protobuf.Int32Value page = 2;
 * @return {?proto.google.protobuf.Int32Value}
 */
proto.core.private.discounts.GetDiscountsRequest.prototype.getPage = function() {
  return /** @type{?proto.google.protobuf.Int32Value} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.Int32Value, 2));
};


/**
 * @param {?proto.google.protobuf.Int32Value|undefined} value
 * @return {!proto.core.private.discounts.GetDiscountsRequest} returns this
*/
proto.core.private.discounts.GetDiscountsRequest.prototype.setPage = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.core.private.discounts.GetDiscountsRequest} returns this
 */
proto.core.private.discounts.GetDiscountsRequest.prototype.clearPage = function() {
  return this.setPage(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.core.private.discounts.GetDiscountsRequest.prototype.hasPage = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional google.protobuf.Int32Value page_size = 3;
 * @return {?proto.google.protobuf.Int32Value}
 */
proto.core.private.discounts.GetDiscountsRequest.prototype.getPageSize = function() {
  return /** @type{?proto.google.protobuf.Int32Value} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.Int32Value, 3));
};


/**
 * @param {?proto.google.protobuf.Int32Value|undefined} value
 * @return {!proto.core.private.discounts.GetDiscountsRequest} returns this
*/
proto.core.private.discounts.GetDiscountsRequest.prototype.setPageSize = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.core.private.discounts.GetDiscountsRequest} returns this
 */
proto.core.private.discounts.GetDiscountsRequest.prototype.clearPageSize = function() {
  return this.setPageSize(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.core.private.discounts.GetDiscountsRequest.prototype.hasPageSize = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional string search = 4;
 * @return {string}
 */
proto.core.private.discounts.GetDiscountsRequest.prototype.getSearch = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.core.private.discounts.GetDiscountsRequest} returns this
 */
proto.core.private.discounts.GetDiscountsRequest.prototype.setSearch = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.core.private.discounts.GetDiscountsResponse.repeatedFields_ = [5];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.core.private.discounts.GetDiscountsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.core.private.discounts.GetDiscountsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.core.private.discounts.GetDiscountsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.core.private.discounts.GetDiscountsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    currentPage: jspb.Message.getFieldWithDefault(msg, 1, 0),
    pageCount: jspb.Message.getFieldWithDefault(msg, 2, 0),
    pageSize: jspb.Message.getFieldWithDefault(msg, 3, 0),
    rowCount: jspb.Message.getFieldWithDefault(msg, 4, 0),
    discountsList: jspb.Message.toObjectList(msg.getDiscountsList(),
    proto.core.private.discounts.Discount.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.core.private.discounts.GetDiscountsResponse}
 */
proto.core.private.discounts.GetDiscountsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.core.private.discounts.GetDiscountsResponse;
  return proto.core.private.discounts.GetDiscountsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.core.private.discounts.GetDiscountsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.core.private.discounts.GetDiscountsResponse}
 */
proto.core.private.discounts.GetDiscountsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setCurrentPage(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPageCount(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPageSize(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setRowCount(value);
      break;
    case 5:
      var value = new proto.core.private.discounts.Discount;
      reader.readMessage(value,proto.core.private.discounts.Discount.deserializeBinaryFromReader);
      msg.addDiscounts(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.core.private.discounts.GetDiscountsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.core.private.discounts.GetDiscountsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.core.private.discounts.GetDiscountsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.core.private.discounts.GetDiscountsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCurrentPage();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getPageCount();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getPageSize();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
  f = message.getRowCount();
  if (f !== 0) {
    writer.writeInt64(
      4,
      f
    );
  }
  f = message.getDiscountsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      5,
      f,
      proto.core.private.discounts.Discount.serializeBinaryToWriter
    );
  }
};


/**
 * optional int32 current_page = 1;
 * @return {number}
 */
proto.core.private.discounts.GetDiscountsResponse.prototype.getCurrentPage = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.core.private.discounts.GetDiscountsResponse} returns this
 */
proto.core.private.discounts.GetDiscountsResponse.prototype.setCurrentPage = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int32 page_count = 2;
 * @return {number}
 */
proto.core.private.discounts.GetDiscountsResponse.prototype.getPageCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.core.private.discounts.GetDiscountsResponse} returns this
 */
proto.core.private.discounts.GetDiscountsResponse.prototype.setPageCount = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int32 page_size = 3;
 * @return {number}
 */
proto.core.private.discounts.GetDiscountsResponse.prototype.getPageSize = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.core.private.discounts.GetDiscountsResponse} returns this
 */
proto.core.private.discounts.GetDiscountsResponse.prototype.setPageSize = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional int64 row_count = 4;
 * @return {number}
 */
proto.core.private.discounts.GetDiscountsResponse.prototype.getRowCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.core.private.discounts.GetDiscountsResponse} returns this
 */
proto.core.private.discounts.GetDiscountsResponse.prototype.setRowCount = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * repeated Discount discounts = 5;
 * @return {!Array<!proto.core.private.discounts.Discount>}
 */
proto.core.private.discounts.GetDiscountsResponse.prototype.getDiscountsList = function() {
  return /** @type{!Array<!proto.core.private.discounts.Discount>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.core.private.discounts.Discount, 5));
};


/**
 * @param {!Array<!proto.core.private.discounts.Discount>} value
 * @return {!proto.core.private.discounts.GetDiscountsResponse} returns this
*/
proto.core.private.discounts.GetDiscountsResponse.prototype.setDiscountsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 5, value);
};


/**
 * @param {!proto.core.private.discounts.Discount=} opt_value
 * @param {number=} opt_index
 * @return {!proto.core.private.discounts.Discount}
 */
proto.core.private.discounts.GetDiscountsResponse.prototype.addDiscounts = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 5, opt_value, proto.core.private.discounts.Discount, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.core.private.discounts.GetDiscountsResponse} returns this
 */
proto.core.private.discounts.GetDiscountsResponse.prototype.clearDiscountsList = function() {
  return this.setDiscountsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.core.private.discounts.GetDiscountRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.core.private.discounts.GetDiscountRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.core.private.discounts.GetDiscountRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.core.private.discounts.GetDiscountRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    companyId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    discountId: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.core.private.discounts.GetDiscountRequest}
 */
proto.core.private.discounts.GetDiscountRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.core.private.discounts.GetDiscountRequest;
  return proto.core.private.discounts.GetDiscountRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.core.private.discounts.GetDiscountRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.core.private.discounts.GetDiscountRequest}
 */
proto.core.private.discounts.GetDiscountRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setCompanyId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setDiscountId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.core.private.discounts.GetDiscountRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.core.private.discounts.GetDiscountRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.core.private.discounts.GetDiscountRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.core.private.discounts.GetDiscountRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCompanyId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getDiscountId();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
};


/**
 * optional int32 company_id = 1;
 * @return {number}
 */
proto.core.private.discounts.GetDiscountRequest.prototype.getCompanyId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.core.private.discounts.GetDiscountRequest} returns this
 */
proto.core.private.discounts.GetDiscountRequest.prototype.setCompanyId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int32 discount_id = 2;
 * @return {number}
 */
proto.core.private.discounts.GetDiscountRequest.prototype.getDiscountId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.core.private.discounts.GetDiscountRequest} returns this
 */
proto.core.private.discounts.GetDiscountRequest.prototype.setDiscountId = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.core.private.discounts.GetDiscountResponse.repeatedFields_ = [6];

/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.core.private.discounts.GetDiscountResponse.oneofGroups_ = [[8,9]];

/**
 * @enum {number}
 */
proto.core.private.discounts.GetDiscountResponse.DiscountModelCase = {
  DISCOUNT_MODEL_NOT_SET: 0,
  PERCENTAGE: 8,
  MONETARY: 9
};

/**
 * @return {proto.core.private.discounts.GetDiscountResponse.DiscountModelCase}
 */
proto.core.private.discounts.GetDiscountResponse.prototype.getDiscountModelCase = function() {
  return /** @type {proto.core.private.discounts.GetDiscountResponse.DiscountModelCase} */(jspb.Message.computeOneofCase(this, proto.core.private.discounts.GetDiscountResponse.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.core.private.discounts.GetDiscountResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.core.private.discounts.GetDiscountResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.core.private.discounts.GetDiscountResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.core.private.discounts.GetDiscountResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    companyId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    discountId: jspb.Message.getFieldWithDefault(msg, 2, 0),
    name: jspb.Message.getFieldWithDefault(msg, 3, ""),
    isAllowedWithOtherDiscounts: jspb.Message.getBooleanFieldWithDefault(msg, 4, false),
    allowAllPlans: jspb.Message.getBooleanFieldWithDefault(msg, 5, false),
    planIdsList: (f = jspb.Message.getRepeatedField(msg, 6)) == null ? undefined : f,
    deductionType: jspb.Message.getFieldWithDefault(msg, 7, 0),
    percentage: (f = msg.getPercentage()) && proto.core.private.discounts.GetDiscountResponse.PercentageModel.toObject(includeInstance, f),
    monetary: (f = msg.getMonetary()) && proto.core.private.discounts.GetDiscountResponse.MonetaryModel.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.core.private.discounts.GetDiscountResponse}
 */
proto.core.private.discounts.GetDiscountResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.core.private.discounts.GetDiscountResponse;
  return proto.core.private.discounts.GetDiscountResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.core.private.discounts.GetDiscountResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.core.private.discounts.GetDiscountResponse}
 */
proto.core.private.discounts.GetDiscountResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setCompanyId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setDiscountId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 4:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsAllowedWithOtherDiscounts(value);
      break;
    case 5:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setAllowAllPlans(value);
      break;
    case 6:
      var value = /** @type {!Array<number>} */ (reader.readPackedInt32());
      msg.setPlanIdsList(value);
      break;
    case 7:
      var value = /** @type {!proto.core.private.discounts.DeductionType} */ (reader.readEnum());
      msg.setDeductionType(value);
      break;
    case 8:
      var value = new proto.core.private.discounts.GetDiscountResponse.PercentageModel;
      reader.readMessage(value,proto.core.private.discounts.GetDiscountResponse.PercentageModel.deserializeBinaryFromReader);
      msg.setPercentage(value);
      break;
    case 9:
      var value = new proto.core.private.discounts.GetDiscountResponse.MonetaryModel;
      reader.readMessage(value,proto.core.private.discounts.GetDiscountResponse.MonetaryModel.deserializeBinaryFromReader);
      msg.setMonetary(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.core.private.discounts.GetDiscountResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.core.private.discounts.GetDiscountResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.core.private.discounts.GetDiscountResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.core.private.discounts.GetDiscountResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCompanyId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getDiscountId();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getIsAllowedWithOtherDiscounts();
  if (f) {
    writer.writeBool(
      4,
      f
    );
  }
  f = message.getAllowAllPlans();
  if (f) {
    writer.writeBool(
      5,
      f
    );
  }
  f = message.getPlanIdsList();
  if (f.length > 0) {
    writer.writePackedInt32(
      6,
      f
    );
  }
  f = message.getDeductionType();
  if (f !== 0.0) {
    writer.writeEnum(
      7,
      f
    );
  }
  f = message.getPercentage();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      proto.core.private.discounts.GetDiscountResponse.PercentageModel.serializeBinaryToWriter
    );
  }
  f = message.getMonetary();
  if (f != null) {
    writer.writeMessage(
      9,
      f,
      proto.core.private.discounts.GetDiscountResponse.MonetaryModel.serializeBinaryToWriter
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.core.private.discounts.GetDiscountResponse.PercentageModel.prototype.toObject = function(opt_includeInstance) {
  return proto.core.private.discounts.GetDiscountResponse.PercentageModel.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.core.private.discounts.GetDiscountResponse.PercentageModel} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.core.private.discounts.GetDiscountResponse.PercentageModel.toObject = function(includeInstance, msg) {
  var f, obj = {
    percentage: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.core.private.discounts.GetDiscountResponse.PercentageModel}
 */
proto.core.private.discounts.GetDiscountResponse.PercentageModel.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.core.private.discounts.GetDiscountResponse.PercentageModel;
  return proto.core.private.discounts.GetDiscountResponse.PercentageModel.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.core.private.discounts.GetDiscountResponse.PercentageModel} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.core.private.discounts.GetDiscountResponse.PercentageModel}
 */
proto.core.private.discounts.GetDiscountResponse.PercentageModel.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPercentage(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.core.private.discounts.GetDiscountResponse.PercentageModel.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.core.private.discounts.GetDiscountResponse.PercentageModel.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.core.private.discounts.GetDiscountResponse.PercentageModel} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.core.private.discounts.GetDiscountResponse.PercentageModel.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPercentage();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
};


/**
 * optional int32 percentage = 1;
 * @return {number}
 */
proto.core.private.discounts.GetDiscountResponse.PercentageModel.prototype.getPercentage = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.core.private.discounts.GetDiscountResponse.PercentageModel} returns this
 */
proto.core.private.discounts.GetDiscountResponse.PercentageModel.prototype.setPercentage = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.core.private.discounts.GetDiscountResponse.MonetaryModel.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.core.private.discounts.GetDiscountResponse.MonetaryModel.prototype.toObject = function(opt_includeInstance) {
  return proto.core.private.discounts.GetDiscountResponse.MonetaryModel.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.core.private.discounts.GetDiscountResponse.MonetaryModel} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.core.private.discounts.GetDiscountResponse.MonetaryModel.toObject = function(includeInstance, msg) {
  var f, obj = {
    pricesList: jspb.Message.toObjectList(msg.getPricesList(),
    proto.core.private.discounts.GetMonetaryCurrencyPrices.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.core.private.discounts.GetDiscountResponse.MonetaryModel}
 */
proto.core.private.discounts.GetDiscountResponse.MonetaryModel.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.core.private.discounts.GetDiscountResponse.MonetaryModel;
  return proto.core.private.discounts.GetDiscountResponse.MonetaryModel.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.core.private.discounts.GetDiscountResponse.MonetaryModel} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.core.private.discounts.GetDiscountResponse.MonetaryModel}
 */
proto.core.private.discounts.GetDiscountResponse.MonetaryModel.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.core.private.discounts.GetMonetaryCurrencyPrices;
      reader.readMessage(value,proto.core.private.discounts.GetMonetaryCurrencyPrices.deserializeBinaryFromReader);
      msg.addPrices(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.core.private.discounts.GetDiscountResponse.MonetaryModel.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.core.private.discounts.GetDiscountResponse.MonetaryModel.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.core.private.discounts.GetDiscountResponse.MonetaryModel} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.core.private.discounts.GetDiscountResponse.MonetaryModel.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPricesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.core.private.discounts.GetMonetaryCurrencyPrices.serializeBinaryToWriter
    );
  }
};


/**
 * repeated GetMonetaryCurrencyPrices prices = 1;
 * @return {!Array<!proto.core.private.discounts.GetMonetaryCurrencyPrices>}
 */
proto.core.private.discounts.GetDiscountResponse.MonetaryModel.prototype.getPricesList = function() {
  return /** @type{!Array<!proto.core.private.discounts.GetMonetaryCurrencyPrices>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.core.private.discounts.GetMonetaryCurrencyPrices, 1));
};


/**
 * @param {!Array<!proto.core.private.discounts.GetMonetaryCurrencyPrices>} value
 * @return {!proto.core.private.discounts.GetDiscountResponse.MonetaryModel} returns this
*/
proto.core.private.discounts.GetDiscountResponse.MonetaryModel.prototype.setPricesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.core.private.discounts.GetMonetaryCurrencyPrices=} opt_value
 * @param {number=} opt_index
 * @return {!proto.core.private.discounts.GetMonetaryCurrencyPrices}
 */
proto.core.private.discounts.GetDiscountResponse.MonetaryModel.prototype.addPrices = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.core.private.discounts.GetMonetaryCurrencyPrices, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.core.private.discounts.GetDiscountResponse.MonetaryModel} returns this
 */
proto.core.private.discounts.GetDiscountResponse.MonetaryModel.prototype.clearPricesList = function() {
  return this.setPricesList([]);
};


/**
 * optional int32 company_id = 1;
 * @return {number}
 */
proto.core.private.discounts.GetDiscountResponse.prototype.getCompanyId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.core.private.discounts.GetDiscountResponse} returns this
 */
proto.core.private.discounts.GetDiscountResponse.prototype.setCompanyId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int32 discount_id = 2;
 * @return {number}
 */
proto.core.private.discounts.GetDiscountResponse.prototype.getDiscountId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.core.private.discounts.GetDiscountResponse} returns this
 */
proto.core.private.discounts.GetDiscountResponse.prototype.setDiscountId = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional string name = 3;
 * @return {string}
 */
proto.core.private.discounts.GetDiscountResponse.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.core.private.discounts.GetDiscountResponse} returns this
 */
proto.core.private.discounts.GetDiscountResponse.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional bool is_allowed_with_other_discounts = 4;
 * @return {boolean}
 */
proto.core.private.discounts.GetDiscountResponse.prototype.getIsAllowedWithOtherDiscounts = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 4, false));
};


/**
 * @param {boolean} value
 * @return {!proto.core.private.discounts.GetDiscountResponse} returns this
 */
proto.core.private.discounts.GetDiscountResponse.prototype.setIsAllowedWithOtherDiscounts = function(value) {
  return jspb.Message.setProto3BooleanField(this, 4, value);
};


/**
 * optional bool allow_all_plans = 5;
 * @return {boolean}
 */
proto.core.private.discounts.GetDiscountResponse.prototype.getAllowAllPlans = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 5, false));
};


/**
 * @param {boolean} value
 * @return {!proto.core.private.discounts.GetDiscountResponse} returns this
 */
proto.core.private.discounts.GetDiscountResponse.prototype.setAllowAllPlans = function(value) {
  return jspb.Message.setProto3BooleanField(this, 5, value);
};


/**
 * repeated int32 plan_ids = 6;
 * @return {!Array<number>}
 */
proto.core.private.discounts.GetDiscountResponse.prototype.getPlanIdsList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 6));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.core.private.discounts.GetDiscountResponse} returns this
 */
proto.core.private.discounts.GetDiscountResponse.prototype.setPlanIdsList = function(value) {
  return jspb.Message.setField(this, 6, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.core.private.discounts.GetDiscountResponse} returns this
 */
proto.core.private.discounts.GetDiscountResponse.prototype.addPlanIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 6, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.core.private.discounts.GetDiscountResponse} returns this
 */
proto.core.private.discounts.GetDiscountResponse.prototype.clearPlanIdsList = function() {
  return this.setPlanIdsList([]);
};


/**
 * optional DeductionType deduction_type = 7;
 * @return {!proto.core.private.discounts.DeductionType}
 */
proto.core.private.discounts.GetDiscountResponse.prototype.getDeductionType = function() {
  return /** @type {!proto.core.private.discounts.DeductionType} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {!proto.core.private.discounts.DeductionType} value
 * @return {!proto.core.private.discounts.GetDiscountResponse} returns this
 */
proto.core.private.discounts.GetDiscountResponse.prototype.setDeductionType = function(value) {
  return jspb.Message.setProto3EnumField(this, 7, value);
};


/**
 * optional PercentageModel percentage = 8;
 * @return {?proto.core.private.discounts.GetDiscountResponse.PercentageModel}
 */
proto.core.private.discounts.GetDiscountResponse.prototype.getPercentage = function() {
  return /** @type{?proto.core.private.discounts.GetDiscountResponse.PercentageModel} */ (
    jspb.Message.getWrapperField(this, proto.core.private.discounts.GetDiscountResponse.PercentageModel, 8));
};


/**
 * @param {?proto.core.private.discounts.GetDiscountResponse.PercentageModel|undefined} value
 * @return {!proto.core.private.discounts.GetDiscountResponse} returns this
*/
proto.core.private.discounts.GetDiscountResponse.prototype.setPercentage = function(value) {
  return jspb.Message.setOneofWrapperField(this, 8, proto.core.private.discounts.GetDiscountResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.core.private.discounts.GetDiscountResponse} returns this
 */
proto.core.private.discounts.GetDiscountResponse.prototype.clearPercentage = function() {
  return this.setPercentage(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.core.private.discounts.GetDiscountResponse.prototype.hasPercentage = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * optional MonetaryModel monetary = 9;
 * @return {?proto.core.private.discounts.GetDiscountResponse.MonetaryModel}
 */
proto.core.private.discounts.GetDiscountResponse.prototype.getMonetary = function() {
  return /** @type{?proto.core.private.discounts.GetDiscountResponse.MonetaryModel} */ (
    jspb.Message.getWrapperField(this, proto.core.private.discounts.GetDiscountResponse.MonetaryModel, 9));
};


/**
 * @param {?proto.core.private.discounts.GetDiscountResponse.MonetaryModel|undefined} value
 * @return {!proto.core.private.discounts.GetDiscountResponse} returns this
*/
proto.core.private.discounts.GetDiscountResponse.prototype.setMonetary = function(value) {
  return jspb.Message.setOneofWrapperField(this, 9, proto.core.private.discounts.GetDiscountResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.core.private.discounts.GetDiscountResponse} returns this
 */
proto.core.private.discounts.GetDiscountResponse.prototype.clearMonetary = function() {
  return this.setMonetary(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.core.private.discounts.GetDiscountResponse.prototype.hasMonetary = function() {
  return jspb.Message.getField(this, 9) != null;
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.core.private.discounts.Discount.oneofGroups_ = [[7,8]];

/**
 * @enum {number}
 */
proto.core.private.discounts.Discount.DiscountModelCase = {
  DISCOUNT_MODEL_NOT_SET: 0,
  PERCENTAGE: 7,
  MONETARY: 8
};

/**
 * @return {proto.core.private.discounts.Discount.DiscountModelCase}
 */
proto.core.private.discounts.Discount.prototype.getDiscountModelCase = function() {
  return /** @type {proto.core.private.discounts.Discount.DiscountModelCase} */(jspb.Message.computeOneofCase(this, proto.core.private.discounts.Discount.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.core.private.discounts.Discount.prototype.toObject = function(opt_includeInstance) {
  return proto.core.private.discounts.Discount.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.core.private.discounts.Discount} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.core.private.discounts.Discount.toObject = function(includeInstance, msg) {
  var f, obj = {
    discountId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    discountUniqueId: jspb.Message.getFieldWithDefault(msg, 3, ""),
    couponCount: jspb.Message.getFieldWithDefault(msg, 4, 0),
    discountType: jspb.Message.getFieldWithDefault(msg, 5, 0),
    deductionType: jspb.Message.getFieldWithDefault(msg, 6, 0),
    percentage: (f = msg.getPercentage()) && proto.core.private.discounts.Discount.PercentageModel.toObject(includeInstance, f),
    monetary: (f = msg.getMonetary()) && proto.core.private.discounts.Discount.MonetaryModel.toObject(includeInstance, f),
    isAllowedWithOtherDiscounts: jspb.Message.getBooleanFieldWithDefault(msg, 9, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.core.private.discounts.Discount}
 */
proto.core.private.discounts.Discount.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.core.private.discounts.Discount;
  return proto.core.private.discounts.Discount.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.core.private.discounts.Discount} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.core.private.discounts.Discount}
 */
proto.core.private.discounts.Discount.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setDiscountId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setDiscountUniqueId(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setCouponCount(value);
      break;
    case 5:
      var value = /** @type {!proto.core.private.discounts.DiscountType} */ (reader.readEnum());
      msg.setDiscountType(value);
      break;
    case 6:
      var value = /** @type {!proto.core.private.discounts.DeductionType} */ (reader.readEnum());
      msg.setDeductionType(value);
      break;
    case 7:
      var value = new proto.core.private.discounts.Discount.PercentageModel;
      reader.readMessage(value,proto.core.private.discounts.Discount.PercentageModel.deserializeBinaryFromReader);
      msg.setPercentage(value);
      break;
    case 8:
      var value = new proto.core.private.discounts.Discount.MonetaryModel;
      reader.readMessage(value,proto.core.private.discounts.Discount.MonetaryModel.deserializeBinaryFromReader);
      msg.setMonetary(value);
      break;
    case 9:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsAllowedWithOtherDiscounts(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.core.private.discounts.Discount.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.core.private.discounts.Discount.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.core.private.discounts.Discount} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.core.private.discounts.Discount.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDiscountId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getDiscountUniqueId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getCouponCount();
  if (f !== 0) {
    writer.writeInt32(
      4,
      f
    );
  }
  f = message.getDiscountType();
  if (f !== 0.0) {
    writer.writeEnum(
      5,
      f
    );
  }
  f = message.getDeductionType();
  if (f !== 0.0) {
    writer.writeEnum(
      6,
      f
    );
  }
  f = message.getPercentage();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      proto.core.private.discounts.Discount.PercentageModel.serializeBinaryToWriter
    );
  }
  f = message.getMonetary();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      proto.core.private.discounts.Discount.MonetaryModel.serializeBinaryToWriter
    );
  }
  f = message.getIsAllowedWithOtherDiscounts();
  if (f) {
    writer.writeBool(
      9,
      f
    );
  }
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.core.private.discounts.Discount.MonetaryModel.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.core.private.discounts.Discount.MonetaryModel.prototype.toObject = function(opt_includeInstance) {
  return proto.core.private.discounts.Discount.MonetaryModel.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.core.private.discounts.Discount.MonetaryModel} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.core.private.discounts.Discount.MonetaryModel.toObject = function(includeInstance, msg) {
  var f, obj = {
    pricesList: jspb.Message.toObjectList(msg.getPricesList(),
    proto.core.private.discounts.GetMonetaryCurrencyPrices.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.core.private.discounts.Discount.MonetaryModel}
 */
proto.core.private.discounts.Discount.MonetaryModel.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.core.private.discounts.Discount.MonetaryModel;
  return proto.core.private.discounts.Discount.MonetaryModel.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.core.private.discounts.Discount.MonetaryModel} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.core.private.discounts.Discount.MonetaryModel}
 */
proto.core.private.discounts.Discount.MonetaryModel.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.core.private.discounts.GetMonetaryCurrencyPrices;
      reader.readMessage(value,proto.core.private.discounts.GetMonetaryCurrencyPrices.deserializeBinaryFromReader);
      msg.addPrices(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.core.private.discounts.Discount.MonetaryModel.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.core.private.discounts.Discount.MonetaryModel.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.core.private.discounts.Discount.MonetaryModel} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.core.private.discounts.Discount.MonetaryModel.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPricesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.core.private.discounts.GetMonetaryCurrencyPrices.serializeBinaryToWriter
    );
  }
};


/**
 * repeated GetMonetaryCurrencyPrices prices = 1;
 * @return {!Array<!proto.core.private.discounts.GetMonetaryCurrencyPrices>}
 */
proto.core.private.discounts.Discount.MonetaryModel.prototype.getPricesList = function() {
  return /** @type{!Array<!proto.core.private.discounts.GetMonetaryCurrencyPrices>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.core.private.discounts.GetMonetaryCurrencyPrices, 1));
};


/**
 * @param {!Array<!proto.core.private.discounts.GetMonetaryCurrencyPrices>} value
 * @return {!proto.core.private.discounts.Discount.MonetaryModel} returns this
*/
proto.core.private.discounts.Discount.MonetaryModel.prototype.setPricesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.core.private.discounts.GetMonetaryCurrencyPrices=} opt_value
 * @param {number=} opt_index
 * @return {!proto.core.private.discounts.GetMonetaryCurrencyPrices}
 */
proto.core.private.discounts.Discount.MonetaryModel.prototype.addPrices = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.core.private.discounts.GetMonetaryCurrencyPrices, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.core.private.discounts.Discount.MonetaryModel} returns this
 */
proto.core.private.discounts.Discount.MonetaryModel.prototype.clearPricesList = function() {
  return this.setPricesList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.core.private.discounts.Discount.PercentageModel.prototype.toObject = function(opt_includeInstance) {
  return proto.core.private.discounts.Discount.PercentageModel.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.core.private.discounts.Discount.PercentageModel} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.core.private.discounts.Discount.PercentageModel.toObject = function(includeInstance, msg) {
  var f, obj = {
    percentage: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.core.private.discounts.Discount.PercentageModel}
 */
proto.core.private.discounts.Discount.PercentageModel.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.core.private.discounts.Discount.PercentageModel;
  return proto.core.private.discounts.Discount.PercentageModel.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.core.private.discounts.Discount.PercentageModel} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.core.private.discounts.Discount.PercentageModel}
 */
proto.core.private.discounts.Discount.PercentageModel.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPercentage(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.core.private.discounts.Discount.PercentageModel.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.core.private.discounts.Discount.PercentageModel.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.core.private.discounts.Discount.PercentageModel} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.core.private.discounts.Discount.PercentageModel.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPercentage();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
};


/**
 * optional int32 percentage = 1;
 * @return {number}
 */
proto.core.private.discounts.Discount.PercentageModel.prototype.getPercentage = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.core.private.discounts.Discount.PercentageModel} returns this
 */
proto.core.private.discounts.Discount.PercentageModel.prototype.setPercentage = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int32 discount_id = 1;
 * @return {number}
 */
proto.core.private.discounts.Discount.prototype.getDiscountId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.core.private.discounts.Discount} returns this
 */
proto.core.private.discounts.Discount.prototype.setDiscountId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.core.private.discounts.Discount.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.core.private.discounts.Discount} returns this
 */
proto.core.private.discounts.Discount.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string discount_unique_id = 3;
 * @return {string}
 */
proto.core.private.discounts.Discount.prototype.getDiscountUniqueId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.core.private.discounts.Discount} returns this
 */
proto.core.private.discounts.Discount.prototype.setDiscountUniqueId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional int32 coupon_count = 4;
 * @return {number}
 */
proto.core.private.discounts.Discount.prototype.getCouponCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.core.private.discounts.Discount} returns this
 */
proto.core.private.discounts.Discount.prototype.setCouponCount = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional DiscountType discount_type = 5;
 * @return {!proto.core.private.discounts.DiscountType}
 */
proto.core.private.discounts.Discount.prototype.getDiscountType = function() {
  return /** @type {!proto.core.private.discounts.DiscountType} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {!proto.core.private.discounts.DiscountType} value
 * @return {!proto.core.private.discounts.Discount} returns this
 */
proto.core.private.discounts.Discount.prototype.setDiscountType = function(value) {
  return jspb.Message.setProto3EnumField(this, 5, value);
};


/**
 * optional DeductionType deduction_type = 6;
 * @return {!proto.core.private.discounts.DeductionType}
 */
proto.core.private.discounts.Discount.prototype.getDeductionType = function() {
  return /** @type {!proto.core.private.discounts.DeductionType} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {!proto.core.private.discounts.DeductionType} value
 * @return {!proto.core.private.discounts.Discount} returns this
 */
proto.core.private.discounts.Discount.prototype.setDeductionType = function(value) {
  return jspb.Message.setProto3EnumField(this, 6, value);
};


/**
 * optional PercentageModel percentage = 7;
 * @return {?proto.core.private.discounts.Discount.PercentageModel}
 */
proto.core.private.discounts.Discount.prototype.getPercentage = function() {
  return /** @type{?proto.core.private.discounts.Discount.PercentageModel} */ (
    jspb.Message.getWrapperField(this, proto.core.private.discounts.Discount.PercentageModel, 7));
};


/**
 * @param {?proto.core.private.discounts.Discount.PercentageModel|undefined} value
 * @return {!proto.core.private.discounts.Discount} returns this
*/
proto.core.private.discounts.Discount.prototype.setPercentage = function(value) {
  return jspb.Message.setOneofWrapperField(this, 7, proto.core.private.discounts.Discount.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.core.private.discounts.Discount} returns this
 */
proto.core.private.discounts.Discount.prototype.clearPercentage = function() {
  return this.setPercentage(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.core.private.discounts.Discount.prototype.hasPercentage = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional MonetaryModel monetary = 8;
 * @return {?proto.core.private.discounts.Discount.MonetaryModel}
 */
proto.core.private.discounts.Discount.prototype.getMonetary = function() {
  return /** @type{?proto.core.private.discounts.Discount.MonetaryModel} */ (
    jspb.Message.getWrapperField(this, proto.core.private.discounts.Discount.MonetaryModel, 8));
};


/**
 * @param {?proto.core.private.discounts.Discount.MonetaryModel|undefined} value
 * @return {!proto.core.private.discounts.Discount} returns this
*/
proto.core.private.discounts.Discount.prototype.setMonetary = function(value) {
  return jspb.Message.setOneofWrapperField(this, 8, proto.core.private.discounts.Discount.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.core.private.discounts.Discount} returns this
 */
proto.core.private.discounts.Discount.prototype.clearMonetary = function() {
  return this.setMonetary(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.core.private.discounts.Discount.prototype.hasMonetary = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * optional bool is_allowed_with_other_discounts = 9;
 * @return {boolean}
 */
proto.core.private.discounts.Discount.prototype.getIsAllowedWithOtherDiscounts = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 9, false));
};


/**
 * @param {boolean} value
 * @return {!proto.core.private.discounts.Discount} returns this
 */
proto.core.private.discounts.Discount.prototype.setIsAllowedWithOtherDiscounts = function(value) {
  return jspb.Message.setProto3BooleanField(this, 9, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.core.private.discounts.GetMonetaryCurrencyPrices.prototype.toObject = function(opt_includeInstance) {
  return proto.core.private.discounts.GetMonetaryCurrencyPrices.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.core.private.discounts.GetMonetaryCurrencyPrices} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.core.private.discounts.GetMonetaryCurrencyPrices.toObject = function(includeInstance, msg) {
  var f, obj = {
    currencyCode: jspb.Message.getFieldWithDefault(msg, 1, ""),
    value: (f = msg.getValue()) && google_protobuf_wrappers_pb.Int32Value.toObject(includeInstance, f),
    valueFormatted: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.core.private.discounts.GetMonetaryCurrencyPrices}
 */
proto.core.private.discounts.GetMonetaryCurrencyPrices.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.core.private.discounts.GetMonetaryCurrencyPrices;
  return proto.core.private.discounts.GetMonetaryCurrencyPrices.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.core.private.discounts.GetMonetaryCurrencyPrices} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.core.private.discounts.GetMonetaryCurrencyPrices}
 */
proto.core.private.discounts.GetMonetaryCurrencyPrices.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setCurrencyCode(value);
      break;
    case 2:
      var value = new google_protobuf_wrappers_pb.Int32Value;
      reader.readMessage(value,google_protobuf_wrappers_pb.Int32Value.deserializeBinaryFromReader);
      msg.setValue(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setValueFormatted(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.core.private.discounts.GetMonetaryCurrencyPrices.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.core.private.discounts.GetMonetaryCurrencyPrices.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.core.private.discounts.GetMonetaryCurrencyPrices} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.core.private.discounts.GetMonetaryCurrencyPrices.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCurrencyCode();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getValue();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      google_protobuf_wrappers_pb.Int32Value.serializeBinaryToWriter
    );
  }
  f = message.getValueFormatted();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional string currency_code = 1;
 * @return {string}
 */
proto.core.private.discounts.GetMonetaryCurrencyPrices.prototype.getCurrencyCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.core.private.discounts.GetMonetaryCurrencyPrices} returns this
 */
proto.core.private.discounts.GetMonetaryCurrencyPrices.prototype.setCurrencyCode = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional google.protobuf.Int32Value value = 2;
 * @return {?proto.google.protobuf.Int32Value}
 */
proto.core.private.discounts.GetMonetaryCurrencyPrices.prototype.getValue = function() {
  return /** @type{?proto.google.protobuf.Int32Value} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.Int32Value, 2));
};


/**
 * @param {?proto.google.protobuf.Int32Value|undefined} value
 * @return {!proto.core.private.discounts.GetMonetaryCurrencyPrices} returns this
*/
proto.core.private.discounts.GetMonetaryCurrencyPrices.prototype.setValue = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.core.private.discounts.GetMonetaryCurrencyPrices} returns this
 */
proto.core.private.discounts.GetMonetaryCurrencyPrices.prototype.clearValue = function() {
  return this.setValue(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.core.private.discounts.GetMonetaryCurrencyPrices.prototype.hasValue = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional string value_formatted = 3;
 * @return {string}
 */
proto.core.private.discounts.GetMonetaryCurrencyPrices.prototype.getValueFormatted = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.core.private.discounts.GetMonetaryCurrencyPrices} returns this
 */
proto.core.private.discounts.GetMonetaryCurrencyPrices.prototype.setValueFormatted = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.core.private.discounts.CreateDiscountRequest.repeatedFields_ = [5];

/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.core.private.discounts.CreateDiscountRequest.oneofGroups_ = [[7,8]];

/**
 * @enum {number}
 */
proto.core.private.discounts.CreateDiscountRequest.DiscountModelCase = {
  DISCOUNT_MODEL_NOT_SET: 0,
  PERCENTAGE: 7,
  MONETARY: 8
};

/**
 * @return {proto.core.private.discounts.CreateDiscountRequest.DiscountModelCase}
 */
proto.core.private.discounts.CreateDiscountRequest.prototype.getDiscountModelCase = function() {
  return /** @type {proto.core.private.discounts.CreateDiscountRequest.DiscountModelCase} */(jspb.Message.computeOneofCase(this, proto.core.private.discounts.CreateDiscountRequest.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.core.private.discounts.CreateDiscountRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.core.private.discounts.CreateDiscountRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.core.private.discounts.CreateDiscountRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.core.private.discounts.CreateDiscountRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    companyId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    isAllowedWithOtherDiscounts: jspb.Message.getBooleanFieldWithDefault(msg, 3, false),
    allowAllPlans: jspb.Message.getBooleanFieldWithDefault(msg, 4, false),
    planIdsList: (f = jspb.Message.getRepeatedField(msg, 5)) == null ? undefined : f,
    deductionType: jspb.Message.getFieldWithDefault(msg, 6, 0),
    percentage: (f = msg.getPercentage()) && proto.core.private.discounts.CreateDiscountRequest.PercentageModel.toObject(includeInstance, f),
    monetary: (f = msg.getMonetary()) && proto.core.private.discounts.CreateDiscountRequest.MonetaryModel.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.core.private.discounts.CreateDiscountRequest}
 */
proto.core.private.discounts.CreateDiscountRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.core.private.discounts.CreateDiscountRequest;
  return proto.core.private.discounts.CreateDiscountRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.core.private.discounts.CreateDiscountRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.core.private.discounts.CreateDiscountRequest}
 */
proto.core.private.discounts.CreateDiscountRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setCompanyId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsAllowedWithOtherDiscounts(value);
      break;
    case 4:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setAllowAllPlans(value);
      break;
    case 5:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedInt32() : [reader.readInt32()]);
      for (var i = 0; i < values.length; i++) {
        msg.addPlanIds(values[i]);
      }
      break;
    case 6:
      var value = /** @type {!proto.core.private.discounts.DeductionType} */ (reader.readEnum());
      msg.setDeductionType(value);
      break;
    case 7:
      var value = new proto.core.private.discounts.CreateDiscountRequest.PercentageModel;
      reader.readMessage(value,proto.core.private.discounts.CreateDiscountRequest.PercentageModel.deserializeBinaryFromReader);
      msg.setPercentage(value);
      break;
    case 8:
      var value = new proto.core.private.discounts.CreateDiscountRequest.MonetaryModel;
      reader.readMessage(value,proto.core.private.discounts.CreateDiscountRequest.MonetaryModel.deserializeBinaryFromReader);
      msg.setMonetary(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.core.private.discounts.CreateDiscountRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.core.private.discounts.CreateDiscountRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.core.private.discounts.CreateDiscountRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.core.private.discounts.CreateDiscountRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCompanyId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getIsAllowedWithOtherDiscounts();
  if (f) {
    writer.writeBool(
      3,
      f
    );
  }
  f = message.getAllowAllPlans();
  if (f) {
    writer.writeBool(
      4,
      f
    );
  }
  f = message.getPlanIdsList();
  if (f.length > 0) {
    writer.writePackedInt32(
      5,
      f
    );
  }
  f = message.getDeductionType();
  if (f !== 0.0) {
    writer.writeEnum(
      6,
      f
    );
  }
  f = message.getPercentage();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      proto.core.private.discounts.CreateDiscountRequest.PercentageModel.serializeBinaryToWriter
    );
  }
  f = message.getMonetary();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      proto.core.private.discounts.CreateDiscountRequest.MonetaryModel.serializeBinaryToWriter
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.core.private.discounts.CreateDiscountRequest.PercentageModel.prototype.toObject = function(opt_includeInstance) {
  return proto.core.private.discounts.CreateDiscountRequest.PercentageModel.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.core.private.discounts.CreateDiscountRequest.PercentageModel} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.core.private.discounts.CreateDiscountRequest.PercentageModel.toObject = function(includeInstance, msg) {
  var f, obj = {
    percentage: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.core.private.discounts.CreateDiscountRequest.PercentageModel}
 */
proto.core.private.discounts.CreateDiscountRequest.PercentageModel.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.core.private.discounts.CreateDiscountRequest.PercentageModel;
  return proto.core.private.discounts.CreateDiscountRequest.PercentageModel.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.core.private.discounts.CreateDiscountRequest.PercentageModel} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.core.private.discounts.CreateDiscountRequest.PercentageModel}
 */
proto.core.private.discounts.CreateDiscountRequest.PercentageModel.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPercentage(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.core.private.discounts.CreateDiscountRequest.PercentageModel.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.core.private.discounts.CreateDiscountRequest.PercentageModel.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.core.private.discounts.CreateDiscountRequest.PercentageModel} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.core.private.discounts.CreateDiscountRequest.PercentageModel.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPercentage();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
};


/**
 * optional int32 percentage = 1;
 * @return {number}
 */
proto.core.private.discounts.CreateDiscountRequest.PercentageModel.prototype.getPercentage = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.core.private.discounts.CreateDiscountRequest.PercentageModel} returns this
 */
proto.core.private.discounts.CreateDiscountRequest.PercentageModel.prototype.setPercentage = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.core.private.discounts.CreateDiscountRequest.MonetaryModel.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.core.private.discounts.CreateDiscountRequest.MonetaryModel.prototype.toObject = function(opt_includeInstance) {
  return proto.core.private.discounts.CreateDiscountRequest.MonetaryModel.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.core.private.discounts.CreateDiscountRequest.MonetaryModel} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.core.private.discounts.CreateDiscountRequest.MonetaryModel.toObject = function(includeInstance, msg) {
  var f, obj = {
    pricesList: jspb.Message.toObjectList(msg.getPricesList(),
    proto.core.private.discounts.MonetaryCurrencyPrices.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.core.private.discounts.CreateDiscountRequest.MonetaryModel}
 */
proto.core.private.discounts.CreateDiscountRequest.MonetaryModel.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.core.private.discounts.CreateDiscountRequest.MonetaryModel;
  return proto.core.private.discounts.CreateDiscountRequest.MonetaryModel.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.core.private.discounts.CreateDiscountRequest.MonetaryModel} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.core.private.discounts.CreateDiscountRequest.MonetaryModel}
 */
proto.core.private.discounts.CreateDiscountRequest.MonetaryModel.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.core.private.discounts.MonetaryCurrencyPrices;
      reader.readMessage(value,proto.core.private.discounts.MonetaryCurrencyPrices.deserializeBinaryFromReader);
      msg.addPrices(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.core.private.discounts.CreateDiscountRequest.MonetaryModel.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.core.private.discounts.CreateDiscountRequest.MonetaryModel.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.core.private.discounts.CreateDiscountRequest.MonetaryModel} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.core.private.discounts.CreateDiscountRequest.MonetaryModel.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPricesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.core.private.discounts.MonetaryCurrencyPrices.serializeBinaryToWriter
    );
  }
};


/**
 * repeated MonetaryCurrencyPrices prices = 1;
 * @return {!Array<!proto.core.private.discounts.MonetaryCurrencyPrices>}
 */
proto.core.private.discounts.CreateDiscountRequest.MonetaryModel.prototype.getPricesList = function() {
  return /** @type{!Array<!proto.core.private.discounts.MonetaryCurrencyPrices>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.core.private.discounts.MonetaryCurrencyPrices, 1));
};


/**
 * @param {!Array<!proto.core.private.discounts.MonetaryCurrencyPrices>} value
 * @return {!proto.core.private.discounts.CreateDiscountRequest.MonetaryModel} returns this
*/
proto.core.private.discounts.CreateDiscountRequest.MonetaryModel.prototype.setPricesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.core.private.discounts.MonetaryCurrencyPrices=} opt_value
 * @param {number=} opt_index
 * @return {!proto.core.private.discounts.MonetaryCurrencyPrices}
 */
proto.core.private.discounts.CreateDiscountRequest.MonetaryModel.prototype.addPrices = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.core.private.discounts.MonetaryCurrencyPrices, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.core.private.discounts.CreateDiscountRequest.MonetaryModel} returns this
 */
proto.core.private.discounts.CreateDiscountRequest.MonetaryModel.prototype.clearPricesList = function() {
  return this.setPricesList([]);
};


/**
 * optional int32 company_id = 1;
 * @return {number}
 */
proto.core.private.discounts.CreateDiscountRequest.prototype.getCompanyId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.core.private.discounts.CreateDiscountRequest} returns this
 */
proto.core.private.discounts.CreateDiscountRequest.prototype.setCompanyId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.core.private.discounts.CreateDiscountRequest.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.core.private.discounts.CreateDiscountRequest} returns this
 */
proto.core.private.discounts.CreateDiscountRequest.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional bool is_allowed_with_other_discounts = 3;
 * @return {boolean}
 */
proto.core.private.discounts.CreateDiscountRequest.prototype.getIsAllowedWithOtherDiscounts = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 3, false));
};


/**
 * @param {boolean} value
 * @return {!proto.core.private.discounts.CreateDiscountRequest} returns this
 */
proto.core.private.discounts.CreateDiscountRequest.prototype.setIsAllowedWithOtherDiscounts = function(value) {
  return jspb.Message.setProto3BooleanField(this, 3, value);
};


/**
 * optional bool allow_all_plans = 4;
 * @return {boolean}
 */
proto.core.private.discounts.CreateDiscountRequest.prototype.getAllowAllPlans = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 4, false));
};


/**
 * @param {boolean} value
 * @return {!proto.core.private.discounts.CreateDiscountRequest} returns this
 */
proto.core.private.discounts.CreateDiscountRequest.prototype.setAllowAllPlans = function(value) {
  return jspb.Message.setProto3BooleanField(this, 4, value);
};


/**
 * repeated int32 plan_ids = 5;
 * @return {!Array<number>}
 */
proto.core.private.discounts.CreateDiscountRequest.prototype.getPlanIdsList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 5));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.core.private.discounts.CreateDiscountRequest} returns this
 */
proto.core.private.discounts.CreateDiscountRequest.prototype.setPlanIdsList = function(value) {
  return jspb.Message.setField(this, 5, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.core.private.discounts.CreateDiscountRequest} returns this
 */
proto.core.private.discounts.CreateDiscountRequest.prototype.addPlanIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 5, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.core.private.discounts.CreateDiscountRequest} returns this
 */
proto.core.private.discounts.CreateDiscountRequest.prototype.clearPlanIdsList = function() {
  return this.setPlanIdsList([]);
};


/**
 * optional DeductionType deduction_type = 6;
 * @return {!proto.core.private.discounts.DeductionType}
 */
proto.core.private.discounts.CreateDiscountRequest.prototype.getDeductionType = function() {
  return /** @type {!proto.core.private.discounts.DeductionType} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {!proto.core.private.discounts.DeductionType} value
 * @return {!proto.core.private.discounts.CreateDiscountRequest} returns this
 */
proto.core.private.discounts.CreateDiscountRequest.prototype.setDeductionType = function(value) {
  return jspb.Message.setProto3EnumField(this, 6, value);
};


/**
 * optional PercentageModel percentage = 7;
 * @return {?proto.core.private.discounts.CreateDiscountRequest.PercentageModel}
 */
proto.core.private.discounts.CreateDiscountRequest.prototype.getPercentage = function() {
  return /** @type{?proto.core.private.discounts.CreateDiscountRequest.PercentageModel} */ (
    jspb.Message.getWrapperField(this, proto.core.private.discounts.CreateDiscountRequest.PercentageModel, 7));
};


/**
 * @param {?proto.core.private.discounts.CreateDiscountRequest.PercentageModel|undefined} value
 * @return {!proto.core.private.discounts.CreateDiscountRequest} returns this
*/
proto.core.private.discounts.CreateDiscountRequest.prototype.setPercentage = function(value) {
  return jspb.Message.setOneofWrapperField(this, 7, proto.core.private.discounts.CreateDiscountRequest.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.core.private.discounts.CreateDiscountRequest} returns this
 */
proto.core.private.discounts.CreateDiscountRequest.prototype.clearPercentage = function() {
  return this.setPercentage(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.core.private.discounts.CreateDiscountRequest.prototype.hasPercentage = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional MonetaryModel monetary = 8;
 * @return {?proto.core.private.discounts.CreateDiscountRequest.MonetaryModel}
 */
proto.core.private.discounts.CreateDiscountRequest.prototype.getMonetary = function() {
  return /** @type{?proto.core.private.discounts.CreateDiscountRequest.MonetaryModel} */ (
    jspb.Message.getWrapperField(this, proto.core.private.discounts.CreateDiscountRequest.MonetaryModel, 8));
};


/**
 * @param {?proto.core.private.discounts.CreateDiscountRequest.MonetaryModel|undefined} value
 * @return {!proto.core.private.discounts.CreateDiscountRequest} returns this
*/
proto.core.private.discounts.CreateDiscountRequest.prototype.setMonetary = function(value) {
  return jspb.Message.setOneofWrapperField(this, 8, proto.core.private.discounts.CreateDiscountRequest.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.core.private.discounts.CreateDiscountRequest} returns this
 */
proto.core.private.discounts.CreateDiscountRequest.prototype.clearMonetary = function() {
  return this.setMonetary(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.core.private.discounts.CreateDiscountRequest.prototype.hasMonetary = function() {
  return jspb.Message.getField(this, 8) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.core.private.discounts.MonetaryCurrencyPrices.prototype.toObject = function(opt_includeInstance) {
  return proto.core.private.discounts.MonetaryCurrencyPrices.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.core.private.discounts.MonetaryCurrencyPrices} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.core.private.discounts.MonetaryCurrencyPrices.toObject = function(includeInstance, msg) {
  var f, obj = {
    currencyCode: jspb.Message.getFieldWithDefault(msg, 1, ""),
    value: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.core.private.discounts.MonetaryCurrencyPrices}
 */
proto.core.private.discounts.MonetaryCurrencyPrices.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.core.private.discounts.MonetaryCurrencyPrices;
  return proto.core.private.discounts.MonetaryCurrencyPrices.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.core.private.discounts.MonetaryCurrencyPrices} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.core.private.discounts.MonetaryCurrencyPrices}
 */
proto.core.private.discounts.MonetaryCurrencyPrices.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setCurrencyCode(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setValue(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.core.private.discounts.MonetaryCurrencyPrices.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.core.private.discounts.MonetaryCurrencyPrices.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.core.private.discounts.MonetaryCurrencyPrices} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.core.private.discounts.MonetaryCurrencyPrices.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCurrencyCode();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getValue();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
};


/**
 * optional string currency_code = 1;
 * @return {string}
 */
proto.core.private.discounts.MonetaryCurrencyPrices.prototype.getCurrencyCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.core.private.discounts.MonetaryCurrencyPrices} returns this
 */
proto.core.private.discounts.MonetaryCurrencyPrices.prototype.setCurrencyCode = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int32 value = 2;
 * @return {number}
 */
proto.core.private.discounts.MonetaryCurrencyPrices.prototype.getValue = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.core.private.discounts.MonetaryCurrencyPrices} returns this
 */
proto.core.private.discounts.MonetaryCurrencyPrices.prototype.setValue = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.core.private.discounts.CreateDiscountResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.core.private.discounts.CreateDiscountResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.core.private.discounts.CreateDiscountResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.core.private.discounts.CreateDiscountResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    discountId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    discountUniqueId: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.core.private.discounts.CreateDiscountResponse}
 */
proto.core.private.discounts.CreateDiscountResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.core.private.discounts.CreateDiscountResponse;
  return proto.core.private.discounts.CreateDiscountResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.core.private.discounts.CreateDiscountResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.core.private.discounts.CreateDiscountResponse}
 */
proto.core.private.discounts.CreateDiscountResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setDiscountId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setDiscountUniqueId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.core.private.discounts.CreateDiscountResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.core.private.discounts.CreateDiscountResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.core.private.discounts.CreateDiscountResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.core.private.discounts.CreateDiscountResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDiscountId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getDiscountUniqueId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional int32 discount_id = 1;
 * @return {number}
 */
proto.core.private.discounts.CreateDiscountResponse.prototype.getDiscountId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.core.private.discounts.CreateDiscountResponse} returns this
 */
proto.core.private.discounts.CreateDiscountResponse.prototype.setDiscountId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string discount_unique_id = 2;
 * @return {string}
 */
proto.core.private.discounts.CreateDiscountResponse.prototype.getDiscountUniqueId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.core.private.discounts.CreateDiscountResponse} returns this
 */
proto.core.private.discounts.CreateDiscountResponse.prototype.setDiscountUniqueId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * @enum {number}
 */
proto.core.private.discounts.CouponType = {
  UNSPECIFIED_CT: 0,
  SINGLE_USE: 1,
  MULTI_USE: 2
};

/**
 * @enum {number}
 */
proto.core.private.discounts.EligibleCustomers = {
  UNSPECIFIED_EC: 0,
  NEW_CUSTOMERS: 1,
  EXISTING_CUSTOMERS: 2,
  ALL_CUSTOMERS: 3
};

/**
 * @enum {number}
 */
proto.core.private.discounts.DeductionType = {
  UNSPECIFIED_DET: 0,
  PLAN_ONLY: 1,
  ENTIRE_SUBSCRIPTION: 2
};

/**
 * @enum {number}
 */
proto.core.private.discounts.DiscountType = {
  UNSPECIFIED_DIT: 0,
  PERCENTAGE: 1,
  MONETARY: 2
};

/**
 * @enum {number}
 */
proto.core.private.discounts.FrequencyType = {
  UNSPECIFIED_FT: 0,
  DAILY: 1,
  WEEKLY: 2,
  MONTHLY: 3,
  YEARLY: 4
};

goog.object.extend(exports, proto.core.private.discounts);
