import { BillsbyAction } from "../models/BillsbyAction";
import { AppState } from "..";
import { SET_DELETED_ADD_ON, SET_DELETE_DISCOUNT, SET_SHOW_DELETE_DISCOUNT_MODAL, FETCH_SUBSCRIPTION_DETAILS_SUCCESS, RESET_SUBSCRIPTION_DETAILS, FETCH_SUBSCRIPTION_ADD_ONS_SUCCESS, SET_SUBSCRIPTION_DETAILS_FIELD, SET_DELETED_ALLOWANCE, FETCH_SUBSCRIPTION_ALLOWANCES_SUCCESS, FETCH_SUBSCRIPTION_DISCOUNTS_SUCCESS, UPDATE_BILLING_DATE_REQUEST, UPDATE_BILLING_DATE_FAILURE, UPDATE_BILLING_DATE_SUCCESS, UPDATE_SUBSCRIPTION_STATUS_SUCCESS, UPDATE_SUBSCRIPTION_STATUS_FAILURE, UPDATE_SUBSCRIPTION_STATUS_REQUEST } from "../actions/subscriptionDetailsActions";
import { SubscriptionStatusType } from "../models/Subscriptions";
import { IPlanPricingModel } from "../models/Subscription"
import { VisibilityType } from "../models/Product";
import { GetAddonsResponse, Addon, Allowance, GetAllowancesResponse, SubscriptionStatus } from "../utils/grpc/generated/Billsby.Protos/billing/private/subscription/subscription_pb";
import { GetDiscountsResponse, SubscriptionDiscount } from "../utils/grpc/generated/Billsby.Protos/billing/private/subscription/discount/discount_pb";

export interface ISubscriptionDetailsReducerState {
  createdOn: Date,
  currency: string,
  customerEmail: string,
  customerFullname: string,
  customerUniqueId: string,
  customerId: number,
  nextBillingAmount: number,
  nextBillingAmountFormatted: string,
  nextCycleRecurringBillingAmount: string,
  nextBillingDate: Date,
  planId: number,
  planName: string,
  productId: number,
  productName: string,
  status: SubscriptionStatusType,
  subscriptionId?: number,
  subscriptionUniqueId: string,
  totalRevenue: number,
  totalRevenueFormatted: string,
  planPrice: string,
  planFrequency: number,
  planFrequencyType: string,
  planPricingModel: IPlanPricingModel,
  nextPlanPricingModel: IPlanPricingModel,
  cycleId: number,
  productVisibility: VisibilityType,
  visibility: VisibilityType,
  cycleIdNext?: number,
  planIdNext?: number,
  cycleSkipCounter: number,
  isBillingAddressValidationRequired: boolean,
  isShippingAddressValidationRequired: boolean,
  isShippingAddressRequired: boolean,
  units?: number,
  addOns: Array<Addon>,
  allowances: Array<Allowance>,
  addOnToEdit: number | null
  contractMinimumTermEnd?: Date,
  deletedAddOn?: Addon,
  deletedAllowance?: Allowance,
  discounts: GetDiscountsResponse,
  discountToDelete?: SubscriptionDiscount,
  showDeleteDiscountModal: boolean
  pauseSubscriptionError: boolean
  resumeSubscriptionError: boolean,
  newBillingDate?: Date,
  newStatus?: { value: SubscriptionStatusType, label: string },
  changeBillDateError: boolean,
  changeBillDateRequest: boolean,
  changeBillDateSuccess: boolean,
  changeBillErrorMessage?: string,
  newSubscriptionStatus?: { value: SubscriptionStatus, label: string },
  updateStatusError: boolean,
  updateStatusRequest: boolean,
  updateStatusSuccess: boolean,
  updateStatusErrorMessage?: string
}

export const initialState = {
  createdOn: new Date(),
  currency: "",
  customerEmail: "",
  customerFullname: "",
  customerId: 0,
  customerUniqueId: "",
  nextBillingAmount: 0,
  nextBillingAmountFormatted: "",
  nextCycleRecurringBillingAmount: "",
  nextBillingDate: new Date(),
  planId: 0,
  planName: "",
  productId: 0,
  productName: "",
  status: SubscriptionStatusType.ACTIVE,
  subscriptionId: undefined,
  subscriptionUniqueId: "",
  totalRevenue: 0,
  totalRevenueFormatted: "",
  planPrice: "",
  planFrequency: 0,
  planFrequencyType: "",
  cycleSkipCounter: 0,
  planPricingModel: {
    billingDateType: "",
    fixedBillingDateDay: undefined,
    freeTrial: 0,
    freeTrialFrequencyType: "",
    frequency: 0,
    frequencyType: undefined,
    price: 0,
    priceFormatted: "",
    pricingModelId: 0,
    proRateOption: "",
    setupFeePrice: 0,
    setupFeePriceFormatted: ""
  },
  nextPlanPricingModel: {
    billingDateType: "",
    fixedBillingDateDay: undefined,
    freeTrial: 0,
    freeTrialFrequencyType: "",
    frequency: 0,
    frequencyType: undefined,
    price: 0,
    priceFormatted: "",
    pricingModelId: 0,
    proRateOption: "",
    setupFeePrice: 0,
    setupFeePriceFormatted: ""
  },
  cycleId: 0,
  productVisibility: VisibilityType.Public,
  visibility: VisibilityType.Public,
  planIdNext: undefined,
  cycleIdNext: undefined,
  isBillingAddressValidationRequired: false,
  isShippingAddressValidationRequired: false,
  isShippingAddressRequired: false,
  addOns: [],
  allowances: [],
  addOnToEdit: null,
  contractMinimumTermEnd: undefined,
  deletedAddOn: undefined,
  deletedAllowance: undefined,
  discounts: new GetDiscountsResponse(),
  discountToDelete: undefined,
  showDeleteDiscountModal: false,
  pauseSubscriptionError: false,
  resumeSubscriptionError: false,
  changeBillDateError: false,
  changeBillDateRequest: false,
  changeBillDateSuccess: false,
  newSubscriptionStatus: undefined,
  updateStatusError: false,
  updateStatusRequest: false,
  updateStatusSuccess: false,
}

export default function subscriptionDetailsReducer(state: ISubscriptionDetailsReducerState = initialState, action: BillsbyAction, store: AppState) {
  switch (action.type) {
    case FETCH_SUBSCRIPTION_DETAILS_SUCCESS:
      const subscriptionDetails = action.response;
      return { ...state, ...subscriptionDetails }
    case FETCH_SUBSCRIPTION_ADD_ONS_SUCCESS:
      const subscriptionAddOns: GetAddonsResponse = action.response;
      return { ...state, addOns: subscriptionAddOns.getAddonsList() }
    case FETCH_SUBSCRIPTION_ALLOWANCES_SUCCESS:
      const subscriptionAllowances: GetAllowancesResponse = action.response;
      return { ...state, allowances: subscriptionAllowances.getAllowancesList() }
    case SET_SUBSCRIPTION_DETAILS_FIELD:
      return { ...state, [action.fieldName]: action.fieldValue }
    case SET_DELETED_ADD_ON:
      return { ...state, deletedAddOn: action.payload }
    case SET_DELETED_ALLOWANCE:
      return { ...state, deletedAllowance: action.payload }
    case SET_DELETE_DISCOUNT:
      return { ...state, discountToDelete: action.payload }
    case SET_SHOW_DELETE_DISCOUNT_MODAL:
      return { ...state, showDeleteDiscountModal: action.payload }
    case FETCH_SUBSCRIPTION_DISCOUNTS_SUCCESS:
      const discounts: GetDiscountsResponse = action.response;
      return { ...state, discounts: discounts }
    case UPDATE_BILLING_DATE_REQUEST:
      return { ...state, changeBillDateRequest: true, changeBillDateError: false, changeBillDateSuccess: false }
    case UPDATE_BILLING_DATE_FAILURE:
      const changeBillDateErrorMsg = action.error && action.error.list.length > 0 ? action.error.list[0].description : undefined
      return { ...state, changeBillDateRequest: false, changeBillDateError: true, changeBillDateSuccess: false, changeBillErrorMessage: changeBillDateErrorMsg }
    case UPDATE_BILLING_DATE_SUCCESS:
      return { ...state, changeBillDateRequest: false, changeBillDateError: false, changeBillDateSuccess: true }
    case UPDATE_SUBSCRIPTION_STATUS_REQUEST:
      return { ...state, updateStatusRequest: true, updateStatusError: false, updateStatusSuccess: false }
    case UPDATE_SUBSCRIPTION_STATUS_FAILURE:
      const updateStatusErrorMsg = action.error ? action.error.message : "Something went wrong while updating the status. Please try again."
      return { ...state, updateStatusRequest: false, updateStatusError: true, updateStatusSuccess: false, updateStatusErrorMessage: updateStatusErrorMsg }
    case UPDATE_SUBSCRIPTION_STATUS_SUCCESS:
      return { ...state, updateStatusRequest: false, updateStatusError: false, updateStatusSuccess: true }
    case RESET_SUBSCRIPTION_DETAILS:
      return { ...initialState }
    default:
      return state;
  }
}