import React, { useLayoutEffect, useState } from "react";
import Panel from "../../ui/panel/Panel";
import Text from "../../ui/text/Text";
import Button from "../../ui/button/Button";
import FormLabel from "../../ui/form-label/FormLabel";
import FormGroup from "../../ui/form-group/FormGroup";
import Dropdown from "../../ui/dropdown/Dropdown";
import { Row, Col } from "react-grid-system";
import counterpart from "counterpart";
import Input from "../../ui/input/Input";
import "./UpdateAddOn.scss";
import { AppState } from "../../..";
import { useSelector } from "react-redux";
import ProgressIndicator from "../../ui/progress-indicator/ProgressIndicator";
import { grpcUnaryCall } from "../../../utils/grpc/grpcUtils";
import {
  UpdateSubscriptionAddonsRequest,
  SubscriptionAddon,
  GetAddonsRequest as GetSubAddonsRequest,
  GetAddonsResponse as GetSubAddonsResponse,
  CheckoutAddonsSimulationRequest,
  CheckoutAddonsSimulationResponse,
  AddonPriceSimulation,
} from "../../../utils/grpc/generated/Billsby.Protos/billing/private/subscription/subscription_pb";
import { SubscriptionServiceClient } from "../../../utils/grpc/generated/Billsby.Protos/billing/private/subscription/SubscriptionServiceClientPb";
import { ConfigConstants } from "../../../utils/config";
import { Int32Value } from "google-protobuf/google/protobuf/wrappers_pb";
import { PricingModelType, Addon, GetAddonsResponse, GetAddonsRequest, AddonPriceModel, Currency } from "../../../utils/grpc/generated/Billsby.Protos/core/private/addons/addons_pb";
import { AddOnsServiceClient } from "../../../utils/grpc/generated/Billsby.Protos/core/private/addons/AddonsServiceClientPb";
import NoticePanel from "../../ui/notice-panel/NoticePanel";
import moment from "moment";
import { getFrequencyTypeEnum, getFrequencyTypeGrpc, getCycleFrequencyText } from "../../../utils/commonUtils";
import { FrequencyType } from "../../../models/Product";
import { SubscriptionStatusType } from "../../../models/Subscriptions";

interface IUpdateAddOn {
  addOnId?: number | null;
  handleCallback?: () => void;
}

interface IListItem {
  value: Addon;
  label: string;
}

interface IErrorMessage {
  message: string;
}

const UpdateAddOn: React.FC<IUpdateAddOn> = ({ addOnId, handleCallback }) => {
  const auth = useSelector((state: AppState) => state.auth);
  const subscriptionDetails = useSelector((state: AppState) => state.subscriptionDetailsReducer);

  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingData, setIsLoadingData] = useState(false);
  const [isSimulating, setIsSimulating] = useState(false);
  const [addOnsList, setAddOnsList] = useState<Array<IListItem>>([]);
  const [subAddOnsList, setSubAddOnsList] = useState<Array<SubscriptionAddon>>([]);
  const [addOnsChoicesList, setAddOnsChoicesList] = useState<Array<IListItem>>([]);
  const [selectedAddOn, setSelectedAddOn] = useState<IListItem | null>(null);
  const [addOnPriceModel, setAddOnPriceModel] = useState<AddonPriceModel | null>(null);
  const [units, setUnits] = useState(0);
  const [priceDetails, setPriceDetails] = useState("");
  const [simulatedAddOn, setSimulatedAddOn] = useState<AddonPriceSimulation | null>(null);
  const [errorMessage, setErrorMessage] = useState("");

  const { currentCompanyId, currentCompanyDomain, parsedToken } = auth;
  const { subscriptionId, customerId, planId, nextBillingDate, planPricingModel, subscriptionUniqueId, status, currency } = subscriptionDetails;

  const isPerUnit = selectedAddOn && selectedAddOn.value.getPricingModelType() === PricingModelType.PER_UNIT;
  const isFlatFee = selectedAddOn && selectedAddOn.value.getPricingModelType() === PricingModelType.FLAT_FEE;
  const isTiered = selectedAddOn && selectedAddOn.value.getPricingModelType() === PricingModelType.TIERED;
  const isVolume = selectedAddOn && selectedAddOn.value.getPricingModelType() === PricingModelType.VOLUME;
  const isRanged = selectedAddOn && selectedAddOn.value.getPricingModelType() === PricingModelType.RANGED;

  const isFreeTrial = status === SubscriptionStatusType.INTRIAL;

  const addonServiceClient = new AddOnsServiceClient(ConfigConstants.grpcBaseUrl);
  const subscriptionServiceClient = new SubscriptionServiceClient(ConfigConstants.grpcBaseUrl);

  const maxUnit = 1000000;

  const formattedNextBillingDate = moment(nextBillingDate).format("DD MMM YYYY");

  const getPriceModelText = () => {
    if (isVolume) {
      return counterpart("CREATE_PLAN_PRICING_VOLUME");
    }

    if (isRanged) {
      return counterpart("CREATE_PLAN_PRICING_RANGED");
    }

    if (isTiered) {
      return counterpart("CREATE_PLAN_PRICING_TIERED");
    }
  };

  const fetchData = async () => {
    setIsLoadingData(true);

    const getAddOns = new GetAddonsRequest();
    const getSubAddOns = new GetSubAddonsRequest();

    let addOnsList: Array<IListItem> = [];
    let subAddOnsList: Array<SubscriptionAddon> = [];

    getAddOns.setCompanyDomain(currentCompanyDomain);
    getSubAddOns.setSubscriptionId(subscriptionId as number);
    getSubAddOns.setCompanyId(currentCompanyId as number);

    try {
      const getAddOnsReponse = (await grpcUnaryCall(getAddOns, addonServiceClient, addonServiceClient.getAddons)) as GetAddonsResponse;
      const getSubAddOnsResponse = (await grpcUnaryCall(getSubAddOns, subscriptionServiceClient, subscriptionServiceClient.getAddons)) as GetSubAddonsResponse;

      getSubAddOnsResponse.getAddonsList().forEach((addOn) => {
        const subAddOn = new SubscriptionAddon();
        subAddOn.setAddonId(addOn.getId());
        subAddOn.setQuantity(addOn.getQuantity());

        subAddOnsList.push(subAddOn);
      });

      getAddOnsReponse.getAddonsList().forEach((addOn) => {
        if (addOn.getPlansList().find((plan) => plan.getId() === planId)) {
          addOnsList.push({ label: addOn.getName(), value: addOn });
        }
      });

      setAddOnsList(addOnsList);
      setSubAddOnsList(subAddOnsList);
      setIsLoadingData(false);
    } catch (err) {
      console.log(err);
      setIsLoadingData(false);
    }
  };

  const fetchSimulation = async () => {
    if (!selectedAddOn) {
      return;
    }

    setIsSimulating(true);
    setSimulatedAddOn(null);

    const newSubAddOn = new SubscriptionAddon();

    newSubAddOn.setAddonId(selectedAddOn.value.getId());
    newSubAddOn.setQuantity(units);

    const addOnSimulation = new CheckoutAddonsSimulationRequest();
    addOnSimulation.setSubscriptionUniqueId(subscriptionUniqueId);
    addOnSimulation.setCompanyDomain(currentCompanyDomain);
    addOnSimulation.setSubscriptionAddonsList(
      addOnId
        ? subAddOnsList.map((addOn) => {
          if (addOn.getAddonId() === addOnId) {
            addOn.setQuantity(units);
            return addOn;
          } else return addOn;
        })
        : [...subAddOnsList, newSubAddOn]
    );

    try {
      const addonsSimulationResponse = (await grpcUnaryCall(addOnSimulation, subscriptionServiceClient, subscriptionServiceClient.checkoutAddonsSimulation)) as CheckoutAddonsSimulationResponse;
      const simulatedAddOn = addonsSimulationResponse.getPriceSimulationsList().find((simulated) => simulated.getAddonId() === selectedAddOn.value.getId());
      setSimulatedAddOn(simulatedAddOn || null);
      setIsSimulating(false);
    } catch (err) {
      console.log(err);
      setIsSimulating(false);
      setSimulatedAddOn(null);
    }
  };

  const onSubmit = async (evt: any) => {
    evt.preventDefault();

    setErrorMessage("");
    if (!selectedAddOn) {
      return;
    }

    setIsLoading(true);

    const updateAddOn = new UpdateSubscriptionAddonsRequest();
    const userId = new Int32Value();
    const newSubAddOn = new SubscriptionAddon();

    userId.setValue(parsedToken ? Number(parsedToken.nameid) : 0);

    newSubAddOn.setQuantity(units);
    newSubAddOn.setAddonId(selectedAddOn.value.getId());

    updateAddOn.setCompanyId(currentCompanyId as number);
    updateAddOn.setSubscriptionId(subscriptionId as number);
    updateAddOn.setRequestedByUserId(userId);

    updateAddOn.setSubscriptionAddonsList(
      addOnId
        ? subAddOnsList.map((addOn) => {
          if (addOn.getAddonId() === addOnId) {
            addOn.setQuantity(units);
            return addOn;
          } else return addOn;
        })
        : [...subAddOnsList, newSubAddOn]
    );

    try {
      await grpcUnaryCall(updateAddOn, subscriptionServiceClient, subscriptionServiceClient.updateSubscriptionAddons);
      handleCallback && (await handleCallback());
    } catch (err) {
      setIsLoading(false);
      setErrorMessage((err as IErrorMessage).message);
    }
  };

  useLayoutEffect(() => {
    fetchData();
  }, []);

  useLayoutEffect(() => {
    if (addOnId) {
      const addOn = addOnsList.find((addOn) => addOn.value.getId() === addOnId);
      const subAddOn = subAddOnsList.find((subAddOn) => (addOn ? addOn.value.getId() === subAddOn.getAddonId() : false));

      setSelectedAddOn(addOn || null);
      setUnits(subAddOn ? subAddOn.getQuantity() : 0);
    }

    const addOnsChoicesList = addOnsList.filter((addOn) => subAddOnsList.every((subAddOn) => addOn.value.getId() !== subAddOn.getAddonId())).sort((a: any, b: any) => a.label - b.label);
    setAddOnsChoicesList(addOnsChoicesList);
  }, [addOnId, addOnsList, subAddOnsList]);

  useLayoutEffect(() => {
    if (selectedAddOn) {
      const priceModel = selectedAddOn.value
        .getPriceModelsList()
        .find((addOn) =>
          (addOn.getCurrency() as Currency).getIso3Code() === currency
          && addOn.getFrequency() === planPricingModel.frequency
          && addOn.getFrequencyType() === getFrequencyTypeGrpc(planPricingModel.frequencyType as FrequencyType));
      setAddOnPriceModel(priceModel || null);
    }
  }, [selectedAddOn]);

  useLayoutEffect(() => {
    let priceDetails = "";

    if (!selectedAddOn || !addOnPriceModel) {
      return;
    }

    if (simulatedAddOn) {
      priceDetails = counterpart("ADD_ADD_ON_SUMMARY", {
        date: formattedNextBillingDate,
        nextBillingAmount: simulatedAddOn.getRegularAmountFormatted(),
        frequency: getCycleFrequencyText(addOnPriceModel.getFrequency(), getFrequencyTypeEnum(addOnPriceModel.getFrequencyType())),
      });

      return setPriceDetails(priceDetails);
    }

    if (isPerUnit) {
      priceDetails = counterpart("ADD_ADD_ON_PER_UNIT", { price: addOnPriceModel.getPerUnitPriceFormatted() });
    }

    if (isRanged || isTiered || isVolume) {
      addOnPriceModel
        .getTiersList()
        .sort((a, b) => a.getStart() - b.getStart())
        .forEach((tier, idx) => {
          priceDetails += counterpart("ADD_ADD_ON_PAYMENT_DETAILS", {
            price: tier.getPriceFormatted(),
            unitRange: `${tier.getStart()}${
              tier.getFinish() === maxUnit ? `+ (${getPriceModelText()})` : `-${tier.getFinish()}${addOnPriceModel.getTiersList().length - 1 === idx ? ` (${getPriceModelText()})` : ", "}`
              }`,
          });
        });
    }

    if (!simulatedAddOn && units) {
      priceDetails = counterpart("UNIT_OUT_OF_RANGE");
    }

    setPriceDetails(priceDetails);
  }, [addOnPriceModel, simulatedAddOn]);

  useLayoutEffect(() => {
    if (!units && !isFlatFee) {
      setSimulatedAddOn(null)
      return;
    }

    const timeout = setTimeout(() => {
      fetchSimulation()
    }, 500);

    return () => clearTimeout(timeout);
  }, [units, selectedAddOn]);

  const renderContent = () => {
    if (isLoadingData) {
      return <ProgressIndicator color="blue" coverage="normal" noPadding />;
    }

    return (
      <>
        <FormGroup noMargin={!selectedAddOn || isFlatFee}>
          <Row align="center">
            <Col xs={2}>
              <FormLabel target="select-add-on" content="ADD_ADD_ON_CHOOSE_ADD_ON" className={addOnId ? "update-add-on__label--disabled" : ""} noMargin />
            </Col>
            <Col xs={10}>
              {addOnId ? (
                <Input id="select-add-on" value={selectedAddOn ? selectedAddOn.value.getName() : ""} disabled={true} />
              ) : (
                  <Dropdown
                    id="select-add-on"
                    value={selectedAddOn}
                    onChange={(value: IListItem) => {
                      setSelectedAddOn(value);
                      setUnits(0);
                    }}
                    options={addOnsChoicesList}
                    placeholder={counterpart("ADD_ADD_ON_SELECT_YOUR_ADD_ON")}
                  />
                )}
            </Col>
          </Row>
        </FormGroup>
        {selectedAddOn && (
          <div>
            <Row align="center">
              {!isFlatFee && (
                <>
                  <Col xs={2}>
                    <FormLabel target="quantity-units" content="ADD_ADD_ON_QUANTITY_UNITS" noMargin />
                  </Col>
                  <Col xs={10}>
                    <Input id="quantity-units" type="number" placeholder="0" value={units === 0 ? "" : units} onChange={(evt: any) => setUnits(+evt.target.value)} />
                  </Col>
                </>
              )}
              {
                <Col offset={{ xs: 2 }} xs={10}>
                  {isSimulating ? (
                    <ProgressIndicator className="update-add-on__summary-loader" color="blue" align="left" noPadding />
                  ) : (
                      <Text className="update-add-on__summary" content={priceDetails} shouldTranslate={false} noMargin />
                    )}
                </Col>
              }
            </Row>
          </div>
        )}
      </>
    );
  };

  return (
    <div className="update-add-on">      
      <Panel className={`update-add-on__title-panel ${addOnId ? "edit" : ""}`} title={addOnId ? "EDIT_ADD_ON_TITLE" : "ADD_ADD_ON_TITLE"}>
        <Text
          className="update-add-on__title-text"
          content={addOnId ? "EDIT_ADD_ON_DESCRIPTION" : isFreeTrial ? "ADD_ADD_ON_DESCRIPTION_TRIAL" : "ADD_ADD_ON_DESCRIPTION"}
          translateWith={{ date: <span className="text-blue">{formattedNextBillingDate}</span> }}
          noMargin
        />
      </Panel>

      <form onSubmit={onSubmit}>        
        {!!errorMessage && <NoticePanel
          type="error"
          content={errorMessage}
          shouldTranslate={false}
        />}
        {!addOnId && addOnsChoicesList.length === 0 && !isLoadingData ? (
          <NoticePanel type="warning" content="ADD_ADD_ON_NOTICE" />
        ) : (
            <>
              <Panel>{renderContent()}</Panel>
              <Button
                disabled={!simulatedAddOn}
                isLoading={isLoading}
                id="update-add-on"
                type="submit"
                buttonType="general"
                title={addOnId ? "EDIT_ADD_ON_BTN" : simulatedAddOn ? "ADD_ADD_ON_PAY_BTN" : "ADD_ADD_ON_BTN"}
                translateWith={{ amount: simulatedAddOn && simulatedAddOn.getAmountFormatted() }}
                isFullWidth
              />
            </>
          )}
      </form>
    </div>
  );
};

export default UpdateAddOn;
