import React, { useEffect } from "react";
import "./SetupAccount.scss";
import Quiz from "../../../components/quiz/Quiz";
import { useDispatch, useSelector } from "react-redux";
import { setIsDoingQuiz } from "../../../actions/registrationActions";
import { AppState } from "../../..";
import { IRegistrationReducerState } from "../../../reducers/registrationReducer";
import { loginUser } from "../../../actions/loginActions";
import { setStoreType } from "../../../utils/storeUtils";
import { COOKIE_STORAGE } from "../../../utils/constants";
import { IAuthReducerState } from "../../../reducers/authReducer";

const SetupAccount: React.FC = () => {

    const { workEmail, password, company: companyName } = useSelector<AppState, IRegistrationReducerState>(state => state.registration);
    const { companies } = useSelector<AppState, IAuthReducerState>(state => state.auth);
    const dispatch = useDispatch<Function>()
    const company = companies.find(company => company.name === companyName) || companies[0];

    useEffect(() => {
      dispatch(setIsDoingQuiz(true));
    }, []);

    if (!companies.length) {
      return null;
    }

    return (
      <div className="setup-account">
        {/*<Panel className="setup-account__titlepanel">
          <Text content="SETUP_ACCOUNT_TITLE" />
          <Text content="SETUP_ACCOUNT_SCALE_CONTENT" noMargin />
        </Panel>*/}

        <Quiz
          currencySymbol="$"
          companyName={company.companyDomain}
          email={workEmail}
          password={password}
          onFinishCb={() => {
            setStoreType(COOKIE_STORAGE);
            dispatch(loginUser(workEmail, password))
            //.then(() => dispatch(setIsDoingQuiz(false)))
            //restore cookie storage mechanism 
          }}
        />
      </div>
    )
  }

export default SetupAccount