import React, { useState, useEffect } from "react";
import BackNavigationPanel from "../../../../../ui/back-navigation-panel/BackNavigationPanel";
import MenuTable from "../../../../../ui/menu-table/MenuTable";
import { EmailTypes, UpdateEmailNotificationStatusRequest, UpdateEmailNotificationStatusResponse } from "../../../../../../utils/grpc/generated/Billsby.Protos/Messenger/private/companyconfig/companyconfig_pb";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../../../../../..";
import { updateEmailNotificationToggles, fetchEmailNotificationToggles } from "../../../../../../actions/emailRoutingActions";
import { useHistory } from "react-router";

const WelcomeEmailsMenu: React.FC = () => {
  const emailNotificationToggles = useSelector((state: AppState) => state.emailRoutingReducer.emailNotificationToggles);
  const currentCompanyId = useSelector((state: AppState) => state.auth.currentCompanyId);

  const dispatch = useDispatch<Function>();
  const history = useHistory();

  const [switchState, setSwitchState] = useState({
    newSubscriptionWithTrial: false,
    newSubscriptionWithoutTrial: false,
    newCustomerCreatedWithoutSubscription: false,
    subscriptionAddOnCreated: false,
    newAllowanceAppliedToSubscription: false
  });

  const updateData = async (updateEmailNotification: UpdateEmailNotificationStatusRequest) => {
    try {
      await (dispatch(updateEmailNotificationToggles(updateEmailNotification)) as { response: UpdateEmailNotificationStatusResponse });
      dispatch(fetchEmailNotificationToggles(Number(currentCompanyId)));
    }
    catch (err) { }
  }

  useEffect(() => {
    if (emailNotificationToggles.getEmailNotificationConfigsList().length) {
      let newState: typeof switchState = { ...switchState };
      emailNotificationToggles.getEmailNotificationConfigsList().forEach((elem) => {
        switch (elem.getEmailType()) {
          case EmailTypes.NEW_TRIAL_SUBSCRIPTION:
            newState.newSubscriptionWithTrial = elem.getIsEnabled();
            break;
          case EmailTypes.NEW_SUBSCRIPTION:
            newState.newSubscriptionWithoutTrial = elem.getIsEnabled();
            break;
          case EmailTypes.NEW_CUSTOMER_WITHOUT_SUBSCRIPTION:
            newState.newCustomerCreatedWithoutSubscription = elem.getIsEnabled();
            break;
          case EmailTypes.SUBSCRIPTION_ADDON_CREATED:
            newState.subscriptionAddOnCreated = elem.getIsEnabled();
            break;
          case EmailTypes.NEW_ALLOWANCE_APPLIED_TO_EXISTING_SUBSCRIPTION:
            newState.newAllowanceAppliedToSubscription = elem.getIsEnabled();
            break;
        }
      })
      setSwitchState(newState);
    }
  }, [emailNotificationToggles]);

  useEffect(() => {
    dispatch(fetchEmailNotificationToggles(Number(currentCompanyId)));
  }, []);


  return (
    <div>
      <BackNavigationPanel
        title={"EMAILS_MENU_SUBSCRIPTION_LIFECYCLE_WELCOME_EMAILS"} shouldTranslateTitle={true}
        onClick={() => history.push("/configuration/email-notifications/emails")}>
        <MenuTable
          data={[
            {
              title: "EMAILS_MENU_SUBSCRIPTION_LIFECYCLE_WELCOME_EMAILS_NEW_SUBSCRIPTION_WITH_TRIAL",
              onClick: () => history.push("/configuration/email-notifications/emails/welcome-emails/new-subscription-with-trial-template"),
              canConfigureNotification: true,
              enableNotification: switchState.newSubscriptionWithTrial,
              enableNotificationChange: (isChecked) => {
                setSwitchState({ ...switchState, newSubscriptionWithTrial: isChecked });
                const updateEmailNotification = new UpdateEmailNotificationStatusRequest();
                updateEmailNotification.setCompanyId(Number(currentCompanyId));
                updateEmailNotification.setEmailType(EmailTypes.NEW_TRIAL_SUBSCRIPTION);
                updateEmailNotification.setIsEnabled(isChecked);
                updateData(updateEmailNotification);
              }
            },
            {
              title: "EMAILS_MENU_SUBSCRIPTION_LIFECYCLE_WELCOME_EMAILS_NEW_SUBSCRIPTION_WITHOUT_TRIAL",
              onClick: () => history.push("/configuration/email-notifications/emails/welcome-emails/new-subscription-without-trial-template"),
              canConfigureNotification: true,
              enableNotification: switchState.newSubscriptionWithoutTrial,
              enableNotificationChange: (isChecked) => {
                setSwitchState({ ...switchState, newSubscriptionWithoutTrial: isChecked });
                const updateEmailNotification = new UpdateEmailNotificationStatusRequest();
                updateEmailNotification.setCompanyId(Number(currentCompanyId));
                updateEmailNotification.setEmailType(EmailTypes.NEW_SUBSCRIPTION);
                updateEmailNotification.setIsEnabled(isChecked);
                updateData(updateEmailNotification);
              }
            },
            {
              title: "EMAILS_MENU_SUBSCRIPTION_LIFECYCLE_WELCOME_EMAILS_NEW_CUSTOMER_WITHOUT_SUBSCRIPTION",
              onClick: () => history.push("/configuration/email-notifications/emails/welcome-emails/new-customer-without-subscription-template"),
              canConfigureNotification: true,
              enableNotification: switchState.newCustomerCreatedWithoutSubscription,
              enableNotificationChange: (isChecked) => {
                setSwitchState({ ...switchState, newCustomerCreatedWithoutSubscription: isChecked });
                const updateEmailNotification = new UpdateEmailNotificationStatusRequest();
                updateEmailNotification.setCompanyId(Number(currentCompanyId));
                updateEmailNotification.setEmailType(EmailTypes.NEW_CUSTOMER_WITHOUT_SUBSCRIPTION);
                updateEmailNotification.setIsEnabled(isChecked);
                updateData(updateEmailNotification);
              }
            },
            {
              title: "EMAIL_TEMPLATE_NEW_ADD_ON",
              onClick: () => history.push("/configuration/email-notifications/emails/welcome-emails/new-add-on-template"),
              canConfigureNotification: true,
              enableNotification: switchState.subscriptionAddOnCreated,
              enableNotificationChange: (isChecked) => {
                setSwitchState({ ...switchState, subscriptionAddOnCreated: isChecked });
                const updateEmailNotification = new UpdateEmailNotificationStatusRequest();
                updateEmailNotification.setCompanyId(Number(currentCompanyId));
                updateEmailNotification.setEmailType(EmailTypes.SUBSCRIPTION_ADDON_CREATED);
                updateEmailNotification.setIsEnabled(isChecked);
                updateData(updateEmailNotification);
              }
            },
            {
              title: "EMAIL_TEMPLATE_NEW_ALLOWANCE",
              onClick: () => history.push("/configuration/email-notifications/emails/welcome-emails/new-allowance-template"),
              canConfigureNotification: true,
              enableNotification: switchState.newAllowanceAppliedToSubscription,
              enableNotificationChange: (isChecked) => {
                setSwitchState({ ...switchState, newAllowanceAppliedToSubscription: isChecked });
                const updateEmailNotification = new UpdateEmailNotificationStatusRequest();
                updateEmailNotification.setCompanyId(Number(currentCompanyId));
                updateEmailNotification.setEmailType(EmailTypes.NEW_ALLOWANCE_APPLIED_TO_EXISTING_SUBSCRIPTION);
                updateEmailNotification.setIsEnabled(isChecked);
                updateData(updateEmailNotification);
              }
            }
          ]}
        />
      </BackNavigationPanel>
    </div>
  )
}

export default WelcomeEmailsMenu;
