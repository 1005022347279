import React from "react";
import "./BrandingEmailPreview.scss";
import Text from "../../../../ui/text/Text";
import { AppState } from "../../../../..";
import { useSelector } from "react-redux";
import Panel from "../../../../ui/panel/Panel";
import Button from "../../../../ui/button/Button";
import EmailPreivewContainer from "./EmailPreivewContainer";

const BrandingEmailPreview: React.FC = () => {
  const companyBrandingReducer = useSelector((state: AppState) => state.companyBrandingReducer);
  const authReducerData = useSelector((state: AppState) => state.auth);

  const { highlightColor } = companyBrandingReducer;
  const { companyName } = authReducerData;
  const defaultHighLightColor = "#F87847";
  return (

    <div className="branding-email-preview__container">
      <div className="branding-email-preview__container__panel">
        <EmailPreivewContainer className="branding-email-preview__content">
          <Text
            content="BRAND_CUSTOMISATION_EMAIL_PREVIEW_TITLE"
            className="email-preview__details__text email-preview__details__text-title"
            component="span"
            noMargin
          />

          <Text
            content="BRAND_CUSTOMISATION_EMAIL_PREVIEW_GREETINGS"
            className="email-preview__details__text"
            component="span"
            noMargin
          />

          <Text
            content="BRAND_CUSTOMISATION_EMAIL_PREVIEW_MESSAGE"
            className="email-preview__details__text"
            component="span"
            noMargin
          />

          <div className="email-preview__details__button">
            <Button
              id="select-plan-continue"
              type="button"
              icon="far fa-arrow-right"
              iconPosition="right"
              className="email-preview__details__button-orange"
              title="BRAND_CUSTOMISATION_EMAIL_PREVIEW_PAY_NOW"
              style={{ backgroundColor: !!highlightColor ? highlightColor : defaultHighLightColor }}
            />
          </div>

          <Text
            content="BRAND_CUSTOMISATION_EMAIL_PREVIEW_REGARDS"
            className="email-preview__details__text"
            component="span"
            noMargin
          />

          <Text
            content={companyName}
            className="email-preview__details__text"
            component="span"
            shouldTranslate={false}
            noMargin
          />

          <Panel className="email-preview__details__note-panel" noMargin>
            <Text
              content="BRAND_CUSTOMISATION_EMAIL_PREVIEW_NOTE"
              component="span"
              noMargin
            />
          </Panel>
        </EmailPreivewContainer>
      </div>
    </div>
  )
}

export default BrandingEmailPreview;

