import React, { useEffect, useMemo, useState } from "react"
import StepCollapsablePanel from "../../../../ui/step-collapsable-panel/StepCollapsablePanel";
import Button from "../../../../ui/button/Button";
import Text from "../../../../ui/text/Text";
import counterpart from "counterpart";
import { IPaymentGatewaysReducer } from "../../../../../reducers/paymentGatewaysReducer";
import { IPaymentGatewayElement, PaymentGatewaysType } from "../../../../../models/Configuration";
import FormGroup from "../../../../ui/form-group/FormGroup";
import { Row, Col } from "react-grid-system";
import FormLabel from "../../../../ui/form-label/FormLabel";
import Input from "../../../../ui/input/Input";
import { IScaState } from "../../../../../reducers/scaReducer";
import { resetScaSettingsSetup, setScaField, setStripScaValues, setupScaSettings } from "../../../../../actions/scaActions";
import "../Sca.scss";
import StatusLabel from "../../../../ui/status-label/StatusLabel";
import NoticePanel from "../../../../ui/notice-panel/NoticePanel";
import Panel from "../../../../ui/panel/Panel";
import Checkbox from "../../../../ui/checkbox/Checkbox";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../../../../..";

interface Props {
  gateway: IPaymentGatewayElement
}

const StripeScaPaymentGateway: React.FC<Props> = ({ gateway }) => {
  const [isCollapsed, setIsCollapsed] = useState(true);
  const [isConfirmed, setIsConfirmed] = useState(false);
  const [isChecked, setIsChecked] = useState(false);
  const { paymentGatewaysLogos } = useSelector<AppState, IPaymentGatewaysReducer>(state => state.paymentGatewaysReducer);
  const { stripeScaConfig, isSettingUpSca, selectedPaymentgatewayId, hasPreviouslySetupSca } = useSelector<AppState, IScaState>(state => state.scaReducer);
  const dispatch = useDispatch<Function>()

  useEffect(() => {
    if(selectedPaymentgatewayId === gateway.paymentGatewayId) {
      setIsCollapsed(false);
      if(hasPreviouslySetupSca) {
        setIsConfirmed(true);
      } else {
        setIsConfirmed(false)
      }
    }
  }, [selectedPaymentgatewayId, gateway, hasPreviouslySetupSca])


  const [helpTitle, setHelpTitle] = useState("SCA_STRIPE_HELP_TITLE");
  const [helpContentFor, setHelpContentFor] = useState<"webhook-id" | "webhook-signing-secret" | "api-secret-key" | undefined>(undefined);
  const [isInitiateTesTransaction, setIsInitiateTesTransaction] = useState(false);

  const getHelpMenuContent = useMemo(() => {
    switch (helpContentFor) {
      case "webhook-id": {
        return <>
          <Text content={"SCA_STRIPE_HELP_WEBHOOK_ID_CONTENT_1"} className="sca__help-text text-inline" />
          <Text content={"SCA_STRIPE_HELP_WEBHOOK_ID_CONTENT_1_1"} weight="bold" className="sca__help-text text-inline" />
          <Text content={"SCA_STRIPE_HELP_WEBHOOK_ID_CONTENT_1_2"} className="sca__help-text text-inline" />
          <Text content={"SCA_STRIPE_HELP_WEBHOOK_ID_CONTENT_1_3"} weight="bold" className="sca__help-text text-inline" />
          <Text content={"SCA_STRIPE_HELP_WEBHOOK_ID_CONTENT_1_4"} className="sca__help-text text-inline" />
        </>
      }
      case "webhook-signing-secret": {
        return <>
          <Text content={"SCA_STRIPE_HELP_WEBHOOK_SECRET_CONTENT_1"} className="sca__help-text text-inline" />
        </>
      }
      case "api-secret-key": {
        return <>
          <Text content={"SCA_STRIPE_API_SECRET_KEY_CONTENT_1"} />
          <Text content={"SCA_STRIPE_API_SECRET_KEY_CONTENT_2"} />
        </>
      }
      default: {
        return <>
          <div>
            <Text content={"SCA_STRIPE_HELP_CONTENT_1"} className="sca__help-text text-inline" />
            <Text content={"SCA_STRIPE_HELP_CONTENT_1_1"} className="sca__help-text text-inline link-text" onClick={() => window.open("https://stripe.com/docs/webhooks")} />
            <Text content={"SCA_STRIPE_HELP_CONTENT_1_2"} className="sca__help-text text-inline" />
            <Text content={"SCA_STRIPE_HELP_CONTENT_1_3"} weight="bold" className="sca__help-text text-inline" />
            <Text content={"SCA_STRIPE_HELP_CONTENT_1_4"} className="sca__help-text text-inline" />
            <Text content={"SCA_STRIPE_HELP_CONTENT_1_5"} weight="bold" className="sca__help-text text-inline" />
            <Text content={"SCA_STRIPE_HELP_CONTENT_1_6"} className="sca__help-text text-inline" />
          </div>
          <div className="sca__help-text__container">
            <Text content={"SCA_STRIPE_HELP_CONTENT_2"} />
          </div>
          <div className="sca__help-text__container">
            <Text content={"SCA_STRIPE_HELP_CONTENT_3"} className="sca__help-text text-inline" />
            <Text content={"SCA_STRIPE_HELP_CONTENT_3_1"} weight="bold" className="sca__help-text text-inline" />
          </div>
          <div className="sca__help-text__container">
            <Text content={"SCA_STRIPE_HELP_CONTENT_4"} className="sca__help-text text-inline" />
            <Text content={"SCA_STRIPE_HELP_CONTENT_4_1"} weight="bold" className="sca__help-text text-inline" />
          </div>
        </>
      }
    }
  }, [helpContentFor])

  const cancelEnableSCA = () => {
    dispatch(resetScaSettingsSetup());
    setIsConfirmed(false);
    setIsChecked(false);
    setIsCollapsed(true);
    setIsInitiateTesTransaction(false);
    dispatch(setScaField("hasPreviouslySetupSca", false))
  }

  const renderConfirmationModal = () => {
    if(isCollapsed || isConfirmed) {
      return null
    }

    return (
      <div className="sca-container__overlay">
        <Panel className="sca-container__modal" noMargin>
          <NoticePanel isModal type="warning" content="SCA_STRIPE_WARNING_TITLE"></NoticePanel>
          <Text content="SCA_STRIPE_WARNING_CONTENT" translateWith={{ id: <b>{counterpart("SCA_STRIPE_WARNING_ID")}</b> }}></Text>
          <Checkbox checked={isChecked} onClick={() => setIsChecked(!isChecked)} value='' content={counterpart("SCA_STRIPE_WARNING_CHECKBOX")} />
          <div className="sca-container__footer">
           <Button isFullWidth disabled={!isChecked} id="sca-confirm" title="SCA_STRIPE_WARNING_BTN" onClick={() => setIsConfirmed(true)}></Button>
           <Button
            id="sca-cancel-btn-modal"
            buttonType="error"
            title="SCA_CANCEL_BUTTON"
            onClick={cancelEnableSCA}
          />
          </div>
        </Panel>
      </div>
    )
  }

  const getTestTransactionHelp = () => (
    <>
      <div>
        <Text content={"SCA_STRIPE_HELP_TEST_TRANSACTION_TITLE"} weight="bold" className="sca__help-text text-title" />
        <Text content={"SCA_STRIPE_HELP_TEST_TRANSACTION_CONTENT"} translateWith={{minChargeAmount: gateway.minChargeAmountFormmated}} className="sca__help-text" />
      </div>
    </>)

  return (
    <div className="sca-container">
    {renderConfirmationModal()}
    <StepCollapsablePanel
      isCollapsed={isCollapsed}
      className={`sca__gateway ${isInitiateTesTransaction ? "initiate-transaction" : ""}`}
      isLocked={isSettingUpSca && selectedPaymentgatewayId !== gateway.paymentGatewayId}
      helpMenuPosition={isInitiateTesTransaction ? "top" : "right"}
      helpMenuTitle={!isInitiateTesTransaction ? helpTitle : ""}
      shouldTranslateHelpMenuTitle={!isInitiateTesTransaction}
      helpMenuContent={!isInitiateTesTransaction ?
        <div>
          {getHelpMenuContent}
        </div> : getTestTransactionHelp()
      }
      shouldTranslateTitle={false}
      title={
        <div className="payment-gateways__added-title">
          <div>
            <img src={paymentGatewaysLogos.Stripe} alt="no img" />
            <div>
              {<Text content={gateway.title} shouldTranslate={false} noMargin />}
              <Text content={gateway.type === PaymentGatewaysType.STRIPEINTENT ? PaymentGatewaysType.STRIPE : gateway.type} shouldTranslate={false} noMargin />
            </div>
          </div>
          <div>
            <Button
              id="visit-website"
              title="PAYMENT_GATEWAYS_VISIT_WEBSITE_STRIPE"
              onClick={(evt: any) => {
                evt.stopPropagation();
                window.open("https://stripe.com/", "_self");
              }}
              buttonType="add"
              isVisible={!isCollapsed}
            />
          </div>
          {
            gateway.isScaActivated ? <StatusLabel icon="far fa-check-circle" iconPosition="left" content="SCA_STRIPE_ENABLED_SCA"></StatusLabel> : (
            <Button
              id="enable-sca"
              title="SCA_STRIPE_ENABLE_SCA"
              onClick={(evt: any) => {
                dispatch(setupScaSettings(gateway.paymentGatewayId));
              }}
              disabled={isSettingUpSca && selectedPaymentgatewayId !== gateway.paymentGatewayId}
              buttonType="add"
              isVisible={isCollapsed}
              className="sca__enable-sca"
            />
            )
          }
        </div>
      }
    >

      <div>
        {!isInitiateTesTransaction ? <>
          <form autoComplete="off"> 
          <Text content="SCA_STRIPE_DESCRIPTION" />
          <FormGroup>
            <Row align="center">
              <Col xs={2}>
                <FormLabel target="webhook-id" content="SCA_STRIPE_WEBHOOK_ID" noMargin />
              </Col>
              <Col xs={10}>
                <Input
                  autocomplete="off"
                  id="webhook-id"
                  onFocus={() => {
                    setHelpTitle("SCA_STRIPE_HELP_WEBHOOK_ID_TITLE");
                    setHelpContentFor("webhook-id");
                  }}
                  onBlur={() => {
                    setHelpTitle("SCA_STRIPE_HELP_TITLE");
                    setHelpContentFor(undefined);
                  }}
                  placeholder={counterpart("SCA_STRIPE_WEBHOOK_ID_PLACEHOLDER")}
                  value={stripeScaConfig?.webhookId}
                  onChange={(evt: any) => isConfirmed && dispatch(setStripScaValues({ ...stripeScaConfig, webhookId: evt.target.value }))}
                />
              </Col>
            </Row>
          </FormGroup>

          <FormGroup>
            <Row align="center">
              <Col xs={2}>
                <FormLabel target="webhook-signing-secret" content="SCA_STRIPE_WEBHOOK_SIGNING_SECRET" noMargin />
              </Col>
              <Col xs={10}>
                <Input
                  autocomplete="off"
                  id="webhook-signing-secret"
                  onFocus={() => {
                    setHelpTitle("SCA_STRIPE_HELP_WEBHOOK_SECRET_TITLE");
                    setHelpContentFor("webhook-signing-secret");
                  }}
                  onBlur={() => {
                    setHelpTitle("SCA_STRIPE_HELP_TITLE");
                    setHelpContentFor(undefined);
                  }}
                  placeholder={counterpart("SCA_STRIPE_WEBHOOK_SIGNING_SECRET_PLACEHOLDER")}
                  value={stripeScaConfig?.webhookSigningSecret}
                  onChange={(evt: any) => isConfirmed && dispatch(setStripScaValues({ ...stripeScaConfig, webhookSigningSecret: evt.target.value }))}
                />
              </Col>
            </Row>
          </FormGroup>

          <FormGroup>
            <Row align="center">
              <Col xs={2}>
                <FormLabel target="api-secret-key" content="SCA_STRIPE_API_SECRET_KEY" noMargin />
              </Col>
              <Col xs={10}>
                <Input
                  autocomplete="off"
                  id="api-secret-key"
                  onFocus={() => {
                    setHelpTitle("SCA_STRIPE_API_SECRET_KEY");
                    setHelpContentFor("api-secret-key");
                  }}
                  onBlur={() => {
                    setHelpTitle("SCA_STRIPE_HELP_TITLE");
                    setHelpContentFor(undefined);
                  }}
                  placeholder={counterpart("SCA_STRIPE_API_SECRET_KEY")}
                  value={stripeScaConfig?.apiSecretKey}
                  onChange={(evt: any) => isConfirmed && dispatch(setStripScaValues({ ...stripeScaConfig, apiSecretKey: evt.target.value }))}
                />
              </Col>
            </Row>
          </FormGroup>
           </form>       
        </> : <></>}
        <div className="sca__payment-gateways__btns">
          <Button
            id="sca-continue-btn"
            title={isInitiateTesTransaction ? "SCA_INITIATE_TEST_TRANSACTION_BUTTON" : "SCA_CONTINUE_BUTTON"}
            disabled={!stripeScaConfig?.webhookId || !stripeScaConfig?.webhookSigningSecret || !stripeScaConfig.apiSecretKey}
            onClick={async (evt: any) => {
              if(!isConfirmed) return;
              setIsInitiateTesTransaction(true);
              isInitiateTesTransaction && dispatch(setScaField("showTestModal", true))
            }}
          />
          <Button
            id="sca-cancel-btn"
            buttonType="error"
            title="SCA_CANCEL_BUTTON"
            onClick={cancelEnableSCA}
          />
        </div>
      </div>
    </StepCollapsablePanel>
    </div>
  )
}

export default StripeScaPaymentGateway;
