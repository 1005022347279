import React, { useLayoutEffect, useState } from "react";
import { grpcUnaryCall } from "../../../utils/grpc/grpcUtils";
import { GetMonthsRevenueRequest, GetMonthsRevenueResponse } from "../../../utils/grpc/generated/Billsby.Protos/revenues/private/company/company_pb";
import { CompanyServiceClient } from "../../../utils/grpc/generated/Billsby.Protos/revenues/private/company/CompanyServiceClientPb";
import { ConfigConstants } from "../../../utils/config";
import BillingRevenue from "../billing-revenue/BillingRevenue";
import ErrorNotification from "../../ui/error-notification/ErrorNotification";
import { GettingStartedType } from "../../../models/GettingStarted";
import { useSelector } from "react-redux";
import { AppState } from "../../..";


const BillingLive: React.FC = () => {
  const auth = useSelector((state: AppState) => state.auth);

  const [isLoading, setIsLoading] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [monthsRevenue, setMonthsRevenue] = useState<GetMonthsRevenueResponse | undefined>(undefined);
  const { dashboardSettings } = auth;
  const status = !!dashboardSettings ? dashboardSettings.status : undefined;

  const getMonthsRevenue = async (companyId: number) => {
    const getMonthsRevenueRequest = new GetMonthsRevenueRequest();
    const currentDate = new Date();
    getMonthsRevenueRequest.setCompanyId(companyId);
    getMonthsRevenueRequest.setMonth(currentDate.getMonth() + 1);
    getMonthsRevenueRequest.setYear(currentDate.getFullYear());
    const companyServiceClient = new CompanyServiceClient(ConfigConstants.grpcBaseUrl);
    
    setIsLoading(true);
    try {
      const res = await grpcUnaryCall(getMonthsRevenueRequest, companyServiceClient, companyServiceClient.getMonthsRevenue);
      setMonthsRevenue(res as GetMonthsRevenueResponse);
    }
    catch {
      setHasError(true);
    }
    finally {
      setIsLoading(false);
    }
  }

  useLayoutEffect(() => {
    getMonthsRevenue(Number(auth.currentCompanyId));
  }, [status]);

  if (isLoading) {
    return null;
  }

  if (status === GettingStartedType.Live) {
    return <BillingRevenue monthsRevenue={monthsRevenue} />
  }

  return (
    <>
      <ErrorNotification
        title="MONTHS_REVENUE_ERROR"
        showAlert={hasError}
        isModal={true}
        onClose={() => setHasError(false)}
      />
      <BillingRevenue monthsRevenue={monthsRevenue} />
    </>
  )
}

export default BillingLive;