import { BillsbyAction } from "../models/BillsbyAction";
import { AppState } from "..";
import { FETCH_COUNTRIES_GO_LIVE_SUCCESS, FETCH_GOLIVE_CHECKLIST_SUCCESS, SET_GOLIVE_FIELD, VALIDATE_GOLIVE_FORM, MOVE_COMPANY_TO_LIVE_SUCCESS, MOVE_COMPANY_TO_LIVE_REQUEST, MOVE_COMPANY_TO_LIVE_FAILURE, SET_GOLIVE_COUNTRY, SET_GOLIVE_STATE, RESET_GOLIVE, MOVE_COMPANY_TO_LIVE_SCA_REQUEST, MOVE_COMPANY_TO_LIVE_SCA_SUCCESS, MOVE_COMPANY_TO_LIVE_SCA_FAILURE, FETCH_COMPANY_FEATURES_STATE_SUCCESS } from "../actions/goLiveActions";
import { reorderCountries,gaEventTracker } from "../utils/commonUtils";
import { ICountry } from "../models/Country";
import { ICountryState } from "../models/CountryState";
import { BillsbyPlan, IGoLiveCheckList } from "../models/GoLive";
import counterpart from "counterpart";
import { countryStates } from "../utils/states/states";
import { CustomeAnalyticsEvents } from "../utils/constants";
import { GetCompanyFeaturesStateResponse } from "../utils/grpc/generated/Billsby.Protos/core/private/companies/company_pb";

export interface IGoLiveError {
  cardHolderName?: string,
  addressLine1?: string,
  addressLine2?: string,
  city?: string,
  state?: string,
  postCode?: string,
  country?: string,
  cardExpiryMonth?: string,
  cardExpiryYear?: string,
  creditCardNumber?: string
}

export interface IGoLiveReducer {
  countries: Array<{ value: ICountry, label: string }>,
  states: Array<{ value: ICountryState, label: string }>,
  checkList?: IGoLiveCheckList,
  isEnabledBillsbyBrand: boolean,
  cardHolderName: string,
  addressLine1: string,
  addressLine2: string,
  city: string,
  state: string,
  selectedState?: { value: ICountryState, label: string },
  postCode: string,
  country?: { value: ICountry, label: string },
  cardExpiryMonth?: number,
  cardExpiryYear?: number,
  errors: IGoLiveError,
  paymentToken?: string,
  isGoingLive: boolean,
  isGoingLiveSuccess: boolean,
  apiError: string, 
  isGoLive3ds1Success: boolean,
  isGoLive3ds1Failure: boolean,
  isGoLive3ds1Request: boolean,
  selectedBillsbyPlan?: BillsbyPlan,
  featuresList: Array<{ title: string, subTitle: string, pathname: string, isUsed: boolean }>
}

export const initialState = {
  countries: [],
  states: [],
  checkList: undefined,
  isEnabledBillsbyBrand: false,
  cardHolderName: "",
  addressLine1: "",
  addressLine2: "",
  city: "",
  state: "",
  postCode: "",
  errors: {},
  isGoingLive: false,
  isGoingLiveSuccess: false,
  apiError: "",
  isGoLive3ds1Success: false,
  isGoLive3ds1Failure: false,
  isGoLive3ds1Request: false,
  featuresList: []
}

const validateGoLiveForm = (currentState: IGoLiveReducer) => {
  const errors: IGoLiveError = {};
  const { cardHolderName, addressLine1, city, state, postCode, country, cardExpiryMonth, cardExpiryYear } = currentState;
  const requiredFields = { cardHolderName, addressLine1, city, state, postCode, country, cardExpiryMonth, cardExpiryYear };
  const camelCaseToTextWithSpaces = (text: string) => text.replace(/([A-Z]+)/g, " $1");

  for (let field in requiredFields) {
    if (!(requiredFields as any)[field]) {
      (errors as any)[field] = camelCaseToTextWithSpaces(field).toLocaleLowerCase().capitalize();
    }
  }
  return errors;
}

export default function goLiveReducer(state: IGoLiveReducer = initialState, action: BillsbyAction, store: AppState) {
  switch (action.type) {
    case FETCH_COUNTRIES_GO_LIVE_SUCCESS: {
      const countries: Array<ICountry> = action.response;
      reorderCountries(countries);
      const defaultCountry: ICountry = countries[0];
      const statesOptions: Array<ICountryState> = countryStates.filter(state => state.CountryIso2 === defaultCountry.iso2Code);
      const statesDropdown = statesOptions.map((countryState: ICountryState) => ({ label: countryState.StateName, value: countryState }));
      const defaultState = statesDropdown[0];
      return { ...state, country: { value: defaultCountry, label: defaultCountry.name }, countries: countries.map(country => ({ value: country, label: country.name })), states: statesDropdown, state: defaultState.label, selectedState: defaultState }
    }
    case FETCH_GOLIVE_CHECKLIST_SUCCESS:
      return { ...state, checkList: action.response }
    case SET_GOLIVE_FIELD:
      return { ...state, [action.fieldName]: action.fieldValue }
    case SET_GOLIVE_COUNTRY:
      const selectedCountry = action.payload;
      const statesOptions: Array<ICountryState> = countryStates.filter(state => state.CountryIso2 === selectedCountry.value.iso2Code);
      const statesDropdown = statesOptions.map((countryState: ICountryState) => ({ label: countryState.StateName, value: countryState }));
      const defaultState = statesDropdown[0];
      return { ...state, country: action.payload, states: statesDropdown, state: defaultState ? defaultState.label : "", selectedState: defaultState }
    case SET_GOLIVE_STATE:
      const selectedState = action.payload;
      return { ...state, state: selectedState.label, selectedState: selectedState }
    case VALIDATE_GOLIVE_FORM:
      return { ...state, errors: validateGoLiveForm(state) }
    case MOVE_COMPANY_TO_LIVE_REQUEST:
      return { ...state, isGoingLive: true, apiError: "" }
    case MOVE_COMPANY_TO_LIVE_SUCCESS:
      // send analytics event
      gaEventTracker(CustomeAnalyticsEvents.GO_LIVE)
      return { ...state, isGoingLiveSuccess: true, isGoingLive: false, apiError: "" }
    case MOVE_COMPANY_TO_LIVE_FAILURE: {
      let apiError = counterpart("GO_LIVE_ERROR");
      
      if (action.error && action.error.info) {
        apiError = action.error.info;
      }
      else if (action.error && action.error.message) {
        apiError = action.error.message;
      }

      return { ...state, isGoingLive: false, apiError }
    }
    case MOVE_COMPANY_TO_LIVE_SCA_REQUEST: 
      return { ...state, isGoLive3ds1Success: false, isGoLive3ds1Failure: false, isGoLive3ds1Request: true }
    case MOVE_COMPANY_TO_LIVE_SCA_SUCCESS: 
      return { ...state, isGoLive3ds1Success: true, isGoLive3ds1Failure: false, isGoLive3ds1Request: false }
    case MOVE_COMPANY_TO_LIVE_SCA_FAILURE: 
      return { ...state, isGoLive3ds1Success: false, isGoLive3ds1Failure: true, isGoLive3ds1Request: false }    
    
    case FETCH_COMPANY_FEATURES_STATE_SUCCESS: {
      const featuresState = action.response as GetCompanyFeaturesStateResponse;

      const featuresList = [{
        title: "GO_LIVE_PRO_PLAN_FEATURE_TITLE1",
        subTitle: "GO_LIVE_PRO_PLAN_FEATURE_SUBTITLE1",
        pathname: "/configuration/ach-settings",
        isUsed: featuresState.getIsAchUsed()
      },
      {
        title: "GO_LIVE_PRO_PLAN_FEATURE_TITLE2",
        subTitle: "GO_LIVE_PRO_PLAN_FEATURE_SUBTITLE2",
        pathname: "/integrations/free-agent",
        isUsed: featuresState.getIsFreeAgentIntegrationUsed()
      },
      {
        title: "GO_LIVE_PRO_PLAN_FEATURE_TITLE3",
        subTitle: "GO_LIVE_PRO_PLAN_FEATURE_SUBTITLE3",
        pathname: "/configuration/payment-gateways",
        isUsed: featuresState.getIsMultiplePaymentGatewaysUsed()
      },
      {
        title: "GO_LIVE_PRO_PLAN_FEATURE_TITLE4",
        subTitle: "GO_LIVE_PRO_PLAN_FEATURE_SUBTITLE4",
        pathname: "/configuration/taxes",
        isUsed: featuresState.getIsMultipleTaxRegionsUsed()
      },
      {
        title: "GO_LIVE_PRO_PLAN_FEATURE_TITLE5",
        subTitle: "GO_LIVE_PRO_PLAN_FEATURE_SUBTITLE5",
        pathname: "/configuration/taxes",
        isUsed: featuresState.getIsTaxJarUsed()
      },
      {
        title: "GO_LIVE_PRO_PLAN_FEATURE_TITLE6",
        subTitle: "GO_LIVE_PRO_PLAN_FEATURE_SUBTITLE6",
        pathname: "/integrations/webex",
        isUsed: featuresState.getIsWebexIntegrationUsed()
      }]

      return { ...state, featuresList }
    }
    case RESET_GOLIVE:
      return { ...initialState }

    default:
      return state;
  }
}