import React from "react";
import "./CouponsWelcome.scss";

import Panel from "../../components/ui/panel/Panel";
import Text from "../../components/ui/text/Text";

export const CouponsWelcome = () => {
  return (
    <div className="coupons">
     <Panel className="coupons__greeting" title="COUPONS_WELCOME_TITLE">
      <Text content="COUPONS_WELCOME_TEXT" />
      {/* <Panel className="coupons__greeting-btn">
        <Button id="coupons-welcome" icon="fal fa-plus-circle" buttonType="add" title="COUPONS_BUTTON" />
      </Panel> */}
     </Panel>
    </div>
  )
}

export default CouponsWelcome;