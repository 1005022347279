import API from "../utils/API";
import { makeGenericActionCreator, makeActionCreator } from "../utils/reduxUtils";
import { IInvoicesCreditNotesCustomizationState } from "../reducers/invoicesCreditNotesCustomizationReducer";
import { IInvoicesFooterConfigData, IInvoicesSupportContactConfigData, InvoiceConfigType } from "../models/Invoices";
import { CompanyConfigServiceClient } from "../utils/grpc/generated/Billsby.Protos/billing/private/companyconfig/Company_configServiceClientPb";

import { GetInvoiceAddressConfigRequest, UpsertInvoiceAddressConfigRequest, GetInvoiceConfigRequest, UpsertInvoiceConfigRequest } from "../utils/grpc/generated/Billsby.Protos/billing/private/companyconfig/company_config_pb";
import { grpcUnaryCall } from "../utils/grpc/grpcUtils";
import { ConfigConstants } from "../utils/config";

export const FETCH_INVOICE_CONFIG_REQUEST = "FETCH_INVOICE_CONFIG_REQUEST";
export const FETCH_INVOICE_CONFIG_SUCCESS= "FETCH_INVOICE_CONFIG_SUCCESS";
export const FETCH_INVOICE_CONFIG_FAILURE = "FETCH_INVOICE_CONFIG_FAILURE";

export const UPDATE_INVOICE_CONFIG_REQUEST = "UPDATE_INVOICE_CONFIG_REQUEST";
export const UPDATE_INVOICE_CONFIG_SUCCESS= "UPDATE_INVOICE_CONFIG_SUCCESS";
export const UPDATE_INVOICE_CONFIG_FAILURE = "UPDATE_INVOICE_CONFIG_FAILURE";

export const FETCH_INVOICES_CREDIT_NOTES_CONFIG_REQUEST = "FETCH_INVOICES_CREDIT_NOTES_CONFIG_REQUEST";
export const FETCH_INVOICES_CREDIT_NOTES_CONFIG_SUCCESS= "FETCH_INVOICES_CREDIT_NOTES_CONFIG_SUCCESS";
export const FETCH_INVOICES_CREDIT_NOTES_CONFIG_FAILURE = "FETCH_INVOICES_CREDIT_NOTES_CONFIG_FAILURE";

export const UPDATE_INVOICES_CREDIT_NOTES_CONFIG_REQUEST = "UPDATE_INVOICES_CREDIT_NOTES_CONFIG_REQUEST";
export const UPDATE_INVOICES_CREDIT_NOTES_CONFIG_SUCCESS= "UPDATE_INVOICES_CREDIT_NOTES_CONFIG_SUCCESS";
export const UPDATE_INVOICES_CREDIT_NOTES_CONFIG_FAILURE = "UPDATE_INVOICES_CREDIT_NOTES_CONFIG_FAILURE";

export const UPDATE_SUPPORT_CONTACT_DETAILS_REQUEST = "UPDATE_SUPPORT_CONTACT_DETAILS_REQUEST";
export const UPDATE_SUPPORT_CONTACT_DETAILS_SUCCESS= "UPDATE_SUPPORT_CONTACT_DETAILS_SUCCESS";
export const UPDATE_SUPPORT_CONTACT_DETAILS_FAILURE = "UPDATE_SUPPORT_CONTACT_DETAILS_FAILURE";

export const FETCH_INVOICES_CREDIT_NOTES_FOOTER_REQUEST = "FETCH_INVOICES_CREDIT_NOTES_FOOTER_REQUEST";
export const FETCH_INVOICES_CREDIT_NOTES_FOOTER_SUCCESS= "FETCH_INVOICES_CREDIT_NOTES_FOOTER_SUCCESS";
export const FETCH_INVOICES_CREDIT_NOTES_FOOTER_FAILURE = "FETCH_INVOICES_CREDIT_NOTES_FOOTER_FAILURE";

export const UPDATE_INVOICES_CREDIT_NOTES_FOOTER_REQUEST = "UPDATE_INVOICES_CREDIT_NOTES_FOOTER_REQUEST";
export const UPDATE_INVOICES_CREDIT_NOTES_FOOTER_SUCCESS= "UPDATE_INVOICES_CREDIT_NOTES_FOOTER_SUCCESS";
export const UPDATE_INVOICES_CREDIT_NOTES_FOOTER_FAILURE = "UPDATE_INVOICES_CREDIT_NOTES_FOOTER_FAILURE";


export const SET_INVOICES_CREDIT_NOTES_CUSTOMIZATION_FIELD = "SET_INVOICES_CREDIT_NOTES_CUSTOMIZATION_FIELD";

type AllowedInvoicesCreditNotesFields = keyof IInvoicesCreditNotesCustomizationState ;
export const setInvoicesCreditNotesField = makeGenericActionCreator<AllowedInvoicesCreditNotesFields>(SET_INVOICES_CREDIT_NOTES_CUSTOMIZATION_FIELD);

export const RESET_INVOICES_CREDIT_NOTES_FOOTER = "RESET_INVOICES_CREDIT_NOTES_FOOTER";
export const resetFooterContent = makeActionCreator(RESET_INVOICES_CREDIT_NOTES_FOOTER, "payload");
export const RESET_INVOICES_CREDIT_NOTES_SUPPORT = "RESET_INVOICES_CREDIT_NOTES_SUPPORT";
export const resetSupportContactDetails = makeActionCreator(RESET_INVOICES_CREDIT_NOTES_SUPPORT, "payload");
export const RESET_CONTACT_DETAILS_INVOICE = "RESET_CONTACT_DETAILS_INVOICE";
export const resetContactDetailsInvoice = makeActionCreator(RESET_CONTACT_DETAILS_INVOICE, "payload");

export const fetchInvoiceCreditNotesConfig = function (companyDomain: string) {
  return {
    types: [FETCH_INVOICES_CREDIT_NOTES_CONFIG_REQUEST, FETCH_INVOICES_CREDIT_NOTES_CONFIG_SUCCESS, FETCH_INVOICES_CREDIT_NOTES_CONFIG_FAILURE],
    callAPI: () => API.fetchInvoiceCreditNotesConfig(companyDomain)
  }
}

export const updateInvoiceCreditNotesConfig = function (companyDomain: string, data: IInvoicesFooterConfigData) {
  return {
    types: [UPDATE_INVOICES_CREDIT_NOTES_CONFIG_REQUEST, UPDATE_INVOICES_CREDIT_NOTES_CONFIG_SUCCESS, UPDATE_INVOICES_CREDIT_NOTES_CONFIG_FAILURE],
    callAPI: () => API.updateInvoiceCreditNotesConfig(companyDomain, data)
  }
}

export const updateSupportContactDetails = function (companyDomain: string, invoiceType: InvoiceConfigType, data: IInvoicesSupportContactConfigData) {
  return {
    types: [UPDATE_SUPPORT_CONTACT_DETAILS_REQUEST, UPDATE_SUPPORT_CONTACT_DETAILS_SUCCESS, UPDATE_SUPPORT_CONTACT_DETAILS_FAILURE],
    callAPI: () => API.updateSupportContactDetails(companyDomain, invoiceType, data)
  }
}

export const fetchInvoiceCreditNotesFooter = (companyId: number) => {
  const getInvoiceAddressConfigRequest = new GetInvoiceAddressConfigRequest();
  getInvoiceAddressConfigRequest.setCompanyId(companyId);

  const companyConfigServiceClient = new CompanyConfigServiceClient(ConfigConstants.grpcBaseUrl);
  return {
    types: [FETCH_INVOICES_CREDIT_NOTES_FOOTER_REQUEST, FETCH_INVOICES_CREDIT_NOTES_FOOTER_SUCCESS, FETCH_INVOICES_CREDIT_NOTES_FOOTER_FAILURE],
    callAPI: () => grpcUnaryCall(getInvoiceAddressConfigRequest, companyConfigServiceClient, companyConfigServiceClient.getInvoiceAddressConfig)
  }
}

export const updateInvoiceCreditNotesFooter = (companyId: number, footerConfigData: IInvoicesFooterConfigData) => {
  const updateInvoiceAddressConfigRequest = new UpsertInvoiceAddressConfigRequest();
  updateInvoiceAddressConfigRequest.setCompanyId(companyId);
  updateInvoiceAddressConfigRequest.setCompanyName(footerConfigData.companyName);
  updateInvoiceAddressConfigRequest.setPhoneNumber(footerConfigData.phoneNumber);
  updateInvoiceAddressConfigRequest.setPhoneNumberDialCode(footerConfigData.phoneNumberDialCode);
  updateInvoiceAddressConfigRequest.setPhoneNumberDialCountry(footerConfigData.phoneNumberDialCountry);
  updateInvoiceAddressConfigRequest.setEmailAddress(footerConfigData.emailAddress);
  updateInvoiceAddressConfigRequest.setAddressLine1(footerConfigData.address.addressLine1);
  updateInvoiceAddressConfigRequest.setAddressLine2(footerConfigData.address.addressLine2 as string);
  updateInvoiceAddressConfigRequest.setCity(footerConfigData.address.city);
  updateInvoiceAddressConfigRequest.setState(footerConfigData.address.state);
  updateInvoiceAddressConfigRequest.setPostCode(footerConfigData.address.postCode);
  updateInvoiceAddressConfigRequest.setCountryIso3(footerConfigData.address.country);

  const companyConfigServiceClient = new CompanyConfigServiceClient(ConfigConstants.grpcBaseUrl);
  return {
    types: [UPDATE_INVOICES_CREDIT_NOTES_FOOTER_REQUEST, UPDATE_INVOICES_CREDIT_NOTES_FOOTER_SUCCESS, UPDATE_INVOICES_CREDIT_NOTES_FOOTER_FAILURE],
    callAPI: () => grpcUnaryCall(updateInvoiceAddressConfigRequest, companyConfigServiceClient, companyConfigServiceClient.upsertInvoiceAddressConfig)
  }
}

export const fetchInvoiceConfig = (companyId: number) => {
  const getInvoiceConfigRequest = new GetInvoiceConfigRequest();
  getInvoiceConfigRequest.setCompanyId(companyId);

  const companyConfigServiceClient = new CompanyConfigServiceClient(ConfigConstants.grpcBaseUrl);
  return {
    types: [FETCH_INVOICE_CONFIG_REQUEST, FETCH_INVOICE_CONFIG_SUCCESS, FETCH_INVOICE_CONFIG_FAILURE],
    callAPI: () => grpcUnaryCall(getInvoiceConfigRequest, companyConfigServiceClient, companyConfigServiceClient.getInvoiceConfig)
  }
}

export const updateInvoiceConfig = (companyId: number, title: string) => {
  const updateInvoiceConfigRequest = new UpsertInvoiceConfigRequest();
  updateInvoiceConfigRequest.setCompanyId(companyId);
  updateInvoiceConfigRequest.setTitle(title)

  const companyConfigServiceClient = new CompanyConfigServiceClient(ConfigConstants.grpcBaseUrl);
  return {
    types: [UPDATE_INVOICE_CONFIG_REQUEST, UPDATE_INVOICE_CONFIG_SUCCESS, UPDATE_INVOICE_CONFIG_FAILURE],
    callAPI: () => grpcUnaryCall(updateInvoiceConfigRequest, companyConfigServiceClient, companyConfigServiceClient.upsertInvoiceConfig)
  }
}