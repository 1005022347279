import React from "react";
import "./Checkbox.scss";

interface ICheckbox {
  /**
  * The ID of the checkbox
  */
  id?: string,
  /**
   * The checked state of the checkbox
   */
  checked: any,
  /**
   * The callback when a checkbox is selected
   */
  onClick: () => void,
  /**
   * Whether or not the user has to tick these checkboxes
   */
  required?: boolean,
  /**
   * The name of the checkbox option could be either a string or a node (plain html)
   */
  content: any,
  /**
   * The design style of the checkbox
   */
  checkType?: "general",
  /**
   * The value of the checkbox
   */
  value: string | number,
  /**
   * instead of pure text the caller pass html content
   */
  isHtml?: boolean

  /*
   * Remove margin if it's true
   */
  noMargin?: boolean,
  disabled?: boolean,
  style?: any,
  className?: string
}

const Checkbox: React.FC<ICheckbox> = React.memo((props) => {
  const {
    id,
    checked,
    onClick,
    required = false,
    content: Content,
    checkType = "general",
    value,
    isHtml = false,
    noMargin = false,
    disabled = false,
    style = {}
  } = props;

  return (
    <div className={`cc-checkbox ${!noMargin ? "cc-checkbox--margin-bottom" : ""} ${disabled ? "cc-checkbox--disabled" : ""}`} onClick={() => !disabled && onClick()}>
      <input
        id={id}
        className={`cc-checkbox-${checkType}__input`}
        type="checkbox"
        checked={checked}
        onClick={evt => {  evt.preventDefault(); evt.stopPropagation(); }}
        onChange={evt => { evt.preventDefault(); evt.stopPropagation(); }}
        required={required}
        value={value}
      />
      <i className={`${checked ? "fas fa-check-circle" : "fal fa-circle"} fa-2x cc-checkbox-${checkType}__icon`} style={style}/>
      <label className={`cc-checkbox-${checkType}__label`} htmlFor={id}>{isHtml ? <Content /> : Content}</label>

      {/*!-- This fake div hidden preloads our web font icons to avoid a flashy effect on the tick icon on the first interaction */}
      <div className="cc-checkbox__fake-hidden">
        <i className="fas fa-check-circle"/>
        <i className="fal fa-circle"/>
      </div>

    </div>

  );
})

export default Checkbox;
