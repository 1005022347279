import { makeActionCreator } from "../utils/reduxUtils";
import API from "../utils/API";
import { ISubscriptionUpdateModel } from "../models/Subscription";
import { GetInvoiceSimulationRequest, AllowanceOverage } from "../utils/grpc/generated/Billsby.Protos/billing/public/invoice/invoice_pb";
import { InvoiceServiceClient } from "../utils/grpc/generated/Billsby.Protos/billing/public/invoice/InvoiceServiceClientPb";
import { ConfigConstants } from "../utils/config";
import { grpcUnaryCall } from "../utils/grpc/grpcUtils";
import { Int32Value } from "google-protobuf/google/protobuf/wrappers_pb";

/**********************  SYNCHRONOUS ACTIONS ********************/
export const SET_PLAN = "SET_PLAN";
export const setPlan = makeActionCreator(SET_PLAN, "payload");
export const SET_CYCLE = "SET_CYCLE";
export const setCycle = makeActionCreator(SET_CYCLE, "payload");
export const SET_PLAN_ID = "SET_PLAN_ID";
export const setPlanId = makeActionCreator(SET_PLAN_ID, "payload");
export const SET_CYCLE_ID = "SET_CYCLE_ID";
export const setCycleId = makeActionCreator(SET_CYCLE_ID, "payload");
export const SET_PAYMENT_DESCRIPTION = "SET_PAYMENT_DESCRIPTION";
export const setPaymentDescription = makeActionCreator(SET_PAYMENT_DESCRIPTION, "payload");
export const SET_PLAN_CHANGE_TYPE = "SET_PLAN_CHANGE_TYPE";
export const setPlanChangeType = makeActionCreator(SET_PLAN_CHANGE_TYPE, "payload");
export const SET_SHOW_UPDATE_SUBSCRIPTION_MODAL = "SET_SHOW_UPDATE_SUBSCRIPTION_MODAL";
export const setShowUpdateSubscriptionModal = makeActionCreator(SET_SHOW_UPDATE_SUBSCRIPTION_MODAL, "payload");
export const SET_NUMBER_OF_UNITS = "SET_NUMBER_OF_UNITS";
export const setNumberOfUnits = makeActionCreator(SET_NUMBER_OF_UNITS, "payload"); 

export const RESET_SELECTPLAN_STATE = "RESET_SELECTPLAN_STATE";
export const resetSelectPlanState = makeActionCreator(RESET_SELECTPLAN_STATE);

export const FETCH_PRODUCT_PLANS_REQUEST = "FETCH_PRODUCT_PLANS_REQUEST";
export const FETCH_PRODUCT_PLANS_SUCCESS = "FETCH_PRODUCT_PLANS_SUCCESS";
export const FETCH_PRODUCT_PLANS_FAILURE = "FETCH_PRODUCT_PLANS_FAILURE";

export const UPDATE_PRODUCT_PLANS_REQUEST = "UPDATE_PRODUCT_PLANS_REQUEST";
export const UPDATE_PRODUCT_PLANS_SUCCESS = "UPDATE_PRODUCT_PLANS_SUCCESS";
export const UPDATE_PRODUCT_PLANS_FAILURE = "UPDATE_PRODUCT_PLANS_FAILURE";

export const FETCH_PAYMENT_DESCRIPTION_REQUEST = "FETCH_PAYMENT_DESCRIPTION_REQUEST";
export const FETCH_PAYMENT_DESCRIPTION_SUCCESS = "FETCH_PAYMENT_DESCRIPTION_SUCCESS";
export const FETCH_PAYMENT_DESCRIPTION_FAILURE = "FETCH_PAYMENT_DESCRIPTION_FAILURE";


/**********************  ASYNCHRONOUS ACTIONS (api requests) ********************/

export const fetchProductPlans = function (companyDomain: string, productId: number) {
  return {
    types: [FETCH_PRODUCT_PLANS_REQUEST, FETCH_PRODUCT_PLANS_SUCCESS, FETCH_PRODUCT_PLANS_FAILURE],
    callAPI: () => API.fetchPlansByProduct(companyDomain, productId)
  }
}

export const GET_PRICING_INFO_DISCLOSURE_REQUEST = "GET_PRICING_INFO_DISCLOSURE_REQUEST";
export const GET_PRICING_INFO_DISCLOSURE_SUCCESS = "GET_PRICING_INFO_DISCLOSURE_SUCCESS";
export const GET_PRICING_INFO_DISCLOSURE_FAILURE = "GET_PRICING_INFO_DISCLOSURE_FAILURE";

const buildInvoiceSimulationRequest = (companyDomain: string, cycleId: number, planId: number, units?: number, currentCycleId?: number) => {
  const getInvoiceSimulationRequest = new GetInvoiceSimulationRequest();
  getInvoiceSimulationRequest.setCompanyDomain(companyDomain);
  getInvoiceSimulationRequest.setCycleId(cycleId);

  if(currentCycleId) {
    const newInt32 = new Int32Value();
    newInt32.setValue(currentCycleId)

    getInvoiceSimulationRequest.setCurrentCycleId(newInt32)
  }

  const allowanceOverage = new AllowanceOverage();
  allowanceOverage.setCycleId(cycleId);
  allowanceOverage.setPlanId(planId);
  getInvoiceSimulationRequest.setAllowanceOverage(allowanceOverage);
  if(!!units) {
    getInvoiceSimulationRequest.setUnits(units);
  }
  else {
    getInvoiceSimulationRequest.setUnits(1);
  }
  return getInvoiceSimulationRequest
}

export const fetchPaymentDescription = function (companyDomain: string, cycleId: number, planId: number, units?: number, currentCycleId?: number) {
  const getInvoiceSimulationRequest = buildInvoiceSimulationRequest(companyDomain, cycleId, planId, units, currentCycleId)
  const invoiceServiceClient = new InvoiceServiceClient(ConfigConstants.grpcBaseUrl);

  return {
    types: [FETCH_PAYMENT_DESCRIPTION_REQUEST, FETCH_PAYMENT_DESCRIPTION_SUCCESS, FETCH_PAYMENT_DESCRIPTION_FAILURE],
    callAPI: () => grpcUnaryCall(getInvoiceSimulationRequest, invoiceServiceClient, invoiceServiceClient.getInvoiceSimulation)
  }
}

export const fetchPricingInfoDisclosure = (companyDomain: string, cycleId: number, planId: number, units?: number, currentCycleId?: number) => {
  const getInvoiceSimulationRequest = buildInvoiceSimulationRequest(companyDomain, cycleId, planId, units, currentCycleId)
  const invoiceServiceClient = new InvoiceServiceClient(ConfigConstants.grpcBaseUrl);

  return {
    types: [GET_PRICING_INFO_DISCLOSURE_REQUEST, GET_PRICING_INFO_DISCLOSURE_SUCCESS, GET_PRICING_INFO_DISCLOSURE_FAILURE],
    callAPI: () => grpcUnaryCall(getInvoiceSimulationRequest, invoiceServiceClient, invoiceServiceClient.getInvoicePricingDisclosure)
  }
}

export const updateProductPlans = function (companyDomain: string, subscriptionUniqueId: string, data: ISubscriptionUpdateModel) {
  return {
    types: [UPDATE_PRODUCT_PLANS_REQUEST, UPDATE_PRODUCT_PLANS_SUCCESS, UPDATE_PRODUCT_PLANS_FAILURE],
    callAPI: () => API.updateSubscription(companyDomain, subscriptionUniqueId, data)
  }
}

