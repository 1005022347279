import { BillsbyAction } from "../models/BillsbyAction";
import { AppState } from "..";
import { FETCH_SUBSCRIPTIONS_SUCCESS, SET_SUBSCRIPTIONS_FIELD, FETCH_SUBSCRIPTIONS_REQUEST, RESET_SUBSCRIPTIONS } from "../actions/subscriptionsActions";
import { ISubscriptionsPaginated, SubscriptionStatusType } from "../models/Subscriptions";
import { IPageNotification } from "../models/Configuration";

export interface ISubscriptionsReducer {
  subscriptionsPaginated: ISubscriptionsPaginated,
  searchedSubscriptions: string,
  hasSubscription: boolean,
  isLoading: boolean,
  isFirstSearch: boolean,
  pageNotification?: IPageNotification,
  subscriptionStatus?: { label: string, value: SubscriptionStatusType },
  searchedProductFilter?: { label: string, value: { productName: string, productId: number } },
  searchedPlanFilter?: { label: string, value: { planName: string, productName: string, planId: number } },
  products: Array<{ label: string, value: { productName: string, productId: number } }>,
  plans: Array<{ label: string, value: { planName: string, productName: string, planId: number } }>,
  isCreatedOnDescendingOrder: boolean
}

export const subscriptionsInitialState = {
  subscriptionsPaginated: {
    currentPage: 1,
    pageCount: 1,
    pageSize: 0,
    rowCount: 0,
    results: undefined
  },
  searchedSubscriptions: "",
  hasSubscription: false,
  isLoading: true,
  isFirstSearch: true,
  products: [],
  plans: [],
  isCreatedOnDescendingOrder: true
}

export default function subcriptionsReducer(state: ISubscriptionsReducer = subscriptionsInitialState, action: BillsbyAction, store: AppState) {
  switch (action.type) {
    case SET_SUBSCRIPTIONS_FIELD:
      return { ...state, [action.fieldName]: action.fieldValue }
    case FETCH_SUBSCRIPTIONS_REQUEST:
      return { ...state, isLoading: true, isFirstSearch: !state.subscriptionsPaginated.results }
    case FETCH_SUBSCRIPTIONS_SUCCESS:
      const hasSubscription = (!!action.response.results && action.response.results.length > 0) || state.hasSubscription;
      return { ...state, subscriptionsPaginated: action.response, hasSubscription, isLoading: false }
    case RESET_SUBSCRIPTIONS:
      return { ...subscriptionsInitialState }
    default:
      return state;
  }
}