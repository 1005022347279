/* eslint-disable @typescript-eslint/no-use-before-define */

export interface IToken {
  access_token: string,
  expires_in: number,
  refresh_token: string,
}


export interface IParsedToken {
  email: string,
  exp: number,
  iat: number,
  nameid: string,
  given_name: string,
  nbf: number,
  UserRole?: UserRole,
  companyDomain?: string,
  companyId?: number,
  Intercom: string,
  Permissions: {
    Plan: Array<keyof typeof Permission>,
    Teammate: Array<keyof typeof Permission>
  },
  CurrentPermissions: Array<keyof typeof Permission>,
  Limitations: ILimitation
}


export interface ICompany {
  companyId: number,
  name: string,
  companyDomain: string,
  currencyCode: string,
  isCurrencyMappingMatched: boolean,
  subscriptionUniqueId: string,
  customerUniqueId: string,
  trialLimit: number,
  goLiveOn: string,
  googleAnalyticsId: string
}

export interface INewCompany {
  companyName: string,
  companyCountry: string,
  companyCurrency: string,
  timezone: string,
  companyDomain: string,
  googleAnalyticsId: string
}


export enum Permission {
  ProRatedBillingOption = 1,
  BillingUnitOption = 2,
  BillingMeteredOption = 3,
  AddonsRead = 4,
  DiscountCouponRead = 8,
  ContractTermOption = 10,
  CustomFieldOption = 11,
  FeatureTagsOption = 12,
  NetTermsRead = 13,
  NetTermsWrite = 14,
  OneTimeChargeWrite = 15,
  AutomatedDunningRead = 16,
  AutomatedDunningWrite = 17,
  PaymentGatewaysRead = 18,
  PaymentGatewaysWrite = 19,
  PaymentCurrencyRead = 20,
  PaymentCurrencyWrite = 21,
  AchPaymentProcessorRead = 22,
  AchPaymentProcessorWrite = 23,
  ManualTaxConfigurationRead = 24,
  ManualTaxConfigurationWrite = 25,
  AutomatedTaxCalculationsRead = 26,
  AutomatedTaxCalculationsWrite = 27,
  TaxJarRead = 28,
  TaxJarWrite = 29,
  MultiTaxRegionRead = 30,
  MultiTaxRegionWrite = 31,
  DashboardAnalyticsRead = 32,
  DashboardAnalyticsWrite = 33,
  EmailLogsRead = 34,
  EmailLogsWrite = 35,
  TransactionLogsRead = 36,
  TransactionLogsWrite = 37,
  ActivitySummaryRead = 38,
  ActivitySummaryWrite = 39,
  TaxSummaryRead = 40,
  TaxSummaryWrite = 41,
  CustomerCancellationRead = 42,
  CustomerCancellationWrite = 43,
  RevenueRecognitionRead = 44,
  RevenueRecognitionWrite = 45,
  DeferredRevenueRead = 46,
  DeferredRevenueWrite = 47,
  DataExportsCsv = 48,
  MultiLanguageRead = 53,
  MultiLanguageWrite = 54,
  FreeAgentRead = 57,
  FreeAgentWrite = 58,
  QuickBooksRead = 59,
  QuickBooksWrite = 60,
  ZapierRead = 61,
  ZapierWrite = 62,
  WebexRead = 63,
  WebexWrite = 64,
  BillsbyApiKey = 65,
  WebhookConfigRead = 66,
  WebhookConfigWrite = 67,
  CustomersRead = 68,
  SubscriptionCustomerLinkRead = 69,
  InvoiceCustomerLinkRead = 70,
  CreditNoteCustomerLinkRead = 71,
  CustomerEdit = 72,
  CustomerAddSubscription = 73,
  CustomerReplacePaymentMethod = 74,
  CustomerEditTaxValidation = 75,
  CustomerCaptureOneTimeCharge = 76,
  CustomerClearPersonalData = 77,
  CustomerDelete = 78,
  CustomerAdd = 79,
  CustomerReplaceCard = 80,
  CustomerAddNote = 81,
  SubscriptionsRead = 82,
  SubscriptionEdit = 83,
  SubscriptionAddAddon = 84,
  SubscriptionAddAllowance = 85,
  SubscriptionAddDiscount = 86,
  SubscriptionChangeBillDate = 87,
  SubscriptionUpdateStatus = 88,
  SubscriptionCancel = 89,
  SubscriptionDeleteAddon = 90,
  SubscriptionEditAddon = 91,
  SubscriptionUpdateAllowanceUsage = 92,
  SubscriptionDeleteAllowance = 93,
  SubscriptionRemoveDiscount = 94,
  SubscriptionAddFeatureTag = 95,
  SubscriptionRemoveFeatureTag = 96,
  InvoicesRead = 97,
  InvoiceRefund = 98,
  InvoiceSend = 99,
  InvoiceDownloadPdf = 100,
  InvoiceReattempt = 101,
  InvoiceUpdateStatus = 102,
  CreditNotesRead = 103,
  CreditNoteSend = 104,
  CreditNoteDownloadPdf = 105,
  CreditNoteReattempt = 106,
  ProductsPlansCyclesRead = 107,
  ProductAdd = 108,
  ProductEdit = 109,
  ProductDelete = 110,
  ProductPlansReorder = 111,
  ProductPlanAdd = 112,
  ProductPlanEdit = 113,
  ProductPlanDelete = 114,
  AddonAdd = 115,
  AddonEdit = 116,
  AddonDelete = 117,
  AllowancesRead = 118,
  AllowanceAdd = 119,
  AllowanceEdit = 120,
  AllowanceDelete = 121,
  DiscountsRead = 122,
  DiscountAdd = 123,
  DiscountEdit = 124,
  DiscountDelete = 125,
  DiscountCouponAdd = 126,
  DiscountCouponEdit = 127,
  DiscountCouponExpire = 128,
  AccountingReportsRead = 129,
  SelfServiceReportsRead = 130,
  IntegrationsRead = 131,
  SettingsCompanyDetailsRead = 132,
  SettingsCompanyDetailsWrite = 133,
  SettingsPauseAndCancelSubscriptionRead = 134,
  SettingsPauseAndCancelSubscriptionWrite = 135,
  SettingsCurrenciesRead = 136,
  SettingsCurrenciesWrite = 137,
  SettingsTaxesRead = 138,
  SettingsTaxesWrite = 139,
  SettingsInvoicesRead = 140,
  SettingsInvoicesWrite = 141,
  SettingsDunningAndFailedPaymentsRead = 142,
  SettingsDunningAndFailedPaymentsWrite = 143,
  SettingsAllowancesRead = 144,
  SettingsAllowancesWrite = 145,
  SettingsPaymentGatewayScaRead = 146,
  SettingsPaymentGatewayScaWrite = 147,
  SettingsBrandCustomizationRead = 148,
  SettingsBrandCustomizationWrite = 149,
  SettingsEmailNotificationsRead = 150,
  SettingsEmailNotificationsWrite = 151,
  SettingsCheckoutRead = 152,
  SettingsCheckoutWrite = 153,
  SettingsApiAndWebhooksRead = 154,
  SettingsApiAndWebhooksWrite = 155,
  SettingsInformationFieldsRead = 156,
  SettingsInformationFieldsWrite = 157,
  EmbedCodesRead = 158,
  BillingRead = 159,
  BillingWrite = 160,
  GoLive = 161,
  TeammatesRead = 162,
  TeammateAdd = 163,
  TeammateAssignRole = 164,
  CancelCompany = 165,
  Sandbox = 166,
  CustomerFeatureTagsRead = 167,
  CustomerFeatureTagsWrite = 168,
  SubscriptionFeatureTagsRead = 169,
  CustomFieldRead = 170,
  CustomFieldWrite = 171,
  G2ReviewAdd = 172,
  CompanyAdd = 173,
  SettingsAccountManagementRead = 174,
  SettingsAccountManagementWrite = 175
}

interface ILimitation {
  PaymentGateway: number
  Currency: number
  TaxRegion: number
}

export enum UserRole {
  Unassigned = 1,
  Admin = 2,
  Developer = 3,
  Editor = 4,
  Viewer = 5,
  Agent = 6,
  AirfiAgent = 100,
  BillsbyAdmin = 101,
  BillsbyDeveloper = 102,
  BillsbyAnalyst = 103,
  BillsbySeniorOperationsManager = 104,
  BillsbyOperationsManager = 105
}