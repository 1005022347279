import React, { useState, useLayoutEffect, FormEvent } from "react";
import "./SupportContactDetails.scss";
import { useParams } from "react-router-dom";
import Panel from "../../../../ui/panel/Panel";
import Text from "../../../../ui/text/Text";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../../../../..";
import counterpart from "counterpart";
import { camelize } from "../../../../../utils/commonUtils";
import Input from "../../../../ui/input/Input";
import FormLabel from "../../../../ui/form-label/FormLabel";
import FormGroup from "../../../../ui/form-group/FormGroup";
import { Col, Row } from "react-grid-system";
import Button from "../../../../ui/button/Button";
import Textarea from "../../../../ui/textarea/Textarea";
import Switch from "../../../../ui/switch/Switch";
import { setInvoicesCreditNotesField, resetContactDetailsInvoice, resetSupportContactDetails, updateSupportContactDetails } from "../../../../../actions/invoicesCreditNotesCustomizationActions";
import { ISupportContactDetails } from "../../../../../reducers/invoicesCreditNotesCustomizationReducer";
import FileUpload from "../../../../ui/file-upload/FileUpload";
import API from "../../../../../utils/API";
import { InvoiceConfigType, IInvoicesSupportContactConfigData } from "../../../../../models/Invoices";

const SupportContactDetails: React.FC = () => {
  const currentCompanyDomain = useSelector((state: AppState) => state.auth.currentCompanyDomain);
  const supportContactDetails = useSelector((state: AppState) => state.invoicesCreditNotesCustomizationReducer.supportContactDetails);

  const dispatch = useDispatch<Function>();
  const { propertyData } = useParams<{ propertyData: string }>()

  const [state, setState] = useState({ isLoading: false, hasError: false, errorMsg: "" });
  const { isLoading, hasError, errorMsg } = state;

  const paramData =  propertyData || "paid-invoices";
  const mapLinkInvoiceType: { [key: string]: string } = {
    "pending-invoices": counterpart("INVOICE_CREDIT_NOTE_CUSTOMIZATION_PENDING_INVOICE"),
    "paid-invoices": counterpart("INVOICE_CREDIT_NOTE_CUSTOMIZATION_PAID_INVOICE"),
    "unpaid-invoices": counterpart("INVOICE_CREDIT_NOTE_CUSTOMIZATION_UNPAID_INVOICE"),
    "paid-credit-notes": counterpart("INVOICE_CREDIT_NOTE_CUSTOMIZATION_PAID_CREDIT_NOTE"),
    "pending-credit-notes": counterpart("INVOICE_CREDIT_NOTE_CUSTOMIZATION_PENDING_CREDIT_NOTE"),
    "failed-credit-notes": counterpart("INVOICE_CREDIT_NOTE_CUSTOMIZATION_FAILED_CREDIT_NOTE")
  }
  const formattedParam = paramData.replace(/-/g, "_").toUpperCase();
  const invoiceConfigType = (camelize(paramData)[0].toUpperCase() + camelize(paramData).slice(1, -1)) as InvoiceConfigType;


  const currentSupportContactDetails = supportContactDetails.find(details => details.invoiceType === invoiceConfigType);

  useLayoutEffect(() => {
    dispatch(resetContactDetailsInvoice(invoiceConfigType));

    return () => dispatch(resetSupportContactDetails());
  }, [propertyData]);

  if (!currentSupportContactDetails) {
    return null;
  }

  const { heading, content, supportPageUrl, supportPageUrlButtonText, isAdvertEnabled, advertLinkToUrl, advertImageUrl } = currentSupportContactDetails;

  const updateSupportDetailContent = (property: keyof ISupportContactDetails, value: any) => {
    setState({ ...state, hasError: false })
    dispatch(
      setInvoicesCreditNotesField(
        "supportContactDetails",
        supportContactDetails.map(details => (details.invoiceType === currentSupportContactDetails.invoiceType ? { ...details, [property]: value } : details))
      )
    );
  };

  const onSubmit = async (evt: FormEvent) => {
    evt.preventDefault();

    const contactDetailsConfigData: IInvoicesSupportContactConfigData = {
      heading,
      content,
      supportPageUrl,
      supportPageUrlButtonText: supportPageUrlButtonText ? supportPageUrlButtonText : "Visit our support portal",
      isAdvertEnabled,
      advertImageUrl: isAdvertEnabled ? advertImageUrl : "",
      advertLinkToUrl: isAdvertEnabled ? advertLinkToUrl : ""
    };

    setState({ ...state, isLoading: true });

    try {
      const response = await dispatch(updateSupportContactDetails(currentCompanyDomain, invoiceConfigType, contactDetailsConfigData));

      if (!response.ok && response.error) {
        setState({ ...state, isLoading: false, hasError: true, errorMsg: response.error.list[0].description });
        return;
      }

      setState({ ...state, isLoading: false });
    } catch (err) {
      setState({ ...state, isLoading: false });

      console.log(err);
    }
  };

  return (
    <div className="support-contact-details">
      <Panel title={`INVOICE_CREDIT_NOTE_CUSTOMIZATION_${formattedParam}`} className="support-contact-details__heading">
        <Button
          className="support-contact-details__floating-button"
          icon="fas fa-eye"
          title="INVOICE_CREDIT_NOTE_CUSTOMIZATION_PREVIEW"
          type="button"
          buttonType="add"
          id={"preview-invoice"}
          onClick={() => window.open(`/invoice/${paramData}/preview`, "_self")}
        />
        <Text content={`INVOICE_CREDIT_NOTE_CUSTOMIZATION_${formattedParam}_CONTENT`} noMargin />
      </Panel>
      <form onSubmit={onSubmit}>
        <Panel title="INVOICE_CREDIT_NOTE_CUSTOMIZATION_SUPPORT_CONTACT_DETAILS">
          <FormGroup>
            <Row align="center">
              <Col md={1.5}>
                <FormLabel target="support-contact-details-heading" content="INVOICE_CREDIT_NOTE_CUSTOMIZATION_LABEL_HEADING" />
              </Col>
              <Col md={10.5}>
                <Input
                  id="support-contact-details-heading"
                  placeholder={counterpart("INVOICE_CREDIT_NOTE_CUSTOMIZATION_SUPPORT_CONTACT_HEADING_PLACEHOLDER")}
                  value={heading ? heading : ""}
                  onChange={(evt: any) => updateSupportDetailContent("heading", evt.target.value)}
                />
              </Col>
            </Row>
          </FormGroup>
          <FormGroup>
            <Row align="start">
              <Col md={1.5}>
                <FormLabel target="support-contact-details-content" content="INVOICE_CREDIT_NOTE_CUSTOMIZATION_LABEL_CONTENT" />
              </Col>
              <Col md={10.5}>
                <Textarea
                  id="support-contact-details-content"
                  value={content ? content : ""}
                  placeholder={`${counterpart("INVOICE_CREDIT_NOTE_CUSTOMIZATION_SUPPORT_CONTACT_CONTENT_PLACEHOLDER1")}\n\n${counterpart(
                    "INVOICE_CREDIT_NOTE_CUSTOMIZATION_SUPPORT_CONTACT_CONTENT_PLACEHOLDER2"
                  )}`}
                  onChange={(evt: any) => updateSupportDetailContent("content", evt.target.value)}
                />
              </Col>
            </Row>
          </FormGroup>
          <FormGroup>
            <Row align="center">
              <Col md={1.5}>
                <FormLabel target="support-contact-details-url" content="INVOICE_CREDIT_NOTE_CUSTOMIZATION_LABEL_SUPPORT_PAGE_URL" noMargin />
              </Col>
              <Col md={10.5}>
                <Input
                  placeholder={counterpart("INVOICE_CREDIT_NOTE_CUSTOMIZATION_SUPPORT_CONTACT_EMAIL_PLACEHOLDER")}
                  id="support-contact-details-url"
                  isError={hasError && errorMsg === "Invalid URL"}
                  errorMsg={errorMsg}
                  value={supportPageUrl ? supportPageUrl : ""}
                  onChange={(evt: any) => updateSupportDetailContent("supportPageUrl", evt.target.value)}
                />
              </Col>
            </Row>
          </FormGroup>
          {supportPageUrl && (
            <Row align="center">
              <Col md={1.5}>
                <FormLabel target="support-contact-details-url" content="INVOICE_CREDIT_NOTE_CUSTOMIZATION_LABEL_BUTTON_TEXT" noMargin />
              </Col>
              <Col md={10.5}>
                <Input
                  placeholder={counterpart("INVOICE_CREDIT_NOTE_CUSTOMIZATION_SUPPORT_BUTTON_TEXT_PLACEHOLDER")}
                  id="support-contact-details-url"
                  required
                  value={supportPageUrlButtonText ? supportPageUrlButtonText : ""}
                  onChange={(evt: any) => updateSupportDetailContent("supportPageUrlButtonText", evt.target.value)}
                />
              </Col>
            </Row>
          )}
        </Panel>
        <Panel title="INVOICE_CREDIT_NOTE_CUSTOMIZATION_ADVERTISEMENT">
          <Text content="INVOICE_CREDIT_NOTE_CUSTOMIZATION_ADVERTISEMENT_CONTENT" translateWith={{ invoiceType: mapLinkInvoiceType[paramData] }} />
          <FormGroup>
            <Row align="center">
              <Col md={1.5}>
                <FormLabel target="support-contact-details-advert" content="INVOICE_CREDIT_NOTE_CUSTOMIZATION_INCLUDE_ADVERT" noMargin />
              </Col>
              <Col md={10.5}>
                <Switch id="collect-tax" checked={isAdvertEnabled} onChange={() => updateSupportDetailContent("isAdvertEnabled", !isAdvertEnabled)} />
              </Col>
            </Row>
          </FormGroup>
          {isAdvertEnabled && (
            <>
              <FileUpload
                title={
                  <Text
                    content="INVOICE_CREDIT_NOTE_CUSTOMIZATION_ADVERT_UPLOADER_TITLE"
                    translateWith={
                      { link: <a href="javascript:void(0)">{counterpart("INVOICE_CREDIT_NOTE_CUSTOMIZATION_ADVERT_UPLOADER_BROWSE")}</a> }
                    }
                    noMargin
                  />
                }
                shouldTranslateTitle={false}
                subTitle="INVOICE_CREDIT_NOTE_CUSTOMIZATION_ADVERT_UPLOADER_SUB_TITLE"
                image={advertImageUrl ? advertImageUrl : ""}
                onDrop={async (files: any) => {
                  const response = (await API.uploadInvoiceAdBanner(currentCompanyDomain, files[0])) as {
                    fileName: string;
                    imageSrcUrl: string;
                  };
                  updateSupportDetailContent("advertImageUrl", response.imageSrcUrl);
                }}
              />
              <Row align="center">
                <Col md={1}>
                  <FormLabel target="support-contact-details-advert-url" content="INVOICE_CREDIT_NOTE_CUSTOMIZATION_LABEL_LINK_URL" noMargin />
                </Col>
                <Col md={11}>
                  <Input
                    placeholder={counterpart("INVOICE_CREDIT_NOTE_CUSTOMIZATION_LINK_URL_PLACEHOLDER")}
                    id="support-contact-details-advert-url"
                    value={advertLinkToUrl ? advertLinkToUrl : ""}
                    required
                    isError={hasError && errorMsg === "Invalid image URL"}
                    errorMsg={errorMsg}
                    onChange={(evt: any) => updateSupportDetailContent("advertLinkToUrl", evt.target.value)}
                  />
                </Col>
              </Row>
            </>
          )}
        </Panel>
        <Row align="center">
          <Col md={8}>
            <Button id="support-contact-details-update" isLoading={isLoading} type="submit" isFullWidth buttonType="general" title="INVOICE_CREDIT_NOTE_CUSTOMIZATION_BUTTON_UPDATE_CHANGES" />
          </Col>
          <Col md={4}>
            <Button
              id="support-contact-details-cancel"
              isFullWidth
              buttonType="error"
              title="INVOICE_CREDIT_NOTE_CUSTOMIZATION_BUTTON_CANCEL"
              onClick={() => dispatch(resetContactDetailsInvoice(invoiceConfigType))}
            />
          </Col>
        </Row>
      </form>
    </div>
  );
};

export default SupportContactDetails;
