import { ConfigConstants } from "./config";
import { fetchPost } from "./fetchUtils";
import { storeSet, storeGet, storeRemove } from "./storeUtils";
import { reduxStore } from "../index";
import { BILLSBY_AUTH_DATA_CONTROL, BILLSBY_SELECTED_COMPANY, BILLSBY_ONE_SESSION_LOGIN } from "./constants";
import history from "./history";
import { IToken, IParsedToken, ICompany } from "../models/Auth";
import { initAuthData, initReadmeioLinks, cookieSetSyncRedux } from "../actions/authActions";
import rsa from "jsrsasign";
import API from "./API";
import { PendingInvitation } from "../models/Invitations";
import { injectTrackingScripts } from "./injectScripts";

const IS_PRODUCTION = process.env["NODE_ENV"] === "production";

export function login(username: string, password: string, code2fa?: string, redirectAfterLogin: boolean = true) {
  return new Promise(function (fulfill: Function, reject: Function) {
    // username = username.split('+').join('%2B');
    // password = password.split('+').join('%2B');

    const data = JSON.stringify({ "grant_type": "password", username, password, code: code2fa });
    let url = `${ConfigConstants.baseUrl}token`;
    const headers = new Headers({ "Content-Type": "application/json", ClientId: ConfigConstants.clientId });

    fetchPost(url, headers, data)
      .then((response: any) => {
        response = <IToken>response;

        if (!reduxStore.getState().login.rememberUser) {
          // if the user doesn't check the remember user checkbox we save the token in the session storage
          //setStoreType(SESSION_STORAGE);
          storeSet(BILLSBY_ONE_SESSION_LOGIN, new Date());
        }

        // Store session data in browser local storage
        storeSet(BILLSBY_AUTH_DATA_CONTROL, response);
        storeRemove(BILLSBY_SELECTED_COMPANY);
        return Promise.all([response, API.fetchPendingInvitations(), reduxStore.dispatch<any>(initAuthData())])

      })
      .then(async ([token, pendingInvitations]) => {
        // If only one company redirect to company subdomain
        if (reduxStore.getState().auth.companies.length === 1 && !(pendingInvitations as Array<PendingInvitation>).length) {
          const company: ICompany = reduxStore.getState().auth.companies[0];
          storeSet(BILLSBY_SELECTED_COMPANY, company.companyId);
          await refreshToken(company.companyId);
          if (redirectAfterLogin) {
            window.location.replace(`//${company.companyDomain}${ConfigConstants.billsbyDomain}${!IS_PRODUCTION ? `:${process.env.REACT_APP_PORT}` : ""}`);
            return;
          }
          return fulfill(token);
        }
        injectTrackingScripts(token);
        reduxStore.dispatch(initReadmeioLinks());

        if (redirectAfterLogin) {
          history.push("/");
        }

        fulfill(token);
      })

      .catch((err) => {
        /*if(err && err.message === "User_Login_2FA_Code_Invalid") {
          history.push("/two-factor-authentication");
        }*/
        reject(err);
      });
  });
}


export function logout() {
  // this is a public route that display an empty screen, it is used to avoid to see for a second the login and then experience a hard refresh again
  history.push("/loggin-out");
  reduxStore.dispatch(cookieSetSyncRedux(BILLSBY_SELECTED_COMPANY, undefined));
  reduxStore.dispatch(cookieSetSyncRedux(BILLSBY_AUTH_DATA_CONTROL, undefined));

  if (!IS_PRODUCTION) {
    window.location.replace(<string>process.env.REACT_APP_BROWSER);
    return;
  }

  // redirect to environment specific url -> app.dev.billsby.com | app.test.billsby.com
  window.location.replace(`//app${ConfigConstants.billsbyDomain}`);
}

export function refreshToken(companyId?: number) {
  return new Promise(function (fulfill: Function, reject: Function) {

    let storeData = storeGet(BILLSBY_AUTH_DATA_CONTROL);
    const data = JSON.stringify({ "grant_type": "refresh_token", "refresh_token": storeData?.refresh_token, "company_id": companyId });
    let url = `${ConfigConstants.baseUrl}token`;
    const headers = new Headers({ "Content-Type": "application/json", ClientId: ConfigConstants.clientId });

    fetchPost(url, headers, data)
      .then((response: any) => {

        response = <IToken>response;
        // Store session data in browser local storage
        storeSet(BILLSBY_AUTH_DATA_CONTROL, response);
        reduxStore.dispatch(initAuthData());
        fulfill(response);

      }).catch((error) => {
        reject(error);
      });
  });
}

export function getDocTokenSSO(parsedToken: IParsedToken) {
  const userData = parsedToken;
  const sso_secret = "ROMmJZXuK0W9G0uppF5N";
  // User being logged into ReadMe
  const user = {
    name: userData.given_name,
    email: userData.email,
    version: 1,
    isReadOnly: true,
  };

  return rsa.KJUR.jws.JWS.sign("HS256", {alg: "HS256"}, JSON.stringify(user), sso_secret);
}

export function getScopeToken(companyDomain: string, companyId: number) {
  return new Promise(function (fulfill: Function, reject: Function) {

    let storeData = storeGet(BILLSBY_AUTH_DATA_CONTROL);
    const data = JSON.stringify({
      "refresh_token": storeData.refresh_token,
      "scope": [`companydomain=${companyDomain}`],
      "grant_type": "refresh_token",
      "company_id": companyId
    });
    let url = `${ConfigConstants.baseUrl}token`;
    const headers = new Headers({ "Content-Type": "application/json", ClientId: ConfigConstants.clientId });

    fetchPost(url, headers, data)
      .then((response: any) => {

        response = <IToken>response;
        // Store session data in browser local storage
        storeSet(BILLSBY_AUTH_DATA_CONTROL, response);
        reduxStore.dispatch(initAuthData());
        fulfill(response);

      }).catch((error) => {
        reject(error);
      });
  });
}