import React from "react";
import Text from "../../components/ui/text/Text";
import Panel from "../../components/ui/panel/Panel";
import GettingStartedStep from "./GettingStartedStep";
import { IGettingStartedSettings, GettingStartedType } from "../../models/GettingStarted";

interface IGettingStartedPanel {
  dashboardSettings: IGettingStartedSettings
}

const GettingStartedPanel: React.FC<IGettingStartedPanel> = ({ dashboardSettings }) => {
  const { status } = dashboardSettings;
  if(status === GettingStartedType.PreLive || status === GettingStartedType.Live) {
    return null;
  }

  return (
    <Panel className="getting-started-panel">
      <Text content="GETTING_STARTED_TITLE" className="getting-started-panel__title" />
      <GettingStartedStep text="GETTING_STARTED_CREATE_ACCOUNT" status="done" />
      <GettingStartedStep text="GETTING_STARTED_COMPANY_DETAILS" status="done" />
      <GettingStartedStep text="GETTING_STARTED_PRODUCT_AND_PLAN" status={ status === GettingStartedType.GettingStarted1 ? "current" : "done"} />
      <GettingStartedStep text="GETTING_STARTED_BRANDING_SETTINGS" status={ status === GettingStartedType.GettingStarted2 ? "current" : "locked"}  noMargin />
    </Panel>
  )
}

export default GettingStartedPanel;