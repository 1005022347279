import React from "react";
import Text from "../text/Text";

import "./NoticePanel.scss";

interface INoticePanel {
  id?: string,
  content: JSX.Element | string,
  className?: string,
  isModal?: boolean,
  icon?: string,
  translateWith?: object
  type: "error" | "warning" | "success",
  shouldTranslate?: boolean,
  isVisible?: boolean
}

const NoticePanel: React.FC<INoticePanel> = React.memo((props: INoticePanel) => {
  const { id, type, className, content, isModal, icon, translateWith, shouldTranslate = true, isVisible = true } = props;

  if(!isVisible) {
    return null
  }
  
  return (
    <div id={id} className={`${className ? className : ""} notice-panel notice-panel--${type} ${isModal ? "notice-panel--modal" : ""}`}>
      <div className="notice-panel__icon">
        <i className={`${icon ? icon : "far fa-lightbulb-exclamation"}`} />
      </div>
      <Text translateWith={translateWith ? translateWith : {}} className="notice-panel__content" component="p" shouldTranslate={shouldTranslate} content={content} noMargin />
    </div>
  );
});

export default NoticePanel;
