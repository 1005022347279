import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../../..";
import history from "../../../utils/history";
import { resetCreateAddOnState, fetchCompanyProductsWithPlans, setAddOnsSelectedPlan } from "../../../actions/addOnsActions";
import NavigationBar from "../../../components/ui/navigation-bar/NavigationBar";
import NavigationAlertCurrencyMismatch from "../../../components/ui/navigation-alert/NavigationAlertCurrencyMismatch";
import PageWrapper from "../../../components/ui/layout/PageWrapper";
import Panel from "../../../components/ui/panel/Panel";
import Text from "../../../components/ui/text/Text";
import counterpart from "counterpart";
import StepCollapsablePanel from "../../../components/ui/step-collapsable-panel/StepCollapsablePanel";
import { findDOMNode } from "react-dom";
import FormGroup from "../../../components/ui/form-group/FormGroup";
import { Row, Col } from "react-grid-system";
import FormLabel from "../../../components/ui/form-label/FormLabel";
import Input from "../../../components/ui/input/Input";
import Checkbox from "../../../components/ui/checkbox/Checkbox";
import Button from "../../../components/ui/button/Button";
import "./CreateDiscount.scss";
import { setCreateDiscountReducerFields, setDiscountFormCompleted, setDiscountFormHasError, createDiscount, setDiscountStepsCollapsed, resetCreateDiscount, fetchDiscountDetails, updateDiscount, fetchCurrenciesOfPlansDiscounts } from "../../../actions/discount-coupon/createDiscountActions";
import SelectPlanStep from "../../../components/discount-and-coupons/select-plan-step/SelectPlanStep";
import Switch from "../../../components/ui/switch/Switch";
import { DeductionType, DiscountType, GetDiscountResponse } from "../../../utils/grpc/generated/Billsby.Protos/core/private/discounts/discounts_pb";
import CurrencyInputTab from "../../../components/discount-and-coupons/currency-input-tab/CurrencyInputTab";
import { fetchGettingStartedSettings } from "../../../actions/authActions";
import Form from "../../../components/ui/form/Form";
import { useParams } from "react-router";

const CreateDiscount: React.FC = () => {
  const auth = useSelector((state: AppState) => state.auth);
  const createDiscountState = useSelector((state: AppState) => state.createDiscountReducer);
  const selectAddOnPlan = useSelector((state: AppState) => state.selectAddOnPlanReducer);

  const dispatch = useDispatch<Function>();
  const params = useParams<{ discountId: string }>();

  const { helpTextContentBasicForm, helpTextTitleBasicForm, name, canBeUseInConjunction,
    stageCollapsed, stageStatuses, stageErrors, isCreatingDiscountRequest,
    isCreatingDiscountSuccess, isCreatingDiscountFailure, discountType, helpTextTitleDiscountForm,
    helpTextContentDiscountForm, discountTypeButtonText, isAllPlans, hasSelectedDiscountType,
    deductionType, percentage, monetaries, currenciesOfPlans } = createDiscountState;

  const { selectedPlans, companyPlans } = selectAddOnPlan;
  const isFormValid = name;
  const formRef0: any = useRef(null);
  const discountId = +params.discountId || null;

  console.log({discountId})

  const [formFourIsComplete, setFormFourIsComplete] = useState(false);
  const [discountName, setDiscountName] = useState("");

  const hasUnassingedCurrency = () => discountType === DiscountType.MONETARY && monetaries.filter(i => !i.value).length > 0;

  const fetchDiscountData = async () => {
    try {
      // this call needs to be blocking in case of editing a discount
      await dispatch(fetchCompanyProductsWithPlans(auth.currentCompanyId as number));
      if(!discountId) {
        return;
      }  
      const { response } = await dispatch(fetchDiscountDetails(auth.currentCompanyId as number, discountId)) as { response: GetDiscountResponse };
      await dispatch(fetchCurrenciesOfPlansDiscounts(auth.currentCompanyDomain, response.getPlanIdsList()));
      dispatch(setAddOnsSelectedPlan(response.getPlanIdsList()));
      setDiscountName(response.getName());
    } catch (err) {
      history.push("/discount-and-coupons")
    }
  }

  useEffect(() => {
    if (isFormValid && (isCreatingDiscountSuccess || isCreatingDiscountFailure)) {
      history.push("/discount-and-coupons");
    }

  }, [isCreatingDiscountSuccess, isCreatingDiscountFailure])

  useEffect(() => {
    fetchDiscountData();
    return () => {
      dispatch(resetCreateDiscount());
      dispatch(resetCreateAddOnState());
    }
  }, [])


  const submitForm = () => {
    const planIds = isAllPlans ? companyPlans.map(i => i.planId) : selectedPlans.map(i => i.planId);
    if (discountId) {
      dispatch(updateDiscount(auth.currentCompanyId as number, discountId, createDiscountState, planIds))
        .then(() => dispatch(fetchGettingStartedSettings(auth.currentCompanyDomain)))
        .catch((err: any) => console.log(err));
    } else {
      dispatch(createDiscount(auth.currentCompanyId as number, createDiscountState, planIds));
    }
  }
  const checkAllCurrencies = (arr1: string[], arr2: string[]) => {   
    if (arr1.length !== arr2.length) return false;
    for (var i = 0; arr1.length < i; i++) {
      if (arr1[i] !== arr2[i]) return false;
    }

    return true;
  };

  const checkAllCurrenciesHaveValue = () => {
    if(monetaries.find(m => !m.value)) {
      return false
    }
    return true;
  };

  const checkFormFourIsComplete = () => {
    if(!isAllPlans && selectedPlans.length === 0) {
      setFormFourIsComplete(false)
      return;
    }
    if (discountType === DiscountType.PERCENTAGE) {
      setFormFourIsComplete(!!percentage);
    } else if (discountType === DiscountType.MONETARY) {
      if (!currenciesOfPlans) {
        setFormFourIsComplete(false);
        return;
      }
      if (!checkAllCurrenciesHaveValue()) {
        setFormFourIsComplete(false);
        return;
      }
      if(!selectedPlans.length && !isAllPlans) {
        setFormFourIsComplete(false);
        return;
      }
      var planCurrencies = currenciesOfPlans.getCurrenciesList().map(i => i.getIso3Code());
      var monetaryCurrencies = monetaries.map(i => i.currencyCode);

      setFormFourIsComplete(checkAllCurrencies(planCurrencies, monetaryCurrencies));
    }
  }

  const validatePercentageInput = (input?: number) => {
    if (!!input && Number(input) >= 0 && Number(input) <= 100)
      return input;
  
    return undefined;
  };

  useEffect(() => {
    checkFormFourIsComplete();

  }, [name, canBeUseInConjunction, discountType, isAllPlans, deductionType, percentage, monetaries, selectedPlans, currenciesOfPlans]);

  return (
    <div>
      <NavigationBar
        pageTitle=""
        previousPageTitle="DISCOUNT_AND_COUPONS_TITLE"
        previousPageCb={() => history.push("/discount-and-coupons")}
      />
      <NavigationAlertCurrencyMismatch />

      <PageWrapper className="create-discount">
        <Panel className="create-discount__heading" translateWith={{ name: discountName }}
          title={discountId ? "EDIT_DISCOUNT_HEADING_TITLE" : "CREATE_DISCOUNT_HEADING_TITLE"}>
          <Text
            content={discountId ? "EDIT_DISCOUNT_HEADING_TEXT" : "CREATE_DISCOUNT_HEADING_TEXT"}
            className="create-discount__heading-text"
            noMargin
          />
        </Panel>

        <StepCollapsablePanel
          number={1}
          isCompleted={stageStatuses[0]}
          isCollapsed={!discountId ? stageCollapsed[0] : false}
          isShowTitleIcon={!discountId}
          onClick={() => !discountId ? dispatch(setDiscountStepsCollapsed(0)) : {}}
          //onFocus={() => setIsBasicFormCollapsed(!isBasicFormCollapsed)}
          hasError={stageErrors[0]}
          title={discountId ? "EDIT_DISCOUNT_FORM_HEADER_BASICS" : "CREATE_DISCOUNT_FORM_HEADER_BASICS"}
          helpMenuPosition="right"
          helpMenuTitle={helpTextTitleBasicForm}
          helpMenuContent={
            <div>
              {helpTextContentBasicForm.map((text, id) => <Text key={id} content={text} />)}
            </div>
          }
        >

          <Form ref={formRef0} onChange={() => {
            const form = findDOMNode(formRef0.current) as HTMLFormElement;
            if (form.checkValidity()) {
              dispatch(setDiscountFormCompleted(0, true));
              dispatch(setDiscountFormHasError(0, false));
            } else {
              dispatch(setDiscountFormCompleted(0, false));
              dispatch(setDiscountFormHasError(0, true));
            }
          }}>

            <FormGroup className={"create-discount__basic-form"}>
              <Row align="center">
                <Col md={2}>
                  <FormLabel target="name" content="CREATE_DISCOUNT_FORM_NAME_INPUT" />
                </Col>
                <Col md={10}>
                  <Input
                    id="name"
                    required
                    value={name}
                    placeholder={counterpart("CREATE_DISCOUNT_FORM_NAME_INPUT_PLACEHOLDER")}
                    onChange={(evt: any) => dispatch(setCreateDiscountReducerFields("name", evt.target.value))}
                    onFocus={() => {
                      dispatch(setCreateDiscountReducerFields("helpTextTitleBasicForm", "CREATE_DISCOUNT_FORM_NAME_HELP_TITLE"));
                      dispatch(setCreateDiscountReducerFields("helpTextContentBasicForm", ["CREATE_DISCOUNT_FORM_NAME_HELP_TEXT_1"]));
                    }}
                  />
                </Col>
              </Row>
            </FormGroup>

            <FormGroup className={"create-discount__basic-form"}>
              <Row align="center">
                <Col md={2}>
                  <Switch
                    className="create-discount__basic-form__switch"
                    id={"create-discount-conjuction-switch"}
                    checked={canBeUseInConjunction}
                    onChange={() => {
                      dispatch(setCreateDiscountReducerFields("canBeUseInConjunction", !canBeUseInConjunction));
                      dispatch(setCreateDiscountReducerFields("helpTextTitleBasicForm", "CREATE_DISCOUNT_FORM_CONJUCTION_HELP_TITLE"));
                      dispatch(setCreateDiscountReducerFields("helpTextContentBasicForm", ["CREATE_DISCOUNT_FORM_CONJUCTION_HELP_TEXT_1"]));
                    }}
                  />
                </Col>
                <Col md={10}>
                  <FormLabel target="junction" content="CREATE_DISCOUNT_FORM_CONJUCTION_INPUT" noMargin />
                </Col>
              </Row>
            </FormGroup>
          </Form>
        </StepCollapsablePanel>

        <SelectPlanStep discountId={discountId} />

        <StepCollapsablePanel
          number={3}
          isCompleted={hasSelectedDiscountType}
          isCollapsed={stageCollapsed[2]}
          isShowTitleIcon={!discountId}
          isLocked={hasSelectedDiscountType || (stageErrors[0] || !stageStatuses[0]) || (!isAllPlans && selectedPlans.length < 1)}
          onClick={() => !discountId && dispatch(setDiscountStepsCollapsed(2))}
          hasError={stageErrors[2]}
          title={
            <div className="create-discount__discount-type-title">
              <Text content={discountId ? "EDIT_DISCOUNT_FORM_DISCOUNT_TYPE" : "CREATE_DISCOUNT_FORM_DISCOUNT_TYPE"} noMargin />
              {
                hasSelectedDiscountType
                  ? (
                    <div>
                      <div className="border" />
                      <Text
                        content={discountType === DiscountType.MONETARY ? "CREATE_DISCOUNT_FORM_DISCOUNT_TYPE_MONETARY" : "CREATE_DISCOUNT_FORM_DISCOUNT_TYPE_PERCENTAGE"} noMargin />
                    </div>
                  )
                  : null
              }
            </div>
          }
          shouldTranslateTitle={false}
          isShowLockIcon={hasSelectedDiscountType || (stageErrors[0] || !stageStatuses[0]) || (!isAllPlans && selectedPlans.length < 1)}
          helpMenuPosition="right"
          helpMenuTitle={helpTextTitleDiscountForm}
          helpMenuContent={
            <div>
              {helpTextContentDiscountForm.map((text, id) => <Text key={id} content={text} />)}
            </div>
          }
        >

          <form className="create-discount__pick-discount__type__panel">
            <Text content="CREATE_DISCOUNT_FORM_DISCOUNT_TYPE_TEXT" />
            <Checkbox checked={discountType === DiscountType.MONETARY} onClick={() => {
              dispatch(setCreateDiscountReducerFields("discountType", DiscountType.MONETARY));
              dispatch(setCreateDiscountReducerFields("discountTypeButtonText", "CREATE_DISCOUNT_FORM_DISCOUNT_TYPE_MONETARY_BUTTON"));
              dispatch(setCreateDiscountReducerFields("helpTextTitleDiscountForm", "CREATE_DISCOUNT_FORM_DISCOUNT_TYPE_MONETARY"));
              dispatch(setCreateDiscountReducerFields("helpTextContentDiscountForm", ["CREATE_DISCOUNT_FORM_DISCOUNT_HELP_TEXT_MONETARY_1", "CREATE_DISCOUNT_FORM_DISCOUNT_HELP_TEXT_MONETARY_2"]));
            }} value='auto-calculation' content={<Text content='CREATE_DISCOUNT_FORM_DISCOUNT_TYPE_MONETARY' noMargin />} />
            <Checkbox checked={discountType === DiscountType.PERCENTAGE} onClick={() => {
              dispatch(setCreateDiscountReducerFields("discountType", DiscountType.PERCENTAGE));
              dispatch(setCreateDiscountReducerFields("discountTypeButtonText", "CREATE_DISCOUNT_FORM_DISCOUNT_TYPE_PERCENTAGE_BUTTON"));
              dispatch(setCreateDiscountReducerFields("helpTextTitleDiscountForm", "CREATE_DISCOUNT_FORM_DISCOUNT_TYPE_PERCENTAGE"));
              dispatch(setCreateDiscountReducerFields("helpTextContentDiscountForm", ["CREATE_DISCOUNT_FORM_DISCOUNT_HELP_TEXT_PERCENTAGE_1", "CREATE_DISCOUNT_FORM_DISCOUNT_HELP_TEXT_PERCENTAGE_2"]));
            }} value='auto-calculation' content={<Text content='CREATE_DISCOUNT_FORM_DISCOUNT_TYPE_PERCENTAGE' noMargin />} />
            <Button
              className="create-discount__select-discount-type__btn"
              id="create-discount-select-discount-type-btn"
              title={discountTypeButtonText}
              buttonType="general"
              isFullWidth={true}
              onClick={() => {
                dispatch(setCreateDiscountReducerFields("hasSelectedDiscountType", true));
                dispatch(setDiscountStepsCollapsed(3));
              }}
            />
          </form>
        </StepCollapsablePanel>

        <StepCollapsablePanel
          number={4}
          isCompleted={stageStatuses[3]}
          isCollapsed={!discountId ? stageCollapsed[3] : !selectedPlans.length && !isAllPlans}
          isLocked={!hasSelectedDiscountType || !selectedPlans.length && !isAllPlans}
          isShowTitleIcon={!discountId}
          onClick={() => !discountId ? dispatch(setDiscountStepsCollapsed(3)) : {}}
          hasError={stageErrors[3] || hasUnassingedCurrency()}
          title={discountId ? "EDIT_DISCOUNT_FORM_DISCOUNT_DEDUCTION" : "CREATE_DISCOUNT_FORM_DISCOUNT_DEDUCTION"}
          helpMenuPosition="right"
          hasHelpSection={false}
          isShowLockIcon={!hasSelectedDiscountType}
        >

          <form className="create-discount__discount-deduction__panel">

            <Text content="CREATE_DISCOUNT_FORM_DISCOUNT_DEDUCTION_TEXT" />

            <Checkbox checked={deductionType === DeductionType.PLAN_ONLY} onClick={() => {
              dispatch(setCreateDiscountReducerFields("deductionType", DeductionType.PLAN_ONLY));
              dispatch(setCreateDiscountReducerFields("helpTextTitleDiscountForm", "CREATE_DISCOUNT_FORM_DISCOUNT_TYPE_MONETARY"));
              dispatch(setCreateDiscountReducerFields("helpTextContentDiscountForm", ["CREATE_DISCOUNT_FORM_DISCOUNT_HELP_TEXT_MONETARY_1, CREATE_DISCOUNT_FORM_DISCOUNT_HELP_TEXT_MONETARY_2"]));
            }} value='auto-calculation' content={<Text content='CREATE_DISCOUNT_FORM_DISCOUNT_DEDUCTION_FROM_PLAN' noMargin />} />
            <Checkbox checked={deductionType === DeductionType.ENTIRE_SUBSCRIPTION} onClick={() => {
              dispatch(setCreateDiscountReducerFields("deductionType", DeductionType.ENTIRE_SUBSCRIPTION));
              dispatch(setCreateDiscountReducerFields("helpTextTitleDiscountForm", "CREATE_DISCOUNT_FORM_DISCOUNT_TYPE_PERCENTAGE"));
              dispatch(setCreateDiscountReducerFields("helpTextContentDiscountForm", ["CREATE_DISCOUNT_FORM_DISCOUNT_HELP_TEXT_PERCENTAGE_1", "CREATE_DISCOUNT_FORM_DISCOUNT_HELP_TEXT_PERCENTAGE_2"]));
            }} value='auto-calculation' content={<Text content='CREATE_DISCOUNT_FORM_DISCOUNT_DEDUCTION_FROM_SUBSCRIPTION' noMargin />} />

            {
              discountType === DiscountType.PERCENTAGE &&
              <Row align="center">
                <Col md={1}>
                  <FormLabel target="percentage" content="CREATE_DISCOUNT_FORM_DISCOUNT_DEDUCTION_FROM_PERCENTAGE" noMargin />
                </Col>
                <Col md={10}>
                  <Input
                    id="percentage"
                    required
                    type="number"
                    value={percentage}
                    placeholder={counterpart("CREATE_DISCOUNT_FORM_DISCOUNT_DEDUCTION_FROM_PERCENTAGE_PLACEHOLDER")}
                    onChange={(evt: any) => {
                      const percentageValue = validatePercentageInput(evt.target.value);
                      dispatch(setCreateDiscountReducerFields("percentage", percentageValue));
                      dispatch(setDiscountFormCompleted(3, !!percentageValue));
                    }}
                    onFocus={() => {
                      dispatch(setCreateDiscountReducerFields("helpTextTitleBasicForm", "CREATE_DISCOUNT_FORM_NAME_HELP_TITLE"));
                      dispatch(setCreateDiscountReducerFields("helpTextContentBasicForm", ["CREATE_DISCOUNT_FORM_NAME_HELP_TEXT_1"]));
                    }}
                  />
                </Col>
                <Col md={1}>
                  <FormLabel target="percentage" content="%" shouldTranslate={false} noMargin />
                </Col>
              </Row>
            }
    
            {discountType === DiscountType.MONETARY && <CurrencyInputTab isEdit={!!discountId} />}

          </form>
        </StepCollapsablePanel>

        <Button
          className="create-discount__btn"
          id="create-discount-btn"
          title={discountId ? "EDIT_DISCOUNT_BUTTON" : "CREATE_DISCOUNT_BUTTON"}
          buttonType="general"
          disabled={!formFourIsComplete}
          isLoading={isCreatingDiscountRequest}
          isFullWidth={true}
          onClick={() => submitForm()}
        />

        {!!discountId && <Text className="create-discount__warning" content="EDIT_DISCOUNT_WARNING_MESSAGE" translateWith={{ numberOfCustomers: "numberOfCustomers" }} />}

      </PageWrapper>
    </div>
  )
}

export default CreateDiscount;
