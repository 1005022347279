import React, { useState, useLayoutEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { IAuthReducerState } from "../../../reducers/authReducer";
import {
  setLinkAddOnsAllowancesFields,
  setSelectedAddOnsPage,
  fetchAddOnsSelectorList,
  setSelectedAddOnsId,
  setSelectedAddOns,
  setForceAddOn,
  deselectAllSelectedAddOns,
} from "../../../actions/linkAddOnsAllowancesActions";
import { AppState } from "../../..";
import StepCollapsablePanel from "../../ui/step-collapsable-panel/StepCollapsablePanel";
import Button from "../../ui/button/Button";
import Text from "../../ui/text/Text";
import Pagination from "../../ui/pagination/Pagination";
import { IPlanReducer } from "../../../reducers/plansReducer";
import TabbedContainer from "../../ui/tabbed-container/TabbedContainer";
import { NavLink } from "react-router-dom";
import counterpart from "counterpart";
import "./LinkAddOnsAllowances.scss";
import { setPlanFormCollapsed, setPlanFormHasError, setPlanFormCompleted } from "../../../actions/productPlanCycleActions";
import { ILinkAddOnsAllowancesReducerState, isPriceModelsConfigured, ISelectedCompanyElement } from "../../../reducers/linkAddOnsAllowancesReducer";
import SelectAddOns from "./select-addons/SelectAddOns";
import SelecteAddOnsListItem from "./select-addons-list-item/SelectAddOnsListItem";
import { setShowSelectAddOnsModal } from "../../../actions/linkAddOnsAllowancesActions";
import AddOnConfigurePrice from "../../addons-allowances/addon-cost/AddOnConfigurePrice";
import { PricingModelType, IProductLightWithPlans, IPlanAddOn, IPlanAllowance } from "../../../models/Product";
import { Currency, AddonPriceModel } from "../../../utils/grpc/generated/Billsby.Protos/core/private/addons/addons_pb";
import ProgressIndicator from "../../ui/progress-indicator/ProgressIndicator";
import { ICycleReducer } from "../../../reducers/cyclesReducer";
import { AddonsAllowancesScreen } from "../../../models/AddonsAllowances";
import { AllowancePriceModel } from "../../../utils/grpc/generated/Billsby.Protos/core/private/allowances/allowances_pb";

interface Props {
  product: IProductLightWithPlans;
  isCreatingPlan: boolean;
}

const LinkAddOnsAllowances: React.FC<Props> = ({ product, isCreatingPlan }) => {
  const auth = useSelector<AppState, IAuthReducerState>(state => state.auth)
  const { stageStatuses, stageCollapsed, addons, allowances, stageErrors } = useSelector<AppState, IPlanReducer>(state => state.plans)
  const  { cycles } = useSelector<AppState, ICycleReducer>(state => state.cycles)
  const { showSelectAddOnsModal, selectedPricingModel, numberOfPages, selectedAddOnsIds, selectedAddOnsDisplay, companyAddOns,
    isFetchingCompanyAddOnsSuccess, addOnPriceModel, selectedAddOns, selectedAllowances, selectedAllowancesDisplay,
    selectedAllowancesIds } = useSelector<AppState, ILinkAddOnsAllowancesReducerState>(state => state.linkAddOnsAllowancesReducer)
  const dispatch = useDispatch<Function>()

  const [activeTab, setActiveTab] = useState<AddonsAllowancesScreen>(AddonsAllowancesScreen.ALLOWANCES);
  const [isLoadingData, setIsLoadingData] = useState(false);
  const [hasFocusedOnce, setHasFocusedOnce] = useState(false);


  const planProductCurrencyTemplate = product.billingCurrencyTemplate;
  const templateCharToBeReplaced = planProductCurrencyTemplate.indexOf("N.D") > -1 ? "N.D" : "N"; 
  const currencySymbol = planProductCurrencyTemplate.replace(templateCharToBeReplaced, "_");
  const isAddonScreen = activeTab === AddonsAllowancesScreen.ADDONS;
  const [isAddonLoaded, setIsAddonLoaded] = useState(false);
  const [isAllowanceLoaded, setIsAllowanceLoaded] = useState(false);

  const fetchAddOns = async () => {
    setIsLoadingData(true);
    try {
      await dispatch(fetchAddOnsSelectorList(auth.currentCompanyDomain, activeTab));
    }
    catch (err) {
      console.log(err);
    }
    finally {
      setIsLoadingData(false);
    }
  };

  useLayoutEffect(() => {
    fetchAddOns();
  }, [activeTab]);


  useLayoutEffect(() => {
    if (!isFetchingCompanyAddOnsSuccess) { return }

    if (isAddonScreen && !isAddonLoaded || !isAddonScreen && !isAllowanceLoaded) {
      const originalItems = (isAddonScreen ? addons : allowances) as Array<IPlanAddOn | IPlanAllowance>;
      const selectedItems = (isAddonScreen ? selectedAddOns : selectedAllowances) as Array<ISelectedCompanyElement>
      dispatch(deselectAllSelectedAddOns());
      dispatch(setSelectedAddOns([]));
      const allItems = [...selectedItems, ...originalItems];
      allItems.forEach(el => dispatch(setSelectedAddOnsId(el.id)));
      dispatch(setSelectedAddOns(allItems.map(el => el.id)));

      allItems.forEach(el => {
        if (el.isForced) {
          dispatch(setForceAddOn(el.id));
        }
      });

      if (isAddonScreen) {
        setIsAddonLoaded(true);
      }
      else {
        setIsAllowanceLoaded(true);
        //load selected allowances first then selected addons
        setActiveTab(AddonsAllowancesScreen.ADDONS);
        if(!isCreatingPlan) {
          //window.scrollTo({ top: 0, behavior: 'smooth' });
        }
      }

    }

  }, [companyAddOns, addons, allowances]);



  useLayoutEffect(() => {
    const addonAllowance = [...selectedAddOns, ...selectedAllowances];
    const addonAllowanceDisplay = [...selectedAddOnsDisplay, ...selectedAllowancesDisplay];
    const allPriceModelsConfigured = [...addonAllowance, ...addonAllowanceDisplay].every((addOn) => {
      return (
        addOn.isConfigured ||
        addOn.unconfiguredPriceModelsList.length === 0 ||
        addOn.unconfiguredPriceModelsList.every((priceModel: AddonPriceModel | AllowancePriceModel) => {
          return isPriceModelsConfigured(priceModel);
        })
      );
    });

    dispatch(setPlanFormHasError(3, !allPriceModelsConfigured));
    dispatch(setPlanFormCompleted(3, allPriceModelsConfigured));
  }, [addOnPriceModel, selectedAddOns, selectedAddOnsDisplay, selectedAllowances, selectedAllowancesDisplay, cycles]);

  const renderSelectedItems = () => {
    if (companyAddOns.length > 0) {
      return (
        <>
          <div className="link-addons-allowances__select_add_ons">
            <Button
              className="link-addons-allowances__select_add_ons__btn"
              id="link-addons-allowances-select-plan-btn"
              title={isAddonScreen ? "CREATE_PLAN_ADD_ADD_ON" : "CREATE_PLAN_ADD_ALLOWANCE"}
              buttonType="general"
              icon="fal fa-plus-circle"
              isFullWidth={true}
              onClick={() => dispatch(setShowSelectAddOnsModal(true))}
            />
            {numberOfPages > 0 && (
              <Pagination
                className="link-addons-allowances__select_add_ons__pagination"
                pageCount={numberOfPages}
                isVisible={numberOfPages > 1}
                onPageChange={({ selected }) => dispatch(setSelectedAddOnsPage(selected + 1))}
              />
            )}
          </div>
          {/* 
          {isAddonScreen && selectedAddOnsDisplay.length > 0 && (
            <div className="link-addons-allowances__select_add_ons__container">
              {selectedAddOnsDisplay.map((addOn) => (
                <SelecteAddOnsListItem product={product} key={addOn.id} addOn={addOn} />
              ))}
            </div>
          )}
          {!isAddonScreen && selectedAllowancesDisplay.length > 0 && (
            <div className="link-addons-allowances__select_add_ons__container">
              {selectedAllowancesDisplay.map((addOn) => (
                <SelecteAddOnsListItem product={product} key={addOn.id} addOn={addOn} />
              ))}
            </div>
          )} */}

          <div
            style={{ display: isAddonScreen ? "block" : "none" }}
            className="link-addons-allowances__select_add_ons__container">
            {selectedAddOnsDisplay.map((addOn) => (
              <SelecteAddOnsListItem product={product} key={addOn.id} addOn={addOn} />
            ))}
          </div>
          <div
            style={{ display: !isAddonScreen ? "block" : "none" }}
            className="link-addons-allowances__select_add_ons__container">
            {selectedAllowancesDisplay.map((addOn) => (
              <SelecteAddOnsListItem product={product} isAddOn={false} key={addOn.id} addOn={addOn} />
            ))}
          </div>
        </>
      );
    }

    return (
      <div className={`link-addons-allowances__empty-screen ${isAddonScreen ? "link-addons-allowances__empty-screen--addons" : "link-addons-allowances__empty-screen--allowances"}`}>
        <Text content={isAddonScreen ? "CREATE_PLAN_FORM_LINK_TO_PLAN_ADD_ONS_TAB_CONTENT1" : "CREATE_PLAN_ALLOWANCES_HELP_TEXT_CONTENT1"} />
        <Text content={isAddonScreen ? "CREATE_PLAN_FORM_LINK_TO_PLAN_ADD_ONS_TAB_CONTENT2" : "CREATE_PLAN_ALLOWANCES_HELP_TEXT_CONTENT2"} noMargin />
      </div>
    );
  };


  const renderStepContent = () => {
    if (showSelectAddOnsModal) {
      return <SelectAddOns />;
    }

    const currency = new Currency();
    currency.setSymbol(currencySymbol);

    return (
      <>
        {addOnPriceModel && (
          <AddOnConfigurePrice
            priceModel={addOnPriceModel}
            setPriceModel={(data) => {
              dispatch(setLinkAddOnsAllowancesFields("addOnPriceModel", data));
            }}
            selectedPricingModel={selectedPricingModel as PricingModelType}
            selectedCurrency={currency}
            type={activeTab}
          />
        )}

        <StepCollapsablePanel
          title={"CREATE_PLAN_FORM_HEADER_LINK_TO_PLAN"}
          number={4}
          isCompleted={hasFocusedOnce && stageStatuses[3]}
          isCollapsed={!isCreatingPlan ? false : stageCollapsed[3]}
          hasError={stageErrors[3]}
          hasHelpSection={companyAddOns.length > 0 && !isLoadingData}
          className={`link-addons-allowances__step ${addOnPriceModel ? "hidden" : ""}`}
          onClick={() => {
            dispatch(setPlanFormCollapsed(3));
            setHasFocusedOnce(true);
          }}
          isLocked={!stageStatuses[2]}
          isShowTitleIcon={isCreatingPlan || (stageErrors[3] && !isCreatingPlan)}
          unMountChild={false}
          topSection={
            !isLoadingData ? (
              <TabbedContainer type="submenu">
                <NavLink
                  key="add-ons"
                  to=""
                  isActive={() => activeTab === AddonsAllowancesScreen.ADDONS}
                  onClick={(evt: any) => {
                    evt.preventDefault();
                    setActiveTab(AddonsAllowancesScreen.ADDONS);
                  }}
                  tabIndex={-1}
                >
                  {counterpart("CREATE_PLAN_FORM_LINK_TO_PLAN_ADD_ONS_TAB")}
                </NavLink>
                <NavLink
                  key="allowances"
                  to=""
                  isActive={() => activeTab === AddonsAllowancesScreen.ALLOWANCES}
                  onClick={(evt: any) => {
                    evt.preventDefault();
                    setActiveTab(AddonsAllowancesScreen.ALLOWANCES);
                  }}
                  tabIndex={-1}
                >
                  {counterpart("CREATE_PLAN_FORM_LINK_TO_PLAN_ALLOWANCES_TAB")}
                </NavLink>
              </TabbedContainer>
            ) : null
          }
          helpMenuPosition="top"
          helpMenuContent={
            <Text
              content={isAddonScreen ? "CREATE_PLAN_ADD_ONS_HELP_TEXT_CONTENT" : "CREATE_PLAN_ALLOWANCES_HELP_TEXT_CONTENT"}
              translateWith={{ icon: <i className="fas fa-map-marker-exclamation text-orange" /> }}
              noMargin
            />
          }
        >
          {/* {isLoadingData ? <ProgressIndicator color="blue" /> : <>{renderSelectedItems()}</>} */}
          <>
            {isLoadingData ? <ProgressIndicator color="blue" /> : null}
            <div className="link-addons-allowances__empty-screen-container" style={{ display: isLoadingData ? "none" : "block" }}>{renderSelectedItems()}</div>
          </>
        </StepCollapsablePanel>
      </>
    );
  };

  return <div className="link-addons-allowances">{renderStepContent()}</div>;
};

export default LinkAddOnsAllowances