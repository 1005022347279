import React from "react";
import Helmet from "react-helmet";
import counterpart from "counterpart";
import Panel from "../../../components/ui/panel/Panel";
import Text from "../../../components/ui/text/Text";
import CodePanel from "../../../components/ui/code-panel/CodePanel";
import { ConfigConstants } from "../../../utils/config";
import { useSelector } from "react-redux";
import { AppState } from "../../..";
import { IAuthReducerState } from "../../../reducers/authReducer";

interface IEmbedCodesCardTokenizer {

}

const EmbedCodesCardTokenizer: React.FC<IEmbedCodesCardTokenizer> = () => {
  const { currentCompanyDomain } = useSelector<AppState, IAuthReducerState>(state => state.auth)
  const script = `<script src="https://tokenlib${ConfigConstants.billsbyDomain}/tokenizer.min.js" data-billsby-company="${currentCompanyDomain}"></script>`;
  const form_line1 = '<form id="payment-form" action="https://www.billsby.com/" onsubmit=\"submitPaymentForm(); return false;\">';
  const form_line2 = '<input type="hidden" name="payment_method_token" id="payment_method_token">'
  const form_line3 = '<label for="full_name">Name</label>';
  const form_line4 = '<input type="text" id="full_name" name="full_name"><br />';
  const form_line5 = "<label>Credit Card Number</label>";
  const form_line6 = '<div id="billsby-number" style="width:225px; height:35px; border: 2px solid"></div><br />';
  const form_line7 = '<label for="month">Expiration Date</label>';
  const form_line8 = '<input type="text" id="month" name="month" maxlength="2">';
  const form_line9 = '<input type="text" id="year" name="year" maxlength="4"><br />';
  const form_line10 = "<label>CVV</label>";
  const form_line11 = '<div id="billsby-cvv" style="width:60px; height:35px; border: 2px solid "></div><br />';
  const form_line12 = '<input id="submit-button" type="submit" value="Pay Now" disabled>';
  const form_line13 = "</form>";
  const form = `
    ${form_line1}

    ${form_line2}

    ${form_line3}
    ${form_line4}

    ${form_line5}
    ${form_line6}

    ${form_line7}
    ${form_line8}
    ${form_line9}

    ${form_line10}
    ${form_line11}

    ${form_line12}

    ${form_line13}
  `;

  const initTokenizer1 = "<script>";
  const initTokenizer2 = 'billsbyTokens.init("billsby-number", "billsby-cvv");';
  const initTokenizer3 = "</script>";
  const initTokenizer = `
    ${initTokenizer1}
    ${initTokenizer2}
    ${initTokenizer3}
  `;

  const onReadyTokenizer1 = "billsbyTokens.on('ready', function () {";
  const onReadyTokenizer2 = "var submitButton = document.getElementById('submit-button');";
  const onReadyTokenizer3 = "submitButton.disabled = false;";
  const onReadyTokenizer4 = "});";
  const onReadyTokenizer = `
    ${onReadyTokenizer1}
    ${onReadyTokenizer2}
    ${onReadyTokenizer3}
    ${onReadyTokenizer4}
  `;

  const submitForm1 = "<script>";
  const submitForm2 = "function submitPaymentForm() {";
  const submitForm3 = "var requiredFields = {};";
  const submitForm4 = "// Get required, non-sensitive, values from host page";
  const submitForm5 = 'requiredFields["full_name"] = document.getElementById("full_name").value;';
  const submitForm6 = 'requiredFields["month"] = document.getElementById("month").value;';
  const submitForm7 = 'requiredFields["year"] = document.getElementById("year").value;';
  const submitForm8 = "billsbyTokens.tokenizeCreditCard(requiredFields);";
  const submitForm9 = "}";
  const submitForm10 = "</script>";
  const submitForm = `
    ${submitForm1}
    ${submitForm2}

    ${submitForm3}

    ${submitForm4}
    ${submitForm5}
    ${submitForm6}
    ${submitForm7}

    ${submitForm8}
    ${submitForm9}
    ${submitForm10}
  `;

  const receiveTokenizer1 = "<script>";
  const receiveTokenizer2 = 'billsbyTokens.on(\"paymentMethod\", function (token, pmData) {';
  const receiveTokenizer3 = "// Set the token in the hidden form field";
  const receiveTokenizer4 = 'var tokenField = document.getElementById("payment_method_token");';
  const receiveTokenizer5 = 'tokenField.setAttribute("value", token);';
  const receiveTokenizer6 = "// Submit the form";
  const receiveTokenizer7 = "var masterForm = document.getElementById('payment-form');";
  const receiveTokenizer8 = "masterForm.submit();";
  const receiveTokenizer9 = "});";
  const receiveTokenizer10 = "</script>";
  const receiveTokenizer = `
    ${receiveTokenizer1}
    ${receiveTokenizer2}

    ${receiveTokenizer3}
    ${receiveTokenizer4}
    ${receiveTokenizer5}

    ${receiveTokenizer6}
    ${receiveTokenizer7}
    ${receiveTokenizer8}
    ${receiveTokenizer9}
    ${receiveTokenizer10}
  `;

  const errorsTokenizer1 = "<script>";
  const errorsTokenizer2 = 'billsbyTokens.on(\"errors\", function (errors) {';
  const errorsTokenizer3 = "for (var i = 0; i < errors.length; i++) {";
  const errorsTokenizer4 = "var error = errors[i];";
  const errorsTokenizer5 = "console.log(error);";
  const errorsTokenizer6 = "};";
  const errorsTokenizer7 = "});";
  const errorsTokenizer8 = "</script>";
  const errorsTokenizer = `
    ${errorsTokenizer1}
    ${errorsTokenizer2}
    ${errorsTokenizer3}
    ${errorsTokenizer4}
    ${errorsTokenizer5}
    ${errorsTokenizer6}
    ${errorsTokenizer7}
    ${errorsTokenizer8}
  `;

  const resetHandler1 = "<script>";
  const resetHandler2 = "billsbyTokens.removeHandlers()";
  const resetHandler3 = "</script>";
  const resetHandler = `
    ${resetHandler1}
    ${resetHandler2}
    ${resetHandler3}
  `;

  return (
    <div className="embed-codes">
      <Helmet title={`${counterpart("NAVBAR_EMBED_CODES")}-${counterpart("EMBED_CODES_CARD_TOKENIZER")}`} />
      <Panel className="embed-codes__welcome-card-tokenizer" title="EMBED_CODES_CARD_TOKENIZER_PANEL1_TITLE">
        <Text content="EMBED_CODES_CARD_TOKENIZER_PANEL1_CONTENT" noMargin />
      </Panel>
      <Panel title="EMBED_CODES_CARD_TOKENIZER_PANEL2_TITLE">
        <Text content="EMBED_CODES_CARD_TOKENIZER_PANEL2_CONTENT1" />
        <Text content="EMBED_CODES_CARD_TOKENIZER_PANEL2_CONTENT2" />
        <CodePanel id="tokenizer-script" codeStr={script}>
          <span className="embed-codes__basic__code-main">
            {script}
          </span>
        </CodePanel>
        <Text content="EMBED_CODES_CARD_TOKENIZER_PANEL2_CONTENT3" />
        <CodePanel id="form-tokenizer" codeStr={form} makeBeauty>
          <span className="embed-codes__basic__code-main" >
            <div>{form_line1}</div><br/>
            <div>{form_line2}</div><br/>
            <div>{form_line3}</div>
            <div>{form_line4}</div><br/>
            <div>{form_line5}</div>
            <div>{form_line6}</div><br/>
            <div>{form_line7}</div>
            <div>{form_line8}</div>
            <div>{form_line9}</div><br/>
            <div>{form_line10}</div>
            <div>{form_line11}</div><br/>
            <div>{form_line12}</div><br/>
            <div>{form_line13}</div>
          </span>
        </CodePanel>
        <Text content="EMBED_CODES_CARD_TOKENIZER_PANEL2_CONTENT4" />
        <CodePanel id="init-tokenizer" codeStr={initTokenizer} makeBeauty>
          <span className="embed-codes__basic__code-main">
            <div>{initTokenizer1}</div>
            <div>{initTokenizer2}</div>
            <div>{initTokenizer3}</div>
          </span>
        </CodePanel>
        <Text content="EMBED_CODES_CARD_TOKENIZER_PANEL2_CONTENT5" />
        <CodePanel id="onready-tokenizer" codeStr={onReadyTokenizer} makeBeauty>
          <span className="embed-codes__basic__code-main">
            <div>{onReadyTokenizer1}</div>
            <div>{onReadyTokenizer2}</div>
            <div>{onReadyTokenizer3}</div>
            <div>{onReadyTokenizer4}</div>
          </span>
        </CodePanel>
        <Text content="EMBED_CODES_CARD_TOKENIZER_PANEL2_CONTENT6" noMargin />
      </Panel>

      <Panel title="EMBED_CODES_CARD_TOKENIZER_PANEL3_TITLE">
        <Text content="EMBED_CODES_CARD_TOKENIZER_PANEL3_CONTENT1" />
        <CodePanel id="submit-form-tokenizer" codeStr={submitForm} makeBeauty>
          <span className="embed-codes__basic__code-main">
            <div>{submitForm1}</div>
            <div>{submitForm2}</div><br/>
            <div>{submitForm3}</div><br/>
            <div>{submitForm4}</div>
            <div>{submitForm5}</div>
            <div>{submitForm6}</div>
            <div>{submitForm7}</div><br/>
            <div>{submitForm8}</div>
            <div>{submitForm9}</div>
            <div>{submitForm10}</div>
          </span>
        </CodePanel>
        <Text content="EMBED_CODES_CARD_TOKENIZER_PANEL3_CONTENT2" noMargin />
      </Panel>
      <Panel title="EMBED_CODES_CARD_TOKENIZER_PANEL4_TITLE">
        <Text content="EMBED_CODES_CARD_TOKENIZER_PANEL4_CONTENT1" />
        <Text content="EMBED_CODES_CARD_TOKENIZER_PANEL4_CONTENT2" />
        <CodePanel id="receive-tokenizer" codeStr={receiveTokenizer} makeBeauty>
          <span className="embed-codes__basic__code-main">
            <div>{receiveTokenizer1}</div>
            <div>{receiveTokenizer2}</div><br/>
            <div>{receiveTokenizer3}</div>
            <div>{receiveTokenizer4}</div>
            <div>{receiveTokenizer5}</div><br/>
            <div>{receiveTokenizer6}</div>
            <div>{receiveTokenizer7}</div>
            <div>{receiveTokenizer8}</div>
            <div>{receiveTokenizer9}</div>
            <div>{receiveTokenizer10}</div>
          </span>
        </CodePanel>
        <Text content="EMBED_CODES_CARD_TOKENIZER_PANEL4_CONTENT3" />
        <Text content="EMBED_CODES_CARD_TOKENIZER_PANEL4_CONTENT4" noMargin />
      </Panel>

      <Panel title="EMBED_CODES_CARD_TOKENIZER_PANEL5_TITLE">
        <Text content="EMBED_CODES_CARD_TOKENIZER_PANEL5_CONTENT1" />
        <Text content="EMBED_CODES_CARD_TOKENIZER_PANEL5_CONTENT2" />
        <CodePanel id="errors-tokenizer" codeStr={errorsTokenizer} makeBeauty noMargin>
          <span className="embed-codes__basic__code-main">
            <div>{errorsTokenizer1}</div>
            <div>{errorsTokenizer2}</div>
            <div>{errorsTokenizer3}</div>
            <div>{errorsTokenizer4}</div>
            <div>{errorsTokenizer5}</div>
            <div>{errorsTokenizer6}</div>
            <div>{errorsTokenizer7}</div>
            <div>{errorsTokenizer8}</div>
          </span>
        </CodePanel>
      </Panel>

      <Panel title="EMBED_CODES_CARD_TOKENIZER_PANEL6_TITLE">
        <Text content="EMBED_CODES_CARD_TOKENIZER_PANEL6_CONTENT1" />
        <CodePanel id="errors-tokenizer" codeStr={resetHandler} makeBeauty noMargin>
          <span className="embed-codes__basic__code-main">
            <div>{resetHandler1}</div>
            <div>{resetHandler2}</div>
            <div>{resetHandler3}</div>
          </span>
        </CodePanel>
      </Panel>
    </div>
  )
}

export default EmbedCodesCardTokenizer;

