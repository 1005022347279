import React, { useMemo } from "react";
import "./NotePanel.scss";
import Panel from "../../../ui/panel/Panel";
import Text from "../../../ui/text/Text";
import moment from "moment";
import counterpart from "counterpart";
import { Col, Row } from "react-grid-system";
import { ICustomerNote } from "../../../../models/Customer";
import ButtonLoader from "../../../ui/button/ButtonLoader";
import Tooltip from "../../../ui/tooltip/Tooltip";

interface INotePanelProps {
  id: number
  subject: string,
  content: string,
  dateCreated?: Date,
  className?: string,
  disabled: boolean,
  isDeletingNote: boolean,
  tooltipMessage?: string,
  onUpdate: (customerNote: ICustomerNote) => void
  onDelete: (customerNoteId: number) => void
}

const emptyFunction = () => { return; }

const NotePanel: React.FC<INotePanelProps> = React.memo(({ id, subject, isDeletingNote, dateCreated, disabled, content = "", tooltipMessage, className = "", onUpdate = emptyFunction, onDelete = emptyFunction }) => {

  const createdOnDate = moment(dateCreated).format("DD MMM YYYY");
  const createdOnTime = moment(dateCreated).format("HH:mm Z");

  const getFooter = useMemo(() => {
    return (
      <>
        {counterpart("CUSTOMER_NOTES_LAST_UPDATED_ON")} <span className="text-hightlight">{createdOnDate}</span> {counterpart("CUSTOMER_NOTES_LAST_UPDATED_AT")}{" "}
        <span className="text-hightlight">{createdOnTime}</span>
      </>
    );
  }, [dateCreated]);

  return (
    <div className="note_panel">
      <Panel className={`note_panel ${className}`}>
        <div>
          <div className="note_panel__title">
            <div className="note_panel__title__text">
              <Text content={subject} shouldTranslate={false} />
            </div>
          </div>
          <div className="note_panel__content">
            <Text content={content} shouldTranslate={false} />
          </div>
          <div className="note_panel__footer">
            <Row>
              <Col md={8}>
                <div className="note_panel__footer">{getFooter}</div>
              </Col>
              <Col md={4}>
                <div data-tip data-for={createdOnTime} className="note_panel__actions">
                  <div className={disabled || isDeletingNote ? "disabled" : ""}><span className="fas fa-edit" onClick={() => !disabled && onUpdate({ id, subject, content })}></span></div>
                  <div className={`${disabled || isDeletingNote ? "disabled" : ""} ${isDeletingNote ? "loading" : ""}`}>
                    {
                      isDeletingNote ? <ButtonLoader color="blue" /> : <span className="fas fa-trash-alt" onClick={() => !disabled && onDelete(id)}></span>
                    }
                  </div>
                </div>
                {tooltipMessage && <Tooltip id={createdOnTime} place='bottom' type='blue'>
                  <Text content={tooltipMessage} noMargin />
                </Tooltip>}
              </Col>
            </Row>
          </div>
        </div>
      </Panel>
    </div>
  )
})

export default NotePanel;