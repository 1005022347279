import React, { useState, useLayoutEffect } from "react";
import { Row, Col } from "react-grid-system";
import Panel from "../../ui/panel/Panel";
import Text from "../../ui/text/Text";
import FormGroup from "../../ui/form-group/FormGroup";
import FormLabel from "../../ui/form-label/FormLabel";
import Button from "../../ui/button/Button";
import TaxRatePanel from "./TaxRatePanel";
import OverrideTaxRatePanel from "./OverrideTaxRatePanel";
import "./ConfigureTaxRates.scss";
import { TaxRate } from "../../../utils/grpc/generated/Billsby.Protos/billing/private/company/taxation/taxation_pb";
import Dropdown from "../../ui/dropdown/Dropdown";
import counterpart from "counterpart";
import { countryStates } from "../../../utils/states/states";
import { ComplexTaxCountry, IOverrideStateRate, IndiaGeneralRate } from "../../../models/Taxes";
import { ICountryState } from "../../../models/CountryState";

interface IConfigureTaxRates {
  generalTaxRates: Array<TaxRate>;
  selectedStates: Array<IOverrideStateRate>;
  isConfigured: boolean;
  onAdd: (countryState: ICountryState) => void;
  onDelete: (stateCode: string) => void;
  onChangeGeneralTaxRates: (value: number, taxRate: TaxRate) => void;
  onChangeOverrideTaxRates: (value: number, taxRate: TaxRate) => void;
  onChangeOverrideRegNumber: (value: string, taxRate: TaxRate) => void;
  onChangeOverride: (value: boolean, stateToOverride: IOverrideStateRate) => void;
  onDeleteConfig: () => void;
}

const ConfigureTaxRates: React.FC<IConfigureTaxRates> = React.memo(
  ({ isConfigured, generalTaxRates, onChangeGeneralTaxRates, onChangeOverrideTaxRates, onChangeOverride, selectedStates, onChangeOverrideRegNumber, onDelete, onAdd, onDeleteConfig }) => {
    const [states, setStates] = useState<Array<{ value: ICountryState; label: string }>>([]);

    useLayoutEffect(() => {
      setStates(
        countryStates
          .filter((cs) => {
            //remove selected rates from dropdown
            return cs.CountryIso3 === ComplexTaxCountry.India && !selectedStates.find((ss) => ss.stateCode === cs.StateIso2);
          })
          .map((state) => {
            return { value: state, label: state.StateName };
          })
      );
    }, [selectedStates]);

    return (
      <Panel className="configure-tax-rates" title="CONFIG_TAX_RATES_TITLE">
        {isConfigured && <Button className="add-tax-rates__floating-button" id="delete-config" title="ADD_TAX_DELETE" icon="far fa-trash-alt" buttonType="error" onClick={onDeleteConfig} />}

        <Text className={isConfigured ? "configure-tax-rates__content" : ""} content="CONFIG_TAX_RATES_CONTENT" />
        <FormGroup>
          {generalTaxRates.map((tax) => {
            return (
              <TaxRatePanel
                key={`${tax.getCountryIso3() || tax.getName()}`}
                taxRate={tax}
                required={tax.getName() === IndiaGeneralRate.CGST || tax.getName() === IndiaGeneralRate.SGST_UTGST}
                fixedNameWidth
                showAsText={isConfigured}
                onChangeTaxRate={(value) => onChangeGeneralTaxRates(value, tax)}
                showDeleteButton={false}
              />
            );
          })}
        </FormGroup>

        <FormGroup noMargin={selectedStates.length === 0}>
          <Row align="center">
            <Col md={2}>
              <FormLabel target="override-states" content="CONFIG_TAX_RATES_OVERRIDE_STATES" noMargin />
            </Col>
            <Col md={10}>
              <Dropdown id="override-states" options={states} onChange={(selected: any) => onAdd(selected.value)} placeholder={counterpart("CONFIG_TAX_RATES_OVERRIDE_PLACEHOLDER")} />
            </Col>
          </Row>
        </FormGroup>
        {selectedStates.map((ot) => {
          return (
            <OverrideTaxRatePanel
              key={ot.stateCode}
              stateToOverride={ot}
              showAsText={isConfigured}
              onDelete={() => onDelete(ot.stateCode)}
              onChangeOverrideTaxRate={onChangeOverrideTaxRates}
              onChangeOverrideRegNumber={onChangeOverrideRegNumber}
              onChangeOverride={onChangeOverride}
            />
          );
        })}
      </Panel>
    );
  }
);

export default ConfigureTaxRates;
