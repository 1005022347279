import React, { useState, useLayoutEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";
import NavigationBar from "../../components/ui/navigation-bar/NavigationBar";
import PageWrapper from "../../components/ui/layout/PageWrapper";
import Text from "../../components/ui/text/Text";
import Button from "../../components/ui/button/Button";
import { AppState } from "../..";
import "./CreditNoteDetails.scss";
import { resetCreditNoteDetails, putCreditNotePayment } from "../../actions/creditNoteDetailsActions";
import { downloadPdf } from "../../utils/commonUtils";
import Modal from "../../components/modal/Modal";
import NoticePanel from "../../components/ui/notice-panel/NoticePanel";
import SendCreditNote from "../../components/send-credit-note/SendCreditNote";
import counterpart from "counterpart";
import LoadingModal from "../../components/modal/loading-modal/LoadingModal";
import ProgressIndicator from "../../components/ui/progress-indicator/ProgressIndicator";
import InvoicePrint from "../../components/invoice-print/InvoicePrint";
import { fetchInvoiceDetails } from "../../actions/invoiceDetailsActions";
import { InvoiceStatus } from "../../models/Invoices";
import { CustomerDataStatus } from "../../models/Customer";
import history from "../../utils/history";
import { Permission } from "../../models/Auth";
import useCheckFeaturePermission from "../../utils/hooks/useCheckFeaturePermission";

interface IMatchParams {
  customerUniqueId: string;
  creditNoteNumber: string;
}

const CreditNoteDetails: React.FC = () => {
  const { currentCompanyDomain, invoiceDetails } = useSelector((state: AppState) => ({ currentCompanyDomain: state.auth.currentCompanyDomain, invoiceDetails: state.invoiceDetailsReducer.invoiceDetails  }))
  const dispatch = useDispatch();
  const params = useParams<IMatchParams>();

  const [showSuccessNotice, setShowSuccessPanel] = useState(false);
  const [showSendCreditNoteModal, setShowSendCreditNoteModal] = useState(false);
  const [isLoadingData, setIsLoadingData] = useState(false);
  const [isCreatingPdf, setIsCreatingPdf] = useState(false);
  const isCreditNoteReattemptPermitted = useCheckFeaturePermission(Permission.CreditNoteReattempt);
  const isCreditNoteSendPermitted = useCheckFeaturePermission(Permission.CreditNoteSend);
  const isCreditNoteDownloadPermitted = useCheckFeaturePermission(Permission.CreditNoteDownloadPdf);


  const { customerFirstName, customerLastName, status, invoiceNumber, customerStatus } = invoiceDetails;

  const customerName = `${customerFirstName} ${customerLastName}`;
  
  const isDeletedCustomer = useMemo(() => customerStatus === CustomerDataStatus.GDPR_DELETED || customerStatus === CustomerDataStatus.DELETED, [customerStatus]);
  
  const reattemptPayment = async () => {
    await dispatch(putCreditNotePayment(currentCompanyDomain, params.creditNoteNumber));
    fetchData();
  };

  const download = () => {
    var element = document.querySelector(".receipt-details") as HTMLElement;
    setIsCreatingPdf(true);
    downloadPdf(element, invoiceNumber)
      .then(() => setIsCreatingPdf(false));
  };

  const fetchData = async () => {
    setIsLoadingData(true);
    try {
      await dispatch(fetchInvoiceDetails(params.creditNoteNumber));
    } catch (err) {
      console.log(err);
    } finally {
      setIsLoadingData(false);
      return function cleanup() {
        dispatch(resetCreditNoteDetails());
      };
    }
  };

  useLayoutEffect(() => {
    fetchData();
  }, []);

  if (isLoadingData) {
    return <ProgressIndicator color="blue" coverage="full-content"></ProgressIndicator>
  }

  return (
    <div className="credit-note-details">
      <NavigationBar
        previousPageTitle="CREDIT_NOTE_DETAILS_BACK"
        shouldTranslatePageTitle={false}
        previousPageCb={() => {
          history.push("/credit-notes");
        }}
        pageTitle={
          <span>
            {counterpart("CREDIT_NOTE_DETAILS_FOR")}
            {
              isDeletedCustomer ? <Text content={customerName} className="credit-note-details__customer-link deleted" component="span" shouldTranslate={false} noMargin /> : 
              <Link to={`/customers/${params.customerUniqueId}`}>
                <Text content={customerName} className="credit-note-details__customer-link" component="span" shouldTranslate={false} noMargin />
              </Link>
            }
          </span>
        }
      />

      <div className="credit-note-details__action-bar">
        <div className="credit-note-details__action-bar__wrapper">
          <div>
            {status === InvoiceStatus.FAILED && (
              <Button
              disabled={!isCreditNoteReattemptPermitted}
              tooltipMessage={!isCreditNoteReattemptPermitted ? "NO_ACCESS_MSG" : undefined} 
              id="reaatempt-credit-note-payment" icon="fas fa-sync" title="CREDIT_NOTE_DETAILS_REATTEMPT" buttonType="error" onClick={() => reattemptPayment()} />
            )}
          </div>
          <div>
            <Button
              id="send-credit-note"
              icon="fab fa-telegram-plane"
              className="credit-note-details__send-button"
              disabled={isDeletedCustomer || !isCreditNoteSendPermitted}
              tooltipMessage={!isCreditNoteSendPermitted ? "NO_ACCESS_MSG" : undefined} 
              onClick={() => setShowSendCreditNoteModal(!showSendCreditNoteModal)}
              title="CREDIT_NOTE_DETAILS_SEND_NOTE"
              buttonType="add"
            />
            <Button 
              id="download-pdf" 
              icon="fas fa-file-pdf" 
              title="CREDIT_NOTE_DETAILS_DOWNLOAD_PDF"
              buttonType="add" 
              onClick={() => download()}
              disabled={!isCreditNoteDownloadPermitted}
              tooltipMessage={!isCreditNoteDownloadPermitted ? "NO_ACCESS_MSG" : undefined} 
            />
          </div>
        </div>
      </div>
      {showSuccessNotice && <NoticePanel type="success" icon="fas fa-check-circle" className="credit-note-details__notice-panel" content="SEND_CREDIT_NOTE_SUCCESS" />}

      <PageWrapper>
        <InvoicePrint data={invoiceDetails}></InvoicePrint>
      </PageWrapper>
      <Modal
        title="SEND_CREDIT_NOTE_TITLE"
        isOpen={showSendCreditNoteModal}
        onRequestClose={() => setShowSendCreditNoteModal(false)}
        children={
          <SendCreditNote
            customerUniqueId={params.customerUniqueId}
            handleSendCreditNoteCallback={() => {
              setShowSuccessPanel(true);
              setShowSendCreditNoteModal(false);
            }}
          />
        }
      />
      <LoadingModal isOpen={isCreatingPdf} title={counterpart("DOWNLOADING_CREDIT_NOTE")} subTitle={""} shouldTranslateTitle={false} shouldTranslateSubTitle={false} />
    </div>
  );
};

export default CreditNoteDetails;
