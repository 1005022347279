import React, { useState } from "react";
import QuizStep1 from "./QuizStep1";
import QuizStep2 from "./QuizStep2";
import QuizStep3 from "./QuizStep3";
import QuizStep4 from "./QuizStep4";
import QuizStep5 from "./QuizStep5";
import QuizStep6 from "./QuizStep6";
import QuizStep7 from "./QuizStep7";
import QuizStep8 from "./QuizStep8";
import QuizStep9 from "./QuizStep9";
import Button from "../ui/button/Button";
import API from "../../utils/API";
import counterpart from "counterpart";
import TitleSection from "../ui/title-section/TitleSection";
import { Link } from "react-router-dom";

interface IQuiz {
  companyName: string,
  email: string,
  password: string,
  currencySymbol: string,
  onFinishCb: () => void
}

const Quiz: React.FC<IQuiz> = ({ companyName, email, password, currencySymbol, onFinishCb }) => {
  const [isFinished, setIsFinished] = useState(false);
  const [isCompleted, setIsCompleted] = useState(false);
  const [subscriberBase, setSubscriberBase] = useState("");
  const [currentStep, setCurrentStep] = useState(1);
  let QuizStep: any = <div />;

  const onContinue = async (nextStep: number, fieldType: string, fieldValue: string) => {
    try {
      if (fieldValue === "Ignore") {
        return setCurrentStep(nextStep);
      }
      if (nextStep === 7) {
        setSubscriberBase(fieldValue);
      }
      if (nextStep === 8) {
        fieldValue = `${Math.ceil(+fieldValue)}/${+subscriberBase}`;
      }

      await API.sendResponseToQuiz(companyName, fieldType, fieldValue);
      setCurrentStep(nextStep);

      if (nextStep === 9) {
        setIsFinished(true);
        setIsCompleted(true);
      }
    }
    catch (err) {

    }
  }

  switch (currentStep) {
    case 1:
      QuizStep = (
        <QuizStep1 onContinue={onContinue}
        />
      )
      break;
    case 2:
      QuizStep = (
        <QuizStep2
          onContinue={onContinue}
        />
      )
      break;
    case 3:
      QuizStep = (
        <QuizStep3
          onContinue={onContinue}
        />
      )
      break;
    case 4:
      QuizStep = (
        <QuizStep4
          onContinue={onContinue}
        />
      )
      break;
    case 5:
      QuizStep = (
        <QuizStep5
          onContinue={onContinue}
        />
      )
      break;
    case 6:
      QuizStep = (
        <QuizStep6
          onContinue={onContinue}
        />
      )
      break;
    case 7:
      QuizStep = (
        <QuizStep7
          currencySymbol={currencySymbol}
          onContinue={onContinue}
        />
      )
      break;
    case 8:
      QuizStep = (
        <QuizStep8
          onContinue={onContinue}
        />
      )
      break;
    case 9:
      QuizStep = <QuizStep9 />
      break;
    default:
      QuizStep = <div>not found</div>
  }


  return (
    <>
      <div className="quiz">
        <TitleSection title="QUIZ_TITLE" translateTitleWith={{  companyName }} subTitle="QUIZ_SUBTITLE" translateSubTitleWith={{
          boldText: <span className="bold">{counterpart("QUIZ_SIXTY_SECOND")}</span>,
          link: <Link style={{ fontSize: "17px" }} to="/configuration/taxes" onClick={(evt: any) => {
            evt.preventDefault();
            onFinishCb()
          }}>{counterpart("QUIZ_SKIP_AHEAD")}</Link>
        }}></TitleSection>

        {QuizStep}
      </div>


      {/* HIDE FOR NOW */}
      {/* {
        !isFinished ?
          <SpinnerBar text="SETUP_ACCOUNT_LOADER" onEnd={() => setIsFinished(true)} />
        :
          isCompleted ?
            <Button id="setup-account" title="SETUP_ACCOUNT_CONTINUE" className="setup-account__continueBtn" isFullWidth onClick={onFinishCb} />
          :
            <div>
              <Button id="setup-account" title="SETUP_ACCOUNT_CONTINUE_UNCOMPLETE" className="setup-account__continueBtn-uncompleted" isFullWidth disabled={true} />
              <div className="setup-account__continueBtn-skip">
                <a id="setup-account-skip" onClick={onFinishCb}><Text content="SETUP_ACCOUNT_CONTINUE_UNCOMPLETE_SKIP" /></a>
              </div>
            </div>
      } */}


      <Button id="setup-account" buttonType="orangeBillsbyMarketing" style={{ marginBottom: "20px" }} title="SETUP_ACCOUNT_CONTINUE_CONTROL_PANEL" isFullWidth onClick={onFinishCb} />
    </>
  )
}

export default Quiz;