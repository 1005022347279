import counterpart from "counterpart";
import React from "react";
import Translate from "react-translate-component";
import "./Text.scss";

interface IText {
  /**
  * The text to be rendered
  */
  content: string | JSX.Element,
  /**
  * The type of div element
  */
  component?: string,
  /**
  * Additional classes use to add custom styles
  */
  className?: string,
  /**
   * additional inline style
   */
  style?: object
  /**
   * if the value has to be translated
   */
  shouldTranslate?: boolean,
  /**
   * translate with props
   */
  translateWith?: object,
  /*
   * Remove the bottom margin
   */
  noMargin?: boolean,
  /*
   * Click handler
   */
  onClick?: (evt: any) => any,
    /**
   * weight of text
   */
  weight?: "bold" | "normal",
  /**
   * size of text
   */
  size?: "xlarge" | "large" | "normal" | "small" | "xsmall"
  /**
   * Show tooltip
   */
  title?: string,
   /**
   * dynamic translation of single text
   */
  locale?: string
}

const Text: React.FC<IText> = React.memo(({ content, component = "p", title, className = "", weight = "normal", size = "normal", shouldTranslate = true, 
translateWith = {}, noMargin = false, onClick = null, style = {}, locale = counterpart.getLocale() }) => {

  const margin = noMargin ? "mb0" : "";

  if (shouldTranslate) {
    return (
      <Translate
        content={content}
        component={component}
        with={translateWith}
        className={`cc-text ${margin} ${className} cc-text--weight-${weight} cc-text--${size}`}
        onClick={onClick}
        style={style}
        locale={locale}
      />
    );
  }

  //first letter MUST BE capitalized in order to bind dinamically the component
  let Component: any = component;
  return (
    <Component title={title} className={`cc-text ${margin} ${className} cc-text--weight-${weight} cc-text--${size}`} style={style} onClick={onClick}>
      {content}
    </Component>
  );
})

export default Text;
