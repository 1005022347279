import React, { useState, useRef } from "react";
import Panel from "../ui/panel/Panel";
import Text from "../ui/text/Text";
import Button from "../ui/button/Button";
import RangeSlider from "../ui/range-slider/RangeSlider";
import { QUIZ_FIELD_ARPU_TYPE } from "../../utils/constants";

interface IQuizStep7 {
  onContinue: (nextStep: number, fieldType: string, fieldValue: string) => any,
  currencySymbol: string,
}


const QuizStep7: React.FC<IQuizStep7> = ({ onContinue, currencySymbol }) => {
  const [value, setValue] = useState(20);
  const slider = useRef(null);
  return (
    <div className="quiz quiz-step7">
      <Panel title="QUIZ_STEP7_TITLE">
        <Text content="QUIZ_STEP7_SUBTITLE" />
        <RangeSlider
          ref={slider}
          className="quiz-step7__slider"
          min={0}
          max={1000}
          defaultValue={value}
          value={value}
          marks={{ 20: `${currencySymbol}20`, 100: `${currencySymbol}100`, 250: `${currencySymbol}250`, 500: `${currencySymbol}500` }}
          step={1}
          onChange={(value: any) => { setValue(value)}}
          tipFormatter={(value: number) => value !== 1000 ? `${currencySymbol}${value}` : `${currencySymbol}${value}+`}
        />
        <Button id="quiz-step7-btn" title="QUIZ_STEP7_BTN" isFullWidth onClick={() => onContinue(8, QUIZ_FIELD_ARPU_TYPE, (value + "").replace(/$/g, ""))} />
        <Text content="QUIZ_STEP7_LINK" className="quiz__link" noMargin onClick={() => onContinue(8, QUIZ_FIELD_ARPU_TYPE, "Ignore")} />
      </Panel>
    </div>
  )
}

export default QuizStep7;