import React from "react";
import Text from "../text/Text";
import "./Switch.scss";

interface ISwitch {
  checked: boolean,
  onChange: (isChecked: boolean) => void,
  id: string,
  onIcon?: string,
  offIcon?: string,
  text?: string,
  translateWith?: any,
  className?: string
}

const Switch: React.FC<ISwitch> = React.memo(({ className, checked, onChange, id, onIcon = "", offIcon = "", text = "", translateWith = {} }) => (
  <span className="switch" id={id}>
    <label className={`switch__container ${className}`}>
      <input type="checkbox" checked={checked} onChange={(evt: any) => onChange(evt.target.checked)} />
      <span className="switch__slider switch__slider--round">
        <span className={checked ? "switch__container__text switch__container__text--on" : "switch__container__text switch__container__text--off"}>
          {(checked && onIcon) && <i className={onIcon}></i>}
          {/*(checked && !onIcon) && counterpart("ON")*/}
          {(!checked && offIcon) && <i className={offIcon}></i>}
          {/*(!checked && !offIcon) && counterpart("OFF")*/}
        </span>
      </span>
    </label>
    {text && <Text translateWith={translateWith} noMargin className="switch__text" component="span" content={text} />}
  </span>
));

export default Switch;
