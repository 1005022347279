import React, { useLayoutEffect, FormEvent, useState } from "react";
import "./RegistrationFromInvitation.scss";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../../..";
import { IRegistrationReducerState } from "../../../reducers/registrationReducer";
import Panel from "../../../components/ui/panel/Panel";
import Text from "../../../components/ui/text/Text";
import Password from "../../../components/ui/password/Password";
import { addPasswordConfirm, addPassword, checkPasswordValidity, addPhoneNumber, addPhoneNumberPrefix, addPhoneNumberCountryIso2, registerUserFromInvitation, fetchDetailsInvitedUser } from "../../../actions/registrationActions";
import PhoneInput from "../../../components/ui/phone-input/PhoneInput";
import { PREFERRED_PHONE_COUNTRIES } from "../../../utils/constants";
import { Row, Col } from "react-grid-system";
import FormGroup from "../../../components/ui/form-group/FormGroup";
import Button from "../../../components/ui/button/Button";
import counterpart from "counterpart";
import FormLabel from "../../../components/ui/form-label/FormLabel";
import queryString from "query-string";
import history from "../../../utils/history";
import { RegisterInvitedUser } from "../../../models/Invitations";
import { loginUser } from "../../../actions/loginActions";
import TitleSection from "../../../components/ui/title-section/TitleSection";
import RegistrationCheckboxes from "../RegistrationCheckboxes";
const { isValidNumber } = (global as any).intlTelInputUtils;



const RegistrationFromInvitation: React.FC = React.memo(() => {
  const { password, passwordConfirmation, phoneNumber, phoneNumberPrefix, notificationAlert, invitedUserDetails, invitedUserDetailsError, 
    passwordIsValid, phoneNumberCountryIso2,isRegisteringUser, acceptAgreement } = useSelector<AppState, IRegistrationReducerState>(state => state.registration);
  const dispatch = useDispatch<Function>();
  const [showAgreementError, setShowAgreementError] = useState(false);


  useLayoutEffect(() => {
    const queryParams = queryString.parse(window.location.search);
    const { invitationCode, companyDomain, email } = queryParams as any;
    if (!invitationCode || !companyDomain || !email) {
      history.push({ pathname: "/registration", search: window.location.search });
    }
    else {
      dispatch(fetchDetailsInvitedUser({ invitationCode, companyDomain, email }))
    }
  }, []);

  if (invitedUserDetailsError) {
    return (
      <Panel title="INVITATIONS_EXPIRED_KEY_TITLE" className="registration-from-invitation__error">
        <Text content="INVITATIONS_EXPIRED_KEY_CONTENT" noMargin />
      </Panel>
    )
  }


  if (!invitedUserDetails) {
    return null;
  }

  const onSubmit = async (evt: FormEvent) => {
    evt.preventDefault();

    if (!isValidNumber(`+${phoneNumberPrefix}${phoneNumber}`) || !passwordIsValid) {
      return;
    }
    if(!acceptAgreement) {
      setShowAgreementError(true);
      return;
    }
    const user: RegisterInvitedUser = {
      companyId: invitedUserDetails.companyId,
      invitationCode: invitedUserDetails.invitationCode,
      isOptedInForMarketing: notificationAlert,
      password,
      phoneNumberDialCountry: phoneNumberCountryIso2,
      phoneNumberDialCode: phoneNumberPrefix,
      phoneNumber: phoneNumber,
      userId: invitedUserDetails.userId
    }
    try {
      await dispatch(registerUserFromInvitation(user));
      await dispatch(loginUser(invitedUserDetails.email, password));

    }
    catch (err) { }
  }


  return (
    <form className="registration-from-invitation" onSubmit={onSubmit}>

      <TitleSection
        title={counterpart("INVITATION_REGISTRATION_WELCOME", { firstName: invitedUserDetails.firstName })}
        subTitle={counterpart("INVITATION_REGISTRATION_WELCOME_SUBTITLE", { companyName: invitedUserDetails.companyName })}
        shouldTranslateTitle={false}
        shouldTranslateSubtitle={false}
      />

      {/*<Panel className="registration-from-invitation__heading" title="INVITATION_REGISTRATION_WELCOME" translateWith={{ firstName: invitedUserDetails.firstName }}>
        <Text content="INVITATION_REGISTRATION_WELCOME_SUBTITLE" translateWith={{ companyName: invitedUserDetails.companyName }} noMargin />
      </Panel>*/}

      <Panel>

        <Password
          password={password}
          passwordConfirmation={passwordConfirmation}
          onChangeConfirmPassword={(pswConfirmation: string) => dispatch(addPasswordConfirm(pswConfirmation))}
          onChangePassword={(psw: string) => dispatch(addPassword(psw))}
          passwordIsValidCallback={(passwordIsValid: boolean) => dispatch(checkPasswordValidity(passwordIsValid))}
        />

        <FormGroup>
          <Row align="center">
            <Col md={2}>
              <FormLabel target="phonenumber" content="REGISTRATION_FORM_PHONENUMBER" noMargin />
            </Col>
            <Col md={10}>
              <PhoneInput
                preferredCountries={PREFERRED_PHONE_COUNTRIES.countries}
                defaultCountry={PREFERRED_PHONE_COUNTRIES.defaultCountry}
                value={phoneNumber}
                isRequired
                dialCode={phoneNumberPrefix}
                onPhoneNumberChange={(isValid: boolean, phone: string, countryData: any) => {
                  dispatch(addPhoneNumber(phone));
                  dispatch(addPhoneNumberPrefix(countryData.dialCode));
                  dispatch(addPhoneNumberCountryIso2(countryData.iso2));
                }}
                onSelectFlag={(phone: string, countryData: any) => {
                  dispatch(addPhoneNumberPrefix(countryData.dialCode));
                  dispatch(addPhoneNumberCountryIso2(countryData.iso2));
                }}
                errorLabel="REGISTRATION_PHONE_VALIDATION_ERROR"
                separateDialCode
              />
            </Col>
          </Row>
        </FormGroup>

        <RegistrationCheckboxes showAgreementError={showAgreementError} acceptAgreementOnClick={() => setShowAgreementError(false)}/>

      </Panel>

      <Button
        id="register_btn"
        title="REGISTRATION_FORM_BUTTON"
        type="submit"
        buttonType="orangeBillsbyMarketing"
        isLoading={isRegisteringUser}
        disabled={isRegisteringUser}
        isFullWidth
      />

      <FormGroup style={{ "textAlign": "center" }}>
        <a href="https://www.billsby.com/terms" target="_self">{counterpart("REGISTRATION_FORM_TERMS_LINK1")}</a>
        <Text content="REGISTRATION_FORM_TERMS_AND" component="span" />
        <a href="https://www.billsby.com/privacy" target="_self">{counterpart("REGISTRATION_FORM_TERMS_LINK2")}</a>
      </FormGroup>

    </form>
  )
})

export default RegistrationFromInvitation