import React, { useLayoutEffect, useEffect, useState, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../../index";
import PageWrapper from "../../components/ui/layout/PageWrapper";
import counterpart from "counterpart";
import Helmet from "react-helmet";
import NavigationAlertCurrencyMismatch from "../../components/ui/navigation-alert/NavigationAlertCurrencyMismatch";
import "./Subscriptions.scss";
import { fetchSubscriptions, setSubscriptionsField, resetSubscriptions } from "../../actions/subscriptionsActions";
import Table, { TableArrowBtn } from "../../components/ui/table/Table";
import Text from "../../components/ui/text/Text";
import moment from "moment";
import StatusLabel from "../../components/ui/status-label/StatusLabel";
import { COLOR } from "../../utils/constants";
import { SubscriptionStatusType } from "../../models/Subscriptions";
import Search from "../../components/search/Search";
import Pagination from "../../components/ui/pagination/Pagination";
import history from "../../utils/history";
import SubscriptionsWelcome from "../../components/subscriptions/SubscriptionsWelcome";
import ProgressIndicator from "../../components/ui/progress-indicator/ProgressIndicator";
import FilterDropdown from "../../components/ui/filter-dropdown/FilterDropdown";
import API from "../../utils/API";
import { IPlanWithCycles, IProductLight } from "../../models/Product";
import FilterSort from "../../components/ui/filter-sort/FilterSort";
import Button from "../../components/ui/button/Button";
import Modal from "../../components/modal/Modal";
import ExportData from "../../components/export-data/ExportData";
import NoticePanel from "../../components/ui/notice-panel/NoticePanel";
import { Permission } from "../../models/Auth";
import Tooltip from "../../components/ui/tooltip/Tooltip";

const Subscriptions: React.FC = () => {
  const authData = useSelector((state: AppState) => state.auth);
  const subscriptionsData = useSelector((state: AppState) => state.subscriptionsReducer);

  const dispatch = useDispatch();

  const { readmeioLinks, currentCompanyDomain, currentCompanyId, parsedToken } = authData;
  const { subscriptionsPaginated, searchedSubscriptions, isFirstSearch, isLoading, subscriptionStatus, searchedProductFilter,
    searchedPlanFilter, products, plans, isCreatedOnDescendingOrder, pageNotification } = subscriptionsData;
  const { currentPage, pageCount, results, rowCount } = subscriptionsPaginated;
  const [allProducts, setAllProducts] = useState<Array<IProductLight>>([]);
  const [showExportSubscriptionsModal, setShowExportSubscriptionsModal] = useState(false);

  const hasPermissionDataExport = useMemo(() => {
    if(parsedToken?.CurrentPermissions.some(p => p === Permission[Permission.DataExportsCsv])) {
      return true
    }
    return false
  }, [parsedToken])

  useEffect(() => {
    const getProducts = async () => {
      try {
        const allProducts = await API.fetchAllProducts(currentCompanyId, false, true, true) as { results: Array<IProductLight> };
        setAllProducts(allProducts.results);
        const _products = allProducts.results
          .map(p => ({
            label: p.name,
            value: {
              productName: p.name,
              productId: p.productId
            }
          }))
          .sort((a, b) => a.label.toLocaleLowerCase() > b.label.toLocaleLowerCase() ? 1 : -1)
        dispatch(setSubscriptionsField("products", _products));
      }
      catch (err) {
        dispatch(setSubscriptionsField("products", []));
      }
    }

    if(!products.length) {
      //if the array is populated it means we're coming back from the subscription details page
      getProducts();
    }

  }, []);

  useEffect(() => {
    const getPlansByProductId = async () => {
      try {
        const product = products.find(p => p.value.productName === searchedProductFilter?.value.productName)
        if(!product) { 
          dispatch(setSubscriptionsField("plans", []));
          return;
        }


        const _plans = (await API.fetchPlansByProduct(currentCompanyDomain, product.value.productId)) as Array<IPlanWithCycles>
        
        const plansOfProduct = _plans.map(plan => ({
          label: plan.name,
          value: {
            planName: plan.name,
            productName: product.value.productName,
            planId: plan.planId
          }
        }))
        .sort((a, b) => a.label.toLocaleLowerCase() > b.label.toLocaleLowerCase() ? 1 : -1)

        dispatch(setSubscriptionsField("plans", plansOfProduct));
        dispatch(setSubscriptionsField("searchedPlanFilter", plansOfProduct.find(p => p.value.planId === searchedPlanFilter?.value.planId)));
      }
      catch(err) {
        dispatch(setSubscriptionsField("plans", []));
      }
      
    }
    getPlansByProductId();
    
  }, [products, searchedProductFilter]);



  const filterOptions = [
    { label: "SUBSCRIPTIONS_LIST_TABLE_ACTIVE", value: SubscriptionStatusType.ACTIVE },
    { label: "SUBSCRIPTIONS_LIST_TABLE_CANCELLED", value: SubscriptionStatusType.CANCELLED },
    { label: "SUBSCRIPTIONS_LIST_TABLE_PAUSED", value: SubscriptionStatusType.PAUSED },
    { label: "SUBSCRIPTIONS_LIST_TABLE_INTRIAL", value: SubscriptionStatusType.INTRIAL },
    { label: "SUBSCRIPTIONS_LIST_TABLE_SUSPENDED", value: SubscriptionStatusType.SUSPENDED },
  ]

  const getStatusText = (status: SubscriptionStatusType) => {
    switch (status) {
      case SubscriptionStatusType.ACTIVE:
        return "SUBSCRIPTIONS_LIST_TABLE_ACTIVE";
      case SubscriptionStatusType.CANCELLED:
        return "SUBSCRIPTIONS_LIST_TABLE_CANCELLED";
      case SubscriptionStatusType.PAUSED:
        return "SUBSCRIPTIONS_LIST_TABLE_PAUSED";
      case SubscriptionStatusType.INTRIAL:
        return "SUBSCRIPTIONS_LIST_TABLE_INTRIAL";
      case SubscriptionStatusType.SUSPENDED:
        return "SUBSCRIPTIONS_LIST_TABLE_SUSPENDED";
      default:
        return "SUBSCRIPTIONS_LIST_TABLE_UNSPECIFIED";
    }
  };

  const getStatusColor = (status: SubscriptionStatusType) => {
    switch (status) {
      case SubscriptionStatusType.ACTIVE:
        return COLOR.GREEN;
      case SubscriptionStatusType.CANCELLED:
        return COLOR.RED;
      case SubscriptionStatusType.PAUSED:
        return COLOR.LIGHT_GREEN;
      case SubscriptionStatusType.INTRIAL:
        return COLOR.ORANGE;
      case SubscriptionStatusType.SUSPENDED:
        return COLOR.LIGHT_RED
      default:
        return COLOR.ORANGE;
    }
  };

  useLayoutEffect(() => {
    if(!subscriptionsPaginated.results) {
      // we won't refetch the subscriptions if we're coming back from the subscription details page
      dispatch(fetchSubscriptions(currentCompanyDomain, currentPage, undefined, searchedSubscriptions, subscriptionStatus?.value, searchedProductFilter?.value.productName, searchedPlanFilter?.value.planName, isCreatedOnDescendingOrder));
    }
    return function cleanup() {
      !window.location.href.includes("/subscriptions") && dispatch(resetSubscriptions());
    };
  }, []);

  if (!results) {
    return <ProgressIndicator color="blue" coverage='full-content' />
  }

  if (!results.length && isFirstSearch) {
    return (
      <div className="subscriptions">
        <Helmet title={counterpart("NAVBAR_SUBSCRIPTIONS")} />
        <NavigationAlertCurrencyMismatch />
        <PageWrapper>
          <SubscriptionsWelcome readMeBillsbyApiLink={readmeioLinks.api} readMeBillsbyCheckoutLink={readmeioLinks.checkout} />
        </PageWrapper>
      </div>
    );
  }

  return (
    <div className="subscriptions">
      <Helmet title={counterpart("NAVBAR_SUBSCRIPTIONS")} />
      <NavigationAlertCurrencyMismatch />
      <div className="subscriptions__header">
        <div className="subscriptions__search">
          <Text
            content="SUBSCRIPTIONS_LIST_TABLE_NUMBER"
            translateWith={{
              numberOfSubscriptions: rowCount
            }}
            noMargin
          />
          <Search
            className="subscriptions__search-bar"
            value={searchedSubscriptions}
            placeholder="SUBSCRIPTIONS_SEARCH_PLACEHOLDER"
            onChange={(evt: any) => dispatch(setSubscriptionsField("searchedSubscriptions", evt.target.value))}
            onSearch={() => dispatch(fetchSubscriptions(currentCompanyDomain, 1, undefined, searchedSubscriptions, subscriptionStatus?.value, searchedProductFilter?.value.productName, searchedPlanFilter?.value.planName, isCreatedOnDescendingOrder))}
          />
          <div className="subscriptions__export-btn__container" data-tip data-for="permission-warning-tooltip">
            <Button
              id="subscriptions-export"
              buttonType="add"
              disabled={!hasPermissionDataExport}
              className="subscriptions__export-btn" 
              title="EXPORT_SUBSCRIPTIONS_DATA" 
              onClick={() => setShowExportSubscriptionsModal(true)} 
              icon="far fa-file-download"
            />
            {
              !hasPermissionDataExport &&
              <Tooltip id="permission-warning-tooltip" place="bottom" type="blue">
                <Text content="EXPORT_CUSTOMER_DATA_WARNING_PERMISSION" noMargin />
              </Tooltip>
            }
          </div>
          <Pagination
            className="subscriptions__search__pagination"
            pageCount={pageCount}
            isVisible={pageCount > 1}
            forcePage={currentPage - 1}
            onPageChange={({ selected }) => {
              dispatch(setSubscriptionsField("subscriptionsPaginated", { ...subscriptionsPaginated, currentPage: selected + 1 }))
              dispatch(fetchSubscriptions(currentCompanyDomain, selected + 1, undefined, searchedSubscriptions, subscriptionStatus?.value, searchedProductFilter?.value.productName, searchedPlanFilter?.value.planName, isCreatedOnDescendingOrder))
            }}
          />
        </div>
      </div>
      <PageWrapper>
        {pageNotification && <NoticePanel isModal className="customers__notice-panel" type={pageNotification.type} icon={pageNotification.icon} content={pageNotification.content} translateWith={pageNotification.translateWith}></NoticePanel>}
        {
          isLoading
            ? <ProgressIndicator color="blue" coverage='full-content' />
            : (
              <Table enableHoverEffect>
                <thead>
                  <tr>
                    <Text content="SUBSCRIPTIONS_LIST_TABLE_EMAIL" component="th" noMargin />
                    <Text content="SUBSCRIPTIONS_LIST_TABLE_FULL_NAME" component="th" noMargin />
                    <FilterDropdown label="SUBSCRIPTIONS_LIST_TABLE_PRODUCT" component="th"
                      shouldTranslateOptionLabel={false}
                      className="subscriptions__product-filter"
                      options={products}
                      selected={searchedProductFilter}
                      onSelect={(filter) => {
                        dispatch(setSubscriptionsField("searchedProductFilter", filter));
                        dispatch(setSubscriptionsField("searchedPlanFilter", undefined));
                        dispatch(fetchSubscriptions(currentCompanyDomain, 1, undefined, searchedSubscriptions, subscriptionStatus?.value, filter?.value.productName, undefined, isCreatedOnDescendingOrder));
                      }}
                      onClear={() => {
                        dispatch(setSubscriptionsField("searchedProductFilter", undefined));
                        dispatch(setSubscriptionsField("searchedPlanFilter", undefined));
                        dispatch(fetchSubscriptions(currentCompanyDomain, 1, undefined, searchedSubscriptions, subscriptionStatus?.value, undefined, undefined, isCreatedOnDescendingOrder));
                      }}
                    />
                    <FilterDropdown label="SUBSCRIPTIONS_LIST_TABLE_PLAN" component="th"
                      shouldTranslateOptionLabel={false}
                      className="subscriptions__plan-filter"
                      options={plans}
                      readonly={!searchedProductFilter}
                      selected={searchedPlanFilter}
                      onSelect={(filter) => {
                        dispatch(setSubscriptionsField("searchedPlanFilter", filter));
                        dispatch(fetchSubscriptions(currentCompanyDomain, 1, undefined, searchedSubscriptions, subscriptionStatus?.value, searchedProductFilter?.value.productName, filter.value.planName, isCreatedOnDescendingOrder));
                      }}
                      onClear={() => {
                        dispatch(setSubscriptionsField("searchedPlanFilter", undefined));
                        dispatch(fetchSubscriptions(currentCompanyDomain, 1, undefined, searchedSubscriptions, subscriptionStatus?.value, searchedProductFilter?.value.productName, undefined, isCreatedOnDescendingOrder));
                      }}
                    />
                    <FilterSort className="subscriptions__date-sort" label="SUBSCRIPTIONS_LIST_TABLE_CREATED_ON" component="th"
                      isDescendingOrder={isCreatedOnDescendingOrder}
                      onClick={(bool) => {
                        dispatch(setSubscriptionsField("isCreatedOnDescendingOrder", bool));
                        dispatch(fetchSubscriptions(currentCompanyDomain, currentPage, undefined, searchedSubscriptions, subscriptionStatus?.value, searchedProductFilter?.value.productName, searchedPlanFilter?.value.planName, bool));
                      }}
                    />
                    <FilterDropdown label="SUBSCRIPTIONS_LIST_TABLE_STATUS" component="th"
                      className="subscriptions__status-filter"
                      options={filterOptions}
                      selected={subscriptionStatus}
                      onSelect={(filter) => {
                        dispatch(setSubscriptionsField("subscriptionStatus", filter));
                        dispatch(fetchSubscriptions(currentCompanyDomain, 1, undefined, searchedSubscriptions, filter.value, searchedProductFilter?.value.productName, searchedPlanFilter?.value.planName, isCreatedOnDescendingOrder))
                      }}
                      onClear={() => {
                        dispatch(setSubscriptionsField("subscriptionStatus", undefined));
                        dispatch(fetchSubscriptions(currentCompanyDomain, 1, undefined, searchedSubscriptions, undefined, searchedProductFilter?.value.productName, searchedPlanFilter?.value.planName, isCreatedOnDescendingOrder))
                      }}
                    />
                    <Text content="SUBSCRIPTIONS_LIST_TABLE_SUBSCRIPTION_ID" component="th" noMargin className="subscriptions__subscription-id-column" />
                    <Text className="view-arrow" content="" component="th" shouldTranslate={false} noMargin />
                  </tr>
                </thead>
                <tbody>
                  {results &&
                    results.map(subscription => (
                      <tr className="subscriptions__table__item" key={subscription.subscriptionId} onClick={() => history.push(`/subscriptions/${subscription.subscriptionUniqueId}`)}>
                        <td>{subscription.customerEmail}</td>
                        <td>{subscription.customerFullname}</td>
                        <td>{subscription.productName}</td>
                        <td>{subscription.planName}</td>
                        <td>{moment(subscription.createdOn).format("DD MMM YYYY")}</td>
                        <td>
                          <StatusLabel content={getStatusText(subscription.status)} color={getStatusColor(subscription.status)} />
                        </td>
                        <td>{subscription.subscriptionUniqueId}</td>
                        <TableArrowBtn link={true} to={`/subscriptions/${subscription.subscriptionUniqueId}`} />
                      </tr>
                    ))}
                </tbody>
              </Table>
            )
        }
      </PageWrapper>
      <Modal
        isOpen={showExportSubscriptionsModal}
        title="EXPORT_SUBSCRIPTIONS_DATA"
        onRequestClose={() => setShowExportSubscriptionsModal(false)}
        children={
        <ExportData
          title="EXPORT_SUBSCRIPTIONS_DATA_PANEL_TITLE"
          content="EXPORT_SUBSCRIPTIONS_DATA_PANEL_CONTENT"
          companyId={currentCompanyId}
          type='subscriptions'
          callback={() => {
            setShowExportSubscriptionsModal(false)
            dispatch(setSubscriptionsField("pageNotification", { type: "success", icon: "fas fa-check-circle", content: "EXPORT_DATE_SUCCESS_NOTIFICATION", translateWith: { email: parsedToken?.email } }));
          }}
        />}
      />
    </div>
  );
};

export default Subscriptions;
