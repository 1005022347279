import React, { useState } from "react";
import CollapsablePanelWithArrow from "../../../ui/collapsable-panel-with-arrow/CollapsablePanelWithArrow";
import ErrorPanel from "../../../ui/error-panel/ErrorPanel";
import Text from "../../../ui/text/Text";

interface IFreeAgentFaq { }

export const FreeAgentFaq: React.FC<IFreeAgentFaq> = () => {

  const [collapsedPanel, setCollapsedPanel] = useState([false, true, true, true]);
  return (
    <div className="free-agent-faq">
      <CollapsablePanelWithArrow
        isCollapsed={collapsedPanel[0]}
        onClick={() => setCollapsedPanel([false, true, true, true])}
        className="free-agent-faq__panel"
        title="INTEGRATION_FAQ_PANEL1_TITLE">
        <Text content="INTEGRATION_FAQ_PANEL1_TEXT" noMargin />
      </CollapsablePanelWithArrow>

      <CollapsablePanelWithArrow
        isCollapsed={collapsedPanel[1]}
        onClick={() => setCollapsedPanel([true, false, true, true])}
        className="free-agent-faq__panel"
        title="INTEGRATION_FAQ_PANEL2_TITLE">
        <Text content="INTEGRATION_FAQ_PANEL2_TEXT" noMargin />
      </CollapsablePanelWithArrow>

      <CollapsablePanelWithArrow
        isCollapsed={collapsedPanel[2]}
        onClick={() => setCollapsedPanel([true, true, false, true])}
        className="free-agent-faq__panel"
        title="INTEGRATION_FAQ_PANEL3_TITLE">
        <Text content="INTEGRATION_FAQ_PANEL3_TEXT1" />
        <div>
          <Text content="INTEGRATION_FAQ_PANEL3_TEXT2" className="free-agent-faq__text text-inline" />
          <Text
            content="INTEGRATION_FAQ_PANEL3_TEXT3"
            className="free-agent-faq__text text-inline text-link"
            onClick={() => window.open("https://www.thebunker.net/", "_self")}
          />
          <Text content="INTEGRATION_FAQ_PANEL3_TEXT4" className="free-agent-faq__text text-inline" />
          <Text
            content="INTEGRATION_FAQ_PANEL3_TEXT5"
            className="free-agent-faq__text text-inline text-link"
            onClick={() => window.open("https://www.freeagent.com/features/security/", "_self")}
          />
          <Text content="INTEGRATION_FAQ_PANEL3_TEXT6" className="free-agent-faq__text text-inline" />
        </div>
      </CollapsablePanelWithArrow>

      <CollapsablePanelWithArrow
        isCollapsed={collapsedPanel[3]}
        onClick={() => setCollapsedPanel([true, true, true, false])}
        className="free-agent-faq__panel"
        title="INTEGRATION_FAQ_PANEL4_TITLE">
        <Text content="INTEGRATION_FAQ_PANEL4_TEXT" noMargin />
      </CollapsablePanelWithArrow>

      <ErrorPanel
        title="INTEGRATION_FAQ_TALK_US_TEXT1"
        subtitle="INTEGRATION_FAQ_TALK_US_TEXT2"
        icon="fas fa-question-circle"
      />
    </div>
  )
}

export default FreeAgentFaq;