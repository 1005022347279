/**
 * @fileoverview gRPC-Web generated client stub for billing.private.company.taxation
 * @enhanceable
 * @public
 */

// GENERATED CODE -- DO NOT EDIT!


/* eslint-disable */
// @ts-nocheck


import * as grpcWeb from 'grpc-web';

import * as taxation_pb from './taxation_pb';


export class TaxationServiceClient {
  client_: grpcWeb.AbstractClientBase;
  hostname_: string;
  credentials_: null | { [index: string]: string; };
  options_: null | { [index: string]: any; };

  constructor (hostname: string,
               credentials?: null | { [index: string]: string; },
               options?: null | { [index: string]: any; }) {
    if (!options) options = {};
    if (!credentials) credentials = {};
    options['format'] = 'text';

    this.client_ = new grpcWeb.GrpcWebClientBase(options);
    this.hostname_ = hostname;
    this.credentials_ = credentials;
    this.options_ = options;
  }

  methodDescriptorUpsertTaxJarConfig = new grpcWeb.MethodDescriptor(
    '/billing.private.company.taxation.TaxationService/UpsertTaxJarConfig',
    grpcWeb.MethodType.UNARY,
    taxation_pb.UpsertTaxJarConfigRequest,
    taxation_pb.UpsertTaxJarConfigResponse,
    (request: taxation_pb.UpsertTaxJarConfigRequest) => {
      return request.serializeBinary();
    },
    taxation_pb.UpsertTaxJarConfigResponse.deserializeBinary
  );

  upsertTaxJarConfig(
    request: taxation_pb.UpsertTaxJarConfigRequest,
    metadata: grpcWeb.Metadata | null): Promise<taxation_pb.UpsertTaxJarConfigResponse>;

  upsertTaxJarConfig(
    request: taxation_pb.UpsertTaxJarConfigRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: taxation_pb.UpsertTaxJarConfigResponse) => void): grpcWeb.ClientReadableStream<taxation_pb.UpsertTaxJarConfigResponse>;

  upsertTaxJarConfig(
    request: taxation_pb.UpsertTaxJarConfigRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: taxation_pb.UpsertTaxJarConfigResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/billing.private.company.taxation.TaxationService/UpsertTaxJarConfig',
        request,
        metadata || {},
        this.methodDescriptorUpsertTaxJarConfig,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/billing.private.company.taxation.TaxationService/UpsertTaxJarConfig',
    request,
    metadata || {},
    this.methodDescriptorUpsertTaxJarConfig);
  }

  methodDescriptorUpsertAutoBillsbyTax = new grpcWeb.MethodDescriptor(
    '/billing.private.company.taxation.TaxationService/UpsertAutoBillsbyTax',
    grpcWeb.MethodType.UNARY,
    taxation_pb.UpsertAutoBillsbyTaxRequest,
    taxation_pb.UpsertAutoBillsbyTaxResponse,
    (request: taxation_pb.UpsertAutoBillsbyTaxRequest) => {
      return request.serializeBinary();
    },
    taxation_pb.UpsertAutoBillsbyTaxResponse.deserializeBinary
  );

  upsertAutoBillsbyTax(
    request: taxation_pb.UpsertAutoBillsbyTaxRequest,
    metadata: grpcWeb.Metadata | null): Promise<taxation_pb.UpsertAutoBillsbyTaxResponse>;

  upsertAutoBillsbyTax(
    request: taxation_pb.UpsertAutoBillsbyTaxRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: taxation_pb.UpsertAutoBillsbyTaxResponse) => void): grpcWeb.ClientReadableStream<taxation_pb.UpsertAutoBillsbyTaxResponse>;

  upsertAutoBillsbyTax(
    request: taxation_pb.UpsertAutoBillsbyTaxRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: taxation_pb.UpsertAutoBillsbyTaxResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/billing.private.company.taxation.TaxationService/UpsertAutoBillsbyTax',
        request,
        metadata || {},
        this.methodDescriptorUpsertAutoBillsbyTax,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/billing.private.company.taxation.TaxationService/UpsertAutoBillsbyTax',
    request,
    metadata || {},
    this.methodDescriptorUpsertAutoBillsbyTax);
  }

  methodDescriptorUpsertManualTaxConfig = new grpcWeb.MethodDescriptor(
    '/billing.private.company.taxation.TaxationService/UpsertManualTaxConfig',
    grpcWeb.MethodType.UNARY,
    taxation_pb.UpsertManualTaxConfigRequest,
    taxation_pb.UpsertManualTaxConfigResponse,
    (request: taxation_pb.UpsertManualTaxConfigRequest) => {
      return request.serializeBinary();
    },
    taxation_pb.UpsertManualTaxConfigResponse.deserializeBinary
  );

  upsertManualTaxConfig(
    request: taxation_pb.UpsertManualTaxConfigRequest,
    metadata: grpcWeb.Metadata | null): Promise<taxation_pb.UpsertManualTaxConfigResponse>;

  upsertManualTaxConfig(
    request: taxation_pb.UpsertManualTaxConfigRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: taxation_pb.UpsertManualTaxConfigResponse) => void): grpcWeb.ClientReadableStream<taxation_pb.UpsertManualTaxConfigResponse>;

  upsertManualTaxConfig(
    request: taxation_pb.UpsertManualTaxConfigRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: taxation_pb.UpsertManualTaxConfigResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/billing.private.company.taxation.TaxationService/UpsertManualTaxConfig',
        request,
        metadata || {},
        this.methodDescriptorUpsertManualTaxConfig,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/billing.private.company.taxation.TaxationService/UpsertManualTaxConfig',
    request,
    metadata || {},
    this.methodDescriptorUpsertManualTaxConfig);
  }

  methodDescriptorGetTaxConfig = new grpcWeb.MethodDescriptor(
    '/billing.private.company.taxation.TaxationService/GetTaxConfig',
    grpcWeb.MethodType.UNARY,
    taxation_pb.GetTaxConfigRequest,
    taxation_pb.GetTaxConfigResponse,
    (request: taxation_pb.GetTaxConfigRequest) => {
      return request.serializeBinary();
    },
    taxation_pb.GetTaxConfigResponse.deserializeBinary
  );

  getTaxConfig(
    request: taxation_pb.GetTaxConfigRequest,
    metadata: grpcWeb.Metadata | null): Promise<taxation_pb.GetTaxConfigResponse>;

  getTaxConfig(
    request: taxation_pb.GetTaxConfigRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: taxation_pb.GetTaxConfigResponse) => void): grpcWeb.ClientReadableStream<taxation_pb.GetTaxConfigResponse>;

  getTaxConfig(
    request: taxation_pb.GetTaxConfigRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: taxation_pb.GetTaxConfigResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/billing.private.company.taxation.TaxationService/GetTaxConfig',
        request,
        metadata || {},
        this.methodDescriptorGetTaxConfig,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/billing.private.company.taxation.TaxationService/GetTaxConfig',
    request,
    metadata || {},
    this.methodDescriptorGetTaxConfig);
  }

  methodDescriptorGetTaxJarProductCategories = new grpcWeb.MethodDescriptor(
    '/billing.private.company.taxation.TaxationService/GetTaxJarProductCategories',
    grpcWeb.MethodType.UNARY,
    taxation_pb.GetTaxJarProductCategoriesRequest,
    taxation_pb.GetProductCategoriesResponse,
    (request: taxation_pb.GetTaxJarProductCategoriesRequest) => {
      return request.serializeBinary();
    },
    taxation_pb.GetProductCategoriesResponse.deserializeBinary
  );

  getTaxJarProductCategories(
    request: taxation_pb.GetTaxJarProductCategoriesRequest,
    metadata: grpcWeb.Metadata | null): Promise<taxation_pb.GetProductCategoriesResponse>;

  getTaxJarProductCategories(
    request: taxation_pb.GetTaxJarProductCategoriesRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: taxation_pb.GetProductCategoriesResponse) => void): grpcWeb.ClientReadableStream<taxation_pb.GetProductCategoriesResponse>;

  getTaxJarProductCategories(
    request: taxation_pb.GetTaxJarProductCategoriesRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: taxation_pb.GetProductCategoriesResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/billing.private.company.taxation.TaxationService/GetTaxJarProductCategories',
        request,
        metadata || {},
        this.methodDescriptorGetTaxJarProductCategories,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/billing.private.company.taxation.TaxationService/GetTaxJarProductCategories',
    request,
    metadata || {},
    this.methodDescriptorGetTaxJarProductCategories);
  }

  methodDescriptorGetAutoBillsbyProductCategories = new grpcWeb.MethodDescriptor(
    '/billing.private.company.taxation.TaxationService/GetAutoBillsbyProductCategories',
    grpcWeb.MethodType.UNARY,
    taxation_pb.GetAutoBillsbyProductCategoriesRequest,
    taxation_pb.GetProductCategoriesResponse,
    (request: taxation_pb.GetAutoBillsbyProductCategoriesRequest) => {
      return request.serializeBinary();
    },
    taxation_pb.GetProductCategoriesResponse.deserializeBinary
  );

  getAutoBillsbyProductCategories(
    request: taxation_pb.GetAutoBillsbyProductCategoriesRequest,
    metadata: grpcWeb.Metadata | null): Promise<taxation_pb.GetProductCategoriesResponse>;

  getAutoBillsbyProductCategories(
    request: taxation_pb.GetAutoBillsbyProductCategoriesRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: taxation_pb.GetProductCategoriesResponse) => void): grpcWeb.ClientReadableStream<taxation_pb.GetProductCategoriesResponse>;

  getAutoBillsbyProductCategories(
    request: taxation_pb.GetAutoBillsbyProductCategoriesRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: taxation_pb.GetProductCategoriesResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/billing.private.company.taxation.TaxationService/GetAutoBillsbyProductCategories',
        request,
        metadata || {},
        this.methodDescriptorGetAutoBillsbyProductCategories,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/billing.private.company.taxation.TaxationService/GetAutoBillsbyProductCategories',
    request,
    metadata || {},
    this.methodDescriptorGetAutoBillsbyProductCategories);
  }

  methodDescriptorDeleteTaxConfig = new grpcWeb.MethodDescriptor(
    '/billing.private.company.taxation.TaxationService/DeleteTaxConfig',
    grpcWeb.MethodType.UNARY,
    taxation_pb.DeleteTaxConfigRequest,
    taxation_pb.DeleteTaxConfigResponse,
    (request: taxation_pb.DeleteTaxConfigRequest) => {
      return request.serializeBinary();
    },
    taxation_pb.DeleteTaxConfigResponse.deserializeBinary
  );

  deleteTaxConfig(
    request: taxation_pb.DeleteTaxConfigRequest,
    metadata: grpcWeb.Metadata | null): Promise<taxation_pb.DeleteTaxConfigResponse>;

  deleteTaxConfig(
    request: taxation_pb.DeleteTaxConfigRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: taxation_pb.DeleteTaxConfigResponse) => void): grpcWeb.ClientReadableStream<taxation_pb.DeleteTaxConfigResponse>;

  deleteTaxConfig(
    request: taxation_pb.DeleteTaxConfigRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: taxation_pb.DeleteTaxConfigResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/billing.private.company.taxation.TaxationService/DeleteTaxConfig',
        request,
        metadata || {},
        this.methodDescriptorDeleteTaxConfig,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/billing.private.company.taxation.TaxationService/DeleteTaxConfig',
    request,
    metadata || {},
    this.methodDescriptorDeleteTaxConfig);
  }

}

