import { BillsbyAction } from "../models/BillsbyAction";
import { AppState } from "..";
import { FETCH_EVENT_LOGS_SUCCESS, RESET_EVENT_LOGS } from "../actions/eventLogsActions";
import { IEventLog } from "../models/EventLogs";

export interface IEventLogsReducerState {
  currentPage: number,
  pageCount: number,
  pageSize: number,
  rowCount: number,
  results?: Array<IEventLog>
}

export const initState = {
  currentPage: 1,
  pageCount: 1,
  pageSize: 0,
  rowCount: 0,
  results: undefined
}

export default function eventLogsReducer(state: IEventLogsReducerState = initState, action: BillsbyAction, store: AppState) {
  switch (action.type) {
    case FETCH_EVENT_LOGS_SUCCESS:
      const { isLoadMore } = action;
      const result = {
        currentPage: action.response.currentPage,
        pageCount: action.response.pageCount,
        pageSize: action.response.pageSize,
        rowCount: action.response.rowCount,
        results: (isLoadMore && !!state.results) ? state.results.concat(action.response.results) : action.response.results
      }
      return { ...state, ...result }
    case RESET_EVENT_LOGS: {
      return { ...initState }
    }
    default:
      return state;
  }
}