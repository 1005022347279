import React, { useState, useLayoutEffect } from "react";
import Button from "../../../ui/button/Button";
import CollectTaxRegistrationNumber from "../../tax-components/CollectTaxRegistrationNumber";
import ValidationOptions from "../../tax-components/ValidationOptions";
import EuropeanUnionRegistrationSteps from "../../tax-components/EuropeanUnionRegistrationSteps";
import ExemptionNote from "../../tax-components/ExemptionNote";
import ConfigureMemberStates from "../../tax-components/ConfigureMemberStates";
import { TaxCollection } from "../../../../models/Taxes";
import { TaxRate, ManualTaxConfig } from "../../../../utils/grpc/generated/Billsby.Protos/billing/private/company/taxation/taxation_pb";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../../../..";
import Panel from "../../../ui/panel/Panel";
import Text from "../../../ui/text/Text";
import { Row, Col } from "react-grid-system";
import Switch from "../../../ui/switch/Switch";
import { updateManualTaxConfig, fetchTaxConfig, deleteTaxconfig } from "../../../../actions/companyTaxRatesActions";
import { europeanCountriesCode } from "../../../../utils/states/states";

interface IEuropeanUnionTaxConfigFlow {
  handleUpdateCallback?: Function;
}

interface IDropdownSelect {
  value: string;
  label: string;
}

const EuropeanUnionTaxConfigFlow: React.FC<IEuropeanUnionTaxConfigFlow> = ({ handleUpdateCallback }) => {
  const auth = useSelector((state: AppState) => state.auth);
  const companyTaxRatesReducer = useSelector((state: AppState) => state.companyTaxRatesReducer);

  const dispatch = useDispatch<Function>();
  
  const [isLoading, setIsLoading] = useState(false);
  const [isConfigured, setIsConfigured] = useState(false);
  const [showAddTaxScreen, setShowAddTaxScreen] = useState(false);

  const { currentCompanyDomain } = auth;
  const { currentCountry, otherCountries } = companyTaxRatesReducer;

  //Product type state
  const [isDigital, setIsDigital] = useState<boolean | undefined>();

  //Registration state
  const [isRegistered, setIsRegistered] = useState<boolean | undefined>();

  //Tax registration numbers
  const [taxLabel, setTaxLabel] = useState("");
  const [taxRegNumber, setTaxRegNumber] = useState("");

  //Validation options states
  const [validateLocation, setValidateLocation] = useState(false);
  const [validateTax, setValidateTax] = useState(false);
  const [validateVies, setValidateVies] = useState(false);

  //Member states
  const [originCountry, setOriginCountry] = useState<TaxRate | undefined>();
  const [memberStates, setMemberStates] = useState<Array<IDropdownSelect>>([]);
  const [selectedStates, setSelectedStates] = useState<Array<TaxRate>>([]);

  //Exemption notes
  const [noteReverseCharge, setNoteReverseCharge] = useState("");
  const [noteExports, setNoteExports] = useState("");

  //Collect tax registration number states
  const [taxRegNumberRequirementType, setTaxRegNumberRequirementType] = useState(TaxCollection.DO_NOT_COLLECT);

  const isSaveAllowed = isDigital ? selectedStates.length > 0 : originCountry;

  useLayoutEffect(() => {
    setMemberStates(
      europeanCountriesCode.map((code) => {
        const state = otherCountries.find((oc) => oc.iso3Code === code);
        return { value: code, label: state ? state.name : "" };
      })
    );
  }, []);

  useLayoutEffect(() => {
    if (taxRegNumberRequirementType === TaxCollection.DO_NOT_COLLECT) {
      setValidateLocation(false);
      setValidateTax(false);
      setValidateVies(false)
    }
  }, [currentCountry, taxRegNumberRequirementType]);

  useLayoutEffect(() => {
    //Preload data if configured

    if (currentCountry && currentCountry.taxConfig) {
      const taxConfig = currentCountry.taxConfig as ManualTaxConfig.AsObject;
      const configuredOrigin = taxConfig.taxRatesList.find((co) => co.isOrigin);
      const configuredStates = taxConfig.taxRatesList.filter((cs) => !cs.isOrigin);

      setIsConfigured(true);

      setSelectedStates(
        configuredStates.map((taxRate) => {
          const tr = new TaxRate();
          tr.setName(taxRate.name);
          tr.setRate(taxRate.rate);
          tr.setCountryIso3(taxRate.countryIso3);
          tr.setTaxRegNumber(taxRate.taxRegNumber);
          return tr;
        })
      );

      if (configuredOrigin) {
        const originCountry = new TaxRate();
        originCountry.setCountryIso3(configuredOrigin.countryIso3);
        originCountry.setName(configuredOrigin.name);
        originCountry.setTaxRegNumber(configuredOrigin.taxRegNumber);
        originCountry.setIsOrigin(configuredOrigin.isOrigin);
        originCountry.setRate(configuredOrigin.rate);
        setOriginCountry(originCountry);
      }

      setIsDigital(taxConfig.isDigital);
      setIsRegistered(taxConfig.withVatMoss);
      setTaxLabel(taxConfig.taxLabel);
      setTaxRegNumber(taxConfig.taxRegNumber);
      setTaxRegNumberRequirementType(taxConfig.taxRegNumberRequirementType as TaxCollection);
      setValidateLocation(taxConfig.locationValidation);
      setValidateTax(taxConfig.taxValidation);
      setValidateVies(taxConfig.viesValidation);
      setNoteReverseCharge(taxConfig.exemptionNoteReverseCharge);
      setNoteExports(taxConfig.exemptionNoteExports);
    }
  }, [currentCountry]);

  if (!currentCountry) {
    return null;
  }
  
  const onAdd = ({ label, value }: IDropdownSelect) => {
    const newState = new TaxRate();
    newState.setCountryIso3(value);
    newState.setName(label);
    newState.setTaxRegNumber(taxRegNumber);
    newState.setRate(-1);

    setSelectedStates([newState, ...selectedStates]);
  };

  const onDelete = (state: TaxRate) => {
    setSelectedStates(selectedStates.filter((ss) => ss.getCountryIso3() !== state.getCountryIso3()));
  };

  const onAddOrigin = ({ label, value }: IDropdownSelect) => {
    const newOrigin = new TaxRate();
    newOrigin.setCountryIso3(value);
    newOrigin.setName(label);
    newOrigin.setIsOrigin(true);
    newOrigin.setRate(-1);

    setOriginCountry(newOrigin);
  };

  const onDeleteOrigin = () => {
    setOriginCountry(undefined);
  };

  const onChangeTaxRate = (value: number, state: TaxRate) => {
    if (state.getIsOrigin() && originCountry) {
      let newOrigin = new TaxRate();
      newOrigin.setCountryIso3(state.getCountryIso3());
      newOrigin.setName(state.getName());
      newOrigin.setTaxRegNumber(state.getTaxRegNumber());
      newOrigin.setIsOrigin(true);
      newOrigin.setRate(value);

      setOriginCountry(newOrigin);
      return;
    }

    setSelectedStates(
      selectedStates.map((ss) => {
        if (ss.getCountryIso3() === state.getCountryIso3()) {
          ss.setRate(value);
          return ss;
        }
        return ss;
      })
    );
  };

  const onChangeTaxRegNum = (value: string, state: TaxRate) => {
    if (state.getIsOrigin() && originCountry) {
      let newOrigin = new TaxRate();
      newOrigin.setCountryIso3(state.getCountryIso3());
      newOrigin.setName(state.getName());
      newOrigin.setRate(state.getRate());
      newOrigin.setIsOrigin(true);
      newOrigin.setTaxRegNumber(value);

      setOriginCountry(newOrigin);
      return;
    }

    setSelectedStates(
      selectedStates.map((ss) => {
        if (ss.getCountryIso3() === state.getCountryIso3()) {
          ss.setTaxRegNumber(value);
          return ss;
        }
        return ss;
      })
    );
  };

  const onDeleteTaxConfig = async () => {
    if (!currentCountry) {
      return;
    }

    try {
      await dispatch(deleteTaxconfig(currentCompanyDomain, currentCountry.countryCode, currentCountry.taxServiceType));
      await dispatch(fetchTaxConfig(currentCompanyDomain));
      handleUpdateCallback && handleUpdateCallback();
    } catch (err) {
      console.log(err);
    }
  };

  const onUpdateTaxConfig = async (evt: any) => {
    evt.preventDefault();

    if (!isSaveAllowed) {
      return;
    }

    setIsLoading(true);
    const manualTaxConfig = new ManualTaxConfig();

    if (isDigital !== undefined) {
      manualTaxConfig.setIsDigital(isDigital);
    }

    if (isRegistered !== undefined) {
      manualTaxConfig.setWithVatMoss(isRegistered);

      if (isRegistered) {
        manualTaxConfig.setTaxLabel(taxLabel);
        manualTaxConfig.setTaxRegNumber(taxRegNumber);
      }
    }

    manualTaxConfig.setTaxRatesList(originCountry ? [...selectedStates, originCountry] : selectedStates);
    manualTaxConfig.setTaxRegNumberRequirementType(taxRegNumberRequirementType);
    manualTaxConfig.setLocationValidation(validateLocation);
    manualTaxConfig.setTaxValidation(validateTax);
    manualTaxConfig.setViesValidation(validateVies);
    manualTaxConfig.setExemptionNoteExports(noteExports);
    manualTaxConfig.setExemptionNoteReverseCharge(noteReverseCharge);

    try {
      await dispatch(updateManualTaxConfig(currentCompanyDomain, currentCountry.countryCode, manualTaxConfig));
      await dispatch(fetchTaxConfig(currentCompanyDomain));

      handleUpdateCallback && handleUpdateCallback();
    } catch (err) {
      setIsLoading(false);
    }
  };

  const renderRegistrationStep = () => {
    return (
      <>
        <EuropeanUnionRegistrationSteps
          isDigital={isDigital}
          isRegistered={isRegistered}
          taxLabel={taxLabel}
          taxRegNumber={taxRegNumber}
          onChangeProductType={(value) => setIsDigital(value)}
          onChangeRegistration={(value) => setIsRegistered(value)}
          onChangeTaxLabel={(value) => setTaxLabel(value)}
          onChangeTaxRegNumber={(value) => setTaxRegNumber(value)}
          onClickButton={() => setShowAddTaxScreen(true)}
        />
      </>
    );
  };

  const renderAddTaxScreen = () => {
    return (
      <form onSubmit={onUpdateTaxConfig}>
        <ConfigureMemberStates
          title={isDigital ? "EUROPEAN_UNION_TITLE1" : "EUROPEAN_UNION_TITLE2"}
          content={isRegistered ? "EUROPEAN_UNION_CONTENT1" : isDigital ? "EUROPEAN_UNION_CONTENT2" : "EUROPEAN_UNION_CONTENT3"}
          onAdd={onAdd}
          onDelete={onDelete}
          onAddOrigin={onAddOrigin}
          onDeleteOrigin={onDeleteOrigin}
          onChangeTaxRate={onChangeTaxRate}
          onDeleteConfig={onDeleteTaxConfig}
          onChangeTaxRegNum={!isRegistered ? onChangeTaxRegNum : undefined}
          taxLabel={taxLabel}
          taxRegNumber={taxRegNumber}
          states={memberStates}
          selectedStates={selectedStates}
          originCountry={originCountry}
          isConfigured={isConfigured}
          isDigital={isDigital}
        />

        <CollectTaxRegistrationNumber
          taxRegNumberRequirementType={taxRegNumberRequirementType}
          onChangeType={(value) => setTaxRegNumberRequirementType(value)}
          onChangeCollectTax={(value) => setTaxRegNumberRequirementType(value)}
        />

        {taxRegNumberRequirementType !== TaxCollection.DO_NOT_COLLECT && <ValidationOptions
          taxIdentifier="VAT"
          onValidateLocation={() => setValidateLocation(!validateLocation)}
          onValidateTax={() => setValidateTax(!validateTax)}
          validateLocation={validateLocation}
          validateTax={validateTax}
        />}

        {taxRegNumberRequirementType !== TaxCollection.DO_NOT_COLLECT && <Panel title="EUROPEAN_UNION_COLLECT_TAX_REG_TITLE">
          <Text content="EUROPEAN_UNION_COLLECT_TAX_REG_CONTENT" />
          <Row align="center">
            <Col md={1}>
              <Switch id="vies-collect-tax-reg" checked={validateVies} onChange={() => setValidateVies(!validateVies)} />
            </Col>
            <Col md={11}>
              <Text component="span" content="EUROPEAN_UNION_COLLECT_TAX_REG_SWITCH" />
            </Col>
          </Row>
        </Panel>}

        <ExemptionNote
          note={noteReverseCharge}
          title="EUROPEAN_UNION_EXEMPTION_TITLE1"
          content="EUROPEAN_UNION_EXEMPTION_CONTENT1"
          placeholder="EUROPEAN_UNION_EXEMPTION_PLACEHOLDER1"
          onChangeNote={(value) => setNoteReverseCharge(value)}
        />

        <ExemptionNote
          note={noteExports}
          title="EUROPEAN_UNION_EXEMPTION_TITLE2"
          content="EUROPEAN_UNION_EXEMPTION_CONTENT2"
          placeholder="EUROPEAN_UNION_EXEMPTION_PLACEHOLDER2"
          onChangeNote={(value) => setNoteExports(value)}
        />
        <Button disabled={!isSaveAllowed} type="submit" isLoading={isLoading} isFullWidth id="save-manual-tax" title="MANUAL_TAX_CONFIG_SAVE" />
      </form>
    );
  };

  return <div className="tax-config-flow">{showAddTaxScreen || isConfigured ? renderAddTaxScreen() : renderRegistrationStep()}</div>;
};

export default EuropeanUnionTaxConfigFlow;
