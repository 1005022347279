import React from "react";
import "./FileUpload.scss";
import Text from "../text/Text";
import ProgressIndicator from "../progress-indicator/ProgressIndicator";

interface IFileUploadText {
  title: string | JSX.Element,
  shouldTranslateTitle?: boolean,
  subTitle: string,
  shouldTranslateSubTitle?: boolean,
  isLoading?: boolean
}

const FileUploadText: React.FC<IFileUploadText> = ({ title, shouldTranslateTitle = true, subTitle, shouldTranslateSubTitle = true, isLoading = false }) => {

  return (
    <>
      {isLoading ? <ProgressIndicator color="blue" coverage="normal" className="file-upload__progress_indicator" /> :
        <div className="file-upload__text">
          <Text content={title} component="div" shouldTranslate={shouldTranslateTitle} noMargin />
          <Text content={subTitle} shouldTranslate={shouldTranslateSubTitle} noMargin />
        </div>
      }
    </>
  )
}

export default FileUploadText;