import React from "react";
import { NavLink, useRouteMatch } from "react-router-dom";
import counterpart from "counterpart";
import TabbedContainer from "../../../../ui/tabbed-container/TabbedContainer";
import { PrivateRoute } from "../../../../../containers/AuthRoutes";
import "./EmailInvoicesTabs.scss";
import InvoicePreview from "../invoice-preview/InvoicePreview";
import BrandingEmailPreview from "../email-preview/BrandingEmailPreview";

const EmailInvoicesTabs: React.FC = () => {
  const match = useRouteMatch();

  const tabArray = [
    { label: "BRAND_CUSTOMISATION_EMAIL_PREVIEW_TAB", navlink: "" },
    { label: "BRAND_CUSTOMISATION_INVOICE_TAB", navlink: "/invoice" }
  ];
  
  return (
    <div className="email-invoices-tabs">
      <TabbedContainer type="submenu">
        {tabArray.map((tab, idx) => (
          <NavLink
            isActive={(match, location) => (!match || !location ? false : match?.isExact)}
            key={idx}
            activeClassName="active"
            to={`${match?.url}${tab.navlink}`}
          >
            <li>{counterpart(tab.label)}</li>
          </NavLink>
        ))}
      </TabbedContainer>

      <PrivateRoute
        render={() => (
          <div className="email-invoices-tabs__content">
            <PrivateRoute exact path={`${match?.url}/`} component={BrandingEmailPreview} />
            <PrivateRoute exact path={`${match?.url}/invoice`} component={InvoicePreview} />
          </div>
        )}
      />
    </div>
  );
};

export default EmailInvoicesTabs;