import { BillsbyAction } from "../models/BillsbyAction";
import { AppState } from "..";
import { FETCH_CREDIT_NOTES_FAILURE, FETCH_CREDIT_NOTES_REQUEST, FETCH_CREDIT_NOTES_SUCCESS, RESET_CREDIT_NOTES, SET_CREDIT_NOTES_FIELD } from "../actions/creditNotesActions";
import { CreditNoteStatus, ICreditNotesPaginated } from "../models/CreditNotes";
import { IPageNotification } from "../models/Configuration";

export interface ICreditNotesReducerState {
  creditNotesPaginated: ICreditNotesPaginated,
  searchedCreditNotes: string,
  hasCreditNotes: boolean,
  creditNoteStatus?: { label: string, value: CreditNoteStatus },
  isDateDescendingOrder: boolean,
  isFetching: boolean,
  pageNotification?: IPageNotification
}

export const creditNotesInitState = {
  creditNotesPaginated: {
    currentPage: 1,
    pageCount: 1,
    pageSize: 0,
    rowCount: 0,
    results: undefined
  },
  searchedCreditNotes: "",
  hasCreditNotes: false,
  isDateDescendingOrder: true,
  isFetching: false
}

export default function creditNotesReducer(state: ICreditNotesReducerState = creditNotesInitState, action: BillsbyAction, store: AppState) {
  switch (action.type) {
    case SET_CREDIT_NOTES_FIELD:
      return { ...state, [action.fieldName]: action.fieldValue }
    case FETCH_CREDIT_NOTES_REQUEST:
      return { ...state, isFetching: true }
    case FETCH_CREDIT_NOTES_FAILURE:
      return { ...state, isFetching: false }
    case FETCH_CREDIT_NOTES_SUCCESS:
        const hasCreditNotes = (!!action.response.results && action.response.results.length > 0) || state.hasCreditNotes;
      return { ...state, creditNotesPaginated: action.response, hasCreditNotes, isFetching: false }
    case RESET_CREDIT_NOTES:
      return { ...creditNotesInitState }
    default:
      return state;
  }
}