import { COLOR } from "../utils/constants";

export enum ConfigurationScreen {
  HOME = "HOME",
  PAYMENT_GATEWAYS = "PAYMENT_GATEWAYS",
  CURRENCIES = "CURRENCIES"
}

export interface ICustomStatus {
  label: string;
  icon?: string;
  color?: COLOR;
}

export interface IPageNotification { 
  type: "error" | "warning" | "success", 
  icon: string, 
  content: string,
  translateWith?: object 
}
export interface IListElement {
  title: string | any,
  shouldTranslateTitle?: boolean,
  subTitle?: string | any,
  shouldTranslateSubtitle?: boolean,
  onClick?: (listElement: IListElement) => void,
  logo?: string,
  isLogoFontAwesome?: boolean,
  statusLabel?: string,
  statusLabelColor?: COLOR,
  icon?: string,
  isElementClickable?: boolean,
  isLocked?: boolean;
  isVisible?: boolean;
  customStatus?: Array<ICustomStatus>
  translateSubtitleWith?: object
  tooltipMessage?: string
}

export interface IDropdownOptions {
  label: string,
  value: string,
  [prop: string]: string,
}

export interface IPaymentGatewayElement extends IListElement {
  paymentGatewayId?: number,
  type: PaymentGatewaysType,
  helpTitle: string,
  helpContent: Array<string>,
  mustActivate?: boolean,
  hasMappedCurrency: boolean,
  data: any,
  isScaSupported: boolean,
  isScaActivated: boolean,
  isAchSupported: boolean,
  minChargeAmountFormmated: string,
  isReplaceable: boolean
}

export interface IExistingPaymentGateway {
  displayName: string,
  paymentGatewayId: number,
  gatewayType: PaymentGatewaysType,
  hasMappedCurrencies: boolean,
  isReplaceable: boolean,
  data: any,
  isScaSupported: boolean,
  isScaActivated: boolean,
  isAchSupported: boolean,
  minChargeAmountFormmated: string
}

/* enum used for the query string type, at the moment only after stripe redirect */
export enum PaymentGatewaysType {
  STRIPE = "Stripe",
  TEST = "SpreedlyTest",
  ADYEN = "Adyen",
  AUTHORIZE = "AuthorizeDotNet",
  BRAINTREE = "Braintree",
  CHECKOUT = "CheckoutDotCom",
  IXOPAY = "Ixopay",
  NMI = "NMI",
  PAYMENTCLOUD = "PaymentCloud",
  CLOVERCONNECT = "CardConnect",
  STRIPEINTENT = "StripeIntent"
}

export interface IStripeScaConfig {
  webhookId: string;
  webhookSigningSecret: string;
  apiSecretKey: string;
}