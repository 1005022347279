import { BillsbyAction } from "../models/BillsbyAction";
import { AppState } from "..";
import { SET_UI_FIELD, RESET_UI } from "../actions/userInteraceActions";

export interface IUserInterfaceReducerState {
  autoScroll: boolean
}

const initState = {
  autoScroll: true
}

export default function userInterfaceReducer(state: IUserInterfaceReducerState = initState, action: BillsbyAction, store: AppState) {
  switch (action.type) {
    case SET_UI_FIELD: {
      return { ...state, [action.fieldName]: action.fieldValue }
    }
    case RESET_UI: {
      return initState
    }
    default: return state
  }
}