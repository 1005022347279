/* eslint-disable */
// source: coupons.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!

var jspb = require('google-protobuf');
var goog = jspb;
var global = Function('return this')();

var google_protobuf_timestamp_pb = require('google-protobuf/google/protobuf/timestamp_pb.js');
goog.object.extend(proto, google_protobuf_timestamp_pb);
goog.exportSymbol('proto.core.private.coupons.CreateCouponRequest', null, global);
goog.exportSymbol('proto.core.private.coupons.CreateCouponRequest.CouponTypeCase', null, global);
goog.exportSymbol('proto.core.private.coupons.CreateCouponRequest.DiscountLengthCase', null, global);
goog.exportSymbol('proto.core.private.coupons.CreateCouponRequest.ExpirationCase', null, global);
goog.exportSymbol('proto.core.private.coupons.CreateCouponRequest.FixedTermDiscountLength', null, global);
goog.exportSymbol('proto.core.private.coupons.CreateCouponRequest.MultiUse', null, global);
goog.exportSymbol('proto.core.private.coupons.CreateCouponRequest.SingleUse', null, global);
goog.exportSymbol('proto.core.private.coupons.CreateCouponResponse', null, global);
goog.exportSymbol('proto.core.private.coupons.EligibleCustomers', null, global);
goog.exportSymbol('proto.core.private.coupons.ExpireCouponRequest', null, global);
goog.exportSymbol('proto.core.private.coupons.ExpireCouponResponse', null, global);
goog.exportSymbol('proto.core.private.coupons.FrequencyType', null, global);
goog.exportSymbol('proto.core.private.coupons.GetCouponRequest', null, global);
goog.exportSymbol('proto.core.private.coupons.GetCouponResponse', null, global);
goog.exportSymbol('proto.core.private.coupons.GetCouponResponse.CouponType', null, global);
goog.exportSymbol('proto.core.private.coupons.GetCouponResponse.CouponTypeModelCase', null, global);
goog.exportSymbol('proto.core.private.coupons.GetCouponResponse.DiscountLengthCase', null, global);
goog.exportSymbol('proto.core.private.coupons.GetCouponResponse.ExpirationCase', null, global);
goog.exportSymbol('proto.core.private.coupons.GetCouponResponse.FixedTermDiscountLength', null, global);
goog.exportSymbol('proto.core.private.coupons.GetCouponResponse.MultiUse', null, global);
goog.exportSymbol('proto.core.private.coupons.GetCouponResponse.SingleUse', null, global);
goog.exportSymbol('proto.core.private.coupons.UpdateCouponRequest', null, global);
goog.exportSymbol('proto.core.private.coupons.UpdateCouponRequest.CouponTypeCase', null, global);
goog.exportSymbol('proto.core.private.coupons.UpdateCouponRequest.DiscountLengthCase', null, global);
goog.exportSymbol('proto.core.private.coupons.UpdateCouponRequest.ExpirationCase', null, global);
goog.exportSymbol('proto.core.private.coupons.UpdateCouponRequest.FixedTermDiscountLength', null, global);
goog.exportSymbol('proto.core.private.coupons.UpdateCouponRequest.UpdateMultiUse', null, global);
goog.exportSymbol('proto.core.private.coupons.UpdateCouponRequest.UpdateSingleUse', null, global);
goog.exportSymbol('proto.core.private.coupons.UpdateCouponResponse', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.core.private.coupons.GetCouponRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.core.private.coupons.GetCouponRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.core.private.coupons.GetCouponRequest.displayName = 'proto.core.private.coupons.GetCouponRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.core.private.coupons.GetCouponResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.core.private.coupons.GetCouponResponse.oneofGroups_);
};
goog.inherits(proto.core.private.coupons.GetCouponResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.core.private.coupons.GetCouponResponse.displayName = 'proto.core.private.coupons.GetCouponResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.core.private.coupons.GetCouponResponse.SingleUse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.core.private.coupons.GetCouponResponse.SingleUse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.core.private.coupons.GetCouponResponse.SingleUse.displayName = 'proto.core.private.coupons.GetCouponResponse.SingleUse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.core.private.coupons.GetCouponResponse.MultiUse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.core.private.coupons.GetCouponResponse.MultiUse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.core.private.coupons.GetCouponResponse.MultiUse.displayName = 'proto.core.private.coupons.GetCouponResponse.MultiUse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.core.private.coupons.GetCouponResponse.FixedTermDiscountLength = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.core.private.coupons.GetCouponResponse.FixedTermDiscountLength, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.core.private.coupons.GetCouponResponse.FixedTermDiscountLength.displayName = 'proto.core.private.coupons.GetCouponResponse.FixedTermDiscountLength';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.core.private.coupons.ExpireCouponRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.core.private.coupons.ExpireCouponRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.core.private.coupons.ExpireCouponRequest.displayName = 'proto.core.private.coupons.ExpireCouponRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.core.private.coupons.ExpireCouponResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.core.private.coupons.ExpireCouponResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.core.private.coupons.ExpireCouponResponse.displayName = 'proto.core.private.coupons.ExpireCouponResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.core.private.coupons.UpdateCouponRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.core.private.coupons.UpdateCouponRequest.oneofGroups_);
};
goog.inherits(proto.core.private.coupons.UpdateCouponRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.core.private.coupons.UpdateCouponRequest.displayName = 'proto.core.private.coupons.UpdateCouponRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.core.private.coupons.UpdateCouponRequest.FixedTermDiscountLength = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.core.private.coupons.UpdateCouponRequest.FixedTermDiscountLength, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.core.private.coupons.UpdateCouponRequest.FixedTermDiscountLength.displayName = 'proto.core.private.coupons.UpdateCouponRequest.FixedTermDiscountLength';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.core.private.coupons.UpdateCouponRequest.UpdateSingleUse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.core.private.coupons.UpdateCouponRequest.UpdateSingleUse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.core.private.coupons.UpdateCouponRequest.UpdateSingleUse.displayName = 'proto.core.private.coupons.UpdateCouponRequest.UpdateSingleUse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.core.private.coupons.UpdateCouponRequest.UpdateMultiUse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.core.private.coupons.UpdateCouponRequest.UpdateMultiUse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.core.private.coupons.UpdateCouponRequest.UpdateMultiUse.displayName = 'proto.core.private.coupons.UpdateCouponRequest.UpdateMultiUse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.core.private.coupons.UpdateCouponResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.core.private.coupons.UpdateCouponResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.core.private.coupons.UpdateCouponResponse.displayName = 'proto.core.private.coupons.UpdateCouponResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.core.private.coupons.CreateCouponRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.core.private.coupons.CreateCouponRequest.oneofGroups_);
};
goog.inherits(proto.core.private.coupons.CreateCouponRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.core.private.coupons.CreateCouponRequest.displayName = 'proto.core.private.coupons.CreateCouponRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.core.private.coupons.CreateCouponRequest.FixedTermDiscountLength = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.core.private.coupons.CreateCouponRequest.FixedTermDiscountLength, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.core.private.coupons.CreateCouponRequest.FixedTermDiscountLength.displayName = 'proto.core.private.coupons.CreateCouponRequest.FixedTermDiscountLength';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.core.private.coupons.CreateCouponRequest.SingleUse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.core.private.coupons.CreateCouponRequest.SingleUse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.core.private.coupons.CreateCouponRequest.SingleUse.displayName = 'proto.core.private.coupons.CreateCouponRequest.SingleUse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.core.private.coupons.CreateCouponRequest.MultiUse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.core.private.coupons.CreateCouponRequest.MultiUse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.core.private.coupons.CreateCouponRequest.MultiUse.displayName = 'proto.core.private.coupons.CreateCouponRequest.MultiUse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.core.private.coupons.CreateCouponResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.core.private.coupons.CreateCouponResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.core.private.coupons.CreateCouponResponse.displayName = 'proto.core.private.coupons.CreateCouponResponse';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.core.private.coupons.GetCouponRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.core.private.coupons.GetCouponRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.core.private.coupons.GetCouponRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.core.private.coupons.GetCouponRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    companyId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    couponId: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.core.private.coupons.GetCouponRequest}
 */
proto.core.private.coupons.GetCouponRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.core.private.coupons.GetCouponRequest;
  return proto.core.private.coupons.GetCouponRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.core.private.coupons.GetCouponRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.core.private.coupons.GetCouponRequest}
 */
proto.core.private.coupons.GetCouponRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setCompanyId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setCouponId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.core.private.coupons.GetCouponRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.core.private.coupons.GetCouponRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.core.private.coupons.GetCouponRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.core.private.coupons.GetCouponRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCompanyId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getCouponId();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
};


/**
 * optional int32 company_id = 1;
 * @return {number}
 */
proto.core.private.coupons.GetCouponRequest.prototype.getCompanyId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.core.private.coupons.GetCouponRequest} returns this
 */
proto.core.private.coupons.GetCouponRequest.prototype.setCompanyId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int32 coupon_id = 2;
 * @return {number}
 */
proto.core.private.coupons.GetCouponRequest.prototype.getCouponId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.core.private.coupons.GetCouponRequest} returns this
 */
proto.core.private.coupons.GetCouponRequest.prototype.setCouponId = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.core.private.coupons.GetCouponResponse.oneofGroups_ = [[5,6],[7,8],[9,10]];

/**
 * @enum {number}
 */
proto.core.private.coupons.GetCouponResponse.ExpirationCase = {
  EXPIRATION_NOT_SET: 0,
  IS_INFINITE: 5,
  EXPIRES_ON: 6
};

/**
 * @return {proto.core.private.coupons.GetCouponResponse.ExpirationCase}
 */
proto.core.private.coupons.GetCouponResponse.prototype.getExpirationCase = function() {
  return /** @type {proto.core.private.coupons.GetCouponResponse.ExpirationCase} */(jspb.Message.computeOneofCase(this, proto.core.private.coupons.GetCouponResponse.oneofGroups_[0]));
};

/**
 * @enum {number}
 */
proto.core.private.coupons.GetCouponResponse.DiscountLengthCase = {
  DISCOUNT_LENGTH_NOT_SET: 0,
  IS_LIFE_TIME: 7,
  FIXED_TERM: 8
};

/**
 * @return {proto.core.private.coupons.GetCouponResponse.DiscountLengthCase}
 */
proto.core.private.coupons.GetCouponResponse.prototype.getDiscountLengthCase = function() {
  return /** @type {proto.core.private.coupons.GetCouponResponse.DiscountLengthCase} */(jspb.Message.computeOneofCase(this, proto.core.private.coupons.GetCouponResponse.oneofGroups_[1]));
};

/**
 * @enum {number}
 */
proto.core.private.coupons.GetCouponResponse.CouponTypeModelCase = {
  COUPON_TYPE_MODEL_NOT_SET: 0,
  SINGLE_USE: 9,
  MULTI_USE: 10
};

/**
 * @return {proto.core.private.coupons.GetCouponResponse.CouponTypeModelCase}
 */
proto.core.private.coupons.GetCouponResponse.prototype.getCouponTypeModelCase = function() {
  return /** @type {proto.core.private.coupons.GetCouponResponse.CouponTypeModelCase} */(jspb.Message.computeOneofCase(this, proto.core.private.coupons.GetCouponResponse.oneofGroups_[2]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.core.private.coupons.GetCouponResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.core.private.coupons.GetCouponResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.core.private.coupons.GetCouponResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.core.private.coupons.GetCouponResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    couponId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    couponType: jspb.Message.getFieldWithDefault(msg, 3, 0),
    eligibleCustomers: jspb.Message.getFieldWithDefault(msg, 4, 0),
    isInfinite: jspb.Message.getBooleanFieldWithDefault(msg, 5, false),
    expiresOn: (f = msg.getExpiresOn()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    isLifeTime: jspb.Message.getBooleanFieldWithDefault(msg, 7, false),
    fixedTerm: (f = msg.getFixedTerm()) && proto.core.private.coupons.GetCouponResponse.FixedTermDiscountLength.toObject(includeInstance, f),
    singleUse: (f = msg.getSingleUse()) && proto.core.private.coupons.GetCouponResponse.SingleUse.toObject(includeInstance, f),
    multiUse: (f = msg.getMultiUse()) && proto.core.private.coupons.GetCouponResponse.MultiUse.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.core.private.coupons.GetCouponResponse}
 */
proto.core.private.coupons.GetCouponResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.core.private.coupons.GetCouponResponse;
  return proto.core.private.coupons.GetCouponResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.core.private.coupons.GetCouponResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.core.private.coupons.GetCouponResponse}
 */
proto.core.private.coupons.GetCouponResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setCouponId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = /** @type {!proto.core.private.coupons.GetCouponResponse.CouponType} */ (reader.readEnum());
      msg.setCouponType(value);
      break;
    case 4:
      var value = /** @type {!proto.core.private.coupons.EligibleCustomers} */ (reader.readEnum());
      msg.setEligibleCustomers(value);
      break;
    case 5:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsInfinite(value);
      break;
    case 6:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setExpiresOn(value);
      break;
    case 7:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsLifeTime(value);
      break;
    case 8:
      var value = new proto.core.private.coupons.GetCouponResponse.FixedTermDiscountLength;
      reader.readMessage(value,proto.core.private.coupons.GetCouponResponse.FixedTermDiscountLength.deserializeBinaryFromReader);
      msg.setFixedTerm(value);
      break;
    case 9:
      var value = new proto.core.private.coupons.GetCouponResponse.SingleUse;
      reader.readMessage(value,proto.core.private.coupons.GetCouponResponse.SingleUse.deserializeBinaryFromReader);
      msg.setSingleUse(value);
      break;
    case 10:
      var value = new proto.core.private.coupons.GetCouponResponse.MultiUse;
      reader.readMessage(value,proto.core.private.coupons.GetCouponResponse.MultiUse.deserializeBinaryFromReader);
      msg.setMultiUse(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.core.private.coupons.GetCouponResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.core.private.coupons.GetCouponResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.core.private.coupons.GetCouponResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.core.private.coupons.GetCouponResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCouponId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getCouponType();
  if (f !== 0.0) {
    writer.writeEnum(
      3,
      f
    );
  }
  f = message.getEligibleCustomers();
  if (f !== 0.0) {
    writer.writeEnum(
      4,
      f
    );
  }
  f = /** @type {boolean} */ (jspb.Message.getField(message, 5));
  if (f != null) {
    writer.writeBool(
      5,
      f
    );
  }
  f = message.getExpiresOn();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = /** @type {boolean} */ (jspb.Message.getField(message, 7));
  if (f != null) {
    writer.writeBool(
      7,
      f
    );
  }
  f = message.getFixedTerm();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      proto.core.private.coupons.GetCouponResponse.FixedTermDiscountLength.serializeBinaryToWriter
    );
  }
  f = message.getSingleUse();
  if (f != null) {
    writer.writeMessage(
      9,
      f,
      proto.core.private.coupons.GetCouponResponse.SingleUse.serializeBinaryToWriter
    );
  }
  f = message.getMultiUse();
  if (f != null) {
    writer.writeMessage(
      10,
      f,
      proto.core.private.coupons.GetCouponResponse.MultiUse.serializeBinaryToWriter
    );
  }
};


/**
 * @enum {number}
 */
proto.core.private.coupons.GetCouponResponse.CouponType = {
  UNSPECIFIED_CT: 0,
  SINGLE_USE: 1,
  MULTI_USE: 2
};




if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.core.private.coupons.GetCouponResponse.SingleUse.prototype.toObject = function(opt_includeInstance) {
  return proto.core.private.coupons.GetCouponResponse.SingleUse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.core.private.coupons.GetCouponResponse.SingleUse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.core.private.coupons.GetCouponResponse.SingleUse.toObject = function(includeInstance, msg) {
  var f, obj = {
    generatedAmount: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.core.private.coupons.GetCouponResponse.SingleUse}
 */
proto.core.private.coupons.GetCouponResponse.SingleUse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.core.private.coupons.GetCouponResponse.SingleUse;
  return proto.core.private.coupons.GetCouponResponse.SingleUse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.core.private.coupons.GetCouponResponse.SingleUse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.core.private.coupons.GetCouponResponse.SingleUse}
 */
proto.core.private.coupons.GetCouponResponse.SingleUse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setGeneratedAmount(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.core.private.coupons.GetCouponResponse.SingleUse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.core.private.coupons.GetCouponResponse.SingleUse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.core.private.coupons.GetCouponResponse.SingleUse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.core.private.coupons.GetCouponResponse.SingleUse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getGeneratedAmount();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
};


/**
 * optional int32 generated_amount = 1;
 * @return {number}
 */
proto.core.private.coupons.GetCouponResponse.SingleUse.prototype.getGeneratedAmount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.core.private.coupons.GetCouponResponse.SingleUse} returns this
 */
proto.core.private.coupons.GetCouponResponse.SingleUse.prototype.setGeneratedAmount = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.core.private.coupons.GetCouponResponse.MultiUse.prototype.toObject = function(opt_includeInstance) {
  return proto.core.private.coupons.GetCouponResponse.MultiUse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.core.private.coupons.GetCouponResponse.MultiUse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.core.private.coupons.GetCouponResponse.MultiUse.toObject = function(includeInstance, msg) {
  var f, obj = {
    couponCode: jspb.Message.getFieldWithDefault(msg, 1, ""),
    usesLimit: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.core.private.coupons.GetCouponResponse.MultiUse}
 */
proto.core.private.coupons.GetCouponResponse.MultiUse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.core.private.coupons.GetCouponResponse.MultiUse;
  return proto.core.private.coupons.GetCouponResponse.MultiUse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.core.private.coupons.GetCouponResponse.MultiUse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.core.private.coupons.GetCouponResponse.MultiUse}
 */
proto.core.private.coupons.GetCouponResponse.MultiUse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setCouponCode(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setUsesLimit(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.core.private.coupons.GetCouponResponse.MultiUse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.core.private.coupons.GetCouponResponse.MultiUse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.core.private.coupons.GetCouponResponse.MultiUse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.core.private.coupons.GetCouponResponse.MultiUse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCouponCode();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getUsesLimit();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
};


/**
 * optional string coupon_code = 1;
 * @return {string}
 */
proto.core.private.coupons.GetCouponResponse.MultiUse.prototype.getCouponCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.core.private.coupons.GetCouponResponse.MultiUse} returns this
 */
proto.core.private.coupons.GetCouponResponse.MultiUse.prototype.setCouponCode = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int32 uses_limit = 2;
 * @return {number}
 */
proto.core.private.coupons.GetCouponResponse.MultiUse.prototype.getUsesLimit = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.core.private.coupons.GetCouponResponse.MultiUse} returns this
 */
proto.core.private.coupons.GetCouponResponse.MultiUse.prototype.setUsesLimit = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.core.private.coupons.GetCouponResponse.FixedTermDiscountLength.prototype.toObject = function(opt_includeInstance) {
  return proto.core.private.coupons.GetCouponResponse.FixedTermDiscountLength.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.core.private.coupons.GetCouponResponse.FixedTermDiscountLength} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.core.private.coupons.GetCouponResponse.FixedTermDiscountLength.toObject = function(includeInstance, msg) {
  var f, obj = {
    frequency: jspb.Message.getFieldWithDefault(msg, 1, 0),
    frequencyType: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.core.private.coupons.GetCouponResponse.FixedTermDiscountLength}
 */
proto.core.private.coupons.GetCouponResponse.FixedTermDiscountLength.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.core.private.coupons.GetCouponResponse.FixedTermDiscountLength;
  return proto.core.private.coupons.GetCouponResponse.FixedTermDiscountLength.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.core.private.coupons.GetCouponResponse.FixedTermDiscountLength} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.core.private.coupons.GetCouponResponse.FixedTermDiscountLength}
 */
proto.core.private.coupons.GetCouponResponse.FixedTermDiscountLength.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setFrequency(value);
      break;
    case 2:
      var value = /** @type {!proto.core.private.coupons.FrequencyType} */ (reader.readEnum());
      msg.setFrequencyType(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.core.private.coupons.GetCouponResponse.FixedTermDiscountLength.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.core.private.coupons.GetCouponResponse.FixedTermDiscountLength.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.core.private.coupons.GetCouponResponse.FixedTermDiscountLength} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.core.private.coupons.GetCouponResponse.FixedTermDiscountLength.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFrequency();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getFrequencyType();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
};


/**
 * optional int32 frequency = 1;
 * @return {number}
 */
proto.core.private.coupons.GetCouponResponse.FixedTermDiscountLength.prototype.getFrequency = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.core.private.coupons.GetCouponResponse.FixedTermDiscountLength} returns this
 */
proto.core.private.coupons.GetCouponResponse.FixedTermDiscountLength.prototype.setFrequency = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional FrequencyType frequency_type = 2;
 * @return {!proto.core.private.coupons.FrequencyType}
 */
proto.core.private.coupons.GetCouponResponse.FixedTermDiscountLength.prototype.getFrequencyType = function() {
  return /** @type {!proto.core.private.coupons.FrequencyType} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.core.private.coupons.FrequencyType} value
 * @return {!proto.core.private.coupons.GetCouponResponse.FixedTermDiscountLength} returns this
 */
proto.core.private.coupons.GetCouponResponse.FixedTermDiscountLength.prototype.setFrequencyType = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};


/**
 * optional int32 coupon_id = 1;
 * @return {number}
 */
proto.core.private.coupons.GetCouponResponse.prototype.getCouponId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.core.private.coupons.GetCouponResponse} returns this
 */
proto.core.private.coupons.GetCouponResponse.prototype.setCouponId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.core.private.coupons.GetCouponResponse.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.core.private.coupons.GetCouponResponse} returns this
 */
proto.core.private.coupons.GetCouponResponse.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional CouponType coupon_type = 3;
 * @return {!proto.core.private.coupons.GetCouponResponse.CouponType}
 */
proto.core.private.coupons.GetCouponResponse.prototype.getCouponType = function() {
  return /** @type {!proto.core.private.coupons.GetCouponResponse.CouponType} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {!proto.core.private.coupons.GetCouponResponse.CouponType} value
 * @return {!proto.core.private.coupons.GetCouponResponse} returns this
 */
proto.core.private.coupons.GetCouponResponse.prototype.setCouponType = function(value) {
  return jspb.Message.setProto3EnumField(this, 3, value);
};


/**
 * optional EligibleCustomers eligible_customers = 4;
 * @return {!proto.core.private.coupons.EligibleCustomers}
 */
proto.core.private.coupons.GetCouponResponse.prototype.getEligibleCustomers = function() {
  return /** @type {!proto.core.private.coupons.EligibleCustomers} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {!proto.core.private.coupons.EligibleCustomers} value
 * @return {!proto.core.private.coupons.GetCouponResponse} returns this
 */
proto.core.private.coupons.GetCouponResponse.prototype.setEligibleCustomers = function(value) {
  return jspb.Message.setProto3EnumField(this, 4, value);
};


/**
 * optional bool is_infinite = 5;
 * @return {boolean}
 */
proto.core.private.coupons.GetCouponResponse.prototype.getIsInfinite = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 5, false));
};


/**
 * @param {boolean} value
 * @return {!proto.core.private.coupons.GetCouponResponse} returns this
 */
proto.core.private.coupons.GetCouponResponse.prototype.setIsInfinite = function(value) {
  return jspb.Message.setOneofField(this, 5, proto.core.private.coupons.GetCouponResponse.oneofGroups_[0], value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.core.private.coupons.GetCouponResponse} returns this
 */
proto.core.private.coupons.GetCouponResponse.prototype.clearIsInfinite = function() {
  return jspb.Message.setOneofField(this, 5, proto.core.private.coupons.GetCouponResponse.oneofGroups_[0], undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.core.private.coupons.GetCouponResponse.prototype.hasIsInfinite = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional google.protobuf.Timestamp expires_on = 6;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.core.private.coupons.GetCouponResponse.prototype.getExpiresOn = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 6));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.core.private.coupons.GetCouponResponse} returns this
*/
proto.core.private.coupons.GetCouponResponse.prototype.setExpiresOn = function(value) {
  return jspb.Message.setOneofWrapperField(this, 6, proto.core.private.coupons.GetCouponResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.core.private.coupons.GetCouponResponse} returns this
 */
proto.core.private.coupons.GetCouponResponse.prototype.clearExpiresOn = function() {
  return this.setExpiresOn(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.core.private.coupons.GetCouponResponse.prototype.hasExpiresOn = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional bool is_life_time = 7;
 * @return {boolean}
 */
proto.core.private.coupons.GetCouponResponse.prototype.getIsLifeTime = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 7, false));
};


/**
 * @param {boolean} value
 * @return {!proto.core.private.coupons.GetCouponResponse} returns this
 */
proto.core.private.coupons.GetCouponResponse.prototype.setIsLifeTime = function(value) {
  return jspb.Message.setOneofField(this, 7, proto.core.private.coupons.GetCouponResponse.oneofGroups_[1], value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.core.private.coupons.GetCouponResponse} returns this
 */
proto.core.private.coupons.GetCouponResponse.prototype.clearIsLifeTime = function() {
  return jspb.Message.setOneofField(this, 7, proto.core.private.coupons.GetCouponResponse.oneofGroups_[1], undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.core.private.coupons.GetCouponResponse.prototype.hasIsLifeTime = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional FixedTermDiscountLength fixed_term = 8;
 * @return {?proto.core.private.coupons.GetCouponResponse.FixedTermDiscountLength}
 */
proto.core.private.coupons.GetCouponResponse.prototype.getFixedTerm = function() {
  return /** @type{?proto.core.private.coupons.GetCouponResponse.FixedTermDiscountLength} */ (
    jspb.Message.getWrapperField(this, proto.core.private.coupons.GetCouponResponse.FixedTermDiscountLength, 8));
};


/**
 * @param {?proto.core.private.coupons.GetCouponResponse.FixedTermDiscountLength|undefined} value
 * @return {!proto.core.private.coupons.GetCouponResponse} returns this
*/
proto.core.private.coupons.GetCouponResponse.prototype.setFixedTerm = function(value) {
  return jspb.Message.setOneofWrapperField(this, 8, proto.core.private.coupons.GetCouponResponse.oneofGroups_[1], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.core.private.coupons.GetCouponResponse} returns this
 */
proto.core.private.coupons.GetCouponResponse.prototype.clearFixedTerm = function() {
  return this.setFixedTerm(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.core.private.coupons.GetCouponResponse.prototype.hasFixedTerm = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * optional SingleUse single_use = 9;
 * @return {?proto.core.private.coupons.GetCouponResponse.SingleUse}
 */
proto.core.private.coupons.GetCouponResponse.prototype.getSingleUse = function() {
  return /** @type{?proto.core.private.coupons.GetCouponResponse.SingleUse} */ (
    jspb.Message.getWrapperField(this, proto.core.private.coupons.GetCouponResponse.SingleUse, 9));
};


/**
 * @param {?proto.core.private.coupons.GetCouponResponse.SingleUse|undefined} value
 * @return {!proto.core.private.coupons.GetCouponResponse} returns this
*/
proto.core.private.coupons.GetCouponResponse.prototype.setSingleUse = function(value) {
  return jspb.Message.setOneofWrapperField(this, 9, proto.core.private.coupons.GetCouponResponse.oneofGroups_[2], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.core.private.coupons.GetCouponResponse} returns this
 */
proto.core.private.coupons.GetCouponResponse.prototype.clearSingleUse = function() {
  return this.setSingleUse(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.core.private.coupons.GetCouponResponse.prototype.hasSingleUse = function() {
  return jspb.Message.getField(this, 9) != null;
};


/**
 * optional MultiUse multi_use = 10;
 * @return {?proto.core.private.coupons.GetCouponResponse.MultiUse}
 */
proto.core.private.coupons.GetCouponResponse.prototype.getMultiUse = function() {
  return /** @type{?proto.core.private.coupons.GetCouponResponse.MultiUse} */ (
    jspb.Message.getWrapperField(this, proto.core.private.coupons.GetCouponResponse.MultiUse, 10));
};


/**
 * @param {?proto.core.private.coupons.GetCouponResponse.MultiUse|undefined} value
 * @return {!proto.core.private.coupons.GetCouponResponse} returns this
*/
proto.core.private.coupons.GetCouponResponse.prototype.setMultiUse = function(value) {
  return jspb.Message.setOneofWrapperField(this, 10, proto.core.private.coupons.GetCouponResponse.oneofGroups_[2], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.core.private.coupons.GetCouponResponse} returns this
 */
proto.core.private.coupons.GetCouponResponse.prototype.clearMultiUse = function() {
  return this.setMultiUse(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.core.private.coupons.GetCouponResponse.prototype.hasMultiUse = function() {
  return jspb.Message.getField(this, 10) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.core.private.coupons.ExpireCouponRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.core.private.coupons.ExpireCouponRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.core.private.coupons.ExpireCouponRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.core.private.coupons.ExpireCouponRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    companyId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    couponId: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.core.private.coupons.ExpireCouponRequest}
 */
proto.core.private.coupons.ExpireCouponRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.core.private.coupons.ExpireCouponRequest;
  return proto.core.private.coupons.ExpireCouponRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.core.private.coupons.ExpireCouponRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.core.private.coupons.ExpireCouponRequest}
 */
proto.core.private.coupons.ExpireCouponRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setCompanyId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setCouponId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.core.private.coupons.ExpireCouponRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.core.private.coupons.ExpireCouponRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.core.private.coupons.ExpireCouponRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.core.private.coupons.ExpireCouponRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCompanyId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getCouponId();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
};


/**
 * optional int32 company_id = 1;
 * @return {number}
 */
proto.core.private.coupons.ExpireCouponRequest.prototype.getCompanyId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.core.private.coupons.ExpireCouponRequest} returns this
 */
proto.core.private.coupons.ExpireCouponRequest.prototype.setCompanyId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int32 coupon_id = 2;
 * @return {number}
 */
proto.core.private.coupons.ExpireCouponRequest.prototype.getCouponId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.core.private.coupons.ExpireCouponRequest} returns this
 */
proto.core.private.coupons.ExpireCouponRequest.prototype.setCouponId = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.core.private.coupons.ExpireCouponResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.core.private.coupons.ExpireCouponResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.core.private.coupons.ExpireCouponResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.core.private.coupons.ExpireCouponResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    isSuccess: jspb.Message.getBooleanFieldWithDefault(msg, 1, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.core.private.coupons.ExpireCouponResponse}
 */
proto.core.private.coupons.ExpireCouponResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.core.private.coupons.ExpireCouponResponse;
  return proto.core.private.coupons.ExpireCouponResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.core.private.coupons.ExpireCouponResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.core.private.coupons.ExpireCouponResponse}
 */
proto.core.private.coupons.ExpireCouponResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsSuccess(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.core.private.coupons.ExpireCouponResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.core.private.coupons.ExpireCouponResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.core.private.coupons.ExpireCouponResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.core.private.coupons.ExpireCouponResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getIsSuccess();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
};


/**
 * optional bool is_success = 1;
 * @return {boolean}
 */
proto.core.private.coupons.ExpireCouponResponse.prototype.getIsSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.core.private.coupons.ExpireCouponResponse} returns this
 */
proto.core.private.coupons.ExpireCouponResponse.prototype.setIsSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.core.private.coupons.UpdateCouponRequest.oneofGroups_ = [[6,7],[8,9],[10,11]];

/**
 * @enum {number}
 */
proto.core.private.coupons.UpdateCouponRequest.CouponTypeCase = {
  COUPON_TYPE_NOT_SET: 0,
  SINGLE_USE: 6,
  MULTI_USE: 7
};

/**
 * @return {proto.core.private.coupons.UpdateCouponRequest.CouponTypeCase}
 */
proto.core.private.coupons.UpdateCouponRequest.prototype.getCouponTypeCase = function() {
  return /** @type {proto.core.private.coupons.UpdateCouponRequest.CouponTypeCase} */(jspb.Message.computeOneofCase(this, proto.core.private.coupons.UpdateCouponRequest.oneofGroups_[0]));
};

/**
 * @enum {number}
 */
proto.core.private.coupons.UpdateCouponRequest.ExpirationCase = {
  EXPIRATION_NOT_SET: 0,
  IS_INFINITE: 8,
  EXPIRES_ON: 9
};

/**
 * @return {proto.core.private.coupons.UpdateCouponRequest.ExpirationCase}
 */
proto.core.private.coupons.UpdateCouponRequest.prototype.getExpirationCase = function() {
  return /** @type {proto.core.private.coupons.UpdateCouponRequest.ExpirationCase} */(jspb.Message.computeOneofCase(this, proto.core.private.coupons.UpdateCouponRequest.oneofGroups_[1]));
};

/**
 * @enum {number}
 */
proto.core.private.coupons.UpdateCouponRequest.DiscountLengthCase = {
  DISCOUNT_LENGTH_NOT_SET: 0,
  IS_LIFE_TIME: 10,
  FIXED_TERM: 11
};

/**
 * @return {proto.core.private.coupons.UpdateCouponRequest.DiscountLengthCase}
 */
proto.core.private.coupons.UpdateCouponRequest.prototype.getDiscountLengthCase = function() {
  return /** @type {proto.core.private.coupons.UpdateCouponRequest.DiscountLengthCase} */(jspb.Message.computeOneofCase(this, proto.core.private.coupons.UpdateCouponRequest.oneofGroups_[2]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.core.private.coupons.UpdateCouponRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.core.private.coupons.UpdateCouponRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.core.private.coupons.UpdateCouponRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.core.private.coupons.UpdateCouponRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    companyId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    couponId: jspb.Message.getFieldWithDefault(msg, 2, 0),
    name: jspb.Message.getFieldWithDefault(msg, 3, ""),
    eligibleCustomers: jspb.Message.getFieldWithDefault(msg, 5, 0),
    singleUse: (f = msg.getSingleUse()) && proto.core.private.coupons.UpdateCouponRequest.UpdateSingleUse.toObject(includeInstance, f),
    multiUse: (f = msg.getMultiUse()) && proto.core.private.coupons.UpdateCouponRequest.UpdateMultiUse.toObject(includeInstance, f),
    isInfinite: jspb.Message.getBooleanFieldWithDefault(msg, 8, false),
    expiresOn: (f = msg.getExpiresOn()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    isLifeTime: jspb.Message.getBooleanFieldWithDefault(msg, 10, false),
    fixedTerm: (f = msg.getFixedTerm()) && proto.core.private.coupons.UpdateCouponRequest.FixedTermDiscountLength.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.core.private.coupons.UpdateCouponRequest}
 */
proto.core.private.coupons.UpdateCouponRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.core.private.coupons.UpdateCouponRequest;
  return proto.core.private.coupons.UpdateCouponRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.core.private.coupons.UpdateCouponRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.core.private.coupons.UpdateCouponRequest}
 */
proto.core.private.coupons.UpdateCouponRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setCompanyId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setCouponId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 5:
      var value = /** @type {!proto.core.private.coupons.EligibleCustomers} */ (reader.readEnum());
      msg.setEligibleCustomers(value);
      break;
    case 6:
      var value = new proto.core.private.coupons.UpdateCouponRequest.UpdateSingleUse;
      reader.readMessage(value,proto.core.private.coupons.UpdateCouponRequest.UpdateSingleUse.deserializeBinaryFromReader);
      msg.setSingleUse(value);
      break;
    case 7:
      var value = new proto.core.private.coupons.UpdateCouponRequest.UpdateMultiUse;
      reader.readMessage(value,proto.core.private.coupons.UpdateCouponRequest.UpdateMultiUse.deserializeBinaryFromReader);
      msg.setMultiUse(value);
      break;
    case 8:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsInfinite(value);
      break;
    case 9:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setExpiresOn(value);
      break;
    case 10:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsLifeTime(value);
      break;
    case 11:
      var value = new proto.core.private.coupons.UpdateCouponRequest.FixedTermDiscountLength;
      reader.readMessage(value,proto.core.private.coupons.UpdateCouponRequest.FixedTermDiscountLength.deserializeBinaryFromReader);
      msg.setFixedTerm(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.core.private.coupons.UpdateCouponRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.core.private.coupons.UpdateCouponRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.core.private.coupons.UpdateCouponRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.core.private.coupons.UpdateCouponRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCompanyId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getCouponId();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getEligibleCustomers();
  if (f !== 0.0) {
    writer.writeEnum(
      5,
      f
    );
  }
  f = message.getSingleUse();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      proto.core.private.coupons.UpdateCouponRequest.UpdateSingleUse.serializeBinaryToWriter
    );
  }
  f = message.getMultiUse();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      proto.core.private.coupons.UpdateCouponRequest.UpdateMultiUse.serializeBinaryToWriter
    );
  }
  f = /** @type {boolean} */ (jspb.Message.getField(message, 8));
  if (f != null) {
    writer.writeBool(
      8,
      f
    );
  }
  f = message.getExpiresOn();
  if (f != null) {
    writer.writeMessage(
      9,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = /** @type {boolean} */ (jspb.Message.getField(message, 10));
  if (f != null) {
    writer.writeBool(
      10,
      f
    );
  }
  f = message.getFixedTerm();
  if (f != null) {
    writer.writeMessage(
      11,
      f,
      proto.core.private.coupons.UpdateCouponRequest.FixedTermDiscountLength.serializeBinaryToWriter
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.core.private.coupons.UpdateCouponRequest.FixedTermDiscountLength.prototype.toObject = function(opt_includeInstance) {
  return proto.core.private.coupons.UpdateCouponRequest.FixedTermDiscountLength.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.core.private.coupons.UpdateCouponRequest.FixedTermDiscountLength} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.core.private.coupons.UpdateCouponRequest.FixedTermDiscountLength.toObject = function(includeInstance, msg) {
  var f, obj = {
    frequency: jspb.Message.getFieldWithDefault(msg, 1, 0),
    frequencyType: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.core.private.coupons.UpdateCouponRequest.FixedTermDiscountLength}
 */
proto.core.private.coupons.UpdateCouponRequest.FixedTermDiscountLength.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.core.private.coupons.UpdateCouponRequest.FixedTermDiscountLength;
  return proto.core.private.coupons.UpdateCouponRequest.FixedTermDiscountLength.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.core.private.coupons.UpdateCouponRequest.FixedTermDiscountLength} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.core.private.coupons.UpdateCouponRequest.FixedTermDiscountLength}
 */
proto.core.private.coupons.UpdateCouponRequest.FixedTermDiscountLength.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setFrequency(value);
      break;
    case 2:
      var value = /** @type {!proto.core.private.coupons.FrequencyType} */ (reader.readEnum());
      msg.setFrequencyType(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.core.private.coupons.UpdateCouponRequest.FixedTermDiscountLength.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.core.private.coupons.UpdateCouponRequest.FixedTermDiscountLength.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.core.private.coupons.UpdateCouponRequest.FixedTermDiscountLength} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.core.private.coupons.UpdateCouponRequest.FixedTermDiscountLength.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFrequency();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getFrequencyType();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
};


/**
 * optional int32 frequency = 1;
 * @return {number}
 */
proto.core.private.coupons.UpdateCouponRequest.FixedTermDiscountLength.prototype.getFrequency = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.core.private.coupons.UpdateCouponRequest.FixedTermDiscountLength} returns this
 */
proto.core.private.coupons.UpdateCouponRequest.FixedTermDiscountLength.prototype.setFrequency = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional FrequencyType frequency_type = 2;
 * @return {!proto.core.private.coupons.FrequencyType}
 */
proto.core.private.coupons.UpdateCouponRequest.FixedTermDiscountLength.prototype.getFrequencyType = function() {
  return /** @type {!proto.core.private.coupons.FrequencyType} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.core.private.coupons.FrequencyType} value
 * @return {!proto.core.private.coupons.UpdateCouponRequest.FixedTermDiscountLength} returns this
 */
proto.core.private.coupons.UpdateCouponRequest.FixedTermDiscountLength.prototype.setFrequencyType = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.core.private.coupons.UpdateCouponRequest.UpdateSingleUse.prototype.toObject = function(opt_includeInstance) {
  return proto.core.private.coupons.UpdateCouponRequest.UpdateSingleUse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.core.private.coupons.UpdateCouponRequest.UpdateSingleUse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.core.private.coupons.UpdateCouponRequest.UpdateSingleUse.toObject = function(includeInstance, msg) {
  var f, obj = {
    additionalAmountToGenerate: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.core.private.coupons.UpdateCouponRequest.UpdateSingleUse}
 */
proto.core.private.coupons.UpdateCouponRequest.UpdateSingleUse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.core.private.coupons.UpdateCouponRequest.UpdateSingleUse;
  return proto.core.private.coupons.UpdateCouponRequest.UpdateSingleUse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.core.private.coupons.UpdateCouponRequest.UpdateSingleUse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.core.private.coupons.UpdateCouponRequest.UpdateSingleUse}
 */
proto.core.private.coupons.UpdateCouponRequest.UpdateSingleUse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setAdditionalAmountToGenerate(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.core.private.coupons.UpdateCouponRequest.UpdateSingleUse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.core.private.coupons.UpdateCouponRequest.UpdateSingleUse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.core.private.coupons.UpdateCouponRequest.UpdateSingleUse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.core.private.coupons.UpdateCouponRequest.UpdateSingleUse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAdditionalAmountToGenerate();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
};


/**
 * optional int32 additional_amount_to_generate = 1;
 * @return {number}
 */
proto.core.private.coupons.UpdateCouponRequest.UpdateSingleUse.prototype.getAdditionalAmountToGenerate = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.core.private.coupons.UpdateCouponRequest.UpdateSingleUse} returns this
 */
proto.core.private.coupons.UpdateCouponRequest.UpdateSingleUse.prototype.setAdditionalAmountToGenerate = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.core.private.coupons.UpdateCouponRequest.UpdateMultiUse.prototype.toObject = function(opt_includeInstance) {
  return proto.core.private.coupons.UpdateCouponRequest.UpdateMultiUse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.core.private.coupons.UpdateCouponRequest.UpdateMultiUse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.core.private.coupons.UpdateCouponRequest.UpdateMultiUse.toObject = function(includeInstance, msg) {
  var f, obj = {
    couponCode: jspb.Message.getFieldWithDefault(msg, 1, ""),
    usesLimit: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.core.private.coupons.UpdateCouponRequest.UpdateMultiUse}
 */
proto.core.private.coupons.UpdateCouponRequest.UpdateMultiUse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.core.private.coupons.UpdateCouponRequest.UpdateMultiUse;
  return proto.core.private.coupons.UpdateCouponRequest.UpdateMultiUse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.core.private.coupons.UpdateCouponRequest.UpdateMultiUse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.core.private.coupons.UpdateCouponRequest.UpdateMultiUse}
 */
proto.core.private.coupons.UpdateCouponRequest.UpdateMultiUse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setCouponCode(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setUsesLimit(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.core.private.coupons.UpdateCouponRequest.UpdateMultiUse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.core.private.coupons.UpdateCouponRequest.UpdateMultiUse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.core.private.coupons.UpdateCouponRequest.UpdateMultiUse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.core.private.coupons.UpdateCouponRequest.UpdateMultiUse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCouponCode();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getUsesLimit();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
};


/**
 * optional string coupon_code = 1;
 * @return {string}
 */
proto.core.private.coupons.UpdateCouponRequest.UpdateMultiUse.prototype.getCouponCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.core.private.coupons.UpdateCouponRequest.UpdateMultiUse} returns this
 */
proto.core.private.coupons.UpdateCouponRequest.UpdateMultiUse.prototype.setCouponCode = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int32 uses_limit = 2;
 * @return {number}
 */
proto.core.private.coupons.UpdateCouponRequest.UpdateMultiUse.prototype.getUsesLimit = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.core.private.coupons.UpdateCouponRequest.UpdateMultiUse} returns this
 */
proto.core.private.coupons.UpdateCouponRequest.UpdateMultiUse.prototype.setUsesLimit = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int32 company_id = 1;
 * @return {number}
 */
proto.core.private.coupons.UpdateCouponRequest.prototype.getCompanyId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.core.private.coupons.UpdateCouponRequest} returns this
 */
proto.core.private.coupons.UpdateCouponRequest.prototype.setCompanyId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int32 coupon_id = 2;
 * @return {number}
 */
proto.core.private.coupons.UpdateCouponRequest.prototype.getCouponId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.core.private.coupons.UpdateCouponRequest} returns this
 */
proto.core.private.coupons.UpdateCouponRequest.prototype.setCouponId = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional string name = 3;
 * @return {string}
 */
proto.core.private.coupons.UpdateCouponRequest.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.core.private.coupons.UpdateCouponRequest} returns this
 */
proto.core.private.coupons.UpdateCouponRequest.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional EligibleCustomers eligible_customers = 5;
 * @return {!proto.core.private.coupons.EligibleCustomers}
 */
proto.core.private.coupons.UpdateCouponRequest.prototype.getEligibleCustomers = function() {
  return /** @type {!proto.core.private.coupons.EligibleCustomers} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {!proto.core.private.coupons.EligibleCustomers} value
 * @return {!proto.core.private.coupons.UpdateCouponRequest} returns this
 */
proto.core.private.coupons.UpdateCouponRequest.prototype.setEligibleCustomers = function(value) {
  return jspb.Message.setProto3EnumField(this, 5, value);
};


/**
 * optional UpdateSingleUse single_use = 6;
 * @return {?proto.core.private.coupons.UpdateCouponRequest.UpdateSingleUse}
 */
proto.core.private.coupons.UpdateCouponRequest.prototype.getSingleUse = function() {
  return /** @type{?proto.core.private.coupons.UpdateCouponRequest.UpdateSingleUse} */ (
    jspb.Message.getWrapperField(this, proto.core.private.coupons.UpdateCouponRequest.UpdateSingleUse, 6));
};


/**
 * @param {?proto.core.private.coupons.UpdateCouponRequest.UpdateSingleUse|undefined} value
 * @return {!proto.core.private.coupons.UpdateCouponRequest} returns this
*/
proto.core.private.coupons.UpdateCouponRequest.prototype.setSingleUse = function(value) {
  return jspb.Message.setOneofWrapperField(this, 6, proto.core.private.coupons.UpdateCouponRequest.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.core.private.coupons.UpdateCouponRequest} returns this
 */
proto.core.private.coupons.UpdateCouponRequest.prototype.clearSingleUse = function() {
  return this.setSingleUse(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.core.private.coupons.UpdateCouponRequest.prototype.hasSingleUse = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional UpdateMultiUse multi_use = 7;
 * @return {?proto.core.private.coupons.UpdateCouponRequest.UpdateMultiUse}
 */
proto.core.private.coupons.UpdateCouponRequest.prototype.getMultiUse = function() {
  return /** @type{?proto.core.private.coupons.UpdateCouponRequest.UpdateMultiUse} */ (
    jspb.Message.getWrapperField(this, proto.core.private.coupons.UpdateCouponRequest.UpdateMultiUse, 7));
};


/**
 * @param {?proto.core.private.coupons.UpdateCouponRequest.UpdateMultiUse|undefined} value
 * @return {!proto.core.private.coupons.UpdateCouponRequest} returns this
*/
proto.core.private.coupons.UpdateCouponRequest.prototype.setMultiUse = function(value) {
  return jspb.Message.setOneofWrapperField(this, 7, proto.core.private.coupons.UpdateCouponRequest.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.core.private.coupons.UpdateCouponRequest} returns this
 */
proto.core.private.coupons.UpdateCouponRequest.prototype.clearMultiUse = function() {
  return this.setMultiUse(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.core.private.coupons.UpdateCouponRequest.prototype.hasMultiUse = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional bool is_infinite = 8;
 * @return {boolean}
 */
proto.core.private.coupons.UpdateCouponRequest.prototype.getIsInfinite = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 8, false));
};


/**
 * @param {boolean} value
 * @return {!proto.core.private.coupons.UpdateCouponRequest} returns this
 */
proto.core.private.coupons.UpdateCouponRequest.prototype.setIsInfinite = function(value) {
  return jspb.Message.setOneofField(this, 8, proto.core.private.coupons.UpdateCouponRequest.oneofGroups_[1], value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.core.private.coupons.UpdateCouponRequest} returns this
 */
proto.core.private.coupons.UpdateCouponRequest.prototype.clearIsInfinite = function() {
  return jspb.Message.setOneofField(this, 8, proto.core.private.coupons.UpdateCouponRequest.oneofGroups_[1], undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.core.private.coupons.UpdateCouponRequest.prototype.hasIsInfinite = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * optional google.protobuf.Timestamp expires_on = 9;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.core.private.coupons.UpdateCouponRequest.prototype.getExpiresOn = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 9));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.core.private.coupons.UpdateCouponRequest} returns this
*/
proto.core.private.coupons.UpdateCouponRequest.prototype.setExpiresOn = function(value) {
  return jspb.Message.setOneofWrapperField(this, 9, proto.core.private.coupons.UpdateCouponRequest.oneofGroups_[1], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.core.private.coupons.UpdateCouponRequest} returns this
 */
proto.core.private.coupons.UpdateCouponRequest.prototype.clearExpiresOn = function() {
  return this.setExpiresOn(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.core.private.coupons.UpdateCouponRequest.prototype.hasExpiresOn = function() {
  return jspb.Message.getField(this, 9) != null;
};


/**
 * optional bool is_life_time = 10;
 * @return {boolean}
 */
proto.core.private.coupons.UpdateCouponRequest.prototype.getIsLifeTime = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 10, false));
};


/**
 * @param {boolean} value
 * @return {!proto.core.private.coupons.UpdateCouponRequest} returns this
 */
proto.core.private.coupons.UpdateCouponRequest.prototype.setIsLifeTime = function(value) {
  return jspb.Message.setOneofField(this, 10, proto.core.private.coupons.UpdateCouponRequest.oneofGroups_[2], value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.core.private.coupons.UpdateCouponRequest} returns this
 */
proto.core.private.coupons.UpdateCouponRequest.prototype.clearIsLifeTime = function() {
  return jspb.Message.setOneofField(this, 10, proto.core.private.coupons.UpdateCouponRequest.oneofGroups_[2], undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.core.private.coupons.UpdateCouponRequest.prototype.hasIsLifeTime = function() {
  return jspb.Message.getField(this, 10) != null;
};


/**
 * optional FixedTermDiscountLength fixed_term = 11;
 * @return {?proto.core.private.coupons.UpdateCouponRequest.FixedTermDiscountLength}
 */
proto.core.private.coupons.UpdateCouponRequest.prototype.getFixedTerm = function() {
  return /** @type{?proto.core.private.coupons.UpdateCouponRequest.FixedTermDiscountLength} */ (
    jspb.Message.getWrapperField(this, proto.core.private.coupons.UpdateCouponRequest.FixedTermDiscountLength, 11));
};


/**
 * @param {?proto.core.private.coupons.UpdateCouponRequest.FixedTermDiscountLength|undefined} value
 * @return {!proto.core.private.coupons.UpdateCouponRequest} returns this
*/
proto.core.private.coupons.UpdateCouponRequest.prototype.setFixedTerm = function(value) {
  return jspb.Message.setOneofWrapperField(this, 11, proto.core.private.coupons.UpdateCouponRequest.oneofGroups_[2], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.core.private.coupons.UpdateCouponRequest} returns this
 */
proto.core.private.coupons.UpdateCouponRequest.prototype.clearFixedTerm = function() {
  return this.setFixedTerm(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.core.private.coupons.UpdateCouponRequest.prototype.hasFixedTerm = function() {
  return jspb.Message.getField(this, 11) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.core.private.coupons.UpdateCouponResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.core.private.coupons.UpdateCouponResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.core.private.coupons.UpdateCouponResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.core.private.coupons.UpdateCouponResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    isSuccess: jspb.Message.getBooleanFieldWithDefault(msg, 1, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.core.private.coupons.UpdateCouponResponse}
 */
proto.core.private.coupons.UpdateCouponResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.core.private.coupons.UpdateCouponResponse;
  return proto.core.private.coupons.UpdateCouponResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.core.private.coupons.UpdateCouponResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.core.private.coupons.UpdateCouponResponse}
 */
proto.core.private.coupons.UpdateCouponResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsSuccess(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.core.private.coupons.UpdateCouponResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.core.private.coupons.UpdateCouponResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.core.private.coupons.UpdateCouponResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.core.private.coupons.UpdateCouponResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getIsSuccess();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
};


/**
 * optional bool is_success = 1;
 * @return {boolean}
 */
proto.core.private.coupons.UpdateCouponResponse.prototype.getIsSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.core.private.coupons.UpdateCouponResponse} returns this
 */
proto.core.private.coupons.UpdateCouponResponse.prototype.setIsSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.core.private.coupons.CreateCouponRequest.oneofGroups_ = [[5,6],[7,8],[9,10]];

/**
 * @enum {number}
 */
proto.core.private.coupons.CreateCouponRequest.CouponTypeCase = {
  COUPON_TYPE_NOT_SET: 0,
  SINGLE_USE: 5,
  MULTI_USE: 6
};

/**
 * @return {proto.core.private.coupons.CreateCouponRequest.CouponTypeCase}
 */
proto.core.private.coupons.CreateCouponRequest.prototype.getCouponTypeCase = function() {
  return /** @type {proto.core.private.coupons.CreateCouponRequest.CouponTypeCase} */(jspb.Message.computeOneofCase(this, proto.core.private.coupons.CreateCouponRequest.oneofGroups_[0]));
};

/**
 * @enum {number}
 */
proto.core.private.coupons.CreateCouponRequest.ExpirationCase = {
  EXPIRATION_NOT_SET: 0,
  IS_INFINITE: 7,
  EXPIRES_ON: 8
};

/**
 * @return {proto.core.private.coupons.CreateCouponRequest.ExpirationCase}
 */
proto.core.private.coupons.CreateCouponRequest.prototype.getExpirationCase = function() {
  return /** @type {proto.core.private.coupons.CreateCouponRequest.ExpirationCase} */(jspb.Message.computeOneofCase(this, proto.core.private.coupons.CreateCouponRequest.oneofGroups_[1]));
};

/**
 * @enum {number}
 */
proto.core.private.coupons.CreateCouponRequest.DiscountLengthCase = {
  DISCOUNT_LENGTH_NOT_SET: 0,
  IS_LIFETIME: 9,
  FIXED_TERM: 10
};

/**
 * @return {proto.core.private.coupons.CreateCouponRequest.DiscountLengthCase}
 */
proto.core.private.coupons.CreateCouponRequest.prototype.getDiscountLengthCase = function() {
  return /** @type {proto.core.private.coupons.CreateCouponRequest.DiscountLengthCase} */(jspb.Message.computeOneofCase(this, proto.core.private.coupons.CreateCouponRequest.oneofGroups_[2]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.core.private.coupons.CreateCouponRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.core.private.coupons.CreateCouponRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.core.private.coupons.CreateCouponRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.core.private.coupons.CreateCouponRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    companyId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    discountId: jspb.Message.getFieldWithDefault(msg, 2, 0),
    name: jspb.Message.getFieldWithDefault(msg, 3, ""),
    eligibleCustomers: jspb.Message.getFieldWithDefault(msg, 4, 0),
    singleUse: (f = msg.getSingleUse()) && proto.core.private.coupons.CreateCouponRequest.SingleUse.toObject(includeInstance, f),
    multiUse: (f = msg.getMultiUse()) && proto.core.private.coupons.CreateCouponRequest.MultiUse.toObject(includeInstance, f),
    isInfinite: jspb.Message.getBooleanFieldWithDefault(msg, 7, false),
    expiresOn: (f = msg.getExpiresOn()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    isLifetime: jspb.Message.getBooleanFieldWithDefault(msg, 9, false),
    fixedTerm: (f = msg.getFixedTerm()) && proto.core.private.coupons.CreateCouponRequest.FixedTermDiscountLength.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.core.private.coupons.CreateCouponRequest}
 */
proto.core.private.coupons.CreateCouponRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.core.private.coupons.CreateCouponRequest;
  return proto.core.private.coupons.CreateCouponRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.core.private.coupons.CreateCouponRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.core.private.coupons.CreateCouponRequest}
 */
proto.core.private.coupons.CreateCouponRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setCompanyId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setDiscountId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 4:
      var value = /** @type {!proto.core.private.coupons.EligibleCustomers} */ (reader.readEnum());
      msg.setEligibleCustomers(value);
      break;
    case 5:
      var value = new proto.core.private.coupons.CreateCouponRequest.SingleUse;
      reader.readMessage(value,proto.core.private.coupons.CreateCouponRequest.SingleUse.deserializeBinaryFromReader);
      msg.setSingleUse(value);
      break;
    case 6:
      var value = new proto.core.private.coupons.CreateCouponRequest.MultiUse;
      reader.readMessage(value,proto.core.private.coupons.CreateCouponRequest.MultiUse.deserializeBinaryFromReader);
      msg.setMultiUse(value);
      break;
    case 7:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsInfinite(value);
      break;
    case 8:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setExpiresOn(value);
      break;
    case 9:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsLifetime(value);
      break;
    case 10:
      var value = new proto.core.private.coupons.CreateCouponRequest.FixedTermDiscountLength;
      reader.readMessage(value,proto.core.private.coupons.CreateCouponRequest.FixedTermDiscountLength.deserializeBinaryFromReader);
      msg.setFixedTerm(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.core.private.coupons.CreateCouponRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.core.private.coupons.CreateCouponRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.core.private.coupons.CreateCouponRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.core.private.coupons.CreateCouponRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCompanyId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getDiscountId();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getEligibleCustomers();
  if (f !== 0.0) {
    writer.writeEnum(
      4,
      f
    );
  }
  f = message.getSingleUse();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      proto.core.private.coupons.CreateCouponRequest.SingleUse.serializeBinaryToWriter
    );
  }
  f = message.getMultiUse();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      proto.core.private.coupons.CreateCouponRequest.MultiUse.serializeBinaryToWriter
    );
  }
  f = /** @type {boolean} */ (jspb.Message.getField(message, 7));
  if (f != null) {
    writer.writeBool(
      7,
      f
    );
  }
  f = message.getExpiresOn();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = /** @type {boolean} */ (jspb.Message.getField(message, 9));
  if (f != null) {
    writer.writeBool(
      9,
      f
    );
  }
  f = message.getFixedTerm();
  if (f != null) {
    writer.writeMessage(
      10,
      f,
      proto.core.private.coupons.CreateCouponRequest.FixedTermDiscountLength.serializeBinaryToWriter
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.core.private.coupons.CreateCouponRequest.FixedTermDiscountLength.prototype.toObject = function(opt_includeInstance) {
  return proto.core.private.coupons.CreateCouponRequest.FixedTermDiscountLength.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.core.private.coupons.CreateCouponRequest.FixedTermDiscountLength} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.core.private.coupons.CreateCouponRequest.FixedTermDiscountLength.toObject = function(includeInstance, msg) {
  var f, obj = {
    frequency: jspb.Message.getFieldWithDefault(msg, 1, 0),
    frequencyType: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.core.private.coupons.CreateCouponRequest.FixedTermDiscountLength}
 */
proto.core.private.coupons.CreateCouponRequest.FixedTermDiscountLength.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.core.private.coupons.CreateCouponRequest.FixedTermDiscountLength;
  return proto.core.private.coupons.CreateCouponRequest.FixedTermDiscountLength.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.core.private.coupons.CreateCouponRequest.FixedTermDiscountLength} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.core.private.coupons.CreateCouponRequest.FixedTermDiscountLength}
 */
proto.core.private.coupons.CreateCouponRequest.FixedTermDiscountLength.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setFrequency(value);
      break;
    case 2:
      var value = /** @type {!proto.core.private.coupons.FrequencyType} */ (reader.readEnum());
      msg.setFrequencyType(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.core.private.coupons.CreateCouponRequest.FixedTermDiscountLength.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.core.private.coupons.CreateCouponRequest.FixedTermDiscountLength.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.core.private.coupons.CreateCouponRequest.FixedTermDiscountLength} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.core.private.coupons.CreateCouponRequest.FixedTermDiscountLength.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFrequency();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getFrequencyType();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
};


/**
 * optional int32 frequency = 1;
 * @return {number}
 */
proto.core.private.coupons.CreateCouponRequest.FixedTermDiscountLength.prototype.getFrequency = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.core.private.coupons.CreateCouponRequest.FixedTermDiscountLength} returns this
 */
proto.core.private.coupons.CreateCouponRequest.FixedTermDiscountLength.prototype.setFrequency = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional FrequencyType frequency_type = 2;
 * @return {!proto.core.private.coupons.FrequencyType}
 */
proto.core.private.coupons.CreateCouponRequest.FixedTermDiscountLength.prototype.getFrequencyType = function() {
  return /** @type {!proto.core.private.coupons.FrequencyType} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.core.private.coupons.FrequencyType} value
 * @return {!proto.core.private.coupons.CreateCouponRequest.FixedTermDiscountLength} returns this
 */
proto.core.private.coupons.CreateCouponRequest.FixedTermDiscountLength.prototype.setFrequencyType = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.core.private.coupons.CreateCouponRequest.SingleUse.prototype.toObject = function(opt_includeInstance) {
  return proto.core.private.coupons.CreateCouponRequest.SingleUse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.core.private.coupons.CreateCouponRequest.SingleUse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.core.private.coupons.CreateCouponRequest.SingleUse.toObject = function(includeInstance, msg) {
  var f, obj = {
    generateAmount: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.core.private.coupons.CreateCouponRequest.SingleUse}
 */
proto.core.private.coupons.CreateCouponRequest.SingleUse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.core.private.coupons.CreateCouponRequest.SingleUse;
  return proto.core.private.coupons.CreateCouponRequest.SingleUse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.core.private.coupons.CreateCouponRequest.SingleUse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.core.private.coupons.CreateCouponRequest.SingleUse}
 */
proto.core.private.coupons.CreateCouponRequest.SingleUse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setGenerateAmount(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.core.private.coupons.CreateCouponRequest.SingleUse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.core.private.coupons.CreateCouponRequest.SingleUse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.core.private.coupons.CreateCouponRequest.SingleUse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.core.private.coupons.CreateCouponRequest.SingleUse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getGenerateAmount();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
};


/**
 * optional int32 generate_amount = 1;
 * @return {number}
 */
proto.core.private.coupons.CreateCouponRequest.SingleUse.prototype.getGenerateAmount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.core.private.coupons.CreateCouponRequest.SingleUse} returns this
 */
proto.core.private.coupons.CreateCouponRequest.SingleUse.prototype.setGenerateAmount = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.core.private.coupons.CreateCouponRequest.MultiUse.prototype.toObject = function(opt_includeInstance) {
  return proto.core.private.coupons.CreateCouponRequest.MultiUse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.core.private.coupons.CreateCouponRequest.MultiUse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.core.private.coupons.CreateCouponRequest.MultiUse.toObject = function(includeInstance, msg) {
  var f, obj = {
    couponCode: jspb.Message.getFieldWithDefault(msg, 1, ""),
    usesLimit: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.core.private.coupons.CreateCouponRequest.MultiUse}
 */
proto.core.private.coupons.CreateCouponRequest.MultiUse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.core.private.coupons.CreateCouponRequest.MultiUse;
  return proto.core.private.coupons.CreateCouponRequest.MultiUse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.core.private.coupons.CreateCouponRequest.MultiUse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.core.private.coupons.CreateCouponRequest.MultiUse}
 */
proto.core.private.coupons.CreateCouponRequest.MultiUse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setCouponCode(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setUsesLimit(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.core.private.coupons.CreateCouponRequest.MultiUse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.core.private.coupons.CreateCouponRequest.MultiUse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.core.private.coupons.CreateCouponRequest.MultiUse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.core.private.coupons.CreateCouponRequest.MultiUse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCouponCode();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getUsesLimit();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
};


/**
 * optional string coupon_code = 1;
 * @return {string}
 */
proto.core.private.coupons.CreateCouponRequest.MultiUse.prototype.getCouponCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.core.private.coupons.CreateCouponRequest.MultiUse} returns this
 */
proto.core.private.coupons.CreateCouponRequest.MultiUse.prototype.setCouponCode = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int32 uses_limit = 2;
 * @return {number}
 */
proto.core.private.coupons.CreateCouponRequest.MultiUse.prototype.getUsesLimit = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.core.private.coupons.CreateCouponRequest.MultiUse} returns this
 */
proto.core.private.coupons.CreateCouponRequest.MultiUse.prototype.setUsesLimit = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int32 company_id = 1;
 * @return {number}
 */
proto.core.private.coupons.CreateCouponRequest.prototype.getCompanyId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.core.private.coupons.CreateCouponRequest} returns this
 */
proto.core.private.coupons.CreateCouponRequest.prototype.setCompanyId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int32 discount_id = 2;
 * @return {number}
 */
proto.core.private.coupons.CreateCouponRequest.prototype.getDiscountId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.core.private.coupons.CreateCouponRequest} returns this
 */
proto.core.private.coupons.CreateCouponRequest.prototype.setDiscountId = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional string name = 3;
 * @return {string}
 */
proto.core.private.coupons.CreateCouponRequest.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.core.private.coupons.CreateCouponRequest} returns this
 */
proto.core.private.coupons.CreateCouponRequest.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional EligibleCustomers eligible_customers = 4;
 * @return {!proto.core.private.coupons.EligibleCustomers}
 */
proto.core.private.coupons.CreateCouponRequest.prototype.getEligibleCustomers = function() {
  return /** @type {!proto.core.private.coupons.EligibleCustomers} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {!proto.core.private.coupons.EligibleCustomers} value
 * @return {!proto.core.private.coupons.CreateCouponRequest} returns this
 */
proto.core.private.coupons.CreateCouponRequest.prototype.setEligibleCustomers = function(value) {
  return jspb.Message.setProto3EnumField(this, 4, value);
};


/**
 * optional SingleUse single_use = 5;
 * @return {?proto.core.private.coupons.CreateCouponRequest.SingleUse}
 */
proto.core.private.coupons.CreateCouponRequest.prototype.getSingleUse = function() {
  return /** @type{?proto.core.private.coupons.CreateCouponRequest.SingleUse} */ (
    jspb.Message.getWrapperField(this, proto.core.private.coupons.CreateCouponRequest.SingleUse, 5));
};


/**
 * @param {?proto.core.private.coupons.CreateCouponRequest.SingleUse|undefined} value
 * @return {!proto.core.private.coupons.CreateCouponRequest} returns this
*/
proto.core.private.coupons.CreateCouponRequest.prototype.setSingleUse = function(value) {
  return jspb.Message.setOneofWrapperField(this, 5, proto.core.private.coupons.CreateCouponRequest.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.core.private.coupons.CreateCouponRequest} returns this
 */
proto.core.private.coupons.CreateCouponRequest.prototype.clearSingleUse = function() {
  return this.setSingleUse(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.core.private.coupons.CreateCouponRequest.prototype.hasSingleUse = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional MultiUse multi_use = 6;
 * @return {?proto.core.private.coupons.CreateCouponRequest.MultiUse}
 */
proto.core.private.coupons.CreateCouponRequest.prototype.getMultiUse = function() {
  return /** @type{?proto.core.private.coupons.CreateCouponRequest.MultiUse} */ (
    jspb.Message.getWrapperField(this, proto.core.private.coupons.CreateCouponRequest.MultiUse, 6));
};


/**
 * @param {?proto.core.private.coupons.CreateCouponRequest.MultiUse|undefined} value
 * @return {!proto.core.private.coupons.CreateCouponRequest} returns this
*/
proto.core.private.coupons.CreateCouponRequest.prototype.setMultiUse = function(value) {
  return jspb.Message.setOneofWrapperField(this, 6, proto.core.private.coupons.CreateCouponRequest.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.core.private.coupons.CreateCouponRequest} returns this
 */
proto.core.private.coupons.CreateCouponRequest.prototype.clearMultiUse = function() {
  return this.setMultiUse(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.core.private.coupons.CreateCouponRequest.prototype.hasMultiUse = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional bool is_infinite = 7;
 * @return {boolean}
 */
proto.core.private.coupons.CreateCouponRequest.prototype.getIsInfinite = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 7, false));
};


/**
 * @param {boolean} value
 * @return {!proto.core.private.coupons.CreateCouponRequest} returns this
 */
proto.core.private.coupons.CreateCouponRequest.prototype.setIsInfinite = function(value) {
  return jspb.Message.setOneofField(this, 7, proto.core.private.coupons.CreateCouponRequest.oneofGroups_[1], value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.core.private.coupons.CreateCouponRequest} returns this
 */
proto.core.private.coupons.CreateCouponRequest.prototype.clearIsInfinite = function() {
  return jspb.Message.setOneofField(this, 7, proto.core.private.coupons.CreateCouponRequest.oneofGroups_[1], undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.core.private.coupons.CreateCouponRequest.prototype.hasIsInfinite = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional google.protobuf.Timestamp expires_on = 8;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.core.private.coupons.CreateCouponRequest.prototype.getExpiresOn = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 8));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.core.private.coupons.CreateCouponRequest} returns this
*/
proto.core.private.coupons.CreateCouponRequest.prototype.setExpiresOn = function(value) {
  return jspb.Message.setOneofWrapperField(this, 8, proto.core.private.coupons.CreateCouponRequest.oneofGroups_[1], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.core.private.coupons.CreateCouponRequest} returns this
 */
proto.core.private.coupons.CreateCouponRequest.prototype.clearExpiresOn = function() {
  return this.setExpiresOn(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.core.private.coupons.CreateCouponRequest.prototype.hasExpiresOn = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * optional bool is_lifetime = 9;
 * @return {boolean}
 */
proto.core.private.coupons.CreateCouponRequest.prototype.getIsLifetime = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 9, false));
};


/**
 * @param {boolean} value
 * @return {!proto.core.private.coupons.CreateCouponRequest} returns this
 */
proto.core.private.coupons.CreateCouponRequest.prototype.setIsLifetime = function(value) {
  return jspb.Message.setOneofField(this, 9, proto.core.private.coupons.CreateCouponRequest.oneofGroups_[2], value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.core.private.coupons.CreateCouponRequest} returns this
 */
proto.core.private.coupons.CreateCouponRequest.prototype.clearIsLifetime = function() {
  return jspb.Message.setOneofField(this, 9, proto.core.private.coupons.CreateCouponRequest.oneofGroups_[2], undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.core.private.coupons.CreateCouponRequest.prototype.hasIsLifetime = function() {
  return jspb.Message.getField(this, 9) != null;
};


/**
 * optional FixedTermDiscountLength fixed_term = 10;
 * @return {?proto.core.private.coupons.CreateCouponRequest.FixedTermDiscountLength}
 */
proto.core.private.coupons.CreateCouponRequest.prototype.getFixedTerm = function() {
  return /** @type{?proto.core.private.coupons.CreateCouponRequest.FixedTermDiscountLength} */ (
    jspb.Message.getWrapperField(this, proto.core.private.coupons.CreateCouponRequest.FixedTermDiscountLength, 10));
};


/**
 * @param {?proto.core.private.coupons.CreateCouponRequest.FixedTermDiscountLength|undefined} value
 * @return {!proto.core.private.coupons.CreateCouponRequest} returns this
*/
proto.core.private.coupons.CreateCouponRequest.prototype.setFixedTerm = function(value) {
  return jspb.Message.setOneofWrapperField(this, 10, proto.core.private.coupons.CreateCouponRequest.oneofGroups_[2], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.core.private.coupons.CreateCouponRequest} returns this
 */
proto.core.private.coupons.CreateCouponRequest.prototype.clearFixedTerm = function() {
  return this.setFixedTerm(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.core.private.coupons.CreateCouponRequest.prototype.hasFixedTerm = function() {
  return jspb.Message.getField(this, 10) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.core.private.coupons.CreateCouponResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.core.private.coupons.CreateCouponResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.core.private.coupons.CreateCouponResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.core.private.coupons.CreateCouponResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    couponId: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.core.private.coupons.CreateCouponResponse}
 */
proto.core.private.coupons.CreateCouponResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.core.private.coupons.CreateCouponResponse;
  return proto.core.private.coupons.CreateCouponResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.core.private.coupons.CreateCouponResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.core.private.coupons.CreateCouponResponse}
 */
proto.core.private.coupons.CreateCouponResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setCouponId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.core.private.coupons.CreateCouponResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.core.private.coupons.CreateCouponResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.core.private.coupons.CreateCouponResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.core.private.coupons.CreateCouponResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCouponId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
};


/**
 * optional int32 coupon_id = 1;
 * @return {number}
 */
proto.core.private.coupons.CreateCouponResponse.prototype.getCouponId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.core.private.coupons.CreateCouponResponse} returns this
 */
proto.core.private.coupons.CreateCouponResponse.prototype.setCouponId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * @enum {number}
 */
proto.core.private.coupons.EligibleCustomers = {
  UNSPECIFIED_EC: 0,
  NEW_CUSTOMERS: 1,
  EXISTING_CUSTOMERS: 2,
  ALL_CUSTOMERS: 3
};

/**
 * @enum {number}
 */
proto.core.private.coupons.FrequencyType = {
  UNSPECIFIED_FT: 0,
  DAILY: 1,
  WEEKLY: 2,
  MONTHLY: 3,
  YEARLY: 4
};

goog.object.extend(exports, proto.core.private.coupons);
