import React, { useLayoutEffect } from "react";
import Panel from "../ui/panel/Panel";
import "./EmailLogModal.scss";
import { fetchEmailBody } from "../../actions/emailLogsActions";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../..";
import ProgressIndicator from "../ui/progress-indicator/ProgressIndicator";
import ErrorNotification from "../ui/error-notification/ErrorNotification";

interface IEmailLogModal {
  emailLogUrl: string,
}

const EmailLogModal: React.FC<IEmailLogModal> = ({ emailLogUrl }) => {
  const emailLogsReducer = useSelector((state: AppState) => state.emailLogsReducer);
  const currentCompanyId = useSelector((state: AppState) => state.auth.currentCompanyId);

  const dispatch = useDispatch<Function>();

  const { emailBody, isLoadingEmailBody } = emailLogsReducer;

  useLayoutEffect(() => {
    dispatch(fetchEmailBody(currentCompanyId as number, emailLogUrl));
  }, []);

  if(isLoadingEmailBody) {
    return <ProgressIndicator color="blue" coverage='normal' />
  }
  
  if(!emailBody || !emailBody.getBody()) {
    return <ErrorNotification title="EMAIL_LOGS_MODAL_UNAVAILABLE" isModal showXIcon={false} autoClose={false} />
  }

  return (
    <Panel className="email-log-modal">
      <div dangerouslySetInnerHTML={{ __html: emailBody.getBody() }}></div>
    </Panel>
  )

}

export default EmailLogModal;