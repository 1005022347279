import { BillsbyAction } from "../models/BillsbyAction";
import { AppState } from "..";
import { SubscriptionStatusType } from "../models/Subscriptions";
import { IEmailAttempt, IWrittingOffInvoice, IMinimumTerm } from "../models/DunningFailedProcess";
import { InvoiceWriteOffType } from "../models/Invoices";
import { SET_DUNNING_PROCESS_FIELD, SET_DUNNING_PROCESS_GROUP_FIELD, FETCH_DUNNING_PROCESS_SUCCESS, RESET_DUNNING_PROCESS, RESET_DECLINED_PAYMENTS, RESET_FAILED_PAYMENTS } from "../actions/dunningFailedProcessActions";


export interface IDunningFailedProcessState {
  initialPaymentDeclineStatus: SubscriptionStatusType,
  successReattemptStatus: SubscriptionStatusType,
  attemptOne: IEmailAttempt,
  attemptTwo: IEmailAttempt,
  attemptThree: IEmailAttempt,
  minimumTerm: IMinimumTerm,
  writingOffInvoice: IWrittingOffInvoice,
  initialPaymentFailureHoursUntilPrompt: number,
  continuedPaymentFailureHoursUntilTransfer: number
}

export let declinedPaymentsInitialState = {
  initialPaymentDeclineStatus: SubscriptionStatusType.ACTIVE,
  successReattemptStatus: SubscriptionStatusType.ACTIVE,
  attemptOne: { reattempt: true, hours: 24, subscriptionStatusType: SubscriptionStatusType.ACTIVE },
  attemptTwo: { reattempt: true, hours: 48, subscriptionStatusType: SubscriptionStatusType.ACTIVE },
  attemptThree: { reattempt: true, hours: 72, subscriptionStatusType: SubscriptionStatusType.CANCELLED },
  minimumTerm: { hasMinimumTerm: false, hours: 72, subscriptionStatusType: SubscriptionStatusType.ACTIVE },
  writingOffInvoice: { writeOffStatus: InvoiceWriteOffType.WRITE_OFF_IMMEDIATELY, subscriptionStatusType: SubscriptionStatusType.CANCELLED, hours: 720 }
}

export let failedPaymentsInitialState = {
  initialPaymentFailureHoursUntilPrompt: 48,
  continuedPaymentFailureHoursUntilTransfer: 24
}

export const initialState: IDunningFailedProcessState = {
  ...declinedPaymentsInitialState,
  ...failedPaymentsInitialState
}

export default function dunningFailedProcessReducer(state: IDunningFailedProcessState = initialState, action: BillsbyAction, store: AppState) {
  switch (action.type) {

    case FETCH_DUNNING_PROCESS_SUCCESS: {

      const { attemptOne, attemptTwo, attemptThree, minimumTerm, writeOff, failedPaymentHoursUntilDunning, failedPaymentHoursUntilEmailAlert, subscriptionStatusOnInitialFailure, subscriptionStatusOnSuccess } = action.response;
      const reattemptOne = Boolean(attemptOne);
      const reattemptTwo = Boolean(attemptTwo);
      const reattemptThree = Boolean(attemptThree);
      const hasMinimumTerm = Boolean(minimumTerm);

      declinedPaymentsInitialState = {
        ...declinedPaymentsInitialState,
        initialPaymentDeclineStatus: subscriptionStatusOnInitialFailure,
        successReattemptStatus: subscriptionStatusOnSuccess,
        attemptOne: reattemptOne ? { reattempt: true, hours: attemptOne.hoursUntilReattempt, subscriptionStatusType: attemptOne.subscriptionStatusOnFailure } : { ...initialState.attemptOne, reattempt: false },
        attemptTwo: reattemptTwo ? { reattempt: true, hours: attemptTwo.hoursUntilReattempt, subscriptionStatusType: attemptTwo.subscriptionStatusOnFailure } : { ...initialState.attemptTwo, reattempt: false},
        attemptThree: reattemptThree ? { reattempt: true, hours: attemptThree.hoursUntilReattempt, subscriptionStatusType: attemptThree.subscriptionStatusOnFailure } : { ...initialState.attemptThree, reattempt: false},
        minimumTerm: hasMinimumTerm ? { hasMinimumTerm: true, hours: minimumTerm.hoursUntilReattempt, subscriptionStatusType: minimumTerm.subscriptionStatusOnFailure } : { ...initialState.minimumTerm },
        writingOffInvoice: { writeOffStatus: writeOff.logic, subscriptionStatusType: writeOff.subscriptionStatus || SubscriptionStatusType.CANCELLED, hours: writeOff.hoursUntilAutomaticWriteOff },
      };

      failedPaymentsInitialState = {
        ...failedPaymentsInitialState,
        initialPaymentFailureHoursUntilPrompt: failedPaymentHoursUntilEmailAlert,
        continuedPaymentFailureHoursUntilTransfer: failedPaymentHoursUntilDunning
      }

      return { ...state, ...declinedPaymentsInitialState, ...failedPaymentsInitialState }
    }

    case SET_DUNNING_PROCESS_FIELD: {
      return { ...state, [action.fieldName]: action.fieldValue }
    }

    case SET_DUNNING_PROCESS_GROUP_FIELD: {
      return { ...state, ...action.payload }
    }

    case RESET_DECLINED_PAYMENTS: {
      return { ...state, ...declinedPaymentsInitialState }
    }

    case RESET_FAILED_PAYMENTS: {
      return { ...state, ...failedPaymentsInitialState }
    }

    case RESET_DUNNING_PROCESS: {
      return initialState
    }

    default:
      return state
  }
}