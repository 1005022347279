import React, { FormEvent, useEffect } from "react";
import counterpart from "counterpart";
import { Row, Col } from "react-grid-system";
import { Link, RouteComponentProps } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import {
  requestPasswordLink,
  setEmail,
  submitNewPassword,
  setPassword,
  setConfirmPassword,
  checkPasswordValidity,
  clearErrors,
} from "../../../actions/loginActions";
import history from "../../../utils/history";

import "./ForgotPassword.scss";
import Panel from "../../../components/ui/panel/Panel";
import Text from "../../../components/ui/text/Text";
import FormLabel from "../../../components/ui/form-label/FormLabel";
import Input from "../../../components/ui/input/Input";
import Button from "../../../components/ui/button/Button";
import Password from "../../../components/ui/password/Password";
import { AppState } from "../../..";
import { ILoginReducerState } from "../../../reducers/loginReducer";


const extractUserData = (search: string) => {
  const searchParams = new URLSearchParams(search);
  return {
    validationCode: searchParams.get("code")
  }
}

const hasValidationCodeExpired = (validationCode: string) => {
  const code = JSON.parse(window.atob(validationCode));
  const codeExpired = moment(code.ExpiresOn).isBefore();
  return codeExpired;
}

const showPasswordScreen = (resetPasswordDesc: string,
  buttonTitle: string,
  formFunc: () => any,
  password: string,
  passwordConfirmation: string,
  onChangePassword: (psw: string) => void,
  onChangeConfirmPassword: (confirmPsw: string) => void,
  passwordIsValidCallback?: (isValid: boolean) => void,
  buttonIcon?: string,
  buttonType?: "general" | "custom" | "add" | "light" | "error" | "success" | "with-shadow",
  isValidating?: boolean,
  isButtonDisabled?: boolean) => {
  return (
    <div className="forgot-password">
      <Panel title="FORGOT_PASSWORD_TITLE" className="forgot-password-panel">
        <Text content={resetPasswordDesc} noMargin />
      </Panel>
      <form
        id="forgot-password-form"
        onSubmit={(evt: FormEvent) => {
          evt.preventDefault();
          formFunc();
        }}
      >
        <div>
          <Panel className="forgot-password-email">
            <Password
              password={password}
              passwordConfirmation={passwordConfirmation}
              onChangePassword={onChangePassword}
              onChangeConfirmPassword={onChangeConfirmPassword}
              passwordIsValidCallback={passwordIsValidCallback}
            />
          </Panel>
          <Row>
            <Col className="forgot-password-footer">
              <Button
                icon={buttonIcon}
                type="submit"
                buttonType={buttonType}
                id="forgot-password-btn"
                isFullWidth
                title={buttonTitle}
                isLoading={isValidating}
                disabled={isButtonDisabled}
                className="btn-uppercase"
              />
              <Text
                className="forgot-password-footer_text"
                shouldTranslate={false}
                content={
                  <Link id="forgot-password-footer_login"
                    to="/login">{counterpart("FORGOT_PASSWORD_TEXT_LINK")}</Link>
                }
              />
            </Col>
          </Row>
        </div>
      </form>
    </div>
  );
}

const showResetPasswordEmailScreen = (resetPasswordDesc: string,
  buttonTitle: string,
  formFunc: () => any,
  inputValue: string,
  inputOnChange?: (evt: any) => any,
  inputErrorMessage?: string,
  inputIsError?: boolean,
  buttonIcon?: string,
  buttonType?: "general" | "custom" | "add" | "light" | "error" | "success" | "with-shadow",
  isValidating?: boolean,
  isButtonDisabled?: boolean) => {
  return (
    <div className="forgot-password">
      <Panel title="FORGOT_PASSWORD_TITLE" className="forgot-password-panel">
        <Text content={resetPasswordDesc} noMargin />
      </Panel>
      <form
        id="forgot-password-form"
        onSubmit={(evt: FormEvent) => {
          evt.preventDefault();
          formFunc();
        }}
      >
        <div>
          <Panel className="forgot-password-email">
            <Row>
              <Col md={2} className="forgot-password-email_input-id">
                <FormLabel target="forgot-password-email" content="FORGOT_PASSWORD_EMAIL" noMargin />
              </Col>
              <Col md={10}>
                <Input
                  type="email"
                  required
                  id="forgot-password-email"
                  placeholder={counterpart("LOGIN_EMAIL_PLACEHOLDER")}
                  value={inputValue}
                  onChange={inputOnChange}
                  errorMsg={inputErrorMessage}
                  isError={inputIsError}
                />
              </Col>
            </Row>
          </Panel>
          <Row>
            <Col className="forgot-password-footer">
              <Button
                icon={buttonIcon}
                type="submit"
                buttonType={buttonType}
                id="forgot-password-btn"
                isFullWidth
                title={buttonTitle}
                isLoading={isValidating}
                disabled={isButtonDisabled}
                className="btn-uppercase"
              />
              <Text
                className="forgot-password-footer_text"
                shouldTranslate={false}
                content={
                  <Link id="forgot-password-footer_login"
                    to="/login">{counterpart("FORGOT_PASSWORD_TEXT_LINK")}</Link>
                }
              />
            </Col>
          </Row>
        </div>
      </form>
    </div>
  );
}

const ForgotPassword: React.FC<RouteComponentProps> = ({ match }) => {
  const {  email, passwordRequestSent, passwordIsValid, passwordHasBeenSent,
    password, passwordConfirmation, emailErrorMsg, isValidating,
    incorrectPasswordCount } = useSelector<AppState, ILoginReducerState>(state => state.login);
  const dispatch = useDispatch<Function>()

  const userData = extractUserData(history.location.search);

  let isCodeExpired = false;
  if (userData.validationCode) {
    isCodeExpired = hasValidationCodeExpired(userData.validationCode);
  }

  useEffect(() => {
    return () => dispatch(clearErrors())
  }, [])

  if (incorrectPasswordCount === 5) {
    return showResetPasswordEmailScreen(
      "FORGOT_PASSWORD_LOCKED_TEXT",
      passwordRequestSent ? "FORGOT_PASSWORD_BUTTON_RESET_TEXT" : "FORGOT_PASSWORD_BUTTON_TEXT",
      () => dispatch(requestPasswordLink(email)),
      email,
      e => dispatch(setEmail(e.target.value)),
      emailErrorMsg,
      emailErrorMsg !== "",
      passwordRequestSent ? "far fa-check-circle" : "",
      passwordRequestSent ? "success" : "general",
      isValidating,
      passwordRequestSent
    );
  }

  if (match?.url === "/new-password") {
    if (isCodeExpired) {
      return showResetPasswordEmailScreen(
        "FORGOT_PASSWORD_EXPIRED_TEXT",
        passwordRequestSent ? "FORGOT_PASSWORD_BUTTON_RESET_TEXT" : "FORGOT_PASSWORD_BUTTON_TEXT",
        () => dispatch(requestPasswordLink(email)),
        email,
        e => dispatch(setEmail(e.target.value)),
        emailErrorMsg,
        emailErrorMsg !== "",
        passwordRequestSent ? "far fa-check-circle" : "",
        passwordRequestSent ? "success" : "general",
        isValidating,
        passwordRequestSent
      );
    }
    if (passwordHasBeenSent !== null && !passwordHasBeenSent) {
      return showResetPasswordEmailScreen(
        "FORGOT_PASSWORD_CODE_INVALID_TEXT",
        passwordRequestSent ? "FORGOT_PASSWORD_BUTTON_RESET_TEXT" : "FORGOT_PASSWORD_BUTTON_TEXT",
        () => dispatch(requestPasswordLink(email)),
        email,
        e => dispatch(setEmail(e.target.value)),
        emailErrorMsg,
        emailErrorMsg !== "",
        passwordRequestSent ? "far fa-check-circle" : "",
        passwordRequestSent ? "success" : "general",
        isValidating,
        passwordRequestSent
      );
    }


    return showPasswordScreen(
      "FORGOT_PASSWORD_CHOOSE_NEW_TEXT",
      passwordHasBeenSent ? "FORGOT_PASSWORD_BUTTON_CHANGED_TEXT" : "FORGOT_PASSWORD_BUTTON_SAVE_TEXT",
      () => userData.validationCode ? dispatch(submitNewPassword(password, userData.validationCode)) : "",
      password,
      passwordConfirmation,
      (psw: string) => dispatch(setPassword(psw)),
      (pswConfirmation: string) => dispatch(setConfirmPassword(pswConfirmation)),
      (passwordIsValid: boolean) => dispatch(checkPasswordValidity(passwordIsValid)),
      passwordHasBeenSent ? "far fa-check-circle" : "",
      passwordHasBeenSent ? "success" : "general",
      isValidating,
      passwordHasBeenSent ? passwordHasBeenSent : (!passwordIsValid && passwordHasBeenSent === null) || (passwordHasBeenSent !== null && passwordHasBeenSent));
  }

  return showResetPasswordEmailScreen(
    "FORGOT_PASSWORD_TEXT",
    passwordRequestSent ? "FORGOT_PASSWORD_BUTTON_RESET_TEXT" : "FORGOT_PASSWORD_BUTTON_TEXT",
    () => dispatch(requestPasswordLink(email)),
    email,
    e => dispatch(setEmail(e.target.value)),
    emailErrorMsg,
    emailErrorMsg !== "",
    passwordRequestSent ? "far fa-check-circle" : "",
    passwordRequestSent ? "success" : "general",
    isValidating,
    passwordRequestSent
  );
}

export default ForgotPassword