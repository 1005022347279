import { BillsbyAction } from "../models/BillsbyAction";
import { AppState } from "..";
import { FETCH_CUSTOMER_SUBSCRIPTIONS_SUCCESS, FETCH_CUSTOMER_SUBSCRIPTIONS_REQUEST, RESET_CUSTOMER_SUBSCRIPTIONS, FETCH_CUSTOMER_SUBSCRIPTIONS_FAILURE } from "../actions/customerSubscriptionsActions";
import { SubscriptionStatusType } from "../models/Subscriptions";

export interface ICustomerSubscriptionsReducerState {
  subscriptions?: Array<CustomerSubscription>;
  isLoadingCustomerSubscription: boolean;
}

export const initialState = {
  subscriptions: undefined,
  isLoadingCustomerSubscription: false
}



interface CustomerSubscription {
  createdOn: Date,
  currency: string,
  nextBilling: Date,
  planId: number,
  planName: string,
  productId: number,
  productName: string,
  revenue: string,
  status: SubscriptionStatusType,
  subscriptionId: number
}

export default function customerSubscriptionsReducer(state: ICustomerSubscriptionsReducerState = initialState, action: BillsbyAction, store: AppState) {
  switch (action.type) {
    case FETCH_CUSTOMER_SUBSCRIPTIONS_SUCCESS:
      const subscriptions = action.response;
      return { ...state, subscriptions, isLoadingCustomerSubscription: false }
    case FETCH_CUSTOMER_SUBSCRIPTIONS_REQUEST:
      return { ...state, isLoadingCustomerSubscription: true }
    case FETCH_CUSTOMER_SUBSCRIPTIONS_FAILURE:
      return { ...initialState, isLoadingCustomerSubscription: false }
    case RESET_CUSTOMER_SUBSCRIPTIONS:
      return { ...initialState }
    default:
      return state;
  }
}