import { FETCH_CURRENCIES_PRODUCT_SUCCESS, FETCH_PRODUCTS_REQUEST, FETCH_PRODUCTS_SUCCESS, FETCH_PLANS_BY_PRODUCT_SUCCESS, SET_PRODUCTS_CUSTOM_FIELD, SET_PRODUCT_CYCLES_UNMOUNT, SET_SEARCHED_PRODUCT_NAME, FETCH_PRODUCTS_FAILURE } from "../actions/productPlanCycleActions";
import { BillsbyAction } from "../models/BillsbyAction";
import { ICurrency } from "../models/Currency";
import { reorderCurrencies } from "../utils/commonUtils";
import { IProductLightWithPlans, IPlanWithCycles, IProductsPaginated, VisibilityType } from "../models/Product";
import { AppState } from "..";

export interface IProductPlanCycleReducerState {
  searchedProductName: string
  defaultCurrency: { label: string, value: string } | null,
  currencies: Array<ICurrency>,
  currenciesDropdown: Array<{ label: string, value: string }>,
  products: IProductLightWithPlans[],
  firstCreatedProduct: IProductLightWithPlans | null,
  filteredProducts: IProductLightWithPlans[],
  isFetchingProducts: boolean,
  productsPaginated: IProductsPaginated,
  selectedProductsFilter: VisibilityType | string,
  hasProducts: boolean
}

export const initialState = {
  searchedProductName: "",
  products: [],
  defaultCurrency: null,
  currencies: [],
  currenciesDropdown: [],
  filteredProducts: [],
  isFetchingProducts: true,
  selectedProductsFilter: "",
  firstCreatedProduct: null,
  productsPaginated: {
    currentPage: 1,
    pageCount: 1,
    pageSize: 0,
    rowCount: 0,
    results: undefined
  },
  hasProducts: false
}

export default function productPlanCycleReducer(state: IProductPlanCycleReducerState = initialState, action: BillsbyAction, store: AppState) {
  switch (action.type) {
    case SET_SEARCHED_PRODUCT_NAME:
      return { ...state, searchedProductName: action.payload, filteredProducts: state.products.filter(product => product.name.toUpperCase().indexOf(action.payload.toUpperCase()) >= 0) }
    case FETCH_CURRENCIES_PRODUCT_SUCCESS: {
      const defaultCurrency: ICurrency = action.response.length ? (action.response.find((el: ICurrency) => el.isoCode === store.auth.currentCompanyCurrency)) || {} : {};
      const currencies = reorderCurrencies(action.response);

      return {
        ...state, currencies, currenciesDropdown: currencies.map((currency: ICurrency) => ({ value: currency.isoCode, label: currency.englishName })),
        defaultCurrency: { value: defaultCurrency.isoCode, label: defaultCurrency.englishName }
      }
    }
    case FETCH_PRODUCTS_REQUEST:
      return { ...state, isFetchingProducts: true }
    case FETCH_PRODUCTS_FAILURE:
      return { ...state, isFetchingProducts: false }
    case FETCH_PRODUCTS_SUCCESS:
      const hasProducts = (!!action.response.results && action.response.results.length > 0) || state.hasProducts;
      return { ...state, products: action.response, filteredProducts: action.response, isFetchingProducts: false, productsPaginated: action.response, hasProducts }
    case FETCH_PLANS_BY_PRODUCT_SUCCESS: {
      const plans = action.response as IPlanWithCycles[];
      let newProducts = state.productsPaginated.results ? [...state.productsPaginated.results] : [];
      if (plans.length) {
        const firstPlan = plans[0];
        const product = newProducts.find(s => s.productId === firstPlan.productId);
        if (product) {
          product.plans = plans
        }
      }
      return { ...state, products: newProducts }
    }

    case SET_PRODUCTS_CUSTOM_FIELD:
      return { ...state, [action.fieldName]: action.fieldValue }

    case SET_PRODUCT_CYCLES_UNMOUNT:
      return { ...initialState }

    default:
      return state;
  }
}
