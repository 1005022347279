import React from "react";
import Panel from "../../components/ui/panel/Panel";
import Text from "../../components/ui/text/Text";
import "./ACHDetails.scss";
import Button from "../../components/ui/button/Button";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../..";
import { setCustomerDetailsField } from "../../actions/customerDetailsActions";

const ACHDetails: React.FC = () => {
  const dispatch = useDispatch();
  const { customerDetails: { cardDetails: { fullName, createdOn, bankName, lastFourDigits } } } = useSelector((state: AppState) => ({ customerDetails: state.customerDetailsReducer })); 
  return (
    <div className="ach-details">
      <Panel className="ach-details__panel">
        <Button
          className="ach-details__button"
          id="replace-card"
          icon="fas fa-retweet"
          buttonType="add"
          title="CARD_DETAILS_BUTTON_TITLE"
          onClick={() => dispatch(setCustomerDetailsField("showReplacePaymentModal", true))}
        ></Button>
        <div className="ach-details__content">
          <div className={"ach-details__content-details"}>
            <Text className="ach-details__content-details-label" content="ACH_DETAILS_CREATED_ON" noMargin></Text>
            <Text className="ach-details__content-details-value" shouldTranslate={false} content={createdOn || ""} noMargin></Text>
          </div>
          <div className="ach-details__content-details">
            <Text className="ach-details__content-details-label" content="ACH_DETAILS_ACCT_HOLDER" noMargin></Text>
            <Text className="ach-details__content-details-value" shouldTranslate={false} content={fullName} noMargin></Text>
          </div>
          <div className="ach-details__content-details">
            <Text className="ach-details__content-details-label" content="ACH_DETAILS_ACCT_NUM_ENDING" noMargin></Text>
            <Text className="ach-details__content-details-value" shouldTranslate={false} content={lastFourDigits} noMargin></Text>
          </div>
          <div className="ach-details__content-details">
            <Text className="ach-details__content-details-label" content="ACH_DETAILS_BANK_NAME" noMargin></Text>
            <Text className="ach-details__content-details-value" shouldTranslate={false} content={bankName} noMargin></Text>
          </div>
        </div>
      </Panel>
    </div>
  )
};

export default ACHDetails;
