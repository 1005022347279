import API from "../utils/API";
import { makeActionCreator, makeGenericActionCreatorTypeSafe } from "../utils/reduxUtils";
import { GetAddonsRequest, DeleteSubscriptionAddonRequest, GetAllowancesRequest, DeleteSubscriptionAllowanceRequest, UpdateSubscriptionStatusRequest, SubscriptionStatus } from "../utils/grpc/generated/Billsby.Protos/billing/private/subscription/subscription_pb";
import { SubscriptionServiceClient } from "../utils/grpc/generated/Billsby.Protos/billing/private/subscription/SubscriptionServiceClientPb";
import { ConfigConstants } from "../utils/config";
import { grpcUnaryCall } from "../utils/grpc/grpcUtils";
import { ISubscriptionDetailsReducerState } from "../reducers/subscriptionDetailsReducer";
import { GetDiscountsRequest, AddCouponDiscountRequest, FrequencyType, RemoveDiscountRequest } from "../utils/grpc/generated/Billsby.Protos/billing/private/subscription/discount/discount_pb";
import {SubscriptionDiscountServiceClient} from "../utils/grpc/generated/Billsby.Protos/billing/private/subscription/discount/DiscountServiceClientPb";
import { Int32Value } from "google-protobuf/google/protobuf/wrappers_pb";

export const FETCH_SUBSCRIPTION_DETAILS_REQUEST = "FETCH_SUBSCRIPTION_DETAILS_REQUEST";
export const FETCH_SUBSCRIPTION_DETAILS_SUCCESS = "FETCH_SUBSCRIPTION_DETAILS_SUCCESS";
export const FETCH_SUBSCRIPTION_DETAILS_FAILURE = "FETCH_SUBSCRIPTION_DETAILS_FAILURE";
export const RESET_SUBSCRIPTION_DETAILS = "RESET_SUBSCRIPTION_DETAILS";

export const SET_SUBSCRIPTION_DETAILS_FIELD = "SET_SUBSCRIPTION_DETAILS_FIELD";

export const setSubscriptionDetailsField = makeGenericActionCreatorTypeSafe<ISubscriptionDetailsReducerState>(SET_SUBSCRIPTION_DETAILS_FIELD)

export const resetSubscriptionDetails = makeActionCreator(RESET_SUBSCRIPTION_DETAILS);

export const FETCH_SUBSCRIPTION_ADD_ONS_REQUEST = "FETCH_SUBSCRIPTION_ADD_ONS_REQUEST";
export const FETCH_SUBSCRIPTION_ADD_ONS_SUCCESS = "FETCH_SUBSCRIPTION_ADD_ONS_SUCCESS";
export const FETCH_SUBSCRIPTION_ADD_ONS_FAILURE = "FETCH_SUBSCRIPTION_ADD_ONS_FAILURE";

export const FETCH_SUBSCRIPTION_ALLOWANCES_REQUEST = "FETCH_SUBSCRIPTION_ALLOWANCES_REQUEST";
export const FETCH_SUBSCRIPTION_ALLOWANCES_SUCCESS = "FETCH_SUBSCRIPTION_ALLOWANCES_SUCCESS";
export const FETCH_SUBSCRIPTION_ALLOWANCES_FAILURE = "FETCH_SUBSCRIPTION_ALLOWANCES_FAILURE";

export const DELETE_SUBSCRIPTION_ADD_ONS_REQUEST = "DELETE_SUBSCRIPTION_ADD_ONS_REQUEST";
export const DELETE_SUBSCRIPTION_ADD_ONS_SUCCESS = "DELETE_SUBSCRIPTION_ADD_ONS_SUCCESS";
export const DELETE_SUBSCRIPTION_ADD_ONS_FAILURE = "DELETE_SUBSCRIPTION_ADD_ONS_FAILURE";

export const DELETE_SUBSCRIPTION_ALLOWANCE_REQUEST = "DELETE_SUBSCRIPTION_ALLOWANCE_REQUEST";
export const DELETE_SUBSCRIPTION_ALLOWANCE_SUCCESS = "DELETE_SUBSCRIPTION_ALLOWANCE_SUCCESS";
export const DELETE_SUBSCRIPTION_ALLOWANCE_FAILURE = "DELETE_SUBSCRIPTION_ALLOWANCE_FAILURE";

export const FETCH_SUBSCRIPTION_DISCOUNTS_REQUEST = "FETCH_SUBSCRIPTION_DISCOUNTS_REQUEST";
export const FETCH_SUBSCRIPTION_DISCOUNTS_SUCCESS = "FETCH_SUBSCRIPTION_DISCOUNTS_SUCCESS";
export const FETCH_SUBSCRIPTION_DISCOUNTS_FAILURE = "FETCH_SUBSCRIPTION_DISCOUNTS_FAILURE";

export const ADD_SUBSCRIPTION_DISCOUNTS_REQUEST = "ADD_SUBSCRIPTION_DISCOUNTS_REQUEST";
export const ADD_SUBSCRIPTION_DISCOUNTS_SUCCESS = "ADD_SUBSCRIPTION_DISCOUNTS_SUCCESS";
export const ADD_SUBSCRIPTION_DISCOUNTS_FAILURE = "ADD_SUBSCRIPTION_DISCOUNTS_FAILURE";

export const REMOVE_SUBSCRIPTION_DISCOUNT_REQUEST = "REMOVE_SUBSCRIPTION_DISCOUNT_REQUEST";
export const REMOVE_SUBSCRIPTION_DISCOUNT_SUCCESS = "REMOVE_SUBSCRIPTION_DISCOUNT_SUCCESS";
export const REMOVE_SUBSCRIPTION_DISCOUNT_FAILURE = "REMOVE_SUBSCRIPTION_DISCOUNT_FAILURE";

export const UPDATE_BILLING_DATE_REQUEST = "UPDATE_BILLING_DATE_REQUEST";
export const UPDATE_BILLING_DATE_SUCCESS = "UPDATE_BILLING_DATE_SUCCESS";
export const UPDATE_BILLING_DATE_FAILURE = "UPDATE_BILLING_DATE_FAILURE";

export const UPDATE_SUBSCRIPTION_STATUS_REQUEST = "UPDATE_SUBSCRIPTION_STATUS_REQUEST";
export const UPDATE_SUBSCRIPTION_STATUS_SUCCESS = "UPDATE_SUBSCRIPTION_STATUS_SUCCESS";
export const UPDATE_SUBSCRIPTION_STATUS_FAILURE = "UPDATE_SUBSCRIPTION_STATUS_FAILURE";

export const SET_DELETED_ADD_ON = "SET_DELETED_ADD_ON";
export const setDeletedAddOn = makeActionCreator(SET_DELETED_ADD_ON, "payload");

export const SET_DELETED_ALLOWANCE = "SET_DELETED_ALLOWANCE";
export const setDeletedAllowance = makeActionCreator(SET_DELETED_ALLOWANCE, "payload");

export const SET_DELETE_DISCOUNT = "SET_DELETE_DISCOUNT";
export const setDeleteDiscount = makeActionCreator(SET_DELETE_DISCOUNT, "payload");

export const SET_SHOW_DELETE_DISCOUNT_MODAL = "SET_SHOW_DELETE_DISCOUNT_MODAL";
export const setShowDeleteDiscountModal = makeActionCreator(SET_SHOW_DELETE_DISCOUNT_MODAL, "payload");

export const fetchSubscriptionDetails = function (companyDomain: string, subscriptionUniqueId: string) {
  return {
    types: [FETCH_SUBSCRIPTION_DETAILS_REQUEST, FETCH_SUBSCRIPTION_DETAILS_SUCCESS, FETCH_SUBSCRIPTION_DETAILS_FAILURE],
    callAPI: () => API.getSubscriptionDetails(companyDomain, subscriptionUniqueId)
  }
}

export const fetchSubscriptionAddOns = (companyId: number, subscriptionId: number) => {
  const getAddOnsRequest = new GetAddonsRequest();
  getAddOnsRequest.setCompanyId(companyId);
  getAddOnsRequest.setSubscriptionId(subscriptionId);
  
  const subscriptionServiceClient = new SubscriptionServiceClient(ConfigConstants.grpcBaseUrl);
  return {
    types: [FETCH_SUBSCRIPTION_ADD_ONS_REQUEST, FETCH_SUBSCRIPTION_ADD_ONS_SUCCESS, FETCH_SUBSCRIPTION_ADD_ONS_FAILURE],
    callAPI: () => grpcUnaryCall(getAddOnsRequest, subscriptionServiceClient, subscriptionServiceClient.getAddons)
  }
}

export const fetchSubscriptionAllowances = (companyId: number, subscriptionId: number) => {
  const getAllowancesRequest = new GetAllowancesRequest();
  getAllowancesRequest.setCompanyId(companyId);
  getAllowancesRequest.setSubscriptionId(subscriptionId);
  
  const subscriptionServiceClient = new SubscriptionServiceClient(ConfigConstants.grpcBaseUrl);
  return {
    types: [FETCH_SUBSCRIPTION_ALLOWANCES_REQUEST, FETCH_SUBSCRIPTION_ALLOWANCES_SUCCESS, FETCH_SUBSCRIPTION_ALLOWANCES_FAILURE],
    callAPI: () => grpcUnaryCall(getAllowancesRequest, subscriptionServiceClient, subscriptionServiceClient.getAllowances)
  }
}

export const deleteSubscriptionAddOns = (companyId: number, subscriptionId: number, addOnId: number, userId: number) => {
  const deleteSubscriptionAddonRequest = new DeleteSubscriptionAddonRequest();
  const id = new Int32Value();
  id.setValue(userId);

  deleteSubscriptionAddonRequest.setCompanyId(companyId);
  deleteSubscriptionAddonRequest.setSubscriptionId(subscriptionId);
  deleteSubscriptionAddonRequest.setAddonId(addOnId);
  deleteSubscriptionAddonRequest.setRequestedByUserId(id);
  
  const subscriptionServiceClient = new SubscriptionServiceClient(ConfigConstants.grpcBaseUrl);
  return {
    types: [DELETE_SUBSCRIPTION_ADD_ONS_REQUEST, DELETE_SUBSCRIPTION_ADD_ONS_SUCCESS, DELETE_SUBSCRIPTION_ADD_ONS_FAILURE],
    callAPI: () => grpcUnaryCall(deleteSubscriptionAddonRequest, subscriptionServiceClient, subscriptionServiceClient.deleteAddon)
  }
}

export const deleteSubscriptionAllowances = (companyId: number, subscriptionId: number, allowanceId: number, userId: number) => {
  const deleteSubscriptionAllowanceRequest = new DeleteSubscriptionAllowanceRequest();
  const id = new Int32Value();
  id.setValue(userId);

  deleteSubscriptionAllowanceRequest.setCompanyId(companyId);
  deleteSubscriptionAllowanceRequest.setSubscriptionId(subscriptionId);
  deleteSubscriptionAllowanceRequest.setAllowanceId(allowanceId);
  deleteSubscriptionAllowanceRequest.setRequestedByUserId(id);
  
  const subscriptionServiceClient = new SubscriptionServiceClient(ConfigConstants.grpcBaseUrl);
  return {
    types: [DELETE_SUBSCRIPTION_ALLOWANCE_REQUEST, DELETE_SUBSCRIPTION_ALLOWANCE_SUCCESS, DELETE_SUBSCRIPTION_ALLOWANCE_FAILURE],
    callAPI: () => grpcUnaryCall(deleteSubscriptionAllowanceRequest, subscriptionServiceClient, subscriptionServiceClient.deleteSubscriptionAllowance)
  }
}

export const fetchSubscriptionDiscounts = (companyId: number, subscriptionId: number) => {
  const getDiscountsRequest = new GetDiscountsRequest();
  getDiscountsRequest.setCompanyId(companyId);
  getDiscountsRequest.setSubscriptionId(subscriptionId);
  const subscriptionDiscountServiceClient = new SubscriptionDiscountServiceClient(ConfigConstants.grpcBaseUrl);
  return {
    types: [FETCH_SUBSCRIPTION_DISCOUNTS_REQUEST, FETCH_SUBSCRIPTION_DISCOUNTS_SUCCESS, FETCH_SUBSCRIPTION_DISCOUNTS_FAILURE],
    callAPI: () => grpcUnaryCall(getDiscountsRequest, subscriptionDiscountServiceClient, subscriptionDiscountServiceClient.getDiscounts)
  }
}

export const addSubscriptionDiscounts = (companyId: number, subscriptionId: number, discountId: number, userId: number, isFixedTerm: boolean, frequency?: number, frequencyType?: FrequencyType) => {
  const addCouponDiscountRequest = new AddCouponDiscountRequest();
  addCouponDiscountRequest.setCompanyId(companyId);
  addCouponDiscountRequest.setDiscountId(discountId);
  addCouponDiscountRequest.setIsLifetime(!isFixedTerm);
  addCouponDiscountRequest.setSubscriptionId(subscriptionId);
  addCouponDiscountRequest.setRequestedByUserId(userId);
  if(isFixedTerm) {
    const discountLength = new AddCouponDiscountRequest.FixedTermDiscountLength();
    discountLength.setFrequency(Number(frequency));
    discountLength.setFrequencyType(frequencyType as FrequencyType);
    addCouponDiscountRequest.setFixedTerm(discountLength);
  }

  const subscriptionDiscountServiceClient = new SubscriptionDiscountServiceClient(ConfigConstants.grpcBaseUrl);
  return {
    types: [ADD_SUBSCRIPTION_DISCOUNTS_REQUEST, ADD_SUBSCRIPTION_DISCOUNTS_SUCCESS, ADD_SUBSCRIPTION_DISCOUNTS_FAILURE],
    callAPI: () => grpcUnaryCall(addCouponDiscountRequest, subscriptionDiscountServiceClient, subscriptionDiscountServiceClient.addCouponDiscount)
  }
}

export const removeSubscriptionDiscount = (companyId: number, subscriptionId: number, subscriptionDiscountId: number, userId: number) => {
  const removeDiscountRequest = new RemoveDiscountRequest();
  removeDiscountRequest.setCompanyId(companyId);
  removeDiscountRequest.setSubscriptionId(subscriptionId);
  removeDiscountRequest.setRequestedByUserId(userId);
  removeDiscountRequest.setSubscriptionDiscountId(subscriptionDiscountId)
  
  const subscriptionDiscountServiceClient = new SubscriptionDiscountServiceClient(ConfigConstants.grpcBaseUrl);
  return {
    types: [REMOVE_SUBSCRIPTION_DISCOUNT_REQUEST, REMOVE_SUBSCRIPTION_DISCOUNT_SUCCESS, REMOVE_SUBSCRIPTION_DISCOUNT_FAILURE],
    callAPI: () => grpcUnaryCall(removeDiscountRequest, subscriptionDiscountServiceClient, subscriptionDiscountServiceClient.removeDiscount)
  }
}

export const updateBillingDate = (companyDomain: string, subscriptionUniqueId: string, data: { newRenewalDate?: string, pauseSubscription?: boolean }) => {
  return {
    types: [UPDATE_BILLING_DATE_REQUEST, UPDATE_BILLING_DATE_SUCCESS, UPDATE_BILLING_DATE_FAILURE],
    callAPI: () => API.updateBillingDate(companyDomain, subscriptionUniqueId, data)
  }
}

export const updateSubscriptionStatus =  (companyId: number, subscriptionUniqueId: string, status: SubscriptionStatus, userId: number) => {
  const updateSubscriptionStatusRequest = new UpdateSubscriptionStatusRequest();
  const id = new Int32Value();
  id.setValue(userId);

  updateSubscriptionStatusRequest.setCompanyId(companyId);
  updateSubscriptionStatusRequest.setSubscriptionUniqueId(subscriptionUniqueId);
  updateSubscriptionStatusRequest.setStatus(status);
  updateSubscriptionStatusRequest.setRequestedByUserId(id);

  const subscriptionServiceClient = new SubscriptionServiceClient(ConfigConstants.grpcBaseUrl);
  return {
    types: [UPDATE_SUBSCRIPTION_STATUS_REQUEST, UPDATE_SUBSCRIPTION_STATUS_SUCCESS, UPDATE_SUBSCRIPTION_STATUS_FAILURE],
    callAPI: () => grpcUnaryCall(updateSubscriptionStatusRequest, subscriptionServiceClient, subscriptionServiceClient.updateSubscriptionStatus)
  }
}
