import React, { useState, useLayoutEffect } from "react";
import Panel from "../../ui/panel/Panel";
import Text from "../../ui/text/Text";
import Input from "../../ui/input/Input";
import "./TaxRatePanel.scss";
import { taxRateChecker } from "../../../utils/commonUtils";
import { TaxRate } from "../../../utils/grpc/generated/Billsby.Protos/billing/private/company/taxation/taxation_pb";

interface ITaxRatePanel {
  required?: boolean;
  taxRate: TaxRate;
  taxRateTextFormat?: string; // tax rate format when shown as text
  taxRegNumTextFormat?: string; // tax reg num format when show as text
  inputSuffix?: string;
  onChangeTaxRate?: (value: number) => void;
  onChangeTaxRegNum?: (value: string) => void;
  showAsText?: boolean; // convert to text when configured
  inputOnly?: boolean; // show input component only
  fixedNameWidth?: boolean;
  showEditButton?: boolean;
  showDeleteButton?: boolean;
  showTaxRegInput?: boolean;
  onDelete?: () => void;
  onEdit?: () => void;
  className?: string;
  isLocked?: boolean;
}

const TaxRatePanel: React.FC<ITaxRatePanel> = React.memo(
  ({
    taxRate,
    taxRateTextFormat,
    taxRegNumTextFormat,
    required = false,
    inputSuffix,
    fixedNameWidth = false,
    inputOnly = false,
    onDelete,
    onEdit,
    showAsText = false,
    showEditButton = true,
    showDeleteButton = true,
    showTaxRegInput = false,
    isLocked = false,
    onChangeTaxRate,
    onChangeTaxRegNum,
  }) => {
    const [isConfigured, setIsConfigured] = useState(false);
    const [isEditing, setIsEditing] = useState(false);

    useLayoutEffect(() => {
      setIsConfigured(showAsText && taxRate.getRate() > -1);
    }, [showAsText]);

    useLayoutEffect(() => {
      // show as input if new tax rate has been added
      if (onChangeTaxRegNum && taxRate.getRate() < 0 && taxRate.getTaxRegNumber() === "") {
        setIsEditing(true);
      }
    }, [taxRate]);

    const isText = isConfigured && !isEditing;

    const renderInput = () => {
      return (
        <>
          <Input
            className="tax-rate-panel__input"
            type="number"
            required={required}
            value={taxRateTextFormat && isText ? taxRateTextFormat : taxRate.getRate() < 0 ? "" : +taxRate.getRate().toFixed(2)}
            id={"tax-rate"}
            placeholder="10.00"
            step={"0.01"}
            showAsText={isText}
            onChange={(evt: any) => onChangeTaxRate && onChangeTaxRate(taxRateChecker(evt.target.value))}
          />
          {!isText && inputSuffix && <Text className="tax-rate-panel__input__suffix" content={inputSuffix} shouldTranslate={false} noMargin />}
        </>
      );
    };

    if (inputOnly) {
      return <div className="tax-rate-panel__input__container">{renderInput()}</div>;
    }

    return (
      <Panel className="tax-rate-panel" noMargin>
        <div className={`tax-rate-panel__name ${fixedNameWidth ? "tax-rate-panel__name--fixed" : ""}`}>
          <Text content={taxRate.getName()} shouldTranslate={false} noMargin />
        </div>
        <span className="divider divider--vertical" />

        <div className="tax-rate-panel__input__container">
          {!isText && <Text content="ADD_TAX_RATE" noMargin />}
          {renderInput()}
          <Text content="%" shouldTranslate={false} noMargin />

          {onChangeTaxRegNum && showTaxRegInput && (
            <div className="tax-rate-panel__input__tax-reg-num">
              {!isText && <Text content="TAX_REGISTRATION_NUMBER_REG_NUM" noMargin />}
              <Input
                className="tax-rate-panel__input"
                type="text"
                required={required}
                value={isText ? taxRegNumTextFormat : taxRate.getTaxRegNumber()}
                id={"tax-reg-num"}
                placeholder="123-456-7890"
                showAsText={isText}
                onChange={(evt: any) => onChangeTaxRegNum && onChangeTaxRegNum(evt.target.value)}
              />
            </div>
          )}
        </div>

        <div className="tax-rate-panel__actions">
          {showEditButton && isText && (
            <i
              className="fas fa-edit edit"
              onClick={() => {
                if (onEdit) {
                  return onEdit();
                }
                setIsEditing(!isEditing);
              }}
            />
          )}
          {showDeleteButton && onDelete && <i className={`${isLocked ? "fas fa-lock" : "far fa-trash-alt"} delete`} onClick={() => !isLocked && onDelete()} />}
        </div>
      </Panel>
    );
  }
);

export default TaxRatePanel;
