import React, { useEffect, useMemo } from "react";
import Panel from "../../ui/panel/Panel";
import Text from "../../ui/text/Text";
import { DashboardChartType, DashboardChartPeriod } from "../../../models/Dashboard";
import counterpart from "counterpart";
import DashboardChart from "../dashboard-chart/DashboardChart";
import { Switch, useRouteMatch, useHistory } from "react-router";
import TabbedContainer from "../../ui/tabbed-container/TabbedContainer";
import { NavLink } from "react-router-dom";
import { PrivateRoute } from "../../../containers/AuthRoutes";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../../..";
import { fetchDashboardChartsData } from "../../../actions/dashboardActions";
import moment from "moment";

interface IDashboardChartSection {
  type: DashboardChartType,
}

const labelsMap = {
  [DashboardChartType.SALES]: {
    title: "DASHBOARD_SALES_HEADING_TITLE",
    content: () => <Text content={"DASHBOARD_SALES_HEADING_CONTENT"} noMargin />
  },
  [DashboardChartType.PAYMENTS]: {
    title: "DASHBOARD_PAYMENTS_HEADING_TITLE",
    content: () => (
      <>
        <Text content="DASHBOARD_PAYMENTS_HEADING_CONTENT" noMargin component="span" />
        <span> <b>{counterpart("DASHBOARD_EXCLUDING")}</b> </span>
        <Text content="DASHBOARD_PAYMENTS_HEADING_CONTENT3" noMargin component="span" />
      </>
    )
  },
  [DashboardChartType.REFUNDS]: {
    title: "DASHBOARD_REFUNDS_HEADING_TITLE",
    content: () => (
      <>
        <Text content="DASHBOARD_REFUNDS_HEADING_CONTENT" noMargin component="span" />
        <span> <b>{counterpart("DASHBOARD_EXCLUDING")}</b> </span>
        <Text content="DASHBOARD_REFUNDS_HEADING_CONTENT2" noMargin component="span" />
      </>
    )
  },
  [DashboardChartType.MRR]: {
    title: "DASHBOARD_MRR_HEADING_TITLE",
    content: () => <Text content={"DASHBOARD_MRR_HEADING_CONTENT"} noMargin />
  },
  [DashboardChartType.CMRR]: {
    title: "DASHBOARD_CMRR_HEADING_TITLE",
    content: () => <Text content={"DASHBOARD_MRR_HEADING_CONTENT"} noMargin />
  },
  [DashboardChartType.SIGNUPS]: {
    title: "DASHBOARD_SIGNUPS_HEADING_TITLE",
    content: () => <Text content={"DASHBOARD_SIGNUPS_HEADING_CONTENT"} noMargin />
  },
  [DashboardChartType.CHURN]: {
    title: "DASHBOARD_CHURN_HEADING_TITLE",
    content: () => <Text content={"DASHBOARD_CHURN_HEADING_CONTENT"} noMargin />
  },
  [DashboardChartType.CREDITS]: {
    title: "DASHBOARD_CREDITNOTES_HEADING_TITLE",
    content: () => <Text content={"DASHBOARD_CREDITNOTES_HEADING_CONTENT"} noMargin />
  }
}

const DashboardChartSection: React.FC<IDashboardChartSection> = ({ type }) => {

  const authReducer = useSelector((state: AppState) => state.auth);
  const dashboardReducer = useSelector((state: AppState) => state.dashboardReducer);
  const dispatch = useDispatch();

  const match = useRouteMatch();
  const history = useHistory();

  const labels = labelsMap[type];
  const { currentCompanyDomain, companies, currentCompanyId } = authReducer;
  const { chartData } = dashboardReducer;

  const currentCompany = useMemo(() => {
    if (currentCompanyId) {
      return companies.find(c => c.companyId === currentCompanyId)
    }
  }, [companies, currentCompanyId]);

  useEffect(() => {
    if(type === DashboardChartType.MRR) {
      history.push(`${match?.path}/${DashboardChartPeriod.THREEMONTHS}`)
    }
  }, [type])

  const isLessThanMonth = ((months: number) => {
    if (currentCompany) {
      const currentDate = moment().utc().format("MMM DD YYYY");
      const goLiveDate = moment(currentCompany.goLiveOn).utc().format("MMM DD YYYY");
      return (moment(moment(currentDate).utc().subtract(months, "months").subtract(1, "days")).utc().isSameOrBefore(goLiveDate))
    }

    return false
  })

  const fetchChartsData = (period: DashboardChartPeriod) => dispatch(fetchDashboardChartsData(currentCompanyDomain, type, period));

  return (
    <div>
      <TabbedContainer type="submenu">
        {type !== DashboardChartType.MRR ? <NavLink exact activeClassName={"active"} to={`${match?.path}`}>
          {counterpart("DASHBOARD_PERIOD_DAILY")}
        </NavLink> : <div />}
        <NavLink exact activeClassName="active" to={`${match?.path}/${DashboardChartPeriod.THREEMONTHS}`}>
          {counterpart("DASHBOARD_PERIOD_THREE_MONTHS")}
        </NavLink>
        <NavLink exact activeClassName="active" to={`${match?.path}/${DashboardChartPeriod.SIXMONTHS}`}>
          {counterpart("DASHBOARD_PERIOD_SIX_MONTHS")}
        </NavLink>
        <NavLink exact activeClassName="active" to={`${match?.path}/${DashboardChartPeriod.TWELVEMONTHS}`}>
          {counterpart("DASHBOARD_PERIOD_TWELVE_MONTHS")}
        </NavLink>
      </TabbedContainer>

      <Panel title={labels.title}>
        {labels.content()}
      </Panel>

      <Switch>
        <PrivateRoute
          exact
          path={`${match?.path}`}
          render={() => <DashboardChart type={type} period={DashboardChartPeriod.DAILY} chartData={chartData} fetchChartsData={fetchChartsData} />}
        />
        <PrivateRoute
          exact
          path={`${match?.path}/${DashboardChartPeriod.THREEMONTHS}`}
          render={() => <DashboardChart type={type} period={DashboardChartPeriod.THREEMONTHS} chartData={chartData} fetchChartsData={fetchChartsData} />}
        />
        <PrivateRoute
          exact
          path={`${match?.path}/${DashboardChartPeriod.SIXMONTHS}`}
          render={() => <DashboardChart showChart={type === DashboardChartType.PAYMENTS ? !isLessThanMonth(4) : true} type={type} period={DashboardChartPeriod.SIXMONTHS} chartData={chartData} fetchChartsData={fetchChartsData} />}
        />
        <PrivateRoute
          exact
          path={`${match?.path}/${DashboardChartPeriod.TWELVEMONTHS}`}
          render={() => <DashboardChart showChart={type === DashboardChartType.PAYMENTS ? !isLessThanMonth(7) : true} type={type} period={DashboardChartPeriod.TWELVEMONTHS} chartData={chartData} fetchChartsData={fetchChartsData} />}
        />
      </Switch>
    </div>
  )
}

export default DashboardChartSection;