import React, { useEffect, useMemo, useState } from "react";
import "./CheckoutPreview.scss";
import "./CheckoutPreviewNew.scss";
import Text from "../../../../ui/text/Text";
import Button from "../../../../ui/button/Button";
import { useSelector } from "react-redux";
import { AppState } from "../../../../..";
import NumberPicker from "../../../../ui/number-picker/NumberPicker";
import Coaching from "../../../../../images/branding/coaching.png";
import Fitness from "../../../../../images/branding/fitness.png";
import Towel from "../../../../../images/branding/towel.png";
import NavigationBarCheckout from "../../../../ui/navigation-bar-checkout/NavigationBarCheckout";
import { hexToRgb } from "../../../../../utils/commonUtils";
import WebFont from "webfontloader"

interface ICheckoutPreviewProps {
  children?: JSX.Element[] | JSX.Element,
  title?: string,
  subTitle?: string,
  btnText?: string,
  showNavigationBar?: boolean,
  showLink?: boolean,
  showCart?: boolean
}

const CheckoutPreview: React.FC<ICheckoutPreviewProps> = ({ children, btnText = "BRAND_CUSTOMISATION_PAGES_PREVIEW_CONTINUE", showLink = false, showCart = true, showNavigationBar = true, title = "CHECKOUT_PREVIEW_TITLE", subTitle = "CHECKOUT_PREVIEW_SUBTITLE" }) => {
  const companyBrandingReducer = useSelector((state: AppState) => state.companyBrandingReducer);
  
  const { typographyBody, typographyHeader, typographyHeaderIsAllCaps, backgroundColor, headerAndButtonColor, bodyTextColor, confirmationColor, errorColor, rounding } = companyBrandingReducer;
  const [bottomScrolled, setBottomScrolled] = useState(false);

  const backgroundColorStyle = useMemo(() => `
  .checkout-preview-new {
    background-color: ${backgroundColor}
  }
  .checkout-preview-new__body__gradient {
    background:  linear-gradient(0deg, rgba(${hexToRgb(backgroundColor)},1) 9%, rgba(${hexToRgb(backgroundColor)},0) 55%)
  }
`, [backgroundColor]);

  const headerAndButtonColorStyle = useMemo(() => `
  .checkout-preview-new__btn,
  .cart__button,
  .checkout-preview-new .number-input__btn,
  .navigation-bar__filler,
  .navigation-bar__text {
    background-color: ${headerAndButtonColor}
  }
  .checkout-preview-new__header__title,
  .checkout-preview-new__panel__title,
  .checkout-preview-new__header__icon {
    color: ${headerAndButtonColor}
  }
  .navigation-bar__container {
    background-color: rgba(${hexToRgb(headerAndButtonColor)}, 0.5)
  }
`, [headerAndButtonColor]);

  const bodyTextColorStyle = useMemo(() => `
  .checkout-preview-new__body-text {
    color: ${bodyTextColor}
  }
`, [bodyTextColor])

  const confirmationColorStyle = useMemo(() => `
  .checkout-preview-new__btn.checkout-preview-new__btn--selected {
    background-color: ${confirmationColor}
  }
`, [confirmationColor]);

  const errorColorStyle = useMemo(() => `
  .checkout-preview-new .number-input__btn.lock {
    background-color: ${errorColor}
  }
`, [errorColor]);

  const roundingStyle = useMemo(() => `
  .checkout-preview-new,
  .checkout-preview-new__btn,
  .checkout-preview-new__panel {
    border-radius: ${rounding}px
  }
  .checkout-preview-new .number-input__btn.lock,
  .cart__button {
    border-radius: ${rounding}px 0px 0px ${rounding}px
  }
  .checkout-preview-new .number-input__btn {
    border-radius: 0px ${rounding}px ${rounding}px 0px
  }
  .navigation-bar__text {
    border-top-right-radius: ${rounding}px
  }
`, [rounding]);

  const headerFontStyle = useMemo(() => `
  .checkout-preview-new__header__title,
  .checkout-preview-new__panel__title,
  .checkout-preview-new__header__icon {
    font-family: ${typographyHeader.value};
    text-transform: ${typographyHeaderIsAllCaps ? "uppercase" : "unset"}
  }
  `, [typographyHeaderIsAllCaps, typographyHeader]);

  const bodyFontStyle = useMemo(() => `
  .checkout-preview-new__body-text,
  .checkout-preview-new__btn span {
    font-family: ${typographyBody.value};
  }
  `, [typographyBody])

  useEffect(() => {
    WebFont.load({
      google: {
        families: [typographyHeader.value, typographyBody.value],
      },
    })
  }, [typographyHeader, typographyBody])

  const onScrollContainer = (evt: any) => {
    if (evt.target.scrollTop >= (evt.target.scrollHeight - evt.target.offsetHeight)) {
      setBottomScrolled(true);
    } else {
      setBottomScrolled(false);

    }
  }

  const renderHeader = () => {
    return (
      <div className="checkout-preview-new__header">
        <div className="checkout-preview-new__header__icon">
          <i className="far fa-chevron-left"></i>
        </div>
        <div className="checkout-preview-new__header__content">
          <Text className="checkout-preview-new__header__title" content={title} weight="bold" noMargin></Text>
          {subTitle && <Text className="checkout-preview-new__body-text checkout-preview-new__header__subtitle" content={subTitle} noMargin></Text>}
        </div>
        <div className="checkout-preview-new__header__icon">
          <i className="far fa-times"></i>
        </div>
      </div>
    )
  }

  const renderContents = () => {
    if (children) {
      return (
        <div className="checkout-preview-new__body" onScroll={onScrollContainer}>
          <div className="checkout-preview-new__body__gradient" style={{ bottom: "97px", opacity: bottomScrolled ? 0 : 1 }}></div>
          {children}
        </div>
      )
    }

    return (
      <div className="checkout-preview-new__body" onScroll={onScrollContainer}>
        <div className="checkout-preview-new__body__gradient" style={{ bottom: "82px", opacity: bottomScrolled ? 0 : 1 }}></div>
        <div className="checkout-preview-new__panel" style={{ minHeight: "104px" }}>
          <img src={Coaching} alt="coaching"></img>
          <div className="checkout-preview-new__panel__content">
            <Text className="checkout-preview-new__panel__title" content="CHECKOUT_PREVIEW_PANEL1_TITLE" weight="bold" noMargin></Text>
            <Text className="checkout-preview-new__body-text checkout-preview-new__panel__subtitle" content="CHECKOUT_PREVIEW_PANEL1_SUBTITLE" noMargin></Text>
          </div>
          <div className="checkout-preview-new__panel__action">
            <NumberPicker value={1} minLock onMinus={() => { }} onPlus={() => { }}></NumberPicker>
            <Text className="checkout-preview-new__body-text" content="£0.00 " shouldTranslate={false} component="span" weight="bold" noMargin></Text>
            <Text className="checkout-preview-new__body-text checkout-preview-new__panel__action__info" content="(why?)" shouldTranslate={false} component="span" noMargin></Text>
          </div>
        </div>

        <div className="checkout-preview-new__panel" style={{ minHeight: "104px" }}>
          <img src={Towel} alt="towel"></img>
          <div className="checkout-preview-new__panel__content">
            <Text className="checkout-preview-new__panel__title" content="CHECKOUT_PREVIEW_PANEL2_TITLE" weight="bold" noMargin></Text>
            <Text className="checkout-preview-new__body-text checkout-preview-new__panel__subtitle" content="CHECKOUT_PREVIEW_PANEL2_SUBTITLE" noMargin></Text>
          </div>
          <div className="checkout-preview-new__panel__action">
            <Button className="checkout-preview-new__btn" title="£10.00" shouldTranslate={false} id="not-added" icon="fal fa-plus-circle"></Button>
          </div>
        </div>

        <div className="checkout-preview-new__panel" style={{ minHeight: "104px" }}>
          <img src={Fitness} alt="fitness"></img>
          <div className="checkout-preview-new__panel__content">
            <Text className="checkout-preview-new__panel__title" content="CHECKOUT_PREVIEW_PANEL3_TITLE" weight="bold" noMargin></Text>
            <Text className="checkout-preview-new__body-text checkout-preview-new__panel__subtitle" content="CHECKOUT_PREVIEW_PANEL3_SUBTITLE" noMargin></Text>
          </div>
          <div className="checkout-preview-new__panel__action">
            <Button className="checkout-preview-new__btn checkout-preview-new__btn--selected" title="£50.00" shouldTranslate={false} id="added" icon="fas fa-check-circle"></Button>
          </div>
        </div>
      </div>
    )
  }

  const renderNewCheckoutPreview = () => {
    return (
      <>
        <style id="123">
          {`
            ${backgroundColorStyle}
            ${headerAndButtonColorStyle}
            ${bodyTextColorStyle}
            ${confirmationColorStyle}
            ${errorColorStyle}
            ${roundingStyle}
            ${headerFontStyle}
            ${bodyFontStyle}
          `}
        </style>
        <div className="checkout-preview-new">
          {renderHeader()}
          {renderContents()}
          <div className="checkout-preview-new__footer">
            <Button className="checkout-preview-new__btn" id="continue" isFullWidth title={btnText}></Button>
            {showLink && <Text className="checkout-preview-new__footer__link" content="CHECKOUT_PREVIEW_CANCEL_CONTINUE"></Text>}
          </div>

          {showCart && <div className="cart__button">
            <i className="far fa-angle-left"></i>
            <i className="fal fa-shopping-cart"></i>
          </div>}
          {showNavigationBar && <NavigationBarCheckout />}
        </div>
      </>
    )
  }

  return renderNewCheckoutPreview()
}

export default CheckoutPreview;