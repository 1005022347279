import React, { useState } from "react"
import { useDispatch, useSelector } from "react-redux";
import { setPaymentGatewaysField, fetchPaymentGateways, cancelLastPaymentGateway } from "../../../../../actions/paymentGatewaysActions";
import { ConfigConstants } from "../../../../../utils/config";
import { IPaymentGatewayElement } from "../../../../../models/Configuration";
import { UpdateAdyenGatewayRequest } from "../../../../../utils/grpc/generated/Billsby.Protos/billing/private/paymentgateway/payment_gateway_pb";
import { PaymentGatewayServiceClient } from "../../../../../utils/grpc/generated/Billsby.Protos/billing/private/paymentgateway/Payment_gatewayServiceClientPb";
import { grpcUnaryCall } from "../../../../../utils/grpc/grpcUtils";
import PaymentGatewayContainer from "../payment-gateways-shared-components/PaymentGatewayContainer";
import AdyenPaymentGatewayForm from "./AdyenPaymentGatewayForm";
import { AppState } from "../../../../..";
import { IAuthReducerState } from "../../../../../reducers/authReducer";

interface Props {
  paymentGateway: IPaymentGatewayElement,
  isOneGatewayPending: boolean,
  currentGatewayState: any,
  idx: number,
  isCreatingGateway: boolean,
  onError: () => void,
}

interface IData {
  username: string,
  merchantAccount: string,
  subdomain: string
}

const AdyenPaymentGatewayUpdate: React.FC<Props> = ({ onError, paymentGateway, isOneGatewayPending, currentGatewayState, idx, isCreatingGateway }) => {
  const { currentCompanyDomain } = useSelector<AppState, IAuthReducerState>(state => state.auth)
  const dispatch = useDispatch<Function>()
  const data = paymentGateway.data as IData;
  const [helpTitle, setHelpTitle] = useState("PAYMENT_GATEWAYS_ADDED_ADYEN_HELP");
  const [helpContent, setHelpContent] = useState(["PAYMENT_GATEWAYS_ADDED_ADYEN_HELP_CONTENT_1", "PAYMENT_GATEWAYS_ADDED_ADYEN_HELP_CONTENT_2"]);

  const cancelChanges = () => {
    paymentGateway.mustActivate ? dispatch(cancelLastPaymentGateway())
      : dispatch(setPaymentGatewaysField(`paymentGateway${idx}`, { ...currentGatewayState, displayName: paymentGateway.title, isCollapsed: true }))
  }

  const updatePaymentGateway = async (displayName: string, username: string, password: string, merchantAccount: string, subdomain: string) => {
    const updateAdyenGatewayRequest = new UpdateAdyenGatewayRequest();
    updateAdyenGatewayRequest.setCompanyDomain(currentCompanyDomain);
    updateAdyenGatewayRequest.setDisplayName(displayName);
    updateAdyenGatewayRequest.setUsername(username);
    updateAdyenGatewayRequest.setPassword(password);
    updateAdyenGatewayRequest.setMerchantAccount(merchantAccount);
    updateAdyenGatewayRequest.setSubdomain(subdomain);
    updateAdyenGatewayRequest.setPaymentGatewayId(paymentGateway.paymentGatewayId as number)
    const paymentGatewayServiceClient = new PaymentGatewayServiceClient(ConfigConstants.grpcBaseUrl);
    try {
      await grpcUnaryCall(updateAdyenGatewayRequest, paymentGatewayServiceClient, paymentGatewayServiceClient.updateAdyenGateway);
      await dispatch(fetchPaymentGateways(currentCompanyDomain));
      dispatch(setPaymentGatewaysField(`paymentGateway${idx}`, { ...currentGatewayState, isCollapsed: true }));
    } catch {
      onError();
    }
  }

  return (
    <PaymentGatewayContainer
      helpTitle={helpTitle}
      helpContent={helpContent}
      description="PAYMENT_GATEWAYS_ADYEN_DESCRIPTION"
      isOneGatewayPending={isOneGatewayPending}
      currentGatewayState={currentGatewayState}
      onClick={() => dispatch(setPaymentGatewaysField(`paymentGateway${idx}`, { ...currentGatewayState, isCollapsed: paymentGateway.mustActivate ? false : !currentGatewayState.isCollapsed }))}
      websiteLink="https://www.adyen.com/"
      type="edit"
      paymentGateway={paymentGateway}
    >
      <AdyenPaymentGatewayForm
        onSave={(displayName: string, username: string, password: string, merchantAccount: string, subdomain: string) => updatePaymentGateway(displayName, username, password, merchantAccount, subdomain)}
        onCancel={() => cancelChanges()}
        setHelpTitle={(text: string) => setHelpTitle(text)}
        setHelpContent={(text: Array<string>) => setHelpContent(text)}
        isSaveEnabled={!isCreatingGateway}
        isEdit={true}
        initDisplayName={paymentGateway.title}
        initUsername={data.username}
        initMerchantAccount={data.merchantAccount}
        initSubdomain={data.subdomain}
      />
    </PaymentGatewayContainer>
  )
}

export default AdyenPaymentGatewayUpdate
