import { BillsbyAction } from "../models/BillsbyAction";
import { AppState } from "..";
import { FETCH_TIME_REMAINING_SUCCESS, FETCH_EXIT_REASONS_SUCCESS, FETCH_RETENTION_STEPS_SUCCESS, FETCH_HELP_INTERRUPT_SUCCESS, FETCH_REASONS_TO_STAY_SUCCESS, FETCH_REFUND_POLICY_SUCCESS, RESET_REFUND_POLICY, SET_REFUND_POLICY, SET_CANCEL_SUB_CONFIG_FIELD, RESET_CANCEL_SUB_CONFIG, FETCH_PLAN_CHANGE_SUCCESS, FETCH_DISCOUNT_OFFER_SUCCESS, UPLOAD_DISCOUNT_OFFER_IMG_SUCCESS } from "../actions/cancelSubscriptionConfigActions";
import { RefundPolicyType } from "../models/RefundPolicy";
import { GetRefundPolicyResponse } from "../utils/grpc/generated/Billsby.Protos/billing/private/refundpolicy/refundpolicy_pb";
import { GetHelpInterruptResponse, GetReasonsToStayResponse, GetTimeRemainingResponse, GetPlanChangeResponse, GetRetentionStepsResponse, GetDiscountOfferResponse } from "../utils/grpc/generated/Billsby.Protos/core/private/companyconfig/retentionstep/retention_step_pb";
import { RetentionStepType } from "../models/Subscription";
import { GetExitReasonsConfigResponse } from "../utils/grpc/generated/Billsby.Protos/billing/private/company/exitreason/exit_reason_config_pb";
import { FrequencyType } from "../utils/grpc/generated/Billsby.Protos/core/private/companyconfig/retentionstep/retention_step_pb";
import { getFrequencyText, divideNumberBy100 } from "../utils/commonUtils";
import { FrequencyType as  ProductFrequencyType } from "../models/Product";

export interface IHelpInterrupt extends GetHelpInterruptResponse.AsObject {
  provideEmailAddressEnabled: boolean,
  providePhoneNumberEnabled: boolean
}

export interface IRetentionStep {
  type: RetentionStepType,
  title: string;
  subTitle: string;
  isEnabled: boolean;
  orderSequenceNumber: number;
  url: string
}

export interface IExitReason extends GetExitReasonsConfigResponse.ExitReasonConfig.AsObject {
  temporaryId?: number
}

export interface ICancelSubscriptionConfigState {
  refundPolicy: RefundPolicyType;
  selectedRefundPolicy: RefundPolicyType;
  exitReason: string;
  exitReasonsList: Array<IExitReason>;
  retentionStepsList: Array<IRetentionStep>;
  //Retention steps
  helpInterrupt: IHelpInterrupt,
  reasonsToStay: GetReasonsToStayResponse.AsObject,
  timeRemaining: GetTimeRemainingResponse.AsObject,
  planChange: GetPlanChangeResponse.AsObject,
  offerDiscount: boolean,
  discountPercentage?: number,
  discountAllCustomers: boolean,
  discountFrequency?:number,
  discountFrequencyType?: {label: string, value: FrequencyType},
  discountMinimumTenure?:number,
  discountMinimumTenureType?: {label: string, value: FrequencyType},
  discountLimitToOne: boolean,
  exitIntentImage?: string
}

export let initialRetentionStepsList: Array<IRetentionStep> = [
  { type: RetentionStepType.HelpInterrupt, title: "RETENTION_STEPS_HELP_INTERRUPT", subTitle: "RETENTION_STEPS_STEP1_CONTENT", isEnabled: false, orderSequenceNumber: 1, url: "/help-interrupt" },
  { type: RetentionStepType.ReasonsToStay, title: "RETENTION_STEPS_REASONS_TO_STAY", subTitle: "RETENTION_STEPS_STEP2_CONTENT", isEnabled: false, orderSequenceNumber: 2, url: "/reasons-to-stay" },
  { type: RetentionStepType.TimeRemaining, title: "RETENTION_STEPS_TIME_REMAINING", subTitle: "RETENTION_STEPS_STEP3_CONTENT", isEnabled: false, orderSequenceNumber: 3, url: "/time-remaining" },
  { type: RetentionStepType.PlanChange, title: "RETENTION_STEPS_PLAN_CHANGE", subTitle: "RETENTION_STEPS_STEP4_CONTENT", isEnabled: false, orderSequenceNumber: 4, url: "/plan-change" },
  { type: RetentionStepType.DiscountOffer, title: "RETENTION_STEPS_DISCOUNT_OFFER", subTitle: "RETENTION_STEPS_STEP5_CONTENT", isEnabled: false, orderSequenceNumber: 5, url: "/discount-offer" }
]

export let initialExitReasonsList: Array<GetExitReasonsConfigResponse.ExitReasonConfig.AsObject> = []

export const initialState = {
  refundPolicy: RefundPolicyType.NO_REFUND,
  selectedRefundPolicy: RefundPolicyType.INVALID,
  exitReason: "",
  exitReasonsList: initialExitReasonsList,
  retentionStepsList: initialRetentionStepsList,
  helpInterrupt: {
    title: "Is there anything we can help you with today?",
    subtitle: "Our support team is here to solve your problems.",
    email: "",
    phoneNumberDialCode: "",
    phoneNumberDialCountry: "",
    phoneNumber: "",
    supportPageUrl: "",
    isEnabled: false,
    orderSequenceNumber: 0,
    provideEmailAddressEnabled: false,
    providePhoneNumberEnabled: false
  },
  reasonsToStay: {
    text1: "",
    text2: "",
    text3: "",
    text4: "",
    text5: "",
  },
  timeRemaining: {
    text: "You have paid for unused time. If you cancel any time remaining on this subscription will be lost.",
    isEnabled: false,
    orderSequenceNumber: 0,
  },
  planChange: {
    text: "Maybe one of our other plans would be more suitable to your needs?",
    subText: "Select the plans you’d like to change to, or continue to cancel.",
    isEnabled: false,
    orderSequenceNumber: 0,
  },
  discountOffer: {
    text: "Offer to apply a discount to the customers account for a specified number of cycles if they don’t cancel",
    isEnabled: false,
    orderSequenceNumber: 0,
  },
  offerDiscount: true,
  discountPercentage: 25,
  discountNumberOfCycles: undefined,
  discountAllCustomers: true,
  discountFrequency: 3,
  discountFrequencyType: {label: "months", value: FrequencyType.MONTHLY},
  discountMinimumTenure: 1,
  discountMinimumTenureType: {label: "month", value: FrequencyType.MONTHLY},
  discountLimitToOne: true,
  exitIntentImage: undefined
};

export const getFrequencyType = (frequencyType: FrequencyType, frequency?: number) => {
  let result = { label: getFrequencyText(1, ProductFrequencyType.Daily), value: FrequencyType.DAILY }
  if(!frequency)
    return result;

  switch (frequencyType) {
    case FrequencyType.DAILY: {
      result = { label: getFrequencyText(frequency, ProductFrequencyType.Daily), value: FrequencyType.DAILY };
      break;
    }
    case FrequencyType.WEEKLY: {
      result = { label: getFrequencyText(frequency, ProductFrequencyType.Weekly), value: FrequencyType.WEEKLY };
      break;
    }
    case FrequencyType.MONTHLY: {
      result = { label: getFrequencyText(frequency, ProductFrequencyType.Monthly), value: FrequencyType.MONTHLY };
      break;
    }
    case FrequencyType.YEARLY: {
      result = { label: getFrequencyText(frequency, ProductFrequencyType.Yearly), value: FrequencyType.YEARLY };
      break;
    }
    default: {
      result = { label: getFrequencyText(frequency, ProductFrequencyType.Daily), value: FrequencyType.DAILY };
      break;
    }
  }

  return result;
}
export default function cancelSubscriptionConfigReducer(state: ICancelSubscriptionConfigState = initialState, action: BillsbyAction, store: AppState) {
  switch (action.type) {
    case FETCH_REFUND_POLICY_SUCCESS: {
      const refundPolicy = action.response as GetRefundPolicyResponse;
      return { ...state, refundPolicy: refundPolicy.getRefundPolicy(), selectedRefundPolicy: refundPolicy.getRefundPolicy() };
    }
    case SET_REFUND_POLICY: {
      return { ...state, selectedRefundPolicy: action.payload };
    }
    case RESET_REFUND_POLICY: {
      return { refundPolicy: initialState.refundPolicy, selectedRefundPolicy: initialState.selectedRefundPolicy };
    }
    case SET_CANCEL_SUB_CONFIG_FIELD: {
      return { ...state, [action.fieldName]: action.fieldValue };
    }

    case FETCH_EXIT_REASONS_SUCCESS: {
      const response = action.response as GetExitReasonsConfigResponse;
      const reasonsList = response.getResultsList();

      const mappedReasonsList: Array<IExitReason> = reasonsList.map(result => (
        {
          exitReasonConfigId: result.getExitReasonConfigId(),
          text: result.getText(),
          orderSequenceNumber: result.getOrderSequenceNumber(),
          isArchived: result.getIsArchived(),
        }
      ));

      mappedReasonsList.sort((a, b) => {
        if (a && b) {
          if (a.orderSequenceNumber > b.orderSequenceNumber) {
            return 1;
          } else return -1
        }

        return 0;
      });

      initialExitReasonsList = mappedReasonsList;

      return {
        ...state, exitReasonsList: mappedReasonsList
      }
    }

    case FETCH_RETENTION_STEPS_SUCCESS: {
      const response = action.response as GetRetentionStepsResponse;
      const helpInterrupt = response.getHelpInterrupt();
      const reasonsToStay = response.getReasonsToStay();
      const timeRemaining = response.getTimeRemaining();
      const planChange = response.getPlanChange();
      const discountOffer = response.getDiscountOffer();

      const retentionStepsList = state.retentionStepsList.map(step => {
        if (helpInterrupt && step.type === RetentionStepType.HelpInterrupt) {
          return { ...step, orderSequenceNumber: helpInterrupt.getOrderSequenceNumber(), isEnabled: helpInterrupt.getIsEnabled() }
        }
        if (reasonsToStay && step.type === RetentionStepType.ReasonsToStay) {
          return { ...step, orderSequenceNumber: reasonsToStay.getOrderSequenceNumber(), isEnabled: reasonsToStay.getIsEnabled() }
        }
        if (timeRemaining && step.type === RetentionStepType.TimeRemaining) {
          return { ...step, orderSequenceNumber: timeRemaining.getOrderSequenceNumber(), isEnabled: timeRemaining.getIsEnabled() }
        }
        if (planChange && step.type === RetentionStepType.PlanChange) {
          return { ...step, orderSequenceNumber: planChange.getOrderSequenceNumber(), isEnabled: planChange.getIsEnabled() }
        }
        if (discountOffer && step.type === RetentionStepType.DiscountOffer) {
          return { ...step, orderSequenceNumber: discountOffer.getOrderSequenceNumber(), isEnabled: discountOffer.getIsEnabled() }
        }
      }) as Array<IRetentionStep>

      retentionStepsList.sort((a, b) => {
        if (a && b) {
          if (a.orderSequenceNumber > b.orderSequenceNumber) {
            return 1;
          } else return -1
        }

        return 0;
      })

      initialRetentionStepsList = retentionStepsList;

      return { ...state, retentionStepsList: retentionStepsList };
    }

    case FETCH_DISCOUNT_OFFER_SUCCESS: {
      const response = action.response as GetDiscountOfferResponse;
      const discountOffer = !!response.getDiscountOffer() ? response.getDiscountOffer() : undefined;

      let frequency;
      let frequencyType = { label: getFrequencyText(3, ProductFrequencyType.Monthly), value: FrequencyType.MONTHLY };
      if(!!discountOffer) {
        frequency = discountOffer.getExpiryFrequency();
        frequencyType = getFrequencyType(discountOffer.getExpiryFrequencyType(), frequency)
      }
      let minimumtenureFrequency;
      let minimumtenureFrequencyType = { label: getFrequencyText(1, ProductFrequencyType.Monthly), value: FrequencyType.MONTHLY };
      if(!!discountOffer && discountOffer.getMinimumTenure()) {
        const minimumTenure = discountOffer.getMinimumTenure() as GetDiscountOfferResponse.DiscountOffer.MinimumTenure;
        minimumtenureFrequency = minimumTenure.getFrequency();
        minimumtenureFrequencyType = getFrequencyType(minimumTenure.getFrequencyType(), minimumtenureFrequency)
      }
      return {
        ...state,
        offerDiscount: !response.getIsDisabled(),
        discountPercentage: !!discountOffer ? Number(divideNumberBy100(discountOffer.getPercentage())) : undefined,
        discountAllCustomers: !!discountOffer ? discountOffer.getEnabledAllCustomers() : undefined,
        discountFrequency: frequency,
        discountFrequencyType: frequencyType,
        discountMinimumTenure: minimumtenureFrequency,
        discountMinimumTenureType: minimumtenureFrequencyType,
        discountLimitToOne: !!discountOffer ? discountOffer.getLimitToOneRetentionDiscountPerSub() : undefined,
        exitIntentImage: !!discountOffer ? discountOffer.getExitIntentImageUrl() : undefined
      }
    }
    case FETCH_HELP_INTERRUPT_SUCCESS: {
      const response = action.response as GetHelpInterruptResponse;
      return {
        ...state,
        helpInterrupt: {
          ...state.helpInterrupt,
          title: response.getTitle(),
          subtitle: response.getSubtitle(),
          email: response.getEmail(),
          phoneNumberDialCode: response.getPhoneNumberDialCode(),
          phoneNumberDialCountry: response.getPhoneNumberDialCountry(),
          phoneNumber: response.getPhoneNumber(),
          supportPageUrl: response.getSupportPageUrl(),
          isEnabled: response.getIsEnabled(),
          orderSequenceNumber: response.getOrderSequenceNumber(),
          providePhoneNumberEnabled: Boolean(response.getPhoneNumber()),
          provideEmailAddressEnabled: Boolean(response.getEmail())
        }
      }
    }

    case FETCH_REASONS_TO_STAY_SUCCESS: {
      const response = action.response as GetReasonsToStayResponse;
      return {
        ...state,
        reasonsToStay: {
          ...state.reasonsToStay,
          text1: response.getText1(),
          text2: response.getText2(),
          text3: response.getText3(),
          text4: response.getText4(),
          text5: response.getText5(),
        }
      }
    }

    case FETCH_TIME_REMAINING_SUCCESS: {
      const response = action.response as GetTimeRemainingResponse;
      return {
        ...state,
        timeRemaining: {
          ...state.timeRemaining,
          text: response.getText()
        }
      }
    }

    case FETCH_PLAN_CHANGE_SUCCESS: {
      const response = action.response as GetPlanChangeResponse;
      return {
        ...state,
        planChange: {
          ...state.planChange,
          text: response.getText(),
          subText: response.getSubText()
        }
      }
    }
    case UPLOAD_DISCOUNT_OFFER_IMG_SUCCESS: {
      return {
        ...state,
        exitIntentImage: action.response.imageSrcUrl
      }
    }

    case RESET_CANCEL_SUB_CONFIG: {
      return initialState;
    }
    default:
      return state;
  }
}
