import { BillsbyAction } from "../../../models/BillsbyAction";
import { AppState } from "../../..";
import { SET_ADD_ONS_STEPS_COLLAPSED, SET_ADD_ONS_FORM_COMPLETED, SET_ADD_ONS_FORM_HAS_ERROR, SET_CREATE_ADD_ONS_BASIC_INFO, RESET_CREATE_ADD_ON_STATE, CREATE_ADD_ONS_REMOVE_FEATURE_TAGS, CREATE_ADD_ONS_ADD_FEATURE_TAGS, FETCH_ADD_ON_SUCCESS, FETCH_COUNTERS_SUCCESS, CREATE_COUNTER_SUCCESS, ADD_ON_IMAGE_UPLOAD_SUCCESS, ADD_ON_IMAGE_UPLOAD_REQUEST, ADD_ON_IMAGE_UPLOAD_FAILURE } from "../../../actions/addOnsActions";
import { PricingModelType } from "../../../models/Product";
import { GetAddonResponse, PricingModelType as PricingModelTypeGrpc } from "../../../utils/grpc/generated/Billsby.Protos/core/private/addons/addons_pb";
import { Counter, CreateCounterResponse, GetCountersResponse } from "../../../utils/grpc/generated/Billsby.Protos/core/private/counters/counters_pb";
import { AddonsAllowancesScreen } from "../../../models/AddonsAllowances";
import { GetAllowanceResponse } from "../../../utils/grpc/generated/Billsby.Protos/core/private/allowances/allowances_pb";
import { getErrorMessage } from "../../../utils/commonUtils";

export interface ICreateAddOnsReducerState {
  helpTextTitleBasicForm: string,
  helpTextContentBasicForm: Array<string>,
  name: string,
  displayName: string,
  description: string,
  singleUnitName: string,
  pluralUnitName: string
  stageCollapsed: Array<boolean>,
  stageStatuses: Array<boolean>,
  stageErrors: Array<boolean>,
  pricingModels: Array<{ text: string, helpTextContentAddon: Array<string>, helpTextContentAllowance: Array<string>, value: PricingModelType }>,
  selectedPricingModel: { text: string, helpTextContentAddon: Array<string>, helpTextContentAllowance: Array<string>, value: PricingModelType },
  hasSelectedPricingModel: boolean,
  helpTextTitlePricingModel: string,
  helpTextContentPricingModel: Array<string>,
  featureTags: Array<{ name: string }>,
  counters?: GetCountersResponse,
  selectedCounter: { label: string, value: Counter }
  newCounter?: CreateCounterResponse,
  isAddOnImageUploading: boolean,
  isAddOnImageEnabled: boolean,
  addOnImageFileName: string,
  addOnError: string,
  addOnImageUrl: string
}

export const initialState = {
  helpTextTitleBasicForm: "CREATE_ADD_ONS_FORM_HELP_TITLE",
  helpTextContentBasicForm: ["CREATE_ADD_ONS_FORM_HELP_TEXT_1", "CREATE_ADD_ONS_FORM_HELP_TEXT_2"],
  name: "",
  displayName: "",
  description: "",
  singleUnitName: "unit",
  pluralUnitName: "units",
  stageCollapsed: [false, true, true, true, true, true, true],
  stageStatuses: [false, false, false, false, false, false],
  stageErrors: [false, false, false, false, false, false],
  pricingModels: [
    {
      text: "CREATE_PLAN_PRICING_MODEL_HELP_FLAT_FEE_TITLE",
      helpTextContentAddon: ["CREATE_PLAN_PRICING_MODEL_HELP_FLAT_FEE_CONTENT"],
      helpTextContentAllowance: [""],
      value: PricingModelType.FlatFee
    },
    {
      text: "CREATE_PLAN_PRICING_MODEL_HELP_PERUNIT_TITLE",
      helpTextContentAddon: ["CREATE_ADD_ONS_PRICING_MODEL_HELP_PERUNIT_CONTENT"],
      helpTextContentAllowance: ["CREATE_ALLOWANCES_PRICING_MODEL_HELP_PERUNIT_CONTENT"],
      value: PricingModelType.PerUnit
    },
    {
      text: "CREATE_PLAN_PRICING_MODEL_HELP_VOLUME_TITLE",
      helpTextContentAddon: ["CREATE_ADD_ONS_PRICING_MODEL_HELP_VOLUME_CONTENT1", "CREATE_ADD_ONS_PRICING_MODEL_HELP_VOLUME_CONTENT2"],
      helpTextContentAllowance: ["CREATE_ALLOWANCES_PRICING_MODEL_HELP_VOLUME_CONTENT1", "CREATE_ALLOWANCES_PRICING_MODEL_HELP_VOLUME_CONTENT2"],
      value: PricingModelType.Volume
    },
    {
      text: "CREATE_PLAN_PRICING_MODEL_HELP_TIERED_TITLE",
      helpTextContentAddon: ["CREATE_ADD_ONS_PRICING_MODEL_HELP_TIERED_CONTENT1", "CREATE_ADD_ONS_PRICING_MODEL_HELP_TIERED_CONTENT2"],
      helpTextContentAllowance: ["CREATE_ALLOWANCES_PRICING_MODEL_HELP_TIERED_CONTENT1", "CREATE_ALLOWANCES_PRICING_MODEL_HELP_TIERED_CONTENT2"],
      value: PricingModelType.Tiered
    },
    {
      text: "CREATE_PLAN_PRICING_MODEL_HELP_RANGED_TITLE",
      helpTextContentAddon: ["CREATE_ADD_ONS_PRICING_MODEL_HELP_RANGED_CONTENT1", "CREATE_ADD_ONS_PRICING_MODEL_HELP_RANGED_CONTENT2"],
      helpTextContentAllowance: ["CREATE_ALLOWANCES_PRICING_MODEL_HELP_RANGED_CONTENT1", "CREATE_ALLOWANCES_PRICING_MODEL_HELP_RANGED_CONTENT2"],
      value: PricingModelType.Ranged
    },
    {
      text: "CREATE_PLAN_PRICING_MODEL_HELP_CAPPED_TITLE",
      helpTextContentAddon: [""],
      helpTextContentAllowance: ["CREATE_PLAN_PRICING_MODEL_HELP_CAPPED_CONTENT"],
      value: PricingModelType.Capped
    }
  ],
  selectedPricingModel: {
    text: "CREATE_PLAN_PRICING_MODEL_HELP_FLAT_FEE_TITLE",
    helpTextContentAddon: ["CREATE_PLAN_PRICING_MODEL_HELP_FLAT_FEE_CONTENT"],
    helpTextContentAllowance: [""],
    value: PricingModelType.FlatFee
  },
  hasSelectedPricingModel: false,
  helpTextTitlePricingModel: "CREATE_PLAN_PRICING_MODEL_HELP_FLAT_FEE_TITLE",
  helpTextContentPricingModel: ["CREATE_PLAN_PRICING_MODEL_HELP_FLAT_FEE_CONTENT"],
  featureTags: [],
  selectedCounter: { label: "", value: new Counter() },
  isAddOnImageUploading: false,  
  isAddOnImageEnabled: false,
  addOnImageFileName: "",
  addOnError: "",
  addOnImageUrl: ""
}

export default function createAddOnsReducer(state: ICreateAddOnsReducerState = initialState, action: BillsbyAction, store: AppState) {
  switch (action.type) {
    case SET_CREATE_ADD_ONS_BASIC_INFO:
      return { ...state, [action.fieldName]: action.fieldValue }
    case SET_ADD_ONS_STEPS_COLLAPSED: {
      let panelToCollapse = action.payload;
      let newArray = [...state.stageCollapsed];

      newArray = newArray.map(el => (el !== panelToCollapse ? true : el));
      newArray[panelToCollapse] = false;

      return { ...state, stageCollapsed: newArray }
    }
    case SET_ADD_ONS_FORM_COMPLETED: {
      let newArray = [...state.stageStatuses];
      newArray[action.stageIndex] = action.value;
      return { ...state, stageStatuses: newArray }
    }
    case SET_ADD_ONS_FORM_HAS_ERROR: {
      let newArray = [...state.stageErrors];
      newArray[action.stageIndex] = action.value;
      return { ...state, stageErrors: newArray }
    }
    case CREATE_ADD_ONS_ADD_FEATURE_TAGS: {
      action.payload.name = action.payload.name.replace(/[^a-z0-9]/g, "");
      let tags = [...state.featureTags];
      if (action.payload.name && action.payload.name.trim() !== "") {
        tags.push(action.payload);
      }
      return { ...state, featureTags: tags }
    }
    case CREATE_ADD_ONS_REMOVE_FEATURE_TAGS: {
      let tags = [...state.featureTags];
      tags.splice(action.payload, 1);
      return { ...state, featureTags: tags }
    }
 
    case FETCH_ADD_ON_SUCCESS: {
      const type = action.fetchType as AddonsAllowancesScreen;
      const isAddonScreen = type === AddonsAllowancesScreen.ADDONS;
      const addOn: GetAddonResponse | GetAllowanceResponse = action.response;
      const pricingModelTypeMapping = {
        [PricingModelTypeGrpc.UNSPECIFIED_PMT]: PricingModelType.FlatFee,
        [PricingModelTypeGrpc.FLAT_FEE]: PricingModelType.FlatFee,
        [PricingModelTypeGrpc.PER_UNIT]: PricingModelType.PerUnit,
        [PricingModelTypeGrpc.TIERED]: PricingModelType.Tiered,
        [PricingModelTypeGrpc.RANGED]: PricingModelType.Ranged,
        [PricingModelTypeGrpc.VOLUME]: PricingModelType.Volume,
        [PricingModelTypeGrpc.CAPPED]: PricingModelType.Capped
      }
      const name = addOn.getName();
      const displayName = addOn.getDisplayName();
      const description = addOn.getDescription();
      const singleUnitName = addOn.getSingleUnitName();
      const pluralUnitName = addOn.getPluralUnitName();
      const selectedPricingModel = state.pricingModels.find(pricingModel => pricingModel.value === pricingModelTypeMapping[addOn.getPricingModelType()]);
      let selectedCounter =  { label: "", value: new Counter() };
      if(!isAddonScreen) {
        const counter = (addOn as GetAllowanceResponse).getCounter() || new Counter();
        selectedCounter = { label: counter.getCounterName(), value: counter }
      }
      const featureTags = addOn.getFeatureTagsList().map(tag => ({ name: tag }));
      const stageStatuses = [true, true, true, true, true];
      const stageCollapsed = isAddonScreen ? [false, false, true, false, false] : [ false, false, true, false, false, false]
      const isAddOnImageEnabled = (addOn as GetAddonResponse).getIsImageEnabled();
      const addOnImageFileName = (addOn as GetAddonResponse).getImageFilename();
      const addOnImageUrl = (addOn as GetAddonResponse).getImageUrl();

      return { ...state, name, displayName, description, singleUnitName, pluralUnitName, selectedPricingModel, featureTags, 
        stageStatuses, stageCollapsed, selectedCounter, hasSelectedPricingModel: true, isAddOnImageEnabled, addOnImageFileName, addOnImageUrl }
    }
    case FETCH_COUNTERS_SUCCESS:
      return { ...state, counters: action.response }
    
    case CREATE_COUNTER_SUCCESS:
      return { ...state, newCounter: action.response }
    case ADD_ON_IMAGE_UPLOAD_REQUEST:
      return { ...state, isAddOnImageUploading: true }
    case ADD_ON_IMAGE_UPLOAD_FAILURE: {
      const { error } = action;
      return { ...state, isAddOnImageUploading: false, addOnError: getErrorMessage(error) }
    }
    case ADD_ON_IMAGE_UPLOAD_SUCCESS:
      {
        const { response } = action;
        return { ...state, addOnImageFileName: response.fileName, addOnImageUrl: response.imageSrcUrl, addOnError: "", isAddOnImageUploading: false }
      }
    case RESET_CREATE_ADD_ON_STATE:
      return { ...initialState }
    default:
      return state;
  }
}