import React from "react";
import { DragDropContext, Droppable, Draggable, DropResult } from "react-beautiful-dnd";

const reorder = (list: Array<IListElement>, startIndex: number, endIndex: number) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

interface IListElement {
  content: JSX.Element | string,
  value: any
}

interface IDragDropContainer {
  list: Array<IListElement>,
  onDragEnd: (newList: Array<IListElement>) => void
}

const DragDropContainer: React.FC<IDragDropContainer> = React.memo(({ list, onDragEnd }) => {

  const _onDragEnd = (result: DropResult) => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    const newList = reorder(list, result.source.index, result.destination.index);

    onDragEnd(newList.map(el => el.value));
  }


  return (
    <DragDropContext onDragEnd={_onDragEnd}>
      <Droppable droppableId="droppable">
        {(provided, snapshot) => (
          <div
            {...provided.droppableProps}
            ref={provided.innerRef}
          //style={getListStyle(snapshot.isDraggingOver)}
          >
            {
              list.map((item, index) => (
                <Draggable key={`item-${index}`} draggableId={`item-${index}`} index={index}>
                  {(provided, snapshot) => (
                    <div
                      ref={provided.innerRef}
                      {...provided.draggableProps as any}
                      {...provided.dragHandleProps}
                    >
                      {item.content}
                    </div> 
                  )}
                </Draggable>
              ))
            }
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  );
})

export default DragDropContainer;