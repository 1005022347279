import React, { useState } from "react";
import counterpart from "counterpart";
import FormGroup from "../../../../ui/form-group/FormGroup";
import { Row, Col } from "react-grid-system";
import FormLabel from "../../../../ui/form-label/FormLabel";
import Input from "../../../../ui/input/Input";
import PaymentGatewayButtons from "../payment-gateways-shared-components/PaymentGatewayButtons";
import NoticePanel from "../../../../ui/notice-panel/NoticePanel";
import ElementModifiers from "../../../../ui/element-modifiers/ElementModifiers";
import Switch from "../../../../ui/switch/Switch";

interface Props {
  onSave: (displayName: string, merchantId: string, publickKey: string, privateKey: string) => Promise<void>;
  onCancel: () => void;
  setHelpTitle: (text: string) => void;
  setHelpContent: (text: Array<string>) => void;
  initDisplayName: string;
  initMerchantId: string;
  initPublicKey: string;
  initPrivateKey: string;
  initCurrencyCode: string;
  initMerchantAccountId: string;
  isSaveEnabled: boolean;
  isEdit: boolean;
  isSandbox: boolean
}

const BrainTreePaymentGatewayUpdateForm: React.FC<Props> = ({ onSave, onCancel, setHelpTitle, setHelpContent, initDisplayName, initMerchantId, initPublicKey, initPrivateKey, initCurrencyCode, initMerchantAccountId, isSaveEnabled, isEdit, isSandbox }) => {
  const [displayName, setDisplayName] = useState(initDisplayName);
  const [merchantId, setMerchantId] = useState(initMerchantId);
  const [publickKey, setPublicKey] = useState(initPublicKey);
  const [privateKey, setPrivateKey] = useState(initPrivateKey);
  const [selectedMerchant, setSelectedMerchant] = useState(`[${initCurrencyCode} - ${initMerchantAccountId}]`);

  const [hasError, setHasError] = useState(false);

  const clearFields = () => {
    setDisplayName(initDisplayName);
    setMerchantId(initMerchantId);
    setPublicKey(initPublicKey);
    setPrivateKey(initPrivateKey);
  }

  const savePaymentGateway = async () => {
    try {
      await onSave(displayName, merchantId, publickKey, privateKey);
      clearFields();
    } catch {
      setHasError(true);
    }
  }

  const onFocus = (inputField: string) => {
    switch (inputField) {
      case "DisplayName":
        setHelpTitle("PAYMENT_GATEWAYS_ADDED_BRAINTREE_DISPLAY_NAME_HELP_TITLE");
        setHelpContent(["PAYMENT_GATEWAYS_ADDED_BRAINTREE_DISPLAY_NAME_HELP_CONTENT_1"]);
        break;
      case "MerchantId":
        setHelpTitle("PAYMENT_GATEWAYS_ADDED_BRAINTREE_MERCHANT_ID_HELP_TITLE");
        setHelpContent(["PAYMENT_GATEWAYS_ADDED_BRAINTREE_MERCHANT_ID_HELP_CONTENT_1", "PAYMENT_GATEWAYS_ADDED_BRAINTREE_MERCHANT_ID_HELP_CONTENT_2"]);
        break;
      case "PublicKey":
        setHelpTitle("PAYMENT_GATEWAYS_ADDED_BRAINTREE_PUBLIC_KEY_HELP_TITLE");
        setHelpContent(["PAYMENT_GATEWAYS_ADDED_BRAINTREE_PUBLIC_KEY_HELP_CONTENT_1", "PAYMENT_GATEWAYS_ADDED_BRAINTREE_PUBLIC_KEY_HELP_CONTENT_2"]);
        break;
      case "PrivateKey":
        setHelpTitle("PAYMENT_GATEWAYS_ADDED_BRAINTREE_PRIVATE_KEY_HELP_TITLE");
        setHelpContent(["PAYMENT_GATEWAYS_ADDED_BRAINTREE_PRIVATE_KEY_HELP_CONTENT_1", "PAYMENT_GATEWAYS_ADDED_BRAINTREE_PRIVATE_KEY_HELP_CONTENT_2"]);
        break;
      case "MerchantAccount":
        setHelpTitle("PAYMENT_GATEWAYS_ADDED_BRAINTREE_MERCHANT_ACCOUNT_HELP_TITLE");
        setHelpContent(["PAYMENT_GATEWAYS_ADDED_BRAINTREE_MERCHANT_ACCOUNT_HELP_CONTENT_1", "PAYMENT_GATEWAYS_ADDED_BRAINTREE_MERCHANT_ACCOUNT_HELP_CONTENT_2"]);
        break;
    }
  }
  return (
    <div className="payment-gateways__update">
      <FormGroup>
        {hasError && <NoticePanel
          className="payment-gateways__error-panel"
          content="PAYMENT_GATEWAYS_BRAINTREE_ERROR"
          type="error"
          icon="fas fa-exclamation-circle"
          isModal={true}
        />}
        <Row align="center" className="payment-gateways__update__form-group">
          <Col xs={2}>
            <FormLabel target="display-name" content="PAYMENT_GATEWAYS_BRAINTREE_DISPLAY_NAME" noMargin />
          </Col>
          <Col xs={10}>
            <Input
              onFocus={() => onFocus("DisplayName")}
              id="display-name"
              placeholder={counterpart("PAYMENT_GATEWAYS_BRAINTREE_DISPLAY_NAME_PLACEHOLDER")}
              value={displayName}
              onChange={(evt: any) => setDisplayName(evt.target.value)}
            />
          </Col>
        </Row>
        <Row align="center" className="payment-gateways__update__form-group">
          <Col xs={2}>
            <FormLabel target="merchant-id" content="PAYMENT_GATEWAYS_BRAINTREE_MERCHANT_ID" noMargin />
          </Col>
          <Col xs={10}>
            <Input
              id="merchant-id"
              onFocus={() => onFocus("MerchantId")}
              placeholder={counterpart("PAYMENT_GATEWAYS_BRAINTREE_MERCHANT_ID_PLACEHOLDER")}
              value={merchantId}
              onChange={(evt: any) => setMerchantId(evt.target.value)}
            />
          </Col>
        </Row>
        <Row align="center" className="payment-gateways__update__form-group">
          <Col xs={2}>
            <FormLabel target="public-key" content="PAYMENT_GATEWAYS_BRAINTREE_PUBLIC_KEY" noMargin />
          </Col>
          <Col xs={10}>
            <Input
              id="public-key"
              onFocus={() => onFocus("PublicKey")}
              placeholder={counterpart("PAYMENT_GATEWAYS_BRAINTREE_PUBLIC_KEY_PLACEHOLDER")}
              value={publickKey}
              onChange={(evt: any) => setPublicKey(evt.target.value)}
            />
          </Col>
        </Row>
        <Row align="center" className="payment-gateways__update__form-group">
          <Col xs={2}>
            <FormLabel target="private-key" content="PAYMENT_GATEWAYS_BRAINTREE_PRIVATE_KEY" noMargin />
          </Col>
          <Col xs={10}>
            <Input
              id="private-key"
              onFocus={() => onFocus("PrivateKey")}
              placeholder={counterpart("PAYMENT_GATEWAYS_BRAINTREE_PRIVATE_KEY_PLACEHOLDER")}
              value={privateKey}
              onChange={(evt: any) => setPrivateKey(evt.target.value)}
            />
          </Col>
        </Row>

        <Row align="center" className="payment-gateways__update__form-group">
          <Col xs={2}>
            <FormLabel target="merchant-account" content="PAYMENT_GATEWAYS_BRAINTREE_MERCHANT_ACCOUNT" noMargin />
          </Col>
          <Col xs={10}>
            <Input
              id="merchant-account"
              onFocus={() => onFocus("MerchantAccount")}
              disabled={true}
              placeholder={counterpart("PAYMENT_GATEWAYS_BRAINTREE_PRIVATE_KEY_PLACEHOLDER")}
              value={selectedMerchant}
              onChange={(evt: any) => setSelectedMerchant(evt.target.value)}
            />
          </Col>
        </Row>

        <Row align="center" className="payment-gateways__update__form-group disabled">
          <Col xs={2}>
            <FormLabel target="is-sandboxed" content="PAYMENT_GATEWAYS_BRAINTREE_SANDBOX" noMargin />
          </Col>
          <Col xs={9}>
            <Switch id="is-sandbox" checked={isSandbox} onChange={() => { }}></Switch>
          </Col>
          <Col xs={1}><ElementModifiers isShowLockIcon /></Col>
        </Row>
      </FormGroup>

      <PaymentGatewayButtons
        onSave={() => savePaymentGateway()}
        onCancel={() => {
          onCancel();
          clearFields();
        }}
        isEdit={isEdit}
        isSaveEnabled={isSaveEnabled}
      />
    </div>
  )
}

export default BrainTreePaymentGatewayUpdateForm;