import { BillsbyAction } from "../models/BillsbyAction";
import { AppState } from "..";
import { FETCH_AVAILABLE_LANGUAGES_SUCCESS, FETCH_EMBED_CODES_CUSTOM_FIELDS_SUCCESS, RESET_EMBED_CODES_CUSTOM_FIELDS, SET_CUSTOM_FIELD_PANEL_IS_COLLAPSED, SET_SELECTED_EMBED_CODES_CUSTOM_FIELD } from "../actions/embedCodesCheckOutActions";
import { ICustomField } from "../models/CustomFields";
import { GetCompanyLanguagesResponse } from "../utils/grpc/generated/Billsby.Protos/core/private/companies/company_pb";

export interface IEmbedCodesCheckOutReducerState {
  customFieldsPanelIsCollapsed: boolean,
  selectedCustomField?: ICustomField,
  customFields: Array<ICustomField>,
  companyLanguages: Array<{ value: string, label: string }>
}

export const initState = {
  customFieldsPanelIsCollapsed: true,
  selectedCustomField: undefined,
  customFields: [],
  companyLanguages: []
}

export default function embedCodesCheckOutReducer(state: IEmbedCodesCheckOutReducerState = initState, action: BillsbyAction, store: AppState) {
  switch (action.type) {
    case FETCH_EMBED_CODES_CUSTOM_FIELDS_SUCCESS:
      return { ...state, customFields: action.response }
    case RESET_EMBED_CODES_CUSTOM_FIELDS:
      return { ...initState }
    case SET_CUSTOM_FIELD_PANEL_IS_COLLAPSED:
      return { ...state, customFieldsPanelIsCollapsed: action.payload };
    case SET_SELECTED_EMBED_CODES_CUSTOM_FIELD:
      const selectedItem = state.customFields.find(item => item.customFieldId === action.payload.value)
      return { ...state, selectedCustomField: selectedItem }
    case FETCH_AVAILABLE_LANGUAGES_SUCCESS:
      const response = action.response as GetCompanyLanguagesResponse;
      const companyLanguages: Array<{ value: string, label: string }> = [];
      response.getCompanyLanguagesList().forEach(l => {
        if (l.getIsEnabled()) {
          companyLanguages.push({
            value: l.getIsoCode(),
            label: l.getEnglishName()
          })
        }
      })
      companyLanguages.sort((a, b) => {
        if (a.label < b.label) { return -1; }
        if (a.label > b.label) { return 1; }
        return 0;
      })
      return { ...state, companyLanguages }
    default:
      return state;
  }
}