import React, { useState, useEffect } from "react";
import MenuTable from "../../../../../ui/menu-table/MenuTable";
import BackNavigationPanel from "../../../../../ui/back-navigation-panel/BackNavigationPanel";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../../../../../..";
import { updateEmailNotificationToggles, fetchEmailNotificationToggles } from "../../../../../../actions/emailRoutingActions";
import { EmailTypes, UpdateEmailNotificationStatusRequest, UpdateEmailNotificationStatusResponse } from "../../../../../../utils/grpc/generated/Billsby.Protos/Messenger/private/companyconfig/companyconfig_pb";
import { useHistory } from "react-router";

const PaymentCreditsRefundMenu: React.FC = () => {
  const emailNotificationToggles = useSelector((state: AppState) => state.emailRoutingReducer.emailNotificationToggles);
  const currentCompanyId = useSelector((state: AppState) => state.auth.currentCompanyId);

  const dispatch = useDispatch<Function>();
  const history = useHistory();
  
  const [switchState, setSwitchState] = useState({
    subscritionRenewal: false,
    positiveOneTimeCharge: false,
    positiveOneTimeChargePaymentFailed: false,
    negativeOneTimeCharge: false,
    refundIssued: false,
    adjustmentMadeToNextBill: false,
    resendInvoiceLink: false,
    resendCreditNoteLink: false
  });

  const updateData = async (updateEmailNotification: UpdateEmailNotificationStatusRequest) => {
    try {
      await (dispatch(updateEmailNotificationToggles(updateEmailNotification)) as { response: UpdateEmailNotificationStatusResponse });
      dispatch(fetchEmailNotificationToggles(Number(currentCompanyId)));
    }
    catch (err) { }
  }

  useEffect(() => {
    if (emailNotificationToggles.getEmailNotificationConfigsList().length) {
      let newState: any = {};
      emailNotificationToggles.getEmailNotificationConfigsList().forEach((elem) => {
        switch (elem.getEmailType()) {
          case EmailTypes.SUCCESSFUL_RENEWAL_PAYMENT_EMAIL:
            newState.subscritionRenewal = elem.getIsEnabled();
            break;
          case EmailTypes.POSITIVE_ONE_TIME_CHARGE:
            newState.positiveOneTimeCharge = elem.getIsEnabled();
            break;
          case EmailTypes.REFUND_ISSUED:
            newState.refundIssued = elem.getIsEnabled();
            break;
          case EmailTypes.NEXT_BILL_ADJUSTMENT:
            newState.adjustmentMadeToNextBill = elem.getIsEnabled();
            break;
          case EmailTypes.INVOICE_LINK:
            newState.resendInvoiceLink = elem.getIsEnabled();
            break;
          case EmailTypes.CREDIT_NOTE_LINK:
            newState.resendCreditNoteLink = elem.getIsEnabled();
            break;
          case EmailTypes.ONE_TIME_CHARGE_PAYMENT_FAILURE:
          newState.positiveOneTimeChargePaymentFailed = elem.getIsEnabled();
          break;
        }
      })
      setSwitchState(newState);
    }
  }, [emailNotificationToggles]);

  useEffect(() => {
    dispatch(fetchEmailNotificationToggles(Number(currentCompanyId)));
  }, []);

  return (
    <div>
      <BackNavigationPanel
        title={"EMAIL_TEMPLATE_PAYMENTS_CREDITS_REFUNDS_TEMPLATE_BACK_PANEL_TITLE"} shouldTranslateTitle={true}
        onClick={() => history.push("/configuration/email-notifications/emails")}>
        <MenuTable
          data={[
            {
              title: "EMAIL_TEMPLATE_PAYMENTS_CREDITS_REFUNDS_SUBSCRIPTION_CAPTURED_SUCCESSFULLY_TEMPLATE_TITLE",
              onClick: () => history.push("/configuration/email-notifications/emails/payment-credits-refund/renewal-payment-email-template"),
              canConfigureNotification: true,
              enableNotification: switchState.subscritionRenewal,
              enableNotificationChange: (isChecked) => {
                setSwitchState({ ...switchState, subscritionRenewal: isChecked });
                const updateEmailNotification = new UpdateEmailNotificationStatusRequest();
                updateEmailNotification.setCompanyId(Number(currentCompanyId));
                updateEmailNotification.setEmailType(EmailTypes.SUCCESSFUL_RENEWAL_PAYMENT_EMAIL);
                updateEmailNotification.setIsEnabled(isChecked);
                updateData(updateEmailNotification);
              }
            },
            {
              title: "EMAIL_TEMPLATE_PAYMENTS_CREDITS_REFUNDS_POSITIVE_ONE_TIME_CHARGE_CAPTURED_SUCCESSFULLY_TEMPLATE_TITLE",
              onClick: () => history.push("/configuration/email-notifications/emails/payment-credits-refund/positive-one-time-charge-successfully-captured-email-template"),
              canConfigureNotification: true,
              enableNotification: switchState.positiveOneTimeCharge,
              enableNotificationChange: (isChecked) => {
                setSwitchState({ ...switchState, positiveOneTimeCharge: isChecked });
                const updateEmailNotification = new UpdateEmailNotificationStatusRequest();
                updateEmailNotification.setCompanyId(Number(currentCompanyId));
                updateEmailNotification.setEmailType(EmailTypes.POSITIVE_ONE_TIME_CHARGE);
                updateEmailNotification.setIsEnabled(isChecked);
                updateData(updateEmailNotification);
              }
            },
            /*{
              title: 'EMAIL_TEMPLATE_PAYMENTS_CREDITS_REFUNDS_NEGATIVE_ONE_TIME_CHARGE_CAPTURED_SUCCESSFULLY_TEMPLATE_TITLE',
              onClick: () => history.push('/configuration/email-notifications/emails/payment-credits-refund/negative-one-time-charge-successfully-captured-email-template'),
              canConfigureNotification: true,
              enableNotification: switchState.negativeOneTimeCharge,
              enableNotificationChange: (isChecked) => {
                setSwitchState({ ...switchState, negativeOneTimeCharge: isChecked });
                const updateEmailNotification = new UpdateEmailNotificationStatusRequest();
                updateEmailNotification.setCompanyId(Number(currentCompanyId));
                updateEmailNotification.setEmailType(EmailTypes.onetime);
                updateEmailNotification.setIsEnabled(isChecked);
                updateData(updateEmailNotification);
              }
            },*/
            {
              title: "EMAIL_TEMPLATE_PAYMENTS_CREDITS_REFUNDS_POSITIVE_ONE_TIME_CHARGE_PAYMENT_FAILED_TITLE",
              onClick: () => history.push("/configuration/email-notifications/emails/payment-credits-refund/positive-one-time-charge-payment-failed"),
              canConfigureNotification: true,
              enableNotification: switchState.positiveOneTimeChargePaymentFailed,
              enableNotificationChange: (isChecked) => {
                setSwitchState({ ...switchState, positiveOneTimeChargePaymentFailed: isChecked });
                const updateEmailNotification = new UpdateEmailNotificationStatusRequest();
                updateEmailNotification.setCompanyId(Number(currentCompanyId));
                updateEmailNotification.setEmailType(EmailTypes.ONE_TIME_CHARGE_PAYMENT_FAILURE);
                updateEmailNotification.setIsEnabled(isChecked);
                updateData(updateEmailNotification);
              }
            },
            {
              title: "EMAIL_TEMPLATE_PAYMENTS_CREDITS_REFUNDS_REFUND_ISSUED_TEMPLATE_TITLE",
              onClick: () => history.push("/configuration/email-notifications/emails/payment-credits-refund/refund-issued-email-template"),
              canConfigureNotification: true,
              enableNotification: switchState.refundIssued,
              enableNotificationChange: (isChecked) => {
                setSwitchState({ ...switchState, refundIssued: isChecked });
                const updateEmailNotification = new UpdateEmailNotificationStatusRequest();
                updateEmailNotification.setCompanyId(Number(currentCompanyId));
                updateEmailNotification.setEmailType(EmailTypes.REFUND_ISSUED);
                updateEmailNotification.setIsEnabled(isChecked);
                updateData(updateEmailNotification);
              }
            },
            /* FEATURE NOT YET IMPLEMENTED
            {
              title: 'EMAIL_TEMPLATE_PAYMENTS_CREDITS_REFUNDS_ADJUSTMENT_MADE_TO_NEXT_BILL_TEMPLATE_TITLE',
              onClick: () => history.push('/configuration/email-notifications/emails/payment-credits-refund/adjustment-made-to-next-bill-email-template'),
              canConfigureNotification: true,
              enableNotification: switchState.adjustmentMadeToNextBill,
              enableNotificationChange: (isChecked) => {
                setSwitchState({ ...switchState, adjustmentMadeToNextBill: isChecked });
                const updateEmailNotification = new UpdateEmailNotificationStatusRequest();
                updateEmailNotification.setCompanyId(Number(currentCompanyId));
                updateEmailNotification.setEmailType(EmailTypes.NEXT_BILL_ADJUSTMENT);
                updateEmailNotification.setIsEnabled(isChecked);
                updateData(updateEmailNotification);
              }
            },*/
            {
              title: "EMAIL_TEMPLATE_PAYMENTS_CREDITS_REFUNDS_RESEND_INVOICE_LINK_TEMPLATE_TITLE",
              onClick: () => history.push("/configuration/email-notifications/emails/payment-credits-refund/resend-invoice-link-email-template"),
              canConfigureNotification: true,
              enableNotification: switchState.resendInvoiceLink,
              enableNotificationChange: (isChecked) => {
                setSwitchState({ ...switchState, resendInvoiceLink: isChecked });
                const updateEmailNotification = new UpdateEmailNotificationStatusRequest();
                updateEmailNotification.setCompanyId(Number(currentCompanyId));
                updateEmailNotification.setEmailType(EmailTypes.INVOICE_LINK);
                updateEmailNotification.setIsEnabled(isChecked);
                updateData(updateEmailNotification);
              }
            },
            {
              title: "EMAIL_TEMPLATE_PAYMENTS_CREDITS_REFUNDS_RESEND_CREDIT_NOTE_LINK_TEMPLATE_TITLE",
              onClick: () => history.push("/configuration/email-notifications/emails/payment-credits-refund/resend-credit-note-link-email-template"),
              canConfigureNotification: true,
              enableNotification: switchState.resendCreditNoteLink,
              enableNotificationChange: (isChecked) => {
                setSwitchState({ ...switchState, resendCreditNoteLink: isChecked });
                const updateEmailNotification = new UpdateEmailNotificationStatusRequest();
                updateEmailNotification.setCompanyId(Number(currentCompanyId));
                updateEmailNotification.setEmailType(EmailTypes.CREDIT_NOTE_LINK);
                updateEmailNotification.setIsEnabled(isChecked);
                updateData(updateEmailNotification);
              }
            }
          ]}
        />
      </BackNavigationPanel>
    </div>
  )
}

export default PaymentCreditsRefundMenu;
