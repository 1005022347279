import React, { ReactElement } from "react";
import ReactTooltip from "react-tooltip";
import "./Tooltip.scss";

interface ITooltip {
  children: ReactElement,
  id?: string
  place: ReactTooltip.Place,
  type?: "default" | "blue",
  className?: string
}

const Tooltip: React.FC<ITooltip> = ({ children, id, place, type = "default", className = "", ...rest }) => {

  return (
    <ReactTooltip
      {...rest}
      isCapture
      className={`tooltip tooltip--${type} ${className}`}
      type='light'
      place={place}
      id={id}
      globalEventOff="click"
      scrollHide={false}
    >
      {children}
    </ReactTooltip>
  );
}

export default Tooltip;