import { BillsbyAction } from "../models/BillsbyAction";
import { AppState } from "..";
import { FETCH_SUBSCRIPTION_SHIPPING_ADDRESS_SUCCESS, SET_SUBSCRIPTIONS_SHIPPING_ADDRESS_SHOW_UPDATE_MODAL, RESET_SUBSCRIPTION_SHIPPING_ADDRESS, SET_SUBSCRIPTION_SHIPPING_ADDRESS } from "../actions/shippingAddressActions";

export interface IShippingAddressReducerState {
  addressLine1: string,
  addressLine2: string,
  state: string,
  city: string,
  country: string,
  postCode: string,
  showShippingAddressUpdateModal: boolean
}

export const initState = {  
  addressLine1: "",
  addressLine2: "",
  state: "",
  city: "",
  country: "",
  postCode: "",
  showShippingAddressUpdateModal: false
}

export default function shippingAddressReducer(state: IShippingAddressReducerState = initState, action: BillsbyAction, store: AppState) {
  switch (action.type) {
    case SET_SUBSCRIPTION_SHIPPING_ADDRESS:
      return { ...state, ...action.payload }
    case SET_SUBSCRIPTIONS_SHIPPING_ADDRESS_SHOW_UPDATE_MODAL:
      return { ...state, showShippingAddressUpdateModal: action.payload }
    case FETCH_SUBSCRIPTION_SHIPPING_ADDRESS_SUCCESS:
      return { ...state, ...action.response }
    case RESET_SUBSCRIPTION_SHIPPING_ADDRESS:
      return { ...initState }
    default:
      return state;
  }
}