
import { BillsbyAction } from "../../models/BillsbyAction";
import { AppState } from "../..";
import { GetPlanCurrenciesResponse, GetAddonResponse, Currency, GetCurrencyPriceModelsResponse, AddonPriceModel } from "../../utils/grpc/generated/Billsby.Protos/core/private/addons/addons_pb";
import { FETCH_CURRENCIES_OF_PLANS_SUCCESS, FETCH_CURRENCY_PRICE_MODELS_SUCCESS, RESET_CREATE_ADD_ON_STATE, SET_ADD_ONS_COST_CURRENT_PAGE, RESET_ADD_ON_COST_STATE, SET_HAS_CONFIGURED_ALL_PRICING_MODELS, FETCH_ADD_ON_SUCCESS, FETCH_CURRENCY_PRICE_MODELS_REQUEST, SET_ADD_ONS_COST_CURRENCY_PRICEMODELS } from "../../actions/addOnsActions";
import { GetCurrencyPriceModelsResponse as GetCurrencyPriceModelsResponseAllowances, GetAllowanceResponse } from "../../utils/grpc/generated/Billsby.Protos/core/private/allowances/allowances_pb";
import { AllowancePriceModel } from "../../utils/grpc/generated/Billsby.Protos/core/private/allowances/allowances_pb";
import { AddonsAllowancesScreen } from "../../models/AddonsAllowances";
import { divideNumberBy100, toAddOnTierUnit } from "../../utils/commonUtils";

export interface ICurrencyPriceModels {
  [currency: string]: Array<AddonPriceModel | AllowancePriceModel>
}

export interface IAddOnCostReducer {
  currenciesOfPlans?: GetPlanCurrenciesResponse,
  currencyPriceModels?: ICurrencyPriceModels,
  currentPage: number,
  hasConfiguredAllPriceModels: boolean,
  isLoading: boolean
}

const initialState: IAddOnCostReducer = {
  currentPage: 1,
  hasConfiguredAllPriceModels: true,
  isLoading: false
}


export default function addOnCostReducer(state: IAddOnCostReducer = initialState, action: BillsbyAction, store: AppState) {
  switch (action.type) {
    case FETCH_CURRENCIES_OF_PLANS_SUCCESS: {
      const currenciesOfPlans = action.response as GetPlanCurrenciesResponse;
      if(currenciesOfPlans.getCurrenciesList().length === 0) {
        // if there's no currency available we reset all all currencies and pricemodels and we set the step to valid
        // because by spec the user can proceed even without and plans
        return {
          ...state,
          currenciesOfPlans: undefined,
          currencyPriceModels: undefined,
          hasConfiguredAllPriceModels: true
        }
      }
      return { ...state, currenciesOfPlans: action.response }
    }
    case FETCH_CURRENCY_PRICE_MODELS_REQUEST:
      return { ...state, isLoading: true }
    case FETCH_CURRENCY_PRICE_MODELS_SUCCESS: {
      const getCurrencyPriceModelsResponse: GetCurrencyPriceModelsResponse | GetCurrencyPriceModelsResponseAllowances = action.response;
      const existingCyclesPerCurrency = (state.currencyPriceModels && state.currencyPriceModels[action.selectedCurrency]) ? [...state.currencyPriceModels[action.selectedCurrency]] : [];
      let mergePriceModels: Array<AddonPriceModel | AllowancePriceModel> = [];
      for (let priceModel of getCurrencyPriceModelsResponse.getResultsList()) {
        let existingPriceModel = existingCyclesPerCurrency.find(exCycle => exCycle.getFrequency() === priceModel.getFrequency() && exCycle.getFrequencyType() === priceModel.getFrequencyType());
        if (existingPriceModel) {
          mergePriceModels.push(existingPriceModel);
          continue;
        }
        mergePriceModels.push(priceModel);
      }

      const newCurrencyPriceModels: ICurrencyPriceModels = {
        ...state.currencyPriceModels,
        [action.selectedCurrency]: mergePriceModels
      }

      if (state.currenciesOfPlans) {
        // if the available currencies (currenciesOfPlans) change, for example by removing a plan we need to remove all the pricing models 
        // related to that currency!
        for (let currencyIso3PriceModel of Object.keys(newCurrencyPriceModels)) {
          const hasCurrencyAvailable = state.currenciesOfPlans.getCurrenciesList().some(c => c.getIso3Code() === currencyIso3PriceModel);
          if (!hasCurrencyAvailable) {
            delete newCurrencyPriceModels[currencyIso3PriceModel];
          }
        }
      }

      return {
        ...state,
        currencyPriceModels: newCurrencyPriceModels
      }
    }

    case SET_ADD_ONS_COST_CURRENT_PAGE:
      return { ...state, currentPage: action.payload }
    case SET_HAS_CONFIGURED_ALL_PRICING_MODELS:
      return { ...state, hasConfiguredAllPriceModels: action.payload }
    case SET_ADD_ONS_COST_CURRENCY_PRICEMODELS:
      return { ...state, currencyPriceModels: action.payload }

    case FETCH_ADD_ON_SUCCESS: {
      // edit addon screen, preload added cycles
      const type = action.fetchType as AddonsAllowancesScreen;
      const isAddonScreen = type === AddonsAllowancesScreen.ADDONS;
      const addOn: GetAddonResponse | GetAllowanceResponse = action.response;
      const priceModels = isAddonScreen
        ? (addOn as GetAddonResponse).getPriceModelsList()
        : (addOn as GetAllowanceResponse).getPriceModelsList();
      const currencies = (priceModels as Array<AddonPriceModel | AllowancePriceModel>)
        .map(priceModel => (priceModel.getCurrency() || new Currency()).getIso3Code());

      const currencyPriceModels: ICurrencyPriceModels = {};

      const uniqCurrencies = currencies.filter((item, pos) => currencies.indexOf(item) === pos);

      for (let currencyIso3 of uniqCurrencies) {
        currencyPriceModels[currencyIso3] = (priceModels as Array<AddonPriceModel | AllowancePriceModel>)
          .filter(priceModel => (priceModel.getCurrency() || new Currency()).getIso3Code() === currencyIso3)
          .map(priceModel => {
            if (isAddonScreen) {
              (priceModel as AddonPriceModel).setFlatFeePrice(divideNumberBy100((priceModel as AddonPriceModel).getFlatFeePrice()));
              (priceModel as AddonPriceModel).setPerUnitPrice(divideNumberBy100((priceModel as AddonPriceModel).getPerUnitPrice()));
            }
            else {
              (priceModel as AllowancePriceModel).setPerUnitPrice(divideNumberBy100((priceModel as AllowancePriceModel).getPerUnitPrice()) || "");
            }
           
            priceModel.setTiersList(priceModel.getTiersList().map(tier => {
              tier.setPrice(tier.getPrice() / 100);
              return toAddOnTierUnit(tier);
            }));
            return priceModel;
          })

        //currencyPriceModels[currencyIso3].forEach((priceModel) => console.log(priceModel.toObject()))
      }
      return { ...state, currencyPriceModels }
    }

    case RESET_ADD_ON_COST_STATE:
    case RESET_CREATE_ADD_ON_STATE:
      return { ...initialState }
    default:
      return state;
  }
}