/* eslint-disable */
import React from "react";
import { IEmailRoutingReducerState } from "../../../../../../reducers/emailRoutingReducer";
import { IAuthReducerState } from "../../../../../../reducers/authReducer";

interface Props {
  emailRoutingReducer: IEmailRoutingReducerState,
  authReducer: IAuthReducerState,
  dispatch: Function
}

const PausesMenu: React.FC<Props> = ({ emailRoutingReducer: { emailNotificationToggles }, authReducer: { currentCompanyId }, dispatch }) => {
  /*const [switchState, setSwitchState] = useState<boolean>(false);

  const updateData = async (updateEmailNotification: UpdateEmailNotificationStatusRequest) => {
    try {
      await (dispatch(updateEmailNotificationToggles(updateEmailNotification)) as { response: UpdateEmailNotificationStatusResponse });
      dispatch(fetchEmailNotificationToggles(Number(currentCompanyId)));
    }
    catch (err) { }
  }

  useEffect(() => {
    if (emailNotificationToggles.getEmailNotificationConfigsList().length) {
      const notificationStatus = emailNotificationToggles.getEmailNotificationConfigsList().find(el => el.getEmailType() === EmailTypes.SUBSCRIPTION_PAUSED) as EmailNotificationConfig;
      if (notificationStatus) {
        setSwitchState(notificationStatus.getIsEnabled());
      }
    }
  }, [emailNotificationToggles]);

  useEffect(() => {
    dispatch(fetchEmailNotificationToggles(Number(currentCompanyId)));
  }, []);

  return (
    <div>
      <BackNavigationPanel
        title={`EMAILS_MENU_RETENTION_PAUSES`} shouldTranslateTitle={true}
        onClick={() => history.push('/configuration/email-notifications/emails')}>
        <MenuTable
          data={[
            {
              title: 'EMAILS_MENU_RETENTION_PAUSES_WHEN_A_PAUSE_IS_APPLIED',
              onClick: () => history.push('/configuration/email-notifications/emails/pauses/pause-email-template'),
              canConfigureNotification: true,
              enableNotification: switchState,
              enableNotificationChange: (isChecked) => {
                setSwitchState(isChecked);
                const updateEmailNotification = new UpdateEmailNotificationStatusRequest();
                updateEmailNotification.setCompanyId(Number(currentCompanyId));
                updateEmailNotification.setEmailType(EmailTypes.SUBSCRIPTION_PAUSED);
                updateEmailNotification.setIsEnabled(isChecked);
                updateData(updateEmailNotification);
              }
            }
          ] as Array<INotificationListElement>}
        />
      </BackNavigationPanel>
    </div>
  )*/
  return null
}

//export default connect((state: AppState) => ({ emailRoutingReducer: state.emailRoutingReducer, authReducer: state.auth }))(PausesMenu)
export default () => { return null }