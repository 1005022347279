export interface IBaseCustomField {
  type: CustomFieldType,
  label: string,
  description: string,
  options?: string | null,
  compulsory: boolean
}

export interface ICustomField extends IBaseCustomField {
  customFieldId: number
}

export interface ISelectedCustomField extends ICustomField {
  isSelected: boolean
}

export enum CustomFieldType {
  SingleLineTextField = "SingleLineTextField",
  MultiLineTextField = "MultiLineTextField",
  DropdownField = "DropdownField",
  CheckboxField = "CheckboxField",
  DatePickerField = "DatePickerField",
  NumbersField = "NumbersField"
}

export enum CustomFieldScreen {
  STANDARD_FIELDS = "STANDARD_FIELDS",
  COMPANY_FIELDS = "COMPANY_FIELDS",
  INTEGRATION_FIELDS = "INTEGRATION_FIELDS"
}

export interface ISubscriptionCustomFields {
  customFieldResponseId: number,
  customField: {
    customFieldId: number,
    type: CustomFieldType,
    label: string,
    description: string,
    options: string,
    compulsory: true
  },
  value: any
}