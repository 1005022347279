import { EmailTypes } from "../utils/grpc/generated/Billsby.Protos/Messenger/private/emailconfig/emailconfig_pb";

export enum DunningFailedEmailType {
  PaymentDeclinedInitial = "PaymentDeclinedInitial",
  PaymentDeclinedAttemptOne = "PaymentDeclinedAttemptOne",
  PaymentDeclinedAttemptTwo = "PaymentDeclinedAttemptTwo",
  PaymentDeclinedAttemptThree = "PaymentDeclinedAttemptThree",
  HandlingMinimumTermsEmail = "HandlingMinimumTermsEmail"
}

// we need this object because we pass the dunning email type in the url and we need to map it to the proto type
export const DunningFailedEmailTypeMap = {
  [DunningFailedEmailType.PaymentDeclinedInitial]: EmailTypes.PAYMENT_DECLINED_INITIAL,
  [DunningFailedEmailType.PaymentDeclinedAttemptOne]: EmailTypes.PAYMENT_DECLINED_ATTEMPT_ONE,
  [DunningFailedEmailType.PaymentDeclinedAttemptTwo]: EmailTypes.PAYMENT_DECLINED_ATTEMPT_TWO,
  [DunningFailedEmailType.PaymentDeclinedAttemptThree]: EmailTypes.PAYMENT_DECLINED_ATTEMPT_THREE,
  [DunningFailedEmailType.HandlingMinimumTermsEmail]: EmailTypes.HANDLING_MINIMUM_TERMS_EMAIL
}