import React from "react";
import CustomFieldPreview from "../../ui/custom-field-preview/CustomFieldPreview";
import Text from "../../ui/text/Text";
import CheckoutDropdown from "./ui-checkout/CheckoutDropdown";
import counterpart from "counterpart";

interface IDropdownPreview {
  label: string,
  supportText: string,
  options: Array<{ label: string, value: any }>
}

const DropdownPreview: React.FC<IDropdownPreview> = ({ label, supportText, options }) => {
  return (
    <CustomFieldPreview className="preview">
        <Text content={label} shouldTranslate={false} />
        <CheckoutDropdown
          placeholder={counterpart("CREATE_CUSTOM_FIELDS_DROPDOWN_OPTIONS_SELECT")}
          options={options}
        />
        <Text content={supportText} shouldTranslate={false} noMargin/>
    </CustomFieldPreview>
  )
}

export default DropdownPreview;