import React, { useEffect, useState, useRef } from "react";
import CraftyClicks, { CraftyClicksField, CraftyClicksSelectedAddress, CraftyClicksConfig } from "../../utils/craftyClicksUtils";
import "./AddressLookup.scss";
import FormLabel from "../ui/form-label/FormLabel";
import Text from "../ui/text/Text";
import FormGroup from "../ui/form-group/FormGroup";
import Input from "../ui/input/Input";
import Panel from "../ui/panel/Panel";

interface IAddressLookup {
  title: string,
  shouldTranslateTitle?: boolean,
  showSummaryPanel?: boolean,
  selectedAddress: CraftyClicksSelectedAddress,
  onSelectedAddress: (selectedAddress: CraftyClicksSelectedAddress) => void,
  hasError?: boolean
}

const AddressLookup: React.FC<IAddressLookup> = ({ title, shouldTranslateTitle = true, showSummaryPanel = true, onSelectedAddress, selectedAddress, hasError = false }) => {
  const [search, setSearch] = useState("");
  const root = useRef(null);

  // in case we have multiple instances of crafty clicks forms we need to use different id's for the dom elements to allow the lookup of the right ones
  const [ccRandomId] = useState(Math.round(Math.random() * 100));
  const [ccConfig] = useState<Partial<CraftyClicksConfig>>({
    dom: {
      search: `${CraftyClicksField.SEARCH}${ccRandomId}`,
      town: `${CraftyClicksField.CITY}${ccRandomId}`,
      postcode: `${CraftyClicksField.POSTCODE}${ccRandomId}`,
      county: `${CraftyClicksField.STATE}${ccRandomId}`,
      line_1: `${CraftyClicksField.LINE_1}${ccRandomId}`,
      line_2: `${CraftyClicksField.LINE_2}${ccRandomId}`,
      country: `${CraftyClicksField.COUNTRY}${ccRandomId}`
    }
  });

  useEffect(() => {
    CraftyClicks.loadConfig(selectedAddress => {
      onSelectedAddress(selectedAddress);
    }, ccConfig);
  }, []);

  if (!ccConfig.dom) {
    return null;
  }

  return (
    <div className="address-lookup" ref={root}>

      <FormGroup className="address-lookup__title">
        <FormLabel content={title} shouldTranslate={shouldTranslateTitle} target="" noMargin />
        <FormLabel className="address-lookup__country" content="ADDRESS_LOOKUP_CHANGE_COUNTRY" target="" onClick={() => {
          const rootEl = (root.current as any) as HTMLElement;
          if (rootEl) {
            const searchEl = rootEl.querySelector(`#${(ccConfig.dom as any).search}`) as HTMLElement;
            searchEl && searchEl.focus();
          }
        }} />
      </FormGroup>

      <FormGroup>
        <Input id={ccConfig.dom.search} isError={hasError} value={search} onChange={(evt: any) => setSearch(evt.target.value)} />
        <Input id={ccConfig.dom.line_1} value={selectedAddress.line1} style={{ display: "none" }} onChange={() => {}} />
        <Input id={ccConfig.dom.line_2} value={selectedAddress.line2} style={{ display: "none" }} onChange={() => {}} />
        <Input id={ccConfig.dom.town} value={selectedAddress.city} style={{ display: "none" }} onChange={() => {}} />
        <Input id={ccConfig.dom.postcode} value={selectedAddress.zipCode} style={{ display: "none" }} onChange={() => {}} />
        <Input id={ccConfig.dom.county} value={selectedAddress.state} style={{ display: "none" }} onChange={() => {}} />
        <Input id={ccConfig.dom.country} value={selectedAddress.countryIso2} style={{ display: "none" }} onChange={() => {}} />
      </FormGroup>

      {
        Object.values(selectedAddress).some(k => !!k) && showSummaryPanel
          ? (
            <Panel className="address-lookup__summary">
              <Text content={selectedAddress.line1} shouldTranslate={false} noMargin />
              {selectedAddress.line2 && <Text content={selectedAddress.line2} shouldTranslate={false} noMargin />}
              <Text content={`${selectedAddress.city}, ${selectedAddress.zipCode}`} shouldTranslate={false} noMargin />
              <Text content={selectedAddress.state} shouldTranslate={false} noMargin />
              <Text content={selectedAddress.countryName} shouldTranslate={false} noMargin />
            </Panel>
          )
          : null
      }
    </div>
  )
}

export default AddressLookup;