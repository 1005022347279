import React, { useCallback, useMemo } from "react";
import { useDropzone } from "react-dropzone";
import Panel from "../panel/Panel";
import "./FileUpload.scss";
import Text from "../text/Text";
import FileUploadText from "./FileUploadText";

interface IFileUpload {
  onDrop: (files: Array<File>) => void,
  title: string | JSX.Element,
  image?: string,
  shouldTranslateTitle?: boolean,
  subTitle: string,
  shouldTranslateSubTitle?: boolean,
  className?: string,
  imageAsBackground?: boolean,
  isLoading?: boolean
}

const FileUpload: React.FC<IFileUpload> = ({ onDrop, title, image = "", imageAsBackground = false, className = "", shouldTranslateTitle = true, subTitle, shouldTranslateSubTitle = true, isLoading = false }) => {
  onDrop = useCallback(onDrop, [])
  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop })

  const displayImage = useMemo(() =>
    imageAsBackground ? <div className="product-image-file-upload__image" style={{ backgroundImage: `url("${image}")`, width: "110.35px", height: "104px", marginRight: "20px", backgroundSize: "cover" }}></div>
      : <div className="file-upload__image"><img src={image} alt="no img" /></div>
    , [imageAsBackground, image])

  return (
    <Panel className={`file-upload ${className}`}>
      <div {...getRootProps()} className="file-upload__container">
        {image ?
          <>{displayImage}</>
          :
          <div className="file-upload__logo" />
        }
        <input {...getInputProps()} />
        {
          isDragActive
            ? <Text content="FILE_UPLOAD_IS_DRAGGING_TEXT" noMargin className="file-upload__text" />
            : <FileUploadText title={title} shouldTranslateTitle={shouldTranslateTitle} shouldTranslateSubTitle={shouldTranslateSubTitle} subTitle={subTitle} isLoading={isLoading} />
        }
      </div>
    </Panel>
  )
}

export default FileUpload;