import React, { useMemo } from "react";
import Receipt from "../receipt/Receipt";
import { InvoiceStatus, StandAloneInvoiceType } from "../../models/Invoices";
import { IInvoiceDetails } from "../../models/Customer";
import Table from "../ui/table/Table";
import Text from "../ui/text/Text";
import moment from "moment";
import { getInvoiceStatusColor, getInvoiceStatusText, getCardText } from "../../utils/commonUtils";
import { europeanCountriesCode } from "../../utils/states/states";
import counterpart from "counterpart";
import "./InvoicePrint.scss";

interface IInvoicePrint {
  data: IInvoiceDetails,
  isStandAlone?: boolean,
  locale?: string
}

const formatDate = (date: string) => {
  return moment(date).format("MMM DD YYYY");
};

const InvoicePrint: React.FC<IInvoicePrint> = ({ data, locale, isStandAlone = false }) => {
  const {
    customerUniqueId,
    customerFirstName,
    customerLastName,
    customerTaxRegNumber,
    customerCompanyName,
    addressLine1,
    addressLine2,
    addressPostCode,
    addressCity,
    addressState,
    addressCountryCode,
    addressCountry,
    invoiceNumber,
    createdOn,
    amount,
    formattedAmount,
    currency,
    status,
    subTotal,
    taxTotal,
    taxes,
    taxPercentage,
    invoiceTitle,
    subscriptions,
    brandingHighlightColor,
    brandingLogo,
    brandingLogoBackgroundColor,
    companyName,
    companyPhone,
    companyEmail,
    companyTaxLabel,
    companyTaxNumber,
    invoiceType,
    cardLast4Digits,
    cardType,
    companyCity,
    companyCountry,
    companyAddressLine1,
    companyAddressLine2,
    companyPostCode,
    companyState,
    companyPhoneNumber,
    companyPhoneNumberDialCode,
    companyPhoneNumberDialCountry,    
    prorationAdjustment,
    prorationFactor,
  } = data;

  const billingAddress = {
    addressLine1,
    addressLine2,
    addressPostCode,
    addressState,
    addressCountry,
    addressCity
  };

  const companyAddress = {
    addressLine1: companyAddressLine1,
    addressLine2: companyAddressLine2,
    addressPostCode: companyPostCode,
    addressState: companyState,
    addressCountry: companyCountry,
    addressCity: companyCity
  };

  const hexBrandingHighlightColor = `#${brandingHighlightColor}`;
  const hexBrandingLogoBackgroundColor = `#${brandingLogoBackgroundColor}`;

  const customerName = `${customerFirstName} ${customerLastName}`;
  const isInvoice = invoiceType === StandAloneInvoiceType.RENEWAL || invoiceType === StandAloneInvoiceType.ONE_TIME_CHARGE;
  const checkStatus = () => {
    if (isStandAlone) {
      if (status === InvoiceStatus.PAID_OFFLINE) {
        return InvoiceStatus.PAID;
      }

      if (status === InvoiceStatus.WRITTEN_OFF) {
        return InvoiceStatus.UNPAID;
      }

      if (status === InvoiceStatus.TOBEWRITTEN_OFF) {
        return InvoiceStatus.UNPAID;
      }
    }

    return status;
  };

  const getTaxLabel = useMemo(() => {
    switch (addressCountryCode) {
      case "CAN":
        return "INVOICE_DETAILS_CUSTOMER_BN";
      case "IND":
        return "INVOICE_DETAILS_CUSTOMER_GSTIN";
      case "NZL":
        return "INVOICE_DETAILS_CUSTOMER_NZBN";
      case "USA":
        return "INVOICE_DETAILS_CUSTOMER_TIN";
      default:
        return europeanCountriesCode.indexOf(addressCountryCode) !== -1 ? "INVOICE_DETAILS_CUSTOMER_VAT" : "INVOICE_DETAILS_CUSTOMER_TAX"
    }

  }, [addressCountryCode])

  const getTitle = () => {
    if(isInvoice) {
      return "INVOICE_DETAILS_TITLE"
    } 
    return "CREDIT_NOTE_DETAILS_TITLE"
  }

  const renderPlanTables = () => {
    const { subscriptions, currency } = data;

    let infoDiscountValue = "";
    const tdProps = { shouldTranslate: false, component: "td", noMargin: true };
    const thProps = { style: { color: hexBrandingHighlightColor }, component: "th", noMargin: true, locale };
    const showExtraFields = !isInvoice || invoiceType === StandAloneInvoiceType.ONE_TIME_CHARGE;

    return (
      <>
        {subscriptions !== null &&
          subscriptions.map((subscription: any, idx: number) => (
            <React.Fragment key={idx}>
              {!showExtraFields && (
                <Receipt.PlanHeader
                  locale={locale}
                  highlightColor={hexBrandingHighlightColor}
                  content={{
                    planName: `${subscription.productDisplayName || subscription.productName}`,
                    subscriptionUniqueId: subscription.subscriptionUniqueId,
                    billingPeriod: `${formatDate(subscription.billingPeriodStart)} - ${formatDate(subscription.billingPeriodEnd)}`
                  }}
                />
              )}

              <Table className="invoice-details__table">
                <thead>
                  <tr>
                    <Text {...thProps} content="INVOICE_DETAILS_TABLE_DESCRIPTION" />
                    {!showExtraFields && (
                      <>
                        <Text {...thProps} content="INVOICE_DETAILS_TABLE_UNITS" />
                        <Text {...thProps} content="INVOICE_DETAILS_TABLE_UNIT_PRICE" />
                        <Text {...thProps} content="INVOICE_DETAILS_TABLE_DISCOUNTS" />
                      </>
                    )}

                    <Text
                      {...thProps}
                      content="INVOICE_DETAILS_TABLE_AMOUNT"
                      translateWith={{
                        currency
                      }}
                    />
                  </tr>
                </thead>
                <tbody>
                  {subscription.subscriptionItems.map((item: any, idx: number) => {
                    infoDiscountValue = item.discounts;
                    return (
                      <tr key={idx}>
                        <Text {...tdProps} content={item.description} />

                        {!showExtraFields && (
                          <>
                            <Text {...tdProps} content={item.units} />
                            <Text {...tdProps} content={item.unitPrice} />
                            <Text {...tdProps} content={item.discounts} />
                          </>
                        )}

                        <Text {...tdProps} content={isInvoice ? item.amount : `-${item.amount}`} />
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
            </React.Fragment>
          ))}
        {/* 
        <div className="invoice-details__discounts-info">
          <Text component="span" className="gray bold" noMargin content="INVOICE_DETAILS_DISCOUNTS" />
          <br />
          <Text
            component="span"
            className="gray"
            noMargin
            content="INVOICE_DETAILS_DISCOUNTS_INFO"
            translateWith={{
              discountValue: infoDiscountValue
            }}
          /> 
        </div>
        */}
      </>
    );
  };

  return (
    <Receipt.Container>
      <Receipt.Header locale={locale} title={getTitle()} customTitle={invoiceTitle} backgroundColor={hexBrandingLogoBackgroundColor} logoUrl={brandingLogo} />
      <Receipt.Body>
        <Receipt.GeneralInfo
          locale={locale}
          content={{
            fullName: customerName,
            customerCompanyName,
            billingAddress,
            referenceNumber: {
              label: `${isInvoice ? "INVOICE" : "CREDIT_NOTE"}_DETAILS_REF`,
              value: invoiceNumber
            },
            createdOn: {
              label: `${isInvoice ? "INVOICE" : "CREDIT_NOTE"}_DETAILS_DATE`,
              value: formatDate(createdOn)
            },
            amount: {
              label: `${isInvoice ? "INVOICE" : "CREDIT_NOTE"}_DETAILS_AMOUNT`,
              value: isInvoice ? formattedAmount : `-${formattedAmount}`
            },
            customerId: {
              label: `${isInvoice ? "INVOICE" : "CREDIT_NOTE"}_DETAILS_CUSTOMER_ID`,
              value: customerUniqueId
            },
            customerTaxRegNumber: customerTaxRegNumber ? { 
              label: getTaxLabel,
              value: customerTaxRegNumber } : undefined, 
            status: {
              color: getInvoiceStatusColor(checkStatus()),
              content: getInvoiceStatusText(checkStatus())
            }
          }}
        />
        {renderPlanTables()}
        <Receipt.Summary
          locale={locale}
          highlightColor={hexBrandingHighlightColor}
          content={{
            prorationAdjustment: prorationAdjustment,
            prorationFactor: prorationFactor,
            subtotalAmount: isInvoice ? subTotal : `-${subTotal}`,
            taxAmount: isInvoice ? taxTotal : `-${taxTotal}`,
            totalAmount: isInvoice ? formattedAmount : `-${formattedAmount}`,

            taxPercentage,
            taxes,
            cardDetails: status === InvoiceStatus.PAID && !!cardLast4Digits && !!getCardText(cardType) ? `${counterpart(isInvoice ? "RECEIPT_DETAILS_PAID_WITH" : "RECEIPT_DETAILS_REFUNDED_CARD", { cardType: getCardText(cardType).capitalize(), cardLastFourDigit: cardLast4Digits, locale })}` : undefined
          }}
        />
        <Receipt.Footer
          locale={locale}
          backgroundColor={hexBrandingLogoBackgroundColor}
          content={{
            companyName,
            companyPhone: companyPhoneNumber ? `+${companyPhoneNumberDialCode} ${companyPhoneNumber}` : companyPhone,
            companyEmail: companyEmail,
            companyTaxLabel,
            companyTaxNumber,
            companyAddress
          }}
        />
      </Receipt.Body>
    </Receipt.Container>
  );
};


export default InvoicePrint;
