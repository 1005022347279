import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { CompleteEnableSCARequest } from "../../utils/grpc/generated/Billsby.Protos/billing/private/paymentgateway/payment_gateway_pb";
import { grpcUnaryCall } from "../../utils/grpc/grpcUtils";
import { PaymentGatewayServiceClient } from "../../utils/grpc/generated/Billsby.Protos/billing/private/paymentgateway/Payment_gatewayServiceClientPb";
import { ConfigConstants } from "../../utils/config";
import ProgressIndicator from "../../components/ui/progress-indicator/ProgressIndicator";
import history from "../../utils/history";
import { isValidJSON } from "../../utils/commonUtils";
import { loadPreviousScaState, setScaField } from "../../actions/scaActions";


const Sca3ds1Success: React.FC= () => {
  const dispatch = useDispatch();

  const serviceClient = new PaymentGatewayServiceClient(ConfigConstants.grpcBaseUrl);

  useEffect(() => {
    const handleCompleteEnableSCARequest = async () => {

      const isValid = await isValidJSON(sessionStorage.billsbyTestSCA);

      if (!isValid) {
        return history.push("/configuration/sca")
      }
      const billsbyTestSCA = JSON.parse(sessionStorage.billsbyTestSCA);
      const { companyDomain, scaTransactionId, paymentGatewayId, scaReducer } = billsbyTestSCA;
      try {
        const request = new CompleteEnableSCARequest();
        request.setCompanyDomain(companyDomain);
        request.setScaTransactionId(scaTransactionId);
        request.setPaymentGatewayId(paymentGatewayId);
        request.setIsSuccessful(true);
        dispatch(setScaField("hasSuccessfulScaSetup", true));

        await grpcUnaryCall(request, serviceClient, serviceClient.completeEnableSCA);
      } catch (err) {
        dispatch(loadPreviousScaState({...scaReducer, selectedPaymentgatewayId: paymentGatewayId, showTestModal: false, hasSuccessfulScaSetup: false, hasFailedScaSetup: true }));
        console.log(err)
      } finally {
        history.push("/configuration/sca");
      }
    }

    handleCompleteEnableSCARequest();
  }, [])


  return <ProgressIndicator color="blue" coverage="full-content"></ProgressIndicator>
}

export default Sca3ds1Success;