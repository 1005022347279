import React, { useState, useEffect } from "react";
import StepCollapsablePanel from "../../ui/step-collapsable-panel/StepCollapsablePanel";
import Text from "../../ui/text/Text";
import FormGroup from "../../ui/form-group/FormGroup";
import { Row, Col } from "react-grid-system";
import FormLabel from "../../ui/form-label/FormLabel";
import Input from "../../ui/input/Input";
import Dropdown from "../../ui/dropdown/Dropdown";
import Button from "../../ui/button/Button";
import {
  setIsAddingNewCycle,
  setPlanFormCompleted,
  setEditedCycle,
  removeCycle,
  addCycle,
  setCreateCycleField,
  setPlanFormCollapsed,
  setIsReorderingCycles,
  setTempCycles,
  setCycles,
  addTier,
  setPricePerUnit,
  setTierUnit,
  deleteTier
} from "../../../actions/productPlanCycleActions";
import ElementModifiers from "../../ui/element-modifiers/ElementModifiers";
import StatusLabel from "../../ui/status-label/StatusLabel";
import Panel from "../../ui/panel/Panel";
import { allowNumberMoreThanZero, allowZeroAndPositiveNumbers, convertToDecimalFormat, getCycleDescription, getCycleStatusColor, getFrequencyTypeGrpc, removeSpecialChars } from "../../../utils/commonUtils";
import { IBaseCycle, BillingDateType, ProRateType, PricingModelType, FrequencyType, IProductLightWithPlans, VisibilityType } from "../../../models/Product";
import counterpart from "counterpart";
import "./CreateCycle.scss";
import Switch from "../../ui/switch/Switch";
import Checkbox from "../../ui/checkbox/Checkbox";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../../..";
import DragDropContainer from "../../ui/drag-drop-container/DragDropContainer";
import MonthDayPicker from "../../ui/month-day-picker/MonthDayPicker";
import moment from "moment";
import { ISelectedCompanyAddOn, ISelectedCompanyAllowance } from "../../../reducers/linkAddOnsAllowancesReducer";
import { setUnconfiguredPriceModelsList } from "../../../actions/linkAddOnsAllowancesActions";
import { AddonPriceModel } from "../../../utils/grpc/generated/Billsby.Protos/core/private/addons/addons_pb";
import { AllowancePriceModel } from "../../../utils/grpc/generated/Billsby.Protos/core/private/allowances/allowances_pb";

interface ICreateCycle {
  product: IProductLightWithPlans;
  isCreatingPlan: boolean;
}

const CreateCycle: React.FC<ICreateCycle> = ({ product, isCreatingPlan }) => {
  const cycleReducerData = useSelector((state: AppState) => state.cycles);
  const planReducerData = useSelector((state: AppState) => state.plans);
  const linkAddOnsAllowancesReducer = useSelector((state: AppState) => state.linkAddOnsAllowancesReducer);

  const dispatch = useDispatch<Function>();

  const {
    isAddingNewCycle,
    price,
    frequencyType,
    frequencyTypes,
    frequencyTypesPlural,
    frequency,
    editedCycle,
    cycles,
    isFreeTrial,
    freeTrial,
    freeTrialFrequencyType,
    isContractTerm,
    contractTerm,
    contractTermFrequencyType,
    isSetupFee,
    setupFeePrice,
    billingDates,
    billingDate,
    billingDaysMonth,
    billingMonth,
    billingDaysWeek,
    billingDay,
    proRateType,
    helpMenuTitle,
    helpMenuContent,
    visibility,
    visibilityTypes,
    alertEmail,
    freeQuantity,
    isFreeQuantity,
    isReorderingCycles,
    tempCycles,
    createdTiers,
    pricePerUnit,
    tierUnit,
  } = cycleReducerData;
  const { pricingModel, stageCollapsed, stageStatuses, hasSelectedFee } = planReducerData;
  const { selectedAddOns, selectedAddOnsDisplay, selectedAllowances, selectedAllowancesDisplay } = linkAddOnsAllowancesReducer;
  const [unitsToSimulate, setUnitsToSimulate] = useState(0);

  function changeCollapsedValue(index: number, stageCollapsed: Array<boolean>, dispatch: Function) {
    if (stageCollapsed[index]) {
      dispatch(setPlanFormCollapsed(index));
    }
  }

  const isFlatFee = pricingModel === PricingModelType.FlatFee;
  const isPerUnit = pricingModel === PricingModelType.PerUnit;
  const isTiered = pricingModel === PricingModelType.Tiered;
  const isRanged = pricingModel === PricingModelType.Ranged;
  const isVolume = pricingModel === PricingModelType.Volume;

  const planProductCurrencyTemplate = product.billingCurrencyTemplate;
  const templateCharToBeReplaced = planProductCurrencyTemplate.indexOf("N.D") > -1 ? "N.D" : "N"; 
  const currencySymbol = planProductCurrencyTemplate.replace(templateCharToBeReplaced, "");

  const handleSetupFeePriceChanged = (event:any) => {
    let { value, max } = event.target;
    value = (value > Number(max))  ? max : value;
    dispatch(setCreateCycleField("setupFeePrice", allowZeroAndPositiveNumbers(value)))
  };

  const updateUnconfiguredPriceModels = () => {
    if (!editedCycle || !frequency) {
      return
    }

    if (+frequency !== +editedCycle.pricingModel.frequency || frequencyType.value !== editedCycle.pricingModel.frequencyType) {
      selectedAddOns.forEach((ao: ISelectedCompanyAddOn) => {
        dispatch(setUnconfiguredPriceModelsList({ id: ao.id, arr: ao.unconfiguredPriceModelsList.filter((pm: AddonPriceModel) => pm.getFrequency() !== +editedCycle.pricingModel.frequency && pm.getFrequencyType() !== getFrequencyTypeGrpc(editedCycle.pricingModel.frequencyType)) }))
      });

      selectedAllowances.forEach((ao: ISelectedCompanyAllowance) => {
        dispatch(setUnconfiguredPriceModelsList({ id: ao.id, arr: ao.unconfiguredPriceModelsList.filter((pm: AllowancePriceModel) => pm.getFrequency() !== +editedCycle.pricingModel.frequency && pm.getFrequencyType() !== getFrequencyTypeGrpc(editedCycle.pricingModel.frequencyType)) }))
      });
    }
  }

  const handleFreeQuantityChanged = (event:any) => {
    let { value, max } = event.target;
    value = (!!value && value > Number(max))  ? max : value;
    dispatch(setCreateCycleField("freeQuantity", allowNumberMoreThanZero(value)));
  };

  const removeUnconfiguredPriceModel = (cycle: IBaseCycle) => {
    selectedAddOns.forEach((ao: ISelectedCompanyAddOn) => {
      //IMPORTANT: To prevent restarting configured price models
      const priceModelToRemove = ao.unconfiguredPriceModelsList.find(pm => pm.getFrequency() === +cycle.pricingModel.frequency && pm.getFrequencyType() === getFrequencyTypeGrpc(cycle.pricingModel.frequencyType))
      if (!priceModelToRemove) {
        return;
      }

      dispatch(setUnconfiguredPriceModelsList({ id: ao.id, arr: ao.unconfiguredPriceModelsList.filter((pm: AddonPriceModel) => pm !== priceModelToRemove) }))
    });

    selectedAllowances.forEach((ao: ISelectedCompanyAllowance) => {
      //IMPORTANT: To prevent restarting configured price models
      const priceModelToRemove = ao.unconfiguredPriceModelsList.find(pm => pm.getFrequency() === +cycle.pricingModel.frequency && pm.getFrequencyType() === getFrequencyTypeGrpc(cycle.pricingModel.frequencyType))
      if (!priceModelToRemove) {
        return;
      }

      dispatch(setUnconfiguredPriceModelsList({ id: ao.id, arr: ao.unconfiguredPriceModelsList.filter((pm: AllowancePriceModel) => pm !== priceModelToRemove) }))
    });
  }


  const isFrequencyValueValid = (frequency: number | undefined, frequencyType: FrequencyType) => {
    if (!frequency) {
      return true;
    }

    if (frequency && frequencyType === FrequencyType.Daily) {
      return frequency >= 1 && frequency <= 1825;
    }
    if (frequency && frequencyType === FrequencyType.Weekly) {
      return frequency >= 1 && frequency <= 260;
    }
    if (frequency && frequencyType === FrequencyType.Monthly) {
      return frequency >= 1 && frequency <= 60;
    }
    if (frequency && frequencyType === FrequencyType.Yearly) {
      return frequency >= 1 && frequency <= 5;
    }
  };

  const isPriceValueValid = () => {
    if (price === undefined || pricePerUnit === undefined) {
      return true
    }

    if (isVolume || isRanged || isTiered) {
      return pricePerUnit <= 999999.99;
    }

    if (isPerUnit || isFlatFee) {
      if (price === 0) {
        return true;
      }
      return (price >= 0.5 && price <= 999999.99);
    }

    return false;
  };

  const simulatePrice = () => {
    let simulatedPrice = 0;

    createdTiers.forEach(tier => {
      if (isTiered) {
        for (let i = 1; i <= unitsToSimulate; i++) {
          if (tier.start <= i && tier.finish >= i) {
            simulatedPrice += tier.price;
          }
        }
      }

      if (isRanged) {
        if (tier.start <= unitsToSimulate && tier.finish >= unitsToSimulate) {
          simulatedPrice = tier.price;
        }
      }

      if (isVolume) {
        if (tier.start <= unitsToSimulate && tier.finish >= unitsToSimulate) {
          simulatedPrice = unitsToSimulate * tier.price;
        }
      }
    });

    return simulatedPrice / 100;
  };

  const onBlurEmptyFrequency = () => {
    if(!frequency) {
      dispatch(setCreateCycleField("frequency", 1));
    }
  }

  const isAddCycleDisabled = () => {
    const baseCondition = (isFreeQuantity && !freeQuantity) || !frequency || (isFreeTrial && !freeTrial) || (isContractTerm && !contractTerm) || (isSetupFee && !setupFeePrice) || !isFrequencyValueValid(frequency, frequencyType.value) || !isFrequencyValueValid(contractTerm, contractTermFrequencyType.value);

    // if the frequency type is 'daily' and the billing date is 'fixed day every week' and the frequency is not a multiple of 7 then the cycle cannot be created
    if (frequencyType.value === FrequencyType.Daily && billingDate.value === BillingDateType.FIXED_DAY_WEEK && Number(frequency) % 7 !== 0) {
      return true;
    }

    if (isTiered || isVolume || isRanged) {
      return createdTiers.length === 0 || baseCondition;
    }

    return price === undefined || baseCondition || !isPriceValueValid();
  };

  const setHelpTextPriceTypes = () => {
    if (isFlatFee) {
      dispatch(setCreateCycleField("helpMenuTitle", "CREATE_CYCLE_FLAT_FEE_PRICE_HELP_TITLE"));
      dispatch(setCreateCycleField("helpMenuContent", ["CREATE_CYCLE_FLAT_FEE_PRICE_HELP_CONTENT1", "CREATE_CYCLE_FLAT_FEE_PRICE_HELP_CONTENT2"]));
    }
    if (isPerUnit) {
      dispatch(setCreateCycleField("helpMenuTitle", "CREATE_CYCLE_PER_UNIT_PRICE_HELP_TITLE"));
      dispatch(setCreateCycleField("helpMenuContent", ["CREATE_CYCLE_PER_UNIT_PRICE_HELP_CONTENT1", "CREATE_CYCLE_PER_UNIT_PRICE_HELP_CONTENT2"]));
    }

    if (isRanged) {
      dispatch(setCreateCycleField("helpMenuTitle", "CREATE_CYCLE_RANGED_PRICE_HELP_TITLE"));
      dispatch(setCreateCycleField("helpMenuContent", ["CREATE_CYCLE_RANGED_PRICE_HELP_CONTENT1", "CREATE_CYCLE_RANGED_PRICE_HELP_CONTENT2"]));
    }
    if (isVolume) {
      dispatch(setCreateCycleField("helpMenuTitle", "CREATE_CYCLE_VOLUME_PRICE_HELP_TITLE"));
      dispatch(setCreateCycleField("helpMenuContent", ["CREATE_CYCLE_VOLUME_PRICE_HELP_CONTENT1", "CREATE_CYCLE_VOLUME_PRICE_HELP_CONTENT2"]));
    }
    if (isTiered) {
      dispatch(setCreateCycleField("helpMenuTitle", "CREATE_CYCLE_TIERED_PRICE_HELP_TITLE"));
      dispatch(setCreateCycleField("helpMenuContent", ["CREATE_CYCLE_TIERED_PRICE_HELP_CONTENT1", "CREATE_CYCLE_TIERED_PRICE_HELP_CONTENT2"]));
    }
  };

  const getBillingDateOptions = () => {
    if (frequencyType.value === FrequencyType.Yearly) {
      return billingDates.filter(d => d.value !== BillingDateType.FIXED_DAY_WEEK && d.value !== BillingDateType.FIXED_DAY_MONTH);
    }

    if (frequencyType.value === FrequencyType.Monthly) {
      return billingDates.filter(d => d.value !== BillingDateType.FIXED_DAY_EACH_YEAR && d.value !== BillingDateType.FIXED_DAY_WEEK)
    }

    if (frequencyType.value === FrequencyType.Weekly) {
      return billingDates.filter(d => d.value !== BillingDateType.FIXED_DAY_EACH_YEAR && d.value !== BillingDateType.FIXED_DAY_MONTH)
    }

    if(frequencyType.value === FrequencyType.Daily) {
      return billingDates.filter(d => d.value !== BillingDateType.FIXED_DAY_EACH_YEAR && d.value !== BillingDateType.FIXED_DAY_MONTH && d.value !== BillingDateType.FIXED_DAY_WEEK)
    }
    return []
  }

  const renderPriceFormGroup = () => {
    return (
      <FormGroup>
        <Row align="center">
          <Col xs={2}>
            <FormLabel target="create-cycle-price" content="CREATE_PLAN_ADDING_CYCLE_PRICE" noMargin />
          </Col>
          <Col xs={3}>
            <div className="create-cycle__price">
              <Text content={currencySymbol} shouldTranslate={false} noMargin />
              <Input
                id="create-cycle-price"
                required
                type="number"
                step={0.01}
                isError={!isPriceValueValid()}
                value={price}
                placeholder="10.00"
                onFocus={setHelpTextPriceTypes}
                onChange={(evt: any) => {
                  dispatch(setCreateCycleField("price", allowZeroAndPositiveNumbers(evt.target.value)))
                }}
              />
            </div>
          </Col>
          <Col xs={isPerUnit ? 4 : 3}>
            <div className="create-cycle__price">
              <Text className={isPerUnit ? "create-cycle__price__every" : ""} content={isPerUnit ? "CREATE_PLAN_ADDING_CYCLE_PERUNIT_EVERY" : "CREATE_PLAN_ADDING_CYCLE_EVERY"} noMargin />
              <Input
                id="create-cycle-frequency"
                required
                type="number"
                allowDecimal={false}
                value={frequency}
                isError={!isFrequencyValueValid(frequency, frequencyType.value)}
                placeholder="x"
                onFocus={setHelpTextPriceTypes}
                onBlur={onBlurEmptyFrequency}
                onChange={(evt: any) => {
                  if (Number(evt.target.value) > 1) {
                    const frequencyTypePlural = frequencyTypesPlural.find(f => f.value === frequencyType.value);
                    dispatch(setCreateCycleField("frequencyType", frequencyTypePlural));
                  } else {
                    const frequencyTypeSingular = frequencyTypes.find(f => f.value === frequencyType.value);
                    dispatch(setCreateCycleField("frequencyType", frequencyTypeSingular));
                  }
                  dispatch(setCreateCycleField("frequency", allowZeroAndPositiveNumbers(evt.target.value)));
                }}
              />
            </div>
          </Col>
          <Col xs={isPerUnit ? 3 : 4}>
            <Dropdown
              id="frequencyType"
              options={!frequency || +frequency === 1 ? frequencyTypes : frequencyTypesPlural}
              value={frequencyType}
              onChange={(selectedItem: any) => {
                dispatch(setCreateCycleField("frequencyType", selectedItem));
                // need to reset the billing date to avoid forbidden values
                dispatch(setCreateCycleField("billingDate", billingDates.find(d => d.value === BillingDateType.ANNINVERSARY_SIGNUP)));
              }}
            />
          </Col>
        </Row>
      </FormGroup>
    );
  };

  const renderTierPriceFormGroup = () => {
    const maxEndUnit = 1000000;
    const currentTierUnit = createdTiers[createdTiers.length - 1] ? createdTiers[createdTiers.length - 1].finish + 1 : 1;
    const nextTierUnit = !!tierUnit && tierUnit !== 0 ? tierUnit : maxEndUnit;
    const isValidTierUnit = (!!tierUnit && tierUnit !== 0 ? currentTierUnit <= tierUnit : true);
    const enableAddTier = pricePerUnit !== undefined ? isValidTierUnit : false;
    const rangeIsMaxed = createdTiers[createdTiers.length - 1] && createdTiers[createdTiers.length - 1].finish === maxEndUnit;
    const isNotAddTierAllowed = !enableAddTier || rangeIsMaxed || tierUnit > maxEndUnit;

    return (
      <div className="create-cycle-tiered">
        <FormGroup>
          <Row align="center">
            <Col xs={2}>
              <FormLabel target="unit-range" shouldTranslate={false} content={counterpart("CREATE_PLAN_ADDING_CYCLE_UNITS", { unitFrom: rangeIsMaxed ? 1 : currentTierUnit })} noMargin />
            </Col>
            <Col xs={2}>
              <Input
                disabled={rangeIsMaxed}
                id="unit-range"
                max={maxEndUnit}
                type="number"
                icon=""
                value={tierUnit === 0 ? "" : tierUnit}
                placeholder={counterpart("CREATE_PLAN_UNITS_ABOVE")}
                allowDecimal={false}
                onChange={(evt: any) => dispatch(setTierUnit(allowZeroAndPositiveNumbers(evt.target.value)))}
                onFocus={setHelpTextPriceTypes}
              />
            </Col>
            <Col xs={2}>
              <FormLabel target="price-per-unit" content={isRanged ? "CREATE_PLAN_ADDING_CYCLE_PRICE_FOR_RANGE" : "CREATE_PLAN_ADDING_CYCLE_PRICE_PER_UNIT"} noMargin />
            </Col>
            <Col xs={3}>
              <div className="create-cycle__price">
                <Text className="text-blue" content={currencySymbol} shouldTranslate={false} noMargin />
                <Input
                  disabled={rangeIsMaxed}
                  id="price-per-unit"
                  type="number"
                  value={pricePerUnit}
                  placeholder="10.00"
                  isError={!isPriceValueValid()}
                  onChange={(evt: any) => dispatch(setPricePerUnit(allowZeroAndPositiveNumbers(evt.target.value)))}
                  onFocus={setHelpTextPriceTypes}
                />
              </div>
            </Col>
            <Col xs={3}>
              <Button
                id="add-tier"
                disabled={isNotAddTierAllowed || !isPriceValueValid()}
                buttonType="add"
                title="CREATE_PLAN_ADDING_CYCLE_ADD_TIERED"
                icon="fal fa-plus-circle"
                isFullWidth
                onClick={() => {
                  if (isNotAddTierAllowed) {
                    return;
                  }
                  dispatch(addTier({ price: pricePerUnit && Math.round(pricePerUnit * 100), start: currentTierUnit, finish: nextTierUnit }));
                  dispatch(setTierUnit(0));
                  dispatch(setPricePerUnit(undefined));
                }}
              />
            </Col>
          </Row>
        </FormGroup>
        {createdTiers.map((tier, idx) => (
          <Panel className="create-cycle-tiered__panel" key={idx}>
            <Text
              content={tier.finish === maxEndUnit ? "CREATE_PLAN_ADDING_CYCLE_UNITS_RANGE_MAX" : "CREATE_PLAN_ADDING_CYCLE_UNITS_RANGE"}
              translateWith={{ unitFrom: tier.start, unitTo: tier.finish }}
              noMargin
            />
            <span className="divider divider--vertical" />
            <Text
              content={isRanged ? "CREATE_PLAN_ADDING_CYCLE_PRICE_FOR_RANGE_DATA" : "CREATE_PLAN_ADDING_CYCLE_PRICE_PER_UNIT_DATA"}
              translateWith={{ price: tier.priceFormatted || `${currencySymbol}${convertToDecimalFormat(tier.price / 100)}` }}
              noMargin
            />
            {createdTiers.length - 1 === idx && <i className="far fa-trash-alt" onClick={() => dispatch(deleteTier(idx))} />}
          </Panel>
        ))}
        {createdTiers.length !== 0 && (
          <Panel className="create-cycle-tiered__panel create-cycle-tiered__panel--preview">
            <FormLabel target="preview-input" content="CREATE_PLAN_ADDING_CYCLE_PREVIEW" noMargin />
            <div className="create-cycle-tiered__preview-content">
              <Text noMargin content="CREATE_PLAN_ADDING_CYCLE_FOR" />
              <Input
                id="preview-input"
                type="number"
                value={unitsToSimulate}
                allowDecimal={false}
                onChange={(evt: any) => setUnitsToSimulate(evt.target.value)}
                onFocus={() => {
                  dispatch(setCreateCycleField("helpMenuTitle", "CREATE_PLAN_ADDING_CYCLE_PREVIEW_PRICE_HELP_TITLE"));
                  dispatch(setCreateCycleField("helpMenuContent", ["CREATE_PLAN_ADDING_CYCLE_PREVIEW_PRICE_HELP_CONTENT1", "CREATE_PLAN_ADDING_CYCLE_PREVIEW_PRICE_HELP_CONTENT2"]));
                }}
              />
              <Text noMargin content="CREATE_PLAN_ADDING_CYCLE_PREVIEW_DESCRIPTION" translateWith={{ simulatedPrice: `${currencySymbol}${simulatePrice()}` }} />
            </div>
          </Panel>
        )}
        <FormGroup>
          <Row align="center">
            <Col xs={2}>
              <FormLabel target="create-cycle-frequency" content="CREATE_PLAN_ADDING_CYCLE_FRQUENCY" />
            </Col>
            <Col xs={3}>
              <Input
                id="create-cycle-frequency"
                required
                isError={!isFrequencyValueValid(frequency, frequencyType.value)}
                type="number"
                className={isPerUnit ? "create-cycle__price__every" : ""}
                allowDecimal={false}
                value={frequency}
                placeholder="x"
                onFocus={setHelpTextPriceTypes}
                onBlur={onBlurEmptyFrequency}
                onChange={(evt: any) => {
                  if (Number(evt.target.value) > 1) {
                    const frequencyTypePlural = frequencyTypesPlural.find(f => f.value === frequencyType.value);
                    dispatch(setCreateCycleField("frequencyType", frequencyTypePlural));
                  } else {
                    const frequencyTypeSingular = frequencyTypes.find(f => f.value === frequencyType.value);
                    dispatch(setCreateCycleField("frequencyType", frequencyTypeSingular));
                  }
                  dispatch(setCreateCycleField("frequency", evt.target.value));
                }}
              />
            </Col>
            <Col xs={7}>
              <Dropdown
                id="frequencyType"
                options={!frequency || +frequency === 1 ? frequencyTypes : frequencyTypesPlural}
                value={frequencyType}
                onChange={(selectedItem: any) => {
                  dispatch(setCreateCycleField("frequencyType", selectedItem));
                  // need to reset the billing date to avoid forbidden values
                  dispatch(setCreateCycleField("billingDate", billingDates.find(d => d.value === BillingDateType.ANNINVERSARY_SIGNUP)));
                }}
              />
            </Col>
          </Row>
        </FormGroup>

        <span className="divider" />
      </div>
    );
  };

  const renderFreeQuantityFormGroup = () => {
    return (
      <FormGroup>
        <Row align="center">
          <Col xs={2}>
            <FormLabel target="switch-free-quantity" content="CREATE_CYCLE_FREE_QUANTITY_TITLE" noMargin />
          </Col>
          <Col xs={10}>
            <div className="create-cycle__free-quantity">
              <Switch
                id="switch-free-quantity"
                checked={isFreeQuantity}
                onChange={(isChecked: boolean) => {
                  dispatch(setCreateCycleField("isFreeQuantity", isChecked));
                  dispatch(setCreateCycleField("helpMenuTitle", "CREATE_CYCLE_FREE_QUANTITY_HELP_TITLE"));
                  dispatch(setCreateCycleField("helpMenuContent", ["CREATE_CYCLE_FREE_QUANTITY_HELP_CONTENT"]));
                  if (!isChecked) {
                    dispatch(setCreateCycleField("freeQuantity", undefined));
                  }
                }}
              />
              {isFreeQuantity ? (
                <Input
                  id="free-quantity-input"
                  type="number"
                  placeholder="1"
                  allowDecimal={false}
                  value={freeQuantity}
                  max={999999999}
                  onFocus={() => {
                    dispatch(setCreateCycleField("helpMenuTitle", "CREATE_CYCLE_FREE_QUANTITY_HELP_TITLE"));
                    dispatch(setCreateCycleField("helpMenuContent", ["CREATE_CYCLE_FREE_QUANTITY_HELP_CONTENT"]));
                  }}
                  onChange={(evt: any) => handleFreeQuantityChanged(evt)}
                />
              ) : null}
            </div>
          </Col>
        </Row>
      </FormGroup>
    );
  };

  const renderFreeTrialFormGroup = () => {
    return (
      <FormGroup>
        <Row align="center">
          <Col xs={2}>
            <FormLabel target="switch-free-trial" content="CREATE_CYCLE_FREE_TRIAL_TITLE" noMargin />
          </Col>
          <Col xs={3}>
            <div className="create-cycle__free-trial">
              <Switch
                id="switch-free-trial"
                checked={isFreeTrial}
                onChange={(isChecked: boolean) => {
                  dispatch(setCreateCycleField("isFreeTrial", isChecked));
                  dispatch(setCreateCycleField("helpMenuTitle", "CREATE_CYCLE_FREE_TRIAL_HELP_TITLE"));
                  dispatch(setCreateCycleField("helpMenuContent", ["CREATE_CYCLE_FREE_TRIAL_HELP_CONTENT"]));

                  if (!isChecked) {
                    dispatch(setCreateCycleField("freeTrial", undefined));
                    dispatch(setCreateCycleField("freeTrialFrequencyType", { label: counterpart("CREATE_PLAN_PERIOD_MONTHLY"), value: FrequencyType.Monthly }));
                  }
                }}
              />
              {isFreeTrial ? (
                <Input
                  id="free-trial-input"
                  placeholder="1"
                  step={0.01}
                  required
                  allowDecimal={false}
                  min={1}
                  max={
                    freeTrialFrequencyType &&
                    (freeTrialFrequencyType.value === FrequencyType.Daily
                      ? 1825
                      : freeTrialFrequencyType.value === FrequencyType.Monthly
                        ? 60
                        : freeTrialFrequencyType.value === FrequencyType.Weekly
                          ? 260
                          : 5)
                  }
                  type="number"
                  value={freeTrial}
                  onFocus={() => {
                    dispatch(setCreateCycleField("helpMenuTitle", "CREATE_CYCLE_FREE_TRIAL_HELP_TITLE"));
                    dispatch(setCreateCycleField("helpMenuContent", ["CREATE_CYCLE_FREE_TRIAL_HELP_CONTENT"]));
                  }}
                  onChange={(evt: any) => {
                    if (Number(evt.target.value) > 1) {
                      const frequencyTypePlural = frequencyTypesPlural.find(f => f && f.value === freeTrialFrequencyType.value);
                      dispatch(setCreateCycleField("freeTrialFrequencyType", frequencyTypePlural));
                    } else {
                      const frequencyTypeSingular = frequencyTypes.find(f => f && f.value === freeTrialFrequencyType.value);
                      dispatch(setCreateCycleField("freeTrialFrequencyType", frequencyTypeSingular));
                    }
                    dispatch(setCreateCycleField("freeTrial", allowZeroAndPositiveNumbers(evt.target.value)));
                  }}
                />
              ) : null}
            </div>
          </Col>
          <Col xs={7}>
            {isFreeTrial ? (
              <Dropdown
                id="free-trial-dropdown-id"
                value={freeTrialFrequencyType}
                options={!freeTrial || +freeTrial === 1 ? frequencyTypes : frequencyTypesPlural}
                onChange={(selectedItem: any) => dispatch(setCreateCycleField("freeTrialFrequencyType", selectedItem))}
              />
            ) : null}
          </Col>
        </Row>
      </FormGroup>
    );
  };

  const renderSetupFreeFormGroup = () => {
    return (
      <FormGroup>
        <Row align="center">
          <Col xs={2}>
            <FormLabel target="switch-setup-fee" content="CREATE_CYCLE_SETUP_FEE_TITLE" noMargin />
          </Col>
          <Col xs={10}>
            <div className="create-cycle__setup-fee">
              <Switch
                id="switch-setup-fee"
                checked={isSetupFee}
                onChange={(isChecked: boolean) => {
                  dispatch(setCreateCycleField("isSetupFee", isChecked));
                  dispatch(setCreateCycleField("helpMenuTitle", "CREATE_CYCLE_SETUP_FEE_HELP_TITLE"));
                  dispatch(setCreateCycleField("helpMenuContent", ["CREATE_CYCLE_SETUP_FEE_HELP"]));

                  if (!isChecked) {
                    dispatch(setCreateCycleField("setupFeePrice", undefined));
                  }
                }}
              />
              {isSetupFee ? (
                <div>
                  <Text content={currencySymbol} shouldTranslate={false} component="span" noMargin />
                  <Input
                    id="setup-fee-input"
                    type="number"
                    step={0.01}
                    required
                    placeholder="10.00"
                    min={0.5}
                    max={999999.99}
                    onFocus={() => {
                      dispatch(setCreateCycleField("helpMenuTitle", "CREATE_CYCLE_SETUP_FEE_HELP_TITLE"));
                      dispatch(setCreateCycleField("helpMenuContent", ["CREATE_CYCLE_SETUP_FEE_HELP"]));
                    }}
                    value={setupFeePrice}
                    onChange={(evt: any) => handleSetupFeePriceChanged(evt)}
                  />
                </div>
              ) : null}
            </div>
          </Col>
        </Row>
      </FormGroup>
    );
  };

  const renderBillingDateBillingFormGroup = () => {
    return (
      <FormGroup>
        <Row align="center">
          <Col xs={2}>
            <FormLabel target="billing-date-dropdown" content="CREATE_CYCLE_BILLING_DATE_TITLE" noMargin />
          </Col>
          <Col xs={8}>
            <Dropdown
              id="billing-date-dropdown"
              value={billingDate}
              // the billing date option 'fixed day of the week' is not available when the user select an yearly/monthly frequency type
              // the billing date option 'fixed day of the month' is not available when the user select a daily/weekly frequency type
              options={getBillingDateOptions()}
              onChange={(newBillingDate: any) => {
                dispatch(setCreateCycleField("billingDate", newBillingDate));
                dispatch(setCreateCycleField("billingMonth", { label: "", value: moment().month() + 1 }));
                dispatch(setCreateCycleField("billingDay", newBillingDate.value === BillingDateType.FIXED_DAY_WEEK ? billingDaysWeek[0] :
                  newBillingDate.value === BillingDateType.FIXED_DAY_EACH_YEAR ? { label: "", value: moment().date() } : billingDaysMonth[0]));
              }}
            />
          </Col>
          <Col xs={2}>
            {billingDate.value === BillingDateType.FIXED_DAY_EACH_YEAR &&
              <MonthDayPicker
                value={`${moment(`${billingMonth.value} ${billingDay.value}`, "M D").format("MMM DD")}`}
                onChange={value => {
                  const day = moment(value, "MMM DD").date()
                  const month = moment(value, "MMM DD").month() + 1

                  dispatch(setCreateCycleField("billingDay", { label: value, value: day }));
                  dispatch(setCreateCycleField("billingMonth", { label: value, value: month }));
                }} />}
            {billingDate.value !== BillingDateType.FIXED_DAY_EACH_YEAR &&
              < Dropdown
                id="billing-date-day"
                placeholder=""
                value={billingDate.value === BillingDateType.ANNINVERSARY_SIGNUP ? null : billingDate.value === BillingDateType.FIXED_DAY_WEEK ? billingDaysWeek.find(d => d.value === billingDay.value) : billingDay}
                options={billingDate.value === BillingDateType.FIXED_DAY_MONTH ? billingDaysMonth : billingDaysWeek}
                onChange={(selectedItem: any) => {
                  dispatch(setCreateCycleField("billingDay", selectedItem))
                }}
                isDisabled={billingDate.value === BillingDateType.ANNINVERSARY_SIGNUP}
              />
            }
          </Col>
        </Row>
      </FormGroup>
    );
  };

  const renderProRatingFormGroup = () => {
    if (billingDate.value === BillingDateType.ANNINVERSARY_SIGNUP) {
      return null;
    }

    return (
      <FormGroup>
        <Row>
          <Col xs={12}>
            <Panel className="create-cycle__prorate">
              <Text content="CREATE_CYCLE_PRORATE_TITLE" />
              <Text content="CREATE_CYCLE_PRORATE_SUBTITLE" />
              <Checkbox
                value={ProRateType.NO_PRORATE}
                checked={proRateType === ProRateType.NO_PRORATE}
                content={counterpart("CREATE_CYCLE_PRORATE_1")}
                onClick={() => {
                  dispatch(setCreateCycleField("proRateType", ProRateType.NO_PRORATE));
                  dispatch(setCreateCycleField("helpMenuTitle", "CREATE_CYCLE_PRORATE_HELP1_TITLE"));
                  dispatch(setCreateCycleField("helpMenuContent", ["CREATE_CYCLE_PRORATE_HELP1_PART1", "CREATE_CYCLE_PRORATE_HELP1_PART2"]));
                }}
              />
              <Checkbox
                value={ProRateType.NO_CHARGE_UNTIL_NEXT_CYCLE}
                checked={proRateType === ProRateType.NO_CHARGE_UNTIL_NEXT_CYCLE}
                content={counterpart("CREATE_CYCLE_PRORATE_2")}
                onClick={() => {
                  dispatch(setCreateCycleField("proRateType", ProRateType.NO_CHARGE_UNTIL_NEXT_CYCLE));
                  dispatch(setCreateCycleField("helpMenuTitle", "CREATE_CYCLE_PRORATE_HELP2_TITLE"));
                  dispatch(setCreateCycleField("helpMenuContent", ["CREATE_CYCLE_PRORATE_HELP2_PART1", "CREATE_CYCLE_PRORATE_HELP2_PART2"]));
                }}
              />
              <Checkbox
                value={ProRateType.BASED_ON_DAYS_REMAINING}
                checked={proRateType === ProRateType.BASED_ON_DAYS_REMAINING}
                content={counterpart("CREATE_CYCLE_PRORATE_3")}
                onClick={() => {
                  dispatch(setCreateCycleField("proRateType", ProRateType.BASED_ON_DAYS_REMAINING));
                  dispatch(setCreateCycleField("helpMenuTitle", "CREATE_CYCLE_PRORATE_HELP3_TITLE"));
                  dispatch(setCreateCycleField("helpMenuContent", ["CREATE_CYCLE_PRORATE_HELP3_PART1", "CREATE_CYCLE_PRORATE_HELP3_PART2", "CREATE_CYCLE_PRORATE_HELP3_PART3"]));
                }}
              />
            </Panel>
          </Col>
        </Row>
      </FormGroup>
    );
  };

  const renderContractTermFormGroup = () => {
    return (
      <FormGroup>
        <Row align="center">
          <Col xs={2}>
            <FormLabel target="contract-term-input" content="CREATE_CYCLE_CONTRACT_TERM_TITLE" noMargin />
          </Col>
          <Col xs={3}>
            <div className="create-cycle__contract-term">
              <Switch
                id="contract-term-switch"
                checked={isContractTerm}
                onChange={(isChecked: boolean) => {
                  dispatch(setCreateCycleField("isContractTerm", isChecked));
                  dispatch(setCreateCycleField("helpMenuTitle", "CREATE_CYCLE_CONTRACT_TERM_HELP_TITLE"));
                  dispatch(setCreateCycleField("helpMenuContent", ["CREATE_CYCLE_CONTRACT_TERM_HELP_CONTENT"]));

                  if (!isChecked) {
                    dispatch(setCreateCycleField("contractTerm", undefined));
                    dispatch(setCreateCycleField("contractTermFrequencyType", { label: counterpart("CREATE_PLAN_PERIOD_MONTHLY"), value: FrequencyType.Monthly }));
                  }
                }}
              />
              {isContractTerm ? (
                <Input
                  id="contract-term-input"
                  placeholder="1"
                  step={0.01}
                  required
                  min={1}
                  max={
                    contractTermFrequencyType &&
                    (contractTermFrequencyType.value === FrequencyType.Daily
                      ? 1825
                      : contractTermFrequencyType.value === FrequencyType.Monthly
                        ? 60
                        : contractTermFrequencyType.value === FrequencyType.Weekly
                          ? 260
                          : 5)
                  }
                  type="text"
                  // isError={!isFrequencyValueValid(contractTerm, contractTermFrequencyType ? contractTermFrequencyType.value : undefined)}
                  value={contractTerm}
                  onFocus={() => {
                    dispatch(setCreateCycleField("helpMenuTitle", "CREATE_CYCLE_CONTRACT_TERM_HELP_TITLE"));
                    dispatch(setCreateCycleField("helpMenuContent", ["CREATE_CYCLE_CONTRACT_TERM_HELP_CONTENT"]));
                  }}
                  onChange={(evt: any) => {
                    if (Number(evt.target.value) > 1) {
                      const frequencyTypePlural = frequencyTypesPlural.find(f => f && f.value === contractTermFrequencyType.value);
                      dispatch(setCreateCycleField("contractTermFrequencyType", frequencyTypePlural));
                    } else {
                      const frequencyTypeSingular = frequencyTypes.find(f => f && f.value === contractTermFrequencyType.value);
                      dispatch(setCreateCycleField("contractTermFrequencyType", frequencyTypeSingular));
                    }
                    dispatch(setCreateCycleField("contractTerm", allowZeroAndPositiveNumbers(removeSpecialChars(evt.target.value))))
                  }}
                />
              ) : null}
            </div>
          </Col>
          <Col xs={7}>
            {isContractTerm ? (
              <Dropdown
                id="contract-term-dropdown"
                value={contractTermFrequencyType}
                options={(!contractTerm || +contractTerm === 1) ? frequencyTypes : frequencyTypesPlural}
                onChange={(selectedItem: any) => dispatch(setCreateCycleField("contractTermFrequencyType", selectedItem))}
              />
            ) : null}
          </Col>
        </Row>
      </FormGroup>
    );
  };

  const renderVisibilityFormGroup = () => {
    return (
      <FormGroup>
        <Row align="center">
          <Col xs={2}>
            <FormLabel target="visibility-dropdown" content="CREATE_CYCLE_VISIBILITY_TITLE" noMargin />
          </Col>
          <Col xs={10}>
            <Dropdown
              id="visibility-dropdown"
              value={visibility}
              onChange={(selectedItem: any) => dispatch(setCreateCycleField("visibility", selectedItem))}
              options={visibilityTypes}
              onFocus={() => {
                dispatch(setCreateCycleField("helpMenuTitle", "CREATE_CYCLE_VISIBILITY_HELP_TITLE"));
                dispatch(setCreateCycleField("helpMenuContent", ["CREATE_CYCLE_VISIBILITY_HELP_CONTENT1", "CREATE_CYCLE_VISIBILITY_HELP_CONTENT2", "CREATE_CYCLE_VISIBILITY_HELP_CONTENT3", "CREATE_CYCLE_VISIBILITY_HELP_CONTENT6"]));
                // dispatch(setCreateCycleField('helpMenuContent', ['CREATE_CYCLE_VISIBILITY_HELP_CONTENT1', 'CREATE_CYCLE_VISIBILITY_HELP_CONTENT2', 'CREATE_CYCLE_VISIBILITY_HELP_CONTENT3',
                //   'CREATE_CYCLE_VISIBILITY_HELP_CONTENT4', 'CREATE_CYCLE_VISIBILITY_HELP_CONTENT5', 'CREATE_CYCLE_VISIBILITY_HELP_CONTENT6']));
              }}
            />
          </Col>
        </Row>
      </FormGroup>
    );
  };

  const renderAlertEmailFormGroup = () => {
    return (
      <FormGroup>
        <Row align="center">
          <Col xs={2}>
            <FormLabel target="alert-email-input" content="CREATE_CYCLE_ALERT_EMAIL_TITLE" noMargin />
          </Col>
          <Col xs={10}>
            <Input
              id="alert-email-input"
              placeholder={counterpart("LOGIN_EMAIL_PLACEHOLDER")}
              value={alertEmail}
              onChange={(evt: any) => dispatch(setCreateCycleField("alertEmail", evt.target.value))}
            />
          </Col>
        </Row>
      </FormGroup>
    );
  };

  useEffect(() => {
  }, [isAddingNewCycle]);

  if (isAddingNewCycle) {
    // create or edit cycle

    return (
      <div className="create-cycle">
        <StepCollapsablePanel
          title={`CREATE_PLAN_${editedCycle ? "EDITING" : "ADDING"}_CYCLE_TITLE`}
          isCollapsed={false}
          helpMenuTitle={helpMenuTitle}
          helpMenuContent={
            <div>
              {helpMenuContent.map((helpContent, id) => (
                <Text key={id} content={helpContent} />
              ))}
            </div>
          }
          isShowTitleIcon={isCreatingPlan}
        >
          <form>
            <div>
              {/* PRICE */}
              <div className="create-cycle__price-form">{isTiered || isVolume || isRanged ? renderTierPriceFormGroup() : renderPriceFormGroup()}</div>

              {/* FREE QUANTITY */}
              {(isVolume || isRanged || isPerUnit) && renderFreeQuantityFormGroup()}

              {/* FREE TRIAL */}
              {renderFreeTrialFormGroup()}

              {/* SETUP FEE */}
              {renderSetupFreeFormGroup()}

              {/* BILLING DATE */}
              {renderBillingDateBillingFormGroup()}

              {/* PRO RATING */}
              {renderProRatingFormGroup()}

              {/* TEMPORARY REMOVED THESE FEATURES */}
              {renderContractTermFormGroup()}

              {/* VISIBILITY */}
              {renderVisibilityFormGroup()}

              {/* TEMPORARY REMOVED THESE FEATURES */}
              {/* {renderAlertEmailFormGroup()} */}

              <Row>
                <Col xs={8}>
                  <Button
                    id="add-new-cycle"
                    title={editedCycle ? "CREATE_PLAN_CYCLE_EDIT" : "CREATE_PLAN_ADDING_CYCLE_BTN"}
                    isFullWidth
                    disabled={isAddCycleDisabled()}
                    onClick={() => {
                      if (isAddCycleDisabled()) {
                        return;
                      }

                      let newCycle: IBaseCycle = {
                        pricingModel: {
                          frequency: 1,
                          frequencyType: FrequencyType.Monthly,
                          price: 0, // Cannot be null
                          priceFormatted: planProductCurrencyTemplate.replace(templateCharToBeReplaced, price ? convertToDecimalFormat(price) : "0".toString()),
                          freeTrial: isFreeTrial ? Number(freeTrial) : undefined,
                          freeTrialFrequencyType: isFreeTrial ? freeTrialFrequencyType.value : undefined,
                          contractTerm: isContractTerm ? Number(contractTerm) : undefined,
                          contractTermFrequencyType: isContractTerm ? contractTermFrequencyType.value : undefined,
                          setupFeePrice: isSetupFee ? Number(setupFeePrice) : undefined,
                          billingDateType: billingDate.value,
                          fixedBillingDateDay: billingDate.value !== BillingDateType.ANNINVERSARY_SIGNUP ? billingDay.value : undefined,
                          fixedBillingDateMonth: billingDate.value === BillingDateType.FIXED_DAY_EACH_YEAR ? billingMonth.value : undefined,
                          proRateOption: billingDate.value !== BillingDateType.ANNINVERSARY_SIGNUP ? proRateType : undefined,
                          visibility: visibility.value
                        }
                      };

                      if ((isTiered || isRanged || isVolume) && frequency && frequencyType) {
                        newCycle = {
                          pricingModel: {
                            ...newCycle.pricingModel,
                            frequency,
                            frequencyType: frequencyType.value,
                            freeQuantity: (isVolume || isRanged) && isFreeQuantity ? freeQuantity : undefined,
                            tiers: createdTiers.map(tier => ({ ...tier, priceFormatted: planProductCurrencyTemplate.replace(templateCharToBeReplaced, convertToDecimalFormat(tier.price / 100)) }))
                          }
                        };
                      } else if (frequency && price !== undefined && frequencyType) {
                        if (isPerUnit) {
                          newCycle = {
                            pricingModel: {
                              ...newCycle.pricingModel,
                              frequency,
                              freeQuantity: isFreeQuantity ? freeQuantity : undefined,
                              frequencyType: frequencyType.value,
                              price
                            }
                          };
                        } else {
                          newCycle = {
                            pricingModel: {
                              ...newCycle.pricingModel,
                              frequency,
                              frequencyType: frequencyType.value,
                              price
                            }
                          };
                        }
                      }

                      //Update unconfigured price models when editing cyles
                      updateUnconfiguredPriceModels();


                      dispatch(addCycle(newCycle));
                      dispatch(setIsAddingNewCycle(false));
                      dispatch(setPlanFormCompleted(2, true));
                    }}
                  />
                </Col>
                <Col xs={4}>
                  <Button id="cancel-new-cycle" title="CREATE_PLAN_ADDING_CYCLE_BTN_CANCEL" isFullWidth buttonType="error" onClick={() => dispatch(setIsAddingNewCycle(false))} />
                </Col>
              </Row>
            </div>
          </form>
        </StepCollapsablePanel>
      </div>
    );
  }

  // show list of cycles
  return (
    <StepCollapsablePanel
      number={3}
      title={counterpart(isCreatingPlan ? "CREATE_PLAN_CYCLE_TITLE" : "EDIT_PLAN_CYCLE_TITLE")}
      shouldTranslateTitle={false}
      hasHelpSection={!isCreatingPlan}
      isCompleted={stageStatuses[2]}
      isLocked={!hasSelectedFee}
      // isShowLockIcon={!stageStatuses[0] || !stageStatuses[1]}
      onClick={() => dispatch(setPlanFormCollapsed(2))}
      onFocus={() => changeCollapsedValue(2, stageCollapsed, dispatch)}
      isCollapsed={!isCreatingPlan ? false : stageCollapsed[2]}
      isShowTitleIcon={isCreatingPlan}
      helpMenuIcon="far fa-lightbulb-exclamation"
      helpMenuPosition="top"
      helpMenuType="warning"
      helpMenuContent={
        <Text
          content={"CREATE_PLAN_CYCLE_WARNING"}
          translateWith={{ boldText: <span className="bold">{counterpart("CREATE_PLAN_CYCLE_WARNING_RETAIN")}</span> }}
          noMargin
        />
      }
    >
      {!cycles.length ? (
        <div>
          <Text content="CREATE_PLAN_CYCLE_SUBTITLE" />
          <Button
            id="plan-costs"
            title="CREATE_PLAN_CYCLE_BTN"
            onClick={() => dispatch(setIsAddingNewCycle(true))}
            icon="fal fa-plus-circle"
            isFullWidth
            tabIndex={stageCollapsed[2] && !stageStatuses[1] ? -1 : 0}
          />
        </div>
      ) : (
          <div>
            {isReorderingCycles ? (
              <DragDropContainer
                list={tempCycles.map((cycle: IBaseCycle, id: number) => ({
                  content: (
                    <Panel className="create-cycle__list" key={id}>
                      <div className="create-cycle__listitem">
                        <Text content={getCycleDescription(cycle, false, pricingModel)} shouldTranslate={false} noMargin />
                        <StatusLabel content={cycle.pricingModel.visibility} shouldTranslate={false} color={getCycleStatusColor(cycle.pricingModel.visibility)} />
                      </div>
                      <div className="create-cycle__icons">
                        <i className="fas fa-grip-horizontal" />
                      </div>
                    </Panel>
                  ),
                  value: cycle
                }))}
                onDragEnd={newCyclesArr => dispatch(setTempCycles(newCyclesArr))}
              />
            ) : (
                cycles.map((cycle: IBaseCycle, id: number) => {
                  if (cycle.pricingModel.visibility === VisibilityType.OffSale) {
                    return null;
                  }

                  return (
                    <Panel className="create-cycle__list" key={id}>
                      <div className="create-cycle__listitem">
                        <Text content={getCycleDescription(cycle, false, pricingModel)} shouldTranslate={false} noMargin />
                        <StatusLabel content={cycle.pricingModel.visibility} shouldTranslate={false} color={getCycleStatusColor(cycle.pricingModel.visibility)} />
                      </div>
                      <div className="create-cycle__icons">
                        <ElementModifiers
                          onEditClick={() => {
                            dispatch(setIsAddingNewCycle(true));
                            dispatch(setEditedCycle(cycle));
                          }}
                          onDeleteClick={() => {
                            if (cycles.length === 1) {
                              // if after the removal there are no cycles left the form is not completed
                              dispatch(setPlanFormCompleted(2, false));
                            }
                            //Remove cycle price models 
                            removeUnconfiguredPriceModel(cycle)
                            dispatch(removeCycle(cycle));
                          }}
                        />
                      </div>
                    </Panel>
                  )
                })
              )}

            {isReorderingCycles ? (
              <Row>
                <Col xs={8}>
                  <Button
                    id="create-cycle-save-reoder"
                    title="CREATE_PLAN_CYCLE_SAVE_REORDER"
                    onClick={() => {
                      dispatch(setCycles([...tempCycles]));
                      dispatch(setIsReorderingCycles(false));
                    }}
                    isFullWidth
                  />
                </Col>
                <Col xs={4}>
                  <Button id="create-cycle-cancel-reorder" title="CREATE_PLAN_CYCLE_CANCEL_REORDER" onClick={() => dispatch(setIsReorderingCycles(false))} buttonType="error" isFullWidth />
                </Col>
              </Row>
            ) : (
                <Row>
                  <Col xs={8}>
                    <Button
                      id="create-cycle-add"
                      title="CREATE_PLAN_CYCLE_BTN2"
                      onClick={() => dispatch(setIsAddingNewCycle(true))}
                      icon="fal fa-plus-circle"
                      isFullWidth
                      tabIndex={stageCollapsed[2] && !stageStatuses[1] ? -1 : 0}
                    />
                  </Col>
                  <Col xs={4}>
                    <Button
                      id="create-cycle-reorder"
                      title="CREATE_PLAN_CYCLE_REORDER_BTN"
                      onClick={() => {
                        dispatch(setTempCycles([...cycles]));
                        dispatch(setIsReorderingCycles(true));
                      }}
                      disabled={cycles.length <= 1}
                      buttonType="add"
                      icon="fal fa-sort"
                      isFullWidth
                      tabIndex={stageCollapsed[2] && !stageStatuses[1] ? -1 : 0}
                    />
                  </Col>
                </Row>
              )}
          </div>
        )}
    </StepCollapsablePanel>
  );
};

export default CreateCycle;
