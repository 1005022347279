import { makeActionCreator, makeGenericActionCreatorTypeSafe } from "../utils/reduxUtils";
import { ILinkAddOnsAllowancesReducerState } from "../reducers/linkAddOnsAllowancesReducer";
import { GetAddonsRequest } from "../utils/grpc/generated/Billsby.Protos/core/private/addons/addons_pb";
import { grpcUnaryCall } from "../utils/grpc/grpcUtils";
import { AddOnsServiceClient } from "../utils/grpc/generated/Billsby.Protos/core/private/addons/AddonsServiceClientPb";
import { ConfigConstants } from "../utils/config";
import { AddonsAllowancesScreen } from "../models/AddonsAllowances";
import { GetAllowanceRequest } from "../utils/grpc/generated/Billsby.Protos/core/private/allowances/allowances_pb";
import { AllowancesServiceClient } from "../utils/grpc/generated/Billsby.Protos/core/private/allowances/AllowancesServiceClientPb";

export const FETCH_COMPANY_PRODUCTS_ADD_ONS_REQUEST = "FETCH_COMPANY_PRODUCTS_ADD_ONS_REQUEST";
export const FETCH_COMPANY_PRODUCTS_ADD_ONS_SUCCESS = "FETCH_COMPANY_PRODUCTS_ADD_ONS_SUCCESS";
export const FETCH_COMPANY_PRODUCTS_ADD_ONS_FAILURE = "FETCH_COMPANY_PRODUCTS_ADD_ONS_FAILURE";

export const SET_LINK_ADD_ONS_ALLOWANCES_FIELDS = "SET_LINK_ADD_ONS_ALLOWANCES_FIELDS";
export const setLinkAddOnsAllowancesFields = makeGenericActionCreatorTypeSafe<ILinkAddOnsAllowancesReducerState>(SET_LINK_ADD_ONS_ALLOWANCES_FIELDS);

export const SET_SELECTED_COMPANY_ADD_ONS_PAGE = "SET_SELECTED_COMPANY_ADD_ONS_PAGE";
export const setSelectedCompanyAddOnsPage = makeActionCreator(SET_SELECTED_COMPANY_ADD_ONS_PAGE, "payload");

export const SET_SELECTED_ADD_ONS_PAGE = "SET_SELECTED_ADD_ONS_PAGE";
export const setSelectedAddOnsPage = makeActionCreator(SET_SELECTED_ADD_ONS_PAGE, "payload");

export const SET_SHOW_SELECT_ADD_ONS_MODAL = "SET_SHOW_SELECT_ADD_ONS_MODAL";
export const setShowSelectAddOnsModal = makeActionCreator(SET_SHOW_SELECT_ADD_ONS_MODAL, "payload");

export const SET_SELECTED_ADD_ONS = "SET_SELECTED_ADD_ONS";
export const setSelectedAddOns = makeActionCreator(SET_SELECTED_ADD_ONS, "payload");

export const SET_SELECTED_ADD_ONS_ID = "SET_SELECTED_ADD_ONS_ID";
export const setSelectedAddOnsId = makeActionCreator(SET_SELECTED_ADD_ONS_ID, "payload");

export const SET_FORCE_ADD_ON = "SET_FORCE_ADD_ON";
export const setForceAddOn = makeActionCreator(SET_FORCE_ADD_ON, "payload");

export const REMOVE_SELECTED_ADD_ON = "REMOVE_SELECTED_ADD_ON";
export const removeSelectedAddOn = makeActionCreator(REMOVE_SELECTED_ADD_ON, "payload");

export const DESELECT_ALL_SELECTED_ADD_ONS = "DESELECT_ALL_SELECTED_ADD_ONS";
export const deselectAllSelectedAddOns = makeActionCreator(DESELECT_ALL_SELECTED_ADD_ONS, "payload");

export const SELECT_ALL_ADD_ONS = "SELECT_ALL_ADD_ONS";
export const selectAllAddOns = makeActionCreator(SELECT_ALL_ADD_ONS, "payload");

export const SET_SEARCH_KEY_ADD_ONS_PAGE = "SET_SEARCH_KEY_ADD_ONS_PAGE";
export const setSearchKeyAddOnsPage = makeActionCreator(SET_SEARCH_KEY_ADD_ONS_PAGE, "payload");

export const SET_UNCOFIGURED_PRICE_MODELS_LIST = "SET_UNCOFIGURED_PRICE_MODELS_LIST";
export const setUnconfiguredPriceModelsList = makeActionCreator(SET_UNCOFIGURED_PRICE_MODELS_LIST, "payload");

export const ADD_CYCLE_PRICE_MODELS_LIST = "ADD_CYCLE_PRICE_MODELS_LIST";
export const addCylePriceModelsList = makeActionCreator(ADD_CYCLE_PRICE_MODELS_LIST, "payload");

export const REMOVE_CYCLE_PRICE_MODELS_LIST = "REMOVE_CYCLE_PRICE_MODELS_LIST";
export const removeCylePriceModelsList = makeActionCreator(REMOVE_CYCLE_PRICE_MODELS_LIST, "payload");

export const CANCEL_SELECTED_ADD_ONS_PAGE = "CANCEL_SELECTED_ADD_ONS_PAGE";
export const cancelSelectedAddOnsPage = makeActionCreator(CANCEL_SELECTED_ADD_ONS_PAGE, "payload");

export const RESET_ADD_ADD_ON_STATE = "RESET_ADD_ADD_ON_STATE";
export const resetAddAddOnState = makeActionCreator(RESET_ADD_ADD_ON_STATE, "payload");

export const fetchAddOnsSelectorList = (companyDomain: string, type: AddonsAllowancesScreen) => {

  let action: any = {
    types: [FETCH_COMPANY_PRODUCTS_ADD_ONS_REQUEST, FETCH_COMPANY_PRODUCTS_ADD_ONS_SUCCESS, FETCH_COMPANY_PRODUCTS_ADD_ONS_FAILURE],
    payload: { fetchType: type }
  }
  
  if (type === AddonsAllowancesScreen.ADDONS) {
    const getAddOns = new GetAddonsRequest();
    getAddOns.setCompanyDomain(companyDomain);
    const addonServiceClient = new AddOnsServiceClient(ConfigConstants.grpcBaseUrl);
    action.callAPI = () => grpcUnaryCall(getAddOns, addonServiceClient, addonServiceClient.getAddons)
  }
  else {
    const getAllowances = new GetAllowanceRequest();
    getAllowances.setCompanyDomain(companyDomain);
    const allowanceServiceClient = new AllowancesServiceClient(ConfigConstants.grpcBaseUrl);
    action.callAPI = () => grpcUnaryCall(getAllowances, allowanceServiceClient, allowanceServiceClient.getAllowances)
  }

  return action;
}

