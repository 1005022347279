/**
 * @fileoverview gRPC-Web generated client stub for billing.private.paymentlogs
 * @enhanceable
 * @public
 */

// GENERATED CODE -- DO NOT EDIT!


/* eslint-disable */
// @ts-nocheck


import * as grpcWeb from 'grpc-web';

import * as payment_logs_pb from './payment_logs_pb';


export class PaymentLogServiceClient {
  client_: grpcWeb.AbstractClientBase;
  hostname_: string;
  credentials_: null | { [index: string]: string; };
  options_: null | { [index: string]: any; };

  constructor (hostname: string,
               credentials?: null | { [index: string]: string; },
               options?: null | { [index: string]: any; }) {
    if (!options) options = {};
    if (!credentials) credentials = {};
    options['format'] = 'text';

    this.client_ = new grpcWeb.GrpcWebClientBase(options);
    this.hostname_ = hostname;
    this.credentials_ = credentials;
    this.options_ = options;
  }

  methodDescriptorGetPaymentLogs = new grpcWeb.MethodDescriptor(
    '/billing.private.paymentlogs.PaymentLogService/GetPaymentLogs',
    grpcWeb.MethodType.UNARY,
    payment_logs_pb.GetPaymentLogsRequest,
    payment_logs_pb.GetPaymentLogsResponse,
    (request: payment_logs_pb.GetPaymentLogsRequest) => {
      return request.serializeBinary();
    },
    payment_logs_pb.GetPaymentLogsResponse.deserializeBinary
  );

  getPaymentLogs(
    request: payment_logs_pb.GetPaymentLogsRequest,
    metadata: grpcWeb.Metadata | null): Promise<payment_logs_pb.GetPaymentLogsResponse>;

  getPaymentLogs(
    request: payment_logs_pb.GetPaymentLogsRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: payment_logs_pb.GetPaymentLogsResponse) => void): grpcWeb.ClientReadableStream<payment_logs_pb.GetPaymentLogsResponse>;

  getPaymentLogs(
    request: payment_logs_pb.GetPaymentLogsRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: payment_logs_pb.GetPaymentLogsResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/billing.private.paymentlogs.PaymentLogService/GetPaymentLogs',
        request,
        metadata || {},
        this.methodDescriptorGetPaymentLogs,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/billing.private.paymentlogs.PaymentLogService/GetPaymentLogs',
    request,
    metadata || {},
    this.methodDescriptorGetPaymentLogs);
  }

  methodDescriptorGetPaymentLogsPaginated = new grpcWeb.MethodDescriptor(
    '/billing.private.paymentlogs.PaymentLogService/GetPaymentLogsPaginated',
    grpcWeb.MethodType.UNARY,
    payment_logs_pb.GetPaymentLogsPaginatedRequest,
    payment_logs_pb.GetPaymentLogsPaginatedResponse,
    (request: payment_logs_pb.GetPaymentLogsPaginatedRequest) => {
      return request.serializeBinary();
    },
    payment_logs_pb.GetPaymentLogsPaginatedResponse.deserializeBinary
  );

  getPaymentLogsPaginated(
    request: payment_logs_pb.GetPaymentLogsPaginatedRequest,
    metadata: grpcWeb.Metadata | null): Promise<payment_logs_pb.GetPaymentLogsPaginatedResponse>;

  getPaymentLogsPaginated(
    request: payment_logs_pb.GetPaymentLogsPaginatedRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: payment_logs_pb.GetPaymentLogsPaginatedResponse) => void): grpcWeb.ClientReadableStream<payment_logs_pb.GetPaymentLogsPaginatedResponse>;

  getPaymentLogsPaginated(
    request: payment_logs_pb.GetPaymentLogsPaginatedRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: payment_logs_pb.GetPaymentLogsPaginatedResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/billing.private.paymentlogs.PaymentLogService/GetPaymentLogsPaginated',
        request,
        metadata || {},
        this.methodDescriptorGetPaymentLogsPaginated,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/billing.private.paymentlogs.PaymentLogService/GetPaymentLogsPaginated',
    request,
    metadata || {},
    this.methodDescriptorGetPaymentLogsPaginated);
  }

}

