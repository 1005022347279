import React, { FormEvent, useState } from "react";
import { useSelector } from "react-redux";
import Panel from "../ui/panel/Panel";
import Text from "../ui/text/Text";
import Button from "../ui/button/Button";
import "./SendCreditNote.scss";
import { AppState } from "../..";
import NoticePanel from "../ui/notice-panel/NoticePanel";
import API from "../../utils/API";

interface ISendCreditNoteProps {
  customerUniqueId: string,
  handleSendCreditNoteCallback?: Function,
}

const SendCreditNote: React.FC<ISendCreditNoteProps> = ({ customerUniqueId, handleSendCreditNoteCallback  }) => {
  const authData = useSelector((state: AppState) => state.auth);
  const invoiceDetails = useSelector((state: AppState) => state.invoiceDetailsReducer.invoiceDetails);
  
  const [state, setState] = useState({
    hasError: false,
    isLoadingData: false,
    isLoading: false
  });
  const { hasError, isLoadingData, isLoading } = state;

  const onSubmit = async (evt: FormEvent) => {
    evt.preventDefault();
    evt.stopPropagation();

    setState({ ...state, isLoading: true });

    try {
     await API.sendCreditNoteLink(authData.currentCompanyDomain, customerUniqueId, { invoiceNumber: invoiceDetails.invoiceNumber });
     handleSendCreditNoteCallback && handleSendCreditNoteCallback();
    } catch (err) {
      setState({ ...state, isLoading: false, hasError: true });
      console.log(err)
    }
  };

  if (isLoadingData) {
    return null;
  }

  return (
    <>
      <form className="send-credit-note" onSubmit={onSubmit}>
        {hasError && <NoticePanel type="error" isModal={true} icon="far fa-lightbulb-exclamation" content="SEND_CREDIT_NOTE_ERROR" />}
        <Panel className="send-credit-note__titlepanel" title="SEND_CREDIT_NOTE_PANEL1_TITLE">
          {/* TODO: Include customer email in invoice data */}
          <div className="send-credit-note__description">
            <Text content="SEND_CREDIT_NOTE_PANEL1_CONTENT" translateWith={{ customerEmail: <span className="send-credit-note__email">{invoiceDetails.customerEmail || "sample@email.com"}</span> }} component="span" noMargin />
          </div>
        </Panel>

        <Button
          id="charge-customer"
          type="submit"
          buttonType={"general"}
          isLoading={isLoading && !hasError}
          title={"SEND_CREDIT_NOTE_RESEND_CREDIT_NOTE_LINK"}
          isFullWidth
        />
      </form>
    </>
  );
};

export default SendCreditNote;
