export const INDUSTRY_OPTIONS = [
  { value: "Retail", label: "Retail" },
  { value: "Digital Products", label: "Digital Products" },
  { value: "Food and drink", label: "Food and drink" },
  { value: "Professional services", label: "Professional services" },
  { value: "Membership organizations", label: "Membership organizations" },
  { value: "Personal services", label: "Personal services" },
  { value: "Transportation", label: "Transportation" },
  { value: "Travel and lodging", label: "Travel and lodging" },
  { value: "Medical services", label: "Medical services" },
  { value: "Education", label: "Education" },
  { value: "Entertainment as recreation", label: "Entertainment as recreation" },
  { value: "Building services", label: "Building services" },
  { value: "Financial services", label: "Financial services" },
  { value: "Regulated and age-restricted products", label: "Regulated and age-restricted products" }
]

export const INDUSTRY_MULTIOPTIONS = [
  {
    key: "Retail",
    value: [
      "Software",
      "Clothing and accessories",
      "Convenience stores",
      "Beauty products",
      "Home goods and furniture",
      "Home electronics",
      "Auto parts and accessories",
      "Other merchandise"
    ]
  },
  {
    key: "Digital Products",
    value: [
      "SaaS",
      "Apps",
      "Books",
      "Music or other media",
      "Games",
      "Blogs and written content",
      "Other digital goods"
    ]
  },
  {
    key: "Food and drink",
    value: [
      "Restaurants and nightlife",
      "Grocery stores",
      "Caterers",
      "Other food and dining"
    ]
  },
  {
    key: "Professional services",
    value: [
      "Consulting",
      "Printing and publishing",
      "Attorneys and lawyers",
      "Bankruptcy services",
      "Bail bonds",
      "Accounting and auditing, or tax prep",
      "Computer repair",
      "Testing laboratories",
      "Auto services",
      "Car rentals",
      "Car sales",
      "Lead generation",
      "Direct marketing",
      "Utilities",
      "Government services",
      "Telemarking",
      "Credit counseling or credit repair",
      "Mortgage consulting services",
      "Debt reduction services",
      "Warranty services",
      "Other Marketing services",
      "Other Business services "
    ]
  },
  {
    key: "Membership organizations",
    value: [
      "Civic, fraternal, or social associations",
      "Charities or social service organizations",
      "Religious organizations",
      "Country clubs",
      "Political organizations",
      "Other membership organizations"
    ]
  },
  {
    key: "Personal services",
    value: [
      "Photography studios",
      "Health and beauty spas",
      "Salons or barbers",
      "Landscaping services",
      "Massage parlors",
      "Counseling services",
      "Health and wellness coaching",
      "Laundry or cleaning services",
      "Dating services",
      "Other personal services"
    ]
  },
  {
    key: "Transportation",
    value: [
      "Ridesharing",
      "Taxis and limos",
      "Courier services",
      "Parking lots",
      "Travel agencies",
      "Freight forwarders",
      "Shipping or forwarding",
      "Commuter transportation",
      "Cruise lines",
      "Airlines and air carriers",
      "Other transportation services"
    ]
  },
  {
    key: "Travel and lodging",
    value: [
      "Property rentals",
      "Hotels, Inns, or Motels",
      "Timeshares",
      "Other travel and lodging"
    ]
  },
  {
    key: "Medical services",
    value: [
      "Medical devices",
      "Doctors and physicians",
      "Opticians and eyeglasses",
      "Dentists and orthodontists",
      "Chiropractors",
      "Nursing or personal care facilities",
      "Hospitals",
      "Personal fundraising or crowdfunding",
      "Mental health services",
      "Assisted living",
      "Veterinary services",
      "Medical organizations",
      "Telemedicine and Telehealth",
      "Other medical services"
    ]
  },
  {
    key: "Education",
    value: [
      "Child care services",
      "Colleges or universities",
      "Vocational schools or trade schools",
      "Elementary or secondary schools",
      "Other educational services"
    ]
  },
  {
    key: "Entertainment as recreation",
    value: [
      "Event ticketing",
      "Tourist attractions",
      "Recreational camps",
      "Musicians, bands, or orchestras",
      "Amusement parks, carnivals, or circuses",
      "Fortune tellers",
      "Movie theaters",
      "Betting or fantasy sports",
      "Lotteries",
      "Sports forecasting or prediction services",
      "Online gambling",
      "Other entertainment and recreation"
    ]
  },
  {
    key: "Building services",
    value: [
      "General contractors",
      "Electrical contractors",
      "Carpentry contractors",
      "Special trade contractors",
      "Telecom services",
      "Telecom equipment",
      "A/C and heating contractors",
      "Other building services"
    ]
  },
  {
    key: "Financial services",
    value: [
      "Insurance",
      "Security brokers or dealers",
      "Money orders",
      "Currency exchanges",
      "Wire transfers",
      "Loans or lending",
      "Collections agencies",
      "Money services or transmission",
      "Investment services",
      "Virtual currencies",
      "Digital wallets",
      "Cryptocurrencies",
      "Other financial institutions",
      "Financial information and research"
    ]
  },
  {
    key: "Regulated and age-restricted products",
    value: [
      "Pharmacies or pharmaceuticals",
      "Tobacco or cigars",
      "Adult content or services",
      "Vapes, e-cigarettes, e-juice or related products",
      "Weapons or munitions",
      "Supplements or nutraceuticals",
      "Marijuana dispensaries",
      "Marijuana-related products",
      "Accessories for tobacco and marijuana",
      "Alcohol"
    ]
  }
]