import React from "react";
import "./GenericFieldPanel.scss";
import Panel from "../../ui/panel/Panel";
import Text from "../../ui/text/Text";
import CircleIcon from "../../ui/circle-icon/CirlcleIcon";
import ElementModifiers from "../../ui/element-modifiers/ElementModifiers";

interface IGenericFieldPanel {
  title: string,
  subTitle?: string,
  shouldTranslateTitle?: boolean,
  shouldTranslateSubTitle?: boolean,
  addIcon?: {
    isChecked: boolean,
    onChange: (isChecked: boolean) => any
  }
  editIcon?: {
    onClick: () => void
  }
}

const GenericFieldPanel: React.FC<IGenericFieldPanel> = ({ title, subTitle, shouldTranslateTitle = true, shouldTranslateSubTitle = true, addIcon, editIcon }) => {
  return (
    <Panel className="generic-field-panel">
      <div>
        <Text 
          className={`generic-field-panel__title ${subTitle ? "generic-field-panel__title--margin" : "generic-field-panel__title--nomargin" }`} 
          content={title} 
          shouldTranslate={shouldTranslateTitle} 
          noMargin 
        />
        {subTitle && <Text content={subTitle} shouldTranslate={shouldTranslateSubTitle} noMargin />}
      </div>

      <div>
        {
          editIcon
            ? (
              <ElementModifiers onEditClick={editIcon.onClick} className="generic-field-panel__edit" />
            )
            : null
        }

        {
          addIcon
            ? (
              <CircleIcon
                icon={addIcon.isChecked ? "far fa-check" : "far fa-plus"}
                className={`generic-field-panel__add ${addIcon.isChecked ? "generic-field-panel__add--checked" : "generic-field-panel__add--unchecked"}`}
                onClick={() => addIcon.onChange(!addIcon.isChecked)}
              />
            )
            : null
        }
      </div>

    </Panel>
  )
}

export default GenericFieldPanel;