import React, { useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../../..";
import { IAddOnCostReducer } from "../../../reducers/add-ons/addOnCostReducer";
import { setAddOnsCostCurrentPage } from "../../../actions/addOnsActions";
import { IAuthReducerState } from "../../../reducers/authReducer";
import Pagination from "../../ui/pagination/Pagination";
import ProgressIndicator from "../../ui/progress-indicator/ProgressIndicator";
import { Currency, AddonPriceModel } from "../../../utils/grpc/generated/Billsby.Protos/core/private/addons/addons_pb";
import { PricingModelType } from "../../../models/Product";
import { paginateItems, getNumberOfDays } from "../../../utils/commonUtils";
import { ICreateAddOnsReducerState } from "../../../reducers/add-ons/create-addons/createAddOnsReducer";
import AddOnPriceModelPanel from "./AddOnPriceModelPanel";
import { AllowancePriceModel } from "../../../utils/grpc/generated/Billsby.Protos/core/private/allowances/allowances_pb";

interface IStepAddOnCostList {
  selectedCurrency: Currency;
  configurePriceModelCb: (cycle: AddonPriceModel | AllowancePriceModel) => void;
  pricingModelType: PricingModelType;
  planIds: Array<number>;
}

const StepAddOnCostList: React.FC<IStepAddOnCostList> = ({
  selectedCurrency,
  pricingModelType,
  configurePriceModelCb,
  planIds
}) => {
  const { currentCompanyDomain } = useSelector<AppState, IAuthReducerState>(state => state.auth)
  const addOnCostReducer = useSelector<AppState, IAddOnCostReducer>(state => state.addOnCostReducer)
  const createAddOnReducer = useSelector<AppState, ICreateAddOnsReducerState>(state => state.createAddOnsReducer)
  const { currencyPriceModels, currenciesOfPlans, currentPage } = addOnCostReducer;
  const { stageStatuses } = createAddOnReducer;
  const dispatch = useDispatch<Function>()

  const currencyIso3 = selectedCurrency.getIso3Code();

  /* check step2 "i'll attach this add-on to plans later" */
  const isAttachPlansLaterSelected = stageStatuses[1] && !planIds.length;
  const itemsPerPage = 10;

  const paginatedPriceModels = useMemo(() => {
    if (!currencyPriceModels || !currencyPriceModels[currencyIso3]) {
      return [];
    }

    const sortedPriceModels = currencyPriceModels[currencyIso3].sort((a, b) => {
      return getNumberOfDays(a) - getNumberOfDays(b)
    })

    return paginateItems(sortedPriceModels, currentPage);
  }, [currentPage, selectedCurrency, currencyPriceModels]);


  if (isAttachPlansLaterSelected) {
    return null;
  }

  if (!currencyPriceModels || (currencyPriceModels && !currencyPriceModels[currencyIso3])) {
    return <ProgressIndicator color="blue" coverage="normal" />;
  }

  return (
    <>
      {paginatedPriceModels.map((priceModel, id) => (
        <AddOnPriceModelPanel 
          key={id} 
          priceModel={priceModel} 
          configurePriceModelCb={configurePriceModelCb} 
          selectedCurrency={selectedCurrency} 
          pricingModelType={pricingModelType} />
      ))}

      <Pagination
        className="step-addon-cost__list-pagination"
        forcePage={currentPage - 1}
        pageCount={currencyPriceModels[currencyIso3].length / itemsPerPage}
        isVisible={currencyPriceModels[currencyIso3].length / itemsPerPage > 1}
        onPageChange={({ selected }) => dispatch(setAddOnsCostCurrentPage(selected + 1))}
      />
    </>
  );
};

export default StepAddOnCostList
