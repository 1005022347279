/* eslint-disable */
// source: customer.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = (function() {
  if (this) { return this; }
  if (typeof window !== 'undefined') { return window; }
  if (typeof global !== 'undefined') { return global; }
  if (typeof self !== 'undefined') { return self; }
  return Function('return this')();
}.call(null));

var google_protobuf_timestamp_pb = require('google-protobuf/google/protobuf/timestamp_pb.js');
goog.object.extend(proto, google_protobuf_timestamp_pb);
goog.exportSymbol('proto.billing.private.customer.AddCustomerNoteRequest', null, global);
goog.exportSymbol('proto.billing.private.customer.AddCustomerNoteResponse', null, global);
goog.exportSymbol('proto.billing.private.customer.AdminUpdateCustomerCardDetailsScaRequest', null, global);
goog.exportSymbol('proto.billing.private.customer.AdminUpdateCustomerCardDetailsScaResponse', null, global);
goog.exportSymbol('proto.billing.private.customer.DeleteCustomerNoteRequest', null, global);
goog.exportSymbol('proto.billing.private.customer.DeleteCustomerNoteResponse', null, global);
goog.exportSymbol('proto.billing.private.customer.GetCustomerLanguageRequest', null, global);
goog.exportSymbol('proto.billing.private.customer.GetCustomerLanguageResponse', null, global);
goog.exportSymbol('proto.billing.private.customer.GetCustomerNotesRequest', null, global);
goog.exportSymbol('proto.billing.private.customer.GetCustomerNotesResponse', null, global);
goog.exportSymbol('proto.billing.private.customer.GetCustomerNotesResponse.CustomerNote', null, global);
goog.exportSymbol('proto.billing.private.customer.GetCustomerPaymentDetailsRequest', null, global);
goog.exportSymbol('proto.billing.private.customer.GetCustomerPaymentDetailsResponse', null, global);
goog.exportSymbol('proto.billing.private.customer.GetViesVatValidationsRequest', null, global);
goog.exportSymbol('proto.billing.private.customer.GetViesVatValidationsResponse', null, global);
goog.exportSymbol('proto.billing.private.customer.UpdateCustomerLanguageRequest', null, global);
goog.exportSymbol('proto.billing.private.customer.UpdateCustomerLanguageResponse', null, global);
goog.exportSymbol('proto.billing.private.customer.UpdateCustomerNoteRequest', null, global);
goog.exportSymbol('proto.billing.private.customer.UpdateCustomerNoteResponse', null, global);
goog.exportSymbol('proto.billing.private.customer.UpdateCustomerPaymentDetailsScaRequest', null, global);
goog.exportSymbol('proto.billing.private.customer.UpdateCustomerPaymentDetailsScaResponse', null, global);
goog.exportSymbol('proto.billing.private.customer.ViesVatValidation', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.billing.private.customer.GetCustomerPaymentDetailsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.billing.private.customer.GetCustomerPaymentDetailsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.billing.private.customer.GetCustomerPaymentDetailsRequest.displayName = 'proto.billing.private.customer.GetCustomerPaymentDetailsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.billing.private.customer.GetCustomerPaymentDetailsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.billing.private.customer.GetCustomerPaymentDetailsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.billing.private.customer.GetCustomerPaymentDetailsResponse.displayName = 'proto.billing.private.customer.GetCustomerPaymentDetailsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.billing.private.customer.GetViesVatValidationsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.billing.private.customer.GetViesVatValidationsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.billing.private.customer.GetViesVatValidationsRequest.displayName = 'proto.billing.private.customer.GetViesVatValidationsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.billing.private.customer.GetViesVatValidationsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.billing.private.customer.GetViesVatValidationsResponse.repeatedFields_, null);
};
goog.inherits(proto.billing.private.customer.GetViesVatValidationsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.billing.private.customer.GetViesVatValidationsResponse.displayName = 'proto.billing.private.customer.GetViesVatValidationsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.billing.private.customer.ViesVatValidation = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.billing.private.customer.ViesVatValidation, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.billing.private.customer.ViesVatValidation.displayName = 'proto.billing.private.customer.ViesVatValidation';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.billing.private.customer.UpdateCustomerPaymentDetailsScaRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.billing.private.customer.UpdateCustomerPaymentDetailsScaRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.billing.private.customer.UpdateCustomerPaymentDetailsScaRequest.displayName = 'proto.billing.private.customer.UpdateCustomerPaymentDetailsScaRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.billing.private.customer.UpdateCustomerPaymentDetailsScaResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.billing.private.customer.UpdateCustomerPaymentDetailsScaResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.billing.private.customer.UpdateCustomerPaymentDetailsScaResponse.displayName = 'proto.billing.private.customer.UpdateCustomerPaymentDetailsScaResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.billing.private.customer.AdminUpdateCustomerCardDetailsScaRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.billing.private.customer.AdminUpdateCustomerCardDetailsScaRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.billing.private.customer.AdminUpdateCustomerCardDetailsScaRequest.displayName = 'proto.billing.private.customer.AdminUpdateCustomerCardDetailsScaRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.billing.private.customer.AdminUpdateCustomerCardDetailsScaResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.billing.private.customer.AdminUpdateCustomerCardDetailsScaResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.billing.private.customer.AdminUpdateCustomerCardDetailsScaResponse.displayName = 'proto.billing.private.customer.AdminUpdateCustomerCardDetailsScaResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.billing.private.customer.AddCustomerNoteRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.billing.private.customer.AddCustomerNoteRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.billing.private.customer.AddCustomerNoteRequest.displayName = 'proto.billing.private.customer.AddCustomerNoteRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.billing.private.customer.AddCustomerNoteResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.billing.private.customer.AddCustomerNoteResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.billing.private.customer.AddCustomerNoteResponse.displayName = 'proto.billing.private.customer.AddCustomerNoteResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.billing.private.customer.UpdateCustomerNoteRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.billing.private.customer.UpdateCustomerNoteRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.billing.private.customer.UpdateCustomerNoteRequest.displayName = 'proto.billing.private.customer.UpdateCustomerNoteRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.billing.private.customer.UpdateCustomerNoteResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.billing.private.customer.UpdateCustomerNoteResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.billing.private.customer.UpdateCustomerNoteResponse.displayName = 'proto.billing.private.customer.UpdateCustomerNoteResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.billing.private.customer.DeleteCustomerNoteRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.billing.private.customer.DeleteCustomerNoteRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.billing.private.customer.DeleteCustomerNoteRequest.displayName = 'proto.billing.private.customer.DeleteCustomerNoteRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.billing.private.customer.DeleteCustomerNoteResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.billing.private.customer.DeleteCustomerNoteResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.billing.private.customer.DeleteCustomerNoteResponse.displayName = 'proto.billing.private.customer.DeleteCustomerNoteResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.billing.private.customer.GetCustomerNotesRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.billing.private.customer.GetCustomerNotesRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.billing.private.customer.GetCustomerNotesRequest.displayName = 'proto.billing.private.customer.GetCustomerNotesRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.billing.private.customer.GetCustomerNotesResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.billing.private.customer.GetCustomerNotesResponse.repeatedFields_, null);
};
goog.inherits(proto.billing.private.customer.GetCustomerNotesResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.billing.private.customer.GetCustomerNotesResponse.displayName = 'proto.billing.private.customer.GetCustomerNotesResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.billing.private.customer.GetCustomerNotesResponse.CustomerNote = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.billing.private.customer.GetCustomerNotesResponse.CustomerNote, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.billing.private.customer.GetCustomerNotesResponse.CustomerNote.displayName = 'proto.billing.private.customer.GetCustomerNotesResponse.CustomerNote';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.billing.private.customer.GetCustomerLanguageRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.billing.private.customer.GetCustomerLanguageRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.billing.private.customer.GetCustomerLanguageRequest.displayName = 'proto.billing.private.customer.GetCustomerLanguageRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.billing.private.customer.GetCustomerLanguageResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.billing.private.customer.GetCustomerLanguageResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.billing.private.customer.GetCustomerLanguageResponse.displayName = 'proto.billing.private.customer.GetCustomerLanguageResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.billing.private.customer.UpdateCustomerLanguageRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.billing.private.customer.UpdateCustomerLanguageRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.billing.private.customer.UpdateCustomerLanguageRequest.displayName = 'proto.billing.private.customer.UpdateCustomerLanguageRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.billing.private.customer.UpdateCustomerLanguageResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.billing.private.customer.UpdateCustomerLanguageResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.billing.private.customer.UpdateCustomerLanguageResponse.displayName = 'proto.billing.private.customer.UpdateCustomerLanguageResponse';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.billing.private.customer.GetCustomerPaymentDetailsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.billing.private.customer.GetCustomerPaymentDetailsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.billing.private.customer.GetCustomerPaymentDetailsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing.private.customer.GetCustomerPaymentDetailsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    companyId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    customerUniqueId: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.billing.private.customer.GetCustomerPaymentDetailsRequest}
 */
proto.billing.private.customer.GetCustomerPaymentDetailsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.billing.private.customer.GetCustomerPaymentDetailsRequest;
  return proto.billing.private.customer.GetCustomerPaymentDetailsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.billing.private.customer.GetCustomerPaymentDetailsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.billing.private.customer.GetCustomerPaymentDetailsRequest}
 */
proto.billing.private.customer.GetCustomerPaymentDetailsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setCompanyId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setCustomerUniqueId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.billing.private.customer.GetCustomerPaymentDetailsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.billing.private.customer.GetCustomerPaymentDetailsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.billing.private.customer.GetCustomerPaymentDetailsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing.private.customer.GetCustomerPaymentDetailsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCompanyId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getCustomerUniqueId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional int32 company_id = 1;
 * @return {number}
 */
proto.billing.private.customer.GetCustomerPaymentDetailsRequest.prototype.getCompanyId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.billing.private.customer.GetCustomerPaymentDetailsRequest} returns this
 */
proto.billing.private.customer.GetCustomerPaymentDetailsRequest.prototype.setCompanyId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string customer_unique_id = 2;
 * @return {string}
 */
proto.billing.private.customer.GetCustomerPaymentDetailsRequest.prototype.getCustomerUniqueId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.billing.private.customer.GetCustomerPaymentDetailsRequest} returns this
 */
proto.billing.private.customer.GetCustomerPaymentDetailsRequest.prototype.setCustomerUniqueId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.billing.private.customer.GetCustomerPaymentDetailsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.billing.private.customer.GetCustomerPaymentDetailsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.billing.private.customer.GetCustomerPaymentDetailsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing.private.customer.GetCustomerPaymentDetailsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    companyId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    customerUniqueId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    paymentMethodType: jspb.Message.getFieldWithDefault(msg, 3, ""),
    cardType: jspb.Message.getFieldWithDefault(msg, 4, ""),
    lastFourDigits: jspb.Message.getFieldWithDefault(msg, 5, ""),
    expiryMonth: jspb.Message.getFieldWithDefault(msg, 6, 0),
    expiryYear: jspb.Message.getFieldWithDefault(msg, 7, 0),
    fullName: jspb.Message.getFieldWithDefault(msg, 8, ""),
    bankName: jspb.Message.getFieldWithDefault(msg, 9, ""),
    createdOn: (f = msg.getCreatedOn()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.billing.private.customer.GetCustomerPaymentDetailsResponse}
 */
proto.billing.private.customer.GetCustomerPaymentDetailsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.billing.private.customer.GetCustomerPaymentDetailsResponse;
  return proto.billing.private.customer.GetCustomerPaymentDetailsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.billing.private.customer.GetCustomerPaymentDetailsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.billing.private.customer.GetCustomerPaymentDetailsResponse}
 */
proto.billing.private.customer.GetCustomerPaymentDetailsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setCompanyId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setCustomerUniqueId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setPaymentMethodType(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setCardType(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setLastFourDigits(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setExpiryMonth(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setExpiryYear(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setFullName(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setBankName(value);
      break;
    case 10:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setCreatedOn(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.billing.private.customer.GetCustomerPaymentDetailsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.billing.private.customer.GetCustomerPaymentDetailsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.billing.private.customer.GetCustomerPaymentDetailsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing.private.customer.GetCustomerPaymentDetailsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCompanyId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getCustomerUniqueId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getPaymentMethodType();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getCardType();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getLastFourDigits();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getExpiryMonth();
  if (f !== 0) {
    writer.writeInt32(
      6,
      f
    );
  }
  f = message.getExpiryYear();
  if (f !== 0) {
    writer.writeInt32(
      7,
      f
    );
  }
  f = message.getFullName();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getBankName();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getCreatedOn();
  if (f != null) {
    writer.writeMessage(
      10,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
};


/**
 * optional int32 company_id = 1;
 * @return {number}
 */
proto.billing.private.customer.GetCustomerPaymentDetailsResponse.prototype.getCompanyId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.billing.private.customer.GetCustomerPaymentDetailsResponse} returns this
 */
proto.billing.private.customer.GetCustomerPaymentDetailsResponse.prototype.setCompanyId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string customer_unique_id = 2;
 * @return {string}
 */
proto.billing.private.customer.GetCustomerPaymentDetailsResponse.prototype.getCustomerUniqueId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.billing.private.customer.GetCustomerPaymentDetailsResponse} returns this
 */
proto.billing.private.customer.GetCustomerPaymentDetailsResponse.prototype.setCustomerUniqueId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string payment_method_type = 3;
 * @return {string}
 */
proto.billing.private.customer.GetCustomerPaymentDetailsResponse.prototype.getPaymentMethodType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.billing.private.customer.GetCustomerPaymentDetailsResponse} returns this
 */
proto.billing.private.customer.GetCustomerPaymentDetailsResponse.prototype.setPaymentMethodType = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string card_type = 4;
 * @return {string}
 */
proto.billing.private.customer.GetCustomerPaymentDetailsResponse.prototype.getCardType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.billing.private.customer.GetCustomerPaymentDetailsResponse} returns this
 */
proto.billing.private.customer.GetCustomerPaymentDetailsResponse.prototype.setCardType = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string last_four_digits = 5;
 * @return {string}
 */
proto.billing.private.customer.GetCustomerPaymentDetailsResponse.prototype.getLastFourDigits = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.billing.private.customer.GetCustomerPaymentDetailsResponse} returns this
 */
proto.billing.private.customer.GetCustomerPaymentDetailsResponse.prototype.setLastFourDigits = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional int32 expiry_month = 6;
 * @return {number}
 */
proto.billing.private.customer.GetCustomerPaymentDetailsResponse.prototype.getExpiryMonth = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.billing.private.customer.GetCustomerPaymentDetailsResponse} returns this
 */
proto.billing.private.customer.GetCustomerPaymentDetailsResponse.prototype.setExpiryMonth = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * optional int32 expiry_year = 7;
 * @return {number}
 */
proto.billing.private.customer.GetCustomerPaymentDetailsResponse.prototype.getExpiryYear = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.billing.private.customer.GetCustomerPaymentDetailsResponse} returns this
 */
proto.billing.private.customer.GetCustomerPaymentDetailsResponse.prototype.setExpiryYear = function(value) {
  return jspb.Message.setProto3IntField(this, 7, value);
};


/**
 * optional string full_name = 8;
 * @return {string}
 */
proto.billing.private.customer.GetCustomerPaymentDetailsResponse.prototype.getFullName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.billing.private.customer.GetCustomerPaymentDetailsResponse} returns this
 */
proto.billing.private.customer.GetCustomerPaymentDetailsResponse.prototype.setFullName = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional string bank_name = 9;
 * @return {string}
 */
proto.billing.private.customer.GetCustomerPaymentDetailsResponse.prototype.getBankName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.billing.private.customer.GetCustomerPaymentDetailsResponse} returns this
 */
proto.billing.private.customer.GetCustomerPaymentDetailsResponse.prototype.setBankName = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional google.protobuf.Timestamp created_on = 10;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.billing.private.customer.GetCustomerPaymentDetailsResponse.prototype.getCreatedOn = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 10));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.billing.private.customer.GetCustomerPaymentDetailsResponse} returns this
*/
proto.billing.private.customer.GetCustomerPaymentDetailsResponse.prototype.setCreatedOn = function(value) {
  return jspb.Message.setWrapperField(this, 10, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.billing.private.customer.GetCustomerPaymentDetailsResponse} returns this
 */
proto.billing.private.customer.GetCustomerPaymentDetailsResponse.prototype.clearCreatedOn = function() {
  return this.setCreatedOn(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.billing.private.customer.GetCustomerPaymentDetailsResponse.prototype.hasCreatedOn = function() {
  return jspb.Message.getField(this, 10) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.billing.private.customer.GetViesVatValidationsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.billing.private.customer.GetViesVatValidationsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.billing.private.customer.GetViesVatValidationsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing.private.customer.GetViesVatValidationsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    companyDomain: jspb.Message.getFieldWithDefault(msg, 1, ""),
    customerUniqueId: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.billing.private.customer.GetViesVatValidationsRequest}
 */
proto.billing.private.customer.GetViesVatValidationsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.billing.private.customer.GetViesVatValidationsRequest;
  return proto.billing.private.customer.GetViesVatValidationsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.billing.private.customer.GetViesVatValidationsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.billing.private.customer.GetViesVatValidationsRequest}
 */
proto.billing.private.customer.GetViesVatValidationsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setCompanyDomain(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setCustomerUniqueId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.billing.private.customer.GetViesVatValidationsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.billing.private.customer.GetViesVatValidationsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.billing.private.customer.GetViesVatValidationsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing.private.customer.GetViesVatValidationsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCompanyDomain();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getCustomerUniqueId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string company_domain = 1;
 * @return {string}
 */
proto.billing.private.customer.GetViesVatValidationsRequest.prototype.getCompanyDomain = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.billing.private.customer.GetViesVatValidationsRequest} returns this
 */
proto.billing.private.customer.GetViesVatValidationsRequest.prototype.setCompanyDomain = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string customer_unique_id = 2;
 * @return {string}
 */
proto.billing.private.customer.GetViesVatValidationsRequest.prototype.getCustomerUniqueId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.billing.private.customer.GetViesVatValidationsRequest} returns this
 */
proto.billing.private.customer.GetViesVatValidationsRequest.prototype.setCustomerUniqueId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.billing.private.customer.GetViesVatValidationsResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.billing.private.customer.GetViesVatValidationsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.billing.private.customer.GetViesVatValidationsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.billing.private.customer.GetViesVatValidationsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing.private.customer.GetViesVatValidationsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    resultsList: jspb.Message.toObjectList(msg.getResultsList(),
    proto.billing.private.customer.ViesVatValidation.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.billing.private.customer.GetViesVatValidationsResponse}
 */
proto.billing.private.customer.GetViesVatValidationsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.billing.private.customer.GetViesVatValidationsResponse;
  return proto.billing.private.customer.GetViesVatValidationsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.billing.private.customer.GetViesVatValidationsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.billing.private.customer.GetViesVatValidationsResponse}
 */
proto.billing.private.customer.GetViesVatValidationsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.billing.private.customer.ViesVatValidation;
      reader.readMessage(value,proto.billing.private.customer.ViesVatValidation.deserializeBinaryFromReader);
      msg.addResults(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.billing.private.customer.GetViesVatValidationsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.billing.private.customer.GetViesVatValidationsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.billing.private.customer.GetViesVatValidationsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing.private.customer.GetViesVatValidationsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getResultsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.billing.private.customer.ViesVatValidation.serializeBinaryToWriter
    );
  }
};


/**
 * repeated ViesVatValidation results = 1;
 * @return {!Array<!proto.billing.private.customer.ViesVatValidation>}
 */
proto.billing.private.customer.GetViesVatValidationsResponse.prototype.getResultsList = function() {
  return /** @type{!Array<!proto.billing.private.customer.ViesVatValidation>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.billing.private.customer.ViesVatValidation, 1));
};


/**
 * @param {!Array<!proto.billing.private.customer.ViesVatValidation>} value
 * @return {!proto.billing.private.customer.GetViesVatValidationsResponse} returns this
*/
proto.billing.private.customer.GetViesVatValidationsResponse.prototype.setResultsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.billing.private.customer.ViesVatValidation=} opt_value
 * @param {number=} opt_index
 * @return {!proto.billing.private.customer.ViesVatValidation}
 */
proto.billing.private.customer.GetViesVatValidationsResponse.prototype.addResults = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.billing.private.customer.ViesVatValidation, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.billing.private.customer.GetViesVatValidationsResponse} returns this
 */
proto.billing.private.customer.GetViesVatValidationsResponse.prototype.clearResultsList = function() {
  return this.setResultsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.billing.private.customer.ViesVatValidation.prototype.toObject = function(opt_includeInstance) {
  return proto.billing.private.customer.ViesVatValidation.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.billing.private.customer.ViesVatValidation} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing.private.customer.ViesVatValidation.toObject = function(includeInstance, msg) {
  var f, obj = {
    number: jspb.Message.getFieldWithDefault(msg, 1, ""),
    dateValidated: (f = msg.getDateValidated()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.billing.private.customer.ViesVatValidation}
 */
proto.billing.private.customer.ViesVatValidation.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.billing.private.customer.ViesVatValidation;
  return proto.billing.private.customer.ViesVatValidation.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.billing.private.customer.ViesVatValidation} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.billing.private.customer.ViesVatValidation}
 */
proto.billing.private.customer.ViesVatValidation.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setNumber(value);
      break;
    case 2:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setDateValidated(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.billing.private.customer.ViesVatValidation.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.billing.private.customer.ViesVatValidation.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.billing.private.customer.ViesVatValidation} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing.private.customer.ViesVatValidation.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getNumber();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getDateValidated();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
};


/**
 * optional string number = 1;
 * @return {string}
 */
proto.billing.private.customer.ViesVatValidation.prototype.getNumber = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.billing.private.customer.ViesVatValidation} returns this
 */
proto.billing.private.customer.ViesVatValidation.prototype.setNumber = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional google.protobuf.Timestamp date_validated = 2;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.billing.private.customer.ViesVatValidation.prototype.getDateValidated = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 2));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.billing.private.customer.ViesVatValidation} returns this
*/
proto.billing.private.customer.ViesVatValidation.prototype.setDateValidated = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.billing.private.customer.ViesVatValidation} returns this
 */
proto.billing.private.customer.ViesVatValidation.prototype.clearDateValidated = function() {
  return this.setDateValidated(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.billing.private.customer.ViesVatValidation.prototype.hasDateValidated = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.billing.private.customer.UpdateCustomerPaymentDetailsScaRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.billing.private.customer.UpdateCustomerPaymentDetailsScaRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.billing.private.customer.UpdateCustomerPaymentDetailsScaRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing.private.customer.UpdateCustomerPaymentDetailsScaRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    customerUniqueId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    companyId: jspb.Message.getFieldWithDefault(msg, 2, 0),
    cardType: jspb.Message.getFieldWithDefault(msg, 3, ""),
    cardLastFourDigits: jspb.Message.getFieldWithDefault(msg, 4, ""),
    expiryMonth: jspb.Message.getFieldWithDefault(msg, 5, 0),
    expiryYear: jspb.Message.getFieldWithDefault(msg, 6, 0),
    paymentCardToken: jspb.Message.getFieldWithDefault(msg, 7, ""),
    fullName: jspb.Message.getFieldWithDefault(msg, 8, ""),
    ipAddress: jspb.Message.getFieldWithDefault(msg, 9, ""),
    transactionToken: jspb.Message.getFieldWithDefault(msg, 10, ""),
    browserInfo: jspb.Message.getFieldWithDefault(msg, 11, ""),
    redirectUrl: jspb.Message.getFieldWithDefault(msg, 12, ""),
    callbackUrl: jspb.Message.getFieldWithDefault(msg, 13, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.billing.private.customer.UpdateCustomerPaymentDetailsScaRequest}
 */
proto.billing.private.customer.UpdateCustomerPaymentDetailsScaRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.billing.private.customer.UpdateCustomerPaymentDetailsScaRequest;
  return proto.billing.private.customer.UpdateCustomerPaymentDetailsScaRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.billing.private.customer.UpdateCustomerPaymentDetailsScaRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.billing.private.customer.UpdateCustomerPaymentDetailsScaRequest}
 */
proto.billing.private.customer.UpdateCustomerPaymentDetailsScaRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setCustomerUniqueId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setCompanyId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setCardType(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setCardLastFourDigits(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setExpiryMonth(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setExpiryYear(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setPaymentCardToken(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setFullName(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setIpAddress(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setTransactionToken(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.setBrowserInfo(value);
      break;
    case 12:
      var value = /** @type {string} */ (reader.readString());
      msg.setRedirectUrl(value);
      break;
    case 13:
      var value = /** @type {string} */ (reader.readString());
      msg.setCallbackUrl(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.billing.private.customer.UpdateCustomerPaymentDetailsScaRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.billing.private.customer.UpdateCustomerPaymentDetailsScaRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.billing.private.customer.UpdateCustomerPaymentDetailsScaRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing.private.customer.UpdateCustomerPaymentDetailsScaRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCustomerUniqueId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getCompanyId();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getCardType();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getCardLastFourDigits();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getExpiryMonth();
  if (f !== 0) {
    writer.writeInt32(
      5,
      f
    );
  }
  f = message.getExpiryYear();
  if (f !== 0) {
    writer.writeInt32(
      6,
      f
    );
  }
  f = message.getPaymentCardToken();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getFullName();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getIpAddress();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getTransactionToken();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getBrowserInfo();
  if (f.length > 0) {
    writer.writeString(
      11,
      f
    );
  }
  f = message.getRedirectUrl();
  if (f.length > 0) {
    writer.writeString(
      12,
      f
    );
  }
  f = message.getCallbackUrl();
  if (f.length > 0) {
    writer.writeString(
      13,
      f
    );
  }
};


/**
 * optional string customer_unique_id = 1;
 * @return {string}
 */
proto.billing.private.customer.UpdateCustomerPaymentDetailsScaRequest.prototype.getCustomerUniqueId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.billing.private.customer.UpdateCustomerPaymentDetailsScaRequest} returns this
 */
proto.billing.private.customer.UpdateCustomerPaymentDetailsScaRequest.prototype.setCustomerUniqueId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int32 company_id = 2;
 * @return {number}
 */
proto.billing.private.customer.UpdateCustomerPaymentDetailsScaRequest.prototype.getCompanyId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.billing.private.customer.UpdateCustomerPaymentDetailsScaRequest} returns this
 */
proto.billing.private.customer.UpdateCustomerPaymentDetailsScaRequest.prototype.setCompanyId = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional string card_type = 3;
 * @return {string}
 */
proto.billing.private.customer.UpdateCustomerPaymentDetailsScaRequest.prototype.getCardType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.billing.private.customer.UpdateCustomerPaymentDetailsScaRequest} returns this
 */
proto.billing.private.customer.UpdateCustomerPaymentDetailsScaRequest.prototype.setCardType = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string card_last_four_digits = 4;
 * @return {string}
 */
proto.billing.private.customer.UpdateCustomerPaymentDetailsScaRequest.prototype.getCardLastFourDigits = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.billing.private.customer.UpdateCustomerPaymentDetailsScaRequest} returns this
 */
proto.billing.private.customer.UpdateCustomerPaymentDetailsScaRequest.prototype.setCardLastFourDigits = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional int32 expiry_month = 5;
 * @return {number}
 */
proto.billing.private.customer.UpdateCustomerPaymentDetailsScaRequest.prototype.getExpiryMonth = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.billing.private.customer.UpdateCustomerPaymentDetailsScaRequest} returns this
 */
proto.billing.private.customer.UpdateCustomerPaymentDetailsScaRequest.prototype.setExpiryMonth = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional int32 expiry_year = 6;
 * @return {number}
 */
proto.billing.private.customer.UpdateCustomerPaymentDetailsScaRequest.prototype.getExpiryYear = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.billing.private.customer.UpdateCustomerPaymentDetailsScaRequest} returns this
 */
proto.billing.private.customer.UpdateCustomerPaymentDetailsScaRequest.prototype.setExpiryYear = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * optional string payment_card_token = 7;
 * @return {string}
 */
proto.billing.private.customer.UpdateCustomerPaymentDetailsScaRequest.prototype.getPaymentCardToken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.billing.private.customer.UpdateCustomerPaymentDetailsScaRequest} returns this
 */
proto.billing.private.customer.UpdateCustomerPaymentDetailsScaRequest.prototype.setPaymentCardToken = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string full_name = 8;
 * @return {string}
 */
proto.billing.private.customer.UpdateCustomerPaymentDetailsScaRequest.prototype.getFullName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.billing.private.customer.UpdateCustomerPaymentDetailsScaRequest} returns this
 */
proto.billing.private.customer.UpdateCustomerPaymentDetailsScaRequest.prototype.setFullName = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional string ip_address = 9;
 * @return {string}
 */
proto.billing.private.customer.UpdateCustomerPaymentDetailsScaRequest.prototype.getIpAddress = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.billing.private.customer.UpdateCustomerPaymentDetailsScaRequest} returns this
 */
proto.billing.private.customer.UpdateCustomerPaymentDetailsScaRequest.prototype.setIpAddress = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional string transaction_token = 10;
 * @return {string}
 */
proto.billing.private.customer.UpdateCustomerPaymentDetailsScaRequest.prototype.getTransactionToken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.billing.private.customer.UpdateCustomerPaymentDetailsScaRequest} returns this
 */
proto.billing.private.customer.UpdateCustomerPaymentDetailsScaRequest.prototype.setTransactionToken = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * optional string browser_info = 11;
 * @return {string}
 */
proto.billing.private.customer.UpdateCustomerPaymentDetailsScaRequest.prototype.getBrowserInfo = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};


/**
 * @param {string} value
 * @return {!proto.billing.private.customer.UpdateCustomerPaymentDetailsScaRequest} returns this
 */
proto.billing.private.customer.UpdateCustomerPaymentDetailsScaRequest.prototype.setBrowserInfo = function(value) {
  return jspb.Message.setProto3StringField(this, 11, value);
};


/**
 * optional string redirect_url = 12;
 * @return {string}
 */
proto.billing.private.customer.UpdateCustomerPaymentDetailsScaRequest.prototype.getRedirectUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 12, ""));
};


/**
 * @param {string} value
 * @return {!proto.billing.private.customer.UpdateCustomerPaymentDetailsScaRequest} returns this
 */
proto.billing.private.customer.UpdateCustomerPaymentDetailsScaRequest.prototype.setRedirectUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 12, value);
};


/**
 * optional string callback_url = 13;
 * @return {string}
 */
proto.billing.private.customer.UpdateCustomerPaymentDetailsScaRequest.prototype.getCallbackUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 13, ""));
};


/**
 * @param {string} value
 * @return {!proto.billing.private.customer.UpdateCustomerPaymentDetailsScaRequest} returns this
 */
proto.billing.private.customer.UpdateCustomerPaymentDetailsScaRequest.prototype.setCallbackUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 13, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.billing.private.customer.UpdateCustomerPaymentDetailsScaResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.billing.private.customer.UpdateCustomerPaymentDetailsScaResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.billing.private.customer.UpdateCustomerPaymentDetailsScaResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing.private.customer.UpdateCustomerPaymentDetailsScaResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    status: jspb.Message.getFieldWithDefault(msg, 1, ""),
    transactionToken: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.billing.private.customer.UpdateCustomerPaymentDetailsScaResponse}
 */
proto.billing.private.customer.UpdateCustomerPaymentDetailsScaResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.billing.private.customer.UpdateCustomerPaymentDetailsScaResponse;
  return proto.billing.private.customer.UpdateCustomerPaymentDetailsScaResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.billing.private.customer.UpdateCustomerPaymentDetailsScaResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.billing.private.customer.UpdateCustomerPaymentDetailsScaResponse}
 */
proto.billing.private.customer.UpdateCustomerPaymentDetailsScaResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setStatus(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setTransactionToken(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.billing.private.customer.UpdateCustomerPaymentDetailsScaResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.billing.private.customer.UpdateCustomerPaymentDetailsScaResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.billing.private.customer.UpdateCustomerPaymentDetailsScaResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing.private.customer.UpdateCustomerPaymentDetailsScaResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStatus();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getTransactionToken();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string status = 1;
 * @return {string}
 */
proto.billing.private.customer.UpdateCustomerPaymentDetailsScaResponse.prototype.getStatus = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.billing.private.customer.UpdateCustomerPaymentDetailsScaResponse} returns this
 */
proto.billing.private.customer.UpdateCustomerPaymentDetailsScaResponse.prototype.setStatus = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string transaction_token = 2;
 * @return {string}
 */
proto.billing.private.customer.UpdateCustomerPaymentDetailsScaResponse.prototype.getTransactionToken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.billing.private.customer.UpdateCustomerPaymentDetailsScaResponse} returns this
 */
proto.billing.private.customer.UpdateCustomerPaymentDetailsScaResponse.prototype.setTransactionToken = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.billing.private.customer.AdminUpdateCustomerCardDetailsScaRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.billing.private.customer.AdminUpdateCustomerCardDetailsScaRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.billing.private.customer.AdminUpdateCustomerCardDetailsScaRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing.private.customer.AdminUpdateCustomerCardDetailsScaRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    customerUniqueId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    companyId: jspb.Message.getFieldWithDefault(msg, 2, 0),
    fullName: jspb.Message.getFieldWithDefault(msg, 3, ""),
    paymentCardToken: jspb.Message.getFieldWithDefault(msg, 4, ""),
    expiryMonth: jspb.Message.getFieldWithDefault(msg, 5, 0),
    expiryYear: jspb.Message.getFieldWithDefault(msg, 6, 0),
    cardType: jspb.Message.getFieldWithDefault(msg, 7, ""),
    cardLastFourDigits: jspb.Message.getFieldWithDefault(msg, 8, ""),
    ipAddress: jspb.Message.getFieldWithDefault(msg, 9, ""),
    transactionToken: jspb.Message.getFieldWithDefault(msg, 10, ""),
    browserInfo: jspb.Message.getFieldWithDefault(msg, 11, ""),
    redirectUrl: jspb.Message.getFieldWithDefault(msg, 12, ""),
    callbackUrl: jspb.Message.getFieldWithDefault(msg, 13, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.billing.private.customer.AdminUpdateCustomerCardDetailsScaRequest}
 */
proto.billing.private.customer.AdminUpdateCustomerCardDetailsScaRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.billing.private.customer.AdminUpdateCustomerCardDetailsScaRequest;
  return proto.billing.private.customer.AdminUpdateCustomerCardDetailsScaRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.billing.private.customer.AdminUpdateCustomerCardDetailsScaRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.billing.private.customer.AdminUpdateCustomerCardDetailsScaRequest}
 */
proto.billing.private.customer.AdminUpdateCustomerCardDetailsScaRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setCustomerUniqueId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setCompanyId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setFullName(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setPaymentCardToken(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setExpiryMonth(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setExpiryYear(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setCardType(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setCardLastFourDigits(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setIpAddress(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setTransactionToken(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.setBrowserInfo(value);
      break;
    case 12:
      var value = /** @type {string} */ (reader.readString());
      msg.setRedirectUrl(value);
      break;
    case 13:
      var value = /** @type {string} */ (reader.readString());
      msg.setCallbackUrl(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.billing.private.customer.AdminUpdateCustomerCardDetailsScaRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.billing.private.customer.AdminUpdateCustomerCardDetailsScaRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.billing.private.customer.AdminUpdateCustomerCardDetailsScaRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing.private.customer.AdminUpdateCustomerCardDetailsScaRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCustomerUniqueId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getCompanyId();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getFullName();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getPaymentCardToken();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getExpiryMonth();
  if (f !== 0) {
    writer.writeInt32(
      5,
      f
    );
  }
  f = message.getExpiryYear();
  if (f !== 0) {
    writer.writeInt32(
      6,
      f
    );
  }
  f = message.getCardType();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getCardLastFourDigits();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getIpAddress();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getTransactionToken();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getBrowserInfo();
  if (f.length > 0) {
    writer.writeString(
      11,
      f
    );
  }
  f = message.getRedirectUrl();
  if (f.length > 0) {
    writer.writeString(
      12,
      f
    );
  }
  f = message.getCallbackUrl();
  if (f.length > 0) {
    writer.writeString(
      13,
      f
    );
  }
};


/**
 * optional string customer_unique_id = 1;
 * @return {string}
 */
proto.billing.private.customer.AdminUpdateCustomerCardDetailsScaRequest.prototype.getCustomerUniqueId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.billing.private.customer.AdminUpdateCustomerCardDetailsScaRequest} returns this
 */
proto.billing.private.customer.AdminUpdateCustomerCardDetailsScaRequest.prototype.setCustomerUniqueId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int32 company_id = 2;
 * @return {number}
 */
proto.billing.private.customer.AdminUpdateCustomerCardDetailsScaRequest.prototype.getCompanyId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.billing.private.customer.AdminUpdateCustomerCardDetailsScaRequest} returns this
 */
proto.billing.private.customer.AdminUpdateCustomerCardDetailsScaRequest.prototype.setCompanyId = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional string full_name = 3;
 * @return {string}
 */
proto.billing.private.customer.AdminUpdateCustomerCardDetailsScaRequest.prototype.getFullName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.billing.private.customer.AdminUpdateCustomerCardDetailsScaRequest} returns this
 */
proto.billing.private.customer.AdminUpdateCustomerCardDetailsScaRequest.prototype.setFullName = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string payment_card_token = 4;
 * @return {string}
 */
proto.billing.private.customer.AdminUpdateCustomerCardDetailsScaRequest.prototype.getPaymentCardToken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.billing.private.customer.AdminUpdateCustomerCardDetailsScaRequest} returns this
 */
proto.billing.private.customer.AdminUpdateCustomerCardDetailsScaRequest.prototype.setPaymentCardToken = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional int32 expiry_month = 5;
 * @return {number}
 */
proto.billing.private.customer.AdminUpdateCustomerCardDetailsScaRequest.prototype.getExpiryMonth = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.billing.private.customer.AdminUpdateCustomerCardDetailsScaRequest} returns this
 */
proto.billing.private.customer.AdminUpdateCustomerCardDetailsScaRequest.prototype.setExpiryMonth = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional int32 expiry_year = 6;
 * @return {number}
 */
proto.billing.private.customer.AdminUpdateCustomerCardDetailsScaRequest.prototype.getExpiryYear = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.billing.private.customer.AdminUpdateCustomerCardDetailsScaRequest} returns this
 */
proto.billing.private.customer.AdminUpdateCustomerCardDetailsScaRequest.prototype.setExpiryYear = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * optional string card_type = 7;
 * @return {string}
 */
proto.billing.private.customer.AdminUpdateCustomerCardDetailsScaRequest.prototype.getCardType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.billing.private.customer.AdminUpdateCustomerCardDetailsScaRequest} returns this
 */
proto.billing.private.customer.AdminUpdateCustomerCardDetailsScaRequest.prototype.setCardType = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string card_last_four_digits = 8;
 * @return {string}
 */
proto.billing.private.customer.AdminUpdateCustomerCardDetailsScaRequest.prototype.getCardLastFourDigits = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.billing.private.customer.AdminUpdateCustomerCardDetailsScaRequest} returns this
 */
proto.billing.private.customer.AdminUpdateCustomerCardDetailsScaRequest.prototype.setCardLastFourDigits = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional string ip_address = 9;
 * @return {string}
 */
proto.billing.private.customer.AdminUpdateCustomerCardDetailsScaRequest.prototype.getIpAddress = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.billing.private.customer.AdminUpdateCustomerCardDetailsScaRequest} returns this
 */
proto.billing.private.customer.AdminUpdateCustomerCardDetailsScaRequest.prototype.setIpAddress = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional string transaction_token = 10;
 * @return {string}
 */
proto.billing.private.customer.AdminUpdateCustomerCardDetailsScaRequest.prototype.getTransactionToken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.billing.private.customer.AdminUpdateCustomerCardDetailsScaRequest} returns this
 */
proto.billing.private.customer.AdminUpdateCustomerCardDetailsScaRequest.prototype.setTransactionToken = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * optional string browser_info = 11;
 * @return {string}
 */
proto.billing.private.customer.AdminUpdateCustomerCardDetailsScaRequest.prototype.getBrowserInfo = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};


/**
 * @param {string} value
 * @return {!proto.billing.private.customer.AdminUpdateCustomerCardDetailsScaRequest} returns this
 */
proto.billing.private.customer.AdminUpdateCustomerCardDetailsScaRequest.prototype.setBrowserInfo = function(value) {
  return jspb.Message.setProto3StringField(this, 11, value);
};


/**
 * optional string redirect_url = 12;
 * @return {string}
 */
proto.billing.private.customer.AdminUpdateCustomerCardDetailsScaRequest.prototype.getRedirectUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 12, ""));
};


/**
 * @param {string} value
 * @return {!proto.billing.private.customer.AdminUpdateCustomerCardDetailsScaRequest} returns this
 */
proto.billing.private.customer.AdminUpdateCustomerCardDetailsScaRequest.prototype.setRedirectUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 12, value);
};


/**
 * optional string callback_url = 13;
 * @return {string}
 */
proto.billing.private.customer.AdminUpdateCustomerCardDetailsScaRequest.prototype.getCallbackUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 13, ""));
};


/**
 * @param {string} value
 * @return {!proto.billing.private.customer.AdminUpdateCustomerCardDetailsScaRequest} returns this
 */
proto.billing.private.customer.AdminUpdateCustomerCardDetailsScaRequest.prototype.setCallbackUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 13, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.billing.private.customer.AdminUpdateCustomerCardDetailsScaResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.billing.private.customer.AdminUpdateCustomerCardDetailsScaResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.billing.private.customer.AdminUpdateCustomerCardDetailsScaResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing.private.customer.AdminUpdateCustomerCardDetailsScaResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    status: jspb.Message.getFieldWithDefault(msg, 1, ""),
    transactionToken: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.billing.private.customer.AdminUpdateCustomerCardDetailsScaResponse}
 */
proto.billing.private.customer.AdminUpdateCustomerCardDetailsScaResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.billing.private.customer.AdminUpdateCustomerCardDetailsScaResponse;
  return proto.billing.private.customer.AdminUpdateCustomerCardDetailsScaResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.billing.private.customer.AdminUpdateCustomerCardDetailsScaResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.billing.private.customer.AdminUpdateCustomerCardDetailsScaResponse}
 */
proto.billing.private.customer.AdminUpdateCustomerCardDetailsScaResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setStatus(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setTransactionToken(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.billing.private.customer.AdminUpdateCustomerCardDetailsScaResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.billing.private.customer.AdminUpdateCustomerCardDetailsScaResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.billing.private.customer.AdminUpdateCustomerCardDetailsScaResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing.private.customer.AdminUpdateCustomerCardDetailsScaResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStatus();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getTransactionToken();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string status = 1;
 * @return {string}
 */
proto.billing.private.customer.AdminUpdateCustomerCardDetailsScaResponse.prototype.getStatus = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.billing.private.customer.AdminUpdateCustomerCardDetailsScaResponse} returns this
 */
proto.billing.private.customer.AdminUpdateCustomerCardDetailsScaResponse.prototype.setStatus = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string transaction_token = 2;
 * @return {string}
 */
proto.billing.private.customer.AdminUpdateCustomerCardDetailsScaResponse.prototype.getTransactionToken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.billing.private.customer.AdminUpdateCustomerCardDetailsScaResponse} returns this
 */
proto.billing.private.customer.AdminUpdateCustomerCardDetailsScaResponse.prototype.setTransactionToken = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.billing.private.customer.AddCustomerNoteRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.billing.private.customer.AddCustomerNoteRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.billing.private.customer.AddCustomerNoteRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing.private.customer.AddCustomerNoteRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    customerUniqueId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    companyId: jspb.Message.getFieldWithDefault(msg, 2, 0),
    subjectLine: jspb.Message.getFieldWithDefault(msg, 3, ""),
    content: jspb.Message.getFieldWithDefault(msg, 4, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.billing.private.customer.AddCustomerNoteRequest}
 */
proto.billing.private.customer.AddCustomerNoteRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.billing.private.customer.AddCustomerNoteRequest;
  return proto.billing.private.customer.AddCustomerNoteRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.billing.private.customer.AddCustomerNoteRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.billing.private.customer.AddCustomerNoteRequest}
 */
proto.billing.private.customer.AddCustomerNoteRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setCustomerUniqueId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setCompanyId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setSubjectLine(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setContent(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.billing.private.customer.AddCustomerNoteRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.billing.private.customer.AddCustomerNoteRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.billing.private.customer.AddCustomerNoteRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing.private.customer.AddCustomerNoteRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCustomerUniqueId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getCompanyId();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getSubjectLine();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getContent();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
};


/**
 * optional string customer_unique_id = 1;
 * @return {string}
 */
proto.billing.private.customer.AddCustomerNoteRequest.prototype.getCustomerUniqueId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.billing.private.customer.AddCustomerNoteRequest} returns this
 */
proto.billing.private.customer.AddCustomerNoteRequest.prototype.setCustomerUniqueId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int32 company_id = 2;
 * @return {number}
 */
proto.billing.private.customer.AddCustomerNoteRequest.prototype.getCompanyId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.billing.private.customer.AddCustomerNoteRequest} returns this
 */
proto.billing.private.customer.AddCustomerNoteRequest.prototype.setCompanyId = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional string subject_line = 3;
 * @return {string}
 */
proto.billing.private.customer.AddCustomerNoteRequest.prototype.getSubjectLine = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.billing.private.customer.AddCustomerNoteRequest} returns this
 */
proto.billing.private.customer.AddCustomerNoteRequest.prototype.setSubjectLine = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string content = 4;
 * @return {string}
 */
proto.billing.private.customer.AddCustomerNoteRequest.prototype.getContent = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.billing.private.customer.AddCustomerNoteRequest} returns this
 */
proto.billing.private.customer.AddCustomerNoteRequest.prototype.setContent = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.billing.private.customer.AddCustomerNoteResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.billing.private.customer.AddCustomerNoteResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.billing.private.customer.AddCustomerNoteResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing.private.customer.AddCustomerNoteResponse.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.billing.private.customer.AddCustomerNoteResponse}
 */
proto.billing.private.customer.AddCustomerNoteResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.billing.private.customer.AddCustomerNoteResponse;
  return proto.billing.private.customer.AddCustomerNoteResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.billing.private.customer.AddCustomerNoteResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.billing.private.customer.AddCustomerNoteResponse}
 */
proto.billing.private.customer.AddCustomerNoteResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.billing.private.customer.AddCustomerNoteResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.billing.private.customer.AddCustomerNoteResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.billing.private.customer.AddCustomerNoteResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing.private.customer.AddCustomerNoteResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.billing.private.customer.UpdateCustomerNoteRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.billing.private.customer.UpdateCustomerNoteRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.billing.private.customer.UpdateCustomerNoteRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing.private.customer.UpdateCustomerNoteRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    customerUniqueId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    companyId: jspb.Message.getFieldWithDefault(msg, 2, 0),
    customerNoteId: jspb.Message.getFieldWithDefault(msg, 3, 0),
    subjectLine: jspb.Message.getFieldWithDefault(msg, 4, ""),
    content: jspb.Message.getFieldWithDefault(msg, 5, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.billing.private.customer.UpdateCustomerNoteRequest}
 */
proto.billing.private.customer.UpdateCustomerNoteRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.billing.private.customer.UpdateCustomerNoteRequest;
  return proto.billing.private.customer.UpdateCustomerNoteRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.billing.private.customer.UpdateCustomerNoteRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.billing.private.customer.UpdateCustomerNoteRequest}
 */
proto.billing.private.customer.UpdateCustomerNoteRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setCustomerUniqueId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setCompanyId(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setCustomerNoteId(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setSubjectLine(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setContent(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.billing.private.customer.UpdateCustomerNoteRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.billing.private.customer.UpdateCustomerNoteRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.billing.private.customer.UpdateCustomerNoteRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing.private.customer.UpdateCustomerNoteRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCustomerUniqueId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getCompanyId();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getCustomerNoteId();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
  f = message.getSubjectLine();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getContent();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
};


/**
 * optional string customer_unique_id = 1;
 * @return {string}
 */
proto.billing.private.customer.UpdateCustomerNoteRequest.prototype.getCustomerUniqueId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.billing.private.customer.UpdateCustomerNoteRequest} returns this
 */
proto.billing.private.customer.UpdateCustomerNoteRequest.prototype.setCustomerUniqueId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int32 company_id = 2;
 * @return {number}
 */
proto.billing.private.customer.UpdateCustomerNoteRequest.prototype.getCompanyId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.billing.private.customer.UpdateCustomerNoteRequest} returns this
 */
proto.billing.private.customer.UpdateCustomerNoteRequest.prototype.setCompanyId = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int32 customer_note_id = 3;
 * @return {number}
 */
proto.billing.private.customer.UpdateCustomerNoteRequest.prototype.getCustomerNoteId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.billing.private.customer.UpdateCustomerNoteRequest} returns this
 */
proto.billing.private.customer.UpdateCustomerNoteRequest.prototype.setCustomerNoteId = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional string subject_line = 4;
 * @return {string}
 */
proto.billing.private.customer.UpdateCustomerNoteRequest.prototype.getSubjectLine = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.billing.private.customer.UpdateCustomerNoteRequest} returns this
 */
proto.billing.private.customer.UpdateCustomerNoteRequest.prototype.setSubjectLine = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string content = 5;
 * @return {string}
 */
proto.billing.private.customer.UpdateCustomerNoteRequest.prototype.getContent = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.billing.private.customer.UpdateCustomerNoteRequest} returns this
 */
proto.billing.private.customer.UpdateCustomerNoteRequest.prototype.setContent = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.billing.private.customer.UpdateCustomerNoteResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.billing.private.customer.UpdateCustomerNoteResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.billing.private.customer.UpdateCustomerNoteResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing.private.customer.UpdateCustomerNoteResponse.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.billing.private.customer.UpdateCustomerNoteResponse}
 */
proto.billing.private.customer.UpdateCustomerNoteResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.billing.private.customer.UpdateCustomerNoteResponse;
  return proto.billing.private.customer.UpdateCustomerNoteResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.billing.private.customer.UpdateCustomerNoteResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.billing.private.customer.UpdateCustomerNoteResponse}
 */
proto.billing.private.customer.UpdateCustomerNoteResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.billing.private.customer.UpdateCustomerNoteResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.billing.private.customer.UpdateCustomerNoteResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.billing.private.customer.UpdateCustomerNoteResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing.private.customer.UpdateCustomerNoteResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.billing.private.customer.DeleteCustomerNoteRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.billing.private.customer.DeleteCustomerNoteRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.billing.private.customer.DeleteCustomerNoteRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing.private.customer.DeleteCustomerNoteRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    companyId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    customerNoteId: jspb.Message.getFieldWithDefault(msg, 2, 0),
    customerUniqueId: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.billing.private.customer.DeleteCustomerNoteRequest}
 */
proto.billing.private.customer.DeleteCustomerNoteRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.billing.private.customer.DeleteCustomerNoteRequest;
  return proto.billing.private.customer.DeleteCustomerNoteRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.billing.private.customer.DeleteCustomerNoteRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.billing.private.customer.DeleteCustomerNoteRequest}
 */
proto.billing.private.customer.DeleteCustomerNoteRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setCompanyId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setCustomerNoteId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setCustomerUniqueId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.billing.private.customer.DeleteCustomerNoteRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.billing.private.customer.DeleteCustomerNoteRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.billing.private.customer.DeleteCustomerNoteRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing.private.customer.DeleteCustomerNoteRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCompanyId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getCustomerNoteId();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getCustomerUniqueId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional int32 company_id = 1;
 * @return {number}
 */
proto.billing.private.customer.DeleteCustomerNoteRequest.prototype.getCompanyId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.billing.private.customer.DeleteCustomerNoteRequest} returns this
 */
proto.billing.private.customer.DeleteCustomerNoteRequest.prototype.setCompanyId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int32 customer_note_id = 2;
 * @return {number}
 */
proto.billing.private.customer.DeleteCustomerNoteRequest.prototype.getCustomerNoteId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.billing.private.customer.DeleteCustomerNoteRequest} returns this
 */
proto.billing.private.customer.DeleteCustomerNoteRequest.prototype.setCustomerNoteId = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional string customer_unique_id = 3;
 * @return {string}
 */
proto.billing.private.customer.DeleteCustomerNoteRequest.prototype.getCustomerUniqueId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.billing.private.customer.DeleteCustomerNoteRequest} returns this
 */
proto.billing.private.customer.DeleteCustomerNoteRequest.prototype.setCustomerUniqueId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.billing.private.customer.DeleteCustomerNoteResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.billing.private.customer.DeleteCustomerNoteResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.billing.private.customer.DeleteCustomerNoteResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing.private.customer.DeleteCustomerNoteResponse.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.billing.private.customer.DeleteCustomerNoteResponse}
 */
proto.billing.private.customer.DeleteCustomerNoteResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.billing.private.customer.DeleteCustomerNoteResponse;
  return proto.billing.private.customer.DeleteCustomerNoteResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.billing.private.customer.DeleteCustomerNoteResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.billing.private.customer.DeleteCustomerNoteResponse}
 */
proto.billing.private.customer.DeleteCustomerNoteResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.billing.private.customer.DeleteCustomerNoteResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.billing.private.customer.DeleteCustomerNoteResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.billing.private.customer.DeleteCustomerNoteResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing.private.customer.DeleteCustomerNoteResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.billing.private.customer.GetCustomerNotesRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.billing.private.customer.GetCustomerNotesRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.billing.private.customer.GetCustomerNotesRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing.private.customer.GetCustomerNotesRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    customerUniqueId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    companyId: jspb.Message.getFieldWithDefault(msg, 2, 0),
    search: jspb.Message.getFieldWithDefault(msg, 3, ""),
    page: jspb.Message.getFieldWithDefault(msg, 4, 0),
    pageSize: jspb.Message.getFieldWithDefault(msg, 5, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.billing.private.customer.GetCustomerNotesRequest}
 */
proto.billing.private.customer.GetCustomerNotesRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.billing.private.customer.GetCustomerNotesRequest;
  return proto.billing.private.customer.GetCustomerNotesRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.billing.private.customer.GetCustomerNotesRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.billing.private.customer.GetCustomerNotesRequest}
 */
proto.billing.private.customer.GetCustomerNotesRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setCustomerUniqueId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setCompanyId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setSearch(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPage(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPageSize(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.billing.private.customer.GetCustomerNotesRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.billing.private.customer.GetCustomerNotesRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.billing.private.customer.GetCustomerNotesRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing.private.customer.GetCustomerNotesRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCustomerUniqueId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getCompanyId();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getSearch();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getPage();
  if (f !== 0) {
    writer.writeInt32(
      4,
      f
    );
  }
  f = message.getPageSize();
  if (f !== 0) {
    writer.writeInt32(
      5,
      f
    );
  }
};


/**
 * optional string customer_unique_id = 1;
 * @return {string}
 */
proto.billing.private.customer.GetCustomerNotesRequest.prototype.getCustomerUniqueId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.billing.private.customer.GetCustomerNotesRequest} returns this
 */
proto.billing.private.customer.GetCustomerNotesRequest.prototype.setCustomerUniqueId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int32 company_id = 2;
 * @return {number}
 */
proto.billing.private.customer.GetCustomerNotesRequest.prototype.getCompanyId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.billing.private.customer.GetCustomerNotesRequest} returns this
 */
proto.billing.private.customer.GetCustomerNotesRequest.prototype.setCompanyId = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional string search = 3;
 * @return {string}
 */
proto.billing.private.customer.GetCustomerNotesRequest.prototype.getSearch = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.billing.private.customer.GetCustomerNotesRequest} returns this
 */
proto.billing.private.customer.GetCustomerNotesRequest.prototype.setSearch = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional int32 page = 4;
 * @return {number}
 */
proto.billing.private.customer.GetCustomerNotesRequest.prototype.getPage = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.billing.private.customer.GetCustomerNotesRequest} returns this
 */
proto.billing.private.customer.GetCustomerNotesRequest.prototype.setPage = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional int32 page_size = 5;
 * @return {number}
 */
proto.billing.private.customer.GetCustomerNotesRequest.prototype.getPageSize = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.billing.private.customer.GetCustomerNotesRequest} returns this
 */
proto.billing.private.customer.GetCustomerNotesRequest.prototype.setPageSize = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.billing.private.customer.GetCustomerNotesResponse.repeatedFields_ = [6];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.billing.private.customer.GetCustomerNotesResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.billing.private.customer.GetCustomerNotesResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.billing.private.customer.GetCustomerNotesResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing.private.customer.GetCustomerNotesResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    search: jspb.Message.getFieldWithDefault(msg, 1, ""),
    currentPage: jspb.Message.getFieldWithDefault(msg, 2, 0),
    pageSize: jspb.Message.getFieldWithDefault(msg, 3, 0),
    pageCount: jspb.Message.getFieldWithDefault(msg, 4, 0),
    rowCount: jspb.Message.getFieldWithDefault(msg, 5, 0),
    resultsList: jspb.Message.toObjectList(msg.getResultsList(),
    proto.billing.private.customer.GetCustomerNotesResponse.CustomerNote.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.billing.private.customer.GetCustomerNotesResponse}
 */
proto.billing.private.customer.GetCustomerNotesResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.billing.private.customer.GetCustomerNotesResponse;
  return proto.billing.private.customer.GetCustomerNotesResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.billing.private.customer.GetCustomerNotesResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.billing.private.customer.GetCustomerNotesResponse}
 */
proto.billing.private.customer.GetCustomerNotesResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setSearch(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setCurrentPage(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPageSize(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPageCount(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setRowCount(value);
      break;
    case 6:
      var value = new proto.billing.private.customer.GetCustomerNotesResponse.CustomerNote;
      reader.readMessage(value,proto.billing.private.customer.GetCustomerNotesResponse.CustomerNote.deserializeBinaryFromReader);
      msg.addResults(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.billing.private.customer.GetCustomerNotesResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.billing.private.customer.GetCustomerNotesResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.billing.private.customer.GetCustomerNotesResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing.private.customer.GetCustomerNotesResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSearch();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getCurrentPage();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getPageSize();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
  f = message.getPageCount();
  if (f !== 0) {
    writer.writeInt32(
      4,
      f
    );
  }
  f = message.getRowCount();
  if (f !== 0) {
    writer.writeInt64(
      5,
      f
    );
  }
  f = message.getResultsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      6,
      f,
      proto.billing.private.customer.GetCustomerNotesResponse.CustomerNote.serializeBinaryToWriter
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.billing.private.customer.GetCustomerNotesResponse.CustomerNote.prototype.toObject = function(opt_includeInstance) {
  return proto.billing.private.customer.GetCustomerNotesResponse.CustomerNote.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.billing.private.customer.GetCustomerNotesResponse.CustomerNote} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing.private.customer.GetCustomerNotesResponse.CustomerNote.toObject = function(includeInstance, msg) {
  var f, obj = {
    customerNoteId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    subjectLine: jspb.Message.getFieldWithDefault(msg, 2, ""),
    content: jspb.Message.getFieldWithDefault(msg, 3, ""),
    updatedOn: (f = msg.getUpdatedOn()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.billing.private.customer.GetCustomerNotesResponse.CustomerNote}
 */
proto.billing.private.customer.GetCustomerNotesResponse.CustomerNote.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.billing.private.customer.GetCustomerNotesResponse.CustomerNote;
  return proto.billing.private.customer.GetCustomerNotesResponse.CustomerNote.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.billing.private.customer.GetCustomerNotesResponse.CustomerNote} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.billing.private.customer.GetCustomerNotesResponse.CustomerNote}
 */
proto.billing.private.customer.GetCustomerNotesResponse.CustomerNote.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setCustomerNoteId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setSubjectLine(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setContent(value);
      break;
    case 4:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setUpdatedOn(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.billing.private.customer.GetCustomerNotesResponse.CustomerNote.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.billing.private.customer.GetCustomerNotesResponse.CustomerNote.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.billing.private.customer.GetCustomerNotesResponse.CustomerNote} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing.private.customer.GetCustomerNotesResponse.CustomerNote.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCustomerNoteId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getSubjectLine();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getContent();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getUpdatedOn();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
};


/**
 * optional int32 customer_note_id = 1;
 * @return {number}
 */
proto.billing.private.customer.GetCustomerNotesResponse.CustomerNote.prototype.getCustomerNoteId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.billing.private.customer.GetCustomerNotesResponse.CustomerNote} returns this
 */
proto.billing.private.customer.GetCustomerNotesResponse.CustomerNote.prototype.setCustomerNoteId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string subject_line = 2;
 * @return {string}
 */
proto.billing.private.customer.GetCustomerNotesResponse.CustomerNote.prototype.getSubjectLine = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.billing.private.customer.GetCustomerNotesResponse.CustomerNote} returns this
 */
proto.billing.private.customer.GetCustomerNotesResponse.CustomerNote.prototype.setSubjectLine = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string content = 3;
 * @return {string}
 */
proto.billing.private.customer.GetCustomerNotesResponse.CustomerNote.prototype.getContent = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.billing.private.customer.GetCustomerNotesResponse.CustomerNote} returns this
 */
proto.billing.private.customer.GetCustomerNotesResponse.CustomerNote.prototype.setContent = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional google.protobuf.Timestamp updated_on = 4;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.billing.private.customer.GetCustomerNotesResponse.CustomerNote.prototype.getUpdatedOn = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 4));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.billing.private.customer.GetCustomerNotesResponse.CustomerNote} returns this
*/
proto.billing.private.customer.GetCustomerNotesResponse.CustomerNote.prototype.setUpdatedOn = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.billing.private.customer.GetCustomerNotesResponse.CustomerNote} returns this
 */
proto.billing.private.customer.GetCustomerNotesResponse.CustomerNote.prototype.clearUpdatedOn = function() {
  return this.setUpdatedOn(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.billing.private.customer.GetCustomerNotesResponse.CustomerNote.prototype.hasUpdatedOn = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional string search = 1;
 * @return {string}
 */
proto.billing.private.customer.GetCustomerNotesResponse.prototype.getSearch = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.billing.private.customer.GetCustomerNotesResponse} returns this
 */
proto.billing.private.customer.GetCustomerNotesResponse.prototype.setSearch = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int32 current_page = 2;
 * @return {number}
 */
proto.billing.private.customer.GetCustomerNotesResponse.prototype.getCurrentPage = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.billing.private.customer.GetCustomerNotesResponse} returns this
 */
proto.billing.private.customer.GetCustomerNotesResponse.prototype.setCurrentPage = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int32 page_size = 3;
 * @return {number}
 */
proto.billing.private.customer.GetCustomerNotesResponse.prototype.getPageSize = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.billing.private.customer.GetCustomerNotesResponse} returns this
 */
proto.billing.private.customer.GetCustomerNotesResponse.prototype.setPageSize = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional int32 page_count = 4;
 * @return {number}
 */
proto.billing.private.customer.GetCustomerNotesResponse.prototype.getPageCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.billing.private.customer.GetCustomerNotesResponse} returns this
 */
proto.billing.private.customer.GetCustomerNotesResponse.prototype.setPageCount = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional int64 row_count = 5;
 * @return {number}
 */
proto.billing.private.customer.GetCustomerNotesResponse.prototype.getRowCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.billing.private.customer.GetCustomerNotesResponse} returns this
 */
proto.billing.private.customer.GetCustomerNotesResponse.prototype.setRowCount = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * repeated CustomerNote results = 6;
 * @return {!Array<!proto.billing.private.customer.GetCustomerNotesResponse.CustomerNote>}
 */
proto.billing.private.customer.GetCustomerNotesResponse.prototype.getResultsList = function() {
  return /** @type{!Array<!proto.billing.private.customer.GetCustomerNotesResponse.CustomerNote>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.billing.private.customer.GetCustomerNotesResponse.CustomerNote, 6));
};


/**
 * @param {!Array<!proto.billing.private.customer.GetCustomerNotesResponse.CustomerNote>} value
 * @return {!proto.billing.private.customer.GetCustomerNotesResponse} returns this
*/
proto.billing.private.customer.GetCustomerNotesResponse.prototype.setResultsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 6, value);
};


/**
 * @param {!proto.billing.private.customer.GetCustomerNotesResponse.CustomerNote=} opt_value
 * @param {number=} opt_index
 * @return {!proto.billing.private.customer.GetCustomerNotesResponse.CustomerNote}
 */
proto.billing.private.customer.GetCustomerNotesResponse.prototype.addResults = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 6, opt_value, proto.billing.private.customer.GetCustomerNotesResponse.CustomerNote, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.billing.private.customer.GetCustomerNotesResponse} returns this
 */
proto.billing.private.customer.GetCustomerNotesResponse.prototype.clearResultsList = function() {
  return this.setResultsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.billing.private.customer.GetCustomerLanguageRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.billing.private.customer.GetCustomerLanguageRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.billing.private.customer.GetCustomerLanguageRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing.private.customer.GetCustomerLanguageRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    customerUniqueId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    companyDomain: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.billing.private.customer.GetCustomerLanguageRequest}
 */
proto.billing.private.customer.GetCustomerLanguageRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.billing.private.customer.GetCustomerLanguageRequest;
  return proto.billing.private.customer.GetCustomerLanguageRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.billing.private.customer.GetCustomerLanguageRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.billing.private.customer.GetCustomerLanguageRequest}
 */
proto.billing.private.customer.GetCustomerLanguageRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setCustomerUniqueId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setCompanyDomain(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.billing.private.customer.GetCustomerLanguageRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.billing.private.customer.GetCustomerLanguageRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.billing.private.customer.GetCustomerLanguageRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing.private.customer.GetCustomerLanguageRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCustomerUniqueId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getCompanyDomain();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string customer_unique_id = 1;
 * @return {string}
 */
proto.billing.private.customer.GetCustomerLanguageRequest.prototype.getCustomerUniqueId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.billing.private.customer.GetCustomerLanguageRequest} returns this
 */
proto.billing.private.customer.GetCustomerLanguageRequest.prototype.setCustomerUniqueId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string company_domain = 2;
 * @return {string}
 */
proto.billing.private.customer.GetCustomerLanguageRequest.prototype.getCompanyDomain = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.billing.private.customer.GetCustomerLanguageRequest} returns this
 */
proto.billing.private.customer.GetCustomerLanguageRequest.prototype.setCompanyDomain = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.billing.private.customer.GetCustomerLanguageResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.billing.private.customer.GetCustomerLanguageResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.billing.private.customer.GetCustomerLanguageResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing.private.customer.GetCustomerLanguageResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    languageIsoCode: jspb.Message.getFieldWithDefault(msg, 1, ""),
    languageEnglishName: jspb.Message.getFieldWithDefault(msg, 2, ""),
    dateUpdated: (f = msg.getDateUpdated()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.billing.private.customer.GetCustomerLanguageResponse}
 */
proto.billing.private.customer.GetCustomerLanguageResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.billing.private.customer.GetCustomerLanguageResponse;
  return proto.billing.private.customer.GetCustomerLanguageResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.billing.private.customer.GetCustomerLanguageResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.billing.private.customer.GetCustomerLanguageResponse}
 */
proto.billing.private.customer.GetCustomerLanguageResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setLanguageIsoCode(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setLanguageEnglishName(value);
      break;
    case 3:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setDateUpdated(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.billing.private.customer.GetCustomerLanguageResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.billing.private.customer.GetCustomerLanguageResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.billing.private.customer.GetCustomerLanguageResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing.private.customer.GetCustomerLanguageResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getLanguageIsoCode();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getLanguageEnglishName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getDateUpdated();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
};


/**
 * optional string language_iso_code = 1;
 * @return {string}
 */
proto.billing.private.customer.GetCustomerLanguageResponse.prototype.getLanguageIsoCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.billing.private.customer.GetCustomerLanguageResponse} returns this
 */
proto.billing.private.customer.GetCustomerLanguageResponse.prototype.setLanguageIsoCode = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string language_english_name = 2;
 * @return {string}
 */
proto.billing.private.customer.GetCustomerLanguageResponse.prototype.getLanguageEnglishName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.billing.private.customer.GetCustomerLanguageResponse} returns this
 */
proto.billing.private.customer.GetCustomerLanguageResponse.prototype.setLanguageEnglishName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional google.protobuf.Timestamp date_updated = 3;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.billing.private.customer.GetCustomerLanguageResponse.prototype.getDateUpdated = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 3));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.billing.private.customer.GetCustomerLanguageResponse} returns this
*/
proto.billing.private.customer.GetCustomerLanguageResponse.prototype.setDateUpdated = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.billing.private.customer.GetCustomerLanguageResponse} returns this
 */
proto.billing.private.customer.GetCustomerLanguageResponse.prototype.clearDateUpdated = function() {
  return this.setDateUpdated(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.billing.private.customer.GetCustomerLanguageResponse.prototype.hasDateUpdated = function() {
  return jspb.Message.getField(this, 3) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.billing.private.customer.UpdateCustomerLanguageRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.billing.private.customer.UpdateCustomerLanguageRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.billing.private.customer.UpdateCustomerLanguageRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing.private.customer.UpdateCustomerLanguageRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    customerUniqueId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    companyId: jspb.Message.getFieldWithDefault(msg, 2, 0),
    languageIsoCode: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.billing.private.customer.UpdateCustomerLanguageRequest}
 */
proto.billing.private.customer.UpdateCustomerLanguageRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.billing.private.customer.UpdateCustomerLanguageRequest;
  return proto.billing.private.customer.UpdateCustomerLanguageRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.billing.private.customer.UpdateCustomerLanguageRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.billing.private.customer.UpdateCustomerLanguageRequest}
 */
proto.billing.private.customer.UpdateCustomerLanguageRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setCustomerUniqueId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setCompanyId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setLanguageIsoCode(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.billing.private.customer.UpdateCustomerLanguageRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.billing.private.customer.UpdateCustomerLanguageRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.billing.private.customer.UpdateCustomerLanguageRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing.private.customer.UpdateCustomerLanguageRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCustomerUniqueId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getCompanyId();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getLanguageIsoCode();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional string customer_unique_id = 1;
 * @return {string}
 */
proto.billing.private.customer.UpdateCustomerLanguageRequest.prototype.getCustomerUniqueId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.billing.private.customer.UpdateCustomerLanguageRequest} returns this
 */
proto.billing.private.customer.UpdateCustomerLanguageRequest.prototype.setCustomerUniqueId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int32 company_id = 2;
 * @return {number}
 */
proto.billing.private.customer.UpdateCustomerLanguageRequest.prototype.getCompanyId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.billing.private.customer.UpdateCustomerLanguageRequest} returns this
 */
proto.billing.private.customer.UpdateCustomerLanguageRequest.prototype.setCompanyId = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional string language_iso_code = 3;
 * @return {string}
 */
proto.billing.private.customer.UpdateCustomerLanguageRequest.prototype.getLanguageIsoCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.billing.private.customer.UpdateCustomerLanguageRequest} returns this
 */
proto.billing.private.customer.UpdateCustomerLanguageRequest.prototype.setLanguageIsoCode = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.billing.private.customer.UpdateCustomerLanguageResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.billing.private.customer.UpdateCustomerLanguageResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.billing.private.customer.UpdateCustomerLanguageResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing.private.customer.UpdateCustomerLanguageResponse.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.billing.private.customer.UpdateCustomerLanguageResponse}
 */
proto.billing.private.customer.UpdateCustomerLanguageResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.billing.private.customer.UpdateCustomerLanguageResponse;
  return proto.billing.private.customer.UpdateCustomerLanguageResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.billing.private.customer.UpdateCustomerLanguageResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.billing.private.customer.UpdateCustomerLanguageResponse}
 */
proto.billing.private.customer.UpdateCustomerLanguageResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.billing.private.customer.UpdateCustomerLanguageResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.billing.private.customer.UpdateCustomerLanguageResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.billing.private.customer.UpdateCustomerLanguageResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing.private.customer.UpdateCustomerLanguageResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};


goog.object.extend(exports, proto.billing.private.customer);
