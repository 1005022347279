
import { BillsbyAction } from "../../models/BillsbyAction";
import { AppState } from "../..";
import { GetAddonsResponse, AddonFilterType } from "../../utils/grpc/generated/Billsby.Protos/core/private/addons/addons_pb";
import { FETCH_ADD_ONS_LIST_SUCCESS, SET_ADD_ONS_FIELDS, RESET_ADD_ONS_LIST, CREATE_ADD_ON_REQUEST, CREATE_ADD_ON_SUCCESS, CREATE_ADD_ON_FAILURE, UPDATE_ADD_ON_REQUEST, UPDATE_ADD_ON_SUCCESS, UPDATE_ADD_ON_FAILURE, FETCH_ADD_ONS_LIST_REQUEST, FETCH_ADD_ONS_LIST_FAILURE } from "../../actions/addOnsActions";
import { GetAllowancesResponse, AllowanceFilterType } from "../../utils/grpc/generated/Billsby.Protos/core/private/allowances/allowances_pb";
import { AddonsAllowancesScreen } from "../../models/AddonsAllowances";
import { getActionErrorMessage } from "../../utils/commonUtils";

export interface IAddOnsReducerState {
  searchedAddOnsName: string,
  selectedAddOnsFilter?: { label: string, value: AddonFilterType | AllowanceFilterType },
  addOnsPaginated?: GetAddonsResponse | GetAllowancesResponse,
  hasAddOns: boolean,
  isCreatingAddOnRequest: boolean,
  isCreatingAddOnSuccess: boolean,
  isCreatingAddOnFailure: boolean,
  isEditingAddOnRequest: boolean,
  isEditingAddOnSuccess: boolean,
  isEditingAddOnFailure: boolean,
  errorMessage: string,
  isFetching: boolean
}

const initState = {
  searchedAddOnsName: "",
  //selectedAddOnsFilter: { label: counterpart('ADD_ONS_LIST_FILTER_1'), value: AddonFilterType.UNSPECIFIED_AFT },
  //addOnsPaginated: new GetAddonsResponse(),
  hasAddOns: false,
  isCreatingAddOnRequest: false,
  isCreatingAddOnSuccess: false,
  isCreatingAddOnFailure: false,
  isEditingAddOnRequest: false,
  isEditingAddOnSuccess: false,
  isEditingAddOnFailure: false,
  errorMessage: "",
  isFetching: false
}

export default function addOnsReducer(state: IAddOnsReducerState = initState, action: BillsbyAction, store: AppState) {
  switch (action.type) {
    case FETCH_ADD_ONS_LIST_REQUEST: {
      return { ...state, isFetching: true }
    }
    case FETCH_ADD_ONS_LIST_FAILURE: {
      return { ...state, isFetching: false }
    }
    case FETCH_ADD_ONS_LIST_SUCCESS: {
      if (action.fetchType === AddonsAllowancesScreen.ADDONS) {
        const response = action.response as GetAddonsResponse;
        const hasAddOns = (!!response.getAddonsList() && response.getAddonsList().length > 0) || state.hasAddOns;
        return { ...state, addOnsPaginated: response, hasAddOns, isFetching: false }
      }
      else {
        const response = action.response as GetAllowancesResponse;
        const hasAddOns = (!!response.getAllowancesList() && response.getAllowancesList().length > 0) || state.hasAddOns;
        return { ...state, addOnsPaginated: response, hasAddOns, isFetching: false }
      }
    }

    case SET_ADD_ONS_FIELDS: {
      return { ...state, [action.fieldName]: action.fieldValue }
    }
    case CREATE_ADD_ON_REQUEST:
      return { ...state, isCreatingAddOnRequest: true, isCreatingAddOnSuccess: false, isCreatingAddOnFailure: false }
    case CREATE_ADD_ON_SUCCESS:
      return { ...state, isCreatingAddOnRequest: false, isCreatingAddOnSuccess: true, isCreatingAddOnFailure: false }
    case CREATE_ADD_ON_FAILURE:
      return { ...state, isCreatingAddOnRequest: false, isCreatingAddOnSuccess: false, isCreatingAddOnFailure: true, errorMessage: getActionErrorMessage(action.error) }
    case UPDATE_ADD_ON_REQUEST:
      return { ...state, isEditingAddOnRequest: true, isEditingAddOnSuccess: false, isEditingAddOnFailure: false }
    case UPDATE_ADD_ON_SUCCESS:
      return { ...state, isEditingAddOnRequest: false, isEditingAddOnSuccess: true, isEditingAddOnFailure: false }
    case UPDATE_ADD_ON_FAILURE:
      return { ...state, isEditingAddOnRequest: false, isEditingAddOnSuccess: false, isEditingAddOnFailure: true, errorMessage: getActionErrorMessage(action.error)  }

    case RESET_ADD_ONS_LIST:
      return initState

    default:
      return state
  }
}