import API from "../utils/API";
import { makeActionCreator, makeGenericActionCreatorTypeSafe } from "../utils/reduxUtils";
import { PAGINATION_PAGE_SIZE } from "../utils/constants";
import { ISubscriptionsReducer } from "../reducers/subscriptionsReducer";
import { SubscriptionStatusType } from "../models/Subscriptions";

export const FETCH_SUBSCRIPTIONS_REQUEST = "FETCH_SUBSCRIPTIONS_REQUEST";
export const FETCH_SUBSCRIPTIONS_SUCCESS= "FETCH_SUBSCRIPTIONS_SUCCESS";
export const FETCH_SUBSCRIPTIONS_FAILURE = "FETCH_SUBSCRIPTIONS_FAILURE";

export const SET_SUBSCRIPTIONS_FIELD = "SET_SUBSCRIPTIONS_FIELD";
export const setSubscriptionsField = makeGenericActionCreatorTypeSafe<ISubscriptionsReducer>(SET_SUBSCRIPTIONS_FIELD);

export const RESET_SUBSCRIPTIONS = "RESET_SUBSCRIPTIONS";
export const resetSubscriptions = makeActionCreator(RESET_SUBSCRIPTIONS, "payload");

export const fetchSubscriptions = function (companyDomain: string, pageNumber: number, pageSize: number = PAGINATION_PAGE_SIZE, search: string = "", 
  subscriptionStatus?: SubscriptionStatusType, searchedProductName: string = "", searchedPlanName: string = "", isCreatedOnSortDescendingOrder: boolean = true) {
  return {
    types: [FETCH_SUBSCRIPTIONS_REQUEST, FETCH_SUBSCRIPTIONS_SUCCESS, FETCH_SUBSCRIPTIONS_FAILURE],
    callAPI: () => API.fetchSubscriptions(companyDomain, pageNumber, pageSize, search, subscriptionStatus, searchedProductName, searchedPlanName, isCreatedOnSortDescendingOrder)
  }
}
