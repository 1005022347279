import React from "react";
import { NavLink, useRouteMatch } from "react-router-dom";
import TabbedContainer from "../../../components/ui/tabbed-container/TabbedContainer";
import CreditNotesTable from "../../../containers/credit-notes-table/CreditNotesTable";
import InvoicesTable from "../../../containers/invoices-table/InvoicesTable";
import CardDetails from "../../../containers/card-details/CardDetails";
import TaxValidationTable from "../../../containers/tax-validation-table/TaxValidationTable"
import { PrivateRoute } from "../../AuthRoutes";
import counterpart from "counterpart";
import "./CustomerDetailsTab.scss";
import CustomerSubscriptions from "../../../components/customers/customer-subscriptions/CustomerSubscriptions";
import CustomerNotes from "../../../components/customers/customer-notes/CustomerNotes";
import CustomerLanguages from "../../../components/customers/customer-languages/CustomerLanguages";
import { useSelector } from "react-redux";
import { AppState } from "../../..";
import { ICustomerDetailsReducerState } from "../../../reducers/customerDetailsReducer";
import { CardStatus, PaymentMethodType } from "../../../models/Customer";
import { isCardExpired } from "../../../utils/commonUtils";
import EmailLogs  from "../../email-logs/EmailLogs";
import ACHDetails from "../../ach-details/ACHDetails";

const CustomerDetailsTabs: React.FC = () => {
  const match = useRouteMatch();
  const { cardStatus, cardDetails: { expiryMonth, expiryYear, paymentMethodType }, email } = useSelector<AppState, ICustomerDetailsReducerState>(state => state.customerDetailsReducer)
  const isACHMandated = paymentMethodType === PaymentMethodType.ACH;

  const tabArray: Array<{ label: string; navlink: string; className?: string, param?: string }> = [
    { label: "CUSTOMER_DETAILS_TAB_SUBSCRIPTION", navlink: "" },
    { label: "CUSTOMER_DETAILS_TAB_INVOICES", navlink: "/invoice-list" },
    { label: "CUSTOMER_DETAILS_TAB_CREDIT_NOTES", navlink: "/credit-notes" },
    { label: isACHMandated ? "CUSTOMER_DETAILS_TAB_ACH" : "CUSTOMER_DETAILS_TAB_CARD", navlink: isACHMandated ? "/ach-details" : "/card", className: isACHMandated ? "" : (cardStatus === CardStatus.MISSING || isCardExpired(expiryMonth, expiryYear)) ? "has-error" : "" },
    { label: "CUSTOMER_DETAILS_TAB_EMAILS", navlink: "/emails" },
    { label: "CUSTOMER_DETAILS_TAB_TAX_VALIDATION", navlink: "/tax-validation" },
    { label: "CUSTOMER_DETAILS_TAB_NOTES", navlink: "/notes" },
    // { label: 'CUSTOMER_DETAILS_TAB_LANGUAGES', navlink: '/languages' }
    // { label: 'CUSTOMER_DETAILS_TAB_VALUES_SCORE', navlink: '/value-score' }
  ];

  return (
    <div className="customer-details-tab">
      <TabbedContainer type="submenu">
        {tabArray.map((tab, idx) => (
          <NavLink exact key={idx} className={`${tab.className ? tab.className : ""}`} activeClassName={"active"} to={`${match?.url}${tab.navlink}`}>
            <li>{counterpart(tab.label)}</li>
          </NavLink>
        ))}
      </TabbedContainer>

      <PrivateRoute
        render={() => (
          <div className="customer-details-tab__content">
            <PrivateRoute exact path={`${match?.path}/`} component={CustomerSubscriptions} />
            <PrivateRoute exact path={`${match?.path}/invoice-list`} component={InvoicesTable} />
            <PrivateRoute exact path={`${match?.path}/credit-notes`} component={CreditNotesTable} />
            <PrivateRoute exact path={isACHMandated ? `${match?.path}/ach-details` : `${match?.path}/card`} component={isACHMandated ? ACHDetails : CardDetails} />
            <PrivateRoute exact path={`${match?.path}/emails`} component={EmailLogs} />
            <PrivateRoute exact path={`${match?.path}/tax-validation`} component={TaxValidationTable} />
            <PrivateRoute exact path={`${match?.path}/notes`} component={CustomerNotes} />
            <PrivateRoute exact path={`${match?.path}/languages`} component={CustomerLanguages} />
          </div>
        )}
      />
    </div>
  );
};


export default CustomerDetailsTabs;
