import * as React from "react";
import "./ElementModifiers.scss";

export interface IElementModifiersProps {
  isShowLockIcon?: boolean,
  onEditClick?: (evt: any) => void,
  onDeleteClick?: (evt: any) => void,
  className?: string
}

const ElementModifiers: React.FC<IElementModifiersProps> = React.memo(({ isShowLockIcon = false, onEditClick, onDeleteClick, className }) => {

  return (
    <div className={`element-modifiers ${className}`}>
      {onEditClick ?
        <i className="element-modifiers__edit fa fa-edit" onClick={onEditClick} />
        : null}
      {onDeleteClick ?
        <i className="element-modifiers__delete far fa-trash-alt" onClick={onDeleteClick} />
        : null}
      {isShowLockIcon ?
        <i className="element-modifiers__lock fas fa-lock-alt" />
        : null}
    </div>
  );
});

export default ElementModifiers;