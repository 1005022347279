import { BillsbyAction } from "../models/BillsbyAction";
import { AppState } from "..";
import { InvoiceStandaloneSidebarCurrentScreen } from "../models/InvoiceStandalone";
import { SET_INVOICE_STANDALONE_FIELD, FETCH_INVOICE_STANDALONE_SUCCESS, FETCH_INVOICE_STANDALONE_REQUEST, FETCH_INVOICE_STANDALONE_FAILURE, REATTEMPT_INVOICE_PAYMENT_REQUEST, REATTEMPT_INVOICE_PAYMENT_SUCCESS, REATTEMPT_INVOICE_PAYMENT_FAILURE, REPLACE_PAYMENT_CARD_REQUEST, REPLACE_PAYMENT_CARD_SUCCESS, REPLACE_PAYMENT_CARD_FAILURE, FETCH_CUSTOMER_LANGUAGE_SUCCESS } from "../actions/invoiceStandaloneActions";
import { IInvoiceDetails } from "../models/Customer";
import { ISpreedlyCardData } from "../models/Spreedly";
import { GetCustomerLanguageResponse } from "../utils/grpc/generated/Billsby.Protos/billing/public/customer/customer_pb";

export interface IInvoiceStandaloneReducerState {
  sidebarCurrentScreen: InvoiceStandaloneSidebarCurrentScreen,
  invoice?: IInvoiceDetails,
  isFetchingInvoice: boolean,
  isFetchingInvoiceSuccess: boolean,
  isFetchingInvoiceFailure: boolean,
  cardHolderName: string,
  expDateMM: string,
  expDateYYYY: string,
  newSpreedlyCardData?: ISpreedlyCardData,
  reattemptPaymentInvoiceRequest: boolean,
  reattempPaymentInvoiceSuccess: boolean,
  reattemptPaymentInvoiceFailure: boolean,
  replacePaymentCardRequest: boolean,
  replacePaymentCardSuccess: boolean,
  replacePaymentCardFailure: boolean,
  customerLanguage?: string
}

export const initialState = {
  sidebarCurrentScreen: InvoiceStandaloneSidebarCurrentScreen.HOME,
  isFetchingInvoice: false,
  isFetchingInvoiceSuccess: false,
  isFetchingInvoiceFailure: false,
  cardHolderName: "",
  expDateMM: "",
  expDateYYYY: "",
  reattemptPaymentInvoiceRequest: false,
  reattempPaymentInvoiceSuccess: false,
  reattemptPaymentInvoiceFailure: false,
  replacePaymentCardRequest: false,
  replacePaymentCardSuccess: false,
  replacePaymentCardFailure: false
}

export default function invoiceStandaloneReducer(state: IInvoiceStandaloneReducerState = initialState, action: BillsbyAction, store: AppState) {
  switch (action.type) {
    case SET_INVOICE_STANDALONE_FIELD:
      return { ...state, [action.fieldName]: action.fieldValue }
    case FETCH_INVOICE_STANDALONE_REQUEST:
      return { ...state, isFetchingInvoice: true, isFetchingInvoiceSuccess: false, isFetchingInvoiceFailure: false }
    case FETCH_INVOICE_STANDALONE_SUCCESS:
      return { ...state, invoice: action.response, isFetchingInvoice: false, isFetchingInvoiceSuccess: true, isFetchingInvoiceFailure: false }
    case FETCH_INVOICE_STANDALONE_FAILURE:
      return { ...state, isFetchingInvoice: false, isFetchingInvoiceSuccess: false, isFetchingInvoiceFailure: true }
    case REATTEMPT_INVOICE_PAYMENT_REQUEST:
      return { ...state, reattemptPaymentInvoiceRequest: true, reattempPaymentInvoiceSuccess: false, reattemptPaymentInvoiceFailure: false }
    case REATTEMPT_INVOICE_PAYMENT_SUCCESS:
      return { ...state, reattemptPaymentInvoiceRequest: false, reattempPaymentInvoiceSuccess: true, reattemptPaymentInvoiceFailure: false }
    case REATTEMPT_INVOICE_PAYMENT_FAILURE:
      return { ...state, reattemptPaymentInvoiceRequest: false, reattempPaymentInvoiceSuccess: false, reattemptPaymentInvoiceFailure: true }
    case REPLACE_PAYMENT_CARD_REQUEST:
      return { ...state, replacePaymentCardRequest: true, replacePaymentCardSuccess: false, replacePaymentCardFailure: false }
    case REPLACE_PAYMENT_CARD_SUCCESS:
      return { ...state, replacePaymentCardRequest: false, replacePaymentCardSuccess: true, replacePaymentCardFailure: false }
    case REPLACE_PAYMENT_CARD_FAILURE:
      return { ...state, replacePaymentCardRequest: false, replacePaymentCardSuccess: false, replacePaymentCardFailure: true }
    case FETCH_CUSTOMER_LANGUAGE_SUCCESS:
      const response = action.response as GetCustomerLanguageResponse;
      const locale = response.getIsoCode()
      return { ...state, customerLanguage: locale }
    default:
      return state;
  }
}