/**
 * @fileoverview gRPC-Web generated client stub for billing.private.subscription.discount
 * @enhanceable
 * @public
 */

// GENERATED CODE -- DO NOT EDIT!


import * as grpcWeb from "grpc-web";


import {
  AddCouponDiscountRequest,
  AddCouponDiscountResponse,
  GetDiscountsRequest,
  GetDiscountsResponse,
  RemoveDiscountRequest,
  RemoveDiscountResponse} from "./discount_pb";

export class SubscriptionDiscountServiceClient {
  client_: grpcWeb.AbstractClientBase;
  hostname_: string;
  credentials_: null | { [index: string]: string; };
  options_: null | { [index: string]: string; };

  constructor (hostname: string,
               credentials?: null | { [index: string]: string; },
               options?: null | { [index: string]: string; }) {
    if (!options) options = {};
    if (!credentials) credentials = {};
    options["format"] = "text";

    this.client_ = new grpcWeb.GrpcWebClientBase(options);
    this.hostname_ = hostname;
    this.credentials_ = credentials;
    this.options_ = options;
  }

  methodInfoGetDiscounts = new grpcWeb.AbstractClientBase.MethodInfo(
    GetDiscountsResponse,
    (request: GetDiscountsRequest) => {
      return request.serializeBinary();
    },
    GetDiscountsResponse.deserializeBinary
  );

  getDiscounts(
    request: GetDiscountsRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: GetDiscountsResponse) => void) {
    return this.client_.rpcCall(
      this.hostname_ +
        "/billing.private.subscription.discount.SubscriptionDiscountService/GetDiscounts",
      request,
      metadata || {},
      this.methodInfoGetDiscounts,
      callback);
  }

  methodInfoRemoveDiscount = new grpcWeb.AbstractClientBase.MethodInfo(
    RemoveDiscountResponse,
    (request: RemoveDiscountRequest) => {
      return request.serializeBinary();
    },
    RemoveDiscountResponse.deserializeBinary
  );

  removeDiscount(
    request: RemoveDiscountRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: RemoveDiscountResponse) => void) {
    return this.client_.rpcCall(
      this.hostname_ +
        "/billing.private.subscription.discount.SubscriptionDiscountService/RemoveDiscount",
      request,
      metadata || {},
      this.methodInfoRemoveDiscount,
      callback);
  }

  methodInfoAddCouponDiscount = new grpcWeb.AbstractClientBase.MethodInfo(
    AddCouponDiscountResponse,
    (request: AddCouponDiscountRequest) => {
      return request.serializeBinary();
    },
    AddCouponDiscountResponse.deserializeBinary
  );

  addCouponDiscount(
    request: AddCouponDiscountRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: AddCouponDiscountResponse) => void) {
    return this.client_.rpcCall(
      this.hostname_ +
        "/billing.private.subscription.discount.SubscriptionDiscountService/AddCouponDiscount",
      request,
      metadata || {},
      this.methodInfoAddCouponDiscount,
      callback);
  }

}

