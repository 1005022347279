import React, { useMemo } from "react";
import StepCollapsablePanel from "../../ui/step-collapsable-panel/StepCollapsablePanel";
import Button from "../../ui/button/Button";
import { Col, Row } from "react-grid-system";
import Dropdown from "../../ui/dropdown/Dropdown";
import Checkbox from "../../ui/checkbox/Checkbox";
import Text from "../../ui/text/Text";
import Panel from "../../ui/panel/Panel";
import FormGroup from "../../ui/form-group/FormGroup";
import FormLabel from "../../ui/form-label/FormLabel";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../../..";
import { IProductReducerState } from "../../../reducers/productsReducer";
import counterpart from "counterpart";
import { setProductAddressCollapsed, discardAddressOptions, setIsShippingAddressRequired, setIsWorldWideAddressAllowed, setSelectedCountryAddress, removeSelectedCountryAddress, addSelectedCountryAddress, applyAddressOptions, setSelectedCountryAllowBillingShippingAddress } from "../../../actions/productPlanCycleActions";
import "./CreateProductAddressValidator.scss";


const CreateProductAddressValidator: React.FC = () => {
  const { isReorderingCustomFields, isAddressCollapsed, addressOptionsTemp } = useSelector<AppState, IProductReducerState>(state => state.products);
  const dispatch = useDispatch<Function>()

  // there has to be at least one restricted country with both billing and shipping address, or one restricted country with the shipping and another one with the billing
  const isPermittedCountriesValid = useMemo(() => {
    if (addressOptionsTemp.isWorldWideAddressAllowed) {
      return true;
    }
    const hasBothAddressesDisabled = addressOptionsTemp.permittedCountries.find(country => !country.value.allowBillingAddress && !country.value.allowShippingAddress);
    if(hasBothAddressesDisabled) {
      return false
    }
    const hasAllowedBillingAddress = addressOptionsTemp.permittedCountries.some(country => country.value.allowBillingAddress);
    const hasAllowedShippingAddress = addressOptionsTemp.permittedCountries.some(country => country.value.allowShippingAddress);
    return !addressOptionsTemp.isShippingAddressRequired ? hasAllowedBillingAddress : hasAllowedBillingAddress && hasAllowedShippingAddress;
  }, [addressOptionsTemp]);

  const sortedPermittedCountries = useMemo(() => {
    return addressOptionsTemp.permittedCountries.sort((a, b) => {
      if (a.label.toUpperCase() < b.label.toUpperCase()) {
        return -1
      }
      if (a.label.toUpperCase() > b.label.toUpperCase()) {
        return 1
      }
      return 0
    })
  }, [addressOptionsTemp]);

  const filteredCountryOptions = useMemo(() => {
    return addressOptionsTemp.countries.filter((c) => {
      return !sortedPermittedCountries.find(x => x.value.iso3Code === c.value.iso3Code)
    })
  }, [sortedPermittedCountries]);

  const renderPermittedCountries = () => {
    return sortedPermittedCountries.map((country) => {
      return <Panel key={country.value.iso3Code} className="address-validator__country-panel" noMargin>
        <Text noMargin key={country.label} content={country.label} shouldTranslate={false}></Text>
        <div className="address-validator__country-panel__controls">
          <Checkbox
            className="checkbox-billing"
            id={`${country.value.iso3Code}_billing`}
            checked={country.value.allowBillingAddress}
            content={counterpart("CREATE_PRODUCT_INFO_ADDRESS_VALIDATING_CHECKBOX_BILLING")}
            value=""
            onClick={() => dispatch(setSelectedCountryAllowBillingShippingAddress({ countryCode: country.value.iso3Code, prop: "allowBillingAddress" }))}
            noMargin
          />
          <Checkbox
            className="checkbox-shipping"
            id={`${country.value.iso3Code}_shipping`}
            checked={country.value.allowShippingAddress}
            content={counterpart("CREATE_PRODUCT_INFO_ADDRESS_VALIDATING_CHECKBOX_SHIPPING")}
            value=""
            onClick={() => dispatch(setSelectedCountryAllowBillingShippingAddress({ countryCode: country.value.iso3Code, prop: "allowShippingAddress" }))}
            noMargin
          />
          <div className="address-validator__country-panel__controls__delete" onClick={() => dispatch(removeSelectedCountryAddress(country))}>
            <i className="far fa-fw fa-trash-alt" />
          </div>
        </div>
      </Panel>
    })
  }

  return (
    <StepCollapsablePanel
      title="CREATE_PRODUCT_INFO_ADDRESS_TITLE"
      subTitle="CREATE_PRODUCT_INFO_ADDRESS_SUBTITLE"
      className={`address-validator ${isReorderingCustomFields ? "create-product__info-body--hidden" : isAddressCollapsed ? "create-product__info-body--without-opacity" : ""}`}
      isCollapsed={isAddressCollapsed}
      hasHelpSection={false}
      isShowLockIcon
      onEditClick={() => {
        dispatch(setProductAddressCollapsed(!isAddressCollapsed));
        if (!isAddressCollapsed) {
          dispatch(discardAddressOptions());
        }
      }}
    >
      <div>
        <Checkbox
          id="require-shipping-address"
          checked={addressOptionsTemp.isShippingAddressRequired}
          onClick={() => dispatch(setIsShippingAddressRequired(!addressOptionsTemp.isShippingAddressRequired))}
          isHtml
          content={() => {
            return (
              <div className="address-validator__shipping-checkbox">
                <Text content="CREATE_PRODUCT_INFO_ADDRESS_CHECKBOX_REQUIRED_SHIPPING_TITLE" noMargin component="p" />
                <Text content="CREATE_PRODUCT_INFO_ADDRESS_CHECKBOX_REQUIRED_SHIPPING_SUB_TITLE" noMargin component="p" />
              </div>
            );
          }}
          value=""
        />
        {/*
        // this functionality has been removed
        <Panel title="CREATE_PRODUCT_INFO_ADDRESS_VALIDATING_TITLE" className="address-validator__body">
          <StatusLabel color={COLOR.ORANGE} content="CREATE_PRODUCT_INFO_ADDRESS_VALIDATING_STATUS" className="address-validator__premiumlabel" />
          <Text content="CREATE_PRODUCT_INFO_ADDRESS_VALIDATING_TEXT" />
          <Row>
            <Col md={3}>
              <Checkbox checked={addressOptionsTemp.isBillingAddressValidationRequired} content={counterpart("CREATE_PRODUCT_INFO_ADDRESS_VALIDATING_CHECKBOX_BILLING")} value="" onClick={() => dispatch(setIsBillingAddressValidationRequired(!addressOptionsTemp.isBillingAddressValidationRequired))} noMargin />
            </Col>
            <Col md={3}>
              <Checkbox checked={addressOptionsTemp.isShippingAddressValidationRequired} content={counterpart("CREATE_PRODUCT_INFO_ADDRESS_VALIDATING_CHECKBOX_SHIPPING")} value="" onClick={() => dispatch(setIsShippingAddressValidationRequired(!addressOptionsTemp.isShippingAddressValidationRequired))} noMargin />
            </Col>
          </Row>
        </Panel>*/}
        <Checkbox id="allow-all-countries" checked={addressOptionsTemp.isWorldWideAddressAllowed} content={counterpart("CREATE_PRODUCT_INFO_ADDRESS_CHECKBOX_ALL_WORLD")} value="" onClick={() => dispatch(setIsWorldWideAddressAllowed(!addressOptionsTemp.isWorldWideAddressAllowed))} />
        {
          !addressOptionsTemp.isWorldWideAddressAllowed && (
            <FormGroup>
              <Panel title="CREATE_PRODUCT_INFO_ADDRESS_PERMITTED_COUNTRIES">
                <Text content="CREATE_PRODUCT_INFO_ADDRESS_PERMITTED_COUNTRIES_SUBTITLE" />
                <FormGroup noMargin>
                  <Row align="center">
                    <Col xs={1}>
                      <FormLabel target="" content="CREATE_PRODUCT_INFO_ADDRESS_PERMITTED_COUNTRIES_COUNTRY" noMargin />
                    </Col>
                    <Col xs={9}>
                      <Dropdown
                        id="countries"
                        dividerAfter={4}
                        placeholder={counterpart("CREATE_PRODUCT_INFO_ADDRESS_PERMITTED_SELECT_COUNTRY")}
                        onChange={(country: any) => dispatch(setSelectedCountryAddress(country))}
                        options={filteredCountryOptions}
                        resetDependencies={[sortedPermittedCountries]}
                        value={addressOptionsTemp.selectedCountry}
                      />
                    </Col>
                    <Col xs={2}>
                      <Button
                        id="add-delete-permitted-country"
                        buttonType={"add"}
                        disabled={!addressOptionsTemp.selectedCountry}
                        title="CREATE_PRODUCT_INFO_ADDRESS_PERMITTED_COUNTRIES_ADD"
                        onClick={() => {
                          dispatch(addSelectedCountryAddress(addressOptionsTemp.selectedCountry));
                          dispatch(setSelectedCountryAddress(undefined))
                        }}
                        isFullWidth
                      />
                    </Col>
                  </Row>
                </FormGroup>
                <Row>
                  <Col xs={1} />
                  <Col xs={11}>
                    {renderPermittedCountries()}
                  </Col>
                </Row>
              </Panel>
            </FormGroup>
          )
        }

        <Row>
          <Col md={10}>
            <Button
              id="update-address-settings-ok"
              title="CREATE_PRODUCT_INFO_ADDRESS_UPDATE_BTN"
              onClick={() => dispatch(applyAddressOptions())}
              disabled={!isPermittedCountriesValid}
              isFullWidth
              tabIndex={-1}
            />
          </Col>
          <Col md={2}>
            <Button
              id="update-address-settings-cancel"
              buttonType="error"
              title="CREATE_PRODUCT_INFO_ADDRESS_CANCEL"
              onClick={() => dispatch(discardAddressOptions())}
              isFullWidth
              tabIndex={-1}
            />
          </Col>
        </Row>
      </div>
    </StepCollapsablePanel>
  )
}

export default CreateProductAddressValidator