import React from "react";
import FormGroup from "../../../ui/form-group/FormGroup";
import { Row, Col } from "react-grid-system";
import Input from "../../../ui/input/Input";
import counterpart from "counterpart";
import FormLabel from "../../../ui/form-label/FormLabel";
import Button from "../../../ui/button/Button";
import Panel from "../../../ui/panel/Panel";
import Text from "../../../ui/text/Text";
import "./DropdownOptions.scss";
import ElementModifiers from "../../../ui/element-modifiers/ElementModifiers";
import DragDropContainer from "../../../ui/drag-drop-container/DragDropContainer";

interface IDropdownOptions {
  optionValue: string,
  optionValueOnChange: (evt: any) => void,
  optionValueOnFocus: () => void,
  optionValueOnDelete: (index: number) => void,
  optionListOnChange: (newList: Array<any>) => void,
  onAdd: () => any,
  options: Array<string>,
  optionsSource: Array<string>,
}

const getOptionDraggablePanel = (text: string, optionsSource: Array<string>, index: number, onDelete: Function) => {

  const isPresent = optionsSource.includes(text)

  return (
    <Panel className="dropdown-options__draggable-panel">
      <div>
        <i className="fas fa-grip-horizontal" />
        <Text content={text} shouldTranslate={false} noMargin />
      </div>
      {
        !isPresent ?
        <ElementModifiers
          onDeleteClick={() => onDelete(index)}
        />
        : null
      }
    </Panel>
  )
}

const DropdownOptions: React.FC<IDropdownOptions> = ({ optionValue, optionsSource, optionValueOnChange, optionValueOnFocus, optionValueOnDelete, optionListOnChange, onAdd, options }) => {
  return (
    <div className="dropdown-options">
      <FormGroup>
        <Row align="center">
          <Col xs={2}>
            <FormLabel target="field-type-options" content="CREATE_CUSTOM_FIELDS_DROPDOWN_OPTIONS" />
          </Col>
          <Col xs={8}>
            <Input
              id="field-label-value"
              placeholder={counterpart("CREATE_CUSTOM_FIELDS_DROPDOWN_OPTIONS_PLACEHOLDER")}
              value={optionValue}
              onChange={optionValueOnChange}
              onFocus={optionValueOnFocus}
            />
          </Col>
          <Col xs={2}>
            <Button
              id="field-option-add"
              buttonType="add"
              title="CREATE_CUSTOM_FIELDS_DROPDOWN_OPTIONS_ADD"
              onClick={onAdd}
              isFullWidth
            />
          </Col>
        </Row>
      </FormGroup>

      <FormGroup className="dropdown-options__list">
        <Row align="center">
          <Col xs={2} />
          <Col xs={10}>
            <DragDropContainer
              list={options.map((opt, idx) => ({ content: getOptionDraggablePanel(opt, optionsSource, idx, optionValueOnDelete), value: opt }))}
              onDragEnd={(newList: Array<any>) => optionListOnChange(newList)}
            />
          </Col>
        </Row>
      </FormGroup>
    </div>
  )
}

export default DropdownOptions;