import React from "react";
import "./NavigationBar.scss";
import Text from "../text/Text";

interface INavigationBar {
  previousPageTitle?: string,
  className?: string,
  shouldTranslatePreviousPageTitle?: boolean,
  previousPageCb?: () => void,
  pageTitle: string | JSX.Element,
  shouldTranslatePageTitle?: boolean
}

const NavigationBar: React.FC<INavigationBar> = React.memo(({ previousPageTitle = "", className = "", shouldTranslatePreviousPageTitle = true,
  previousPageCb = () => { }, pageTitle = "", shouldTranslatePageTitle = true }) => {

  return (
    <div className={`navigation-bar ${className}`}>
      <div className="navigation-bar__wrapper">
        <div className="navigation-bar__previouspage" onClick={previousPageCb}>
          <i className="far fa-chevron-left" />
          <Text content={previousPageTitle} shouldTranslate={shouldTranslatePreviousPageTitle} noMargin />
        </div>
        <Text content={pageTitle} shouldTranslate={shouldTranslatePageTitle} noMargin />
      </div>
    </div>
  );
}
);

export default NavigationBar;
