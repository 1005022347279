import React, { useState, useEffect } from "react"
import { AppState } from "../../../..";
import { useDispatch, useSelector } from "react-redux";
import "./UpdateShippingAddress.scss";
import Panel from "../../../../components/ui/panel/Panel";
import Button from "../../../ui/button/Button";
import AddressForm from "../../../address-form/AddressForm";
import { updateSubscriptionShippingAddress, setSubscriptionShippingAddress, setShowShippingAddressUpdateModal } from "../../../../actions/shippingAddressActions";


const UpdateShippingAddress: React.FC = () => {
  const currentCompanyDomain = useSelector((state: AppState) => state.auth.currentCompanyDomain);
  const subscriptionUniqueId = useSelector((state: AppState) => state.subscriptionDetailsReducer.subscriptionUniqueId);
  const shippingAddressData = useSelector((state: AppState) => state.shippingAddressReducer);

  const dispatch = useDispatch<Function>();

  const { addressLine1, addressLine2, city, state, postCode, country } = shippingAddressData;
  const [shippingAddress, setShippingAddress] = useState({
    addressLine1: "",
    addressLine2: "",
    addressState: { value: "", label: "" },
    addressCity: "",
    addressPostCode: "",
    addressCountry: { value: "", label: "" },
    isCountryLocked: false,
    isLoading: false,
    countries: [],
    isLoadingData: true
  });

  useEffect(() => {
    setShippingAddress({
      ...shippingAddress,
      addressLine1: !!addressLine1 ? addressLine1 : "",
      addressLine2: !!addressLine2 ? addressLine2 : "",
      addressCity: !!city ? city : "",
      addressCountry: !!country ? { value: country, label: "" } : { value: "", label: "" },
      addressState: !!state ? { value: state, label: "" } : { value: "", label: "" },
      addressPostCode: !!postCode ? postCode : "",
      isLoadingData: false
    });
  }, [shippingAddressData]);

  const isFormNotValid = () => {
    return (
      !shippingAddress.addressLine1 || !shippingAddress.addressState || !shippingAddress.addressCountry ||
      !shippingAddress.addressCity || !shippingAddress.addressPostCode
    );
  };

  const onSaveShippingAddress = async () => {
    if (isFormNotValid()) {
      return;
    }

    setShippingAddress({ ...shippingAddress, isLoading: true });

    const addressData = {
      addressLine1: shippingAddress.addressLine1,
      addressLine2: shippingAddress.addressLine2,
      city: shippingAddress.addressCity,
      postCode: shippingAddress.addressPostCode,
      state: shippingAddress.addressState.value,
      country: shippingAddress.addressCountry.value
    };

    try {
      await dispatch(updateSubscriptionShippingAddress(currentCompanyDomain, subscriptionUniqueId, addressData));
      dispatch(setSubscriptionShippingAddress(addressData));
      dispatch(setShowShippingAddressUpdateModal(false));
    } catch (err) {
      setShippingAddress({ ...shippingAddress, isLoading: false });
      console.log(err);
    }
  };

  return (
    <>
      <Panel title="UPDATE_SUBSCRIPTION_SHIPPING_ADDRESS_PANEL_TITLE">
        <AddressForm
          onChangeAddressLine1={(line1) => setShippingAddress({ ...shippingAddress, addressLine1: line1 })}
          onChangeAddressLine2={(line2) => setShippingAddress({ ...shippingAddress, addressLine2: line2 })}
          onChangeAddressState={(_state: any) => {
            setShippingAddress(prev => ({ ...prev, addressState: _state }));
          }}
          onChangeAddressCity={(city) => setShippingAddress({ ...shippingAddress, addressCity: city })}
          onChangeAddressPostCode={(code) => setShippingAddress({ ...shippingAddress, addressPostCode: code  })}
          onChangeAddressCountry={(country: any) => setShippingAddress(prev => ({ ...prev, addressCountry: country }))}
          formErrors={false}
          formData={{
            addressLine1: shippingAddress.addressLine1,
            addressLine2: shippingAddress.addressLine2,
            addressCity: shippingAddress.addressCity,
            addressPostCode: shippingAddress.addressPostCode,
            addressState: shippingAddress.addressState,
            addressCountry: shippingAddress.addressCountry
          }}
        />
      </Panel>
      <Button
        id="update-shipping-address-btn"
        disabled={isFormNotValid()}
        type="button"
        onClick={() => onSaveShippingAddress()}
        buttonType={"general"}
        isLoading={shippingAddress.isLoading}
        title={"UPDATE_SUBSCRIPTION_SHIPPING_ADDRESS_SAVE_CHANGES"}
        isFullWidth
      />
    </>
  )
}

export default UpdateShippingAddress;