import React, { useEffect, useState } from "react";
import NavigationAlert from "../../../ui/navigation-alert/NavigationAlert";
import history from "../../../../utils/history";
import NavigationBar from "../../../ui/navigation-bar/NavigationBar";
import PageWrapper from "../../../ui/layout/PageWrapper";
import Panel from "../../../ui/panel/Panel";
import Text from "../../../ui/text/Text";
import "./BrandCustomization.scss";
import { AppState } from "../../../..";
import { useDispatch, useSelector } from "react-redux";
import { openIntercomIframe } from "../../../../utils/commonUtils";
import { fetchCompanyBranding, fetchGoogleFonts, resetCompanyBranding } from "../../../../actions/companyBrandingActions";
import { GettingStartedType } from "../../../../models/GettingStarted";
import ProgressIndicator from "../../../ui/progress-indicator/ProgressIndicator";
import { setUserInterfaceField } from "../../../../actions/userInteraceActions";
import BrandCustomizationTabs from "./brand-customization-tabs/BrandCustomizationTabs";

const BrandCustomization: React.FC = () => {
  const authReducer = useSelector((state: AppState) => state.auth);

  const dispatch = useDispatch<Function>();

  const [isLoadingData, setIsLoadingData] = useState(true)
  const { currentCompanyDomain, parsedToken, dashboardSettings } = authReducer;

  useEffect(() => {
    const loadData = async () => {
      setIsLoadingData(true)
      try {
        dispatch(setUserInterfaceField("autoScroll", false));
        await dispatch(fetchCompanyBranding(currentCompanyDomain));
        await dispatch(fetchGoogleFonts());
      }
      catch (err) { }
      finally {
        setIsLoadingData(false);
      }
    }
    loadData();

    return () => {
      dispatch(setUserInterfaceField("autoScroll", true));
      dispatch(resetCompanyBranding())
    };
  }, [])


  if (isLoadingData) {
    return <ProgressIndicator color="blue" coverage="full-content"></ProgressIndicator>
  }

  return (
    <div className="brand_customization">
      <NavigationBar pageTitle="" previousPageTitle="CONFIGURATION_PAGE_TITLE" previousPageCb={() => history.push("/configuration")} />

      <NavigationAlert
        title="BRAND_CUSTOMIZATION_ALERT_TITLE"
        translateTitleWith={{ givenName: parsedToken !== null ? parsedToken.given_name : "" }}
        subTitle="BRAND_CUSTOMIZATION_ALERT_SUB_TITLE"
        type="warning"
        buttonLabel="BRAND_CUSTOMIZATION_ALERT_BUTTON_TITLE"
        icon="fas fa-headset"
        onClick={openIntercomIframe}
        isVisible={!!dashboardSettings && dashboardSettings.status === GettingStartedType.GettingStarted2}
      />

      <PageWrapper className="brand_customization__panel">
        <Panel title="BRAND_CUSTOMIZATION_MENU_ITEM_TITLE" className="brand_customization__panel__header">
          <Text content="BRAND_CUSTOMIZATION_MENU_ITEM_CONTENT" noMargin />
        </Panel>

         <BrandCustomizationTabs />

      </PageWrapper>

    </div>
  );
}

export default BrandCustomization;
