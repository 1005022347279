import React, { useState, useEffect } from "react";
import BackNavigationPanel from "../../../../../ui/back-navigation-panel/BackNavigationPanel";
import MenuTable from "../../../../../ui/menu-table/MenuTable";
import { EmailTypes, UpdateEmailNotificationStatusRequest, UpdateEmailNotificationStatusResponse } from "../../../../../../utils/grpc/generated/Billsby.Protos/Messenger/private/companyconfig/companyconfig_pb";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../../../../../..";
import { updateEmailNotificationToggles, fetchEmailNotificationToggles } from "../../../../../../actions/emailRoutingActions";
import { useHistory } from "react-router";

const ExpiringPaymentMethodsMenu: React.FC = () => {
  const emailNotificationToggles = useSelector((state: AppState) => state.emailRoutingReducer.emailNotificationToggles);
  const currentCompanyId = useSelector((state: AppState) => state.auth.currentCompanyId);

  const dispatch = useDispatch<Function>();
  const history = useHistory();

  const [switchState, setSwitchState] = useState({
    sevenDaysBeforePaymentExpires: false,
    dayPaymentExpires: false,
    sevenDaysAfterPaymentExpires: false
  });

  const updateData = async (updateEmailNotification: UpdateEmailNotificationStatusRequest) => {
    try {
      await (dispatch(updateEmailNotificationToggles(updateEmailNotification)) as { response: UpdateEmailNotificationStatusResponse });
      dispatch(fetchEmailNotificationToggles(Number(currentCompanyId)));
    }
    catch (err) { }
  }

  useEffect(() => {
    if (emailNotificationToggles.getEmailNotificationConfigsList().length) {
      let newState: any = {};
      emailNotificationToggles.getEmailNotificationConfigsList().forEach((elem) => {
        switch (elem.getEmailType()) {
          case EmailTypes.PAYMENT_METHOD_EXPIRING_SOON:
            newState.sevenDaysBeforePaymentExpires = elem.getIsEnabled();
            break;
          case EmailTypes.PAYMENT_METHOD_EXPIRED:
            newState.dayPaymentExpires = elem.getIsEnabled();
            break;
          case EmailTypes.NEW_PAYMENT_DETAILS_REQUIRED:
            newState.sevenDaysAfterPaymentExpires = elem.getIsEnabled();
            break;
        }
      })
      setSwitchState(newState);
    }
  }, [emailNotificationToggles]);

  useEffect(() => {
    dispatch(fetchEmailNotificationToggles(Number(currentCompanyId)));
  }, []);


  return (
    <div>
      <BackNavigationPanel
        title={"EMAIL_TEMPLATE_EXPIRING_PAYMENT_METHOD_TEMPLATE_BACK_PANEL_TITLE"} shouldTranslateTitle={true}
        onClick={() => history.push("/configuration/email-notifications/emails")}>
        <MenuTable
          data={[
            {
              title: "EMAIL_TEMPLATE_EXPIRING_PAYMENT_METHOD_SEVEN_DAYS_BEFORE_A_PAYMENT_METHOD_EXPIRES_TEMPLATE",
              onClick: () => history.push("/configuration/email-notifications/emails/expiring-payment-methods/seven-days-before-payment-method-expires-email-template"),
              canConfigureNotification: true,
              enableNotification: switchState.sevenDaysBeforePaymentExpires,
              enableNotificationChange: (isChecked) => {
                setSwitchState({ ...switchState, sevenDaysBeforePaymentExpires: isChecked });
                const updateEmailNotification = new UpdateEmailNotificationStatusRequest();
                updateEmailNotification.setCompanyId(Number(currentCompanyId));
                updateEmailNotification.setEmailType(EmailTypes.PAYMENT_METHOD_EXPIRING_SOON);
                updateEmailNotification.setIsEnabled(isChecked);
                updateData(updateEmailNotification);
              }
            },
            {
              title: "EMAIL_TEMPLATE_EXPIRING_PAYMENT_METHOD_THE_DAY_PAYMENT_METHOD_EXPIRES_TEMPLATE",
              onClick: () => history.push("/configuration/email-notifications/emails/expiring-payment-methods/the-day-payment-method-expires-email-template"),
              canConfigureNotification: true,
              enableNotification: switchState.dayPaymentExpires,
              enableNotificationChange: (isChecked) => {
                setSwitchState({ ...switchState, dayPaymentExpires: isChecked });
                const updateEmailNotification = new UpdateEmailNotificationStatusRequest();
                updateEmailNotification.setCompanyId(Number(currentCompanyId));
                updateEmailNotification.setEmailType(EmailTypes.PAYMENT_METHOD_EXPIRED);
                updateEmailNotification.setIsEnabled(isChecked);
                updateData(updateEmailNotification);
              }
            },
            {
              title: "EMAIL_TEMPLATE_EXPIRING_PAYMENT_METHOD_SEVEN_DAYS_AFTER_A_PAYMENT_METHOD_EXPIRES_TEMPLATE",
              onClick: () => history.push("/configuration/email-notifications/emails/expiring-payment-methods/seven-days-after-payment-method-expires-email-template"),
              canConfigureNotification: true,
              enableNotification: switchState.sevenDaysAfterPaymentExpires,
              enableNotificationChange: (isChecked) => {
                setSwitchState({ ...switchState, sevenDaysAfterPaymentExpires: isChecked });
                const updateEmailNotification = new UpdateEmailNotificationStatusRequest();
                updateEmailNotification.setCompanyId(Number(currentCompanyId));
                updateEmailNotification.setEmailType(EmailTypes.NEW_PAYMENT_DETAILS_REQUIRED);
                updateEmailNotification.setIsEnabled(isChecked);
                updateData(updateEmailNotification);
              }
            }
          ]}
        />
      </BackNavigationPanel>
    </div>
  )
}

export default ExpiringPaymentMethodsMenu;
