import { BillsbyAction } from "../../models/BillsbyAction";
import { AppState } from "../..";
import { CREATE_COUPON_SUCCESS, CREATE_COUPON_REQUEST, CREATE_COUPON_FAILURE, EDIT_COUPON_REQUEST, SET_CREATE_COUPON_BASIC_INFO, SET_COUPON_FORM_COMPLETED, SET_COUPON_STEPS_COLLAPSED, SET_COUPON_FORM_HAS_ERROR, RESET_CREATE_COUPON_STATE, FETCH_COUPON_DETAILS_SUCCESS, FETCH_COUPON_USAGE_SUCCESS } from "../../actions/discount-coupon/createCouponActions";
import { EligibleCustomers, CreateCouponRequest, FrequencyType, GetCouponResponse } from "../../utils/grpc/generated/Billsby.Protos/core/private/coupons/coupons_pb";
import counterpart from "counterpart";
import { Timestamp } from "google-protobuf/google/protobuf/timestamp_pb";
import { getFrequencyText } from "../../utils/commonUtils";
import { FrequencyType as ProductFrequencyType } from "../../models/Product";
import { GetCouponUsageResponse } from "../../utils/grpc/generated/Billsby.Protos/billing/private/coupons/coupons_pb";

export interface ICreateCouponState {
  helpTextTitleBasicForm: string,
  helpTextContentBasicForm: Array<string>,
  helpTextTitleCouponTypeForm: string,
  helpTextContentCouponTypeForm: Array<string>,
  helpTextTitleHowCouponWorksForm: string,
  helpTextContentHowCouponWorksForm: Array<string>,
  name: string,
  stageCollapsed: Array<boolean>,
  stageStatuses: Array<boolean>,
  stageErrors: Array<boolean>,
  isCreatingCouponRequest: boolean,
  isCreatingCouponSuccess: boolean,
  isCreatingCouponFailure: boolean,
  eligibleCustomers?: { value: EligibleCustomers, label: string },
  couponType: CreateCouponRequest.CouponTypeCase,
  couponCode: string,
  limits?: number,
  extraCoupons?: number,
  hasExpiry: boolean,
  expirationDate?: Date,
  discountLength?: { value: CreateCouponRequest.DiscountLengthCase, label: string },
  frequency?: number,
  frequencyType?: { value: FrequencyType, label: string },
  numberGenerated?: number,
  addExtraCoupons: boolean,
  couponUsage?: number
}

export const initialState = {
  helpTextTitleBasicForm: "HELP_EMPTY_TITLE",
  helpTextContentBasicForm: ["HELP_EMPTY_1", "HELP_EMPTY_2"],
  helpTextTitleCouponTypeForm: "HELP_EMPTY_TITLE",
  helpTextContentCouponTypeForm: ["HELP_EMPTY_1", "HELP_EMPTY_2"],
  helpTextTitleHowCouponWorksForm: "HELP_EMPTY_TITLE",
  helpTextContentHowCouponWorksForm: ["HELP_EMPTY_1", "HELP_EMPTY_2"],
  stageCollapsed: [false, true, true, true, true],
  stageStatuses: [false, false, false, false],
  stageErrors: [false, false, false, false],
  isCreatingCouponRequest: false,
  isCreatingCouponSuccess: false,
  isCreatingCouponFailure: false,
  name: "",
  eligibleCustomers: undefined,
  couponType: CreateCouponRequest.CouponTypeCase.SINGLE_USE,
  couponCode: "",
  limits: undefined,
  extraCoupons: undefined,
  hasExpiry: false,
  expirationDate: undefined,
  discountLength: undefined,
  frequency: undefined,
  frequencyType: undefined,
  numberGenerated: undefined,
  addExtraCoupons: false,
  couponUsage: undefined
}

export default function createCouponReducer(state: ICreateCouponState = initialState, action: BillsbyAction, store: AppState) {
  switch (action.type) {
    case SET_CREATE_COUPON_BASIC_INFO:
      return { ...state, [action.fieldName]: action.fieldValue }
    case SET_COUPON_STEPS_COLLAPSED: {
      let panelToCollapse = action.payload;
      let newArray = [...state.stageCollapsed];

      newArray = newArray.map(el => (el !== panelToCollapse ? true : el));
      newArray[panelToCollapse] = false;

      return { ...state, stageCollapsed: newArray }
    }
    case SET_COUPON_FORM_COMPLETED: {
      let newArray = [...state.stageStatuses];
      newArray[action.stageIndex] = action.value;
      return { ...state, stageStatuses: newArray }
    }
    case SET_COUPON_FORM_HAS_ERROR: {
      let newArray = [...state.stageErrors];
      newArray[action.stageIndex] = action.value;
      return { ...state, stageErrors: newArray }
    }
    case CREATE_COUPON_REQUEST:
      return { ...state, isCreatingCouponRequest: true, isCreatingCouponSuccess: false, isCreatingCouponFailure: false }
    case CREATE_COUPON_SUCCESS:
      return { ...state, isCreatingCouponRequest: false, isCreatingCouponSuccess: true, isCreatingCouponFailure: false }
    case CREATE_COUPON_FAILURE:
      return { ...state, isCreatingCouponRequest: false, isCreatingCouponSuccess: false, isCreatingCouponFailure: true }

    case EDIT_COUPON_REQUEST:
      return { ...state, isCreatingCouponRequest: true, isCreatingCouponSuccess: false, isCreatingCouponFailure: false }

    case FETCH_COUPON_DETAILS_SUCCESS: {
      const data = action.response as GetCouponResponse;
      let eligibleCustomers: { value: EligibleCustomers, label: string } | undefined;
      if (data.getEligibleCustomers() === EligibleCustomers.NEW_CUSTOMERS) {
        eligibleCustomers = { value: EligibleCustomers.NEW_CUSTOMERS, label: counterpart("CREATE_COUPON_FORM_ELIGIBLE_CUSTOMERS_NEW_CUSTOMERS_ONLY") };
      } else if (data.getEligibleCustomers() === EligibleCustomers.EXISTING_CUSTOMERS) {
        eligibleCustomers = { value: EligibleCustomers.EXISTING_CUSTOMERS, label: counterpart("CREATE_COUPON_FORM_ELIGIBLE_CUSTOMERS_EXISTING_CUSTOMERS_ONLY") };
      } else if (data.getEligibleCustomers() === EligibleCustomers.ALL_CUSTOMERS) {
        eligibleCustomers = { value: EligibleCustomers.ALL_CUSTOMERS, label: counterpart("CREATE_COUPON_FORM_ELIGIBLE_CUSTOMERS_ALL_CUSTOMERS") };
      }

      let limits;
      let couponCode;
      let couponType;
      let generatedAmount;
      if (data.getCouponType() === GetCouponResponse.CouponType.MULTI_USE) {
        couponType = CreateCouponRequest.CouponTypeCase.MULTI_USE;
        if (data.getMultiUse()) {
          const multiUse = data.getMultiUse() as GetCouponResponse.MultiUse;
          couponCode = multiUse.getCouponCode();
          limits = multiUse.getUsesLimit();
        }
      } else {
        couponType = CreateCouponRequest.CouponTypeCase.SINGLE_USE;
        if (data.getSingleUse()) {
          const singleUse = data.getSingleUse() as GetCouponResponse.SingleUse;
          generatedAmount = singleUse.getGeneratedAmount();
        }
      }

      let expirationDate;
      let hasExpiry = false;
      if (data.getExpiresOn()) {
        const expiresOn = data.getExpiresOn() as Timestamp;
        expirationDate = expiresOn.toDate();
        hasExpiry = true;
      }

      let discountLength;
      let frequency;
      let frequencyType;

      if (data.getDiscountLengthCase() === GetCouponResponse.DiscountLengthCase.FIXED_TERM) {
        const fixedTerm = data.getFixedTerm() as GetCouponResponse.FixedTermDiscountLength;
        frequency = fixedTerm.getFrequency();

        if (fixedTerm.getFrequencyType() === FrequencyType.DAILY) {
          frequencyType = { label: getFrequencyText(frequency, ProductFrequencyType.Daily), value: FrequencyType.DAILY };
        } else if (fixedTerm.getFrequencyType() === FrequencyType.WEEKLY) {
          frequencyType = { label: getFrequencyText(frequency, ProductFrequencyType.Weekly), value: FrequencyType.WEEKLY };
        } else if (fixedTerm.getFrequencyType() === FrequencyType.MONTHLY) {
          frequencyType = { label: getFrequencyText(frequency, ProductFrequencyType.Monthly), value: FrequencyType.MONTHLY };
        } else if (fixedTerm.getFrequencyType() === FrequencyType.YEARLY) {
          frequencyType = { label: getFrequencyText(frequency, ProductFrequencyType.Yearly), value: FrequencyType.YEARLY };
        }

        discountLength = { value: CreateCouponRequest.DiscountLengthCase.FIXED_TERM, label: counterpart("CREATE_COUPON_FORM_COUPON_WORKS_DISCOUNT_LENGTH_FIXED_TERM") };
      } else if (data.getDiscountLengthCase() === GetCouponResponse.DiscountLengthCase.IS_LIFE_TIME) {
        discountLength = { value: CreateCouponRequest.DiscountLengthCase.IS_LIFETIME, label: counterpart("CREATE_COUPON_FORM_COUPON_WORKS_DISCOUNT_LENGTH_LIFETIME") };
      }

      return {
        ...state,
        name: data.getName(),
        eligibleCustomers: eligibleCustomers,
        couponType: couponType,
        couponCode: couponCode,
        limits: limits,
        hasExpiry: hasExpiry,
        expirationDate: expirationDate,
        discountLength: discountLength,
        frequency: frequency,
        frequencyType: frequencyType,
        numberGenerated: generatedAmount,
        stageStatuses: [true, true, true]
      }

    }
    case FETCH_COUPON_USAGE_SUCCESS: {
      const data = action.response as GetCouponUsageResponse;
      return {...state, couponUsage: data.getCodesUsed()}
    }
    case RESET_CREATE_COUPON_STATE:
      return { ...initialState }
    default:
      return state;
  }
}