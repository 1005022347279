/**
 * @fileoverview gRPC-Web generated client stub for core.public.company
 * @enhanceable
 * @public
 */

// GENERATED CODE -- DO NOT EDIT!


import * as grpcWeb from "grpc-web";

import {
  IsCompanyDomainUsedRequest,
  IsCompanyDomainUsedResponse} from "./company_pb";

export class CompanyServiceClient {
  client_: grpcWeb.AbstractClientBase;
  hostname_: string;
  credentials_: null | { [index: string]: string; };
  options_: null | { [index: string]: string; };

  constructor (hostname: string,
               credentials?: null | { [index: string]: string; },
               options?: null | { [index: string]: string; }) {
    if (!options) options = {};
    if (!credentials) credentials = {};
    options["format"] = "text";

    this.client_ = new grpcWeb.GrpcWebClientBase(options);
    this.hostname_ = hostname;
    this.credentials_ = credentials;
    this.options_ = options;
  }

  methodInfoIsCompanyDomainUsed = new grpcWeb.AbstractClientBase.MethodInfo(
    IsCompanyDomainUsedResponse,
    (request: IsCompanyDomainUsedRequest) => {
      return request.serializeBinary();
    },
    IsCompanyDomainUsedResponse.deserializeBinary
  );

  isCompanyDomainUsed(
    request: IsCompanyDomainUsedRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: IsCompanyDomainUsedResponse) => void) {
    return this.client_.rpcCall(
      this.hostname_ +
        "/core.public.company.CompanyService/IsCompanyDomainUsed",
      request,
      metadata || {},
      this.methodInfoIsCompanyDomainUsed,
      callback);
  }

}

