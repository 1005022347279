import React, { useState } from "react";
import Button from "../../../../../ui/button/Button";
import Panel from "../../../../../ui/panel/Panel";
import Text from "../../../../../ui/text/Text";
import Input from "../../../../../ui/input/Input";
import "./EmailAdvertisement.scss";
import FileUpload from "../../../../../ui/file-upload/FileUpload";
import { Row, Col } from "react-grid-system";
import Checkbox from "../../../../../ui/checkbox/Checkbox";
import Switch from "../../../../../ui/switch/Switch";
import counterpart from "counterpart";
import NoticePanel from "../../../../../ui/notice-panel/NoticePanel";

interface Props {
  title: string,
  linkUrl: string,
  logoUrl: string,
  hideAdvertisement: boolean,
  buttonText: string,
  id: string,
  noMargin?: boolean,
  isLoading?: boolean,
  canOverrideBaseAdvert?: boolean,
  overrideBaseAdvert?: boolean,
  hasIncludeAdvertOption?: boolean,
  onHideAdvertisementChange: () => void,
  onOverrideBaseAdvertChange?: () => void,
  onDrop: (files: Array<File>) => void,
  onLinkUrlChange: (evt: any) => any,
  onSubmit: () => void
}

export const EmailAdvertisement: React.FC<Props> = ({ onDrop, canOverrideBaseAdvert = false, overrideBaseAdvert = true, onOverrideBaseAdvertChange = () => { }, hideAdvertisement = false, hasIncludeAdvertOption = false, isLoading = false, title = "", linkUrl = "", logoUrl = "", buttonText = "", noMargin = false, id = "", onHideAdvertisementChange = () => { }, onSubmit = () => { }, onLinkUrlChange = () => { } }) => {
  const showAdvertSettings = canOverrideBaseAdvert ? overrideBaseAdvert : true;
  const noBottomPadding = (hasIncludeAdvertOption && hideAdvertisement) || (!overrideBaseAdvert && hideAdvertisement) ? "email-advertisement-input-no-padding-bottom" : "";
  const [errorMsg, setErrorMsg] = useState("");

  const _onDrop = async (files: Array<File>) => {
    setErrorMsg("")
    try {
      const response = await onDrop(files) as any;
      if (response.error) {
        setErrorMsg(response.error.list.length ? response.error.list[0].description : "Unknown error occured.");
      }
    } catch { }
  }

  return (
    <Panel title={title} shouldTranslate={false} noMargin={noMargin} className={noBottomPadding}>
      {hasIncludeAdvertOption && <Row className="email-advertisement-input__switch">
        <Col sm={4}>
          <Text className="email-advertisement-input__switch__label" content="EMAIL_FORMS_ADVERTISEMENT_INLCLUDE_AN_ADVERT" />
        </Col>
        <Col sm={8}>
          <Switch
            id="switch-free-quantity"
            checked={!hideAdvertisement}
            onChange={onHideAdvertisementChange}
            className="email-advertisement-input__switch-control"
          />
        </Col>
      </Row>}
      {canOverrideBaseAdvert && <Row className="email-advertisement-input__switch">
        <Col sm={4}>
          <Text className="email-advertisement-input__switch__label" content="EMAIL_FORMS_ADVERTISEMENT_OVERRIDE_ADVERT" />
        </Col>
        <Col sm={8}>
          <Switch
            id="switch-free-quantity"
            checked={overrideBaseAdvert}
            onChange={onOverrideBaseAdvertChange}
            className="email-advertisement-input__switch-control"
          />
        </Col>
      </Row>}
      {showAdvertSettings &&
        <>
          {!(hideAdvertisement && hasIncludeAdvertOption) &&
            <>
              <div className="email-advertisement-input__file-uploader">
                <FileUpload title={<>{counterpart("EMAIL_FORMS_ADVERTISEMENT_FILE_UPLOADER")} <a className="email-advertisement-input__file-uploader__browse">{counterpart("EMAIL_FORMS_ADVERTISEMENT_FILE_UPLOADER_BROWSE")}</a> </>}
                  subTitle=''
                  shouldTranslateTitle={false}
                  image={logoUrl}
                  onDrop={_onDrop}
                />
                {errorMsg && <NoticePanel type='error' content={errorMsg} shouldTranslate={false}></NoticePanel>}
              </div>
              <Row>
                <Col sm={3}>
                  <Text className="email-advertisement-input__link__label-blue" content="EMAIL_FORMS_ADVERTISEMENT_LINK_TO_URL" />
                </Col>
                <Col sm={9}>
                  <Input id={`subject-${id}`} value={linkUrl} onChange={onLinkUrlChange} placeholder={counterpart("EMAIL_FORMS_ADVERTISEMENT_YOUR_URL")} />
                </Col>
              </Row>
              {!hasIncludeAdvertOption && <Row>
                <Col sm={12}>
                  <Checkbox checked={hideAdvertisement} onClick={onHideAdvertisementChange} value='' content={
                    <div className="email-advertisement-input__checkbox__label">
                      <Text content="EMAIL_FORMS_ADVERTISEMENT_DO_NOT_SHOW_ADVERTISEMENT" className="email-advertisement-input__checkbox__title" noMargin />
                      <Text content="EMAIL_FORMS_ADVERTISEMENT_DO_NOT_SHOW_ADVERTISEMENT_SUB_TITLE" className="email-advertisement-input__checkbox__subtitle" noMargin />
                    </div>
                  }
                  />
                </Col>
              </Row>}
              <Button className="email-advertisement-input-submit"
                id={`btn-${id}`}
                title={buttonText}
                shouldTranslate={false}
                buttonType="add"
                onClick={onSubmit}
                isLoading={isLoading}
              />
            </>}
        </>}
    </Panel>
  )
}

export default EmailAdvertisement;
