import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Panel from "../ui/panel/Panel";
import Text from "../ui/text/Text";
import Button from "../ui/button/Button";
import { AppState } from "../..";
import { CompleteEnableSCARequest, StartEnableSCARequest, StartEnableSCAResponse, GetGatewayScaDetailsRequest, GetGatewayScaDetailsResponse } from "../../utils/grpc/generated/Billsby.Protos/billing/private/paymentgateway/payment_gateway_pb";
import PaymentDetailsForm, { ICardDetailInputDetail, validatePaymentDetailsForm } from "../payment-details-form/PaymentDetailsForm";
import { PaymentGatewayServiceClient } from "../../utils/grpc/generated/Billsby.Protos/billing/private/paymentgateway/Payment_gatewayServiceClientPb";
import { ConfigConstants } from "../../utils/config";
import { grpcUnaryCall } from "../../utils/grpc/grpcUtils";
import { Int32Value } from "google-protobuf/google/protobuf/wrappers_pb";
import ScaSetup, { getBrowserInfo, setup3dSecure } from "../../components/sca-setup/ScaSetup";
import { ICustomerPaymentDetailsError } from "../../models/Customer";
import ProgressIndicator from "../ui/progress-indicator/ProgressIndicator";
import "./InitiateTestSCA.scss"
import { resetScaSettingsSetup, setScaField } from "../../actions/scaActions";
import { fetchCompanyConfigurationDetails } from "../../actions/companyDetailsActions";
import { fetchPaymentGateways } from "../../actions/paymentGatewaysActions";

interface IInitiateTestSCA {
	paymentGatewayType?: GetGatewayScaDetailsRequest.PaymentGateTypes,
	callback: (status: string) => void
}

const InitiateTestSCA: React.FC<IInitiateTestSCA> = ({
	paymentGatewayType = GetGatewayScaDetailsRequest.PaymentGateTypes.SPREEDLY_TEST,
	callback,
}) => {
	const currentCompanyDomain = useSelector((state: AppState) => state.auth.currentCompanyDomain);
	const scaReducer = useSelector((state: AppState) => state.scaReducer);
	const paymentGateways = useSelector((state: AppState) => state.paymentGatewaysReducer.paymentGateways);
	const companyDetails = useSelector((state: AppState) => state.companyDetailsReducer.companyDetails);

	const dispatch = useDispatch<Function>();

	const [isInitiatingTest, setIsInitiatingTest] = useState(false);
	const [isLoadingData, setIsLoadingData] = useState(false);
	const [minimumCharge, setMinimumCharge] = useState("");
	const [cardholderName, setCardholderName] = useState("");
	const [expiryMonth, setExpiryMonth] = useState(0);
	const [expiryYear, setExpiryYear] = useState(0);
	const [cardDetailsErrors, setCardDetailsErrors] = useState<ICustomerPaymentDetailsError | null>(null);
	const serviceClient = new PaymentGatewayServiceClient(ConfigConstants.grpcBaseUrl);

	const { selectedPaymentgatewayId, stripeScaConfig } = scaReducer

	const gid = Number(selectedPaymentgatewayId);

	useEffect(() => {
		const fetchCharge = async () => {
			const request = new GetGatewayScaDetailsRequest();
			request.setCompanyDomain(currentCompanyDomain);
			request.setPaymentGatewayType(paymentGatewayType);

			setIsLoadingData(true)

			try {
				const response = await grpcUnaryCall(request, serviceClient, serviceClient.getGatewayScaDetails) as GetGatewayScaDetailsResponse;
				await dispatch(fetchCompanyConfigurationDetails(currentCompanyDomain))
				setMinimumCharge(response.getMinimumChargeAmount());
				setIsLoadingData(false);

			} catch (err) {
				console.log(err);
				setIsLoadingData(false);
			}
		}

		fetchCharge();
	}, [])

	if (isLoadingData) {
		return <ProgressIndicator className="initiate-test-sca__loading" coverage="full-content" color="blue"></ProgressIndicator>
	}

	const handleStartEnableSCARequest = async (cardDetails: ICardDetailInputDetail): Promise<StartEnableSCAResponse> => {

		const request = new StartEnableSCARequest();
		request.setCompanyDomain(currentCompanyDomain);
		request.setPaymentGatewayId(gid);
		request.setTestCardFullName(cardholderName);
		request.setTestCardPaymentMethodToken(cardDetails.paymentCardToken);
		request.setBrowserInfo(getBrowserInfo());
		stripeScaConfig && request.setWebhookId(stripeScaConfig.webhookId);
		stripeScaConfig && request.setWebhookSigningSecret(stripeScaConfig.webhookSigningSecret);
		stripeScaConfig && request.setGatewaySecretKey(stripeScaConfig.apiSecretKey);

		return await grpcUnaryCall(request, serviceClient, serviceClient.startEnableSCA) as StartEnableSCAResponse;
	}

	const handleCompleteEnableSCARequest = async (event: { action: string }, startEnableSCAResponse: StartEnableSCAResponse) => {
		const request = new CompleteEnableSCARequest();
		request.setCompanyDomain(currentCompanyDomain);
		request.setScaTransactionId((startEnableSCAResponse.getScaTransactionId() as Int32Value).getValue());
		request.setPaymentGatewayId(gid);

		if (event.action === "succeeded" || event.action === "trigger-completion") {
			request.setIsSuccessful(true);
			await grpcUnaryCall(request, serviceClient, serviceClient.completeEnableSCA);
			return dispatch(setScaField("showTestModal", false));

		} else if (event.action === "error") {
			return dispatch(setScaField("showTestModal", false));

		} else {
			return dispatch(setScaField("showTestModal", false));
		}
	}

	const initiateTest = async (cardDetails: ICardDetailInputDetail) => {
		setIsInitiatingTest(true)
		try {
			const startEnableSCAResponse = await handleStartEnableSCARequest(cardDetails);
			if (startEnableSCAResponse.getIsThreeDsRequired()) {
				sessionStorage.setItem("billsbyTestSCA", JSON.stringify({
					companyDomain: currentCompanyDomain,
					scaTransactionId: (startEnableSCAResponse.getScaTransactionId() as Int32Value).getValue(),
					paymentGatewayId: startEnableSCAResponse.getPaymentGatewayId(),
					paymentGatewayTitle: paymentGateways?.find(p => p.paymentGatewayId === gid)?.title,
					scaReducer
				}))

				setup3dSecure(startEnableSCAResponse.getTransactionToken(), async (event: { action: string }) => {
					await handleCompleteEnableSCARequest(event, startEnableSCAResponse)
				})
			}
      		await dispatch(fetchPaymentGateways(currentCompanyDomain));
	  		!startEnableSCAResponse.getIsThreeDsRequired() && dispatch(setScaField("hasSuccessfulScaSetup", true));
			dispatch(setScaField("hasFailedScaSetup", false));
			dispatch(resetScaSettingsSetup())
			dispatch(setScaField("showTestModal", false));
      
		} catch (err) {
			console.log(err);
			dispatch(setScaField("hasFailedScaSetup", true));
			dispatch(setScaField("hasSuccessfulScaSetup", false));
			dispatch(setScaField("showTestModal", false));
		} finally {
			window.scroll({
				top: 0,
				left: 0,
				behavior: "smooth"
			});
		}
	}

	const onSubmit = (event: any) => {
		event.preventDefault();
		validatePaymentDetailsForm({ cardholderName, expiryMonth, expiryYear },
			{
				addressLine1: companyDetails.billingAddressLine1,
				addressLine2: companyDetails.billingAddressLine2,
				state: companyDetails.billingAddressState,
				city: companyDetails.billingAddressCity,
				country: companyDetails.billingAddressCountryISO3,
				postCode: companyDetails.billingAddressPostCode
			},
			(cardData, errors: ICustomerPaymentDetailsError | null) => {
				if (errors) {
					setCardDetailsErrors(errors)
				}

				if (cardData) {
					const cardDetails: ICardDetailInputDetail = {
						paymentCardToken: cardData.token,
						expiryMonth: cardData.month,
						expiryYear: cardData.year,
						cardType: cardData.card_type,
						last4Digits: cardData.last_four_digits,
						fullName: cardData.full_name
					};
					initiateTest(cardDetails)
				}
			});
	};

	return (
		<div className="initiate-test-sca">
			<ScaSetup />
			<Panel className="initiate_test_sca__title-panel" title="SCA_TEST_TRANSACTION_TITLE">
				<Text className="initiate_test_sca__title-text" content="SCA_TEST_TRANSACTION_CONTENT" translateWith={{ value: minimumCharge }} noMargin />
			</Panel>
			<Panel title="SCA_TEST_TRANSACTION_PAYMENT_DETAILS">
				<form className="initiate-test-sca__form" onSubmit={onSubmit}>
					<PaymentDetailsForm
						className="initiate-test-sca__payment-details"
						onChangeCardholderName={(name) => setCardholderName(name)}
						onChangeExpiryMonth={(month) => setExpiryMonth(month)}
						onChangeExpiryYear={(year) => setExpiryYear(year)}
						cardNumberId="test-card-number"
						cvvId="test-cvv"
						formData={{
							cardholderName,
							expiryMonth,
							expiryYear
						}}
						formErrors={cardDetailsErrors}
					/>
					<Button id="initiate-test" type="submit" buttonType={"general"} disabled={!cardholderName || !expiryYear || !expiryMonth} isLoading={isInitiatingTest} title={"SCA_TEST_TRANSACTION_BTN"} translateWith={{ value: minimumCharge }} isFullWidth />
				</form>
			</Panel>
		</div>
	)
}

export default InitiateTestSCA;