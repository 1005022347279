import React from "react";
import Text from "../../components/ui/text/Text";

interface IGettingStartedStep {
  text: string,
  status: "current" | "done" | "locked",
  shouldTranslateText?: boolean,
  noMargin?: boolean
}

const GettingStartedStep: React.FC<IGettingStartedStep> = ({ text, status, shouldTranslateText = true, noMargin = false }) => {
  return (
    <Text 
      className={`getting-started-step getting-started-step--${status} ${noMargin ? "getting-started-step__noMargin" : ""}`} 
      shouldTranslate={shouldTranslateText} 
      content={text} 
    />
  )
}


export default GettingStartedStep;