import React from "react";
import "./LogPanel.scss";
import Panel from "../panel/Panel";

interface ILogPanelProps {
  number?: number
  title: string | JSX.Element,
  subTitle?: string | JSX.Element,
  icon?: string | JSX.Element,
  className?: string
}

const LogPanel: React.FC<ILogPanelProps> = React.memo(({ title, subTitle = "",  className = "", icon = "" }) => {
  return (
    <div className="log_panel">
      <Panel className={`log_panel ${className}`}>
        <div>
          <div className="log_panel__title">
            <div className="log_panel__title__icon">
              {icon}
            </div>
            <div className="log_panel__title__text">              
              {title}
            </div>
          </div>
          <div className="log_panel__subtitle">
            {subTitle}
          </div>
        </div>
      </Panel>
    </div>
  )
})

export default LogPanel;