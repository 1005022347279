import React, { useEffect } from "react";
import history from "../../../../../utils/history";
import Panel from "../../../../ui/panel/Panel";
import Text from "../../../../ui/text/Text";
import "./TrialWillExpireInThreeDaysEmailTemplate.scss";
import { AppState } from "../../../../..";
import { useDispatch, useSelector } from "react-redux";
import { IAuthReducerState } from "../../../../../reducers/authReducer";
import { Row, Col } from "react-grid-system";
import EmailPreivewContainer from "../../brand-customization/email-preview/EmailPreivewContainer";
import BackNavigationPanel from "../../../../ui/back-navigation-panel/BackNavigationPanel";
import { IEmailCustomizationReducerState } from "../../../../../reducers/emailCustomizationReducer";
import { resetEmailTemplateValues, getEmailTemplateValues } from "../../../../../actions/emailCustomizationActions";
import { fetchCompanyBranding } from "../../../../../actions/companyBrandingActions";
import EmailFooter from "../email-footer/EmailFooter";
import EmailEditableArea from "../email-editable-area/EmailEditableArea";
import { EmailTypes } from "../../../../../utils/grpc/generated/Billsby.Protos/Messenger/private/emailconfig/emailconfig_pb";

const TrialWillExpireInThreeDaysEmailTemplate: React.FC = () => {
  const dispatch = useDispatch();
  const emailCustomizationReducer = useSelector<AppState, IEmailCustomizationReducerState>(state => state.emailCustomizationReducer);
  const authReducer = useSelector<AppState, IAuthReducerState>(state => state.auth);
  
  const { customArea1, customArea2, customArea3 } = emailCustomizationReducer;
  const { currentCompanyId, companyName, currentCompanyDomain } = authReducer;
  type allowLoadingType = "subject" | "custom1" | "custom2" | "custom3" | "ads" | "bbc";

  useEffect(() => {
    dispatch(fetchCompanyBranding(currentCompanyDomain));
    dispatch(getEmailTemplateValues(currentCompanyId as number, companyName, EmailTypes.REMINDER_72_HOURS_BEFORE_TRIAL_EXPIRES));
    return () => { dispatch(resetEmailTemplateValues()) };
  }, []);

  return (
    <div>
      <BackNavigationPanel
        title={"EMAIL_TEMPLATE_TRIAL_WILL_EXPIRE_IN_THREE_DAYS_BACK_PANEL_TITLE"} shouldTranslateTitle={true}
        onClick={() => history.push("/configuration/email-notifications/emails/trial-management")} />

      <Row>
        <Col sm={8}>
          <Panel className="trial-will-expire-in-three-days-email-template__panel" noMargin>
            <EmailPreivewContainer>

              <Text
                content={<span dangerouslySetInnerHTML={{__html: customArea1}}></span>}
                shouldTranslate={false}
                className="email-preview__details__text email-preview__details__text-title bar bar-blue"
                component="span"
                noMargin
              />

              <Text
                content="EMAIL_TEMPLATE_TRIAL_WILL_EXPIRE_IN_THREE_DAYS_GREETINGS"
                className="email-preview__details__text"
                component="span"
                noMargin
              />

              <Text
                content={<span dangerouslySetInnerHTML={{__html: customArea2}}></span>}
                shouldTranslate={false}
                className="email-preview__details__text bar bar-orange"
                component="span"
                noMargin
              />

              <Text
                content="EMAIL_TEMPLATE_TRIAL_WILL_EXPIRE_IN_THREE_DAYS_BODY_1"
                className="email-preview__details__text email-preview__details__text-bold"
                component="span"
                noMargin
              />

              <Text
                content="EMAIL_TEMPLATE_TRIAL_WILL_EXPIRE_IN_THREE_DAYS_BODY_2"
                className="email-preview__details__text"
                component="span"
                noMargin
              />

              <Text
                content="EMAIL_TEMPLATE_TRIAL_WILL_EXPIRE_IN_THREE_DAYS_REAGRDS"
                className="email-preview__details__text"
                component="span"
                noMargin
              />

              <Text
                content={companyName}
                className="email-preview__details__text"
                component="span"
                shouldTranslate={false}
                noMargin
              />
              <EmailFooter />
            </EmailPreivewContainer>
          </Panel>
        </Col>
        <Col sm={4}>
          <EmailEditableArea
            id="TrialWillExpireInThreeDaysEmailTemplate"
            emailCustomCopyOneValue={customArea1}
            emailCustomCopyTwoValue={customArea2}
            emailCustomCopyThreeValue={customArea3}
            emailCustomCopyThreeIsVisible={false}
            showCompanyAlertToggle={true}
            showProductAlertToggle={true}
            showPlanAlertToggle={true}
          />
        </Col>
      </Row>
    </div>
  )
}

export default TrialWillExpireInThreeDaysEmailTemplate;