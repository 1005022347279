import React, { useLayoutEffect, useEffect, useMemo, useState } from "react";
import Panel from "../../components/ui/panel/Panel";
import PageWrapper from "../../components/ui/layout/PageWrapper";
import Text from "../../components/ui/text/Text";
import "./GoLive.scss";
import CheckList from "../../components/ui/checklist/CheckList";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { AppState } from "../..";
import FormGroup from "../../components/ui/form-group/FormGroup";
import { Row, Col } from "react-grid-system";
import FormLabel from "../../components/ui/form-label/FormLabel";
import Input from "../../components/ui/input/Input";
import Dropdown from "../../components/ui/dropdown/Dropdown";
import Button from "../../components/ui/button/Button";
import ErrorPanel from "../../components/ui/error-panel/ErrorPanel";
import { ConfigConstants } from "../../utils/config";
import history from "../../utils/history";
import { getCountries, fetchGoLiveChecklist, setGoLiveField, validateGoLiveFormAndSubmit, setGoLiveCountry, setGoLiveState, resetGoLive, fetchCompanyFeaturesState } from "../../actions/goLiveActions";
import GoLiveSuccess from "./GoLiveSuccess";
import ErrorNotification from "../../components/ui/error-notification/ErrorNotification";
import counterpart from "counterpart";
import { getPublicIpV4 } from "../../utils/commonUtils";
import NoticePanel from "../../components/ui/notice-panel/NoticePanel";
import PlanPriceCard from "../../components/plan-price-card/PlanPriceCard";
import { BillsbyPlan } from "../../models/GoLive";
import Modal from "../../components/modal/Modal";
import ProPlanFeatures from "../pro-plan-features/ProPlanFeatures";

const getAnniversaryDay = () => {
  let day = +moment(new Date(), "YYYY/MM/DD").format("D");
  if (day === 29 || day === 30 || day === 31) {
    day = 1;
  }

  if (day === 1 || day === 21)
    return `${day}st`;

  if (day === 2 || day === 22)
    return `${day}nd`;

  if (day === 3 || day === 23)
    return `${day}rd`;

  return `${day}th`;
}

const GoLive: React.FC = () => {
  const authState = useSelector((state: AppState) => state.auth);
  const goLiveState = useSelector((state: AppState) => state.goLiveReducer);
  const [showFixFeaturesModal, setShowFixFeaturesModal] = useState(false);

  const dispatch = useDispatch();

  const { companyName, currentCompanyDomain, currentCompanyId, companyTrialLimit } = authState;
  const { countries, states, selectedState, checkList, cardHolderName, addressLine1, addressLine2, city, state, postCode, country, cardExpiryMonth, cardExpiryYear,
    errors, isGoingLive, isGoingLiveSuccess, isGoLive3ds1Request, isGoLive3ds1Failure, apiError, selectedBillsbyPlan, featuresList } = goLiveState;

  const goLiveIsEnabled = useMemo(() => !!cardHolderName && !!addressLine1 && !!city && !!state && !!postCode
    && !!country && !!cardExpiryMonth && !!cardExpiryYear && !!selectedBillsbyPlan, [cardHolderName, addressLine1, city, state, postCode, country, cardExpiryMonth, cardExpiryYear, selectedBillsbyPlan])

  useLayoutEffect(() => {
    dispatch(getCountries());
    dispatch(fetchGoLiveChecklist(currentCompanyDomain));
    currentCompanyId && dispatch(fetchCompanyFeaturesState(currentCompanyId))
    return () => { dispatch(resetGoLive()) };
  }, []);

  const hasProFeatures = useMemo(() => {
    return featuresList?.some(i => i.isUsed);
  }, [featuresList])

  useEffect(() => {
    const state = history.location.state as { hasprofeature: boolean } | undefined
    if (hasProFeatures && state?.hasprofeature) {
      setShowFixFeaturesModal(true)
    } else {
      setShowFixFeaturesModal(false)
    }
  }, [hasProFeatures, history.location])

  useEffect(() => {
    if (!checkList) {
      return;
    }
    const Spreedly = (window as any).Spreedly;

    Spreedly.init(ConfigConstants.spreedlyKey, {
      "numberEl": "spreedly-number",
      "cvvEl": "spreedly-cvv"
    });

    Spreedly.on("ready", () => {
      Spreedly.setFieldType("number", "text");
      Spreedly.setNumberFormat("prettyFormat");
    });

  }, [checkList]);

  const errorNotification = useMemo(() => {
    //conversion to set to remove duplicates and then back to array
    let errorsUnique = Array.from(new Set<string>(Object.values(errors)));
    let frontendAndSpreedlyErrors = "";

    if (isGoLive3ds1Request) {
      return <div />
    }

    if (errorsUnique.length) {
      frontendAndSpreedlyErrors = counterpart("GO_LIVE_INVALID_FIELDS") + errorsUnique.filter(err => !!err).join(", ");
    }

    if (apiError || errorsUnique.length) {
      return (
        <ErrorNotification
          key={Math.random() * 1000}
          title={apiError || frontendAndSpreedlyErrors}
          shouldTranslateTitle={false}
        />
      )
    }
    return <div />
  }, [apiError, errors])

  if (!checkList) {
    return null;
  }

  if (isGoingLiveSuccess) {
    return (
      <GoLiveSuccess authData={authState} />
    )
  }

  const onGoLive = async () => {
    try {
      const ipAddress = await getPublicIpV4();
      dispatch(validateGoLiveFormAndSubmit(currentCompanyDomain, Number(currentCompanyId), ipAddress))
    } catch (err) {
      console.log(err);
      dispatch(validateGoLiveFormAndSubmit(currentCompanyDomain, Number(currentCompanyId)))
    }
  }

  const onFixFeatures = (evt: React.MouseEvent) => {
    evt.preventDefault();
    setShowFixFeaturesModal(true)
  }

  return (
    <>
      {isGoLive3ds1Failure && <NoticePanel className="golive__sca-error" type="error" content="SPREEDLY_ERROR_SCA_CARD"></NoticePanel>}
      <PageWrapper className="golive">
        <Panel title="GO_LIVE_HEADER_TITLE" translateWith={{ trialLimit: companyTrialLimit }} className="golive__heading">
          <Text content="GO_LIVE_HEADER_CONTENT" translateWith={{ trialLimit: companyTrialLimit }} noMargin />
        </Panel>

        <Panel className="golive__checklist">
          <Text className="golive__checklist__title" content="GO_LIVE_CHECKLIST_TITLE" translateWith={{ companyName }} />
          <CheckList
            data={[
              {
                title: "GO_LIVE_CHECKLIST_1",
                isDone: checkList.isProductReady,
                btnTitle: "GO_LIVE_CHECKLIST_FIXTHIS",
                btnClb: () => history.push("/products/products-plans-cycles"),
                isBtnVisible: !checkList.isProductReady
              },
              {
                title: "GO_LIVE_CHECKLIST_2",
                isDone: checkList.isPaymentGatewayReady,
                btnTitle: "GO_LIVE_CHECKLIST_FIXTHIS",
                btnClb: () => history.push("/configuration/payment-gateways"),
                isBtnVisible: !checkList.isPaymentGatewayReady
              },
              {
                title: "GO_LIVE_CHECKLIST_3",
                isDone: checkList.isCurrencyReady,
                btnTitle: "GO_LIVE_CHECKLIST_FIXTHIS",
                btnClb: () => history.push("/configuration/currencies"),
                isBtnVisible: !checkList.isCurrencyReady

              }
            ]}
          />
        </Panel>
        
        <div className={!checkList.isProductReady || !checkList.isPaymentGatewayReady || !checkList.isCurrencyReady ? "golive--opaque" : ""}>
        <Panel title="GO_LIVE_SELECT_PLAN_TITLE" className="golive__plans">
          <Text content="GO_LIVE_SELECT_PLAN_SUBTITLE" translateWith={{
            link: <a href="https://www.billsby.com/pricing" target="_self">{counterpart("GO_LIVE_SELECT_PLAN_SUBTITLE_HERE")}</a>
          }} />
          <Text content="GO_LIVE_SELECT_PLAN_SUBTITLE2" />
          {hasProFeatures && <NoticePanel className="golive__fix-features" type="warning" content="GO_LIVE_CORE_PLAN_WARNING" translateWith={{
            link: <a className="golive__fix-features__link" href="" onClick={onFixFeatures}>{counterpart("GO_LIVE_SELECT_PLAN_SUBTITLE_HERE")}</a>
          }} />}

          <div className="golive__plans__cards">
            <PlanPriceCard
              disabled={hasProFeatures}
              type="GO_LIVE_SELECT_PLAN_CORE_TYPE"
              cost="GO_LIVE_SELECT_PLAN_CORE_COST"
              billingType="GO_LIVE_SELECT_PLAN_CORE_BILLING_TYPE"
              description="GO_LIVE_SELECT_PLAN_CORE_DESCRIPTION"
              amountUsers="GO_LIVE_SELECT_PLAN_CORE_USERS"
              features={[
                "GO_LIVE_SELECT_PLAN_CORE_FEATURE1",
                "GO_LIVE_SELECT_PLAN_CORE_FEATURE2",
                "GO_LIVE_SELECT_PLAN_CORE_FEATURE3",
                "GO_LIVE_SELECT_PLAN_CORE_FEATURE4",
                "GO_LIVE_SELECT_PLAN_CORE_FEATURE5",
                "GO_LIVE_SELECT_PLAN_CORE_FEATURE6",
                "GO_LIVE_SELECT_PLAN_CORE_FEATURE7",
                "GO_LIVE_SELECT_PLAN_CORE_FEATURE8",
              ]}
              isSelected={selectedBillsbyPlan === BillsbyPlan.CORE}
              onClick={() => dispatch(setGoLiveField("selectedBillsbyPlan", BillsbyPlan.CORE))}
            />

            <PlanPriceCard
              type="GO_LIVE_SELECT_PLAN_PRO_TYPE"
              label="GO_LIVE_SELECT_PLAN_PRO_LABEL"
              cost="GO_LIVE_SELECT_PLAN_PRO_COST"
              billingType="GO_LIVE_SELECT_PLAN_PRO_BILLING_TYPE"
              description="GO_LIVE_SELECT_PLAN_PRO_DESCRIPTION"
              amountUsers="GO_LIVE_SELECT_PLAN_PRO_USERS"
              features={[
                "GO_LIVE_SELECT_PLAN_PRO_FEATURE1",
                "GO_LIVE_SELECT_PLAN_PRO_FEATURE2",
                "GO_LIVE_SELECT_PLAN_PRO_FEATURE3",
                "GO_LIVE_SELECT_PLAN_PRO_FEATURE4",
                "GO_LIVE_SELECT_PLAN_PRO_FEATURE5",
                "GO_LIVE_SELECT_PLAN_PRO_FEATURE6",
                "GO_LIVE_SELECT_PLAN_PRO_FEATURE7",
                "GO_LIVE_SELECT_PLAN_PRO_FEATURE8",
              ]}
              isSelected={selectedBillsbyPlan === BillsbyPlan.PRO}
              onClick={() => dispatch(setGoLiveField("selectedBillsbyPlan", BillsbyPlan.PRO))}
            />
          </div>
        </Panel>
          {/*<Panel title="GO_LIVE_TRANSACTIONFEES_TITLE" className="golive__transactionfees">
            <Text
              content="GO_LIVE_TRANSACTIONFEES_CONTENT"
              translateWith={{
                trialLimit: companyTrialLimit,
                trialLimitBold: <b>{companyTrialLimit}</b>,
                day: <b>{getAnniversaryDay()}</b>,
                link: <a href="https://www.billsby.com/terms" target="blank">{counterpart("GO_LIVE_TRANSACTIONFEES_TERMS")}</a>
              }}
            />
            <Text content="GO_LIVE_TRANSACTIONFEES_CONTENT2" noMargin className="grey" />
            </Panel>*/}

          {/* <Panel title="GO_LIVE_BILLSBY_BRAND_TITLE" className="golive__brand">
          <div>
            <Text content="GO_LIVE_BILLSBY_BRAND_CONTENT1" component="span" />
            <Text content="GO_LIVE_BILLSBY_BRAND_CONTENT2" component="span" className="bold" />
          </div>
          <div>
            <Checkbox
              checked={isEnabledBillsbyBrand}
              content={() => (
                <div>
                  <Text content="GO_LIVE_BILLSBY_BRAND_CONTENT3" component="span" />
                  <Text content="GO_LIVE_BILLSBY_BRAND_CONTENT4" component="span" className="bold" />
                </div>
              )}
              onClick={() => dispatch(setGoLiveField("isEnabledBillsbyBrand", !isEnabledBillsbyBrand))}
              value=""
              isHtml
            />
          </div>
          <Text content="GO_LIVE_BILLSBY_BRAND_CONTENT5" noMargin className="grey" />
        </Panel> */}

          <Panel title="GO_LIVE_BILLSBY_PAYMENT_DETAILS_TITLE" className="golive__payment-details">
            <FormGroup>
              <Row align="center">
                <Col xs={12}>
                  <div className="golive__payment-details__form-element">
                    <FormLabel target="cardholder-name" content="GO_LIVE_BILLSBY_PAYMENT_DETAILS_CARDHOLDER" noMargin />
                    <Input
                      id="cardholder-name"
                      value={cardHolderName}
                      onChange={(evt: any) => dispatch(setGoLiveField("cardHolderName", evt.target.value))}
                      isError={!!errors.cardHolderName}
                    />
                  </div>
                </Col>
              </Row>
            </FormGroup>

            <FormGroup>
              <Row align="center">
                <Col xs={12}>
                  <div className="golive__payment-details__form-element">
                    <FormLabel target="address-line1" content="GO_LIVE_BILLSBY_PAYMENT_DETAILS_ADDRESS_LINE1" noMargin />
                    <Input
                      id="address-line1"
                      value={addressLine1}
                      onChange={(evt: any) => dispatch(setGoLiveField("addressLine1", evt.target.value))}
                      isError={!!errors.addressLine1}
                    />
                  </div>
                </Col>
              </Row>
            </FormGroup>

            <FormGroup>
              <Row align="center">
                <Col xs={12}>
                  <div className="golive__payment-details__form-element">
                    <FormLabel target="address-line2" content="GO_LIVE_BILLSBY_PAYMENT_DETAILS_ADDRESS_LINE2" noMargin />
                    <Input
                      id="address-line2"
                      value={addressLine2}
                      onChange={(evt: any) => dispatch(setGoLiveField("addressLine2", evt.target.value))}
                    />
                  </div>
                </Col>
              </Row>
            </FormGroup>

            <FormGroup>
              <Row align="center">
                <Col xs={6}>
                  <div className="golive__payment-details__form-element">
                    <FormLabel target="city" content="GO_LIVE_BILLSBY_PAYMENT_DETAILS_CITY" noMargin />
                    <Input
                      id="city"
                      value={city}
                      onChange={(evt: any) => dispatch(setGoLiveField("city", evt.target.value))}
                      isError={!!errors.city}
                    />
                  </div>
                </Col>
                <Col xs={6}>
                  <div className="golive__payment-details__form-element golive__payment-details__form-element--multiple">
                    <FormLabel target="state" content="GO_LIVE_BILLSBY_PAYMENT_DETAILS_STATE" noMargin />
                    {
                      selectedState && selectedState.label && selectedState.value
                        ? (
                          <Dropdown
                            id="state-dropdown"
                            dividerAfter={4}
                            onChange={(state: any) => dispatch(setGoLiveState(state))}
                            value={selectedState}
                            options={states}
                          />
                        )
                        : (
                          <Input
                            id="state-input"
                            value={state}
                            isError={!!errors.state}
                            onChange={(evt: any) => dispatch(setGoLiveState({ label: evt.target.value, value: undefined }))}
                          />
                        )
                    }
                  </div>
                </Col>
              </Row>
            </FormGroup>


            <FormGroup>
              <Row align="center">
                <Col xs={6}>
                  <div className="golive__payment-details__form-element">
                    <FormLabel target="post-code" content="GO_LIVE_BILLSBY_PAYMENT_DETAILS_POSTCODE" noMargin />
                    <Input
                      id="post-code"
                      value={postCode}
                      onChange={(evt: any) => dispatch(setGoLiveField("postCode", evt.target.value))}
                      isError={!!errors.postCode}
                    />
                  </div>
                </Col>
                <Col xs={6}>
                  <div className="golive__payment-details__form-element golive__payment-details__form-element--multiple">
                    <FormLabel target="country" content="GO_LIVE_BILLSBY_PAYMENT_DETAILS_COUNTRY" noMargin />
                    <Dropdown
                      id="country-dropdown"
                      dividerAfter={4}
                      defaultCountry={country}
                      onChange={(country: any) => dispatch(setGoLiveCountry(country))}
                      value={country}
                      options={countries}
                    />
                  </div>
                </Col>
              </Row>
            </FormGroup>

            <FormGroup>
              <Row align="center">
                <Col xs={12}>
                  <div className="golive__payment-details__form-element golive__spreedly-card">
                    <FormLabel target="spreedly-number" content="GO_LIVE_BILLSBY_PAYMENT_DETAILS_CREDITCARD" noMargin />
                    <div id="spreedly-number" className={`golive__spreedly-card-input ${!!errors.creditCardNumber ? "golive__spreedly-card__error-border" : ""}`} />
                  </div>
                </Col>
              </Row>
            </FormGroup>

            <FormGroup>
              <Row align="center">
                <Col xs={12}>
                  <div className="golive__payment-details__form-element">
                    <FormLabel target="spreedly-cvv" content="GO_LIVE_BILLSBY_PAYMENT_DETAILS_CVV" noMargin />
                    <div id="spreedly-cvv" className="golive__spreedly-card-input" />
                  </div>
                </Col>
              </Row>
            </FormGroup>


            <Row align="center">
              <Col xs={12}>
                <div className="golive__payment-details__form-element golive__payment-details__form-element--multipleinputs">
                  <FormLabel target="expiration-date-mm" content="GO_LIVE_BILLSBY_PAYMENT_DETAILS_EXPIRATION_DATE" noMargin />
                  <Input
                    id="expiration-date-mm"
                    placeholder="MM"
                    value={cardExpiryMonth}
                    onChange={(evt: any) => evt.target.value.length <= 2 && +evt.target.value <= 12 && dispatch(setGoLiveField("cardExpiryMonth", evt.target.value))}
                    isError={!!errors.cardExpiryMonth}
                  />
                  <Input
                    id="expiration-date-aaaa"
                    placeholder="YYYY"
                    value={cardExpiryYear}
                    onChange={(evt: any) => +evt.target.value < 3000 && dispatch(setGoLiveField("cardExpiryYear", evt.target.value))}
                    isError={!!errors.cardExpiryYear}
                  />
                </div>
              </Col>
            </Row>

          </Panel>

          <Panel title="GO_LIVE_WHAT_HAPPENS_TITLE">
            <Text content="GO_LIVE_WHAT_HAPPENS_CONTENT" translateWith={{
              link: <a href="https://www.billsby.com/terms" target="_self">
                {counterpart("GO_LIVE_TERMS_LINK")}
              </a>
            }} noMargin />
          </Panel>

          <FormGroup>
            <Button
              id="go-live-btn"
              title="GO_LIVE_WHAT_HAPPENS_BTN"
              onClick={onGoLive}
              disabled={isGoingLive || !goLiveIsEnabled}
              isLoading={isGoingLive}
              isFullWidth
              buttonType={"general"}
            />
          </FormGroup>

          {errorNotification}

          <ErrorPanel
            title="GO_LIVE_INFO_1"
            subtitle="GO_LIVE_INFO_2"
            icon="fas fa-question-circle"
          />

        </div>

        {hasProFeatures && <Modal title="GO_LIVE_PRO_PLAN_FEATURE_TITLE" isOpen={showFixFeaturesModal} onRequestClose={() => setShowFixFeaturesModal(false)}>
          <ProPlanFeatures historyState={{ from: "/launch-checklist" }} /> 
        </Modal>}
      </PageWrapper>
    </>
  )
}

export default GoLive;