import React, { useState, useLayoutEffect } from "react";
import Button from "../../../ui/button/Button";
import CollectTaxRegistrationNumber from "../../tax-components/CollectTaxRegistrationNumber";
import TaxRegistrationNumber from "../../tax-components/TaxRegistrationNumber";
import ConfigureTaxRates from "../../tax-components/ConfigureTaxRates";
import { TaxCollection, IndiaGeneralRate, IOverrideStateRate } from "../../../../models/Taxes";
import { TaxRate, ManualTaxConfig } from "../../../../utils/grpc/generated/Billsby.Protos/billing/private/company/taxation/taxation_pb";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../../../..";
import "./TaxConfigFlow.scss";
import { deleteTaxconfig, fetchTaxConfig, updateManualTaxConfig } from "../../../../actions/companyTaxRatesActions";
import { ICountryState } from "../../../../models/CountryState";
import { groupBy } from "../../../../utils/commonUtils";
import { countryStates } from "../../../../utils/states/states";
import TaxExemption from "../../tax-components/TaxExemption";

interface IIndiaTaxConfigFlow {
  handleUpdateCallback?: Function;
}

const IndiaTaxConfigFlow: React.FC<IIndiaTaxConfigFlow> = ({ handleUpdateCallback }) => {
  const auth = useSelector((state: AppState) => state.auth);
  const companyTaxRates = useSelector((state: AppState) => state.companyTaxRatesReducer);

  const dispatch = useDispatch<Function>();

  const [isLoading, setIsLoading] = useState(false);
  const [isConfigured, setIsConfigured] = useState(false);

  const generalTaxRate1 = new TaxRate();
  const generalTaxRate2 = new TaxRate();
  const generalTaxRate3 = new TaxRate();

  generalTaxRate1.setName(IndiaGeneralRate.CGST);
  generalTaxRate1.setRate(-1); //If undefined
  generalTaxRate2.setName(IndiaGeneralRate.SGST_UTGST);
  generalTaxRate2.setRate(-1); //If undefined
  generalTaxRate3.setName(IndiaGeneralRate.Cess);
  generalTaxRate3.setRate(-1); //If undefined

  //General tax rates
  const [generalTaxRates, setGeneralTaxRates] = useState<Array<TaxRate>>([generalTaxRate1, generalTaxRate2, generalTaxRate3]);
  const [selectedStates, setSelectedStates] = useState<Array<IOverrideStateRate>>([]);

  //Tax registration number
  const [taxLabel, setTaxLabel] = useState("");
  const [taxRegNumber, setTaxRegNumber] = useState("");

  //Collect tax registration number states
  const [taxRegNumberRequirementType, setTaxRegNumberRequirementType] = useState<TaxCollection>(TaxCollection.DO_NOT_COLLECT);

  //Customer tax exemption
  const [customerTaxExemption, setCustomerTaxExemption] = useState(false);

  const { currentCompanyDomain } = auth;
  const { currentCountry } = companyTaxRates;

  const defaultRatesName = [IndiaGeneralRate.CGST, IndiaGeneralRate.SGST_UTGST, IndiaGeneralRate.Cess];

  useLayoutEffect(() => {
    //Preload data if configured
    if (currentCountry && currentCountry.taxConfig) {
      setIsConfigured(true);

      const taxConfig = currentCountry.taxConfig as ManualTaxConfig.AsObject;

      const filteredGeneralRates = taxConfig.taxRatesList.filter((tr) => tr.stateIso2 === "");
      const filteredSelectedStates = taxConfig.taxRatesList.filter((tr) => tr.stateIso2 !== "");

      let states: Array<IOverrideStateRate> = [];

      const groupedRates = groupBy(filteredSelectedStates, (item) => [item.stateIso2]);

      groupedRates.forEach((arr: Array<TaxRate.AsObject>) => {
        const state = countryStates.find((cs) => cs.StateIso2 === arr[0].stateIso2);

        const overridenState: IOverrideStateRate = {
          stateCode: arr[0].stateIso2,
          stateName: state ? state.StateName : "",
          taxRegNumber: arr[0].taxRegNumber,
          isOverride: true,
          taxRates: defaultRatesName.map((name) => {
            const taxRate = new TaxRate();
            const configured = arr.find((cf) => cf.name === name);

            if (configured) {
              taxRate.setName(configured.name);
              taxRate.setRate(configured.rate);
              taxRate.setTaxRegNumber(configured.taxRegNumber);
              taxRate.setStateIso2(configured.stateIso2);
              return taxRate;
            }

            taxRate.setName(name);
            taxRate.setStateIso2(arr[0].stateIso2);
            taxRate.setTaxRegNumber(arr[0].taxRegNumber);
            taxRate.setRate(-1); //If undefined
            return taxRate;
          }),
        };

        states.push(overridenState);
      });

      setSelectedStates(states);

      setGeneralTaxRates(
        defaultRatesName.map((name) => {
          const configured = filteredGeneralRates.find((cf) => cf.name === name);
          const tr = new TaxRate();
          if (configured) {
            tr.setName(configured.name);
            tr.setRate(configured.rate);
            return tr;
          }

          tr.setName(name);
          tr.setRate(-1);
          return tr;
        })
      );

      setTaxLabel(taxConfig.taxLabel);
      setTaxRegNumber(taxConfig.taxRegNumber);
      setTaxRegNumberRequirementType(taxConfig.taxRegNumberRequirementType as TaxCollection);
      setCustomerTaxExemption(taxConfig.customerTaxExemption)
    }
  }, [currentCountry]);

  if (!currentCountry) {
    return null;
  }

  const onAdd = (countryState: ICountryState) => {
    if (selectedStates.find((ss) => ss.stateCode === countryState.StateIso2)) {
      return;
    }

    const newState: IOverrideStateRate = {
      stateCode: countryState.StateIso2,
      stateName: countryState.StateName,
      taxRegNumber: "",
      isOverride: false,
      taxRates: defaultRatesName.map((name) => {
        const taxRate = new TaxRate();
        taxRate.setStateIso2(countryState.StateIso2);
        taxRate.setName(name);
        taxRate.setRate(-1);

        return taxRate;
      }),
    };

    setSelectedStates([newState, ...selectedStates]);
  };

  const onDelete = (stateCode: string) => {
    setSelectedStates(selectedStates.filter((ss) => ss.stateCode !== stateCode));
  };

  const onChangeGeneralTaxRates = (value: number, taxRate: TaxRate) => {
    setGeneralTaxRates(
      generalTaxRates.map((gt) => {
        if (gt.getName() === taxRate.getName()) {
          gt.setRate(value);
          return gt;
        }
        return gt;
      })
    );
  };

  const onChangeOverrideTaxRates = (value: number, taxRate: TaxRate) => {
    setSelectedStates(
      selectedStates.map((ss) => {
        if (ss.stateCode === taxRate.getStateIso2()) {
          return {
            ...ss,
            taxRates: ss.taxRates.map((tr) => {
              if (tr.getName() === taxRate.getName()) {
                tr.setRate(value);
                return tr;
              }
              return tr;
            }),
          };
        }
        return ss;
      })
    );
  };

  const onChangeOverrideRegNumber = (value: string, taxRate: TaxRate) => {
    setSelectedStates(
      selectedStates.map((ss) => {
        if (ss.stateCode === taxRate.getStateIso2()) {
          return {
            ...ss,
            taxRegNumber: value,
            taxRates: ss.taxRates.map((tr) => {
              tr.setTaxRegNumber(value);
              return tr;
            }),
          };
        }
        return ss;
      })
    );
  };

  const onChangeOverride = (value: boolean, stateToOverride: IOverrideStateRate) => {
    setSelectedStates(
      selectedStates.map((ss) => {
        if (ss.stateCode === stateToOverride.stateCode) {
          return {
            ...ss,
            isOverride: value,
          };
        }
        return ss;
      })
    );
  };

  const onDeleteTaxConfig = async () => {
    if (!currentCountry) {
      return;
    }

    try {
      await dispatch(deleteTaxconfig(currentCompanyDomain, currentCountry.countryCode, currentCountry.taxServiceType));
      await dispatch(fetchTaxConfig(currentCompanyDomain));
      handleUpdateCallback && handleUpdateCallback();
    } catch (err) {
      console.log(err);
    }
  };

  const onUpdateTaxConfig = async (evt: any) => {
    evt.preventDefault();

    setIsLoading(true);
    const manualTaxConfig = new ManualTaxConfig();

    let filteredSelectedStates: Array<TaxRate> = [];
    const filteredGeneralTaxes = generalTaxRates.filter((gt) => gt.getRate() >= 0);

    selectedStates
      .filter((st) => st.isOverride)
      .forEach((ss) => {
        ss.taxRates.forEach((tr) => {
          if (tr.getRate() >= 0) {
            filteredSelectedStates.push(tr);
          }
        });
      });

    manualTaxConfig.setTaxRatesList([...filteredGeneralTaxes, ...filteredSelectedStates]);
    manualTaxConfig.setTaxLabel(taxLabel);
    manualTaxConfig.setTaxRegNumber(taxRegNumber);
    manualTaxConfig.setTaxRegNumberRequirementType(taxRegNumberRequirementType);
    manualTaxConfig.setCustomerTaxExemption(taxRegNumberRequirementType !== TaxCollection.DO_NOT_COLLECT ? customerTaxExemption : false);

    try {
      await dispatch(updateManualTaxConfig(currentCompanyDomain, currentCountry.countryCode, manualTaxConfig));
      await dispatch(fetchTaxConfig(currentCompanyDomain));

      handleUpdateCallback && handleUpdateCallback();
    } catch (err) {
      setIsLoading(false);
    }
  };

  return (
    <div className="tax-config-flow">
      <form onSubmit={onUpdateTaxConfig}>
        <ConfigureTaxRates
          generalTaxRates={generalTaxRates}
          selectedStates={selectedStates}
          onAdd={onAdd}
          onDelete={onDelete}
          onChangeGeneralTaxRates={onChangeGeneralTaxRates}
          onChangeOverrideTaxRates={onChangeOverrideTaxRates}
          onChangeOverride={onChangeOverride}
          onChangeOverrideRegNumber={onChangeOverrideRegNumber}
          onDeleteConfig={onDeleteTaxConfig}
          isConfigured={isConfigured}
        />

        <TaxRegistrationNumber taxLabel={taxLabel} taxRegNumber={taxRegNumber} onChangeTaxLabel={(value) => setTaxLabel(value)} onChangeTaxRegNumber={(value) => setTaxRegNumber(value)} />

        <CollectTaxRegistrationNumber
          taxRegNumberRequirementType={taxRegNumberRequirementType}
          onChangeType={(value) => setTaxRegNumberRequirementType(value)}
          onChangeCollectTax={(value) => setTaxRegNumberRequirementType(value)}
        />

        {taxRegNumberRequirementType !== TaxCollection.DO_NOT_COLLECT && (
          <TaxExemption customerTaxExemption={customerTaxExemption} onChangeTaxExemption={() => setCustomerTaxExemption(!customerTaxExemption)}></TaxExemption>
        )}

        <Button disabled={false} isLoading={isLoading} type="submit" isFullWidth id="save-manual-tax" title="MANUAL_TAX_CONFIG_SAVE" />
      </form>
    </div>
  );
};

export default IndiaTaxConfigFlow;
