import React, { FormEvent, useState, useEffect } from "react";
import { Row, Col } from "react-grid-system";
import { useDispatch, useSelector } from "react-redux";
import Panel from "../../../ui/panel/Panel";
import Text from "../../../ui/text/Text";
import Button from "../../../ui/button/Button";
import FormLabel from "../../../ui/form-label/FormLabel";
import counterpart from "counterpart";
import Dropdown from "../../../ui/dropdown/Dropdown";
import Checkbox from "../../../ui/checkbox/Checkbox";
import ErrorPanel from "../../../ui/error-panel/ErrorPanel";
import { useParams } from "react-router";
import { AppState } from "../../../..";
import { updateBillsbyTaxConfig, fetchTaxConfig, setCurrentCountry, fetchAutoBillsbyProductCategories } from "../../../../actions/companyTaxRatesActions";
import "./BillsbyTaxCompleteIntegration.scss";
import { Address, UpsertAutoBillsbyTaxRequest } from "../../../../utils/grpc/generated/Billsby.Protos/billing/private/company/taxation/taxation_pb";
import { TaxCollection } from "../../../../models/Taxes";

interface IBillsbyTaxCompleteIntegration {
  nexusAddress?: Address.AsObject;
}

interface IMatchProps {
  countryCode: string;
}

const BillsbyTaxCompleteIntegration: React.FC<IBillsbyTaxCompleteIntegration> = ({ nexusAddress }) => {
  const companyTaxRatesReducer = useSelector((state: AppState) => state.companyTaxRatesReducer);
  const authData = useSelector((state: AppState) => state.auth);

  const dispatch = useDispatch<Function>();
  const { countryCode } = useParams<IMatchProps>();

  const { productCategories, currentCountry } = companyTaxRatesReducer;
  const { currentCompanyDomain, currentCompanyId } = authData;
  const [category, setCategory] = useState<{ value: ""; label: "" } | null>(null);
  const [autoCalculation, setAutoCalculation] = useState(false);

  const [state, setState] = useState({
    hasError: false,
    isLoadingData: false,
    isLoading: false
  });

  const fetchProductCategories = async () => {
    try {
      await dispatch(fetchAutoBillsbyProductCategories());
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    fetchProductCategories();
  }, []);

  const onSubmit = async (evt: FormEvent) => {
    evt.preventDefault();
    evt.stopPropagation();

    if (!category || !currentCompanyId) {
      return;
    }

    const data: UpsertAutoBillsbyTaxRequest.AsObject = {
      companyDomain: currentCompanyDomain,
      countryIso3Code: countryCode,
      billsbyTaxConfig: {
        taxProductCategoryCode: category.value,
        taxLabel: "",
        taxRegNumber: "",
        taxRegNumberRequirementType: TaxCollection.DO_NOT_COLLECT,
        nexusAddress: nexusAddress
      }
    };

    setState({ ...state, isLoading: true });

    try {
      await dispatch(updateBillsbyTaxConfig(data));
      await dispatch(fetchTaxConfig(currentCompanyDomain));

      if (currentCountry) {
        dispatch(setCurrentCountry({ countryName: currentCountry.countryName, countryCode }));
      }
    } catch (err) {
      setState({ ...state, isLoading: false, hasError: true });
      console.log(err);
    }
  };

  if (!productCategories) {
    return null;
  }

  return (
    <div className='billsby-tax-complete-integration'>
      <form className='billsby-tax-complete-integration__form' onSubmit={onSubmit}>
        <Panel title='AUTO_TAX_CALC_PANEL1_TITLE'>
          <Text content={`AUTO_TAX_CALC_PANEL1_CONTENT${currentCountry && currentCountry.countryCode === "USA" ? "_USA" : ""}`} translateWith={{ bold: <span className='bold'>{counterpart("AUTO_TAX_CALC_FEE")}</span>, countryName: currentCountry && currentCountry.countryName }}></Text>
          <Row align='center'>
            <Col md={2}>
              <FormLabel target='product-category' content='TAXJAR_COMPLETE_INTEGRATION_PRODUCT_CATEGORY' noMargin />
            </Col>
            <Col md={10}>
              <Dropdown id='product-category' onChange={(category: any) => setCategory(category)} placeholder={counterpart("AUTO_TAX_CALC_DROPDOWN_PLACEHOLDER")} value={category} options={productCategories} />
            </Col>
          </Row>
        </Panel>

        <Panel>
          <Text content='AUTO_TAX_CALC_PANEL2_CONTENT' translateWith={{ bold: <span className='bold'>{counterpart("AUTO_TAX_CALC_FEE")}</span>, countryName: currentCountry && currentCountry.countryName }}></Text>
          <Checkbox checked={autoCalculation} onClick={() => setAutoCalculation(!autoCalculation)} value='auto-calculation' content={<Text content='AUTO_TAX_CALC_CHECKBOX' translateWith={{ bold: <span className='bold'>{counterpart("AUTO_TAX_CALC_FEE")}</span> }} noMargin />} />
          <Text content='AUTO_TAX_CALC_PANEL2_INFO' className='billsby-tax-complete-integration__info' noMargin></Text>
        </Panel>
        <Button id='confirm-details' type='submit' disabled={!autoCalculation || !category} buttonType={"general"} isLoading={state.isLoading} title='AUTO_TAX_CALC_BUTTON3' isFullWidth />
      </form>
      {currentCountry && currentCountry.countryCode === "CAN" && <ErrorPanel title='AUTO_TAX_CALC_PANEL3_TITLE' subtitle='AUTO_TAX_CALC_PANEL3_CONTENT' buttonText='AUTO_TAX_CALC_BUTTON2' icon='fas fa-gavel' buttonIcon='' buttonCb={() => window.open("https://www.taxjar.com/", "_self")} />}
    </div>
  );
};

export default BillsbyTaxCompleteIntegration;
