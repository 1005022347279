import React, { useState, useEffect } from "react";
import Table from "../../../components/ui/table/Table";
import PageWrapper from "../../../components/ui/layout/PageWrapper";
import NavigationAlert from "../../../components/ui/navigation-alert/NavigationAlert";
import counterpart from "counterpart";
import NavigationBar from "../../../components/ui/navigation-bar/NavigationBar";
import { useHistory } from "react-router-dom";
import Text from "../../../components/ui/text/Text";
import Dropdown from "../../../components/ui/dropdown/Dropdown";
import "./TaxSummary.scss";
import Switch from "../../../components/ui/switch/Switch";
import Button from "../../../components/ui/button/Button";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../../..";
import moment from "moment";
import { downloadCSV } from "../../../utils/commonUtils";
import { JurisdictionType } from "../../../utils/grpc/generated/Billsby.Protos/billing/private/report/report_pb";
import LoadingModal from "../../../components/modal/loading-modal/LoadingModal";
import { fetchTaxSummary, resetTaxSummary, setTaxSummaryField } from "../../../actions/taxSummaryActions";
import MonthPicker from "../../../components/ui/month-picker/MonthPicker";
import ProgressIndicator from "../../../components/ui/progress-indicator/ProgressIndicator";

const TaxSummary: React.FC = () => {
  const auth = useSelector((state: AppState) => state.auth);
  const taxSummary = useSelector((state: AppState) => state.taxSummaryReducer);

  const dispatch = useDispatch<Function>();
  const history = useHistory();

  const [isLoading, setIsLoading] = useState(false);
  const [isCreatingCSV, setIsCreatingCSV] = useState(false);
  const { startDate, endDate, jurisdictionType, includeCurrentMonthToDate, details, headerMessage, tableHeaderType } = taxSummary;
  const typeOptions = [
    { value: JurisdictionType.JURISDICTION, label: counterpart("TAX_SUMMARY_JURISDICTION") },
    { value: JurisdictionType.COUNTRY, label: counterpart("TAX_SUMMARY_COUNTRY") }
  ];
  const dateFormat = "MMM YYYY";
  const defaultStartDate = moment().utc()
    .subtract(3, "month")
    .format(dateFormat);
  const defaultEndDate = moment().utc()
    .subtract(1, "month")
    .format(dateFormat);

  const fetchData = async (_startDate: string = defaultStartDate, _endDate: string = defaultEndDate) => {
    if (!auth.currentCompanyId) {
      return;
    }

    setIsLoading(true);

    const withCurrentMonth = includeCurrentMonthToDate && _endDate === defaultEndDate;
    const endDateToFetch = withCurrentMonth
      ? moment().utc()
          .subtract(0, "month")
          .format(dateFormat)
      : _endDate;

    try {
      await dispatch(fetchTaxSummary(auth.currentCompanyId, _startDate, endDateToFetch, jurisdictionType.value));
      dispatch(setTaxSummaryField("hasCurrentMonth", withCurrentMonth));
      dispatch(setTaxSummaryField("tableHeaderType", counterpart(jurisdictionType.value === JurisdictionType.JURISDICTION ? "TAX_SUMMARY_JURISDICTION" : "TAX_SUMMARY_COUNTRY")));
      dispatch(
        setTaxSummaryField(
          "headerMessage",
          counterpart("TAX_SUMMARY_ALERT_TITLE", {
            startDate: _startDate,
            endDate: endDateToFetch,
            toDate: withCurrentMonth ? ` (${counterpart("TAX_SUMMARY_TO_DATE")}) ` : "",
            type: counterpart(jurisdictionType.value === JurisdictionType.JURISDICTION ? "TAX_SUMMARY_JURISDICTION" : "TAX_SUMMARY_COUNTRY").toLowerCase()
          })
        )
      );

      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
      console.log(err);
    }
  };

  useEffect(() => {
    fetchData();

    dispatch(setTaxSummaryField("startDate", defaultStartDate));
    dispatch(setTaxSummaryField("endDate", defaultEndDate));
    dispatch(setTaxSummaryField("jurisdictionType", typeOptions[0]));

    return () => dispatch(resetTaxSummary());
  }, []);

  useEffect(() => {
    if (moment.utc(endDate, dateFormat).isBefore(startDate)) {
      dispatch(setTaxSummaryField("endDate", startDate));
    }
  }, [endDate, startDate]);

  const renderHeader = () => {
    const maxDate = moment.utc().subtract(1, "month").format(dateFormat);
    return (
      <>
        <NavigationBar previousPageTitle="NAVBAR_ACCOUNTING" shouldTranslatePageTitle={false} previousPageCb={() => history.push("/report-accounting")} pageTitle={""} />
        <div className="navigation-alert-wrapper">
          <NavigationAlert
            title={!isLoading ? headerMessage : ""}
            className=""
            shouldTranslateTitle={false}
            type="default"
            subTitle=""
            icon="fal fa-file-download"
            buttonLabel="SUMMARY_BUTTON_CSV"
            buttonDisabled={!details || isLoading}
            onClick={handleDownloadCSV}
            isVisible={true}
          />
        </div>
        <div className="tax-summary__header">
          <div className="tax-summary__filter">
            <MonthPicker className="tax-summary__start-date" value={startDate} maxDate={maxDate} onChange={value => dispatch(setTaxSummaryField("startDate", value))}></MonthPicker>
            <Text content="ACTIVITY_SUMMARY_TO" className="with-side-margin" component="span" noMargin></Text>
            <MonthPicker className="tax-summary__end-date" value={endDate} minDate={startDate} maxDate={maxDate} onChange={value => dispatch(setTaxSummaryField("endDate", value))}></MonthPicker>
            <span className="divider divider--vertical with-side-margin"></span>
            <Dropdown
              id="tax-summary-dropdown-3"
              className="dropdown--lg"
              options={typeOptions}
              value={jurisdictionType}
              onChange={(selectedType: any) => dispatch(setTaxSummaryField("jurisdictionType", selectedType))}
            ></Dropdown>

            {defaultEndDate === endDate && (
              <>
                <span className="divider divider--vertical with-side-margin"></span>
                <Switch id="include-current-month" checked={includeCurrentMonthToDate} onChange={() => dispatch(setTaxSummaryField("includeCurrentMonthToDate", !includeCurrentMonthToDate))}></Switch>
                <Text content="ACTIVITY_SUMMARY_SWITCH1_LABEL" component="span" noMargin></Text>
              </>
            )}
            <Button isLoading={isLoading} id="run-report" icon="fas fa-sync-alt" title="SUMMARY_BUTTON_REPORT" onClick={() => fetchData(startDate, endDate)}></Button>
          </div>
        </div>
      </>
    );
  };

  const renderTable = () => {
    if (!details || isLoading) {
      return <ProgressIndicator color="blue"></ProgressIndicator>;
    }

    return (
      <Table className="tax-summary__table" scrollable fixedColWidths={[300]}>
        <thead>
          <tr>
            <Text component="th" content={tableHeaderType} shouldTranslate={false}></Text>
            <Text component="th" content="TAX_SUMMARY_TAXABLE_AMOUNT"></Text>
            <Text component="th" content="TAX_SUMMARY_TAX"></Text>
          </tr>
        </thead>
        <tbody>
          {details.getResultsList().map((data, idx) => {
            return (
              <tr key={idx}>
                <Text component="td" shouldTranslate={false} content={data.getJurisdictionName()}></Text>
                <Text component="td" shouldTranslate={false} content={data.getTaxableAmountFormatted()}></Text>
                <Text component="td" shouldTranslate={false} content={data.getTaxFormatted()}></Text>
              </tr>
            );
          })}
        </tbody>
      </Table>
    );
  };

  const handleDownloadCSV = () => {
    if (!details || isLoading) {
      return null;
    }

    setIsCreatingCSV(true);
    setTimeout(() => {
      downloadCSV(".cc-table", headerMessage);
      setIsCreatingCSV(false);
    }, 500);
  };

  return (
    <div className="tax-summary">
      {renderHeader()}
      <PageWrapper>{renderTable()}</PageWrapper>
      <LoadingModal isOpen={isCreatingCSV} title={counterpart("DOWNLOADING_CSV_TAX_SUMMARY")} subTitle={""} shouldTranslateTitle={false} shouldTranslateSubTitle={false} />
    </div>
  );
};

export default TaxSummary;
