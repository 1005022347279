import { PaymentMethodType } from "../../models/Customer";

export interface IBillsbyData {
  cid?: string,
  containerWebsite?: ContainerWebsite,
  firstName?: string,
  lastName?: string,
  email?: string,
  billingAddressLine1?: string,
  billingAddressLine2?: string,
  billingAddressCity?: string,
  billingAddressState: string,
  billingAddressZip?: string,
  billingAddressCountry?: string,
  shippingAddressLine1?: string,
  shippingAddressLine2?: string,
  shippingAddressCity?: string,
  shippingAddressState?: string,
  shippingAddressZip?: string,
  shippingAddressCountry?: string,
  companyName?: string,
  additionalEmails?: string,
  phoneNumberDialCode?: string,
  phoneNumberDialCountry?: string,
  phoneNumber?: string,
  marketingConsent?: boolean,
  customFields?: Array<Partial<ICustomFieldWithValue>>,
  paymentSourceType?: PaymentMethodType,
  achSession?: {
    customerId: string,
    fullName: string,
    last4Digits: string,
    bankName: string,
    stripePaymentId: string
  },
}

export enum BillsbyDataParams {
  type = "data-billsby-type",
  company = "data-billsby-company",
  product = "data-billsby-product",
  plan = "data-billsby-plan",
  cycle = "data-billsby-cycle",
  units = "data-billsby-units",
  redirect = "data-billsby-redirect",
  wmessage = "data-billsby-wmessage",
  action = "data-billsby-action",
  customer = "data-billsby-customer",
  subscription = "data-billsby-subscription",
  addons = "data-billsby-addons",
  allowances = "data-billsby-allowances",
  language = "data-billsby-language"
}

/* type of action passed in the query string */
export enum BillsbyDataAction {
  CANCEL_PLAN = "cancelplan",
  UPDATE_PAYMENT_DETAILS = "paymentdetails",
  CHANGE_PLAN = "changeplan",
  CHANGE_PERSONAL_DETAILS = "personaldetails",
  CHANGE_ADDRESS = "address",
  MANAGE_ADDONS = "manageaddons",
  VIEW_BILLING_HISTORY = "billinghistory",
  VIEW_BILLING_HISTORY_UNPAID = "billinghistoryunpaid",
  MANAGE_COUPONS = "managecoupons",
  MANAGE_ALLOWANCES = "manageallowances"
}

export enum BillsbyDataType {
  ACCOUNT = "account",
  CHECKOUT = "checkout"
}

export enum ContainerWebsite {
  BILLSBY_APP = "billsby_app",
  OTHER = "other"
}

export const BILLSBY_BILLING_COMPANY = "billsby";

export interface ICustomField {
  customFieldId: number,
  type: CustomFieldType,
  label: string,
  description: string,
  options: string,
  compulsory: boolean
}

export interface ICustomFieldWithValue extends ICustomField {
  value: string
}

export enum CustomFieldType {
  SingleLineTextField = "SingleLineTextField",
  MultiLineTextField = "MultiLineTextField",
  DropdownField = "DropdownField",
  CheckboxField = "CheckboxField",
  DatePickerField = "DatePickerField",
  NumbersField = "NumbersField"
}