import React, { ReactElement } from "react";
import "./MenuPanel.scss";
import Panel from "../panel/Panel";

interface IMenuPanelProps {
  className?: string;
  children: ReactElement | Array<ReactElement>;
}

const Menu: React.FC = ({ children }) => {
  return (
    <div className="menu-panel__menu">
      {children}
    </div>
  )
}

const Content: React.FC = ({ children }) => {
  return (
    <div className="menu-panel__content">
      { children }
    </div>
  )
}

interface IMenuPanel extends React.FC<IMenuPanelProps> {
  Content?: any;
  Menu?: any;
}

const MenuPanel: IMenuPanel = ({ className = "", children }) => {  
  
    return (
      <Panel className={`menu-panel ${className}`}>
        <Panel className="menu-panel__modal" noMargin >
          { children }
        </Panel>
      </Panel>
    )
}

MenuPanel.Content = Content;
MenuPanel.Menu = Menu;

export default MenuPanel;