import React, { useState } from "react";
import { NavLink as NavLinkRouter } from "react-router-dom";
import history from "../../utils/history";
import counterpart from "counterpart";
import { useActiveLinks } from "../../utils/custom-hooks";

interface INavLink {
  id: string,
  target: string,
  content: string,
  icon?:string,
  isEnabled?: boolean,
  isVisible?: boolean
}

const NavLink =  React.memo((props: INavLink) => {
  const { id, target, content, icon, isEnabled = false, isVisible = true } = props;
  const [isActive, setIsActive] = useState(false);

  useActiveLinks(history, setIsActive, [target])

  if(!isVisible) {
    return null;
  }

  return (
    <NavLinkRouter
      className={!isEnabled ? "link-locked" : ""}
      activeClassName="link-active"
      id={id}
      to={isEnabled ? target : "#"}
    >
      {!!icon && <i className={`navbar__link-icon ${icon}`}></i>}
      {counterpart(content)}
    </NavLinkRouter>
  )
})

export default NavLink