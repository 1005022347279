import React, { useMemo, useState } from "react"
import Button from "../../../../ui/button/Button";
import Text from "../../../../ui/text/Text";
import { IPaymentGatewayElement } from "../../../../../models/Configuration";
import { DeletePaymentGatewayRequest } from "../../../../../utils/grpc/generated/Billsby.Protos/billing/private/paymentgateway/payment_gateway_pb";
import { PaymentGatewayServiceClient } from "../../../../../utils/grpc/generated/Billsby.Protos/billing/private/paymentgateway/Payment_gatewayServiceClientPb";
import { grpcUnaryCall } from "../../../../../utils/grpc/grpcUtils";
import { fetchPaymentGateways, setPaymentGatewaysField } from "../../../../../actions/paymentGatewaysActions";
import { ConfigConstants } from "../../../../../utils/config";
import { useDispatch } from "react-redux";

interface Props {
  onWebisteButtonClick: () => void;
  onError: () => void;
  paymentGateway: IPaymentGatewayElement;
  isOneGatewayPending: boolean;
  currentGatewayState: any;
  currentCompanyDomain: string;
  type: "create" | "edit";
}

const PaymentGatewaysTitle: React.FC<Props> = ({ onWebisteButtonClick, onError, paymentGateway, isOneGatewayPending, currentGatewayState, currentCompanyDomain, type }) => {
  const dispatch = useDispatch<Function>();

  const isViewWebsiteVisible = useMemo(() => !currentGatewayState.isCollapsed && !isOneGatewayPending && !paymentGateway.mustActivate,
  [paymentGateway, isOneGatewayPending, currentGatewayState]);
  const [isLoading, setIsLoading] = useState(false);

  const paymentGatewayLogo = useMemo(() => paymentGateway.isLogoFontAwesome ?
    <i className={paymentGateway.logo} /> : <img src={paymentGateway.logo} alt="no img" />,
    [paymentGateway.isLogoFontAwesome, paymentGateway.logo]);


  const isDiconnectButtonVisible = useMemo(() => !currentGatewayState.isCollapsed && !paymentGateway.mustActivate && !isOneGatewayPending,
    [currentGatewayState.isCollapsed, paymentGateway.mustActivate, isOneGatewayPending]);

  const disconnectPaymentGateway = async () => {
    setIsLoading(true);
    const disconnectPaymentGatewayRequest = new DeletePaymentGatewayRequest();
    const paymentGatewayServiceClient = new PaymentGatewayServiceClient(ConfigConstants.grpcBaseUrl);
    disconnectPaymentGatewayRequest.setPaymentGatewayId(paymentGateway.paymentGatewayId as number);
    disconnectPaymentGatewayRequest.setCompanyDomain(currentCompanyDomain);
    try {
      await grpcUnaryCall(disconnectPaymentGatewayRequest, paymentGatewayServiceClient, paymentGatewayServiceClient.deletePaymentGateway);
      await dispatch(fetchPaymentGateways(currentCompanyDomain));
      setIsLoading(false);
    } catch {
      setIsLoading(false);
      onError();
    }
  }

  const paymentGatewayTitle = useMemo(() => {
    if (type === "create") {
      return <>
        <Text content="PAYMENT_GATEWAYS_DEFAULT_NAME" noMargin />
        <Text content={paymentGateway.title} noMargin />
      </>
    } else {
      return <>
        <Text content={paymentGateway.title} shouldTranslate={false} noMargin />    
        <Text content={paymentGateway.subTitle} shouldTranslate={true} noMargin />
      </>
    }
  }
    , [type, paymentGateway.title])

  return (
    <div className="payment-gateways__added-title">
      <div>
        {paymentGatewayLogo}
        <div>
          {paymentGatewayTitle}
        </div>
      </div>
      <div>
        {type === "edit" && 
          <div data-tip data-for={!paymentGateway.isReplaceable ? "warning-replaceable-tooltip" : ""}>
            <Button
              id="replace-gateway"
              title="PAYMENT_GATEWAYS_REPLACE"
              disabled={!paymentGateway.isReplaceable}
              icon="fas fa-sync"
              buttonType="add"
              onClick={(evt) => {
                dispatch(setPaymentGatewaysField("replacedPaymentGateway", paymentGateway));
                document.querySelector("#payment-gateways__available")?.scrollIntoView?.({behavior: "smooth"});
              }}
              isVisible={isDiconnectButtonVisible}
            />
          </div>
        }
        {type === "edit" && 
          <div data-tip data-for={paymentGateway.hasMappedCurrency ? "warning-disconnect-tooltip" : ""}>
            <Button
              id="disconnect-gateway"
              title="PAYMENT_GATEWAYS_DELETE"
              disabled={paymentGateway.hasMappedCurrency || isLoading}
              isLoading={isLoading}
              icon={paymentGateway.hasMappedCurrency ? "fas fa-lock" : "far fa-trash-alt"}
              buttonType="error"
              onClick={() => disconnectPaymentGateway()}
              isVisible={isDiconnectButtonVisible}
            />
          </div>
        }        
        <Button
          id="visit-website"
          title="PAYMENT_GATEWAYS_VISIT_WEBSITE"
          onClick={(evt: any) => {
            evt.stopPropagation();
            onWebisteButtonClick();
          }}
          buttonType="add"
          isVisible={type === "create" || isViewWebsiteVisible}
        />
      </div>
    </div>
  )
}

export default PaymentGatewaysTitle;
