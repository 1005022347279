import { BillsbyAction } from "../models/BillsbyAction";
import { AppState } from "..";
import { SET_TAX_SUMMARY_FIELD, FETCH_TAX_SUMMARY_SUCCESS, FETCH_CURRENT_MONTH_TAX_SUMMARY_SUCCESS, RESET_TAX_SUMMARY } from "../actions/taxSummaryActions";
import { GetTaxSummaryResponse, JurisdictionType } from "../utils/grpc/generated/Billsby.Protos/billing/private/report/report_pb";

export interface ITaxSummaryReducerState {
  startDate: string,
  endDate: string,
  includeCurrentMonthToDate: boolean,
  hasCurrentMonth: boolean,
  headerMessage: string, 
  tableHeaderType: string
  jurisdictionType: { value: JurisdictionType, label: string},
  details?: GetTaxSummaryResponse,
};

export const initState: ITaxSummaryReducerState = {
  startDate: "",
  endDate: "",
  hasCurrentMonth: true,
  headerMessage: "",
  tableHeaderType: "",
  jurisdictionType: { value: JurisdictionType.JURISDICTION, label: "" },
  includeCurrentMonthToDate: true,
}

export default function taxSummaryReducer(state: ITaxSummaryReducerState = initState, action: BillsbyAction, store: AppState) {
  switch (action.type) {
    case FETCH_TAX_SUMMARY_SUCCESS: {
      const details = action.response as GetTaxSummaryResponse;
      return { ...state, details, currentMonthDetails: undefined }
    }

    case FETCH_CURRENT_MONTH_TAX_SUMMARY_SUCCESS: {
      const currentMonthDetails = action.response as GetTaxSummaryResponse;
      return { ...state, currentMonthDetails }
    }

    case SET_TAX_SUMMARY_FIELD: {
      return { ...state, [action.fieldName]: action.fieldValue }
    }

    case RESET_TAX_SUMMARY: {
      return initState
    }

    default: return state
  }
}