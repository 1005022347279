/* eslint-disable */
// source: accounts.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = Function('return this')();

var google_protobuf_wrappers_pb = require('google-protobuf/google/protobuf/wrappers_pb.js');
goog.object.extend(proto, google_protobuf_wrappers_pb);
goog.exportSymbol('proto.accountingintegrations.private.accounts.CreateFreeAgentIntegrationRequest', null, global);
goog.exportSymbol('proto.accountingintegrations.private.accounts.CreateFreeAgentIntegrationResponse', null, global);
goog.exportSymbol('proto.accountingintegrations.private.accounts.CreateQuickBooksIntegrationRequest', null, global);
goog.exportSymbol('proto.accountingintegrations.private.accounts.CreateQuickBooksIntegrationResponse', null, global);
goog.exportSymbol('proto.accountingintegrations.private.accounts.DeleteIntegrationRequest', null, global);
goog.exportSymbol('proto.accountingintegrations.private.accounts.DeleteIntegrationResponse', null, global);
goog.exportSymbol('proto.accountingintegrations.private.accounts.GetCompanyQuickBooksAccountsRequest', null, global);
goog.exportSymbol('proto.accountingintegrations.private.accounts.GetCompanyQuickBooksAccountsResponse', null, global);
goog.exportSymbol('proto.accountingintegrations.private.accounts.GetQuickBooksAccountsRequest', null, global);
goog.exportSymbol('proto.accountingintegrations.private.accounts.GetQuickBooksAccountsResponse', null, global);
goog.exportSymbol('proto.accountingintegrations.private.accounts.IntegrationType', null, global);
goog.exportSymbol('proto.accountingintegrations.private.accounts.QuickBooksAccount', null, global);
goog.exportSymbol('proto.accountingintegrations.private.accounts.UpdateCompanyQuickBooksAccountsRequest', null, global);
goog.exportSymbol('proto.accountingintegrations.private.accounts.UpdateCompanyQuickBooksAccountsResponse', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.accountingintegrations.private.accounts.GetCompanyQuickBooksAccountsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.accountingintegrations.private.accounts.GetCompanyQuickBooksAccountsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.accountingintegrations.private.accounts.GetCompanyQuickBooksAccountsRequest.displayName = 'proto.accountingintegrations.private.accounts.GetCompanyQuickBooksAccountsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.accountingintegrations.private.accounts.GetCompanyQuickBooksAccountsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.accountingintegrations.private.accounts.GetCompanyQuickBooksAccountsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.accountingintegrations.private.accounts.GetCompanyQuickBooksAccountsResponse.displayName = 'proto.accountingintegrations.private.accounts.GetCompanyQuickBooksAccountsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.accountingintegrations.private.accounts.UpdateCompanyQuickBooksAccountsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.accountingintegrations.private.accounts.UpdateCompanyQuickBooksAccountsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.accountingintegrations.private.accounts.UpdateCompanyQuickBooksAccountsRequest.displayName = 'proto.accountingintegrations.private.accounts.UpdateCompanyQuickBooksAccountsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.accountingintegrations.private.accounts.UpdateCompanyQuickBooksAccountsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.accountingintegrations.private.accounts.UpdateCompanyQuickBooksAccountsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.accountingintegrations.private.accounts.UpdateCompanyQuickBooksAccountsResponse.displayName = 'proto.accountingintegrations.private.accounts.UpdateCompanyQuickBooksAccountsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.accountingintegrations.private.accounts.QuickBooksAccount = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.accountingintegrations.private.accounts.QuickBooksAccount, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.accountingintegrations.private.accounts.QuickBooksAccount.displayName = 'proto.accountingintegrations.private.accounts.QuickBooksAccount';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.accountingintegrations.private.accounts.GetQuickBooksAccountsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.accountingintegrations.private.accounts.GetQuickBooksAccountsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.accountingintegrations.private.accounts.GetQuickBooksAccountsRequest.displayName = 'proto.accountingintegrations.private.accounts.GetQuickBooksAccountsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.accountingintegrations.private.accounts.GetQuickBooksAccountsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.accountingintegrations.private.accounts.GetQuickBooksAccountsResponse.repeatedFields_, null);
};
goog.inherits(proto.accountingintegrations.private.accounts.GetQuickBooksAccountsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.accountingintegrations.private.accounts.GetQuickBooksAccountsResponse.displayName = 'proto.accountingintegrations.private.accounts.GetQuickBooksAccountsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.accountingintegrations.private.accounts.DeleteIntegrationRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.accountingintegrations.private.accounts.DeleteIntegrationRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.accountingintegrations.private.accounts.DeleteIntegrationRequest.displayName = 'proto.accountingintegrations.private.accounts.DeleteIntegrationRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.accountingintegrations.private.accounts.DeleteIntegrationResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.accountingintegrations.private.accounts.DeleteIntegrationResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.accountingintegrations.private.accounts.DeleteIntegrationResponse.displayName = 'proto.accountingintegrations.private.accounts.DeleteIntegrationResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.accountingintegrations.private.accounts.CreateFreeAgentIntegrationRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.accountingintegrations.private.accounts.CreateFreeAgentIntegrationRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.accountingintegrations.private.accounts.CreateFreeAgentIntegrationRequest.displayName = 'proto.accountingintegrations.private.accounts.CreateFreeAgentIntegrationRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.accountingintegrations.private.accounts.CreateFreeAgentIntegrationResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.accountingintegrations.private.accounts.CreateFreeAgentIntegrationResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.accountingintegrations.private.accounts.CreateFreeAgentIntegrationResponse.displayName = 'proto.accountingintegrations.private.accounts.CreateFreeAgentIntegrationResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.accountingintegrations.private.accounts.CreateQuickBooksIntegrationRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.accountingintegrations.private.accounts.CreateQuickBooksIntegrationRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.accountingintegrations.private.accounts.CreateQuickBooksIntegrationRequest.displayName = 'proto.accountingintegrations.private.accounts.CreateQuickBooksIntegrationRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.accountingintegrations.private.accounts.CreateQuickBooksIntegrationResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.accountingintegrations.private.accounts.CreateQuickBooksIntegrationResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.accountingintegrations.private.accounts.CreateQuickBooksIntegrationResponse.displayName = 'proto.accountingintegrations.private.accounts.CreateQuickBooksIntegrationResponse';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.accountingintegrations.private.accounts.GetCompanyQuickBooksAccountsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.accountingintegrations.private.accounts.GetCompanyQuickBooksAccountsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.accountingintegrations.private.accounts.GetCompanyQuickBooksAccountsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.accountingintegrations.private.accounts.GetCompanyQuickBooksAccountsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    companyId: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.accountingintegrations.private.accounts.GetCompanyQuickBooksAccountsRequest}
 */
proto.accountingintegrations.private.accounts.GetCompanyQuickBooksAccountsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.accountingintegrations.private.accounts.GetCompanyQuickBooksAccountsRequest;
  return proto.accountingintegrations.private.accounts.GetCompanyQuickBooksAccountsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.accountingintegrations.private.accounts.GetCompanyQuickBooksAccountsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.accountingintegrations.private.accounts.GetCompanyQuickBooksAccountsRequest}
 */
proto.accountingintegrations.private.accounts.GetCompanyQuickBooksAccountsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setCompanyId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.accountingintegrations.private.accounts.GetCompanyQuickBooksAccountsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.accountingintegrations.private.accounts.GetCompanyQuickBooksAccountsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.accountingintegrations.private.accounts.GetCompanyQuickBooksAccountsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.accountingintegrations.private.accounts.GetCompanyQuickBooksAccountsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCompanyId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
};


/**
 * optional int32 company_id = 1;
 * @return {number}
 */
proto.accountingintegrations.private.accounts.GetCompanyQuickBooksAccountsRequest.prototype.getCompanyId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.accountingintegrations.private.accounts.GetCompanyQuickBooksAccountsRequest} returns this
 */
proto.accountingintegrations.private.accounts.GetCompanyQuickBooksAccountsRequest.prototype.setCompanyId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.accountingintegrations.private.accounts.GetCompanyQuickBooksAccountsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.accountingintegrations.private.accounts.GetCompanyQuickBooksAccountsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.accountingintegrations.private.accounts.GetCompanyQuickBooksAccountsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.accountingintegrations.private.accounts.GetCompanyQuickBooksAccountsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    invoicesAccountId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    creditNotesAccountId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    refundsAccountId: jspb.Message.getFieldWithDefault(msg, 3, ""),
    badDebtAccountId: jspb.Message.getFieldWithDefault(msg, 4, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.accountingintegrations.private.accounts.GetCompanyQuickBooksAccountsResponse}
 */
proto.accountingintegrations.private.accounts.GetCompanyQuickBooksAccountsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.accountingintegrations.private.accounts.GetCompanyQuickBooksAccountsResponse;
  return proto.accountingintegrations.private.accounts.GetCompanyQuickBooksAccountsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.accountingintegrations.private.accounts.GetCompanyQuickBooksAccountsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.accountingintegrations.private.accounts.GetCompanyQuickBooksAccountsResponse}
 */
proto.accountingintegrations.private.accounts.GetCompanyQuickBooksAccountsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setInvoicesAccountId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setCreditNotesAccountId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setRefundsAccountId(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setBadDebtAccountId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.accountingintegrations.private.accounts.GetCompanyQuickBooksAccountsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.accountingintegrations.private.accounts.GetCompanyQuickBooksAccountsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.accountingintegrations.private.accounts.GetCompanyQuickBooksAccountsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.accountingintegrations.private.accounts.GetCompanyQuickBooksAccountsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getInvoicesAccountId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getCreditNotesAccountId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getRefundsAccountId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getBadDebtAccountId();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
};


/**
 * optional string invoices_account_id = 1;
 * @return {string}
 */
proto.accountingintegrations.private.accounts.GetCompanyQuickBooksAccountsResponse.prototype.getInvoicesAccountId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.accountingintegrations.private.accounts.GetCompanyQuickBooksAccountsResponse} returns this
 */
proto.accountingintegrations.private.accounts.GetCompanyQuickBooksAccountsResponse.prototype.setInvoicesAccountId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string credit_notes_account_id = 2;
 * @return {string}
 */
proto.accountingintegrations.private.accounts.GetCompanyQuickBooksAccountsResponse.prototype.getCreditNotesAccountId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.accountingintegrations.private.accounts.GetCompanyQuickBooksAccountsResponse} returns this
 */
proto.accountingintegrations.private.accounts.GetCompanyQuickBooksAccountsResponse.prototype.setCreditNotesAccountId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string refunds_account_id = 3;
 * @return {string}
 */
proto.accountingintegrations.private.accounts.GetCompanyQuickBooksAccountsResponse.prototype.getRefundsAccountId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.accountingintegrations.private.accounts.GetCompanyQuickBooksAccountsResponse} returns this
 */
proto.accountingintegrations.private.accounts.GetCompanyQuickBooksAccountsResponse.prototype.setRefundsAccountId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string bad_debt_account_id = 4;
 * @return {string}
 */
proto.accountingintegrations.private.accounts.GetCompanyQuickBooksAccountsResponse.prototype.getBadDebtAccountId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.accountingintegrations.private.accounts.GetCompanyQuickBooksAccountsResponse} returns this
 */
proto.accountingintegrations.private.accounts.GetCompanyQuickBooksAccountsResponse.prototype.setBadDebtAccountId = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.accountingintegrations.private.accounts.UpdateCompanyQuickBooksAccountsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.accountingintegrations.private.accounts.UpdateCompanyQuickBooksAccountsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.accountingintegrations.private.accounts.UpdateCompanyQuickBooksAccountsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.accountingintegrations.private.accounts.UpdateCompanyQuickBooksAccountsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    companyId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    invoicesAccountId: (f = msg.getInvoicesAccountId()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    creditNotesAccountId: (f = msg.getCreditNotesAccountId()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    refundsAccountId: (f = msg.getRefundsAccountId()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    badDebtAccountId: (f = msg.getBadDebtAccountId()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.accountingintegrations.private.accounts.UpdateCompanyQuickBooksAccountsRequest}
 */
proto.accountingintegrations.private.accounts.UpdateCompanyQuickBooksAccountsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.accountingintegrations.private.accounts.UpdateCompanyQuickBooksAccountsRequest;
  return proto.accountingintegrations.private.accounts.UpdateCompanyQuickBooksAccountsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.accountingintegrations.private.accounts.UpdateCompanyQuickBooksAccountsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.accountingintegrations.private.accounts.UpdateCompanyQuickBooksAccountsRequest}
 */
proto.accountingintegrations.private.accounts.UpdateCompanyQuickBooksAccountsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setCompanyId(value);
      break;
    case 2:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setInvoicesAccountId(value);
      break;
    case 3:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setCreditNotesAccountId(value);
      break;
    case 4:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setRefundsAccountId(value);
      break;
    case 5:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setBadDebtAccountId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.accountingintegrations.private.accounts.UpdateCompanyQuickBooksAccountsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.accountingintegrations.private.accounts.UpdateCompanyQuickBooksAccountsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.accountingintegrations.private.accounts.UpdateCompanyQuickBooksAccountsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.accountingintegrations.private.accounts.UpdateCompanyQuickBooksAccountsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCompanyId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getInvoicesAccountId();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getCreditNotesAccountId();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getRefundsAccountId();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getBadDebtAccountId();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
};


/**
 * optional int32 company_id = 1;
 * @return {number}
 */
proto.accountingintegrations.private.accounts.UpdateCompanyQuickBooksAccountsRequest.prototype.getCompanyId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.accountingintegrations.private.accounts.UpdateCompanyQuickBooksAccountsRequest} returns this
 */
proto.accountingintegrations.private.accounts.UpdateCompanyQuickBooksAccountsRequest.prototype.setCompanyId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional google.protobuf.StringValue invoices_account_id = 2;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.accountingintegrations.private.accounts.UpdateCompanyQuickBooksAccountsRequest.prototype.getInvoicesAccountId = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 2));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.accountingintegrations.private.accounts.UpdateCompanyQuickBooksAccountsRequest} returns this
*/
proto.accountingintegrations.private.accounts.UpdateCompanyQuickBooksAccountsRequest.prototype.setInvoicesAccountId = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.accountingintegrations.private.accounts.UpdateCompanyQuickBooksAccountsRequest} returns this
 */
proto.accountingintegrations.private.accounts.UpdateCompanyQuickBooksAccountsRequest.prototype.clearInvoicesAccountId = function() {
  return this.setInvoicesAccountId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.accountingintegrations.private.accounts.UpdateCompanyQuickBooksAccountsRequest.prototype.hasInvoicesAccountId = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional google.protobuf.StringValue credit_notes_account_id = 3;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.accountingintegrations.private.accounts.UpdateCompanyQuickBooksAccountsRequest.prototype.getCreditNotesAccountId = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 3));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.accountingintegrations.private.accounts.UpdateCompanyQuickBooksAccountsRequest} returns this
*/
proto.accountingintegrations.private.accounts.UpdateCompanyQuickBooksAccountsRequest.prototype.setCreditNotesAccountId = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.accountingintegrations.private.accounts.UpdateCompanyQuickBooksAccountsRequest} returns this
 */
proto.accountingintegrations.private.accounts.UpdateCompanyQuickBooksAccountsRequest.prototype.clearCreditNotesAccountId = function() {
  return this.setCreditNotesAccountId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.accountingintegrations.private.accounts.UpdateCompanyQuickBooksAccountsRequest.prototype.hasCreditNotesAccountId = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional google.protobuf.StringValue refunds_account_id = 4;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.accountingintegrations.private.accounts.UpdateCompanyQuickBooksAccountsRequest.prototype.getRefundsAccountId = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 4));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.accountingintegrations.private.accounts.UpdateCompanyQuickBooksAccountsRequest} returns this
*/
proto.accountingintegrations.private.accounts.UpdateCompanyQuickBooksAccountsRequest.prototype.setRefundsAccountId = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.accountingintegrations.private.accounts.UpdateCompanyQuickBooksAccountsRequest} returns this
 */
proto.accountingintegrations.private.accounts.UpdateCompanyQuickBooksAccountsRequest.prototype.clearRefundsAccountId = function() {
  return this.setRefundsAccountId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.accountingintegrations.private.accounts.UpdateCompanyQuickBooksAccountsRequest.prototype.hasRefundsAccountId = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional google.protobuf.StringValue bad_debt_account_id = 5;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.accountingintegrations.private.accounts.UpdateCompanyQuickBooksAccountsRequest.prototype.getBadDebtAccountId = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 5));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.accountingintegrations.private.accounts.UpdateCompanyQuickBooksAccountsRequest} returns this
*/
proto.accountingintegrations.private.accounts.UpdateCompanyQuickBooksAccountsRequest.prototype.setBadDebtAccountId = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.accountingintegrations.private.accounts.UpdateCompanyQuickBooksAccountsRequest} returns this
 */
proto.accountingintegrations.private.accounts.UpdateCompanyQuickBooksAccountsRequest.prototype.clearBadDebtAccountId = function() {
  return this.setBadDebtAccountId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.accountingintegrations.private.accounts.UpdateCompanyQuickBooksAccountsRequest.prototype.hasBadDebtAccountId = function() {
  return jspb.Message.getField(this, 5) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.accountingintegrations.private.accounts.UpdateCompanyQuickBooksAccountsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.accountingintegrations.private.accounts.UpdateCompanyQuickBooksAccountsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.accountingintegrations.private.accounts.UpdateCompanyQuickBooksAccountsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.accountingintegrations.private.accounts.UpdateCompanyQuickBooksAccountsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    invoicesAccountId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    creditNotesAccountId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    refundsAccountId: jspb.Message.getFieldWithDefault(msg, 3, ""),
    badDebtAccountId: jspb.Message.getFieldWithDefault(msg, 4, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.accountingintegrations.private.accounts.UpdateCompanyQuickBooksAccountsResponse}
 */
proto.accountingintegrations.private.accounts.UpdateCompanyQuickBooksAccountsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.accountingintegrations.private.accounts.UpdateCompanyQuickBooksAccountsResponse;
  return proto.accountingintegrations.private.accounts.UpdateCompanyQuickBooksAccountsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.accountingintegrations.private.accounts.UpdateCompanyQuickBooksAccountsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.accountingintegrations.private.accounts.UpdateCompanyQuickBooksAccountsResponse}
 */
proto.accountingintegrations.private.accounts.UpdateCompanyQuickBooksAccountsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setInvoicesAccountId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setCreditNotesAccountId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setRefundsAccountId(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setBadDebtAccountId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.accountingintegrations.private.accounts.UpdateCompanyQuickBooksAccountsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.accountingintegrations.private.accounts.UpdateCompanyQuickBooksAccountsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.accountingintegrations.private.accounts.UpdateCompanyQuickBooksAccountsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.accountingintegrations.private.accounts.UpdateCompanyQuickBooksAccountsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getInvoicesAccountId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getCreditNotesAccountId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getRefundsAccountId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getBadDebtAccountId();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
};


/**
 * optional string invoices_account_id = 1;
 * @return {string}
 */
proto.accountingintegrations.private.accounts.UpdateCompanyQuickBooksAccountsResponse.prototype.getInvoicesAccountId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.accountingintegrations.private.accounts.UpdateCompanyQuickBooksAccountsResponse} returns this
 */
proto.accountingintegrations.private.accounts.UpdateCompanyQuickBooksAccountsResponse.prototype.setInvoicesAccountId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string credit_notes_account_id = 2;
 * @return {string}
 */
proto.accountingintegrations.private.accounts.UpdateCompanyQuickBooksAccountsResponse.prototype.getCreditNotesAccountId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.accountingintegrations.private.accounts.UpdateCompanyQuickBooksAccountsResponse} returns this
 */
proto.accountingintegrations.private.accounts.UpdateCompanyQuickBooksAccountsResponse.prototype.setCreditNotesAccountId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string refunds_account_id = 3;
 * @return {string}
 */
proto.accountingintegrations.private.accounts.UpdateCompanyQuickBooksAccountsResponse.prototype.getRefundsAccountId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.accountingintegrations.private.accounts.UpdateCompanyQuickBooksAccountsResponse} returns this
 */
proto.accountingintegrations.private.accounts.UpdateCompanyQuickBooksAccountsResponse.prototype.setRefundsAccountId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string bad_debt_account_id = 4;
 * @return {string}
 */
proto.accountingintegrations.private.accounts.UpdateCompanyQuickBooksAccountsResponse.prototype.getBadDebtAccountId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.accountingintegrations.private.accounts.UpdateCompanyQuickBooksAccountsResponse} returns this
 */
proto.accountingintegrations.private.accounts.UpdateCompanyQuickBooksAccountsResponse.prototype.setBadDebtAccountId = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.accountingintegrations.private.accounts.QuickBooksAccount.prototype.toObject = function(opt_includeInstance) {
  return proto.accountingintegrations.private.accounts.QuickBooksAccount.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.accountingintegrations.private.accounts.QuickBooksAccount} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.accountingintegrations.private.accounts.QuickBooksAccount.toObject = function(includeInstance, msg) {
  var f, obj = {
    quickBooksAccountId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    accountType: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.accountingintegrations.private.accounts.QuickBooksAccount}
 */
proto.accountingintegrations.private.accounts.QuickBooksAccount.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.accountingintegrations.private.accounts.QuickBooksAccount;
  return proto.accountingintegrations.private.accounts.QuickBooksAccount.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.accountingintegrations.private.accounts.QuickBooksAccount} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.accountingintegrations.private.accounts.QuickBooksAccount}
 */
proto.accountingintegrations.private.accounts.QuickBooksAccount.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setQuickBooksAccountId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setAccountType(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.accountingintegrations.private.accounts.QuickBooksAccount.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.accountingintegrations.private.accounts.QuickBooksAccount.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.accountingintegrations.private.accounts.QuickBooksAccount} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.accountingintegrations.private.accounts.QuickBooksAccount.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getQuickBooksAccountId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getAccountType();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional string quick_books_account_id = 1;
 * @return {string}
 */
proto.accountingintegrations.private.accounts.QuickBooksAccount.prototype.getQuickBooksAccountId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.accountingintegrations.private.accounts.QuickBooksAccount} returns this
 */
proto.accountingintegrations.private.accounts.QuickBooksAccount.prototype.setQuickBooksAccountId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.accountingintegrations.private.accounts.QuickBooksAccount.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.accountingintegrations.private.accounts.QuickBooksAccount} returns this
 */
proto.accountingintegrations.private.accounts.QuickBooksAccount.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string account_type = 3;
 * @return {string}
 */
proto.accountingintegrations.private.accounts.QuickBooksAccount.prototype.getAccountType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.accountingintegrations.private.accounts.QuickBooksAccount} returns this
 */
proto.accountingintegrations.private.accounts.QuickBooksAccount.prototype.setAccountType = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.accountingintegrations.private.accounts.GetQuickBooksAccountsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.accountingintegrations.private.accounts.GetQuickBooksAccountsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.accountingintegrations.private.accounts.GetQuickBooksAccountsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.accountingintegrations.private.accounts.GetQuickBooksAccountsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    companyId: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.accountingintegrations.private.accounts.GetQuickBooksAccountsRequest}
 */
proto.accountingintegrations.private.accounts.GetQuickBooksAccountsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.accountingintegrations.private.accounts.GetQuickBooksAccountsRequest;
  return proto.accountingintegrations.private.accounts.GetQuickBooksAccountsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.accountingintegrations.private.accounts.GetQuickBooksAccountsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.accountingintegrations.private.accounts.GetQuickBooksAccountsRequest}
 */
proto.accountingintegrations.private.accounts.GetQuickBooksAccountsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setCompanyId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.accountingintegrations.private.accounts.GetQuickBooksAccountsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.accountingintegrations.private.accounts.GetQuickBooksAccountsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.accountingintegrations.private.accounts.GetQuickBooksAccountsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.accountingintegrations.private.accounts.GetQuickBooksAccountsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCompanyId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
};


/**
 * optional int32 company_id = 1;
 * @return {number}
 */
proto.accountingintegrations.private.accounts.GetQuickBooksAccountsRequest.prototype.getCompanyId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.accountingintegrations.private.accounts.GetQuickBooksAccountsRequest} returns this
 */
proto.accountingintegrations.private.accounts.GetQuickBooksAccountsRequest.prototype.setCompanyId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.accountingintegrations.private.accounts.GetQuickBooksAccountsResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.accountingintegrations.private.accounts.GetQuickBooksAccountsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.accountingintegrations.private.accounts.GetQuickBooksAccountsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.accountingintegrations.private.accounts.GetQuickBooksAccountsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.accountingintegrations.private.accounts.GetQuickBooksAccountsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    accountsList: jspb.Message.toObjectList(msg.getAccountsList(),
    proto.accountingintegrations.private.accounts.QuickBooksAccount.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.accountingintegrations.private.accounts.GetQuickBooksAccountsResponse}
 */
proto.accountingintegrations.private.accounts.GetQuickBooksAccountsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.accountingintegrations.private.accounts.GetQuickBooksAccountsResponse;
  return proto.accountingintegrations.private.accounts.GetQuickBooksAccountsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.accountingintegrations.private.accounts.GetQuickBooksAccountsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.accountingintegrations.private.accounts.GetQuickBooksAccountsResponse}
 */
proto.accountingintegrations.private.accounts.GetQuickBooksAccountsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.accountingintegrations.private.accounts.QuickBooksAccount;
      reader.readMessage(value,proto.accountingintegrations.private.accounts.QuickBooksAccount.deserializeBinaryFromReader);
      msg.addAccounts(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.accountingintegrations.private.accounts.GetQuickBooksAccountsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.accountingintegrations.private.accounts.GetQuickBooksAccountsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.accountingintegrations.private.accounts.GetQuickBooksAccountsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.accountingintegrations.private.accounts.GetQuickBooksAccountsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAccountsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.accountingintegrations.private.accounts.QuickBooksAccount.serializeBinaryToWriter
    );
  }
};


/**
 * repeated QuickBooksAccount accounts = 1;
 * @return {!Array<!proto.accountingintegrations.private.accounts.QuickBooksAccount>}
 */
proto.accountingintegrations.private.accounts.GetQuickBooksAccountsResponse.prototype.getAccountsList = function() {
  return /** @type{!Array<!proto.accountingintegrations.private.accounts.QuickBooksAccount>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.accountingintegrations.private.accounts.QuickBooksAccount, 1));
};


/**
 * @param {!Array<!proto.accountingintegrations.private.accounts.QuickBooksAccount>} value
 * @return {!proto.accountingintegrations.private.accounts.GetQuickBooksAccountsResponse} returns this
*/
proto.accountingintegrations.private.accounts.GetQuickBooksAccountsResponse.prototype.setAccountsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.accountingintegrations.private.accounts.QuickBooksAccount=} opt_value
 * @param {number=} opt_index
 * @return {!proto.accountingintegrations.private.accounts.QuickBooksAccount}
 */
proto.accountingintegrations.private.accounts.GetQuickBooksAccountsResponse.prototype.addAccounts = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.accountingintegrations.private.accounts.QuickBooksAccount, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.accountingintegrations.private.accounts.GetQuickBooksAccountsResponse} returns this
 */
proto.accountingintegrations.private.accounts.GetQuickBooksAccountsResponse.prototype.clearAccountsList = function() {
  return this.setAccountsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.accountingintegrations.private.accounts.DeleteIntegrationRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.accountingintegrations.private.accounts.DeleteIntegrationRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.accountingintegrations.private.accounts.DeleteIntegrationRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.accountingintegrations.private.accounts.DeleteIntegrationRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    companyId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    integrationType: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.accountingintegrations.private.accounts.DeleteIntegrationRequest}
 */
proto.accountingintegrations.private.accounts.DeleteIntegrationRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.accountingintegrations.private.accounts.DeleteIntegrationRequest;
  return proto.accountingintegrations.private.accounts.DeleteIntegrationRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.accountingintegrations.private.accounts.DeleteIntegrationRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.accountingintegrations.private.accounts.DeleteIntegrationRequest}
 */
proto.accountingintegrations.private.accounts.DeleteIntegrationRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setCompanyId(value);
      break;
    case 2:
      var value = /** @type {!proto.accountingintegrations.private.accounts.IntegrationType} */ (reader.readEnum());
      msg.setIntegrationType(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.accountingintegrations.private.accounts.DeleteIntegrationRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.accountingintegrations.private.accounts.DeleteIntegrationRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.accountingintegrations.private.accounts.DeleteIntegrationRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.accountingintegrations.private.accounts.DeleteIntegrationRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCompanyId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getIntegrationType();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
};


/**
 * optional int32 company_id = 1;
 * @return {number}
 */
proto.accountingintegrations.private.accounts.DeleteIntegrationRequest.prototype.getCompanyId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.accountingintegrations.private.accounts.DeleteIntegrationRequest} returns this
 */
proto.accountingintegrations.private.accounts.DeleteIntegrationRequest.prototype.setCompanyId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional IntegrationType integration_type = 2;
 * @return {!proto.accountingintegrations.private.accounts.IntegrationType}
 */
proto.accountingintegrations.private.accounts.DeleteIntegrationRequest.prototype.getIntegrationType = function() {
  return /** @type {!proto.accountingintegrations.private.accounts.IntegrationType} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.accountingintegrations.private.accounts.IntegrationType} value
 * @return {!proto.accountingintegrations.private.accounts.DeleteIntegrationRequest} returns this
 */
proto.accountingintegrations.private.accounts.DeleteIntegrationRequest.prototype.setIntegrationType = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.accountingintegrations.private.accounts.DeleteIntegrationResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.accountingintegrations.private.accounts.DeleteIntegrationResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.accountingintegrations.private.accounts.DeleteIntegrationResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.accountingintegrations.private.accounts.DeleteIntegrationResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    isSuccess: jspb.Message.getBooleanFieldWithDefault(msg, 1, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.accountingintegrations.private.accounts.DeleteIntegrationResponse}
 */
proto.accountingintegrations.private.accounts.DeleteIntegrationResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.accountingintegrations.private.accounts.DeleteIntegrationResponse;
  return proto.accountingintegrations.private.accounts.DeleteIntegrationResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.accountingintegrations.private.accounts.DeleteIntegrationResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.accountingintegrations.private.accounts.DeleteIntegrationResponse}
 */
proto.accountingintegrations.private.accounts.DeleteIntegrationResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsSuccess(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.accountingintegrations.private.accounts.DeleteIntegrationResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.accountingintegrations.private.accounts.DeleteIntegrationResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.accountingintegrations.private.accounts.DeleteIntegrationResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.accountingintegrations.private.accounts.DeleteIntegrationResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getIsSuccess();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
};


/**
 * optional bool is_success = 1;
 * @return {boolean}
 */
proto.accountingintegrations.private.accounts.DeleteIntegrationResponse.prototype.getIsSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.accountingintegrations.private.accounts.DeleteIntegrationResponse} returns this
 */
proto.accountingintegrations.private.accounts.DeleteIntegrationResponse.prototype.setIsSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.accountingintegrations.private.accounts.CreateFreeAgentIntegrationRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.accountingintegrations.private.accounts.CreateFreeAgentIntegrationRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.accountingintegrations.private.accounts.CreateFreeAgentIntegrationRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.accountingintegrations.private.accounts.CreateFreeAgentIntegrationRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    companyId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    code: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.accountingintegrations.private.accounts.CreateFreeAgentIntegrationRequest}
 */
proto.accountingintegrations.private.accounts.CreateFreeAgentIntegrationRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.accountingintegrations.private.accounts.CreateFreeAgentIntegrationRequest;
  return proto.accountingintegrations.private.accounts.CreateFreeAgentIntegrationRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.accountingintegrations.private.accounts.CreateFreeAgentIntegrationRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.accountingintegrations.private.accounts.CreateFreeAgentIntegrationRequest}
 */
proto.accountingintegrations.private.accounts.CreateFreeAgentIntegrationRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setCompanyId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setCode(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.accountingintegrations.private.accounts.CreateFreeAgentIntegrationRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.accountingintegrations.private.accounts.CreateFreeAgentIntegrationRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.accountingintegrations.private.accounts.CreateFreeAgentIntegrationRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.accountingintegrations.private.accounts.CreateFreeAgentIntegrationRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCompanyId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getCode();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional int32 company_id = 1;
 * @return {number}
 */
proto.accountingintegrations.private.accounts.CreateFreeAgentIntegrationRequest.prototype.getCompanyId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.accountingintegrations.private.accounts.CreateFreeAgentIntegrationRequest} returns this
 */
proto.accountingintegrations.private.accounts.CreateFreeAgentIntegrationRequest.prototype.setCompanyId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string code = 2;
 * @return {string}
 */
proto.accountingintegrations.private.accounts.CreateFreeAgentIntegrationRequest.prototype.getCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.accountingintegrations.private.accounts.CreateFreeAgentIntegrationRequest} returns this
 */
proto.accountingintegrations.private.accounts.CreateFreeAgentIntegrationRequest.prototype.setCode = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.accountingintegrations.private.accounts.CreateFreeAgentIntegrationResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.accountingintegrations.private.accounts.CreateFreeAgentIntegrationResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.accountingintegrations.private.accounts.CreateFreeAgentIntegrationResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.accountingintegrations.private.accounts.CreateFreeAgentIntegrationResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    isSuccess: jspb.Message.getBooleanFieldWithDefault(msg, 1, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.accountingintegrations.private.accounts.CreateFreeAgentIntegrationResponse}
 */
proto.accountingintegrations.private.accounts.CreateFreeAgentIntegrationResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.accountingintegrations.private.accounts.CreateFreeAgentIntegrationResponse;
  return proto.accountingintegrations.private.accounts.CreateFreeAgentIntegrationResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.accountingintegrations.private.accounts.CreateFreeAgentIntegrationResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.accountingintegrations.private.accounts.CreateFreeAgentIntegrationResponse}
 */
proto.accountingintegrations.private.accounts.CreateFreeAgentIntegrationResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsSuccess(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.accountingintegrations.private.accounts.CreateFreeAgentIntegrationResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.accountingintegrations.private.accounts.CreateFreeAgentIntegrationResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.accountingintegrations.private.accounts.CreateFreeAgentIntegrationResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.accountingintegrations.private.accounts.CreateFreeAgentIntegrationResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getIsSuccess();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
};


/**
 * optional bool is_success = 1;
 * @return {boolean}
 */
proto.accountingintegrations.private.accounts.CreateFreeAgentIntegrationResponse.prototype.getIsSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.accountingintegrations.private.accounts.CreateFreeAgentIntegrationResponse} returns this
 */
proto.accountingintegrations.private.accounts.CreateFreeAgentIntegrationResponse.prototype.setIsSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.accountingintegrations.private.accounts.CreateQuickBooksIntegrationRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.accountingintegrations.private.accounts.CreateQuickBooksIntegrationRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.accountingintegrations.private.accounts.CreateQuickBooksIntegrationRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.accountingintegrations.private.accounts.CreateQuickBooksIntegrationRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    companyId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    code: jspb.Message.getFieldWithDefault(msg, 2, ""),
    realmId: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.accountingintegrations.private.accounts.CreateQuickBooksIntegrationRequest}
 */
proto.accountingintegrations.private.accounts.CreateQuickBooksIntegrationRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.accountingintegrations.private.accounts.CreateQuickBooksIntegrationRequest;
  return proto.accountingintegrations.private.accounts.CreateQuickBooksIntegrationRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.accountingintegrations.private.accounts.CreateQuickBooksIntegrationRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.accountingintegrations.private.accounts.CreateQuickBooksIntegrationRequest}
 */
proto.accountingintegrations.private.accounts.CreateQuickBooksIntegrationRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setCompanyId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setCode(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setRealmId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.accountingintegrations.private.accounts.CreateQuickBooksIntegrationRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.accountingintegrations.private.accounts.CreateQuickBooksIntegrationRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.accountingintegrations.private.accounts.CreateQuickBooksIntegrationRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.accountingintegrations.private.accounts.CreateQuickBooksIntegrationRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCompanyId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getCode();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getRealmId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional int32 company_id = 1;
 * @return {number}
 */
proto.accountingintegrations.private.accounts.CreateQuickBooksIntegrationRequest.prototype.getCompanyId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.accountingintegrations.private.accounts.CreateQuickBooksIntegrationRequest} returns this
 */
proto.accountingintegrations.private.accounts.CreateQuickBooksIntegrationRequest.prototype.setCompanyId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string code = 2;
 * @return {string}
 */
proto.accountingintegrations.private.accounts.CreateQuickBooksIntegrationRequest.prototype.getCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.accountingintegrations.private.accounts.CreateQuickBooksIntegrationRequest} returns this
 */
proto.accountingintegrations.private.accounts.CreateQuickBooksIntegrationRequest.prototype.setCode = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string realm_id = 3;
 * @return {string}
 */
proto.accountingintegrations.private.accounts.CreateQuickBooksIntegrationRequest.prototype.getRealmId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.accountingintegrations.private.accounts.CreateQuickBooksIntegrationRequest} returns this
 */
proto.accountingintegrations.private.accounts.CreateQuickBooksIntegrationRequest.prototype.setRealmId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.accountingintegrations.private.accounts.CreateQuickBooksIntegrationResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.accountingintegrations.private.accounts.CreateQuickBooksIntegrationResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.accountingintegrations.private.accounts.CreateQuickBooksIntegrationResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.accountingintegrations.private.accounts.CreateQuickBooksIntegrationResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    isSuccess: jspb.Message.getBooleanFieldWithDefault(msg, 1, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.accountingintegrations.private.accounts.CreateQuickBooksIntegrationResponse}
 */
proto.accountingintegrations.private.accounts.CreateQuickBooksIntegrationResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.accountingintegrations.private.accounts.CreateQuickBooksIntegrationResponse;
  return proto.accountingintegrations.private.accounts.CreateQuickBooksIntegrationResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.accountingintegrations.private.accounts.CreateQuickBooksIntegrationResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.accountingintegrations.private.accounts.CreateQuickBooksIntegrationResponse}
 */
proto.accountingintegrations.private.accounts.CreateQuickBooksIntegrationResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsSuccess(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.accountingintegrations.private.accounts.CreateQuickBooksIntegrationResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.accountingintegrations.private.accounts.CreateQuickBooksIntegrationResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.accountingintegrations.private.accounts.CreateQuickBooksIntegrationResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.accountingintegrations.private.accounts.CreateQuickBooksIntegrationResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getIsSuccess();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
};


/**
 * optional bool is_success = 1;
 * @return {boolean}
 */
proto.accountingintegrations.private.accounts.CreateQuickBooksIntegrationResponse.prototype.getIsSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.accountingintegrations.private.accounts.CreateQuickBooksIntegrationResponse} returns this
 */
proto.accountingintegrations.private.accounts.CreateQuickBooksIntegrationResponse.prototype.setIsSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * @enum {number}
 */
proto.accountingintegrations.private.accounts.IntegrationType = {
  FREE_AGENT: 0,
  QUICKBOOKS: 1
};

goog.object.extend(exports, proto.accountingintegrations.private.accounts);
