import React from "react";
import {  useDispatch, useSelector } from "react-redux";
import history from "../../utils/history";
import logo from "../../images/billsby-logo.svg";
import { logout } from "../../utils/authUtils";
import "./Header.scss";
import { ICompany } from "../../models/Auth";
import Button from "../../components/ui/button/Button";
import { AppState } from "../..";
import GettingStarted from "../../components/getting-started/GettingStarted";
import { useLocation } from "react-router";
import { IAuthReducerState } from "../../reducers/authReducer";
import { IAccountingIntegrationsReducerState } from "../../reducers/accountingIntegrationsReducer";

interface IHeaderProps  {
  minimiseNav?: any,
  isMinimised?: boolean,
  isDoingQuiz?: boolean,
  isSimplified?: boolean,
}

const Header: React.FC<IHeaderProps> = ({ isSimplified, isDoingQuiz, minimiseNav, isMinimised }) => {
  const auth = useSelector<AppState, IAuthReducerState>(state => state.auth);
  const accountingIntegrationsReducer = useSelector<AppState, IAccountingIntegrationsReducerState>(state => state.accountingIntegrationsReducer);
  const freeTrialToken = useSelector<AppState, string | undefined>(state => state.registration.freeTrialToken);
  
  const location = useLocation();
  const dispatch = useDispatch();

  const { parsedToken, selectedCompanyId, companies } = auth;

  const goTo = (url: string) => {
    history.push(url);
  }

  if (isSimplified) {
    return (
      <div className="header">
        <div className="header__logo">
          <img src={logo} alt="Billsby Logo" style={{ cursor: "auto" }} />
        </div>
      </div>
    )
  }

  if (!parsedToken || isDoingQuiz) {
    return (
      <div className="header">
        <div className="header__logo">
          <img src={logo} alt="Billsby Logo" onClick={() => !isDoingQuiz && goTo("/login")} />
        </div>
        <Button
          id="header-signup"
          className="header__signup"
          title="SIGNUP"
          onClick={() => goTo(freeTrialToken ? `/registration?key=${freeTrialToken}` : "/registration")}
          isVisible={!location.pathname.includes("/registration")}
        />
      </div>
    )
  }

  if (parsedToken && !selectedCompanyId) {
    return (
      <div className="header">
        <div className="header__logo">
          <img src={logo} alt="Billsby Logo" style={{ cursor: "auto" }} />
        </div>
        <Button
          id="header-logout"
          className="header__logout"
          title="LOGOUT"
          onClick={logout}
          icon="far fa-sign-out-alt"
        />
      </div>
    )
  }

  if (!selectedCompanyId) { return null }

  const selectedCompany = companies.find((company: ICompany) => company.companyId === selectedCompanyId) as ICompany;

  if (!selectedCompany) { return null }

  return (
    <GettingStarted
      minimiseNav={minimiseNav}
      isMinimised={isMinimised || false}
    />
  )
}

export default Header;