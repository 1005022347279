import React, { useEffect } from "react";
import "./CustomFields.scss";
import CircleIcon from "../ui/circle-icon/CirlcleIcon";
import Text from "../ui/text/Text";
import Button from "../ui/button/Button";
import MenuPanel from "../ui/menu-panel/MenuPanel";
import { CustomFieldScreen } from "../../models/CustomFields";
import StandardFields from "./StandardFields";
import CompanyFields from "./CompanyFields";
import IntegrationFields from "./IntegrationFields";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../..";
import { ICustomFieldsReducer } from "../../reducers/customFieldsReducer";
import { setCustomFieldsCurrentScreen, setIsCreatingCustomField, resetCustomFieldsState, fetchCustomFields } from "../../actions/customFieldsActions";
import { setIsAdditionalEmailRequired, setIsPhoneNumberRequired, setIsMarketingConsentRequired, setProductCustomFields } from "../../actions/productPlanCycleActions";
import { IProductReducerState } from "../../reducers/productsReducer";
import { IAuthReducerState } from "../../reducers/authReducer";
import CreateCustomFields from "./create-custom-fields/CreateCustomField";

interface ICustomFields {
  onClose?: () => void
}

const getCurrentScreen = (dispatch: Function, customFieldsData: ICustomFieldsReducer, productsData: IProductReducerState, authData: IAuthReducerState) => {
  const { currentScreen } = customFieldsData;
  switch (currentScreen) {
    case CustomFieldScreen.STANDARD_FIELDS:
      return <StandardFields />
    case CustomFieldScreen.COMPANY_FIELDS:
      return <CompanyFields />
    case CustomFieldScreen.INTEGRATION_FIELDS:
      return <IntegrationFields />
    default:
      return <div />
  }
}

const updateFields = (dispatch: Function, customFieldsData: ICustomFieldsReducer) => {
  dispatch(setIsAdditionalEmailRequired(customFieldsData.standardFields.isAdditionalEmailRequired));
  dispatch(setIsPhoneNumberRequired(customFieldsData.standardFields.isPhoneNumberRequired));
  dispatch(setIsMarketingConsentRequired(customFieldsData.standardFields.isMarketingConsentRequired));
  dispatch(setProductCustomFields(customFieldsData.newCustomFields.filter(field => field.isSelected)));
  dispatch(resetCustomFieldsState());
}

const hasSomeStandardFieldChanged = (customFieldsData: ICustomFieldsReducer, productsData: IProductReducerState) => {
  return customFieldsData.standardFields.isAdditionalEmailRequired !== productsData.isAdditionalEmailRequired
    || (customFieldsData.standardFields.isMarketingConsentRequired !== productsData.isMarketingConsentRequired)
    || (customFieldsData.standardFields.isPhoneNumberRequired !== productsData.isPhoneNumberRequired);
}

const hasCustomFieldsChanged = (customFieldsData: ICustomFieldsReducer) => {
  return customFieldsData.newCustomFields.some(field => 
    customFieldsData.newCustomFieldsTemp.some(customField => customField.customFieldId === field.customFieldId && customField.isSelected !== field.isSelected));
}

const CustomFields: React.FC<ICustomFields> = ({ onClose = () => { } }) => {
  const customFieldsData = useSelector<AppState, ICustomFieldsReducer>(state => state.customFields);
  const { currentScreen, isCreatingCustomField } = customFieldsData;
  const productsData = useSelector<AppState, IProductReducerState>(state => state.products)
  const authData = useSelector<AppState, IAuthReducerState>(state => state.auth)
  const dispatch = useDispatch<Function>()

  const isUpdateBtnDisabled = !hasSomeStandardFieldChanged(customFieldsData, productsData) && !hasCustomFieldsChanged(customFieldsData);

  useEffect(() => {
    dispatch(fetchCustomFields(authData.currentCompanyDomain));
  }, []);
  
  if (isCreatingCustomField) {
    return (
      <MenuPanel className="custom-fields">
        <CircleIcon className="custom-fields__closebtn" icon="fal fa-times" onClick={() => { dispatch(resetCustomFieldsState()); onClose(); }} />
        <CreateCustomFields onBack={() => dispatch(setIsCreatingCustomField(false))} />
      </MenuPanel>
    )
  }

  return (
    <MenuPanel className="custom-fields">
      <CircleIcon className="custom-fields__closebtn" icon="fal fa-times" onClick={() => { dispatch(resetCustomFieldsState()); onClose(); }} />
      <MenuPanel.Menu>
        <Text content="CUSTOM_FIELDS_MODAL_STANDARD_FIELDS" className={`custom-fields__menu__item ${currentScreen === CustomFieldScreen.STANDARD_FIELDS ? "custom-fields__menu__item--selected" : ""}`} onClick={() => dispatch(setCustomFieldsCurrentScreen(CustomFieldScreen.STANDARD_FIELDS))} />
        <Text content="CUSTOM_FIELDS_MODAL_COMPANY_FIELDS" translateWith={{ companyName: authData.companyName }} className={`custom-fields__menu__item ${currentScreen === CustomFieldScreen.COMPANY_FIELDS ? "custom-fields__menu__item--selected" : ""}`} onClick={() => dispatch(setCustomFieldsCurrentScreen(CustomFieldScreen.COMPANY_FIELDS))} />
        <Text content="CUSTOM_FIELDS_MODAL_INTEGRATION_FIELDS" noMargin className={`custom-fields__menu__item ${currentScreen === CustomFieldScreen.INTEGRATION_FIELDS ? "custom-fields__menu__item--selected" : ""}`} onClick={() => dispatch(setCustomFieldsCurrentScreen(CustomFieldScreen.INTEGRATION_FIELDS))} />
        <Button
          id="select-some-fields"
          title="CUSTOM_FIELDS_SELECT_SOME_FIELDS"
          disabled={isUpdateBtnDisabled}
          onClick={() => {
            updateFields(dispatch, customFieldsData);
            onClose();
          }}
        />
        <Button
          id="create-some-fields"
          icon="fal fa-pencil-paintbrush"
          title="CUSTOM_FIELDS_CREATE_NEW_FIELD"
          onClick={() => dispatch(setIsCreatingCustomField(!isCreatingCustomField))}
        />
      </MenuPanel.Menu>
      <MenuPanel.Content>
        {getCurrentScreen(dispatch, customFieldsData, productsData, authData)}
      </MenuPanel.Content>
    </MenuPanel>
  )
}

export default CustomFields