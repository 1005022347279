import counterpart from "counterpart";
import React, { useEffect, useMemo, useState } from "react";
import { Col, Row } from "react-grid-system";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { AppState } from "../../..";
import { IAuthReducerState } from "../../../reducers/authReducer";
import { ILanguageSupportReducerState } from "../../../reducers/languageSupportReducer";
import Dropdown from "../../../components/ui/dropdown/Dropdown";
import FormGroup from "../../../components/ui/form-group/FormGroup";
import FormLabel from "../../../components/ui/form-label/FormLabel";
import Panel from "../../../components/ui/panel/Panel";
import Text from "../../../components/ui/text/Text";
import Button from "../../../components/ui/button/Button";
import { fetchCompanyLanguages } from "../../../actions/languageSupportActions";
import ProgressIndicator from "../../../components/ui/progress-indicator/ProgressIndicator";
import { ICustomerDetailsReducerState } from "../../../reducers/customerDetailsReducer";
import "./ChangeLanguage.scss";
import { fetchCustomerLanguages, updateCustomerLanguage } from "../../../actions/customerDetailsActions";
import NoticePanel from "../../../components/ui/notice-panel/NoticePanel";

interface IChangeLanguage {
  handleCallback?: () => void
}

const ChangeLanguage: React.FC<IChangeLanguage> = ({ handleCallback }) => {
  const [isLoadingData, setIsLoadingData] = useState(false);
  const [isUpdatingLanguage, setIsUpdatingLanguage] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [newLanguage, setNewLanguage] = useState<{ label: string, value: string }>({ label: "", value: "" }); 

  const { currentCompanyId, currentCompanyDomain } = useSelector<AppState, IAuthReducerState>(state => state.auth);  
  const { companyLaguageConfig: { companyLanguagesList } } = useSelector<AppState, ILanguageSupportReducerState>(state => state.languageSupportReducer);
  const { customerUniqueId, customerLanguages } = useSelector<AppState, ICustomerDetailsReducerState>(state => state.customerDetailsReducer);
  const dispatch = useDispatch()

  const disableChangeLanguage = customerLanguages.getLanguageIsoCode() === newLanguage.value || isUpdatingLanguage;

  const sortedLanguagesOption = useMemo(() => {
    const companyLanguages: Array<{ value: string, label: string }> = [];
    companyLanguagesList.forEach(l => {
      if (l.isEnabled) {
        companyLanguages.push({
          value: l.isoCode,
          label: l.englishName
        })
      }
    })
    companyLanguages.sort((a, b) => {
      if (a.label < b.label) { return -1; }
      if (a.label > b.label) { return 1; }
      return 0;
    })

    return companyLanguages;
  }, [companyLanguagesList])

  const fetchData = async () => {
    setIsLoadingData(true)
    try {
      currentCompanyId && await dispatch(fetchCompanyLanguages(currentCompanyId));
      await dispatch(fetchCustomerLanguages(currentCompanyDomain, customerUniqueId));
    } catch(err) {
      setHasError(true);
      console.log(err)
    } finally {
      setIsLoadingData(false)
    }
  }

  useEffect(() => {
      fetchData();
  }, []);

  useEffect(() => {
    setNewLanguage({ value: customerLanguages.getLanguageIsoCode(), label: customerLanguages.getLanguageEnglishName() })
  }, [customerLanguages])


  const updateLanguage = async () => {
    setIsUpdatingLanguage(true);
    try {
      currentCompanyId && await dispatch(updateCustomerLanguage(currentCompanyId, customerUniqueId, newLanguage.value));
      await dispatch(fetchCustomerLanguages(currentCompanyDomain, customerUniqueId));
      handleCallback && handleCallback()
    } catch (err) {
      console.log(err)
    } finally {
      setIsUpdatingLanguage(false)
    }
  }

  if (isLoadingData) {
    return <ProgressIndicator color="blue" coverage="normal" noPadding />;
  }

  return (
    <div className="change-language">
      {hasError && <NoticePanel type="error" isModal content="CHANGE_LANGUAGE_ERROR" />}
      <Panel className="change-language__title-panel" title="CHANGE_LANGUAGE_TITLE">
        <Text
          className="change-language__title-text"
          content="CHANGE_LANGUAGE_CONTENT"
          translateWith={{ link: <Link to='/configuration/languages'>{counterpart("CHANGE_LANGUAGE_LINK")}</Link>}}
          noMargin
        />
      </Panel>

      <Panel>
      <FormGroup noMargin>
         <Row align='center' >
            <Col md={2.5}>
              <FormLabel target='language' content='CHANGE_LANGUAGE_LABEL' noMargin />
            </Col>
            <Col md={9.5}>
              <Dropdown
                id='language-dropdown'
                className="change-bill-date__date-status"
                value={newLanguage}
                placeholder={counterpart("CHANGE_LANGUAGE_TITLE")}
                onChange={(language: any) => setNewLanguage(language)} 
                options={sortedLanguagesOption}/>
            </Col>
          </Row>
        </FormGroup>
      </Panel>
      <Button disabled={disableChangeLanguage} isLoading={isUpdatingLanguage} id="change-language-btn" title="CHANGE_LANGUAGE_BTN" onClick={updateLanguage} isFullWidth></Button>
    </div>
  )
}

export default ChangeLanguage