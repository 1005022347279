import counterpart from "counterpart"
import React, { useEffect, useMemo } from "react"
import { Col, Row } from "react-grid-system"
import Helmet from "react-helmet"
import { useHistory } from "react-router"
import Button from "../../../ui/button/Button"
import FormGroup from "../../../ui/form-group/FormGroup"
import FormLabel from "../../../ui/form-label/FormLabel"
import Input from "../../../ui/input/Input"
import PageWrapper from "../../../ui/layout/PageWrapper"
import NavigationAlertCurrencyMismatch from "../../../ui/navigation-alert/NavigationAlertCurrencyMismatch"
import NavigationBar from "../../../ui/navigation-bar/NavigationBar"
import Panel from "../../../ui/panel/Panel"
import Switch from "../../../ui/switch/Switch"
import Text from "../../../ui/text/Text"
import "./AchSettings.scss"
import { useDispatch, useSelector } from "react-redux"
import { AppState } from "../../../.."
import { IAuthReducerState } from "../../../../reducers/authReducer"
import { isValidEmailFormat } from "../../../../utils/commonUtils"
import NoticePanel from "../../../ui/notice-panel/NoticePanel"
import { Link } from "react-router-dom";
import { setCustomersField } from "../../../../actions/customersActions"
import { CardStatusType } from "../../../../models/Customers"
import { IAchSettingsReducer } from "../../../../reducers/achSettingsReducer"
import { fetchAchMicroDepositSettings, fetchAchPaymentConfig, setAchSettingsField, updateAchMicroDepositSettings, updateAchPaymentConfig } from "../../../../actions/achSettingsActions"
import { useIsFromSpecificPage } from "../../../../utils/custom-hooks";
import { setShowBillingModal } from "../../../../actions/authActions"
import { Permission } from "../../../../models/Auth"

const AchSettings: React.FC = () => {
	const dispatch = useDispatch<Function>()
	const history = useHistory()
	const { currentCompanyId, parsedToken } = useSelector<AppState, IAuthReducerState>(state => state.auth);
	const isFromChecklist = useIsFromSpecificPage("/launch-checklist");
	const isFromBillingModal = useIsFromSpecificPage("billing-modal");

	const { bccEmail, isValidEmail, isEnabledAchNotifications, isLoading, isSubmittingPaymentConfig, isSubmittingMicroDepositConfig,
		isAchPaymentActive, isAchPaymentAvailable } = useSelector<AppState, IAchSettingsReducer>(state => state.achSettingsReducer)


	const isValidFormMicroDeposit = useMemo(() => {
		return isValidEmail && !!bccEmail
	}, [isValidEmail, bccEmail])

	const hasPermissionACH = useMemo(() => {
		if (parsedToken?.CurrentPermissions.some(p => p === Permission[Permission.AchPaymentProcessorRead])) {
			return true
		}
		return false
	}, [parsedToken])

	const fetchData = async () => {
		dispatch(fetchAchMicroDepositSettings(currentCompanyId as number))
		dispatch(fetchAchPaymentConfig(currentCompanyId as number))
	}

	const renderContent = () => {
		return (
			<>
				{!isAchPaymentAvailable && (
					<NoticePanel
						type="error"
						content="ACH_SETTINGS_PAYMENT_ERROR"
						translateWith={{
							link: (
								<Link
									to="/customers"
									onClick={(evt) => {
										dispatch(setCustomersField("cardStatus", { label: "CUSTOMERS_LIST_TABLE_ACH_MANDATE_ADDED", value: CardStatusType.ACH }));
									}}
								>{counterpart("ACH_SETTINGS_PAYMENT_ERROR_LINK")}
								</Link>
							)
						}}
					/>
				)}

				<Panel title="ACH_SETTINGS_PAYMENT_TITLE">
					<Text content="ACH_SETTINGS_PAYMENT_CONTENT" />
					<FormGroup>
						<Row align="center" className={isAchPaymentAvailable ? "" : "ach-settings__locked"}>
							<Col md={1.5}>
								<FormLabel noMargin target="ach-payment-method" content="ACH_SETTINGS_PAYMENT_CHECKBOX" />
							</Col>
							<Col md={10.5}>
								<Switch
									id="ach-payment-method"
									checked={isAchPaymentActive}
									onChange={() => dispatch(setAchSettingsField("isAchPaymentActive", !isAchPaymentActive))}
								/>
							</Col>
						</Row>
					</FormGroup>

					<Row align="center">
						<Col md={9}>
							<Button
								id="ach-payment-update-preferences"
								title="ACH_SETTINGS_PAYMENT_UPDATE_PREFERENCE"
								onClick={() => dispatch(updateAchPaymentConfig(currentCompanyId as number, isAchPaymentActive))}
								type="submit"
								isLoading={isSubmittingPaymentConfig}
								disabled={isLoading || isSubmittingPaymentConfig || !isAchPaymentAvailable}
								isFullWidth
							/>
						</Col>
						<Col md={3}>
							<Button
								id="ach-payment-cancel"
								title="ACH_SETTINGS_PAYMENT_UPDATE_CANCEL"
								onClick={fetchData}
								disabled={!isAchPaymentAvailable}
								isFullWidth
								buttonType="error"
							/>
						</Col>
					</Row>
				</Panel>


				<Panel title="ACH_SETTINGS_MICRODEPOSIT_TITLE">
					<Text content="ACH_SETTINGS_MICRODEPOSIT_CONTENT" />
					<FormGroup>
						<Row align="center">
							<Col md={3}>
								<FormLabel noMargin target="ach-notifications" content="ACH_SETTINGS_MICRODEPOSIT_CHECKBOX" />
							</Col>
							<Col md={9}>
								<Switch
									id="ach-notifications"
									checked={isEnabledAchNotifications}
									onChange={() => dispatch(setAchSettingsField("isEnabledAchNotifications", !isEnabledAchNotifications))}
								/>
							</Col>
						</Row>
					</FormGroup>

					<FormGroup>
						<Row align="center">
							<Col md={3}>
								<FormLabel noMargin target="ach-notifications-email" content="ACH_SETTINGS_MICRODEPOSIT_BCC" />
							</Col>
							<Col md={9}>
								<Input
									id="ach-notifications-email"
									value={bccEmail}
									isError={!isValidEmail}
									placeholder={counterpart("ACH_SETTINGS_MICRODEPOSIT_BCC_PLACEHOLDER")}
									onChange={(evt: any) => {
										dispatch(setAchSettingsField("bccEmail", evt.target.value))
										const isValidEmail = isValidEmailFormat(evt.target.value) || !evt.target.value;
										dispatch(setAchSettingsField("isValidEmail", isValidEmail))
									}}
								/>
							</Col>
						</Row>
					</FormGroup>

					<Row align="center">
						<Col md={9}>
							<Button
								id="ach-update-preferences"
								title="ACH_SETTINGS_MICRODEPOSIT_UPDATE_PREFERENCES"
								onClick={() => dispatch(updateAchMicroDepositSettings(currentCompanyId as number, bccEmail, isEnabledAchNotifications))}
								type="submit"
								isLoading={isSubmittingMicroDepositConfig}
								disabled={isLoading || isSubmittingMicroDepositConfig || !isValidFormMicroDeposit}
								isFullWidth
							/>
						</Col>
						<Col md={3}>
							<Button
								id="ach-cancel"
								title="ACH_SETTINGS_MICRODEPOSIT_UPDATE_CANCEL"
								onClick={fetchData}
								isFullWidth
								buttonType="error"
							/>
						</Col>
					</Row>
				</Panel>
			</>
		)
	}

	useEffect(() => {
		fetchData()
	}, [])


	
	return (
		<div className="ach-settings">
			<Helmet title={counterpart("CONFIGURATION_BASIC_ACH_CONFIG_TITLE")} />
			<NavigationBar
				pageTitle=""
				previousPageTitle={
					isFromChecklist || isFromBillingModal
						? "GO_LIVE_PRO_PLAN_FEATURE_NAVBAR"
						: "CONFIGURATION_PAGE_TITLE"}
				previousPageCb={() =>
					isFromChecklist ? history.push({ pathname: "/launch-checklist", state: { hasprofeature: true } })
						: isFromBillingModal ? dispatch(setShowBillingModal(true))
							: history.push("/configuration")} />

			<NavigationAlertCurrencyMismatch />


			<PageWrapper>
				<Panel title="ACH_SETTINGS_PANEL1_TITLE" className="ach-settings__panel1">
					<Text content="ACH_SETTINGS_PANEL1_CONTENT" noMargin />
				</Panel>

				{!hasPermissionACH && (
					<NoticePanel
						type="warning"
						icon="far fa-lightbulb-exclamation"
						content="ACH_SETTINGS_PERMISSION_WARNING"
						translateWith={{
							here: <Link to="" id="billing-modal-link" onClick={(evt) => { dispatch(setShowBillingModal(true)); evt.preventDefault(); }}>{counterpart("REPORTS_ACCOUNTING_WARNING_PERMISSION_HERE")}</Link>
						}}
						isVisible={!hasPermissionACH}
					/>
				)}

				{ hasPermissionACH && renderContent()}

			</PageWrapper>
		</div>

	)
}

export default AchSettings