import React, { useState } from "react";
import "./BasicPreview.scss";
import Text from "../../ui/text/Text";
import CustomFieldPreview from "../../ui/custom-field-preview/CustomFieldPreview";
import CheckoutDatepicker from "./ui-checkout/CheckoutDatepicker";
import { Moment } from "moment";
import moment from"moment";


interface IDatePickerPreviewProps {
  label: string,
  supportText: string
}

const DatePickerPreview: React.FC<IDatePickerPreviewProps> = ({ label = "", supportText = "" }) => {
  const [date, setDate] = useState(moment)

  return (
    <CustomFieldPreview className="preview">
      <Text content={label} shouldTranslate={false} />
      <CheckoutDatepicker onChange={(date:Moment | null)=>{setDate(date ? date : moment())}} date={date}/>
      <Text content={supportText} shouldTranslate={false} noMargin />
    </CustomFieldPreview>
  );
};

export default DatePickerPreview;