
import { makeActionCreator, makeGenericActionCreatorTypeSafe } from "../utils/reduxUtils";
import { IUserInterfaceReducerState } from "../reducers/userInterfaceReducer";

export const SET_UI_FIELD = "SET_UI_FIELD";
export const setUserInterfaceField = makeGenericActionCreatorTypeSafe<IUserInterfaceReducerState>(SET_UI_FIELD);

export const RESET_UI = "RESET_UI";
export const resetUserInterface = makeActionCreator(RESET_UI, "payload");



