import React from "react";
import { Redirect, Route, RouteComponentProps, withRouter } from "react-router";
import { ICompany, UserRole } from "../models/Auth";
import { storeGet } from "../utils/storeUtils";
import { useSelector } from "react-redux";
import { AppState } from "..";
import { BILLSBY_SELECTED_COMPANY } from "../utils/constants";
import { ConfigConstants } from "../utils/config";
import { PaymentGatewaysType } from "../models/Configuration";
import { isRegistrationFromInvitationQueryString } from "../utils/commonUtils";
import { forbiddenRoutes } from "../models/AuthGuard";
import InvalidRole from "../components/invalid-role/InvalidRole";

const isLocalHost = ConfigConstants.reactAppEnvironment === "Localhost";

interface IPrivateRoute extends RouteComponentProps {
  [key: string]: any
}
/**
 * wrapper around Route to create a private (logged) route
 * @param props
 */
const _PrivateRoute: React.FC<IPrivateRoute> = props => {

  const authReducer = useSelector((state: AppState) => state.auth);
  const selectedCompany = storeGet(BILLSBY_SELECTED_COMPANY);
  const { parsedToken, companies, dashboardSettings } = authReducer;
  const { ...routeProps } = props;
  const { href, search } = window.location;


  if (!parsedToken) {
    return <Redirect to="/login" />
  }

  // HIDE FOR NOW
  if(dashboardSettings && dashboardSettings.isSuspended){
    return <Redirect to="/suspended-account"/>
  }

  const company: ICompany = companies.find((c) => c.companyId === selectedCompany) as ICompany;

  if (!company && props.path !== "/home") {
    return <Redirect to="/" />
  }

  if(props.path === "/home") {
    if(parsedToken.UserRole === UserRole.Agent || parsedToken.UserRole === UserRole.BillsbyOperationsManager) {
      return <Redirect to="/customers" />
    }
  }

  const port = isLocalHost ? `:${process.env.REACT_APP_PORT}` : "";


  const isForbiddenRoute = forbiddenRoutes[parsedToken.UserRole || UserRole.Admin].some(route => RegExp(route).test(href))

  if(isForbiddenRoute) {
    return <InvalidRole />
  }

  if (href.match(".*\/auth\/stripe.*")) {
    // internal redirect in case of stripe connect -> basically redirect to payment gateways page preserving the query-string from stripe
    window.location.replace(`//${company.companyDomain}${ConfigConstants.billsbyDomain}${port}/configuration${search}&type=${PaymentGatewaysType.STRIPE}`);
    return null;
  }

  if (href.match(".*\/sca\/3ds1.*")) {
    window.location.replace(`//${company.companyDomain}${ConfigConstants.billsbyDomain}${port}/configuration/3ds1-status${search}`);
    return null;
  }

  if (href.includes("/auth/webex")) {
    window.location.replace(`//${company.companyDomain}${ConfigConstants.billsbyDomain}${port}/integrations/webex${search}`);
    return null;
  }

  if (href.includes("/auth/quickbooks")) {
    window.location.replace(`//${company.companyDomain}${ConfigConstants.billsbyDomain}${port}/integrations/quickbooks${search}`);
    return null;
  }

  if (href.includes("/auth/freeagent")) {
    window.location.replace(`//${company.companyDomain}${ConfigConstants.billsbyDomain}${port}/integrations/free-agent${search}`);
    return null;
  }


  if (company && !href.match(new RegExp(`.*${company.companyDomain}${ConfigConstants.billsbyDomain}.*`))) {
 
    // if they try to access a different company domain this will be prevented, 
    // the instruction below has been commented out to retain the current session provided by the company and simply redirect to it
    // this will speed up the process without showing the company selection screen, if the user wants to switch company can do it from the nav menu

    // storeRemove(BILLSBY_SELECTED_COMPANY);
    window.location.replace(`//${company.companyDomain}${ConfigConstants.billsbyDomain}${isLocalHost ? `:${process.env.REACT_APP_PORT}` : ""}`);
    return null;
  }

  return <Route {...routeProps} />
}


interface IPublicRoute extends RouteComponentProps {
  [key: string]: any
}

const _PublicRoute: React.FC<IPublicRoute> = (props) => {

  const authReducer = useSelector((state: AppState) => state.auth);
  const registrationData = useSelector((state: AppState) => state.registration);
  const { ...routeProps } = props;
  const { parsedToken } = authReducer;


  if (!parsedToken && !isLocalHost && !window.location.host.match(`.*app${ConfigConstants.billsbyDomain}.*`)) {
    // if not logged and the user types the company subdomain we redirect to the main domain
    window.location.replace(`//app${ConfigConstants.billsbyDomain}`);
  }

  if (!parsedToken && isLocalHost && !window.location.host.match(".*local\.billsby.*")) {
    // if not logged and the user types the company subdomain we redirect to the main domain
    window.location.replace(process.env.REACT_APP_BROWSER as string);
  }
  if (registrationData.isDoingQuiz || isRegistrationFromInvitationQueryString() || props.path === "/suspended-account") {
    return <Route {...routeProps} />
  }

  if (parsedToken) {
    return <Redirect to="/" />
  }

  return <Route {...routeProps} />
}


const PrivateRoute = withRouter(_PrivateRoute);

const PublicRoute = withRouter(_PublicRoute);

export {
  PublicRoute,
  PrivateRoute
}