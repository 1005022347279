import React from "react";
import FixedSidebar from "../ui/fixed-sidebar/FixedSidebar";
import { IInvoiceDetails } from "../../models/Customer";
import Panel from "../ui/panel/Panel";
import Text from "../ui/text/Text";
import { InvoiceConfigType } from "../../models/Invoices";
import "./InvoiceStandaloneSidebarPreview.scss";
import Button from "../ui/button/Button";
import { lightOrDark } from "../../utils/commonUtils";

interface IInvoiceStandaloneSidebarPreview  {
  data: IInvoiceDetails;
  invoiceConfigType: InvoiceConfigType;
}

const InvoiceStandaloneSidebarPreview: React.FC<IInvoiceStandaloneSidebarPreview> = ({ data, invoiceConfigType }) => {
  const { brandingHighlightColor, brandingLogo, brandingLogoBackgroundColor } = data;
  const hexHighlightColor = `#${brandingHighlightColor}`;

  const renderTopPanel = () => {
    if (invoiceConfigType === InvoiceConfigType.PAID_INVOICE) {
      return (
        <Panel className="invoice-sidebar-preview__panel invoice-sidebar-preview__panel--success" title="CUSTOM_PREVIEW_PAID_INVOICE1">
          <Text content="CUSTOM_PREVIEW_PAID_INVOICE2" noMargin></Text>
        </Panel>
      );
    }

    if (invoiceConfigType === InvoiceConfigType.PENDING_INVOICE) {
      return (
        <Panel className="invoice-sidebar-preview__panel invoice-sidebar-preview__panel--pending" title="CUSTOM_PREVIEW_PENDING_INVOICE1">
          <Text content="CUSTOM_PREVIEW_PENDING_INVOICE2" noMargin></Text>
        </Panel>
      );
    }

    if (invoiceConfigType === InvoiceConfigType.UNPAID_INVOICE) {
      return (
        <>
          <Panel className="invoice-sidebar-preview__panel invoice-sidebar-preview__panel--error" title="CUSTOM_PREVIEW_UNPAID_INVOICE1">
            <Text content="CUSTOM_PREVIEW_UNPAID_INVOICE2" noMargin></Text>
          </Panel>

          <Panel className="invoice-sidebar-preview__panel">
            <Text component="h6" content="CUSTOM_PREVIEW_UNPAID_INVOICE3" style={{ color: hexHighlightColor }} noMargin></Text>
            <Text content="CUSTOM_PREVIEW_UNPAID_INVOICE4" noMargin></Text>
          </Panel>

          <Button
            id="select-plan-continue"
            type="button"
            className="invoice-sidebar-preview__arrow-button"
            title="CHECKOUT_PREVIEW_CONTINUE_BUTTON"
            style={{ backgroundColor: hexHighlightColor }}
            isFullWidth
          />
        </>
      );
    }

    if (invoiceConfigType === InvoiceConfigType.PAID_CREDIT_NOTE) {
      return (
        <Panel className="invoice-sidebar-preview__panel invoice-sidebar-preview__panel--success" title="CUSTOM_PREVIEW_PAID_CREDIT_NOTE1">
          <Text content="CUSTOM_PREVIEW_PAID_CREDIT_NOTE2" noMargin></Text>
        </Panel>
      );
    }

    if (invoiceConfigType === InvoiceConfigType.PENDING_CREDIT_NOTE) {
      return (
        <Panel className="invoice-sidebar-preview__panel invoice-sidebar-preview__panel--pending" title="CUSTOM_PREVIEW_PENDING_CREDIT_NOTE1">
          <Text content="CUSTOM_PREVIEW_PENDING_CREDIT_NOTE2" noMargin></Text>
        </Panel>
      );
    }

    if (invoiceConfigType === InvoiceConfigType.FAILED_CREDIT_NOTE) {
      return (
        <Panel className="invoice-sidebar-preview__panel invoice-sidebar-preview__panel--error" title="CUSTOM_PREVIEW_UNPAID_INVOICE1">
          <Text content="CUSTOM_PREVIEW_UNPAID_INVOICE2" noMargin></Text>
        </Panel>
      );
    }

    return null;
  };

  const renderSupportContactDetails = () => {
    return (
      <>
        {(data.supportContactHeading || data.supportContactContent) && (
          <Panel className="invoice-sidebar-preview__panel">
            {data.supportContactHeading && <Text component="h6" content={data.supportContactHeading} shouldTranslate={false} style={{ color: hexHighlightColor }} noMargin></Text>}
            {data.supportContactContent && data.supportContactContent.split("\n").map((text: string, idx) => <Text key={idx} content={text} shouldTranslate={false}></Text>)}
          </Panel>
        )}
        {(data.supportContactSupportUrl) && (
          <Button
            id="support-button"
            type="button"
            className="invoice-sidebar-preview__arrow-button"
            shouldTranslate={false}
            title={data.supportContactSupportUrlButtonText}
            style={{ backgroundColor: hexHighlightColor }}
            isFullWidth
            onClick={(evt: any) => {
              evt.preventDefault();
              window.open(data.supportContactSupportUrl, "_self")
            }}
          />
        )}
        {data.advertImageUrl && data.advertLinkToUrl && (
          <a className="invoice-sidebar-preview__link-advert" href={data.advertLinkToUrl}>
            <img className="invoice-sidebar-preview__advert" alt="no img" src={data.advertImageUrl}></img>
          </a>
        )}
      </>
    );
  };

  return (
    <>
      <FixedSidebar
        backgroundHeader={`#${brandingLogoBackgroundColor}`}
        logoHeader={brandingLogo}
        rightIcon={<i className={`fal fa-file-pdf ${lightOrDark(`#${brandingLogoBackgroundColor}`) === "dark" ? "light-icon" : ""}`}  />}
        companyDomain={""}
        companyName={""}
        className="invoice-standalone-sidebar"
      >
        {renderTopPanel()}
        {renderSupportContactDetails()}
      </FixedSidebar>
    </>
  );
};

export default InvoiceStandaloneSidebarPreview;
