import React, { FormEvent, useState } from "react";
import { useSelector } from "react-redux";
import Panel from "../ui/panel/Panel";
import Text from "../ui/text/Text";
import Button from "../ui/button/Button";
import "./SendInvoice.scss";
import { AppState } from "../..";
import NoticePanel from "../ui/notice-panel/NoticePanel";
import API from "../../utils/API";

interface ISendInvoiceProps {
  handleSendInvoiceCallback?: Function,
  customerUniqueId: string
}

const SendInvoice: React.FC<ISendInvoiceProps> = ({ handleSendInvoiceCallback, customerUniqueId }) => {
  const authData = useSelector((state: AppState) => state.auth);
  const invoiceDetails = useSelector((state: AppState) => state.invoiceDetailsReducer.invoiceDetails);
  
  const [state, setState] = useState({
    hasError: false,
    isLoadingData: false,
    isLoading: false
  });
  const { hasError, isLoadingData, isLoading } = state;

  const onSubmit = async (evt: FormEvent) => {
    evt.preventDefault();
    evt.stopPropagation();

    setState({ ...state, isLoading: true });
    try {
     await API.sendInvoiceLink(authData.currentCompanyDomain, customerUniqueId, { invoiceNumber: invoiceDetails.invoiceNumber });
     handleSendInvoiceCallback && handleSendInvoiceCallback();
    } catch (err) {
      setState({ ...state, isLoading: false, hasError: true });
      console.log(err)
    }
  };

  if (isLoadingData) {
    return null;
  }

  return (
    <>
      <form className="send-invoice" onSubmit={onSubmit}>
        {hasError && <NoticePanel type="error" isModal={true} icon="far fa-lightbulb-exclamation" content="SEND_INVOICE_ERROR" />}
        <Panel className="send-invoice__titlepanel" title="SEND_INVOICE_PANEL1_TITLE">
          <div className="send-invoice__description">
            <Text content="SEND_INVOICE_PANEL2_CONTENT" translateWith={{ customerEmail: <span className="send-invoice__email">{invoiceDetails.customerEmail || "smaple@email.com"}</span> }} component="span" noMargin />
          </div>
        </Panel>

        <Button
          id="charge-customer"
          type="submit"
          buttonType={"general"}
          isLoading={isLoading && !hasError}
          title={"SEND_INVOICE_RESEND_INVOICE_LINK"}
          isFullWidth
        />
      </form>
    </>
  );
};

export default SendInvoice;
