import React from "react";
import moment from "moment";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "./MonthPicker.scss";

interface IMonthPicker {
  value: string;
  className?: string;
  onChange: (value: any) => void;
  minDate?: string;
  maxDate?: string
}

const MonthPicker: React.FC<IMonthPicker> = React.memo(({ value, minDate, maxDate, onChange, className = "" }) => {
  if (!value) return null;

  return (
    <div className={`custom-month-picker ${className}`}>
      <DatePicker
        minDate={minDate ? moment.utc(minDate, "MMM YYYY").toDate() : null}
        maxDate={maxDate ? moment.utc(maxDate, "MMM YYYY").toDate() : null}
        selected={moment(value, "MMM YYYY").toDate()}
        onChange={(date: Date) => onChange(moment(date).format("MMM YYYY"))}
        dateFormat="MMM yyyy"
        showMonthYearPicker
      />
    </div>
  );
});

export default MonthPicker;
