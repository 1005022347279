import React, { FormEvent, useLayoutEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Panel from "../../ui/panel/Panel";
import Text from "../../ui/text/Text";
import Button from "../../ui/button/Button";
import API from "../../../utils/API";
import { AppState } from "../../..";
import { IAuthReducerState } from "../../../reducers/authReducer";
import "./CollectPaymentDetails.scss";
import CollapsablePanelWithArrow from "../../ui/collapsable-panel-with-arrow/CollapsablePanelWithArrow";
import { INewCustomerDetails, ICustomerPaymentDetailsError } from "../../../models/Customer";
import NoticePanel from "../../ui/notice-panel/NoticePanel";
import PaymentDetailsForm, { validatePaymentDetailsForm } from "../../payment-details-form/PaymentDetailsForm";
import { setCustomersField } from "../../../actions/customersActions";
import { ISpreedlyCardData } from "../../../models/Spreedly";
import ErrorNotification from "../../ui/error-notification/ErrorNotification";
import { GettingStartedType } from "../../../models/GettingStarted";
import { SPREEDLY_TEST_CARD_NAME, SPREEDLY_TEST_CREDIT_CARD, SPREEDLY_TEST_CVV, SPREEDLY_TEST_EXP_MONTH, SPREEDLY_TEST_EXP_YEAR } from "../../../utils/constants";
import { fetchPaymentGateways } from "../../../actions/paymentGatewaysActions";
import { fetchCurrenciesMapping } from "../../../actions/currenciesActions";
interface ICollectPaymentDetailsModalProps {
  newCustomerDetails: INewCustomerDetails;
  handleAddCustomerCallback: Function;
}

const CollectPaymentDetails: React.FC<ICollectPaymentDetailsModalProps> = ({ newCustomerDetails, handleAddCustomerCallback }) => {
  const { currentCompanyDomain, dashboardSettings } = useSelector<AppState, IAuthReducerState>(state => state.auth);
  const isScaActivated = useSelector<AppState, boolean>(state => state.paymentGatewaysReducer.isScaActivated);
  const isACHActivated = useSelector<AppState, boolean>(state => !!state.currenciesReducer.currenciesGatewaysMapping?.some(c => c.achPaymentGatewayId))
  const dispatch = useDispatch<Function>();

  const [hasError, setHasError] = useState(false);
  const [isLoadingData, setIsLoadingData] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isSendingRequest, setIsSendingRequest] = useState(false);
  const [isSendingACHRequest, setIsSendingACHRequest] = useState(false);
  const [openManualInputPanel, setOpenManualInputPanel] = useState(false);
  const [openSendPaymentDetailsPanel, setOpenSendPaymentDetailsPanel] = useState(false);
  const [openACHMandatePanel, setOpenACHMandatePanel] = useState(false);
  const [cardholderName, setCardholderName] = useState("");
  const [expiryMonth, setExpiryMonth] = useState(0);
  const [expiryYear, setExpiryYear] = useState(0);
  const [cardDetailsErrors, setCardDetailsErrors] = useState<ICustomerPaymentDetailsError | null>(null);
  const [isCreatingCustomerWithCardDetails, setIsCreatingCustomerWithCardDetails] = useState(false);
  const [isCreatingCustomerWithCardDetailsError, setIsCreatingCustomerWithCardDetailsError] = useState(false);

  const isLive = dashboardSettings && (dashboardSettings.status === GettingStartedType.OnTrial || dashboardSettings.status === GettingStartedType.Live);

  useLayoutEffect(() => {
    const fetchData = async () => {
      setIsLoadingData(true);
      try {
        await dispatch(fetchPaymentGateways(currentCompanyDomain));
        await dispatch(fetchCurrenciesMapping(currentCompanyDomain));
      } finally {
        setIsLoadingData(false)
      }
    }

    fetchData()
  }, [])

  if (isLoadingData) {
    return null;
  }

  const createCustomerAndSendRequest = async (type: "ach" | "creditcard", successMsg: string) => {
    const response: any = await API.createCustomer(currentCompanyDomain, { ...newCustomerDetails, cardDetails: null });
    await API.sendCustomerRequestPaymentDetails(currentCompanyDomain, response.customerUniqueId, type);
    dispatch(setCustomersField("pageNotification", { type: "success", icon: "fas fa-check-circle", content: successMsg }));
    await handleAddCustomerCallback();
  }

  const handleCreateCustomerAndSendRequest = async (evt: any) => {
    evt.preventDefault();

    setIsSendingRequest(true)
    dispatch(setCustomersField("createCustomerError", false));

    try {
      await createCustomerAndSendRequest("creditcard", "ADD_CUSTOMER_WITH_REQUEST_SUCCESS");
    } catch (err) {
      console.log(err);
      setHasError(true)
      dispatch(setCustomersField("createCustomerError", true));
    } finally {
      setIsSendingRequest(false)
    }
  };

  const handleCreateCustomerWithPaymentDetails = (evt: FormEvent) => {
    evt.preventDefault();

    setIsCreatingCustomerWithCardDetails(true);
    dispatch(setCustomersField("createCustomerError", false));

    validatePaymentDetailsForm({ cardholderName, expiryMonth, expiryYear },
      {
        addressLine1: newCustomerDetails.addressLine1,
        addressLine2: newCustomerDetails.addressLine2,
        state: newCustomerDetails.addressState,
        city: newCustomerDetails.addressCity,
        country: newCustomerDetails.addressCountry,
        postCode: newCustomerDetails.addressPostCode
      },
      async (cardData: ISpreedlyCardData, error: ICustomerPaymentDetailsError | null) => {
        if (error || !cardData) {
          setCardDetailsErrors(error);
          setIsCreatingCustomerWithCardDetails(false)
          return;
        }

        setCardDetailsErrors(null);
        setIsCreatingCustomerWithCardDetails(true)
        try {
          await API.createCustomer(currentCompanyDomain, {
            ...newCustomerDetails,
            cardDetails: { fullName: cardData.full_name, paymentCardToken: cardData.token, expiryMonth, expiryYear, last4Digits: cardData.last_four_digits, cardType: cardData.card_type }
          });

          dispatch(setCustomersField("pageNotification", { type: "success", icon: "fas fa-check-circle", content: "ADD_CUSTOMER_WITH_PAYMENT_DETAILS_SUCCESS" }));
          await handleAddCustomerCallback();
        } catch (err) {
          console.log(err);
          setIsCreatingCustomerWithCardDetailsError(true)
          dispatch(setCustomersField("createCustomerError", true));
        } finally {
          setIsCreatingCustomerWithCardDetails(false)
        }
      });
  };

  const handleCreateCustomerWithoutPaymentDetails = async (evt: any) => {
    evt.preventDefault();

    setIsLoading(true)

    try {
      await API.createCustomer(currentCompanyDomain, { ...newCustomerDetails, cardDetails: null });
      dispatch(setCustomersField("pageNotification", { type: "success", icon: "fas fa-check-circle", content: "ADD_CUSTOMER_WITHOUT_PAYMENT_DETAILS_SUCCESS" }));
      await handleAddCustomerCallback();
    } catch (err) {
      console.log(err);
      setHasError(true)
      dispatch(setCustomersField("createCustomerError", true));
    } finally {
      setIsLoading(false)
    }
  };

  const handleSendACHMandateRequest = async (evt: any) => {
    evt.preventDefault();
    setIsSendingACHRequest(true);

    try {
      await createCustomerAndSendRequest("ach", "ADD_CUSTOMER_WITH_ACH_REQUEST_SUCCESS");
    } catch (err) {
      setHasError(true)
      console.log(err);
    } finally {
      setIsSendingACHRequest(false);
    }
  }

  const renderManualInputDetailsForm = () => {
    if (isScaActivated) { return null }
    return (
      <CollapsablePanelWithArrow
        onClick={() => setOpenManualInputPanel(!openManualInputPanel)}
        isCollapsed={!openManualInputPanel}
        title={"ADD_CUSTOMER_COLLECT_PAYMENT_DETAILS_PANEL1_TITLE"}
        subtitle={"ADD_CUSTOMER_COLLECT_PAYMENT_DETAILS_PANEL1_CONTENT"}
      >
        <form id="payment-details-form" onSubmit={handleCreateCustomerWithPaymentDetails}>
          <PaymentDetailsForm
            onChangeCardholderName={(name) => setCardholderName(name)}
            onChangeExpiryMonth={(month) => setExpiryMonth(month)}
            onChangeExpiryYear={(year) => setExpiryYear(year)}
            cardNumberId="spreedly-number-1"
            cvvId="spreedly-cvv-1"
            initialCardholderName={!isLive ? SPREEDLY_TEST_CARD_NAME : undefined}
            initialCardNumber={!isLive ? SPREEDLY_TEST_CREDIT_CARD : undefined}
            initialCvv={!isLive ? SPREEDLY_TEST_CVV : undefined}
            initialExpiryMonth={!isLive ? SPREEDLY_TEST_EXP_MONTH : undefined}
            initialExpiryYear={!isLive ? SPREEDLY_TEST_EXP_YEAR : undefined}
            formData={{ cardholderName, expiryMonth, expiryYear }}
            formErrors={cardDetailsErrors}
          />
          <Button id="create-customer" type="submit" buttonType={"general"} disabled={validateData()} isLoading={isCreatingCustomerWithCardDetails} title={"ADD_CUSTOMER"} isFullWidth />
        </form>
      </CollapsablePanelWithArrow>
    );
  };

  const renderSendPaymentDetailsForm = () => {
    return (
      <CollapsablePanelWithArrow
        onClick={() => setOpenSendPaymentDetailsPanel(!openSendPaymentDetailsPanel)}
        isCollapsed={!openSendPaymentDetailsPanel}
        title={"ADD_CUSTOMER_COLLECT_PAYMENT_DETAILS_PANEL2_TITLE"}
        subtitle={"ADD_CUSTOMER_COLLECT_PAYMENT_DETAILS_PANEL2_CONTENT"}
      >
        <Text content="ADD_CUSTOMER_COLLECT_PAYMENT_DETAILS_PANEL2_BODY" translateWith={{ customerEmail: <span className="collect-payment-details__email">{newCustomerDetails.email}</span> }}></Text>
        <Button
          id="send-request"
          type="submit"
          isLoading={isSendingRequest}
          buttonType={"general"}
          title={"ADD_CUSTOMER_COLLECT_PAYMENT_DETAILS_SEND_REQUEST"}
          onClick={handleCreateCustomerAndSendRequest}
          isFullWidth
        />
      </CollapsablePanelWithArrow>
    );
  };

  const renderACHMandate = () => {
    if(!isACHActivated) {
      return null
    }

    return (
      <CollapsablePanelWithArrow
        onClick={() => setOpenACHMandatePanel(!openACHMandatePanel)}
        isCollapsed={!openACHMandatePanel}
        title={"ADD_CUSTOMER_COLLECT_PAYMENT_DETAILS_PANEL3_TITLE"}
        subtitle={"ADD_CUSTOMER_COLLECT_PAYMENT_DETAILS_PANEL3_CONTENT1"}
      >
        <Text content="ADD_CUSTOMER_COLLECT_PAYMENT_DETAILS_PANEL3_CONTENT2" translateWith={{ customerEmail: <span className="collect-payment-details__email">{newCustomerDetails.email}</span> }}></Text>
        <Button
          id="send-ach-request"
          type="submit"
          isLoading={isSendingACHRequest}
          buttonType={"general"}
          title={"ADD_CUSTOMER_SEND_ACH_MANDATE_REQUEST"}
          onClick={handleSendACHMandateRequest}
          isFullWidth
        />
      </CollapsablePanelWithArrow>
    );
  }

  const validateData = () => {
    return !cardholderName || !expiryMonth || !expiryYear;
  };

  return (
    <>
      {isCreatingCustomerWithCardDetailsError && <ErrorNotification autoClose={false} isModal title="ADD_CUSTOMER_CARD_ERROR"></ErrorNotification>}
      {cardDetailsErrors && <NoticePanel isModal type="warning" content="PAYMENT_DETAILS_INCORRECT_DETAILS" />}
      <div className="collect-payment-details">
        <Panel className="collect-payment-details__title-panel" title="ADD_CUSTOMER_COLLECT_PAYMENT_DETAILS_TITLE">
          <Text className="collect-payment-details__title-text" content="ADD_CUSTOMER_COLLECT_PAYMENT_DETAILS_CONTENT" noMargin />
        </Panel>
        {renderManualInputDetailsForm()}
        {renderSendPaymentDetailsForm()}
        {renderACHMandate()}
        <Button
          id="add-customer"
          type="button"
          icon={""}
          buttonType={"general"}
          isLoading={isLoading && !hasError}
          title={"ADD_CUSTOMER_WITHOUT_PAYMENT_DETAILS"}
          onClick={handleCreateCustomerWithoutPaymentDetails}
          isFullWidth
        />
      </div>
      {hasError && <ErrorNotification closingTime={8000} title="ADD_CUSTOMER_ERROR" showAlert={hasError} ></ErrorNotification>}
    </>
  )
}

export default CollectPaymentDetails;
