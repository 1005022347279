import React, { useMemo } from "react";
import StatusLabel from "../status-label/StatusLabel";
import { COLOR } from "../../../utils/constants";
import "./PercentageLabel.scss";

interface IPercentageLabel {
  value: number,
  type: "positive" | "negative" | "equals"
}

const PercentageLabel: React.FC<IPercentageLabel> = ({ value, type = "positive" }) => {
  const icon = useMemo(() => {
    switch (type) {
      case "positive":
        return <i className="fal fa-arrow-up" />
      case "negative":
        return <i className="fal fa-arrow-down" />
      case "equals":
        return <i className="fal fa-equals"></i>
    }
  }, [type])

  const color = useMemo(() => {
    switch (type) {
      case "positive":
        return COLOR.GREEN;
      case "negative":
        return COLOR.RED;
      case "equals":
        return COLOR.ORANGE;
    }
  }, [type])

  return (
    <StatusLabel color={color} content={
      <div className="percentage-label">
        {icon}
        <span>{value}</span>
        <span>%</span>
      </div>
    }  shouldTranslate={false} />
  )
}

export default PercentageLabel;