import React, { useState, useEffect, useMemo } from "react";
import StepCollapsablePanel from "../../ui/step-collapsable-panel/StepCollapsablePanel";
import Text from "../../ui/text/Text";
import { Row, Col } from "react-grid-system";
import FormLabel from "../../ui/form-label/FormLabel";
import Input from "../../ui/input/Input";
import Button from "../../ui/button/Button";
import counterpart from "counterpart";
import "./StepAddOnCounter.scss";
import FormGroup from "../../ui/form-group/FormGroup";
import Dropdown from "../../ui/dropdown/Dropdown";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../../..";
import { ICreateAddOnsReducerState } from "../../../reducers/add-ons/create-addons/createAddOnsReducer";
import { Counter } from "../../../utils/grpc/generated/Billsby.Protos/core/private/counters/counters_pb";
import { createCounter, fetchCounters, setCreateAddOnsBasicInfo, setAddOnsStepsCollapsed } from "../../../actions/addOnsActions";
import { IAuthReducerState } from "../../../reducers/authReducer";
import { IAddOnCostReducer } from "../../../reducers/add-ons/addOnCostReducer";

interface IStepAddOnCounter {
  addOnId: number | null;
}

const StepAddOnCounter: React.FC<IStepAddOnCounter> = ({ addOnId }) => {
  const { currentCompanyDomain } = useSelector<AppState, IAuthReducerState>(state => state.auth)
  const addOnCostReducer = useSelector<AppState, IAddOnCostReducer>(state => state.addOnCostReducer)
  const createAddonReducer = useSelector<AppState, ICreateAddOnsReducerState>(state => state.createAddOnsReducer)
  const { hasSelectedPricingModel, stageCollapsed, counters, selectedCounter, newCounter } = createAddonReducer;
  const { hasConfiguredAllPriceModels } = addOnCostReducer;
  const dispatch = useDispatch<Function>()

  const [isAdding, setIsAdding] = useState(false);
  const [counterName, setCounterName] = useState("");
  const [hasAddedNewCounter, setHasAddedNewCounter] = useState(false);
  const [countersDropdown, setCountersDropdown] = useState<Array<{ label: string, value: Counter }>>([]);

  const isValidCounter = useMemo(() => {
    return /^[a-z\d\s]+$/i.test(counterName);
  }, [counterName]);

  useEffect(() => {
    dispatch(fetchCounters(currentCompanyDomain));
  }, []);

  useEffect(() => {
    if (counters) {
      const counterOptions = counters.getCountersList().map(el => ({ label: `${el.getCounterName()} - ${el.getCounterId()}`, value: el }));
      setCountersDropdown(counterOptions);
    }
  }, [counters])

  if (!counters) {
    return null;
  }

  const renderCounterId = (id?: string) => {
    return (
      <FormGroup>
        <Row align="center" >
          <Col xs={2}>
            <FormLabel target="counter-id" content="CREATE_ALLOWANCES_LINK_TO_COUNTER_ID_LABEL" noMargin />
          </Col>
          <Col xs={10}>
            <Input
              readOnly
              id="counter-id"
              value={id}
            />
          </Col>
        </Row>
      </FormGroup>
    )
  }

  const renderContent = () => {
    return (
      <>
        <FormGroup>
          <Row align="center">
            <Col xs={2}>
              <FormLabel target="counter-name" content="CREATE_ALLOWANCES_LINK_TO_COUNTER_LABEL" noMargin />
            </Col>
            <Col xs={10}>
              {!countersDropdown.length ? <Input disabled id="disabled-counter" placeholder={counterpart("CREATE_ALLOWANCES_LINK_TO_COUNTER_NO_AVAILABLE_PLACEHOLDER")}></Input>
                : <Dropdown
                  className="step-addon-counter__dropdown"
                  id="select-counter"
                  onChange={(counter: any) => {
                    dispatch(setCreateAddOnsBasicInfo("selectedCounter", { label: counter.value.getCounterName(), value: counter.value }))
                    setCounterName("")
                  }}
                  placeholder={counterpart("CREATE_ALLOWANCES_LINK_TO_COUNTER_SELECT")}
                  resetDependencies={[newCounter]}
                  value={selectedCounter.label ? selectedCounter : undefined}
                  options={countersDropdown}
                />
              }
            </Col>
          </Row>
        </FormGroup>
        {selectedCounter && selectedCounter.value.getCounterId() && renderCounterId(selectedCounter.value.getCounterId())}
        <FormGroup>
          <Row align="center" >
            <Col xs={2}>
              <FormLabel target="counter-id" content="CREATE_ALLOWANCES_LINK_TO_COUNTER_CREATE" noMargin />
            </Col>
            <Col xs={8}>
              <Input
                id="counter-name"
                placeholder={counterpart("CREATE_ALLOWANCES_LINK_TO_COUNTER_NAME_PLACEHOLDER")}
                value={counterName}
                onChange={evt => {
                  setCounterName(evt.target.value.trimLeft());
                  setHasAddedNewCounter(false);
                }}
              />
            </Col>
            <Col xs={2}>
              <Button
                disabled={!counterName || !isValidCounter || hasAddedNewCounter}
                id="btn-counter"
                title="CREATE_ALLOWANCES_LINK_TO_COUNTER_ADD"
                buttonType="add"
                isLoading={isAdding}
                icon="fal fa-plus-circle"
                isFullWidth
                onClick={async () => {
                  setIsAdding(true)
                  try {
                    await dispatch(createCounter(currentCompanyDomain, counterName));
                    await dispatch(fetchCounters(currentCompanyDomain));
                    dispatch(setCreateAddOnsBasicInfo("selectedCounter", { label: "", value: new Counter() }))
                    setHasAddedNewCounter(true);
                  }
                  catch (err) { }
                  finally {
                    setIsAdding(false);
                  }
                }}
              />
            </Col>
          </Row>
        </FormGroup>
        {!selectedCounter.value.getCounterId() && hasAddedNewCounter && newCounter && renderCounterId(newCounter.getCounter()?.getCounterId())}
      </>
    )
  }

  return (
    <StepCollapsablePanel
      title={addOnId ? "EDIT_ALLOWANCES_LINK_TO_COUNTER" : "CREATE_ALLOWANCES_LINK_TO_COUNTER"}
      number={5}
      isCompleted={!!selectedCounter.value.getCounterId()}
      isLocked={!(hasConfiguredAllPriceModels && hasSelectedPricingModel)}
      hasHelpSection={false}
      isShowTitleIcon={!addOnId}
      isCollapsed={addOnId ? false : stageCollapsed[4]}
      className="step-addon-counter"
      onClick={() => dispatch(setAddOnsStepsCollapsed(4))}>
      <FormGroup noMargin style={{ marginBottom: "-15px" }}>
        <Text content="CREATE_ALLOWANCES_LINK_TO_COUNTER_HELP"></Text>
        {renderContent()}
      </FormGroup>
    </StepCollapsablePanel>
  )
}

export default StepAddOnCounter