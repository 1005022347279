import React from "react";
import Panel from "../ui/panel/Panel";
import Text from "../ui/text/Text";
import Dropdown from "../ui/dropdown/Dropdown";
import counterpart from "counterpart";
import { INDUSTRY_OPTIONS, INDUSTRY_MULTIOPTIONS } from "../../utils/industries/industries";
import { QUIZ_FIELD_INDUSTRY_TYPE } from "../../utils/constants";

interface IQuizStep8 {
  onContinue: (nextStep: number, fieldType: string, fieldValue: string) => any
}

const QuizStep8: React.FC<IQuizStep8> = ({ onContinue }) => {
  return (
    <div className="quiz quiz-step8">
      <Panel title="QUIZ_STEP8_TITLE">
        <Text content="QUIZ_STEP8_SUBTITLE" />
        <Dropdown
          //menuIsOpen={true}
          id="inudustry-select"
          placeholder={counterpart("QUIZ_STEP_DROPDOWN_PLACEHOLDER")}
          className="quiz-step8__select"
          onChange={(provider: { category: string, subCategory: string}) => onContinue(9, QUIZ_FIELD_INDUSTRY_TYPE, `${provider.category} > ${provider.subCategory}`)}
          options={INDUSTRY_OPTIONS}
          multioptions={INDUSTRY_MULTIOPTIONS}
          isMultiLayer
        />
      </Panel>
    </div>
  )
}

export default QuizStep8;