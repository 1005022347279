import React, { useEffect, useState } from "react";
import Button from "../../../../ui/button/Button";
import history from "../../../../../utils/history";
import Panel from "../../../../ui/panel/Panel";
import Text from "../../../../ui/text/Text";
import "./EmailBaseTemplate.scss";
import { AppState } from "../../../../..";
import { IAuthReducerState } from "../../../../../reducers/authReducer";
import { ICompanyBrandingReducerState } from "../../../../../reducers/companyBrandingReducer";
import { Row, Col } from "react-grid-system";
import EmailTextArea from "../emailForms/emailTextArea/EmailTextArea";
import EmailCommandButton from "../emailForms/emailCommandButton/EmailCommandButton";
import EmailPreivewContainer from "../../brand-customization/email-preview/EmailPreivewContainer";
import counterpart from "counterpart";
import EmailAdvertisement from "../emailForms/emailAdvertisement/EmailAdvertisement";
import { fetchCompanyBranding } from "../../../../../actions/companyBrandingActions";
import { fetchEmailBaseTemplate, setBaseEmailTemplateField, updateEmailBaseTemplate, updateBaseAdsBanner, resetEmailBaseTemplateValues } from "../../../../../actions/emailBaseTemplateActions";
import { IEmailBaseTemplateState } from "../../../../../reducers/emailBaseTemplateReducer";
import { useRouteMatch } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";


interface IEmailTypeParam {
  emailType: string
}

const EmailBaseTemplate: React.FC = () => {
  const { logoUrl, faviconUrl, highlightColor, logoBackgroundColor } = useSelector<AppState, ICompanyBrandingReducerState>(state => state.companyBrandingReducer);
  const { companyId, includeAdvert, avertisementImageUrl, advertisementUrl, footerText } = useSelector<AppState, IEmailBaseTemplateState>(state => state.emailBaseTemplateReducer);
  const { companyName, currentCompanyId, currentCompanyDomain } = useSelector<AppState, IAuthReducerState>(state => state.auth);
  const dispatch = useDispatch<Function>()
  const match = useRouteMatch()

  const defaultLogoBackgroundColor = "#1E2432";
  const defaultHighLightColor = "#F87847";
  const emailTypeParam = match.params as IEmailTypeParam;
  type allowLoadingType = "branding" | "subject" | "custom1" | "custom2" | "custom3" | "ads" | "bbc";
  const [subjectIsLoading, setSubjectIsLoading] = useState(false);
  const [custom1IsLoading, setCustom1IsLoading] = useState(false);
  const [custom2IsLoading, setCustom2IsLoading] = useState(false);
  const [custom3IsLoading, setCustom3IsLoading] = useState(false);
  const [adsIsLoading, setAdsIsLoading] = useState(false);
  const [bbcIsLoading, setBbcIsLoading] = useState(false);

  useEffect(() => {
    dispatch(fetchCompanyBranding(currentCompanyDomain));
    dispatch(fetchEmailBaseTemplate(currentCompanyId as number));
    return () => dispatch(resetEmailBaseTemplateValues());
  }, []);

  const setLoading = (loadingType: allowLoadingType, value: boolean) => {
    switch (loadingType) {
      case "subject":
        setSubjectIsLoading(value);
        break;
      case "custom1":
        setCustom1IsLoading(value);
        break;
      case "custom2":
        setCustom2IsLoading(value);
        break;
      case "custom3":
        setCustom3IsLoading(value);
        break;
      case "ads":
        setAdsIsLoading(value);
        break;
      case "bbc":
        setBbcIsLoading(value);
        break;
    }
  }

  const saveChanges = async (loadingType: allowLoadingType) => {
    setLoading(loadingType, true);
    try {
      await dispatch(updateEmailBaseTemplate(currentCompanyId as number, advertisementUrl, avertisementImageUrl, footerText, includeAdvert))
    }
    catch { }
    finally {
      setLoading(loadingType, false);
    }
  }

  const handleIncludeAdvertChange = () => {
    dispatch(setBaseEmailTemplateField("includeAdvert", !includeAdvert));
    dispatch(updateEmailBaseTemplate(currentCompanyId as number, advertisementUrl, avertisementImageUrl, footerText, !includeAdvert))
  }

  const updateAds = async (file: File) => {
    setLoading("ads", true);
    try {
      return await dispatch(updateBaseAdsBanner(currentCompanyDomain, file));
    }
    catch { }
    finally {
      setLoading("ads", false);
    }
  }

  return (
    <div>
      <Row>
        <Col sm={8}>
          <Panel className="base-email-template__panel" noMargin>
            <EmailPreivewContainer>

              <Text
                content="BASE_EMAIL_TEMPLATE_EMAIL_TITLE"
                className="email-preview__details__text email-preview__details__text-title"
                component="span"
                noMargin
              />

              <Text
                content="BASE_EMAIL_TEMPLATE_GREETINGS"
                className="email-preview__details__text"
                component="span"
                noMargin
              />

              <Text
                content="BASE_EMAIL_TEMPLATE_BODY_1"
                className="email-preview__details__text base-email-template__body-1"
                component="span"
                noMargin
              />              

              <Text
                content={<a onClick={() => history.push("/configuration/email-notifications/emails")}>{counterpart("BASE_EMAIL_TEMPLATE_BODY_1_EMAILS")}</a>}
                className="email-preview__details__text base-email-template__body-1"
                component="span"
                shouldTranslate={false}
                noMargin
              />
              
              <Text
                content="BASE_EMAIL_TEMPLATE_BODY_1_PAGE"
                className="email-preview__details__text base-email-template__body-1"
                component="span"
                noMargin
              />

              <div className="email-preview__details__button base-email-template__button-left">
                <Button
                  id="select-plan-continue"
                  type="button"
                  className="email-preview__details__button-orange base-email-template__button"
                  title="BASE_EMAIL_TEMPLATE_BUTTON_1"
                  style={{ backgroundColor: !!highlightColor ? highlightColor : defaultHighLightColor }}
                />
              </div>

              <Text
                content="BASE_EMAIL_TEMPLATE_BODY_2"
                className="email-preview__details__text"
                component="span"
                noMargin
              />

              <Text
                content="BASE_EMAIL_TEMPLATE_REGARDS"
                className="email-preview__details__text"
                component="span"
                noMargin
              />

              <Text
                content="BASE_EMAIL_TEMPLATE_COMPANY_NAME"
                className="email-preview__details__text"
                component="span"
                noMargin
              />

              <div className="base-email-template__footer__ads-container">
                {includeAdvert && !!avertisementImageUrl && <a href={advertisementUrl} target="_self"><img src={avertisementImageUrl} alt="no img" className="base-email-template__footer__ads" /></a>}

                {includeAdvert && !avertisementImageUrl && <div className="base-email-template__footer__ads-container__placeholder">
                  <p className="base-email-template__footer__ads-container__placeholder__title">Advertising space</p>
                  <p className="base-email-template__footer__ads-container__placeholder__sub-title">500 x 150px .png</p>
                </div>}

              </div>

              {footerText && <div className="base-email-template__footer__text-container">
                <Text
                  content={<span dangerouslySetInnerHTML={{__html: footerText}}></span>}
                  shouldTranslate={false}
                  className="email-preview__details__text base-email-template__footer__text"
                  component="span"
                  noMargin
                />
              </div>}
            </EmailPreivewContainer>
          </Panel>
        </Col>
        <Col sm={4}>

          <EmailCommandButton title={counterpart("BASE_EMAIL_TEMPLATE_COMPANY_LOGO_AND_COLORS")} id={"custom-copy"}
            description={counterpart("BASE_EMAIL_TEMPLATE_COMPANY_LOGO_AND_COLORS_DESCRIPTION")}
            buttonText={counterpart("BASE_EMAIL_TEMPLATE_COMPANY_LOGO_AND_COLORS_BUTTON_TEXT")}
            onSubmit={() => history.push("/configuration/brand-customization")} />

          <EmailAdvertisement title={counterpart("BASE_EMAIL_TEMPLATE_COMPANY_ADVERTISEMENT_SPACE")} id={"advertisement"} linkUrl={advertisementUrl}
            logoUrl='' onDrop={(files) => updateAds(files[0])}
            hideAdvertisement={!includeAdvert} onHideAdvertisementChange={() => handleIncludeAdvertChange()}
            buttonText={counterpart("BASE_EMAIL_TEMPLATE_COMPANY_ADVERTISEMENT_SPACE_BUTTON_TEXT")}
            onSubmit={() => saveChanges("ads")}
            onLinkUrlChange={(evt: any) => { dispatch(setBaseEmailTemplateField("advertisementUrl", evt.target.value)) }}
            isLoading={adsIsLoading} hasIncludeAdvertOption={true} />

          <EmailTextArea title={counterpart("BASE_EMAIL_TEMPLATE_FOOTER_TEXT_TITLE")}
            description={counterpart("BASE_EMAIL_TEMPLATE_FOOTER_TEXT_DESCRIPTION")}
            id={"custom-copy"} value={footerText}
            buttonText={counterpart("BASE_EMAIL_TEMPLATE_FOOTER_TEXT_BUTTON")}
            onSubmit={() => saveChanges("custom1")}
            onChange={(evt: any) => { dispatch(setBaseEmailTemplateField("footerText", evt.target.value)) }}
            isLoading={custom1IsLoading} />


          {
          /* HIDDEN FOR NOW
          <EmailCommandButton title={counterpart("BASE_EMAIL_TEMPLATE_WHITE_LABEL_TITLE")} id={"custom-copy"}
            description={counterpart("BASE_EMAIL_TEMPLATE_WHITE_LABEL_DESCRIPTION")}
            buttonText={counterpart("BASE_EMAIL_TEMPLATE_WHITE_LABEL_BUTTON")}
            onSubmit={() => { }} />
          */}
        </Col>
      </Row>
    </div>
  )
}

export default EmailBaseTemplate