import React from "react";
import "./quiz.scss";
import Panel from "../ui/panel/Panel";
import Text from "../ui/text/Text";
import Button from "../ui/button/Button";
import { QUIZ_FIELD_CUSTOMER_TYPE } from "../../utils/constants";

interface IQuizStep1 {
  onContinue: (nextStep: number, fieldType: string, fieldValue: string) => any
}

const QuizStep1: React.FC<IQuizStep1> = ({ onContinue }) => {
  return (
    <div className="quiz quiz-step1">
      <Panel title="QUIZ_STEP1_TITLE">
        <Text content="QUIZ_STEP1_SUBTITLE" />
        <Button id="quiz-step1" title="QUIZ_STEP1_BTN1" isFullWidth onClick={() => onContinue(3, QUIZ_FIELD_CUSTOMER_TYPE, "Small Business")} />
        <Button id="quiz-step2" title="QUIZ_STEP1_BTN2" isFullWidth onClick={() => onContinue(3, QUIZ_FIELD_CUSTOMER_TYPE, "Membership Business")} />
        <Button id="quiz-step3" title="QUIZ_STEP1_BTN3" isFullWidth onClick={() => onContinue(3, QUIZ_FIELD_CUSTOMER_TYPE, "Startup")} />
        <Button id="quiz-step4" title="QUIZ_STEP1_BTN4" isFullWidth onClick={() => onContinue(3, QUIZ_FIELD_CUSTOMER_TYPE, "Enterprise")} />
        <Button id="quiz-step5" title="QUIZ_STEP1_BTN5" isFullWidth onClick={() => onContinue(2, QUIZ_FIELD_CUSTOMER_TYPE, "Migration Opportunity")} />
        <Text content="QUIZ_STEP1_LINK" className="quiz__link" noMargin onClick={() => onContinue(3, QUIZ_FIELD_CUSTOMER_TYPE, "Ignore")} />
      </Panel>

    </div>
  )
}

export default QuizStep1;