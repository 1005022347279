import React, { useEffect, useState } from "react";
import FormGroup from "../../../components/ui/form-group/FormGroup";
import Panel from "../../../components/ui/panel/Panel";
import "./TwoFactorAuthLogin.scss";
import Text from "../../../components/ui/text/Text";
import Input from "../../../components/ui/input/Input";
import Button from "../../../components/ui/button/Button";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../../..";
import { ILoginReducerState } from "../../../reducers/loginReducer";
import { setSixDigits2faPart2, loginUser, setSixDigits2faPart1, clearErrors, sendLoginCodeViaSMS } from "../../../actions/loginActions";
import InfoLabel from "../../../components/ui/info-label/InfoLabel";
import counterpart from "counterpart";
import history from "../../../utils/history";



const TwoFactorAuthLogin: React.FC = () => {
  const { email, password, sixDigits2faPart1, sixDigits2faPart2, isLoginRequest, isLoginSuccess, isLoginError } = useSelector<AppState, ILoginReducerState>(state => state.login)
  const dispatch = useDispatch<Function>()

  useEffect(() => {
    if(!email || !password) {
      history.push("/");
    }
    return () => {
      dispatch(clearErrors());
      dispatch(setSixDigits2faPart1(""));
      dispatch(setSixDigits2faPart2(""));
    }
  }, []);

  const [isSentSMS, setIsSentSMS] = useState(false);
  const [last4Digits, setLast4Digits] = useState(null);
  const sendLoginCode = async () => {
    try {
      setIsSentSMS(false);
      const resp = await dispatch(sendLoginCodeViaSMS({ email, password }));
      if(!resp.error && resp.response.phoneNumberLast4) {
        setLast4Digits(resp.response.phoneNumberLast4);
        setIsSentSMS(true);
      }
    }
    catch (err) {  }
  }

  return (
    <FormGroup className="two-factor-auth-login">
      <Panel className="two-factor-auth-login__heading" title="TWO_FACTOR_AUTH_LOGIN_TITLE">
        <Text content="TWO_FACTOR_AUTH_LOGIN_CONTENT1" />
        {!isSentSMS && <Text content="TWO_FACTOR_AUTH_LOGIN_CONTENT2" className="two-factor-auth-login__heading-link" onClick={sendLoginCode} noMargin />}
        <InfoLabel type="success" content={counterpart("TWO_FACTOR_AUTH_SENT_SMS_CODE", { last4Digits })} shouldTranslate={false} isVisible={isSentSMS} noMargin />
      </Panel>

      <Panel className="two-factor-auth-login__six-digits">
        <Text content="TWO_FACTOR_AUTH_LOGIN_SIX_DIGITS" noMargin />
        <div>
          <Input
            id="six-digit-first-part"
            value={sixDigits2faPart1}
            maxLength="3"
            onChange={(evt: any) => {
              dispatch(clearErrors());
              dispatch(setSixDigits2faPart1(evt.target.value));
            }}
            placeholder="123"
          />
          <Input
            id="six-digit-second-part"
            value={sixDigits2faPart2}
            maxLength="3"
            onChange={(evt: any) => {
              dispatch(clearErrors());
              dispatch(setSixDigits2faPart2(evt.target.value));
            }}
            placeholder="456"
          />
          {isLoginError && (!sixDigits2faPart1 || !sixDigits2faPart2) && <InfoLabel content="TWO_FACTOR_AUTH_ERROR_LOGIN_EMPTY_SIX_DIGITS" type="error" noMargin />}
          {isLoginError && sixDigits2faPart1 && sixDigits2faPart2 && <InfoLabel content="TWO_FACTOR_AUTH_ERROR_LOGIN_EMPTY_WRONG_CODE" type="error" noMargin />}
        </div>
      </Panel>

      <Button
        id="continue-with-two-fa"
        title="TWO_FACTOR_AUTH_LOGIN_CONTINUE"
        isFullWidth
        isLoading={isLoginRequest}
        disabled={isLoginRequest}
        buttonType="general"
        onClick={() => dispatch(loginUser(email, password, `${sixDigits2faPart1}${sixDigits2faPart2}`))}
      />
    </FormGroup>
  )
}

export default TwoFactorAuthLogin