
import API from "../utils/API";
import { ConfigConstants } from "../utils/config";
import { CompanyServiceClient } from "../utils/grpc/generated/Billsby.Protos/core/private/companies/CompanyServiceClientPb";
import { GetCompanyLanguagesRequest } from "../utils/grpc/generated/Billsby.Protos/core/private/companies/company_pb";
import { grpcUnaryCall } from "../utils/grpc/grpcUtils";
import { makeActionCreator } from "../utils/reduxUtils";

export const FETCH_EMBED_CODES_CUSTOM_FIELDS_REQUEST = "FETCH_EMBED_CODES_CUSTOM_FIELDS_REQUEST";
export const FETCH_EMBED_CODES_CUSTOM_FIELDS_SUCCESS = "FETCH_EMBED_CODES_CUSTOM_FIELDS_SUCCESS";
export const FETCH_EMBED_CODES_CUSTOM_FIELDS_FAILURE = "FETCH_EMBED_CODES_CUSTOM_FIELDS_FAILURE";

export const SET_CUSTOM_FIELD_PANEL_IS_COLLAPSED = "SET_CUSTOM_FIELD_PANEL_IS_COLLAPSED";
export const setCustomFieldPanelIsCollapsed = makeActionCreator(SET_CUSTOM_FIELD_PANEL_IS_COLLAPSED, "payload");

export const SET_SELECTED_EMBED_CODES_CUSTOM_FIELD = "SET_SELECTED_EMBED_CODES_CUSTOM_FIELD";
export const setSelectedEmbedCodesCustomField = makeActionCreator(SET_SELECTED_EMBED_CODES_CUSTOM_FIELD, "payload");

export const FETCH_AVAILABLE_LANGUAGES_REQUEST = "FETCH_AVAILABLE_LANGUAGES_REQUEST";
export const FETCH_AVAILABLE_LANGUAGES_SUCCESS = "FETCH_AVAILABLE_LANGUAGES_SUCCESS";
export const FETCH_AVAILABLE_LANGUAGES_FAILURE = "FETCH_AVAILABLE_LANGUAGES_FAILURE";

export const RESET_EMBED_CODES_CUSTOM_FIELDS = "RESET_EMBED_CODES_CUSTOM_FIELDS";
export const resetEmbedCodesCustomFields = makeActionCreator(RESET_EMBED_CODES_CUSTOM_FIELDS, "payload");

export const fetchEmbedCodesCustomFields = (companyDomain: string) => {
  return {
    types: [FETCH_EMBED_CODES_CUSTOM_FIELDS_REQUEST, FETCH_EMBED_CODES_CUSTOM_FIELDS_SUCCESS, FETCH_EMBED_CODES_CUSTOM_FIELDS_FAILURE],
    callAPI: () => API.getCustomFields(companyDomain)
  }
}

export const fetchEmbedCodesLanguages = (companyId: number) => {
  const getCompanyLanguagesRequest = new GetCompanyLanguagesRequest();
  const companyServiceClient = new CompanyServiceClient(ConfigConstants.grpcBaseUrl);
  getCompanyLanguagesRequest.setCompanyId(companyId);

  return {
    types: [FETCH_AVAILABLE_LANGUAGES_REQUEST, FETCH_AVAILABLE_LANGUAGES_SUCCESS, FETCH_AVAILABLE_LANGUAGES_FAILURE],
    callAPI: () => grpcUnaryCall(getCompanyLanguagesRequest, companyServiceClient, companyServiceClient.getCompanyLanguages)
  }

}