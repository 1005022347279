/**
 * @fileoverview gRPC-Web generated client stub for billing.private.subscription
 * @enhanceable
 * @public
 */

// GENERATED CODE -- DO NOT EDIT!


import * as grpcWeb from "grpc-web";


import {
  CancelSubscriptionRequest,
  CancelSubscriptionResponse,
  CheckoutAddonsSimulationRequest,
  CheckoutAddonsSimulationResponse,
  CheckoutAllowancesSimulationRequest,
  CheckoutAllowancesSimulationResponse,
  DeleteSubscriptionAddonRequest,
  DeleteSubscriptionAddonResponse,
  DeleteSubscriptionAllowanceRequest,
  DeleteSubscriptionAllowanceResponse,
  GetAddonsRequest,
  GetAddonsResponse,
  GetAllowancesRequest,
  GetAllowancesResponse,
  GetCancellationDetailsRequest,
  GetCancellationDetailsResponse,
  UpdateSubscriptionAddonsRequest,
  UpdateSubscriptionAddonsResponse,
  UpdateSubscriptionAllowanceRequest,
  UpdateSubscriptionAllowanceResponse,
  UpdateSubscriptionStatusRequest,
  UpdateSubscriptionStatusResponse} from "./subscription_pb";

export class SubscriptionServiceClient {
  client_: grpcWeb.AbstractClientBase;
  hostname_: string;
  credentials_: null | { [index: string]: string; };
  options_: null | { [index: string]: string; };

  constructor (hostname: string,
               credentials?: null | { [index: string]: string; },
               options?: null | { [index: string]: string; }) {
    if (!options) options = {};
    if (!credentials) credentials = {};
    options["format"] = "text";

    this.client_ = new grpcWeb.GrpcWebClientBase(options);
    this.hostname_ = hostname;
    this.credentials_ = credentials;
    this.options_ = options;
  }

  methodInfoCancelSubscription = new grpcWeb.AbstractClientBase.MethodInfo(
    CancelSubscriptionResponse,
    (request: CancelSubscriptionRequest) => {
      return request.serializeBinary();
    },
    CancelSubscriptionResponse.deserializeBinary
  );

  cancelSubscription(
    request: CancelSubscriptionRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: CancelSubscriptionResponse) => void) {
    return this.client_.rpcCall(
      this.hostname_ +
        "/billing.private.subscription.SubscriptionService/CancelSubscription",
      request,
      metadata || {},
      this.methodInfoCancelSubscription,
      callback);
  }

  methodInfoGetCancellationDetails = new grpcWeb.AbstractClientBase.MethodInfo(
    GetCancellationDetailsResponse,
    (request: GetCancellationDetailsRequest) => {
      return request.serializeBinary();
    },
    GetCancellationDetailsResponse.deserializeBinary
  );

  getCancellationDetails(
    request: GetCancellationDetailsRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: GetCancellationDetailsResponse) => void) {
    return this.client_.rpcCall(
      this.hostname_ +
        "/billing.private.subscription.SubscriptionService/GetCancellationDetails",
      request,
      metadata || {},
      this.methodInfoGetCancellationDetails,
      callback);
  }

  methodInfoGetAddons = new grpcWeb.AbstractClientBase.MethodInfo(
    GetAddonsResponse,
    (request: GetAddonsRequest) => {
      return request.serializeBinary();
    },
    GetAddonsResponse.deserializeBinary
  );

  getAddons(
    request: GetAddonsRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: GetAddonsResponse) => void) {
    return this.client_.rpcCall(
      this.hostname_ +
        "/billing.private.subscription.SubscriptionService/GetAddons",
      request,
      metadata || {},
      this.methodInfoGetAddons,
      callback);
  }

  methodInfoDeleteAddon = new grpcWeb.AbstractClientBase.MethodInfo(
    DeleteSubscriptionAddonResponse,
    (request: DeleteSubscriptionAddonRequest) => {
      return request.serializeBinary();
    },
    DeleteSubscriptionAddonResponse.deserializeBinary
  );

  deleteAddon(
    request: DeleteSubscriptionAddonRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: DeleteSubscriptionAddonResponse) => void) {
    return this.client_.rpcCall(
      this.hostname_ +
        "/billing.private.subscription.SubscriptionService/DeleteAddon",
      request,
      metadata || {},
      this.methodInfoDeleteAddon,
      callback);
  }

  methodInfoUpdateSubscriptionAddons = new grpcWeb.AbstractClientBase.MethodInfo(
    UpdateSubscriptionAddonsResponse,
    (request: UpdateSubscriptionAddonsRequest) => {
      return request.serializeBinary();
    },
    UpdateSubscriptionAddonsResponse.deserializeBinary
  );

  updateSubscriptionAddons(
    request: UpdateSubscriptionAddonsRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: UpdateSubscriptionAddonsResponse) => void) {
    return this.client_.rpcCall(
      this.hostname_ +
        "/billing.private.subscription.SubscriptionService/UpdateSubscriptionAddons",
      request,
      metadata || {},
      this.methodInfoUpdateSubscriptionAddons,
      callback);
  }

  methodInfoCheckoutAddonsSimulation = new grpcWeb.AbstractClientBase.MethodInfo(
    CheckoutAddonsSimulationResponse,
    (request: CheckoutAddonsSimulationRequest) => {
      return request.serializeBinary();
    },
    CheckoutAddonsSimulationResponse.deserializeBinary
  );

  checkoutAddonsSimulation(
    request: CheckoutAddonsSimulationRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: CheckoutAddonsSimulationResponse) => void) {
    return this.client_.rpcCall(
      this.hostname_ +
        "/billing.private.subscription.SubscriptionService/CheckoutAddonsSimulation",
      request,
      metadata || {},
      this.methodInfoCheckoutAddonsSimulation,
      callback);
  }

  methodInfoUpdateSubscriptionAllowance = new grpcWeb.AbstractClientBase.MethodInfo(
    UpdateSubscriptionAllowanceResponse,
    (request: UpdateSubscriptionAllowanceRequest) => {
      return request.serializeBinary();
    },
    UpdateSubscriptionAllowanceResponse.deserializeBinary
  );

  updateSubscriptionAllowance(
    request: UpdateSubscriptionAllowanceRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: UpdateSubscriptionAllowanceResponse) => void) {
    return this.client_.rpcCall(
      this.hostname_ +
        "/billing.private.subscription.SubscriptionService/UpdateSubscriptionAllowance",
      request,
      metadata || {},
      this.methodInfoUpdateSubscriptionAllowance,
      callback);
  }

  methodInfoDeleteSubscriptionAllowance = new grpcWeb.AbstractClientBase.MethodInfo(
    DeleteSubscriptionAllowanceResponse,
    (request: DeleteSubscriptionAllowanceRequest) => {
      return request.serializeBinary();
    },
    DeleteSubscriptionAllowanceResponse.deserializeBinary
  );

  deleteSubscriptionAllowance(
    request: DeleteSubscriptionAllowanceRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: DeleteSubscriptionAllowanceResponse) => void) {
    return this.client_.rpcCall(
      this.hostname_ +
        "/billing.private.subscription.SubscriptionService/DeleteSubscriptionAllowance",
      request,
      metadata || {},
      this.methodInfoDeleteSubscriptionAllowance,
      callback);
  }

  methodInfoGetAllowances = new grpcWeb.AbstractClientBase.MethodInfo(
    GetAllowancesResponse,
    (request: GetAllowancesRequest) => {
      return request.serializeBinary();
    },
    GetAllowancesResponse.deserializeBinary
  );

  getAllowances(
    request: GetAllowancesRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: GetAllowancesResponse) => void) {
    return this.client_.rpcCall(
      this.hostname_ +
        "/billing.private.subscription.SubscriptionService/GetAllowances",
      request,
      metadata || {},
      this.methodInfoGetAllowances,
      callback);
  }

  methodInfoCheckoutAllowancesSimulation = new grpcWeb.AbstractClientBase.MethodInfo(
    CheckoutAllowancesSimulationResponse,
    (request: CheckoutAllowancesSimulationRequest) => {
      return request.serializeBinary();
    },
    CheckoutAllowancesSimulationResponse.deserializeBinary
  );

  checkoutAllowancesSimulation(
    request: CheckoutAllowancesSimulationRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: CheckoutAllowancesSimulationResponse) => void) {
    return this.client_.rpcCall(
      this.hostname_ +
        "/billing.private.subscription.SubscriptionService/CheckoutAllowancesSimulation",
      request,
      metadata || {},
      this.methodInfoCheckoutAllowancesSimulation,
      callback);
  }

  methodInfoUpdateSubscriptionStatus = new grpcWeb.AbstractClientBase.MethodInfo(
    UpdateSubscriptionStatusResponse,
    (request: UpdateSubscriptionStatusRequest) => {
      return request.serializeBinary();
    },
    UpdateSubscriptionStatusResponse.deserializeBinary
  );

  updateSubscriptionStatus(
    request: UpdateSubscriptionStatusRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: UpdateSubscriptionStatusResponse) => void) {
    return this.client_.rpcCall(
      this.hostname_ +
        "/billing.private.subscription.SubscriptionService/UpdateSubscriptionStatus",
      request,
      metadata || {},
      this.methodInfoUpdateSubscriptionStatus,
      callback);
  }

}

