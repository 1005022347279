import React, { useEffect, useMemo, useState } from "react";
import Panel from "../ui/panel/Panel";
import Text from "../ui/text/Text";
import "./ExportData.scss";
import Switch from "../ui/switch/Switch";
import DateTimePicker from "../../components/ui/date-time-picker/DateTimePicker";
import FormGroup from "../ui/form-group/FormGroup";
import { Col, Row } from "react-grid-system";
import FormLabel from "../ui/form-label/FormLabel";
import Button from "../ui/button/Button";
import { ExportSubscriptionsRequest } from "../../utils/grpc/generated/Billsby.Protos/CsvExporter/private/subscriptions/subscriptions_pb";
import { ExportInvoicesRequest } from "../../utils/grpc/generated/Billsby.Protos/CsvExporter/private/invoices/invoices_pb";
import { ExportCustomersRequest } from "../../utils/grpc/generated/Billsby.Protos/CsvExporter/private/customers/customers_pb";
import { ExportCreditNotesRequest } from "../../utils/grpc/generated/Billsby.Protos/CsvExporter/private/creditnotes/credit_notes_pb";
import { SubscriptionsExporterServiceClient } from "../../utils/grpc/generated/Billsby.Protos/CsvExporter/private/subscriptions/SubscriptionsServiceClientPb";
import { InvoicesExporterServiceClient } from "../../utils/grpc/generated/Billsby.Protos/CsvExporter/private/invoices/InvoicesServiceClientPb";
import { CreditNotesExporterServiceClient } from "../../utils/grpc/generated/Billsby.Protos/CsvExporter/private/creditnotes/Credit_notesServiceClientPb";
import { CustomersExporterServiceClient } from "../../utils/grpc/generated/Billsby.Protos/CsvExporter/private/customers/CustomersServiceClientPb";
import { ConfigConstants } from "../../utils/config";
import { Timestamp } from "google-protobuf/google/protobuf/timestamp_pb";
import { Int32Value } from "google-protobuf/google/protobuf/wrappers_pb";
import moment from "moment";
import { grpcUnaryCall } from "../../utils/grpc/grpcUtils";
import NoticePanel from "../ui/notice-panel/NoticePanel";

interface IExportData {
  title: string,
  content: string,
  type: "subscriptions" | "invoices" | "credit-notes" | "customers",
  companyId: number | null,
  callback?: Function
}

const ExportData: React.FC<IExportData> = ({ title, content, callback, companyId, type = "customers" }) => {
  const [isFiltered, setIsFiltered] = useState(true);
  const [startDate, setStartDate] = useState<Date | undefined>(undefined);
  const [endDate, setEndDate] = useState<Date | undefined>(undefined);
  const [isLoading, setIsLoading] = useState(false);
  const [hasError, setHasError] = useState(false);

  // reset data after disabling date filter
  useEffect(() => {
    if (!isFiltered) {
      setStartDate(undefined);
      setEndDate(undefined);
    }
  }, [isFiltered])

  const isValidDateFilter = useMemo(() => {
    if (isFiltered && (!startDate || !endDate)) {
      return false;
    }
    return true;
  }, [isFiltered, startDate, endDate]);

  const onSubmit = async () => {
    setHasError(false);
    setIsLoading(true);

    try {
      await exportData();
      callback && callback();
    }
    catch (err) {
      setHasError(true);
    }
    finally {
      setIsLoading(false);
    }
  }

  const renderWelcomePanel = () => {
    return (
      <Panel className="export-data__welcome" title={title}>
        <Text content={content} noMargin />
      </Panel>
    )
  }

  const exportData = async () => {
    let request;
    let serviceClient;
    let serviceClientMethod;

    const companyIdInt32 = new Int32Value();
    const startDateTimestamp = new Timestamp();
    const endDateTimeStamp = new Timestamp();

    const getDateDetails = (date: Date) => {
      return moment.utc([date.getFullYear(), date.getMonth(), date.getDate()]).toDate()
    }

    if (type === "subscriptions") {
      request = new ExportSubscriptionsRequest();
      serviceClient = new SubscriptionsExporterServiceClient(ConfigConstants.grpcBaseUrl);
      serviceClientMethod = serviceClient.exportSubscriptions;
    }
    if (type === "invoices") {
      request = new ExportInvoicesRequest();
      serviceClient = new InvoicesExporterServiceClient(ConfigConstants.grpcBaseUrl);
      serviceClientMethod = serviceClient.exportInvoices;
    }
    if (type === "credit-notes") {
      request = new ExportCreditNotesRequest();
      serviceClient = new CreditNotesExporterServiceClient(ConfigConstants.grpcBaseUrl);
      serviceClientMethod = serviceClient.exportCreditNotes;
    }
    if (type === "customers") {
      request = new ExportCustomersRequest();
      serviceClient = new CustomersExporterServiceClient(ConfigConstants.grpcBaseUrl);
      serviceClientMethod = serviceClient.exportCustomers;
    }
    startDate && startDateTimestamp.fromDate(getDateDetails(startDate));
    endDate && endDateTimeStamp.fromDate(getDateDetails(endDate));

    if (!request || !companyId || !serviceClient || !serviceClientMethod) {
      return;
    }

    companyIdInt32.setValue(companyId);
    request.setCompanyId(companyIdInt32);
    startDate && request.setFromDate(startDateTimestamp);
    endDate && request.setToDate(endDateTimeStamp);

    try {
      await grpcUnaryCall(request, serviceClient, serviceClientMethod);
    } catch (err) {
      console.log(err)
      throw err
    }

  }

  const renderDateFilter = () => {
    return (
      <Panel className="export-data__datefilter">
        <FormGroup noMargin={!isFiltered}>
          <Text content="EXPORT_DATA_FILTER_DATE" noMargin />
          <Switch
            id="export-data-filter-switch"
            checked={isFiltered}
            onChange={() => setIsFiltered(!isFiltered)}
          />
        </FormGroup>
        {
          isFiltered && (
            <>
              <FormGroup>
                <Row align='center'>
                  <Col md={2.5}>
                    <FormLabel target='start-date' content='EXPORT_DATA_FILTER_START_DATE' noMargin />
                  </Col>
                  <Col md={9.5}>
                    <DateTimePicker
                      className=""
                      value={startDate}
                      maxDate={moment.utc().toDate()}
                      showTimeInput={false}
                      filterDate={endDate ? (date) => date <= (endDate || new Date()) : undefined}
                      onChange={(date) => { setStartDate(moment.utc(date).toDate()) }}
                    />
                  </Col>
                </Row>
              </FormGroup>
              <FormGroup noMargin>
                <Row align='center'>
                  <Col md={2.5}>
                    <FormLabel target='end-date' content='EXPORT_DATA_FILTER_END_DATE' noMargin />
                  </Col>
                  <Col md={9.5}>
                    <DateTimePicker
                      className=""
                      value={endDate}
                      maxDate={moment.utc().toDate()}
                      showTimeInput={false}
                      filterDate={startDate ? (date) => date >= (startDate || new Date()) : undefined}
                      onChange={(date) => { setEndDate(moment.utc(date).toDate()) }}
                    />
                  </Col>
                </Row>
              </FormGroup>
            </>
          )
        }
      </Panel>
    )
  }

  const renderError = () => {
    if (hasError) {
      return (
        <NoticePanel
          type="error"
          content="EXPORT_DATA_ERROR"
          isModal
        />
      )
    } 

    return null
  }

  return (
    <div className="export-data">
      {renderError()}
      {renderWelcomePanel()}
      {renderDateFilter()}

      <Button
        id="export-data-btn"
        isLoading={isLoading && !hasError}
        disabled={isLoading || !isValidDateFilter}
        onClick={onSubmit}
        title="EXPORT_DATA_BUTTON"
        isFullWidth
      />
    </div>
  )
}

export default ExportData