import { BillsbyAction } from "../models/BillsbyAction";
import { AppState } from "..";
import { CardStatusType, CustomerStatusType, ICustomersPaginated } from "../models/Customers";
import { FETCH_CUSTOMERS_SUCCESS, SET_CUSTOMERS_FIELD, RESET_CUSTOMERS, FETCH_CUSTOMERS_FAILURE, FETCH_CUSTOMERS_REQUEST } from "../actions/customersActions";
import { IPageNotification } from "../models/Configuration";

export interface ICustomersReducer {
  customersPaginated: ICustomersPaginated,
  customerStatus?: { label: string, value: CustomerStatusType },
  cardStatus?: { label: string, value: CardStatusType },
  searchedCustomer: string,
  pageNotification?: IPageNotification,
  createCustomerError: boolean, 
  isLoading: boolean,
  isFirstSearch: boolean,
  isCreatedOnDescendingOrder: boolean
}

export const customersInitialState = () => {
  return {
    customersPaginated: {
      currentPage: 1,
      pageCount: 1,
      pageSize: 0,
      rowCount: 0,
      results: undefined
    },
    searchedCustomer: "",
    isLoading: true,
    isFirstSearch: true,
    createCustomerError: false,
    isCreatedOnDescendingOrder: true
  }
}


export default function customersReducer(state: ICustomersReducer = customersInitialState(), action: BillsbyAction, store: AppState) {
  switch (action.type) {
    case SET_CUSTOMERS_FIELD:
      return { ...state, [action.fieldName]: action.fieldValue }
    case FETCH_CUSTOMERS_REQUEST:
      return { ...state, isLoading: true, isFirstSearch: !state.customersPaginated.results }
    case FETCH_CUSTOMERS_SUCCESS:
      return { ...state, customersPaginated: action.response, isLoading: false }
    case FETCH_CUSTOMERS_FAILURE:
      return { ...state, isLoading: false }
    case RESET_CUSTOMERS:
      return customersInitialState();
    default:
      return state;
  }
}