import { BillsbyAction } from "../models/BillsbyAction";
import { IInvoicesPaginated, InvoiceStatus } from "../models/Invoices";
import {
  FETCH_INVOICES_SUCCESS,
  SET_INVOICES_FIELD,
  RESET_INVOICES,
  FETCH_INVOICES_REQUEST,
  FETCH_INVOICES_FAILURE
} from "../actions/invoicesActions";
import { AppState } from "..";
import { IPageNotification } from "../models/Configuration";

export interface IInvoicesReducer {
  invoicesPaginated: IInvoicesPaginated,
  searchedInvoices: string,
  invoiceStatus?: { label: string, value: InvoiceStatus }
  isDateDescendingOrder: boolean,
  isFetching: boolean;
  pageNotification?: IPageNotification
}

export const invoicesListInitialState: IInvoicesReducer = {
  invoicesPaginated: {
    currentPage: 1,
    pageCount: 1,
    pageSize: 0,
    rowCount: 0,
    results: undefined
  },
  searchedInvoices: "",
  isDateDescendingOrder: true,
  isFetching: false
};

const invoicesReducer = (state: IInvoicesReducer = invoicesListInitialState, action: BillsbyAction, store: AppState) => {
  switch (action.type) {
    case SET_INVOICES_FIELD:
      return { ...state, [action.fieldName]: action.fieldValue };
    case FETCH_INVOICES_REQUEST:
      return { ...state, isFetching: true }
    case FETCH_INVOICES_FAILURE:
      return { ...state, isFetching: false }
    case FETCH_INVOICES_SUCCESS:
      return {
        ...state,
        invoicesPaginated: action.response,
        isFetching: false
      };
    case RESET_INVOICES:
      return invoicesListInitialState;
    default:
      return state;
  }
};

export default invoicesReducer;
