import React, { useEffect } from "react";
import { IAuthReducerState } from "../../../../reducers/authReducer";
import Panel from "../../../../components/ui/panel/Panel";
import Text from "../../../../components/ui/text/Text";
import Button from "../../../../components/ui/button/Button";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../../../..";
import history from "../../../../utils/history";
import NavigationBar from "../../../../components/ui/navigation-bar/NavigationBar";
import PageWrapper from "../../../../components/ui/layout/PageWrapper";
import "./BillsbyPages.scss";
import billsbyPagesLogo from "../../../../images/website_builders/billsby_pages_white_logo.png";
import billsbyPagesHome from "../../../../images/website_builders/billsby_pages_home.png";
import CodePanel from "../../../ui/code-panel/CodePanel";
import { ICompanyApiKeyReducerState } from "../../../../reducers/companyApiKeyReducer";
import moment from "moment";
import { fetchCompanyApiKey } from "../../../../actions/companyApiKeysActions";


const BillsbyPages: React.FC = () => {
  const { apiKey, createdOn } = useSelector<AppState, ICompanyApiKeyReducerState>(state => state.companyApiKeyReducer);
  const { currentCompanyDomain } = useSelector<AppState, IAuthReducerState>(state => state.auth);
  const dispatch = useDispatch<Function>()
  const createdOnDate = moment(createdOn).format("DD MMM YYYY");
  const createdOnTime = moment(createdOn).format("HH:mm Z");

  useEffect(() => {
    dispatch(fetchCompanyApiKey(currentCompanyDomain));
  }, [])

  return (
    <div className="billsby-pages">
      <NavigationBar
        pageTitle=""        
        previousPageTitle={"INTEGRATIONS_TITLE"}
        previousPageCb={() => history.push("/integrations")}
      />

      <PageWrapper>
        <Panel className="billsby-pages__heading">
          <div className="billsby-pages__heading__text-group">
            <img src={billsbyPagesLogo} alt="no img" />
            <Text content="BILLSBY_PAGES_HEADER_TEXT1" className="billsby-pages__header1" noMargin />
            <Text content="BILLSBY_PAGES_HEADER_TEXT2" noMargin />
          </div>
          <Button
            id="create-billsby-page"
            className="billsby-pages__heading__create-page-btn"
            title="BILLSBY_PAGES_CREATE_YOUR_BILLSBY_PAGE"
            onClick={(evt: any) => {
              evt.stopPropagation();
              window.open("https://pages.billsby.com/account", "_self");
            }}
          />
        </Panel>

        <Panel className="billsby-pages__api-key" title="BILLSBY_PAGES_YOUR_API_KEY">
        <CodePanel id="billsby-pages__api-key" codeStr={apiKey} noMargin>
          <>
            <span className="billsby-pages__api-key-copy">
              {apiKey}
            </span>
            <Text content="BILLSBY_PAGES_YOUR_API_KEY_CREATED_ON" translateWith={{createdOnDate, createdOnTime}} className="billsby-pages__api-key-created" noMargin />
          </>
        </CodePanel>
      </Panel>

        <Panel className="billsby-pages__content">
          <div className="billsby-pages__content__image-container">
            <img src={billsbyPagesHome} className="billsby-pages__content__image" alt="no img" />
          </div>
          <div className="billsby-pages__content__text">
            <div className="billsby-pages__content__text-group">
              <Text content="BILLSBY_PAGES_CONTENT_TITLE1" className="billsby-pages__content__text__title" noMargin />
              <Text content="BILLSBY_PAGES_CONTENT_TEXT1" noMargin />
            </div>

            <div className="billsby-pages__content__text-group">
              <Text content="BILLSBY_PAGES_CONTENT_TITLE2" className="billsby-pages__content__text__title" noMargin />
              <Text content="BILLSBY_PAGES_CONTENT_TEXT2" noMargin />
            </div>
                
            <div className="billsby-pages__content__text-group">
              <Text content="BILLSBY_PAGES_CONTENT_TITLE3" className="billsby-pages__content__text__title" noMargin />
              <Text content="BILLSBY_PAGES_CONTENT_TEXT3" noMargin />
            </div>
                
            <div className="billsby-pages__content__text-group">
              <Text content="BILLSBY_PAGES_CONTENT_TITLE4" className="billsby-pages__content__text__title" noMargin />
              <Text content="BILLSBY_PAGES_CONTENT_TEXT4" noMargin />
            </div>
                
            <div className="billsby-pages__content__text-group">
              <Text content="BILLSBY_PAGES_CONTENT_TITLE5" className="billsby-pages__content__text__title" noMargin />
              <Text content="BILLSBY_PAGES_CONTENT_TEXT5" noMargin />
            </div>
                
            <div className="billsby-pages__content__text-group">
              <Text content="BILLSBY_PAGES_CONTENT_TITLE6" className="billsby-pages__content__text__title" noMargin />
              <Text content="BILLSBY_PAGES_CONTENT_TEXT6" noMargin />
            </div>
          </div>               
        </Panel>
      </PageWrapper>

    </div>
  );
};

export default BillsbyPages