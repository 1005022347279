import React from "react";
import "./EventsWelcome.scss";

import Panel from "../../components/ui/panel/Panel";
import Text from "../../components/ui/text/Text";

export const EventsWelcome: React.FC = () => {
  return (
    <div className="events-welcome">
     <Panel className="events-welcome__greeting" title="EVENTS_WELCOME_TITLE">
      <Text content="EVENTS_WELCOME_TEXT" />
      <Panel className="events-welcome__greeting-question" title="EVENTS_FIRST_NOTE_TITLE">
        <Text noMargin content="EVENTS_FIRST_NOTE_TEXT" />
      </Panel>
     </Panel>
    </div>
  )
}

export default EventsWelcome;