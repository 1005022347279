import React, { useEffect, useMemo, useRef } from "react";
import "./SuspendedAccount.scss";
import Panel from "../../components/ui/panel/Panel";
import Text from "../../components/ui/text/Text";
import { useSelector } from "react-redux";
import { AppState } from "../..";
import NotFound from "../error/NotFound";
import { BILLSBY_BILLING_COMPANY, BillsbyDataAction, BillsbyDataParams, BillsbyDataType } from "../../utils/checkout/checkoutIntegrationModel";
import Button from "../../components/ui/button/Button";
import { findDOMNode } from "react-dom";
import { appendBillsbyDataAttributeToCheckoutLib } from "../../utils/checkout/checkoutIntegrationUtils";

const SuspendedAccount: React.FC = () => {
  const authData = useSelector((state: AppState) => state.auth);

  const { parsedToken, companyName, dashboardSettings, currentCompanyCustomerUniqueId, currentCompanyDomain } = authData;
  const viewBillingHistoryRef = useRef(null)

  useEffect(() => {
    appendBillsbyDataAttributeToCheckoutLib({ label: BillsbyDataParams.company, value: BILLSBY_BILLING_COMPANY });
    window.scanDomBillsby();
  }, [dashboardSettings])

  const firstUnpaidInvoice = useMemo(() => {
    return dashboardSettings?.unpaidInvoiceNumber.split(",")[0] ?? ""
  }, [dashboardSettings])

  if(!dashboardSettings) {
    return null
  }

  if(!dashboardSettings.isSuspended) {
    return <NotFound />
  }

  const { unpaidAmountFormatted } = dashboardSettings;


  const getBillingHistoryBtn = () => {

    const viewBillingHistoryEmbedCodeProps = {
      [BillsbyDataParams.type]: BillsbyDataType.CHECKOUT,
      [BillsbyDataParams.customer]: currentCompanyCustomerUniqueId,
      [BillsbyDataParams.action]: BillsbyDataAction.VIEW_BILLING_HISTORY_UNPAID
    }

    return (
      <div style={{ display: "none" }}>
        <a
          onClick={e => e.preventDefault()}
          id={`view-billing-history-${currentCompanyCustomerUniqueId}`}
          ref={viewBillingHistoryRef}
          {...viewBillingHistoryEmbedCodeProps}
        />
      </div>
    )
  }

  return (
    <div className="suspended-account">
      { getBillingHistoryBtn() }
      <Text className="suspended-account__title" content="SUSPENDED_ACCOUNT_TITLE" translateWith={{ firstName: parsedToken ? parsedToken.given_name : "" }}></Text>
      <Text className="suspended-account__content" content="SUSPENDED_ACCOUNT_CONTENT1" translateWith={{ amount: unpaidAmountFormatted, companyName }}></Text>
      <Text className="suspended-account__content" content="SUSPENDED_ACCOUNT_CONTENT2"></Text>

      <Panel className="suspended-account__item">
        <i className="fas fa-times-circle" />
        <Text content="SUSPENDED_ACCOUNT_ITEM1" noMargin />
      </Panel>

      <Panel className="suspended-account__item">
        <i className="fas fa-check-circle" />
        <Text content="SUSPENDED_ACCOUNT_ITEM2" noMargin />
      </Panel>

      <Panel className="suspended-account__item">
        <i className="fas fa-check-circle" />
        <Text content="SUSPENDED_ACCOUNT_ITEM3" noMargin />
      </Panel>

      <Button id="suspended-btn" title="SUSPENDED_ACCOUNT_BTN" icon="fal fa-file-invoice-dollar" onClick={() => { 
        const el = (findDOMNode(viewBillingHistoryRef.current) as HTMLElement)
        el?.click?.()  
        }} isFullWidth className="suspended-account__btn" type="button" buttonType="error"></Button>

    </div>
  )
}

export default SuspendedAccount;