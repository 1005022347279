import React from "react";
import "./BillingPreLive.scss";

import Panel from "../../../components/ui/panel/Panel";
import Text from "../../../components/ui/text/Text";
import history from "../../../utils/history";
import PreLiveMenu from "../../../components/menu/pre-live-menu/PreLiveMenu";

interface IBillingPreLiveProps {
  onRequestClose: Function
}

const BillingPreLive = (props: IBillingPreLiveProps) => {
  const handleGoLiveButton = () => {
    history.push("/launch-checklist");
    props.onRequestClose();
  }

  return (
    <div>
      <Panel className="billing_prelive_panel" title="BILLING_PRE_LIVE_TITLE">
        <div className="billing_prelive_panel_content">
          <Text content="BILLING_PRE_LIVE_CONTENT_AFTER_YOU_LUNCH" />
          <PreLiveMenu onRequestClose={() => handleGoLiveButton} menuName="billing-pre-live" />
        </div>
      </Panel>
    </div>
  )
}

export default BillingPreLive;