import React, { useState, useLayoutEffect } from "react";
import { Row, Col } from "react-grid-system";
import Panel from "../../ui/panel/Panel";
import Text from "../../ui/text/Text";
import FormGroup from "../../ui/form-group/FormGroup";
import Switch from "../../ui/switch/Switch";
import Checkbox from "../../ui/checkbox/Checkbox";
import { TaxCollection } from "../../../models/Taxes";
import "./CollectTaxRegistrationNumber.scss";

interface ICollectTaxRegistrationNumber {
  onChangeCollectTax: (value: TaxCollection) => void;
  onChangeType: (value: TaxCollection) => void;
  taxRegNumberRequirementType: TaxCollection;
}

const CollectTaxRegistrationNumber: React.FC<ICollectTaxRegistrationNumber> = ({ onChangeCollectTax, onChangeType, taxRegNumberRequirementType }) => {
  const [collectTaxRegNumber, setCollectTaxRegNumber] = useState(false);

  useLayoutEffect(() => {
    setCollectTaxRegNumber(taxRegNumberRequirementType !== TaxCollection.DO_NOT_COLLECT);
  }, [taxRegNumberRequirementType]);

  return (
    <Panel className="collect-tax-reg-number" title="COLLECT_TAX_REG_NUMBER_TITLE">
      <Text content="COLLECT_TAX_REG_NUMBER_CONTENT" />
      <FormGroup noMargin={taxRegNumberRequirementType === TaxCollection.DO_NOT_COLLECT}>
        <Row align="center">
          <Col md={1}>
            <Switch id="collect-tax" checked={collectTaxRegNumber} onChange={() => {
              setCollectTaxRegNumber(!collectTaxRegNumber);

              if (taxRegNumberRequirementType === TaxCollection.DO_NOT_COLLECT) {
                onChangeCollectTax(TaxCollection.COMPULSORY);
              } else onChangeCollectTax(TaxCollection.DO_NOT_COLLECT);

            }} />
          </Col>
          <Col md={11}>
            <Text component="span" content="COLLECT_TAX_REG_NUMBER_SWITCH" />
          </Col>
        </Row>
      </FormGroup>
      {taxRegNumberRequirementType !== TaxCollection.DO_NOT_COLLECT && (
        <Row align="center">
          <Col md={12}>
            <Checkbox
              checked={taxRegNumberRequirementType === TaxCollection.COMPULSORY}
              onClick={() => onChangeType(TaxCollection.COMPULSORY)}
              value={TaxCollection.COMPULSORY}
              content={<Text content="COLLECT_TAX_REG_NUMBER_COMPULSORY" noMargin />}
            />
            <Checkbox
              checked={taxRegNumberRequirementType === TaxCollection.OPTIONAL}
              onClick={() => onChangeType(TaxCollection.OPTIONAL)}
              value={TaxCollection.OPTIONAL}
              content={<Text content="COLLECT_TAX_REG_NUMBER_OPTIONAL" noMargin />}
            />
          </Col>
        </Row>
      )}
    </Panel>
  );
};

export default CollectTaxRegistrationNumber;
