import React, { useEffect, useState } from "react";
import Button from "../../../../ui/button/Button";
import history from "../../../../../utils/history";
import Panel from "../../../../ui/panel/Panel";
import Text from "../../../../ui/text/Text";
import "./ThreeDaysBeforeRenewalEmailTemplate.scss";
import { AppState } from "../../../../..";
import { useDispatch, useSelector } from "react-redux";
import { IAuthReducerState } from "../../../../../reducers/authReducer";
import { ICompanyBrandingReducerState } from "../../../../../reducers/companyBrandingReducer";
import { Row, Col } from "react-grid-system";
import EmailTextArea from "../emailForms/emailTextArea/EmailTextArea";
import EmailSubject from "../emailForms/emailSubject/EmailSubject";
import BbcSettings from "../emailForms/bbcSettings/BbcSettings";
import EmailPreivewContainer from "../../brand-customization/email-preview/EmailPreivewContainer";
import BackNavigationPanel from "../../../../ui/back-navigation-panel/BackNavigationPanel";
import counterpart from "counterpart";
import EmailAdvertisement from "../emailForms/emailAdvertisement/EmailAdvertisement";
import { IEmailCustomizationReducerState } from "../../../../../reducers/emailCustomizationReducer";
import { resetEmailTemplateValues, getEmailTemplateValues, updateEmailTemplateValues, setEmailTemplateField, updateAdsBanner } from "../../../../../actions/emailCustomizationActions";
import { fetchCompanyBranding } from "../../../../../actions/companyBrandingActions";
import EmailFooter from "../email-footer/EmailFooter";
import { EmailTypes } from "../../../../../utils/grpc/generated/Billsby.Protos/Messenger/private/emailconfig/emailconfig_pb";


interface IEmailTypeParam {
  emailType: string
}

const ThreeDaysBeforeRenewalEmailTemplate: React.FC = () => {
  const { highlightColor } = useSelector<AppState, ICompanyBrandingReducerState>(state => state.companyBrandingReducer);
  const emailCustomizationReducer = useSelector<AppState, IEmailCustomizationReducerState>(state => state.emailCustomizationReducer)
  const { overrideAdvert, subjectLine, customArea1, customArea2, customArea3, hideAdvertisement, advertisementLink,  sendToCompanyAlertEmail, sendToPlanAlertEmail, sendToProductAlertEmail 
  } = emailCustomizationReducer
  const { currentCompanyId, companyName, currentCompanyDomain } = useSelector<AppState, IAuthReducerState>(state => state.auth);
  const dispatch = useDispatch<Function>()

  const defaultHighLightColor = "#F87847";
  type allowLoadingType = "subject" | "custom1" | "custom2" | "custom3" | "ads" | "bbc";
  const [subjectIsLoading, setSubjectIsLoading] = useState(false);
  const [custom1IsLoading, setCustom1IsLoading] = useState(false);
  const [custom2IsLoading, setCustom2IsLoading] = useState(false);
  const [custom3IsLoading, setCustom3IsLoading] = useState(false);
  const [adsIsLoading, setAdsIsLoading] = useState(false);
  const [bbcIsLoading, setBbcIsLoading] = useState(false);

  useEffect(() => {
    dispatch(fetchCompanyBranding(currentCompanyDomain));
    dispatch(getEmailTemplateValues(currentCompanyId as number, companyName, EmailTypes.REMINDER_72_HOURS_BEFORE_RENEWAL));
    return () => dispatch(resetEmailTemplateValues());
  }, []);

  const setLoading = (loadingType: allowLoadingType, value: boolean) => {
    switch (loadingType) {
      case "subject":
        setSubjectIsLoading(value);
        break;
      case "custom1":
        setCustom1IsLoading(value);
        break;
      case "custom2":
        setCustom2IsLoading(value);
        break;
      case "custom3":
        setCustom3IsLoading(value);
        break;
      case "ads":
        setAdsIsLoading(value);
        break;
      case "bbc":
        setBbcIsLoading(value);
        break;
    }
  }

  const saveChanges = async (loadingType: allowLoadingType) => {
    setLoading(loadingType, true);
    try {
      const data = {...emailCustomizationReducer} as IEmailCustomizationReducerState;
      await dispatch(updateEmailTemplateValues(currentCompanyId as number, data));
    }
    catch { }
    finally {
      setLoading(loadingType, false);
    }
  }

  const handleOverrideBaseAdvert = () => {
    dispatch(setEmailTemplateField("overrideAdvert", !overrideAdvert));
    const data = { ...emailCustomizationReducer, overrideAdvert: !overrideAdvert} as IEmailCustomizationReducerState;
    dispatch(updateEmailTemplateValues(currentCompanyId as number, data))
  }

  const updateAds = async (file: File) => {
    setLoading("ads", true);
    try {
      return await dispatch(updateAdsBanner(currentCompanyDomain, file));
    }
    catch { }
    finally {
      setLoading("ads", false);
    }
  }

  return (
    <div>
      <BackNavigationPanel
        title={"EMAIL_TEMPLATE_THREE_DAYS_BEFORE_RENEWAL_BACK_PANEL_TITLE"} shouldTranslateTitle={true}
        onClick={() => history.push("/configuration/email-notifications/emails/renewal-reminders")} />

      <Row>
        <Col sm={8}>
          <Panel className="three-days-before-renewal-email-template__panel" noMargin>
            <EmailPreivewContainer>

              <Text
                content={<span dangerouslySetInnerHTML={{__html: customArea1}}></span>}
                shouldTranslate={false}
                className="email-preview__details__text email-preview__details__text-title bar bar-blue"
                component="span"
                noMargin
              />

              <Text
                content="EMAIL_TEMPLATE_THREE_DAYS_BEFORE_RENEWAL_GREETINGS"
                className="email-preview__details__text"
                component="span"
                noMargin
              />

              <Text
                content={<span dangerouslySetInnerHTML={{__html: customArea2}}></span>}
                shouldTranslate={false}
                className="email-preview__details__text bar bar-orange"
                component="span"
                noMargin
              />

              <Text
                content="EMAIL_TEMPLATE_THREE_DAYS_BEFORE_RENEWAL_BODY_1"
                className="email-preview__details__text email-preview__details__text-bold"
                component="span"
                noMargin
              />

              <Text
                content="EMAIL_TEMPLATE_THREE_DAYS_BEFORE_RENEWAL_BODY_2"
                className="email-preview__details__text"
                component="span"
                noMargin
              />

              <Text
                content="EMAIL_TEMPLATE_THREE_DAYS_BEFORE_RENEWAL_BODY_3"
                className="email-preview__details__text"
                component="span"
                noMargin
              />

              <div className="email-preview__details__button align-left">
                <Button
                  id="select-plan-continue"
                  type="button"
                  className="email-preview__details__button-orange three-days-before-renewal-email-template__button arrow-icon"
                  title="EMAIL_TEMPLATE_THREE_DAYS_BEFORE_RENEWAL_BUTTON_TEXT"
                  style={{ backgroundColor: !!highlightColor ? highlightColor : defaultHighLightColor }}
                />
              </div>

              <Text
                content={<span dangerouslySetInnerHTML={{__html: customArea3}}></span>}
                shouldTranslate={false}
                className="email-preview__details__text bar bar-green"
                component="span"
                noMargin
              />

              <Text
                content="EMAIL_TEMPLATE_THREE_DAYS_BEFORE_RENEWAL_REAGRDS"
                className="email-preview__details__text"
                component="span"
                noMargin
              />

              <Text
                content={companyName}
                className="email-preview__details__text"
                component="span"
                shouldTranslate={false}
                noMargin
              />
              <EmailFooter />
            </EmailPreivewContainer>
          </Panel>
        </Col>
        <Col sm={4}>

          <EmailSubject title={counterpart("EMAIL_TEMPLATE_SUBJECT_LINE")} id={"custom-copy"} value={subjectLine}
            buttonText={counterpart("EMAIL_TEMPLATE_SUBJECT_LINE_BUTTON_TITLE")}
            onSubmit={() => saveChanges("subject")}
            onChange={(evt: any) => { dispatch(setEmailTemplateField("subjectLine", evt.target.value)) }} isLoading={subjectIsLoading} />

          <EmailTextArea title={counterpart("EMAIL_TEMPLATE_CUSTOM_COPY_AREA_ONE")}
            description={counterpart("EMAIL_TEMPLATE_CUSTOM_COPY_AREA_ONE_DESCRIPTION")}
            id={"custom-copy"} value={customArea1}
            buttonText={counterpart("EMAIL_TEMPLATE_CUSTOM_COPY_AREA_ONE_BUTTON_TITLE")}
            onSubmit={() => saveChanges("custom1")}
            onChange={(evt: any) => { dispatch(setEmailTemplateField("customArea1", evt.target.value)) }}
            isLoading={custom1IsLoading} />

          <EmailTextArea title={counterpart("EMAIL_TEMPLATE_CUSTOM_COPY_AREA_TWO")}
            description={counterpart("EMAIL_TEMPLATE_CUSTOM_COPY_AREA_TWO_DESCRIPTION")}
            className="three-days-before-renewal-email-template__panel-header-orange"
            id={"custom-copy-two"} value={customArea2}
            buttonText={counterpart("EMAIL_TEMPLATE_CUSTOM_COPY_AREA_TWO_BUTTON_TITLE")}
            onSubmit={() => saveChanges("custom2")}
            onChange={(evt: any) => { dispatch(setEmailTemplateField("customArea2", evt.target.value)) }}
            isLoading={custom2IsLoading} />

          <EmailTextArea title={counterpart("EMAIL_TEMPLATE_CUSTOM_COPY_AREA_THREE")}
            description={counterpart("EMAIL_TEMPLATE_CUSTOM_COPY_AREA_THREE_DESCRIPTION")}
            className="three-days-before-renewal-email-template__panel-header-green"
            id={"custom-copy-three"} value={customArea3}
            buttonText={counterpart("EMAIL_TEMPLATE_CUSTOM_COPY_AREA_THREE_BUTTON_TITLE")}
            onSubmit={() => saveChanges("custom3")}
            onChange={(evt: any) => { dispatch(setEmailTemplateField("customArea3", evt.target.value)) }}
            isLoading={custom3IsLoading} />

          <EmailAdvertisement title={counterpart("EMAIL_TEMPLATE_OVERRIDE_ADVERTISING_SPACE")} id={"advertisement"} linkUrl={advertisementLink}
            logoUrl='' onDrop={(files) => updateAds(files[0])}
            hideAdvertisement={hideAdvertisement} onHideAdvertisementChange={() => { dispatch(setEmailTemplateField("hideAdvertisement", !hideAdvertisement)) }}
            buttonText={counterpart("EMAIL_TEMPLATE_OVERRIDE_ADVERTISING_SPACE_BUTTON_TEXT")}
            onSubmit={() => saveChanges("ads")}
            onLinkUrlChange={(evt: any) => { dispatch(setEmailTemplateField("advertisementLink", evt.target.value)) }}
            isLoading={adsIsLoading} canOverrideBaseAdvert={true} overrideBaseAdvert={overrideAdvert} onOverrideBaseAdvertChange={() => handleOverrideBaseAdvert()}/>

          <BbcSettings title={counterpart("EMAIL_TEMPLATE_CONFIGURE_BCC_SETTINGS")}
            noMargin={true} companyAlert={sendToCompanyAlertEmail} productAlert={sendToProductAlertEmail} planAlert={sendToPlanAlertEmail}
            id={"custom-copy-two"}
            buttonText={counterpart("EMAIL_TEMPLATE_CONFIGURE_BCC_SETTINGS_UPDATE_BUTTON")}
            onSubmit={() => saveChanges("bbc")}
            onCompanyAlertChange={() => { dispatch(setEmailTemplateField("sendToCompanyAlertEmail", !sendToCompanyAlertEmail)) }}
            onProductAlertChange={() => { dispatch(setEmailTemplateField("sendToProductAlertEmail", !sendToProductAlertEmail)) }}
            onPlanAlertChange={() => { dispatch(setEmailTemplateField("sendToPlanAlertEmail", !sendToPlanAlertEmail)) }}
            isLoading={bbcIsLoading} />

        </Col>
      </Row>
    </div>
  )
}

export default ThreeDaysBeforeRenewalEmailTemplate