import React from "react";
import "./ProductPlanCycleWelcome.scss";
import OtherCompanies from "./OtherCompanies";
import Panel from "../../../components/ui/panel/Panel";
import Text from "../../../components/ui/text/Text";
import Button from "../../../components/ui/button/Button";
import PageWrapper from "../../ui/layout/PageWrapper";
import NavigationAlertCurrencyMismatch from "../../ui/navigation-alert/NavigationAlertCurrencyMismatch";
import Tooltip from "../../ui/tooltip/Tooltip";
import history from "../../../utils/history";
import Helmet from "react-helmet";
import counterpart from "counterpart";
import NavigationAlert from "../../ui/navigation-alert/NavigationAlert";
import { openIntercomIframe } from "../../../utils/commonUtils";
import { useSelector } from "react-redux";
import { AppState } from "../../..";
import { IAuthReducerState } from "../../../reducers/authReducer";

interface IProductPlanCycleWelcomeProps {
  otherCompaniesContent: Array<any>
}

export const ProductPlanCycleWelcome: React.FC<IProductPlanCycleWelcomeProps> = ({ otherCompaniesContent }) => {
  const { parsedToken } = useSelector<AppState, IAuthReducerState>(state => state.auth)

  return (
    <div>
      <Helmet title={counterpart("NAVBAR_PRODUCTS_PLANS_CYCLES")} />
      <NavigationAlertCurrencyMismatch />
      <NavigationAlert
        title={counterpart("PRODUCT_PLAN_GREETING_TITLE", { name: parsedToken && parsedToken.given_name })}
        shouldTranslateTitle={false}
        subTitle="PRODUCT_PLAN_GREETING_TEXT"
        buttonLabel="PRODUCT_PLAN_TALK_BUTTON"
        onClick={openIntercomIframe}
        isVisible={true}
      />
      <PageWrapper className="ppc-welcome">
        <Panel className="ppc-welcome__explain" title="PRODUCT_PLAN_EXPLAIN_TITLE">
          <div className="ppc-welcome__content">
            <Text content="PRODUCT_PLAN_WELCOME_CONENT1" component={"span"} />
            <span className="ppc-welcome__tooltip-text" data-tip data-for="product-tooltip">
              <Text content="PRODUCT_PLAN_WELCOME_CONENT2" component={"span"} />
            </span>
            <Text content="PRODUCT_PLAN_WELCOME_CONENT3" component={"span"} />
            <span className="ppc-welcome__tooltip-text" data-tip data-for="plan-tooltip">
              <Text content="PRODUCT_PLAN_WELCOME_CONENT4" component={"span"} />
            </span>
            !
            <p>
              <Text content="PRODUCT_PLAN_WELCOME_CONENT5" component={"span"} />
              <span className="ppc-welcome__tooltip-text" data-tip data-for="cycles-tooltip">
                <Text content="PRODUCT_PLAN_WELCOME_CONENT6" component={"span"} />
              </span>

              <Text content="PRODUCT_PLAN_WELCOME_CONENT7" component={"span"} />
            </p>
            <Text content="PRODUCT_PLAN_WELCOME_CONENT8" component={"span"} />
          </div>

          <Button
            id="product-plan-cycle-welcome-create"
            title="PRODUCT_PLAN_WELCOME_BUTTON"
            buttonType="general"
            icon="fal fa-plus-circle"
            onClick={() => history.push("/products/products-plans-cycles/create-product")}
          />

          <Tooltip id="product-tooltip" place="bottom" type="blue">
            <Text content="PRODUCT_TOOLTIP" noMargin />
          </Tooltip>
          <Tooltip id="plan-tooltip" place="bottom" type="blue">
            <Text content="PLAN_TOOLTIP" noMargin />
          </Tooltip>
          <Tooltip id="cycles-tooltip" place="bottom" type="blue">
            <Text content="CYCLES_TOOTLTIP" noMargin />
          </Tooltip>
        </Panel>

        <Panel className="ppc-welcome__other-companies" title="PRODUCT_PLAN_OTHER_COMPANIES">
          <Text content="PRODUCT_PLAN_OTHER_COMPANIES_TEXT" />
          {<OtherCompanies companies={otherCompaniesContent} />}
        </Panel>
      </PageWrapper>
    </div>
  );
};

export default ProductPlanCycleWelcome