import React, { useState } from "react"
import StepCollapsablePanel from "../../../ui/step-collapsable-panel/StepCollapsablePanel";
import logo from "../../../../images/integrations/webex/webex-logo.png";
import Text from "../../../ui/text/Text";
import Button from "../../../ui/button/Button";
import { AccountingIntegrationType } from "../../../../models/AccountingIntegrations";
import { deleteWebexIntegration } from "../../../../actions/accountingIntegrationsActions";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../../../..";
import NoticePanel from "../../../ui/notice-panel/NoticePanel";

interface IUninstallWebex {
  onUninstall: () => void
}

export const UninstallWebex: React.FC<IUninstallWebex> = ({ onUninstall }) => {
  const authData = useSelector((state: AppState) => state.auth);
  const integrations = useSelector((state: AppState) => state.accountingIntegrationsReducer.integrations);
  const dispatch = useDispatch<Function>();
  const [isLoading, setIsLoading] = useState(false);
  const [hasError, setHasError] = useState(false);

  const uninstallWebex = async () => {
    const isWebexInstalled = integrations.getIntegrationsList().some(i => i.getName() === AccountingIntegrationType.WEBEX && i.getIsInstalled());

    if(isWebexInstalled) {
      setIsLoading(true);
      setHasError(false);

      try {
        dispatch(deleteWebexIntegration(Number(authData.currentCompanyId)));
        onUninstall();
      } 
      catch (err) {
        setHasError(true);
      } 
      finally {
        setIsLoading(false);
      }
    }
  }

  return (
    <div>
      {hasError && <NoticePanel content="GENERIC_ERROR" type="error" isModal={true}></NoticePanel>}
      <StepCollapsablePanel title={
        <div className="webex-integrations__title">
          <div>
            <img src={logo} alt="no img" />
            <div>
              <Text content="INTEGRATION_UNINSTALL_WEBEX" noMargin />
            </div>
          </div>
        </div>
      } className="webex-integrations__webex"
        shouldTranslateTitle={false}
        hasHelpSection={false}
        isCollapsed={false}
      >
        <Text content="INTEGRATION_UNINSTALL_WEBEX_TEXT" noMargin />
      </StepCollapsablePanel>

      <Button
        id="webex-uninstall-btn"
        type="button"
        buttonType={"general"}
        disabled={isLoading}
        onClick={uninstallWebex}
        title="INTEGRATION_UNINSTALL_WEBEX_BUTTON"
        isFullWidth
      />
    </div>
  )
}

export default UninstallWebex;
