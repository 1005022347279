import React, { ReactElement, useMemo, useState } from "react";
import StepCollapsablePanel from "../../../../ui/step-collapsable-panel/StepCollapsablePanel";
import Text from "../../../../ui/text/Text";
import { useSelector } from "react-redux";
import { AppState } from "../../../../..";
import { IAuthReducerState } from "../../../../../reducers/authReducer";
import ErrorNotification from "../../../../ui/error-notification/ErrorNotification";
import PaymentGatewaysTitle from "./PaymentGatewaysTitle";
import { IPaymentGatewayElement } from "../../../../../models/Configuration";
import Tooltip from "../../../../ui/tooltip/Tooltip";

interface Props {
  children: ReactElement | Array<ReactElement>;
  helpTitle: string;
  helpContent: string[];
  description: string;
  isOneGatewayPending: boolean;
  currentGatewayState: any;
  websiteLink: string;
  onClick?: () => void;
  type: "create" | "edit";
  paymentGateway: IPaymentGatewayElement | undefined
}

const PaymentGatewayContainer: React.FC<Props> = ({ children, paymentGateway, helpTitle, helpContent,
  description, isOneGatewayPending, currentGatewayState, websiteLink, onClick=() => { return; }, type }) => {

  const { currentCompanyDomain } = useSelector<AppState, IAuthReducerState>(state => state.auth)

  const [showError, setShowError] = useState(false);
  
  const isCollapsed = useMemo(() => {
    if (type === "create") return false;
    
    if (!!paymentGateway && !paymentGateway.mustActivate) {
      return isOneGatewayPending ? true : currentGatewayState.isCollapsed;
    }

    return false;
  }, [paymentGateway, isOneGatewayPending, currentGatewayState]);

  if (!paymentGateway) {
    return null;
  }

  return (
    <>
      <StepCollapsablePanel
      className="payment-gateways__step-panel"
        isCollapsed={isCollapsed}
        isShowTitleIcon={false}
        hasError={false}
        onClick={onClick}
        title={
          <PaymentGatewaysTitle
            currentCompanyDomain={currentCompanyDomain}
            isOneGatewayPending={isOneGatewayPending}
            paymentGateway={paymentGateway}
            currentGatewayState={currentGatewayState}
            onError={() => setShowError(true)}
            type={type}
            onWebisteButtonClick={() => window.open(websiteLink, "_self")}
          />
        }
        shouldTranslateTitle={false}
        helpMenuPosition="right"
        helpMenuTitle={helpTitle}
        helpMenuContent={
          <div>
            {helpContent.map((helpLine, index) => (
              <Text key={index} content={helpLine} />
            ))}
          </div>
        }
      >
        <>
          <Tooltip place="top" id="warning-disconnect-tooltip" type="blue">
            <Text content="PAYMENT_GATEWAYS_DISCONNECT_WARNING" noMargin />
          </Tooltip>
          <Text content={description} />
          <div className="payment-gateways__update">          
            {children}
          </div>
        </>
      </StepCollapsablePanel>
      <ErrorNotification title="GENERIC_ERROR" showAlert={showError} onClose={() => setShowError(false)} />
    </>
  )
}

export default PaymentGatewayContainer