import React, { useMemo } from "react";
import "./EmailNotifications.scss";
import { NavLink, useRouteMatch } from "react-router-dom";
import Panel from "../../../ui/panel/Panel";
import Text from "../../../ui/text/Text";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../../../..";
import history from "../../../../utils/history";
import NavigationBar from "../../../ui/navigation-bar/NavigationBar";
import PageWrapper from "../../../ui/layout/PageWrapper";
import TabbedContainer from "../../../ui/tabbed-container/TabbedContainer";
import counterpart from "counterpart";
import { PrivateRoute } from "../../../../containers/AuthRoutes";
import EmailRouting from "./email-routing/EmailRouting";
import PaymentDetailsRequestEmailTemplate from "../email-customization/payment-details-request-email-template/PaymentDetailsRequestEmailTemplate";
import OneTimePasscodeTemplate from "../email-customization/oneTimePasscodeTemplate/OneTimePasscodeTemplate";
import EmailNotificationsMenu from "./email-notiifcations-menu/EmailNotificationsMenu";
import EmailBaseTemplate from "../email-customization/emailBaseTemplate/EmailBaseTemplate";
import OneTimePasscodeMenu from "./email-notiifcations-menu/one-time-passcode-menu/OneTimePasscodeMenu";
import PaymentDetailsRequestMenu from "./email-notiifcations-menu/payment-details-request-menu/PaymentDetailsRequestMenu";
import PausesMenu from "./email-notiifcations-menu/pauses-menu/PausesMenu";
import PauseSubscriptionEmailTemplate from "../email-customization/pause-subscription-email-template/PauseSubscriptionEmailTemplate";
import PaymentCreditsRefundMenu from "../email-notifications/email-notiifcations-menu/payment-credits-refund-menu/PaymentCreditsRefundMenu";
import SubscriptionRenewalCapturedSuccessfully from "../email-customization/subscription-renewal-captured-successfully-email-template/SubscriptionRenewalCapturedSuccessfully";
import PositiveOneTimeChargeSuccessfullyCapturedEmailTemplate from "../email-customization/positive-one-time-charge-successfully-captured-email-template/PositiveOneTimeChargeSuccessfullyCapturedEmailTemplate";
import NegativeOneTimeChargeSuccessfullyCapturedEmailTemplate from "../email-customization/negative-one-time-charge-successfully-captured-email-template/NegativeOneTimeChargeSuccessfullyCapturedEmailTemplate";
import ExpiringPaymentMethodsMenu from "../email-notifications/email-notiifcations-menu/expiring-payment-methods-menu/ExpiringPaymentMethodsMenu";
import CancellationsMenu from "../email-notifications/email-notiifcations-menu/cancellations-menu/CancellationsMenu";
import SubscriptionCancelledEmailTemplate from "../email-customization/subscription-cancelled-email-template/SubscriptionCancelledEmailTemplate";
import SevenDaysAfterPaymentMethodExpiresEmailTemplate from "../email-customization/seven-days-after-payment-method-expires-email-template/SevenDaysAfterPaymentMethodExpiresEmailTemplate";
import TheDayPaymentMethodExpiresEmailTemplate from "../email-customization/the-day-payment-method-expires-email-template/TheDayPaymentMethodExpiresEmailTemplate";
import SevenDaysBeforePaymentMethodExpiresEmailTemplate from "../email-customization/seven-days-before-payment-method-expires-email-template/SevenDaysBeforePaymentMethodExpiresEmailTemplate";
import AdjustmentMadeToNextBillEmailTemplate from "../email-customization/adjustment-made-to-net-bill-email-template/AdjustmentMadeToNextBillEmailTemplate";
import WelcomeEmailMenu from "../email-notifications/email-notiifcations-menu/welcome-emails-menu/WelcomeEmailsMenu";
import NewSubscriptionWithTrialEmailTemplate from "../email-customization/new-subscription-with-trial-email-template/NewSubscriptionWithTrialEmailTemplate";
import NewSubscriptionWithoutTrialEmailTemplate from "../email-customization/new-subscription-without-trial-email-template/NewSubscriptionWithoutTrialEmailTemplate";
import TrialManagementMenu from "../email-notifications/email-notiifcations-menu/trial-management-menu/TrialManagementMenu";
import TrialWillExpireInThreeDaysEmailTemplate from "../email-customization/trial-will-expire-in-three-days-email-template/TrialWillExpireInThreeDaysEmailTemplate";
import RenewalRemindersMenu from "../email-notifications/email-notiifcations-menu/renewal-reminders-menu/RenewalRemindersMenu";
import ThreeDaysBeforeRenewalEmailTemplate from "../email-customization/three-days-before-renewal-email-template/ThreeDaysBeforeRenewalEmailTemplate";
import NewCustomerWithoutSubscriptionEmailTemplate from "../email-customization/new-customer-without-subscription-email-template/NewCustomerWithoutSubscriptionEmailTemplate";
import RefundIssuedEmailTemplate from "../email-customization/refund-issued-email-template/RefundIssuedEmailTemplate";
import ResendInvoiceLinkEmailTemplate from "../email-customization/resend-invoice-link-email-template/ResendInvoiceLinkEmailTemplate";
import ResendCreditNoteLinkEmailTemplate from "../email-customization/resend-credit-note-link-email-template/ResendCreditNoteLinkEmailTemplate";
import NewAddOnEmailTemplate from "../email-customization/new-add-on-email-template/NewAddOnEmailTemplate";
import NewAllowanceEmailTemplate from "../email-customization/new-allowance-email-template/NewAllowanceEmailTemplate";
import RemoveAddOnEmailTemplate from "../email-customization/remove-add-on-email-template/RemoveAddOnEmailTemplate";
import ContractTermMenu from "../email-notifications/email-notiifcations-menu/contract-term-menu/ContractTermMenu";
import ContractTermBeforeEndEmailTemplate from "../email-customization/contract-term-before-end-email-template/ContractTermBeforeEndEmailTemplate";
import RemoveAllowanceEmailTemplate from "../email-customization/remove-allowance-email-template/RemoveAllowanceEmailTemplate";
import DiscountCouponsMenu from "../email-notifications/email-notiifcations-menu/discount-coupons-menu/DiscountCouponsMenu";
import CouponAppliedEmailTemplate from "../email-customization/coupon-applied-email-template/CouponAppliedEmailTemplate";
import BeforeDiscountExpiresEmailTemplate from "../email-customization/before-discount-expires-email-template/BeforeDiscountExpiresEmailTemplate";
import ErrorNotification from "../../../ui/error-notification/ErrorNotification";
import { IEmailCustomizationReducerState } from "../../../../reducers/emailCustomizationReducer";
import { setEmailTemplateField } from "../../../../actions/emailCustomizationActions";
import { IEmailBaseTemplateState } from "../../../../reducers/emailBaseTemplateReducer";
import { setBaseEmailTemplateField } from "../../../../actions/emailBaseTemplateActions";
import PositiveOneTimeChargePaymentFailed from "../email-customization/positive-one-time-charge-payment-failed/PositiveOneTimeChargePaymentFailed";
import NewBillDateMenu from "./email-notiifcations-menu/new-bill-date-menu/NewBillDateMenu";
import NextBillDateIsChangedTemplate from "../email-customization/next-bill-date-is-changed-template/NextBillDateIsChangedTemplate";


const EmailNotifications: React.FC = () => {
  const { errorMessage } = useSelector<AppState, IEmailCustomizationReducerState>(state => state.emailCustomizationReducer);
  const { errorMessage: baseErrorMessage } = useSelector<AppState, IEmailBaseTemplateState>(state => state.emailBaseTemplateReducer);
  const dispatch = useDispatch<Function>()
  const match = useRouteMatch()

  const emailTemplateErrorMessage = useMemo(() => {
    if (!!errorMessage) return errorMessage;
    if (!!baseErrorMessage) return baseErrorMessage;
    return "";
  }, [errorMessage, baseErrorMessage]);

  const updateErrorMessage = () => {
    if(!!errorMessage) {
      dispatch(setEmailTemplateField("errorMessage", ""));
    }

    if(!!baseErrorMessage) {
      dispatch(setBaseEmailTemplateField("errorMessage", ""))
    }
  }

  const pathIsIncluded = (path: string) => {
    return history.location.pathname.toLocaleLowerCase().includes(path);
  }

  return (
    <div className="email-notifications">
      <NavigationBar pageTitle="" previousPageTitle="CONFIGURATION_PAGE_TITLE" previousPageCb={() => history.push("/configuration")} />

      <PageWrapper>
        <Panel title="EMAIL_NOTIFICATIONS_MENU_ITEM_TITLE" className="email-notifications__heading" noMargin>
          <Text content="EMAIL_NOTIFICATIONS_PANEL1_CONTENT" noMargin />
        </Panel>

        <TabbedContainer type="submenu">
        
          <NavLink isActive={(match, location) => (!match || !location ? false : match?.isExact) || pathIsIncluded("email-notifications/emails")} activeClassName="active" to={`${match?.url}/emails`}>
            {counterpart("EMAIL_NOTIFICATIONS_EMAILS_TAB")}
          </NavLink>

          <NavLink exact activeClassName="active" to={`${match?.url}/template`}>
            {counterpart("EMAIL_NOTIFICATIONS_TEMPLATE_TAB")}
          </NavLink>
          
          <NavLink isActive={(match, location) => (!match || !location ? false : match?.isExact)} activeClassName="active" to={`${match?.url}`}>
            {counterpart("EMAIL_NOTIFICATIONS_ROUTING_TAB")}
          </NavLink>
        </TabbedContainer>
        
        <ErrorNotification
          title={emailTemplateErrorMessage}
          showAlert={!!emailTemplateErrorMessage}
          shouldTranslateTitle={false}
          onClose={() => updateErrorMessage()}
        />

        <PrivateRoute exact path={match?.path} component={EmailRouting} />
        <PrivateRoute exact path={`${match?.path}/template`} component={EmailBaseTemplate} />
        <PrivateRoute exact path={`${match?.path}/emails`} component={EmailNotificationsMenu} />
        <PrivateRoute exact path={`${match?.path}/emails/one-time-passcode`} component={OneTimePasscodeMenu} />
        <PrivateRoute exact path={`${match?.path}/emails/one-time-passcode/template`} component={OneTimePasscodeTemplate} />
        <PrivateRoute exact path={`${match?.path}/emails/payment-details-request`} component={PaymentDetailsRequestMenu} />
        <PrivateRoute exact path={`${match?.path}/emails/payment-details-request/template`} component={PaymentDetailsRequestEmailTemplate} />
        <PrivateRoute exact path={`${match?.path}/emails/welcome-emails`} component={WelcomeEmailMenu} />
        <PrivateRoute exact path={`${match?.path}/emails/welcome-emails/new-subscription-with-trial-template`} component={NewSubscriptionWithTrialEmailTemplate} />
        <PrivateRoute exact path={`${match?.path}/emails/welcome-emails/new-subscription-without-trial-template`} component={NewSubscriptionWithoutTrialEmailTemplate} />
        <PrivateRoute exact path={`${match?.path}/emails/welcome-emails/new-customer-without-subscription-template`} component={NewCustomerWithoutSubscriptionEmailTemplate} />
        <PrivateRoute exact path={`${match?.path}/emails/welcome-emails/new-add-on-template`} component={NewAddOnEmailTemplate} />
        <PrivateRoute exact path={`${match?.path}/emails/welcome-emails/new-allowance-template`} component={NewAllowanceEmailTemplate} />
        <PrivateRoute exact path={`${match?.path}/emails/trial-management`} component={TrialManagementMenu} />
        <PrivateRoute exact path={`${match?.path}/emails/trial-management/trial-will-expire-in-three-days-template`} component={TrialWillExpireInThreeDaysEmailTemplate} />
        <PrivateRoute exact path={`${match?.path}/emails/renewal-reminders`} component={RenewalRemindersMenu} />
        <PrivateRoute exact path={`${match?.path}/emails/renewal-reminders/subscription-will-auto-renew-in-three-days-template`} component={ThreeDaysBeforeRenewalEmailTemplate} />
        <PrivateRoute exact path={`${match?.path}/emails/pauses`} component={PausesMenu} />
        <PrivateRoute exact path={`${match?.path}/emails/pauses/pause-email-template`} component={PauseSubscriptionEmailTemplate} />
        <PrivateRoute exact path={`${match?.path}/emails/cancellations`} component={CancellationsMenu} />
        <PrivateRoute exact path={`${match?.path}/emails/cancellations/subscription-cancelled-template`} component={SubscriptionCancelledEmailTemplate} />
        <PrivateRoute exact path={`${match?.path}/emails/cancellations/add-on-remove-template`} component={RemoveAddOnEmailTemplate} />
        <PrivateRoute exact path={`${match?.path}/emails/cancellations/allowance-remove-template`} component={RemoveAllowanceEmailTemplate} />
        <PrivateRoute exact path={`${match?.path}/emails/payment-credits-refund`} component={PaymentCreditsRefundMenu} />
        <PrivateRoute exact path={`${match?.path}/emails/payment-credits-refund/renewal-payment-email-template`} component={SubscriptionRenewalCapturedSuccessfully} />
        <PrivateRoute exact path={`${match?.path}/emails/payment-credits-refund/positive-one-time-charge-successfully-captured-email-template`} component={PositiveOneTimeChargeSuccessfullyCapturedEmailTemplate} />
        <PrivateRoute exact path={`${match?.path}/emails/payment-credits-refund/negative-one-time-charge-successfully-captured-email-template`} component={NegativeOneTimeChargeSuccessfullyCapturedEmailTemplate} />
        <PrivateRoute exact path={`${match?.path}/emails/payment-credits-refund/positive-one-time-charge-payment-failed`} component={PositiveOneTimeChargePaymentFailed} />
        <PrivateRoute exact path={`${match?.path}/emails/payment-credits-refund/refund-issued-email-template`} component={RefundIssuedEmailTemplate} />
        <PrivateRoute exact path={`${match?.path}/emails/payment-credits-refund/adjustment-made-to-next-bill-email-template`} component={AdjustmentMadeToNextBillEmailTemplate} />
        <PrivateRoute exact path={`${match?.path}/emails/payment-credits-refund/resend-invoice-link-email-template`} component={ResendInvoiceLinkEmailTemplate} />
        <PrivateRoute exact path={`${match?.path}/emails/payment-credits-refund/resend-credit-note-link-email-template`} component={ResendCreditNoteLinkEmailTemplate} />
        <PrivateRoute exact path={`${match?.path}/emails/expiring-payment-methods`} component={ExpiringPaymentMethodsMenu} />
        <PrivateRoute exact path={`${match?.path}/emails/expiring-payment-methods/seven-days-before-payment-method-expires-email-template`} component={SevenDaysBeforePaymentMethodExpiresEmailTemplate} />
        <PrivateRoute exact path={`${match?.path}/emails/expiring-payment-methods/the-day-payment-method-expires-email-template`} component={TheDayPaymentMethodExpiresEmailTemplate} />
        <PrivateRoute exact path={`${match?.path}/emails/expiring-payment-methods/seven-days-after-payment-method-expires-email-template`} component={SevenDaysAfterPaymentMethodExpiresEmailTemplate} />
        <PrivateRoute exact path={`${match?.path}/emails/new-bill-date`} component={NewBillDateMenu} />
        <PrivateRoute exact path={`${match?.path}/emails/new-bill-date/next-bill-date-is-changed-template`} component={NextBillDateIsChangedTemplate} />
        <PrivateRoute exact path={`${match?.path}/emails/contract-term`} component={ContractTermMenu} />
        <PrivateRoute exact path={`${match?.path}/emails/contract-term/before-end-email-template`} component={ContractTermBeforeEndEmailTemplate} />
        <PrivateRoute exact path={`${match?.path}/emails/discount-coupons`} component={DiscountCouponsMenu} />
        <PrivateRoute exact path={`${match?.path}/emails/discount-coupons/coupon-applied-email-template`} component={CouponAppliedEmailTemplate} />
        <PrivateRoute exact path={`${match?.path}/emails/discount-coupons/before-discount-expires-email-template`} component={BeforeDiscountExpiresEmailTemplate} />
      </PageWrapper>
    </div>
  );
};

export default EmailNotifications
