import React, { useState, ReactElement, useEffect, useRef, FormEvent } from "react";
import CircleIcon from "../circle-icon/CirlcleIcon";
import Text from "../text/Text";
import "./StepCollapsablePanel.scss";
import CollapsablePanel from "../collapsable-panel/CollapsablePanel";
import { findDOMNode } from "react-dom";
import ElementModifiers from "../element-modifiers/ElementModifiers";

interface IStepCollapsablePanel {
  number?: number;
  title: string | JSX.Element;
  shouldTranslateTitle?: boolean;
  subTitle?: JSX.Element | string;
  shouldTranslateSubTitle?: boolean;
  isCollapsed?: boolean;
  isCompleted?: boolean;
  isShowTitleIcon?: boolean;
  hasHelpSection?: boolean;
  helpMenuTitle?: string;
  helpMenuContent?: JSX.Element;
  helpMenuPosition?: "top" | "right";
  helpMenuType?: "general" | "warning",
  helpMenuIcon?: string,
  shouldTranslateHelpMenuTitle?: boolean;
  hasError?: boolean;
  isLocked?: boolean;
  isShowLockIcon?: boolean;
  onEditClick?: (evt: any) => void;
  onDeleteClick?: (evt: any) => void;
  onClick?: (evt: any) => void;
  onFocus?: (evt: any) => void;
  onBlur?: (evt: any) => void;
  children?: ReactElement | Array<ReactElement>;
  className?: string;
  topSection?: ReactElement | Array<ReactElement> | null;
  unMountChild?: boolean;
}

const adjustHelpSectionHeight = (panelRef: any) => {
  const panelDom: HTMLElement = findDOMNode(panelRef.current) as HTMLElement;

  if (panelDom) {
    const helpMenu: HTMLElement = panelDom.querySelector(".step-cpanel__helpmenu--right") as HTMLElement;
    if (helpMenu) {
      helpMenu.style.height = panelDom.getBoundingClientRect().height.toFixed(2) + "px";
      helpMenu.style.overflowY = "auto";
      helpMenu.style.visibility = "visible";
    }
  }
};

/**
 * Step collapsable panel, used for numbered section in the create product screens
 */
const StepCollapsablePanel: React.FC<IStepCollapsablePanel> = React.memo(
  ({
    number,
    title,
    unMountChild = true,
    topSection,
    isCollapsed = true,
    isCompleted = false,
    isShowTitleIcon = true,
    helpMenuTitle = "",
    helpMenuContent,
    helpMenuPosition = "right",
    helpMenuType = "general",
    helpMenuIcon = "far fa-question-circle",
    shouldTranslateTitle = true,
    shouldTranslateHelpMenuTitle = true,
    hasError = false,
    isLocked = false,
    onClick = () => { },
    onFocus = () => { },
    onBlur = () => { },
    hasHelpSection = true,
    subTitle = "",
    shouldTranslateSubTitle = true,
    className = "",
    isShowLockIcon,
    onEditClick,
    onDeleteClick,
    children,
  }) => {
    const [helpMenuRightCollapsed, setHelpMenuRightCollapsed] = useState(false);
    const [bottomScrolled, setBottomScrolled] = useState(false);
    const panelRef = useRef(null);
    const helpMenuContentRef = useRef(null);

    useEffect(() => {
      const helpContentDom = findDOMNode(helpMenuContentRef.current) as HTMLElement | null;
      //Detect current content if scrollable
      if (helpContentDom && (helpContentDom.scrollTop + 25 >= (helpContentDom.scrollHeight - helpContentDom.offsetHeight))) {
        setBottomScrolled(true)
      } else {
        setBottomScrolled(false)
      }
    }, [helpMenuContentRef.current, helpMenuContent])

    // if (hasHelpSection && helpMenuPosition === 'right' && !isCollapsed) {
    //   useEffect(() => afterMount(panelRef));
    //   setTimeout(() => adjustHelpSectionHeight(panelRef), 200);
    // }

    if (isLocked) {
      //Reset all event if component is locked
      onEditClick = onEditClick ? () => { } : onEditClick;
      onDeleteClick = onDeleteClick ? () => { } : onDeleteClick;
      onClick = !!onClick ? () => { } : onClick;
    }

    const onScrollContainer = (evt: any) => {
      if (evt.target.scrollTop + 25 >= (evt.target.scrollHeight - evt.target.offsetHeight)) {
        setBottomScrolled(true);
      } else {
        setBottomScrolled(false);
      }
    }

    const renderContent = () => {
      if (!unMountChild) {
        if (hasHelpSection) {
          return (
            <div
              className={`step-cpanel__body ${helpMenuPosition === "top" ? "step-cpanel__body--fullwidth" : ""} ${helpMenuRightCollapsed ? "step-cpanel__body--expanded" : ""} ${isCollapsed ? "hidden" : ""}`}
              // avoid the event to propagate to the parent <CollapsableProductPanel/> otherwise the panel would close!
              onClick={(evt: any) => evt.stopPropagation()}
            >
              {children}
            </div>
          );
        }

        return (
          <div
            className={`step-cpanel__body step-cpanel__body--fullwidth ${isCollapsed ? "hidden" : ""}`}
            // avoid the event to propagate to the parent <CollapsableProductPanel/> otherwise the panel would close!
            onClick={(evt: any) => evt.stopPropagation()}
          >
            {children}
          </div>
        )
      }

      if (unMountChild && !isCollapsed) {
        if (hasHelpSection) {
          return (
            <div
              className={`step-cpanel__body ${helpMenuPosition === "top" ? "step-cpanel__body--fullwidth" : ""} ${helpMenuRightCollapsed ? "step-cpanel__body--expanded" : ""}`}
              // avoid the event to propagate to the parent <CollapsableProductPanel/> otherwise the panel would close!
              onClick={(evt: any) => evt.stopPropagation()}
            >
              {children}
            </div>
          );
        }

        return (
          <div
            className={"step-cpanel__body step-cpanel__body--fullwidth"}
            // avoid the event to propagate to the parent <CollapsableProductPanel/> otherwise the panel would close!
            onClick={(evt: any) => evt.stopPropagation()}
          >
            {children}
          </div>
        );
      }
    };

    return (
      <div ref={panelRef} className="step-cpanel--fullwidth">
        <CollapsablePanel
          className={`step-cpanel ${className} ${isLocked ? "step-cpanel__locked" : ""}`}
          isCollapsed={isLocked ? true : isCollapsed}
          onClick={onClick}
          onFocus={onFocus}
          onBlur={onBlur}
        >
          <div>
            <div className="step-cpanel__title">
              {isShowTitleIcon ? getIcon(isCompleted, number, hasError) : null}
              <div className="step-cpanel__title__text">
                <Text
                  className={helpMenuRightCollapsed ? "step-cpanel__title__text--expanded" : "step-cpanel__title__text--fullwidth"}
                  content={title}
                  component="div"
                  shouldTranslate={shouldTranslateTitle}
                  noMargin
                />
                {subTitle && <Text className="step-cpanel__subtitle" content={subTitle} shouldTranslate={shouldTranslateSubTitle} component="div" noMargin />}
              </div>
              {<ElementModifiers isShowLockIcon={isShowLockIcon} onEditClick={onEditClick} onDeleteClick={onDeleteClick} />}
            </div>
            {topSection && topSection}
            {hasHelpSection ? (
              <div>
                {helpMenuPosition === "right" && !isCollapsed && !isLocked && (
                  <i
                    className={`step-cpanel__helpmenu__collapsed-icon ${helpMenuRightCollapsed ? "fas fa-expand-alt" : "fas fa-compress-alt"}`}
                    onClick={(evt: any) => {
                      setHelpMenuRightCollapsed(!helpMenuRightCollapsed);
                      // avoid the event to propagate to the parent <CollapsableProductPanel/> otherwise the panel would close!
                      evt.stopPropagation();
                    }}
                  />
                )}
                <div
                  onScroll={onScrollContainer}
                  onClick={(evt: FormEvent) => {
                    evt.stopPropagation();
                  }}
                  ref={helpMenuContentRef}
                  className={`step-cpanel__helpmenu step-cpanel__helpmenu--${helpMenuType} ${helpMenuPosition === "right" ? "step-cpanel__helpmenu--right" : "step-cpanel__helpmenu--top"} 
                    ${helpMenuRightCollapsed ? "step-cpanel__helpmenu--right--hidden" : ""} ${isCollapsed || isLocked ? "step-cpanel__helpmenu--collapsed" : ""}`}
                >
                  {
                    helpMenuPosition === "right" ? (
                      <div className="step-cpanel__helpmenu__contents">
                        <div className="step-cpanel__helpmenu__title">
                          <div>
                            <i className={helpMenuIcon} />
                            <Text content={helpMenuTitle} shouldTranslate={shouldTranslateHelpMenuTitle} noMargin />
                          </div>
                        </div>

                        {helpMenuContent}
                      </div>
                    ) : (
                        <>
                          <div className="step-cpanel__helpmenu__title">
                            <div>
                              <i className={helpMenuIcon} />
                              <Text content={helpMenuTitle} shouldTranslate={shouldTranslateHelpMenuTitle} noMargin />
                            </div>
                          </div>
                          {helpMenuContent}
                        </>
                      )
                  }
                </div>
                { helpMenuPosition === "right" && (
                  <>
                    <div
                      className={`step-cpanel__gradient step-cpanel__gradient--right ${helpMenuRightCollapsed ? "step-cpanel__gradient--right--collapsed" : ""}`}
                    />
                    {!isLocked && !isCollapsed && (
                      <div
                        style={{ opacity: bottomScrolled ? 0 : 1 }}
                        className={`step-cpanel__gradient step-cpanel__gradient--bottom ${helpMenuRightCollapsed ? "step-cpanel__gradient--bottom--collapsed" : ""}`}
                      />
                    )}
                  </>
                )}
              </div>
            ) : null}

            {renderContent()}
          </div>
        </CollapsablePanel>
      </div>
    );
  }
);

const getIcon = (isCompleted: boolean, number: number | undefined, hasError: boolean) => {
  if (hasError) {
    return <CircleIcon className="step-cpanel__error" icon="fas fa-exclamation" />;
  }
  if (isCompleted) {
    return <CircleIcon className="step-cpanel__completed" icon="far fa-check" />;
  }
  if (number) {
    return <CircleIcon nr={number} />;
  }
};

export default StepCollapsablePanel;
