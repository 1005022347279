import React from "react"
import "./CheckoutPreviewContainer.scss";
import CheckoutPreview from "../checkout-preview/CheckoutPreview";
import CheckoutConfirmationPreview from "../checkout-preview/CheckoutConfirmationPreview";

interface ICheckoutPreviewContainer {
  screen?: "AddOns" | "Confirmation";
}
const CheckoutPreviewContainer:React.FC<ICheckoutPreviewContainer> = ({screen = "AddOns"}) => {

  const getScreen = () => {
    switch (screen) {
      case "AddOns":
        return <CheckoutPreview />;
      case "Confirmation":
        return <CheckoutConfirmationPreview />;
      default:
        return <CheckoutPreview />;
    }

  }
  return (
    <div className="checkout-preview-container">
      <div className="checkout-preview-container__content">
        {getScreen()}
      </div>
    </div>
  )
}

export default CheckoutPreviewContainer
