import React, { useState } from "react";
import "./AllowancesWelcome.scss";
import history from "../../../utils/history";
import Panel from "../../ui/panel/Panel";
import Text from "../../ui/text/Text";
import Button from "../../ui/button/Button";
import OtherCompanies from "../../products/productPlanCycle/OtherCompanies";
import { Link } from "react-router-dom";
import counterpart from "counterpart";
import CollapsablePanelWithArrow from "../../ui/collapsable-panel-with-arrow/CollapsablePanelWithArrow";
import { AddonsAllowancesScreen } from "../../../models/AddonsAllowances";

interface IAllowancesWelcome {
  withAddOns?: boolean;
}
export const AllowancesWelcome: React.FC<IAllowancesWelcome> = ({ withAddOns }) => {
  const [isCollapsed, setIsCollapsed] = useState(true);

  const otherCompaniesContent = [
    {
      name: "ALLOWANCES_WELCOME_AUDIBLE",
      type: "ALLOWANCES_WELCOME_AUDIBLE_TYPE",
      otherDetails: [
        { title: "ALLOWANCES_WELCOME_AUDIBLE_DESC1", descriptions: ["ALLOWANCES_WELCOME_AUDIBLE_DESC2"] }
      ]
    },
    {
      name: "ALLOWANCES_WELCOME_ATT",
      type: "ALLOWANCES_WELCOME_ATT_TYPE",
      otherDetails: [
        { title: "ALLOWANCES_WELCOME_ATT_DESC1", descriptions: ["ALLOWANCES_WELCOME_ATT_DESC2"] },
        { title: "ALLOWANCES_WELCOME_ATT_DESC3", descriptions: ["ALLOWANCES_WELCOME_ATT_DESC4"] }
      ]
    },
    {
      name: "ALLOWANCES_WELCOME_DIGITALOCEAN",
      type: "ALLOWANCES_WELCOME_DIGITALOCEAN_TYPE",
      otherDetails: [
        { title: "ALLOWANCES_WELCOME_DIGITALOCEAN_DESC1", descriptions: ["ALLOWANCES_WELCOME_DIGITALOCEAN_DESC2"] }
      ]
    },
    {
      name: "ALLOWANCES_WELCOME_INTERCOM",
      type: "ALLOWANCES_WELCOME_INTERCOM_TYPE",
      otherDetails: [
        { title: "ALLOWANCES_WELCOME_INTERCOM_DESC1", descriptions: ["ALLOWANCES_WELCOME_INTERCOM_DESC2"] }
      ]
    },
    {
      name: "ALLOWANCES_WELCOME_AMAZON",
      type: "ALLOWANCES_WELCOME_AMAZON_TYPE",
      otherDetails: [
        { title: "ALLOWANCES_WELCOME_AMAZON_DESC1", descriptions: ["ALLOWANCES_WELCOME_AMAZON_DESC2"] }
      ]
    }
  ];

  return (
    <div className="allowances-welcome">
      <Panel className="allowances-welcome__greeting" title="ALLOWANCES_WELCOME_TITLE">
        <div className="allowances-welcome__container-text">
          <Text content="ALLOWANCES_WELCOME_TEXT_1" />
          <Text content="ALLOWANCES_WELCOME_TEXT_2" />
          <Text content="ALLOWANCES_WELCOME_TEXT_3" translateWith={{
            addons: (
              <Link to={`/addons-allowances/${AddonsAllowancesScreen.ADDONS}`}>{counterpart("ALLOWANCES_WELCOME_TEXT_4")}</Link>
            )
          }} />

          {!withAddOns && <Button
            className="addons-welcome__greeting-btn"
            id="create-allowance"
            title="ALLOWANCES_WELCOME_CREATE_BTN"
            buttonType="general"
            icon="fal fa-plus-circle"
            onClick={() => history.push(`/addons-allowances/${AddonsAllowancesScreen.ALLOWANCES}/create`)}
          />}

        </div>
      </Panel>

      {withAddOns ? (
        <CollapsablePanelWithArrow isCollapsed={isCollapsed} onClick={() => setIsCollapsed(!isCollapsed)} className="ppc-list__other-companies" title="ALLOWANCES_WELCOME_TITLE2">
          <Text content="ALLOWANCES_WELCOME_OTHER_COMPANIES_TEXT_1" />
          <OtherCompanies companies={otherCompaniesContent} />
        </CollapsablePanelWithArrow>
      ) : (
          <Panel title="ALLOWANCES_WELCOME_OTHER_COMPANIES">
            <Text content="ALLOWANCES_WELCOME_OTHER_COMPANIES_TEXT_1" />
            <OtherCompanies companies={otherCompaniesContent} />
          </Panel>
        )
      }
    </div>
  );
};

export default AllowancesWelcome;
