import React, { MouseEventHandler } from "react";
import "./FormGroup.scss";

interface IFormGroup {
  id?: string,
  /**
  * The form elements wrapped by this component
  */
  children?: any,
  /**
  * Add custom classes
  */
  className?: string,
  /**
   * add custom inline style
   */
  style?: object,
  onClick?: MouseEventHandler
  noMargin?: boolean | null
}

const FormGroup: React.FC<IFormGroup> = React.memo(({ id, children = null, className = "", style = {}, noMargin = false, onClick }) => {
  return (
    <div id={id} className={`cc-form-group ${className} ${noMargin ? "mb0" : ""}`} style={style} onClick={onClick}>
      {children}
    </div>
  );
})

export default FormGroup;
