import React, { useLayoutEffect, useState } from "react";
import InputTags from "../../ui/input-tags/InputTags";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../../..";
import { setSubscriptionFeatureTags, removeSubscriptionFeatureTags, fetchSubscriptionFeatureTags, deleteSubscriptionFeatureTags, addSubscriptionFeatureTags, resetSubscriptionFeatureTags } from "../../../actions/subscriptionFeatureTagsActions";
import Panel from "../../ui/panel/Panel";
import FormGroup from "../../ui/form-group/FormGroup";
import { Row, Col } from "react-grid-system";
import FormLabel from "../../ui/form-label/FormLabel";
import Text from "../../ui/text/Text";
import Button from "../../ui/button/Button";
import "./SubscriptionFeatureTags.scss";
import FeatureTags from "../../ui/feature-tags/FeatureTags";
import { ITag } from "../../../models/SubscriptionFeaturedTags";
import counterpart from "counterpart";
import { matchPath } from "react-router";
import ProgressIndicator from "../../ui/progress-indicator/ProgressIndicator";
import { Permission } from "../../../models/Auth";
import useCheckFeaturePermission from "../../../utils/hooks/useCheckFeaturePermission";


const SubscriptionFeatureTags: React.FC = () => {
  const subscriptionFeatureTagsData = useSelector((state: AppState) => state.subscriptionFeatureTagsReducer);
  const authData = useSelector((state: AppState) => state.auth);
  const subscriptionDetailsData = useSelector((state: AppState) => state.subscriptionDetailsReducer);
  const isSubscriptionAddFeatureTagPermitted = useCheckFeaturePermission(Permission.SubscriptionAddFeatureTag);
  const isSubscriptionRemoveFeatureTagPermitted = useCheckFeaturePermission(Permission.SubscriptionRemoveFeatureTag);
  const dispatch = useDispatch<Function>();
  
  const [isLoadingData, setIsLoadingData] = useState(false);
  const [isAddingFeatureTags, setIsAddingFeatureTags] = useState(false);

  const getParams = (pathname: string) => {
    const matchProfile = matchPath(pathname, {
      path: "/subscriptions/:id",
    });
    return (matchProfile && matchProfile.params) || {};
  };

  const { subscriptionFeatureTags, planFeatureTags, featureTags } = subscriptionFeatureTagsData;
  const { currentCompanyDomain } = authData;
  const { pathname } = window.location;
  const currentParams = getParams(pathname);
  //const { id } = currentParams as ISubscriptionId;
  const { subscriptionUniqueId } = subscriptionDetailsData;



  useLayoutEffect(() => {
    const fetchData = async () => {
      setIsLoadingData(true);
      try {
        await dispatch(fetchSubscriptionFeatureTags(currentCompanyDomain, subscriptionUniqueId));
        setIsLoadingData(false);
      } catch (err) {
        setIsLoadingData(false);
      }
    }

    fetchData();

    return () => {
      dispatch(resetSubscriptionFeatureTags());
    };
  }, []);


  const handleAddTags = (tags: Array<ITag>) => {
    setIsAddingFeatureTags(true);
    dispatch(addSubscriptionFeatureTags(currentCompanyDomain, subscriptionUniqueId, tags.map(tag => tag.name)))
      .then(() => {
        dispatch(fetchSubscriptionFeatureTags(currentCompanyDomain, subscriptionUniqueId));
        setIsAddingFeatureTags(false);
      });
  }

  const handleRemoveTags = (tag: string) => {
    dispatch(deleteSubscriptionFeatureTags(currentCompanyDomain, subscriptionUniqueId, tag))
      .then(() => {
        dispatch(fetchSubscriptionFeatureTags(currentCompanyDomain, subscriptionUniqueId));
      });
  }

  if (isLoadingData) {
    return <ProgressIndicator color="blue"></ProgressIndicator>
  }

  return (
    <div className="subscription-feature-tags">
      <Panel title="SUBSCRIPTION_FEATURE_TAGS_INCLUSIVE_FEATURE_TAGS" className="subscription-feature-tags__plan-feature-tags">
        <Text content="SUBSCRIPTION_FEATURE_TAGS_INCLUSIVE_FEATURE_TAGS_DESCRIPTION" noMargin={planFeatureTags.length === 0} />
        {planFeatureTags.length > 0 && <FeatureTags tags={planFeatureTags} />}
      </Panel>

      <Panel title="SUBSCRIPTION_FEATURE_TAGS_CUSTOM_FEATURE_TAGS">
        <Text content="SUBSCRIPTION_FEATURE_TAGS_CUSTOM_FEATURE_TAGS_DESCRIPTION" />
        <FormGroup>
          <Row align="center">
            <Col md={1}>
              <FormLabel className="subscription-feature-tags__label" target="featureTag" content="SUBSCRIPTION_FEATURE_LABEL" noMargin />
            </Col>
            <Col md={9}>
              <InputTags
                placeholder={counterpart("SUBSCRIPTION_FEATURE_INPUT_PLACE_HOLDER")}
                tags={featureTags}
                handleDelete={(tagIndex: number) => { dispatch(removeSubscriptionFeatureTags(tagIndex)); }}
                handleAddition={(tag: any) => { dispatch(setSubscriptionFeatureTags(tag)); }}
              />
            </Col>
            <Col md={2}>
              <Button
                id="subscription-feature-tags-add"
                icon="fal fa-plus-circle"
                title="SUBSCRIPTION_FEATURE_TAGS_ADD_BUTTON"
                onClick={() => handleAddTags(featureTags)}
                isLoading={isAddingFeatureTags}
                disabled={featureTags.length === 0 || isAddingFeatureTags || !isSubscriptionAddFeatureTagPermitted}
                tooltipMessage={!isSubscriptionAddFeatureTagPermitted ? "NO_ACCESS_MSG" : undefined}
                buttonType="add"
                isFullWidth
              />
            </Col>
            <Col offset={{ md: 1 }} md={9}>
              <Text style={{ marginTop: "10px" }} content="EDIT_SUBSCRIPTION_FEATURE_TAGS_NOTICE" noMargin></Text>
            </Col>
          </Row>
        </FormGroup>
        {subscriptionFeatureTags.length > 0 && <FeatureTags tags={subscriptionFeatureTags} hasDelete={isSubscriptionRemoveFeatureTagPermitted} handleDelete={(tag) => handleRemoveTags(tag)} />}
      </Panel>
    </div>
  )
}

export default SubscriptionFeatureTags;
