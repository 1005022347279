import { useLayoutEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { v4 as uuidv4 } from "uuid";
import ReactGA from "react-ga4";
import { storeGet } from "../storeUtils";
import { BILLSBY_GUID } from "../constants";
import { AppState } from "../..";
import { ConfigConstants } from "../config";
import browserHistory from "../history";

const useAnalytics = () => {
    const { currentCompanyGoogleAnalyticsUID, parsedToken } = useSelector((state: AppState) => state.auth)
    const dispatch = useDispatch<Function>();
  
    useLayoutEffect(() => {
      const initializeAnalytics = async () => {
        const actions = await import("../../actions/authActions");
        let userId = parsedToken ? currentCompanyGoogleAnalyticsUID : storeGet(BILLSBY_GUID);
  
        if (!userId && !parsedToken) {
          userId = uuidv4();
        }
  
        if (!currentCompanyGoogleAnalyticsUID) {
          dispatch(actions.setBillsbyCompanyGoogleAnalyticsUID(userId));
        }
  
        if (currentCompanyGoogleAnalyticsUID) {
          ReactGA.initialize(ConfigConstants.billsbyGoogleAnalyticsId, { gaOptions: { userId: currentCompanyGoogleAnalyticsUID, titleCase: false } });
          ReactGA.set({ cookie_prefix: "billsby_cookie" });
          browserHistory.listen(() => {
            ReactGA.send({ hitType: "pageview", title: document.title });
          })
        }
      }
  
      initializeAnalytics().catch(err => console.log(err));
    }, [parsedToken, currentCompanyGoogleAnalyticsUID]);
    return null
  }

  export default useAnalytics;