import React, { useState, useMemo } from "react";
import "./NavigationBarCheckout.scss";
import { NavigationBarSteps } from "../../../models/Nav";
import counterpart from "counterpart";
import Text from "../text/Text";

interface INavigationBar {
  className?: string,
  isVisible?: boolean,
}

const NavigationBar: React.FC<INavigationBar> = React.memo(({ className = "", isVisible = true }) => {
  const [showText, setShowText] = useState(false);
  let navigationStep = NavigationBarSteps.SELECT_PLAN;

  const navigationBarMap = useMemo(() => {
    return {
      [NavigationBarSteps.SELECT_PLAN]: counterpart("NAVIGATION_BAR_SELECT_SUBSCRIPTION"),
      [NavigationBarSteps.PERSONAL_DETAILS]: counterpart("NAVIGATION_BAR_PERSONAL_DETAILS"),
      [NavigationBarSteps.ADDRESS_DETAILS]: counterpart("NAVIGATION_BAR_ADDRESS_DETAILS"),
      [NavigationBarSteps.PAYMENT_METHOD]: counterpart("NAVIGATION_BAR_PAYMENT_METHOD")
    }
  }, []);

  const subscriptionProgress = useMemo(() => {
    switch (navigationStep) {
      case NavigationBarSteps.SELECT_PLAN:
        return 25;
      case NavigationBarSteps.PERSONAL_DETAILS:
        return 50;
      case NavigationBarSteps.ADDRESS_DETAILS:
        return 75;
      case NavigationBarSteps.PAYMENT_METHOD:
        return 100;
      default:
        return 0;
    }
  }, [navigationStep]);

  if (!isVisible) {
    return null;
  }

  return (
    <div className={`navigation-bar__container ${className}`} onMouseEnter={() => setShowText(true)} onMouseLeave={() => setShowText(false)}>
      {
        showText
          ? (
            <div
              className={"navigation-bar__text"}
              style={{
                left: `${(subscriptionProgress - 25)}%`
              }}>
              <Text content={navigationBarMap[navigationStep]} shouldTranslate={false} />
            </div>
          )
          : (
            <div
              className={"navigation-bar__filler"}
              style={{
                left: `${(subscriptionProgress - 25)}%`
              }}
            />
          )
      }
    </div>
  )
})

export default NavigationBar;
