/**
 * @fileoverview gRPC-Web generated client stub for billing.private.paymentgateway
 * @enhanceable
 * @public
 */

// GENERATED CODE -- DO NOT EDIT!


import * as grpcWeb from "grpc-web";


import {
  CompleteEnableSCARequest,
  CompleteEnableSCAResponse,
  CreateAdyenGatewayRequest,
  CreateAdyenGatewayResponse,
  CreateAuthorizeDotNetGatewayRequest,
  CreateAuthorizeDotNetGatewayResponse,
  CreateBraintreeGatewayRequest,
  CreateBraintreeGatewayResponse,
  CreateCheckoutDotComGatewayRequest,
  CreateCheckoutDotComGatewayResponse,
  CreateCloverConnectRequest,
  CreateCloverConnectResponse,
  CreateIxopayGatewayRequest,
  CreateIxopayGatewayResponse,
  CreateNMIGatewayRequest,
  CreateNMIGatewayResponse,
  CreatePaymentCloudRequest,
  CreatePaymentCloudResponse,
  CreateStripeIntentGatewayRequest,
  CreateStripeIntentGatewayResponse,
  DeletePaymentGatewayRequest,
  DeletePaymentGatewayResponse,
  GetGatewayScaDetailsRequest,
  GetGatewayScaDetailsResponse,
  GetSpreedlyTransactionRequest,
  GetSpreedlyTransactionResponse,
  GetTransactionLogRequest,
  GetTransactionLogResponse,
  ReplaceGatewayToAdyenRequest,
  ReplaceGatewayToAuthorizeDotNetRequest,
  ReplaceGatewayToBraintreeRequest,
  ReplaceGatewayToCheckoutDotComRequest,
  ReplaceGatewayToCloverConnectRequest,
  ReplaceGatewayToIxopayRequest,
  ReplaceGatewayToNMIRequest,
  ReplaceGatewayToPaymentCloudRequest,
  ReplaceGatewayToStripeIntentGatewayRequest,
  StartEnableSCARequest,
  StartEnableSCAResponse,
  UpdateAdyenGatewayRequest,
  UpdateAdyenGatewayResponse,
  UpdateAuthorizeDotNetGatewayRequest,
  UpdateAuthorizeDotNetGatewayResponse,
  UpdateBraintreeGatewayRequest,
  UpdateBraintreeGatewayResponse,
  UpdateCheckoutDotComGatewayRequest,
  UpdateCheckoutDotComGatewayResponse,
  UpdateCloverConnectRequest,
  UpdateCloverConnectResponse,
  UpdateIxopayGatewayRequest,
  UpdateIxopayGatewayResponse,
  UpdateNMIGatewayRequest,
  UpdateNMIGatewayResponse,
  UpdatePaymentCloudRequest,
  UpdatePaymentCloudResponse,
  UpdateStripeGatewayRequest,
  UpdateStripeGatewayResponse} from "./payment_gateway_pb";

export class PaymentGatewayServiceClient {
  client_: grpcWeb.AbstractClientBase;
  hostname_: string;
  credentials_: null | { [index: string]: string; };
  options_: null | { [index: string]: string; };

  constructor (hostname: string,
               credentials?: null | { [index: string]: string; },
               options?: null | { [index: string]: string; }) {
    if (!options) options = {};
    if (!credentials) credentials = {};
    options["format"] = "text";

    this.client_ = new grpcWeb.GrpcWebClientBase(options);
    this.hostname_ = hostname;
    this.credentials_ = credentials;
    this.options_ = options;
  }

  methodInfoCreateCheckoutDotComGateway = new grpcWeb.AbstractClientBase.MethodInfo(
    CreateCheckoutDotComGatewayResponse,
    (request: CreateCheckoutDotComGatewayRequest) => {
      return request.serializeBinary();
    },
    CreateCheckoutDotComGatewayResponse.deserializeBinary
  );

  createCheckoutDotComGateway(
    request: CreateCheckoutDotComGatewayRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: CreateCheckoutDotComGatewayResponse) => void) {
    return this.client_.rpcCall(
      this.hostname_ +
        "/billing.private.paymentgateway.PaymentGatewayService/CreateCheckoutDotComGateway",
      request,
      metadata || {},
      this.methodInfoCreateCheckoutDotComGateway,
      callback);
  }

  methodInfoReplaceGatewayToCheckoutDotCom = new grpcWeb.AbstractClientBase.MethodInfo(
    CreateCheckoutDotComGatewayResponse,
    (request: ReplaceGatewayToCheckoutDotComRequest) => {
      return request.serializeBinary();
    },
    CreateCheckoutDotComGatewayResponse.deserializeBinary
  );

  replaceGatewayToCheckoutDotCom(
    request: ReplaceGatewayToCheckoutDotComRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: CreateCheckoutDotComGatewayResponse) => void) {
    return this.client_.rpcCall(
      this.hostname_ +
        "/billing.private.paymentgateway.PaymentGatewayService/ReplaceGatewayToCheckoutDotCom",
      request,
      metadata || {},
      this.methodInfoReplaceGatewayToCheckoutDotCom,
      callback);
  }

  methodInfoUpdateCheckoutDotComGateway = new grpcWeb.AbstractClientBase.MethodInfo(
    UpdateCheckoutDotComGatewayResponse,
    (request: UpdateCheckoutDotComGatewayRequest) => {
      return request.serializeBinary();
    },
    UpdateCheckoutDotComGatewayResponse.deserializeBinary
  );

  updateCheckoutDotComGateway(
    request: UpdateCheckoutDotComGatewayRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: UpdateCheckoutDotComGatewayResponse) => void) {
    return this.client_.rpcCall(
      this.hostname_ +
        "/billing.private.paymentgateway.PaymentGatewayService/UpdateCheckoutDotComGateway",
      request,
      metadata || {},
      this.methodInfoUpdateCheckoutDotComGateway,
      callback);
  }

  methodInfoCreateBraintreeGateway = new grpcWeb.AbstractClientBase.MethodInfo(
    CreateBraintreeGatewayResponse,
    (request: CreateBraintreeGatewayRequest) => {
      return request.serializeBinary();
    },
    CreateBraintreeGatewayResponse.deserializeBinary
  );

  createBraintreeGateway(
    request: CreateBraintreeGatewayRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: CreateBraintreeGatewayResponse) => void) {
    return this.client_.rpcCall(
      this.hostname_ +
        "/billing.private.paymentgateway.PaymentGatewayService/CreateBraintreeGateway",
      request,
      metadata || {},
      this.methodInfoCreateBraintreeGateway,
      callback);
  }

  methodInfoReplaceGatewayToBraintree = new grpcWeb.AbstractClientBase.MethodInfo(
    CreateBraintreeGatewayResponse,
    (request: ReplaceGatewayToBraintreeRequest) => {
      return request.serializeBinary();
    },
    CreateBraintreeGatewayResponse.deserializeBinary
  );

  replaceGatewayToBraintree(
    request: ReplaceGatewayToBraintreeRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: CreateBraintreeGatewayResponse) => void) {
    return this.client_.rpcCall(
      this.hostname_ +
        "/billing.private.paymentgateway.PaymentGatewayService/ReplaceGatewayToBraintree",
      request,
      metadata || {},
      this.methodInfoReplaceGatewayToBraintree,
      callback);
  }

  methodInfoUpdateBraintreeGateway = new grpcWeb.AbstractClientBase.MethodInfo(
    UpdateBraintreeGatewayResponse,
    (request: UpdateBraintreeGatewayRequest) => {
      return request.serializeBinary();
    },
    UpdateBraintreeGatewayResponse.deserializeBinary
  );

  updateBraintreeGateway(
    request: UpdateBraintreeGatewayRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: UpdateBraintreeGatewayResponse) => void) {
    return this.client_.rpcCall(
      this.hostname_ +
        "/billing.private.paymentgateway.PaymentGatewayService/UpdateBraintreeGateway",
      request,
      metadata || {},
      this.methodInfoUpdateBraintreeGateway,
      callback);
  }

  methodInfoCreateAdyenGateway = new grpcWeb.AbstractClientBase.MethodInfo(
    CreateAdyenGatewayResponse,
    (request: CreateAdyenGatewayRequest) => {
      return request.serializeBinary();
    },
    CreateAdyenGatewayResponse.deserializeBinary
  );

  createAdyenGateway(
    request: CreateAdyenGatewayRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: CreateAdyenGatewayResponse) => void) {
    return this.client_.rpcCall(
      this.hostname_ +
        "/billing.private.paymentgateway.PaymentGatewayService/CreateAdyenGateway",
      request,
      metadata || {},
      this.methodInfoCreateAdyenGateway,
      callback);
  }

  methodInfoReplaceGatewayToAdyen = new grpcWeb.AbstractClientBase.MethodInfo(
    CreateAdyenGatewayResponse,
    (request: ReplaceGatewayToAdyenRequest) => {
      return request.serializeBinary();
    },
    CreateAdyenGatewayResponse.deserializeBinary
  );

  replaceGatewayToAdyen(
    request: ReplaceGatewayToAdyenRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: CreateAdyenGatewayResponse) => void) {
    return this.client_.rpcCall(
      this.hostname_ +
        "/billing.private.paymentgateway.PaymentGatewayService/ReplaceGatewayToAdyen",
      request,
      metadata || {},
      this.methodInfoReplaceGatewayToAdyen,
      callback);
  }

  methodInfoUpdateAdyenGateway = new grpcWeb.AbstractClientBase.MethodInfo(
    UpdateAdyenGatewayResponse,
    (request: UpdateAdyenGatewayRequest) => {
      return request.serializeBinary();
    },
    UpdateAdyenGatewayResponse.deserializeBinary
  );

  updateAdyenGateway(
    request: UpdateAdyenGatewayRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: UpdateAdyenGatewayResponse) => void) {
    return this.client_.rpcCall(
      this.hostname_ +
        "/billing.private.paymentgateway.PaymentGatewayService/UpdateAdyenGateway",
      request,
      metadata || {},
      this.methodInfoUpdateAdyenGateway,
      callback);
  }

  methodInfoCreateAuthorizeDotNetGateway = new grpcWeb.AbstractClientBase.MethodInfo(
    CreateAuthorizeDotNetGatewayResponse,
    (request: CreateAuthorizeDotNetGatewayRequest) => {
      return request.serializeBinary();
    },
    CreateAuthorizeDotNetGatewayResponse.deserializeBinary
  );

  createAuthorizeDotNetGateway(
    request: CreateAuthorizeDotNetGatewayRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: CreateAuthorizeDotNetGatewayResponse) => void) {
    return this.client_.rpcCall(
      this.hostname_ +
        "/billing.private.paymentgateway.PaymentGatewayService/CreateAuthorizeDotNetGateway",
      request,
      metadata || {},
      this.methodInfoCreateAuthorizeDotNetGateway,
      callback);
  }

  methodInfoReplaceGatewayToAuthorizeDotNet = new grpcWeb.AbstractClientBase.MethodInfo(
    CreateAuthorizeDotNetGatewayResponse,
    (request: ReplaceGatewayToAuthorizeDotNetRequest) => {
      return request.serializeBinary();
    },
    CreateAuthorizeDotNetGatewayResponse.deserializeBinary
  );

  replaceGatewayToAuthorizeDotNet(
    request: ReplaceGatewayToAuthorizeDotNetRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: CreateAuthorizeDotNetGatewayResponse) => void) {
    return this.client_.rpcCall(
      this.hostname_ +
        "/billing.private.paymentgateway.PaymentGatewayService/ReplaceGatewayToAuthorizeDotNet",
      request,
      metadata || {},
      this.methodInfoReplaceGatewayToAuthorizeDotNet,
      callback);
  }

  methodInfoUpdateAuthorizeDotNetGateway = new grpcWeb.AbstractClientBase.MethodInfo(
    UpdateAuthorizeDotNetGatewayResponse,
    (request: UpdateAuthorizeDotNetGatewayRequest) => {
      return request.serializeBinary();
    },
    UpdateAuthorizeDotNetGatewayResponse.deserializeBinary
  );

  updateAuthorizeDotNetGateway(
    request: UpdateAuthorizeDotNetGatewayRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: UpdateAuthorizeDotNetGatewayResponse) => void) {
    return this.client_.rpcCall(
      this.hostname_ +
        "/billing.private.paymentgateway.PaymentGatewayService/UpdateAuthorizeDotNetGateway",
      request,
      metadata || {},
      this.methodInfoUpdateAuthorizeDotNetGateway,
      callback);
  }

  methodInfoCreateIxopayGateway = new grpcWeb.AbstractClientBase.MethodInfo(
    CreateIxopayGatewayResponse,
    (request: CreateIxopayGatewayRequest) => {
      return request.serializeBinary();
    },
    CreateIxopayGatewayResponse.deserializeBinary
  );

  createIxopayGateway(
    request: CreateIxopayGatewayRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: CreateIxopayGatewayResponse) => void) {
    return this.client_.rpcCall(
      this.hostname_ +
        "/billing.private.paymentgateway.PaymentGatewayService/CreateIxopayGateway",
      request,
      metadata || {},
      this.methodInfoCreateIxopayGateway,
      callback);
  }

  methodInfoReplaceGatewayToIxopay = new grpcWeb.AbstractClientBase.MethodInfo(
    CreateIxopayGatewayResponse,
    (request: ReplaceGatewayToIxopayRequest) => {
      return request.serializeBinary();
    },
    CreateIxopayGatewayResponse.deserializeBinary
  );

  replaceGatewayToIxopay(
    request: ReplaceGatewayToIxopayRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: CreateIxopayGatewayResponse) => void) {
    return this.client_.rpcCall(
      this.hostname_ +
        "/billing.private.paymentgateway.PaymentGatewayService/ReplaceGatewayToIxopay",
      request,
      metadata || {},
      this.methodInfoReplaceGatewayToIxopay,
      callback);
  }

  methodInfoUpdateIxopayGateway = new grpcWeb.AbstractClientBase.MethodInfo(
    UpdateIxopayGatewayResponse,
    (request: UpdateIxopayGatewayRequest) => {
      return request.serializeBinary();
    },
    UpdateIxopayGatewayResponse.deserializeBinary
  );

  updateIxopayGateway(
    request: UpdateIxopayGatewayRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: UpdateIxopayGatewayResponse) => void) {
    return this.client_.rpcCall(
      this.hostname_ +
        "/billing.private.paymentgateway.PaymentGatewayService/UpdateIxopayGateway",
      request,
      metadata || {},
      this.methodInfoUpdateIxopayGateway,
      callback);
  }

  methodInfoCreateNMIGateway = new grpcWeb.AbstractClientBase.MethodInfo(
    CreateNMIGatewayResponse,
    (request: CreateNMIGatewayRequest) => {
      return request.serializeBinary();
    },
    CreateNMIGatewayResponse.deserializeBinary
  );

  createNMIGateway(
    request: CreateNMIGatewayRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: CreateNMIGatewayResponse) => void) {
    return this.client_.rpcCall(
      this.hostname_ +
        "/billing.private.paymentgateway.PaymentGatewayService/CreateNMIGateway",
      request,
      metadata || {},
      this.methodInfoCreateNMIGateway,
      callback);
  }

  methodInfoReplaceGatewayToNMI = new grpcWeb.AbstractClientBase.MethodInfo(
    CreateNMIGatewayResponse,
    (request: ReplaceGatewayToNMIRequest) => {
      return request.serializeBinary();
    },
    CreateNMIGatewayResponse.deserializeBinary
  );

  replaceGatewayToNMI(
    request: ReplaceGatewayToNMIRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: CreateNMIGatewayResponse) => void) {
    return this.client_.rpcCall(
      this.hostname_ +
        "/billing.private.paymentgateway.PaymentGatewayService/ReplaceGatewayToNMI",
      request,
      metadata || {},
      this.methodInfoReplaceGatewayToNMI,
      callback);
  }

  methodInfoUpdateNMIGateway = new grpcWeb.AbstractClientBase.MethodInfo(
    UpdateNMIGatewayResponse,
    (request: UpdateNMIGatewayRequest) => {
      return request.serializeBinary();
    },
    UpdateNMIGatewayResponse.deserializeBinary
  );

  updateNMIGateway(
    request: UpdateNMIGatewayRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: UpdateNMIGatewayResponse) => void) {
    return this.client_.rpcCall(
      this.hostname_ +
        "/billing.private.paymentgateway.PaymentGatewayService/UpdateNMIGateway",
      request,
      metadata || {},
      this.methodInfoUpdateNMIGateway,
      callback);
  }

  methodInfoDeletePaymentGateway = new grpcWeb.AbstractClientBase.MethodInfo(
    DeletePaymentGatewayResponse,
    (request: DeletePaymentGatewayRequest) => {
      return request.serializeBinary();
    },
    DeletePaymentGatewayResponse.deserializeBinary
  );

  deletePaymentGateway(
    request: DeletePaymentGatewayRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: DeletePaymentGatewayResponse) => void) {
    return this.client_.rpcCall(
      this.hostname_ +
        "/billing.private.paymentgateway.PaymentGatewayService/DeletePaymentGateway",
      request,
      metadata || {},
      this.methodInfoDeletePaymentGateway,
      callback);
  }

  methodInfoCreateCloverConnectGateway = new grpcWeb.AbstractClientBase.MethodInfo(
    CreateCloverConnectResponse,
    (request: CreateCloverConnectRequest) => {
      return request.serializeBinary();
    },
    CreateCloverConnectResponse.deserializeBinary
  );

  createCloverConnectGateway(
    request: CreateCloverConnectRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: CreateCloverConnectResponse) => void) {
    return this.client_.rpcCall(
      this.hostname_ +
        "/billing.private.paymentgateway.PaymentGatewayService/CreateCloverConnectGateway",
      request,
      metadata || {},
      this.methodInfoCreateCloverConnectGateway,
      callback);
  }

  methodInfoReplaceGatewayToCloverConnect = new grpcWeb.AbstractClientBase.MethodInfo(
    CreateCloverConnectResponse,
    (request: ReplaceGatewayToCloverConnectRequest) => {
      return request.serializeBinary();
    },
    CreateCloverConnectResponse.deserializeBinary
  );

  replaceGatewayToCloverConnect(
    request: ReplaceGatewayToCloverConnectRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: CreateCloverConnectResponse) => void) {
    return this.client_.rpcCall(
      this.hostname_ +
        "/billing.private.paymentgateway.PaymentGatewayService/ReplaceGatewayToCloverConnect",
      request,
      metadata || {},
      this.methodInfoReplaceGatewayToCloverConnect,
      callback);
  }

  methodInfoUpdateCloverConnectGateway = new grpcWeb.AbstractClientBase.MethodInfo(
    UpdateCloverConnectResponse,
    (request: UpdateCloverConnectRequest) => {
      return request.serializeBinary();
    },
    UpdateCloverConnectResponse.deserializeBinary
  );

  updateCloverConnectGateway(
    request: UpdateCloverConnectRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: UpdateCloverConnectResponse) => void) {
    return this.client_.rpcCall(
      this.hostname_ +
        "/billing.private.paymentgateway.PaymentGatewayService/UpdateCloverConnectGateway",
      request,
      metadata || {},
      this.methodInfoUpdateCloverConnectGateway,
      callback);
  }

  methodInfoCreatePaymentCloudGateway = new grpcWeb.AbstractClientBase.MethodInfo(
    CreatePaymentCloudResponse,
    (request: CreatePaymentCloudRequest) => {
      return request.serializeBinary();
    },
    CreatePaymentCloudResponse.deserializeBinary
  );

  createPaymentCloudGateway(
    request: CreatePaymentCloudRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: CreatePaymentCloudResponse) => void) {
    return this.client_.rpcCall(
      this.hostname_ +
        "/billing.private.paymentgateway.PaymentGatewayService/CreatePaymentCloudGateway",
      request,
      metadata || {},
      this.methodInfoCreatePaymentCloudGateway,
      callback);
  }

  methodInfoReplaceGatewayToPaymentCloud = new grpcWeb.AbstractClientBase.MethodInfo(
    CreatePaymentCloudResponse,
    (request: ReplaceGatewayToPaymentCloudRequest) => {
      return request.serializeBinary();
    },
    CreatePaymentCloudResponse.deserializeBinary
  );

  replaceGatewayToPaymentCloud(
    request: ReplaceGatewayToPaymentCloudRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: CreatePaymentCloudResponse) => void) {
    return this.client_.rpcCall(
      this.hostname_ +
        "/billing.private.paymentgateway.PaymentGatewayService/ReplaceGatewayToPaymentCloud",
      request,
      metadata || {},
      this.methodInfoReplaceGatewayToPaymentCloud,
      callback);
  }

  methodInfoUpdatePaymentCloudGateway = new grpcWeb.AbstractClientBase.MethodInfo(
    UpdatePaymentCloudResponse,
    (request: UpdatePaymentCloudRequest) => {
      return request.serializeBinary();
    },
    UpdatePaymentCloudResponse.deserializeBinary
  );

  updatePaymentCloudGateway(
    request: UpdatePaymentCloudRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: UpdatePaymentCloudResponse) => void) {
    return this.client_.rpcCall(
      this.hostname_ +
        "/billing.private.paymentgateway.PaymentGatewayService/UpdatePaymentCloudGateway",
      request,
      metadata || {},
      this.methodInfoUpdatePaymentCloudGateway,
      callback);
  }

  methodInfoGetSpreedlyTransaction = new grpcWeb.AbstractClientBase.MethodInfo(
    GetSpreedlyTransactionResponse,
    (request: GetSpreedlyTransactionRequest) => {
      return request.serializeBinary();
    },
    GetSpreedlyTransactionResponse.deserializeBinary
  );

  getSpreedlyTransaction(
    request: GetSpreedlyTransactionRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: GetSpreedlyTransactionResponse) => void) {
    return this.client_.rpcCall(
      this.hostname_ +
        "/billing.private.paymentgateway.PaymentGatewayService/GetSpreedlyTransaction",
      request,
      metadata || {},
      this.methodInfoGetSpreedlyTransaction,
      callback);
  }

  methodInfoGetTransactionLog = new grpcWeb.AbstractClientBase.MethodInfo(
    GetTransactionLogResponse,
    (request: GetTransactionLogRequest) => {
      return request.serializeBinary();
    },
    GetTransactionLogResponse.deserializeBinary
  );

  getTransactionLog(
    request: GetTransactionLogRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: GetTransactionLogResponse) => void) {
    return this.client_.rpcCall(
      this.hostname_ +
        "/billing.private.paymentgateway.PaymentGatewayService/GetTransactionLog",
      request,
      metadata || {},
      this.methodInfoGetTransactionLog,
      callback);
  }

  methodInfoCreateStripeIntentGateway = new grpcWeb.AbstractClientBase.MethodInfo(
    CreateStripeIntentGatewayResponse,
    (request: CreateStripeIntentGatewayRequest) => {
      return request.serializeBinary();
    },
    CreateStripeIntentGatewayResponse.deserializeBinary
  );

  createStripeIntentGateway(
    request: CreateStripeIntentGatewayRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: CreateStripeIntentGatewayResponse) => void) {
    return this.client_.rpcCall(
      this.hostname_ +
        "/billing.private.paymentgateway.PaymentGatewayService/CreateStripeIntentGateway",
      request,
      metadata || {},
      this.methodInfoCreateStripeIntentGateway,
      callback);
  }

  methodInfoReplaceGatewayToStripeIntentGateway = new grpcWeb.AbstractClientBase.MethodInfo(
    CreateStripeIntentGatewayResponse,
    (request: ReplaceGatewayToStripeIntentGatewayRequest) => {
      return request.serializeBinary();
    },
    CreateStripeIntentGatewayResponse.deserializeBinary
  );

  replaceGatewayToStripeIntentGateway(
    request: ReplaceGatewayToStripeIntentGatewayRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: CreateStripeIntentGatewayResponse) => void) {
    return this.client_.rpcCall(
      this.hostname_ +
        "/billing.private.paymentgateway.PaymentGatewayService/ReplaceGatewayToStripeIntentGateway",
      request,
      metadata || {},
      this.methodInfoReplaceGatewayToStripeIntentGateway,
      callback);
  }

  methodInfoUpdateStripeGateway = new grpcWeb.AbstractClientBase.MethodInfo(
    UpdateStripeGatewayResponse,
    (request: UpdateStripeGatewayRequest) => {
      return request.serializeBinary();
    },
    UpdateStripeGatewayResponse.deserializeBinary
  );

  updateStripeGateway(
    request: UpdateStripeGatewayRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: UpdateStripeGatewayResponse) => void) {
    return this.client_.rpcCall(
      this.hostname_ +
        "/billing.private.paymentgateway.PaymentGatewayService/UpdateStripeGateway",
      request,
      metadata || {},
      this.methodInfoUpdateStripeGateway,
      callback);
  }

  methodInfoStartEnableSCA = new grpcWeb.AbstractClientBase.MethodInfo(
    StartEnableSCAResponse,
    (request: StartEnableSCARequest) => {
      return request.serializeBinary();
    },
    StartEnableSCAResponse.deserializeBinary
  );

  startEnableSCA(
    request: StartEnableSCARequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: StartEnableSCAResponse) => void) {
    return this.client_.rpcCall(
      this.hostname_ +
        "/billing.private.paymentgateway.PaymentGatewayService/StartEnableSCA",
      request,
      metadata || {},
      this.methodInfoStartEnableSCA,
      callback);
  }

  methodInfoCompleteEnableSCA = new grpcWeb.AbstractClientBase.MethodInfo(
    CompleteEnableSCAResponse,
    (request: CompleteEnableSCARequest) => {
      return request.serializeBinary();
    },
    CompleteEnableSCAResponse.deserializeBinary
  );

  completeEnableSCA(
    request: CompleteEnableSCARequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: CompleteEnableSCAResponse) => void) {
    return this.client_.rpcCall(
      this.hostname_ +
        "/billing.private.paymentgateway.PaymentGatewayService/CompleteEnableSCA",
      request,
      metadata || {},
      this.methodInfoCompleteEnableSCA,
      callback);
  }

  methodInfoGetGatewayScaDetails = new grpcWeb.AbstractClientBase.MethodInfo(
    GetGatewayScaDetailsResponse,
    (request: GetGatewayScaDetailsRequest) => {
      return request.serializeBinary();
    },
    GetGatewayScaDetailsResponse.deserializeBinary
  );

  getGatewayScaDetails(
    request: GetGatewayScaDetailsRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: GetGatewayScaDetailsResponse) => void) {
    return this.client_.rpcCall(
      this.hostname_ +
        "/billing.private.paymentgateway.PaymentGatewayService/GetGatewayScaDetails",
      request,
      metadata || {},
      this.methodInfoGetGatewayScaDetails,
      callback);
  }

}

