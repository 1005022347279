import React from "react";
import { RouteComponentProps } from "react-router";
import { AddonsAllowancesScreen } from "../../../models/AddonsAllowances";
import NotFound from "../../error/NotFound";


/**
 * High order component that guard the usage of create/edit or list of addon/allowances only if the url is correct
 * and the type provided is actually addons or allowance othewise redirect to a 404 page
 * @param Component 
 */
const AddOnHoc = (Component: Function) => {
  return function (props: RouteComponentProps) {
    const type = (props.match?.params as any).type;
    if (type !== AddonsAllowancesScreen.ADDONS && type !== AddonsAllowancesScreen.ALLOWANCES) {
      return <NotFound />
    }
    return <Component {...props} type={type} />
  }
}

export default AddOnHoc;