/**
 * @fileoverview gRPC-Web generated client stub for core.private.coupons
 * @enhanceable
 * @public
 */

// GENERATED CODE -- DO NOT EDIT!


import * as grpcWeb from "grpc-web";


import {
  CreateCouponRequest,
  CreateCouponResponse,
  ExpireCouponRequest,
  ExpireCouponResponse,
  GetCouponRequest,
  GetCouponResponse,
  UpdateCouponRequest,
  UpdateCouponResponse} from "./coupons_pb";

export class CouponsServiceClient {
  client_: grpcWeb.AbstractClientBase;
  hostname_: string;
  credentials_: null | { [index: string]: string; };
  options_: null | { [index: string]: string; };

  constructor (hostname: string,
               credentials?: null | { [index: string]: string; },
               options?: null | { [index: string]: string; }) {
    if (!options) options = {};
    if (!credentials) credentials = {};
    options["format"] = "text";

    this.client_ = new grpcWeb.GrpcWebClientBase(options);
    this.hostname_ = hostname;
    this.credentials_ = credentials;
    this.options_ = options;
  }

  methodInfoCreateCoupon = new grpcWeb.AbstractClientBase.MethodInfo(
    CreateCouponResponse,
    (request: CreateCouponRequest) => {
      return request.serializeBinary();
    },
    CreateCouponResponse.deserializeBinary
  );

  createCoupon(
    request: CreateCouponRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: CreateCouponResponse) => void) {
    return this.client_.rpcCall(
      this.hostname_ +
        "/core.private.coupons.CouponsService/CreateCoupon",
      request,
      metadata || {},
      this.methodInfoCreateCoupon,
      callback);
  }

  methodInfoUpdateCoupon = new grpcWeb.AbstractClientBase.MethodInfo(
    UpdateCouponResponse,
    (request: UpdateCouponRequest) => {
      return request.serializeBinary();
    },
    UpdateCouponResponse.deserializeBinary
  );

  updateCoupon(
    request: UpdateCouponRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: UpdateCouponResponse) => void) {
    return this.client_.rpcCall(
      this.hostname_ +
        "/core.private.coupons.CouponsService/UpdateCoupon",
      request,
      metadata || {},
      this.methodInfoUpdateCoupon,
      callback);
  }

  methodInfoExpireCoupon = new grpcWeb.AbstractClientBase.MethodInfo(
    ExpireCouponResponse,
    (request: ExpireCouponRequest) => {
      return request.serializeBinary();
    },
    ExpireCouponResponse.deserializeBinary
  );

  expireCoupon(
    request: ExpireCouponRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: ExpireCouponResponse) => void) {
    return this.client_.rpcCall(
      this.hostname_ +
        "/core.private.coupons.CouponsService/ExpireCoupon",
      request,
      metadata || {},
      this.methodInfoExpireCoupon,
      callback);
  }

  methodInfoGetCoupon = new grpcWeb.AbstractClientBase.MethodInfo(
    GetCouponResponse,
    (request: GetCouponRequest) => {
      return request.serializeBinary();
    },
    GetCouponResponse.deserializeBinary
  );

  getCoupon(
    request: GetCouponRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: GetCouponResponse) => void) {
    return this.client_.rpcCall(
      this.hostname_ +
        "/core.private.coupons.CouponsService/GetCoupon",
      request,
      metadata || {},
      this.methodInfoGetCoupon,
      callback);
  }

}

