import React, { useState, useEffect } from "react";
import Panel from "../ui/panel/Panel";
import Text from "../ui/text/Text";
import Button from "../ui/button/Button";
import "./AccountAccess.scss";
import API from "../../utils/API";
import { IUser } from "../../models/User";
import moment, { defaultFormatUtc } from "moment";

const AccountAccess: React.FC = () => {
  const [state, setState] = useState({
    hasError: false,
    isLoadingData: false,
    isLoading: false,
    isAccessPermitted: false,
    billsbyAccessPermittedUntil: ""
  });

  const { isLoading, isAccessPermitted, billsbyAccessPermittedUntil } = state;
  const dateAndTime = moment.utc(billsbyAccessPermittedUntil, defaultFormatUtc).format("MMM. D, YYYY [at] h:mm A z");

  useEffect(() => {
    fetchData();
  }, [isAccessPermitted]);

  const fetchData = async () => {
    setState(prevState => ({ ...prevState, isLoadingData: true }));
    try {
      const userDetails = (await API.getUserDetails()) as IUser;
      setState(prevState => ({
        ...prevState,
        isAccessPermitted: userDetails.isBillsbyAccessPermitted ? userDetails.isBillsbyAccessPermitted : false,
        billsbyAccessPermittedUntil: userDetails.billsbyAccessPermittedUntil,
        isLoading: false,
      }));
    } catch (err) {
      setState(prevState => ({ ...prevState, isLoadingData: false }));
      console.log(err);
    }
  };

  const handleAccountAccess = async () => {
    setState(prevState => ({ ...prevState, isLoading: true }));
    try {
      if (!isAccessPermitted) {
        await API.allowAccountAccess();
        setState(prevState =>  ({ ...prevState, isLoading: false, isAccessPermitted: true }));
      } else {
        await API.revokeAccountAccess();
        setState(prevState => ({ ...prevState, isLoading: false, isAccessPermitted: false }));
      }
    } catch (err) {
      setState(prevState => ({ ...prevState, isLoading: false }));
      console.log(err);
    }
  };

  return (
    <div className="account-access">
      <Panel className="account-access__title-panel" title={`ACCOUNT_ACCESS_PANEL1_${isAccessPermitted ? "TITLE2" : "TITLE1"}`} translateWith={{ dateAndTime }}>
        <Text className="account-access__title-text" content="ACCOUNT_ACCESS_PANEL1_CONTENT1" />
        <Button
          id="toggle-account-access"
          buttonType={isAccessPermitted ? "error" : "general"}
          isLoading={isLoading}
          title={`ACCOUNT_ACCESS_BTN_${isAccessPermitted ? "REVOKE" : "APPROVE"}`}
          onClick={handleAccountAccess}
        ></Button>
      </Panel>
    </div>
  );
};

export default AccountAccess;
