import React from "react";
import Tooltip from "../ui/tooltip/Tooltip";
import Text from "../ui/text/Text";
import ReactTooltip from "react-tooltip";

interface IAddTooltip {
  id: string,
  place?: ReactTooltip.Place,
  msg?: string,
  showTooltip?: boolean
}
const AddTooltip: React.FC<IAddTooltip> = React.memo(({ children, id, place = "top", msg = "NO_ACCESS_MSG", showTooltip }) => {
  if (!showTooltip) {
    return <>{children}</>;
  }

  return (
    <>
      <div data-tip data-for={id} className="disabled-feature-wrapper">
        {children}
      </div>
      <Tooltip id={id} place={place} type="blue">
        <Text content={msg} className="disabled-feature-tooltip" noMargin />
      </Tooltip>
    </>
  )
})

export default AddTooltip;