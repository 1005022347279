/**
 * @fileoverview gRPC-Web generated client stub for core.private.dashboards
 * @enhanceable
 * @public
 */

// GENERATED CODE -- DO NOT EDIT!


/* eslint-disable */
// @ts-nocheck


import * as grpcWeb from 'grpc-web';

import * as dashboards_pb from './dashboards_pb';


export class DashboardsServiceClient {
  client_: grpcWeb.AbstractClientBase;
  hostname_: string;
  credentials_: null | { [index: string]: string; };
  options_: null | { [index: string]: any; };

  constructor (hostname: string,
               credentials?: null | { [index: string]: string; },
               options?: null | { [index: string]: any; }) {
    if (!options) options = {};
    if (!credentials) credentials = {};
    options['format'] = 'text';

    this.client_ = new grpcWeb.GrpcWebClientBase(options);
    this.hostname_ = hostname;
    this.credentials_ = credentials;
    this.options_ = options;
  }

  methodDescriptorSetTeammateAllowanceManualUpdate = new grpcWeb.MethodDescriptor(
    '/core.private.dashboards.DashboardsService/SetTeammateAllowanceManualUpdate',
    grpcWeb.MethodType.UNARY,
    dashboards_pb.SetTeammateAllowanceManualUpdateRequest,
    dashboards_pb.SetTeammateAllowanceManualUpdateResponse,
    (request: dashboards_pb.SetTeammateAllowanceManualUpdateRequest) => {
      return request.serializeBinary();
    },
    dashboards_pb.SetTeammateAllowanceManualUpdateResponse.deserializeBinary
  );

  setTeammateAllowanceManualUpdate(
    request: dashboards_pb.SetTeammateAllowanceManualUpdateRequest,
    metadata: grpcWeb.Metadata | null): Promise<dashboards_pb.SetTeammateAllowanceManualUpdateResponse>;

  setTeammateAllowanceManualUpdate(
    request: dashboards_pb.SetTeammateAllowanceManualUpdateRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: dashboards_pb.SetTeammateAllowanceManualUpdateResponse) => void): grpcWeb.ClientReadableStream<dashboards_pb.SetTeammateAllowanceManualUpdateResponse>;

  setTeammateAllowanceManualUpdate(
    request: dashboards_pb.SetTeammateAllowanceManualUpdateRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: dashboards_pb.SetTeammateAllowanceManualUpdateResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/core.private.dashboards.DashboardsService/SetTeammateAllowanceManualUpdate',
        request,
        metadata || {},
        this.methodDescriptorSetTeammateAllowanceManualUpdate,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/core.private.dashboards.DashboardsService/SetTeammateAllowanceManualUpdate',
    request,
    metadata || {},
    this.methodDescriptorSetTeammateAllowanceManualUpdate);
  }

  methodDescriptorRemoveTaxCouldNotBeAppliedWarning = new grpcWeb.MethodDescriptor(
    '/core.private.dashboards.DashboardsService/RemoveTaxCouldNotBeAppliedWarning',
    grpcWeb.MethodType.UNARY,
    dashboards_pb.RemoveTaxCouldNotBeAppliedWarningRequest,
    dashboards_pb.RemoveTaxCouldNotBeAppliedWarningResponse,
    (request: dashboards_pb.RemoveTaxCouldNotBeAppliedWarningRequest) => {
      return request.serializeBinary();
    },
    dashboards_pb.RemoveTaxCouldNotBeAppliedWarningResponse.deserializeBinary
  );

  removeTaxCouldNotBeAppliedWarning(
    request: dashboards_pb.RemoveTaxCouldNotBeAppliedWarningRequest,
    metadata: grpcWeb.Metadata | null): Promise<dashboards_pb.RemoveTaxCouldNotBeAppliedWarningResponse>;

  removeTaxCouldNotBeAppliedWarning(
    request: dashboards_pb.RemoveTaxCouldNotBeAppliedWarningRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: dashboards_pb.RemoveTaxCouldNotBeAppliedWarningResponse) => void): grpcWeb.ClientReadableStream<dashboards_pb.RemoveTaxCouldNotBeAppliedWarningResponse>;

  removeTaxCouldNotBeAppliedWarning(
    request: dashboards_pb.RemoveTaxCouldNotBeAppliedWarningRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: dashboards_pb.RemoveTaxCouldNotBeAppliedWarningResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/core.private.dashboards.DashboardsService/RemoveTaxCouldNotBeAppliedWarning',
        request,
        metadata || {},
        this.methodDescriptorRemoveTaxCouldNotBeAppliedWarning,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/core.private.dashboards.DashboardsService/RemoveTaxCouldNotBeAppliedWarning',
    request,
    metadata || {},
    this.methodDescriptorRemoveTaxCouldNotBeAppliedWarning);
  }

}

