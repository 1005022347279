import React, { useState } from "react";
import Text from "../ui/text/Text";
import Button from "../ui/button/Button";
import API from "../../utils/API";
import { useSelector } from "react-redux";
import { AppState } from "../..";
import { ITeammate } from "../../models/CompanyTeammates";

interface IPendingInvitationRow {
  teammate: ITeammate
  updateCallback?: Function
}

const PendingInvitationRow: React.FC<IPendingInvitationRow> = ({ teammate, updateCallback }) => {
  const authData = useSelector((state: AppState) => state.auth);
  const { userId, firstName, lastName, email, role } = teammate;
  const [state, setState] = useState({ isSending: false, isSent: false, isRevoking: false, isRevoked: false });
  const { isSending, isSent, isRevoked, isRevoking } = state;

  const handleResendEmail = async (evt: any) => {
    evt.preventDefault();

    if (isSent) {
      return null;
    }

    setState({ ...state, isSending: true });

    try {
      await API.sendInvitationToUser(authData.currentCompanyDomain, { firstName, lastName, email, role });
      setState({ ...state, isSending: false, isSent: true, isRevoked: false });
    } catch (err) {
      setState({ ...state, isSending: false, isSent: false });
      console.log(err);
    }
  };

  const handleRevokeInvite = async (evt: any) => {
    evt.preventDefault();

    if (isRevoked) {
      return null;
    }

    setState({ ...state, isRevoking: true });

    try {
      await API.revokeTeammateUserAccess(authData.currentCompanyDomain, userId);
      setState({ ...state, isRevoking: false, isRevoked: true, isSent: false });
      updateCallback && (await updateCallback());
    } catch (err) {
      setState({ ...state, isRevoking: false, isRevoked: false });
      console.log(err);
    }
  };

  return (
    <tr key={userId}>
      <Text shouldTranslate={false} content={email} component="td" noMargin />
      <Text shouldTranslate={false} content={`${firstName} ${lastName}`} component="td" noMargin />
      <td>
        <div className="teammates__action-buttons-wrapper">
          <Button
            id="teammates-email-resent"
            onClick={handleResendEmail}
            isLoading={isSending}
            icon={isSent ? "far fa-check-circle" : ""}
            title={`TEAMMATES_LIST_${isSent ? "EMAIL_RESENT" : "RESEND_EMAIL"}`}
            buttonType={isSent ? "success" : "add"}
            isFullWidth
          />
          <Button
            id={"teammates-revoke-invite"}
            onClick={handleRevokeInvite}
            isLoading={isRevoking}
            className={isRevoked ? "revoked" : ""}
            icon={isRevoked ? "far fa-times-circle" : ""}
            title={`TEAMMATES_LIST_${isRevoked ? "REVOKED" : "REVOKE"}_INVITE`}
            buttonType="error"
            isFullWidth
          />
        </div>
      </td>
    </tr>
  );
};

export default PendingInvitationRow;
