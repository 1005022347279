import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Table from "../../components/ui/table/Table";
import Text from "../../components/ui/text/Text";
import Modal from "../../components/modal/Modal";
import Refund from "../../components/refund/Refund";
import StatusLabel from "../../components/ui/status-label/StatusLabel";
import { AppState } from "../..";
import { fetchCustomerInvoices, resetCustomerDetailsInvoices } from "../../actions/customerDetailsActions";
import { putInvoicePayment } from "../../actions/invoiceDetailsActions";
import Button from "../../components/ui/button/Button";
import InvoicesPaymentsTable from "./invoices-payment-table/InvoicesPaymentsTable";
import moment from "moment";
import counterpart from "counterpart";
import LoadingModal from "../../components/modal/loading-modal/LoadingModal";
import "./InvoicesTable.scss";
import { getInvoiceRefundError, getInvoiceStatusColor, getInvoiceStatusText } from "../../utils/commonUtils";
import { InvoiceRefundType, InvoiceStatus } from "../../models/Invoices";
import { ICompanyBrandingReducerState } from "../../reducers/companyBrandingReducer";
import ProgressIndicator from "../../components/ui/progress-indicator/ProgressIndicator";
import { ICustomerDetailsReducerState } from "../../reducers/customerDetailsReducer";
import { IAuthReducerState } from "../../reducers/authReducer";
import { useParams } from "react-router";
import history from "../../utils/history";
import { ICustomerInvoiceData } from "../../models/Customer";
import { Permission } from "../../models/Auth";
import useCheckFeaturePermission from "../../utils/hooks/useCheckFeaturePermission";

const InvoicesTable: React.FC = () => {
  const [state, setState] = useState({
    showRefundModal: false,
    showLoadingModal: false,
    amount: "",
    invoiceNumber: "",
    refundableAmount: 0,
    isLoadingData: false
  })

  const { showLoadingModal, showRefundModal, amount, invoiceNumber, isLoadingData } = state;
  const { invoiceData } = useSelector<AppState, ICustomerDetailsReducerState>(state => state.customerDetailsReducer)
  const { logoUrl, logoBackgroundColor } = useSelector<AppState, ICompanyBrandingReducerState>(state => state.companyBrandingReducer);
  const { currentCompanyDomain } = useSelector<AppState, IAuthReducerState>(state => state.auth)
  const dispatch = useDispatch<Function>()
  const params = useParams<{ id: string }>()
  const isInvoiceReattemptPermitted = useCheckFeaturePermission(Permission.InvoiceReattempt);
  const isInvoiceRefundPermitted = useCheckFeaturePermission(Permission.InvoiceRefund);

  const invoicesTableHeader = [
    "CUSTOMER_DETAILS_INVOICES_DATE",
    "CUSTOMER_DETAILS_INVOICES_NUMBER",
    "CUSTOMER_DETAILS_INVOICES_AMOUNT",
    "CUSTOMER_DETAILS_INVOICES_PAYMENTS",
    "CUSTOMER_DETAILS_INVOICES_STATUS"
  ];

  const fetchInvoicesData = async () => {
    setState(prevState => ({ ...prevState, isLoadingData: true }))
    try {
      await dispatch(fetchCustomerInvoices(currentCompanyDomain, params.id.toString()));
      setState(prevState => ({ ...prevState, isLoadingData: false }))

    } catch (err) {
      console.log(err);
      setState(prevState => ({ ...prevState, isLoadingData: false }))
    }
  };

  useEffect(() => {
    fetchInvoicesData();
    return () => dispatch(resetCustomerDetailsInvoices())
  }, [params.id]);

  const handleRefundCallback = async () => {
    setState(prevState => ({ ...prevState, showRefundModal: false }));
    await dispatch(fetchCustomerInvoices(currentCompanyDomain, params.id.toString()));
  };

  const handleReattempt = async (invoiceNumber: string) => {
    try {
      await dispatch(putInvoicePayment(currentCompanyDomain, invoiceNumber));
      await dispatch(fetchCustomerInvoices(currentCompanyDomain, params.id.toString()));
    } catch (err) {
      console.log(err)
    }
  };

  const renderInvoiceActionButton = (data: ICustomerInvoiceData) => {
    if(data.status === InvoiceStatus.PENDING || data.status === InvoiceStatus.CLEARING) {
      return null
    }

    if (data.isReattemptable) {
      return (
        <Button
          id={`invoice-refund-${data.invoiceId}`}
          title="CUSTOMER_DETAILS_INVOICES_REATTEMPT"
          disabled={!isInvoiceReattemptPermitted}
          onClick={(evt: Event) => {
            evt.stopPropagation();
            handleReattempt(data.invoiceNumber);
            setState((prevState) => ({ ...prevState, amount: data.formattedAmount }));
          }}
          tooltipMessage={!isInvoiceReattemptPermitted ? "NO_ACCESS_MSG" : undefined}
          buttonType="error"
        />
      )
    }

    return (
      <Button
        id={`invoice-refund-${data.invoiceId}`}
        title="CUSTOMER_DETAILS_INVOICES_REFUND"
        disabled={data.refundType !== InvoiceRefundType.AVAILABLE || !isInvoiceRefundPermitted}
        onClick={(evt: Event) => {
          evt.stopPropagation();
          setState((prevState) => ({
            ...prevState,
            showRefundModal: true,
            invoiceNumber: data.invoiceNumber,
            refundableAmount: data.refundableAmount
          }));
        }}
        tooltipMessage={!isInvoiceRefundPermitted ? "NO_ACCESS_MSG" : getInvoiceRefundError(data.refundType)}
        buttonType="add"
      />
    )

  }

  const renderTableDataContents = () => {
    return invoiceData.map(data => {
      return (
        <React.Fragment key={data.invoiceId}>
          <tr>
            <Text shouldTranslate={false} content={moment(data.createdOn).format("DD MMM YYYY")} component="td" noMargin />
            <Text shouldTranslate={false} content={data.invoiceNumber} component="td" noMargin />
            <Text shouldTranslate={false} content={data.formattedTotalGross} component="td" noMargin />
            <Text
              className="invoices-table__collapsible-transactions"
              content={"CUSTOMER_DETAILS_INVOICES_LINKED_TRANSACTIONS"}
              translateWith={{
                transactionCount: data.linkedTransactionsCount
              }}
              component="td"
              noMargin
            />
            <td>
              <div className="invoices-table__status-btn-wrapper">
                <StatusLabel color={getInvoiceStatusColor(data.status)} content={getInvoiceStatusText(data.status)} />

                <div className="invoices-table__btn-wrapper">
                  {renderInvoiceActionButton(data)}
                  <Button
                    id={`invoice-view-${data.invoiceId}`}
                    icon="fas fa-eye"
                    shouldTranslate={false}
                    title={counterpart("CUSTOMER_DETAILS_INVOICES_VIEW")}
                    buttonType="add"
                    link={`/invoices/${data.invoiceNumber}/customer/${params.id}`}
                    onClick={() => history.push(`/invoices/${data.invoiceNumber}/customer/${params.id}`)}
                  />
                </div>
              </div>
            </td>
          </tr>
          <tr className="expanded">
            <InvoicesPaymentsTable invoiceNumber={data.invoiceNumber} />
          </tr>
        </React.Fragment>
      );
    });
  };

  if (isLoadingData) {
    return <ProgressIndicator color="blue"></ProgressIndicator>
  }

  if (!invoiceData || invoiceData.length === 0) {
    return null;
  }

  return (
    <>
      <Table expandable className="invoices-table">
        <thead>
          <tr>
            {invoicesTableHeader.map(content => (
              <Text key={content} content={content} component="th" noMargin />
            ))}
          </tr>
        </thead>
        <tbody>{renderTableDataContents()}</tbody>
      </Table>
      <Modal
        title="REFUND_INVOICE_WELCOME"
        isOpen={showRefundModal}
        onRequestClose={() => setState({ ...state, showRefundModal: false })}
        children={<Refund handleRefundCallback={handleRefundCallback} refundData={{ customerUniqueId: params.id, invoiceNumber }} getInvoiceDetails={true} />}
      />
      <LoadingModal
        title="INVOICE_DETAILS_REATTEMPT_TITLE"
        logo={logoUrl}
        logoBackgroundColor={logoBackgroundColor}
        titleTranslateWith={{ amount }}
        subTitle="INVOICE_DETAILS_REATTEMPT_SUB_TITLE"
        isOpen={showLoadingModal}
      />
    </>
  );
};


export default InvoicesTable;
