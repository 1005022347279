import React, { useEffect, useMemo, useState } from "react";
import { IAuthReducerState } from "../../../../reducers/authReducer";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../../../..";
import NavigationBar from "../../../ui/navigation-bar/NavigationBar";
import history from "../../../../utils/history";
import PageWrapper from "../../../ui/layout/PageWrapper";
import Panel from "../../../ui/panel/Panel";
import Text from "../../../ui/text/Text";
import { Row, Col } from "react-grid-system";
import Button from "../../../ui/button/Button";
import StepCollapsablePanel from "../../../ui/step-collapsable-panel/StepCollapsablePanel";
import Switch from "../../../ui/switch/Switch";
import FormGroup from "../../../ui/form-group/FormGroup";
import Dropdown from "../../../ui/dropdown/Dropdown";
import FormLabel from "../../../ui/form-label/FormLabel";
import InteractiveList from "../../../ui/interactive-list/InteractiveList";
import { ILanguageSupportReducerState } from "../../../../reducers/languageSupportReducer";
import { ISupportedLanguage, Language } from "../../../../models/languageSupport";
import { fetchCompanyLanguages, resetAccountManagementLanguages, resetCheckoutLanguages, resetCompanyLanguages, resetCompanyLanguagesConfig, updateAccountManagementDefaultLaguage, updateAccountManagementLanguages, updateCheckoutDefaultLaguage, updateCheckoutLanguages, updateCompanyLaguages, updateCompanyLanguages } from "../../../../actions/languageSupportActions";
import ProgressIndicator from "../../../ui/progress-indicator/ProgressIndicator";
import "./LanguageConfig.scss";


const LanguageConfig: React.FC = () => {
  
  const [isSavingCompanyLanguage, setIsSavingCompanyLanguage] = useState(false);
  const [isSavingCheckoutLanguage, setIsSavingCheckoutLanguage] = useState(false);
  const [isSavingAccountManagementLanguage, setIsSavingAccountManagementLanguage] = useState(false);

  const { companyLaguageConfig, intinialCompanyLanguagesConfig } = useSelector<AppState, ILanguageSupportReducerState>(state => state.languageSupportReducer);
  const { currentCompanyId } = useSelector<AppState, IAuthReducerState>(state => state.auth);
  const dispatch = useDispatch<Function>()
  
  useEffect(() => {
    if(!!currentCompanyId) {
      dispatch(fetchCompanyLanguages(currentCompanyId));
    }
    return () => {      
      dispatch(resetCompanyLanguagesConfig());
    }
  }, []) 
  
  const languageOptions: Array<{ label: string, value: string }> = useMemo(() => [...intinialCompanyLanguagesConfig.companyLanguagesList
    .filter(language => language.isEnabled)
    .map(language => ({ label: language.englishName, value: language.isoCode }))],
    [intinialCompanyLanguagesConfig.companyLanguagesList]);

  const isLocked =  (languageIso: string) => languageIso === intinialCompanyLanguagesConfig.defaultAccountManagementLanguage || 
    languageIso === intinialCompanyLanguagesConfig.defaultCheckoutLanguage || languageIso === Language.ENGLISH;

    
  const updateCompanyLaguage = (companyLanguage: ISupportedLanguage ) => {
    if (isLocked(companyLanguage.isoCode))
      return;

    dispatch(updateCompanyLanguages({...companyLanguage}));
  }

  const saveEnabledLanguageConfig = async () => {
    setIsSavingCompanyLanguage(true);
    await dispatch(updateCompanyLaguages(Number(currentCompanyId), [...companyLaguageConfig.companyLanguagesList.map(i => ({...i}))]));
    setIsSavingCompanyLanguage(false);
  }

  const saveCheckoutLanguageConfig = async () => {
    setIsSavingCheckoutLanguage(true);
    await dispatch(updateCheckoutDefaultLaguage(Number(currentCompanyId), companyLaguageConfig.defaultCheckoutLanguage));
    setIsSavingCheckoutLanguage(false);
  }

  const saveAccountManagementLanguageConfig = async () => {
    setIsSavingAccountManagementLanguage(true);
    await dispatch(updateAccountManagementDefaultLaguage(Number(currentCompanyId), companyLaguageConfig.defaultAccountManagementLanguage));
    setIsSavingAccountManagementLanguage(false);
  }

  if (!companyLaguageConfig || !companyLaguageConfig.companyLanguagesList.length) {
    return <ProgressIndicator color="blue" coverage='full-content' />
  }

  const renderYourLanguages = () => {
    return (
      <Panel title="LANGUAGES_CONFIG_YOUR_LANGUAGES">
        <Text content="LANGUAGES_CONFIG_YOUR_LANGUAGES_DESCRIPTION"></Text>
        <div className="company-language-config__draggable-container">
          {
            !!companyLaguageConfig && companyLaguageConfig.companyLanguagesList.map((companyLanguage: ISupportedLanguage ) => 
              <StepCollapsablePanel
                className="company-language-config__draggable-panel"
                key={`language-${companyLanguage.isoCode}`}
                title={
                  <div className="company-language-config__draggable-panel__content">
                    <div>
                      <Text className="title" content={`LANGUAGES_CONFIG_${companyLanguage.englishName.toUpperCase()}`} noMargin />
                      <Text className="sub-title" content={`LANGUAGES_CONFIG_${companyLanguage.englishName.toUpperCase()}_DESCRIPTION`} noMargin />
                    </div>
                    <div className={`company-language-config__draggable-panel__action ${isLocked(companyLanguage.isoCode) ? "locked" : ""}`}>
                      {isLocked(companyLanguage.isoCode) && <i className="fas fa-fw fa-lock" />}
                      <br />
                      <Switch
                        id={`language-${companyLanguage.isoCode.toLowerCase()}`}
                        onChange={() => updateCompanyLaguage({...companyLanguage})} checked={companyLanguage.isEnabled}
                      ></Switch>
                    </div>
                  </div>
                }
                shouldTranslateTitle={false}
                hasHelpSection={false}
              />)
          }
        </div>

        <Row align="center">
          <Col md={9}>
            <Button
              onClick={() => saveEnabledLanguageConfig()}
              isLoading={isSavingCompanyLanguage}
              disabled={isSavingCompanyLanguage}
              id="language-config-update-language-btn"
              type="submit"
              isFullWidth
              buttonType="general"
              title="LANGUAGES_CONFIG_UPDATE_LANGUAGE_BUTTON"
            />
          </Col>
          <Col md={3}>
            <Button
              onClick={() => dispatch(resetCompanyLanguages())}
              disabled={isSavingCompanyLanguage}
              id="language-config-update-language-cancel-btn"
              isFullWidth
              buttonType="error"
              title="LANGUAGES_CONFIG_CANCEL_BUTTON"
            />
          </Col>
        </Row>
      </Panel>
    );
  };

  const renderCheckoutLanguage = () => {
    return (
      <Panel title="LANGUAGES_CONFIG_CHECKOUT_DEFAULT_LANGUAGE">
        <Text content="LANGUAGES_CONFIG_CHECKOUT_DEFAULT_LANGUAGE_DESCRIPTION" />

        <FormGroup>
          <Row align="center">
            <Col xs={1.5}>
              <FormLabel target="language-checkout-default" content="LANGUAGES_CONFIG_DEFAULT_LANGUAGE_LABEL" noMargin />
            </Col>
            <Col xs={10.5}>
              <Dropdown
                id="language-checkout-default"
                options={languageOptions}
                value={languageOptions.find(i => i.value === companyLaguageConfig.defaultCheckoutLanguage)}
                onChange={(language: { label: string, value: any }) => dispatch(updateCheckoutLanguages(language.value))}
              />
            </Col>
          </Row>
        </FormGroup>


        <Row align="center">
          <Col md={9}>
            <Button
              onClick={() => saveCheckoutLanguageConfig()}
              isLoading={isSavingCheckoutLanguage}
              disabled={isSavingCheckoutLanguage}
              type="submit"
              isFullWidth
              buttonType="general"
              id="save-checkout-default-language"
              title="LANGUAGES_CONFIG_DEFAULT_UPDATE_BUTTON"
            />
          </Col>
          <Col md={3}>
            <Button
              onClick={() => dispatch(resetCheckoutLanguages())}
              disabled={isSavingCheckoutLanguage}
              isFullWidth
              id="cancel-checkout-default-language"
              title="LANGUAGES_CONFIG_CANCEL_BUTTON"
              buttonType="error"
            />
          </Col>
        </Row>
      </Panel>
    )
  }

  const renderSelfServiceLanguage = () => {
    return (
      <Panel title="LANGUAGES_CONFIG_SELF_SERVICE_DEFAULT_LANGUAGE">
        <Text content="LANGUAGES_CONFIG_SELF_SERVICE_DEFAULT_LANGUAGE_DESCRIPTION" />

        <FormGroup>
          <Row align="center">
            <Col xs={1.5}>
              <FormLabel target="language-self-service-default" content="LANGUAGES_CONFIG_DEFAULT_LANGUAGE_LABEL" noMargin />
            </Col>
            <Col xs={10.5}>
              <Dropdown
                id="language-self-service-default"
                options={languageOptions}
                value={languageOptions.find(i => i.value === companyLaguageConfig.defaultAccountManagementLanguage)}
                onChange={(language: { label: string, value: any }) => dispatch(updateAccountManagementLanguages(language.value))}
              />
            </Col>
          </Row>
        </FormGroup>


        <Row align="center">
          <Col md={9}>
            <Button
              onClick={() => saveAccountManagementLanguageConfig()}
              isLoading={isSavingAccountManagementLanguage}
              disabled={isSavingAccountManagementLanguage}
              type="submit"
              isFullWidth
              buttonType="general"
              id="save-self-service-default-language"
              title="LANGUAGES_CONFIG_DEFAULT_UPDATE_BUTTON"
            />
          </Col>
          <Col md={3}>
            <Button
              onClick={() => dispatch(resetAccountManagementLanguages())}
              disabled={isSavingAccountManagementLanguage}
              isFullWidth
              id="cancel-self-service-default-language"
              title="LANGUAGES_CONFIG_CANCEL_BUTTON"
              buttonType="error"
            />
          </Col>
        </Row>
      </Panel>
    )
  }

  return (
    <div className="company-language-config">
      <NavigationBar pageTitle="" previousPageTitle="CONFIGURATION_PAGE_TITLE" previousPageCb={() => history.push("/configuration")} />

      <PageWrapper>
        <Panel title="LANGUAGES_CONFIG_TITLE" className="company-language-config__header-panel">
          <Text content="LANGUAGES_CONFIG_DESCRIPTION" noMargin />
        </Panel>

        {renderYourLanguages()}
        {renderCheckoutLanguage()}
        {renderSelfServiceLanguage()}

        <InteractiveList
          data={[
            {
              title: "LANGUAGES_CONFIG_EMBED_CODES_LINK_TITLE",
              subTitle: "LANGUAGES_CONFIG_EMBED_CODES_LINK_DESCRIPTION",
              onClick: () => history.push("/embed-codes/checkout")
            }
          ]}
        />
      </PageWrapper>
    </div>
  )
}


export default LanguageConfig