import React, { FormEvent, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../../../../..";
import { IDunningFailedProcessState } from "../../../../../reducers/dunningFailedProcessReducer";
import "./FailedPayments.scss";
import Panel from "../../../../ui/panel/Panel";
import Text from "../../../../ui/text/Text";
import Button from "../../../../ui/button/Button";
import { Row, Col } from "react-grid-system";
import Input from "../../../../ui/input/Input";
import FormLabel from "../../../../ui/form-label/FormLabel";
import { updateDunningProcessProps, resetFailedPayments, updateDunningProcessData, fetchDunningProcessData } from "../../../../../actions/dunningFailedProcessActions";
import { IDunningConfig } from "../../../../../models/DunningFailedProcess";
import { IAuthReducerState } from "../../../../../reducers/authReducer";
import counterpart from "counterpart";
import { allowZeroAndPositiveNumbers } from "../../../../../utils/commonUtils";
import { useHistory } from "react-router";

interface IFailedPaymentsProps {
  configData: IDunningConfig
}

const FailedPayments: React.FC<IFailedPaymentsProps> = ({ configData }) => {
  const [state, setState] = useState({ hasError: false, errorMessage: "", success: false, isLoading: false });
  const authReducer = useSelector<AppState, IAuthReducerState>(state => state.auth)
  const {
    initialPaymentDeclineStatus,
    successReattemptStatus,
    writingOffInvoice,
    attemptOne,
    attemptTwo,
    attemptThree,
    initialPaymentFailureHoursUntilPrompt,
    continuedPaymentFailureHoursUntilTransfer,
    minimumTerm
  } = useSelector<AppState, IDunningFailedProcessState>(state => state.dunningFailedProcessReducer);
  const dispatch = useDispatch<Function>()

  const history = useHistory()
  
  const { isLoading, hasError } = state;

  useEffect(() => {
    return () => dispatch(resetFailedPayments());
  }, []);

  const initialPaymentFailureHoursError = initialPaymentFailureHoursUntilPrompt < 6;
  const continuedPaymentFailureHoursError = continuedPaymentFailureHoursUntilTransfer < 6;

  const onSubmit = async (evt: FormEvent) => {
    evt.preventDefault();
    evt.stopPropagation();

    const configData: IDunningConfig = {
      subscriptionStatusOnInitialFailure: initialPaymentDeclineStatus,
      subscriptionStatusOnSuccess: successReattemptStatus,
      attemptOne: attemptOne.reattempt ? { hoursUntilReattempt: attemptOne.hours, subscriptionStatusOnFailure: attemptOne.subscriptionStatusType } : null,
      attemptTwo: attemptTwo.reattempt ? { hoursUntilReattempt: attemptTwo.hours, subscriptionStatusOnFailure: attemptTwo.subscriptionStatusType } : null,
      attemptThree: attemptThree.reattempt ? { hoursUntilReattempt: attemptThree.hours, subscriptionStatusOnFailure: attemptThree.subscriptionStatusType } : null,
      minimumTerm: minimumTerm.hasMinimumTerm ? { hoursUntilReattempt: minimumTerm.hours, subscriptionStatusOnFailure: minimumTerm.subscriptionStatusType } : null,      
      writeOff: { logic: writingOffInvoice.writeOffStatus, subscriptionStatus: writingOffInvoice.subscriptionStatusType, hoursUntilAutomaticWriteOff: writingOffInvoice.hours },
      failedPaymentDunningConfig: {
        hoursUntilDunning: continuedPaymentFailureHoursUntilTransfer,
        hoursUntilEmailAlert: initialPaymentFailureHoursUntilPrompt
      }
    };

    setState({ ...state, isLoading: true });

    try {
      await dispatch(updateDunningProcessData(authReducer.currentCompanyDomain, configData));
      await dispatch(fetchDunningProcessData(authReducer.currentCompanyDomain));
      setState({ ...state, isLoading: false });
    } catch (err) {
      setState({ ...state, isLoading: false, hasError: true });
      console.log(err);
    }
  };

  return (
    <div className="dunning-failed-payments">
      <form onSubmit={onSubmit}>
        <Panel title="DUNNING_FAILED_PAYMENTS_PANEL9_TITLE">
          <Text content="DUNNING_FAILED_PAYMENTS_PANEL9_CONTENT" noMargin />
        </Panel>

        <Panel>
          <div>
            <Text content="DUNNING_FAILED_PAYMENTS_PANEL10_TITLE" noMargin />
            <Button id="initial-payment-failure-customize-email" title="DUNNING_FAILED_PAYMENTS_BUTTON_CUSTOMIZE_EMAIL" buttonType="add" icon="fas fa-edit" onClick={() => history.push("/configuration/dunning-process/customize-email/failed/initial-payment-failure")}/>
          </div>
          <Text content="DUNNING_FAILED_PAYMENTS_PANEL10_CONTENT" />
          <Row align="center">
            <Col xs={2}>
              <FormLabel target="hours-until-prompt" content="DUNNING_FAILED_PAYMENTS_PANEL10_LABEL" noMargin />
            </Col>
            <Col xs={10}>
              <Input
                id="hours-until-prompt"
                type="number"
                allowDecimal={false}
                errorMsg={counterpart("DUNNING_FAILED_PAYMENTS_MIN_HOUR")}
                isError={initialPaymentFailureHoursError}
                value={initialPaymentFailureHoursUntilPrompt}
                onChange={(evt: any) => dispatch(updateDunningProcessProps("initialPaymentFailureHoursUntilPrompt", allowZeroAndPositiveNumbers(evt.target.value)))}
              />
            </Col>
          </Row>
        </Panel>

        <Panel title="DUNNING_FAILED_PAYMENTS_PANEL11_TITLE">
          <Text content="DUNNING_FAILED_PAYMENTS_PANEL11_CONTENT" />
          <Row align="center">
            <Col xs={2}>
              <FormLabel target="hours-until-transfer" content="DUNNING_FAILED_PAYMENTS_PANEL11_LABEL" noMargin />
            </Col>
            <Col xs={10}>
              <Input
                id="hours-until-transfer"
                type="number"
                allowDecimal={false}
                errorMsg={counterpart("DUNNING_FAILED_PAYMENTS_MIN_HOUR")}
                isError={continuedPaymentFailureHoursError}
                value={continuedPaymentFailureHoursUntilTransfer}
                onChange={(evt: any) => dispatch(updateDunningProcessProps("continuedPaymentFailureHoursUntilTransfer", allowZeroAndPositiveNumbers(evt.target.value)))}
              />
            </Col>
          </Row>
        </Panel>

        <Row align="center">
          <Col md={9}>
            <Button type="submit" id="btn-failed-payments" isFullWidth buttonType="general" disabled={initialPaymentFailureHoursError || continuedPaymentFailureHoursError} isLoading={isLoading} title="DUNNING_FAILED_PAYMENTS_BUTTON_UPDATE_CHANGES" />
          </Col>
          <Col md={3}>
            <Button id="btn-failed-payments-cancel" onClick={() => dispatch(resetFailedPayments())} isFullWidth buttonType="error" title="DUNNING_FAILED_PAYMENTS_BUTTON_CANCEL" />
          </Col>
        </Row>
      </form>
    </div>
  );
};



export default FailedPayments