/**
 * @fileoverview gRPC-Web generated client stub for billing.public.customer
 * @enhanceable
 * @public
 */

// GENERATED CODE -- DO NOT EDIT!


import * as grpcWeb from "grpc-web";

import {
  GetCustomerCardDetailsRequest,
  GetCustomerCardDetailsResponse,
  GetCustomerLanguageRequest,
  GetCustomerLanguageResponse,
  SendPasscodeEmailRequest,
  SendPasscodeEmailResponse,
  ValidatePasscodeRequest,
  ValidatePasscodeResponse} from "./customer_pb";

export class CustomerServiceClient {
  client_: grpcWeb.AbstractClientBase;
  hostname_: string;
  credentials_: null | { [index: string]: string; };
  options_: null | { [index: string]: string; };

  constructor (hostname: string,
               credentials?: null | { [index: string]: string; },
               options?: null | { [index: string]: string; }) {
    if (!options) options = {};
    if (!credentials) credentials = {};
    options["format"] = "text";

    this.client_ = new grpcWeb.GrpcWebClientBase(options);
    this.hostname_ = hostname;
    this.credentials_ = credentials;
    this.options_ = options;
  }

  methodInfoSendPasscodeEmail = new grpcWeb.AbstractClientBase.MethodInfo(
    SendPasscodeEmailResponse,
    (request: SendPasscodeEmailRequest) => {
      return request.serializeBinary();
    },
    SendPasscodeEmailResponse.deserializeBinary
  );

  sendPasscodeEmail(
    request: SendPasscodeEmailRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: SendPasscodeEmailResponse) => void) {
    return this.client_.rpcCall(
      this.hostname_ +
        "/billing.public.customer.CustomerService/SendPasscodeEmail",
      request,
      metadata || {},
      this.methodInfoSendPasscodeEmail,
      callback);
  }

  methodInfoValidatePasscode = new grpcWeb.AbstractClientBase.MethodInfo(
    ValidatePasscodeResponse,
    (request: ValidatePasscodeRequest) => {
      return request.serializeBinary();
    },
    ValidatePasscodeResponse.deserializeBinary
  );

  validatePasscode(
    request: ValidatePasscodeRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: ValidatePasscodeResponse) => void) {
    return this.client_.rpcCall(
      this.hostname_ +
        "/billing.public.customer.CustomerService/ValidatePasscode",
      request,
      metadata || {},
      this.methodInfoValidatePasscode,
      callback);
  }

  methodInfoGetCustomerCardDetails = new grpcWeb.AbstractClientBase.MethodInfo(
    GetCustomerCardDetailsResponse,
    (request: GetCustomerCardDetailsRequest) => {
      return request.serializeBinary();
    },
    GetCustomerCardDetailsResponse.deserializeBinary
  );

  getCustomerCardDetails(
    request: GetCustomerCardDetailsRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: GetCustomerCardDetailsResponse) => void) {
    return this.client_.rpcCall(
      this.hostname_ +
        "/billing.public.customer.CustomerService/GetCustomerCardDetails",
      request,
      metadata || {},
      this.methodInfoGetCustomerCardDetails,
      callback);
  }

  methodInfoGetCustomerLanguage = new grpcWeb.AbstractClientBase.MethodInfo(
    GetCustomerLanguageResponse,
    (request: GetCustomerLanguageRequest) => {
      return request.serializeBinary();
    },
    GetCustomerLanguageResponse.deserializeBinary
  );

  getCustomerLanguage(
    request: GetCustomerLanguageRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: GetCustomerLanguageResponse) => void) {
    return this.client_.rpcCall(
      this.hostname_ +
        "/billing.public.customer.CustomerService/GetCustomerLanguage",
      request,
      metadata || {},
      this.methodInfoGetCustomerLanguage,
      callback);
  }

}

