import API from "../utils/API";
import { makeActionCreator, makeGenericActionCreator } from "../utils/reduxUtils";
import { EmailTypes, GetEmailConfigRequest, UpsertEmailConfigRequest } from "../utils/grpc/generated/Billsby.Protos/Messenger/private/emailconfig/emailconfig_pb";
import { EmailConfigServiceClient } from "../utils/grpc/generated/Billsby.Protos/Messenger/private/emailconfig/EmailconfigServiceClientPb";

import { grpcUnaryCall } from "../utils/grpc/grpcUtils";
import { ConfigConstants } from "../utils/config";
import { IEmailCustomizationReducerState } from "../reducers/emailCustomizationReducer";

export const FETCH_EMAIL_TEMPLATE_VALUES_REQUEST = "FETCH_EMAIL_TEMPLATE_VALUES_REQUEST";
export const FETCH_EMAIL_TEMPLATE_VALUES_SUCCESS = "FETCH_EMAIL_TEMPLATE_VALUES_SUCCESS";
export const FETCH_EMAIL_TEMPLATE_VALUES_FAILURE = "FETCH_EMAIL_TEMPLATE_VALUES_FAILURE";

export const UPDATE_EMAIL_TEMPLATE_VALUES_REQUEST = "UPDATE_EMAIL_TEMPLATE_VALUES_REQUEST";
export const UPDATE_EMAIL_TEMPLATE_VALUES_SUCCESS = "UPDATE_EMAIL_TEMPLATE_VALUES_SUCCESS";
export const UPDATE_EMAIL_TEMPLATE_VALUES_FAILURE = "UPDATE_EMAIL_TEMPLATE_VALUES_FAILURE";

export const UPDATE_EMAIL_TEMPLATE_ADS_BANNER_REQUEST = "UPDATE_EMAIL_TEMPLATE_ADS_BANNER_REQUEST";
export const UPDATE_EMAIL_TEMPLATE_ADS_BANNER_SUCCESS = "UPDATE_EMAIL_TEMPLATE_ADS_BANNER_SUCCESS";
export const UPDATE_EMAIL_TEMPLATE_ADS_BANNER_FAILURE = "UPDATE_EMAIL_TEMPLATE_ADS_BANNER_FAILURE";

export const RESET_EMAIL_TEMPLATE_VALUES = "RESET_EMAIL_TEMPLATE_VALUES";
export const resetEmailTemplateValues = makeActionCreator(RESET_EMAIL_TEMPLATE_VALUES, "payload");

export const SET_EMAIL_TEMPLATE_FIELD = "SET_EMAIL_TEMPLATE_FIELD";
type AllowedEmailTemplateReducerFields = "subjectLine" | "customArea1" | "customArea2" | "customArea3" | "hideAdvertisement" | "advertisementLink" | "overrideAdvert" | "sendToCompanyAlertEmail" | "sendToProductAlertEmail" | "sendToPlanAlertEmail" | "errorMessage";
export const setEmailTemplateField = makeGenericActionCreator<AllowedEmailTemplateReducerFields>(SET_EMAIL_TEMPLATE_FIELD);

export const getEmailTemplateValues = function(companyId: number, companyName: string, emailType: EmailTypes) {
  const getEmailTemplateConfigRequest = new GetEmailConfigRequest();
  getEmailTemplateConfigRequest.setCompanyId(companyId);
  getEmailTemplateConfigRequest.setCompanyName(companyName);
  //const typedEmailString = emailType as keyof typeof EmailTypesMap;
  //getEmailTemplateConfigRequest.setEmailType(EmailTypesMap[typedEmailString] as any);
  getEmailTemplateConfigRequest.setEmailType(emailType);
  const emailConfigServiceClient = new EmailConfigServiceClient(ConfigConstants.grpcBaseUrl);
  return {
    types: [
      FETCH_EMAIL_TEMPLATE_VALUES_REQUEST,
      FETCH_EMAIL_TEMPLATE_VALUES_SUCCESS,
      FETCH_EMAIL_TEMPLATE_VALUES_FAILURE
    ],
    callAPI: () => grpcUnaryCall(getEmailTemplateConfigRequest, emailConfigServiceClient, emailConfigServiceClient.getEmailConfig)
  };
}

export const updateEmailTemplateValues = function(companyId: number, data: IEmailCustomizationReducerState) {
  const upsertEmailConfigRequest = new UpsertEmailConfigRequest();  
  //const typedEmailString = (data.emailType as string) as keyof typeof EmailTypesMap;
  //upsertEmailConfigRequest.setEmailType(EmailTypesMap[typedEmailString] as any);
  upsertEmailConfigRequest.setCompanyId(companyId);
  upsertEmailConfigRequest.setEmailType(data.emailType as EmailTypes);
  upsertEmailConfigRequest.setCultureCode(data.cultureCode);
  upsertEmailConfigRequest.setSubjectLine(data.subjectLine);
  upsertEmailConfigRequest.setCustomArea1(data.customArea1);
  upsertEmailConfigRequest.setCustomArea2(data.customArea2);
  upsertEmailConfigRequest.setCustomArea3(data.customArea3);
  upsertEmailConfigRequest.setAdvertisementImageUrl(data.advertisementImageUrl);
  upsertEmailConfigRequest.setSendToCompanyAlertEmail(data.sendToCompanyAlertEmail);
  upsertEmailConfigRequest.setSendToProductAlertEmail(data.sendToProductAlertEmail);
  upsertEmailConfigRequest.setSendToPlanAlertEmail(data.sendToPlanAlertEmail);
  upsertEmailConfigRequest.setHideAdvertisement(data.hideAdvertisement);
  upsertEmailConfigRequest.setAdvertisementUrl(data.advertisementLink);
  upsertEmailConfigRequest.setOverrideAdvert(data.overrideAdvert);
  const emailConfigServiceClient = new EmailConfigServiceClient(ConfigConstants.grpcBaseUrl);
  return {
    types: [
      UPDATE_EMAIL_TEMPLATE_VALUES_REQUEST,
      UPDATE_EMAIL_TEMPLATE_VALUES_SUCCESS,
      UPDATE_EMAIL_TEMPLATE_VALUES_FAILURE
    ],
    callAPI: () => grpcUnaryCall(upsertEmailConfigRequest, emailConfigServiceClient, emailConfigServiceClient.upsertEmailConfig)
  };
}


export const updateAdsBanner = function (companyDomain: string, file: File) {
  return {
    types: [UPDATE_EMAIL_TEMPLATE_ADS_BANNER_REQUEST, UPDATE_EMAIL_TEMPLATE_ADS_BANNER_SUCCESS, UPDATE_EMAIL_TEMPLATE_ADS_BANNER_FAILURE],
    callAPI: () => API.uploadCompanyImage(companyDomain, file)
  }
}