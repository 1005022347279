import React from "react";
import Panel from "../../components/ui/panel/Panel";
import Text from "../../components/ui/text/Text";
import "./CardDetails.scss";
import Button from "../../components/ui/button/Button";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../..";
import { setCustomerDetailsField } from "../../actions/customerDetailsActions";
import moment from "moment";
import { CardStatus } from "../../models/Customer";
import { isCardExpired, getCardLogo, getCardText } from "../../utils/commonUtils";
import { Permission } from "../../models/Auth";
import useCheckFeaturePermission from "../../utils/hooks/useCheckFeaturePermission";

const CardDetails: React.FC = () => {
  const dispatch = useDispatch();
  const { customerDetails } = useSelector((state: AppState) => ({ customerDetails: state.customerDetailsReducer }));
  const isCustomerReplaceCardPermitted = useCheckFeaturePermission(Permission.CustomerReplaceCard);

  const {
    cardDetails: { lastFourDigits, cardType, expiryMonth, expiryYear, fullName }, cardStatus
  } = customerDetails;

  const formattedDate = moment().utc().month(expiryMonth - 1).year(expiryYear).format("MMM YYYY");
  const isExpired = isCardExpired(expiryMonth, expiryYear);

  if(cardStatus === CardStatus.MISSING || !cardType) {
    return null
  }

  return (
    <div className="card-details">
      <Panel className="card-details__panel">
        <Button
          className="card-details__button"
          id="replace-card"
          icon="fas fa-retweet"
          buttonType="add"
          disabled={!isCustomerReplaceCardPermitted}
          tooltipMessage={!isCustomerReplaceCardPermitted ? "NO_ACCESS_MSG" : undefined}
          title="CARD_DETAILS_BUTTON_TITLE"
          onClick={() => dispatch(setCustomerDetailsField("showReplacePaymentModal", true))}
        ></Button>
        <i className={`card-details__card ${getCardLogo(cardType)}`}></i>
        <div className="card-details__content">
          <Text className="card-details__content-title" content="CARD_DETAILS_CONTENT_TITLE" translateWith={{ cardType: <span>{getCardText(cardType.replace("_", " "))}</span>, lastFourDigits }}></Text>
          <div className={`card-details__content-details${isExpired ? " card-details__content-details--error" : ""}`}>
            <Text className="card-details__content-details-label" content="CARD_DETAILS_EXPIRY_DATE" noMargin></Text>
            <Text className="card-details__content-details-value" shouldTranslate={false} content={formattedDate} noMargin></Text>
          </div>

          {/* TODO: Add card name */}
          <div className="card-details__content-details">
            <Text className="card-details__content-details-label" content="CARD_DETAILS_NAME" noMargin></Text>
            <Text className="card-details__content-details-value" shouldTranslate={false} content={fullName} noMargin></Text>
          </div>
        </div>
      </Panel>
    </div>
  );
};

export default CardDetails;
