import React from "react";
import Button from "../../../../../ui/button/Button";
import Panel from "../../../../../ui/panel/Panel";
import Input from "../../../../../ui/input/Input";
import "./EmailSubject.scss";

interface Props {
  title: string,
  value: string,
  buttonText: string,
  id: string,
  noMargin?: boolean,
  isLoading?: boolean,
  onChange: (evt: any) => any,
  onSubmit: () => void
}

export const EmailSubject: React.FC<Props> = ({title = "", value = "", buttonText = "", noMargin = false, isLoading = false, id = "", onSubmit = () => { }, onChange = () => { }}) => {
  return (
    <Panel title={title} shouldTranslate={false} noMargin={noMargin}>
      <Input id={`subject-${id}`} value={value} onChange={onChange} className="email-subject-input-field"/>
      <Button className="email-subject-input-submit"
        id={`btn-${id}`}
        title={buttonText}
        shouldTranslate={false}
        buttonType="add"
        onClick={onSubmit}
        isLoading={isLoading}
      />
    </Panel>
  )
}

export default EmailSubject;
