import React, { useEffect, useState } from "react"
import StepCollapsablePanel from "../../../ui/step-collapsable-panel/StepCollapsablePanel";
import logo from "../../../../images/integrations/quickbooks/logo.png";
import Text from "../../../ui/text/Text";
import Button from "../../../ui/button/Button";
import Panel from "../../../ui/panel/Panel";
import FormGroup from "../../../ui/form-group/FormGroup";
import { Col, Row } from "react-grid-system";
import FormLabel from "../../../ui/form-label/FormLabel";
import Dropdown from "../../../ui/dropdown/Dropdown";
import "./QuickBooksOnlineIntegration.scss";
import { UpdateCompanyQuickBooksAccountsRequest } from "../../../../utils/grpc/generated/Billsby.Protos/accountingIntegrations/private/accounts/accounts_pb";
import { IAuthReducerState } from "../../../../reducers/authReducer";
import { AccountingIntegrationServiceClient } from "../../../../utils/grpc/generated/Billsby.Protos/accountingIntegrations/private/accounts/AccountsServiceClientPb";
import { ConfigConstants } from "../../../../utils/config";
import { grpcUnaryCall } from "../../../../utils/grpc/grpcUtils";
import { StringValue } from "google-protobuf/google/protobuf/wrappers_pb";
import { IAccountingIntegrationsReducerState } from "../../../../reducers/accountingIntegrationsReducer";
import { setAccountingIntegrationsField } from "../../../../actions/accountingIntegrationsActions";
import NoticePanel from "../../../ui/notice-panel/NoticePanel";
import Checkbox from "../../../ui/checkbox/Checkbox";
import history from "../../../../utils/history";
import counterpart from "counterpart";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../../../..";

interface IQuickBooksOnlineIntegration {
  setupDone: () => void
}

export const QuickBooksOnlineIntegration: React.FC<IQuickBooksOnlineIntegration> = ({ setupDone }) => {
  const authData = useSelector<AppState, IAuthReducerState>(state => state.auth)
  const { invoicesOptions, creditNotesOptions, refundsOptions,
    selectedInvoice, selectedCreditNote, selectedRefund } = useSelector<AppState, IAccountingIntegrationsReducerState>(state => state.accountingIntegrationsReducer);
  const dispatch = useDispatch<Function>()

  const [warningMessage, setWarningMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [firstWarningIsCheck, setfirstWarningIsCheck] = useState(false);
  const [secondWarningIsCheck, setSecondWarningIsCheck] = useState(false);
  const [thirdWarningIsCheck, setThirdWarningIsCheck] = useState(false);
  const [fourthWarningIsCheck, setFourthWarningIsCheck] = useState(false);

  useEffect(() => {
    dispatch(setAccountingIntegrationsField("isQuickbooksUnderSetup", true));
  }, [])

  const createRequestInput = () => {
    const requestInput = new UpdateCompanyQuickBooksAccountsRequest();
    requestInput.setCompanyId(Number(authData.currentCompanyId))
    const invoiceInput = new StringValue();
    invoiceInput.setValue(!!selectedInvoice ? selectedInvoice.value : "");
    requestInput.setInvoicesAccountId(invoiceInput);

    const creditNoteInput = new StringValue();
    creditNoteInput.setValue(!!selectedCreditNote ? selectedCreditNote.value : "");
    requestInput.setCreditNotesAccountId(creditNoteInput);

    const refundInput = new StringValue();
    refundInput.setValue(!!selectedRefund ? selectedRefund.value : "");
    requestInput.setRefundsAccountId(refundInput);

    return requestInput;
  }

  const updateQuickBooksAccounts = async () => {
    const requestInput = createRequestInput();
    const accountingIntegrationServiceClient = new AccountingIntegrationServiceClient(ConfigConstants.grpcBaseUrl);
    setIsLoading(true);

    try {
      await grpcUnaryCall(requestInput, accountingIntegrationServiceClient, accountingIntegrationServiceClient.updateCompanyQuickBooksAccounts)
      setupDone();
      dispatch(setAccountingIntegrationsField("isQuickbooksUnderSetup", false))
      history.push("/integrations/quickbooks/faq");
    } finally {
      setIsLoading(false);
    }
  }

  return (
    <div>
      {!!warningMessage && <NoticePanel
        type="warning"
        isModal
        content={warningMessage}
      />}
      <StepCollapsablePanel title={
        <div className="integrations__title">
          <div>
            <img src={logo} alt="no img" />
            <div>
              <Text content="INTEGRATIONS_QUICKBOOKS_PANEL1_TITLE" noMargin />
            </div>
          </div>
          <div>
            <Button
              id="visit-website"
              title="INTEGRATIONS_VIEW_WEBSITE"
              onClick={(evt: any) => {
                evt.stopPropagation();
                window.open("https://quickbooks.intuit.com/", "_self");
              }}
              buttonType="add"
            />
          </div>
        </div>
      } className="integrations__free-agent"
        shouldTranslateTitle={false}
        hasHelpSection={false}
        isCollapsed={false}
      >
        <Text content="INTEGRATION_QUICKBOOKS_ONLINE_INTEGRATION_MODAL_TEXT" noMargin />
      </StepCollapsablePanel>
      <Panel>
        <FormGroup>
          <Row>
            <Col xs={2}>
              <FormLabel target="" content="INTEGRATION_QUICKBOOKS_ONLINE_INVOICES" className="quick-books-online-integration__form-label" />
            </Col>
            <Col xs={10}>
              <Dropdown
                placeholder={counterpart("INTEGRATIONS_QUICKBOOKS_DROPDWON_PLACEHOLDER")}
                id="quick-books-online-integration-dropdown-invoices"
                value={selectedInvoice}
                onChange={(selectedItem: any) => {
                  dispatch(setAccountingIntegrationsField("selectedInvoice", selectedItem));
                  setWarningMessage(!selectedItem.value ? "INTEGRATION_QUICKBOOKS_INVOICE_CREATE_NEW" : "")
                }}
                options={invoicesOptions}
              />
            </Col>
          </Row>
        </FormGroup>
        <FormGroup>
          <Row>
            <Col xs={2}>
              <FormLabel target="" content="INTEGRATION_QUICKBOOKS_ONLINE_CREDIT_NOTES" className="quick-books-online-integration__form-label" />
            </Col>
            <Col xs={10}>
              <Dropdown
                placeholder={counterpart("INTEGRATIONS_QUICKBOOKS_DROPDWON_PLACEHOLDER")}
                id="quick-books-online-integration-dropdown-credit-notes"
                value={selectedCreditNote}
                onChange={(selectedItem: any) => {
                  dispatch(setAccountingIntegrationsField("selectedCreditNote", selectedItem));
                  setWarningMessage(!selectedItem.value ? "INTEGRATION_QUICKBOOKS_CREDIT_NOTE_CREATE_NEW" : "");
                }}
                options={creditNotesOptions}
              />
            </Col>
          </Row>
        </FormGroup>
        <FormGroup>
          <Row>
            <Col xs={2}>
              <FormLabel target="" content="INTEGRATION_QUICKBOOKS_ONLINE_REFUNDS" className="quick-books-online-integration__form-label" />
            </Col>
            <Col xs={10}>
              <Dropdown
                placeholder={counterpart("INTEGRATIONS_QUICKBOOKS_DROPDWON_PLACEHOLDER")}
                id="quick-books-online-integration-dropdown-refunds"
                value={selectedRefund}
                onChange={(selectedItem: any) => {
                  dispatch(setAccountingIntegrationsField("selectedRefund", selectedItem));
                  setWarningMessage(!selectedItem.value ? "INTEGRATION_QUICKBOOKS_REFUND_CREATE_NEW" : "");
                }}
                options={refundsOptions}
              />
            </Col>
          </Row>
        </FormGroup>
      </Panel>
      <Panel title="QUICKBOOKS_ONLINE_INTEGRATION_BEFORE_INTEGRATE">
        <FormGroup>
          <Row>
            <Col xs={1}>
              <Checkbox checked={firstWarningIsCheck} onClick={() => setfirstWarningIsCheck(!firstWarningIsCheck)} value="" content="" />
            </Col>
            <Col xs={11}>
              <Text content="QUICKBOOKS_ONLINE_INTEGRATION_BEFORE_INTEGRATE_TEXT1" noMargin />
            </Col>
          </Row>
        </FormGroup>
        <FormGroup>
          <Row>
            <Col xs={1}>
              <Checkbox checked={secondWarningIsCheck} onClick={() => setSecondWarningIsCheck(!secondWarningIsCheck)} value="" content="" />
            </Col>
            <Col xs={11}>
              <Text content="QUICKBOOKS_ONLINE_INTEGRATION_BEFORE_INTEGRATE_TEXT2" noMargin />
            </Col>
          </Row>
        </FormGroup>
        <FormGroup>
          <Row>
            <Col xs={1}>
              <Checkbox checked={thirdWarningIsCheck} onClick={() => setThirdWarningIsCheck(!thirdWarningIsCheck)} value="" content="" />
            </Col>
            <Col xs={11}>
              <Text content="QUICKBOOKS_ONLINE_INTEGRATION_BEFORE_INTEGRATE_TEXT3" noMargin />
            </Col>
          </Row>
        </FormGroup>
        <FormGroup noMargin>
          <Row>
            <Col xs={1}>
              <Checkbox checked={fourthWarningIsCheck} onClick={() => setFourthWarningIsCheck(!fourthWarningIsCheck)} value="" content="" />
            </Col>
            <Col xs={11}>
              <Text content="QUICKBOOKS_ONLINE_INTEGRATION_BEFORE_INTEGRATE_TEXT4" noMargin />
            </Col>
          </Row>
        </FormGroup>
      </Panel>
      <Button
        id="quick-books-online-integration-submit"
        type="submit"
        isLoading={isLoading}
        disabled={!firstWarningIsCheck || !secondWarningIsCheck || !thirdWarningIsCheck || !fourthWarningIsCheck || isLoading}
        buttonType={"general"}
        onClick={() => updateQuickBooksAccounts()}
        title="INTEGRATION_QUICKBOOKS_ONLINE_SUBMIT_BUTTON"
        isFullWidth
      />
    </div>
  )
}

export default QuickBooksOnlineIntegration;
