import React from "react";

import "./PageWrapper.scss";

interface IPageWrapperProps {
  className?: string,
  children: any
}

const PageWrapper: React.FC<IPageWrapperProps> = React.memo(({ className = "", children }) => { 
  return (
    <div className={`page-wrapper ${className}`}>
      {children}
    </div>
  )
})

export default PageWrapper;