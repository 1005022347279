import React from "react";
import Panel from "../../ui/panel/Panel";
import Text from "../../ui/text/Text";
import { Row, Col } from "react-grid-system";
import Switch from "../../ui/switch/Switch";

interface ITaxExemption {
  onChangeTaxExemption: () => void,
  customerTaxExemption: boolean
}

const TaxExemption: React.FC<ITaxExemption> = ({ customerTaxExemption, onChangeTaxExemption }) => {
  return (
    <Panel title="CONFIG_CUSTOMER_TAX_EXEMPTION_TITLE">
      <Text content="CONFIG_CUSTOMER_TAX_EXEMPTION_CONTENT" />
      <Row align="center">
        <Col md={1}>
          <Switch id="tax-exemption" checked={customerTaxExemption} onChange={onChangeTaxExemption} />
        </Col>
        <Col md={11}>
          <Text component="span" content="CONFIG_CUSTOMER_TAX_EXEMPTION_SWITCH" />
        </Col>
      </Row>
    </Panel>
  )
}

export default TaxExemption;