/**
 * @fileoverview gRPC-Web generated client stub for revenues.private.company
 * @enhanceable
 * @public
 */

// GENERATED CODE -- DO NOT EDIT!


import * as grpcWeb from "grpc-web";


import {
  GeTrialBalanceResponse,
  GetMonthsRevenueRequest,
  GetMonthsRevenueResponse,
  GetTrialBalanceRequest} from "./company_pb";

export class CompanyServiceClient {
  client_: grpcWeb.AbstractClientBase;
  hostname_: string;
  credentials_: null | { [index: string]: string; };
  options_: null | { [index: string]: string; };

  constructor (hostname: string,
               credentials?: null | { [index: string]: string; },
               options?: null | { [index: string]: string; }) {
    if (!options) options = {};
    if (!credentials) credentials = {};
    options["format"] = "text";

    this.client_ = new grpcWeb.GrpcWebClientBase(options);
    this.hostname_ = hostname;
    this.credentials_ = credentials;
    this.options_ = options;
  }

  methodInfoGetMonthsRevenue = new grpcWeb.AbstractClientBase.MethodInfo(
    GetMonthsRevenueResponse,
    (request: GetMonthsRevenueRequest) => {
      return request.serializeBinary();
    },
    GetMonthsRevenueResponse.deserializeBinary
  );

  getMonthsRevenue(
    request: GetMonthsRevenueRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: GetMonthsRevenueResponse) => void) {
    return this.client_.rpcCall(
      this.hostname_ +
        "/revenues.private.company.CompanyService/GetMonthsRevenue",
      request,
      metadata || {},
      this.methodInfoGetMonthsRevenue,
      callback);
  }

  methodInfoGetTrialBalance = new grpcWeb.AbstractClientBase.MethodInfo(
    GeTrialBalanceResponse,
    (request: GetTrialBalanceRequest) => {
      return request.serializeBinary();
    },
    GeTrialBalanceResponse.deserializeBinary
  );

  getTrialBalance(
    request: GetTrialBalanceRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: GeTrialBalanceResponse) => void) {
    return this.client_.rpcCall(
      this.hostname_ +
        "/revenues.private.company.CompanyService/GetTrialBalance",
      request,
      metadata || {},
      this.methodInfoGetTrialBalance,
      callback);
  }

}

