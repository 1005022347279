import React from "react";
import "./CheckoutTextArea.scss";

interface ICheckoutTextArea {
  placeholder?: string,
  rows?: number,
  maxlength?: number,
  required?: boolean,
  value?: string,
  onChange?: (evt: any) => any,
  name?: string,
  id: string,
  className?: string
}

const CheckoutTextArea: React.FC<ICheckoutTextArea> = (props) => {
  const { placeholder = "", rows = 5, maxlength = 200, required = false, value, onChange = () => { }, name = "", id, className = "" } = props;

  return (
    <textarea
      placeholder={placeholder}
      rows={rows}
      maxLength={maxlength}
      required={required}
      className={`checkout-textarea ${className}`}
      value={value}
      onChange={onChange}
      name={name}
      id={id}
    />
  );
}

export default CheckoutTextArea;
