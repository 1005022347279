import React from "react"
import CircleIcon from "../../../../../ui/circle-icon/CirlcleIcon";
import "./CheckoutRectanglePanel.scss";

interface ICheckoutRectanglePanelProps {
  /**
   * The elements which will be wrapped by the panel
   */
  children: any,
  /**
   * Allow custom styling
   */
  className?: string,
  /**
   * add inline style
   */
  style?: object,
  /**
   * show close button
   */
  showCloseButton?: boolean,
  /**
   * close button color
   */
  closeButtonColor?: string,
  /**
   * callback on click on the panel container
   */
  onCloseButtonClick?: () => void
}

const  CheckoutRectanglePanel: React.FC<ICheckoutRectanglePanelProps> = ({ children = null, className = "", style = {}, closeButtonColor = "#F87847", showCloseButton = false, onCloseButtonClick = () => { } }) => {
  return (
    <div className={`rectangle-panel ${className}`} style={style}>
      {showCloseButton && <CircleIcon className="rectangle-panel__closeBtn" style={{ backgroundColor: closeButtonColor }} onClick={onCloseButtonClick}/>}
      {children}
    </div>
  )
}

export default  CheckoutRectanglePanel

