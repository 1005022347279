import React, { useEffect } from "react";
import "./InputTags.scss";
import ReactTags from "react-tag-autocomplete";
import { formattedFeatureTag } from "../../../utils/commonUtils";

interface IInputTags {
  tags: Array<any>,
  handleDelete?: (tagIndex: number) => void,
  handleAddition?: (tag: any) => void,
  tabIndex?: number,
  onFocus?: () => void,
  placeholder?: string
}

const InputTags: React.FC<IInputTags> = React.memo((props) => {
  const {
    tags,
    handleDelete = (tagIndex: number) => { },
    handleAddition = (tag: any) => { },
    tabIndex,
    onFocus = () => { },
    placeholder = undefined
  } = props;


  const onInputChange = (evt: any) => {
    evt.target.value = formattedFeatureTag(evt.target.value)
  }

  useEffect(() => {
    const inputEl: HTMLInputElement | null = document.querySelector(".react-tags__search-input > input");
    
    if (inputEl) {
      inputEl.addEventListener("input", onInputChange)
      return () => inputEl.removeEventListener("input", onInputChange)
    }
  }, []);


  return (
    <ReactTags
      placeholder={placeholder}
      tags={tags}
      delimiters={[]}
      delimiterChars={[",", " ", ";", "."]}
      allowNew={true}
      handleDelete={handleDelete}
      handleAddition={handleAddition}
      tabIndex={tabIndex}
      onFocus={onFocus}
      // disable the input autofocus prop otherwise the page will scroll down to to this component automatically! 
      autofocus={false}
    />
  )
})

export default InputTags;