import React, { useEffect, useState } from "react"
import StepCollapsablePanel from "../../../ui/step-collapsable-panel/StepCollapsablePanel";
import logo from "../../../../images/integrations/free-agent-logo.png";
import Text from "../../../ui/text/Text";
import Button from "../../../ui/button/Button";
import { IAuthReducerState } from "../../../../reducers/authReducer";
import { IAccountingIntegrationsReducerState } from "../../../../reducers/accountingIntegrationsReducer";
import { AccountingIntegrationType } from "../../../../models/AccountingIntegrations";
import { IntegrationType } from "../../../../utils/grpc/generated/Billsby.Protos/accountingIntegrations/private/accounts/accounts_pb";
import { deleteAccountingIntegration, setAccountingIntegrationsField } from "../../../../actions/accountingIntegrationsActions";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../../../..";

interface IUninstallFreeAgent {
  onUninstall: () => void
}

export const UninstallFreeAgent: React.FC<IUninstallFreeAgent> = ({ onUninstall }) => {
  const authData = useSelector<AppState, IAuthReducerState>(state => state.auth)
  const accountingIntegrations = useSelector<AppState, IAccountingIntegrationsReducerState>(state => state.accountingIntegrationsReducer)
  const dispatch = useDispatch<Function>()
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    dispatch(setAccountingIntegrationsField("isCreatingIntegrationFailure", false));
    dispatch(setAccountingIntegrationsField("isCreatingIntegrationSuccess", false));
  }, [])

  return (
    <div>
      <StepCollapsablePanel title={
        <div className="free-agent-integrations__title">
          <div>
            <img src={logo} alt="no img" />
            <div>
              <Text content="INTEGRATION_UNINSTALL_FREE_AGENT" noMargin />
            </div>
          </div>
        </div>
      } className="free-agent-integrations__free-agent"
        shouldTranslateTitle={false}
        hasHelpSection={false}
        isCollapsed={false}
      >
        <Text content="INTEGRATION_UNINSTALL_FREE_AGENT_TEXT" noMargin />
      </StepCollapsablePanel>

      <Button
        id="free-agent-uninstall-btn"
        type="button"
        buttonType={"general"}
        disabled={isLoading}
        onClick={async () => {
          setIsLoading(true);
          const isFreeAgentInstalled = accountingIntegrations.integrations.getIntegrationsList()
            .some(i => (i.getName() as AccountingIntegrationType) === AccountingIntegrationType.FREEAGENT && i.getIsInstalled());
          if (isFreeAgentInstalled) {
            await dispatch(deleteAccountingIntegration(Number(authData.currentCompanyId), IntegrationType.FREE_AGENT));
            setIsLoading(false);
            onUninstall();
          }
        }}
        title="INTEGRATION_UNINSTALL_FREE_AGENT_BUTTON"
        isFullWidth
      />
    </div>
  )
}

export default UninstallFreeAgent;
