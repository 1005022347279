import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../../..";
import Panel from "../../ui/panel/Panel";
import Text from "../../ui/text/Text";
import Button from "../../ui/button/Button";
import { createCustomerNote, deleteCustomerNote, fetchCustomerNotes, updateCustomerNote } from "../../../actions/customerDetailsActions";
import Search from "../../search/Search";
import NotePanel from "./note-panel/NotePanel";
import "./CustomerNotes.scss";
import FormGroup from "../../ui/form-group/FormGroup";
import { Col, Row } from "react-grid-system";
import Input from "../../ui/input/Input";
import counterpart from "counterpart";
import Pagination from "../../ui/pagination/Pagination";
import { ICustomerNote } from "../../../models/Customer";
import ButtonLoader from "../../ui/button/ButtonLoader";
import ErrorNotification from "../../ui/error-notification/ErrorNotification";
import ProgressIndicator from "../../ui/progress-indicator/ProgressIndicator";
import { Permission } from "../../../models/Auth";
import useCheckFeaturePermission from "../../../utils/hooks/useCheckFeaturePermission";


const CustomerNotes: React.FC = () => {
  const [customerNote, setCustomerNote] = useState<ICustomerNote>({ id: undefined, subject: "", content: "" });
  const [noteFormIsVisible, setNoteFormIsVisible] = useState(false);
  const [isSubjectIsEmpty, setIsSubjectIsEmpty] = useState(false);
  const [isContentIsEmpty, setIsContentIsEmpty] = useState(false);
  const [isSavingNote, setIsSavingNote] = useState(false);
  const [isDeletingNote, setIsDeletingNote] = useState(false);
  const [searchedNotes, setSearchedNotes] = useState("");
  const [showError, setShowError] = useState(false);
  const [isFetchingNotes, setIsFetchingNotes] = useState(false);

  const authData = useSelector((state: AppState) => state.auth);
  const customerDetailsData = useSelector((state: AppState) => state.customerDetailsReducer);
  const isCustomerAddNoteEnabled = useCheckFeaturePermission(Permission.CustomerAddNote);

  const dispatch = useDispatch<Function>();

  const pageSize = 5;

  const { currentCompanyId } = authData;
  const { customerUniqueId, customerNotes } = customerDetailsData;

  const clearCustomerNote = () => {
    setCustomerNote({ id: undefined, subject: "", content: "" });
    setNoteFormIsVisible(false);
    setIsSubjectIsEmpty(false);
    setIsContentIsEmpty(false);
  }

  const fetchNotes = async (page?: number) => {
    try {
      setIsFetchingNotes(true);
      await dispatch(fetchCustomerNotes(Number(currentCompanyId), customerUniqueId, page || 1, pageSize, searchedNotes || ""));
      setIsFetchingNotes(false);
    }
    catch {
      setIsFetchingNotes(false);
      setShowError(true);
    }
  }

  const clearForm = () => {
    fetchNotes();
    clearCustomerNote();
  }

  const saveCustomerNote = async () => {
    setIsSubjectIsEmpty(!customerNote.subject);
    setIsContentIsEmpty(!customerNote.content);

    if (!customerNote.subject || !customerNote.content)
      return;

    setIsSavingNote(true);

    try {
      if (!!customerNote.id) {
        await dispatch(updateCustomerNote(Number(currentCompanyId), customerUniqueId, customerNote.id, customerNote.subject, customerNote.content));
        setIsSavingNote(false);
        clearForm();
      } else {
        await dispatch(createCustomerNote(Number(currentCompanyId), customerUniqueId, customerNote.subject, customerNote.content));
        clearForm();
        setIsSavingNote(false);
      }
    } catch {
      setIsSavingNote(false);
      setShowError(true);
    }
  }

  const deleteNote = async (customerNoteId: number) => {
    setIsDeletingNote(true);
    try {
      await dispatch(deleteCustomerNote(Number(currentCompanyId), customerUniqueId, customerNoteId));
      await fetchNotes();
      setIsDeletingNote(false);
    }
    catch {
      setShowError(true);
      setIsDeletingNote(false);
    }
  }

  useEffect(() => {
    fetchNotes();
  }, [])

  return (
    <>
      <div className="customers-notes__header">
        <div className="customers-notes__search">
          <Text content="CUSTOMER_NOTES_NUMBER" translateWith={{ notesNumber: customerNotes.getRowCount() }} noMargin />
          <Search
            className="customers-notes__search-bar"
            value={searchedNotes}
            placeholder="CUSTOMER_NOTES_SEARCH_PLACEHOLDER"
            onChange={(evt: any) => setSearchedNotes(evt.target.value)}
            onSearch={() => fetchNotes()}
          />
          <Pagination
            className="customers__search__pagination"
            pageCount={customerNotes.getPageCount()}
            isVisible={customerNotes.getPageCount() > 1}
            forcePage={customerNotes.getCurrentPage() - 1}
            onPageChange={({ selected }) => fetchNotes(selected + 1)}
          />
          <div className="customers-notes__add-btn__container">
            <Button
              id="customers-note-add"
              className="customers-notes__add-btn"
              title="CUSTOMER_NOTES_ADD_A_NEW_NOTE"
              disabled={noteFormIsVisible || !isCustomerAddNoteEnabled}
              tooltipMessage={!isCustomerAddNoteEnabled ? "NO_ACCESS_MSG" : undefined}
              onClick={() => setNoteFormIsVisible(true)}
              buttonType="add"
              icon="far fa-sticky-note" />
          </div>
        </div>
      </div>
      {noteFormIsVisible &&
        <Panel>
          <FormGroup>
            <Row align="center">
              <Col md={8}>
                <Input
                  id="customer-notes-subject-line"
                  value={customerNote.subject}
                  onChange={(evt: any) => setCustomerNote({ ...customerNote, subject: evt.target.value })}
                  placeholder={counterpart("CUSTOMER_NOTES_SUBJECT_LINE_PLACEHOLDER")}
                  className={isSubjectIsEmpty ? "customers-notes__input is-empty" : ""}
                />
              </Col>
              <Col md={4}>
              </Col>
            </Row>
          </FormGroup>
          <FormGroup>
            <Row align="center">
              <Col md={12}>
                <Input
                  id="customer-notes-content"
                  value={customerNote.content}
                  onChange={(evt: any) => setCustomerNote({ ...customerNote, content: evt.target.value })}
                  placeholder={counterpart("CUSTOMER_NOTES_SUMMARY_PLACEHOLDER")}
                  className={isContentIsEmpty ? "customers-notes__input is-empty" : ""}
                />
              </Col>
            </Row>
          </FormGroup>
          <Row>
            <Col md={12}>
              <div className="customers-notes__form-actions">
                <div onClick={() => clearCustomerNote()} id="notes-cancel-btn">Cancel</div>
                <div onClick={() => !isSavingNote && saveCustomerNote()} id="notes-save-btn">
                  {
                    isSavingNote ? <ButtonLoader color="blue" /> : <>Save Note</>
                  }
                </div>
              </div>
            </Col>
          </Row>
        </Panel>
      }
      {
        isFetchingNotes && <ProgressIndicator color="blue" />
      }
      {!isFetchingNotes &&
        customerNotes.getResultsList().map(note =>
          <NotePanel
            disabled={noteFormIsVisible || !isCustomerAddNoteEnabled}
            tooltipMessage={!isCustomerAddNoteEnabled ? "NO_ACCESS_MSG" : undefined}
            key={`note-${note.getCustomerNoteId()}`}
            id={note.getCustomerNoteId()}
            subject={note.getSubjectLine()}
            content={note.getContent()}
            dateCreated={note.getUpdatedOn()?.toDate()}
            onDelete={(customerNoteId: number) => deleteNote(customerNoteId)}
            isDeletingNote={isDeletingNote}
            onUpdate={(customerNoteToUpdate: ICustomerNote) => {
              setCustomerNote(customerNoteToUpdate);
              setNoteFormIsVisible(true);
            }}
          />
        )
      }
      <ErrorNotification title="GENERIC_ERROR" showAlert={showError} onClose={() => setShowError(false)} />
    </>
  )
}

export default CustomerNotes;