import { ConfigConstants } from "./config";
import { fetchGet, fetchPost, fetchPut, fetchDelete, fetchPatch, externalGet, externalPost } from "./fetchUtils";
import { logout, refreshToken } from "./authUtils";
import history from "./history";
import { BILLSBY_AUTH_DATA_CONTROL, BILLSBY_SELECTED_COMPANY } from "./constants";
import { IToken } from "../models/Auth";
import { storeGet } from "./storeUtils";
import { reduxStore } from "..";

export function getHeaders() {
  const headers = new Headers();

  headers.append("Content-Type", "application/json");
  headers.append("Accept", "application/json");

  if (storeGet(BILLSBY_AUTH_DATA_CONTROL)) {
    const token = <IToken>storeGet(BILLSBY_AUTH_DATA_CONTROL);
    headers.append("Authorization", "Bearer " + token.access_token);
  }

  return headers;
}

export const getToken = () => <IToken>storeGet(BILLSBY_AUTH_DATA_CONTROL);

export function retryConnection(tries: number) {
  return new Promise((fulfill, reject) => {
    const companyId = reduxStore.getState().auth.currentCompanyId ?? storeGet(BILLSBY_SELECTED_COMPANY) as number
    // Refresh token
    refreshToken(companyId).then(() => {
      // Token refreshed successfully
      fulfill();
    }).catch(() => {
      // If refresh token fails, try again 3 times
      tries++;
      if (tries < 3) {
        retryConnection(tries);
      } else {
        // Do not logout the user when using Safari
        // https://dev.azure.com/airfidev/Billsby/_workitems/edit/11075
        // the auth cookies will be flushed automatically if they're invalid after the redirect from subdomain to maindomain
        const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
        !isSafari && logout();
        history.push("/");
      }
    });
  });
}

function getFileUploadHeaders() {
  const headers = new Headers();

  if (storeGet(BILLSBY_AUTH_DATA_CONTROL)) {
    const token = <IToken>storeGet(BILLSBY_AUTH_DATA_CONTROL);
    headers.append("Authorization", "Bearer " + token.access_token);
  }

  return headers;
}

function getExternalHeaders() {
  const createHeaders = {
    "Content-Type": "application/json",
    "Accept": "application/json",
  };

  const headers = new Headers(createHeaders);

  return headers;
}

export function apiGetExternal(req: string) {
  return externalGet(req, getExternalHeaders());
}

export function apiPostExternal(req: string, data: object) {
  return externalPost(req, getExternalHeaders(), JSON.stringify(data));
}

export function apiGet(request: string) {
  return new Promise((fulfill, reject) => {
    const url = ConfigConstants.baseUrl + request;

    return fetchGet(url, getHeaders())
      .then((response) => {
        fulfill(response);
      }).catch((error) => {
        if (error.status === 401) {
          retryConnection(0).then(() => {
            apiGet(request).then((response) => {
              fulfill(response);
            });
          });
        } else {
          if (error.status === 403) {
            reject(error);
          } else {
            reject(error);
          }
        }
      });
  });
}

export function apiPost(request: string, data: object, isMock?: boolean) {
  return new Promise((fulfill, reject) => {
    const url = ConfigConstants.baseUrl + request;

    return fetchPost(url, getHeaders(), JSON.stringify(data))
      .then((response) => {
        fulfill(response);
      }).catch((error) => {
        if (error.status === 401) {
          retryConnection(0).then(() => {
            apiPost(request, data).then((response) => {
              fulfill(response);
            });
          });
        } else {
          if (error.status === 403) {
            reject(error);
          } else {
            reject(error);
          }
        }
      });
  });
}

export function apiPatch(request: string, data: object) {
  return new Promise((fulfill, reject) => {
    const url = ConfigConstants.baseUrl + request;

    return fetchPatch(url, getHeaders(), JSON.stringify(data))
      .then((response) => {
        fulfill(response);
      }).catch((error) => {
        if (error.status === 401) {
          retryConnection(0).then(() => {
            apiPatch(request, data).then((response) => {
              fulfill(response);
            });
          });
        } else {
          if (error.status === 403) {
            reject(error);
          } else {
            reject(error);
          }
        }
      });
  });
}

export function apiPostFiles(request: string, data: object) {
  return new Promise((fulfill, reject) => {
    const url = ConfigConstants.baseUrl + request;

    return fetchPost(url, getFileUploadHeaders(), data)
      .then((response) => {
        fulfill(response);
      }).catch((error) => {
        if (error.status === 401) {
          retryConnection(0).then(() => {
            apiPostFiles(request, data).then((response) => {
              fulfill(response);
            });
          });
        } else {
          if (error.status === 403) {
            reject(error);
          } else {
            reject(error);
          }
        }
      });
  });
}

export function apiPutFiles(request: string, data: object) {
  return new Promise((fulfill, reject) => {
    const url = ConfigConstants.baseUrl + request;

    return fetchPut(url, getFileUploadHeaders(), data)
      .then((response) => {
        fulfill(response);
      }).catch((error) => {
        if (error.status === 401) {
          retryConnection(0).then(() => {
            apiPutFiles(request, data).then((response) => {
              fulfill(response);
            });
          });
        } else {
          if (error.status === 403) {
            reject(error);
          } else {
            reject(error);
          }
        }
      });
  });
}

export function apiPut(request: string, data: object) {
  return new Promise((fulfill, reject) => {
    const url = ConfigConstants.baseUrl + request;

    return fetchPut(url, getHeaders(), JSON.stringify(data))
      .then((response) => {
        fulfill(response);
      }).catch((error) => {
        if (error.status === 401) {
          retryConnection(0).then(() => {
            apiPut(request, data).then((response) => {
              fulfill(response);
            });
          });
        } else {
          if (error.status === 403) {
            reject(error);
          } else {
            reject(error);
          }
        }
      });
  });
}

export function apiDelete(request: string) {
  return new Promise((fulfill, reject) => {
    const url = ConfigConstants.baseUrl + request;

    return fetchDelete(url, getHeaders())
      .then((response) => {
        fulfill(response);
      }).catch((error) => {
        if (error.status === 401) {
          retryConnection(0).then(() => {
            apiDelete(request).then((response) => {
              fulfill(response);
            });
          });
        } else {
          reject(error);
        }
      });
  });
}

export function parseJSON(response: any) {
  return response.json();
}