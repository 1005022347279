import React, { useState, useEffect, useRef } from "react";
import FormGroup from "../ui/form-group/FormGroup";
import FormLabel from "../ui/form-label/FormLabel";
import Input from "../ui/input/Input";
import { Row, Col } from "react-grid-system";
import counterpart from "counterpart";
import API from "../../utils/API";
import { ICountry } from "../../models/Country";
import Dropdown from "../../components/ui/dropdown/Dropdown";
import { reorderCountries } from "../../utils/commonUtils";
import { ICountryState } from "../../models/CountryState";
import { countryStates } from "../../utils/states/states";
import { IDropdownOptions } from "../../models/Configuration";

function usePrevious(value: IDropdownOptions) {
  const ref = useRef<IDropdownOptions>();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
}

interface IAddressFormProps {
  onChangeAddressLine1: (evt: any) => void;
  onChangeAddressLine2: (evt: any) => void;
  onChangeAddressCity: (evt: any) => void;
  onChangeAddressPostCode: (evt: any) => void;
  onChangeAddressState: (state: any) => void;
  onChangeAddressCountry: (country: any) => void;
  formData: {
    addressLine1: string;
    addressLine2: string;
    addressCity: string;
    addressPostCode: string;
    addressState: IDropdownOptions;
    addressCountry: IDropdownOptions;
  };
  formErrors?: boolean;
  label?: string;
  isRequired?: boolean
}

const AddressForm: React.FC<IAddressFormProps> = props => {
  const [countries, setCountries] = useState<Array<IDropdownOptions> | null>(null);
  const [states, setStates] = useState<Array<IDropdownOptions>>([]);

  const {
    formData: { addressLine1, addressLine2, addressState, addressCity, addressPostCode, addressCountry },
    onChangeAddressLine1,
    onChangeAddressLine2,
    onChangeAddressCity,
    onChangeAddressCountry,
    onChangeAddressPostCode,
    onChangeAddressState,
    formErrors,
    label,
    isRequired = true
  } = props;

  const stateOptions = countryStates.map((state: ICountryState) => ({ value: state.StateIso2, label: state.StateName, countryIso: state.CountryIso3 }));

  const isCountryCanadaOrUsa = (country: IDropdownOptions) => country && country.value === "CAN" || country && country.value === "USA";

  useEffect(() => {
    let isSubscribed = true;
    const fetchCountries = async () => {
      try {
        const fetchedCountries = (await API.getCountries()) as Array<ICountry>;
        const countryOptions = reorderCountries(fetchedCountries).map((country: ICountry) => ({ value: country.iso3Code, label: country.name }));
        if (isSubscribed) {
          setCountries(countryOptions);
        }
      } catch (err) {
        console.log(err);
      }
    };

    if (!countries) {
      fetchCountries();
    }

    if (countries && addressCountry && addressCountry.value) {
      let currentSelectedCountry = countries.find(c => c.value === addressCountry.value);
      _onChangeAddressCountry(currentSelectedCountry || countries[0], true);
    }

    return () => {
      isSubscribed = false;
    };
  }, [countries]);

  const _onChangeAddressCountry = (country: any, isFirstRun: boolean = false) => {
    const filteredOptions = stateOptions.filter(state => state.countryIso === country.value);
    setStates(filteredOptions);

    onChangeAddressCountry(country);

    if(isCountryCanadaOrUsa(country)) {
      if (addressState && addressState.value) {
        let currentSelectedState = filteredOptions.find(s => s.value === addressState.value);
        onChangeAddressState(currentSelectedState || filteredOptions[0]);
      } else {
        onChangeAddressState(filteredOptions[0]);
      }
    } else if(isFirstRun && addressState && addressState.value) {
      onChangeAddressState({ value: addressState.value, label: "" })
    } else {
      onChangeAddressState({ value: "", label: "" })
    }

  };

  if (!countries) {
    return null;
  }

  return (
    <>
      {label && (
        <FormGroup>
          <Row align="center">
            <Col md={12}>
              <FormLabel target="addressLine1" content={label} noMargin/>
            </Col>
          </Row>
        </FormGroup>
      )}
      <FormGroup>
        <Row align="center">
          <Col md={12}>
            <Input 
              id="addressLine1" 
              isError={formErrors && !addressLine1} 
              required={isRequired}
              value={addressLine1} 
              placeholder={counterpart("ADDRESS_FORM_ADDRESS_LINE_1")} 
              onChange={(evt: any) => onChangeAddressLine1(evt.target.value)} 
            />
          </Col>
        </Row>
      </FormGroup>
      <FormGroup>
        <Row align="center">
          <Col md={12}>
            <Input 
              id="addressLine2" 
              isError={formErrors && !addressLine2} 
              value={addressLine2} 
              placeholder={counterpart("ADDRESS_FORM_ADDRESS_LINE_2")} 
              onChange={(evt: any) => onChangeAddressLine2(evt.target.value)} 
            />
          </Col>
        </Row>
      </FormGroup>
      <FormGroup>
        <Row align="center">
          <Col md={6}>
            <Input 
              id="city" 
              isError={formErrors && !addressCity} 
              required={isRequired} 
              value={addressCity} 
              placeholder={counterpart("ADDRESS_FORM_ADDRESS_CITY")} 
              onChange={(evt: any) => onChangeAddressCity((evt.target.value))} 
            />
          </Col>
          <Col md={6}>
            <Input 
              id="postCode" 
              isError={formErrors && !addressPostCode} 
              required={isRequired} 
              value={addressPostCode} 
              placeholder={counterpart("ADDRESS_FORM_POSTCODE")} 
              onChange={(evt: any) => onChangeAddressPostCode(evt.target.value)} 
            />
          </Col>
        </Row>
      </FormGroup>

      <Row align="center">
        <Col md={6}>
          <Dropdown
            id="country"
            dividerAfter={4}
            className={formErrors && !addressCountry.value ? "has-error" : ""}
            placeholder={counterpart("ADD_CUSTOMER_COUNTRY")}
            onChange={(selectedCountry: any) => _onChangeAddressCountry(selectedCountry)}
            value={addressCountry && addressCountry.value ? addressCountry : null}
            options={countries}
          />
        </Col>
        <Col md={6}>
          {
            isCountryCanadaOrUsa(addressCountry)
              ? (
                <Dropdown
                  id="state"
                  dividerAfter={4}
                  className={formErrors && !addressState.value ? "has-error" : ""}
                  placeholder={counterpart("ADDRESS_FORM_ADDRESS_STATE")}
                  onChange={onChangeAddressState}
                  value={addressState && addressState.value ? addressState : null}
                  options={states}
                />
              )
              : (
                <Input
                  id="state" 
                  required={isRequired}
                  placeholder={counterpart("ADDRESS_FORM_ADDRESS_STATE")}
                  value={addressState && addressState.value ? addressState.value : ""}
                  onChange={(evt: any) => {
                    onChangeAddressState({ label: evt.target.value, value: evt.target.value })
                  }}
                />
              )

          }
        </Col>
      </Row>
    </>
  );
};

export default AddressForm;
