import { BillsbyAction } from "../models/BillsbyAction";
import { AppState } from "..";
import { RESET_SCA_SETTINGS_SETUP, RESET_SCA, SET_IS_SETTING_UP_SCA, SET_SCA_FIELD, SET_STRIPE_SCA_VALUES, SET_UP_SCA_SETTINGS, LOAD_PREVIOUS_SCA_STATE } from "../actions/scaActions";
import { IStripeScaConfig } from "../models/Configuration";

export interface IScaState {
  isSettingUpSca: boolean,
  selectedPaymentgatewayId?: number,
  hasSuccessfulScaSetup: boolean,
  hasFailedScaSetup: boolean,
  stripeScaConfig?: IStripeScaConfig,
  showTestModal: boolean,
  hasPreviouslySetupSca: boolean,
}

export const initState: IScaState = {
  isSettingUpSca: false,
  selectedPaymentgatewayId: undefined,
  hasSuccessfulScaSetup: false,
  hasFailedScaSetup: false,
  stripeScaConfig: {
    webhookId: "",
    webhookSigningSecret: "",
    apiSecretKey: "",
  },
  showTestModal: false,
  hasPreviouslySetupSca: false,
}

export default function scaReducer(state: IScaState = initState, action: BillsbyAction, store: AppState) {
  switch (action.type) {
    case SET_IS_SETTING_UP_SCA:
      return { ...state, isSettingUpSca: action.payload }
    case SET_UP_SCA_SETTINGS:
      return { ...state, isSettingUpSca: true, selectedPaymentgatewayId: action.payload }
    case RESET_SCA_SETTINGS_SETUP:
      return { ...state, isSettingUpSca: false, selectedPaymentgatewayId: undefined,
        stripeScaConfig: { webhookId: "", webhookSigningSecret: "", apiSecretKey: "" }}
    case SET_STRIPE_SCA_VALUES:
      return { ...state, stripeScaConfig: action.payload }   
    case SET_SCA_FIELD: {
      return { ...state, [action.fieldName]: action.fieldValue }
    }  
    case LOAD_PREVIOUS_SCA_STATE: {
      return { ...action.payload, hasPreviouslySetupSca: true }
    }
    case RESET_SCA: {
      return initState
    }

    default: return state
  }
}