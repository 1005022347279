import React from "react";
import { useSelector } from "react-redux";
import "./EmbedCodesSelfService.scss";
import Helmet from "react-helmet";
import counterpart from "counterpart";
import { Link } from "react-router-dom";
import Panel from "../../../components/ui/panel/Panel";
import Text from "../../../components/ui/text/Text";
import { Row, Col } from "react-grid-system";
import CodePanel from "../../../components/ui/code-panel/CodePanel";
import { ConfigConstants } from "../../../utils/config";
import { AppState } from "../../../index";

const EmbedCodesChangePlan: React.FC = () => {
  const companyDomain = useSelector<AppState, string>(state => state.auth.currentCompanyDomain);

  const renderBasicEmbedCodePanel = () => {
    const basicEmbedCode1 = `<script src="${ConfigConstants.checkoutLibUrl}" data-billsby-company="${companyDomain}"></script>`;
    const basicEmbedCode2 = "<a href=\"javascript:void(0)\" data-billsby-type=\"account\">Manage account</a>";

    return (
      <Panel className="ecself-service__basic" title="SETTINGS_CHANGE_PLAN_PANEL2_TITLE">
        <Text content="SETTINGS_CHANGE_PLAN_PANEL2_CONTENT" />

        <Text className="ecself-service__basic__subtitle" content="SETTINGS_SELF_SERVICE_PANEL2_HEADER1" />
        <CodePanel id="basic-copy-1" codeStr={basicEmbedCode1}>
          <span className="ecself-service__basic__code-main">{basicEmbedCode1}</span>
        </CodePanel>

        <Text className="ecself-service__basic__subtitle" content="SETTINGS_SELF_SERVICE_PANEL2_HEADER2" />
        <CodePanel noMargin id="basic-copy-2" codeStr={basicEmbedCode2}>
          <span className="ecself-service__basic__code-main">{basicEmbedCode2}</span>
        </CodePanel>
      </Panel>
    );
  };

  const renderIDsManageAccountPanel = () => {
    const specificCustomerCode1 = '<a href="javascript:void(0)" data-billsby-type="account" data-billsby-customer="';
    const specificCustomerId = "[Customer ID]";
    const specificCustomerCode2 = '">Manage account</a>';

    return (
      <Panel className="ecself-service__basic" title="SETTINGS_CHANGE_PLAN_PANEL3_TITLE">
        <Text content="SETTINGS_CHANGE_PLAN_PANEL3_CONTENT" />

        <Text className="ecself-service__basic__subtitle" content="SETTINGS_SELF_SERVICE_PANEL3_HEADER1" />
        <CodePanel noMargin id="basic-copy-3" codeStr={specificCustomerCode1 + specificCustomerId + specificCustomerCode2}>
          <span className="eccheckout__basic__code-main">
            {specificCustomerCode1}
            <span className="eccheckout__basic__variable">{specificCustomerId}</span>
            {specificCustomerCode2}
          </span>
        </CodePanel>
      </Panel>
    );
  };

  const renderDirectCustomersPanel = () => {
    const actionPaymentChangePlan = "\"changeplan\"";

    const updatePaymentDetails1 = '<a href="javascript:void(0)" data-billsby-type="account" data-billsby-action=';
    const updatePaymentDetails2 = ">Change your plan</a>";

    return (
      <Panel className="ecself-service__basic" title="SETTINGS_CHANGE_PLAN_PANEL4_TITLE">
        <Text content="SETTINGS_CHANGE_PLAN_PANEL4_CONTENT" />

        <Text className="ecself-service__basic__subtitle" content="SETTINGS_CHANGE_PLAN_PANEL4_HEADER1" />
        <CodePanel noMargin id="basic-copy-4" codeStr={updatePaymentDetails1 + actionPaymentChangePlan + updatePaymentDetails2}>
          <span className="ecself-service__basic__code-main">
            {updatePaymentDetails1}
            <span className="ecself-service__basic__variable">{actionPaymentChangePlan}</span>
            {updatePaymentDetails2}
          </span>
        </CodePanel>
      </Panel>
    );
  };
  
  return (
    <div className="ecself-service">
      <Helmet title={`${counterpart("NAVBAR_EMBED_CODES")}-${counterpart("SETTINGS_EMBED_CODES_CHANGE_PLAN")}`} />
      <Panel className="ecself-service__change-plan" title="SETTINGS_CHANGE_PLAN_PANEL1_TITLE">
        <Row>
          <Col md={10}>
            <Text content="SETTINGS_CHANGE_PLAN_PANEL1_CONTENT" component="span" />
            <Link to="/configuration/brand-customization">
              <Text content="SETTINGS_CHANGE_PLAN_PANEL1_LINK" noMargin component="span" />
            </Link>
          </Col>
        </Row>
      </Panel>

      {renderBasicEmbedCodePanel()}
      {renderIDsManageAccountPanel()}
      {renderDirectCustomersPanel()}
    </div>
  )
}

export default EmbedCodesChangePlan;
