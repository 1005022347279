import React, { useState } from "react";
import moment from "moment";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "./MonthDayPicker.scss";

interface IMonthDayPicker {
  value?: string;
  className?: string;
  onChange: (value: any) => void;
}

const MonthDayPicker: React.FC<IMonthDayPicker> = React.memo(({ value, onChange, className = "" }) => {
  const [startDate, setStartDate] = useState<Date | null>(new Date());
  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December"
  ];
  if (!value) return null;
  return (
    <div className={`custom-month-day-picker ${className}`}>
      <DatePicker
        renderCustomHeader={({
          date,
          changeYear,
          changeMonth,
          decreaseMonth,
          increaseMonth,
          prevMonthButtonDisabled,
          nextMonthButtonDisabled
        }) => (
            <div>

              <div className="react-datepicker__triangle"></div>
              <button type="button" onClick={decreaseMonth} className="react-datepicker__navigation react-datepicker__navigation--previous" aria-label="Previous Year"></button>
              <button type="button" onClick={increaseMonth} className="react-datepicker__navigation react-datepicker__navigation--next" aria-label="Next Year"></button>

              <div className="react-datepicker__header react-datepicker-month-header">{months[(date).getMonth()]}</div>

            </div>
          )}
          selected={value && moment(value, "MMM DD").isValid() ? moment(value, "MMM DD").toDate() : new Date()}
          onChange={(date: Date) => onChange(moment(date).format("MMM DD"))}
          dateFormat="MMM d"
      />
    </div>
  );
});

export default MonthDayPicker;
