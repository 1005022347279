import React, { useState } from "react";
import Text from "../../../components/ui/text/Text";
import Button from "../../../components/ui/button/Button";
import { divideNumberBy100 } from "../../../utils/commonUtils";
import history from "../../../utils/history";
import { DiscountType, Discount, DeductionType, GetCouponsResponse } from "../../../utils/grpc/generated/Billsby.Protos/core/private/discounts/discounts_pb";
import { fetchCouponsList, setDiscountsFields } from "../../../actions/discount-coupon/discountsActions";
import { IAuthReducerState } from "../../../reducers/authReducer";
import DiscountMonetariesTable from "./DiscountMonetariesTable";
import DiscountCouponsTable from "./DiscountCouponsTable";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../../..";
import { Permission } from "../../../models/Auth";
import useCheckFeaturePermission from "../../../utils/hooks/useCheckFeaturePermission";

interface IDiscountListRow {
  data: Discount,
  couponList: Array<{ discountId: number, coupons: GetCouponsResponse }>
}

const DiscountListRow: React.FC<IDiscountListRow> = ({ data, couponList }) => {
  const auth = useSelector<AppState, IAuthReducerState>(state => state.auth)
  const dispatch = useDispatch<Function>()
  const isDiscountCouponAddPermitted = useCheckFeaturePermission(Permission.DiscountCouponAdd);
  const isDiscountEditPermitted = useCheckFeaturePermission(Permission.DiscountEdit);
  const isDiscountDeletePermitted = useCheckFeaturePermission(Permission.DiscountDelete);

  const [expandedTable, setExpandedTable] = useState<"deduction" | "coupons" | null>(null);

  const getPercentage = () => {
    if (data.getPercentage()) {
      const percentage = data.getPercentage() as Discount.PercentageModel;
      return Number(divideNumberBy100(percentage.getPercentage()));
    }

    return "";
  }

  const getMonetaries = () => {
    if (!data.getMonetary())
      return null;
    return data.getMonetary() as Discount.MonetaryModel;
  }

  const deductionDisplay = () => {
    if (data.getDiscountType() === DiscountType.PERCENTAGE) {
      return <td className="cc-text mb0 ">
        <p>
          <Text content="DISCOUNTS_LIST_DEDUCTION_PERCENTAGE" translateWith={{ percentage: getPercentage() }} noMargin component="span" />
          <Text className="discount-list__table__deduction-label" content={data.getDeductionType() === DeductionType.PLAN_ONLY ? "DISCOUNTS_LIST_DISCOUNT_TYPE_MONETARY_TYPE_PLAN_ONLY" : "DISCOUNTS_LIST_DISCOUNT_TYPE_MONETARY_TYPE_ENTIRE_SUBSCRIPTION"} component="span" noMargin />
        </p>
      </td>;
    } else {
      if (data.getMonetary()) {
        const monetary = data.getMonetary() as Discount.MonetaryModel;
        if (monetary.getPricesList().length === 1) {
          const price = monetary.getPricesList()[0];
          return <Text content={price.getValueFormatted()} shouldTranslate={false} component="td" noMargin />;
        }
        else {
          return <td
            className={`discount-list__table__collapsible${getMonetaries() && (getMonetaries() as Discount.MonetaryModel).getPricesList().length < 1 ? " discount-list__table__collapsible--no-data" : ""}`}
            onClick={() => {
              setExpandedTable(expandedTable === "deduction" ? null : "deduction");
            }}
          >
            <span className={`discount-list__table__collapsible-arrow${expandedTable === "deduction" ? " expanded" : ""}`} />
            <Text className="discount-list__table__collapsible-label" content="DISCOUNTS_LIST_DEDUCTION_CURRENCIES" translateWith={{ count: monetary.getPricesList().length }} component="span" noMargin />
          </td>
        }
      }

      return <td></td>;
    }
  }

  const hasUnassignedCurrency = () => {
    if (data.hasMonetary()) {
      const monetaries = data.getMonetary() as Discount.MonetaryModel;
      const unassignedCurrencies = monetaries.getPricesList().filter(p => !p.hasValue());
      return unassignedCurrencies.length > 0;
    }

    return false;
  }

  const getRowClassName = () => {
    let className = "";
    if (expandedTable) {
      className += "cc-table--expandable__rowExpanded";
    }

    if (hasUnassignedCurrency()) {
      className += " discount-list__table__row-error";
    }

    return className;
  }  

  return (
    <>
      <tr className={getRowClassName()}>
        <td className="cc-text mb0">
          {hasUnassignedCurrency() && <i className="fas fa-map-marker-exclamation discount-list__table__currency-error"></i>}
          <p>{data.getName()}</p>
        </td>
        <Text content={data.getDiscountUniqueId()} shouldTranslate={false} component="td" noMargin />
        <td
          className={`discount-list__table__collapsible${data.getCouponCount() < 1 ? " discount-list__table__collapsible--no-data" : ""}`}
          onClick={() => {
            dispatch(fetchCouponsList(auth.currentCompanyId as number, data.getDiscountId()));
            if (data.getCouponCount() > 0) {
              setExpandedTable(expandedTable === "coupons" ? null : "coupons");
            }
          }}
        >
          <span className={`discount-list__table__collapsible-arrow${expandedTable === "coupons" ? " expanded" : ""}`} />
          <Text className="discount-list__table__collapsible-label" content={ data.getCouponCount() === 1 ? "DISCOUNTS_LIST_COUPON" : "DISCOUNTS_LIST_COUPONS"} translateWith={{ coupons: data.getCouponCount() }} component="span" noMargin />
        </td>
        <Text content={data.getDiscountType() === DiscountType.PERCENTAGE ? "DISCOUNTS_LIST_DISCOUNT_TYPE_PERCENTAGE" : "DISCOUNTS_LIST_DISCOUNT_TYPE_MONETARY"} component="td" noMargin />
        {deductionDisplay()}
        <td className='discount-list__table__actions'>
          <span data-tip data-for={hasUnassignedCurrency() ? "discount-add-coupon-tooltip" : ""}>
            <Button
              id='add-coupon'
              disabled={hasUnassignedCurrency() || !isDiscountCouponAddPermitted}
              tooltipMessage={!isDiscountCouponAddPermitted ? "NO_ACCESS_MSG" : undefined}
              buttonType='add'
              icon='fas fa-plus-circle'
              title={"DISCOUNTS_TABLE_ADD_A_COUPON"}
              onClick={() => history.push(`/discount-and-coupons/${data.getDiscountId()}/coupon/create`)}
            ></Button>
          </span>
          <span data-tip data-for='discount-edit-tooltip'>
            <Button id='edit-discount'
              disabled={!isDiscountEditPermitted}
              tooltipMessage={!isDiscountEditPermitted ? "NO_ACCESS_MSG" : undefined}
              buttonType='add' icon='fas fa-edit' title='DISCOUNTS_TABLE_EDIT'
              onClick={() => history.push(`/discount-and-coupons/edit/${data.getDiscountId()}`)}></Button>
          </span>
          <span data-tip data-for='discount-delete-tooltip'>
            <Button id='delete-discount'
              buttonType='error'
              icon='far fa-trash-alt'
              title='DISCOUNTS_TABLE_DELETE'
              disabled={!isDiscountDeletePermitted}
              tooltipMessage={!isDiscountDeletePermitted ? "NO_ACCESS_MSG" : undefined}
              onClick={() => {
                dispatch(setDiscountsFields("showDiscountDeleteModal", true));
                dispatch(setDiscountsFields("discountToDelete", data));
              }}></Button>
          </span>
        </td>
      </tr>
      {expandedTable && (
        <tr className="discount-list__table expanded">
          <td>
            <div className="discount-list__expanded-wrapper">{expandedTable === "deduction" ? <DiscountMonetariesTable data={data} /> : <DiscountCouponsTable discountId={data.getDiscountId()} couponList={couponList} />}</div>
          </td>
        </tr>
      )}
    </>
  );
};

export default DiscountListRow;
