import { BillsbyAction } from "../models/BillsbyAction";
import {
  FETCH_TAXABLE_COUNTRIES_SUCCESS,
  RESET_TAXJAR_CURRENT_COUNTRY,
  RESET_TAXJAR_PRODUCT_CATEGORIES,
  FETCH_PRODUCT_CATEGORIES_SUCCESS,
  RESET_COMPANY_TAX_RATES,
  SET_CURRENT_COUNTRY,
  SET_TAX_RATES_FIELD,
  FETCH_TAX_CONFIGS_SUCCESS,
  FETCH_OTHER_COUNTRIES_SUCCESS,
  UPDATE_TAX_CONFIG_FAILURE,
  UPDATE_TAX_CONFIG_REQUEST
} from "../actions/companyTaxRatesActions";
import { AppState } from "..";
import { ICountryTax, IOtherCountry } from "../models/Taxes";
import { GetTaxConfigResponse, GetProductCategoriesResponse, TaxJarConfig, AutoBillsbyTaxConfig, ManualTaxConfig } from "../utils/grpc/generated/Billsby.Protos/billing/private/company/taxation/taxation_pb";
import { ICountry } from "../models/Country";
import { extractGrpcErrorMessage } from "../utils/commonUtils";

export interface ICompanyTaxRatesReducerState {
  countries: Array<ICountryTax>;
  otherCountries: Array<IOtherCountry>
  configs?: GetTaxConfigResponse;
  currentCountry: ICountryTax | null;
  taxableCountries: Array<ICountry>;
  productCategories: Array<IProductCategory> | null;
  nexusAddressFilled: boolean;
  errorMessage?: string
}

interface IProductCategory {
  label: string;
  value: string;
}

export const initialState = {
  countries: [],
  otherCountries: [],
  taxableCountries: [],
  configs: undefined,
  currentCountry: null,
  productCategories: null,
  nexusAddressFilled: false
};

export default function companyTaxRatesReducer(state: ICompanyTaxRatesReducerState = initialState, action: BillsbyAction, store: AppState) {
  switch (action.type) {
    case SET_TAX_RATES_FIELD: {
      return { ...state, [action.fieldName]: action.fieldValue };
    }
    case FETCH_TAX_CONFIGS_SUCCESS:
      const details = action.response as GetTaxConfigResponse;
      return { ...state, configs: details };

    case SET_CURRENT_COUNTRY: {
      if (!state.configs) {
        return state;
      }

      const countryConfig = state.configs.getResultsList().filter(config => (config.hasManualTaxConfig() || config.hasTaxJarConfig() || config.hasBillsbyTaxConfig()) && config.getCountryIso3Code() === action.payload.countryCode);
      const countryTax = countryConfig[0];

      let taxConfig = undefined;

      if (countryTax && countryTax.hasTaxJarConfig()) {
        const taxJarConfig = countryTax.getTaxJarConfig();

        if (taxJarConfig) {
          taxConfig = {
            apiToken: taxJarConfig.getApiToken(),
            taxProductCategoryCode: taxJarConfig.getTaxProductCategoryCode(),
            isAutoFileEnabled: taxJarConfig.getIsAutoFileEnabled(),
            taxLabel: taxJarConfig.getTaxLabel(),
            taxRegNumber: taxJarConfig.getTaxRegNumber(),
            taxRegNumberRequirementType: taxJarConfig.getTaxRegNumberRequirementType(),
             // HIDE FOR NOW
            // customerTaxExemption: billsbyTaxConfig.getCustomerTaxExemption(),
          } as TaxJarConfig.AsObject;
        }
      }

      if (countryTax && countryTax.hasBillsbyTaxConfig()) {
        const billsbyTaxConfig = countryTax.getBillsbyTaxConfig();

        if (billsbyTaxConfig) {
          const nexusAddress = billsbyTaxConfig.getNexusAddress();

          taxConfig = {
            taxProductCategoryCode: billsbyTaxConfig.getTaxProductCategoryCode(),
            taxLabel: billsbyTaxConfig.getTaxLabel(),
            taxRegNumber: billsbyTaxConfig.getTaxRegNumber(),
            taxRegNumberRequirementType: billsbyTaxConfig.getTaxRegNumberRequirementType(),
            // HIDE FOR NOW
            // customerTaxExemption: billsbyTaxConfig.getCustomerTaxExemption(),
            nexusAddress: nexusAddress
              ? {
                addressLine1: nexusAddress.getAddressLine1(),
                addressLine2: nexusAddress.getAddressLine2(),
                city: nexusAddress.getCity(),
                state: nexusAddress.getState(),
                postCode: nexusAddress.getPostCode(),
                countryIso3Code: nexusAddress.getCountryIso3Code()
              }
              : undefined
          } as AutoBillsbyTaxConfig.AsObject;
        }
      }

      if (countryTax && countryTax.hasManualTaxConfig()) {
        const manualTaxConfig = countryTax.getManualTaxConfig()

        if (manualTaxConfig) {
          taxConfig = {
            taxLabel: manualTaxConfig.getTaxLabel(),
            taxRegNumber: manualTaxConfig.getTaxRegNumber(),
            taxRegNumberRequirementType: manualTaxConfig.getTaxRegNumberRequirementType(),
            locationValidation: manualTaxConfig.getLocationValidation(),
            taxValidation: manualTaxConfig.getTaxValidation(),
            isDigital: manualTaxConfig.getIsDigital(),
            withVatMoss: manualTaxConfig.getWithVatMoss(),
            viesValidation: manualTaxConfig.getViesValidation(),
            exemptionNoteReverseCharge: manualTaxConfig.getExemptionNoteReverseCharge(),
            exemptionNoteExports: manualTaxConfig.getExemptionNoteExports(),
            customerTaxExemption: manualTaxConfig.getCustomerTaxExemption(),
            taxRatesList: manualTaxConfig.getTaxRatesList().map(taxRate => ({
              name: taxRate.getName(),
              stateIso2: taxRate.getStateIso2(),
              countryIso3: taxRate.getCountryIso3(),
              taxRegNumber: taxRate.getTaxRegNumber(),
              rate: taxRate.getRate(),
              isOrigin: taxRate.getIsOrigin()
            })),
          } as ManualTaxConfig.AsObject
        }
      }

      const currentCountryTaxData: ICountryTax = {
        countryName: action.payload.countryName,
        countryCode: action.payload.countryCode,
        taxConfig,
        taxServiceType: countryTax?.getTaxServiceType()
      };

      return { ...state, currentCountry: currentCountryTaxData };
    }
    case FETCH_PRODUCT_CATEGORIES_SUCCESS: {
      let editedCategories: Array<IProductCategory> = [];
      const categories = action.response as GetProductCategoriesResponse;

      categories.getCategoriesList().forEach(data => {
        editedCategories.push({ label: data.getName(), value: data.getProductTaxCode() });
      });

      return { ...state, productCategories: editedCategories };
    }
    case FETCH_TAXABLE_COUNTRIES_SUCCESS: {
      return { ...state, taxableCountries: action.response };
    }

    case FETCH_OTHER_COUNTRIES_SUCCESS: {
      return { ...state, otherCountries: action.response }
    }

    case UPDATE_TAX_CONFIG_REQUEST: {
      return { ...state, errorMessage: undefined }
    }
    
    case UPDATE_TAX_CONFIG_FAILURE: {
      const error = action.error;
      return { ...state, errorMessage: extractGrpcErrorMessage(error.message) }
    }

    case RESET_TAXJAR_PRODUCT_CATEGORIES: {
      return { ...state, productCategories: null };
    }

    case RESET_TAXJAR_CURRENT_COUNTRY: {
      return { ...state, currentCountry: null };
    }

    case RESET_COMPANY_TAX_RATES:
      return initialState;
    default:
      return state;
  }
}
