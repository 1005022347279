import React, { FormEvent, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Panel from "../ui/panel/Panel";
import Text from "../ui/text/Text";
import FormLabel from "../ui/form-label/FormLabel";
import Input from "../ui/input/Input";
import Button from "../ui/button/Button";
import FormGroup from "../ui/form-group/FormGroup";
import Table from "../ui/table/Table";
import counterpart from "counterpart";
import { Row, Col } from "react-grid-system";
import { AppState } from "../..";
import { ICustomerDetailsReducerState } from "../../reducers/customerDetailsReducer";
import API from "../../utils/API";
import "./ClearPersonalData.scss";
import ErrorNotification from "../ui/error-notification/ErrorNotification";
import NoticePanel from "../ui/notice-panel/NoticePanel";
import { fetchCustomerInvoices } from "../../actions/customerDetailsActions";
import { useParams } from "react-router-dom";
import { ICustomerInvoiceData } from "../../models/Customer";
import ProgressIndicator from "../ui/progress-indicator/ProgressIndicator";
import { InvoiceStatus } from "../../models/Invoices";

interface IClearPersonalDataProps {
  handleClearPersonalDataCallback: Function,
}

const ClearPersonalData: React.FC<IClearPersonalDataProps> = ({ handleClearPersonalDataCallback }) => {
  const currentCompanyDomain = useSelector<AppState, string>(state => state.auth.currentCompanyDomain);
  const { phoneNumber, billingAddress, email, firstName, lastName, customerUniqueId } = useSelector<AppState, ICustomerDetailsReducerState>(state => state.customerDetailsReducer)

  const dispatch = useDispatch<Function>();
  const params = useParams<{ id: string }>();

  const [hasError, setHasError] = useState(false);
  const [isLoadingData, setIsLoadingData] = useState(false);
  const [hasPendingInvoice, setHasPendingInvoice] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [inputCustomerUniqueId, setInputCustomerUniqueId] = useState("");

  useEffect(() => {
    const fetchData = async () => {
      setIsLoadingData(true);
      try {
        const { response: invoices } = await dispatch(fetchCustomerInvoices(currentCompanyDomain, params.id.toString())) as unknown as { response: ICustomerInvoiceData[] }
        setHasPendingInvoice(invoices.some(i => i.status !== InvoiceStatus.PAID && i.status !== InvoiceStatus.WRITTEN_OFF && i.status !== InvoiceStatus.PAID_OFFLINE && i.status !== InvoiceStatus.PAID_MANUALLY))
      }
      catch (err) { }
      finally {
        setIsLoadingData(false);
      }
    }
    fetchData();
  }, []);

  const wrongCustomerUniqueId = useMemo(() => {
    return inputCustomerUniqueId !== "" && customerUniqueId !== inputCustomerUniqueId
  }, [inputCustomerUniqueId]);

  const onSubmit = async (evt: FormEvent) => {
    evt.preventDefault();
    evt.stopPropagation();

    if (!customerUniqueId) {
      return;
    }
    setIsLoading(true)

    try {
      await API.clearCustomerDetails(currentCompanyDomain, customerUniqueId);
      await handleClearPersonalDataCallback();
    } catch (err) {
      setHasError(true);
    } finally {
      setIsLoading(false)
    }
  };

  const renderTable = () => {
    return (
      <Panel>
        <Text content="CLEAR_PERSONAL_DATA_MODAL_PANEL2_CONTENT" />
        <Table className="clear-personal-data-table">
          <tbody>
            <tr>
              <td>
                <Row align="center" justify="start" className="clear-personal-data-table__row">
                  <Col md={3}>
                    <Text className="clear-personal-data-table__label" content="CLEAR_PERSONAL_DATA_MODAL_EMAIL" noMargin />
                  </Col>

                  <Col md={9}>
                    <Text shouldTranslate={false} content={email} noMargin />
                  </Col>
                </Row>
              </td>
            </tr>
            <tr>
              <td>
                <Row align="center" justify="start" className="clear-personal-data-table__row">
                  <Col md={3}>
                    <Text className="clear-personal-data-table__label" content="CLEAR_PERSONAL_DATA_MODAL_FULL_NAME" noMargin />
                  </Col>

                  <Col md={9}>
                    <Text shouldTranslate={false} content={`${firstName} ${lastName}`} noMargin />
                  </Col>
                </Row>
              </td>
            </tr>
            <tr className="no-max-height">
              <td>
                <Row align="start" justify="end" className="clear-personal-data-table__row">
                  <Col md={3}>
                    <Text className="clear-personal-data-table__label" content="CLEAR_PERSONAL_DATA_MODAL_ADDRESS" noMargin />
                  </Col>

                  <Col md={9}>
                    <Text shouldTranslate={false} content={billingAddress.addressLine1} noMargin />
                    {billingAddress.addressLine2 && <Text shouldTranslate={false} content={billingAddress.addressLine2} noMargin />}
                    <Text shouldTranslate={false} content={billingAddress.state} noMargin />
                    <Text shouldTranslate={false} content={billingAddress.city} noMargin />
                    <Text shouldTranslate={false} content={billingAddress.country} noMargin />
                    <Text shouldTranslate={false} content={billingAddress.postCode} noMargin />
                  </Col>
                </Row>
              </td>
            </tr>
            <tr>
              <td>
                <Row align="center" justify="end" className="clear-personal-data-table__row">
                  <Col md={3}>
                    <Text className="clear-personal-data-table__label" content="CLEAR_PERSONAL_DATA_MODAL_PHONE_NUMBER" noMargin />
                  </Col>

                  <Col md={9}>
                    <Text shouldTranslate={false} content={phoneNumber} noMargin />
                  </Col>
                </Row>
              </td>
            </tr>
            <tr>
              <td>
                <Row align="center" justify="end" className="clear-personal-data-table__row">
                  <Col md={3}>
                    <Text className="clear-personal-data-table__label" content="CLEAR_PERSONAL_DATA_MODAL_CUSTOMER_ID" noMargin />
                  </Col>

                  <Col md={9}>
                    <Text shouldTranslate={false} content={customerUniqueId} noMargin />
                  </Col>
                </Row>
              </td>
            </tr>
          </tbody>
        </Table>
      </Panel>
    );
  };

  if (isLoadingData) {
    return <ProgressIndicator color="blue" coverage='normal' />
  }

  return (
    <form className="clear-personal-data" onSubmit={onSubmit}>
      {hasPendingInvoice && <NoticePanel
        type="error"
        content={"CLEAR_PERSONAL_DATA_WARNING_PENDING_INVOICES"}
        isModal
      />}
      <Panel className="clear-personal-data__titlepanel" title="CLEAR_PERSONAL_DATA_MODAL_PANEL1_TITLE">
        <Text content="CLEAR_PERSONAL_DATA_MODAL_PANEL1_CONTENT" noMargin />
      </Panel>

      {renderTable()}

      <Panel>
        <Text content="CLEAR_PERSONAL_DATA_MODAL_PANEL3_CONTENT" />
        <FormGroup>
          <Row align="center">
            <Col md={2}>
              <FormLabel target="customer-id" content="CLEAR_PERSONAL_DATA_MODAL_CUSTOMER_ID_INPUT" />
            </Col>
            <Col md={10}>
              <Input
                required
                type="text"
                isError={wrongCustomerUniqueId}
                warninglayout={wrongCustomerUniqueId}
                id="customer-id"
                value={inputCustomerUniqueId}
                onChange={e => setInputCustomerUniqueId(e.target.value)}
                placeholder={counterpart("CLEAR_PERSONAL_DATA_MODAL_CUSTOMER_ID_INPUT")}
              />
            </Col>
          </Row>
        </FormGroup>
      </Panel>

      <Button
        id="clear-personal-data"
        type="submit"
        disabled={isLoading || customerUniqueId !== inputCustomerUniqueId || hasPendingInvoice}
        buttonType={"general"}
        isLoading={isLoading && !hasError}
        title={"CLEAR_PERSONAL_DATA"}
        isFullWidth
      />

      <ErrorNotification
        title="CLEAR_PERSONAL_DATA_ERROR"
        showAlert={hasError}
        onClose={() => setHasError(false)} />
    </form>
  )
}

export default ClearPersonalData;
