import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../../../../..";
import { setCompanyBrandingFields } from "../../../../../actions/companyBrandingActions";
import ColorPicker from "../../../../ui/color-picker/ColorPicker";
import Panel from "../../../../ui/panel/Panel";
import "./ColorCustomization.scss";

const ColorCustomization: React.FC = () => {
  const companyBrandingReducer = useSelector((state: AppState) => state.companyBrandingReducer);

  const dispatch = useDispatch<Function>();

  const { backgroundColor, headerAndButtonColor, bodyTextColor, confirmationColor, errorColor } = companyBrandingReducer;
  const whiteColor = "#ffffff";

  return (
    <Panel title="BRAND_CUSTOMISATION_COLOR" className="color-customization">
      <div>
        <ColorPicker
          color={backgroundColor}
          colorText={headerAndButtonColor}
          label="BRAND_CUSTOMISATION_BG_COLOR"
          key="background-color-picker"
          onChange={(evt: { color: string }) => dispatch(setCompanyBrandingFields("backgroundColor", evt.color))}
        />
        <ColorPicker
          color={headerAndButtonColor}
          colorLeft={backgroundColor}
          colorRight={headerAndButtonColor}
          colorTextLeft={headerAndButtonColor}
          colorTextRight={whiteColor}
          label="BRAND_CUSTOMISATION_HEADER_BTN_COLOR"
          key="header-btn-color-picker"
          onChange={(evt: { color: string }) => dispatch(setCompanyBrandingFields("headerAndButtonColor", evt.color))}
        />
        <ColorPicker
          color={bodyTextColor}
          colorLeft={whiteColor}
          colorRight={backgroundColor}
          colorText={bodyTextColor}
          fontWeight="normal"
          label="BRAND_CUSTOMISATION_BODY_TEXT_COLOR"
          key="body-text-color-picker"
          onChange={(evt: { color: string }) => dispatch(setCompanyBrandingFields("bodyTextColor", evt.color))}
        />
        <ColorPicker
          color={confirmationColor}
          label="BRAND_CUSTOMISATION_CONFIRM_COLOR"
          key="confirm-color-picker"
          onChange={(evt: { color: string }) => dispatch(setCompanyBrandingFields("confirmationColor", evt.color))}
        />
        <ColorPicker
          color={errorColor}
          label="BRAND_CUSTOMISATION_ERROR_COLOR"
          key="error-color-picker"
          onChange={(evt: { color: string }) => dispatch(setCompanyBrandingFields("errorColor", evt.color))}
        />
      </div>

    </Panel>
  )
}

export default ColorCustomization;