import { BillsbyAction } from "../models/BillsbyAction";
import { AppState } from "..";
import { FETCH_COMPANY_BRANDING_SUCCESS, SET_COMPANY_BRANDING_LOGO_BACKGROUND_COLOR, SET_COMPANY_BRANDING_HIGHLIGHT_COLOR, UPDATE_COMPANY_BRANDING_SUCCESS, UPDATE_COMPANY_BRANDING_REQUEST, UPDATE_COMPANY_BRANDING_FAILURE, UPDATE_COMPANY_BRANDING_LOGO_SUCCESS, UPDATE_COMPANY_BRANDING_LOGO_REQUEST, UPDATE_COMPANY_BRANDING_LOGO_FAILURE, UPDATE_COMPANY_BRANDING_FAVICON_SUCCESS, UPDATE_COMPANY_BRANDING_FAVICON_FAILURE, UPDATE_COMPANY_BRANDING_FAVICON_REQUEST, SET_COMPANY_BRANDING_FIELDS, RESET_COMPANY_BRANDING, FETCH_GOOGLE_FONTS_SUCCESS } from "../actions/companyBrandingActions";
import { IFont } from "../models/CompanyBranding";
import moment from "moment";


//Temporary only to support new checkout migration
const releaseDate = "Nov 15 2020"; // TODO: Need exact date
const twoWeeksPriorReleaseDate = moment(releaseDate, "MMM DD YYYY").subtract(2, "weeks");
const showNewBrandingNotice = moment().isSameOrAfter(twoWeeksPriorReleaseDate)

export interface ICompanyBrandingReducerState {
  logoUrl: string;
  logoBackgroundColor: string;
  faviconUrl: string;
  highlightColor: string;
  logoFileName: string;
  faviconFileName: string;
  isLoading: boolean;

  rounding: number;
  typographyBody: { label: string, value: string };
  typographyHeader: { label: string, value: string };
  typographyHeaderIsAllCaps: boolean;
  headerAndButtonColor: string;
  backgroundColor: string;
  bodyTextColor: string;
  confirmationColor: string;
  errorColor: string;
  fontOptions: Array<{ label: string, value: string }>,
  releaseDate: string,
  showNewBrandingNotice: boolean
}

export const initialState = {
  logoUrl: "",
  logoBackgroundColor: "",
  faviconUrl: "",
  highlightColor: "",
  logoFileName: "",
  faviconFileName: "",
  isLoading: false,
  rounding: 6,
  typographyBody: { label: "Open Sans", value: "Open Sans" },
  typographyHeader: { label: "Open Sans", value: "Open Sans" },
  typographyHeaderIsAllCaps: false,
  headerAndButtonColor: "#555555",
  backgroundColor: "#F5F5F5",
  bodyTextColor: "#555555",
  confirmationColor: "#25A970",
  errorColor: "#D22630",
  fontOptions: [],
  releaseDate,
  showNewBrandingNotice
}

export default function companyBrandingReducer(state: ICompanyBrandingReducerState = initialState, action: BillsbyAction, store: AppState) {
  switch (action.type) {
    case FETCH_COMPANY_BRANDING_SUCCESS:
      const companyBranding = action.response || initialState;
      return { ...state, ...companyBranding, 
        backgroundColor: companyBranding.backgroundColor || initialState.backgroundColor,
        headerAndButtonColor: companyBranding.headerAndButtonColor || initialState.headerAndButtonColor,
        bodyTextColor: companyBranding.bodyTextColor || initialState.bodyTextColor,
        confirmationColor: companyBranding.confirmationColor || initialState.confirmationColor,
        errorColor: companyBranding.errorColor || initialState.errorColor,
        typographyBody: { label: companyBranding.typographyBody, value: companyBranding.typographyBody },
        typographyHeader: { label: companyBranding.typographyHeader, value: companyBranding.typographyHeader }
      }
    case SET_COMPANY_BRANDING_LOGO_BACKGROUND_COLOR:
      return { ...state, logoBackgroundColor: action.payload }
    case SET_COMPANY_BRANDING_HIGHLIGHT_COLOR:
      return { ...state, highlightColor: action.payload }
    case UPDATE_COMPANY_BRANDING_SUCCESS:
      const companyBrandingResult = action.response;
      return { ...state, ...companyBrandingResult, 
        backgroundColor: companyBrandingResult.backgroundColor || initialState.backgroundColor,
        headerAndButtonColor: companyBrandingResult.headerAndButtonColor || initialState.headerAndButtonColor,
        bodyTextColor: companyBrandingResult.bodyTextColor || initialState.bodyTextColor,
        confirmationColor: companyBrandingResult.confirmationColor || initialState.confirmationColor,
        errorColor: companyBrandingResult.errorColor || initialState.errorColor,
        typographyBody: { label: companyBrandingResult.typographyBody, value: companyBrandingResult.typographyBody },
        typographyHeader: { label: companyBrandingResult.typographyHeader, value: companyBrandingResult.typographyHeader },
        isLoading: false
      }
    case UPDATE_COMPANY_BRANDING_REQUEST:
      return { ...state, isLoading: true }
    case UPDATE_COMPANY_BRANDING_FAILURE:
      return { ...state, isLoading: false }

    case UPDATE_COMPANY_BRANDING_LOGO_SUCCESS:
      const brandingLogo = action.response;
      return { ...state, logoUrl: brandingLogo.imageSrcUrl, logoFileName: brandingLogo.fileName, isLoading: false }
    case UPDATE_COMPANY_BRANDING_LOGO_REQUEST:
      return { ...state, isLoading: true }
    case UPDATE_COMPANY_BRANDING_LOGO_FAILURE:
      return { ...state, isLoading: false }

    case UPDATE_COMPANY_BRANDING_FAVICON_SUCCESS:
      const brandingFavicon = action.response;
      return { ...state, faviconUrl: brandingFavicon.imageSrcUrl, faviconFileName: brandingFavicon.fileName, isLoading: false }
    case UPDATE_COMPANY_BRANDING_FAVICON_REQUEST:
      return { ...state, isLoading: true }
    case UPDATE_COMPANY_BRANDING_FAVICON_FAILURE:
      return { ...state, isLoading: false }
    case SET_COMPANY_BRANDING_FIELDS:
      return { ...state, [action.fieldName]: action.fieldValue }

    case FETCH_GOOGLE_FONTS_SUCCESS:
      const fontOptions = (action.response.items as Array<IFont>).map(f => ({
        label: f.family,
        value: f.family
      }))
      return { ...state, fontOptions }
    case RESET_COMPANY_BRANDING:
      return initialState
    default:
      return state;
  }
}