import React, { useEffect, useState } from "react";
import Table from "../../ui/table/Table";
import Text from "../../ui/text/Text";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../../..";
import { fetchSubscriptionAddOns, setSubscriptionDetailsField, setDeletedAddOn } from "../../../actions/subscriptionDetailsActions";
import moment from "moment";
import { Addon } from "../../../utils/grpc/generated/Billsby.Protos/billing/private/subscription/subscription_pb";
import SubscriptionAddOnsTiers from "./SubscriptionAddOnsTiers";
import "./SubscriptionAddOns.scss";
import ProgressIndicator from "../../ui/progress-indicator/ProgressIndicator";
import NoticePanel from "../../ui/notice-panel/NoticePanel";

const SubscriptionAddOns: React.FC = () => {
  const authData = useSelector((state: AppState) => state.auth);
  const subscriptionDetails = useSelector((state: AppState) => state.subscriptionDetailsReducer);

  const dispatch = useDispatch<Function>();

  const [isLoadingData, setIsLoadingData] = useState(false);
  const { addOns, planPricingModel, subscriptionId, deletedAddOn } = subscriptionDetails;

  const fetchData = async () => {
    setIsLoadingData(true);
    try {
      dispatch(setDeletedAddOn(undefined));
      await dispatch(fetchSubscriptionAddOns(authData.currentCompanyId as number, subscriptionId as number));
      setIsLoadingData(false);
    } catch (err) {
      console.log(err);
      setIsLoadingData(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  if (isLoadingData) {
    return <ProgressIndicator color="blue" />;
  }

  return (
    <div>
      {deletedAddOn && <NoticePanel
        type="success"
        content="SUBSCRIPTION_ADD_ONS_DELETED_ADD_ON"
        translateWith={{
          name: deletedAddOn.getName(),
          nextBillingDate: moment(subscriptionDetails.nextBillingDate).format("DD MMM YYYY"),
          nextBillingTime: moment(subscriptionDetails.nextBillingDate).format("HH:mm:ss Z")
        }}
        icon="fas fa-check-circle"
      />}
      <Table className="subscription-add-ons__table">
        <thead>
          <tr>
            <Text content="SUBSCRIPTION_ADD_ONS_LIST_NAME" component="th" noMargin />
            <Text content="SUBSCRIPTION_ADD_ONS_LIST_REVENUE" component="th" noMargin />
            <Text content="SUBSCRIPTION_ADD_ONS_LIST_NUMBER_OF_UNITS" component="th" noMargin />
            <Text content="SUBSCRIPTION_ADD_ONS_LIST_PRICING_MODEL" component="th" noMargin />
            <Text content="SUBSCRIPTION_ADD_ONS_LIST_ADDED_ON" component="th" noMargin />
            <Text content="" component="th" shouldTranslate={false} noMargin />
          </tr>
        </thead>
        <tbody>{addOns && addOns.map((addOn: Addon) => (
          <SubscriptionAddOnsTiers
            addOnAllowance={addOn}
            planPricingModel={planPricingModel}
            key={addOn.getId()}
            callback={(id) => dispatch(setSubscriptionDetailsField("addOnToEdit", id))}
          />
        ))}
        </tbody>
      </Table>

    </div>
  );
};

export default SubscriptionAddOns;
