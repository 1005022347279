import React, { useEffect, useMemo } from "react";
import AddOnsWelcome from "../../components/addons-allowances/addons/AddOnsWelcome";
import Helmet from "react-helmet";
import Text from "../../components/ui/text/Text";
import Button from "../../components/ui/button/Button";
import Pagination from "../../components/ui/pagination/Pagination";
import Dropdown from "../../components/ui/dropdown/Dropdown";
import Search from "../../components/search/Search";
import counterpart from "counterpart";
import PageWrapper from "../../components/ui/layout/PageWrapper";
import Table from "../../components/ui/table/Table";
import AddOnsListRow from "./add-ons-list-row/AddOnsListRow";
import "./AddOns.scss";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../..";
import { setAddOnsFields, fetchAddOnsList, resetAddOnsList, deleteAddOn } from "../../actions/addOnsActions";
import ProgressIndicator from "../../components/ui/progress-indicator/ProgressIndicator";
import { AddonFilterType, GetAddonsResponse, Addon } from "../../utils/grpc/generated/Billsby.Protos/core/private/addons/addons_pb";
import { AddonsAllowancesScreen } from "../../models/AddonsAllowances";
import AllowancesWelcome from "../../components/addons-allowances/allowances/AllowancesWelcome";
import { GetAllowancesResponse, Allowance, AllowanceFilterType } from "../../utils/grpc/generated/Billsby.Protos/core/private/allowances/allowances_pb";
import AddOnHoc from "./addon-hoc/AddOnHOC";
import history from "../../utils/history";
import { Permission } from "../../models/Auth";
import useCheckFeaturePermission from "../../utils/hooks/useCheckFeaturePermission";

interface IAddOns {
  type: AddonsAllowancesScreen;
}

const AddOns: React.FC<IAddOns> = ({ type }) => {
  const dispatch = useDispatch();
  const { auth: { currentCompanyDomain }, addOnsData } = useSelector((state: AppState) => ({ addOnsData: state.addOnsReducer, auth: state.auth }))
  const { searchedAddOnsName, addOnsPaginated, hasAddOns, isFetching } = addOnsData;
  const isAddonAddPermitted = useCheckFeaturePermission(Permission.AddonAdd);
  const isAllowanceAddPermitted = useCheckFeaturePermission(Permission.AllowanceAdd);
  const isAddonEditPermitted = useCheckFeaturePermission(Permission.AddonEdit);
  const isAllowanceEditPermitted = useCheckFeaturePermission(Permission.AllowanceEdit);
  const isAddonDeletePermitted = useCheckFeaturePermission(Permission.AddonDelete);
  const isAllowanceDeletePermitted = useCheckFeaturePermission(Permission.AllowanceDelete);

  const disableAddNewAddonAllowance = useMemo(() => type === AddonsAllowancesScreen.ADDONS ? !isAddonAddPermitted : !isAllowanceAddPermitted, [type]);
  const disableEditAddonAllowance = useMemo(() => type === AddonsAllowancesScreen.ADDONS ? !isAddonEditPermitted : !isAllowanceEditPermitted, [type]);
  const disableDeleteAddonAllowance = useMemo(() => type === AddonsAllowancesScreen.ADDONS ? !isAddonDeletePermitted : !isAllowanceDeletePermitted, [type]);

  const isAddonScreen = type === AddonsAllowancesScreen.ADDONS;
  const selectedAddOnsFilter = (addOnsData.selectedAddOnsFilter) || (isAddonScreen
    ? { label: counterpart("ADD_ONS_LIST_FILTER_1"), value: AddonFilterType.UNSPECIFIED_AFT }
    : { label: counterpart("ALLOWANCES_FILTER_ALL"), value: AllowanceFilterType.UNSPECIFIED_AFT });

  const addOnFilters = isAddonScreen
    ?
    [
      { label: counterpart("ADD_ONS_LIST_FILTER_1"), value: AddonFilterType.UNSPECIFIED_AFT },
      { label: counterpart("ADD_ONS_LIST_FILTER_2"), value: AddonFilterType.WITH_LINKED_PLANS },
      { label: counterpart("ADD_ONS_LIST_FILTER_3"), value: AddonFilterType.WITHOUT_LINKED_PLANS },
      { label: counterpart("ADD_ONS_LIST_FILTER_4"), value: AddonFilterType.WITH_FLAT_FEE },
      { label: counterpart("ADD_ONS_LIST_FILTER_5"), value: AddonFilterType.WITH_PER_UNIT },
      { label: counterpart("ADD_ONS_LIST_FILTER_6"), value: AddonFilterType.WITH_VOLUME },
      { label: counterpart("ADD_ONS_LIST_FILTER_7"), value: AddonFilterType.WITH_TIERED },
      { label: counterpart("ADD_ONS_LIST_FILTER_8"), value: AddonFilterType.WITH_RANGED }
    ]
    :
    [
      { label: counterpart("ALLOWANCES_FILTER_ALL"), value: AllowanceFilterType.UNSPECIFIED_AFT },
      { label: counterpart("ALLOWANCES_FILTER_WITH_LINKED_PLANS"), value: AllowanceFilterType.WITH_LINKED_PLANS },
      { label: counterpart("ALLOWANCES_FILTER_WITHOUT_LINKED_PLANS"), value: AllowanceFilterType.WITHOUT_LINKED_PLANS },
      { label: counterpart("ALLOWANCES_FILTER_WITH_CAPPED_PRICE_MODEL"), value: AllowanceFilterType.WITH_CAPPED },
      { label: counterpart("ALLOWANCES_FILTER_WITH_PERUNIT_PRICE_MODEL"), value: AllowanceFilterType.WITH_PER_UNIT },
      { label: counterpart("ALLOWANCES_FILTER_WITH_VOLUME_PRICE_MODEL"), value: AllowanceFilterType.WITH_VOLUME },
      { label: counterpart("ALLOWANCES_FILTER_WITH_TIERED_PRICE_MODEL"), value: AllowanceFilterType.WITH_TIERED },
      { label: counterpart("ALLOWANCES_FILTER_WITH_RANGED_PRICE_MODEL"), value: AllowanceFilterType.WITH_RANGED }
    ]

  const fetchData = () => dispatch(fetchAddOnsList(currentCompanyDomain, 1, "", null, type));

  useEffect(() => {
    fetchData();
    return () => { dispatch(resetAddOnsList()) }
  }, [type]);

  const renderHeaderBar = (addOnsPaginated: GetAddonsResponse | GetAllowancesResponse) => {
    return (
      <>
        <div className="add-ons-list__header">
          <div className="add-ons-list__filter">
            <Dropdown
              id="add-ons-list__dropdown"
              options={addOnFilters}
              value={selectedAddOnsFilter}
              onChange={(selectedItem: any) => {
                dispatch(setAddOnsFields("selectedAddOnsFilter", selectedItem));
                dispatch(fetchAddOnsList(currentCompanyDomain, 1, searchedAddOnsName, selectedItem.value, type))
              }}
            />
            <Button
              title={type === AddonsAllowancesScreen.ADDONS ? "ADD_ONS_LIST_BTN_ADD_ADD_ON" : "ALLOWANCES_ADD_NEW"}
              icon="fal fa-plus-circle"
              id="add-ons-list-btn-add-new-product"
              shouldTranslate={true}
              buttonType="add"
              disabled={disableAddNewAddonAllowance}
              tooltipMessage={disableAddNewAddonAllowance ? "NO_ACCESS_MSG" : undefined}
              onClick={() => history.push(`/addons-allowances/${type}/create`)}
            />
          </div>
          <span className="divider" />
          <div className="add-ons-list__search">
            <Search
              placeholder={type === AddonsAllowancesScreen.ADDONS ? "ADD_ONS_LIST_SEARCH_PLACEHOLDER" : "ALLOWANCES_SEARCH_PLACEHOLDER"}
              value={searchedAddOnsName}
              onChange={evt => dispatch(setAddOnsFields("searchedAddOnsName", evt.target.value))}
              onSearch={() => { dispatch(fetchAddOnsList(currentCompanyDomain, 1, searchedAddOnsName, selectedAddOnsFilter.value, type)) }}
            />
            <Pagination
              className="add-ons-list__pagination"
              pageCount={addOnsPaginated.getPageCount()}
              isVisible={addOnsPaginated.getPageCount() > 1}
              onPageChange={({ selected }) => dispatch(fetchAddOnsList(currentCompanyDomain, selected + 1, searchedAddOnsName, selectedAddOnsFilter.value, type))}
            />
          </div>
        </div>
      </>
    );
  };

  const getTypedResponse = (): Array<Addon | Allowance> => {
    return type === AddonsAllowancesScreen.ADDONS
      ? !!(addOnsPaginated as GetAddonsResponse).getAddonsList ? (addOnsPaginated as GetAddonsResponse).getAddonsList() : []
      : !!(addOnsPaginated as GetAllowancesResponse).getAllowancesList ? (addOnsPaginated as GetAllowancesResponse).getAllowancesList() : []
  }

  const onDelete = async (data: Addon | Allowance) => {
    try {
      await dispatch(deleteAddOn(currentCompanyDomain, data.getId(), type));
      fetchData();
    }
    catch (err) { }
  }

  const renderAddOnsListTable = () => {
    return (
      <Table className="add-ons-list__table">
        <thead>
          <tr>
            <Text content="ADD_ONS_LIST_TABLE_NAME" component="th" noMargin />
            <Text content="ADD_ONS_LIST_TABLE_LINKED_PLANS" component="th" noMargin />
            <Text content={type === AddonsAllowancesScreen.ADDONS ? "ADD_ONS_LIST_TABLE_PRICING_MODEL" : "ALLOWANCES_OVERAGE_PRICING_MODEL"} component="th" noMargin />
            <Text content="ADD_ONS_LIST_TABLE_CONFIGURED_CYCLES" component="th" noMargin />
            <th />
          </tr>
        </thead>
        <tbody>
          {getTypedResponse().map(addon => (
            <AddOnsListRow key={addon.getId()} data={addon} disableDelete={disableDeleteAddonAllowance} disableEdit={disableEditAddonAllowance} type={type} onDelete={onDelete} />
          ))}
        </tbody>
      </Table>
    );
  };

  if (!addOnsPaginated) {
    return <ProgressIndicator color="blue" coverage="full-content" />;
  }

  if (!hasAddOns) {
    return (
      <>
        <Helmet title={counterpart(type === AddonsAllowancesScreen.ADDONS ? "NAVBAR_ADDONS" : "NAVBAR_ADDONS_ALLOWANCES")} />
        <PageWrapper>
          {type === AddonsAllowancesScreen.ADDONS ? <AddOnsWelcome /> : <AllowancesWelcome />}
        </PageWrapper>
      </>
    );
  }

  return (
    <>
      <Helmet title={counterpart(type === AddonsAllowancesScreen.ADDONS ? "NAVBAR_ADDONS" : "NAVBAR_ADDONS_ALLOWANCES")} />
      {renderHeaderBar(addOnsPaginated)}
      {/* <ErrorNotification title={
        isAddonScreen ? isEditingAddOnFailure ? "EDIT_ADDON_FAILURE" : "CREATE_ADDON_FAILURE" :
          isEditingAddOnFailure ? "EDIT_ALLOWANCE_FAILURE" : "CREATE_ALLOWANCE_FAILURE"
      } showAlert={isCreatingAddOnFailure || isEditingAddOnFailure} /> */}
      <PageWrapper>
        {isFetching ? <ProgressIndicator color="blue" /> : renderAddOnsListTable()}
        {type === AddonsAllowancesScreen.ADDONS ? <AddOnsWelcome withAddOns /> : <AllowancesWelcome withAddOns />}
      </PageWrapper>
    </>
  );
};

export default AddOnHoc(AddOns)
