import { IScaState } from "../reducers/scaReducer";
import { makeActionCreator, makeGenericActionCreatorTypeSafe } from "../utils/reduxUtils";


export const SET_IS_SETTING_UP_SCA = "SET_IS_SETTING_UP_SCA";
export const SET_UP_SCA_SETTINGS = "SET_UP_SCA_SETTINGS";
export const RESET_SCA_SETTINGS_SETUP = "RESET_SCA_SETTINGS_SETUP";
export const SET_STRIPE_SCA_VALUES = "SET_STRIPE_SCA_VALUES";
export const RESET_SCA = "RESET_SCA";
export const LOAD_PREVIOUS_SCA_STATE = "LOAD_PREVIOUS_SCA_STATE";

export const SET_SCA_FIELD = "SET_SCA_FIELD";
export const setScaField = makeGenericActionCreatorTypeSafe<IScaState>(SET_SCA_FIELD);

export const setStripScaValues = makeActionCreator(SET_STRIPE_SCA_VALUES, "payload");
export const setupScaSettings = makeActionCreator(SET_UP_SCA_SETTINGS, "payload");
export const resetScaSettingsSetup = makeActionCreator(RESET_SCA_SETTINGS_SETUP, "payload");
export const loadPreviousScaState = makeActionCreator(LOAD_PREVIOUS_SCA_STATE, "payload");
