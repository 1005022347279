import React, { useLayoutEffect, useMemo, useState } from "react";
import { IPaymentGatewaysReducer } from "../../../../reducers/paymentGatewaysReducer";
import "./PaymentGateways.scss";
import Panel from "../../../ui/panel/Panel";
import Text from "../../../ui/text/Text";
import { IAuthReducerState } from "../../../../reducers/authReducer";
import InteractiveList from "../../../ui/interactive-list/InteractiveList";
import ErrorPanel from "../../../ui/error-panel/ErrorPanel";
import {
  fetchPaymentGateways,
  setPaymentGatewaysField,
  resetPaymentGatewaysState,
  RESTORE_PAYMENT_GATEWAY_STATE
} from "../../../../actions/paymentGatewaysActions";
import { IPaymentGatewayElement, PaymentGatewaysType } from "../../../../models/Configuration";
import queryString from "query-string";
import history from "../../../../utils/history";
import NavigationBar from "../../../ui/navigation-bar/NavigationBar";
import { AppState } from "../../../..";
import { useDispatch, useSelector } from "react-redux";
import PageWrapper from "../../../ui/layout/PageWrapper";
import NavigationAlertCurrencyMismatch from "../../../ui/navigation-alert/NavigationAlertCurrencyMismatch";
import ProgressIndicator from "../../../ui/progress-indicator/ProgressIndicator";
import StripePaymentGateway from "./stripe-payment-gateway/StripePaymentGateway";
import CheckoutPaymentGateway from "./checkout-payment-gateway/CheckoutPaymentGateway";
import AuthorizePaymentGateway from "./authorize-payment-gateway/AuthorizePaymentGateway";
import BrainTreePaymentGateway from "./braintree-payment-gateway/BrainTreePaymentGateway";
import AdyenPaymentGateway from "./adyen-payment-gateway/AdyenPaymentGateway";
import StripePaymentGatewayUpdate from "./stripe-payment-gateway/StripePaymentGatewayUpdate";
import CheckoutPaymentGatewayUpdate from "./checkout-payment-gateway/CheckoutPaymentGatewayUpdate";
import AdyenPaymentGatewayUpdate from "./adyen-payment-gateway/AdyenPaymentGatewayUpdate";
import AuthorizePaymentGatewayUpdate from "./authorize-payment-gateway/AuthorizePaymentGatewayUpdate";
import BrainTreePaymentGatewayUpdate from "./braintree-payment-gateway/BrainTreePaymentGatewayUpdate";
import IxopayPaymentGatewayUpdate from "./ixopay-payment-gateway/IxopayPaymentGatewayUpdate";
import IxopayPaymentGateway from "./ixopay-payment-gateway/IxopayPaymentGateway";
import NMIPaymentGateway from "./nmi-payment-gateway/NMIPaymentGateway";
import NMIPaymentGatewayUpdate from "./nmi-payment-gateway/NMIPaymentGatewayUpdate";
import CloverConnectPaymentGateway from "./clover-connect-payment-gateway/CloverConnectPaymentGateway";
import CloverConnectPaymentGatewayUpdate from "./clover-connect-payment-gateway/CloverConnectPaymentGatewayUpdate";
import PaymentCloudPaymentGateway from "./payment-cloud-payment-gateway/PaymentCloudPaymentGateway";
import PaymentCloudPaymentGatewayUpdate from "./payment-cloud-payment-gateway/PaymentCloudPaymentGatewayUpdate";
import InteractiveMenuPanel from "./interactive-menu-panel/InteractiveMenuPanel";
import { COLOR } from "../../../../utils/constants";
import goCardless from "../../../../images/gateways/goCardless.png";
import API from "../../../../utils/API";
import { IUser } from "../../../../models/User";
import Helmet from "react-helmet";
import counterpart from "counterpart";
import ErrorNotification from "../../../ui/error-notification/ErrorNotification";
import NoticePanel from "../../../ui/notice-panel/NoticePanel";
import { useIsFromSpecificPage } from "../../../../utils/custom-hooks";
import { setShowBillingModal } from "../../../../actions/authActions";
import { Permission } from "../../../../models/Auth";
import { Link } from "react-router-dom";


const isLockedAddGetaway = (paymentGatewaysReducer: IPaymentGatewaysReducer) => {
  const { selectedPaymentGateway, paymentGateways } = paymentGatewaysReducer;
  const isOneAlreadyAdded = !!selectedPaymentGateway;
  const isOnePending = paymentGateways && paymentGateways.some(gateway => !!gateway.mustActivate);
  return isOneAlreadyAdded || isOnePending;
};

const PaymentGateways: React.FC = () => {
  const [isLoadingData, setIsLoadingData] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [userDetails, setUserDetails] = useState<IUser | undefined>(undefined);
  const { companyName, currentCompanyDomain } = useSelector<AppState, IAuthReducerState>(state => state.auth);
  const paymentGatewaysReducer = useSelector<AppState, IPaymentGatewaysReducer>(state => state.paymentGatewaysReducer);
  const { selectedPaymentGateway, paymentGateways, paymentGatewaysLogos, isCreatingGateway, 
    linkFromSca, replacedPaymentGateway } = paymentGatewaysReducer

  const dispatch = useDispatch<Function>();
	const isFromChecklist = useIsFromSpecificPage("/launch-checklist");
  const isFromBillingModal = useIsFromSpecificPage("billing-modal");
	const isOneGatewayPending = paymentGateways?.some(g => !!g.mustActivate) || false;

  const { parsedToken } = useSelector((state: AppState) => state.auth)

  const hasPermissionAddPaymentGateway = useMemo(() => {
    if(replacedPaymentGateway?.isReplaceable) {
      return true;
    }
    if((paymentGateways?.length === 1 && parsedToken?.Limitations.PaymentGateway === 1)  || (parsedToken?.Limitations.PaymentGateway === 0)) {
      return false
    }
    return parsedToken?.CurrentPermissions.some(p => p === Permission[Permission.PaymentGatewaysRead])
  }, [parsedToken, paymentGateways, replacedPaymentGateway])


  useLayoutEffect(() => {
    const fetchData = async () => {
      setIsLoadingData(true);
      try {
        await dispatch(fetchPaymentGateways(currentCompanyDomain));
        await fetchUserDetails();
        setIsLoadingData(false);
      } catch (err) {
        setIsLoadingData(false);
      }
    };

    dispatch({ type: RESTORE_PAYMENT_GATEWAY_STATE });
    fetchData();

    return () => {
      dispatch(resetPaymentGatewaysState());
    };
  }, []);
  const fetchUserDetails = async () => {
    try {
      const userDetails = (await API.getUserDetails()) as IUser;
      setUserDetails(userDetails);
    } catch(err){}
  }
  const sortFunc = (itemA: IPaymentGatewayElement, itemB: IPaymentGatewayElement) => {
    if(itemA.title.toLowerCase() === itemB.title.toLowerCase()) {
      return (itemA.paymentGatewayId as number) > (itemB.paymentGatewayId as number) ? 1 : -1;
    } else {
      return itemA.title.toLowerCase() > itemB.title.toLowerCase() ? 1 : -1;
    }
  }

  const sortedPaymentGateways = useMemo(() => paymentGateways?.sort((a, b) => sortFunc(a, b)).sort((a, b) => (a.type === PaymentGatewaysType.STRIPE) ? -1 : 0), [paymentGateways])


  if (isLoadingData) {
    return <ProgressIndicator color="blue" coverage="full-content"></ProgressIndicator>
  }

  if (!paymentGateways) {
    return null;
  }

  const onError = () => {
    setHasError(true);
  }
  const joinGoCardlessWaitingList = async (bool: boolean) => {
    try {
      await API.joinGoCardlessWaitingList(bool);
      await fetchUserDetails()
    } catch(err){}
  }
  const paymentGatewayOnClickHandler = (paymentGateway: any) => {
    const isLocked = isLockedAddGetaway(paymentGatewaysReducer);
    if (isLocked) {
      return null;
    }
    dispatch(setPaymentGatewaysField("selectedPaymentGateway", paymentGateway));
    if(replacedPaymentGateway) {
      document.querySelector(".payment-gateways__added")?.scrollIntoView?.({behavior: "smooth"});
    }
    else {
      document.querySelector(".payment-gateways__added")?.lastElementChild?.scrollIntoView?.({behavior: "smooth"});
    }
  }

  const getAddPaymentGatewayComponent = () => {

    if (!selectedPaymentGateway) {
      return null;
    }
    switch (selectedPaymentGateway.type) {
      case PaymentGatewaysType.STRIPE: 
      case PaymentGatewaysType.STRIPEINTENT:
        return <StripePaymentGateway />
      case PaymentGatewaysType.CHECKOUT:
        return <CheckoutPaymentGateway onError={onError} />
      case PaymentGatewaysType.AUTHORIZE:
        return <AuthorizePaymentGateway onError={onError}/>
      case PaymentGatewaysType.BRAINTREE:
        return <BrainTreePaymentGateway />
      case PaymentGatewaysType.ADYEN:
        return <AdyenPaymentGateway onError={onError} />
      case PaymentGatewaysType.IXOPAY:
        return <IxopayPaymentGateway onError={onError} />
      case PaymentGatewaysType.NMI:
        return <NMIPaymentGateway onError={onError} />
      case PaymentGatewaysType.PAYMENTCLOUD:
        return <PaymentCloudPaymentGateway onError={onError} />
      case PaymentGatewaysType.CLOVERCONNECT:
        return <CloverConnectPaymentGateway onError={onError} />
      default:
        return null;
    }
  }

  const getUpdatePaymentGatewayComponent = (paymentGateway: IPaymentGatewayElement,
    isOneGatewayPending: boolean,
    currentGatewayState: any,
    idx: number,
    isCreatingGateway: boolean,
    type: PaymentGatewaysType) => {

    const commonProps = {
      onError,
      idx,
      paymentGateway,
      isOneGatewayPending,
      currentGatewayState,
      isCreatingGateway
    }
    switch (type) {
      case PaymentGatewaysType.STRIPE:        
      case PaymentGatewaysType.STRIPEINTENT:
        return <StripePaymentGatewayUpdate { ...commonProps } />
      case PaymentGatewaysType.CHECKOUT:
        return <CheckoutPaymentGatewayUpdate { ...commonProps } />
      case PaymentGatewaysType.AUTHORIZE:
        return <AuthorizePaymentGatewayUpdate { ...commonProps } />
      case PaymentGatewaysType.BRAINTREE:
        return  <BrainTreePaymentGatewayUpdate { ...commonProps } />
      case PaymentGatewaysType.ADYEN:
        return  <AdyenPaymentGatewayUpdate { ...commonProps } />
      case PaymentGatewaysType.IXOPAY:
        return <IxopayPaymentGatewayUpdate { ...commonProps } />
      case PaymentGatewaysType.NMI:
        return <NMIPaymentGatewayUpdate { ...commonProps } />
      case PaymentGatewaysType.PAYMENTCLOUD:
        return <PaymentCloudPaymentGatewayUpdate { ...commonProps } />
      case PaymentGatewaysType.CLOVERCONNECT:
        return <CloverConnectPaymentGatewayUpdate { ...commonProps } />
      default:
        return null;
    }
  }

  const getNavBarData = () => {
    const data: { title: string, cb: () => void } = { title: "CONFIGURATION_PAGE_TITLE", cb: () => history.push("/configuration") };
    if(linkFromSca) {
      data.title = "CONFIGURATION_SCA_TITLE";
      data.cb = () => history.push("/configuration/sca");
      return data;
    } 
    
    if(isFromChecklist) {
      data.title = "GO_LIVE_PRO_PLAN_FEATURE_NAVBAR";
      data.cb = () => history.push({ pathname: "/launch-checklist", state: { hasprofeature: true } });
      return data;
    } 

    if(isFromBillingModal) {
      data.title = "GO_LIVE_PRO_PLAN_FEATURE_NAVBAR";
      data.cb = () => dispatch(setShowBillingModal(true))
      return data;
    } 

    return data;
  } 

  return (
    <div className="payment-gateways">
      <Helmet title={counterpart("CONFIGURATION_BASIC_PAYMENT_GATEWAYS_TITLE")} />
      <NavigationBar
        pageTitle=""
        previousPageTitle={getNavBarData().title}
        previousPageCb={getNavBarData().cb} />

      <NavigationAlertCurrencyMismatch />

      <PageWrapper>
        <Panel title="CONFIGURATION_BASIC_PAYMENT_GATEWAYS_TITLE" className="payment-gateways__welcome">
          <Text content="PAYMENT_GATEWAYS_HEADER_CONTENT" noMargin />
        </Panel>

        {!userDetails?.goCardlessIsLiveBannerDismissedOn && !userDetails?.goCardlessIsLiveNotificationRequestedOn && <ErrorPanel className="payment-gateways__go-cardless" title="GO_CARDLESS_TITLE" image={goCardless} subtitle="GO_CARDLESS_CONTENT" buttonText="JOIN_WAITING_LIST_BTN" buttonCb={() => joinGoCardlessWaitingList(true)} closeCb={() => joinGoCardlessWaitingList(false)} />}

        <InteractiveMenuPanel/>

        {selectedPaymentGateway || paymentGateways.length ? (
          <Panel title="PAYMENT_GATEWAYS_COMPANY_GATEWAYS" className={"payment-gateways__added"} translateWith={{ companyName }}>
            
            {/* Existing payment gateways (and potentially a pending payment gateway) */
              sortedPaymentGateways?.map((paymentGateway: IPaymentGatewayElement, idx: number) => {
                  const currentGatewayState = (paymentGatewaysReducer as any)[`paymentGateway${idx}`] || {
                    isCollapsed: true,
                    displayName: paymentGateway.title,
                    paymentGatewayId: paymentGateway.paymentGatewayId
                  };
                
                  const createdPaymentGatewayId = Number(queryString.parse(window.location.search).createdGatewayId);
                  const isCreatedSuccess = paymentGateway.paymentGatewayId && (paymentGateway.paymentGatewayId === paymentGatewaysReducer.createdPaymentGatewayId || paymentGateway.paymentGatewayId === createdPaymentGatewayId) && currentGatewayState.isCollapsed;
 
                  return (
                    <div key={idx} className={`${isCreatedSuccess ? "payment-gateways__creation-succeed" : ""} ${replacedPaymentGateway && !paymentGateway.mustActivate && (!selectedPaymentGateway || (paymentGateway?.paymentGatewayId !== replacedPaymentGateway?.paymentGatewayId)) ? "payment-gateways__added--locked" : ""}`}>
                      { 
                        (!selectedPaymentGateway || paymentGateway.mustActivate || paymentGateway.paymentGatewayId !== replacedPaymentGateway?.paymentGatewayId)
                          ? getUpdatePaymentGatewayComponent(paymentGateway, isOneGatewayPending, currentGatewayState, idx, isCreatingGateway, paymentGateway.type)
                          : null
                      }
                      {
                        (selectedPaymentGateway && !isOneGatewayPending && paymentGateway.paymentGatewayId === replacedPaymentGateway?.paymentGatewayId) && (
                          getAddPaymentGatewayComponent()
                        )
                      }


                      <NoticePanel isVisible={isCreatedSuccess} content='PAYMENT_GATEWAYS_SUCCESS_CREATION' type='warning' />                 
                    </div>
                  );
                })}

            {/* Add new payment gateway */
              (selectedPaymentGateway && !paymentGateways.some(g => !!g.mustActivate) && !replacedPaymentGateway) && (
                getAddPaymentGatewayComponent()
              )}
          </Panel>
        ) : null}

        <NoticePanel
          type="warning"
          icon="far fa-lightbulb-exclamation"
          content="PAYMENT_GATEWAYS_WARNING_PERMISSION"
          translateWith={{
            here: <Link to="" id="billing-modal-link" onClick={(evt) => { dispatch(setShowBillingModal(true)); evt.preventDefault();}}>{counterpart("REPORTS_ACCOUNTING_WARNING_PERMISSION_HERE")}</Link>
          }}
          isVisible={!hasPermissionAddPaymentGateway}
        />
        <NoticePanel
          type="warning"
          icon="far fa-lightbulb-exclamation"
          content="PAYMENT_GATEWAYS_WARNING_REPLACING_GATEWAY"
          isVisible={!!replacedPaymentGateway}
        />

        <Panel id="payment-gateways__available" title={!!replacedPaymentGateway ? "PAYMENT_GATEWAYS_CHOOSE_NEW" : "PAYMENT_GATEWAYS_ADD"}>
          <InteractiveList
            effectOnHover={false}
            className="payment-gateways__available-list"
            data={[              
              {
                title: "PAYMENT_GATEWAYS_ADYEN_TITLE",
                subTitle: "",
                statusLabel: "",
                icon: "fal fa-plus-circle",
                type: PaymentGatewaysType.ADYEN,
                logo: paymentGatewaysLogos[PaymentGatewaysType.ADYEN],
                isLogoFontAwesome: false,
                onClick: paymentGatewayOnClickHandler,
                isElementClickable: false,
                isLocked: !hasPermissionAddPaymentGateway || isLockedAddGetaway(paymentGatewaysReducer),
                helpTitle: "PAYMENT_GATEWAYS_ADDED_ADYEN_DISPLAYNAME_HELP_TITLE",
                helpContent: ["PAYMENT_GATEWAYS_ADDED_ADYEN_HELP_CONTENT_1", "PAYMENT_GATEWAYS_ADDED_ADYEN_HELP_CONTENT_2"]
              },
              {
                title: "PAYMENT_GATEWAYS_AUTHORIZE_TITLE",
                subTitle: "",
                statusLabel: "",
                icon: "fal fa-plus-circle",
                type: PaymentGatewaysType.AUTHORIZE,
                logo: paymentGatewaysLogos[PaymentGatewaysType.AUTHORIZE],
                isLogoFontAwesome: false,
                onClick: paymentGatewayOnClickHandler,
                isElementClickable: false,
                isLocked: !hasPermissionAddPaymentGateway || isLockedAddGetaway(paymentGatewaysReducer),
                helpTitle: "PAYMENT_GATEWAYS_ADDED_DISPLAYNAME_HELP_TITLE",
                helpContent: ["PAYMENT_GATEWAYS_ADDED_DISPLAYNAME_HELP_CONTENT"]
              },
              {
                title: "PAYMENT_GATEWAYS_BRAINTREE_TITLE",
                subTitle: "",
                statusLabel: "",
                icon: "fal fa-plus-circle",
                type: PaymentGatewaysType.BRAINTREE,
                logo: paymentGatewaysLogos[PaymentGatewaysType.BRAINTREE],
                isLogoFontAwesome: false,
                onClick: paymentGatewayOnClickHandler,
                isElementClickable: false,
                isLocked: !hasPermissionAddPaymentGateway || isLockedAddGetaway(paymentGatewaysReducer),
                helpTitle: "PAYMENT_GATEWAYS_ADDED_BRAINTREE_DISPLAYNAME_HELP_TITLE",
                helpContent: ["PAYMENT_GATEWAYS_ADDED_BRAINTREE_HELP_CONTENT_1", "PAYMENT_GATEWAYS_ADDED_BRAINTREE_HELP_CONTENT_2"]
              },
              {
                title: "PAYMENT_GATEWAYS_CLOVER_CONNECT_TITLE",
                subTitle: "",
                statusLabel: "",
                icon: "fal fa-plus-circle",
                type: PaymentGatewaysType.CLOVERCONNECT,
                logo: paymentGatewaysLogos[PaymentGatewaysType.CLOVERCONNECT],
                isLogoFontAwesome: false,
                onClick: paymentGatewayOnClickHandler,
                isElementClickable: false,
                isLocked: !hasPermissionAddPaymentGateway || isLockedAddGetaway(paymentGatewaysReducer),
                customStatus: [                  
                  { label: "INTERACTIVE_MENU_PANEL_PREFERRED_PRICING", color: COLOR.ORANGE, icon: "far fa-money-bill-wave"}
                ],
                helpTitle: "PAYMENT_GATEWAYS_ADDED_DISPLAYNAME_HELP_TITLE",
                helpContent: ["PAYMENT_GATEWAYS_ADDED_DISPLAYNAME_HELP_CONTENT"]
              },
              {
                title: "PAYMENT_GATEWAYS_CHECKOUT_TITLE",
                subTitle: "",
                statusLabel: "",
                icon: "fal fa-plus-circle",
                type: PaymentGatewaysType.CHECKOUT,
                logo: paymentGatewaysLogos[PaymentGatewaysType.CHECKOUT],
                isLogoFontAwesome: false,
                onClick: paymentGatewayOnClickHandler,
                isElementClickable: false,
                isLocked: !hasPermissionAddPaymentGateway || isLockedAddGetaway(paymentGatewaysReducer),
                helpTitle: "PAYMENT_GATEWAYS_ADDED_DISPLAYNAME_HELP_TITLE",
                helpContent: ["PAYMENT_GATEWAYS_ADDED_DISPLAYNAME_HELP_CONTENT"]
              },
              {
                title: "PAYMENT_GATEWAYS_IXOPAY_TITLE",
                subTitle: "",
                statusLabel: "",
                icon: "fal fa-plus-circle",
                type: PaymentGatewaysType.IXOPAY,
                logo: paymentGatewaysLogos[PaymentGatewaysType.IXOPAY],
                isLogoFontAwesome: false,
                onClick: paymentGatewayOnClickHandler,
                isElementClickable: false,
                isLocked: !hasPermissionAddPaymentGateway || isLockedAddGetaway(paymentGatewaysReducer),
                helpTitle: "PAYMENT_GATEWAYS_ADDED_DISPLAYNAME_HELP_TITLE",
                helpContent: ["PAYMENT_GATEWAYS_ADDED_DISPLAYNAME_HELP_CONTENT"]
              },
              {
                title: "PAYMENT_GATEWAYS_NMI_TITLE",
                subTitle: "",
                statusLabel: "",
                icon: "fal fa-plus-circle",
                type: PaymentGatewaysType.NMI,
                logo: paymentGatewaysLogos[PaymentGatewaysType.NMI],
                isLogoFontAwesome: false,
                onClick: paymentGatewayOnClickHandler,
                isElementClickable: false,
                isLocked: !hasPermissionAddPaymentGateway || isLockedAddGetaway(paymentGatewaysReducer),
                helpTitle: "PAYMENT_GATEWAYS_ADDED_DISPLAYNAME_HELP_TITLE",
                helpContent: ["PAYMENT_GATEWAYS_ADDED_DISPLAYNAME_HELP_CONTENT"]
              },
              {
                title: "PAYMENT_GATEWAYS_PAYMENTCLOUD_TITLE_VIA_AUTHORIZE_NET",
                subTitle: "",
                statusLabel: "",
                icon: "fal fa-plus-circle",
                type: PaymentGatewaysType.PAYMENTCLOUD,
                logo: paymentGatewaysLogos[PaymentGatewaysType.PAYMENTCLOUD],
                isLogoFontAwesome: false,
                onClick: paymentGatewayOnClickHandler,
                isElementClickable: false,
                isLocked: !hasPermissionAddPaymentGateway || isLockedAddGetaway(paymentGatewaysReducer),
                customStatus: [                  
                  { label: "INTERACTIVE_MENU_PANEL_ACCEPT_HIGH_RISK", color: COLOR.GREEN, icon: "far fa-check-circle"},
                  { label: "INTERACTIVE_MENU_PANEL_PREFERRED_PRICING", color: COLOR.ORANGE, icon: "far fa-money-bill-wave"}
                ],
                helpTitle: "PAYMENT_GATEWAYS_ADDED_DISPLAYNAME_HELP_TITLE",
                helpContent: ["PAYMENT_GATEWAYS_ADDED_DISPLAYNAME_HELP_CONTENT"]
              },
              {
                title: "PAYMENT_GATEWAYS_STRIPE_TITLE",
                subTitle: "",
                statusLabel: "",
                icon: "fal fa-plus-circle",
                type: PaymentGatewaysType.STRIPE,
                logo: paymentGatewaysLogos[PaymentGatewaysType.STRIPE],
                isLogoFontAwesome: false,
                onClick: paymentGatewayOnClickHandler,
                isElementClickable: false,
                isLocked: !hasPermissionAddPaymentGateway || isLockedAddGetaway(paymentGatewaysReducer),
                helpTitle: "PAYMENT_GATEWAYS_STRIPE_HELP_TITLE",
                helpContent: ["PAYMENT_GATEWAYS_STRIPE_HELP_CONTENT_1", "PAYMENT_GATEWAYS_STRIPE_HELP_CONTENT_2", "PAYMENT_GATEWAYS_STRIPE_HELP_CONTENT_3"]
              }
            ]}
          />
        </Panel>
        <ErrorPanel title="PAYMENT_GATEWAYS_TALK_TO_US_TITLE" subtitle="PAYMENT_GATEWAYS_TALK_TO_US_SUBTITLE" icon="fas fa-credit-card" />

        {
          // HIDDEN FOR NOW
          /*
          <StepCollapsablePanel
            isCollapsed={false}
            isShowTitleIcon={false}
            onClick={() => {

            }}
            onFocus={() => { }}
            hasError={false}
            title="PAYMENT_GATEWAYS_TRANSATION_IDENTIFIER_TITLE"
            helpMenuPosition="top"
            helpMenuContent={
              <Text content="PAYMENT_GATEWAYS_TRANSATION_IDENTIFIER_HELP_CONTENT" noMargin />
            }
          >
            <FormGroup>
              <Input
                id="transaction-identifier-input"
                placeholder={companyName}
                onChange={() => { }}
              />
            </FormGroup>

            <Button
              id="transaction-update-id"
              title="PAYMENT_GATEWAYS_TRANSATION_IDENTIFIER_UPDATE_BTN"
              onClick={() => { }}
              isFullWidth
            />
          </StepCollapsablePanel>

          <StepCollapsablePanel
            title="PAYMENT_GATEWAYS_BILLSBY_VAULT"
            isCollapsed={true}
          />
          */
        }
      </PageWrapper>
      <ErrorNotification title="GENERIC_ERROR" showAlert={hasError} onClose={() => setHasError(false)} />
    </div>
  );
};

export const isInvalidGatewayFormsDetails = (arr: Array<string>) => {
  return arr.some(detail => detail === "" || detail === undefined || detail === null)
}
export default PaymentGateways
