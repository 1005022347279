import { FrequencyType } from "./Product";
import { SubscriptionStatusType } from "./Subscriptions";
import { FrequencyType as DiscountFrequencyType } from "../../src/utils/grpc/generated/Billsby.Protos/core/private/discounts/discounts_pb";

export enum EventLogType {
  SignUpV1 = "SignUpV1",
  PaymentFailedV1 = "PaymentFailedV1",
  PaymentSuccessV1 = "PaymentSuccessV1",
  PlanChangedV1 = "PlanChangedV1",
  InvoiceSentV1 = "InvoiceSentV1",
  AddedItemV1 = "AddedItemV1",
  RemovedItemV1 = "RemovedItemV1",
  UpdatedItemV1 = "UpdatedItemV1",
  UsedItemV1 = "UsedItemV1",
  PausedV1 = "PausedV1",
  ResumedV1 = "ResumedV1",
  CancelledV1 = "CancelledV1",
  DiscountRemovedV1 = "DiscountRemovedV1",
  DiscountAddedV1 = "DiscountAddedV1",
  CycleChangedV1 = "CycleChangedV1",
  RenewalDateChangedV1 = "RenewalDateChangedV1",
  UpdateStatusV1 = "UpdateStatusV1",
  TaxNotAppliedV1 = "TaxNotAppliedV1"
}

export enum RelatedItemType {
  Addon = "Addon",
  Allowance = "Allowance",
  Coupon = "Coupon"
}

export interface IEventLog {
  subscriptionEventLogId: number,
  type: EventLogType,
  createdOn: Date,
  eventData: ISignUpEventLog | IPaymentEventLog | IPlanChangedEventLog | IInvoiceSentEventLog
  | IRelatedItemEventLog | IAddedItem | IUpdatedItem | IRemovedItem | IDiscountAdded | IDiscountRemoved
  | ICycleChangedEventLog | IRenewalDateChanged | IUpdateStatus | ITaxNotApplied
}

export interface ISignUpEventLog {
  planId: number,
  planName: string,
  firstName: string,
  lastName: string,
}

export interface ICycleChangedEventLog {
  firstName: string,
  lastName: string,
  frequency: number
  frequencyType: FrequencyType
  newBillsbyCycleId: number
  oldBillsbyCycleId: number
  price: number,
  priceFormatted: string,
  userId: number
}

export interface IPaymentEventLog {
  planName: string,
  amount: number,
  firstName: string,
  lastName: string
}

export interface IPlanChangedEventLog {
  fromPlanId: number,
  fromPlanName: string,
  toPlanId: number,
  toPlanName: string,
  firstName: string,
  lastName: string
}

export interface IInvoiceSentEventLog {
  email: string,
  amount: number,
  firstName: string,
  lastName: string
}

export interface IRelatedItemEventLog {
  itemName: string,
  firstName: string,
  lastName: string,
  type: RelatedItemType,
  addedOn?: Date,
  removedOn?: Date,
  updatedOn?: Date
}

export interface IPausedEventLog {
  planId: number,
  planName: string,
  firstName: string,
  lastName: string
}

export interface IResumedEventLog {
  planId: number,
  planName: string,
  firstName: string,
  lastName: string
}

export interface ICancelEventLog {
  planId: number,
  planName: string,
  firstName: string,
  lastName: string
}

export interface IAddedItem {
  itemId: number,
  itemName: string,
  usedId: number,
  firstName: string,
  lastName: string,
  type: RelatedItemType,
  addedOn: Date
}

export interface IRemovedItem {
  itemId: number,
  itemName: string,
  usedId: number,
  firstName: string,
  lastName: string,
  type: RelatedItemType,
  removedOn: Date
}

export interface IUpdatedItem {
  itemId: number,
  itemName: string,
  usedId: number,
  firstName: string,
  lastName: string,
  type: RelatedItemType,
  updatedOn: Date
}

export interface IDiscountAdded {
  createdOn: Date,
  discountName: string,
  firstName: string,
  lastName: string,
  frequencyType: DiscountFrequencyType,
  frequency: number,
  isLifetime: boolean,
  expiresOn?: Date
}

export interface IDiscountRemoved {
  createdOn: Date,
  discountName: string,
  firstName: string,
  lastName: string,
}

export interface IRenewalDateChanged {
  createdOn: Date
  newBillDate: Date,
  firstName: string,
  lastName: string,
}

export interface IUpdateStatus {
  firstName: string
  lastName: string
  newStatus: SubscriptionStatusType
  oldStatus: SubscriptionStatusType
  updatedOn: Date
}

export interface ITaxNotApplied {
  firstName: string,
  lastName: string,
  invoiceId: string,
  createdOn: Date,
  errorMessage: string
}