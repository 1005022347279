import React from "react";
import counterpart from "counterpart";
import { Link } from "react-router-dom";

interface ISideNavItem {
  icon: string,
  content: string,
  className?: string
  shouldTranslate?: boolean,
  isEnabled?: boolean,
  isActive: boolean
  setSelectedItem?: () => void,
  callBack?: () => void
}

const SideNavItem = React.memo((props: ISideNavItem) => {
  const { icon, content, shouldTranslate = true, isEnabled = false, className = "", isActive = false, setSelectedItem = () => { }, callBack = () => { } } = props;
  
  const handleOnclickEvent = () => {
    setSelectedItem();
    callBack();
  }

  return (
    <div className={`side_navbar__content-body__item ${!isEnabled ? "side_navbar__content-body__item--locked" : ""} ${className}`}>
      <Link onClick={handleOnclickEvent}
        className={isActive ? "side_navbar__content-body__item-line link-active" : "side_navbar__content-body__item-line"}
        to={"#"}
      >
        <i className={icon} />
        <div className="side_navbar__content-body__item-name">
          {shouldTranslate ? counterpart(content) : content}
        </div>
      </Link>
    </div>
  )
})

export default SideNavItem