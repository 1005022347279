import React from "react";
import Slider, { createSliderWithTooltip } from "rc-slider";

import "rc-slider/assets/index.css";
import "./RangeSlider.scss";

const SliderWithTooltip = createSliderWithTooltip(Slider);

/**
 * TO DO: Maintain a consistent width between the range markers even if the markers are uneven numbers
 */

interface IRangeSlider {
  /**
  * Minimum value
  */
  min: number,
  /**
  * Maximum value
  */
  max: number,
  /**
  * Default value
  */
  defaultValue: number,
  /**
  * Current value
  */
  value?: number,
  /**
  * Range markers
  */
  marks: any,
  /**
  * How much each 
  */
  step: number | null,
  /**
  * Customise the text shown in the tooltip
  */
  tipFormatter?: (value: number) => string,
  /**
  * Add props that allow you to add classnames etc. See the tooltips .ts file for a full breakdown
  */
  tipProps?: Object,
  /**
  * Use this hook to capture the chosen value chosen. Selected instead of onChange, which triggers after each change
  */
  onChange: (value: number) => any,
  /**
   * additional classnames
   */
  className?: string,
  [key: string]: any
}


const RangeSlider: React.FC<IRangeSlider> = (props) => {

  const { min, max, defaultValue, marks, step, tipFormatter, tipProps, onChange, value = 0, className = "", ...rest } = props;

  return (
    <div className="range-slider">
      <SliderWithTooltip
        className={className}
        min={min}
        max={max}
        defaultValue={defaultValue}
        marks={marks}
        step={step}
        tipFormatter={tipFormatter}
        tipProps={tipProps}
        onChange={onChange}
        value={value}
        {...rest}
      />
    </div>
  );
}

export default RangeSlider;