import React from "react";
import moment from "moment";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "./DateTimePicker.scss";
import Text from "../text/Text";

export interface IDateTimePicker {
  value?: Date;
  className?: string;
  startDate?: string;
  endDate?: string;
  showTimeInput?: boolean;
  minDate?: Date | null;
  maxDate?: Date | null;
  filterDate?: (date: Date) => boolean;
  onChange: (value: any) => void;
}

const DateTimePicker: React.FC<IDateTimePicker> = React.memo(({ value, onChange, className = "", startDate, endDate, showTimeInput = true, filterDate, minDate, maxDate }) => {
  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December"
  ];
  const dateTimeFormat = showTimeInput ? "MM/dd/yyyy h:mm aa" : "MM/dd/yyyy";
  return (
    <div className={`custom-date-time-picker ${className}`}>
      {!value && <div className="custom-date-time-picker__placeholder"><i className="far fa-calendar-day"/> <Text noMargin content="CHANGE_BILL_DATE_SELECT"></Text></div>}
      <DatePicker
        className="custom-date-time-picker__component"
        renderCustomHeader={({
          date,
          decreaseMonth,
          increaseMonth,
        }) => (
            <div>
              <div className="react-datepicker__triangle"></div>
              <button type="button" onClick={decreaseMonth} className="react-datepicker__navigation react-datepicker__navigation--previous" aria-label="Previous month"></button>
              <button type="button" onClick={increaseMonth} className="react-datepicker__navigation react-datepicker__navigation--next" aria-label="Next month"></button>
              <div className="react-datepicker__header react-datepicker-month-header">{months[(date).getMonth()]} {date.getFullYear()}</div>
            </div>
          )}
          showTimeInput={showTimeInput}
          startDate={startDate ? moment.utc(startDate).toDate() : undefined}
          endDate={endDate ? moment.utc(endDate).toDate() : undefined}
          minDate={minDate}
          maxDate={maxDate}
          filterDate={filterDate}
          selected={value ? moment.utc(value, moment.defaultFormatUtc).toDate() : undefined}
          onChange={(date: Date) =>  onChange(date)}
          onChangeRaw={(e: any) => e.preventDefault()}
          dateFormat={dateTimeFormat}
          popperPlacement="bottom-start"
          popperModifiers={
            {
              modifiers: [
                {
                  name: "flip",
                  options: {
                    enabled: false,
                    fallbackPlacements: ["bottom"]
                  },
                },
              ]
            }
          }
      />
    </div>
  );
});

export default DateTimePicker;
