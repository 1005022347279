import React, { useEffect } from "react";
import Button from "../../../../ui/button/Button";
import history from "../../../../../utils/history";
import Panel from "../../../../ui/panel/Panel";
import Text from "../../../../ui/text/Text";
import { AppState } from "../../../../..";
import { useDispatch, useSelector } from "react-redux";
import { IAuthReducerState } from "../../../../../reducers/authReducer";
import { ICompanyBrandingReducerState } from "../../../../../reducers/companyBrandingReducer";
import { Row, Col } from "react-grid-system";
import EmailPreivewContainer from "../../brand-customization/email-preview/EmailPreivewContainer";
import BackNavigationPanel from "../../../../ui/back-navigation-panel/BackNavigationPanel";
import { IEmailCustomizationReducerState } from "../../../../../reducers/emailCustomizationReducer";
import { resetEmailTemplateValues, getEmailTemplateValues } from "../../../../../actions/emailCustomizationActions";
import { fetchCompanyBranding } from "../../../../../actions/companyBrandingActions";
import EmailFooter from "../email-footer/EmailFooter";
import EmailEditableArea from "../email-editable-area/EmailEditableArea";
import { EmailTypes } from "../../../../../utils/grpc/generated/Billsby.Protos/Messenger/private/emailconfig/emailconfig_pb";

const PositiveOneTimeChargePaymentFailedEmailTemplate: React.FC = () => {
  const dispatch = useDispatch();
  const potcp_companyBrandingReducer = useSelector<AppState, ICompanyBrandingReducerState>(state => state.companyBrandingReducer);
  const potcp_emailCustomizationReducer = useSelector<AppState, IEmailCustomizationReducerState>(state => state.emailCustomizationReducer);
  const authReducer = useSelector<AppState, IAuthReducerState>(state => state.auth);
  const defaultHighLightColor = "#F87847";

  const { customArea1, customArea2, customArea3 } = potcp_emailCustomizationReducer;
  const { currentCompanyId, companyName, currentCompanyDomain } = authReducer;
  const { highlightColor } = potcp_companyBrandingReducer;

  useEffect(() => {
    dispatch(fetchCompanyBranding(currentCompanyDomain));
    dispatch(getEmailTemplateValues(currentCompanyId as number, companyName, EmailTypes.ONE_TIME_CHARGE_PAYMENT_FAILURE));
    return () => { dispatch(resetEmailTemplateValues()) };
  }, []);


  return (
    <div id="PositiveOneTimeChargePaymentFailedEmailTemplate-wrapper">
      <BackNavigationPanel
        title={"EMAIL_TEMPLATE_POSITIVE_ONE_TIME_CHARGE_PAYMENT_FAILED_TITLE"} shouldTranslateTitle={true}
        onClick={() => history.push("/configuration/email-notifications/emails/payment-credits-refund")} />

      <Row>
        <Col sm={8}>
          <Panel className="refund-issued-email-template__panel" noMargin>
            <EmailPreivewContainer>

              <Text
                content="EMAIL_TEMPLATE_POSITIVE_ONE_TIME_CHARGE_PAYMENT_FAILED_GREETINGS"
                className="email-preview__details__text"
                component="span"
                noMargin
              />


              <Text
                content={<span dangerouslySetInnerHTML={{__html: customArea1}}></span>}
                shouldTranslate={false}
                className="email-preview__details__text bar bar-orange"
                component="span"
                noMargin
              />

              <div className="email-preview__details__button align-left">
                <Button
                  id="select-plan-continue"
                  type="button"
                  className="email-preview__details__button-orange payment-details-request-email-template__button arrow-icon"
                  title="EMAIL_TEMPLATE_POSITIVE_ONE_TIME_CHARGE_PAYMENT_FAILED_BODY1"
                  style={{ backgroundColor: !!highlightColor ? highlightColor : defaultHighLightColor }}
                />
              </div>


              <Text
                content="EMAIL_TEMPLATE_POSITIVE_ONE_TIME_CHARGE_PAYMENT_FAILED_BODY2"
                className="email-preview__details__text email-preview__details__text-bold"
                component="span"
                noMargin
              />

              <Row>
                <Col md={4}>
                  <Text
                    content="EMAIL_TEMPLATE_POSITIVE_ONE_TIME_CHARGE_PAYMENT_FAILED_BODY3"
                    className="email-preview__details__text email-preview__details__text-no-margin"
                    component="span"
                    noMargin
                  />
                </Col>
                <Col md={8}>
                  <Text
                    content="EMAIL_TEMPLATE_POSITIVE_ONE_TIME_CHARGE_PAYMENT_FAILED_BODY4"
                    className="email-preview__details__text email-preview__details__text-no-margin"
                    component="span"
                    noMargin
                  />
                </Col>
              </Row>

              <Row>
                <Col md={4}>
                  <Text
                    content="EMAIL_TEMPLATE_POSITIVE_ONE_TIME_CHARGE_PAYMENT_FAILED_BODY5"
                    className="email-preview__details__text email-preview__details__text-no-margin"
                    component="span"
                    noMargin
                  />
                </Col>
                <Col md={8}>
                  <Text
                    content="EMAIL_TEMPLATE_POSITIVE_ONE_TIME_CHARGE_PAYMENT_FAILED_BODY6"
                    className="email-preview__details__text email-preview__details__text-no-margin"
                    component="span"
                    noMargin
                  />
                </Col>
              </Row>

              <Row>
                <Col md={4}>
                  <Text
                    content="EMAIL_TEMPLATE_POSITIVE_ONE_TIME_CHARGE_PAYMENT_FAILED_BODY7"
                    className="email-preview__details__text"
                    component="span"
                    noMargin
                  />
                </Col>
                <Col md={8}>
                  <Text
                    content="EMAIL_TEMPLATE_POSITIVE_ONE_TIME_CHARGE_PAYMENT_FAILED_BODY8"
                    className="email-preview__details__text"
                    component="span"
                    noMargin
                  />
                </Col>
              </Row>

              <Text
                content="EMAIL_TEMPLATE_POSITIVE_ONE_TIME_CHARGE_PAYMENT_FAILED_BODY9"
                className="email-preview__details__text"
                component="span"
                noMargin
                style={{ textDecoration: "underline", cursor: "pointer", color: !!highlightColor ? highlightColor : defaultHighLightColor }}
              />

              <Text
                content={<span dangerouslySetInnerHTML={{__html: customArea2}}></span>}
                shouldTranslate={false}
                className="email-preview__details__text bar bar-orange"
                component="span"
                noMargin
              />

              <Text
                content="EMAIL_TEMPLATE_POSITIVE_ONE_TIME_CHARGE_PAYMENT_FAILED_BODY10"
                className="email-preview__details__text"
                component="span"
                noMargin
              />

              <Text
                content={companyName}
                className="email-preview__details__text"
                component="span"
                shouldTranslate={false}
                noMargin
              />

              <EmailFooter />

            </EmailPreivewContainer>
          </Panel>
        </Col>
        <Col sm={4}>
          <EmailEditableArea
            id="PositiveOneTimeChargePaymentFailedEmailTemplate"
            emailCustomCopyOneValue={customArea1}
            emailCustomCopyTwoValue={customArea2}
            emailCustomCopyThreeValue={customArea3}
            emailCustomCopyThreeIsVisible={false}
            emailAdsIsVisible={false}
            showCompanyAlertToggle={true}
            showProductAlertToggle={false}
            showPlanAlertToggle={false}
          />
        </Col>
      </Row>
    </div>
  )
}

export default PositiveOneTimeChargePaymentFailedEmailTemplate;