/**
 * @fileoverview gRPC-Web generated client stub for billing.public.company.pricingdisclosure
 * @enhanceable
 * @public
 */

// GENERATED CODE -- DO NOT EDIT!


import * as grpcWeb from "grpc-web";


import {
  GetMockedPricingDisclosureRequest,
  GetMockedPricingDisclosureResponse} from "./pricing_disclosure_pb";

export class PricingDisclosureServiceClient {
  client_: grpcWeb.AbstractClientBase;
  hostname_: string;
  credentials_: null | { [index: string]: string; };
  options_: null | { [index: string]: string; };

  constructor (hostname: string,
               credentials?: null | { [index: string]: string; },
               options?: null | { [index: string]: string; }) {
    if (!options) options = {};
    if (!credentials) credentials = {};
    options["format"] = "text";

    this.client_ = new grpcWeb.GrpcWebClientBase(options);
    this.hostname_ = hostname;
    this.credentials_ = credentials;
    this.options_ = options;
  }

  methodInfoGetMockedPricingDisclosure = new grpcWeb.AbstractClientBase.MethodInfo(
    GetMockedPricingDisclosureResponse,
    (request: GetMockedPricingDisclosureRequest) => {
      return request.serializeBinary();
    },
    GetMockedPricingDisclosureResponse.deserializeBinary
  );

  getMockedPricingDisclosure(
    request: GetMockedPricingDisclosureRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: GetMockedPricingDisclosureResponse) => void) {
    return this.client_.rpcCall(
      this.hostname_ +
        "/billing.public.company.pricingdisclosure.PricingDisclosureService/GetMockedPricingDisclosure",
      request,
      metadata || {},
      this.methodInfoGetMockedPricingDisclosure,
      callback);
  }

}

