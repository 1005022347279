import API from "../utils/API";
import { AppState } from "..";
import { makeActionCreator, makeGenericActionCreator } from "../utils/reduxUtils";
import counterpart from "counterpart";
import { ILiveCompany  } from "../models/GoLive";
import { fetchGettingStartedSettings } from "./authActions";
import { validatePaymentDetailsForm } from "../components/payment-details-form/PaymentDetailsForm";
import { ISpreedlyCardData } from "../models/Spreedly";
import { ICustomerPaymentDetailsError } from "../models/Customer";
import { IGoLiveError, IGoLiveReducer } from "../reducers/goLiveReducer";
import { getBrowserInfo, setup3dSecure } from "../components/sca-setup/ScaSetup";
import { ConfigConstants } from "../utils/config";
import { Address, CardDetails, GoLiveRequest, GoLiveResponse, PaymentStatus, Transaction, GetCompanyFeaturesStateRequest } from "../utils/grpc/generated/Billsby.Protos/core/private/companies/company_pb";
import { CompanyServiceClient } from "../utils/grpc/generated/Billsby.Protos/core/private/companies/CompanyServiceClientPb";
import { grpcUnaryCall } from "../utils/grpc/grpcUtils";
import { refreshToken } from "../utils/authUtils";

export const FETCH_COUNTRIES_GO_LIVE_REQUEST = "FETCH_COUNTRIES_GO_LIVE_REQUEST";
export const FETCH_COUNTRIES_GO_LIVE_SUCCESS = "FETCH_COUNTRIES_GO_LIVE_SUCCESS";
export const FETCH_COUNTRIES_GO_LIVE_FAILURE = "FETCH_COUNTRIES_GO_LIVE_FAILURE";

export const FETCH_GOLIVE_CHECKLIST_REQUEST = "FETCH_GOLIVE_CHECKLIST_REQUEST";
export const FETCH_GOLIVE_CHECKLIST_SUCCESS = "FETCH_GOLIVE_CHECKLIST_SUCCESS";
export const FETCH_GOLIVE_CHECKLIST_FAILURE = "FETCH_GOLIVE_CHECKLIST_FAILURE";

export const MOVE_COMPANY_TO_LIVE_REQUEST = "MOVE_COMPANY_TO_LIVE_REQUEST";
export const MOVE_COMPANY_TO_LIVE_SUCCESS = "MOVE_COMPANY_TO_LIVE_SUCCESS";
export const MOVE_COMPANY_TO_LIVE_FAILURE = "MOVE_COMPANY_TO_LIVE_FAILURE";

export const MOVE_COMPANY_TO_LIVE_SCA_REQUEST = "MOVE_COMPANY_TO_LIVE_SCA_REQUEST";
export const MOVE_COMPANY_TO_LIVE_SCA_SUCCESS = "MOVE_COMPANY_TO_LIVE_SCA_SUCCESS";
export const MOVE_COMPANY_TO_LIVE_SCA_FAILURE = "MOVE_COMPANY_TO_LIVE_SCA_FAILURE";


export const FETCH_COMPANY_FEATURES_STATE_REQUEST = "FETCH_COMPANY_FEATURES_STATE_REQUEST";
export const FETCH_COMPANY_FEATURES_STATE_SUCCESS = "FETCH_COMPANY_FEATURES_STATE_SUCCESS";
export const FETCH_COMPANY_FEATURES_STATE_FAILURE = "FETCH_COMPANY_FEATURES_STATE_FAILURE";

export const SET_GOLIVE_COUNTRY = "SET_GOLIVE_COUNTRY";
export const setGoLiveCountry = makeActionCreator(SET_GOLIVE_COUNTRY, "payload");

export const SET_GOLIVE_STATE = "SET_GOLIVE_STATE";
export const setGoLiveState = makeActionCreator(SET_GOLIVE_STATE, "payload");

export const SET_GOLIVE_FIELD = "SET_GOLIVE_FIELD";
export const setGoLiveField = makeGenericActionCreator<keyof IGoLiveReducer>(SET_GOLIVE_FIELD);

export const VALIDATE_GOLIVE_FORM = "VALIDATE_GOLIVE_FORM";

export const RESET_GOLIVE = "RESET_GOLIVE";
export const resetGoLive = makeActionCreator(RESET_GOLIVE);

export const getCountries = function () {
  return {
    types: [FETCH_COUNTRIES_GO_LIVE_REQUEST, FETCH_COUNTRIES_GO_LIVE_SUCCESS, FETCH_COUNTRIES_GO_LIVE_FAILURE],
    callAPI: () => API.getCountries()
  }
}

export const fetchGoLiveChecklist = function (companyDomain: string) {
  return {
    types: [FETCH_GOLIVE_CHECKLIST_REQUEST, FETCH_GOLIVE_CHECKLIST_SUCCESS, FETCH_GOLIVE_CHECKLIST_FAILURE],
    callAPI: () => API.getGoLiveChecklist(companyDomain)
  }
}

export const setCompanyToLive = async (companyDomain: string, companyId: number, dispatch: Function, data: ILiveCompany) => {
  dispatch(setGoLiveField("isGoingLive", true));
  const url = `https://${companyDomain}${ConfigConstants.billsbyDomain}/launch-checklist/3ds1-status`;

  try {
    const response = await moveCompanyToLiveRequest(companyId, { ...data, redirectUrl: url, callbackUrl: url});

    if ((response.getPaymentStatus() === PaymentStatus.SUCCESS && response.getProcessCompletedSuccessfully()) || !response.getPaymentStatus()) {
      dispatch({ type: MOVE_COMPANY_TO_LIVE_SUCCESS });
      dispatch(fetchGettingStartedSettings(companyDomain));
      //refresh permissions in the token
      await refreshToken(companyId)
      return;
    }

    if (response.getPaymentStatus() === PaymentStatus.PENDING) {
      dispatch({ type: MOVE_COMPANY_TO_LIVE_SCA_REQUEST });

      sessionStorage.setItem("goLiveSCA", JSON.stringify({
        companyLiveData: { ...data, transactionToken: response.getTransactionToken(), redirectUrl: url, callbackUrl: url },
        companyDomain, 
        companyId
      }));
      
      const statusUpdates = async (event: { action: string }) => {
        if (event.action === "succeeded" || event.action === "trigger-completion") {
          dispatch({ type: MOVE_COMPANY_TO_LIVE_SUCCESS });
          dispatch(fetchGettingStartedSettings(companyDomain));
        } else {
          dispatch({ type: MOVE_COMPANY_TO_LIVE_FAILURE });
        }
      }

      return setup3dSecure(response.getTransactionToken(), statusUpdates);
    }

    if (response.getPaymentStatus() === PaymentStatus.DECLINED || response.getPaymentStatus() === PaymentStatus.FAILED) {
      dispatch({ type: MOVE_COMPANY_TO_LIVE_FAILURE });
      return;
    }

  } catch (error) {
    dispatch({ type: MOVE_COMPANY_TO_LIVE_FAILURE, error });
  }
}

export const validateGoLiveFormAndSubmit = (companyDomain: string, companyId: number, ipAddress?: string) => {
  return (dispatch: Function, getState: () => AppState) => {
    dispatch({ type: VALIDATE_GOLIVE_FORM });
    const { cardHolderName, cardExpiryMonth, cardExpiryYear, errors, addressLine1, addressLine2, city, state, selectedState, postCode, country,
      isEnabledBillsbyBrand } = getState().goLiveReducer;

    dispatch(setGoLiveField("apiError", ""));

    if (!Object.keys(errors).length) {
      validatePaymentDetailsForm({ cardholderName: cardHolderName, expiryMonth: Number(cardExpiryMonth), expiryYear: Number(cardExpiryYear) },
        {
          addressLine1: addressLine1,
          addressLine2: addressLine2,
          state: selectedState?.value?.StateIso2 || state,
          city: city,
          country: country ? country.value.iso3Code : "",
          postCode: postCode
        },
        async (cardData: ISpreedlyCardData, spreedlyErrors: ICustomerPaymentDetailsError | null) => {
          if (spreedlyErrors || !cardData) {
            const errors: IGoLiveError = {
              creditCardNumber: spreedlyErrors?.cardNumber ? counterpart("GO_LIVE_INVALID_CREDIT_CARDS") : undefined,
              cardExpiryMonth: spreedlyErrors?.expiryMonth,
              cardExpiryYear: spreedlyErrors?.expiryYear
            }
            dispatch(setGoLiveField("errors", errors));
            return;
          }
          const { isGoingLiveSuccess, isGoingLive, selectedBillsbyPlan } = getState().goLiveReducer;
          if (!selectedBillsbyPlan || isGoingLiveSuccess || isGoingLive) {
            return;
          }
          const companyLiveData: ILiveCompany = {
            planId: selectedBillsbyPlan,
            cardHolderName,
            billingAddressLine1: addressLine1,
            billingAddressLine2: addressLine2,
            billingAddressPostCode: postCode,
            billingAddressState: selectedState?.value?.StateIso2 || state,
            billingAddressCity: city,
            billingAddressCountryISO3: country ? country.value.iso3Code : "",
            creditCardToken: cardData.token,
            whiteLabelChecked: isEnabledBillsbyBrand,
            expiryMonth: Number(cardExpiryMonth),
            expiryYear: Number(cardExpiryYear),
            cardType: cardData.card_type,
            last4Digits: cardData.last_four_digits,
            ipAddress
          };

          dispatch(setGoLiveField("paymentToken", cardData.token));
          await setCompanyToLive(companyDomain, companyId, dispatch, companyLiveData);
        });

    }
  }
}

export const fetchCompanyFeaturesState = (companyId: number) => {
  const request = new GetCompanyFeaturesStateRequest();
  const serviceClient = new CompanyServiceClient(ConfigConstants.grpcBaseUrl);
  
  request.setCompanyId(companyId);

  return {
    types: [FETCH_COMPANY_FEATURES_STATE_REQUEST, FETCH_COMPANY_FEATURES_STATE_SUCCESS, FETCH_COMPANY_FEATURES_STATE_FAILURE],
    callAPI: () => grpcUnaryCall(request, serviceClient, serviceClient.getCompanyFeaturesState)
  }
}

export const moveCompanyToLiveRequest = async (companyId: number, data: ILiveCompany) => {
  const request = new GoLiveRequest();
  const cardDetails = new CardDetails();
  const transaction = new Transaction();
  const address = new Address();
  const serviceClient = new CompanyServiceClient(ConfigConstants.grpcBaseUrl);

  let response = undefined;

  cardDetails.setCardholderName(data.cardHolderName);
  cardDetails.setCardType(data.cardType);
  cardDetails.setPaymentMethodToken(data.creditCardToken);
  cardDetails.setExpiryMonth(data.expiryMonth);
  cardDetails.setExpiryYear(data.expiryYear);
  cardDetails.setLast4digits(data.last4Digits);

  data.transactionToken && transaction.setTransactionToken(data.transactionToken);
  data.ipAddress && transaction.setIpAddress(data.ipAddress);
  data.redirectUrl && transaction.setRedirectUrl(data.redirectUrl);
  data.callbackUrl && transaction.setCallbackUrl(data.callbackUrl);
  transaction.setBrowserInfo(getBrowserInfo());

  address.setCity(data.billingAddressCity);
  address.setState(data.billingAddressState);
  address.setLineOne(data.billingAddressLine1);
  address.setLineTwo(data.billingAddressLine2);
  address.setPostCode(data.billingAddressPostCode);
  address.setCountryIso3(data.billingAddressCountryISO3);

  request.setCompanyId(companyId);
  request.setBillingAddress(address);
  request.setCardDetails(cardDetails);
  request.setTransaction(transaction);
  request.setWhiteLabelChecked(data.whiteLabelChecked);
  request.setBillsbyPlanId(data.planId);

  try {
    response = await grpcUnaryCall(request, serviceClient, serviceClient.goLive) as GoLiveResponse;
  } catch (err) {
    throw new Error((err as Error).message)
  }
  return response;
}

