import { BillsbyAction } from "../models/BillsbyAction";
import { FrequencyType, IBaseCycle, BillingDateType, ProRateType, VisibilityType, ICreatedTier } from "../models/Product";
import { SET_IS_ADDING_NEW_CYCLE, ADD_CYCLE, REMOVE_CYCLE, SET_EDITED_CYCLE, CREATE_CYCLE_REQUEST, CREATE_CYCLE_SUCCESS, CREATE_CYCLE_FAILURE, SET_PRODUCT_CYCLES_UNMOUNT, SET_CREATE_CYCLE_FIELD, RESET_PLAN_FOR_EDITING, SET_IS_REORDERING_CYCLES, SET_TEMP_CYCLES, SET_CYCLES, ADD_TIER, DELETE_TIER, SET_TIER_UNIT, SET_CURRENT_TIER_UNIT, SET_PRICE_PER_UNIT } from "../actions/productPlanCycleActions";
import { AppState } from "..";
import counterpart from "counterpart";
import { getFrequencyText, getBillingDayLabelFormatted, getActionErrorMessage } from "../utils/commonUtils";

export interface ICycleReducer {
  price: number | undefined,
  frequency: number | undefined,
  cycles: Array<IBaseCycle>,
  helpMenuTitle: string,
  helpMenuContent: Array<string>,
  frequencyType: { label: string, value: FrequencyType },
  frequencyTypes: Array<{ label: string, value: FrequencyType }>,
  frequencyTypesPlural: Array<{ label: string, value: FrequencyType }>,
  isFreeQuantity: boolean,
  freeQuantity: number | undefined,
  isFreeTrial: boolean,
  freeTrial: number | undefined,
  freeTrialFrequencyType: { label: string, value: FrequencyType },
  isContractTerm: boolean,
  contractTerm: number | undefined,
  contractTermFrequencyType: { label: string, value: FrequencyType },
  isSetupFee: boolean,
  setupFeePrice: number | undefined,
  billingDates: Array<{ label: string, value: BillingDateType }>,
  billingDate: { label: string, value: BillingDateType },
  billingDaysMonth: Array<{ label: string, value: number }>,
  billingDaysWeek: Array<{ label: string, value: number }>,
  billingDay: { label: string, value: number },
  billingMonth: { label: string, value: number },
  proRateType: ProRateType,
  visibility: { label: string, value: VisibilityType },
  visibilityTypes: Array<{ label: String, value: VisibilityType }>,
  alertEmail: string,
  editedCycle: IBaseCycle | null,
  isCreatingCyclesRequest: boolean,
  isCreatingCyclesSuccess: boolean,
  isCreatingCyclesFailure: boolean,
  errorMessage: string,
  isAddingNewCycle: boolean,
  hasAddedFirstCycleEver: boolean
  isReorderingCycles: boolean,
  tempCycles: Array<IBaseCycle>,
  createdTiers: Array<ICreatedTier>,
  pricePerUnit: number | undefined,
  tierUnit: number,
  currentTierUnit: number,
}

export const initState = () => {
  const billingDates = [
    { label: counterpart("CREATE_CYCLE_BILLINGDATE_ANNIVERSARY_SIGNUP"), value: BillingDateType.ANNINVERSARY_SIGNUP },
    { label: counterpart("CREATE_CYCLE_BILLINGDATE_FIXED_DAY_MONTH"), value: BillingDateType.FIXED_DAY_MONTH },
    { label: counterpart("CREATE_CYCLE_BILLINGDATE_FIXED_DAY_WEEK"), value: BillingDateType.FIXED_DAY_WEEK },
    { label: counterpart("CREATE_CYCLE_BILLINGDATE_FIXED_DAY_YEAR"), value: BillingDateType.FIXED_DAY_EACH_YEAR }
  ];
  const billingDaysMonth = [...Array(28)].map((el, idx) =>
    ({ label: `${getBillingDayLabelFormatted(idx + 1)}`, value: idx + 1 })
  );
  /*const billingDaysWeek = [...Array(5)].map((el, idx) =>
    ({ label: `${getBillingDayLabelFormatted(idx + 1)}`, value: idx + 1 })
  );*/
  const billingDaysWeek = [
    { label: counterpart("CREATE_CYCLE_PRO_RATE_MONDAY"), value: 1 },
    { label: counterpart("CREATE_CYCLE_PRO_RATE_TUESDAY"), value: 2 },
    { label: counterpart("CREATE_CYCLE_PRO_RATE_WEDNESDAY"), value: 3 },
    { label: counterpart("CREATE_CYCLE_PRO_RATE_THURSDAY"), value: 4 },
    { label: counterpart("CREATE_CYCLE_PRO_RATE_FRIDAY"), value: 5 },
    { label: counterpart("CREATE_CYCLE_PRO_RATE_SATURDAY"), value: 6 },
    { label: counterpart("CREATE_CYCLE_PRO_RATE_SUNDAY"), value: 7 }
  ]
  const frequencyTypes = [
    { label: counterpart("CREATE_PLAN_PERIOD_DAILY"), value: FrequencyType.Daily },
    { label: counterpart("CREATE_PLAN_PERIOD_WEEKLY"), value: FrequencyType.Weekly },
    { label: counterpart("CREATE_PLAN_PERIOD_MONTHLY"), value: FrequencyType.Monthly },
    { label: counterpart("CREATE_PLAN_PERIOD_YEARLY"), value: FrequencyType.Yearly }
  ];
  const frequencyTypesPlural = [
    { label: counterpart("CREATE_PLAN_PERIOD_DAILY_PLURAL"), value: FrequencyType.Daily },
    { label: counterpart("CREATE_PLAN_PERIOD_WEEKLY_PLURAL"), value: FrequencyType.Weekly },
    { label: counterpart("CREATE_PLAN_PERIOD_MONTHLY_PLURAL"), value: FrequencyType.Monthly },
    { label: counterpart("CREATE_PLAN_PERIOD_YEARLY_PLURAL"), value: FrequencyType.Yearly }
  ];
  const visibilityTypes = [
    { label: counterpart("CREATE_CYCLE_VISIBILITY_PUBLIC"), value: VisibilityType.Public },
    { label: counterpart("CREATE_CYCLE_VISIBILITY_HIDDEN"), value: VisibilityType.Hidden },
    // { label: counterpart("CREATE_CYCLE_VISIBILITY_INTERNAL"), value: VisibilityType.Internal },
    // { label: counterpart("CREATE_CYCLE_VISIBILITY_OFFSALE"), value: VisibilityType.OffSale }
  ]
  return {
    price: undefined,
    frequency: 1,
    cycles: [],
    helpMenuTitle: "HELP_EMPTY_TITLE",
    helpMenuContent: ["HELP_EMPTY_1", "HELP_EMPTY_2"],
    frequencyType: frequencyTypes[2],
    frequencyTypes: frequencyTypes,
    frequencyTypesPlural: frequencyTypesPlural,
    isFreeQuantity: false,
    freeQuantity: undefined,
    isFreeTrial: false,
    freeTrial: undefined,
    freeTrialFrequencyType: frequencyTypes[2],
    isContractTerm: false,
    contractTerm: undefined,
    contractTermFrequencyType: frequencyTypes[2],
    isSetupFee: false,
    setupFeePrice: undefined,
    billingDates: billingDates,
    billingDate: billingDates[0],
    billingMonth: { label: "", value: 0 },
    billingDaysMonth,
    billingDaysWeek,
    billingDay: billingDaysMonth[0],
    proRateType: ProRateType.BASED_ON_DAYS_REMAINING,
    visibility: visibilityTypes[0],
    visibilityTypes: visibilityTypes,
    alertEmail: "",
    editedCycle: null,
    isCreatingCyclesRequest: false,
    isCreatingCyclesSuccess: false,
    isCreatingCyclesFailure: false,
    isAddingNewCycle: false,
    hasAddedFirstCycleEver: false,
    isReorderingCycles: false,
    tempCycles: [],
    createdTiers: [],
    pricePerUnit: undefined,
    tierUnit: 0,
    currentTierUnit: 1,
    errorMessage: ""
  }
}

export default function cyclesReducer(state: ICycleReducer = initState(), action: BillsbyAction, store: AppState) {
  switch (action.type) {

    case SET_CREATE_CYCLE_FIELD: {
      // if (action.fieldName === "price") {
      //   return { ...state, price: isPriceWith2Decimals(action.fieldValue) ? action.fieldValue : state.price }
      // }

      return { ...state, [action.fieldName]: action.fieldValue }
    }

    case ADD_CYCLE: {
      if (state.editedCycle) {
        const editedCycle = action.payload as IBaseCycle;
        state.editedCycle.pricingModel = { ...editedCycle.pricingModel, };
        

        return {
          ...initState(), cycles: [...state.cycles], editedCycle: null, hasAddedFirstCycleEver: true
        }
      }

      return {
        ...initState(), cycles: [...state.cycles, action.payload], hasAddedFirstCycleEver: true
      }
    }
    case REMOVE_CYCLE:
      return { ...state, cycles: state.cycles.filter(cycle => cycle !== action.payload) }
    case SET_EDITED_CYCLE: {
      const editedCycle: IBaseCycle = action.payload;
      const { billingDates, billingDaysMonth, billingDaysWeek, visibilityTypes } = state;
      let { freeTrial, freeTrialFrequencyType, contractTerm, contractTermFrequencyType, visibility, frequency, frequencyType, price, setupFeePrice, billingDateType, fixedBillingDateDay,
        proRateOption, freeQuantity, fixedBillingDateMonth, } = editedCycle.pricingModel;
      freeTrialFrequencyType = freeTrialFrequencyType ?? FrequencyType.Monthly;
      return {
        ...state,
        editedCycle: action.payload,
        isFreeTrial: !!freeTrial,
        freeTrial,
        freeTrialFrequencyType: !!freeTrialFrequencyType ? { label: getFrequencyText(Number(freeTrial), freeTrialFrequencyType), value: freeTrialFrequencyType } : undefined,
        isContractTerm: !!contractTerm,
        contractTerm,
        contractTermFrequencyType: contractTermFrequencyType ? { label: getFrequencyText(contractTerm || 1, contractTermFrequencyType), value: contractTermFrequencyType } : { label: getFrequencyText(1, FrequencyType.Monthly), value: FrequencyType.Monthly },
        frequency,
        frequencyType: { label: getFrequencyText(frequency, frequencyType), value: frequencyType },
        price,
        isFreeQuantity: Boolean(freeQuantity),
        freeQuantity: freeQuantity ? freeQuantity : undefined,
        isSetupFee: !!setupFeePrice,
        setupFeePrice,
        billingDate: billingDates.find(b => b.value === billingDateType),
        billingDay: billingDateType === BillingDateType.FIXED_DAY_EACH_YEAR ? { label: "", value: fixedBillingDateDay } : billingDaysMonth.find(m => m.value === fixedBillingDateDay) || billingDaysWeek.find(d => d.value === fixedBillingDateDay),
        billingMonth: billingDateType === BillingDateType.FIXED_DAY_EACH_YEAR ? { label: "", value: fixedBillingDateMonth ? fixedBillingDateMonth : "" } : undefined,
        proRateType: proRateOption,
        visibility: visibilityTypes.find(v => v.value === visibility),
        createdTiers: editedCycle.pricingModel.tiers
      }
    }
    case CREATE_CYCLE_REQUEST:
      return { ...state, isCreatingCyclesRequest: true }
    case CREATE_CYCLE_SUCCESS:
      return { ...initState(), isCreatingCyclesSuccess: true }
    case CREATE_CYCLE_FAILURE:
      return { ...state, isCreatingCyclesRequest: false, isCreatingCyclesFailure: true, errorMessage: getActionErrorMessage(action.error) }
    case SET_IS_ADDING_NEW_CYCLE:
      return {
        ...initState(),
        cycles: [...state.cycles],
        hasAddedFirstCycleEver: state.hasAddedFirstCycleEver,
        isAddingNewCycle: action.payload
      }
    case SET_PRODUCT_CYCLES_UNMOUNT:
      return initState();
    case RESET_PLAN_FOR_EDITING:
      return initState();
    case SET_IS_REORDERING_CYCLES:
      return { ...state, isReorderingCycles: action.payload }
    case SET_TEMP_CYCLES:
      return { ...state, tempCycles: action.payload }
    case SET_CYCLES:
      return { ...state, cycles: action.payload }
           
    case ADD_TIER:
      return { ...state, createdTiers: [...state.createdTiers, action.payload] }  

    case DELETE_TIER:
      return { ...state, createdTiers: state.createdTiers.filter((tier, idx) => idx !== action.payload) }  

    case SET_TIER_UNIT:
      return { ...state, tierUnit: action.payload }
    
    case SET_CURRENT_TIER_UNIT:
      return { ...state, currentTierUnit: action.payload }

    case SET_PRICE_PER_UNIT:
      return { ...state, pricePerUnit: action.payload }

    default:
      return state;
  }
}