import API from "../utils/API";
import { makeActionCreator } from "../utils/reduxUtils";

export const FETCH_COMPANY_API_KEY_REQUEST = "FETCH_COMPANY_API_KEY_REQUEST";
export const FETCH_COMPANY_API_KEY_SUCCESS = "FETCH_COMPANY_API_KEY_SUCCESS";
export const FETCH_COMPANY_API_KEY_FAILURE = "FETCH_COMPANY_API_KEY_FAILURE";

export const SET_IS_COLLAPSED_KEEPING_SECURE = "SET_IS_COLLAPSED_KEEPING_SECURE";

export const setProductAddressCollapsed = makeActionCreator(SET_IS_COLLAPSED_KEEPING_SECURE, "payload");

export const fetchCompanyApiKey = function (companyDomain: string) {
  return {
    types: [FETCH_COMPANY_API_KEY_REQUEST, FETCH_COMPANY_API_KEY_SUCCESS, FETCH_COMPANY_API_KEY_FAILURE],
    callAPI: () => API.getCompanyApiKey(companyDomain)
  }
}