import { makeActionCreator, makeGenericActionCreatorTypeSafe } from "../../utils/reduxUtils";
import { grpcUnaryCall } from "../../utils/grpc/grpcUtils";
import {CreateDiscountRequest, DiscountType, MonetaryCurrencyPrices, GetDiscountRequest, UpdateDiscountRequest, GetPlanCurrenciesRequest} from "../../utils/grpc/generated/Billsby.Protos/core/private/discounts/discounts_pb";
import {DiscountsServiceClient} from "../../utils/grpc/generated/Billsby.Protos/core/private/discounts/DiscountsServiceClientPb";
import { ConfigConstants } from "../../utils/config";
import { ICreateDiscountState } from "../../reducers/discount-coupons/createDiscountReducer";
import { multiplyNumberBy100 } from "../../utils/commonUtils";

export const SET_CREATE_DISCOUNT_BASIC_INFO = "SET_CREATE_DISCOUNT_BASIC_INFO";

export const setCreateDiscountReducerFields = makeGenericActionCreatorTypeSafe<ICreateDiscountState>(SET_CREATE_DISCOUNT_BASIC_INFO);

export const CREATE_DISCOUNT_REQUEST = "CREATE_DISCOUNT_REQUEST";
export const CREATE_DISCOUNT_SUCCESS = "CREATE_DISCOUNT_SUCCESS";
export const CREATE_DISCOUNT_FAILURE = "CREATE_DISCOUNT_FAILURE";

export const EDIT_DISCOUNT_REQUEST = "EDIT_DISCOUNT_REQUEST";
export const EDIT_DISCOUNT_SUCCESS = "EDIT_DISCOUNT_SUCCESS";
export const EDIT_DISCOUNT_FAILURE = "EDIT_DISCOUNT_FAILURE";

export const FETCH_DISCOUNT_DETAILS_REQUEST = "FETCH_DISCOUNT_DETAILS_REQUEST";
export const FETCH_DISCOUNT_DETAILS_SUCCESS = "FETCH_DISCOUNT_DETAILS_SUCCESS";
export const FETCH_DISCOUNT_DETAILS_FAILURE = "FETCH_DISCOUNT_DETAILS_FAILURE";

export const FETCH_CURRENCIES_OF_PLANS_DISCOUNTS_REQUEST = "FETCH_CURRENCIES_OF_PLANS_DISCOUNTS_REQUEST";
export const FETCH_CURRENCIES_OF_PLANS_DISCOUNTS_SUCCESS = "FETCH_CURRENCIES_OF_PLANS_DISCOUNTS_SUCCESS";
export const FETCH_CURRENCIES_OF_PLANS_DISCOUNTS_FAILURE = "FETCH_CURRENCIES_OF_PLANS_DISCOUNTS_FAILURE";

export const SET_DISCOUNT_FORM_COMPLETED = "SET_DISCOUNT_FORM_COMPLETED";
export const setDiscountFormCompleted = makeActionCreator(SET_DISCOUNT_FORM_COMPLETED, "stageIndex", "value");

export const SET_DISCOUNT_FORM_HAS_ERROR = "SET_DISCOUNT_FORM_HAS_ERROR";
export const setDiscountFormHasError = makeActionCreator(SET_DISCOUNT_FORM_HAS_ERROR, "stageIndex", "value");

export const SET_DISCOUNT_STEPS_COLLAPSED = "SET_DISCOUNT_STEPS_COLLAPSED";
export const setDiscountStepsCollapsed = makeActionCreator(SET_DISCOUNT_STEPS_COLLAPSED, "payload");

export const SET_MONETARY_DISCOUNT = "SET_MONETARY_DISCOUNT";
export const setMonetaryDiscount = makeActionCreator(SET_MONETARY_DISCOUNT, "currency", "value");

export const RESET_CREATE_DISCOUNT_STATE = "RESET_CREATE_DISCOUNT_STATE";
export const resetCreateDiscount = makeActionCreator(RESET_CREATE_DISCOUNT_STATE);

export const createDiscount = (companyId: number, createDiscount: ICreateDiscountState, plandIds: number[]) => {
  const createDiscountRequest = new CreateDiscountRequest();
  createDiscountRequest.setCompanyId(companyId);
  createDiscountRequest.setName(createDiscount.name);
  createDiscountRequest.setIsAllowedWithOtherDiscounts(createDiscount.canBeUseInConjunction);
  createDiscountRequest.setAllowAllPlans(createDiscount.isAllPlans);
  createDiscountRequest.setPlanIdsList(plandIds);
  createDiscountRequest.setDeductionType(createDiscount.deductionType);
  if(createDiscount.discountType === DiscountType.PERCENTAGE) {
    const percentageModel = new CreateDiscountRequest.PercentageModel();
    percentageModel.setPercentage(Number(multiplyNumberBy100(createDiscount.percentage)));
    createDiscountRequest.setPercentage(percentageModel);
  } else if(createDiscount.discountType === DiscountType.MONETARY) {
    const monetaryModel = new CreateDiscountRequest.MonetaryModel();
    monetaryModel.setPricesList(createDiscount.monetaries.map(i => {
      const monetary = new MonetaryCurrencyPrices();
      monetary.setCurrencyCode(i.currencyCode);      
      monetary.setValue(Number(multiplyNumberBy100(i.value)));
      return monetary;
    }));
    createDiscountRequest.setMonetary(monetaryModel);
  }

  const discountsServiceClient = new DiscountsServiceClient(ConfigConstants.grpcBaseUrl);
  return {
    types: [CREATE_DISCOUNT_REQUEST, CREATE_DISCOUNT_SUCCESS, CREATE_DISCOUNT_FAILURE],
    callAPI: () =>  grpcUnaryCall(createDiscountRequest, discountsServiceClient, discountsServiceClient.createDiscount)
  }
}

export const updateDiscount = (companyId: number, discountId: number, createDiscount: ICreateDiscountState, plandIds: number[]) => {
  const updateDiscountRequest = new UpdateDiscountRequest();
  updateDiscountRequest.setCompanyId(companyId);
  updateDiscountRequest.setDiscountId(discountId);
  updateDiscountRequest.setName(createDiscount.name);
  updateDiscountRequest.setIsAllowedWithOtherDiscounts(createDiscount.canBeUseInConjunction);
  updateDiscountRequest.setAllowAllPlans(createDiscount.isAllPlans);
  updateDiscountRequest.setPlanIdsList(plandIds);
  updateDiscountRequest.setDeductionType(createDiscount.deductionType);
  if(createDiscount.discountType === DiscountType.PERCENTAGE) {
    const percentageModel = new CreateDiscountRequest.PercentageModel();
    percentageModel.setPercentage(Number(multiplyNumberBy100(createDiscount.percentage)));
    updateDiscountRequest.setPercentage(percentageModel);
  } else if(createDiscount.discountType === DiscountType.MONETARY) {
    const monetaryModel = new CreateDiscountRequest.MonetaryModel();
    monetaryModel.setPricesList(createDiscount.monetaries.map(i => {
      const monetary = new MonetaryCurrencyPrices();
      monetary.setCurrencyCode(i.currencyCode);      
      monetary.setValue(Number(multiplyNumberBy100(i.value)));
      return monetary;
    }));
    updateDiscountRequest.setMonetary(monetaryModel);
  }

  const discountsServiceClient = new DiscountsServiceClient(ConfigConstants.grpcBaseUrl);
  return {
    types: [CREATE_DISCOUNT_REQUEST, CREATE_DISCOUNT_SUCCESS, CREATE_DISCOUNT_FAILURE],
    callAPI: () =>  grpcUnaryCall(updateDiscountRequest, discountsServiceClient, discountsServiceClient.updateDiscount)
  }
}

export const fetchDiscountDetails = (companyId: number, discountId: number) => {

  const getDiscountRequest = new GetDiscountRequest();
  getDiscountRequest.setCompanyId(companyId);
  getDiscountRequest.setDiscountId(discountId);
  
  const discountsServiceClient = new DiscountsServiceClient(ConfigConstants.grpcBaseUrl);

  return {
    types: [FETCH_DISCOUNT_DETAILS_REQUEST, FETCH_DISCOUNT_DETAILS_SUCCESS, FETCH_DISCOUNT_DETAILS_FAILURE],
    callAPI: () =>  grpcUnaryCall(getDiscountRequest, discountsServiceClient, discountsServiceClient.getDiscount)
  }
}

export const fetchCurrenciesOfPlansDiscounts = (companyDomain: string, planIds: Array<number>) => {
  const getPlanCurrenciesRequest = new GetPlanCurrenciesRequest();
  getPlanCurrenciesRequest.setCompanyDomain(companyDomain);
  getPlanCurrenciesRequest.setPlanIdsList(planIds);

  const discountsServiceClient = new DiscountsServiceClient(ConfigConstants.grpcBaseUrl);
  return {
    types: [FETCH_CURRENCIES_OF_PLANS_DISCOUNTS_REQUEST, FETCH_CURRENCIES_OF_PLANS_DISCOUNTS_SUCCESS, FETCH_CURRENCIES_OF_PLANS_DISCOUNTS_FAILURE],
    callAPI: () => grpcUnaryCall(getPlanCurrenciesRequest, discountsServiceClient, discountsServiceClient.getPlanCurrencies)
  }
}