import React from "react";
import "./RoundingBlock.scss";

interface IRoundingBlock {
  rounding: number,
  onClick: () => void,
  isSelected: boolean,
}

const RoundingBlock: React.FC<IRoundingBlock> = ({ rounding, onClick, isSelected }) => {
  return (
    <div onClick={onClick} className={`rounding-block${isSelected ? " rounding-block--selected" : ""}`}>
      <div style={{ borderTopLeftRadius: `${rounding}px` }}></div>
    </div>
  )
}

export default RoundingBlock;