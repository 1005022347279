import React from "react";
import "./MenuTable.scss";
import Text from "../text/Text";
import { COLOR } from "../../../utils/constants";
import Table from "../table/Table";
import Switch from "../switch/Switch";
import { INotificationListElement } from "../../../models/EmailNotifications";

interface IMenuTable<T extends INotificationListElement> {
  className?: string,
  effectOnHover?: boolean,
  data: Array<T>
}

const getListElementClass = (index: number, data: Array<any>, effectOnHover: boolean, isLocked: boolean) => {
  let className = "menu-table__element";
  if (index === 0) {
    className += " menu-table__element--first";
  }
  if (index === data.length - 1) {
    className += " menu-table__element--last";
  }
  if (effectOnHover) {
    className += " menu-table__element--hover";
  }
  if (isLocked) {
    className += " menu-table__element--locked";
  }

  return className;
}

const MenuTable = <T extends INotificationListElement>({ className = "", effectOnHover = true, data }: IMenuTable<T>) => {
  return (
    <Table className={`menu-table ${className}`}>
      <tbody>
        {data.map((listElement: T, index) => {
          const { title, subTitle, onClick, shouldTranslateTitle = true, shouldTranslateSubtitle = true, logo, isLogoFontAwesome = true,
            statusLabel, statusLabelColor = COLOR.ORANGE, icon = "fas fa-fw fa-edit", isElementClickable = true, isLocked = false,
            canConfigureNotification = false, enableNotification = false, enableNotificationChange = () => { } } = listElement;
          return (
            <tr key={index} className={getListElementClass(index, data, effectOnHover, isLocked)}>
              <td>
                {canConfigureNotification &&
                  <Switch
                    id={`switch-${index}`}
                    checked={enableNotification}
                    onChange={enableNotificationChange}
                  />
                }
                <Text content={title} shouldTranslate={shouldTranslateTitle} className={"menu-table__element__title"} noMargin></Text>
              </td>
              <td className="menu-table__actions">
                <span className="menu-table__edit" onClick={(isElementClickable && onClick) ? () => onClick(listElement) : () => { }}>
                  <i className={icon}></i>
                </span>
              </td>
            </tr>
          )
        })}
      </tbody>
    </Table>
  )
}

export default MenuTable;