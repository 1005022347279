import { ICountry } from "../models/Country";
import { ICurrency } from "../models/Currency";
import { IBaseCycle, FrequencyType, VisibilityType, ICycle, PricingModelType, IPlanWithCycles, IPlanAddOnPricingModel, IPlanAllowancePricingModel } from "../models/Product";
import counterpart from "counterpart";
import { ConfigConstants } from "./config";
import moment from "moment";
import { BILLSBY_ONE_SESSION_LOGIN, BILLSBY_AUTH_DATA_CONTROL, BILLSBY_SELECTED_COMPANY, COLOR, CustomeAnalyticsEvents } from "./constants";
import { storeGet, storeRemove } from "./storeUtils";
import { CreditNoteStatus } from "../models/CreditNotes";
import { InvoiceRefundType, InvoiceStatus } from "../models/Invoices";
import history from "./history";
import { VisibilityType as VisibilityTypeGrpc, FrequencyType as FrequencyTypeGrpc, AddonPriceModel, UnitTier as AddOnUnitTier } from "./grpc/generated/Billsby.Protos/core/private/addons/addons_pb";
import { PricingModelType as PricingModelTypeGrpc, AllowancePriceModel, UnitTier as AllowanceUnitTier } from "./grpc/generated/Billsby.Protos/core/private/allowances/allowances_pb";
import { UnitTier as SubscriptionUnitTier, AllowancePriceModel as SubscriptionAllowancePriceModel } from "./grpc/generated/Billsby.Protos/billing/private/subscription/subscription_pb";
import { Int32Value } from "google-protobuf/google/protobuf/wrappers_pb";
import queryString from "query-string";
import { fetchGet } from "./fetchUtils";
import ReactGA from "react-ga4";
const html2pdf = require("html2pdf.js");
const publicIp = require("public-ip");

const isLocalHost = ConfigConstants.reactAppEnvironment === "Localhost";

// same as the one used in variables.scss
export const MOBILE_WIDTH = 768;

export function isMobile() {
  if (window.innerWidth <= MOBILE_WIDTH) {
    return true;
  }
  return false;
}

export function debounce(this: any, fn: Function, delay: number = 500) {
  let timeout: any = -1;
  const currentScope = this;
  return function (...args: any[]) {
    clearTimeout(timeout);
    timeout = setTimeout(fn.bind(currentScope, ...args), delay);
  };
}

/**
 * reorder the countries putting on top USA, UK, AUSTRALIA, CANADA
 * @param countries
 */
export function reorderCountries(countries: Array<ICountry>): Array<ICountry> {
  const USA = countries.splice(
    countries.findIndex(country => country.iso3Code === "USA"),
    1
  );
  const UK = countries.splice(
    countries.findIndex(country => country.iso3Code === "GBR"),
    1
  );
  const AUSTRALIA = countries.splice(
    countries.findIndex(country => country.iso3Code === "AUS"),
    1
  );
  const CANADA = countries.splice(
    countries.findIndex(country => country.iso3Code === "CAN"),
    1
  );
  countries.unshift(
    ...USA.concat(UK)
      .concat(AUSTRALIA)
      .concat(CANADA)
  );
  return countries;
}
/**
 * reorder the currencies putting on top US$, CAN$, EUR€, GBP£
 * @param currencies
 */
export function reorderCurrencies(currencies: Array<ICurrency>): Array<ICurrency> {
  const USD = currencies.splice(
    currencies.findIndex(currency => currency.isoCode === "USD"),
    1
  );
  const CAN = currencies.splice(
    currencies.findIndex(currency => currency.isoCode === "CAD"),
    1
  );
  const EURO = currencies.splice(
    currencies.findIndex(currency => currency.isoCode === "EUR"),
    1
  );
  const GBP = currencies.splice(
    currencies.findIndex(currency => currency.isoCode === "GBP"),
    1
  );

  currencies.unshift(
    ...USD.concat(CAN)
      .concat(EURO)
      .concat(GBP)
  );
  return currencies;
}

export function showErrorFormInvalidEls(form: HTMLFormElement, borderColor = "#F87847") {
  const validEls: Array<HTMLElement> = Array.from(form.querySelectorAll("[required]:valid"));
  const invalidEls: Array<HTMLElement> = Array.from(form.querySelectorAll("[required]:invalid"));
  for (let item of validEls) {
    // inherit original style
    delete item.style.border;
  }
  for (let item of invalidEls) {
    item.style.border = `1px solid ${borderColor}`;
  }
}

export function openIntercomIframe() {
  /*const iframe = (window as any).document.querySelector('.intercom-launcher') 
    || (window as any).document.querySelector('.intercom-launche').contentWindow;
  if (iframe && iframe.document) {
    iframe.document.querySelector('.intercom-launcher').click();
  }*/
  (global as any).Intercom("show");
}

/**
 * copy a text to the clipboard
 */
export function copyToClipboard(text: string) {
  var id = "mycustom-clipboard-textarea-hidden-id";
  var existsTextarea = document.getElementById(id) as HTMLTextAreaElement;

  if (!existsTextarea) {
    let textarea = document.createElement("textarea") as HTMLTextAreaElement;
    textarea.id = id;
    // Place in top-left corner of screen regardless of scroll position.
    textarea.style.position = "fixed";
    textarea.style.top = "0";
    textarea.style.left = "0";

    // Ensure it has a small width and height. Setting to 1px / 1em
    // doesn't work as this gives a negative w/h on some browsers.
    textarea.style.width = "1px";
    textarea.style.height = "1px";

    // We don't need padding, reducing the size if it does flash render.
    textarea.style.padding = "0";

    // Clean up any borders.
    textarea.style.border = "none";
    textarea.style.outline = "none";
    textarea.style.boxShadow = "none";

    // Avoid flash of white box if rendered for any reason.
    textarea.style.background = "transparent";
    const body = document.querySelector("body");
    if (!body) {
      return;
    }
    body.appendChild(textarea);
    existsTextarea = document.getElementById(id) as HTMLTextAreaElement;
  }

  existsTextarea.value = text;
  existsTextarea.select();

  try {
    var status = document.execCommand("copy");
  } catch (err) {
    console.log("Unable to copy.");
  }
}

export function getCycleDescription(cycle: IBaseCycle, isDevide: boolean = false, pricingModelType?: PricingModelType, showFrequency: boolean = true): string {
  const maxEndUnit = 1000000;
  let pricingTypeContent = "CREATE_PLAN_PRICING_TIERED";

  switch (pricingModelType) {
    case PricingModelType.Tiered:
      pricingTypeContent = "CREATE_PLAN_PRICING_TIERED";
      break;

    case PricingModelType.Ranged:
      pricingTypeContent = "CREATE_PLAN_PRICING_RANGED";
      break;

    case PricingModelType.Volume:
      pricingTypeContent = "CREATE_PLAN_PRICING_VOLUME";
      break;

    default:
      break;
  }

  //If tiered cycle
  if (cycle.pricingModel.tiers && cycle.pricingModel.tiers.length !== 0) {
    const firstTier = cycle.pricingModel.tiers[0];
    const finalTier = cycle.pricingModel.tiers[cycle.pricingModel.tiers.length - 1];

    return `${counterpart(cycle.pricingModel.tiers.length > 1 ? "CREATE_PLAN_CYCLE_TIERED_DESC" : "CREATE_PLAN_CYCLE_TIERED_DESC_SINGLE", {
      firstPrice: firstTier.priceFormatted,
      firstRange: firstTier.finish === maxEndUnit ? `${firstTier.start} ${counterpart("CREATE_PLAN_UNITS_ABOVE")}` : `${firstTier.start}-${firstTier.finish}`,
      finalPrice: finalTier.priceFormatted,
      finalRange: finalTier.finish === maxEndUnit ? `${finalTier.start} ${counterpart("CREATE_PLAN_UNITS_ABOVE")}` : `${finalTier.start}-${finalTier.finish}`,
      pricingModelType: counterpart(pricingTypeContent),
      frequency: showFrequency && `${getCycleFrequencyText(cycle.pricingModel.frequency, cycle.pricingModel.frequencyType)}`
    })}`;
  }
  return `${cycle.pricingModel.priceFormatted} ${getCycleFrequencyText(cycle.pricingModel.frequency, cycle.pricingModel.frequencyType, pricingModelType)}`;
}

export function getAddOnAllowancePriceModelDescription(pricingModel: IPlanAddOnPricingModel | IPlanAllowancePricingModel,
  showFrequency: boolean = true): string {

  const maxEndUnit = 1000000;
  let pricingTypeContent = "CREATE_PLAN_PRICING_TIERED";

  switch (pricingModel.pricingModelType) {
    case PricingModelTypeGrpc.TIERED:
      pricingTypeContent = "CREATE_PLAN_PRICING_TIERED";
      break;

    case PricingModelTypeGrpc.RANGED:
      pricingTypeContent = "CREATE_PLAN_PRICING_RANGED";
      break;

    case PricingModelTypeGrpc.VOLUME:
      pricingTypeContent = "CREATE_PLAN_PRICING_VOLUME";
      break;

    default:
      break;
  }

  //If tiered addon
  if (pricingModel.tiers && pricingModel.tiers.length !== 0) {
    const firstTier = pricingModel.tiers[0];
    const finalTier = pricingModel.tiers[pricingModel.tiers.length - 1];

    return `${counterpart(pricingModel.tiers.length > 1 ? "CREATE_PLAN_CYCLE_TIERED_DESC" : "CREATE_PLAN_CYCLE_TIERED_DESC_SINGLE", {
      firstPrice: firstTier.price,
      firstRange: firstTier.finish === maxEndUnit ? `${firstTier.start} ${counterpart("CREATE_PLAN_UNITS_ABOVE")}` : `${firstTier.start}-${firstTier.finish}`,
      finalPrice: finalTier.price,
      finalRange: finalTier.finish === maxEndUnit ? `${finalTier.start} ${counterpart("CREATE_PLAN_UNITS_ABOVE")}` : `${finalTier.start}-${finalTier.finish}`,
      pricingModelType: counterpart(pricingTypeContent),
      frequency: showFrequency && `${getCycleFrequencyText(pricingModel.frequency, getFrequencyTypeEnum(pricingModel.frequencyType))}`
    })}`;
  }
  return `${pricingModel.perUnitPrice} ${getCycleFrequencyText(pricingModel.frequency,
    getFrequencyTypeEnum(pricingModel.frequencyType), getPricingModelTypeEnum(pricingModel.pricingModelType))}`;
}

export const getCycleFrequencyText = (frequency: number, frequencyType: FrequencyType, pricingModelType?: PricingModelType) => {
  if (frequency > 1) {
    return `${counterpart(pricingModelType === PricingModelType.PerUnit ? "FREQUENCY_EVERY_PER_UNIT" : "FREQUENCY_EVERY")} ${frequency} ${getFrequencyText(frequency, frequencyType)}`;
  }
  return `${counterpart(pricingModelType === PricingModelType.PerUnit ? "FREQUENCY_EVERY_PER_UNIT" : "FREQUENCY_EVERY")} ${getFrequencyText(frequency, frequencyType)}`;
};

export const getCycleFreeTrialText = (freeTrial: number | undefined, freeTrialFrequency: FrequencyType | FrequencyTypeGrpc | undefined) => {
  if (freeTrial && freeTrialFrequency) {
    return `${counterpart("FREE_TRIAL_WITH")} ${freeTrial} ${getFrequencyText(freeTrial, freeTrialFrequency)} ${counterpart("FREE_TRIAL_TEXT")}`;
  }
  return "";
};

/**
 * return string like [FirstPrice] for units [FirstRange] through [FinalPrice] for units [LastRange] (tiered|volume|ranged) 
 */
export const getUnitsOverageText = (priceModel: AddonPriceModel | AllowancePriceModel | SubscriptionAllowancePriceModel, priceModelType: PricingModelType) => {
  const firstTier = priceModel.getTiersList()[0];
  const finalTier = priceModel.getTiersList()[priceModel.getTiersList().length - 1];
  const maxEndUnit = 1000000;
  let pricingTypeContent = "CREATE_PLAN_PRICING_TIERED";

  switch (priceModelType) {
    case PricingModelType.Tiered:
      pricingTypeContent = "CREATE_PLAN_PRICING_TIERED";
      break;

    case PricingModelType.Ranged:
      pricingTypeContent = "CREATE_PLAN_PRICING_RANGED";
      break;

    case PricingModelType.Volume:
      pricingTypeContent = "CREATE_PLAN_PRICING_VOLUME";
      break;

    default:
      break;
  }
  return `${counterpart(priceModel.getTiersList().length > 1 ? "CREATE_PLAN_CYCLE_TIERED_DESC" : "CREATE_PLAN_CYCLE_TIERED_DESC_SINGLE", {
    firstPrice: firstTier.getPriceFormatted(),
    firstRange: firstTier.getFinish() === maxEndUnit ? `${firstTier.getStart()} ${counterpart("CREATE_PLAN_UNITS_ABOVE")}` : `${firstTier.getStart()}-${firstTier.getFinish()}`,
    finalPrice: finalTier.getPriceFormatted(),
    finalRange: finalTier.getFinish() === maxEndUnit ? `${finalTier.getStart()} ${counterpart("CREATE_PLAN_UNITS_ABOVE")}` : `${finalTier.getStart()}-${finalTier.getFinish()}`,
    pricingModelType: counterpart(pricingTypeContent),
    frequency: "",
  })}`
}

export const getUnitsLabel = (nrUnits: number) => {
  return counterpart(nrUnits > 1 ? "UPDATE_SUBSCRIPTION_ALLOWANCE_MODAL_UNITS" : "UPDATE_SUBSCRIPTION_ALLOWANCE_MODAL_UNIT");
}


export const getSetupFeeText = (setupFeeFormatted: string | null) => {
  if (setupFeeFormatted) {
    return `${setupFeeFormatted} ${counterpart("SETUP_FEE_TEXT")}`;
  }
  return "";
};

export const getFreeQuantityText = (freeQuantity: number) => {
  if (Number(freeQuantity)) {
    return counterpart(Number(freeQuantity) > 1 ? "FREE_QUANTITIY_TEXT" : "FREE_QUANTITIY_TEXT_SINGLE", { units: freeQuantity })
  }

  return "";
}

export const getFrequencyText = (frequency: number, frequencyType: FrequencyType | FrequencyTypeGrpc) => {
  switch (frequencyType) {
    case FrequencyType.Daily:
    case FrequencyTypeGrpc.DAILY:
      return frequency > 1 ? counterpart("FREQUENCY_DAYS") : counterpart("FREQUENCY_DAY");
    case FrequencyType.Weekly:
    case FrequencyTypeGrpc.WEEKLY:
      return frequency > 1 ? counterpart("FREQUENCY_WEEKS") : counterpart("FREQUENCY_WEEK");
    case FrequencyType.Monthly:
    case FrequencyTypeGrpc.MONTHLY:
      return frequency > 1 ? counterpart("FREQUENCY_MONTHS") : counterpart("FREQUENCY_MONTH");
    case FrequencyType.Yearly:
    case FrequencyTypeGrpc.YEARLY:
      return frequency > 1 ? counterpart("FREQUENCY_YEARS") : counterpart("FREQUENCY_YEAR");
  }
};


export const getFrequencyTextLabel = (frequency: number, frequencyType: FrequencyType) => {
  if (frequency > 1) {
    return `${counterpart("FREQUENCY_EVERY")} ${getFrequencyText(frequency, frequencyType)}`;
  }
  return `${counterpart("FREQUENCY_PER")} ${getFrequencyText(frequency, frequencyType)}`;
}

/**
 * get stripe connection URL
 * https://stripe.com/docs/connect/standard-accounts#redirected
 */
export function getStripeConnectURL(clientId: string, responseType = "code", scope = "read_write") {
  const redirectUri = `${isLocalHost ? "http://www.local" : "https://app"}${ConfigConstants.billsbyDomain}${isLocalHost ? `:${process.env.REACT_APP_PORT}` : ""}/auth/stripe`;
  return `https://connect.stripe.com/oauth/authorize?response_type=${responseType}&client_id=${clientId}&scope=${scope}&redirect_uri=${redirectUri}`;
}

export function isRegistrationFromInvitationQueryString() {
  const queryParams = queryString.parse(window.location.search);
  return queryParams.invitationCode && queryParams.companyDomain && queryParams.email;
}

export function checkBillsbyOneSessionLogin() {
  const billsbyOneSessionLogin = storeGet(BILLSBY_ONE_SESSION_LOGIN);
  // if the user logged for just one session we invalidate the token, one session for simplicity last one minute
  // we cannot use the session storage cause the session is invalidated when we redirect to the company domain
  if (billsbyOneSessionLogin) {
    const now = moment();
    const sessionDate = moment(billsbyOneSessionLogin);
    const diff = moment.duration(now.diff(sessionDate)).asDays();
    if (diff > 1) {
      storeRemove(BILLSBY_ONE_SESSION_LOGIN);
      storeRemove(BILLSBY_AUTH_DATA_CONTROL);
      storeRemove(BILLSBY_SELECTED_COMPANY);
    }
  }
}

/**
 * @returns the formatted label 1st, 2sd, 3rd..
 * @param nr between 1 and 30
 */
export function getBillingDayLabelFormatted(nr: number) {
  switch (nr) {
    case 1:
      return `${nr}st`;
    case 2:
      return `${nr}nd`;
    case 3:
      return `${nr}rd`;
    case 21:
      return `${nr}st`;
    case 22:
      return `${nr}nd`;
    case 23:
      return `${nr}rd`;
    default:
      return `${nr}th`;
  }
}


export const getCreditNoteStatusText = (status: CreditNoteStatus) => {
  return `CREDIT_NOTES_STATUS_${status.toUpperCase()}`;
};

export const getCreditNoteStatusColor = (status: CreditNoteStatus) => {
  switch (status) {
    case CreditNoteStatus.PAID:
      return COLOR.GREEN;
    case CreditNoteStatus.PENDING:
      return COLOR.ORANGE;
    case CreditNoteStatus.FAILED:
    case CreditNoteStatus.UNPAID:
      return COLOR.LIGHT_RED;
    case CreditNoteStatus.WRITTEN_OFF:
      return COLOR.RED;
    case CreditNoteStatus.CLEARING:
      return COLOR.LIGHT_ORANGE
    default:
      return undefined;
  }
};

export const capitalizeFirstLetter = (text: string) => {
  return text.charAt(0).toUpperCase() + text.slice(1)
}

export const getCycleStatusColor = (status: VisibilityType) => {
  switch (status) {
    case VisibilityType.Internal:
    case VisibilityType.Public:
      return COLOR.GREEN;
    case VisibilityType.Hidden:
      return COLOR.LIGHT_GREEN;
    case VisibilityType.OffSale:
      return COLOR.RED;
  }
};

export const extractDecimal = (value: string): { base: string; decimal?: string } => {
  if (typeof value !== "string") {
    return { base: "", decimal: "" };
  }
  const base = value.split(".").length > 1 ? value.split(".")[0] : value;
  const decimal = value.split(".").length > 1 ? value.split(".")[1] : undefined;
  return { base, decimal };
};
export const getInvoiceStatusColor = (status: InvoiceStatus) => {
  switch (status) {
    case InvoiceStatus.PAID:
      return COLOR.GREEN;
    case InvoiceStatus.PAID_MANUALLY:
      return COLOR.GREEN;
    case InvoiceStatus.UNPAID:
    case InvoiceStatus.FAILED:
      return COLOR.LIGHT_RED;
    case InvoiceStatus.PAID_OFFLINE:
      return COLOR.LIGHT_GREEN;
    case InvoiceStatus.PENDING:
      return COLOR.ORANGE;
    case InvoiceStatus.CLEARING:
      return COLOR.LIGHT_ORANGE;
    case InvoiceStatus.TOBEWRITTEN_OFF:
    case InvoiceStatus.WRITTEN_OFF:
      return COLOR.RED;
    default:
      return undefined;
  }
};

export const getInvoiceStatusText = (status: InvoiceStatus) => {
  return `INVOICE_STATUS_${status.toUpperCase()}`;
};

export const getProductStatusColor = (status: VisibilityType | VisibilityTypeGrpc) => {
  switch (status) {
    case VisibilityType.Public:
    case VisibilityTypeGrpc.PUBLIC:
      return COLOR.GREEN;

    case VisibilityType.Hidden:
    case VisibilityTypeGrpc.HIDDEN:
      return COLOR.LIGHT_GREEN;

    case VisibilityType.Internal:
    case VisibilityTypeGrpc.INTERNAL:
      return COLOR.ORANGE;

    case VisibilityType.OffSale:
    case VisibilityTypeGrpc.OFFSALE:
      return COLOR.RED;
  }
};

export const getProductStatusText = (status: VisibilityType | VisibilityTypeGrpc) => {
  let _status: string = "";

  switch (status) {
    case VisibilityType.Public:
    case VisibilityTypeGrpc.PUBLIC: {
      _status = VisibilityType.Public;
      break;
    }

    case VisibilityType.Hidden:
    case VisibilityTypeGrpc.HIDDEN: {
      _status = VisibilityType.Hidden;
      break;
    }

    case VisibilityType.Internal:
    case VisibilityTypeGrpc.INTERNAL: {
      _status = VisibilityType.Internal;
      break;
    }
    case VisibilityType.OffSale:
    case VisibilityTypeGrpc.OFFSALE: {
      _status = VisibilityType.OffSale;
      break;
    }
  }


  return `PRODUCT_STATUS_${(_status || VisibilityType.Public).toUpperCase()}`;
};

export const getFrequencyTypeEnum = (arg: FrequencyTypeGrpc) => {
  switch (arg) {
    case FrequencyTypeGrpc.UNSPECIFIED_FT: return FrequencyType.Daily;
    case FrequencyTypeGrpc.DAILY: return FrequencyType.Daily;
    case FrequencyTypeGrpc.WEEKLY: return FrequencyType.Weekly;
    case FrequencyTypeGrpc.MONTHLY: return FrequencyType.Monthly;
    case FrequencyTypeGrpc.YEARLY: return FrequencyType.Yearly;
  }
};

export const getFrequencyTypeGrpc = (arg: FrequencyType) => {
  switch (arg) {
    case FrequencyType.Daily: return FrequencyTypeGrpc.DAILY;
    case FrequencyType.Weekly: return FrequencyTypeGrpc.WEEKLY;
    case FrequencyType.Monthly: return FrequencyTypeGrpc.MONTHLY;
    case FrequencyType.Yearly: return FrequencyTypeGrpc.YEARLY;
  }
}

export const getPricingModelTypeEnum = (arg: PricingModelTypeGrpc) => {
  switch (arg) {
    case PricingModelTypeGrpc.UNSPECIFIED_PMT: return PricingModelType.FlatFee;
    case PricingModelTypeGrpc.FLAT_FEE: return PricingModelType.FlatFee;
    case PricingModelTypeGrpc.PER_UNIT: return PricingModelType.PerUnit;
    case PricingModelTypeGrpc.TIERED: return PricingModelType.Tiered;
    case PricingModelTypeGrpc.RANGED: return PricingModelType.Ranged;
    case PricingModelTypeGrpc.VOLUME: return PricingModelType.Volume;
    case PricingModelTypeGrpc.CAPPED: return PricingModelType.Capped;
  }
};

export const getPricingModelTypeGrpc = (arg: PricingModelType) => {
  switch (arg) {
    case PricingModelType.FlatFee: return PricingModelTypeGrpc.FLAT_FEE;
    case PricingModelType.PerUnit: return PricingModelTypeGrpc.PER_UNIT;
    case PricingModelType.Ranged: return PricingModelTypeGrpc.RANGED;
    case PricingModelType.Tiered: return PricingModelTypeGrpc.TIERED;
    case PricingModelType.Volume: return PricingModelTypeGrpc.VOLUME;
    case PricingModelType.Capped: return PricingModelTypeGrpc.CAPPED;
  }
}

export const getMonthYearDates = (from: string = "Jan 2010", to: string = moment().format("MMM YYYY"), isDropdown: boolean = true) => {
  const startDate = moment.utc(from, "MMM YYYY").startOf("month");
  const endDate = moment.utc(to, "MMM YYYY").startOf("month");
  const dateList: any = [];

  while (endDate > startDate || startDate.format("M") === endDate.format("M")) {
    const formattedDate = startDate.format("MMM YYYY");

    if (isDropdown) {
      dateList.push({ label: formattedDate, value: formattedDate });
    } else dateList.push(formattedDate);

    startDate.add(1, "M");
  }

  return dateList;
};

export function camelize(str: string) {
  return str
    .replace(/(?:^\w|[A-Z]|\b\w|\s+)/g, function (match, index) {
      if (+match === 0) return ""; // or if (/\s+/.test(match)) for white spaces
      return index === 0 ? match?.toLowerCase() : match?.toUpperCase();
    })
    .replace(/-/g, "");
}

export const getCardLogo = (cardType: string) => {
  switch (cardType) {
    case "visa": {
      return "fab fa-cc-visa";
    }
    case "master": {
      return "fab fa-cc-mastercard";
    }
    case "american_express": {
      return "fab fa-cc-amex";
    }
    case "jcb": {
      return "fab fa-cc-jcb";
    }
    case "diners_club": {
      return "fab fa-cc-diners-club";
    }

    default: {
      return "";
    }
  }
};

export const getTieredUnitCycles = (plan: IPlanWithCycles, units: number | undefined, currencyFormat: string = "$_ ") => {
  let newCycle: Array<ICycle> = [];

  if (!units) {
    return null;
  }

  plan.cycles.forEach(cycle => {
    let amount = 0;
    let _units = cycle.pricingModel.freeQuantity ? units - cycle.pricingModel.freeQuantity : units;

    if (cycle.pricingModel.tiers) {
      cycle.pricingModel.tiers.forEach(tier => {
        if (plan.pricingModelType === PricingModelType.Tiered) {
          if (tier.start + _units <= tier.finish) {
            amount += _units * tier.price;
            _units = 0;
          } else {
            const tierUnits = tier.finish - tier.start + 1;
            amount += tierUnits * tier.price;
            _units -= tierUnits;
          }
        }

        if (plan.pricingModelType === PricingModelType.Ranged) {
          if (tier.start <= _units && tier.finish >= _units) {
            amount = tier.price
          }
        }

        if (plan.pricingModelType === PricingModelType.Volume) {
          if (tier.start <= _units && tier.finish >= _units) {
            amount = _units * tier.price;
            amount = amount < 0 ? 0 : amount;
          }
        }

        if (tier.start <= units && tier.finish >= units) {
          newCycle.push({ ...cycle, pricingModel: { ...cycle.pricingModel, price: amount, priceFormatted: `${currencyFormat.replace("_", (amount / 100).toString())}` } });
        }
      });
    }

    if (plan.pricingModelType === PricingModelType.PerUnit) {
      amount = cycle.pricingModel.price * _units;
      amount = amount < 0 ? 0 : amount;
      newCycle.push({ ...cycle, pricingModel: { ...cycle.pricingModel, price: amount, priceFormatted: `${currencyFormat.replace("_", (amount / 100).toString())}` } });
    }
  });

  return newCycle;
};

export const getMaximumUnit = (plan: IPlanWithCycles) => {
  let tierFinish: Array<number> = [];

  plan.cycles.forEach(cycle => {
    if (cycle.pricingModel.tiers) {
      cycle.pricingModel.tiers.forEach(tier => {
        if (tierFinish.indexOf(tier.finish) === -1) {
          tierFinish.push(tier.finish)
        }
      })
    }

    return 0;
  })

  return Math.max(...tierFinish);
}

export const getMaximumUnitAddOn = (pricingModel: IPlanAddOnPricingModel | IPlanAllowancePricingModel) => {
  let tierFinish = new Set<number>();
  const maxEndUnit = 1000000;

  if (pricingModel.tiers && pricingModel.tiers.length) {
    pricingModel.tiers.forEach(tier => {
      tierFinish.add(tier.finish);
    })
  } else {
    tierFinish.add(maxEndUnit);
  }

  return Math.max(...Array.from(tierFinish));
}

export const isCardExpired = (expiryMonth: number, expiryYear: number) => {
  return moment()
    .month(expiryMonth - 1)
    .year(expiryYear)
    .isBefore(moment());
};

/*
 * delay the execution of the program of t ms and returns a promise that will resolve after t ms
 */
export const delay = (t: number) => new Promise(resolve => setTimeout(resolve, t));

/**
 * utility to download a pdf with js2pf
 * doc: https://github.com/eKoopmans/html2pdf.js
 * @param element
 * @param options
 */
export const downloadPdf = (element: HTMLElement, filename: string = "draft", extendOptions?: any,) => {
  const baseOptions = {
    margin: [10, 2, 10, 3.5],
    html2canvas: {
      //letterRendering: 1,
      // useCors = true allow downloading images such as the company logo before the conversion to pdf
      useCORS: true,
      height: element.scrollHeight,
      //the scaling removes the unusual borders after the conversion
      scale: 1.1
    }
  };

  const options = {
    ...baseOptions,
    ...extendOptions
  };

  return html2pdf()
    .set(options)
    .from(element)
    .save(filename)
    .then(() => delay(300));
};

export const downloadCSV = (tableClassname: string, filename: string = "CSV_File") => {
  function download_csv(csv: any, filename: string) {
    var csvFile;
    var downloadLink;

    // CSV FILE
    csvFile = new Blob(["\ufeff", csv], { type: "text/csv;charset=utf-8,%EF%BB%BF" });

    // Download link
    downloadLink = document.createElement("a");

    // File name
    downloadLink.download = filename;

    // We have to create a link to the file
    downloadLink.href = window.URL.createObjectURL(csvFile);

    // Make sure that the link is not displayed
    downloadLink.style.display = "none";

    // Add the link to your DOM
    document.body.appendChild(downloadLink);

    downloadLink.click();
  }

  var csv = [];
  var rows = document.querySelectorAll(`table${tableClassname} tr`);

  for (var i = 0; i < rows.length; i++) {
    var row = [],
      cols = rows[i].querySelectorAll("td, th");

    for (var j = 0; j < cols.length; j++) row.push(`"=""${(cols[j] as HTMLElement).innerText}"""`);

    csv.push(row.join(","));
  }

  // Download CSV
  download_csv(csv.join("\n"), `${filename.split(" ").join("_")}.csv`);
};

/**
 * @returns true if the given string is a valid URL
 * @param url
 */
export const isValidUrl = (url: string) => {
  return url.match(/^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/);
};

export const isValidEmailFormat = (email: string, isMultipleEmails: boolean = true) => {
  const re = /\S+@\S+\.\S+/;
  if(!isMultipleEmails && email.includes(",")) {
    return false;
  }
  return re.test(email);

  /*const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());*/
}

export const isPriceWith2Decimals = (price: string) => {
  return String(price).match(/^\d+\.*\d{0,2}$/);
}

export const isCustomerFacingPage = () => {
  return history.location.pathname.startsWith("/invoice") || history.location.pathname.startsWith("/creditnote");
}

export function paginateItems<T>(items: Array<T>, pageNumber: number, itemsPerPage: number = 10): Array<T> {
  return items.slice(
    (pageNumber - 1) * itemsPerPage,
    pageNumber * itemsPerPage
  );
};

export const simulatePrice = (units: number, tiersInput: AddOnUnitTier[], pricingModelType: PricingModelType) => {
  let price = 0;

  tiersInput.forEach(tier => {
    if (pricingModelType === PricingModelType.Tiered) {
      for (let i = 1; i <= units; i++) {
        if (tier.getStart() <= i && tier.getFinish() >= i) {
          price += tier.getPrice();
        }
      }
    }

    if (pricingModelType === PricingModelType.Ranged) {
      if (tier.getStart() <= units && tier.getFinish() >= units) {
        price = tier.getPrice();
      }
    }

    if (pricingModelType === PricingModelType.Volume) {
      if (tier.getStart() <= units && tier.getFinish() >= units) {
        price = units * tier.getPrice();
      }
    }
  });

  return price / 100;
};

export const simulatePricePerTier = (units: number, tier: AddOnUnitTier, pricingModelType: PricingModelTypeGrpc) => {
  let price = 0;


  if (pricingModelType === PricingModelTypeGrpc.TIERED) {
    for (let i = 1; i <= units; i++) {
      if (tier.getStart() <= i && tier.getFinish() >= i) {
        price += tier.getPrice();
      }
    }
  }

  if (pricingModelType === PricingModelTypeGrpc.RANGED) {
    if (tier.getStart() <= units && tier.getFinish() >= units) {
      price = tier.getPrice();
    }
  }

  if (pricingModelType === PricingModelTypeGrpc.VOLUME) {
    if (tier.getStart() <= units && tier.getFinish() >= units) {
      price = units * tier.getPrice();
    }
  }

  return price / 100;
}

export const getMaxEndTierUnit = (tierList: AddOnUnitTier[]) => {
  return Math.max.apply(Math, tierList.map(function(tier) { return tier.getFinish(); }))
}

export const groupBy = (array: Array<any>, func: (item: any) => void) => {
  var groups: any = {};
  array.forEach(function (o) {
    var group = JSON.stringify(func(o));
    groups[group] = groups[group] || [];
    groups[group].push(o);
  });
  return Object.keys(groups).map(function (group) {
    return groups[group];
  });
}

export const taxRateChecker = (value: any) => {
  if (value === "" || isNaN(+value) || +value < 0) {
    return -1 // set to -1 when value is undefined
  }

  return +value
}

/**
 * utility to multiply any number value by a 100 preserving the decimals
 * @param value 
 */
type K<T> = T extends string | number | Int32Value ? T : never;
export function multiplyNumberBy100<K>(inputVal?: K): K | undefined {
  if (!inputVal) { return }
  if (!(inputVal as any as Int32Value).getValue) {
    //treat it like a number primitive
    //return Math.round(Number(value) * 100) as any as T;
    const val = Math.round(Number(inputVal) * 100)
    if(typeof inputVal === "number") return val as any as K
    if(typeof inputVal === "string") return String(val) as any as K
  }
  const newVal = (inputVal as any as Int32Value).getValue();
  const newValBy100 = new Int32Value();
  newValBy100.setValue(Math.round(newVal * 100));
  return newValBy100 as any as K;
}

/**
 * utility to divide any number value by a 100 preserving the decimals
 * @param inputVal 
 */
export function divideNumberBy100<K>(inputVal?: K): K | undefined {
  if (inputVal === undefined || inputVal === null) { return }
  if (!(inputVal as any as Int32Value).getValue) {
    //treat it like a number primitive
    const val = Number(inputVal) / 100
    if(typeof inputVal === "number") return val as any as K
    if(typeof inputVal === "string") return String(val) as any as K
  }
  const newVal = (inputVal as any as Int32Value).getValue();
  const newValDivideBy100 = new Int32Value();
  newValDivideBy100.setValue(newVal / 100);
  return newValDivideBy100 as any as K;
}


export const extractGrpcErrorMessage = (message: string) => {
  const fromWord = message.indexOf("ErrorMessage: ") > -1 ? "ErrorMessage: " : "; "
  return message.split(fromWord).pop() || "";
}

export const extractInfoErrorMessage = (text?: string) => { 
  const match = text?.match(/Detail="([^"]+)"/);
  if (match && match.length > 1) {
    return match[1];
  } else {
    return "";
  }
}

export const getNumberOfDays = (pm: AddonPriceModel | AllowancePriceModel): number => {
  switch (pm.getFrequencyType()) {
    case FrequencyTypeGrpc.DAILY:
      return pm.getFrequency() * 1
    case FrequencyTypeGrpc.WEEKLY:
      return pm.getFrequency() * 7
    case FrequencyTypeGrpc.MONTHLY:
      return pm.getFrequency() * 30.4167
    case FrequencyTypeGrpc.YEARLY:
      return pm.getFrequency() * 365
    default: return 1
  }
}

export const downloadCsvFile = (rows: Array<any>, name: string) => {
  let csvContent = "data:text/csv;charset=utf-8,\uFEFF"
    + rows.map(e => e.join(",")).join("\n");

  var encodedUri = encodeURI(csvContent);
  var link = document.createElement("a");
  link.setAttribute("href", encodedUri);
  link.setAttribute("download", `${name}.csv`);
  document.body.appendChild(link);
  link.click();
}

/**
 * trim whitespaces from string, at the beginning, at the end, or all of them
 */
export const trimWhiteSpaces = (text: string, type: "left" | "right" | "all") => {
  if (type === "left") {
    return text.replace(/^\s+/g, "");
  }
  if (type === "right") {
    return text.replace(/\s+$/g, "");
  }
  return text.replace(/\s/g, "");
}

export const getErrorMessageInGrpc = (message: string) => {
  const regExpParenthesis = /\((.*)\)/;
  const outsidePatenthiesisMatches = regExpParenthesis.exec(message);
  try {
    if (!!outsidePatenthiesisMatches) {
      const insidePatenthiesisMatches = regExpParenthesis.exec(outsidePatenthiesisMatches[1]);
      if (!!insidePatenthiesisMatches) {
        var regExpQuotes = /\"(.*)\"/;
        var details = regExpQuotes.exec(insidePatenthiesisMatches[1]);
        return !!details ? details[1] : ""
      }
    }
  } catch { }

  return "";
}

export const allowNumberMoreThanZero = (input?: string) => {
  if (!!input && Number(input) > 0)
    return +input;

  return undefined;
}

export const allowZeroAndPositiveNumbers = (input?: string) => {
  if (!!input && Number(input) >= 0)
    return +input;

  return undefined;
}

export const getActionErrorMessage = (error: { message: string | undefined, list: Array<{ description: string }> | undefined }) => {
  if (error.message) {
    return error.message
  }

  if (error.list) {
    return error.list.map(err => err.description).join(", ");
  }

  return ""
}

export const isComplexPricingModel = (pricingModelType: PricingModelTypeGrpc | PricingModelType) => {
  return [PricingModelTypeGrpc.PER_UNIT, PricingModelTypeGrpc.TIERED,
  PricingModelTypeGrpc.VOLUME, PricingModelTypeGrpc.RANGED,
  PricingModelType.PerUnit, PricingModelType.Tiered,
  PricingModelType.Volume, PricingModelType.Ranged].some(el => el === pricingModelType);
}

export const convertFormattedPriceToNumber = (formattedPrice: string) => {
  return +formattedPrice.replace(/[^0-9.]/g, "")
}

export const isInteger = (value: any, range: "onlyPositive" | "onlyNegative" | "all" = "all") => {
  if (isNaN(value)) {
    return false;
  }
  const val = parseFloat(value);
  const isInteger = (val | 0) === val;
  if (!isInteger) {
    return false;
  }
  if (range === "onlyPositive") {
    return isInteger && val >= 0;
  }
  return isInteger && val < 0;
}

export const getPublicIpV4 = async () => {
  const ipv4 = await publicIp.v4() as any;
  return ipv4
}

export const getCardText = (cardType: string) => {
  switch (cardType) {
    case "master":
      return "Mastercard";
    case "visa":
      return "Visa"
    default:
      return cardType;
  }
}

export const hexToRgb = (hex: string) => {
  const bigint = parseInt(hex.substring(1), 16);
  const r = (bigint >> 16) & 255;
  const g = (bigint >> 8) & 255;
  const b = bigint & 255;

  return r + "," + g + "," + b;
}

export const formattedFeatureTag = (tag: string) => {
  return tag.replace(/[^a-zA-Z0-9]/g, "").toLowerCase().trim()
}

export const getDatesFromRange = (startDate: string, endDate: string) => {
  const dateFormat = "MMM YYYY";
  const dates = [];
  const _startDate = moment.utc(startDate, dateFormat).startOf("day")
  const _endDate = moment.utc(endDate, dateFormat).startOf("day");
  while (_startDate.diff(endDate) <= 0) {
    dates.push(_startDate.clone().format(dateFormat));
    _startDate.add(1, "months")
  }
  return dates;
}

export const randomInteger = (min: number, max: number) => {
  return Math.floor(Math.random() * (max - min + 1)) + min;
}

export const convertToDecimalFormat = (value: number) => {
  if(value % 1 === 0) {
    return value.toString()
  }

  return parseFloat(value.toString()).toFixed(2)
}

export const removeSpecialChars = (input?: any) => {
  return input && input.toString().replace(/[^0-9a-z-A-Z ]/g, "").replace(/ +/, " ")
}

export const getIpLocation = async () => {
  try {
    const ipAddress = await getPublicIpV4();
    return await fetchGet(`https://ipapi.co/${ipAddress}/json/`, {} as any) as { country_code: string, country_name: string };
  } catch(err) {
    console.log(err)
  }
}

export function isValidJSON(text: string) {
  try {
    JSON.parse(text);
    return true;
  } catch {
    return false;
  }
}

export const validProductImageSize = (image: HTMLImageElement) => image.width >= 400 && image.height >= 400;

export const sendGaEvent = (...params: any) => {
  if ("gtag" in window) {
    let gtag = window.gtag as any;
    gtag(...params);
  }
}

interface IAnalyticsEventConfig {
  category?: string,
  label?: string,
  value?: number,
  nonInteraction?: boolean,
  transport?: "xhr" | "beacon" | "image"
}

export const gaEventTracker = (action: CustomeAnalyticsEvents, config: IAnalyticsEventConfig = { value: 1 }) => {
  return ReactGA.event(action, config)
} 

export const toAddOnTierUnit = (unitTier: AllowanceUnitTier | AddOnUnitTier | SubscriptionUnitTier) => {
  const newUnitTier = new AddOnUnitTier();
  newUnitTier.setStart(unitTier.getStart());
  newUnitTier.setFinish(unitTier.getFinish());
  newUnitTier.setPrice(unitTier.getPrice());
  newUnitTier.setPriceFormatted(unitTier.getPriceFormatted());
  return newUnitTier;
}

export const removeDuplicatedItems = (arr: Array<any>, key?: any) => {
  if(key) {
    return arr.filter((v,i,a)=>a.findIndex(t=>(t[key] === v[key]))===i)
  }
  return arr.filter(function(item, pos, self) {
    return self.indexOf(item) === pos;
  })
}

export const getErrorMessage = (error: any) => {
  if (!!error && !!error.message)
    return error.message;
    
  if (!!error && !!error.list && error.list.length > 0)
    return error.list[0].description;

  return counterpart("GENERIC_ERROR");
}

export const getInvoiceRefundError = (error: InvoiceRefundType) => {
  switch(error) {
    case InvoiceRefundType.ACH_EXPIRED:
      return "INVOICE_REFUND_ERROR_EXPIRED_ACH"
    case InvoiceRefundType.INVOICE_UNPAID:
    case InvoiceRefundType.MISSING_SUCCESSFUL_PAYMENT_LOG:
      return "INVOICE_REFUND_ERROR_UNSUCCESSFUL_LOG"
    case InvoiceRefundType.FULLY_REFUNDED:
      return "INVOICE_REFUND_ERROR_FULLY_REFUNDED"
    case InvoiceRefundType.NOT_AVAILABLE:
      return "INVOICE_REFUND_ERROR_GENERIC"
    default:
      return undefined
  }
}

export function lightOrDark(hex: any) {
  let rgba: any = hexToRgb(hex);
  rgba = rgba.match(/\d+/g);
  if((rgba[0]*0.299)+(rgba[1]*0.587)+(rgba[2]*0.114)>186) {
    return "light";
  } else {
    return "dark";
  }
}

/**
 * This method formats a number using fixed-point notation without rounding-off.
 * Useful when dealing with repeating decimals.
 * 
 * Example: toFixedWithoutRounding(0.1666666666, 2) === 0.16
 * 
 * @param {number} num 
 * @param {number} fixed The number of digits to appear after the decimal point
 */
export const toFixedWithoutRounding = (num: number, fixed: number) => {
  const re = new RegExp("^-?\\d+(?:\.\\d{0," + (fixed || -1) + "})?");
  return +(num.toString().match(re) || [0])[0];
}

export const changeFavIcon = (icon: string = "", removeExisting = true) => {
  if (removeExisting) {
    // remove existing fav-icons
    const favIconsDom = document.querySelectorAll("link[rel*='icon']");
    favIconsDom.forEach(el => el.remove());
  }
  const link: HTMLLinkElement = document.querySelector("link[rel*='icon']") || document.createElement("link");
  link.type = "image/x-icon";
  link.rel = "shortcut icon";
  link.href = icon;
  document.getElementsByTagName("head")[0].appendChild(link);
}

export const addScriptDynamically = (data: { url?: string, scriptAsText?: string }, isAsync = false, queryParams: { [key: string]: string } = {}, onloadCb?: () => void) => {
  let existingQueryString = "";
  let { url, scriptAsText } = data;
  
  if (url && url.indexOf("?") > 0) {
    existingQueryString = url.substring(url.indexOf("?"), url.length);
    url = url.substring(0, url.indexOf("?"));
  }

  const queryStringParsed: any = queryString.parse(existingQueryString);
  Object.keys(queryParams).forEach(queryParamKey => {
    queryStringParsed[queryParamKey] = queryParams[queryParamKey];
  });
  const script: HTMLScriptElement = document.createElement("script");

  if(url) {
    script.setAttribute("src", `${url}?${queryString.stringify(queryStringParsed)}`);
  }
  if(scriptAsText) {
    script.text = scriptAsText
  }

  script.async = isAsync;
  document.head.appendChild(script);
  script.onload = () => {
    onloadCb?.();
  }
}

export const addNoScriptElementDynamically = (content: string) => {
  const noscript = document.createElement("noscript")
  noscript.textContent = content
  document.body.appendChild(noscript)
}

export const addSpaceBeforeCapitalLetter = (str: string) => str.replace(/([A-Z])/g, " $1").trim();