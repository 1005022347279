import React, { useEffect } from "react";
import Panel from "../../ui/panel/Panel";
import Text from "../../ui/text/Text";
import Button from "../../ui/button/Button";
import FormLabel from "../../ui/form-label/FormLabel";
import FormGroup from "../../ui/form-group/FormGroup";
import Dropdown from "../../ui/dropdown/Dropdown";
import { Row, Col } from "react-grid-system";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../../..";
import DateTimePicker from "../../../components/ui/date-time-picker/DateTimePicker"
import counterpart from "counterpart";
import { SubscriptionStatusType } from "../../../models/Subscriptions";
import "./ChangeBillDate.scss"
import { setSubscriptionDetailsField, updateBillingDate } from "../../../actions/subscriptionDetailsActions";
import moment from "moment";
import NoticePanel from "../../ui/notice-panel/NoticePanel";

interface IChangeBillDate {
  handleCallback?: () => void
}

const ChangeBillDate: React.FC<IChangeBillDate> = ({ handleCallback }) => {
  const { newBillingDate, newStatus, nextBillingDate, changeBillDateRequest, changeBillDateSuccess, changeBillDateError, changeBillErrorMessage, subscriptionUniqueId, status } = useSelector((state: AppState) => state.subscriptionDetailsReducer)
  const currentCompanyDomain = useSelector((state: AppState) => state.auth).currentCompanyDomain;

  const dispatch = useDispatch<Function>();

  const formattedNextBillingDate = moment(nextBillingDate).format("DD MMM YYYY");
  const options = [{
    value: SubscriptionStatusType.ACTIVE,
    label: counterpart("CHANGE_BILL_DATE_STATUS_ACTIVE")
  }, {
    value: SubscriptionStatusType.PAUSED,
    label: counterpart("CHANGE_BILL_DATE_STATUS_PAUSED")
  }]

  useEffect(() => {
    if (changeBillDateSuccess) {
      handleCallback && handleCallback();
    }

    return () => {
      dispatch(setSubscriptionDetailsField("changeBillDateSuccess", false));
      dispatch(setSubscriptionDetailsField("changeBillErrorMessage", undefined));
      dispatch(setSubscriptionDetailsField("newBillingDate", undefined));
      dispatch(setSubscriptionDetailsField("newStatus", undefined));
    }
  }, [changeBillDateSuccess])

  return (
    <div className="change-bill-date">
      {changeBillDateError && changeBillErrorMessage && <NoticePanel
        type="error"
        isModal
        shouldTranslate={false}
        content={changeBillErrorMessage}
      />}
      <Panel className="change-bill-date__title-panel" title="CHANGE_BILL_DATE">
        <Text
          className="change-bill-date__title-text"
          content="CHANGE_BILL_DATE_CONTENT"
          translateWith={{ nextBillDate: formattedNextBillingDate }}
          noMargin
        />
      </Panel>
      <Panel>
        <FormGroup>
          <Row align='center'>
            <Col md={2.5}>
              <FormLabel target='new-bill-date' content='CHANGE_BILL_DATE_NEW_BILL_DATE' noMargin />
            </Col>
            <Col md={9.5}>
              <DateTimePicker minDate={moment.utc().toDate()} className="change-bill-date__date-picker" value={newBillingDate} onChange={(date) => {dispatch(setSubscriptionDetailsField("newBillingDate", date))}
              } />
            </Col>
          </Row>
          {status !== SubscriptionStatusType.INTRIAL && <Row align='center' style={{ marginTop: "20px" }}>
            <Col md={2.5}>
              <FormLabel target='new-status' content='CHANGE_BILL_DATE_STATUS' noMargin />
            </Col>
            <Col md={9.5}>
              <Dropdown
                id='new-status'
                className="change-bill-date__date-status"
                value={newStatus}
                placeholder={counterpart("CHANGE_BILL_DATE_SELECT_STATUS")}
                onChange={(status: any) => dispatch(setSubscriptionDetailsField("newStatus", status))} options={options}></Dropdown>
            </Col>
          </Row>}
        </FormGroup>
        <Button
          title="CHANGE_BILL_DATE_BTN"
          id="change-bill"
          isLoading={changeBillDateRequest}
          disabled={status !== SubscriptionStatusType.INTRIAL ? !newBillingDate || !newStatus : !newBillingDate}
          isFullWidth
          onClick={() =>
            dispatch(updateBillingDate(currentCompanyDomain, subscriptionUniqueId, {
              newRenewalDate: newBillingDate ? moment.utc(newBillingDate).format(moment.defaultFormatUtc) : undefined,
              pauseSubscription: newStatus?.value === SubscriptionStatusType.PAUSED
            }))
          } />
      </Panel>
    </div>
  )
}

export default ChangeBillDate;
