import { BillsbyAction } from "../../models/BillsbyAction";
import { AppState } from "../..";
import {
  SELECT_ALL_ADD_ONS_SELECTED_PLAN,
  SET_SELECTED_COMPANY_PLANS_PAGE,
  SET_SELECTED_ADD_ONS_PLANS_PAGE,
  SET_ADD_ONS_SELECTED_PLAN,
  SET_ADD_ONS_SELECTED_PLANS_ID,
  SET_PLAN_FORCE_ADD_ON,
  REMOVE_ADD_ONS_SELECTED_PLAN,
  DESELECT_ALL_ADD_ONS_SELECTED_PLAN,
  FETCH_COMPANY_PRODUCTS_WITH_PLANS_SUCCESS,
  SET_SEARCH_KEY_ADD_ONS_PLANS_PAGE,
  CANCEL_SELECTED_ADD_ONS_PLANS_PAGE,
  SET_SHOW_SELECT_PLAN_MODAL,
  RESET_CREATE_ADD_ON_STATE,
  FETCH_ADD_ON_SUCCESS
} from "../../actions/addOnsActions";
import { GetAddonResponse, AddOnPlan } from "../../utils/grpc/generated/Billsby.Protos/core/private/addons/addons_pb";
import { paginateItems } from "../../utils/commonUtils";
import { GetAllowanceResponse, AllowancePlan } from "../../utils/grpc/generated/Billsby.Protos/core/private/allowances/allowances_pb";
import { AddonsAllowancesScreen } from "../../models/AddonsAllowances";

interface ICompanyPlans {
  product: string;
  plan: string;
  planId: number;
}

export interface ISelectedCompanyPlans {
  product: string;
  plan: string;
  planId: number;
  isForced: boolean;
}

export interface ISelectPlanAddOnsReducerState {
  companyPlans: Array<ICompanyPlans>;
  companyAllPlans: Array<ICompanyPlans>;
  companyPlansFiltered: Array<ICompanyPlans>;
  companyPlansDisplay: Array<ICompanyPlans>;
  selectedPlans: Array<ISelectedCompanyPlans>;
  selectedPlansIds: Array<number>;
  selectedPlansDisplay: Array<ISelectedCompanyPlans>;
  numberOfpages: number;
  currentPage: number;
  plansNumberOfPages: number;
  plansCurrentPage: number;
  showSelectPlanModal: boolean
}

export const initialState = {
  companyPlans: [],
  companyAllPlans: [],
  companyPlansFiltered: [],
  companyPlansDisplay: [],
  selectedPlans: [],
  selectedPlansIds: [],
  selectedPlansDisplay: [],
  numberOfpages: 0,
  currentPage: 1,
  plansNumberOfPages: 0,
  plansCurrentPage: 1,
  showSelectPlanModal: false
};

const itemsPerPage = 10;

const calculateNumberOfPages = (items: number) => {
  return items > 0 ? Math.ceil(items / itemsPerPage) : 0;
};

const sortItems = (items: Array<ISelectedCompanyPlans>) => {
  return items.sort((a: any, b: any) => {
    if (a.product.toLowerCase() < b.product.toLowerCase()) {
      return -1;
    }
    if (a.product.toLowerCase() > b.product.toLowerCase()) {
      return 1;
    }
    return a.plan.toLowerCase() < b.plan.toLowerCase() ? -1 : 1
  });
};

export default function selectAddOnPlanReducer(
  state: ISelectPlanAddOnsReducerState = initialState,
  action: BillsbyAction,
  store: AppState
) {
  switch (action.type) {
    case FETCH_COMPANY_PRODUCTS_WITH_PLANS_SUCCESS: {
      const products = action.response.results;

      const companyPlans = Array<ICompanyPlans>();
      const companyAllPlans = Array<ICompanyPlans>();
      products.forEach((product: any) => {
        product.plans.filter((i:any) => !i.isDeleted).forEach((plan: any) => {
          companyPlans.push({
            product: product.displayName,
            plan: !plan.displayName ? plan.name : plan.displayName,
            planId: plan.planId,
          });
        });
      });

      products.forEach((product: any) => {
        product.plans.forEach((plan: any) => {
          companyAllPlans.push({
            product: product.displayName,
            plan: !plan.displayName ? plan.name : plan.displayName,
            planId: plan.planId,
          });
        });
      });

      return {
        ...state,
        companyPlans: companyPlans,
        companyAllPlans: companyAllPlans,
        companyPlansFiltered: companyPlans,
        plansNumberOfPages: calculateNumberOfPages(companyPlans.length),
        numberOfpages: calculateNumberOfPages(state.selectedPlans.length),
        plansCurrentPage: 1,
        companyPlansDisplay: paginateItems(companyPlans, 1),
      };
    }
    case SET_SHOW_SELECT_PLAN_MODAL: {
      const newArray = state.selectedPlans.map(i => i.planId);
      return { ...state, showSelectPlanModal: action.payload, selectedPlansIds: newArray }
    }
    case SET_ADD_ONS_SELECTED_PLAN: {
      let newArray = [...state.selectedPlans];
      let plans = [...state.companyPlans];
      const ids = action.payload as Array<number>;
      newArray = newArray.filter(plan => ids.find(id => id === plan.planId));

      ids.forEach((i) => {
        const index = newArray.findIndex((item) => item.planId === i);
        if (index < 0) {
          const plan = plans.find((p) => p.planId === i);
          if (plan)
            newArray.push({
              product: plan.product,
              plan: plan.plan,
              planId: plan.planId,
              isForced: false,
            });
        }
      });

      newArray = sortItems(newArray);
      return {
        ...state,
        selectedPlans: newArray,
        numberOfpages: calculateNumberOfPages(newArray.length),
        currentPage: 1,
        selectedPlansDisplay: paginateItems(newArray, 1),
      };
    }
    case SET_ADD_ONS_SELECTED_PLANS_ID: {
      let newArray = [...state.selectedPlansIds];
      const index = newArray.findIndex((item) => item === action.payload);
      if (index >= 0) {
        newArray.splice(index, 1);
      } else {
        newArray.push(action.payload);
      }

      return { ...state, selectedPlansIds: newArray };
    }
    case SET_PLAN_FORCE_ADD_ON: {
      let newArray = [...state.selectedPlans];
      const index = newArray.findIndex(
        (item) => item.planId === action.payload
      );
      if (index >= 0) {
        newArray[index].isForced = !newArray[index].isForced;
      }

      return { ...state, selectedPlans: newArray };
    }
    case REMOVE_ADD_ONS_SELECTED_PLAN: {
      let newArray = [...state.selectedPlans];
      const index = newArray.findIndex(
        (item) => item.planId === action.payload
      );

      if (index >= 0) {
        newArray.splice(index, 1);
      }
      const selectedPlansIds = newArray.map(plan => plan.planId);
      const numberOfpages = calculateNumberOfPages(newArray.length);
      const currentPage = state.currentPage > numberOfpages ? numberOfpages : state.currentPage;
      return {
        ...state,
        selectedPlans: newArray,
        selectedPlansIds,
        numberOfpages,
        currentPage: currentPage,
        selectedPlansDisplay: paginateItems(newArray, currentPage),
      };
    }
    case DESELECT_ALL_ADD_ONS_SELECTED_PLAN: {
      let newArray = [...state.selectedPlansIds];
      if (state.companyPlans.length !== state.companyPlansFiltered.length) {
        state.companyPlansFiltered.forEach((i) => {
          const index = newArray.findIndex((item) => item === i.planId);
          if (index >= 0) {
            newArray.splice(index, 1);
          }
        });
        const index = newArray.findIndex((item) => item === action.payload);
        if (index >= 0) {
          newArray.splice(index, 1);
        } else {
          newArray.push(action.payload);
        }
      } else {
        newArray = new Array<number>();
      }
      return { ...state, selectedPlansIds: newArray };
    }
    case SELECT_ALL_ADD_ONS_SELECTED_PLAN: {
      const newArray = state.companyPlansFiltered.map((i) => i.planId);
      return { ...state, selectedPlansIds: newArray };
    }
    case SET_SELECTED_ADD_ONS_PLANS_PAGE: {
      return {
        ...state,
        currentPage: action.payload,
        selectedPlansDisplay: paginateItems(
          state.selectedPlans,
          action.payload
        ),
      };
    }
    case SET_SELECTED_COMPANY_PLANS_PAGE: {
      return {
        ...state,
        plansCurrentPage: action.payload,
        companyPlansDisplay: paginateItems(
          state.companyPlansFiltered,
          action.payload
        ),
      };
    }
    case SET_SEARCH_KEY_ADD_ONS_PLANS_PAGE: {
      const newArray = state.companyPlans.filter(
        (i) =>
          i.plan
            .toLocaleLowerCase()
            .includes(action.payload.toLocaleLowerCase()) ||
          i.product
            .toLocaleLowerCase()
            .includes(action.payload.toLocaleLowerCase())
      );
      return {
        ...state,
        companyPlansFiltered: newArray,
        plansNumberOfPages: calculateNumberOfPages(newArray.length),
        plansCurrentPage: 1,
        companyPlansDisplay: paginateItems(newArray, 1),
      };
    }
    case CANCEL_SELECTED_ADD_ONS_PLANS_PAGE: {
      const newArray = state.selectedPlans.map(i => i.planId);
      return { ...state, selectedPlansIds: newArray };
    }

    // in case of edit addon
    case FETCH_ADD_ON_SUCCESS: {
      const fetchType = action.fetchType as AddonsAllowancesScreen;
      const isAddonScreen = fetchType === AddonsAllowancesScreen.ADDONS;
      const addOn: GetAddonResponse | GetAllowanceResponse = action.response;
      const selectedPlans: Array<ISelectedCompanyPlans> = (addOn.getPlansList() as Array<AddOnPlan | AllowancePlan>)
        .map((plan: AddOnPlan | AllowancePlan) => ({
          product: plan.getProductName(),
          plan: plan.getPlanName(),
          planId: plan.getPlanId(),
          isForced: isAddonScreen ? (plan as AddOnPlan).getForceAddon() : (plan as AllowancePlan).getForceAllowance()
        }))
      sortItems(selectedPlans);
      return {
        ...state,
        selectedPlans,
        selectedPlansIds: selectedPlans.map(plan => plan.planId),
        selectedPlansDisplay: paginateItems(selectedPlans, 1)
      }
    }

    case RESET_CREATE_ADD_ON_STATE:
      return { ...initialState }
    default:
      return state;
  }
}
