import React, { ReactNode, useEffect } from "react";

import PageWrapper from "../../../components/ui/layout/PageWrapper";
import counterpart from "counterpart";
import TabbedContainer from "../../../components/ui/tabbed-container/TabbedContainer";
import { Link } from "react-router-dom";
import NavigationAlertCurrencyMismatch from "../../../components/ui/navigation-alert/NavigationAlertCurrencyMismatch";
import "./EmbedCodes.scss";
import history from "../../../utils/history";

interface IEmbedCodesProps {
  children: Array<ReactNode>
}

export const EmbedCodes = (props: IEmbedCodesProps) => {
  const { children } = props;

  useEffect(() => {
    if(history.location.pathname === "/embed-codes") {
      history.push("/embed-codes/checkout");
    }
  }, []);

  return (
    <div>
      <NavigationAlertCurrencyMismatch />
      <TabbedContainer>
        <Link
          className={`${window.location.pathname.includes("embed-codes/checkout") ? "active" : ""}`}
          to="/embed-codes/checkout">
          <li > {counterpart("SETTINGS_EMBED_CODES_CHECKOUT")}  </li>
        </Link>
        <Link
          className={`${window.location.pathname.includes("embed-codes/self-service") ? "active" : ""}`}
          to="/embed-codes/self-service">
          <li > {counterpart("SETTINGS_EMBED_CODES_SELF_SERVICE")}  </li>
        </Link>
        <Link
          className={`${window.location.pathname.includes("embed-codes/change-plan") ? "active" : ""}`}
          to="/embed-codes/change-plan">
          <li > {counterpart("SETTINGS_EMBED_CODES_CHANGE_PLAN")}  </li>
        </Link>
        <Link
          className={`${window.location.pathname.includes("embed-codes/add-on") ? "active" : ""}`}
          to="/embed-codes/add-on">
          <li > {counterpart("SETTINGS_EMBED_CODES_ADD_ON")}  </li>
        </Link>
        <Link
          className={`${window.location.pathname.includes("embed-codes/allowances") ? "active" : ""}`}
          to="/embed-codes/allowances">
          <li > {counterpart("SETTINGS_EMBED_CODES_ALLOWANCES")}  </li>
        </Link>
        <Link
          className={`${window.location.pathname.includes("embed-codes/card-tokenizer") ? "active" : ""}`}
          to="/embed-codes/card-tokenizer">
          <li > {counterpart("EMBED_CODES_CARD_TOKENIZER")}  </li>
        </Link>
        <div></div>
      </TabbedContainer>
      <PageWrapper>
        {children}
      </PageWrapper>
    </div>

  )
}

export default EmbedCodes;