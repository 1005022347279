import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Table, { TableArrowBtn } from "../../../components/ui/table/Table";
import Text from "../../../components/ui/text/Text";
import StatusLabel from "../../../components/ui/status-label/StatusLabel";
import { ICustomerInvoicePaymentLogData, PaymentMethodType } from "../../../models/Customer";
import { AppState } from "../../..";
import API from "../../../utils/API";
import { COLOR } from "../../../utils/constants";
import "./InvoicesPaymentsTable.scss";
import moment from "moment";
import { InvoicePaymentLogStatus } from "../../../models/Invoices";
import ButtonLoader from "../../../components/ui/button/ButtonLoader";
import Modal from "../../../components/modal/Modal";
import ViewTransactionLog from "../../../components/view-transaction-log/ViewTransactionLog";
import { LogExtended } from "../../../utils/grpc/generated/Billsby.Protos/billing/private/paymentlogs/payment_logs_pb";
import { PaymentSourceType } from "../../../utils/grpc/generated/Billsby.Protos/billing/private/paymentgateway/payment_gateway_pb";

interface IInvoicesPaymentsTableProps {
  invoiceNumber: string;
}

const InvoicesPaymentsTable: React.FC<IInvoicesPaymentsTableProps> = ({ invoiceNumber }) => {
  const currentCompanyDomain = useSelector((state: AppState) => state.auth.currentCompanyDomain);
  const customerUniqueId = useSelector((state: AppState) => state.customerDetailsReducer.customerUniqueId);

  const [paymentLogs, setPaymentLogs] = useState<Array<ICustomerInvoicePaymentLogData>>([]);
  const [showModal, setShowModal] = useState(false);
  const [selectedLog, setSelectedLog] = useState<Partial<LogExtended.AsObject>>({ reference: "", paymentSourceType: PaymentSourceType.NONE });
  const [isLoadingData, setIsLoadingData] = useState(true);
  const paymentsTableHeader = ["PAYMENTS_TABLE_DATE", "PAYMENTS_TABLE_GATEWAY_NAME", "PAYMENTS_TABLE_REFERENCE", "PAYMENTS_TABLE_PAYMENT_METHOD", "PAYMENTS_TABLE_STATUS"];

  useEffect(() => {
    let isSubscribed = true;

    const fetchCustomerInvoicesPayments = async () => {
      setIsLoadingData(true);
      try {
        if (customerUniqueId) {
          const data: any = await API.fetchCustomerInvoicesPayments(currentCompanyDomain, customerUniqueId, invoiceNumber);
          if (isSubscribed) {
            setIsLoadingData(false);
            setPaymentLogs(data);
          }
        }
      } catch (err) {
        if (isSubscribed) {
          setIsLoadingData(false);
        }
      }
    };

    fetchCustomerInvoicesPayments();

    return () => {
      isSubscribed = false;
      setPaymentLogs([]);
    };
  }, [customerUniqueId]);

  const getStatusColor = (status: InvoicePaymentLogStatus) => {
    switch (status) {
      case InvoicePaymentLogStatus.DECLINED:
        return COLOR.LIGHT_RED
      case InvoicePaymentLogStatus.FAILED:
        return COLOR.RED
      case InvoicePaymentLogStatus.PENDING:
        return COLOR.ORANGE
      case InvoicePaymentLogStatus.PENDINGACH:
        return COLOR.LIGHT_ORANGE
      default:
        return COLOR.GREEN
    }
  }

  const getPaymentSourceType = (type: PaymentMethodType) => {
    switch(type) {
      case PaymentMethodType.ACH:
        return PaymentSourceType.ACH
      case PaymentMethodType.CREDIT_CARD:
        return PaymentSourceType.CREDIT_CARD
      default:
        return PaymentSourceType.NONE
    }
  }

  const renderTableData = () => {
    return paymentLogs.map((data, idx) => (
      <tr className={data.status === InvoicePaymentLogStatus.PENDINGACH ? "disabled" : ""} key={idx}>
        <Text shouldTranslate={false} content={moment(data.createdOn).format("DD MMM YYYY")} component="td" noMargin />
        <Text shouldTranslate={false} content={data.gatewayName} component="td" noMargin />
        <Text shouldTranslate={false} content={data.reference} component="td" noMargin />
        <Text shouldTranslate={false} content={data.paymentMethod.charAt(0).toUpperCase() + data.paymentMethod.slice(1)} component="td" noMargin />
        <td>
          <StatusLabel color={getStatusColor(data.status)} content={`PAYMENTS_TABLE_${data.status.toLocaleUpperCase()}`} />
        </td>
        <TableArrowBtn
          onClick={() => {
            setShowModal(true);
            setSelectedLog({ reference: data.reference, paymentSourceType: getPaymentSourceType(data.paymentSourceType) });
          }}
        />
      </tr>
    ));
  };

  return (
    <div className="invoice-payments-wrapper">
      {isLoadingData ? (
        <ButtonLoader />
      ) : (
        <Table className="invoice-payments-table">
          <thead>
            <tr>
              {paymentsTableHeader.map(content => (
                <Text key={content} content={content} component="th" noMargin />
              ))}
              <Text content="" shouldTranslate={false} component="th" noMargin />
            </tr>
          </thead>
          <tbody>{renderTableData()}</tbody>
        </Table>
      )}
      <Modal
        title="VIEW_TRANSACTION_LOG_TITLE"
        isOpen={showModal}
        onRequestClose={() => {
          setShowModal(false);
          setSelectedLog({ reference: "", paymentSourceType: PaymentSourceType.NONE });
        }}>
        <ViewTransactionLog data={selectedLog} /> 
      </Modal>
    </div>
  );
};

export default InvoicesPaymentsTable;
