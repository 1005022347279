import React, { useState } from "react";
import { PricingModelType, AddonPriceModel, Addon, UnitTier, Allowance, AllowancePriceModel } from "../../../utils/grpc/generated/Billsby.Protos/billing/private/subscription/subscription_pb";
import Table from "../../ui/table/Table";
import Text from "../../ui/text/Text";
import { IPlanPricingModel } from "../../../models/Subscription";
import { PricingModelType as PriceModeType } from "../../../models/Product";
import Button from "../../ui/button/Button";
import { simulatePrice, getUnitsLabel, divideNumberBy100, toAddOnTierUnit } from "../../../utils/commonUtils";
import Tooltip from "../../ui/tooltip/Tooltip";
import { IAuthReducerState } from "../../../reducers/authReducer";
import { ISubscriptionDetailsReducerState } from "../../../reducers/subscriptionDetailsReducer";
import { deleteSubscriptionAddOns, setDeletedAddOn, fetchSubscriptionAddOns, fetchSubscriptionAllowances, setDeletedAllowance, deleteSubscriptionAllowances } from "../../../actions/subscriptionDetailsActions";
import moment from "moment";
import { Timestamp } from "google-protobuf/google/protobuf/timestamp_pb";
import { Currency } from "../../../utils/grpc/generated/Billsby.Protos/core/private/addons/addons_pb";
import Input from "../../ui/input/Input";
import { UpdateUsageRequest } from "../../../utils/grpc/generated/Billsby.Protos/core/private/allowances/allowances_pb";
import { AllowancesServiceClient } from "../../../utils/grpc/generated/Billsby.Protos/core/private/allowances/AllowancesServiceClientPb";
import { ConfigConstants } from "../../../utils/config";
import { grpcUnaryCall } from "../../../utils/grpc/grpcUtils";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../../..";
import { Permission } from "../../../models/Auth";
import useCheckFeaturePermission from "../../../utils/hooks/useCheckFeaturePermission";

interface ISubscriptionAddOnsTiers {
  addOnAllowance: Addon | Allowance,
  planPricingModel: IPlanPricingModel,
  callback: (id: number) => void
}

const SubscriptionAddOnsTiers: React.FC<ISubscriptionAddOnsTiers> = ({ addOnAllowance, planPricingModel, callback }) => {
  const dispatch = useDispatch<Function>();

  const { currentCompanyDomain, currentCompanyId, parsedToken, dashboardSettings } = useSelector<AppState, IAuthReducerState>(state => state.auth);
  const { subscriptionUniqueId, subscriptionId, nextBillingDate } = useSelector<AppState, ISubscriptionDetailsReducerState>(state => state.subscriptionDetailsReducer);
 
  const isSubscriptionEditAddonPermitted = useCheckFeaturePermission(Permission.SubscriptionEditAddon);
  const isSubscriptionDeleteAddonPermitted = useCheckFeaturePermission(Permission.SubscriptionDeleteAddon);
  const isSubscriptionUpdateAllowanceUsagePermitted = useCheckFeaturePermission(Permission.SubscriptionUpdateAllowanceUsage);
  const isSubscriptionDeleteAllowancePermitted = useCheckFeaturePermission(Permission.SubscriptionDeleteAllowance);

  const [expandedTable, setExpandedTable] = useState(false);
  const [isUpdatingAllowanceUsage, setIsUpdatingAllowanceUsage] = useState(false);
  const [allowanceUsage, setAllowanceUsage] = useState("");
  const isFlatFee = addOnAllowance.getPricingModelType() === PricingModelType.FLATFEE;
  const isCapped = addOnAllowance.getPricingModelType() === PricingModelType.CAPPED;
  const isPerUnit = addOnAllowance.getPricingModelType() === PricingModelType.PERUNIT;

  const isTeamMateAllowedToUpdateAllowanceUsage = dashboardSettings && dashboardSettings.isTeammateManualAllowanceUsageUpdateAllowed;

  const pricingModelTypes = [
    "",
    "SUBSCRIPTION_ADD_ONS_FLAT_FEE",
    "SUBSCRIPTION_ADD_ONS_PER_UNIT",
    "SUBSCRIPTION_ADD_ONS_CREDIT",
    "SUBSCRIPTION_ADD_ONS_TIERED",
    "SUBSCRIPTION_ADD_ONS_VOLUME",
    "SUBSCRIPTION_ADD_ONS_RANGED",
    "SUBSCRIPTION_ADD_ONS_CAPPED"
  ];

  const frequencyType = ["", "Daily", "Weekly", "Monthly", "Yearly"];

  const getPricingModel = (pricingModels: Array<AddonPriceModel | AllowancePriceModel>) => {
    const priceModel = (pricingModels as Array<AddonPriceModel | AllowancePriceModel>)
      .find(i => i.getFrequency() === planPricingModel.frequency && frequencyType[i.getFrequencyType()] === planPricingModel.frequencyType);
    return priceModel || null;
  }

  const getRevenue = (pricingModels: AddonPriceModel[], units: number, pricingModelType: PricingModelType) => {
    const priceModel = getPricingModel(pricingModels);
    if (priceModel) {
      //const currency = priceModel.getFlatFeePriceFormatted().substring(0, 1);
      const currency = (priceModel.getCurrency() || new Currency()).getSymbol();
      if (pricingModelType === PricingModelType.FLATFEE)
        return (priceModel as AddonPriceModel).getFlatFeePriceFormatted();
      else if (pricingModelType === PricingModelType.PERUNIT) {
        const perUnitPrice = (priceModel as AddonPriceModel).getPerUnitPrice();
        if (!!perUnitPrice)
          return currency.replace("_", `${divideNumberBy100(units * Number(perUnitPrice))}`)
      }
      else if (pricingModelType === PricingModelType.RANGED)
        return currency.replace("_", `${simulatePrice(units, priceModel.getTiersList().map(t => toAddOnTierUnit(t)), PriceModeType.Ranged)}`);
      else if (pricingModelType === PricingModelType.VOLUME)
        return currency.replace("_", `${simulatePrice(units, priceModel.getTiersList().map(t => toAddOnTierUnit(t)), PriceModeType.Volume)}`);
      else if (pricingModelType === PricingModelType.TIERED)
        return currency.replace("_", `${simulatePrice(units, priceModel.getTiersList().map(t => toAddOnTierUnit(t)), PriceModeType.Tiered)}`);
    }

    return "";
  }

  const updateAllowanceUsage = async (selectedAllowance: Allowance, newUsageQuantity: number) => {
    const allowanceServiceClient = new AllowancesServiceClient(ConfigConstants.grpcBaseUrl);
    try {
      const updateUsageRequest = new UpdateUsageRequest();
      updateUsageRequest.setCompanyDomain(currentCompanyDomain);
      updateUsageRequest.setAllowanceId(selectedAllowance.getId());
      updateUsageRequest.setSubscriptionUniqueId(subscriptionUniqueId);
      updateUsageRequest.setUsageQuantity(newUsageQuantity);
      await grpcUnaryCall(updateUsageRequest, allowanceServiceClient, allowanceServiceClient.updateUsage);
      await callback(null as any);
    }
    catch (err) { }
    finally {
      setIsUpdatingAllowanceUsage(false);
      setAllowanceUsage("");
    }
  }

  const getFinishText = (finish: number) => finish >= 999999 ? "+" : `-${finish}`;
  const getDateAddOn = (date: Timestamp | undefined) => date ? moment(date.toDate()).format("DD MMM YYYY") : "";

  const renderTiers = (tiers: Array<UnitTier>) => {
    return (
      <Table className="subscription-add-ons__inner-table">
        <thead>
          <tr>
            <Text component="th" content="SUBSCRIPTION_ADD_ONS_LIST_UNITS" noMargin />
            <Text component="th" content="SUBSCRIPTION_ADD_ONS_LIST_PRICE_PER_UNIT" noMargin />
          </tr>
        </thead>
        <tbody>
          {tiers.sort((a, b) => a.getStart() - b.getStart()).map((tier, idx) => {
            return (
              <tr key={idx}>
                <Text
                  component="td"
                  shouldTranslate={false}
                  content={`${tier.getStart()}${getFinishText(tier.getFinish())}`}
                  noMargin
                />
                <Text component="td" shouldTranslate={false} content={tier.getPriceFormatted()} noMargin />
              </tr>
            );
          })}
        </tbody>
      </Table>
    );
  };

  if (addOnAllowance instanceof Addon) {
    const addOn = addOnAllowance as Addon;
    const addOnPriceModel = getPricingModel(addOn.getAddOnPriceModelsList()) as AddonPriceModel;
    return (
      <>
        <tr className={expandedTable ? "cc-table--expandable__rowExpanded" : ""}>
          <Text content={addOn.getName()} shouldTranslate={false} component="td" noMargin />
          <Text content={getRevenue(addOn.getAddOnPriceModelsList(), addOn.getQuantity(), addOn.getPricingModelType())} shouldTranslate={false} component="td" noMargin />
          {/* Should only show the new unit quantity on the next billing date */}
          <Text content={isFlatFee ? "-" : `${addOn.getQuantity()}`} shouldTranslate={false} component="td" noMargin />
          <td
            className={`subscription-add-ons__table__collapsible${isFlatFee || isPerUnit ? " subscription-add-ons__table__collapsible--no-data" : ""}`}
            onClick={() => {
              if (!isFlatFee || !isPerUnit)
                setExpandedTable(!expandedTable)
            }
            }
          >
            <span className={`subscription-add-ons__table__collapsible-arrow${expandedTable ? " expanded" : ""}`} />
            {

              isPerUnit ? <div>
                <Text
                  className="subscription-add-ons__table__label"
                  content={getRevenue(addOn.getAddOnPriceModelsList(), 1, addOn.getPricingModelType())}
                  component="p"
                  shouldTranslate={false}
                  noMargin
                />
                <Text
                  className="subscription-add-ons__table__sub-label"
                  content={pricingModelTypes[addOn.getPricingModelType()]}
                  component="span"
                  noMargin
                />
              </div> : <Text
                  className="subscription-add-ons__table__collapsible-label"
                  content={pricingModelTypes[addOn.getPricingModelType()]}
                  component="span"
                  noMargin
                />
            }
          </td>
          <td>{getDateAddOn(addOn.getAddedOn())}</td>
          <td>
            {!addOn.getWillBeDeleted() &&
              <Button
                id="edit-add-on"
                icon="fas fa-edit left"
                onClick={() => !isFlatFee && callback && callback(addOn.getId())}
                buttonType="add"
                disabled={isFlatFee || !isSubscriptionEditAddonPermitted}
                tooltipMessage={!isSubscriptionEditAddonPermitted ? "NO_ACCESS_MSG" : undefined}
                title="ADD_ONS_LIST_TABLE_BTN_EDIT"
              />
            }
            <div data-tip data-for={`add-on-delete-tooltip-${addOn.getId()}`} className="button-holder">
              <Button
                id="delete-add-on"
                icon={addOn.getIsForced() ? "fas fa-lock" : "far fa-trash-alt"}
                disabled={addOn.getWillBeDeleted() || addOn.getIsForced() || !isSubscriptionDeleteAddonPermitted}
                tooltipMessage={!isSubscriptionDeleteAddonPermitted ? "NO_ACCESS_MSG" : undefined}
                buttonType="error"
                title="ADD_ONS_LIST_TABLE_BTN_DELETE"
                onClick={() => {
                  dispatch(deleteSubscriptionAddOns(currentCompanyId as number, subscriptionId as number, addOn.getId(), parsedToken ? Number(parsedToken.nameid) : 0))
                    .then(() => {
                      dispatch(setDeletedAddOn(addOn));
                      dispatch(fetchSubscriptionAddOns(currentCompanyId as number, subscriptionId as number));
                    })
                }}
              />
            </div>
            {(addOn.getWillBeDeleted() || addOn.getIsForced()) &&
              <Tooltip id={`add-on-delete-tooltip-${addOn.getId()}`} place='top' type='blue'>
                {
                  addOn.getIsForced()
                    ? <Text content='SUBSCRIPTION_ADD_ONS_FORCE_TOOLTIP' noMargin />
                    :
                    <Text
                      content='SUBSCRIPTION_ADD_ONS_DELETED_TOOLTIP'
                      translateWith={{ nextBillingDate: moment(nextBillingDate).format("DD MMM YYYY") }}
                      noMargin
                    />
                }
              </Tooltip>
            }
          </td>
        </tr>
        {expandedTable && (
          <tr className="expanded">
            <td>
              <div className="subscription-add-ons__expanded-wrapper">{renderTiers(addOnPriceModel.getTiersList())}</div>
            </td>
          </tr>
        )}
      </>
    )
  }

  // if is an allowance instead
  const allowance = addOnAllowance as Allowance;
  const allowancePriceModel = getPricingModel(allowance.getAllowancePriceModelsList()) as AllowancePriceModel;
  return (
    <>
      <tr className={expandedTable ? "cc-table--expandable__rowExpanded" : ""}>
        <Text content={allowance.getName()} shouldTranslate={false} component="td" noMargin />
        <Text
          content={`${Number(allowancePriceModel.getIncludedUnits()) || "-"}`}
          shouldTranslate={false}
          component="td"
          noMargin
        />
        <td
          className={`subscription-add-ons__table__collapsible${(isPerUnit || isCapped) ? " subscription-add-ons__table__collapsible--no-data" : ""}`}
          onClick={() => {
            if (!isPerUnit && !isCapped)
              setExpandedTable(!expandedTable)
          }}
        >
          {
            isPerUnit || isCapped
              ? (
                <div className=" subscription-add-ons__table__overage-perunit">
                  {isPerUnit &&
                    <Text
                      content={allowancePriceModel.getPerUnitPriceFormatted()}
                      component="div"
                      shouldTranslate={false}
                      noMargin
                    />
                  }
                  <Text
                    className="subscription-add-ons__table__collapsible-label"
                    content={pricingModelTypes[allowance.getPricingModelType()]}
                    component="span"
                    noMargin
                  />
                </div>
              )
              : (
                <>
                  <span className={`subscription-add-ons__table__collapsible-arrow${expandedTable ? " expanded" : ""}`} />
                  <Text
                    className="subscription-add-ons__table__collapsible-label"
                    content={pricingModelTypes[allowance.getPricingModelType()]}
                    component="span"
                    noMargin
                  />
                </>
              )
          }
        </td>
        <td>{getDateAddOn(allowance.getAddedOn())}</td>
        <td>
          {
            isUpdatingAllowanceUsage
              ? (
                <Input
                  id="update-allowance-usage"
                  value={allowanceUsage}
                  onChange={(evt) => evt.target.value === "" ? setAllowanceUsage(evt.target.value) : Number.isInteger(+evt.target.value) && setAllowanceUsage(evt.target.value)}
                />
              )
              : allowance.getQuantity()
          }
        </td>
        <td>
          {
            isUpdatingAllowanceUsage
              ? (
                <>
                  <div data-tip data-for={`allowance-update-usage-tooltip-${allowance.getId()}`}>
                    <Button
                      id="update-usage-save-changes"
                      onClick={() => updateAllowanceUsage(allowance, +allowanceUsage)}
                      buttonType="add"
                      disabled={(isCapped && +allowanceUsage > Number(allowancePriceModel.getIncludedUnits())) || !allowanceUsage}
                      title="ALLOWANCES_LIST_TABLE_BTN_UPDATE_USAGE_SAVE"
                    />
                  </div>
                  <Button
                    id="cancel-update-usage"
                    icon="far fa-trash-alt"
                    buttonType="error"                    
                    title="ALLOWANCES_LIST_TABLE_BTN_UPDATE_USAGE_CANCEL"
                    onClick={() => setIsUpdatingAllowanceUsage(false)}
                  />
                  {isCapped && +allowanceUsage > Number(allowancePriceModel.getIncludedUnits()) && (
                    <Tooltip id={`allowance-update-usage-tooltip-${allowance.getId()}`} place='top' type='blue'>
                      <Text
                        content='SUBSCRIPTION_ALLOWANCES_USAGE_CAPPED_TOOLTIP'
                        translateWith={{
                          units: Number(allowancePriceModel.getIncludedUnits()),
                          unitsLabel: getUnitsLabel(Number(allowancePriceModel.getIncludedUnits()))
                        }}
                        noMargin
                      />
                    </Tooltip>
                  )}
                </>
              )
              : (
                <>
                  {isTeamMateAllowedToUpdateAllowanceUsage && <Button
                    id="update-usage-allowance"
                    icon="fas fa-edit left"
                    onClick={() => { setIsUpdatingAllowanceUsage(true); setAllowanceUsage(allowance.getQuantity() + "")}}
                    buttonType="add"
                    disabled={!isSubscriptionUpdateAllowanceUsagePermitted}
                    tooltipMessage={!isSubscriptionUpdateAllowanceUsagePermitted ? "NO_ACCESS_MSG" : undefined}
                    title="ALLOWANCES_LIST_TABLE_BTN_UPDATE_USAGE"
                  />}
                  <div data-tip data-for={`allowance-delete-tooltip-${allowance.getId()}`} className="button-holder">
                    <Button
                      id="delete-allowance"
                      icon={allowance.getIsForced() ? "fas fa-lock" : "far fa-trash-alt"}
                      disabled={allowance.getWillBeDeleted() || allowance.getIsForced() || !isSubscriptionDeleteAllowancePermitted}
                      tooltipMessage={!isSubscriptionDeleteAllowancePermitted ? "NO_ACCESS_MSG" : undefined}
                      buttonType="error"
                      title="ALLOWANCE_LIST_TABLE_BTN_DELETE"
                      onClick={() => {
                        dispatch(deleteSubscriptionAllowances(currentCompanyId as number, subscriptionId as number, allowance.getId(), parsedToken ? Number(parsedToken.nameid) : 0))
                          .then(() => {
                            dispatch(setDeletedAllowance(allowance));
                            dispatch(fetchSubscriptionAllowances(currentCompanyId as number, subscriptionId as number));
                          })
                      }}
                    />
                  </div>
                  {(allowance.getWillBeDeleted() || allowance.getIsForced()) &&
                    <Tooltip id={`allowance-delete-tooltip-${allowance.getId()}`} place='top' type='blue'>
                      {
                        allowance.getIsForced()
                          ? <Text content='SUBSCRIPTION_ALLOWANCES_FORCE_TOOLTIP' noMargin />
                          :
                          <Text
                            content='SUBSCRIPTION_ALLOWANCES_DELETED_TOOLTIP'
                            translateWith={{ nextBillingDate: moment(nextBillingDate).format("DD MMM YYYY") }}
                            noMargin
                          />
                      }
                    </Tooltip>
                  }
                </>
              )
          }
        </td>
      </tr>
      {expandedTable && (
        <tr className="expanded">
          <td>
            <div className="subscription-add-ons__expanded-wrapper">{renderTiers(allowancePriceModel.getTiersList())}</div>
          </td>
        </tr>
      )}
    </>
  )


}

export default SubscriptionAddOnsTiers
