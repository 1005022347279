import React, { useState } from "react";
import Panel from "../ui/panel/Panel";
import Text from "../ui/text/Text";
import Button from "../ui/button/Button";
import RangeSlider from "../ui/range-slider/RangeSlider";
import { QUIZ_FIELD_SUBSCRIBER_BASE_TYPE } from "../../utils/constants";

interface IQuizStep6 {
  onContinue: (nextStep: number, fieldType: string, fieldValue: string) => any
}


const QuizStep6: React.FC<IQuizStep6> = ({ onContinue }) => {
  const [value, setValue] = useState(5000);
  return (
    <div className="quiz quiz-step6">
      <Panel title="QUIZ_STEP6_TITLE">
        <Text content="QUIZ_STEP6_SUBTITLE" />
        <RangeSlider
          //ref={slider}
          className="quiz-step6__slider"
          min={0}
          max={220000}
          defaultValue={value}
          value={value}
          marks={{ 5000: 5000, 20000: 20000, 50000: 50000, 200000: 200000 }}
          step={1}
          onChange={(value: any) => { setValue(value)}}
          tipFormatter={(value: number) => value !== 220000 ? `${value}` : `${value}+`}
        />
        <Button id="quiz-step6-btn" title="QUIZ_STEP6_BTN" isFullWidth onClick={() => onContinue(7, QUIZ_FIELD_SUBSCRIBER_BASE_TYPE, value + "")} />
        <Text content="QUIZ_STEP6_LINK" className="quiz__link" noMargin onClick={() => onContinue(7, QUIZ_FIELD_SUBSCRIBER_BASE_TYPE, "Ignore")} />
      </Panel>
    </div>
  )
}

export default QuizStep6;