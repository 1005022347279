import API from "../utils/API";
import { makeActionCreator, makeGenericActionCreatorTypeSafe } from "../utils/reduxUtils";
import { PAGINATION_PAGE_SIZE } from "../utils/constants";
import { ICreditNotesReducerState } from "../reducers/creditNotesReducer";
import { CreditNoteStatus } from "../models/CreditNotes";

export const FETCH_CREDIT_NOTES_REQUEST = "FETCH_CREDIT_NOTES_REQUEST";
export const FETCH_CREDIT_NOTES_SUCCESS= "FETCH_CREDIT_NOTES_SUCCESS";
export const FETCH_CREDIT_NOTES_FAILURE = "FETCH_CREDIT_NOTES_FAILURE";

export const SET_CREDIT_NOTES_FIELD = "SET_CREDIT_NOTES_FIELD";
export const setCreditNotesField = makeGenericActionCreatorTypeSafe<ICreditNotesReducerState>(SET_CREDIT_NOTES_FIELD);


export const RESET_CREDIT_NOTES = "RESET_CREDIT_NOTES";
export const resetCreditNotes = makeActionCreator(RESET_CREDIT_NOTES, "payload");

export const fetchCreditNotes = function (companyDomain: string, pageNumber: number, pageSize: number = PAGINATION_PAGE_SIZE, search: string = "", creditNoteStatus?: CreditNoteStatus,  isDateSortDescendingOrder: boolean = true) {
  return {
    types: [FETCH_CREDIT_NOTES_REQUEST, FETCH_CREDIT_NOTES_SUCCESS, FETCH_CREDIT_NOTES_FAILURE],
    callAPI: () => API.fetchCreditNotes(companyDomain, pageNumber, pageSize, search, creditNoteStatus, isDateSortDescendingOrder)
  }
}