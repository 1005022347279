export interface IConfig {
  reactAppEnvironment: "Localhost" | "Development" | "Test" | "Staging" | "Production",
  baseUrl: string,
  grpcBaseUrl: string,
  checkoutLibUrl: string,
  clientId: string,
  instrumentationKey: string,
  intercomKey: string,
  billsbyDomain: string,
  billsbyGoogleAnalyticsId: string,
  billsbyGtagContainerId: string,
  hotjarNotLogged: string,
  hotjarLogged: string,
  stripeClientId: string,
  spreedlyKey: string,
  freeAgentClientId: string,
  quickBooksClientId: string,
  googleFontApiKey: string,
  quickBooksUrl: string,
  freeAgentUrl: string,
  webexUrl: string,
  webexClientId: string,
  webexUrlRedirect: string
 }

export let ConfigConstants: IConfig = (window as any).ConfigConstants || {};

