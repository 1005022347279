import React from "react";
import { COLOR } from "../../../utils/constants";
import Translate from "react-translate-component";
import "./StatusLabel.scss";

interface IStatusLabel {
  content: string | JSX.Element,
  color?: COLOR,
  shouldTranslate?: boolean,
  icon?: string,
  iconPosition?: "left" | "right",
  className?: string
}

const StatusLabel: React.FC<IStatusLabel> = React.memo(({ content, color = COLOR.GREEN, shouldTranslate = true, icon, iconPosition = "left", className }) => {

  if (!shouldTranslate) {
    return (
      <div className={`status-label status-label--${color} ${className ? className : ""}`}>
        {icon && iconPosition === "left" ? <i className={`${icon} left`}></i> : ""}
        {content}
        {icon && iconPosition === "right" ? <i className={`${icon} right`}></i> : ""}
      </div>
    )
  }
  return (
    <div className={`status-label status-label--${color} ${className ? className : ""}`} >
      {icon && iconPosition === "left" ? <i className={`${icon} left`}></i> : ""}
      <Translate content={content} component="span" />
      {icon && iconPosition === "right" ? <i className={`${icon} right`}></i> : ""}
    </div>
  )
})

export default StatusLabel;