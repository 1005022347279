import { useEffect, useState } from "react"
import { addScriptDynamically } from "../commonUtils"
import { useSelector } from "react-redux"
import { AppState } from "../.."
import { ConfigConstants } from "../config"

const useG2Prospect = () => {
  const { currentCompanyGoogleAnalyticsUID } = useSelector((state: AppState) => state.auth)
  const [isAddedScript, setIsAddedScript] = useState(false);
  const isProduction = ConfigConstants.reactAppEnvironment === "Production";

  useEffect(() => {
    if(!currentCompanyGoogleAnalyticsUID || isAddedScript || !isProduction) {
      return;
    }

    setIsAddedScript(true);

    addScriptDynamically({
      scriptAsText: `
      (function (c, p, d, u, id, i) {
        id = "${currentCompanyGoogleAnalyticsUID}"; // Optional Custom ID for user in your system
        u = 'https://tracking.g2crowd.com/attribution_tracking/conversions/' + c + '.js?p=' + encodeURI(p) + '&e=' + id;
        i = document.createElement('script');
        i.type = 'application/javascript';
        i.async = true;
        i.src = u;
        d.getElementsByTagName('head')[0].appendChild(i);
      }("3030", document.location.href, document));
      `
    })
  }, [currentCompanyGoogleAnalyticsUID])

  return null
}

export default useG2Prospect