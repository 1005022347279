import React from "react";
import { Row, Col } from "react-grid-system";
import FormLabel from "../form-label/FormLabel";
import Input from "../input/Input";
import counterpart from "counterpart";
import FormGroup from "../form-group/FormGroup";
import Text from "../text/Text";
import "./Password.scss";

interface IPassword {
  password: string,
  passwordConfirmation: string,
  onChangePassword: (psw: string) => void,
  onChangeConfirmPassword: (confirmPsw: string) => void,
  passwordIsValidCallback?: (isValid: boolean) => void,
  passwordLabel?: string 
}

const hasPasswordMoreThan8Char = (psw: string) => {
  return psw.length >= 8;
}

const hasPasswordOneNumber = (psw: string) => {
  return psw.match(/.*[0-9].*/);
}

const hasPasswordOneSpecial = (psw: string) => {
  return psw.match(/[^a-zA-Z0-9]+/);
}

/*
VALID SPECIAL CHARS
'!', '"', '#', '$', '%', '&', '\'', '(', ')', '*', '+',
',' , '-', '.', '/', ':' ,';', '<', '=', '>', '?', '@', '[',
'\\', ']', '^', '_', '`', '{', '|', '}', '~' 
*/
const hasPasswordOneSupportedSpecial = (psw: string) => {
  const specialCharsSupportedBackend = /[!"#$%&\\()*+,-./:;<=>?@\[\]^_`{|}~]+/;
  return psw.match(specialCharsSupportedBackend);
}

const checkPasswordIsValid = (password: string, passwordConfirmation: string, passwordIsValidCallback: (isValid: boolean) => void) => {
  if (
    password === passwordConfirmation &&
    hasPasswordMoreThan8Char(password) &&
    hasPasswordOneNumber(password) &&
    hasPasswordOneSupportedSpecial(password)
  ) { 
    return passwordIsValidCallback(true)  
  }
  
  return passwordIsValidCallback(false)  
}

const Password: React.FC<IPassword> = React.memo((props) => {
  const { onChangePassword, onChangeConfirmPassword, password, passwordConfirmation, passwordIsValidCallback = () => {}, 
    passwordLabel = "REGISTRATION_FORM_PASSWORD" } = props;

  return (
    <div className="password-check">
      <FormGroup>
        <Row align="center">
          <Col md={2}>
            <FormLabel target="password" content={passwordLabel} noMargin />
          </Col>
          <Col md={5}>
            <Input 
              required 
              id="password" 
              type="password" 
              placeholder="**********" 
              value={password} 
              onChange={evt => { 
                onChangePassword(evt.target.value)
                checkPasswordIsValid(evt.target.value, passwordConfirmation, passwordIsValidCallback)
              }}
            />
          </Col>
          <Col md={5}>
            <Input 
              required 
              id="confirm-password" 
              type="password" 
              placeholder={counterpart("REGISTRATION_FORM_CONFIRM_PASSWORD_PLACEHOLDER")} 
              value={passwordConfirmation} 
              onChange={evt => {
                onChangeConfirmPassword(evt.target.value)
                checkPasswordIsValid(password, evt.target.value, passwordIsValidCallback)
              }} 
            />
          </Col>
        </Row>
      </FormGroup>

      {
        (password || passwordConfirmation)
          ? (
            <FormGroup>
              <Row align="center">
                <Col md={2} />
                <Col md={5}>
                  <Row align="center" className="registration__passwordRequirements">
                    <Col md={12}>
                      <i className={`far ${password === passwordConfirmation ? "fa-check-circle" : "fa-times-circle"}`}></i>
                      <Text content="REGISTRATION_PASSWORD_SHOULD_MATCH" noMargin />
                    </Col>
                    <Col md={12}>
                      <i className={`far ${hasPasswordMoreThan8Char(password) ? "fa-check-circle" : "fa-times-circle"}`}></i>
                      <Text content="REGISTRATION_PASSWORD_AT_LEAST_8_CHAR" noMargin />
                    </Col>
                    <Col md={12}>
                      <i className={`far ${hasPasswordOneNumber(password) ? "fa-check-circle" : "fa-times-circle"}`}></i>
                      <Text content="REGISTRATION_PASSWORD_AT_LEAST_1_NUMBER" noMargin />
                    </Col>
                    <Col md={12}>
                      <i className={`far ${hasPasswordOneSupportedSpecial(password) ? "fa-check-circle" : "fa-times-circle"}`}></i>
                      <Text content="REGISTRATION_PASSWORD_AT_LEAST_1_SPECIAL" noMargin />
                    </Col>
                  </Row>
                </Col>
              </Row>
            </FormGroup>
          )
          : null
      }
    </div>
  )
})

export default Password;