export interface ISubscription {
  createdOn: Date,
  customerEmail: string,
  customerFullname: string,
  customerId: number,
  planId: number,
  planName: string,
  productId: number,
  productName: string,
  status: SubscriptionStatusType,
  subscriptionId: number,
  subscriptionUniqueId: string
}

export enum SubscriptionStatusType {
  ACTIVE = "Active",
  CANCELLED = "Cancelled",
  SUSPENDED = "Suspended",
  PAUSED = "Paused",
  INTRIAL = "InTrial",
}


export enum PlanChangeType {
  IMMEDIATE = 1,
  NEXT_BILLING = 2
}

export interface ISubscriptionsPaginated {
  currentPage: number,
  pageCount: number,
  pageSize: number,
  rowCount: number,
  results?: Array<ISubscription>
}