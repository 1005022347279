/* eslint-disable */
// source: company_config.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = Function('return this')();

goog.exportSymbol('proto.billing.private.companyconfig.GetAchPaymentConfigRequest', null, global);
goog.exportSymbol('proto.billing.private.companyconfig.GetAchPaymentConfigResponse', null, global);
goog.exportSymbol('proto.billing.private.companyconfig.GetInvoiceAddressConfigRequest', null, global);
goog.exportSymbol('proto.billing.private.companyconfig.GetInvoiceAddressConfigResponse', null, global);
goog.exportSymbol('proto.billing.private.companyconfig.GetInvoiceConfigRequest', null, global);
goog.exportSymbol('proto.billing.private.companyconfig.GetInvoiceConfigResponse', null, global);
goog.exportSymbol('proto.billing.private.companyconfig.UpdateAchPaymentConfigRequest', null, global);
goog.exportSymbol('proto.billing.private.companyconfig.UpdateAchPaymentConfigResponse', null, global);
goog.exportSymbol('proto.billing.private.companyconfig.UpsertInvoiceAddressConfigRequest', null, global);
goog.exportSymbol('proto.billing.private.companyconfig.UpsertInvoiceConfigRequest', null, global);
goog.exportSymbol('proto.billing.private.companyconfig.UpsertInvoiceConfigResponse', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.billing.private.companyconfig.UpsertInvoiceAddressConfigRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.billing.private.companyconfig.UpsertInvoiceAddressConfigRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.billing.private.companyconfig.UpsertInvoiceAddressConfigRequest.displayName = 'proto.billing.private.companyconfig.UpsertInvoiceAddressConfigRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.billing.private.companyconfig.GetInvoiceAddressConfigRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.billing.private.companyconfig.GetInvoiceAddressConfigRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.billing.private.companyconfig.GetInvoiceAddressConfigRequest.displayName = 'proto.billing.private.companyconfig.GetInvoiceAddressConfigRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.billing.private.companyconfig.GetInvoiceAddressConfigResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.billing.private.companyconfig.GetInvoiceAddressConfigResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.billing.private.companyconfig.GetInvoiceAddressConfigResponse.displayName = 'proto.billing.private.companyconfig.GetInvoiceAddressConfigResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.billing.private.companyconfig.UpsertInvoiceConfigRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.billing.private.companyconfig.UpsertInvoiceConfigRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.billing.private.companyconfig.UpsertInvoiceConfigRequest.displayName = 'proto.billing.private.companyconfig.UpsertInvoiceConfigRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.billing.private.companyconfig.UpsertInvoiceConfigResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.billing.private.companyconfig.UpsertInvoiceConfigResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.billing.private.companyconfig.UpsertInvoiceConfigResponse.displayName = 'proto.billing.private.companyconfig.UpsertInvoiceConfigResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.billing.private.companyconfig.GetInvoiceConfigRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.billing.private.companyconfig.GetInvoiceConfigRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.billing.private.companyconfig.GetInvoiceConfigRequest.displayName = 'proto.billing.private.companyconfig.GetInvoiceConfigRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.billing.private.companyconfig.GetInvoiceConfigResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.billing.private.companyconfig.GetInvoiceConfigResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.billing.private.companyconfig.GetInvoiceConfigResponse.displayName = 'proto.billing.private.companyconfig.GetInvoiceConfigResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.billing.private.companyconfig.GetAchPaymentConfigRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.billing.private.companyconfig.GetAchPaymentConfigRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.billing.private.companyconfig.GetAchPaymentConfigRequest.displayName = 'proto.billing.private.companyconfig.GetAchPaymentConfigRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.billing.private.companyconfig.GetAchPaymentConfigResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.billing.private.companyconfig.GetAchPaymentConfigResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.billing.private.companyconfig.GetAchPaymentConfigResponse.displayName = 'proto.billing.private.companyconfig.GetAchPaymentConfigResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.billing.private.companyconfig.UpdateAchPaymentConfigRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.billing.private.companyconfig.UpdateAchPaymentConfigRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.billing.private.companyconfig.UpdateAchPaymentConfigRequest.displayName = 'proto.billing.private.companyconfig.UpdateAchPaymentConfigRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.billing.private.companyconfig.UpdateAchPaymentConfigResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.billing.private.companyconfig.UpdateAchPaymentConfigResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.billing.private.companyconfig.UpdateAchPaymentConfigResponse.displayName = 'proto.billing.private.companyconfig.UpdateAchPaymentConfigResponse';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.billing.private.companyconfig.UpsertInvoiceAddressConfigRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.billing.private.companyconfig.UpsertInvoiceAddressConfigRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.billing.private.companyconfig.UpsertInvoiceAddressConfigRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing.private.companyconfig.UpsertInvoiceAddressConfigRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    companyId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    companyName: jspb.Message.getFieldWithDefault(msg, 2, ""),
    addressLine1: jspb.Message.getFieldWithDefault(msg, 3, ""),
    addressLine2: jspb.Message.getFieldWithDefault(msg, 4, ""),
    city: jspb.Message.getFieldWithDefault(msg, 5, ""),
    state: jspb.Message.getFieldWithDefault(msg, 6, ""),
    countryIso3: jspb.Message.getFieldWithDefault(msg, 7, ""),
    postCode: jspb.Message.getFieldWithDefault(msg, 8, ""),
    emailAddress: jspb.Message.getFieldWithDefault(msg, 9, ""),
    phoneNumberDialCountry: jspb.Message.getFieldWithDefault(msg, 10, ""),
    phoneNumberDialCode: jspb.Message.getFieldWithDefault(msg, 11, ""),
    phoneNumber: jspb.Message.getFieldWithDefault(msg, 12, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.billing.private.companyconfig.UpsertInvoiceAddressConfigRequest}
 */
proto.billing.private.companyconfig.UpsertInvoiceAddressConfigRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.billing.private.companyconfig.UpsertInvoiceAddressConfigRequest;
  return proto.billing.private.companyconfig.UpsertInvoiceAddressConfigRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.billing.private.companyconfig.UpsertInvoiceAddressConfigRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.billing.private.companyconfig.UpsertInvoiceAddressConfigRequest}
 */
proto.billing.private.companyconfig.UpsertInvoiceAddressConfigRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setCompanyId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setCompanyName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setAddressLine1(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setAddressLine2(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setCity(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setState(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setCountryIso3(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setPostCode(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setEmailAddress(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setPhoneNumberDialCountry(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.setPhoneNumberDialCode(value);
      break;
    case 12:
      var value = /** @type {string} */ (reader.readString());
      msg.setPhoneNumber(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.billing.private.companyconfig.UpsertInvoiceAddressConfigRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.billing.private.companyconfig.UpsertInvoiceAddressConfigRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.billing.private.companyconfig.UpsertInvoiceAddressConfigRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing.private.companyconfig.UpsertInvoiceAddressConfigRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCompanyId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getCompanyName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getAddressLine1();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getAddressLine2();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getCity();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getState();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getCountryIso3();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getPostCode();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getEmailAddress();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getPhoneNumberDialCountry();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getPhoneNumberDialCode();
  if (f.length > 0) {
    writer.writeString(
      11,
      f
    );
  }
  f = message.getPhoneNumber();
  if (f.length > 0) {
    writer.writeString(
      12,
      f
    );
  }
};


/**
 * optional int32 company_id = 1;
 * @return {number}
 */
proto.billing.private.companyconfig.UpsertInvoiceAddressConfigRequest.prototype.getCompanyId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.billing.private.companyconfig.UpsertInvoiceAddressConfigRequest} returns this
 */
proto.billing.private.companyconfig.UpsertInvoiceAddressConfigRequest.prototype.setCompanyId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string company_name = 2;
 * @return {string}
 */
proto.billing.private.companyconfig.UpsertInvoiceAddressConfigRequest.prototype.getCompanyName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.billing.private.companyconfig.UpsertInvoiceAddressConfigRequest} returns this
 */
proto.billing.private.companyconfig.UpsertInvoiceAddressConfigRequest.prototype.setCompanyName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string address_line_1 = 3;
 * @return {string}
 */
proto.billing.private.companyconfig.UpsertInvoiceAddressConfigRequest.prototype.getAddressLine1 = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.billing.private.companyconfig.UpsertInvoiceAddressConfigRequest} returns this
 */
proto.billing.private.companyconfig.UpsertInvoiceAddressConfigRequest.prototype.setAddressLine1 = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string address_line_2 = 4;
 * @return {string}
 */
proto.billing.private.companyconfig.UpsertInvoiceAddressConfigRequest.prototype.getAddressLine2 = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.billing.private.companyconfig.UpsertInvoiceAddressConfigRequest} returns this
 */
proto.billing.private.companyconfig.UpsertInvoiceAddressConfigRequest.prototype.setAddressLine2 = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string city = 5;
 * @return {string}
 */
proto.billing.private.companyconfig.UpsertInvoiceAddressConfigRequest.prototype.getCity = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.billing.private.companyconfig.UpsertInvoiceAddressConfigRequest} returns this
 */
proto.billing.private.companyconfig.UpsertInvoiceAddressConfigRequest.prototype.setCity = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string state = 6;
 * @return {string}
 */
proto.billing.private.companyconfig.UpsertInvoiceAddressConfigRequest.prototype.getState = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.billing.private.companyconfig.UpsertInvoiceAddressConfigRequest} returns this
 */
proto.billing.private.companyconfig.UpsertInvoiceAddressConfigRequest.prototype.setState = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string country_iso_3 = 7;
 * @return {string}
 */
proto.billing.private.companyconfig.UpsertInvoiceAddressConfigRequest.prototype.getCountryIso3 = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.billing.private.companyconfig.UpsertInvoiceAddressConfigRequest} returns this
 */
proto.billing.private.companyconfig.UpsertInvoiceAddressConfigRequest.prototype.setCountryIso3 = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string post_code = 8;
 * @return {string}
 */
proto.billing.private.companyconfig.UpsertInvoiceAddressConfigRequest.prototype.getPostCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.billing.private.companyconfig.UpsertInvoiceAddressConfigRequest} returns this
 */
proto.billing.private.companyconfig.UpsertInvoiceAddressConfigRequest.prototype.setPostCode = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional string email_address = 9;
 * @return {string}
 */
proto.billing.private.companyconfig.UpsertInvoiceAddressConfigRequest.prototype.getEmailAddress = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.billing.private.companyconfig.UpsertInvoiceAddressConfigRequest} returns this
 */
proto.billing.private.companyconfig.UpsertInvoiceAddressConfigRequest.prototype.setEmailAddress = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional string phone_number_dial_country = 10;
 * @return {string}
 */
proto.billing.private.companyconfig.UpsertInvoiceAddressConfigRequest.prototype.getPhoneNumberDialCountry = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.billing.private.companyconfig.UpsertInvoiceAddressConfigRequest} returns this
 */
proto.billing.private.companyconfig.UpsertInvoiceAddressConfigRequest.prototype.setPhoneNumberDialCountry = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * optional string phone_number_dial_code = 11;
 * @return {string}
 */
proto.billing.private.companyconfig.UpsertInvoiceAddressConfigRequest.prototype.getPhoneNumberDialCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};


/**
 * @param {string} value
 * @return {!proto.billing.private.companyconfig.UpsertInvoiceAddressConfigRequest} returns this
 */
proto.billing.private.companyconfig.UpsertInvoiceAddressConfigRequest.prototype.setPhoneNumberDialCode = function(value) {
  return jspb.Message.setProto3StringField(this, 11, value);
};


/**
 * optional string phone_number = 12;
 * @return {string}
 */
proto.billing.private.companyconfig.UpsertInvoiceAddressConfigRequest.prototype.getPhoneNumber = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 12, ""));
};


/**
 * @param {string} value
 * @return {!proto.billing.private.companyconfig.UpsertInvoiceAddressConfigRequest} returns this
 */
proto.billing.private.companyconfig.UpsertInvoiceAddressConfigRequest.prototype.setPhoneNumber = function(value) {
  return jspb.Message.setProto3StringField(this, 12, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.billing.private.companyconfig.GetInvoiceAddressConfigRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.billing.private.companyconfig.GetInvoiceAddressConfigRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.billing.private.companyconfig.GetInvoiceAddressConfigRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing.private.companyconfig.GetInvoiceAddressConfigRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    companyId: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.billing.private.companyconfig.GetInvoiceAddressConfigRequest}
 */
proto.billing.private.companyconfig.GetInvoiceAddressConfigRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.billing.private.companyconfig.GetInvoiceAddressConfigRequest;
  return proto.billing.private.companyconfig.GetInvoiceAddressConfigRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.billing.private.companyconfig.GetInvoiceAddressConfigRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.billing.private.companyconfig.GetInvoiceAddressConfigRequest}
 */
proto.billing.private.companyconfig.GetInvoiceAddressConfigRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setCompanyId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.billing.private.companyconfig.GetInvoiceAddressConfigRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.billing.private.companyconfig.GetInvoiceAddressConfigRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.billing.private.companyconfig.GetInvoiceAddressConfigRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing.private.companyconfig.GetInvoiceAddressConfigRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCompanyId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
};


/**
 * optional int32 company_id = 1;
 * @return {number}
 */
proto.billing.private.companyconfig.GetInvoiceAddressConfigRequest.prototype.getCompanyId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.billing.private.companyconfig.GetInvoiceAddressConfigRequest} returns this
 */
proto.billing.private.companyconfig.GetInvoiceAddressConfigRequest.prototype.setCompanyId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.billing.private.companyconfig.GetInvoiceAddressConfigResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.billing.private.companyconfig.GetInvoiceAddressConfigResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.billing.private.companyconfig.GetInvoiceAddressConfigResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing.private.companyconfig.GetInvoiceAddressConfigResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    companyId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    companyName: jspb.Message.getFieldWithDefault(msg, 2, ""),
    addressLine1: jspb.Message.getFieldWithDefault(msg, 3, ""),
    addressLine2: jspb.Message.getFieldWithDefault(msg, 4, ""),
    city: jspb.Message.getFieldWithDefault(msg, 5, ""),
    state: jspb.Message.getFieldWithDefault(msg, 6, ""),
    countryIso3: jspb.Message.getFieldWithDefault(msg, 7, ""),
    postCode: jspb.Message.getFieldWithDefault(msg, 8, ""),
    emailAddress: jspb.Message.getFieldWithDefault(msg, 9, ""),
    phoneNumberDialCountry: jspb.Message.getFieldWithDefault(msg, 10, ""),
    phoneNumberDialCode: jspb.Message.getFieldWithDefault(msg, 11, ""),
    phoneNumber: jspb.Message.getFieldWithDefault(msg, 12, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.billing.private.companyconfig.GetInvoiceAddressConfigResponse}
 */
proto.billing.private.companyconfig.GetInvoiceAddressConfigResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.billing.private.companyconfig.GetInvoiceAddressConfigResponse;
  return proto.billing.private.companyconfig.GetInvoiceAddressConfigResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.billing.private.companyconfig.GetInvoiceAddressConfigResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.billing.private.companyconfig.GetInvoiceAddressConfigResponse}
 */
proto.billing.private.companyconfig.GetInvoiceAddressConfigResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setCompanyId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setCompanyName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setAddressLine1(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setAddressLine2(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setCity(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setState(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setCountryIso3(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setPostCode(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setEmailAddress(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setPhoneNumberDialCountry(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.setPhoneNumberDialCode(value);
      break;
    case 12:
      var value = /** @type {string} */ (reader.readString());
      msg.setPhoneNumber(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.billing.private.companyconfig.GetInvoiceAddressConfigResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.billing.private.companyconfig.GetInvoiceAddressConfigResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.billing.private.companyconfig.GetInvoiceAddressConfigResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing.private.companyconfig.GetInvoiceAddressConfigResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCompanyId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getCompanyName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getAddressLine1();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getAddressLine2();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getCity();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getState();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getCountryIso3();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getPostCode();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getEmailAddress();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getPhoneNumberDialCountry();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getPhoneNumberDialCode();
  if (f.length > 0) {
    writer.writeString(
      11,
      f
    );
  }
  f = message.getPhoneNumber();
  if (f.length > 0) {
    writer.writeString(
      12,
      f
    );
  }
};


/**
 * optional int32 company_id = 1;
 * @return {number}
 */
proto.billing.private.companyconfig.GetInvoiceAddressConfigResponse.prototype.getCompanyId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.billing.private.companyconfig.GetInvoiceAddressConfigResponse} returns this
 */
proto.billing.private.companyconfig.GetInvoiceAddressConfigResponse.prototype.setCompanyId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string company_name = 2;
 * @return {string}
 */
proto.billing.private.companyconfig.GetInvoiceAddressConfigResponse.prototype.getCompanyName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.billing.private.companyconfig.GetInvoiceAddressConfigResponse} returns this
 */
proto.billing.private.companyconfig.GetInvoiceAddressConfigResponse.prototype.setCompanyName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string address_line_1 = 3;
 * @return {string}
 */
proto.billing.private.companyconfig.GetInvoiceAddressConfigResponse.prototype.getAddressLine1 = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.billing.private.companyconfig.GetInvoiceAddressConfigResponse} returns this
 */
proto.billing.private.companyconfig.GetInvoiceAddressConfigResponse.prototype.setAddressLine1 = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string address_line_2 = 4;
 * @return {string}
 */
proto.billing.private.companyconfig.GetInvoiceAddressConfigResponse.prototype.getAddressLine2 = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.billing.private.companyconfig.GetInvoiceAddressConfigResponse} returns this
 */
proto.billing.private.companyconfig.GetInvoiceAddressConfigResponse.prototype.setAddressLine2 = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string city = 5;
 * @return {string}
 */
proto.billing.private.companyconfig.GetInvoiceAddressConfigResponse.prototype.getCity = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.billing.private.companyconfig.GetInvoiceAddressConfigResponse} returns this
 */
proto.billing.private.companyconfig.GetInvoiceAddressConfigResponse.prototype.setCity = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string state = 6;
 * @return {string}
 */
proto.billing.private.companyconfig.GetInvoiceAddressConfigResponse.prototype.getState = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.billing.private.companyconfig.GetInvoiceAddressConfigResponse} returns this
 */
proto.billing.private.companyconfig.GetInvoiceAddressConfigResponse.prototype.setState = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string country_iso_3 = 7;
 * @return {string}
 */
proto.billing.private.companyconfig.GetInvoiceAddressConfigResponse.prototype.getCountryIso3 = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.billing.private.companyconfig.GetInvoiceAddressConfigResponse} returns this
 */
proto.billing.private.companyconfig.GetInvoiceAddressConfigResponse.prototype.setCountryIso3 = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string post_code = 8;
 * @return {string}
 */
proto.billing.private.companyconfig.GetInvoiceAddressConfigResponse.prototype.getPostCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.billing.private.companyconfig.GetInvoiceAddressConfigResponse} returns this
 */
proto.billing.private.companyconfig.GetInvoiceAddressConfigResponse.prototype.setPostCode = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional string email_address = 9;
 * @return {string}
 */
proto.billing.private.companyconfig.GetInvoiceAddressConfigResponse.prototype.getEmailAddress = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.billing.private.companyconfig.GetInvoiceAddressConfigResponse} returns this
 */
proto.billing.private.companyconfig.GetInvoiceAddressConfigResponse.prototype.setEmailAddress = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional string phone_number_dial_country = 10;
 * @return {string}
 */
proto.billing.private.companyconfig.GetInvoiceAddressConfigResponse.prototype.getPhoneNumberDialCountry = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.billing.private.companyconfig.GetInvoiceAddressConfigResponse} returns this
 */
proto.billing.private.companyconfig.GetInvoiceAddressConfigResponse.prototype.setPhoneNumberDialCountry = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * optional string phone_number_dial_code = 11;
 * @return {string}
 */
proto.billing.private.companyconfig.GetInvoiceAddressConfigResponse.prototype.getPhoneNumberDialCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};


/**
 * @param {string} value
 * @return {!proto.billing.private.companyconfig.GetInvoiceAddressConfigResponse} returns this
 */
proto.billing.private.companyconfig.GetInvoiceAddressConfigResponse.prototype.setPhoneNumberDialCode = function(value) {
  return jspb.Message.setProto3StringField(this, 11, value);
};


/**
 * optional string phone_number = 12;
 * @return {string}
 */
proto.billing.private.companyconfig.GetInvoiceAddressConfigResponse.prototype.getPhoneNumber = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 12, ""));
};


/**
 * @param {string} value
 * @return {!proto.billing.private.companyconfig.GetInvoiceAddressConfigResponse} returns this
 */
proto.billing.private.companyconfig.GetInvoiceAddressConfigResponse.prototype.setPhoneNumber = function(value) {
  return jspb.Message.setProto3StringField(this, 12, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.billing.private.companyconfig.UpsertInvoiceConfigRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.billing.private.companyconfig.UpsertInvoiceConfigRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.billing.private.companyconfig.UpsertInvoiceConfigRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing.private.companyconfig.UpsertInvoiceConfigRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    companyId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    title: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.billing.private.companyconfig.UpsertInvoiceConfigRequest}
 */
proto.billing.private.companyconfig.UpsertInvoiceConfigRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.billing.private.companyconfig.UpsertInvoiceConfigRequest;
  return proto.billing.private.companyconfig.UpsertInvoiceConfigRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.billing.private.companyconfig.UpsertInvoiceConfigRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.billing.private.companyconfig.UpsertInvoiceConfigRequest}
 */
proto.billing.private.companyconfig.UpsertInvoiceConfigRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setCompanyId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setTitle(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.billing.private.companyconfig.UpsertInvoiceConfigRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.billing.private.companyconfig.UpsertInvoiceConfigRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.billing.private.companyconfig.UpsertInvoiceConfigRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing.private.companyconfig.UpsertInvoiceConfigRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCompanyId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getTitle();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional int32 company_id = 1;
 * @return {number}
 */
proto.billing.private.companyconfig.UpsertInvoiceConfigRequest.prototype.getCompanyId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.billing.private.companyconfig.UpsertInvoiceConfigRequest} returns this
 */
proto.billing.private.companyconfig.UpsertInvoiceConfigRequest.prototype.setCompanyId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string title = 2;
 * @return {string}
 */
proto.billing.private.companyconfig.UpsertInvoiceConfigRequest.prototype.getTitle = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.billing.private.companyconfig.UpsertInvoiceConfigRequest} returns this
 */
proto.billing.private.companyconfig.UpsertInvoiceConfigRequest.prototype.setTitle = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.billing.private.companyconfig.UpsertInvoiceConfigResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.billing.private.companyconfig.UpsertInvoiceConfigResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.billing.private.companyconfig.UpsertInvoiceConfigResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing.private.companyconfig.UpsertInvoiceConfigResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    title: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.billing.private.companyconfig.UpsertInvoiceConfigResponse}
 */
proto.billing.private.companyconfig.UpsertInvoiceConfigResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.billing.private.companyconfig.UpsertInvoiceConfigResponse;
  return proto.billing.private.companyconfig.UpsertInvoiceConfigResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.billing.private.companyconfig.UpsertInvoiceConfigResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.billing.private.companyconfig.UpsertInvoiceConfigResponse}
 */
proto.billing.private.companyconfig.UpsertInvoiceConfigResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setTitle(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.billing.private.companyconfig.UpsertInvoiceConfigResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.billing.private.companyconfig.UpsertInvoiceConfigResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.billing.private.companyconfig.UpsertInvoiceConfigResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing.private.companyconfig.UpsertInvoiceConfigResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTitle();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string title = 1;
 * @return {string}
 */
proto.billing.private.companyconfig.UpsertInvoiceConfigResponse.prototype.getTitle = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.billing.private.companyconfig.UpsertInvoiceConfigResponse} returns this
 */
proto.billing.private.companyconfig.UpsertInvoiceConfigResponse.prototype.setTitle = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.billing.private.companyconfig.GetInvoiceConfigRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.billing.private.companyconfig.GetInvoiceConfigRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.billing.private.companyconfig.GetInvoiceConfigRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing.private.companyconfig.GetInvoiceConfigRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    companyId: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.billing.private.companyconfig.GetInvoiceConfigRequest}
 */
proto.billing.private.companyconfig.GetInvoiceConfigRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.billing.private.companyconfig.GetInvoiceConfigRequest;
  return proto.billing.private.companyconfig.GetInvoiceConfigRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.billing.private.companyconfig.GetInvoiceConfigRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.billing.private.companyconfig.GetInvoiceConfigRequest}
 */
proto.billing.private.companyconfig.GetInvoiceConfigRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setCompanyId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.billing.private.companyconfig.GetInvoiceConfigRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.billing.private.companyconfig.GetInvoiceConfigRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.billing.private.companyconfig.GetInvoiceConfigRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing.private.companyconfig.GetInvoiceConfigRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCompanyId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
};


/**
 * optional int32 company_id = 1;
 * @return {number}
 */
proto.billing.private.companyconfig.GetInvoiceConfigRequest.prototype.getCompanyId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.billing.private.companyconfig.GetInvoiceConfigRequest} returns this
 */
proto.billing.private.companyconfig.GetInvoiceConfigRequest.prototype.setCompanyId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.billing.private.companyconfig.GetInvoiceConfigResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.billing.private.companyconfig.GetInvoiceConfigResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.billing.private.companyconfig.GetInvoiceConfigResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing.private.companyconfig.GetInvoiceConfigResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    title: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.billing.private.companyconfig.GetInvoiceConfigResponse}
 */
proto.billing.private.companyconfig.GetInvoiceConfigResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.billing.private.companyconfig.GetInvoiceConfigResponse;
  return proto.billing.private.companyconfig.GetInvoiceConfigResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.billing.private.companyconfig.GetInvoiceConfigResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.billing.private.companyconfig.GetInvoiceConfigResponse}
 */
proto.billing.private.companyconfig.GetInvoiceConfigResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setTitle(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.billing.private.companyconfig.GetInvoiceConfigResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.billing.private.companyconfig.GetInvoiceConfigResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.billing.private.companyconfig.GetInvoiceConfigResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing.private.companyconfig.GetInvoiceConfigResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTitle();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string title = 1;
 * @return {string}
 */
proto.billing.private.companyconfig.GetInvoiceConfigResponse.prototype.getTitle = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.billing.private.companyconfig.GetInvoiceConfigResponse} returns this
 */
proto.billing.private.companyconfig.GetInvoiceConfigResponse.prototype.setTitle = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.billing.private.companyconfig.GetAchPaymentConfigRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.billing.private.companyconfig.GetAchPaymentConfigRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.billing.private.companyconfig.GetAchPaymentConfigRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing.private.companyconfig.GetAchPaymentConfigRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    companyId: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.billing.private.companyconfig.GetAchPaymentConfigRequest}
 */
proto.billing.private.companyconfig.GetAchPaymentConfigRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.billing.private.companyconfig.GetAchPaymentConfigRequest;
  return proto.billing.private.companyconfig.GetAchPaymentConfigRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.billing.private.companyconfig.GetAchPaymentConfigRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.billing.private.companyconfig.GetAchPaymentConfigRequest}
 */
proto.billing.private.companyconfig.GetAchPaymentConfigRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setCompanyId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.billing.private.companyconfig.GetAchPaymentConfigRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.billing.private.companyconfig.GetAchPaymentConfigRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.billing.private.companyconfig.GetAchPaymentConfigRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing.private.companyconfig.GetAchPaymentConfigRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCompanyId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
};


/**
 * optional int32 company_id = 1;
 * @return {number}
 */
proto.billing.private.companyconfig.GetAchPaymentConfigRequest.prototype.getCompanyId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.billing.private.companyconfig.GetAchPaymentConfigRequest} returns this
 */
proto.billing.private.companyconfig.GetAchPaymentConfigRequest.prototype.setCompanyId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.billing.private.companyconfig.GetAchPaymentConfigResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.billing.private.companyconfig.GetAchPaymentConfigResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.billing.private.companyconfig.GetAchPaymentConfigResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing.private.companyconfig.GetAchPaymentConfigResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    isAchPaymentsActive: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    isAchPaymentsAvailable: jspb.Message.getBooleanFieldWithDefault(msg, 2, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.billing.private.companyconfig.GetAchPaymentConfigResponse}
 */
proto.billing.private.companyconfig.GetAchPaymentConfigResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.billing.private.companyconfig.GetAchPaymentConfigResponse;
  return proto.billing.private.companyconfig.GetAchPaymentConfigResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.billing.private.companyconfig.GetAchPaymentConfigResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.billing.private.companyconfig.GetAchPaymentConfigResponse}
 */
proto.billing.private.companyconfig.GetAchPaymentConfigResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsAchPaymentsActive(value);
      break;
    case 2:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsAchPaymentsAvailable(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.billing.private.companyconfig.GetAchPaymentConfigResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.billing.private.companyconfig.GetAchPaymentConfigResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.billing.private.companyconfig.GetAchPaymentConfigResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing.private.companyconfig.GetAchPaymentConfigResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getIsAchPaymentsActive();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getIsAchPaymentsAvailable();
  if (f) {
    writer.writeBool(
      2,
      f
    );
  }
};


/**
 * optional bool is_ach_payments_active = 1;
 * @return {boolean}
 */
proto.billing.private.companyconfig.GetAchPaymentConfigResponse.prototype.getIsAchPaymentsActive = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.billing.private.companyconfig.GetAchPaymentConfigResponse} returns this
 */
proto.billing.private.companyconfig.GetAchPaymentConfigResponse.prototype.setIsAchPaymentsActive = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional bool is_ach_payments_available = 2;
 * @return {boolean}
 */
proto.billing.private.companyconfig.GetAchPaymentConfigResponse.prototype.getIsAchPaymentsAvailable = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
};


/**
 * @param {boolean} value
 * @return {!proto.billing.private.companyconfig.GetAchPaymentConfigResponse} returns this
 */
proto.billing.private.companyconfig.GetAchPaymentConfigResponse.prototype.setIsAchPaymentsAvailable = function(value) {
  return jspb.Message.setProto3BooleanField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.billing.private.companyconfig.UpdateAchPaymentConfigRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.billing.private.companyconfig.UpdateAchPaymentConfigRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.billing.private.companyconfig.UpdateAchPaymentConfigRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing.private.companyconfig.UpdateAchPaymentConfigRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    companyId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    isAchPaymentsActive: jspb.Message.getBooleanFieldWithDefault(msg, 2, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.billing.private.companyconfig.UpdateAchPaymentConfigRequest}
 */
proto.billing.private.companyconfig.UpdateAchPaymentConfigRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.billing.private.companyconfig.UpdateAchPaymentConfigRequest;
  return proto.billing.private.companyconfig.UpdateAchPaymentConfigRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.billing.private.companyconfig.UpdateAchPaymentConfigRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.billing.private.companyconfig.UpdateAchPaymentConfigRequest}
 */
proto.billing.private.companyconfig.UpdateAchPaymentConfigRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setCompanyId(value);
      break;
    case 2:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsAchPaymentsActive(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.billing.private.companyconfig.UpdateAchPaymentConfigRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.billing.private.companyconfig.UpdateAchPaymentConfigRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.billing.private.companyconfig.UpdateAchPaymentConfigRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing.private.companyconfig.UpdateAchPaymentConfigRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCompanyId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getIsAchPaymentsActive();
  if (f) {
    writer.writeBool(
      2,
      f
    );
  }
};


/**
 * optional int32 company_id = 1;
 * @return {number}
 */
proto.billing.private.companyconfig.UpdateAchPaymentConfigRequest.prototype.getCompanyId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.billing.private.companyconfig.UpdateAchPaymentConfigRequest} returns this
 */
proto.billing.private.companyconfig.UpdateAchPaymentConfigRequest.prototype.setCompanyId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional bool is_ach_payments_active = 2;
 * @return {boolean}
 */
proto.billing.private.companyconfig.UpdateAchPaymentConfigRequest.prototype.getIsAchPaymentsActive = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
};


/**
 * @param {boolean} value
 * @return {!proto.billing.private.companyconfig.UpdateAchPaymentConfigRequest} returns this
 */
proto.billing.private.companyconfig.UpdateAchPaymentConfigRequest.prototype.setIsAchPaymentsActive = function(value) {
  return jspb.Message.setProto3BooleanField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.billing.private.companyconfig.UpdateAchPaymentConfigResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.billing.private.companyconfig.UpdateAchPaymentConfigResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.billing.private.companyconfig.UpdateAchPaymentConfigResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing.private.companyconfig.UpdateAchPaymentConfigResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    isAchPaymentsActive: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    isAchPaymentsAvailable: jspb.Message.getBooleanFieldWithDefault(msg, 2, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.billing.private.companyconfig.UpdateAchPaymentConfigResponse}
 */
proto.billing.private.companyconfig.UpdateAchPaymentConfigResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.billing.private.companyconfig.UpdateAchPaymentConfigResponse;
  return proto.billing.private.companyconfig.UpdateAchPaymentConfigResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.billing.private.companyconfig.UpdateAchPaymentConfigResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.billing.private.companyconfig.UpdateAchPaymentConfigResponse}
 */
proto.billing.private.companyconfig.UpdateAchPaymentConfigResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsAchPaymentsActive(value);
      break;
    case 2:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsAchPaymentsAvailable(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.billing.private.companyconfig.UpdateAchPaymentConfigResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.billing.private.companyconfig.UpdateAchPaymentConfigResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.billing.private.companyconfig.UpdateAchPaymentConfigResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing.private.companyconfig.UpdateAchPaymentConfigResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getIsAchPaymentsActive();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getIsAchPaymentsAvailable();
  if (f) {
    writer.writeBool(
      2,
      f
    );
  }
};


/**
 * optional bool is_ach_payments_active = 1;
 * @return {boolean}
 */
proto.billing.private.companyconfig.UpdateAchPaymentConfigResponse.prototype.getIsAchPaymentsActive = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.billing.private.companyconfig.UpdateAchPaymentConfigResponse} returns this
 */
proto.billing.private.companyconfig.UpdateAchPaymentConfigResponse.prototype.setIsAchPaymentsActive = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional bool is_ach_payments_available = 2;
 * @return {boolean}
 */
proto.billing.private.companyconfig.UpdateAchPaymentConfigResponse.prototype.getIsAchPaymentsAvailable = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
};


/**
 * @param {boolean} value
 * @return {!proto.billing.private.companyconfig.UpdateAchPaymentConfigResponse} returns this
 */
proto.billing.private.companyconfig.UpdateAchPaymentConfigResponse.prototype.setIsAchPaymentsAvailable = function(value) {
  return jspb.Message.setProto3BooleanField(this, 2, value);
};


goog.object.extend(exports, proto.billing.private.companyconfig);
