import React, { useLayoutEffect, Fragment, useState } from "react";
import "./SubscriptionEventLogs.scss";
import LogPanel from "../../ui/log-panel/LogPanel";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../../..";
import { fetchEventLogs, resetEventLogs } from "../../../actions/eventLogsActions";
import { matchPath } from "react-router";
import {
  EventLogType,
  IEventLog,
  RelatedItemType,
  IRelatedItemEventLog,
  IInvoiceSentEventLog,
  IPaymentEventLog,
  IPlanChangedEventLog,
  ISignUpEventLog,
  IDiscountAdded,
  IDiscountRemoved,
  ICycleChangedEventLog,
  IRenewalDateChanged,
  IUpdateStatus,
  ITaxNotApplied
} from "../../../models/EventLogs";
import counterpart from "counterpart";
import Text from "../../ui/text/Text";
import moment from "moment";
import CircleIcon from "../../ui/circle-icon/CirlcleIcon";
import Button from "../../ui/button/Button";
import ProgressIndicator from "../../ui/progress-indicator/ProgressIndicator";
import { getFrequencyText, getFrequencyTextLabel } from "../../../utils/commonUtils";

interface ISubscriptionId {
  id: string;
}

const SubscriptionEventLogs: React.FC = () => {
  const authData = useSelector((state: AppState) => state.auth);
  const eventLogsData = useSelector((state: AppState) => state.eventLogsReducer);

  const dispatch = useDispatch<Function>();

  const [isLoadingData, setIsLoadingData] = useState(false);
  const getParams = (pathname: string) => {
    const matchProfile = matchPath(pathname, {
      path: "/subscriptions/:id",
    });
    return (matchProfile && matchProfile.params) || {};
  };

  const { pathname } = window.location;
  const currentParams = getParams(pathname);
  const { currentCompanyDomain } = authData;
  const { id } = currentParams as ISubscriptionId;
  const pageSize = 6;

  const getDiscountLength = (discount: IDiscountAdded) => {
    if(discount.isLifetime) {
      return <span className="text-hightlight">{`${counterpart("SUBSCRIPTION_EVENT_LOG_LIFETIME_DISCOUNT")} `}</span>
    }
    if(discount.expiresOn && !discount.isLifetime) {
      return <span className="text-hightlight">{`${counterpart("SUBSCRIPTION_EVENT_LOG_FIXED_TERM_DISCOUNT")} `}</span>
    }
    return <span className="text-hightlight">{`${discount.frequency} ${getFrequencyText(discount.frequency, discount.frequencyType)?.capitalize()} `}</span>
  }

  const getItemEventContent = (item: IRelatedItemEventLog) => {
    const eventLogAction = item.addedOn
      ? "SUBSCRIPTION_EVENT_LOG_ADDED_TO_SUBSCRIPTION"
      : item.removedOn
        ? "SUBSCRIPTION_EVENT_LOG_REMOVED_FROM_SUBSCRIPTION"
        : "SUBSCRIPTION_EVENT_LOG_EDITED_SUBSCRIPTION";
    switch (item.type) {
      case RelatedItemType.Addon:
        return (
          <Text
            content={
              <Fragment>
                {counterpart("SUBSCRIPTION_EVENT_LOG_ADD_ON")} <span className="text-hightlight">{item.itemName}</span> {counterpart(eventLogAction)}
              </Fragment>
            }
            shouldTranslate={false}
          />
        );
      case RelatedItemType.Allowance:
        return (
          <Text
            content={
              <Fragment>
                {counterpart("SUBSCRIPTION_EVENT_LOG_ALLOWANCE")} <span className="text-hightlight">{item.itemName}</span> {counterpart(eventLogAction)}
              </Fragment>
            }
            shouldTranslate={false}
          />
        );
      case RelatedItemType.Coupon:
        return (
          <Text
            content={
              <Fragment>
                {counterpart("SUBSCRIPTION_EVENT_LOG_COUPON")} <span className="text-hightlight">{item.itemName}</span> {counterpart(eventLogAction)}
              </Fragment>
            }
            shouldTranslate={false}
          />
        );
      default:
        return <span />;
    }
  };

  const getTitle = (event: IEventLog) => {
    switch (event.type) {
      case EventLogType.AddedItemV1:
      case EventLogType.RemovedItemV1:
      case EventLogType.UpdatedItemV1:
        const relatedItem = event.eventData as IRelatedItemEventLog;
        return getItemEventContent(relatedItem);
      case EventLogType.InvoiceSentV1:
        const invoiceSentItem = event.eventData as IInvoiceSentEventLog;
        return (
          <Text
            content={
              <Fragment>
                {counterpart("SUBSCRIPTION_EVENT_LOG_INVOICE_SENT_TO")} <span className="text-hightlight">{invoiceSentItem.email}</span> {counterpart("SUBSCRIPTION_EVENT_LOG_OF")}{" "}
                <span className="text-hightlight">{invoiceSentItem.amount}</span> {counterpart("SUBSCRIPTION_EVENT_LOG_FOR")}{" "}
                <span className="text-hightlight">{counterpart("SUBSCRIPTION_EVENT_LOG_PLAN_PAYMENT")}</span>
              </Fragment>
            }
            shouldTranslate={false}
          />
        );
      case EventLogType.PausedV1:
        const pausedDate = moment(event.createdOn).format("DD MMM YYYY");
        return (
          <Text
            content={
              <Fragment>
                {counterpart("SUBSCRIPTION_EVENT_LOG_SUBSCRIPTION_PAUSED")} <span className="text-hightlight">{pausedDate}</span>
              </Fragment>
            }
            shouldTranslate={false}
          />
        );
      case EventLogType.ResumedV1:
        const resumedDate = moment(event.createdOn).format("DD MMM YYYY");
        return (
          <Text
            content={
              <Fragment>
                {counterpart("SUBSCRIPTION_EVENT_LOG_SUBSCRIPTION_RESUMED")} <span className="text-hightlight">{resumedDate}</span>
              </Fragment>
            }
            shouldTranslate={false}
          />
        );
      case EventLogType.CancelledV1:
        const cancelledDate = moment(event.createdOn).format("DD MMM YYYY");
        return (
          <Text
            content={
              <Fragment>
                {counterpart("SUBSCRIPTION_EVENT_LOG_SUBSCRIPTION_CANCELLED")} <span className="text-hightlight">{cancelledDate}</span>
              </Fragment>
            }
            shouldTranslate={false}
          />
        );
      case EventLogType.PaymentFailedV1:
        const paymentFailed = event.eventData as IPaymentEventLog;
        return (
          <Text
            className="large-icon-text"
            content={
              <Fragment>
                {counterpart("SUBSCRIPTION_EVENT_LOG_FAILED_PAYMENT_OF")} <span className="text-hightlight">{paymentFailed.amount}</span> {counterpart("SUBSCRIPTION_EVENT_LOG_FOR")}{" "}
                <span className="text-hightlight">{paymentFailed.planName}</span>
              </Fragment>
            }
            shouldTranslate={false}
          />
        );
      case EventLogType.PaymentSuccessV1:
        const paymentSuccess = event.eventData as IPaymentEventLog;
        return (
          <Text
            className="large-icon-text"
            content={
              <Fragment>
                {counterpart("SUBSCRIPTION_EVENT_LOG_SUCCESSFUL_PAYMENT_OF")} <span className="text-hightlight">{paymentSuccess.amount}</span> {counterpart("SUBSCRIPTION_EVENT_LOG_TAKEN_FOR")}{" "}
                <span className="text-hightlight">{paymentSuccess.planName}</span>
              </Fragment>
            }
            shouldTranslate={false}
          />
        );
      case EventLogType.PlanChangedV1:
        const planChanged = event.eventData as IPlanChangedEventLog;
        return (
          <Text
            content={
              <Fragment>
                {counterpart("SUBSCRIPTION_EVENT_LOG_PLAN_CHANGED_FROM")} <span className="text-hightlight">{planChanged.fromPlanName}</span> {counterpart("SUBSCRIPTION_EVENT_LOG_TO")}{" "}
                <span className="text-hightlight">{planChanged.toPlanName}</span>
              </Fragment>
            }
            shouldTranslate={false}
          />
        );
      case EventLogType.SignUpV1:
        const signupItem = event.eventData as ISignUpEventLog;
        return (
          <Text
            className="large-icon-text"
            content={
              <Fragment>
                {counterpart("SUBSCRIPTION_EVENT_LOG_SUCCESSFUL_SIGNUP")} <span className="text-hightlight">{signupItem.planName}</span> {counterpart("SUBSCRIPTION_EVENT_LOG_SUCCESSFUL_SIGNUP_PLAN")}
              </Fragment>
            }
            shouldTranslate={false}
          />
        );
      case EventLogType.DiscountAddedV1: {
        const discountAddedItem = event.eventData as IDiscountAdded;
        return (
          <Text
            className="large-icon-text"
            content={
              <Fragment>
                {getDiscountLength(discountAddedItem)}{counterpart("SUBSCRIPTION_EVENT_LOG_DISCOUNT")} <span className="text-hightlight">{discountAddedItem.discountName}</span> {counterpart("SUBSCRIPTION_EVENT_LOG_ADDED_TO_SUBSCRIPTION")}
              </Fragment>
            }
            shouldTranslate={false}
          />
        );
      }
      case EventLogType.DiscountRemovedV1: {
        const discountRemovedItem = event.eventData as IDiscountRemoved;
        return (
          <Text
            className="large-icon-text"
            content={
              <Fragment>
                {counterpart("SUBSCRIPTION_EVENT_LOG_DISCOUNT").capitalize()} <span className="text-hightlight">{discountRemovedItem.discountName}</span> {counterpart("SUBSCRIPTION_EVENT_LOG_REMOVED_FROM_SUBSCRIPTION")}
              </Fragment>
            }
            shouldTranslate={false}
          />
        );
      }
      case EventLogType.CycleChangedV1: {
        const cycleChangedItem = event.eventData as ICycleChangedEventLog;
        return (
          <Text
            className="large-icon-text"
            content={
              <Fragment>
                {counterpart("SUBSCRIPTION_EVENT_LOG_CYCLE_CHANGED")}
                <span className="text-hightlight">{` ${cycleChangedItem.priceFormatted} 
                  ${getFrequencyTextLabel(cycleChangedItem.frequency, cycleChangedItem.frequencyType)}`}
                </span>
              </Fragment>
            }
            shouldTranslate={false}
          />
        );
      }
      case EventLogType.RenewalDateChangedV1: {
        const renewalChangeItem = event.eventData as IRenewalDateChanged;
        return (
          <Text
            className="large-icon-text"
            content={
              <Fragment>
                {counterpart("CHANGE_BILL_DATE_STATUS_EVENT")} <span className="text-hightlight">{moment.utc(renewalChangeItem.newBillDate).format("DD MMM YYYY")}</span>
              </Fragment>
            }
            shouldTranslate={false}
          />
        );
      }
      case EventLogType.UpdateStatusV1: {
        const updateStatusItem = event.eventData as IUpdateStatus;
        return (
          <Text
            className="large-icon-text"
            content={
              <Fragment>
                {counterpart("SUBSCRIPTION_EVENT_LOG_UPDATED_STATUS_TITLE")} <span className="text-hightlight">{moment.utc(updateStatusItem.updatedOn).format("DD MMM YYYY")}</span>
              </Fragment>
            }
            shouldTranslate={false}
          />
        );
      }
      case EventLogType.TaxNotAppliedV1: {
        const taxNotAppliedItem = event.eventData as ITaxNotApplied;
        return (
          <Text
            className="large-icon-text"
            content={
              <Fragment>
                {counterpart("SUBSCRIPTION_EVENT_LOG_TAX_NOT_APPLIED_TITLE")} <span className="text-hightlight">{taxNotAppliedItem.invoiceId}</span>
              </Fragment>
            }
            shouldTranslate={false}
          />
        )
      }
      // no design yet, need to confirm the contents.
      // case EventLogType.RemovedItemV1:
      //   return <span></span>;
      // case EventLogType.UsedItemV1:
      //     return <span></span>;
      default:
        return <span />;
    }
  };

  const getSubtitle = (event: IEventLog) => {
    const createdOnDate = moment(event.createdOn).format("DD MMM YYYY");
    const createdOnTime = moment(event.createdOn).format("HH:mm Z");


    const dateCreatedText = (
      <Fragment>
        {counterpart("SUBSCRIPTION_EVENT_LOG_ON")} <span className="text-hightlight">{createdOnDate}</span> {counterpart("SUBSCRIPTION_EVENT_LOG_AT")}{" "}
        <span className="text-hightlight">{createdOnTime}</span>
      </Fragment>
    );
    const createdByText =
      !!event.eventData.firstName && !!event.eventData.lastName ? (
        <Fragment>
          {counterpart("SUBSCRIPTION_EVENT_LOG_BY")} <span className="text-hightlight">{`${event.eventData.firstName} ${event.eventData.lastName}`}</span> {dateCreatedText}
        </Fragment>
      ) : null;
    return (
      <Fragment>
        {counterpart("SUBSCRIPTION_EVENT_LOG_ADDED")} {!!createdByText ? createdByText : dateCreatedText}
      </Fragment>
    );
  };

  const getAddItemEventIcon = (relatedItemEventLog: IRelatedItemEventLog) => {
    const type = relatedItemEventLog.type as RelatedItemType;
    switch (type) {
      case RelatedItemType.Addon:
        return <i className="fas fa-layer-group" />;
      case RelatedItemType.Allowance:
        return <i className="fas fa-tachometer-alt" />;
      case RelatedItemType.Coupon:
        return <i className="fas fa-ticket-alt" />;
      default:
        return <i />;
    }
  };

  const getIcon = (event: IEventLog) => {
    switch (event.type) {
      case EventLogType.AddedItemV1:
      case EventLogType.RemovedItemV1:
      case EventLogType.UpdatedItemV1:
        const relatedItemEventLog = event.eventData as IRelatedItemEventLog;
        return getAddItemEventIcon(relatedItemEventLog);
      case EventLogType.InvoiceSentV1:
        return <i className="far fa-file" />;
      case EventLogType.PausedV1:
        return <i className="fa fa-pause" />;
      case EventLogType.ResumedV1:
        return <i className="fa fa-play" />;
      case EventLogType.CancelledV1:
        return <i className="fa fa-trash-alt subscription-event-log__icon-red" />;
      case EventLogType.PaymentSuccessV1:
        return <CircleIcon className="icon-green" icon="far fa-check" />;
      case EventLogType.PaymentFailedV1:
      case EventLogType.TaxNotAppliedV1:
        return <CircleIcon className="icon-red" icon="fas fa-exclamation" />;
      case EventLogType.PlanChangedV1:
      case EventLogType.CycleChangedV1:
        return <i className="fas fa-pencil-alt" />;
      case EventLogType.SignUpV1:
        return <CircleIcon className="icon-green" icon="far fa-check" />;
      case EventLogType.DiscountAddedV1:
        return <i className="fas fa-ticket-alt subscription-event-log__discount-icon" />;
      case EventLogType.DiscountRemovedV1:
        return <i className="fas fa-ticket-alt subscription-event-log__discount-icon" />;
      // no design yet, need to confirm the contents.
      // case EventLogType.RemovedItemV1:
      //   return getAddItemEventIcon(event)
      // case EventLogType.UsedItemV1:
      //   return getAddItemEventIcon(event)
      case EventLogType.RenewalDateChangedV1:
        return <i className="fas fa-calendar-day" />
      case EventLogType.UpdateStatusV1:
        return <i className='fas fa-edit' />
      default:
        return <span />;
    }
  };

  useLayoutEffect(() => {
    const fetchData = async () => {
      setIsLoadingData(true);
      dispatch(resetEventLogs());
      try {
        await dispatch(fetchEventLogs(currentCompanyDomain, id, 1, pageSize));
        setIsLoadingData(false);
      } catch (err) {
        console.log(err);
        setIsLoadingData(false);
      }
    };

    fetchData();
  }, []);

  if (isLoadingData) {
    return <ProgressIndicator color="blue" />;
  }

  if (!eventLogsData.results) {
    return null;
  }

  return (
    <div className="subscription-event-log">
      {/* <div className="subscription-event-log__controls">
        <Pagination
          className="subscription-event-log__controls__pagination"
          pageCount={eventLogsData.pageCount}
          isVisible={eventLogsData.pageCount > 1}
          onPageChange={({ selected }) => dispatch(fetchEventLogs(currentCompanyDomain, id, selected + 1))}
        />
      </div> */}

      {eventLogsData.results.map((eventLog, index) =>
        // Currently we don't have a design and contents for this types.
        eventLog.type !== EventLogType.UsedItemV1
          ? (
            <LogPanel
              key={`${eventLog.subscriptionEventLogId}-${index}`}
              title={getTitle(eventLog)}
              subTitle={getSubtitle(eventLog)}
              icon={getIcon(eventLog)}
            />
          )
          : null
      )}

      {eventLogsData.currentPage < eventLogsData.pageCount && (
        <Button
          id="load-more-events-btn"
          type="submit"
          buttonType="general"
          isLoading={false}
          className="subscription-event-log__load-more-btn"
          title="SUBSCRIPTION_EVENT_LOG_LOAD_MORE_EVENTS"
          isFullWidth
          onClick={() => dispatch(fetchEventLogs(currentCompanyDomain, id, eventLogsData.currentPage + 1, pageSize, true))}
        />
      )}
    </div>
  );
};

export default SubscriptionEventLogs;
