import React from "react";
import Panel from "../../../ui/panel/Panel";
import Text from "../../../ui/text/Text";
import icon1 from "../../../../images/integrations/webex/customer-add-icon.png";
import icon2 from "../../../../images/integrations/webex/customer-edit-icon.png";
import icon3 from "../../../../images/integrations/webex/subscription-icon.png";
import icon4 from "../../../../images/integrations/webex/subscription-edit-icon.png";
import icon5 from "../../../../images/integrations/webex/payment-icon.png";
import "./WebexEventTriggers.scss";

export const WebexEventTriggers: React.FC = () => {
  return (

    <Panel className="webex_event-triggers" title="INTEGRATIONS_EVENT_TRIGGERS_WEBEX">
      <Text content="INTEGRATIONS_EVENT_TRIGGERS_WEBEX_TEXT"></Text>
      <div className="webex_event-triggers__contents">
        <Panel>
          <img src={icon1} alt="no img"/>
          <div className="webex_event-triggers__contents__details">
            <Text content="INTEGRATIONS_WEBEX_EVENT1" noMargin></Text>
            <Text content="INTEGRATIONS_WEBEX_EVENT1_TEXT" noMargin></Text>
          </div>
        </Panel>
        <Panel>
          <img src={icon2} alt="no img"/>
          <div className="webex_event-triggers__contents__details">
            <Text content="INTEGRATIONS_WEBEX_EVENT2" noMargin></Text>
            <Text content="INTEGRATIONS_WEBEX_EVENT2_TEXT" noMargin></Text>
          </div>
        </Panel>
        <Panel>
          <img src={icon3} alt="no img"/>
          <div className="webex_event-triggers__contents__details">
            <Text content="INTEGRATIONS_WEBEX_EVENT3" noMargin></Text>
            <Text content="INTEGRATIONS_WEBEX_EVENT3_TEXT" noMargin></Text>
          </div>
        </Panel>
        <Panel>
          <img src={icon4} alt="no img"/>
          <div className="webex_event-triggers__contents__details">
            <Text content="INTEGRATIONS_WEBEX_EVENT4" noMargin></Text>
            <Text content="INTEGRATIONS_WEBEX_EVENT4_TEXT" noMargin></Text>
          </div>
        </Panel>
        <Panel>
          <img src={icon5} alt="no img"/>
          <div className="webex_event-triggers__contents__details">
            <Text content="INTEGRATIONS_WEBEX_EVENT5" noMargin></Text>
            <Text content="INTEGRATIONS_WEBEX_EVENT5_TEXT" noMargin></Text>
          </div>
        </Panel>
      </div>
    </Panel>

  )
}

export default WebexEventTriggers;