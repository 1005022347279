import React, { useMemo } from "react"

interface IPricingInfoDisclosure {
  rawHTMLEncoded: string,
  className?: string
}


const PricingInfoDisclosure: React.FC<IPricingInfoDisclosure> = ({ rawHTMLEncoded, className = ""}) => {

  /* safe decoding from base64 to retain special characters
  * https://stackoverflow.com/questions/30106476/using-javascripts-atob-to-decode-base64-doesnt-properly-decode-utf-8-strings
  */
  const b64_to_utf8 = (str: string) => {
    str = str.replace(/\s/g, "");    
    return decodeURIComponent(escape(window.atob( str )));
  }

  const htmlToRender = useMemo(() => {
    // decoding of html base64
    let text = b64_to_utf8(rawHTMLEncoded)
    //replace classes of returned html to match classes in app
    let text_styled = text.replace(/text/g, "cc-text")
    text_styled = text_styled.replace(/cc-text__mb0/g, "mb0")
    text_styled = text_styled.replace(/form-group__default/g, "cc-form-group")
    return text_styled
  }, [rawHTMLEncoded])

  return (
    <div dangerouslySetInnerHTML={{ __html: htmlToRender }} className={className} />
  )
}

export default PricingInfoDisclosure