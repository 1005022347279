import React from "react";
import Button from "../../../../../ui/button/Button";
import history from "../../../../../../utils/history";
import Panel from "../../../../../ui/panel/Panel";
import Text from "../../../../../ui/text/Text";
import Checkbox from "../../../../../ui/checkbox/Checkbox";
import { Row, Col } from "react-grid-system";
import "./BbcSettings.scss";
import counterpart from "counterpart";

interface Props {
  title: string,
  buttonText: string,
  id: string,
  noMargin?: boolean,
  companyAlert: boolean,
  productAlert: boolean,
  planAlert: boolean,
  isLoading?: boolean,
  onCompanyAlertChange?: () => void,
  onProductAlertChange?: () => void,
  onPlanAlertChange?: () => void,
  onSubmit: () => void
}

export const BbcSettings: React.FC<Props> = ({ title, companyAlert, id, productAlert, planAlert, isLoading = false, buttonText, noMargin = false,
  onSubmit, onCompanyAlertChange, onProductAlertChange, onPlanAlertChange }) => {

  return (
    <Panel title={title} shouldTranslate={false} noMargin={noMargin} className="bbc-settings-input__panel__title">
      {
        !!onCompanyAlertChange &&
        <Row>
          <Col md={12}>
            <Checkbox checked={companyAlert} onClick={onCompanyAlertChange} value='' content={
              <div className="bbc-settings-input__checkbox__label">
                <Text content="BBC_SETTINGS_COMPANY_ALERT" className="bbc-settings-input__checkbox__title" noMargin />
                <Text content={<>{counterpart("BBC_SETTINGS_COMPANY_ALERT_DESCRIPTION")} <span className="bbc-settings-input__checkbox__subtitle-link" onClick={() => history.push("/configuration/company-details")}>{counterpart("BBC_SETTINGS_COMPANY_ALERT_DESCRIPTION_COMPANY_DETAILS")}</span></>}
                  shouldTranslate={false} className="bbc-settings-input__checkbox__subtitle" noMargin />
              </div>
            }
            />
          </Col>
        </Row>
      }
      {
        !!onProductAlertChange &&
        <Row>
          <Col md={12}>
            <Checkbox checked={productAlert} onClick={onProductAlertChange} value='' content={
              <div className="bbc-settings-input__checkbox__label">
                <Text content="BBC_SETTINGS_PRODUCT_ALERT" className="bbc-settings-input__checkbox__title" noMargin />
                <Text content="BBC_SETTINGS_PRODUCT_ALERT_DESCRIPTION" className="bbc-settings-input__checkbox__subtitle" noMargin />
              </div>
            }
            />
          </Col>
        </Row>
      }
      {
        !!onPlanAlertChange &&
        <Row>
          <Col md={12}>
            <Checkbox checked={planAlert} onClick={onPlanAlertChange} value='' content={
              <div className="bbc-settings-input__checkbox__label">
                <Text content="BBC_SETTINGS_PLAN_ALERT" className="bbc-settings-input__checkbox__title" noMargin />
                <Text content="BBC_SETTINGS_PLAN_ALERT_DESCRIPTION" className="bbc-settings-input__checkbox__subtitle" noMargin />
              </div>
            }
            />
          </Col>
        </Row>
      }
      <Button className="bbc-settings-input__submit"
        id={`btn-${id}`}
        title={buttonText}
        shouldTranslate={false}
        buttonType="add"
        onClick={onSubmit}
        isLoading={isLoading}
      />
    </Panel>
  )
}

export default BbcSettings;
