import React from "react";
import "./CheckoutInput.scss";

interface ICheckoutInput {
  id?: string,
  /**
   * optional icon to be placed before the actual text
   */
  icon?: string,
  /**
   * show the error state
   */
  isError?: boolean,
  /**
   * show error message
   */
  errorMsg?: string,
  /**
   * type of error icon
   */
  errorIcon?: string,
  /**
   * callback for error icon
   */
  errorCb?: () => {},
  /**
   * classes
   */
  className?: string,
  /**
   * placeholder
   */
  placeholder?: string,
  /**
   * on change hook
   */
  onChange?: (evt: any) => any,
  /**
   * value
   */
  value?: string | number,
  type?: string,
  pattern?: string,
  /**
   * display error with a warning layout
   */
  warninglayout?: boolean,
  required?: boolean,
  autocomplete?: string | boolean
}

const CheckoutInput: React.FC<ICheckoutInput> = ({ icon = "", isError = false, errorMsg = "", errorIcon = "exclamation-circle", errorCb = () => { }, warninglayout = false,
  className = "", ...rest }) => {

  return (
    <div className={isError ? (`checkout-input checkout-input${warninglayout ? "--warning" : "--error"} ${className}`) : `checkout-input ${className}`}>
      {icon && <i className={`checkout-input__icon ${icon}`} />}
      <input
        type="text"
        className={icon ? "checkout-input__field checkout-input__field--icon" : "checkout-input__field"}
        {...rest}
      />
      {isError &&
        <span className={`checkout-input__error-container ${warninglayout ? "checkout-input__error-container--warning" : ""}`}>
          <i role="button" onClick={errorCb} className={`checkout-input__error-icon ${warninglayout ? "far fa-info-circle" : errorIcon}`} tabIndex={-1} />
          <span className="checkout-input__error-msg">{errorMsg}</span>
        </span>
      }
    </div>
  );
}

export default CheckoutInput;
