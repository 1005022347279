import React, { useLayoutEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../../../index";
import "./CustomerSubscriptions.scss";
import { fetchCustomerSubscriptions } from "../../../actions/customerSubscriptionsActions";
import Table from "../../ui/table/Table";
import Text from "../../ui/text/Text";
import moment from "moment";
import StatusLabel from "../../ui/status-label/StatusLabel";
import Button from "../../ui/button/Button";
import { COLOR } from "../../../utils/constants";
import { matchPath } from "react-router";
import ProgressIndicator from "../../ui/progress-indicator/ProgressIndicator";
import { CardStatus, CustomerDataStatus, PaymentMethodType } from "../../../models/Customer";
import { BillsbyDataParams } from "../../../utils/checkout/checkoutIntegrationModel";
import { appendBillsbyDataAttributeToCheckoutLib } from "../../../utils/checkout/checkoutIntegrationUtils";
import Tooltip from "../../ui/tooltip/Tooltip";
import { ICheckoutEvent, CROSS_DOMAIN_EVENTS } from "../../../utils/checkout/checkoutEvents";
import { fetchCustomerDetails } from "../../../actions/customerDetailsActions";
import { IProductLightWithPlans } from "../../../models/Product";
import { fetchProducts } from "../../../actions/productPlanCycleActions";
import { SubscriptionStatusType } from "../../../models/Subscriptions";
import { Permission } from "../../../models/Auth";
import useCheckFeaturePermission from "../../../utils/hooks/useCheckFeaturePermission";

interface ICustomerUniqueId {
  id: string
}

const CustomerSubscriptions: React.FC = () => {

  const authData = useSelector((state: AppState) => state.auth); 
  const customerSubscriptionsData = useSelector((state: AppState) => state.customerSubscriptionsReducer); 
  const customerDetailsData = useSelector((state: AppState) => state.customerDetailsReducer); 
  const checkList = useSelector((state: AppState) => state.goLiveReducer.checkList);
  const isCustomerAddSubscriptionPermitted  = useCheckFeaturePermission(Permission.CustomerAddSubscription); 

  const dispatch = useDispatch<Function>();

  const { currentCompanyDomain } = authData;

  const getParams = (pathname: string) => {
    const matchProfile = matchPath(pathname, { path: "/customers/:id" });
    return (matchProfile && matchProfile.params) || {};
  };

  const currentParams = getParams(window.location.pathname) as ICustomerUniqueId;
  const [isLoadingPlans, setIsLoadingPlans] = useState(true);
  const [hasPlans, setHasPlans] = useState(false);

  useLayoutEffect(() => {
    const fetchPlans = async () => {
      try {
        const data = (await dispatch(fetchProducts(currentCompanyDomain, 1, 100))) as ({ response: { results: Array<IProductLightWithPlans>}});
        setHasPlans(data.response.results.filter(p => p.numberOfPlans > 0).length > 0);
        setIsLoadingPlans(false);
      } catch(err) {
        setIsLoadingPlans(false);
      }
    }
    fetchPlans()
  }, [])

  useLayoutEffect(() => {
    try {
      dispatch(fetchCustomerSubscriptions(currentCompanyDomain, currentParams.id));
    }
    catch (err) { }
  }, []);

  window.onmessage = (checkoutEvent: ICheckoutEvent) => {
    // this callback intercept the events coming from the checkout iframe and trigger some behaviour consequently
    switch (checkoutEvent.data.key) {
      case CROSS_DOMAIN_EVENTS.CREATE_SUBSCRIPTION_SUCCESS:
        //reload customer details and subscriptions after creating one new subscription via the checkout
        dispatch(fetchCustomerSubscriptions(currentCompanyDomain, currentParams.id));
        dispatch(fetchCustomerDetails(currentCompanyDomain, currentParams.id));
        break;
      default:
        break;
    }
  }

  const getStatusText = (status: SubscriptionStatusType) => {
    switch (status) {
      case SubscriptionStatusType.ACTIVE:
        return "CUSTOMER_SUBSCRIPTIONS_LIST_TABLE_ACTIVE";
      case SubscriptionStatusType.CANCELLED:
        return "CUSTOMER_SUBSCRIPTIONS_LIST_TABLE_CANCELLED";
      case SubscriptionStatusType.PAUSED:
        return "CUSTOMER_SUBSCRIPTIONS_LIST_TABLE_PAUSED";
      case SubscriptionStatusType.INTRIAL:
        return "CUSTOMER_SUBSCRIPTIONS_LIST_TABLE_INTRIAL";
      case SubscriptionStatusType.SUSPENDED:
        return "CUSTOMER_SUBSCRIPTIONS_LIST_TABLE_SUSPENDED";
      default:
        return "CUSTOMER_SUBSCRIPTIONS_LIST_TABLE_UNSPECIFIED";
    }
  }

  const getStatusColor = (status: SubscriptionStatusType) => {
    switch (status) {
      case SubscriptionStatusType.ACTIVE:
        return COLOR.GREEN;
      case SubscriptionStatusType.CANCELLED:
        return COLOR.RED;
      case SubscriptionStatusType.PAUSED:
        return COLOR.LIGHT_GREEN;
      case SubscriptionStatusType.INTRIAL:
        return COLOR.ORANGE;
      case SubscriptionStatusType.SUSPENDED:
        return COLOR.LIGHT_RED
      default:
        return COLOR.ORANGE;
    }
  }

  if (!customerSubscriptionsData.subscriptions) {
    return <ProgressIndicator color="blue" />
  }

  if(!checkList?.isProductReady && !customerSubscriptionsData.subscriptions.length) {
    return null;
  }
  
  if (customerSubscriptionsData.subscriptions.length === 0) {
    const isCardInvalidAndNotACH = customerDetailsData.cardDetails.paymentMethodType !== PaymentMethodType.ACH && customerDetailsData.cardStatus !== CardStatus.VALID;
    return (
      <div className="customer_subscriptions" data-tip data-for="invalid-card-tooltip">
        <Button
          id="create-subscription"
          title="CUSTOMER_DETAILS_NAV_ADD_SUBSCRIPTION"
          buttonType="add"
          icon="fal fa-plus-circle"
          isFullWidth
          isLoading={isLoadingPlans}
          tooltipMessage={!isCustomerAddSubscriptionPermitted ? "NO_ACCESS_MSG" : undefined}
          disabled={!isCustomerAddSubscriptionPermitted || !hasPlans || isCardInvalidAndNotACH || customerDetailsData.status !== CustomerDataStatus.ACTIVE}
          onClick={() => {
            appendBillsbyDataAttributeToCheckoutLib({ label: BillsbyDataParams.company, value: currentCompanyDomain });
            window.scanDomBillsby();
            const createSubscriptionCheckoutBtn = document.querySelector(`#create-subscription-${customerDetailsData.customerUniqueId}`);
            createSubscriptionCheckoutBtn && (createSubscriptionCheckoutBtn as HTMLElement).click();
          }}
        />
        {
          isCardInvalidAndNotACH &&
          <Tooltip id="invalid-card-tooltip" place="top" type="blue">
            <Text content="CUSTOMER_SUBSCRIPTIONS_CARD_REQUIRED" noMargin />
          </Tooltip>
        }
      </div>
    )
  }

  return (
    <div className="customer_subscriptions">
      <Table className="customer_subscriptions__table">
        <thead>
          <tr>
            <Text content="CUSTOMER_SUBSCRIPTIONS_PRODUCT" component="th" noMargin />
            <Text content="CUSTOMER_SUBSCRIPTIONS_PLAN" component="th" noMargin />
            <Text content="CUSTOMER_SUBSCRIPTIONS_STATUS" component="th" noMargin />
            <Text content="CUSTOMER_SUBSCRIPTIONS_REVENUE" component="th" noMargin />
            <Text content="CUSTOMER_SUBSCRIPTIONS_NEXT_BILLING" component="th" noMargin />
            <Text content="CUSTOMER_SUBSCRIPTIONS_CREATED_ON" component="th" noMargin />
            <Text content="" component="th" shouldTranslate={false} noMargin />
          </tr>
        </thead>
        <tbody>
          {
            customerSubscriptionsData.subscriptions.map(subscription => (
              <tr key={subscription.subscriptionId}>
                <td>{subscription.productName}</td>
                <td>{subscription.planName}</td>
                <td>
                  <StatusLabel
                    content={getStatusText(subscription.status)}
                    color={getStatusColor(subscription.status)}
                  />
                </td>
                <td className={subscription.status === SubscriptionStatusType.CANCELLED ? "disabled" : ""}>{subscription.revenue}</td>
                <td className={subscription.status === SubscriptionStatusType.CANCELLED ? "disabled" : ""}>{moment(subscription.nextBilling).format("DD MMM YYYY")}</td>
                <td>{moment(subscription.createdOn).format("DD MMM YYYY")}</td>
                <td>
                  <Button
                    id="customer_subscriptions-view"
                    icon="fas fa-eye"
                    title="CUSTOMER_SUBSCRIPTIONS_VIEW_BUTTON"
                    buttonType="add"
                    link={`/subscriptions/${subscription.subscriptionId}`}
                  />
                </td>
              </tr>
            ))
          }
        </tbody>
      </Table>
    </div>
  )
}

export default CustomerSubscriptions;