import React, { useEffect, useState } from "react";
import Table from "../../ui/table/Table";
import Text from "../../ui/text/Text";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../../..";
import { setDeletedAllowance, fetchSubscriptionAllowances } from "../../../actions/subscriptionDetailsActions";
import moment from "moment";
import { Allowance } from "../../../utils/grpc/generated/Billsby.Protos/billing/private/subscription/subscription_pb";
import SubscriptionAddOnsTiers from "../subscription-add-ons/SubscriptionAddOnsTiers";
import ProgressIndicator from "../../ui/progress-indicator/ProgressIndicator";
import NoticePanel from "../../ui/notice-panel/NoticePanel";

const SubscriptionAllowances: React.FC = () => {
  const authData = useSelector((state: AppState) => state.auth);
  const subscriptionDetails = useSelector((state: AppState) => state.subscriptionDetailsReducer);

  const dispatch = useDispatch<Function>();

  const [isLoadingData, setIsLoadingData] = useState(false);
  const { allowances, planPricingModel, subscriptionId, deletedAllowance } = subscriptionDetails;
  const nextBillingDate = moment(subscriptionDetails.nextBillingDate).format("DD MMM YYYY");
  const nextBillingTime = moment(subscriptionDetails.nextBillingDate).format("HH:mm:ss Z");

  const fetchData = async () => {
    setIsLoadingData(true);
    try {
      dispatch(setDeletedAllowance(undefined));
      await dispatch(fetchSubscriptionAllowances(authData.currentCompanyId as number, subscriptionId as number));
    }
    catch (err) {
      console.log(err);
    }
    finally {
      setIsLoadingData(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  if (isLoadingData) {
    return <ProgressIndicator color="blue" />;
  }

  return (
    <div>
      {deletedAllowance && (
        <NoticePanel
          type="success"
          content="SUBSCRIPTION_ALLOWANCES_DELETED_ADD_ON"
          translateWith={{ name: deletedAllowance.getName(), nextBillingDate, nextBillingTime }}
          icon="fas fa-check-circle"
        />
      )}
      {!!allowances && allowances.length > 0 && <NoticePanel
        type="warning"
        content="SUBSCRIPTION_ALLOWANCES_RESET_WARNING"
        translateWith={{ nextBillingDate, nextBillingTime }}
        icon="fal fa-lightbulb-exclamation"
      />}
      <Table className="subscription-add-ons__table">
        <thead>
          <tr>
            <Text content="SUBSCRIPTION_ALLOWANCES_LIST_NAME" component="th" noMargin />
            <Text content="SUBSCRIPTION_ALLOWANCES_UNITS_INCLUDED" component="th" noMargin />
            <Text content="SUBSCRIPTION_ALLOWANCES_OVERAGE" component="th" noMargin />
            <Text content="SUBSCRIPTION_ALLOWANCES_ADDEDON" component="th" noMargin />
            <Text content="SUBSCRIPTION_ALLOWANCES_UNITS_USED" component="th" noMargin />
            <Text content="" component="th" shouldTranslate={false} noMargin />
          </tr>
        </thead>
        <tbody>{allowances.map((allowance: Allowance) => (
          <SubscriptionAddOnsTiers
            addOnAllowance={allowance}
            planPricingModel={planPricingModel}
            key={allowance.getId()}
            callback={() => fetchData()}
          />
        ))}
        </tbody>
      </Table>

    </div>
  );
};

export default SubscriptionAllowances;
