import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../../../../..";
import { IPaymentGatewaysReducer } from "../../../../../reducers/paymentGatewaysReducer";
import { IAuthReducerState } from "../../../../../reducers/authReducer";
import { CreateAdyenGatewayRequest, CreateAdyenGatewayResponse, ReplaceGatewayToAdyenRequest } from "../../../../../utils/grpc/generated/Billsby.Protos/billing/private/paymentgateway/payment_gateway_pb";
import { PaymentGatewayServiceClient } from "../../../../../utils/grpc/generated/Billsby.Protos/billing/private/paymentgateway/Payment_gatewayServiceClientPb";
import { grpcUnaryCall } from "../../../../../utils/grpc/grpcUtils";
import { ConfigConstants } from "../../../../../utils/config";
import PaymentGatewayContainer from "../payment-gateways-shared-components/PaymentGatewayContainer";
import AdyenPaymentGatewayForm from "./AdyenPaymentGatewayForm";
import { fetchPaymentGateways, setPaymentGatewaysField } from "../../../../../actions/paymentGatewaysActions";

interface Props {
  onError: () => void;
}

const AdyenPaymentGateway: React.FC<Props> = ({ onError }) => {
  const { selectedPaymentGateway, isCreatingGateway, replacedPaymentGateway } = useSelector<AppState, IPaymentGatewaysReducer>(state => state.paymentGatewaysReducer);
  const { currentCompanyDomain } = useSelector<AppState, IAuthReducerState>(state => state.auth);
  const dispatch = useDispatch<Function>()

  const [helpTitle, setHelpTitle] = useState("PAYMENT_GATEWAYS_ADDED_ADYEN_HELP");
  const [helpContent, setHelpContent] = useState(["PAYMENT_GATEWAYS_ADDED_ADYEN_HELP_CONTENT_1", "PAYMENT_GATEWAYS_ADDED_ADYEN_HELP_CONTENT_2"]);

  if (!selectedPaymentGateway) {
    return null;
  }
  
  const addNewPaymentGateway = async (displayName: string, username: string, password: string, merchantAccount: string, subdomain: string) => {
    const createAdyenGatewayRequest = new CreateAdyenGatewayRequest();
    createAdyenGatewayRequest.setCompanyDomain(currentCompanyDomain);
    createAdyenGatewayRequest.setDisplayName(displayName);
    createAdyenGatewayRequest.setUsername(username);
    createAdyenGatewayRequest.setPassword(password);
    createAdyenGatewayRequest.setMerchantAccount(merchantAccount);
    createAdyenGatewayRequest.setSubdomain(subdomain);
    const paymentGatewayServiceClient = new PaymentGatewayServiceClient(ConfigConstants.grpcBaseUrl);
    let request: Promise<any>
    if(replacedPaymentGateway) {
      const replaceGatewayToAdyenRequest = new ReplaceGatewayToAdyenRequest()
      replaceGatewayToAdyenRequest.setCreateAdyenRequest(createAdyenGatewayRequest);
      replaceGatewayToAdyenRequest.setPaymentGatewayToReplaceId(replacedPaymentGateway.paymentGatewayId as number);
      request = grpcUnaryCall(replaceGatewayToAdyenRequest, paymentGatewayServiceClient, paymentGatewayServiceClient.replaceGatewayToAdyen)
    }
    else {
      request = grpcUnaryCall(createAdyenGatewayRequest, paymentGatewayServiceClient, paymentGatewayServiceClient.createAdyenGateway)
    }
    
    try {
      const data = await request as CreateAdyenGatewayResponse;
      dispatch(setPaymentGatewaysField("createdPaymentGatewayId", data.getPaymentGatewayId()));
      dispatch(setPaymentGatewaysField("selectedPaymentGateway", undefined));
      dispatch(setPaymentGatewaysField("replacedPaymentGateway", undefined));
      await dispatch(fetchPaymentGateways(currentCompanyDomain));
    } catch {
      onError();
    }
  }

  return (
  <PaymentGatewayContainer
    helpTitle={helpTitle}
    helpContent={helpContent}
    description="PAYMENT_GATEWAYS_ADYEN_DESCRIPTION"
    isOneGatewayPending={false}
    currentGatewayState={{ isCollapsed: false }}
    websiteLink="https://www.adyen.com/"
    type="create"
    paymentGateway={selectedPaymentGateway}
  >
    <AdyenPaymentGatewayForm
        onSave={(displayName: string, username: string, password: string, merchantAccount: string, subdomain: string) => addNewPaymentGateway(displayName, username, password, merchantAccount, subdomain)}
        onCancel={() => {
          dispatch(setPaymentGatewaysField("selectedPaymentGateway", undefined));
          dispatch(setPaymentGatewaysField("replacedPaymentGateway", undefined));
        }}
        setHelpTitle={(text: string) => setHelpTitle(text)}
        setHelpContent={(text: Array<string>) => setHelpContent(text)}
        isSaveEnabled={!isCreatingGateway}
        isEdit={false}
        initDisplayName=""
        initUsername=""
        initMerchantAccount=""
        initSubdomain=""
      />
  </PaymentGatewayContainer>
  )
}

export default AdyenPaymentGateway