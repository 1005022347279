/**
 * @fileoverview gRPC-Web generated client stub for webexintegrations.private.webex
 * @enhanceable
 * @public
 */

// GENERATED CODE -- DO NOT EDIT!


/* eslint-disable */
// @ts-nocheck


import * as grpcWeb from 'grpc-web';

import * as webex_pb from './webex_pb';


export class WebexServiceClient {
  client_: grpcWeb.AbstractClientBase;
  hostname_: string;
  credentials_: null | { [index: string]: string; };
  options_: null | { [index: string]: any; };

  constructor (hostname: string,
               credentials?: null | { [index: string]: string; },
               options?: null | { [index: string]: any; }) {
    if (!options) options = {};
    if (!credentials) credentials = {};
    options['format'] = 'text';

    this.client_ = new grpcWeb.GrpcWebClientBase(options);
    this.hostname_ = hostname;
    this.credentials_ = credentials;
    this.options_ = options;
  }

  methodDescriptorCreateIntegration = new grpcWeb.MethodDescriptor(
    '/webexintegrations.private.webex.WebexService/CreateIntegration',
    grpcWeb.MethodType.UNARY,
    webex_pb.CreateIntegrationRequest,
    webex_pb.CreateIntegrationResponse,
    (request: webex_pb.CreateIntegrationRequest) => {
      return request.serializeBinary();
    },
    webex_pb.CreateIntegrationResponse.deserializeBinary
  );

  createIntegration(
    request: webex_pb.CreateIntegrationRequest,
    metadata: grpcWeb.Metadata | null): Promise<webex_pb.CreateIntegrationResponse>;

  createIntegration(
    request: webex_pb.CreateIntegrationRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: webex_pb.CreateIntegrationResponse) => void): grpcWeb.ClientReadableStream<webex_pb.CreateIntegrationResponse>;

  createIntegration(
    request: webex_pb.CreateIntegrationRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: webex_pb.CreateIntegrationResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/webexintegrations.private.webex.WebexService/CreateIntegration',
        request,
        metadata || {},
        this.methodDescriptorCreateIntegration,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/webexintegrations.private.webex.WebexService/CreateIntegration',
    request,
    metadata || {},
    this.methodDescriptorCreateIntegration);
  }

  methodDescriptorDeleteIntegration = new grpcWeb.MethodDescriptor(
    '/webexintegrations.private.webex.WebexService/DeleteIntegration',
    grpcWeb.MethodType.UNARY,
    webex_pb.DeleteIntegrationRequest,
    webex_pb.DeleteIntegrationResponse,
    (request: webex_pb.DeleteIntegrationRequest) => {
      return request.serializeBinary();
    },
    webex_pb.DeleteIntegrationResponse.deserializeBinary
  );

  deleteIntegration(
    request: webex_pb.DeleteIntegrationRequest,
    metadata: grpcWeb.Metadata | null): Promise<webex_pb.DeleteIntegrationResponse>;

  deleteIntegration(
    request: webex_pb.DeleteIntegrationRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: webex_pb.DeleteIntegrationResponse) => void): grpcWeb.ClientReadableStream<webex_pb.DeleteIntegrationResponse>;

  deleteIntegration(
    request: webex_pb.DeleteIntegrationRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: webex_pb.DeleteIntegrationResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/webexintegrations.private.webex.WebexService/DeleteIntegration',
        request,
        metadata || {},
        this.methodDescriptorDeleteIntegration,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/webexintegrations.private.webex.WebexService/DeleteIntegration',
    request,
    metadata || {},
    this.methodDescriptorDeleteIntegration);
  }

}

