import React from "react";
import "./ProgressIndicator.scss";

interface IProgressIndicator {
  color?: "white" | "blue" | "orange";
  type?: "dotted" | "spinner";
  align?: "left" | "center" | "right";
  size?: "small" | "medium" | "large";
  coverage?: "full-content" | "full-screen" | "normal";
  noPadding?: boolean,
  className?: string
}

const ProgressIndicator: React.FC<IProgressIndicator> = React.memo(({ color = "white", type = "dotted", align = "center", size = "medium", coverage = "normal", noPadding = false, className = "" }) => {
  const renderDottedIndicator = () => {
    return (
      <div className={`progress-indicator__dotted progress-indicator__dotted--${color}  progress-indicator__dotted--${size}`}>
        <div className="dot1"></div>
        <div className="dot2"></div>
        <div className="dot3"></div>
      </div>
    );
  };

  const renderSpinnerIndicator = () => {
    return <div className={`progress-indicator__spinner ${className}`}>Loading</div>;
  };

  return (
    <div className={`progress-indicator progress-indicator--align-${align} progress-indicator--${coverage} progress-indicator--${noPadding ? "no-padding" : "with-padding"} ${className}`}>
      {type === "dotted" ? renderDottedIndicator() : renderSpinnerIndicator()}
    </div>
  );
});

export default ProgressIndicator;
