import { BillsbyAction } from "../models/BillsbyAction";
import { AppState } from "..";
import { UPDATE_SUBSCRIPTION_CUSTOM_FIELDS_SUCCESS, FETCH_SUBSCRIPTION_CUSTOM_FIELDS_SUCCESS, SET_SUBSCRIPTION_CUSTOM_FIELDS, RESET_SUBSCRIPTION_CUSTOM_FIELDS } from "../actions/subscriptionCustomFieldsActions";
import { ISubscriptionCustomFields } from "../models/CustomFields";

export interface ISubscriptionCustomFieldsReducerState {
  customFields: Array<ISubscriptionCustomFields>
}

const initialState = {
  customFields: []
}

export let customFieldsInitialState: Array<ISubscriptionCustomFields> = [];

export default function subscriptionCustomFieldsReducer(state: ISubscriptionCustomFieldsReducerState = initialState, action: BillsbyAction, store: AppState) {
  switch (action.type) {
    case FETCH_SUBSCRIPTION_CUSTOM_FIELDS_SUCCESS: {
      customFieldsInitialState = action.response;
      return { ...state, customFields: action.response }
    }

    case UPDATE_SUBSCRIPTION_CUSTOM_FIELDS_SUCCESS: {
      customFieldsInitialState = action.response;
      return { ...state, customFields: action.response };
    }

    case SET_SUBSCRIPTION_CUSTOM_FIELDS: {
      return { ...state, [action.fieldName]: action.fieldValue }
    }

    case RESET_SUBSCRIPTION_CUSTOM_FIELDS: {
      return { ...state, customFields: customFieldsInitialState }
    }
    
    default:
      return state;
  }
}