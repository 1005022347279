import React from "react";
import Panel from "../../../ui/panel/Panel";
import Text from "../../../ui/text/Text";
import ImageSlider from "../../../ui/image-slider/ImageSlider";
import freeAgent from "../../../../images/integrations/free-agent.png";
import image4 from "../../../../images/integrations/Image4.png";
import image5 from "../../../../images/integrations/Image5.png";
import image6 from "../../../../images/integrations/Image6.png";
import "./FreeAgentOverview.scss";

interface IFreeAgentOverview {}

export const FreeAgentOverview: React.FC<IFreeAgentOverview> = () => {
  return (
    <div className="overview">
      <Panel className="overview__free-agent" title="INTEGRATIONS_OVERVIEW_WHAT_IS_FREE_AGENT">
        <div className="overview__free-agent__container">
          <Panel className="overview__free-agent__image-container">
            <img src={freeAgent} className="overview__free-agent__image" alt="no img"/>
          </Panel>
          <div className="overview__free-agent__text">
            <Text content="INTEGRATIONS_OVERVIEW_WHAT_IS_FREE_AGENT_TEXT1" />
            <Text content="INTEGRATIONS_OVERVIEW_WHAT_IS_FREE_AGENT_TEXT2" />
            <Text content="INTEGRATIONS_OVERVIEW_WHAT_IS_FREE_AGENT_TEXT3" />
          </div>
        </div>
      </Panel>
      <ImageSlider images={[image4, image5, image6]} />
    </div>
  )
}

export default FreeAgentOverview;