import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../../../../..";
import { BusinessType } from "../../../../../models/Payment";
import { IAuthReducerState } from "../../../../../reducers/authReducer";
import { IPaymentGatewaysReducer } from "../../../../../reducers/paymentGatewaysReducer";
import API from "../../../../../utils/API";
import { openIntercomIframe, getIpLocation } from "../../../../../utils/commonUtils";
import { COLOR } from "../../../../../utils/constants";
import Button from "../../../../ui/button/Button";
import InteractiveList from "../../../../ui/interactive-list/InteractiveList";
import Panel from "../../../../ui/panel/Panel";
import StatusLabel from "../../../../ui/status-label/StatusLabel";
import Text from "../../../../ui/text/Text";
import "./InteractiveMenuPanel.scss"


interface ICallbackStatus { isRequesting: boolean, isSuccessful: boolean }

const InteractiveMenuPanel: React.FC = () => {
  const { currentCompanyDomain, companyName } = useSelector<AppState, IAuthReducerState>(state => state.auth);
  const { paymentGateways } = useSelector<AppState, IPaymentGatewaysReducer>(state => state.paymentGatewaysReducer);
  const dispatch = useDispatch<Function>()

  const [businessType, setBusinessType] = useState<BusinessType | undefined>(undefined);
  const [callbackStatus, setCallbackStatus] = useState<ICallbackStatus>({ isRequesting: false, isSuccessful: false });
  const [countryCode, setCountryCode] = useState<string | undefined>(undefined);
  
  useEffect(() => {
    getIpLocation().then(res => {setCountryCode(res?.country_code)})
  }, [])

  const requestCallback = async () => {
    setCallbackStatus({ isRequesting: true, isSuccessful: false })
    try {
      await API.requestPaymentCloudCallback(currentCompanyDomain);
      setCallbackStatus({ isRequesting: false, isSuccessful: true })
    } catch(err) {
      console.log(err);
      setCallbackStatus({ isRequesting: false, isSuccessful: false })
    }
  }

  const goBack = () => {
    setBusinessType(undefined);
    setCallbackStatus({ isRequesting: false, isSuccessful: false })
  }

  const renderDefaultPanel = () => {
    return (
      <Panel className="interactive-menu-panel interactive-menu-panel--default" title="INTERACTIVE_MENU_PANEL_DEFAULT_TITLE" translateWith={{ companyName }} ribbonContent="INTERACTIVE_MENU_PANEL_TAG">
        <Text content="INTERACTIVE_MENU_PANEL_DEFAULT_CONTENT"></Text>
        <Text content="INTERACTIVE_MENU_PANEL_DEFAULT_QUESTION_TITLE" weight="bold"></Text>
        <InteractiveList data={[
          {
            title: "INTERACTIVE_MENU_PANEL_DEFAULT_OPT1",
            onClick: () => setBusinessType(BusinessType.PRE_ESTABLISHED)
          },
          {
            title: "INTERACTIVE_MENU_PANEL_DEFAULT_OPT2",
            subTitle: "INTERACTIVE_MENU_PANEL_DEFAULT_OPT2_SUB",
            onClick: () => setBusinessType(BusinessType.HIGH_RISK)
          },
          {
            title: "INTERACTIVE_MENU_PANEL_DEFAULT_OPT3",
            onClick: () => setBusinessType(BusinessType.LOW_RISK)
          }
        ]} />
      </Panel>
    )
  }
  
  const renderPreEstPanel = () => {
    return (
      <Panel className="interactive-menu-panel interactive-menu-panel--pre-established" ribbonContent="INTERACTIVE_MENU_PANEL_TAG">
        <div className="interactive-menu-panel__nav">
          <i className="interactive-menu-panel__nav__back far fa-chevron-left" onClick={goBack}></i>
          <Text className="interactive-menu-panel__nav__title" content="INTERACTIVE_MENU_PANEL_PRE_EST_TITLE" noMargin></Text>
          <StatusLabel color={COLOR.ORANGE} icon="far fa-money-bill-wave" content="INTERACTIVE_MENU_PANEL_PREFERRED_PRICING"></StatusLabel>
        </div>

        <Text content="INTERACTIVE_MENU_PANEL_PRE_EST_CONTENT"></Text>
        <Text content="INTERACTIVE_MENU_PANEL_PRE_EST_QUESTION_TITLE1" weight="bold"></Text>
        <Panel className="interactive-menu-panel__option">
          <div className="interactive-menu-panel__option__content">
            <Text content="INTERACTIVE_MENU_PANEL_PRE_EST_OPT1_TITLE" className="interactive-menu-panel__option__content__title" noMargin></Text>
            <Text content="INTERACTIVE_MENU_PANEL_PRE_EST_OPT1_SUB" className="interactive-menu-panel__option__content__subtitle" noMargin></Text>
          </div>
          <StatusLabel color={COLOR.GREEN} icon="far fa-check-circle" content="INTERACTIVE_MENU_PANEL_ACCEPT_HIGH_RISK"></StatusLabel>
          <Button title={callbackStatus.isSuccessful ? "INTERACTIVE_MENU_PANEL_REQUEST_COMPLETE" : "INTERACTIVE_MENU_PANEL_REQUEST_CALLBACK"} isLoading={callbackStatus.isRequesting} id="request-callback" icon={callbackStatus.isSuccessful ? "far fa-check" : "fas fa-headset"} buttonType={callbackStatus.isSuccessful ? "success" : "add"} onClick={() => !callbackStatus.isSuccessful && requestCallback()}></Button>
        </Panel>
        <Text content="INTERACTIVE_MENU_PANEL_PRE_EST_QUESTION_TITLE2" weight="bold"></Text>
        <Panel className="interactive-menu-panel__option">
          <div className="interactive-menu-panel__option__content">
            <Text content="INTERACTIVE_MENU_PANEL_PRE_EST_OPT2_TITLE" className="interactive-menu-panel__option__content__title" noMargin></Text>
            <Text content="INTERACTIVE_MENU_PANEL_PRE_EST_OPT2_SUB" className="interactive-menu-panel__option__content__subtitle" noMargin></Text>
          </div>
          <Button title="INTERACTIVE_MENU_PANEL_CHAT" id="chat-card-connect" icon="fas fa-comments" buttonType="add" onClick={openIntercomIframe}></Button>
        </Panel>

        <Text content="INTERACTIVE_MENU_PANEL_PRE_EST_QUESTION_TITLE3" weight="bold"></Text>
        <Panel className="interactive-menu-panel__option" noMargin>
          <div className="interactive-menu-panel__option__content">
            <Text content="INTERACTIVE_MENU_PANEL_PRE_EST_OPT3_TITLE" className="interactive-menu-panel__option__content__title" noMargin></Text>
            <Text content="INTERACTIVE_MENU_PANEL_PRE_EST_OPT3_SUB" className="interactive-menu-panel__option__content__subtitle" noMargin></Text>
          </div>
          <StatusLabel color={COLOR.GREEN} icon="far fa-money-bill-wave" content="INTERACTIVE_MENU_PANEL_ACCEPT_HIGH_RISK"></StatusLabel>
          <Button title="INTERACTIVE_MENU_PANEL_CHAT" id="chat-review-service" icon="fas fa-comments" buttonType="add" onClick={openIntercomIframe}></Button>
        </Panel>
      </Panel>
    )
  }

  const renderHighRiskPanel = () => {
    return (
      <Panel className="interactive-menu-panel interactive-menu-panel--high-risk" ribbonContent="INTERACTIVE_MENU_PANEL_TAG">
        <div className="interactive-menu-panel__nav">
          <i className="interactive-menu-panel__nav__back far fa-chevron-left" onClick={goBack}></i>
          <Text className="interactive-menu-panel__nav__title" content="INTERACTIVE_MENU_PANEL_HIGH_RISK_TITLE" noMargin></Text>
        </div>

        <Text content="INTERACTIVE_MENU_PANEL_HIGH_RISK_CONTENT1"></Text>
        <Text content="INTERACTIVE_MENU_PANEL_HIGH_RISK_CONTENT2"></Text>
        <div className="interactive-menu-panel__cards">
          <Panel className="interactive-menu-panel__card" title="INTERACTIVE_MENU_PANEL_HIGH_RISK_CARD1_TITLE" noMargin>
            <Text content="INTERACTIVE_MENU_PANEL_HIGH_RISK_CARD1_CONTENT" noMargin></Text>
          </Panel>
          <div className="interactive-menu-panel__plus"><i className="far fa-plus"></i></div>
          <Panel className="interactive-menu-panel__card" title="INTERACTIVE_MENU_PANEL_HIGH_RISK_CARD2_TITLE" noMargin>
            <Text content="INTERACTIVE_MENU_PANEL_HIGH_RISK_CARD2_CONTENT" noMargin></Text>
          </Panel>
          <div className="interactive-menu-panel__info">
            <StatusLabel color={COLOR.ORANGE} icon="far fa-money-bill-wave" content="INTERACTIVE_MENU_PANEL_PREFERRED_PRICING"></StatusLabel>
            <Text content="INTERACTIVE_MENU_PANEL_HIGH_RISK_PRICING_INFO" noMargin></Text>
          </div>
        </div>

        <Text content="INTERACTIVE_MENU_PANEL_HIGH_RISK_QUESTION_TITLE1" weight="bold"></Text>
        <Panel className="interactive-menu-panel__option" noMargin>
          <div className="interactive-menu-panel__option__content">
            <Text content="INTERACTIVE_MENU_PANEL_HIGH_RISK_OPT1_TITLE" className="interactive-menu-panel__option__content__title" noMargin></Text>
            <Text content="INTERACTIVE_MENU_PANEL_HIGH_RISK_OPT1_SUB" className="interactive-menu-panel__option__content__subtitle" noMargin></Text>
          </div>
          <Button title={callbackStatus.isSuccessful ? "INTERACTIVE_MENU_PANEL_REQUEST_COMPLETE" : "INTERACTIVE_MENU_PANEL_REQUEST_CALLBACK"} isLoading={callbackStatus.isRequesting} id="request-callback" icon={callbackStatus.isSuccessful ? "far fa-check" : "fas fa-headset"} buttonType={callbackStatus.isSuccessful ? "success" : "add"} onClick={() => !callbackStatus.isSuccessful && requestCallback()}></Button>
        </Panel>
      </Panel>
    )
  }

  const renderLowRiskPanel = () => {
    return (
      <Panel className="interactive-menu-panel interactive-menu-panel--low-risk" ribbonContent="INTERACTIVE_MENU_PANEL_TAG">
        <div className="interactive-menu-panel__nav">
          <i className="interactive-menu-panel__nav__back far fa-chevron-left" onClick={goBack}></i>
          <Text className="interactive-menu-panel__nav__title" content="INTERACTIVE_MENU_PANEL_LOW_RISK_TITLE" noMargin></Text>
        </div>

        <Text content="INTERACTIVE_MENU_PANEL_LOW_RISK_CONTENT"></Text>
        <div className="interactive-menu-panel__cards">
          <Panel className="interactive-menu-panel__card" title="INTERACTIVE_MENU_PANEL_LOW_RISK_CARD1_TITLE" noMargin>
            <Text content="INTERACTIVE_MENU_PANEL_LOW_RISK_CARD1_CONTENT" noMargin></Text>
          </Panel>
          <Panel className="interactive-menu-panel__card" style={{ marginLeft: "15px" }} title="INTERACTIVE_MENU_PANEL_LOW_RISK_CARD2_TITLE" noMargin>
            <Text content="INTERACTIVE_MENU_PANEL_LOW_RISK_CARD2_CONTENT" noMargin></Text>
          </Panel>
          <div className="interactive-menu-panel__info">
            <Text content="INTERACTIVE_MENU_PANEL_LOW_RISK_PRICING_INFO" translateWith={{ bold: <Text content="INTERACTIVE_MENU_PANEL_LOW_RISK_PRICING_INFO_BOLD" component="span" weight="bold" noMargin></Text> }} noMargin></Text>
          </div>
        </div>

        <Text content="INTERACTIVE_MENU_PANEL_LOW_RISK_QUESTION_TITLE1" weight="bold"></Text>
        <Panel className="interactive-menu-panel__option">
          <div className="interactive-menu-panel__option__content">
            <Text content="INTERACTIVE_MENU_PANEL_LOW_RISK_OPT1_TITLE" className="interactive-menu-panel__option__content__title" noMargin></Text>
            <Text content="INTERACTIVE_MENU_PANEL_LOW_RISK_OPT1_SUB" className="interactive-menu-panel__option__content__subtitle" noMargin></Text>
          </div>
          <StatusLabel color={COLOR.ORANGE} icon="far fa-money-bill-wave" content="INTERACTIVE_MENU_PANEL_PREFERRED_PRICING"></StatusLabel>
          <Button title={callbackStatus.isSuccessful ? "INTERACTIVE_MENU_PANEL_REQUEST_COMPLETE" : "INTERACTIVE_MENU_PANEL_REQUEST_CALLBACK"} isLoading={callbackStatus.isRequesting} id="request-callback" icon={callbackStatus.isSuccessful ? "far fa-check" : "fas fa-headset"} buttonType={callbackStatus.isSuccessful ? "success" : "add"} onClick={() => !callbackStatus.isSuccessful && requestCallback()}></Button>
        </Panel>
        <Panel className="interactive-menu-panel__option" noMargin>
          <div className="interactive-menu-panel__option__content">
            <Text content="INTERACTIVE_MENU_PANEL_LOW_RISK_OPT2_TITLE" className="interactive-menu-panel__option__content__title" noMargin></Text>
            <Text content="INTERACTIVE_MENU_PANEL_LOW_RISK_OPT2_SUB" className="interactive-menu-panel__option__content__subtitle" noMargin></Text>
          </div>
          <StatusLabel color={COLOR.ORANGE} icon="far fa-money-bill-wave" content="INTERACTIVE_MENU_PANEL_PREFERRED_PRICING"></StatusLabel>
          <Button title="INTERACTIVE_MENU_PANEL_LOW_RISK_APPLY_ONLINE" id="apply-online" icon="fas fa-mouse" buttonType="add" onClick={(evt: any) => {
            evt.preventDefault();
            window.open("https://integrate.clover.com/partner/billsby", "_self")
          }}></Button>
        </Panel>
      </Panel>
    )
  }

  const renderPanelContent = () => {
    if(countryCode !== "US" || (paymentGateways && paymentGateways.length > 0)) {
      return null
    }

    switch (businessType) {
      case BusinessType.PRE_ESTABLISHED:
        return renderPreEstPanel()
      case BusinessType.HIGH_RISK:
        return renderHighRiskPanel()
      case BusinessType.LOW_RISK:
        return renderLowRiskPanel()
      default:
        return renderDefaultPanel()
    }
  }
  
  return renderPanelContent()
}

export default InteractiveMenuPanel