import React, { useEffect, useState } from "react";
import "./Alert.scss";
import Text from "../text/Text";

interface IAlert {
  /**
    * Determines the colour and icon
    */
  type?: "success" | "warning" | "info" | "support" | "custom",
  /**
  * The text to be displayed in the alert
  */
  text: string,
  /**
  * Uses the Translate "with" prop for dynamic translation of the text
  */
  translateWith?: any,
  /**
  * If the alert is a custom one, you can also use a different icon
  */
  customIcon?: string,
  /**
  * Change the element's styles if being used in a modal
  */
  isModal?: boolean,
  /**
  * Use this to dismiss and show the alert
  */
  showAlert?: boolean,
  /**
  * A callback to close the alert
  */
  onClose?: (...args: any) => any,
  /**
   * Whether to translate or not
   */
  shouldTranslate?: boolean,
  /**
   * after how long it disappears
   */
  closingTime?: number,
  /**
   * auto close
   */
  autoClose?: boolean
}

const Alert: React.FC<IAlert> = React.memo((props) => {
  const { type = "success", text, translateWith = {}, shouldTranslate = true, customIcon = "check-circle", isModal, onClose, showAlert = true, closingTime = 2000,
    autoClose = true } = props;
  const modalClass = isModal ? "cc-alert--isModal" : "";
  let alertType;
  let icon;

  const [isVisible, setIsVisible] = useState(true);
  let timeout: any = -1;

  useEffect(() => {
    if(showAlert) {
      setIsVisible(true);
    }
    if (autoClose && (showAlert || isVisible)) {
      clearTimeout(timeout);
      timeout = setTimeout(() => {
        setIsVisible(false);
        if (typeof (onClose) === "function") {
          onClose();
        }
      }, closingTime);
    }
  }, [autoClose, showAlert]);

  switch (type) {
    case "warning":
      alertType = "cc-alert-warning";
      icon = "fas fa-exclamation-circle";
      break;
    case "info":
      alertType = "cc-alert-info";
      icon = "fas fa-info-circle";
      break;
    case "support":
      alertType = "cc-alert-support";
      icon = "fas fa-comments";
      break;
    case "custom":
      alertType = "cc-alert-custom";
      icon = `fas fa-${customIcon}`;
      break;
    default:
      alertType = "cc-alert-success";
      icon = "fas fa-check-circle";
  }

  return (
    <div className={`cc-alert ${alertType} ${(!isVisible || !showAlert) ? "cc-alert--closed" : ""} ${modalClass}`}>
      <div className={`cc-alert-container ${(!isVisible || !showAlert) ? "cc-alert-container--closed" : ""}`}>
        <span className={`cc-alert__icon ${icon}`} aria-hidden="true" />
        <Text
          content={text}
          component="span"
          className={"cc-alert__text"}
          translateWith={translateWith}
          noMargin
          shouldTranslate={shouldTranslate}
        />
        {typeof (onClose) === "function" && <i className="fas fa-times-circle cc-alert__close" onClick={onClose} role="button" />}
      </div>
    </div>
  );
})


export default Alert;
