import React, { useEffect } from "react";
import history from "../../../../../utils/history";
import Panel from "../../../../ui/panel/Panel";
import Text from "../../../../ui/text/Text";
import "./RefundIssuedEmailTemplate.scss";
import { AppState } from "../../../../..";
import { useDispatch, useSelector } from "react-redux";
import { IAuthReducerState } from "../../../../../reducers/authReducer";
import { ICompanyBrandingReducerState } from "../../../../../reducers/companyBrandingReducer";
import { Row, Col } from "react-grid-system";
import EmailPreivewContainer from "../../brand-customization/email-preview/EmailPreivewContainer";
import BackNavigationPanel from "../../../../ui/back-navigation-panel/BackNavigationPanel";
import { IEmailCustomizationReducerState } from "../../../../../reducers/emailCustomizationReducer";
import { resetEmailTemplateValues, getEmailTemplateValues } from "../../../../../actions/emailCustomizationActions";
import { fetchCompanyBranding } from "../../../../../actions/companyBrandingActions";
import EmailFooter from "../email-footer/EmailFooter";
import EmailEditableArea from "../email-editable-area/EmailEditableArea";
import { EmailTypes } from "../../../../../utils/grpc/generated/Billsby.Protos/Messenger/private/emailconfig/emailconfig_pb";

const RefundIssuedEmailTemplate: React.FC = () => {

  const rie_companyBrandingReducer = useSelector<AppState, ICompanyBrandingReducerState>(state => state.companyBrandingReducer);
  const { highlightColor } = rie_companyBrandingReducer;
  const { customArea1, customArea2 } = useSelector<AppState, IEmailCustomizationReducerState>(state => state.emailCustomizationReducer);
  const authReducer = useSelector<AppState, IAuthReducerState>(state => state.auth);
  const { currentCompanyDomain, currentCompanyId, companyName } = authReducer;
  const defaultHighLightColor = "#F87847";
  
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchCompanyBranding(currentCompanyDomain));
    dispatch(getEmailTemplateValues(currentCompanyId as number, companyName, EmailTypes.REFUND_ISSUED));
    return () => { dispatch(resetEmailTemplateValues()) };
  }, []);


  return (
    <div>
      <BackNavigationPanel
        title={"EMAIL_TEMPLATE_REFUND_ISSUED_BACK_PANEL_TITLE"} shouldTranslateTitle={true}
        onClick={() => history.push("/configuration/email-notifications/emails/payment-credits-refund")} />

      <Row>
        <Col sm={8}>
          <Panel className="refund-issued-email-template__panel" noMargin>
            <EmailPreivewContainer>

              <Text
                content={<span dangerouslySetInnerHTML={{__html: customArea1}}></span>}
                shouldTranslate={false}
                className="email-preview__details__text email-preview__details__text-title bar bar-blue"
                component="span"
                noMargin
              />

              <Text
                content="EMAIL_TEMPLATE_REFUND_ISSUED_GREETINGS"
                className="email-preview__details__text"
                component="span"
                noMargin
              />

              <Text
                content={<span dangerouslySetInnerHTML={{__html: customArea2}}></span>}
                shouldTranslate={false}
                className="email-preview__details__text bar bar-orange"
                component="span"
                noMargin
              />

              <Text
                content="EMAIL_TEMPLATE_REFUND_ISSUED_BODY_1"
                className="email-preview__details__text email-preview__details__text-bold"
                component="span"
                noMargin
              />

              <Row>
                <Col md={4}>
                  <Text
                    content="EMAIL_TEMPLATE_REFUND_ISSUED_BODY_2"
                    className="email-preview__details__text email-preview__details__text-no-margin"
                    component="span"
                    noMargin
                  />
                </Col>
                <Col md={8}>
                  <Text
                    content="EMAIL_TEMPLATE_REFUND_ISSUED_BODY_3"
                    className="email-preview__details__text email-preview__details__text-no-margin"
                    component="span"
                    noMargin
                  />
                </Col>
              </Row>

              <Row>
                <Col md={4}>
                  <Text
                    content="EMAIL_TEMPLATE_REFUND_ISSUED_BODY_4"
                    className="email-preview__details__text email-preview__details__text-no-margin"
                    component="span"
                    noMargin
                  />
                </Col>
                <Col md={8}>
                  <Text
                    content="EMAIL_TEMPLATE_REFUND_ISSUED_BODY_5"
                    className="email-preview__details__text email-preview__details__text-no-margin"
                    component="span"
                    noMargin
                  />
                </Col>
              </Row>

              <Row>
                <Col md={4}>
                  <Text
                    content="EMAIL_TEMPLATE_REFUND_ISSUED_BODY_6"
                    className="email-preview__details__text"
                    component="span"
                    noMargin
                  />
                </Col>
                <Col md={8}>
                  <Text
                    content="EMAIL_TEMPLATE_REFUND_ISSUED_BODY_7"
                    className="email-preview__details__text"
                    component="span"
                    noMargin
                  />
                </Col>
              </Row>

              <Text
                content="EMAIL_TEMPLATE_REFUND_ISSUED_BODY_8"
                className="email-preview__details__text"
                component="span"
                noMargin
                style={{ textDecoration: "underline", cursor: "pointer", color: !!highlightColor ? highlightColor : defaultHighLightColor }}
              />

              <Text
                content="EMAIL_TEMPLATE_REFUND_ISSUED_REAGRDS"
                className="email-preview__details__text"
                component="span"
                noMargin
              />

              <Text
                content={companyName}
                className="email-preview__details__text"
                component="span"
                shouldTranslate={false}
                noMargin
              />

              <EmailFooter />

            </EmailPreivewContainer>
          </Panel>
        </Col>
        <Col sm={4}>
          <EmailEditableArea
            id="RefundIssuedEmailTemplate"
            emailCustomCopyOneValue={customArea1}
            emailCustomCopyTwoValue={customArea2}
            emailCustomCopyThreeValue=""
            emailCustomCopyThreeIsVisible={false}
            showCompanyAlertToggle={true}
            showProductAlertToggle={false}
            showPlanAlertToggle={false}
          />
        </Col>
      </Row>
    </div>
  )
}

export default RefundIssuedEmailTemplate;