import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../../../../..";
import { IPaymentGatewaysReducer } from "../../../../../reducers/paymentGatewaysReducer";
import { IAuthReducerState } from "../../../../../reducers/authReducer";
import { setPaymentGatewaysField, fetchPaymentGateways } from "../../../../../actions/paymentGatewaysActions";
import { CreateCheckoutDotComGatewayRequest, CreateCheckoutDotComGatewayResponse, ReplaceGatewayToCheckoutDotComRequest } from "../../../../../utils/grpc/generated/Billsby.Protos/billing/private/paymentgateway/payment_gateway_pb";
import { PaymentGatewayServiceClient } from "../../../../../utils/grpc/generated/Billsby.Protos/billing/private/paymentgateway/Payment_gatewayServiceClientPb";
import { grpcUnaryCall } from "../../../../../utils/grpc/grpcUtils";
import { ConfigConstants } from "../../../../../utils/config";
import PaymentGatewayContainer from "../payment-gateways-shared-components/PaymentGatewayContainer";
import CheckoutPaymentGatewayForm from "./CheckoutPaymentGatewayForm";

interface Props {
  onError: () => void;
}

const CheckoutPaymentGateway: React.FC<Props> = ({ onError }) => {
  const { selectedPaymentGateway, isCreatingGateway, replacedPaymentGateway } = useSelector<AppState, IPaymentGatewaysReducer>(state => state.paymentGatewaysReducer);
  const { currentCompanyDomain } = useSelector<AppState, IAuthReducerState>(state => state.auth);
  const dispatch = useDispatch<Function>()

  const [helpTitle, setHelpTitle] = useState("PAYMENT_GATEWAYS_ADDED_CHECKOUT_HELP_TITLE");
  const [helpContent, setHelpContent] = useState(["PAYMENT_GATEWAYS_ADDED_CHECKOUT_HELP_CONTENT_1", "PAYMENT_GATEWAYS_ADDED_CHECKOUT_HELP_CONTENT_2"]);

  if (!selectedPaymentGateway) {
    return null;
  }

  const addNewPaymentGateway = async (displayName: string, secretKey: string) => {
    const createCheckoutDotComGatewayRequest = new CreateCheckoutDotComGatewayRequest();
    createCheckoutDotComGatewayRequest.setCompanyDomain(currentCompanyDomain);
    createCheckoutDotComGatewayRequest.setDisplayName(displayName);
    createCheckoutDotComGatewayRequest.setSecretKey(secretKey);
    const paymentGatewayServiceClient = new PaymentGatewayServiceClient(ConfigConstants.grpcBaseUrl);
    let request: Promise<any>
    if(replacedPaymentGateway) {
      const replaceGatewayToCheckoutDotComRequest = new ReplaceGatewayToCheckoutDotComRequest()
      replaceGatewayToCheckoutDotComRequest.setCreateCheckoutGateway(createCheckoutDotComGatewayRequest);
      replaceGatewayToCheckoutDotComRequest.setPaymentGatewayToReplaceId(replacedPaymentGateway.paymentGatewayId as number);
      request = grpcUnaryCall(replaceGatewayToCheckoutDotComRequest, paymentGatewayServiceClient, paymentGatewayServiceClient.replaceGatewayToCheckoutDotCom)
    }
    else {
      request = grpcUnaryCall(createCheckoutDotComGatewayRequest, paymentGatewayServiceClient, paymentGatewayServiceClient.createCheckoutDotComGateway)
    }
    
    try {
      const data = await request as CreateCheckoutDotComGatewayResponse;
      dispatch(setPaymentGatewaysField("createdPaymentGatewayId", data.getPaymentGatewayId()));
      dispatch(setPaymentGatewaysField("selectedPaymentGateway", undefined));
      dispatch(setPaymentGatewaysField("replacedPaymentGateway", undefined));
      await dispatch(fetchPaymentGateways(currentCompanyDomain));
    } catch {
      onError();
    }
  }

  return (    
    <PaymentGatewayContainer
      helpTitle={helpTitle}
      helpContent={helpContent}
      description="PAYMENT_GATEWAYS_CHECKOUT_DESCRIPTION"
      isOneGatewayPending={false}
      currentGatewayState={{ isCollapsed: false }}
      websiteLink="https://checkout.com/"
      type="create"
      paymentGateway={selectedPaymentGateway}
    >
      <CheckoutPaymentGatewayForm
        onSave={(displayName: string, secretKey: string) =>  addNewPaymentGateway(displayName, secretKey)}
        onCancel={() => {
          dispatch(setPaymentGatewaysField("selectedPaymentGateway", undefined));
          dispatch(setPaymentGatewaysField("replacedPaymentGateway", undefined));
        }}
        setHelpTitle={(text: string) => setHelpTitle(text)}
        setHelpContent={(text: Array<string>) => setHelpContent(text)}
        initDisplayName=""
        isSaveEnabled={!isCreatingGateway}
        isEdit={false}
      />
    </PaymentGatewayContainer>
  )
}

export default CheckoutPaymentGateway
