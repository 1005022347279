import React, { useState } from "react";
import NavItem from "./SideNavItem";
import Panel from "../../ui/panel/Panel";
import "./SideNavBar.scss";
import { ISideNavItems } from "../../../models/SideNavItem";
import AddTooltip from "../../add-tooltip/AddTooltip";

const Nav: React.FC<ISideNavItems> = ({ navItems }) => {
  const [selectedItem, setSelectedItem] = useState("");

  return (
    <Panel className="side_navbar" >
      <div className="side_navbar__content">
        <div className="sidenavbar__content-body">
          {navItems.map(({ isVisible = true, isEnabled = true, shouldTranslate = true, hasPermission, ...item }, index) => {
            return isVisible && (
              <AddTooltip id={`${item.name}${index}`} showTooltip={!hasPermission}>
                <NavItem
                  key={index}
                  icon={item.icon}
                  shouldTranslate={shouldTranslate}
                  content={item.text}
                  isEnabled={isEnabled && hasPermission}
                  isActive={item.name === selectedItem}
                  setSelectedItem={() => setSelectedItem(item.name)}
                  callBack={item.callback}
                  className={item.className}
                />
              </AddTooltip>
            )
          })}
        </div>
      </div>
    </Panel>
  )
}

export default Nav;