import React, { useLayoutEffect, useState, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../../index";
import CustomersWelcome from "../../components/customers/CustomersWelcome";
import PageWrapper from "../../components/ui/layout/PageWrapper";
import counterpart from "counterpart";
import Helmet from "react-helmet";
import NavigationAlertCurrencyMismatch from "../../components/ui/navigation-alert/NavigationAlertCurrencyMismatch";
import "./Customers.scss";
import { fetchCustomers, setCustomersField, resetCustomers } from "../../actions/customersActions";
import { IAuthReducerState } from "../../reducers/authReducer";
import { ICustomersReducer } from "../../reducers/customersReducer";
import Table, { TableArrowBtn } from "../../components/ui/table/Table";
import Text from "../../components/ui/text/Text";
import moment from "moment";
import StatusLabel from "../../components/ui/status-label/StatusLabel";
import { COLOR } from "../../utils/constants";
import Button from "../../components/ui/button/Button";
import { CardStatusType, CustomerStatusType, PaymentSourceType } from "../../models/Customers";
import Search from "../../components/search/Search";
import Pagination from "../../components/ui/pagination/Pagination";
import history from "../../utils/history";
import Modal from "../../components/modal/Modal";
import AddCustomer from "../../components/add-customer/AddCustomer";
import NoticePanel from "../../components/ui/notice-panel/NoticePanel";
import ProgressIndicator from "../../components/ui/progress-indicator/ProgressIndicator";
import ErrorNotification from "../../components/ui/error-notification/ErrorNotification";
import FilterDropdown from "../../components/ui/filter-dropdown/FilterDropdown";
import ErrorPanel from "../../components/ui/error-panel/ErrorPanel";
import FilterSort from "../../components/ui/filter-sort/FilterSort";
import CircleIcon from "../../components/ui/circle-icon/CirlcleIcon";
import { RouteComponentProps } from "react-router";
import NavigationBar from "../../components/ui/navigation-bar/NavigationBar";
import { GetCollectCompanyNameRequest, GetCollectCompanyNameResponse, CollectCompanyNameType } from "../../utils/grpc/generated/Billsby.Protos/core/private/companies/company_pb";
import { grpcUnaryCall } from "../../utils/grpc/grpcUtils";
import { CompanyServiceClient } from "../../utils/grpc/generated/Billsby.Protos/core/private/companies/CompanyServiceClientPb";
import { ConfigConstants } from "../../utils/config";
import ExportData from "../../components/export-data/ExportData";
import { Permission } from "../../models/Auth";
import Tooltip from "../../components/ui/tooltip/Tooltip";
import useCheckFeaturePermission from "../../utils/hooks/useCheckFeaturePermission";

interface ICustomersProps extends RouteComponentProps{}

export const Customers: React.FC<ICustomersProps> = ({ location }) => {
  const { readmeioLinks, currentCompanyDomain, currentCompanyId, parsedToken } = useSelector<AppState, IAuthReducerState>(state => state.auth);
  const { customersPaginated, pageNotification, searchedCustomer, isLoading, isFirstSearch, isCreatedOnDescendingOrder, createCustomerError, customerStatus,
    cardStatus } = useSelector<AppState, ICustomersReducer>(state =>state.customersReducer);
  const { currentPage, pageCount, rowCount, results } = customersPaginated;
  const dispatch = useDispatch<Function>();
  const isCustomerAddPermitted = useCheckFeaturePermission(Permission.CustomerAdd);

  const [showAddCustomerModal, setShowAddCustomerModal] = useState(false);
  const [showExportCustomersModal, setShowExportCustomersModal] = useState(false);
  const [collectCompanyNameType, setCollectCompanyNameType] = useState<CollectCompanyNameType>(CollectCompanyNameType.DO_NOT_COLLECT);

  
  const hasPermissionDataExport = useMemo(() => {
    if(parsedToken?.CurrentPermissions.some(p => p === Permission[Permission.DataExportsCsv])) {
      return true
    }
    return false
  }, [parsedToken])

  const filterOptions = [
    { label: "CUSTOMERS_LIST_TABLE_ACTIVE", value: CustomerStatusType.ACTIVE },
    { label: "CUSTOMERS_LIST_TABLE_INACTIVE", value: CustomerStatusType.INACTIVE }
  ]

  const filterCardOptions = [
    { label: "CUSTOMERS_LIST_TABLE_ACTIVE_CARD", value: CardStatusType.ACTIVE },
    { label: "CUSTOMERS_LIST_TABLE_EXPIRED_CARD", value: CardStatusType.EXPIRED },
    { label: "CUSTOMERS_LIST_TABLE_ACH_MANDATE_ADDED", value: CardStatusType.ACH },
    { label: "CUSTOMERS_LIST_TABLE_NO_CARD", value: CardStatusType.NO_CARD },
  ]

  const isComingFromDashboard = useMemo(() => {
    return (location as any)?.state?.from?.includes("/home");
  }, [location]);

  useEffect(() => {
    if (createCustomerError) {
      setShowAddCustomerModal(false)
    }
  }, [createCustomerError])

  const handleUpdateCustomers = async () => {
    setShowAddCustomerModal(false);
    await dispatch(fetchCustomers(currentCompanyDomain, currentPage, undefined, searchedCustomer, customerStatus?.value, isCreatedOnDescendingOrder, cardStatus?.value));
  };

  useLayoutEffect(() => {
    const fetchData = async () => {
      const companyServiceClient = new CompanyServiceClient(ConfigConstants.grpcBaseUrl);

      try {
        if(!results) {
          // we won't refetch the customers if we're coming back from the customer details page
          await dispatch(fetchCustomers(currentCompanyDomain, currentPage, undefined, searchedCustomer, customerStatus?.value, isCreatedOnDescendingOrder, cardStatus?.value));
        }
        const request = new GetCollectCompanyNameRequest();
        currentCompanyId && request.setCompanyId(currentCompanyId);
        const response = await grpcUnaryCall(request, companyServiceClient, companyServiceClient.getCollectCompanyName) as GetCollectCompanyNameResponse;
        setCollectCompanyNameType(response?.getCollectCompanyNameType?.());

      } catch (err) {
        console.log(err);
      }
    }
    fetchData()
    
    return function cleanup() {
      !window.location.href?.includes("/customers") && dispatch(resetCustomers());
    };
  }, []);

  const renderMigrationBanner = () => {
    if (rowCount >= 50) {
      return null
    }
    return (
      <ErrorPanel
        title="MIGRATE_CUSTOMERS_HELP_TITLE"
        subtitle="MIGRATE_CUSTOMERS_HELP_CONTENT"
        icon="fas fa-person-dolly"
      />
    )
  }

  if (!results) {
    return <ProgressIndicator color="blue" coverage='full-content' />
  }


  if (!results.length && isFirstSearch) {
    return (
      <div className="customers">
        <Helmet title={counterpart("NAVBAR_CUSTOMERS")} />
        <NavigationAlertCurrencyMismatch />
        <PageWrapper>
          {pageNotification && <NoticePanel isModal className="customers__notice-panel" type={pageNotification.type} icon={pageNotification.icon} content={pageNotification.content}></NoticePanel>}
          {isComingFromDashboard
            && <NavigationBar
              pageTitle=""
              previousPageTitle="DASHBOARD_STATS_SCREEN_TITLE"
              previousPageCb={() => history.push("/home")}
            />}
          <CustomersWelcome readMeBillsbyApiLink={readmeioLinks.api} readMeBillsbyCheckoutLink={readmeioLinks.checkout} addCustomer={() => setShowAddCustomerModal(true)} />
          {renderMigrationBanner()}
        </PageWrapper>
        <Modal
          isOpen={showAddCustomerModal}
          title="ADD_CUSTOMER_MODAL_HEADER"
          onRequestClose={() => setShowAddCustomerModal(false)}
          children={<AddCustomer collectCompanyNameType={collectCompanyNameType} handleAddCustomerCallback={handleUpdateCustomers} />}
        ></Modal>
      </div>
    );
  }

  return (
    <div className="customers">
      <Helmet title={counterpart("NAVBAR_CUSTOMERS")} />
      <NavigationAlertCurrencyMismatch />
      {isComingFromDashboard
        && <NavigationBar
          className="customers__navigation"
          pageTitle=""
          previousPageTitle="DASHBOARD_STATS_SCREEN_TITLE"
          previousPageCb={() => history.push("/home")}
        />}
      <div className="customers__header">
        <div className="customers__search">
          <Text content="CUSTOMERS_LIST_NUMBER" translateWith={{ nrCustomers: rowCount }} noMargin />
          <Search
            className="customers__search-bar"
            value={searchedCustomer}
            placeholder="CUSTOMERS_SEARCH_PLACEHOLDER"
            onChange={(evt: any) => dispatch(setCustomersField("searchedCustomer", evt.target.value))}
            onSearch={() => dispatch(fetchCustomers(currentCompanyDomain, 1, undefined, searchedCustomer, customerStatus?.value, isCreatedOnDescendingOrder, cardStatus?.value))}
          />
          <div className="customers__add-btn__container">
            <Button 
            disabled={!isCustomerAddPermitted} 
            tooltipMessage={!isCustomerAddPermitted ? "NO_ACCESS_MSG" : undefined} 
            id="customers-add" className="customers__add-btn" title="CUSTOMERS_LIST_TABLE_ADD_CUSTOMER" onClick={() => setShowAddCustomerModal(true)} buttonType="add" icon="far fa-user-plus" />
          </div>
          <div className="customers__export-btn__container" data-tip data-for="permission-warning-tooltip">
            <Button 
              id="customers-export"     
              buttonType="add"
              className="customers__export-btn" 
              title="EXPORT_CUSTOMER_DATA"
              disabled={!hasPermissionDataExport}
              onClick={() => setShowExportCustomersModal(true)} 
              icon="far fa-file-download"
            />
            {
              !hasPermissionDataExport &&
              <Tooltip id="permission-warning-tooltip" place="bottom" type="blue">
                <Text content="EXPORT_CUSTOMER_DATA_WARNING_PERMISSION" noMargin />
              </Tooltip>
            }
          </div>
          <Pagination
            className="customers__search__pagination"
            pageCount={pageCount}
            isVisible={pageCount > 1}
            forcePage={currentPage - 1}
            onPageChange={({ selected }) => {
              dispatch(setCustomersField("customersPaginated", { ...customersPaginated, currentPage: selected + 1 }))
              dispatch(fetchCustomers(currentCompanyDomain, selected + 1, undefined, searchedCustomer, customerStatus?.value, isCreatedOnDescendingOrder, cardStatus?.value))
            }}
          />
        </div>
      </div>
      {
        isLoading
          ? <ProgressIndicator color="blue" coverage='full-content' />
          : (
            <PageWrapper>
              {pageNotification && <NoticePanel isModal className="customers__notice-panel" type={pageNotification.type} icon={pageNotification.icon} content={pageNotification.content} translateWith={pageNotification.translateWith}></NoticePanel>}
              <Table enableHoverEffect className="customers__table">
                <thead>
                  <tr>
                    <Text content="CUSTOMERS_LIST_TABLE_EMAIL" component="th" noMargin />
                    <Text content="CUSTOMERS_LIST_TABLE_FULLNAME" component="th" noMargin />
                    <FilterSort className="customers__date-sort" label="CUSTOMERS_LIST_TABLE_CREATEDON" component="th" isDescendingOrder={isCreatedOnDescendingOrder}
                      onClick={(bool) => { 
                        dispatch(setCustomersField("isCreatedOnDescendingOrder", bool));
                        dispatch(fetchCustomers(currentCompanyDomain, currentPage, undefined, searchedCustomer, customerStatus?.value, bool, cardStatus?.value))
                      }}
                    />
                    <FilterDropdown label="CUSTOMERS_LIST_TABLE_STATUS" 
                      component="th"
                      className="customers__status-filter"
                      options={filterOptions}
                      selected={customerStatus}
                      onSelect={(filter) => {
                        dispatch(setCustomersField("customerStatus", filter));
                        dispatch(fetchCustomers(currentCompanyDomain, 1, undefined, searchedCustomer, filter.value, isCreatedOnDescendingOrder, cardStatus?.value))
                      }}
                      onClear={() => {
                        dispatch(setCustomersField("customerStatus", undefined));
                        dispatch(fetchCustomers(currentCompanyDomain, 1, undefined, searchedCustomer, undefined, isCreatedOnDescendingOrder, cardStatus?.value))
                      }}
                    />

                    <FilterDropdown label="CUSTOMERS_LIST_TABLE_CARD_STATUS" 
                      component="th"
                      className="customers__status__card-filter"
                      options={filterCardOptions}
                      selected={cardStatus}
                      onSelect={(filter) => {
                        dispatch(setCustomersField("cardStatus", filter));
                        dispatch(fetchCustomers(currentCompanyDomain, 1, undefined, searchedCustomer, customerStatus?.value, isCreatedOnDescendingOrder, filter.value))
                      }}
                      onClear={() => {
                        dispatch(setCustomersField("cardStatus", undefined));
                        dispatch(fetchCustomers(currentCompanyDomain, 1, undefined, searchedCustomer, customerStatus?.value, isCreatedOnDescendingOrder, undefined))
                      }}
                    />
                    
                    <Text content="CUSTOMERS_LIST_TABLE_CUSTOMERID" component="th" noMargin />
                    <Text className="view-arrow" content="" component="th" shouldTranslate={false} noMargin />
                  </tr>
                </thead>
                <tbody>
                  {results.map(customer => (
                    <tr className="customers__table__item" key={`customers-table-${(Math.random() * 10) + customer.customerUniqueId}`} onClick={() => history.push(`/customers/${customer.customerUniqueId}`)} >
                      <td>{customer.email}</td>
                      <td>
                        {customer.firstName} {customer.lastName}
                      </td>
                      <td>{moment.utc(new Date(customer.createdOn)).format("DD MMM YYYY")}</td>
                      <td>
                        <StatusLabel
                          content={customer.status === CustomerStatusType.ACTIVE ? "CUSTOMERS_LIST_TABLE_ACTIVE" : "CUSTOMERS_LIST_TABLE_INACTIVE"}
                          color={customer.status === CustomerStatusType.ACTIVE ? COLOR.GREEN : COLOR.RED}
                        />
                      </td>
                      <td>
                        { customer.paymentSourceType === PaymentSourceType.Ach 
                          ? 
                          <CircleIcon
                            title={counterpart("CUSTOMERS_LIST_TABLE_ACH_MANDATE_ADDED")}
                            icon="fas fa-university" 
                            className="customers__table__item__card--active"
                            />
                          :
                          <CircleIcon
                            title={customer.isNoCard ? counterpart("CUSTOMERS_LIST_TABLE_NO_CARD") : customer.isCardExpired ? counterpart("CUSTOMERS_LIST_TABLE_EXPIRED_CARD") : counterpart("CUSTOMERS_LIST_TABLE_ACTIVE_CARD")}
                            icon="far fa-credit-card" 
                            className={`customers__table__item__card--${customer.isNoCard ? "no-card" : customer.isCardExpired ? "expired" : "active"}`}
                          />
                        }
                      </td>
                      <td>{customer.customerUniqueId}</td>
                      <TableArrowBtn link={true} to={`/customers/${customer.customerUniqueId}`} />
                    </tr>
                  ))}
                </tbody>
              </Table>
              {renderMigrationBanner()}
            </PageWrapper>
          )
      }
      <Modal
        isOpen={showAddCustomerModal}
        title="ADD_CUSTOMER_MODAL_HEADER"
        onRequestClose={() => setShowAddCustomerModal(false)}
        children={<AddCustomer collectCompanyNameType={collectCompanyNameType} handleAddCustomerCallback={handleUpdateCustomers} />}
      ></Modal>

      <Modal
        isOpen={showExportCustomersModal}
        title="EXPORT_CUSTOMER_DATA"
        onRequestClose={() => setShowExportCustomersModal(false)}
        children={
        <ExportData
          title="EXPORT_CUSTOMER_DATA_PANEL_TITLE"
          content="EXPORT_CUSTOMER_DATA_PANEL_CONTENT"
          type="customers"
          companyId={currentCompanyId}
          callback={() => {
            setShowExportCustomersModal(false);
            dispatch(setCustomersField("pageNotification", { type: "success", icon: "fas fa-check-circle", content: "EXPORT_DATE_SUCCESS_NOTIFICATION", translateWith: { email: parsedToken?.email } }));
          }}
        />}
      />

      {createCustomerError && <ErrorNotification title="ADD_CUSTOMER_ERROR" showAlert={createCustomerError}></ErrorNotification>}
    </div>
  );
};

export default Customers
