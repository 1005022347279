import React from "react";
import "./CustomersWelcome.scss";

import Panel from "../ui/panel/Panel";
import Text from "../ui/text/Text";
import Button from "../ui/button/Button";
import CirlcleIcon from "../ui/circle-icon/CirlcleIcon";
import Helmet from "react-helmet";
import useCheckFeaturePermission from "../../utils/hooks/useCheckFeaturePermission";
import { Permission } from "../../models/Auth";

export interface ICustomersWelcome {
  readMeBillsbyApiLink: string
  readMeBillsbyCheckoutLink: string,
  addCustomer: () => void
}

export const CustomersWelcome = ({ readMeBillsbyApiLink, readMeBillsbyCheckoutLink, addCustomer}: ICustomersWelcome) => {
  const isCustomerAddPermitted = useCheckFeaturePermission(Permission.CustomerAdd);
  return (
    <div>
		<Helmet title="Customers"/>
    <div className="customers-welcome">
     <Panel className="customers-welcome__greeting" title="CUSTOMERS_WELCOME_TITLE">
      <Text content="CUSTOMERS_WELCOME_TEXT" />
      <Panel className="customers-welcome__greeting-instructions" title="CUSTOMERS_WELCOME_INSTRUCTIONS_TITLE">
        <div className="customers-welcome__greeting-instruction">
          <CirlcleIcon nr={1} icon={"far fa-code"} className={"customers-welcome__greeting-icon"} />
          <Text content="CUSTOMERS_WELCOME_INSTRUCTIONS1_TEXT1" noMargin component="span"/>
          <a href={readMeBillsbyApiLink}>
            <Text content="CUSTOMERS_WELCOME_INSTRUCTIONS1_LINK" noMargin component="span"/>
          </a>
          <Text content="CUSTOMERS_WELCOME_INSTRUCTIONS1_TEXT2" noMargin component="span"/>
        </div>
        <div className="customers-welcome__greeting-instruction">          
          <CirlcleIcon nr={1} icon={"far fa-shopping-cart"} className={"customers-welcome__greeting-icon"} />          
          <Text content="CUSTOMERS_WELCOME_INSTRUCTIONS2_TEXT1" noMargin component="span"/>
          <a href={readMeBillsbyCheckoutLink}>
            <Text content="CUSTOMERS_WELCOME_INSTRUCTIONS2_LINK" noMargin component="span"/>
          </a>
          <Text content="CUSTOMERS_WELCOME_INSTRUCTIONS2_TEXT2" noMargin component="span"/>
        </div>
        <div className="customers-welcome__greeting-instruction">
          <CirlcleIcon nr={1} icon={"far fa-pencil"} className={"customers-welcome__greeting-icon"} />
          <Text content="CUSTOMERS_WELCOME_INSTRUCTIONS3_TEXT1" noMargin component="span"/>
          <Button disabled={!isCustomerAddPermitted} tooltipMessage={!isCustomerAddPermitted ? "NO_ACCESS_MSG" : undefined} id="customers-welcome" icon="far fa-user-plus" buttonType="add" title="CUSTOMERS_WELCOME_INSTRUCTIONS3_BUTTON" className="customers-welcome__greeting-button" onClick={addCustomer}/>
          <Text content="CUSTOMERS_WELCOME_INSTRUCTIONS3_TEXT2" noMargin component="span"/>
        </div>
      </Panel>
     </Panel>
    </div>
    </div>
  )
}

export default CustomersWelcome;