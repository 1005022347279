import React from "react";
import Select from "react-select";
import "./CheckoutDropdown.scss";
import { ActionMeta, ValueType } from "react-select/lib/types";


interface ICheckoutDropdown {
  [key: string]: any,
  options: Array<{ label: string, value: any }>
  onChange?: (item: ValueType<{ label: string, value: string }>, action: ActionMeta) => void
  menuIsOpen?: boolean
}


const CheckoutDropdown: React.FC<ICheckoutDropdown> = ({ dividerAfter, className = "", onChange, menuIsOpen, options = [], ...rest }) => {

  return (
    <Select
      {...rest}
      menuIsOpen={menuIsOpen}
      onChange={onChange}
      options={options}
      className={`checkout-dropdown ${className}`}
      classNamePrefix="custom-select"
    />
  )

}



export default CheckoutDropdown;
