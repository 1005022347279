import React, { useState, useMemo } from "react";
import counterpart from "counterpart";
import { Link } from "react-router-dom";
import history from "../../utils/history";
import { useActiveLinks } from "../../utils/custom-hooks";

interface INavItem {
  icon: string, 
  children?: any,
  content: string,
  className?: string,
  id?: string,
  targets?: Array<string>,
  shouldTranslate?: boolean,
  isEnabled?: boolean,
  dataFor?: string,
  isExpanded?: boolean,
  hasError?: boolean,
  isVisible?: boolean
  setIsExpanded?: (isExpanded: boolean) => void
}

const defaultSetIsExpanded = () => {
  return;
}

const NavItem = React.memo((props: INavItem) => {
  const { isVisible = true, icon, children, content, id = "", targets = [], shouldTranslate = true, isEnabled = false, dataFor, isExpanded = false, setIsExpanded = () => defaultSetIsExpanded(), className = "", hasError = false } = props;
  //const [isOpen, setIsOpen] = useState(false);
  const [isActive, setIsActive] = useState(false);

  useActiveLinks(history, setIsActive, targets, setIsExpanded);

  const errorClass = useMemo(() => `${hasError ? "error" : ""}`, [hasError])

  if(!isVisible) {
    return null
  }

  return ( 
    children ?
      <div className={`navbar__content-body__item ${!isEnabled ? "navbar__content-body__item--locked" : "" } ${className}`}>
        <button className="navbar__content-body__item-line"
          id={`${id}-btn`}
          onClick={() => { isEnabled && setIsExpanded(!isExpanded) }}
          aria-controls={id}
          aria-expanded={isExpanded}
          data-tip data-for={dataFor} 
          data-event="click"
        >
          <i className={isActive ? icon + " link-active" : icon} />
          <div className={`navbar__content-body__item-name ${errorClass}`} >
            {hasError && <i className={"navbar__content-body__item__error fas fa-map-marker-exclamation"}></i>}
            { counterpart(content) }
          </div>
          { isEnabled && <div className="navbar__content-body__item-arrow" role="presentation" />}
        </button>
        <div
          className={ isExpanded ? "navbar__content-body__item-links" : "navbar__content-body__item-links navbar__content-body__item-links--hidden" } 
          id={id} 
        >
          { children }
        </div>
      </div>
      :
      (shouldTranslate ? 
        <div className={`navbar__content-body__item ${!isEnabled ? "navbar__content-body__item--locked" : "" } ${className}`}>
          <Link 
            className={isActive ? "navbar__content-body__item-line link-active" : "navbar__content-body__item-line"}
            id={id} 
            to={isEnabled ? targets[0] : "#"}
          > 
            <i className={icon} />
            <div className={`navbar__content-body__item-name ${errorClass}`}>
              {hasError && <i className={"navbar__content-body__item__error fas fa-map-marker-exclamation"}></i>}
              { counterpart(content) }
            </div>
          </Link>
        </div>
        : 
        <div className={`navbar__content-body__item ${!isEnabled ? "navbar__content-body__item--locked" : "" } ${className}`}>
          <button className="navbar__content-body__item-line" data-tip data-for={dataFor} data-event="click">
            <i className={icon} />
            <div className={`navbar__content-body__item-name ${errorClass}`}>
              {hasError && <i className={"navbar__content-body__item__error fas fa-map-marker-exclamation"}></i>}
              { content }
            </div>
            <i className="navbar__content-body__item-dots far fa-ellipsis-h" />
          </button>
        </div>
      ) 
  )
})

export default NavItem