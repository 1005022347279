import API from "../utils/API";
import { makeActionCreator } from "../utils/reduxUtils";

export const FETCH_CREDIT_NOTE_DETAILS_REQUEST = "FETCH_CREDIT_NOTE_DETAILS_REQUEST";
export const FETCH_CREDIT_NOTE_DETAILS_SUCCESS = "FETCH_CREDIT_NOTE_DETAILS_SUCCESS";
export const FETCH_CREDIT_NOTE_DETAILS_FAILURE = "FETCH_CREDIT_NOTE_DETAILS_FAILURE";

export const PUT_CREDIT_NOTE_PAYMENT_REQUEST = "PUT_CREDIT_NOTE_PAYMENT_REQUEST";
export const PUT_CREDIT_NOTE_PAYMENT_SUCCESS = "PUT_CREDIT_NOTE_PAYMENT_SUCCESS";
export const PUT_CREDIT_NOTE_PAYMENT_FAILURE = "PUT_CREDIT_NOTE_PAYMENT_FAILURE";

export const RESET_CREDIT_NOTE_DETAILS = "RESET_CREDIT_NOTE_DETAILS";
export const resetCreditNoteDetails = makeActionCreator(RESET_CREDIT_NOTE_DETAILS, "payload");

export const putCreditNotePayment = function (companyDomain: string, creditNoteNumber: string) {
  return {
    types: [PUT_CREDIT_NOTE_PAYMENT_REQUEST, PUT_CREDIT_NOTE_PAYMENT_SUCCESS, PUT_CREDIT_NOTE_PAYMENT_FAILURE],
    callAPI: () => API.putCreditNotePayment(companyDomain, creditNoteNumber)
  }
}

export const fetchCreditNotesDetails = function (creditNoteNumber: string) {
  return {
    types: [FETCH_CREDIT_NOTE_DETAILS_REQUEST, FETCH_CREDIT_NOTE_DETAILS_SUCCESS, FETCH_CREDIT_NOTE_DETAILS_FAILURE],
    callAPI: () => API.fetchInvoiceStandalone(creditNoteNumber)
  }
}