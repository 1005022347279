/**
 * @fileoverview gRPC-Web generated client stub for accountingintegrations.private.accounts
 * @enhanceable
 * @public
 */

// GENERATED CODE -- DO NOT EDIT!


import * as grpcWeb from "grpc-web";


import {
  CreateFreeAgentIntegrationRequest,
  CreateFreeAgentIntegrationResponse,
  CreateQuickBooksIntegrationRequest,
  CreateQuickBooksIntegrationResponse,
  DeleteIntegrationRequest,
  DeleteIntegrationResponse,
  GetCompanyQuickBooksAccountsRequest,
  GetCompanyQuickBooksAccountsResponse,
  GetQuickBooksAccountsRequest,
  GetQuickBooksAccountsResponse,
  UpdateCompanyQuickBooksAccountsRequest,
  UpdateCompanyQuickBooksAccountsResponse} from "./accounts_pb";

export class AccountingIntegrationServiceClient {
  client_: grpcWeb.AbstractClientBase;
  hostname_: string;
  credentials_: null | { [index: string]: string; };
  options_: null | { [index: string]: string; };

  constructor (hostname: string,
               credentials?: null | { [index: string]: string; },
               options?: null | { [index: string]: string; }) {
    if (!options) options = {};
    if (!credentials) credentials = {};
    options["format"] = "text";

    this.client_ = new grpcWeb.GrpcWebClientBase(options);
    this.hostname_ = hostname;
    this.credentials_ = credentials;
    this.options_ = options;
  }

  methodInfoCreateFreeAgentIntegration = new grpcWeb.AbstractClientBase.MethodInfo(
    CreateFreeAgentIntegrationResponse,
    (request: CreateFreeAgentIntegrationRequest) => {
      return request.serializeBinary();
    },
    CreateFreeAgentIntegrationResponse.deserializeBinary
  );

  createFreeAgentIntegration(
    request: CreateFreeAgentIntegrationRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: CreateFreeAgentIntegrationResponse) => void) {
    return this.client_.rpcCall(
      this.hostname_ +
        "/accountingintegrations.private.accounts.AccountingIntegrationService/CreateFreeAgentIntegration",
      request,
      metadata || {},
      this.methodInfoCreateFreeAgentIntegration,
      callback);
  }

  methodInfoCreateQuickBooksIntegration = new grpcWeb.AbstractClientBase.MethodInfo(
    CreateQuickBooksIntegrationResponse,
    (request: CreateQuickBooksIntegrationRequest) => {
      return request.serializeBinary();
    },
    CreateQuickBooksIntegrationResponse.deserializeBinary
  );

  createQuickBooksIntegration(
    request: CreateQuickBooksIntegrationRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: CreateQuickBooksIntegrationResponse) => void) {
    return this.client_.rpcCall(
      this.hostname_ +
        "/accountingintegrations.private.accounts.AccountingIntegrationService/CreateQuickBooksIntegration",
      request,
      metadata || {},
      this.methodInfoCreateQuickBooksIntegration,
      callback);
  }

  methodInfoGetQuickBooksAccounts = new grpcWeb.AbstractClientBase.MethodInfo(
    GetQuickBooksAccountsResponse,
    (request: GetQuickBooksAccountsRequest) => {
      return request.serializeBinary();
    },
    GetQuickBooksAccountsResponse.deserializeBinary
  );

  getQuickBooksAccounts(
    request: GetQuickBooksAccountsRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: GetQuickBooksAccountsResponse) => void) {
    return this.client_.rpcCall(
      this.hostname_ +
        "/accountingintegrations.private.accounts.AccountingIntegrationService/GetQuickBooksAccounts",
      request,
      metadata || {},
      this.methodInfoGetQuickBooksAccounts,
      callback);
  }

  methodInfoGetCompanyQuickBooksAccounts = new grpcWeb.AbstractClientBase.MethodInfo(
    GetCompanyQuickBooksAccountsResponse,
    (request: GetCompanyQuickBooksAccountsRequest) => {
      return request.serializeBinary();
    },
    GetCompanyQuickBooksAccountsResponse.deserializeBinary
  );

  getCompanyQuickBooksAccounts(
    request: GetCompanyQuickBooksAccountsRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: GetCompanyQuickBooksAccountsResponse) => void) {
    return this.client_.rpcCall(
      this.hostname_ +
        "/accountingintegrations.private.accounts.AccountingIntegrationService/GetCompanyQuickBooksAccounts",
      request,
      metadata || {},
      this.methodInfoGetCompanyQuickBooksAccounts,
      callback);
  }

  methodInfoUpdateCompanyQuickBooksAccounts = new grpcWeb.AbstractClientBase.MethodInfo(
    UpdateCompanyQuickBooksAccountsResponse,
    (request: UpdateCompanyQuickBooksAccountsRequest) => {
      return request.serializeBinary();
    },
    UpdateCompanyQuickBooksAccountsResponse.deserializeBinary
  );

  updateCompanyQuickBooksAccounts(
    request: UpdateCompanyQuickBooksAccountsRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: UpdateCompanyQuickBooksAccountsResponse) => void) {
    return this.client_.rpcCall(
      this.hostname_ +
        "/accountingintegrations.private.accounts.AccountingIntegrationService/UpdateCompanyQuickBooksAccounts",
      request,
      metadata || {},
      this.methodInfoUpdateCompanyQuickBooksAccounts,
      callback);
  }

  methodInfoDeleteIntegration = new grpcWeb.AbstractClientBase.MethodInfo(
    DeleteIntegrationResponse,
    (request: DeleteIntegrationRequest) => {
      return request.serializeBinary();
    },
    DeleteIntegrationResponse.deserializeBinary
  );

  deleteIntegration(
    request: DeleteIntegrationRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: DeleteIntegrationResponse) => void) {
    return this.client_.rpcCall(
      this.hostname_ +
        "/accountingintegrations.private.accounts.AccountingIntegrationService/DeleteIntegration",
      request,
      metadata || {},
      this.methodInfoDeleteIntegration,
      callback);
  }

}

