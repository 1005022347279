import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { isRegistrationFromInvitationQueryString } from "../commonUtils";
import { storeRemove } from "../storeUtils";
import { BILLSBY_AUTH_DATA_CONTROL, BILLSBY_SELECTED_COMPANY } from "../constants";
import { resetAuthState } from "../../actions/authActions";

const useRegistrationFromInvitation = () => {
    const dispatch = useDispatch<Function>()
  
    useEffect(() => {
      if(isRegistrationFromInvitationQueryString()) {
        // if the user is trying to register from an invitation we must force a log-out if there's any active session
        storeRemove(BILLSBY_SELECTED_COMPANY);
        storeRemove(BILLSBY_AUTH_DATA_CONTROL);
        dispatch(resetAuthState());
      }
    }, [])
  
    return null
  }

export default useRegistrationFromInvitation;