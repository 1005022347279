/* eslint-disable */
// source: companyconfig.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = Function('return this')();

goog.exportSymbol('proto.messenger.private.companyconfig.EmailNotificationConfig', null, global);
goog.exportSymbol('proto.messenger.private.companyconfig.EmailTypes', null, global);
goog.exportSymbol('proto.messenger.private.companyconfig.GetAchMicroDepositEmailConfigRequest', null, global);
goog.exportSymbol('proto.messenger.private.companyconfig.GetAchMicroDepositEmailConfigResponse', null, global);
goog.exportSymbol('proto.messenger.private.companyconfig.GetCompanyAlertsAndNotificationRequest', null, global);
goog.exportSymbol('proto.messenger.private.companyconfig.GetCompanyAlertsAndNotificationResponse', null, global);
goog.exportSymbol('proto.messenger.private.companyconfig.GetEmailNotificationStatusRequest', null, global);
goog.exportSymbol('proto.messenger.private.companyconfig.GetEmailNotificationStatusResponse', null, global);
goog.exportSymbol('proto.messenger.private.companyconfig.GetEmailTemplateConfigRequest', null, global);
goog.exportSymbol('proto.messenger.private.companyconfig.GetEmailTemplateConfigResponse', null, global);
goog.exportSymbol('proto.messenger.private.companyconfig.UpdateAchMicroDepositEmailConfigRequest', null, global);
goog.exportSymbol('proto.messenger.private.companyconfig.UpdateAchMicroDepositEmailConfigResponse', null, global);
goog.exportSymbol('proto.messenger.private.companyconfig.UpdateCompanyAlertsAndNotificationRequest', null, global);
goog.exportSymbol('proto.messenger.private.companyconfig.UpdateCompanyAlertsAndNotificationResponse', null, global);
goog.exportSymbol('proto.messenger.private.companyconfig.UpdateEmailNotificationStatusRequest', null, global);
goog.exportSymbol('proto.messenger.private.companyconfig.UpdateEmailNotificationStatusResponse', null, global);
goog.exportSymbol('proto.messenger.private.companyconfig.UpsertEmailTemplateConfigRequest', null, global);
goog.exportSymbol('proto.messenger.private.companyconfig.UpsertEmailTemplateConfigResponse', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.messenger.private.companyconfig.UpsertEmailTemplateConfigRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.messenger.private.companyconfig.UpsertEmailTemplateConfigRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.messenger.private.companyconfig.UpsertEmailTemplateConfigRequest.displayName = 'proto.messenger.private.companyconfig.UpsertEmailTemplateConfigRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.messenger.private.companyconfig.UpsertEmailTemplateConfigResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.messenger.private.companyconfig.UpsertEmailTemplateConfigResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.messenger.private.companyconfig.UpsertEmailTemplateConfigResponse.displayName = 'proto.messenger.private.companyconfig.UpsertEmailTemplateConfigResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.messenger.private.companyconfig.GetEmailTemplateConfigRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.messenger.private.companyconfig.GetEmailTemplateConfigRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.messenger.private.companyconfig.GetEmailTemplateConfigRequest.displayName = 'proto.messenger.private.companyconfig.GetEmailTemplateConfigRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.messenger.private.companyconfig.GetEmailTemplateConfigResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.messenger.private.companyconfig.GetEmailTemplateConfigResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.messenger.private.companyconfig.GetEmailTemplateConfigResponse.displayName = 'proto.messenger.private.companyconfig.GetEmailTemplateConfigResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.messenger.private.companyconfig.GetEmailNotificationStatusRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.messenger.private.companyconfig.GetEmailNotificationStatusRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.messenger.private.companyconfig.GetEmailNotificationStatusRequest.displayName = 'proto.messenger.private.companyconfig.GetEmailNotificationStatusRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.messenger.private.companyconfig.GetEmailNotificationStatusResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.messenger.private.companyconfig.GetEmailNotificationStatusResponse.repeatedFields_, null);
};
goog.inherits(proto.messenger.private.companyconfig.GetEmailNotificationStatusResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.messenger.private.companyconfig.GetEmailNotificationStatusResponse.displayName = 'proto.messenger.private.companyconfig.GetEmailNotificationStatusResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.messenger.private.companyconfig.EmailNotificationConfig = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.messenger.private.companyconfig.EmailNotificationConfig, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.messenger.private.companyconfig.EmailNotificationConfig.displayName = 'proto.messenger.private.companyconfig.EmailNotificationConfig';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.messenger.private.companyconfig.UpdateEmailNotificationStatusRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.messenger.private.companyconfig.UpdateEmailNotificationStatusRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.messenger.private.companyconfig.UpdateEmailNotificationStatusRequest.displayName = 'proto.messenger.private.companyconfig.UpdateEmailNotificationStatusRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.messenger.private.companyconfig.UpdateEmailNotificationStatusResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.messenger.private.companyconfig.UpdateEmailNotificationStatusResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.messenger.private.companyconfig.UpdateEmailNotificationStatusResponse.displayName = 'proto.messenger.private.companyconfig.UpdateEmailNotificationStatusResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.messenger.private.companyconfig.UpdateCompanyAlertsAndNotificationRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.messenger.private.companyconfig.UpdateCompanyAlertsAndNotificationRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.messenger.private.companyconfig.UpdateCompanyAlertsAndNotificationRequest.displayName = 'proto.messenger.private.companyconfig.UpdateCompanyAlertsAndNotificationRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.messenger.private.companyconfig.UpdateCompanyAlertsAndNotificationResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.messenger.private.companyconfig.UpdateCompanyAlertsAndNotificationResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.messenger.private.companyconfig.UpdateCompanyAlertsAndNotificationResponse.displayName = 'proto.messenger.private.companyconfig.UpdateCompanyAlertsAndNotificationResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.messenger.private.companyconfig.GetCompanyAlertsAndNotificationRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.messenger.private.companyconfig.GetCompanyAlertsAndNotificationRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.messenger.private.companyconfig.GetCompanyAlertsAndNotificationRequest.displayName = 'proto.messenger.private.companyconfig.GetCompanyAlertsAndNotificationRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.messenger.private.companyconfig.GetCompanyAlertsAndNotificationResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.messenger.private.companyconfig.GetCompanyAlertsAndNotificationResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.messenger.private.companyconfig.GetCompanyAlertsAndNotificationResponse.displayName = 'proto.messenger.private.companyconfig.GetCompanyAlertsAndNotificationResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.messenger.private.companyconfig.GetAchMicroDepositEmailConfigRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.messenger.private.companyconfig.GetAchMicroDepositEmailConfigRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.messenger.private.companyconfig.GetAchMicroDepositEmailConfigRequest.displayName = 'proto.messenger.private.companyconfig.GetAchMicroDepositEmailConfigRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.messenger.private.companyconfig.GetAchMicroDepositEmailConfigResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.messenger.private.companyconfig.GetAchMicroDepositEmailConfigResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.messenger.private.companyconfig.GetAchMicroDepositEmailConfigResponse.displayName = 'proto.messenger.private.companyconfig.GetAchMicroDepositEmailConfigResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.messenger.private.companyconfig.UpdateAchMicroDepositEmailConfigRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.messenger.private.companyconfig.UpdateAchMicroDepositEmailConfigRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.messenger.private.companyconfig.UpdateAchMicroDepositEmailConfigRequest.displayName = 'proto.messenger.private.companyconfig.UpdateAchMicroDepositEmailConfigRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.messenger.private.companyconfig.UpdateAchMicroDepositEmailConfigResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.messenger.private.companyconfig.UpdateAchMicroDepositEmailConfigResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.messenger.private.companyconfig.UpdateAchMicroDepositEmailConfigResponse.displayName = 'proto.messenger.private.companyconfig.UpdateAchMicroDepositEmailConfigResponse';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.messenger.private.companyconfig.UpsertEmailTemplateConfigRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.messenger.private.companyconfig.UpsertEmailTemplateConfigRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.messenger.private.companyconfig.UpsertEmailTemplateConfigRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.messenger.private.companyconfig.UpsertEmailTemplateConfigRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    companyId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    includeAdvert: jspb.Message.getBooleanFieldWithDefault(msg, 2, false),
    advertisementImageUrl: jspb.Message.getFieldWithDefault(msg, 3, ""),
    advertisementUrl: jspb.Message.getFieldWithDefault(msg, 4, ""),
    footerText: jspb.Message.getFieldWithDefault(msg, 5, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.messenger.private.companyconfig.UpsertEmailTemplateConfigRequest}
 */
proto.messenger.private.companyconfig.UpsertEmailTemplateConfigRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.messenger.private.companyconfig.UpsertEmailTemplateConfigRequest;
  return proto.messenger.private.companyconfig.UpsertEmailTemplateConfigRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.messenger.private.companyconfig.UpsertEmailTemplateConfigRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.messenger.private.companyconfig.UpsertEmailTemplateConfigRequest}
 */
proto.messenger.private.companyconfig.UpsertEmailTemplateConfigRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setCompanyId(value);
      break;
    case 2:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIncludeAdvert(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setAdvertisementImageUrl(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setAdvertisementUrl(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setFooterText(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.messenger.private.companyconfig.UpsertEmailTemplateConfigRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.messenger.private.companyconfig.UpsertEmailTemplateConfigRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.messenger.private.companyconfig.UpsertEmailTemplateConfigRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.messenger.private.companyconfig.UpsertEmailTemplateConfigRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCompanyId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getIncludeAdvert();
  if (f) {
    writer.writeBool(
      2,
      f
    );
  }
  f = message.getAdvertisementImageUrl();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getAdvertisementUrl();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getFooterText();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
};


/**
 * optional int32 company_id = 1;
 * @return {number}
 */
proto.messenger.private.companyconfig.UpsertEmailTemplateConfigRequest.prototype.getCompanyId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.messenger.private.companyconfig.UpsertEmailTemplateConfigRequest} returns this
 */
proto.messenger.private.companyconfig.UpsertEmailTemplateConfigRequest.prototype.setCompanyId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional bool include_advert = 2;
 * @return {boolean}
 */
proto.messenger.private.companyconfig.UpsertEmailTemplateConfigRequest.prototype.getIncludeAdvert = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
};


/**
 * @param {boolean} value
 * @return {!proto.messenger.private.companyconfig.UpsertEmailTemplateConfigRequest} returns this
 */
proto.messenger.private.companyconfig.UpsertEmailTemplateConfigRequest.prototype.setIncludeAdvert = function(value) {
  return jspb.Message.setProto3BooleanField(this, 2, value);
};


/**
 * optional string advertisement_image_url = 3;
 * @return {string}
 */
proto.messenger.private.companyconfig.UpsertEmailTemplateConfigRequest.prototype.getAdvertisementImageUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.messenger.private.companyconfig.UpsertEmailTemplateConfigRequest} returns this
 */
proto.messenger.private.companyconfig.UpsertEmailTemplateConfigRequest.prototype.setAdvertisementImageUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string advertisement_url = 4;
 * @return {string}
 */
proto.messenger.private.companyconfig.UpsertEmailTemplateConfigRequest.prototype.getAdvertisementUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.messenger.private.companyconfig.UpsertEmailTemplateConfigRequest} returns this
 */
proto.messenger.private.companyconfig.UpsertEmailTemplateConfigRequest.prototype.setAdvertisementUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string footer_text = 5;
 * @return {string}
 */
proto.messenger.private.companyconfig.UpsertEmailTemplateConfigRequest.prototype.getFooterText = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.messenger.private.companyconfig.UpsertEmailTemplateConfigRequest} returns this
 */
proto.messenger.private.companyconfig.UpsertEmailTemplateConfigRequest.prototype.setFooterText = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.messenger.private.companyconfig.UpsertEmailTemplateConfigResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.messenger.private.companyconfig.UpsertEmailTemplateConfigResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.messenger.private.companyconfig.UpsertEmailTemplateConfigResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.messenger.private.companyconfig.UpsertEmailTemplateConfigResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    companyId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    includeAdvert: jspb.Message.getBooleanFieldWithDefault(msg, 2, false),
    advertisementImageUrl: jspb.Message.getFieldWithDefault(msg, 3, ""),
    advertisementUrl: jspb.Message.getFieldWithDefault(msg, 4, ""),
    footerText: jspb.Message.getFieldWithDefault(msg, 5, ""),
    defaultFooterText: jspb.Message.getFieldWithDefault(msg, 6, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.messenger.private.companyconfig.UpsertEmailTemplateConfigResponse}
 */
proto.messenger.private.companyconfig.UpsertEmailTemplateConfigResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.messenger.private.companyconfig.UpsertEmailTemplateConfigResponse;
  return proto.messenger.private.companyconfig.UpsertEmailTemplateConfigResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.messenger.private.companyconfig.UpsertEmailTemplateConfigResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.messenger.private.companyconfig.UpsertEmailTemplateConfigResponse}
 */
proto.messenger.private.companyconfig.UpsertEmailTemplateConfigResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setCompanyId(value);
      break;
    case 2:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIncludeAdvert(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setAdvertisementImageUrl(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setAdvertisementUrl(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setFooterText(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setDefaultFooterText(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.messenger.private.companyconfig.UpsertEmailTemplateConfigResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.messenger.private.companyconfig.UpsertEmailTemplateConfigResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.messenger.private.companyconfig.UpsertEmailTemplateConfigResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.messenger.private.companyconfig.UpsertEmailTemplateConfigResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCompanyId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getIncludeAdvert();
  if (f) {
    writer.writeBool(
      2,
      f
    );
  }
  f = message.getAdvertisementImageUrl();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getAdvertisementUrl();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getFooterText();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getDefaultFooterText();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
};


/**
 * optional int32 company_id = 1;
 * @return {number}
 */
proto.messenger.private.companyconfig.UpsertEmailTemplateConfigResponse.prototype.getCompanyId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.messenger.private.companyconfig.UpsertEmailTemplateConfigResponse} returns this
 */
proto.messenger.private.companyconfig.UpsertEmailTemplateConfigResponse.prototype.setCompanyId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional bool include_advert = 2;
 * @return {boolean}
 */
proto.messenger.private.companyconfig.UpsertEmailTemplateConfigResponse.prototype.getIncludeAdvert = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
};


/**
 * @param {boolean} value
 * @return {!proto.messenger.private.companyconfig.UpsertEmailTemplateConfigResponse} returns this
 */
proto.messenger.private.companyconfig.UpsertEmailTemplateConfigResponse.prototype.setIncludeAdvert = function(value) {
  return jspb.Message.setProto3BooleanField(this, 2, value);
};


/**
 * optional string advertisement_image_url = 3;
 * @return {string}
 */
proto.messenger.private.companyconfig.UpsertEmailTemplateConfigResponse.prototype.getAdvertisementImageUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.messenger.private.companyconfig.UpsertEmailTemplateConfigResponse} returns this
 */
proto.messenger.private.companyconfig.UpsertEmailTemplateConfigResponse.prototype.setAdvertisementImageUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string advertisement_url = 4;
 * @return {string}
 */
proto.messenger.private.companyconfig.UpsertEmailTemplateConfigResponse.prototype.getAdvertisementUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.messenger.private.companyconfig.UpsertEmailTemplateConfigResponse} returns this
 */
proto.messenger.private.companyconfig.UpsertEmailTemplateConfigResponse.prototype.setAdvertisementUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string footer_text = 5;
 * @return {string}
 */
proto.messenger.private.companyconfig.UpsertEmailTemplateConfigResponse.prototype.getFooterText = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.messenger.private.companyconfig.UpsertEmailTemplateConfigResponse} returns this
 */
proto.messenger.private.companyconfig.UpsertEmailTemplateConfigResponse.prototype.setFooterText = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string default_footer_text = 6;
 * @return {string}
 */
proto.messenger.private.companyconfig.UpsertEmailTemplateConfigResponse.prototype.getDefaultFooterText = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.messenger.private.companyconfig.UpsertEmailTemplateConfigResponse} returns this
 */
proto.messenger.private.companyconfig.UpsertEmailTemplateConfigResponse.prototype.setDefaultFooterText = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.messenger.private.companyconfig.GetEmailTemplateConfigRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.messenger.private.companyconfig.GetEmailTemplateConfigRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.messenger.private.companyconfig.GetEmailTemplateConfigRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.messenger.private.companyconfig.GetEmailTemplateConfigRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    companyId: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.messenger.private.companyconfig.GetEmailTemplateConfigRequest}
 */
proto.messenger.private.companyconfig.GetEmailTemplateConfigRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.messenger.private.companyconfig.GetEmailTemplateConfigRequest;
  return proto.messenger.private.companyconfig.GetEmailTemplateConfigRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.messenger.private.companyconfig.GetEmailTemplateConfigRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.messenger.private.companyconfig.GetEmailTemplateConfigRequest}
 */
proto.messenger.private.companyconfig.GetEmailTemplateConfigRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setCompanyId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.messenger.private.companyconfig.GetEmailTemplateConfigRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.messenger.private.companyconfig.GetEmailTemplateConfigRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.messenger.private.companyconfig.GetEmailTemplateConfigRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.messenger.private.companyconfig.GetEmailTemplateConfigRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCompanyId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
};


/**
 * optional int32 company_id = 1;
 * @return {number}
 */
proto.messenger.private.companyconfig.GetEmailTemplateConfigRequest.prototype.getCompanyId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.messenger.private.companyconfig.GetEmailTemplateConfigRequest} returns this
 */
proto.messenger.private.companyconfig.GetEmailTemplateConfigRequest.prototype.setCompanyId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.messenger.private.companyconfig.GetEmailTemplateConfigResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.messenger.private.companyconfig.GetEmailTemplateConfigResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.messenger.private.companyconfig.GetEmailTemplateConfigResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.messenger.private.companyconfig.GetEmailTemplateConfigResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    companyId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    includeAdvert: jspb.Message.getBooleanFieldWithDefault(msg, 2, false),
    advertisementImageUrl: jspb.Message.getFieldWithDefault(msg, 3, ""),
    advertisementUrl: jspb.Message.getFieldWithDefault(msg, 4, ""),
    footerText: jspb.Message.getFieldWithDefault(msg, 5, ""),
    defaultFooterText: jspb.Message.getFieldWithDefault(msg, 6, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.messenger.private.companyconfig.GetEmailTemplateConfigResponse}
 */
proto.messenger.private.companyconfig.GetEmailTemplateConfigResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.messenger.private.companyconfig.GetEmailTemplateConfigResponse;
  return proto.messenger.private.companyconfig.GetEmailTemplateConfigResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.messenger.private.companyconfig.GetEmailTemplateConfigResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.messenger.private.companyconfig.GetEmailTemplateConfigResponse}
 */
proto.messenger.private.companyconfig.GetEmailTemplateConfigResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setCompanyId(value);
      break;
    case 2:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIncludeAdvert(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setAdvertisementImageUrl(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setAdvertisementUrl(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setFooterText(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setDefaultFooterText(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.messenger.private.companyconfig.GetEmailTemplateConfigResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.messenger.private.companyconfig.GetEmailTemplateConfigResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.messenger.private.companyconfig.GetEmailTemplateConfigResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.messenger.private.companyconfig.GetEmailTemplateConfigResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCompanyId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getIncludeAdvert();
  if (f) {
    writer.writeBool(
      2,
      f
    );
  }
  f = message.getAdvertisementImageUrl();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getAdvertisementUrl();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getFooterText();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getDefaultFooterText();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
};


/**
 * optional int32 company_id = 1;
 * @return {number}
 */
proto.messenger.private.companyconfig.GetEmailTemplateConfigResponse.prototype.getCompanyId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.messenger.private.companyconfig.GetEmailTemplateConfigResponse} returns this
 */
proto.messenger.private.companyconfig.GetEmailTemplateConfigResponse.prototype.setCompanyId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional bool include_advert = 2;
 * @return {boolean}
 */
proto.messenger.private.companyconfig.GetEmailTemplateConfigResponse.prototype.getIncludeAdvert = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
};


/**
 * @param {boolean} value
 * @return {!proto.messenger.private.companyconfig.GetEmailTemplateConfigResponse} returns this
 */
proto.messenger.private.companyconfig.GetEmailTemplateConfigResponse.prototype.setIncludeAdvert = function(value) {
  return jspb.Message.setProto3BooleanField(this, 2, value);
};


/**
 * optional string advertisement_image_url = 3;
 * @return {string}
 */
proto.messenger.private.companyconfig.GetEmailTemplateConfigResponse.prototype.getAdvertisementImageUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.messenger.private.companyconfig.GetEmailTemplateConfigResponse} returns this
 */
proto.messenger.private.companyconfig.GetEmailTemplateConfigResponse.prototype.setAdvertisementImageUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string advertisement_url = 4;
 * @return {string}
 */
proto.messenger.private.companyconfig.GetEmailTemplateConfigResponse.prototype.getAdvertisementUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.messenger.private.companyconfig.GetEmailTemplateConfigResponse} returns this
 */
proto.messenger.private.companyconfig.GetEmailTemplateConfigResponse.prototype.setAdvertisementUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string footer_text = 5;
 * @return {string}
 */
proto.messenger.private.companyconfig.GetEmailTemplateConfigResponse.prototype.getFooterText = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.messenger.private.companyconfig.GetEmailTemplateConfigResponse} returns this
 */
proto.messenger.private.companyconfig.GetEmailTemplateConfigResponse.prototype.setFooterText = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string default_footer_text = 6;
 * @return {string}
 */
proto.messenger.private.companyconfig.GetEmailTemplateConfigResponse.prototype.getDefaultFooterText = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.messenger.private.companyconfig.GetEmailTemplateConfigResponse} returns this
 */
proto.messenger.private.companyconfig.GetEmailTemplateConfigResponse.prototype.setDefaultFooterText = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.messenger.private.companyconfig.GetEmailNotificationStatusRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.messenger.private.companyconfig.GetEmailNotificationStatusRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.messenger.private.companyconfig.GetEmailNotificationStatusRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.messenger.private.companyconfig.GetEmailNotificationStatusRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    companyId: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.messenger.private.companyconfig.GetEmailNotificationStatusRequest}
 */
proto.messenger.private.companyconfig.GetEmailNotificationStatusRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.messenger.private.companyconfig.GetEmailNotificationStatusRequest;
  return proto.messenger.private.companyconfig.GetEmailNotificationStatusRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.messenger.private.companyconfig.GetEmailNotificationStatusRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.messenger.private.companyconfig.GetEmailNotificationStatusRequest}
 */
proto.messenger.private.companyconfig.GetEmailNotificationStatusRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setCompanyId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.messenger.private.companyconfig.GetEmailNotificationStatusRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.messenger.private.companyconfig.GetEmailNotificationStatusRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.messenger.private.companyconfig.GetEmailNotificationStatusRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.messenger.private.companyconfig.GetEmailNotificationStatusRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCompanyId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
};


/**
 * optional int32 company_id = 1;
 * @return {number}
 */
proto.messenger.private.companyconfig.GetEmailNotificationStatusRequest.prototype.getCompanyId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.messenger.private.companyconfig.GetEmailNotificationStatusRequest} returns this
 */
proto.messenger.private.companyconfig.GetEmailNotificationStatusRequest.prototype.setCompanyId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.messenger.private.companyconfig.GetEmailNotificationStatusResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.messenger.private.companyconfig.GetEmailNotificationStatusResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.messenger.private.companyconfig.GetEmailNotificationStatusResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.messenger.private.companyconfig.GetEmailNotificationStatusResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.messenger.private.companyconfig.GetEmailNotificationStatusResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    emailNotificationConfigsList: jspb.Message.toObjectList(msg.getEmailNotificationConfigsList(),
    proto.messenger.private.companyconfig.EmailNotificationConfig.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.messenger.private.companyconfig.GetEmailNotificationStatusResponse}
 */
proto.messenger.private.companyconfig.GetEmailNotificationStatusResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.messenger.private.companyconfig.GetEmailNotificationStatusResponse;
  return proto.messenger.private.companyconfig.GetEmailNotificationStatusResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.messenger.private.companyconfig.GetEmailNotificationStatusResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.messenger.private.companyconfig.GetEmailNotificationStatusResponse}
 */
proto.messenger.private.companyconfig.GetEmailNotificationStatusResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.messenger.private.companyconfig.EmailNotificationConfig;
      reader.readMessage(value,proto.messenger.private.companyconfig.EmailNotificationConfig.deserializeBinaryFromReader);
      msg.addEmailNotificationConfigs(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.messenger.private.companyconfig.GetEmailNotificationStatusResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.messenger.private.companyconfig.GetEmailNotificationStatusResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.messenger.private.companyconfig.GetEmailNotificationStatusResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.messenger.private.companyconfig.GetEmailNotificationStatusResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getEmailNotificationConfigsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.messenger.private.companyconfig.EmailNotificationConfig.serializeBinaryToWriter
    );
  }
};


/**
 * repeated EmailNotificationConfig email_notification_configs = 1;
 * @return {!Array<!proto.messenger.private.companyconfig.EmailNotificationConfig>}
 */
proto.messenger.private.companyconfig.GetEmailNotificationStatusResponse.prototype.getEmailNotificationConfigsList = function() {
  return /** @type{!Array<!proto.messenger.private.companyconfig.EmailNotificationConfig>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.messenger.private.companyconfig.EmailNotificationConfig, 1));
};


/**
 * @param {!Array<!proto.messenger.private.companyconfig.EmailNotificationConfig>} value
 * @return {!proto.messenger.private.companyconfig.GetEmailNotificationStatusResponse} returns this
*/
proto.messenger.private.companyconfig.GetEmailNotificationStatusResponse.prototype.setEmailNotificationConfigsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.messenger.private.companyconfig.EmailNotificationConfig=} opt_value
 * @param {number=} opt_index
 * @return {!proto.messenger.private.companyconfig.EmailNotificationConfig}
 */
proto.messenger.private.companyconfig.GetEmailNotificationStatusResponse.prototype.addEmailNotificationConfigs = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.messenger.private.companyconfig.EmailNotificationConfig, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.messenger.private.companyconfig.GetEmailNotificationStatusResponse} returns this
 */
proto.messenger.private.companyconfig.GetEmailNotificationStatusResponse.prototype.clearEmailNotificationConfigsList = function() {
  return this.setEmailNotificationConfigsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.messenger.private.companyconfig.EmailNotificationConfig.prototype.toObject = function(opt_includeInstance) {
  return proto.messenger.private.companyconfig.EmailNotificationConfig.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.messenger.private.companyconfig.EmailNotificationConfig} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.messenger.private.companyconfig.EmailNotificationConfig.toObject = function(includeInstance, msg) {
  var f, obj = {
    emailType: jspb.Message.getFieldWithDefault(msg, 1, 0),
    isEnabled: jspb.Message.getBooleanFieldWithDefault(msg, 2, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.messenger.private.companyconfig.EmailNotificationConfig}
 */
proto.messenger.private.companyconfig.EmailNotificationConfig.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.messenger.private.companyconfig.EmailNotificationConfig;
  return proto.messenger.private.companyconfig.EmailNotificationConfig.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.messenger.private.companyconfig.EmailNotificationConfig} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.messenger.private.companyconfig.EmailNotificationConfig}
 */
proto.messenger.private.companyconfig.EmailNotificationConfig.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.messenger.private.companyconfig.EmailTypes} */ (reader.readEnum());
      msg.setEmailType(value);
      break;
    case 2:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsEnabled(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.messenger.private.companyconfig.EmailNotificationConfig.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.messenger.private.companyconfig.EmailNotificationConfig.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.messenger.private.companyconfig.EmailNotificationConfig} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.messenger.private.companyconfig.EmailNotificationConfig.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getEmailType();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getIsEnabled();
  if (f) {
    writer.writeBool(
      2,
      f
    );
  }
};


/**
 * optional EmailTypes email_type = 1;
 * @return {!proto.messenger.private.companyconfig.EmailTypes}
 */
proto.messenger.private.companyconfig.EmailNotificationConfig.prototype.getEmailType = function() {
  return /** @type {!proto.messenger.private.companyconfig.EmailTypes} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.messenger.private.companyconfig.EmailTypes} value
 * @return {!proto.messenger.private.companyconfig.EmailNotificationConfig} returns this
 */
proto.messenger.private.companyconfig.EmailNotificationConfig.prototype.setEmailType = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional bool is_enabled = 2;
 * @return {boolean}
 */
proto.messenger.private.companyconfig.EmailNotificationConfig.prototype.getIsEnabled = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
};


/**
 * @param {boolean} value
 * @return {!proto.messenger.private.companyconfig.EmailNotificationConfig} returns this
 */
proto.messenger.private.companyconfig.EmailNotificationConfig.prototype.setIsEnabled = function(value) {
  return jspb.Message.setProto3BooleanField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.messenger.private.companyconfig.UpdateEmailNotificationStatusRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.messenger.private.companyconfig.UpdateEmailNotificationStatusRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.messenger.private.companyconfig.UpdateEmailNotificationStatusRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.messenger.private.companyconfig.UpdateEmailNotificationStatusRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    companyId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    emailType: jspb.Message.getFieldWithDefault(msg, 2, 0),
    isEnabled: jspb.Message.getBooleanFieldWithDefault(msg, 3, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.messenger.private.companyconfig.UpdateEmailNotificationStatusRequest}
 */
proto.messenger.private.companyconfig.UpdateEmailNotificationStatusRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.messenger.private.companyconfig.UpdateEmailNotificationStatusRequest;
  return proto.messenger.private.companyconfig.UpdateEmailNotificationStatusRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.messenger.private.companyconfig.UpdateEmailNotificationStatusRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.messenger.private.companyconfig.UpdateEmailNotificationStatusRequest}
 */
proto.messenger.private.companyconfig.UpdateEmailNotificationStatusRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setCompanyId(value);
      break;
    case 2:
      var value = /** @type {!proto.messenger.private.companyconfig.EmailTypes} */ (reader.readEnum());
      msg.setEmailType(value);
      break;
    case 3:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsEnabled(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.messenger.private.companyconfig.UpdateEmailNotificationStatusRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.messenger.private.companyconfig.UpdateEmailNotificationStatusRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.messenger.private.companyconfig.UpdateEmailNotificationStatusRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.messenger.private.companyconfig.UpdateEmailNotificationStatusRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCompanyId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getEmailType();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
  f = message.getIsEnabled();
  if (f) {
    writer.writeBool(
      3,
      f
    );
  }
};


/**
 * optional int32 company_id = 1;
 * @return {number}
 */
proto.messenger.private.companyconfig.UpdateEmailNotificationStatusRequest.prototype.getCompanyId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.messenger.private.companyconfig.UpdateEmailNotificationStatusRequest} returns this
 */
proto.messenger.private.companyconfig.UpdateEmailNotificationStatusRequest.prototype.setCompanyId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional EmailTypes email_type = 2;
 * @return {!proto.messenger.private.companyconfig.EmailTypes}
 */
proto.messenger.private.companyconfig.UpdateEmailNotificationStatusRequest.prototype.getEmailType = function() {
  return /** @type {!proto.messenger.private.companyconfig.EmailTypes} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.messenger.private.companyconfig.EmailTypes} value
 * @return {!proto.messenger.private.companyconfig.UpdateEmailNotificationStatusRequest} returns this
 */
proto.messenger.private.companyconfig.UpdateEmailNotificationStatusRequest.prototype.setEmailType = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};


/**
 * optional bool is_enabled = 3;
 * @return {boolean}
 */
proto.messenger.private.companyconfig.UpdateEmailNotificationStatusRequest.prototype.getIsEnabled = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 3, false));
};


/**
 * @param {boolean} value
 * @return {!proto.messenger.private.companyconfig.UpdateEmailNotificationStatusRequest} returns this
 */
proto.messenger.private.companyconfig.UpdateEmailNotificationStatusRequest.prototype.setIsEnabled = function(value) {
  return jspb.Message.setProto3BooleanField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.messenger.private.companyconfig.UpdateEmailNotificationStatusResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.messenger.private.companyconfig.UpdateEmailNotificationStatusResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.messenger.private.companyconfig.UpdateEmailNotificationStatusResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.messenger.private.companyconfig.UpdateEmailNotificationStatusResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    emailType: jspb.Message.getFieldWithDefault(msg, 1, 0),
    isEnabled: jspb.Message.getBooleanFieldWithDefault(msg, 2, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.messenger.private.companyconfig.UpdateEmailNotificationStatusResponse}
 */
proto.messenger.private.companyconfig.UpdateEmailNotificationStatusResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.messenger.private.companyconfig.UpdateEmailNotificationStatusResponse;
  return proto.messenger.private.companyconfig.UpdateEmailNotificationStatusResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.messenger.private.companyconfig.UpdateEmailNotificationStatusResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.messenger.private.companyconfig.UpdateEmailNotificationStatusResponse}
 */
proto.messenger.private.companyconfig.UpdateEmailNotificationStatusResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.messenger.private.companyconfig.EmailTypes} */ (reader.readEnum());
      msg.setEmailType(value);
      break;
    case 2:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsEnabled(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.messenger.private.companyconfig.UpdateEmailNotificationStatusResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.messenger.private.companyconfig.UpdateEmailNotificationStatusResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.messenger.private.companyconfig.UpdateEmailNotificationStatusResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.messenger.private.companyconfig.UpdateEmailNotificationStatusResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getEmailType();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getIsEnabled();
  if (f) {
    writer.writeBool(
      2,
      f
    );
  }
};


/**
 * optional EmailTypes email_type = 1;
 * @return {!proto.messenger.private.companyconfig.EmailTypes}
 */
proto.messenger.private.companyconfig.UpdateEmailNotificationStatusResponse.prototype.getEmailType = function() {
  return /** @type {!proto.messenger.private.companyconfig.EmailTypes} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.messenger.private.companyconfig.EmailTypes} value
 * @return {!proto.messenger.private.companyconfig.UpdateEmailNotificationStatusResponse} returns this
 */
proto.messenger.private.companyconfig.UpdateEmailNotificationStatusResponse.prototype.setEmailType = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional bool is_enabled = 2;
 * @return {boolean}
 */
proto.messenger.private.companyconfig.UpdateEmailNotificationStatusResponse.prototype.getIsEnabled = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
};


/**
 * @param {boolean} value
 * @return {!proto.messenger.private.companyconfig.UpdateEmailNotificationStatusResponse} returns this
 */
proto.messenger.private.companyconfig.UpdateEmailNotificationStatusResponse.prototype.setIsEnabled = function(value) {
  return jspb.Message.setProto3BooleanField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.messenger.private.companyconfig.UpdateCompanyAlertsAndNotificationRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.messenger.private.companyconfig.UpdateCompanyAlertsAndNotificationRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.messenger.private.companyconfig.UpdateCompanyAlertsAndNotificationRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.messenger.private.companyconfig.UpdateCompanyAlertsAndNotificationRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    companyId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    alertEmail: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.messenger.private.companyconfig.UpdateCompanyAlertsAndNotificationRequest}
 */
proto.messenger.private.companyconfig.UpdateCompanyAlertsAndNotificationRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.messenger.private.companyconfig.UpdateCompanyAlertsAndNotificationRequest;
  return proto.messenger.private.companyconfig.UpdateCompanyAlertsAndNotificationRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.messenger.private.companyconfig.UpdateCompanyAlertsAndNotificationRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.messenger.private.companyconfig.UpdateCompanyAlertsAndNotificationRequest}
 */
proto.messenger.private.companyconfig.UpdateCompanyAlertsAndNotificationRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setCompanyId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setAlertEmail(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.messenger.private.companyconfig.UpdateCompanyAlertsAndNotificationRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.messenger.private.companyconfig.UpdateCompanyAlertsAndNotificationRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.messenger.private.companyconfig.UpdateCompanyAlertsAndNotificationRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.messenger.private.companyconfig.UpdateCompanyAlertsAndNotificationRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCompanyId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getAlertEmail();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional int32 company_id = 1;
 * @return {number}
 */
proto.messenger.private.companyconfig.UpdateCompanyAlertsAndNotificationRequest.prototype.getCompanyId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.messenger.private.companyconfig.UpdateCompanyAlertsAndNotificationRequest} returns this
 */
proto.messenger.private.companyconfig.UpdateCompanyAlertsAndNotificationRequest.prototype.setCompanyId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string alert_email = 2;
 * @return {string}
 */
proto.messenger.private.companyconfig.UpdateCompanyAlertsAndNotificationRequest.prototype.getAlertEmail = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.messenger.private.companyconfig.UpdateCompanyAlertsAndNotificationRequest} returns this
 */
proto.messenger.private.companyconfig.UpdateCompanyAlertsAndNotificationRequest.prototype.setAlertEmail = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.messenger.private.companyconfig.UpdateCompanyAlertsAndNotificationResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.messenger.private.companyconfig.UpdateCompanyAlertsAndNotificationResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.messenger.private.companyconfig.UpdateCompanyAlertsAndNotificationResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.messenger.private.companyconfig.UpdateCompanyAlertsAndNotificationResponse.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.messenger.private.companyconfig.UpdateCompanyAlertsAndNotificationResponse}
 */
proto.messenger.private.companyconfig.UpdateCompanyAlertsAndNotificationResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.messenger.private.companyconfig.UpdateCompanyAlertsAndNotificationResponse;
  return proto.messenger.private.companyconfig.UpdateCompanyAlertsAndNotificationResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.messenger.private.companyconfig.UpdateCompanyAlertsAndNotificationResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.messenger.private.companyconfig.UpdateCompanyAlertsAndNotificationResponse}
 */
proto.messenger.private.companyconfig.UpdateCompanyAlertsAndNotificationResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.messenger.private.companyconfig.UpdateCompanyAlertsAndNotificationResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.messenger.private.companyconfig.UpdateCompanyAlertsAndNotificationResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.messenger.private.companyconfig.UpdateCompanyAlertsAndNotificationResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.messenger.private.companyconfig.UpdateCompanyAlertsAndNotificationResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.messenger.private.companyconfig.GetCompanyAlertsAndNotificationRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.messenger.private.companyconfig.GetCompanyAlertsAndNotificationRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.messenger.private.companyconfig.GetCompanyAlertsAndNotificationRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.messenger.private.companyconfig.GetCompanyAlertsAndNotificationRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    companyId: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.messenger.private.companyconfig.GetCompanyAlertsAndNotificationRequest}
 */
proto.messenger.private.companyconfig.GetCompanyAlertsAndNotificationRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.messenger.private.companyconfig.GetCompanyAlertsAndNotificationRequest;
  return proto.messenger.private.companyconfig.GetCompanyAlertsAndNotificationRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.messenger.private.companyconfig.GetCompanyAlertsAndNotificationRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.messenger.private.companyconfig.GetCompanyAlertsAndNotificationRequest}
 */
proto.messenger.private.companyconfig.GetCompanyAlertsAndNotificationRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setCompanyId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.messenger.private.companyconfig.GetCompanyAlertsAndNotificationRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.messenger.private.companyconfig.GetCompanyAlertsAndNotificationRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.messenger.private.companyconfig.GetCompanyAlertsAndNotificationRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.messenger.private.companyconfig.GetCompanyAlertsAndNotificationRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCompanyId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
};


/**
 * optional int32 company_id = 1;
 * @return {number}
 */
proto.messenger.private.companyconfig.GetCompanyAlertsAndNotificationRequest.prototype.getCompanyId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.messenger.private.companyconfig.GetCompanyAlertsAndNotificationRequest} returns this
 */
proto.messenger.private.companyconfig.GetCompanyAlertsAndNotificationRequest.prototype.setCompanyId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.messenger.private.companyconfig.GetCompanyAlertsAndNotificationResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.messenger.private.companyconfig.GetCompanyAlertsAndNotificationResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.messenger.private.companyconfig.GetCompanyAlertsAndNotificationResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.messenger.private.companyconfig.GetCompanyAlertsAndNotificationResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    alertEmail: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.messenger.private.companyconfig.GetCompanyAlertsAndNotificationResponse}
 */
proto.messenger.private.companyconfig.GetCompanyAlertsAndNotificationResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.messenger.private.companyconfig.GetCompanyAlertsAndNotificationResponse;
  return proto.messenger.private.companyconfig.GetCompanyAlertsAndNotificationResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.messenger.private.companyconfig.GetCompanyAlertsAndNotificationResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.messenger.private.companyconfig.GetCompanyAlertsAndNotificationResponse}
 */
proto.messenger.private.companyconfig.GetCompanyAlertsAndNotificationResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setAlertEmail(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.messenger.private.companyconfig.GetCompanyAlertsAndNotificationResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.messenger.private.companyconfig.GetCompanyAlertsAndNotificationResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.messenger.private.companyconfig.GetCompanyAlertsAndNotificationResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.messenger.private.companyconfig.GetCompanyAlertsAndNotificationResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAlertEmail();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string alert_email = 1;
 * @return {string}
 */
proto.messenger.private.companyconfig.GetCompanyAlertsAndNotificationResponse.prototype.getAlertEmail = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.messenger.private.companyconfig.GetCompanyAlertsAndNotificationResponse} returns this
 */
proto.messenger.private.companyconfig.GetCompanyAlertsAndNotificationResponse.prototype.setAlertEmail = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.messenger.private.companyconfig.GetAchMicroDepositEmailConfigRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.messenger.private.companyconfig.GetAchMicroDepositEmailConfigRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.messenger.private.companyconfig.GetAchMicroDepositEmailConfigRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.messenger.private.companyconfig.GetAchMicroDepositEmailConfigRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    companyId: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.messenger.private.companyconfig.GetAchMicroDepositEmailConfigRequest}
 */
proto.messenger.private.companyconfig.GetAchMicroDepositEmailConfigRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.messenger.private.companyconfig.GetAchMicroDepositEmailConfigRequest;
  return proto.messenger.private.companyconfig.GetAchMicroDepositEmailConfigRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.messenger.private.companyconfig.GetAchMicroDepositEmailConfigRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.messenger.private.companyconfig.GetAchMicroDepositEmailConfigRequest}
 */
proto.messenger.private.companyconfig.GetAchMicroDepositEmailConfigRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setCompanyId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.messenger.private.companyconfig.GetAchMicroDepositEmailConfigRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.messenger.private.companyconfig.GetAchMicroDepositEmailConfigRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.messenger.private.companyconfig.GetAchMicroDepositEmailConfigRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.messenger.private.companyconfig.GetAchMicroDepositEmailConfigRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCompanyId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
};


/**
 * optional int32 company_id = 1;
 * @return {number}
 */
proto.messenger.private.companyconfig.GetAchMicroDepositEmailConfigRequest.prototype.getCompanyId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.messenger.private.companyconfig.GetAchMicroDepositEmailConfigRequest} returns this
 */
proto.messenger.private.companyconfig.GetAchMicroDepositEmailConfigRequest.prototype.setCompanyId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.messenger.private.companyconfig.GetAchMicroDepositEmailConfigResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.messenger.private.companyconfig.GetAchMicroDepositEmailConfigResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.messenger.private.companyconfig.GetAchMicroDepositEmailConfigResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.messenger.private.companyconfig.GetAchMicroDepositEmailConfigResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    isEnabled: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    bccEmail: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.messenger.private.companyconfig.GetAchMicroDepositEmailConfigResponse}
 */
proto.messenger.private.companyconfig.GetAchMicroDepositEmailConfigResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.messenger.private.companyconfig.GetAchMicroDepositEmailConfigResponse;
  return proto.messenger.private.companyconfig.GetAchMicroDepositEmailConfigResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.messenger.private.companyconfig.GetAchMicroDepositEmailConfigResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.messenger.private.companyconfig.GetAchMicroDepositEmailConfigResponse}
 */
proto.messenger.private.companyconfig.GetAchMicroDepositEmailConfigResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsEnabled(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setBccEmail(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.messenger.private.companyconfig.GetAchMicroDepositEmailConfigResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.messenger.private.companyconfig.GetAchMicroDepositEmailConfigResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.messenger.private.companyconfig.GetAchMicroDepositEmailConfigResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.messenger.private.companyconfig.GetAchMicroDepositEmailConfigResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getIsEnabled();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getBccEmail();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional bool is_enabled = 1;
 * @return {boolean}
 */
proto.messenger.private.companyconfig.GetAchMicroDepositEmailConfigResponse.prototype.getIsEnabled = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.messenger.private.companyconfig.GetAchMicroDepositEmailConfigResponse} returns this
 */
proto.messenger.private.companyconfig.GetAchMicroDepositEmailConfigResponse.prototype.setIsEnabled = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional string bcc_email = 2;
 * @return {string}
 */
proto.messenger.private.companyconfig.GetAchMicroDepositEmailConfigResponse.prototype.getBccEmail = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.messenger.private.companyconfig.GetAchMicroDepositEmailConfigResponse} returns this
 */
proto.messenger.private.companyconfig.GetAchMicroDepositEmailConfigResponse.prototype.setBccEmail = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.messenger.private.companyconfig.UpdateAchMicroDepositEmailConfigRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.messenger.private.companyconfig.UpdateAchMicroDepositEmailConfigRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.messenger.private.companyconfig.UpdateAchMicroDepositEmailConfigRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.messenger.private.companyconfig.UpdateAchMicroDepositEmailConfigRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    companyId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    isEnabled: jspb.Message.getBooleanFieldWithDefault(msg, 2, false),
    bccEmail: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.messenger.private.companyconfig.UpdateAchMicroDepositEmailConfigRequest}
 */
proto.messenger.private.companyconfig.UpdateAchMicroDepositEmailConfigRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.messenger.private.companyconfig.UpdateAchMicroDepositEmailConfigRequest;
  return proto.messenger.private.companyconfig.UpdateAchMicroDepositEmailConfigRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.messenger.private.companyconfig.UpdateAchMicroDepositEmailConfigRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.messenger.private.companyconfig.UpdateAchMicroDepositEmailConfigRequest}
 */
proto.messenger.private.companyconfig.UpdateAchMicroDepositEmailConfigRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setCompanyId(value);
      break;
    case 2:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsEnabled(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setBccEmail(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.messenger.private.companyconfig.UpdateAchMicroDepositEmailConfigRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.messenger.private.companyconfig.UpdateAchMicroDepositEmailConfigRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.messenger.private.companyconfig.UpdateAchMicroDepositEmailConfigRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.messenger.private.companyconfig.UpdateAchMicroDepositEmailConfigRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCompanyId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getIsEnabled();
  if (f) {
    writer.writeBool(
      2,
      f
    );
  }
  f = message.getBccEmail();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional int32 company_id = 1;
 * @return {number}
 */
proto.messenger.private.companyconfig.UpdateAchMicroDepositEmailConfigRequest.prototype.getCompanyId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.messenger.private.companyconfig.UpdateAchMicroDepositEmailConfigRequest} returns this
 */
proto.messenger.private.companyconfig.UpdateAchMicroDepositEmailConfigRequest.prototype.setCompanyId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional bool is_enabled = 2;
 * @return {boolean}
 */
proto.messenger.private.companyconfig.UpdateAchMicroDepositEmailConfigRequest.prototype.getIsEnabled = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
};


/**
 * @param {boolean} value
 * @return {!proto.messenger.private.companyconfig.UpdateAchMicroDepositEmailConfigRequest} returns this
 */
proto.messenger.private.companyconfig.UpdateAchMicroDepositEmailConfigRequest.prototype.setIsEnabled = function(value) {
  return jspb.Message.setProto3BooleanField(this, 2, value);
};


/**
 * optional string bcc_email = 3;
 * @return {string}
 */
proto.messenger.private.companyconfig.UpdateAchMicroDepositEmailConfigRequest.prototype.getBccEmail = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.messenger.private.companyconfig.UpdateAchMicroDepositEmailConfigRequest} returns this
 */
proto.messenger.private.companyconfig.UpdateAchMicroDepositEmailConfigRequest.prototype.setBccEmail = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.messenger.private.companyconfig.UpdateAchMicroDepositEmailConfigResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.messenger.private.companyconfig.UpdateAchMicroDepositEmailConfigResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.messenger.private.companyconfig.UpdateAchMicroDepositEmailConfigResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.messenger.private.companyconfig.UpdateAchMicroDepositEmailConfigResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    isEnabled: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    bccEmail: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.messenger.private.companyconfig.UpdateAchMicroDepositEmailConfigResponse}
 */
proto.messenger.private.companyconfig.UpdateAchMicroDepositEmailConfigResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.messenger.private.companyconfig.UpdateAchMicroDepositEmailConfigResponse;
  return proto.messenger.private.companyconfig.UpdateAchMicroDepositEmailConfigResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.messenger.private.companyconfig.UpdateAchMicroDepositEmailConfigResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.messenger.private.companyconfig.UpdateAchMicroDepositEmailConfigResponse}
 */
proto.messenger.private.companyconfig.UpdateAchMicroDepositEmailConfigResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsEnabled(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setBccEmail(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.messenger.private.companyconfig.UpdateAchMicroDepositEmailConfigResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.messenger.private.companyconfig.UpdateAchMicroDepositEmailConfigResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.messenger.private.companyconfig.UpdateAchMicroDepositEmailConfigResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.messenger.private.companyconfig.UpdateAchMicroDepositEmailConfigResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getIsEnabled();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getBccEmail();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional bool is_enabled = 1;
 * @return {boolean}
 */
proto.messenger.private.companyconfig.UpdateAchMicroDepositEmailConfigResponse.prototype.getIsEnabled = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.messenger.private.companyconfig.UpdateAchMicroDepositEmailConfigResponse} returns this
 */
proto.messenger.private.companyconfig.UpdateAchMicroDepositEmailConfigResponse.prototype.setIsEnabled = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional string bcc_email = 2;
 * @return {string}
 */
proto.messenger.private.companyconfig.UpdateAchMicroDepositEmailConfigResponse.prototype.getBccEmail = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.messenger.private.companyconfig.UpdateAchMicroDepositEmailConfigResponse} returns this
 */
proto.messenger.private.companyconfig.UpdateAchMicroDepositEmailConfigResponse.prototype.setBccEmail = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * @enum {number}
 */
proto.messenger.private.companyconfig.EmailTypes = {
  UNSPECIFIED: 0,
  PASSWORD_RESET_EMAIL: 1,
  NEW_SUBSCRIPTION: 2,
  INVITATION_EMAIL: 3,
  REFUND_ISSUED: 5,
  SUBSCRIPTION_CANCELLED: 6,
  ONE_TIME_CHARGE: 7,
  ONE_TIME_CREDIT: 9,
  POSITIVE_ONE_TIME_CHARGE: 10,
  SUCCESSFUL_RENEWAL_PAYMENT_EMAIL: 11,
  NEW_TRIAL_SUBSCRIPTION: 12,
  PAYMENT_DETAILS_REQUEST: 13,
  PAYMENT_DECLINED_INITIAL: 14,
  PAYMENT_DECLINED_ATTEMPT_ONE: 15,
  PAYMENT_DECLINED_ATTEMPT_TWO: 16,
  PAYMENT_DECLINED_ATTEMPT_THREE: 17,
  PAYMENT_SUCCEEDED_AFTER_FAILURE: 18,
  PAYMENT_FAILURE_INITIAL: 19,
  INVOICE_LINK: 20,
  NEXT_BILL_ADJUSTMENT: 21,
  CREDIT_NOTE_LINK: 22,
  NEW_CUSTOMER_WITHOUT_SUBSCRIPTION: 23,
  CUSTOMER_PASSCODE: 24,
  REMINDER_72_HOURS_BEFORE_RENEWAL: 25,
  PAYMENT_METHOD_EXPIRING_SOON: 26,
  PAYMENT_METHOD_EXPIRED: 27,
  NEW_PAYMENT_DETAILS_REQUIRED: 28,
  PAUSED_SUBSCRIPTION_RESTARTING_SOON: 29,
  REMINDER_72_HOURS_BEFORE_TRIAL_EXPIRES: 30,
  SUBSCRIPTION_ADDON_CREATED: 32,
  SUBSCRIPTION_ADDON_REMOVED: 33,
  HANDLING_MINIMUM_TERMS_EMAIL: 34,
  TWENTY_DAYS_BEFORE_END_OF_CONTRACT_TERM: 35,
  NEW_ALLOWANCE_APPLIED_TO_EXISTING_SUBSCRIPTION: 36,
  SUBSCRIPTION_ALLOWANCE_REMOVED: 37,
  TOKEN_APPLIED: 38,
  REMINDER_COUPON_EXPIRES_IN_20_DAYS: 39,
  PAYMENT_DECLINED_INITIAL_SCA: 40,
  PAYMENT_DECLINED_ATTEMPT_ONE_SCA: 41,
  PAYMENT_DECLINED_ATTEMPT_TWO_SCA: 42,
  PAYMENT_DECLINED_ATTEMPT_THREE_SCA: 43,
  HANDLING_MINIMUM_TERMS_EMAIL_SCA: 44,
  ONE_TIME_CHARGE_PAYMENT_FAILURE: 45,
  SUBSCRIPTION_BILL_DATE_CHANGED: 46,
  SUCCESSFUL_CSV_DATA_EXPORT: 47,
  PAYMENT_DECLINED_INITIAL_ACH: 48,
  PAYMENT_DECLINED_ATTEMPT_ONE_ACH: 49,
  PAYMENT_DECLINED_ATTEMPT_TWO_ACH: 50,
  PAYMENT_DECLINED_ATTEMPT_THREE_ACH: 51,
  HANDLING_MINIMUM_TERMS_EMAIL_ACH: 52,
  ONE_TIME_CHARGE_ACH_PAYMENT_FAILURE: 53
};

goog.object.extend(exports, proto.messenger.private.companyconfig);
