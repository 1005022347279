import { BillsbyAction } from "../models/BillsbyAction";
import { IParsedToken, ICompany, Permission } from "../models/Auth";
import { IReadmeIoLinks } from "../models/ReadmeIoLinks";
import { INIT_TOKEN_DATA, INIT_READMEIO_LINKS_DATA, FETCH_GETTING_STARTED_SUCCESS, SET_BILLSBY_COMPANY_GOOGLE_ANALYTICS_UID, UPDATE_GETTING_STARTED_SUCCESS, SET_IS_BILLSBY_PRELIVE_FIRST_TIME, FETCH_COMPANIES_SUCCESS, RESET_AUTH_STATE, SET_SELECTED_COMPANY_ID, SET_PARSED_TOKEN, DELETE_COMPANY_SUCCESS, FETCH_TRIAL_BALANCE_REQUEST, FETCH_TRIAL_BALANCE_FAILURE, FETCH_TRIAL_BALANCE_SUCCESS, SET_SHOW_BILLING_MODAL } from "../actions/authActions";
import {  GeTrialBalanceResponse } from "../utils/grpc/generated/Billsby.Protos/revenues/private/company/company_pb";
import { storeGet, storeRemove, storeSet } from "../utils/storeUtils";
import { BILLSBY_AUTH_DATA_CONTROL, BILLSBY_GUID, BILLSBY_SELECTED_COMPANY } from "../utils/constants";
import { getDocTokenSSO } from "../utils/authUtils";
import { AppState } from "..";
import { IGettingStartedSettings } from "../models/GettingStarted";
import { divideNumberBy100 } from "../utils/commonUtils";

export interface IAuthReducerState {
  parsedToken: IParsedToken | null,
  selectedCompanyId?: number,
  companyName: string,
  currentCompanyId: number | null,
  currentCompanyDomain: string,
  currentCompanyCurrency: string,
  currentCompanySubscriptionUniqueId: string,
  currentCompanyCustomerUniqueId: string,
  currentCompanyGoogleAnalyticsUID: string,
  readmeioLinks: IReadmeIoLinks,
  dashboardSettings: IGettingStartedSettings | null,
  companies: Array<ICompany>,
  isFetchedCompaniesSuccess: boolean,
  isBillsbyPreliveFirstTime: boolean,
  isFetchingTrialBalanceRequest: boolean,
  isFetchingTrialBalanceSuccess: boolean,
  isFetchingTrialBalanceFailure: boolean,
  trialBalance?: GeTrialBalanceResponse,
  companyTrialLimit: string,
  showBillingModal: boolean
}

export const authInitState = {
  parsedToken: null,
  selectedCompanyId: undefined,
  companyName: "",
  currentCompanyId: null,
  currentCompanyDomain: "",
  currentCompanyCurrency: "",
  currentCompanySubscriptionUniqueId: "",
  currentCompanyCustomerUniqueId: "",
  currentCompanyGoogleAnalyticsUID: "",
  readmeioLinks: <IReadmeIoLinks>{
    api: "https://support.billsby.com/reference/",
    checkout: "https://support.billsby.com/docs/billsby-checkout",
    docs: "https://support.billsby.com/docs/",
    discuss: "https://support.billsby.com/discuss/",
    blog: "https://support.billsby.com/blog/",
  },
  dashboardSettings: null,
  companies: [],
  isBillsbyPreliveFirstTime: false,
  isFetchedCompaniesSuccess: false,
  isFetchingTrialBalanceRequest: false,
  isFetchingTrialBalanceSuccess: false,
  isFetchingTrialBalanceFailure: false,
  companyTrialLimit: "$50,000",
  showBillingModal: false
}

export default function authReducer(state: IAuthReducerState = authInitState, action: BillsbyAction, store: AppState) {
  switch (action.type) {
    case INIT_TOKEN_DATA: {
      const parsedToken: IParsedToken | null = getAuthData();
      return { ...state, parsedToken }
    }
    case INIT_READMEIO_LINKS_DATA: {
      if (state.parsedToken != null) {
        const readmeioLinks = <IReadmeIoLinks>getReadmeioJwt(state);
        return { ...state, readmeioLinks }
      }
      return state;
    }
    case FETCH_GETTING_STARTED_SUCCESS: {
      const dashboardSettings: IGettingStartedSettings = action.response;
      return { ...state, dashboardSettings }
    }
    case UPDATE_GETTING_STARTED_SUCCESS:
      return { ...state, dashboardSettings: action.response }
    case SET_IS_BILLSBY_PRELIVE_FIRST_TIME:
      return { ...state, isBillsbyPreliveFirstTime: action.payload }
    case SET_BILLSBY_COMPANY_GOOGLE_ANALYTICS_UID: {
      // the guid cookie must be updated
      if(action.payload) {
        storeSet(BILLSBY_GUID, action.payload)
      }
      return { ...state, currentCompanyGoogleAnalyticsUID: action.payload  }
    }
    case FETCH_COMPANIES_SUCCESS: {
      const companies: Array<ICompany> = action.response;
      if (state.parsedToken) {
        let domain = window.location.host;
        if (domain.startsWith("www.")) {
          domain = domain.replace("www.", "");
        }
        const company = companies.find((company: ICompany) => domain.split(".")[0] === company.companyDomain);
        
        if(company?.googleAnalyticsId) {
          // the guid cookie must be updated
          storeSet(BILLSBY_GUID, company?.googleAnalyticsId)
        }

        return {
          ...state,
          selectedCompanyId: storeGet(BILLSBY_SELECTED_COMPANY),
          currentCompanyId: company ? company.companyId : null,
          currentCompanyDomain: company ? company.companyDomain : "",
          currentCompanySubscriptionUniqueId: company ? company.subscriptionUniqueId : "",
          currentCompanyCustomerUniqueId: company ? company.customerUniqueId : "",
          currentCompanyGoogleAnalyticsUID: company? company.googleAnalyticsId : "",
          companyName: company ? company.name : "",
          currentCompanyCurrency: company ? company.currencyCode : "",
          isFetchedCompaniesSuccess: true,
          companies,
          companyTrialLimit: company ? `$${(divideNumberBy100(company.trialLimit) as number).toLocaleString()}` : state.companyTrialLimit
        }
      }

      return { ...state }
    }

    case FETCH_TRIAL_BALANCE_REQUEST: {
      return { ...state, isFetchingTrialBalanceRequest: true, isFetchingTrialBalanceSuccess: false, isFetchingTrialBalanceFailure: false }
    }

    case FETCH_TRIAL_BALANCE_FAILURE: {
      return { ...state, isFetchingTrialBalanceRequest: false, isFetchingTrialBalanceSuccess: false, isFetchingTrialBalanceFailure: true }
    }

    case FETCH_TRIAL_BALANCE_SUCCESS: {
      const trialBalance = action.response as GeTrialBalanceResponse
      return { ...state, isFetchingTrialBalanceRequest: false, isFetchingTrialBalanceSuccess: true, isFetchingTrialBalanceFailure: false, trialBalance }
    }

    case RESET_AUTH_STATE:
      return { ...authInitState }

    case SET_SELECTED_COMPANY_ID:
      return { ...state, selectedCompanyId: action.payload }

    case DELETE_COMPANY_SUCCESS: {
      return { ...state, dashboardSettings: { ...state.dashboardSettings, deletedCompany: store.auth.companyName } }
    }

    case SET_PARSED_TOKEN:
      return { ...state, parsedToken: action.payload }
    
    case SET_SHOW_BILLING_MODAL:
      return { ...state, showBillingModal: action.payload }

    default:
      return state;
  }
}

export function parseJwt(token: string) {
  const base64Url = token.split(".")[1];
  const base64 = base64Url.replace("-", "+").replace("_", "/");
  return JSON.parse(window.atob(base64));
}

function getAuthData(): IParsedToken | null {
  const jwt = storeGet(BILLSBY_AUTH_DATA_CONTROL);
  try {
    const parsedToken = parseJwt(jwt.access_token);
    parsedToken.Permissions = JSON.parse(parsedToken.Permissions || "{\"Plan\":[],\"Teammate\":[]}") as { Plan: Array<Permission>, Teamamate: Array<Permission>}
    parsedToken.Limitations = JSON.parse(parsedToken.Limitations || "{}");
    parsedToken.UserRole = Number(parsedToken.UserRole);
    parsedToken.Permissions.Plan = parsedToken.Permissions.Plan.map((p: Permission) => Permission[p]).sort();
    parsedToken.Permissions.Teammate = parsedToken.Permissions.Teammate.map((p: Permission) => Permission[p]).sort();
    parsedToken.CurrentPermissions = parsedToken.Permissions.Plan.filter((value: Permission) => parsedToken.Permissions.Teammate.includes(value)).sort();
    return parsedToken;
  }
  catch (err) {
    storeRemove(BILLSBY_AUTH_DATA_CONTROL);
    return null;
  }
}

function getReadmeioJwt(state: IAuthReducerState) {
  return <IReadmeIoLinks>{
    api: `https://support.billsby.com/reference/?auth_token=${getDocTokenSSO(<IParsedToken>state.parsedToken)}`,
    checkout: `https://support.billsby.com/docs/billsby-checkout?auth_token=${getDocTokenSSO(<IParsedToken>state.parsedToken)}`,
    docs: `https://support.billsby.com/docs/?auth_token=${getDocTokenSSO(<IParsedToken>state.parsedToken)}`,
    discuss: `https://support.billsby.com/discuss/?auth_token=${getDocTokenSSO(<IParsedToken>state.parsedToken)}`,
    blog: `https://support.billsby.com/blog/?auth_token=${getDocTokenSSO(<IParsedToken>state.parsedToken)}`,
  }
}