import { BillsbyAction } from "../models/BillsbyAction";
import { AppState } from "..";
import { FETCH_CREDIT_NOTE_DETAILS_SUCCESS, RESET_CREDIT_NOTE_DETAILS, PUT_CREDIT_NOTE_PAYMENT_REQUEST } from "../actions/creditNoteDetailsActions";
import { ICreditNoteDetails } from "../models/Customer";
import { CreditNoteStatus } from "../models/CreditNotes";
import { CreditNoteType } from "../models/Invoices";

export interface ICreditNoteDetailsReducerState {
  creditNoteDetails: ICreditNoteDetails
}

const initialState: ICreditNoteDetailsReducerState = {
  creditNoteDetails: {
    invoiceId: 0,
    customerEmail: "",
    customerFirstName: "",
    customerLastName: "",
    customerUniqueId: "",
    addressLine1: "",
    addressLine2: "",
    addressCity: "",
    addressPostCode: "",
    addressState: "",
    addressCountry: "",
    invoiceNumber: "",
    createdOn: "",
    amount: "",
    currency: "",
    status: CreditNoteStatus.PAID,
    subTotal: "",
    taxTotal: "",
    formattedAmount: "",
    taxPercentage: 0,
    description: "",
    companyName: "",
    companyPhone: "",
    companyEmail: "",
    companyTaxLabel: "",
    companyTaxNumber: "",
    brandingLogo: "",
    brandingLogoBackgroundColor: "",
    brandingHighlightColor: "",
    invoiceType: CreditNoteType.REFUND,
    cardType: "",
    cardLast4Digits: "",
    companyCity: "",
    companyCountry: "",
    companyAddressLine1: "",
    companyAddressLine2: "",
    companyPostCode: "",
    companyState: "",
    companyPhoneNumber: "",
    companyPhoneNumberDialCode: "",
    companyPhoneNumberDialCountry: "",
    supportContactContent: "",
    supportContactHeading: "",
    supportContactSupportUrl: "",
    supportContactSupportUrlButtonText: "",
    advertImageUrl: "",
    advertLinkToUrl: ""
  }
}

const creditNoteDetailsReducer = (state: ICreditNoteDetailsReducerState = initialState, action: BillsbyAction, store: AppState) => {
  switch (action.type) {
    case FETCH_CREDIT_NOTE_DETAILS_SUCCESS:
      return { ...state, creditNoteDetails: action.response }
    case PUT_CREDIT_NOTE_PAYMENT_REQUEST:
      return { ...state, creditNoteDetails: {...state.creditNoteDetails, status: "Pending"} }
    case RESET_CREDIT_NOTE_DETAILS:
      return { ...initialState }
    default:
      return state;
  }
};

export default creditNoteDetailsReducer;
