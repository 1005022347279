export function customPolyfill() {

  
  String.prototype.trimLeft = function () {
    return this.replace(/^\s+/, "");
  }

  String.prototype.capitalize = function() {
    return this.charAt(0).toUpperCase() + this.slice(1); 
  }

  String.prototype.trunc = function(n : number) {
    return ((this.length > n) ? this.substr(0, n-1) + "..." : this).toString();
  }


  //Promise.prototype.finally polyfill
  {
    let worker = (p: any, f: Function, done: Function) => {
      return p.constructor.resolve(f()).then(done, done);
    };
    // eslint-disable-next-line
    Object.defineProperty(Promise.prototype, "finally", {
      value(f: Function) {
        return this.then(
          (result: any) => worker(this, f, () => result),
          (error: any) => worker(this, f, () => { throw error; })
        );
      }
    });
  }

}