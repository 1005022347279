import React, { useEffect, useState } from "react";
import DiscountAndCouponsWelcome from "../../components/discount-and-coupons/DiscountAndCouponsWelcome";
import counterpart from "counterpart";
import Helmet from "react-helmet";
import NavigationAlertCurrencyMismatch from "../../components/ui/navigation-alert/NavigationAlertCurrencyMismatch";
import PageWrapper from "../../components/ui/layout/PageWrapper";
import DiscountList from "../../components/discount-and-coupons/discount-list/DiscountList";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../..";
import { resetDiscountList, fetchDiscountsList } from "../../actions/discount-coupon/discountsActions";
import ProgressIndicator from "../../components/ui/progress-indicator/ProgressIndicator";

const DiscountAndCoupons: React.FC = () => {
  const dispatch = useDispatch();
  const { currentCompanyId, discounts: { hasDiscounts } } = useSelector((state: AppState) => ({ currentCompanyId: state.auth.currentCompanyId, discounts: state.discountsReducer }))
  const [isLoadingData, setIsLoadingData] = useState(true);

  const fetchData = async () => {
    setIsLoadingData(true);
    try {
      await dispatch(fetchDiscountsList(currentCompanyId as number, 1, ""));
      setIsLoadingData(false);
    } catch (err) {
      setIsLoadingData(false);
    }
  };

  useEffect(() => {
    fetchData();

    return () => {
      dispatch(resetDiscountList());
    }
  }, []);

  if (isLoadingData) {
    return <ProgressIndicator color="blue" coverage="full-content" />;
  }

  if(!hasDiscounts) {
    return (
      <div>
        <Helmet title={counterpart("NAVBAR_DISCOUNTS_AND_COUPONS")} />
        <NavigationAlertCurrencyMismatch />
        <PageWrapper>
          <DiscountAndCouponsWelcome />
        </PageWrapper>
      </div>
    )
  }

  return <DiscountList />
}

export default DiscountAndCoupons;
