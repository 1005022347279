import React, { useState } from "react";
import counterpart from "counterpart";
import FormGroup from "../../../../ui/form-group/FormGroup";
import { Row, Col } from "react-grid-system";
import FormLabel from "../../../../ui/form-label/FormLabel";
import Input from "../../../../ui/input/Input";
import PaymentGatewayButtons from "../payment-gateways-shared-components/PaymentGatewayButtons";
import { isInvalidGatewayFormsDetails } from "../PaymentGateways";

interface Props {
  onSave: (displayName: string, username: string, password: string, merchantAccount: string, subdomain: string) => Promise<void>;
  onCancel: () => void;
  setHelpTitle: (text: string) => void;
  setHelpContent: (text: Array<string>) => void;
  isSaveEnabled: boolean;
  isEdit: boolean;
  initDisplayName: string;
  initUsername: string;
  initMerchantAccount: string;
  initSubdomain: string;
}

const AdyenPaymentGatewayForm: React.FC<Props> = ({ onSave, onCancel, setHelpTitle, setHelpContent, initDisplayName, isSaveEnabled, isEdit, initUsername, initMerchantAccount, initSubdomain }) => {

  const [displayName, setDisplayName] = useState(initDisplayName);
  const [username, setUsername] = useState(initUsername);
  const [password, setPassword] = useState("");
  const [merchantAccount, setMerchantAccount] = useState(initMerchantAccount);
  const [subdomain, setSubdomain] = useState(initSubdomain);

  const clearFields = () => {
    setDisplayName(initDisplayName);
    setUsername(initUsername);
    setPassword("");
    setMerchantAccount(initMerchantAccount);
    setSubdomain(initSubdomain);
  }

  const savePaymentGateway = async () => {
    await onSave(displayName, username, password, merchantAccount, subdomain);
    clearFields();
  } 

  const onFocus = (inputField: string) => {
    switch (inputField) {
      case "DisplayName":
        setHelpTitle("PAYMENT_GATEWAYS_ADDED_ADYEN_DISPLAYNAME_HELP_TITLE");
        setHelpContent(["PAYMENT_GATEWAYS_ADDED_ADYEN_DISPLAYNAME_HELP_CONTENT_1"]);
        break;
      case "Username":
        setHelpTitle("PAYMENT_GATEWAYS_ADDED_ADYEN_USERNAME_HELP_TITLE");
        setHelpContent(["PAYMENT_GATEWAYS_ADDED_ADYEN_USERNAME_HELP_CONTENT_1", "PAYMENT_GATEWAYS_ADDED_ADYEN_USERNAME_HELP_CONTENT_2"]);
        break;
      case "Password":
        setHelpTitle("PAYMENT_GATEWAYS_ADDED_ADYEN_PASSWORD_HELP_TITLE");
        setHelpContent(["PAYMENT_GATEWAYS_ADDED_ADYEN_PASSWORD_HELP_CONTENT_1", "PAYMENT_GATEWAYS_ADDED_ADYEN_PASSWORD_HELP_CONTENT_2"]);
        break;
      case "MerchantAccount":
        setHelpTitle("PAYMENT_GATEWAYS_ADDED_ADYEN_MERCHANT_ACCOUNT_HELP_TITLE");
        setHelpContent(["PAYMENT_GATEWAYS_ADDED_ADYEN_MERCHANT_ACCOUNT_HELP_CONTENT_1", "PAYMENT_GATEWAYS_ADDED_ADYEN_MERCHANT_ACCOUNT_HELP_CONTENT_2"]);
        break;
      case "Subdomain":
        setHelpTitle("PAYMENT_GATEWAYS_ADDED_ADYEN_SUBDOMAIN_HELP_TITLE");
        setHelpContent(["PAYMENT_GATEWAYS_ADDED_ADYEN_SUBDOMAIN_HELP_CONTENT_1", "PAYMENT_GATEWAYS_ADDED_ADYEN_SUBDOMAIN_HELP_CONTENT_2"]);
        break;
    }
  }

  return (
      <div className="payment-gateways__update">
        <FormGroup>
          <Row align="center" className="payment-gateways__update__form-group">
            <Col xs={2}>
              <FormLabel target="display-name" content="PAYMENT_GATEWAYS_ADYEN_DISPLAY_NAME" noMargin />
            </Col>
            <Col xs={10}>
              <Input
                id="display-name"
                onFocus={() => onFocus("DisplayName")}
                placeholder={counterpart("PAYMENT_GATEWAYS_ADYEN_DISPLAY_NAME_PLACEHOLDER")}
                value={displayName}
                onChange={(evt: any) => setDisplayName(evt.target.value)}
              />
            </Col>
          </Row>
          <Row align="center" className="payment-gateways__update__form-group">
            <Col xs={2}>
              <FormLabel target="user-name" content="PAYMENT_GATEWAYS_ADYEN_USERNAME" noMargin />
            </Col>
            <Col xs={10}>
              <Input
                id="user-name"
                disabled={isEdit}
                onFocus={() => onFocus("Username")}
                placeholder={counterpart("PAYMENT_GATEWAYS_ADYEN_USERNAME_PLACEHOLDER")}
                value={username}
                onChange={(evt: any) => setUsername(evt.target.value)}
              />
            </Col>
          </Row>
          <Row align="center" className="payment-gateways__update__form-group">
            <Col xs={2}>
              <FormLabel target="password" content="PAYMENT_GATEWAYS_ADYEN_PASSWORD" noMargin />
            </Col>
            <Col xs={10}>
              <Input
                id="password"
                onFocus={() => onFocus("Password")}
                placeholder={counterpart("PAYMENT_GATEWAYS_ADYEN_PASSWORD_PLACEHOLDER")}
                type="password"
                value={password}
                onChange={(evt: any) => setPassword(evt.target.value)}
              />
            </Col>
          </Row>
          <Row align="center" className="payment-gateways__update__form-group">
            <Col xs={2}>
              <FormLabel target="merchant-account" content="PAYMENT_GATEWAYS_ADYEN_MERCHANT_ACCOUNT" noMargin />
            </Col>
            <Col xs={10}>
              <Input
                id="merchant-account"
                onFocus={() => onFocus("MerchantAccount")}
                disabled={isEdit}
                placeholder={counterpart("PAYMENT_GATEWAYS_ADYEN_MERCHANT_ACCOUNT_PLACEHOLDER")}
                value={merchantAccount}
                onChange={(evt: any) => setMerchantAccount(evt.target.value)}
              />
            </Col>
          </Row>
          <Row align="center" className="payment-gateways__update__form-group">
            <Col xs={2}>
              <FormLabel target="sub-domain" content="PAYMENT_GATEWAYS_ADYEN_SUBDOMAIN" noMargin />
            </Col>
            <Col xs={8}>
              <Input
                id="sub-domain"
                onFocus={() => onFocus("Subdomain")}
                disabled={isEdit}
                placeholder={counterpart("PAYMENT_GATEWAYS_ADYEN_SUBDOMAIN_PLACEHOLDER")}
                value={subdomain}
                onChange={(evt: any) => setSubdomain(evt.target.value)}
              />
            </Col>
            <Col xs={2}>
              <FormLabel target="sub-domain" content="PAYMENT_GATEWAYS_ADYEN_SUBDOMAIN_TEXT" noMargin />
            </Col>
          </Row>
        </FormGroup>

        <PaymentGatewayButtons
          onSave={() => savePaymentGateway()}
          onCancel={() => {
            onCancel();
            clearFields();
          }}
          isEdit={isEdit}
          isSaveEnabled={isSaveEnabled && !isInvalidGatewayFormsDetails([displayName, username, password, merchantAccount, subdomain])}
        />
      </div>
  )
}

export default AdyenPaymentGatewayForm;