import React, { FormEvent, useState, useEffect, useMemo } from "react";
import { AppState } from "../../../../..";
import { useDispatch, useSelector } from "react-redux";
import Panel from "../../../../ui/panel/Panel";
import Text from "../../../../ui/text/Text";
import { IAuthReducerState } from "../../../../../reducers/authReducer";
import FormLabel from "../../../../ui/form-label/FormLabel";
import FormGroup from "../../../../ui/form-group/FormGroup";
import Input from "../../../../ui/input/Input";
import { Col, Row } from "react-grid-system";
import Button from "../../../../ui/button/Button";
import Dropdown from "../../../../ui/dropdown/Dropdown";
import counterpart from "counterpart";
import { SubscriptionStatusType } from "../../../../../models/Subscriptions";
import "./DeclinedPayments.scss";
import { InvoiceWriteOffType } from "../../../../../models/Invoices";
import Checkbox from "../../../../ui/checkbox/Checkbox";
import { IDunningFailedProcessState } from "../../../../../reducers/dunningFailedProcessReducer";
import { updateDunningProcessProps, updateDunningProcessData, resetDeclinedPayments, DunningProcessConnectedPanels, fetchDunningProcessData } from "../../../../../actions/dunningFailedProcessActions";
import AttemptPaymentPanels, { IAttemptPanelData } from "./AttemptPaymentPanels";
import { DunningFailedEmailType } from "../../../../../models/Emails";
import { IDunningConfig, IEmailAttempt } from "../../../../../models/DunningFailedProcess";
import HandlingMinimumTermsPanel from "./HandlingMinimumTermsPanel";
import { useHistory } from "react-router";
import NoticePanel from "../../../../ui/notice-panel/NoticePanel";


const DeclinedPayments: React.FC = () => {
  
  const initialSubscriptionStatusOptions = [
    { label: counterpart("DUNNING_FAILED_PAYMENTS_SUBCRIPTION_STATUS_ACTIVE"), value: SubscriptionStatusType.ACTIVE },
    { label: counterpart("DUNNING_FAILED_PAYMENTS_SUBCRIPTION_STATUS_SUSPENDED"), value: SubscriptionStatusType.SUSPENDED },
    { label: counterpart("DUNNING_FAILED_PAYMENTS_SUBCRIPTION_STATUS_CANCELLED"), value: SubscriptionStatusType.CANCELLED }
  ];

  const [state, setState] = useState({ hasError: false, errorMessage: "", success: false, isLoading: false });

  const authReducer = useSelector<AppState, IAuthReducerState>(state => state.auth)
  const dunningData = useSelector<AppState, IDunningFailedProcessState>(state => state.dunningFailedProcessReducer)
  const {
    initialPaymentDeclineStatus,
    successReattemptStatus,
    writingOffInvoice,
    attemptOne,
    attemptTwo,
    attemptThree,
    initialPaymentFailureHoursUntilPrompt,
    continuedPaymentFailureHoursUntilTransfer,
    minimumTerm
  } = dunningData
  const [writingOffDropdownValue, setWritingOffDropdownValue] = useState<{label:string, value: any} | undefined>
  (initialSubscriptionStatusOptions.find(i => i.value === writingOffInvoice?.subscriptionStatusType));
  const [writtenOffOptions, setWrittenOffOptions] = useState<Array<{label:string, value: any}>>(initialSubscriptionStatusOptions);
  const { isLoading, hasError } = state;
  const attempts: Array<DunningProcessConnectedPanels> = ["attemptOne", "attemptTwo", "attemptThree"];
  const dispatch = useDispatch<Function>()

  const history = useHistory()

  useEffect(() => {
    return () => dispatch(resetDeclinedPayments());
  }, []);

  const onSubmit = async (evt: FormEvent) => {
    evt.preventDefault();
    evt.stopPropagation();

    const configData: IDunningConfig = {
      subscriptionStatusOnInitialFailure: initialPaymentDeclineStatus,
      subscriptionStatusOnSuccess: successReattemptStatus,
      attemptOne: attemptOne.reattempt ? { hoursUntilReattempt: attemptOne.hours, subscriptionStatusOnFailure: attemptOne.subscriptionStatusType } : null,
      attemptTwo: attemptTwo.reattempt ? { hoursUntilReattempt: attemptTwo.hours, subscriptionStatusOnFailure: attemptTwo.subscriptionStatusType } : null,
      attemptThree: attemptThree.reattempt ? { hoursUntilReattempt: attemptThree.hours, subscriptionStatusOnFailure: attemptThree.subscriptionStatusType } : null,
      minimumTerm: minimumTerm.hasMinimumTerm ? { hoursUntilReattempt: minimumTerm.hours, subscriptionStatusOnFailure: minimumTerm.subscriptionStatusType } : null,
      writeOff: { logic: writingOffInvoice.writeOffStatus, subscriptionStatus: writingOffInvoice.subscriptionStatusType, hoursUntilAutomaticWriteOff: writingOffInvoice.hours },
      failedPaymentDunningConfig: {
        hoursUntilDunning: continuedPaymentFailureHoursUntilTransfer,
        hoursUntilEmailAlert: initialPaymentFailureHoursUntilPrompt
      }
    };

    setState({ ...state, isLoading: true });

    try {
      await dispatch(updateDunningProcessData(authReducer.currentCompanyDomain, configData));
      await dispatch(fetchDunningProcessData(authReducer.currentCompanyDomain));
      setState({ ...state, isLoading: false });
    } catch (err) {
      setState({ ...state, isLoading: false, hasError: true });
      console.log(err);
    }
  };

  const handleInitialPaymentOnChange = (status: any) => {
    dispatch(updateDunningProcessProps("initialPaymentDeclineStatus", status.value));

    if (status.value === SubscriptionStatusType.CANCELLED) {
      return attempts.forEach(attempt => dispatch(updateDunningProcessProps(attempt, { ...dunningData[attempt], subscriptionStatusType: status.value })));
    }

    if (status.value === SubscriptionStatusType.SUSPENDED) {
      return attempts.forEach(attempt =>
        dispatch(
          updateDunningProcessProps(attempt, {
            ...dunningData[attempt],
            subscriptionStatusType: dunningData[attempt].subscriptionStatusType === SubscriptionStatusType.ACTIVE ? status.value : dunningData[attempt].subscriptionStatusType
          })
        )
      );
    }
  };
  
  const getWrittenOffOptions = () => {
    if (writingOffInvoice.writeOffStatus === InvoiceWriteOffType.WRITE_OFF_MANUALLY)
      return initialSubscriptionStatusOptions;
    if(writtenOffMinStatusValue === SubscriptionStatusType.SUSPENDED)
      return initialSubscriptionStatusOptions.filter(i => i.value !== SubscriptionStatusType.ACTIVE);
    if(writtenOffMinStatusValue === SubscriptionStatusType.CANCELLED)
      return initialSubscriptionStatusOptions.filter(i => i.value === SubscriptionStatusType.CANCELLED);
    return initialSubscriptionStatusOptions;
  }

  const writtenOffMinStatusValue = useMemo(() => {
    if (writingOffInvoice.writeOffStatus === InvoiceWriteOffType.WRITE_OFF_MANUALLY)
      return initialPaymentDeclineStatus;

    if(minimumTerm.hasMinimumTerm)
      return minimumTerm.subscriptionStatusType;

    if(attemptThree.reattempt)
      return attemptThree.subscriptionStatusType;

    if(attemptTwo.reattempt)
      return attemptTwo.subscriptionStatusType;

    if(attemptOne.reattempt)
      return attemptOne.subscriptionStatusType;
      
    return initialPaymentDeclineStatus;

  }, [attemptOne, attemptTwo, attemptThree, minimumTerm, writingOffInvoice])

  
  useEffect(() => {
    const newWrittenOffOptions = getWrittenOffOptions();
    setWrittenOffOptions(newWrittenOffOptions);
    if (writingOffInvoice.writeOffStatus === InvoiceWriteOffType.WRITE_OFF_MANUALLY) {
      dispatch(updateDunningProcessProps("writingOffInvoice", { ...writingOffInvoice, subscriptionStatusType: SubscriptionStatusType.ACTIVE }));
      setWritingOffDropdownValue(initialSubscriptionStatusOptions[0]);
    } else {
      const minOption = initialSubscriptionStatusOptions.find(i => i.value === writtenOffMinStatusValue);
      const isStillInOptions = newWrittenOffOptions.some(o => o.value === writingOffDropdownValue?.value);
      const newOptionValue = isStillInOptions ? writingOffDropdownValue: minOption;
      dispatch(updateDunningProcessProps("writingOffInvoice", { ...writingOffInvoice, subscriptionStatusType: newOptionValue?.value }));
      setWritingOffDropdownValue(newOptionValue);
    }
  }, [writtenOffMinStatusValue])
  
  const renderInitialPaymentDeclinePanel = () => {
    const dropdownValue = initialSubscriptionStatusOptions.find(status => status.value === initialPaymentDeclineStatus);
    const initialPaymentOptions = initialSubscriptionStatusOptions.filter(o => o.value !== SubscriptionStatusType.CANCELLED)

    return (
      <Panel className="dunning-declined-payments__panel" title="DUNNING_FAILED_PAYMENTS_PANEL3_TITLE">
        <Text content="DUNNING_FAILED_PAYMENTS_PANEL3_CONTENT"></Text>
        <Button
          className="dunning-declined-payments__floating-button"
          icon="fas fa-edit"
          title="DUNNING_FAILED_PAYMENTS_BUTTON_CUSTOMIZE_EMAIL"
          type="button"
          buttonType="add"
          id="customize-email"
          onClick={() => history.push(`/configuration/dunning-process/customize-email/declined/${DunningFailedEmailType.PaymentDeclinedInitial}`)}
        />
        <Row align="center">
          <Col md={2}>
            <FormLabel noMargin target="subscription-status-1" content="DUNNING_FAILED_PAYMENTS_LABEL_SUBSCRIPTION_STATUS" />
          </Col>
          <Col md={10}>
            <Dropdown
              isSearchable={false}
              className="dunning-declined-payments__dropdown"
              id="initial-payment-subscription-type"
              value={dropdownValue}
              onChange={handleInitialPaymentOnChange}
              options={initialPaymentOptions}
            />
          </Col>
        </Row>
      </Panel>
    );
  };

  const renderAttemptPanels = () => {
    const attemptPanelData: Array<IAttemptPanelData> = [
      {
        attemptType: "attemptOne",
        title: "DUNNING_FAILED_PAYMENTS_PANEL4_TITLE",
        content: "DUNNING_FAILED_PAYMENTS_PANEL4_CONTENT",
        customizeEmailLink: () => history.push(`/configuration/dunning-process/customize-email/declined/${DunningFailedEmailType.PaymentDeclinedAttemptOne}`)
      },
      {
        attemptType: "attemptTwo",
        title: "DUNNING_FAILED_PAYMENTS_PANEL5_TITLE",
        content: "DUNNING_FAILED_PAYMENTS_PANEL5_CONTENT",
        customizeEmailLink: () => history.push(`/configuration/dunning-process/customize-email/declined/${DunningFailedEmailType.PaymentDeclinedAttemptTwo}`)
      },
      {
        attemptType: "attemptThree",
        title: "DUNNING_FAILED_PAYMENTS_PANEL6_TITLE",
        content: "DUNNING_FAILED_PAYMENTS_PANEL6_CONTENT",
        customizeEmailLink: () => history.push(`/configuration/dunning-process/customize-email/declined/${DunningFailedEmailType.PaymentDeclinedAttemptThree}`)
      }
    ];

    return <AttemptPaymentPanels attemptData={attemptPanelData} statusOptions={initialSubscriptionStatusOptions} />;
  };

  const renderSuccessfulReattemptPanel = () => {
    const dropdownValue = initialSubscriptionStatusOptions.find(status => status.value === successReattemptStatus);

    return (
      <Panel title="DUNNING_FAILED_PAYMENTS_PANEL7_TITLE" className="dunning-declined-payments__panel--stretched">
        <Text content="DUNNING_FAILED_PAYMENTS_PANEL7_CONTENT"></Text>
        <FormGroup>
          <Row align="center">
            <Col md={4}>
              <FormLabel noMargin target="subscription-status-4" content="DUNNING_FAILED_PAYMENTS_LABEL_SUBSCRIPTION_STATUS" />
            </Col>
            <Col md={8}>
              <Dropdown
                isSearchable={false}
                className="dunning-declined-payments__dropdown"
                id="successful-reattempt-subcription-type"
                value={dropdownValue}
                onChange={(status: any) => dispatch(updateDunningProcessProps("successReattemptStatus", status.value))}
                options={initialSubscriptionStatusOptions}
              />
            </Col>
          </Row>
        </FormGroup>
        <Button
          isFullWidth
          icon="fas fa-edit"
          title="DUNNING_FAILED_PAYMENTS_BUTTON_CUSTOMIZE_EMAIL"
          type="button"
          buttonType="add"
          id="customize-email-4"
          onClick={() => history.push("/configuration/dunning-process/customize-email/successful-reattempt")}
        />
      </Panel>
    );
  };

  const renderWriteOffTypeCheckboxes = () => {
    const checkboxWriteOffType = [
      { value: InvoiceWriteOffType.WRITE_OFF_MANUALLY, label: "DUNNING_FAILED_PAYMENTS_INVOICE_OPT2" },
      { value: InvoiceWriteOffType.WRITE_OFF_IMMEDIATELY, label: "DUNNING_FAILED_PAYMENTS_INVOICE_OPT1" },
      { value: InvoiceWriteOffType.WRITE_OFF_SCHEDULED, label: "DUNNING_FAILED_PAYMENTS_INVOICE_OPT3" }
    ];
    return checkboxWriteOffType.map(type => (
      <Checkbox
        key={type.value}
        checked={writingOffInvoice.writeOffStatus === type.value}
        onClick={() => {          
          dispatch(updateDunningProcessProps("writingOffInvoice", { ...writingOffInvoice, writeOffStatus: type.value }));
        }}
        value={type.value}
        content={<Text content={type.label} noMargin />}
      />
    ));
  };

  const renderWrittingOffInvoicePanel = () => {
    let currentAttempt: IEmailAttempt | null = null;
    for (let i = attempts.length - 1; i >= 0; --i) {
      const baseAttempt = dunningData[attempts[i]];
      if (baseAttempt.reattempt) {
        if (baseAttempt.subscriptionStatusType === SubscriptionStatusType.SUSPENDED || baseAttempt.subscriptionStatusType === SubscriptionStatusType.CANCELLED) {
          currentAttempt = baseAttempt as IEmailAttempt;
          break;
        }
      }
    }

    return (
      <Panel title="DUNNING_FAILED_PAYMENTS_PANEL8_TITLE" className="dunning-declined-payments__panel--stretched">
        <Text content="DUNNING_FAILED_PAYMENTS_PANEL8_CONTENT" noMargin></Text>
        <FormGroup>{renderWriteOffTypeCheckboxes()}</FormGroup>
        {writingOffInvoice.writeOffStatus === InvoiceWriteOffType.WRITE_OFF_SCHEDULED && (
          <FormGroup>
            <Row align="center">
              <Col md={4}>
                <FormLabel noMargin target="writting-off-invoice-hour" content="DUNNING_FAILED_PAYMENTS_LABEL_HOURS_WRITE_OFF" />
              </Col>
              <Col md={8}>
                <Input
                  id="writting-off-invoice-hour"
                  type="number"
                  value={writingOffInvoice.hours}
                  onChange={(evt: any) => {
                    dispatch(updateDunningProcessProps("writingOffInvoice", { ...writingOffInvoice, hours: evt.target.value }))}}
                />
              </Col>
            </Row>
          </FormGroup>
        )}
        {writingOffInvoice.writeOffStatus !== InvoiceWriteOffType.WRITE_OFF_MANUALLY && (
          <Row align="center">
            <Col md={4}>
              <FormLabel noMargin target="writting-off-invoice-type" content="DUNNING_FAILED_PAYMENTS_LABEL_SUBSCRIPTION_STATUS" />
            </Col>
            <Col md={8}>
              <Dropdown
                isSearchable={false}
                className="dunning-declined-payments__dropdown"
                id="writting-off-invoice-type"
                isDisabled={minimumTerm.hasMinimumTerm ? minimumTerm.subscriptionStatusType === SubscriptionStatusType.CANCELLED : currentAttempt && currentAttempt.subscriptionStatusType === SubscriptionStatusType.CANCELLED}
                value={writingOffDropdownValue}
                onChange={(status: any) => {
                  dispatch(updateDunningProcessProps("writingOffInvoice", { ...writingOffInvoice, subscriptionStatusType: status.value }));
                  setWritingOffDropdownValue(status);
                }}
                options={writtenOffOptions}
              />
            </Col>
          </Row>
        )}
      </Panel>
    );
  };

  return (
    <div className="dunning-declined-payments">
      <Panel title="DUNNING_FAILED_PAYMENTS_PANEL2_TITLE" className="dunning-declined-payments__welcome">
        <Text content="DUNNING_FAILED_PAYMENTS_PANEL2_CONTENT" noMargin></Text>
      </Panel>
      <NoticePanel type="error" content="DUNNING_WARNING_MESSAGE"/>
      <form onSubmit={onSubmit}>
        {renderInitialPaymentDeclinePanel()}
        {renderAttemptPanels()}
        <HandlingMinimumTermsPanel statusOptions={initialSubscriptionStatusOptions}/>
        <FormGroup>
          <Row className="dunning-declined-payments__row--stretched">
            <Col md={6}>{renderSuccessfulReattemptPanel()}</Col>
            <Col md={6}>{renderWrittingOffInvoicePanel()}</Col>
          </Row>
        </FormGroup>

        <Row align="center">
          <Col md={9}>
            <Button disabled={false} id="company-update-alerts" type="submit" isLoading={isLoading} isFullWidth buttonType="general" title="DUNNING_FAILED_PAYMENTS_BUTTON_UPDATE_CHANGES" />
          </Col>
          <Col md={3}>
            <Button onClick={() => dispatch(resetDeclinedPayments())} disabled={false} id="company-cancel-alerts-update" isFullWidth buttonType="error" title="DUNNING_FAILED_PAYMENTS_BUTTON_CANCEL" />
          </Col>
        </Row>
      </form>
    </div>
  );
};


export default DeclinedPayments
