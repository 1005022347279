import { makeActionCreator } from "../utils/reduxUtils";
import API from "../utils/API";
import { IBaseCustomField } from "../models/CustomFields";

export const SET_CUSTOMFIELDS_CURRENTSCREEN = "SET_CUSTOMFIELDS_CURRENTSCREEN";
export const setCustomFieldsCurrentScreen = makeActionCreator(SET_CUSTOMFIELDS_CURRENTSCREEN, "payload");
export const SET_SEARCHED_STANDARD_FIELD = "SET_SEARCHED_STANDARD_FIELD";
export const setSearchedStandardField = makeActionCreator(SET_SEARCHED_STANDARD_FIELD, "payload");
export const SET_STANDARD_FIELD = "SET_STANDARD_FIELD";
export const setStandardField = makeActionCreator(SET_STANDARD_FIELD, "fieldName", "payload");
export const SET_IS_CREATING_CUSTOM_FIELD = "IS_CREATING_CUSTOM_FIELD";
export const setIsCreatingCustomField = makeActionCreator(SET_IS_CREATING_CUSTOM_FIELD, "payload");
export const SET_CREATE_CUSTOM_FIELD = "SET_CREATE_CUSTOM_FIELD";
export const setCreateCustomField = makeActionCreator(SET_CREATE_CUSTOM_FIELD, "payload");
export const RESET_CUSTOM_FIELDS_STATE = "RESET_CUSTOM_FIELDS_STATE";
export const resetCustomFieldsState = makeActionCreator(RESET_CUSTOM_FIELDS_STATE);
export const RESET_CREATE_CUSTOM_FIELD_STATE = "RESET_CREATE_CUSTOM_FIELD_STATE";
export const resetCreateCustomFieldState = makeActionCreator(RESET_CREATE_CUSTOM_FIELD_STATE);
export const ADD_CUSTOM_FIELD = "ADD_CUSTOM_FIELD";
export const addCustomField = makeActionCreator(ADD_CUSTOM_FIELD, "payload");
export const UPDATE_CUSTOM_FIELD = "UPDATE_CUSTOM_FIELD";
export const updateCustomField = makeActionCreator(UPDATE_CUSTOM_FIELD, "payload");
export const SET_SEARCHED_CUSTOM_FIELD = "SET_SEARCHED_CUSTOM_FIELD";
export const setSearchedCustomField = makeActionCreator(SET_SEARCHED_CUSTOM_FIELD, "payload");
export const EDIT_CUSTOM_FIELD = "EDIT_CUSTOM_FIELD";
export const editCustomField = makeActionCreator(EDIT_CUSTOM_FIELD, "customFieldId", "fieldType", "label", "supportText", "fieldOptions", "compulsory");
export const SELECT_CUSTOM_FIELD = "SELECT_CUSTOM_FIELD";
export const selectCustomField = makeActionCreator(SELECT_CUSTOM_FIELD, "customFieldId", "isSelected");
export const CREATE_CUSTOM_FIELD_REQUEST = "CREATE_CUSTOM_FIELD_REQUEST";
export const CREATE_CUSTOM_FIELD_SUCCESS = "CREATE_CUSTOM_FIELD_SUCCESS";
export const CREATE_CUSTOM_FIELD_FAILURE = "CREATE_CUSTOM_FIELD_FAILURE";
export const FETCH_CUSTOM_FIELDS_REQUEST = "FETCH_CUSTOM_FIELDS_REQUEST";
export const FETCH_CUSTOM_FIELDS_SUCCESS = "FETCH_CUSTOM_FIELDS_SUCCESS";
export const FETCH_CUSTOM_FIELDS_FAILURE = "FETCH_CUSTOM_FIELDS_FAILURE";
export const PUT_CUSTOM_FIELD_REQUEST = "PUT_CUSTOM_FIELD_REQUEST";
export const PUT_CUSTOM_FIELD_SUCCESS = "PUT_CUSTOM_FIELD_SUCCESS";
export const PUT_CUSTOM_FIELD_FAILURE = "PUT_CUSTOM_FIELD_FAILURE";

export const SET_IS_QUICK_EDIT = "SET_IS_QUICK_EDIT";
export const setIsQuickEdit = makeActionCreator(SET_IS_QUICK_EDIT, "payload");

export const createCustomField = (companyDomain: string, customField: IBaseCustomField) => {
  return {
    types: [CREATE_CUSTOM_FIELD_REQUEST, CREATE_CUSTOM_FIELD_SUCCESS, CREATE_CUSTOM_FIELD_FAILURE],
    callAPI: () => API.createCustomField(companyDomain, customField)
  }
}

export const putCustomField = (companyDomain: string, customFieldId: number, customField: IBaseCustomField) => {
  return {
    types: [PUT_CUSTOM_FIELD_FAILURE, PUT_CUSTOM_FIELD_SUCCESS, PUT_CUSTOM_FIELD_FAILURE],
    callAPI: () => API.putCustomField(companyDomain, customFieldId, customField)
  }
}

export const fetchCustomFields = (companyDomain: string) => {
  return {
    types: [FETCH_CUSTOM_FIELDS_REQUEST, FETCH_CUSTOM_FIELDS_SUCCESS, FETCH_CUSTOM_FIELDS_FAILURE],
    callAPI: () => API.getCustomFields(companyDomain)
  }
}