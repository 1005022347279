import { AnyAction } from "redux";


/**
 * generic TYPE SAFE action creator in order to use one action to set multiple fields (theoretically to be used in the same reducer)
 * @param type
 */
export function makeGenericActionCreator<T extends string>(type: string) {
  return function (fieldName: T, fieldValue: any) {
    const action: AnyAction = { type, fieldName, fieldValue }
    return action;
  }
}


/**
 * 
 * action creator NON TYPE SAFE
 * @param type 
 * @param argNames 
 */
export function makeActionCreator(type: string, ...argNames: Array<any>) {
  return function (...args: Array<any>) {
    const action: AnyAction = { type };
    argNames.forEach((arg, index) => {
      action[argNames[index]] = args[index];
    })
    return action;
  }
}

export function makeGenericActionCreatorTypeSafe<T>(type: string) {
  return function<K extends keyof T>(fieldName: K, fieldValue: T[K]) {
    const action: AnyAction = { type, fieldName, fieldValue }
    return action;
  }
}

