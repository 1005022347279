import React, { FormEvent, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import Panel from "../ui/panel/Panel";
import Text from "../ui/text/Text";
import FormLabel from "../ui/form-label/FormLabel";
import Input from "../ui/input/Input";
import Button from "../ui/button/Button";
import FormGroup from "../ui/form-group/FormGroup";
import counterpart from "counterpart";
import { Row, Col } from "react-grid-system";
import { AppState } from "../..";
import "./DeleteCustomer.scss";
import API from "../../utils/API";
import { setCustomersField } from "../../actions/customersActions";
import Table from "../ui/table/Table";
import { ICustomerDetailsReducerState } from "../../reducers/customerDetailsReducer";
import ErrorNotification from "../ui/error-notification/ErrorNotification";

const DeleteCustomer: React.FC = () => {
  const currentCompanyDomain = useSelector<AppState, string>(state => state.auth.currentCompanyDomain)
  const { phoneNumber, billingAddress, email, firstName, lastName, customerUniqueId, phoneNumberDialCode } = useSelector<AppState, ICustomerDetailsReducerState>(state => state.customerDetailsReducer)

  const dispatch = useDispatch();
  const history = useHistory();

  const [hasError, setHasError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [inputCustomerUniqueId, setInputCustomerUniqueId] = useState("");

  const wrongCustomerUniqueId = useMemo(() => {
    return inputCustomerUniqueId !== "" && customerUniqueId !== inputCustomerUniqueId
  }, [inputCustomerUniqueId])

  const onSubmit = async (evt: FormEvent) => {
    evt.preventDefault();
    evt.stopPropagation();

    if (!customerUniqueId) {
      return;
    }

    setIsLoading(true);

    try {
      await API.deleteCustomer(currentCompanyDomain, customerUniqueId);
      dispatch(setCustomersField("pageNotification", { type: "success", icon: "fas fa-check-circle", content: "DELETE_CUSTOMER_SUCCESS_MESSAGE" }));
      history.push("/customers");
    } catch (err) {
      console.log(err);
      setHasError(true)
    } finally {
      setIsLoading(false)
    }
  };

  const renderTable = () => {
    
    return (
      <Panel>
        <Text content="DELETE_CUSTOMER_MODAL_PANEL2_CONTENT" />
        <Table className="delete-customer-table">
          <tbody>
            <tr>
              <td>
                <Row align="center" justify="start" className="delete-customer-table__row">
                  <Col md={3}>
                    <Text className="delete-customer-table__label" content="DELETE_CUSTOMER_MODAL_EMAIL" noMargin />
                  </Col>
                  <Col md={9}>
                    <Text shouldTranslate={false} content={email} noMargin />
                  </Col>
                </Row>
              </td>
            </tr>
            <tr>
              <td>
                <Row align="center" justify="start" className="delete-customer-table__row">
                  <Col md={3}>
                    <Text className="delete-customer-table__label" content="DELETE_CUSTOMER_MODAL_FULL_NAME" noMargin />
                  </Col>
                  <Col md={9}>
                    <Text shouldTranslate={false} content={`${firstName} ${lastName}`} noMargin />
                  </Col>
                </Row>
              </td>
            </tr>
            <tr className="no-max-height">
              <td>
                <Row align="start" justify="end" className="delete-customer-table__row">
                  <Col md={3}>
                    <Text className="delete-customer-table__label" content="DELETE_CUSTOMER_MODAL_ADDRESS" noMargin />
                  </Col>
                  <Col md={9}>
                    <Text shouldTranslate={false} content={billingAddress.addressLine1} noMargin />
                    {billingAddress.addressLine2 && <Text shouldTranslate={false} content={billingAddress.addressLine2} noMargin />}
                    <Text shouldTranslate={false} content={billingAddress.state} noMargin />
                    <Text shouldTranslate={false} content={billingAddress.city} noMargin />
                    <Text shouldTranslate={false} content={billingAddress.country} noMargin />
                    <Text shouldTranslate={false} content={billingAddress.postCode} noMargin />
                  </Col>
                </Row>
              </td>
            </tr>
            <tr>
              <td>
                <Row align="center" justify="end" className="delete-customer-table__row">
                  <Col md={3}>
                    <Text className="delete-customer-table__label" content="DELETE_CUSTOMER_MODAL_PHONE_NUMBER" noMargin />
                  </Col>
                  <Col md={9}>
                    <Text shouldTranslate={false} content={`${phoneNumberDialCode || ""} ${phoneNumber || ""}`} noMargin />
                  </Col>
                </Row>
              </td>
            </tr>
            <tr>
              <td>
                <Row align="center" justify="end" className="delete-customer-table__row">
                  <Col md={3}>
                    <Text className="delete-customer-table__label" content="DELETE_CUSTOMER_MODAL_CUSTOMER_ID" noMargin />
                  </Col>
                  <Col md={9}>
                    <Text shouldTranslate={false} content={customerUniqueId} noMargin />
                  </Col>
                </Row>
              </td>
            </tr>
          </tbody>
        </Table>
      </Panel>
    );
  };

  return (
    <form className="delete-customer" onSubmit={onSubmit}>
    <Panel className="delete-customer__titlepanel" title="DELETE_CUSTOMER_MODAL_PANEL1_TITLE">
      <Text
        content="DELETE_CUSTOMER_MODAL_PANEL1_CONTENT"
        translateWith={{
          boldText1: <span className="delete-customer__text--bold">{counterpart("DELETE_CUSTOMER_MODAL_PANEL1_CONTENT_TEXT1")}</span>,
          boldText2: <span className="delete-customer__text--bold">{counterpart("DELETE_CUSTOMER_MODAL_PANEL1_CONTENT_TEXT2")}</span>
        }}
        noMargin
      />
    </Panel>
    {renderTable()}
    <Panel>
      <Text content="DELETE_CUSTOMER_MODAL_PANEL3_CONTENT" />
      <FormGroup>
        <Row align="center">
          <Col md={2}>
            <FormLabel target="customer-id" content="DELETE_CUSTOMER_MODAL_CUSTOMER_ID_INPUT" />
          </Col>
          <Col md={10}>
            <Input
              required
              type="text"
              isError={wrongCustomerUniqueId}
              warninglayout={wrongCustomerUniqueId}
              id="customer-id"
              value={inputCustomerUniqueId}
              onChange={e => setInputCustomerUniqueId(e.target.value)}
              placeholder={counterpart("DELETE_CUSTOMER_MODAL_CUSTOMER_ID_INPUT")}
            />
          </Col>
        </Row>
      </FormGroup>
    </Panel>
    <Button id="delete-customer" type="submit" disabled={customerUniqueId !== inputCustomerUniqueId} buttonType={"general"} isLoading={isLoading && !hasError} title={"DELETE_CUSTOMER"} isFullWidth />
    <ErrorNotification title="DELETE_CUSTOMER_ERROR" showAlert={hasError} onClose={() => setHasError(false)} />
  </form>
  )
}

export default DeleteCustomer;
