import React, { FormEvent, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Panel from "../ui/panel/Panel";
import Text from "../ui/text/Text";
import FormLabel from "../ui/form-label/FormLabel";
import Input from "../ui/input/Input";
import Button from "../ui/button/Button";
import FormGroup from "../ui/form-group/FormGroup";
import Checkbox from "../ui/checkbox/Checkbox";
import Table from "../ui/table/Table";
import counterpart from "counterpart";
import OverridePolicy from "./OverridePolicy";
import { Row, Col } from "react-grid-system";
import { AppState } from "../..";
import { ISubscriptionDetailsReducerState } from "../../reducers/subscriptionDetailsReducer";
import { ICustomerDetailsReducerState } from "../../reducers/customerDetailsReducer";
import moment from "moment";
import "./CancelSubscription.scss";
import { fetchEventLogs } from "../../actions/eventLogsActions";
import { CancelSubscriptionRequest, GetCancellationDetailsRequest, GetCancellationDetailsResponse } from "../../utils/grpc/generated/Billsby.Protos/billing/private/subscription/subscription_pb";
import { grpcUnaryCall } from "../../utils/grpc/grpcUtils";
import { SubscriptionServiceClient } from "../../utils/grpc/generated/Billsby.Protos/billing/private/subscription/SubscriptionServiceClientPb";
import { Timestamp } from "google-protobuf/google/protobuf/timestamp_pb";
import { ConfigConstants } from "../../utils/config";
import { SubscriptionStatusType } from "../../models/Subscriptions";
import ErrorNotification from "../ui/error-notification/ErrorNotification";
import { getCardText } from "../../utils/commonUtils";
import { fetchCustomerInvoices } from "../../actions/customerDetailsActions";
import { ICustomerInvoiceData } from "../../models/Customer";
import { InvoiceStatus, InvoiceType } from "../../models/Invoices";
import NoticePanel from "../ui/notice-panel/NoticePanel";
import ProgressIndicator from "../ui/progress-indicator/ProgressIndicator";
import { IAuthReducerState } from "../../reducers/authReducer";

interface ICancelSubscriptionProps {
  handleCancelSubscriptionCallback: Function
}

const CancelSubscription: React.FC<ICancelSubscriptionProps> = ({ handleCancelSubscriptionCallback }) => {
  const { currentCompanyDomain, currentCompanyId } = useSelector<AppState, IAuthReducerState>(state => state.auth);
  const { subscriptionUniqueId, customerUniqueId, subscriptionId, customerEmail, customerFullname, planName, productName, contractMinimumTermEnd, nextBillingDate, status } = useSelector<AppState, ISubscriptionDetailsReducerState>(state => state.subscriptionDetailsReducer);
  const { phoneNumber, billingAddress, cardDetails } = useSelector<AppState, ICustomerDetailsReducerState>(state => state.customerDetailsReducer);

  const dispatch = useDispatch();

  const [issueRefund, setIssueRefund] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [isLoadingData, setIsLoadingData] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [displayOverridePolicy, setDisplayOverridePolicy] = useState(true);
  const [inputCustomerUniqueId, setInputCustomerUniqueId] = useState("");
  const [hasPendingInvoice, setHasPendingInvoice] = useState(false);
  const [hasRecentUnpaidInvoice, setHasRecentUnpaidInvoice] = useState(false);
  const [refundDetails, setRefundDetails] = useState<any>({
    refundAmount: 0,
    refundAmountFormatted: "",
    billingCurrency: "",
    unusedTimeStartDate: null,
    unusedTimeEndDate: null,
    hasUnusedTimeStartDate: false,
    hasUnusedTimeEndDate: false,
  });

  const isPaidInvoice = (_invoice: ICustomerInvoiceData) => [InvoiceStatus.PAID, InvoiceStatus.PAID_MANUALLY, InvoiceStatus.PAID_OFFLINE, InvoiceStatus.WRITTEN_OFF].some(s => s === _invoice.status);

  useEffect(() => {
    const fetchData = async () => {
      const getCancellationDetailsRequest = new GetCancellationDetailsRequest();
      getCancellationDetailsRequest.setCompanyId(currentCompanyId as number);
      getCancellationDetailsRequest.setSubscriptionId(subscriptionId as number);
      const subscriptionServiceClient = new SubscriptionServiceClient(ConfigConstants.grpcBaseUrl);

      const hasMostRecentUnpaidInvoice = (invoices: ICustomerInvoiceData[]) => {
        const format = "DD MMM YYYY";
        const allRenewalInvoices = invoices.filter(i => i.invoiceType === InvoiceType.RENEWAL);
        const mappedInvoices = allRenewalInvoices.map(i => moment(i.createdOn));
        const invoice = allRenewalInvoices.find(i => moment(i.createdOn).format(format) === moment.max(mappedInvoices).format(format));
        return invoice ? !isPaidInvoice(invoice) : false
      }

      const getDate = (timeStamp?: Timestamp) => {
        if (!timeStamp)
          return null;
        return timeStamp.toDate();
      }

      try {
        setIsLoadingData(true)
        const { response: invoices } = await dispatch(fetchCustomerInvoices(currentCompanyDomain, customerUniqueId)) as unknown as { response: ICustomerInvoiceData[] }
        setHasPendingInvoice(invoices.some(i => !isPaidInvoice(i) && i.invoiceType === InvoiceType.RENEWAL));
        setHasRecentUnpaidInvoice(hasMostRecentUnpaidInvoice(invoices));

        const response = await grpcUnaryCall(getCancellationDetailsRequest, subscriptionServiceClient, subscriptionServiceClient.getCancellationDetails) as GetCancellationDetailsResponse;
        setRefundDetails({
          refundAmount: response.getRefundAmount(),
          refundAmountFormatted: response.getRefundAmountFormatted(),
          billingCurrency: response.getBillingCurrency(),
          unusedTimeStartDate: getDate(response.getUnusedTimeStartDate()),
          unusedTimeEndDate: getDate(response.getUnusedTimeEndDate()),
          hasUnusedTimeStartDate: response.hasUnusedTimeStartDate(),
          hasUnusedTimeEndDate: response.hasUnusedTimeEndDate()
        })
      }
      catch (err) { }
      finally {
        setIsLoadingData(false)
      }
    }

    fetchData();
  }, []);

  const wrongCustomerUniqueId = useMemo(() => {
    return inputCustomerUniqueId !== "" && customerUniqueId !== inputCustomerUniqueId
  }, [inputCustomerUniqueId])

  const onSubmit = async (evt: FormEvent) => {
    evt.preventDefault();
    evt.stopPropagation();
    setIsLoading(true);

    try {
      const cancelSubscriptionRequest = new CancelSubscriptionRequest();
      cancelSubscriptionRequest.setCompanyId(currentCompanyId as number);
      cancelSubscriptionRequest.setSubscriptionId(subscriptionId as number);
      cancelSubscriptionRequest.setIssueRefund(issueRefund);
      const subscriptionServiceClient = new SubscriptionServiceClient(ConfigConstants.grpcBaseUrl);
      await grpcUnaryCall(cancelSubscriptionRequest, subscriptionServiceClient, subscriptionServiceClient.cancelSubscription);
      await handleCancelSubscriptionCallback();
      dispatch(fetchEventLogs(currentCompanyDomain, subscriptionUniqueId, 1, 6));
    } catch (err) {
      setHasError(true)
    } finally {
      setIsLoading(false)
    }
  };

  const renderTable = () => {
    const { lastFourDigits, cardType, expiryMonth, expiryYear } = cardDetails;
    const expiryDate = !!expiryMonth && !!expiryYear ? moment().month(expiryMonth).year(expiryYear).format("MMM YYYY") : "";
    return (
      <Panel>
        <Text content="CANCELING_SUBSCRIPTION_PANEL2_CONTENT" />
        <Table className="cancel-subscription-table">
          <tbody>
            <tr>
              <td>
                <Row align="center" justify="start" className="cancel-subscription-table__row">
                  <Col md={3}>
                    <Text content="CANCELING_SUBSCRIPTION_EMAIL" noMargin />
                  </Col>

                  <Col md={9}>
                    <Text shouldTranslate={false} content={customerEmail} noMargin />
                  </Col>
                </Row>
              </td>
            </tr>
            <tr>
              <td>
                <Row align="center" justify="start" className="cancel-subscription-table__row">
                  <Col md={3}>
                    <Text content="CANCELING_SUBSCRIPTION_FULL_NAME" noMargin />
                  </Col>

                  <Col md={9}>
                    <Text shouldTranslate={false} content={customerFullname} noMargin />
                  </Col>
                </Row>
              </td>
            </tr>
            <tr>
              <td>
                <Row align="center" justify="end" className="cancel-subscription-table__row">
                  <Col md={3}>
                    <Text content="CANCELING_SUBSCRIPTION" noMargin />
                  </Col>

                  <Col md={9}>
                    <Text shouldTranslate={false} content={planName} noMargin />
                    <Text shouldTranslate={false} className="cancel-subscription-table__row__sub-text" content={productName} noMargin />
                  </Col>
                </Row>
              </td>
            </tr>
            <tr className="no-max-height">
              <td>
                <Row align="start" justify="end" className="cancel-subscription-table__row">
                  <Col md={3}>
                    <Text content="CANCELING_SUBSCRIPTION_ADDRESS" noMargin />
                  </Col>

                  <Col md={9}>
                    <Text shouldTranslate={false} content={billingAddress.addressLine1} noMargin />
                    <Text shouldTranslate={false} content={billingAddress.addressLine2} noMargin />
                    <Text shouldTranslate={false} content={billingAddress.state} noMargin />
                    <Text shouldTranslate={false} content={billingAddress.city} noMargin />
                    <Text shouldTranslate={false} content={billingAddress.country} noMargin />
                    <Text shouldTranslate={false} content={billingAddress.postCode} noMargin />
                  </Col>
                </Row>
              </td>
            </tr>
            <tr>
              <td>
                <Row align="center" justify="end" className="cancel-subscription-table__row">
                  <Col md={3}>
                    <Text content="CANCELING_SUBSCRIPTION_PHONE_NUMBER" noMargin />
                  </Col>

                  <Col md={9}>
                    <Text shouldTranslate={false} content={phoneNumber} noMargin />
                  </Col>
                </Row>
              </td>
            </tr>
            <tr>
              <td>
                <Row align="center" justify="end" className="cancel-subscription-table__row">
                  <Col md={3}>
                    <Text content="CANCELING_SUBSCRIPTION_CUSTOMER_ID" noMargin />
                  </Col>

                  <Col md={9}>
                    <Text shouldTranslate={false} content={customerUniqueId} noMargin />
                  </Col>
                </Row>
              </td>
            </tr>
            <tr className="no-max-height">
              <td>
                <Row align="start" justify="end" className="cancel-subscription-table__row">
                  <Col md={3}>
                    <Text content="CANCELING_SUBSCRIPTION_CARD_DETAILS" noMargin />
                  </Col>

                  <Col md={9}>
                    <Text
                      translateWith={{
                        cardType: <span className="capitalize">{getCardText(cardType.replace("_", " "))}</span>,
                        cardLastFourDigit: lastFourDigits
                      }}
                      content="CANCELING_SUBSCRIPTION_CARD_DETAILS_DATA1"
                      noMargin
                    />
                    <Text
                      translateWith={{
                        expiryDate: expiryDate
                      }}
                      content="CANCELING_SUBSCRIPTION_CARD_DETAILS_DATA2"
                      noMargin
                    />
                  </Col>
                </Row>
              </td>
            </tr>
          </tbody>
        </Table>
      </Panel>
    );
  }

  const renderRefundOption = () => {
    const { lastFourDigits, cardType } = cardDetails;
    const startDate = moment(refundDetails.unusedTimeStartDate).format("DD MMM YYYY");
    const endDate = moment(refundDetails.unusedTimeEndDate).format("DD MMM YYYY");
    return (
      <Panel>
        <Text content="CANCELING_SUBSCRIPTION_PANEL3_CONTENT" />
        <FormGroup noMargin>
          {!hasRecentUnpaidInvoice && <Checkbox
            checked={issueRefund}
            onClick={() => setIssueRefund(true)}
            value="Issue refund"
            content={
              <Text
                content="CANCELING_SUBSCRIPTION_OPT1"
                translateWith={{
                  proratedRefundValue: refundDetails.refundAmountFormatted,
                  refundStartDate: startDate,
                  refundEndDate: endDate,
                  cardType: getCardText(cardType),
                  cardLastFourDigit: lastFourDigits
                }}
                noMargin
              />
            }
          />}
          <Checkbox checked={!issueRefund} noMargin onClick={() => setIssueRefund(false)} value="Do not issue refund" content={<Text content="CANCELING_SUBSCRIPTION_OPT2" noMargin />} />
        </FormGroup>
      </Panel>
    );
  };


  if (isLoadingData) {
    return <ProgressIndicator color="blue" coverage='normal' />
  }

  if (displayOverridePolicy && contractMinimumTermEnd && contractMinimumTermEnd > nextBillingDate) {
    return <OverridePolicy onClick={() => setDisplayOverridePolicy(false)}
      contractMinimumTermEnd={contractMinimumTermEnd} />;
  }

  return (
    <form className="cancel-subscription" onSubmit={onSubmit}>
      {(hasRecentUnpaidInvoice || hasPendingInvoice) && <NoticePanel
        type="error"
        content={"CANCELING_SUBSCRIPTION_WARNING_PENDING_INVOICE"}
        isModal
      />}
      <Panel className="cancel-subscription__titlepanel" title="CANCELING_SUBSCRIPTION_PANEL1_TITLE">
        <Text content="CANCELING_SUBSCRIPTION_PANEL1_CONTENT" noMargin />
      </Panel>
      {renderTable()}
      {status !== SubscriptionStatusType.INTRIAL && renderRefundOption()}
      <Panel>
        <Text content="CANCELING_SUBSCRIPTION_PANEL4_CONTENT" />
        <div>
          <Row align="center">
            <Col md={2}>
              <FormLabel target="customer-id" content="CANCELING_SUBSCRIPTION_CUSTOMER_ID_INPUT" />
            </Col>
            <Col md={10}>
              <Input
                required
                type="text"
                isError={wrongCustomerUniqueId}
                warninglayout={wrongCustomerUniqueId}
                id="customer-id"
                value={inputCustomerUniqueId}
                onChange={e =>
                  setInputCustomerUniqueId(e.target.value)
                }
                placeholder={counterpart("CANCELING_SUBSCRIPTION_CUSTOMER_ID_INPUT")}
              />
            </Col>
          </Row>
        </div>
      </Panel>

      <Button
        id="cancel-subscription"
        type="submit"
        disabled={(customerUniqueId !== inputCustomerUniqueId) || hasPendingInvoice}
        icon={""}
        buttonType={"general"}
        isLoading={isLoading && !hasError}
        title={"CANCELING_SUBSCRIPTION_CANCEL"}
        isFullWidth
      />

      <ErrorNotification title="CANCEL_SUBSCRIPTION_ERROR" showAlert={hasError} onClose={() => setHasError(false)} />
    </form>
  )
}


export default CancelSubscription;
