import { GetEmailTemplateConfigRequest, UpsertEmailTemplateConfigRequest } from "../utils/grpc/generated/Billsby.Protos/Messenger/private/companyconfig/companyconfig_pb";
import { grpcUnaryCall } from "../utils/grpc/grpcUtils";
import { CompanyConfigServiceClient } from "../utils/grpc/generated/Billsby.Protos/Messenger/private/companyconfig/CompanyconfigServiceClientPb";
import { makeGenericActionCreator, makeActionCreator } from "../utils/reduxUtils";
import API from "../utils/API";
import { ConfigConstants } from "../utils/config";

export const FETCH_EMAIL_BASE_TEMPLATE_REQUEST = "FETCH_EMAIL_BASE_TEMPLATE_REQUEST";
export const FETCH_EMAIL_BASE_TEMPLATE_SUCCESS = "FETCH_EMAIL_BASE_TEMPLATE_SUCCESS";
export const FETCH_EMAIL_BASE_TEMPLATE_FAILURE = "FETCH_EMAIL_BASE_TEMPLATE_FAILURE";

export const UPSERT_EMAIL_BASE_TEMPLATE_REQUEST = "UPSERT_EMAIL_BASE_TEMPLATE_REQUEST";
export const UPSERT_EMAIL_BASE_TEMPLATE_SUCCESS = "UPSERT_EMAIL_BASE_TEMPLATE_SUCCESS";
export const UPSERT_EMAIL_BASE_TEMPLATE_FAILURE = "UPSERT_EMAIL_BASE_TEMPLATE_FAILURE";

export const UPDATE_EMAIL_BASE_TEMPLATE_ADS_BANNER_REQUEST = "UPDATE_EMAIL_BASE_TEMPLATE_ADS_BANNER_REQUEST";
export const UPDATE_EMAIL_BASE_TEMPLATE_ADS_BANNER_SUCCESS = "UPDATE_EMAIL_BASE_TEMPLATE_ADS_BANNER_SUCCESS";
export const UPDATE_EMAIL_BASE_TEMPLATE_ADS_BANNER_FAILURE = "UPDATE_EMAIL_BASE_TEMPLATE_ADS_BANNER_FAILURE";

export const SET_BASE_EMAIL_TEMPLATE_FIELD = "SET_BASE_EMAIL_TEMPLATE_FIELD";
type AllowedEmailTemplateReducerFields = "includeAdvert" | "avertisementImageUrl" | "advertisementUrl" | "footerText" | "errorMessage";
export const setBaseEmailTemplateField = makeGenericActionCreator<AllowedEmailTemplateReducerFields>(SET_BASE_EMAIL_TEMPLATE_FIELD);

export const RESET_EMAIL_BASE_TEMPLATE_VALUES = "RESET_EMAIL_BASE_TEMPLATE_VALUES";
export const resetEmailBaseTemplateValues = makeActionCreator(RESET_EMAIL_BASE_TEMPLATE_VALUES, "payload");

export const fetchEmailBaseTemplate = function(
  companyId: number
) {
  const getCustomerCardDetailsRequest = new GetEmailTemplateConfigRequest();
  getCustomerCardDetailsRequest.setCompanyId(companyId);
  const companyConfigServiceClient = new CompanyConfigServiceClient(ConfigConstants.grpcBaseUrl);
  return {
    types: [
      FETCH_EMAIL_BASE_TEMPLATE_REQUEST,
      FETCH_EMAIL_BASE_TEMPLATE_SUCCESS,
      FETCH_EMAIL_BASE_TEMPLATE_FAILURE
    ],
    callAPI: () => grpcUnaryCall(getCustomerCardDetailsRequest, companyConfigServiceClient, companyConfigServiceClient.getEmailTemplateConfig)
  };
};

export const updateEmailBaseTemplate = function(
  companyId: number, advertisementUrl: string, advertisementImageUrl: string, footerText: string, includeAdvert: boolean
) {
  const upsertEmailTemplateConfigRequest = new UpsertEmailTemplateConfigRequest();
  upsertEmailTemplateConfigRequest.setCompanyId(companyId);
  upsertEmailTemplateConfigRequest.setAdvertisementUrl(advertisementUrl);
  upsertEmailTemplateConfigRequest.setAdvertisementImageUrl(advertisementImageUrl);
  upsertEmailTemplateConfigRequest.setFooterText(footerText);
  upsertEmailTemplateConfigRequest.setIncludeAdvert(includeAdvert);
  const companyConfigServiceClient = new CompanyConfigServiceClient(ConfigConstants.grpcBaseUrl);

  return {
    types: [
      UPSERT_EMAIL_BASE_TEMPLATE_REQUEST,
      UPSERT_EMAIL_BASE_TEMPLATE_SUCCESS,
      UPSERT_EMAIL_BASE_TEMPLATE_FAILURE
    ],
    callAPI: () => grpcUnaryCall(upsertEmailTemplateConfigRequest, companyConfigServiceClient, companyConfigServiceClient.upsertEmailTemplateConfig)
  };
};

export const updateBaseAdsBanner = function (companyDomain: string, file: File) {
  return {
    types: [UPDATE_EMAIL_BASE_TEMPLATE_ADS_BANNER_REQUEST, UPDATE_EMAIL_BASE_TEMPLATE_ADS_BANNER_SUCCESS, UPDATE_EMAIL_BASE_TEMPLATE_ADS_BANNER_FAILURE],
    callAPI: () => API.uploadCompanyImage(companyDomain, file)
  }
}