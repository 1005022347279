/* eslint-disable */
// source: invoice.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = Function('return this')();

var google_protobuf_timestamp_pb = require('google-protobuf/google/protobuf/timestamp_pb.js');
goog.object.extend(proto, google_protobuf_timestamp_pb);
var google_protobuf_wrappers_pb = require('google-protobuf/google/protobuf/wrappers_pb.js');
goog.object.extend(proto, google_protobuf_wrappers_pb);
goog.exportSymbol('proto.billing.public.invoice.AddonsAllowances', null, global);
goog.exportSymbol('proto.billing.public.invoice.AllowanceOverage', null, global);
goog.exportSymbol('proto.billing.public.invoice.GetInvoicePricingDisclosureResponse', null, global);
goog.exportSymbol('proto.billing.public.invoice.GetInvoiceSimulationRequest', null, global);
goog.exportSymbol('proto.billing.public.invoice.GetInvoiceSimulationResponse', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.billing.public.invoice.GetInvoiceSimulationRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.billing.public.invoice.GetInvoiceSimulationRequest.repeatedFields_, null);
};
goog.inherits(proto.billing.public.invoice.GetInvoiceSimulationRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.billing.public.invoice.GetInvoiceSimulationRequest.displayName = 'proto.billing.public.invoice.GetInvoiceSimulationRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.billing.public.invoice.GetInvoiceSimulationResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.billing.public.invoice.GetInvoiceSimulationResponse.repeatedFields_, null);
};
goog.inherits(proto.billing.public.invoice.GetInvoiceSimulationResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.billing.public.invoice.GetInvoiceSimulationResponse.displayName = 'proto.billing.public.invoice.GetInvoiceSimulationResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.billing.public.invoice.AllowanceOverage = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.billing.public.invoice.AllowanceOverage.repeatedFields_, null);
};
goog.inherits(proto.billing.public.invoice.AllowanceOverage, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.billing.public.invoice.AllowanceOverage.displayName = 'proto.billing.public.invoice.AllowanceOverage';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.billing.public.invoice.AddonsAllowances = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.billing.public.invoice.AddonsAllowances, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.billing.public.invoice.AddonsAllowances.displayName = 'proto.billing.public.invoice.AddonsAllowances';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.billing.public.invoice.GetInvoicePricingDisclosureResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.billing.public.invoice.GetInvoicePricingDisclosureResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.billing.public.invoice.GetInvoicePricingDisclosureResponse.displayName = 'proto.billing.public.invoice.GetInvoicePricingDisclosureResponse';
}

/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.billing.public.invoice.GetInvoiceSimulationRequest.repeatedFields_ = [5,6,7,8];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.billing.public.invoice.GetInvoiceSimulationRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.billing.public.invoice.GetInvoiceSimulationRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.billing.public.invoice.GetInvoiceSimulationRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing.public.invoice.GetInvoiceSimulationRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    companyDomain: jspb.Message.getFieldWithDefault(msg, 1, ""),
    currentCycleId: (f = msg.getCurrentCycleId()) && google_protobuf_wrappers_pb.Int32Value.toObject(includeInstance, f),
    cycleId: jspb.Message.getFieldWithDefault(msg, 3, 0),
    units: jspb.Message.getFieldWithDefault(msg, 4, 0),
    addonsList: jspb.Message.toObjectList(msg.getAddonsList(),
    proto.billing.public.invoice.AddonsAllowances.toObject, includeInstance),
    allowancesList: jspb.Message.toObjectList(msg.getAllowancesList(),
    proto.billing.public.invoice.AddonsAllowances.toObject, includeInstance),
    couponsList: (f = jspb.Message.getRepeatedField(msg, 7)) == null ? undefined : f,
    discountsList: (f = jspb.Message.getRepeatedField(msg, 8)) == null ? undefined : f,
    customerCountry: jspb.Message.getFieldWithDefault(msg, 9, ""),
    allowanceOverage: (f = msg.getAllowanceOverage()) && proto.billing.public.invoice.AllowanceOverage.toObject(includeInstance, f),
    iterationsSkip: (f = msg.getIterationsSkip()) && google_protobuf_wrappers_pb.Int32Value.toObject(includeInstance, f),
    startedOn: (f = msg.getStartedOn()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.billing.public.invoice.GetInvoiceSimulationRequest}
 */
proto.billing.public.invoice.GetInvoiceSimulationRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.billing.public.invoice.GetInvoiceSimulationRequest;
  return proto.billing.public.invoice.GetInvoiceSimulationRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.billing.public.invoice.GetInvoiceSimulationRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.billing.public.invoice.GetInvoiceSimulationRequest}
 */
proto.billing.public.invoice.GetInvoiceSimulationRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setCompanyDomain(value);
      break;
    case 2:
      var value = new google_protobuf_wrappers_pb.Int32Value;
      reader.readMessage(value,google_protobuf_wrappers_pb.Int32Value.deserializeBinaryFromReader);
      msg.setCurrentCycleId(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setCycleId(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setUnits(value);
      break;
    case 5:
      var value = new proto.billing.public.invoice.AddonsAllowances;
      reader.readMessage(value,proto.billing.public.invoice.AddonsAllowances.deserializeBinaryFromReader);
      msg.addAddons(value);
      break;
    case 6:
      var value = new proto.billing.public.invoice.AddonsAllowances;
      reader.readMessage(value,proto.billing.public.invoice.AddonsAllowances.deserializeBinaryFromReader);
      msg.addAllowances(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.addCoupons(value);
      break;
    case 8:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedInt32() : [reader.readInt32()]);
      for (var i = 0; i < values.length; i++) {
        msg.addDiscounts(values[i]);
      }
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setCustomerCountry(value);
      break;
    case 10:
      var value = new proto.billing.public.invoice.AllowanceOverage;
      reader.readMessage(value,proto.billing.public.invoice.AllowanceOverage.deserializeBinaryFromReader);
      msg.setAllowanceOverage(value);
      break;
    case 11:
      var value = new google_protobuf_wrappers_pb.Int32Value;
      reader.readMessage(value,google_protobuf_wrappers_pb.Int32Value.deserializeBinaryFromReader);
      msg.setIterationsSkip(value);
      break;
    case 12:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setStartedOn(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.billing.public.invoice.GetInvoiceSimulationRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.billing.public.invoice.GetInvoiceSimulationRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.billing.public.invoice.GetInvoiceSimulationRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing.public.invoice.GetInvoiceSimulationRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCompanyDomain();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getCurrentCycleId();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      google_protobuf_wrappers_pb.Int32Value.serializeBinaryToWriter
    );
  }
  f = message.getCycleId();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
  f = message.getUnits();
  if (f !== 0) {
    writer.writeInt32(
      4,
      f
    );
  }
  f = message.getAddonsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      5,
      f,
      proto.billing.public.invoice.AddonsAllowances.serializeBinaryToWriter
    );
  }
  f = message.getAllowancesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      6,
      f,
      proto.billing.public.invoice.AddonsAllowances.serializeBinaryToWriter
    );
  }
  f = message.getCouponsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      7,
      f
    );
  }
  f = message.getDiscountsList();
  if (f.length > 0) {
    writer.writePackedInt32(
      8,
      f
    );
  }
  f = message.getCustomerCountry();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getAllowanceOverage();
  if (f != null) {
    writer.writeMessage(
      10,
      f,
      proto.billing.public.invoice.AllowanceOverage.serializeBinaryToWriter
    );
  }
  f = message.getIterationsSkip();
  if (f != null) {
    writer.writeMessage(
      11,
      f,
      google_protobuf_wrappers_pb.Int32Value.serializeBinaryToWriter
    );
  }
  f = message.getStartedOn();
  if (f != null) {
    writer.writeMessage(
      12,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
};


/**
 * optional string company_domain = 1;
 * @return {string}
 */
proto.billing.public.invoice.GetInvoiceSimulationRequest.prototype.getCompanyDomain = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.billing.public.invoice.GetInvoiceSimulationRequest} returns this
 */
proto.billing.public.invoice.GetInvoiceSimulationRequest.prototype.setCompanyDomain = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional google.protobuf.Int32Value current_cycle_id = 2;
 * @return {?proto.google.protobuf.Int32Value}
 */
proto.billing.public.invoice.GetInvoiceSimulationRequest.prototype.getCurrentCycleId = function() {
  return /** @type{?proto.google.protobuf.Int32Value} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.Int32Value, 2));
};


/**
 * @param {?proto.google.protobuf.Int32Value|undefined} value
 * @return {!proto.billing.public.invoice.GetInvoiceSimulationRequest} returns this
*/
proto.billing.public.invoice.GetInvoiceSimulationRequest.prototype.setCurrentCycleId = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.billing.public.invoice.GetInvoiceSimulationRequest} returns this
 */
proto.billing.public.invoice.GetInvoiceSimulationRequest.prototype.clearCurrentCycleId = function() {
  return this.setCurrentCycleId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.billing.public.invoice.GetInvoiceSimulationRequest.prototype.hasCurrentCycleId = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional int32 cycle_id = 3;
 * @return {number}
 */
proto.billing.public.invoice.GetInvoiceSimulationRequest.prototype.getCycleId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.billing.public.invoice.GetInvoiceSimulationRequest} returns this
 */
proto.billing.public.invoice.GetInvoiceSimulationRequest.prototype.setCycleId = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional int32 units = 4;
 * @return {number}
 */
proto.billing.public.invoice.GetInvoiceSimulationRequest.prototype.getUnits = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.billing.public.invoice.GetInvoiceSimulationRequest} returns this
 */
proto.billing.public.invoice.GetInvoiceSimulationRequest.prototype.setUnits = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * repeated AddonsAllowances addons = 5;
 * @return {!Array<!proto.billing.public.invoice.AddonsAllowances>}
 */
proto.billing.public.invoice.GetInvoiceSimulationRequest.prototype.getAddonsList = function() {
  return /** @type{!Array<!proto.billing.public.invoice.AddonsAllowances>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.billing.public.invoice.AddonsAllowances, 5));
};


/**
 * @param {!Array<!proto.billing.public.invoice.AddonsAllowances>} value
 * @return {!proto.billing.public.invoice.GetInvoiceSimulationRequest} returns this
*/
proto.billing.public.invoice.GetInvoiceSimulationRequest.prototype.setAddonsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 5, value);
};


/**
 * @param {!proto.billing.public.invoice.AddonsAllowances=} opt_value
 * @param {number=} opt_index
 * @return {!proto.billing.public.invoice.AddonsAllowances}
 */
proto.billing.public.invoice.GetInvoiceSimulationRequest.prototype.addAddons = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 5, opt_value, proto.billing.public.invoice.AddonsAllowances, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.billing.public.invoice.GetInvoiceSimulationRequest} returns this
 */
proto.billing.public.invoice.GetInvoiceSimulationRequest.prototype.clearAddonsList = function() {
  return this.setAddonsList([]);
};


/**
 * repeated AddonsAllowances allowances = 6;
 * @return {!Array<!proto.billing.public.invoice.AddonsAllowances>}
 */
proto.billing.public.invoice.GetInvoiceSimulationRequest.prototype.getAllowancesList = function() {
  return /** @type{!Array<!proto.billing.public.invoice.AddonsAllowances>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.billing.public.invoice.AddonsAllowances, 6));
};


/**
 * @param {!Array<!proto.billing.public.invoice.AddonsAllowances>} value
 * @return {!proto.billing.public.invoice.GetInvoiceSimulationRequest} returns this
*/
proto.billing.public.invoice.GetInvoiceSimulationRequest.prototype.setAllowancesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 6, value);
};


/**
 * @param {!proto.billing.public.invoice.AddonsAllowances=} opt_value
 * @param {number=} opt_index
 * @return {!proto.billing.public.invoice.AddonsAllowances}
 */
proto.billing.public.invoice.GetInvoiceSimulationRequest.prototype.addAllowances = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 6, opt_value, proto.billing.public.invoice.AddonsAllowances, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.billing.public.invoice.GetInvoiceSimulationRequest} returns this
 */
proto.billing.public.invoice.GetInvoiceSimulationRequest.prototype.clearAllowancesList = function() {
  return this.setAllowancesList([]);
};


/**
 * repeated string coupons = 7;
 * @return {!Array<string>}
 */
proto.billing.public.invoice.GetInvoiceSimulationRequest.prototype.getCouponsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 7));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.billing.public.invoice.GetInvoiceSimulationRequest} returns this
 */
proto.billing.public.invoice.GetInvoiceSimulationRequest.prototype.setCouponsList = function(value) {
  return jspb.Message.setField(this, 7, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.billing.public.invoice.GetInvoiceSimulationRequest} returns this
 */
proto.billing.public.invoice.GetInvoiceSimulationRequest.prototype.addCoupons = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 7, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.billing.public.invoice.GetInvoiceSimulationRequest} returns this
 */
proto.billing.public.invoice.GetInvoiceSimulationRequest.prototype.clearCouponsList = function() {
  return this.setCouponsList([]);
};


/**
 * repeated int32 discounts = 8;
 * @return {!Array<number>}
 */
proto.billing.public.invoice.GetInvoiceSimulationRequest.prototype.getDiscountsList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 8));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.billing.public.invoice.GetInvoiceSimulationRequest} returns this
 */
proto.billing.public.invoice.GetInvoiceSimulationRequest.prototype.setDiscountsList = function(value) {
  return jspb.Message.setField(this, 8, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.billing.public.invoice.GetInvoiceSimulationRequest} returns this
 */
proto.billing.public.invoice.GetInvoiceSimulationRequest.prototype.addDiscounts = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 8, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.billing.public.invoice.GetInvoiceSimulationRequest} returns this
 */
proto.billing.public.invoice.GetInvoiceSimulationRequest.prototype.clearDiscountsList = function() {
  return this.setDiscountsList([]);
};


/**
 * optional string customer_country = 9;
 * @return {string}
 */
proto.billing.public.invoice.GetInvoiceSimulationRequest.prototype.getCustomerCountry = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.billing.public.invoice.GetInvoiceSimulationRequest} returns this
 */
proto.billing.public.invoice.GetInvoiceSimulationRequest.prototype.setCustomerCountry = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional AllowanceOverage allowance_overage = 10;
 * @return {?proto.billing.public.invoice.AllowanceOverage}
 */
proto.billing.public.invoice.GetInvoiceSimulationRequest.prototype.getAllowanceOverage = function() {
  return /** @type{?proto.billing.public.invoice.AllowanceOverage} */ (
    jspb.Message.getWrapperField(this, proto.billing.public.invoice.AllowanceOverage, 10));
};


/**
 * @param {?proto.billing.public.invoice.AllowanceOverage|undefined} value
 * @return {!proto.billing.public.invoice.GetInvoiceSimulationRequest} returns this
*/
proto.billing.public.invoice.GetInvoiceSimulationRequest.prototype.setAllowanceOverage = function(value) {
  return jspb.Message.setWrapperField(this, 10, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.billing.public.invoice.GetInvoiceSimulationRequest} returns this
 */
proto.billing.public.invoice.GetInvoiceSimulationRequest.prototype.clearAllowanceOverage = function() {
  return this.setAllowanceOverage(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.billing.public.invoice.GetInvoiceSimulationRequest.prototype.hasAllowanceOverage = function() {
  return jspb.Message.getField(this, 10) != null;
};


/**
 * optional google.protobuf.Int32Value iterations_skip = 11;
 * @return {?proto.google.protobuf.Int32Value}
 */
proto.billing.public.invoice.GetInvoiceSimulationRequest.prototype.getIterationsSkip = function() {
  return /** @type{?proto.google.protobuf.Int32Value} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.Int32Value, 11));
};


/**
 * @param {?proto.google.protobuf.Int32Value|undefined} value
 * @return {!proto.billing.public.invoice.GetInvoiceSimulationRequest} returns this
*/
proto.billing.public.invoice.GetInvoiceSimulationRequest.prototype.setIterationsSkip = function(value) {
  return jspb.Message.setWrapperField(this, 11, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.billing.public.invoice.GetInvoiceSimulationRequest} returns this
 */
proto.billing.public.invoice.GetInvoiceSimulationRequest.prototype.clearIterationsSkip = function() {
  return this.setIterationsSkip(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.billing.public.invoice.GetInvoiceSimulationRequest.prototype.hasIterationsSkip = function() {
  return jspb.Message.getField(this, 11) != null;
};


/**
 * optional google.protobuf.Timestamp started_on = 12;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.billing.public.invoice.GetInvoiceSimulationRequest.prototype.getStartedOn = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 12));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.billing.public.invoice.GetInvoiceSimulationRequest} returns this
*/
proto.billing.public.invoice.GetInvoiceSimulationRequest.prototype.setStartedOn = function(value) {
  return jspb.Message.setWrapperField(this, 12, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.billing.public.invoice.GetInvoiceSimulationRequest} returns this
 */
proto.billing.public.invoice.GetInvoiceSimulationRequest.prototype.clearStartedOn = function() {
  return this.setStartedOn(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.billing.public.invoice.GetInvoiceSimulationRequest.prototype.hasStartedOn = function() {
  return jspb.Message.getField(this, 12) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.billing.public.invoice.GetInvoiceSimulationResponse.repeatedFields_ = [16,20];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.billing.public.invoice.GetInvoiceSimulationResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.billing.public.invoice.GetInvoiceSimulationResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.billing.public.invoice.GetInvoiceSimulationResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing.public.invoice.GetInvoiceSimulationResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    billingCurrency: jspb.Message.getFieldWithDefault(msg, 1, ""),
    firstInvoicePrice: jspb.Message.getFieldWithDefault(msg, 2, ""),
    firstInvoiceTotalPrice: jspb.Message.getFieldWithDefault(msg, 3, ""),
    firstInvoiceTotalWithoutDiscountsPrice: jspb.Message.getFieldWithDefault(msg, 4, ""),
    firstInvoiceDate: jspb.Message.getFieldWithDefault(msg, 5, ""),
    secondInvoicePrice: jspb.Message.getFieldWithDefault(msg, 6, ""),
    secondInvoiceTotalWithoutDiscountsPrice: jspb.Message.getFieldWithDefault(msg, 7, ""),
    secondInvoiceDate: jspb.Message.getFieldWithDefault(msg, 8, ""),
    frequency: jspb.Message.getFieldWithDefault(msg, 9, 0),
    frequencyType: jspb.Message.getFieldWithDefault(msg, 10, ""),
    setupFee: jspb.Message.getFieldWithDefault(msg, 11, ""),
    proRateOption: jspb.Message.getFieldWithDefault(msg, 12, ""),
    endTrialDate: jspb.Message.getFieldWithDefault(msg, 13, ""),
    hasInvoices: jspb.Message.getBooleanFieldWithDefault(msg, 14, false),
    allowancesOveragePrice: jspb.Message.getFieldWithDefault(msg, 15, ""),
    allowanceNamesList: (f = jspb.Message.getRepeatedField(msg, 16)) == null ? undefined : f,
    allowanceProrated: jspb.Message.getFieldWithDefault(msg, 17, 0),
    hasAllowanceWithOverage: jspb.Message.getBooleanFieldWithDefault(msg, 18, false),
    addonsPrice: jspb.Message.getFieldWithDefault(msg, 19, ""),
    addonsNamesList: (f = jspb.Message.getRepeatedField(msg, 20)) == null ? undefined : f,
    taxesIncluded: jspb.Message.getBooleanFieldWithDefault(msg, 21, false),
    customerTaxExemptionEnabled: jspb.Message.getBooleanFieldWithDefault(msg, 22, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.billing.public.invoice.GetInvoiceSimulationResponse}
 */
proto.billing.public.invoice.GetInvoiceSimulationResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.billing.public.invoice.GetInvoiceSimulationResponse;
  return proto.billing.public.invoice.GetInvoiceSimulationResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.billing.public.invoice.GetInvoiceSimulationResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.billing.public.invoice.GetInvoiceSimulationResponse}
 */
proto.billing.public.invoice.GetInvoiceSimulationResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setBillingCurrency(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setFirstInvoicePrice(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setFirstInvoiceTotalPrice(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setFirstInvoiceTotalWithoutDiscountsPrice(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setFirstInvoiceDate(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setSecondInvoicePrice(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setSecondInvoiceTotalWithoutDiscountsPrice(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setSecondInvoiceDate(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setFrequency(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setFrequencyType(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.setSetupFee(value);
      break;
    case 12:
      var value = /** @type {string} */ (reader.readString());
      msg.setProRateOption(value);
      break;
    case 13:
      var value = /** @type {string} */ (reader.readString());
      msg.setEndTrialDate(value);
      break;
    case 14:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setHasInvoices(value);
      break;
    case 15:
      var value = /** @type {string} */ (reader.readString());
      msg.setAllowancesOveragePrice(value);
      break;
    case 16:
      var value = /** @type {string} */ (reader.readString());
      msg.addAllowanceNames(value);
      break;
    case 17:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setAllowanceProrated(value);
      break;
    case 18:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setHasAllowanceWithOverage(value);
      break;
    case 19:
      var value = /** @type {string} */ (reader.readString());
      msg.setAddonsPrice(value);
      break;
    case 20:
      var value = /** @type {string} */ (reader.readString());
      msg.addAddonsNames(value);
      break;
    case 21:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setTaxesIncluded(value);
      break;
    case 22:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setCustomerTaxExemptionEnabled(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.billing.public.invoice.GetInvoiceSimulationResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.billing.public.invoice.GetInvoiceSimulationResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.billing.public.invoice.GetInvoiceSimulationResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing.public.invoice.GetInvoiceSimulationResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getBillingCurrency();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getFirstInvoicePrice();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getFirstInvoiceTotalPrice();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getFirstInvoiceTotalWithoutDiscountsPrice();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getFirstInvoiceDate();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getSecondInvoicePrice();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getSecondInvoiceTotalWithoutDiscountsPrice();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getSecondInvoiceDate();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getFrequency();
  if (f !== 0) {
    writer.writeInt32(
      9,
      f
    );
  }
  f = message.getFrequencyType();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getSetupFee();
  if (f.length > 0) {
    writer.writeString(
      11,
      f
    );
  }
  f = message.getProRateOption();
  if (f.length > 0) {
    writer.writeString(
      12,
      f
    );
  }
  f = message.getEndTrialDate();
  if (f.length > 0) {
    writer.writeString(
      13,
      f
    );
  }
  f = message.getHasInvoices();
  if (f) {
    writer.writeBool(
      14,
      f
    );
  }
  f = message.getAllowancesOveragePrice();
  if (f.length > 0) {
    writer.writeString(
      15,
      f
    );
  }
  f = message.getAllowanceNamesList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      16,
      f
    );
  }
  f = message.getAllowanceProrated();
  if (f !== 0) {
    writer.writeInt32(
      17,
      f
    );
  }
  f = message.getHasAllowanceWithOverage();
  if (f) {
    writer.writeBool(
      18,
      f
    );
  }
  f = message.getAddonsPrice();
  if (f.length > 0) {
    writer.writeString(
      19,
      f
    );
  }
  f = message.getAddonsNamesList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      20,
      f
    );
  }
  f = message.getTaxesIncluded();
  if (f) {
    writer.writeBool(
      21,
      f
    );
  }
  f = message.getCustomerTaxExemptionEnabled();
  if (f) {
    writer.writeBool(
      22,
      f
    );
  }
};


/**
 * optional string billing_currency = 1;
 * @return {string}
 */
proto.billing.public.invoice.GetInvoiceSimulationResponse.prototype.getBillingCurrency = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.billing.public.invoice.GetInvoiceSimulationResponse} returns this
 */
proto.billing.public.invoice.GetInvoiceSimulationResponse.prototype.setBillingCurrency = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string first_invoice_price = 2;
 * @return {string}
 */
proto.billing.public.invoice.GetInvoiceSimulationResponse.prototype.getFirstInvoicePrice = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.billing.public.invoice.GetInvoiceSimulationResponse} returns this
 */
proto.billing.public.invoice.GetInvoiceSimulationResponse.prototype.setFirstInvoicePrice = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string first_invoice_total_price = 3;
 * @return {string}
 */
proto.billing.public.invoice.GetInvoiceSimulationResponse.prototype.getFirstInvoiceTotalPrice = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.billing.public.invoice.GetInvoiceSimulationResponse} returns this
 */
proto.billing.public.invoice.GetInvoiceSimulationResponse.prototype.setFirstInvoiceTotalPrice = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string first_invoice_total_without_discounts_price = 4;
 * @return {string}
 */
proto.billing.public.invoice.GetInvoiceSimulationResponse.prototype.getFirstInvoiceTotalWithoutDiscountsPrice = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.billing.public.invoice.GetInvoiceSimulationResponse} returns this
 */
proto.billing.public.invoice.GetInvoiceSimulationResponse.prototype.setFirstInvoiceTotalWithoutDiscountsPrice = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string first_invoice_date = 5;
 * @return {string}
 */
proto.billing.public.invoice.GetInvoiceSimulationResponse.prototype.getFirstInvoiceDate = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.billing.public.invoice.GetInvoiceSimulationResponse} returns this
 */
proto.billing.public.invoice.GetInvoiceSimulationResponse.prototype.setFirstInvoiceDate = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string second_invoice_price = 6;
 * @return {string}
 */
proto.billing.public.invoice.GetInvoiceSimulationResponse.prototype.getSecondInvoicePrice = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.billing.public.invoice.GetInvoiceSimulationResponse} returns this
 */
proto.billing.public.invoice.GetInvoiceSimulationResponse.prototype.setSecondInvoicePrice = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string second_invoice_total_without_discounts_price = 7;
 * @return {string}
 */
proto.billing.public.invoice.GetInvoiceSimulationResponse.prototype.getSecondInvoiceTotalWithoutDiscountsPrice = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.billing.public.invoice.GetInvoiceSimulationResponse} returns this
 */
proto.billing.public.invoice.GetInvoiceSimulationResponse.prototype.setSecondInvoiceTotalWithoutDiscountsPrice = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string second_invoice_date = 8;
 * @return {string}
 */
proto.billing.public.invoice.GetInvoiceSimulationResponse.prototype.getSecondInvoiceDate = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.billing.public.invoice.GetInvoiceSimulationResponse} returns this
 */
proto.billing.public.invoice.GetInvoiceSimulationResponse.prototype.setSecondInvoiceDate = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional int32 frequency = 9;
 * @return {number}
 */
proto.billing.public.invoice.GetInvoiceSimulationResponse.prototype.getFrequency = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/**
 * @param {number} value
 * @return {!proto.billing.public.invoice.GetInvoiceSimulationResponse} returns this
 */
proto.billing.public.invoice.GetInvoiceSimulationResponse.prototype.setFrequency = function(value) {
  return jspb.Message.setProto3IntField(this, 9, value);
};


/**
 * optional string frequency_type = 10;
 * @return {string}
 */
proto.billing.public.invoice.GetInvoiceSimulationResponse.prototype.getFrequencyType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.billing.public.invoice.GetInvoiceSimulationResponse} returns this
 */
proto.billing.public.invoice.GetInvoiceSimulationResponse.prototype.setFrequencyType = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * optional string setup_fee = 11;
 * @return {string}
 */
proto.billing.public.invoice.GetInvoiceSimulationResponse.prototype.getSetupFee = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};


/**
 * @param {string} value
 * @return {!proto.billing.public.invoice.GetInvoiceSimulationResponse} returns this
 */
proto.billing.public.invoice.GetInvoiceSimulationResponse.prototype.setSetupFee = function(value) {
  return jspb.Message.setProto3StringField(this, 11, value);
};


/**
 * optional string pro_rate_option = 12;
 * @return {string}
 */
proto.billing.public.invoice.GetInvoiceSimulationResponse.prototype.getProRateOption = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 12, ""));
};


/**
 * @param {string} value
 * @return {!proto.billing.public.invoice.GetInvoiceSimulationResponse} returns this
 */
proto.billing.public.invoice.GetInvoiceSimulationResponse.prototype.setProRateOption = function(value) {
  return jspb.Message.setProto3StringField(this, 12, value);
};


/**
 * optional string end_trial_date = 13;
 * @return {string}
 */
proto.billing.public.invoice.GetInvoiceSimulationResponse.prototype.getEndTrialDate = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 13, ""));
};


/**
 * @param {string} value
 * @return {!proto.billing.public.invoice.GetInvoiceSimulationResponse} returns this
 */
proto.billing.public.invoice.GetInvoiceSimulationResponse.prototype.setEndTrialDate = function(value) {
  return jspb.Message.setProto3StringField(this, 13, value);
};


/**
 * optional bool has_invoices = 14;
 * @return {boolean}
 */
proto.billing.public.invoice.GetInvoiceSimulationResponse.prototype.getHasInvoices = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 14, false));
};


/**
 * @param {boolean} value
 * @return {!proto.billing.public.invoice.GetInvoiceSimulationResponse} returns this
 */
proto.billing.public.invoice.GetInvoiceSimulationResponse.prototype.setHasInvoices = function(value) {
  return jspb.Message.setProto3BooleanField(this, 14, value);
};


/**
 * optional string allowances_overage_price = 15;
 * @return {string}
 */
proto.billing.public.invoice.GetInvoiceSimulationResponse.prototype.getAllowancesOveragePrice = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 15, ""));
};


/**
 * @param {string} value
 * @return {!proto.billing.public.invoice.GetInvoiceSimulationResponse} returns this
 */
proto.billing.public.invoice.GetInvoiceSimulationResponse.prototype.setAllowancesOveragePrice = function(value) {
  return jspb.Message.setProto3StringField(this, 15, value);
};


/**
 * repeated string allowance_names = 16;
 * @return {!Array<string>}
 */
proto.billing.public.invoice.GetInvoiceSimulationResponse.prototype.getAllowanceNamesList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 16));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.billing.public.invoice.GetInvoiceSimulationResponse} returns this
 */
proto.billing.public.invoice.GetInvoiceSimulationResponse.prototype.setAllowanceNamesList = function(value) {
  return jspb.Message.setField(this, 16, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.billing.public.invoice.GetInvoiceSimulationResponse} returns this
 */
proto.billing.public.invoice.GetInvoiceSimulationResponse.prototype.addAllowanceNames = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 16, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.billing.public.invoice.GetInvoiceSimulationResponse} returns this
 */
proto.billing.public.invoice.GetInvoiceSimulationResponse.prototype.clearAllowanceNamesList = function() {
  return this.setAllowanceNamesList([]);
};


/**
 * optional int32 allowance_prorated = 17;
 * @return {number}
 */
proto.billing.public.invoice.GetInvoiceSimulationResponse.prototype.getAllowanceProrated = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 17, 0));
};


/**
 * @param {number} value
 * @return {!proto.billing.public.invoice.GetInvoiceSimulationResponse} returns this
 */
proto.billing.public.invoice.GetInvoiceSimulationResponse.prototype.setAllowanceProrated = function(value) {
  return jspb.Message.setProto3IntField(this, 17, value);
};


/**
 * optional bool has_allowance_with_overage = 18;
 * @return {boolean}
 */
proto.billing.public.invoice.GetInvoiceSimulationResponse.prototype.getHasAllowanceWithOverage = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 18, false));
};


/**
 * @param {boolean} value
 * @return {!proto.billing.public.invoice.GetInvoiceSimulationResponse} returns this
 */
proto.billing.public.invoice.GetInvoiceSimulationResponse.prototype.setHasAllowanceWithOverage = function(value) {
  return jspb.Message.setProto3BooleanField(this, 18, value);
};


/**
 * optional string addons_price = 19;
 * @return {string}
 */
proto.billing.public.invoice.GetInvoiceSimulationResponse.prototype.getAddonsPrice = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 19, ""));
};


/**
 * @param {string} value
 * @return {!proto.billing.public.invoice.GetInvoiceSimulationResponse} returns this
 */
proto.billing.public.invoice.GetInvoiceSimulationResponse.prototype.setAddonsPrice = function(value) {
  return jspb.Message.setProto3StringField(this, 19, value);
};


/**
 * repeated string addons_names = 20;
 * @return {!Array<string>}
 */
proto.billing.public.invoice.GetInvoiceSimulationResponse.prototype.getAddonsNamesList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 20));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.billing.public.invoice.GetInvoiceSimulationResponse} returns this
 */
proto.billing.public.invoice.GetInvoiceSimulationResponse.prototype.setAddonsNamesList = function(value) {
  return jspb.Message.setField(this, 20, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.billing.public.invoice.GetInvoiceSimulationResponse} returns this
 */
proto.billing.public.invoice.GetInvoiceSimulationResponse.prototype.addAddonsNames = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 20, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.billing.public.invoice.GetInvoiceSimulationResponse} returns this
 */
proto.billing.public.invoice.GetInvoiceSimulationResponse.prototype.clearAddonsNamesList = function() {
  return this.setAddonsNamesList([]);
};


/**
 * optional bool taxes_included = 21;
 * @return {boolean}
 */
proto.billing.public.invoice.GetInvoiceSimulationResponse.prototype.getTaxesIncluded = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 21, false));
};


/**
 * @param {boolean} value
 * @return {!proto.billing.public.invoice.GetInvoiceSimulationResponse} returns this
 */
proto.billing.public.invoice.GetInvoiceSimulationResponse.prototype.setTaxesIncluded = function(value) {
  return jspb.Message.setProto3BooleanField(this, 21, value);
};


/**
 * optional bool customer_tax_exemption_enabled = 22;
 * @return {boolean}
 */
proto.billing.public.invoice.GetInvoiceSimulationResponse.prototype.getCustomerTaxExemptionEnabled = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 22, false));
};


/**
 * @param {boolean} value
 * @return {!proto.billing.public.invoice.GetInvoiceSimulationResponse} returns this
 */
proto.billing.public.invoice.GetInvoiceSimulationResponse.prototype.setCustomerTaxExemptionEnabled = function(value) {
  return jspb.Message.setProto3BooleanField(this, 22, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.billing.public.invoice.AllowanceOverage.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.billing.public.invoice.AllowanceOverage.prototype.toObject = function(opt_includeInstance) {
  return proto.billing.public.invoice.AllowanceOverage.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.billing.public.invoice.AllowanceOverage} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing.public.invoice.AllowanceOverage.toObject = function(includeInstance, msg) {
  var f, obj = {
    planId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    cycleId: jspb.Message.getFieldWithDefault(msg, 2, 0),
    allowanceOveragesList: jspb.Message.toObjectList(msg.getAllowanceOveragesList(),
    proto.billing.public.invoice.AddonsAllowances.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.billing.public.invoice.AllowanceOverage}
 */
proto.billing.public.invoice.AllowanceOverage.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.billing.public.invoice.AllowanceOverage;
  return proto.billing.public.invoice.AllowanceOverage.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.billing.public.invoice.AllowanceOverage} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.billing.public.invoice.AllowanceOverage}
 */
proto.billing.public.invoice.AllowanceOverage.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPlanId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setCycleId(value);
      break;
    case 3:
      var value = new proto.billing.public.invoice.AddonsAllowances;
      reader.readMessage(value,proto.billing.public.invoice.AddonsAllowances.deserializeBinaryFromReader);
      msg.addAllowanceOverages(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.billing.public.invoice.AllowanceOverage.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.billing.public.invoice.AllowanceOverage.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.billing.public.invoice.AllowanceOverage} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing.public.invoice.AllowanceOverage.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPlanId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getCycleId();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getAllowanceOveragesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.billing.public.invoice.AddonsAllowances.serializeBinaryToWriter
    );
  }
};


/**
 * optional int32 plan_id = 1;
 * @return {number}
 */
proto.billing.public.invoice.AllowanceOverage.prototype.getPlanId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.billing.public.invoice.AllowanceOverage} returns this
 */
proto.billing.public.invoice.AllowanceOverage.prototype.setPlanId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int32 cycle_id = 2;
 * @return {number}
 */
proto.billing.public.invoice.AllowanceOverage.prototype.getCycleId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.billing.public.invoice.AllowanceOverage} returns this
 */
proto.billing.public.invoice.AllowanceOverage.prototype.setCycleId = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * repeated AddonsAllowances allowance_overages = 3;
 * @return {!Array<!proto.billing.public.invoice.AddonsAllowances>}
 */
proto.billing.public.invoice.AllowanceOverage.prototype.getAllowanceOveragesList = function() {
  return /** @type{!Array<!proto.billing.public.invoice.AddonsAllowances>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.billing.public.invoice.AddonsAllowances, 3));
};


/**
 * @param {!Array<!proto.billing.public.invoice.AddonsAllowances>} value
 * @return {!proto.billing.public.invoice.AllowanceOverage} returns this
*/
proto.billing.public.invoice.AllowanceOverage.prototype.setAllowanceOveragesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.billing.public.invoice.AddonsAllowances=} opt_value
 * @param {number=} opt_index
 * @return {!proto.billing.public.invoice.AddonsAllowances}
 */
proto.billing.public.invoice.AllowanceOverage.prototype.addAllowanceOverages = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.billing.public.invoice.AddonsAllowances, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.billing.public.invoice.AllowanceOverage} returns this
 */
proto.billing.public.invoice.AllowanceOverage.prototype.clearAllowanceOveragesList = function() {
  return this.setAllowanceOveragesList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.billing.public.invoice.AddonsAllowances.prototype.toObject = function(opt_includeInstance) {
  return proto.billing.public.invoice.AddonsAllowances.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.billing.public.invoice.AddonsAllowances} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing.public.invoice.AddonsAllowances.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    units: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.billing.public.invoice.AddonsAllowances}
 */
proto.billing.public.invoice.AddonsAllowances.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.billing.public.invoice.AddonsAllowances;
  return proto.billing.public.invoice.AddonsAllowances.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.billing.public.invoice.AddonsAllowances} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.billing.public.invoice.AddonsAllowances}
 */
proto.billing.public.invoice.AddonsAllowances.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setUnits(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.billing.public.invoice.AddonsAllowances.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.billing.public.invoice.AddonsAllowances.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.billing.public.invoice.AddonsAllowances} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing.public.invoice.AddonsAllowances.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getUnits();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
};


/**
 * optional int32 id = 1;
 * @return {number}
 */
proto.billing.public.invoice.AddonsAllowances.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.billing.public.invoice.AddonsAllowances} returns this
 */
proto.billing.public.invoice.AddonsAllowances.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int32 units = 2;
 * @return {number}
 */
proto.billing.public.invoice.AddonsAllowances.prototype.getUnits = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.billing.public.invoice.AddonsAllowances} returns this
 */
proto.billing.public.invoice.AddonsAllowances.prototype.setUnits = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.billing.public.invoice.GetInvoicePricingDisclosureResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.billing.public.invoice.GetInvoicePricingDisclosureResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.billing.public.invoice.GetInvoicePricingDisclosureResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing.public.invoice.GetInvoicePricingDisclosureResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    pricingDisclosure: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.billing.public.invoice.GetInvoicePricingDisclosureResponse}
 */
proto.billing.public.invoice.GetInvoicePricingDisclosureResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.billing.public.invoice.GetInvoicePricingDisclosureResponse;
  return proto.billing.public.invoice.GetInvoicePricingDisclosureResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.billing.public.invoice.GetInvoicePricingDisclosureResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.billing.public.invoice.GetInvoicePricingDisclosureResponse}
 */
proto.billing.public.invoice.GetInvoicePricingDisclosureResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setPricingDisclosure(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.billing.public.invoice.GetInvoicePricingDisclosureResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.billing.public.invoice.GetInvoicePricingDisclosureResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.billing.public.invoice.GetInvoicePricingDisclosureResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing.public.invoice.GetInvoicePricingDisclosureResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPricingDisclosure();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string pricing_disclosure = 1;
 * @return {string}
 */
proto.billing.public.invoice.GetInvoicePricingDisclosureResponse.prototype.getPricingDisclosure = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.billing.public.invoice.GetInvoicePricingDisclosureResponse} returns this
 */
proto.billing.public.invoice.GetInvoicePricingDisclosureResponse.prototype.setPricingDisclosure = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


goog.object.extend(exports, proto.billing.public.invoice);
