import React, { useState, useEffect } from "react";
import Panel from "../panel/Panel";
import Text from "../text/Text";
import Button from "../button/Button";
import "./ErrorNotification.scss";
import { openIntercomIframe } from "../../../utils/commonUtils";

interface IErrorNotification {
  title?: string,
  subtitle?: string,
  buttonText?: string,
  buttonIcon?: string,
  buttonCb?: () => void,
  shouldTranslateTitle?: boolean,
  shouldTranslateSubtitle?: boolean,
  shouldTranslateButton?: boolean,
  showAlert?: boolean,
  icon?: string,
  onClose?: (...args: any) => any,
  autoClose?: boolean,
  closingTime?: number,
  showXIcon?: boolean,
  isModal?: boolean,
  showSubtitle?: boolean
}


const ErrorNotification: React.FC<IErrorNotification> = React.memo(({ title = "ERROR_PANEL_TITLE", subtitle = "ERROR_PANEL_SUBTITLE", buttonText = "ERROR_PANEL_BUTTON_TEXT",
  buttonIcon = "fas fa-headset", buttonCb = openIntercomIframe, shouldTranslateTitle = true, shouldTranslateSubtitle = true, shouldTranslateButton = true,
  showAlert = true, icon = "far fa-exclamation-circle", onClose = () => { }, autoClose = true, closingTime = 5000, 
  showXIcon = true, isModal = false, showSubtitle = true }) => {

  const [isVisible, setIsVisible] = useState(true);
  let timeout: any = -1;

  useEffect(() => {
    if (showAlert) {
      setIsVisible(true);
    }
    if (autoClose && (showAlert || isVisible)) {
      clearTimeout(timeout);
      timeout = setTimeout(() => {
        setIsVisible(false);
        if (typeof (onClose) === "function") {
          onClose();
        }
      }, closingTime);
    }
  }, [autoClose, showAlert]);

  if (!isVisible || !showAlert) {
    return null;
  }

  const notification = () => {
    return (
      <Panel className={`error-notification${isModal ? " error-notification--full-width" : ""}`}>
        <div className="error-notification__titlecontainer">
          <i className={icon}></i>
          <div>
            <Text content={title} shouldTranslate={shouldTranslateTitle} noMargin />
            {showSubtitle && <Text content={subtitle} shouldTranslate={shouldTranslateSubtitle} noMargin />}
          </div>
        </div>

        <div>
          <Button
            id="error-notification-btn"
            className="error-notification__btn"
            title={buttonText}
            icon={buttonIcon}
            onClick={buttonCb}
            shouldTranslate={shouldTranslateButton}
          />
          {showXIcon && <i className="fas fa-times error-notification__close-btn" onClick={() => { onClose(); setIsVisible(false); }} role="button" />}
        </div>
      </Panel>
    )
  }

  if (!isModal) {
    return (
      <div className={`error-notification__modal ${(!isVisible || !showAlert) ? "error-notification__modal--closed" : ""}`}>
        <div className={`error-notification__modal-container ${(!isVisible || !showAlert) ? "error-notification__modal-container--closed" : ""}`}>
          {notification()}
        </div>
      </div>
    )
  }

  return (
    notification()
  )
})

export default ErrorNotification;