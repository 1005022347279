import React, { useEffect, useRef, useState } from "react";
import Text from "../text/Text";
import "./FilterDropdown.scss";

interface IFilterDropdown {
  options: Array<{ label: string, value: any }>,
  onSelect?: (value: { label: string, value: any }) => void,
  onClear?: () => void,
  selected?: { label: string, value: any },
  component?: string,
  label: string,
  shouldTranslateOptionLabel?: boolean,
  className?: string,
  readonly?: boolean,
}

const FilterDropdown: React.FC<IFilterDropdown> = React.memo(({ options, className = "", component = "div", onSelect, onClear, label, shouldTranslateOptionLabel = true, selected, readonly = false }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState<{ label: string, value: any }>();
  const filterDropdownRef = useRef(null);

  const onClickOutside = (evt: any) => {
    if (filterDropdownRef.current && !(filterDropdownRef.current as any as HTMLElement).contains(evt.target)) {
      setIsOpen(false)
    }
  }

  useEffect(() => {
    selected && setSelectedOption(options.find(o => o.value === selected.value))
  }, [selected])

  useEffect(() => {
    document.addEventListener("click", onClickOutside);
    return () => document.removeEventListener("click", onClickOutside);
  })

  const Component: any = component;

  if(readonly) {
    return <Component><Text content={label} noMargin component="span" /></Component>
  }
  
  return (
    <Component ref={filterDropdownRef} className={`filter-dropdown ${className}`} onClick={() => setIsOpen(!isOpen)}>
      <Text content={label} noMargin component="span" />
      <i className={(selectedOption || isOpen) ? "fas fa-filter" : "fal fa-filter"}></i>
      {isOpen && (
        <div className="filter-dropdown__options">
          <div className="filter-dropdown__options--scrollable">
          {
            options.map((o, idx) => (
              <div key={idx} className={`filter-dropdown__options__item ${selectedOption && selectedOption.value === o.value ? "filter-dropdown__options__item--selected" : ""}`} onClick={() => {
                onSelect && onSelect(o);
                setSelectedOption(o)
                setIsOpen(false)
              }}>
                <Text content={o.label} shouldTranslate={shouldTranslateOptionLabel} component="span" noMargin></Text>
              </div>
            )
            )
          }
          </div>
          <div className="filter-dropdown__options__item filter-dropdown__options__item--clear" onClick={() => {
            onClear && onClear()
            setSelectedOption(undefined)
            setIsOpen(false)
          }}>
            <i className="far fa-times"></i>
            <Text content="INVOICE_STATUS_FILTER_SHOW_ALL" component="span" noMargin></Text>
          </div>
        </div>)}
    </Component>
  )
})

export default FilterDropdown;