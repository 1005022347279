import { BillsbyAction } from "../models/BillsbyAction";
import { FETCH_COMPANY_TEAMMATES_SUCCESS } from "../actions/companyTeammatesActions";
import { AppState } from "..";
import { ITeammate } from "../models/CompanyTeammates";
import { UserRole } from "../models/Auth";

export interface ICompanyTeammatesReducerState {
  user: ITeammate,
  existingTeammates: Array<ITeammate>,
  pendingInvitations: Array<ITeammate>,
}

export const initialState = {
  user: {
    userId: 0,
    email: "",
    firstName: "",
    lastName: "",
    role: UserRole.Admin
  },
  existingTeammates: [],
  pendingInvitations: []
};

export default function companyTeammatesReducer(state: ICompanyTeammatesReducerState = initialState, action: BillsbyAction, store: AppState) {
  switch (action.type) {
    case FETCH_COMPANY_TEAMMATES_SUCCESS:
      return action.response;
    default:
      return state;
  }
}
