/**
 * @fileoverview gRPC-Web generated client stub for billing.private.report
 * @enhanceable
 * @public
 */

// GENERATED CODE -- DO NOT EDIT!


import * as grpcWeb from "grpc-web";


import {
  ExitReasonsGraphDataRequest,
  ExitReasonsGraphDataResponse,
  GetActivitySummaryRequest,
  GetActivitySummaryResponse,
  GetExitReasonsRequest,
  GetExitReasonsResponse,
  GetTaxSummaryRequest,
  GetTaxSummaryResponse} from "./report_pb";

export class ReportServiceClient {
  client_: grpcWeb.AbstractClientBase;
  hostname_: string;
  credentials_: null | { [index: string]: string; };
  options_: null | { [index: string]: string; };

  constructor (hostname: string,
               credentials?: null | { [index: string]: string; },
               options?: null | { [index: string]: string; }) {
    if (!options) options = {};
    if (!credentials) credentials = {};
    options["format"] = "text";

    this.client_ = new grpcWeb.GrpcWebClientBase(options);
    this.hostname_ = hostname;
    this.credentials_ = credentials;
    this.options_ = options;
  }

  methodInfoGetActivitySummary = new grpcWeb.AbstractClientBase.MethodInfo(
    GetActivitySummaryResponse,
    (request: GetActivitySummaryRequest) => {
      return request.serializeBinary();
    },
    GetActivitySummaryResponse.deserializeBinary
  );

  getActivitySummary(
    request: GetActivitySummaryRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: GetActivitySummaryResponse) => void) {
    return this.client_.rpcCall(
      this.hostname_ +
        "/billing.private.report.ReportService/GetActivitySummary",
      request,
      metadata || {},
      this.methodInfoGetActivitySummary,
      callback);
  }

  methodInfoGetTaxSummary = new grpcWeb.AbstractClientBase.MethodInfo(
    GetTaxSummaryResponse,
    (request: GetTaxSummaryRequest) => {
      return request.serializeBinary();
    },
    GetTaxSummaryResponse.deserializeBinary
  );

  getTaxSummary(
    request: GetTaxSummaryRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: GetTaxSummaryResponse) => void) {
    return this.client_.rpcCall(
      this.hostname_ +
        "/billing.private.report.ReportService/GetTaxSummary",
      request,
      metadata || {},
      this.methodInfoGetTaxSummary,
      callback);
  }

  methodInfoGetExitReasons = new grpcWeb.AbstractClientBase.MethodInfo(
    GetExitReasonsResponse,
    (request: GetExitReasonsRequest) => {
      return request.serializeBinary();
    },
    GetExitReasonsResponse.deserializeBinary
  );

  getExitReasons(
    request: GetExitReasonsRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: GetExitReasonsResponse) => void) {
    return this.client_.rpcCall(
      this.hostname_ +
        "/billing.private.report.ReportService/GetExitReasons",
      request,
      metadata || {},
      this.methodInfoGetExitReasons,
      callback);
  }

  methodInfoGetExitReasonsGraphData = new grpcWeb.AbstractClientBase.MethodInfo(
    ExitReasonsGraphDataResponse,
    (request: ExitReasonsGraphDataRequest) => {
      return request.serializeBinary();
    },
    ExitReasonsGraphDataResponse.deserializeBinary
  );

  getExitReasonsGraphData(
    request: ExitReasonsGraphDataRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: ExitReasonsGraphDataResponse) => void) {
    return this.client_.rpcCall(
      this.hostname_ +
        "/billing.private.report.ReportService/GetExitReasonsGraphData",
      request,
      metadata || {},
      this.methodInfoGetExitReasonsGraphData,
      callback);
  }

}

