import React, { FormEvent, useEffect, useState, useMemo } from "react";
import Panel from "../ui/panel/Panel";
import Text from "../ui/text/Text";
import { Row, Col } from "react-grid-system";
import FormLabel from "../ui/form-label/FormLabel";
import Input from "../ui/input/Input";
import Dropdown from "../ui/dropdown/Dropdown";
import Button from "../ui/button/Button";
import FormGroup from "../ui/form-group/FormGroup";
import API from "../../utils/API";
import { ICurrency } from "../../models/Currency";
import { ICountry } from "../../models/Country";
import { reorderCountries, reorderCurrencies } from "../../utils/commonUtils";
import {  useDispatch, useSelector } from "react-redux";
import { ICompany, INewCompany } from "../../models/Auth";
import { refreshToken } from "../../utils/authUtils";
import { initAuthData } from "../../actions/authActions";
import { AppState } from "../..";
import { storeSet } from "../../utils/storeUtils";
import { BILLSBY_SELECTED_COMPANY } from "../../utils/constants";
import { ConfigConstants } from "../../utils/config";
import "./AddCompany.scss";
import ErrorNotification from "../ui/error-notification/ErrorNotification";
import { IsCompanyDomainUsedRequest, IsCompanyDomainUsedResponse } from "../../utils/grpc/generated/Billsby.Protos/core/public/company/company_pb";
import { CompanyServiceClient } from "../../utils/grpc/generated/Billsby.Protos/core/public/company/CompanyServiceClientPb";
import { grpcUnaryCall } from "../../utils/grpc/grpcUtils";

interface IOption {
  value: string,
  label: string
}

const AddCompany: React.FC = () => {
  const auth = useSelector((state: AppState) => state.auth);
  const dispatch = useDispatch<Function>();
  const { currentCompanyDomain, companies } = auth;

  const [countries, setCountries] = useState<Array<ICountry>>([]);
  const [currencies, setCurrencies] = useState<Array<ICurrency>>([]);
  const [currency, setCurrency] = useState<IOption>({ value: "", label: "" });
  const [country, setCountry] = useState<IOption>({ value: "", label: "" });
  const [companyName, setCompanyName] = useState("");
  const [companyDomain, setCompanyDomain] = useState("");
  const [hasError, setHasError] = useState(false);
  const [isInvalidCompanyDomain, setIsInvalidCompanyDomain] = useState(false);
  const [isValidatingCompanyDomain, setIsValidatingCompanyDomain] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [success, setSuccess] = useState(false);

  const isButtonDisabled = !countries.length || !currencies.length || !companyName || !companyDomain || isInvalidCompanyDomain || isValidatingCompanyDomain

  useEffect(() => {
    const fetchCountriesAndCurrencies = async () => {
      try {
        const countries = await API.getCountries() as Array<ICountry>;
        const currencies = await API.getCurrencies() as Array<ICurrency>;
        setCountries(countries);
        setCurrencies(currencies);
        setCountry({ value: countries[0].iso3Code, label: countries[0].name });
        setCurrency({ value: currencies[0].isoCode, label: currencies[0].englishName })
      }
      catch (err) {
        console.log(err)
      }
    }

    fetchCountriesAndCurrencies()
  }, []);

  const validateCompanyDomain = async () => {
    setIsValidatingCompanyDomain(true);
    setIsInvalidCompanyDomain(false);

    try {
      const isCompanyDomainUsedRequest = new IsCompanyDomainUsedRequest();
      isCompanyDomainUsedRequest.setCompanyDomain(companyDomain);
      const companyServiceClient = new CompanyServiceClient(ConfigConstants.grpcBaseUrl);
      const response = await grpcUnaryCall(isCompanyDomainUsedRequest, companyServiceClient, companyServiceClient.isCompanyDomainUsed) as IsCompanyDomainUsedResponse;
      setIsInvalidCompanyDomain(response.getIsUsed());
    } catch (err) {
      console.log(err);
      setIsInvalidCompanyDomain(true);
    } finally {
      setIsValidatingCompanyDomain(false);
    }
  }

  useEffect(() => {
    if(success) {
      const companiesWithSameCompanyName = companies.filter(c => c.name === companyName);
      if (companiesWithSameCompanyName.length > 0) {
        const company = companiesWithSameCompanyName[companiesWithSameCompanyName.length - 1];
        storeSet(BILLSBY_SELECTED_COMPANY, company.companyId);
        const isLocalHost = ConfigConstants.reactAppEnvironment === "Localhost";
        window.location.replace(`//${company.companyDomain}${ConfigConstants.billsbyDomain}${isLocalHost ? `:${process.env.REACT_APP_PORT}` : ""}`);
      }
    }
  }, [companies, success])

  useEffect(() => {
    if(!companyDomain) {
      return;
    }

    const timeout = setTimeout(() => {
      validateCompanyDomain()
    }, 500)

    return () => clearTimeout(timeout);
  }, [companyDomain]);

  const onSubmit = async (evt: FormEvent) => {
    evt.preventDefault();
    evt.stopPropagation();

    setIsLoading(true);

    const companyData: INewCompany = {
      companyName,
      companyDomain,
      companyCountry: (country as any).value,
      companyCurrency: (currency as any).value,
      timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      googleAnalyticsId: ""
    };

    try {
      await API.createCompany(currentCompanyDomain, companyData);
      const companies = await API.fetchCompanies() as Array<ICompany>
      const newCompany = companies.find(c => c.companyDomain === companyDomain)
      await refreshToken(newCompany?.companyId as number);
      // need to invalidate the token and get a new one to update the company list in the header and in all the components that are using it
      await dispatch(initAuthData());
      setSuccess(true)
    }
    catch (err) {
      setHasError(true);
      setIsLoading(false)
    }
  }

  const countriesList: Array<IOption> = useMemo(() => reorderCountries(countries).map((country) => ({ value: country.iso3Code, label: country.name })), [countries]);
  const currenciesList: Array<IOption> = useMemo(() => reorderCurrencies(currencies).map((currency) => ({ value: currency.isoCode, label: currency.englishName })), [currencies]);

  return (
    <form className="add-company" onSubmit={onSubmit} >
      <ErrorNotification
        title="NEW_COMPANY_ERROR"
        showAlert={hasError}
        isModal
        onClose={() => setHasError(false)}
      />
      <Panel title="ADD_COMPANY_PANEL1_TITLE">
        <Text content="ADD_COMPANY_PANEL1_CONTENT" noMargin />
      </Panel>
      <Panel>
        <FormGroup>
          <Row align="center">
            <Col md={2}>
              <FormLabel content="ADD_COMPANY_LABEL_COMPANY" target="mycompany" noMargin />
            </Col>
            <Col md={10}>
              <Input
                id="mycompany"
                value={companyName}
                required
                minLength="3"
                placeholder="My Company"
                onChange={(evt: any) => setCompanyName(evt.target.value)}
              />
            </Col>
          </Row>
        </FormGroup>

        <FormGroup>
          <Row align="center">
            <Col md={2}>
              <FormLabel content="ADD_COMPANY_LABEL_COMPANY_DOMAIN" target="companyDomain" noMargin />
            </Col>
            <Col md={10}>
              <Input
                id="companyDomain"
                value={companyDomain}
                isError={isInvalidCompanyDomain}
                placeholder="Company domain"
                onChange={(evt: any) => {
                  setCompanyDomain(evt.target.value.toLowerCase().replace(/[^a-z0-9]/gi, ""));
                  setIsValidatingCompanyDomain(true);
                  setIsInvalidCompanyDomain(false);
                }}
              />
            </Col>
          </Row>
          {
            isInvalidCompanyDomain &&
            <Row align="center">
              <Col offset={{ md: 2 }} md={10}>
                <FormLabel
                  target="companyDomain"
                  className="add-company__invalid-domain"
                  content="REGISTRATION_STEP2_COMPANY_SUBDOMAIN_ERROR"
                  noMargin
                />
              </Col>
            </Row>
          }
        </FormGroup>

        <FormGroup>
          <Row align="center">
            <Col md={2}>
              <FormLabel target="country" content="ADD_COMPANY_LABEL_COUNTRY" noMargin />
            </Col>
            <Col md={10}>
              <Dropdown
                id="country"
                dividerAfter={4}
                onChange={(country: any) => setCountry(country)}
                value={country}
                options={countriesList}
              />
            </Col>
          </Row>
        </FormGroup>

        <Row align="center">
          <Col md={2}>
            <FormLabel target="currency" content="ADD_COMPANY_LABEL_CURRENCY" noMargin />
          </Col>
          <Col md={10}>
            <Dropdown
              id="billingcurrency"
              dividerAfter={4}
              onChange={(currency: any) => setCurrency(currency)}
              value={currency}
              options={currenciesList}
            />
          </Col>
        </Row>
      </Panel>

      <Button
        id="add-company"
        type="submit"
        isLoading={isLoading && !hasError}
        disabled={isButtonDisabled}
        icon={success ? "far fa-check-circle" : undefined}
        buttonType={success ? "success" : "general"}
        title="ADD_COMPANY_SUBMIT"
        isFullWidth
      />
    </form>
  )
}

export default AddCompany;