import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../../../../..";
import { IPaymentGatewaysReducer } from "../../../../../reducers/paymentGatewaysReducer";
import { IAuthReducerState } from "../../../../../reducers/authReducer";
import { setPaymentGatewaysField, fetchPaymentGateways } from "../../../../../actions/paymentGatewaysActions";
import { CreateIxopayGatewayRequest, CreateIxopayGatewayResponse, ReplaceGatewayToIxopayRequest } from "../../../../../utils/grpc/generated/Billsby.Protos/billing/private/paymentgateway/payment_gateway_pb";
import { PaymentGatewayServiceClient } from "../../../../../utils/grpc/generated/Billsby.Protos/billing/private/paymentgateway/Payment_gatewayServiceClientPb";
import { grpcUnaryCall } from "../../../../../utils/grpc/grpcUtils";
import { ConfigConstants } from "../../../../../utils/config";
import PaymentGatewayContainer from "../payment-gateways-shared-components/PaymentGatewayContainer";
import IxopayPaymentGatewayForm from "./IxopayPaymentGatewayForm";

interface Props {
  onError: () => void;
}

const IxopayPaymentGateway: React.FC<Props> = ({ onError }) => {
  const { selectedPaymentGateway, isCreatingGateway, replacedPaymentGateway } = useSelector<AppState, IPaymentGatewaysReducer>(state => state.paymentGatewaysReducer);
  const { currentCompanyDomain } = useSelector<AppState, IAuthReducerState>(state => state.auth);
  const dispatch = useDispatch<Function>()

  const [helpTitle, setHelpTitle] = useState("PAYMENT_GATEWAYS_ADDED_IXOPAY_HELP");
  const [helpContent, setHelpContent] = useState(["PAYMENT_GATEWAYS_ADDED_IXOPAY_HELP_CONTENT_1", "PAYMENT_GATEWAYS_ADDED_IXOPAY_HELP_CONTENT_2"]);

  if (!selectedPaymentGateway) {
    return null;
  }

  const addNewPaymentGateway = async (displayName: string, username: string, password: string, apiKey: string, apiSecret: string) => {
    const createIxopayGatewayRequest = new CreateIxopayGatewayRequest();
    createIxopayGatewayRequest.setCompanyDomain(currentCompanyDomain);
    createIxopayGatewayRequest.setDisplayName(displayName);
    createIxopayGatewayRequest.setUsername(username);
    createIxopayGatewayRequest.setPassword(password);
    createIxopayGatewayRequest.setApiKey(apiKey);
    createIxopayGatewayRequest.setSecret(apiSecret);

    const paymentGatewayServiceClient = new PaymentGatewayServiceClient(ConfigConstants.grpcBaseUrl);
    
    let request: Promise<any>
    if(replacedPaymentGateway) {
      const replaceGatewayToIxopayRequest = new ReplaceGatewayToIxopayRequest()
      replaceGatewayToIxopayRequest.setCreateIxopayRequest(createIxopayGatewayRequest);
      replaceGatewayToIxopayRequest.setPaymentGatewayToReplaceId(replacedPaymentGateway.paymentGatewayId as number);
      request = grpcUnaryCall(replaceGatewayToIxopayRequest, paymentGatewayServiceClient, paymentGatewayServiceClient.replaceGatewayToIxopay)
    }
    else {
      request = grpcUnaryCall(createIxopayGatewayRequest, paymentGatewayServiceClient, paymentGatewayServiceClient.createIxopayGateway)
    }
    
    try {
      const data = await request as CreateIxopayGatewayResponse;
      dispatch(setPaymentGatewaysField("createdPaymentGatewayId", data.getPaymentGatewayId()));
      dispatch(setPaymentGatewaysField("selectedPaymentGateway", undefined));
      dispatch(setPaymentGatewaysField("replacedPaymentGateway", undefined));
      await dispatch(fetchPaymentGateways(currentCompanyDomain));
    } catch {
      onError();
    }
  }

  return (    
    <PaymentGatewayContainer
      helpTitle={helpTitle}
      helpContent={helpContent}
      description="PAYMENT_GATEWAYS_IXOPAY_DESCRIPTION"
      isOneGatewayPending={false}
      currentGatewayState={{ isCollapsed: false }}
      websiteLink="https://www.ixopay.com/"
      type="create"
      paymentGateway={selectedPaymentGateway}
    >
      <IxopayPaymentGatewayForm
        onSave={(displayName: string, username: string, password: string, apiKey: string, apiSecret: string) => addNewPaymentGateway(displayName, username, password, apiKey, apiSecret)}
        onCancel={() => {
          dispatch(setPaymentGatewaysField("selectedPaymentGateway", undefined));
          dispatch(setPaymentGatewaysField("replacedPaymentGateway", undefined));
        }}
        setHelpTitle={(text: string) => setHelpTitle(text)}
        setHelpContent={(text: Array<string>) => setHelpContent(text)}
        initDisplayName=""
        isSaveEnabled={!isCreatingGateway}
        isEdit={false}
      />
    </PaymentGatewayContainer>
  )
}

export default IxopayPaymentGateway