import React, { useState, useEffect } from "react";
import {  useSelector } from "react-redux";
import { AppState } from "../../..";
import FormGroup from "../../ui/form-group/FormGroup";
import Panel from "../../ui/panel/Panel";
import Text from "../../ui/text/Text";
import "./UpdateAllowance.scss";
import { Row, Col } from "react-grid-system";
import FormLabel from "../../ui/form-label/FormLabel";
import Dropdown from "../../ui/dropdown/Dropdown";
import counterpart from "counterpart";
import { GetAllowancesRequest as GetSubAllowancesRequest, GetAllowancesResponse as GetSubAllowancesResponse, Allowance as SubAllowance, AllowancePriceModel, UpdateSubscriptionAllowanceRequest, CheckoutAllowancesSimulationRequest, CheckoutAllowancesSimulationResponse } from "../../../utils/grpc/generated/Billsby.Protos/billing/private/subscription/subscription_pb";
import Button from "../../ui/button/Button";
import ProgressIndicator from "../../ui/progress-indicator/ProgressIndicator";
import { GetAllowancesRequest, GetAllowancesResponse, Allowance, PricingModelType } from "../../../utils/grpc/generated/Billsby.Protos/core/private/allowances/allowances_pb";
import { grpcUnaryCall } from "../../../utils/grpc/grpcUtils";
import { AllowancesServiceClient } from "../../../utils/grpc/generated/Billsby.Protos/core/private/allowances/AllowancesServiceClientPb";
import { ConfigConstants } from "../../../utils/config";
import { SubscriptionServiceClient } from "../../../utils/grpc/generated/Billsby.Protos/billing/private/subscription/SubscriptionServiceClientPb";
import moment from "moment";
import { getFrequencyTypeGrpc, getFrequencyTextLabel, getFrequencyTypeEnum, getUnitsOverageText, getPricingModelTypeEnum } from "../../../utils/commonUtils";
import { FrequencyType, ProRateType } from "../../../models/Product";
import { Int32Value } from "google-protobuf/google/protobuf/wrappers_pb";

interface IUpdateAllowance {
  handleCallback: () => void;
}

const UpdateAllowance: React.FC<IUpdateAllowance> = ({ handleCallback }) => {
  const { currentCompanyDomain, currentCompanyId, parsedToken } = useSelector((state: AppState) => state.auth);
  const subscriptionDetails = useSelector((state: AppState) => state.subscriptionDetailsReducer);

  const [selectedAllowance, setSelectedAllowance] = useState<{ label: string, value: Allowance } | null>(null);
  const [subAllowancesList, setsubAllowancesList] = useState<Array<SubAllowance>>([]);
  const [allowancesList, setAllowancesList] = useState<Array<{ label: string, value: Allowance }>>([]);
  const [choosableAllowances, setChoosableAllowances] = useState<Array<{ label: string, value: Allowance }>>([]);
  const [priceInfoData, setPriceInfoData] = useState(<div />);
  const [isLoadingData, setIsLoadingData] = useState(true);
  const { subscriptionId, subscriptionUniqueId, planId, nextBillingDate, planPricingModel } = subscriptionDetails;
  const nextBilling = moment(nextBillingDate).format("DD MMM YYYY");

  const fetchData = async () => {
    const allowanceServiceClient = new AllowancesServiceClient(ConfigConstants.grpcBaseUrl);
    const subscriptionServiceClient = new SubscriptionServiceClient(ConfigConstants.grpcBaseUrl);

    const getAllowances = new GetAllowancesRequest();
    const getSubAllowances = new GetSubAllowancesRequest();

    getAllowances.setCompanyDomain(currentCompanyDomain);
    getSubAllowances.setSubscriptionId(subscriptionId as number);
    getSubAllowances.setCompanyId(currentCompanyId as number);

    try {
      const getAllowancesReponse = (await grpcUnaryCall(getAllowances, allowanceServiceClient, allowanceServiceClient.getAllowances)) as GetAllowancesResponse;
      const getSubAllowancesResponse = (await grpcUnaryCall(getSubAllowances, subscriptionServiceClient, subscriptionServiceClient.getAllowances)) as GetSubAllowancesResponse;

      const subAllowancesList = getSubAllowancesResponse.getAllowancesList();

      const allowancesList = getAllowancesReponse.getAllowancesList()
        .filter(allowance => (allowance.getPlansList().some(plan => plan.getId() === planId)))
        .map(allowance => ({ label: allowance.getName(), value: allowance }))

      const choosableAllowances = allowancesList.filter(allowance => subAllowancesList.every(a => a.getId() !== allowance.value.getId()));

      setsubAllowancesList(subAllowancesList);
      setAllowancesList(allowancesList);
      setChoosableAllowances(choosableAllowances.sort((a, b) => a.value.getName() < b.value.getName() ? -1 : a.value.getName() > b.value.getName() ? 1 : 0));
    }
    catch (err) {
      console.log(err);
    }
    finally {
      setIsLoadingData(false);
    }
  };


  useEffect(() => {
    fetchData();
  }, [])

  useEffect(() => {
    const simulation = async () => {
      if (!selectedAllowance) { return }
      const priceModel = selectedAllowance.value.getPriceModelsList()
        .find((allowance) => allowance.getFrequency() === planPricingModel.frequency
          && allowance.getFrequencyType() === getFrequencyTypeGrpc(planPricingModel.frequencyType as FrequencyType)) as AllowancePriceModel;
      const getUnitlabel = (nrUnit: number) => counterpart(nrUnit > 1 ? "UPDATE_SUBSCRIPTION_ALLOWANCE_MODAL_UNITS" : "UPDATE_SUBSCRIPTION_ALLOWANCE_MODAL_UNIT");
      const includedUnits = (priceModel.getIncludedUnits() || new Int32Value()).getValue();

      let overageText;
      switch (selectedAllowance.value.getPricingModelType()) {
        case PricingModelType.CAPPED:
          overageText = <Text content="" shouldTranslate={false} noMargin />;
          break;
        case PricingModelType.PER_UNIT:
          overageText = <Text content={`${priceModel.getPerUnitPriceFormatted()} ${counterpart("FREQUENCY_PERUNIT")}`} shouldTranslate={false} noMargin />
          break;
        default:
          overageText = <Text content={`${counterpart("UPDATE_SUBSCRIPTION_ALLOWANCE_MODAL_OVERAGE_CHARGED")} ${getUnitsOverageText(priceModel, getPricingModelTypeEnum(selectedAllowance.value.getPricingModelType()))}`} shouldTranslate={false} noMargin />
      }

      const subscriptionService = new SubscriptionServiceClient(ConfigConstants.grpcBaseUrl);

      try {
        const simulationRequest = new CheckoutAllowancesSimulationRequest();
        simulationRequest.setCompanyDomain(currentCompanyDomain);
        simulationRequest.setSubscriptionUniqueId(subscriptionUniqueId);
        simulationRequest.setSubscriptionAllowancesIdsList([selectedAllowance.value.getId()]);
        const simulationResponse = await grpcUnaryCall(simulationRequest, subscriptionService, subscriptionService.checkoutAllowancesSimulation) as CheckoutAllowancesSimulationResponse;
        const allowanceSimulatedPrices = simulationResponse.getPriceSimulationsList()[0];
        setPriceInfoData(
          <>
            {
              includedUnits
                ? (
                  <Text
                    content="UPDATE_SUBSCRIPTION_ALLOWANCE_MODAL_INCLUDED_UNITS_SIMULATION"
                    translateWith={{
                      unitsSimulated: planPricingModel.proRateOption === ProRateType.NO_PRORATE ? includedUnits : Number(allowanceSimulatedPrices.getProratedIncludedUnits()),
                      unitsSimulatedLabel: getUnitlabel(planPricingModel.proRateOption === ProRateType.NO_PRORATE ? includedUnits : Number(allowanceSimulatedPrices.getProratedIncludedUnits())),
                      nextBillingDate: nextBilling,
                      includedUnits: Number(allowanceSimulatedPrices.getRegularIncludedUnits()),
                      unitLabel2: getUnitlabel(includedUnits),
                      frequencyType: getFrequencyTextLabel(priceModel.getFrequency(), getFrequencyTypeEnum(priceModel.getFrequencyType()))
                    }}
                    noMargin
                  />
                )
                : <div />
            }
            {overageText}
          </>
        )
      }
      catch (err) { }

    }
    simulation();
  }, [selectedAllowance])

  const onSubmit = async () => {
    if (!selectedAllowance) { return }

    const updateSubAllowances = new UpdateSubscriptionAllowanceRequest();
    const userId = new Int32Value();

    userId.setValue(parsedToken ? Number(parsedToken.nameid) : 0); 

    updateSubAllowances.setCompanyId(currentCompanyId as number);
    updateSubAllowances.setSubscriptionId(subscriptionId as number);
    updateSubAllowances.setSubscriptionAllowancesList([...subAllowancesList.map(el => el.getId()), selectedAllowance.value.getId()]);
    updateSubAllowances.setRequestedByUserId(userId);
    const subscriptionServiceClient = new SubscriptionServiceClient(ConfigConstants.grpcBaseUrl);
    setIsLoadingData(true);

    try {
      await grpcUnaryCall(updateSubAllowances, subscriptionServiceClient, subscriptionServiceClient.updateSubscriptionAllowance);
    }
    catch (err) {
      console.log(err);
    }
    finally {
      setIsLoadingData(false);
      handleCallback();
    }
  }

  if (isLoadingData) {
    return <ProgressIndicator color="blue" coverage="normal" noPadding />
  }

  return (
    <div className="update-allowance">
      <FormGroup>
        <Panel className="update-allowance__heading" title="UPDATE_SUBSCRIPTION_ALLOWANCE_MODAL_ADDING">
          <Text content="UPDATE_SUBSCRIPTION_ALLOWANCE_MODAL_PICK" noMargin />
        </Panel>
      </FormGroup>
      <Panel>
        <FormGroup>
          <Row align="center">
            <Col xs={2}>
              <FormLabel target="select-allowance" content="UPDATE_SUBSCRIPTION_ALLOWANCE_MODAL_CHOOSE_LABEL" noMargin />
            </Col>
            <Col xs={10}>
              <Dropdown
                id="select-allowance"
                value={selectedAllowance}
                onChange={(item: any) => { setSelectedAllowance(item) }}
                options={choosableAllowances}
                placeholder={counterpart("UPDATE_SUBSCRIPTION_ALLOWANCE_MODAL_CHOOSE_PLACEHOLDER")}
              />
            </Col>
          </Row>
        </FormGroup>
        <Row>
          <Col xs={2} />
          <Col xs={10}>
            {priceInfoData}
          </Col>
        </Row>
      </Panel>
      <Button
        id="update-allowance"
        title="UPDATE_SUBSCRIPTION_ALLOWANCE_MODAL_BTN"
        disabled={!selectedAllowance || isLoadingData}
        isLoading={isLoadingData}
        buttonType="general"
        onClick={onSubmit}
        isFullWidth
      />
    </div>
  )
}

export default UpdateAllowance;
