import React, { useRef, useState } from "react";
import Panel from "../panel/Panel";
import Text from "../text/Text";
import FormGroup from "../form-group/FormGroup";
import "./StatsPanel.scss";
import PercentageLabel from "../percentage-label/PercentageLabel";
import Tooltip from "../tooltip/Tooltip";
import { extractDecimal } from "../../../utils/commonUtils";

interface IStatsPanelProps {
  title: string,
  shouldTranslateTitle?: boolean,
  type: "positive" | "negative" | "equals"
  percentage: number,
  infoText?: string | JSX.Element,
  shouldTranslateInfoText?: boolean,
  percentageText?: string | JSX.Element,
  shouldTranslatePercentageText?: boolean
}

interface IStatsPanelValues {
  value1: string,
  label1: string,
  shouldTranslateLabel1?: boolean,
  value2?: string,
  label2?: string
  shouldTranslateLabel2?: boolean
}

const StatsPanelValues: React.FC<IStatsPanelValues> = React.memo(({ value1, label1, shouldTranslateLabel1 = true, value2 = "", label2 = "", shouldTranslateLabel2 = true }) => {
  const value1Splitted = extractDecimal(value1);
  const value2Splitted = extractDecimal(value2);

  return (
    <Panel className="stats-panel__values">
      <div className="stats-panel__values-block1">
        <span>{value1Splitted.base}</span>
        {value1Splitted.decimal && <span>.</span>}
        <span className="decimal">{value1Splitted.decimal}</span>
        <Text content={label1} shouldTranslate={shouldTranslateLabel1} noMargin />
      </div>
      {
        value2 && (
          <div className="stats-panel__values-block2">
            <span>{value2Splitted.base}</span>
            {value2Splitted.decimal && <span>.</span>}
            <span className="decimal">{value2Splitted.decimal}</span>
            <Text content={label2} shouldTranslate={shouldTranslateLabel2} noMargin />
          </div>
        )
      }
    </Panel>
  )
})

interface IStatsPanel extends React.FC<IStatsPanelProps> {
  VALUES?: any;
}

const StatsPanel: IStatsPanel = ({ title, shouldTranslateTitle = true, type = "positive", percentage, infoText, shouldTranslateInfoText = true, 
percentageText, shouldTranslatePercentageText = true, children }) => {

  const [isHoveredInfo, setIsHoveredInfo] = useState(false);
  const idInfoTooltip = useRef(`tooltip-stats-panel-i-${Math.floor(Math.random() * 100)}`);
  const idPercentageTooltip = useRef(`tooltip-stats-panel-%-${Math.floor(Math.random() * 100)}`);

  const getPercentageTooltipAttributes = () => {
    if(!percentageText) {
      return {}
    }
    return {
      "data-tip": true,
      "data-for": idPercentageTooltip.current
    }
  }

    return (
      <Panel className="stats-panel" onMouseEnter={() => setIsHoveredInfo(true)} onMouseLeave={() => setIsHoveredInfo(false)}>
        <FormGroup className="stats-panel__title">
          <div>
            <Text content={title} shouldTranslate={shouldTranslateTitle} noMargin />
            <div {...getPercentageTooltipAttributes()}>
              <PercentageLabel 
                type={type} 
                value={percentage}
              />
            </div>
          </div>
          {infoText && 
            <i 
              className="far fa-info-circle" 
              data-tip 
              data-for={idInfoTooltip.current} 
              style={{ visibility: isHoveredInfo ? "visible" : "hidden" }} 
            />
          }
        </FormGroup>

        {
          infoText && (
            <Tooltip id={idInfoTooltip.current} place="left" type="blue">
              <Text content={infoText} shouldTranslate={shouldTranslateInfoText} noMargin />
            </Tooltip>
          )
        }

        {
          percentageText && (
            <Tooltip id={idPercentageTooltip.current} place="bottom" type="blue">
              <Text content={percentageText} shouldTranslate={shouldTranslatePercentageText} noMargin />
            </Tooltip>
          )
        }

        {children}
      </Panel>
    )
}

StatsPanel.VALUES = StatsPanelValues;

export default StatsPanel;