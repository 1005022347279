import React, { useMemo } from "react";
import PageWrapper from "../../components/ui/layout/PageWrapper";
import Panel from "../../components/ui/panel/Panel";
import Text from "../../components/ui/text/Text";
import FormGroup from "../../components/ui/form-group/FormGroup";
import InteractiveList from "../../components/ui/interactive-list/InteractiveList";
import ErrorPanel from "../../components/ui/error-panel/ErrorPanel";
import { COLOR } from "../../utils/constants";
import "./ReportsAccounting.scss";
import { useHistory } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../..";
import { Permission } from "../../models/Auth";
import NoticePanel from "../../components/ui/notice-panel/NoticePanel";
import { setShowBillingModal } from "../../actions/authActions";
import counterpart from "counterpart";
import { Link } from "react-router-dom";

const ReportsAccounting: React.FC = () => {
  const history = useHistory();
  const { parsedToken } = useSelector((state: AppState) => state.auth)
  const dispatch = useDispatch()

  const { canViewRevenueRecognition, canViewDeferredRevenue } = useMemo(() => {
    const canViewRevenueRecognition = parsedToken?.CurrentPermissions.some(p => p === Permission[Permission.RevenueRecognitionRead])
    const canViewDeferredRevenue = parsedToken?.CurrentPermissions.some(p => p === Permission[Permission.DeferredRevenueRead])
    return { canViewRevenueRecognition, canViewDeferredRevenue }
  }, [parsedToken])

  return (
    <PageWrapper className="reports-accounting">
      <Panel title="REPORTS_ACCOUNTING_HEADING_TITLE" className="reports-accounting__heading">
        <Text content="REPORTS_ACCOUNTING_HEADING_BODY" noMargin />
      </Panel>
      <NoticePanel
        type="warning"
        icon="far fa-lightbulb-exclamation"
        content="REPORTS_ACCOUNTING_WARNING_PERMISSION"
        translateWith={{
          here: <Link to="" id="billing-modal-link" onClick={(evt) => { dispatch(setShowBillingModal(true)); evt.preventDefault();}}>{counterpart("REPORTS_ACCOUNTING_WARNING_PERMISSION_HERE")}</Link>
        }}
        isVisible={!canViewRevenueRecognition && !canViewDeferredRevenue}
      />
      <FormGroup>
        <InteractiveList
          data={[
            {
              title: "REPORTS_ACCOUNTING_ACTIVITY_TITLE",
              subTitle: "REPORTS_ACCOUNTING_ACTIVITY_SUBTITLE",
              onClick: () => { history.push("/report-accounting/activity-summary") },
              statusLabel: "REPORTS_CUSTOMERS_MONTHLY_LABEL",
              statusLabelColor: COLOR.BLUE
            },
            /*{
              title: "REPORTS_ACCOUNTING_UNPAID_INVOICES_TITLE",
              subTitle: "REPORTS_ACCOUNTING_UNPAID_INVOICES_SUBTITLE",
              onClick: () => { },
              statusLabel: "REPORTS_CUSTOMERS_ONDEMAND_LABEL",
              statusLabelColor: COLOR.BLUE
            },*/
            {
              title: "REPORTS_ACCOUNTING_TAX_SUMMARY_TITLE",
              subTitle: "REPORTS_ACCOUNTING_TAX_SUMMARY_SUBTITLE",
              onClick: () => { history.push("/report-accounting/tax-summary") },
              statusLabel: "REPORTS_CUSTOMERS_MONTHLY_LABEL",
              statusLabelColor: COLOR.BLUE
            },
            {
              title: "REPORTS_ACCOUNTING_REVENUE_RECOGNITION_TITLE",
              subTitle: "REPORTS_ACCOUNTING_REVENUE_RECOGNITION_SUBTITLE",
              onClick: () => { history.push("/report-accounting/revenue-recognition") },
              statusLabel: "REPORTS_CUSTOMERS_REALTIME_LABEL",
              statusLabelColor: COLOR.BLUE,
              isLocked: !canViewRevenueRecognition
            },
            {
              title: "REPORTS_ACCOUNTING_DEFERRED_REVENUE_TITLE",
              subTitle: "REPORTS_ACCOUNTING_DEFERRED_REVENUE_SUBTITLE",
              onClick: () => { history.push("/report-accounting/deferred-revenue") },
              statusLabel: "REPORTS_CUSTOMERS_REALTIME_LABEL",
              statusLabelColor: COLOR.BLUE,
              isLocked: !canViewDeferredRevenue
            }
          ]}
        />
      </FormGroup>

      <ErrorPanel
        title="REPORTS_CUSTOMERS_FOOTER_TITLE"
        subtitle="REPORTS_CUSTOMERS_FOOTER_SUBTITLE"
        icon="fas fa-analytics"
      />
    </PageWrapper>
  )
}

export default ReportsAccounting;