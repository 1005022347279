import React, { useState } from "react"
import { setPaymentGatewaysField, fetchPaymentGateways, cancelLastPaymentGateway } from "../../../../../actions/paymentGatewaysActions";
import { ConfigConstants } from "../../../../../utils/config";
import { IPaymentGatewayElement } from "../../../../../models/Configuration"
import { UpdateNMIGatewayRequest } from "../../../../../utils/grpc/generated/Billsby.Protos/billing/private/paymentgateway/payment_gateway_pb";
import { PaymentGatewayServiceClient } from "../../../../../utils/grpc/generated/Billsby.Protos/billing/private/paymentgateway/Payment_gatewayServiceClientPb";
import { grpcUnaryCall } from "../../../../../utils/grpc/grpcUtils";
import PaymentGatewayContainer from "../payment-gateways-shared-components/PaymentGatewayContainer";
import NMIPaymentGatewayForm from "./NMIPaymentGatewayForm";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../../../../..";
import { IAuthReducerState } from "../../../../../reducers/authReducer";

interface Props {
  paymentGateway: IPaymentGatewayElement,
  isOneGatewayPending: boolean,
  currentGatewayState: any,
  idx: number,
  isCreatingGateway: boolean,
  onError: () => void
}

interface IData {
  username: string
}

const NMIPaymentGatewayUpdate: React.FC<Props> = ({ onError, paymentGateway, isOneGatewayPending, currentGatewayState, idx, isCreatingGateway }) => {

  const { currentCompanyDomain } = useSelector<AppState, IAuthReducerState>(state => state.auth)
  const dispatch = useDispatch<Function>()
  const data = paymentGateway.data as IData;
  const [helpTitle, setHelpTitle] = useState("PAYMENT_GATEWAYS_ADDED_NMI_HELP");
  const [helpContent, setHelpContent] = useState(["PAYMENT_GATEWAYS_ADDED_NMI_HELP_CONTENT_1", "PAYMENT_GATEWAYS_ADDED_NMI_HELP_CONTENT_2"]);
  
  const updatePaymentGateway = async (displayName: string, username: string, password: string) => {
    const updateNMIGatewayRequest = new UpdateNMIGatewayRequest();
    updateNMIGatewayRequest.setCompanyDomain(currentCompanyDomain);
    updateNMIGatewayRequest.setDisplayName(displayName);
    updateNMIGatewayRequest.setLogin(username);
    updateNMIGatewayRequest.setPassword(password);
    updateNMIGatewayRequest.setPaymentGatewayId(paymentGateway.paymentGatewayId as number)
    const paymentGatewayServiceClient = new PaymentGatewayServiceClient(ConfigConstants.grpcBaseUrl);
    try {
      await grpcUnaryCall(updateNMIGatewayRequest, paymentGatewayServiceClient, paymentGatewayServiceClient.updateNMIGateway);
      await dispatch(fetchPaymentGateways(currentCompanyDomain));dispatch(setPaymentGatewaysField(`paymentGateway${idx}`, { ...currentGatewayState, isCollapsed: true }));
    } catch {      
      onError();
    }
  }

  const cancelChanges = () => {
    paymentGateway.mustActivate ? dispatch(cancelLastPaymentGateway())
      : dispatch(setPaymentGatewaysField(`paymentGateway${idx}`, { ...currentGatewayState, displayName: paymentGateway.title, isCollapsed: true }))
  }

  return (
    <PaymentGatewayContainer
      helpTitle={helpTitle}
      helpContent={helpContent}
      description="PAYMENT_GATEWAYS_NMI_DESCRIPTION"
      isOneGatewayPending={isOneGatewayPending}
      currentGatewayState={currentGatewayState}
      onClick={() => dispatch(setPaymentGatewaysField(`paymentGateway${idx}`, { ...currentGatewayState, isCollapsed: paymentGateway.mustActivate ? false : !currentGatewayState.isCollapsed }))}
      websiteLink="https://www.nmi.com/"
      type="edit"
      paymentGateway={paymentGateway}
    >
      <NMIPaymentGatewayForm
        onSave={(displayName: string, username: string, password: string) => updatePaymentGateway(displayName, username, password)}
        onCancel={() => cancelChanges()}
        setHelpTitle={(text: string) => setHelpTitle(text)}
        setHelpContent={(text: Array<string>) => setHelpContent(text)}
        initDisplayName={paymentGateway.title}
        initUsername={data.username}
        isSaveEnabled={!isCreatingGateway}
        isEdit={true}
      />
    </PaymentGatewayContainer>
  )
}

export default NMIPaymentGatewayUpdate
