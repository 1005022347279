/**
 * Setup event-based protocol to make Billsby communicates with the Checkout,
 * below the event types sent by the Checkout 
 * */

export enum CROSS_DOMAIN_EVENTS {
  CLOSE = "CLOSE",
  REDIRECT = "REDIRECT",
  REDIRECT_NEW_TAB = "REDIRECT_NEW_TAB",

  CREATE_SUBSCRIPTION_SUCCESS = "CREATE_SUBSCRIPTION_SUCCESS",
  CREATE_SUBSCRIPTION_ERROR = "CREATE_SUBSCRIPTION_ERROR",

  CHANGE_PLAN_SUCCESS = "CHANGE_PLAN_SUCCESS",
  CHANGE_PLAN_ERROR = "CHANGE_PLAN_ERROR",

  CHANGE_CONTACT_DETAILS_SUCCESS = "CHANGE_CONTACT_DETAILS_SUCCESS",
  CHANGE_CONTACT_DETAILS_ERROR = "CHANGE_CONTACT_DETAILS_ERROR",

  CHANGE_ADDRESS_DETAILS_SUCCESS = "CHANGE_ADDRESS_DETAILS_SUCCESS", 
  CHANGE_ADDRESS_DETAILS_ERROR = "CHANGE_ADDRESS_DETAILS_ERROR",

  CHANGE_PAYMENT_DETAILS_SUCCESS = "CHANGE_PAYMENT_DETAILS_SUCCESS",
  CHANGE_PAYMENT_DETAILS_ERROR = "CHANGE_PAYMENT_DETAILS_ERROR",

  CHANGE_ADDONS_SUCCESS = "CHANGE_ADDONS_SUCCESS", 
  CHANGE_ADDONS_ERROR = "CHANGE_ADDONS_ERROR",

  CHANGE_ALLOWANCES_SUCCESS = "CHANGE_ALLOWANCES_SUCCESS", 
  CHANGE_ALLOWANCES_ERROR = "CHANGE_ALLOWANCES_ERROR",

  CANCEL_SUBSCRIPTION_SUCCESS = "CANCEL_SUBSCRIPTION_SUCCESS", 
  CANCEL_SUBSCRIPTION_ERROR = "CANCEL_SUBSCRIPTION_ERROR",
}

export interface ICheckoutEvent {
  data: { key: CROSS_DOMAIN_EVENTS, value?: string };
}