import React from "react";
import { useSelector } from "react-redux";
import { AppState } from "../..";
import PageWrapper from "../../components/ui/layout/PageWrapper";
import history from "../../utils/history";
import "./Integrations.scss";
import Panel from "../../components/ui/panel/Panel";
import InteractiveList from "../../components/ui/interactive-list/InteractiveList";
import freeAgentLogo from "../../images/integrations/free-agent-logo.png";
import quickBooksLogo from "../../images/integrations/quickbooks/logo.png";
import zapierLogo from "../../images/zapier-logo.png";
import webexLogo from "../../images/integrations/webex/webex-logo.png";
import billsbyPagesLogo from "../../images/website_builders/billsby_pages_logo.svg";
import ErrorPanel from "../../components/ui/error-panel/ErrorPanel";
import { AccountingIntegrationType } from "../../models/AccountingIntegrations";
import { COLOR } from "../../utils/constants";

export const Integrations: React.FC = () => {
  const { accountingIntegrations: { integrations } } = useSelector((state: AppState) => ({ accountingIntegrations: state.accountingIntegrationsReducer }))
  const isFreeAgentInstalled = integrations.getIntegrationsList().filter(i => i.getName() === AccountingIntegrationType.FREEAGENT && i.getIsInstalled()).length > 0;
  const isQuickbooksInstalled = integrations.getIntegrationsList().filter(i => i.getName() === AccountingIntegrationType.QUICKBOOKS && i.getIsInstalled()).length > 0;

  console.log("--->", { isFreeAgentInstalled, isQuickbooksInstalled })
  return (
    <div>
      <PageWrapper>
 
        <Panel title="INTEGRATIONS_ACCOUNTING_INTEGRATIONS">
          <InteractiveList className="integrations__list"
            data={[
              {
                isLogoFontAwesome: false,
                logo: freeAgentLogo,
                statusLabelColor: isFreeAgentInstalled ? undefined : COLOR.LIGHT_BLUE,
                statusLabel: isFreeAgentInstalled ? "INTEGRATION_INSTALLED_LABEL" : "INTEGRATION_PREVIEW_LABEL",
                title: "INTEGRATIONS_ACCOUNTING_INTEGRATIONS_FREE_AGENT",
                subTitle: "INTEGRATIONS_ACCOUNTING_INTEGRATIONS_FREE_AGENT_TEXT",
                onClick: () => history.push("/integrations/free-agent")
              },
              {
                isLogoFontAwesome: false,
                logo: quickBooksLogo,
                statusLabelColor: isQuickbooksInstalled ? undefined : COLOR.LIGHT_BLUE,
                statusLabel: isQuickbooksInstalled ? "INTEGRATION_INSTALLED_LABEL" : "INTEGRATION_PREVIEW_LABEL",
                title: "INTEGRATIONS_ACCOUNTING_INTEGRATIONS_QUICKBOOKS",
                subTitle: "INTEGRATIONS_ACCOUNTING_INTEGRATIONS_QUICKBOOKS_TEXT",
                onClick: () => history.push("/integrations/quickbooks")
              }
            ]}
          />
        </Panel>


        <Panel title="INTEGRATION_WEB_CONFERENCING">
          <InteractiveList className="integrations__list webex"
            data={[
              {
                isLogoFontAwesome: false,
                logo: webexLogo,
                statusLabel: "",
                title: "INTEGRATIONS_WEBEX",
                subTitle: "INTEGRATION_WEB_CONFERENCING_TEXT",
                onClick: () => history.push("/integrations/webex")
              }
            ]}
          />
        </Panel>

        <Panel title="INTEGRATION_WEBSITE_BUILDERS">
          <InteractiveList className="integrations__list website-builder"
            data={[
              {
                isLogoFontAwesome: false,
                logo: billsbyPagesLogo,
                statusLabel: "",
                title: undefined,
                subTitle: "INTEGRATION_BILLSBY_PAGES",
                onClick: () => history.push("/integrations/billsby-pages")
              }
            ]}
          />
        </Panel>

        <Panel title="INTEGRATION_ZAPIER">
          <InteractiveList className="integrations__list zapier"
            data={[
              {
                isLogoFontAwesome: false,
                logo: zapierLogo,
                statusLabel: "",
                title: "INTEGRATION_ZAPIER",
                subTitle: "INTEGRATION_ZAPIER_TEXT",
                onClick: () => history.push("/integrations/zapier")
              }
            ]}
          />
        </Panel>

        <ErrorPanel
          title="INTEGRATION_TALK_US_TEXT1"
          subtitle="INTEGRATION_TALK_US_TEXT2"
          icon="fas fa-user-headset"
        />
      </PageWrapper>
    </div>
  )
}

export default Integrations;
