import React, { FormEvent, useState } from "react"
import StepCollapsablePanel from "../../../../ui/step-collapsable-panel/StepCollapsablePanel";
import Button from "../../../../ui/button/Button";
import Text from "../../../../ui/text/Text";
import counterpart from "counterpart";
import { setPaymentGatewaysField, fetchPaymentGateways, resetPaymentGatewaysState, updatePaymentGatewayName, cancelLastPaymentGateway, deletePaymentGateway, createStripeIntentGateway } from "../../../../../actions/paymentGatewaysActions";
import { IPaymentGatewayElement } from "../../../../../models/Configuration";
import Tooltip from "../../../../ui/tooltip/Tooltip";
import FormGroup from "../../../../ui/form-group/FormGroup";
import { Row, Col } from "react-grid-system";
import FormLabel from "../../../../ui/form-label/FormLabel";
import Input from "../../../../ui/input/Input";
import queryString from "query-string";
import history from "../../../../../utils/history";
import { useDispatch, useSelector } from "react-redux";
import { CreateStripeIntentGatewayRequest, CreateStripeIntentGatewayResponse, ReplaceGatewayToStripeIntentGatewayRequest } from "../../../../../utils/grpc/generated/Billsby.Protos/billing/private/paymentgateway/payment_gateway_pb";
import { IPaymentGatewaysReducer } from "../../../../../reducers/paymentGatewaysReducer";
import { AppState } from "../../../../..";
import { PaymentGatewayServiceClient } from "../../../../../utils/grpc/generated/Billsby.Protos/billing/private/paymentgateway/Payment_gatewayServiceClientPb";
import { ConfigConstants } from "../../../../../utils/config";
import { grpcUnaryCall } from "../../../../../utils/grpc/grpcUtils";
import { IAuthReducerState } from "../../../../../reducers/authReducer";

interface Props {
  paymentGateway: IPaymentGatewayElement,
  isOneGatewayPending: boolean,
  currentGatewayState: any,
  idx: number,
  isCreatingGateway: boolean
}

const StripePaymentGatewayUpdate: React.FC<Props> = ({ paymentGateway, isOneGatewayPending, currentGatewayState, idx, isCreatingGateway }) => {

  const { replacedPaymentGateway } = useSelector<AppState, IPaymentGatewaysReducer>(state => state.paymentGatewaysReducer);
  const { currentCompanyDomain } = useSelector<AppState, IAuthReducerState>(state => state.auth)
  const dispatch = useDispatch<Function>()
  const [isLoading, setIsLoading] = useState(false);

  const updatePaymentGateway = async (evt: FormEvent) => {
    setIsLoading(true);
    evt.preventDefault();
    evt.stopPropagation(); //to avoid the event bubbling up to the panel and close the panel before triggering the update
    const authorizationCode = String(queryString.parse(window.location.search).code);
    try {
      if (paymentGateway.mustActivate && currentGatewayState.displayName && authorizationCode) {
          
        let request: Promise<any>

        if(replacedPaymentGateway) {
          const createStripeRequest = new CreateStripeIntentGatewayRequest();
          const clientService = new PaymentGatewayServiceClient(ConfigConstants.grpcBaseUrl); 
          createStripeRequest.setCompanyDomain(currentCompanyDomain);
          createStripeRequest.setAuthorizationCode(authorizationCode);
          createStripeRequest.setDisplayName(currentGatewayState.displayName);
          const replaceGatewayToStripeIntentGatewayRequest = new ReplaceGatewayToStripeIntentGatewayRequest()
          replaceGatewayToStripeIntentGatewayRequest.setCreateStripeRequest(createStripeRequest);
          replaceGatewayToStripeIntentGatewayRequest.setPaymentGatewayToReplaceId(replacedPaymentGateway.paymentGatewayId as number);
          request = grpcUnaryCall(replaceGatewayToStripeIntentGatewayRequest, clientService, clientService.replaceGatewayToStripeIntentGateway)
        }
        else {
          request = dispatch(createStripeIntentGateway(currentCompanyDomain, authorizationCode, currentGatewayState.displayName))
        }

        const data = await request as CreateStripeIntentGatewayResponse
        dispatch(resetPaymentGatewaysState());
        dispatch(setPaymentGatewaysField("createdPaymentGatewayId", data?.getPaymentGatewayId?.()));
        dispatch(setPaymentGatewaysField("selectedPaymentGateway", undefined));
        dispatch(setPaymentGatewaysField("replacedPaymentGateway", undefined));
        dispatch(fetchPaymentGateways(currentCompanyDomain));
        // alert(response.getPaymentGatewayId())
        // the re-route will clean the query-string that holds the stripe code
        history.push(`/configuration/payment-gateways?createdGatewayId=${data?.getPaymentGatewayId?.()}`);
        
      } 
      else {
        await dispatch(updatePaymentGatewayName(currentCompanyDomain, currentGatewayState.paymentGatewayId, currentGatewayState.displayName));
        await dispatch(fetchPaymentGateways(currentCompanyDomain));
        dispatch(setPaymentGatewaysField(`paymentGateway${idx}`, { ...currentGatewayState, isCollapsed: true }));
      }
      setIsLoading(false)
    } catch (err) {
      setIsLoading(false);
    }
  }
  return (
    <StepCollapsablePanel
      className="payment-gateways__step-panel"
      isCollapsed={paymentGateway.mustActivate ? false : isOneGatewayPending ? true : currentGatewayState.isCollapsed}
      isShowTitleIcon={false}
      onClick={() => {
        dispatch(setPaymentGatewaysField(`paymentGateway${idx}`, { ...currentGatewayState, isCollapsed: paymentGateway.mustActivate ? false : !currentGatewayState.isCollapsed }));
      }}
      onFocus={() => { }}
      hasError={false}
      title={
        <div className="payment-gateways__added-title">
          <div>
            {paymentGateway.isLogoFontAwesome && <i className={paymentGateway.logo} />}
            {!paymentGateway.isLogoFontAwesome && <img src={paymentGateway.logo} alt="no img" />}
            <div>
              {paymentGateway.title && <Text content={paymentGateway.title} shouldTranslate={false} noMargin />}
              {!paymentGateway.title && <Text content="PAYMENT_GATEWAYS_DEFAULT_NAME" noMargin />}
              <Text content={paymentGateway.subTitle} noMargin />
            </div>
          </div>
          <div>
            <div data-tip data-for={!paymentGateway.isReplaceable ? "warning-replaceable-tooltip" : ""}>
              <Button
                id="replace-gateway"
                title="PAYMENT_GATEWAYS_REPLACE"
                disabled={!paymentGateway.isReplaceable}
                icon="fas fa-sync"
                buttonType="add"
                onClick={(evt: FormEvent) => {
                  evt.preventDefault()
                  dispatch(setPaymentGatewaysField("replacedPaymentGateway", paymentGateway));
                  document.querySelector("#payment-gateways__available")?.scrollIntoView?.({behavior: "smooth"});
                }}
                isVisible={!currentGatewayState.isCollapsed}
              />
            </div>
            <div data-tip data-for={paymentGateway.hasMappedCurrency ? "warning-disconnect-tooltip" : ""}>
              <Button
                id="disconnect-gateway"
                title="PAYMENT_GATEWAYS_DISCONNECT"
                disabled={paymentGateway.hasMappedCurrency}
                icon={paymentGateway.hasMappedCurrency ? "fas fa-lock" : undefined}
                buttonType="error"
                onClick={() => {
                  dispatch(deletePaymentGateway(currentCompanyDomain, Number(paymentGateway.paymentGatewayId))).then(() => dispatch(fetchPaymentGateways(currentCompanyDomain)));
                }}
                isVisible={!currentGatewayState.isCollapsed && !paymentGateway.mustActivate && !isOneGatewayPending}
              />
            </div>
            <Button
              id="visit-website"
              title="PAYMENT_GATEWAYS_VISIT_WEBSITE_STRIPE"
              onClick={(evt: any) => {
                evt.stopPropagation();
                window.open("https://stripe.com/gb", "_self");
              }}
              buttonType="add"
              isVisible={paymentGateway.mustActivate ? true : isOneGatewayPending ? false : !currentGatewayState.isCollapsed}
            />
          </div>
        </div>
      }
      shouldTranslateTitle={false}
      helpMenuPosition="right"
      helpMenuTitle={paymentGateway.helpTitle}
      helpMenuContent={
        <div>
          {paymentGateway.helpContent.map((helpLine, index) => (
            <Text key={index} content={helpLine} />
          ))}
        </div>
      }
    >
      <Tooltip id="warning-disconnect-tooltip" place="top" type="blue">
        <Text content="PAYMENT_GATEWAYS_DISCONNECT_WARNING" noMargin />
      </Tooltip>
      <Tooltip id="warning-replaceable-tooltip" place="top" type="blue">
        <Text content="PAYMENT_GATEWAYS_FORBIDDEN_REPLACE" noMargin />
      </Tooltip>

      <div className="payment-gateways__update">
        <FormGroup>
          <Row align="center">
            <Col xs={2}>
              <FormLabel target="display-name" content="PAYMENT_GATEWAYS_DISPLAY_NAME" noMargin />
            </Col>
            <Col xs={10}>
              <Input
                id="display-name"
                placeholder={counterpart("PAYMENT_GATEWAYS_DISPLAY_NAME_PLACEHOLDER")}
                value={currentGatewayState.displayName}
                onChange={(evt: any) => dispatch(setPaymentGatewaysField(`paymentGateway${idx}`, { ...currentGatewayState, displayName: evt.target.value }))}
              />
            </Col>
          </Row>
        </FormGroup>

        <div className="payment-gateways__update-btns">
          <Button
            id="payment-gateway-save-changes"
            title="PAYMENT_GATEWAYS_SAVE_CHANGES"
            disabled={isCreatingGateway}
            isLoading={isCreatingGateway || isLoading}
            onClick={updatePaymentGateway}
          />
          <Button
            id="payment-gateway-cancel"
            buttonType="error"
            title="PAYMENT_GATEWAYS_CANCEL"
            onClick={() => {
              dispatch(setPaymentGatewaysField("replacedPaymentGateway", undefined));
              paymentGateway.mustActivate
                ? dispatch(cancelLastPaymentGateway())
                : dispatch(setPaymentGatewaysField(`paymentGateway${idx}`, { ...currentGatewayState, displayName: paymentGateway.title, isCollapsed: true }))
            }}
          />
        </div>
      </div>
    </StepCollapsablePanel>
  )
}

export default StripePaymentGatewayUpdate
