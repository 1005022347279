import React from "react";
import counterpart from "counterpart";
import Panel from "../panel/Panel";
import Text from "../text/Text";
import "./CollapsablePanelWithArrow.scss";


interface ICollapsablePanelWithArrow {
  title?: string,
  subtitle?: string,
  isCollapsed?: boolean,
  className?: string
  children?: any,
  onClick?: (evt: any) => void,
  onFocus?: (evt: any) => void,
  unmount?: boolean
}

/**
 * Collapsable panel with arrow 
 */
const CollapsablePanelWithArrow: React.FC<ICollapsablePanelWithArrow> = React.memo(({ title, subtitle, isCollapsed = true, className = "", unmount = false, children, onClick = () => { },
  onFocus = () => {} }) => {
  return (
    <Panel className={`cpanel-arrow ${className} ${isCollapsed ? "cpanel-arrow--collapsed" : ""}`} >
      <h6 className="content-panel-title cpanel-arrow-title" onClick={onClick} onFocus={onFocus}>
        <i className={isCollapsed ? "fas fa-chevron-down" : "fas fa-chevron-up"}></i> {counterpart(`${title}`)}
      </h6>
      {subtitle &&
        <Text className='cpanel-arrow-subtitle' content={subtitle} />
      }
      {unmount && isCollapsed ? null : <div className='cpanel-arrow-content' >{children}</div>}
    </Panel>
  )
})

export default CollapsablePanelWithArrow;