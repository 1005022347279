import React from "react";
import { Row, Col } from "react-grid-system";
import Panel from "../../ui/panel/Panel";
import Text from "../../ui/text/Text";
import Input from "../../ui/input/Input";
import FormLabel from "../../ui/form-label/FormLabel";
import counterpart from "counterpart";
import FormGroup from "../../ui/form-group/FormGroup";

interface ITaxRegistrationNumber {
  onChangeTaxLabel: (value: string) => void;
  onChangeTaxRegNumber: (value: string) => void;
  taxLabel: string;
  taxRegNumber: string;
  formFormat?: boolean;
}

const TaxRegistrationNumber: React.FC<ITaxRegistrationNumber> = React.memo(({ onChangeTaxLabel, onChangeTaxRegNumber, taxLabel, taxRegNumber, formFormat = false }) => {
  const renderForm = () => {
    return (
      <>
        <FormGroup>
          <Row align="center">
            <Col md={2}>
              <FormLabel target="tax-label" content="TAX_REGISTRATION_NUMBER_LABEL" noMargin />
            </Col>
            <Col md={10}>
              <Input id="tax-label" type="text" placeholder={counterpart("TAX_REGISTRATION_NUMBER_PLACEHOLDER")} value={taxLabel} onChange={(evt: any) => onChangeTaxLabel(evt.target.value)} />
            </Col>
          </Row>
        </FormGroup>
        <Row align="center">
          <Col md={2}>
            <FormLabel target="tax-reg-number" content="TAX_REGISTRATION_NUMBER_REG_NUM" noMargin />
          </Col>
          <Col md={10}>
            <Input id="tax-reg-number" type="text" placeholder="123-456-7890" value={taxRegNumber} onChange={(evt: any) => onChangeTaxRegNumber(evt.target.value)} />
          </Col>
        </Row>
      </>
    );
  };

  if (formFormat) {
   return renderForm();
  }

  return (
    <Panel title="TAX_REGISTRATION_NUMBER_TITLE">
      <Text content="TAX_REGISTRATION_NUMBER_CONTENT" />
      {renderForm()}
    </Panel>
  );
});

export default TaxRegistrationNumber;
