/**
 * @fileoverview gRPC-Web generated client stub for csvexporter.private.customers
 * @enhanceable
 * @public
 */

// GENERATED CODE -- DO NOT EDIT!


/* eslint-disable */
// @ts-nocheck


import * as grpcWeb from 'grpc-web';

import * as customers_pb from './customers_pb';


export class CustomersExporterServiceClient {
  client_: grpcWeb.AbstractClientBase;
  hostname_: string;
  credentials_: null | { [index: string]: string; };
  options_: null | { [index: string]: any; };

  constructor (hostname: string,
               credentials?: null | { [index: string]: string; },
               options?: null | { [index: string]: any; }) {
    if (!options) options = {};
    if (!credentials) credentials = {};
    options['format'] = 'text';

    this.client_ = new grpcWeb.GrpcWebClientBase(options);
    this.hostname_ = hostname;
    this.credentials_ = credentials;
    this.options_ = options;
  }

  methodDescriptorExportCustomers = new grpcWeb.MethodDescriptor(
    '/csvexporter.private.customers.CustomersExporterService/ExportCustomers',
    grpcWeb.MethodType.UNARY,
    customers_pb.ExportCustomersRequest,
    customers_pb.ExportResponse,
    (request: customers_pb.ExportCustomersRequest) => {
      return request.serializeBinary();
    },
    customers_pb.ExportResponse.deserializeBinary
  );

  exportCustomers(
    request: customers_pb.ExportCustomersRequest,
    metadata: grpcWeb.Metadata | null): Promise<customers_pb.ExportResponse>;

  exportCustomers(
    request: customers_pb.ExportCustomersRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: customers_pb.ExportResponse) => void): grpcWeb.ClientReadableStream<customers_pb.ExportResponse>;

  exportCustomers(
    request: customers_pb.ExportCustomersRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: customers_pb.ExportResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/csvexporter.private.customers.CustomersExporterService/ExportCustomers',
        request,
        metadata || {},
        this.methodDescriptorExportCustomers,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/csvexporter.private.customers.CustomersExporterService/ExportCustomers',
    request,
    metadata || {},
    this.methodDescriptorExportCustomers);
  }

}

