import { SET_PLAN, SET_CYCLE, SET_PLAN_ID, FETCH_PAYMENT_DESCRIPTION_SUCCESS, SET_CYCLE_ID, RESET_SELECTPLAN_STATE, FETCH_PRODUCT_PLANS_SUCCESS, FETCH_PRODUCT_PLANS_FAILURE, FETCH_PRODUCT_PLANS_REQUEST, UPDATE_PRODUCT_PLANS_SUCCESS, UPDATE_PRODUCT_PLANS_FAILURE, SET_SHOW_UPDATE_SUBSCRIPTION_MODAL, UPDATE_PRODUCT_PLANS_REQUEST, SET_PLAN_CHANGE_TYPE, SET_NUMBER_OF_UNITS, GET_PRICING_INFO_DISCLOSURE_SUCCESS } from "../actions/subscriptionPlanActions";
import { IProductLight, IPlanWithCycles, ICycle } from "../models/Product";
import { BillsbyAction } from "../models/BillsbyAction";
import { AppState } from "..";
import { PlanChangeType } from "../models/Subscriptions";
import { PaymentDescriptionInputModel } from "../models/Payment";
import { GetDiscountsResponse } from "../utils/grpc/generated/Billsby.Protos/billing/private/subscription/discount/discount_pb";
import { GetInvoicePricingDisclosureResponse } from "../utils/grpc/generated/Billsby.Protos/billing/public/invoice/invoice_pb";

export interface ISubscriptionPlanReducerState {
  product: { label: string, value: IProductLight } | null,
  plan: { label: string, value: IPlanWithCycles } | null,
  cycle: ICycle | null,
  productId: number | undefined,
  planId: number | undefined,
  cycleId: number | undefined,
  isFetchingProducts: boolean,
  isFetchingProductsFailure: boolean,
  products: Array<IProductLight>,
  productsDropdown: Array<{ label: string, value: IProductLight }>,
  plans: Array<IPlanWithCycles>,
  isFetchingPlans: boolean,
  plansDropdown: Array<{ label: string, value: IPlanWithCycles }>,
  isSavingSubscription: boolean,
  hasError: boolean,
  showSubscriptionUpdateModal: boolean,
  selectedPlanId: number,
  selectedCycleId: number,
  planChangeType: PlanChangeType,
  paymentDescription?: PaymentDescriptionInputModel,
  pricingInfoDisclosure: string,
  numberOfUnits: number,
}

export const initialState = {
  product: null,
  plan: null,
  cycle: null,
  productId: undefined,
  planId: undefined,
  cycleId: undefined,
  isFetchingProducts: true,
  isFetchingProductsFailure: false,
  products: [],
  productsDropdown: [],
  plans: [],
  isFetchingPlans: false,
  plansDropdown: [],
  isSavingSubscription: false,
  hasError: false,
  showSubscriptionUpdateModal: false,
  selectedPlanId: 0,
  selectedCycleId: 0,
  planChangeType: PlanChangeType.IMMEDIATE,
  numberOfUnits: 0,
  paymentDescription: undefined,
  pricingInfoDisclosure: ""
}

export default function subscriptionPlanReducer(state: ISubscriptionPlanReducerState = initialState, action: BillsbyAction, store: AppState) {
  switch (action.type) {
    case SET_PLAN:
      const selectedPlan = action.payload;
      return { ...state, plan: selectedPlan, selectedPlanId: selectedPlan.value.planId }
    case SET_CYCLE:
        const selectedCycle = action.payload;
      return { ...state, cycle: selectedCycle, selectedCycleId: !!selectedCycle ? selectedCycle.cycleId : null }
    case SET_PLAN_ID:
      return { ...state, planId: action.payload }
    case SET_CYCLE_ID:
      return { ...state, cycleId: action.payload }
    case SET_SHOW_UPDATE_SUBSCRIPTION_MODAL:
        return { ...state, showSubscriptionUpdateModal: action.payload }
    case SET_PLAN_CHANGE_TYPE:
        return { ...state, planChangeType: action.payload }
    case SET_NUMBER_OF_UNITS:
      return { ...state, numberOfUnits: action.payload }
    case FETCH_PAYMENT_DESCRIPTION_SUCCESS: 
    {
      const response = action.response as GetDiscountsResponse;
      return { ...state, paymentDescription: { values: response.toObject() } }
    }
    case GET_PRICING_INFO_DISCLOSURE_SUCCESS: {
      const pricingInfoDisclosure = action.response as GetInvoicePricingDisclosureResponse;
      return { ...state, pricingInfoDisclosure: pricingInfoDisclosure.getPricingDisclosure() }
    }
    case FETCH_PRODUCT_PLANS_REQUEST:
      return { ...state, isFetchingPlans: true }
    case FETCH_PRODUCT_PLANS_SUCCESS:
      return { ...state, isFetchingPlans: false, plans: action.response, plansDropdown: action.response.map((plan: IPlanWithCycles) => ({ label: plan.displayName || plan.name, value: plan })) }
    case FETCH_PRODUCT_PLANS_FAILURE:
      return { ...state, isFetchingPlans: false }
    case UPDATE_PRODUCT_PLANS_REQUEST:
      return { ...state, isSavingSubscription: true }
    case UPDATE_PRODUCT_PLANS_SUCCESS:
      return { ...state, isSavingSubscription: false, showSubscriptionUpdateModal: false, hasError: false }
    case UPDATE_PRODUCT_PLANS_FAILURE:
      return { ...state, isSavingSubscription: false, hasError: true }
    case RESET_SELECTPLAN_STATE:
      return { ...initialState }
    default:
      return state;
  }
}