import React, { useState } from "react";
import "./CheckoutDatepicker.scss";
import { SingleDatePicker } from "react-dates";
import "react-dates/initialize";
import "react-dates/lib/css/_datepicker.css";
import { Moment } from "moment";


interface ICheckoutDatepickerProps {
  date: Moment | null,
  onChange: (date: Moment | null) => void
  className?: string,
  placeholder?: string,
  isOutsideRange?: ((day: any) => boolean),
  enableOutsideDays?: boolean
}

const CheckoutDatepicker: React.FC<ICheckoutDatepickerProps> = ({ date, onChange, className = "", placeholder = "Date", isOutsideRange, enableOutsideDays }) => {
  const [focus, setFocus] = useState(false);

  return (
    <div className={`custom-date-picker ${className}`} onClick={(evt: any) => {
      const datePicker: HTMLElement = evt.target.querySelector("button");
      datePicker && datePicker.click();
    }}>
      <SingleDatePicker
        id="Some-id"
        date={date}
        placeholder={placeholder}
        onDateChange={date => onChange(date)}
        focused={focus}
        enableOutsideDays={enableOutsideDays}
        isOutsideRange={isOutsideRange ? (d: any) => !!isOutsideRange ? isOutsideRange(d) : false : undefined}
        onFocusChange={({ focused }) => setFocus(!!focused)}
        customInputIcon={(
          <div>
            <i className="custom-date-picker__icon far fa-calendar-day" />
            <i className="custom-date-picker__icon-chevron far fa-chevron-down" />
          </div>
        )}
        displayFormat={"MMMM DD YYYY"}
        block
        numberOfMonths={1}
        inputIconPosition="before"
      >
      </SingleDatePicker>
    </div>
  );
};

export default CheckoutDatepicker;