import React from "react";
import "./BillingWelcome.scss";

import Panel from "../../../components/ui/panel/Panel";
import Text from "../../../components/ui/text/Text";

export const BillingWelcome = () => {
  return (
    <div className="billing-welcome">
     <Panel className="billing-welcome__greeting" title="BILLING_WELCOME_TITLE">
      <Text className="billing-welcome__greeting-text" content="BILLING_WELCOME_TEXT" />
     </Panel>
    </div>
  )
}

export default BillingWelcome;