import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import InvoicesWelcome from "../../components/invoices/InvoicesWelcome";
import PageWrapper from "../../components/ui/layout/PageWrapper";
import counterpart from "counterpart";
import Helmet from "react-helmet";
import { IInvoicesReducer } from "../../reducers/invoicesReducer";
import { fetchInvoices, resetInvoicesList } from "../../actions/invoicesActions";
import NavigationAlertCurrencyMismatch from "../../components/ui/navigation-alert/NavigationAlertCurrencyMismatch";
import InvoicesList from "../../containers/invoices-list/InvoicesList";
import { AppState } from "../..";
import { PAGINATION_PAGE_SIZE } from "../../utils/constants";
import ProgressIndicator from "../../components/ui/progress-indicator/ProgressIndicator";
import NavigationBar from "../../components/ui/navigation-bar/NavigationBar";
import { useHistory } from "react-router";
import { IAuthReducerState } from "../../reducers/authReducer";


export const Invoices: React.FC = () => {
  const [showInvoicesData, setShowInvoicesData] = useState(true);
  const [isLoading, setIsLoading] = useState(true);
  const { invoiceStatus, searchedInvoices, invoicesPaginated: { currentPage }, isDateDescendingOrder } = useSelector<AppState, IInvoicesReducer>(state => state.invoicesReducer);
  const { currentCompanyDomain } = useSelector<AppState, IAuthReducerState>(state => state.auth)
  const dispatch = useDispatch<Function>()
  const history = useHistory()

  const isComingFromDashboard = useMemo(() => {
    const state = history.location.state as { from: string } | undefined
    return state?.from?.includes("/home");
  }, [history.location]);


  useEffect(() => {
    const fetchInvoicesData = async () => {
      try {
        // the first call filter ALL the invoices
        const { response: allInvoices } = await dispatch(fetchInvoices(currentCompanyDomain, currentPage, PAGINATION_PAGE_SIZE, searchedInvoices, undefined, isDateDescendingOrder));
        if (invoiceStatus?.value) {
          // the second call is necessary if we set the invoice filter from a different page (such as the dashboard)
          await dispatch(fetchInvoices(currentCompanyDomain, currentPage, PAGINATION_PAGE_SIZE, searchedInvoices, invoiceStatus.value, isDateDescendingOrder));
        }

        if (allInvoices.results) {
          setShowInvoicesData(allInvoices.results.length > 0);
        }
      }
      catch (err) {
        setShowInvoicesData(false);
      }
      finally {
        setIsLoading(false);
      }
    };

    fetchInvoicesData();

    return () => {
      !window.location.href.includes("/invoices") && dispatch(resetInvoicesList());
    };
  }, []);

  const renderInvoicesPageContents = () => {
    if (isLoading) {
      return <ProgressIndicator color="blue" coverage="full-content" />
    }
    if (!showInvoicesData) {
      return (
        <PageWrapper>
          {isComingFromDashboard
            && <NavigationBar
              pageTitle=""
              previousPageTitle="DASHBOARD_STATS_SCREEN_TITLE"
              previousPageCb={() => history.push("/home")}
            />}
          <InvoicesWelcome />
        </PageWrapper>
      );
    } else {
      return <InvoicesList isComingFromDashboard={isComingFromDashboard} />;
    }
  };

  return (
    <>
      <Helmet title={counterpart("NAVBAR_INVOICES")} />
      <NavigationAlertCurrencyMismatch />
      {renderInvoicesPageContents()}
    </>
  );
};


export default Invoices
