import counterpart from "counterpart";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { AppState } from "../..";
import { ConfigConstants } from "../../utils/config";
import { PaymentGatewayServiceClient } from "../../utils/grpc/generated/Billsby.Protos/billing/private/paymentgateway/Payment_gatewayServiceClientPb";
import { GetSpreedlyTransactionRequest, GetSpreedlyTransactionResponse, GetTransactionLogRequest, GetTransactionLogResponse } from "../../utils/grpc/generated/Billsby.Protos/billing/private/paymentgateway/payment_gateway_pb";
import { LogExtended, PaymentSourceType } from "../../utils/grpc/generated/Billsby.Protos/billing/private/paymentlogs/payment_logs_pb";
import { grpcUnaryCall } from "../../utils/grpc/grpcUtils";
import CollapsablePanelWithArrow from "../ui/collapsable-panel-with-arrow/CollapsablePanelWithArrow";
import Panel from "../ui/panel/Panel";
import ProgressIndicator from "../ui/progress-indicator/ProgressIndicator";
import Text from "../ui/text/Text";
import "./ViewTransactionLog.scss";
import { TransactionPaymentStatus } from "../../models/TransactionLogs";

interface IViewTransactionLog {
  data: Partial<LogExtended.AsObject>
}

const ViewTransactionLog: React.FC<IViewTransactionLog> = ({data: { paymentSourceType, reference }}) => {
  const currentCompanyDomain = useSelector((state: AppState) => state.auth.currentCompanyDomain)
  const [panels, setPanels] = useState({
    openPanel1: false,
    openPanel2: false,
    openPanel3: false
  });
  const [spreedlyPaymentLog, setSpreedlyPaymentLog] = useState<GetSpreedlyTransactionResponse.AsObject | undefined>(undefined);
  const [achPaymentLog, setAchPaymentLog] = useState<GetTransactionLogResponse.AsObject | undefined>(undefined);
  const [isLoading, setIsLoading] = useState(false);
  const isAch = paymentSourceType === PaymentSourceType.ACH;

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);

      if(!reference) {
        return;
      }

      try {
        const transactionRequest = isAch ? new GetTransactionLogRequest() : new GetSpreedlyTransactionRequest();
        transactionRequest.setCompanyDomain(currentCompanyDomain);
        transactionRequest.setTransactionToken(reference)
        const paymentGatewayServiceClient = new PaymentGatewayServiceClient(ConfigConstants.grpcBaseUrl);

        if (isAch) {
          const response = await grpcUnaryCall(transactionRequest, paymentGatewayServiceClient, paymentGatewayServiceClient.getTransactionLog) as GetTransactionLogResponse;
          setAchPaymentLog(response.toObject());
        } else {
          const response = await grpcUnaryCall(transactionRequest, paymentGatewayServiceClient, paymentGatewayServiceClient.getSpreedlyTransaction) as GetSpreedlyTransactionResponse;
          setSpreedlyPaymentLog(response.toObject());
        }


      } catch (err) {
        console.log(err)
      } finally {
        setIsLoading(false);
      }
    }
    fetchData();
  }, []);

  const gatewayPaymentSourceType = isAch ? achPaymentLog?.paymentSourceType : spreedlyPaymentLog?.responseCodesAndDescription?.gatewayType;
  const isStripe = gatewayPaymentSourceType === PaymentSourceType.ACH || gatewayPaymentSourceType === "stripe_payment_intents" || gatewayPaymentSourceType === "stripe";
  const isAuthorizeNet = gatewayPaymentSourceType === "authorize_net";

  const togglePanel = (panel: keyof typeof panels) => {
    setPanels(prevState => ({ ...prevState, [panel]: !prevState[panel] }));
  }

  const getSuccessfulResponseCodeContent = () => {
    const title = isStripe
      ? `${counterpart("PAYMENT_GATEWAYS_STRIPE_TITLE")} > ${counterpart("VIEW_TRANSACTION_LOG_STRIPE_SUCCESS")}`
      : `${counterpart("PAYMENT_GATEWAYS_AUTHORIZE_TITLE")} > I00001`

    return (
      <>
        <Text className='view-transaction-log__content__title' content={title} shouldTranslate={false} noMargin></Text>
        <Text content={isStripe ? "VIEW_TRANSACTION_LOG_STRIPE_SUCCESS_CONTENT" : "VIEW_TRANSACTION_LOG_AUTHORIZE_SUCCESS_CONTENT"} noMargin />
      </>
    )
  }

  const getFailedResponseCodeContent = () => {
    const transaction = spreedlyPaymentLog as GetSpreedlyTransactionResponse.AsObject;
    if (transaction?.status === TransactionPaymentStatus.Success) {
      return null
    }

    const getError = (type: "generic" | "specific") => {
      const sortedErrors = transaction.responseCodesAndDescription?.errorCodesAndDescriptionList.sort((a: any, b: any) => a.index - b.index);

      const errorObj: { code?: string; text?: string; description?: string } = {
        code: undefined,
        text: undefined,
        description: undefined
      }

      if (!sortedErrors || !sortedErrors.length) {
        return errorObj;
      }

      if (type === "generic") {
        errorObj.code = sortedErrors[0].code;
        errorObj.text = `${sortedErrors[0].text} ${sortedErrors[0].description}`;
      }

      if (type === "specific") {
        errorObj.code = sortedErrors[sortedErrors.length - 1].code;
        errorObj.text = `${sortedErrors[sortedErrors.length - 1].text} ${sortedErrors[sortedErrors.length - 1].description}`;
      }

      errorObj.description = sortedErrors[0].description

      return errorObj;
    }

    const genericError = getError("generic");
    const specificError = getError("specific");

    if (isAuthorizeNet) {
      const isSuccessError = genericError.code === "I00001";
      return (
        <>
          <Text className='view-transaction-log__content__title' content={`${counterpart("PAYMENT_GATEWAYS_AUTHORIZE_TITLE")} > ${genericError.code || counterpart("VIEW_TRANSACTION_LOG_NO_RESPONSE_CODE")}`} shouldTranslate={false} noMargin />
          {isSuccessError
            ? <Text content='VIEW_TRANSACTION_LOG_AUTHORIZE_NET_ERROR_FORMAT1' translateWith={{ message: specificError.text?.toLowerCase() }} noMargin />
            : genericError.text && <Text content={genericError.text} shouldTranslate={false} noMargin />
          }
          {isSuccessError
            ? <Text content='VIEW_TRANSACTION_LOG_AUTHORIZE_NET_ERROR_FORMAT2' translateWith={{ code: specificError.code }} noMargin />
            : genericError.description && <Text content={genericError.description} shouldTranslate={false} noMargin />}
        </>
      )
    }

    if (isStripe) {
      const isDeclined = genericError.code === "card_declined";
      return (
        <>
          <Text className='view-transaction-log__content__title' content={`${counterpart("PAYMENT_GATEWAYS_STRIPE_TITLE")} > ${specificError.code || counterpart("VIEW_TRANSACTION_LOG_NO_RESPONSE_CODE")}`} shouldTranslate={false} noMargin />
          {isDeclined
            ? <Text content='VIEW_TRANSACTION_LOG_STRIPE_ERROR_FORMAT1' translateWith={{ message: specificError.text }} noMargin />
            : specificError.text && <Text content={specificError.text} shouldTranslate={false} noMargin />
          }
          {isDeclined
            ? <Text content='VIEW_TRANSACTION_LOG_STRIPE_ERROR_FORMAT2' noMargin />
            : genericError.description && <Text content={genericError.description} shouldTranslate={false} noMargin />
          }
        </>
      )
    }

    return null;
  }

  const renderReference = () => {
    return (
      <CollapsablePanelWithArrow
        unmount
        onClick={() => togglePanel("openPanel1")}
        isCollapsed={!panels.openPanel1}
        title={isAch ? "VIEW_TRANSACTION_LOG_STRIPE_REFERENCE" : "VIEW_TRANSACTION_LOG_SPREEDLY_REFERENCE"}
        subtitle={isAch ? "VIEW_TRANSACTION_LOG_STRIPE_REFERENCE_CONTENT" : "VIEW_TRANSACTION_LOG_SPREEDLY_REFERENCE_CONTENT"}>
        <div className='view-transaction-log__content'>
          <Text className='view-transaction-log__content__title' content={reference || ""} shouldTranslate={false} noMargin></Text>
        </div>
      </CollapsablePanelWithArrow>
    )
  }

  const renderGatewayResponseCode = () => {
    const renderResponseCodeContents = () => {
      if (isAch && achPaymentLog) {
        const errorModel = achPaymentLog.errorModel || undefined;
        const isSuccessful = achPaymentLog.status === "Success";

        const title = `${counterpart("PAYMENT_GATEWAYS_STRIPE_TITLE")} > ${isSuccessful 
          ? achPaymentLog.status
          : (errorModel?.errorCode || errorModel?.declineCode || undefined)
        }`;

        const responseMessage = isSuccessful
        ? "VIEW_TRANSACTION_LOG_STRIPE_SUCCESS_CONTENT" 
        : (errorModel?.message || errorModel?.errorDescription || undefined);

        return (
          <div className='view-transaction-log__content'>
            <Text className='view-transaction-log__content__title' content={title} shouldTranslate={false} noMargin></Text>
            {responseMessage && <Text content={responseMessage} shouldTranslate={isSuccessful} noMargin />}
          </div>
        )
      }

      return (
        <div className='view-transaction-log__content'>
          {spreedlyPaymentLog?.status === TransactionPaymentStatus.Success ? getSuccessfulResponseCodeContent() : getFailedResponseCodeContent()}
        </div>
      )
    }


    if (isStripe || isAuthorizeNet) {
      return (
        <CollapsablePanelWithArrow
          unmount
          onClick={() => togglePanel("openPanel2")}
          isCollapsed={!panels.openPanel2}
          title='VIEW_TRANSACTION_LOG_GATEWAY_RESPONSE_CODE'
          subtitle='VIEW_TRANSACTION_LOG_GATEWAY_RESPONSE_CODE_CONTENT'>
          {renderResponseCodeContents()}
        </CollapsablePanelWithArrow>
      )
    }
    return null
  }

  const renderPaymentLog = () => {
    return (
      <CollapsablePanelWithArrow
        unmount
        onClick={() => togglePanel("openPanel3")}
        isCollapsed={!panels.openPanel3}
        title={isAch ? "VIEW_TRANSACTION_LOG_STRIPE_RAW_PAYMENT_LOG" : "VIEW_TRANSACTION_LOG_SPREEDLY_RAW_PAYMENT_LOG"}
        subtitle={isAch ? "VIEW_TRANSACTION_LOG_STRIPE_RAW_PAYMENT_LOG_CONTENT" : "VIEW_TRANSACTION_LOG_SPREEDLY_RAW_PAYMENT_LOG_CONTENT"}>
        <div className='view-transaction-log__content'>
          {
            isAch
              ? <Text content={JSON.stringify(JSON.parse(achPaymentLog?.rawJson || "{}") ,null,2)} component='pre' shouldTranslate={false} noMargin></Text>
              : <Text content={JSON.stringify(JSON.parse(spreedlyPaymentLog?.rawSpreedlyTransaction || "{}"), null, 2)} component='pre' shouldTranslate={false} noMargin></Text>
          }
        </div>
      </CollapsablePanelWithArrow>
    )
  }

  return (
    <div className='view-transaction-log'>
      <Panel className="view-transaction-log__title-panel" title="VIEW_TRANSACTION_LOG_TITLE">
        <Text className="view-transaction-log__title-text" content={isAch ? "VIEW_TRANSACTION_LOG_STRIPE_CONTENT" : "VIEW_TRANSACTION_LOG_CONTENT"} noMargin />
      </Panel>
      {
        isLoading
          ? <ProgressIndicator color='blue' coverage='normal'></ProgressIndicator>
          : <>
            {renderReference()}
            {renderGatewayResponseCode()}
            {renderPaymentLog()}
          </>
      }
    </div>
  )
}

export default ViewTransactionLog;