/**
 * @fileoverview gRPC-Web generated client stub for core.private.allowances
 * @enhanceable
 * @public
 */

// GENERATED CODE -- DO NOT EDIT!


import * as grpcWeb from "grpc-web";


import {
  CreateAllowanceRequest,
  CreateAllowanceResponse,
  DeleteAllowanceRequest,
  DeleteAllowanceResponse,
  GetAllowanceRequest,
  GetAllowanceResponse,
  GetAllowancesRequest,
  GetAllowancesResponse,
  GetCurrencyPriceModelsRequest,
  GetCurrencyPriceModelsResponse,
  UpdateAllowanceRequest,
  UpdateAllowanceResponse,
  UpdateUsageRequest,
  UpdateUsageResponse} from "./allowances_pb";

export class AllowancesServiceClient {
  client_: grpcWeb.AbstractClientBase;
  hostname_: string;
  credentials_: null | { [index: string]: string; };
  options_: null | { [index: string]: string; };

  constructor (hostname: string,
               credentials?: null | { [index: string]: string; },
               options?: null | { [index: string]: string; }) {
    if (!options) options = {};
    if (!credentials) credentials = {};
    options["format"] = "text";

    this.client_ = new grpcWeb.GrpcWebClientBase(options);
    this.hostname_ = hostname;
    this.credentials_ = credentials;
    this.options_ = options;
  }

  methodInfoGetCurrencyPriceModels = new grpcWeb.AbstractClientBase.MethodInfo(
    GetCurrencyPriceModelsResponse,
    (request: GetCurrencyPriceModelsRequest) => {
      return request.serializeBinary();
    },
    GetCurrencyPriceModelsResponse.deserializeBinary
  );

  getCurrencyPriceModels(
    request: GetCurrencyPriceModelsRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: GetCurrencyPriceModelsResponse) => void) {
    return this.client_.rpcCall(
      this.hostname_ +
        "/core.private.allowances.AllowancesService/GetCurrencyPriceModels",
      request,
      metadata || {},
      this.methodInfoGetCurrencyPriceModels,
      callback);
  }

  methodInfoGetAllowances = new grpcWeb.AbstractClientBase.MethodInfo(
    GetAllowancesResponse,
    (request: GetAllowancesRequest) => {
      return request.serializeBinary();
    },
    GetAllowancesResponse.deserializeBinary
  );

  getAllowances(
    request: GetAllowancesRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: GetAllowancesResponse) => void) {
    return this.client_.rpcCall(
      this.hostname_ +
        "/core.private.allowances.AllowancesService/GetAllowances",
      request,
      metadata || {},
      this.methodInfoGetAllowances,
      callback);
  }

  methodInfoCreateAllowance = new grpcWeb.AbstractClientBase.MethodInfo(
    CreateAllowanceResponse,
    (request: CreateAllowanceRequest) => {
      return request.serializeBinary();
    },
    CreateAllowanceResponse.deserializeBinary
  );

  createAllowance(
    request: CreateAllowanceRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: CreateAllowanceResponse) => void) {
    return this.client_.rpcCall(
      this.hostname_ +
        "/core.private.allowances.AllowancesService/CreateAllowance",
      request,
      metadata || {},
      this.methodInfoCreateAllowance,
      callback);
  }

  methodInfoGetAllowance = new grpcWeb.AbstractClientBase.MethodInfo(
    GetAllowanceResponse,
    (request: GetAllowanceRequest) => {
      return request.serializeBinary();
    },
    GetAllowanceResponse.deserializeBinary
  );

  getAllowance(
    request: GetAllowanceRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: GetAllowanceResponse) => void) {
    return this.client_.rpcCall(
      this.hostname_ +
        "/core.private.allowances.AllowancesService/GetAllowance",
      request,
      metadata || {},
      this.methodInfoGetAllowance,
      callback);
  }

  methodInfoUpdateAllowance = new grpcWeb.AbstractClientBase.MethodInfo(
    UpdateAllowanceResponse,
    (request: UpdateAllowanceRequest) => {
      return request.serializeBinary();
    },
    UpdateAllowanceResponse.deserializeBinary
  );

  updateAllowance(
    request: UpdateAllowanceRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: UpdateAllowanceResponse) => void) {
    return this.client_.rpcCall(
      this.hostname_ +
        "/core.private.allowances.AllowancesService/UpdateAllowance",
      request,
      metadata || {},
      this.methodInfoUpdateAllowance,
      callback);
  }

  methodInfoUpdateUsage = new grpcWeb.AbstractClientBase.MethodInfo(
    UpdateUsageResponse,
    (request: UpdateUsageRequest) => {
      return request.serializeBinary();
    },
    UpdateUsageResponse.deserializeBinary
  );

  updateUsage(
    request: UpdateUsageRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: UpdateUsageResponse) => void) {
    return this.client_.rpcCall(
      this.hostname_ +
        "/core.private.allowances.AllowancesService/UpdateUsage",
      request,
      metadata || {},
      this.methodInfoUpdateUsage,
      callback);
  }

  methodInfoDeleteAllowance = new grpcWeb.AbstractClientBase.MethodInfo(
    DeleteAllowanceResponse,
    (request: DeleteAllowanceRequest) => {
      return request.serializeBinary();
    },
    DeleteAllowanceResponse.deserializeBinary
  );

  deleteAllowance(
    request: DeleteAllowanceRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: DeleteAllowanceResponse) => void) {
    return this.client_.rpcCall(
      this.hostname_ +
        "/core.private.allowances.AllowancesService/DeleteAllowance",
      request,
      metadata || {},
      this.methodInfoDeleteAllowance,
      callback);
  }

}

