import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import CustomerInfo from "../../../components/customers/customer-info/CustomerInfo";
import PageWrapper from "../../../components/ui/layout/PageWrapper";
import SideNavBar from "../../../components/ui/side-nav-bar/SideNavBar";
import { ISideNavItems } from "../../../models/SideNavItem";
import NavigationBar from "../../../components/ui/navigation-bar/NavigationBar";
import history from "../../../utils/history";
import OneTimeCharge from "../../../components/users/one-time-charge/OneTimeCharge";
import Modal from "../../../components/modal/Modal";
import CustomerDetailsTabs from "../customer-details-tab/CustomerDetailsTab";
import "./CustomerDetails.scss";
import EditCustomer from "../../../components/customers/EditCustomer";
import { ICustomerDetailsReducerState } from "../../../reducers/customerDetailsReducer";
import { IAuthReducerState } from "../../../reducers/authReducer";
import { CustomerDataStatus, CardStatus, PaymentMethodType } from "../../../models/Customer";
import { AppState } from "../../..";
import { fetchCustomerDetails, fetchCustomerInvoices, fetchCustomerCreditNotes, setCustomerDetailsField, fetchCustomerCardDetails, resetCustomerDetails } from "../../../actions/customerDetailsActions";
import ClearPersonalData from "../../../components/clear-personal-data/ClearPersonalData";
import NoticePanel from "../../../components/ui/notice-panel/NoticePanel";
import DeleteCustomer from "../../../components/delete-customer/DeleteCustomer";
import ReplacePaymentMethod from "../../../components/replace-payment-method/ReplacePaymentMethod";
import ProgressIndicator from "../../../components/ui/progress-indicator/ProgressIndicator";
import { RouteComponentProps } from "react-router";
import { ICustomerSubscriptionsReducerState } from "../../../reducers/customerSubscriptionsReducer";
import { BillsbyDataParams, BillsbyDataType, ContainerWebsite } from "../../../utils/checkout/checkoutIntegrationModel";
import { appendBillsbyDataAttributeToCheckoutLib } from "../../../utils/checkout/checkoutIntegrationUtils";
import { findDOMNode } from "react-dom";
import { IGoLiveReducer } from "../../../reducers/goLiveReducer";
import { fetchGoLiveChecklist } from "../../../actions/goLiveActions";
import ChangeLanguage from "../change-language/ChangeLanguage";
import { fetchCustomerSubscriptions, resetCutomerSubscriptions } from "../../../actions/customerSubscriptionsActions";
import { SubscriptionStatusType } from "../../../models/Subscriptions";
import { Permission } from "../../../models/Auth";
import useCheckFeaturePermission from "../../../utils/hooks/useCheckFeaturePermission";


interface ICustomerDetailsProps extends RouteComponentProps { }

interface ICustomerUniqueId {
  id: string;
}

export const CustomerDetails: React.FC<ICustomerDetailsProps> = ({ match }) => {

  const customerDetails = useSelector<AppState, ICustomerDetailsReducerState>(state => state.customerDetailsReducer)
  const { subscriptions, isLoadingCustomerSubscription } = useSelector<AppState, ICustomerSubscriptionsReducerState>(state => state.customerSubscriptionsReducer)
  const authReducerData = useSelector<AppState, IAuthReducerState>(state => state.auth)
  const { checkList } = useSelector<AppState, IGoLiveReducer>(state => state.goLiveReducer)
  const dispatch = useDispatch();
  const isCustomerEditPermitted = useCheckFeaturePermission(Permission.CustomerEdit);
  const isCustomerAddSubscriptionPermitted = useCheckFeaturePermission(Permission.CustomerAddSubscription);
  const isCustomerReplacePaymentPermitted = useCheckFeaturePermission(Permission.CustomerReplacePaymentMethod);
  const isCustomerCaptureOneTimeChargePermitted = useCheckFeaturePermission(Permission.CustomerCaptureOneTimeCharge);
  const isCustomerClearPersonalDataPermitted = useCheckFeaturePermission(Permission.CustomerClearPersonalData);
  const isCustomerDeletionPermitted = useCheckFeaturePermission(Permission.CustomerDelete);

  const [oneTimeChargeModal, toggleOneTimeChargeModal] = useState(false);
  const [editCustomerModal, toggleEditCustomerModal] = useState(false);
  const [clearPersonalData, toggleClearPersonalDataModal] = useState(false);
  const [personalDataClearedNotice, setPersonalDataClearedNotice] = useState(false);
  const [deleteCustomerModal, toggleDeleteCustomerModal] = useState(false);

  const [isLoadingData, setIsLoadingData] = useState(false);
  const createSubscriptionRef = useRef(null);

  const params = match?.params as ICustomerUniqueId;

  const fetchData = async () => {
    setIsLoadingData(true);
    try {
      await dispatch(fetchGoLiveChecklist(authReducerData.currentCompanyDomain));
      await dispatch(fetchCustomerDetails(authReducerData.currentCompanyDomain, params.id));
      await dispatch(fetchCustomerSubscriptions(authReducerData.currentCompanyDomain, params.id));
      setIsLoadingData(false)
    } catch (err) {
      console.log(err)
      setIsLoadingData(false)
    }
  }

  useEffect(() => {
    fetchData();
    return () => {
      dispatch(resetCustomerDetails());
      dispatch(resetCutomerSubscriptions());
    }
  }, [])

  useEffect(() => {
    if (customerDetails.customerUniqueId) {
      dispatch(fetchCustomerCardDetails(authReducerData.currentCompanyId as number, customerDetails.customerUniqueId));
    }
  }, [customerDetails.customerUniqueId])


  if (isLoadingData) {
    return <ProgressIndicator color="blue" coverage="full-content"></ProgressIndicator>
  }

  const getCheckoutBtn = () => {
    window.billsbyData = {
      containerWebsite: ContainerWebsite.BILLSBY_APP,
      firstName: customerDetails.firstName,
      lastName: customerDetails.lastName,
      email: customerDetails.email,
      billingAddressLine1: customerDetails.billingAddress.addressLine1,
      billingAddressLine2: customerDetails.billingAddress.addressLine2,
      billingAddressCity: customerDetails.billingAddress.city,
      billingAddressState: customerDetails.billingAddress.state,
      billingAddressZip: customerDetails.billingAddress.postCode,
      billingAddressCountry: customerDetails.billingAddress.country
    }

    const createSubscriptionExistingCustomerProps = {
      [BillsbyDataParams.type]: BillsbyDataType.CHECKOUT,
      [BillsbyDataParams.customer]: customerDetails.customerUniqueId,
      // the control panel language is always going to be shown in English
      [BillsbyDataParams.language]: "en"
    }

    return (
      <div style={{ display: "none" }}>
        <a
          onClick={e => e.preventDefault()}
          id={`create-subscription-${customerDetails.customerUniqueId}`}
          ref={createSubscriptionRef}
          {...createSubscriptionExistingCustomerProps}
        />
      </div>
    )
  }

  const createSubscriptionCb = () => {
    appendBillsbyDataAttributeToCheckoutLib({ label: BillsbyDataParams.company, value: authReducerData.currentCompanyDomain });
    window.scanDomBillsby();
    const createSubscriptionDomEl = findDOMNode(createSubscriptionRef.current);
    createSubscriptionDomEl && (createSubscriptionDomEl as HTMLElement).click();
  }

  const sideNav: ISideNavItems = {
    navItems: [
      {
        name: "edit-customer",
        text: "CUSTOMER_DETAILS_NAV_EDIT_CUSTOMER",
        icon: "fas fa-user-edit",
        isEnabled: customerDetails.status !== CustomerDataStatus.DELETED,
        callback: () => {
          toggleEditCustomerModal(!editCustomerModal);
        },
        hasPermission: isCustomerEditPermitted
      },
      // {
      //   name: 'add-note',
      //   text: 'CUSTOMER_DETAILS_NAV_ADD_NOTE',
      //   icon: 'fas fa-comment-alt-plus',
      // },
      {
        name: "add-subscription",
        text: "CUSTOMER_DETAILS_NAV_ADD_SUBSCRIPTION",
        icon: "fas fa-cart-plus",
        // checkList?.isProductReady tell us that the company has at least one product, one plan and one cycle
        isVisible: !!subscriptions && !subscriptions.length && checkList?.isProductReady,
        isEnabled: customerDetails.status === CustomerDataStatus.ACTIVE
          && (
            customerDetails.cardDetails.paymentMethodType === PaymentMethodType.ACH
            || customerDetails.cardStatus === CardStatus.VALID
          ),
        callback: createSubscriptionCb,
        hasPermission: isCustomerAddSubscriptionPermitted
      },
      {
        name: "replace-payment-method",
        text: "CUSTOMER_DETAILS_NAV_REPLACE_PAYMENT_METHOD",
        icon: "fas fa-money-check",
        callback: () => dispatch(setCustomerDetailsField("showReplacePaymentModal", true)),
        hasPermission: isCustomerReplacePaymentPermitted
      },
      // {
      //   name: 'change-customer-language',
      //   text: 'CUSTOMER_DETAILS_NAV_CHANGE_CUSTOMER_LANGUAGE',
      //   icon: 'fa fa-globe-americas',
      //   callback: () => dispatch(setCustomerDetailsField('showChangeCustomerLanguage', true))
      // },
      {
        name: "capture-one-time-charge",
        text: "CUSTOMER_DETAILS_NAV_CAPTURE_ONE_TIME_CHARGE",
        icon: "fas fa-cash-register",
        isEnabled: customerDetails.status !== CustomerDataStatus.DELETED,
        callback: () => toggleOneTimeChargeModal(!oneTimeChargeModal),
        hasPermission: isCustomerCaptureOneTimeChargePermitted
      },
      {
        name: "clear-personal-data-gdpr",
        text: "CUSTOMER_DETAILS_NAV_CLEAR_PERSONAL_DATA",
        icon: "fas fa-ghost",
        isEnabled: (customerDetails.status === CustomerDataStatus.ACTIVE && !!subscriptions && !subscriptions.length) || customerDetails.status === CustomerDataStatus.INACTIVE,
        callback: () => (customerDetails.status === CustomerDataStatus.ACTIVE || customerDetails.status === CustomerDataStatus.INACTIVE ? toggleClearPersonalDataModal(!clearPersonalData) : null),
        hasPermission: isCustomerClearPersonalDataPermitted
      },
      {
        name: "delete-customer",
        text: "CUSTOMER_DETAILS_NAV_DELETE_CUSTOMER",
        icon: "fas fa-trash-alt",
        className: "nav-item-icon-red",
        isEnabled: customerDetails.status !== CustomerDataStatus.DELETED && !isLoadingCustomerSubscription && (!subscriptions?.length || (!!subscriptions?.length && subscriptions?.every(i => i.status === SubscriptionStatusType.CANCELLED))),
        callback: () => toggleDeleteCustomerModal(!deleteCustomerModal),
        hasPermission: isCustomerDeletionPermitted
      }
    ]
  };

  const onUpdateCustomer = async () => {
    if (customerDetails.customerUniqueId) {
      toggleEditCustomerModal(false);
      await dispatch(fetchCustomerDetails(authReducerData.currentCompanyDomain, customerDetails.customerUniqueId));
    }
  };

  const handleOneTimeChargeCallback = async () => {
    try {
      if (customerDetails.customerUniqueId) {
        toggleOneTimeChargeModal(false);
        await dispatch(fetchCustomerCreditNotes(authReducerData.currentCompanyDomain, customerDetails.customerUniqueId.toString()));
        await dispatch(fetchCustomerInvoices(authReducerData.currentCompanyDomain, customerDetails.customerUniqueId.toString()));
      }
    } catch (err) {
      console.log(err);
    }
  };

  const handleClearPersonalDataCallback = async () => {
    try {
      if (customerDetails.customerUniqueId) {
        await onUpdateCustomer();
        toggleClearPersonalDataModal(false);
        setPersonalDataClearedNotice(true);
      }
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <div>
      <NavigationBar pageTitle="" previousPageTitle="CUSTOMERS" previousPageCb={() => history.push("/customers")} />
      {personalDataClearedNotice && <NoticePanel className="cleared-personal-data__notice" type="success" icon="fas fa-check-circle" content="CLEAR_PERSONAL_DATA_SUCCESS_MESSAGE" />}
      {getCheckoutBtn()}
      <PageWrapper>
        <div className="customer_details">
          <div className="customer_details__content">
            <CustomerInfo />
          </div>
          <div className="customer_details__nav">
            <SideNavBar navItems={sideNav.navItems} />
          </div>
        </div>
        <CustomerDetailsTabs />
      </PageWrapper>

      <Modal isOpen={oneTimeChargeModal} onRequestClose={() => toggleOneTimeChargeModal(!oneTimeChargeModal)} title="ONE_TIME_CHARGE_PANEL1_TITLE">
        <OneTimeCharge handleOneTimeChargeCallback={handleOneTimeChargeCallback} />
      </Modal>
      <Modal isOpen={editCustomerModal} onRequestClose={() => toggleEditCustomerModal(!editCustomerModal)} title="EDIT_CUSTOMER_PANEL1_TITLE">
        <EditCustomer onUpdateCustomer={onUpdateCustomer} />
      </Modal>
      <Modal isOpen={clearPersonalData} onRequestClose={() => toggleClearPersonalDataModal(!clearPersonalData)} title="CLEAR_PERSONAL_DATA_MODAL_HEADER">
        <ClearPersonalData handleClearPersonalDataCallback={handleClearPersonalDataCallback} />
      </Modal>
      <Modal isOpen={deleteCustomerModal} onRequestClose={() => toggleDeleteCustomerModal(!deleteCustomerModal)} title="DELETE_CUSTOMER_MODAL_HEADER">
        <DeleteCustomer />
      </Modal>
      <Modal isOpen={customerDetails.showReplacePaymentModal} onRequestClose={() => dispatch(setCustomerDetailsField("showReplacePaymentModal", false))} title="REPLACE_CARD_TITLE">
        <ReplacePaymentMethod />
      </Modal>
      <Modal isOpen={customerDetails.showChangeCustomerLanguage} onRequestClose={() => dispatch(setCustomerDetailsField("showChangeCustomerLanguage", false))} title="CHANGE_LANGUAGE_TITLE" className="subscriptions_details__change-language__modal" >
        <ChangeLanguage handleCallback={() => dispatch(setCustomerDetailsField("showChangeCustomerLanguage", false))} />
      </Modal>
    </div>
  );
};

export default CustomerDetails