import React from "react";
import Panel from "../panel/Panel";
import Text from "../text/Text";
import Button from "../button/Button";
import "./ErrorPanel.scss";
import { openIntercomIframe } from "../../../utils/commonUtils";

interface IErrorPanel {
  title?: string,
  subtitle?: string,
  buttonText?: string,
  buttonIcon?: string,
  buttonCb?: () => void,
  disabled?: boolean,
  shouldTranslateTitle?: boolean,
  shouldTranslateSubtitle?: boolean,
  shouldTranslateButton?: boolean,
  translateTitleWith?: object,
  translateSubTitleWith?: object,
  isVisible?: boolean,
  icon?: string
  className?:string,
  image?: string,
  closeCb?: () => void
}

const ErrorPanel: React.FC<IErrorPanel> = React.memo(({ className, disabled = false, translateTitleWith, translateSubTitleWith, title = "ERROR_PANEL_TITLE", subtitle = "ERROR_PANEL_SUBTITLE", buttonText = "ERROR_PANEL_BUTTON_TEXT",
  buttonIcon = "fas fa-headset", buttonCb = openIntercomIframe, shouldTranslateTitle = true, shouldTranslateSubtitle = true, shouldTranslateButton = true,
  isVisible = true, icon = "fas fa-exclamation-triangle", image, closeCb }) => {

  if (!isVisible) {
    return null;
  }

  return (
    <Panel className={`error-panel ${className}`}>
      <div className="error-panel__titlecontainer">
        {image ? <img src={image} alt="img"/> : <i className={icon}></i>}
        <div>
          <Text content={title} shouldTranslate={shouldTranslateTitle} translateWith={translateTitleWith} noMargin />
          <Text content={subtitle} shouldTranslate={shouldTranslateSubtitle} translateWith={translateSubTitleWith} noMargin />
        </div>
      </div>

      <Button
        disabled={disabled}
        id="error-panel-btn"
        buttonType="add"
        title={buttonText}
        icon={buttonIcon}
        onClick={buttonCb}
        shouldTranslate={shouldTranslateButton}
      />
      { closeCb && <i className="error-panel__close-btn fas fa-times" onClick={closeCb}></i> }
    </Panel>
  )
})

export default ErrorPanel;