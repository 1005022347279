import React from "react";
import Button from "../../../../../ui/button/Button";
import Panel from "../../../../../ui/panel/Panel";
import Text from "../../../../../ui/text/Text";
import "./EmailCommandButton.scss";

interface Props {
  title: string,
  description: string,
  buttonText: string,
  id: string,
  noMargin?: boolean,
  isLoading?: boolean,
  onSubmit: () => void
}

export const EmailCommandButton: React.FC<Props> = ({title = "", description = "", buttonText = "", noMargin = false, isLoading = false, id = "", onSubmit = () => { }}) => {
  return (
    <Panel title={title} shouldTranslate={false} noMargin={noMargin}>
    <Text content={description} shouldTranslate={false} className="email-command-button-description"/>
      <Button className="email-command-button-submit"
        id={`btn-${id}`}
        title={buttonText}
        shouldTranslate={false}
        buttonType="add"
        onClick={onSubmit}
        isLoading={isLoading}
      />
    </Panel>
  )
}

export default EmailCommandButton;
