import React, { useLayoutEffect, useState } from "react";
import "./PendingInvitations.scss";
import API from "../../utils/API";
import { PendingInvitation, AcceptDeclinePendingInvitation } from "../../models/Invitations";
import Panel from "../ui/panel/Panel";
import Text from "../ui/text/Text";
import Button from "../ui/button/Button";
import { initAuthData } from "../../actions/authActions";
import { useDispatch } from "react-redux";
import { refreshToken } from "../../utils/authUtils";


const PendingInvitations: React.FC = () => {
  const dispatch = useDispatch();
  const [pendingInvitations, setPendingInvitations] = useState<Array<PendingInvitation>>([]);

  const fetchPendingInvitations = async () => {
    try {
      const pendingInvitations = await API.fetchPendingInvitations() as Array<PendingInvitation>;
      setPendingInvitations(pendingInvitations);
    }
    catch (err) { }
  }

  const acceptDeclinePendingInvitation = async (data: AcceptDeclinePendingInvitation) => {
    try {
      await API.acceptDeclinePendingInvitation(data);
      await fetchPendingInvitations();
      //fetch the new list of companies
      await dispatch(initAuthData());
      // fetch a new token to get the updated api permissions
      await refreshToken();
    }
    catch (err) { }
  }


  useLayoutEffect(() => {
    fetchPendingInvitations();
  }, [])

  if (!pendingInvitations.length) {
    return null;
  }

  return (
    <div className="pending-invitations">
      <Text className="pending-invitations__title" content="INVITATION_PENDING_TITLE" />
      {
        pendingInvitations.map(invitation => {
          const { companyId, companyName, invitationCode } = invitation;
          return (
            <Panel key={companyId} className="pending-invitations__el" title="INVITATIONS_COMPANY_WANTS_USER_TO_JOIN" translateWith={{ companyName }}>
              <div>
                <Button
                  id="accept-invitation"
                  title="INVITATIONS_ACCEPT"
                  buttonType="success"
                  onClick={acceptDeclinePendingInvitation.bind(null, { invitationAccepted: true, companyId, invitationCode })}
                />
                <Text
                  content="INVITATIONS_DECLINE"
                  component="a"
                  onClick={acceptDeclinePendingInvitation.bind(null, { invitationAccepted: false, companyId, invitationCode })}
                  noMargin
                />
              </div>
            </Panel>
          )
        })
      }
    </div>
  )
}

export default PendingInvitations;