import React, { useState, useEffect } from "react";
import Text from "../../components/ui/text/Text";
import Button from "../../components/ui/button/Button";
import moment from "moment";
import { Timestamp } from "google-protobuf/google/protobuf/timestamp_pb";
import { ViesVatValidation } from "../../utils/grpc/generated/Billsby.Protos/billing/private/customer/customer_pb";
import { copyToClipboard } from "../../utils/commonUtils";

interface ITaxValidationRow {
  data: ViesVatValidation;
}

const TaxValidationRow: React.FC<ITaxValidationRow> = ({ data }) => {

  const [isCopied, setIsCopied] = useState(false);

  let copyTimeout: ReturnType<typeof setTimeout>

  const onCopy = () => {
    if (isCopied) {
      return;
    }    
    setIsCopied(true);
    copyToClipboard(data.getNumber())

    if(!copyTimeout) {
      copyTimeout = setTimeout(() => {
        setIsCopied(false);
      }, 2000);
    }
  };

  useEffect(() => {
    return () => clearTimeout(copyTimeout)
  }, [])

  return (
    <tr>
      <Text component="td" content={data.getNumber()} shouldTranslate={false} noMargin />
      <Text component="td" content={moment.unix((data.getDateValidated() as Timestamp).getSeconds()).utc().format("DD MMM YYYY [at] HH:MM:SS [UTC]")} shouldTranslate={false} noMargin />
      <td>
        <Button  buttonType="add" icon={isCopied ? "fas fa-check-circle" : "fal fa-copy"} id="copy-number" title={isCopied ? "TAX_VALIDATION_COPIED" : "TAX_VALIDATION_COPY"} onClick={onCopy} />
      </td>
    </tr>
  );
};

export default TaxValidationRow;
