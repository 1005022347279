import { makeActionCreator } from "../utils/reduxUtils";
import API from "../utils/API";

/**********************  SYNCHRONOUS ACTIONS ********************/
export const SET_REMEMBER_USER = "SET_REMEMBER_USER";
export const setRememberUser = makeActionCreator(SET_REMEMBER_USER, "payload");
export const SET_EMAIL = "SET_EMAIL";
export const setEmail = makeActionCreator(SET_EMAIL, "payload");
export const SET_PASSWORD = "SET_PASSWORD";
export const setPassword = makeActionCreator(SET_PASSWORD, "payload");
export const SET_CONFIRM_PASSWORD = "SET_CONFIRM_PASSWORD";
export const setConfirmPassword = makeActionCreator(SET_CONFIRM_PASSWORD, "payload");
export const CLEAR_ERRORS = "CLEAR_ERRORS";
export const clearErrors = makeActionCreator(CLEAR_ERRORS, "payload");
export const CHECK_PASSWORD_VALIDITY = "CHECK_PASSWORD_VALIDITY";
export const checkPasswordValidity = makeActionCreator(CHECK_PASSWORD_VALIDITY, "payload");
export const SET_SIX_DIGITS_2FA_PART1 = "SET_SIX_DIGITS_2FA_PART1";
export const setSixDigits2faPart1 = makeActionCreator(SET_SIX_DIGITS_2FA_PART1, "payload");
export const SET_SIX_DIGITS_2FA_PART2 = "SET_SIX_DIGITS_2FA_PART2";
export const setSixDigits2faPart2 = makeActionCreator(SET_SIX_DIGITS_2FA_PART2, "payload");
export const SET_INCORRECT_PASSWORD_COUNT = "SET_INCORRECT_PASSWORD_COUNT";
export const setIncorrectPasswordCount = makeActionCreator(SET_INCORRECT_PASSWORD_COUNT, "payload");

export const LOGIN_USER_REQUEST = "LOGIN_USER_REQUEST";
export const LOGIN_USER_SUCCESS = "LOGIN_USER_SUCCESS";
export const LOGIN_USER_FAILURE = "LOGIN_USER_FAILURE";

export const RESET_PASSWORD_REQUEST = "RESET_PASSWORD_REQUEST";
export const RESET_PASSWORD_REQUEST_SUCCESS = "RESET_PASSWORD_REQUEST_SUCCESS";
export const RESET_PASSWORD_REQUEST_FAILURE = "RESET_PASSWORD_REQUEST_FAILURE";

export const SUBMIT_NEW_PASSWORD_REQUEST = "SUBMIT_NEW_PASSWORD_REQUEST";
export const SUBMIT_NEW_PASSWORD_REQUEST_SUCCESS = "SUBMIT_NEW_PASSWORD_REQUEST_SUCCESS";
export const SUBMIT_NEW_PASSWORD_REQUEST_FAILURE = "SUBMIT_NEW_PASSWORD_REQUEST_FAILURE";

export const SEND_LOGIN_CODE_VIA_SMS_REQUEST = "SEND_LOGIN_CODE_VIA_SMS_REQUEST";
export const SEND_LOGIN_CODE_VIA_SMS_SUCCESS = "SEND_LOGIN_CODE_VIA_SMS_SUCCESS";
export const SEND_LOGIN_CODE_VIA_SMS_FAILURE = "SEND_LOGIN_CODE_VIA_SMS_FAILURE";


/**********************  ASYNCHRONOUS ACTIONS (api requests) ********************/
export const loginUser = function (email: string, password: string, code2fa?: string, redirectAfterLogin: boolean = true)  {
  return {
    types: [LOGIN_USER_REQUEST, LOGIN_USER_SUCCESS, LOGIN_USER_FAILURE],
    callAPI: () => API.loginUser(email, password, code2fa, redirectAfterLogin)
  }
}

export const requestPasswordLink = function (email: string) {
  return {
    types: [RESET_PASSWORD_REQUEST, RESET_PASSWORD_REQUEST_SUCCESS, RESET_PASSWORD_REQUEST_FAILURE],
    callAPI: () => API.requestPasswordLink(email)
  }
}

export const submitNewPassword = function (email: string, code: string) {
  return {
    types: [SUBMIT_NEW_PASSWORD_REQUEST, SUBMIT_NEW_PASSWORD_REQUEST_SUCCESS, SUBMIT_NEW_PASSWORD_REQUEST_FAILURE],
    callAPI: () => API.submitNewPassword(email, code)
  }
}

export const sendLoginCodeViaSMS = function (data: { email: string, password: string }) {
  return {
    types: [SEND_LOGIN_CODE_VIA_SMS_REQUEST, SEND_LOGIN_CODE_VIA_SMS_SUCCESS, SEND_LOGIN_CODE_VIA_SMS_FAILURE],
    callAPI: () => API.sendLoginCodeViaSMS(data)
  }
}