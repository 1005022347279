import React, { useLayoutEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../../index";
import PageWrapper from "../../components/ui/layout/PageWrapper";
import counterpart from "counterpart";
import Helmet from "react-helmet";
import NavigationAlertCurrencyMismatch from "../../components/ui/navigation-alert/NavigationAlertCurrencyMismatch";
import "./CreditNotes.scss";
import Table, { TableArrowBtn } from "../../components/ui/table/Table";
import Text from "../../components/ui/text/Text";
import moment from "moment";
import StatusLabel from "../../components/ui/status-label/StatusLabel";
import Search from "../../components/search/Search";
import Pagination from "../../components/ui/pagination/Pagination";
import history from "../../utils/history";
import CreditNotesWelcome from "../../components/credit-notes/CreditNotesWelcome";
import { fetchCreditNotes, resetCreditNotes, setCreditNotesField } from "../../actions/creditNotesActions";
import { getCreditNoteStatusText, getCreditNoteStatusColor } from "../../utils/commonUtils";
import ProgressIndicator from "../../components/ui/progress-indicator/ProgressIndicator";
import { PAGINATION_PAGE_SIZE } from "../../utils/constants";
import FilterDropdown from "../../components/ui/filter-dropdown/FilterDropdown";
import { CreditNoteStatus } from "../../models/CreditNotes";
import FilterSort from "../../components/ui/filter-sort/FilterSort";
import Modal from "../../components/modal/Modal";
import ExportData from "../../components/export-data/ExportData";
import Button from "../../components/ui/button/Button";
import NoticePanel from "../../components/ui/notice-panel/NoticePanel";
import { Permission } from "../../models/Auth";
import Tooltip from "../../components/ui/tooltip/Tooltip";

const CreditNotes: React.FC = () => {
  const { currentCompanyDomain, creditNotesData, currentCompanyId, parsedToken } = useSelector((state: AppState) => ({ currentCompanyDomain: state.auth.currentCompanyDomain, creditNotesData: state.creditNotesReducer, currentCompanyId: state.auth.currentCompanyId, parsedToken: state.auth.parsedToken }))
  const dispatch = useDispatch();

  const { creditNotesPaginated, searchedCreditNotes, hasCreditNotes, creditNoteStatus, isDateDescendingOrder, isFetching, pageNotification } = creditNotesData;
  const { currentPage, pageCount, results } = creditNotesPaginated;

  const [isLoadingData, setIsLoadingData] = useState(true);
  const [showExportCreditNotesModal, setShowExportCreditNotesModal] = useState(false);

  const hasPermissionDataExport = useMemo(() => {
    if(parsedToken?.CurrentPermissions.some(p => p === Permission[Permission.DataExportsCsv])) {
      return true
    }
    return false
  }, [parsedToken])

  const filterOptions = [
    { label: "CREDIT_NOTES_STATUS_PAID", value: CreditNoteStatus.PAID },
    { label: "CREDIT_NOTES_STATUS_PENDING", value: CreditNoteStatus.PENDING },
    { label: "CREDIT_NOTES_STATUS_UNPAID", value: CreditNoteStatus.UNPAID },
  ]

  useLayoutEffect(() => {
    const loadData = async () => {
      try {
        await dispatch(fetchCreditNotes(currentCompanyDomain, currentPage, PAGINATION_PAGE_SIZE, searchedCreditNotes, creditNoteStatus?.value, isDateDescendingOrder));
      }
      catch(err) {}
      finally {
        setIsLoadingData(false);
      }
    }
    loadData();

    return function cleanup() {
      !window.location.href.includes("/credit-notes") && dispatch(resetCreditNotes());
    };
  }, []);

  if(isLoadingData) {
    return <ProgressIndicator color="blue" coverage="full-screen" />;
  }

  if (hasCreditNotes === false) {
    return (
      <div>
        <Helmet title={counterpart("NAVBAR_CREDIT_NOTES")} />
        <NavigationAlertCurrencyMismatch />
        <PageWrapper>
          <CreditNotesWelcome />
        </PageWrapper>
      </div>
    );
  }

  return (
    <div className="credit-notes">
      <Helmet title={counterpart("NAVBAR_CREDIT_NOTES")} />
      <NavigationAlertCurrencyMismatch />
      <div className="credit-notes__header">
        <div className="credit-notes__search">
          <Search
            value={searchedCreditNotes}
            placeholder="CREDIT_NOTES_SEARCH_PLACEHOLDER"
            onChange={(evt: any) => dispatch(setCreditNotesField("searchedCreditNotes", evt.target.value))}
            onSearch={() => {
              dispatch(fetchCreditNotes(currentCompanyDomain, 1, undefined, searchedCreditNotes, creditNoteStatus?.value, isDateDescendingOrder))
            }}
          />
          <div className="credit-notes__export-btn__container" data-tip data-for="permission-warning-tooltip">
            <Button 
              id="credit-notes-export"
              buttonType="add"
              disabled={!hasPermissionDataExport}
              className="credit-notes__export-btn" 
              title="EXPORT_CREDIT_NOTES_DATA" 
              onClick={() => setShowExportCreditNotesModal(true)} 
              icon="far fa-file-download"
            />
            {
              !hasPermissionDataExport &&
              <Tooltip id="permission-warning-tooltip" place="bottom" type="blue">
                <Text content="EXPORT_CUSTOMER_DATA_WARNING_PERMISSION" noMargin />
              </Tooltip>
            }
          </div>
          <Pagination
            className="credit-notes__search__pagination"
            forcePage={currentPage - 1}
            pageCount={pageCount}
            isVisible={pageCount > 1}
            onPageChange={({ selected }) => {
              dispatch(setCreditNotesField("creditNotesPaginated", { ...creditNotesPaginated, currentPage: selected + 1 }));
              dispatch(fetchCreditNotes(currentCompanyDomain, selected + 1, undefined, searchedCreditNotes, creditNoteStatus?.value, isDateDescendingOrder))
            }}
          />
        </div>
      </div>
      {isFetching && <ProgressIndicator color="blue" />}
      {!isFetching &&
        <PageWrapper>
          {pageNotification && <NoticePanel isModal className="customers__notice-panel" type={pageNotification.type} icon={pageNotification.icon} content={pageNotification.content} translateWith={pageNotification.translateWith}></NoticePanel>}
          <Table enableHoverEffect className="credit-notes__table">
            <thead>
              <tr>
                <FilterSort label="CREDIT_NOTES_LIST_TABLE_DATE"
                  className="credit-notes__date-column" 
                  component="th"
                  isDescendingOrder={isDateDescendingOrder} 
                  onClick={(bool) => {
                    dispatch(setCreditNotesField("isDateDescendingOrder", bool))
                    dispatch(fetchCreditNotes(currentCompanyDomain, currentPage, undefined, searchedCreditNotes, creditNoteStatus?.value, bool))
                  }}  
                />
                <Text content="CREDIT_NOTES_LIST_TABLE_CUSTOMER_NAME" component="th" noMargin className="credit-notes__customer-name-column" />
                <Text content="CREDIT_NOTES_LIST_TABLE_AMOUNT_CREDITED" component="th" noMargin className="credit-notes__amount-credited-column" />
                <Text content="CREDIT_NOTES_LIST_TABLE_CREDIT_NOTE_NUMBER" component="th" noMargin className="credit-notes__credit-notes-number-column" />
                <FilterDropdown label="CREDIT_NOTES_LIST_TABLE_STATUS" component="th"
                  className="credit-notes__status-filter credit-notes__status-column"
                  options={filterOptions}
                  selected={creditNoteStatus}
                  onSelect={(filter) => {
                    dispatch(setCreditNotesField("creditNoteStatus", filter));
                    dispatch(fetchCreditNotes(currentCompanyDomain, 1, undefined, searchedCreditNotes, filter.value, isDateDescendingOrder))
                  }}
                  onClear={() => {
                    dispatch(setCreditNotesField("creditNoteStatus", undefined));
                    dispatch(fetchCreditNotes(currentCompanyDomain, 1, undefined, searchedCreditNotes, undefined, isDateDescendingOrder))
                  }}
                />
                <Text content="CREDIT_NOTES_LIST_TABLE_CUSTOMER_ID" component="th" noMargin />
                <Text className="view-arrow" content="" component="th" shouldTranslate={false} noMargin />
              </tr>
            </thead>
            <tbody>
              {results &&
                results.map(creditNote => (
                  <tr className="credit-notes__table__item"  key={creditNote.creditNoteUniqueId} onClick={() => history.push(`/credit-notes/${creditNote.creditNoteUniqueId}/customer/${creditNote.customerUniqueId}`)}>
                    <td className="credit-notes__date-column">{moment(creditNote.createdOn).format("DD MMM YYYY")}</td>
                    <td className="credit-notes__customer-name-column">{creditNote.customerFullName}</td>
                    <td className="credit-notes__amount-credited-column">{`-${creditNote.amount}`}</td>
                    <td className="credit-notes__credit-notes-number-column">{creditNote.creditNoteUniqueId}</td>
                    <td className="credit-notes__status-column">
                      <StatusLabel content={getCreditNoteStatusText(creditNote.status)} color={getCreditNoteStatusColor(creditNote.status)} />
                    </td>
                    <td>{creditNote.customerUniqueId}</td>
                    <TableArrowBtn link={true} to={`/credit-notes/${creditNote.creditNoteUniqueId}/customer/${creditNote.customerUniqueId}`}/>
                  </tr>
                ))}
            </tbody>
          </Table>
        </PageWrapper>
      }
      <Modal
        isOpen={showExportCreditNotesModal}
        title="EXPORT_CREDIT_NOTES_DATA"
        onRequestClose={() => setShowExportCreditNotesModal(false)}
        children={
        <ExportData
          title="EXPORT_CREDIT_NOTES_DATA_PANEL_TITLE"
          content="EXPORT_CREDIT_NOTES_DATA_PANEL_CONTENT"
          type='credit-notes'
          companyId={currentCompanyId}
          callback={() => {
            setShowExportCreditNotesModal(false);
            dispatch(setCreditNotesField("pageNotification", { type: "success", icon: "fas fa-check-circle", content: "EXPORT_DATE_SUCCESS_NOTIFICATION", translateWith: { email: parsedToken?.email } }));
          }}
        />}
      />

    </div>
  );
};

export default CreditNotes;
