import React, { useState, useLayoutEffect } from "react";
import Text from "../../ui/text/Text";
import Checkbox from "../../ui/checkbox/Checkbox";
import StepCollapsablePanel from "../../ui/step-collapsable-panel/StepCollapsablePanel";
import "./EuropeanUnionRegistrationSteps.scss";
import TaxRegistrationNumber from "./TaxRegistrationNumber";
import Button from "../../ui/button/Button";

interface IEuropeanUnionRegistrationSteps {
  isDigital?: boolean;
  isRegistered?: boolean;
  taxLabel: string;
  taxRegNumber: string;
  onChangeProductType: (value: boolean | undefined) => void;
  onChangeRegistration: (value: boolean | undefined) => void;
  onChangeTaxLabel: (value: string) => void;
  onChangeTaxRegNumber: (value: string) => void;
  onClickButton: () => void;
}

const EuropeanUnionRegistrationSteps: React.FC<IEuropeanUnionRegistrationSteps> = ({
  isDigital,
  isRegistered,
  taxLabel,
  taxRegNumber,
  onChangeProductType,
  onChangeRegistration,
  onChangeTaxLabel,
  onChangeTaxRegNumber,
  onClickButton
}) => {
  const [activeStep, setActiveStep] = useState(1);

  useLayoutEffect(() => {
    //Restart value
    if (!isDigital) {
      onChangeRegistration(undefined);
      onChangeTaxLabel("");
      onChangeTaxRegNumber("");
    }

    if (!isRegistered) {
      onChangeTaxLabel("");
      onChangeTaxRegNumber("");
    }
  }, [isDigital, isRegistered]);

  const enableContinue = () => {
    if (isDigital === undefined) {
      return false;
    }

    if (isDigital) {
      if (isRegistered !== undefined) {
        if (isRegistered) {
          return !!taxLabel && !!taxRegNumber;
        }
        return true;
      }
      return false;
    }
    return true;
  };

  const renderStep1 = () => {
    return (
      <StepCollapsablePanel number={1} title="EUROPEAN_UNION_STEP1_TITLE" isCollapsed={activeStep !== 1} isLocked={false} hasHelpSection={false} onClick={() => setActiveStep(1)}>
        <div className="european-union-steps__content">
          <Checkbox
            checked={isDigital}
            onClick={() => onChangeProductType(true)}
            value={""}
            content={
              <div className="european-union-steps__label">
                <Text content="EUROPEAN_UNION_STEP1_OPT1_TITLE" noMargin />
                <Text content="EUROPEAN_UNION_STEP1_OPT1_SUBTITLE" noMargin />
              </div>
            }
          />
          <Checkbox
            checked={isDigital === false}
            onClick={() => onChangeProductType(false)}
            value={""}
            content={
              <div className="european-union-steps__label">
                <Text content="EUROPEAN_UNION_STEP1_OPT2_TITLE" noMargin />
                <Text content="EUROPEAN_UNION_STEP1_OPT2_SUBTITLE" noMargin />
              </div>
            }
          />
        </div>
      </StepCollapsablePanel>
    );
  };

  const renderStep2 = () => {
    return (
      <StepCollapsablePanel
        number={2}
        title="EUROPEAN_UNION_STEP2_TITLE"
        isCollapsed={activeStep !== 2}
        isShowLockIcon={!isDigital}
        isLocked={!isDigital}
        hasHelpSection={false}
        onClick={() => setActiveStep(2)}
      >
        <div className="european-union-steps__content">
          <Text content="EUROPEAN_UNION_STEP2_CONTENT" />
          <Checkbox checked={isRegistered} onClick={() => onChangeRegistration(true)} value={""} content={<Text content="EUROPEAN_UNION_STEP2_OPT1_TITLE" noMargin />} />
          <Checkbox checked={isRegistered === false} onClick={() => onChangeRegistration(false)} value={""} content={<Text content="EUROPEAN_UNION_STEP2_OPT2_TITLE" noMargin />} />
        </div>
      </StepCollapsablePanel>
    );
  };

  const renderStep3 = () => {
    return (
      <StepCollapsablePanel
        number={3}
        title="EUROPEAN_UNION_STEP3_TITLE"
        isCollapsed={activeStep !== 3}
        isShowLockIcon={!isRegistered}
        isLocked={!isRegistered}
        hasHelpSection={false}
        onClick={() => setActiveStep(3)}
      >
        <div className="european-union-steps__content">
          <Text content="EUROPEAN_UNION_STEP3_CONTENT" />
          <TaxRegistrationNumber
            taxLabel={taxLabel}
            taxRegNumber={taxRegNumber}
            onChangeTaxLabel={(value) => onChangeTaxLabel(value)}
            onChangeTaxRegNumber={(value) => onChangeTaxRegNumber(value)}
            formFormat
          />
        </div>
      </StepCollapsablePanel>
    );
  };

  return (
    <div className="european-union-steps">
      {renderStep1()}
      {renderStep2()}
      {renderStep3()}

      <Button
          disabled={!enableContinue()}
          isFullWidth
          id="continue-add-tax-rate"
          title="MANUAL_TAX_CONFIG_CONTINUE_ADD_TAX_RATES"
          onClick={() => enableContinue() && onClickButton()}
        />
    </div>
  );
};

export default EuropeanUnionRegistrationSteps;
