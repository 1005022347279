import API from "../utils/API";
import { makeActionCreator } from "../utils/reduxUtils";

export const FETCH_CUSTOMER_SUBSCRIPTIONS_REQUEST = "FETCH_CUSTOMER_SUBSCRIPTIONS_REQUEST";
export const FETCH_CUSTOMER_SUBSCRIPTIONS_SUCCESS = "FETCH_CUSTOMER_SUBSCRIPTIONS_SUCCESS";
export const FETCH_CUSTOMER_SUBSCRIPTIONS_FAILURE = "FETCH_CUSTOMER_SUBSCRIPTIONS_FAILURE";

export const RESET_CUSTOMER_SUBSCRIPTIONS = "RESET_CUSTOMER_SUBSCRIPTIONS";
export const resetCutomerSubscriptions = makeActionCreator(RESET_CUSTOMER_SUBSCRIPTIONS);

export const fetchCustomerSubscriptions = function (companyDomain: string, customerUniqueId: string) {
  return {
    types: [FETCH_CUSTOMER_SUBSCRIPTIONS_REQUEST, FETCH_CUSTOMER_SUBSCRIPTIONS_SUCCESS, FETCH_CUSTOMER_SUBSCRIPTIONS_FAILURE],
    callAPI: () => API.getCustomerSubscriptions(companyDomain, customerUniqueId)
  }
}