import { BillsbyAction } from "../models/BillsbyAction";
import { ICompanyWebhooks } from "../models/CompanyWebhooks";
import { AppState } from "..";
import { SET_WEBHOOKS_DETAILS_FIELD, FETCH_COMPANY_WEBHOOK_SETUP_SUCCESS, RESET_WEBHOOKS, SET_WEBHOOKS_IS_COLLAPSED_WEBHOOKS_RESPOND } from "../actions/companyWebhooksActions";

export interface ICompanyWebhooksReducerState extends ICompanyWebhooks {
  isCollapsedRespondingToWebhooks: boolean
}

export const initialState: ICompanyWebhooksReducerState = {
  url: "",
  secret: "",
  isCollapsedRespondingToWebhooks: true
}

export default function companyWebhooksReducer(state: ICompanyWebhooksReducerState = initialState, action: BillsbyAction, store: AppState) {
  switch(action.type) {

    case SET_WEBHOOKS_DETAILS_FIELD: {
      return { ...state, [action.fieldName]: action.fieldValue }
    }

    case SET_WEBHOOKS_IS_COLLAPSED_WEBHOOKS_RESPOND: {
      return { ...state, isCollapsedRespondingToWebhooks: !state.isCollapsedRespondingToWebhooks }
    }

    case FETCH_COMPANY_WEBHOOK_SETUP_SUCCESS: {
      return {
        ...state,
        url: action.response.targetUrl,
        secret: action.response.secret,
      }
    }

    case RESET_WEBHOOKS: {
      return initialState
    }

    default:
      return state
  }
}