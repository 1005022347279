import React from "react";
import "./DiscountAndCouponsWelcome.scss";
import Panel from "../ui/panel/Panel";
import Text from "../ui/text/Text";
import Button from "../ui/button/Button";
import history from "../../utils/history";


export const DiscountAndCouponsWelcome: React.FC = () => {
  return (
    <div className="discount-and-coupons">
     <Panel className="discount-and-coupons__welcome-panel" title="DISCOUNT_COUPON_WELCOME_TITLE">
      <Text content="DISCOUNT_COUPON_WELCOME_TEXT_1" />
      <Text content="DISCOUNT_COUPON_WELCOME_TEXT_2" />
      <Text content="DISCOUNT_COUPON_WELCOME_TEXT_3" />
      <Button
            className="discount-and-coupons__welcome-panel__btn"
            id="create-allowance"
            title="DISCOUNT_COUPON_WELCOME_BUTTON"
            buttonType="general"
            icon="fal fa-plus-circle"
            onClick={() => history.push("/discount-and-coupons/create")}
          />
     </Panel>
    </div>
  )
}

export default DiscountAndCouponsWelcome;