import React from "react";
import "./CircleIcon.scss";

interface ICircleIcon {
  title?: string,
  nr?: number
  icon?: string,
  className?: string,
  style?: any,
  onClick?: () => void
}

const CircleIcon: React.FC<ICircleIcon> = React.memo(({ title, nr, icon, className = "", style = {}, onClick = () => { } }) => {
  return (
    <div title={title} className={`circle-icon ${className}`} style={style} onClick={onClick}>
      {
        icon
          ? <i className={`${icon}`} />
          : nr
      }
    </div>
  )
})

export default CircleIcon;