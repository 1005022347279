import { BillsbyAction } from "../models/BillsbyAction";
import { AppState } from "..";
import { IPaymentGatewayElement, IExistingPaymentGateway, PaymentGatewaysType } from "../models/Configuration";
import { SET_PAYMENT_GATEWAYS_FIELD, FETCH_PAYMENT_GATEWAYS_SUCCESS, CREATE_PAYMENT_GATEWAY_REQUEST, CREATE_PAYMENT_GATEWAY_SUCCESS, CREATE_PAYMENT_GATEWAY_FAILURE, RESET_PAYMENT_GATEWAYS_STATE, CANCEL_LAST_PAYMENT_GATEWAY, FETCH_PAYMENT_GATEWAYS_REQUEST, FETCH_PAYMENT_GATEWAYS_FAILURE, CREATE_STRIPE_PAYMENT_GATEWAY_SUCCESS, CREATE_STRIPE_PAYMENT_GATEWAY_FAILURE, CREATE_STRIPE_PAYMENT_GATEWAY_REQUEST, SAVE_PAYMENT_GATEWAY_STATE, RESTORE_PAYMENT_GATEWAY_STATE } from "../actions/paymentGatewaysActions";
import queryString from "query-string";
import stripeLogo from "../images/gateways/stripe.png";
import braintreeLogo from "../images/gateways/braintree.png";
import authorizeLogo from "../images/gateways/authorize.png";
import checkoutLogo from "../images/gateways/checkout.png";
import adyenLogo from "../images/gateways/adyen.png";
import ixopayLogo from "../images/gateways/ixopay.png";
import nmiLogo from "../images/gateways/NMI.png";
import paymentCloudLogo from "../images/gateways/paymentCloud.png";
import cloverConnectLogo from "../images/gateways/cloverConnect.svg";
import { CreateStripeIntentGatewayResponse } from "../utils/grpc/generated/Billsby.Protos/billing/private/paymentgateway/payment_gateway_pb";
import { isValidJSON } from "../utils/commonUtils";

export interface IPaymentGatewaysReducer {
  selectedPaymentGateway?: IPaymentGatewayElement,
  isCreatingGateway: boolean,
  paymentGateways?: Array<IPaymentGatewayElement>,
  paymentGatewaysLogos: { [key in PaymentGatewaysType]: string },
  paymentGatewaysTypes: { [key in PaymentGatewaysType]: string },
  paymentGatewaysDropdown?: Array<{ label: string, value: IPaymentGatewayElement }>
  createdPaymentGatewayId?: number,
  linkFromSca: boolean;
  isScaActivated: boolean;
  isFetchingPaymentGateways: boolean;
  replacedPaymentGateway?: IPaymentGatewayElement;
}

export const initialState: IPaymentGatewaysReducer = {
  selectedPaymentGateway: undefined,
  isCreatingGateway: false,
  paymentGateways: undefined,
  linkFromSca: false,
  isScaActivated: false,
  paymentGatewaysLogos: {
    [PaymentGatewaysType.STRIPE]: stripeLogo,
    [PaymentGatewaysType.TEST]: stripeLogo,
    [PaymentGatewaysType.ADYEN]: adyenLogo,
    [PaymentGatewaysType.AUTHORIZE]: authorizeLogo,
    [PaymentGatewaysType.BRAINTREE]: braintreeLogo,
    [PaymentGatewaysType.CHECKOUT]: checkoutLogo,
    [PaymentGatewaysType.IXOPAY]: ixopayLogo,
    [PaymentGatewaysType.NMI]: nmiLogo,
    [PaymentGatewaysType.PAYMENTCLOUD]: paymentCloudLogo,
    [PaymentGatewaysType.CLOVERCONNECT]: cloverConnectLogo,
    [PaymentGatewaysType.STRIPEINTENT]: stripeLogo,
  },
  paymentGatewaysTypes: {
    [PaymentGatewaysType.STRIPE]: "PAYMENT_GATEWAYS_STRIPE_TITLE",
    [PaymentGatewaysType.TEST]: "PAYMENT_GATEWAYS_STRIPE_TITLE",
    [PaymentGatewaysType.ADYEN]: "PAYMENT_GATEWAYS_ADYEN_TITLE",
    [PaymentGatewaysType.AUTHORIZE]: "PAYMENT_GATEWAYS_AUTHORIZE_TITLE",
    [PaymentGatewaysType.BRAINTREE]: "PAYMENT_GATEWAYS_BRAINTREE_TITLE",
    [PaymentGatewaysType.CHECKOUT]: "PAYMENT_GATEWAYS_CHECKOUT_TITLE",
    [PaymentGatewaysType.IXOPAY]: "PAYMENT_GATEWAYS_IXOPAY_TITLE",
    [PaymentGatewaysType.NMI]: "PAYMENT_GATEWAYS_NMI_TITLE",
    [PaymentGatewaysType.PAYMENTCLOUD]: "PAYMENT_GATEWAYS_PAYMENTCLOUD_TITLE",
    [PaymentGatewaysType.CLOVERCONNECT]: "PAYMENT_GATEWAYS_CLOVER_CONNECT_TITLE",
    [PaymentGatewaysType.STRIPEINTENT]: "PAYMENT_GATEWAYS_STRIPE_TITLE",
  },
  paymentGatewaysDropdown: undefined,
  isFetchingPaymentGateways: false
}

const helpTitle = {
  [PaymentGatewaysType.STRIPE]: "PAYMENT_GATEWAYS_STRIPE_HELP_TITLE",
  [PaymentGatewaysType.TEST]: "PAYMENT_GATEWAYS_STRIPE_HELP_TITLE",
  [PaymentGatewaysType.ADYEN]: "PAYMENT_GATEWAYS_ADDED_ADYEN_DISPLAYNAME_HELP_TITLE",
  [PaymentGatewaysType.AUTHORIZE]: "PAYMENT_GATEWAYS_ADDED_DISPLAYNAME_HELP_TITLE",
  [PaymentGatewaysType.BRAINTREE]: "PAYMENT_GATEWAYS_ADDED_BRAINTREE_DISPLAYNAME_HELP_TITLE",
  [PaymentGatewaysType.CHECKOUT]: "PAYMENT_GATEWAYS_ADDED_DISPLAYNAME_HELP_TITLE",
  [PaymentGatewaysType.IXOPAY]: "PAYMENT_GATEWAYS_ADDED_IXOPAY_DISPLAYNAME_HELP_TITLE",
  [PaymentGatewaysType.NMI]: "PAYMENT_GATEWAYS_ADDED_NMI_DISPLAYNAME_HELP_TITLE",
  [PaymentGatewaysType.PAYMENTCLOUD]: "PAYMENT_GATEWAYS_PAYMENTCLOUD_HELP_TITLE",
  [PaymentGatewaysType.CLOVERCONNECT]: "PAYMENT_GATEWAYS_PAYMENTCLOUD_HELP_TITLE",
  [PaymentGatewaysType.STRIPEINTENT]: "PAYMENT_GATEWAYS_STRIPE_HELP_TITLE",
}

const helpContent = {
  [PaymentGatewaysType.STRIPE]: ["PAYMENT_GATEWAYS_ADDED_DISPLAYNAME_HELP_CONTENT"],
  [PaymentGatewaysType.TEST]: ["PAYMENT_GATEWAYS_ADDED_DISPLAYNAME_HELP_CONTENT"],
  [PaymentGatewaysType.ADYEN]: ["PAYMENT_GATEWAYS_ADDED_ADYEN_HELP_CONTENT_1", "PAYMENT_GATEWAYS_ADDED_ADYEN_HELP_CONTENT_2"],
  [PaymentGatewaysType.AUTHORIZE]: ["PAYMENT_GATEWAYS_ADDED_DISPLAYNAME_HELP_CONTENT"],
  [PaymentGatewaysType.BRAINTREE]: ["PAYMENT_GATEWAYS_ADDED_BRAINTREE_HELP_CONTENT_1", "PAYMENT_GATEWAYS_ADDED_BRAINTREE_HELP_CONTENT_2"],
  [PaymentGatewaysType.CHECKOUT]: ["PAYMENT_GATEWAYS_ADDED_DISPLAYNAME_HELP_CONTENT"],
  [PaymentGatewaysType.IXOPAY]: ["PAYMENT_GATEWAYS_ADDED_IXOPAY_DISPLAYNAME_HELP_CONTENT_1"],
  [PaymentGatewaysType.NMI]: ["PAYMENT_GATEWAYS_ADDED_NMI_DISPLAYNAME_HELP_CONTENT_1"],
  [PaymentGatewaysType.PAYMENTCLOUD]: ["PAYMENT_GATEWAYS_PAYMENTCLOUD_HELP_CONTENT_1", "PAYMENT_GATEWAYS_P.0AYMENTCLOUD_HELP_CONTENT_2"],
  [PaymentGatewaysType.CLOVERCONNECT]: ["PAYMENT_GATEWAYS_PAYMENTCLOUD_HELP_CONTENT_1", "PAYMENT_GATEWAYS_P.0AYMENTCLOUD_HELP_CONTENT_2"],
  [PaymentGatewaysType.STRIPEINTENT]: ["PAYMENT_GATEWAYS_ADDED_DISPLAYNAME_HELP_CONTENT"]
}

export default function paymentGatewaysReducer(state: IPaymentGatewaysReducer = initialState, action: BillsbyAction, store: AppState) {
  switch (action.type) {
    case SET_PAYMENT_GATEWAYS_FIELD:
      return { ...state, [action.field]: action.payload }
    case FETCH_PAYMENT_GATEWAYS_REQUEST:
      return { ...state, isFetchingPaymentGateways: true }
    case FETCH_PAYMENT_GATEWAYS_FAILURE:
      return { ...state, isFetchingPaymentGateways: false }
    case FETCH_PAYMENT_GATEWAYS_SUCCESS: {
      const existingPaymentGateways: Array<IExistingPaymentGateway> = action.response && action.response.length ? action.response : [];
      const formattedPaymentGateways: Array<IPaymentGatewayElement> = existingPaymentGateways.map(gateway => ({
        title: gateway.displayName,
        type: gateway.gatewayType,
        data: gateway.data,
        shouldTranslateTitle: false,
        subTitle: state.paymentGatewaysTypes[gateway.gatewayType],
        shouldTranslateSubtitle: true,
        paymentGatewayId: gateway.paymentGatewayId,
        logo: state.paymentGatewaysLogos[gateway.gatewayType],
        hasMappedCurrency: gateway.hasMappedCurrencies,
        isLogoFontAwesome: false,
        helpTitle: helpTitle[gateway.gatewayType],
        helpContent: helpContent[gateway.gatewayType],
        isScaSupported: gateway.isScaSupported,
        isScaActivated: gateway.isScaActivated,
        minChargeAmountFormmated: gateway.minChargeAmountFormmated,
        isAchSupported: gateway.isAchSupported,
        isReplaceable: gateway.isReplaceable
      })).sort((p1, p2) => p1.paymentGatewayId < p2.paymentGatewayId ? -1 : 1);

      const isStripeRedirect = !!queryString.parse(window.location.search).code;
      if (isStripeRedirect) {
        formattedPaymentGateways.push({
          title: "",
          type: PaymentGatewaysType.STRIPE,
          subTitle: "PAYMENT_GATEWAYS_STRIPE_TITLE",
          logo: state.paymentGatewaysLogos[PaymentGatewaysType.STRIPE],
          isLogoFontAwesome: false,
          helpTitle: "PAYMENT_GATEWAYS_ADDED_DISPLAYNAME_HELP_TITLE",
          helpContent: ["PAYMENT_GATEWAYS_ADDED_DISPLAYNAME_HELP_CONTENT"],
          mustActivate: true,
          hasMappedCurrency: false,
          data: null,
          isScaSupported: true,
          isScaActivated: false,
          isAchSupported: false,
          isReplaceable: true,
          minChargeAmountFormmated: ""
        })
      }
      const isScaActivated = formattedPaymentGateways.filter(p => p.isScaActivated).length > 0;
      const paymentGatewaysDropdown = formattedPaymentGateways.map(gateway => ({ label: `${gateway.title}`, value: gateway }))
      return { ...state, paymentGateways: formattedPaymentGateways, paymentGatewaysDropdown, isScaActivated, isFetchingPaymentGateways: false }
    }
    case CREATE_PAYMENT_GATEWAY_REQUEST:
    case CREATE_STRIPE_PAYMENT_GATEWAY_REQUEST:
      return { ...state, isCreatingGateway: true }
    case CREATE_STRIPE_PAYMENT_GATEWAY_SUCCESS:
      const response = action.response as CreateStripeIntentGatewayResponse;
      return { ...state, isCreatingGateway: false, createdPaymentGatewayId: response ? response.getPaymentGatewayId() : undefined }
    case CREATE_PAYMENT_GATEWAY_SUCCESS:
      return { ...state, isCreatingGateway: false, createdPaymentGatewayId: action.response ? action.response.paymentGatewayId : undefined }
    case CREATE_PAYMENT_GATEWAY_FAILURE:
    case CREATE_STRIPE_PAYMENT_GATEWAY_FAILURE:
      return { ...state, isCreatingGateway: false }
    case RESET_PAYMENT_GATEWAYS_STATE:
      sessionStorage.setItem("paymentGatewayState", "");
      return { ...initialState }
    case CANCEL_LAST_PAYMENT_GATEWAY:
      let newGateways;
      if (state.paymentGateways) {
       newGateways = state.paymentGateways.filter(p => !p.mustActivate)
      }
      return { ...state, paymentGateways: newGateways }

    case SAVE_PAYMENT_GATEWAY_STATE:
      sessionStorage.setItem("paymentGatewayState", JSON.stringify(state));
      return state;
    case RESTORE_PAYMENT_GATEWAY_STATE:
      // we use this mainly for stripe because we need to keep the state in session after the redirect from stripe
      const newState = isValidJSON(sessionStorage["paymentGatewayState"]) ? JSON.parse(sessionStorage["paymentGatewayState"]) : { ...state }
      return newState;

    default:
      return state;
  }
}