import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../../..";
import StepCollapsablePanel from "../../ui/step-collapsable-panel/StepCollapsablePanel";
import Button from "../../ui/button/Button";
import Text from "../../ui/text/Text";
import Pagination from "../../ui/pagination/Pagination";
import Panel from "../../ui/panel/Panel";
import { setDiscountStepsCollapsed, setCreateDiscountReducerFields, fetchCurrenciesOfPlansDiscounts } from "../../../actions/discount-coupon/createDiscountActions";
import { GetPlanCurrenciesResponse, DiscountType } from "../../../utils/grpc/generated/Billsby.Protos/core/private/discounts/discounts_pb";
import { setShowSelectPlanModal, setSelectedAddOnsPlansPage, removeAddOnsSelectedPlan } from "../../../actions/addOnsActions";
import Checkbox from "../../ui/checkbox/Checkbox";
import PlanSelector from "../../plan-selector/PlanSelector";

interface ISelectPlanStep {
  discountId: number | null
}

const SelectPlanStep: React.FC<ISelectPlanStep> = ({ discountId }) => {
  const auth = useSelector((state: AppState) => state.auth);
  const createDiscount = useSelector((state: AppState) => state.createDiscountReducer);
  const selectAddOnPlan = useSelector((state: AppState) => state.selectAddOnPlanReducer);
  const dispatch = useDispatch<Function>();

  const { stageStatuses, stageErrors, stageCollapsed, isAllPlans, hasSelectedDiscountType, discountType, isUpdate, monetaries, selectedCurrency } = createDiscount;
  const { showSelectPlanModal, numberOfpages, selectedPlans, selectedPlansDisplay, companyAllPlans } = selectAddOnPlan;
  
  useEffect(() => {
    const fetchCurrencies = async () => {
      if(!hasSelectedDiscountType || discountType !== DiscountType.MONETARY || (isAllPlans && !companyAllPlans.length) || (!isAllPlans && !selectedPlans.length)) {
        return;
      }
      dispatch(setCreateDiscountReducerFields("currenciesOfPlans", undefined));
      const { response } = await dispatch(fetchCurrenciesOfPlansDiscounts(auth.currentCompanyDomain, isAllPlans ? companyAllPlans.map(i => i.planId) : selectedPlans.map(p => p.planId))) as { response: GetPlanCurrenciesResponse };
      const newMonetaries = response.getCurrenciesList().map(c => c.getIso3Code());
      dispatch(setCreateDiscountReducerFields("monetaries", monetaries.filter(m => newMonetaries.indexOf(m.currencyCode) !== -1)));
      if (!selectedCurrency || selectedCurrency && newMonetaries.indexOf(selectedCurrency?.getIso3Code()) < 0) {
        dispatch(setCreateDiscountReducerFields("selectedCurrency", response.getCurrenciesList()[0]));
      }
    }

    fetchCurrencies();
  }, [isAllPlans, discountType, selectedPlans, companyAllPlans, hasSelectedDiscountType]);

  return (
    <>
      {showSelectPlanModal && <PlanSelector />}
      {!showSelectPlanModal && <StepCollapsablePanel
        title={isUpdate ? "EDIT_DISCOUNT_FORM_HEADER_SELECT_PLANS" : "CREATE_DISCOUNT_FORM_HEADER_SELECT_PLANS"}
        number={2}
        isCompleted={selectedPlans.length > 0 || hasSelectedDiscountType}
        isLocked={stageErrors[0] || !stageStatuses[0]}
        isShowTitleIcon={!discountId}
        isCollapsed={!discountId ? stageCollapsed[1] : false}
        hasError={stageErrors[1]}
        hasHelpSection={false}
        className="create-discount__select-plans"
        onClick={() => !discountId ? dispatch(setDiscountStepsCollapsed(1)) : {}}
        isShowLockIcon={stageErrors[0] || !stageStatuses[0]}
      >
        <div className="create-discount__select-plans__panel">
          <Text content="CREATE_DISCOUNT_FORM_HEADER_SELECT_PLANS_Text" />
          <Checkbox 
            checked={isAllPlans} 
            onClick={() => {
              dispatch(setCreateDiscountReducerFields("isAllPlans", true));
            }} 
            value='auto-calculation' 
            content={<Text content='CREATE_DISCOUNT_FORM_SELECT_PLANS_ALL_PLANS' noMargin /> } 
          />
          <Checkbox 
            checked={!isAllPlans} 
            onClick={() => dispatch(setCreateDiscountReducerFields("isAllPlans", false))} 
            value='auto-calculation' 
            content={<Text content='CREATE_DISCOUNT_FORM_SELECT_PLANS_SPECIFIC_PLANS' noMargin />
          } />
          {!isAllPlans && <><div className="create-discount__select_discount_plan">
            <Button
              className="create-discount__select_discount_plan__btn"
              id="create-discount-select-plan-btn"
              title="CREATE_DISCOUNT_FORM_HEADER_SELECT_PLANS_BUTTON_TEXT"
              buttonType="general"
              icon="fal fa-plus-circle"
              isFullWidth={true}
              onClick={() => dispatch(setShowSelectPlanModal(true))}
            />
            {numberOfpages > 0 &&
              <Pagination
                className="create-discount__select_discount_plan__pagination"
                pageCount={numberOfpages}
                isVisible={numberOfpages > 1}
                onPageChange={({ selected }) => dispatch(setSelectedAddOnsPlansPage(selected + 1))}
              />}
          </div>
            {selectedPlansDisplay.length > 0 && <div className="create-discount__select-plans__container">{
              selectedPlansDisplay.map(selectedPlan => <Panel className="create-discount__select-plans__list" key={`selected-add-on-${selectedPlan.planId}`}>
                <div className="create-discount__select-plans__listitem">
                  <Text content={selectedPlan.product} shouldTranslate={false} noMargin />
                </div>
                <div className="create-discount__select-plans__listitem create-discount__select-plans__listitem-plan">
                  <Text content={selectedPlan.plan} shouldTranslate={false} noMargin />
                </div>
                <div className="create-discount__select-plans__list__commands">
                  <span>
                    <i className="create-discount__select-plans__commands-delete far fa-trash-alt" onClick={() => dispatch(removeAddOnsSelectedPlan(selectedPlan.planId))} />
                  </span>
                </div>
              </Panel>)
            }</div>}</>}

        </div>
      </StepCollapsablePanel>}
    </>
  )
}

export default SelectPlanStep;
