import React from "react";
import Text from "../../ui/text/Text";
import counterpart from "counterpart";
import { FrequencyType as FrequencyTypeGrpc, Currency, AddonPriceModel } from "../../../utils/grpc/generated/Billsby.Protos/core/private/addons/addons_pb";
import Panel from "../../ui/panel/Panel";
import { getCycleFrequencyText } from "../../../utils/commonUtils";
import { PricingModelType, FrequencyType } from "../../../models/Product";
import Button from "../../ui/button/Button";
import { Int32Value } from "google-protobuf/google/protobuf/wrappers_pb";
import { AllowancePriceModel } from "../../../utils/grpc/generated/Billsby.Protos/core/private/allowances/allowances_pb";

interface IAddOnPriceModel {
  priceModel: AddonPriceModel | AllowancePriceModel;
  configurePriceModelCb: (priceModel: AddonPriceModel | AllowancePriceModel) => void;
  selectedCurrency: Currency;
  pricingModelType: PricingModelType;
  isTableRow?: boolean;
}

const AddOnPriceModelPanel: React.FC<IAddOnPriceModel> = React.memo(({ priceModel, configurePriceModelCb, selectedCurrency, pricingModelType, isTableRow = false }) => {
  /* mapping used because the proto refers to a different enum for the FrequencyType */
  const frequencyTypeMapping = {
    [FrequencyTypeGrpc.UNSPECIFIED_FT]: FrequencyType.Daily,
    [FrequencyTypeGrpc.DAILY]: FrequencyType.Daily,
    [FrequencyTypeGrpc.WEEKLY]: FrequencyType.Weekly,
    [FrequencyTypeGrpc.MONTHLY]: FrequencyType.Monthly,
    [FrequencyTypeGrpc.YEARLY]: FrequencyType.Yearly,
  };

  const priceWithSymbol = (price: Int32Value | number) => selectedCurrency.getSymbol().replace("_", typeof price === "number" ? price.toString() : price.getValue().toString());

  const isConfigured = (priceModel: AddonPriceModel | AllowancePriceModel) => {
    return !!priceModel.getPerUnitPriceFormatted() ||
      !!((priceModel as AddonPriceModel).getFlatFeePriceFormatted && (priceModel as AddonPriceModel).getFlatFeePriceFormatted())
      || priceModel.getTiersList().length > 0
      || !!((priceModel as AllowancePriceModel).getIncludedUnits && (priceModel as AllowancePriceModel).getIncludedUnits())
  };

  const getIncludedUnitsLabel = (priceModel: AddonPriceModel | AllowancePriceModel) => {
    if (pricingModelType === PricingModelType.Capped) {
      return `${(priceModel as AllowancePriceModel).getIncludedUnits()} ${counterpart("CREATE_ALLOWANCES_PRICE_MODELS_UNITS_LABEL")}`
    }
    else if ((priceModel as AllowancePriceModel).getIncludedUnits && (priceModel as AllowancePriceModel).getIncludedUnits()) {
      return `${(priceModel as AllowancePriceModel).getIncludedUnits()} ${counterpart("CREATE_ALLOWANCES_PRICE_MODELS_UNITS_LABEL")},
       ${counterpart("CREATE_ALLOWANCES_PRICE_MODELS_CAPPED_THEN")}`;
    }
    return "";
  }

  const getLabel = (priceModel: AddonPriceModel | AllowancePriceModel) => {
    if (pricingModelType === PricingModelType.Volume || pricingModelType === PricingModelType.Ranged
      || pricingModelType === PricingModelType.Tiered) {

      const maxEndUnit = 1000000;
      const unitTiers = priceModel.getTiersList();
      const numberOfTiers = unitTiers.length;
      const priceFirstTier = unitTiers[0].getPrice();
      const firstUnitRangeStart = unitTiers[0].getStart();
      const firstUnitRangeEnd = unitTiers[0].getFinish();
      const firstUnitRange = `${firstUnitRangeStart}-${firstUnitRangeEnd}`;
      const priceLastTier = unitTiers[numberOfTiers - 1].getPrice();
      const lastUnitRangeStart = unitTiers[numberOfTiers - 1].getStart();
      const lastUnitRangeEnd = unitTiers[numberOfTiers - 1].getFinish();
      const lastUnitRange = lastUnitRangeEnd === maxEndUnit ? `${lastUnitRangeStart} ${counterpart("CREATE_ADD_ONS_PRICE_MODELS_TIERED_PRICE_LIST_MAX")}` : `${lastUnitRangeStart}-${lastUnitRangeEnd}`;
      const textTemplate =
        numberOfTiers === 1
          ? firstUnitRangeEnd === maxEndUnit
            ? "CREATE_ADD_ONS_PRICE_MODELS_TIERED_PRICE_LIST_SINGLE_TIER_MAX"
            : "CREATE_ADD_ONS_PRICE_MODELS_TIERED_PRICE_LIST_SINGLE_TIER"
          : "CREATE_ADD_ONS_PRICE_MODELS_TIERED_PRICE_LIST";

      return (
        <Text
          content={`${getIncludedUnitsLabel(priceModel)} ${counterpart(textTemplate, {
            priceStart: `${priceWithSymbol(priceFirstTier)}`,
            firstUnitRange: firstUnitRange,
            priceEnd: `${priceWithSymbol(priceLastTier)}`,
            endUnitRange: lastUnitRange,
            priceModel: `(${pricingModelType.toString().toLowerCase()})`,
          })}`}
          shouldTranslate={false}
          noMargin
        />
      );
    } else if (pricingModelType === PricingModelType.FlatFee || pricingModelType === PricingModelType.PerUnit) {
      let price: number | Int32Value;
      if((priceModel as AddonPriceModel).getFlatFeePrice && (priceModel as AddonPriceModel).getFlatFeePrice()) {
        price =  (priceModel as AddonPriceModel).getFlatFeePrice() as Int32Value
      }
      else {
        if(priceModel.getPerUnitPrice() && (priceModel.getPerUnitPrice() as Int32Value).getValue) {
          //it's an addon
          price = (priceModel.getPerUnitPrice() as Int32Value) || new Int32Value().getValue()
        }
        else {
          //it's an allowance
          price = Number(priceModel.getPerUnitPrice() as string)
        }
      }
      return (
        <Text
          content={`${getIncludedUnitsLabel(priceModel)} 
          ${priceWithSymbol(price)}
             ${counterpart("CREATE_ADD_ONS_PRICE_MODELS_CONFIGURE_PERUNIT")}`}
          shouldTranslate={false}
          noMargin
        />
      );
    }
    else {
      // capped
      return (
        <Text
          content={`${getIncludedUnitsLabel(priceModel)} ${counterpart("CREATE_ALLOWANCES_PRICE_MODELS_CAPPED_LABEL")}`}
          shouldTranslate={false}
          noMargin
        />
      )
    }
  };

  if (!isConfigured(priceModel)) {
    if (isTableRow) {
      return (
        <tr className="step-addon-cost__list-notConfiguredEl">
          <td>
            <i className="fas fa-map-marker-exclamation" />
            <Text
              content={getCycleFrequencyText(priceModel.getFrequency(), frequencyTypeMapping[priceModel.getFrequencyType()], PricingModelType.FlatFee).capitalize()}
              shouldTranslate={false}
              noMargin
            />
            <Button 
              id="configure-cycle-id" 
              title="CREATE_ADD_ONS_PRICE_MODELS_CONFIGURE_BUTTON" 
              icon="fal fa-plus-circle" 
              buttonType="add" 
              onClick={() => configurePriceModelCb(priceModel)} 
            />
          </td>
        </tr>
      );
    }

    return (
      <Panel className="step-addon-cost__list-notConfiguredEl">
        <div>
          <i className="fas fa-map-marker-exclamation" />
          <Text
            content={getCycleFrequencyText(priceModel.getFrequency(), frequencyTypeMapping[priceModel.getFrequencyType()], PricingModelType.FlatFee).capitalize()}
            shouldTranslate={false}
            noMargin
          />
        </div>
        <Button id="configure-cycle-id" title="CREATE_ADD_ONS_PRICE_MODELS_CONFIGURE_BUTTON" icon="fas fa-plus-circle" buttonType="add" onClick={() => configurePriceModelCb(priceModel)} />
      </Panel>
    );
  }

  if (isTableRow) {
    return (
      <tr className="step-addon-cost__list-configuredEl">
        <td>
          <Text
            content={getCycleFrequencyText(priceModel.getFrequency(), frequencyTypeMapping[priceModel.getFrequencyType()], PricingModelType.FlatFee).capitalize()}
            shouldTranslate={false}
            noMargin
          />
          <div className="border" />
          {getLabel(priceModel)}
          <i className="fas fa-edit" onClick={() => configurePriceModelCb(priceModel)} />
        </td>
      </tr>
    );
  }

  return (
    <Panel className="step-addon-cost__list-configuredEl">
      <div>
        <Text content={getCycleFrequencyText(priceModel.getFrequency(), frequencyTypeMapping[priceModel.getFrequencyType()], PricingModelType.FlatFee).capitalize()} shouldTranslate={false} noMargin />
        <div className="border" />
        {getLabel(priceModel)}
      </div>
      <i className="fas fa-edit" onClick={() => configurePriceModelCb(priceModel)} />
    </Panel>
  );
});

export default AddOnPriceModelPanel;
