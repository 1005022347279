import React, { FormEvent, useState, useEffect } from "react";
import { Row, Col } from "react-grid-system";
import { useRouteMatch } from "react-router-dom";
import Panel from "../../../ui/panel/Panel";
import Text from "../../../ui/text/Text";
import Button from "../../../ui/button/Button";
import FormLabel from "../../../../components/ui/form-label/FormLabel";
import FormGroup from "../../../../components/ui/form-group/FormGroup";
import Checkbox from "../../../../components/ui/checkbox/Checkbox";
import Dropdown from "../../../../components/ui/dropdown/Dropdown";
import counterpart from "counterpart";
import { TaxCollection } from "../../../../models/Taxes";
import { useDispatch, useSelector } from "react-redux";
import "./TaxJarCompleteIntegration.scss";
import { AppState } from "../../../..";
import { fetchTaxConfig, resetCompanyTaxJarProductCategories, updateTaxJarConfig, setCurrentCountry } from "../../../../actions/companyTaxRatesActions";
import { UpsertTaxJarConfigRequest } from "../../../../utils/grpc/generated/Billsby.Protos/billing/private/company/taxation/taxation_pb";

interface ICategory {
  label: string,
  value: string,
}

interface IMatchProps {
  countryCode: string,
}

interface ITaxJarCompleteIntegrationProps {
  apiToken: string,
}

interface ITaxJarCompleteIntegrationState {
  hasError: boolean,
  isAutoFileEnabled: boolean,
  category: ICategory | null,
  isLoadingData: boolean,
  isLoading: boolean,
}

const TaxJarCompleteIntegration: React.FC<ITaxJarCompleteIntegrationProps> = ({ apiToken }) => {
  const authData = useSelector((state: AppState) => state.auth);
  const companyTaxRatesReducer = useSelector((state: AppState) => state.companyTaxRatesReducer);

  const dispatch = useDispatch<Function>();
  const match = useRouteMatch<IMatchProps>();

  const [state, setState] = useState<ITaxJarCompleteIntegrationState>({ hasError: false, isAutoFileEnabled: true, category: null, isLoadingData: false, isLoading: false });
  const { hasError, isAutoFileEnabled, category, isLoadingData, isLoading } = state;
  const { productCategories, currentCountry } = companyTaxRatesReducer;
  const { currentCompanyDomain, currentCompanyId } = authData;

  useEffect(() => {
    return () => dispatch(resetCompanyTaxJarProductCategories())
  }, [])

  const onSubmit = async (evt: FormEvent) => {
    evt.preventDefault();
    evt.stopPropagation();

    const params = match?.params;

    if (!category || !currentCompanyId) {
      return;
    }

    const data: UpsertTaxJarConfigRequest.AsObject = {
      companyDomain: currentCompanyDomain,
      countryIso3Code: params.countryCode,
      taxJarConfig: {
        apiToken,
        taxProductCategoryCode: category.value,
        isAutoFileEnabled,
        taxLabel: "",
        taxRegNumber: "",
        taxRegNumberRequirementType: TaxCollection.DO_NOT_COLLECT
      }
    }

    setState({ ...state, isLoading: true })

    try {
      await dispatch(updateTaxJarConfig(data));
      await dispatch(fetchTaxConfig(currentCompanyDomain));

      if(currentCountry) {
        dispatch(setCurrentCountry({ countryName: currentCountry.countryName, countryCode: params.countryCode  }))
      }

    } catch (err) {
      setState({ ...state, isLoading: false, hasError: true })
      console.log(err);
    }
  };

  if (isLoadingData || !productCategories) {
    return null;
  }

  return (
    <>
      <form className="taxjar-complete-integration" onSubmit={onSubmit}>
        <Panel className="taxjar-complete-integration__titlepanel" title="TAXJAR_COMPLETE_INTEGRATION_PANEL_TITLE">
          <Text className="taxjar-complete-integration__content" content="TAXJAR_COMPLETE_INTEGRATION_PANEL1_CONTENT" noMargin />

          <Row align="center">
            <Col md={2}>
              <FormLabel target="product-category" content="TAXJAR_COMPLETE_INTEGRATION_PRODUCT_CATEGORY" />
            </Col>
            <Col md={10}>
              <Dropdown
                id="product-category"
                onChange={(category: ICategory) => {
                  setState({...state, category})
                }}
                placeholder={counterpart("TAXJAR_COMPLETE_INTEGRATION_DIGITAL_SERVICES")}
                value={category}
                options={productCategories}
              />
            </Col>
          </Row>
        </Panel>

        <Panel>
          <Text content="TAXJAR_COMPLETE_INTEGRATION_PANEL2_CONTENT" />
          <FormGroup>
            <Checkbox
              checked={isAutoFileEnabled}
              onClick={() => setState({...state, isAutoFileEnabled: true})}
              value="File and calculate tax"
              content={<Text content="TAXJAR_COMPLETE_INTEGRATION_CALCULATE_OPT1" noMargin />}
            />
            <Checkbox
              checked={!isAutoFileEnabled}
              onClick={() => setState({...state, isAutoFileEnabled: false})}
              value="Calculate only"
              content={<Text content="TAXJAR_COMPLETE_INTEGRATION_CALCULATE_OPT2" noMargin />}
            />
          </FormGroup>
        </Panel>

        <Button
          id="taxjar-integration"
          type="submit"
          disabled={!category}
          buttonType={"general"}
          isLoading={isLoading && !hasError}
          title="TAXJAR_COMPLETE_INTEGRATION"
          isFullWidth
        />
      </form>
    </>
  );
};

export default TaxJarCompleteIntegration;
