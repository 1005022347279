import React from "react";
import Button from "../../../../../ui/button/Button";
import Panel from "../../../../../ui/panel/Panel";
import Text from "../../../../../ui/text/Text";
import TextArea from "../../../../../ui/textarea/Textarea";
import "./EmailTextArea.scss";

interface Props {
  title: string,
  description: string,
  value: string,
  buttonText: string,
  id: string,
  noMargin?: boolean,
  className?: string,
  isLoading?: boolean,
  onChange: (evt: any) => any,
  onSubmit: () => void
}

export const EmailTextArea: React.FC<Props> = ({ title = "", description = "", value = "", buttonText = "", className = "", noMargin = false, isLoading = false, id = "", onSubmit = () => { }, onChange = () => { } }) => {

  const sanitizeInput = (evt: any) => {
    var inputValue = evt.target.value;
    var regex = /<(?!a\s*\/?)[^?!a>]+>/g;
    inputValue = inputValue.replace(regex, "");
    evt.target.value = inputValue;
    onChange(evt);    
  }

  return (
    <Panel title={title} shouldTranslate={false} noMargin={noMargin} className={className}>
      <Text content={description} shouldTranslate={false} className="email-textarea-input-description" />
      <TextArea id={`textarea-${id}`} value={value} rows={3} onChange={(evt: any) => {sanitizeInput(evt)}} />
      <Button className="email-textarea-input-submit"
        id={`btn-${id}`}
        title={buttonText}
        shouldTranslate={false}
        buttonType="add"
        onClick={onSubmit}
        isLoading={isLoading}
      />
    </Panel>
  )
}

export default EmailTextArea;
