import { BillsbyAction } from "../models/BillsbyAction";
import { AppState } from "..";
import {
  FETCH_CHECKOUT_MGMT_SUCCESS,
  FETCH_SELF_SERVICE_MGMT_SUCCESS,
  SET_CHECKOUT_ACCT_MGMT,
  UPDATE_CHECKOUT_MGMT_SUCCESS,
  UPDATE_SELF_SERVICE_MGMT_SUCCESS,
  RESET_SELF_SERVICE_MGMT_MENU,
  RESET_SELF_SERVICE_MGMT_TERMINOLOGY,
  RESET_CHECKOUT_ACCT_MGMT,
  RESET_CHECKOUT_MGMT_TERMINOLOGY,
  RESET_CHECKOUT_MGMT_INCLUDED_FEATURES,
  RESET_CHECKOUT_MGMT_TOF_PRIVACY_POLICY,
  RESET_CHECKOUT_MGMT_REDIRECT_URL,
  RESET_CHECKOUT_MGMT_JS_INTEGRATION,
  RESET_CHECKOUT_ACCT_MGMT_SHOW_HIDE_FEATURES,
  RESET_CHECKOUT_ACCT_MGMT_CONFIRMATION_SCREEN,
  UPDATE_CHECK_OUT_MANAGEMENT_DATA,
  RESET_CHECKOUT_MGMT_GA_CODE
} from "../actions/checkoutAccountManagementActions";
import { ICheckoutManagement, ISelfServiceManagement } from "../models/CheckoutAccountManagement";

export interface ICheckoutAccountManagementReducerState {
  checkoutManagement: ICheckoutManagement
  selfServiceManagement: ISelfServiceManagement
}

export let checkoutMgmtInitialState: ICheckoutManagement = {
  terminologyProductSingular: "",
  terminologyProductPlural: "",
  terminologyPlanSingular: "",
  terminologyPlanPlural: "",
  terminologyUnitSingular: "",
  terminologyUnitPlural: "",
  terminologyAddonSingular: "",
  terminologyAddonPlural: "",
  terminologyAllowanceSingular: "",
  terminologyAllowancePlural: "",
  terminologySetupFeeSingular: "",
  terminologySetupFeePlural: "",
  terminologySubscriptionSingular: "",
  terminologySubscriptionPlural: "",
  terminologyDiscountSingular: "",
  terminologyDiscountPlural: "",
  terminologyCouponSingular: "",
  terminologyCouponPlural: "",
  isShoppingCartHidden: false,
  termsOfUseUrl: "",
  privacyPolicyUrl: "",
  redirectUrl: "",
  trackingCodeStart: "",
  trackingCodeEnd: "",
  confirmationButtonText: "",
  confirmationCopy: "",
  confirmationHeading: "",
  confirmationSubheading: "",
  isAmexCardLogoVisible: true,
  isApplyCouponsVisible: true,
  isDinersCardLogoVisible: false,
  isHowWasThisCalculatedVisible: true,
  isJCBCardLogoVisible: false,
  isMasterCardLogoVisible: true,
  isProgressIndicatorVisible: true,
  isVisaCardLogoVisible: true,
  isWixCompatibilityModeVisible: false,
  googleAnalyticsCode: ""
}

export let selfServiceMgmtInitialState: ISelfServiceManagement = {
  terminologySubscriptionSingular: "",
  terminologySubscriptionPlural: "",
  isViewChangePlanVisible: false,
  isChangePersonalDetailsVisible: false,
  isChangeAddressVisible: false,
  isUpdatePaymentVisible: false,
  isManageAllowancesVisible: false,
  isManageAddonsVisible: false,
  isViewBillingHistoryVisible: false,
  isCancelPlanVisible: false
}

export const initialState: ICheckoutAccountManagementReducerState = {
  checkoutManagement: checkoutMgmtInitialState,
  selfServiceManagement: selfServiceMgmtInitialState,
}

export default function checkoutAccountManagementReducer(state: ICheckoutAccountManagementReducerState = initialState, action: BillsbyAction, store: AppState) {
  if(!checkoutMgmtInitialState || !selfServiceMgmtInitialState ) {
    return { ...initialState }
  }
  
  switch (action.type) {
    case FETCH_CHECKOUT_MGMT_SUCCESS: {
      checkoutMgmtInitialState = action.response;
      return { ...state, checkoutManagement: checkoutMgmtInitialState }
    }

    case UPDATE_CHECKOUT_MGMT_SUCCESS: {
      checkoutMgmtInitialState = action.response;
      return state;
    }

    case FETCH_SELF_SERVICE_MGMT_SUCCESS: {
      selfServiceMgmtInitialState = action.response;
      return { ...state, selfServiceManagement: selfServiceMgmtInitialState }
    }

    case UPDATE_SELF_SERVICE_MGMT_SUCCESS: {
      selfServiceMgmtInitialState = action.response;
      return state;
    }

    case SET_CHECKOUT_ACCT_MGMT: {
      return { ...state, [action.fieldName]: action.fieldValue }
    }

    case RESET_CHECKOUT_MGMT_TERMINOLOGY: {
      const { terminologyPlanPlural, terminologyPlanSingular, terminologyProductPlural, terminologyProductSingular, terminologyUnitPlural, terminologyUnitSingular,
       terminologyAddonPlural, terminologyAddonSingular, terminologyAllowancePlural, terminologyAllowanceSingular, terminologySetupFeePlural, terminologySetupFeeSingular,
      terminologySubscriptionPlural, terminologySubscriptionSingular, terminologyCouponPlural, terminologyCouponSingular, terminologyDiscountPlural, terminologyDiscountSingular} = checkoutMgmtInitialState;
      return {
        ...state, 
        checkoutManagement: {
          ...state.checkoutManagement,
          terminologyPlanPlural,
          terminologyPlanSingular,
          terminologyProductPlural,
          terminologyProductSingular,
          terminologyUnitPlural,
          terminologyUnitSingular,
          terminologyAddonPlural,
          terminologyAddonSingular,
          terminologyAllowancePlural,
          terminologyAllowanceSingular,
          terminologySetupFeePlural,
          terminologySetupFeeSingular,
          terminologySubscriptionPlural,
          terminologySubscriptionSingular,
          terminologyCouponPlural,
          terminologyCouponSingular,
          terminologyDiscountPlural,
          terminologyDiscountSingular
        }
      }
    }

    case RESET_CHECKOUT_MGMT_INCLUDED_FEATURES: {
      const { isShoppingCartHidden } = checkoutMgmtInitialState;
      return {
        ...state,
        checkoutManagement: {
          ...state.checkoutManagement,
          isShoppingCartHidden
        }
      }
    }

    case RESET_CHECKOUT_MGMT_TOF_PRIVACY_POLICY: {
      const { privacyPolicyUrl, termsOfUseUrl } = checkoutMgmtInitialState;
      return {
        ...state,
        checkoutManagement: {
          ...state.checkoutManagement,
          termsOfUseUrl,
          privacyPolicyUrl
        }
      }
    }

    case RESET_CHECKOUT_MGMT_REDIRECT_URL: {
      const { redirectUrl } = checkoutMgmtInitialState;
      return {
        ...state,
        checkoutManagement: {
          ...state.checkoutManagement,
          redirectUrl
        }
      }
    }

    case RESET_CHECKOUT_MGMT_JS_INTEGRATION: {
      const { trackingCodeEnd, trackingCodeStart } = checkoutMgmtInitialState;
      return {
        ...state,
        checkoutManagement: {
          ...state.checkoutManagement,
          trackingCodeStart,
          trackingCodeEnd
        }
      }
    }

    case RESET_CHECKOUT_MGMT_GA_CODE: {
      const { googleAnalyticsCode } = checkoutMgmtInitialState;
      return {
        ...state,
        checkoutManagement: {
          ...state.checkoutManagement,
          googleAnalyticsCode
        }
      }
    }


    case RESET_SELF_SERVICE_MGMT_TERMINOLOGY: {
      const { terminologySubscriptionSingular, terminologySubscriptionPlural } = selfServiceMgmtInitialState;

      return {
        ...state, selfServiceManagement: {
          ...state.selfServiceManagement,
          terminologySubscriptionSingular,
          terminologySubscriptionPlural
        }
      }
    }
    

    case RESET_SELF_SERVICE_MGMT_MENU: {
      const { isViewChangePlanVisible, isChangePersonalDetailsVisible, isChangeAddressVisible, isUpdatePaymentVisible, isManageAllowancesVisible, isManageAddonsVisible, isViewBillingHistoryVisible, isCancelPlanVisible } = selfServiceMgmtInitialState;
      return {
        ...state, selfServiceManagement: {
          ...state.selfServiceManagement,
          isViewChangePlanVisible,
          isChangePersonalDetailsVisible,
          isChangeAddressVisible,
          isUpdatePaymentVisible,
          isManageAllowancesVisible,
          isManageAddonsVisible,
          isViewBillingHistoryVisible,
          isCancelPlanVisible
        }
      }
    }
    case RESET_CHECKOUT_ACCT_MGMT_SHOW_HIDE_FEATURES: {
      const { isProgressIndicatorVisible, isShoppingCartHidden, isVisaCardLogoVisible, isJCBCardLogoVisible, isMasterCardLogoVisible, isDinersCardLogoVisible,
        isAmexCardLogoVisible, isHowWasThisCalculatedVisible, isApplyCouponsVisible, isWixCompatibilityModeVisible } = checkoutMgmtInitialState;
      return {
        ...state, 
        checkoutManagement: {
          ...state.checkoutManagement,
          isProgressIndicatorVisible,
          isShoppingCartHidden,
          isVisaCardLogoVisible,
          isJCBCardLogoVisible,
          isMasterCardLogoVisible,
          isDinersCardLogoVisible,
          isAmexCardLogoVisible,
          isHowWasThisCalculatedVisible,
          isApplyCouponsVisible,
          isWixCompatibilityModeVisible
        }
      }
    }
    case RESET_CHECKOUT_ACCT_MGMT_CONFIRMATION_SCREEN: {
      const { confirmationHeading, confirmationSubheading, confirmationCopy, confirmationButtonText, redirectUrl } = checkoutMgmtInitialState;
      return {
        ...state, 
        checkoutManagement: {
          ...state.checkoutManagement,
          confirmationHeading,
          confirmationSubheading,
          confirmationCopy,
          confirmationButtonText,
          redirectUrl
        }
      }
    }    
    case UPDATE_CHECK_OUT_MANAGEMENT_DATA: {
      checkoutMgmtInitialState = action.payload;
      return state;
    }
    case RESET_CHECKOUT_ACCT_MGMT: {
      return initialState
    }

    default:
      return state
  }
}