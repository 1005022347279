import React from "react";
import API from "../../utils/API";
import TwoFactorAuthDisable from "./TwoFactorAuthDisable";
import TwoFactorAuthEnable from "./TwoFactorAuthEnable";
import { useFetch } from "../../utils/custom-hooks";
import { IUserWith2FA } from "../../models/User";

interface ITwoFactorAuth {

}

const TwoFactorAuth: React.FC<ITwoFactorAuth> = (props) => {
  const { response, error, isLoading } = useFetch(() => API.getUserDetails());
  const userProfile = response as IUserWith2FA;
  if(isLoading) {
    return null;
  }

  if(userProfile.is2FAActive) {
    return <TwoFactorAuthDisable />
  }

  return <TwoFactorAuthEnable userProfile={userProfile} />
}

export default TwoFactorAuth;