import React from "react";
import "./SandboxWelcome.scss";

import Panel from "../../../components/ui/panel/Panel";
import Text from "../../../components/ui/text/Text";

export const SandboxWelcome = () => {
  return (
    <div className="sandbox-welcome">
     <Panel className="sandbox-welcome__greeting" title="SANDBOX_WELCOME_TITLE">
      <Text className="sandbox-welcome__greeting-text" content="SANDBOX_WELCOME_TEXT" />
     </Panel>
    </div>
  )
}

export default SandboxWelcome;