import React, { FormEvent, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Panel from "../../ui/panel/Panel";
import Text from "../../ui/text/Text";
import Button from "../../ui/button/Button";
import counterpart from "counterpart";
import ErrorPanel from "../../ui/error-panel/ErrorPanel";
import "./BillsbyTaxIntegration.scss";
import { useRouteMatch } from "react-router";
import { AppState } from "../../..";
import AddressForm from "../../address-form/AddressForm";
import { setTaxRatesField } from "../../../actions/companyTaxRatesActions";
import BillsbyTaxCompleteIntegration from "./billsby-tax-complete-integration/BillsbyTaxCompleteIntegration";

interface IMatchProps {
  countryCode: string;
}

const BillsbyTaxIntegration: React.FC = () => {
  const companyTaxRatesReducer = useSelector((state: AppState) => state.companyTaxRatesReducer);

  const dispatch = useDispatch<Function>();
  const match = useRouteMatch<IMatchProps>();

  const [addressLine1, setAddressLine1] = useState("");
  const [addressLine2, setAddressLine2] = useState("");
  const [addressState, setAddressState] = useState({ value: "", label: "" });
  const [addressCountry, setAddressCountry] = useState({ value: "", label: "" });
  const [addressCity, setAddressCity] = useState("");
  const [addressPostCode, setAddressPostCode] = useState("");

  const [state, setState] = useState({
    hasError: false,
    isLoadingData: false,
    isLoading: false
  });

  useEffect(() => {
    return () => {
      dispatch(setTaxRatesField("nexusAddressFilled", false));
      dispatch(setTaxRatesField("productCategories", null));
    };
  }, []);

  const disableConfigUpdate = () => {
    return addressLine1 === "" || addressState.value === "" || addressCountry.value === "" || addressCity === "" || addressPostCode === "";
  };

  const params = match?.params as IMatchProps;
  const { nexusAddressFilled, currentCountry } = companyTaxRatesReducer;

  const onSubmit = async (evt: FormEvent) => {
    evt.preventDefault();
    evt.stopPropagation();

    if (disableConfigUpdate()) {
      return;
    }

    setState({ ...state, isLoading: true });
    try {
      dispatch(setTaxRatesField("nexusAddressFilled", true));
    } catch (err) {
      console.log(err);
    }
  };

  if (params.countryCode === "CAN" || nexusAddressFilled) {
    return (
      <BillsbyTaxCompleteIntegration
        nexusAddress={
          nexusAddressFilled
            ? {
                addressLine1,
                addressLine2,
                countryIso3Code: addressCountry.value,
                state: addressState.value,
                city: addressCity,
                postCode: addressPostCode
              }
            : undefined
        }
      ></BillsbyTaxCompleteIntegration>
    );
  }

  return (
    <div className='billsby-tax-integration'>
      <form className='billsby-tax-integration__form' onSubmit={onSubmit}>
        <Panel title='AUTO_TAX_CALC_PANEL1_TITLE'>
          <Text content='AUTO_TAX_CALC_PANEL1_CONTENT2' translateWith={{ bold: <span className='bold'>{counterpart("AUTO_TAX_CALC_FEE")}</span>, countryName: currentCountry && currentCountry.countryName }}></Text>
          <Text content='AUTO_TAX_CALC_PANEL1_CONTENT3'></Text>
          <AddressForm
            formData={{ addressLine1, addressLine2, addressState, addressCity, addressPostCode, addressCountry }}
            onChangeAddressLine1={line1 => setAddressLine1(line1)}
            onChangeAddressLine2={line2 => setAddressLine2(line2)}
            onChangeAddressCity={city => setAddressCity(city)}
            onChangeAddressCountry={country => setAddressCountry(country)}
            onChangeAddressPostCode={code => setAddressPostCode(code)}
            onChangeAddressState={state => setAddressState(state)}
            label='AUTO_TAX_CALC_NEXUS_ADDRESS'
          ></AddressForm>
        </Panel>

        <Button id='confirm-details' type='submit' disabled={disableConfigUpdate()} buttonType={"general"} isLoading={false} title='AUTO_TAX_CALC_BUTTON1' isFullWidth />
      </form>
      <ErrorPanel
        title='AUTO_TAX_CALC_PANEL3_TITLE2'
        subtitle='AUTO_TAX_CALC_PANEL3_CONTENT'
        buttonText='AUTO_TAX_CALC_BUTTON2'
        icon='fas fa-gavel'
        buttonIcon=''
        buttonCb={() => {
          window.open("https://www.taxjar.com/", "_self");
        }}
      />
    </div>
  );
};


export default BillsbyTaxIntegration;
