import React, { ReactElement } from "react";
import "./TabbedContainer.scss";

interface ITabbedContainerProps {
  type?: "default" | "submenu",
  children: ReactElement | Array<ReactElement>
}

export const TabbedContainer: React.FC<ITabbedContainerProps> = React.memo(({ type = "default", children }) => {
  return (
    <div className={`tabbed-container tabbed-container--${type}`}>
      {children}
    </div>
  )
})

export default TabbedContainer;