import { BillsbyAction } from "../models/BillsbyAction";
import { AppState } from "..";
import {
  RESET_EMAIL_TEMPLATE_VALUES,
  FETCH_EMAIL_TEMPLATE_VALUES_SUCCESS,
  SET_EMAIL_TEMPLATE_FIELD,
  UPDATE_EMAIL_TEMPLATE_ADS_BANNER_SUCCESS,
  UPDATE_EMAIL_TEMPLATE_VALUES_SUCCESS,
  UPDATE_EMAIL_TEMPLATE_VALUES_FAILURE
} from "../actions/emailCustomizationActions";
import { EmailTypes, GetEmailConfigResponse } from "../utils/grpc/generated/Billsby.Protos/Messenger/private/emailconfig/emailconfig_pb";

export interface IEmailCustomizationReducerState {
  subjectLine: string;
  customArea1: string;
  customArea2: string;
  customArea3: string;
  cultureCode: string;
  advertisementImageUrl: string;
  emailType?: EmailTypes;
  sendToCompanyAlertEmail: boolean;
  sendToPlanAlertEmail: boolean;
  sendToProductAlertEmail: boolean;
  hideAdvertisement: boolean;
  advertisementLink: string;
  overrideAdvert: boolean;
  errorMessage: string;
}

export const initState = {
  subjectLine: "",
  customArea1: "",
  customArea2: "",
  customArea3: "",
  cultureCode: "",
  advertisementImageUrl: "",
  emailType: undefined,
  sendToCompanyAlertEmail: false,
  sendToPlanAlertEmail: false,
  sendToProductAlertEmail: false,
  hideAdvertisement: false,
  advertisementLink: "",
  overrideAdvert: true,
  errorMessage: ""
};

export default function emailCustomizationReducer(
  state: IEmailCustomizationReducerState = initState,
  action: BillsbyAction,
  store: AppState
) {
  switch (action.type) {
    case SET_EMAIL_TEMPLATE_FIELD:
      return { ...state, [action.fieldName]: action.fieldValue };
    case UPDATE_EMAIL_TEMPLATE_VALUES_SUCCESS:
      return { ...state, errorMessage: "" };
    case UPDATE_EMAIL_TEMPLATE_VALUES_FAILURE:
      const failureResponse = action as any;
      return { ...state, errorMessage: failureResponse.error.message };
    case FETCH_EMAIL_TEMPLATE_VALUES_SUCCESS:
      const response = action.response as GetEmailConfigResponse;
      return {
        ...state,
        subjectLine: response.getSubjectLine(),
        customArea1: response.getCustomArea1(),
        customArea2: response.getCustomArea2(),
        customArea3: response.getCustomArea3(),
        cultureCode: response.getCultureCode(),
        advertisementImageUrl: response.getAdvertisementImageUrl(),
        emailType: [response.getEmailType()],
        sendToCompanyAlertEmail: response.getSendToCompanyAlertEmail(),
        sendToPlanAlertEmail: response.getSendToPlanAlertEmail(),
        sendToProductAlertEmail: response.getSendToProductAlertEmail(),
        hideAdvertisement: response.getHideAdvertisement(),
        advertisementLink: response.getAdvertisementUrl(),
        overrideAdvert: response.getOverrideAdvert()
      };
    case UPDATE_EMAIL_TEMPLATE_ADS_BANNER_SUCCESS:
      const adsImage = action.response;
      return { ...state, advertisementImageUrl: adsImage.imageSrcUrl };
    case RESET_EMAIL_TEMPLATE_VALUES:
      return { ...initState };
    default:
      return state;
  }
}
