import React from "react";
import "./NumberPicker.scss";

interface INumberPicker {
  onChange?: (value: number) => any,
  className?: string,
  value?: number,
  min?: number,
  max?: number,
  minLock?: boolean,
  maxLock?: boolean
  onMinus: () => void,
  onPlus: () => void,
}

const NumberPicker: React.FC<INumberPicker> = React.memo(({ min = 0, max = 1000000, value = 0, minLock, maxLock, onMinus = () => { }, onPlus = () => { }, onChange = (evt: any) => { }, className = "", ...rest }) => {
  const _value = (value === undefined || value as any === "") ? 0 : value;

  const _onChange = (evt: any) => {
    const value = +evt.target.value;

    if (isNaN(value) || value < min || value > max) {
      return;
    }

    onChange && onChange(value);
  }

  const _onMinus = () => {
    if (value < min) {
      return;
    }

    onMinus && onMinus()
  }

  const _onPlus = () => {
    if (value > max) {
      return;
    }
    onPlus && onPlus()
  }


  return (
    <div className={`number-input number-input__container ${className}`}>
      <button
        className={`number-input__btn number-input__btn-minus ${min === value ? "disabled" : ""} ${minLock ? "lock" : ""}`}
        onClick={_onMinus}
        disabled={_value === min || minLock}
      >
        <i className={`number-input__icon ${minLock ? "fas fa-lock-alt" : "fas fa-minus"}`}></i>
      </button>
        <input type="number" className="number-input__field" value={value == null ? "" : value} onChange={_onChange} {...rest} />
      <button 
        className={`number-input__btn number-input__btn-plus ${max === value ? "disabled" : ""} ${maxLock ? "lock" : ""}`}
        onClick={_onPlus}
        disabled={_value === max || maxLock}>
        <i className={`number-input__icon ${maxLock ? "fas fa-lock-alt" : "fas fa-plus"}`}></i>
      </button>
    </div>
  )
})

export default NumberPicker;