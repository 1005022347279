import React, { useEffect, useState } from "react";
import "./DunningFailedProcess.scss";
import { NavLink, useRouteMatch } from "react-router-dom";
import Panel from "../../../ui/panel/Panel";
import Text from "../../../ui/text/Text";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../../../..";
import history from "../../../../utils/history";
import NavigationBar from "../../../ui/navigation-bar/NavigationBar";
import PageWrapper from "../../../ui/layout/PageWrapper";
import TabbedContainer from "../../../ui/tabbed-container/TabbedContainer";
import counterpart from "counterpart";
import { PrivateRoute } from "../../../../containers/AuthRoutes";
import DeclinedPayments from "./declined-payments/DeclinedPayments";
import FailedPayments from "./failed-payments/FailedPayments";
import DeclinedPaymentEmailTemplate from "../email-customization/declinedPaymentEmailTemplate/DeclinedPaymentEmailTemplate";
import SuccessfulReattemptEmailTemplate from "../email-customization/declinedPaymentEmailTemplate/successfulReattemptEmailTemplate/SuccessfulReattemptEmailTemplate";
import { fetchDunningProcessData, resetDunningProcess } from "../../../../actions/dunningFailedProcessActions";
import FailedPaymentEmailTemplate from "../email-customization/failedPaymentEmailTemplate/FailedPaymentEmailTemplate";
import ProgressIndicator from "../../../ui/progress-indicator/ProgressIndicator";
import { fetchCompanyBranding } from "../../../../actions/companyBrandingActions";


const DunningProcess: React.FC= () => {
  const currentCompanyDomain = useSelector((state: AppState) => state.auth.currentCompanyDomain);

  const match = useRouteMatch();
  const dispatch = useDispatch<Function>();

  const [state, setState] = useState({ isLoadingData: false });

  const fetchData = async () => {
    setState({ ...state, isLoadingData: true })
    try {
      await dispatch(fetchDunningProcessData(currentCompanyDomain));
      setState({ ...state, isLoadingData: false })
    } catch (err) {
      setState({ ...state, isLoadingData: false })

      console.log(err)
    }
  }

  useEffect(() => {
    fetchData();
    dispatch(fetchCompanyBranding(currentCompanyDomain));

    return () => dispatch(resetDunningProcess())
  },[]);

  const pathIsIncluded = (path: string) => {
    return history.location.pathname.toLocaleLowerCase().includes(path);
  }

  if(state.isLoadingData) {
    return <ProgressIndicator color="blue" coverage="full-content"></ProgressIndicator>;
  }

  return (
    <div className="dunning-process">
      <NavigationBar pageTitle="" previousPageTitle="CONFIGURATION_PAGE_TITLE" previousPageCb={() => history.push("/configuration")} />

      <PageWrapper>
        <Panel title="DUNNING_FAILED_PAYMENTS_MENU_TITLE" className="dunning-process__welcome" noMargin>
          <Text content="DUNNING_FAILED_PAYMENTS_PANEL1_CONTENT" noMargin />
        </Panel>

        <TabbedContainer type="submenu">
          <NavLink isActive={(match, location) => (!match || !location ? false : match?.isExact) || pathIsIncluded("customize-email/declined") || pathIsIncluded("customize-email/successful-reattempt")} activeClassName="active" to={`${match?.url}`}>
            {counterpart("DUNNING_FAILED_PAYMENTS_TAB_DECLINED")}
          </NavLink>
          <NavLink isActive={(match, location) => (!match || !location ? false : match?.isExact) || pathIsIncluded("customize-email/failed")} activeClassName="active" to={`${match?.url}/failed`}>
            {counterpart("DUNNING_FAILED_PAYMENTS_TAB_FAILED")}
          </NavLink>
        </TabbedContainer>

        <PrivateRoute render={() => (
          <>
            <PrivateRoute exact path={match?.path} component={DeclinedPayments} />
            <PrivateRoute exact path={`${match?.path}/failed`} component={FailedPayments} />
            <PrivateRoute exact path={`${match?.path}/customize-email/declined/:emailType`} component={DeclinedPaymentEmailTemplate} />
            <PrivateRoute exact path={`${match?.path}/customize-email/successful-reattempt`} component={SuccessfulReattemptEmailTemplate} />
            <PrivateRoute exact path={`${match?.path}/customize-email/failed/initial-payment-failure`} component={FailedPaymentEmailTemplate} />
          </>
        )} />

      </PageWrapper>
    </div>
  );
};

export default DunningProcess;
