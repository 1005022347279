import React, { useState } from "react";
import counterpart from "counterpart";
import "./OtherCompanies.scss";

import Text from "../../../components/ui/text/Text";

interface ICompany {
  name: string,
  type: string,
  productText?: Array<string>,
  cycleText?: Array<string>,
  featuresText?: Array<string>
  otherDetails?: Array<{title: string, descriptions: Array<string>}>
}

interface IOtherCompaniesProps {
  companies: Array<ICompany>
}

const CompanyInfo: React.FC<{ company: ICompany }> = ({ company }) => {

  return (
    <div>
      {company.productText && <div className="other-companies__info-content">
        <i className="fas fa-box-full" />
        <div>
          <Text className="other-companies__info-title" content="PRODUCT_PLAN_OTHER_COMPANIES_PRODUCT" />
          {
            company.productText.map((text: string, i: number) => {
              return <Text key={i} noMargin className="other-companies__info-text" content={text} />
            })
          }  
        </div>
      </div>}
      {company.cycleText && <div className="other-companies__info-content">
        <i className="far fa-repeat" />
        <div>
          <Text className="other-companies__info-title" content="PRODUCT_PLAN_OTHER_COMPANIES_CYCLES" />
          {
            company.cycleText.map((text: string, i: number) => {
              return <Text key={i} noMargin className="other-companies__info-text" content={text} />
            })
          }
        </div>        
      </div>}
      {company.featuresText && <div className="other-companies__info-content">
        <span className="other-companies__info-icon">
          <i className="far fa-ticket-alt" />
        </span>
        <div>
          <Text className="other-companies__info-title" content="PRODUCT_PLAN_OTHER_COMPANIES_FEATURES" />
          {
            company.featuresText.map((text: string, i: number) => {
              return <Text key={i} noMargin className="other-companies__info-text" content={text} />
            })
          }
        </div>        
      </div>}
      {company.otherDetails && company.otherDetails.map((detail:{title: string, descriptions: Array<string>}, idx: number) => (
        <div className="other-companies__info-content" key={idx}>
        <div>
          <Text className="other-companies__info-title" content={detail.title}/>
          {detail.descriptions.map((text: string, i: number) => {
              return <Text key={i} noMargin className="other-companies__info-text" content={text} />
            })}
        </div>        
      </div>
      ))}
    </div>
  )
}

function renderCompanyTabs(companies: Array<ICompany>, selectedCompany: ICompany, onChange: Function) {
  return companies.map((company: ICompany) => {
    const companyName = counterpart(company.name);
    const selectedClass = counterpart(selectedCompany.name) ===  counterpart(company.name) ? "other-companies__tabs__company--active" : "";

    return (
      <button key={companyName} onClick={() => { onChange(company) }} className={`other-companies__tabs__company ${selectedClass}`}>
        <div className={`other-companies__tabs__company-image other-companies__tabs__company-image--${companyName}`} />
        <Text noMargin className="other-companies__tabs__company-type" content={company.type} component="div" />
      </button>
    )
  })
}

export const OtherCompanies: React.FC<IOtherCompaniesProps> = ({ companies }) => {

  const defaultCompany= companies[0]
  const [selectedCompany, setSelectedCompany] = useState(defaultCompany);

  return (
    <div className="other-companies">
      <div className="other-companies__container-tabs">
        <div className="other-companies__tabs">
          {
            renderCompanyTabs(companies, selectedCompany, setSelectedCompany)
          }
        </div>
      </div>
      <div className="other-companies__info">
        {
          <CompanyInfo company={selectedCompany} />
        }
      </div>      
    </div>
  )
}

export default OtherCompanies;