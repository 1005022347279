/**
 * @fileoverview gRPC-Web generated client stub for billing.private.coupons
 * @enhanceable
 * @public
 */

// GENERATED CODE -- DO NOT EDIT!


import * as grpcWeb from "grpc-web";


import {
  GetCouponCodesRequest,
  GetCouponCodesResponse,
  GetCouponUsageRequest,
  GetCouponUsageResponse} from "./coupons_pb";

export class CouponsServiceClient {
  client_: grpcWeb.AbstractClientBase;
  hostname_: string;
  credentials_: null | { [index: string]: string; };
  options_: null | { [index: string]: string; };

  constructor (hostname: string,
               credentials?: null | { [index: string]: string; },
               options?: null | { [index: string]: string; }) {
    if (!options) options = {};
    if (!credentials) credentials = {};
    options["format"] = "text";

    this.client_ = new grpcWeb.GrpcWebClientBase(options);
    this.hostname_ = hostname;
    this.credentials_ = credentials;
    this.options_ = options;
  }

  methodInfoGetCouponUsage = new grpcWeb.AbstractClientBase.MethodInfo(
    GetCouponUsageResponse,
    (request: GetCouponUsageRequest) => {
      return request.serializeBinary();
    },
    GetCouponUsageResponse.deserializeBinary
  );

  getCouponUsage(
    request: GetCouponUsageRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: GetCouponUsageResponse) => void) {
    return this.client_.rpcCall(
      this.hostname_ +
        "/billing.private.coupons.CouponsService/GetCouponUsage",
      request,
      metadata || {},
      this.methodInfoGetCouponUsage,
      callback);
  }

  methodInfoGetCouponCodes = new grpcWeb.AbstractClientBase.MethodInfo(
    GetCouponCodesResponse,
    (request: GetCouponCodesRequest) => {
      return request.serializeBinary();
    },
    GetCouponCodesResponse.deserializeBinary
  );

  getCouponCodes(
    request: GetCouponCodesRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: GetCouponCodesResponse) => void) {
    return this.client_.rpcCall(
      this.hostname_ +
        "/billing.private.coupons.CouponsService/GetCouponCodes",
      request,
      metadata || {},
      this.methodInfoGetCouponCodes,
      callback);
  }

}

