import React from "react";
import Panel from "../../../ui/panel/Panel";
import Text from "../../../ui/text/Text";
import webex from "../../../../images/integrations/webex/webex-overview.png";
import "./WebexOverview.scss";
import WebexEventTriggers from "../webex-event-triggers/WebexEventTriggers";

export const WebexOverview: React.FC = () => {
  return (
    <div className="overview">
      <Panel className="overview__webex" title="INTEGRATIONS_OVERVIEW_WHAT_IS_WEBEX">
        <div className="overview__webex__container">
          <Panel className="overview__webex__image-container">
            <img src={webex} className="overview__webex__image" alt="no img"/>
          </Panel>
          <div className="overview__webex__text">
            <Text content="INTEGRATIONS_OVERVIEW_WHAT_IS_WEBEX_TEXT1" />
            <Text content="INTEGRATIONS_OVERVIEW_WHAT_IS_WEBEX_TEXT2" />
            <Text content="INTEGRATIONS_OVERVIEW_WHAT_IS_WEBEX_TEXT3" />
          </div>
        </div>
      </Panel>

      <WebexEventTriggers></WebexEventTriggers>
    </div>
  )
}

export default WebexOverview;