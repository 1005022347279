import React from "react"
import StepCollapsablePanel from "../../../ui/step-collapsable-panel/StepCollapsablePanel";
import logo from "../../../../images/integrations/free-agent-logo.png";
import Text from "../../../ui/text/Text";

interface IFreeAgentInstalled {}

export const FreeAgentInstalled: React.FC<IFreeAgentInstalled> = () => {
  return (
    <div>
      <StepCollapsablePanel title={
        <div className="free-agent-integrations__title">
          <div>
            <img src={logo} alt="no img" />
            <div>
              <Text content="INTEGRATIONS_FREE_AGENT" noMargin />
            </div>
          </div>
        </div>
      } className="free-agent-integrations__free-agent"
        shouldTranslateTitle={false}
        hasHelpSection={false}
        isCollapsed={false}
      >
        <Text content="INTEGRATION_FREE_AGENT_INSTALLED_MODAL_TEXT1"
          className="free-agent-integrations__faq__text text-inline"
          noMargin />
        <Text content="INTEGRATION_FREE_AGENT_INSTALLED_MODAL_TEXT2"
          className="free-agent-integrations__faq__text text-inline text-bold"
          noMargin />
        <Text content="INTEGRATION_FREE_AGENT_INSTALLED_MODAL_TEXT3"
          className="free-agent-integrations__faq__text text-inline"
          noMargin />
      </StepCollapsablePanel>
    </div>
  )
}

export default FreeAgentInstalled;