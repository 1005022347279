import React, { useState, useEffect, useMemo } from "react";
import Panel from "../../ui/panel/Panel";
import Text from "../../ui/text/Text";
import Button from "../../ui/button/Button";
import FormLabel from "../../ui/form-label/FormLabel";
import FormGroup from "../../ui/form-group/FormGroup";
import Dropdown from "../../ui/dropdown/Dropdown";
import ProgressIndicator from "../../ui/progress-indicator/ProgressIndicator";
import { Row, Col } from "react-grid-system";
import Input from "../../ui/input/Input";
import { Discount, DiscountType, DeductionType } from "../../../utils/grpc/generated/Billsby.Protos/core/private/discounts/discounts_pb";
import counterpart from "counterpart";
import NoticePanel from "../../ui/notice-panel/NoticePanel";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../../..";
import Checkbox from "../../ui/checkbox/Checkbox";
import { FrequencyType } from "../../../utils/grpc/generated/Billsby.Protos/billing/private/subscription/discount/discount_pb";
import { addSubscriptionDiscounts } from "../../../actions/subscriptionDetailsActions";
import { allowNumberMoreThanZero, divideNumberBy100, simulatePrice } from "../../../utils/commonUtils";
import "./SubscriptionAddDiscount.scss";
import { IPlanWithCycles, PricingModelType } from "../../../models/Product";
import { UnitTier } from "../../../utils/grpc/generated/Billsby.Protos/core/private/addons/addons_pb";

interface ISubscriptionAddDiscount {
  handleCallback?: () => void,
  plan: IPlanWithCycles
}

interface IListItem {
  value: Discount,
  label: string
}

const SubscriptionAddDiscount: React.FC<ISubscriptionAddDiscount> = ({ handleCallback, plan }) => {
  const authData = useSelector((state: AppState) => state.auth);
  const subscriptionData = useSelector((state: AppState) => state.subscriptionDetailsReducer);
  const discountsData = useSelector((state: AppState) => state.discountsReducer);

  const dispatch = useDispatch<Function>();
  
  const [isLoadingData, setIsLoadingData] = useState(false);
  const [selectedDiscount, setSelectedDiscount] = useState<IListItem | null>(null);
  const [discountIsFixedTerm, setDiscountIsFixedTerm] = useState(false);
  const [frequency, setFrequency] = useState<number | undefined>(undefined);
  const [frequencyType, setFrequencyType] = useState<{ label: string, value: FrequencyType } | undefined>(undefined);
  const [isLoading, setIsLoading] = useState(false);
  const [isGreaterThanSubscription, setIsGreaterThanSubscription] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string | undefined>();
  const { currency, planPricingModel, nextBillingAmount, discounts, units, cycleId } = subscriptionData;

  const { discountList } = discountsData;

  useEffect(() => {
    if (!!selectedDiscount) {
      const discount = selectedDiscount.value;
      if (discount.getDiscountType() === DiscountType.MONETARY) {
        const priceList = (discount.getMonetary() as Discount.MonetaryModel).getPricesList()
          .filter(i => i.getCurrencyCode() === currency);
        if (priceList.length > 0) {
          if (discount.getDeductionType() === DeductionType.ENTIRE_SUBSCRIPTION) {
            setIsGreaterThanSubscription(Number(priceList[0].getValue()) > nextBillingAmount);
          } else {
            if (plan.pricingModelType === PricingModelType.FlatFee) {
              setIsGreaterThanSubscription(Number(priceList[0].getValue()) > planPricingModel.price);
            } else if(plan.pricingModelType === PricingModelType.PerUnit) {
              setIsGreaterThanSubscription(
                Number(priceList[0].getValue()) >= (planPricingModel.price * Number(units)));
            } else {
              const currentCycle = plan && plan.cycles.find((cycle) => cycle.cycleId === cycleId);
              if (!!currentCycle) {
                const tiers = !!currentCycle.pricingModel.tiers ?
                currentCycle.pricingModel.tiers.map(i => new UnitTier()) : [];
                const price = simulatePrice(Number(units), tiers, plan.pricingModelType);
                setIsGreaterThanSubscription(Number(divideNumberBy100(priceList[0].getValue())) > price);
              }
            }
          }
        }
      } else {
        setIsGreaterThanSubscription(false);
      }
    }
    return () => {
    }
  }, [selectedDiscount])

  const onSubmit = async () => {
    if (!selectedDiscount)
      return;

    const discount = selectedDiscount.value;

    let selectedFrequencyType;
    if (!!frequencyType) {
      selectedFrequencyType = frequencyType.value;
    }

    setIsLoading(true);
    setErrorMessage(undefined);
    try {
      const response = await dispatch(addSubscriptionDiscounts(
        Number(authData.currentCompanyId),
        Number(subscriptionData.subscriptionId),
        discount.getDiscountId(),
        !!authData.parsedToken ? Number(authData.parsedToken.nameid) : 0,
        discountIsFixedTerm,
        frequency,
        selectedFrequencyType));
        if(response.error) {
          setErrorMessage(response.error.code === 13 ? "ADD_DISCOUNT_ERROR1" : "ADD_DISCOUNT_ERROR2");
          setIsLoading(false);
        } else {
          handleCallback && handleCallback();
        }
    } catch (err) {
      setErrorMessage("GENERIC_ERROR");
      setIsLoading(false);
    } 
  };

  const frequencyTypes = [
    { label: counterpart("CREATE_PLAN_PERIOD_DAILY"), value: FrequencyType.DAILY },
    { label: counterpart("CREATE_PLAN_PERIOD_WEEKLY"), value: FrequencyType.WEEKLY },
    { label: counterpart("CREATE_PLAN_PERIOD_MONTHLY"), value: FrequencyType.MONTHLY },
    { label: counterpart("CREATE_PLAN_PERIOD_YEARLY"), value: FrequencyType.YEARLY }
  ];
  const frequencyTypesPlural = [
    { label: counterpart("CREATE_PLAN_PERIOD_DAILY_PLURAL"), value: FrequencyType.DAILY },
    { label: counterpart("CREATE_PLAN_PERIOD_WEEKLY_PLURAL"), value: FrequencyType.WEEKLY },
    { label: counterpart("CREATE_PLAN_PERIOD_MONTHLY_PLURAL"), value: FrequencyType.MONTHLY },
    { label: counterpart("CREATE_PLAN_PERIOD_YEARLY_PLURAL"), value: FrequencyType.YEARLY }
  ];

  const discountInfo = () => {
    if (!selectedDiscount)
      return null;

    const discount = selectedDiscount.value;
    let deductionTypeText = discount.getDeductionType() === DeductionType.PLAN_ONLY ?
      "ADD_DISCOUNT_DISCOUNT_INFO_PLAN_ONLY" : "ADD_DISCOUNT_DISCOUNT_INFO_ENTIRE_SUBSCRIPTION";
    let deduction;
    if (discount.getDiscountType() === DiscountType.PERCENTAGE) {
      deduction = Number(divideNumberBy100(discount.getPercentage()?.getPercentage()));
      return <Text content={deductionTypeText} translateWith={{ deduction: `${deduction}%` }} noMargin />
    }

    if (discount.getDiscountType() === DiscountType.MONETARY) {
      const monetary = discount.getMonetary() as Discount.MonetaryModel;
      const prices = monetary.getPricesList().filter(i => i.getCurrencyCode() === currency);
      if (!!prices) {
        deduction = prices[0].getValueFormatted();
      }
      return <Text content={deductionTypeText} translateWith={{ deduction: `${deduction}` }} noMargin />
    }
  }

  const discountChoicesList = useMemo(() => {
    if (discountList.getDiscountsList().length > 0) {
      const items = discountList.getDiscountsList();
      if (discounts.getDiscountsList().length > 0) {
        const appliedDiscounts = discounts.getDiscountsList();
        if (appliedDiscounts.filter(i => !(i.getDiscount() as Discount).getIsAllowedWithOtherDiscounts()).length > 0) {
          return Array<IListItem>();
        }
        const appliedDiscountIds = appliedDiscounts.map(i => (i.getDiscount() as Discount).getDiscountId())
        return items.filter(i => !appliedDiscountIds.includes(i.getDiscountId())).map(i => ({ value: i, label: i.getName() }));
      }
      return discountList.getDiscountsList().map(i => ({ value: i, label: i.getName() }));
    }

    return Array<IListItem>();
  }, [discounts, discountList]);

  const renderContent = () => {
    if (isLoadingData) {
      return <ProgressIndicator color="blue" coverage="normal" noPadding />;
    }

    return (
      <>
        <FormGroup noMargin>
          <Row align="center">
            <Col xs={2}>
              <FormLabel target="select-discount" content="ADD_DISCOUNT_SELECT_DISCOUNT" noMargin />
            </Col>
            <Col xs={10}>
              <Dropdown
                id="select-discount"
                value={selectedDiscount}
                onChange={(value: IListItem) => {
                  setSelectedDiscount(value);
                }}
                options={discountChoicesList}
                placeholder={counterpart("ADD_DISCOUNT_SELECT_YOUR_DISCOUNT")}
              />
            </Col>
          </Row>
        </FormGroup>
        {selectedDiscount && (
          <Row align="center" className="subscription-add-discount__discount-info">
            <Col xs={2}>
            </Col>
            <Col xs={10}>
              {discountInfo()}
            </Col>
          </Row>)}
        {
          selectedDiscount && (
            <FormGroup noMargin>
              <Row align="center">
                <Col xs={2}>
                </Col>
                <Col xs={10}>
                  <Checkbox checked={!discountIsFixedTerm} onClick={() => setDiscountIsFixedTerm(false)} value='auto-calculation' content={<Text content='ADD_DISCOUNT_LIFETIME_DISCOUNT' noMargin />} />
                  <Checkbox checked={discountIsFixedTerm} onClick={() => setDiscountIsFixedTerm(true)} value='auto-calculation' content={<Text content='ADD_DISCOUNT_FIXED_TERM_DISCOUNT' noMargin />} />
                </Col>
              </Row>
            </FormGroup>
          )
        }
        {
          selectedDiscount && discountIsFixedTerm && (
            <FormGroup noMargin>
              <Row align="center">
                <Col md={2}>
                  <FormLabel target="frequency" content="ADD_DISCOUNT_DISCOUNT_LENGTH" noMargin />
                </Col>
                <Col md={4}>
                  <Input
                    id="frequency"
                    required
                    value={frequency}
                    type="number"
                    placeholder={counterpart("CREATE_COUPON_FORM_COUPON_WORKS_DISCOUNT_LENGTH_FIXED_TERM_FREQUENCY_PLACEHOLDER")}
                    onChange={(evt: any) => {
                      setFrequency(allowNumberMoreThanZero(evt.target.value));
                      if (frequencyType) {
                        const value = evt.target.value > 1 ? frequencyTypesPlural.filter(i => i.value === frequencyType.value)[0]
                          : frequencyTypes.filter(i => i.value === frequencyType.value)[0];
                        setFrequencyType(value);
                      }
                    }}
                  />
                </Col>
                <Col md={6}>
                  <Dropdown
                    id="frequencyType"
                    dividerAfter={4}
                    onChange={(selectedItem: any) => setFrequencyType(selectedItem)}
                    value={frequencyType}
                    options={frequency && +frequency > 1 ? frequencyTypesPlural : frequencyTypes}
                  />
                </Col>
              </Row>
            </FormGroup>
          )
        }
      </>
    );
  };

  const canSubmit = () => !selectedDiscount ? false : !discountIsFixedTerm ? true : (!!frequency && !!frequencyType);

  return (
    <>
      {isGreaterThanSubscription && <NoticePanel type="warning" isModal={true} content="ADD_DISCOUNT_DISCOUNT_WARNING_EXCEEDS_SUBSCRIPTION_PRICE" />}
      {errorMessage && <NoticePanel type="error" isModal={true} content={errorMessage} />}
      <div className="subscription-add-discount">
        <Panel className={`subscription-add-discount__title-panel ${false ? "edit" : ""}`} title={false ? "EDIT_ADD_ON_TITLE" : "SUBSCRIPTION_DETAILS_ADD_DISCOUNT_TITLE"}>
          <Text
            className="subscription-add-discount__title-text"
            content={false ? "EDIT_ADD_ON_DESCRIPTION" : "SUBSCRIPTION_DETAILS_ADD_DISCOUNT_TEXT_1"}
            noMargin
          />
        </Panel>
        <Panel>{renderContent()}</Panel>
        <Button
          disabled={!canSubmit()}
          isLoading={isLoading}
          onClick={() => onSubmit()}
          id="add-discount-btn"
          type="submit"
          buttonType="general"
          title="ADD_DISCOUNT_ADD_DISCOUNT_BUTTON"
          isFullWidth
        />
      </div>
    </>
  )
}

export default SubscriptionAddDiscount;
