import { IAchSettingsReducer } from "../reducers/achSettingsReducer";
import { ConfigConstants } from "../utils/config";
import { CompanyConfigServiceClient } from "../utils/grpc/generated/Billsby.Protos/Messenger/private/companyconfig/CompanyconfigServiceClientPb";
import { GetAchMicroDepositEmailConfigRequest, UpdateAchMicroDepositEmailConfigRequest } from "../utils/grpc/generated/Billsby.Protos/Messenger/private/companyconfig/companyconfig_pb";
import { grpcUnaryCall } from "../utils/grpc/grpcUtils";
import { makeGenericActionCreatorTypeSafe } from "../utils/reduxUtils";
import { CompanyConfigServiceClient as CompanyConfigServiceClientBilling } from "../utils/grpc/generated/Billsby.Protos/billing/private/companyconfig/Company_configServiceClientPb"
import { GetAchPaymentConfigRequest, UpdateAchPaymentConfigRequest } from "../utils/grpc/generated/Billsby.Protos/billing/private/companyconfig/company_config_pb";

export const SET_ACH_SETTINGS_FIELD = "SET_ACH_SETTINGS_FIELD"
export const setAchSettingsField = makeGenericActionCreatorTypeSafe<IAchSettingsReducer>(SET_ACH_SETTINGS_FIELD)

export const FETCH_ACH_MICRODEPOSIT_SETTINGS_REQUEST = "FETCH_ACH_MICRODEPOSIT_SETTINGS_REQUEST"
export const FETCH_ACH_MICRODEPOSIT_SETTINGS_SUCCESS = "FETCH_ACH_MICRODEPOSIT_SETTINGS_SUCCESS"
export const FETCH_ACH_MICRODEPOSIT_SETTINGS_FAILURE = "FETCH_ACH_MICRODEPOSIT_SETTINGS_FAILURE"

export const UPDATE_ACH_MICRODEPOSIT_SETTINGS_REQUEST = "UPDATE_ACH_MICRODEPOSIT_SETTINGS_REQUEST"
export const UPDATE_ACH_MICRODEPOSIT_SETTINGS_SUCCESS = "UPDATE_ACH_MICRODEPOSIT_SETTINGS_SUCCESS"
export const UPDATE_ACH_MICRODEPOSIT_SETTINGS_FAILURE = "UPDATE_ACH_MICRODEPOSIT_SETTINGS_FAILURE"

export const FETCH_ACH_PAYMENT_CONFIG_REQUEST = "FETCH_ACH_PAYMENT_CONFIG_REQUEST"
export const FETCH_ACH_PAYMENT_CONFIG_SUCCESS= "FETCH_ACH_PAYMENT_CONFIG_SUCCESS"
export const FETCH_ACH_PAYMENT_CONFIG_FAILURE = "FETCH_ACH_PAYMENT_CONFIG_FAILURE"

export const UPDATE_ACH_PAYMENT_CONFIG_REQUEST = "UPDATE_ACH_PAYMENT_CONFIG_REQUEST"
export const UPDATE_ACH_PAYMENT_CONFIG_SUCCESS = "UPDATE_ACH_PAYMENT_CONFIG_SUCCESS"
export const UPDATE_ACH_PAYMENT_CONFIG_FAILURE = "UPDATE_ACH_PAYMENT_CONFIG_FAILURE"


export const fetchAchMicroDepositSettings = (companyId: number) => {
  const companyService = new CompanyConfigServiceClient(ConfigConstants.grpcBaseUrl)
  const getAchMicroDepositEmailConfigRequest = new GetAchMicroDepositEmailConfigRequest()
  getAchMicroDepositEmailConfigRequest.setCompanyId(companyId)

  return {
    types: [FETCH_ACH_MICRODEPOSIT_SETTINGS_REQUEST, FETCH_ACH_MICRODEPOSIT_SETTINGS_SUCCESS, FETCH_ACH_MICRODEPOSIT_SETTINGS_FAILURE],
    callAPI: () => grpcUnaryCall(getAchMicroDepositEmailConfigRequest, companyService, companyService.getAchMicroDepositEmailConfig)
  }
}

export const updateAchMicroDepositSettings = (companyId: number, bccEmail: string, isEnabledNotifications: boolean) => {
  const companyService = new CompanyConfigServiceClient(ConfigConstants.grpcBaseUrl)
  const updateAchMicroDepositEmailConfigRequest = new UpdateAchMicroDepositEmailConfigRequest()
  updateAchMicroDepositEmailConfigRequest.setCompanyId(companyId)
  updateAchMicroDepositEmailConfigRequest.setBccEmail(bccEmail)
  updateAchMicroDepositEmailConfigRequest.setIsEnabled(isEnabledNotifications)
			
  return {
    types: [UPDATE_ACH_MICRODEPOSIT_SETTINGS_REQUEST, UPDATE_ACH_MICRODEPOSIT_SETTINGS_SUCCESS, UPDATE_ACH_MICRODEPOSIT_SETTINGS_FAILURE],
    callAPI: () => grpcUnaryCall(updateAchMicroDepositEmailConfigRequest, companyService, companyService.updateAchMicroDepositEmailConfig)
  }
}

export const fetchAchPaymentConfig = (companyId: number) => {
  const companyService = new CompanyConfigServiceClientBilling(ConfigConstants.grpcBaseUrl)
  const getAchPaymentConfig = new GetAchPaymentConfigRequest()
  getAchPaymentConfig.setCompanyId(companyId)

  return {
    types: [FETCH_ACH_PAYMENT_CONFIG_REQUEST, FETCH_ACH_PAYMENT_CONFIG_SUCCESS, FETCH_ACH_PAYMENT_CONFIG_FAILURE],
    callAPI: () => grpcUnaryCall(getAchPaymentConfig, companyService, companyService.getAchPaymentConfig)
  }
}

export const updateAchPaymentConfig = (companyId: number, isAchPaymentActive: boolean) => {
  const companyService = new CompanyConfigServiceClientBilling(ConfigConstants.grpcBaseUrl)
  const updateAchPaymentConfig = new UpdateAchPaymentConfigRequest()
  updateAchPaymentConfig.setCompanyId(companyId)
  updateAchPaymentConfig.setIsAchPaymentsActive(isAchPaymentActive)
  return {
    types: [UPDATE_ACH_PAYMENT_CONFIG_REQUEST, UPDATE_ACH_PAYMENT_CONFIG_SUCCESS, UPDATE_ACH_PAYMENT_CONFIG_FAILURE],
    callAPI: () => grpcUnaryCall(updateAchPaymentConfig, companyService, companyService.updateAchPaymentConfig)
  }
}