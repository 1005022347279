import moment from "moment";
import { ConfigConstants } from "./config";

export const INVALID_REGISTRATION_EMAILS_DOMAINS: Array<string> = [
  "aol.*",
  "att.*",
  "comcast.*",
  "facebook.com",
  "gmail.com",
  "gmx.com",
  "googlemail.com",
  "google.com",
  "hotmail.com",
  "hotmail.co.uk",
  "mac.com",
  "me.com",
  "mail.com",
  "msn.com",
  "live.com",
  "sbcglobal.net",
  "verizon.net",
  "yahoo.com",
  "yahoo.co.uk",
  "email.com",
  "fastmail.fm",
  "games.com",
  "gmx.net",
  "hush.com",
  "hushmail.com",
  "icloud.com",
  "iname.com",
  "inbox.com",
  "lavabit.com",
  "love.com",
  "outlook.com",
  "pobox.com",
  "protonmail.com",
  "rocketmail.com",
  "safe- mail.net",
  "wow.com",
  "ygm.com",
  "ymail.com",
  "zoho.com",
  "yandex.com"
];

export const EXISTING_BILLING_PROVIDERS: Array<string> = [
  "Stripe",
  "Chargebee",
  "Chargify",
  "ChargeOver",
  "Recurly",
  "Zuora",
  "NetSuite",
  "Fusebill",
  "Sage Intacct",
  "PaySimple",
  "Zoho",
  "Vindicia",
  "Salesforce",
  "SAP"
].sort();


/**
 * this IFE returns the environment (dev, test, prod) key used in the auth cookie 
 * so we can login in the same browser in multiple environments e.g in dev and test without logging out from one of the others
 */
type EnvType = "_dev" | "_test" | "";
const environment: EnvType = (function getEnv() {
  const isRunningTests = process.env.JEST_WORKER_ID !== undefined;
  if(isRunningTests) { 
    return "_test" 
  }

  const isDev = ConfigConstants.baseUrl.includes("dev") ? true : false;
  const isTest = ConfigConstants.baseUrl.includes("test") ? true : false;
  return isDev ? "_dev" : isTest ? "_test" : "";
}())

/* COOKIES */
export const BILLSBY_AUTH_DATA_CONTROL = `billsby_auth_data_control${environment}`;
export const BILLSBY_SELECTED_COMPANY = `billsby_selected_company${environment}`;
export const BILLSBY_ONE_SESSION_LOGIN = `billsby_one_session_login${environment}`;
export const BILLSBY_PRELIVE_FIRST_TIME = `billsby_prelive_first_time${environment}`;
export const BILLSBY_GUID = "billsby_guid";

/* STORE TYPES */
export const SESSION_STORAGE = "sessionStorage";
export const LOCAL_STORAGE = "localStorage";
export const COOKIE_STORAGE = "cookieStorage";

export const QUIZ_FIELD_CUSTOMER_TYPE = "Customer_Type";
export const QUIZ_FIELD_EXISTING_PROVIDER_TYPE = "Existing_Provider";
export const QUIZ_FIELD_TIMESCALE_CAPTURED_TYPE = "Timescale";
export const QUIZ_FIELD_SUBSCRIBER_BASE_TYPE = "Subscriber_Base";
export const QUIZ_FIELD_ARPU_TYPE = "ARPU";
export const QUIZ_FIELD_INDUSTRY_TYPE = "Industry";


export enum COLOR {
  GREEN = "green",
  LIGHT_GREEN = "light-green",
  LIGHT_RED = "light-red",
  LIGHT_BLUE = "light-blue",
  RED = "red",
  ORANGE = "orange",
  BLUE = "blue",
  LIGHT_ORANGE = "light-orange"
}

export enum CustomeAnalyticsEvents {
  REGISTRATION_JOURNEY_STARTED = "registration_journey_started",
  FIRST_CYCLE_ADDED = "first_cycle_added",
  SIGN_UP = "sign_up",
  GO_LIVE = "go_live"
}

/**
 * internal mapping from spreedly errors to user errors
 * https://docs.spreedly.com/reference/iframe/v1/#errors
 */
export const SPREEDLY_ERRORS: { [key: string]: string } = {
  "errors.account_inactive": "SPREEDLY_ERROR_GENERIC",
  "errors.environment_key_parameter_required": "SPREEDLY_ERROR_GENERIC",
  "errors.invalid_environment_key_parameter": "SPREEDLY_ERROR_GENERIC",
  "errors.blank": "SPREEDLY_ERROR_CARD_INVALID",
  "errors.invalid": "SPREEDLY_ERROR_CARD_INVALID",
  "errors.blank_card_type": "SPREEDLY_ERROR_CARD_TYPE_INVALID",
  "errors.expired": "SPREEDLY_ERROR_CARD_EXPIRED",
  "errors.unknown_referrer": "SPREEDLY_ERROR_GENERIC",
  "errors.invalid_referrer": "SPREEDLY_ERROR_GENERIC",
  "errors.configuration": "SPREEDLY_ERROR_GENERIC"
};

export const SPREEDLY_TEST_CREDIT_CARD = "4111111111111111";
export const SPREEDLY_TEST_CVV = "123";
export const SPREEDLY_TEST_EXP_YEAR = moment.utc().add(3, "years").year();
export const SPREEDLY_TEST_EXP_MONTH = 12;
export const SPREEDLY_TEST_CARD_NAME = "Sandbox Test Card"

export const PREFERRED_PHONE_COUNTRIES = {
  countries: ["US", "GB", "AU", "CA"],
  defaultCountry: "US"
};

export const TEMP_API_TOKEN = "f8f32397265203291b3bc32d2dc3a0b6";

export const PAGINATION_PAGE_SIZE = 15;
export const EMAIL_LOG_PAGINATION_SIZE = 13;

export const BILLSBY_COMPANY_ID = 1000;