import API from "../utils/API";
import { makeActionCreator, makeGenericActionCreator } from "../utils/reduxUtils";

export const FETCH_COMPANY_WEBHOOK_SETUP_REQUEST = "FETCH_COMPANY_WEBHOOK_SETUP_REQUEST";
export const FETCH_COMPANY_WEBHOOK_SETUP_SUCCESS = "FETCH_COMPANY_WEBHOOK_SETUP_SUCCESS";
export const FETCH_COMPANY_WEBHOOK_SETUP_FAILURE = "FETCH_COMPANY_WEBHOOK_SETUP_FAILURE"

export const UPDATE_COMPANY_WEBHOOK_SETUP_REQUEST = "UPDATE_COMPANY_WEBHOOK_SETUP_REQUEST";
export const UPDATE_COMPANY_WEBHOOK_SETUP_SUCCESS = "UPDATE_COMPANY_WEBHOOK_SETUP_SUCCESS";
export const UPDATE_COMPANY_WEBHOOK_SETUP_FAILURE = "UPDATE_COMPANY_WEBHOOK_SETUP_FAILURE";

export const REMOVE_COMPANY_WEBHOOK_SETUP_REQUEST = "REMOVE_COMPANY_WEBHOOK_SETUP_REQUEST";
export const REMOVE_COMPANY_WEBHOOK_SETUP_SUCCESS = "REMOVE_COMPANY_WEBHOOK_SETUP_SUCCESS";
export const REMOVE_COMPANY_WEBHOOK_SETUP_FAILURE = "REMOVE_COMPANY_WEBHOOK_SETUP_FAILURE";

export const SET_WEBHOOKS_DETAILS_FIELD = "SET_WEBHOOKS_DETAILS_FIELD";
export const SET_WEBHOOKS_IS_COLLAPSED_WEBHOOKS_RESPOND = "SET_WEBHOOKS_IS_COLLAPSED_WEBHOOKS_RESPOND";
export const RESET_WEBHOOKS = "RESET_WEBHOOKS";

type AllowedWebhooksReducerFields = "url" | "secret";

export const resetWebhooks = makeActionCreator(RESET_WEBHOOKS, "payload");
export const setRespondingToWebhooksCollapsed = makeActionCreator(SET_WEBHOOKS_IS_COLLAPSED_WEBHOOKS_RESPOND, "payload");
export const setWebhooksDetailsField = makeGenericActionCreator<AllowedWebhooksReducerFields>(SET_WEBHOOKS_DETAILS_FIELD);

export const fetchCompanyWebhookSetup = function (companyDomain: string) {
  return {
    types: [FETCH_COMPANY_WEBHOOK_SETUP_REQUEST, FETCH_COMPANY_WEBHOOK_SETUP_SUCCESS, FETCH_COMPANY_WEBHOOK_SETUP_FAILURE],
    callAPI: () => API.fetchCompanyWebhookSetup(companyDomain)
  }
}

export const updateCompanyWebhookSetup = function (companyDomain: string, data: { targetUrl: string, secret: string }) {
  return {
    types: [UPDATE_COMPANY_WEBHOOK_SETUP_REQUEST, UPDATE_COMPANY_WEBHOOK_SETUP_SUCCESS, UPDATE_COMPANY_WEBHOOK_SETUP_FAILURE],
    callAPI: () => API.updateCompanyWebhookSetup(companyDomain, data)
  }
}

export const removeCompanyWebhookSetup = function (companyDomain: string) {
  return {
    types: [REMOVE_COMPANY_WEBHOOK_SETUP_REQUEST, REMOVE_COMPANY_WEBHOOK_SETUP_SUCCESS, REMOVE_COMPANY_WEBHOOK_SETUP_FAILURE],
    callAPI: () => API.removeCompanyWebhookSetup(companyDomain)
  }
}