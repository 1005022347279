import React, { useEffect } from "react";
import history from "../../../../../utils/history";
import Panel from "../../../../ui/panel/Panel";
import Text from "../../../../ui/text/Text";
import "./OneTimePasscodeTemplate.scss";
import { AppState } from "../../../../..";
import { useDispatch, useSelector } from "react-redux";
import { IAuthReducerState } from "../../../../../reducers/authReducer";
import { ICompanyBrandingReducerState } from "../../../../../reducers/companyBrandingReducer";
import { Row, Col } from "react-grid-system";
import EmailPreivewContainer from "../../brand-customization/email-preview/EmailPreivewContainer";
import BackNavigationPanel from "../../../../ui/back-navigation-panel/BackNavigationPanel";
import EmailFooter from "../email-footer/EmailFooter";
import EmailEditableArea from "../email-editable-area/EmailEditableArea";
import { fetchCompanyBranding } from "../../../../../actions/companyBrandingActions";
import { getEmailTemplateValues, resetEmailTemplateValues } from "../../../../../actions/emailCustomizationActions";
import { EmailTypes } from "../../../../../utils/grpc/generated/Billsby.Protos/Messenger/private/emailconfig/emailconfig_pb";


const OneTimePasscodeTemplate: React.FC = () => {

  const dispatch = useDispatch();
  const authReducer = useSelector<AppState, IAuthReducerState>(state => state.auth);
  const otp_companyBrandingReducer = useSelector<AppState, ICompanyBrandingReducerState>(state => state.companyBrandingReducer);
  const { companyName, currentCompanyDomain, currentCompanyId } = authReducer;

  useEffect(() => {
    dispatch(fetchCompanyBranding(currentCompanyDomain));
    dispatch(getEmailTemplateValues(currentCompanyId as number, companyName, EmailTypes.CUSTOMER_PASSCODE));
    return () => { dispatch(resetEmailTemplateValues()) };
  }, []);

  return (
    <div id="OneTimePasscodeTemplate-wrapper">
      <BackNavigationPanel
        title={"EMAIL_TEMPLATE_ONE_TIME_PASSCODE_TEMPLATE_BACK_PANEL_TITLE"} shouldTranslateTitle={true}
        onClick={() => history.push("/configuration/email-notifications/emails/one-time-passcode")} />

      <Row>
        <Col sm={8}>
          <Panel className="one-time-passcode-email-template__panel" noMargin>
            <EmailPreivewContainer>

              <Text
                content="EMAIL_TEMPLATE_ONE_TIME_PASSCODE_TITLE"
                className="email-preview__details__text email-preview__details__text-title"
                component="span"
                noMargin
              />

              <Text
                content="EMAIL_TEMPLATE_GREETINGS"
                className="email-preview__details__text"
                component="span"
                noMargin
              />

              <Text
                content="EMAIL_TEMPLATE_ONE_TIME_PASSCODE_BODY_1"
                className="email-preview__details__text"
                component="span"
                noMargin
              />

              <Text
                content="EMAIL_TEMPLATE_REGARDS"
                className="email-preview__details__text"
                component="span"
                noMargin
              />

              <Text
                content={companyName}
                className="email-preview__details__text"
                component="span"
                shouldTranslate={false}
                noMargin
              />
              <EmailFooter />
            </EmailPreivewContainer>
          </Panel>
        </Col>
        <Col sm={4}>
          <EmailEditableArea
            id="OneTimePasscodeTemplate"
            emailCustomCopyOneValue=""
            emailCustomCopyTwoValue=""
            emailCustomCopyThreeValue=""
            emailCustomCopyOneIsVisible={false}
            emailCustomCopyTwoIsVisible={false}
            emailCustomCopyThreeIsVisible={false}
            showCompanyAlertToggle={false}
            showProductAlertToggle={false}
            showPlanAlertToggle={false}
          />   
        </Col>
      </Row>
    </div>
  )
}

export default OneTimePasscodeTemplate