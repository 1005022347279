import React, { FormEvent, useEffect, useState, useMemo } from "react";
import { useSelector } from "react-redux";
import Panel from "../../ui/panel/Panel";
import Text from "../../ui/text/Text";
import { Row, Col } from "react-grid-system";
import FormLabel from "../../ui/form-label/FormLabel";
import Input from "../../ui/input/Input";
import Button from "../../ui/button/Button";
import Dropdown from "../../ui/dropdown/Dropdown";
import FormGroup from "../../ui/form-group/FormGroup";
import API from "../../../utils/API";
import counterpart from "counterpart";
import { ICurrency } from "../../../models/Currency";
import { AppState } from "../../..";
import NoticePanel from "../../ui/notice-panel/NoticePanel";
import "./OneTimeCharge.scss";
import { Link } from "react-router-dom";
import { multiplyNumberBy100 } from "../../../utils/commonUtils";

interface IOneTimeChargeModalProps {
  handleOneTimeChargeCallback: Function
}

interface IOneTimeChargeState {
  amount?: string,
  currency: { label: string, value: string, isCreditSupported: boolean },
  currenciesDropdown: Array<{ label: string, value: string, isCreditSupported: boolean }>,
  description: string,
  hasPaymentGatewayMapped: boolean,
  hasError: boolean,
  success: boolean,
  isLoadingData: boolean,
  isLoading: boolean
}

interface IMappedCurrency {
  paymentGatewayId: number,
  currencyCode: string,
  isCreditSupported: boolean
}

const OneTimeCharge: React.FC<IOneTimeChargeModalProps> = ({ handleOneTimeChargeCallback }) => {
  const authData = useSelector((state: AppState) => state.auth);
  const customerUniqueId = useSelector((state: AppState) => state.customerDetailsReducer.customerUniqueId);

  const [state, setState] = useState<IOneTimeChargeState>({
    amount: "",
    currency: { label: "", value: "", isCreditSupported: true },
    currenciesDropdown: [],
    description: "",
    hasPaymentGatewayMapped: false,
    hasError: false,
    success: false,
    isLoadingData: false,
    isLoading: false
  });

  const { amount, currency, currenciesDropdown, description, hasError, success, isLoadingData, hasPaymentGatewayMapped, isLoading } = state;
  const { currentCompanyDomain, currentCompanyCurrency } = authData;

  const fetchData = async () => {
    setState(prevState => ({ ...prevState, isLoadingData: true }))
    try {
      const allCurrencies = (await API.getCurrencies()) as Array<ICurrency>;
      const mappedCurrencies = (await API.fetchMappedCurrency(currentCompanyDomain)) as Array<IMappedCurrency>;

      if (mappedCurrencies.length === 0) {
        return setState(prevState => ({ ...prevState, hasPaymentGatewayMapped: false, isLoadingData: false }));
      }

      const currencyOptions = allCurrencies.filter(c => mappedCurrencies.some(c2 => c2.currencyCode === c.isoCode));
      const currenciesDropdown = currencyOptions.map(c => {
        const { isCreditSupported } = mappedCurrencies.find(c2 => c2.currencyCode === c.isoCode) as IMappedCurrency;
        return { value: c.isoCode, label: c.englishName, isCreditSupported }
      });
      const baseCurrency = currenciesDropdown.find(c => c.value === currentCompanyCurrency) || currenciesDropdown[0];
      setState(prevState => ({ ...prevState, currency: baseCurrency, currenciesDropdown, hasPaymentGatewayMapped: true, isLoadingData: false }));
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const hasNegativeValue = useMemo(() => !!amount && `${amount}`.includes("-"), [amount]);

  const onSubmit = async (evt: FormEvent) => {
    evt.preventDefault();
    evt.stopPropagation();

    if (!customerUniqueId) {
      return;
    }

    setState(prevState => ({ ...prevState, isLoading: true }));

    try {
      await API.oneTimeChargeUser(currentCompanyDomain, customerUniqueId, { currencyCode: currency.value, amount: multiplyNumberBy100(Number(amount)) as number, description });
      await handleOneTimeChargeCallback();
    } catch (err) {
      setState(prevState => ({ ...prevState, isLoading: false, hasError: true }));

      console.log(err);
    }
  };

  if(isLoadingData) {
    return null;
  }

  return (
    <form className="one-time-charge" onSubmit={onSubmit}>

      {hasNegativeValue && <NoticePanel
        type="warning"
        isModal
        content="ONE_TIME_CHARGE_NEGATIVE_VALUE_WARNING"
      />}

      {hasError && <NoticePanel type="error" isModal={true} icon="far fa-lightbulb-exclamation" content="ONE_TIME_CHARGE_ERROR" />}
      <Panel className="one-time-charge__titlepanel" title="ONE_TIME_CHARGE_PANEL1_TITLE">
        <Text content="ONE_TIME_CHARGE_PANEL1_CONTENT" noMargin />
      </Panel>

      {hasPaymentGatewayMapped ? (
        <>
          <Panel>
            <FormGroup>
              <Row align="center">
                <Col md={2}>
                  <FormLabel target="amount" content="ONE_TIME_CHARGE_AMOUNT" noMargin />
                </Col>
                <Col md={10}>
                  <Input
                    required
                    id="amount"
                    disabled={!hasPaymentGatewayMapped}
                    placeholder="10.00"
                    value={amount}
                    onChange={(evt: any) => {
                      const value = !evt.target.value || evt.target.value === "-" || !isNaN(evt.target.value) ? evt.target.value : amount;
                      setState({ ...state, amount: value });
                    }}
                  />
                </Col>
              </Row>
            </FormGroup>

            <FormGroup>
              <Row align="center">
                <Col md={2}>
                  <FormLabel target="currency" content="ONE_TIME_CHARGE_CURRENCY" />
                </Col>
                <Col md={10}>
                  <Dropdown
                    id="billingcurrency"
                    dividerAfter={4}
                    onChange={(currency: any) => {
                      setState({ ...state, currency });
                    }}
                    value={currency}
                    options={currenciesDropdown}
                    isDisabled={!hasPaymentGatewayMapped}
                  />
                </Col>
              </Row>
            </FormGroup>

            <div>
              <Row align="center">
                <Col md={2}>
                  <FormLabel target="description" content="ONE_TIME_CHARGE_DESCRIPTION" />
                </Col>
                <Col md={10}>
                  <Input
                    id="description"
                    disabled={!hasPaymentGatewayMapped}
                    placeholder={counterpart("ONE_TIME_CHARGE_DESCRIPTION_PLACEHOLDER")}
                    value={description}
                    onChange={(evt: any) => setState({ ...state, description: evt.target.value })}
                    required
                  />
                </Col>
              </Row>
            </div>
          </Panel>

          <Button
            id="charge-customer"
            type="submit"
            disabled={!amount || !hasPaymentGatewayMapped || !description || (hasNegativeValue && !currency.isCreditSupported) || isLoading}
            icon={success ? "far fa-check-circle" : ""}
            buttonType={success ? "success" : "general"}
            isLoading={isLoading && !hasError}
            title={success ? "ONE_TIME_CHARGE_CUSTOMER_SUCCESS" : "ONE_TIME_CHARGE_CUSTOMER"}
            isFullWidth
          />
        </>
      ) : (
        <NoticePanel
          type="error"
          icon="far fa-lightbulb-exclamation"
          content="ERROR_PAYMENT_GATEWAY"
          className="one-time-charge__notice-panel"
          translateWith={{ link: <Link to="/configuration/payment-gateways">{counterpart("LINK_PAYMENT_GATEWAYS")}</Link> }}
        />
      )}
    </form>
  );
};

export default OneTimeCharge;
