import { makeActionCreator, makeGenericActionCreatorTypeSafe } from "../utils/reduxUtils";
import { grpcUnaryCall } from "../utils/grpc/grpcUtils";
import { GetCompanyIntegrationsRequest } from "../utils/grpc/generated/Billsby.Protos/core/private/companyintegrations/companyintegrations_pb";
import { CompanyIntegrationServiceClient } from "../utils/grpc/generated/Billsby.Protos/core/private/companyintegrations/CompanyintegrationsServiceClientPb";
import { ConfigConstants } from "../utils/config";
import { GetCompanyQuickBooksAccountsRequest, GetQuickBooksAccountsRequest, DeleteIntegrationRequest, IntegrationType, CreateQuickBooksIntegrationRequest } from "../utils/grpc/generated/Billsby.Protos/accountingIntegrations/private/accounts/accounts_pb";
import { AccountingIntegrationServiceClient } from "../utils/grpc/generated/Billsby.Protos/accountingIntegrations/private/accounts/AccountsServiceClientPb";
import { IAccountingIntegrationsReducerState } from "../reducers/accountingIntegrationsReducer";
import { CreateIntegrationRequest } from "../utils/grpc/generated/Billsby.Protos/webexIntegration/private/webex/webex_pb";
import { WebexServiceClient } from "../utils/grpc/generated/Billsby.Protos/webexIntegration/private/webex/WebexServiceClientPb";

export const FETCH_ACCOUNTING_INTEGRATIONS_REQUEST = "FETCH_ACCOUNTING_INTEGRATIONS_REQUEST";
export const FETCH_ACCOUNTING_INTEGRATIONS_SUCCESS = "FETCH_ACCOUNTING_INTEGRATIONS_SUCCESS";
export const FETCH_ACCOUNTING_INTEGRATIONS_FAILURE = "FETCH_ACCOUNTING_INTEGRATIONS_FAILURE";

export const FETCH_QUICK_BOOKS_ACCOUNTS_REQUEST = "FETCH_QUICK_BOOKS_ACCOUNTS_REQUEST";
export const FETCH_QUICK_BOOKS_ACCOUNTS_SUCCESS = "FETCH_QUICK_BOOKS_ACCOUNTS_SUCCESS";
export const FETCH_QUICK_BOOKS_ACCOUNTS_FAILURE = "FETCH_QUICK_BOOKS_ACCOUNTS_FAILURE";

export const FETCH_COMPANY_QUICK_BOOKS_ACCOUNTS_REQUEST = "FETCH_COMPANY_QUICK_BOOKS_ACCOUNTS_REQUEST";
export const FETCH_COMPANY_QUICK_BOOKS_ACCOUNTS_SUCCESS = "FETCH_COMPANY_QUICK_BOOKS_ACCOUNTS_SUCCESS";
export const FETCH_COMPANY_QUICK_BOOKS_ACCOUNTS_FAILURE = "FETCH_COMPANY_QUICK_BOOKS_ACCOUNTS_FAILURE";

export const DELETE_ACCOUNTING_INTEGRATION_REQUEST = "DELETE_ACCOUNTING_INTEGRATION_REQUEST";
export const DELETE_ACCOUNTING_INTEGRATION_SUCCESS = "DELETE_ACCOUNTING_INTEGRATION_SUCCESS";
export const DELETE_ACCOUNTING_INTEGRATION_FAILURE = "DELETE_ACCOUNTING_INTEGRATION_FAILURE";

export const CREATE_INTEGRATION_REQUEST = "CREATE_INTEGRATION_REQUEST";
export const CREATE_INTEGRATION_SUCCESS = "CREATE_INTEGRATION_SUCCESS";
export const CREATE_INTEGRATION_FAILURE = "CREATE_INTEGRATION_FAILURE";

export const SET_ACCOUNTING_INTEGRATIONS_FIELD = "SET_ACCOUNTING_INTEGRATIONS_FIELD";
export const setAccountingIntegrationsField = makeGenericActionCreatorTypeSafe<IAccountingIntegrationsReducerState>(SET_ACCOUNTING_INTEGRATIONS_FIELD);

export const CANCEL_ACCOUNT_INTEGRTIONS_CHANGES = "CANCEL_ACCOUNT_INTEGRTIONS_CHANGES";
export const cancelAccountIntegrationChanges = makeActionCreator(CANCEL_ACCOUNT_INTEGRTIONS_CHANGES);

export const fetchAccountIntegrations = (companyId: number) => {
  const getCompanyIntegrationsRequest = new GetCompanyIntegrationsRequest();
  getCompanyIntegrationsRequest.setCompanyId(companyId);
  const counterServiceClient = new CompanyIntegrationServiceClient(ConfigConstants.grpcBaseUrl);
  return {
    types: [FETCH_ACCOUNTING_INTEGRATIONS_REQUEST, FETCH_ACCOUNTING_INTEGRATIONS_SUCCESS, FETCH_ACCOUNTING_INTEGRATIONS_FAILURE],
    callAPI: () => grpcUnaryCall(getCompanyIntegrationsRequest, counterServiceClient, counterServiceClient.getCompanyIntegrations)
  }
}

export const fetchQuickBooksAccounts = (companyId: number) => {
  const getQuickbooksAccountsRequest = new GetQuickBooksAccountsRequest();
    getQuickbooksAccountsRequest.setCompanyId(companyId);
    const accountingIntegrationServiceClient = new AccountingIntegrationServiceClient(ConfigConstants.grpcBaseUrl);
  return {
    types: [FETCH_QUICK_BOOKS_ACCOUNTS_REQUEST, FETCH_QUICK_BOOKS_ACCOUNTS_SUCCESS, FETCH_QUICK_BOOKS_ACCOUNTS_FAILURE],
    callAPI: () => grpcUnaryCall(getQuickbooksAccountsRequest, accountingIntegrationServiceClient, accountingIntegrationServiceClient.getQuickBooksAccounts)
  }
}

export const fetchCompanyQuickBooksAccounts = (companyId: number) => {
  const getCompanyQuickbooksAccountsRequest = new GetCompanyQuickBooksAccountsRequest();
  getCompanyQuickbooksAccountsRequest.setCompanyId(companyId);
    const accountingIntegrationServiceClient = new AccountingIntegrationServiceClient(ConfigConstants.grpcBaseUrl);
  return {
    types: [FETCH_COMPANY_QUICK_BOOKS_ACCOUNTS_REQUEST, FETCH_COMPANY_QUICK_BOOKS_ACCOUNTS_SUCCESS, FETCH_COMPANY_QUICK_BOOKS_ACCOUNTS_FAILURE],
    callAPI: () => grpcUnaryCall(getCompanyQuickbooksAccountsRequest, accountingIntegrationServiceClient, accountingIntegrationServiceClient.getCompanyQuickBooksAccounts)
  }
}

export const deleteAccountingIntegration = (companyId: number, type: IntegrationType) => {
  const deleteAccountingIntegrationRequest = new DeleteIntegrationRequest();
  deleteAccountingIntegrationRequest.setIntegrationType(type);
  deleteAccountingIntegrationRequest.setCompanyId(companyId);
  const accountingIntegrationServiceClient = new AccountingIntegrationServiceClient(ConfigConstants.grpcBaseUrl);
  return {
    types: [DELETE_ACCOUNTING_INTEGRATION_REQUEST, DELETE_ACCOUNTING_INTEGRATION_SUCCESS, DELETE_ACCOUNTING_INTEGRATION_FAILURE],
    callAPI: () => grpcUnaryCall(deleteAccountingIntegrationRequest, accountingIntegrationServiceClient, accountingIntegrationServiceClient.deleteIntegration)
  }
}

export const createWebexIntegration = (companyId: number, authCode: string) => {
  const request = new CreateIntegrationRequest();
  request.setCode(authCode);
  request.setCompanyId(companyId);
  const webexServiceClient = new WebexServiceClient(ConfigConstants.grpcBaseUrl);
  return {
    types: [CREATE_INTEGRATION_REQUEST, CREATE_INTEGRATION_SUCCESS, CREATE_INTEGRATION_FAILURE],
    callAPI: () => grpcUnaryCall(request, webexServiceClient, webexServiceClient.createIntegration)
  }
}

export const deleteWebexIntegration = (companyId: number) => {
  const request = new DeleteIntegrationRequest();
  const webexServiceClient = new WebexServiceClient(ConfigConstants.grpcBaseUrl);
  request.setCompanyId(companyId);
  return {
    types: [DELETE_ACCOUNTING_INTEGRATION_REQUEST, DELETE_ACCOUNTING_INTEGRATION_SUCCESS, DELETE_ACCOUNTING_INTEGRATION_FAILURE],
    callAPI: () => grpcUnaryCall(request, webexServiceClient, webexServiceClient.deleteIntegration)
  }
}

export const createQuickbooksIntegration = (companyId: number, authCode: string, realmId: string) => {
  const createQuickbooksIntegrationRequest = new CreateQuickBooksIntegrationRequest();
  createQuickbooksIntegrationRequest.setCompanyId(companyId);
  createQuickbooksIntegrationRequest.setCode(authCode);
  createQuickbooksIntegrationRequest.setRealmId(realmId);
  const accountingIntegrationServiceClient = new AccountingIntegrationServiceClient(ConfigConstants.grpcBaseUrl);
  return {
    types: [CREATE_INTEGRATION_REQUEST, CREATE_INTEGRATION_SUCCESS, CREATE_INTEGRATION_FAILURE],
    callAPI: () => grpcUnaryCall(createQuickbooksIntegrationRequest, accountingIntegrationServiceClient, accountingIntegrationServiceClient.createQuickBooksIntegration)
  }
}

export const createFreeAgentIntegration = (companyId: number, authCode: string) => {
  const createFreeAgentIntegrationRequest = new CreateQuickBooksIntegrationRequest();
  createFreeAgentIntegrationRequest.setCompanyId(companyId);
  createFreeAgentIntegrationRequest.setCode(authCode);
  const accountingIntegrationServiceClient = new AccountingIntegrationServiceClient(ConfigConstants.grpcBaseUrl);
  return {
    types: [CREATE_INTEGRATION_REQUEST, CREATE_INTEGRATION_SUCCESS, CREATE_INTEGRATION_FAILURE],
    callAPI: () => grpcUnaryCall(createFreeAgentIntegrationRequest, accountingIntegrationServiceClient, accountingIntegrationServiceClient.createFreeAgentIntegration)
  }
}