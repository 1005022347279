import React from "react";
import { AppState } from "../../../../..";
import { useDispatch, useSelector } from "react-redux";
import Panel from "../../../../ui/panel/Panel";
import Text from "../../../../ui/text/Text";
import FormLabel from "../../../../ui/form-label/FormLabel";
import FormGroup from "../../../../ui/form-group/FormGroup";
import Input from "../../../../ui/input/Input";
import { Col, Row } from "react-grid-system";
import Button from "../../../../ui/button/Button";
import Dropdown from "../../../../ui/dropdown/Dropdown";
import { SubscriptionStatusType } from "../../../../../models/Subscriptions";
import "./DeclinedPayments.scss";
import Switch from "../../../../ui/switch/Switch";
import {  updateDunningProcessProps, DunningProcessConnectedPanels } from "../../../../../actions/dunningFailedProcessActions";
import { IEmailAttempt } from "../../../../../models/DunningFailedProcess";
import { allowZeroAndPositiveNumbers } from "../../../../../utils/commonUtils";

export interface IAttemptPanelData {
  attemptType: DunningProcessConnectedPanels,
  title: string,
  content: string,
  customizeEmailLink?: () => void
}

interface IAttemptPaymentPanelsProps {
  attemptData: Array<IAttemptPanelData>,
  statusOptions: Array<any>
}

const AttemptPaymentPanels: React.FC<IAttemptPaymentPanelsProps> = ({ statusOptions, attemptData }) => {
  const dunningData = useSelector((state: AppState) => state.dunningFailedProcessReducer);

  const dispatch = useDispatch<Function>();

  const initialSubscriptionStatusOptions = statusOptions;
  const {minimumTerm, writingOffInvoice, initialPaymentDeclineStatus } = dunningData;
  const currentSubscriptionStatusOptions = initialSubscriptionStatusOptions.filter(data => data.value !== SubscriptionStatusType.ACTIVE);
  
  if (!dunningData) {
    return null;
  }

  const handleSwitchToggle = (value: boolean, idx: number, previousAttempt: IEmailAttempt) => {
    if (updateDunningProcessProps) {
      const attempt = attemptData[idx].attemptType;
      const currentAttempt = dunningData[attempt];
      
      currentAttempt.subscriptionStatusType = attempt === "attemptOne" ? initialPaymentDeclineStatus : previousAttempt.subscriptionStatusType;

      dispatch(updateDunningProcessProps(attempt, { ...currentAttempt, reattempt: value }));
      attemptData.forEach((data, _idx) => {
        const currentAttemptState = dunningData[data.attemptType];

        if (idx < _idx) {
          dispatch(updateDunningProcessProps(data.attemptType, { ...currentAttemptState, reattempt: false }));
        }
      });
    }
  };

  const handleUpdateStatus = (value: SubscriptionStatusType, idx: number) => {
    if (updateDunningProcessProps) {
      const attempt = attemptData[idx].attemptType;

      dispatch(updateDunningProcessProps(attempt, { ...dunningData[attempt], subscriptionStatusType: value }));
      attemptData.forEach((data, _idx) => {
        const currentAttemptState = dunningData[data.attemptType];
        if (idx <= _idx) {
          if (value === SubscriptionStatusType.SUSPENDED) {
            if (currentAttemptState.subscriptionStatusType === SubscriptionStatusType.ACTIVE) {
              dispatch(updateDunningProcessProps(data.attemptType, { ...currentAttemptState, subscriptionStatusType: value }));
            }
            if (writingOffInvoice.subscriptionStatusType === SubscriptionStatusType.ACTIVE) {
              dispatch(updateDunningProcessProps("writingOffInvoice", { ...writingOffInvoice, subscriptionStatusType: value }));
            }
          }
          if (value === SubscriptionStatusType.CANCELLED) {
            dispatch(updateDunningProcessProps(data.attemptType, { ...currentAttemptState, subscriptionStatusType: value }));
            dispatch(updateDunningProcessProps("minimumTerm", { ...minimumTerm, subscriptionStatusType: value }));
            dispatch(updateDunningProcessProps("writingOffInvoice", { ...writingOffInvoice, subscriptionStatusType: value }));
          }
        }
      });
    }
  };

  return (
    <>
      {attemptData.map((data, idx) => {
        const currentAttempt = dunningData[data.attemptType];
        const previousAttempt = dunningData[attemptData[idx === 0 ? 0 : idx - 1].attemptType];
        const disableDropdown = idx !== 0 && previousAttempt.subscriptionStatusType === SubscriptionStatusType.CANCELLED;
        const attemptOptions = (idx !== 0 && previousAttempt.subscriptionStatusType === SubscriptionStatusType.SUSPENDED) || dunningData.initialPaymentDeclineStatus === SubscriptionStatusType.SUSPENDED ? currentSubscriptionStatusOptions : initialSubscriptionStatusOptions;
        const dropdownValue = initialSubscriptionStatusOptions.filter(status => status.value === currentAttempt.subscriptionStatusType);

        if (idx !== 0 && !previousAttempt.reattempt) {
          return null;
        }

        return (
          <Panel key={data.attemptType} className="dunning-declined-payments__panel" title={data.title}>
            <Text content={data.content}></Text>
            <Button
              className="dunning-declined-payments__floating-button"
              icon="fas fa-edit"
              title="DUNNING_FAILED_PAYMENTS_BUTTON_CUSTOMIZE_EMAIL"
              type="button"
              buttonType="add"
              id={`customize-email-${data.attemptType}`}
              disabled={!currentAttempt.reattempt}
              onClick={data.customizeEmailLink}
            />
            <FormGroup>
              <Row align="center">
                <Col md={2}>
                  <FormLabel noMargin target={`reattempt-payment-${data.attemptType}`} content="DUNNING_FAILED_PAYMENTS_LABEL_REATTEPT_PAYMENT" />
                </Col>
                <Col md={10}>
                  <Switch
                    id={`reattempt-payment-${data.attemptType}`}
                    checked={currentAttempt.reattempt}
                    onChange={() => {
                      handleSwitchToggle(!currentAttempt.reattempt, idx, previousAttempt as IEmailAttempt);
                    }}
                  />
                </Col>
              </Row>
            </FormGroup>
            {currentAttempt.reattempt && (
              <>
                <FormGroup>
                  <Row align="center">
                    <Col md={2}>
                      <FormLabel noMargin target={`hours-reattempt-${data.attemptType}`} content="DUNNING_FAILED_PAYMENTS_LABEL_HOURS_UNTIL_REATTEMPT" />
                    </Col>
                    <Col md={10}>
                      <Input
                        id={`hours-reattempt-${data.attemptType}`}
                        type="number"
                        value={currentAttempt.hours}
                        allowDecimal={false}
                        onChange={(evt: any) => updateDunningProcessProps && dispatch(updateDunningProcessProps(data.attemptType, { ...currentAttempt, hours: allowZeroAndPositiveNumbers(evt.target.value) }))}
                      />
                    </Col>
                  </Row>
                </FormGroup>
                <Row align="center">
                  <Col md={2}>
                    <FormLabel noMargin target={`subscription-failure-${data.attemptType}`} content="DUNNING_FAILED_PAYMENTS_LABEL_STATUS_ON_FAILURE" />
                  </Col>
                  <Col md={10}>
                    <Dropdown
                      isSearchable={false}
                      className="dunning-declined-payments__dropdown"
                      id={`subscription-failure-${data.attemptType}`}
                      value={dropdownValue}
                      options={attemptOptions}
                      isDisabled={disableDropdown || dunningData.initialPaymentDeclineStatus === SubscriptionStatusType.CANCELLED}
                      onChange={(status: any) => handleUpdateStatus(status.value, idx)}
                    />
                  </Col>
                </Row>
              </>
            )}
          </Panel>
        );
      })}
    </>
  );
};


export default AttemptPaymentPanels;
