import React from "react";
import { NavLink, Redirect, useRouteMatch } from "react-router-dom";
import counterpart from "counterpart";
import TabbedContainer from "../../../../ui/tabbed-container/TabbedContainer";
import { PrivateRoute } from "../../../../../containers/AuthRoutes";
import "./BrandCustomizationTabs.scss";
import CheckoutAccountManagement from "../checkout-account-management/CheckoutAccountManagement";
import EmailInvoices from "../email-invoices/EmailInvoices";
import history from "../../../../../utils/history";

const BrandCustomizationTabs: React.FC = () => {
  const match = useRouteMatch();
  const tabArray = [
    { label: "BRAND_CUSTOMIZATION_CHECKOUT_ACCOUNT_MANAGEMENT", navlink: "/checkout-account-management" },
    { label: "BRAND_CUSTOMIZATION_CHECKOUT_EMAIL_INVOICES", navlink: "/email-invoices" }
  ];


  return (
    <div className="brand-customization-tabs">
      <TabbedContainer type="submenu">
        {tabArray.map((tab, idx) => (
          <NavLink
            isActive={(match, location) => (!match || !location ? false : history.location.pathname.includes(`${tab.navlink}`))}
            key={idx}
            activeClassName="active"
            to={`${match?.url}${tab.navlink}`}
          >
            <li>{counterpart(tab.label)}</li>
          </NavLink>
        ))}
      </TabbedContainer>


      <PrivateRoute
        render={() => (
          <div className="brand-customization-tabs__content">
            <PrivateRoute path={`${match?.url}/checkout-account-management`} component={CheckoutAccountManagement} />
            <PrivateRoute path={`${match?.url}/email-invoices`} component={EmailInvoices} />

          </div>
        )}
      />
      <PrivateRoute 
        exact path="/configuration/brand-customization" 
        render={() => <Redirect to="/configuration/brand-customization/checkout-account-management" />} 
      />
    </div>
  );
};

export default BrandCustomizationTabs;