import React from "react"
import { AppState } from "../../..";
import { useDispatch, useSelector } from "react-redux";
import "./SubscriptionShippingAddress.scss";
import Panel from "../../../components/ui/panel/Panel";
import Text from "../../../components/ui/text/Text";
import { Row, Col } from "react-grid-system";
import Button from "../../ui/button/Button";
import { setShowShippingAddressUpdateModal } from "../../../actions/shippingAddressActions";

const SubscriptionShippingAddress: React.FC = () => {
  const shippingAddressData = useSelector((state: AppState) => state.shippingAddressReducer);

  const dispatch = useDispatch<Function>();

  const { addressLine1, addressLine2, city, state, postCode, country } = shippingAddressData;

  return (
    <Panel className="subscription-shipping-address__panel" title="UPDATE_SUBSCRIPTION_SHIPPING_ADDRESS_PANEL_TITLE">
      <Button
        className="subscription-shipping-address__floating-button"
        icon="fas fa-edit"
        title="UPDATE_SUBSCRIPTION_EDIT_BUTTON_TEXT"
        type="button"
        buttonType="add"
        id="customize-email"
        onClick={() => dispatch(setShowShippingAddressUpdateModal(true))}
      />
      <Row align="center">
        <Col xs={12}>
          {/*!addressLine1 && <Text content="UPDATE_SUBSCRIPTION_ADDRESS_LINE_ONE_TEXT" noMargin />*/}
          {addressLine1 && <Text content={addressLine1} shouldTranslate={false} noMargin />}
        </Col>
      </Row>
      <Row align="center">
        <Col xs={12}>
          {/*!addressLine2 && <Text content="UPDATE_SUBSCRIPTION_ADDRESS_LINE_TWO_TEXT" noMargin />*/}
          {addressLine2 && <Text content={addressLine2} shouldTranslate={false} noMargin />}
        </Col>
      </Row>
      <Row align="center">
        <Col xs={12}>
          {/*!city && <Text content="UPDATE_SUBSCRIPTION_CITY_TEXT" noMargin />*/}
          {city && <Text content={city} shouldTranslate={false} noMargin />}
        </Col>
      </Row>
      <Row align="center">
        <Col xs={3}>
          {/*!state && <Text content="UPDATE_SUBSCRIPTION_STATE_TEXT" noMargin />*/}
          {state && <Text content={state} shouldTranslate={false} noMargin />}
        </Col>
      </Row>
      <Row align="center">
        <Col xs={3}>
          {/*!postCode && <Text content="UPDATE_SUBSCRIPTION_POST_CODE_TEXT" noMargin />*/}
          {postCode && <Text content={postCode} shouldTranslate={false} noMargin />}
        </Col>
      </Row>
      <Row align="center">
        <Col xs={3}>
          {/*!country && <Text content="UPDATE_SUBSCRIPTION_COUNTRY_TEXT" noMargin />*/}
          {country && <Text content={country} shouldTranslate={false} noMargin />}
        </Col>
      </Row>
    </Panel>
  )
}

export default SubscriptionShippingAddress;