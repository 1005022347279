import React, { useState, useEffect } from "react";
import Panel from "../../../ui/panel/Panel";
import "./ChangeCompanyDomain.scss";
import Text from "../../../ui/text/Text";
import Table from "../../../ui/table/Table";
import { Row, Col } from "react-grid-system";
import Input from "../../../ui/input/Input";
import FormLabel from "../../../ui/form-label/FormLabel";
import Button from "../../../ui/button/Button";
import { IsCompanyDomainUsedRequest, IsCompanyDomainUsedResponse } from "../../../../utils/grpc/generated/Billsby.Protos/core/public/company/company_pb";
import { ConfigConstants } from "../../../../utils/config";
import { grpcUnaryCall } from "../../../../utils/grpc/grpcUtils";
import { CompanyServiceClient } from "../../../../utils/grpc/generated/Billsby.Protos/core/public/company/CompanyServiceClientPb";
import { CompanyServiceClient as CompanyServiceUpdateClient} from "../../../../utils/grpc/generated/Billsby.Protos/core/private/companies/CompanyServiceClientPb";
import { UpdateCompanyDomainRequest } from "../../../../utils/grpc/generated/Billsby.Protos/core/private/companies/company_pb";
import { useSelector } from "react-redux";
import { AppState } from "../../../..";
import ErrorNotification from "../../../ui/error-notification/ErrorNotification";
import { refreshToken } from "../../../../utils/authUtils";

const ChangeCompanyDomain: React.FC = () => {
  const currentCompanyId = useSelector((state: AppState) => state.auth.currentCompanyId);

  const [newCompanyDomain, setNewCompanyDomain] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [isInvalidCompanyDomain, setIsInvalidCompanyDomain] = useState(false);
  const [isvalidatingCompanyDomain, setIsValidatingCompanyDomain] = useState(false);

  const validateCompanyDomain = async () => {
    setIsValidatingCompanyDomain(true);
    setIsInvalidCompanyDomain(false);

    try {
      const isCompanyDomainUsedRequest = new IsCompanyDomainUsedRequest();
      isCompanyDomainUsedRequest.setCompanyDomain(newCompanyDomain);
      const companyServiceClient = new CompanyServiceClient(ConfigConstants.grpcBaseUrl);
      const response = await grpcUnaryCall(isCompanyDomainUsedRequest, companyServiceClient, companyServiceClient.isCompanyDomainUsed) as IsCompanyDomainUsedResponse;
      setIsInvalidCompanyDomain(response.getIsUsed());
    } catch (err) {
      console.log(err);
      setIsInvalidCompanyDomain(true);
    } finally {
      setIsValidatingCompanyDomain(false);
    }
  }

  // HIDE FOR NOW
  // const fetchSubdomainIsUsed = debounce(async () => {
  //   try {
  //     const isCompanyDomainUsedRequest = new IsCompanyDomainUsedRequest();
  //     isCompanyDomainUsedRequest.setCompanyDomain(newCompanyDomain);
  //     const companyServiceClient = new CompanyServiceClient(ConfigConstants.grpcBaseUrl);
  //     const response = await grpcUnaryCall(isCompanyDomainUsedRequest, companyServiceClient, companyServiceClient.isCompanyDomainUsed) as IsCompanyDomainUsedResponse;
  //     setIsNewCompanyDomainUsed(response.getIsUsed());
  //   }
  //   catch (err) {
  //     setIsNewCompanyDomainUsed(true);
  //   }
  // }, 500);
  

  // useEffect(() => {
  //   if (newCompanyDomain) {
  //     fetchSubdomainIsUsed();
  //   }
  // }, [newCompanyDomain]);

  useEffect(() => {
    if(!newCompanyDomain) {
      return;
    }

    const timeout = setTimeout(() => {
      validateCompanyDomain()
    }, 500)

    return () => clearTimeout(timeout);
  }, [newCompanyDomain]);

  const onSubmit = async () => {
    const isLocalHost = ConfigConstants.reactAppEnvironment === "Localhost";
    const companyServiceUpdateClient = new CompanyServiceUpdateClient(ConfigConstants.grpcBaseUrl);
    const updateCompanyDomainRequest = new UpdateCompanyDomainRequest();
    updateCompanyDomainRequest.setCompanyId(Number(currentCompanyId));
    updateCompanyDomainRequest.setCompanyDomain(newCompanyDomain);
    setIsLoading(true);
    setHasError(false);

    try {
      await grpcUnaryCall(updateCompanyDomainRequest, companyServiceUpdateClient, companyServiceUpdateClient.updateCompanyDomain);
      await refreshToken(currentCompanyId as number);
      window.location.replace(`//${newCompanyDomain}${ConfigConstants.billsbyDomain}${isLocalHost ? `:${process.env.REACT_APP_PORT}` : ""}`);
    }
    catch (err) {
      setHasError(true);
    }
    finally {
      setIsLoading(false);
    }
  }

  return (
    <div className="change-company-domain">
      <ErrorNotification
        title="CHANGE_COMPANY_SUBDOMAIN_ERROR"
        showAlert={hasError}
        isModal
        onClose={() => setHasError(false)}
      />
      <Panel title="CHANGE_COMPANY_SUBDOMAIN_PANEL1_TITLE" className="change-company-domain__header">
        <Text content="CHANGE_COMPANY_SUBDOMAIN_PANEL1_CONTENT" noMargin />
      </Panel>
      <Panel>
        <Text content="CHANGE_COMPANY_SUBDOMAIN_PANEL2_TITLE" />
        <Table>
          <tbody>
            <tr>
              <Text component="th" content="CHANGE_COMPANY_SUBDOMAIN_PANEL2_CONTENT_API" />
              <Text component="td" content="CHANGE_COMPANY_SUBDOMAIN_PANEL2_CONTENT_API_DESC" />
            </tr>
            <tr>
              <Text component="th" content="CHANGE_COMPANY_SUBDOMAIN_PANEL2_CONTENT_EMBEDCODE" />
              <Text component="td" content="CHANGE_COMPANY_SUBDOMAIN_PANEL2_CONTENT_EMBEDCODE_DESC" />
            </tr>
            <tr>
              <Text component="th" content="CHANGE_COMPANY_SUBDOMAIN_PANEL_CONTENT_EMAILLOGS" />
              <Text component="td" content="CHANGE_COMPANY_SUBDOMAIN_PANEL2_CONTENT_EMAILLOGS_DESC" />
            </tr>
            <tr>
              <Text component="th" content="CHANGE_COMPANY_SUBDOMAIN_PANEL2_CONTENT_SUBDOMAIN" />
              <Text component="td" content="CHANGE_COMPANY_SUBDOMAIN_PANEL2_CONTENT_SUBDOMAIN_DESC" />
            </tr>
            <tr>
              <Text component="th" content="CHANGE_COMPANY_SUBDOMAIN_PANEL2_CONTENT_PLUGINS" />
              <Text component="td" content="CHANGE_COMPANY_SUBDOMAIN_PANEL2_CONTENT_PLUGINS_DESC" />
            </tr>
          </tbody>
        </Table>
      </Panel>

      <Panel>
        <Text content="CHANGE_COMPANY_SUBDOMAIN_PANEL3_TITLE" />
        <Row align="center">
          <Col xs={2}>
            <FormLabel target="company-subdomain" content="CHANGE_COMPANY_SUBDOMAIN_PANEL3_SUBDOMAIN" noMargin />
          </Col>
          <Col xs={10}>
            <div className="new-subdomain">
              <Input
                id="company-subdomain"
                placeholder="mycompany"
                required
                value={newCompanyDomain}
                onChange={evt => {
                  // switch to lowercase because the backend doesn't save a capitalized letter in the company domain
                  setIsValidatingCompanyDomain(true);
                  setNewCompanyDomain(evt.target.value.toLowerCase().replace(/[^a-z0-9]/gi, ""));
                }}
                isError={isInvalidCompanyDomain}
              />
              <FormLabel target="" content="CHANGE_COMPANY_SUBDOMAIN_PANEL3_BILLSBYDOTCOM" noMargin />
            </div>
          </Col>
        </Row>
        <Row align="center">
          <Col xs={2} />
          <Col xs={10}>
            {
              isInvalidCompanyDomain && (
                <FormLabel
                  className="change-company-domain__error"
                  target=""
                  content="CHANGE_COMPANY_SUBDOMAIN_PANEL3_SUBDOMAIN_ERROR"
                  noMargin
                />
              )
            }
          </Col>
        </Row>
      </Panel>

      <Button
        id="change-company-domain"
        title="CHANGE_COMPANY_SUBDOMAIN_PANEL3_CONFIRM"
        isLoading={isLoading}
        onClick={onSubmit}
        isFullWidth
        disabled={isLoading || isvalidatingCompanyDomain || isInvalidCompanyDomain || !newCompanyDomain}
      />

    </div>
  )
}

export default ChangeCompanyDomain;