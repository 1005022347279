import React from "react";
import "./SandboxPreLive.scss";

import Panel from "../../../components/ui/panel/Panel";
import Text from "../../../components/ui/text/Text";
import history from "../../../utils/history";
import PreLiveMenu from "../../../components/menu/pre-live-menu/PreLiveMenu";

interface ISandboxPreLiveProps {
  onRequestClose: Function
}

const SandboxPreLive = (props: ISandboxPreLiveProps) => {
  const handleGoLiveButton = () => {
    history.push("/launch-checklist");
    props.onRequestClose();
  }

  return (
    <div>
      <Panel className="sandbox_prelive_panel" title="SANDBOX_PRE_LIVE_TITLE">
        <div className="sandbox_prelive_panel_content">
          <Text content="SANDBOX_PRE_LIVE_CONTENT_AFTER_YOU_LUNCH" />
          <PreLiveMenu onRequestClose={() => handleGoLiveButton} menuName="sandbox-pre-live"/>
        </div>
      </Panel>
    </div>
  )
}

export default SandboxPreLive;