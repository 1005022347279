import counterpart from "counterpart";

export const supportedLanguages = new Map<string, NodeRequire>([
  ["en-us", require("./en-us.json")],
  ["es-es", require("./es-es.json")]
]);


export function registerTranslations() {
  counterpart.registerTranslations("en", require("counterpart/locales/en"));
  // load our own translations
  supportedLanguages.forEach((langFile, key) => {
    counterpart.registerTranslations(key, langFile);
  })

  //default locale
  counterpart.setLocale(supportedLanguages.keys().next().value);
}
