import API from "../utils/API";
import {
  makeActionCreator,
  makeGenericActionCreatorTypeSafe
} from "../utils/reduxUtils";
import { PAGINATION_PAGE_SIZE } from "../utils/constants";
import { InvoiceStatus } from "../models/Invoices";
import { IInvoicesReducer } from "../reducers/invoicesReducer";

export const FETCH_INVOICES_REQUEST = "FETCH_INVOICES_REQUEST";
export const FETCH_INVOICES_SUCCESS = "FETCH_INVOICES_SUCCESS";
export const FETCH_INVOICES_FAILURE = "FETCH_INVOICES_FAILURE";
export const RESET_INVOICES = "RESET_INVOICES";
export const SET_INVOICES_FIELD = "SET_INVOICES_FIELD";

export const setInvoicesField = makeGenericActionCreatorTypeSafe<IInvoicesReducer>(SET_INVOICES_FIELD);
export const resetInvoicesList = makeActionCreator(RESET_INVOICES, "payload");

export const fetchInvoices = function(
  companyDomain: string,
  pageNumber: number,
  pageSize: number = PAGINATION_PAGE_SIZE,
  search: string = "",
  invoiceStatus?: InvoiceStatus,
  isDateSortDescendingOrder: boolean = true
) {
  return {
    types: [
      FETCH_INVOICES_REQUEST,
      FETCH_INVOICES_SUCCESS,
      FETCH_INVOICES_FAILURE
    ],
    callAPI: () =>
      API.fetchInvoices(companyDomain, pageNumber, pageSize, search, invoiceStatus, isDateSortDescendingOrder)
  };
};

