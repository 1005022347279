import React, { useEffect, useState } from "react";
import { IAuthReducerState } from "../../../../reducers/authReducer";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../../../..";
import NavigationBar from "../../../ui/navigation-bar/NavigationBar";
import history from "../../../../utils/history";
import PageWrapper from "../../../ui/layout/PageWrapper";
import Panel from "../../../ui/panel/Panel";
import Text from "../../../ui/text/Text";
import { Row, Col } from "react-grid-system";
import Button from "../../../ui/button/Button";
import Switch from "../../../ui/switch/Switch";
import FormGroup from "../../../ui/form-group/FormGroup";
import FormLabel from "../../../ui/form-label/FormLabel";
import ProgressIndicator from "../../../ui/progress-indicator/ProgressIndicator";
import "./InformationFields.scss";
import Checkbox from "../../../ui/checkbox/Checkbox";
import counterpart from "counterpart";
import { CollectCompanyNameType, GetCollectCompanyNameRequest, GetCollectCompanyNameResponse, UpdateCollectCompanyNameRequest } from "../../../../utils/grpc/generated/Billsby.Protos/core/private/companies/company_pb";
import { CompanyServiceClient } from "../../../../utils/grpc/generated/Billsby.Protos/core/private/companies/CompanyServiceClientPb";
import { ConfigConstants } from "../../../../utils/config";
import { grpcUnaryCall } from "../../../../utils/grpc/grpcUtils";
import ErrorNotification from "../../../ui/error-notification/ErrorNotification";


const InformationFields: React.FC = () => {
  const { currentCompanyId } = useSelector<AppState, IAuthReducerState>(state => state.auth)
  const dispatch = useDispatch<Function>()
  const [collectCompanyNameType, setCollectCompanyNameType] = useState<CollectCompanyNameType>(CollectCompanyNameType.DO_NOT_COLLECT);
  const [isLoading, setIsLoading] = useState(true);
  const [isUpdating, setIsUpdating] = useState(false);
  const [hasError, setHasError] = useState(false);
  const companyServiceClient = new CompanyServiceClient(ConfigConstants.grpcBaseUrl);

  const fetchData = async () => {
    try {
      const request = new GetCollectCompanyNameRequest();
      currentCompanyId && request.setCompanyId(currentCompanyId);
      const response = await grpcUnaryCall(request, companyServiceClient, companyServiceClient.getCollectCompanyName) as GetCollectCompanyNameResponse;
      setCollectCompanyNameType(response.getCollectCompanyNameType());
    } catch (err) {
      console.log(err);
    } finally {
      setIsLoading(false);
    }
  }

  const handleUpdateCollectCompanyName = async () => {
    setIsUpdating(true);
    setHasError(false);

    try {
      const request = new UpdateCollectCompanyNameRequest();
      currentCompanyId && request.setCompanyId(currentCompanyId);
      request.setCollectCompanyNameType(collectCompanyNameType)
      await grpcUnaryCall(request, companyServiceClient, companyServiceClient.updateCollectCompanyName);

    } catch (err) {
      console.log(err)
      setHasError(true);
    } finally {
      setIsUpdating(false);
    }
  }

  useEffect(() => {
    fetchData();
  }, []);

  const renderOptions = () => {
    if (collectCompanyNameType === CollectCompanyNameType.DO_NOT_COLLECT) {
      return null
    }

    return (
      <FormGroup>
        <Checkbox id="optional" value="optional" content={counterpart("INFO_FIELDS_CONFIG_OPT1")} checked={collectCompanyNameType === CollectCompanyNameType.OPTIONAL} onClick={() => setCollectCompanyNameType(CollectCompanyNameType.OPTIONAL)}></Checkbox>
        <Checkbox id="compulsary" value="compulsary" content={counterpart("INFO_FIELDS_CONFIG_OPT2")} checked={collectCompanyNameType === CollectCompanyNameType.COMPULSORY} onClick={() => setCollectCompanyNameType(CollectCompanyNameType.COMPULSORY)}></Checkbox>
      </FormGroup>
    )
  }

  if (isLoading) {
    return <ProgressIndicator color="blue" coverage='full-content' />
  }

  return (
    <div className="information-fields-config">
      <NavigationBar pageTitle="" previousPageTitle="CONFIGURATION_PAGE_TITLE" previousPageCb={() => history.push("/configuration")} />

      <PageWrapper>
        <Panel title="INFO_FIELDS_CONFIG_TITLE" className="information-fields-config__header">
          <Text content="INFO_FIELDS_CONFIG_DESCRIPTION" noMargin />
        </Panel>

        <Panel title="INFO_FIELDS_CONFIG_COMPANY_NAME">
          <Text content="INFO_FIELDS_CONFIG_CONTENT1" />
          <FormGroup>
            <Row align="center">
              <Col md={2}>
                <FormLabel noMargin target="collect-company-name" content="INFO_FIELDS_CONFIG_TOGGLE" />
              </Col>
              <Col md={10}>
                <Switch
                  id="collect-company-name"
                  checked={collectCompanyNameType !== CollectCompanyNameType.DO_NOT_COLLECT}
                  onChange={() => setCollectCompanyNameType(collectCompanyNameType === CollectCompanyNameType.DO_NOT_COLLECT ? CollectCompanyNameType.OPTIONAL : CollectCompanyNameType.DO_NOT_COLLECT)}
                />
              </Col>
            </Row>
          </FormGroup>
          {renderOptions()}

          <Row align="center">
            <Col md={9}>
              <Button
                onClick={handleUpdateCollectCompanyName}
                isLoading={isUpdating}
                disabled={false}
                type="submit"
                isFullWidth
                buttonType="general"
                id="save-information-fields"
                title="INFO_FIELDS_CONFIG_BTN"
              />
            </Col>
            <Col md={3}>
              <Button
                onClick={fetchData}
                disabled={false}
                isFullWidth
                id="cancel-information-fields"
                title="INFO_FIELDS_CONFIG_CANCEL"
                buttonType="error"
              />
            </Col>
          </Row>
        </Panel>

        <ErrorNotification
          showAlert={hasError}
          title="GENERIC_ERROR"
          subtitle="GENERIC_ERROR"
        />
      </PageWrapper>
    </div>
  )
}


export default InformationFields