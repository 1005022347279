import React from "react";
import "./SandboxTrial.scss";

import Panel from "../../../components/ui/panel/Panel";
import Text from "../../../components/ui/text/Text";


const SandboxTrial = () => {
  return (
    <div>
      <Panel className="sandbox_trial_panel" title="SANDBOX_TRIAL_TITLE">
        <div className="sandbox_trial_panel_content">
          <Text content="SANDBOX_TRIAL_CONTENT_TEXT" noMargin />
        </div>
      </Panel>
    </div>
  )
}

export default SandboxTrial;