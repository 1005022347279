import { makeActionCreator, makeGenericActionCreatorTypeSafe } from "../utils/reduxUtils";
import { ExitReasonsGraphDataRequest, GetExitReasonsRequest } from "../utils/grpc/generated/Billsby.Protos/billing/private/report/report_pb";
import { ReportServiceClient } from "../utils/grpc/generated/Billsby.Protos/billing/private/report/ReportServiceClientPb";
import { grpcUnaryCall } from "../utils/grpc/grpcUtils";
import moment from "moment";
import { Timestamp } from "google-protobuf/google/protobuf/timestamp_pb";
import { IExitReasonsState } from "../reducers/exitReasonsReducer";
import { Int32Value } from "google-protobuf/google/protobuf/wrappers_pb";
import { ConfigConstants } from "../utils/config";

export const FETCH_EXIT_REASONS_REPORT_SUCCESS = "FETCH_EXIT_REASONS_REPORT_SUCCESS";
export const FETCH_EXIT_REASONS_REPORT_FAILURE = "FETCH_EXIT_REASONS_REPORT_FAILURE";
export const FETCH_EXIT_REASONS_REPORT_REQUEST = "FETCH_EXIT_REASONS_REPORT_REQUEST";

export const FETCH_EXIT_REASONS_REPORT_GRAPH_DATA_SUCCESS = "FETCH_EXIT_REASONS_REPORT_GRAPH_DATA_SUCCESS";
export const FETCH_EXIT_REASONS_REPORT_GRAPH_DATA_FAILURE = "FETCH_EXIT_REASONS_REPORT_GRAPH_DATA_FAILURE";
export const FETCH_EXIT_REASONS_REPORT_GRAPH_DATA_REQUEST = "FETCH_EXIT_REASONS_REPORT_GRAPH_DATA_REQUEST";

export const FETCH_CURRENT_MONTH_EXIT_REASONS_REPORT_SUCCESS = "FETCH_CURRENT_MONTH_EXIT_REASONS_REPORT_SUCCESS";
export const FETCH_CURRENT_MONTH_EXIT_REASONS_REPORT_FAILURE = "FETCH_CURRENT_MONTH_EXIT_REASONS_REPORT_FAILURE";
export const FETCH_CURRENT_MONTH_EXIT_REASONS_REPORT_REQUEST = "FETCH_CURRENT_MONTH_EXIT_REASONS_REPORT_REQUEST";

export const SET_EXIT_REASONS_REPORT_FIELD = "SET_EXIT_REASONS_REPORT_FIELD";
export const RESET_EXIT_REASONS_REPORT = "RESET_EXIT_REASONS_REPORT";

export const resetExitReasonsReport = makeActionCreator(RESET_EXIT_REASONS_REPORT, "payload");
export const setExitReasonsReportFields = makeGenericActionCreatorTypeSafe<IExitReasonsState>(SET_EXIT_REASONS_REPORT_FIELD);

export const fetchExitReasonsReportGraphData = function (companyDomain: string, startDate: string, endDate: string, productId: number | undefined) {
  const getExitReasonsGraphData = new ExitReasonsGraphDataRequest();
  const startDateTimestamp = new Timestamp();
  const endDateTimeStamp = new Timestamp();
  const productIdInt32 = new Int32Value();

  startDateTimestamp.fromDate(moment.utc(startDate, "MMM YYYY").startOf("month").toDate());
  endDateTimeStamp.fromDate(moment.utc(endDate, "MMM YYYY").endOf("month").toDate());
  productId && productIdInt32.setValue(productId);

  getExitReasonsGraphData.setCompanyDomain(companyDomain);
  getExitReasonsGraphData.setDateFrom(startDateTimestamp);
  getExitReasonsGraphData.setDateTo(endDateTimeStamp);
  getExitReasonsGraphData.setProductId(productId ? productIdInt32 : undefined);
  const reportServiceClient = new ReportServiceClient(ConfigConstants.grpcBaseUrl);
  return ({
    types: [FETCH_EXIT_REASONS_REPORT_GRAPH_DATA_REQUEST, FETCH_EXIT_REASONS_REPORT_GRAPH_DATA_SUCCESS, FETCH_EXIT_REASONS_REPORT_GRAPH_DATA_FAILURE],
    callAPI: () => grpcUnaryCall(getExitReasonsGraphData, reportServiceClient, reportServiceClient.getExitReasonsGraphData)
  })
}

export const fetchExitReasonsReportList = function (companyDomain: string, page: number, startDate: string, endDate: string, productId: number | undefined) {
  const getExitReasonsListData = new GetExitReasonsRequest();
  const startDateTimestamp = new Timestamp();
  const endDateTimeStamp = new Timestamp();
  const productIdInt32 = new Int32Value();

  startDateTimestamp.fromDate(moment.utc(startDate, "MMM YYYY").startOf("month").toDate());
  endDateTimeStamp.fromDate(moment.utc(endDate, "MMM YYYY").endOf("month").toDate());
  productId && productIdInt32.setValue(productId);

  getExitReasonsListData.setCompanyDomain(companyDomain);
  getExitReasonsListData.setPage(page);
  getExitReasonsListData.setPageSize(10);
  getExitReasonsListData.setDateFrom(startDateTimestamp);
  getExitReasonsListData.setDateTo(endDateTimeStamp);
  getExitReasonsListData.setProductId(productId ? productIdInt32 : undefined);
  const reportServiceClient = new ReportServiceClient(ConfigConstants.grpcBaseUrl);

  return ({
    types: [FETCH_EXIT_REASONS_REPORT_REQUEST, FETCH_EXIT_REASONS_REPORT_SUCCESS, FETCH_EXIT_REASONS_REPORT_FAILURE],
    callAPI: () => grpcUnaryCall(getExitReasonsListData, reportServiceClient, reportServiceClient.getExitReasons)
  })
}
