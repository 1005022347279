import { makeActionCreator, makeGenericActionCreatorTypeSafe, makeGenericActionCreator } from "../utils/reduxUtils";
import API from "../utils/API";
import { IBaseProduct, IBasePlan, IProductLight, IPricingModel, VisibilityType, ILinkedAddOn, ILinkedAllowance } from "../models/Product";
import { PAGINATION_PAGE_SIZE } from "../utils/constants";
import { IProductPlanCycleReducerState } from "../reducers/productPlanCycleReducer";
import { IPlanReducer } from "../reducers/plansReducer";

/**********************  SYNCHRONOUS ACTIONS ********************/

// PRODUCT SCREEN
export const SET_SEARCHED_PRODUCT_NAME = "SET_SEARCHED_PRODUCT_NAME";
export const setSearchedProductName = makeActionCreator(SET_SEARCHED_PRODUCT_NAME, "payload");
export const SET_PRODUCT_NAME = "SET_PRODUCT_NAME";
export const setProductName = makeActionCreator(SET_PRODUCT_NAME, "payload");
export const SET_PRODUCT_DISPLAY_NAME = "SET_DISPLAY_NAME";
export const setProductDisplayName = makeActionCreator(SET_PRODUCT_DISPLAY_NAME, "payload");
export const SET_PROUDUCT_DESCRIPTION = "SET_PRODUCT_DESCRIPTION";
export const setProductDescription = makeActionCreator(SET_PROUDUCT_DESCRIPTION, "payload");
export const SET_IS_PRODUCT_IMAGE_ENABLED = "SET_IS_PRODUCT_IMAGE_ENABLED";
export const setIsProductImageEnabled = makeActionCreator(SET_IS_PRODUCT_IMAGE_ENABLED, "payload");
export const SET_PRODUCT_CURRENCY = "SET_PRODUCT_CURRENCY";
export const setProductCurrency = makeActionCreator(SET_PRODUCT_CURRENCY, "payload");
export const SET_PRODUCT_TYPE = "SET_PRODUCT_TYPE";
export const setProductType = makeActionCreator(SET_PRODUCT_TYPE, "payload");
export const SET_PRODUCT_FORM_COLLAPSED = "SET_PRODUCT_FORM_COLLAPSED";
export const setProductFormCollapsed = makeActionCreator(SET_PRODUCT_FORM_COLLAPSED, "payload");
export const SET_PRODUCT_FORM_HAS_ERROR = "SET_PRODUCT_FORM_HAS_ERROR";
export const setProductFormHasError = makeActionCreator(SET_PRODUCT_FORM_HAS_ERROR, "stageIndex", "value");
export const SET_PRODUCT_FORM_COMPLETED = "SET_PRODUCT_FORM_COMPLETED";
export const setProductFormCompleted = makeActionCreator(SET_PRODUCT_FORM_COMPLETED, "stageIndex", "value");
export const SET_PRODUCT_VISIBILITY = "SET_PRODUCT_VISIBILITY";
export const setProductVisibility = makeActionCreator(SET_PRODUCT_VISIBILITY, "payload");
export const SET_PRODUCT_ALERT_EMAIL = "SET_PRODUCT_ALERT_EMAIL";
export const setProductAlertEmail = makeActionCreator(SET_PRODUCT_ALERT_EMAIL, "payload");
export const SET_PRODUCT_ERROR = "SET_PRODUCT_ERROR";
export const setProductError = makeActionCreator(SET_PRODUCT_ERROR, "payload");

export const SET_PRODUCT_FOR_EDITING = "SET_PRODUCT_FOR_EDITING";
export const setProductForEditing = makeActionCreator(SET_PRODUCT_FOR_EDITING, "payload");
export const RESET_PRODUCT_FOR_EDITING = "RESET_PRODUCT_FOR_EDITING";
export const resetProductForEditing = makeActionCreator(RESET_PRODUCT_FOR_EDITING, "payload");
export const SET_PRODUCT_HELP_TITLE = "SET_PRODUCT_HELP_TITLE";
export const setProductHelpTitle = makeActionCreator(SET_PRODUCT_HELP_TITLE, "payload");
export const SET_PRODUCT_HELP_CONTENT = "SET_PRODUCT_HELP_CONTENT";
export const setProductHelpContent = makeActionCreator(SET_PRODUCT_HELP_CONTENT, "payload");
export const SET_PRODUCT_SETTINGS_HELP_TITLE = "SET_PRODUCT_SETTINGS_HELP_TITLE";
export const setProductSettingsHelpTitle = makeActionCreator(SET_PRODUCT_SETTINGS_HELP_TITLE, "payload");
export const SET_PRODUCT_SETTINGS_HELP_CONTENT = "SET_PRODUCT_SETTINGS_HELP_CONTENT";
export const setProductSettingsHelpContent = makeActionCreator(SET_PRODUCT_SETTINGS_HELP_CONTENT, "payload");
export const SET_PRODUCT_ADDRESS_COLLAPSED = "SET_PRODUCT_ADDRESS_COLLAPSED";
export const setProductAddressCollapsed = makeActionCreator(SET_PRODUCT_ADDRESS_COLLAPSED, "payload");
export const SET_IS_CUSTOMFIELDS_OPENED = "SET_IS_CUSTOMFIELDS_OPENED";
export const setIsCustomFieldsOpened = makeActionCreator(SET_IS_CUSTOMFIELDS_OPENED, "payload");
export const SET_IS_ADDITIONAL_EMAIL_REQUIRED = "SET_IS_ADDITIONAL_EMAIL_REQUIRED";
export const setIsAdditionalEmailRequired = makeActionCreator(SET_IS_ADDITIONAL_EMAIL_REQUIRED, "payload");
export const SET_IS_PHONE_NUMBER_REQUIRED = "SET_IS_PHONE_NUMBER_REQUIRED";
export const setIsPhoneNumberRequired = makeActionCreator(SET_IS_PHONE_NUMBER_REQUIRED, "payload");
export const SET_IS_MARKETING_CONSENT_REQUIRED = "SET_IS_MARKETING_CONSENT_REQUIRED";
export const setIsMarketingConsentRequired = makeActionCreator(SET_IS_MARKETING_CONSENT_REQUIRED, "payload");
export const SET_PRODUCT_ALERT_EMAIL_IS_VALID = "SET_PRODUCT_ALERT_EMAIL_IS_VALID";
export const setProductAlertEmailIsValid = makeActionCreator(SET_PRODUCT_ALERT_EMAIL_IS_VALID, "payload");

export const SET_IS_SHIPPING_ADDRESS_REQUIRED = "SET_IS_SHIPPING_ADDRESS_REQUIRED";
export const setIsShippingAddressRequired = makeActionCreator(SET_IS_SHIPPING_ADDRESS_REQUIRED, "payload");
export const SET_IS_SHIPPING_ADDRESS_VALIDATION_REQUIRED = "SET_IS_SHIPPING_ADDRESS_VALIDATION_REQUIRED";
export const setIsShippingAddressValidationRequired = makeActionCreator(SET_IS_SHIPPING_ADDRESS_VALIDATION_REQUIRED, "payload");
export const SET_IS_BILLING_ADDRESS_VALIDATION_REQUIRED = "SET_IS_BILLING_ADDRESS_VALIDATION_REQUIRED";
export const setIsBillingAddressValidationRequired = makeActionCreator(SET_IS_BILLING_ADDRESS_VALIDATION_REQUIRED, "payload");
export const SET_SELECTED_COUNTRY_ADDRESS = "SET_SELECTED_COUNTRY_ADDRESS";
export const setSelectedCountryAddress = makeActionCreator(SET_SELECTED_COUNTRY_ADDRESS, "payload");
export const SET_PERMITTED_COUNTRY_ADDRESS = "SET_PERMITTED_COUNTRY_ADDRESS";
export const setPermittedCountryAddress = makeActionCreator(SET_PERMITTED_COUNTRY_ADDRESS, "payload");
export const ADD_SELECTED_COUNTRY_ADDRESS = "ADD_SELECTED_COUNTRY_ADDRESS";
export const addSelectedCountryAddress = makeActionCreator(ADD_SELECTED_COUNTRY_ADDRESS, "payload");
export const REMOVE_SELECTED_COUNTRY_ADDRESS = "REMOVE_SELECTED_COUNTRY_ADDRESS";
export const removeSelectedCountryAddress = makeActionCreator(REMOVE_SELECTED_COUNTRY_ADDRESS, "payload");

export const SET_SELECTED_COUNTRY_ALLOW_BILLING_SHIPPING_ADDRESS = "SET_SELECTED_COUNTRY_ALLOW_BILLING_SHIPPING_ADDRESS";
export const setSelectedCountryAllowBillingShippingAddress = makeActionCreator(SET_SELECTED_COUNTRY_ALLOW_BILLING_SHIPPING_ADDRESS, "payload");

export const SET_IS_WORLD_WIDE_ADDRESS_ALLOWED = "SET_IS_WORLD_WIDE_ADDRESS_ALLOWED";
export const setIsWorldWideAddressAllowed = makeActionCreator(SET_IS_WORLD_WIDE_ADDRESS_ALLOWED, "payload");
export const APPLY_ADDRESS_OPTIONS = "APPLY_ADDRESS_OPTIONS";
export const applyAddressOptions = makeActionCreator(APPLY_ADDRESS_OPTIONS);
export const DISCARD_ADDRESS_OPTIONS = "DISCARD_ADDRESS_OPTIONS";
export const discardAddressOptions = makeActionCreator(DISCARD_ADDRESS_OPTIONS);
export const SET_PRODUCT_CUSTOM_FIELDS = "SET_PRODUCT_CUSTOM_FIELDS";
export const setProductCustomFields = makeActionCreator(SET_PRODUCT_CUSTOM_FIELDS, "payload", "isAfterReordering");
export const SET_TEMP_PRODUCT_CUSTOM_FIELDS = "SET_TEMP_PRODUCT_CUSTOM_FIELDS";
export const setTempProductCustomFields = makeActionCreator(SET_TEMP_PRODUCT_CUSTOM_FIELDS, "payload");
export const REMOVE_CUSTOM_FIELD = "REMOVE_CUSTOM_FIELD";
export const removeCustomField = makeActionCreator(REMOVE_CUSTOM_FIELD, "payload");
export const SET_IS_REORDERING_CUSTOM_FIELDS = "SET_IS_REORDERING_CUSTOM_FIELDS";
export const setIsReorderingCustomFields = makeActionCreator(SET_IS_REORDERING_CUSTOM_FIELDS, "payload");

export const PRODUCT_IMAGE_UPLOAD_REQUEST = "PRODUCT_IMAGE_UPLOAD_REQUEST";
export const PRODUCT_IMAGE_UPLOAD_SUCCESS = "PRODUCT_IMAGE_UPLOAD_SUCCESS";
export const PRODUCT_IMAGE_UPLOAD_FAILURE = "PRODUCT_IMAGE_UPLOAD_FAILURE";

// PLAN SCREEN
export const SET_PLAN_PRODUCT_ID = "SET_PLAN_PRODUCT_ID";
export const setPlanProductId = makeActionCreator(SET_PLAN_PRODUCT_ID, "payload");
export const SET_PLAN_PRODUCT_NAME = "SET_PLAN_PRODUCT_NAME";
export const setPlanProductName = makeActionCreator(SET_PLAN_PRODUCT_NAME, "payload");
export const SET_PLAN_NAME = "SET_PLAN_NAME";
export const setPlanName = makeActionCreator(SET_PLAN_NAME, "payload");
export const SET_PLAN_ADD_ONS = "SET_PLAN_ADD_ONS";
export const setPlanAddOns = makeActionCreator(SET_PLAN_ADD_ONS, "payload");
export const SET_PLAN_ALLOWANCES = "SET_PLAN_ALLOWANCES";
export const setPlanAllowances = makeActionCreator(SET_PLAN_ALLOWANCES, "payload");
export const SET_PLAN_DISPLAY_NAME = "SET_PLAN_DISPLAY_NAME";
export const setPlanDisplayName = makeActionCreator(SET_PLAN_DISPLAY_NAME, "payload");
export const SET_PLAN_DESCRIPTION = "SET_PLAN_DESCRIPTION";
export const setPlanDescription = makeActionCreator(SET_PLAN_DESCRIPTION, "payload");
export const SET_IS_PLAN_IMAGE_ENABLED = "SET_IS_PLAN_IMAGE_ENABLED";
export const setIsPlanImageEnabled = makeActionCreator(SET_IS_PLAN_IMAGE_ENABLED, "payload");
export const SET_PLAN_IMAGE_URL = "SET_PLAN_IMAGE_URL";
export const setPlanImageUrl = makeActionCreator(SET_PLAN_IMAGE_URL, "payload");
export const SET_PLAN_IMAGE_FILE_NAME = "SET_PLAN_IMAGE_FILE_NAME";
export const setPlanImageFileName = makeActionCreator(SET_PLAN_IMAGE_FILE_NAME, "payload");
export const SET_PLAN_FORM_COLLAPSED = "SET_PLAN_FORM_COLLAPSED";
export const setPlanFormCollapsed = makeActionCreator(SET_PLAN_FORM_COLLAPSED, "payload");
export const SET_PLAN_FORM_HAS_ERROR = "SET_PLAN_FORM_HAS_ERROR";
export const setPlanFormHasError = makeActionCreator(SET_PLAN_FORM_HAS_ERROR, "stageIndex", "value");
export const SET_PLAN_FORM_COMPLETED = "SET_PLAN_FORM_COMPLETED";
export const setPlanFormCompleted = makeActionCreator(SET_PLAN_FORM_COMPLETED, "stageIndex", "value");
export const SET_PLAN_PRICING_MODEL = "SET_PRICING_MODEL";
export const setPlanPricingModel = makeActionCreator(SET_PLAN_PRICING_MODEL, "payload");
export const SET_PLAN_PRICING_MODEL_FORM_COLLAPSED = "SET_PLAN_PRICING_MODEL_FORM_COLLAPSED";
export const setPlanPricingModelFormCollapsed = makeActionCreator(SET_PLAN_PRICING_MODEL_FORM_COLLAPSED, "payload");
export const SET_PLAN_FEATURE_TAG = "SET_PLAN_FEATURE_TAG";
export const setPlanFeatureTags = makeActionCreator(SET_PLAN_FEATURE_TAG, "payload");
export const REMOVE_PLAN_FEATURE_TAG = "REMOVE_PLAN_FEATURE_TAG";
export const removePlanFeatureTags = makeActionCreator(REMOVE_PLAN_FEATURE_TAG, "payload");
export const SET_PLAN_HELP_TITLE_BASIC = "SET_PLAN_HELP_TITLE_BASIC";
export const setPlanHelpTitleBasic = makeActionCreator(SET_PLAN_HELP_TITLE_BASIC, "payload");
export const SET_PLAN_HELP_CONTENT_BASIC = "SET_PLAN_HELP_CONTENT_BASIC";
export const setPlanHelpContentBasic = makeActionCreator(SET_PLAN_HELP_CONTENT_BASIC, "payload");
export const SET_PLAN_HELP_TITLE_ADVANCED = "SET_PLAN_HELP_TITLE_ADVANCED";
export const setPlanHelpTitleAdvanced = makeActionCreator(SET_PLAN_HELP_TITLE_ADVANCED, "payload");
export const SET_PLAN_HELP_CONTENT_ADVANCED = "SET_PLAN_HELP_CONTENT_ADVANCED";
export const setPlanHelpContentAdvanced = makeActionCreator(SET_PLAN_HELP_CONTENT_ADVANCED, "payload");
export const RESET_PLAN_FOR_EDITING = "RESET_PLAN_FOR_EDITING";
export const resetPlanForEditing = makeActionCreator(RESET_PLAN_FOR_EDITING, "payload");
export const SET_PLAN_FIELD = "SET_PLAN_FIELD";
export const setPlanField = makeGenericActionCreatorTypeSafe<IPlanReducer>(SET_PLAN_FIELD);
export const SET_PLAN_VISIBILITY = "SET_PLAN_VISIBILITY";
export const setPlanVisibility = makeActionCreator(SET_PLAN_VISIBILITY, "payload");
export const SET_PLAN_REDIRECT_URL = "SET_PLAN_REDIRECT_URL";
export const setPlanRedirectUrl = makeActionCreator(SET_PLAN_REDIRECT_URL, "payload");
export const SET_PLAN_ALERT_EMAIL = "SET_PLAN_ALERT_EMAIL";
export const setPlanAlertEmail = makeActionCreator(SET_PLAN_ALERT_EMAIL, "payload");
export const SET_PLAN_ALERT_EMAIL_IS_VALID = "SET_PLAN_ALERT_EMAIL_IS_VALID";
export const setPlanAlertEmailIsValid = makeActionCreator(SET_PLAN_ALERT_EMAIL_IS_VALID, "payload");
export const SET_PLAN_ERROR = "SET_PLAN_ERROR";
export const setPlanError = makeActionCreator(SET_PLAN_ERROR, "payload");

export const FETCH_CURRENCIES_PRODUCT_REQUEST = "FETCH_CURRENCIES_PRODUCT_REQUEST";
export const FETCH_CURRENCIES_PRODUCT_SUCCESS = "FETCH_CURRENCIES_PRODUCT_SUCCESS";
export const FETCH_CURRENCIES_PRODUCT_FAILURE = "FETCH_CURRENCIES_PRODUCT_FAILURE";

export const CREATE_PRODUCT_REQUEST = "CREATE_PRODUCT_REQUEST";
export const CREATE_PRODUCT_SUCCESS = "CREATE_PRODUCT_SUCCESS";
export const CREATE_PRODUCT_FAILURE = "CREATE_PRODUCT_FAILURE";

export const EDIT_PRODUCT_REQUEST = "EDIT_PRODUCT_REQUEST";
export const EDIT_PRODUCT_SUCCESS = "EDIT_PRODUCT_SUCCESS";
export const EDIT_PRODUCT_FAILURE = "EDIT_PRODUCT_FAILURE";

export const FETCH_PRODUCTS_REQUEST = "FETCH_PRODUCTS_REQUEST";
export const FETCH_PRODUCTS_SUCCESS = "FETCH_PRODUCTS_SUCCESS";
export const FETCH_PRODUCTS_FAILURE = "FETCH_PRODUCTS_FAILURE";

export const FETCH_PRODUCT_REQUEST = "FETCH_PRODUCT_REQUEST";
export const FETCH_PRODUCT_SUCCESS = "FETCH_PRODUCT_SUCCESS";
export const FETCH_PRODUCT_FAILURE = "FETCH_PRODUCT_FAILURE";


export const FETCH_PLANS_BY_PRODUCT_REQUEST = "FETCH_PLANS_BY_PRODUCT_REQUEST";
export const FETCH_PLANS_BY_PRODUCT_SUCCESS = "FETCH_PLANS_BY_PRODUCT_SUCCESS";
export const FETCH_PLANS_BY_PRODUCT_FAILURE = "FETCH_PLANS_BY_PRODUCT_FAILURE";

export const CREATE_PLAN_REQUEST = "CREATE_PLAN_REQUEST";
export const CREATE_PLAN_SUCCESS = "CREATE_PLAN_SUCCESS";
export const CREATE_PLAN_FAILURE = "CREATE_PLAN_FAILURE";

export const EDIT_PLAN_REQUEST = "EDIT_PLAN_REQUEST";
export const EDIT_PLAN_SUCCESS = "EDIT_PLAN_SUCCESS";
export const EDIT_PLAN_FAILURE = "EDIT_PLAN_FAILURE";

export const CREATE_CYCLE_REQUEST = "CREATE_CYCLE_REQUEST";
export const CREATE_CYCLE_SUCCESS = "CREATE_CYCLE_SUCCESS";
export const CREATE_CYCLE_FAILURE = "CREATE_CYCLE_FAILURE";

export const FETCH_COUNTRIES_PRODUCT_REQUEST = "FETCH_COUNTRIES_PRODUCT_REQUEST";
export const FETCH_COUNTRIES_PRODUCT_SUCCESS = "FETCH_COUNTRIES_PRODUCT_SUCCESS";
export const FETCH_COUNTRIES_PRODUCT_FAILURE = "FETCH_COUNTRIES_PRODUCT_FAILURE";

export const PLAN_IMAGE_UPLOAD_REQUEST = "PLAN_IMAGE_UPLOAD_REQUEST";
export const PLAN_IMAGE_UPLOAD_SUCCESS = "PLAN_IMAGE_UPLOAD_SUCCESS";
export const PLAN_IMAGE_UPLOAD_FAILURE = "PLAN_IMAGE_UPLOAD_FAILURE";

// CYCLES
export const SET_IS_ADDING_NEW_CYCLE = "SET_IS_ADDING_NEW_CYCLE";
export const setIsAddingNewCycle = makeActionCreator(SET_IS_ADDING_NEW_CYCLE, "payload");
export const SET_CYCLE_PRICE = "SET_CYCLE_PRICE";
export const setCyclePrice = makeActionCreator(SET_CYCLE_PRICE, "payload");
export const SET_CYCLE_FREQUENCY = "SET_CYCLE_FREQUENCY";
export const setCycleFrequency = makeActionCreator(SET_CYCLE_FREQUENCY, "payload");
export const SET_CYCLE_FREQUENCY_TYPE = "SET_CYCLE_FREQUENCY_TYPE";
export const setCycleFrequencyType = makeActionCreator(SET_CYCLE_FREQUENCY_TYPE, "payload");
export const ADD_CYCLE = "ADD_CYCLE";
export const addCycle = makeActionCreator(ADD_CYCLE, "payload");
export const REMOVE_CYCLE = "REMOVE_CYCLE";
export const removeCycle = makeActionCreator(REMOVE_CYCLE, "payload");
export const SET_EDITED_CYCLE = "SET_EDITED_CYCLE";
export const setEditedCycle = makeActionCreator(SET_EDITED_CYCLE, "payload");
export const SET_HAS_SELECTED_FEE = "SET_HAS_SELECTED_FEE";
export const setHasSelectedFee = makeActionCreator(SET_HAS_SELECTED_FEE, "payload");
export const SET_PRODUCT_CYCLES_UNMOUNT = "SET_PRODUCT_CYCLES_UNMOUNT";
export const setProductCyclesUnmount = makeActionCreator(SET_PRODUCT_CYCLES_UNMOUNT, "payload");
export const SET_CREATE_CYCLE_FIELD = "SET_CREATE_CYCLE_FIELD";
export const setCreateCycleField = makeActionCreator(SET_CREATE_CYCLE_FIELD, "fieldName", "fieldValue");
export const SET_IS_REORDERING_CYCLES = "SET_IS_REORDERING_CYCLES";
export const setIsReorderingCycles = makeActionCreator(SET_IS_REORDERING_CYCLES, "payload");
export const SET_TEMP_CYCLES = "SET_TEMP_CYCLES";
export const setTempCycles = makeActionCreator(SET_TEMP_CYCLES, "payload");
export const SET_CYCLES = "SET_CYCLES";
export const setCycles = makeActionCreator(SET_CYCLES, "payload");
export const ADD_TIER = "ADD_TIER";
export const addTier = makeActionCreator(ADD_TIER, "payload");
export const DELETE_TIER = "DELETE_TIER";
export const deleteTier = makeActionCreator(DELETE_TIER, "payload");
export const SET_TIER_UNIT = "SET_TIER_UNIT";
export const setTierUnit = makeActionCreator(SET_TIER_UNIT, "payload");
export const SET_CURRENT_TIER_UNIT = "SET_CURRENT_TIER_UNIT";
export const setCurrentTierUnit = makeActionCreator(SET_CURRENT_TIER_UNIT, "payload");
export const SET_PRICE_PER_UNIT = "SET_PRICE_PER_UNIT";
export const setPricePerUnit = makeActionCreator(SET_PRICE_PER_UNIT, "payload");


export const SET_PRODUCTS_CUSTOM_FIELD = "SET_PRODUCTS_CUSTOM_FIELD"
export const setProductsCustomFields = makeGenericActionCreator<keyof IProductPlanCycleReducerState>(SET_PRODUCTS_CUSTOM_FIELD);

/**********************  ASYNCHRONOUS ACTIONS (api requests) ********************/

export const fetchCurrencies = () => {
  return {
    types: [FETCH_CURRENCIES_PRODUCT_REQUEST, FETCH_CURRENCIES_PRODUCT_SUCCESS, FETCH_CURRENCIES_PRODUCT_FAILURE],
    callAPI: () => API.getCurrencies()
  }
}

export const createProduct = (companyDomain: string, product: IBaseProduct) => {
  return {
    types: [CREATE_PRODUCT_REQUEST, CREATE_PRODUCT_SUCCESS, CREATE_PRODUCT_FAILURE],
    callAPI: () => API.createProduct(companyDomain, product)
  }
}

export const editProduct = (companyDomain: string, product: IProductLight) => {
  return {
    types: [EDIT_PRODUCT_REQUEST, EDIT_PRODUCT_SUCCESS, EDIT_PRODUCT_FAILURE],
    callAPI: () => API.editProduct(companyDomain, product)
  }
}

export const fetchProducts = (companyDomain: string, pageNumber: number, pageSize: number = PAGINATION_PAGE_SIZE, search: string = "", visbilityType: VisibilityType | string = "") => {
  return {
    types: [FETCH_PRODUCTS_REQUEST, FETCH_PRODUCTS_SUCCESS, FETCH_PRODUCTS_FAILURE],
    callAPI: () => API.fetchProducts(companyDomain, pageNumber, pageSize, search, visbilityType)
  }
}

export const fetchProduct = (companyDomain: string, productId: number) => {
  return {
    types: [FETCH_PRODUCT_REQUEST, FETCH_PRODUCT_SUCCESS, FETCH_PRODUCT_FAILURE],
    callAPI: () => API.fetchProduct(companyDomain, productId)
  }
}

export const fetchPlansByProduct = (companyDomain: string, productId: number) => {
  return {
    types: [FETCH_PLANS_BY_PRODUCT_REQUEST, FETCH_PLANS_BY_PRODUCT_SUCCESS, FETCH_PLANS_BY_PRODUCT_FAILURE],
    callAPI: () => API.fetchPlansByProduct(companyDomain, productId),
    //shouldCallAPI: (s:any) => s.productPlanCycle.products.find((p:IProductWithPlan) => p.productId === productId).plans == undefined
  }
}

export const createPlan = (companyDomain: string, productId: number, plan: IBasePlan) => {
  return {
    types: [CREATE_PLAN_REQUEST, CREATE_PLAN_SUCCESS, CREATE_PLAN_FAILURE],
    callAPI: () => API.createPlan(companyDomain, productId, plan)
  }
}

export const editPlan = (companyDomain: string, productId: number, planId: number, plan: any) => {
  return {
    types: [EDIT_PLAN_REQUEST, EDIT_PLAN_SUCCESS, EDIT_PLAN_FAILURE],
    callAPI: () => API.editPlan(companyDomain, productId, planId, plan)
  }
}

export const createCycle = (companyDomain: string, productId: number, planId: number, cycles: Array<IPricingModel> | IPricingModel, 
  addonPlanInputModels: Array<ILinkedAddOn> = [], allowancePlanInputModels: Array<ILinkedAllowance>) => {
  return {
    types: [CREATE_CYCLE_REQUEST, CREATE_CYCLE_SUCCESS, CREATE_CYCLE_FAILURE],
    callAPI: () => API.createCycles(companyDomain, productId, planId, { cyclesInputModel: cycles, addonPlanInputModels, allowancePlanInputModels })
  }
}

export const fetchCountriesProduct = () => {
  return {
    types: [FETCH_COUNTRIES_PRODUCT_REQUEST, FETCH_COUNTRIES_PRODUCT_SUCCESS, FETCH_COUNTRIES_PRODUCT_FAILURE],
    callAPI: () => API.getCountries()
  }
}

export const uploadProductImage = (currentCompanyDomain: string, files: Array<File>) => {
  return {
    types: [PRODUCT_IMAGE_UPLOAD_REQUEST, PRODUCT_IMAGE_UPLOAD_SUCCESS, PRODUCT_IMAGE_UPLOAD_FAILURE],
    callAPI: () => API.uploadCompanyImage(currentCompanyDomain, files[0], "Product")
  }
}

export const uploadPlanImage = (currentCompanyDomain: string, files: Array<File>) => {
  return {
    types: [PLAN_IMAGE_UPLOAD_REQUEST, PLAN_IMAGE_UPLOAD_SUCCESS, PLAN_IMAGE_UPLOAD_FAILURE],
    callAPI: () => API.uploadCompanyImage(currentCompanyDomain, files[0], "Plan")
  }
}