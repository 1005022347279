import API from "../utils/API";
import { makeActionCreator, makeGenericActionCreator } from "../utils/reduxUtils";
import { AppState } from "..";
import { InvoiceWriteOffType } from "../models/Invoices";
import { IDunningConfig } from "../models/DunningFailedProcess";
import { IDunningFailedProcessState } from "../reducers/dunningFailedProcessReducer";

export const FETCH_DUNNING_PROCESS_REQUEST = "FETCH_DUNNING_PROCESS_REQUEST";
export const FETCH_DUNNING_PROCESS_SUCCESS = "FETCH_DUNNING_PROCESS_SUCCESS";
export const FETCH_DUNNING_PROCESS_FAILURE = "FETCH_DUNNING_PROCESS_FAILURE";

export const UPDATE_DUNNING_PROCESS_REQUEST = "UPDATE_DUNNING_PROCESS_REQUEST";
export const UPDATE_DUNNING_PROCESS_SUCCESS = "UPDATE_DUNNING_PROCESS_SUCCESS";
export const UPDATE_DUNNING_PROCESS_FAILURE = "UPDATE_DUNNING_PROCESS_FAILURE";
export const SET_DUNNING_PROCESS_ADDRESS = "SET_DUNNING_PROCESS_ADDRESS";
export const SET_DUNNING_PROCESS_GROUP_FIELD = "SET_DUNNING_PROCESS_GROUP_FIELD"

export const SET_DUNNING_PROCESS_FIELD = "SET_DUNNING_PROCESS_FIELD";
export const RESET_DUNNING_PROCESS = "RESET_DUNNING_PROCESS";
export const RESET_FAILED_PAYMENTS = "RESET_FAILED_PAYMENTS";
export const RESET_DECLINED_PAYMENTS = "RESET_DECLINED_PAYMENTS";

export type AllowedDunningProcessFields = keyof IDunningFailedProcessState;
export type DunningProcessConnectedPanels = "attemptOne" | "attemptTwo" | "attemptThree" | "writingOffInvoice";

export const setDunningProcessGroupField = makeActionCreator(SET_DUNNING_PROCESS_GROUP_FIELD, "payload");
export const resetDunningProcess = makeActionCreator(RESET_DUNNING_PROCESS, "payload");
export const resetFailedPayments = makeActionCreator(RESET_FAILED_PAYMENTS, "payload");
export const resetDeclinedPayments = makeActionCreator(RESET_DECLINED_PAYMENTS, "payload");
export const setDunningProcessField = makeGenericActionCreator<AllowedDunningProcessFields>(SET_DUNNING_PROCESS_FIELD);

export const fetchDunningProcessData = function (companyDomain: string) {
  return {
    types: [FETCH_DUNNING_PROCESS_REQUEST, FETCH_DUNNING_PROCESS_SUCCESS, FETCH_DUNNING_PROCESS_SUCCESS],
    callAPI: () => API.fetchDunningProccessData(companyDomain)
  }
}

export const updateDunningProcessData = function (companyDomain: string, data: IDunningConfig) {
  return {
    types: [UPDATE_DUNNING_PROCESS_REQUEST, UPDATE_DUNNING_PROCESS_SUCCESS, UPDATE_DUNNING_PROCESS_SUCCESS],
    callAPI: () => API.updateDunningProcessData(companyDomain, data)
  }
}

export const updateDunningProcessProps = function (propName: AllowedDunningProcessFields, propValue: any) {
  return (dispatch: Function, getState: () => AppState) => {
    dispatch(setDunningProcessField(propName, propValue))
    const { attemptOne, attemptTwo, attemptThree, writingOffInvoice, minimumTerm } = getState().dunningFailedProcessReducer;

    if (!attemptOne.reattempt && !attemptTwo.reattempt && !attemptThree.reattempt && !minimumTerm.hasMinimumTerm) {
      dispatch(setDunningProcessField("writingOffInvoice", { ...writingOffInvoice, writeOffStatus: InvoiceWriteOffType.WRITE_OFF_MANUALLY }))
    }

  }
}