import React, { useState, useEffect, useMemo } from "react";
import { AppState } from "../../../../..";
import { useDispatch, useSelector } from "react-redux";
import Panel from "../../../../ui/panel/Panel";
import Text from "../../../../ui/text/Text";
import FormLabel from "../../../../ui/form-label/FormLabel";
import FormGroup from "../../../../ui/form-group/FormGroup";
import Input from "../../../../ui/input/Input";
import { Col, Row } from "react-grid-system";
import Button from "../../../../ui/button/Button";
import Dropdown from "../../../../ui/dropdown/Dropdown";
import { SubscriptionStatusType } from "../../../../../models/Subscriptions";
import "./DeclinedPayments.scss";
import Switch from "../../../../ui/switch/Switch";
import { IDunningFailedProcessState } from "../../../../../reducers/dunningFailedProcessReducer";
import { setDunningProcessField } from "../../../../../actions/dunningFailedProcessActions";
import { DunningFailedEmailType } from "../../../../../models/Emails";
import { allowZeroAndPositiveNumbers } from "../../../../../utils/commonUtils";
import { useHistory } from "react-router";

interface IHandlingMinimumTermsPanelProps  {
  statusOptions: Array<any>
}

const HandlingMinimumTermsPanel: React.FC<IHandlingMinimumTermsPanelProps> = ({ statusOptions }) => {
  const { minimumTerm, attemptThree, attemptTwo, attemptOne, initialPaymentDeclineStatus } = useSelector<AppState, IDunningFailedProcessState>(state => state.dunningFailedProcessReducer);
  const dispatch = useDispatch<Function>()

  const [statusOptionsValue, setStatusOptionsValue] = useState<{ label:string, value: SubscriptionStatusType} | null>(statusOptions.find(i => i.value === minimumTerm.subscriptionStatusType));

  const history = useHistory()
  
  const isDisabled = useMemo(() => {
    if(attemptThree.reattempt)
      return attemptThree.subscriptionStatusType === SubscriptionStatusType.CANCELLED;

    if(attemptTwo.reattempt)
      return attemptTwo.subscriptionStatusType === SubscriptionStatusType.CANCELLED;

    if(attemptOne.reattempt)
      return attemptOne.subscriptionStatusType === SubscriptionStatusType.CANCELLED;
    
    return initialPaymentDeclineStatus === SubscriptionStatusType.CANCELLED;

  }, [attemptOne, attemptTwo, attemptThree, initialPaymentDeclineStatus])

  const minStatusValue = useMemo(() => {
    if(attemptThree.reattempt)
      return attemptThree.subscriptionStatusType;

    if(attemptTwo.reattempt)
      return attemptTwo.subscriptionStatusType;

    if(attemptOne.reattempt)
      return attemptOne.subscriptionStatusType;
      
    return initialPaymentDeclineStatus;

  }, [attemptOne, attemptTwo, attemptThree, initialPaymentDeclineStatus])

  const options = useMemo(() => {
    if(minStatusValue === SubscriptionStatusType.SUSPENDED)
      return statusOptions.filter(i => i.value !== SubscriptionStatusType.ACTIVE);
    if(minStatusValue === SubscriptionStatusType.CANCELLED)
      return statusOptions.filter(i => i.value !== SubscriptionStatusType.ACTIVE && i.value !== SubscriptionStatusType.SUSPENDED);
    return statusOptions;
  }, [minStatusValue]);

  useEffect(() => {
    const minOption = statusOptions.filter(i => i.value === minStatusValue)[0];
    const isStillInOptions = options.filter(o => o.value === statusOptionsValue?.value).length > 0;
    setStatusOptionsValue(isStillInOptions ? statusOptionsValue : minOption);
  }, [statusOptions]);
    
  return (<Panel className="dunning-declined-payments__panel" title="DUNNING_FAILED_PAYMENTS_HANDLING_MINIMUM_TERMS_TITLE">
    <Text content="DUNNING_FAILED_PAYMENTS_HANDLING_MINIMUM_TERMS_BODY_1"></Text>
    <Button
      className="dunning-declined-payments__floating-button"
      icon="fas fa-edit"
      title="DUNNING_FAILED_PAYMENTS_BUTTON_CUSTOMIZE_EMAIL"
      type="button"
      buttonType="add"
      disabled={!minimumTerm.hasMinimumTerm}
      id={"customize-email-minimum-term"}
      onClick={() => history.push(`/configuration/dunning-process/customize-email/declined/${DunningFailedEmailType.HandlingMinimumTermsEmail}`)}
    />
    <FormGroup>
      <Row align="center">
        <Col md={2}>
          <FormLabel noMargin target={"reattempt-payment-minimum-term-switch"} content="DUNNING_FAILED_PAYMENTS_LABEL_INVOICE_MINIMUM_TERM" />
        </Col>
        <Col md={10}>
          <Switch
            id={"reattempt-payment-minimum-term-switch"}
            checked={minimumTerm.hasMinimumTerm}
            onChange={() => dispatch(setDunningProcessField("minimumTerm", { ...minimumTerm, hasMinimumTerm: !minimumTerm.hasMinimumTerm, subscriptionStatusType: statusOptionsValue?.value }))}
          />
        </Col>
      </Row>
    </FormGroup>
    {
      minimumTerm.hasMinimumTerm &&
      <>
        <FormGroup>
          <Row align="center">
            <Col md={2}>
              <FormLabel noMargin target={"hours-reattempt-minimum-term"} content="DUNNING_FAILED_PAYMENTS_LABEL_HOURS_UNTIL_INVOICE" />
            </Col>
            <Col md={10}>
              <Input
                id={"hours-reattempt-minimum-term"}
                type="number"
                value={minimumTerm.hours || ""}
                allowDecimal={false}
                onChange={(evt: any) => dispatch(setDunningProcessField("minimumTerm", { ...minimumTerm, hours: allowZeroAndPositiveNumbers(evt.target.value) }))}
              />
            </Col>
          </Row>
        </FormGroup>
        <Row align="center">
          <Col md={2}>
            <FormLabel noMargin target={"subscription-failure-minimum-term"} content="DUNNING_FAILED_PAYMENTS_LABEL_STATUS_ON_FAILURE" />
          </Col>
          <Col md={10}>
            <Dropdown
              className="dunning-declined-payments__dropdown"
              id={"subscription-failure-minimum-term"}
              value={statusOptionsValue}
              options={options}              
              isDisabled={isDisabled}
              onChange={(status: any) => {
                setStatusOptionsValue(status);
                dispatch(setDunningProcessField("minimumTerm", { ...minimumTerm, subscriptionStatusType: status.value }));
              }}
            />
          </Col>
        </Row>
      </>
    }
  </Panel>
  );
};

export default HandlingMinimumTermsPanel
