import React from "react";
import { ConfigConstants } from "../../utils/config";
import "./ScaSetup.scss";


const showHidePageWrapper = (type: "hide" | "show") => {
  // when the sca iframe is visible we hide all the content in the body and the back button
  // both in desktop and mobile mode
  const styleContent = `
    .page-wrapper__scrollable, 
    .page-wrapper__sticky-header__content, 
    .page-wrapper__sticky-header__back-btn {
      display: none;
    }
    .floating-header .icon-container:first-child {
      visibility: hidden;
    }
  `;

  if (type === "hide") {
    const style = document.createElement("style");
    style.id = "hide-page-wrapper-content";
    style.appendChild(document.createTextNode(styleContent));
    document.head.appendChild(style);
  }
  else {
    const style = document.head.querySelector("#hide-page-wrapper-content");
    style && document.head.removeChild(style);
  }
}

const showHideNode = (id: string, type: "show" | "hide") => {
  const domNode = document.getElementById(id);
  if (domNode && type === "show") {
    domNode.classList.remove("hidden");
  }
  if (domNode && type === "hide") {
    domNode.classList.add("hidden");
  }
}

export const getBrowserInfo = (): string => {
  const Spreedly = (window as any).Spreedly;
  const browser_size = "01";
  const acceptHeader = "text/html,application/xhtml+xml,application/xml;q=0.9,*/*;q=0.8";

  return Spreedly.ThreeDS.serialize(
    browser_size,
    acceptHeader
  );
}

export const setup3dSecure = (transaction_token: string, statusUpdatesCb: (event: { action: string }) => void) => {
  const Spreedly = (window as any).Spreedly;

  const commonBehaviorCb = (event: { action: string }) => {
    if (event.action === "succeeded" || event.action === "error") {
      showHidePageWrapper("show");
      showHideNode("challenge-modal", "hide");
      showHideNode("device-fingerprint", "hide");
    }
    else if (event.action === "challenge") {
      showHideNode("challenge-modal", "show");
      showHidePageWrapper("hide")
    }
    /*else if (event.action === 'device-fingerprint') {
      showHideNode("device-fingerprint", "show");
      showHidePageWrapper("hide")
    }*/
  }


  Spreedly.on("3ds:status", (event: { action: string }) => {
    commonBehaviorCb(event);
    statusUpdatesCb(event);
  });

  const lifecycle = new Spreedly.ThreeDS.Lifecycle({
    environmentKey: ConfigConstants.spreedlyKey,
    hiddenIframeLocation: "device-fingerprint",
    challengeIframeLocation: "challenge",
    transactionToken: transaction_token,
  });

  lifecycle.start();
}


interface IScaSetup { }

const ScaSetup: React.FC<IScaSetup> = () => {
  return (
    <div className="sca-setup">
      <div id="device-fingerprint" className="hidden"></div>
      <div id="challenge-modal" className="hidden">
        <div id="challenge"></div>
      </div>
    </div>
  )
}

export default ScaSetup;