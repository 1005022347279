import API from "../utils/API";

export const FETCH_COMPANY_TEAMMATES_PENDING = "FETCH_COMPANY_TEAMMATES_PENDING";
export const FETCH_COMPANY_TEAMMATES_SUCCESS = "FETCH_COMPANY_TEAMMATES_SUCCESS";
export const FETCH_COMPANY_TEAMMATES_FAILURE = "FETCH_COMPANY_TEAMMATES_FAILURE";

export const fetchCompanyTeammates = function (companyDomain: string) {
  return {
    types: [
      FETCH_COMPANY_TEAMMATES_PENDING,
      FETCH_COMPANY_TEAMMATES_SUCCESS,
      FETCH_COMPANY_TEAMMATES_FAILURE
    ],
    callAPI: () => API.fetchCompanyTeammates(companyDomain)
  };
};
