/**
 * @fileoverview gRPC-Web generated client stub for core.private.counters
 * @enhanceable
 * @public
 */

// GENERATED CODE -- DO NOT EDIT!


import * as grpcWeb from "grpc-web";

import {
  CreateCounterRequest,
  CreateCounterResponse,
  GetCountersRequest,
  GetCountersResponse} from "./counters_pb";

export class CounterServiceClient {
  client_: grpcWeb.AbstractClientBase;
  hostname_: string;
  credentials_: null | { [index: string]: string; };
  options_: null | { [index: string]: string; };

  constructor (hostname: string,
               credentials?: null | { [index: string]: string; },
               options?: null | { [index: string]: string; }) {
    if (!options) options = {};
    if (!credentials) credentials = {};
    options["format"] = "text";

    this.client_ = new grpcWeb.GrpcWebClientBase(options);
    this.hostname_ = hostname;
    this.credentials_ = credentials;
    this.options_ = options;
  }

  methodInfoGetCounters = new grpcWeb.AbstractClientBase.MethodInfo(
    GetCountersResponse,
    (request: GetCountersRequest) => {
      return request.serializeBinary();
    },
    GetCountersResponse.deserializeBinary
  );

  getCounters(
    request: GetCountersRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: GetCountersResponse) => void) {
    return this.client_.rpcCall(
      this.hostname_ +
        "/core.private.counters.CounterService/GetCounters",
      request,
      metadata || {},
      this.methodInfoGetCounters,
      callback);
  }

  methodInfoCreateCounter = new grpcWeb.AbstractClientBase.MethodInfo(
    CreateCounterResponse,
    (request: CreateCounterRequest) => {
      return request.serializeBinary();
    },
    CreateCounterResponse.deserializeBinary
  );

  createCounter(
    request: CreateCounterRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: CreateCounterResponse) => void) {
    return this.client_.rpcCall(
      this.hostname_ +
        "/core.private.counters.CounterService/CreateCounter",
      request,
      metadata || {},
      this.methodInfoCreateCounter,
      callback);
  }

}

