import React, { useState, useEffect } from "react"
import Table from "../../ui/table/Table"
import Text from "../../ui/text/Text"
import ProgressIndicator from "../../ui/progress-indicator/ProgressIndicator";
import { fetchSubscriptionDiscounts, setDeleteDiscount, setShowDeleteDiscountModal } from "../../../actions/subscriptionDetailsActions";
import { AppState } from "../../..";
import { useDispatch, useSelector } from "react-redux";
import { SubscriptionDiscount, Discount, Coupon } from "../../../utils/grpc/generated/Billsby.Protos/billing/private/subscription/discount/discount_pb";
import Button from "../../ui/button/Button";
import "./SubscriptionDiscounts.scss";
import { Timestamp } from "google-protobuf/google/protobuf/timestamp_pb";
import moment from "moment";
import { DiscountType, DeductionType } from "../../../utils/grpc/generated/Billsby.Protos/core/private/discounts/discounts_pb";
import { divideNumberBy100 } from "../../../utils/commonUtils";
import { Permission } from "../../../models/Auth";
import useCheckFeaturePermission from "../../../utils/hooks/useCheckFeaturePermission";

const SubscriptionDiscounts: React.FC = () => {
  const authData = useSelector((state: AppState) => state.auth);
  const subscriptionDetails = useSelector((state: AppState) => state.subscriptionDetailsReducer);

  const dispatch = useDispatch<Function>();
  const isSubscriptionRemoveDiscountPermitted = useCheckFeaturePermission(Permission.SubscriptionRemoveDiscount);

  const [isLoadingData, setIsLoadingData] = useState(false);
  const { subscriptionId, discounts, currency } = subscriptionDetails;

  const fetchData = async () => {
    setIsLoadingData(true);
    try {
      await dispatch(fetchSubscriptionDiscounts(Number(authData.currentCompanyId), Number(subscriptionId)));
      setIsLoadingData(false);
    } catch (err) {
      console.log(err);
      setIsLoadingData(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  if (isLoadingData) {
    return <ProgressIndicator color="blue" />;
  }

  
  const getPercentage = (discount: Discount) => {
    if (discount.getPercentage()) {
      const percentage = discount.getPercentage() as Discount.PercentageModel;
      return Number(divideNumberBy100(percentage.getPercentage()));
    }

    return "";
  }

  const getMonetaries = (discount: Discount) => {
    if (discount.getMonetary()) {
      const monetary = discount.getMonetary() as Discount.MonetaryModel;
      const monetaryCurrency = monetary.getPricesList().filter(i => i.getCurrencyCode() === currency);
      if(monetaryCurrency.length > 0) {
        return `${monetaryCurrency[0].getValueFormatted()}`;
      }
    }

    return "";
  }

  const deductionDisplay = (discount: Discount) => {
    if (discount.getDiscountType() === DiscountType.PERCENTAGE) {
      return <td className="cc-text mb0 ">
        <p>
          <Text content="DISCOUNTS_LIST_DEDUCTION_PERCENTAGE" translateWith={{ percentage: getPercentage(discount) }} noMargin component="span" />
          <Text className="discount-list__table__deduction-label" content={discount.getDeductionType() === DeductionType.PLAN_ONLY ? "DISCOUNTS_LIST_DISCOUNT_TYPE_MONETARY_TYPE_PLAN_ONLY" : "DISCOUNTS_LIST_DISCOUNT_TYPE_MONETARY_TYPE_ENTIRE_SUBSCRIPTION"} component="span" noMargin />
        </p>
      </td>;
    } else {
      if (discount.getMonetary()) {
        return <td className="cc-text mb0 ">
          <p>
            <Text content={getMonetaries(discount)} shouldTranslate={false} noMargin component="span" />
            <Text className="discount-list__table__deduction-label" content={discount.getDeductionType() === DeductionType.PLAN_ONLY ? "DISCOUNTS_LIST_DISCOUNT_TYPE_MONETARY_TYPE_PLAN_ONLY" : "DISCOUNTS_LIST_DISCOUNT_TYPE_MONETARY_TYPE_ENTIRE_SUBSCRIPTION"} component="span" noMargin />
          </p>
        </td>;
      }

      return <td></td>;
    }
  }

  return (
    <div>
      <Table className="subscription-discounts__table">
        <thead>
          <tr>
            <Text content="SUBSCRIPTION_DISCOUNT_LIST_DISCOUNT_NAME" component="th" noMargin />
            <Text content="SUBSCRIPTION_DISCOUNT_LIST_COUPON_USED" component="th" noMargin />
            <Text content="SUBSCRIPTION_DISCOUNT_LIST_DEDUCTION" component="th" noMargin />
            <Text content="SUBSCRIPTION_DISCOUNT_LIST_ADDED_ON" component="th" noMargin />
            <Text content="SUBSCRIPTION_DISCOUNT_LIST_EXPIRES" component="th" noMargin />
            <Text content="" component="th" shouldTranslate={false} noMargin />
          </tr>
        </thead>
        <tbody>
          {discounts.getDiscountsList() && discounts.getDiscountsList().filter(i => !i.getIsRetentionDiscount()).map((discount: SubscriptionDiscount) => (
            <tr key={discount.getSubscriptionDiscountId()}>
              <Text content={(discount.getDiscount() as Discount).getName()} shouldTranslate={false} component="td" noMargin />
              <Text content={!discount.getCouponValue() ? "-" : (discount.getCouponValue() as Coupon).getName()} shouldTranslate={false} component="td" noMargin />
              {deductionDisplay(discount.getDiscount() as Discount)}
              <Text content={discount.getAddedOn() ? moment((discount.getAddedOn() as Timestamp).toDate().toString()).format("DD MMM YYYY") : "-"} shouldTranslate={false} component="td" noMargin />
              <Text content={discount.getExpiresOn() ? moment((discount.getExpiresOn() as Timestamp).toDate().toString()).format("DD MMM YYYY") : "-"} shouldTranslate={false} component="td" noMargin />
              <td>
                <Button id='delete-discount'
                  buttonType='error'
                  disabled={!isSubscriptionRemoveDiscountPermitted}
                  tooltipMessage={!isSubscriptionRemoveDiscountPermitted ? "NO_ACCESS_MSG" : undefined}
                  icon='far fa-times'
                  title='SUBSCRIPTION_DISCOUNT_LIST_REMOVE_BUTTON'
                  onClick={() => {
                    dispatch(setDeleteDiscount(discount));
                    dispatch(setShowDeleteDiscountModal(true));
                  }}></Button>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
      {discounts.getDiscountsList() && discounts.getDiscountsList()
      .filter(i => i.getIsRetentionDiscount()).map((discount: SubscriptionDiscount) => 
      <Text
      content="RETENTION_DISCOUNT_MESSAGE_IN_SUBSCRIPTION_DISCOUNTS"
      className="subscription-discounts__retention-discount"
      translateWith={{percentage: getPercentage(discount.getDiscount() as Discount),
      expiresOn: discount.getExpiresOn() ? moment((discount.getExpiresOn() as Timestamp).toDate().toString()).format("DD MMM YYYY") : "-"}}
      />)}
    </div>
  )
}

export default SubscriptionDiscounts;
