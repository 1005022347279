import React from "react";
import "./Input.scss";
import ElementModifiers from "../element-modifiers/ElementModifiers";
import Text from "../text/Text";
import { trimWhiteSpaces } from "../../../utils/commonUtils";

interface IInput {
  [key: string]: any,

  id: string,
  /**
   * optional icon to be placed before the actual text
   */
  icon?: string,
  /**
   * icon position
   */
  iconPosition?: "left" | "right",
  /**
   * show the error state
   */
  isError?: boolean,
  /**
   * show error message
   */
  errorMsg?: string,
  /**
   * type of error icon
   */
  errorIcon?: string,
  /**
   * callback for error icon
   */
  errorCb?: () => {},
  /**
   * classes
   */
  className?: string,
  /**
   * placeholder
   */
  placeholder?: string,
  /**
   * on change hook
   */
  onChange?: (evt: any) => any,
  /**
   * value
   */
  value?: string | number,
  type?: string,
  /**
   * display error with a warning layout
   */
  warninglayout?: boolean,
  warningIcon?: string,
  required?: boolean,
  autocomplete?: string,
  pattern?: string,
  disabled?: boolean,
  showDisabledIcon?: boolean,
  showAsText?: boolean,
  allowDecimal?: boolean,
  trim?: "left" | "right" | "all"
}

const Input: React.FC<IInput> = React.memo((props) => {
  const {
    icon = "",
    iconPosition = "left",
    isError = false,
    type = "text",
    errorMsg = "",
    errorIcon = "exclamation-circle",
    errorCb = () => { },
    warninglayout = false,
    warningIcon = "far fa-info-circle",
    showWarningIcon = true,
    className = "",
    value = "",
    disabled = false,
    disabledShowLockIcon = true,
    showAsText = false,
    showDisabledIcon = true,
    allowDecimal = true,
    autocomplete,
    trim,
    onChange,
    ...rest
  } = props;

  if(showAsText) {
    return <Text component="span" content={(value as string)} shouldTranslate={false} noMargin></Text>
  }

  const _onChange = (evt: any) => {
    const event = trim ? { ...evt, target: { ...evt.target, value: trimWhiteSpaces(evt.target.value, trim) } } : evt
    onChange && onChange(event);
  }

  const onPasteRemoveDecimal = (evt: any) => {
    let str = evt.clipboardData.getData("Text");
    evt.target.value = !!str ? Math.trunc(Number(str)) : undefined;
    if(!!onChange) {
      onChange(evt);
    }

    evt.preventDefault();
  }

  return (
    <div className={isError ? (`cc-input cc-input${warninglayout ? "--warning" : "--error"} ${className}`) : disabled ? `cc-input ${className} cc-input--disabled` : `cc-input ${className}`}>
      {icon && iconPosition === "left" && <i className={`cc-input__icon ${icon} ${iconPosition}`} />}
      <input
        type={type}
        className={icon ? `cc-input__field cc-input__field--icon ${iconPosition}` : "cc-input__field"}
        value={value}
        disabled={disabled}
        onKeyPress={(evt: any) => type === "number" && !allowDecimal && (!(evt.charCode >= 48 && evt.charCode <= 57)) ? evt.preventDefault() : undefined}
        onPaste={(evt: any) => type === "number" && !allowDecimal ? onPasteRemoveDecimal(evt) : undefined}
        onKeyDown={(evt: any) => evt.keyCode === 69 && type === "number" ? evt.preventDefault() : undefined}
        onChange={_onChange}
        //spread all the other props
        autoComplete={autocomplete}
        {...rest}
      />
      {icon && iconPosition === "right" && <i className={`cc-input__icon ${icon} ${iconPosition}`} />}
      {isError &&
        <span className={`cc-input__error-container ${warninglayout ? "cc-input__error-container--warning" : ""}`}>
          <i role="button" onClick={errorCb} className={`cc-input__error-icon ${warninglayout ? warningIcon : errorIcon}`} tabIndex={-1} />
          <span className="cc-input__error-msg">{errorMsg}</span>
        </span>
      }
      {disabled &&
        <ElementModifiers className="cc-input__locker" isShowLockIcon={showDisabledIcon}/>
      }
    </div>
  );
})

export default Input;
