import React, { useLayoutEffect, useState } from "react";
import InvoicePrint from "../../components/invoice-print/InvoicePrint";
import "./InvoiceStandalone.scss";
import ErrorPanel from "../../components/ui/error-panel/ErrorPanel";
import InvoiceStandaloneSidebar from "../../components/invoice-standalone-sidebar/InvoiceStandaloneSidebar";
import { IInvoiceStandaloneReducerState } from "../../reducers/invoiceStandaloneReducer";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../..";
import { fetchCustomerLanguage, fetchInvoiceStandalone } from "../../actions/invoiceStandaloneActions";
import ProgressIndicator from "../../components/ui/progress-indicator/ProgressIndicator";
import { IInvoiceDetails } from "../../models/Customer";
import { useParams } from "react-router";
import { changeFavIcon } from "../../utils/commonUtils";


const InvoiceStandalone: React.FC = () => {
  const [isLoadingData, setIsLoadingData] = useState(false);
  const { invoice, isFetchingInvoice, isFetchingInvoiceFailure, customerLanguage } = useSelector<AppState, IInvoiceStandaloneReducerState>(state => state.invoiceStandaloneReducer);
  const dispatch = useDispatch()
  const { invoiceCode } = useParams<{ invoiceCode: string }>()

  useLayoutEffect(() => {
    const fetchData = async () => {
      setIsLoadingData(true);
      try {
        const { response } = await dispatch(fetchInvoiceStandalone(invoiceCode)) as any as { response: IInvoiceDetails };
        await dispatch(fetchCustomerLanguage(response.companyDomain, response.customerUniqueId));
        changeFavIcon(response.brandingFaviconUrl);
        setIsLoadingData(false);
      } catch (err) {
        console.log(err);
        setIsLoadingData(false);
      }
    };

    fetchData()
  }, []);


  if(isLoadingData) {
    return <ProgressIndicator color="blue" coverage="full-screen"></ProgressIndicator>
  }

  if (isFetchingInvoiceFailure) {
    return (
      <div className="invoice-standalone__error">
        <ErrorPanel title="ERROR_FETCHING_STANDALONE_INVOICE" />
      </div>
    );
  }

  if (isFetchingInvoice || !invoice) {
    return null;
  }

  const getCustomPrinterStyle = () => {
    return `
      .receipt-details__header, .receipt-details__footer {
        background-color: #${invoice.brandingLogoBackgroundColor};
        /*background property is ignored in print preview mode, therefore we use box shadow that is not*/
        box-shadow:  inset 0 0 0 1000px #${invoice.brandingLogoBackgroundColor};
      }

      @media print {
        .fixed-sidebar, *[data-html2canvas-ignore] {
          display: none !important;
        }
       }
    `;
  };

  const applyBranding = () => {
    let style = "";

    if (invoice.brandingHighlightColor) {
      style += `
        .content-panel:not(.invoice-standalone-sidebar-unpaid):not(.invoice-standalone-sidebar-error):not(.invoice-standalone-sidebar-paid):not(.invoice-standalone-sidebar-pending) > .cc-text:first-child {
          color: #${invoice.brandingHighlightColor};
        }
        .warning-replace-card:before {
          color: #${invoice.brandingHighlightColor} !important;
        }
        button {
          background: #${invoice.brandingHighlightColor} !important;
        }
        .lds-roller div:after {
          background: #${invoice.brandingHighlightColor};
        }
        .invoice-standalone-sidebar .cc-form-label > label {
          color: #${invoice.brandingHighlightColor};
        }
      `;
    }

    if (invoice.brandingLogoBackgroundColor) {
      style += `
        .loading_modal__header {
          background: #${invoice.brandingLogoBackgroundColor} !important;
        }
      `;
    }

    if (invoice.brandingLogo) {
      style += `
        .loading_modal__header__logo {
          width: 85px;
          height: -webkit-fill-available;
          box-sizing:border-box;
          padding-left: 85px;
          background: url(${invoice.brandingLogo}) left top no-repeat;
          background-size: contain;
        }
      `;
    }
    return style;
  };

  return (
    <div className="invoice-standalone">
      <style>
        {getCustomPrinterStyle()}
        {applyBranding()}
      </style>

      <InvoicePrint locale={customerLanguage} isStandAlone data={invoice} />
      <InvoiceStandaloneSidebar />
    </div>
  );
};

export default InvoiceStandalone