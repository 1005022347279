export enum CreditNoteStatus {
  PAID = "Paid",
  UNPAID = "Unpaid",
  PENDING = "Pending",
  FAILED = "Failed",
  WRITTEN_OFF = "WrittenOff",
  CLEARING = "Clearing"
}

export interface CreditNote {
  creditNoteUniqueId: string,
  amount: string,
  createdOn: Date,
  currency: string,
  customerFirstName: string,
  customerLastName: string,
  customerFullName: string,
  customerUniqueId: string,
  customerId: number,
  status: CreditNoteStatus
}

export interface ICreditNotesPaginated {
  currentPage: number,
  pageCount: number,
  pageSize: number,
  rowCount: number,
  results?: Array<CreditNote>
}