export enum AccountingIntegrationType {
  FREEAGENT = "FreeAgent",
  QUICKBOOKS = "Quickbooks",
  WEBEX = "Webex"
}

export enum AccountType {
  INCOME = "Income",
  EXPENSE = "Expense",
  OTHERCURRENTASSET = "Other Current Asset",
  BANK = "Bank"
}