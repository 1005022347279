import React, { FormEvent, useState } from "react";
import { AppState } from "../../../../..";
import { useDispatch, useSelector } from "react-redux";
import { Col, Row } from "react-grid-system";
import Button from "../../../../ui/button/Button";
import { IAuthReducerState } from "../../../../../reducers/authReducer";
import Text from "../../../../ui/text/Text";
import { ICheckoutAccountManagementReducerState } from "../../../../../reducers/checkoutAccountManagementReducer";
import {
  resetCheckoutMgmtTerminology
} from "../../../../../actions/checkoutAccountManagementActions";
import Panel from "../../../../ui/panel/Panel";
import { grpcUnaryCall } from "../../../../../utils/grpc/grpcUtils";
import { CompanyCheckoutConfigServiceClient } from "../../../../../utils/grpc/generated/Billsby.Protos/core/private/companyconfig/companycheckoutconfig/CompanycheckoutconfigServiceClientPb";
import { UpsertTerminologiesCheckoutConfigRequest } from "../../../../../utils/grpc/generated/Billsby.Protos/core/private/companyconfig/companycheckoutconfig/companycheckoutconfig_pb";
import { ConfigConstants } from "../../../../../utils/config";
import TerminologyInput from "./TerminologyInput";


const TerminologyCustomization: React.FC = () => {
  const { currentCompanyDomain } = useSelector<AppState, IAuthReducerState>(state => state.auth)
  const { checkoutManagement } = useSelector<AppState, ICheckoutAccountManagementReducerState>(state => state.checkoutAccountManagementReducer)
  const dispatch = useDispatch<Function>()

  const [isUpdatingTerminology, setIsUpdatingTerminology] = useState(false);

  const {
    terminologyPlanPlural,
    terminologyPlanSingular,
    terminologyProductPlural,
    terminologyProductSingular,
    terminologyUnitPlural,
    terminologyUnitSingular,
    terminologyAddonSingular,
    terminologyAddonPlural,
    terminologyAllowanceSingular,
    terminologyAllowancePlural,
    terminologySetupFeeSingular,
    terminologySetupFeePlural,
    terminologySubscriptionSingular,
    terminologySubscriptionPlural,
    terminologyDiscountSingular,
    terminologyDiscountPlural,
    terminologyCouponSingular,
    terminologyCouponPlural
  } = checkoutManagement;

  const handleUpdateTerminology = async (evt: FormEvent) => {
    evt.preventDefault();
    setIsUpdatingTerminology(true);
    const upsertTerminologiesCheckoutConfigRequest = new UpsertTerminologiesCheckoutConfigRequest();
    upsertTerminologiesCheckoutConfigRequest.setCompanyDomain(currentCompanyDomain);
    upsertTerminologiesCheckoutConfigRequest.setTerminologyAddonPlural(terminologyAddonPlural);
    upsertTerminologiesCheckoutConfigRequest.setTerminologyAddonSingular(terminologyAddonSingular);
    upsertTerminologiesCheckoutConfigRequest.setTerminologyAllowancePlural(terminologyAllowancePlural);
    upsertTerminologiesCheckoutConfigRequest.setTerminologyAllowanceSingular(terminologyAllowanceSingular);
    upsertTerminologiesCheckoutConfigRequest.setTerminologyPlanPlural(terminologyPlanPlural);
    upsertTerminologiesCheckoutConfigRequest.setTerminologyPlanSingular(terminologyPlanSingular);
    upsertTerminologiesCheckoutConfigRequest.setTerminologyProductPlural(terminologyProductPlural);
    upsertTerminologiesCheckoutConfigRequest.setTerminologyProductSingular(terminologyProductSingular);
    upsertTerminologiesCheckoutConfigRequest.setTerminologySetupFeePlural(terminologySetupFeePlural);
    upsertTerminologiesCheckoutConfigRequest.setTerminologySetupFeeSingular(terminologySetupFeeSingular);
    upsertTerminologiesCheckoutConfigRequest.setTerminologySubscriptionPlural(terminologySubscriptionPlural);
    upsertTerminologiesCheckoutConfigRequest.setTerminologySubscriptionSingular(terminologySubscriptionSingular);
    upsertTerminologiesCheckoutConfigRequest.setTerminologyUnitPlural(terminologyUnitPlural);
    upsertTerminologiesCheckoutConfigRequest.setTerminologyUnitSingular(terminologyUnitSingular);
    upsertTerminologiesCheckoutConfigRequest.setTerminologyDiscountPlural(terminologyDiscountPlural);
    upsertTerminologiesCheckoutConfigRequest.setTerminologyDiscountSingular(terminologyDiscountSingular);
    upsertTerminologiesCheckoutConfigRequest.setTerminologyCouponPlural(terminologyCouponPlural);
    upsertTerminologiesCheckoutConfigRequest.setTerminologyCouponSingular(terminologyCouponSingular);

    try {
      const companyCheckoutConfigServiceClient = new CompanyCheckoutConfigServiceClient(ConfigConstants.grpcBaseUrl);
      await grpcUnaryCall(upsertTerminologiesCheckoutConfigRequest, companyCheckoutConfigServiceClient, companyCheckoutConfigServiceClient.upsertTerminologiesCheckoutConfig)
      setIsUpdatingTerminology(false);
    } catch (err) {
      setIsUpdatingTerminology(false);
      console.log(err);
    }
  };

  return (
    <Panel title="CHECKOUT_MANAGEMENT_PANEL1_TITLE" noMargin>
      <Text content="CHECKOUT_MANAGEMENT_PANEL1_CONTENT" />
      <form onSubmit={handleUpdateTerminology}>
        <TerminologyInput
          valueSingular={terminologyProductSingular}
          valuePlural={terminologyProductPlural}
          isLock={isUpdatingTerminology}
          name="product"
          singularField="terminologyProductSingular"
          pluralField="terminologyProductPlural"
          singularPlaceHolder="CHECKOUT_MANAGEMENT_PLACEHOLDER_PRODUCT_SINGLE"
          pluralPlaceHolder="CHECKOUT_MANAGEMENT_PLACEHOLDER_PRODUCT_PLURAL"
          label="CHECKOUT_MANAGEMENT_LABEL_PRODUCT_SINGLE"
          maxLength={40}
        />
        <TerminologyInput
          valueSingular={terminologyPlanSingular}
          valuePlural={terminologyPlanPlural}
          isLock={isUpdatingTerminology}
          name="plan"
          singularField="terminologyPlanSingular"
          pluralField="terminologyPlanPlural"
          singularPlaceHolder="CHECKOUT_MANAGEMENT_PLACEHOLDER_PLAN_SINGLE"
          pluralPlaceHolder="CHECKOUT_MANAGEMENT_PLACEHOLDER_PLAN_PLURAL"
          label="CHECKOUT_MANAGEMENT_LABEL_PLAN_SINGLE"
          maxLength={15}
        />
        <TerminologyInput
          valueSingular={terminologyUnitSingular}
          valuePlural={terminologyUnitPlural}
          isLock={isUpdatingTerminology}
          name="unit"
          singularField="terminologyUnitSingular"
          pluralField="terminologyUnitPlural"
          singularPlaceHolder="CHECKOUT_MANAGEMENT_LABEL_UNIT_SINGLE"
          pluralPlaceHolder="CHECKOUT_MANAGEMENT_PLACEHOLDER_UNIT_PLURAL"
          label="CHECKOUT_MANAGEMENT_LABEL_UNIT_SINGLE"
          maxLength={30}
        />
        <TerminologyInput
          valueSingular={terminologyAddonSingular}
          valuePlural={terminologyAddonPlural}
          isLock={isUpdatingTerminology}
          name="addon"
          singularField="terminologyAddonSingular"
          pluralField="terminologyAddonPlural"
          singularPlaceHolder="CHECKOUT_MANAGEMENT_PLACEHOLDER_ADD_ON_SINGLE"
          pluralPlaceHolder="CHECKOUT_MANAGEMENT_PLACEHOLDER_ADD_ON_PLURAL"
          label="CHECKOUT_MANAGEMENT_LABEL_ADD_ON_SINGLE"
          maxLength={15}
        />
        <TerminologyInput
          valueSingular={terminologyAllowanceSingular}
          valuePlural={terminologyAllowancePlural}
          isLock={isUpdatingTerminology}
          name="allowance"
          singularField="terminologyAllowanceSingular"
          pluralField="terminologyAllowancePlural"
          singularPlaceHolder="CHECKOUT_MANAGEMENT_PLACEHOLDER_ALLOWANCE_SINGLE"
          pluralPlaceHolder="CHECKOUT_MANAGEMENT_PLACEHOLDER_ALLOWANCE_PLURAL"
          label="CHECKOUT_MANAGEMENT_LABEL_ALLOWANCE_SINGLE"
          maxLength={15}
        />    
        <TerminologyInput
          valueSingular={terminologyDiscountSingular}
          valuePlural={terminologyDiscountPlural}
          isLock={isUpdatingTerminology}
          name="discount"
          singularField="terminologyDiscountSingular"
          pluralField="terminologyDiscountPlural"
          singularPlaceHolder="CHECKOUT_MANAGEMENT_PLACEHOLDER_DISCOUNT_SINGLE"
          pluralPlaceHolder="CHECKOUT_MANAGEMENT_PLACEHOLDER_DISCOUNT_PLURAL"
          label="CHECKOUT_MANAGEMENT_LABEL_DISCOUNT_SINGLE"
          maxLength={15}
        />       
        <TerminologyInput
          valueSingular={terminologyCouponSingular}
          valuePlural={terminologyCouponPlural}
          isLock={isUpdatingTerminology}
          name="coupon"
          singularField="terminologyCouponSingular"
          pluralField="terminologyCouponPlural"
          singularPlaceHolder="CHECKOUT_MANAGEMENT_PLACEHOLDER_COUPON_SINGLE"
          pluralPlaceHolder="CHECKOUT_MANAGEMENT_PLACEHOLDER_COUPON_PLURAL"
          label="CHECKOUT_MANAGEMENT_LABEL_COUPON_SINGLE"
          maxLength={15}
        />   
        <TerminologyInput
          valueSingular={terminologySetupFeeSingular}
          valuePlural={terminologySetupFeePlural}
          isLock={isUpdatingTerminology}
          name="setup-fee"
          singularField="terminologySetupFeeSingular"
          pluralField="terminologySetupFeePlural"
          singularPlaceHolder="CHECKOUT_MANAGEMENT_PLACEHOLDER_SET_UP_FEE_SINGLE"
          pluralPlaceHolder="CHECKOUT_MANAGEMENT_PLACEHOLDER_SET_UP_FEE_PLURAL"
          label="CHECKOUT_MANAGEMENT_LABEL_SET_UP_FEE_SINGLE"
          maxLength={30}
        />
        <TerminologyInput
          valueSingular={terminologySubscriptionSingular}
          valuePlural={terminologySubscriptionPlural}
          isLock={isUpdatingTerminology}
          name="subscriptiopn"
          singularField="terminologySubscriptionSingular"
          pluralField="terminologySubscriptionPlural"
          singularPlaceHolder="CHECKOUT_MANAGEMENT_PLACEHOLDER_SUBSCRIPTION_SINGLE"
          pluralPlaceHolder="CHECKOUT_MANAGEMENT_PLACEHOLDER_SUBSCRIPTION_PLURAL"
          label="CHECKOUT_MANAGEMENT_LABEL_SUBSCRIPTION_SINGLE"
          maxLength={25}
        />
        <Row align="center">
          <Col md={9}>
            <Button id="update-terminilogy" type="submit" isLoading={isUpdatingTerminology} isFullWidth buttonType="general" title="CHECKOUT_ACCT_MANAGEMENT_BTN_UPDATE_PREF" />
          </Col>
          <Col md={3}>
            <Button id="cancel-update-terminilogy" isFullWidth buttonType="error" title="CHECKOUT_ACCT_MANAGEMENT_BTN_CANCEL" onClick={() => dispatch(resetCheckoutMgmtTerminology())} />
          </Col>
        </Row>
      </form>

    </Panel>
  )
}

export default TerminologyCustomization