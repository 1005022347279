export interface ICompanyDiscountCurrencyWarnings {
  discountId: number,
  currencyCode: string,
  companyWarningId: number,
  warningType: number
}

export interface ICompanyTaxCouldNotBeAppliedWarnings {
  companyWarningId: number,
  warningType: 1
}

export interface IGettingStartedSettings {
  status: GettingStartedType,
  companyDiscountCurrencyWarnings?: Array<ICompanyDiscountCurrencyWarnings>,
  isCurrencyMappingMatched?: boolean,
  deletionActiveDate?: Date,
  hasActiveDeletionRequest: boolean,
  isReadyForDeletion: boolean,
  deletedCompany?: string,
  isSuspended: boolean,
  hasUnpaidInvoices: boolean,
  hasFailedTaxes: boolean,
  unpaidAmount: number,
  unpaidAmountFormatted: string,
  unpaidInvoiceNumber: string,
  isTeammateManualAllowanceUsageUpdateAllowed: boolean,
  companyTaxCouldNotBeAppliedWarnings?: Array<ICompanyTaxCouldNotBeAppliedWarnings>
}

export enum GettingStartedType {
  GettingStarted1 = "GettingStarted1",
  GettingStarted2 = "GettingStarted2",
  PreLive = "PreLive",
  OnTrial = "OnTrial",
  Live = "Live"
}
