import React, { useState } from "react";
import counterpart from "counterpart";
import FormGroup from "../../../../ui/form-group/FormGroup";
import { Row, Col } from "react-grid-system";
import FormLabel from "../../../../ui/form-label/FormLabel";
import Input from "../../../../ui/input/Input";
import PaymentGatewayButtons from "../payment-gateways-shared-components/PaymentGatewayButtons";
import { isInvalidGatewayFormsDetails } from "../PaymentGateways";

interface Props {
  onSave: (displayName: string, username: string, password: string, apiKey: string, apiSecret: string) => Promise<void>;
  onCancel: () => void;
  setHelpTitle: (text: string) => void;
  setHelpContent: (text: Array<string>) => void;
  initDisplayName: string;
  isSaveEnabled: boolean;
  isEdit: boolean;
}

const IxopayPaymentGatewayForm: React.FC<Props> = ({ onSave, onCancel, setHelpTitle, setHelpContent, initDisplayName, isSaveEnabled, isEdit }) => {

  const [displayName, setDisplayName] = useState(initDisplayName);
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [apiKey, setApiKey] = useState("");
  const [apiSecret, setApiSecret] = useState("");

  const clearFields = () => {
    setDisplayName(initDisplayName);
    setUsername("");
    setPassword("");
    setApiKey("");
    setApiSecret("");
  }

  const savePaymentGateway = async () => {
    await onSave(displayName, username, password, apiKey, apiSecret);
    clearFields();
  } 

  const onFocus = (inputField: string) => {
    switch (inputField) {
      case "DisplayName":
        setHelpTitle("PAYMENT_GATEWAYS_ADDED_IXOPAY_DISPLAYNAME_HELP_TITLE");
        setHelpContent(["PAYMENT_GATEWAYS_ADDED_IXOPAY_DISPLAYNAME_HELP_CONTENT_1"]);
        break;
      case "Username":
        setHelpTitle("PAYMENT_GATEWAYS_ADDED_IXOPAY_USERNAME_HELP_TITLE");
        setHelpContent(["PAYMENT_GATEWAYS_ADDED_IXOPAY_USERNAME_HELP_CONTENT_1"]);
        break;
      case "Password":
        setHelpTitle("PAYMENT_GATEWAYS_ADDED_IXOPAY_PASSWORD_HELP_TITLE");
        setHelpContent(["PAYMENT_GATEWAYS_ADDED_IXOPAY_PASSWORD_HELP_CONTENT_1"]);
        break;
      case "ApiSecret":
        setHelpTitle("PAYMENT_GATEWAYS_ADDED_IXOPAY_API_SECRET_HELP_TITLE");
        setHelpContent(["PAYMENT_GATEWAYS_ADDED_IXOPAY_API_SECRET_HELP_CONTENT_1"]);
        break;
      case "ApiKey":
        setHelpTitle("PAYMENT_GATEWAYS_ADDED_IXOPAY_API_KEY_HELP_TITLE");
        setHelpContent(["PAYMENT_GATEWAYS_ADDED_IXOPAY_API_KEY_HELP_CONTENT_1"]);
        break;
    }
  }

  return (
      <div className="payment-gateways__update">
        <FormGroup>
          <Row align="center" className="payment-gateways__update__form-group">
            <Col xs={2}>
              <FormLabel target="display-name" content="PAYMENT_GATEWAYS_IXOPAY_DISPLAY_NAME" noMargin />
            </Col>
            <Col xs={10}>
              <Input
                onFocus={() => onFocus("DisplayName")}
                id="display-name"
                placeholder={counterpart("PAYMENT_GATEWAYS_IXOPAY_DISPLAY_NAME_PLACEHOLDER")}
                value={displayName}
                onChange={(evt: any) => setDisplayName(evt.target.value)}
              />
            </Col>
          </Row>
          <Row align="center" className="payment-gateways__update__form-group">
            <Col xs={2}>
              <FormLabel target="user-name" content="PAYMENT_GATEWAYS_IXOPAY_USERNAME" noMargin />
            </Col>
            <Col xs={10}>
              <Input
                id="user-name"
                onFocus={() => onFocus("Username")}
                placeholder={counterpart("PAYMENT_GATEWAYS_IXOPAY_USERNAME_PLACEHOLDER")}
                value={username}
                onChange={(evt: any) => setUsername(evt.target.value)}
              />
            </Col>
          </Row>
          <Row align="center" className="payment-gateways__update__form-group">
            <Col xs={2}>
              <FormLabel target="password" content="PAYMENT_GATEWAYS_IXOPAY_PASSWORD" noMargin />
            </Col>
            <Col xs={10}>
              <Input
                id="password"
                onFocus={() => onFocus("Password")}
                placeholder={counterpart("PAYMENT_GATEWAYS_IXOPAY_PASSWORD_PLACEHOLDER")}
                type="password"
                value={password}
                onChange={(evt: any) => setPassword(evt.target.value)}
              />
            </Col>
          </Row>
          <Row align="center" className="payment-gateways__update__form-group">
            <Col xs={2}>
              <FormLabel target="api-key" content="PAYMENT_GATEWAYS_IXOPAY_API_KEY" noMargin />
            </Col>
            <Col xs={10}>
              <Input
                id="api-key"
                onFocus={() => onFocus("ApiKey")}
                placeholder={counterpart("PAYMENT_GATEWAYS_IXOPAY_API_KEY_PLACEHOLDER")}
                value={apiKey}
                onChange={(evt: any) => setApiKey(evt.target.value)}
              />
            </Col>
          </Row>
          <Row align="center" className="payment-gateways__update__form-group">
            <Col xs={2}>
              <FormLabel target="api-secret" content="PAYMENT_GATEWAYS_IXOPAY_API_SECRET" noMargin />
            </Col>
            <Col xs={10}>
              <Input
                id="api-secret"
                onFocus={() => onFocus("ApiSecret")}
                placeholder={counterpart("PAYMENT_GATEWAYS_IXOPAY_API_SECRET_PLACEHOLDER")}
                value={apiSecret}
                onChange={(evt: any) => setApiSecret(evt.target.value)}
              />
            </Col>
          </Row>
        </FormGroup>

        <PaymentGatewayButtons
          onSave={() => savePaymentGateway()}
          onCancel={() => {
            onCancel();
            clearFields();
          }}
          isEdit={isEdit}
          isSaveEnabled={isSaveEnabled && !isInvalidGatewayFormsDetails([displayName, username, password, apiKey, apiSecret])}
        />
      </div>
  )
}

export default IxopayPaymentGatewayForm;