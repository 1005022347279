import { makeActionCreator } from "../utils/reduxUtils";
import API from "../utils/API";
import { IGettingStartedSettings, GettingStartedType } from "../models/GettingStarted";
import { AppState } from "..";
import { IParsedToken } from "../models/Auth";
import { storeRemove, storeSet } from "../utils/storeUtils";
import { DeleteCompanyRequest, RequestDeleteCompanyRequest, CancelDeleteCompanyRequestRequest } from "../utils/grpc/generated/Billsby.Protos/core/private/companies/company_pb";
import { CompanyServiceClient } from "../utils/grpc/generated/Billsby.Protos/core/private/companies/CompanyServiceClientPb";
import { ConfigConstants } from "../utils/config";
import { grpcUnaryCall } from "../utils/grpc/grpcUtils";
import { GetTrialBalanceRequest } from "../utils/grpc/generated/Billsby.Protos/revenues/private/company/company_pb";
import { CompanyServiceClient as CompanyTrialServiceClient } from "../utils/grpc/generated/Billsby.Protos/revenues/private/company/CompanyServiceClientPb"
import { SetTeammateAllowanceManualUpdateRequest } from "../utils/grpc/generated/Billsby.Protos/core/private/dashboards/dashboards_pb";
import { DashboardsServiceClient } from "../utils/grpc/generated/Billsby.Protos/core/private/dashboards/DashboardsServiceClientPb";
import { BILLSBY_AUTH_DATA_CONTROL, BILLSBY_SELECTED_COMPANY} from "../utils/constants";

export const INIT_TOKEN_DATA = "INIT_TOKEN_DATA";

export const INIT_READMEIO_LINKS_DATA = "INIT_READMEIO_LINKS_DATA";
export const initReadmeioLinks = makeActionCreator(INIT_READMEIO_LINKS_DATA);

export const SET_IS_BILLSBY_PRELIVE_FIRST_TIME = "SET_IS_BILLSBY_PRELIVE_FIRST_TIME";
export const setIsBillsbyLiveFirstTime = makeActionCreator(SET_IS_BILLSBY_PRELIVE_FIRST_TIME, "payload");

export const RESET_AUTH_STATE = "RESET_AUTH_STATE";
export const resetAuthState = makeActionCreator(RESET_AUTH_STATE);

export const SET_BILLSBY_COMPANY_GOOGLE_ANALYTICS_UID = "SET_BILLSBY_COMPANY_GOOGLE_ANALYTICS_UID"
export const setBillsbyCompanyGoogleAnalyticsUID = makeActionCreator(SET_BILLSBY_COMPANY_GOOGLE_ANALYTICS_UID, "payload");

export const FETCH_GETTING_STARTED_REQUEST = "FETCH_GETTING_STARTED_REQUEST";
export const FETCH_GETTING_STARTED_SUCCESS = "FETCH_GETTING_STARTED_SUCCESS";
export const FETCH_GETTING_STARTED_FAILURE = "FETCH_GETTING_STARTED_FAILURE";

export const UPDATE_GETTING_STARTED_REQUEST = "UPDATE_GETTING_STARTED_REQUEST";
export const UPDATE_GETTING_STARTED_SUCCESS = "UPDATE_GETTING_STARTED_SUCCESS";
export const UPDATE_GETTING_STARTED_FAILURE = "UPDATE_GETTING_STARTED_FAILURE";

export const FETCH_COMPANIES_REQUEST = "FETCH_COMPANIES_REQUEST";
export const FETCH_COMPANIES_SUCCESS = "FETCH_COMPANIES_SUCCESS";
export const FETCH_COMPANIES_FAILURE = "FETCH_COMPANIES_FAILURE";

export const REQUEST_COMPANY_DELETION_REQUEST = "REQUEST_COMPANY_DELETION_REQUEST";
export const REQUEST_COMPANY_DELETION_SUCCESS = "REQUEST_COMPANY_DELETION_SUCCESS";
export const REQUEST_COMPANY_DELETION_FAILURE = "REQUEST_COMPANY_DELETION_FAILURE";

export const CANCEL_REQUEST_COMPANY_DELETION_REQUEST = "CANCEL_REQUEST_COMPANY_DELETION_REQUEST";
export const CANCEL_REQUEST_COMPANY_DELETION_SUCCESS = "CANCEL_REQUEST_COMPANY_DELETION_SUCCESS";
export const CANCEL_REQUEST_COMPANY_DELETION_FAILURE = "CANCEL_REQUEST_COMPANY_DELETION_FAILURE";

export const DELETE_COMPANY_REQUEST = "DELETE_COMPANY_REQUEST";
export const DELETE_COMPANY_SUCCESS = "DELETE_COMPANY_SUCCESS";
export const DELETE_COMPANY_FAILURE = "DELETE_COMPANY_FAILURE";

export const FETCH_TRIAL_BALANCE_REQUEST = "FETCH_TRIAL_BALANCE_REQUEST";
export const FETCH_TRIAL_BALANCE_SUCCESS = "FETCH_TRIAL_BALANCE_SUCCESS";
export const FETCH_TRIAL_BALANCE_FAILURE = "FETCH_TRIAL_BALANCE_FAILURE";

export const UPDATE_TEAMMATE_ALLOW_MANUAL_UPDATE_REQUEST = "UPDATE_TEAMMATE_ALLOW_MANUAL_UPDATE_REQUEST";
export const UPDATE_TEAMMATE_ALLOW_MANUAL_UPDATE_SUCCESS = "UPDATE_TEAMMATE_ALLOW_MANUAL_UPDATE_SUCCESS";
export const UPDATE_TEAMMATE_ALLOW_MANUAL_UPDATE_FAILURE = "UPDATE_TEAMMATE_ALLOW_MANUAL_UPDATE_FAILURE";

export const SET_SHOW_BILLING_MODAL = "SET_SHOW_BILLING_MODAL"
export const setShowBillingModal = makeActionCreator(SET_SHOW_BILLING_MODAL, "payload")

export const fetchGettingStartedSettings = (companyDomain: string) => {
  return {
    types: [FETCH_GETTING_STARTED_REQUEST, FETCH_GETTING_STARTED_SUCCESS, FETCH_GETTING_STARTED_FAILURE],
    callAPI: () => API.fetchGettingStartedSettings(companyDomain)
  }
}

export const updateGettingStartedSettings = (companyDomain: string, gettingStarted: IGettingStartedSettings) => {
  return (dispatch: Function, getState: () => AppState) => {

    if (gettingStarted.status === GettingStartedType.PreLive) {
      dispatch(setIsBillsbyLiveFirstTime(true));
    }
    dispatch({
      types: [UPDATE_GETTING_STARTED_REQUEST, UPDATE_GETTING_STARTED_SUCCESS, UPDATE_GETTING_STARTED_FAILURE],
      callAPI: () => API.updateGettingStartedSettings(companyDomain, gettingStarted)
    });
  }
}

export const requestCompanyDeletion = (companyId: number) => {
  const requestDeleteCompanyRequest = new RequestDeleteCompanyRequest();
  requestDeleteCompanyRequest.setCompanyId(companyId);
  const companyServiceClient = new CompanyServiceClient(ConfigConstants.grpcBaseUrl);
  return {
    types: [REQUEST_COMPANY_DELETION_REQUEST, REQUEST_COMPANY_DELETION_SUCCESS, REQUEST_COMPANY_DELETION_FAILURE],
    callAPI: () => grpcUnaryCall(requestDeleteCompanyRequest, companyServiceClient, companyServiceClient.requestDeleteCompany)
  }
}

export const deleteCompany = (companyId: number) => {
  const deleteCompanyRequest = new DeleteCompanyRequest();
  deleteCompanyRequest.setCompanyId(companyId);
  const companyServiceClient = new CompanyServiceClient(ConfigConstants.grpcBaseUrl);
  return {
    types: [DELETE_COMPANY_REQUEST, DELETE_COMPANY_SUCCESS, DELETE_COMPANY_FAILURE],
    callAPI: () => grpcUnaryCall(deleteCompanyRequest, companyServiceClient, companyServiceClient.deleteCompany)
  }
}

export const cancelRequestCompanyDeletion = (companyId: number) => {
  const cancelDeleteCompanyRequestRequest = new CancelDeleteCompanyRequestRequest();
  cancelDeleteCompanyRequestRequest.setCompanyId(companyId);
  const companyServiceClient = new CompanyServiceClient(ConfigConstants.grpcBaseUrl);
  return {
    types: [CANCEL_REQUEST_COMPANY_DELETION_REQUEST, CANCEL_REQUEST_COMPANY_DELETION_SUCCESS, CANCEL_REQUEST_COMPANY_DELETION_FAILURE],
    callAPI: () => grpcUnaryCall(cancelDeleteCompanyRequestRequest, companyServiceClient, companyServiceClient.cancelDeleteCompanyRequest)
  }
}


export const fetchTrialBalance = (companyId: number) => {
  const getTrialBalanceRequest = new GetTrialBalanceRequest();
  getTrialBalanceRequest.setCompanyId(companyId);
  const companyServiceClient = new CompanyTrialServiceClient(ConfigConstants.grpcBaseUrl);

  return {
    types: [FETCH_TRIAL_BALANCE_REQUEST, FETCH_TRIAL_BALANCE_SUCCESS, FETCH_TRIAL_BALANCE_FAILURE],
    callAPI: () => grpcUnaryCall(getTrialBalanceRequest, companyServiceClient, companyServiceClient.getTrialBalance)
  }
}

export const initAuthData = () => {
  return (dispatch: Function, getState: () => AppState) => {
    dispatch({ type: INIT_TOKEN_DATA });
    const token: IParsedToken | null = getState().auth.parsedToken;
    if (!token) {
      return Promise.resolve();
    }
    return dispatch({
      types: [FETCH_COMPANIES_REQUEST, FETCH_COMPANIES_SUCCESS, FETCH_COMPANIES_FAILURE],
      callAPI: () => API.fetchCompanies()
    })
  }
}

export const SET_PARSED_TOKEN = "SET_PARSED_TOKEN";
export const SET_SELECTED_COMPANY_ID = "SET_SELECTED_COMPANY_ID";

export const cookieSetSyncRedux = (key: string, value: string | undefined) => {
  return (dispatch: Function, getState: () => AppState) => {
    if (!value) {
      storeRemove(key);
    } else {
      storeSet(key, value);
    }
    switch (key) {
      case BILLSBY_AUTH_DATA_CONTROL:
        dispatch({ type: SET_PARSED_TOKEN, payload: value });
        break;
      case BILLSBY_SELECTED_COMPANY:
        dispatch({ type: SET_SELECTED_COMPANY_ID, payload: value });
        break;
    }
  }
}

export const updateTeammateAllowanceManualUpdate = (companyId: number, isTeamMateAllowed: boolean) => {
  const setTeammateAllowanceManualUpdateRequest = new SetTeammateAllowanceManualUpdateRequest();
  setTeammateAllowanceManualUpdateRequest.setCompanyId(companyId);
  setTeammateAllowanceManualUpdateRequest.setIsTeammateManualUpdateAllowed(isTeamMateAllowed);
  const dashboardsServiceClient = new DashboardsServiceClient(ConfigConstants.grpcBaseUrl);
  return {
    types: [UPDATE_TEAMMATE_ALLOW_MANUAL_UPDATE_REQUEST, UPDATE_TEAMMATE_ALLOW_MANUAL_UPDATE_SUCCESS, UPDATE_TEAMMATE_ALLOW_MANUAL_UPDATE_FAILURE],
    callAPI: () => grpcUnaryCall(setTeammateAllowanceManualUpdateRequest, dashboardsServiceClient, dashboardsServiceClient.setTeammateAllowanceManualUpdate)
  }
}