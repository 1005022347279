import React, { useState } from "react";
import PageWrapper from "../../components/ui/layout/PageWrapper";
import Panel from "../../components/ui/panel/Panel";
import { IAuthReducerState } from "../../reducers/authReducer";
import Text from "../../components/ui/text/Text";
import Button from "../../components/ui/button/Button";
import counterpart from "counterpart";
import API from "../../utils/API";
import NoticePanel from "../../components/ui/notice-panel/NoticePanel";

interface IGoLiveSuccess {
  authData: IAuthReducerState
}

const GoLiveSuccess: React.FC<IGoLiveSuccess> = ({ authData }) => {
  const { companyName, currentCompanyDomain } = authData;

  const [isLoading, setIsLoading] = useState(false);
  const [hasError, setHasError] = useState(false);

  const onReviewOnG2 = async () => {
    setIsLoading(true);
    setHasError(false);

    try {
      const g2Url = await API.fetchG2ReviewURL(currentCompanyDomain) as string;
      g2Url && window.open(g2Url, "_self");
    } catch (err) {
      console.log(err);
      setHasError(true);

    } finally {
      setIsLoading(false);
    }
  }

  return (
    <>
    {hasError && <NoticePanel icon="fas fa-exclamation-circle" className="golive__success__notice-panel" type="error" content="GO_LIVE_G2_ERROR"></NoticePanel>}

    <PageWrapper className="golive__success">
      <Panel title="GO_LIVE_SUCCESS_FIRST_TITLE" translateWith={{ companyName }} className="golive__success__headingpanel">
        <Text content="GO_LIVE_SUCCESS_FIRST_SUBTITLE" noMargin />
      </Panel>

      {/* HIDE FOR NOW */}
      {/* <Panel title="GO_LIVE_SUCCESS_SECOND_TITLE" translateWith={{ companyName }}>
        <Text content="GO_LIVE_SUCCESS_SECOND_SUBTITLE1" translateWith={{ companyName }} />
        <Text content="GO_LIVE_SUCCESS_SECOND_SUBTITLE2" translateWith={{ companyName }} />
        <FormGroup>
          <Button
            id="golive-talk-to-us"
            title="GO_LIVE_SUCCESS_TALKTOUS"
            buttonType="add"
            icon="fas fa-headset"
            onClick={openIntercomIframe}
          />
        </FormGroup>
        <Text content="GO_LIVE_SUCCESS_THANKS" />
        <div className="golive__success__headofproduct">
          <img src={headOfProductLogo} alt="no img" />
          <div>
            <Text content="GO_LIVE_SUCCESS_HEAD_OF_PRODUCT1" component="span" noMargin />
            <Text content="GO_LIVE_SUCCESS_HEAD_OF_PRODUCT2" component="span" noMargin />
          </div>
        </div>
      </Panel> */}

      <Panel title="GO_LIVE_SUCCESS_THIRD_TITLE">
        <Text content="GO_LIVE_SUCCESS_THIRD_SUBTITLE1" translateWith={{ keepPricesLowLabel: <b>{counterpart("GO_LIVE_SUCCESS_THIRD_SUBTITLE_HELP")}</b> }} />
        <Text content="GO_LIVE_SUCCESS_THIRD_SUBTITLE2" />
        <Text className='golive__success__conditions' content="GO_LIVE_SUCCESS_THIRD_SUBTITLE3" noMargin />
      </Panel>

      <Button
        id="review-billsby"
        title="GO_LIVE_SUCCESS_REVIEW_BILLSBY"
        isFullWidth
        isLoading={isLoading}
        onClick={onReviewOnG2}
      />
    </PageWrapper>
    </>
  )
}

export default GoLiveSuccess;