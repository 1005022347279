import React from "react";
import Panel from "../ui/panel/Panel";
import Text from "../ui/text/Text";
import Button from "../ui/button/Button";
import { QUIZ_FIELD_TIMESCALE_CAPTURED_TYPE } from "../../utils/constants";

interface IQuizStep4 {
  onContinue: (nextStep: number, fieldType: string, fieldValue: string) => any
}

const QuizStep4: React.FC<IQuizStep4> = ({ onContinue }) => {
  return (
    <div className="quiz quiz-step4">
      <Panel title="QUIZ_STEP4_TITLE">
        <Text content="QUIZ_STEP4_SUBTITLE" />
        <Button id="quiz-step4-opt1" title="QUIZ_STEP4_OPT1" isFullWidth onClick={() => onContinue(6, QUIZ_FIELD_TIMESCALE_CAPTURED_TYPE, "1 month")} />
        <Button id="quiz-step4-opt2" title="QUIZ_STEP4_OPT2" isFullWidth onClick={() => onContinue(6, QUIZ_FIELD_TIMESCALE_CAPTURED_TYPE, "1-3 months")} />
        <Button id="quiz-step4-opt3" title="QUIZ_STEP4_OPT3" isFullWidth onClick={() => onContinue(6, QUIZ_FIELD_TIMESCALE_CAPTURED_TYPE, "3-12 months")} />
        <Button id="quiz-step4-opt4" title="QUIZ_STEP4_OPT4" isFullWidth onClick={() => onContinue(6, QUIZ_FIELD_TIMESCALE_CAPTURED_TYPE, "> 12 months")} />
        <Text content="QUIZ_STEP4_LINK" className="quiz__link" noMargin onClick={() => onContinue(6, QUIZ_FIELD_TIMESCALE_CAPTURED_TYPE, "Ignore")} />
      </Panel>
    </div>
  )
}

export default QuizStep4;