import { BillsbyAction } from "../models/BillsbyAction";
import { AppState } from "..";
import { ITag } from "../models/SubscriptionFeaturedTags";
import { SET_SUBSCRIPTION_FEATURE_TAGS, REMOVE_SUBSCRIPTION_FEATURE_TAG, FETCH_SUBSCRIPTION_FEATURE_TAGS_SUCCESS, ADD_SUBSCRIPTION_FEATURE_TAGS_SUCCESS, RESET_SUBSCRIPTION_FEATURE_TAGS } from "../actions/subscriptionFeatureTagsActions";

export interface ISubscriptionFeatureTagsState {
  featureTags: Array<ITag>,
  planFeatureTags: Array<string>,
  subscriptionFeatureTags: Array<string>
}

export const initialState = {
  featureTags: [],
  planFeatureTags: [],
  subscriptionFeatureTags: []
}

export default function subscriptionFeatureTagsReducer(state: ISubscriptionFeatureTagsState = initialState, action: BillsbyAction, store: AppState) {
  switch (action.type) {
    case FETCH_SUBSCRIPTION_FEATURE_TAGS_SUCCESS:
      return { ...state, ...action.response }
    case SET_SUBSCRIPTION_FEATURE_TAGS: {
      action.payload.name = action.payload.name.replace(/[^a-z0-9]/g, "");
      let tags = state.featureTags.slice(0);
      if (action.payload.name && action.payload.name.trim() !== "")
        tags.push(action.payload);
      return { ...state, featureTags: tags }
    }
    case REMOVE_SUBSCRIPTION_FEATURE_TAG: {
      let tags = state.featureTags.slice(0)
      tags.splice(action.payload, 1);
      return { ...state, featureTags: tags }
    }
    case ADD_SUBSCRIPTION_FEATURE_TAGS_SUCCESS:
      return { ...state, featureTags: [] }
    case RESET_SUBSCRIPTION_FEATURE_TAGS:
      return { ...initialState }
    default:
      return state;
  }
}