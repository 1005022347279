/* eslint-disable */
// source: payment_logs.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = (function() {
  if (this) { return this; }
  if (typeof window !== 'undefined') { return window; }
  if (typeof global !== 'undefined') { return global; }
  if (typeof self !== 'undefined') { return self; }
  return Function('return this')();
}.call(null));

var google_protobuf_timestamp_pb = require('google-protobuf/google/protobuf/timestamp_pb.js');
goog.object.extend(proto, google_protobuf_timestamp_pb);
var google_protobuf_wrappers_pb = require('google-protobuf/google/protobuf/wrappers_pb.js');
goog.object.extend(proto, google_protobuf_wrappers_pb);
goog.exportSymbol('proto.billing.private.paymentlogs.GetPaymentLogsPaginatedRequest', null, global);
goog.exportSymbol('proto.billing.private.paymentlogs.GetPaymentLogsPaginatedRequest.PaymentLogType', null, global);
goog.exportSymbol('proto.billing.private.paymentlogs.GetPaymentLogsPaginatedResponse', null, global);
goog.exportSymbol('proto.billing.private.paymentlogs.GetPaymentLogsRequest', null, global);
goog.exportSymbol('proto.billing.private.paymentlogs.GetPaymentLogsResponse', null, global);
goog.exportSymbol('proto.billing.private.paymentlogs.Log', null, global);
goog.exportSymbol('proto.billing.private.paymentlogs.LogExtended', null, global);
goog.exportSymbol('proto.billing.private.paymentlogs.PaymentLogStatus', null, global);
goog.exportSymbol('proto.billing.private.paymentlogs.PaymentSourceType', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.billing.private.paymentlogs.GetPaymentLogsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.billing.private.paymentlogs.GetPaymentLogsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.billing.private.paymentlogs.GetPaymentLogsRequest.displayName = 'proto.billing.private.paymentlogs.GetPaymentLogsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.billing.private.paymentlogs.GetPaymentLogsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.billing.private.paymentlogs.GetPaymentLogsResponse.repeatedFields_, null);
};
goog.inherits(proto.billing.private.paymentlogs.GetPaymentLogsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.billing.private.paymentlogs.GetPaymentLogsResponse.displayName = 'proto.billing.private.paymentlogs.GetPaymentLogsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.billing.private.paymentlogs.Log = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.billing.private.paymentlogs.Log, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.billing.private.paymentlogs.Log.displayName = 'proto.billing.private.paymentlogs.Log';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.billing.private.paymentlogs.GetPaymentLogsPaginatedRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.billing.private.paymentlogs.GetPaymentLogsPaginatedRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.billing.private.paymentlogs.GetPaymentLogsPaginatedRequest.displayName = 'proto.billing.private.paymentlogs.GetPaymentLogsPaginatedRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.billing.private.paymentlogs.GetPaymentLogsPaginatedResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.billing.private.paymentlogs.GetPaymentLogsPaginatedResponse.repeatedFields_, null);
};
goog.inherits(proto.billing.private.paymentlogs.GetPaymentLogsPaginatedResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.billing.private.paymentlogs.GetPaymentLogsPaginatedResponse.displayName = 'proto.billing.private.paymentlogs.GetPaymentLogsPaginatedResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.billing.private.paymentlogs.LogExtended = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.billing.private.paymentlogs.LogExtended, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.billing.private.paymentlogs.LogExtended.displayName = 'proto.billing.private.paymentlogs.LogExtended';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.billing.private.paymentlogs.GetPaymentLogsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.billing.private.paymentlogs.GetPaymentLogsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.billing.private.paymentlogs.GetPaymentLogsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing.private.paymentlogs.GetPaymentLogsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    lastName: jspb.Message.getFieldWithDefault(msg, 1, ""),
    zipCode: jspb.Message.getFieldWithDefault(msg, 2, ""),
    lastFourDigits: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.billing.private.paymentlogs.GetPaymentLogsRequest}
 */
proto.billing.private.paymentlogs.GetPaymentLogsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.billing.private.paymentlogs.GetPaymentLogsRequest;
  return proto.billing.private.paymentlogs.GetPaymentLogsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.billing.private.paymentlogs.GetPaymentLogsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.billing.private.paymentlogs.GetPaymentLogsRequest}
 */
proto.billing.private.paymentlogs.GetPaymentLogsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setLastName(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setZipCode(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setLastFourDigits(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.billing.private.paymentlogs.GetPaymentLogsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.billing.private.paymentlogs.GetPaymentLogsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.billing.private.paymentlogs.GetPaymentLogsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing.private.paymentlogs.GetPaymentLogsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getLastName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getZipCode();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getLastFourDigits();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional string last_name = 1;
 * @return {string}
 */
proto.billing.private.paymentlogs.GetPaymentLogsRequest.prototype.getLastName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.billing.private.paymentlogs.GetPaymentLogsRequest} returns this
 */
proto.billing.private.paymentlogs.GetPaymentLogsRequest.prototype.setLastName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string zip_code = 2;
 * @return {string}
 */
proto.billing.private.paymentlogs.GetPaymentLogsRequest.prototype.getZipCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.billing.private.paymentlogs.GetPaymentLogsRequest} returns this
 */
proto.billing.private.paymentlogs.GetPaymentLogsRequest.prototype.setZipCode = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string last_four_digits = 3;
 * @return {string}
 */
proto.billing.private.paymentlogs.GetPaymentLogsRequest.prototype.getLastFourDigits = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.billing.private.paymentlogs.GetPaymentLogsRequest} returns this
 */
proto.billing.private.paymentlogs.GetPaymentLogsRequest.prototype.setLastFourDigits = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.billing.private.paymentlogs.GetPaymentLogsResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.billing.private.paymentlogs.GetPaymentLogsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.billing.private.paymentlogs.GetPaymentLogsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.billing.private.paymentlogs.GetPaymentLogsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing.private.paymentlogs.GetPaymentLogsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    paymentLogsList: jspb.Message.toObjectList(msg.getPaymentLogsList(),
    proto.billing.private.paymentlogs.Log.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.billing.private.paymentlogs.GetPaymentLogsResponse}
 */
proto.billing.private.paymentlogs.GetPaymentLogsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.billing.private.paymentlogs.GetPaymentLogsResponse;
  return proto.billing.private.paymentlogs.GetPaymentLogsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.billing.private.paymentlogs.GetPaymentLogsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.billing.private.paymentlogs.GetPaymentLogsResponse}
 */
proto.billing.private.paymentlogs.GetPaymentLogsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.billing.private.paymentlogs.Log;
      reader.readMessage(value,proto.billing.private.paymentlogs.Log.deserializeBinaryFromReader);
      msg.addPaymentLogs(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.billing.private.paymentlogs.GetPaymentLogsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.billing.private.paymentlogs.GetPaymentLogsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.billing.private.paymentlogs.GetPaymentLogsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing.private.paymentlogs.GetPaymentLogsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPaymentLogsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.billing.private.paymentlogs.Log.serializeBinaryToWriter
    );
  }
};


/**
 * repeated Log payment_logs = 1;
 * @return {!Array<!proto.billing.private.paymentlogs.Log>}
 */
proto.billing.private.paymentlogs.GetPaymentLogsResponse.prototype.getPaymentLogsList = function() {
  return /** @type{!Array<!proto.billing.private.paymentlogs.Log>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.billing.private.paymentlogs.Log, 1));
};


/**
 * @param {!Array<!proto.billing.private.paymentlogs.Log>} value
 * @return {!proto.billing.private.paymentlogs.GetPaymentLogsResponse} returns this
*/
proto.billing.private.paymentlogs.GetPaymentLogsResponse.prototype.setPaymentLogsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.billing.private.paymentlogs.Log=} opt_value
 * @param {number=} opt_index
 * @return {!proto.billing.private.paymentlogs.Log}
 */
proto.billing.private.paymentlogs.GetPaymentLogsResponse.prototype.addPaymentLogs = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.billing.private.paymentlogs.Log, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.billing.private.paymentlogs.GetPaymentLogsResponse} returns this
 */
proto.billing.private.paymentlogs.GetPaymentLogsResponse.prototype.clearPaymentLogsList = function() {
  return this.setPaymentLogsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.billing.private.paymentlogs.Log.prototype.toObject = function(opt_includeInstance) {
  return proto.billing.private.paymentlogs.Log.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.billing.private.paymentlogs.Log} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing.private.paymentlogs.Log.toObject = function(includeInstance, msg) {
  var f, obj = {
    reference: jspb.Message.getFieldWithDefault(msg, 1, ""),
    mainBody: jspb.Message.getFieldWithDefault(msg, 2, ""),
    createdOn: (f = msg.getCreatedOn()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    paymentStatus: jspb.Message.getFieldWithDefault(msg, 4, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.billing.private.paymentlogs.Log}
 */
proto.billing.private.paymentlogs.Log.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.billing.private.paymentlogs.Log;
  return proto.billing.private.paymentlogs.Log.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.billing.private.paymentlogs.Log} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.billing.private.paymentlogs.Log}
 */
proto.billing.private.paymentlogs.Log.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setReference(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setMainBody(value);
      break;
    case 3:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setCreatedOn(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setPaymentStatus(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.billing.private.paymentlogs.Log.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.billing.private.paymentlogs.Log.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.billing.private.paymentlogs.Log} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing.private.paymentlogs.Log.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getReference();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getMainBody();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getCreatedOn();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getPaymentStatus();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
};


/**
 * optional string reference = 1;
 * @return {string}
 */
proto.billing.private.paymentlogs.Log.prototype.getReference = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.billing.private.paymentlogs.Log} returns this
 */
proto.billing.private.paymentlogs.Log.prototype.setReference = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string main_body = 2;
 * @return {string}
 */
proto.billing.private.paymentlogs.Log.prototype.getMainBody = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.billing.private.paymentlogs.Log} returns this
 */
proto.billing.private.paymentlogs.Log.prototype.setMainBody = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional google.protobuf.Timestamp created_on = 3;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.billing.private.paymentlogs.Log.prototype.getCreatedOn = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 3));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.billing.private.paymentlogs.Log} returns this
*/
proto.billing.private.paymentlogs.Log.prototype.setCreatedOn = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.billing.private.paymentlogs.Log} returns this
 */
proto.billing.private.paymentlogs.Log.prototype.clearCreatedOn = function() {
  return this.setCreatedOn(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.billing.private.paymentlogs.Log.prototype.hasCreatedOn = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional string payment_status = 4;
 * @return {string}
 */
proto.billing.private.paymentlogs.Log.prototype.getPaymentStatus = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.billing.private.paymentlogs.Log} returns this
 */
proto.billing.private.paymentlogs.Log.prototype.setPaymentStatus = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.billing.private.paymentlogs.GetPaymentLogsPaginatedRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.billing.private.paymentlogs.GetPaymentLogsPaginatedRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.billing.private.paymentlogs.GetPaymentLogsPaginatedRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing.private.paymentlogs.GetPaymentLogsPaginatedRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    search: jspb.Message.getFieldWithDefault(msg, 1, ""),
    paymentLogStatusFilter: jspb.Message.getFieldWithDefault(msg, 2, 0),
    paymentLogTypeFilter: jspb.Message.getFieldWithDefault(msg, 3, 0),
    companyId: jspb.Message.getFieldWithDefault(msg, 4, 0),
    page: jspb.Message.getFieldWithDefault(msg, 5, 0),
    pageSize: jspb.Message.getFieldWithDefault(msg, 6, 0),
    orderBy: jspb.Message.getFieldWithDefault(msg, 7, ""),
    orderByDescending: jspb.Message.getFieldWithDefault(msg, 8, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.billing.private.paymentlogs.GetPaymentLogsPaginatedRequest}
 */
proto.billing.private.paymentlogs.GetPaymentLogsPaginatedRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.billing.private.paymentlogs.GetPaymentLogsPaginatedRequest;
  return proto.billing.private.paymentlogs.GetPaymentLogsPaginatedRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.billing.private.paymentlogs.GetPaymentLogsPaginatedRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.billing.private.paymentlogs.GetPaymentLogsPaginatedRequest}
 */
proto.billing.private.paymentlogs.GetPaymentLogsPaginatedRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setSearch(value);
      break;
    case 2:
      var value = /** @type {!proto.billing.private.paymentlogs.PaymentLogStatus} */ (reader.readEnum());
      msg.setPaymentLogStatusFilter(value);
      break;
    case 3:
      var value = /** @type {!proto.billing.private.paymentlogs.GetPaymentLogsPaginatedRequest.PaymentLogType} */ (reader.readEnum());
      msg.setPaymentLogTypeFilter(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setCompanyId(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPage(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPageSize(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setOrderBy(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setOrderByDescending(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.billing.private.paymentlogs.GetPaymentLogsPaginatedRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.billing.private.paymentlogs.GetPaymentLogsPaginatedRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.billing.private.paymentlogs.GetPaymentLogsPaginatedRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing.private.paymentlogs.GetPaymentLogsPaginatedRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSearch();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getPaymentLogStatusFilter();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
  f = message.getPaymentLogTypeFilter();
  if (f !== 0.0) {
    writer.writeEnum(
      3,
      f
    );
  }
  f = message.getCompanyId();
  if (f !== 0) {
    writer.writeInt32(
      4,
      f
    );
  }
  f = message.getPage();
  if (f !== 0) {
    writer.writeInt32(
      5,
      f
    );
  }
  f = message.getPageSize();
  if (f !== 0) {
    writer.writeInt32(
      6,
      f
    );
  }
  f = message.getOrderBy();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getOrderByDescending();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
};


/**
 * @enum {number}
 */
proto.billing.private.paymentlogs.GetPaymentLogsPaginatedRequest.PaymentLogType = {
  UNSPECIFIED: 0,
  WITH_CUSTOMER_ID: 1,
  WITHOUT_CUSTOMER_ID: 2
};

/**
 * optional string search = 1;
 * @return {string}
 */
proto.billing.private.paymentlogs.GetPaymentLogsPaginatedRequest.prototype.getSearch = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.billing.private.paymentlogs.GetPaymentLogsPaginatedRequest} returns this
 */
proto.billing.private.paymentlogs.GetPaymentLogsPaginatedRequest.prototype.setSearch = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional PaymentLogStatus payment_log_status_filter = 2;
 * @return {!proto.billing.private.paymentlogs.PaymentLogStatus}
 */
proto.billing.private.paymentlogs.GetPaymentLogsPaginatedRequest.prototype.getPaymentLogStatusFilter = function() {
  return /** @type {!proto.billing.private.paymentlogs.PaymentLogStatus} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.billing.private.paymentlogs.PaymentLogStatus} value
 * @return {!proto.billing.private.paymentlogs.GetPaymentLogsPaginatedRequest} returns this
 */
proto.billing.private.paymentlogs.GetPaymentLogsPaginatedRequest.prototype.setPaymentLogStatusFilter = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};


/**
 * optional PaymentLogType payment_log_type_filter = 3;
 * @return {!proto.billing.private.paymentlogs.GetPaymentLogsPaginatedRequest.PaymentLogType}
 */
proto.billing.private.paymentlogs.GetPaymentLogsPaginatedRequest.prototype.getPaymentLogTypeFilter = function() {
  return /** @type {!proto.billing.private.paymentlogs.GetPaymentLogsPaginatedRequest.PaymentLogType} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {!proto.billing.private.paymentlogs.GetPaymentLogsPaginatedRequest.PaymentLogType} value
 * @return {!proto.billing.private.paymentlogs.GetPaymentLogsPaginatedRequest} returns this
 */
proto.billing.private.paymentlogs.GetPaymentLogsPaginatedRequest.prototype.setPaymentLogTypeFilter = function(value) {
  return jspb.Message.setProto3EnumField(this, 3, value);
};


/**
 * optional int32 company_id = 4;
 * @return {number}
 */
proto.billing.private.paymentlogs.GetPaymentLogsPaginatedRequest.prototype.getCompanyId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.billing.private.paymentlogs.GetPaymentLogsPaginatedRequest} returns this
 */
proto.billing.private.paymentlogs.GetPaymentLogsPaginatedRequest.prototype.setCompanyId = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional int32 page = 5;
 * @return {number}
 */
proto.billing.private.paymentlogs.GetPaymentLogsPaginatedRequest.prototype.getPage = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.billing.private.paymentlogs.GetPaymentLogsPaginatedRequest} returns this
 */
proto.billing.private.paymentlogs.GetPaymentLogsPaginatedRequest.prototype.setPage = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional int32 page_size = 6;
 * @return {number}
 */
proto.billing.private.paymentlogs.GetPaymentLogsPaginatedRequest.prototype.getPageSize = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.billing.private.paymentlogs.GetPaymentLogsPaginatedRequest} returns this
 */
proto.billing.private.paymentlogs.GetPaymentLogsPaginatedRequest.prototype.setPageSize = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * optional string order_by = 7;
 * @return {string}
 */
proto.billing.private.paymentlogs.GetPaymentLogsPaginatedRequest.prototype.getOrderBy = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.billing.private.paymentlogs.GetPaymentLogsPaginatedRequest} returns this
 */
proto.billing.private.paymentlogs.GetPaymentLogsPaginatedRequest.prototype.setOrderBy = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string order_by_descending = 8;
 * @return {string}
 */
proto.billing.private.paymentlogs.GetPaymentLogsPaginatedRequest.prototype.getOrderByDescending = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.billing.private.paymentlogs.GetPaymentLogsPaginatedRequest} returns this
 */
proto.billing.private.paymentlogs.GetPaymentLogsPaginatedRequest.prototype.setOrderByDescending = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.billing.private.paymentlogs.GetPaymentLogsPaginatedResponse.repeatedFields_ = [5];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.billing.private.paymentlogs.GetPaymentLogsPaginatedResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.billing.private.paymentlogs.GetPaymentLogsPaginatedResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.billing.private.paymentlogs.GetPaymentLogsPaginatedResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing.private.paymentlogs.GetPaymentLogsPaginatedResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    currentPage: jspb.Message.getFieldWithDefault(msg, 1, 0),
    pageCount: jspb.Message.getFieldWithDefault(msg, 2, 0),
    pageSize: jspb.Message.getFieldWithDefault(msg, 3, 0),
    rowCount: jspb.Message.getFieldWithDefault(msg, 4, 0),
    paymentLogsList: jspb.Message.toObjectList(msg.getPaymentLogsList(),
    proto.billing.private.paymentlogs.LogExtended.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.billing.private.paymentlogs.GetPaymentLogsPaginatedResponse}
 */
proto.billing.private.paymentlogs.GetPaymentLogsPaginatedResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.billing.private.paymentlogs.GetPaymentLogsPaginatedResponse;
  return proto.billing.private.paymentlogs.GetPaymentLogsPaginatedResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.billing.private.paymentlogs.GetPaymentLogsPaginatedResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.billing.private.paymentlogs.GetPaymentLogsPaginatedResponse}
 */
proto.billing.private.paymentlogs.GetPaymentLogsPaginatedResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setCurrentPage(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPageCount(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPageSize(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setRowCount(value);
      break;
    case 5:
      var value = new proto.billing.private.paymentlogs.LogExtended;
      reader.readMessage(value,proto.billing.private.paymentlogs.LogExtended.deserializeBinaryFromReader);
      msg.addPaymentLogs(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.billing.private.paymentlogs.GetPaymentLogsPaginatedResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.billing.private.paymentlogs.GetPaymentLogsPaginatedResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.billing.private.paymentlogs.GetPaymentLogsPaginatedResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing.private.paymentlogs.GetPaymentLogsPaginatedResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCurrentPage();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getPageCount();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getPageSize();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
  f = message.getRowCount();
  if (f !== 0) {
    writer.writeInt64(
      4,
      f
    );
  }
  f = message.getPaymentLogsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      5,
      f,
      proto.billing.private.paymentlogs.LogExtended.serializeBinaryToWriter
    );
  }
};


/**
 * optional int32 current_page = 1;
 * @return {number}
 */
proto.billing.private.paymentlogs.GetPaymentLogsPaginatedResponse.prototype.getCurrentPage = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.billing.private.paymentlogs.GetPaymentLogsPaginatedResponse} returns this
 */
proto.billing.private.paymentlogs.GetPaymentLogsPaginatedResponse.prototype.setCurrentPage = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int32 page_count = 2;
 * @return {number}
 */
proto.billing.private.paymentlogs.GetPaymentLogsPaginatedResponse.prototype.getPageCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.billing.private.paymentlogs.GetPaymentLogsPaginatedResponse} returns this
 */
proto.billing.private.paymentlogs.GetPaymentLogsPaginatedResponse.prototype.setPageCount = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int32 page_size = 3;
 * @return {number}
 */
proto.billing.private.paymentlogs.GetPaymentLogsPaginatedResponse.prototype.getPageSize = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.billing.private.paymentlogs.GetPaymentLogsPaginatedResponse} returns this
 */
proto.billing.private.paymentlogs.GetPaymentLogsPaginatedResponse.prototype.setPageSize = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional int64 row_count = 4;
 * @return {number}
 */
proto.billing.private.paymentlogs.GetPaymentLogsPaginatedResponse.prototype.getRowCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.billing.private.paymentlogs.GetPaymentLogsPaginatedResponse} returns this
 */
proto.billing.private.paymentlogs.GetPaymentLogsPaginatedResponse.prototype.setRowCount = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * repeated LogExtended payment_logs = 5;
 * @return {!Array<!proto.billing.private.paymentlogs.LogExtended>}
 */
proto.billing.private.paymentlogs.GetPaymentLogsPaginatedResponse.prototype.getPaymentLogsList = function() {
  return /** @type{!Array<!proto.billing.private.paymentlogs.LogExtended>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.billing.private.paymentlogs.LogExtended, 5));
};


/**
 * @param {!Array<!proto.billing.private.paymentlogs.LogExtended>} value
 * @return {!proto.billing.private.paymentlogs.GetPaymentLogsPaginatedResponse} returns this
*/
proto.billing.private.paymentlogs.GetPaymentLogsPaginatedResponse.prototype.setPaymentLogsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 5, value);
};


/**
 * @param {!proto.billing.private.paymentlogs.LogExtended=} opt_value
 * @param {number=} opt_index
 * @return {!proto.billing.private.paymentlogs.LogExtended}
 */
proto.billing.private.paymentlogs.GetPaymentLogsPaginatedResponse.prototype.addPaymentLogs = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 5, opt_value, proto.billing.private.paymentlogs.LogExtended, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.billing.private.paymentlogs.GetPaymentLogsPaginatedResponse} returns this
 */
proto.billing.private.paymentlogs.GetPaymentLogsPaginatedResponse.prototype.clearPaymentLogsList = function() {
  return this.setPaymentLogsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.billing.private.paymentlogs.LogExtended.prototype.toObject = function(opt_includeInstance) {
  return proto.billing.private.paymentlogs.LogExtended.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.billing.private.paymentlogs.LogExtended} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing.private.paymentlogs.LogExtended.toObject = function(includeInstance, msg) {
  var f, obj = {
    reference: jspb.Message.getFieldWithDefault(msg, 1, ""),
    customerFullName: jspb.Message.getFieldWithDefault(msg, 2, ""),
    customerUniqueId: jspb.Message.getFieldWithDefault(msg, 3, ""),
    createdOn: (f = msg.getCreatedOn()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    status: jspb.Message.getFieldWithDefault(msg, 5, 0),
    paymentGatewayTypeId: (f = msg.getPaymentGatewayTypeId()) && google_protobuf_wrappers_pb.Int32Value.toObject(includeInstance, f),
    paymentSourceType: jspb.Message.getFieldWithDefault(msg, 7, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.billing.private.paymentlogs.LogExtended}
 */
proto.billing.private.paymentlogs.LogExtended.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.billing.private.paymentlogs.LogExtended;
  return proto.billing.private.paymentlogs.LogExtended.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.billing.private.paymentlogs.LogExtended} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.billing.private.paymentlogs.LogExtended}
 */
proto.billing.private.paymentlogs.LogExtended.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setReference(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setCustomerFullName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setCustomerUniqueId(value);
      break;
    case 4:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setCreatedOn(value);
      break;
    case 5:
      var value = /** @type {!proto.billing.private.paymentlogs.PaymentLogStatus} */ (reader.readEnum());
      msg.setStatus(value);
      break;
    case 6:
      var value = new google_protobuf_wrappers_pb.Int32Value;
      reader.readMessage(value,google_protobuf_wrappers_pb.Int32Value.deserializeBinaryFromReader);
      msg.setPaymentGatewayTypeId(value);
      break;
    case 7:
      var value = /** @type {!proto.billing.private.paymentlogs.PaymentSourceType} */ (reader.readEnum());
      msg.setPaymentSourceType(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.billing.private.paymentlogs.LogExtended.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.billing.private.paymentlogs.LogExtended.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.billing.private.paymentlogs.LogExtended} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing.private.paymentlogs.LogExtended.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getReference();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getCustomerFullName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getCustomerUniqueId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getCreatedOn();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      5,
      f
    );
  }
  f = message.getPaymentGatewayTypeId();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      google_protobuf_wrappers_pb.Int32Value.serializeBinaryToWriter
    );
  }
  f = message.getPaymentSourceType();
  if (f !== 0.0) {
    writer.writeEnum(
      7,
      f
    );
  }
};


/**
 * optional string reference = 1;
 * @return {string}
 */
proto.billing.private.paymentlogs.LogExtended.prototype.getReference = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.billing.private.paymentlogs.LogExtended} returns this
 */
proto.billing.private.paymentlogs.LogExtended.prototype.setReference = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string customer_full_name = 2;
 * @return {string}
 */
proto.billing.private.paymentlogs.LogExtended.prototype.getCustomerFullName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.billing.private.paymentlogs.LogExtended} returns this
 */
proto.billing.private.paymentlogs.LogExtended.prototype.setCustomerFullName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string customer_unique_id = 3;
 * @return {string}
 */
proto.billing.private.paymentlogs.LogExtended.prototype.getCustomerUniqueId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.billing.private.paymentlogs.LogExtended} returns this
 */
proto.billing.private.paymentlogs.LogExtended.prototype.setCustomerUniqueId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional google.protobuf.Timestamp created_on = 4;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.billing.private.paymentlogs.LogExtended.prototype.getCreatedOn = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 4));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.billing.private.paymentlogs.LogExtended} returns this
*/
proto.billing.private.paymentlogs.LogExtended.prototype.setCreatedOn = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.billing.private.paymentlogs.LogExtended} returns this
 */
proto.billing.private.paymentlogs.LogExtended.prototype.clearCreatedOn = function() {
  return this.setCreatedOn(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.billing.private.paymentlogs.LogExtended.prototype.hasCreatedOn = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional PaymentLogStatus status = 5;
 * @return {!proto.billing.private.paymentlogs.PaymentLogStatus}
 */
proto.billing.private.paymentlogs.LogExtended.prototype.getStatus = function() {
  return /** @type {!proto.billing.private.paymentlogs.PaymentLogStatus} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {!proto.billing.private.paymentlogs.PaymentLogStatus} value
 * @return {!proto.billing.private.paymentlogs.LogExtended} returns this
 */
proto.billing.private.paymentlogs.LogExtended.prototype.setStatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 5, value);
};


/**
 * optional google.protobuf.Int32Value payment_gateway_type_id = 6;
 * @return {?proto.google.protobuf.Int32Value}
 */
proto.billing.private.paymentlogs.LogExtended.prototype.getPaymentGatewayTypeId = function() {
  return /** @type{?proto.google.protobuf.Int32Value} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.Int32Value, 6));
};


/**
 * @param {?proto.google.protobuf.Int32Value|undefined} value
 * @return {!proto.billing.private.paymentlogs.LogExtended} returns this
*/
proto.billing.private.paymentlogs.LogExtended.prototype.setPaymentGatewayTypeId = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.billing.private.paymentlogs.LogExtended} returns this
 */
proto.billing.private.paymentlogs.LogExtended.prototype.clearPaymentGatewayTypeId = function() {
  return this.setPaymentGatewayTypeId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.billing.private.paymentlogs.LogExtended.prototype.hasPaymentGatewayTypeId = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional PaymentSourceType payment_source_type = 7;
 * @return {!proto.billing.private.paymentlogs.PaymentSourceType}
 */
proto.billing.private.paymentlogs.LogExtended.prototype.getPaymentSourceType = function() {
  return /** @type {!proto.billing.private.paymentlogs.PaymentSourceType} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {!proto.billing.private.paymentlogs.PaymentSourceType} value
 * @return {!proto.billing.private.paymentlogs.LogExtended} returns this
 */
proto.billing.private.paymentlogs.LogExtended.prototype.setPaymentSourceType = function(value) {
  return jspb.Message.setProto3EnumField(this, 7, value);
};


/**
 * @enum {number}
 */
proto.billing.private.paymentlogs.PaymentLogStatus = {
  UNSPECIFIED: 0,
  SUCCESS: 1,
  DECLINED: 2,
  FAILED: 3,
  PENDING: 4,
  PENDING_ACH: 5
};

/**
 * @enum {number}
 */
proto.billing.private.paymentlogs.PaymentSourceType = {
  NONE: 0,
  CREDIT_CARD: 1,
  ACH: 2
};

goog.object.extend(exports, proto.billing.private.paymentlogs);
