import { BillsbyAction } from "../models/BillsbyAction";
import { AppState } from "..";
import { SET_ACTIVITY_SUMMARY_FIELD, FETCH_ACTIVITY_SUMMARY_SUCCESS, RESET_ACTIVITY_SUMMARY, FETCH_ACTIVITY_SUMMARY_REQUEST, FETCH_ACTIVITY_SUMMARY_FAILURE } from "../actions/activitySummaryActions";
import { GetActivitySummaryResponse } from "../utils/grpc/generated/Billsby.Protos/billing/private/report/report_pb";

export interface IActivitySummaryReducerState {
  startDate: string,
  endDate: string,
  includeCurrentMonthToDate: boolean,
  hasCurrentMonth: boolean,
  showAfterTax: boolean,
  headerMessage: string,
  details?: GetActivitySummaryResponse
};

export const initState: IActivitySummaryReducerState = {
  startDate: "",
  endDate: "",
  hasCurrentMonth: true,
  includeCurrentMonthToDate: true,
  showAfterTax: false,
  headerMessage: ""
}

export default function activitySummaryReducer(state: IActivitySummaryReducerState = initState, action: BillsbyAction, store: AppState) {
  switch (action.type) {
    case FETCH_ACTIVITY_SUMMARY_REQUEST:
    case FETCH_ACTIVITY_SUMMARY_FAILURE: {
        return { ...state, details: undefined }
    }

    case FETCH_ACTIVITY_SUMMARY_SUCCESS: {
      const details = action.response as GetActivitySummaryResponse;
      return { ...state, details }
    }

    case SET_ACTIVITY_SUMMARY_FIELD: {
      return { ...state, [action.fieldName]: action.fieldValue }
    }

    case RESET_ACTIVITY_SUMMARY: {
      return initState
    }

    default: return state
  }
}