import React from "react";
import Panel from "../../../../ui/panel/Panel";
import Text from "../../../../ui/text/Text";
import "./PauseSubscriptionWelcome.scss";
import ErrorPanel from "../../../../ui/error-panel/ErrorPanel";


const PauseSubscriptionWelcome: React.FC = () => {

  return (
    <div className="pause-subscription-welcome">
      <Panel className="pause-subscription-welcome__greeting" title="PAUSE_SUBSCRIPTION_WELCOME_TITLE">
        <Text content="PAUSE_SUBSCRIPTION_WELCOME_TEXT" />
      </Panel>

      <ErrorPanel
        title="PAUSE_SUBSCRIPTION_WELCOME_CONTACT_US_TITLE"
        subtitle="PAUSE_SUBSCRIPTION_WELCOME_CONTACT_US_TEXT"
        icon="far fa-pause-circle"
      />
    </div>
  )
}

export default PauseSubscriptionWelcome;
