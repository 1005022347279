import React, { useEffect } from "react";
import { AppState } from "../../..";
import { useDispatch, useSelector } from "react-redux";
import "./CustomerInfo.scss";
import Panel from "../../../components/ui/panel/Panel";
import Text from "../../../components/ui/text/Text";
import CustomerStatusBar from "../../../components/customers/customer-status-bar/CustomerStatusBar";
import { resetCustomerDetails } from "../../../actions/customerDetailsActions";
import { Row, Col } from "react-grid-system";
import { CustomerDataStatus } from "../../../models/Customer";
import { europeanCountriesCode } from "../../../utils/states/states";

const CustomerInfo: React.FC = () => {
  const customerDetails = useSelector((state: AppState) => state.customerDetailsReducer);
  const dispatch = useDispatch<Function>();

  useEffect(() => {
    return () => dispatch(resetCustomerDetails());
  }, [])

  
    const { firstName, lastName, email, phoneNumber, phoneNumberDialCode, billingAddress, customerUniqueId, status, customerTaxRegNumber, companyName } = customerDetails;
    const { addressLine1, addressLine2, state, city, country, postCode } = billingAddress;

    return (
      <div>
        <CustomerStatusBar />
        <Panel className="customer_info">
          <Row>
            <Col sm={4}>
              <div className="customer_info__details">
                <div className="name">
                  <Text shouldTranslate={false} content={`${firstName} ${lastName}`} />
                </div>
                <div className="info">
                  <i className="fas fa-envelope-open-text" />
                  <Text className="email" shouldTranslate={false} content={!!email && email.length > 0 ? email : "N/A"} />
                </div>
                <div className="info">
                  <i className="fas fa-phone" />
                  <Text shouldTranslate={false} content={!!phoneNumber && phoneNumber.length > 0 ? `${status === CustomerDataStatus.ACTIVE ? `+${phoneNumberDialCode}` : ""} ${phoneNumber}` : "N/A"} />
                </div>
                <div className="info">
                  <i className="far fa-id-card" />
                  <Text shouldTranslate={false} content={!!customerUniqueId && customerUniqueId.length > 0 ? customerUniqueId : "N/A"} />
                  <Text 
                    className="sub-text" 
                    shouldTranslate={true} 
                    content="BILLSBY_CUSTOMER_ID" 
                    noMargin />
                </div>
                <div className="info">
                  <i className="far fa-coins" />
                  <Text shouldTranslate={false} content={customerTaxRegNumber || "N/A"} />
                  <Text 
                    className="sub-text" 
                    shouldTranslate={true} 
                    content={ 
                      country === "CAN" ? "BN_REGISTRATION_NUMBER" :
                      country === "IND" ? "GSTIN_REGISTRATION_NUMBER" : 
                      country === "NZL" ? "NZBN_REGISTRATION_NUMBER" : 
                      country === "USA" || europeanCountriesCode.indexOf(country) !== -1 ? "VAT_REGISTRATION_NUMBER" :
                      "TAX_REGISTRATION_NUMBER" }
                    noMargin/>
                </div>
                <div className="info">
                <i className="far fa-building"></i>
                  <Text shouldTranslate={false} content={companyName || "N/A"} />
                  <Text 
                    className="sub-text" 
                    shouldTranslate={true} 
                    content="BILLSBY_CUSTOMER_COMPANY_NAME" 
                    noMargin />
                </div>
              </div>
            </Col>
            <Col sm={8}>
              <div className="customer_info__address">
                <Text className="address-title" content="BILLING_ADDRESS" />
                {addressLine1 && <Text className="address" shouldTranslate={false} content={addressLine1} noMargin />}
                {addressLine2 && <Text className="address" shouldTranslate={false} content={addressLine2} noMargin />}
                {city && <Text className="address" shouldTranslate={false} content={city} noMargin />}
                {state && <Text className="address" shouldTranslate={false} content={state} noMargin />}
                {postCode && <Text className="address" shouldTranslate={false} content={postCode} noMargin />}
                {country && <Text className="address" shouldTranslate={false} content={country} noMargin />}
              </div>
            </Col>
          </Row>
        </Panel>
      </div>
    );
  
}

export default CustomerInfo;
