import React, { useState } from "react";
import { VisibilityType, IProductLightWithPlans } from "../../../models/Product";
import "./ProductPlanCycleList.scss";
import Panel from "../../../components/ui/panel/Panel";
import Text from "../../../components/ui/text/Text";
import Button from "../../../components/ui/button/Button";
import StatusLabel from "../../ui/status-label/StatusLabel";
import { setProductsCustomFields, fetchProducts } from "../../../actions/productPlanCycleActions";
import OtherCompanies from "./OtherCompanies";
import CollapsablePanelWithArrow from "../../ui/collapsable-panel-with-arrow/CollapsablePanelWithArrow";
import Search from "../../search/Search";
import { openIntercomIframe, getProductStatusText, getProductStatusColor } from "../../../utils/commonUtils";
import counterpart from "counterpart";
import NavigationAlert from "../../ui/navigation-alert/NavigationAlert";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../../..";
import { IProductPlanCycleReducerState } from "../../../reducers/productPlanCycleReducer";
import { IAuthReducerState } from "../../../reducers/authReducer";
import ProductPlanCycleWelcome from "./ProductPlanCycleWelcome";
import PageWrapper from "../../ui/layout/PageWrapper";
import NavigationAlertCurrencyMismatch from "../../ui/navigation-alert/NavigationAlertCurrencyMismatch";
import history from "../../../utils/history";
import Helmet from "react-helmet";
import Pagination from "../../ui/pagination/Pagination";
import Dropdown from "../../ui/dropdown/Dropdown";
import Table from "../../ui/table/Table";
import ProductPlansList from "./ProductPlansList";
import Tooltip from "../../ui/tooltip/Tooltip";
import ProgressIndicator from "../../ui/progress-indicator/ProgressIndicator";
import Modal from "../../modal/Modal";
import DeleteProduct from "./DeleteProduct";
import { Permission } from "../../../models/Auth";
import useCheckFeaturePermission from "../../../utils/hooks/useCheckFeaturePermission";

interface IProductPlanCycleList {
  reloadData: () => void
}

const ProductPlanCycleList: React.FC<IProductPlanCycleList> = ({ reloadData }) => {
  const [isCollapsed, setIsCollapsed] = useState(true);
  const [productToDelete, setProductToDelete] = useState<IProductLightWithPlans | undefined>(undefined);

  const { searchedProductName, productsPaginated, selectedProductsFilter, hasProducts, firstCreatedProduct , isFetchingProducts } = useSelector<AppState, IProductPlanCycleReducerState>(state => state.productPlanCycle);
  const { parsedToken, currentCompanyDomain } = useSelector<AppState, IAuthReducerState>(state => state.auth);
  const { results, pageCount } = productsPaginated;

  const dispatch = useDispatch<Function>();
  const isProductAddPermitted = useCheckFeaturePermission(Permission.ProductAdd);
  const isProductPlanAddPermitted = useCheckFeaturePermission(Permission.ProductPlanAdd);
  const isProductEditPermitted = useCheckFeaturePermission(Permission.ProductEdit);
  const isProductDeletePermitted = useCheckFeaturePermission(Permission.ProductDelete);

  const otherCompaniesContent = [
    {
      name: "PRODUCT_PLAN_NETFLIX",
      type: "PRODUCT_PLAN_NETFLIX_TYPE",
      productText: ["PRODUCT_PLAN_NETFLIX_PRODUCT_TEXT"],
      cycleText: ["PRODUCT_PLAN_NETFLIX_CYCLE_TEXT"],
      featuresText: ["PRODUCT_PLAN_NETFLIX_FEATURE_TEXT"]
    },
    {
      name: "PRODUCT_PLAN_DSC",
      type: "PRODUCT_PLAN_DSC_TYPE",
      productText: ["PRODUCT_PLAN_DSC_PRODUCT_TEXT"],
      cycleText: ["PRODUCT_PLAN_DSC_CYCLE_TEXT"],
      featuresText: ["PRODUCT_PLAN_DSC_FEATURE_TEXT"]
    },
    {
      name: "PRODUCT_PLAN_EQUINOX",
      type: "PRODUCT_PLAN_EQUINOX_TYPE",
      productText: ["PRODUCT_PLAN_EQUINOX_PRODUCT_TEXT"],
      cycleText: ["PRODUCT_PLAN_EQUINOX_CYCLE_TEXT"],
      featuresText: ["PRODUCT_PLAN_EQUINOX_FEATURE_TEXT"]
    },
    {
      name: "PRODUCT_PLAN_INTERCOM",
      type: "PRODUCT_PLAN_INTERCOM_TYPE",
      productText: ["PRODUCT_PLAN_INTERCOM_PRODUCT_TEXT"],
      cycleText: ["PRODUCT_PLAN_INTERCOM_CYCLE_TEXT"],
      featuresText: ["PRODUCT_PLAN_INTERCOM_FEATURE_TEXT"]
    },
    {
      name: "PRODUCT_PLAN_AMAZON",
      type: "PRODUCT_PLAN_AMAZON_TYPE",
      productText: ["PRODUCT_PLAN_AMAZON_PRODUCT_TEXT"],
      cycleText: ["PRODUCT_PLAN_AMAZON_CYCLE_TEXT"],
      featuresText: ["PRODUCT_PLAN_AMAZON_FEATURE_TEXT"]
    },
    {
      name: "PRODUCT_PLAN_SPOTIFY",
      type: "PRODUCT_PLAN_SPOTIFY_TYPE",
      productText: ["PRODUCT_PLAN_SPOTIFY_PRODUCT_TEXT"],
      cycleText: ["PRODUCT_PLAN_SPOTIFY_CYCLE_TEXT"],
      featuresText: ["PRODUCT_PLAN_SPOTIFY_FEATURE_TEXT"]
    }
  ];

  const renderHeaderBar = () => {
    const productsFilter = [
      { label: counterpart("PRODUCT_FILTER_STATUS_ALL"), value: "" },
      { label: counterpart("PRODUCT_FILTER_STATUS_PUBLIC"), value: VisibilityType.Public },
      { label: counterpart("PRODUCT_FILTER_STATUS_HIDDEN"), value: VisibilityType.Hidden },
      { label: counterpart("PRODUCT_FILTER_STATUS_INTERNAL"), value: VisibilityType.Internal },
      { label: counterpart("PRODUCT_FILTER_STATUS_OFFSALE"), value: VisibilityType.OffSale }
    ];
    const selectedValue = productsFilter.find(filter => filter.value === selectedProductsFilter);
    return (
      <>
        <div className='ppc-list__header'>
          <div className='ppc-list__filter'>
            <Dropdown
              id='ppc-list__dropdown'
              options={productsFilter}
              value={selectedValue}
              onChange={(selectedItem: any) => {
                dispatch(setProductsCustomFields("selectedProductsFilter", selectedItem.value));
                dispatch(fetchProducts(currentCompanyDomain, 1, undefined, searchedProductName, selectedItem.value));
              }}
            ></Dropdown>
            <Button
              title='PRODUCT_PLAN_BUTTON_ADD_NEW_PRODUCT'
              icon='fal fa-plus-circle'
              id='product-plan-cycle-list-btn-add-new-product'
              shouldTranslate={true}
              buttonType='add'
              disabled={!isProductAddPermitted}
              tooltipMessage={!isProductAddPermitted ? "NO_ACCESS_MSG" : undefined}
              onClick={() => {
                history.push("/products/products-plans-cycles/create-product");
              }}
            />
          </div>
          <span className='divider'></span>
          <div className='ppc-list__search'>
            <Search
              placeholder='CREATE_PRODUCT_SEARCH'
              value={searchedProductName}
              onChange={evt => dispatch(setProductsCustomFields("searchedProductName", evt.target.value))}
              onSearch={() => dispatch(fetchProducts(currentCompanyDomain, 1, undefined, searchedProductName, selectedProductsFilter))}
            />
            <Pagination
              className='ppc-list__pagination'
              pageCount={pageCount}
              isVisible={pageCount > 1}
              onPageChange={({ selected }) => dispatch(fetchProducts(currentCompanyDomain, selected + 1, undefined, searchedProductName, selectedProductsFilter))}
            />
          </div>
          {renderWelcomeBanner()}
        </div>
      </>
    );
  };

  const renderProductsListTable = () => {
    return (
      <Table expandable className='ppc-list__table'>
        <thead>
          <tr>
            <Text content={"PRODUCT_TABLE_NAME"} component='th' noMargin />
            <Text content={"PRODUCT_TABLE_PLANS"} component='th' noMargin />
            <Text content={"PRODUCT_TABLE_STATUS"} component='th' noMargin />
            <th></th>
          </tr>
        </thead>
        <tbody>
          {results &&
            results.map((product: IProductLightWithPlans) => {
              return (
                <React.Fragment key={product.productId}>
                  <tr className={!product.hasPlans ? "not-expandable" : ""}>
                    <Text shouldTranslate={false} content={product.name} component={"td"} noMargin></Text>
                    {/* TODO: Add product plans list length property */}
                    <td className={`ppc-list__table__collapsible${!product.hasPlans ? " ppc-list__table__collapsible--no-plans" : ""}`}>
                      <span className='ppc-list__table__collapsible-arrow'></span>
                      <Text className='ppc-list__table__collapsible-label' content={product.numberOfPlans === 1 ? "PRODUCT_TABLE_PLAN_NUM" : "PRODUCT_TABLE_PLANS_NUM"} translateWith={{ count: product.numberOfPlans }} component={"span"} noMargin></Text>
                    </td>
                    <td>
                      <StatusLabel color={getProductStatusColor(product.visibility)} content={getProductStatusText(product.visibility)} />{" "}
                    </td>
                    <td className='ppc-list__table__actions'>
                      <Button
                        disabled={!isProductPlanAddPermitted}
                        tooltipMessage={!isProductPlanAddPermitted ? "NO_ACCESS_MSG" : undefined}
                        id='add-new-plan'
                        className={!product.hasPlans ? "ppc-list__button--highlight" : ""}
                        buttonType='add'
                        icon='fas fa-plus-circle'
                        title={!product.hasPlans ? "PRODUCT_BUTTON_ADD_FIRST_PLAN" : "PRODUCT_BUTTON_ADD_NEW_PLAN"}
                        isFullWidth
                        onClick={addPlan(product.productId)}
                      ></Button>
                      <Button
                        disabled={!isProductEditPermitted}
                        tooltipMessage={!isProductEditPermitted ? "NO_ACCESS_MSG" : undefined}
                        id='edit-product'
                        buttonType='add'
                        icon='fas fa-edit'
                        title='PRODUCT_BUTTON_EDIT_PRODUCT'
                        isFullWidth
                        onClick={editProduct(product.productId)}
                      />
                      <Button
                        disabled={!isProductDeletePermitted}
                        tooltipMessage={!isProductDeletePermitted ? "NO_ACCESS_MSG" : undefined}
                        id='delete-product'
                        buttonType='error'
                        icon='fas fa-trash-alt'
                        title='PRODUCT_BUTTON_DELETE_PRODUCT'
                        isFullWidth
                        onClick={deleteProduct(product)}
                      />
                    </td>
                  </tr>
                  <tr className='expanded'>
                    <ProductPlansList productId={product.productId} reloadData={reloadData}/>
                  </tr>
                </React.Fragment>
              );
            })}
          <Modal
            isOpen={!!productToDelete}
            onRequestClose={() => setProductToDelete(undefined)}
            title={counterpart("PRODUCT_DELETE_MODAL_TITLE", { productName: productToDelete ? productToDelete.name : "" })}
            shouldTranslateTitle={false}
          >
            {productToDelete && (
              <DeleteProduct
                product={productToDelete}
                onClose={() => {
                  reloadData();
                  setProductToDelete(undefined);
                }}
              />
            )}
          </Modal>
        </tbody>
      </Table>
    );
  };

  const renderWelcomeBanner = () => {
    if (!firstCreatedProduct || firstCreatedProduct.hasPlans || !results) {
      return null;
    }

    return (
      <NavigationAlert
        title={counterpart("PRODUCT_PLAN_ONE_PRODUCT_GREETING_TITLE", { name: parsedToken && parsedToken.given_name })}
        shouldTranslateTitle={false}
        subTitle='PRODUCT_PLAN_ONE_PRODUCT_GREETING_TEXT'
        buttonLabel='PRODUCT_PLAN_TALK_BUTTON'
        onClick={openIntercomIframe}
        isVisible={true}
        className='ppc-list__greeting-alert'
      />
    );
  };

  const renderBottomPanel = () => {
    return (
      <Panel className='ppc-list__explain' title='PRODUCT_PLAN_EXPLAIN_TITLE'>
        <div className='ppc-list__content'>
          <p>
            <Text content='PRODUCTPLAN_PANEL_CONTENT1' component={"span"} />

            <span className='ppc-list__tooltip-text' data-tip data-for='plan-tooltip'>
              <Text content='PRODUCTPLAN_PANEL_CONTENT2' component={"span"} />
            </span>

            <Text content='PRODUCTPLAN_PANEL_CONTENT3' component={"span"} />

            <span className='ppc-list__tooltip-text' data-tip data-for='cycles-tooltip'>
              <Text content='PRODUCTPLAN_PANEL_CONTENT4' component={"span"} />
            </span>

            <Text content='PRODUCTPLAN_PANEL_CONTENT5' component={"span"} />

            <span className='ppc-list__tooltip-text' data-tip data-for='product-tooltip'>
              <Text content='PRODUCTPLAN_PANEL_CONTENT6' component={"span"} />
            </span>

            <Text content='PRODUCTPLAN_PANEL_CONTENT7' component={"span"} noMargin />
          </p>
        </div>

        <Tooltip id='product-tooltip' place='bottom' type='blue'>
          <Text content='PRODUCT_TOOLTIP' noMargin />
        </Tooltip>
        <Tooltip id='plan-tooltip' place='bottom' type='blue'>
          <Text content='PLAN_TOOLTIP' noMargin />
        </Tooltip>
        <Tooltip id='cycles-tooltip' place='bottom' type='blue'>
          <Text content='CYCLES_TOOTLTIP' noMargin />
        </Tooltip>
      </Panel>
    );
  };

  const renderCollapsiblePanel = () => {
    return (
      <CollapsablePanelWithArrow isCollapsed={isCollapsed} onClick={() => setIsCollapsed(!isCollapsed)} className='ppc-list__other-companies' title='PRODUCT_PLAN_OTHER_COMPANIES'>
        <Text content='PRODUCT_PLAN_OTHER_COMPANIES_TEXT' />
        {<OtherCompanies companies={otherCompaniesContent} />}
      </CollapsablePanelWithArrow>
    );
  };

  const addPlan = (productId: number) => (evt: any) => {
    evt.stopPropagation();
    history.push(`/products/products-plans-cycles/${productId}/create-plan`);
  };

  const editProduct = (productId: number) => (evt: any) => {
    evt.stopPropagation();
    history.push(`/products/products-plans-cycles/edit-product/${productId}`);
  };

  const deleteProduct = (product: IProductLightWithPlans) => (evt: any) => {
    evt.stopPropagation();
    setProductToDelete(product);
  };

  if (!hasProducts) {
    return <ProductPlanCycleWelcome otherCompaniesContent={otherCompaniesContent} />;
  }

  return (
    <div className='ppc-list'>
      <Helmet title={counterpart("NAVBAR_PRODUCTS_PLANS_CYCLES")} />
      <NavigationAlertCurrencyMismatch />
      {renderHeaderBar()}
      <PageWrapper>
        {isFetchingProducts ? <ProgressIndicator color="blue" /> : renderProductsListTable()}
        {renderBottomPanel()}
        {renderCollapsiblePanel()}
      </PageWrapper>
    </div>
  );
};

export default ProductPlanCycleList
