/**
 * @fileoverview gRPC-Web generated client stub for messenger.private.emailconfig
 * @enhanceable
 * @public
 */

// GENERATED CODE -- DO NOT EDIT!


import * as grpcWeb from "grpc-web";

import {
  GetEmailConfigRequest,
  GetEmailConfigResponse,
  UpsertEmailConfigRequest,
  UpsertEmailConfigResponse} from "./emailconfig_pb";

export class EmailConfigServiceClient {
  client_: grpcWeb.AbstractClientBase;
  hostname_: string;
  credentials_: null | { [index: string]: string; };
  options_: null | { [index: string]: string; };

  constructor (hostname: string,
               credentials?: null | { [index: string]: string; },
               options?: null | { [index: string]: string; }) {
    if (!options) options = {};
    if (!credentials) credentials = {};
    options["format"] = "text";

    this.client_ = new grpcWeb.GrpcWebClientBase(options);
    this.hostname_ = hostname;
    this.credentials_ = credentials;
    this.options_ = options;
  }

  methodInfoUpsertEmailConfig = new grpcWeb.AbstractClientBase.MethodInfo(
    UpsertEmailConfigResponse,
    (request: UpsertEmailConfigRequest) => {
      return request.serializeBinary();
    },
    UpsertEmailConfigResponse.deserializeBinary
  );

  upsertEmailConfig(
    request: UpsertEmailConfigRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: UpsertEmailConfigResponse) => void) {
    return this.client_.rpcCall(
      this.hostname_ +
        "/messenger.private.emailconfig.EmailConfigService/UpsertEmailConfig",
      request,
      metadata || {},
      this.methodInfoUpsertEmailConfig,
      callback);
  }

  methodInfoGetEmailConfig = new grpcWeb.AbstractClientBase.MethodInfo(
    GetEmailConfigResponse,
    (request: GetEmailConfigRequest) => {
      return request.serializeBinary();
    },
    GetEmailConfigResponse.deserializeBinary
  );

  getEmailConfig(
    request: GetEmailConfigRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: GetEmailConfigResponse) => void) {
    return this.client_.rpcCall(
      this.hostname_ +
        "/messenger.private.emailconfig.EmailConfigService/GetEmailConfig",
      request,
      metadata || {},
      this.methodInfoGetEmailConfig,
      callback);
  }

}

