import {
  ADD_FIRSTNAME, ADD_LASTNAME, ADD_WORKEMAIL, ADD_PHONE_NUMBER_PREFIX, ADD_PHONE_NUMBER, ADD_PASSWORD, ADD_FREE_TRIAL_TOKEN,
  SET_NOTIFICATION_ALERT, ADD_PASSWORD_CONFIRM, VALIDATE_EMAIL_REQUEST, VALIDATE_EMAIL_SUCCESS, VALIDATE_EMAIL_FAILURE,
  ADD_COUNTRY, ADD_COMPANY_NAME, ADD_BILLING_CURRENCY, FETCH_COUNTRIES_REQUEST, FETCH_COUNTRIES_SUCCESS, FETCH_CURRENCIES_REQUEST,
  FETCH_CURRENCIES_SUCCESS, REGISTER_USER_REQUEST, REGISTER_USER_SUCCESS, REGISTER_USER_FAILURE, ADD_PHONE_NUMBER_COUNTRY_ISO2, ADD_INVITE_CODE,
  CHECK_PASSWORD_VALIDITY, SET_IS_DOING_QUIZ, RESET_REGISTRATION_STATE, FETCH_DETAILS_INVITED_USER_SUCCESS, REGISTER_USER_FROM_INVITATION_REQUEST, REGISTER_USER_FROM_INVITATION_SUCCESS, REGISTER_USER_FROM_INVITATION_FAILURE, FETCH_DETAILS_INVITED_USER_FAILURE, ADD_COMPANY_DOMAIN, IS_COMPANYDOMAIN_USED_SUCCESS, SET_ACCEPT_AGREEMENT
} from "../actions/registrationActions";
import { BillsbyAction } from "../models/BillsbyAction";
import { BILLSBY_GUID, CustomeAnalyticsEvents, INVALID_REGISTRATION_EMAILS_DOMAINS } from "../utils/constants";
import { ICountry } from "../models/Country";
import { ICurrency } from "../models/Currency";
import { reorderCurrencies, reorderCountries, gaEventTracker } from "../utils/commonUtils";
import { AppState } from "..";
import { InvitedUserDetails } from "../models/Invitations";
import { IsCompanyDomainUsedResponse } from "../utils/grpc/generated/Billsby.Protos/core/public/company/company_pb";
import { storeRemove } from "../utils/storeUtils";

export interface IRegistrationReducerState {
  firstName: string,
  lastName: string,
  workEmail: string,
  hasWorkEmailInvalidDomain: boolean,
  phoneNumberPrefix: string,
  phoneNumber: string,
  phoneNumberCountryIso2: string,
  password: string,
  passwordConfirmation: string
  notificationAlert: boolean,
  acceptAgreement: boolean,
  hasPassword8Chars: boolean,
  hasPasswordOneNumber: boolean,
  hashPasswordOneSpecialCharacter: boolean,
  isValidatingEmail: boolean,
  isValidatingFailure: any,
  isValidatingSuccess: any,
  country: { value: string, label: string },
  company: string,
  companyDomain: string,
  isCompanyDomainUsed: boolean,
  billingCurrency: { value: string, label: string },
  isFetchingCountries: boolean,
  isFetchingCurrencies: boolean,
  countries: ICountry[],
  currencies: ICurrency[],
  isRegisteringUser: boolean,
  isRegisteringUserSuccess: boolean,
  isRegisteringUserFailure: boolean,
  isDoingQuiz: boolean,
  passwordIsValid: boolean,
  invitationCode: string,
  freeTrialToken?: string,
  invitedUserDetails?: InvitedUserDetails
  invitedUserDetailsError?: Error
}

export const initialState = {
  firstName: "",
  lastName: "",
  workEmail: "",
  hasWorkEmailInvalidDomain: false,
  phoneNumberPrefix: "",
  phoneNumber: "",
  phoneNumberCountryIso2: "",
  password: "",
  passwordConfirmation: "",
  notificationAlert: true,
  acceptAgreement: false,
  hasPassword8Chars: false,
  hasPasswordOneNumber: false,
  hashPasswordOneSpecialCharacter: false,
  isValidatingEmail: false,
  isValidatingFailure: null,
  isValidatingSuccess: false,
  country: { value: "USA", label: "United States" },
  company: "",
  companyDomain: "",
  isCompanyDomainUsed: false,
  billingCurrency: { value: "USD", label: "US Dollar" },
  isFetchingCountries: true,
  isFetchingCurrencies: true,
  countries: [],
  currencies: [],
  isRegisteringUser: false,
  isRegisteringUserSuccess: false,
  isRegisteringUserFailure: false,
  isDoingQuiz: false,
  passwordIsValid: false,
  invitationCode: ""
}

export default function registrationReducer(state: IRegistrationReducerState = initialState, action: BillsbyAction, store: AppState) {
  switch (action.type) {
    case ADD_FIRSTNAME:
      return { ...state, firstName: action.payload }

    case ADD_LASTNAME:
      return { ...state, lastName: action.payload }

    case ADD_WORKEMAIL: {
      const workEmail: string = action.payload;
      if (INVALID_REGISTRATION_EMAILS_DOMAINS.some(domain => new RegExp(`.+@${domain}`).test(workEmail))) {
        return { ...state, workEmail, company: "", hasWorkEmailInvalidDomain: true, isValidatingFailure: false }
      }
      const emailDomain = workEmail.substring(workEmail.indexOf("@") + 1, workEmail.length);
      const companyRecommendedName = emailDomain.substring(0, emailDomain.indexOf("."));

      return { ...state, workEmail, company: companyRecommendedName.capitalize(), hasWorkEmailInvalidDomain: false, isValidatingFailure: false }
    }

    case ADD_PHONE_NUMBER_PREFIX:
      return { ...state, phoneNumberPrefix: action.payload }

    case ADD_PHONE_NUMBER:
      return { ...state, phoneNumber: action.payload }

    case ADD_PHONE_NUMBER_COUNTRY_ISO2:
      return { ...state, phoneNumberCountryIso2: action.payload }

    case ADD_PASSWORD:
      return { ...state, password: action.payload }

    case ADD_PASSWORD_CONFIRM:
      return { ...state, passwordConfirmation: action.payload }

    case CHECK_PASSWORD_VALIDITY:
      return { ...state, passwordIsValid: action.payload }

    case SET_NOTIFICATION_ALERT:
      return { ...state, notificationAlert: action.payload }
    
    case SET_ACCEPT_AGREEMENT:
      return { ...state, acceptAgreement: action.payload }

    case VALIDATE_EMAIL_REQUEST:
      return { ...state, isValidatingEmail: true, isValidatingSuccess: false, isValidatingFailure: false }

    case VALIDATE_EMAIL_SUCCESS:
      return { ...state, isValidatingEmail: false, isValidatingSuccess: action.response, isValidatingFailure: false }

    case VALIDATE_EMAIL_FAILURE:
      return { ...state, isValidatingEmail: false, isValidatingSuccess: false, isValidatingFailure: action.error }

    case ADD_COUNTRY:
      return { ...state, country: action.payload }

    case ADD_COMPANY_NAME:
      return { ...state, company: action.payload }

    case ADD_BILLING_CURRENCY:
      return { ...state, billingCurrency: action.payload }
    case ADD_COMPANY_DOMAIN:
      return { ...state, companyDomain: action.payload }
    case IS_COMPANYDOMAIN_USED_SUCCESS: {
      const resp = action.response as IsCompanyDomainUsedResponse;
      return { ...state, isCheckingCompanyDomain: false, isCompanyDomainUsed: resp.getIsUsed() }
    }
    case FETCH_COUNTRIES_REQUEST:
      return { ...state, isFetchingCountries: true, countries: [] }

    case FETCH_COUNTRIES_SUCCESS: {
      const countries: Array<ICountry> = action.response;
      reorderCountries(countries);
      const defaultCountry: ICountry = countries.find((country: ICountry) => country.iso2Code.toLocaleUpperCase() === state.phoneNumberCountryIso2.toUpperCase()) || countries[0];
      return { ...state, isFetchingCountries: false, country: { value: defaultCountry.iso3Code, label: defaultCountry.name }, countries: action.response }
    }
    case FETCH_CURRENCIES_REQUEST:
      return { ...state, isFetchingCurrencies: true, currencies: [] }

    case FETCH_CURRENCIES_SUCCESS: {
      const currencies: ICurrency[] = action.response;
      reorderCurrencies(currencies);
      const countryOfPhone: ICountry = <ICountry>state.countries.find(country => country.iso2Code.toUpperCase() === state.phoneNumberCountryIso2.toUpperCase()) || state.countries[0];

      const defaultCurrency: ICurrency = currencies.find(currency => currency.isoCode.toUpperCase() === countryOfPhone.defaultCurrencyCode.toUpperCase()) || currencies[0];

      return { ...state, isFetchingCurrencies: false, billingCurrency: { value: defaultCurrency.isoCode, label: defaultCurrency.englishName }, currencies: action.response }

    }
    case REGISTER_USER_REQUEST:
      return { ...state, isRegisteringUser: true, isRegisteringUserSuccess: false, isRegisteringUserFailure: false }

    case REGISTER_USER_SUCCESS:
      gaEventTracker(CustomeAnalyticsEvents.SIGN_UP);
      storeRemove(BILLSBY_GUID);
      return { ...state, isRegisteringUser: false, isRegisteringUserSuccess: true, isRegisteringUserFailure: false, quizCurrentStep: 1 }

    case REGISTER_USER_FAILURE:
      return { ...state, isRegisteringUser: false, isRegisteringUserSuccess: false, isRegisteringUserFailure: true }

    case SET_IS_DOING_QUIZ:
      return { ...state, isDoingQuiz: action.payload }

    case ADD_INVITE_CODE:
      return { ...state, invitationCode: action.payload }

    case ADD_FREE_TRIAL_TOKEN: 
      return { ...state, freeTrialToken: action.payload }
    
    case FETCH_DETAILS_INVITED_USER_SUCCESS:
      return { ...state, invitedUserDetails: action.response }
    case FETCH_DETAILS_INVITED_USER_FAILURE:
      return { ...state, invitedUserDetailsError: new Error() }
    case REGISTER_USER_FROM_INVITATION_REQUEST:
      return { ...state, isRegisteringUser: true, isRegisteringUserSuccess: false, isRegisteringUserFailure: false }
    case REGISTER_USER_FROM_INVITATION_SUCCESS:
      return { ...state, isRegisteringUser: false, isRegisteringUserSuccess: true, isRegisteringUserFailure: false }
    case REGISTER_USER_FROM_INVITATION_FAILURE:
      return { ...state, isRegisteringUser: false, isRegisteringUserSuccess: false, isRegisteringUserFailure: true }
      
    case RESET_REGISTRATION_STATE:
      return { ...initialState }

    default:
      return state;
  }
}