import React from "react"
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../../..";
import TabbedContainer from "../../ui/tabbed-container/TabbedContainer";
import { NavLink } from "react-router-dom";
import CurrencyInput from "./CurrencyInput";
import "./CurrencyInputTab.scss";
import { setCreateDiscountReducerFields } from "../../../actions/discount-coupon/createDiscountActions";
import ProgressIndicator from "../../ui/progress-indicator/ProgressIndicator";

interface ICurrencyInputTab {
  isEdit: boolean
}

const CurrencyInputTab: React.FC<ICurrencyInputTab> = ({ isEdit }) => {
  const createDiscount = useSelector((state: AppState) => state.createDiscountReducer);
  const dispatch = useDispatch();

  const { selectedCurrency, monetaries, currenciesOfPlans } = createDiscount;
  const hasValue = (currencyCode: string) => monetaries.filter(i => i.currencyCode === currencyCode && !!i.value).length > 0;

  if(!currenciesOfPlans) {
    return <ProgressIndicator color="blue"></ProgressIndicator>
  }

  return (
    <div className="currency-input-tab">
      {currenciesOfPlans && currenciesOfPlans.getCurrenciesList().length > 1 &&
        <TabbedContainer type="submenu">
          <>{currenciesOfPlans.getCurrenciesList()
            .sort((a, b) => {
              const x = a.getIso3Code().toLowerCase();
              const y = b.getIso3Code().toLowerCase();
              return x < y ? -1 : x > y ? 1 : 0;
            }).map((currency, idx) => (
              <NavLink
                key={idx}
                isActive={(_, __) =>
                  !!selectedCurrency ?
                  selectedCurrency.getIso3Code() === currency.getIso3Code() : idx === 0
                }
                to=''
                onClick={(evt) => {
                  evt.preventDefault();
                  dispatch(setCreateDiscountReducerFields("selectedCurrency", currency));
                }}>
                {isEdit && !hasValue(currency.getIso3Code()) && <i className="fas fa-map-marker-exclamation currency-input-tab__currency-error"></i>}
                <span className={isEdit && !hasValue(currency.getIso3Code()) ? "currency-input-tab__currency-error-label" : ""}>{currency.getIso3Code()}</span>
              </NavLink>
            ))}
          </>
        </TabbedContainer>
      }
      {currenciesOfPlans && <div className="currency-input-tab__content">
        <CurrencyInput />
      </div>}

    </div>
  )
}

export default CurrencyInputTab;
