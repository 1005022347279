import { BaseAddress } from "./Address";

export interface IInvoice {
  createdOn: Date;
  customerFirstName: string,
  customerLastName: string,
  customerFullName: string,
  customerId: number,
  invoiceNumber: string,
  amount: string,
  status: InvoiceStatus,
  currency: string,
  invoiceId: number,
  customerUniqueId: string,
  formattedAmount: string
}

export enum InvoiceStatus {
  PAID = "Paid",
  PAID_OFFLINE = "PaidOffline",
  UNPAID = "Unpaid",
  PENDING = "Pending",
  WRITTEN_OFF = "WrittenOff",
  TOBEWRITTEN_OFF = "ToBeWrittenOff",
  PAID_MANUALLY = "PaidManually",
  FAILED = "Failed",
  CLEARING = "Clearing"
}

export enum InvoiceWriteOffType {
  WRITE_OFF_IMMEDIATELY = "WriteOffImmediately",
  WRITE_OFF_MANUALLY = "WriteOffManually",
  WRITE_OFF_SCHEDULED = "ScheduledWriteOff"
}

export enum InvoicePaymentLogStatus {
  SUCCESS = "Success",
  DECLINED = "Declined",
  FAILED = "Failed",
  PENDING = "Pending",
  PENDINGACH = "PendingAch"
} 

export enum InvoiceType {
  RENEWAL = "Renewal",
  ONE_TIME_CHARGE = "OneTimeCharge"
}

export enum CreditNoteType {
  REFUND = "Refund",
  ONE_TIME_CREDIT = "OneTimeCredit"
}

export enum StandAloneInvoiceType {
  RENEWAL = "Renewal",
  ONE_TIME_CHARGE = "OneTimeCharge",
  REFUND = "Refund",
  ONE_TIME_CREDIT = "OneTimeCredit"
}

export interface IInvoicesPaginated {
  currentPage: number,
  pageCount: number,
  pageSize: number,
  rowCount: number,
  results?: Array<IInvoice>,
}

export enum InvoiceConfigType {
  UNPAID_INVOICE = "UnpaidInvoice",
  PENDING_INVOICE = "PendingInvoice",
  PAID_INVOICE = "PaidInvoice",
  FAILED_CREDIT_NOTE = "FailedCreditNote",
  PENDING_CREDIT_NOTE = "PendingCreditNote",
  PAID_CREDIT_NOTE = "PaidCreditNote"
}

export enum InvoiceRefundType {
  AVAILABLE = "Available",
  INVOICE_UNPAID = "InvoiceUnpaid",
  MISSING_SUCCESSFUL_PAYMENT_LOG =  "MissingSuccessfulPaymentLog",
  ACH_EXPIRED = "AchExpired",
  FULLY_REFUNDED = "FullyRefunded",
  NOT_AVAILABLE = "NotAvailable"
}

export interface IInvoicesFooterConfigData {
  companyName: string,
  address: BaseAddress,
  emailAddress: string,
  phoneNumberDialCountry: string,
  phoneNumberDialCode: string,
  phoneNumber: string
}

export interface IInvoicesSupportContactConfigData {
  heading: string,
  content: string,
  supportPageUrl: string,
  supportPageUrlButtonText: string,
  isAdvertEnabled: boolean,
  advertImageUrl: string,
  advertLinkToUrl: string
}