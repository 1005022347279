import { ISupportedLanguage } from "../models/languageSupport";
import { ConfigConstants } from "../utils/config";
import { CompanyServiceClient } from "../utils/grpc/generated/Billsby.Protos/core/private/companies/CompanyServiceClientPb";
import { CompanyLanguageUpdate, GetCompanyLanguagesRequest, UpdateCompanyLanguagesRequest } from "../utils/grpc/generated/Billsby.Protos/core/private/companies/company_pb";
import { CompanyCheckoutConfigServiceClient } from "../utils/grpc/generated/Billsby.Protos/core/private/companyconfig/companycheckoutconfig/CompanycheckoutconfigServiceClientPb";
import { UpdateAccountManagementLanguageRequest, UpdateCheckoutDefaultLanguageRequest } from "../utils/grpc/generated/Billsby.Protos/core/private/companyconfig/companycheckoutconfig/companycheckoutconfig_pb";
import { grpcUnaryCall } from "../utils/grpc/grpcUtils";
import { makeActionCreator } from "../utils/reduxUtils";

export const UPDATE_COMPANY_LANGUAGES = "UPDATE_COMPANY_LANGUAGES";
export const updateCompanyLanguages = makeActionCreator(UPDATE_COMPANY_LANGUAGES, "payload");

export const RESET_COMPANY_LANGUAGES = "RESET_COMPANY_LANGUAGES";
export const resetCompanyLanguages = makeActionCreator(RESET_COMPANY_LANGUAGES, "payload");

export const UPDATE_CHECKOUT_LANGUAGES = "UPDATE_CHECKOUT_LANGUAGES";
export const updateCheckoutLanguages = makeActionCreator(UPDATE_CHECKOUT_LANGUAGES, "payload");

export const RESET_CHECKOUT_LANGUAGES = "RESET_CHECKOUT_LANGUAGES";
export const resetCheckoutLanguages = makeActionCreator(RESET_CHECKOUT_LANGUAGES, "payload");

export const UPDATE_ACCOUNT_MANAGEMENT_LANGUAGES = "UPDATE_ACCOUNT_MANAGEMENT_LANGUAGES";
export const updateAccountManagementLanguages = makeActionCreator(UPDATE_ACCOUNT_MANAGEMENT_LANGUAGES, "payload");

export const RESET_ACCOUNT_MANAGEMENT_LANGUAGES = "RESET_ACCOUNT_MANAGEMENT_LANGUAGES";
export const resetAccountManagementLanguages = makeActionCreator(RESET_ACCOUNT_MANAGEMENT_LANGUAGES, "payload");

export const RESET_COMPANY_LANGUAGES_CONFIG = "RESET_COMPANY_LANGUAGES_CONFIG";
export const resetCompanyLanguagesConfig = makeActionCreator(RESET_COMPANY_LANGUAGES_CONFIG, "payload");

export const FETCH_COMPANY_LANGUAGES_SUCCESS = "FETCH_COMPANY_LANGUAGES_SUCCESS";
export const FETCH_COMPANY_LANGUAGES_FAILURE = "FETCH_COMPANY_LANGUAGES_FAILURE";
export const FETCH_COMPANY_LANGUAGES_REQUEST = "FETCH_COMPANY_LANGUAGES_REQUEST";

export const UPDATE_COMPANY_LANGUAGES_SUCCESS = "UPDATE_COMPANY_LANGUAGES_SUCCESS";
export const UPDATE_COMPANY_LANGUAGES_FAILURE = "UPDATE_COMPANY_LANGUAGES_FAILURE";
export const UPDATE_COMPANY_LANGUAGES_REQUEST = "UPDATE_COMPANY_LANGUAGES_REQUEST";

export const UPDATE_CHECKOUT_LANGUAGES_SUCCESS = "UPDATE_CHECKOUT_LANGUAGES_SUCCESS";
export const UPDATE_CHECKOUT_LANGUAGES_FAILURE = "UPDATE_CHECKOUT_LANGUAGES_FAILURE";
export const UPDATE_CHECKOUT_LANGUAGES_REQUEST = "UPDATE_CHECKOUT_LANGUAGES_REQUEST";

export const UPDATE_ACCOUNT_MANAGEMENT_LANGUAGES_SUCCESS = "UPDATE_ACCOUNT_MANAGEMENT_LANGUAGES_SUCCESS";
export const UPDATE_ACCOUNT_MANAGEMENT_LANGUAGES_FAILURE = "UPDATE_ACCOUNT_MANAGEMENT_LANGUAGES_FAILURE";
export const UPDATE_ACCOUNT_MANAGEMENT_LANGUAGES_REQUEST = "UPDATE_ACCOUNT_MANAGEMENT_LANGUAGES_REQUEST";

export const fetchCompanyLanguages = function (companyId: number) {
  const getCompanyLanguagesRequest = new GetCompanyLanguagesRequest();
  getCompanyLanguagesRequest.setCompanyId(companyId)

  const companyServiceClient = new CompanyServiceClient(ConfigConstants.grpcBaseUrl);
  return ({
    types: [FETCH_COMPANY_LANGUAGES_REQUEST, FETCH_COMPANY_LANGUAGES_SUCCESS, FETCH_COMPANY_LANGUAGES_FAILURE],
    callAPI: () => grpcUnaryCall(getCompanyLanguagesRequest, companyServiceClient, companyServiceClient.getCompanyLanguages)
  })
}

export const updateCompanyLaguages = function(companyId: number, laguages: Array<ISupportedLanguage>) {
  const updateCompanyLanguagesRequest = new UpdateCompanyLanguagesRequest();
  updateCompanyLanguagesRequest.setCompanyLanguageUpdateInputsList([...laguages.map(language => {
    const languageUpdate = new CompanyLanguageUpdate();
    languageUpdate.setIsoCode(language.isoCode);
    languageUpdate.setIsEnabled(language.isEnabled);
    return languageUpdate;
  })])
  updateCompanyLanguagesRequest.setCompanyId(companyId);

  const companyServiceClient = new CompanyServiceClient(ConfigConstants.grpcBaseUrl);
  return {
    types: [UPDATE_COMPANY_LANGUAGES_REQUEST, UPDATE_COMPANY_LANGUAGES_SUCCESS, UPDATE_COMPANY_LANGUAGES_FAILURE],
    callAPI: () => grpcUnaryCall(updateCompanyLanguagesRequest, companyServiceClient, companyServiceClient.updateCompanyLanguages)
  };
}

export const updateCheckoutDefaultLaguage = function(companyId: number, languageIso: string) {
  const updateCheckoutDefaultLanguageRequest = new UpdateCheckoutDefaultLanguageRequest();
  updateCheckoutDefaultLanguageRequest.setLanguageIsoCode(languageIso);
  updateCheckoutDefaultLanguageRequest.setCompanyId(companyId);

  const companyCheckoutConfigServiceClient = new CompanyCheckoutConfigServiceClient(ConfigConstants.grpcBaseUrl);
  return {
    types: [UPDATE_CHECKOUT_LANGUAGES_REQUEST, UPDATE_CHECKOUT_LANGUAGES_SUCCESS, UPDATE_CHECKOUT_LANGUAGES_FAILURE],
    callAPI: () => grpcUnaryCall(updateCheckoutDefaultLanguageRequest, companyCheckoutConfigServiceClient, companyCheckoutConfigServiceClient.updateCheckoutDefaultLanguage)
  };
}

export const updateAccountManagementDefaultLaguage = function(companyId: number, languageIso: string) {
  const updateAccountManagementLanguageRequest = new UpdateAccountManagementLanguageRequest();
  updateAccountManagementLanguageRequest.setLanguageIsoCode(languageIso);
  updateAccountManagementLanguageRequest.setCompanyId(companyId);

  const companyCheckoutConfigServiceClient = new CompanyCheckoutConfigServiceClient(ConfigConstants.grpcBaseUrl);
  return {
    types: [UPDATE_ACCOUNT_MANAGEMENT_LANGUAGES_REQUEST, UPDATE_ACCOUNT_MANAGEMENT_LANGUAGES_SUCCESS, UPDATE_ACCOUNT_MANAGEMENT_LANGUAGES_FAILURE],
    callAPI: () => grpcUnaryCall(updateAccountManagementLanguageRequest, companyCheckoutConfigServiceClient, companyCheckoutConfigServiceClient.updateAccountManagementDefaultLanguage)
  };
}