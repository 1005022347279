

import React, { useState } from "react";
import { AppState } from "../../../../..";
import { useDispatch, useSelector } from "react-redux";
import Panel from "../../../../ui/panel/Panel";
import Text from "../../../../ui/text/Text";
import { Col, Row } from "react-grid-system";
import Button from "../../../../ui/button/Button";
import counterpart from "counterpart";
import "./ShowHideCheckoutFeaturePanel.scss";
import Checkbox from "../../../../ui/checkbox/Checkbox";
import { grpcUnaryCall } from "../../../../../utils/grpc/grpcUtils";
import { CompanyCheckoutConfigServiceClient } from "../../../../../utils/grpc/generated/Billsby.Protos/core/private/companyconfig/companycheckoutconfig/CompanycheckoutconfigServiceClientPb";
import { UpsertShowHideFeaturesRequest } from "../../../../../utils/grpc/generated/Billsby.Protos/core/private/companyconfig/companycheckoutconfig/companycheckoutconfig_pb";
import { ConfigConstants } from "../../../../../utils/config";
import { AllowedCheckoutMgmtFields, setCheckoutAccountMgmtField, resetCheckoutAcctMgmtShowHideFeatures, updateCheckoutManagementData } from "../../../../../actions/checkoutAccountManagementActions";


const ShowHideCheckoutFeaturePanel: React.FC = () => {
  const [isUpdating, setIsUpdating] = useState(false);

  const checkoutManagement = useSelector((state: AppState) => state.checkoutAccountManagementReducer.checkoutManagement);
  const currentCompanyDomain = useSelector((state: AppState) => state.auth.currentCompanyDomain);

  const dispatch = useDispatch<Function>();
  
  const { isProgressIndicatorVisible, isShoppingCartHidden, isAmexCardLogoVisible, isApplyCouponsVisible, isDinersCardLogoVisible,
    isHowWasThisCalculatedVisible, isJCBCardLogoVisible, isMasterCardLogoVisible, isVisaCardLogoVisible, isWixCompatibilityModeVisible } = checkoutManagement;

  const handleUpdate = async () => {
    setIsUpdating(true);
    const upsertShowHideFeaturesRequest = new UpsertShowHideFeaturesRequest();
    upsertShowHideFeaturesRequest.setCompanyDomain(currentCompanyDomain);
    upsertShowHideFeaturesRequest.setIsAmexCardLogoVisible(isAmexCardLogoVisible);
    upsertShowHideFeaturesRequest.setIsApplyCouponsVisible(isApplyCouponsVisible);
    upsertShowHideFeaturesRequest.setIsDinersCardLogoVisible(isDinersCardLogoVisible);
    upsertShowHideFeaturesRequest.setIsHowWasThisCalculatedVisible(isHowWasThisCalculatedVisible);
    upsertShowHideFeaturesRequest.setIsJcbCardLogoVisible(isJCBCardLogoVisible);
    upsertShowHideFeaturesRequest.setIsMasterCardLogoVisible(isMasterCardLogoVisible);
    upsertShowHideFeaturesRequest.setIsProgressIndicatorVisible(isProgressIndicatorVisible);
    upsertShowHideFeaturesRequest.setIsSummaryCartHidden(isShoppingCartHidden);
    upsertShowHideFeaturesRequest.setIsVisaCardLogoVisible(isVisaCardLogoVisible);
    upsertShowHideFeaturesRequest.setIsWixCompatibilityVisible(isWixCompatibilityModeVisible);
    try {
      const companyCheckoutConfigServiceClient = new CompanyCheckoutConfigServiceClient(ConfigConstants.grpcBaseUrl);
      await grpcUnaryCall(upsertShowHideFeaturesRequest, companyCheckoutConfigServiceClient, companyCheckoutConfigServiceClient.upsertShowHideFeatures)
      dispatch(updateCheckoutManagementData(checkoutManagement))
      setIsUpdating(false);
    } catch (err) {
      setIsUpdating(false);
      console.log(err);
    }
  };

  const updateCheckoutField = (arg: AllowedCheckoutMgmtFields, value: any) => {
    dispatch(setCheckoutAccountMgmtField("checkoutManagement", { ...checkoutManagement, [arg]: value }));
  };

  return (
    <Panel title="SHOW_HIDE_CHECKOUT_FEATURE_PANEL_TITLE" className="show-hide-checkout-feature-panel">
      <Text content="SHOW_HIDE_CHECKOUT_FEATURE_PANEL_TEXT" />
      <Panel className="config-item">
        <div className="image-container progress-indicator">
        </div>
        <div className="text-container">
          <Text content="SHOW_HIDE_CHECKOUT_FEATURE_PROGRESS_INDICATOR" className="title" noMargin />
          <Text content="SHOW_HIDE_CHECKOUT_FEATURE_PROGRESS_INDICATOR_TEXT" className="sub-title" />
        </div>
        <div className="controls">
          <Checkbox checked={isProgressIndicatorVisible} onClick={() => updateCheckoutField("isProgressIndicatorVisible", !isProgressIndicatorVisible)} value='' content="" />
        </div>
      </Panel>

      <Panel className="config-item">
        <div className="image-container summary-cart">
        </div>
        <div className="text-container">
          <Text content="SHOW_HIDE_CHECKOUT_FEATURE_SUMMARY_CART" className="title" noMargin />
          <Text content="SHOW_HIDE_CHECKOUT_FEATURE_SUMMARY_CART_TEXT" className="sub-title" />
        </div>
        <div className="controls">
          <Checkbox checked={!isShoppingCartHidden} onClick={() => updateCheckoutField("isShoppingCartHidden", !isShoppingCartHidden)} value='' content="" />
        </div>
      </Panel>

      <Panel className="config-item">
        <div className="image-container card-logos">
        </div>
        <div className="text-container">
          <Text content="SHOW_HIDE_CHECKOUT_FEATURE_CARD_LOGOS" className="title" noMargin />
          <Text content="SHOW_HIDE_CHECKOUT_FEATURE_CARD_LOGOS_TEXT" className="sub-title" />
        </div>
        <div className="controls multiple">
          <Checkbox checked={isVisaCardLogoVisible} onClick={() => updateCheckoutField("isVisaCardLogoVisible", !isVisaCardLogoVisible)} value='' content={counterpart("SHOW_HIDE_CHECKOUT_FEATURE_CARD_VISA")} />
          <Checkbox checked={isJCBCardLogoVisible} onClick={() => updateCheckoutField("isJCBCardLogoVisible", !isJCBCardLogoVisible)} value='' content={counterpart("SHOW_HIDE_CHECKOUT_FEATURE_CARD_JCB")} />
          <Checkbox checked={isMasterCardLogoVisible} onClick={() => updateCheckoutField("isMasterCardLogoVisible", !isMasterCardLogoVisible)} value='' content={counterpart("SHOW_HIDE_CHECKOUT_FEATURE_CARD_MASTERCARD")} />
          <Checkbox checked={isDinersCardLogoVisible} onClick={() => updateCheckoutField("isDinersCardLogoVisible", !isDinersCardLogoVisible)} value='' content={counterpart("SHOW_HIDE_CHECKOUT_FEATURE_CARD_DINERS")} />
          <Checkbox checked={isAmexCardLogoVisible} onClick={() => updateCheckoutField("isAmexCardLogoVisible", !isAmexCardLogoVisible)} value='' content={counterpart("SHOW_HIDE_CHECKOUT_FEATURE_CARD_AMERICAN_EXPRESS")} />
        </div>
      </Panel>

      <Panel className="config-item">
        <div className="image-container how-was-this-calculated">
        </div>
        <div className="text-container">
          <Text content="SHOW_HIDE_CHECKOUT_FEATURE_HOW_WAS_THIS_CALCULATED" className="title" noMargin />
          <Text content="SHOW_HIDE_CHECKOUT_FEATURE_HOW_WAS_THIS_CALCULATED_TEXT" className="sub-title" />
        </div>
        <div className="controls">
          <Checkbox checked={isHowWasThisCalculatedVisible} onClick={() => updateCheckoutField("isHowWasThisCalculatedVisible", !isHowWasThisCalculatedVisible)} value='' content="" />
        </div>
      </Panel>

      <Panel className="config-item">
        <div className="image-container apply-coupons">
        </div>
        <div className="text-container">
          <Text content="SHOW_HIDE_CHECKOUT_FEATURE_APPLY_COUPONS" className="title" noMargin />
          <Text content="SHOW_HIDE_CHECKOUT_FEATURE_APPLY_COUPONS_TEXT" className="sub-title" />
        </div>
        <div className="controls">
          <Checkbox checked={isApplyCouponsVisible} onClick={() => updateCheckoutField("isApplyCouponsVisible", !isApplyCouponsVisible)} value='' content="" />
        </div>
      </Panel>

      <Panel className="config-item">
        <div className="image-container wix-compatibility">
        </div>
        <div className="text-container">
          <Text content="SHOW_HIDE_CHECKOUT_FEATURE_WIX_COMPATIBILITY_MODE" className="title" noMargin />
          <Text content="SHOW_HIDE_CHECKOUT_FEATURE_WIX_COMPATIBILITY_MODE_TEXT" className="sub-title" />
        </div>
        <div className="controls">
          <Checkbox checked={isWixCompatibilityModeVisible} onClick={() => updateCheckoutField("isWixCompatibilityModeVisible", !isWixCompatibilityModeVisible)} value='' content="" />
        </div>
      </Panel>

      <Row align="center">
        <Col md={9}>
          <Button id="show-hide-feature-panel-save-btn" type="submit" onClick={() => handleUpdate()} isLoading={isUpdating} isFullWidth buttonType="general" title="SHOW_HIDE_CHECKOUT_FEATURE_SAVE_BUTTON" />
        </Col>
        <Col md={3}>
          <Button id="show-hide-feature-panel-cancel-btn" isFullWidth buttonType="error" title="CHECKOUT_ACCT_MANAGEMENT_BTN_CANCEL" onClick={() => dispatch(resetCheckoutAcctMgmtShowHideFeatures())} />
        </Col>
      </Row>
    </Panel>
  );
};

export default ShowHideCheckoutFeaturePanel;