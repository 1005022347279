import React from "react";
import "./InvalidRole.scss";
import Panel from "../../components/ui/panel/Panel";
import Text from "../../components/ui/text/Text";

interface IInvalidRole {
  description?: string,
  shouldTranslateDescription?: boolean
}

const InvalidRole: React.FC<IInvalidRole> = React.memo(({ description = "INVALID_ROLE_CONTENT", shouldTranslateDescription = true }) => {
  return (
    <div className="invalid-role">
      <Panel className="invalid-role__panel" title="INVALID_ROLE_TITLE">
        <Text noMargin content={description} shouldTranslate={shouldTranslateDescription} />
      </Panel>
    </div>
  )
})

export default InvalidRole;