import React, { useLayoutEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ICreateAddOnsReducerState } from "../../../reducers/add-ons/create-addons/createAddOnsReducer";
import { ISelectPlanAddOnsReducerState } from "../../../reducers/add-ons/selectAddOnPlanReducer";
import { IAuthReducerState } from "../../../reducers/authReducer";
import { fetchCompanyProductsWithPlans, setAddOnsStepsCollapsed, setShowSelectPlanModal, setSelectedAddOnsPlansPage, setPlanForceAddOn, removeAddOnsSelectedPlan, setAddOnsFormCompleted } from "../../../actions/addOnsActions";
import { AppState } from "../../..";
import SelectAddOnPlan from "../select-add-on-plan/SelectAddOnPlan";
import StepCollapsablePanel from "../../ui/step-collapsable-panel/StepCollapsablePanel";
import Button from "../../ui/button/Button";
import Text from "../../ui/text/Text";
import Pagination from "../../ui/pagination/Pagination";
import Switch from "../../ui/switch/Switch";
import Panel from "../../ui/panel/Panel";
import { AddonsAllowancesScreen } from "../../../models/AddonsAllowances";

interface Props {
  type: AddonsAllowancesScreen;
  addOnId: number | null
}

const StepSelectAddOnPlans: React.FC<Props> = ({ type, addOnId }) => {
  const auth = useSelector<AppState, IAuthReducerState>(state => state.auth)
  const addOns = useSelector<AppState, ICreateAddOnsReducerState>(state => state.createAddOnsReducer)
  const selectAddOnPlan = useSelector<AppState, ISelectPlanAddOnsReducerState>(state => state.selectAddOnPlanReducer)
  const { stageStatuses, stageErrors, stageCollapsed } = addOns;
  const { showSelectPlanModal, numberOfpages, selectedPlans, selectedPlansDisplay } = selectAddOnPlan;
  const dispatch = useDispatch<Function>()
  
  const isAddonScreen = type === AddonsAllowancesScreen.ADDONS;
  useLayoutEffect(() => {
    dispatch(fetchCompanyProductsWithPlans(auth.currentCompanyId as number));
    return () => { };
  }, []);

  const getTitle = () => {
    if (isAddonScreen && !addOnId) {
      return "CREATE_ADD_ONS_FORM_HEADER_SELECT_PLANS"
    }
    if (isAddonScreen && addOnId) {
      return "EDIT_ADD_ONS_FORM_HEADER_SELECT_PLANS";
    }
    if (!isAddonScreen && !addOnId) {
      return "CREATE_ALLOWANCES_FORM_HEADER_SELECT_PLANS";
    }
    return "EDIT_ALLOWANCES_FORM_HEADER_SELECT_PLANS";
  }

  return (
    <>
      {showSelectPlanModal && <SelectAddOnPlan type={type} />}
      {!showSelectPlanModal && (
        <StepCollapsablePanel
          title={getTitle()}
          number={2}
          isShowTitleIcon={!addOnId}
          isCompleted={selectedPlans.length > 0 || stageStatuses[1]}
          isLocked={stageErrors[0] || !stageStatuses[0]}
          isCollapsed={addOnId ? false : stageCollapsed[1]}
          hasError={stageErrors[1]}
          hasHelpSection={false}
          className="create-add-ons__select-plans"
          onClick={() => dispatch(setAddOnsStepsCollapsed(1))}
        >
          <div>
            <Text content={isAddonScreen ? "CREATE_ADD_ONS_FORM_HEADER_SELECT_PLANS_Text" : "CREATE_ALLOWANCES_FORM_HEADER_SELECT_PLANS_Text"} />
            <div className="create-add-ons__select_add_ons_plan">
              <Button
                className="create-add-ons__select_add_ons_plan__btn"
                id="create-add-ons-select-plan-btn"
                title="CREATE_ADD_ONS_FORM_HEADER_SELECT_PLANS_BUTTON_Text"
                buttonType="general"
                icon="fal fa-plus-circle"
                isFullWidth={true}
                onClick={() => dispatch(setShowSelectPlanModal(true))}
              />
              {numberOfpages > 0 &&
                <Pagination
                  className="create-add-ons__select_add_ons_plan__pagination"
                  pageCount={numberOfpages}
                  isVisible={numberOfpages > 1}
                  onPageChange={({ selected }) => dispatch(setSelectedAddOnsPlansPage(selected + 1))}
                />}
            </div>
            {selectedPlans.length === 0 && (
              <Text
                content={isAddonScreen ? "CREATE_ADD_ONS_FORM_HEADER_SELECT_PLANS_ATTACH_LATER" : "CREATE_ALLOWANCES_FORM_HEADER_SELECT_PLANS_ATTACH_LATER"}
                className="create-add-ons__select-plans__attach-later"
                onClick={() => {
                  dispatch(setAddOnsFormCompleted(1, true));
                  !addOnId && dispatch(setAddOnsStepsCollapsed(2));
                }}
              />
            )}

            {selectedPlansDisplay.length > 0 &&
              <div className="create-add-ons__select-plans__container">
                {selectedPlansDisplay.map(selectedPlan =>
                  <Panel className="create-add-ons__select-plans__list" key={`selected-add-on-${selectedPlan.planId}`}>
                    <div className="create-add-ons__select-plans__listitem">
                      <Text content={selectedPlan.product} shouldTranslate={false} noMargin />
                    </div>
                    <div className="create-add-ons__select-plans__listitem create-add-ons__select-plans__listitem-plan">
                      <Text content={selectedPlan.plan} shouldTranslate={false} noMargin />
                    </div>
                    <div className="create-add-ons__select-plans__list__commands">
                      <Switch
                        id={`switch-selected-add-on-${selectedPlan.planId}`}
                        checked={selectedPlan.isForced}
                        onChange={() => dispatch(setPlanForceAddOn(selectedPlan.planId))}
                        className="create-add-ons__select-plans__list__commands-force"
                      />
                      <Text
                        content={isAddonScreen ? "CREATE_ADD_ONS_FORM_HEADER_SELECT_PLANS_FORCE_ADD" : "CREATE_ALLOWANCES_FORM_HEADER_SELECT_PLANS_FORCE_ADD"}
                        className="create-add-ons__select-plans__list__commands-force-label"
                        noMargin
                      />
                      <span>
                        <i
                          className="create-add-ons__select-plans__commands-delete far fa-trash-alt"
                          onClick={() => {
                            dispatch(removeAddOnsSelectedPlan(selectedPlan.planId));
                          }}
                        />
                      </span>
                    </div>
                  </Panel>
                )
                }</div>}

          </div>
        </StepCollapsablePanel>
      )}
    </>
  )
}

export default StepSelectAddOnPlans