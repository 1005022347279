import { BillsbyAction } from "../models/BillsbyAction";
import {
  SET_IS_COLLAPSED_KEEPING_SECURE,
  FETCH_COMPANY_API_KEY_SUCCESS
} from "../actions/companyApiKeysActions";
import { AppState } from "..";
import { ICompanyApiKey } from "../models/CompanyApiKey";

export interface ICompanyApiKeyReducerState {
  apiKey: string,
  companyId: number,
  createdOn: Date,
  name: string,
  isCollapsedKeepingSecure: boolean,
}

const initialState = {
  apiKey: "",
  companyId: 0,
  createdOn: new Date(),
  name: "",
  isCollapsedKeepingSecure: true
};

export default function companyApiKeyReducer(
  state: ICompanyApiKeyReducerState = initialState,
  action: BillsbyAction,
  store: AppState
) {
  switch (action.type) {
    case FETCH_COMPANY_API_KEY_SUCCESS:
      const companyApiKeyReducer: Array<ICompanyApiKey> = action.response;
      return { ...state, ...companyApiKeyReducer };
    case SET_IS_COLLAPSED_KEEPING_SECURE:
      return { ...state, isCollapsedKeepingSecure: action.payload };
    default:
      return state;
  }
}
