/**
 * @fileoverview gRPC-Web generated client stub for billing.private.companyconfig
 * @enhanceable
 * @public
 */

// GENERATED CODE -- DO NOT EDIT!


import * as grpcWeb from "grpc-web";

import {
  GetAchPaymentConfigRequest,
  GetAchPaymentConfigResponse,
  GetInvoiceAddressConfigRequest,
  GetInvoiceAddressConfigResponse,
  GetInvoiceConfigRequest,
  GetInvoiceConfigResponse,
  UpdateAchPaymentConfigRequest,
  UpdateAchPaymentConfigResponse,
  UpsertInvoiceAddressConfigRequest,
  UpsertInvoiceConfigRequest,
  UpsertInvoiceConfigResponse} from "./company_config_pb";

export class CompanyConfigServiceClient {
  client_: grpcWeb.AbstractClientBase;
  hostname_: string;
  credentials_: null | { [index: string]: string; };
  options_: null | { [index: string]: string; };

  constructor (hostname: string,
               credentials?: null | { [index: string]: string; },
               options?: null | { [index: string]: string; }) {
    if (!options) options = {};
    if (!credentials) credentials = {};
    options["format"] = "text";

    this.client_ = new grpcWeb.GrpcWebClientBase(options);
    this.hostname_ = hostname;
    this.credentials_ = credentials;
    this.options_ = options;
  }

  methodInfoUpsertInvoiceAddressConfig = new grpcWeb.AbstractClientBase.MethodInfo(
    GetInvoiceAddressConfigResponse,
    (request: UpsertInvoiceAddressConfigRequest) => {
      return request.serializeBinary();
    },
    GetInvoiceAddressConfigResponse.deserializeBinary
  );

  upsertInvoiceAddressConfig(
    request: UpsertInvoiceAddressConfigRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: GetInvoiceAddressConfigResponse) => void) {
    return this.client_.rpcCall(
      this.hostname_ +
        "/billing.private.companyconfig.CompanyConfigService/UpsertInvoiceAddressConfig",
      request,
      metadata || {},
      this.methodInfoUpsertInvoiceAddressConfig,
      callback);
  }

  methodInfoGetInvoiceAddressConfig = new grpcWeb.AbstractClientBase.MethodInfo(
    GetInvoiceAddressConfigResponse,
    (request: GetInvoiceAddressConfigRequest) => {
      return request.serializeBinary();
    },
    GetInvoiceAddressConfigResponse.deserializeBinary
  );

  getInvoiceAddressConfig(
    request: GetInvoiceAddressConfigRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: GetInvoiceAddressConfigResponse) => void) {
    return this.client_.rpcCall(
      this.hostname_ +
        "/billing.private.companyconfig.CompanyConfigService/GetInvoiceAddressConfig",
      request,
      metadata || {},
      this.methodInfoGetInvoiceAddressConfig,
      callback);
  }

  methodInfoUpsertInvoiceConfig = new grpcWeb.AbstractClientBase.MethodInfo(
    UpsertInvoiceConfigResponse,
    (request: UpsertInvoiceConfigRequest) => {
      return request.serializeBinary();
    },
    UpsertInvoiceConfigResponse.deserializeBinary
  );

  upsertInvoiceConfig(
    request: UpsertInvoiceConfigRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: UpsertInvoiceConfigResponse) => void) {
    return this.client_.rpcCall(
      this.hostname_ +
        "/billing.private.companyconfig.CompanyConfigService/UpsertInvoiceConfig",
      request,
      metadata || {},
      this.methodInfoUpsertInvoiceConfig,
      callback);
  }

  methodInfoGetInvoiceConfig = new grpcWeb.AbstractClientBase.MethodInfo(
    GetInvoiceConfigResponse,
    (request: GetInvoiceConfigRequest) => {
      return request.serializeBinary();
    },
    GetInvoiceConfigResponse.deserializeBinary
  );

  getInvoiceConfig(
    request: GetInvoiceConfigRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: GetInvoiceConfigResponse) => void) {
    return this.client_.rpcCall(
      this.hostname_ +
        "/billing.private.companyconfig.CompanyConfigService/GetInvoiceConfig",
      request,
      metadata || {},
      this.methodInfoGetInvoiceConfig,
      callback);
  }

  methodInfoGetAchPaymentConfig = new grpcWeb.AbstractClientBase.MethodInfo(
    GetAchPaymentConfigResponse,
    (request: GetAchPaymentConfigRequest) => {
      return request.serializeBinary();
    },
    GetAchPaymentConfigResponse.deserializeBinary
  );

  getAchPaymentConfig(
    request: GetAchPaymentConfigRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: GetAchPaymentConfigResponse) => void) {
    return this.client_.rpcCall(
      this.hostname_ +
        "/billing.private.companyconfig.CompanyConfigService/GetAchPaymentConfig",
      request,
      metadata || {},
      this.methodInfoGetAchPaymentConfig,
      callback);
  }

  methodInfoUpdateAchPaymentConfig = new grpcWeb.AbstractClientBase.MethodInfo(
    UpdateAchPaymentConfigResponse,
    (request: UpdateAchPaymentConfigRequest) => {
      return request.serializeBinary();
    },
    UpdateAchPaymentConfigResponse.deserializeBinary
  );

  updateAchPaymentConfig(
    request: UpdateAchPaymentConfigRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: UpdateAchPaymentConfigResponse) => void) {
    return this.client_.rpcCall(
      this.hostname_ +
        "/billing.private.companyconfig.CompanyConfigService/UpdateAchPaymentConfig",
      request,
      metadata || {},
      this.methodInfoUpdateAchPaymentConfig,
      callback);
  }

}

