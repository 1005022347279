import React, { FormEvent, useEffect, useState } from "react";
import Panel from "../../ui/panel/Panel";
import Text from "../../ui/text/Text";
import { Row, Col } from "react-grid-system";
import FormLabel from "../../ui/form-label/FormLabel";
import Input from "../../ui/input/Input";
import PhoneInput from "../../ui/phone-input/PhoneInput";
import Checkbox from "../../ui/checkbox/Checkbox";
import Button from "../../ui/button/Button";
import FormGroup from "../../ui/form-group/FormGroup";
import "./EditProfile.scss";
import API from "../../../utils/API";
import { INewUserDetails } from "../../../models/NewUserDetails";
import { useDispatch, useSelector } from "react-redux";
import { refreshToken } from "../../../utils/authUtils";
import { initAuthData } from "../../../actions/authActions";
import ErrorNotification from "../../ui/error-notification/ErrorNotification";
import { AppState } from "../../..";
import { IAuthReducerState } from "../../../reducers/authReducer";
const { isValidNumber } = (global as any).intlTelInputUtils;

const EditProfile: React.FC = () => {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [phoneNumberCountry, setPhoneNumberCountry] = useState("");
  const [phoneNumberPrefix, setPhoneNumberPrefix] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [preferredCountries, setPreferredCountries] = useState(["US", "GB", "AU", "CA"]);
  const [isOptedInForMarketing, setisOptedInForMarketing] = useState(true);
  const [hasError, setHasError] = useState(false);
  const [success, setSuccess] = useState(false);
  const [isLoadingData, setIsLoadingData] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const { currentCompanyId } = useSelector<AppState, IAuthReducerState>(state => state.auth)
  const dispatch = useDispatch();

  useEffect(() => {
    const fetchData = async () => {
      setIsLoadingData(true)
      try {
        const newUserDetails = await API.getUserDetails() as INewUserDetails;
        const { email, firstName, lastName, phoneNumber, phoneNumberDialCode, phoneNumberDialCountry, isOptedInForMarketing } = newUserDetails;

        const phoneNumberCountry = phoneNumberDialCountry;

        const _preferredCountries = [...preferredCountries];
        const index = _preferredCountries.indexOf(phoneNumberCountry);
        if (_preferredCountries.includes(phoneNumberCountry)) {
          if (index > -1) {
            _preferredCountries.splice(index, 1)
          }

          _preferredCountries.splice(0, 0, phoneNumberCountry);
        }

        setEmail(email);
        setFirstName(firstName);
        setLastName(lastName);
        setPhoneNumberCountry(phoneNumberCountry ? phoneNumberCountry.toLowerCase() : phoneNumberCountry)
        setPhoneNumberPrefix(phoneNumberDialCode);
        setPhoneNumber(phoneNumber);
        setPreferredCountries(_preferredCountries);
        setisOptedInForMarketing(isOptedInForMarketing)

      } catch (err) {
        console.log(err);
      } finally {
        setIsLoadingData(false)
      }
    }

    fetchData();
  }, [])

  const onSubmit = async (evt: FormEvent) => {
    evt.preventDefault();
    evt.stopPropagation();
    
    const newUserDetails: INewUserDetails = {
      firstName,
      lastName,
      email,
      phoneNumberDialCountry: phoneNumberCountry,
      phoneNumberDialCode: phoneNumberPrefix,
      phoneNumber,
      isOptedInForMarketing
    };

    if (!isValidNumber(`+${newUserDetails.phoneNumberDialCode}${newUserDetails.phoneNumber}`)) {
      return;
    }

    setHasError(false);
    setSuccess(false);
    setIsLoading(true);

    try {
      await API.updateUserProfile(newUserDetails);
      setSuccess(true);
      await refreshToken(currentCompanyId as number);
      // need to invalidate the token and get a new one to update the name in the header and in all the components that are using it
      dispatch(initAuthData());
    }
    catch (err) {
      setHasError(true);
      console.log("error", err);
    }
    finally {
      setIsLoading(false)
    }
  }

  if(isLoadingData) {
    return null
  }

  return (
    <form className="edit-profile" onSubmit={onSubmit}>
      <ErrorNotification
        title="UPDATE_PROFILE_ERROR"
        showAlert={hasError}
        isModal
        onClose={() => setHasError(false)}
      />
      <Panel className="edit-profile__titlepanel" title="EDIT_PROFILE_PANEL1_TITLE">
        <Text content="EDIT_PROFILE_PANEL1_CONTENT" noMargin />
      </Panel>

      <Panel>
        <FormGroup>
          <Row align="center">
            <Col md={2}>
              <FormLabel target="firstname" content="EDIT_PROFILE_FULLNAME" noMargin />
            </Col>
            <Col md={5}>
              <Input required id="firstname" placeholder="James" value={firstName} onChange={(evt: any) => setFirstName(evt.target.value)} />
            </Col>
            <Col md={5}>
              <Input required id="lastname" placeholder="Stevenson" value={lastName} onChange={(evt: any) => setLastName(evt.target.value)} />
            </Col>
          </Row>
        </FormGroup>

        <FormGroup>
          <Row align="center">
            <Col md={2}>
              <FormLabel target="email" content="EDIT_PROFILE_EMAIL" />
            </Col>
            <Col md={10}>
              <Input required id="email" placeholder="james.stevenson@mycompany.com" value={email} onChange={(evt: any) => setEmail(evt.target.value)} />
            </Col>
          </Row>
        </FormGroup>

        <FormGroup>
          <Row align="center">
            <Col md={2}>
              <FormLabel target="phonenumber" content="EDIT_PROFILE_PHONENUMBER" noMargin />
            </Col>
            <Col md={10}>
              <PhoneInput
                preferredCountries={preferredCountries}
                value={phoneNumber}
                dialCode={phoneNumberPrefix}
                defaultCountry={phoneNumberCountry}
                isRequired
                onPhoneNumberChange={(isValid: boolean, phone: string, countryData: any) => {
                  setPhoneNumber(phone);
                  setPhoneNumberCountry(countryData.iso2);
                  setPhoneNumberPrefix(countryData.dialCode)
                }}
                onSelectFlag={(phone: string, countryData: any) => {
                  setPhoneNumber(phone);
                  setPhoneNumberCountry(countryData.iso2);
                  setPhoneNumberPrefix(countryData.dialCode)
                }}
                errorLabel="REGISTRATION_PHONE_VALIDATION_ERROR"
                separateDialCode
              />
            </Col>
          </Row>
        </FormGroup>

        <FormGroup>
          <Row>
            <Col md={2} />
            <Col md={10}>
              <Checkbox
                id="opted-marketing"
                checked={isOptedInForMarketing}
                onClick={() => setisOptedInForMarketing(!isOptedInForMarketing)}
                value="somevalue"
                content={
                  <Text content="EDIT_PROFILE_NOTIFICATIONS" noMargin />
                }
              />
            </Col>
          </Row>
        </FormGroup>
      </Panel>

      <Button
        id="save-changes"
        type="submit"
        icon={success ? "far fa-check-circle" : ""}
        buttonType={success ? "success" : "general"}
        isLoading={isLoading && !hasError}
        title={success ? "EDIT_PROFILE_SAVE_CHANGES_SUCCESS" : "EDIT_PROFILE_SAVE_CHANGES"}
        isFullWidth
      />
    </form>
  )
}


export default EditProfile;