import React, { useEffect, useRef } from "react";
import counterpart from "counterpart";
import Input from "../ui/input/Input";
import "./Search.scss";

interface ISearch {
  value?: string,
  placeholder: string,
  shouldTranslatePlaceholder?: boolean,
  className?: string,
  onChange: (evt: any) => void,
  onSearch?: () => void
}

const Search: React.FC<ISearch> = ({ value, placeholder, shouldTranslatePlaceholder, className, onChange, onSearch }) => {
  const DEBOUNCE_TIME = 500; //ms
  const firstDebounce = useRef(true);

  useEffect(() => {
    if (firstDebounce.current) {
      firstDebounce.current = false;
      return;
    }

    const debouncedOnSearch = setTimeout(() => {
      onSearch && onSearch()
    }, DEBOUNCE_TIME);

    return () => clearTimeout(debouncedOnSearch)
  }, [value])

  return (
    <Input
      id="search"
      className={`search ${className}`}
      icon="far fa-search"
      placeholder={shouldTranslatePlaceholder || shouldTranslatePlaceholder === undefined ? counterpart(placeholder) : placeholder}
      value={value}
      onChange={evt => {
        onChange(evt);
      }}
    />
  );
}

export default Search;