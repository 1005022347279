import {
  LOGIN_USER_REQUEST, LOGIN_USER_FAILURE, LOGIN_USER_SUCCESS, SET_REMEMBER_USER, SET_EMAIL,
  SET_PASSWORD, RESET_PASSWORD_REQUEST_FAILURE, RESET_PASSWORD_REQUEST, RESET_PASSWORD_REQUEST_SUCCESS,
  SET_CONFIRM_PASSWORD, CLEAR_ERRORS, SUBMIT_NEW_PASSWORD_REQUEST, SUBMIT_NEW_PASSWORD_REQUEST_SUCCESS,
  SUBMIT_NEW_PASSWORD_REQUEST_FAILURE, CHECK_PASSWORD_VALIDITY, SET_SIX_DIGITS_2FA_PART1, SET_SIX_DIGITS_2FA_PART2, SET_INCORRECT_PASSWORD_COUNT
} from "../actions/loginActions";
import { BillsbyAction } from "../models/BillsbyAction";
import counterpart from "counterpart";
import { AppState } from "..";

export interface ILoginReducerState {
  password: string,
  email: string,
  rememberUser: boolean,
  incorrectPasswordCount: number,
  hasForgottenPassword: boolean,
  emailErrorMsg: string,
  passwordErrorMsg: string,
  passwordRequestSent: boolean,
  passwordConfirmation: string,
  isValidating: boolean,
  isLoginRequest: boolean,
  isLoginSuccess: boolean,
  isLoginError: boolean,
  passwordIsValid: boolean,
  passwordHasBeenSent: boolean | null,
  sixDigits2faPart1: string,
  sixDigits2faPart2: string
}

export const initialState = {
  password: "",
  email: "",
  rememberUser: true,
  incorrectPasswordCount: 0,
  hasForgottenPassword: false,
  emailErrorMsg: "",
  passwordErrorMsg: "",
  passwordRequestSent: false,
  passwordConfirmation: "",
  isValidating: false,
  isLoginRequest: false,
  isLoginSuccess: false,
  isLoginError: false,
  passwordIsValid: false,
  passwordHasBeenSent: null,
  sixDigits2faPart1: "",
  sixDigits2faPart2: ""
}

export default function loginReducer(state: ILoginReducerState = initialState, action: BillsbyAction, store: AppState) {
  const errMsg: string = action.error && action.error.message && action.error.message.toUpperCase();

  switch (action.type) {
    case LOGIN_USER_FAILURE: {
      const flags = { isValidating: false, isLoginRequest: false, isLoginSuccess: false, isLoginError: true };
      if (errMsg === "USER_LOGIN_INVALID_PASSWORD_ATTEMPT" && state.incorrectPasswordCount < 5) {
        return { 
          ...state, 
          ...flags,
          incorrectPasswordCount: state.incorrectPasswordCount + 1,
          passwordErrorMsg: counterpart("USER_LOGIN_INVALID_PASSWORD_ATTEMPT"),
          emailErrorMsg: "",
        }
      }

      if (errMsg === "USER_LOGIN_INVALID_EMAIL" || errMsg === "USER_LOGIN_UNATHORIZED") {
        return { 
          ...state, 
          ...flags,
          emailErrorMsg: counterpart("USER_LOGIN_INVALID_EMAIL"),
          passwordErrorMsg: ""
        }  
      }

      if (errMsg === "USER_LOGIN_EMPTY_PASSWORD") {        
        return { 
          ...state, 
          ...flags,
          emailErrorMsg: "",
          passwordErrorMsg: counterpart("USER_LOGIN_EMPTY_PASSWORD")
        }  
      }

      if (errMsg === "USER_LOGIN_EMPTY_EMAIL") {        
        return { 
          ...state, 
          ...flags,
          emailErrorMsg: counterpart("USER_LOGIN_EMPTY_EMAIL"),
          passwordErrorMsg: ""
        }  
      }
      
      if (action.error) {
        return { 
          ...state, 
          ...flags,
          emailErrorMsg: counterpart("USER_LOGIN_GENERIC_ERROR"),
          passwordErrorMsg: ""
        }  
      }

      return { ...state, ...flags }
    }
    case LOGIN_USER_SUCCESS: {
      return { ...state, isValidating: false, isLoginRequest: false, isLoginSuccess: true, isLoginError: false }
    }
    case LOGIN_USER_REQUEST: {
      return { ...state, isValidating: true, isLoginRequest: true, isLoginSuccess: false, isLoginError: false }
    }
    case SET_REMEMBER_USER:
      return { ...state, rememberUser: !state.rememberUser }
    case SET_EMAIL:
      return { ...state, email: action.payload }
    case SET_PASSWORD:
      return { ...state, password: action.payload }
    case SET_CONFIRM_PASSWORD:
      return { ...state, passwordConfirmation: action.payload }
    case RESET_PASSWORD_REQUEST:
      return { ...state, isValidating: true }
    case RESET_PASSWORD_REQUEST_FAILURE: {
      if (errMsg === "USER_FORGOT_PASSWORD_INVALID_EMAIL" || errMsg === "USER_FORGOT_PASSWORD_USER_NOTFOUND") {
        return { 
          ...state, 
          isValidating: false,
          emailErrorMsg: counterpart(errMsg),
        }  
      }
      return { ...state, isValidating: false }
    }
    case RESET_PASSWORD_REQUEST_SUCCESS:
      return { 
        ...state,
        isValidating: false,
        passwordRequestSent: true,
        emailErrorMsg: ""
      }
    case SUBMIT_NEW_PASSWORD_REQUEST: 
      return { ...state, isValidating: true, passwordRequestSent: false }
    case SUBMIT_NEW_PASSWORD_REQUEST_FAILURE: 
      return { ...state, isValidating: false, passwordHasBeenSent: false }
    case SUBMIT_NEW_PASSWORD_REQUEST_SUCCESS: 
      return { ...state, isValidating: false, passwordHasBeenSent: true, incorrectPasswordCount: 0 }
    case CLEAR_ERRORS:
      return { ...state, emailErrorMsg: "", passwordErrorMsg: "", isValidating: false, isLoginRequest: false, isLoginSuccess: false, isLoginError: false }
    case CHECK_PASSWORD_VALIDITY: 
      return { ...state, passwordIsValid: action.payload}
    case SET_SIX_DIGITS_2FA_PART1:
      return { ...state, sixDigits2faPart1: action.payload }
    case SET_SIX_DIGITS_2FA_PART2:
      return { ...state, sixDigits2faPart2: action.payload }
    case SET_INCORRECT_PASSWORD_COUNT:
      return { ...state, incorrectPasswordCount: action.payload }
    default:
      return state;
  }
}