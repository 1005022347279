import { makeActionCreator, makeGenericActionCreatorTypeSafe } from "../utils/reduxUtils";
import { grpcUnaryCall } from "../utils/grpc/grpcUtils";
import { GetPaymentLogsPaginatedRequest, PaymentLogStatus} from "../utils/grpc/generated/Billsby.Protos/billing/private/paymentlogs/payment_logs_pb";
import { PaymentLogServiceClient } from "../utils/grpc/generated/Billsby.Protos/billing/private/paymentlogs/Payment_logsServiceClientPb";
import { PAGINATION_PAGE_SIZE } from "../utils/constants";
import { ConfigConstants } from "../utils/config";
import { ITransactionLogsReducerState } from "../reducers/transactionLogsReducer";

export const SET_TRANSACTION_LOGS_FIELD = "SET_TRANSACTION_LOGS_FIELD";
export const setTransactionLogsField = makeGenericActionCreatorTypeSafe<ITransactionLogsReducerState>(SET_TRANSACTION_LOGS_FIELD);

export const FETCH_TRANSACTION_LOGS_REQUEST = "TRANSACTION_LOGS_REQUEST";
export const FETCH_TRANSACTION_LOGS_SUCCESS = "TRANSACTION_LOGS_SUCCESS";
export const FETCH_TRANSACTION_LOGS_FAILURE = "TRANSACTION_LOGS_FAILURE";

export const RESET_TRANSACTION_LOGS_STATE = "RESET_TRANSACTION_LOGS_STATE";
export const resetTransactionLogsState = makeActionCreator(RESET_TRANSACTION_LOGS_STATE);

export const fetchTransactionLogs = (companyId: number, pageNumber: number, search: string,  logStatus?: PaymentLogStatus, customerIdType?: GetPaymentLogsPaginatedRequest.PaymentLogType, isCreatedOnSortDescendingOrder: boolean = true) => {
  const request = new GetPaymentLogsPaginatedRequest();
  const serviceClient = new PaymentLogServiceClient(ConfigConstants.grpcBaseUrl);

  request.setCompanyId(companyId);
  request.setSearch(search);
  request.setPaymentLogStatusFilter(logStatus || PaymentLogStatus.UNSPECIFIED);
  request.setPaymentLogTypeFilter(customerIdType || GetPaymentLogsPaginatedRequest.PaymentLogType.UNSPECIFIED);
  request.setPage(pageNumber);
  request.setPageSize(PAGINATION_PAGE_SIZE);
  isCreatedOnSortDescendingOrder ? request.setOrderByDescending("createdOn") : request.setOrderBy("createdOn");

  return {
    types: [FETCH_TRANSACTION_LOGS_REQUEST, FETCH_TRANSACTION_LOGS_SUCCESS, FETCH_TRANSACTION_LOGS_FAILURE],
    callAPI: () => grpcUnaryCall(request, serviceClient, serviceClient.getPaymentLogsPaginated)
  }
}