import React from "react";
import { AddonPriceModel, Currency, UnitTier } from "../../../utils/grpc/generated/Billsby.Protos/core/private/addons/addons_pb";
import { PricingModelType } from "../../../models/Product";
import FlatFeePriceModel from "./FlatFeePriceModel";
import TierPriceModel from "./TierPriceModel";
import { getFrequencyTypeEnum, getCycleFrequencyText, toAddOnTierUnit, allowZeroAndPositiveNumbers } from "../../../utils/commonUtils";
import { Int32Value } from "google-protobuf/google/protobuf/wrappers_pb";
import { AddonsAllowancesScreen } from "../../../models/AddonsAllowances";
import CappedPriceModel from "./CappedPriceModel";
import { AllowancePriceModel } from "../../../utils/grpc/generated/Billsby.Protos/core/private/allowances/allowances_pb";

interface IAddOnConfigurePrice {
  priceModel: AddonPriceModel | AllowancePriceModel | null;
  setPriceModel: (priceModel: AddonPriceModel | AllowancePriceModel | null) => void;
  selectedCurrency: Currency;
  selectedPricingModel: PricingModelType;
  className?: string;
  type: AddonsAllowancesScreen;
}

const AddOnConfigurePrice: React.FC<IAddOnConfigurePrice> = React.memo(({ priceModel, setPriceModel, selectedCurrency, selectedPricingModel,
  className = "", type }) => {

  const setPriceFormatted = (price?: number, nrUnits?: number) => {
    if (priceModel && (price || price === 0)) {
      const priceInt32 = new Int32Value();
      priceInt32.setValue(price);
      if (selectedPricingModel === PricingModelType.FlatFee) {
        (priceModel as AddonPriceModel).setFlatFeePrice(priceInt32);
        (priceModel as AddonPriceModel).setFlatFeePriceFormatted(`${price}`);
      }
      if (selectedPricingModel === PricingModelType.PerUnit) {
        if(type === AddonsAllowancesScreen.ADDONS) {
          (priceModel as AddonPriceModel).setPerUnitPrice(priceInt32);
        }
        else {
          (priceModel as AllowancePriceModel).setPerUnitPrice(priceInt32.getValue().toString());
        }
        priceModel.setPerUnitPriceFormatted(`${price}`);
      }
      if(selectedPricingModel === PricingModelType.PerUnit && type === AddonsAllowancesScreen.ALLOWANCES) {
        if (nrUnits) {
          const units = new Int32Value();
          units.setValue(nrUnits);
          (priceModel as AllowancePriceModel).setIncludedUnits(units); 
        }
        else {
          (priceModel as AllowancePriceModel).setIncludedUnits(undefined); 
        }
      }
      priceModel.setCurrency(selectedCurrency);
      setPriceModel(null);
    }
  };

  const setUnitTiers = (tiers: UnitTier[], nrUnits?: number) => {
    if (tiers && priceModel) {
      priceModel.setTiersList([]);
      tiers.forEach((item) => priceModel.addTiers(item));
      priceModel.setCurrency(selectedCurrency);
      if((priceModel as any).setIncludedUnits) {
        if(nrUnits) {
          const units = new Int32Value();
          units.setValue(nrUnits);
          (priceModel as AllowancePriceModel).setIncludedUnits(units); 
        }
        else {
          (priceModel as AllowancePriceModel).setIncludedUnits(undefined); 
        }
      }
      setPriceModel(null);
    }
  };


  if (selectedPricingModel === PricingModelType.Capped) {
    return priceModel && (
      <CappedPriceModel
        nrUnits={!!(priceModel as AllowancePriceModel).getIncludedUnits && Number((priceModel as AllowancePriceModel).getIncludedUnits())}
        configure={(nrUnits: number) => {
          const units = new Int32Value();
          units.setValue(nrUnits);
          (priceModel as AllowancePriceModel).setIncludedUnits(units); 
          priceModel.setCurrency(selectedCurrency);
          setPriceModel(null);
        }}
        cancel={() => { setPriceModel(null)}}
      />
    )
  }

  if (selectedPricingModel === PricingModelType.FlatFee || selectedPricingModel === PricingModelType.PerUnit) {
    return (
      priceModel &&
      selectedCurrency && (
        <div className={className}>
          <FlatFeePriceModel
            nrUnits={!!(priceModel as AllowancePriceModel).getIncludedUnits ? Number((priceModel as AllowancePriceModel).getIncludedUnits() || 0) : 0}
            price={
              allowZeroAndPositiveNumbers(priceModel.getPerUnitPrice()?.toString()
              || (priceModel as AddonPriceModel).getFlatFeePrice && (priceModel as AddonPriceModel).getFlatFeePrice()?.getValue().toString())
            }
            currency={selectedCurrency.getSymbol()}
            frequency={getCycleFrequencyText(priceModel.getFrequency(), getFrequencyTypeEnum(priceModel.getFrequencyType()), PricingModelType.FlatFee)}
            configure={setPriceFormatted}
            isPerUnit={selectedPricingModel === PricingModelType.PerUnit}
            cancel={() => setPriceModel(null)}
            type={type}
          />
        </div>
      )
    );
  }

  if (selectedPricingModel === PricingModelType.Volume || selectedPricingModel === PricingModelType.Ranged || selectedPricingModel === PricingModelType.Tiered) {
    return (
      priceModel &&
      selectedCurrency && (
        <div className={className}>
          <TierPriceModel
            nrUnits={!!(priceModel as AllowancePriceModel).getIncludedUnits ? Number((priceModel as AllowancePriceModel).getIncludedUnits() || 0) : 0}
            unitTiers={priceModel.getTiersList().map(t => toAddOnTierUnit(t))}
            currency={selectedCurrency.getSymbol()}
            frequency={getCycleFrequencyText(priceModel.getFrequency(), getFrequencyTypeEnum(priceModel.getFrequencyType()), selectedPricingModel)}
            pricingModelType={selectedPricingModel}
            configure={setUnitTiers}
            cancel={() => setPriceModel(null)}
            type={type}
          />
        </div>
      )
    );
  }

  return <div />;
});

export default AddOnConfigurePrice;
