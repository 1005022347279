import React, { useState, useEffect } from "react";
import BackNavigationPanel from "../../../../../ui/back-navigation-panel/BackNavigationPanel";
import MenuTable from "../../../../../ui/menu-table/MenuTable";
import { EmailTypes, UpdateEmailNotificationStatusRequest, UpdateEmailNotificationStatusResponse } from "../../../../../../utils/grpc/generated/Billsby.Protos/Messenger/private/companyconfig/companyconfig_pb";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../../../../../..";
import { updateEmailNotificationToggles, fetchEmailNotificationToggles } from "../../../../../../actions/emailRoutingActions";
import { useHistory } from "react-router";

const RenewalRemindersMenu: React.FC = () => {
  const emailNotificationToggles = useSelector((state: AppState) => state.emailRoutingReducer.emailNotificationToggles);
  const currentCompanyId = useSelector((state: AppState) => state.auth.currentCompanyId);

  const dispatch = useDispatch<Function>();
  const history = useHistory();

  const [switchState, setSwitchState] = useState({
    _72HoursBeforeRenewal: false
  });

  const updateData = async (updateEmailNotification: UpdateEmailNotificationStatusRequest) => {
    try {
      await (dispatch(updateEmailNotificationToggles(updateEmailNotification)) as { response: UpdateEmailNotificationStatusResponse });
      dispatch(fetchEmailNotificationToggles(Number(currentCompanyId)));
    }
    catch (err) { }
  }

  useEffect(() => {
    if (emailNotificationToggles.getEmailNotificationConfigsList().length) {
      let newState: any = {};
      emailNotificationToggles.getEmailNotificationConfigsList().forEach((elem) => {
        switch (elem.getEmailType()) {
          case EmailTypes.REMINDER_72_HOURS_BEFORE_RENEWAL:
            newState._72HoursBeforeRenewal = elem.getIsEnabled();
            break;
        }
      })
      setSwitchState(newState);
    }
  }, [emailNotificationToggles]);

  useEffect(() => {
    dispatch(fetchEmailNotificationToggles(Number(currentCompanyId)));
  }, []);

  return (
    <div>
      <BackNavigationPanel
        title={"EMAILS_MENU_SUBSCRIPTION_LIFECYCLE_RENEWAL_REMINDERS"} shouldTranslateTitle={true}
        onClick={() => history.push("/configuration/email-notifications/emails")}>
        <MenuTable
          data={[
            {
              title: "EMAILS_MENU_SUBSCRIPTION_LIFECYCLE_RENEWAL_REMINDERS_SUBSCRIPTION_WILL_AUTO_RENEW_IN_THREE_DAYS",
              onClick: () => history.push("/configuration/email-notifications/emails/renewal-reminders/subscription-will-auto-renew-in-three-days-template"),
              canConfigureNotification: true,
              enableNotification: switchState._72HoursBeforeRenewal,
              enableNotificationChange: (isChecked) => {
                setSwitchState({ ...switchState, _72HoursBeforeRenewal: isChecked });
                const updateEmailNotification = new UpdateEmailNotificationStatusRequest();
                updateEmailNotification.setCompanyId(Number(currentCompanyId));
                updateEmailNotification.setEmailType(EmailTypes.REMINDER_72_HOURS_BEFORE_RENEWAL);
                updateEmailNotification.setIsEnabled(isChecked);
                updateData(updateEmailNotification);
              }
            }
          ]}
        />
      </BackNavigationPanel>
    </div>
  )
}

export default RenewalRemindersMenu;