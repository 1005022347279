import React from "react";
import "./ChartTooltip.scss";

interface IChartTooltip {
  isActive?: boolean
}

const ChartTooltip: React.FC<IChartTooltip> = React.memo(({ isActive = false, children }) => {
  if (!isActive) {
    return null;
  }

  return (
    <div className="chart-tooltip">
      <div className="chart-tooltip__arrow"/>
      {children}
    </div>
  )
})

export default ChartTooltip;