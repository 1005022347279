import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../../../../..";
import { setCompanyBrandingFields } from "../../../../../actions/companyBrandingActions";
import Panel from "../../../../ui/panel/Panel";
import RoundingBlock from "../../../../ui/rounding-block/RoundingBlock";
import "./RoundingCustomization.scss"

const RoundingCustomization: React.FC = () => {
  const companyBrandingReducer = useSelector((state: AppState) => state.companyBrandingReducer);

  const dispatch = useDispatch<Function>();

  const { rounding } = companyBrandingReducer
  const roundingOption = [0, 2, 4, 6, 8, 10];

  return (
    <Panel title="BRAND_CUSTOMISATION_ROUNDING" className="rounding-customization">
      <div>
        {
          roundingOption.map(r => <RoundingBlock key={r} rounding={r} onClick={() => dispatch(setCompanyBrandingFields("rounding", r))} isSelected={rounding === r}></RoundingBlock>)
        }
      </div>
    </Panel>
  )
}

export default RoundingCustomization;