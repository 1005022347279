import React, { FormEvent } from "react";
import Panel from "../../../components/ui/panel/Panel";
import counterpart from "counterpart";
import Text from "../../../components/ui/text/Text";
import CircleIcon from "../../../components/ui/circle-icon/CirlcleIcon";
import FormGroup from "../../../components/ui/form-group/FormGroup";
import Button from "../../../components/ui/button/Button";
import { useDispatch } from "react-redux";
import { VALIDATE_EMAIL_SUCCESS } from "../../../actions/registrationActions";
import TitleSection from "../../../components/ui/title-section/TitleSection";

interface IRegistrationSameDomainErrorProps {
  firstName: string,
  workEmail: string
}

const RegistrationSameDomainError: React.FC<IRegistrationSameDomainErrorProps> = ({ firstName = "", workEmail = "" }) => {
  const dispatch = useDispatch<Function>()

  const onSubmit = (evt: FormEvent) => {
    evt.preventDefault();
    // we use an existing action that will trigger the complete registration screen (as in the email is valid)
    dispatch({ type: VALIDATE_EMAIL_SUCCESS, response: "" });
  }

  return (
    <form className="registration" onSubmit={onSubmit.bind(null)}>

      <TitleSection
        title={counterpart("REGISTRATION_STEP1C_ERROR_TITLE", { firstName })}
        shouldTranslateTitle={false}
        subTitle={counterpart("REGISTRATION_STEP1C_ERROR_BODY_1", { emailDomain: workEmail.substring(workEmail.indexOf("@"), workEmail.length) })}
        shouldTranslateSubtitle={false}
      />

      <Panel>
        <Text content="REGISTRATION_STEP1C_ERROR_BODY_2" />
        <FormGroup>
          <CircleIcon nr={1} />
          <Text content="REGISTRATION_STEP1C_POINT_1" noMargin component="span" />
        </FormGroup>

        <FormGroup>
          <CircleIcon nr={2} />
          <Text content="REGISTRATION_STEP1C_POINT_2" noMargin component="span" />
        </FormGroup>

        <div>
          <CircleIcon nr={3} />
          <Text content="REGISTRATION_STEP1C_POINT_3" noMargin component="span" />
        </div>
      </Panel>

      <Button
        id="same_domain_btn"
        title="REGISTRATION_STEP1C_LOGIN_BTN"
        type="submit"
        buttonType="orangeBillsbyMarketing"
        isFullWidth
      />
    </form>
  )
}

export default RegistrationSameDomainError