/**
 * @fileoverview gRPC-Web generated client stub for core.private.companyretentionstep
 * @enhanceable
 * @public
 */

// GENERATED CODE -- DO NOT EDIT!


import * as grpcWeb from "grpc-web";

import {
  GetDiscountOfferRequest,
  GetDiscountOfferResponse,
  GetHelpInterruptRequest,
  GetHelpInterruptResponse,
  GetPlanChangeRequest,
  GetPlanChangeResponse,
  GetReasonsToStayRequest,
  GetReasonsToStayResponse,
  GetRetentionStepsRequest,
  GetRetentionStepsResponse,
  GetTimeRemainingRequest,
  GetTimeRemainingResponse,
  SetDiscountOfferRequest,
  SetDiscountOfferResponse,
  UpdateRetentionStepsRequest,
  UpdateRetentionStepsResponse,
  UpsertHelpInterruptRequest,
  UpsertHelpInterruptResponse,
  UpsertPlanChangeRequest,
  UpsertPlanChangeResponse,
  UpsertReasonsToStayRequest,
  UpsertReasonsToStayResponse,
  UpsertTimeRemainingRequest,
  UpsertTimeRemainingResponse} from "./retention_step_pb";

export class CompanyRetentionStepServiceClient {
  client_: grpcWeb.AbstractClientBase;
  hostname_: string;
  credentials_: null | { [index: string]: string; };
  options_: null | { [index: string]: string; };

  constructor (hostname: string,
               credentials?: null | { [index: string]: string; },
               options?: null | { [index: string]: string; }) {
    if (!options) options = {};
    if (!credentials) credentials = {};
    options["format"] = "text";

    this.client_ = new grpcWeb.GrpcWebClientBase(options);
    this.hostname_ = hostname;
    this.credentials_ = credentials;
    this.options_ = options;
  }

  methodInfoUpsertHelpInterrupt = new grpcWeb.AbstractClientBase.MethodInfo(
    UpsertHelpInterruptResponse,
    (request: UpsertHelpInterruptRequest) => {
      return request.serializeBinary();
    },
    UpsertHelpInterruptResponse.deserializeBinary
  );

  upsertHelpInterrupt(
    request: UpsertHelpInterruptRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: UpsertHelpInterruptResponse) => void) {
    return this.client_.rpcCall(
      this.hostname_ +
        "/core.private.companyretentionstep.CompanyRetentionStepService/UpsertHelpInterrupt",
      request,
      metadata || {},
      this.methodInfoUpsertHelpInterrupt,
      callback);
  }

  methodInfoGetHelpInterrupt = new grpcWeb.AbstractClientBase.MethodInfo(
    GetHelpInterruptResponse,
    (request: GetHelpInterruptRequest) => {
      return request.serializeBinary();
    },
    GetHelpInterruptResponse.deserializeBinary
  );

  getHelpInterrupt(
    request: GetHelpInterruptRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: GetHelpInterruptResponse) => void) {
    return this.client_.rpcCall(
      this.hostname_ +
        "/core.private.companyretentionstep.CompanyRetentionStepService/GetHelpInterrupt",
      request,
      metadata || {},
      this.methodInfoGetHelpInterrupt,
      callback);
  }

  methodInfoUpsertPlanChange = new grpcWeb.AbstractClientBase.MethodInfo(
    UpsertPlanChangeResponse,
    (request: UpsertPlanChangeRequest) => {
      return request.serializeBinary();
    },
    UpsertPlanChangeResponse.deserializeBinary
  );

  upsertPlanChange(
    request: UpsertPlanChangeRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: UpsertPlanChangeResponse) => void) {
    return this.client_.rpcCall(
      this.hostname_ +
        "/core.private.companyretentionstep.CompanyRetentionStepService/UpsertPlanChange",
      request,
      metadata || {},
      this.methodInfoUpsertPlanChange,
      callback);
  }

  methodInfoGetPlanChange = new grpcWeb.AbstractClientBase.MethodInfo(
    GetPlanChangeResponse,
    (request: GetPlanChangeRequest) => {
      return request.serializeBinary();
    },
    GetPlanChangeResponse.deserializeBinary
  );

  getPlanChange(
    request: GetPlanChangeRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: GetPlanChangeResponse) => void) {
    return this.client_.rpcCall(
      this.hostname_ +
        "/core.private.companyretentionstep.CompanyRetentionStepService/GetPlanChange",
      request,
      metadata || {},
      this.methodInfoGetPlanChange,
      callback);
  }

  methodInfoUpsertReasonsToStay = new grpcWeb.AbstractClientBase.MethodInfo(
    UpsertReasonsToStayResponse,
    (request: UpsertReasonsToStayRequest) => {
      return request.serializeBinary();
    },
    UpsertReasonsToStayResponse.deserializeBinary
  );

  upsertReasonsToStay(
    request: UpsertReasonsToStayRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: UpsertReasonsToStayResponse) => void) {
    return this.client_.rpcCall(
      this.hostname_ +
        "/core.private.companyretentionstep.CompanyRetentionStepService/UpsertReasonsToStay",
      request,
      metadata || {},
      this.methodInfoUpsertReasonsToStay,
      callback);
  }

  methodInfoGetReasonsToStay = new grpcWeb.AbstractClientBase.MethodInfo(
    GetReasonsToStayResponse,
    (request: GetReasonsToStayRequest) => {
      return request.serializeBinary();
    },
    GetReasonsToStayResponse.deserializeBinary
  );

  getReasonsToStay(
    request: GetReasonsToStayRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: GetReasonsToStayResponse) => void) {
    return this.client_.rpcCall(
      this.hostname_ +
        "/core.private.companyretentionstep.CompanyRetentionStepService/GetReasonsToStay",
      request,
      metadata || {},
      this.methodInfoGetReasonsToStay,
      callback);
  }

  methodInfoUpsertTimeRemaining = new grpcWeb.AbstractClientBase.MethodInfo(
    UpsertTimeRemainingResponse,
    (request: UpsertTimeRemainingRequest) => {
      return request.serializeBinary();
    },
    UpsertTimeRemainingResponse.deserializeBinary
  );

  upsertTimeRemaining(
    request: UpsertTimeRemainingRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: UpsertTimeRemainingResponse) => void) {
    return this.client_.rpcCall(
      this.hostname_ +
        "/core.private.companyretentionstep.CompanyRetentionStepService/UpsertTimeRemaining",
      request,
      metadata || {},
      this.methodInfoUpsertTimeRemaining,
      callback);
  }

  methodInfoGetTimeRemaining = new grpcWeb.AbstractClientBase.MethodInfo(
    GetTimeRemainingResponse,
    (request: GetTimeRemainingRequest) => {
      return request.serializeBinary();
    },
    GetTimeRemainingResponse.deserializeBinary
  );

  getTimeRemaining(
    request: GetTimeRemainingRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: GetTimeRemainingResponse) => void) {
    return this.client_.rpcCall(
      this.hostname_ +
        "/core.private.companyretentionstep.CompanyRetentionStepService/GetTimeRemaining",
      request,
      metadata || {},
      this.methodInfoGetTimeRemaining,
      callback);
  }

  methodInfoGetRetentionSteps = new grpcWeb.AbstractClientBase.MethodInfo(
    GetRetentionStepsResponse,
    (request: GetRetentionStepsRequest) => {
      return request.serializeBinary();
    },
    GetRetentionStepsResponse.deserializeBinary
  );

  getRetentionSteps(
    request: GetRetentionStepsRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: GetRetentionStepsResponse) => void) {
    return this.client_.rpcCall(
      this.hostname_ +
        "/core.private.companyretentionstep.CompanyRetentionStepService/GetRetentionSteps",
      request,
      metadata || {},
      this.methodInfoGetRetentionSteps,
      callback);
  }

  methodInfoUpdateRetentionSteps = new grpcWeb.AbstractClientBase.MethodInfo(
    UpdateRetentionStepsResponse,
    (request: UpdateRetentionStepsRequest) => {
      return request.serializeBinary();
    },
    UpdateRetentionStepsResponse.deserializeBinary
  );

  updateRetentionSteps(
    request: UpdateRetentionStepsRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: UpdateRetentionStepsResponse) => void) {
    return this.client_.rpcCall(
      this.hostname_ +
        "/core.private.companyretentionstep.CompanyRetentionStepService/UpdateRetentionSteps",
      request,
      metadata || {},
      this.methodInfoUpdateRetentionSteps,
      callback);
  }

  methodInfoGetDiscountOffer = new grpcWeb.AbstractClientBase.MethodInfo(
    GetDiscountOfferResponse,
    (request: GetDiscountOfferRequest) => {
      return request.serializeBinary();
    },
    GetDiscountOfferResponse.deserializeBinary
  );

  getDiscountOffer(
    request: GetDiscountOfferRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: GetDiscountOfferResponse) => void) {
    return this.client_.rpcCall(
      this.hostname_ +
        "/core.private.companyretentionstep.CompanyRetentionStepService/GetDiscountOffer",
      request,
      metadata || {},
      this.methodInfoGetDiscountOffer,
      callback);
  }

  methodInfoSetDiscountOffer = new grpcWeb.AbstractClientBase.MethodInfo(
    SetDiscountOfferResponse,
    (request: SetDiscountOfferRequest) => {
      return request.serializeBinary();
    },
    SetDiscountOfferResponse.deserializeBinary
  );

  setDiscountOffer(
    request: SetDiscountOfferRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: SetDiscountOfferResponse) => void) {
    return this.client_.rpcCall(
      this.hostname_ +
        "/core.private.companyretentionstep.CompanyRetentionStepService/SetDiscountOffer",
      request,
      metadata || {},
      this.methodInfoSetDiscountOffer,
      callback);
  }

}

