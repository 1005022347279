import React, { useEffect } from "react";
import Nav from "../header/Nav";
import { IAuthReducerState } from "../../reducers/authReducer";
import "./GettingStarted.scss";
import GettingStartedPanel from "./GettingStartedPanel";
import { fetchGettingStartedSettings, updateGettingStartedSettings } from "../../actions/authActions";
import { IGettingStartedSettings, GettingStartedType } from "../../models/GettingStarted";
import { INavItemVisibility } from "../../models/Nav";
import Button from "../ui/button/Button";
import history from "../../utils/history";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../..";
import { fetchAccountIntegrations } from "../../actions/accountingIntegrationsActions";


interface IGettingStarted {
  minimiseNav: Function,
  isMinimised: boolean
}

const NAV_VISIBILITY_DEFAULT: INavItemVisibility = {
  dashboard: false,
  newDashboard: false,
  customers: false,
  subscriptions: false,
  invoices: false,
  products: false,
  logs: false,
  reports: false,
  zapier: false,
  settings: false,
  help: false,
  company: false,
  user: false,
  integrations: false
};

const enableNavItems = (enabled: Partial<INavItemVisibility>) => {
  return { ...NAV_VISIBILITY_DEFAULT, ...enabled };
}

const getMenuVisibility = (dashboardSettings: IGettingStartedSettings) => {
  const { status } = dashboardSettings;

  switch (status) {
    case GettingStartedType.GettingStarted1: {
      if(!history.location.pathname.match(/\/products-plans-cycles/)) {
        history.push("/products/products-plans-cycles");
      }
      return enableNavItems({ products: [1], user: [4] });
    }
    case GettingStartedType.GettingStarted2: {
      if(!history.location.pathname.match(/\/configuration\/brand-customization/)) {
        history.push("/configuration/brand-customization");
      }
      return enableNavItems({ settings: [1], user: [4] });
    }
    default:
      return enableNavItems({
        dashboard: true,
        newDashboard: true,
        customers: true,
        subscriptions: true,
        invoices: true,
        products: true,
        logs: true,
        reports: true,
        settings: true,
        zapier: true,
        help: true,
        company: true,
        user: true,
        integrations: true
      });
  }
}

const isGuidedMenu = (dashboardSettings: IGettingStartedSettings) => {
  return dashboardSettings.status !== GettingStartedType.PreLive && dashboardSettings.status !== GettingStartedType.Live 
    && dashboardSettings.status !== GettingStartedType.OnTrial;
}

const GettingStarted: React.FC<IGettingStarted> = ({ minimiseNav, isMinimised }) => {
  const authData = useSelector<AppState, IAuthReducerState>(state => state.auth);
  const { dashboardSettings, currentCompanyDomain, currentCompanyId } = authData
  const dispatch = useDispatch<Function>()

  useEffect(() => {
    !!currentCompanyDomain && dispatch(fetchGettingStartedSettings(currentCompanyDomain));
    !!currentCompanyId && dispatch(fetchAccountIntegrations(currentCompanyId));
  }, [currentCompanyDomain, currentCompanyId])

  if (!dashboardSettings) {
    return null;
  }

  if (isMinimised || !isGuidedMenu(dashboardSettings)) {
    return (
      <Nav
        navItemsVisibility={getMenuVisibility(dashboardSettings)}
        isMinimised={isMinimised}
        minimiseNav={minimiseNav}
      />
    )
  }

  return (
    <div className="getting-started">
      <GettingStartedPanel dashboardSettings={dashboardSettings} />

      <Nav
        navItemsVisibility={getMenuVisibility(dashboardSettings)}
        isMinimised={isMinimised}
        minimiseNav={minimiseNav}
      />

      <Button
        id="skip-getting-started-btn"
        title="GETTING_STARTED_BRANDING_SKIP_BTN"
        onClick={() => {
          dispatch(updateGettingStartedSettings(currentCompanyDomain, { ...dashboardSettings, status: GettingStartedType.PreLive }));
          setTimeout(() => history.push("/home"), 1000);
        }}
        isVisible={!isMinimised && isGuidedMenu(dashboardSettings)}
      />
    </div>
  )

}

export default GettingStarted;