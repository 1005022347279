import React, { useState } from "react";
import Panel from "../../ui/panel/Panel";
import Button from "../../ui/button/Button";
import { IPlanWithCycles } from "../../../models/Product";
import Text from "../../ui/text/Text";
import counterpart from "counterpart";
import { PlansServiceClient } from "../../../utils/grpc/generated/Billsby.Protos/core/private/plans/PlansServiceClientPb";
import { DeletePlanRequest } from "../../../utils/grpc/generated/Billsby.Protos/core/private/plans/plans_pb";
import { useSelector } from "react-redux";
import { AppState } from "../../..";
import { IAuthReducerState } from "../../../reducers/authReducer";
import { grpcUnaryCall } from "../../../utils/grpc/grpcUtils";
import { ConfigConstants } from "../../../utils/config";


interface IDeletePlan {
  plan: IPlanWithCycles,
  onClose: () => void
}

const DeletePlan: React.FC<IDeletePlan> = ({ plan, onClose }) => {
  const { currentCompanyDomain } = useSelector<AppState, IAuthReducerState>(state => state.auth)
  const [isLoading, setIsLoading] = useState(false);

  const onSubmit = async () => {
    setIsLoading(true);
    try {
      const deletePlanRequest = new DeletePlanRequest();
      deletePlanRequest.setCompanyDomain(currentCompanyDomain);
      deletePlanRequest.setPlanId(plan.planId);
      const productServiceClient = new PlansServiceClient(ConfigConstants.grpcBaseUrl);
      await grpcUnaryCall(deletePlanRequest, productServiceClient, productServiceClient.deletePlan);
      onClose();
    }
    catch(err) {}
    finally {
      setIsLoading(false);
    }
  }

  return (
    <>
      <Panel title="PLAN_DELETE_MODAL_TITLE" translateWith={{ planName: plan.name }}>
        <Text 
          content="PLAN_DELETE_MODAL_HEADING"
          translateWith={{ retainPlan: <b>{counterpart("PLAN_DELETE_MODAL_RETAIN_PLAN")}</b> }}
          noMargin 
        />
      </Panel>
      <Button
        id="delete-plan"
        title={counterpart("PLAN_DELETE_MODAL_TITLE", { planName: plan.name }).toUpperCase()}
        isLoading={isLoading}
        disabled={isLoading}
        shouldTranslate={false}
        isFullWidth
        onClick={onSubmit}
      />
    </>
  )
}

export default DeletePlan