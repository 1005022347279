import * as React from "react";
import "./BasicPreview.scss";
import Text from "../../ui/text/Text";
import CustomFieldPreview from "../../ui/custom-field-preview/CustomFieldPreview";
import CheckoutTextArea from "./ui-checkout/CheckoutTextArea";


interface IMultiLinePreviewProps {
  label: string,
  supportText: string
}

const MultiLinePreview: React.FC<IMultiLinePreviewProps> = ({ label = "", supportText = "" }) => {
  return (
    <CustomFieldPreview className="preview">
      <Text content={label} shouldTranslate={false} />
      <CheckoutTextArea id="preview_multiline_id" />
      <Text content={supportText} shouldTranslate={false} noMargin />
    </CustomFieldPreview>
  );
};

export default MultiLinePreview;