import React from "react";
import counterpart from "counterpart";
import { Col, Row } from "react-grid-system";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../..";
import { setAcceptAgreement, setNotificationAlert } from "../../actions/registrationActions";
import Checkbox from "../../components/ui/checkbox/Checkbox";
import FormGroup from "../../components/ui/form-group/FormGroup";
import Text from "../../components/ui/text/Text";

interface IRegistrationCheckboxes {
  showAgreementError: boolean;
  acceptAgreementOnClick?: () => void
}

const RegistrationCheckboxes: React.FC<IRegistrationCheckboxes> = ({ showAgreementError, acceptAgreementOnClick }) => {
  const dispatch = useDispatch<Function>();
  const { acceptAgreement, notificationAlert } = useSelector((state: AppState) => state.registration);

  return (
    <>
      <Row>
        <Col md={2} />
        <Col md={10}>
          <FormGroup>
            <Checkbox
              id="accept-agreement"
              checked={acceptAgreement}
              onClick={() => {
                dispatch(setAcceptAgreement(!acceptAgreement));
                acceptAgreementOnClick?.();
              }}
              value="somevalue"
              content={
                <div className="registration__agreement">
                  <Text content="REGISTRATION_FORM_AGREEMENT" noMargin translateWith={{
                    link1: <a href="https://www.billsby.com/terms" target="_self">{counterpart("REGISTRATION_FORM_TOS")}</a>,
                    link2: <a href="https://www.billsby.com/privacy" target="_self">{counterpart("REGISTRATION_FORM_PRIVACY_NOTICE")}</a>,
                    link3: <a href="https://support.billsby.com/docs/data-processing-addendum" target="_self">{counterpart("REGISTRATION_FORM_DPA")}</a>
                  }} component="div" />
                </div>
              }
            />
            {showAgreementError && <Text className="registration__checkbox-error" content="REGISTRATION_FORM_AGREEMENT_ERROR" noMargin></Text>}
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col md={2} />
        <Col md={10}>
          <FormGroup>
            <Checkbox
              id="notification-alert"
              checked={notificationAlert}
              onClick={() => dispatch(setNotificationAlert(!notificationAlert))}
              value="somevalue"
              content={
                <div className="registration__notification">
                  <Text content="REGISTRATION_FORM_NOTIFICATION_1" component="div" />
                  <Text content="REGISTRATION_FORM_NOTIFICATION_2" component="div" noMargin />
                </div>
              }
            />
          </FormGroup>
        </Col>
      </Row>
    </>
  )
}

export default RegistrationCheckboxes;