import { ICountryState } from "../../models/CountryState";

export const countryStates: Array<ICountryState> = [
  { CountryIso3: "USA", CountryIso2: "US", StateName: "Alabama", StateIso2: "AL" },
  { CountryIso3: "USA", CountryIso2: "US", StateName: "Alaska", StateIso2: "AK" },
  { CountryIso3: "USA", CountryIso2: "US", StateName: "Arizona", StateIso2: "AZ" },
  { CountryIso3: "USA", CountryIso2: "US", StateName: "Arkansas", StateIso2: "AR" },
  { CountryIso3: "USA", CountryIso2: "US", StateName: "California", StateIso2: "CA" },
  { CountryIso3: "USA", CountryIso2: "US", StateName: "Colorado", StateIso2: "CO" },
  { CountryIso3: "USA", CountryIso2: "US", StateName: "Connecticut", StateIso2: "CT" },
  { CountryIso3: "USA", CountryIso2: "US", StateName: "Delaware", StateIso2: "DE" },
  { CountryIso3: "USA", CountryIso2: "US", StateName: "District of Columbia", StateIso2: "DC" },
  { CountryIso3: "USA", CountryIso2: "US", StateName: "Florida", StateIso2: "FL" },
  { CountryIso3: "USA", CountryIso2: "US", StateName: "Georgia", StateIso2: "GA" },
  { CountryIso3: "USA", CountryIso2: "US", StateName: "Hawaii", StateIso2: "HI" },
  { CountryIso3: "USA", CountryIso2: "US", StateName: "Idaho", StateIso2: "ID" },
  { CountryIso3: "USA", CountryIso2: "US", StateName: "Illinois", StateIso2: "IL" },
  { CountryIso3: "USA", CountryIso2: "US", StateName: "Indiana", StateIso2: "IN" },
  { CountryIso3: "USA", CountryIso2: "US", StateName: "Iowa", StateIso2: "IA" },
  { CountryIso3: "USA", CountryIso2: "US", StateName: "Kansas", StateIso2: "KS" },
  { CountryIso3: "USA", CountryIso2: "US", StateName: "Kentucky", StateIso2: "KY" },
  { CountryIso3: "USA", CountryIso2: "US", StateName: "Louisiana", StateIso2: "LA" },
  { CountryIso3: "USA", CountryIso2: "US", StateName: "Maine", StateIso2: "ME" },
  { CountryIso3: "USA", CountryIso2: "US", StateName: "Maryland", StateIso2: "MD" },
  { CountryIso3: "USA", CountryIso2: "US", StateName: "Massachusetts", StateIso2: "MA" },
  { CountryIso3: "USA", CountryIso2: "US", StateName: "Michigan", StateIso2: "MI" },
  { CountryIso3: "USA", CountryIso2: "US", StateName: "Minnesota", StateIso2: "MN" },
  { CountryIso3: "USA", CountryIso2: "US", StateName: "Mississippi", StateIso2: "MS" },
  { CountryIso3: "USA", CountryIso2: "US", StateName: "Missouri", StateIso2: "MO" },
  { CountryIso3: "USA", CountryIso2: "US", StateName: "Montana", StateIso2: "MT" },
  { CountryIso3: "USA", CountryIso2: "US", StateName: "Nebraska", StateIso2: "NE" },
  { CountryIso3: "USA", CountryIso2: "US", StateName: "Nevada", StateIso2: "NV" },
  { CountryIso3: "USA", CountryIso2: "US", StateName: "New Hampshire", StateIso2: "NH" },
  { CountryIso3: "USA", CountryIso2: "US", StateName: "New Jersey", StateIso2: "NJ" },
  { CountryIso3: "USA", CountryIso2: "US", StateName: "New Mexico", StateIso2: "NM" },
  { CountryIso3: "USA", CountryIso2: "US", StateName: "New York", StateIso2: "NY" },
  { CountryIso3: "USA", CountryIso2: "US", StateName: "North Carolina", StateIso2: "NC" },
  { CountryIso3: "USA", CountryIso2: "US", StateName: "North Dakota", StateIso2: "ND" },
  { CountryIso3: "USA", CountryIso2: "US", StateName: "Ohio", StateIso2: "OH" },
  { CountryIso3: "USA", CountryIso2: "US", StateName: "Oklahoma", StateIso2: "OK" },
  { CountryIso3: "USA", CountryIso2: "US", StateName: "Oregon", StateIso2: "OR" },
  { CountryIso3: "USA", CountryIso2: "US", StateName: "Puerto Rico", StateIso2: "PR" },
  { CountryIso3: "USA", CountryIso2: "US", StateName: "Pennsylvania", StateIso2: "PA" },
  { CountryIso3: "USA", CountryIso2: "US", StateName: "Rhode Island", StateIso2: "RI" },
  { CountryIso3: "USA", CountryIso2: "US", StateName: "South Carolina", StateIso2: "SC" },
  { CountryIso3: "USA", CountryIso2: "US", StateName: "South Dakota", StateIso2: "SD" },
  { CountryIso3: "USA", CountryIso2: "US", StateName: "Tennessee", StateIso2: "TN" },
  { CountryIso3: "USA", CountryIso2: "US", StateName: "Texas", StateIso2: "TX" },
  { CountryIso3: "USA", CountryIso2: "US", StateName: "Utah", StateIso2: "UT" },
  { CountryIso3: "USA", CountryIso2: "US", StateName: "Vermont", StateIso2: "VT" },
  { CountryIso3: "USA", CountryIso2: "US", StateName: "Virginia", StateIso2: "VA" },
  { CountryIso3: "USA", CountryIso2: "US", StateName: "Washington", StateIso2: "WA" },
  { CountryIso3: "USA", CountryIso2: "US", StateName: "West Virginia", StateIso2: "WV" },
  { CountryIso3: "USA", CountryIso2: "US", StateName: "Wisconsin", StateIso2: "WI" },
  { CountryIso3: "USA", CountryIso2: "US", StateName: "Wyoming", StateIso2: "WY" },

  { CountryIso3: "CAN", CountryIso2: "CA", StateName: "Alberta", StateIso2: "AB" },
  { CountryIso3: "CAN", CountryIso2: "CA", StateName: "British Columbia", StateIso2: "BC" },
  { CountryIso3: "CAN", CountryIso2: "CA", StateName: "Manitoba", StateIso2: "MB" },
  { CountryIso3: "CAN", CountryIso2: "CA", StateName: "New Brunswick", StateIso2: "NB" },
  { CountryIso3: "CAN", CountryIso2: "CA", StateName: "Newfoundland and Labrador", StateIso2: "NL" },
  { CountryIso3: "CAN", CountryIso2: "CA", StateName: "Nova Scotia", StateIso2: "NS" },
  { CountryIso3: "CAN", CountryIso2: "CA", StateName: "Ontario", StateIso2: "ON" },
  { CountryIso3: "CAN", CountryIso2: "CA", StateName: "Prince Edward Island", StateIso2: "PE" },
  { CountryIso3: "CAN", CountryIso2: "CA", StateName: "Quebec", StateIso2: "QC" },
  { CountryIso3: "CAN", CountryIso2: "CA", StateName: "Saskatchewan", StateIso2: "SK" },
  { CountryIso3: "CAN", CountryIso2: "CA", StateName: "Northwest Territories", StateIso2: "NT" },
  { CountryIso3: "CAN", CountryIso2: "CA", StateName: "Nunavut", StateIso2: "NU" },
  { CountryIso3: "CAN", CountryIso2: "CA", StateName: "Yukon", StateIso2: "YT" },

  { CountryIso3: "IND", CountryIso2: "IN", StateName: "Andaman and Nicobar Islands", StateIso2: "AN" },
  { CountryIso3: "IND", CountryIso2: "IN", StateName: "Andhra Pradesh", StateIso2: "AP" },
  { CountryIso3: "IND", CountryIso2: "IN", StateName: "Arunachal Pradesh", StateIso2: "AR" },
  { CountryIso3: "IND", CountryIso2: "IN", StateName: "Assam", StateIso2: "AS" },
  { CountryIso3: "IND", CountryIso2: "IN", StateName: "Bihar", StateIso2: "BR" },
  { CountryIso3: "IND", CountryIso2: "IN", StateName: "Chandigarh", StateIso2: "CH" },
  { CountryIso3: "IND", CountryIso2: "IN", StateName: "Chhattisgarh", StateIso2: "CT" },
  { CountryIso3: "IND", CountryIso2: "IN", StateName: "Daman and Diu", StateIso2: "DD" },
  { CountryIso3: "IND", CountryIso2: "IN", StateName: "Delhi", StateIso2: "DL" },
  { CountryIso3: "IND", CountryIso2: "IN", StateName: "Dadra and Nagar Haveli", StateIso2: "DN" },
  { CountryIso3: "IND", CountryIso2: "IN", StateName: "Goa", StateIso2: "GA" },
  { CountryIso3: "IND", CountryIso2: "IN", StateName: "Gujarat", StateIso2: "GJ" },
  { CountryIso3: "IND", CountryIso2: "IN", StateName: "Himachal Pradesh", StateIso2: "HP" },
  { CountryIso3: "IND", CountryIso2: "IN", StateName: "Haryana", StateIso2: "HR" },
  { CountryIso3: "IND", CountryIso2: "IN", StateName: "Jharkhand", StateIso2: "JH" },
  { CountryIso3: "IND", CountryIso2: "IN", StateName: "Jammu and Kashmir", StateIso2: "JK" },
  { CountryIso3: "IND", CountryIso2: "IN", StateName: "Karnataka", StateIso2: "KA" },
  { CountryIso3: "IND", CountryIso2: "IN", StateName: "Kerala", StateIso2: "KL" },
  { CountryIso3: "IND", CountryIso2: "IN", StateName: "Ladakh", StateIso2: "LA" },
  { CountryIso3: "IND", CountryIso2: "IN", StateName: "Lakshadweep", StateIso2: "LD" },
  { CountryIso3: "IND", CountryIso2: "IN", StateName: "Maharashtra", StateIso2: "MH" },
  { CountryIso3: "IND", CountryIso2: "IN", StateName: "Meghalaya", StateIso2: "ML" },
  { CountryIso3: "IND", CountryIso2: "IN", StateName: "Manipur", StateIso2: "MN" },
  { CountryIso3: "IND", CountryIso2: "IN", StateName: "Madhya Pradesh", StateIso2: "MP" },
  { CountryIso3: "IND", CountryIso2: "IN", StateName: "Mizoram", StateIso2: "MZ" },
  { CountryIso3: "IND", CountryIso2: "IN", StateName: "Nagaland", StateIso2: "NL" },
  { CountryIso3: "IND", CountryIso2: "IN", StateName: "Odisha", StateIso2: "OR" },
  { CountryIso3: "IND", CountryIso2: "IN", StateName: "Punjab", StateIso2: "PB" },
  { CountryIso3: "IND", CountryIso2: "IN", StateName: "Puducherry", StateIso2: "PY" },
  { CountryIso3: "IND", CountryIso2: "IN", StateName: "Rajasthan", StateIso2: "RJ" },
  { CountryIso3: "IND", CountryIso2: "IN", StateName: "Sikkim", StateIso2: "SK" },
  { CountryIso3: "IND", CountryIso2: "IN", StateName: "Telangana", StateIso2: "TG" },
  { CountryIso3: "IND", CountryIso2: "IN", StateName: "Tamil Nadu", StateIso2: "TN" },
  { CountryIso3: "IND", CountryIso2: "IN", StateName: "Tripura", StateIso2: "TR" },
  { CountryIso3: "IND", CountryIso2: "IN", StateName: "Uttar Pradesh", StateIso2: "UP" },
  { CountryIso3: "IND", CountryIso2: "IN", StateName: "Uttarakhand", StateIso2: "UT" },
  { CountryIso3: "IND", CountryIso2: "IN", StateName: "West Bengal", StateIso2: "WB" },
]

export const europeanCountriesCode = [
  "AUT", "BEL", "BGR", "HRV", "CYP", "CZE", "DNK", "EST", "FIN", "FRA",
  "DEU", "GRC", "HUN", "IRL", "ITA", "LVA", "LTU", "LUX", "MLT", "NLD",
  "POL", "PRT", "ROU", "SVK", "SVN", "ESP", "SWE", "GBR"]