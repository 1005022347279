import React, { Fragment, useState } from "react"
import Button from "../../ui/button/Button";
import history from "../../../utils/history";
import counterpart from "counterpart";
import Search from "../../search/Search";
import Pagination from "../../ui/pagination/Pagination";
import Table from "../../ui/table/Table";
import Text from "../../ui/text/Text";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../../..";
import Helmet from "react-helmet";
import NavigationAlertCurrencyMismatch from "../../ui/navigation-alert/NavigationAlertCurrencyMismatch";
import PageWrapper from "../../ui/layout/PageWrapper";
import { Discount, Coupon } from "../../../utils/grpc/generated/Billsby.Protos/core/private/discounts/discounts_pb";
import "./DiscountList.scss";
import Tooltip from "../../ui/tooltip/Tooltip";
import Panel from "../../ui/panel/Panel";
import { fetchDiscountsList, setDiscountsFields, expireCoupon, fetchCouponsList, deleteDiscount, fetchCouponCodes } from "../../../actions/discount-coupon/discountsActions";
import DiscountListRow from "./DiscountListRow";
import Modal from "../../modal/Modal";
import FormGroup from "../../ui/form-group/FormGroup";
import { Row, Col } from "react-grid-system";
import FormLabel from "../../ui/form-label/FormLabel";
import Input from "../../ui/input/Input";
import ErrorPanel from "../../ui/error-panel/ErrorPanel";
import { GetCouponCodesResponse } from "../../../utils/grpc/generated/Billsby.Protos/billing/private/coupons/coupons_pb";
import { downloadCsvFile } from "../../../utils/commonUtils";
import moment from "moment";
import { Timestamp } from "google-protobuf/google/protobuf/timestamp_pb";
import ErrorNotification from "../../ui/error-notification/ErrorNotification";
import ProgressIndicator from "../../ui/progress-indicator/ProgressIndicator";
import useCheckFeaturePermission from "../../../utils/hooks/useCheckFeaturePermission";
import { Permission } from "../../../models/Auth";

const DiscountList: React.FC = () => {
  const auth = useSelector((state: AppState) => state.auth);
  const discounts = useSelector((state: AppState) => state.discountsReducer);
  const dispatch = useDispatch<Function>();

  const { discountList, searchedDiscountKey, showExpireModal, couponToExpire, isCouponExpiring,
    discountIdOfcouponToExpire, showDiscountDeleteModal, discountToDelete, isDeleting, newCouponId, newCouponName, errorMessage, isFetchingDiscounts } = discounts;

  const [deleteDiscountId, setDeleteDiscountId] = useState("");
  const isDiscountAddPermitted = useCheckFeaturePermission(Permission.DiscountAdd);

  const downloadCsv = async (name: string, couponId: number) => {
    const fileName = `${name}-${moment(new Date()).format("DD MMM YYYY")} at ${moment(new Date()).format("HH:mm:ss Z")}`;
    try {
      const { response } = await dispatch(fetchCouponCodes(Number(auth.currentCompanyId), couponId)) as { response: GetCouponCodesResponse };
      const rows = response.getCouponCodesList().map(i => [name, i.getCode(),
        `${moment((i.getCreatedOn() as Timestamp).toDate()).format("DD MMM YYYY")} at ${moment((i.getCreatedOn() as Timestamp).toDate()).format("HH:mm:ss Z")}`,
        i.getUsageLeft() > 0 ? "Unused" : "Used"]);
      rows.unshift([counterpart("CSV_COLUMN_COUPON_NAME"),
      counterpart("CSV_COLUMN_COUPON_CODE"),
      counterpart("CSV_COLUMN_COUPON_TIME_STAMP"),
      counterpart("CSV_COLUMN_COUPON_USAGE_STATE")
      ]);
      downloadCsvFile(rows, fileName);
    } catch (err) {
      console.log(err);
    }
  }

  const renderHeaderBar = () => {
    const discountsFilters = [
      { label: counterpart("DISCOUNTS_FILTER_ALL"), value: "" },
      { label: counterpart("DISCOUNTS_FILTER_WITH_COUPONS"), value: "" },
      { label: counterpart("DISCOUNTS_FILTER_WITH_OUT_COUPONS"), value: "" },
      { label: counterpart("DISCOUNTS_FILTER_PERCENTAGE_TYPE"), value: "" },
      { label: counterpart("DISCOUNTS_FILTER_MONETARY_TYPE"), value: "" },
      { label: counterpart("DISCOUNTS_FILTER_PLAN_ONLY"), value: "" },
      { label: counterpart("DISCOUNTS_FILTER_ENTIRE_SUBSCRIPTION"), value: "" }
    ];

    return (
      <>
        <div className='discount-list__header'>
          <div className='discount-list__filter'>
            {/* <Dropdown
              id='discount-list__dropdown'
              options={discountsFilters}
              value={discountsFilters[0]}
              onChange={(selectedItem: any) => { }}
            ></Dropdown> */}
            <div></div>
            <Button
              disabled={!isDiscountAddPermitted}
              tooltipMessage={!isDiscountAddPermitted ? "NO_ACCESS_MSG" : undefined}
              title='DISCOUNTS_ADD_NEW_DISCOUNT'
              icon='fal fa-plus-circle'
              id='add-new-discount-btn'
              shouldTranslate={true}
              buttonType='add'
              onClick={() => {
                history.push("/discount-and-coupons/create");
              }}
            />
          </div>
          <span className='divider'></span>
          <div className='discount-list__search'>
            <Search
              placeholder='DISCOUNTS_SEARCH_PLACEHOLDER'
              value={searchedDiscountKey}
              onChange={evt => dispatch(setDiscountsFields("searchedDiscountKey", evt.target.value))}
              onSearch={() => { dispatch(fetchDiscountsList(auth.currentCompanyId as number, 1, searchedDiscountKey)) }}
            />
            <Pagination
              className="discount-list__pagination"
              pageCount={discountList.getPageCount()}
              isVisible={discountList.getPageCount() > 1}
              onPageChange={({ selected }) => dispatch(fetchDiscountsList(auth.currentCompanyId as number, selected + 1, searchedDiscountKey))}
            />
          </div>
        </div>
      </>
    );
  };


  const renderListTable = () => {

    if (isFetchingDiscounts) {
      return <ProgressIndicator color="blue" />;
    }

    return (
      <Table expandable className='discount-list__table'>
        <thead>
          <tr>
            <Text content={"DISCOUNTS_TABLE_NAME"} component='th' noMargin />
            <Text content={"DISCOUNTS_TABLE_DISCOUNT_ID"} component='th' noMargin />
            <Text content={"DISCOUNTS_TABLE_COUPONS"} component='th' noMargin />
            <Text content={"DISCOUNTS_TABLE_TYPE"} component='th' noMargin />
            <Text content={"DISCOUNTS_TABLE_DEDUCTION"} component='th' noMargin />
            <th></th>
          </tr>
        </thead>
        <tbody>
          {discountList.getDiscountsList().map((discount, idx) => <Fragment key={`add-ons-list-${idx}`}>
            <DiscountListRow data={discount} couponList={discounts.couponList} />
          </Fragment>)}
        </tbody>
      </Table>
    );
  };


  const renderBottomPanel = () => {
    return (
      <Panel className='discount-list__explain' title='DISCOUNTS_LIST_PANEL_TITLE'>
        <div className='discount-list__content'>
          <Text content='DISCOUNTS_LIST_PANEL_TEXT_1' component='p' />
          <Text content='DISCOUNTS_LIST_PANEL_TEXT_2' component='p' noMargin />
        </div>
      </Panel>
    );
  };

  return (
    <div className='discount-list'>
      <Helmet title={counterpart("NAVBAR_PRODUCTS_PLANS_CYCLES")} />
      <NavigationAlertCurrencyMismatch />
      {renderHeaderBar()}
      {newCouponId && <ErrorPanel className="discount_list discount_list__coupon-success" title="DISCOUNT_LIST_COUPON_SUCCESS_NOTIFICATION_TITLE"
        subtitle="DISCOUNT_LIST_COUPON_SUCCESS_NOTIFICATION_SUB_TITLE"
        buttonText="DISCOUNT_LIST_COUPON_SUCCESS_NOTIFICATION_BUTTON_TEXT"
        buttonIcon="fas fa-download"
        buttonCb={() => downloadCsv(newCouponName, newCouponId)}
      />}
      <PageWrapper>
        {renderListTable()}
        {renderBottomPanel()}

        <Tooltip id='discount-edit-tooltip' place='top' type='blue'>
          <Text content='DISCOUNTS_LIST_EDIT_TOOLTIP' noMargin />
        </Tooltip>
        <Tooltip id='discount-delete-tooltip' place='top' type='blue'>
          <Text content='DISCOUNTS_LIST_DELETE_TOOLTIP' noMargin />
        </Tooltip>
        <Tooltip id='discount-add-coupon-tooltip' place='top' type='blue'>
          <Text content='DISCOUNTS_LIST_ADD_COUPON_TOOLTIP' noMargin />
        </Tooltip>

        <Modal isOpen={showExpireModal} onRequestClose={() => {
          dispatch(setDiscountsFields("showExpireModal", false));
          dispatch(setDiscountsFields("couponToExpire", undefined));
          dispatch(setDiscountsFields("discountIdOfcouponToExpire", undefined));
        }} title="DISCOUNTS_LIST_EXPIRING_COUPON_MODAL_TITLE">
          <>
            <Panel title="DISCOUNTS_LIST_EXPIRING_COUPON_MODAL_PANEL_TITLE">
              <Text content='DISCOUNTS_LIST_EXPIRING_COUPON_MODAL_PANEL_TEXT_1' />
              <Text content='DISCOUNTS_LIST_EXPIRING_COUPON_MODAL_PANEL_TEXT_2' className="discount-list__inline-text" noMargin />
              <Text content={couponToExpire ? couponToExpire.getName() : ""} shouldTranslate={false} className="discount-list__inline-text discount-list__text-blue" noMargin />
              <Text content='DISCOUNTS_LIST_EXPIRING_COUPON_MODAL_PANEL_TEXT_3' className="discount-list__inline-text" noMargin />
            </Panel>
            <Button
              id="expire-coupon-btn"
              title="DISCOUNTS_LIST_EXPIRING_COUPON_BUTTON_TEXT"
              buttonType="general"
              isFullWidth={true}
              isLoading={isCouponExpiring}
              onClick={() =>
                (dispatch(expireCoupon(Number(auth.currentCompanyId), (couponToExpire as Coupon).getCouponId())))
                  .then((res: any) => {
                    dispatch(fetchCouponsList(auth.currentCompanyId as number, Number(discountIdOfcouponToExpire)));
                    dispatch(setDiscountsFields("showExpireModal", false));
                    dispatch(setDiscountsFields("couponToExpire", undefined));
                    dispatch(setDiscountsFields("discountIdOfcouponToExpire", undefined));
                  })
                  .catch((err: any) => console.log(err))}
            />
          </>
        </Modal>

        <Modal isOpen={showDiscountDeleteModal} onRequestClose={() => {
          dispatch(setDiscountsFields("showDiscountDeleteModal", false));
          dispatch(setDiscountsFields("discountToDelete", undefined));
        }} title="DISCOUNTS_LIST_DELETE_DISCOUNT_MODAL_TITLE">
          <>
            <Panel title="DISCOUNTS_LIST_DELETE_DISCOUNT_MODAL_PANEL_TITLE">
              <Text content='DISCOUNTS_LIST_DELETE_DISCOUNT_MODAL_PANEL_TEXT_1' noMargin />
            </Panel>

            <Panel>
              <Text content='DISCOUNTS_LIST_DELETE_DISCOUNT_MODAL_PANEL_TEXT_2' className="discount-list__inline-text" noMargin />
              <Text content={discountToDelete ? discountToDelete.getName() : ""} shouldTranslate={false} className="discount-list__inline-text discount-list__text-blue" noMargin />
              <Text content='DISCOUNTS_LIST_DELETE_DISCOUNT_MODAL_PANEL_TEXT_3' className="discount-list__inline-text" noMargin />
              <FormGroup className="discount-list__delete-form">
                <Row align="center">
                  <Col md={2}>
                    <FormLabel target="deleteDiscountId" content="DISCOUNTS_LIST_DELETE_DISCOUNT_MODAL_INPUT_LABEL" noMargin />
                  </Col>
                  <Col md={10}>
                    <Input
                      id="deleteDiscountId"
                      required
                      value={deleteDiscountId}
                      type="text"
                      placeholder={counterpart("DISCOUNTS_LIST_DELETE_DISCOUNT_MODAL_INPUT_PLACEHOLDER")}
                      onChange={(evt: any) => setDeleteDiscountId(evt.target.value)}
                    />
                  </Col>
                </Row>
              </FormGroup>
            </Panel>

            <Button
              id="delete-discount-btn"
              title="DISCOUNTS_LIST_DELETE_DISCOUNT_MODAL_BUTTON_TEXT"
              buttonType="general"
              isFullWidth={true}
              isLoading={isDeleting}
              disabled={discountToDelete ? discountToDelete.getDiscountUniqueId() !== deleteDiscountId : true}
              onClick={() => {
                (dispatch(deleteDiscount(Number(auth.currentCompanyId), (discountToDelete as Discount).getDiscountId())))
                  .then((res: any) => {
                    dispatch(fetchDiscountsList(auth.currentCompanyId as number, 1, searchedDiscountKey));
                    dispatch(setDiscountsFields("showDiscountDeleteModal", false));
                    dispatch(setDiscountsFields("discountToDelete", undefined));
                    setDeleteDiscountId("");
                  }).catch((err: any) => console.log(err))
              }}
            />
          </>
        </Modal>
      </PageWrapper>
      <ErrorNotification title={errorMessage} shouldTranslateTitle={false} showAlert={Boolean(errorMessage)} closingTime={8000}></ErrorNotification>
    </div>
  )
}

export default DiscountList;
