import React, { useEffect } from "react";
import history from "../../../../../utils/history";
import Panel from "../../../../ui/panel/Panel";
import Text from "../../../../ui/text/Text";
import { AppState } from "../../../../..";
import { useDispatch, useSelector } from "react-redux";
import { IAuthReducerState } from "../../../../../reducers/authReducer";
import { ICompanyBrandingReducerState } from "../../../../../reducers/companyBrandingReducer";
import { Row, Col } from "react-grid-system";
import EmailPreivewContainer from "../../brand-customization/email-preview/EmailPreivewContainer";
import BackNavigationPanel from "../../../../ui/back-navigation-panel/BackNavigationPanel";
import { IEmailCustomizationReducerState } from "../../../../../reducers/emailCustomizationReducer";
import { resetEmailTemplateValues, getEmailTemplateValues } from "../../../../../actions/emailCustomizationActions";
import { fetchCompanyBranding } from "../../../../../actions/companyBrandingActions";
import EmailFooter from "../email-footer/EmailFooter";
import EmailEditableArea from "../email-editable-area/EmailEditableArea";
import { EmailTypes } from "../../../../../utils/grpc/generated/Billsby.Protos/Messenger/private/emailconfig/emailconfig_pb";


const NextBillDateIsChangedTemplate: React.FC = () => {
  const dispatch = useDispatch();
  const nbdic_companyBrandingReducer = useSelector<AppState, ICompanyBrandingReducerState>(state => state.companyBrandingReducer);
  const nbdic_emailCustomizationReducer = useSelector<AppState, IEmailCustomizationReducerState>(state => state.emailCustomizationReducer);
  const authReducer = useSelector<AppState, IAuthReducerState>(state => state.auth);

  const { customArea1, customArea2, customArea3 } = nbdic_emailCustomizationReducer;
  const { currentCompanyId, companyName, currentCompanyDomain } = authReducer;

  useEffect(() => {
    dispatch(fetchCompanyBranding(currentCompanyDomain));
    dispatch(getEmailTemplateValues(currentCompanyId as number, companyName, EmailTypes.SUBSCRIPTION_BILL_DATE_CHANGED));
    return () => { dispatch(resetEmailTemplateValues()) }
  }, []);


  return (
    <div id="NextBillDateIsChangedTemplate-wrapper">
      <BackNavigationPanel
        title={"EMAIL_TEMPLATE_NEW_BILL_DATE_NEXT_BILL_DATE_IS_CHANGED_TITLE"} shouldTranslateTitle={true}
        onClick={() => history.push("/configuration/email-notifications/emails/new-bill-date")} />

      <Row>
        <Col sm={8}>
          <Panel className="refund-issued-email-template__panel" noMargin>
            <EmailPreivewContainer>

              <Text
                content={<span dangerouslySetInnerHTML={{ __html: customArea1 }}></span>}
                shouldTranslate={false}
                className="email-preview__details__text bar bar-blue"
                component="span"
                noMargin
              />

              <Text
                content="EMAIL_TEMPLATE_NEW_BILL_DATE_NEXT_BILL_DATE_IS_CHANGED_GREETINGS"
                className="email-preview__details__text"
                component="span"
                noMargin
              />


              <Text
                content={<span dangerouslySetInnerHTML={{ __html: customArea2 }}></span>}
                shouldTranslate={false}
                className="email-preview__details__text bar bar-orange"
                component="span"
                noMargin
              />

              <Text
                content="EMAIL_TEMPLATE_NEW_BILL_DATE_NEXT_BILL_DATE_IS_CHANGED_BODY1"
                className="email-preview__details__text email-preview__details__text-bold"
                component="span"
                noMargin
              />

              <Text
                content="EMAIL_TEMPLATE_NEW_BILL_DATE_NEXT_BILL_DATE_IS_CHANGED_BODY2"
                className="email-preview__details__text"
                component="span"
                noMargin
              />


              <Text
                content={<span dangerouslySetInnerHTML={{ __html: customArea3 }}></span>}
                shouldTranslate={false}
                className="email-preview__details__text bar bar-green"
                component="span"
                noMargin
              />

              <Text
                content="EMAIL_TEMPLATE_NEW_BILL_DATE_NEXT_BILL_DATE_IS_CHANGED_BODY3"
                className="email-preview__details__text"
                component="span"
                noMargin
              />

              <Text
                content={companyName}
                className="email-preview__details__text"
                component="span"
                shouldTranslate={false}
                noMargin
              />

              <EmailFooter />

            </EmailPreivewContainer>
          </Panel>
        </Col>
        <Col sm={4}>
          <EmailEditableArea
            id='NextBillDateIsChangedTemplate'
            emailCustomCopyOneValue={customArea1}
            emailCustomCopyTwoValue={customArea2}
            emailCustomCopyThreeValue={customArea3}
            showCompanyAlertToggle={true}
            showProductAlertToggle={true}
            showPlanAlertToggle={true}
          />
        </Col>
      </Row>
    </div>
  )
}

export default NextBillDateIsChangedTemplate