import React, { useEffect, useState } from "react";
import "./Taxes.scss";
import { NavLink, useRouteMatch } from "react-router-dom";
import Panel from "../../../ui/panel/Panel";
import Text from "../../../ui/text/Text";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../../../..";
import NavigationBar from "../../../ui/navigation-bar/NavigationBar";
import PageWrapper from "../../../ui/layout/PageWrapper";
import TabbedContainer from "../../../ui/tabbed-container/TabbedContainer";
import counterpart from "counterpart";
import { PrivateRoute } from "../../../../containers/AuthRoutes";
import TaxRates from "./tax-rates/TaxRates";
import { IAuthReducerState } from "../../../../reducers/authReducer";
import { fetchTaxConfig, resetCompanyTaxRates, fetchTaxableCountries, fetchOtherCountries } from "../../../../actions/companyTaxRatesActions";
import TaxRatesConfiguration from "./tax-rates/TaxRatesConfirguration";
import ProgressIndicator from "../../../ui/progress-indicator/ProgressIndicator";
import { useHistory } from "react-router";
import { useIsFromSpecificPage } from "../../../../utils/custom-hooks";
import { setShowBillingModal } from "../../../../actions/authActions";


const Taxes: React.FC = () => {
  const [isLoadingData, setIsLoadingData] = useState(false);
  const { currentCompanyDomain, currentCompanyId } = useSelector<AppState, IAuthReducerState>(state => state.auth);
  const dispatch = useDispatch<Function>()
  const history = useHistory();
  const match = useRouteMatch();
	const isFromChecklist = useIsFromSpecificPage("/launch-checklist");
  const isFromBillingModal = useIsFromSpecificPage("billing-modal");

  const fetchCompanyTaxRatesData = async () => {
    setIsLoadingData(true);
    if(!currentCompanyId) {
      return null;
    }

    try {
      await dispatch(fetchTaxConfig(currentCompanyDomain));
      await dispatch(fetchTaxableCountries());
      await dispatch(fetchOtherCountries());
      setIsLoadingData(false);

    } catch (err) {
      console.log(err);
      setIsLoadingData(false);

    }
  };

  useEffect(() => {
    fetchCompanyTaxRatesData();
    
    return () => dispatch(resetCompanyTaxRates())
  }, []);

  if(isLoadingData) {
    return <ProgressIndicator color="blue" coverage="full-content"></ProgressIndicator>
  }

  return (
    <div className="taxes">
      <NavigationBar pageTitle="" previousPageTitle={
        isFromChecklist || isFromBillingModal 
        ? "GO_LIVE_PRO_PLAN_FEATURE_NAVBAR" 
        : "CONFIGURATION_PAGE_TITLE"} previousPageCb={() => 
        isFromChecklist 
        ? history.push({ pathname: "/launch-checklist", state: { hasprofeature: true } }) 
        : isFromBillingModal ? dispatch(setShowBillingModal(true))
        : history.push("/configuration")} />

      <PageWrapper>
        <Panel title="CONFIG_TAXES_MENU_ITEM_TITLE" className="taxes__heading" noMargin>
          <Text content="CONFIG_TAXES_MENU_ITEM_CONTENT" noMargin />
        </Panel>

        <TabbedContainer type="submenu">
          <NavLink isActive={(match, location) => (!match || !location ? false : match?.isExact || location.pathname.indexOf("configure") !== -1)} activeClassName="active" to={`${match?.url}`}>
            {counterpart("CONFIG_TAXES_RATES")}
          </NavLink>
          {/* <NavLink exact activeClassName="active" to={`${match?.url}/tax-display`}>
            {counterpart('CONFIG_TAXES_DISPLAY')}
          </NavLink> */}
        </TabbedContainer>

        <PrivateRoute exact path={match?.path} component={TaxRates} />
        <PrivateRoute exact path={`${match?.path}/configure/:countryCode`} component={TaxRatesConfiguration} />
        <PrivateRoute exact path={`${match?.path}/tax-display`} render={() => null} />
      </PageWrapper>
    </div>
  );
};

export default Taxes