import React, { useRef, useEffect, useMemo } from "react";
import { findDOMNode } from "react-dom";
import counterpart from "counterpart";
import history from "../../../utils/history";
import PageWrapper from "../../../components/ui/layout/PageWrapper";
import NavigationBar from "../../../components/ui/navigation-bar/NavigationBar";
import NavigationAlertCurrencyMismatch from "../../../components/ui/navigation-alert/NavigationAlertCurrencyMismatch";
import Panel from "../../../components/ui/panel/Panel";
import Text from "../../../components/ui/text/Text";
import FormGroup from "../../../components/ui/form-group/FormGroup";
import StepCollapsablePanel from "../../../components/ui/step-collapsable-panel/StepCollapsablePanel";
import { Row, Col } from "react-grid-system";
import FormLabel from "../../../components/ui/form-label/FormLabel";
import Input from "../../../components/ui/input/Input";
import Textarea from "../../../components/ui/textarea/Textarea";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../../..";
import { setAddOnsFormCompleted, setAddOnsFormHasError, resetCreateAddOnState, setAddOnsStepsCollapsed, setCreateAddOnsBasicInfo, createAddOnsAddFeatureTags, createAddOnsRemoveFeatureTags, createUpdateAddOn, fetchAddOn } from "../../../actions/addOnsActions";
import { trimWhiteSpaces } from "../../../utils/commonUtils";
import "./CreateAddOns.scss";
import { ICreateAddOnsReducerState } from "../../../reducers/add-ons/create-addons/createAddOnsReducer";
import { ISelectPlanAddOnsReducerState } from "../../../reducers/add-ons/selectAddOnPlanReducer";
import Checkbox from "../../../components/ui/checkbox/Checkbox";
import StepAddOnCost from "../../../components/addons-allowances/addon-cost/StepAddOnCost";
import StepSelectAddOnPlans from "../../../components/addons-allowances/step-select-add-on-plans/StepSelectAddOnPlans";
import Button from "../../../components/ui/button/Button";
import { IAddOnCostReducer, ICurrencyPriceModels } from "../../../reducers/add-ons/addOnCostReducer";
import { IAuthReducerState } from "../../../reducers/authReducer";
import { IAddOnsReducerState } from "../../../reducers/add-ons/addOnsReducer";
import { AddonsAllowancesScreen } from "../../../models/AddonsAllowances";
import { PricingModelType } from "../../../models/Product";
import StepAddOnCounter from "../../../components/addons-allowances/step-addon-counter/StepAddOnCounter";
import AddOnHoc from "../addon-hoc/AddOnHOC";
import Form from "../../../components/ui/form/Form";
import ErrorNotification from "../../../components/ui/error-notification/ErrorNotification";
import AddOnImageUploader from "../../../components/addons-allowances/addons/AddOnImageUploader";
import NoticePanel from "../../../components/ui/notice-panel/NoticePanel";
import InputTags from "../../../components/ui/input-tags/InputTags";

interface Props {
  type: AddonsAllowancesScreen;
  match: { params: { addOnId: number } }
}

const CreateAddOns: React.FC<Props> = ({ type, match }) => {
  const { currentCompanyDomain } = useSelector<AppState, IAuthReducerState>(state => state.auth)
  const createdAddOns = useSelector<AppState, ICreateAddOnsReducerState>(state => state.createAddOnsReducer)
  const addOns = useSelector<AppState, IAddOnsReducerState>(state => state.addOnsReducer)
  const selectAddOnPlan = useSelector<AppState, ISelectPlanAddOnsReducerState>(state => state.selectAddOnPlanReducer)
  const addOnCostReducer = useSelector<AppState, IAddOnCostReducer>(state => state.addOnCostReducer)
  const dispatch = useDispatch()

  const { featureTags, helpTextContentBasicForm, helpTextTitleBasicForm, name, displayName, description, singleUnitName,
    pluralUnitName, stageCollapsed, stageStatuses, stageErrors, pricingModels, selectedPricingModel, hasSelectedPricingModel,
    selectedCounter, addOnError, isAddOnImageEnabled, addOnImageFileName } = createdAddOns;
  const { isCreatingAddOnRequest, isCreatingAddOnSuccess, isCreatingAddOnFailure, isEditingAddOnRequest, isEditingAddOnFailure, isEditingAddOnSuccess, errorMessage } = addOns;
  const { selectedPlans } = selectAddOnPlan;
  const { currencyPriceModels, hasConfiguredAllPriceModels } = addOnCostReducer;
  const isFormValid = name && description && singleUnitName && pluralUnitName;
  const formRef0: any = useRef(null);
  const addOnId = match?.params.addOnId || null;
  // can be create addon or create allowances
  const isAddonScreen = type === AddonsAllowancesScreen.ADDONS;

  const isAddOnImageValid = useMemo(() => isAddOnImageEnabled ? !!addOnImageFileName : true, [isAddOnImageEnabled, addOnImageFileName]);

  useEffect(() => {
    if (isFormValid && (isCreatingAddOnSuccess || isEditingAddOnSuccess)) {
      history.push(`/addons-allowances/${type}`);
    }
  }, [isCreatingAddOnSuccess, isCreatingAddOnFailure, isEditingAddOnFailure, isEditingAddOnSuccess])

  useEffect(() => {
    if (addOnId) {
      dispatch(fetchAddOn(currentCompanyDomain, addOnId, type))
    }
    const pricingModels = isAddonScreen
      ? createdAddOns.pricingModels.filter(p => p.value !== PricingModelType.Capped)
      : createdAddOns.pricingModels.filter(p => p.value !== PricingModelType.FlatFee);

    const selectedPricingModel = !isAddonScreen && createdAddOns.selectedPricingModel.value === PricingModelType.FlatFee
      ? { ...pricingModels[0] }
      : createdAddOns.selectedPricingModel;

    dispatch(setCreateAddOnsBasicInfo("pricingModels", pricingModels));
    dispatch(setCreateAddOnsBasicInfo("selectedPricingModel", selectedPricingModel));
    return () => {
      dispatch(resetCreateAddOnState());
    }
  }, []);

  const shouldUnlockFeatureTag = useMemo(() => {
    const isPricingModelCompleted = (hasConfiguredAllPriceModels && hasSelectedPricingModel);
    return isAddonScreen ? isPricingModelCompleted : isPricingModelCompleted && !!selectedCounter.value.getCounterName();
  }, [hasConfiguredAllPriceModels, hasSelectedPricingModel, selectedCounter])

  return (
    <div>
      <NavigationBar
        pageTitle=""
        previousPageTitle={isAddonScreen ? "CREATE_ADD_ONS_TITLE" : "CREATE_ALLOWANCES_TITLE"}
        previousPageCb={() => history.push(`/addons-allowances/${type}`)}
      />
      <NavigationAlertCurrencyMismatch />
      <ErrorNotification title={errorMessage} shouldTranslateTitle={false} showAlert={isCreatingAddOnFailure || isEditingAddOnFailure} />

      <PageWrapper className="create-add-ons">
        <Panel className={`create-add-ons__heading ${isAddonScreen ? "create-add-ons__heading--addons" : "create-add-ons__heading--allowances"}`}
          shouldTranslate={false}
          title={counterpart(addOnId
            ? "EDIT_ADD_ONS_HEADING_TITLE"
            : (isAddonScreen ? "CREATE_ADD_ONS_HEADING_TITLE" : "CREATE_ALLOWANCE_HEADING_TITLE")
            , { addOnName: name })}>
          <Text
            content={addOnId
              ? (isAddonScreen ? "EDIT_ADD_ONS_HEADING_CONTENT" : "EDIT_ALLOWANCES_HEADING_CONTENT")
              : (isAddonScreen ? "CREATE_ADD_ONS_HEADING_CONTENT" : "CREATE_ALLOWANCES_HEADING_CONTENT")}
            className="create-add-ons__heading-text"
            noMargin
          />
        </Panel>

        <StepCollapsablePanel
          number={1}
          isCompleted={stageStatuses[0] && isAddOnImageValid}
          isCollapsed={addOnId ? false : stageCollapsed[0]}
          isShowTitleIcon={!addOnId}
          onClick={() => dispatch(setAddOnsStepsCollapsed(0))}
          //onFocus={() => setIsBasicFormCollapsed(!isBasicFormCollapsed)}
          hasError={stageErrors[0] || !isAddOnImageValid}
          title={addOnId ? "EDIT_ADDONS_FORM_HEADER_BASICS" : "CREATE_ADD_ONS_FORM_HEADER_BASICS"}
          helpMenuPosition="right"
          helpMenuTitle={helpTextTitleBasicForm}
          helpMenuContent={
            <div>
              {helpTextContentBasicForm.map((text, id) => <Text key={id} content={text} />)}
            </div>
          }
        >

          <Form ref={formRef0} onChange={() => {
            const form = findDOMNode(formRef0.current) as HTMLFormElement;
            
            if (form.checkValidity()) {
              dispatch(setAddOnsFormCompleted(0, true));
              dispatch(setAddOnsFormHasError(0, false));
            } else {
              dispatch(setAddOnsFormCompleted(0, false));
              dispatch(setAddOnsFormHasError(0, true));
            }
          }}>

          {!!addOnError && 
            <Row>
              <Col md={12}>
                <div className="create-add-ons__error-contianer">
                  <NoticePanel type="error" isModal content={addOnError} shouldTranslate={false} />
                </div>
              </Col>
            </Row>
          }
            <FormGroup>
              <Row align="center">
                <Col md={2}>
                  <FormLabel target="name" content="CREATE_ADD_ONS_FORM_NAME_INPUT" />
                </Col>
                <Col md={10}>
                  <Input
                    id="name"
                    required
                    value={name}
                    placeholder={counterpart(isAddonScreen ? "CREATE_ADD_ONS_FORM_NAME_INPUT_PLACE_HOLDER" : "CREATE_ALLOWANCE_FORM_NAME_INPUT_PLACE_HOLDER")}
                    onChange={(evt: any) => dispatch(setCreateAddOnsBasicInfo("name", trimWhiteSpaces(evt.target.value, "left")))}
                    onFocus={() => {
                      dispatch(setCreateAddOnsBasicInfo("helpTextTitleBasicForm", "CREATE_ADD_ONS_FORM_HELP_NAME_INPUT_TITLE"));
                      dispatch(setCreateAddOnsBasicInfo("helpTextContentBasicForm",
                        isAddonScreen ? ["CREATE_ADD_ONS_FORM_HELP_NAME_INPUT_TEXT_1"] : ["CREATE_ALLOWANCES_FORM_HELP_NAME_INPUT_TEXT_1"]));
                    }}
                  />
                </Col>
              </Row>
            </FormGroup>

            <FormGroup>
              <Row align="center">
                <Col md={2}>
                  <FormLabel target="displayname" content="CREATE_ADD_ONS_FORM_DISPLAY_NAME_INPUT" />
                </Col>
                <Col md={10}>
                  <Input
                    id="displayname"
                    placeholder={counterpart(isAddonScreen ? "CREATE_ADD_ONS_FORM_DISPLAY_NAME_INPUT_PLACE_HOLDER" : "CREATE_ALLOWANCE_FORM_NAME_INPUT_PLACE_HOLDER")}
                    value={displayName}
                    onChange={(evt: any) => dispatch(setCreateAddOnsBasicInfo("displayName", trimWhiteSpaces(evt.target.value, "left")))}
                    onFocus={() => {
                      dispatch(setCreateAddOnsBasicInfo("helpTextTitleBasicForm", "CREATE_ADD_ONS_FORM_HELP_DISPLAY_NAME_INPUT_TITLE"));
                      dispatch(setCreateAddOnsBasicInfo("helpTextContentBasicForm",
                        isAddonScreen ? ["CREATE_ADD_ONS_FORM_HELP_DISPLAY_NAME_INPUT_TEXT_1"] : ["CREATE_ALLOWANCES_FORM_HELP_DISPLAY_NAME_INPUT_TEXT_1"]));
                    }}
                  />
                </Col>
              </Row>
            </FormGroup>

            <FormGroup>
              <Row>
                <Col md={2}>
                  <FormLabel className="create-product__description" target="description" content="CREATE_ADD_ONS_FORM_DESCRIPTION_INPUT" />
                </Col>
                <Col md={10}>
                  <Textarea
                    required
                    id="description"
                    className="create-product__description-textarea"
                    value={description}
                    placeholder={counterpart(isAddonScreen ? "CREATE_ADD_ONS_FORM_DESCRIPTION_INPUT_PLACE_HOLDER" : "CREATE_ALLOWANCE_FORM_DESCRIPTION_INPUT_PLACE_HOLDER")}
                    onChange={(evt: any) => dispatch(setCreateAddOnsBasicInfo("description", evt.target.value))}
                    onFocus={() => {
                      dispatch(setCreateAddOnsBasicInfo("helpTextTitleBasicForm", "CREATE_ADD_ONS_FORM_HELP_DESCRIPTION_INPUT_TITLE"));
                      dispatch(setCreateAddOnsBasicInfo("helpTextContentBasicForm",
                        isAddonScreen ? ["CREATE_ADD_ONS_FORM_HELP_DESCRIPTION_INPUT_TEXT_1"] : ["CREATE_ALLOWANCES_FORM_HELP_DESCRIPTION_INPUT_TEXT_1"]));
                    }}
                  />
                </Col>
              </Row>
            </FormGroup>

            <FormGroup>
              <Row align="center">
                <Col md={2}>
                  <FormLabel target="singleUnitName" content="CREATE_ADD_ONS_FORM_SINGLE_UNIT_NAME_INPUT" />
                </Col>
                <Col md={10}>
                  <Input
                    id="singleUnitName"
                    placeholder={counterpart("CREATE_ADD_ONS_FORM_SINGLE_UNIT_NAME_INPUT_PLACE_HOLDER")}
                    value={singleUnitName}
                    required
                    onChange={(evt: any) => dispatch(setCreateAddOnsBasicInfo("singleUnitName", evt.target.value))}
                    onFocus={() => {
                      dispatch(setCreateAddOnsBasicInfo("helpTextTitleBasicForm", "CREATE_ADD_ONS_FORM_HELP_SINGLE_UNIT_NAME_INPUT_TITLE"));
                      dispatch(setCreateAddOnsBasicInfo("helpTextContentBasicForm", isAddonScreen
                        ? ["CREATE_ADD_ONS_FORM_HELP_SINGLE_UNIT_NAME_INPUT_TEXT_1", "CREATE_ADD_ONS_FORM_HELP_SINGLE_UNIT_NAME_INPUT_TEXT_2"]
                        : ["CREATE_ALLOWANCES_FORM_HELP_SINGLE_UNIT_NAME_INPUT_TEXT"]));
                    }}
                  />
                </Col>
              </Row>
            </FormGroup>

            <FormGroup>
              <Row align="center">
                <Col md={2}>
                  <FormLabel target="pluralUnitName" content="CREATE_ADD_ONS_FORM_PLURAL_UNIT_NAME_INPUT" />
                </Col>
                <Col md={10}>
                  <Input
                    id="pluralUnitName"
                    placeholder={counterpart("CREATE_ADD_ONS_FORM_PLURAL_UNIT_NAME_INPUT_PLACE_HOLDER")}
                    value={pluralUnitName}
                    required
                    onChange={(evt: any) => dispatch(setCreateAddOnsBasicInfo("pluralUnitName", evt.target.value))}
                    onFocus={() => {
                      dispatch(setCreateAddOnsBasicInfo("helpTextTitleBasicForm", "CREATE_ADD_ONS_FORM_HELP_PLURAL_UNIT_NAME_INPUT_TITLE"));
                      dispatch(setCreateAddOnsBasicInfo("helpTextContentBasicForm", isAddonScreen
                        ? ["CREATE_ADD_ONS_FORM_HELP_PLURAL_UNIT_NAME_INPUT_TEXT_1", "CREATE_ADD_ONS_FORM_HELP_PLURAL_UNIT_NAME_INPUT_TEXT_2"]
                        : ["CREATE_ALLOWANCES_FORM_HELP_PLURAL_UNIT_NAME_INPUT_TEXT"]));
                    }}
                  />
                </Col>
              </Row>
            </FormGroup>
            
            <AddOnImageUploader isAddonScreen={isAddonScreen} />
          </Form>
        </StepCollapsablePanel>

        <StepSelectAddOnPlans type={type} addOnId={addOnId} />

        <StepCollapsablePanel
          title={
            <div className="create-add-ons__pricing-model-title">
              <Text
                content={addOnId ? "EDIT_ADD_ONS_FORM_HEADER_PICK_A_PRICING_MODEL" : "CREATE_ADD_ONS_FORM_HEADER_PICK_A_PRICING_MODEL"}
                noMargin
              />
              {
                hasSelectedPricingModel
                  ? (
                    <div>
                      <div className="border" />
                      <Text content={selectedPricingModel.text} noMargin />
                    </div>
                  )
                  : null
              }
            </div>
          }
          className="create-add-ons__pricing-model"
          shouldTranslateTitle={false}
          number={3}
          isCompleted={stageStatuses[2]}
          hasHelpSection={!hasSelectedPricingModel}
          isLocked={hasSelectedPricingModel || (!stageStatuses[1] && selectedPlans.length < 1)}
          isCollapsed={stageCollapsed[2]}
          hasError={stageErrors[2]}
          isShowLockIcon={hasSelectedPricingModel}
          isShowTitleIcon={!addOnId}
          helpMenuPosition="right"
          helpMenuTitle={selectedPricingModel.text}
          helpMenuContent={
            <div>
              {selectedPricingModel[isAddonScreen ? "helpTextContentAddon" : "helpTextContentAllowance"]
                .map((help, idx) => <Text key={idx} content={help} />)}
            </div>
          }
          onClick={() => dispatch(setAddOnsStepsCollapsed(2))}
        >
          <>
            <Text content={isAddonScreen ? "CREATE_ADD_ONS_PRICING_MODEL_SUBTITLE" : "CREATE_ALLOWANCES_PRICING_MODEL_SUBTITLE"} />
            <FormGroup>
              {
                pricingModels.map((pricingModel, id) => (
                  <Checkbox
                    key={id}
                    checked={selectedPricingModel.value === pricingModel.value}
                    onClick={() => {
                      dispatch(setCreateAddOnsBasicInfo("selectedPricingModel", pricingModel));
                      dispatch(setCreateAddOnsBasicInfo("helpTextTitlePricingModel", pricingModel.text));
                      dispatch(setCreateAddOnsBasicInfo("helpTextContentPricingModel", isAddonScreen ? pricingModel.helpTextContentAddon : pricingModel.helpTextContentAllowance));
                    }}
                    value={pricingModel.value}
                    content={counterpart(pricingModel.text)}
                  />
                ))
              }
            </FormGroup>
            <Button
              id="add-on-select-pricing-model"
              title={isAddonScreen ? "CREATE_ADD_ONS_PRICING_MODEL_BTN" : "CREATE_ALLOWANCES_PRICING_MODEL_BTN"}
              translateWith={{ pricingModel: counterpart(selectedPricingModel.text).toUpperCase() }}
              onClick={() => {
                dispatch(setCreateAddOnsBasicInfo("hasSelectedPricingModel", true));
                dispatch(setAddOnsFormCompleted(2, true));
                // open subsequent panel
                dispatch(setAddOnsStepsCollapsed(3));
              }}
              isFullWidth
            />
          </>
        </StepCollapsablePanel>


        <StepAddOnCost type={type} addOnId={addOnId} />

        {!isAddonScreen && <StepAddOnCounter addOnId={addOnId} />}

        <StepCollapsablePanel
          title={(isAddonScreen && !addOnId)
            ? "CREATE_ADD_ONS_FORM_HEADER_ADD_FEATURE_TAG"
            : (!isAddonScreen && !addOnId)
              ? "CREATE_ALLOWANCE_FORM_HEADER_ADD_FEATURE_TAG"
              : "EDIT_ADDONS_ALLOWANCE_FORM_HEADER_ADD_FEATURE_TAG"
          }
          number={isAddonScreen ? 5 : 6}
          isCompleted={featureTags.length > 0}
          isLocked={!shouldUnlockFeatureTag}
          isCollapsed={addOnId ? false : stageCollapsed[isAddonScreen ? 4 : 5]}
          hasError={stageErrors[isAddonScreen ? 4 : 5]}
          hasHelpSection={false}
          isShowTitleIcon={!addOnId}
          onClick={() => dispatch(setAddOnsStepsCollapsed(isAddonScreen ? 4 : 5))}
        >
          <div>
            <Text content={"CREATE_ADD_ONS_FORM_HEADER_ADD_FEATURE_TAG_TEXT"} />
            <Row align="center" className="create-add-ons__add-feature-tags">
              <Col md={1}>
                <FormLabel target="featureTags" content="CREATE_ADD_ONS_ADD_FEATURE_TAG_LABEL" noMargin />
              </Col>
              <Col md={11}>
                <InputTags
                  tags={featureTags}
                  placeholder={counterpart("CREATE_ADD_ONS_ADD_FEATURE_TAG_PLACEHOLDER")}
                  handleDelete={(tagIndex: number) => {
                    dispatch(createAddOnsRemoveFeatureTags(tagIndex));
                  }}
                  handleAddition={(tag: any) => {
                    dispatch(createAddOnsAddFeatureTags(tag));
                  }}
                />
              </Col>
              <Col offset={{ md: 1 }} md={9}>
                <Text style={{ marginTop: "10px" }} content="CREATE_PLAN_FEATURE_TAGS_NOTICE" noMargin></Text>
              </Col>
            </Row>
          </div>
        </StepCollapsablePanel>

        <Button
          className="create-add-ons__btn"
          id="create-add-on-btn"
          title={addOnId ? (isAddonScreen ? "EDIT_ADD_ONS_BUTTON" : "EDIT_ALLOWANCE_BUTTON") : (isAddonScreen ? "CREATE_ADD_ONS_BUTTON" : "CREATE_ALLOWANCE_BUTTON")}
          buttonType="general"
          disabled={!isFormValid || !hasConfiguredAllPriceModels || !hasSelectedPricingModel || isCreatingAddOnRequest || !isAddOnImageValid || (!isAddonScreen && !selectedCounter.value.getCounterName())}
          isLoading={isCreatingAddOnRequest || isEditingAddOnRequest}
          isFullWidth={true}
          onClick={() => dispatch(createUpdateAddOn(addOnId, currentCompanyDomain, name, displayName, description, singleUnitName, pluralUnitName,
            selectedPlans, selectedPricingModel.value, currencyPriceModels || {} as ICurrencyPriceModels, selectedCounter, featureTags.map(t => t.name), type, isAddOnImageEnabled, addOnImageFileName))}
        />

      </PageWrapper>
    </div>
  )
}

export default AddOnHoc(CreateAddOns)
