import { BillsbyAction } from "../models/BillsbyAction";
import { AppState } from "..";
import { FETCH_COMPANY_LANGUAGES_SUCCESS, RESET_ACCOUNT_MANAGEMENT_LANGUAGES, RESET_CHECKOUT_LANGUAGES, RESET_COMPANY_LANGUAGES, RESET_COMPANY_LANGUAGES_CONFIG, UPDATE_ACCOUNT_MANAGEMENT_LANGUAGES, UPDATE_ACCOUNT_MANAGEMENT_LANGUAGES_SUCCESS, UPDATE_CHECKOUT_LANGUAGES, UPDATE_CHECKOUT_LANGUAGES_SUCCESS, UPDATE_COMPANY_LANGUAGES, UPDATE_COMPANY_LANGUAGES_SUCCESS  } from "../actions/languageSupportActions";
import { ICompanyLaguageConfig, ISupportedLanguage, Language } from "../models/languageSupport";
import { GetCompanyLanguagesResponse } from "../utils/grpc/generated/Billsby.Protos/core/private/companies/company_pb";

export let initialDefaultCheckoutLanguage: string = "";
export let initialDefaultAccountManagementLanguage: string = "";

export interface ILanguageSupportReducerState {
  companyLaguageConfig: ICompanyLaguageConfig;
  intinialCompanyLanguagesConfig: ICompanyLaguageConfig;
}

export const initialState = {
  companyLaguageConfig: {
    companyLanguagesList: [],
    defaultCheckoutLanguage: "",
    defaultAccountManagementLanguage: "",
  },
  intinialCompanyLanguagesConfig: {
    companyLanguagesList: [],
    defaultCheckoutLanguage: "",
    defaultAccountManagementLanguage: "",
  }
}


export default function languageSupportReducer(state: ILanguageSupportReducerState = initialState, action: BillsbyAction, store: AppState) {
  switch (action.type) {
    case FETCH_COMPANY_LANGUAGES_SUCCESS: {
      const response = action.response as GetCompanyLanguagesResponse;
      const languageList = response.getCompanyLanguagesList();
      const intinialCompanyLanguagesConfig = { ...response.toObject(), companyLanguagesList: [...languageList.map(i =>({...i.toObject()}))] }
      const companyLaguageConfig = { ...response.toObject(), companyLanguagesList: [...languageList.map(i =>({...i.toObject()}))] }
      initialDefaultCheckoutLanguage = response.getDefaultCheckoutLanguage();
      initialDefaultAccountManagementLanguage = response.getDefaultAccountManagementLanguage();

      return { ...state, companyLaguageConfig, intinialCompanyLanguagesConfig }
    }
    case UPDATE_COMPANY_LANGUAGES: {
      const languageToUpdate:ISupportedLanguage = { ...action.payload };
      if(languageToUpdate.isoCode === Language.ENGLISH)
        return { ...state }

      const companyLanguageCopy = [...state.companyLaguageConfig.companyLanguagesList.map(i =>({...i}))];
      const objIndex = companyLanguageCopy.findIndex((obj => obj.isoCode === languageToUpdate.isoCode));
      companyLanguageCopy[objIndex].isEnabled = !languageToUpdate.isEnabled;

      return { ...state, companyLaguageConfig: {...state.companyLaguageConfig, companyLanguagesList: companyLanguageCopy } }
    }   
    case RESET_COMPANY_LANGUAGES: {
      const intialConfigCopy = [...state.intinialCompanyLanguagesConfig.companyLanguagesList.map(i =>({...i}))];
      return { ...state, companyLaguageConfig: {...state.companyLaguageConfig, companyLanguagesList: [...intialConfigCopy] } }
    } 
    case UPDATE_COMPANY_LANGUAGES_SUCCESS: {
      const companyLaguageCopy = [...state.companyLaguageConfig.companyLanguagesList.map(i =>({...i}))];
      
      const defaultCheckoutLanguage = companyLaguageCopy.some(i => i.isoCode === state.companyLaguageConfig.defaultCheckoutLanguage && i.isEnabled) ?
        state.companyLaguageConfig.defaultCheckoutLanguage : Language.ENGLISH;
      const defaultAccountManagementLanguage = companyLaguageCopy.some(i => i.isoCode === state.companyLaguageConfig.defaultAccountManagementLanguage && i.isEnabled) ?
        state.companyLaguageConfig.defaultAccountManagementLanguage : Language.ENGLISH;
      return { ...state, intinialCompanyLanguagesConfig: {...state.intinialCompanyLanguagesConfig, companyLanguagesList: [...companyLaguageCopy] },
        companyLaguageConfig: {...state.companyLaguageConfig, defaultCheckoutLanguage, defaultAccountManagementLanguage}}
    }
    case UPDATE_CHECKOUT_LANGUAGES: {
      return { ...state, companyLaguageConfig: {...state.companyLaguageConfig, defaultCheckoutLanguage: action.payload } }
    }   
    case RESET_CHECKOUT_LANGUAGES: {
      const { defaultCheckoutLanguage } = {...state.intinialCompanyLanguagesConfig };
      return { ...state, companyLaguageConfig: {...state.companyLaguageConfig, defaultCheckoutLanguage } }
    }
    case UPDATE_CHECKOUT_LANGUAGES_SUCCESS: {
      const { defaultCheckoutLanguage, companyLanguagesList } = {...state.companyLaguageConfig };
      const companyLanguageCopy = [...companyLanguagesList.map(i =>({...i}))];
      const objIndex = companyLanguageCopy.findIndex((obj => obj.isoCode === defaultCheckoutLanguage));
      companyLanguageCopy[objIndex].isEnabled = true;
      return { ...state, intinialCompanyLanguagesConfig: {...state.intinialCompanyLanguagesConfig, defaultCheckoutLanguage },
        companyLaguageConfig: {...state.companyLaguageConfig, companyLanguagesList: companyLanguageCopy }}
    }
    case UPDATE_ACCOUNT_MANAGEMENT_LANGUAGES: {
      return { ...state, companyLaguageConfig: {...state.companyLaguageConfig, defaultAccountManagementLanguage: action.payload } }
    }   
    case RESET_ACCOUNT_MANAGEMENT_LANGUAGES: {
      const { defaultAccountManagementLanguage } = {...state.intinialCompanyLanguagesConfig };
      return { ...state, companyLaguageConfig: {...state.companyLaguageConfig, defaultAccountManagementLanguage } }
    }
    case UPDATE_ACCOUNT_MANAGEMENT_LANGUAGES_SUCCESS: {
      const { defaultAccountManagementLanguage, companyLanguagesList } = {...state.companyLaguageConfig };
      const companyLanguageCopy = [...companyLanguagesList.map(i =>({...i}))];
      const objIndex = companyLanguageCopy.findIndex((obj => obj.isoCode === defaultAccountManagementLanguage));
      companyLanguageCopy[objIndex].isEnabled = true;
      return { ...state, intinialCompanyLanguagesConfig: {...state.intinialCompanyLanguagesConfig, defaultAccountManagementLanguage },
        companyLaguageConfig: {...state.companyLaguageConfig, companyLanguagesList: companyLanguageCopy }}
    }
    case RESET_COMPANY_LANGUAGES_CONFIG: {
      return {...initialState}
    }
    default:
      return state;
  }
}