import React, { useEffect, useState } from "react";
import { UnitTier } from "../../../utils/grpc/generated/Billsby.Protos/core/private/addons/addons_pb";
import MenuPanel from "../../ui/menu-panel/MenuPanel";
import StepCollapsablePanel from "../../ui/step-collapsable-panel/StepCollapsablePanel";
import Text from "../../ui/text/Text";
import FormGroup from "../../ui/form-group/FormGroup";
import { Row, Col } from "react-grid-system";
import FormLabel from "../../ui/form-label/FormLabel";
import Input from "../../ui/input/Input";
import Button from "../../ui/button/Button";
import { PricingModelType } from "../../../models/Product";
import counterpart from "counterpart";
import Panel from "../../ui/panel/Panel";
import { Int32Value } from "google-protobuf/google/protobuf/wrappers_pb";
import Switch from "../../ui/switch/Switch";
import { AddonsAllowancesScreen } from "../../../models/AddonsAllowances";
import { allowZeroAndPositiveNumbers, getMaxEndTierUnit } from "../../../utils/commonUtils";

interface IProps {
  currency: string;
  frequency: string;
  unitTiers: UnitTier[],
  pricingModelType: PricingModelType,
  nrUnits?: number,
  configure: (tiers: UnitTier[], nrUnits?: number) => void;
  cancel: () => void;
  type: AddonsAllowancesScreen;
}

const TierPriceModel: React.FC<IProps> = React.memo(({
  currency,
  frequency,
  pricingModelType,
  unitTiers,
  nrUnits = "",
  configure,
  cancel,
  type
}) => {
  const sortTiers = (firstTier: UnitTier, secondTier: UnitTier) => firstTier.getStart() > secondTier.getStart() ? 1 : -1;
  const sortTiersFn = (tiersToSort: UnitTier[]) => [...tiersToSort.sort(sortTiers)];
  const isAddonScreen = type === AddonsAllowancesScreen.ADDONS;
  const [tempPrice, setTempPrice] = useState<number>();
  const [tiers, setTiers] = useState<UnitTier[]>(sortTiersFn(unitTiers));
  const [tempUnit, setTempUnit] = useState<number>();
  const [unitsToSimulate, setUnitsToSimulate] = useState<number>(0);
  const [simulatedPrice, setSimulatedPrice] = useState<number>(0);
  const [hasIncludedUnits, setHasIncludedUnits] = useState(!!nrUnits);
  const [includedUnits, setIncludedUnits] = useState(`${nrUnits}`);
  const getPriceModelHelpTitle = () => {
    return isAddonScreen
      ? `CREATE_ADD_ONS_PRICE_MODELS_${pricingModelType.toUpperCase()}_HELP_TITLE`
      : `CREATE_ALLOWANCES_PRICE_MODELS_${pricingModelType.toUpperCase()}_HELP_TITLE`;
  }
  const getPriceModelHelpContent = () => {
    return isAddonScreen
      ? [
        `CREATE_ADD_ONS_PRICE_MODELS_${pricingModelType.toUpperCase()}_HELP_CONTENT_1`,
        `CREATE_ADD_ONS_PRICE_MODELS_${pricingModelType.toUpperCase()}_HELP_CONTENT_2`
      ]
      : [
        `CREATE_ALLOWANCES_PRICE_MODELS_${pricingModelType.toUpperCase()}_HELP_CONTENT_1`,
        `CREATE_ALLOWANCES_PRICE_MODELS_${pricingModelType.toUpperCase()}_HELP_CONTENT_2`
      ]
  }
  const [helpTitle, setHelpTitle] = useState(getPriceModelHelpTitle());
  const [helpContent, setHelpContent] = useState(getPriceModelHelpContent());

  const priceWithSymbol = (price: Int32Value | number) => currency.replace("_", price.toString());
  const extractSymbol = currency.replace(/ |_/g, "");

  const maxEndUnit = 1000000;
  const currentTierUnit = tiers[tiers.length - 1] ? tiers[tiers.length - 1].getFinish() + 1 : 1;
  const nextTierUnit = tempUnit && tempUnit !== 0 ? tempUnit : maxEndUnit;
  const enableAddTier = tempPrice !== undefined ? (tempUnit && tempUnit !== 0 ? currentTierUnit <= tempUnit : true) : false;
  const rangeIsMaxed = tiers[tiers.length - 1] && tiers[tiers.length - 1].getFinish() === maxEndUnit;

  useEffect(() => {
    //Reset the provided unit when updating the tier list
    setUnitsToSimulate(0)
  }, [tiers])

  useEffect(() => {
    simulatePrice(unitsToSimulate, tiers);
  }, [unitsToSimulate])

  const isPriceValueValid = (price?: number) => {
    if (price === undefined) return false;

    if (pricingModelType === PricingModelType.Volume ||
      pricingModelType === PricingModelType.Ranged ||
      pricingModelType === PricingModelType.Tiered) {
      return price >= 0 && price <= 999999.99;
    }

    if (pricingModelType === PricingModelType.PerUnit || pricingModelType === PricingModelType.FlatFee) {
      if (price === undefined) {
        return true;
      }
      return price && price >= 0.5 && price <= 999999.99;
    }

    return false;
  };

  const addTier = () => {
    const newTiers = [...tiers];
    const newTier = new UnitTier();
    if (tempPrice) {
      newTier.setPrice(tempPrice);
      newTier.setPriceFormatted(`${tempPrice}`);
    }
    newTier.setStart(currentTierUnit);
    if (nextTierUnit) {
      newTier.setFinish(nextTierUnit);
    }
    newTiers.push(newTier);
    setTiers(sortTiersFn(newTiers));
    setTempPrice(undefined);
    setTempUnit(undefined);
  };

  const simulatePrice = (units: number, tiersInput: UnitTier[]) => {
    let price = 0;

    tiersInput.forEach(tier => {
      if (pricingModelType === PricingModelType.Tiered) {
        for (let i = 1; i <= units; i++) {
          if (tier.getStart() <= i && tier.getFinish() >= i) {
            price += tier.getPrice();
          }
        }
      }

      if (pricingModelType === PricingModelType.Ranged) {
        if (tier.getStart() <= units && tier.getFinish() >= units) {
          price = tier.getPrice();
        }
      }

      if (pricingModelType === PricingModelType.Volume) {
        if (tier.getStart() <= units && tier.getFinish() >= units) {
          price = units * tier.getPrice();
        }
      }
    });

    setSimulatedPrice(price);
  };

  const removeTierLastTier = () => {
    const newTiers = [...tiers];
    newTiers.pop();
    setTiers(sortTiersFn(newTiers));
    simulatePrice(unitsToSimulate, newTiers);
  };

  const getIncludedUnitsGroup = () => {
    return (
      <Row align="center" className="step-addon-cost__configurePanel-includedunits">
        <Col xs={2}>
          <FormLabel target="units-switch" content="CREATE_ALLOWANCES_PRICE_MODELS_CONFIGURE_INCLUDEDUNITS_LABEL" />
        </Col>
        <Col xs={2}>
          <Switch id="units-switch" checked={hasIncludedUnits} onChange={isChecked => {
            setHasIncludedUnits(isChecked);
            setHelpTitle("CREATE_ALLOWANCES_PRICE_MODELS_INCLUDEDUNITS_HELP_TITLE");
            setHelpContent(["CREATE_ALLOWANCES_PRICE_MODELS_INCLUDEDUNITS_HELP_CONTENT"]);
            if (!isChecked) {
              setIncludedUnits("0");
              setHelpTitle(getPriceModelHelpTitle());
              setHelpContent(getPriceModelHelpContent());
            }
          }} />
        </Col>
        <Col xs={8}>
          {hasIncludedUnits && (
            <Input
              id="unit-switch-input"
              type="number"
              allowDecimal={false}
              value={includedUnits}
              min={0}
              onFocus={() => {
                setHelpTitle("CREATE_ALLOWANCES_PRICE_MODELS_INCLUDEDUNITS_HELP_TITLE");
                setHelpContent(["CREATE_ALLOWANCES_PRICE_MODELS_INCLUDEDUNITS_HELP_CONTENT"]);
              }}
              onChange={evt => Number.isInteger(+evt.target.value) && setIncludedUnits(evt.target.value)}
            />
          )}
        </Col>
      </Row>
    )
  }

  return (
    <MenuPanel className='step-addon-cost__configurePanel'>
      <MenuPanel.Content>
        <StepCollapsablePanel
          title='CREATE_ADD_ONS_PRICE_MODELS_CONFIGURE_TITLE'
          isCompleted={false}
          hasError={false}
          isLocked={false}
          hasHelpSection={true}
          isCollapsed={false}
          helpMenuPosition='right'
          helpMenuTitle={helpTitle}
          helpMenuContent={
            <>
              <Text content={helpContent[0]} />
              <Text content={helpContent[1]} noMargin />
            </>
          }
          onClick={() => { }}>
          <>
            <FormGroup>
              {!isAddonScreen && getIncludedUnitsGroup()}
              <Row align='center'>
                <Col xs={2}>
                  <FormLabel
                    target='unit-range'
                    shouldTranslate={false}
                    content={counterpart("CREATE_PLAN_ADDING_CYCLE_UNITS", {
                      unitFrom: rangeIsMaxed ? 1 : currentTierUnit
                    })}
                    noMargin
                  />
                </Col>
                <Col xs={2}>
                  <Input
                    disabled={rangeIsMaxed}
                    id='unit-range'
                    max={maxEndUnit}
                    type='number'
                    icon=''
                    allowDecimal={false}
                    value={tempUnit === 0 ? "" : tempUnit}
                    placeholder={counterpart("CREATE_PLAN_UNITS_ABOVE")}
                    onChange={(evt: any) =>
                      setTempUnit(Number(evt.target.value) < maxEndUnit ? Number(evt.target.value) : maxEndUnit)
                    }
                    onFocus={() => {
                      setHelpTitle(getPriceModelHelpTitle());
                      setHelpContent(getPriceModelHelpContent());
                    }}
                  />
                </Col>
                <Col xs={2}>
                  <FormLabel
                    target='price-per-unit'
                    content={
                      pricingModelType === PricingModelType.Ranged
                        ? "CREATE_PLAN_ADDING_CYCLE_PRICE_FOR_RANGE"
                        : "CREATE_PLAN_ADDING_CYCLE_PRICE_PER_UNIT"
                    }
                    noMargin
                  />
                </Col>
                <Col xs={3}>
                  <div className='create-cycle__price'>
                    <Text
                      className='text-blue'
                      content={extractSymbol}
                      shouldTranslate={false}
                      noMargin
                    />
                    <Input
                      disabled={rangeIsMaxed}
                      id='price-per-unit'
                      type='number'
                      value={tempPrice}
                      placeholder='10.00'
                      isError={tempPrice ? !isPriceValueValid(tempPrice) : false}
                      warninglayout={tempPrice ? !isPriceValueValid(tempPrice) : false}
                      warningIcon=""
                      onChange={(evt: any) => {
                        const value = parseFloat(evt.target.value).toFixed(2);
                        setTempPrice(allowZeroAndPositiveNumbers(value))  
                       }
                      }
                      onFocus={() => {
                        setHelpTitle(getPriceModelHelpTitle());
                        setHelpContent(getPriceModelHelpContent());
                      }}
                    />
                  </div>
                </Col>
                <Col xs={3}>
                  <Button
                    id='add-tier'
                    disabled={
                      !enableAddTier ||
                      rangeIsMaxed ||
                      !isPriceValueValid(tempPrice)
                    }
                    buttonType='add'
                    title='CREATE_PLAN_ADDING_CYCLE_ADD_TIERED'
                    icon='fal fa-plus-circle'
                    isFullWidth
                    onClick={() => addTier()}
                  />
                </Col>
              </Row>
            </FormGroup>
            {tiers.map((tier, idx) => (
              <Panel className='create-cycle-tiered__panel' key={idx}>
                <Text
                  content={
                    tier.getFinish() === maxEndUnit
                      ? "CREATE_PLAN_ADDING_CYCLE_UNITS_RANGE_MAX"
                      : "CREATE_PLAN_ADDING_CYCLE_UNITS_RANGE"
                  }
                  translateWith={{
                    unitFrom: tier.getStart(),
                    unitTo: tier.getFinish()
                  }}
                  noMargin
                />
                <span className='divider divider--vertical' />
                <Text
                  content={
                    pricingModelType === PricingModelType.Ranged
                      ? "CREATE_PLAN_ADDING_CYCLE_PRICE_FOR_RANGE_DATA"
                      : "CREATE_PLAN_ADDING_CYCLE_PRICE_PER_UNIT_DATA"
                  }
                  translateWith={{
                    price: `${priceWithSymbol(tier.getPrice())}`
                  }}
                  noMargin
                />
                {tiers.length - 1 === idx && (
                  <i
                    className='far fa-trash-alt'
                    onClick={() => removeTierLastTier()}
                  />
                )}
              </Panel>
            ))}
            {tiers.length !== 0 && (
              <Panel className='create-cycle-tiered__panel create-cycle-tiered__panel--preview'>
                <FormLabel
                  target='preview-input'
                  content='CREATE_PLAN_ADDING_CYCLE_PREVIEW'
                  noMargin
                />
                <div className='create-cycle-tiered__preview-content'>
                  <Text noMargin content='CREATE_PLAN_ADDING_CYCLE_FOR' />
                  <Input
                    id='preview-input'
                    type='number'
                    value={unitsToSimulate}
                    allowDecimal={false}
                    onChange={(evt: any) => {
                      const units = getMaxEndTierUnit(tiers) > evt.target.value ?  evt.target.value : getMaxEndTierUnit(tiers);
                      setUnitsToSimulate(units);
                    }}
                    onFocus={() => {
                      setHelpTitle(
                        `CREATE_${isAddonScreen ? "ADD_ONS" : "ALLOWANCES"}_PRICE_MODELS_TIERED_PRICE_PREVIEW_HELP_TITLE`
                      );
                      setHelpContent([
                        `CREATE_${isAddonScreen ? "ADD_ONS" : "ALLOWANCES"}_PRICE_MODELS_TIERED_PRICE_PREVIEW_HELP_CONTENT_1`,
                        `CREATE_${isAddonScreen ? "ADD_ONS" : "ALLOWANCES"}_PRICE_MODELS_TIERED_PRICE_PREVIEW_HELP_CONTENT_2`
                      ]);
                    }}
                  />
                  <Text
                    noMargin
                    content='CREATE_ADD_ONS_PRICE_MODELS_PREVIEW_DESCRIPTION'
                    translateWith={{
                      simulatedPrice: `${priceWithSymbol(simulatedPrice)}`,
                      frequency: frequency
                    }}
                  />
                </div>
              </Panel>
            )}
            <Row align='center'>
              <Col xs={8}>
                <Button
                  id='configure-price'
                  disabled={!tiers.length || !!(tempPrice || tempUnit) || (hasIncludedUnits && !(+includedUnits))}
                  title='CREATE_ADD_ONS_PRICE_MODELS_CONFIGURE_PRICE_BTN'
                  onClick={() => configure(tiers, +includedUnits)}
                  isFullWidth
                />
              </Col>
              <Col xs={4}>
                <Button
                  id='cancel'
                  title='CREATE_ADD_ONS_PRICE_MODELS_CONFIGURE_PRICE_CANCEL'
                  onClick={cancel}
                  buttonType='error'
                  isFullWidth
                />
              </Col>
            </Row>
          </>
        </StepCollapsablePanel>
      </MenuPanel.Content>
    </MenuPanel>
  );
});

export default TierPriceModel;
