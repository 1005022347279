import { PlanChangeType } from "./Subscriptions";
import { FrequencyType } from "./Product";

export enum CycleFrequencyType {
  Daily = "Daily",
  Weekly = "Weekly",
  Monthly = "Monthly",
  Yearly = "Yearly"
}

export interface IPlanPricingModel {
  billingDateType: string,
  fixedBillingDateDay?: number,
  freeTrial: number,
  freeTrialFrequencyType: string,
  frequency: number,
  frequencyType?: FrequencyType,
  price: number,
  priceFormatted: string,
  pricingModelId: number,
  proRateOption: string,
  setupFeePrice: number,
  setupFeePriceFormatted: string
}

export interface ISubscriptionAddonUpdateModel {
  addOnId: number,
  quantity: number
}

export interface ISubscriptionUpdateModel {
  planId: number,
  cycleId: number,
  customerUniqueId: string,
  planChangetype?: PlanChangeType,
  units?: number,
  addOns: ISubscriptionAddonUpdateModel[],
  allowances: number[]
}

export enum RetentionStepType {
  HelpInterrupt = "HelpInterrupt",
  ReasonsToStay = "ReasonsToStay",
  TimeRemaining = "TimeRemaining",
  PlanChange = "PlanChange",
  DiscountOffer = "DiscountOffer"
}