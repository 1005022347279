import React from "react";
import "./InfoLabel.scss";
import Text from "../text/Text";

interface IInfoLabel {
  content: string,
  shouldTranslate?: boolean,
  type: "error" | "success"
  noMargin?: boolean,
  isVisible?: boolean
}

const InfoLabel: React.FC<IInfoLabel> = React.memo(({ content, shouldTranslate = true, noMargin = false, type, isVisible = true }) => {
  if (!isVisible) {
    return null;
  }
  return (
    <Text className={`info-label info-label--${type}`} content={content} shouldTranslate={shouldTranslate} noMargin={noMargin} />
  )
})

export default InfoLabel;