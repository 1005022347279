import React, { useState } from "react"
import Text from "../../../ui/text/Text";
import Button from "../../../ui/button/Button";
import Panel from "../../../ui/panel/Panel";
import FormGroup from "../../../ui/form-group/FormGroup";
import { Col, Row } from "react-grid-system";
import { ConfigConstants } from "../../../../utils/config";
import Checkbox from "../../../ui/checkbox/Checkbox";

interface IFreeAgentCheckList {
  currentCompanyId: number | null,
  token: string
}

export const FreeAgentCheckList: React.FC<IFreeAgentCheckList> = ({ currentCompanyId, token}) => {
  const [firstWarningIsCheck, setfirstWarningIsCheck] = useState(false);
  const [secondWarningIsCheck, setSecondWarningIsCheck] = useState(false);

  return (
    <div>
      <Panel title="FREE_AGENT_CHECK_LIST_TITLE">
        <FormGroup>
          <Row>
            <Col xs={1}>
              <Checkbox checked={firstWarningIsCheck} onClick={() => setfirstWarningIsCheck(!firstWarningIsCheck)} value="" content="" />
            </Col>
            <Col xs={11}>
              <Text content="FREE_AGENT_CHECK_LIST_TEXT_1" noMargin />
            </Col>
          </Row>
        </FormGroup>
        <FormGroup noMargin>
          <Row>
            <Col xs={1}>
              <Checkbox checked={secondWarningIsCheck} onClick={() => setSecondWarningIsCheck(!secondWarningIsCheck)} value="" content="" />
            </Col>
            <Col xs={11}>
              <Text content="FREE_AGENT_CHECK_LIST_TEXT_2" noMargin />
            </Col>
          </Row>
        </FormGroup>
      </Panel>
      <Button
        id="free-agent-check-list-submit"
        type="submit"
        disabled={!firstWarningIsCheck || !secondWarningIsCheck }
        buttonType={"general"}
        onClick={ (evt: any) => {
          evt.stopPropagation();
          const redirectURI = encodeURI(`https://app${ConfigConstants.billsbyDomain}/auth/freeagent&response_type=code&state=${currentCompanyId}|${window.location.href}`);
          window.location.href = `${ConfigConstants.freeAgentUrl}/v2/approve_app?client_id=${ConfigConstants.freeAgentClientId}&redirect_uri=${redirectURI}`;
        }}
        title="FREE_AGENT_CHECK_LIST_BUTTON"
        isFullWidth
      />
    </div>
  )
}

export default FreeAgentCheckList;
