import React, { useEffect, useRef } from "react";
import "./DashboardUnpaidInvoiveWarning.scss";
import Text from "../../ui/text/Text";
import Button from "../../ui/button/Button";
import { BillsbyDataAction, BillsbyDataParams, BillsbyDataType, BILLSBY_BILLING_COMPANY } from "../../../utils/checkout/checkoutIntegrationModel";
import { useSelector } from "react-redux";
import { AppState } from "../../..";
import { IAuthReducerState } from "../../../reducers/authReducer";
import { findDOMNode } from "react-dom";
import { appendBillsbyDataAttributeToCheckoutLib } from "../../../utils/checkout/checkoutIntegrationUtils";

interface IDashboardUnpaidInvoiveWarning {
  firstName: string,
  companyName: string,
  amountFormatted: string,
  invoiceNumber: string
}

const DashboardUnpaidInvoiveWarning: React.FC<IDashboardUnpaidInvoiveWarning> = ({firstName, companyName, amountFormatted, invoiceNumber}) => {
  const viewUnpaidInvoicesRef = useRef(null);
  const auth = useSelector<AppState, IAuthReducerState>(state => state.auth);

  useEffect(() => {
    appendBillsbyDataAttributeToCheckoutLib({ label: BillsbyDataParams.company, value: BILLSBY_BILLING_COMPANY });
    window.scanDomBillsby();
  }, [])

  const getCheckoutBtns = () => {
    const viewUnpaidInvoicesProps = {
      [BillsbyDataParams.type]: BillsbyDataType.ACCOUNT,
      [BillsbyDataParams.action]: BillsbyDataAction.VIEW_BILLING_HISTORY_UNPAID,
      [BillsbyDataParams.customer]: auth.currentCompanyCustomerUniqueId,
      [BillsbyDataParams.subscription]: auth.currentCompanySubscriptionUniqueId
    }

    return (
      <div style={{ display: "none" }}>
        <a ref={viewUnpaidInvoicesRef} {...viewUnpaidInvoicesProps} />
      </div>
    )
  }

  return (
    <div className="dashboard-unpaid-invoice-warning__container">
      {getCheckoutBtns()}
      <div className="dashboard-unpaid-invoice-warning__message-panel">
        <Text content="DASHBOARD_UNPAID_INVOICE_WARNING_TITLE" translateWith={{firstName}} className="dashboard-unpaid-invoice-warning__title"/>
        <Text content="DASHBOARD_UNPAID_INVOICE_WARNING_TEXT_1" translateWith={{companyName, amountFormatted}} className="dashboard-unpaid-invoice-warning__text"/>
        <Text content="DASHBOARD_UNPAID_INVOICE_WARNING_TEXT_2" className="dashboard-unpaid-invoice-warning__text"/>
        
        <Button
          id="add-customer-next"
          type="submit"
          icon={"far fa-file-invoice-dollar"}
          buttonType={"general"}
          title={"DASHBOARD_UNPAID_INVOICE_WARNING_BUTTON_TEXT"}
          className="dashboard-unpaid-invoice-warning__button"
          onClick={() => {
            //history.push(`/invoice/${invoiceNumber}`)
            const viewUnpaidInvoiceDom = findDOMNode(viewUnpaidInvoicesRef.current) as HTMLElement;
            viewUnpaidInvoiceDom && viewUnpaidInvoiceDom.click();
          }}
        />
      </div>
    </div>
  )
}

export default DashboardUnpaidInvoiveWarning
