/* eslint-disable */
// source: companyintegrations.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!

var jspb = require('google-protobuf');
var goog = jspb;
var global = Function('return this')();

var google_protobuf_wrappers_pb = require('google-protobuf/google/protobuf/wrappers_pb.js');
goog.object.extend(proto, google_protobuf_wrappers_pb);
goog.exportSymbol('proto.core.private.companyintegrations.CompanyIntegration', null, global);
goog.exportSymbol('proto.core.private.companyintegrations.GetCompanyIntegrationsRequest', null, global);
goog.exportSymbol('proto.core.private.companyintegrations.GetCompanyIntegrationsResponse', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.core.private.companyintegrations.GetCompanyIntegrationsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.core.private.companyintegrations.GetCompanyIntegrationsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.core.private.companyintegrations.GetCompanyIntegrationsRequest.displayName = 'proto.core.private.companyintegrations.GetCompanyIntegrationsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.core.private.companyintegrations.GetCompanyIntegrationsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.core.private.companyintegrations.GetCompanyIntegrationsResponse.repeatedFields_, null);
};
goog.inherits(proto.core.private.companyintegrations.GetCompanyIntegrationsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.core.private.companyintegrations.GetCompanyIntegrationsResponse.displayName = 'proto.core.private.companyintegrations.GetCompanyIntegrationsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.core.private.companyintegrations.CompanyIntegration = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.core.private.companyintegrations.CompanyIntegration, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.core.private.companyintegrations.CompanyIntegration.displayName = 'proto.core.private.companyintegrations.CompanyIntegration';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.core.private.companyintegrations.GetCompanyIntegrationsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.core.private.companyintegrations.GetCompanyIntegrationsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.core.private.companyintegrations.GetCompanyIntegrationsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.core.private.companyintegrations.GetCompanyIntegrationsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    companyId: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.core.private.companyintegrations.GetCompanyIntegrationsRequest}
 */
proto.core.private.companyintegrations.GetCompanyIntegrationsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.core.private.companyintegrations.GetCompanyIntegrationsRequest;
  return proto.core.private.companyintegrations.GetCompanyIntegrationsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.core.private.companyintegrations.GetCompanyIntegrationsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.core.private.companyintegrations.GetCompanyIntegrationsRequest}
 */
proto.core.private.companyintegrations.GetCompanyIntegrationsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setCompanyId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.core.private.companyintegrations.GetCompanyIntegrationsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.core.private.companyintegrations.GetCompanyIntegrationsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.core.private.companyintegrations.GetCompanyIntegrationsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.core.private.companyintegrations.GetCompanyIntegrationsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCompanyId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
};


/**
 * optional int32 company_id = 1;
 * @return {number}
 */
proto.core.private.companyintegrations.GetCompanyIntegrationsRequest.prototype.getCompanyId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.core.private.companyintegrations.GetCompanyIntegrationsRequest} returns this
 */
proto.core.private.companyintegrations.GetCompanyIntegrationsRequest.prototype.setCompanyId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.core.private.companyintegrations.GetCompanyIntegrationsResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.core.private.companyintegrations.GetCompanyIntegrationsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.core.private.companyintegrations.GetCompanyIntegrationsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.core.private.companyintegrations.GetCompanyIntegrationsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.core.private.companyintegrations.GetCompanyIntegrationsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    integrationsList: jspb.Message.toObjectList(msg.getIntegrationsList(),
    proto.core.private.companyintegrations.CompanyIntegration.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.core.private.companyintegrations.GetCompanyIntegrationsResponse}
 */
proto.core.private.companyintegrations.GetCompanyIntegrationsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.core.private.companyintegrations.GetCompanyIntegrationsResponse;
  return proto.core.private.companyintegrations.GetCompanyIntegrationsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.core.private.companyintegrations.GetCompanyIntegrationsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.core.private.companyintegrations.GetCompanyIntegrationsResponse}
 */
proto.core.private.companyintegrations.GetCompanyIntegrationsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.core.private.companyintegrations.CompanyIntegration;
      reader.readMessage(value,proto.core.private.companyintegrations.CompanyIntegration.deserializeBinaryFromReader);
      msg.addIntegrations(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.core.private.companyintegrations.GetCompanyIntegrationsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.core.private.companyintegrations.GetCompanyIntegrationsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.core.private.companyintegrations.GetCompanyIntegrationsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.core.private.companyintegrations.GetCompanyIntegrationsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getIntegrationsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.core.private.companyintegrations.CompanyIntegration.serializeBinaryToWriter
    );
  }
};


/**
 * repeated CompanyIntegration integrations = 1;
 * @return {!Array<!proto.core.private.companyintegrations.CompanyIntegration>}
 */
proto.core.private.companyintegrations.GetCompanyIntegrationsResponse.prototype.getIntegrationsList = function() {
  return /** @type{!Array<!proto.core.private.companyintegrations.CompanyIntegration>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.core.private.companyintegrations.CompanyIntegration, 1));
};


/**
 * @param {!Array<!proto.core.private.companyintegrations.CompanyIntegration>} value
 * @return {!proto.core.private.companyintegrations.GetCompanyIntegrationsResponse} returns this
*/
proto.core.private.companyintegrations.GetCompanyIntegrationsResponse.prototype.setIntegrationsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.core.private.companyintegrations.CompanyIntegration=} opt_value
 * @param {number=} opt_index
 * @return {!proto.core.private.companyintegrations.CompanyIntegration}
 */
proto.core.private.companyintegrations.GetCompanyIntegrationsResponse.prototype.addIntegrations = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.core.private.companyintegrations.CompanyIntegration, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.core.private.companyintegrations.GetCompanyIntegrationsResponse} returns this
 */
proto.core.private.companyintegrations.GetCompanyIntegrationsResponse.prototype.clearIntegrationsList = function() {
  return this.setIntegrationsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.core.private.companyintegrations.CompanyIntegration.prototype.toObject = function(opt_includeInstance) {
  return proto.core.private.companyintegrations.CompanyIntegration.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.core.private.companyintegrations.CompanyIntegration} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.core.private.companyintegrations.CompanyIntegration.toObject = function(includeInstance, msg) {
  var f, obj = {
    integrationId: (f = msg.getIntegrationId()) && google_protobuf_wrappers_pb.Int32Value.toObject(includeInstance, f),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    isInstalled: jspb.Message.getBooleanFieldWithDefault(msg, 3, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.core.private.companyintegrations.CompanyIntegration}
 */
proto.core.private.companyintegrations.CompanyIntegration.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.core.private.companyintegrations.CompanyIntegration;
  return proto.core.private.companyintegrations.CompanyIntegration.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.core.private.companyintegrations.CompanyIntegration} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.core.private.companyintegrations.CompanyIntegration}
 */
proto.core.private.companyintegrations.CompanyIntegration.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new google_protobuf_wrappers_pb.Int32Value;
      reader.readMessage(value,google_protobuf_wrappers_pb.Int32Value.deserializeBinaryFromReader);
      msg.setIntegrationId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsInstalled(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.core.private.companyintegrations.CompanyIntegration.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.core.private.companyintegrations.CompanyIntegration.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.core.private.companyintegrations.CompanyIntegration} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.core.private.companyintegrations.CompanyIntegration.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getIntegrationId();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      google_protobuf_wrappers_pb.Int32Value.serializeBinaryToWriter
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getIsInstalled();
  if (f) {
    writer.writeBool(
      3,
      f
    );
  }
};


/**
 * optional google.protobuf.Int32Value integration_id = 1;
 * @return {?proto.google.protobuf.Int32Value}
 */
proto.core.private.companyintegrations.CompanyIntegration.prototype.getIntegrationId = function() {
  return /** @type{?proto.google.protobuf.Int32Value} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.Int32Value, 1));
};


/**
 * @param {?proto.google.protobuf.Int32Value|undefined} value
 * @return {!proto.core.private.companyintegrations.CompanyIntegration} returns this
*/
proto.core.private.companyintegrations.CompanyIntegration.prototype.setIntegrationId = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.core.private.companyintegrations.CompanyIntegration} returns this
 */
proto.core.private.companyintegrations.CompanyIntegration.prototype.clearIntegrationId = function() {
  return this.setIntegrationId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.core.private.companyintegrations.CompanyIntegration.prototype.hasIntegrationId = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.core.private.companyintegrations.CompanyIntegration.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.core.private.companyintegrations.CompanyIntegration} returns this
 */
proto.core.private.companyintegrations.CompanyIntegration.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional bool is_installed = 3;
 * @return {boolean}
 */
proto.core.private.companyintegrations.CompanyIntegration.prototype.getIsInstalled = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 3, false));
};


/**
 * @param {boolean} value
 * @return {!proto.core.private.companyintegrations.CompanyIntegration} returns this
 */
proto.core.private.companyintegrations.CompanyIntegration.prototype.setIsInstalled = function(value) {
  return jspb.Message.setProto3BooleanField(this, 3, value);
};


goog.object.extend(exports, proto.core.private.companyintegrations);
