import { BillsbyAction } from "../models/BillsbyAction";
import { AppState } from "..";
import { FETCH_COMPANY_PRODUCTS_ADD_ONS_SUCCESS, SET_LINK_ADD_ONS_ALLOWANCES_FIELDS, SET_SELECTED_COMPANY_ADD_ONS_PAGE, SET_SHOW_SELECT_ADD_ONS_MODAL, SET_SELECTED_ADD_ONS, SET_SELECTED_ADD_ONS_ID, SET_FORCE_ADD_ON, REMOVE_SELECTED_ADD_ON, DESELECT_ALL_SELECTED_ADD_ONS, SELECT_ALL_ADD_ONS, SET_SEARCH_KEY_ADD_ONS_PAGE, CANCEL_SELECTED_ADD_ONS_PAGE, RESET_ADD_ADD_ON_STATE, SET_SELECTED_ADD_ONS_PAGE, SET_UNCOFIGURED_PRICE_MODELS_LIST, FETCH_COMPANY_PRODUCTS_ADD_ONS_REQUEST } from "../actions/linkAddOnsAllowancesActions";
import { paginateItems, getNumberOfDays, divideNumberBy100, toAddOnTierUnit } from "../utils/commonUtils";
import { AddonPriceModel, GetAddonsResponse, Addon, PricingModelType as PricingModelTypeGrpc } from "../utils/grpc/generated/Billsby.Protos/core/private/addons/addons_pb";
import { PricingModelType } from "../models/Product";
import { AllowancePriceModel, GetAllowancesResponse, Allowance } from "../utils/grpc/generated/Billsby.Protos/core/private/allowances/allowances_pb";
import { AddonsAllowancesScreen } from "../models/AddonsAllowances";


export interface ISelectedCompanyElement {
  name: string,
  id: number,
  isForced: boolean,
  isConfigured: boolean,
  addonPricingModelType: PricingModelTypeGrpc
}

export interface ISelectedCompanyAddOn extends ISelectedCompanyElement {
  priceModelList: Array<AddonPriceModel>,
  unconfiguredPriceModelsList: Array<AddonPriceModel>
}

export interface ISelectedCompanyAllowance extends ISelectedCompanyElement {
  priceModelList: Array<AllowancePriceModel>,
  unconfiguredPriceModelsList: Array<AllowancePriceModel>
}

export interface ILinkAddOnsAllowancesReducerState {
  activeTab: AddonsAllowancesScreen,
  companyAddOns: Array<Addon | Allowance>,
  isFetchingCompanyAddOnsSuccess: boolean,
  companyAddOnsFiltered: Array<Addon | Allowance>,
  companyAddOnsDisplay: Array<Addon | Allowance>,
  selectedAddOns: Array<ISelectedCompanyAddOn>,
  selectedAddOnsDisplay: Array<ISelectedCompanyAddOn>,
  selectedAddOnsIds: Array<number>,
  selectedAllowances: Array<ISelectedCompanyAllowance>,
  selectedAllowancesDisplay: Array<ISelectedCompanyAllowance>,
  selectedAllowancesIds: Array<number>,
  numberOfPages: number,
  currentPage: number,
  addOnsNumberOfPages: number,
  addOnsCurrentPage: number,
  addOnPriceModel: AddonPriceModel | AllowancePriceModel | null,
  showSelectAddOnsModal: boolean,
  selectedPricingModel: PricingModelType | null
}

const itemsPerPage = 10;


const calculateNumberOfPages = (items: number) => {
  return items > 0 ? Math.ceil(items / itemsPerPage) : 0;
};

const sortItems = (items: any) => items.sort((a: any, b: any) => a.name > b.name ? 1 : a.name < b.name ? -1 : 0)

export const isPriceModelsConfigured = (priceModel: AddonPriceModel | AllowancePriceModel) => {
  return !!priceModel.getPerUnitPriceFormatted()
    || !!((priceModel as any).getFlatFeePriceFormatted && (priceModel as AddonPriceModel).getFlatFeePriceFormatted())
    || !!((priceModel as any).getIncludedUnits && (priceModel as AllowancePriceModel).getIncludedUnits())
    || priceModel.getTiersList().length > 0
};

const initialState = {
  activeTab: AddonsAllowancesScreen.ALLOWANCES,
  companyAddOns: [],
  isFetchingCompanyAddOnsSuccess: false,
  companyAddOnsFiltered: [],
  companyAddOnsDisplay: [],
  selectedAddOns: [],
  selectedAddOnsDisplay: [],
  selectedAddOnsIds: [],
  selectedAllowances: [],
  selectedAllowancesDisplay: [],
  selectedAllowancesIds: [],
  numberOfPages: 0,
  currentPage: 1,
  addOnsNumberOfPages: 0,
  addOnsCurrentPage: 1,
  addOnPriceModel: null,
  showSelectAddOnsModal: false,
  selectedPricingModel: null
}

export default function linkAddOnsAllowancesReducer(state: ILinkAddOnsAllowancesReducerState = initialState, action: BillsbyAction, store: AppState) {
  switch (action.type) {

    case SET_LINK_ADD_ONS_ALLOWANCES_FIELDS: {
      return { ...state, [action.fieldName]: action.fieldValue }
    }

    case FETCH_COMPANY_PRODUCTS_ADD_ONS_REQUEST:
      return { ...state, isFetchingCompanyAddOnsSuccess: false }

    case FETCH_COMPANY_PRODUCTS_ADD_ONS_SUCCESS: {
      const response = action.response as GetAddonsResponse | GetAllowancesResponse;
      const companyAddOns = Array<Addon | Allowance>();
      const isAddonScreen = action.fetchType === AddonsAllowancesScreen.ADDONS;

      const dividePriceBy100 = (addOn: Addon | Allowance) => {
        const priceModels = (addOn.getPriceModelsList() as Array<AddonPriceModel | AllowancePriceModel>)
          .map((pricingModel: AddonPriceModel | AllowancePriceModel) => {
            if (isAddonScreen) {
              (pricingModel as AddonPriceModel).setFlatFeePrice(divideNumberBy100((pricingModel as AddonPriceModel).getFlatFeePrice()));
              (pricingModel as AddonPriceModel).setPerUnitPrice(divideNumberBy100((pricingModel as AddonPriceModel).getPerUnitPrice()));
            }
            else {
              (pricingModel as AllowancePriceModel).setPerUnitPrice(divideNumberBy100((pricingModel as AllowancePriceModel).getPerUnitPrice()) || "");
            }
            
            pricingModel.setTiersList(pricingModel.getTiersList().map(tier => {
              tier.setPrice(tier.getPrice() / 100);
              return toAddOnTierUnit(tier);
            }))
            return pricingModel;
          })
        addOn.setPriceModelsList(priceModels as Array<any>);
        return addOn;
      }

      if (isAddonScreen) {
        (response as GetAddonsResponse).getAddonsList().forEach(addOn => {
          const _addOn = dividePriceBy100(addOn);
          companyAddOns.push(_addOn)
        })
      }
      else {
        (response as GetAllowancesResponse).getAllowancesList().forEach(allowance => {
          const _allowance = dividePriceBy100(allowance);
          companyAddOns.push(_allowance)
        })
      }

      return {
        ...state,
        companyAddOns,
        isFetchingCompanyAddOnsSuccess: true,
        activeTab: action.fetchType,
        companyAddOnsFiltered: companyAddOns.sort((a, b) => 
          a.getName().toLowerCase().localeCompare(b.getName().toLowerCase())),
        addOnsNumberOfPages: calculateNumberOfPages(companyAddOns.length),
        addOnsCurrentPage: 1,
        companyAddOnsDisplay: paginateItems(companyAddOns, 1)
      }
    }

    case SET_SHOW_SELECT_ADD_ONS_MODAL: {
      const isAddonScreen = state.activeTab === AddonsAllowancesScreen.ADDONS;
      const target: Array<ISelectedCompanyElement> = isAddonScreen ? state.selectedAddOns : state.selectedAllowances;
      const newArray = target.map(i => i.id);

      return { ...state, showSelectAddOnsModal: action.payload, ...{ [isAddonScreen ? "selectedAddOnsIds" : "selectedAllowancesIds"]: newArray } }
    }

    case SET_SELECTED_ADD_ONS: {
      const isAddonScreen = state.activeTab === AddonsAllowancesScreen.ADDONS;
      let newArray: Array<ISelectedCompanyAddOn | ISelectedCompanyAllowance> = isAddonScreen ? state.selectedAddOns : state.selectedAllowances;
      let addOns = [...state.companyAddOns];
      const ids = action.payload as Array<number>;
      newArray = newArray.filter(addOn => ids.find(id => id === addOn.id));

      ids.forEach((i, idx) => {
        const index = newArray.findIndex((item) => item.id === i);
        if (index < 0) {
          const addOn = addOns.find((p) => p.getId() === i);
          if (addOn) {

            newArray.push({
              name: addOn.getName(),
              id: addOn.getId(),
              isConfigured: false,
              isForced: false,
              priceModelList: addOn.getPriceModelsList() as Array<any>,
              addonPricingModelType: addOn.getPricingModelType(),
              unconfiguredPriceModelsList: []
            });
          }

        }
      });

      newArray = sortItems(newArray);
      let selectedElementData = {};
      if (isAddonScreen) {
        selectedElementData = {
          selectedAddOns: newArray,
          selectedAddOnsDisplay: paginateItems(newArray, 1)
        }
      }
      else {
        selectedElementData = {
          selectedAllowances: newArray,
          selectedAllowancesDisplay: paginateItems(newArray, 1)
        }
      }

      return {
        ...state,
        ...selectedElementData,
        numberOfPages: calculateNumberOfPages(newArray.length),
        currentPage: 1
      }
    }

    case SET_SELECTED_ADD_ONS_ID: {
      const isAddonScreen = state.activeTab === AddonsAllowancesScreen.ADDONS;
      let newArray = isAddonScreen ? state.selectedAddOnsIds : state.selectedAllowancesIds;
      const index = newArray.findIndex((item) => item === action.payload);
      if (index >= 0) {
        newArray.splice(index, 1);
      } else {
        newArray.push(action.payload);
      }

      return { ...state, [isAddonScreen ? "selectedAddOnsIds" : "selectedAllowancesIds"]: newArray };
    }

    case SET_FORCE_ADD_ON: {
      const isAddonScreen = state.activeTab === AddonsAllowancesScreen.ADDONS;
      let newArray: Array<ISelectedCompanyElement> = isAddonScreen ? state.selectedAddOns : state.selectedAllowances;
      const index = newArray.findIndex((item) => item.id === action.payload);

      if (index >= 0) {
        newArray[index].isForced = !newArray[index].isForced;
      }

      let selectedElementData = {}
      if (isAddonScreen) {
        selectedElementData = {
          selectedAddOns: newArray,
          selectedAddOnsDisplay: paginateItems(newArray, state.currentPage)
        }
      }
      else {
        selectedElementData = {
          selectedAllowances: newArray,
          selectedAllowancesDisplay: paginateItems(newArray, state.currentPage)
        }
      }

      return { ...state, ...selectedElementData };
    }

    case REMOVE_SELECTED_ADD_ON: {
      const isAddonScreen = state.activeTab === AddonsAllowancesScreen.ADDONS;
      let newArray: Array<ISelectedCompanyElement> = isAddonScreen ? state.selectedAddOns : state.selectedAllowances;
      const index = newArray.findIndex((item) => item.id === action.payload);

      if (index >= 0) {
        newArray.splice(index, 1);
      }
      const ids = newArray.map(addOn => addOn.id);
      const numberOfPages = calculateNumberOfPages(newArray.length);
      const currentPage = state.currentPage > numberOfPages ? numberOfPages : state.currentPage;

      let selectedElementData = {}
      if (isAddonScreen) {
        selectedElementData = {
          selectedAddOnsIds: ids,
          selectedAddOns: newArray,
          selectedAddOnsDisplay: paginateItems(newArray, state.currentPage)
        }
      }
      else {
        selectedElementData = {
          selectedAllowancesIds: ids,
          selectedAllowances: newArray,
          selectedAllowancesDisplay: paginateItems(newArray, state.currentPage)
        }
      }

      return {
        ...state,
        ...selectedElementData,
        numberOfPages,
        currentPage
      };
    }

    case DESELECT_ALL_SELECTED_ADD_ONS: {
      const isAddonScreen = state.activeTab === AddonsAllowancesScreen.ADDONS;
      let newArray = isAddonScreen ? state.selectedAddOnsIds : state.selectedAllowancesIds;
      if (state.companyAddOns.length !== state.companyAddOnsFiltered.length) {
        state.companyAddOnsFiltered.forEach((i) => {
          const index = newArray.findIndex((item) => item === i.getId());
          if (index >= 0) {
            newArray.splice(index, 1);
          }
        });
        const index = newArray.findIndex((item) => item === action.payload);
        if (index >= 0) {
          newArray.splice(index, 1);
        } else {
          newArray.push(action.payload);
        }
      } else {
        newArray = new Array<number>();
      }
      return { ...state, [isAddonScreen ? "selectedAddOnsIds" : "selectedAllowancesIds"]: newArray };
    }


    case SELECT_ALL_ADD_ONS: {
      const isAddonScreen = state.activeTab === AddonsAllowancesScreen.ADDONS;
      const newArray = state.companyAddOnsFiltered.map((i) => i.getId());
      return { ...state, [isAddonScreen ? "selectedAddOnsIds" : "selectedAllowancesIds"]: newArray };
    }

    case SET_SELECTED_ADD_ONS_PAGE: {
      const isAddonScreen = state.activeTab === AddonsAllowancesScreen.ADDONS;
      let selectedElementData = {}
      if (isAddonScreen) {
        selectedElementData = { selectedAddOnsDisplay: paginateItems(state.selectedAddOns, action.payload) }
      }
      else {
        selectedElementData = { selectedAllowancesDisplay: paginateItems(state.selectedAllowances, action.payload) }
      }
      return {
        ...state,
        currentPage: action.payload,
        ...selectedElementData
      };
    }

    case SET_SELECTED_COMPANY_ADD_ONS_PAGE: {
      return {
        ...state,
        addOnsCurrentPage: action.payload,
        companyAddOnsDisplay: paginateItems(
          state.companyAddOnsFiltered,
          action.payload
        ),
      };
    }

    case SET_SEARCH_KEY_ADD_ONS_PAGE: {
      const newArray = state.companyAddOns.filter(
        (i) =>
          i.getName()
            .toLocaleLowerCase()
            .includes(action.payload.toLocaleLowerCase()) ||
          i.getSingleUnitName()
            .toLocaleLowerCase()
            .includes(action.payload.toLocaleLowerCase())
      );

      return {
        ...state,
        companyAddOnsFiltered: newArray,
        addOnsNumberOfPages: calculateNumberOfPages(newArray.length),
        plansCurrentPage: 1,
        companyAddOnsDisplay: paginateItems(newArray, 1),
      };
    }

    case SET_UNCOFIGURED_PRICE_MODELS_LIST: {
      // const isAddonScreen = state.activeTab === AddonsAllowancesScreen.ADDONS;
      const newPriceModels = action.payload as { id: number, arr: Array<AddonPriceModel | AllowancePriceModel> };
      const sortedPriceModels = newPriceModels.arr.sort((a, b) => {
        return getNumberOfDays(a) - getNumberOfDays(b)
      })

      const updateSelectedAddonsAllowances = (prop: "selectedAddOns" | "selectedAddOnsDisplay" | "selectedAllowances" | "selectedAllowancesDisplay") => {
        return ({
          [prop]: (state[prop] as Array<ISelectedCompanyElement>).map(addon => {
            if (addon.id !== newPriceModels.id) {
              return addon;
            }

            return { ...addon, isConfigured: sortedPriceModels.every(pm => isPriceModelsConfigured(pm)), unconfiguredPriceModelsList: [...sortedPriceModels] }
          })
        })
      }


      return {
        ...state,
        ...updateSelectedAddonsAllowances("selectedAddOns"),
        ...updateSelectedAddonsAllowances("selectedAddOnsDisplay"),
        ...updateSelectedAddonsAllowances("selectedAllowances"),
        ...updateSelectedAddonsAllowances("selectedAllowancesDisplay"),
      }


      //HIDE FOR NOW
      // return {
      //   ...state,
      //   [isAddonScreen ? "selectedAddOns" : "selectedAllowances"]:
      //     (state[isAddonScreen ? "selectedAddOns" : "selectedAllowances"] as Array<ISelectedCompanyElement>).map((addon) => {
      //       if (addon.id !== newPriceModels.id) {
      //         return addon;
      //       }

      //       return { ...addon, isConfigured: sortedPriceModels.every(pm => isPriceModelsConfigured(pm)), unconfiguredPriceModelsList: [...sortedPriceModels] }
      //     }),
      //   [isAddonScreen ? "selectedAddOnsDisplay" : "selectedAllowancesDisplay"]:
      //     (state[isAddonScreen ? "selectedAddOnsDisplay" : "selectedAllowancesDisplay"] as Array<ISelectedCompanyElement>).map((addon) => {
      //       if (addon.id !== newPriceModels.id) {
      //         return addon;
      //       }
      //       return { ...addon, isConfigured: sortedPriceModels.every(pm => isPriceModelsConfigured(pm)), unconfiguredPriceModelsList: [...sortedPriceModels] }
      //     })
      // }
    }

    case CANCEL_SELECTED_ADD_ONS_PAGE: {
      const isAddonScreen = state.activeTab === AddonsAllowancesScreen.ADDONS;
      const newArray: Array<number> = (state[isAddonScreen ? "selectedAddOns" : "selectedAllowances"] as Array<ISelectedCompanyElement>)
        .map(i => i.id);
      return { ...state, [isAddonScreen ? "selectedAddOnsIds" : "selectedAllowancesIds"]: newArray };
    }

    case RESET_ADD_ADD_ON_STATE: {
      return initialState;
    }

    default:
      return state;
  }
}