import React, { useEffect, useState } from "react";
import history from "../../../../../utils/history";
import Panel from "../../../../ui/panel/Panel";
import Text from "../../../../ui/text/Text";
import "./NewSubscriptionWithTrialEmailTemplate.scss";
import { AppState } from "../../../../..";
import { useDispatch, useSelector } from "react-redux";
import { IAuthReducerState } from "../../../../../reducers/authReducer";
import { ICompanyBrandingReducerState } from "../../../../../reducers/companyBrandingReducer";
import { Row, Col } from "react-grid-system";
import EmailPreivewContainer from "../../brand-customization/email-preview/EmailPreivewContainer";
import BackNavigationPanel from "../../../../ui/back-navigation-panel/BackNavigationPanel";
import { IEmailCustomizationReducerState } from "../../../../../reducers/emailCustomizationReducer";
import { resetEmailTemplateValues, getEmailTemplateValues } from "../../../../../actions/emailCustomizationActions";
import { fetchCompanyBranding } from "../../../../../actions/companyBrandingActions";
import EmailFooter from "../email-footer/EmailFooter";
import EmailEditableArea from "../email-editable-area/EmailEditableArea";
import { EmailTypes } from "../../../../../utils/grpc/generated/Billsby.Protos/Messenger/private/emailconfig/emailconfig_pb";
import { PricingDisclosureServiceClient } from "../../../../../utils/grpc/generated/Billsby.Protos/billing/public/company/pricing_disclosure/Pricing_disclosureServiceClientPb";
import { ConfigConstants } from "../../../../../utils/config";
import { GetMockedPricingDisclosureRequest, GetMockedPricingDisclosureResponse } from "../../../../../utils/grpc/generated/Billsby.Protos/billing/public/company/pricing_disclosure/pricing_disclosure_pb";
import { grpcUnaryCall } from "../../../../../utils/grpc/grpcUtils";
import PricingInfoDisclosure from "../../../../pricing-info-disclosure/PricingInfoDisclosure";


const NewSubscriptionWithTrialEmailTemplate: React.FC = () => {

  const dispatch = useDispatch<Function>();
  const authReducer = useSelector<AppState, IAuthReducerState>(state => state.auth);
  const nswte_companyBrandingReducer = useSelector<AppState, ICompanyBrandingReducerState>(state => state.companyBrandingReducer);
  const nswte_emailCustomizationReducer = useSelector<AppState, IEmailCustomizationReducerState>(state => state.emailCustomizationReducer);
  const { customArea1, customArea2 } = nswte_emailCustomizationReducer;
  const { currentCompanyId, companyName, currentCompanyDomain } = authReducer;
  const [pricingDisclosure, setPricingDisclosure] = useState("")

  useEffect(() => {
    const fetchData = async () => {
      try {
        dispatch(fetchCompanyBranding(currentCompanyDomain));
        dispatch(getEmailTemplateValues(currentCompanyId as number, companyName, EmailTypes.NEW_TRIAL_SUBSCRIPTION));
        const pricingDisclosureService = new PricingDisclosureServiceClient(ConfigConstants.grpcBaseUrl)
        const getMockPricingDisclosure = new GetMockedPricingDisclosureRequest();
        getMockPricingDisclosure.setFreeTrial(true)
        const pricingDisclosure = await grpcUnaryCall(getMockPricingDisclosure, pricingDisclosureService, pricingDisclosureService.getMockedPricingDisclosure) as GetMockedPricingDisclosureResponse
        setPricingDisclosure(pricingDisclosure.getPricingDisclosure())
      }
      catch(err) {}
    }
    fetchData()
    return () => dispatch(resetEmailTemplateValues()) 
  }, [])

  return (
    <div id="NewSubscriptionWithTrialEmailTemplate-wrapper">
      <BackNavigationPanel
        title={"EMAIL_TEMPLATE_NEW_SUBSCRIPTION_WITH_TRIAL_BACK_PANEL_TITLE"} shouldTranslateTitle={true}
        onClick={() => history.push("/configuration/email-notifications/emails/welcome-emails")} />

      <Row>
        <Col sm={8}>
          <Panel className="new-subscription-with-trial-email-template__panel" noMargin>
            <EmailPreivewContainer>

              <Text
                content={<span dangerouslySetInnerHTML={{__html: customArea1}}></span>}
                shouldTranslate={false}
                className="email-preview__details__text email-preview__details__text-title bar bar-blue"
                component="span"
                noMargin
              />

              <Text
                content="EMAIL_TEMPLATE_NEW_SUBSCRIPTION_WITH_TRIAL_GREETINGS"
                className="email-preview__details__text"
                component="span"
                noMargin
              />

              <Text
                content={<span dangerouslySetInnerHTML={{__html: customArea2}}></span>}
                shouldTranslate={false}
                className="email-preview__details__text bar bar-orange"
                component="span"
                noMargin
              />

              <Text
                content="EMAIL_TEMPLATE_NEW_SUBSCRIPTION_WITH_TRIAL_BODY_1"
                className="email-preview__details__text email-preview__details__text-bold"
                component="span"
                noMargin
              />


              <PricingInfoDisclosure rawHTMLEncoded={pricingDisclosure} className="new-subscription-with-trial-email-template__pricing-disclosure"  />


              {/*<Text
                content="EMAIL_TEMPLATE_NEW_SUBSCRIPTION_WITH_TRIAL_BODY_2"
                className="email-preview__details__text"
                component="span"
                noMargin
              />*/}

              <Text
                content="EMAIL_TEMPLATE_NEW_SUBSCRIPTION_WITH_TRIAL_REAGRDS"
                className="email-preview__details__text new-subscription-with-trial-email-template__regards"
                component="span"
                noMargin
              />

              <Text
                content={companyName}
                className="email-preview__details__text"
                component="span"
                shouldTranslate={false}
                noMargin
              />

              <EmailFooter />

              


            </EmailPreivewContainer>
          </Panel>
        </Col>
        <Col sm={4}>
          <EmailEditableArea
            id="NewSubscriptionWithTrialEmailTemplate"
            emailCustomCopyOneValue={customArea1}
            emailCustomCopyTwoValue={customArea2}
            emailCustomCopyThreeValue=""
            emailCustomCopyThreeIsVisible={false}
            showCompanyAlertToggle={true}
            showProductAlertToggle={true}
            showPlanAlertToggle={true}
          />
        </Col>
      </Row>
    </div>
  )
}

export default NewSubscriptionWithTrialEmailTemplate