import { BillsbyAction } from "../models/BillsbyAction";
import { AppState } from "..";
import { GetCompanyIntegrationsResponse } from "../utils/grpc/generated/Billsby.Protos/core/private/companyintegrations/companyintegrations_pb";
import { CANCEL_ACCOUNT_INTEGRTIONS_CHANGES, CREATE_INTEGRATION_FAILURE, CREATE_INTEGRATION_REQUEST, CREATE_INTEGRATION_SUCCESS, DELETE_ACCOUNTING_INTEGRATION_FAILURE, DELETE_ACCOUNTING_INTEGRATION_SUCCESS, FETCH_ACCOUNTING_INTEGRATIONS_SUCCESS, FETCH_COMPANY_QUICK_BOOKS_ACCOUNTS_SUCCESS, FETCH_QUICK_BOOKS_ACCOUNTS_SUCCESS, SET_ACCOUNTING_INTEGRATIONS_FIELD } from "../actions/accountingIntegrationsActions";
import { CreateFreeAgentIntegrationResponse, CreateQuickBooksIntegrationResponse, GetCompanyQuickBooksAccountsResponse, GetQuickBooksAccountsResponse, QuickBooksAccount } from "../utils/grpc/generated/Billsby.Protos/accountingIntegrations/private/accounts/accounts_pb";
import counterpart from "counterpart";
import { AccountingIntegrationType, AccountType } from "../models/AccountingIntegrations";

export interface IAccountingIntegrationsReducerState {
  integrations: GetCompanyIntegrationsResponse;
  invoicesOptions: Array<{ label: string, value: string }>;
  creditNotesOptions: Array<{ label: string, value: string }>;
  refundsOptions: Array<{ label: string, value: string }>;
  selectedInvoice?: { label: string, value: string };
  selectedCreditNote?: { label: string, value: string };
  selectedRefund?: { label: string, value: string };
  selectedBadDebt?: { label: string, value: string };
  invoice?: { label: string, value: string };
  creditNote?: { label: string, value: string };
  refund?: { label: string, value: string };
  companyQuickBooksAccounts: GetCompanyQuickBooksAccountsResponse;
  isQuickbooksUnderSetup: boolean;
  invoiceIsDisconnected: boolean;
  creditNoteIsDisconnected: boolean;
  refundIsDisconnected: boolean;
  hasQuickbooksError: boolean;

  isCreatingIntegrationRequest: boolean;
  isCreatingIntegrationSuccess: boolean;
  isCreatingIntegrationFailure: boolean;
  isDeleteIntegrationSuccess: boolean; 
  isDeleteIntegrationFailure: boolean;
};

export const accountingIntegrationsInitState: IAccountingIntegrationsReducerState = {
  integrations: new GetCompanyIntegrationsResponse(),
  invoicesOptions: [],
  creditNotesOptions: [],
  refundsOptions: [],
  selectedInvoice: undefined,
  selectedCreditNote: undefined,
  selectedRefund: undefined,
  selectedBadDebt: undefined,
  invoice: undefined,
  creditNote: undefined,
  refund: undefined,
  invoiceIsDisconnected: false,
  creditNoteIsDisconnected: false,
  refundIsDisconnected: false,
  hasQuickbooksError: false,
  companyQuickBooksAccounts: new GetCompanyQuickBooksAccountsResponse(),
  isQuickbooksUnderSetup: false,

  isCreatingIntegrationRequest: false,
  isCreatingIntegrationSuccess: false,
  isCreatingIntegrationFailure: false,

  isDeleteIntegrationSuccess: false, 
  isDeleteIntegrationFailure: false
}

export default function accountingIntegrationsReducer(state: IAccountingIntegrationsReducerState = accountingIntegrationsInitState, action: BillsbyAction, store: AppState) {
  switch (action.type) {
    case FETCH_ACCOUNTING_INTEGRATIONS_SUCCESS: {
      const integrations = action.response as GetCompanyIntegrationsResponse;
      return { ...state, integrations }
    }
    case FETCH_QUICK_BOOKS_ACCOUNTS_SUCCESS: {
      const data = action.response as GetQuickBooksAccountsResponse;
      const emptyOption = { label: counterpart("INTEGRATION_CREATE_NEW_ACCOUNT"), value: "" }
      const accountListOptions = [...data.getAccountsList()
        .filter((i: QuickBooksAccount) => i.getAccountType() === AccountType.OTHERCURRENTASSET || i.getAccountType() === AccountType.BANK)
        .sort((a, b) => {
          return a.getName().toLowerCase() > b.getName().toLowerCase() ? 1 : -1;
        })
        .map(i => ({ label: i.getName(), value: i.getQuickBooksAccountId().toString() }))];

      const invoicesOptions = [emptyOption, ...accountListOptions];
      const creditNotesOptions = [emptyOption, ...accountListOptions];
      const  refundsOptions = [emptyOption, ...accountListOptions];

      return { ...state, invoicesOptions, creditNotesOptions, refundsOptions }
    }
    case FETCH_COMPANY_QUICK_BOOKS_ACCOUNTS_SUCCESS: {
      const data = action.response as GetCompanyQuickBooksAccountsResponse;
      const emptyOption = { label: counterpart("INTEGRATION_CREATE_NEW_ACCOUNT"), value: "" }
      const invoiceValues = state.invoicesOptions.filter(i => i.value === data.getInvoicesAccountId());
      const creditNoteValues = state.creditNotesOptions.filter(i => i.value === data.getCreditNotesAccountId());
      const refundValues = state.refundsOptions.filter(i => i.value === data.getRefundsAccountId());
      const selectedInvoice = invoiceValues.length > 0 ? invoiceValues[0] : emptyOption;
      const selectedCreditNote = creditNoteValues.length > 0 ? creditNoteValues[0] : emptyOption;
      const selectedRefund = refundValues.length > 0 ? refundValues[0] : emptyOption;
      const invoiceIsDisconnected = invoiceValues.length < 1;
      const creditNoteIsDisconnected = creditNoteValues.length < 1;
      const refundIsDisconnected = refundValues.length < 1;

      let hasQuickbooksError = false;
      const quickbooksIntegration = state.integrations.getIntegrationsList().find(i => i.getName() === AccountingIntegrationType.QUICKBOOKS && i.getIsInstalled());

      if(quickbooksIntegration && !state.isQuickbooksUnderSetup) {
        hasQuickbooksError = invoiceIsDisconnected || creditNoteIsDisconnected || refundIsDisconnected;
      }

      return { ...state, companyQuickBooksAccounts: data, selectedInvoice, selectedCreditNote, selectedRefund,
        invoice: selectedInvoice, creditNote: selectedCreditNote, refund: selectedRefund,
        invoiceIsDisconnected, creditNoteIsDisconnected, refundIsDisconnected, hasQuickbooksError }
    }
    case SET_ACCOUNTING_INTEGRATIONS_FIELD: {
      return { ...state, [action.fieldName]: action.fieldValue }
    }
    case CANCEL_ACCOUNT_INTEGRTIONS_CHANGES: {
      const { invoice, creditNote, refund } = state;
      return { ...state, selectedInvoice: invoice, selectedCreditNote: creditNote, selectedRefund: refund }
    }

    case CREATE_INTEGRATION_REQUEST: {
      return { ...state, isCreatingIntegrationRequest: true, isCreatingIntegrationSuccess: false, isCreatingIntegrationFailure: false }
    }
    case CREATE_INTEGRATION_SUCCESS: {
      const response = action.response as CreateQuickBooksIntegrationResponse || CreateFreeAgentIntegrationResponse
      return { ...state, isCreatingIntegrationRequest: false, isCreatingIntegrationSuccess: response.getIsSuccess(), isCreatingIntegrationFailure: !response.getIsSuccess() }
    }
    case CREATE_INTEGRATION_FAILURE: {
      return { ...state, isCreatingIntegrationRequest: false, isCreatingIntegrationSuccess: false, isCreatingIntegrationFailure: true }
    }
    case DELETE_ACCOUNTING_INTEGRATION_SUCCESS:
      return { ...state, isDeleteIntegrationSuccess: true, isDeleteIntegrationFailure: false }
    case DELETE_ACCOUNTING_INTEGRATION_FAILURE:
      return { ...state, isDeleteIntegrationSuccess: false, isDeleteIntegrationFailure: true }
  

    default: return state
  }
}