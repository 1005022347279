import React, { useState, useEffect } from "react";
import Table from "../../../components/ui/table/Table";
import PageWrapper from "../../../components/ui/layout/PageWrapper";
import NavigationAlert from "../../../components/ui/navigation-alert/NavigationAlert";
import counterpart from "counterpart";
import NavigationBar from "../../../components/ui/navigation-bar/NavigationBar";
import { useHistory} from "react-router-dom";
import Text from "../../../components/ui/text/Text";
import "./ActivitySummary.scss";
import Switch from "../../../components/ui/switch/Switch";
import Button from "../../../components/ui/button/Button";
import Panel from "../../../components/ui/panel/Panel";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../../..";
import { setActivitySummaryField, fetchActivitySummary, resetActivitySummary } from "../../../actions/activitySummaryActions";
import moment from "moment";
import { downloadCSV } from "../../../utils/commonUtils";
import LoadingModal from "../../../components/modal/loading-modal/LoadingModal";
import MonthPicker from "../../../components/ui/month-picker/MonthPicker";
import ProgressIndicator from "../../../components/ui/progress-indicator/ProgressIndicator";

const ActivitySummary: React.FC = () => {
  const activitySummary = useSelector((state: AppState) => state.activitySummaryReducer);
  const auth = useSelector((state: AppState) => state.auth);

  const dispatch = useDispatch<Function>();
  const history = useHistory();

  const [isLoading, setIsLoading] = useState(false);
  const [isCreatingCSV, setIsCreatingCSV] = useState(false);
  const { startDate, endDate, includeCurrentMonthToDate, showAfterTax, details, hasCurrentMonth, headerMessage } = activitySummary;
  const dateFormat = "MMM YYYY";
  const defaultStartDate = moment.utc()
    .subtract(3, "month")
    .format(dateFormat);
  const defaultEndDate = moment.utc()
    .subtract(1, "month")
    .format(dateFormat);
  
  const tableHead = [
    "ACTIVITY_SUMMARY_MONTH",
    "ACTIVITY_SUMMARY_SALES1",
    "ACTIVITY_SUMMARY_SALES2",
    "ACTIVITY_SUMMARY_DISCOUNT1",
    "ACTIVITY_SUMMARY_DISCOUNT2",
    "ACTIVITY_SUMMARY_TAX1",
    "ACTIVITY_SUMMARY_TAX2",
    "ACTIVITY_SUMMARY_WRITEOFF1",
    "ACTIVITY_SUMMARY_WRITEOFF2",
    "ACTIVITY_SUMMARY_PAYMENT",
    "ACTIVITY_SUMMARY_REFUNDS",
    "ACTIVITY_SUMMARY_REVENUE",
    "ACTIVITY_SUMMARY_REVENUE_RECOGNITION",
    "ACTIVITY_SUMMARY_DEFERRED_REVENUE",
  ];

  const fetchData = async (_startDate: string = defaultStartDate, _endDate: string = defaultEndDate) => {
    if (!auth.currentCompanyId) {
      return;
    }

    const withCurrentMonth = includeCurrentMonthToDate && _endDate === defaultEndDate;
    const endDateToFetch = withCurrentMonth
      ? moment.utc()
          .subtract(0, "month")
          .format(dateFormat)
      : _endDate;

    setIsLoading(true);

    try {
      await dispatch(fetchActivitySummary(auth.currentCompanyId, showAfterTax, _startDate, endDateToFetch));
      dispatch(setActivitySummaryField("hasCurrentMonth", withCurrentMonth));
      dispatch(
        setActivitySummaryField(
          "headerMessage",
          counterpart("ACTIVITY_SUMMARY_ALERT_TITLE", { startDate: _startDate, endDate: endDateToFetch, toDate: withCurrentMonth ? counterpart("ACTIVITY_SUMMARY_TO_DATE") : "" })
        )
      );
      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
      console.log(err);
    }
  };

  useEffect(() => {
    fetchData();
    dispatch(setActivitySummaryField("startDate", defaultStartDate));
    dispatch(setActivitySummaryField("endDate", defaultEndDate));

    return () => dispatch(resetActivitySummary());
  }, []);

  useEffect(() => {
    if (moment.utc(endDate, dateFormat).isBefore(startDate)) {
      dispatch(setActivitySummaryField("endDate", startDate));
    }
  }, [endDate, startDate]);

  const renderHeader = () => {
    const maxDate = moment.utc().subtract(1, "month").format(dateFormat);
    return (
      <>
        <NavigationBar previousPageTitle="NAVBAR_ACCOUNTING" shouldTranslatePageTitle={false} previousPageCb={() => history.push("/report-accounting")} pageTitle={""} />
        <div className="navigation-alert-wrapper">
          <NavigationAlert
            title={!isLoading ? headerMessage : ""}
            className=""
            shouldTranslateTitle={false}
            type="default"
            subTitle=""
            icon="fal fa-file-download"
            buttonLabel="SUMMARY_BUTTON_CSV"
            buttonDisabled={!details || isLoading}
            onClick={handleDownloadCSV}
            isVisible={true}
          />
        </div>

        <div className="activity-summary__header">
          <div className="activity-summary__filter">
            <MonthPicker className="activity-summary__start-date" value={startDate} maxDate={maxDate} onChange={value => dispatch(setActivitySummaryField("startDate", value))}></MonthPicker>
            <Text content="ACTIVITY_SUMMARY_TO" className="with-side-margin" component="span" noMargin></Text>
            <MonthPicker className="activity-summary__end-date" value={endDate} minDate={startDate} maxDate={maxDate} onChange={value => dispatch(setActivitySummaryField("endDate", value))}></MonthPicker>
            {defaultEndDate === endDate && (
              <>
                <span className="divider divider--vertical with-side-margin"></span>
                <Switch
                  id="include-current-month"
                  checked={includeCurrentMonthToDate}
                  onChange={() => dispatch(setActivitySummaryField("includeCurrentMonthToDate", !includeCurrentMonthToDate))}
                ></Switch>
                <Text content="ACTIVITY_SUMMARY_SWITCH1_LABEL" component="span" noMargin></Text>
              </>
            )}

            <span className="divider divider--vertical with-side-margin"></span>
            <Switch id="amounts-after-tax" checked={showAfterTax} onChange={() => dispatch(setActivitySummaryField("showAfterTax", !showAfterTax))}></Switch>
            <Text content="ACTIVITY_SUMMARY_SWITCH2_LABEL" component="span" noMargin></Text>
            <Button isLoading={isLoading} id="run-report" icon="fas fa-sync-alt" title="SUMMARY_BUTTON_REPORT" onClick={() => fetchData(startDate, endDate)}></Button>
          </div>
        </div>
      </>
    );
  };


  const renderTable = () => {
    if (!details || isLoading) {
      return <ProgressIndicator color="blue"></ProgressIndicator>;
    }

    return (
      <Table className="activity-summary__table" scrollable fixedColWidths={[200]}>
        <thead>
          <tr>
            {tableHead.map(head => (
              <Text key={head} component="th" content={head}></Text>
            ))}
          </tr>
        </thead>
        <tbody>
          {details.getResultsList().map((data, idx) => {
            const date = data.getDate();
            const isToDate = hasCurrentMonth && idx + 1 === details.getResultsList().length;
            return (
              <tr key={idx}>
                <Text
                  component="td"
                  shouldTranslate={false}
                  content={date ? `${moment.utc(moment.unix(date.getSeconds())).format("MMMM YYYY")} ${isToDate ? counterpart("ACTIVITY_SUMMARY_TO_DATE") : ""}` : ""}
                ></Text>
                <Text component="td" shouldTranslate={false} content={data.getSalesFormatted() || "-" }></Text>
                <Text component="td" shouldTranslate={false} content={data.getSalesRevFormatted() || "-" }></Text>
                <Text component="td" shouldTranslate={false} content={data.getDiscountFormatted() || "-" }></Text>
                <Text component="td" shouldTranslate={false} content={data.getDiscountRevFormatted() || "-" }></Text>
                <Text component="td" shouldTranslate={false} content={data.getTaxFormatted() || "-" }></Text>
                <Text component="td" shouldTranslate={false} content={data.getTaxRevFormatted() || "-" }></Text>
                <Text component="td" shouldTranslate={false} content={data.getWrittenOffFormatted() || "-" }></Text>
                <Text component="td" shouldTranslate={false} content={data.getWrittenOffRevFormatted() || "-" }></Text>
                <Text component="td" shouldTranslate={false} content={data.getPaymentFormatted() || "-" }></Text>
                <Text component="td" shouldTranslate={false} content={data.getRefundsFormatted() || "-" }></Text>
                <Text component="td" shouldTranslate={false} content={data.getRevenueFormatted() || "-" }></Text>
                <Text component="td" shouldTranslate={false} content={data.getRecognisedRevenueFormatted() || "-"}></Text>
                <Text component="td" shouldTranslate={false} content={data.getDeferredRevenueFormatted() || "-" }></Text>
              </tr>
            );
          })}
        </tbody>
      </Table>
    );
  };

  const handleDownloadCSV = () => {
    if (!details || isLoading) {
      return null;
    }

    setIsCreatingCSV(true);
    setTimeout(() => {
      downloadCSV(".cc-table", headerMessage);
      setIsCreatingCSV(false);
    }, 500);
  };

  return (
    <div className="activity-summary">
      {renderHeader()}
      <PageWrapper>
        {renderTable()}
        {!isLoading && (
          <Panel>
            <Text content="ACTIVITY_SUMMARY_REV" component="span" className="bold"></Text>
            <Text content="ACTIVITY_SUMMARY_REV_MEANING" component="span"></Text>
          </Panel>
        )}
      </PageWrapper>
      <LoadingModal isOpen={isCreatingCSV} title={counterpart("DOWNLOADING_CSV_ACTIVITY_SUMMARY")} subTitle={""} shouldTranslateTitle={false} shouldTranslateSubTitle={false} />
    </div>
  );
};

export default ActivitySummary;