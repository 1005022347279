import * as React from "react";
import "./CodePanel.scss"
import Panel from "../panel/Panel";
import Button from "../button/Button";
import { ReactElement, useState } from "react";
import { debounce, copyToClipboard } from "../../../utils/commonUtils";
import jsb from "js-beautify";

export interface ICodePanelProps {
  id: string
  children: ReactElement,
  codeStr: string,
  noMargin?: boolean,
  makeBeauty?: boolean
}


const CodePanel: React.FC<ICodePanelProps> = ({ id, children, codeStr, noMargin, makeBeauty }) => {
  const [isCopied, setIsCopied] = useState(false);

  const changeBackCopyBtnLabel = () => {
    setIsCopied(false);
  }

  const copyTextToClipboard = (text: string) => {
    let textForSaving = text;
    const z = {} as any;
    if (makeBeauty) {
      textForSaving = jsb.html_beautify(text,
        {
          indent_size: 2,
          wrap_attributes: "force",
          indent_inner_html: true
        });
    }

    copyToClipboard(textForSaving);

    setIsCopied(true);
    debounce(changeBackCopyBtnLabel, 1000)();
  }

  return (
    <Panel id={id} className="code-panel" noMargin={noMargin} >
      <Button
        id={id + "-btn"}
        title={!isCopied ? "CODE_PANEL_COPY_CODE" : "CODE_PANEL_COPIED"}
        icon={!isCopied ? "fas fa-copy" : "fas fa-check-circle"}
        buttonType="general"
        shouldTranslate
        isVisible
        className="code-panel-button"
        onClick={() => copyTextToClipboard(codeStr)}
      />
      {children}
    </Panel>
  );
}

export default CodePanel;
