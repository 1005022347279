import React from "react";
import counterpart from "counterpart";
import "./FormLabel.scss";

interface IFormLabel {
  /**
  * The input element associated with the form
  */
  target: string,
  /**
  * The label text
  */
  content: string,
  /**
   * enable or not the translation
   */
  shouldTranslate?: boolean,
  /**
   * The icon to be displayed
   */
  icon?: string,
  /**
   * additional classes
   */
  className?: string,
  /**
   * remove or not the bottom margin
   */
  noMargin?: boolean,

  style?: object
  /**
   * onclick
   */
  onClick?: () => void,
  locale?: string
}


const FormLabel: React.FC<IFormLabel> = React.memo(({ target = "", content, shouldTranslate = true, icon = "", className = "", noMargin = false, 
  style = {}, onClick = () => {}, locale }) => {
  if (shouldTranslate) {
    return (
      <div className={`cc-form-label ${className} ${noMargin ? "cc-form-label--nomargin" : ""}`} onClick={onClick}>
        {icon && <i className={`${icon} cc-form-label-icon`} />}
        <label htmlFor={target} style={style}>{counterpart(content, { locale })}</label>
      </div>
    );
  }

  return (
    <div className={`cc-form-label ${className} ${noMargin ? "cc-form-label--nomargin" : ""}`} onClick={onClick}>
      {icon && <i className={`${icon} cc-form-label-icon`} />}
      <label htmlFor={target}>{content}</label>
    </div>
  );
})

export default FormLabel;
