import { useSelector } from "react-redux";
import { AppState } from "../..";
import { useMemo } from "react";
import { Permission } from "../../models/Auth";

const useCheckFeaturePermission = (permission: Permission) => {
    const currentPermissions = useSelector((state: AppState) => state.auth.parsedToken?.CurrentPermissions);

    const isFeatureEnabled = useMemo(() => {
        const permissionKey = Permission[permission] as keyof typeof Permission;
        return !!currentPermissions?.includes(permissionKey);
      }, [currentPermissions, permission]);
  
    return isFeatureEnabled;
}

export default useCheckFeaturePermission;