import React, { useRef, useLayoutEffect, useState, useMemo } from "react";
import Panel from "../../ui/panel/Panel";
import Text from "../../ui/text/Text";
import StepCollapsablePanel from "../../ui/step-collapsable-panel/StepCollapsablePanel";
import InputTags from "../../ui/input-tags/InputTags";
import Dropdown from "../../ui/dropdown/Dropdown";
import FormGroup from "../../ui/form-group/FormGroup";
import { Row, Col } from "react-grid-system";
import FormLabel from "../../ui/form-label/FormLabel";
import Input from "../../ui/input/Input";
import Button from "../../ui/button/Button";
import counterpart from "counterpart";
import Textarea from "../../ui/textarea/Textarea";
import "./CreatePlan.scss";
import {
  setPlanName,
  setPlanDisplayName,
  setPlanDescription,
  setPlanFeatureTags,
  removePlanFeatureTags,
  setPlanPricingModel,
  setHasSelectedFee,
  createPlan,
  createCycle,
  fetchProducts,
  setPlanFormCollapsed,
  setPlanFormCompleted,
  setPlanFormHasError,
  setPlanHelpTitleBasic,
  setPlanHelpContentBasic,
  resetPlanForEditing,
  setPlanVisibility,
  setPlanAlertEmail,
  setPlanRedirectUrl,
  fetchPlansByProduct,
  addCycle,
  editPlan,
  setPlanAddOns,
  setPlanProductId,
  setPlanAllowances,
  setPlanHelpTitleAdvanced,
  setPlanHelpContentAdvanced,
  setIsPlanImageEnabled,
  setPlanImageFileName,
  setPlanImageUrl,
  setIsAddingNewCycle,
  setPlanAlertEmailIsValid
} from "../../../actions/productPlanCycleActions";
import { findDOMNode } from "react-dom";
import { gaEventTracker, isValidEmailFormat, isValidUrl, multiplyNumberBy100 } from "../../../utils/commonUtils";
import { PricingModelType, VisibilityType, IPlanWithCycles, ICycle, ILinkedAddOn, ILinkedAllowance } from "../../../models/Product";
import Checkbox from "../../ui/checkbox/Checkbox";
import { updateGettingStartedSettings } from "../../../actions/authActions";
import { GettingStartedType, IGettingStartedSettings } from "../../../models/GettingStarted";
import CreateCycle from "../create-cycle/CreateCycle";
import LinkAddOnsAllowances from "../link-addons-allowances/LinkAddOnsAllowances";
import { AppState } from "../../..";
import { useDispatch, useSelector } from "react-redux";
import NavigationBar from "../../ui/navigation-bar/NavigationBar";
import PageWrapper from "../../ui/layout/PageWrapper";
import NavigationAlertCurrencyMismatch from "../../ui/navigation-alert/NavigationAlertCurrencyMismatch";
import history from "../../../utils/history";
import Helmet from "react-helmet";
import ProgressIndicator from "../../ui/progress-indicator/ProgressIndicator";
import { resetAddAddOnState } from "../../../actions/linkAddOnsAllowancesActions";
import { Currency, AddonPriceModel } from "../../../utils/grpc/generated/Billsby.Protos/core/private/addons/addons_pb";
import { Int32Value } from "google-protobuf/google/protobuf/wrappers_pb";
import Form from "../../ui/form/Form";
import PlanImageUploader from "./PlanImageUploader";
import NoticePanel from "../../ui/notice-panel/NoticePanel";
import { CustomeAnalyticsEvents } from "../../../utils/constants";

interface ICreatePlan {
  match: { params: { planProductId: number; planId?: number } };
}

const CreatePlan: React.FC<ICreatePlan> = ({ match }) => {
  const authReducerData = useSelector((state: AppState) => state.auth);
  const plansReducerData = useSelector((state: AppState) => state.plans);
  const cyclesReducerData = useSelector((state: AppState) => state.cycles);
  const linkAddOnsAllowancesReducer = useSelector((state: AppState) => state.linkAddOnsAllowancesReducer);
  const productPlanCyclesReducerData = useSelector((state: AppState) => state.productPlanCycle);

  const dispatch = useDispatch<Function>();

  const { productsPaginated, } = productPlanCyclesReducerData;
  const { selectedAddOns, selectedAllowances } = linkAddOnsAllowancesReducer;
  const [isLoadingData, setIsLoadinData] = useState(true);
  const {
    name,
    displayName,
    description,
    featureTags,
    hasSelectedFee,
    isCreatingPlanRequest,
    stageStatuses,
    stageCollapsed,
    stageErrors,
    pricingModel,
    helpTextTitleBasic,
    helpTextContentBasic,
    helpTextTitleAdvanced,
    helpTextContentAdvanced,
    visibility,
    redirectUrl,
    alertEmail,
    isAlertEmailValid,
    isEditingPlanRequest,
    addons,
    allowances,
    isPlanImageEnabled,
    planImageFileName,
    planError,
    planImageUrl
  } = plansReducerData;

  const { cycles, isCreatingCyclesRequest, hasAddedFirstCycleEver } = cyclesReducerData;
  const { dashboardSettings, currentCompanyDomain } = authReducerData;

  const formRef = useRef(null);

  const VISIBILITY_OPTIONS = [
    { value: VisibilityType.Public, label: counterpart("CREATE_PLAN_VISIBILITY_PUBLIC") },
    { value: VisibilityType.Hidden, label: counterpart("CREATE_PLAN_VISIBILITY_HIDDEN") },
  ];

  const selectedVisibility = VISIBILITY_OPTIONS.find((s: any) => s.value === visibility);

  const PRICING_MODELS: { [key in PricingModelType]: any } = {
    [PricingModelType.FlatFee]: counterpart("CREATE_PLAN_PRICING_MODEL_FLAT_FEE"),
    [PricingModelType.PerUnit]: counterpart("CREATE_PLAN_PRICING_MODEL_PER_UNIT"),
    [PricingModelType.Tiered]: counterpart("CREATE_PLAN_PRICING_MODEL_TIERED"),
    [PricingModelType.Ranged]: counterpart("CREATE_PLAN_PRICING_MODEL_RANGED"),
    [PricingModelType.Volume]: counterpart("CREATE_PLAN_PRICING_MODEL_VOLUME"),
    [PricingModelType.Capped]: counterpart("CREATE_PLAN_PRICING_MODEL_FLAT_FEE"),
  };

  function changeCollapsedValue(index: number, stageCollapsed: Array<boolean>, dispatch: Function) {
    if (stageCollapsed[index]) {
      dispatch(setPlanFormCollapsed(index));
    }
  }

  const planProductId = Number(match?.params.planProductId);
  const planId = Number(match?.params.planId);
  const product = productsPaginated.results && productsPaginated.results.find((p) => p.productId === planProductId);

  const isPlanImageValid = useMemo(() => isPlanImageEnabled ? !!planImageFileName : true, [isPlanImageEnabled, planImageFileName]);

  const getPricingModelSubtitle = (): JSX.Element | string => {
    const pricingModelText = pricingModel && hasSelectedFee ? PRICING_MODELS[pricingModel] : "";
    if(!pricingModelText) {
      return "";
    }
    if(planId || hasAddedFirstCycleEver) {
      return <Text className="create-plan__pricingmodel-group" content={pricingModelText} shouldTranslate={false} noMargin />
    }
    return (
      <div className="create-plan__pricingmodel-group">
        <Text content={pricingModelText} shouldTranslate={false} noMargin />
        <Button
          className=""
          id="undo-pricing-model"
          buttonType="add"
          icon="far fa-undo"
          title="CREATE_PLAN_PRICING_MODEL_UNDO"
          onClick={evt => { 
            evt.preventDefault(); 
            evt.stopPropagation();
            dispatch(setHasSelectedFee(false));
            dispatch(setPlanPricingModel(undefined));
            dispatch(setPlanHelpTitleBasic("HELP_EMPTY_TITLE"));
            dispatch(setPlanHelpContentBasic(["HELP_EMPTY_1", "HELP_EMPTY_2"]));
            //close the cycle management modal if it's open
            dispatch(setIsAddingNewCycle(false));
            dispatch(setPlanFormCompleted(1, false));
            changeCollapsedValue(1, stageCollapsed, dispatch);
          }}
        />
      </div>
    )
  }

  useLayoutEffect(() => {
    dispatch(resetPlanForEditing());
    if (planId && productsPaginated.results && productsPaginated.results.length) {
      // we are in the edit plan scenario
      preloadPlan(planId);
    } else {
      setIsLoadinData(false);
      dispatch(setPlanPricingModel(PricingModelType.FlatFee));
    }
    return () => dispatch(resetAddAddOnState());
  }, [productsPaginated]);


  if (!productsPaginated.results || !product) {
    return null;
  }

  const planProductName = product.name;

  /* used in case of edit plan workflow */
  const preloadPlan = async (planId: number) => {
    setIsLoadinData(true);
    try {
      const { response: plansOfProduct } = (await dispatch(fetchPlansByProduct(currentCompanyDomain, product.productId))) as { response: Array<IPlanWithCycles> };
      const planToEdit = plansOfProduct.find((plan) => plan.planId === planId);
      if (planToEdit) {
        dispatch(setPlanProductId(planToEdit.planId));
        dispatch(setPlanName(planToEdit.name));
        dispatch(setPlanDisplayName(planToEdit.displayName));
        dispatch(setPlanDescription(planToEdit.description));
        dispatch(setPlanRedirectUrl(planToEdit.redirectUrl || ""));
        planToEdit.featureTags.forEach((tag) => dispatch(setPlanFeatureTags({ name: tag })));
        dispatch(setPlanPricingModel((planToEdit as any).pricingModelType));
        dispatch(setPlanVisibility(planToEdit.visibility));
        dispatch(setPlanAlertEmail(planToEdit.alertEmail));
        dispatch(setIsPlanImageEnabled(planToEdit.isPlanImageEnabled));
        dispatch(setPlanImageFileName(planToEdit.planImageFileName));
        dispatch(setPlanImageUrl(planToEdit.planImageUrl));
        planToEdit.cycles.forEach((cycle) =>
          dispatch(
            addCycle({
              ...cycle,
              pricingModel: {
                ...cycle.pricingModel,
                price: cycle.pricingModel.price / 100,
                setupFeePrice: cycle.pricingModel.setupFeePrice ? cycle.pricingModel.setupFeePrice / 100 : undefined,
                visibility: (cycle as any).visibility,
                freeTrialFrequencyType: cycle.pricingModel.freeTrialFrequencyType as any === "Unassigned" ? undefined : cycle.pricingModel.freeTrialFrequencyType,
                contractTermFrequencyType: cycle.pricingModel.contractTermFrequencyType as any === "Unassigned" ? undefined : cycle.pricingModel.contractTermFrequencyType
              },
            })
          )
        );
        dispatch(setPlanAddOns(planToEdit.addons));
        dispatch(setPlanAllowances(planToEdit.allowances));
        dispatch(setHasSelectedFee(true));
        dispatch(setPlanFormCompleted(0, true));
        dispatch(setPlanFormCompleted(1, true));
        dispatch(setPlanFormCompleted(2, true));
        // dispatch(setPlanField("stageCollapsed", [false, false, false, false, false]));
      }
    } finally {
      setIsLoadinData(false);
    }
  };

  if (isLoadingData) {
    return <ProgressIndicator color="blue" coverage="full-content" />;
  }

  return (
    <div>
      <Helmet title={counterpart(!planId ? "NAVBAR_CREATE_PLAN" : "NAVBAR_EDIT_PLAN")} />
      <NavigationBar
        pageTitle={counterpart(!planId ? "CREATE_PLAN_PAGE_TITLE" : "EDIT_PLAN_PAGE_TITLE", { productName: planProductName, planName: name })}
        shouldTranslatePageTitle={false}
        previousPageTitle="CREATE_PRODUCT_PLANCYCLES_TITLE"
        previousPageCb={() => {
          history.push("/products/products-plans-cycles");
        }}
      />
      <NavigationAlertCurrencyMismatch />

      <PageWrapper className="create-plan">
        <Panel className="create-plan__heading" title={counterpart(!planId ? "CREATE_PLAN_HEADING_TITLE" : "EDIT_PLAN_HEADING_TITLE", { planName: name })} shouldTranslate={false}>
          <Text content="CREATE_PLAN_HEADING_SUBTITLE" noMargin />
        </Panel>

        <StepCollapsablePanel
          number={1}
          title={counterpart(!planId ? "CREATE_PLAN_FORM_TITLE" : "EDIT_PLAN_FORM_TITLE")}
          shouldTranslateTitle={false}
          isCollapsed={planId ? false : stageCollapsed[0]}
          isCompleted={stageStatuses[0] && isPlanImageValid}
          hasError={stageErrors[0] || !isPlanImageValid}
          onClick={() => {
            dispatch(setPlanFormCollapsed(0));
            dispatch(setPlanHelpTitleBasic("HELP_EMPTY_TITLE"));
            dispatch(setPlanHelpContentBasic(["HELP_EMPTY_1", "HELP_EMPTY_2"]));
          }}
          onFocus={() => changeCollapsedValue(0, stageCollapsed, dispatch)}
          helpMenuTitle={helpTextTitleBasic}
          isShowTitleIcon={!planId}
          helpMenuContent={
            <div>
              {helpTextContentBasic.map((text, id) => (
                <Text key={id} content={text} />
              ))}
            </div>
          }
        >
          <Form
            ref={formRef}
            onChange={() => {
              const form = findDOMNode(formRef.current) as HTMLFormElement;
              if (form.checkValidity()) {
                dispatch(setPlanFormCompleted(0, true));
                dispatch(setPlanFormHasError(0, false));
              } else {
                dispatch(setPlanFormCompleted(0, false));
                dispatch(setPlanFormHasError(0, true));
              }
            }}
          >
            {!!planError && 
            <Row>
              <Col md={12}>
                <div className="create-plan__error-contianer">
                  <NoticePanel type="error" isModal content={planError} shouldTranslate={false} />
                </div>
                </Col>
            </Row>}

            <FormGroup>
              <Row align="center">
                <Col md={2}>
                  <FormLabel target="name" content="CREATE_PRODUCT_FORM_NAME" />
                </Col>
                <Col md={10}>
                  <Input
                    id="name"
                    required
                    maxLength={90}
                    placeholder={counterpart("CREATE_PLAN_FORM_NAME_PLACEHOLDER")}
                    value={name}
                    onChange={(evt: any) => dispatch(setPlanName(evt.target.value))}
                    tabIndex={stageCollapsed[0] && !stageStatuses[0] ? -1 : 0}
                    onFocus={() => {
                      dispatch(setPlanHelpTitleBasic("CREATE_PLAN_HELP_NAME_TITLE"));
                      dispatch(setPlanHelpContentBasic(["CREATE_PLAN_HELP_NAME_CONTENT"]));
                    }}
                  />
                </Col>
              </Row>
            </FormGroup>

            <FormGroup>
              <Row align="center">
                <Col md={2}>
                  <FormLabel target="displayname" content="CREATE_PRODUCT_FORM_DISPLAYNAME" />
                </Col>
                <Col md={10}>
                  <Input
                    id="displayname"
                    maxLength={90}
                    placeholder={counterpart("CREATE_PLAN_FORM_NAME_PLACEHOLDER")}
                    value={displayName}
                    onChange={(evt: any) => dispatch(setPlanDisplayName(evt.target.value))}
                    tabIndex={stageCollapsed[0] && !stageStatuses[0] ? -1 : 0}
                    onFocus={() => {
                      dispatch(setPlanHelpTitleBasic("CREATE_PLAN_HELP_DISPLAYNAME_TITLE"));
                      dispatch(setPlanHelpContentBasic(["CREATE_PLAN_HELP_DISPLAYNAME_CONTENT"]));
                    }}
                  />
                </Col>
              </Row>
            </FormGroup>

            <FormGroup>
              <Row>
                <Col md={2}>
                  <FormLabel className="create-plan__description" target="description" content="CREATE_PRODUCT_FORM_DESCRIPTION" />
                </Col>
                <Col md={10}>
                  <Textarea
                    id="description"
                    value={description}
                    maxLength={165}
                    placeholder={counterpart("CREATE_PLAN_FORM_DESCRIPTION_PLACEHOLDER")}
                    onChange={(evt: any) => dispatch(setPlanDescription(evt.target.value))}
                    tabIndex={stageCollapsed[0] && !stageStatuses[0] ? -1 : 0}
                    onFocus={() => {
                      dispatch(setPlanHelpTitleBasic("CREATE_PLAN_HELP_DESCRIPTION_TITLE"));
                      dispatch(setPlanHelpContentBasic(["CREATE_PLAN_HELP_DESCRIPTION_CONTENT"]));
                    }}
                  />
                </Col>
              </Row>
            </FormGroup>
            <PlanImageUploader />
          </Form>
        </StepCollapsablePanel>

        <StepCollapsablePanel
          className="create-plan__pricingmodel"
          number={2}
          title={counterpart(!planId ? "CREATE_PLAN_PRICING_MODEL_TITLE" : "EDIT_PLAN_PRICING_MODEL_TITLE")}
          shouldTranslateTitle={false}
          subTitle={getPricingModelSubtitle()}
          shouldTranslateSubTitle={false}
          isCollapsed={stageCollapsed[1]}
          isCompleted={stageStatuses[1]}
          onClick={() => {
            dispatch(setPlanFormCollapsed(1));
            if (stageStatuses[0]) {
              dispatch(setPlanHelpTitleBasic("CREATE_PLAN_PRICING_MODEL_HELP_FLAT_FEE_TITLE"));
              dispatch(setPlanHelpContentBasic(["CREATE_PLAN_PRICING_MODEL_HELP_FLAT_FEE_CONTENT"]));
            }
          }}
          isShowTitleIcon={!planId}
          onFocus={() => changeCollapsedValue(1, stageCollapsed, dispatch)}
          helpMenuTitle={helpTextTitleBasic}
          helpMenuContent={
            <div>
              {helpTextContentBasic.map((text, id) => (
                <Text key={id} content={text} />
              ))}
            </div>
          }
          isLocked={hasSelectedFee || !stageStatuses[0]}
          isShowLockIcon={hasSelectedFee}
        >
          <div>
            <Text content="CREATE_PLAN_PRICING_MODEL_SUBTITLE" />
            <FormGroup>
              <Checkbox
                checked={pricingModel === PricingModelType.FlatFee}
                onClick={() => {
                  dispatch(setPlanPricingModel(PricingModelType.FlatFee));
                  dispatch(setPlanHelpTitleBasic("CREATE_PLAN_PRICING_MODEL_HELP_FLAT_FEE_TITLE"));
                  dispatch(setPlanHelpContentBasic(["CREATE_PLAN_PRICING_MODEL_HELP_FLAT_FEE_CONTENT"]));
                }}
                content={PRICING_MODELS[PricingModelType.FlatFee]}
                value=""
              />
              <Checkbox
                checked={pricingModel === PricingModelType.PerUnit}
                onClick={() => {
                  dispatch(setPlanPricingModel(PricingModelType.PerUnit));
                  dispatch(setPlanHelpTitleBasic("CREATE_PLAN_PRICING_MODEL_HELP_PERUNIT_TITLE"));
                  dispatch(setPlanHelpContentBasic(["CREATE_PLAN_PRICING_MODEL_HELP_PERUNIT_CONTENT"]));
                }}
                content={PRICING_MODELS[PricingModelType.PerUnit]}
                value=""
              />
              <Checkbox
                checked={pricingModel === PricingModelType.Volume}
                onClick={() => {
                  dispatch(setPlanPricingModel(PricingModelType.Volume));
                  dispatch(setPlanHelpTitleBasic("CREATE_PLAN_PRICING_MODEL_HELP_VOLUME_TITLE"));
                  dispatch(setPlanHelpContentBasic(["CREATE_PLAN_PRICING_MODEL_HELP_VOLUME_CONTENT1", "CREATE_PLAN_PRICING_MODEL_HELP_VOLUME_CONTENT2"]));
                }}
                content={PRICING_MODELS[PricingModelType.Volume]}
                value=""
              />
              <Checkbox
                checked={pricingModel === PricingModelType.Tiered}
                onClick={() => {
                  dispatch(setPlanPricingModel(PricingModelType.Tiered));
                  dispatch(setPlanHelpTitleBasic("CREATE_PLAN_PRICING_MODEL_HELP_TIERED_TITLE"));
                  dispatch(setPlanHelpContentBasic(["CREATE_PLAN_PRICING_MODEL_HELP_TIERED_CONTENT1", "CREATE_PLAN_PRICING_MODEL_HELP_TIERED_CONTENT2"]));
                }}
                content={PRICING_MODELS[PricingModelType.Tiered]}
                value=""
              />
              <Checkbox
                checked={pricingModel === PricingModelType.Ranged}
                onClick={() => {
                  dispatch(setPlanPricingModel(PricingModelType.Ranged));
                  dispatch(setPlanHelpTitleBasic("CREATE_PLAN_PRICING_MODEL_HELP_RANGED_TITLE"));
                  dispatch(setPlanHelpContentBasic(["CREATE_PLAN_PRICING_MODEL_HELP_RANGED_CONTENT1", "CREATE_PLAN_PRICING_MODEL_HELP_RANGED_CONTENT2"]));
                }}
                content={PRICING_MODELS[PricingModelType.Ranged]}
                value=""
              />
            </FormGroup>

            <Button
              id="create-plan-pricing-btn"
              title={pricingModel ? "CREATE_PLAN_PRICING_MODEL_BTN" : "CREATE_PLAN_PRICING_MODEL_BTN_DEFAULT"}
              translateWith={{ model: PRICING_MODELS[pricingModel || PricingModelType.FlatFee].toUpperCase() }}
              isFullWidth
              tabIndex={stageCollapsed[1] && (!stageStatuses[0] || stageStatuses[1]) ? -1 : 0}
              disabled={!pricingModel}
              onClick={() => {
                dispatch(setHasSelectedFee(true));
                changeCollapsedValue(1, stageCollapsed, dispatch);
                dispatch(setPlanFormCollapsed(2));
                dispatch(setPlanFormCompleted(1, true));
              }}
            />
          </div>
        </StepCollapsablePanel>

        <CreateCycle product={product} isCreatingPlan={!planId} />

        <LinkAddOnsAllowances product={product} isCreatingPlan={!planId} />

        {/*
        HIDDEN FOR NOW
        <StepCollapsablePanel
          onClick={() => {
            dispatch(setPlanFormCollapsed(3));
          }}
          className="create-plan__coming-soon"
          isCollapsed={stageCollapsed[3]}
          isCompleted={true}
          number={4}
          title={counterpart(!planId ? 'CREATE_PLAN_LINK_ADDONS_TITLE' : 'EDIT_PLAN_LINK_ADDONS_TITLE')}
          shouldTranslateTitle={false}
        >
          <div className="create-plan__coming-soon__placeholder"><Text content="COMING_SOON_CREATE_ADDONS"></Text></div>
        </StepCollapsablePanel>
        */}

        {/*
          HIDDEN FOR NOW
           <StepCollapsablePanel
          onClick={() => {
            dispatch(setPlanFormCollapsed(4));
          }}
          className="create-plan__coming-soon"
          isCollapsed={stageCollapsed[4]}
          isCompleted={true}
          number={5}
          title={counterpart(!planId ? 'CREATE_PLAN_ACTIONS_TITLE' : 'EDIT_PLAN_ACTIONS_TITLE')}
          shouldTranslateTitle={false}
        >
          <div className="create-plan__coming-soon__placeholder"><Text content="COMING_SOON_DEFINE_PLAN_ACTIONS"></Text></div>
        </StepCollapsablePanel>
        */}


        <StepCollapsablePanel
          number={5}
          title={counterpart(!planId ? "CREATE_PLAN_ADVANCED_SETTINGS_TITLE" : "EDIT_PLAN_ADVANCED_SETTINGS_TITLE")}
          shouldTranslateTitle={false}
          isCollapsed={planId ? false : stageCollapsed[4]}
          isCompleted={stageStatuses[4]}
          hasError={stageErrors[4]}
          isLocked={planId ? false : (!stageStatuses[2] || !stageStatuses[3])}
          isShowLockIcon={false}
          isShowTitleIcon={!planId}
          onClick={() => {
            dispatch(setPlanFormCollapsed(4));
          }}
          onFocus={() => changeCollapsedValue(4, stageCollapsed, dispatch)}
          helpMenuTitle={helpTextTitleAdvanced}
          helpMenuContent={
            <div>
              {helpTextContentAdvanced.map((text, id) => (
                <Text key={id} content={text} />
              ))}
            </div>
          }

        >
          <form onSubmit={(evt: any) => evt.preventDefault()}>
            <FormGroup>
              <Row align="center">
                <Col md={2}>
                  <FormLabel target="name" content="CREATE_PLAN_FEATURE_TAGS" noMargin />
                </Col>
                <Col md={10} onFocus={() => {
                  dispatch(setPlanHelpTitleAdvanced("CREATE_PLAN_HELP_FEATURE_TAGS_TITLE"));
                  dispatch(setPlanHelpContentAdvanced(["CREATE_PLAN_HELP_FEATURE_TAGS_CONTENT", "CREATE_PLAN_HELP_FEATURE_TAGS_CONTENT1"]));
                }}>
                  <InputTags
                    tags={featureTags}
                    handleDelete={(tagIndex: number) => {
                      dispatch(removePlanFeatureTags(tagIndex));
                    }}
                    handleAddition={(tag: any) => {
                      dispatch(setPlanFeatureTags(tag));
                    }}
                    tabIndex={stageCollapsed[5] && !stageStatuses[5] ? -1 : 0}
                  />
                </Col>
                <Col offset={{ md: 2 }} md={10}>
                  <Text style={{ marginTop: "10px" }} content="CREATE_PLAN_FEATURE_TAGS_NOTICE" noMargin></Text>
                </Col>
              </Row>
            </FormGroup>
            <FormGroup>
              <Row align="center">
                <Col md={2}>
                  <FormLabel target="alert-email" content="CREATE_PLAN_ALERT_EMAIL" noMargin />
                </Col>
                <Col md={10}>
                  <Input
                    id="alert-email"
                    required
                    value={alertEmail}
                    isError={!isAlertEmailValid}
                    placeholder={counterpart("CREATE_PLAN_ALERT_EMAIL_PLACEHOLDER")}
                    onChange={(evt: any) => {
                      const isValidEmail = isValidEmailFormat(evt.target.value) || !evt.target.value;
                      dispatch(setPlanAlertEmailIsValid(isValidEmail));
                      dispatch(setPlanAlertEmail(evt.target.value));
                    }}
                    onFocus={() => {
                      dispatch(setPlanHelpTitleAdvanced("CREATE_PLAN_ALERT_EMAIL_HELP_TITLE"));
                      dispatch(setPlanHelpContentAdvanced(["CREATE_PLAN_ALERT_EMAIL_HELP_CONTENT_1", "CREATE_PLAN_ALERT_EMAIL_HELP_CONTENT_2"]));
                    }}
                  />
                </Col>
              </Row>
            </FormGroup>
            <FormGroup>
              <Row align="center">
                <Col md={2}>
                  <FormLabel target="redirect-url" content="CREATE_PLAN_REDIRECT_URL" noMargin />
                </Col>
                <Col md={7.5}>
                  <Input
                    id="redirect-url"
                    required
                    value={redirectUrl}
                    placeholder={counterpart("CREATE_PLAN_REDIRECT_URL_PLACEHOLDER")}
                    onChange={(evt: any) => {
                      dispatch(setPlanRedirectUrl(evt.target.value));
                      if (isValidUrl(evt.target.value) || !evt.target.value) {
                        dispatch(setPlanFormCompleted(4, true));
                      } else {
                        dispatch(setPlanFormCompleted(4, false));
                      }
                    }}
                    onFocus={() => {
                      dispatch(setPlanHelpTitleAdvanced("CREATE_PLAN_HELP_REDIRECT_URL_TITLE"));
                      dispatch(setPlanHelpContentAdvanced(["CREATE_PLAN_HELP_REDIRECT_URL_CONTENT"]));
                    }}
                  />
                </Col>
                <Col md={2.5}>
                  <Text
                    shouldTranslate={false}
                    content={
                      <span>
                        /?cid=<span className="dusty-orange">XXX</span>
                        {"&"}sid=<span className="dusty-orange">XXX</span>
                      </span>
                    }
                    noMargin
                  />
                </Col>
              </Row>
            </FormGroup>
            <div>
              <Row align="center">
                <Col md={2}>
                  <FormLabel target="visibility" content="CREATE_PLAN_VISIBILITY" noMargin />
                </Col>
                <Col md={10}>
                  <Dropdown
                    id="visibility"
                    onChange={(visibility: any) => dispatch(setPlanVisibility(visibility.value))}
                    value={selectedVisibility}
                    options={VISIBILITY_OPTIONS}
                    onFocus={() => {
                      dispatch(setPlanHelpTitleAdvanced("CREATE_PLAN_HELP_VISIBILITY_TITLE"));
                      dispatch(setPlanHelpContentAdvanced(["CREATE_PLAN_HELP_VISIBILITY_CONTENT1", "CREATE_PLAN_HELP_VISIBILITY_CONTENT2", "CREATE_PLAN_HELP_VISIBILITY_CONTENT3", "CREATE_PLAN_HELP_VISIBILITY_CONTENT6"]));
                      // dispatch(setPlanHelpContentAdvanced(['CREATE_PLAN_HELP_VISIBILITY_CONTENT1', 'CREATE_PLAN_HELP_VISIBILITY_CONTENT2', 'CREATE_PLAN_HELP_VISIBILITY_CONTENT3',
                      //   'CREATE_PLAN_HELP_VISIBILITY_CONTENT4', 'CREATE_PLAN_HELP_VISIBILITY_CONTENT5', 'CREATE_PLAN_HELP_VISIBILITY_CONTENT6']));
                    }}
                  />
                </Col>
              </Row>
            </div>
          </form>
        </StepCollapsablePanel>

        <Button
          id="create-plan-btn"
          title={!planId ? "CREATE_PLAN_BUTTON" : "EDIT_PLAN_BUTTON"}
          isFullWidth
          // stageStatuses[4] (featureTags) is not mandatory
          disabled={!stageStatuses[0] || !stageStatuses[1] || !stageStatuses[2] || !stageStatuses[3] || !isPlanImageValid || !isAlertEmailValid}
          isLoading={isCreatingPlanRequest || isCreatingCyclesRequest || isEditingPlanRequest}
          onClick={() => {
            let featureTagNames = featureTags.map((featureTag) => {
              return featureTag["name"];
            });

            let promise;
            const basePlanData = {
              name,
              displayName,
              description,
              featureTags: featureTagNames,
              visibility,
              redirectUrl,
              alertEmail,
              isPlanImageEnabled,
              planImageFileName,
              planImageUrl
            };

            const newAddOns: Array<ILinkedAddOn> = selectedAddOns.map((addOn) => {
              return {
                addonId: addOn.id,
                isForced: addOn.isForced,
                addonPriceModels: [...addOn.unconfiguredPriceModelsList, ...addOn.priceModelList].map((priceModel) => {

                  return {
                    frequency: priceModel.getFrequency(),
                    frequencyType: priceModel.getFrequencyType(),
                    flatFeePrice: (priceModel as any).getFlatFeePrice ? (priceModel as AddonPriceModel).getFlatFeePrice() ? +(multiplyNumberBy100((priceModel as AddonPriceModel).getFlatFeePrice()) || new Int32Value()).getValue() : 0 : 0,
                    pricePerUnit: priceModel.getPerUnitPrice() ? +(multiplyNumberBy100(priceModel.getPerUnitPrice()) || new Int32Value()).getValue() : 0,
                    currencyIso3Code: Boolean((priceModel.getCurrency() as Currency).getIso3Code()) ? (priceModel.getCurrency() as Currency).getIso3Code() : product.billingCurrency,
                    unitTiers: priceModel.getTiersList().length > 0 ? priceModel.getTiersList().map((tier) => {
                      return {
                        start: tier.getStart(),
                        finish: tier.getFinish(),
                        price: Number(multiplyNumberBy100(tier.getPrice())),
                      };
                    }) : []
                  };
                }),
              };
            });


            const newAllowances: Array<ILinkedAllowance> = selectedAllowances.map((allowance) => {

              return {
                allowanceId: allowance.id,
                isForced: allowance.isForced,
                allowancePriceModels: [...allowance.unconfiguredPriceModelsList, ...allowance.priceModelList].map((priceModel) => {

                  return {
                    frequency: priceModel.getFrequency(),
                    frequencyType: priceModel.getFrequencyType(),
                    includedUnits: Number(priceModel.getIncludedUnits() || 0),
                    pricePerUnit: priceModel.getPerUnitPrice() ? +(multiplyNumberBy100(priceModel.getPerUnitPrice()) || "0") : 0,
                    currencyIso3Code: Boolean((priceModel.getCurrency() as Currency).getIso3Code()) ? (priceModel.getCurrency() as Currency).getIso3Code() : product.billingCurrency,
                    unitTiers: priceModel.getTiersList().length > 0
                      ? priceModel.getTiersList().map((tier) => {
                        return {
                          start: tier.getStart(),
                          finish: tier.getFinish(),
                          price: Number(multiplyNumberBy100(tier.getPrice())),
                        };
                      })
                      : []
                  };
                }),
              };
            });

            const cyclesApi = cycles.map((cycle) => ({
              cycleId: (cycle as ICycle).cycleId,
              ...cycle.pricingModel,
              price: cycle.pricingModel.tiers ? 1 : Math.round(cycle.pricingModel.price * 100),
              setupFeePrice: cycle.pricingModel.setupFeePrice ? Math.round(cycle.pricingModel.setupFeePrice * 100) : undefined,
            }));


            if (!planId) {
              promise = dispatch(
                createPlan(currentCompanyDomain, planProductId, {
                  ...basePlanData,
                  pricingModel: pricingModel || PricingModelType.FlatFee,
                  addons,
                  allowances
                })
              );
            } else {
              promise = dispatch(editPlan(currentCompanyDomain, planProductId, planId, {
                ...basePlanData,
                cycles: cyclesApi,
                addonPlans: newAddOns,
                allowancePlans: newAllowances
              }));
            }

            promise
              .then(({ response = {} }: any) => {
                return !planId ? dispatch(createCycle(currentCompanyDomain, planProductId, response.planId, cyclesApi, newAddOns, newAllowances)) : Promise.resolve();
              })
              .then(() => {
                const isCreatingFirstPlanEver = productsPaginated.results && productsPaginated.results.length === 1 && !productsPaginated.results[0].hasPlans;
                if (isCreatingFirstPlanEver) {
                  // send analytics event
                  gaEventTracker(CustomeAnalyticsEvents.FIRST_CYCLE_ADDED);
                  dispatch(
                    updateGettingStartedSettings(currentCompanyDomain, {
                      ...dashboardSettings as IGettingStartedSettings,
                      status: GettingStartedType.GettingStarted2,
                    })
                  );
                }
                dispatch(fetchProducts(currentCompanyDomain, 1));
                //dispatch(resetPlanForEditing());
                history.push("/products/products-plans-cycles");
              }).catch((err: any) => {
                console.log(err)
              });
          }}
        />
      </PageWrapper>
    </div>
  );
};

export default CreatePlan;
