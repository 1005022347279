import { useLayoutEffect, useState, useEffect, useMemo } from "react";
import { History } from "history";
import { useHistory } from "react-router";

export const useActiveLinks = (history: History, setActive: Function, targets: Array<string>, setIsExpanded?: Function) => {
  // this only runs on mount and unmount as described in https://daveceddia.com/useeffect-hook-examples/ and https://github.com/ReactTraining/history#listening
  // we are doing this because our header currently sits outside the Switch component
  // so it's children are not re-rendered whenever the route changes 
  useLayoutEffect(() => {
    targets.forEach(target => {
      if (history.location.pathname.indexOf(target) >= 0) {
        if (typeof setIsExpanded === "function") {
          setIsExpanded(true);
        }
        setActive(true)
      }
    });

    const target: string = targets[0];

    const unlisten = history.listen((location: any) => {
      setActive(location.pathname.indexOf(target) >= 0)
    })

    return () => unlisten();
  }, [])
}

/**
 * the parameter is a function and not a promise because if it was a promise the call would be execute every re-render
 * of the component that uses this hook, because promises are "hot" by default and are immediatly executed
 * @param apiFn 
 */
export const useFetch = (apiFn: () => Promise<any>) => {
  const [response, setResponse] = useState({});
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      try {
        const json = await apiFn();
        setResponse(json);
      }
      catch (error) {
        setError(error);
      }
      finally {
        setIsLoading(false);
      }
    };
    fetchData();
  }, []);

  return { response, error, isLoading };
};

export const useIsFromSpecificPage = (from: string) => {
  const history = useHistory();

  const isFromSpecificPage = useMemo(() => {
    const state = history?.location?.state as { from: string }
    return state?.from?.includes(from);
  }, [history?.location]);

  // we need to cast the returned value to boolean
  return !!isFromSpecificPage
}