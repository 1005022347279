import React, { useLayoutEffect } from "react";
import { useLocation } from "react-router";

interface IScrollTop {
  autoScroll?: boolean;
}

const ScrollTop: React.FC<IScrollTop> = React.memo(({ autoScroll = true, children }) => {
  const location = useLocation();
  useLayoutEffect(() => {
    // scroll behaviour is not supported in every browser
    const supportsNativeSmoothScroll = "scrollBehavior" in document.documentElement.style;
    supportsNativeSmoothScroll && autoScroll &&
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: "smooth",
      });
  }, [location.pathname]);

  return <>{children}</>;
});

export default ScrollTop;
