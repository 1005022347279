import React, { useState } from "react";
import Text from "../../../components/ui/text/Text";
import Button from "../../../components/ui/button/Button";
import Table from "../../../components/ui/table/Table";
import { downloadCsvFile, getFrequencyText } from "../../../utils/commonUtils";
import counterpart from "counterpart";
import history from "../../../utils/history";
import { GetCouponsResponse, Coupon, CouponType, FixedTermDiscountLength, FrequencyType } from "../../../utils/grpc/generated/Billsby.Protos/core/private/discounts/discounts_pb";
import { setDiscountsFields, fetchCouponCodes } from "../../../actions/discount-coupon/discountsActions";
import { Timestamp } from "google-protobuf/google/protobuf/timestamp_pb";
import { FrequencyType as ProductFrequencyType } from "../../../models/Product";
import moment from "moment";
import Tooltip from "../../ui/tooltip/Tooltip";
import { IAuthReducerState } from "../../../reducers/authReducer";
import { GetCouponCodesResponse } from "../../../utils/grpc/generated/Billsby.Protos/billing/private/coupons/coupons_pb";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../../..";
import { Permission } from "../../../models/Auth";
import useCheckFeaturePermission from "../../../utils/hooks/useCheckFeaturePermission";

interface IDiscountCouponsTable {
  couponList: Array<{ discountId: number, coupons: GetCouponsResponse }>,
  discountId: number
}

const DiscountCouponsTable: React.FC<IDiscountCouponsTable> = ({ couponList, discountId }) => {
  const auth = useSelector<AppState, IAuthReducerState>(state => state.auth)
  const isDiscountCouponEditPermitted = useCheckFeaturePermission(Permission.DiscountCouponEdit);
  const isDiscountCouponExpirePermitted = useCheckFeaturePermission(Permission.DiscountCouponExpire);
  const dispatch = useDispatch<Function>()

  const getExpirationDate = (coupon: Coupon) => {
    if (coupon.getExpiresOn()) {
      const expiresOn = coupon.getExpiresOn() as Timestamp;
      if (expiresOn.toDate() < new Date()) {
        return counterpart("DISCOUNTS_LIST_COUPON_EXPIRED");
      } else {
        return moment(expiresOn.toDate()).format("DD MMM YYYY")
      }
    }
    return <span style={{ width: "100%", textAlign: "center" }}>-</span>;
  }


  const [showAll, setShowAll] = useState(false);

  const downloadCsv = async (coupon: Coupon) => {
    const fileName = `${coupon.getName()}-${moment(new Date()).format("DD MMM YYYY")} at ${moment(new Date()).format("HH:mm:ss Z")}`;
    try {
      const { response } = await dispatch(fetchCouponCodes(Number(auth.currentCompanyId), coupon.getCouponId())) as { response: GetCouponCodesResponse };
      const rows = response.getCouponCodesList().map(i => [coupon.getName(), i.getCode(),
      `${moment((i.getCreatedOn() as Timestamp).toDate()).format("DD MMM YYYY")} at ${moment((i.getCreatedOn() as Timestamp).toDate()).format("HH:mm:ss Z")}`,
      i.getUsageLeft() > 0 ? "Unused" : "Used"]);
      rows.unshift([counterpart("CSV_COLUMN_COUPON_NAME"),
      counterpart("CSV_COLUMN_COUPON_CODE"),
      counterpart("CSV_COLUMN_COUPON_TIME_STAMP"),
      counterpart("CSV_COLUMN_COUPON_USAGE_STATE")
      ]);
      downloadCsvFile(rows, fileName);
    } 
    catch (err) {
      console.log(err);
    }
  }

  const getDiscountLength = (coupon: Coupon) => {
    if (coupon.getDiscountLengthCase() === Coupon.DiscountLengthCase.IS_LIFE_TIME) {
      return counterpart("CREATE_COUPON_FORM_COUPON_WORKS_DISCOUNT_LENGTH_LIFETIME");
    } else {
      if (coupon.getFixedTerm()) {
        const fixedTerm = coupon.getFixedTerm() as FixedTermDiscountLength;
        let frequencyType;
        switch (fixedTerm.getFrequencyType()) {
          case FrequencyType.DAILY:
            frequencyType = ProductFrequencyType.Daily;
            break;
          case FrequencyType.WEEKLY:
            frequencyType = ProductFrequencyType.Weekly;
            break;
          case FrequencyType.MONTHLY:
            frequencyType = ProductFrequencyType.Monthly;
            break;
          case FrequencyType.YEARLY:
            frequencyType = ProductFrequencyType.Yearly;
            break;
          default:
            frequencyType = ProductFrequencyType.Daily;
            break;
        }
        return `${fixedTerm.getFrequency()} ${getFrequencyText(fixedTerm.getFrequency(), frequencyType)}`;
      }
    }

    return "";
  }

  const coupons = couponList.filter(i => i.discountId === discountId);
  if (coupons.length > 0) {
    return (
      <><Table className="discount-list__table discount-list__coupons-table" >
        <thead>
          <tr>
            <Text component="th" content="DISCOUNTS_LIST_COUPON_COUPON_NAME" noMargin />
            <Text component="th" content="DISCOUNTS_LIST_COUPON_COUPON_TYPE" noMargin />
            <Text component="th" content="DISCOUNTS_LIST_COUPON_COUPON_EXPIRATION" noMargin />
            <Text component="th" content="DISCOUNTS_LIST_COUPON_DISCOUNT_LENGTH" noMargin />
            <Text component="th" content="" shouldTranslate={false} noMargin />
          </tr>
        </thead>
        <tbody>
          {coupons[0].coupons.getCouponsList().map((coupon, idx) => {
            if ((!showAll && idx > 4)) {
              return null;
            }
            return (
              <tr key={idx}>
                <Text component="td" shouldTranslate={false} content={coupon.getName()} noMargin />
                <Text component="td" shouldTranslate={true} content={coupon.getCouponType() === CouponType.MULTI_USE ? "CREATE_COUPON_FORM_COUPON_TYPE_MULTI_USE_TEXT" : "CREATE_COUPON_FORM_COUPON_TYPE_SINGLE_USE_TEXT"} noMargin />
                <Text component="td" shouldTranslate={false} content={coupon.getIsActive() ? getExpirationDate(coupon) : counterpart("COUPON_LIST_EXPIRED")} noMargin />
                <Text component="td" shouldTranslate={false} content={getDiscountLength(coupon)} noMargin />
                <td className='discount-list__table__actions'>
                  {coupon.getCouponType() === CouponType.SINGLE_USE && <Button
                    className="discount-list__table__actions-csv-btn"
                    id='coupon-csv'
                    buttonType='add'
                    disabled={!coupon.getIsActive()}
                    icon='far fa-file-download'
                    title={"DISCOUNTS_LIST_COUPON_CSV"}
                    onClick={() => {
                      downloadCsv(coupon);
                    }}
                  ></Button>}

                  <span data-tip data-for='discount-edit-coupon-tooltip' className="discount-list__table__actions-edit-btn">
                    <Button id='edit-coupon'
                      disabled={!coupon.getIsActive() || !isDiscountCouponEditPermitted}
                      tooltipMessage={!isDiscountCouponEditPermitted ? "NO_ACCESS_MSG" : undefined}
                      buttonType='add' icon='fas fa-edit' title='DISCOUNTS_LIST_COUPON_EDIT' onClick={() => history.push(`/discount-and-coupons/${discountId}/coupon/edit/${coupon.getCouponId()}`)}></Button>
                  </span>
                  <span data-tip data-for='discount-expire-coupon-tooltip' className="discount-list__table__actions-delete-btn">
                    <Button id='expire-coupon'
                      disabled={!coupon.getIsActive() || !isDiscountCouponExpirePermitted}
                      tooltipMessage={!isDiscountCouponExpirePermitted ? "NO_ACCESS_MSG" : undefined}
                      buttonType='error'
                      icon='far fa-calendar-times'
                      title='DISCOUNTS_LIST_COUPON_Expire'
                      onClick={() => {
                        dispatch(setDiscountsFields("couponToExpire", coupon));
                        dispatch(setDiscountsFields("discountIdOfcouponToExpire", discountId));
                        dispatch(setDiscountsFields("showExpireModal", true));
                      }}></Button>
                  </span>
                </td>
              </tr>
            );
          })}
        </tbody>
      </Table>

        {coupons[0].coupons.getCouponsList().length > 5 && !showAll && <div className="discount-list__show-all__container">
          <Text className="discount-list__show-all"
            onClick={() => setShowAll(true)}
            content={"DISCOUNTS_LIST_LOAD_ALL_COUPONS"}
            noMargin />
        </div>}

        <Tooltip id='discount-edit-coupon-tooltip' place='top' type='blue'>
          <Text content='DISCOUNTS_LIST_EDIT_COUPON_TOOLTIP' noMargin />
        </Tooltip>

        <Tooltip id='discount-expire-coupon-tooltip' place='top' type='blue'>
          <Text content='DISCOUNTS_LIST_EXPIRE_COUPON_TOOLTIP' noMargin />
        </Tooltip>
      </>
    );
  }

  return <></>;

}

export default DiscountCouponsTable
