import React, { useState, useEffect } from "react";
import PageWrapper from "../../components/ui/layout/PageWrapper";
import counterpart from "counterpart";
import Helmet from "react-helmet";
import NavigationAlertCurrencyMismatch from "../../components/ui/navigation-alert/NavigationAlertCurrencyMismatch";
import Text from "../../components/ui/text/Text";
import Search from "../../components/search/Search";
import Pagination, { SelectedPage } from "../../components/ui/pagination/Pagination";
import Table, { TableArrowBtn } from "../../components/ui/table/Table";
import Dropdown from "../../components/ui/dropdown/Dropdown";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../..";
import { resetEmailLogsState, setEmailLogsField, fetchEmailLogs } from "../../actions/emailLogsActions";
import "./EmailLogs.scss";
import EmailLogsWelcome from "../../components/email-sms-logs/email-logs/EmailLogsWelcome";
import moment from "moment";
import StatusLabel from "../../components/ui/status-label/StatusLabel";
import { COLOR, EMAIL_LOG_PAGINATION_SIZE } from "../../utils/constants";
import ProgressIndicator from "../../components/ui/progress-indicator/ProgressIndicator";
import Tooltip from "../../components/ui/tooltip/Tooltip";
import Modal from "../../components/modal/Modal";
import EmailLogModal from "../../components/email-logs/EmailLogModal";
import { useRouteMatch } from "react-router";
import { EmailLogEntry, GetEmailLogsResponse, Status } from "../../utils/grpc/generated/Billsby.Protos/EmailSender/private/emaillogs/emaillogs_pb";

export const EmailLogs: React.FC = () => {
  const dispatch = useDispatch();
  const match = useRouteMatch();
  const { 
    currentCompanyId,
    emailLogsReducer: { emailSearchedFilter, filters, emailTypeFilter, emailLogs, selectedPage, isFirstSearch, isLoadingLogs, hasMoreThanOnePage },
    customerDetailsReducer: { email, firstName }
  } = useSelector((state: AppState) => ({ emailLogsReducer: state.emailLogsReducer, currentCompanyDomain: state.auth.currentCompanyDomain, currentCompanyId: state.auth.currentCompanyId, customerDetailsReducer: state.customerDetailsReducer }))

  const [isOpenModal, setIsOpenModal] = useState(false);
  const [emailLogUrl, setEmailLogUrl] = useState("");

  // param passed through the customer details screen to see only the logs of a specific customer
  const customerEmailParam = email;
  const isEmailLogsPage = match?.url === "/email-logs";

  const getPageSize = () => !isEmailLogsPage ? 200 : EMAIL_LOG_PAGINATION_SIZE;

  useEffect(() => {
    dispatch(fetchEmailLogs(currentCompanyId, SelectedPage.FIRST, emailTypeFilter.value, isEmailLogsPage ? "" : customerEmailParam, "", getPageSize()));
    return () => {
      dispatch(resetEmailLogsState());
    }
  }, []);


  const getStyleCustomerDetailsScreen = () => {
    if (customerEmailParam) {
      return `
        .customer-details-tab__content {
          padding-top: 0;
        }
      `
    }
  }

  const getTokenNewPage = (newPage: SelectedPage, emailLogs: GetEmailLogsResponse) => {
    dispatch(setEmailLogsField("selectedPage", newPage));
    if (newPage === SelectedPage.NEXT) {
      return emailLogs.getNext();
    }
    if (newPage === SelectedPage.PREVIOUS) {
      return emailLogs.getPrevious();
    }
    if (newPage === SelectedPage.FIRST) {
      return emailLogs.getFirst();
    }
    return emailLogs.getLast();
  }

  if (!emailLogs) {
    return <ProgressIndicator color="blue" coverage={customerEmailParam ? "normal" : "full-content"} />
  }

  if (!emailLogs.getEmailsList().length && isFirstSearch) {
    return <EmailLogsWelcome customerName={firstName} />
  }

  const getTooltipDescription = (emailLog: EmailLogEntry) => {
    if (emailLog.getFailureDescription()) {
      return emailLog.getFailureDescription();
    }
    return counterpart("EMAIL_LOGS_STATUS_UNKNOWN")
  }
  const getDate = (emailLog: EmailLogEntry) => {
    const timestamp = emailLog.getSentOnTimestamp();
    if (timestamp) {
      return moment.unix(timestamp.getSeconds()).format("DD MMM YYYY")
    }
    return moment.unix(new Date().getSeconds()).format("DD MMM YYYY")
  }
  return (
    <div className="email-logs">
      <Helmet title={counterpart("LOGS_EMAIL")} />
      <NavigationAlertCurrencyMismatch />
      <style>{getStyleCustomerDetailsScreen()}</style>
      {
        customerEmailParam
          ? null
          : (
            <div className="email-logs__header">
              <div className="email-logs__filter">
                <Dropdown
                  id="email-logs-filter"
                  onChange={(selectedItem: any) => {
                    dispatch(setEmailLogsField("emailTypeFilter", selectedItem));
                    dispatch(setEmailLogsField("selectedPage", SelectedPage.FIRST));
                    dispatch(fetchEmailLogs(currentCompanyId, SelectedPage.FIRST, selectedItem.value, emailSearchedFilter || customerEmailParam, "", getPageSize()));
                  }}
                  value={emailTypeFilter}
                  options={filters}
                />
              </div>
              <span className="divider"></span>
              <div className="email-logs__search">
                <Search
                  value={emailSearchedFilter}
                  placeholder="EMAIL_LOGS_SEARCH_PLACEHOLDER"
                  onChange={(evt: any) => dispatch(setEmailLogsField("emailSearchedFilter", evt.target.value))}
                  onSearch={() => dispatch(fetchEmailLogs(currentCompanyId, SelectedPage.FIRST, emailTypeFilter.value, emailSearchedFilter || customerEmailParam, "", getPageSize()))}
                />
                <Pagination
                  className="email-logs__search__pagination"
                  type="unknownNrOfPages"
                  forcePage={selectedPage}
                  isVisible={hasMoreThanOnePage}
                  onPageChange={({ selected }) => {
                    const newPage: SelectedPage = selected;
                    const tokenNewPage = getTokenNewPage(newPage, emailLogs);
                    dispatch(fetchEmailLogs(currentCompanyId, newPage, emailTypeFilter.value, emailSearchedFilter || customerEmailParam, tokenNewPage, getPageSize()));
                  }}
                />
              </div>
            </div>
          )
      }
      <PageWrapper>
        {
          isLoadingLogs
            ? <ProgressIndicator color="blue" coverage={customerEmailParam ? "normal" : "full-content"} />
            : (
              <Table enableHoverEffect className="email-logs__table">
                <thead>
                  <tr>
                    <Text content="EMAIL_LOGS_COLUMN_EMAIL" component="th" noMargin />
                    <Text content="EMAIL_LOGS_COLUMN_SUBJECT" component="th" noMargin />
                    <Text content="EMAIL_LOGS_COLUMN_SENTON" component="th" noMargin />
                    <Text content="EMAIL_LOGS_COLUMN_STATUS" component="th" noMargin />
                    <Text className="view-arrow" content="" component="th" shouldTranslate={false} noMargin />
                  </tr>
                </thead>
                <tbody>
                  {
                    emailLogs.getEmailsList().map((emailLog: EmailLogEntry, idx: number) => {
                      return (
                        <tr className={`email-logs__table__item ${!emailLog.getIsDetailViewAvailable() ? "disabled" : ""}`} key={`emailLog-${idx}`} onClick={() => {
                          if (!emailLog.getIsDetailViewAvailable()) {
                            return;
                          }
                          setIsOpenModal(true); setEmailLogUrl(emailLog.getEmailUrl())
                        }}>
                          <td>{emailLog.getRecipient()}</td>
                          <td>{emailLog.getSubject()}</td>
                          <td>{getDate(emailLog)}</td>
                          <td data-tip data-for={`failed-email-tooltip-${idx}`}>
                            <StatusLabel
                              content={emailLog.getStatus() === Status.DELIVERED ? "EMAIL_LOGS_COLUMN_DELIVERED" : "EMAIL_LOGS_COLUMN_FAILED"}
                              color={emailLog.getStatus() === Status.DELIVERED ? COLOR.GREEN : COLOR.RED}
                              icon={emailLog.getStatus() === Status.FAILED ? "fas fa-question-circle" : ""}
                              iconPosition="right"
                            />
                            {
                              emailLog.getStatus() === Status.FAILED
                                ? (
                                  <Tooltip type="blue" className="trial-menu-tooltip" id={`failed-email-tooltip-${idx}`} place="bottom">
                                    <Text content={getTooltipDescription(emailLog)} shouldTranslate={false} noMargin />
                                  </Tooltip>
                                )
                                : null
                            }
                          </td>
                          <TableArrowBtn>
                            <i className="far fa-chevron-right"></i>
                          </TableArrowBtn>
                        </tr>
                      )
                    })
                  }
                </tbody>
                <Modal isOpen={isOpenModal} onRequestClose={() => setIsOpenModal(!isOpenModal)} title="EMAIL_LOGS_MODAL_TITLE">
                  <EmailLogModal emailLogUrl={emailLogUrl} />
                </Modal>
              </Table>
            )
        }
      </PageWrapper>
    </div>
  );
};

export default EmailLogs;
