import React, { FormEvent, useEffect, useLayoutEffect } from "react";
import Panel from "../../../components/ui/panel/Panel";
import Text from "../../../components/ui/text/Text";
import counterpart from "counterpart";
import { Row, Col } from "react-grid-system";
import FormLabel from "../../../components/ui/form-label/FormLabel";
import Input from "../../../components/ui/input/Input";
import "./RegistrationStep2.scss";
import Dropdown from "../../../components/ui/dropdown/Dropdown";
import FormGroup from "../../../components/ui/form-group/FormGroup";
import { useDispatch, useSelector } from "react-redux";
import Button from "../../../components/ui/button/Button";
import { registerUser, addCountry, addBillingCurrency, addCompanyName, getCountries, getCurrencies, addCompanyDomain, getIsCompanyDomainUsed, setIsDoingQuiz } from "../../../actions/registrationActions";
import { ICurrency } from "../../../models/Currency";
import { ICountry } from "../../../models/Country";
import { loginUser } from "../../../actions/loginActions";
import { setStoreType } from "../../../utils/storeUtils";
import { SESSION_STORAGE } from "../../../utils/constants";
import ErrorPanel from "../../../components/ui/error-panel/ErrorPanel";
import { AppState } from "../../..";
import { IRegistrationReducerState } from "../../../reducers/registrationReducer";
import { ILoginReducerState } from "../../../reducers/loginReducer";
import { debounce } from "../../../utils/commonUtils";
import TitleSection from "../../../components/ui/title-section/TitleSection";
import history from "../../../utils/history";
import { IAuthReducerState } from "../../../reducers/authReducer";


const RegistrationStep2: React.FC = () => {
  const { firstName, lastName, workEmail, phoneNumberCountryIso2, phoneNumberPrefix, phoneNumber, password, notificationAlert, company, country, billingCurrency,
    invitationCode, currencies = [], countries = [], isRegisteringUser, isRegisteringUserSuccess, isRegisteringUserFailure, companyDomain,
    isCompanyDomainUsed, freeTrialToken } = useSelector<AppState, IRegistrationReducerState>(state => state.registration);
  const { isLoginRequest, isLoginSuccess } = useSelector<AppState, ILoginReducerState>(state => state.login);
  const auth = useSelector<AppState, IAuthReducerState>(state => state.auth)
  const dispatch = useDispatch<Function>()

  const onSubmit = (evt: FormEvent) => {
    evt.preventDefault();
    dispatch(registerUser({
      firstName,
      lastName,
      email: workEmail,
      password,
      phoneNumberDialCountry: phoneNumberCountryIso2,
      phoneNumberDialCode: phoneNumberPrefix,
      phoneNumber: phoneNumber,
      companyCountry: country.value,
      companyCurrency: billingCurrency.value,
      companyName: company,
      IsOptedInForMarketing: notificationAlert,
      timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      billsbyAccessPermittedUntil: "",
      freeTrialToken,
      companyDomain,
      googleAnalyticsId: auth.currentCompanyGoogleAnalyticsUID
    })).then(() => {
      // temporary login for the quiz, keep token in the session
      setStoreType(SESSION_STORAGE);
      dispatch(setIsDoingQuiz(true));
      dispatch(loginUser(workEmail, password, undefined, false));
    })
  }

  const fetchSubdomainIsUsed = debounce(() => dispatch(getIsCompanyDomainUsed(companyDomain)), 500);

  useEffect(() => {
    if (companyDomain) {
      fetchSubdomainIsUsed();
    }
  }, [companyDomain]);


  useLayoutEffect(() => {
    dispatch(addCompanyDomain(company.toLowerCase()));
    dispatch(getCountries())
      // needs to be done in sequence, currencies depends on countries for the currency autoselection
      .then(() => dispatch(getCurrencies()));
  }, []);

  useEffect(() => {
    if (!isLoginRequest && isLoginSuccess) {
      history.push("/registration/quiz");
    }
  }, [isLoginRequest, isLoginSuccess]);


  if (isLoginRequest && !isLoginSuccess) {
    return null;
  }

  return (
    <form className="registration registration-step2" onSubmit={onSubmit}>

      <ErrorPanel isVisible={isRegisteringUserFailure} />

      {/*<Panel title={counterpart("REGISTRATION_STEP2_TITLE", { firstName })} shouldTranslate={false}>
        <Text content="REGISTRATION_STEP2_CONTENT" noMargin />
      </Panel>*/}

      <TitleSection
        title={counterpart("REGISTRATION_START_WELCOME", { firstName })}
        shouldTranslateTitle={false}
        subTitle="REGISTRATION_START_WELCOME_CONFIGURATION"
      />

      <Panel>
        <FormGroup>
          <Row align="center">
            <Col md={2}>
              <FormLabel target="company" content="REGISTRATION_STEP2_COMPANY_LABEL" />
            </Col>
            <Col md={10}>
              <Input
                required
                id="company"
                value={company}
                minLength="3"
                placeholder={counterpart("REGISTRATION_STEP2_COMPANY_PLACEHOLDER")}
                onChange={(evt: any) => {
                  const sanitizeValue = evt.target.value.replace(/[^a-zA-Z0-9 ]/g, "")
                  dispatch(addCompanyName(sanitizeValue));
                }}
              />
            </Col>
          </Row>
        </FormGroup>

        <FormGroup>
          <Row align="center">
            <Col md={2}>
              <FormLabel target="country" content="REGISTRATION_STEP2_COUNTRY_LABEL" />
            </Col>
            <Col md={10}>
              <Dropdown
                id="country"
                dividerAfter={4}
                onChange={(country: any) => dispatch(addCountry(country))}
                value={country}
                options={countries.map((country: ICountry) => ({ value: country.iso3Code, label: country.name }))}
              />
            </Col>
          </Row>
        </FormGroup>

        <FormGroup>
          <Row align="center">
            <Col md={2}>
              <FormLabel target="billingcurrency" content="REGISTRATION_STEP2_CURRENCY_LABEL" />
            </Col>
            <Col md={10}>
              <Dropdown
                id="billingcurrency"
                dividerAfter={4}
                onChange={(currency: any) => dispatch(addBillingCurrency(currency))}
                value={billingCurrency}
                options={currencies.map((currency: ICurrency) => ({ value: currency.isoCode, label: currency.englishName }))}
              />
            </Col>
          </Row>
        </FormGroup>
      </Panel>

      {
        company && (
          <Panel className="company-subdomain">
            <FormGroup noMargin>
              <Text content="REGISTRATION_STEP2_COMPANY_SUBDOMAIN_TITLE" />
              <Row align="center">
                <Col md={5}>
                  <Input
                    id="company-subdomain"
                    placeholder="mycompany"
                    required
                    value={companyDomain}
                    onChange={(evt: any) => {
                      // switch to lowercase because the backend doesn't save a capitalized letter in the company domain
                      dispatch(addCompanyDomain(evt.target.value.toLowerCase().replace(/[^a-z0-9]/gi, "")));
                    }}
                    isError={isCompanyDomainUsed}
                  />
                </Col>
                <Col md={7}>
                  <FormLabel target="" content="REGISTRATION_STEP2_COMPANY_SUBDOMAIN_BILLSBY" noMargin />
                </Col>
              </Row>
              <Row align="center">
                <Col xs={12}>
                  {isCompanyDomainUsed &&
                    <FormLabel
                      className="company-subdomain__error"
                      target=""
                      content="REGISTRATION_STEP2_COMPANY_SUBDOMAIN_ERROR"
                      noMargin
                    />}
                </Col>
              </Row>
            </FormGroup>
          </Panel>
        )
      }


      <Button
        id="complete-registration-btn"
        title="REGISTRATION_STEP2_COMPLETE_REGISTRATION"
        isFullWidth
        disabled={isCompanyDomainUsed || isRegisteringUser}
        type="submit"
        buttonType="orangeBillsbyMarketing"
        isLoading={isRegisteringUser}
      />
    </form>
  )
}



export default RegistrationStep2