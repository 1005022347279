import React, { FormEvent, useState } from "react";
import { useSelector } from "react-redux";
import Panel from "../ui/panel/Panel";
import Text from "../ui/text/Text";
import Button from "../ui/button/Button";
import Checkbox from "../ui/checkbox/Checkbox";
import FormGroup from "../ui/form-group/FormGroup";
import API from "../../utils/API";
import { AppState } from "../..";
import NoticePanel from "../ui/notice-panel/NoticePanel";
import { InvoiceStatus } from "../../models/Invoices";
import "./UpdateInvoiceStatus.scss";

interface IUpdateInvoiceStatusModalProps {
  handleUpdateStatusCallback: Function;
}

const UpdateInvoiceStatus: React.FC<IUpdateInvoiceStatusModalProps> = ({ handleUpdateStatusCallback }) => {
  const currentCompanyDomain = useSelector((state: AppState) => state.auth.currentCompanyDomain);
  const invoiceNumber = useSelector((state: AppState) => state.invoiceDetailsReducer.invoiceDetails.invoiceNumber);

  const [state, setState] = useState({
    hasError: false,
    success: false,
    isLoadingData: false,
    isLoading: false,
    selectedStatus: InvoiceStatus.UNPAID
  });

  const onSubmit = async (evt: FormEvent) => {
    evt.preventDefault();
    evt.stopPropagation();

    const { selectedStatus } = state;

    setState((prevState) => { return { ...prevState, isLoading: true }});

    if (selectedStatus === InvoiceStatus.UNPAID) {
      await handleUpdateStatusCallback();
      return;
    }

    try {
      await API[selectedStatus === InvoiceStatus.PAID_OFFLINE ? "updateInvoiceToPaidOffLine" : "updateInvoiceToWrittenOff"](currentCompanyDomain, invoiceNumber);
      await handleUpdateStatusCallback()
      setState((prevState) => { return { ...prevState, isLoading: false }});
    } catch (err) {
      setState((prevState) => { return { ...prevState, isLoading: false }});
    }
  };

  const renderStatusCheckboxes = () => {
    const { selectedStatus } = state;
    return [InvoiceStatus.UNPAID, InvoiceStatus.PAID_OFFLINE, InvoiceStatus.WRITTEN_OFF].map(status => (
      <Checkbox
        key={status}
        checked={selectedStatus === status}
        onClick={() => setState((prevState) => { return { ...prevState, selectedStatus: status }})}
        value={status}
        content={<Text content={`INVOICE_UPDATE_STATUS_${status.toUpperCase()}`} noMargin />}
      />
    ));
  };


  if (state.isLoadingData) {
    return null;
  }

  return (
    <form className="update-invoice-status" onSubmit={onSubmit}>
      {state.selectedStatus !== InvoiceStatus.UNPAID && <NoticePanel type="warning" isModal={true} icon="far fa-lightbulb-exclamation" content="INVOICE_UPDATE_STATUS_WARNING" />}
      <Panel className="update-invoice-status__titlepanel" title="INVOICE_UPDATE_STATUS_MODAL_TTILE">
        <Text className="update-invoice-status__text" content="INVOICE_UPDATE_STATUS_MODAL_CONTENT" noMargin />
      </Panel>

      <Panel>
        <FormGroup>{renderStatusCheckboxes()}</FormGroup>
      </Panel>

      <Button
        id="charge-customer"
        type="submit"
        icon={state.success ? "far fa-check-circle" : ""}
        buttonType={state.success ? "success" : "general"}
        isLoading={state.isLoading && !state.hasError}
        title={"INVOICE_UPDATE_STATUS_BUTTON"}
        isFullWidth
      />
    </form>
  );
}

export default UpdateInvoiceStatus;
