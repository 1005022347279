/* eslint-disable */
// source: emaillogs.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = Function('return this')();

var google_protobuf_timestamp_pb = require('google-protobuf/google/protobuf/timestamp_pb.js');
goog.object.extend(proto, google_protobuf_timestamp_pb);
goog.exportSymbol('proto.emailsender.private.emaillogs.EmailLogEntry', null, global);
goog.exportSymbol('proto.emailsender.private.emaillogs.GetEmailLogsRequest', null, global);
goog.exportSymbol('proto.emailsender.private.emaillogs.GetEmailLogsResponse', null, global);
goog.exportSymbol('proto.emailsender.private.emaillogs.GetEmailRequest', null, global);
goog.exportSymbol('proto.emailsender.private.emaillogs.GetEmailResponse', null, global);
goog.exportSymbol('proto.emailsender.private.emaillogs.Status', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.emailsender.private.emaillogs.GetEmailLogsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.emailsender.private.emaillogs.GetEmailLogsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.emailsender.private.emaillogs.GetEmailLogsRequest.displayName = 'proto.emailsender.private.emaillogs.GetEmailLogsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.emailsender.private.emaillogs.GetEmailLogsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.emailsender.private.emaillogs.GetEmailLogsResponse.repeatedFields_, null);
};
goog.inherits(proto.emailsender.private.emaillogs.GetEmailLogsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.emailsender.private.emaillogs.GetEmailLogsResponse.displayName = 'proto.emailsender.private.emaillogs.GetEmailLogsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.emailsender.private.emaillogs.EmailLogEntry = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.emailsender.private.emaillogs.EmailLogEntry, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.emailsender.private.emaillogs.EmailLogEntry.displayName = 'proto.emailsender.private.emaillogs.EmailLogEntry';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.emailsender.private.emaillogs.GetEmailRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.emailsender.private.emaillogs.GetEmailRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.emailsender.private.emaillogs.GetEmailRequest.displayName = 'proto.emailsender.private.emaillogs.GetEmailRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.emailsender.private.emaillogs.GetEmailResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.emailsender.private.emaillogs.GetEmailResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.emailsender.private.emaillogs.GetEmailResponse.displayName = 'proto.emailsender.private.emaillogs.GetEmailResponse';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.emailsender.private.emaillogs.GetEmailLogsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.emailsender.private.emaillogs.GetEmailLogsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.emailsender.private.emaillogs.GetEmailLogsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.emailsender.private.emaillogs.GetEmailLogsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    companyId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    emailStatusFilter: jspb.Message.getFieldWithDefault(msg, 2, 0),
    searchFilter: jspb.Message.getFieldWithDefault(msg, 3, ""),
    resultsPerPage: jspb.Message.getFieldWithDefault(msg, 4, 0),
    pageUrl: jspb.Message.getFieldWithDefault(msg, 5, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.emailsender.private.emaillogs.GetEmailLogsRequest}
 */
proto.emailsender.private.emaillogs.GetEmailLogsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.emailsender.private.emaillogs.GetEmailLogsRequest;
  return proto.emailsender.private.emaillogs.GetEmailLogsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.emailsender.private.emaillogs.GetEmailLogsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.emailsender.private.emaillogs.GetEmailLogsRequest}
 */
proto.emailsender.private.emaillogs.GetEmailLogsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setCompanyId(value);
      break;
    case 2:
      var value = /** @type {!proto.emailsender.private.emaillogs.Status} */ (reader.readEnum());
      msg.setEmailStatusFilter(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setSearchFilter(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setResultsPerPage(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setPageUrl(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.emailsender.private.emaillogs.GetEmailLogsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.emailsender.private.emaillogs.GetEmailLogsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.emailsender.private.emaillogs.GetEmailLogsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.emailsender.private.emaillogs.GetEmailLogsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCompanyId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getEmailStatusFilter();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
  f = message.getSearchFilter();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getResultsPerPage();
  if (f !== 0) {
    writer.writeInt32(
      4,
      f
    );
  }
  f = message.getPageUrl();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
};


/**
 * optional int32 company_id = 1;
 * @return {number}
 */
proto.emailsender.private.emaillogs.GetEmailLogsRequest.prototype.getCompanyId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.emailsender.private.emaillogs.GetEmailLogsRequest} returns this
 */
proto.emailsender.private.emaillogs.GetEmailLogsRequest.prototype.setCompanyId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional Status email_status_filter = 2;
 * @return {!proto.emailsender.private.emaillogs.Status}
 */
proto.emailsender.private.emaillogs.GetEmailLogsRequest.prototype.getEmailStatusFilter = function() {
  return /** @type {!proto.emailsender.private.emaillogs.Status} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.emailsender.private.emaillogs.Status} value
 * @return {!proto.emailsender.private.emaillogs.GetEmailLogsRequest} returns this
 */
proto.emailsender.private.emaillogs.GetEmailLogsRequest.prototype.setEmailStatusFilter = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};


/**
 * optional string search_filter = 3;
 * @return {string}
 */
proto.emailsender.private.emaillogs.GetEmailLogsRequest.prototype.getSearchFilter = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.emailsender.private.emaillogs.GetEmailLogsRequest} returns this
 */
proto.emailsender.private.emaillogs.GetEmailLogsRequest.prototype.setSearchFilter = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional int32 results_per_page = 4;
 * @return {number}
 */
proto.emailsender.private.emaillogs.GetEmailLogsRequest.prototype.getResultsPerPage = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.emailsender.private.emaillogs.GetEmailLogsRequest} returns this
 */
proto.emailsender.private.emaillogs.GetEmailLogsRequest.prototype.setResultsPerPage = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional string page_url = 5;
 * @return {string}
 */
proto.emailsender.private.emaillogs.GetEmailLogsRequest.prototype.getPageUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.emailsender.private.emaillogs.GetEmailLogsRequest} returns this
 */
proto.emailsender.private.emaillogs.GetEmailLogsRequest.prototype.setPageUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.emailsender.private.emaillogs.GetEmailLogsResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.emailsender.private.emaillogs.GetEmailLogsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.emailsender.private.emaillogs.GetEmailLogsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.emailsender.private.emaillogs.GetEmailLogsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.emailsender.private.emaillogs.GetEmailLogsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    emailsList: jspb.Message.toObjectList(msg.getEmailsList(),
    proto.emailsender.private.emaillogs.EmailLogEntry.toObject, includeInstance),
    previous: jspb.Message.getFieldWithDefault(msg, 2, ""),
    first: jspb.Message.getFieldWithDefault(msg, 3, ""),
    last: jspb.Message.getFieldWithDefault(msg, 4, ""),
    next: jspb.Message.getFieldWithDefault(msg, 5, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.emailsender.private.emaillogs.GetEmailLogsResponse}
 */
proto.emailsender.private.emaillogs.GetEmailLogsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.emailsender.private.emaillogs.GetEmailLogsResponse;
  return proto.emailsender.private.emaillogs.GetEmailLogsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.emailsender.private.emaillogs.GetEmailLogsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.emailsender.private.emaillogs.GetEmailLogsResponse}
 */
proto.emailsender.private.emaillogs.GetEmailLogsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.emailsender.private.emaillogs.EmailLogEntry;
      reader.readMessage(value,proto.emailsender.private.emaillogs.EmailLogEntry.deserializeBinaryFromReader);
      msg.addEmails(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setPrevious(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setFirst(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setLast(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setNext(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.emailsender.private.emaillogs.GetEmailLogsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.emailsender.private.emaillogs.GetEmailLogsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.emailsender.private.emaillogs.GetEmailLogsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.emailsender.private.emaillogs.GetEmailLogsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getEmailsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.emailsender.private.emaillogs.EmailLogEntry.serializeBinaryToWriter
    );
  }
  f = message.getPrevious();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getFirst();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getLast();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getNext();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
};


/**
 * repeated EmailLogEntry emails = 1;
 * @return {!Array<!proto.emailsender.private.emaillogs.EmailLogEntry>}
 */
proto.emailsender.private.emaillogs.GetEmailLogsResponse.prototype.getEmailsList = function() {
  return /** @type{!Array<!proto.emailsender.private.emaillogs.EmailLogEntry>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.emailsender.private.emaillogs.EmailLogEntry, 1));
};


/**
 * @param {!Array<!proto.emailsender.private.emaillogs.EmailLogEntry>} value
 * @return {!proto.emailsender.private.emaillogs.GetEmailLogsResponse} returns this
*/
proto.emailsender.private.emaillogs.GetEmailLogsResponse.prototype.setEmailsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.emailsender.private.emaillogs.EmailLogEntry=} opt_value
 * @param {number=} opt_index
 * @return {!proto.emailsender.private.emaillogs.EmailLogEntry}
 */
proto.emailsender.private.emaillogs.GetEmailLogsResponse.prototype.addEmails = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.emailsender.private.emaillogs.EmailLogEntry, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.emailsender.private.emaillogs.GetEmailLogsResponse} returns this
 */
proto.emailsender.private.emaillogs.GetEmailLogsResponse.prototype.clearEmailsList = function() {
  return this.setEmailsList([]);
};


/**
 * optional string previous = 2;
 * @return {string}
 */
proto.emailsender.private.emaillogs.GetEmailLogsResponse.prototype.getPrevious = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.emailsender.private.emaillogs.GetEmailLogsResponse} returns this
 */
proto.emailsender.private.emaillogs.GetEmailLogsResponse.prototype.setPrevious = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string first = 3;
 * @return {string}
 */
proto.emailsender.private.emaillogs.GetEmailLogsResponse.prototype.getFirst = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.emailsender.private.emaillogs.GetEmailLogsResponse} returns this
 */
proto.emailsender.private.emaillogs.GetEmailLogsResponse.prototype.setFirst = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string last = 4;
 * @return {string}
 */
proto.emailsender.private.emaillogs.GetEmailLogsResponse.prototype.getLast = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.emailsender.private.emaillogs.GetEmailLogsResponse} returns this
 */
proto.emailsender.private.emaillogs.GetEmailLogsResponse.prototype.setLast = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string next = 5;
 * @return {string}
 */
proto.emailsender.private.emaillogs.GetEmailLogsResponse.prototype.getNext = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.emailsender.private.emaillogs.GetEmailLogsResponse} returns this
 */
proto.emailsender.private.emaillogs.GetEmailLogsResponse.prototype.setNext = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.emailsender.private.emaillogs.EmailLogEntry.prototype.toObject = function(opt_includeInstance) {
  return proto.emailsender.private.emaillogs.EmailLogEntry.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.emailsender.private.emaillogs.EmailLogEntry} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.emailsender.private.emaillogs.EmailLogEntry.toObject = function(includeInstance, msg) {
  var f, obj = {
    recipient: jspb.Message.getFieldWithDefault(msg, 1, ""),
    subject: jspb.Message.getFieldWithDefault(msg, 2, ""),
    sentOnTimestamp: (f = msg.getSentOnTimestamp()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    emailUrl: jspb.Message.getFieldWithDefault(msg, 4, ""),
    failureDescription: jspb.Message.getFieldWithDefault(msg, 5, ""),
    status: jspb.Message.getFieldWithDefault(msg, 6, 0),
    isDetailViewAvailable: jspb.Message.getBooleanFieldWithDefault(msg, 7, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.emailsender.private.emaillogs.EmailLogEntry}
 */
proto.emailsender.private.emaillogs.EmailLogEntry.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.emailsender.private.emaillogs.EmailLogEntry;
  return proto.emailsender.private.emaillogs.EmailLogEntry.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.emailsender.private.emaillogs.EmailLogEntry} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.emailsender.private.emaillogs.EmailLogEntry}
 */
proto.emailsender.private.emaillogs.EmailLogEntry.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setRecipient(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setSubject(value);
      break;
    case 3:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setSentOnTimestamp(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setEmailUrl(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setFailureDescription(value);
      break;
    case 6:
      var value = /** @type {!proto.emailsender.private.emaillogs.Status} */ (reader.readEnum());
      msg.setStatus(value);
      break;
    case 7:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsDetailViewAvailable(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.emailsender.private.emaillogs.EmailLogEntry.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.emailsender.private.emaillogs.EmailLogEntry.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.emailsender.private.emaillogs.EmailLogEntry} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.emailsender.private.emaillogs.EmailLogEntry.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRecipient();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getSubject();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getSentOnTimestamp();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getEmailUrl();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getFailureDescription();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      6,
      f
    );
  }
  f = message.getIsDetailViewAvailable();
  if (f) {
    writer.writeBool(
      7,
      f
    );
  }
};


/**
 * optional string recipient = 1;
 * @return {string}
 */
proto.emailsender.private.emaillogs.EmailLogEntry.prototype.getRecipient = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.emailsender.private.emaillogs.EmailLogEntry} returns this
 */
proto.emailsender.private.emaillogs.EmailLogEntry.prototype.setRecipient = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string subject = 2;
 * @return {string}
 */
proto.emailsender.private.emaillogs.EmailLogEntry.prototype.getSubject = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.emailsender.private.emaillogs.EmailLogEntry} returns this
 */
proto.emailsender.private.emaillogs.EmailLogEntry.prototype.setSubject = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional google.protobuf.Timestamp sent_on_timestamp = 3;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.emailsender.private.emaillogs.EmailLogEntry.prototype.getSentOnTimestamp = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 3));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.emailsender.private.emaillogs.EmailLogEntry} returns this
*/
proto.emailsender.private.emaillogs.EmailLogEntry.prototype.setSentOnTimestamp = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.emailsender.private.emaillogs.EmailLogEntry} returns this
 */
proto.emailsender.private.emaillogs.EmailLogEntry.prototype.clearSentOnTimestamp = function() {
  return this.setSentOnTimestamp(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.emailsender.private.emaillogs.EmailLogEntry.prototype.hasSentOnTimestamp = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional string email_url = 4;
 * @return {string}
 */
proto.emailsender.private.emaillogs.EmailLogEntry.prototype.getEmailUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.emailsender.private.emaillogs.EmailLogEntry} returns this
 */
proto.emailsender.private.emaillogs.EmailLogEntry.prototype.setEmailUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string failure_description = 5;
 * @return {string}
 */
proto.emailsender.private.emaillogs.EmailLogEntry.prototype.getFailureDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.emailsender.private.emaillogs.EmailLogEntry} returns this
 */
proto.emailsender.private.emaillogs.EmailLogEntry.prototype.setFailureDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional Status status = 6;
 * @return {!proto.emailsender.private.emaillogs.Status}
 */
proto.emailsender.private.emaillogs.EmailLogEntry.prototype.getStatus = function() {
  return /** @type {!proto.emailsender.private.emaillogs.Status} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {!proto.emailsender.private.emaillogs.Status} value
 * @return {!proto.emailsender.private.emaillogs.EmailLogEntry} returns this
 */
proto.emailsender.private.emaillogs.EmailLogEntry.prototype.setStatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 6, value);
};


/**
 * optional bool is_detail_view_available = 7;
 * @return {boolean}
 */
proto.emailsender.private.emaillogs.EmailLogEntry.prototype.getIsDetailViewAvailable = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 7, false));
};


/**
 * @param {boolean} value
 * @return {!proto.emailsender.private.emaillogs.EmailLogEntry} returns this
 */
proto.emailsender.private.emaillogs.EmailLogEntry.prototype.setIsDetailViewAvailable = function(value) {
  return jspb.Message.setProto3BooleanField(this, 7, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.emailsender.private.emaillogs.GetEmailRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.emailsender.private.emaillogs.GetEmailRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.emailsender.private.emaillogs.GetEmailRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.emailsender.private.emaillogs.GetEmailRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    companyId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    emailUrl: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.emailsender.private.emaillogs.GetEmailRequest}
 */
proto.emailsender.private.emaillogs.GetEmailRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.emailsender.private.emaillogs.GetEmailRequest;
  return proto.emailsender.private.emaillogs.GetEmailRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.emailsender.private.emaillogs.GetEmailRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.emailsender.private.emaillogs.GetEmailRequest}
 */
proto.emailsender.private.emaillogs.GetEmailRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setCompanyId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setEmailUrl(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.emailsender.private.emaillogs.GetEmailRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.emailsender.private.emaillogs.GetEmailRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.emailsender.private.emaillogs.GetEmailRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.emailsender.private.emaillogs.GetEmailRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCompanyId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getEmailUrl();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional int32 company_id = 1;
 * @return {number}
 */
proto.emailsender.private.emaillogs.GetEmailRequest.prototype.getCompanyId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.emailsender.private.emaillogs.GetEmailRequest} returns this
 */
proto.emailsender.private.emaillogs.GetEmailRequest.prototype.setCompanyId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string email_url = 2;
 * @return {string}
 */
proto.emailsender.private.emaillogs.GetEmailRequest.prototype.getEmailUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.emailsender.private.emaillogs.GetEmailRequest} returns this
 */
proto.emailsender.private.emaillogs.GetEmailRequest.prototype.setEmailUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.emailsender.private.emaillogs.GetEmailResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.emailsender.private.emaillogs.GetEmailResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.emailsender.private.emaillogs.GetEmailResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.emailsender.private.emaillogs.GetEmailResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    body: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.emailsender.private.emaillogs.GetEmailResponse}
 */
proto.emailsender.private.emaillogs.GetEmailResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.emailsender.private.emaillogs.GetEmailResponse;
  return proto.emailsender.private.emaillogs.GetEmailResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.emailsender.private.emaillogs.GetEmailResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.emailsender.private.emaillogs.GetEmailResponse}
 */
proto.emailsender.private.emaillogs.GetEmailResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setBody(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.emailsender.private.emaillogs.GetEmailResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.emailsender.private.emaillogs.GetEmailResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.emailsender.private.emaillogs.GetEmailResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.emailsender.private.emaillogs.GetEmailResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getBody();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string body = 1;
 * @return {string}
 */
proto.emailsender.private.emaillogs.GetEmailResponse.prototype.getBody = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.emailsender.private.emaillogs.GetEmailResponse} returns this
 */
proto.emailsender.private.emaillogs.GetEmailResponse.prototype.setBody = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * @enum {number}
 */
proto.emailsender.private.emaillogs.Status = {
  UNASSIGNED: 0,
  FAILED: 1,
  DELIVERED: 2
};

goog.object.extend(exports, proto.emailsender.private.emaillogs);
