import React, { useState, useLayoutEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../../../..";
import { IAuthReducerState } from "../../../../reducers/authReducer";
import Table from "../../../ui/table/Table";
import Text from "../../../ui/text/Text";
import Panel from "../../../ui/panel/Panel";
import Switch from "../../../ui/switch/Switch";
import { ILinkAddOnsAllowancesReducerState, ISelectedCompanyAddOn, isPriceModelsConfigured, ISelectedCompanyAllowance } from "../../../../reducers/linkAddOnsAllowancesReducer";
import { setForceAddOn, removeSelectedAddOn, setLinkAddOnsAllowancesFields, setUnconfiguredPriceModelsList } from "../../../../actions/linkAddOnsAllowancesActions";
import { ICycleReducer } from "../../../../reducers/cyclesReducer";
import { getFrequencyTypeGrpc, getPricingModelTypeEnum } from "../../../../utils/commonUtils";
import { AddonPriceModel, Currency } from "../../../../utils/grpc/generated/Billsby.Protos/core/private/addons/addons_pb";
import AddOnPriceModelPanel from "../../../addons-allowances/addon-cost/AddOnPriceModelPanel";
import { IProductLightWithPlans, IPlanAllowance, IPlanAddOn } from "../../../../models/Product";
import { AddonsAllowancesScreen } from "../../../../models/AddonsAllowances";
import { AllowancePriceModel } from "../../../../utils/grpc/generated/Billsby.Protos/core/private/allowances/allowances_pb";
import { IPlanReducer } from "../../../../reducers/plansReducer";

interface Props {
  addOn: ISelectedCompanyAddOn | ISelectedCompanyAllowance;
  product: IProductLightWithPlans;
  isAddOn?: boolean
}

const SelectAddOnsListItem: React.FC<Props> = ({ addOn, product, isAddOn = true }) => {
  const auth = useSelector<AppState, IAuthReducerState>(state => state.auth)
  const { addOnPriceModel, activeTab }  = useSelector<AppState, ILinkAddOnsAllowancesReducerState>(state => state.linkAddOnsAllowancesReducer)
  const { cycles } = useSelector<AppState, ICycleReducer>(state => state.cycles)
  const { addons, allowances } = useSelector<AppState, IPlanReducer>(state => state.plans)

  const dispatch = useDispatch<Function>()

  const [isExpanded, setIsExpanded] = useState(false);
  const [newPriceModelsConfigured, setNewPriceModelsConfigured] = useState(false);

  const { priceModelList, unconfiguredPriceModelsList, id, isConfigured, isForced, name, addonPricingModelType } = addOn;
  const isAddonScreen = activeTab === AddonsAllowancesScreen.ADDONS;
  const planProductCurrencyTemplate = product.billingCurrencyTemplate;
  const templateCharToBeReplaced = planProductCurrencyTemplate.indexOf("N.D") > -1 ? "N.D" : "N"; 
  const currencySymbol = planProductCurrencyTemplate.replace(templateCharToBeReplaced, "_");
  const preExistingItems = isAddonScreen ? addons : allowances as Array<IPlanAddOn | IPlanAllowance>;
  const isNewAddon = preExistingItems.every(el => el.id !== addOn.id);
  const hasUnconfiguredPricingModels = !!unconfiguredPriceModelsList.length;
  const currency = new Currency();
  currency.setSymbol(currencySymbol);

  useLayoutEffect(() => {
    let newPriceModels: Array<AddonPriceModel | AllowancePriceModel> = [...unconfiguredPriceModelsList];

    cycles.forEach((cycle) => {
      const newPriceModel = isAddOn ? new AddonPriceModel() : new AllowancePriceModel();
      newPriceModel.setFrequency(+cycle.pricingModel.frequency);
      newPriceModel.setFrequencyType(getFrequencyTypeGrpc(cycle.pricingModel.frequencyType));

      const priceModelConfigured = (priceModelList as Array<AddonPriceModel | AllowancePriceModel>)
        .find((pm) => pm.getCurrency()?.getIso3Code() === product.billingCurrency && pm.getFrequency() === newPriceModel.getFrequency() && pm.getFrequencyType() === newPriceModel.getFrequencyType());
      const priceModelAdded = newPriceModels.find((pm) => pm.getFrequency() === newPriceModel.getFrequency() && pm.getFrequencyType() === newPriceModel.getFrequencyType());
      const currencyConfigured = (priceModelList as Array<AddonPriceModel | AllowancePriceModel>).find(pm => (pm.getCurrency() as Currency).getIso3Code() === product.billingCurrency)

      if ((priceModelConfigured && currencyConfigured) || priceModelAdded) {
        return;
      }

      newPriceModels.push(newPriceModel);
    });

    

    dispatch(setUnconfiguredPriceModelsList({ id, arr: newPriceModels }));
  }, [cycles, priceModelList]);

  useLayoutEffect(() => {
    setNewPriceModelsConfigured((unconfiguredPriceModelsList as Array<AddonPriceModel | AllowancePriceModel>)
      .every((priceModel) => isPriceModelsConfigured(priceModel)));
    if (isExpanded && unconfiguredPriceModelsList.length === 0) {
      setIsExpanded(false);
    }
  }, [addOnPriceModel, unconfiguredPriceModelsList]);

  const renderAddonPriceModelsList = () => {
    if (unconfiguredPriceModelsList.length === 0) {
      return null;
    }

    return (unconfiguredPriceModelsList as Array<AddonPriceModel | AllowancePriceModel>)
      .map((priceModel, idx) => {
        const addOnPricingModelType = getPricingModelTypeEnum(addonPricingModelType);

        return (
          <AddOnPriceModelPanel
            isTableRow
            key={idx}
            priceModel={priceModel}
            configurePriceModelCb={() => {
              dispatch(setLinkAddOnsAllowancesFields("addOnPriceModel", priceModel));
              dispatch(setLinkAddOnsAllowancesFields("selectedPricingModel", addOnPricingModelType));
            }}
            selectedCurrency={currency}
            pricingModelType={addOnPricingModelType}
          />
        );
      });
  };

  return (
    <Panel
      key={`selected-add-on-${id}`}
      className={`link-addons-allowances__select_add_ons__list${newPriceModelsConfigured ? " link-addons-allowances__select_add_ons__list--new-price-models-configured" : ""}`}
    >
      <div>
        {(!isConfigured || (isNewAddon && hasUnconfiguredPricingModels)) && (
          <i
            className={`link-addons-allowances__select_add_ons__arrow far fa-chevron-${isExpanded ? "up" : "down"} ${newPriceModelsConfigured ? "text-blue" : ""}`}
            onClick={() => setIsExpanded(!isExpanded)}
          />
        )}
        <div className={`link-addons-allowances__select_add_ons__name${!isConfigured ? " link-addons-allowances__select_add_ons__name--not-configured" : ""}`}>
          {!isConfigured && <i className={`fas fa-map-marker-exclamation${newPriceModelsConfigured ? " hidden" : ""}`} />}
          <Text className="addons-name" content={name} shouldTranslate={false} noMargin />
        </div>
        <div className="link-addons-allowances__select_add_ons__commands">
          <Switch id={`switch-selected-add-on-${id}`} checked={isForced} onChange={() => dispatch(setForceAddOn(id))} className="create-add-ons__select-plans__list__commands-force" />
          <Text 
            content={isAddonScreen ? "CREATE_ADD_ONS_FORM_HEADER_SELECT_PLANS_FORCE_ADD" : "CREATE_ALLOWANCES_FORM_HEADER_SELECT_PLANS_FORCE_ADD"} 
            noMargin 
          />
          <i className="link-addons-allowances__select_add_ons__delete far fa-trash-alt" onClick={() => dispatch(removeSelectedAddOn(id))} />
        </div>
      </div>

      {isExpanded && (
        <div className="link-addons-allowances__select_add_ons__expanded-wrapper">
          <Table className="step-addon-cost__list">
            <tbody>{renderAddonPriceModelsList()}</tbody>
          </Table>
        </div>
      )}
    </Panel>
  );
};

export default SelectAddOnsListItem